const serviceUrl = `${__configuration.apiUrl}/massia/gestion/grille-tarifaires`;

export default class GrilleTarifairesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getGrilleTarifaires(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async print(filters, sorts, pagination) {
        const url = `${serviceUrl}/print`;
        const res = await this.$http.get(url, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            },
            responseType: 'arraybuffer'
        });
        return res;
    }
}
