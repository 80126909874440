export default function Configure($stateProvider) {
    $stateProvider.state('facteur-emission-co2', {
        parent: 'common',
        url: '/facteur-emission-co2',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('facteur-emission-co2.list', {
        url: '/list',
        template: '<facteur-emission-co2></facteur-emission-co2>',
        rights: { domain: 'betonco2', right: 'create' },
        ncyBreadcrumb: {
            parent: 'facteur-emission-co2',
            label: '{{ "FACTEUR-EMISSION-CO2.BREADCRUMBS.FACTEUR-EMISSION-CO2_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'FACTEUR-EMISSION-CO2.BREADCRUMBS.FACTEUR-EMISSION-CO2_LIST',
            order: 75
        },
        search: 'FACTEUR-EMISSION-CO2.BREADCRUMBS.FACTEUR-EMISSION-CO2_LIST'
    });
}

Configure.$inject = ['$stateProvider'];
