import IndicesRevisionConfig from './_config/indices.revision.config';
import IndicesRevisionRoutes from './_config/indices.revision.routes';
import RevisionsPrixRoutes from './_config/revisions.prix.routes';

import IndicesRevisionService from './services/indices.revision.service';

import IndicesRevisionComponent from './components/indices-revision';
import IndiceRevisionDetailComponent from './components/indice-revision-detail';
import IndiceRevisionFormComponent from './components/indice-revision-form';
import IndiceRevisionValidator from './components/indice-revision-form/indice.revision.validator';
import IndicesRevisionGenerationComponent from './components/indices-revision-generation';
import IndicesRevisionGenerationValidator from './components/indices-revision-generation/indices.revision.generation.validator';
import IndicesRevisionResultatComponent from './components/indices-revision-resultat';

const moduleName = 'app.massia.gestion.indices.revision';

angular
    .module(moduleName, [])
    .config(IndicesRevisionConfig)
    .config(IndicesRevisionRoutes)
    .config(RevisionsPrixRoutes)
    .service('IndicesRevisionService', IndicesRevisionService)
    .factory('IndiceRevisionValidator', IndiceRevisionValidator)
    .factory('IndicesRevisionGenerationValidator', IndicesRevisionGenerationValidator)
    .component('indicesRevision', IndicesRevisionComponent)
    .component('indiceRevisionForm', IndiceRevisionFormComponent)
    .component('indiceRevisionDetail', IndiceRevisionDetailComponent)
    .component('indicesRevisionGeneration', IndicesRevisionGenerationComponent)
    .component('indicesRevisionResultat', IndicesRevisionResultatComponent);

export default moduleName;
