import GestionStocksProductionController from './gestion.stocks.production.controller';

export default {
    bindings: {
        ligne: '=',
        arrondi: '=',
        modalInstance: '='
    },
    template: require('./gestion.stocks.production.html'),
    controller: GestionStocksProductionController
};
