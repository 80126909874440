export default function Configure($stateProvider) {
    $stateProvider.state('tracteurs', {
        parent: 'gestion',
        url: '/tracteurs',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.TRANSPORT" | translate }}'
        }
    });

    $stateProvider.state('tracteurs.list', {
        url: '/list',
        template: '<tracteurs></tracteurs>',
        rights: { domain: 'vehicule', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "TRACTEURS.BREADCRUMBS.TRACTEURS_LIST" | translate}}'
        },
        navigation: {
            menu: 'vehicule',
            translate: 'TRACTEURS.BREADCRUMBS.TRACTEURS_LIST',
            order: 9
        },
        search: 'TRACTEURS.BREADCRUMBS.TRACTEURS_LIST'
    });
}

Configure.$inject = ['$stateProvider'];
