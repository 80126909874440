import * as angular from "angular";
import { IAugmentedJQuery } from "angular";
import tippy, { Placement } from "tippy.js/headless";
import "tippy.js/animations/scale.css";
import { Directive, ElementRef, Input, OnDestroy, OnInit } from "../../../core/decorators";

@Directive({ selector: '[masPopover]' })
export class MassiaPopover implements OnInit, OnDestroy {
    @Input('@') masPopoverTitle: string;
    @Input() masPopoverTrigger: string;
    @Input() masPopoverTemplate: string;
    @Input() masPopoverContent: string;
    @Input() masPopoverPlacement: Placement;

    /* @ngInject */
    constructor(public $element: IAugmentedJQuery, public $templateCache: ng.ITemplateCacheService, public $compile: ng.ICompileService, public $scope: ng.IScope) { }

    ngOnInit(): void {
        var template = `<div style="text-align: center;"><h5>${this.masPopoverTitle}</h5></div>`;
        console.log(this.$element, this.$element[0]);
        tippy(this.$element[0], {
            placement: this.masPopoverPlacement || 'top',
            trigger: this.masPopoverTrigger || 'click',
            allowHTML: this.masPopoverTemplate ? true : false,
            appendTo: 'parent',
            content: template + (this.masPopoverTemplate
                ? this.$templateCache.get(this.masPopoverTemplate)
                : this.masPopoverContent),
            render: (instance) => {
                const popper = document.createElement('div');
                const box = document.createElement('div');
                popper.appendChild(box);
                $(popper).addClass('bg-primary')
                    .addClass('mas-p-5')
                    .css('box-shadow', '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)')
                    .css('border-radius', '5px')
                    .css('text-align', 'start');
                const com = this.$compile(instance.props.content.toString())(this.$scope);
                $(box).append(com);
                return {
                    popper
                };
            },
        });
    }

    ngOnDestroy(): void {

    }
}
