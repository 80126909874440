import SousMesuresSelectController from './sous.mesures.select.controller';

export default {
    template: require('./sous.mesures.select.tpl.html'),
    controller: SousMesuresSelectController,
    bindings: {
        disabled: '<',
        clearOnSelect: '<',
        selected: '=',
        excludes: '<',
        onSelect: '&',
        onDelete: '&',
        onDeleteAll: '&',
        domaine: '<',
        typeMesure: '<',
        typeFiltreTheme: '<',
        idFamille: '<',
        reset: '&'
    }
};
