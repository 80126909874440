import OffreValidationNiveau from './offre.validation.niveau.controller';

export default {
	bindings: {
		form: '=',
		profils: '=',
		typesProduit: '=',
		unites: '=',
		copyArray: '=',
		validationNiveau: '=',
		changeModelSeuilVolume: '=',
		niveauSelection: '<',
		isEditMode: '=',
		deleteNiveau: '=',
		getLibelleProfil: '='
	},
	controller: OffreValidationNiveau,
	template: require('./offre.validation.niveau.html')
};
