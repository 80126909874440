import { Injectable } from "../../../core/decorators";
export enum DebugLevel {
    LOG = 0,
    DEBUG = 1,
    INFO = 2,
    WARN = 3,
    ERROR = 4,
    DISABLE = 5
}

interface LogItem {
    time: string;
    message: any;
    type: DebugLevel;
}

@Injectable('Logger')
export class LoggerService {
    private level: DebugLevel;
    private logger: ng.ILogService;
    private m: any;
    /* @ngInject */
    constructor($log: ng.ILogService, moment: any) {
        this.logger = $log;
        this.m = moment;
        if(process.env.NODE_ENV === 'development') {
            this.level = 0;
        } else {
            this.level = (<any>__configuration)['debug'] ?? 5
        }
    }

    log(level: DebugLevel, args: any) {
        if(this.level > level) {
            return;
        }
        const item: LogItem = {
            time: this.m(new Date()).format('DD/MM/YYYY HH:mm:ss'),
            message: args,
            type: level
        }
        switch (level) {
            case DebugLevel.LOG:
                this._log(item);
                break;
            case DebugLevel.DEBUG:
                this.debug(item);
                break;
            case DebugLevel.INFO:
                this.info(item);
                break;
            case DebugLevel.WARN:
                this.warn(item);
                break;
            case DebugLevel.ERROR:
                this.error(item);
                break;
            default:
                break;
        }
    }

    private debug(args: any) {
        this.logger.debug(args);
    }

    private warn(args: any) {
        this.logger.warn(args);
    }

    private info(args: any) {
        this.logger.info(args);
    }

    private _log(args: any) {
        this.logger.log(args);
    }

    private error(args: any) {
        this.logger.error(args);
    }
}