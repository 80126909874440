import { Profil } from '../../../../../core/models/Profil';
import { Type } from '../../../../../core/models/type/Type';
import { Unite } from '../../../../../core/models/Unite';

export interface ValidationDevis {
    id: number;
    idProfilAControler: number;
    isActif: boolean;
    validationDevisNiveaux?: ValidationDevisNiveaux[];
}

export interface ValidationDevisNiveaux {
    id: number;
    niveau: number;
    idProfilDeValidation: number;
    modeSeuilMontant: ModeSeuil | string;
    valeurMinMontant: number;
    valeurMaxMontant?: number;
    modeSeuilVolume: ModeSeuil | string;
    valeurMinVolume: number;
    valeurMaxVolume?: number;
    unite: Partial<Unite>;
    isSeuilVolume: boolean;
    isSeuilRemiseProduit: boolean;
    isSeuilMontant: boolean;
    validationDevisProduits: ValidationDevisProduits[];
}
export interface ValidationDevisProduits {
    id: number;
    typeProduit: Type[];
    modeSeuil: ModeSeuil | string;
    valeurMin: number;
    valeurMax?: number;
}

export enum ModeSeuil {
    ENTRE = '1',
    SUPERIEUR_A = '2'
}

export interface ProfilSelected extends Profil {
    selected: boolean;
}
