const serviceUrl = `${__configuration.apiUrl}/massia/nomenclature`;

export default class NomenclaturesService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getNomenclatures(isEnabled, filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				isEnabled: JSON.stringify(isEnabled),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});
		return res.data;
	}

	async deleteNomenclatureById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async activeNomenclatureById(id) {
		const url = `${serviceUrl}/activate/${id}`;
		return this.$http.put(url);
	}

	async getNomenclatureById(id) {
		const url = `${serviceUrl}/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async createNomenclature(nomenclature) {
		const data = nomenclature.entete;
		const result = await this.$http.post(serviceUrl, data);
		return result.data;
	}

	async updateNomenclature(nomenclature) {
		const data = nomenclature.entete;
		const url = `${serviceUrl}/${data.id}`;
		return this.$http.put(url, data);
	}

	async codeExists(code) {
		const url = `${serviceUrl}/code-unicity/${code}`;
		const result = await this.$http.get(url);
		return result.data;
	}
}
