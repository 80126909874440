import SiteAccreditationController from './site.accreditation.controller';

export default {
    bindings: {
        // on bind avec '=' (double binding)
        // quand on veut pouvoir modifier l'objet des deux cotés (appelant et component)
        site: '<',
        // on bind avec '<' (binding one way)
        // quand on ne veut pas pouvoir modifier l'objet dans le component (seulement dans l'appelant)
        // typeId: '<',
        // On bind une fonction avec '&'
        // hook mis à disposition par le component
        // fonction qu'appellera le component lorsqu'il voudra envoyer les nouvelles données à son parent
        // onUpdate: '&',
        // form: '='
        certificats: '='
    },
    template: require('./site.accreditation.html'),
    controller: SiteAccreditationController
    // si on ne déclare pas de 'controllerAs', par défaut ce sera '$ctrl'
};
