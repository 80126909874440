import validatorBuilder from 'validator-builder';

let parametreTransportValidator = null;

export default class ParametreTransport {
    constructor(data = {}) {
        this.id = data.id;
        this.idSociete = data.idSociete;
        this.idTypeBenne = data.idTypeBenne;
        this.libelleTypeBenne = data.libelleTypeBenne;
        this.idTransport = data.idTransport;
        this.libelleTransport = data.libelleTransport;
        this.quantiteMinimum = data.quantiteMinimum;
        this.quantiteMaximum = data.quantiteMaximum;
        this.unite = data.unite;
    }

    isValid() {
        parametreTransportValidator = parametreTransportValidator || validatorBuilder.getInstanceFor('ParametreTransport');
        const validationResults = parametreTransportValidator.validate(this);
        return validationResults.isValid;
    }
}
