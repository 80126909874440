export default class SyntheseEntityResistanceController {
	/*@ngInject */
	constructor(
		$scope,
		$state,
		$rootScope,
		$stateParams,
		SyntheseEntitiesCommunicationService,
		_,
		notification,
		MassiaApplicationService,
		$translate,
		SyntheseEntitiesService,
		NormesService,
		ReferencesControleService,
		ProduitsService,
		$filter,
		ColorPickerService,
		$uibModal,
		moment,
		globalizationManagementService,
		TemplateImpressionService,
		Interpreteur,
		ParametreProgrammeService,
		CompositionsServices
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$rootScope = $rootScope;
		this.$stateParams = $stateParams;
		this.$filter = $filter;
		this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
		this._ = _;
		this.notification = notification;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$translate = $translate;
		this.SyntheseEntitiesService = SyntheseEntitiesService;
		this.NormesService = NormesService;
		this.ReferencesControleService = ReferencesControleService;
		this.ProduitsService = ProduitsService;
		this.ColorPickerService = ColorPickerService;
		this.$uibModal = $uibModal;
		this.moment = moment;
		this.TemplateImpressionService = TemplateImpressionService;
		this.globalizationManagementService = globalizationManagementService;
		this.Interpreteur = Interpreteur;
		this.ParametreProgrammeService = ParametreProgrammeService;
		this.CompositionsServices = CompositionsServices;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async $onInit() {
		try {
			this.unregisterReinit = this.SyntheseEntitiesCommunicationService.registerReinit(() => this.initForm());
			this.Interpreteur.init(undefined, undefined);
			this.Interpreteur.calculatedFormules = [];
			this.carBetClResist = await this.ParametreProgrammeService.getOneByCode('CarBetClResist');
			this.form = {};
			this.ongletOpen = { carteControle: true };
			this.model = this.$state.params.obj || {};
			this.resultat = {};
			this.betonsRef = [];
			this.idsPrelevementExclureTransOnglet = [];

			if (this.model && this.model.essais) {
				let nbPermutationMax = this.model.essais.length;
				const lstPermutedEssais = [];
				while (nbPermutationMax > 0) {
					const controleEssai = this.model.essais[nbPermutationMax - 1];
					if (controleEssai.isComplementaire && lstPermutedEssais.indexOf(controleEssai.idEssai) < 0) {
						this.updateEssaiPosition(nbPermutationMax - 1);
						lstPermutedEssais.push(controleEssai.idEssai);
					} else {
						this.deleteSousEssaisOfEssai(nbPermutationMax - 1);
						nbPermutationMax--;
					}
				}

				this.startLoading();
				this.resultat = await this.preparePrelevements(this.model.synthese.isCritere2ProduitCom);
				this.conformites = [];
				for (let i = 0; i < this.model.formattedResult.entete.titresEssais.length; i++) {
					const ess = this.model.formattedResult.entete.titresEssais[i];
					const mainSsEss = this.model.formattedResult.entete.titresSousEssais.find(
						(e) => e.idEssai === ess.id && e.codeSsEss === ess.code
					);
					ess.displayTitle = mainSsEss.displayTitle;
					ess.unite = mainSsEss.unite;
					if (!ess.idEssai) {
						ess.idEssai = ess.id;
					}

					if (ess.isForControle) {
						const essaiConformite = await this.prepareConformitesData(ess, this.model.synthese.isCritere2ProduitCom);
						this.model.formattedResult.entete.titresEssais[i].controle = ess.controle;
						if (essaiConformite) {
							//si conformité et nb valeurs totale à l'étude > 0
							essaiConformite.graphData = await this.prepareGraphData(ess.id, ess.code, ess.libelle);

							this.conformites.push(essaiConformite);
						}
					}
				}
				this.stopLoading();
			} else {
				await this.endResistance();
			}
			//Verification de la règle de la formule de reference
			if (this.resultat && this.resultat.familles && this.resultat.familles[0]) {
				const regleTranspoFormuleRefName = angular.copy(this.resultat.familles[0].regleTranspoFormuleRefName);
				const isBetonRefResistanceVisee = regleTranspoFormuleRefName.includes('RVREF');
				const isBetonRefResistanceEstimee = regleTranspoFormuleRefName.includes('REREF');

				if (
					(isBetonRefResistanceVisee && (this.betonsRef.length <= 0 || !this.betonsRef[0].resistanceViseeBetonRef)) ||
					(isBetonRefResistanceEstimee && (this.betonsRef.length <= 0 || !this.betonsRef[0].resistanceEstimeeBetonRef))
				) {
					this.$uibModal.open({
						template: `<div class="modal-body" id="modal-body">
                                <div ng-if="!$ctrl.loading">
                                    <h4 class="modal-title" id="modal-title"><strong>
                                    <span translate="{{$ctrl.isRV ? 'SYNTHENTS.MODAL_ALERT.TITLE_RV': 'SYNTHENTS.MODAL_ALERT.TITLE_RE'}}"></span>
                                    </strong></h4>
                                    <div class="alert alert-danger" style="padding:10px; margin:10px 0;">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <span translate="{{$ctrl.isRV ? 'SYNTHENTS.MODAL_ALERT.BODY_RV': 'SYNTHENTS.MODAL_ALERT.BODY_RE'}}"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-primary" type="button" ng-click="$ctrl.close()">
                                        <span class="massia-icon-door_exit"></span>
                                        <span class="hidden-xs" translate="FERMER"></span>
                                    </button>
                                </div>
                            </div>`,
						controller: [
							'$uibModalInstance',
							function ($uibModalInstance) {
								this.uibModalInstance = $uibModalInstance;
								this.close = () => $uibModalInstance.dismiss();
								this.isRV = isBetonRefResistanceVisee;
							}
						],
						controllerAs: '$ctrl',
						size: 'xl'
					});
				}
			}
		} catch (err) {
			console.error(err);
		}
	}
	//pour mise à jour la liste des prélèvements quand on exclure un ou plusieurs PVs entre les onglets(Famille/Béton)
	async updatePvExclure(idPrelevement) {
		if (idPrelevement) {
			this.idsPrelevementExclureTransOnglet.push(idPrelevement);
		}
	}
	//pour mise à jour la liste des prélèvements quand on inclure un ou plusieurs PVs entre les onglets(Famille/Béton)
	async updatePvInclure(idPrelevement) {
		if (idPrelevement) {
			const pIndex = this.idsPrelevementExclureTransOnglet.findIndex(function (e) {
				return e === idPrelevement;
			});
			this.idsPrelevementExclureTransOnglet.splice(pIndex, 1);
		}
	}

	//mise à jour la liste des prélèvements par rapport les produits
	async updatePvsInResualt() {
		if (this.idsPrelevementExclureTransOnglet.length > 0) {
			this.idsPrelevementExclureTransOnglet.forEach((idPrelevement) => {
				const modelLigne = this.resultat.lignes.find(function (e) {
					return e.idPrelevement == idPrelevement;
				});
				if (modelLigne) {
					modelLigne.excluded = true;
				}
				this.resultat.produits.forEach((produit) => {
					this.resultat[produit.id].lignes.forEach(function (e) {
						if (e.idPrelevement == idPrelevement) {
							e.excluded = true;
						}
					});
					const pIndex = produit.idsPrelevement.findIndex(function (e) {
						return e === idPrelevement;
					});
					produit.idsPrelevement.splice(pIndex, 1);
				});
			});
			const resultat = angular.copy(this.resultat);
			this.$rootScope.$broadcast('listePvsAExclure', resultat);
		}
	}
	updateEssaiPosition(position) {
		const mainEssai = this.model.essais[position];
		mainEssai.isForControle = true;
		this.model.essais.splice(position, 1);
		this.model.essais.splice(0, 0, mainEssai);

		const titreEssai = this.model.formattedResult.entete.titresEssais[position];
		titreEssai.isForControle = true;
		this.model.formattedResult.entete.titresEssais.splice(position, 1);
		this.model.formattedResult.entete.titresEssais.splice(0, 0, titreEssai);

		for (let i = this.model.formattedResult.entete.titresSousEssais.length - 1; i >= 0; i--) {
			const titreSsEssai = this.model.formattedResult.entete.titresSousEssais[i];
			if (titreSsEssai && titreSsEssai.idEssai === mainEssai.idEssai && titreSsEssai.codeSsEss === mainEssai.codeEssai) {
				titreSsEssai.isForControle = true;
				this.model.formattedResult.entete.titresSousEssais.splice(i, 1);
				this.model.formattedResult.entete.titresSousEssais.splice(0, 0, titreSsEssai);
			} else if (titreSsEssai && titreSsEssai.idEssai === mainEssai.idEssai && titreSsEssai.codeSsEss !== mainEssai.codeEssai) {
				this.model.formattedResult.entete.titresSousEssais.splice(i, 1);
			}
		}

		for (let i = this.model.sousEssaisTamis.length - 1; i >= 0; i--) {
			const ssTamis = this.model.sousEssaisTamis[i];
			const codeSsEss = ssTamis.libelle.split(' / ')[0];
			if (ssTamis && ssTamis.idEssai === mainEssai.idEssai && codeSsEss === mainEssai.codeEssai) {
				ssTamis.isForControle = true;
				this.model.sousEssaisTamis.splice(i, 1);
				this.model.sousEssaisTamis.splice(0, 0, ssTamis);
			} else if (ssTamis && ssTamis.idEssai === mainEssai.idEssai && codeSsEss !== mainEssai.codeEssai) {
				this.model.sousEssaisTamis.splice(i, 1);
			}
		}

		this.model.formattedResult.lignes.map(function (l) {
			for (let i = l.valeursEssais.length - 1; i >= 0; i--) {
				const valEssai = l.valeursEssais[i];
				if (valEssai && valEssai.idEssai === mainEssai.idEssai && valEssai.codeSousEssai === mainEssai.codeEssai) {
					valEssai.isForControle = true;
					l.valeursEssais.splice(i, 1);
					l.valeursEssais.splice(0, 0, valEssai);
				} else if (valEssai && valEssai.idEssai === mainEssai.idEssai && valEssai.codeSousEssai !== mainEssai.codeEssai) {
					l.valeursEssais.splice(i, 1);
				}
			}
		});

		this.model.formattedResult.stats.map(function (s) {
			for (let i = s.statsEssais.length - 1; i >= 0; i--) {
				const valStat = s.statsEssais[i];
				if (valStat && valStat.idEssai === mainEssai.idEssai && valStat.codeSousEssai === mainEssai.codeEssai) {
					valStat.isForControle = true;
					s.statsEssais.splice(i, 1);
					s.statsEssais.splice(0, 0, valStat);
				} else if (valStat && valStat.idEssai === mainEssai.idEssai && valStat.codeSousEssai !== mainEssai.codeEssai) {
					s.statsEssais.splice(i, 1);
				}
			}
		});

		this.mainEssai = mainEssai;
	}

	deleteSousEssaisOfEssai(position) {
		const mainEssai = this.model.essais[position];

		for (let i = this.model.formattedResult.entete.titresSousEssais.length - 1; i >= 0; i--) {
			const titreSsEssai = this.model.formattedResult.entete.titresSousEssais[i];
			if (titreSsEssai && titreSsEssai.idEssai === mainEssai.idEssai && titreSsEssai.codeSsEss !== mainEssai.codeEssai) {
				this.model.formattedResult.entete.titresSousEssais.splice(i, 1);
			}
		}

		for (let i = this.model.sousEssaisTamis.length - 1; i >= 0; i--) {
			const ssTamis = this.model.sousEssaisTamis[i];
			const codeSsEss = ssTamis.libelle.split(' / ')[0];
			if (ssTamis && ssTamis.idEssai === mainEssai.idEssai && codeSsEss !== mainEssai.codeEssai) {
				this.model.sousEssaisTamis.splice(i, 1);
			}
		}

		this.model.formattedResult.lignes.map(function (l) {
			for (let i = l.valeursEssais.length - 1; i >= 0; i--) {
				const valEssai = l.valeursEssais[i];
				if (valEssai && valEssai.idEssai === mainEssai.idEssai && valEssai.codeSousEssai !== mainEssai.codeEssai) {
					l.valeursEssais.splice(i, 1);
				}
			}
		});

		this.model.formattedResult.stats.map(function (s) {
			for (let i = s.statsEssais.length - 1; i >= 0; i--) {
				const valStat = s.statsEssais[i];
				if (valStat && valStat.idEssai === mainEssai.idEssai && valStat.codeSousEssai !== mainEssai.codeEssai) {
					s.statsEssais.splice(i, 1);
				}
			}
		});
	}

	$onDestroy() {
		this.unregisterReinit();
	}

	async prepareControle(idEssai) {
		const that = this;

		let controle = {};

		//On récupère la référence, la norme si pas de correspondance entre la référence et l'essai courant
		if (this.model.synthese.idReference && (!controle.specs || controle.specs.length <= 0)) {
			if (!this.controleReference) {
				this.controleReference = await this.ReferencesControleService.getReferenceById(this.model.synthese.idReference);
			}
			controle = angular.copy(this.controleReference);

			controle.specs = controle.mesures
				? angular.copy(
						controle.mesures.filter(function (e) {
							return e.idEssai === idEssai;
						})
				  )
				: [];
			delete controle.mesures;
		}
		if (this.model.synthese.idNorme && (!controle.specs || controle.specs.length <= 0)) {
			if (!this.controleNorme) {
				this.controleNorme = await this.NormesService.getNormeById(this.model.synthese.idNorme);
				this.controleNorme.specs = await this.NormesService.getSpecifications(this.controleNorme.id);
			}
			controle = angular.copy(this.controleNorme);

			const specFinal = [];
			for (let i = 0; i < controle.specs.length; i++) {
				for (let j = 0; j < controle.specs[i].essais.length; j++) {
					const obj = controle.specs[i].essais[j];
					if (obj.idEssai === idEssai) {
						obj.codeEssai = obj.code;
						obj.calculFormule = obj.formuleCalcStat;
						obj.codeGamme = controle.specs[i].codeGamme;
						specFinal.push(obj);
					}
				}
			}
			controle.specs = specFinal;
		}

		if (!controle.specs || controle.specs.length <= 0) {
			controle = {};
		} else {
			controle.specs = controle.specs.map((s) => {
				let codeSsEss = this.model.formattedResult.entete.titresSousEssais.find(
					(e) => e.idEssai === idEssai && e.id === s.idSousEssai
				).codeSsEss;
				s.codeSousEssai = codeSsEss;

				if (s.essais) {
					codeSsEss = this.model.formattedResult.entete.titresSousEssais.find(
						(e) => e.idEssai === idEssai && e.id === s.idSousEssai
					).codeSsEss;
					s.essais = s.essais.map((e) => {
						e.codeSousEssai = codeSsEss;
						return e;
					});
				}

				return s;
			});
		}

		if (this.model.synthese.NQA) {
			controle.nqa = this.model.synthese.NQA[0];
		}

		return controle;
	}

	async prepareProduits(isCritere2ProduitCom) {
		const produits = [];
		if (this.model) {
			try {
				// on met a jour les donnees du scope 'resultat'
				this.resultat = {};
				for (let i = 0; i < this.model.resultat.prelevementBlock.length; i++) {
					const prelev = this.model.resultat.prelevementBlock[i];
					if (prelev.codeFamille && prelev.codeFamille == 'BPE') {
						let findProduit = produits.find(function (e) {
							if (isCritere2ProduitCom) {
								return e.idProduitCom === prelev.idProduitCom;
							}
							return e.idProduit === prelev.idProduit;
						});
						if (!findProduit) {
							findProduit = {
								id: isCritere2ProduitCom ? prelev.idProduitCom : prelev.idProduit,
								idProduit: prelev.idProduit,
								idProduitCom: prelev.idProduitCom,
								idsPrelevement: [prelev.idPrelevement],
								code: isCritere2ProduitCom ? prelev.codeProduitCom : prelev.codeProduit,
								code2: isCritere2ProduitCom ? prelev.code2ProduitCom : prelev.code2Produit,
								libelle: isCritere2ProduitCom ? prelev.libelleProduitCom : prelev.libelleProduit,
								famille: prelev.familleBetonProduit,
								savedETA: prelev.compoEcartTypeAdopte,
								currentETA: prelev.compoEcartTypeAdopte, //0
								CompoCourante: {
									id: prelev.composition.id,
									rv: prelev.composition.resistanceVisee,
									rvSaisie: null,
									dateCreation: this.moment(prelev.composition.dateCreation).format(this.dateFormat)
								}
							};
							//findProduit.caracteristiques = await this.ProduitsService.getProduitValeursCaracteristiquesById(prelev.idProduit);
							isCritere2ProduitCom ? (this.ongletOpen[prelev.idProduitCom] = true) : (this.ongletOpen[prelev.idProduit] = true);
							produits.push(findProduit);
						} else {
							findProduit.idsPrelevement.push(prelev.idPrelevement);
						}
					}

					if (
						prelev.familleBetonProduit.isBetonReference &&
						this.betonsRef.findIndex((e) => e.familleLibelle === prelev.familleBetonProduit.libelleFamille) === -1
					) {
						this.betonsRef.push({
							libelle: prelev.libelleProduit,
							code: prelev.codeProduit,
							familleLibelle: prelev.familleBetonProduit.libelleFamille,
							resistanceViseeBetonRef: this.model.resultat.resistanceViseeBetonRef,
							resistanceEstimeeBetonRef: this.model.resultat.resistanceEstimeeBetonRef,
							regleTranspoFormuleRefLibelle: prelev.familleBetonProduit.regleTranspoLibelle,
							regleTranspoFormuleRefFormule: prelev.familleBetonProduit.regleTranspoFormuleRefName
						});
					}
				}
			} catch (err) {
				console.log(err.data);
			}
		} else {
			this.$state.go('syntheseentities.list');
		}
		return produits;
	}

	async preparePrelevements(isCritere2ProduitCom) {
		const resultat = {};
		resultat.entete = { titresAffichage: [], titresEssais: [], titresSousEssais: [] };
		resultat.lignes = [];
		resultat.stats = [];
		resultat.produits = await this.prepareProduits(isCritere2ProduitCom);
		resultat.familles = [];
		const that = this;

		resultat.entete.titresAffichage = this.model.formattedResult.entete.titresAffichage;
		resultat.entete.titresEssais = this.model.formattedResult.entete.titresEssais;
		//MN: Affichage de stats (non-impromable) sur web
		//resultat.stats = this.model.formattedResult.stats.filter(function (e) { return e.isImprimable ; });
		resultat.stats = this.model.formattedResult.stats;

		resultat.entete.titresSousEssais = this.model.formattedResult.entete.titresSousEssais;
		let lastProductId = null;
		const lignes = [];
		for (let l = 0; l < this.model.formattedResult.lignes.length; l++) {
			const line = this.model.formattedResult.lignes[l];
			const isOkForControl = line.valeursEssais.findIndex((e) => e.isForControle && e.valeur) >= 0;
			if (isOkForControl) {
				const prelev = resultat.produits.find(function (p) {
					return p.idsPrelevement.indexOf(line.idPrelevement) >= 0;
				});
				if (prelev) {
					const prelevement = this.model.resultat.prelevementBlock.find(function (p) {
						return p.idPrelevement === line.idPrelevement;
					});

					line.nbProduits = resultat.produits.length;

					if (isCritere2ProduitCom) {
						if (prelevement.idProduitCom !== lastProductId) {
							line.position = 1;
						}
					} else {
						if (prelevement.idProduit !== lastProductId) {
							line.position = 1;
						}
					}

					lastProductId = isCritere2ProduitCom ? prelevement.idProduitCom : prelevement.idProduit;
					line.idProduit = prelevement.idProduit;
					line.idProduitCom = prelevement.idProduitCom;
					/**TODO caractCC */
					line.caractProduit = await this.ProduitsService.getProduitValeursCaracteristiquesById(prelev.idProduit);
					line.caractProduitCom =
						prelevement.idProduitCom > 0
							? await this.ProduitsService.getProduitValeursCaracteristiquesById(prelevement.idProduitCom)
							: null;
					line.codePrelevement = prelevement.codePrelevement;
					line.codeProduit = prelevement.codeProduit;
					line.code2Produit = prelevement.code2Produit;
					line.code2ProduitCom = prelevement.code2ProduitCom;
					line.libelleProduit = prelevement.libelleProduit;
					line.codeProduitCom = prelevement.codeProduitCom;
					line.libelleProduitCom = prelevement.libelleProduitCom;
					line.datePrelevement = prelevement.datePrelevement;
					line.famille = prelevement.familleBetonProduit;
					line.fck = undefined;
					line.dicEssaisCompoFck = prelevement.dicEssaisCompoFck;

					if (line.famille) {
						const famille = resultat.familles.find(function (f) {
							return f.idFamille === line.famille.idFamille;
						});
						if (!famille) {
							line.famille.savedETA = line.famille.ecartType ? line.famille.ecartType.toFixed(2) : '';
							line.famille.currentETA = parseFloat(line.famille.savedETA); //0;
							resultat.familles.push(line.famille);
							this.ongletOpen[line.famille.idFamille] = true;
						}
					}

					lignes.push(line);
				}
			}
		}

		resultat.lignes = lignes;
		return angular.copy(resultat);
	}

	async update(idEssai, codeEssai, libelleEssai) {
		const graphData = await this.prepareGraphData(idEssai, codeEssai, libelleEssai);
		const essConf = await this.conformites.find(function (e) {
			return e.idEssai === idEssai;
		});
		essConf.graphData = graphData;
	}

	async prepareGraphData(idEssai, codeEssai, libelleEssai) {
		const graphData = { Abscisse: [], titreX: this.$translate.instant('SYNTHENTS.DATE'), titreY1: libelleEssai };
		//1/Récupérer les tamis à afficher
		const lignes = this.resultat.lignes;
		if (lignes && lignes.length > 0) {
			for (let l = 0; l < lignes.length; l++) {
				const ligne = lignes[l];
				//2/Récupérer les données de chaque prélèvement
				if (ligne && !ligne.excluded && ligne.valeursEssais) {
					const sse = ligne.valeursEssais.find((e) => e.idEssai === idEssai && e.codeSousEssai === codeEssai);
					const produit = this.resultat.produits.find(function (e) {
						return e.idsPrelevement.indexOf(ligne.idPrelevement) >= 0;
					});

					if (sse && produit) {
						const date = this.moment(ligne.datePrelevement, this.dateFormat);

						let dateIndex = graphData.Abscisse.findIndex((e) => e.value.format(this.dateFormat) === date.format(this.dateFormat));
						if (dateIndex < 0) {
							graphData.Abscisse.push({ label: this.$translate.instant('SYNTHENTS.DATE'), value: date });
							dateIndex = graphData.Abscisse.length - 1;
						}

						const key = this.model.synthese.isCritere2ProduitCom ? produit.idProduitCom : produit.idProduit;
						if (!graphData['courbe_' + key]) {
							graphData['courbe_' + key] = {
								label: produit.libelle,
								idEssai: sse.idEssai,
								idSousEssai: sse.idSousEssai,
								values: [],
								hidden: sse.hidden
							};
						}

						for (const o in graphData) {
							if (o.startsWith('courbe_')) {
								const iValues = graphData[o].values.length;
								for (let i = iValues; i <= dateIndex; i++) {
									if (!graphData[o].values[i]) {
										graphData[o].values.push({
											pourcPassant: '',
											pourcPassantArrondi: '',
											pourcRefPartiel: '',
											pourcRefPartielArrondi: ''
										});
									}
								}
							}
						}

						graphData['courbe_' + key].values[dateIndex].pourcPassant = parseFloat(sse.valeur);
						graphData['courbe_' + key].values[dateIndex].pourcPassantArrondi = Math.round(sse.valeur, 2).toFixed(0);
						graphData['courbe_' + key].values[dateIndex].pourcRefPartiel = '';
						graphData['courbe_' + key].values[dateIndex].pourcRefPartielArrondi = '';
					}
				}
			}

			graphData.nbElement = graphData.Abscisse.length;
			graphData.idEssai = idEssai;
			graphData.style = { height: '300px' };
			graphData.override = {
				chartType: 'line',
				fill: false,
				typeAxeX: 'linear',
				typeAxeY: 'linear',
				displayAllAbscisses: true,
				spanGaps: true,
				positionLegende: 'bottom'
			};
		}

		return graphData;
	}

	chartLoaded(index) {
		this['hiddeInMainPage' + index] = true;
	}

	async prepareConformitesData(essai, isCritere2ProduitCom) {
		const conformites = {
			idEssai: essai.id,
			codeEssai: essai.code,
			libelleEssai: essai.libelle,
			lines: []
		};
		//todo get limite beton idEssai / idCaract / idEltCaractbetonRef
		conformites.lines.push({ title: this.$translate.instant('SYNTHENTS.CART_CTRL_BET_RES.TOTAL'), value: 0 });
		conformites.lines.push({ title: this.$translate.instant('SYNTHENTS.CART_CTRL_BET_RES.CONFORMES'), value: 0 });
		conformites.lines.push({ title: this.$translate.instant('SYNTHENTS.CART_CTRL_BET_RES.ALERTE'), value: 0 });
		conformites.lines.push({ title: this.$translate.instant('SYNTHENTS.CART_CTRL_BET_RES.NON_CONFORMES'), value: 0 });
		conformites.lines.push({ title: this.$translate.instant('SYNTHENTS.CART_CTRL_BET_RES.NIV_QUAL_ACC'), value: 0 });

		if (essai && this.resultat.produits) {
			const controle = await this.prepareControle(essai.id);
			essai.controle = controle;

			for (let p = 0; p < this.resultat.produits.length; p++) {
				const idProduit = isCritere2ProduitCom ? this.resultat.produits[p].idProduitCom : this.resultat.produits[p].idProduit;
				const lines = this.resultat.lignes
					? this.resultat.lignes.filter(function (e) {
							if (isCritere2ProduitCom) {
								return e.idProduitCom === idProduit;
							}
							return e.idProduit === idProduit;
					  })
					: [];

				for (let l = 0; l < lines.length; l++) {
					const ssessai = lines[l].valeursEssais.find(function (e) {
						return e.idEssai === essai.idEssai && e.codeSousEssai === essai.code;
					});
					const allValues = lines.map(function (line) {
						if (line.valeursEssais) {
							const valueEssai = line.valeursEssais.find(function (e) {
								return e.idEssai === essai.idEssai && e.codeSousEssai === essai.code;
							});
							if (valueEssai && valueEssai.valeur) {
								return valueEssai.valeur;
							}
						}
					});
					if (ssessai && ssessai.valeur) {
						const fck =
							lines[l].dicEssaisCompoFck && lines[l].dicEssaisCompoFck[essai.idEssai]
								? lines[l].dicEssaisCompoFck[essai.idEssai]
								: null;
						const fckRef = lines[l].famille ? lines[l].famille.fckBetonReference : fck;

						//stat, valeur, allValues, controle, ecartTypeAdopte, fck, fckref, nbValStat, iArrondi, idEssai, codeEssai
						const resConformite = await this.checkConformite(
							{ formule: '@VAL' },
							ssessai.valeur,
							allValues,
							this.getPrelevementControle(lines[l], essai.idEssai, essai.code, controle),
							null,
							fck,
							fckRef,
							null,
							ssessai.arrondi,
							essai.idEssai,
							essai.code
						);
						ssessai.conformite = resConformite;
						conformites.lines[0].value += 1;
						conformites.lines[1].value += resConformite.isConforme && !resConformite.isEmpty ? 1 : 0;
						conformites.lines[2].value += resConformite.isAlerte && !resConformite.isEmpty ? 1 : 0;
						conformites.lines[3].value += !resConformite.isConforme && !resConformite.isAlerte && !resConformite.isEmpty ? 1 : 0;
					}

					const ssessaiIgnore = lines[l].valeursEssais.filter(function (e) {
						return e.idEssai !== essai.idEssai || e.codeSousEssai !== essai.code;
					});
					ssessaiIgnore.map((e) => {
						if (!e.conformite || e.conformite.constructor !== Object || e.conformite.isEmpty) {
							e.conformite = { isEmpty: true };
						}
						return e;
					});
				}

				const nqa = controle.nqa
					? controle.nqa.details.find(function (e) {
							return e.nbResult2 >= lines.length;
					  })
					: { valeurAcceptee: 0 };
				if (!nqa.valeurAcceptee) {
					nqa.valeurAcceptee = 0;
				}
				conformites.lines[4].value = conformites.lines[3].value <= nqa.valeurAcceptee ? 'Oui' : 'Non';
			}

			for (let p = 0; p < this.resultat.stats.length; p++) {
				const stat = this.resultat.stats[p];

				for (let l = 0; l < stat.statsEssais.length; l++) {
					const valStat = stat.statsEssais[l].valeur;
					const iarrondi = stat.statsEssais[l].arrondi;
					const allValues = this.resultat.lignes.map(function (line) {
						if (line.valeursEssais) {
							const valueEssai = line.valeursEssais.find(function (e) {
								return e.idEssai === essai.idEssai && e.codeSousEssai === essai.code;
							});
							if (valueEssai && valueEssai.valeur) {
								return valueEssai.valeur;
							}
						}
					});

					const resConformite = await this.checkConformite(
						stat /*.formule*/,
						valStat,
						allValues,
						this.getPrelevementControle(null, essai.idEssai, essai.code, controle),
						null,
						null, //line.dicEssaisCompoFck[essai.idEssai].value,
						null, //line.familleBetonProduit.fckBetonReference,
						null,
						iarrondi,
						essai.idEssai,
						essai.code
					);
					stat.statsEssais[l].conformite = resConformite;
				}
			}
		}

		return conformites;
	}

	getPrelevementControle(prelevement, idEssai, codeEssai, controle) {
		const prelevementControle = angular.copy(controle);
		prelevementControle.seuil = null;
		if (prelevement) {
			let caract = null;
			if (prelevement.caractProduitCom) {
				caract = prelevement.caractProduitCom.find(function (e) {
					return prelevementControle.seuils[0] && e.id === prelevementControle.seuils[0].idCaracteristique;
				});
			} else {
				caract = prelevement.caractProduit.find(function (e) {
					return prelevementControle.seuils[0] && e.id === prelevementControle.seuils[0].idCaracteristique;
				});
			}
			if (caract) {
				prelevementControle.seuil = prelevementControle.seuils.find(function (e) {
					return e.codeEltCaract === caract.value.key;
				});
			}
		}
		delete prelevementControle.seuils;

		let controleSpecs = angular.copy(controle.specs);
		if (controleSpecs && controleSpecs.length > 1) {
			const specType = controleSpecs.find((spec) => !(spec.libelleCategorie && spec.libelleCategorie.toLowerCase().startsWith('famille')));
			if (specType && specType.essais) {
				controleSpecs = specType.essais.filter((e) => e.idEssai === idEssai && e.codeSousEssai === codeEssai);
			}
		} else if (controleSpecs && controleSpecs.length == 1 && controleSpecs[0].essais) {
			controleSpecs = controleSpecs[0].essais.filter((e) => e.idEssai === idEssai && e.codeSousEssai === codeEssai);
		}

		prelevementControle.specs = controleSpecs;

		return prelevementControle;
	}

	async checkConformite(stat, valeur, allValues, controle, ecartTypeAdopte, fck, fckref, nbValStat, iArrondi, idEssai, codeEssai) {
		const result = {
			isConforme: true,
			isAlerte: false,
			isEmpty: true,
			limInf: 0,
			limSup: 0,
			alrtInf: 0,
			alrtSup: 0,
			formuleSeuilInf: '',
			formuleSeuilSup: ''
		};
		if (typeof stat !== 'string') {
			delete stat.hide;
		}
		if (controle && controle.specs && controle.specs.length > 0 && typeof stat !== 'string') {
			const controleSpecs = controle.specs.filter(function (spec) {
				return spec.idCalcStat === stat.idCalcStat && spec.idEssai === idEssai && spec.codeSousEssai === codeEssai;
			});
			let controleSpec = undefined;
			if (controleSpecs && controleSpecs.length > 0) {
				//vérifier les conditions
				let iSpec = 0;
				while (iSpec < controleSpecs.length && !controleSpec) {
					let condSpec = controleSpecs[iSpec].condition;
					if (!condSpec) {
						controleSpec = controleSpecs[iSpec];
					} else {
						condSpec = condSpec.replace(/@NBR\(\)/g, nbValStat?.toString());
						condSpec = condSpec.replace(/@NBR/g, nbValStat?.toString());
						const resCondSpec = await this.Interpreteur.calculeFormule(condSpec);
						if (resCondSpec) {
							controleSpec = controleSpecs[iSpec];
						}
					}
					iSpec++;
				}
			}
			const val = iArrondi !== null && iArrondi !== undefined ? parseFloat(this.arrondiValeur(valeur, iArrondi)) : parseFloat(valeur);
			if (val >= 0 && controleSpec) {
				const fAlrtInf = await this.getBorne(
					controleSpec.alerteInferieure,
					allValues,
					controle.seuil,
					ecartTypeAdopte,
					fck,
					fckref,
					iArrondi
				);
				const fAlrtSup = await this.getBorne(
					controleSpec.alerteSuperieure,
					allValues,
					controle.seuil,
					ecartTypeAdopte,
					fck,
					fckref,
					iArrondi
				);
				const fLimInf = await this.getBorne(
					controleSpec.limiteBetonInferieure,
					allValues,
					controle.seuil,
					ecartTypeAdopte,
					fck,
					fckref,
					iArrondi
				);
				const fLimSup = await this.getBorne(
					controleSpec.limiteBetonSuperieure,
					allValues,
					controle.seuil,
					ecartTypeAdopte,
					fck,
					fckref,
					iArrondi
				);
				if (fLimInf >= 0) {
					result.isConforme = fLimInf <= val;
				}

				if (result.isConforme && fLimSup >= fLimInf) {
					result.isConforme = fLimSup >= val;
				}

				if (result.isConforme && !result.isAlerte && fAlrtInf > 0 && fLimInf >= 0) {
					result.isAlerte = fAlrtInf >= val && fLimInf <= val;
				}
				if (result.isConforme && !result.isAlerte && fAlrtSup > fAlrtInf && fLimSup >= fLimInf) {
					result.isAlerte = fAlrtSup <= val && fLimSup >= val;
				}

				result.limInf = Number(fLimInf);
				result.limSup = fLimSup || fLimSup >= fLimInf ? Number(fLimSup) : '';
				result.alrtInf = Number(fAlrtInf);
				result.alrtSup = fAlrtSup || fAlrtSup >= fAlrtInf ? Number(fAlrtSup) : '';
				result.formuleSeuilInf = controleSpec.limiteBetonInferieure?.replace('RCTHEO', 'fck');
				result.formuleSeuilSup = controleSpec.limiteBetonSuperieure?.replace('RCTHEO', 'fck');

				result.isEmpty = false;
			}
		}

		if (result.isEmpty && typeof stat !== 'string') {
			stat.hide = true;
		}

		return result;
	}

	async getBorne(value, values, seuil, ecartTypeAdopte, fck, fckref, iArrondi) {
		let bNonNumerique = false;
		//SP 22/06/20 : vérifie en + qu'il y a du texte dans value, car 'parseFloat(0.77*ETA)' renvoie 0.77 !
		if (
			value &&
			(value.indexOf('VCMIN') > 0 ||
				value.indexOf('VCMAX') > 0 ||
				value.indexOf('ETA') > 0 ||
				value.indexOf('RCTHEO') > 0 ||
				value.indexOf('RCREF') > 0)
		) {
			bNonNumerique = true;
		}
		let dbValue = parseFloat(value);
		if (bNonNumerique || (!dbValue && value)) {
			if (value.indexOf('@') >= 0) {
				const formuleParts = value.split('@');
				for (let i = 0; i < formuleParts.length; i++) {
					let formule = formuleParts[i];
					if (formule.indexOf('(') < 0) {
						//formule = formule.indexOf('(') <= 0 ? formule : formule.substring(0, formule.indexOf('('));
						formule = formule.indexOf('+') <= 0 ? formule : formule.substring(0, formule.indexOf('+'));
						formule = formule.indexOf('-') <= 0 ? formule : formule.substring(0, formule.indexOf('-'));
						formule = formule.indexOf('*') <= 0 ? formule : formule.substring(0, formule.indexOf('*'));
						formule = formule.indexOf('/') <= 0 ? formule : formule.substring(0, formule.indexOf('/'));
						formule = formule.indexOf('^') <= 0 ? formule : formule.substring(0, formule.indexOf('^'));
						formule = formule.indexOf('%') <= 0 ? formule : formule.substring(0, formule.indexOf('%'));
						formule = '@' + formule.trim();
						const idbValue = await this.Interpreteur.calculeFormuleWithParams(formule + '(' + values.join(';') + ')');
						value = value.replace(formule, idbValue /*.toFixed(2)*/);
					}
				}
			}

			value = value.replace(/VCMIN/g, (seuil && seuil.limiteInferieur ? seuil.limiteInferieur : 0).toString());
			value = value.replace(/VCMAX/g, (seuil && seuil.limiteSuperieur ? seuil.limiteSuperieur : 0).toString());
			value = value.replace(/ETA/g, (ecartTypeAdopte ? ecartTypeAdopte : 0).toString());
			value = value.replace(/RCTHEO/g, (fck ? fck : 0).toString());
			value = value.replace(/RCREF/g, (fckref ? fckref : 0).toString());
			dbValue = await this.Interpreteur.calculeFormuleWithParams(value);
			dbValue = this.arrondiValeur(dbValue, iArrondi); //parseFloat(dbValue/*.toFixed(2)*/);
		}

		return dbValue || 0;
	}

	arrondiValeur(valeur, arrondi) {
		const objetToRound = [{ key: valeur, val: valeur, rounder: arrondi }];
		return this.Interpreteur.getRoundedValue(objetToRound);
	}

	openSynthese() {
		const _this = this;
		this.$uibModal
			.open({
				template:
					'<syntheseentities-form modal-instance="$ctrl.uibModalInstance" synth-for-gene="undefined" params-synthese="$ctrl.paramsSynthese"></syntheseentities-form>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.paramsSynthese = _this.model.synthese;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl',
				backdrop: false
			})
			.result.then(
				async function (result) {
					if (result) {
						//result contient une liste (prelevementBlock)
						//TODO: Rafraichir le résultat

						_this.$state.go('syntheseentities.result', { obj: result });
					}
				},
				function (reason) {}
			);
	}

	async reinit() {
		const res = await this.SyntheseEntitiesService.genererSynthese(this.model.synthese, 'laboratoire');
		this.model.resultat = res;
		this.$state.go('syntheseentities.result', { obj: this.model });
	}

	async archiver() {
		const lstFamillesBeton = [];
		if (this.resultat.familles) {
			for (let i = 0; i < this.resultat.familles.length; i++) {
				lstFamillesBeton.push({
					id: this.resultat.familles[i].idFamille,
					dateEtudeDebut: null,
					dateEtudeFin: null,
					ecartType: this.resultat.familles[i].currentETA
				});
			}

			this.endResistance();
		}
	}

	async endResistance() {
		this.$state.go('syntheseentities.list', { obj: this.model });
	}

	async print() {
		try {
			const res = await this.TemplateImpressionService.downloadModal('ccb');
			if (res === 'cancel') {
				throw res;
			}
			this.notification.info(this.$translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
				autoDismiss: false
			});

			let rapport = null;

			if (res.filename.indexOf('Resistance_7') >= 0) {
				//PENPENIC
				rapport = await this.genereInfosPENPENIC(true);
			} else {
				rapport = await this.genereInfos(true);
				rapport.essais.forEach((essai) => {
					const famille = essai.familles[0].conformites.shift();

					// ajoute des stats vides
					const stats = essai.familles[0].conformites.map((x) => x.stats.length);

					const maxStatsLength = Math.max(...stats);
					essai.familles[0].conformites.forEach((c, index) => {
						const idProduit = c.id; //essai.familles[0].betons.find((x) => x.libelle == c.libelle).id;
						const codeLibelle = angular.copy(c.libelle);
						if (codeLibelle.lastIndexOf('(') >= 0) {
							c.libelle = codeLibelle.substring(0, codeLibelle.lastIndexOf('(')).trim();
						}
						c.code = ' (' + c.code + ') ';
						c.code2 = c.code2 !== null && c.code2 !== '' ? ' (' + c.code2 + ') ' : '';
						if (c.stats.length > 0) {
							for (let s = 0; s < c.stats.length; s++) {
								const element = c.stats[s];
								const specs =
									this.resultat[idProduit]?.lignes[this.resultat[idProduit].lignes.length - 1]?.stats[
										`${element.code}_${essai.code}`
									]?.conformite;
								if (specs?.limInf || specs?.limSup) {
									c.stats[s].values.val = {
										value: `${c.stats[s].values.val} [${specs?.limInf}/${specs?.limSup}]`,
										text: specs.isConforme ? '009900' : 'FF0000'
									};
								}
							}
						}
						if (c.stats.length != maxStatsLength) {
							const toAdd = maxStatsLength - c.stats.length;
							for (let i = 0; i < toAdd; i++) {
								c.stats.push({
									values: {
										nb: '',
										nbConforme: '',
										nbNonConforme: '',
										val: ''
									},
									titre: '',
									code: '',
									formule: ''
								});
							}
						}
					});
					essai.famille = {
						...famille,
						regleTranspo: essai.familles[0].regleTranspo,
						ETAAdopte: essai.familles[0].currentETA
					};
					delete essai.familles[0].libelle;
				}, this);
			}
			//recuperation de la periode
			if (this.resultat.lignes.length > 0) {
				rapport.periode = this.model.synthese.listeSyntheseCritereValeurBlocks[0].listeSyntheseCritereValeurLignes.find(
					(x) => x.domaineCode == 'Prelevements' && x.champ == 9
				);
				const datesPrels = this.resultat.lignes.map((x) => x.datePrelevement);
				const isAfter = this.moment(datesPrels[0], 'D-M-YYYY').isAfter(this.moment(datesPrels[datesPrels.length - 1], 'D-M-YYYY'));
				rapport.dateFirst = isAfter ? datesPrels[datesPrels.length - 1] : datesPrels[0];
				rapport.dateLast = isAfter ? datesPrels[0] : datesPrels[datesPrels.length - 1];
			}
			rapport.typeSynthese = this.model.synthese.typeDeSynthese;
			const template = await this.SyntheseEntitiesService.printRapportConformite(rapport, res.filename);
			template.pdf = res.pdf;
			await this.TemplateImpressionService.downloadTemplate(template.filename, template, 'ccb');
		} catch (err) {
			if (err !== 'cancel') {
				this.notification.error(err.data);
			}
		}
	}

	async genereInfos() {
		const that = this;
		const rapport = {};

		const firstPrelTemp = this.model.resultat.prelevementBlock[0];

		//construction en-tête producteur
		const producteur = {};
		producteur.id = firstPrelTemp.idProducteur;
		producteur.libelle = firstPrelTemp.libelleProducteur;
		producteur.societe = firstPrelTemp.societeProducteur;
		producteur.raisonSociale = firstPrelTemp.rsProducteur; // Mantis 6438 JN
		rapport.producteur = producteur;

		const laboratoire = {};
		laboratoire.id = firstPrelTemp.idLaboAudit;
		laboratoire.libelle = firstPrelTemp.libelleLaboAudit;
		laboratoire.societe = firstPrelTemp.societeLaboAudit;
		laboratoire.raisonSociale = firstPrelTemp.rsLaboAudit;
		rapport.laboratoire = laboratoire;

		if (this.model.synthese.isProdBeton) {
			const produit = {
				id: firstPrelTemp.idProduit,
				code: firstPrelTemp.codeProduit,
				libelle: firstPrelTemp.libelleProduit,
				type: firstPrelTemp.typeProduit,
				familleBeton: firstPrelTemp.familleBetonProduit,
				caracteristiques: firstPrelTemp.listeCaracteristiquesProduit,
				composition: firstPrelTemp.listeCompositionProduit,
				petitd: firstPrelTemp.petitDiametre,
				grandD: firstPrelTemp.grandDiametre
			};
			rapport.produit = produit;
		}

		rapport.essais = [];

		// récupère les traitements n'ayant qu'une valeur (moyenne transposée ...)
		const traitementsSingleValue = this.model.synthese.grpSyntheseCritereTraitement[2]?.selectedCriteresTraitement;
		let singleStatsCode = [];
		if (traitementsSingleValue) {
			singleStatsCode = traitementsSingleValue.map((x) => x.code);
		}
		let singleStats = [];
		if (singleStatsCode.length > 0) {
			singleStats = this.resultat.stats.filter((x) => singleStatsCode.includes(x.code));
		}
		let idFamille = null;
		if (!this.model.synthese.isProdBeton) {
			idFamille = firstPrelTemp.familleBetonProduit.idFamille;
		}
		rapport.singleStats = this.formatSingleStatsValues(singleStats, idFamille);

		let iLoop = 0;
		for (let e = 0; e < this.model.formattedResult.entete.titresEssais.length; e++) {
			const mainEssai = this.model.formattedResult.entete.titresEssais[e];
			const mainSse = this.model.formattedResult.entete.titresSousEssais.find((s) => s.idEssai == mainEssai.id);
			if (mainEssai.isForControle) {
				const essai = { id: mainEssai.id, code: mainEssai.code, libelle: mainEssai.libelle };
				essai.sousEssai = { id: mainSse.id, code: mainSse.codeSsEss, libelle: mainSse.displayTitle };

				essai.affichages = [];
				for (let i = 0; i < this.model.synthese.listeSyntheseCritereAffichage.length; i++) {
					const affichage = this.model.synthese.listeSyntheseCritereAffichage[i];
					if (affichage.idSynthCritAffichage) {
						essai.affichages.push({
							id: affichage.idSynthCritAffichage,
							domaine: affichage.codeDomaine,
							libelle: affichage.titre,
							type: 'affichage'
						});
					}
				}

				essai.traitements = [];
				for (let i = 0; i < this.model.synthese.listeSyntheseCritereTraitement.length; i++) {
					const traitement = this.model.synthese.listeSyntheseCritereTraitement[i];
					// Filtre "IMPRIMABLE"
					if (traitement.isImprimable && traitement.idSynthCritTraitement && !singleStatsCode.includes(traitement.code)) {
						essai.traitements.push({
							id: traitement.idCalcStat,
							code: traitement.code,
							libelle: traitement.libelle,
							formule: traitement.formule,
							type: 'traitement'
						});
					}
				}

				essai.columns = essai.affichages.concat(essai.traitements);

				//construction en-tête famille
				essai.familles = [];

				let famillesIds = [];
				const selectionFamille = this.model.synthese.listeSyntheseCritereValeurBlocks[0].listeSyntheseCritereValeurLignes.find(
					(e) => e.domaineCode == 'FamBeton'
				);
				if (selectionFamille) {
					if (selectionFamille.selectedCritAff.length > 0) {
						famillesIds.push(selectionFamille.selectedCritAff[0].id);
					} else {
						famillesIds = selectionFamille.isteven.map((e) => e.id);
					}
				} else {
					const allPlvFamille = this.resultat.lignes.map((e) => e.famille && e.famille.idFamille);
					for (let i = 0; i < allPlvFamille.length; i++) {
						if (allPlvFamille[i] && famillesIds.indexOf(allPlvFamille[i]) < 0) {
							famillesIds.push(allPlvFamille[i]);
						}
					}
				}

				const graph = document.getElementById('granulograph' + iLoop);
				if (graph) {
					essai.canvas = graph.toDataURL('image/png');
				}

				rapport.essais.push(essai);
				iLoop++;
			}
		}

		return rapport;
	}

	async genereInfosPENPENIC() {
		const that = this;
		const rapport = {};

		const firstPrelTemp = this.model.resultat.prelevementBlock[0];

		//construction en-tête producteur
		const producteur = {};
		const selectionProducteur = this.model.synthese.listeSyntheseCritereValeurBlocks[0].listeSyntheseCritereValeurLignes.find(
			(e) => e.domaineCode == 'SiteProd'
		);
		// if (selectionProducteur && selectionProducteur.selectedCritAff.length > 0) {
		//     producteur.id = selectionProducteur.selectedCritAff[0].id;
		//     producteur.libelle = selectionProducteur.selectedCritAff[0].libelle;
		// }
		// else {
		producteur.id = firstPrelTemp.idProducteur;
		producteur.libelle = firstPrelTemp.libelleProducteur;
		producteur.societe = firstPrelTemp.societeProducteur;
		producteur.raisonSociale = firstPrelTemp.rsProducteur; // Mantis 6438 JN
		//producteur.code = firstPrelTemp.codeProducteur;
		// }
		rapport.producteur = producteur;

		const laboratoire = {};
		// const selectionLaboratoire = this.model.synthese.listeSyntheseCritereValeurBlocks[0].listeSyntheseCritereValeurLignes.find(e => e.domaineCode == 'SiteLabo');
		// if (selectionLaboratoire && selectionLaboratoire.selectedCritAff.length > 0) {
		//     laboratoire.id = selectionLaboratoire.selectedCritAff[0].id;
		//     laboratoire.libelle = selectionLaboratoire.selectedCritAff[0].libelle;
		// }
		// else {
		laboratoire.id = firstPrelTemp.idLaboAudit;
		laboratoire.libelle = firstPrelTemp.libelleLaboAudit;
		laboratoire.societe = firstPrelTemp.societeLaboAudit;
		laboratoire.raisonSociale = firstPrelTemp.rsLaboAudit;
		//laboratoire.code = firstPrelTemp.codeLaboAudit;
		// }
		rapport.laboratoire = laboratoire;

		rapport.essai = {};

		let iLoop = 0;
		for (let e = 0; e < this.model.formattedResult.entete.titresEssais.length; e++) {
			const mainEssai = this.model.formattedResult.entete.titresEssais[e];
			const mainSse = this.model.formattedResult.entete.titresSousEssais.find((s) => s.idEssai == mainEssai.id);
			if (mainEssai.isForControle) {
				const essai = { id: mainEssai.id, code: mainEssai.code, libelle: mainEssai.libelle };
				essai.sousEssai = { id: mainSse.id, code: mainSse.codeSsEss, libelle: mainSse.displayTitle };

				essai.affichages = [];
				for (let i = 0; i < this.model.synthese.listeSyntheseCritereAffichage.length; i++) {
					const affichage = this.model.synthese.listeSyntheseCritereAffichage[i];
					if (affichage.idSynthCritAffichage) {
						essai.affichages.push({
							id: affichage.idSynthCritAffichage,
							domaine: affichage.codeDomaine,
							libelle: affichage.titre,
							type: 'affichage'
						});
					}
				}

				essai.traitements = [];
				for (let i = 0; i < this.model.synthese.listeSyntheseCritereTraitement.length; i++) {
					const traitement = this.model.synthese.listeSyntheseCritereTraitement[i];
					if (traitement.idSynthCritTraitement) {
						essai.traitements.push({
							id: traitement.idCalcStat,
							code: traitement.code,
							libelle: traitement.libelle,
							formule: traitement.formule,
							type: 'traitement'
						});
					}
				}

				essai.columns = essai.affichages.concat(essai.traitements);

				//construction en-tête famille
				essai.famille = {};

				let famillesIds = [];
				const selectionFamille = this.model.synthese.listeSyntheseCritereValeurBlocks[0].listeSyntheseCritereValeurLignes.find(
					(e) => e.domaineCode == 'FamBeton'
				);
				if (selectionFamille) {
					if (selectionFamille.selectedCritAff.length > 0) {
						famillesIds.push(selectionFamille.selectedCritAff[0].id);
					} else {
						famillesIds = selectionFamille.isteven.map((e) => e.id);
					}
				} else {
					const allPlvFamille = this.resultat.lignes.map((e) => e.famille && e.famille.idFamille);
					for (let i = 0; i < allPlvFamille.length; i++) {
						if (allPlvFamille[i] && famillesIds.indexOf(allPlvFamille[i]) < 0) {
							famillesIds.push(allPlvFamille[i]);
						}
					}
				}

				const graph = document.getElementById('granulograph' + iLoop);
				if (graph) {
					essai.canvas = graph.toDataURL('image/png');
				}

				rapport.essai = essai;
				iLoop++;

				//on prend que le premier essai à
				break;
			}
		}

		return rapport;
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	alertResistance(results) {
		if (!results || !results.lignes || !results.lignes[0]) {
			return false;
		}
		const regleRefName = this.resultat.familles[0].regleTranspoFormuleRefName;
		if (regleRefName.includes('RVREF') || regleRefName.includes('REREF')) {
			if (
				(regleRefName.includes('REREF') && !results.lignes[0].compoResEstime) ||
				(regleRefName.includes('RVREF') && !results.lignes[0].compoResVisee)
			) {
				return true;
			}
		}
	}

	formatSingleStatsValues(stats, idFamille) {
		try {
			let allStats = [];
			let statsBeton = [];
			if (idFamille) {
				allStats = this.resultat[`F${idFamille}`].lignes[this.resultat[`F${idFamille}`].lignes.length - 1].stats;
				statsBeton = this.resultat[`F${idFamille}`].statsBeton;
			}

			if (stats && stats.length > 0) {
				const essais = stats[0].statsEssais.map((x) => {
					const enteteEssai = this.resultat.entete.titresEssais.find((essai) => essai.code == x.codeEssai);
					const e = {
						codeSousEssai: x.codeSousEssai,
						code: x.codeEssai,
						libelle: enteteEssai?.libelle,
						symbole: enteteEssai?.symbole
					};
					return e;
				});

				for (let i = 0; i < essais.length; i++) {
					const conformite = this.conformites.find((x) => x.codeEssai == essais[i].code);
					if (conformite) {
						essais[i] = {
							...essais[i],
							total: conformite.lines.find((x) => x.title === 'Total')?.value
						};
					} else {
						essais[i] = {
							...essais[i],
							total: ' ',
							nivAcc: ' '
						};
					}

					essais[i].stats = [];

					for (let j = 0; j < stats.length; j++) {
						const sTmp = allStats[`${stats[j].code}_${essais[i].code}`];
						const statBeton = statsBeton[`${stats[j].code}_${essais[i].code}`];
						let val = stats[j].statsEssais.find((x) => x.codeEssai == essais[i].code)?.valeur;
						if (statBeton) {
							val = statBeton.values.val;
						}
						if (sTmp?.conformite?.limInf || sTmp?.conformite?.limSup) {
							const valeur = val + ` [${sTmp.conformite?.limInf}/ ${sTmp.conformite?.limSup}]`;
							val = {
								value: valeur,
								text: sTmp.conformite.isConforme ? '009900' : 'FF0000'
							};
						}

						essais[i].stats.push({
							code: stats[j].code,
							libelle: stats[j].titre,
							formule: stats[j].formule,
							valeur: val
						});
					}
				}
				return essais;
			}

			return null;
		} catch (error) {
			// console.log('🚀: SyntheseEntityResistanceController -> formatSingleStatsValues -> error', error);
		}
	}

	async editCompo() {
		let result = null;
		const modalInstance = this.$uibModal.open({
			component: 'rvmodal',
			resolve: {
				betonsCopie: () => angular.copy(this.resultat.produits),
				betons: () => this.resultat.produits
			},
			size: 'xl',
			backdrop: true
		});

		result = await modalInstance.result;
		if (result.isOk === true) {
			try {
				const betModified = result.betModified;
				const lstComposToModified = [];
				betModified.forEach((element) => {
					const rvSaisieBet = element.CompoCourante.rvSaisie;
					const rvInitialeBet = angular.copy(this.resultat.produits.filter((x) => x.id === element.id)[0].CompoCourante.rv);
					if (rvSaisieBet !== null && rvSaisieBet !== rvInitialeBet) {
						lstComposToModified.push({
							dateCreation: this.moment(element.CompoCourante.dateCreation, this.dateFormat).toDate(),
							id: element.CompoCourante.id,
							resistanceVisee: element.CompoCourante.rvSaisie
						});
					}
				});
				if (lstComposToModified !== null && lstComposToModified.length >= 1) {
					await this.CompositionsServices.updateListResVis(lstComposToModified);

					this.notification.success(this.$translate.instant('SYNTHENTS.CARTE_DE_CONTROLE.NOTIFICATIONS.EDIT_RV_SUCCESS'));
					// On regénère la synthese
					const res = await this.SyntheseEntitiesService.genererSynthese(
						this.$stateParams.obj.synthese,
						this.MassiaApplicationService.getApplication()
					);
					this.model.resultat = res;
					// On relance l'affichage de la synthese
					this.$state.go('syntheseentities.result', { obj: this.model });
				}
			} catch (error) {
				this.notification.error(error.data);
			}
		}
		return result;
	}
}
