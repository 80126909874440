export const template = {
    synthentsRow: `
      <div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" 
      class="ui-grid-cell" ng-class="{ 'bold': row.entity.footer }" ng-style="{ 'color': row.entity.text, 'background-color': row.entity.background }" 
      ui-grid-cell></div>`,
    synthentsCell: `<div
            class="ui-grid-cell-contents"
            title="TOOLTIP">
            {{row.entity.columns[col.field].value}}
        </div>`,
    synthentsHeader: `<div
      role="columnheader"
      ng-class="{ 'sortable': sortable, 'ui-grid-header-cell-last-col': isLastCol }"
      ui-grid-one-bind-aria-labelledby-grid="col.uid + '-header-text ' + col.uid + '-sortdir-text'"
      aria-sort="{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}">
      <div
        role="button"
        tabindex="0"
        ng-keydown="handleKeyDown($event)"
        class="ui-grid-cell-contents ui-grid-header-cell-primary-focus"
        col-index="renderIndex"
        title="TOOLTIP">
        <span title="{{ col.displayName CUSTOM_FILTERS }}" class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + '-header-text'">{{ col.displayName CUSTOM_FILTERS }}</span>
    
        <span
          ui-grid-one-bind-id-grid="col.uid + '-sortdir-text'"
          ui-grid-visible="col.sort.direction"
          aria-label="{{getSortDirectionAriaLabel()}}">
          <i
          ng-class="{ 'ui-grid-icon-up-dir': col.sort.direction == asc, 'ui-grid-icon-down-dir': col.sort.direction == desc, 'ui-grid-icon-blank': !col.sort.direction }"
          title="{{isSortPriorityVisible() ? i18n.headerCell.priority + ' ' + ( col.sort.priority + 1 )  : null}}"
          aria-hidden="true">
        </i>
        <sub
          ui-grid-visible="isSortPriorityVisible()"
          class="ui-grid-sort-priority-number">
          {{col.sort.priority + 1}}
        </sub>
        </span>
      </div>
    
      <div
        role="button"
        tabindex="0"
        ui-grid-one-bind-id-grid="col.uid + '-menu-button'"
        class="ui-grid-column-menu-button"
        ng-if="grid.options.enableColumnMenus && !col.isRowHeader  && col.colDef.enableColumnMenu !== false"
        ng-click="toggleMenu($event)"
        ng-keydown="headerCellArrowKeyDown($event)"
        ui-grid-one-bind-aria-label="i18n.headerCell.aria.columnMenuButtonLabel"
        aria-expanded="{{col.menuShown}}"
        aria-haspopup="true">
        <i
          class="ui-grid-icon-angle-down"
          aria-hidden="true">
          &nbsp;
        </i>
      </div>
    
      <div class="ui-grid-filter-custom" ui-grid-filter ng-hide="col.filterContainer === 'columnMenu'"></div>
    </div>`,
    synthentsFooter: (i: number) => `
      <div class="ui-grid-cell-contents" col-index="renderIndex" 
          title="{{ col.getAggregationValue()[${i}].val }}" 
          ng-style='col.getAggregationValue()[${i}].style'>
          <div class="mas-jc-space-b mas-row">
            <div class="mas-col-fit">{{ col.getAggregationValue()[${i}].name }}</div>
            <div class="mas-col-fit">{{ col.getAggregationValue()[${i}].val }}</div>
          </div>
      </div>`
};
