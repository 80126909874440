export default class SiteAccreditationService {
    static $inject = ['$http'];
    constructor($http) {
        this.$http = $http;
        this.serviceUrl = `${__configuration.apiUrl}/massia/laboratoire/certificat`;
        this.certificatGenUrl = `${__configuration.apiUrl}/massia/laboratoire/certificat-generique`;
    }

    async getListeCertificatGenerique() {
        const data = await this.$http.get(this.certificatGenUrl);

        return data.data.items;
    }

    async getListeCertificatBySite(site) {
        const data = await this.$http.get(this.serviceUrl, { params: { siteCertifie: site.id } });

        return data.data.items;
    }

    async getTypes() {
        const data = await this.$http.get('assets/enum/certifications/types.enum.json');
        return data.data;
    }

    async getListeSitesAccrediteurs() {
        const data = await this.$http.get(`${__configuration.apiUrl}/massia/sites-fournisseurs/sous-type/1`);
        return data.data;
    }

    async getListeProduits(site) {
        const data = await this.$http.get(`${__configuration.apiUrl}/massia/produits/produitsProducteur/${site.id}`);
        return data.data;
    }

    async getListeNormes() {
        const data = await this.$http.get(`${__configuration.apiUrl}/massia/normes`);
        return data.data;
    }

    async getListeEssais() {
        const data = await this.$http.get(`${__configuration.apiUrl}/massia/mesures`, {
            params: {
                domaine: JSON.stringify('essais')
            }
        });
        return data.data;
    }
}
