import CritereValeur from '../../../../../../../../../models/criteres.valeur.models';

/* @ngInject */
export default class CritrereBoolController {
    constructor() {}
    $onInit() {
        this._ifCritereNotExistsCreateIt();
        this._convertStringToBool('valeur1');
        this._convertStringToBool('valeur2');
    }
    $onDestroy() {}

    _ifCritereNotExistsCreateIt() {
        if (!this.critere.modelSelectionCritereValeur || this.critere.modelSelectionCritereValeur.length === 0) {
            this.critere.modelSelectionCritereValeur = [new CritereValeur({ valeur1: false, valeur2: false })];
        }
    }

    _convertStringToBool(prop) {
        if (typeof this.critere.modelSelectionCritereValeur[0][prop] === 'string') {
            this.critere.modelSelectionCritereValeur[0][prop] = this.critere.modelSelectionCritereValeur[0][prop] === 'true' ? true : false;
        }
    }
}
