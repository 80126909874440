export default class ProduitFormFormulesController {
    static $inject = ['ProduitsService', 'notification', 'ModalService', '$translate'];

    constructor(ProduitsService, notification, ModalService, $translate) {
        this.ProduitsService = ProduitsService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$translate = $translate;
    }

    $onInit() {
        this.loadFormules();
    }

    async loadFormules() {
        this.startLoading();

        try {
            if (this.produit) {
                this.formules = await this.ProduitsService.getProduitFormules(this.produit);
            }
        } catch (ex) {
            this.notification.error(ex);
        } finally {
            this.stopLoading();
        }
    }

    async deleteFormule(index) {
        const that = this;

        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('FORMULES.DELETE.TITLE'),
            modalMsg: this.$translate.instant('FORMULES.DELETE.MESSAGE'),
            headerClass: 'modal-danger'
        });

        modalInstance.result.then(async function () {
            that.startLoading();
            try {
                await that.ProduitsService.deleteProduitFormules(that.formules[index].produitId, that.formules[index].producteurId);
                that.formules.splice(index, 1);
            } catch (ex) {
                that.notification.error(ex.data);
            } finally {
                that.stopLoading();
            }
        });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
