import PaysFormEnteteController from './pays.form.entete.controller';

export default {
    bindings: {
        pays: '<',
        form: '=',
        onUpdate: '&'
    },
    template: require('./pays.form.entete.html'),
    controller: PaysFormEnteteController
};
