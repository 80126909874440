import PrelevementController from './prelevement.controller';

export default {
    bindings: {
        id: '=',
        idTheme: '=',
        idFamille: '=',
        modalInstance: '=',
        codeDomaine: '='
    },
    template: require('./prelevement.html'),
    controller: PrelevementController
};
