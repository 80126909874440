const serviceUrl = `${__configuration.apiUrl}/massia/societes`;

export default class SocietesService {
	static $inject = ['$http', 'TypesNiveauxHierarchique'];

	constructor($http, TypesNiveauxHierarchique) {
		this.$http = $http;
		this.TypesNiveauxHierarchique = TypesNiveauxHierarchique;
	}

	async getSocietes(filters, sorts, pagination, genre, application) {
		const url = `${serviceUrl}/by-genre`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || { skip: 0, take: 0 }),
				genre: JSON.stringify(genre),
				application: JSON.stringify(application)
			}
		});

		return res.data;
	}

	async getAccessSurimpression() {
		const a = await this.$http.get(`${__configuration.apiUrl}/massia/parametre-programme/code`, {
			params: {
				cle: 'BoolSurImprFact'
			}
		});
		return a.data;
	}

	async getLogos() {
		const url = `${__configuration.apiUrl}/massia/images/logo`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async deleteSocieteById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async getSocieteEnteteById(id, application) {
		const url = `${serviceUrl}/${id}/entete/${application}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSocieteValeursCaracteristiquesAssociationsById(id) {
		const data = await this.getSocieteValeursCaracteristiquesById(id);
		const result = {};

		for (const element in data) {
			if (data[element] && !Array.isArray(data[element])) {
				if (data[element].value === 'True') {
					data[element].value = 'true';
				}
				if (data[element].value === 'False') {
					data[element].value = 'false';
				}

				if (data[element].value && data[element].value.key) {
					result[data[element].id] = data[element].value.key;
				} else {
					result[data[element].id] = data[element].value;
				}
			}
		}
		return result;
	}

	async getSocieteValeursCaracteristiquesById(id) {
		const url = `${serviceUrl}/${id}/valeurs-caracteristiques`;
		const res = await this.$http.get(url);
		const result = [];
		res.data.forEach(function (element) {
			const caract = {
				id: element.id,
				code: element.code,
				idNature: element.idNature,
				indice: element.indice,
				label: element.label,
				numericAdditionalInformation: element.numericAdditionalInformation,
				value: element.elementsValues || element.elementValue || element.values || element.value
			};
			result.push(caract);
		});
		return result;
	}

	async createSociete(societe, application) {
		const data = societe.entete;
		data.caracteristiques = [];
		data.niveaux = societe.niveaux;
		data.argumentSocietes = societe.argumentSocietes;
		data.societesAssociees = societe.listeSocietesAssociees;
		data.pontComptable = societe.pontComptable;
		data.caracteristiques = this.formatCaracteristiques(societe.caracteristiques);
		data.application = application;

		// let data = {
		//     code: societe.entete.code,
		//     libelle: societe.entete.libelle,
		//     raison: societe.entete.raison,
		//     associeId: societe.entete.associeId,
		//     type: societe.entete.typeId,
		//     paysId: societe.entete.paysId,
		//     isClient: societe.entete.isClient,
		//     isFournisseur: societe.entete.isFournisseur,
		//     isProducteur: societe.entete.isProducteur,
		//     isTransporteur: societe.entete.isTransporteur,
		//     isUtilisateur: societe.entete.isUtilisateur,
		//     isLaboratoire: societe.entete.isLaboratoire,
		//     isProprietaire: societe.entete.isProprietaire,
		//     isLocataire: societe.entete.isLocataire,
		//     isAuditeur: societe.entete.isAuditeur,
		//     adresse: societe.entete.adresse,
		//     codePostal: societe.entete.codePostal,
		//     ville: societe.entete.ville,
		//     telephone: societe.entete.telephone,
		//     fax: societe.entete.fax,
		//     mail: societe.entete.mail,
		//     website: societe.entete.website,
		//     siren: societe.entete.siren,
		//     tvaIntracommunautaire: societe.entete.tvaIntracommunautaire,
		//     mentionFacture: societe.entete.mentionFacture,
		//     caracteristiques: [],
		//     niveaux: societe.niveaux,
		//     argumentSocietes: societe.argumentSocietes,
		//     application: application
		// };
		// data.caracteristiques = this.formatCaracteristiques(societe.caracteristiques);

		const result = await this.$http.post(serviceUrl, data);

		return result.data;
	}

	formatCaracteristiques(caracteristiques) {
		const data = [];
		for (const property in caracteristiques) {
			let keyValueObject;
			//On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variables interne Js.
			if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
				const propertyVal = caracteristiques[property];
				if (Array.isArray(propertyVal)) {
					if (_.some(propertyVal, 'key')) {
						keyValueObject = {
							IdCaracteristique: parseInt(property),
							valeurs: _.map(propertyVal, 'key')
						};
					} else {
						keyValueObject = {
							IdCaracteristique: parseInt(property),
							valeurs: propertyVal
						};
					}
				} else {
					keyValueObject = {
						IdCaracteristique: parseInt(property),
						valeur: propertyVal
					};
				}
				data.push(keyValueObject);
			}
		}
		return data;
	}

	async updateSociete(societe) {
		const data = societe.entete;
		data.caracteristiques = [];
		data.niveaux = societe.niveaux;
		data.argumentSocietes = societe.argumentSocietes;
		data.societesAssociees = societe.listeSocietesAssociees;
		data.pontComptable = societe.pontComptable;
		data.caracteristiques = this.formatCaracteristiques(societe.caracteristiques);

		const url = `${serviceUrl}/${societe.id}`;
		return await this.$http.put(url, data);
	}

	async codeExists(code) {
		const url = `${serviceUrl}/code-unicity/${code}`;
		const result = await this.$http.get(url);
		return result.data;
	}

	async getPays(filters, sorts, pagination) {
		const paysUrl = `${__configuration.apiUrl}/massia/pays`;
		const res = await this.$http.get(paysUrl);

		return res.data;
	}

	async getAssocies(filters, sorts, pagination) {
		const associesUrl = `${__configuration.apiUrl}/massia/associes`;
		const res = await this.$http.get(associesUrl);

		return res.data;
	}

	//le retour de cette methode doit correspond au format predefini dans mock/lieux/lieux.niveaux.json
	async getSocietesNiveaux() {
		const url = `${__configuration.apiUrl}/massia/domaines/societes/niveaux-hierarchiques`;
		const res = await this.$http.get(url);
		return this.buildSocietesNiveaux(res.data);
	}

	//methode de conversion du format retourne par le serveur vers le format attendu par le front
	buildSocietesNiveaux(niveaux) {
		let newNiveaux = [];

		if (niveaux && niveaux.length > 0) {
			const self = this;

			newNiveaux = _.map(niveaux, function (niveau) {
				return {
					//le resultat retourne par le serveur ait le type int pour id du niveau,
					//cependant, selon json attendu par le front, il est necessire que cet id soit en format string (pour que le reste puisse fonctionner comme prevu)
					//on convertit donc l'id de int a string ici
					idNiveau: niveau.id.toString(),

					label: niveau.label,
					saisi: niveau.type === self.TypesNiveauxHierarchique.Libre.value,
					sousNiveaux: self.buildSocietesNiveaux(niveau.items)
				};
			});
		}

		return newNiveaux;
	}

	//le retour de cette methode doit correspond au format predefini dans mock/lieux/lieux.elements.json
	async getSocietesElements() {
		const url = `${__configuration.apiUrl}/massia/domaines/societes/elements-hierarchiques-by-niveau`;
		const res = await this.$http.get(url);
		return res.data;
	}

	//le retour de cette methode doit correspond au format predefini dans mock/lieux/lieux.valeurs.json
	async getSocieteValeursNiveauxById(id) {
		const url = `${serviceUrl}/${id}/elements-hierarchiques`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getArgumentsById(id) {
		const url = `${serviceUrl}/${id}/argument-societe`;
		const res = await this.$http.get(url);
		return res.data.idFiltres;
	}

	async getTypePontComptable() {
		const url = 'assets/enum/societes/pont-comptable/typePontComptable.json';
		const res = await this.$http.get(url);
		return res.data;
	}
	async getArguments() {
		const url = `${__configuration.apiUrl}/massia/performance/rapport/arguments/header`;
		const res = await this.$http.get(url);
		return res.data;
	}
	async getBanques() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/banques/headers`);
		return res.data;
	}
	async getAssocies() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/associes/headers`);
		return res.data;
	}
	async getCoordonnees(idSociete) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/coordonnees-bancaires/${idSociete}`);
		return res.data;
	}
	async persistCoordonnees(data) {
		const res = await this.$http.post(`${__configuration.apiUrl}/massia/coordonnees-bancaires`, data);
		return res.data;
	}
	async deleteCoordonnees(id) {
		const res = await this.$http.delete(`${__configuration.apiUrl}/massia/coordonnees-bancaires/${id}`);
		return res.data;
	}
	async updateCoordonnees(id, data) {
		const res = await this.$http.put(`${__configuration.apiUrl}/massia/coordonnees-bancaires/${id}`, data);
		return res.data;
	}
	async getSocietesAssociees(idSociete) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/societes-associees/${idSociete}`);
		return res.data;
	}
	async persistSocietesAssociees(data) {
		const res = await this.$http.post(`${__configuration.apiUrl}/massia/societes-associees`, data);
		return res.data;
	}
	async deleteSocietesAssociees(id) {
		const res = await this.$http.delete(`${__configuration.apiUrl}/massia/societes-associees/${id}`);
		return res.data;
	}
	async updateSocietesAssociees(id, data) {
		const res = await this.$http.put(`${__configuration.apiUrl}/massia/societes-associees/${id}`, data);
		return res.data;
	}
	async getGenres() {
		const url = 'assets/enum/societes/genres.json';
		const res = await this.$http.get(url);
		return res.data;
	}
	async getParametreTransport(idSociete) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/parametres/transport-qte-benne/${idSociete}`);
		return res.data;
	}
	async getTypesBenne() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/gestion/type-bennes/headers`);
		return res.data;
	}
	async getTransports() {
		// on prend uniquement les transports ayant un mode de déclenchement au ticket
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/transports/headers/ticket`);
		return res.data;
	}
	async saveParametreTransport(data) {
		const url = `${__configuration.apiUrl}/massia/parametres/transport-qte-benne`;
		const result = await this.$http.post(url, data);
		return result.idLigneParamTrans;
	}
	async deleteParamTrans(idLigneParamTrans) {
		const res = await this.$http.delete(`${__configuration.apiUrl}/massia/parametres/transport-qte-benne/${idLigneParamTrans}`);
		return res.data;
	}
	async updateParametreTransport(idLigneParamTrans, data) {
		const res = await this.$http.put(`${__configuration.apiUrl}/massia/parametres/transport-qte-benne/${idLigneParamTrans}`, data);
		return res.data;
	}
}
