import validatorBuilder from 'validator-builder';

let params3rValidator = null;

export default class Import3rParam {
    constructor(data = {}) {
        this.id = data.id || null;
        this.libelle = data.libelle || null;
        this.essaiIdentifieur = data.essaiIdentifieur || null;
        this.essais = data.essais || null;
        this.sousEssai = data.sousEssai || null;
        this.caracteristique = data.caracteristique || null;
    }

    isValid() {
        params3rValidator = params3rValidator || validatorBuilder.getInstanceFor('Import3rParam');
        const validationResults = params3rValidator.validate(this);
        return validationResults.isValid;
    }
}
