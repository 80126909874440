export default function Configure($stateProvider) {
    $stateProvider.state('donnee-filtrees', {
        parent: 'common',
        url: '/donnee-filtrees',
        abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            skip: true
        }
    });

    $stateProvider.state('donnee-filtrees.list', {
        url: '/list/{id}/type/{type}',
        template: '<donnee-filtrees></donnee-filtrees>',
        rights: { domain: 'utilisateurs', right: 'update' },
        ncyBreadcrumb: {
            label: '{{ "FILTRES.BREADCRUMBS.FILTRES_LIST" | translate}}'
        }
        //search: 'PERSONNES.BREADCRUMBS.FILTRES_LIST'
    });
}

Configure.$inject = ['$stateProvider'];
