import { StateService } from '@uirouter/angularjs';
import { Type } from '../../../../../../core/models/type/Type';
import { Unite } from '../../../../../../core/models/Unite';
import { ValidationDevisNiveaux, ValidationDevis, ValidationDevisProduits, ModeSeuil } from '../ValidationDevis';
import OffreValidationNiveaux from './offre.validation.niveaux.model';
import * as _ from 'lodash';
import OffreValidationProduits from './offre.validation.produits.model';
import { isValidationProduitRow } from '../offre.validation.service';
import Profil from '../../../../../common/profils/services/profil.model';

export default class OffreValidationNiveauController {
	$scope: ng.IScope;
	$state: StateService;
	$translate: any;
	loading: boolean;
	isEditMode: boolean;
	form: ValidationDevis;
	enumModeSeuil: { 1: string; 2: string };
	notification: any;
	unites: Unite[];
	validationNiveau: any[];
	validationProfilAControler: boolean;
	tmp: any[][] = [];
	niveauSelection: number;
	typesProduit: Type[];
	niveauValidation: ValidationDevisNiveaux;
	unregister: (() => void)[] = [];
	profils: Profil[];
	getLibelleProfil: (profil: Profil) => string;

	/* @ngInject */
	constructor($scope: ng.IScope, $state: StateService, $translate: any, notification: any) {
		this.$scope = $scope;
		this.$state = $state;
		this.$translate = $translate;
		this.notification = notification;

		this.enumModeSeuil = {
			1: $translate.instant('OFFRES.VALIDATION_DEVIS.ENTRE'),
			2: $translate.instant('OFFRES.VALIDATION_DEVIS.SUPERIEUR_A')
		};

		this.validationNiveau = [];
	}

	async $onInit() {
		this.unregister.push(
			this.$scope.$watch(
				() => this.niveauSelection,
				() => {
					this.niveauValidation = this.form.validationDevisNiveaux[this.niveauSelection];
				},
				true
			)
		);
	}

	$onDestroy(): void {
		for (const un of this.unregister) {
			un();
		}
	}
	debug() {
	}

	onCheckTypeProduitRow(index: number, validationDevisProduits: OffreValidationProduits) {
		this.tmp[this.niveauSelection][index] = !this.isValidProduitRow(this.niveauValidation, validationDevisProduits);
	}

	onSelect(validationDevisProduit: OffreValidationProduits, tmpTypeProduit: unknown) {
		validationDevisProduit.typeProduit = tmpTypeProduit;
	}

	//affiche le libelle de l'unité pour le mode read
	getUniteLibelle(id: number): string {
		return this.unites.find((unite) => unite.id === id).libelle; //laisser le 'l' minuscule à libelle sinon erreur
	}

	addValidationProduitRow(): void {
		const validationProduits = this.niveauValidation.validationDevisProduits;

		const newValidationProduit = new OffreValidationProduits();
		validationProduits.push(newValidationProduit);
		
		//rajoute a la nouvelle ligne le seuil max de la ligne précédente + 1
		if (validationProduits.length > 1) {
			const lastValidationProduit = validationProduits[validationProduits.length - 1];
			const prevValidationProduit = validationProduits[validationProduits.length - 2];
		
			if (prevValidationProduit.modeSeuil === ModeSeuil.ENTRE && prevValidationProduit.valeurMax !== null) {
			  lastValidationProduit.valeurMin = prevValidationProduit.valeurMax + 1;
			}
		  }
	}

	deleteValidationProduitRow(index: number) {
		this.niveauValidation.validationDevisProduits.splice(index, 1);
	}

	customFilter(model: OffreValidationNiveaux, includes: Type[]) {
		return (value: Type, index: number, array: Type[]) => {
			if (!model) {
				return true;
			}
			const l = model.validationDevisProduits.length;
			let filters: Type[] = [];
			for (let i = 0; i < l; i++) {
				const m = model.validationDevisProduits[i];
				filters = [...filters, ...m.typeProduit];
			}
			return !filters.filter((x) => !includes.some((y) => x.id === y.id)).some((y) => value.id === y.id);
		};
	}

	isValidProduitRow = (vdn: ValidationDevisNiveaux, newValProd: ValidationDevisProduits): boolean => {
		let isProduitValide: boolean = isValidationProduitRow(newValProd);

		if (!isProduitValide) {
			this.notification.error('OFFRES.VALIDATION_DEVIS.CONTROLE_PRODUIT.ERROR');
		}
		this.validationNiveau[vdn.niveau - 1].isSeuilRemiseProduit = isProduitValide;

		return isProduitValide;
	};
}
