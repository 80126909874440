export default class InventaireStocksLeveeController {
    static $inject = ['$stateParams', 'StocksCommunicationService', 'StocksService', 'notification', 'ModalService', '$uibModal'];

    constructor($stateParams, StocksCommunicationService, StocksService, notification, ModalService, $uibModal) {
        this.$stateParams = $stateParams;
        this.StocksCommunicationService = StocksCommunicationService;
        this.StocksService = StocksService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
        this.result = false;
    }

    async $onInit() {
        this.getTotal();
    }

    annuler() {
        this.modalInstance.close(this.result);
    }

    changeTotal() {
        this.result = true;
        this.getTotal();
    }

    getTotal() {
        let total = 0;
        this.levee.tasVolumes.forEach(function (element) {
            total = total + element.volume;
        }, this);
        this.levee.volume = total;
        this.levee.stockReleve = this.levee.volume * this.levee.densite;
        this.levee.ecart = this.levee.stockReleve - this.levee.stockTheorique;
    }

    // async confirmer() {
    //     if(!this.idMotif)
    //     {
    //         this.notification.error('STOCKS.MOTIF_OBLIGATOIRE');
    //     }
    //     else
    //     {
    //         //ajouter le nouvel ajustement
    //         let ajust = {
    //             idMotifAjustement: this.idMotif,
    //             quantite: this.quantite
    //         };
    //         if(!this.ligne.newAjustements)
    //         {
    //             this.ligne.newAjustements = [];
    //         }
    //         this.ligne.newAjustements.push(ajust);
    //         //reclaculer la variation, l'ajustement et le stock final de la ligne
    //         if(this.ligne.variation.valeur)
    //             this.ligne.variation.valeur = this.ligne.variation.valeur + this.quantite;
    //         else
    //             this.ligne.variation.valeur = this.quantite;
    //         if(this.ligne.stockFinal.valeur)
    //             this.ligne.stockFinal.valeur = this.ligne.stockFinal.valeur + this.quantite;
    //         else
    //             this.ligne.stockFinal.valeur = this.quantite;
    //         if(this.ligne.ajustement.valeur)
    //             this.ligne.ajustement.valeur = this.ligne.ajustement.valeur + this.quantite;
    //         else
    //             this.ligne.ajustement.valeur = this.quantite;
    //         this.modalInstance.close(ajust);
    //     }
    // }
}
