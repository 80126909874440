import CommandeComposantController from './commande.composant.controller';

export default {
    bindings: {
        id: '=',
        domaine: '=',
        isOpen: '='
    },
    template: require('./commande.composant.html'),
    controller: CommandeComposantController
};
