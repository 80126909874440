import { IHttpService } from 'angular';
import { Injectable } from '../../../../core/decorators';
import { Site } from '../../../../core/models/site/Site';
import { Societe } from '../../../../core/models/societe/Societe';

const serviceUrlEcoOrganisme = `${__configuration.apiUrl}/massia/registre-eco-organismes`;
const serviceUrlEcominero = `${__configuration.apiUrl}/massia/registre-declaration-ecominero`;
const serviceUrldeclarationRndts = `${__configuration.apiUrl}/massia/gestion/declaration-rndts`;

@Injectable('RegistresService')
export class RegistresService {
	private $http: IHttpService;
	private apiResult : any;
	/* @ngInject */
	constructor($http: IHttpService) {
		this.$http = $http;
		this.apiResult = null;
	}

	async getSocietes(): Promise<any> {
		const url = `${__configuration.apiUrl}/massia/societes-commerciales/header`;

		const res = await this.$http.get<Societe>(url);
		return res.data;
	}

	async getSitesBySocieteId(idSociete: number) {
		const url = `${__configuration.apiUrl}/massia/sites-commerciaux/header/${idSociete}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getAllEcoOrganismes() {
		const res: any = await this.$http.get(`${__configuration.apiUrl}/massia/eco-organisme-inertes`);
		if (res.data.length > 0) {
			return res.data
				.map((eo: any) => ({
					id: eo.societe.id,
					libelle: eo.societe.libelle,
					code: eo.societe.code
				}))// on distinct sur l'id
				.filter((eo: any, index: number, array: any[]) => array.findIndex((item: any) => item.id === eo.id) === index);
		}
	}

	async exportRegistreEcoOrganismes(form: any) {
		const url = `${serviceUrlEcoOrganisme}/export`;

		const res = await this.$http.post(url, form, {
			responseType: 'arraybuffer'
		});
		return res;
	}

	async exportRegistreDeclarationEcominero(form: any) {
		const url = `${serviceUrlEcominero}/export`;

		const res = await this.$http.post(url, form, {
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getDeclarationRndts(filters: any, sorts: any, pagination: any, idSociete: any, idSiteCom: any, dateDebut: any, dateFin: any, typeEntreeSortie: number) {
		const res = await this.$http.get(serviceUrldeclarationRndts, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {}),
				idSociete: JSON.stringify(idSociete || 0),
				idSiteCom: JSON.stringify(idSiteCom || 0),
				dateDebut: JSON.stringify(dateDebut || null),
				dateFin: JSON.stringify(dateFin || null),
				typeEntreeSortie: JSON.stringify(typeEntreeSortie)
			}
		});

		return res.data;
	}

	async printDeclarationRndts(idSociete: any, idSite: any, debut: any, fin: any, typeEntreeSortie: number, fileName: any) {
		const filtres = {
			idSociete: idSociete || 0,
			idSite: idSite || 0,
			debut: debut,
			fin: fin,
			typeEntreeSortie: typeEntreeSortie,
			fileName: fileName
		};
		const url = `${serviceUrldeclarationRndts}/print`;
		const res = await this.$http.get(url, {
			params: {
				filtres: JSON.stringify(filtres || {})
			},
			responseType: 'arraybuffer'
		});

		return res;
	}

	async postApi(idSociete: any, idSiteCom : any, dateDebut : any, dateFin : any, typeEntreeSortie: number) {

		//TODO: Call MASSIA API => Liste des ID des déclaration à transmettre ? 
		const url =  `${__configuration.apiUrl}/massia/gestion/declaration-rndts/teletransmettre`
		const res = await this.$http.get(url, {
			params: {
				idSociete: JSON.stringify(idSociete),
				idSiteCom: JSON.stringify(idSiteCom | 0),
				dateDebut: JSON.stringify(dateDebut),
				dateFin: JSON.stringify(dateFin),
				typeEntreeSortie: JSON.stringify(typeEntreeSortie)
			}
		});

		return res.data || res;
    }

	setApiResult(res: any)
    {
        this.apiResult = res;
    }

    getApiResult()
    {
        return this.apiResult
    }
}
