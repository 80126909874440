export default Configure;

Configure.$inject = ['$provide'];

function Configure($provide) {
    $provide.decorator('tmhDynamicLocale', tmhDynamicLocaleDecorated);

    tmhDynamicLocaleDecorated.$inject = [
        '$delegate',
        '$rootScope',
        '$injector',
        '$locale',
        '$q',
        'tmhDynamicLocaleCache',
        '$timeout',
        'tmhDynamicLocale.STORAGE_KEY'
    ];

    function tmhDynamicLocaleDecorated($delegate, $rootScope, $injector, $locale, $q, tmhDynamicLocaleCache, $timeout, STORAGE_KEY) {
        const promiseCache = {};
        const storageKey = STORAGE_KEY;
        const storage = $injector.get('tmhDynamicLocaleStorageCache');
        const defaultLocale = 'fr-fr';
        let activeLocale = {};

        /**
         * [GBX] bug 188 (23/03/2017)
         * Chargement des locales du language initial
         */
        $rootScope.$evalAsync(function () {
            const initialLocale = storage.get(storageKey) || defaultLocale;
            if (initialLocale) {
                loadLocale(initialLocale);
            }
        });

        /** [GBX] bug 188 (23/03/2017) */
        function loadScript(localeId, callback) {
            require(`angular-i18n/${localeId}`);
            callback();
        }

        /**
         * [GBX] bug 188 (23/03/2017)
         * Adaptation de la fonction loadLocale > angular-dynamic-locale/src/tmhdynamicLocale.js
         * Changement de la fonction loadScript qui prend l'id de la locale au lieux de l'url
         * @param localeId
         * @returns promise
         */
        function loadLocale(localeId) {
            function overrideValues(oldObject, newObject) {
                if (activeLocale !== localeId) {
                    return;
                }
                angular.forEach(oldObject, function (value, key) {
                    if (!newObject[key]) {
                        delete oldObject[key];
                    } else if (angular.isArray(newObject[key])) {
                        oldObject[key].length = newObject[key].length;
                    }
                });
                angular.forEach(newObject, function (value, key) {
                    if (angular.isArray(newObject[key]) || angular.isObject(newObject[key])) {
                        if (!oldObject[key]) {
                            oldObject[key] = angular.isArray(newObject[key]) ? [] : {};
                        }
                        overrideValues(oldObject[key], newObject[key]);
                    } else {
                        oldObject[key] = newObject[key];
                    }
                });
            }

            if (promiseCache[localeId]) {
                activeLocale = localeId;
                return promiseCache[localeId];
            }

            const cachedLocale = tmhDynamicLocaleCache.get(localeId);
            const deferred = $q.defer();
            if (localeId === activeLocale) {
                deferred.resolve($locale);
            } else if (cachedLocale) {
                activeLocale = localeId;
                $rootScope.$evalAsync(function () {
                    overrideValues($locale, cachedLocale);
                    storage.put(storageKey, localeId);
                    $rootScope.$broadcast('$localeChangeSuccess', localeId, $locale);
                    deferred.resolve($locale);
                });
            } else {
                activeLocale = localeId;
                promiseCache[localeId] = deferred.promise;
                /* [GBX] Modifier : anciennement localeUrl */
                loadScript(
                    localeId,
                    function () {
                        // Create a new injector with the new locale
                        const localInjector = angular.injector(['ngLocale']);
                        const externalLocale = localInjector.get('$locale');

                        overrideValues($locale, externalLocale);
                        tmhDynamicLocaleCache.put(localeId, externalLocale);
                        delete promiseCache[localeId];

                        $rootScope.$applyAsync(function () {
                            storage.put(storageKey, localeId);
                            $rootScope.$broadcast('$localeChangeSuccess', localeId, $locale);
                            deferred.resolve($locale);
                        });
                    },
                    function () {
                        delete promiseCache[localeId];

                        $rootScope.$applyAsync(function () {
                            if (activeLocale === localeId) {
                                activeLocale = $locale.id;
                            }
                            $rootScope.$broadcast('$localeChangeError', localeId);
                            deferred.reject(localeId);
                        });
                    },
                    $timeout
                );
            }
            return deferred.promise;
        }

        /* Surcharge du service */
        $delegate.get = () => activeLocale;

        $delegate.set = (localeId) => {
            loadLocale(localeId);
        };

        return $delegate;
    }
}
