import PrelevementListeMesuresController from './prelevement.liste.mesures.controller';

export default {
    bindings: {
        saisie: '=',
        campagne: '=',
        modeleSaisie: '=',
        bornescm: '=',
        idTheme: '=',
        idFamille: '=',
        refresh: '&',
        loadingMesures: '=?',
        isImportable: '<',
        blDataLoaded: '=',
        configurationBalanceMateriel: '=',
        configBalance: '='
    },
    template: require('./prelevement.liste.mesures.html'),
    controller: PrelevementListeMesuresController
};
