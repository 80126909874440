/* @ngInject */
export default class FtpPrintConfigController {
    addConfiguration = false;
    listConfig = [];
    configSelect = null;
    //template séléctionner
    temp = null;
    // partager avec
    constructor(
        $translate,
        $scope,
        $state,
        notification,
        MassiaRightsService,
        ProductService,
        ProduitsService,
        TypesService,
        TemplateImpressionService,
        PrintConfigService,
        globalizationManagementService,
        moment,
        ExcelService
    ) {
        (this.$translate = $translate),
            (this.$scope = $scope),
            (this.$state = $state),
            (this.notification = notification),
            (this.MassiaRightsService = MassiaRightsService);
        this.ProductService = ProductService;
        this.ProduitService = ProduitsService;
        this.TypeService = TypesService;
        this.templateImpressionService = TemplateImpressionService;
        this.PrintConfigService = PrintConfigService;
        this.globalizationManagementService = globalizationManagementService;
        this.moment = moment;
        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
        this.ExcelService = ExcelService;
    }

    async $onInit() {
        this.addConfiguration = true;
        this.certificationRight = this.MassiaRightsService.userHasRight('certification', 'print', 'laboratoire');
        this.isPdf = false;
        this.error = '';
        this.configExcel = false;
        //liste des clients pour le partage de fichier avec le portail
        this.clients = [];
        this.loadTemplate();

        this.produit = await this.ProductService.getOne(this.resolve.produit.id);
        this.types = await this.TypeService.getTypeById(this.produit.type?.id);

        this.listConfig = await this.PrintConfigService.getAll(this.resolve.config.typeSynth);

        this.selectConfig(null);

        this.excelConfigs = [];
        await this.getExcelConfig();
        this.excelconfigsCopy = angular.copy(this.excelConfigs);
    }

    selectConfig(data) {
        this.addConfiguration = false;
        let selected = data;

        if (!data && this.listConfig && this.listConfig.length > 0) {
            this.listConfig[0].selected = true;
            selected = this.listConfig[0];
        }

        if (!selected) {
            this.addConfiguration = true;
            this.resolve.config.id = null;
            this.resolve.config.libelle = '';
            this.resolve.config.saveModel = false;
            this.resolve.config.dateDeb = this.moment().format(this.dateFormat);
            this.resolve.config.dateFin = this.moment().add(6, 'M').format(this.dateFormat);
        }

        if (selected) {
            this.resolve.config.id = selected.id;
            this.resolve.config.libelle = selected.libelle;
            this.resolve.config.title = selected.title;
            this.resolve.config.comment = selected.comment;
            this.resolve.config.printCompl = selected.printCompl;
            this.resolve.config.caracts = selected.caracts;
            this.resolve.config.printPrels = selected.printPrels;
            this.resolve.config.printCertif = selected.printCertif;
            this.resolve.config.printDimRq = selected.printDimRq;
            this.resolve.config.dateDeb = this.moment().format(this.dateFormat);
            this.resolve.config.dateFin = this.moment().add(6, 'M').format(this.dateFormat);
            this.resolve.config.modele = selected.modele;
            this.resolve.config.saveModel = selected.modele ? true : false;
            if (selected.modele) {
                this.temp = this.template.find((x) => x.id == selected.modele);
            }
        }
    }

    async supprConfig() {
        try {
            await this.PrintConfigService.delete(this.resolve.config);
            const idx = this.listConfig.find((x) => x.id == this.resolve.config.id);
            this.listConfig.splice(idx, 1);
            this.selectConfig(null);
            this.notification.success('PRELEVEMENTS.IMPRESSION.DELETE.DELETED');
        } catch (error) {
            this.notification.error('PRELEVEMENTS.IMPRESSION.DELETE.NOTDELETED');
        }
    }
    addConfig(getselected) {
        this.addConfiguration = !this.addConfiguration;
        this.resolve.config.id = null;
        this.resolve.config.libelle = '';
        this.resolve.config.saveModel = false;
        this.resolve.config.dateDeb = this.moment().format(this.dateFormat);
        this.resolve.config.dateFin = this.moment().add(6, 'M').format(this.dateFormat);

        const idx = this.listConfig.find((x) => x.selected);
        if (idx > -1) {
            this.listConfig[idx].selected = false;
        }
        if (getselected) {
            this.selectConfig();
        }
    }

    async saveConfig() {
        const idConfig = angular.copy(this.resolve.config.id);

        try {
            if (this.resolve.config.saveModel) {
                this.resolve.config.modele = this.temp.id;
            }
            // this.manageDates();
            const res = await this.PrintConfigService.createOrUpdate(this.resolve.config);
            this.resolve.config.id = res?.id;
            this.addConfiguration = false;
            if (!idConfig) {
                this.listConfig.forEach((x) => {
                    x.selected = false;
                    return x;
                });
                this.listConfig.push(this.resolve.config);

                this.listConfig[this.listConfig.length - 1].selected = true;
            } else {
                const idx = this.listConfig.find((x) => x.id == this.resolve.config.id);
                res.selected = true;
                // this.listConfig.splice(idx, 1, res);
            }
            this.notification.success('PRELEVEMENTS.IMPRESSION.SUCCESS');
        } catch (error) {
            this.notification.error('PRELEVEMENTS.IMPRESSION.ERROR');
        }
    }
    checkPdf() {
        this.isPdf = this.isPdf ? false : true;
    }
    ok() {
        this.resolve.modele = this.temp;
        this.resolve.pdf = this.isPdf;
        this.modalInstance.close(this.resolve);
    }
    cancel() {
        this.modalInstance.dismiss('cancel');
    }

    async loadTemplate() {
        try {
            const res = await this.templateImpressionService.getTemplateByDomaine('bilannorm');
            this.template = res;
            if (res) {
                this.temp = res[0];
                this.checkApp(res[0]);
            }
        } catch (err) {
            this.error = err.data;
        }
    }
    checkApp(template) {
        const ext = template.extension;
        if (ext === '.docx') {
            this.configExcel = false;
        } else if (ext === '.xlsx') {
            this.excelConfigs = this.filterExcelConfigs(template.id);
            this.configExcel = true;
        }
    }

    manageDates() {
        const debut = this.moment(this.resolve.config.dateDeb, this.dateFormat);
        const fin = this.moment(this.resolve.config.dateFin, this.dateFormat);

        this.resolve.config.dateDeb = debut;
        this.resolve.config.dateFin = fin;
    }

    async getExcelConfig() {
        const typeSynthese = this.resolve.config.typeSynth;
        const res = await this.ExcelService.getAllConfig(0, 0);
        if (res && res.length > 0) {
            this.excelConfigs = res;
        }
    }

    filterExcelConfigs(idTemplate) {
        let listTmp = angular.copy(this.excelconfigsCopy);
        listTmp = listTmp.filter((x) => {
            if (x.idTemplate == idTemplate || !x.idTemplate) {
                console.log('ok');
                return x;
            }
        });
        return listTmp;
    }

    selectConfigExcel(data) {
        this.resolve.configExcel = data.selected ? data : null;
    }
}
