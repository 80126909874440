import ZoneAbattagesConfig from './_config/zone.abattages.config';
import ZoneAbattagesRoutes from './_config/zone.abattages.routes';
import ZoneAbattagesQseRoutes from './_config/zone.abattages.qse.routes';
import ZoneAbattagesComponent from './components/zone-abattages';
import ZoneAbattagesService from './services/zone.abattages.service';
import ZoneAbattagesCommunicationService from './services/zone.abattages.communication.service';

import ZoneAbattageFormComponent from './components/zone-abattages-form';

import ZoneAbattageValidator from './components/zone-abattages-form/zone.abattages.validator';

const moduleName = 'app.massia.common.zone.abattages';

angular
    .module(moduleName, [])
    .config(ZoneAbattagesConfig)
    .config(ZoneAbattagesRoutes)
    .config(ZoneAbattagesQseRoutes)
    .service('ZoneAbattagesService', ZoneAbattagesService)
    .service('ZoneAbattagesCommunicationService', ZoneAbattagesCommunicationService)
    .component('zoneAbattages', ZoneAbattagesComponent)
    .component('zoneAbattagesForm', ZoneAbattageFormComponent)
    .factory('ZoneAbattageValidator', ZoneAbattageValidator);

export default moduleName;
