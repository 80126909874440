import PersonneFormWidgetCreationPlvController from './personne.form.widget.creation.plv.controller';

export default {
    bindings: {
        personne: '=',
        onUpdate: '&',
        form: '='
    },

    template: require('./personne.form.widget.creation.plv.html'),
    controller: PersonneFormWidgetCreationPlvController
};
