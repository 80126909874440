import CritereValeur from '../../../../../../../../../models/criteres.valeur.models';

/* @ngInject */
export default class CritrereIdEnumController {
    constructor(SyntheseSettingService) {
        this.SyntheseSettingService = SyntheseSettingService;
    }
    $onInit() {
        this.enum = [];
        this.loadListeValeur();
    }
    $onDestroy() {}

    async loadListeValeur() {
        this.enum = await this.SyntheseSettingService.loadEnum(this.critere.id);
    }

    setValeur(data, crit) {
        if (!crit) {
            crit = new CritereValeur();
        }
        if (crit.idValeur === data.id) {
            crit.idValeur = null;
        } else {
            crit.idValeur = data.id;
        }
    }

    multiSelect() {
        this.critere.modelSelectionCritereValeur = [];
        for (let i = 0; i < this.selected.length; i++) {
            const element = this.selected[i];
            this.critere.modelSelectionCritereValeur.push(new CritereValeur());
            const idx = this.critere.modelSelectionCritereValeur.length - 1;
            this.setValeur(element, this.critere.modelSelectionCritereValeur[idx]);
        }
    }

    deleteLine(liste, id) {
        const i = liste.findIndex((x) => x.idValeur === id);
        liste.splice(i, 1);
    }

    initCritere(list) {
        if (!list[0]) {
            list.push(new CritereValeur());
        }
    }
}
