import FamillesSelectController from './familles.select.controller';

export default {
    template: require('./familles.select.tpl.html'),
    controller: FamillesSelectController,
    bindings: {
        disabled: '<',
        clearOnSelect: '<',
        selected: '<',
        excludes: '<',
        onSelect: '&'
    }
};
