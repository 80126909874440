import TypeBenne from './type.benne.model';

export default class TypeBenneFormController {
    static $inject = ['_', '$scope', '$state', '$stateParams', '$translate', 'notification', '$timeout', 'TypeBennesService', 'MOPService'];

    constructor(_, $scope, $state, $stateParams, $translate, notification, $timeout, TypeBennesService, MOPService) {
        this.$scope = $scope;
        this.$state = $state;
        this.$translate = $translate;
        this.$stateParams = $stateParams;
        this.notification = notification;
        this.TypeBennesService = TypeBennesService;
        this.$timeout = $timeout;
        this._ = _;
        this.MOPService = MOPService;
    }

    $onInit() {
        this.MOPService.setMop([{ title: 'TYPEBENNES.BREADCRUMBS.TYPEBENNES_LIST', filename: 'Vehicules.pdf', application: 'gestion' }]);
        this.unite = undefined;
        this.loading = false;
        this.canEdit = true;
        this.editCode = undefined;
        this.reset();
        this.getUnites();
    }

    $onDestroy = () => {
        this.MOPService.resetMop();
    };
    // Public
    async reset() {
        this.startLoading();

        let data = {};

        if (this.$stateParams.id) {
            try {
                data = await this.TypeBennesService.getTypeBenneById(this.$stateParams.id);
            } catch (ex) {
                this.notification.error(ex.data || ex);
                this.backToPreviousState();
            }
        }
        this.canEdit = data.canEdit !== false;
        this.typeBenne = new TypeBenne(data);

        if (this.typeBenne.code) {
            this.editCode = this.typeBenne.code;
            this.checkCodeUnicity(this.editCode);
        }

        if (this.typeBenne.idUnite) {
            this.unite = {
                id: this.typeBenne.idUnite
            };
        } else {
            this.unite = undefined;
        }

        const tm = this.$timeout(() => {
            this.stopLoading();
            this.$timeout.cancel(tm);
        });
    }

    confirmer() {
        if (this.checkValidity()) {
            if (this.typeBenne.id) {
                this.updateTypeBenne();
            } else {
                this.createTypeBenne();
            }
        }
    }

    checkValidity() {
        //this.closeAllOnglets();
        let validity = true;

        let firstScroll = true;
        if (!this.typeBenne.isValid()) {
            if (firstScroll) {
                firstScroll = false;
            }

            validity = false;
            this.$scope.$broadcast('typeBenneValidations');
        }

        return validity;
    }

    annuler() {
        this.backToPreviousState();
    }

    // selectUnite(unite) {
    //     this.typeBenne.idUnite = undefined;
    //     this.$scope.$broadcast('TypeBenne.idUnitePropertyClearValidation');

    //     const tm = this.$timeout(() => {
    //         this.typeBenne.idUnite = unite.id;
    //         this.unite = unite;
    //         this.$timeout.cancel(tm);
    //     });
    // }

    async getUnites() {
        this.startLoading();
        try {
            const res = await this.TypeBennesService.getUnites();
            this.listeUnites = res.items;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async checkCodeUnicity(code) {
        if (code !== this.editCode) {
            this.typeBenne.codeExists = null;

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.typeBenne.codeExists = await this.TypeBennesService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            }
        } else {
            this.typeBenne.codeExists = false;
        }
    }

    // Private
    async updateTypeBenne() {
        if (!this.validateTypeBenne()) {
            return;
        }

        this.startLoading();
        try {
            await this.TypeBennesService.updateTypeBenne(this.typeBenne.id, this.typeBenne);
            this.notification.success(this.$translate.instant('EDITER_SUCCESS', { entity: this.typeBenne.code }));
            this.backToPreviousState();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async createTypeBenne() {
        if (!this.validateTypeBenne()) {
            return;
        }
        this.startLoading();

        try {
            await this.TypeBennesService.createTypeBenne(this.typeBenne);
            this.notification.success(this.$translate.instant('AJOUTER_SUCCESS', { entity: this.typeBenne.code }));
            this.backToPreviousState();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    validateTypeBenne() {
        if (!this.typeBenne.isValid() || this.typeBenne.codeExists) {
            this.$scope.$broadcast('TypeBenneValidations');
            return false;
        }

        return true;
    }

    backToPreviousState() {
        this.$state.go('type-bennes.list');
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
