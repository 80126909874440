import ModeleSaisie from './modele.saisie.model';
import { FilterType } from '../../../../../core/interface/FilterProperty';
import { SiteGenre } from '../../../../../core/enum/SiteGenre';

/* @ngInject */
export default class ModeleSaisieFormController {
    mobileWarning = false;
    trameWarning = false;

    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        ModelesSaisiesCommunicationService,
        ModelesSaisiesService,
        notification,
        $location,
        $anchorScroll,
        _,
        MassiaApplicationService,
        $uibModal,
        $filter,
        $translate,
        ProduitsService,
        PersonnesService,
        FamillesService,
        SiteService,
        ModalService,
        MOPService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ModelesSaisiesCommunicationService = ModelesSaisiesCommunicationService;
        this.ModelesSaisiesService = ModelesSaisiesService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this._ = _;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$uibModal = $uibModal;
        this.$filter = $filter;
        this.$translate = $translate;
        this.ProduitsService = ProduitsService;
        this.isCampagne = true;
        this.personnesService = PersonnesService;
        this.FamillesService = FamillesService;
        this.SiteService = SiteService;
        this.ModalService = ModalService;
        this.MOPService = MOPService;
    }

    async $onInit() {
        this.MOPService.setMop([
            { title: 'MODELES_SAISIES.BREADCRUMBS.MODELES_SAISIES_LIST', filename: 'ModelesDeSaisie.pdf', application: 'laboratoire' }
        ]);
        this.personnes = [];
        this.users = [];
        this.modeleSaisie = {};
        this.loading = false;
        const domaineCourant = this.getDomaineCourant();

        if (domaineCourant == 'modelessaisies') {
            this.domaineReference = 'seuilgran';
        } else if (domaineCourant == 'modelessaisiese') {
            this.domaineReference = 'seuils';
        } else if (domaineCourant == 'modelessaisiesm') {
            this.domaineReference = 'seuilctrl';
        } else if (domaineCourant == 'modelessaisiesp') {
            this.domaineReference = 'seuilsecu';
        }
        this.form = {
            entete: {}
        };
        await this.reset();
        await this.getCodeAuto();
        const _this = this;
        if (domaineCourant == 'modelessaisies') {
            await this.getFamilles();
            this.$scope.$watch('$ctrl.modeleSaisie.familleId', function (newValue, oldValue) {
                _this.familleChange();
            });
        } else {
            await this.getThemes();
            this.$scope.$watch('$ctrl.modeleSaisie.themeId', function (newValue, oldValue) {
                _this.themeChange();
            });
        }
        await this.loadPersonnes();

        this.unregister = this.$scope.$watch(
            () => this.isMobile,
            async () => {
                await this.getUsers();
            },
            true
        );
    }

    $onDestroy() {
        this.unregister();
        this.$timeout.cancel(this.updateModeleTimeout);
        this.MOPService.resetMop();
    }

    async getCodeAuto() {
        try {
            this.modeleSaisie.estCodeAuto = await this.ModelesSaisiesService.estCodeAuto(this.getDomaineCourant());
            if (this.modeleSaisie.estCodeAuto) {
                this.modeleSaisie.afficheCode = await this.ModelesSaisiesService.afficheCode(this.getDomaineCourant());
            } else {
                this.modeleSaisie.afficheCode = true;
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async getThemes() {
        this.themes = await this.ModelesSaisiesService.getThemes(this.getDomaineCourant());
        if (this.themes.length == 1) {
            this.modeleSaisie.themeId = this.themes[0].id;
        }
        const eltVide = { id: 0, libelle: '' };
        this.themes.splice(0, 0, eltVide);
    }

    async getFamilles() {
        this.familles = await this.ModelesSaisiesService.getFamilles();
        if (this.familles.length == 1) {
            this.modeleSaisie.familleId = this.familles[0].idFamille;
        }
        const eltVide = { idFamille: 0, nom: '' };
        this.familles.splice(0, 0, eltVide);
    }

    async getTypesCM() {
        //this.startLoading();
        try {
            this.typesCM = await this.ModelesSaisiesService.getTypesCM(this.getDomaineCourant(), this.modeleSaisie.themeId);
            if (this.typesCM.length == 1) {
                this.modeleSaisie.typeCMId = this.typesCM[0].id;
            }
            const eltVide = { id: 0, libelle: '' };
            this.typesCM.splice(0, 0, eltVide);
        } catch (ex) {
            this.notification.error(ex.data);
        } /* finally {
            this.stopLoading();
        }*/
    }

    async getTrames() {
        //this.startLoading();
        try {
            this.trames = await this.ModelesSaisiesService.getTrames(
                this.getDomaineCourant(),
                this.modeleSaisie.themeId,
                this.modeleSaisie.siteProdId,
                this.modeleSaisie.familleId
            );
            if (this.trames.length == 1) {
                this.modeleSaisie.trameId = this.trames[0].id;
            }
            // var eltVide = { id: 0, libelle: '' };
            // this.trames.splice(0, 0, eltVide);
            if (this.modeleSaisie && this.modeleSaisie.trameId) {
                const item = this.trames.find((x) => x.id == this.modeleSaisie.trameId);
                if (item) {
                    item.selected = true;
                }
            }
            this.toggleMobile();
        } catch (ex) {
            this.notification.error(ex.data);
        } /*finally {
            this.stopLoading();
        }*/
    }

    async getTypesSM() {
        //this.startLoading();
        try {
            this.typesSM = await this.ModelesSaisiesService.getTypesSM(
                this.getDomaineCourant(),
                this.modeleSaisie.themeId,
                this.modeleSaisie.familleId
            );
            if (this.typesSM.length == 1) {
                this.modeleSaisie.typeSMId = this.typesSM[0].id;
            }
            const eltVide = { id: 0, libelle: '' };
            this.typesSM.splice(0, 0, eltVide);
        } catch (ex) {
            this.notification.error(ex.data);
        } /* finally {
            this.stopLoading();
        }*/
    }

    async themeChange() {
        if (this.modeleSaisie.themeId) {
            this.isCampagne = await this.ModelesSaisiesService.hasThemeCampagne(this.modeleSaisie.themeId);
        } else {
            this.isCampagne = true;
        }
        await this.getTypesCM();
        await this.getTypesSM();
        await this.getTrames();
        await this.getPointsMesures();
        await this.getReferences();
    }

    async familleChange() {
        this.isCampagne = false;
        await this.getTypesCM();
        await this.getTypesSM();
        await this.getTrames();
        await this.getProduits();
        await this.getReferences();
    }

    async getSitesIntervenants() {
        // let sites = [];
        this.startSiteAuditeurLoading();
        try {
            if (this.getDomaineCourant() == 'modelessaisies') {
                this.sitesAuditeur = await this.ModelesSaisiesService.getSitesLaboratoires();
            } else {
                this.sitesAuditeur = await this.ModelesSaisiesService.getSitesIntervenants();
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopSiteAuditeurLoading();
        }
        if (this.modeleSaisie && this.modeleSaisie.siteLaboId) {
            const item = this.sitesAuditeur.find((x) => x.id === this.modeleSaisie.siteLaboId);
            if (item) {
                item.selected = true;
            }
        }
        // return sites;
    }

    async getSitesProducteurs() {
        this.startSiteProducteurLoading();
        try {
            this.sitesProducteur = await this.ModelesSaisiesService.getSitesProducteurs(
                this.MassiaApplicationService.getApplication(),
                this.modeleSaisie.siteLaboId
            );
            if (this.modeleSaisie && this.modeleSaisie.siteProdId) {
                this.sitesProducteur.find((x) => x.id === this.modeleSaisie.siteProdId).selected = true;
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopSiteProducteurLoading();
        }
    }

    async getSitesClient() {
        this.startSiteClientLoading();
        try {
            const filters = [
                {
                    criterion: 'genre',
                    type: FilterType.EQUALS,
                    value: SiteGenre.Client
                }
            ];
            const sites = await this.SiteService.getAll(filters, [], {}, {}, { application: this.MassiaApplicationService.getApplication() });
            this.sitesClient = sites.items;

            if (this.modeleSaisie && this.modeleSaisie.siteClientId) {
                const item = this.sitesClient.find((x) => x.id === this.modeleSaisie.siteClientId);
                if (item) {
                    item.selected = true;
                }
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopSiteClientLoading();
        }
    }

    async getProduits() {
        this.startProduitLoading();
        try {
            if (this.modeleSaisie) {
                if (this.modeleSaisie.siteProd && !this.modeleSaisie.familleId) {
                    this.produits = await this.ProduitsService.getProduitByProducteur(this.modeleSaisie.siteProdId);
                } else if (this.modeleSaisie.familleId && !this.modeleSaisie.siteProdId) {
                    const famille = await this.FamillesService.getOneById(this.modeleSaisie.familleId);
                    this.produits = await this.ProduitsService.getAllProductsByFamille(famille.code);
                } else if (this.modeleSaisie.siteProdId && this.modeleSaisie.familleId) {
                    this.produits = await this.ProduitsService.getProduitsByFamilleProducteur(
                        this.modeleSaisie.familleId,
                        this.modeleSaisie.siteProdId
                    );
                } else {
                    const produits = await this.ProduitsService.getProduits();
                    this.produits = produits.items;
                }
                if (this.modeleSaisie && this.modeleSaisie.produitId) {
                    const item = this.produits.find((x) => x.id === this.modeleSaisie.produitId);
                    if (item) {
                        item.selected = true;
                    }
                }
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopProduitLoading();
        }
    }

    getApplication() {
        return this.MassiaApplicationService.getApplication();
    }

    getDomaineCourant() {
        if (
            this.$state.current.name === 'modelessaisies.edit' ||
            this.$state.current.name === 'modelessaisies.new' ||
            this.$state.current.name === 'modelessaisies.duplicate'
        ) {
            return 'modelessaisies';
        } else if (
            this.$state.current.name === 'modelessaisiese.edit' ||
            this.$state.current.name === 'modelessaisiese.new' ||
            this.$state.current.name === 'modelessaisiese.duplicate'
        ) {
            return 'modelessaisiese';
        } else if (
            this.$state.current.name === 'modelessaisiesp.edit' ||
            this.$state.current.name === 'modelessaisiesp.new' ||
            this.$state.current.name === 'modelessaisiesp.duplicate'
        ) {
            return 'modelessaisiesp';
        } else if (
            this.$state.current.name === 'modelessaisiesm.edit' ||
            this.$state.current.name === 'modelessaisiesm.new' ||
            this.$state.current.name === 'modelessaisiesm.duplicate'
        ) {
            return 'modelessaisiesm';
        }
    }

    async reset() {
        this.startLoading();
        let data = {
            id: this.$stateParams.id
        };

        if (data.id) {
            try {
                data = await this.ModelesSaisiesService.getModeleById(data.id);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
                this.annuler();
            }
        }
        this.modeleSaisie = new ModeleSaisie(data);
        if (data.id && this.modeleSaisie.isMobile && !this.modeleSaisie.trameId) {
            this.trameWarning = true;
        }
        if (this.$stateParams.duplicate) {
            this.modeleSaisie.id = undefined;
            this.modeleSaisie.code += '_copie';
        }
        this.modeleSaisie.domaineCode = this.getDomaineCourant();
        await this.getReferences();
        await this.getSitesIntervenants();
        await this.getSitesProducteurs();
        if (this.modeleSaisie.domaineCode != 'modelessaisies') {
            await this.getPointsMesures();
        } else {
            await this.getProduits();
        }
        await this.getSitesClient();
        this.selectedReferences = [];
        this.$timeout.cancel(this.updateModeleTimeout);
        this.updateModeleTimeout = this.$timeout(() => this.stopLoading());
        if (this.modeleSaisie.isMobile) {
            await this.getUsers();
        }
    }

    async getPointsMesures() {
        //this.startLoading();
        try {
            if (this.modeleSaisie.siteProdId && this.modeleSaisie.siteProdId > 0) {
                this.pointsMesures = await this.ModelesSaisiesService.getPointsMesures(this.modeleSaisie.themeId, this.modeleSaisie.siteProdId);
                if (this.modeleSaisie && this.modeleSaisie.pointsMesuresIds) {
                    this.pointsMesures.forEach((item) => {
                        if (this.modeleSaisie.pointsMesuresIds.indexOf(item.id) !== -1) {
                            item.selected = true;
                        }
                    });
                }
            } else {
                this.pointsMesures = [];
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } /*finally {
            this.stopLoading();
        }*/
    }

    async getReferences() {
        //this.startLoading();
        try {
            this.references = await this.ModelesSaisiesService.getReferences(
                this.getDomaineCourant(),
                this.modeleSaisie.themeId,
                this.modeleSaisie.siteProdId,
                this.modeleSaisie.familleId
            );
            if (this.modeleSaisie && this.modeleSaisie.references) {
                this.references.forEach((item) => {
                    const idx = this._.findIndex(this.modeleSaisie.references, { referenceId: item.id });
                    if (idx > -1) {
                        item.selected = true;
                        if (this.modeleSaisie.references[idx].estDefaut) {
                            this.referenceDefautId = this.modeleSaisie.references[idx].referenceId;
                            const refTmp = this.modeleSaisie.references[idx];
                            if (refTmp.estDefaut) {
                                refTmp.estDefautRadio = `${refTmp.referenceId}`;
                            }
                        }
                        if (this.modeleSaisie.references[idx].estEnregistre) {
                            this.referenceConformiteId = this.modeleSaisie.references[idx].referenceId;
                            const refTmp = this.modeleSaisie.references[idx];
                            if (refTmp.estEnregistre) {
                                refTmp.estEnregistreRadio = `${refTmp.referenceId}`;
                            }
                        }
                    }
                });
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } /*finally {
            this.stopLoading();
        }*/
    }

    setSelectedReferences() {
        this._.forEach(this.references, (reference) => {
            if (reference.selected) {
                this.selectReference(reference);
            } else {
                this.deleteReference(reference);
            }
        });
    }

    checkIfFirstRef(index, reference) {
        if (index === 0) {
            reference.estEnregistreRadio = `${reference.referenceId}`;
            reference.estEnregistre = true;
        }
    }

    selectReference(reference) {
        const index = this._.findIndex(this.modeleSaisie.references, { referenceId: reference.id });
        if (index < 0) {
            const newRef = {};
            newRef.id = undefined;
            newRef.referenceCodeLibelle = reference.libelle + ' (' + reference.code + ')';
            newRef.referenceId = reference.id;
            newRef.estDefaut = false;
            newRef.estEnregistre = false;
            this.modeleSaisie.references.push(newRef);
        }

        this.$scope.$broadcast('ModeleSaisie.referencesPropertyClearValidation');
    }

    deleteReference(reference) {
        const index = this._.findIndex(this.modeleSaisie.references, { referenceId: reference.id });
        if (index >= 0) {
            this.modeleSaisie.references.splice(index, 1);
            reference.selected = false;
        }
    }

    changeReferentielDefaut(reference) {
        const index = this._.findIndex(this.modeleSaisie.references, { referenceId: reference.referenceId });
        this.referenceDefautId = this.modeleSaisie.references[index].referenceId;
        for (let i = 0; i < this.modeleSaisie.references.length; i++) {
            this.modeleSaisie.references[i].estDefaut = i == index;
        }
    }

    changeReferentielConformite(reference) {
        const index = this._.findIndex(this.modeleSaisie.references, { referenceId: reference.referenceId });
        this.referenceConformiteId = this.modeleSaisie.references[index].referenceId;
        for (let i = 0; i < this.modeleSaisie.references.length; i++) {
            this.modeleSaisie.references[i].estEnregistre = i == index;
        }
    }

    async deleteButtonReference(reference) {
        const index = this._.findIndex(this.modeleSaisie.references, { referenceId: reference.referenceId });
        if (index >= 0) {
            this.modeleSaisie.references.splice(index, 1);
            reference.selected = false;
        }
        await this.getReferences();
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            if (this.modeleSaisie.isMobile) {
                try {
                    await this.checkTrame();
                } catch (error) {
                    this.modeleSaisie.isMobile = false;
                }
            }
            this.startLoading();
            try {
                let id = false;
                if (this.modeleSaisie.id) {
                    await this.ModelesSaisiesService.updateModele(this.modeleSaisie);
                    this.notification.success('MODELES_SAISIES.UPDATED');
                    id = this.modeleSaisie.id;
                } else {
                    id = await this.ModelesSaisiesService.createModele(this.modeleSaisie);
                    this.notification.success('MODELES_SAISIES.CREATED');
                }
                await this.getTrames();
                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async appliquer() {
        const id = await this.sauvegarder();

        if (id && this.modeleSaisie.id) {
            this.reset();
        } else if (id) {
            this.$state.go(this.getDomaineCourant() + '.edit', { id: id });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    annuler() {
        this.$state.go(this.getDomaineCourant() + '.list');
    }

    checkValidity() {
        let validity = true;

        //let firstScroll = true;
        if (!this.modeleSaisie.isValid()) {
            /*if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}

			this.ongletOpen.isEnteteOpen = true;*/
            validity = false;
            this.$scope.$broadcast('modeleSaisieValidations');
        }
        // validation pour l'export en mobilité
        if (this.modeleSaisie.isMobile && (!this.modeleSaisie.trameId || !this.modeleSaisie.typeSMId)) {
            validity = false;

            if (!this.modeleSaisie.trameId) {
                this.notification.error('MODELES_SAISIES.NOTIFICATIONS_ERROR_MOBILE.MISSING_TRAME');
            }

            if (!this.modeleSaisie.typeSMId) {
                this.notification.error('MODELES_SAISIES.NOTIFICATIONS_ERROR_MOBILE.MISSING_TYPE');
            }
        }

        return validity;
    }

    /*scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}  */

    // setModeleSaisieAsTypeAhead() {
    //     this.siteProducteur = {
    //         id: this.modeleSaisie.siteProdId,
    //         libelle: this.modeleSaisie.siteProd
    //     }
    //     this.siteAuditeur = {
    //         id: this.modeleSaisie.siteLaboId,
    //         libelle: this.modeleSaisie.siteLabo
    //     }
    //     this.produit = {
    //         id: this.modeleSaisie.produitId,
    //         libelle: this.modeleSaisie.produit
    //     }
    //     this.siteClient = {
    //         id: this.modeleSaisie.siteClientId,
    //         libelle: this.modeleSaisie.siteClient
    //     }
    // }

    async selectMassiaMultiSelect(val, data) {
        //if (!this.modeleSaisie) this.modeleSaisie = {};
        switch (val) {
            case 'siteprod':
                if (data.selected) {
                    this.modeleSaisie.siteProdId = data.id;
                    this.modeleSaisie.siteProd = data.libelle;
                    await this.getTrames();
                    if (this.getDomaineCourant() != 'modelessaisies') {
                        await this.getPointsMesures();
                    } else {
                        await this.getProduits();
                    }
                    await this.getReferences();
                } else {
                    this.videSiteProducteur();
                }
                break;
            case 'produit':
                if (data.selected) {
                    this.modeleSaisie.produitId = data.id;
                    this.modeleSaisie.produit = data.libelle;
                    await this.getTrames();
                    await this.getReferences();
                } else {
                    this.videProduit();
                }
                break;
            case 'siteaudit':
                if (data.selected) {
                    this.modeleSaisie.siteLaboId = data.id;
                    this.modeleSaisie.siteLabo = data.libelle;
                    await this.getSitesProducteurs();
                } else {
                    this.videSiteAuditeur();
                }
                this.loadPersonnes();
                break;
            case 'personne':
                if (data.selected) {
                    this.modeleSaisie.personneId = data.idSalarie;
                    this.modeleSaisie.personne = data.libelle;
                } else {
                    this.videPersonne();
                }
                break;
            case 'siteclient':
                if (data.selected) {
                    this.modeleSaisie.siteClientId = data.id;
                    this.modeleSaisie.siteClient = data.libelle;
                } else {
                    this.videSiteClient();
                }
                break;
            case 'references':
                if (data.selected) {
                    this.selectReference(data);
                } else {
                    this.deleteReference(data);
                }
                break;
            case 'trame':
                if (data.selected) {
                    this.modeleSaisie.trameId = data.id;
                    this.trameWarning = false;
                    if (this.modeleSaisie.isMobile && !data.isMobile) {
                        this.mobileWarning = true;
                    }
                } else {
                    this.modeleSaisie.trameId = 0;
                    this.mobileWarning = false;
                    if (this.modeleSaisie.isMobile) {
                        this.trameWarning = true;
                    }
                }
                break;
            case 'user':
                this.modeleSaisie.users = this.usersList;
                break;
        }
    }

    videSiteProducteur() {
        this.siteProducteur = {
            id: undefined,
            libelle: ''
        };
        this.modeleSaisie.siteProdId = 0;
        this.modeleSaisie.siteProd = undefined;
    }

    videProduit() {
        this.produit = {
            id: undefined,
            code: '',
            libelle: ''
        };
        this.modeleSaisie.produitId = 0;
        this.modeleSaisie.produit = undefined;
    }

    videSiteAuditeur() {
        this.siteAuditeur = {
            id: undefined,
            libelle: ''
        };
        this.modeleSaisie.siteLaboId = 0;
        this.modeleSaisie.siteLabo = undefined;
    }

    videSiteClient() {
        this.siteClient = {
            id: undefined,
            libelle: ''
        };
        this.modeleSaisie.siteClientId = 0;
        this.modeleSaisie.siteClient = undefined;
    }

    videPersonne() {
        this.personne = {
            id: undefined,
            libelle: ''
        };
        this.modeleSaisie.personneId = 0;
        this.modeleSaisie.personne = undefined;
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.modeleSaisie.codeExists = await this.ModelesSaisiesService.codeExists(code);
                }
            } catch (ex) {
                console.error(ex);

                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.modeleSaisie.codeExists = null;
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }

    startSiteProducteurLoading() {
        this.loadingSiteProducteurs = true;
    }

    stopSiteProducteurLoading() {
        this.loadingSiteProducteurs = false;
    }

    startProduitLoading() {
        this.loadingProduits = true;
    }

    stopProduitLoading() {
        this.loadingProduits = false;
    }

    startSiteAuditeurLoading() {
        this.loadingSiteIntervenants = true;
    }

    stopSiteAuditeurLoading() {
        this.loadingSiteIntervenants = false;
    }

    startSiteClientLoading() {
        this.loadingSiteClient = true;
    }

    stopSiteClientLoading() {
        this.loadingSiteClient = false;
    }

    startPersonnesLoading() {
        this.loadingPersonnes = true;
    }

    stopPersonnesLoading() {
        this.loadingPersonnes = false;
    }

    toggleMobile() {
        if (this.modeleSaisie.isMobile && !this.modeleSaisie.trameId) {
            this.trameWarning = true;
        }
        if (this.modeleSaisie.isMobile && this.modeleSaisie.trameId) {
            const trame = this.trames.find((x) => x.id === this.modeleSaisie.trameId);
            if (!trame.isMobile) {
                this.mobileWarning = true;
            } else {
                this.mobileWarning = false;
            }
        }
        if (!this.modeleSaisie.isMobile) {
            this.trameWarning = false;
            this.mobileWarning = false;
        }
    }

    async getUsers() {
        this.users = await this.personnesService.getUtilisateurs();
    }

    async loadPersonnes() {
        if (this.modeleSaisie.siteLaboId) {
            this.stopPersonnesLoading();
            this.personnes = await this.ModelesSaisiesService.getPersonnes(this.modeleSaisie.siteLaboId);
            this.personnes = this.$filter('selectInIsteven')(this.personnes, 'idSalarie', this.modeleSaisie.personneId, 'selected');
        }
    }

    async checkTrame() {
        const trame = this.trames.find((x) => x.id === this.modeleSaisie.trameId);

        if (!trame.isMobile) {
            const modalInstance = this.ModalService.confirm({
                modalTitle: this.$translate.instant('MODELES_SAISIES.WARNING_TITLE'),
                modalMsg: this.$translate.instant('MODELES_SAISIES.WARNING_MESSAGE'),
                headerClass: 'modal-info'
            });

            return await modalInstance.result;
        }
    }
}
