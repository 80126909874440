import DonneeFiltreesConfig from './_config/donnee.filtrees.config';
import DonneeFiltreesRoutes from './_config/donnee.filtrees.routes';
import DonneeFiltreesComponent from './components/donnee-filtrees';
import DonneeFiltreesService from './services/donnee.filtrees.service';

const moduleName = 'app.massia.common.donneeFiltrees';

angular
    .module(moduleName, [])
    .config(DonneeFiltreesConfig)
    .config(DonneeFiltreesRoutes)
    .service('DonneeFiltreesService', DonneeFiltreesService)
    .component('donneeFiltrees', DonneeFiltreesComponent);

export default moduleName;
