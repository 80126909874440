(function (angular, undefined) {
    'use strict';

    angular.module('blocks.smart.table')
        .filter('acTableFilter', acTableFilter);
    acTableFilter.$inject = ['$filter', '_', 'moment', 'javascriptHelper'];

    function acTableFilter($filter, _, moment, javascriptHelper) {
        var filterFilter = $filter('filter');

        /**
         * array : le tableau complet
         * expression : l'expression envoyée par le search de blocks.smart.table
         * { id: '67', equipement: ['abri', 'parc'], ligne: '4'}
         */
        return function (array, expression) {
            var output = array;
            var subexpression;

            // on filtre dans l'ordre d'arrivée des expressions
            angular.forEach(expression, function (exp, predicate) {
                if (Array.isArray(exp)) {
                    // si c'est un tableau on filtre via lodash
                    output = _.findByValues(output, predicate, exp);
                }
                else if (angular.isObject(exp) && exp.value && exp.comparator) {
                    // si c'est un objet et qu'il définit un comparateur
                    // (exemple : comparator:true signifie comparaison stricte)
                    subexpression = {};
                    subexpression[predicate] = exp.value;
                    output = filterFilter(output, subexpression, exp.comparator);
                }
                else if (angular.isObject(exp) && (exp.min !== undefined || exp.max !== undefined)) {
                    // si c'est un objet qui définit un min ou un max
                    subexpression = {};
                    subexpression[predicate] = exp;
                    output = filterFilter(output, subexpression, function (actual, expected) {
                        return (expected.min === undefined || actual >= expected.min)
                            && (expected.max === undefined || actual <= expected.max);
                    });
                }
                else if (angular.isObject(exp) && (exp.dateMin || exp.dateMax)) {
                    // si c'est un objet qui définit une dateMin ou une dateMax
                    subexpression = {};
                    subexpression[predicate] = exp;
                    output = filterFilter(output, subexpression, function (actual, expected) {
                        var actualDate = moment(new Date(actual));
                        var dateMinDate = expected.dateMin;
                        var dateMaxDate = expected.dateMax;

                        return (dateMinDate === undefined || !actualDate.isBefore(dateMinDate, 'day'))
                            && (dateMaxDate === undefined || actualDate.isBefore(dateMaxDate.add(1, 'days'), 'day'));
                    });
                }
                else if (angular.isObject(exp) && (exp.yes || exp.no)) {
                    // si c'est un objet qui définit un booléen
                    subexpression = {};
                    subexpression[predicate] = exp;
                    output = filterFilter(output, subexpression, function (actual, expected) {
                        return (expected.yes == true && actual) || (expected.no == true && !actual);
                    });
                }
                else {
                    // sinon on créé une sous expression et on l'envoie au filtre angular
                    // {id: '67'}
                    subexpression = {};
                    subexpression[predicate] = exp;
                    output = filterFilter(output, subexpression, function(value, predicate) {
                        var cleanValue = javascriptHelper.removeDiacritic(value);
                        var cleanPredicate = javascriptHelper.removeDiacritic(predicate);
                        return cleanValue.indexOf(cleanPredicate) > -1;
                    });
                }
            });

            return output;
        };
    }

})(angular);