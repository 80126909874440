import ModalBlocSelection from './synthese.modal.bloc.selection.controller';

export default {
    bindings: {
        modalInstance: '<',
        close: '<',
        dismiss: '&',
        resolve: '<',
        type: '<'
    },
    controller: ModalBlocSelection,
    template: require('./synthese.modal.bloc.selection.html')
};
