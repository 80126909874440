export default class MonitoringDetailController {
    static $inject = ['$stateParams', '$state', 'MonitoringService', 'notification'];

    constructor($stateParams, $state, MonitoringService, notification) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.MonitoringService = MonitoringService;
        this.notification = notification;
    }

    $onInit() {
        this.loading = false;

        this.ongletOpen = {
            isDetailOpen: true,
            isEvenementOpen: true,
            isControleOpen: false
        };

        this.initMonitoringDetail();
    }

    async initMonitoringDetail() {
        const id = this.$stateParams.id;

        if (id) {
            this.startLoading();
            try {
                this.monitoringDetail = await this.MonitoringService.getMonitoringDetail(id);
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListMonitoring();
        }
    }

    goListMonitoring() {
        this.$state.go('monitoring.list', {}, { reload: true });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
