import Tache from './tache.model';

export default class TacheFormController {
    static $inject = [
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'TachesCommunicationService',
        'TachesService',
        'notification',
        '$location',
        '$anchorScroll',
        '_',
        '$uibModal',
        'MassiaApplicationService'
    ];

    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        TachesCommunicationService,
        TachesService,
        notification,
        $location,
        $anchorScroll,
        _,
        $uibModal,
        MassiaApplicationService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.TachesCommunicationService = TachesCommunicationService;
        this.TachesService = TachesService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this._ = _;
        this.$uibModal = $uibModal;
        this.MassiaApplicationService = MassiaApplicationService;
        this.ongletOpen = {
            isEnteteOpen: false,
            isCaracteristiqueOpen: false
        };
        this.ongletOpenVal = {
            isEnteteOpen: 'entete',
            isCaracteristiqueOpen: 'caracteristiques'
        };
    }

    $onInit() {
        this.tache = {};
        this.loading = false;
        this.form = {
            entete: {},
            caracteristiques: {}
        };

        this.reset();
    }

    $onDestroy() {
        this.$timeout.cancel(this.updateTacheTimeout);
    }

    async reset() {
        this.startLoading();

        const data = {
            id: this.$stateParams.id
        };

        if (data.id) {
            try {
                data.entete = await this.TachesService.getTacheEnteteById(data.id);
                data.caracteristiques = await this.TachesService.getTacheValeursCaracteristiquesAssociationsById(data.id);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
                this.annuler();
            }
        }
        this.tache = new Tache(data);
        if (this.$stateParams.duplicate) {
            this.tache.id = undefined;
            this.tache.entete.code += '_copie';
        }
        this.ongletOpen = {
            isEnteteOpen: true,
            isCaracteristiqueOpen: false
        };

        this.$timeout.cancel(this.updateTacheTimeout);
        this.updateTacheTimeout = this.$timeout(() => this.stopLoading());
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                let id = false;
                if (this.tache.id) {
                    await this.TachesService.updateTache(this.tache);
                    this.notification.success('TACHES.UPDATED');
                    id = this.tache.id;
                } else {
                    id = await this.TachesService.createTache(this.tache);
                    this.notification.success('TACHES.CREATED');
                }

                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async appliquer() {
        const id = await this.sauvegarder();

        if (id && this.tache.id) {
            this.reset();
        } else if (id) {
            this.$state.go('taches.edit', { id: id });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    annuler() {
        if (this.$stateParams.idOrigine) {
            this.returnOrigine();
        } else {
            this.$state.go('taches.list');
        }
    }

    returnOrigine() {
        const origine = this.$stateParams.idOrigine;
        const domaine = this.$stateParams.domaine;
        const theme = this.$stateParams.theme;
        const application = this.MassiaApplicationService.getApplication();
        switch (domaine) {
            case '0':
                this.$state.go('journal-bord.edit', { id: origine });
                break;
        }
    }

    checkValidity() {
        let validity = true;

        let firstScroll = true;
        if (!this.tache.entete.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('entete');
                firstScroll = false;
            }

            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.$scope.$broadcast('tacheEnteteValidations');
        }
        if (!this.tache.caracteristiques.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('caracteristiques');
                firstScroll = false;
            }

            this.ongletOpen.isCaracteristiqueOpen = true;
            validity = false;
            this.$scope.$broadcast('tacheCaracteristiquesValidations');
        }
        return validity;
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    onUpdateEntete(entete) {
        this.tache.entete = entete;
    }

    onUpdateCaracteristiques(caracteristiques) {
        this.tache.caracteristiques = caracteristiques;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
