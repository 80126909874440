JoursFeriesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'JoursFeriesService',
	'notification',
	'$transitions',
	'MOPService'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function JoursFeriesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	JoursFeriesService,
	notification,
	$transitions,
	MOPService
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	let selectedJourFerieId = undefined;
	vm.lastSelected = undefined;

	vm.loading = false;
	vm.params = $stateParams;
	vm.joursFeries = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};

	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectJourFerie = selectJourFerie;
	vm.isJourFerieSelected = isJourFerieSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadJoursFeries = loadJoursFeries;
	vm.deleteJourFerie = deleteJourFerie;
	vm.setSelected = setSelected;
	vm.duplicateJourFerie = duplicateJourFerie;
	vm.MOPService = MOPService;

	vm.$onInit = () => {
		vm.MOPService.setMop([{ title: 'JOURS_FERIES.BREADCRUMBS.JOURS_FERIES_LIST', filename: 'JoursFeries.pdf', application: 'massia' }]);
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "jours-feries.list", on désélectionne la ligne sélectionnée (s'il y en a)
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		//$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		//quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	};

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			vm.setSelected();
		} else {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(jferie) {
		if (jferie && jferie.id) {
			vm.selectedJourFerieId = $stateParams.id;
			vm.lastSelected = vm.selectedJourFerieId;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			vm.selectedJourFerieId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state && $state.current && $state.current.name && $state.current.name != 'jours-feries.list' && $stateParams && $stateParams.id;
	}

	function duplicateJourFerie(jferie) {
		if (jferie && jferie.id) {
			$state.go('jours-feries.duplicate', { id: jferie.id });
		}
	}

	function selectJourFerie(jFerie) {
		if (jFerie && jFerie.id) {
			//si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedJourFerieId, et charger la vue détail pour les jours fériés sélectionnés
			if (vm.selectedJourFerieId !== jFerie.id) {
				vm.selectedJourFerieId = jFerie.id;
				showDetail(jFerie.id);
			} else {
				//si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
				vm.selectedJourFerieId = undefined;
				closeDetail();
			}
		}
	}

	function isJourFerieSelected(jFerie) {
		return jFerie && jFerie.id && selectedJourFerieId === jFerie.id;
	}

	function showDetail(jFerieId) {
		$state.go('jours-feries.list.detail', { id: jFerieId });
	}

	function closeDetail() {
		$state.go('jours-feries.list');
	}

	async function loadJoursFeries(tableState) {
		startLoading();

		//quand on rafraîchit la grille, on revient sur "zone-abattages.list", et on ferme la vue détail d'un zoneAbattage
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== 'jours-feries.list') {
			$state.go('jours-feries.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.joursFeries = [];

		try {
			const data = await JoursFeriesService.getJoursFeries(filters, sorts, pagination);

			vm.joursFeries = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteJourFerie(jFerie) {
		if (jFerie && jFerie.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('JOURS_FERIES.DELETE.TITLE', { libelle: jFerie.libelle }),
				modalMsg: $translate.instant('JOURS_FERIES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await JoursFeriesService.deleteJourFerieById(jFerie.id);
					notification.success($translate.instant('JOURS_FERIES.DELETE.SUCCESS'));

					selectedJourFerieId = undefined;

					loadJoursFeries();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
