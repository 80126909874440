const typesESByValue = {
    0: 'entree',
    1: 'sortie'
};

const typesESValuesByName = {
    entree: 0,
    sortie: 1
};

export function getTypeESNamesByValues() {
    return angular.copy(typesESByValue);
}

export function getTypeESNamesWithValues() {
    return angular.copy(typesESValuesByName);
}
