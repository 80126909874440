import ZoneAbattage from './zone.abattages.model';

export default class ZoneAbattageFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'ZoneAbattagesCommunicationService',
        'ZoneAbattagesService',
        'notification',
        '$location',
        'MassiaApplicationService'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        ZoneAbattagesCommunicationService,
        ZoneAbattagesService,
        notification,
        $location,
        MassiaApplicationService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ZoneAbattagesCommunicationService = ZoneAbattagesCommunicationService;
        this.ZoneAbattagesService = ZoneAbattagesService;
        this.notification = notification;
        this.$location = $location;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    async $onInit() {
        this.isEditMode = false;
        this.zoneAbattage = {};
        this.sites = this.sites || [];
        this.loading = false;
        this.setZoneAbattage(this.$stateParams.id);
        this.unregisterReinit = this.ZoneAbattagesCommunicationService.registerReinit(() => this.initForm());
        this.getSites();
        this.initForm();
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    initForm() {
        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
            const thatZoneAbattage = {
                id: this.zoneAbattage.id,
                code: this.zoneAbattage.code,
                libelle: this.zoneAbattage.libelle
            };
        } else {
            this.isEditMode = false;
        }
    }

    async getSites() {
        this.startLoading();
        try {
            this.sites = await this.ZoneAbattagesService.getSites(this.MassiaApplicationService.getApplication());
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async setZoneAbattage(zoneAbattageId) {
        if (zoneAbattageId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                const stillThatZoneAbattage = await this.ZoneAbattagesService.getZoneAbattageById(zoneAbattageId);
                this.zoneAbattage = new ZoneAbattage(stillThatZoneAbattage);
            } catch (ex) {
                if (ex.status === 404) {
                    if (this.MassiaApplicationService.getApplication() == 'performance') {
                        this.$state.go('zone-abattages.list', {}, { reload: true });
                    } else {
                        this.$state.go('zone-abattages-qse.list', {}, { reload: true });
                    }
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.zoneAbattage = new ZoneAbattage();
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.zoneAbattage.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.zoneAbattage.codeExists = await this.ZoneAbattagesService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    // boutons de validation
    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                if (this.zoneAbattage.id) {
                    const tmp = await this.updateZoneAbattage();
                    this.notification.success('ZONEABATTAGES.UPDATED');
                    return this.zoneAbattage.id;
                }
                const tmp = await this.createZoneAbattage();
                this.notification.success('ZONEABATTAGES.CREATED');
                return tmp;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async valider() {
        const returnedId = await this.sauvegarder();
        if (returnedId) {
            if (this.MassiaApplicationService.getApplication() == 'performance') {
                this.$state.go('zone-abattages.edit', { id: returnedId });
            } else {
                this.$state.go('zone-abattages-qse.edit', { id: returnedId });
            }
        }
    }
    async validerEtFermer() {
        const returnedId = this.sauvegarder();
        this.backToPreviousState();
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        if (this.MassiaApplicationService.getApplication() == 'performance') {
            this.$state.go('zone-abattages.list');
        } else {
            this.$state.go('zone-abattages-qse.list');
        }
    }

    async createZoneAbattage() {
        this.startLoading();
        try {
            const returnedId = await this.ZoneAbattagesService.createZoneAbattage(this.zoneAbattage);
            this.notification.success('ZONEABATTAGES.CREATED');
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateZoneAbattage() {
        this.startLoading();
        try {
            const returnedId = await this.ZoneAbattagesService.updateZoneAbattage(this.zoneAbattage);
            this.notification.success('ZONEABATTAGES.UPDATED');
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    reinit() {
        this.setZoneAbattage(this.$stateParams.id);
        this.ZoneAbattagesCommunicationService.raiseReinit();
    }

    checkValidity() {
        let validity = true;
        if (!this.zoneAbattage.isValid()) {
            validity = false;
        }
        this.$scope.$broadcast('zoneAbattageValidations');
        return validity;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
