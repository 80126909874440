export default function AuthTranslations($translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.setPart('fr-FR', 'massia.auth', {
        USER_ACCESS_VIOLATION: "Il est nécessaire de s'authentifier pour accéder à cet écran",
        USER_MISSING_RIGHT: "Droit d'affichage manquant",
        USER_MISSING_STATE_RIGHT: "Vous n'avez pas les droits nécessaires à l'affichage de cet écran",
        UNKNOWN_ERROR: 'Une erreur est survenue',
        SERVER_NOT_RESPONDING: 'Le serveur ne répond pas',
        SESSION_EXPIRED: 'Votre session a expiré, veuillez vous authentifier à nouveau.'
    });

    $translatePartialLoaderProvider.setPart('en-US', 'massia.auth', {
        USER_ACCESS_VIOLATION: 'Authentication is required to access this page',
        USER_MISSING_RIGHT: 'Missing display right',
        USER_MISSING_STATE_RIGHT: 'You do not have the necessary rights to access this page',
        UNKNOWN_ERROR: 'An error occured',
        SERVER_NOT_RESPONDING: 'The server is not responding',
        SESSION_EXPIRED: 'Your session has expired , please login again.'
    });

    $translatePartialLoaderProvider.setPart('es-ES', 'massia.auth', {
        USER_ACCESS_VIOLATION: 'Debe autentificarse para acceder  a esta pantalla',
        USER_MISSING_RIGHT: 'Derecho de visualización faltante',
        USER_MISSING_STATE_RIGHT: 'No tiene los derechos para visualizar esta pantallan',
        UNKNOWN_ERROR: 'Un error se produjo',
        SERVER_NOT_RESPONDING: 'El servidor no responde',
        SESSION_EXPIRED: 'Su sesión está cerrada, por favor identifíquese de nuevo.'
    });

    $translatePartialLoaderProvider.setPart('nl-BE', 'massia.auth', {
        USER_ACCESS_VIOLATION: 'Het is noodzakelijk om te verifiëren om toegang te krijgen tot dit scherm',
        USER_MISSING_RIGHT: 'Ontbrekend display rechts',
        USER_MISSING_STATE_RIGHT: 'U beschikt niet over de nodige rechten om dit scherm weer te geven',
        UNKNOWN_ERROR: 'Er is een fout opgetreden',
        SERVER_NOT_RESPONDING: 'De server reageert niet',
        SESSION_EXPIRED: 'Uw sessie is verlopen, gelieve opnieuw te verifiëren.'
    });

    $translatePartialLoaderProvider.addPart('massia.auth');
}

AuthTranslations.$inject = ['$translatePartialLoaderProvider'];
