import validatorBuilder from 'validator-builder';

let prixValidator = null;

export default class Prix {
    constructor(data = {}) {
        this.id = data.id;
        this.type = data.type;
        this.estTransporte = data.estTransporte;
        this.dateModification = data.dateModification;
        this.achat = data.achat;
        this.grilleId = data.grilleId;
        this.produitId = data.produitId;
        this.prestationId = data.prestationId;
        this.zoneId = data.zoneId;
        this.benneId = data.benneId;
        this.siteId = data.siteId;
        this.prixCatalogue = data.modificationValeurCatalogue;
        this.prixStandard = data.modificationValeurStandard;
        this.prix = data.prix;
    }

    isValid() {
        prixValidator = prixValidator || validatorBuilder.getInstanceFor('Prix');
        const validationResults = prixValidator.validate(this);
        return validationResults.isValid;
    }
}
