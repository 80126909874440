export default class PortailStat {
    constructor(stat) {
        this.id = stat.id;
        this.text = stat.text;
        this.total = stat.total;
        this.icon = stat.icon;
        this.type = stat.type;
        this.class = stat.class && typeof stat.class !== 'undefined' ? `-${stat.class}` : '';
        this.link = stat.link;
        this.ordre = stat.ordre;
    }
}
