import DuplicateController from './duplicate.controller';

export default {
    bindings: {
        source: '=',
        modalInstance: '=',
        resolve: '<'
    },
    template: require('./duplicate.html'),
    controller: DuplicateController
};
