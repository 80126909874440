PrevisionnelTourneeController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'PrevisionnelTourneeService',
    'notification'
];

export default function PrevisionnelTourneeController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    PrevisionnelTourneeService,
    notification
) {
    const vm = this;

    let previousTableState;
    const watchers = [];

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.previsionnels = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };
    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.loadPrevisionnelTournee = loadPrevisionnelTournee;
    vm.exporter = exporter;

    async function init() {
        vm.isFiltrer = true;
        vm.sites = await PrevisionnelTourneeService.getSitesCommerciaux();
        vm.ongletOpen = {
            isFiltreOpen: true
        };
    }

    init();

    async function loadPrevisionnelTournee(tableState) {
        startLoading();

        if ($state && $state.current && $state.current.name !== 'previsionnel-tournee.list') {
            $state.go('previsionnel-tournee.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.previsionnels = [];

        try {
            if (vm.date && vm.idSite) {
                const data = await PrevisionnelTourneeService.getPrevisionnelTournee(filters, sorts, pagination, vm.idSite, vm.date);
                vm.previsionnels = data.items;

                if (previousTableState) {
                    PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
                }
                vm.isFiltrer = true;
                vm.ongletOpen = {
                    isFiltreOpen: false
                };
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function exporter(modeImpression) {
        try {
            const filters = PaginationService.getFilters(previousTableState);
            const sorts = PaginationService.getSorts(previousTableState);
            const fileName = 'Previsionnel_tournee';
            let resultat = undefined;
            try {
                resultat = await PrevisionnelTourneeService.exportPrevisionnel(modeImpression, filters, sorts, vm.idSite, vm.date);
            } catch (ex) {
                //si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
                const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
                notification.error('Previsionnel.' + msgException);
                return false;
            }
            if (resultat) {
                const data = resultat.data;
                const status = resultat.status;
                let headers = resultat.headers;
                headers = headers();

                const contentType = headers['content-type'];

                const linkElement = document.createElement('a');
                try {
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    if (modeImpression == 0) {
                        linkElement.setAttribute('download', fileName + '.pdf');
                    } else {
                        linkElement.setAttribute('download', fileName + '.xlsx');
                    }

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                } catch (ex) {
                } finally {
                }
                return true;
            }
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
