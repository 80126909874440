//import jsPDF from 'jspdf';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
//import JSZip from 'jszip/dist/jszip';
import FileSaver from 'file-saver';
//import { SSL_OP_NO_TICKET } from 'constants';

export default class PrefacturationDetailController {
    static $inject = [
        '$stateParams',
        '$state',
        'PrefacturationService',
        'notification',
        'PrefacturationCommunicationService',
        '$uibModal',
        'MassiaRightsService',
        'DataShareService',
        'moment',
        '$scope',
        '$timeout',
        'TemplateImpressionService',
        '$translate',
        'ModalService',
        'MOPService'
    ];
    adapt = {};

    constructor(
        $stateParams,
        $state,
        PrefacturationService,
        notification,
        PrefacturationCommunicationService,
        $uibModal,
        MassiaRightsService,
        DataShareService,
        moment,
        $scope,
        $timeout,
        TemplateImpressionService,
        $translate,
        ModalService,
        MOPService
    ) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.PrefacturationService = PrefacturationService;
        this.notification = notification;
        this.PrefacturationCommunicationService = PrefacturationCommunicationService;
        this.$uibModal = $uibModal;
        this.MassiaRightsService = MassiaRightsService;
        this.DataShareService = DataShareService;
        this.moment = moment;
        this.scope = $scope;
        this.$timeout = $timeout;
        this.scrollAdapter = {};
        this.TemplateImpressionService = TemplateImpressionService;
        this.$translate = $translate;
        this.ModalService = ModalService;
        this.MOPService = MOPService;
    }

    async $onInit() {
        this.MOPService.setMop([{ title: 'PREFACTURATION.BREADCRUMBS.PREFACTURATIONVENTE', filename: 'Facturation.pdf', application: 'gestion' }]);
        this.loaded = false;
        this.loading = false;
        this.prefacturation = {};
        this.hasBeenModified = false;
        this.cdgOpened = false;
        this.display_limit = 2;
        this.disabledValorisation = false;
        this.isFiltred = false;
        this.nbFactureSelected = 0;

        await this.initPrefacturation();

        this.datasource = {
            get: (index, count, success) => {
                this.$timeout(() => {
                    // make it zero-based instead of 1-based.
                    index--;
                    const result = [];
                    if (this.prefacturation && this.prefacturation.listFacturesVirtuelles) {
                        for (let i = index; i <= index + (count - 1); i++) {
                            if (!this.prefacturation.listFacturesVirtuelles[i]) {
                                continue;
                            }
                            result.push(this.prefacturation.listFacturesVirtuelles[i]);
                        }
                    }
                    success(result);
                }, 100);
            }
        };

        // SL on détermine si c'est Achat ou Vente
        if (this.$state.current.rights.domain == 'prefaachat') {
            this.prefacturation.achatVente = 0;
        } else if (this.$state.current.rights.domain == 'prefavente') {
            this.prefacturation.achatVente = 1;
        } else {
            this.prefacturation.achatVente = null;
        }

        this.isFactureErreur = this.prefacturation.listeFacturesEnErreur.length > 0 || this.hasBeenModified || this.disabledValorisation;
    }

    $onDestroy() {
        this.MOPService.resetMop();
    }

    getStyle(facture) {
        const style = {};
        if (facture.selected) {
            style['background-color'] = 'paleturquoise';
        }
        if (facture.isEnAlerte) {
            style['border-color'] = 'orange';
        }
        if (facture.isEnErreur) {
            style['border-color'] = 'red';
        }
        return style;
    }

    async initPrefacturation() {
        // si on recoit bien l objet
        if (this.$state.params.obj && this.$state.params.obj.length != 0) {
            try {
                this.startLoading();
                // on met a jour les donnees du scope 'detail'
                this.number = 0;
                this.prefacturation = angular.copy(this.$state.params.obj);
                this.prefacturation_copy = angular.copy(this.$state.params.obj);
                this.isFiltred = false;
                this.loadMoreData();
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            // s'il y a quelque chose dans le DataShare
            if (_.isEmpty(this.DataShareService.savedData) == false) {
                // on cherche l'entité preFaCommande
                const item = _.find(this.DataShareService.savedData, { entity: 'preFaCommande' });
                if (item != null) {
                    this.prefacturation = item.dataItSelf;
                }
            }
        }

        // RAZ du dataShare concernant l'entité preFaCommande
        this.DataShareService.razData('preFaCommande');
    }

    filtrerPrefaAlerte() {
        if (this.isFiltred) {
            this.prefacturation.listFacturesVirtuelles = this.prefacturation_copy.listFacturesVirtuelles;
            this.isFiltred = false;
        } else {
            this.prefacturation.listFacturesVirtuelles = this.prefacturation_copy.listFacturesVirtuelles.filter((bill) => {
                return bill.isEnAlerte;
            });
            this.isFiltred = true;
        }
        this.getAdapter().reload();
    }

    filtrerPrefaErreur() {
        if (this.isFiltred) {
            this.prefacturation.listFacturesVirtuelles = this.prefacturation_copy.listFacturesVirtuelles;
            this.isFiltred = false;
        } else {
            this.prefacturation.listFacturesVirtuelles = this.prefacturation_copy.listFacturesVirtuelles.filter((bill) => {
                return bill.isEnErreur;
            });
            this.isFiltred = true;
        }
        this.getAdapter().reload();
    }

    goToCommande(idCommande) {
        // sauvegarde des données dans le dataShare
        const dataToSave = {};
        dataToSave.entity = 'preFaCommande';
        dataToSave.dataItSelf = this.prefacturation;
        this.DataShareService.setDataShare(dataToSave, 'preFaCommande');
        // go to commande
        this.$state.go('prefacturation.commande', { id: idCommande });
    }

    onClickFacture(facture) {
        if (facture.selected) {
            facture.selected = false;
        } else {
            facture.selected = true;
        }

        if (this.prefacturation.listFacturesVirtuelles.some((x) => x.selected)) {
            const selecteds = this.prefacturation.listFacturesVirtuelles
                .filter((x) => x.selected)
                .map(function (v) {
                    return v.codeTemporaire;
                });
            let withErreur = false;
            for (var i = 0; i < this.prefacturation.factureEnErreurs.length; i++) {
                if (selecteds.some((x) => x == this.prefacturation.factureEnErreurs[i])) {
                    withErreur = true;
                    break;
                }
            }
            this.isFactureErreur = withErreur || this.hasBeenModified || this.disabledValorisation;
            this.nbFactureSelected = selecteds.length;
        } else {
            this.isFactureErreur = this.prefacturation.listeFacturesEnErreur.length > 0 || this.hasBeenModified || this.disabledValorisation;
            this.nbFactureSelected = 0;
        }
    }

    deselectionnerFactures() {
        const factureSelecteds = this.prefacturation.listFacturesVirtuelles.filter((x) => x.selected);
        for (let i = 0; i < factureSelecteds.length; i++) {
            factureSelecteds[i].selected = false;
        }
        this.nbFactureSelected = 0;
    }

    async genererFacturation(withoutFacture) {
        this.disabledValorisation = true;
        if (withoutFacture) {
            this.startLoading('valorisation');
        } else {
            this.startLoading('facturation');
        }
        try {
            let factureSelected = [];
            if (this.prefacturation.listFacturesVirtuelles.some((x) => x.selected)) {
                factureSelected = this.prefacturation.listFacturesVirtuelles
                    .filter((x) => x.selected)
                    .map(function (v) {
                        return v.codeTemporaire;
                    });
            }

            const resultatFa = await this.PrefacturationService.genererFacturation(withoutFacture, factureSelected);
            // si la préfa achat est ok
            if (resultatFa.isPreFaAchatEnregistree) {
                this.notification.success('PREFACTURATION.PREFAACHATSAVED');
                this.stopLoading();
            } else {
                // si on a un objet, on va sur la page liste de factures en passant l objet
                if (resultatFa.listeFacturesCrees.length != 0) {
                    try {
                        this.$state.go('factures.list', { obj: resultatFa.listeFacturesCrees });
                    } catch (ex) {
                        this.notification.error(ex.data);
                    } finally {
                        this.stopLoading();
                    }
                } else {
                    this.$state.go('prefacturation.newvente');
                    this.notification.info('PREFACTURATION.WARNINGNOFACTURE');
                }
            }

            if (resultatFa.clientCompteNonOuverts) {
                const msg =
                    '<p>' + this.$translate.instant('PREFACTURATION.CLIENT_COMPTE_NON_OUVERT.MESSAGE') + resultatFa.clientCompteNonOuverts + '</p>';
                const modalInstance = this.ModalService.info({
                    modalTitle: this.$translate.instant('PREFACTURATION.CLIENT_COMPTE_NON_OUVERT.TITLE'),
                    modalMsg: msg,
                    headerClass: 'modal-info'
                });
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async regenererPreFacturation() {
        const _this = this;
        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('PREFACTURATION.REGENERER_PREFA.TITLE'),
            modalMsg: this.$translate.instant('PREFACTURATION.REGENERER_PREFA.MESSAGE'),
            headerClass: 'modal-danger'
        });
        modalInstance.result.then(() => {
            _this.regenerer();
        });
    }

    async regenerer() {
        this.startLoading('prefacturation');
        try {
            this.prefacturation.data.isValoriserTicket = true;
            const resultatPreFa = await this.PrefacturationService.genererPreFacturation(this.prefacturation.data, false);
            // si on a un objet, on va sur la page detail en passant l objet
            if (resultatPreFa.nbTicketsSelectionnes != 0) {
                try {
                    if (this.prefacturation.achatVente == 1) {
                        this.$state.go('prefacturation.detailvente', { obj: resultatPreFa });
                    } else if (this.prefacturation.achatVente == 0) {
                        this.$state.go('prefacturation.detailachat', { obj: resultatPreFa });
                    }
                    this.hasBeenModified = false;
                    this.cdgOpened = false;
                } catch (ex) {
                    this.notification.error(ex.data);
                }
            } else {
                if (this.prefacturation.achatVente == 1) {
                    this.$state.go('prefacturation.newvente');
                } else if (this.prefacturation.achatVente == 0) {
                    this.$state.go('prefacturation.newachat');
                }
                this.notification.info('PREFACTURATION.WARNINGPREFA');
            }
        } catch (ex) {
            this.notification.error(ex.data);
            if (this.prefacturation.achatVente == 1) {
                this.$state.go('prefacturation.newvente');
            } else if (this.prefacturation.achatVente == 0) {
                this.$state.go('prefacturation.newachat');
            }
        } finally {
            this.stopLoading();
        }
    }

    async print(orientation) {
        this.startLoading('print');
        this.nbFactures = this.prefacturation.listFacturesVirtuelles.length;
        try {
            const that = this;
            const zip = new JSZip();
            for (var i = 0; i < that.nbFactures; i++) {
                that.factureEnCours = i + 1;
                that.clientEnCours = that.prefacturation.listFacturesVirtuelles[i].nomClient;
                const docById = document.getElementById(i);
                const imgWidth = docById.clientWidth;
                const imgHeight = docById.clientHeight;
                var ratio = imgHeight / imgWidth;

                await html2canvas(docById).then(function (canvas) {
                    const doc = new jsPDF(orientation, 'mm', 'a4');
                    const pageWidth = doc.internal.pageSize.width;
                    const pageHeight = doc.internal.pageSize.height;
                    const imgHeight = ratio * pageWidth;
                    let heightLeft = imgHeight;
                    let position = 0;

                    const imgMedium = canvas.toDataURL('image/jpeg', 0.7);
                    doc.addImage(imgMedium, 'jpeg', 0, position, pageWidth, imgHeight);
                    heightLeft -= pageHeight;

                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(imgMedium, 'jpeg', 0, position, pageWidth, imgHeight);
                        heightLeft -= pageHeight;
                    }
                    zip.file('prefa_' + i + '_' + that.clientEnCours + '.pdf', doc.output('blob'));
                });
            }

            const now = that.moment().format('DDMMYYhhmm');
            zip.generateAsync({ type: 'blob' }).then(function (content) {
                FileSaver.saveAs(content, 'prefacturation_' + that.prefacturation.nomSociete + '_' + now + '.zip');
            });
            that.stopLoading();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
        }
    }

    cancel() {
        if (this.prefacturation.achatVente == 1) {
            this.$state.go('prefacturation.newvente');
        } else if (this.prefacturation.achatVente == 0) {
            this.$state.go('prefacturation.newachat');
        }
    }

    startLoading(nature) {
        this.loading = true;
        this.nature = nature;
    }

    stopLoading() {
        this.loading = false;
        this.nature = null;
    }

    async openPrefaGrille(article, dateLivraison) {
        const canAccess = this.MassiaRightsService.userHasRight('grilletarif', 'read', 'gestion');
        if (canAccess) {
            const _this = this;
            _this.selectedIdGrille = article.idGrilleTarifaire;
            _this.articleObject = {};
            _this.articleObject.idArticle = article.idArticle;
            _this.articleObject.idProducteur = article.idProducteur;
            _this.articleObject.typeArticle = article.typeArticle;
            _this.articleObject.idTypeBenne = article.idTypeBenne;
            _this.articleObject.idZone = article.idZone;
            _this.articleObject.isEnrobe = article.isEnrobe;
            _this.articleObject.isLiant = article.isLiant;
            _this.articleObject.idSiteCommercial = article.idSiteCommercial;
            _this.articleObject.dateLivraison = dateLivraison;
            this.$uibModal
                .open({
                    template:
                        '<prefa-grille has-been-modified="$ctrl.hasBeenModified" selected-id-grille="$ctrl.selectedIdGrille" article-object="$ctrl.articleObject" modal-instance="$ctrl.uibModalInstance"></prefa-grille>',
                    controller: [
                        '$uibModalInstance',
                        function ($uibModalInstance) {
                            const $ctrl = this;
                            $ctrl.uibModalInstance = $uibModalInstance;
                            $ctrl.selectedIdGrille = _this.selectedIdGrille;
                            $ctrl.articleObject = _this.articleObject;
                            $ctrl.hasBeenModified = _this.hasBeenModified;
                        }
                    ],
                    controllerAs: '$ctrl',
                    size: 'xxl',
                    backdrop: false
                })
                .result.then(async function (result) {
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                    if (result) {
                        _this.hasBeenModified = result;
                    }
                    _this.refreshGrilleSelection();
                });

            // , function (reason) {
            //     // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
            //     // déselectionner la grille tarifaire
            //     _this.refreshGrilleSelection();
            // });
        } else {
            this.notification.info('PREFACTURATION.WARNINGACCESGRILLE');
        }
    }

    refreshGrilleSelection() {
        this.selectedIdGrille = null;
    }

    open(domaine, facture, ticket) {
        this.cdgOpened = true;
        const _this = this;
        _this.source = {};
        _this.source.entete = {};
        _this.source.entete.nomType = domaine;
        _this.domaine = domaine;
        _this.source.filtresAppliques = true;
        _this.source.preFaData = {};
        _this.source.preFaData.societes = {};
        _this.source.preFaData.societes.id = this.prefacturation.idSociete;
        _this.source.preFaData.societes.libelle = this.prefacturation.nomSociete;
        _this.source.preFaData.dateApplication = this.prefacturation.data.dateGeneration;

        if (domaine == 'SiteClient' || domaine == 'Chantiers' || domaine == 'TypeClient') {
            if (facture.natureFacture == 1 || facture.natureFacture == 4) {
                _this.source.preFaData.siteCommercial = {};
                _this.source.preFaData.siteCommercial.id = facture.idSiteCommercial;
                _this.source.preFaData.siteCommercial.libelle = facture.nomSiteCommercial;
            }
            if (facture.natureFacture == 2 || facture.natureFacture == 3) {
                if (ticket != null) {
                    _this.source.preFaData.siteCommercial = {};
                    _this.source.preFaData.siteCommercial.id = ticket.idSiteCommercial;
                    _this.source.preFaData.siteCommercial.libelle = ticket.nomSiteCommercial;
                }
            }
        }

        switch (domaine) {
            case 'Societes':
                _this.source.id = this.prefacturation.idSociete;
                _this.source.entete.libelle = this.prefacturation.nomSociete;
                break;
            case 'SiteClient':
                _this.source.id = facture.idClient;
                _this.source.entete.libelle = facture.nomClient;
                _this.source.entete.typeClient = facture.nomTypeClient;
                break;
            case 'SiteTransp':
                _this.source.id = ticket.idSiteTransporteur;
                _this.source.entete.libelle = ticket.nomSiteTransporteur;
                break;
            case 'SiteUtil':
                if (facture.natureFacture == 1 || facture.natureFacture == 4) {
                    _this.source.id = facture.idSiteCommercial;
                    _this.source.entete.libelle = facture.nomSiteCommercial;
                }
                if (facture.natureFacture == 2 || facture.natureFacture == 3) {
                    _this.source.id = ticket.idSiteCommercial;
                    _this.source.entete.libelle = ticket.nomSiteCommercial;
                }
                break;
            case 'Chantiers':
                if (facture.natureFacture == 2 || facture.natureFacture == 4) {
                    _this.source.id = facture.idChantier;
                    _this.source.entete.libelle = facture.nomChantier;
                }
                if (facture.natureFacture == 1 || facture.natureFacture == 3) {
                    _this.source.id = ticket.idChantier;
                    _this.source.entete.libelle = ticket.nomChantier;
                }
                _this.source.entete.nomSite = facture.nomClient;
                break;
            case 'TypeClient':
                _this.source.id = facture.idTypeClient;
                _this.source.entete.libelle = facture.nomTypeClient;
                break;
            default:
                break;
        }

        this.$uibModal
            .open({
                template: '<centre-gestion source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></centre-gestion>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = _this.source;
                        $ctrl.domaine = _this.domaine;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl'
            })
            .result.then(
                async function (result) {
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                    if (result) {
                        _this.hasBeenModified = result;
                    }
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    openTicket(ticket) {
        this.$uibModal
            .open({
                template: '<ticket-detail modal-instance="$ctrl.uibModalInstance" ticket-from-pre-fa="$ctrl.ticketFromPreFa"></ticket-detail>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.uibModalInstance = $uibModalInstance;
                        $ctrl.ticketFromPreFa = ticket.idTicket;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl'
            })
            .result.then(
                async function (result) {
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                    if (result) {
                    }
                },
                function (reason) {}
            );
    }

    async browserPrint() {
        try {
            const res = await this.TemplateImpressionService.downloadModal('preFaVente');
            if (res === 'cancel') {
                throw res;
            }
            this.notification.info(this.$translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
                timeOut: 15000
            });
            const template = await this.PrefacturationService.printPrefa(this.prefacturation, res.filename);
            template.pdf = res.pdf;
            await this.TemplateImpressionService.downloadTemplate(template.filename, template, 'preFaVente');
        } catch (err) {
            console.log(err);
            if (err !== 'cancel') {
                this.notification.error(err.data);
            }
        }
    }

    loadMoreData() {
        if (this.splitedPrefa && this.splitedPrefa.listFacturesVirtuelles) {
            for (let i = 0; i < 3; i++) {
                if (this.prefacturation.listFacturesVirtuelles[i + this.number]) {
                    this.splitedPrefa.listFacturesVirtuelles.push(this.prefacturation.listFacturesVirtuelles[i + this.number]);
                }
            }
            this.number += 3;
        }
    }

    getAdapter() {
        return this.adapt;
    }
}
