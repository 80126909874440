const serviceUrl = `${__configuration.apiUrl}/massia/tickets`;

export default class TicketsService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getTickets(filters, sorts, pagination, idSocieteCom, idSiteCom, dateDebut, dateFin, numeroFacture) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {}),
				idSocieteCom: JSON.stringify(idSocieteCom || 0),
				idSiteCom: JSON.stringify(idSiteCom || 0),
				dateDebut: JSON.stringify(dateDebut || null),
				dateFin: JSON.stringify(dateFin || null),
				numeroFacture: JSON.stringify(numeroFacture || null)
			}
		});

		return res.data;
	}

	async getTicketById(id) {
		const url = `${serviceUrl}/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getCustomFieldHeader() {
		const c = await this.$http.get(`${__configuration.apiUrl}/massia/parametre-programme/code`, {
			params: {
				cle: 'TckPrdCstmFld'
			}
		});

		return c.data;
	}

	async getSites() {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-producteurs`;
		const res = await this.$http.get(siteUrl);

		return res.data;
	}

	async getSiteProducteurs() {
		const application = 'gestion';
		const url = `${__configuration.apiUrl}/massia/sites-producteurs/headers/${application}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getPrestations(type) {
		const url = `${__configuration.apiUrl}/massia/prestations/headers/${type}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async createPrestation(data) {
		const url = `${serviceUrl}/prestation`;
		const result = await this.$http.post(url, data);
		return result.data;
	}

	async deletePrestationById(id) {
		const url = `${serviceUrl}/prestation/${id}`;
		const res = this.$http.delete(url);
		return res;
	}

	async deleteLienTicketRechargement(id) {
		const url = `${serviceUrl}/rechargement/${id}`;
		const res = this.$http.delete(url);
		return res;
	}

	async updatePrix(factProd, idticket) {
		const data = {
			type: factProd.typeArticle,
			idTicket: idticket,
			id: factProd.id,
			prixUnitaire: factProd.prixUnitaire,
			valeur: factProd.valeur,
			idTva: factProd.idTVA,
			typePrix: factProd.typePrix,
			isPrixUnitaireUpdated: factProd.isPrixUnitaireUpdated || false,
			prixAchat: factProd.prixAchat
		};
		const url = `${serviceUrl}/prix`;
		const res = await this.$http.put(url, data);
		return res.data;
	}

	async getTypePrix() {
		const url = 'assets/enum/typePrix.json';
		const res = await this.$http.get(url);
		return res.data;
	}

	async getDap(idTransporteur, idChantier, idLieu) {
		try {
			const res = await this.$http.get(`${__configuration.apiUrl}/massia/gestion/dap/headers/${idTransporteur}/${idChantier}/${idLieu}`);
			return res.data;
		} catch (err) {
			throw err.data;
		}
	}

	async getTva() {
		try {
			const res = await this.$http.get(`${__configuration.apiUrl}/massia/tvas/headers`);
			return res.data;
		} catch (err) {
			throw err.data;
		}
	}

	async getEtats() {
		const url = 'assets/enum/tickets/etats.json';
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTicketSearch(
		dateDebut,
		dateFin,
		commandeId,
		clientId,
		chantierId,
		produitId,
		numeroTicket,
		siteId,
		transporteurId,
		isTransportFacturer,
		isNonFacturable,
		isEntreeSortie,
		isAchatVente,
		isTransportFranco,
		lieuId,
		zoneId,
		isCessionInterne
	) {
		const filtres = {
			dateDebut: dateDebut,
			dateFin: dateFin,
			commandeId: commandeId,
			clientId: clientId,
			chantierId: chantierId,
			produitId: produitId,
			numeroTicket: numeroTicket,
			siteId: siteId,
			transporteurId: transporteurId,
			isTransportFacturer: isTransportFacturer,
			isNonFacturable: isNonFacturable,
			isEntreeSortie: isEntreeSortie,
			isAchatVente: isAchatVente,
			isTransportFranco: isTransportFranco,
			lieuId: lieuId,
			zoneId: zoneId,
			isCessionInterne: isCessionInterne
		};
		const url = `${serviceUrl}/search`;
		const res = await this.$http.get(url, {
			params: {
				filtres: JSON.stringify(filtres || {})
			}
		});

		// console.log("🚀 ~ file: tickets.service.js:163 ~ TicketsService ~ res.data:", res.data)
		return res.data;
	}

	async getChantiers(valeur, idClient) {
		if (idClient) {
			const url = `${__configuration.apiUrl}/massia/chantiers/headersByClient/${idClient}/${valeur}`;
			const res = await this.$http.get(url);
			return res.data;
		}
		const url = `${__configuration.apiUrl}/massia/chantiers/headers/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getProduits(valeur, idSiteClient, idSiteCommercial) {
		// let url = `${__configuration.apiUrl}/massia/produits/headers/${valeur}`;
		const url = `${__configuration.apiUrl}/massia/produits/headers/${valeur}/filtres/${idSiteClient}/null/${idSiteCommercial}/null`;

		const res = await this.$http.get(url);
		return res.data;
	}

	async getCommandes(valeur, date, idClient, idChantier) {
		const url = `${__configuration.apiUrl}/massia/commandes/headerByDate`;

		const filtres = {
			date: date,
			valeur: valeur,
			idClient: idClient,
			idChantier: idChantier
		};
		const res = await this.$http.get(url, {
			params: {
				filtres: JSON.stringify(filtres || {})
			}
		});

		return res.data;
	}

	async getClients(valeur) {
		const url = `${__configuration.apiUrl}/massia/sites-clients-fournisseurs/headers/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getClientsRemplace(valeur, idSiteCommercial) {
		const withFournisseur = true;
		const url = `${__configuration.apiUrl}/massia/sites-clients/typeAheadCompteOuvertSiteComm/${idSiteCommercial}/${valeur}/${withFournisseur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTransporteurs(valeur) {
		const url = `${__configuration.apiUrl}/massia/sites-transporteurs/typeAhead/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTransporteursRemplace(valeur, idSiteCommercial) {
		const url = `${__configuration.apiUrl}/massia/sites-transporteurs/typeAheadCompteOuvertSiteComm/${idSiteCommercial}/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getChauffeursRemplace(valeur, idSiteTransporteur) {
		let url = `${__configuration.apiUrl}/massia/personnes/chauffeurs/header/${valeur}`;
		if (idSiteTransporteur) {
			url = `${__configuration.apiUrl}/massia/personnes/chauffeurs/by-transporteur/${idSiteTransporteur}/${valeur}`;
		}
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSitesCommerciaux(valeur) {
		const idUtilisateur = 0;
		const url = `${__configuration.apiUrl}/massia/sites-commerciaux/typeAhead/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getLieux(valeur, idClient) {
		const url = `${__configuration.apiUrl}/massia/lieux/typeAhead/${valeur}/${idClient}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async updateTicket(
		ids,
		commandeId,
		clientId,
		chantierId,
		produitId,
		producteurId,
		referenceCommande,
		transporteurId,
		chauffeurId,
		isTransportFacturer,
		isNonFacturable,
		isEntreeSortie,
		isAchatVente,
		isTransportFranco,
		lieuId,
		zoneId,
		isDeleteCommande,
		isDeleteChantier,
		isDeleteChauffeur,
		isDeleteTransporteur,
		isDeleteLieu,
		isDeleteZone,
		isCessionInterne
	) {
		const data = {
			ids: ids,
			commandeId: commandeId,
			clientId: clientId,
			chantierId: chantierId,
			produitId: produitId,
			producteurId: producteurId,
			referenceCommande: referenceCommande,
			transporteurId: transporteurId,
			chauffeurId: chauffeurId,
			isTransportFacturer: isTransportFacturer,
			isNonFacturable: isNonFacturable,
			isEntreeSortie: isEntreeSortie,
			isAchatVente: isAchatVente,
			isTransportFranco: isTransportFranco,
			lieuId: lieuId,
			zoneId: zoneId,
			isDeleteCommande: isDeleteCommande,
			isDeleteChantier: isDeleteChantier,
			isDeleteChauffeur: isDeleteChauffeur,
			isDeleteTransporteur: isDeleteTransporteur,
			isDeleteLieu: isDeleteLieu,
			isDeleteZone: isDeleteZone,
			isCessionInterne: isCessionInterne
		};
		const url = `${serviceUrl}/en-lot`;
		const res = await this.$http.put(url, data);
		return res.data;
	}

	async annuleRemplaceTicket(
		ids,
		commandeId,
		clientId,
		chantierId,
		produitId,
		producteurId,
		referenceCommande,
		transporteurId,
		chauffeurId,
		isTransportFacturer,
		isNonFacturable,
		isEntreeSortie,
		isAchatVente,
		isTransportFranco,
		lieuId,
		zoneId,
		isDeleteCommande,
		isDeleteChantier,
		isDeleteChauffeur,
		isDeleteTransporteur,
		isDeleteLieu,
		isDeleteZone,
		isCessionInterne
	) {
		const data = {
			ids: ids,
			commandeId: commandeId,
			clientId: clientId,
			chantierId: chantierId,
			produitId: produitId,
			producteurId: producteurId,
			referenceCommande: referenceCommande,
			transporteurId: transporteurId,
			chauffeurId: chauffeurId,
			isTransportFacturer: isTransportFacturer,
			isNonFacturable: isNonFacturable,
			isEntreeSortie: isEntreeSortie,
			isAchatVente: isAchatVente,
			isTransportFranco: isTransportFranco,
			lieuId: lieuId,
			zoneId: zoneId,
			isDeleteCommande: isDeleteCommande,
			isDeleteChantier: isDeleteChantier,
			isDeleteChauffeur: isDeleteChauffeur,
			isDeleteTransporteur: isDeleteTransporteur,
			isDeleteLieu: isDeleteLieu,
			isDeleteZone: isDeleteZone,
			isCessionInterne: isCessionInterne
		};
		const url = `${serviceUrl}/annule-remplace`;
		const res = await this.$http.put(url, data);
		return res.data;
	}

	async annulationTicket(ids, idMotif, commentaire) {
		const data = {
			ids: ids,
			idMotif: idMotif,
			commentaire: commentaire
		};
		const url = `${serviceUrl}/annulation`;
		const res = await this.$http.put(url, data);
		return res.data;
	}

	async getZones(idSite) {
		let url = `${__configuration.apiUrl}/massia/origines-destinations/header`;
		if (idSite) {
			url = `${__configuration.apiUrl}/massia/origines-destinations/header/${idSite}`;
		}
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSocietesCom() {
		const url = `${__configuration.apiUrl}/massia/societes-commerciales`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSitesCom(pIdSociete) {
		const url = `${__configuration.apiUrl}/massia/sites-commerciaux/header/${pIdSociete}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async exportSete(filename, datedeb, datefin) {
		const exportData = { fileName: JSON.stringify(filename), dateDebut: JSON.stringify(datedeb), dateFin: JSON.stringify(datefin) };
		const url = `${serviceUrl}/csv-sete`;
		const res = await this.$http.get(url, {
			params: exportData,
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getProduitsLies(filtres) {
		const monFiltre = JSON.stringify(filtres);
		const url = `${__configuration.apiUrl}/massia/parametres/produits/lies?filters=${monFiltre}`;
		const res = await this.$http.get(url);
		return res.data.produits;
	}

	async getMotifs() {
		const url = `${__configuration.apiUrl}/massia/motif-annulations/headers`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async exportListeTickets(modeImpr, idSocieteCom, idSiteCom, dateDebut, dateFin, filters, sorts) {
		const url = `${serviceUrl}/print`;
		const res = await this.$http.get(url, {
			params: {
				idSocieteCom: JSON.stringify(idSocieteCom || 0),
				idSiteCom: JSON.stringify(idSiteCom || 0),
				dateDebut: JSON.stringify(dateDebut || null),
				dateFin: JSON.stringify(dateFin || null),
				modeImpr: JSON.stringify(modeImpr),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || [])
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async valoriserPrixAchat(listeTicketIds, idSociete, idSite, dateDebut, dateFin) {
		const data = {
			listeTicketIds: listeTicketIds,
			idSociete: idSociete,
			idSite: idSite,
			dateDebut: dateDebut,
			dateFin: dateFin
		};
		console.log(data);
		const url = `${serviceUrl}/valorisationPrixAchat`;
		return this.$http.put(url, data);
	}

	async exportJyc(filters, sorts, pagination, idSocieteCom, idSiteCom, dateDebut, dateFin) {
		const url = `${serviceUrl}/export-jyc`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {}),
				idSocieteCom: JSON.stringify(idSocieteCom || 0),
				idSiteCom: JSON.stringify(idSiteCom || 0),
				dateDebut: JSON.stringify(dateDebut || null),
				dateFin: JSON.stringify(dateFin || null)
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async exportRubisIcare(filters, sorts, pagination, idSocieteCom, idSiteCom, dateDebut, dateFin) {
		const url = `${serviceUrl}/export-rubis`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {}),
				idSocieteCom: JSON.stringify(idSocieteCom || 0),
				idSiteCom: JSON.stringify(idSiteCom || 0),
				dateDebut: JSON.stringify(dateDebut || null),
				dateFin: JSON.stringify(dateFin || null)
			}
		});
		//return res;
	}

	async upload(data) {
		const formData = new FormData();
		//formData.append('info', angular.toJson(data.data));
		formData.append('file', data.file);
		const res = await this.$http.post(serviceUrl + '/upload', formData, {
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined
			}
		});
		return res.data;
	}

	async getTicketComposant(id, domaine) {
		const url = `${__configuration.apiUrl}/massia/ticket-composant/${id}/${domaine}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async exportTicketComposants(id, domaine) {
		const url = `${__configuration.apiUrl}/massia/ticket-composant/print/${id}/${domaine}`;
		const res = await this.$http.get(url, {
			params: {},
			responseType: 'arraybuffer'
		});
		return res;
	}

	// BetCharge (Ticket)
	async getBetCharge(filters, sorts, pagination) {
		const url = `${__configuration.apiUrl}/massia/ticketsBetCharge`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});
		console.log(res.data);
		return res.data;
	}

	async getBetChargeById(id) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/ticketsBetCharge/${id}`);
		return res.data;
	}

	async updateDap(idTicket, idDap) {
		const url = `${serviceUrl}/update-dap/${idTicket}/${idDap}`;
		return await this.$http.put(url);
	}
}
