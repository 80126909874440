export default class SyntheseModeleDetailController {
    static $inject = [
        '$stateParams',
        '$state',
        'SyntheseModelesOldService',
        'notification',
        'SyntheseModelesCommunicationService',
        'DataShareService',
        'MassiaApplicationService'
    ];

    constructor(
        $stateParams,
        $state,
        SyntheseModelesOldService,
        notification,
        SyntheseModelesCommunicationService,
        DataShareService,
        MassiaApplicationService
    ) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.SyntheseModelesService = SyntheseModelesOldService;
        this.notification = notification;
        this.SyntheseModelesCommunicationService = SyntheseModelesCommunicationService;
        this.DataShareService = DataShareService;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        this.loading = false;
        this.syntheseModele = {};

        this.openAllOnglets();
        this.initSyntheseModele();
    }

    async initSyntheseModele() {
        this.syntheseModele = {
            id: this.$stateParams.id
        };

        if (this.syntheseModele.id) {
            this.startLoading();

            try {
                // on ajoute await afin de ne pas passer trop rapidement dans le finally
                // et ainsi on garde le loading le temps nécessaire
                this.syntheseModele = await this.SyntheseModelesService.getSyntheseModeleById(this.syntheseModele.id);
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListSyntheseModele();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListSyntheseModele();
        }
    }

    goListSyntheseModele() {
        this.$state.go('synthesemodeles.list', {}, { reload: true });
    }

    closeAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: false,
            isListeCriteresOpen: false
        };
    }

    openAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: true,
            isListeCriteresOpen: true
        };
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
