import PrefaGrille from './prefaGrille.model';
import PrefaPrix from './prefaPrix.model';

export default class PrefaGrilleController {
    /* @ngInject */
    constructor($scope, $state, $stateParams, TarifsService, ModalService, notification, $translate, $filter, _, PrefacturationService) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.TarifsService = TarifsService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.$filter = $filter;
        this.PrefacturationService = PrefacturationService;
        this._ = _;
        this.displayedGrille = null;
        this.prixTypes = null;
        this.ongletOpen = {
            isGranulatOpened: false,
            isEnrobeOpened: false,
            isLiantOpened: false,
            isPrestationOpened: false,
            isTransportOpened: false
        };
    }

    async $onInit() {
        this.getTypesPrix();
        await this.displayGrille();
        await this.openRightTab();
    }

    async openRightTab() {
        if (this.articleObject != null) {
            switch (this.articleObject.typeArticle) {
                case 0: // produit
                    if (this.articleObject.isEnrobe) {
                        this.ongletOpen = {
                            isGranulatOpened: false,
                            isEnrobeOpened: true,
                            isLiantOpened: false,
                            isPrestationOpened: false,
                            isTransportOpened: false
                        };
                    } else {
                        if (this.articleObject.isLiant) {
                            this.ongletOpen = {
                                isGranulatOpened: false,
                                isEnrobeOpened: false,
                                isLiantOpened: true,
                                isPrestationOpened: false,
                                isTransportOpened: false
                            };
                        } else {
                            this.ongletOpen = {
                                isGranulatOpened: true,
                                isEnrobeOpened: false,
                                isLiantOpened: false,
                                isPrestationOpened: false,
                                isTransportOpened: false
                            };
                        }
                    }
                    break;
                case 1: // transport
                    this.ongletOpen = {
                        isGranulatOpened: false,
                        isEnrobeOpened: false,
                        isLiantOpened: false,
                        isPrestationOpened: false,
                        isTransportOpened: true
                    };
                    break;
                case 2: // prestation
                    this.ongletOpen = {
                        isGranulatOpened: false,
                        isEnrobeOpened: false,
                        isLiantOpened: false,
                        isPrestationOpened: true,
                        isTransportOpened: false
                    };
                    break;

                default:
                    break;
            }
        }
    }

    cancel() {
        this.modalInstance.close(this.hasBeenModified);
    }

    async updatePrix(produit) {
        const valeurNumbered = await this.convertStringToDecimal(produit.valeur);

        if (produit.valeur == '') {
            produit.valeur = 0;
            if (produit.prixCessionArrondi != null) {
                produit.prix = produit.prixCessionArrondi;
            }
        }

        let prixDeReference = 0;

        if (produit.prixStandard != null && produit.prixStandard.prix > 0) {
            prixDeReference = produit.prixStandard.prix;
        } else if (produit.prixCessionArrondi != null) {
            prixDeReference = produit.prixCessionArrondi;
        }

        switch (produit.type) {
            case 0: // aucun
                break;
            case 1: // négocié
                produit.prix = valeurNumbered;
                break;
            case 2: // remise %
                produit.prix = prixDeReference - Math.round(((valeurNumbered * prixDeReference) / 100) * 100) / 100;
                break;
            case 3: // remise
                produit.prix = prixDeReference - valeurNumbered;
                break;
            case 4: // marge %
                produit.prix = prixDeReference + Math.round(((valeurNumbered * prixDeReference) / 100) * 100) / 100;
                break;
            case 5: // marge
                produit.prix = prixDeReference + valeurNumbered;
                break;
            default:
                break;
        }

        produit.isChanging = 1;
    }

    async displayGrille() {
        this.displayedGrille = await this.findPixArticle();
    }

    async changePrixAchat(lignePrix) {
        if (lignePrix.prixAchat == '') {
            lignePrix.prixAchat = 0;
        }

        if (lignePrix.valeur != null) {
            lignePrix.isChanging = 1;
        }
    }

    async addPrix(produit) {
        let result;
        produit.isLoading = 1;
        if (produit.id > 0) {
            result = await this.TarifsService.updatePrix(produit);
        } else {
            const newPrix = new PrefaPrix();
            produit.grilleTarifaireId = this.selectedIdGrille;
            result = await this.TarifsService.createPrix(produit);
            produit.id = result.idPrix;
        }

        produit.isChanging = 0;
        produit.isLoading = 0;
        produit.dateModification = result.dateModification;

        this.hasBeenModified = true;
    }

    async deletePrix(prix) {
        prix.isLoading = 1;
        await this.TarifsService.deletePrix(prix);
        prix.type = 1;
        prix.prixAchat = null;
        prix.transporte = null;

        prix.isLoading = 0;
        prix.id = 0;
        prix.valeur = null;
        prix.dateModification = null;

        this.hasBeenModified = true;
    }

    async saveAllTransports() {
        angular.forEach(
            this.displayedGrille.prixTransports,
            function (prix) {
                if (prix.isChanging) {
                    this.addPrix(prix);
                }
            },
            this
        );
    }

    async saveAllPrestations() {
        angular.forEach(
            this.displayedGrille.prixPrestations,
            function (prix) {
                if (prix.isChanging) {
                    this.addPrix(prix);
                }
            },
            this
        );
    }

    async saveAllProduits() {
        angular.forEach(
            this.displayedGrille.prixProduits,
            function (prix) {
                if (prix.isChanging) {
                    this.addPrix(prix);
                }
            },
            this
        );
    }

    async saveAllEnrobes() {
        angular.forEach(
            this.displayedGrille.prixEnrobes,
            function (prix) {
                if (prix.isChanging) {
                    this.addPrix(prix);
                }
            },
            this
        );
    }

    async saveAllLiants() {
        angular.forEach(
            this.displayedGrille.prixLiants,
            function (prix) {
                if (prix.isChanging) {
                    this.addPrix(prix);
                }
            },
            this
        );
    }

    async changeTransporte(lignePrix) {
        // lignePrix.transporte = await this.convertStringToDecimal(lignePrix.transporte);
        if (lignePrix.valeur) {
            lignePrix.isChanging = 1;
        }
    }

    async convertStringToDecimal(value) {
        if (typeof value === 'string') {
            // SL parseFloat() à garder !!
            return parseFloat(value.replace(',', '.').replace(' ', ''));
        }

        return value;
    }

    async getTypesPrix() {
        if (!this.prixTypes) {
            try {
                this.prixTypes = await this.TarifsService.getTypesPrix();
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            }
        }
    }

    async findPixArticle() {
        let result = null;
        try {
            result = await this.PrefacturationService.getPSArticleFromGrille(this.selectedIdGrille, this.articleObject);
        } catch (ex) {
            this.notification.error(ex.data);
        }
        return result;
    }
}
