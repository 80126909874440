import AssociesConfig from './_config/associes.config';
import AssociesRoutes from './_config/associes.routes';
import AssociesComponent from './components/associes';
import AssociesService from './services/associes.service';
import AssociesCommunicationService from './services/associes.communication.service';
import AssocieFormComponent from './components/associe-form';
import AssocieValidator from './components/associe-form/associe.validator';

const moduleName = 'app.massia.common.associes';

angular
    .module(moduleName, [])
    .config(AssociesConfig)
    .config(AssociesRoutes)
    .service('AssociesService', AssociesService)
    .service('AssociesCommunicationService', AssociesCommunicationService)
    .component('associes', AssociesComponent)
    .component('associeForm', AssocieFormComponent)
    .factory('AssocieValidator', AssocieValidator);

export default moduleName;
