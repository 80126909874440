import EncoursConfig from './_config/encours.config';
import EncoursRoutes from './_config/encours.routes';
import EncoursComponent from './components/encours';
import EncoursService from './services/encours.service';

const moduleName = 'app.massia.gestion.encours';

angular
    .module(moduleName, [])
    .config(EncoursConfig)
    .config(EncoursRoutes)
    .service('EncoursService', EncoursService)
    .component('encours', EncoursComponent);

export default moduleName;
