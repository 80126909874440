import validatorBuilder from 'validator-builder';

let jourFerieValidator = null;

export default class JourFerie {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.inCalendar = data.inCalendar || false;
        this.codeExists = data.codeExists || null;
        this.codeInvalid = data.codeInvalid || null;
        this.dates = data.dates || [];
        this.recurrences = data.recurrences || [];
    }

    isValid() {
        jourFerieValidator = jourFerieValidator || validatorBuilder.getInstanceFor('JourFerie');
        const validationResults = jourFerieValidator.validate(this);
        return validationResults.isValid;
    }
}
