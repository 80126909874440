import { copy } from 'angular';

/* @ngInject */
export default class BlocSelectionEssaiController {
    constructor($scope, MesuresService, SyntheseEntitiesService, SyntheseCacheService) {
        this.MesureService = MesuresService;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.SyntheseCacheService = SyntheseCacheService;
    }
    $onInit() {
        this.sousEssais = {};
        this.selected = {};
        this.listeSerie = {};
        this.selectedSerieTamis = {};
        this.selectedTamis = {};
        this.tamis = {};
        this.dragOptions = {
            placeholder: 'card bg-primary mas-m-5',
            animation: 200,
            'ui-floating': true,
            stop: this.updateDrop
        };
        this.essais = [];
        this.unsub = this.SyntheseCacheService.essais.subscribe((res) => {
            this.essais = copy(res);
        });
    }

    init(essai) {
        this.loadSousEssai(essai);
        this.checkTamis(
            essai,
            essai.blocSelectionSousEssais.map((x) => x.id)
        );
    }

    // Arrow fx for keep this
    updateDrop = (e, ui) => {
        this.essai = this.essai.map((essai, index) => {
            essai.order = index;
            return essai;
        });
    };

    multiSelectEssai(data, essai) {
        this.checkTamis(
            essai,
            this.selected[essai.id].map((x) => x.id)
        );
        essai.blocSelectionSousEssais = this.multiSelect(this.selected[essai.id]);
    }

    multiSelectTamis(data, essai) {
        essai.serieTamis.tamis = this.multiSelect(
            this.selectedTamis[essai.id].map((x) => {
                x.id = x.idTamis;
                return x;
            })
        );
    }

    multiSelect(output) {
        const list = [];
        for (let i = 0; i < output.length; i++) {
            const element = output[i];
            list.push(element);
        }
        return list;
    }

    async loadSousEssai(essai) {
        try {
            if (!essai.tmpSousEssai) {
                this.sousEssais[essai.id] = this.essais.find((x) => x.id === essai.id).sousEssais;
                this.sousEssais[essai.id] = this.sousEssais[essai.id].map((x) => {
                    x.canUpdate = !this.canUpdate;
                    return x;
                });
                if (!essai.blocSelectionSousEssais || essai.blocSelectionSousEssais.length === 0) {
                    essai.blocSelectionSousEssais = [this.sousEssais[essai.id].find((x) => x.code === essai.code)];
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    async checkTamis(essai, liste) {
        try {
            if (essai.idNature == 8) {
                this.listeSerie[essai.id] = await this.SyntheseEntitiesService.getTamis(liste);
                this.listeSerie[essai.id] = this.listeSerie[essai.id].map((x) => {
                    x.canUpdate = !this.canUpdate;
                    return x;
                });
                if (essai.serieTamis) {
                    this.serieSelect(
                        this.listeSerie[essai.id].find((x) => x.idSerieTamis === essai.serieTamis.id),
                        essai
                    );
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    serieSelect(data, essai) {
        this.tamis[essai.id] = data.listeTamisHeaders.map((x) => {
            x.valeurTamis = `${x.valeurTamis}`;
            x.canUpdate = !this.canUpdate;
            return x;
        });
        essai.serieTamis = {
            id: data.idSerieTamis,
            tamis: essai.serieTamis ? essai.serieTamis.tamis : []
        };
    }

    $onDestroy() {}
}
