import Tva from './tva.model';

export default class TvaFormController {
    static $inject = ['$scope', '$state', '$stateParams', 'TvaCommunicationService', 'TvaService', 'notification', '$location', '_', 'MOPService'];

    constructor($scope, $state, $stateParams, TvaCommunicationService, TvaService, notification, $location, _, MOPService) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.TvaCommunicationService = TvaCommunicationService;
        this.TvaService = TvaService;
        this.notification = notification;
        this.$location = $location;
        this._ = _;
        this.MOPService = MOPService;
    }

    async $onInit() {
        this.MOPService.setMop([{ title: 'TVA.BREADCRUMBS.TVA_LIST', filename: 'TauxTVA.pdf', application: 'gestion' }]);
        this.isEditMode = false;
        this.tva = {};
        this.loading = false;
        this.unregisterReinit = this.TvaCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        this.unregisterReinit();
        this.MOPService.resetMop();
    }

    async initForm() {
        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
            await this.setTva(this.$stateParams.id);
        } else {
            this.isEditMode = false;
        }
        await this.getTvaHeaders();
        this.preselectFiltre();
    }

    async setTva(tvaId) {
        if (tvaId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                const stillThatTva = await this.TvaService.getTvaById(tvaId);
                this.tva = new Tva(stillThatTva);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('tva.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.tva.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.tva.codeExists = await this.TvaService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    // boutons de validation
    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                if (this.tva.id) {
                    const tmp = await this.updateTva();
                    this.notification.success('TVA.UPDATED');
                    return this.tva.id;
                }
                const tmp = await this.createTva();
                this.notification.success('TVA.CREATED');
                return tmp;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        } else {
            return false;
        }
    }

    async valider() {
        const returnedId = await this.sauvegarder();
        if (returnedId) {
            this.$state.go('tva.edit', { id: returnedId });
        }
    }
    async validerEtFermer() {
        const returnedId = this.sauvegarder();
        this.backToPreviousState();
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('tva.list');
    }

    async createTva() {
        this.startLoading();
        try {
            const returnedId = await this.TvaService.createTva(this.tva);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateTva() {
        this.startLoading();
        try {
            const returnedId = await this.TvaService.updateTva(this.tva);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    async getTvaHeaders() {
        if (!this.tvas) {
            this.startLoading();
            try {
                const tvaHeaders = await this.TvaService.getTvaHeaders();
                this.tvas = tvaHeaders.filter((e) => e.isTaxeCombine === false && e.id != this.tva.id);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            } finally {
                this.stopLoading();
            }
        }
    }

    async preselectFiltre() {
        if (this.tva.idTvaLies) {
            for (let index = 0; index < this.tva.idTvaLies.length; index++) {
                const element = this.tva.idTvaLies[index];
                const filtre = this._.find(this.tvas, { id: element });
                if (filtre) {
                    filtre.selected = true;
                }
            }
        }
    }

    reinit() {
        this.setTva(this.$stateParams.id);
        this.TvaCommunicationService.raiseReinit();
    }

    checkValidity() {
        this.$scope.$broadcast('tvaValidations');
        this.tva = new Tva(this.tva);
        return this.tva.isValid();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
