import MesuresSelectController from './mesures.select.controller';

export default {
    template: require('./mesures.select.tpl.html'),
    controller: MesuresSelectController,
    bindings: {
        disabled: '<',
        clearOnSelect: '<',
        selected: '<',
        excludes: '<',
        onSelect: '&',
        domaine: '<',
        typeMesure: '<',
        typeProduit: '<',
        typeFiltreTheme: '<',
        useTable: '<'
    }
};
