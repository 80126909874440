import { enumEtudeAffaire } from './offre.entete.model';

export default class OffreEnteteController {
    /* @ngInject */

    constructor($stateParams, OffresCommunicationService, OffresService, MassiaAuthService, notification, moment) {
        this.$stateParams = $stateParams;
        this.OffresCommunicationService = OffresCommunicationService;
        this.OffresService = OffresService;
        this.MassiaAuthService = MassiaAuthService;
        this.notification = notification;
        this.moment = moment;
        this.isValidator = false;
    }

    async $onInit() {
        this.enum = enumEtudeAffaire;
        this.types = await this.OffresService.getOffreTypes();

        // s'il n'y a qu'une valeur, la positionner directement
        if (this.types.length == 1) {
            this.offre.entete.idType = this.types[0].id;
        }

        this.oldDate = null;
        if (this.offre.entete.etat == null) {
            this.offre.entete.etat = '1';
        }
        this.getValidators();
        this.unregisterReinit = this.OffresCommunicationService.registerReinit(() => this.initForm());
    }

    //compare le userId avec la liste des validateurs pour afficher les boutons de validation
    getValidators() {
        if (this.offre.entete.etat == 6) {
            try {
                const user = this.MassiaAuthService.getAuthenticatedUser();
                this.isValidator = this.offre.entete.validateur.some((x) => user.id === x.id);
            } catch (ex) {
                this.notification.error(ex);
            }
        }
    }

    $onDestroy() {
        // this.unregisterReinit();
    }
}
