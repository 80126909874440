(function (angular, undefined) {
    'use strict';

    angular.module('blocks.helpers')
        .filter('acLimitNumberDigits', acLimitNumberDigitsFilter);

    acLimitNumberDigitsFilter.$inject = [];

    function acLimitNumberDigitsFilter() {
        return function (number, digits, overflowTrail) {
            if (number !== undefined && digits) {
                var max = Math.pow(10, digits) - 1;
                return number <= max ? number : max + (overflowTrail === undefined ? '+' : overflowTrail);
            }
        };
    }

})(angular);