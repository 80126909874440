import validatorBuilder from 'validator-builder';

let typeValidator = null;

export default class Type {
    constructor(data) {
        data = data || {};
        this.id = data.id || undefined;
        this.code = data.code || undefined;
        this.libelle = data.libelle || undefined;
        this.idDomaine = data.idDomaine || undefined;
        this.codeDomaine = data.codeDomaine || undefined;
        this.idTheme = data.idTheme || undefined;
        this.caracteristiques = data.caracteristiques || [];
        this.idFamille = data.idFamille || undefined;
        this.codeFamille = data.codeFamille || undefined;
        this.idUnitePesee = data.idUnitePesee || undefined;
        this.idUniteCompo = data.idUniteCompo || undefined;
        this.idUniteFacturation = data.idUniteFacturation || undefined;
        this.idSerieDimensionnelleTamis = data.idSerieDimensionnelleTamis || undefined;
        this.isRecompose = data.isRecompose || false;
        this.isNormalise = data.isNormalise || false;
        this.enumGranulometrie = data.enumGranulometrie || 0;
        this.niveauVisibiliteSaisie = data.niveauVisibiliteSaisie || 0;
        this.listeBlockCouplesCoLiCourt = data.listeBlockCouplesCoLiCourt || [];
        this.sousTypeFournisseur = data.sousTypeFournisseur || 0;
        this.idProductionType = data.idProductionType;
        this.uniteProduction = data.uniteProduction;
        this.masque = data.masque || null;
        this.codeModifiable = data.codeModifiable;
        this.libelleModifiable = data.libelleModifiable;
        this.appelationModifiable = data.appelationModifiable;
        this.idTypeLie = data.idTypeLie || undefined;
        this.formuleChoice = data.formuleChoice || 1;
        this.idNormeSpec = data.idNormeSpec || -1;
        this.idModeleSaisie = data.idModeleSaisie || undefined;
        this.idTemplateImpression = data.idTemplateImpression || undefined;
        this.backgroundColor = data.backgroundColor;
    }

    isValid() {
        typeValidator = typeValidator || validatorBuilder.getInstanceFor('Type');
        const validationResults = typeValidator.validate(this);
        return validationResults.isValid;
    }
}
