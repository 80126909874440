import ProduitFormEcoOrganismeController from './produit.form.eco.organisme.controller';

export default {
	bindings: {
		produit: '=',
		isOpen: '='
	},
	template: require('./produit.form.eco.organisme.html'),
	controller: ProduitFormEcoOrganismeController
};
