export default class ElementCaracteristiqueService {
    constructor($http) {
        this.http = $http;
        this.url = `${__configuration.apiUrl}/massia/element-caracteristique`;
    }

    async getAll(codeParams = null, code = null) {
        const res = await this.http.get(this.url, {
            params: {
                codeParams: codeParams,
                code: code
            }
        });

        return res.data;
    }

    getOne(id) {
        return this.http.get(`${this.url}/${id}`);
    }

    async getValeur(id, code) {
        const res = this.http.get(`${this.url}/valeur`, {
            params: {
                idProduit: id,
                codeCaract: code
            }
        });

        return res;
    }
}

ElementCaracteristiqueService.$inject = ['$http'];
