export default function Configure($stateProvider) {
    $stateProvider.state('documents-ce-labo', {
        parent: 'common',
        url: '/documents-ce-labo',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.ANALYSE" | translate }}'
        }
    });

    $stateProvider.state('documents-ce-labo.list', {
        url: '/list',
        template: '<documents-c-e></documents-c-e>',
        rights: { domain: 'docce', right: 'read', application: 'laboratoire' },
        ncyBreadcrumb: {
            label: '{{ "DOCUMENTS_CE.BREADCRUMBS.DOCUMENTS_CE_LIST" | translate}}'
        },
        // on affiche la liste des documents ce dans la navigation
        navigation: {
            menu: 'analyse_synthese_ce',
            translate: 'DOCUMENTS_CE.BREADCRUMBS.DOCUMENTS_CE_LIST',
            order: 3
        },
        // la liste des documents ce fait partie des points d'entrée recherchables dans la navigation
        search: 'DOCUMENTS_CE.BREADCRUMBS.DOCUMENTS_CE_LIST'
    });

    $stateProvider.state('documents-ce-labo.new', {
        url: '/new',
        template: '<document-c-e-form></document-c-e-form>',
        rights: { domain: 'docce', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher documents ce > ajouter
            parent: 'documents-ce-labo.list',
            label: '{{ "DOCUMENTS_CE.BREADCRUMBS.DOCUMENT_CE_NEW" | translate}}'
        },
        // l'ajout d'un document ce fait partie des points d'entrée recherchables dans l'application
        search: 'DOCUMENTS_CE.BREADCRUMBS.DOCUMENT_CE_NEW'
    });

    $stateProvider.state('documents-ce-labo.list.detail', {
        url: '/{id}',
        template: '<document-c-e-detail></document-c-e-detail>',
        rights: { domain: 'docce', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher document ce > détail
            parent: 'documents-ce-labo.list',
            label: '{{ "DOCUMENTS_CE.BREADCRUMBS.DOCUMENT_CE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('documents-ce-labo.edit', {
        url: '/{id}/edit',
        template: '<document-c-e-form></document-c-e-form>',
        rights: { domain: 'docce', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher document ce > modifier
            parent: 'documents-ce-labo.list',
            label: '{{ "DOCUMENTS_CE.BREADCRUMBS.DOCUMENT_CE_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
