export default class FormuleCalculCo2Modal {
    /* @ngInject */
    constructor(_, Interpreteur) { this._ = _; this.Interpreteur = Interpreteur; }

    $onInit() {
        this.formatTypeTransport()
    }


    formatTypeTransport() {
        let flat = this._.flatten(this.resolve.formule.composants.map((x) => x.distanceParTypeTransport))
        flat = flat.filter(f => !!f);
        const typeTransport = this._.uniqBy(flat, 'typeTransport.code');

        this._.forEach(this.resolve.formule.composants, (value, key) => {
            const val = {};
            this._.forEach(value.distanceParTypeTransport, (value) => {
                val[value.typeTransport.code] = value.valeur;
            });
            value.distanceParTypeTransportC02 = val;
        });
        this.typeTransport = typeTransport;
    }

    getValeur(composant, transport = null) {
        if (composant && transport && composant.distanceParTypeTransport) {
            if (composant.distanceParTypeTransportC02[transport.typeTransport.code]) {
                const objetToRound = [
                    {
                        key: composant.distanceParTypeTransportC02[transport.typeTransport.code], val: composant.distanceParTypeTransportC02[transport.typeTransport.code], rounder: 0.01 
                    }
                ];
                return this.Interpreteur.getRoundedValue(objetToRound);
            } else {
                return '';
            }
        }
        return composant || composant === 0 ? parseFloat(composant).toFixed(2) : '';
    }

    $onDestroy() { }

    cancel() {
        this.close({ $value: { isOk: false } });
    }

    ok() {
        this.close({ $value: { isOk: true, codeTransport: this.codeTransport, libelleTransport: this.libelleTransport } });
        // A rajouter pour impacter toutes les compos :", editAllCompos: this.editAllCompos"
    }
}
