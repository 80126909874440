(function () {
    'use strict';

    angular.module('blocks.helpers').directive('acMouseover', acMouseover);
    acMouseover.$inject = ['$timeout'];

    function acMouseover($timeout) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var timer;
                var hoverDelay = attrs.acMouseoverDelay ? parseInt(attrs.acMouseoverDelay) : 250;

                element.on('mouseover', mouseover);
                element.on('mouseleave', mouseleave);

                function mouseover() {
                    if (timer === undefined) {
                        timer = $timeout(function () {
                            scope.$eval(attrs.acMouseover);
                        }, hoverDelay);
                    }
                }

                function mouseleave() {
                    if(timer !== undefined) {
                        $timeout.cancel(timer);
                        timer = undefined;
                    }
                }
            }
        };
    }
})();
