export default class TicketImportController {
    static $inject = ['$scope', '$stateParams', 'notification', 'ModalService', '$uibModal'];

    constructor($scope, $stateParams, notification, ModalService, $uibModal) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
    }

    cancel() {
        this.modalInstance.close();
    }

    async $onInit() {}
}
