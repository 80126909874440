import LieuxConfig from './_config/lieux.config';
import LieuxRoutes from './_config/lieux.routes';
import LieuxDonneeRoutes from './_config/lieux-donnee.routes';
import LieuxComponent from './components/lieux';
import LieuxService from './services/lieux.service';
import LieuxTypesService from './services/lieux.types.service';
import LieuxCommunicationService from './services/lieux.communication.service';
import LieuNiveauxTrameService from './components/lieu-config-niveaux/lieu.niveaux.trame.service';
import LieuNiveauxElementsService from './components/lieu-config-niveaux/lieu.niveaux.elements.service';

import LieuFormComponent from './components/lieu-form';
import LieuDetailComponent from './components/lieu-detail';
import LieuFormEnteteComponent from './components/lieu-form/entete';
import LieuFormCaracteristiquesComponent from './components/lieu-form/caracteristiques';
import LieuFormNiveauxComponent from './components/lieu-form/niveaux';
import LieuConfigNiveauxComponent from './components/lieu-config-niveaux';
import TypesNiveauxHierarchique from './components/lieu-config-niveaux/lieu.niveaux.trame.constant';

import LieuValidator from './components/lieu-form/lieu.validator';
import LieuEnteteValidator from './components/lieu-form/entete/lieu.entete.validator';
import LieuCaracteristiquesValidator from './components/lieu-form/caracteristiques/lieu.caracteristiques.validator';

const moduleName = 'app.massia.common.lieux';

angular
    .module(moduleName, [])
    .config(LieuxConfig)
    .config(LieuxRoutes)
    .config(LieuxDonneeRoutes)
    .constant('TypesNiveauxHierarchique', TypesNiveauxHierarchique)
    .service('LieuxService', LieuxService)
    .service('LieuxTypesService', LieuxTypesService)
    .service('LieuxCommunicationService', LieuxCommunicationService)
    .service('LieuNiveauxTrameService', LieuNiveauxTrameService)
    .service('LieuNiveauxElementsService', LieuNiveauxElementsService)
    .component('lieux', LieuxComponent)
    .component('lieuForm', LieuFormComponent)
    .component('lieuDetail', LieuDetailComponent)
    .component('lieuFormEntete', LieuFormEnteteComponent)
    .component('lieuFormCaracteristiques', LieuFormCaracteristiquesComponent)
    .component('lieuFormNiveaux', LieuFormNiveauxComponent)
    .component('lieuConfigNiveaux', LieuConfigNiveauxComponent)
    .factory('LieuValidator', LieuValidator)
    .factory('LieuEnteteValidator', LieuEnteteValidator)
    .factory('LieuCaracteristiquesValidator', LieuCaracteristiquesValidator);

export default moduleName;
