export default function accordeonToggleDirective() {
    return {
        retrict: 'A',
        replace: false,
        transclude: false,
        scope: {
            accordeonToggle: '='
        },
        link: function (scope, element, attrs) {
            element.bind('click', accordeonToggleFunc);

            function accordeonToggleFunc() {
                if (scope.hasOwnProperty('accordeonToggle')) {
                    const isOpen = scope.accordeonToggle.isEnteteOpen || scope.accordeonToggle.isFormOpen;
                    for (const key in scope.accordeonToggle) {
                        scope.accordeonToggle[key] = !isOpen;
                    }
                    scope.$apply();
                }
            }
        }
    };
}
