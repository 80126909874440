import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-table',
    bindings: {
        headers: '<',
        arrayOfDataName: '@',
        arrayData: '<',
        arrayButton: '<',
        detailCallback: '<',
        loading: '<',
    },
    template: require('./massia.table.tpl.html'),
    css: 'libs/massia-component/massia-table/massia.table.scss',
})
export class MassiaTableController {
    constructor($scope, $sce, $templateCache, moment, globalizationManagementService) {
        this.$scope = $scope;
        this.$sce = $sce;
        this.$templateCache = $templateCache;
        this.moment = moment;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
        this.displayDateMask = globalizationManagementService.getCurrentLanguage().displayDateMask;
    }

    $onInit() {
        this.startLoading();
        this.$scope.length = this.headers.length;
        if (this.inputProperty) this.getProperty();
        if (this.arrayData && this.arrayData.length !== 0) {
            this.element = this.arrayData;
            this.stopLoading();
        }
        this.checkPagination();
    }

    async $onChanges(data) {
        if (data.arrayData) this.element = data.arrayData.currentValue;
        if (data.headers) {
            this.headersHtml = await this.generateHeaders(this.headers);
            //console.log(this.$sce.trustAsHtml(this.headersHtml));
            this.$templateCache.put('headersTemplate', this.headersHtml);
        }
        this.stopLoading();
    }

    checkPagination() {
        if (this.headers && this.headers[0] && this.headers[0].options) {
            this.isPaginate = true;
        } else {
            this.isPaginate = false;
        }
    }

    getValue(element, property) {
        let array = property.split('.');
        let value = null;
        for (let i = 0; i < array.length; i++) {
            const e = array[i];
            if(!value && i !== 0) {
                break;
            }
            if (i === 0) {
                value = element[e];
            } else {
                value = value[e];
            }
        }

        return value;
    }

    dateValid(value) {
        // (this.moment(136.1, this.dateFormat))isValid() renvoi true => remplace la valeur par une date
        // On check donc s'il s'agit d'un nombre on renvoi false, sinon on tente de parser en date
        if (!isNaN(value)) {
            return false;
        } else {
            let m = this.moment(value, this.dateFormat);
            return m.isValid();
        }
    }

    getProperty() {
        let toTrim = this.inputProperty.split(',');
        this.property = toTrim.map((str) => str.trim());
    }

    startLoading() {
        this.$scope.loading = true;
    }

    stopLoading() {
        this.$scope.loading = false;
    }

    async generateHeaders(headers) {
        var generatedhtml = '';
        headers.forEach((header, index) => {
            var generatedHeader = '<th ';
            if (header.options) {
                var options = header.options;
                generatedHeader += "class='st-filter st-sort " + header.class + "' ";
                if (options.sort) {
                    generatedHeader += "st-sort='" + header.property + "' ";
                }
                if (options.default) {
                    generatedHeader += "st-sort-default='" + header.property + "' ";
                }
                if (options.filter) {
                    switch (options.filterType) {
                        case 1:
                            generatedHeader += "st-filter='" + header.property + "' ";
                            break;
                        case 2:
                            generatedHeader +=
                                "st-filter-combobox='" +
                                (options.customFilter ? options.customFilter : header.property) +
                                "' st-filter-items='$ctrl.headers[" +
                                index +
                                "].options.items' ";
                            break;
                        case 3:
                            generatedHeader += "st-filter-numeric='" + header.property + "' ";
                            break;
                        case 4:
                            generatedHeader += "st-filter-date='" + header.property + "' ";
                            break;
                        case 5:
                            generatedHeader += "st-filter-bool='" + header.property + "' ";
                            break;
                        default:
                            console.error('options.filterType required in header object');
                            break;
                    }
                }
                if (options.customLabel) {
                    generatedHeader += "st-filter-custom-label='" + options.customLabel + "'";
                }
            } else {
                generatedHeader += "class='" + header.class + "' ";
            }
            generatedHeader += '><span translate=' + header.title + '></span></th>';
            generatedhtml += generatedHeader;
        });
        return generatedhtml;
    }

    displayItem(item) {
        if (this.detailCallback) {
            this.element.map((e) => {
                delete e.batman;
                return e;
            });

            item.batman = true;
            this.detailCallback(item, this.$scope);
        }
    }
}

MassiaTableController.$inject = ['$scope', '$sce', '$templateCache', 'moment', 'globalizationManagementService'];
