const serviceUrl = `${__configuration.apiUrl}/massia/gestion/bennes`;

export default class BennesService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getBennes(isEnabled, filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				isEnabled: JSON.stringify(isEnabled),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async deleteBenneById(id) {
		const url = `${serviceUrl}/${id}`;
		const res = this.$http.delete(url);
		return res;
	}

	async activateBenne(id) {
		const url = `${serviceUrl}/activate/${id}`;
		return this.$http.put(url);
	}

	async createBenne(benne) {
		const result = await this.$http.post(serviceUrl, benne);
		return result.data;
	}

	async updateBenne(benne) {
		const url = `${serviceUrl}/${benne.id}`;
		return await this.$http.put(url, benne);
	}

	async getTypeBennes() {
		const siteUrl = `${__configuration.apiUrl}/massia/gestion/type-bennes/headers`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}
}
