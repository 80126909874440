export default class ActivationSiteSynchroController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'ActivationSiteSynchroService',
		'SitesService',
		'notification',
		'$location',
		'PaginationService',
		'DataShareService',
		'$translate'
	];

	constructor(
		$scope,
		$state,
		$stateParams,
		ActivationSiteSynchroService,
		SitesService,
		notification,
		$location,
		PaginationService,
		DataShareService,
		$translate
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.ActivationSiteSynchroService = ActivationSiteSynchroService;
		this.SitesService = SitesService;
		this.notification = notification;
		this.$location = $location;
		this.PaginationService = PaginationService;
		this.DataShareService = DataShareService;
		this.$translate = $translate;

		this.previousTableState;
		this.savedPagination = {};
		this.savedPagination.entity = 'pagination';
		this.itemsByPageOptions = [50, 200, 500];
		this.itemsByPage = this.itemsByPageOptions[0];
	}

	async $onInit() {
		this.startLoading();
		try {
			this.activations = await this.ActivationSiteSynchroService.getActivations();
			this.activations = this.activations || [];
		} catch (ex) {
			this.notification.error(ex.data);
		}
		this.stopLoading();
	}

	async loadSitesCommerciaux(tableState) {
		let that = this;
		if (!this.startLoading) {
			that = this.$parent.$parent.$ctrl;
		}

		that.startLoading();
		if (tableState) {
			that.previousTableState = tableState;
		}

		const filters = that.PaginationService.getFilters(that.previousTableState);
		const sorts = that.PaginationService.getSorts(that.previousTableState);
		const pagination = that.PaginationService.getPagination(that.previousTableState);

		const objectToManage = {
			tableState: tableState,
			savedPagination: that.savedPagination,
			itemsByPageOptions: that.itemsByPageOptions,
			itemsByPage: that.itemsByPage
		};

		const resultObject = await that.DataShareService.managePaginationContextuelle(objectToManage);
		if (resultObject) {
			tableState = resultObject.tableState;
			that.savedPagination = resultObject.savedPagination;
			that.itemsByPageOptions = resultObject.itemsByPageOptions;
			that.itemsByPage = resultObject.itemsByPage;
		}

		try {
			const oldSites = angular.copy(that.sites);

			const data = await that.SitesService.getSitesCommerciaux(filters, sorts, pagination);

			that.sites = data.items;
			that.sites.forEach((s) => {
				const activation = that.activations.find((a) => a.idSite === s.id);
				if (activation) {
					s.activation = true;
					s.dateDemandeMAJAuto = activation.dateDemandeMAJAuto;
					s.infoDemandeMAJAuto = activation.infoDemandeMAJAuto;
					s.dateTraitementMAJAuto = activation.dateTraitementMAJAuto;
					s.infoTraitementMAJAuto = activation.infoTraitementMAJAuto;
					s.etatTraitementMAJAuto = activation.etatTraitementMAJAuto;
					s.libEtatTraitementMAJAuto = activation.libEtatTraitementMAJAuto;
					s.dateTraitementMAJManuel = activation.dateTraitementMAJManuel;
					s.infoTraitementMAJManuel = activation.infoTraitementMAJManuel;
				}

				if (oldSites) {
					const oldSite = oldSites.find((e) => e.id === s.id);
					if (oldSite) {
						s.activation = oldSite.activation;
						s.dateDemandeMAJAuto = oldSite.dateDemandeMAJAuto;
						s.infoDemandeMAJAuto = oldSite.infoDemandeMAJAuto;
						s.dateTraitementMAJAuto = oldSite.dateTraitementMAJAuto;
						s.infoTraitementMAJAuto = oldSite.infoTraitementMAJAuto;
						s.etatTraitementMAJAuto = oldSite.etatTraitementMAJAuto;
						s.libEtatTraitementMAJAuto = oldSite.libEtatTraitementMAJAuto;
						s.dateTraitementMAJManuel = oldSite.dateTraitementMAJManuel;
						s.infoTraitementMAJManuel = oldSite.infoTraitementMAJManuel;
					}
				}
			});

			if (that.previousTableState) {
				that.PaginationService.setTableState(data.skip, data.take, data.total, that.previousTableState);
			}
		} catch (ex) {
			that.notification.error(ex.data);
		} finally {
			that.stopLoading();
		}
	}

	async valider(id) {
		this.startLoading();
		try {
			await this.ActivationSiteSynchroService.setActivations(id);
			this.notification.success('ACTIVATION_SYNCHRO_SITE.CREATED');
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		} finally {
			this.stopLoading();
		}
	}

	annuler() {
		this.$state.go('activation-site-synchro.edit');
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
