export default function Configure($stateProvider) {
    $stateProvider.state('refsaisiectrl', {
        parent: 'common',
        url: '/refsaisiectrl',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.SECURITE_MATERIEL" | translate }}'
        }
    });

    $stateProvider.state('refsaisiectrl.list', {
        url: '/list',
        template: '<trames-saisies></trames-saisies>',
        rights: { domain: 'refsaisiectrl', right: 'read' },
        ncyBreadcrumb: {
            parent: 'refsaisiectrl',
            label: '{{ "TRAMES.BREADCRUMBS.TRAMES_LIST" | translate}}'
        },
        navigation: {
            menu: 'securite_materiel_donnees',
            translate: 'TRAMES.BREADCRUMBS.TRAMES_LIST',
            order: 6
        },
        search: 'TRAMES.BREADCRUMBS.TRAMES_LIST'
    });

    $stateProvider.state('refsaisiectrl.list.detail', {
        url: '/{id}',
        template: '<trame-saisie-detail></trame-saisie-detail>',
        rights: { domain: 'refsaisiectrl', right: 'read' },
        ncyBreadcrumb: {
            parent: 'refsaisiectrl.list',
            label: '{{ "TRAMES.BREADCRUMBS.TRAME_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('refsaisiectrl.new', {
        url: '/new',
        template: '<trame-saisie-form></trame-saisie-form>',
        rights: { domain: 'refsaisiectrl', right: 'create' },
        ncyBreadcrumb: {
            parent: 'refsaisiectrl.list',
            label: '{{ "TRAMES.BREADCRUMBS.TRAME_NEW" | translate}}'
        },
        search: 'TRAMES.BREADCRUMBS.TRAME_NEW'
    });

    $stateProvider.state('refsaisiectrl.edit', {
        url: '/{id}/edit',
        template: '<trame-saisie-form></trame-saisie-form>',
        rights: { domain: 'refsaisiectrl', right: 'update' },
        ncyBreadcrumb: {
            parent: 'refsaisiectrl.list',
            label: '{{ "TRAMES.BREADCRUMBS.TRAME_EDIT" | translate }}'
        }
    });

    $stateProvider.state('refsaisiectrl.newType', {
        url: '/new-type',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        params: { parentState: 'refsaisiectrl.new' },
        ncyBreadcrumb: {
            parent: 'refsaisiectrl.new',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });

    $stateProvider.state('refsaisiectrl.duplicate', {
        url: '/{id}/duplicate',
        template: '<trame-saisie-form></trame-saisie-form>',
        rights: { domain: 'refsaisiectrl', right: 'create' },
        ncyBreadcrumb: {
            parent: 'refsaisiectrl.list',
            label: '{{ "TRAMES.BREADCRUMBS.TRAME_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });
}

Configure.$inject = ['$stateProvider'];
