export default class PortailRequestController {
    constructor($scope, $state, notification, UserRequestService) {
        this.$scope = $scope;
        this.$state = $state;
        this.notification = notification;
        this.UserRequestService = UserRequestService;
    }
    $onInit() {
        this.loadUserRequest();
    }
    $onDestroy() {}

    async loadUserRequest() {
        try {
            this.request = (await this.UserRequestService.getAll()).data || {};
        } catch (error) {
            this.notification.error(error.data.message);
        }
    }

    async validModification(req) {
        try {
            const res = await this.UserRequestService.deleteRequest(req, true);
            this.notification.success('PORTAIL.USER.UPDATE_REQUEST_VALID');

            if (res.data == 'Email Error') {
                this.notification.warning('PORTAIL.USER.UPDATE_REQUEST_MAIL_ERROR');
            } else {
                this.notification.success('PORTAIL.USER.UPDATE_REQUEST_MAIL');
            }
            this.UserRequestService.loadAll();
            this.loadUserRequest();
        } catch (err) {
            this.notification.error(err.message);
        }
    }

    async denyModification(req) {
        try {
            const res = await this.UserRequestService.deleteRequest(req, false);

            this.notification.success('PORTAIL.USER.UPDATE_REQUEST_REFUS');

            if (res.data == 'Email Error') {
                this.notification.warning('PORTAIL.USER.UPDATE_REQUEST_MAIL_ERROR');
            } else {
                this.notification.success('PORTAIL.USER.UPDATE_REQUEST_MAIL');
            }
            this.UserRequestService.loadAll();
            this.loadUserRequest();
        } catch (err) {
            this.notification.error(err.message);
        }
    }
}

PortailRequestController.$inject = ['$scope', '$state', 'notification', 'UserRequestService'];
