export default class ReferentielMesuresController {
    static $inject = ['$scope', '$timeout', '$state', '$stateParams', '$translate', 'ReferentielTypesService', 'notification', '_'];

    constructor($scope, $timeout, $state, $stateParams, $translate, ReferentielTypesService, notification, _) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.ReferentielTypesService = ReferentielTypesService;
        this.notification = notification;
        this._ = _;
    }

    async $onInit() {
        this.loading = false;
        this.mesures = this.mesures || [];
        this.domaineMesure = this.domaine;
        this.referentiel = this.referentiel;

        this.onDelete = this.onDelete || angular.noop;
        this.onDeleteItem = this.onDeleteItem || angular.noop;
        this.onDeleteAll = this.onDeleteAll || angular.noop;
        this.onSelectMesure = this.onSelectMesure || angular.noop;
        this.onDuplicate = this.onDuplicate || angular.noop;

        this.typesMesures = null;
        this.getTypesMesures();
    }

    async getTypesMesures() {
        try {
            this.typesMesures = await this.ReferentielTypesService.getAllMesures(this.domaineMesure, this.referentiel.familleId);
            const itemVide = { id: 0, libelle: this.$translate.instant('REFERENCES.AUCUN_FILTRE') };
            this.typesMesures.splice(0, 0, itemVide);
        } catch (ex) {
            //this.notification.error(ex.data);
        }
    }

    getDomaineCourant() {
        const domaine = this.$state.current.name;
        return domaine.substring(0, domaine.indexOf('.'));
    }

    async selectMesure(mesure) {
        if (this.onSelectMesure) {
            this.onSelectMesure({ mesure: mesure });
        }
    }
}
