import FTPController from './ftp.controller';

export default {
    bindings: {
        synthese: '<',
        loading: '='
    },
    controller: FTPController,
    template: require('./ftp.tpl.html')
};
