import ProduitFormAssocieController from './produit.form.associes.controller';

export default {
    bindings: {
        produit: '=',
        isOpen: '='
    },
    template: require('./produit.form.associes.html'),
    controller: ProduitFormAssocieController
};
