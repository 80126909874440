(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.dragdrop')
        .directive('acDroppable', droppable);
    droppable.$inject = ['$timeout', '_', '$log', '$document', 'acDragDropService'];

    /* @ngInject */
    function droppable($timeout, _, $log, $document, acDragDropService) {
        /* jshint validthis: true */
        var directive = {
            restrict: 'A',
            scope: {
                acDroppable: '&',
                acDroppableTarget: '=',
                acDroppableMouseover: '&',
                acDroppableDisabled: '&?',
                acCanDrop: '&?',
                acBeforeDrop: '&?'
            },
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            var timer;
            var targetObject, draggedObject, parentObject;

            // native object
            var el = element[0];
            var onDestroy = scope.$on('$destroy', dispose);

            targetObject = scope.acDroppableTarget;

            el.addEventListener('dragover', dragover, false);
            el.addEventListener('dragenter', dragenter, false);
            el.addEventListener('dragleave', dragleave, false);
            el.addEventListener('drop', drop, false);

            function dragover(e) {
                // on stoppe l'évènement sous certaines conditions
                if (preventDrop(draggedObject, targetObject, parentObject)) {
                    if (e.stopPropagation) e.stopPropagation();
                    if (e.preventDefault) e.preventDefault();
                    e.dataTransfer.dropEffect = "none";
                    return;
                }

                // on ouvre le noeud après un certain temps
                if (timer === undefined) {
                    timer = $timeout(function () {
                        scope.$apply(function (scope) {
                            var fn = scope.acDroppableMouseover();
                            if ('undefined' !== typeof fn) {
                                fn();
                            }
                        });
                    }, 1000);
                }

                // prevent selecting the parent
                if (e.stopPropagation) e.stopPropagation();

                e.dataTransfer.dropEffect = 'move';
                // allows us to drop
                if (e.preventDefault) e.preventDefault();
                this.classList.add('drag-over');
                return false;
            }

            function dragenter(e) {
                clearClasses();
                // on stoppe l'évènement sous certaines conditions

                draggedObject = acDragDropService.getDraggedObject();
                parentObject = acDragDropService.getParentObject();

                if (preventDrop(draggedObject, targetObject, parentObject)) {
                    if (e.stopPropagation) e.stopPropagation();
                    if (e.preventDefault) e.preventDefault();
                    e.dataTransfer.dropEffect = "none";
                    return;
                }

                // prevent selecting the parent
                if (e.stopPropagation) e.stopPropagation();

                this.classList.add('drag-over');
                return false;
            }

            function dragleave(e) {
                stopTimer();
                clearClasses();
                return false;
            }

            function drop(e) {
                clearClasses();
                stopTimer();

                // on stoppe l'évènement sous certaines conditions
                if (!canDrop(draggedObject, targetObject, parentObject)) {
                    $log.warn('drop prevented');
                    if (e.stopPropagation) e.stopPropagation();
                    if (e.preventDefault) e.preventDefault();
                    return;
                }

                // on appelle l'éventuelle fonction de beforeDrop
                scope.$apply(function (scope) {
                    var beforeDrop = angular.isFunction(scope.acBeforeDrop) ? scope.acBeforeDrop() : undefined;

                    if (angular.isFunction(beforeDrop)) {
                        beforeDrop(draggedObject, targetObject, parentObject);
                    }
                });

                // empêche certain navigateurs d'effectuer une redirection
                if (e.stopPropagation) e.stopPropagation();
                if (e.preventDefault) e.preventDefault();


                // call the passed drop function
                scope.$apply(function (scope) {
                    var drop = scope.acDroppable();
                    if ('undefined' !== typeof drop) {
                        drop(draggedObject, targetObject, parentObject);
                    }
                });

                return false;
            }

            function stopTimer() {
                if (timer !== undefined) {
                    $timeout.cancel(timer);
                    timer = undefined;
                }
            }

            function preventDrop(draggedModel, targetModel, parentModel) {
                var ret = true;

                // on appelle l'éventuelle fonction de validation de drop
                scope.$apply(function (scope) {
                    var droppableDisabled = angular.isFunction(scope.acDroppableDisabled) ? scope.acDroppableDisabled() : undefined;

                    if (angular.isFunction(droppableDisabled)) {
                        ret = droppableDisabled(draggedModel, targetModel, parentModel);
                    }
                });

                return ret;
            }

            function canDrop(srcModel, targetModel, parentModel) {
                var ret = true;

                // on appelle l'éventuelle fonction de validation de drop
                scope.$apply(function (scope) {
                    var canDrop = angular.isFunction(scope.acCanDrop) ? scope.acCanDrop() : undefined;

                    if (angular.isFunction(canDrop)) {
                        ret = canDrop(srcModel, targetModel, parentModel);
                    }
                });

                return ret;
            }

            function clearClasses() {
                el.classList.remove('drag-over');
                // dans le cas où plusieurs éléments on la classe 'drag-over', on la supprime partout
                var elts = angular.element($document[0].body.querySelector('.drag-over'));
                angular.forEach(elts, function (i) {
                    i.classList.remove('drag-over');
                });
            }

            function dispose() {
                el.removeEventListener('dragover', dragover);
                el.removeEventListener('dragenter', dragenter);
                el.removeEventListener('dragleave', dragleave);
                el.removeEventListener('drop', drop);
                stopTimer();

                if (onDestroy !== undefined) {
                    onDestroy();
                }
            }
        }
    }
})(angular);