export default function TokenInterceptor($httpProvider, AuthConstant) {
    // Cet interceptor se charge d'ajouter le token utilisateur (s'il existe) à chaque appel HTTP réussi
    $httpProvider.interceptors.push([
        'localStorageService',
        function (localStorageService) {
            return {
                request: function (config) {
                    config.headers = config.headers || {};
                    const authData = localStorageService.get(AuthConstant.MASSIA_AUTH_DATA_STORAGE);
                    if (authData) {
                        config.headers.Authorization = 'Bearer ' + authData.token;
                    }

                    return config;
                }
            };
        }
    ]);
}

TokenInterceptor.$inject = ['$httpProvider', 'AuthConstant'];
