import ProduitFormNiveauxStockController from './produit.form.niveaux.stocks.controller';

export default {
    bindings: {
        produit: '=',
        form: '=',
        onUpdate: '&'
    },
    template: require('./produit.form.niveaux.stocks.html'),
    controller: ProduitFormNiveauxStockController
};
