export default class SyntheseEntitiesTraitementsController {
    /* @ngInject */
    constructor(_, FormulesService, ColorPickerService, $translate, Interpreteur) {
        this._ = _;
        this.FormulesService = FormulesService;
        this.ColorPickerService = ColorPickerService;
        this.$translate = $translate;
        this.Interpreteur = Interpreteur;
    }
    $onInit() {
        try {
            this.calculs = this.model.calculs;
            this.essais = this.model.essais;
            this.formattedResult = this.model.formattedResult;
            this.sousEssaisTamis = this.model.sousEssaisTamis;
            this.synthese = this.model.synthese;
        } catch (error) {
            console.log(
                '🚀 ~ file: syntheseentities-traitements.controller.js ~ line 29 ~ SyntheseEntitiesTraitementsController ~ $onInit ~ error',
                error
            );
        }
    }
    $onDestroy() {}

    async removePrelevement(lig) {
        lig.ecartee = true;
        await this.relanceTraitementsStat();
        if (this.synthese.typeDeSynthese === 18) {
            this.onDelete();
        }
    }

    async relanceTraitementsStat() {
        this.calculs = _.orderBy(this.calculs, ['position']);
        this.resultat.stats = [];
        const estFTP = this.model.synthese.typeDeSynthese === 1;
        let ctrlMoyFTP = estFTP && this.resultat.referentiel ? this.resultat.referentiel.enveloppeNorme : null;
        let ctrlEctFTP = estFTP && this.resultat.referentiel ? this.resultat.referentiel.controleETNorme : null;
        const estCtrlAcq = this.model.synthese.typeDeSynthese === 10;
        if (estCtrlAcq) {
            ctrlMoyFTP = this.model.synthese.ftpArchivee.enveloppeNorme;
            ctrlEctFTP = this.model.synthese.ftpArchivee.controleETNorme;
        }
        const plkEss = this._.map(this.model.resultat.prelevementBlock, 'listeSousEssaiLignes');
        const essaisFlat = this._.flatten(plkEss);
        const plkTam = this._.map(this.model.resultat.prelevementBlock, 'listeTamisLignes');
        const tamisFlat = this._.flatten(plkTam);

        const statEcartType =
            estFTP || estCtrlAcq
                ? this.model.synthese.listeSyntheseCritereTraitement.find(function (e) {
                      return e.formule === '@ECT' && !e.isGroupe;
                  })
                : null;
        const statMoyenne =
            estFTP || estCtrlAcq
                ? this.model.synthese.listeSyntheseCritereTraitement.find(function (e) {
                      return e.formule === '@MOY' && !e.isGroupe;
                  })
                : null;
        for (let i = 0; i < this.calculs.length; i++) {
            const stat = {
                titre: this.calculs[i].libelle,
                statsEssais: [],
                couleurFond: this.getColorFromHSL(this.calculs[i].couleurFond),
                couleurTexte: this.getColorFromHSL(this.calculs[i].couleurTexte),
                groupementIndex: this.calculs[i].groupementIndex,
                formule: this.calculs[i].formule,
                code: this.calculs[i].code,
                isImprimable: this.calculs[i].isImprimable,
                isControle: this.calculs[i].isControle,
                idCalcStat: this.calculs[i].idCalcStat,
                isGroupement: this.calculs[i].isGroupement,
                isGroupementGlissant: this.calculs[i].isGroupementGlissant,
                nbValGroupe: this.calculs[i].nbValGroupe,
                isCumul: this.calculs[i].isCumul,
                nbValMinCumul: this.calculs[i].nbValMinCumul,
                isTranspose: this.calculs[i].isTranspose,
                isLastGroupOnly: this.calculs[i].isLastGroupOnly,
                arrondi: this.calculs[i].arrondi,
                arrondiDeLEssai: this.calculs[i].arrondiDeLEssai,
                decimaleSupp: this.calculs[i].decimaleSupp
            };
            if (this.calculs[i].estMini) {
                stat.titre += ' (' + this.$translate.instant('SYNTHENTS.MINIMUM') + ')';
            } else if (this.calculs[i].estMaxi) {
                stat.titre += ' (' + this.$translate.instant('SYNTHENTS.MAXIMUM') + ')';
            }
            for (let j = 0; j < this.resultat.entete.titresSousEssais.length; j++) {
                const ssEss = this.resultat.entete.titresSousEssais[j];
                const ess = this.resultat.entete.titresEssais.find((e) => e.id === this.resultat.entete.titresSousEssais[j].idEssai);
                ssEss.code = ess.code;

                const titre = this.resultat.entete.titresSousEssais[j].titre;
                const sse = this.sousEssaisTamis.find(function (e) {
                    return e.libelle === titre;
                });
                sse.codeEssai = ess.code;
                const statlEss = {
                    valeur: await this.getStatEssai(this.calculs[i], sse),
                    isComplementaire: sse.isComplementaire,
                    conformite: -1,
                    idEssai: ssEss.idEssai,
                    codeEssai: ess.code,
                    codeSousEssai: ssEss.codeSsEss,
                    idSsEss: ssEss.id
                };
                if (statlEss.valeur !== null && statlEss.valeur !== undefined && statlEss.valeur !== '') {
                    let borneStat = undefined;
                    let estFTPEss = estFTP;
                    let ignoreAcq = false;

                    if (estCtrlAcq) {
                        //si moins de n valeurs fournisseur en contrôle acquéreur, on fait les mêmes contrôles qu'en FTP
                        const nbrFTP = sse.idTamis
                            ? this._.filter(this.model.synthese.ftpArchivee.valeurs, {
                                  idEssai: sse.idEssai,
                                  isTamis: true,
                                  idTamis: sse.idTamis,
                                  formuleCalcStat: '@NBR'
                              })
                            : this._.filter(this.model.synthese.ftpArchivee.valeurs, {
                                  idEssai: sse.idEssai,
                                  isTamis: false,
                                  idSsEss: sse.idSousEssai,
                                  formuleCalcStat: '@NBR'
                              });
                        const nbValeursReelFrn = nbrFTP && nbrFTP.length == 1 ? parseFloat(nbrFTP[0].valeur) : 0;
                        const nReferenceAcq = this.model.synthese.ftpArchivee.nombreResultatsFournisseur;
                        if (nReferenceAcq && nbValeursReelFrn < nReferenceAcq) {
                            estFTPEss = true;
                            ignoreAcq = true;
                            //ne faire les contrôles sur moyenne et écart-type que si critère F2 (forcé ou bVal>=n)
                            if (!this.model.synthese.critereF2) {
                                var tabFiltre = sse.idTamis
                                    ? this._.filter(tamisFlat, { idEssai: sse.idEssai, idTamis: sse.idTamis })
                                    : this._.filter(essaisFlat, { idEssai: sse.idEssai, idSousEssai: sse.idSousEssai });
                                var nbValeursReel = tabFiltre.length;
                                if (nReferenceAcq && nbValeursReel < nReferenceAcq) {
                                    estFTPEss = false;
                                }
                            }
                        }
                    } else if (estFTPEss) {
                        //ne faire les contrôles sur moyenne et écart-type que si critère F2 (forcé ou bVal>=n)
                        if (!this.model.synthese.critereF2) {
                            var tabFiltre = sse.idTamis
                                ? this._.filter(tamisFlat, { idEssai: sse.idEssai, idTamis: sse.idTamis })
                                : this._.filter(essaisFlat, { idEssai: sse.idEssai, idSousEssai: sse.idSousEssai });
                            var nbValeursReel = tabFiltre.length;
                            const nReference = this.resultat.referentiel ? this.resultat.referentiel.nombreResultatsN : null;
                            if (nReference && nbValeursReel < nReference) {
                                estFTPEss = false;
                            }
                        }
                    }

                    if (estFTPEss && statEcartType) {
                        //FTP : contrôles en dur
                        borneStat = estCtrlAcq ? this.getBornesFTPA(sse) : this.getBornes(sse, '@VAL');
                        if (borneStat != null && borneStat != undefined) {
                            //chercher l'écart-type
                            const ect = await this.getStatEssai(statEcartType, sse);
                            //contrôle de la moyenne entre VSI+1.25*ECT et VSS-1.25*ECT (1.25 paramétrable)
                            if (this.calculs[i].formule == '@MOY' && ctrlMoyFTP && statMoyenne) {
                                //chercher la moyenne
                                const moy = await this.getStatEssai(statMoyenne, sse);
                                try {
                                    const borneControle = {
                                        borneSuperieure:
                                            borneStat.borneSuperieure != null && borneStat.borneSuperieure != undefined
                                                ? Number(borneStat.borneSuperieure) - Number(ctrlMoyFTP) * ect
                                                : null,
                                        borneInferieure:
                                            borneStat.borneInferieure != null && borneStat.borneInferieure != undefined
                                                ? Number(borneStat.borneInferieure) + Number(ctrlMoyFTP) * ect
                                                : null
                                    };
                                    statlEss.conformite = this.controleConformite(moy, borneControle, false);
                                } catch (ex) {
                                    console.log('erreur moy FTP', ect, ctrlMoyFTP);
                                }
                            }
                            //contrôle de l'écart-type inférieur à e/3.3 (3.3 paramétrable)
                            if (this.calculs[i].formule == '@ECT' && ctrlEctFTP && borneStat.isETControle && borneStat.etendueReference != null) {
                                //récupérer e
                                try {
                                    const borneControle = { borneSuperieure: Number(borneStat.etendueReference) / Number(ctrlEctFTP) };
                                    statlEss.conformite = this.controleConformite(ect, borneControle, false);
                                } catch (ex) {
                                    console.log('erreur ect FTP', borneStat.etendueReference, ctrlEctFTP);
                                }
                            }
                        }
                    } else if (estCtrlAcq) {
                        //Contrôle acquéreur avec nb val fournisseur>=n : contrôles en dur
                        borneStat = this.getBornesFTPA(sse);
                        const tabFiltreAcq = sse.idTamis
                            ? this._.filter(tamisFlat, { idEssai: sse.idEssai, idTamis: sse.idTamis })
                            : this._.filter(essaisFlat, { idEssai: sse.idEssai, idSousEssai: sse.idSousEssai });
                        const nbValeursReelAcq = tabFiltreAcq.length;
                        const naReferenceAcq = this.model.synthese.ftpArchivee.nombreResultatsAcquereur;
                        if (!ignoreAcq && naReferenceAcq && nbValeursReelAcq >= naReferenceAcq) {
                            //au moins na valeurs de l'acquéreur, contrôle de la moyenne
                            //contrôle de la moyenne Xa entre Xf-U et Xf+U (Xa moyenne acquéreur, Xf moyenne fournisseur)
                            if (this.calculs[i].formule == '@MOY' && statMoyenne) {
                                //chercher la moyenne du fournisseur
                                const moyFTP = sse.idTamis
                                    ? this._.filter(this.model.synthese.ftpArchivee.valeurs, {
                                          idEssai: sse.idEssai,
                                          isTamis: true,
                                          idTamis: sse.idTamis,
                                          formuleCalcStat: '@MOY'
                                      })
                                    : this._.filter(this.model.synthese.ftpArchivee.valeurs, {
                                          idEssai: sse.idEssai,
                                          isTamis: false,
                                          idSsEss: sse.idSousEssai,
                                          formuleCalcStat: '@MOY'
                                      });
                                const moyenneFrnXf = moyFTP && moyFTP.length == 1 ? parseFloat(moyFTP[0].valeur) : 0;
                                //chercher la moyenne de l'acquéreur
                                const moyXa = await this.getStatEssai(statMoyenne, sse);
                                try {
                                    const borneControle = {
                                        borneSuperieure:
                                            borneStat.incertitudeReference != null && borneStat.incertitudeReference != undefined
                                                ? moyenneFrnXf + parseFloat(borneStat.incertitudeReference)
                                                : null,
                                        borneInferieure:
                                            borneStat.incertitudeReference != null && borneStat.incertitudeReference != undefined
                                                ? moyenneFrnXf - parseFloat(borneStat.incertitudeReference)
                                                : null
                                    };
                                    statlEss.conformite = this.controleConformite(moyXa, borneControle, false);
                                } catch (ex) {
                                    console.log('erreur moy CTRLACQ', ect, ctrlMoyFTP);
                                }
                            }
                        }
                    } else {
                        borneStat = this.getBornes(sse, this.calculs[i].formule);
                        if (borneStat != null && borneStat != undefined) {
                            statlEss.conformite = this.controleConformite(statlEss.valeur, borneStat, false);
                        }
                    }
                }
                stat.statsEssais.push(statlEss);
            }
            this.resultat.stats.push(stat);
        }
        // On traite le CS de conformite après les calculs de conformite
        // de toutes les valeurs d'essai et de toutes les autres CS
        this.resultat.stats.forEach((stat) => {
            if (stat.formule == '@CONF') {
                const lignesEssais = [];
                this.resultat.lignes.forEach((element) => {
                    if (!element.ecartee) {
                        lignesEssais.push(element.valeursEssais);
                    }
                });
                const statEssai = stat.statsEssais;
                const statsEssais = this._.map(this.resultat.stats, 'statsEssais');
                const tabConformites = ['Conforme', 'Alerte', 'Non Conforme', 'Conforme (U)', 'Non Contrôlé'];

                for (let i = 0; i < statEssai.length; i++) {
                    const lstConfByEssai = [];
                    const lstConfByStat = [];
                    let lignesConf = [];
                    let statsConf = [];

                    lignesEssais.forEach((ligne) => {
                        lstConfByEssai.push(ligne[i].conformite);
                    });
                    statsEssais.forEach((statEss) => {
                        lstConfByStat.push(statEss[i].conformite);
                    });

                    lignesConf = this._.uniq(lstConfByEssai);
                    statsConf = this._.uniq(lstConfByStat);
                    const lstConformites = this._.union(lignesConf, statsConf);
                    let confConverted = this.getConformite(lstConformites);
                    if (confConverted === null || typeof confConverted === 'undefined') {
                        confConverted = 4;
                    }
                    stat.statsEssais[i] = Object.assign({}, stat.statsEssais[i], { valeur: tabConformites[confConverted] });
                }
            }
        });
        this.verifieConformiteSynthese();
        if (this.synthese.typeDeSynthese !== 7 && this.synthese.typeDeSynthese !== 18) {
            await this.preparegraphData();
        }
    }

    getColorFromHSL(coul) {
        if (coul) {
            const hexa = this.ColorPickerService.hslToRgb(coul.hue, coul.saturation, coul.luminosity);
            return 'rgba(' + hexa.red + ',' + hexa.green + ',' + hexa.blue + ', ' + (coul.alpha || coul.alpha === 0 ? coul.alpha : '1') + ')';
        }
        return 'rgb(0,0,0,100)';
    }

    async getStatEssai(stat, essStat) {
        let listVal = undefined;
        let listConf = undefined;
        let listDimRq = undefined;
        let resultat = undefined;
        let formule = stat.formule;

        //stats particulières
        if (stat.formule.includes('@NORME')) {
            const essNorme = essStat.normeEssai;
            if (stat.formule == '@NORME') {
                return essNorme;
            }
            formule = formule.replace(/@NORME/g, essNorme);
        }
        if (stat.formule.includes('@DATEDERN')) {
            const dateDern = this.getDerniereDateValeurs(essStat);
            if (stat.formule == '@DATEDERN') {
                return dateDern;
            }
            formule = formule.replace(/@DATEDERN/g, dateDern);
        }
        if (stat.formule.includes('@DIMRQ')) {
            if (!listDimRq) {
                listDimRq = this.getListeDimRq(essStat);
            }
            if (listDimRq.length > 0) {
                if (stat.formule == '@DIMRQ') {
                    return listDimRq[0];
                }
                formule = formule.replace(/@DIMRQ/g, listDimRq[0]);
            }
        }
        if (stat.formule.includes('@NBCONF')) {
            let resultatNbConf = 0;
            if (!listConf) {
                listConf = this.getListeConformites(essStat);
            }
            if (listConf.length > 0) {
                const conformes = _.filter(listConf, function (num) {
                    return num == 0;
                });
                if (conformes) {
                    resultatNbConf = conformes.length;
                } else {
                    resultatNbConf = 0;
                }
            }
            formule = formule.replace(/@NBCONF/g, resultatNbConf);
        }
        if (stat.formule.includes('@POURCCONF')) {
            let resultatPrcConf = 0;
            if (!listConf) {
                listConf = this.getListeConformites(essStat);
            }
            if (listConf.length > 0) {
                const conformes = _.filter(listConf, function (num) {
                    return num == 0;
                });
                if (conformes) {
                    resultatPrcConf = (conformes.length * 100) / listConf.length;
                } else {
                    resultatPrcConf = 0;
                }
            }
            formule = formule.replace(/@POURCCONF/g, resultatPrcConf);
        }

        // On récupère la catégorie de la norme de l'essai
        if (stat.formule.includes('@CATEG')) {
            const normeCatEssai = this.getNormeCatEssai(essStat);
            if (stat.formule == '@CATEG') {
                return normeCatEssai;
            }
            formule = formule.replace(/@CATEG/g, normeCatEssai);
        }

        //construire la liste de valeurs
        if (!listVal) {
            listVal = await this.getListeValeurs(essStat, stat);
        }
        let strForFormule = '';
        if (listVal.length > 0) {
            for (let i = 0; i < listVal.length; i++) {
                const val = essStat.idNature === 3 || essStat.idNature === 62 ? this.FormulesService.strEnDouble(listVal[i]) : listVal[i];
                if (strForFormule != '') {
                    strForFormule += ';';
                }
                strForFormule += val;
            }
        }

        if (this.Interpreteur) {
            this.Interpreteur.calculatedFormules = [];
        }

        //stats récupérant des valeurs de références
        if (this.model.synthese.typeDeSynthese === 10) {
            this.Interpreteur.borne = this.getBornesFTPA(essStat);
        } else {
            this.Interpreteur.borne = this.getBornes(essStat, '@VAL');
        }

        this.Interpreteur.listeValeursStr = strForFormule;

        //traiter la demande de moyenne de la FTPA à part
        let ignoreFormule = false;
        if (this.model.synthese.typeDeSynthese === 10 && formule.includes('@FTPMOY')) {
            const moyFTP = essStat.idTamis
                ? this._.filter(this.model.synthese.ftpArchivee.valeurs, {
                      idEssai: essStat.idEssai,
                      isTamis: true,
                      idTamis: essStat.idTamis,
                      formuleCalcStat: '@MOY'
                  })
                : this._.filter(this.model.synthese.ftpArchivee.valeurs, {
                      idEssai: essStat.idEssai,
                      isTamis: false,
                      idSsEss: essStat.idSousEssai,
                      formuleCalcStat: '@MOY'
                  });
            if (moyFTP && moyFTP.length == 1) {
                formule = formule.replace(/@FTPMOY/g, moyFTP[0].valeur);
            } else {
                ignoreFormule = true;
            }
        }

        //appel au calcul de formule
        resultat = ignoreFormule ? '' : await this.Interpreteur.calculeFormule(formule, false, essStat.idNature);
        if (resultat !== undefined && resultat !== null && resultat !== '') {
            if (!(essStat.idNature === 0 || essStat.idNature === 1 || essStat.idNature === 5 || essStat.idNature === 63 || essStat.idNature === 60)) {
                if (!isNaN(resultat)) {
                    //gestion des min/max
                    if (stat.limiterGrandeur) {
                        if (essStat.grandeurMinimum != undefined && essStat.grandeurMinimum != null && essStat.grandeurMinimum > resultat) {
                            resultat = essStat.grandeurMinimum;
                        }
                        if (essStat.grandeurMaximum != undefined && essStat.grandeurMaximum != null && essStat.grandeurMaximum < resultat) {
                            resultat = essStat.grandeurMaximum;
                        }
                    }
                    //gestion de l'arrondi
                    if (stat.arrondiDeLEssai) {
                        let newArrondi =
                            stat.decimaleSupp !== 0 && stat.decimaleSupp !== null
                                ? this.Interpreteur.formatDecSupp(essStat.arrondi, stat.decimaleSupp)
                                : essStat.arrondi;
                        return this.arrondiValeur(resultat, newArrondi);
                    }
                    if (stat.arrondi != undefined && stat.arrondi != null) {
                        return this.arrondiValeur(resultat, stat.arrondi);
                    }
                    return this.arrondiValeur(resultat, 0);
                }
            } else {
                return resultat;
            }
        }
        return '';
    }

    getBornesFTPA(essStat) {
        //créer la borne à partir des valeurs de la FTP
        const borneFTPA = {};
        //VSI
        const vsiFTP = essStat.idTamis
            ? this._.filter(this.model.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: true,
                  idTamis: essStat.idTamis,
                  formuleCalcStat: '@VSI'
              })
            : this._.filter(this.model.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: false,
                  idSsEss: essStat.idSousEssai,
                  formuleCalcStat: '@VSI'
              });
        if (vsiFTP && vsiFTP.length == 1) {
            borneFTPA.borneInferieure = vsiFTP[0].valeur;
        }
        //VSS
        const vssFTP = essStat.idTamis
            ? this._.filter(this.model.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: true,
                  idTamis: essStat.idTamis,
                  formuleCalcStat: '@VSS'
              })
            : this._.filter(this.model.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: false,
                  idSsEss: essStat.idSousEssai,
                  formuleCalcStat: '@VSS'
              });
        if (vssFTP && vssFTP.length == 1) {
            borneFTPA.borneSuperieure = vssFTP[0].valeur;
        }
        //U
        const uFTP = essStat.idTamis
            ? this._.filter(this.model.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: true,
                  idTamis: essStat.idTamis,
                  formuleCalcStat: '@UNORME'
              })
            : this._.filter(this.model.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: false,
                  idSsEss: essStat.idSousEssai,
                  formuleCalcStat: '@UNORME'
              });
        if (uFTP && uFTP.length == 1) {
            borneFTPA.incertitudeReference = uFTP[0].valeur;
        }
        //e
        const eFTP = essStat.idTamis
            ? this._.filter(this.model.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: true,
                  idTamis: essStat.idTamis,
                  formuleCalcStat: '@ENORME'
              })
            : this._.filter(this.model.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: false,
                  idSsEss: essStat.idSousEssai,
                  formuleCalcStat: '@ENORME'
              });
        if (eFTP && eFTP.length == 1) {
            borneFTPA.etendueReference = eFTP[0].valeur;
        }
        const essFTPA = essStat.idTamis
            ? this._.filter(this.model.synthese.ftpArchivee.essais, {
                  idEssai: essStat.idEssai,
                  isTamis: true,
                  idSsEssTamis: essStat.idTamis
              })
            : this._.filter(this.model.synthese.ftpArchivee.essais, {
                  idEssai: essStat.idEssai,
                  isTamis: false,
                  idSsEss: essStat.idSousEssai
              });
        if (essFTPA && essFTPA.length == 1) {
            borneFTPA.isETControle = essFTPA[0].isCtrlEcartType;
        }
        return borneFTPA;
    }

    getBornes(essStat, formuleCalcul) {
        if (this.resultat && this.resultat.referentiel) {
            if (essStat.tamis) {
                return this._.find(this.resultat.referentiel.mesures, {
                    essaiId: essStat.idEssai,
                    tamis: essStat.tamis,
                    ouvertureTamis: parseFloat(essStat.libelle),
                    calculFormule: formuleCalcul
                });
            }
            return this._.find(this.resultat.referentiel.mesures, {
                essaiId: essStat.idEssai,
                tamis: essStat.tamis,
                sousEssaiId: essStat.idSousEssai,
                calculFormule: formuleCalcul
            });
        }
        return undefined;
    }

    controleConformite(valeur, borne, priseEnCompteU) {
        if (!borne || valeur === null || valeur === undefined || valeur === '') {
            return -1;
        }
        let conformite = 0;
        if (borne.borneInferieure) {
            if (valeur < parseFloat(borne.borneInferieure)) {
                if (borne.incertitudeReference && priseEnCompteU) {
                    if (valeur < parseFloat(borne.borneInferieure) - parseFloat(borne.incertitudeReference)) {
                        conformite = 2;
                    } else {
                        conformite = 3;
                    }
                } else {
                    conformite = 2;
                }
            } else {
                if (borne.alerteInferieure && valeur < parseFloat(borne.alerteInferieure)) {
                    conformite = 1;
                }
            }
        }
        if (borne.borneSuperieure) {
            if (valeur > parseFloat(borne.borneSuperieure)) {
                if (borne.incertitudeReference && priseEnCompteU) {
                    if (valeur > parseFloat(borne.borneSuperieure) + parseFloat(borne.incertitudeReference)) {
                        conformite = 2;
                    } else {
                        conformite = 3;
                    }
                } else {
                    conformite = 2;
                }
            } else {
                if (conformite < 2 && parseFloat(borne.alerteSuperieure) && valeur > parseFloat(borne.alerteSuperieure)) {
                    conformite = 1;
                }
            }
        }
        return conformite;
    }

    verifieConformiteSynthese() {
        this.model.conformiteSynthese = -1;

        // Récupérer les conformités des valeurs qui ne sont pas ecartees
        let lignesEssais = [];
        this.resultat.lignes.forEach((element) => {
            if (!element.ecartee) {
                lignesEssais.push(element.valeursEssais);
            }
        });

        lignesEssais = this._.flatten(lignesEssais);
        let lignesConf = this._.map(lignesEssais, 'conformite');
        lignesConf = this._.uniq(lignesConf);
        //récupérer les conformités des stats
        let statsEssais = this._.map(this.resultat.stats, 'statsEssais');
        statsEssais = this._.flatten(statsEssais);
        let statsConf = this._.map(statsEssais, 'conformite');
        statsConf = this._.uniq(statsConf);
        const conformites = this._.union(lignesConf, statsConf);
        this.model.conformiteSynthese = this.getConformite(conformites);
    }

    async preparegraphData() {
        this.graphData = { Abscisse: [] };
        for (let i = 0; i < this.resultat.stats.length; i++) {
            const stat = this.resultat.stats[i];
            const nomSerie = stat.formule.substring(1);
            if (!stat.formule.includes('@CONF')) {
                this.graphData[nomSerie] = {};
                this.graphData[nomSerie].label = stat.titre;
                this.graphData[nomSerie].isControle = stat.isControle;
                this.graphData[nomSerie].hidden = false;
                this.graphData[nomSerie].values = [];
                for (let j = this.resultat.entete.titresSousEssais.length - 1; j >= 0; j--) {
                    const ssEss = angular.copy(this.resultat.entete.titresSousEssais[j]);
                    if (ssEss.isTamis) {
                        if (i == 0) {
                            const val = { value: Number(ssEss.titre) };
                            this.graphData.Abscisse.push(Object.assign({}, ssEss, val));
                        }
                        if (
                            stat.statsEssais[j].valeur != undefined &&
                            stat.statsEssais[j].valeur != null &&
                            stat.statsEssais[j].valeur != ''
                            //! Attention si on ne prends pas les valeurs 'string' la courbe n'est pas complète ou affichage bizarre !!!
                            // typeof stat.statsEssais[j].valeur !== 'string'
                        ) {
                            if (typeof stat.statsEssais[j].valeur === 'string') {
                                stat.statsEssais[j].valeur = Number.parseFloat(stat.statsEssais[j].valeur);
                            }
                            this.graphData[nomSerie].values.push(Number(stat.statsEssais[j].valeur));
                        } else {
                            //SP 16/11/20 (5469) ne pas afficher 0 ou 100 si il n'y a pas l'autre valeur...
                            if (stat.formule.includes('@VSI')) {
                                //chercher VSS
                                const statVSS = this._.find(this.resultat.stats, { formule: '@VSS' });
                                if (
                                    statVSS &&
                                    statVSS.statsEssais[j].valeur != undefined &&
                                    statVSS.statsEssais[j].valeur != null &&
                                    statVSS.statsEssais[j].valeur != ''
                                ) {
                                    this.graphData[nomSerie].values.push(0);
                                } else {
                                    this.graphData[nomSerie].values.push(undefined);
                                }
                            } else if (stat.formule.includes('@LI')) {
                                //chercher LS
                                const statLS = this._.find(this.resultat.stats, { formule: '@LS' });
                                if (
                                    statLS &&
                                    statLS.statsEssais[j].valeur != undefined &&
                                    statLS.statsEssais[j].valeur != null &&
                                    statLS.statsEssais[j].valeur != ''
                                ) {
                                    this.graphData[nomSerie].values.push(0);
                                } else {
                                    this.graphData[nomSerie].values.push(undefined);
                                }
                            } else if (stat.formule.includes('@VSS')) {
                                //chercher VSI
                                const statVSI = this._.find(this.resultat.stats, { formule: '@VSI' });
                                if (
                                    statVSI &&
                                    statVSI.statsEssais[j].valeur != undefined &&
                                    statVSI.statsEssais[j].valeur != null &&
                                    statVSI.statsEssais[j].valeur != ''
                                ) {
                                    this.graphData[nomSerie].values.push(100);
                                } else {
                                    this.graphData[nomSerie].values.push(undefined);
                                }
                            } else if (stat.formule.includes('@LS')) {
                                //chercher LI
                                const statLI = this._.find(this.resultat.stats, { formule: '@LI' });
                                if (
                                    statLI &&
                                    statLI.statsEssais[j].valeur != undefined &&
                                    statLI.statsEssais[j].valeur != null &&
                                    statLI.statsEssais[j].valeur != ''
                                ) {
                                    this.graphData[nomSerie].values.push(100);
                                } else {
                                    this.graphData[nomSerie].values.push(undefined);
                                }
                            } else {
                                this.graphData[nomSerie].values.push(undefined);
                            }
                        }
                    }
                }
            }
        }
        this.graphData.nbElement = this.graphData.Abscisse.length;
        this.graphData.idEssai = this.graphData.Abscisse.length > 0 ? this.graphData.Abscisse[0].idEssai : 0;
    }

    async getListeValeurs(essStat, stat) {
        let listVal = [];

        if (this.Interpreteur) {
            this.Interpreteur.calculatedFormules = [];
        }
        for (let i = 0; i < this.resultat.lignes.length; i++) {
            const ligneResultat = this.resultat.lignes[i];
            if (!ligneResultat.ecartee) {
                const originalPrelv = this.model.resultat.prelevementBlock.find((e) => e.idPrelevement === ligneResultat.idPrelevement);

                if (!essStat.tamis) {
                    const sousEssai = this._.find(ligneResultat.valeursEssais, {
                        tamis: essStat.tamis,
                        idEssai: essStat.idEssai,
                        idSousEssai: essStat.idSousEssai
                    });
                    if (sousEssai && sousEssai.valeur !== null && sousEssai.valeur !== undefined && sousEssai.valeur !== '') {
                        if (
                            stat.isTranspose &&
                            originalPrelv &&
                            originalPrelv.familleBetonProduit &&
                            originalPrelv.familleBetonProduit.regleTranspoFormule
                        ) {
                            let arrValues = sousEssai.valeur;
                            if (!Array.isArray(arrValues)) {
                                arrValues = [arrValues];
                            }

                            for (let j = 0; j < arrValues.length; j++) {
                                const formuleTransp = originalPrelv.familleBetonProduit.regleTranspoFormule.replace(/RMES/g, arrValues[j]);
                                sousEssai.valeurTranspo = await this.Interpreteur.calculeFormule(formuleTransp);
                                listVal.push(sousEssai.valeurTranspo);
                            }
                        } else {
                            listVal.push(sousEssai.valeur);
                            listVal = this._.flatten(listVal);
                        }
                    }
                } else {
                    const tamis = this._.find(ligneResultat.valeursEssais, {
                        tamis: true,
                        idEssai: essStat.idEssai,
                        idTamis: essStat.idTamis
                    });
                    if (tamis && tamis.valeur !== null && tamis.valeur !== undefined && tamis.valeur !== '') {
                        if (
                            stat.isTranspose &&
                            originalPrelv &&
                            originalPrelv.familleBetonProduit &&
                            originalPrelv.familleBetonProduit.regleTranspoFormule
                        ) {
                            let arrValues = tamis.valeur;
                            if (!Array.isArray(arrValues)) {
                                arrValues = [arrValues];
                            }

                            for (let j = 0; j < arrValues.length; j++) {
                                const formuleTransp = originalPrelv.familleBetonProduit.regleTranspoFormule.replace(/RMES/g, arrValues[j]);
                                tamis.valeurTranspo = await this.Interpreteur.calculeFormule(formuleTransp);

                                listVal.push(tamis.valeurTranspo);
                            }
                        } else {
                            listVal.push(tamis.valeur);
                        }
                    }
                }
            }
        }

        return listVal;
    }

    arrondiValeur(valeur, arrondi) {
        const objetToRound = [{ key: valeur, val: valeur, rounder: arrondi }];
        return this.Interpreteur.getRoundedValue(objetToRound);
    }

    getConformite(conformites) {
        if (this._.includes(conformites, 2)) {
            return 2;
        }
        if (this._.includes(conformites, 3)) {
            return 3;
        }
        if (this._.includes(conformites, 1)) {
            return 1;
        }
        if (this._.includes(conformites, 0)) {
            return 0;
        }
    }
}
