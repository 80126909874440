export default function Configure($stateProvider) {
    $stateProvider.state('zone-gisements-qse', {
        parent: 'common',
        url: '/zone-gisements-qse',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.ENVIRONNEMENT" | translate }}'
        }
    });

    $stateProvider.state('zone-gisements-qse.list', {
        url: '/list',
        template: '<zone-gisements></zone-gisements>',
        rights: { domain: 'zonesabat', right: 'read', application: 'qse' },
        ncyBreadcrumb: {
            label: '{{ "ZONEGISEMENTS.BREADCRUMBS.ZONEGISEMENTS_LIST" | translate}}'
        },
        // on affiche la liste des zone-gisements dans la navigation
        navigation: {
            menu: 'environnement_tirs_mine',
            translate: 'ZONEGISEMENTS.BREADCRUMBS.ZONEGISEMENTS_LIST',
            order: 2,
            navigationCls: 'menu-item-separator'
        },
        // la liste des zone-gisements fait partie des points d'entrée recherchables dans la navigation
        search: 'ZONEGISEMENTS.BREADCRUMBS.ZONEGISEMENTS_LIST'
    });

    $stateProvider.state('zone-gisements-qse.list.detail', {
        url: '/{id}',
        template: '<zone-gisements-detail></zone-gisements-detail>',
        rights: { domain: 'zonesabat', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher zone-gisements > détail
            parent: 'zone-gisements-qse.list',
            label: '{{ "ZONEGISEMENTS.BREADCRUMBS.ZONEGISEMENT_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('zone-gisements-qse.new', {
        url: '/new',
        template: '<zone-gisements-form></zone-gisements-form>',
        rights: { domain: 'zonesabat', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher zone-gisements > ajouter
            parent: 'zone-gisements-qse.list',
            label: '{{ "ZONEGISEMENTS.BREADCRUMBS.ZONEGISEMENT_NEW" | translate}}'
        },
        // l'ajout d'une entite fait partie des points d'entrée recherchables dans l'application
        search: 'ZONEGISEMENTS.BREADCRUMBS.ZONEGISEMENT_NEW'
    });

    $stateProvider.state('zone-gisements-qse.edit', {
        url: '/{id}/edit',
        template: '<zone-gisements-form></zone-gisements-form>',
        rights: { domain: 'zonesabat', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher zone-gisements > modifier
            parent: 'zone-gisements-qse.list',
            label: '{{ "ZONEGISEMENTS.BREADCRUMBS.ZONEGISEMENT_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
