import ModalImportGranuloController from './modal.import.granulo.controller';

export default {
    bindings: {
        resolve: '=',
        modalInstance: '<'
    },
    template: require('./modal.import.granulo.template.html'),
    controller: ModalImportGranuloController
};
