import NiveauxHierarchiquesConfig from './_config/niveaux.hierarchiques.config';

import NiveauxCommunicationService from './services/niveaux.communication.service';

import NiveauxHierarchiquesComponent from './components/niveaux-hierarchiques';
import NiveauxHierarchiquesTrameComponent from './components/niveaux-hierarchiques-trame';
import NiveauxHierarchiquesElementsComponent from './components/niveaux-hierarchiques-elements';
import NiveauxHierarchiquesFormComponent from './components/niveaux-hierarchiques-form';

import ElementValidator from './components/niveaux-hierarchiques-elements/modale-creation/modale.creation.element.validator';
import TrameValidator from './components/niveaux-hierarchiques-trame/modale-creation/modale.creation.trame.validator';

const moduleName = 'app.massia.common.niveauxHierarchiques';

angular
    .module(moduleName, [])
    .config(NiveauxHierarchiquesConfig)
    .service('NiveauxCommunicationService', NiveauxCommunicationService)
    .component('niveauxHierarchiques', NiveauxHierarchiquesComponent)
    .component('niveauxHierarchiquesTrame', NiveauxHierarchiquesTrameComponent)
    .component('niveauxHierarchiquesElements', NiveauxHierarchiquesElementsComponent)
    .component('niveauxHierarchiquesForm', NiveauxHierarchiquesFormComponent)
    .factory('ElementValidator', ElementValidator)
    .factory('TrameValidator', TrameValidator);

export default moduleName;
