export default class DataShareService {
    constructor() {
        this.savedData = [];
    }

    setDataShare(dataToUse, entityName) {
        const data = _.cloneDeep(dataToUse);
        if (_.isEmpty(this.savedData) == false) {
            const dataToUpdate = _.find(this.savedData, { entity: entityName });
            if (dataToUpdate != null) {
                if (entityName == 'ticket' || entityName == 'facture' || entityName == 'preFaForm') {
                    this.updateDataTicketFacture(data, dataToUpdate);
                }

                if (entityName == 'preFaCommande') {
                    this.updateDataPreFacFromCommande(data, dataToUpdate);
                }

                if (entityName == 'saisie') {
                    this.updateDataSaisieCampagne(data, dataToUpdate);
                }

                if (entityName == 'ptmesure') {
                    this.updateDataPointMesure(data, dataToUpdate);
                }

                if (entityName == 'pagination') {
                    this.updateDataPagination(data, dataToUpdate);
                }

                if (entityName == 'synthConsoCli') {
                    this.updateDataSynthConsoCli(data, dataToUpdate);
                }

                if (entityName == 'rapportsPerf') {
                    this.updateDataRapportsPerf(data, dataToUpdate);
                }

                if (entityName == 'produit') {
                    this.updateDataProduit(data, dataToUpdate);
                }
            } else {
                this.savedData.push(data);
            }
        } else {
            this.savedData.push(data);
        }
    }

    getDataShare(entityName) {
        if (_.isEmpty(this.savedData) == false) {
            const item = _.find(this.savedData, { entity: entityName });

            if (item != null) {
                return item;
            }
        }
    }

    updateDataTicketFacture(dataToUse, dataToUpdate) {
        dataToUpdate.dateDebut = dataToUse.dateDebut;
        dataToUpdate.dateFin = dataToUse.dateFin;
        dataToUpdate.siteComIdSelected = dataToUse.siteComIdSelected;
        dataToUpdate.siteComNomSelected = dataToUse.siteComNomSelected;
        dataToUpdate.societeComIdSelected = dataToUse.societeComIdSelected;
        dataToUpdate.societeComNomSelected = dataToUse.societeComNomSelected;
    }

    updateDataPreFacFromCommande(dataToUse, dataToUpdate) {
        dataToUpdate.dateDebut = dataToUse.dateDebut;
        dataToUpdate.dateFin = dataToUse.dateFin;
        dataToUpdate.siteComIdSelected = dataToUse.siteComIdSelected;
        dataToUpdate.siteComNomSelected = dataToUse.siteComNomSelected;
        dataToUpdate.societeComIdSelected = dataToUse.societeComIdSelected;
        dataToUpdate.societeComNomSelected = dataToUse.societeComNomSelected;
    }

    updateDataSaisieCampagne(dataToUse, dataToUpdate) {
        dataToUpdate.dateDebut = dataToUse.dateDebut;
        dataToUpdate.dateFin = dataToUse.dateFin;
        dataToUpdate.siteProdIdSelected = dataToUse.siteProdIdSelected;
        dataToUpdate.siteProdNomSelected = dataToUse.siteProdNomSelected;
        dataToUpdate.themeIdSelected = dataToUse.themeIdSelected;
        dataToUpdate.themeNomSelected = dataToUse.themeNomSelected;
        dataToUpdate.familleIdSelected = dataToUse.familleIdSelected;
        dataToUpdate.familleNomSelected = dataToUse.familleNomSelected;
        dataToUpdate.modeleIdSelected = dataToUse.modeleIdSelected;
        dataToUpdate.modeleNomSelected = dataToUse.modeleNomSelected;
    }

    updateDataPointMesure(dataToUse, dataToUpdate) {
        dataToUpdate.siteProdIdSelected = dataToUse.siteProdIdSelected;
        dataToUpdate.siteProdNomSelected = dataToUse.siteProdNomSelected;
    }

    updateDataPagination(dataToUse, dataToUpdate) {
        dataToUpdate.number = dataToUse.number;
    }

    updateDataSynthConsoCli(dataToUse, dataToUpdate) {
        dataToUpdate.societeComIdSelected = dataToUse.societeComIdSelected;
        dataToUpdate.listeIdSitesCommerciaux = dataToUse.listeIdSitesCommerciaux;
        dataToUpdate.listeIdProduitsEnrobes = dataToUse.listeIdProduitsEnrobes;
        dataToUpdate.typeClientIdSelected = dataToUse.typeClientIdSelected;
        dataToUpdate.dateDebut = dataToUse.dateDebut;
        dataToUpdate.dateFin = dataToUse.dateFin;
    }

    updateDataRapportsPerf(dataToUse, dataToUpdate) {
        dataToUpdate.siteProdIdSelected = dataToUse.siteProdIdSelected;
        dataToUpdate.date = dataToUse.date;
    }

    updateDataProduit(dataToUse, dataToUpdate) {
        dataToUpdate.laboratoireIdSelected = dataToUse.laboratoireIdSelected;
        dataToUpdate.familleIdSelected = dataToUse.familleIdSelected;
        dataToUpdate.familleCodeSelected = dataToUse.familleCodeSelected;
        dataToUpdate.siteProdIdSelected = dataToUse.siteProdIdSelected;
        dataToUpdate.siteLieIdSelected = dataToUse.siteLieIdSelected;
        dataToUpdate.typeIdSelected = dataToUse.typeIdSelected;
    }

    razData(entityName) {
        if (_.isEmpty(this.savedData) == false) {
            const item = _.find(this.savedData, { entity: entityName });
            if (item != null) {
                _.remove(this.savedData, { entity: entityName });
            }
        }
    }

    managePaginationContextuelle(objectToManage) {
        // si le table state et la pagination existent (bon fonctionnement de la smartTable)
        if (objectToManage.tableState && objectToManage.tableState.pagination) {
            // si on est déjà sur la page, ie on change qq chose, pas forcément la pagination
            if (objectToManage.savedPagination.number) {
                // si la valeur courante est différente de la valeur sauvegardée sur la page
                if (objectToManage.tableState.pagination.number != objectToManage.savedPagination.number) {
                    objectToManage.savedPagination.number = objectToManage.tableState.pagination.number;
                    this.setDataShare(objectToManage.savedPagination, 'pagination');
                }
            } else {
                // si on arrive sur la page, ie on vient d'ailleurs...
                // si le dataShareService fonctionne bien
                if (_.isEmpty(this.savedData) == false) {
                    const pag = _.find(this.savedData, { entity: 'pagination' });
                    // si on trouve des données sauvées pour la pagination
                    if (pag != null) {
                        objectToManage.savedPagination.number = pag.number;
                        objectToManage.tableState.pagination.number = pag.number;
                        this.setDataShare(objectToManage.savedPagination, 'pagination');
                        const indexPag = objectToManage.itemsByPageOptions.indexOf(pag.number);
                        objectToManage.itemsByPage = objectToManage.itemsByPageOptions[indexPag];
                    } else {
                        // sinon, on sauvegarde la donnée courante
                        objectToManage.savedPagination.number = objectToManage.tableState.pagination.number;
                        this.setDataShare(objectToManage.savedPagination, 'pagination');
                    }
                } else {
                    objectToManage.savedPagination.number = objectToManage.tableState.pagination.number;
                    this.setDataShare(objectToManage.savedPagination, 'pagination');
                }
            }
        }
        return objectToManage;
    }

    clearAll() {
        this.savedData = [];
    }
}
