// on importe le helper qui nous permet de récupérer les validateurs
import validatorBuilder from 'validator-builder';
import ControlesController from '../../../../../gestion/controles/components/controles/controles.controller';

let produitCaracteristiquesValidator = null;

export default class ProduitCaracteristiques {
    // le constructeur reçoit les données que l'on envoie lors d'un new ProduitCaracteristiques([données])
    constructor(data) {
        // on va chercher le validateur s'il n'existe pas déjà
        produitCaracteristiquesValidator = produitCaracteristiquesValidator || validatorBuilder.getInstanceFor('ProduitCaracteristiques');
        // nettoyage et génération des règles de validation pour le formulaire dynamique
        produitCaracteristiquesValidator.clearRules();
        produitCaracteristiquesValidator.generateRules();

        this.data = {};
        for (const element in data) {
            //console.log(data);
            if (element !== 'data') {
                this[element] = data[element];
                let value = null;
                const valueTab = [];
                const tmpValue = getFormattedValues(data[element]);

                if (Array.isArray(tmpValue)) {
                    for (let i = 0; i < tmpValue.length; i++) {
                        const v = tmpValue[i];
                        valueTab.push(v.value);
                    }
                } else {
                    value = tmpValue;
                }
                this.data[element] = {
                    id: parseInt(element),
                    additionalInformations: { key: 'clé', value: 'valeur' }
                };

                if (valueTab && valueTab.length > 0) {
                    this.data[element].value = valueTab;
                } else {
                    this.data[element].value = value;
                }
            }
        }

        function getFormattedValues(element) {
            // console.log(element);
            if (Array.isArray(element)) {
                const result = _.map(element, function (e) {
                    if (e && e.hasOwnProperty('key')) {
                        return { value: e.key };
                    }
                    return { value: e };
                });
                return result;
            }
            return element;
        }
    }

    // cette methode, en conjonction avec ng-fluent-validation (porté par blocks.validation)
    // permet de passer l'ensemble des règles de validation sur le modèle
    // et retourne l'état de validité de celui-ci
    isValid() {
        const validationResults = produitCaracteristiquesValidator.validate(this);
        return validationResults.isValid;
    }
}
