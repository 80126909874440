import { SiteService } from '../../../../../core/http/site.service';
import { FilterType } from '../../../../../core/interface/FilterProperty';

export default class DonneeFiltreeUtilisateursController {
    /**
     *
     * @param {SiteService} SiteService
     */
    /*@ngInject*/
    constructor(
        $scope,
        $state,
        $stateParams,
        $translate,
        $timeout,
        notification,
        _,
        DonneeFiltreeUtilisateursService,
        MassiaApplicationService,
        MassiaRightsService,
        SiteService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.$timeout = $timeout;
        this.notification = notification;
        this._ = _;
        this.DonneeFiltreeUtilisateursService = DonneeFiltreeUtilisateursService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.ongletOpen = {
            isEnteteOpen: true,
            isDuplicateOpen: false
        };
        this.MassiaRightsService = MassiaRightsService;
        this.SiteService = SiteService;
    }

    // Component
    async $onInit() {
        this.loading = true;
        this.donneeFiltreeUtilisateur = {};
        this.loadFiltreTimeout = null;
        this.inputFiltreSocietes = [];
        this.inputFiltreSites = [];
        this.inputFiltreMetiers = [];
        this.inputFiltreThemes = [];
        this.inputFiltreFamilles = [];
        this.inputDefaultSelectedFamily = [];
        this.inputFiltreUtilisateurs = [];
        this.listeIdsUtilisateur = [];

        this.outputFiltreSocietes = [];
        this.outputFiltreSites = [];
        this.outputFiltreMetiers = [];
        this.outputFiltreThemes = [];
        this.outputFiltreFamilles;
        this.outputDefaultSelectedFamily = null;

        await this.getDonneesFiltreesUtilisateur();
        await this.initiateSociete();
        await this.initiateProducteur();
        this.inputFiltreUtilisateurs = await this.refreshFiltreUtilisateurs();

        await this.initiateSite();

        const application = this.MassiaApplicationService.getApplication();
        if (application == 'qse' || application == 'laboratoire') {
            await this.initiateMetier();
            if (application == 'qse') {
                await this.initiateTheme();
            }
            if (application == 'laboratoire') {
                await this.initiateFamille();
            }
            if (this.MassiaRightsService.userHasRight('prelmobil', 'read', 'laboratoire')) {
                this.models = await this.DonneeFiltreeUtilisateursService.getMobileModele();

                if (this.donneeFiltreeUtilisateur.models && this.donneeFiltreeUtilisateur.models.length > 0) {
                    this.selectModels();
                }
            }
        }
        //angular.copy(this.inputFiltreMetiers));
    }

    $onDestroy() {
        this.$timeout.cancel(this.loadProfilTimeout);
    }

    async initiateProducteur() {
        try {
            const res = await this.SiteService.getAll([
                {
                    criterion: 'genre',
                    type: FilterType.EQUALS,
                    value: 4
                }
            ]);
            this.producteurs = res.items;
        } catch (err) {
            this.notification.error(err);
        }
    }

    async valider() {
        await this.sauvegarder();
        if (this.listeIdsUtilisateur && this.listeIdsUtilisateur.length > 0) {
            await this.duplicate();
        }
        this.backToPreviousState();
    }

    annuler() {
        this.backToPreviousState();
    }

    async getDonneesFiltreesUtilisateur() {
        this.startLoading();
        const data = {
            id: this.$stateParams.id
        };

        if (data.id) {
            try {
                this.donneeFiltreeUtilisateur = await this.DonneeFiltreeUtilisateursService.getDonneeFiltreeUtilisateur(
                    data.id,
                    this.MassiaApplicationService.getApplication()
                );
            } catch (ex) {
                this.notification.error(ex.data);
            }
        }
        this.stopLoading();
    }

    async initiateSociete() {
        this.startLoading();
        this.inputFiltreSocietes = await this.refreshFiltreSocietes();
        await this.preselectFiltreSocietes();
        this.stopLoading();
    }

    async initiateSite() {
        this.inputFiltreSites = await this.refreshFiltreSites();
        await this.preselectFiltreSites();
    }

    async initiateMetier() {
        this.inputFiltreMetiers = await this.refreshFiltreMetiers();
        //console.log(angular.copy(this.inputFiltreMetiers));
        await this.preselectFiltreMetiers();
    }

    async initiateTheme() {
        this.inputFiltreThemes = await this.refreshFiltreThemes();
        await this.preselectFiltreThemes();
    }

    async initiateFamille() {
        this.inputFiltreFamilles = await this.refreshFiltreFamilles();
        await this.preselectFiltreFamilles();
    }

    async preselectFiltreSocietes() {
        if (this.donneeFiltreeUtilisateur.idFiltreSocietes) {
            for (let index = 0; index < this.donneeFiltreeUtilisateur.idFiltreSocietes.length; index++) {
                const element = this.donneeFiltreeUtilisateur.idFiltreSocietes[index];
                const filtre = this._.find(this.inputFiltreSocietes, { id: element.idSociete });
                if (filtre) {
                    filtre.selected = true;
                }
            }
        }
    }

    async preselectFiltreSites() {
        if (this.donneeFiltreeUtilisateur.idFiltreSites) {
            for (let index = 0; index < this.donneeFiltreeUtilisateur.idFiltreSites.length; index++) {
                const element = this.donneeFiltreeUtilisateur.idFiltreSites[index];
                const filtre = this._.find(this.inputFiltreSites, { id: element.idSite });
                if (filtre) {
                    filtre.selected = true;
                }
            }
        }
    }

    async preselectFiltreMetiers() {
        //console.log(angular.copy(this.donneeFiltreeUtilisateur));
        if (this.donneeFiltreeUtilisateur.idMetiers) {
            for (let index = 0; index < this.donneeFiltreeUtilisateur.idMetiers.length; index++) {
                const element = this.donneeFiltreeUtilisateur.idMetiers[index];
                const filtre = this._.find(this.inputFiltreMetiers, { id: element });
                if (filtre) {
                    filtre.selected = true;
                }
            }
        }
    }

    async preselectFiltreThemes() {
        if (this.donneeFiltreeUtilisateur.idThemes) {
            for (let index = 0; index < this.donneeFiltreeUtilisateur.idThemes.length; index++) {
                const element = this.donneeFiltreeUtilisateur.idThemes[index];
                const filtre = this._.find(this.inputFiltreThemes, { id: element });
                if (filtre) {
                    filtre.selected = true;
                }
            }
        }
    }

    async preselectFiltreFamilles() {
        if (this.donneeFiltreeUtilisateur.idFamilles) {
            const defaultSelectedFamilyList = [];
            for (let index = 0; index < this.donneeFiltreeUtilisateur.idFamilles.length; index++) {
                const element = this.donneeFiltreeUtilisateur.idFamilles[index];
                const filtre = this._.find(this.inputFiltreFamilles, { idFamille: element });
                
                if (filtre) {
                    defaultSelectedFamilyList.push(angular.copy(filtre))
                    filtre.selected = true;
                }
            }

            if (this.donneeFiltreeUtilisateur.idDefaultFamily > 0) {
                const defaultfamily = defaultSelectedFamilyList.find(family => family.idFamille === this.donneeFiltreeUtilisateur.idDefaultFamily)
                defaultfamily.selected =true
            }
            this.inputDefaultSelectedFamily = defaultSelectedFamilyList;
        }
    }

    async saveSelectedSocietes() {
        this.donneeFiltreeUtilisateur.idFiltreSocietes = [];
        for (let i = 0; i < this.outputFiltreSocietes.length; i++) {
            const societeCom = this.outputFiltreSocietes[i];
            this.donneeFiltreeUtilisateur.idFiltreSocietes.push({ idSite: 0, idSociete: societeCom.id });
        }
        // load Sites
        await this.initiateSite();
    }

    async saveSelectedSites() {
        this.donneeFiltreeUtilisateur.idFiltreSites = [];
        for (let i = 0; i < this.inputFiltreSites.length; i++) {
            const siteCom = this.inputFiltreSites[i];
            if (siteCom.selected) {
                if (siteCom.idSociete && siteCom.idSociete > 0) {
                    this.donneeFiltreeUtilisateur.idFiltreSites.push({ idSite: siteCom.id, idSociete: siteCom.idSociete });
                } else {
                    delete siteCom.selected;
                }
            }
        }
    }

    async saveSelectedMetiers() {
        /*  this.donneeFiltreeUtilisateur.idMetiers = [];
        for (let i = 0; i < this.outputFiltreMetiers.length; i++) {
            const metier = this.outputFiltreMetiers[i];
            this.donneeFiltreeUtilisateur.idMetiers.push(metier.id);
        } */
        // console.log(angular.copy(this.outputFiltreMetiers));
        this.donneeFiltreeUtilisateur.idMetiers = this.outputFiltreMetiers;

        // load Themes
        await this.initiateTheme();
    }

    async saveSelectedThemes() {
        this.donneeFiltreeUtilisateur.idThemes = [];
        for (let i = 0; i < this.outputFiltreThemes.length; i++) {
            const theme = this.outputFiltreThemes[i];
            this.donneeFiltreeUtilisateur.idThemes.push(theme.id);
        }
    }

    async saveSelectedFamilles() {
        /*  this.donneeFiltreeUtilisateur.idFamilles = [];
        for (var i = 0; i < this.outputFiltreFamilles.length; i++) {
            let famille = this.outputFiltreFamilles[i];
            this.donneeFiltreeUtilisateur.idFamilles.push(famille.idFamille);
        } */
        this.saveDefaultSelectedFamily()
        // console.log(angular.copy(this.outputFiltreFamilles));
        this.donneeFiltreeUtilisateur.idFamilles = this.outputFiltreFamilles;        
    }

    async saveSelectedFiltreUtilisateurs() {
        this.outputFiltreUtilisateurs.forEach((outputFiltreUtilisateur) => {
            this.listeIdsUtilisateur.push(outputFiltreUtilisateur);
        });
    }

    async refreshFiltreSocietes() {
        let data;
        try {
            data = await this.DonneeFiltreeUtilisateursService.getFiltreSocietes(this.MassiaApplicationService.getApplication());
        } catch (ex) {
            data = [];
        }
        return data;
    }

    async refreshFiltreSites() {
        let data;
        try {
            const listSocieteIds = [];
            for (let index = 0; index < this.donneeFiltreeUtilisateur.idFiltreSocietes.length; index++) {
                const element = this.donneeFiltreeUtilisateur.idFiltreSocietes[index];
                listSocieteIds.push(element.idSociete);
            }
            data = await this.DonneeFiltreeUtilisateursService.getFiltreSites(listSocieteIds, this.MassiaApplicationService.getApplication());
        } catch (ex) {
            data = [];
        }
        return data;
    }

    async refreshFiltreMetiers() {
        let data;
        try {
            data = await this.DonneeFiltreeUtilisateursService.getFiltreMetiers(this.MassiaApplicationService.getApplication());
            // console.log(data);
        } catch (ex) {
            data = [];
        }
        return data;
    }

    async refreshFiltreThemes() {
        let data;
        try {
            const listThemesIds = [];
            for (let index = 0; index < this.donneeFiltreeUtilisateur.idThemess.length; index++) {
                const element = this.donneeFiltreeUtilisateur.idThemess[index];
                //console.log(element);
                listThemesIds.push(element);
            }
            data = await this.DonneeFiltreeUtilisateursService.getFiltreThemes(listThemesIds, this.MassiaApplicationService.getApplication());
        } catch (ex) {
            data = [];
        }
        return data;
    }

    async refreshFiltreFamilles() {
        let data;
        try {
            const lisFamillesIds = [];
            for (let index = 0; index < this.donneeFiltreeUtilisateur.idFamilles.length; index++) {
                const element = this.donneeFiltreeUtilisateur.idFamilles[index];
                lisFamillesIds.push(element);
            }
            data = await this.DonneeFiltreeUtilisateursService.getFiltreFamilles(lisFamillesIds, this.MassiaApplicationService.getApplication());
        } catch (ex) {
            data = [];
        }
        return data;
    }

    async refreshFiltreUtilisateurs() {
        let data;
        try {
            data = await this.DonneeFiltreeUtilisateursService.getFiltreUtilisateurs();
        } catch (ex) {
            data = [];
        }
        return data;
    }

    async sauvegarder() {
        this.startLoading();
        try {
            this.saveInputSites();
            //console.log(angular.copy(this.donneeFiltreeUtilisateur));
            this.donneeFiltreeUtilisateur.models = this.selectedModels;
            await this.DonneeFiltreeUtilisateursService.updateFiltreUtilisateur(
                this.donneeFiltreeUtilisateur,
                this.MassiaApplicationService.getApplication()
            );
            this.notification.success('FILTRES.UPDATED');
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        } finally {
            this.stopLoading();
        }
    }

    async saveInputSites() {
        this.donneeFiltreeUtilisateur.idFiltreSites = [];
        for (let i = 0; i < this.inputFiltreSites.length; i++) {
            const siteCom = this.inputFiltreSites[i];
            if (siteCom.selected) {
                this.donneeFiltreeUtilisateur.idFiltreSites.push({ idSite: siteCom.id, idSociete: siteCom.idSociete });
            }
        }
    }

    async duplicate() {
        this.startLoading();
        try {
            await this.DonneeFiltreeUtilisateursService.duplicateFiltreUtilisateur(
                this.listeIdsUtilisateur,
                this.donneeFiltreeUtilisateur,
                this.MassiaApplicationService.getApplication()
            );
            this.notification.success('FILTRES.DUPLICATE');
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    backToPreviousState() {
        this.$state.go('utilisateurs.edit', { id: this.$stateParams.id, genre: 'utilisateur' });
    }

    selectModels() {
        // console.log('select');
        for (let i = 0; i < this.donneeFiltreeUtilisateur.models.length; i++) {
            const id = this.donneeFiltreeUtilisateur.models[i].id;
            const index = this.models.findIndex((x) => x.id == id);
            if (index > -1) {
                this.models[index].selected = true;
            }
        }
    }

    selectDefaultSelectedFamily() {
		if (this.outputDefaultSelectedFamily.length > 0) {            
            this.donneeFiltreeUtilisateur.idDefaultFamily = this.outputDefaultSelectedFamily[0].idFamille;
        } else {
            this.donneeFiltreeUtilisateur.idDefaultFamily = 0;
        }
    }

    saveDefaultSelectedFamily() {
        const filterFamilyCopy = _.cloneDeep(this.inputFiltreFamilles);

        if (this.outputFiltreFamilles.length === 0) {
            this.inputDefaultSelectedFamily = [];
        }

        if (this.outputFiltreFamilles.length === filterFamilyCopy.length) {
            const  alreadySelectedFamily = this.inputDefaultSelectedFamily.find(family => family.selected === true)
            filterFamilyCopy.forEach(family => family.selected = false);
            if (alreadySelectedFamily) {                
                this.inputDefaultSelectedFamily = [...filterFamilyCopy, alreadySelectedFamily];
            } else {
                this.inputDefaultSelectedFamily = filterFamilyCopy;
            }

        }     

        const idElementJustAdded =  _.difference(this.outputFiltreFamilles, this.donneeFiltreeUtilisateur.idFamilles);

        if (idElementJustAdded > 0) {
            const familyJustAdded = filterFamilyCopy.find(family => family.idFamille === idElementJustAdded[0]);
            familyJustAdded.selected = false;
            this.inputDefaultSelectedFamily = [...this.inputDefaultSelectedFamily, familyJustAdded];      
        }

        const idElementJustDeleted =  _.difference(this.donneeFiltreeUtilisateur.idFamilles, this.outputFiltreFamilles);

        if (idElementJustDeleted.length > 0) {
            const familyJustDeleted= filterFamilyCopy.find(family => family.idFamille === idElementJustDeleted[0]);
            if (familyJustDeleted) {                
                const newFamilyList = this.inputDefaultSelectedFamily.filter(family => family.idFamille != familyJustDeleted.idFamille);
                this.inputDefaultSelectedFamily = [...newFamilyList];
            }
        }
    }
}




