import DispatchingConfig from './_config/dispatching.config';
import DispatchingRoutes from './_config/dispatching.routes';

import DispatchingComponent from './components/dispatching';
import PlanningModalComponent from './components/planning-modal';

import DispatchingService from './services/dispatching.service';
import DispatchingCommunicationService from './services/dispatching.communication.service';

const moduleName = 'app.massia.gestion.plannings.dispatching';

angular
    .module(moduleName, [])
    .config(DispatchingConfig)
    .config(DispatchingRoutes)
    .service('DispatchingService', DispatchingService)
    .service('DispatchingCommunicationService', DispatchingCommunicationService)
    .component('dispatching', DispatchingComponent)
    .component('planningModal', PlanningModalComponent);

export default moduleName;
