const serviceUrl = `${__configuration.apiUrl}/massia/prestations`;

export default class TransportsService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getTransports(filters, sorts, pagination) {
		const url = `${__configuration.apiUrl}/massia/transports`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async deleteTransportById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async getTransportEnteteById(id) {
		const url = `${serviceUrl}/${id}/entete`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async createTransport(entete) {
		const res = await this.$http.post(serviceUrl, entete);

		return res.data;
	}

	async updateEnteteTransport(entete) {
		const url = `${serviceUrl}/${entete.id}`;
		const res = this.$http.put(url, entete);

		return res.data;
	}

	async codeExists(code) {
		const url = `${serviceUrl}/code-unicity/${code}`;
		const result = await this.$http.get(url);
		return result.data;
	}

	async getUnites() {
		const uniteUrl = `${__configuration.apiUrl}/massia/unites`;
		const res = await this.$http.get(uniteUrl);

		return res.data;
	}
	async getGrillesZonesKm() {
		const uniteUrl = `${__configuration.apiUrl}/massia/GrillesZonesKm`;
		const res = await this.$http.get(uniteUrl);

		return res.data;
	}
}
