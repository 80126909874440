export default class SyntheseEntityService {
	/* @ngInject */
	constructor(ApiService, $http) {
		/**
		 * @type ApiService
		 */
		this._api = ApiService;
		this._isSyntheseLoaded = false;
		this.$http = $http;
	}

	getSyntheseLoaded() {
		return this._isSyntheseLoaded;
	}

	setSyntheseLoaded(loadStatus) {
		this._isSyntheseLoaded = loadStatus;
	}

	getAll(type = null, filters = [], sorts = [], pagination = {}) {
		return this._api.get('massia/synthese/entity', {
			params: {
				typeSynthese: type,
				filters: JSON.stringify(filters),
				sorts: JSON.stringify(sorts),
				pagination: JSON.stringify(pagination)
			}
		});
	}

	getOne(id, isUser = false) {
		return this._api.get(`massia/synthese/entity/${id}`, {
			params: {
				user: !isUser
			}
		});
	}

	updateOne(id, synthese, userSetting = false) {
		return this._api.put(`massia/synthese/entity/${id}`, synthese, {
			params: {
				user: userSetting
			}
		});
	}

	create(synthese) {
		return this._api.post('massia/synthese/entity', synthese);
	}

	delete(synthese) {
		return this._api.delete(`massia/synthese/entity/${synthese.id}`);
	}

	generate(id, synthese, type, excluded = [], criterions, user = false) {
		return this._api.post(
			`massia/synthese/entity/${id || 0}/generate`,
			{
				synthese: synthese,
				criterions: criterions
			},
			{
				params: {
					user: user,
					type: type,
					excluded: excluded.join(',')
				}
			}
		);
	}

	print(id, synthese, type, excluded = [], criterions, filename, user = false) {
		return this._api.post(
			`massia/synthese/entity/${id || 0}/generate/print`,
			{
				synthese: synthese,
				criterions: criterions,
				filename: filename
			},
			{
				params: {
					user: user,
					type: type,
					excluded: excluded.join(',')
				}
			}
		);
	}

	async excelExport(result, footer, synthese, isPdf, isPaysage) {
		const grid = {
			header: result.header.map((x) => {
				return {
					group: x.group,
					name: x.displayName
				};
			}),
			infos: synthese,
			footer: footer,
			isPdf: isPdf,
			isPaysage: isPaysage,
			body: result.body.map((x) => {
				const values = Object.values(x.columns).map((c) => (c.value != null ? c.value.toString() : ''));
				return values;
			})
		};

		const url = `${__configuration.apiUrl}/massia/synthese/entity/0/generate/excel/synthesesprint`;
		const res = await this.$http.post(url, grid, {
			responseType: 'arraybuffer'
		});
		return res;
	}
}
