const serviceUrl = `${__configuration.apiUrl}/massia/gestion/tracteurs`;

export default class TracteursService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getTracteurs(isEnabled, filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				isEnabled: JSON.stringify(isEnabled),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async deleteTracteurById(id) {
		const url = `${serviceUrl}/${id}`;
		const res = this.$http.delete(url);
		return res;
	}

	async activateTracteur(id) {
		const url = `${serviceUrl}/activate/${id}`;
		return this.$http.put(url);
	}

	async createTracteur(tracteur) {
		const result = await this.$http.post(serviceUrl, tracteur);
		return result.data;
	}

	async updateTracteur(tracteur) {
		const url = `${serviceUrl}/${tracteur.id}`;
		return await this.$http.put(url, tracteur);
	}
}
