ZoneAbattageValidator.$inject = ['validator'];

export default function ZoneAbattageValidator(validator) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 15).withMessage('VALIDATION_TOO_LONG_15');
    instance
        .ruleFor('code')
        .matches(/^[a-zA-Z0-9_|]*$/)
        .withMessage('CODE_CHARACTERS_NOK');
    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').lengthBetweenValues(0, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance.ruleFor('idSite').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('codeExists').notEqual(true).withMessage('VALIDATION_NOTEMPTY');
    return instance;
}
