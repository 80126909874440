import BlocSelectionEssaiController from './bloc.selection.essai.controller';

export default {
    bindings: {
        essai: '=',
        canUpdate: '='
    },
    template: require('./bloc.selection.essai.tpl.html'),
    controller: BlocSelectionEssaiController
};
