/* @ngInject */
export function uiGridOverride($parse: ng.IParseService, Grid: any) {
    Grid.prototype.getCellValue = function getCellValue(row: any, col: any) {
        if (typeof row.entity['$$' + col.uid] !== 'undefined') {
            return row.entity['$$' + col.uid].rendered;
        } else if (this.options.flatEntityAccess && typeof col.field !== 'undefined') {
            let entity = row.entity;
            let field: string = col.field;
            if (field.includes('columns.') && field.includes('.value')) {
                const cleanedField = field.replace('columns.', '').replace('.value', '');
                if (!entity.columns) {
                    return;
                }
                return entity.columns[cleanedField]?.value;
            }
            return entity[field];
        } else {
            if (!col.cellValueGetterCache) {
                col.cellValueGetterCache = $parse(row.getEntityQualifiedColField(col));
            }

            return col.cellValueGetterCache(row);
        }
    };
}
