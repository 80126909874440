import LieuFormEnteteController from './lieu.form.entete.controller';

export default {
    bindings: {
        lieu: '<',
        form: '=',
        onUpdate: '&'
    },
    template: require('./lieu.form.entete.html'),
    controller: LieuFormEnteteController
};
