DistancierController.$inject = ['$scope', '$state', '$stateParams', '$translate', 'ModalService', 'DistanciersService', 'notification', '_'];

export default function DistancierController($scope, $state, $stateParams, $translate, ModalService, DistanciersService, notification, _) {
    const vm = this;
    const watchers = [];

    let previousTableState;

    vm.loading = false;
    vm.stateParams = $stateParams;
    vm.distancier = {};
    vm.isChantier = false;
    vm.selectedFiltreLieuxDeparts = [];
    vm.selectedFiltreLieuxArrivees = [];
    vm.ongletOpen = {
        isFiltreOpen: true,
        isDistancierOpen: false
    };
    vm.isDisabled = true;
    vm.isKm = true;
    vm.isZone = true;

    vm.loadDistancier = loadDistancier;
    vm.getZones = getZones;
    vm.confirmer = confirmer;
    vm.filtrer = filtrer;
    vm.changeType = changeType;

    vm.getLieuxDeparts = getLieuxDeparts;
    vm.addLieuDepart = addLieuDepart;
    vm.deleteLieuDepart = deleteLieuDepart;
    vm.getLieuxArrivees = getLieuxArrivees;
    vm.addLieuArrivee = addLieuArrivee;
    vm.deleteLieuArrivee = deleteLieuArrivee;
    vm.exporter = exporter;

    async function loadDistancier() {
        startLoading();

        vm.ongletOpen = {
            isFiltreOpen: true,
            isDistancierOpen: false
        };

        if ($state && $state.current && $state.current.name !== 'distanciers.list') {
            $state.go('distanciers.list');
        }

        vm.distancier = {
            lignes: []
        };

        try {
            getZones();
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function getZones() {
        startLoading();
        try {
            vm.zones = await DistanciersService.getZones();
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function changeType() {
        vm.selectedFiltreLieuxArrivees = [];
    }

    async function filtrer() {
        if (
            (vm.selectedFiltreLieuxDeparts.length > 0 && vm.selectedFiltreLieuxArrivees.length > 0) ||
            (vm.selectedFiltreLieuxDeparts.length == 1 && vm.selectedFiltreLieuxArrivees.length == 0)
        ) {
            // SL pour éviter le seuil du nb de caractère dans l'url d'un get, on envoie la liste des Id plutôt que la liste des objets
            const listIdsLieuDepart = _.map(vm.selectedFiltreLieuxDeparts, 'id');
            const listIdsLieuArrivee = _.map(vm.selectedFiltreLieuxArrivees, 'id');

            try {
                vm.distancier = await DistanciersService.getDistancier(vm.isChantier, listIdsLieuDepart, listIdsLieuArrivee);
            } catch (error) {
                vm.notification.error(error.data);
            }

            vm.ongletOpen = {
                isFiltreOpen: false,
                isDistancierOpen: true
            };

            if (vm.distancier.lignes.length > 0) {
                vm.isDisabled = false;
            }
        } else {
            if (vm.selectedFiltreLieuxArrivees.length == 0) {
                notification.error('DISTANCIERS.ERROR_DEPART');
            } else {
                notification.error('DISTANCIERS.ERROR');
            }
        }
    }

    async function confirmer() {
        await DistanciersService.setDistancier(vm.distancier);
        await this.filtrer();
        notification.success('DISTANCIERS.CREATED');
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }

    async function getLieuxDeparts(valeur) {
        try {
            const res = await DistanciersService.getLieuxBySiteCommercial(valeur);

            await filterTypeAheadResult(vm.selectedFiltreLieuxDeparts, res);

            return res;
        } catch (ex) {
            vm.notification.error(ex.data);
        }
    }

    async function getLieuxArrivees(valeur) {
        try {
            if (vm.isChantier) {
                var res = await DistanciersService.getChantiers(valeur);
            } else {
                var res = await DistanciersService.getLieux(valeur);
            }

            await filterTypeAheadResult(vm.selectedFiltreLieuxArrivees, res);

            return res;
        } catch (ex) {
            vm.notification.error(ex.data);
        }
    }

    // SL pour filtrer la liste de résultat et ne pas resélectionner un élément déjà dans la liste
    function filterTypeAheadResult(inputList, resultat) {
        for (let iter = 0; iter < inputList.length; iter++) {
            const element = inputList[iter];
            const indexIn = _.findIndex(resultat, { id: element.id });
            if (indexIn >= 0) {
                _.remove(resultat, (item) => item.id == element.id);
            }
        }
    }

    async function addLieuDepart() {
        try {
            if (vm.selectLieuDepart && vm.selectLieuDepart.id) {
                vm.selectedFiltreLieuxDeparts.push(vm.selectLieuDepart);
                vm.selectLieuDepart = null;
            }
        } catch (err) {
            if (err.data) {
                vm.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async function deleteLieuDepart(idLieuDepart) {
        try {
            _.remove(vm.selectedFiltreLieuxDeparts, { id: idLieuDepart });
        } catch (err) {
            if (err.data) {
                vm.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async function addLieuArrivee() {
        try {
            if (vm.selectLieuArrivee && vm.selectLieuArrivee.id) {
                vm.selectedFiltreLieuxArrivees.push(vm.selectLieuArrivee);
                vm.selectLieuArrivee = null;
            }
        } catch (err) {
            if (err.data) {
                vm.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async function deleteLieuArrivee(idLieuArrivee) {
        try {
            _.remove(vm.selectedFiltreLieuxArrivees, { id: idLieuArrivee });
        } catch (err) {
            if (err.data) {
                vm.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async function exporter() {
        try {
            const fileName = 'Liste_distanciers';
            let resultat = undefined;
            const modeImpression = 1;
            try {
                resultat = await DistanciersService.exportListeDistanciers(
                    vm.isChantier,
                    vm.selectedFiltreLieuxDeparts,
                    vm.selectedFiltreLieuxArrivees
                );
            } catch (ex) {
                //si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
                const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
                notification.error('DISTANCIERS.' + msgException);
                return false;
            }
            if (resultat) {
                const data = resultat.data;
                const status = resultat.status;
                let headers = resultat.headers;
                headers = headers();

                const contentType = headers['content-type'];

                const linkElement = document.createElement('a');
                try {
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    if (modeImpression == 0) {
                        linkElement.setAttribute('download', fileName + '.pdf');
                    } else {
                        linkElement.setAttribute('download', fileName + '.xlsx');
                    }

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                } catch (ex) {
                } finally {
                }
                return true;
            }
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        }
    }
}
