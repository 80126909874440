import SiteObjectif from './site.objectif.model';

export default class SiteObjectifController {
    static $inject = ['$stateParams', 'SitesCommunicationService', 'SitesService', 'SitesTypesService', 'MassiaApplicationService', 'notification'];

    constructor($stateParams, SitesCommunicationService, SitesService, SitesTypesService, MassiaApplicationService, notification) {
        this.$stateParams = $stateParams;
        this.SitesCommunicationService = SitesCommunicationService;
        this.SitesService = SitesService;
        this.SitesTypesService = SitesTypesService;
        this.notification = notification;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    async $onInit() {
        this.loading = false;
        this.codeLoading = false;
        this.isEditMode = false;
        //this.unregisterReinit = this.SitesCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        //this.unregisterReinit();
    }

    initForm() {}

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
