import validatorBuilder from 'validator-builder';

let motifValidator = null;

export default class Motif {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
    }

    isValid() {
        motifValidator = motifValidator || validatorBuilder.getInstanceFor('Motif');
        const validationResults = motifValidator.validate(this);
        return validationResults.isValid;
    }
}
