import ClientsDiversConfig from './_config/clients.divers.config';
import ClientsDiversRoutes from './_config/clients.divers.routes';
import ClientsDiversComponent from './components/clients-divers';
import ClientsDiversService from './services/clients.divers.service';
import ClientsDiversCommunicationService from './services/clients.divers.communication.service';

import ClientDiversFormComponent from './components/client-divers-form';
import ClientDiversDetailComponent from './components/client-divers-detail';
import ClientDiversEnteteComponent from './components/client-divers-entete';
import ClientDiversModalComponent from './components/client-divers-modal';

import ClientDiversValidator from './components/client-divers-form/client.divers.validator';
import ClientDiversEnteteValidator from './components/client-divers-entete/client.divers.entete.validator';

const moduleName = 'app.parametrageGeneral.clients.divers';

angular
    .module(moduleName, [])
    .config(ClientsDiversConfig)
    .config(ClientsDiversRoutes)
    .service('ClientsDiversService', ClientsDiversService)
    .service('ClientsDiversCommunicationService', ClientsDiversCommunicationService)
    .component('clientsDivers', ClientsDiversComponent)
    .component('clientDiversForm', ClientDiversFormComponent)
    .component('clientDiversDetail', ClientDiversDetailComponent)
    .component('clientDiversEntete', ClientDiversEnteteComponent)
    .component('clientDiversModal', ClientDiversModalComponent)
    .factory('ClientDiversValidator', ClientDiversValidator)
    .factory('ClientDiversEnteteValidator', ClientDiversEnteteValidator);

export default moduleName;
