import EtatCompte from './site.etat.compte.model';

export default class EtatCompteController {
    static $inject = [
        '$state',
        '$stateParams',
        'SitesService',
        'SitesTypesService',
        'ModalService',
        'notification',
        '$translate',
        'MassiaApplicationService'
    ];

    constructor($state, $stateParams, SitesService, SitesTypesService, ModalService, notification, $translate, MassiaApplicationService) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SitesService = SitesService;
        this.SitesTypesService = SitesTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        this.site = this.site || {};
    }
    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
