export default class Synthese {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.typeSynthese = data.typeSynthese;
        this.idDomaine = data.idDomaine;
        this.blocSelections = data.blocSelections || [];
        this.controle = data.controle || {
            reference: {},
            norme: {}
        };
        this.convert = data.convert;
        this.setting = data.setting;
        this.ruptures = data.ruptures || [];
        this.interval = data.interval;
        this.periode = data.periode;
        this.essais = data.essais || [];
        this.critereAffichages = data.critereAffichages || [];
        this.traitements = data.traitements || [];
        this.blocklist = data.blocklist || [];
        this.isduplicate = false;
    }
}
