PaiementsController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'PaiementsService',
	'PaiementsTypesService',
	'notification',
	'$transitions',
	'MOPService'
];

export default function PaiementsController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	PaiementsService,
	PaiementsTypesService,
	notification,
	$transitions,
	MOPService
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	let selectedPaiementId = undefined;
	vm.lastSelected = undefined;

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.paiements = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};

	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectPaiement = selectPaiement;
	vm.isPaiementSelected = isPaiementSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadPaiements = loadPaiements;
	vm.deletePaiement = deletePaiement;
	vm.setSelected = setSelected;
	vm.eraseAllFilters = eraseAllFilters;
	vm.MOPService = MOPService;

	function init() {
		vm.MOPService.setMop([{ title: 'PAIEMENTS.BREADCRUMBS.PAIEMENTS_LIST', filename: 'ModesPaiement.pdf', application: 'gestion' }]);
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "Paiements.list", on désélectionne la ligne sélectionnée (s'il y en a)
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		//quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	init();

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(paiement) {
		if (paiement && paiement.id) {
			vm.selectedPaiementId = $stateParams.id;
			vm.lastSelected = vm.selectedPaiementId;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			vm.selectedPaiementId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state && $state.current && $state.current.name && $state.current.name === 'paiements.list.detail' && $stateParams && $stateParams.id;
	}

	function selectPaiement(paiement) {
		if (paiement && paiement.id) {
			//si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedPaiementId, et charger la vue détail pour le Paiement sélectionné
			if (vm.selectedPaiementId !== paiement.id) {
				vm.selectedPaiementId = paiement.id;
				showDetail(paiement.id);
			} else {
				//si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
				vm.selectedPaiementId = undefined;
				closeDetail();
			}
		}
	}

	function isPaiementSelected(paiement) {
		return paiement && paiement.id && selectedPaiementId === paiement.id;
	}

	function showDetail(paiementId) {
		$state.go('paiements.list.detail', { id: paiementId });
	}

	function closeDetail() {
		selectedPaiementId = undefined;
		$state.go('paiements.list');
	}

	async function loadPaiements(tableState) {
		startLoading();

		//quand on rafraîchit la grille, on revient sur "Paiements.list", et on ferme la vue détail d'un Paiement
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== 'paiements.list') {
			$state.go('paiements.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		const type = [];
		try {
			const tmpTypes = await PaiementsTypesService.getPaiementTypes();
			for (var i = 0; i < tmpTypes.length; i++) {
				type[tmpTypes[i].id] = tmpTypes[i].libelle;
			}
		} catch (e) {
			throw e;
		}

		vm.paiements = [];

		try {
			const data = await PaiementsService.getPaiements(filters, sorts, pagination);
			const items = data.items;
			for (var i = 0; i < items.length; i++) {
				items[i].libelleType = type[items[i].type];
			}
			vm.paiements = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deletePaiement(paiement) {
		if (paiement && paiement.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('PAIEMENTS.DELETE.TITLE', { code: paiement.libelle }),
				modalMsg: $translate.instant('PAIEMENTS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await PaiementsService.deletePaiementById(paiement.id);
					notification.success($translate.instant('PAIEMENTS.DELETE.SUCCESS'));

					vm.selectedPaiementId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une redirection vers la vue de la grille, afin de fermer la vue détail
					if (vm.params.id) {
						vm.state.go('paiements.list');
					}
					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);
					loadPaiements();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function eraseAllFilters() {
		loadPaiements();
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
