import Facture from './facture.model';
import FactureEntete from './entete/facture.entete.model';
// import FactureArticle from './articles-chantier/articles.model';
import ArticleBlock from './articles-block/articles.block.model';

export default class FactureFormController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'FacturesCommunicationService',
		'FacturesService',
		'notification',
		'$location',
		'$anchorScroll',
		'$uibModal',
		'ModalService',
		'_',
		'$translate',
		'RouterHistoryService',
		'MOPService'
	];

	constructor(
		$scope,
		$state,
		$stateParams,
		FacturesCommunicationService,
		FacturesService,
		notification,
		$location,
		$anchorScroll,
		$uibModal,
		ModalService,
		_,
		$translate,
		RouterHistoryService,
		MOPService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.params = $stateParams;
		this.FacturesCommunicationService = FacturesCommunicationService;
		this.FacturesService = FacturesService;
		this.notification = notification;
		this.isEditMode = false;
		this.loading = false;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.$uibModal = $uibModal;
		this.ModalService = ModalService;
		this._ = _;
		this.$translate = $translate;
		this.RouterHistoryService = RouterHistoryService;
		this.closeAllOnglets();
		this.isDuplicate = false;
		this.MOPService = MOPService;
	}

	async $onInit() {
		this.MOPService.setMop([{ title: 'FACTURES.BREADCRUMBS.FACTURES_LIST', filename: 'Facturation.pdf', application: 'gestion' }]);
		this.openFirstOnglet();
		this.ongletOpen = {
			isEnteteOpen: true,
			isRecapitulatifOpen: false,
			isArticlesParChantierOpen: false,
			isTicketsOpen: false
		};

		await this.initFacture();
		if (this.isEditMode == true) {
			await this.initComboBoxes(this.facture.entete.natureFacture);
		}

		if (this.isEditMode && this.facture.entete.idEtatFacture != 0 && this.facture.entete.idEtatFacture != 4) {
			this.backToPreviousState();
		}
	}

	$onDestroy() {
		this.MOPService.resetMop();
	}

	async initComboBoxes(pNatureFacture) {
		this.sitesComForComboBox = await this.FacturesService.getSitesCom(this.facture.entete.idSociete);
		this.chantiersForComboBox = await this.FacturesService.getChantiers(this.facture.entete.idClient);

		// add the O/Auncun element pour les Chantiers
		const zeroElement = {};
		zeroElement.id = 0;
		zeroElement.libelle = 'Aucun';
		this.chantiersForComboBox.unshift(zeroElement);
	}

	async initFacture() {
		if (this.params && this.params.id) {
			this.isEditMode = true;
			this.facture = {};

			if (this.params.isDuplicate) {
				this.isDuplicate = true;
			}

			this.startLoading();

			try {
				this.facture = {
					id: this.params.id
				};
				this.facture.entete = await this.FacturesService.getFactureById(this.facture.id);
				this.facture = new Facture(this.facture);
			} catch (ex) {
				if (ex.status === 404) {
					this.goListFacture();
				}
				this.notification.error(ex.data);
			} finally {
				this.getDomaine();
				this.stopLoading();
			}
		} else {
			this.isEditMode = false;
			this.facture = {};
			this.facture = new Facture(this.facture);
		}
	}

	getDomaine() {
		switch (this.facture.entete.idTypeFacture) {
			case 0:
			case 2:
			case 5:
			case 6:
				this.domaine = 'factures';
				break;
			case 4:
			case 9:
				this.domaine = 'avoirmanuel';
				break;
			case 3:
			case 8:
				this.domaine = 'avoirauto';
				break;
			case 1:
				this.domaine = 'facturemanuel';
				break;
		}
	}

	// boutons de validation
	async sauvegarder() {
		if (this.facture.entete.isClientGenerique && this.facture.entete.idClientDivers == null) {
			this.notification.error('Le client divers est obligatoire.');
		} else {
			if (this.checkValidity()) {
				let idReturn = 0;
				if (this.facture.entete.idSiteCommercial) {
					idReturn = await this.save();
					if (idReturn) {
						this.$state.go('factures.edit', { id: idReturn });
					}
				} else {
					if (!this.isEditMode) {
						const modalInstance = this.ModalService.confirm({
							modalTitle: this.$translate.instant('FACTURES.CHECK_VALIDITY.TITLE'),
							modalMsg: this.$translate.instant('FACTURES.CHECK_VALIDITY.MESSAGE'),
							headerClass: 'modal-danger'
						});
						modalInstance.result.then(async () => {
							idReturn = await this.save();
							if (idReturn) {
								this.$state.go('factures.edit', { id: idReturn });
							}
						});
					} else {
						idReturn = await this.save();
						if (idReturn) {
							this.$state.go('factures.edit', { id: idReturn });
						}
					}
				}
			} else {
				return false;
			}
		}
	}

	async save() {
		this.startLoading();
		try {
			if (this.isDuplicate) {
				await this.duplicateFacture();
				return this.facture.entete.idFacture;
			}

			if (this.isEditMode) {
				const tmp = await this.updateFacture();
				return this.params.id;
			}
			this.RouterHistoryService.ignoreNextRoute();
			const tmp = await this.createFacture();
			return tmp;
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			this.stopLoading();
		}
	}

	async valider() {
		const returnedId = await this.sauvegarder();
		//await this.initFacture();
	}
	async validerEtFermer() {
		const returnedId = this.sauvegarder();
		this.backToPreviousState();
	}

	async dupliquer() {
		this.$state.go('factures.duplicate', { id: this.facture.entete.idFacture });
	}

	annuler() {
		if (!this.RouterHistoryService.back()) {
			this.backToPreviousState();
		}
	}

	backToPreviousState() {
		if (this.params && this.params.idCarnet) {
			this.$state.go('carnets.edit', { id: this.params.idCarnet });
		} else {
			this.$state.go('factures.list');
		}
	}

	openFirstOnglet() {
		//par défaut, on ouvert l'onglet "Entête"
		this.ongletOpen = {
			isEnteteOpen: true
		};
	}

	closeAllOnglets() {
		this.ongletOpen = {
			isEnteteOpen: false
		};
	}

	reinit() {
		this.openFirstOnglet();
		// this.setFacture(this.params.id);
		this.FacturesCommunicationService.raiseReinit();
	}

	checkValidity() {
		//this.closeAllOnglets();
		let validity = true;
		let firstScroll = true;
		if (!this.facture.entete.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}
			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('factureEnteteValidations');
		}

		return validity;
	}
	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	async updateFacture() {
		this.startLoading();
		try {
			await this.FacturesService.updateFacture(this.facture.entete);
			this.notification.success('FACTURES.UPDATED');
		} catch (ex) {
			this.notification.error(ex.data);
			this.reinit();
		} finally {
			this.stopLoading();
		}
	}

	async createFacture() {
		this.startLoading();
		try {
			const tmp = await this.FacturesService.createFacture(this.facture.entete);
			this.notification.success('FACTURES.CREATED');
			return tmp;
		} catch (ex) {
			this.notification.error(ex.data);
			this.reinit();
		} finally {
			this.stopLoading();
		}
	}

	async duplicateFacture() {
		this.startLoading();
		try {
			if (this.facture.idFiltreClients) {
				const response = await this.FacturesService.duplicateFacture(this.facture.entete, this.facture.idFiltreClients);
				this.notification.success('FACTURES.CREATED');
				if (response) {
					let msgInfo = this.$translate.instant('FACTURES.DUPLICATE_INFO.MESSAGE');
					this._.forEach(response, (fact) => {
						msgInfo =
							msgInfo + ' pour le client ' + fact.client + ' : ' + fact.code.link('gestion.html#/common/factures/' + fact.id + '/edit');
					});

					this.ModalService.info({
						modalTitle: this.$translate.instant('FACTURES.DUPLICATE_INFO.TITLE'),
						modalMsg: msgInfo
					});
				}
			}
		} catch (ex) {
			this.notification.error(ex.data);
			this.reinit();
		} finally {
			this.stopLoading();
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	addArticlesBlock(pData) {
		const articleBlock = new ArticleBlock(pData);
		// test si le couple SiteCom CHantier existe déjà ou non
		// si oui, message d'information, sinon faire un nouveau bloc
		if (this.facture.entete.listeArticlesByBlock.length > 0) {
			const samus = _.findIndex(this.facture.entete.listeArticlesByBlock, {
				idSiteCommercial: articleBlock.idSiteCommercial,
				idChantier: articleBlock.idChantier
			});
			if (samus != -1) {
				this.notification.warning('FACTURES.EXISTINGBLOCK');
			} else {
				this.facture.entete.listeArticlesByBlock.push(articleBlock);
			}
		} else {
			this.facture.entete.listeArticlesByBlock.push(articleBlock);
		}
	}

	async print() {
		const listFactureIdsToPrint = [];
		listFactureIdsToPrint.push(this.facture.entete.idFacture);

		this.$uibModal
			.open({
				template: '<factures-print source="$ctrl.listFactureIdsToPrint" modal-instance="$ctrl.uibModalInstance"></factures-print>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.listFactureIdsToPrint = listFactureIdsToPrint;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'l'
			})
			.result.then(
				async function (result) {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
				},
				function (reason) {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}

	openModal = function (size, parentSelector) {
		if (this.facture.entete.natureFacture == 4) {
			// ajouter le bloc directement avec les bons Site Com et Chantier
			const data = {};
			// recuperation des ids
			data.idSiteCommercial = this.facture.entete.idSiteCommercial;
			data.idChantier = this.facture.entete.idChantier;
			this.addArticlesBlock(data);
		} else {
			// ouvrir la modal
			const _this = this;
			_this.sitesComForComboBox = this.sitesComForComboBox;
			_this.chantiersForComboBox = this.chantiersForComboBox;
			_this.natureFacture = this.facture.entete.natureFacture;

			const modalInstance = this.$uibModal.open({
				animation: true,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'articleBlockModalContent',
				controllerAs: '$ctrl',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;

						this.sitesComForComboBox = _this.sitesComForComboBox;
						this.chantiersForComboBox = _this.chantiersForComboBox;
						this.natureFacture = _this.natureFacture;

						// si c'est mono Site Commercial
						if (_this.natureFacture == 1) {
							this.selectedIdSiteCommercial = _this.facture.entete.idSiteCommercial;
							this.selectedNomSiteCommercial = _this.facture.entete.nomSiteCommercial;
						} else {
							this.selectedIdSiteCommercial = _this.sitesComForComboBox[0].id;
							this.selectedNomSiteCommercial = _this.sitesComForComboBox[0].libelle;
						}

						// si c'est mono Chantier
						if (_this.natureFacture == 2) {
							this.selectedIdChantier = _this.facture.entete.idChantier;
							this.selectedNomChantier = _this.facture.entete.nomChantier;
						} else {
							this.selectedIdChantier = _this.chantiersForComboBox[0].id;
							this.selectedNomChantier = _this.chantiersForComboBox[0].libelle;
						}

						this.$uibModalInstance = $uibModalInstance;

						this.ok = function () {
							const data = {};
							// recuperation des ids
							data.idSiteCommercial = this.selectedIdSiteCommercial;
							data.idChantier = this.selectedIdChantier;

							// recuperation des libelles
							const indexSelectedSiteCom = _.findIndex(_this.sitesComForComboBox, { id: this.selectedIdSiteCommercial });
							if (indexSelectedSiteCom != -1) {
								data.nomSiteCommercial = _this.sitesComForComboBox[indexSelectedSiteCom].libelle;
							}
							const indexSelectedChantier = _.findIndex(_this.chantiersForComboBox, { id: this.selectedIdChantier });
							if (indexSelectedChantier != -1) {
								data.nomChantier = _this.chantiersForComboBox[indexSelectedChantier].libelle;
							}
							this.$uibModalInstance.close({ data: data });
						};
						this.cancel = function () {
							this.$uibModalInstance.close();
						};
					}
				]
			});

			const that = this;
			modalInstance.result.then(function (result) {
				if (result) {
					that.addArticlesBlock(result.data);
				}
			});
		}
	};
}
