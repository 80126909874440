import NormeSpecificationsController from './norme.specifications.controller';

export default {
    template: require('./norme.specifications.tpl.html'),
    controller: NormeSpecificationsController,
    bindings: {
        categories: '<',
        domaine: '<',
        specification: '<',
        essai: '<',
        norme: '<',
        onDelete: '&',
        onDeleteAll: '&',
        onUpdateCategorie: '&',
        onAdd: '&',
        onSelectEssai: '&'
        //calculsStatistiques: '<'
        //onUpdateOrdre: '&'
    }
};
