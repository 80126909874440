import open from './accordeon.open.directive';
import close from './accordeon.close.directive';
import toggle from './accordeon.toggle.directive';
import scrollTop from './accordeon.scrolltop.directive';

export default {
    close,
    open,
    toggle,
    scrollTop
};
