import ArticlesChantierController from './articles.controller';

export default {
    bindings: {
        facture: '=',
        isOpen: '='
    },
    template: require('./articles.tpl.html'),
    controller: ArticlesChantierController
};
