(function (angular, undefined) {
    'use strict';

    /* Exemple d'utilisation 1
     *  <div ac-pageslide
     *      ps-side="right"
     *      ps-auto-close="true"
     *      ps-size="650px"
     *      ps-open="ctrl.sliderService.sliderOpened"
     *      ps-custom-top="110px"
     *      ps-custom-bottom="50px">
     *          <div style="height:100%" class="slider-form">
     *              <button class="btn btn-link color-white close-slider"
     *                  ng-click="ctrl.sliderService.closeSlider()">
     *                  <span class="glyphicons glyphicons-chevron-right"></span>
     *              </button>
     *              <div style="height:100%"
     *                  ng-if="ctrl.sliderService.sliderOpened"
     *                  ng-include="app/modules/slider/views/template.slider.tpl.html"></div>
     *          </div>
     *  </div>
     */

    angular.module('blocks.pageslide')
        .directive('acPageslide', acPageslide);

    acPageslide.$inject = ['$window', '$timeout', '$document', 'acPageSlideCommunicationService'];
    function acPageslide($window, $timeout, $document, acPageSlideCommunicationService) {
        var defaults = {
            psName: Math.floor((Math.random() * 10000) + 1),
            psOpen: false,
            psAutoClose: false,
            psSide: 'right',
            psSpeed: '0.5',
            psClass: 'ac-pageslide',
            psSize: '300px',
            psSqueeze: false,
            psCloak: true,
            psPush: false,
            psContainer: false,
            psCustomTop: '0px',
            psCustomRight: '0px',
            psCustomBottom: '0px',
            psCustomLeft: '0px',
            zindex: 99
        };

        return {
            restrict: 'EAC',
            transclude: false,
            scope: {
                psName: '@',
                psOpen: '=?',
                psAutoClose: '=?',
                psSide: '@',
                psSpeed: '@',
                psClass: '@',
                psSize: '@',
                psSqueeze: '@',
                psCloak: '@',
                psPush: '@',
                psContainer: '@'
            },
            link: function ($scope, el, attrs) {
                /* Inspect */
                //console.log($scope);
                //console.log(el);
                //console.log(attrs);

                /* Attributs */
                var param = {},
                    content = null,
                    slider = null,
                    sliderEl = null,
                    container = null,
                    containerEl = null,
                    psTimeout = null,
                    psDisplay = null;
                /* Events */
                var onDestroy,
                    onLocationChangeStartEvent,
                    onStateChangeStartEvent;

                /* Initialization */
                init();

                function init() {
                    // Get parameters
                    param = configure();

                    // Get Container
                    container = getContainer();
                    containerEl = angular.element(container);

                    // Get Slider
                    slider = getSlider();
                    sliderEl = angular.element(slider);

                    // Get Content
                    content = angular.element(slider.children);

                    containerEl.append(sliderEl);

                    // Set container Class
                    el.addClass(param.className);

                    // Add watchers
                    addWatchers();

                    // Bind Events
                    bindEvents();

                    // Start
                    psPosition();
                }

                /* Configuration */
                function configure() {
                    return {
                        name: $scope.psName || defaults.psName,
                        side: $scope.psSide || defaults.psSide,
                        speed: $scope.psSpeed || defaults.psSpeed,
                        size: $scope.psSize || defaults.psSize,
                        zindex: defaults.zindex, // Override with custom CSS
                        className: $scope.psClass || defaults.psClass,
                        cloak: $scope.psCloak && $scope.psCloak.toLowerCase() == 'false' ? false : defaults.psCloak,
                        squeeze: Boolean($scope.psSqueeze) || defaults.psSqueeze,
                        push: Boolean($scope.psPush) || defaults.psPush,
                        container: $scope.psContainer || defaults.psContainer,
                        psOnloadContainer: Boolean($scope.psContainerOnload) === false ? false : defaults.psOnloadContainer,
                        psAutoClose: $scope.psAutoClose || defaults.psAutoClose,
                        cssPosition: {
                            'top': attrs.psCustomTop || defaults.psCustomTop,
                            'right': attrs.psCustomRight || defaults.psCustomRight,
                            'bottom': attrs.psCustomBottom || defaults.psCustomBottom,
                            'left': attrs.psCustomLeft || defaults.psCustomLeft
                        },
                        position: {
                            top: parseCSSToInt(attrs.psCustomTop || defaults.psCustomTop),
                            right: parseCSSToInt(attrs.psCustomRight || defaults.psCustomRight),
                            bottom: parseCSSToInt(attrs.psCustomBottom || defaults.psCustomBottom),
                            left: parseCSSToInt(attrs.psCustomLeft || defaults.psCustomLeft)
                        }
                    };
                }

                /* Container */
                function getContainer() {
                    var element = param.container ? document.getElementById(param.container) : globalHelpers.findAncestorByClassName(el[0], 'id-apps-body'); // jshint ignore:line

                    if (element === null) {
                        element = $document.find('body');
                    }

                    if (param.squeeze) {
                        element.style.position = 'absolute';
                        element.style.transitionDuration = param.speed + 's';
                        element.style.webkitTransitionDuration = param.speed + 's';
                        element.style.transitionProperty = 'top, bottom, left, right';
                    }

                    return element;
                }

                /* Slider */
                function getSlider() {
                    var element = el[0];

                    // Check for div tag
                    if (element.tagName.toLowerCase() !== 'div' &&
                        element.tagName.toLowerCase() !== 'pageslide')
                        throw new Error('Pageslide can only be applied to <div> or <pageslide> elements');

                    // Check for content
                    if (element.children.length === 0)
                        throw new Error('You have to content inside the <pageslide>');

                    // Set Style
                    element.style.zIndex = param.zindex;
                    element.style.position = param.container !== false ? 'absolute' : 'fixed';
                    element.style.overflow = 'hidden';
                    element.style.transitionDuration = param.speed + 's';
                    element.style.webkitTransitionDuration = param.speed + 's';

                    switch (param.side) {
                        case 'right':
                            element.style.top = param.cssPosition.top;
                            element.style.bottom = param.cssPosition.bottom;
                            element.style.right = param.cssPosition.right;
                            element.style.width = 0;
                            element.style.transitionProperty = 'width';
                            break;
                        case 'left':
                            element.style.top = param.cssPosition.top;
                            element.style.bottom = param.cssPosition.bottom;
                            element.style.left = param.cssPosition.left;
                            element.style.width = 0;
                            element.style.transitionProperty = 'width';
                            break;
                        case 'top':
                            element.style.left = param.cssPosition.left;
                            element.style.top = param.cssPosition.top;
                            element.style.right = param.cssPosition.right;
                            element.style.height = 0;
                            element.style.transitionProperty = 'height';
                            break;
                        case 'bottom':
                            element.style.bottom = param.cssPosition.bottom;
                            element.style.left = param.cssPosition.left;
                            element.style.right = param.cssPosition.right;
                            element.style.height = 0;
                            element.style.transitionProperty = 'height';
                            break;
                    }

                    return element;
                }

                /* Actions */

                // Event position change
                function psPosition() {
                    if (psTimeout !== null) {
                        $timeout.cancel(psTimeout);
                    }

                    psTimeout = $timeout(function () {
                        psSetupPosition(container, slider, param);
                    });
                }

                // Close slide
                function psClose($scope, container, slider, param) {
                    if (slider && slider.style.width !== 0 && slider.style.width !== 0) {
                        if (param.cloak) content.css('display', 'none');
                        switch (param.side) {
                            case 'right':
                                slider.style.width = '0px';
                                if (param.squeeze) container.style.right = '0px';
                                if (param.push) {
                                    container.style.right = '0px';
                                    container.style.left = '0px';
                                }
                                break;
                            case 'left':
                                slider.style.width = '0px';
                                if (param.squeeze) container.style.left = '0px';
                                if (param.push) {
                                    container.style.left = '0px';
                                    container.style.right = '0px';
                                }
                                break;
                            case 'top':
                                slider.style.height = '0px';
                                if (param.squeeze) container.style.top = '0px';
                                if (param.push) {
                                    container.style.top = '0px';
                                    container.style.bottom = '0px';
                                }
                                break;
                            case 'bottom':
                                slider.style.height = '0px';
                                if (param.squeeze) container.style.bottom = '0px';
                                if (param.push) {
                                    container.style.bottom = '0px';
                                    container.style.top = '0px';
                                }
                                break;
                        }
                    }
                    $scope.psOpen = false;
                }

                // Open slide
                function psOpen(container, slider, content, param) {
                    if (slider.style.width !== 0 && slider.style.width !== 0) {
                        switch (param.side) {
                            case 'right':
                                slider.style.width = param.size;
                                if (param.squeeze) container.style.right = param.size;
                                if (param.push) {
                                    container.style.right = param.size;
                                    container.style.left = '-' + param.size;
                                }
                                break;
                            case 'left':
                                slider.style.width = param.size;
                                if (param.squeeze) container.style.left = param.size;
                                if (param.push) {
                                    container.style.left = param.size;
                                    container.style.right = '-' + param.size;
                                }
                                break;
                            case 'top':
                                slider.style.height = param.size;
                                if (param.squeeze) container.style.top = param.size;
                                if (param.push) {
                                    container.style.top = param.size;
                                    container.style.bottom = '-' + param.size;
                                }
                                break;
                            case 'bottom':
                                slider.style.height = param.size;
                                if (param.squeeze) container.style.bottom = param.size;
                                if (param.push) {
                                    container.style.bottom = param.size;
                                    container.style.top = '-' + param.size;
                                }
                                break;
                        }
                        psDisplay = $timeout(function () {
                            if (param.cloak) content.css('display', 'block');
                        }, (param.speed * 1000));
                    }
                }

                // Calculate new position in container
                function psSetupPosition(container, slider, param) {
                    var containerHeight = container.offsetHeight || 0,
                        containerWidth = container.offsetWidth || 0,
                        sliderHeight = slider.offsetHeight || 0,
                        sliderWidth = slider.offsetWidth || 0,
                        sliderOffsetTop = parseCSSToInt(slider.style.top) || 0,
                        sliderOffsetBottom = parseCSSToInt(slider.style.bottom) || 0,
                        sliderOffsetLeft = parseCSSToInt(slider.style.top) || 0,
                        sliderOffsetRight = parseCSSToInt(slider.style.bottom) || 0,
                        containerOffsetTop = container.scrollY || 0,
                        containerOffsetLeft = container.scrollX || 0;

                    if (param.container === false) {
                        containerOffsetTop += $window.pageYOffset || 0;
                        containerOffsetLeft += $window.pageXOffset || 0;
                    }

                    var fixedTop = Math.max(0, param.position.top - containerOffsetTop),
                        fixedLeft = Math.max(0, param.position.left - containerOffsetLeft),
                        fixedBottom = Math.max(0, param.position.bottom - (containerHeight - (containerOffsetTop + sliderOffsetTop + sliderHeight + sliderOffsetBottom))),
                        fixedRight = Math.max(0, param.position.right - (containerWidth - (containerOffsetLeft + sliderOffsetLeft + sliderWidth + sliderOffsetRight)));

                    switch (param.side) {
                        case 'right':
                        case 'left':
                            slider.style.top = fixedTop + 'px';
                            slider.style.bottom = fixedBottom + 'px';
                            break;
                        case 'top':
                        case 'bottom':
                            slider.style.left = fixedLeft + 'px';
                            slider.style.right = fixedRight + 'px';
                            break;
                    }
                }

                /* Helpers */
                function parseCSSToInt(value) {
                    return value && typeof value === 'string' ? Number(value.replace(/[^\d\.\-]/g, '')) : parseInt(value);
                }

                /* Watchers */
                function addWatchers() {
                    // Toggle slider
                    $scope.$watch('psOpen', function (value) {
                        if (!!value) {
                            // Open
                            psSetupPosition(container, slider, param);
                            psOpen(container, slider, content, param);
                        } else {
                            // Close
                            psClose($scope, container, slider, param);
                        }
                    });
                }

                /* Events */
                function bindEvents() {
                    onDestroy = $scope.$on('$destroy', dispose);
                    // On scroll and Resize
                    angular.element([container, $window]).bind('scroll resize', psPosition);
                    // Event container finish load
                    acPageSlideCommunicationService.registerPageslideReloadFunction(param.name, psPosition);

                    if (param.psAutoClose) {
                        onLocationChangeStartEvent = $scope.$on('$locationChangeStart', function () {
                            psClose($scope, container, slider, param);
                        });
                        onStateChangeStartEvent = $scope.$on('$stateChangeStart', function () {
                            psClose($scope, container, slider, content, param);
                        });
                    }
                }

                function dispose() {
                    sliderEl.remove();
                    angular.element([container, $window]).unbind('scroll resize', psPosition);
                    acPageSlideCommunicationService.unregisterPageslideReloadFunction(param.name);
                    if (psTimeout !== null) {
                        $timeout.cancel(psTimeout);
                    }
                    if (psDisplay !== null) {
                        $timeout.cancel(psDisplay);
                    }
                    if (onDestroy !== undefined) {
                        onDestroy();
                    }
                    if (onLocationChangeStartEvent !== undefined) {
                        onLocationChangeStartEvent();
                    }
                    if (onStateChangeStartEvent !== undefined) {
                        onStateChangeStartEvent();
                    }
                }
            }
        };
    }

})(angular);