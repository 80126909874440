import { Component } from '../../../core/decorators';

@Component({
    selector: 'link-balance',
    bindings: {
        obj: '=',
        ongletOpen: '='
    },
    transclude: true,
    template: require('./link.balance.component.html')
})
export class LinkBalanceController {
    /* @ngInject */
    constructor($state, $stateParams, $scope, notification, $translate, ConfigurationBalanceService) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
        this.notification = notification;
        this.$translate = $translate;
        this.ConfigurationBalanceService = ConfigurationBalanceService;
    }

    async $onInit() {
        this.startLoading();
        this.configurationBalances = [];

        const configs = await this.ConfigurationBalanceService.getAll(null, null, null);
        this.configurationBalances = configs.items;

        if (this.obj?.configurationBalance != null && this.configurationBalances.length > 0) {
            let idx = this.configurationBalances.findIndex((x) => x.id == this.obj.configurationBalance.id);
            if (idx > -1) {
                this.configurationBalances[idx].selected = true;
            }
        }
        this.stopLoading();
    }

    selectItem(item) {
        if (item.selected) {
            this.obj.configurationBalance = {
                id: item.id,
                libelle: item.libelle
            };
        } else {
            this.obj.configurationBalance = null;
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
