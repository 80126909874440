import {StateService} from '@uirouter/angularjs';
import {ng} from '@uirouter/angularjs/lib/angular';

export default class Import3rListController {
    /* @ngInject */
    $scope: ng.IScope;
    $state: StateService;
    $translate: any;
    Import3rService: any;
    PaginationService: any;
    notification: any;
    ModalService: any;
    isOpen: string;
    loading: string | boolean;
    selectedParamId: number;
    itemsByPageOptions: number[];
    data: object;
    previousTableState: any;

    constructor($scope: ng.IScope, $state: StateService, Import3rService: any, $translate: any, PaginationService: any, notification: any, ModalService: any) {
        this.$scope = $scope;
        this.$state = $state;
        this.$translate = $translate;
        this.Import3rService = Import3rService;
        this.PaginationService = PaginationService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.isOpen = 'true';
        this.loading = 'false';
        this.selectedParamId = undefined;
        this.itemsByPageOptions = [20, 100, 200];
    }

    $onInit() {
        this.data = [];
    }

    // Arrow fx for bind
    loadParams = async (tableState?: any) => {
        this.startLoading();
        if (this.$state && this.$state.$current && this.$state.$current.name !== 'import-3r.list') {
            this.$state.go('import-3r.list');
        }

        if (tableState) {
            this.previousTableState = tableState;
        }

        const filters = this.PaginationService.getFilters(this.previousTableState);
        const sorts = this.PaginationService.getSorts(this.previousTableState);
        const pagination = this.PaginationService.getPagination(this.previousTableState);

        try {
            const data: any = await this.Import3rService.getParams3rConfig(filters, sorts, pagination);

            this.data = data.items;

            if (this.previousTableState) {
                this.PaginationService.setTableState(data.skip, data.take, data.total, this.previousTableState);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async deleteParam(config: any) {
        if (config && config.id) {
            const modalInstance = this.ModalService.confirm({
                modalTitle: this.$translate.instant('PRESSE_3R.DELETE.TITLE', {libelle: config.libelle}),
                modalMsg: this.$translate.instant('PRESSE_3R.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result
                .then(async () => {
                    this.startLoading();
                    try {
                        await this.Import3rService.deleteParam3rConfig(config.id);
                        await this.loadParams();

                        this.notification.success(this.$translate.instant('SUPPRIMER_SUCCESS', {entity: config.libelle}));
                        this.selectedParamId = undefined;

                        this.$state.go('import-3r.list', {}, {reload: true});
                        this.previousTableState = this.PaginationService.getTableStateAfterDelete(this.previousTableState, this.previousTableState.pagination.take);

                    } catch (ex) {
                        this.notification.error(ex.data);
                    } finally {
                        this.stopLoading();
                    }
                });
        }
    }

    async changeActiveConfig(idConfig: number) {
        try {
            const config = Object.values(this.data).find(x => x.id === idConfig);
            config.isActive = !config.isActive;
            const idOldConfigActive: number = await this.Import3rService.changeActiveConfigFromList(config);
            if (idOldConfigActive > 0) {
                Object.values(this.data).find(x => x.id === idOldConfigActive).isActive = false;
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async eraseAllFilters() {
        await this.loadParams();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
