(function (angular, undefined) {
    'use strict';

    angular.module('blocks.treeview')
        .run(run);
    run.$inject = ['$templateCache'];

    function run($templateCache) {

        $templateCache.put('blocks/treeview/ac.treeview.configuration.menu.html',
            '    <!-- La dropdown de configuration -->' +
            '    <div class="treeview-tree-actions clearfix">' +
            '        <div ng-if="::(!tree.config.searchDisabled)" ' +
            '              class="form-inline col-md-11 text-left treeview-tree-search">' +
            '            <div class="form-group">' +
            '                <div class="input-group input-group-sm">' +
            '                    <div class="input-group-btn">' +
            '                        <button class="btn btn-default" ng-click="tree.search(tree.searchTerm)">' +
            '                            <span class="glyphicon glyphicon-search"></span>' +
            '                        </button>' +
            '                    </div>' +
            '                    <input type="text" ' +
            '                           class="form-control" ' +
            '                           id="search" ' +
            '                           ng-model="tree.searchTerm"' +
            '                           translate translate-attr-placeholder="TREEVIEW_SEARCH">' +
            '                    <div class="input-group-btn">' +
            '                        <button class="btn btn-default" ng-click="tree.resetSearch()">' +
            '                            <span class="glyphicon glyphicon-remove"></span>' +
            '                        </button>' +
            '                    </div>' +
            '                </div>' +
            '            </div>' +
            '        </div>' +
            '        <div ng-if="::(tree.config.displayTreeActions && !tree.config.readOnly)" ' +
            '             class="btn-group col-md-1 padding-0 treeview-tree-config"' +
            '             ac-context-menu="tree.clearRename()" ' +
            '             ac-context-menu-type="left" ' +
            '             data-target="{{ ::tree.config.name }}-treeview-configuration-menu">' +
            '            <button type="button" class="btn btn-link btn-sm dropdown-toggle">' +
            '                <span class="glyphicon glyphicon-cog"></span>' +
            '            </button>' +
            '       </div>' +
            '' +
            '       <!-- Menu de configuration -->' +
            '       <div class="dropdown position-fixed"' +
            '            id="{{ ::tree.config.name }}-treeview-configuration-menu"' +
            '            ng-if="::(tree.config.displayTreeActions && !tree.config.readOnly)">' +
            '            <ul class="dropdown-menu" role="menu" style="left:-200px;">' +
            '                <li class="text-center text-muted">- {{ tree.config.configTitle || \'TREEVIEW_CONFIG_TITLE\' | translate }} -</li>' +
            '                <li ng-click="tree.createRootNode()"' +
            '                    ng-if="::tree.canCreateRootNode()">' +
            '                    <a href="">' +
            '                        <span class="glyphicon glyphicon-plus"></span><!--' +
            '                        --><span>' +
            '                              {{ (tree.config.labels.addRoot || \'TREEVIEW_ADD_ROOT\') | translate }}' +
            '                        </span>' +
            '                    </a>' +
            '                </li>' +
            '                <li ng-click="tree.expandAll()"' +
            '                    ng-if="::(!tree.config.disableExpandAll)">' +
            '                    <a href="">' +
            '                        <span class="glyphicon glyphicon-folder-open"></span><!--' +
            '                        --><span>' +
            '                              {{ (tree.config.labels.expandAll || \'TREEVIEW_EXPAND_ALL\') | translate }}' +
            '                        </span>' +
            '                    </a>' +
            '                </li>' +
            '                <li ng-click="tree.collapseAll()"' +
            '                    ng-if="::(!tree.config.disableCollapseAll)">' +
            '                    <a href="">' +
            '                        <span class="glyphicon glyphicon-folder-close"></span><!--' +
            '                        --><span>' +
            '                              {{ (tree.config.labels.collapseAll || \'TREEVIEW_COLLAPSE_ALL\') | translate }}' +
            '                        </span>' +
            '                    </a>' +
            '                </li>' +
            '                <li ng-click="tree.config.dropDisabled = false;"' +
            '                    ng-if="::(tree.config.enableDragDropMenu && tree.config.dropDisabled)">' +
            '                    <a href="">' +
            '                        <span class="glyphicon glyphicon-move"></span><!--' +
            '                        --><span>' +
            '                              {{ (tree.config.labels.enableDrag || \'TREEVIEW_ENABLE_DRAG\') | translate }}' +
            '                        </span>' +
            '                    </a>' +
            '                </li>' +
            '                <li ng-click="tree.config.dropDisabled = true;"' +
            '                    ng-if="::(tree.config.enableDragDropMenu && !tree.config.dropDisabled)">' +
            '                    <a href="">' +
            '                        <span class="glyphicon glyphicon-ban-circle"></span><!--' +
            '                        --><span>' +
            '                              {{ (tree.config.labels.disableDrag || \'TREEVIEW_DISABLE_DRAG\') | translate }}' +
            '                        </span>' +
            '                    </a>' +
            '                </li>' +
            '                <li class="divider" ng-if="::(tree.config.configActions.length > 0)"></li>' +
            '                <li ng-repeat="action in ::tree.config.configActions" ng-if="::(tree.canDisplayAction(action))">' +
            '                   <a href="" ng-click="action.function(item)">' +
            '                       <span class="glyphicon glyphicon-{{::action.icon || \'blank\'}}"></span><!--' +
            '                        --><span translate="{{ ::action.name }}"></span></a>' +
            '                </li>' +
            '            </ul>' +
            '        </div>' +
            '    </div>' +
            '');
    }
})(angular);