(function (angular, undefined) {

    angular
        .module('blocks.helpers')
        .directive('acExposeNgModelCtrl', acExposeNgModelCtrl);
    acExposeNgModelCtrl.$inject = ['$log'];

    function acExposeNgModelCtrl($log) {

        return {
            restrict: 'A',
            link: link,
            require: 'ngModel'
        };

        function link(scope, element, attr, ngModelCtrl) {
            var exposedCtrl, exposeNgModelCtrl, exposedCtrlProperty;

            var registeredEvents = [
                scope.$on('$destroy', dispose)
            ];

            init();

            function init() {
                exposeNgModelCtrl = scope.$eval(attr.acExposeNgModelCtrl);

                if (exposeNgModelCtrl && exposeNgModelCtrl.length === 2) {
                    exposedCtrl = exposeNgModelCtrl[0];
                    exposedCtrlProperty = exposeNgModelCtrl[1];
                    exposedCtrl[exposedCtrlProperty] = ngModelCtrl;
                }
                else {
                    $log.warn('acExposeNgModelCtrl needs an array with controllerAs propery (or scope) and name property ac-expose-ng-model-ctrl="[ctrl, ctrl.inputModelCtrlName]');
                }
            }

            function dispose() {
                angular.forEach(registeredEvents, function (unregisterEvent) {
                    unregisterEvent();
                });
            }
        }
    }
})(angular);