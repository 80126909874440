import Rvmodal from './rvmodal.controller';

export default {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: require('./rvmodal.html'),
    controller: Rvmodal
};
