//Config
import ReferencesControleConfig from './_config/references.controle.config';
import SeuilsRoutes from './_config/seuils.routes';
import SeuilSecuRoutes from './_config/seuilsecu.routes';
import SeuilCtrlRoutes from './_config/seuilctrl.routes';
import SeuilGranRoutes from './_config/seuilgran.routes';

//Components
import ReferencesControleComponent from './components/references-controle';
import ReferenceControleDetailComponent from './components/reference-controle-detail';
import ReferenceControleFormComponent from './components/reference-controle-form';
import ReferenceControleMesuresComponent from './components/reference-controle-form/reference-controle-mesures';
import ReferenceControleFormCaracteristiquesComponent from './components/reference-controle-form/caracteristiques';
import ImportNormeModalComponent from './components/import-norme-modal';
import ImportFtpModalComponent from './components/import-ftp-modal';

//Services
import ReferencesControleService from './services/references.controle.service';
import ReferencesControleTypesService from './services/references.controle.types.service';
import ReferencesControleCommunicationService from './services/references.controle.communication.service';

//Validateurs
import ReferenceControleValidator from './components/reference-controle-form/reference.controle.validator';
import ReferenceControleCaracteristiquesValidator from './components/reference-controle-form/caracteristiques/reference.controle.caracteristiques.validator';

const moduleName = 'app.massia.common.references.controle';

angular
    .module(moduleName, [])
    .config(ReferencesControleConfig)
    .config(SeuilsRoutes)
    .config(SeuilSecuRoutes)
    .config(SeuilCtrlRoutes)
    .config(SeuilGranRoutes)
    .service('ReferencesControleService', ReferencesControleService)
    .service('ReferencesControleTypesService', ReferencesControleTypesService)
    .service('ReferencesControleCommunicationService', ReferencesControleCommunicationService)
    .component('referencesControle', ReferencesControleComponent)
    .component('referenceControleDetail', ReferenceControleDetailComponent)
    .component('referenceControleForm', ReferenceControleFormComponent)
    .component('referenceControleMesures', ReferenceControleMesuresComponent)
    .component('referenceControleFormCaracteristiques', ReferenceControleFormCaracteristiquesComponent)
    .component('importNormeModal', ImportNormeModalComponent)
    .component('importFtpModal', ImportFtpModalComponent)
    .factory('ReferenceControleValidator', ReferenceControleValidator)
    .factory('ReferenceControleCaracteristiquesValidator', ReferenceControleCaracteristiquesValidator);

export default moduleName;
