import GrilleTarifairesConfig from './_config/grille.tarifaires.config';
import GrilleTarifairesRoutes from './_config/grille.tarifaires.routes';

import GrilleTarifairesComponent from './components/grille-tarifaires';

import GrilleTarifairesService from './services/grille.tarifaires.service';

const moduleName = 'app.massia.gestion.grille.tarifaires';

angular
    .module(moduleName, [])
    .config(GrilleTarifairesConfig)
    .config(GrilleTarifairesRoutes)
    .service('GrilleTarifairesService', GrilleTarifairesService)
    .component('grilleTarifaires', GrilleTarifairesComponent);

export default moduleName;
