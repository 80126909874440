import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-accordion-group',
    bindings: {
        closeOther: '@'
    },
    transclude: true,
    template: require('./massia.accordion.group.html')
})
export class MassiaAccordionGroupController {
    constructor() {}

    $onInit() {}

    $onDestroy() {}
}

MassiaAccordionGroupController.$inject = [];
