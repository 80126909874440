import authInterceptorModule from 'angular-http-auth'; // il faut mettre le nom du module tel qu'il est dans le package.json

import AuthTranslations from './config/auth.translations';
import TokenInterceptor from './interceptors/token.interceptor';
import AuthConstant from './config/auth.constant';
import MassiaAuthService from './services/massia.auth.service';
import MassiaRightsService from './services/massia.rights.service';
import MassiaAuthConfigurator from './config/massia.auth.run';
import MassiaAuthorizeDirective from './directives/massia.authorize.directive';

const moduleName = 'app.massia.authentication';

angular
    .module(moduleName, [authInterceptorModule])
    .constant('AuthConstant', AuthConstant)
    .config(AuthTranslations)
    .config(TokenInterceptor)
    .service('MassiaAuthService', MassiaAuthService)
    .service('MassiaRightsService', MassiaRightsService)
    .directive('massiaAuthorize', MassiaAuthorizeDirective.create)
    .run(MassiaAuthConfigurator);

export default moduleName;
