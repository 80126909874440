export default class FormuleDateController {
    /* @ngInject */
    constructor($scope, $state, $stateParams, ModalService, notification, $translate, $filter, _, $uibModal, moment, globalizationManagementService) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.$filter = $filter;
        this.$uibModal = $uibModal;
        this.moment = moment;
        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    async $onInit() {
        this.startLoading();
        this.date = this.moment(new Date()).format(this.dateFormat + ' HH:mm');
        this.heure = this.moment(new Date()).format(this.dateFormat + ' HH:mm');

        this.stopLoading();
    }

    valider() {
        let dateArchive = null;
        if (this.date) {
            const date = this.moment(this.date, this.dateFormat).toDate();
            /**
             * @type Date
             */
            const hours = this.moment(this.heure, this.dateFormat + ' HH:mm').toDate();
            if (hours && !isNaN(hours.getTime())) {
                this.date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours.getHours(), hours.getMinutes());
            } else {
                this.date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), null, null);
            }

            dateArchive = this.moment(this.date).format(this.dateFormat + ' HH:mm');
        }

        if (this.moment(this.date, this.dateFormat + ' HH:mm').isAfter(this.moment(new Date(), this.dateFormat + ' HH:mm'))) {
            this.notification.error(this.$translate.instant('FORMULES.CHECK_DATE_JOUR'));
        } else {
            if (this.moment(this.date, this.dateFormat + ' HH:mm').isAfter(this.moment(this.dateArchivage, this.dateFormat + ' HH:mm'))) {
                this.notification.error(this.$translate.instant('FORMULES.CHECK_DATE_VALIDITE'));
            } else {
                this.modalInstance.close(this.date);
            }
        }
    }

    cancel() {
        this.modalInstance.dismiss();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
