(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.pageslide')
        .factory('acPageSlideCommunicationService', acPageSlideCommunicationService);

    acPageSlideCommunicationService.$inject = ['$log'];

    /* @ngInject */
    function acPageSlideCommunicationService($log) {
        var pageslideReloadFunction = [];

        var service = {
            registerPageslideReloadFunction: registerPageslideReloadFunction,
            unregisterPageslideReloadFunction: unregisterPageslideReloadFunction,
            raisePageslideReloadFunction: raisePageslideReloadFunction
        };

        return service;

        // region Pageslide Reload
        function registerPageslideReloadFunction(name, func) {
            if (angular.isFunction(func)) {
                pageslideReloadFunction[name] = func;
            }
            else {
                $log.warn(func + 'is not a function');
            }
        }

        function unregisterPageslideReloadFunction(name) {
            pageslideReloadFunction[name] = angular.noop;
        }

        function raisePageslideReloadFunction(name) {
            if (angular.isFunction(pageslideReloadFunction[name])) {
                pageslideReloadFunction[name]();
            }
        }

        // endregion
    }
})(angular);
