import ControlePeseeController from './controle.pesee.controller';

export default {
    bindings: {
        result: '<',
        save: '=',
        openSynthese: '=',
        generate: '=',
        synthese: '<',
        filter: '<'
    },
    controller: ControlePeseeController,
    template: require('./controle.pesee.tpl.html')
};
