import ImageEntete from './image.entete.model';

export default class ImageFormEnteteController {
    static $inject = ['$state', '$stateParams', 'ImagesService', 'ImagesTypesService', 'ModalService', 'notification', '$translate', 'Upload'];

    constructor($state, $stateParams, ImagesService, ImagesTypesService, ModalService, notification, $translate, Upload) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ImagesService = ImagesService;
        this.ImagesTypesService = ImagesTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.Upload = Upload;
        this.Progression = 0;
    }

    async $onInit() {
        this.image = this.image || {};
        this.entete = new ImageEntete(this.image.entete);
        this.types = this.types || [];
        this.fichier = [];
        await this.getNiveauVisibilite();
        await this.getImageFromServer();
    }

    async getNiveauVisibilite() {
        if (this.entete.idType) {
            this.entete.niveauVisibilite = await this.ImagesTypesService.GetNiveauVisibilite(this.entete.idType);
        }
    }

    $onChanges(objChanges) {
        if (objChanges.image) {
            this.getTypes();
        }
    }

    $doCheck() {
        if (!angular.equals(this.entete, this.image.entete)) {
            this.onUpdate({
                entete: angular.copy(this.entete)
            });
        }
    }

    async onFileSelect($files) {
        try {
            const data = await this.ImagesService.onFileSelect($files, this.image.id, this.image.entete.code);
            await this.onUpdateImage();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async createType() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'IMAGES.CONFIRM_CREATE_TYPE.TITLE',
            modalMsg: 'IMAGES.CONFIRM_CREATE_TYPE.MESSAGE',
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(() => this.$state.go('images.newType'));
    }

    async getTypes() {
        this.startLoading();

        try {
            this.types = await this.ImagesTypesService.getAll();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.entete.codeExists = await this.ImagesService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.entete.codeExists = null;
        }
    }

    async getImageFromServer() {
        this.imageFile = await this.ImagesService.getShowImage(this.image.entete.source, this.image.entete.extension, this.image.entete.code);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
