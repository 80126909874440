import SiteFormNiveauxController from './site.form.niveaux.controller';

export default {
    bindings: {
        site: '<',
        form: '=',
        onUpdate: '&'
    },
    template: require('./site.form.niveaux.html'),
    controller: SiteFormNiveauxController
};
