import ControleEntete from './controle.entete.model';

export default class ControleEnteteControler {
    static $inject = ['$stateParams', 'ControlesCommunicationService', 'ControlesService', 'notification'];

    constructor($stateParams, ControlesCommunicationService, ControlesService, notification) {
        this.$stateParams = $stateParams;
        this.ControlesCommunicationService = ControlesCommunicationService;
        this.ControlesService = ControlesService;
        this.notification = notification;
    }

    async $onInit() {
        this.loading = false;
        this.codeLoading = false;
        this.isEditMode = false;
        this.unregisterReinit = this.ControlesCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    initForm() {
        let entete = {};

        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
            entete = this.controle.entete;
        } else {
            this.isEditMode = false;
        }
        this.controle.entete = new ControleEntete(entete);
        this.getControleTypes();
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.controle.entete.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.controle.entete.codeExists = await this.ControlesService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    async getControleTypes() {
        try {
            this.controleTypes = await this.ControlesService.getControleTypes();
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
