import { element } from 'angular';

export default function CreatePrototype() {
    /**
     *
     * @param {Function} prediction
     */
    Array.prototype.groupBy = function (prediction) {
        const array = this;
        const res = {};
        for (let i = 0; i < array.length; i++) {
            const element = array[i];
            const key = prediction(element);
            if (!res[key]) {
                res[key] = [];
            }
            res[key].push(element);
        }

        return res;
    };

    /**
     *
     * @param {Function} prediction
     */
    Array.prototype.distinctBy = function (prediction) {
        const array = this;
        return [...new Map(array.map((x) => [prediction(x), x])).values()];
    };

    /**
     *
     * @param {Number} nbr
     */
    Number.prototype.round = function (nbr) {
        return Number(Math.round(this + 'e+' + nbr) + 'e-' + nbr);
    };

    /**
     *
     * @param {String} str
     */
    String.prototype.toLowerInvariant = function () {
        return this.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();
    };

    /**
     *
     * @param {String} str
     */
    String.prototype.toUpperInvariant = function () {
        return this.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase();
    };
}

CreatePrototype.$inject = [];
