(function () {
    'use strict';

    angular.module('blocks.modal').run(ModalRun);
    ModalRun.$inject = ['$templateCache'];

    function ModalRun($templateCache) {
        $templateCache.put('blocks/modal/info.tpl.html',
            '<div ac-modal-drag="{{ ::modalCtrl.config.enableDrag }}" class="modal-header {{ modalCtrl.config.headerClass }}">' +
            '   <button type="button" class="close" ng-click="modalCtrl.cancel()">' +
            '       <span class="glyphicon glyphicon-remove"></span>' +
            '   </button>' +
            '   <h4 class="modal-title">' +
            '       <span ng-class="modalCtrl.titleIcons"></span><span translate="{{ modalCtrl.config.modalTitle }}"></span>' +
            '   </h4>' +
            '</div>' +
            '' +
            '<div ac-modal-resize="{{ ::modalCtrl.config.enableResize }}" class="modal-body">' +
            '   <p ng-bind-html="modalCtrl.config.modalMsg | translate"></p>' +
            '</div>' +
            '' +
            '<div class="modal-footer">' +
            '   <button type="button" class="btn btn-{{modalCtrl.config.buttonClass}}" ac-autofocus ng-click="modalCtrl.close(modalCtrl.data)">' +
            '       <span translate="{{ modalCtrl.config.buttonOK }}"></span>' +
            '   </button>' +
            '</div>' +
            '');

        $templateCache.put('blocks/modal/confirm.tpl.html',
            '<div ac-modal-drag="{{ ::modalCtrl.config.enableDrag }}" class="modal-header {{ modalCtrl.config.headerClass }}">' +
            '   <button type="button" class="close" ng-click="modalCtrl.cancel()">' +
            '       <span class="glyphicon glyphicon-remove"></span>' +
            '   </button>' +
            '   <h4 class="modal-title">' +
            '       <span ng-class="modalCtrl.titleIcons"></span><span translate="{{ modalCtrl.config.modalTitle }}"></span>' +
            '   </h4>' +
            '</div>' +
            '' +
            '<div ac-modal-resize="{{ ::modalCtrl.config.enableResize }}" class="modal-body">' +
            '   <p ng-bind-html="modalCtrl.config.modalMsg | translate"></p>' +
            '</div>' +
            '' +
            '<div class="modal-footer">' +
            '   <button type="button" class="btn btn-{{modalCtrl.config.buttonClass}}" ac-autofocus ng-click="modalCtrl.confirm(modalCtrl.data)">' +
            '       <span translate="{{ modalCtrl.config.buttonOK }}"></span>' +
            '   </button>' +
            '   <button type="button" class="btn btn-{{modalCtrl.config.buttonClass}}" ng-click="modalCtrl.cancel(modalCtrl.data)">' +
            '       <span translate="{{ modalCtrl.config.buttonCancel }}"></span>' +
            '   </button>' +
            '</div>' +
            '');

        $templateCache.put('blocks/modal/modal.tpl.html',
            '<div ac-modal-drag="{{ ::modalCtrl.config.enableDrag }}" class="modal-header {{ modalCtrl.config.headerClass }}">' +
            '   <button type="button" class="close" ng-click="modalCtrl.cancel()">' +
            '       <span class="glyphicon glyphicon-remove"></span>' +
            '   </button>' +
            '   <h4 class="modal-title">' +
            '       <span ng-class="modalCtrl.titleIcons"></span><span translate="{{ modalCtrl.config.modalTitle }}"></span>' +
            '   </h4>' +
            '</div>' +
            '' +
            '<div class="modal-body">' +
            '   <div ng-if="modalCtrl.config.templateUrl" ' +
            '        ng-include="modalCtrl.config.templateUrl"' +
            '        ac-dynamic-controller="modalCtrl.config.controller"' +
            '        ac-dynamic-controllerAs="modalCtrl.config.controllerAs"' +
            '        ac-dynamic-init="modalCtrl.init">' +
            '   </div>' +
            '</div>' +
            '');
    }
})();
