const serviceUrl = `${__configuration.apiUrl}/massia/pays`;

export default class PaysService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getPays(filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async deletePaysById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async getPaysEnteteById(id) {
		const url = `${serviceUrl}/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async createPays(pays) {
		const data = {
			code: pays.entete.code,
			libelle: pays.entete.libelle,
			deviseId: pays.entete.deviseId,
			isMembreUE: pays.entete.isMembreUE
		};

		const result = await this.$http.post(serviceUrl, data);
		return result.data;
	}

	async updatePays(pays) {
		const data = pays.entete;

		const url = `${serviceUrl}/${pays.id}`;
		return await this.$http.put(url, data);
	}

	async codeExists(code) {
		const url = `${serviceUrl}/code-unicity/${code}`;
		const result = await this.$http.get(url);
		return result.data;
	}

	async getDevises(filters, sorts, pagination) {
		const deviseUrl = `${__configuration.apiUrl}/massia/devises`;
		const res = await this.$http.get(deviseUrl);

		return res.data;
	}
}
