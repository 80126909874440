export default function MonitoringSynchroController(SitesService, DomainesService) {
    this.SitesService = SitesService;
    this.DomainesService = DomainesService;

    this.$onInit = async () => {
        const sites = await SitesService.getSitesCommerciaux([], [], {
            skip: 0,
            take: 0
        });
        if (sites) {
            this.sites = sites.items;
        }

        const domaines = await DomainesService.getDomaines('gestion');
        if (domaines) {
            this.domaines = domaines;
        }
    };
}

MonitoringSynchroController.$inject = ['SitesService', 'DomainesService'];
