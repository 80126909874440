import NatureAdditionnalInfoController from './nature.additionnal.info.controller';

export default {
    bindings: {
        idNature: '<',
        onUpdate: '&',
        additionnalInfo: '<'
    },
    template: require('./templates/base.tpl.html'),
    controller: NatureAdditionnalInfoController
};
