CaracteristiqueDetailController.$inject = ['$state', 'notification', 'DomainesService', 'CaracteristiquesService', 'MassiaApplicationService'];

export default function CaracteristiqueDetailController($state, notification, DomainesService, CaracteristiquesService, MassiaApplicationService) {
    /* jshint validthis: true */
    const vm = this;

    // Propriétés
    vm.loading = true;
    vm.caracteristique = null;
    vm.exemple = {
        value: []
    };

    // Méthodes
    vm.loadDomaines = loadDomaines;

    // Evènements
    vm.$onInit = onInit();
    vm.$onChanges = angular.noop;
    vm.$doCheck = angular.noop;
    vm.$postLink = angular.noop;
    vm.$onDestroy = angular.noop;

    // Méthodes publiques
    function onInit() {
        loadCaracteristique();
        sortableOptions();
    }

    // Méthodes privées
    async function loadCaracteristique() {
        startLoading();
        try {
            vm.caracteristique = await CaracteristiquesService.getCaracteristique($state.params.id, MassiaApplicationService.getApplication());
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function loadDomaines(filters, sorts, pagination) {
        return CaracteristiquesService.getDomaines(vm.caracteristique.id, filters, sorts, pagination, MassiaApplicationService.getApplication());
    }

    function sortableOptions() {
        vm.dragOptions = {
            placeholder: 'table-row-ui-placeholder',
            revert: true,
            animation: 200,
            axis: 'y',
            'ui-preserve-size': true
        };
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }
}
