import validatorBuilder from 'validator-builder';
import ProspectEntete from '../prospect-entete/prospect.entete.model';

let prospectValidator = null;

export default class Prospect {
    constructor(data) {
        if (data) {
            this.entete = new ProspectEntete(data.entete);
        } else {
            this.entete = new ProspectEntete();
        }
    }

    isValid() {
        prospectValidator = prospectValidator || validatorBuilder.getInstanceFor('Prospect');
        const validationResults = prospectValidator.validate(this);
        return validationResults.isValid;
    }
}
