
export default class TicketCommentaireController {

    /* @ngInject */
    constructor($translate) {
      this.$translate = $translate
    }

    $onInit() {
		  this.ticket = this.resolve.ticket
      this.ticket.remarque = !!this.ticket.remarque ? this.ticket.remarque : this.$translate.instant('TICKETS.COM_MODAL.NONE')
      this.ticket.remarqueClient = !!this.ticket.remarqueClient ? this.ticket.remarqueClient : this.$translate.instant('TICKETS.COM_MODAL.NONE')
      this.ticket.remarqueUtilisateur = !!this.ticket.remarqueUtilisateur ? this.ticket.remarqueUtilisateur : this.$translate.instant('TICKETS.COM_MODAL.NONE')
    }

    close() {
        this.modalInstance.dismiss('close');
    }
}
