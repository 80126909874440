import { copy, equals } from 'angular';
import { HierarchicalLevelService } from '../../../../../../../core/http/hierarchical-level.service';
import { FilterType } from '../../../../../../../core/interface/FilterProperty';
import MassiaApplicationService from '../../../../../../massia/layout/components/massia-application/massia.application.service';
import { Critere } from '../../../../models/criteres.models';
import ModeleSelection from '../../../../models/modele.selection.models';
import SyntheseSettingService from '../../../../services/synthese.setting.service';
import SyntheseModelesService from '../../../../services/synthesemodeles.service';

/* @ngInject */
export default class SyntheseModeleFormController {
	isEditMode = false;
	loading = true;
	displayCriteres = [];
	selectedCritere = [];
	listeSyntheseCritereElement = [];
	dragOptions = {
		placeholder: 'table-row-ui-placeholder',
		animation: 200,
		axis: 'y',
		'ui-preserve-size': true
	};
	ongletOpen = {
		isEnteteOpen: true,
		isListeCriteresOpen: false
	};

	/**
	 *
	 * @param {angular.IScope} $scope
	 * @param {SyntheseModelesService} SyntheseModelesService
	 * @param {MassiaApplicationService} MassiaApplicationService
	 * @param {SyntheseSettingService} SyntheseSettingService
	 * @param {HierarchicalLevelService} HierarchicalLevelService
	 */
	constructor(
		$scope,
		$state,
		$stateParams,
		SyntheseModelesService,
		notification,
		$location,
		MassiaApplicationService,
		$translate,
		ModalService,
		FamillesService,
		SyntheseSettingService,
		HierarchicalLevelService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.SyntheseModelesService = SyntheseModelesService;
		this.notification = notification;
		this.$location = $location;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$translate = $translate;
		this.ModalService = ModalService;
		this.FamillesService = FamillesService;
		this.SyntheseSettingService = SyntheseSettingService;
		this.query = '';
		this.activite = MassiaApplicationService.getApplication(); //Current application
		this.scopeForTreeView($scope);
		$scope.modelValidations = {};
		this.HierarchicalLevelService = HierarchicalLevelService;
		this.modeleSelectionCodeExists;
	}

	async $onInit() {
		try {
			this.selectedHierarchicalLevel = [];
			await this.getTypeSyntheses();
			this.modeleSelection = await this.setSyntheseModele(this.$stateParams);
			await this.loadCriteres();
			this.duplicate();
			this.getSelectedCritere();
			this.loading = false;
		} catch (err) {
			console.error(err);
		}
	}

	$onDestroy() {
		if (this.unregister) {
			this.unregister();
		}
	}

	isCreateOrDuplicate() {
		return !this.$stateParams.id || this.$state.current.name.includes('duplicate');
	}

	duplicate() {
		// pour la duplication
		if (this.$stateParams.duplicate) {
			this.modeleSelection.id = undefined;
			this.modeleSelection.libelle += '_copie';

			if (this.modeleSelection.listeSyntheseCritereElement && this.modeleSelection.listeSyntheseCritereElement.length > 0) {
				for (let index = 0; index < this.modeleSelection.listeSyntheseCritereElement.length; index++) {
					const element = this.modeleSelection.listeSyntheseCritereElement[index];
					element.id = undefined;
				}
			}
		}
	}

	async getTypeSyntheses() {
		try {
			this.listeTypesSynthese = await this.SyntheseSettingService.loadTypeSynthese(this.activite);
		} catch (err) {
			console.error(err);
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}

	async setSyntheseModele(params) {
		try {
			if (params && params.id) {
				const stillThatSyntheseModele = await this.SyntheseModelesService.getSyntheseModeleById(params.id);
				const model = new ModeleSelection(stillThatSyntheseModele);
				if (this.$state.current.name.includes('duplicate')) {
					model.code += '_copie';
				}
				return model;
			}
			return new ModeleSelection();
		} catch (ex) {
			console.error(ex);
			this.notification.error(ex.data);
		}
	}

	async loadCriteres() {
		this.loadCritere = true;
		try {
			if (this.modeleSelection && this.modeleSelection.modelSelectionTypes) {
				if (!this.activite) {
					throw new Error('I000001');
				}
				const result = await this.SyntheseModelesService.getCriteresISteven(
					this.modeleSelection.modelSelectionTypes.map((x) => x.idTypeSynthese).join(','),
					'criteres',
					true
				);
				this.listIsteven = result;
			}
		} catch (err) {
			console.error(err);
			this.notification.error(err.data);
		}
		this.loadCritere = false;
	}

	getSelectedCritere() {
		if (this.modeleSelection.modelSelectionCriteres && this.modeleSelection.modelSelectionCriteres.length > 0) {
			for (let i = 0; i < this.modeleSelection.modelSelectionCriteres.length; i++) {
				const crit = this.modeleSelection.modelSelectionCriteres[i];
				crit.selected = true;
				this.addOrRemoveCritere(crit);
			}
		}
	}

	selectFamille(famille) {
		if (famille && famille.length > 0) {
			this.modeleSelection.famille.id = famille[0].idFamille;
			this.modeleSelection.famille.libelle = famille[0].nom;
			this.modeleSelection.famille.code = famille[0].code;
		} else {
			this.modeleSelection.famille.id = -1;
			this.modeleSelection.famille.libelle = null;
			this.modeleSelection.famille.code = null;
		}
	}

	onTypeSelect() {
		const tmp = [];
		for (let i = 0; i < this.selectedTypeSynthese.length; i++) {
			const type = this.selectedTypeSynthese[i];
			tmp.push({
				idTypeSynthese: type.id,
				libelle: type.name,
				application: type.app
			});
		}
		this.modeleSelection.modelSelectionTypes = tmp;
		this.loadCriteres();
	}

	async sauvegarder() {
		if (this.checkValidity()) {
			this.listeSyntheseCritereElement.forEach((critere, index) => {
				critere.ordre = index;
			});
			this.modeleSelection.modelSelectionCriteres = this.listeSyntheseCritereElement;
			try {
				if (this.modeleSelection.id) {
					await this.updateSyntheseModele();
					return this.modeleSelection.id;
				}
				const tmp = await this.createSyntheseModele();
				return tmp;
			} catch (ex) {
				this.notification.error(ex.data);
				return false;
			}
		} else {
			return false;
		}
	}

	async valider() {
		const res = await this.sauvegarder();
		if (res && res.id) {
			this.$state.go('synthese.model.edit', { id: res.id });
		}
	}
	async validerEtFermer() {
		const success = await this.sauvegarder();
		if (success) {
			this.annuler();
		}
	}

	annuler() {
		this.$state.go('synthese.model.list');
	}

	removeCritere(crit, index) {
		this.listeSyntheseCritereElement.splice(index, 1);
	}

	addOrRemoveCritere(crit) {
		const critere = new Critere(crit);
		critere.domainCode = crit.domainCode;
		const idx = this.listeSyntheseCritereElement.findIndex((x) => this.sameCritere(x, critere));
		if (crit.selected) {
			if (idx < 0) {
				this.listeSyntheseCritereElement.push(critere);
			}
		} else {
			if (idx >= 0) {
				this.listeSyntheseCritereElement.splice(idx, 1);
			}
		}
	}

	/**
	 *
	 * @param {Critere} x
	 * @param {Critere} object
	 */
	sameCritere(x, object) {
		return (
			x.champ === object.champ &&
			x.champComposition === object.champComposition &&
			x.idCaracteristique === object.idCaracteristique &&
			x.idDonneeSelectionable === object.idDonneeSelectionable &&
			x.typeSynthese === object.typeSynthese
		);
	}

	async createSyntheseModele() {
		try {
			const returnedId = await this.SyntheseModelesService.createSyntheseModele(this.modeleSelection);
			this.notification.success('SYNTHMODS.CREATED');
			return returnedId;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async updateSyntheseModele() {
		try {
			const returnedId = await this.SyntheseModelesService.updateSyntheseModele(this.modeleSelection);
			this.notification.success('SYNTHMODS.UPDATED');
			return returnedId;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	checkValidity() {
		this.$scope.$broadcast('modeleSelectionValidations');
		if (!this.unregister) {
			this.unregister = this.$scope.$watch(
				() => this.modeleSelection,
				() => this.checkValidity(),
				true
			);
		}
		return this.modeleSelection.isValid();
	}

	haveNiveauHierarchique() {
		return this.listeSyntheseCritereElement.some((x) => x.champ === 6 || x.champ === 104);
	}

	scopeForTreeView($scope) {
		$scope.toggle = function (scope, item) {
			if (
				(item.critereAffichageFieldDtos && item.critereAffichageFieldDtos.length > 0) ||
				(item.compositions && item.compositions.length > 0) ||
				(item.caracteristiques && item.caracteristiques.length > 0)
			) {
				scope.toggle();
			}
		};
		$scope.selectNode = (node) => {
			if (
				(!node.caracteristiques || node.caracteristiques.length === 0) &&
				(!node.compositions || node.compositions.length === 0) &&
				(!node.critereAffichageFieldDtos || node.critereAffichageFieldDtos.length === 0)
			) {
				node.selected = !node.selected;
				this.addOrRemoveCritere(node);
			}
		};
		$scope.visible = (item) => {
			return !(
				this.query &&
				this.query.length > 0 &&
				!(
					(item.searchProperty && item.searchProperty.toLowerInvariant().includes(this.query.toLowerInvariant())) ||
					(item.critereAffichageFieldDtos &&
						item.critereAffichageFieldDtos.length > 0 &&
						item.critereAffichageFieldDtos.filter((x) => x.searchProperty.toLowerInvariant().includes(this.query.toLowerInvariant()))
							.length > 0) ||
					(item.compositions &&
						item.compositions.length > 0 &&
						item.compositions.filter((x) => x.searchProperty.toLowerInvariant().includes(this.query.toLowerInvariant())).length > 0) ||
					(item.caracteristiques &&
						item.caracteristiques.length > 0 &&
						item.caracteristiques.filter((x) => x.searchProperty.toLowerInvariant().includes(this.query.toLowerInvariant())).length > 0)
				)
			);
		};

		$scope.collapse = (brd) => {
			$scope.$broadcast(brd);
		};

		$scope.checkSelectedCrit = async (node) => {
			const idx = this.listeSyntheseCritereElement.findIndex((x) => this.sameCritere(x, node));
			if (idx >= 0) {
				node.selected = true;
				this.listeSyntheseCritereElement[idx].domainCode = node.domainCode;
				this.listeSyntheseCritereElement[idx].label = node.searchProperty;
			}
		};
	}

	async loadNiveauHierarchique(crit) {
		let domaine = '';
		if (crit.champ === 104) {
			domaine = 'ProduitsStockes';
		}
		if (['SiteProd', 'SiteClient', 'SiteTransp', 'SiteUtil'].includes(crit.domainCode)) {
			domaine = 'Sites';
		}
		const res = await this.HierarchicalLevelService.getAll(
			[
				{
					criterion: 'NiveauParent',
					value: '###',
					type: FilterType.EQUALS
				},
				{
					criterion: 'Domaine.Code',
					value: domaine,
					type: FilterType.EQUALS
				}
			],
			[],
			{ skip: 0, take: 0 }
		);
		crit.niveauHierarchique = res.items;
	}

	// Arrow fx for bind this
	selectHierarchicalLevel = (crit, index, output, ii, input) => {
		if (!this.selectedHierarchicalLevel) {
			this.selectedHierarchicalLevel = [];
		}
		if (!this.selectedHierarchicalLevel[index]) {
			this.selectedHierarchicalLevel[index] = [];
		}
		if (!this.selectedHierarchicalLevel[index][ii]) {
			this.selectedHierarchicalLevel[index][ii] = [];
		}
		if (output[0]) {
			this.selectedHierarchicalLevel[index][ii] = output[0];
		} else if (this.selectedHierarchicalLevel[index][ii]) {
			this.unselectHierarchicalLevel(input);
			this.selectedHierarchicalLevel[index].splice(ii, this.selectedHierarchicalLevel[index].length - ii);
		}
		let last = null;
		if (this.selectedHierarchicalLevel[index]) {
			const l = this.selectedHierarchicalLevel[index].length;
			last = this.selectedHierarchicalLevel[index][l - 1];
		}
		this.listeSyntheseCritereElement[index].idNiveauHierarchique = last ? last.id : null;
	};

	unselectHierarchicalLevel(array) {
		let l = array.length;
		while (l--) {
			array[l].selected = false;
			if (array[l].children && array[l].children.length > 0) {
				this.unselectHierarchicalLevel(array[l].children);
			}
		}
	}

	getSelectedLevel(crit, array, index, ii = 0) {
		if (!this.selectedHierarchicalLevel) {
			this.selectedHierarchicalLevel = [];
		}
		if (!this.selectedHierarchicalLevel[index]) {
			this.selectedHierarchicalLevel[index] = [];
		}
		let l = array.length;
		let selected = false;
		while (l--) {
			if (array[l].children && array[l].children.length > 0) {
				selected = this.getSelectedLevel(crit, array[l].children, index, ii + 1);
				array[l].selected = selected;
				this.selectedHierarchicalLevel[index][ii] = array[l];
			}
			if (array[l].id === crit.idNiveauHierarchique) {
				array[l].selected = true;
				selected = true;
				this.selectedHierarchicalLevel[index][ii] = array[l];
			}
		}
		return selected;
	}

	async checkCodeUnicity(code) {
		this.modeleSelectionCodeExists = null;

		try {
			if (code.match(/^[a-zA-Z0-9_|]*$/)) {
				this.modeleSelectionCodeExists = await this.SyntheseModelesService.codeExists(code);
			}
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}
}
