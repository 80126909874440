import PlanImpressionPrixController from './plan.impression.prix.controller';

export default {
    bindings: {
        domaine: '@',
        clientId: '=',
        modalInstance: '='
    },
    template: require('./plan.impression.prix.html'),
    controller: PlanImpressionPrixController
};
