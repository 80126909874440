PaysController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'PaysService',
    'notification',
    '$transitions',
    'MOPService'
];

export default function PaysController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    PaysService,
    notification,
    $transitions,
    MOPService
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    vm.selectedPaysId = undefined;
    vm.lastSelected = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.pays = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.isPaysSelected = isPaysSelected;
    vm.loadPays = loadPays;
    vm.deletePays = deletePays;
    vm.setSelected = setSelected;
    vm.eraseAllFilters = eraseAllFilters;
    vm.MOPService = MOPService;

    vm.$onInit = () => {
        vm.MOPService.setMop([{ title: 'PAYS.BREADCRUMBS.PAYS_LIST', filename: 'Pays.pdf', application: 'massia' }]);
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    };

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    function stateChangeSuccessFunc(event, toState) {
        if (!$state.params.id) {
            vm.setSelected();
        } else if ($state.params.id) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    function setSelected(pays) {
        if (pays && pays.id) {
            vm.selectedPaysId = $stateParams.id;
            vm.lastSelected = vm.selectedPaysId;
        } else {
            vm.selectedPaysId = undefined;
        }
    }

    function shouldSetSelectedId() {
        return $state && $state.current && $state.current.name && $stateParams && $stateParams.id;
    }

    function isPaysSelected(pays) {
        return pays && pays.id && vm.selectedPaysId === pays.id;
    }

    async function loadPays(tableState) {
        startLoading();

        if ($state && $state.current && $state.current.name !== 'pays.list') {
            $state.go('pays.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.pays = [];

        try {
            const data = await PaysService.getPays(filters, sorts, pagination);

            vm.pays = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deletePays(pays) {
        if (pays /*&& pays.canDelete*/ && pays.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('PAYS.DELETE.TITLE', { code: pays.libelle }),
                modalMsg: $translate.instant('PAYS.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await PaysService.deletePaysById(pays.id);
                    notification.success($translate.instant('PAYS.DELETE.SUCCESS'));

                    vm.selectedPaysId = undefined;

                    if (vm.params.id) {
                        vm.state.go('pays.list');
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadPays();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function eraseAllFilters() {
        loadPays();
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
