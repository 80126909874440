import validatorBuilder from 'validator-builder';

let campagneEnteteValidator = null;

export default class CampagneEntete {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        //this.libelle = data.libelle;
        this.typeId = data.typeId;
        this.siteAuditeur = data.siteAuditeur;
        this.siteAuditeurId = data.siteAuditeurId;
        this.siteProducteur = data.siteProducteur;
        this.siteProducteurId = data.siteProducteurId;
        this.personneFaitPar = data.personneFaitPar;
        this.personneFaitParId = data.personneFaitParId;
        this.dateDebut = data.dateDebut;
        this.dateFin = data.dateFin;
        this.conformite = data.conformite;
        this.codeExists = data.codeExists || null;
        this.estCodeAuto = data.estCodeAuto;
        this.afficheCode = data.afficheCode;
        this.niveauVisibilite = 0;
    }

    isValid() {
        campagneEnteteValidator = campagneEnteteValidator || validatorBuilder.getInstanceFor('CampagneEntete');
        const validationResults = campagneEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
