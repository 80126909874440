export default class MesuresTypesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getAll(domaine) {
        let dom = domaine;
        if (dom == 'mesctrl') {
            dom = 'controles';
        }
        const url = `${__configuration.apiUrl}/massia/domaines/${dom}/types`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getCaracteristiques(id) {
        const url = `${__configuration.apiUrl}/massia/types/${id}/format-caracteristiques`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async GetNiveauVisibilite(id) {
        const url = `${__configuration.apiUrl}/massia/types/${id}/niveau-visibilite`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
