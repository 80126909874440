import validatorBuilder from 'validator-builder';

let paiementEnteteValidator = null;

export default class PaiementEntete {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.typeId = data.typeId || data.type;
        this.echeance = data.echeance;
        this.jourDuMois = parseInt(data.jourDuMois) || 0;
        this.modeCalcul = data.modeCalcul;
        this.echeanceUnite = data.echeanceUnite;
        this.isComptantBascule = data.isComptantBascule || false;
        this.codeExists = data.codeExists || null;
        this.codeInvalid = data.codeInvalid || null;
    }

    isValid() {
        paiementEnteteValidator = paiementEnteteValidator || validatorBuilder.getInstanceFor('PaiementEntete');
        const validationResults = paiementEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
