import UserRoute from './_config/portail.user.routes';
import userList from './components/user-list';
import userAdd from './components/user-add';
import AddContactComponent from './components/user-add/modal-add-contact';

const moduleName = 'app.massia.common.portail.user';

angular
    .module(moduleName, [])
    .config(UserRoute)
    .component('portailUserList', userList)
    .component('portailUserAdd', userAdd)
    .component('addContact', AddContactComponent);
export default moduleName;
