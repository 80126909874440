const apiUrl = `${__configuration.apiUrl}/massia/gestion/sites/importBLExternes`;

export default class SitesImportBLService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getAllImportBLExterne() {
        const res = await this.$http.get('assets/enum/sites/importBLExterne.json');
        return res.data;
    }
    //simuler une liste des BL enregistrés par utilisateur
    async getSiteImportBLExterne(id) {
        const res = await this.$http.get('assets/enum/sites/importBLExterneMOCK.json');
        return res.data;
    }
    async getSiteBLs(idSite) {
        const res = await this.$http.get(`${apiUrl}/${idSite}`);
        // utiliser angular.copy pour mise à jour data et vider le cahche
        return angular.copy(res.data);
    }
    async getSiteBLsById(idSite, idSiteBL) {
        const res = await this.$http.get(`${apiUrl}/${idSite}/${idSiteBL}`);
        return res.data;
    }
    async createSiteBL(idSite, data) {
        const res = await this.$http.post(`${apiUrl}/${idSite}`, data);
        return res.data;
    }
    async updateSiteBL(idSite, idSiteBL, data) {
        const res = await this.$http.put(`${apiUrl}/${idSite}/${idSiteBL}`, data);
        return res.data;
    }
    async deleteSiteBL(idSite, idSiteBL) {
        const res = await this.$http.delete(`${apiUrl}/${idSite}/${idSiteBL}`);
        return res.data;
    }
}
