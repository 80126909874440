export default class AddTransportModal {
    /* @ngInject */
    constructor() {}

    $onInit() {
        this.codeTransport = null;
        this.libelleTransport = null;
    }

    $onDestroy() {}

    annuler() {
        this.close({ $value: { isOk: false } });
    }

    ok() {
        this.close({ $value: { isOk: true, codeTransport: this.codeTransport, libelleTransport: this.libelleTransport } });
        // A rajouter pour impacter toutes les compos :", editAllCompos: this.editAllCompos"
    }
}
