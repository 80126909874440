import UtilisateurPassword from './utilisateur.password.model';

UtilisateurPasswordFormController.$inject = ['$scope', 'data', '$uibModalInstance', 'notification', 'PersonnesService'];

export default function UtilisateurPasswordFormController($scope, data, $uibModalInstance, notification, PersonnesService) {
    const vm = this;

    vm.loading = false;
    vm.utilisateurPassword = undefined;

    vm.confirmer = confirmer;
    vm.annuler = annuler;

    init();

    function init() {
        vm.utilisateurPassword = new UtilisateurPassword(data);
    }

    async function confirmer() {
        if (!vm.utilisateurPassword.isValid()) {
            $scope.$broadcast('utilisateurPasswordValidations');
            return;
        }

        try {
            startLoading();
            await PersonnesService.updatePassword(vm.utilisateurPassword.id, vm.utilisateurPassword.password);
            notification.success('UTILISATEURS.PASSWORD_UPDATED');
            close();
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function annuler() {
        close();
    }

    function close() {
        $uibModalInstance.close();
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }
}
