const serviceUrl = `${__configuration.apiUrl}/massia/gammes-produits`;

export default class GammesProduitsService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getGammesProduits(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
                // filtered: filtered
            }
        });
        return res.data;
    }

    async getGammeProduitById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async deleteGammeProduitById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async createGammeProduit(gamme) {
        const res = await this.$http.post(serviceUrl, gamme);
        gamme.id = res.data;
        return gamme;
    }

    async updateGammeProduit(gamme) {
        const url = `${serviceUrl}/${gamme.id}`;
        await this.$http.put(url, gamme);
        return gamme;
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }
}
