IndicesRevisionController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'IndicesRevisionService',
	'notification',
	'$transitions'
];

export default function IndicesRevisionController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	IndicesRevisionService,
	notification,
	$transitions
) {
	const vm = this;

	let previousTableState;
	let selectedIndiceRevisionId = undefined;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.indicesRevision = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectIndiceRevision = selectIndiceRevision;
	vm.isIndiceRevisionSelected = isIndiceRevisionSelected;
	vm.closeDetail = closeDetail;
	vm.loadIndicesRevision = loadIndicesRevision;
	vm.deleteIndiceRevision = deleteIndiceRevision;

	function init() {
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));
		if (shouldSetSelectedId()) {
			selectedIndiceRevisionId = $stateParams.id;
		}
		getTypes();
	}

	init();

	async function getTypes() {
		startLoading();
		try {
			vm.types = await IndicesRevisionService.getTypesArticles();
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			stopLoading();
		}
	}

	function stateChangeSuccessFunc(event, toState) {
		if (!$stateParams.id) {
			selectedIndiceRevisionId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return (
			$state &&
			$state.current &&
			$state.current.name &&
			$state.current.name === 'indices-revision.list.detail' &&
			$stateParams &&
			$stateParams.id
		);
	}

	function selectIndiceRevision(IndiceRevision) {
		if (IndiceRevision && IndiceRevision.id) {
			if (selectedIndiceRevisionId !== IndiceRevision.id) {
				showDetail(IndiceRevision.id);
			} else {
				//si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
				closeDetail();
			}
		}
	}

	function isIndiceRevisionSelected(IndiceRevision) {
		return IndiceRevision && IndiceRevision.id && selectedIndiceRevisionId === IndiceRevision.id;
	}

	function showDetail(IndiceRevisionId) {
		/*let modalInstance = ModalService.info({
            modalTitle: $translate.instant('INDICES_REVISION.DETAIL.MODAL_HEADER', {libelle: libelle}),
            modalMsg: this.getTooltipDroits(droits),
            headerClass: 'modal-info'
        });*/

		vm.slideMargin['margin-right'] = vm.slideWidth;
		selectedIndiceRevisionId = IndiceRevisionId;
		$state.go('indices-revision.list.detail', { id: IndiceRevisionId });
	}

	function closeDetail() {
		selectedIndiceRevisionId = undefined;
		$state.go('indices-revision.list');
	}

	async function loadIndicesRevision(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'indices-revision.list') {
			$state.go('indices-revision.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.indicesRevision = [];

		try {
			const data = await IndicesRevisionService.getIndicesRevision(filters, sorts, pagination);

			vm.indicesRevision = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteIndiceRevision(IndiceRevision) {
		if (IndiceRevision && IndiceRevision.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('INDICES_REVISION.DELETE.TITLE', { libelle: IndiceRevision.libelle }),
				modalMsg: $translate.instant('INDICES_REVISION.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await IndicesRevisionService.deleteIndiceRevisionById(IndiceRevision.id);
					notification.success($translate.instant('INDICES_REVISION.DELETE.SUCCESS'));
					selectedIndiceRevisionId = undefined;
					if (vm.params.id) {
						vm.state.go('indices-revision.list');
					}
					loadIndicesRevision();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
