InventaireStocksController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'StocksService',
	'notification',
	'MOPService'
];

export default function InventaireStocksController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	StocksService,
	notification,
	MOPService
) {
	const vm = this;

	let previousTableState;
	const selectedInventaireId = undefined;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.inventaires = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};

	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectInventaire = selectInventaire;
	vm.loadInventaires = loadInventaires;
	vm.deleteInventaire = deleteInventaire;
	vm.MOPService = MOPService;

	async function init() {
		vm.MOPService.setMop([{ title: 'STOCKS.BREADCRUMBS.INVENTAIRE_STOCKS', filename: 'GestiondesStocks.pdf', application: 'gestion' }]);
	}

	init();

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function selectInventaire(inventaire) {
		$state.go('stocks.inventaireedit', { id: inventaire.id });
	}

	async function loadInventaires(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'stocks.inventaires') {
			$state.go('stocks.inventaire');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.inventaires = [];

		try {
			const data = await StocksService.getInventaires(filters, sorts, pagination);
			vm.inventaires = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteInventaire(inventaire) {
		if (inventaire && inventaire.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('STOCKS.DELETE.TITLE', { code: inventaire.libelle }),
				modalMsg: $translate.instant('STOCKS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await StocksService.deleteInventaireById(inventaire.id);
					notification.success($translate.instant('STOCKS.DELETE.SUCCESS'));

					if (vm.params.id) {
						vm.state.go('chantiers.list');
					}

					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

					loadInventaires();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}
}
