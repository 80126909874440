import OffreDemande from './offre.demande.model';
import Contact from '../../../../common/personnes/components/mini-form/contact/mini.form.contact.model';

export default class OffreDemandeController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'OffresCommunicationService',
        'OffresService',
        'notification',
        '_',
        'ModalService',
        '$uibModal'
    ];

    newContact = new Contact();

    constructor($scope, $state, $stateParams, OffresCommunicationService, OffresService, notification, _, ModalService, $uibModal) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.OffresCommunicationService = OffresCommunicationService;
        this.OffresService = OffresService;
        this.notification = notification;
        this.ModalService = ModalService;
        this._ = _;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.unregisterReinit = this.OffresCommunicationService.registerReinit(() => this.initForm());
        this.societes = await this.OffresService.getSocietes();
        this.hasBeenCreated = false;

        if (!this.offre.demande.idSociete) {
            this.offre.demande.idSociete = this.societes[0].id;
        }

        this.loadClientsAvecCompteSurSociete();
        this.loadProspects();
        this.loadContacts();

        const _this = this;
        this.$scope.$watch('$ctrl.offre.demande.client', function (newValue, oldValue) {
            if (newValue != null) {
                _this.clientSelected = newValue;
                _this.offre.demande.idClient = newValue.id;
            }
        });

        this.getOffreDevise();
    }

    selectNewContact() {
        this.contacts.push({
            id: this.newContact.id,
            libelle: this.newContact.prenom + ' ' + this.newContact.nom
        });

        this.offre.demande.idContact = this.newContact.id;
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    async loadClientsAvecCompteSurSociete(search) {
        if (this.offre.demande.idSociete) {
            this.clients = await this.OffresService.getClientsAvecCompteSurSociete(this.offre.demande.idSociete, search);
        }

        angular.forEach(this.clients, function (client) {
            if (client.isGenerique == true) {
                client.libelle = client.libelle + ' (Générique)';
            }
        });

        return this.clients;
    }

    async loadProspects() {
        if (this.offre.demande.idSociete) {
            this.prospects = await this.OffresService.getProspectsBySociete(this.offre.demande.idSociete);
        }
    }

    async loadContacts() {
        if (this.offre.demande.client) {
            this.contacts = await this.OffresService.getContactsBySite(this.offre.demande.client.id);
        }
    }

    async changeSociete() {
        this.offre.demande.client = null;
        this.loadProspects();
        this.loadContacts();
        this.getOffreDevise();
    }

    async changeClient() {
        this.loadContacts();
    }

    async getOffreDevise() {
        if (this.offre.demande.idSociete) {
            const selectedSociete = await this.OffresService.getSocieteById(this.offre.demande.idSociete);
            if (selectedSociete.paysId) {
                const pays = await this.OffresService.getPaysById(selectedSociete.paysId);
                this.offre.devise = pays.symboleDevise;
            }
        }
    }

    openProspect() {
        const _this = this;
        this.$stateParams.fromOffre = true;
        this.$uibModal
            .open({
                template: '<prospect-form modal-instance="$ctrl.uibModalInstance" id-societe="$ctrl.idSociete"></prospect-form>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.uibModalInstance = $uibModalInstance;
                        $ctrl.idSociete = _this.offre.demande.idSociete;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl',
                backdrop: false // il faut cliquer sur fermer pour fermer -> controle sur le rechargement des prospects
            })
            .result.then(
                async function (result) {
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                    if (result) {
                        // recharger la liste des prospects lorsqu'on ferme ou qu'on valide + ferme
                        await _this.loadProspects();
                        if (result) {
                            _this.offre.demande.idProspect = _this.prospects[0].id;
                        }
                    }
                },
                function (reason) {}
            );
    }
}
