import DocumentFormController from './document.form.controller';

export default {
    bindings: {
        modalInstance: '=',
        idOffre: '=',
        docfromoffre: '='
    },
    template: require('./document.form.html'),
    controller: DocumentFormController
};
