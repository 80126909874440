(function (angular, undefined) {
    'use strict';

    angular.module('blocks.helpers').service('javascriptHelper', javascriptHelper);

    function javascriptHelper() {
        /* jshint validthis: true */
        var service = this;

        service.removeDiacritic = removeDiacritic;
        service.padLeft = padLeft;
        service.hexToRgb = hexToRgb;

        function removeDiacritic(str) {
            if (str !== undefined && str !== null) {
                str = str.toLowerCase();
                str = str.replace(/\\s/g, '');
                str = str.replace(/[àáâãäå]/g, 'a');
                str = str.replace(/æ/g, 'ae');
                str = str.replace(/ç/g, 'c');
                str = str.replace(/[èéêë]/g, 'e');
                str = str.replace(/[ìíîï]/g, 'i');
                str = str.replace(/ñ/g, 'n');
                str = str.replace(/[òóôõö]/g, 'o');
                str = str.replace(/œ/g, 'oe');
                str = str.replace(/[ùúûü]/g, 'u');
                str = str.replace(/[ýÿ]/g, 'y');
                str = str.replace(/\\W/g, '');
            }
            return str;
        }

        function padLeft(value, pad) {
            var stringValue = value.toString();
            return pad.substring(0, pad.length - stringValue.length) + stringValue;
        }

        function hexToRgb(hex) {
            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function (m, r, g, b) {
                return r + r + g + g + b + b;
            });

            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16)
                } : null;
        }
    }
})(angular);
