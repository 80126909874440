import SyntheseEntitiesAffichageController from './syntheseentities.affichage.controller';

export default {
    bindings: {
        syntheseEntity: '=',
        forGeneration: '=',
        ongletOpen: '='
    },
    template: require('./syntheseentities.affichage.html'),
    controller: SyntheseEntitiesAffichageController
};
