(function () {
    'use strict';

    angular
        .module('blocks.modal')
        .config(AppConfig);
    AppConfig.$inject = ['$translatePartialLoaderProvider'];

    /* @ngInject */
    function AppConfig($translatePartialLoaderProvider) {
        $translatePartialLoaderProvider.setPart('fr-FR', 'blocks.modal', {
            "BUTTON_OK": "OK",
            "BUTTON_CANCEL": "Annuler",
            "BUTTON_YES": "Oui",
            "BUTTON_NO": "Non"
        });

        $translatePartialLoaderProvider.setPart('en-US', 'blocks.modal', {
            "BUTTON_OK": "OK",
            "BUTTON_CANCEL": "Cancel",
            "BUTTON_YES": "Yes",
            "BUTTON_NO": "No"
        });

        $translatePartialLoaderProvider.setPart('es-ES', 'blocks.modal', {
            "BUTTON_OK": "Bueno",
            "BUTTON_CANCEL": "Anular",
            "BUTTON_YES": "Si",
            "BUTTON_NO": "No"
        });

        $translatePartialLoaderProvider.setPart('nl-BE', 'blocks.modal', {
            "BUTTON_OK": "OK",
            "BUTTON_CANCEL": "Annuleren",
            "BUTTON_YES": "Ja",
            "BUTTON_NO": "Niet"
        });

        $translatePartialLoaderProvider.addPart('blocks.modal');
    }
})();