import PrevisionnelTourneeConfig from './_config/previsionnel.tournee.config';
import PrevisionnelTourneeRoutes from './_config/previsionnel.tournee.routes';

import PrevisionnelTourneeComponent from './components/previsionnel-tournee';

import PrevisionnelTourneeService from './services/previsionnel.tournee.service';

const moduleName = 'app.massia.gestion.plannings.previsionnel.tournee';

angular
    .module(moduleName, [])
    .config(PrevisionnelTourneeConfig)
    .config(PrevisionnelTourneeRoutes)
    .service('PrevisionnelTourneeService', PrevisionnelTourneeService)
    .component('previsionnelTournee', PrevisionnelTourneeComponent);

export default moduleName;
