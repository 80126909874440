export default class DeviseDetailController {
    static $inject = ['$stateParams', '$state', 'DevisesService', 'notification', 'DevisesCommunicationService'];

    constructor($stateParams, $state, DevisesService, notification, DevisesCommunicationService) {
        this.params = $stateParams;
        this.$state = $state;
        this.DevisesService = DevisesService;
        this.notification = notification;
        this.loading = false;
        this.devise = {};
        this.deviseId = undefined;
        this.DevisesCommunicationService = DevisesCommunicationService;

        //par défaut, on ouvert l'onglet "Entête"
        this.ongletOpen = {
            isEnteteOpen: true
        };
    }

    async $onInit() {
        this.deviseId = this.params.id;

        this.startLoading();

        try {
            this.devise.entete = await this.DevisesService.getDeviseEnteteById(this.deviseId);
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('devises.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
