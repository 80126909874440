export default class HttpPendingService {
    static $inject = ['_'];

    constructor(_) {
        this._ = _;
        this._pendingRequests = [];
    }

    add(url, canceller) {
        this._pendingRequests.push({ url: url, canceller: canceller });
    }

    remove(url) {
        this._.remove(this._pendingRequests, { url: url });
    }

    get(url) {
        return this._.find(this._pendingRequests, { url: url });
    }

    getAll() {
        return this._pendingRequests;
    }

    cancel(url) {
        const request = this.get(url);
        if (request && request.canceller) {
            request.canceller.resolve();
        }
    }

    cancelAll() {
        this._.forEach(this._pendingRequests, (request) => {
            if (request && request.canceller) {
                request.canceller.resolve();
                this.remove(request.url);
            }
        });
    }
}
