import GammeProduit from './gamme.produit.model';

export default class GammeProduitFormController {
    static $inject = [
        '_',
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'GammesProduitsService',
        'notification',
        '$location',
        '$anchorScroll',
        'MassiaApplicationService',
        '$uibModal',
        'FamillesService',
        'MOPService'
    ];

    constructor(
        _,
        $scope,
        $timeout,
        $state,
        $stateParams,
        GammesProduitsService,
        notification,
        $location,
        $anchorScroll,
        MassiaApplicationService,
        $uibModal,
        FamillesService,
        MOPService
    ) {
        this._ = _;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.GammesProduitsService = GammesProduitsService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$uibModal = $uibModal;
        this.FamillesService = FamillesService;
        this.MOPService = MOPService;
    }

    $onInit() {
        this.MOPService.setMop([
            { title: 'GAMMES_PRODUITS.BREADCRUMBS.GAMME_PRODUITS_LIST', filename: 'GammeDeProduits.pdf', application: 'laboratoire' }
        ]);
        this.loading = false;
        this.form = {};
        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.gammeProduit = {};

        this.reset();
    }

    $onDestroy() {
        this.MOPService.resetMop();
    }

    async reset() {
        this.startLoading();

        // let data = {
        //     id: this.$stateParams.id
        // };

        // if (data.id) {
        //     try {
        //         data = await this.GammesProduitsService.getGammeProduitById(data.id);
        //     } catch (err) {
        //         if (err.data) this.notification.error(err.data);
        //         else throw err;
        //         this.annuler();
        //     }
        // }

        if (this.$stateParams.gamme) {
            this.gammeProduit = new GammeProduit(this.$stateParams.gamme);
        } else if (this.$stateParams.id) {
            const data = await this.GammesProduitsService.getGammeProduitById(this.$stateParams.id);
            this.gammeProduit = new GammeProduit(data);
        } else {
            this.gammeProduit = new GammeProduit({});
        }

        this.listFamilles = await this.getFamillesList();

        this.stopLoading();
    }

    async getFamillesList() {
        try {
            const familles = await this.FamillesService.getFamilles();
            return familles;
        } catch (error) {
            this.notification.error(error);
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.gammeProduit.codeExists = null;
            return;
        }
        try {
            this.gammeProduit.codeExists = await this.GammesProduitsService.codeExists(code);
        } catch (ex) {
            this.notification.error(ex.data);
            this.gammeProduit.codeExists = false;
        }
    }

    selectFamille(data, listFamilles) {
        if (data) {
            if (data.idFamille !== this.gammeProduit.idFamille) {
                this.gammeProduit.idFamille = data.idFamille;
            } else {
                listFamilles.map((e) => {
                    if (data.idFamille === this.gammeProduit.idFamille) {
                        return (e.selected = !e.selected);
                    }
                });

                this.gammeProduit.idFamille = null;
            }
        }
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();

            try {
                let id = false;
                if (this.gammeProduit.id) {
                    await this.GammesProduitsService.updateGammeProduit(this.gammeProduit);
                    this.notification.success('GAMMES_PRODUITS.UPDATED');
                    id = this.gammeProduit.id;
                } else {
                    const data = await this.GammesProduitsService.createGammeProduit(this.gammeProduit);
                    id = data.id;
                    this.notification.success('GAMMES_PRODUITS.CREATED');
                }

                return id;
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async appliquer() {
        const id = await this.sauvegarder();
        this.gammeProduit.id = id;
        this.$state.go('gammes-produits.edit', { id: id, gamme: this.gammeProduit });
    }

    async confirmer() {
        const success = await this.sauvegarder();
        if (success) {
            this.annuler();
        }
    }

    async annuler() {
        this.$state.go('gammes-produits.list');
    }

    checkValidity() {
        const validity = this.gammeProduit.isValid();
        return validity;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
