(function () {
    'use strict';

    /* Exemple d'utilisation
     * ac-truncate-ellipsis="item.title"
     * ac-truncate-ellipsis-truncate="25"
     * ac-truncate-ellipsis-screen-width="1575"
     * ac-truncate-ellipsis-default="50"
     */

    angular.module('blocks.helpers')
        .directive('acTruncateEllipsis', acTruncateEllipsis);
    acTruncateEllipsis.$inject = ['$window', 'domHelper', '$parse'];

    function acTruncateEllipsis($window, domHelper, $parse) {
        var w = angular.element($window);

        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var truncateWidth = attrs.acTruncateEllipsisScreenWidth;
                var truncateFrom = attrs.acTruncateEllipsisTruncate;
                var originalText = $parse(attrs.acTruncateEllipsis)(scope);
                var truncateDefault = attrs.acTruncateEllipsisDefault;
                var unregisterDestroyEvent = scope.$on('$destroy', dispose);

                w.bind('resize', resizeEvent);

                function resizeEvent (event) {
                    // si la taille de la fenêtre est en dessous de la valeur choisie
                    if (w[0].innerWidth <= truncateWidth) {
                        // on truncate le texte
                        var truncated = domHelper.truncateEllipsis(originalText, truncateFrom);
                        element.html(truncated);
                    }
                    else if(element.html() !== originalText) {
                        // sinon on affiche l'original truncated par la valeur par défaut (sinon on affiche l'original)
                        var truncatedByDefault = domHelper.truncateEllipsis(originalText, truncateDefault);
                        element.html(truncatedByDefault);
                    }
                }

                function dispose() {
                    if(unregisterDestroyEvent !== undefined) {
                        unregisterDestroyEvent();
                    }
                    w.unbind('resize', resizeEvent);
                }
            }
        };
    }
})();
