TypeBasculesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'TypeBasculesService',
	'notification',
	'$transitions'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function TypeBasculesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	TypeBasculesService,
	notification,
	$transitions
) {
	const vm = this;

	let previousTableState;
	let selectedTypeBasculeId = undefined;
	const watchers = [];

	vm.loading = false;
	vm.stateParams = $stateParams;
	vm.typeBascules = [];
	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectTypeBascule = selectTypeBascule;
	vm.isTypeBasculeSelected = isTypeBasculeSelected;
	vm.closeDetail = closeDetail;
	vm.loadTypeBascules = loadTypeBascules;
	vm.deleteTypeBascule = deleteTypeBascule;
	vm.setSelected = setSelected;

	function init() {
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "type-bascules.list", on désélectionne la ligne sélectionnée (s'il y en a)
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		//quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	init();

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(typeBascule) {
		if (typeBascule && typeBascule.id) {
			vm.selectedTypeBasculeId = $stateParams.id;
			vm.lastSelected = vm.selectedTypeBasculeId;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			vm.selectedTypeBasculeId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state.current.name === 'type-bascules.list.detail' && $stateParams.id;
	}

	function selectTypeBascule(typeBascule) {
		if (typeBascule && typeBascule.id) {
			if (vm.selectedTypeBasculeId != typeBascule.id) {
				vm.selectedTypeBasculeId = typeBascule.id;
				showDetail(typeBascule.id);
			} else {
				vm.selectedTypeBasculeId = undefined;
				closeDetail();
			}
		}
	}

	function isTypeBasculeSelected(typeBascule) {
		return typeBascule && typeBascule.id && selectedTypeBasculeId === typeBascule.id;
	}

	function showDetail(typeBasculeId) {
		$state.go('type-bascules.list.detail', { id: typeBasculeId });
	}

	function closeDetail() {
		selectedTypeBasculeId = undefined;
		$state.go('type-bascules.list');
	}

	async function loadTypeBascules(tableState) {
		startLoading();

		//quand on rafraîchit la grille, on revient sur "type-bascules.list", et on ferme la vue détail d'un typeBascule
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== 'type-bascules.list') {
			$state.go('type-bascules.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.typeBascules = [];

		try {
			const data = await TypeBasculesService.getTypeBascules(filters, sorts, pagination);

			vm.typeBascules = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteTypeBascule(typeBascule) {
		if (typeBascule && typeBascule.canDelete && typeBascule.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('TYPEBASCULES.DELETE.TITLE', { code: typeBascule.libelle }),
				modalMsg: $translate.instant('TYPEBASCULES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await TypeBasculesService.deleteTypeBasculeById(typeBascule.id);
					notification.success($translate.instant('TYPEBASCULES.DELETE.SUCCESS'));
					selectedTypeBasculeId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
					if (vm.stateParams.id) {
						$state.go('type-bascules.list');
					}

					loadTypeBascules();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
