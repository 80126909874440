const formesPointByValue = {
    0: 'aucun',
    1: 'cercle',
    2: 'plus',
    3: 'croix',
    4: 'tiret',
    5: 'ligne',
    6: 'rectangle',
    7: 'rectangle_arrondi',
    8: 'losange',
    9: 'etoile',
    10: 'triangle'
};

const formesPointValuesByName = {
    aucun: 0,
    ligne: 1,
    plus: 2,
    croix: 3,
    tiret: 4,
    ligne: 5,
    rectangle: 6,
    rectangle_arrondi: 7,
    losange: 8,
    etoile: 9,
    triangle: 10
};

export function getFormePointNamesByValues() {
    return angular.copy(formesPointByValue);
}

export function getFormePointNamesWithValues() {
    return angular.copy(formesPointValuesByName);
}
