(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.datepicker')
        .factory('AcDatepickerService', AcDatepickerService);

    AcDatepickerService.$inject = ['moment', 'globalizationManagementService'];

    function AcDatepickerService(moment, globalizationManagementService) {

        const dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
        const displayDateMask = globalizationManagementService.getCurrentLanguage().displayDateMask;

        var defaultConfig = {
            datepickerPopupTemplateUrl: 'ac-datepicker.popup.html',
            format: displayDateMask,
            onDateSelected: angular.noop,
            outputFormat: dateFormat,
            showButtonBar: true,
            clearText: 'CLEAR',
            currentText: 'TODAY',
            closeText: 'CLOSE',
            type: 'text',
            closeOnDateSelection: true,
            outputMode: 'string', // 'date' 'moment' 'string'

            //contient les options de configuration pour le datepicker en lui-même, voir la doc de ui.bootstrap
            datepickerOptions: {}
        };

        var service = {
            getDefaultConfig: getDefaultConfig,
            getMomentFromOutputValue: getMomentFromOutputValue,
            isValidMoment: isValidMoment,
            compareDates: compareDates,
            validDate: validDate
        };

        return service;

        function getDefaultConfig() {
            return angular.copy(defaultConfig);
        }

        function getMomentFromOutputValue(output, config) {
            var value = output;

            if (moment.isMoment(output)) {
                value = output;
            }
            else if (angular.isDate(output)) {
                value = moment(output);
            }
            else if (angular.isString(output)) {
                // output est un string construit depuis
                // config.outputFormat
                value = moment(output, config.outputFormat);
            }

            return value;
        }

        function isValidMoment(momentValue) {
            return moment.isMoment(momentValue) && momentValue.isValid();
        }

        function compareDates(_config, _otherDatePropertyName, _strict, _compareIsBefore) {
            var config = _config;
            var propertyName = _otherDatePropertyName;
            var strict = _strict;
            var compareIsBefore = _compareIsBefore;

            return function (obj, prop) {
                if (obj && obj[propertyName] && prop) {
                    var propMoment = service.getMomentFromOutputValue(prop, config);
                    var otherMoment = service.getMomentFromOutputValue(obj[propertyName], config);

                    var datesAreValid = service.isValidMoment(propMoment) && service.isValidMoment(otherMoment);

                    var comparison = compareIsBefore ? datesAreValid && propMoment.isBefore(otherMoment, 'day') : datesAreValid && propMoment.isAfter(otherMoment, 'day');

                    var isSame = datesAreValid && propMoment.isSame(otherMoment, 'day');

                    return strict ? (comparison) : (comparison || isSame);
                }

                return true;
            };
        }

        function validDate(config) {
            return function (obj, prop) {
                var ret;
                if (prop !== undefined && prop !== null && prop !== '') {
                    var date = service.getMomentFromOutputValue(prop, config);

                    ret = service.isValidMoment(date);
                }
                else {
                    ret = true;
                }

                return ret;
            };
        }
    }
})(angular);