import MassiaRoutes from './massia.common.routes';
import MassiaConfig from './massia.common.config';
//import Chart from 'chart.js';
//import ChartDataLabels from 'chartjs-plugin-datalabels';

const moduleName = 'app.massia.common.configuration';

angular.module(moduleName, []).config(MassiaConfig).config(MassiaRoutes);

//Chart.plugins.unregister(ChartDataLabels);

export default moduleName;
