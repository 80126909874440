export default class ProduitsCommerciauxCalculModalController {
    /* @ngInject */
    constructor(FormuleCalculsService, FormuleService, ProduitsService, notification, $translate) {
        this.FormuleCalculsService = FormuleCalculsService;
        this.FormuleService = FormuleService;
        this.ProduitsService = ProduitsService;
        this.notification = notification;
        this.$translate = $translate;

        this.msg = '';
    }

    async $onInit() {
        this.startLoading();

        if (this.resolve.produit) {
            this.formule = await this.loadFormules();
        }
        if (this.resolve.parametresFormulation) {
            this.parametresFormulation = this.resolve.parametresFormulation;
        }
        if (this.formule) {
            this.formule.produitCommerciaux = [];
            if (this.resolve.produitCc) {
                this.formule.produitCommerciaux.push(this.resolve.produitCc);
            }
            await this.launchCalcul();
        } else {
            this.msg = this.$translate.instant('PRODUITS.MODAL_CC.FORMULE_NOK');
        }
        //console.log(angular.copy(this.formule));
        this.stopLoading();
    }

    async launchCalcul() {
        this.FormuleCalculsService.init(this.formule, this.parametresFormulation);

        this.formule.produits = await this.FormuleCalculsService.getProduitFormule();
        const valeursDeReferenceCiments = [];
        const valeursDeReferenceAdditions = [];
        const valeursDeReferenceAdjuvants = [];
        const valeursDeReferenceGranulats = [];
        const valeursDeReferenceEaux = [];
        this.formule.composants
            .filter((e) => !e.valeursDeRef)
            .forEach((composant) => {
                for (let i = 0; i < composant.valeursDeRef.length; i++) {
                    switch (composant.codeFamille) {
                        case 'CIM':
                            valeursDeReferenceCiments.push(composant.valeursDeRef[i]);
                            break;
                        case 'ADD':
                            valeursDeReferenceAdditions.push(composant.valeursDeRef[i]);
                            break;
                        case 'ADJ':
                            valeursDeReferenceAdjuvants.push(composant.valeursDeRef[i]);
                            break;
                        case 'GRAN':
                            valeursDeReferenceGranulats.push(composant.valeursDeRef[i]);
                            break;
                        case 'EAU':
                            valeursDeReferenceEaux.push(composant.valeursDeRef[i]);
                            break;
                    }
                }
            });
        this.formule.composantCiments = await this.FormuleCalculsService.recuperationCiment(valeursDeReferenceCiments);
        this.formule.composantAdditions = await this.FormuleCalculsService.recuperationAddition(valeursDeReferenceAdditions);
        this.formule.composantAdjuvants = await this.FormuleCalculsService.recuperationAdjuvant(valeursDeReferenceAdjuvants);
        this.formule.composantGranulats = await this.FormuleCalculsService.recuperationGranulat(valeursDeReferenceGranulats);
        this.formule.composantEaux = await this.FormuleCalculsService.recuperationEau(valeursDeReferenceEaux);

        /**
         * recuperation classe expo et classe de resistance
         */
        const produitCc = await this.ProduitsService.getProduitValeursCaracteristiquesById(this.resolve.produitCc.id);
        this.formule.produitCommerciaux = [produitCc];

        this.formule.classeExpo = await this.FormuleCalculsService.recuperationConcreteCodeExpo();
        this.formule.classeResistance = await this.FormuleCalculsService.recuperationConcreteCodeResistance();
        this.formule.dosageCiment = await this.FormuleCalculsService.recuperationConcreteCodeCiment();
        this.formule.classeConsistance = await this.FormuleCalculsService.recuperationConcreteCodeConsistance();

        this.formule.vdc = await this.FormuleCalculsService.getValeurDeControle(false);

        await this.FormuleCalculsService.assignationPourAffichage();
        await this.FormuleCalculsService.initCalcul();

        this.formule.typeCiment = this.checkTypeCiment();
        this.formule.typeAddition = this.checkTypeAddition();
        this.formule.dMax = this.checkDMax();

        if (this.formule.classeResistance) {
            this.checkClasseResistance();
        }
        /*if (this.formule.classeExpo) {
            this.checkClasseExpo();
        }*/

        this.FormuleCalculsService.resetCache();
        await this.checkConformiterAlerte();
        this.checkConformiteFormule();
        // console.log(this.formule);
    }

    checkConformiterAlerte() {
        if (this.formule && this.formule.normeSpec) {
            if (this.formule.normeSpec.id && this.formule.vdc && this.formule.vdc.length > 0) {
                for (let i = 0; i < this.formule.vdc.length; i++) {
                    const vdc = this.formule.vdc[i];
                    if (Number.parseFloat(vdc.calcul) && Number.parseFloat(vdc.seuil)) {
                        if (vdc.comparateur === ' >= ') {
                            vdc.conformite = null;
                            if (Number.parseFloat(vdc.calcul) >= Number.parseFloat(vdc.seuil)) {
                                vdc.conformite = true;
                            } else {
                                vdc.conformite = false;
                            }
                        } else if (vdc.comparateur === ' <= ') {
                            if (Number.parseFloat(vdc.calcul) <= Number.parseFloat(vdc.seuil)) {
                                vdc.conformite = true;
                            } else {
                                vdc.conformite = false;
                            }
                        }
                    }
                }
                return this.formule;
            }
        } else {
            return (this.msg = this.$translate.instant('PRODUITS.MODAL_CC.NORME_NOK'));
        }
    }

    checkConformiteFormule() {
        if (this.formule && this.formule.vdc && this.formule.vdc.length > 0) {
            const idxLocked = this.formule.vdc.findIndex((x) => x.blocageAlerte === 2 && x.conformite === false);
            const idxWarning = this.formule.vdc.findIndex((x) => x.blocageAlerte === 1 && x.conformite === false);
            if (idxLocked > -1) {
                return (this.formule.locked = true);
            }
            if (idxWarning > -1) {
                return (this.formule.warning = true);
            }
        }
    }

    /** FORMULES */
    async loadFormules() {
        try {
            if (this.resolve.produit && this.resolve.produit.formules && this.resolve.produit.formules.length > 0) {
                const formule = this.resolve.produit.formules.find((x) => !x.dateArchivage);
                if (formule) {
                    return await this.FormuleService.getFormuleById(formule.id, formule.producteur.id);
                }
            }
        } catch (ex) {
            this.notification.error(ex);
        }
    }

    checkTypeCiment() {
        if (this.formule && this.formule.produitCommerciaux && this.formule.produitCommerciaux.length > 0) {
            const ciment = this.formule.produitCommerciaux[0].find((x) => x.code === 'CEM_TYPE' && x.value && x.value.key);
            if (ciment) {
                if (this.formule.composants && this.formule.composants.length > 0) {
                    const valTypeCim = this.formule.composants
                        .find((x) => x.codeFamille === 'CIM')
                        .valeurCaracteristiques.find((c) => c.code === 'CEM_TYPE').value;
                    if (valTypeCim && ciment && valTypeCim === ciment.value.key) {
                        ciment.conformite = 1;
                    } else if (!valTypeCim || !ciment || !ciment.value || !ciment.value.key) {
                        ciment.conformite = -1;
                    } else {
                        ciment.conformite = 0;
                        this.formule.locked = true;
                    }
                }
                return ciment;
            }
        }
    }

    checkTypeAddition() {
        if (this.formule && this.formule.produitCommerciaux && this.formule.produitCommerciaux.length > 0) {
            const addition = this.formule.produitCommerciaux[0].find((x) => x.code === 'ADD_TYPE' && x.value && x.value.key);
            if (addition) {
                if (this.formule.composants && this.formule.composants.length > 0) {
                    const compoAdd = this.formule.composants.find((x) => x.codeFamille === 'ADD');
                    if (compoAdd && compoAdd.valeurCaracteristiques) {
                        const valTypeAdd = compoAdd.valeurCaracteristiques.find((c) => c.code === 'ADD_TYPE').value;
                        if (valTypeAdd && addition && addition.value && valTypeAdd === addition.value.key) {
                            addition.conformite = 1;
                        } else if (!valTypeAdd || !addition || !addition.value || !addition.value.key) {
                            addition.conformite = -1;
                        } else {
                            addition.conformite = 0;
                            /*   HOTFIX 1724 pour EQIOM pour debloquer temporairement
                            this.formule.locked = true; */
                        }
                    }
                }
                return addition;
            }
        }
    }

    checkDMax() {
        if (this.formule && this.formule.produitCommerciaux && this.formule.produitCommerciaux.length > 0) {
            const DMax = this.formule.produitCommerciaux[0].find((x) => x.code === 'DMax' && x.value && x.value.key);

            if (DMax) {
                if (this.formule.vdc && this.formule.vdc.length > 0) {
                    let vdc = this.formule.vdc.find((v) => v.code === 'DM').calcul;
                    //arrondir Dmax de la formule en entier (Dmax22 = 22.4, 32=31.5...)
                    if (vdc) {
                        if (Number.parseFloat(vdc) === 12.5) {
                            vdc = 12;
                        } else if (Number.parseFloat(vdc) === 5.6) {
                            vdc = 5;
                        } else {
                            vdc = Number.parseFloat(vdc.toFixed(0));
                        }
                    }
                    if (vdc && DMax && DMax.value) {
                        const valCarac = Number.parseFloat(DMax.value.key.replace('D', ''));
                        if (valCarac && vdc && valCarac === vdc) {
                            DMax.conformite = 1;
                        } else if (!valCarac || !vdc) {
                            DMax.conformite = -1;
                        } else {
                            DMax.conformite = 0;
                            this.formule.locked = true;
                        }
                    }
                }
                return DMax;
            }
        }
    }

    checkClasseResistance() {
        if (this.formule && this.formule.normeSpec && this.formule.classeResistance) {
            const arrRes = this.formule.classeResistance.element.replace('C', '').split('/');
            const classeResistanceCompare = this.formule.normeSpec.code === 'DNA_LUX' ? Number.parseFloat(arrRes[1]) : Number.parseFloat(arrRes[0]);
            if (this.formule.resistanceVisee && classeResistanceCompare && classeResistanceCompare <= this.formule.resistanceVisee) {
                this.formule.classeResistance.conformite = 1;
            } else if (!this.formule.resistanceVisee) {
                this.formule.classeResistance.conformite = -1;
            } else {
                this.formule.classeResistance.conformite = 0;
                this.formule.locked = true;
            }
        } else {
            this.formule.classeResistance.conformite = -1;
        }
    }

    /*checkClasseExpo() {
        if (this.formule && this.formule.classeExpo && this.formule.produits) {
            let classeExpoProduit = this.formule.produits.produitCaracteristique.find(x => x.id === this.formule.classeExpo.idCaracteristique);
            if (classeExpoProduit && classeExpoProduit.value && this.formule.classeExpo.code === classeExpoProduit.value) {
                this.formule.classeExpo.conformite = 1;
            } else if (!classeExpoProduit || !classeExpoProduit.value) {
                this.formule.classeExpo.conformite = -1;
            } else {
                this.formule.classeExpo.conformite = 0;
                this.formule.locked = true;
            }
        } else {
            this.formule.classeExpo.conformite = -1;
        }
    }*/

    annuler() {
        if (this.formule) {
            //reset de l'état précédent
            delete this.formule.locked;
            delete this.formule.warning;
        }
        this.dismiss();
    }

    ok() {
        this.close();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
