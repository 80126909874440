import IndicesRevisionGeneration from './indices.revision.generation.model';

export default class IndicesRevisionGenerationController {
    static $inject = [
        '$scope',
        //'$timeout',
        '$state',
        '$stateParams',
        'IndicesRevisionService',
        'notification',
        '$location',
        '$anchorScroll',
        '_',
        'MassiaApplicationService',
        'moment',
        '$translate',
        'globalizationManagementService'
    ];

    constructor(
        $scope,
        /*$timeout,*/ $state,
        $stateParams,
        IndicesRevisionService,
        notification,
        $location,
        $anchorScroll,
        _,
        MassiaApplicationService,
        moment,
        $translate,
        globalizationManagementService
    ) {
        this.$scope = $scope;
        //this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.IndicesRevisionService = IndicesRevisionService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this._ = _;
        this.MassiaApplicationService = MassiaApplicationService;
        this.moment = moment;
        this.$translate = $translate;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    $onInit() {
        this.indicesRevisionGeneration = this.indicesRevisionGeneration || {};
        this.filtreSociete = 0;
        this.filtreSiteCommercial = 0;

        this.indicesRevisionGeneration = new IndicesRevisionGeneration();
        this.loading = false;
        this.nature = null;

        // initialise les dates de debut et fin au premier et dernier jour du mois en cours
        const maDate = new Date();
        const y = maDate.getFullYear();
        const m = maDate.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);

        this.indicesRevisionGeneration.dateDebut = this.moment(firstDay).format(this.dateFormat);
        this.indicesRevisionGeneration.dateFin = this.moment(lastDay).format(this.dateFormat);
        this.indicesRevisionGeneration.datePrixNegocies = this.indicesRevisionGeneration.dateDebut;
        this.getTransporteurs();
    }

    $onDestroy() {
        //this.$timeout.cancel(this.updatePrefacturationTimeout);
    }

    async getNomSocietes(valeur) {
        try {
            const res = await this.IndicesRevisionService.getNomSocietes(valeur);
            return res;
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async getNomSitesCommerciaux(valeur) {
        try {
            const res = await this.IndicesRevisionService.getNomSitesCommerciauxBySociete(this.indicesRevisionGeneration.idSociete, valeur);
            return res;
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async societeSelected() {
        if (typeof this.indicesRevisionGeneration.objectSociete.id !== 'undefined') {
            this.indicesRevisionGeneration.idSociete = this.indicesRevisionGeneration.objectSociete.id;
            this.filtreSociete = this.indicesRevisionGeneration.idSociete;
        }
    }

    async siteCommercialSelected() {
        if (typeof this.indicesRevisionGeneration.objectSiteCommercial.id !== 'undefined') {
            this.indicesRevisionGeneration.idSiteCommercial = this.indicesRevisionGeneration.objectSiteCommercial.id;
            this.filtreSiteCommercial = this.indicesRevisionGeneration.idSiteCommercial;
        }
    }

    async getTransporteurs() {
        this.startLoading();
        try {
            this.transporteurs = await this.IndicesRevisionService.getTransporteurs();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    reset() {
        this.filtreSociete = 0;
        this.indicesRevisionGeneration.objectSociete = null;
        this.indicesRevisionGeneration.idSociete = null;
        this.indicesRevisionGeneration.nomSociete = null;

        this.filtreSiteCommercial = 0;
        this.indicesRevisionGeneration.objectSiteCommercial = null;
        this.indicesRevisionGeneration.idSiteCommercial = null;
        this.indicesRevisionGeneration.nomSiteCommercial = null;
    }

    async calculerRevisionsPrix() {
        this.startLoading('generation');
        if (this.checkValidity()) {
            try {
                this.indicesRevisionGeneration.idSociete = this.indicesRevisionGeneration.objectSociete.id;
                this.indicesRevisionGeneration.nomSociete = this.indicesRevisionGeneration.objectSociete.libelle;
                if (this.indicesRevisionGeneration.objectSiteCommercial) {
                    this.indicesRevisionGeneration.idSiteCommercial = this.indicesRevisionGeneration.objectSiteCommercial.id;
                    this.indicesRevisionGeneration.nomSiteCommercial = this.indicesRevisionGeneration.objectSiteCommercial.libelle;
                } else {
                    this.indicesRevisionGeneration.idSiteCommercial = undefined;
                    this.indicesRevisionGeneration.nomSiteCommercial = undefined;
                }
                const resultatPreFa = await this.IndicesRevisionService.calculerRevisionsPrix(this.indicesRevisionGeneration);
                // si on a un objet, on va sur la page detail en passant l objet
                if (resultatPreFa.facturesRevision && resultatPreFa.facturesRevision.length != 0) {
                    try {
                        this.$state.go('revisions-prix.resultat', { obj: resultatPreFa });
                    } catch (ex) {
                        this.notification.error(ex.data);
                    }
                } else {
                    this.$state.go('revisions-prix.generation');
                    this.notification.info('INDICES_REVISION.WARNING_AUCUNE_FACTURE');
                }
            } catch (ex) {
                this.notification.error(ex.data);
                this.$state.go('revisions-prix.generation');
            } finally {
                this.stopLoading();
            }
        }
    }

    checkValidity() {
        let validity = true;

        if (!this.indicesRevisionGeneration.isValid()) {
            validity = false;
            this.$scope.$broadcast('indicesRevisionGenerationEnteteValidations');
        }
        return validity;
    }

    startLoading(nature) {
        this.loading = true;
        this.nature = nature;
    }

    stopLoading() {
        this.loading = false;
        this.nature = null;
    }
}
