import { getNatureNamesWithValues } from './../../../services/natures.enum';

const enumWithValues = getNatureNamesWithValues();

AdditionnalInfoMesureValidator.$inject = ['validator'];

export default function AdditionnalInfoMesureValidator(validator) {
    const instance = new validator();
    instance.ruleFor('decimale').must(requireDecimale).withMessage('VALIDATION_NUMBER_POSITIF');

    instance.ruleFor('idSerieTamis').must(requireSerieDim).withMessage('CHAMP_OBLIGATOIRE');
    instance.ruleFor('codeSsEssaiMasse').must(requireSsEssaiMasse).withMessage('CHAMP_OBLIGATOIRE');

    instance.ruleFor('elements').must(elementsNotEmpty).withMessage('VALIDATION_TOO_SHORT_LISTE');
    instance.ruleFor('elements').must(allCodeMatchRegex).withMessage('CODE_CHARACTERS_NOK');
    instance.ruleFor('elements').must(allCodeAreUnique).withMessage('CODE_UNIQUE_INFO');

    function isNumerique(idNature) {
        switch (idNature) {
            case enumWithValues.numerique:
            case enumWithValues.ensemblenumerique:
                return true;
            default:
                return false;
        }
    }

    function isElementListe(idNature) {
        switch (idNature) {
            case enumWithValues.ensembleelementliste:
            case enumWithValues.elementliste:
                return true;
            default:
                return false;
        }
    }

    function isAnalyseGranulo(idNature) {
        switch (idNature) {
            case enumWithValues.granulo:
                return true;
            default:
                return false;
        }
    }

    function requireDecimale(obj, decimal) {
        return !isNumerique(obj.idNature) || (typeof decimal === 'number' && decimal >= 0);
    }

    function requireSerieDim(obj, idSerieTamis) {
        return !isAnalyseGranulo(obj.idNature) || idSerieTamis >= 0;
    }

    function requireSsEssaiMasse(obj, codeSsEssaiMasse) {
        return !isAnalyseGranulo(obj.idNature) || codeSsEssaiMasse != '';
    }

    function allCodeMatchRegex(obj, elements = []) {
        if (isElementListe(obj.idNature)) {
            if (elements && elements.length > 0) {
                return elements.every((x) => x.key.match(/^([a-zA-Z0-9_|])+$/));
            }
        }
        return true;
    }

    function allCodeAreUnique(obj, elements = []) {
        if (isElementListe(obj.idNature)) {
            if (elements) {
                for (let i = 0; i < elements.length; i++) {
                    const val = elements[i].key;

                    for (let j = 0; j < elements.length; j++) {
                        if (i !== j && val === elements[j].key) {
                            return false;
                        }
                    }
                }
            }
        }

        return true;
    }

    function elementsNotEmpty(obj, elements = []) {
        return !isElementListe(obj.idNature) || (elements.length > 0 && !elements.every((x) => !x.key));
    }

    return instance;
}
