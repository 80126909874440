import validatorBuilder from 'validator-builder';

let controleEnteteValidator = null;

export default class ControleEntete {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.type = 'controle';
        this.code = data.code;
        this.libelle = data.libelle;
        this.controleType = data.controleType || 3;
    }

    isValid() {
        controleEnteteValidator = controleEnteteValidator || validatorBuilder.getInstanceFor('ControleEntete');
        const validationResults = controleEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
