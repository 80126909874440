import validatorBuilder from 'validator-builder';
import DocumentCEEntete from './entete/document.ce.entete.model';
import { DocumentCECaracteristique } from './caracteristique/document.ce.caracteristique.model';

let documentCEValidator = null;

export default class DocumentCE {
    constructor(data = {}) {
        this.id = data.id;
        this.entete = new DocumentCEEntete(data.entete);
        this.caracteristique = new DocumentCECaracteristique(data.caracteristique);
    }

    isValid() {
        documentCEValidator = documentCEValidator || validatorBuilder.getInstanceFor('DocumentCE');
        const validationResults = documentCEValidator.validate(this);
        return validationResults.isValid;
    }
}
