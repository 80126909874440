import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-print-modal',
    bindings: {
        resolve: '<',
        modalInstance: '<'
    },
    template: require('./massia.print.modal.tpl.html')
})
export class MassiaPrintModalController {
    constructor($scope, TemplateImpressionService, $timeout, $rootScope, $compile, SitesService) {
        this.scope = $scope;
        this.templateImpressionService = TemplateImpressionService;
        this.$timeout = $timeout;
        this.root = $rootScope;
        this.compile = $compile;
        this.sitesService = SitesService;
    }

    async $onInit() {
        this.isPdf = false;
        // impression tableau des prélèvements controle acquereur
        this.displayPrelOption = false;
        this.printPrels = false;

        this.printView = false; // catalogue traitement des formules → impression du tableau comme affiché
        if (this.resolve.domaine === 'contrac') this.displayPrelOption = true;

        this.error = '';
        this.portail = false;
        //liste des clients pour le partage de fichier avec le portail
        this.clients = [];
        this.selectedClients = [];
        for (const key in this.resolve) {
            if (this.resolve.hasOwnProperty(key)) {
                if (key !== 'html' && key !== 'displayPdfOption' && key !== 'displayPortailOption' && key !== 'domaine') {
                    this[key] = this.resolve[key];
                }
            }
        }

        this.getBindings();
        this.checkBindings();
        this.loadTemplate();
        if (this.resolve.html) {
            this.createHtml(this.resolve.html);
        }

        this.unregisterPortail = this.scope.$watch(
            () => this.portail,
            async () => {
                if (this.portail && !(this.clients.length > 0)) {
                    this.clients = (await this.sitesService.getSitesClients()).items;
                }
            },
            true
        );
    }
    $onDestroy() {
        this.unregisterPortail();
    }
    $onChanges() {}

    createHtml(html) {
        var newScope = this.scope.$new();
        var el = this.compile(html)(newScope);
        $('#dynamic-component').append(el);
    }

    async loadTemplate() {
        try {
            let res = await this.templateImpressionService.getTemplateByDomaine(this.domaine);
            this.template = res;
            if (res) {
                this.temp = res[0];
            }
        } catch (err) {
            this.error = err.data;
        }
    }

    ok() {
        if (this.temp) {
            let tmp = {};
            for (const key in this.resolve) {
                if (this.resolve.hasOwnProperty(key)) {
                    if (key !== 'html' && key !== 'displayPdfOption' && key !== 'displayPortailOption' && key !== 'domaine') {
                        tmp[key] = this[key];
                    }
                }
            }
            let res = {
                filename: this.temp.filename ? this.temp.filename : this.temp.libelle + this.temp.extension,
                template: this.temp,
                id: this.temp.id,
                pdf: this.isPdf,
                portail: this.portail,
                newFilename: this.customFilename,
                clients: this.selectedClients.map((x) => x.id),
                printPrel: this.printPrels,
                printView: this.printView,
                ...tmp
            };
            this.modalInstance.close(res);
        } else {
            this.selectError = 'IMPRESSION.PAS_DE_TEMPLATE';
            this.$timeout(() => {
                this.selectError = null;
            }, 1500);
        }
    }

    checkPdf() {
        this.isPdf = this.isPdf ? false : true;
    }

    cancel() {
        this.modalInstance.dismiss('cancel');
    }

    getBindings() {
        this.domaine = this.resolve.domaine;
        this.displayPdfOption = this.resolve.displayPdfOption === false ? false : true;
        this.displayPortailOption = this.resolve.displayPortailOption ? true : false;
        this.customFilename = this.resolve.customFilename ? this.resolve.customFilename : '';
        this.nameAuto = this.resolve.nameAuto ? true : false;
        this.portail = this.resolve.portail ? true : false;
    }

    checkBindings() {
        if (!this.domaine) console.error('MassiaPrintModal missing domaine binding');
    }
}

MassiaPrintModalController.$inject = ['$scope', 'TemplateImpressionService', '$timeout', '$rootScope', '$compile', 'SitesService'];
