export default class ProduitStockFormNiveauxController {
    static $inject = ['ProduitsService', 'notification', '_'];

    constructor(ProduitsService, notification, _) {
        this.ProduitsService = ProduitsService;
        this.notification = notification;
        this._ = _;
    }

    $onInit() {
        // Propriétés
        this.loading = true;
        this.produit = this.produit || {};
        this.values = [];
        this.niveaux = this.niveaux || [];
        this.elements = this.elements || [];
        this.chargeProduitsStockes();
        this.loadNiveaux();
    }

    async chargeProduitsStockes() {
        this.startLoading();
        try {
            this.produitsStockes = await this.ProduitsService.getProduitsStockesById(this.produit.id);
            if (this.produitsStockes && this._.isArray(this.produitsStockes)) {
                this.produitsStockes = angular.copy(this._.sortBy(this._.values(this.produitsStockes), 'nomSiteCommercial'));
            }

            this.produitStocke = this.produitsStockes.find((e) => e.elementsHierarchiques && Object.keys(e.elementsHierarchiques).length > 0)?.id;
            if (this.produitStocke) {
                this.loadNivH();
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    loadNivH() {
        this.values = this.produitsStockes.find((e) => e.id === this.produitStocke)?.elementsHierarchiques;
        this.produit.produitsStockes = this.produit.produitsStockes?.filter((obj) => obj.id !== this.produitStocke) || [];
        this.produit.produitsStockes.push({
            id: this.produitStocke,
            values: this.values
        });
    }

    async loadNiveaux() {
        this.startLoading();

        try {
            this.niveaux = await this.ProduitsService.getProduitsStocksNiveaux();
            this.elements = await this.ProduitsService.getProduitsStocksElements();
        } catch (ex) {
            this.notification.error(ex);
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
