export default class MOPService {
    currentMop = null;

    /* @ngInject */
    constructor(globalizationManagementService, $translate) {
        this.globalizationManagementService = globalizationManagementService;
        this.$translate = $translate;
    }

    downloadMOPfile(filename, application) {
        const language = this.globalizationManagementService.getCurrentLanguage().code;
        const link = document.createElement('a');
        link.id = 'downloadlink';
        link.href = `${__server}/mop/${language}/${application}/${filename}`;
        link.target = '_blank';
        link.download = filename;
        document.getElementsByTagName('body')[0].append(link);
        link.click();
        document.getElementById('downloadlink').remove();
    }

    setMop(MopData) {
        MopData = MopData.map((item) => {
            item.title = this.$translate.instant(item.title);
            return item;
        });
        this.currentMop = MopData;
    }

    resetMop() {
        this.currentMop = null;
    }
}
