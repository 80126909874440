import validatorBuilder from 'validator-builder';
import SocieteEntete from './entete/societe.entete.model';
import SocieteCaracteristiques from './caracteristiques/societe.caracteristiques.model';
import SocietePontComptable from './pont-comptable/pont.comptable.model';

let societeValidator = null;

export default class Societe {
    constructor(data = {}) {
        this.id = data.id;
        this.entete = new SocieteEntete(data.entete);
        this.caracteristiques = new SocieteCaracteristiques(data.caracteristiques);
        this.niveaux = data.niveaux || {};
        this.argumentSocietes = data.argumentSocietes || [];
        this.pontComptable = new SocietePontComptable(data.pontComptable);
    }

    isValid() {
        societeValidator = societeValidator || validatorBuilder.getInstanceFor('Societe');
        const validationResults = societeValidator.validate(this);
        return validationResults.isValid;
    }
}
