export default class FacturePopupController {
	static $inject = [
		'$scope',
		'$stateParams',
		'FacturesService',
		'notification',
		'ModalService',
		'$uibModal',
		'moment',
		'$translate',
		'globalizationManagementService'
	];

	constructor($scope, $stateParams, FacturesService, notification, ModalService, $uibModal, moment, $translate, globalizationManagementService) {
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.FacturesService = FacturesService;
		this.notification = notification;
		this.ModalService = ModalService;
		this.$uibModal = $uibModal;
		this.moment = moment;
		this.$translate = $translate;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	annuler() {
		this.modalInstance.close();
	}

	async generateAvoirEtRefacturation() {
		let success = false;

		const dateAvoir = this.moment(this.dateAvoir, this.dateFormat);
		const dateRefacturation = this.moment(this.dateRefacturation, this.dateFormat);
		const dateGenFactOrig = this.moment(this.dateGenFactOrig, this.dateFormat);

		// validator homeMade
		if (dateAvoir.isValid()) {
			if (this.type == 'avoir') {
				if (dateAvoir >= dateGenFactOrig) {
					success = true;
				} else {
					this.notification.error(this.$translate.instant('FACTURES.POPUP.DATEAVOIRINVALID'));
				}
			}

			if (this.type == 'refacturation') {
				if (dateRefacturation.isValid()) {
					if (dateAvoir >= dateGenFactOrig) {
						if (dateRefacturation >= dateGenFactOrig) {
							success = true;
						} else {
							this.notification.error(this.$translate.instant('FACTURES.POPUP.DATEREFACTINVALID'));
						}
					} else {
						this.notification.error(this.$translate.instant('FACTURES.POPUP.DATEAVOIRINVALID'));
					}
				} else {
					this.notification.warning(this.$translate.instant('FACTURES.POPUP.NODATEREFACT'));
				}
			}
		} else {
			this.notification.warning(this.$translate.instant('FACTURES.POPUP.NODATEAVOIR'));
		}

		const data = {};
		if (success) {
			data.dateAvoir = this.dateAvoir;
			data.dateRefacturation = this.dateRefacturation;
			data.idTypeFacture = this.idTypeFacture;
			data.idClient = this.idClient;
			data.idChantier = this.idChantier;
			data.canEdit = false;
		}

		return data;
	}

	async editerEtConfirmer() {
		const data = await this.generateAvoirEtRefacturation();
		if (data) {
			data.canEdit = true;
			this.modalInstance.close(data);
		}
	}

	async confirmer() {
		const data = await this.generateAvoirEtRefacturation();
		if (data) {
			this.modalInstance.close(data);
		}
	}

	async $onInit() {
		this.dateAvoir = null;
		this.dateRefacturation = null;
		this.idTypeFacture = 0;
		this.idClient = this.clientId;
		this.idChantier = this.chantierId;
		await this.getTypes();
		this.siteClient = this.client;
		console.log(this.chantierId);
		this.nomChantier = { id: this.idChantier, libelle: this.chantier };
		this.enteteAvoir = this.$translate.instant('FACTURES.POPUP.AVOIR_N', { numero: this.numero });
	}

	async getTypes() {
		if (!this.listeTypes) {
			try {
				this.listeTypes = await this.FacturesService.getTypesSurAvoirAuto();
				this.idTypeFacture = this.listeTypes[0].id;
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
			}
		}
	}

	async setTypeAheadAs(val) {
		switch (val) {
			case 'siteClient':
				if (this.siteClient) {
					this.idClient = this.siteClient.id;
				}
				break;
			case 'chantier':
				this.idChantier = this.nomChantier ? this.nomChantier.id : null;
				break;
		}
	}

	async getNomSitesClients(valeur) {
		try {
			const res = await this.FacturesService.getNomSitesClients(valeur, this.idSociete);
			return res;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async getNomChantiersByClient(valeur) {
		if (this.idClient) {
			try {
				const res = await this.FacturesService.getNomChantiersByClient(valeur, this.idClient);
				return res;
			} catch (ex) {
				this.notification.error(ex.data);
			}
		}
	}
}
