ClientDiversEnteteValidator.$inject = ['validator'];

export default function ClientDiversEnteteValidator(validator) {
    const instance = new validator();
    const telRgx = /(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}/;

    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('mail').emailAddress().when(isCompleted).withMessage('VALIDATION_EMAIL');
    instance.ruleFor('telephone').matches(telRgx).when(isCompleted).withMessage('VALIDATION_PHONE');
    /*instance.ruleFor('telephoneMobile').matches(/(0|\+33)\d{9}/).when(isCompleted).withMessage('VALIDATION_PHONE');
    instance.ruleFor('fax').matches(/(0|\+33)\d{9}/).when(isCompleted).withMessage('VALIDATION_FAX');*/
    return instance;

    function isCompleted(obj, prop) {
        return prop != null && typeof prop !== undefined && prop != '';
    }
}
