import { Injectable } from '../../../core/decorators';

@Injectable('ExcelService')
export class ExcelService {
    constructor($http) {
        this.url = `${__configuration.apiUrl}/massia/config-excel`;
        this.http = $http;
    }

    async getAllConfig(idTemplate = 0, idTypeSynthese = 0) {
        const res = await this.http.get(this.url, {
            params: {
                typeSynthese: idTypeSynthese,
                template: idTemplate
            }
        });
        return res.data;
    }

    async getOneConfig(id) {
        const res = await this.http.get(`${this.url}/${id}`);
        return res.data;
    }

    async createConfig(data) {
        const res = await this.http.post(this.url, data);
        return res.data;
    }

    async createCellConfig(id, listConfig) {
        const res = await this.http.post(this.url + '/' + id, listConfig);
        return res.data;
    }

    async isCodeUnique(code, entity) {
        let url = `${__configuration.apiUrl}/massia/uniqueness/code/${entity}/${code}`;
        const res = await this.http.get(url);
        return res.data;
    }

    async getCellByConfig(id, idEssai = null) {
        const res = await this.http.get(`${this.url}/${id}/cell`, {
            params: {
                idEssai: idEssai ? idEssai : 0
            }
        });
        return res.data;
    }

    async updateConfig(id, data) {
        const res = await this.http.put(`${this.url}/${id}`, data);
        return res.data;
    }

    async updateConfigCell(id, data) {
        const res = await this.http.put(`${this.url}/${id}/cell`, data);
        return res.data;
    }

    async getFile(id, idConfig, idEssai) {
        const res = await this.http.get(`${this.url}/${id}/file`, {
            params: {
                idConfig: idConfig,
                idEssai: idEssai
            }
        });

        return res.data;
    }

    async uploadExcelResult(id, file, idConfig, idEssai) {
        let form = new FormData();
        form.append('file', file);
        const res = await this.http.post(`${this.url}/${id}/file`, form, {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined },
            params: {
                idConfig: idConfig,
                idEssai: idEssai
            }
        });

        return res.data;
    }
}

ExcelService.$inject = ['$http'];
