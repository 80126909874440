import PrelevementEnteteController from './prelevement.entete.controller';

export default {
    bindings: {
        saisie: '=',
        campagne: '=',
        codeDomaine: '=',
        idTheme: '=',
        idFamille: '=',
        blLoaded: '&'
    },
    template: require('./prelevement.entete.html'),
    controller: PrelevementEnteteController
};
