import DevisesConfig from './_config/devises.config';
import DevisesRoutes from './_config/devises.routes';
import DevisesComponent from './components/devises';
import DevisesService from './services/devises.service';
import DevisesCommunicationService from './services/devises.communication.service';

import DeviseFormComponent from './components/devise-form';
import DeviseDetailComponent from './components/devise-detail';
import DeviseEnteteComponent from './components/devise-entete';

import DeviseValidator from './components/devise-form/devise.validator';
import DeviseEnteteValidator from './components/devise-entete/devise.entete.validator';

const moduleName = 'app.massia.common.devises';

angular
    .module(moduleName, [])
    .config(DevisesConfig)
    .config(DevisesRoutes)
    .service('DevisesService', DevisesService)
    .service('DevisesCommunicationService', DevisesCommunicationService)
    .component('devises', DevisesComponent)
    .component('deviseForm', DeviseFormComponent)
    .component('deviseDetail', DeviseDetailComponent)
    .component('deviseEntete', DeviseEnteteComponent)
    .factory('DeviseValidator', DeviseValidator)
    .factory('DeviseEnteteValidator', DeviseEnteteValidator);

export default moduleName;
