const serviceUrl = `${__configuration.apiUrl}/massia/vehicules`;

export default class VehiculesService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getVehicules(isEnabled, filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				isEnabled: JSON.stringify(isEnabled),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async deleteVehiculeById(id) {
		const url = `${serviceUrl}/${id}`;
		const res = this.$http.delete(url);
		return res;
	}

	async activateVehicule(id) {
		const url = `${serviceUrl}/activate/${id}`;
		return this.$http.put(url);
	}

	async getVehiculeEnteteById(id) {
		const url = `${serviceUrl}/${id}/entete`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async createVehicule(entete) {
		const result = await this.$http.post(serviceUrl, entete);
		return result.data;
	}

	async updateEnteteVehicule(entete) {
		const url = `${serviceUrl}/${entete.id}`;
		return await this.$http.put(url, entete);
	}

	async getImmatBennes(valeur) {
		const immatBenneUrl = `${__configuration.apiUrl}/massia/gestion/immat-bennes/headers/${valeur}`;
		const res = await this.$http.get(immatBenneUrl);
		return res.data;
	}

	async getImmatTracteurs(valeur) {
		const immatTracteurUrl = `${__configuration.apiUrl}/massia/gestion/tracteurs/headers/${valeur}`;
		const res = await this.$http.get(immatTracteurUrl);
		return res.data;
	}

	async getSites() {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-transporteurs/headers`;
		const res = await this.$http.get(siteUrl);

		return res.data;
	}

	async getChauffeurs(idTransporteur) {
		const idSociete = -1;
		const chauffUrl = `${__configuration.apiUrl}/massia/personnes/chauffeurs`;
		const res = await this.$http.get(chauffUrl, {
			params: {
				societeId: idSociete,
				siteTransporteurId: idTransporteur
			}
		});
		return res.data;
	}

	async getSitesCommerciaux(valeur) {
		const idUtilisateur = 0;
		const url = `${__configuration.apiUrl}/massia/sites-commerciaux/typeAhead/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async exportListingTare(idSite, modeImpr, filters, sorts) {
		const url = `${__configuration.apiUrl}/massia/listing-tarage-vehicules/${idSite}/print/${modeImpr}`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || [])
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async exportListeVehicules(modeImpr, filters, sorts) {
		const url = `${serviceUrl}/print`;
		const res = await this.$http.get(url, {
			params: {
				modeImpr: JSON.stringify(modeImpr),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || [])
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getVehiculesATarer(idSite, filters, sorts, pagination) {
		const url = `${__configuration.apiUrl}/massia/listing-tarage-vehicules`;
		if (!idSite) {
			idSite = 0;
		}
		const res = await this.$http.get(url, {
			params: {
				idSite: JSON.stringify(idSite),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}
}
