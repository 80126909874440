export default function LoginTranslations($translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.setPart('fr-FR', 'massia.login', {
        INCORRECT_USERNAME_OR_PASSWORD: 'Adresse e-mail ou mot de passe incorrect',
        ROUTE_LOGIN_TITLE: 'Connexion',
        LOGIN: {
            IDENTIFICATION: 'Authentification',
            USER_NAME_PLACEHOLDER: 'Adresse e-mail',
            USER_PASSWORD_PLACEHOLDER: 'Mot de passe',
            DATABASE_PLACEHOLDER: 'Base de données',
            CONNEXION: 'Connexion',
            FORCE_CONNEXION: 'Forcer la connexion'
        }
    });

    $translatePartialLoaderProvider.setPart('en-US', 'massia.login', {
        INCORRECT_USERNAME_OR_PASSWORD: 'Incorrect email or password',
        ROUTE_LOGIN_TITLE: 'Login',
        LOGIN: {
            IDENTIFICATION: 'Authentication',
            USER_NAME_PLACEHOLDER: 'Email',
            USER_PASSWORD_PLACEHOLDER: 'Password',
            DATABASE_PLACEHOLDER: 'Database',
            CONNEXION: 'Login',
            FORCE_CONNEXION: 'Force login'
        }
    });

    $translatePartialLoaderProvider.setPart('es-ES', 'massia.login', {
        INCORRECT_USERNAME_OR_PASSWORD: 'Correo electrónico o contraseña inválid@',
        ROUTE_LOGIN_TITLE: 'Conexión',
        LOGIN: {
            IDENTIFICATION: 'Autentificación',
            USER_NAME_PLACEHOLDER: 'Correo electrónico',
            USER_PASSWORD_PLACEHOLDER: 'Contraseña',
            DATABASE_PLACEHOLDER: 'Base de datos',
            CONNEXION: 'Conexión',
            FORCE_CONNEXION: 'Forzar la conexión'
        }
    });

    $translatePartialLoaderProvider.setPart('nl-BE', 'massia.login', {
        INCORRECT_USERNAME_OR_PASSWORD: 'Onjuist e-mailadres of wachtwoord',
        ROUTE_LOGIN_TITLE: 'Verbinding',
        LOGIN: {
            IDENTIFICATION: 'Authenticatie',
            USER_NAME_PLACEHOLDER: 'Adres e-mail',
            USER_PASSWORD_PLACEHOLDER: 'Wachtwoord',
            DATABASE_PLACEHOLDER: 'Databank',
            CONNEXION: 'Verbinding',
            FORCE_CONNEXION: 'Login forceren'
        }
    });

    $translatePartialLoaderProvider.addPart('massia.login');
}

LoginTranslations.$inject = ['$translatePartialLoaderProvider'];
