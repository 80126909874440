export default class SitePontComptableController {
    static $inject = ['$state', '$stateParams', 'SitesService', 'SitesTypesService', 'ModalService', 'notification', '$translate'];

    constructor($state, $stateParams, SitesService, SitesTypesService, ModalService, notification, $translate) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SitesService = SitesService;
        this.SitesTypesService = SitesTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
    }

    $onInit() {
        this.site = this.site || {};
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
