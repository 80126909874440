// on importe le controller car les components angularJS
// permettent l'ajout direct d'une fonction dans la propriété "controller"
import SerieTamisCaracteristiquesController from './serie.tamis.form.caracteristiques.controller';

export default {
    bindings: {
        // on bind avec '=' (double binding)
        // quand on veut pouvoir modifier l'objet des deux cotés (appelant et component)
        serieTamis: '=',
        // on bind avec '<' (binding one way)
        // quand on ne veut pas pouvoir modifier l'objet dans le component (seulement dans l'appelant)
        idType: '<',
        onUpdate: '&',
        form: '='
    },
    template: require('./serie.tamis.form.caracteristiques.html'),
    controller: SerieTamisCaracteristiquesController
    // si on ne déclare pas de 'controllerAs', par défaut ce sera '$ctrl'
};
