import DomainPortail from './DomainPortail';

export default class AffectationPortail {
    constructor(data = {}) {
        this.id = data.id || 0;
        this.code = data.code;
        this.libelle = data.libelle;
        this.auto = data.auto || false;
        this.documents = data.documents || true;
        this.donnees = data.donnees || false;
        this.domain = new DomainPortail(data.domain);
        this.domainCode = data.domain?.code;
        this.listCriteres = data.listCriteres || [];
        this.listPersonnes = data.listPersonnes || [];
    }
}
