import validatorBuilder from 'validator-builder';

let vehiculeEnteteValidator = null;

export default class VehiculeEntete {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.nomImmatTracteur = data.nomImmatTracteur;
        this.idImmatTracteur = data.idImmatTracteur;
        this.nomImmatBenne = data.nomImmatBenne;
        this.idImmatBenne = data.idImmatBenne;
        this.siteId = data.siteId;
        this.typeId = data.typeId;
        this.idTypeBenne = data.idTypeBenne;
        this.nomTypeBenne = data.nomTypeBenne;
        this.dateTare = data.dateTare;
        this.dsdTare = data.dsdTare;
        this.tare = data.tare;
        this.ptacVehicule = data.ptacVehicule;
        this.chauffeur = data.chauffeur;
        this.chauffeurId = data.chauffeurId;
        this.dateTare = data.dateTare;
        this.objetImmatTracteur = data.objetImmatTracteur;
        this.objetImmatBenne = data.objetImmatBenne;
    }

    isValid() {
        vehiculeEnteteValidator = vehiculeEnteteValidator || validatorBuilder.getInstanceFor('VehiculeEntete');
        const validationResults = vehiculeEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
