import Theme from './Theme';

export default class DocumentSetting {
    constructor(data = {}) {
        this.id = data?.id || null;
        this.loginBackground = data?.loginBackground || null;
        this.logo = data?.logo || null;
        this.logoComplet = data?.logoComplet || null;
        this.libelle = data?.libelle || '';
        this.coordonnees = data?.coordonnees || '';
        this.urlWebSite = data?.urlWebSite || null;
        this.urlFacebook = data?.urlFacebook || null;
        this.urlLinkedin = data?.urlLinkedin || null;
        this.theme = data?.theme ? new Theme(data.theme) : null;
    }

    isNull() {
        const properties = Object.keys(this);
        let isNull = true;

        for (let i = 0; i < properties.length; i++) {
            const value = properties[i];
            if (value && value !== '') {
                isNull = false;
            }
        }
        return isNull;
    }
}
