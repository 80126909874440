import validatorBuilder from 'validator-builder';
import OffreValidationDevisNiveaux from './offre-validation-niveau/offre.validation.niveaux.model';

let devisValidator = null;

export default class OffreValidationDevis {
    constructor(data) {
        data = data || {};

        this.id = data.id || null;
        this.isActif = data.isActif;
        this.idProfilAControler = data.idProfilAControler || null;
        const validationNiveaux = data.validationDevisNiveaux?.map((x) => new OffreValidationDevisNiveaux(x));
        this.validationDevisNiveaux = validationNiveaux || [];
    }

    isValid() {
        devisValidator = devisValidator || validatorBuilder.getInstanceFor('OffreValidationDevis');
        const validationResults = devisValidator.validate(this);
        return validationResults.isValid;
    }
}
