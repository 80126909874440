import { getNatureNamesByValues } from '../../../caracteristiques/services/natures.enum';

export default class SiteDetailController {
    static $inject = ['$stateParams', '$state', 'SitesService', 'notification', 'SitesCommunicationService', 'MassiaApplicationService'];

    constructor($stateParams, $state, SitesService, notification, SitesCommunicationService, MassiaApplicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.SitesService = SitesService;
        this.notification = notification;
        this.SitesCommunicationService = SitesCommunicationService;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    async $onInit() {
        this.loading = false;
        this.site = {};

        //this.siteId = undefined;
        this.caracteristiquesUtilisateur = [];
        this.caracteristiquesClient = [];
        this.caracteristiquesProducteur = [];
        this.caracteristiquesFournisseur = [];
        this.caracteristiquesTransporteur = [];
        this.caracteristiquesLaboratoire = [];
        this.caracteristiquesProprietaire = [];
        this.caracteristiquesLocataire = [];
        this.caracteristiquesAuditeur = [];
        this.ongletOpen = {
            isEnteteOpen: true,
            isCaracteristiqueUtilisateurOpen: false,
            isCaracteristiqueClientOpen: false,
            isCaracteristiqueProducteurOpen: false,
            isCaracteristiqueFournisseurOpen: false,
            isCaracteristiqueTransporteurOpen: false,
            isCaracteristiqueLaboratoireOpen: false,
            isCaracteristiqueProprietaireOpen: false,
            isCaracteristiqueLocataireOpen: false,
            isCaracteristiqueAuditeurOpen: false,
            isNiveauxHierarchiqueOpen: false
        };

        await this.initSite();
        this.initCarte();
    }

    async updateGenre() {
        switch (this.$stateParams.genre) {
            case 'commerciaux':
                return 1;
            case 'clients':
                return 2;
            case 'producteurs':
                return 4;
            case 'transporteurs':
                return 16;
            case 'fournisseurs':
                return 8;
            case 'laboratoires':
                return 32;
            case 'proprietaires':
                return 64;
            case 'locataires':
                return 128;
            case 'auditeurs':
                return 256;
            default:
                return 0;
        }
    }

    async initSite() {
        this.site = {
            id: this.$stateParams.id
        };

        if (this.site.id) {
            this.startLoading();

            // SL ajout de la determination du genre de la page pour appeler entete site
            const genreSite = await this.updateGenre();

            try {
                await this.getSiteEntete(this.site.id, genreSite);
                const application = this.getApplication();
                if (application == 'gestion' || application == 'performance') {
                    this.site.caracteristiquesUtilisateur = await this.SitesService.getSiteValeursCaracteristiquesUtilisateurById(this.site.id);
                    this.setCaracteristiquesUtilisateur(this.site.caracteristiquesUtilisateur);
                }
                this.site.caracteristiquesProducteur = await this.SitesService.getSiteValeursCaracteristiquesProducteurById(this.site.id);
                this.setCaracteristiquesProducteur(this.site.caracteristiquesProducteur);
                this.site.caracteristiquesFournisseur = await this.SitesService.getSiteValeursCaracteristiquesFournisseurById(this.site.id);
                this.setCaracteristiquesFournisseur(this.site.caracteristiquesFournisseur);
                if (application == 'laboratoire') {
                    this.site.caracteristiquesLaboratoire = await this.SitesService.getSiteValeursCaracteristiquesLaboratoireById(this.site.id);
                    this.setCaracteristiquesLaboratoire(this.site.caracteristiquesLaboratoire);
                }
                if (application == 'qse') {
                    this.site.caracteristiquesProprietaire = await this.SitesService.getSiteValeursCaracteristiquesProprietaireById(this.site.id);
                    this.setCaracteristiquesProprietaire(this.site.caracteristiquesProprietaire);
                    this.site.caracteristiquesLocataire = await this.SitesService.getSiteValeursCaracteristiquesLocataireById(this.site.id);
                    this.setCaracteristiquesLocataire(this.site.caracteristiquesLocataire);
                    this.site.caracteristiquesAuditeur = await this.SitesService.getSiteValeursCaracteristiquesAuditeurById(this.site.id);
                    this.setCaracteristiquesAuditeur(this.site.caracteristiquesAuditeur);
                }
                if (application == 'gestion') {
                    this.site.caracteristiquesTransporteur = await this.SitesService.getSiteValeursCaracteristiquesTransporteurById(this.site.id);
                    this.setCaracteristiquesTransporteur(this.site.caracteristiquesTransporteur);
                }
                if (application == 'gestion' || application == 'laboratoire') {
                    this.site.caracteristiquesClient = await this.SitesService.getSiteValeursCaracteristiquesClientById(this.site.id);
                    this.setCaracteristiquesClient(this.site.caracteristiquesClient);
                }

                this.site.niveaux = await this.SitesService.getSiteValeursNiveauxById(this.site.id);
                await this.loadNiveaux();
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListSite();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListSite();
        }
    }

    async initCarte() {
        this.zoom = undefined;
        this.longitude = undefined;
        this.latitude = undefined;
        if (this.site.entete && this.site.entete.longitude) {
            this.longitude = this.site.entete.longitude;
        }
        if (this.site.entete && this.site.entete.latitude) {
            this.latitude = this.site.entete.latitude;
        }

        const showCarte = this.getApplication() == 'qse' && this.latitude && this.longitude;
        const map = document.getElementById('map');
        if (map) {
            if (showCarte) {
                map.style.visibility = 'visible';
            } else {
                map.style.visibility = 'hidden';
            }
        }

        if (showCarte) {
            this.zoom = 10;
            this.map = L.map('map').setView([this.latitude, this.longitude], this.zoom); //par défaut

            this.map.scrollWheelZoom.disable();

            this.map.on(
                'mapReady',
                function (map) {
                    setTimeout(() => {
                        map.invalidateSize();
                    }, 0);
                },
                this
            );

            //Couches
            const Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                //z:niveau de zoom, x et y coordonnées du pavé
                attribution: '&copy; Les contributeurs <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                maxZoom: 20 //zoom maximum de la carte
            });
            Mapnik.addTo(this.map);

            //ajout du plan topo
            //this.planTopo();

            this.blueMarker = L.icon({
                iconUrl: 'assets/img/pin_GO.png',
                iconSize: [32, 32], // size of the icon
                iconAnchor: [4, 27] // point of the icon which will correspond to marker's location
            });

            this.marqueurPtMes = L.marker([this.latitude, this.longitude], { icon: this.blueMarker, draggable: false });
            this.marqueurPtMes.addTo(this.map);

            this.map.panTo(new L.LatLng(this.latitude, this.longitude));
        }
    }

    async loadNiveaux() {
        this.startLoading();

        try {
            this.niveaux = await this.SitesService.getSitesNiveaux();
            this.elements = await this.SitesService.getSitesElements();
        } catch (ex) {
            this.notification.error(ex);
        } finally {
            this.stopLoading();
        }
    }

    getApplication() {
        return this.MassiaApplicationService.getApplication();
    }

    goListSite() {
        this.$state.go(this.getUrlListe(), {}, { reload: true });
    }

    getUrlListe() {
        switch ($stateParams.genre) {
            case 'commerciaux':
                return 'sites.list';
            case 'clients':
                return 'clients.list';
            case 'producteurs':
                return 'producteurs.list';
            case 'fournisseurs':
                return 'fournisseurs.list';
            case 'transporteurs':
                return 'transporteurs.list';
            case 'laboratoires':
                return 'laboratoires.list';
            case 'proprietaires':
                return 'proprietaires.list';
            case 'locataires':
                return 'locataires.list';
            case 'auditeurs':
                return 'auditeurs.list';
            default:
                return 'sites.list';
        }
    }

    async getSiteEntete(siteId, genreSite) {
        this.site.entete = await this.SitesService.getSiteEnteteById(siteId, genreSite, this.MassiaApplicationService.getApplication());
    }

    async setCaracteristiquesUtilisateur(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiquesUtilisateur = [];
        caracteristiques.forEach((c) => {
            this.caracteristiquesUtilisateur.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    async setCaracteristiquesClient(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiquesClient = [];
        caracteristiques.forEach((c) => {
            this.caracteristiquesClient.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    async setCaracteristiquesProducteur(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiquesProducteur = [];
        caracteristiques.forEach((c) => {
            this.caracteristiquesProducteur.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    async setCaracteristiquesTransporteur(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiquesTransporteur = [];
        caracteristiques.forEach((c) => {
            this.caracteristiquesTransporteur.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    async setCaracteristiquesFournisseur(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiquesFournisseur = [];
        caracteristiques.forEach((c) => {
            this.caracteristiquesFournisseur.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    async setCaracteristiquesLaboratoire(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiquesLaboratoire = [];
        caracteristiques.forEach((c) => {
            this.caracteristiquesLaboratoire.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    async setCaracteristiquesProprietaire(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiquesProprietaire = [];
        caracteristiques.forEach((c) => {
            this.caracteristiquesProprietaire.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    async setCaracteristiquesLocataire(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiquesLocataire = [];
        caracteristiques.forEach((c) => {
            this.caracteristiquesLocataire.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    async setCaracteristiquesAuditeur(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiquesAuditeur = [];
        caracteristiques.forEach((c) => {
            this.caracteristiquesAuditeur.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
