import UnitesConfig from './_config/unites.config';
import UnitesRoutes from './_config/unites.routes';
import UnitesComponent from './components/unites';
import UnitesService from './services/unites.service';
import UnitesCommunicationService from './services/unites.communication.service';
import UnitesFormComponent from './components/unites-form';
import UniteValidator from './components/unites-form/unites.validator';
import UnitesSelectComponent from './components/unites-select';
import ModeUnitesService from './services/mode.unites.service';
import UniteDetailComponent from './components/unite-detail';

const moduleName = 'app.massia.common.unites';

angular
    .module(moduleName, [])
    .config(UnitesConfig)
    .config(UnitesRoutes)
    .service('UnitesService', UnitesService)
    .service('UnitesCommunicationService', UnitesCommunicationService)
    .service('ModeUnitesService', ModeUnitesService)
    .component('unites', UnitesComponent)
    .component('unitesForm', UnitesFormComponent)
    .component('unitesSelect', UnitesSelectComponent)
    .component('uniteDetail', UniteDetailComponent)
    .factory('UniteValidator', UniteValidator);

export default moduleName;
