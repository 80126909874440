import TicketCommentaireController from './ticket.commentaire.controller';

export default {
    bindings: {
        modalInstance: '<',
        resolve: '<'
    },
    template: require('./ticket.commentaire.html'),
    controller: TicketCommentaireController
};
