export default class BadgeDetailController {
    static $inject = ['$stateParams', '$state', 'BadgesService', 'notification', 'BadgesCommunicationService', 'DataShareService'];

    constructor($stateParams, $state, BadgesService, notification, BadgesCommunicationService, DataShareService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.BadgesService = BadgesService;
        this.notification = notification;
        this.BadgesCommunicationService = BadgesCommunicationService;
        this.DataShareService = DataShareService;
    }

    $onInit() {
        this.loading = false;
        this.badge = {};

        this.closeAllOnglets();
        this.openAllOnglets();
        this.openFirstOnglet();

        this.initBadge();
    }

    async initBadge() {
        this.badge = {
            id: this.$stateParams.id
        };

        if (this.badge.id) {
            this.startLoading();

            try {
                // on ajoute await afin de ne pas passer trop rapidement dans le finally
                // et ainsi on garde le loading le temps nécessaire
                this.badge = await this.BadgesService.getBadgeById(this.badge.id);
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListBadge();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListBadge();
        }
    }

    goListBadge() {
        this.$state.go('badges.list', {}, { reload: true });
    }

    openFirstOnglet() {
        this.ongletOpen = {
            isEnteteOpen: true,
            isDestinataireOpen: false,
            isTransportOpen: false,
            isProduitOpen: false
        };
    }
    closeAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: false,
            isDestinataireOpen: false,
            isTransportOpen: false,
            isProduitOpen: false
        };
    }
    openAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: true,
            isDestinataireOpen: true,
            isTransportOpen: true,
            isProduitOpen: true
        };
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
