export default function PromiseConfigurator($q, $log) {
    const configObject = { warnings: false };

    if (!__configuration.environment && __configuration.environment === 'development') {
        configObject.longStackTraces = true;
        configObject.cancellation = true;
        configObject.monitoring = true;
    }

    Promise.config(configObject);

    $q.onPossiblyUnhandledRejection(function (exception) {
        if (exception instanceof Error && exception.message.match(/transition (superseded|prevented|aborted|failed)|canceled/)) {
            return;
        }

        if (
            exception &&
            (typeof exception === 'string' || exception instanceof String) &&
            exception.match(/transition (superseded|prevented|aborted|failed)|canceled/)
        ) {
            return;
        }
        $log.warn('Unhandled rejection', exception);
    });
}

PromiseConfigurator.$inject = ['$q', '$log'];
