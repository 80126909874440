import BanqueFormEnteteController from './banque.form.entete.controller';

export default {
    bindings: {
        banque: '<',
        form: '=',
        onUpdate: '&'
    },
    template: require('./banque.form.entete.html'),
    controller: BanqueFormEnteteController
};
