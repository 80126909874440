import TransporteurInterfaceController from './transporteur.interface.controller';

export default {
	bindings: {
		site: '=',
		isOpen: '='
	},
	template: require('./transporteur.interface.html'),
	controller: TransporteurInterfaceController
};
