import ProductionController from './production.controller';

export default {
    bindings: {
        result: '<',
        save: '=',
        openSynthese: '=',
        generate: '=',
        synthese: '<'
    },
    controller: ProductionController,
    template: require('./production.tpl.html')
};
