export default class EditDistancesModal {
    /* @ngInject */
    constructor(
        TransportService,
        _,
        ProduitsService,
        SitesService,
        MassiaApplicationService,
        SiteDistancePoidsCo2Service,
        moment,
        globalizationManagementService,
        notification,
        $translate,
        $uibModal
    ) {
        this._ = _;
        this.TransportService = TransportService;
        this.ProduitsService = ProduitsService;
        this.$translate = $translate;
        this.transports = [];
        this.sitesLies = [];
        this.listClientsProducteurs = this.listClientsProducteurs || [];
        this.SitesService = SitesService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.listFormatted = [];
        this.listLoaded = false;
        this.listBdd = [];
        this.SiteDistancePoidsCo2Service = SiteDistancePoidsCo2Service;
        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
        this.moment = moment;
        this.notification = notification;
        this.$uibModal = $uibModal;
        this.dateValidite = null;
        this.filter = false;
        this.filterOn = false;
        this.icons = { familyProduct: 'glyphicon glyphicon-chevron-right', productorArea: 'glyphicon glyphicon-chevron-right' };
    }

    async $onInit() {
        await this.load();
    }

    async getValidityDate() {
        if (this.listFormatted && this.listFormatted.length > 0 && this.listFormatted[0].dateValidite !== null) {
            const date = new Date(this.listFormatted[0].dateValidite);
            return this.moment(date, this.dateFormat);
        } else {
            return null;
        }
    }

    async onSort(column) {
        let typeOfOrder = null;
        let labelToSort = null;
        const idxColumnToSort = this._.find(Object.keys(this.icons), function (f) {
            return f === column;
        });
        const idxColumnToUnsort = this._.find(Object.keys(this.icons), function (f) {
            return f !== column;
        });
        const columnToSort = this.icons[idxColumnToSort];
        const columToUnsort = this.icons[idxColumnToUnsort];
        switch (columnToSort) {
            case 'glyphicon glyphicon-chevron-right':
                this.icons[idxColumnToSort] = 'glyphicon glyphicon-chevron-up';
                this.icons[idxColumnToUnsort] = 'glyphicon glyphicon-chevron-right';
                typeOfOrder = 'asc';
                break;
            case 'glyphicon glyphicon-chevron-up':
                this.icons[idxColumnToSort] = 'glyphicon glyphicon-chevron-down';
                this.icons[idxColumnToUnsort] = 'glyphicon glyphicon-chevron-right';
                typeOfOrder = 'desc';
                break;
            case 'glyphicon glyphicon-chevron-down':
                this.icons[idxColumnToSort] = 'glyphicon glyphicon-chevron-up';
                this.icons[idxColumnToUnsort] = 'glyphicon glyphicon-chevron-right';
                typeOfOrder = 'asc';
                break;
            default:
                break;
        }
        if (idxColumnToSort === 'familyProduct') {
            labelToSort = 'famille.libelle';
        } else if (idxColumnToSort === 'productorArea') {
            labelToSort = 'siteProducteur.libelle';
        }
        this.listFormatted = _.orderBy(this.listFormatted, [labelToSort], [typeOfOrder]);
    }

    async checkIfISArchiveEnabled() {
        if (this.listEnabledIsArchive) {
            return await this.formatList(this.archiveSelected[0].values, true);
        } else {
            return await this.formatList(this.listEnabledValues);
        }
    }

    async offFilter() {
        this.filterOn = false;
        this.listFormatted = await this.checkIfISArchiveEnabled();
    }

    async onFilter(keyWord) {
        let list = await this.checkIfISArchiveEnabled();
        let title = this.$translate.instant('SITES.FILTER_MODAL.MAIN_TITLE');
        let a = null;
        let b = null;
        let c = false;
        if (typeof keyWord === 'string' && keyWord.includes('.')) {
            a = keyWord.substring(0, keyWord.indexOf('.')).toString();
            b = keyWord.substring(keyWord.indexOf('.') + 1).toString();
            if (list[0].hasOwnProperty(a) && list[0][a].hasOwnProperty(b)) {
                c = true;
            }
        }
        if (a === 'famille' && b === 'libelle') {
            title += this.$translate.instant('SITES.FILTER_MODAL.SEARCH_PRODUCT_FAMILY_LIBELLE');
        } else if (a === 'siteProducteur' && b === 'libelle') {
            title += this.$translate.instant('SITES.FILTER_MODAL.SEARCH_PRODUCTOR_AREA');
        }
        let result = null;
        const modalInstance = this.$uibModal.open({
            component: 'filterModal',
            animation: true,
            size: 'xxs',
            resolve: {
                title: () => title
            }
        });
        result = await modalInstance.result;
        if (result.isOk) {
            this.filterOn = true;
        }
        if (c && result.filter !== '' && result.filter) {
            this.listFormatted = await this.filterOnList(a, b, result.filter, list);
        }
    }

    filterOnList(a, b, keyWord, list) {
        const result = this._.filter(list, function (o) {
            return _.includes(o[a][b].toLowerCase(), keyWord.toLowerCase());
        });
        return result;
    }

    async load() {
        const filter = [
            { criterion: 'genre', value: 6, type: 'Eq' },
            { criterion: 'isClient', value: true, type: 'Eq' },
            { criterion: 'isProducteur', value: true, type: 'Eq' }
        ];
        const sort = [{ criterion: 'libelle', isDescending: false }];
        this.listClientsProducteurs = await this.SitesService.getSitesProducteurs(this.MassiaApplicationService.getApplication(), filter, sort, null);
        this.listClientsProducteurs = this.listClientsProducteurs.items;
        this.transports = await this.TransportService.getAllTransports();
        this.clientsProducteursSelected = null;
    }

    async getAllSiteLies() {
        if (!this.clientsProducteursSelected || this.clientsProducteursSelected.length <= 0) {
            this.notification.error(this.$translate.instant('FACTEUR-EMISSION-CO2.SEARCH_CLIENT_PRODUCTOR_ERROR'));
        } else {
            if (this.clientsProducteursSelected && this.clientsProducteursSelected[0].id) {
                this.listEnabledIsArchive = false;
                const producteur = this.clientsProducteursSelected[0];
                this.listBdd = await this.SiteDistancePoidsCo2Service.getAllDistancesPoidsCo2(producteur.id);
                this.listEnabledValues = await this.selectEnabledValues();
                this.listDisabledValues = await this.selectEnabledValues(true);
                this.listLoaded = true;
                this.sitesLies = await this.ProduitsService.getProducteurSiteLiesLabo(producteur.id);
                this.listFormatted = await this.formatList(this.listEnabledValues);
                await this.onSort('familyProduct');
                this.dateValidite = await this.getValidityDate();
            }
            if (!this.transports || this.transports.length <= 0) {
                this.notification.error(this.$translate.instant('FACTEUR-EMISSION-CO2.TRANSPORT.EMPTY_TRANSPORTS'));
            }
        }
    }

    async manageListArchives() {
        if (this.archiveSelected && this.archiveSelected.length > 0) {
            this.listEnabledIsArchive = true;
            this.listFormatted = await this.formatList(this.archiveSelected[0].values, true);
            this.dateValidite = await this.getValidityDate();
        } else {
            this.listEnabledIsArchive = false;
            await this.getAllSiteLies();
        }
    }

    async selectEnabledValues(disabled = false) {
        let listEnabledValues = [];
        if (this.listBdd) {
            this._.filter(this.listBdd, (o) => {
                let key = this._.findKey(this.listBdd, (k) => {
                    return k === o;
                });
                let values = [];
                this._.filter(o, function (f) {
                    if (!disabled && !f.dateArchivage) {
                        listEnabledValues.push(f);
                    } else if (disabled && f.dateArchivage) {
                        values.push(f);
                    }
                });
                if (disabled && values.length > 0) {
                    let dateVal = this.moment(new Date(values[0].dateValidite), this.dateFormat).format(this.dateFormat);
                    let dateArch = this.moment(new Date(values[0].dateArchivage), this.dateFormat).format(this.dateFormat);
                    listEnabledValues.push({
                        libelle: 'Date de validité : ' + dateVal + ' - Date Archivage : ' + dateArch,
                        values: values
                    });
                }
            });
        }
        return listEnabledValues;
    }

    checkDate() {
        if (this.dateValidite && this.dateValidite !== null) {
            return this.moment(this.dateValidite, this.dateFormat);
        }
        return null;
    }

    async formatList(list, isArchive = false) {
        const result = [];
        let count = 0;
        this._.forEach(this.sitesLies, (siteLie) => {
            const lineToEdit = this._.filter(result, (x) => {
                return x.famille.id === siteLie.produit.famille.id && x.siteProducteur.id === siteLie.siteProducteur.id;
            });
            if (lineToEdit.length > 0) {
                lineToEdit[0].produits.push(siteLie.produit);
                lineToEdit[0].listIdsSitesLies.push(siteLie.id);
            } else {
                const valeurKmsSaved = this._.filter(list, (x) => {
                    return (
                        x.famille.id === siteLie.produit.famille.id &&
                        x.siteProducteur.id === siteLie.siteProducteur.id &&
                        x.siteClient.id === siteLie.siteClient.id
                    );
                });
                const line = {
                    isArchive: isArchive ? true : false,
                    id: count,
                    produits: [siteLie.produit],
                    transports: this._.map(this.transports, (t) => {
                        const transport = {
                            id: t.id,
                            idFacteur:
                                valeurKmsSaved && valeurKmsSaved.length > 0 && valeurKmsSaved.find((vkms) => vkms.transport.id === t.id)
                                    ? valeurKmsSaved.find((vkms) => vkms.transport.id === t.id).id
                                    : -1,
                            code: t.code,
                            libelle: t.libelle,
                            valeur:
                                valeurKmsSaved && valeurKmsSaved.length > 0 && valeurKmsSaved.find((vkms) => vkms.transport.id === t.id)
                                    ? valeurKmsSaved.find((vkms) => vkms.transport.id === t.id).valeur
                                    : 0
                        };
                        return transport;
                    }),
                    siteProducteur: siteLie.siteProducteur,
                    siteClient: siteLie.siteClient,
                    listIdsSitesLies: [siteLie.id],
                    famille: siteLie.produit.famille,
                    dateValidite: list && list.length > 0 ? list[0].dateValidite : null,
                    dateArchivage: null
                };
                result.push(line);
                count++;
            }
        });
        return result;
    }

    async sauvegarder() {
        if (!this.dateValidite || this.dateValidite == null) {
            this.notification.error(this.$translate.instant('FACTEUR-EMISSION-CO2.TRANSPORT.VALIDITY_DATE_ERROR'));
            return false;
        } else {
            try {
                let res = this.formatForSave(this.listFormatted);
                await this.SiteDistancePoidsCo2Service.updateDistancesPoidsCo2(res);
                this.notification.success(this.$translate.instant('SITES.EDIT_DISTANCES_MODAL.SUCCESS'));
                await this.getAllSiteLies();
                return true;
            } catch (error) {
                this.notification.error(error.data);
                console.error(error);
                return false;
            }
        }
    }

    formatForSave(lines, isArchived = false) {
        const listFormattedForSave = [];
        const dateValidite = this.checkDate();
        let dateArchivage = new Date();
        dateArchivage = this.moment(dateArchivage, this.dateFormat);
        for (const line of lines) {
            for (const transport of line.transports) {
                listFormattedForSave.push({
                    id: transport.idFacteur,
                    produits: line.produits,
                    transport: transport,
                    siteProducteur: line.siteProducteur,
                    siteClient: line.siteClient,
                    listIdsSitesLies: line.listIdsSitesLies,
                    famille: line.famille,
                    dateValidite: dateValidite,
                    dateArchivage: isArchived ? dateArchivage : null,
                    valeur: transport.valeur
                });
            }
        }
        return listFormattedForSave;
    }

    async archiveAll() {
        if (this.listFormatted && this.listFormatted.length > 0) {
            let res = await this.formatForSave(this.listFormatted, true);
            await this.SiteDistancePoidsCo2Service.updateDistancesPoidsCo2(res);
            if (res.length > 0) {
                this.notification.success(this.$translate.instant('FACTEUR-EMISSION-CO2.ARCHIVE.SUCCESS'));
            }
            await this.getAllSiteLies();
        }
    }

    $onDestroy() {}

    annuler() {
        this.close({ $value: { isOk: false } });
    }

    async ok() {
        await this.sauvegarder();
    }

    async validerEtFermer() {
        let result = await this.sauvegarder();
        if (result) {
            this.close({ $value: { isOk: true } });
        }
    }
}
