const serviceUrl = `${__configuration.apiUrl}/massia/prestations`;

export default class ControlesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getControles(filters, sorts, pagination) {
        const url = `${__configuration.apiUrl}/massia/prestation-controles`;
        const res = await this.$http.get(url, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getControleTypes() {
        const res = await this.$http.get('assets/enum/controle-creation-dap/controle.type.json');
        return res.data;
    }

    async deleteControleById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getControleEnteteById(id) {
        const url = `${serviceUrl}/${id}/entete`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createControle(entete) {
        const result = await this.$http.post(serviceUrl, entete);
        return result.data;
    }

    async updateEnteteControle(entete) {
        const url = `${serviceUrl}/${entete.id}`;
        return this.$http.put(url, entete);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getControleResultats(id) {
        const url = `${__configuration.apiUrl}/massia/prestation-controles/resultat/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async deleteResultatById(id) {
        const url = `${__configuration.apiUrl}/massia/prestation-controles/resultat/${id}`;
        const res = this.$http.delete(url);
        return res;
    }

    async createResultat(resultat) {
        const url = `${__configuration.apiUrl}/massia/prestation-controles/resultat`;
        const result = await this.$http.post(url, resultat);
        return result.data;
    }

    async updateResultat(resultat) {
        const url = `${__configuration.apiUrl}/massia/prestation-controles/resultat/${resultat.id}`;
        const result = await this.$http.put(url, resultat);
        return result.data;
    }
}
