export default function Configure($stateProvider) {
    $stateProvider.state('prefacturation', {
        parent: 'common',
        url: '/prefacturation',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
        }
    });

    // préfa Vente
    $stateProvider.state('prefacturation.newvente', {
        url: '/newvente',
        template: '<prefacturation-form></prefacturation-form>',
        rights: { domain: 'prefavente', right: 'create' },
        ncyBreadcrumb: {
            // parent: 'prefacturation',
            label: '{{ "PREFACTURATION.BREADCRUMBS.PREFACTURATIONVENTE_NEW" | translate}}'
        },
        navigation: {
            menu: 'facturation',
            translate: 'PREFACTURATION.BREADCRUMBS.PREFACTURATIONVENTE',
            order: 1
        },
        search: 'PREFACTURATION.BREADCRUMBS.PREFACTURATIONVENTE_NEW'
    });

    $stateProvider.state('prefacturation.detailvente', {
        url: '/resultatvente',
        params: {
            obj: null
        },
        // parent: 'prefacturation',
        template: '<prefacturation-detail></prefacturation-detail>',
        rights: { domain: 'prefavente', right: 'read' },
        ncyBreadcrumb: {
            parent: 'prefacturation.newvente',
            label: '{{ "PREFACTURATION.BREADCRUMBS.PREFACTURATIONVENTE_DETAIL" | translate }}'
        }
    });

    // préfa Achat
    $stateProvider.state('prefacturation.newachat', {
        url: '/newachat',
        template: '<prefacturation-form></prefacturation-form>',
        rights: { domain: 'prefaachat', right: 'create' },
        ncyBreadcrumb: {
            // parent: 'prefacturation',
            label: '{{ "PREFACTURATION.BREADCRUMBS.PREFACTURATIONACHAT_NEW" | translate}}'
        },
        navigation: {
            menu: 'facturation',
            translate: 'PREFACTURATION.BREADCRUMBS.PREFACTURATIONACHAT',
            order: 1
        },
        search: 'PREFACTURATION.BREADCRUMBS.PREFACTURATIONACHAT_NEW'
    });

    $stateProvider.state('prefacturation.detailachat', {
        url: '/resultatachat',
        params: {
            obj: null
        },
        // parent: 'prefacturation',
        template: '<prefacturation-detail></prefacturation-detail>',
        rights: { domain: 'prefaachat', right: 'read' },
        ncyBreadcrumb: {
            parent: 'prefacturation.newachat',
            label: '{{ "PREFACTURATION.BREADCRUMBS.PREFACTURATIONACHAT_DETAIL" | translate }}'
        }
    });

    // SL rooting pour aller sur la commande
    $stateProvider.state('prefacturation.commande', {
        url: '/commande/{id}/edit',
        template: '<commande-form></commande-form>',
        rights: { domain: 'commandes', right: 'update' },
        params: {
            parentState: 'prefacturation.list.detailvente',
            tag: 'preFa'
        },
        ncyBreadcrumb: {
            parent: 'prefacturation.list.detailvente',
            label: '{{ "COMMANDES.BREADCRUMBS.COMMANDE_EDIT" | translate}}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
