import StockClotureJourPopupController from './stock.cloture.jour.popup.controller';

export default {
    bindings: {
        modalInstance: '=',
        resolve: '<'
    },
    template: require('./stock.cloture.jour.popup.html'),
    controller: StockClotureJourPopupController
};
