import validatorBuilder from 'validator-builder';

let additionnalInfoValidator = null;

export default class AdditionnalInfo {
    constructor(idNature, data = {}) {
        this.idNature = idNature;
        this.decimale = data.decimale || 0;
        this.idUnite = data.idUnite;
        this.elements = data.elements || [];
        this.data = data.data || {};
    }

    isValid() {
        additionnalInfoValidator = additionnalInfoValidator || validatorBuilder.getInstanceFor('AdditionnalInfo');
        const validationResults = additionnalInfoValidator.validate(this);
        return validationResults.isValid;
    }
}
