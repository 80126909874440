(function (angular, undefined) {
    'use strict';

    var constants = {
        windowTemplateUrl: 'uib/template/modal/window.html',
        templateUrl: 'blocks/modal/modal.tpl.html',
        controller: 'ModalController',
        controllerAs: 'modalCtrl',
        backdrop: 'static',
        keyboard: true,
        enableDrag: false,
        enableResize: false,
        buttonOK: 'BUTTON_OK',
        buttonCancel: 'BUTTON_CANCEL',
        buttonClass: 'default'
    };

    angular.module('blocks.modal').constant('modalDefaultConfig', constants);
})(angular);