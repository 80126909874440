export default function Configure($stateProvider) {
    $stateProvider.state('transports', {
        parent: 'gestion',
        url: '/transports',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('transports.list', {
        url: '/list',
        template: '<transports></transports>',
        rights: { domain: 'prestation', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "TRANSPORTS.BREADCRUMBS.TRANSPORTS_LIST" | translate}}'
        },
        navigation: {
            menu: 'tarification_transport',
            translate: 'TRANSPORTS.BREADCRUMBS.TRANSPORTS_LIST',
            order: 6
        },
        search: 'TRANSPORTS.BREADCRUMBS.TRANSPORTS_LIST'
    });

    $stateProvider.state('transports.list.detail', {
        url: '/{id}',
        rights: { domain: 'prestation', right: 'read' },
        template: '<transport-detail></transport-detail>',
        ncyBreadcrumb: {
            parent: 'transports.list',
            label: '{{ "TRANSPORTS.BREADCRUMBS.TRANSPORT_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('transports.new', {
        url: '/new',
        template: '<transport-form></transport-form>',
        rights: { domain: 'prestation', right: 'create' },
        ncyBreadcrumb: {
            parent: 'transports.list',
            label: '{{ "TRANSPORTS.BREADCRUMBS.TRANSPORT_NEW" | translate}}'
        },
        search: 'TRANSPORTS.BREADCRUMBS.TRANSPORT_NEW'
    });

    $stateProvider.state('transports.edit', {
        url: '/{id}/edit',
        template: '<transport-form></transport-form>',
        rights: { domain: 'prestation', right: 'update' },
        ncyBreadcrumb: {
            parent: 'transports.list',
            label: '{{ "TRANSPORTS.BREADCRUMBS.TRANSPORT_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
