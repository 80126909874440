const serviceUrl = `${__configuration.apiUrl}/massia/historique`;

export default class HistoriqueService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getHistorique(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getEvenements() {
        const url = 'assets/enum/historique/evenements.json';
        const res = await this.$http.get(url);
        return res.data;
    }

    async getHistoriqueComposant(id, domaine) {
        const url = `${__configuration.apiUrl}/massia/historique-composant/${id}/${domaine}`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
