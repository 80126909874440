(function () {
    'use strict';

    angular
        .module('blocks.smart.table')
        .config(AppConfig);
    AppConfig.$inject = ['$translatePartialLoaderProvider'];

    /* @ngInject */
    function AppConfig($translatePartialLoaderProvider) {
        $translatePartialLoaderProvider.setPart('fr-FR', 'blocks.smart.table', {
            "PAGINATION_ELEMENTS": "{NUM_RESULTS, plural, =0{aucun élément} one{1 élément} other{# éléments}}",
            "PAGINATION_ELEMENTS_SHORT": "{NUM_RESULTS, plural, =0{aucun elm.} one{1 elm.} other{# elms.}}",
            "LABEL_APPLY_FILTER": "Filtrer",
            "LABEL_ERASE_FILTER": "Effacer le filtre",
            "LABEL_FILTER_CONTAINS": "Contient ",
            "LABEL_FILTER_EQUALS": "Égal à ",
            "LABEL_FILTER_IN": "Inclus dans ",
            "LABEL_FILTER_MIN": "Min ",
            "LABEL_FILTER_MAX": "Max ",
            "LABEL_FILTER_FROM_DATE": "Du ",
            "LABEL_FILTER_TO_DATE": "Au "
        });

        $translatePartialLoaderProvider.setPart('en-US', 'blocks.smart.table', {
            "PAGINATION_ELEMENTS": "{NUM_RESULTS, plural, =0{no elements} one{1 element} other{# elements}}",
            "PAGINATION_ELEMENTS_SHORT": "{NUM_RESULTS, plural, =0{no elm.} one{1 elm.} other{# elms.}}",
            "LABEL_APPLY_FILTER": "Filter",
            "LABEL_ERASE_FILTER": "Clear filter",
            "LABEL_FILTER_CONTAINS": "Contains",
            "LABEL_FILTER_EQUALS": "Equals to ",
            "LABEL_FILTER_IN": "Included in ",
            "LABEL_FILTER_MIN": "Min ",
            "LABEL_FILTER_MAX": "Max ",
            "LABEL_FILTER_FROM_DATE": "From ",
            "LABEL_FILTER_TO_DATE": "To "
        });

        $translatePartialLoaderProvider.setPart('es-ES', 'blocks.smart.table', {
            "PAGINATION_ELEMENTS": "{NUM_RESULTS, plural, =0{ningún elemento} one{1 elemento} other{# elementos}}",
            "PAGINATION_ELEMENTS_SHORT": "{NUM_RESULTS, plural, =0{ningún elm.} one{1 elm.} other{# elms.}}",
            "LABEL_APPLY_FILTER": "Filtrar",
            "LABEL_ERASE_FILTER": "Borrar el filtro",
            "LABEL_FILTER_CONTAINS": "Contiene ",
            "LABEL_FILTER_EQUALS": "Igua a ",
            "LABEL_FILTER_IN": "Incluido en ",
            "LABEL_FILTER_MIN": "Mín ",
            "LABEL_FILTER_MAX": "Máx ",
            "LABEL_FILTER_FROM_DATE": "Del ",
            "LABEL_FILTER_TO_DATE": "Al "
        });

        $translatePartialLoaderProvider.setPart('nl-BE', 'blocks.smart.table', {
            "PAGINATION_ELEMENTS": "{NUM_RESULTS, plural, =0{aucun élément} one{1 élément} other{# éléments}}",
            "PAGINATION_ELEMENTS_SHORT": "{NUM_RESULTS, plural, =0{aucun elm.} one{1 elm.} other{# elms.}}",
            "LABEL_APPLY_FILTER": "Filter",
            "LABEL_ERASE_FILTER": "Filter wissen",
            "LABEL_FILTER_CONTAINS": "Bevat ",
            "LABEL_FILTER_EQUALS": "Gelijk aan ",
            "LABEL_FILTER_IN": "Inbegrepen in ",
            "LABEL_FILTER_MIN": "Min ",
            "LABEL_FILTER_MAX": "Max ",
            "LABEL_FILTER_FROM_DATE": "Van ",
            "LABEL_FILTER_TO_DATE": "Tot "
        });

        $translatePartialLoaderProvider.addPart('blocks.smart.table');
    }
})();