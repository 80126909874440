import { getNatureNamesByValues } from '../../../caracteristiques/services/natures.enum';

export default class DocumentDetailController {
    static $inject = ['$stateParams', '$state', 'DocumentsService', 'notification', 'DocumentsCommunicationService'];

    constructor($stateParams, $state, DocumentsService, notification, DocumentsCommunicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.DocumentsService = DocumentsService;
        this.notification = notification;
        this.DocumentsCommunicationService = DocumentsCommunicationService;
    }

    $onInit() {
        this.loading = false;
        this.document = {};
        this.caracteristiques = [];

        this.ongletOpen = {
            isEnteteOpen: true,
            isCaracteristiqueOpen: false
        };

        this.initDocument();
    }

    async initDocument() {
        this.document = { id: this.$stateParams.id };
        if (this.document.id) {
            this.startLoading();
            try {
                this.document.entete = await this.DocumentsService.getDocumentEnteteById(this.document.id);
                this.document.caracteristiques = await this.DocumentsService.getDocumentValeursCaracteristiquesById(this.document.id);
                this.setCaracteristiques(this.document.caracteristiques);
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListDocument();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListDocument();
        }
    }

    async getDocumentEntete(documentId) {
        this.document.entete = await this.DocumentsService.getDocumentEnteteById(documentId);
    }

    async setCaracteristiques(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiques = [];
        caracteristiques.forEach((c) => {
            this.caracteristiques.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    goListDocument() {
        this.$state.go('documents.list', {}, { reload: true });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
