import CommandeLignePrestation from './commande.ligne.prestation.model';
import CommandeLigneTransport from './commande.ligne.transport.model';

export default class CommandeLigneController {
	static $inject = ['$stateParams', 'CommandesCommunicationService', 'CommandesService', 'notification', '_', 'FacturesService', '$translate'];

	constructor($stateParams, CommandesCommunicationService, CommandesService, notification, _, FacturesService, $translate) {
		this.$stateParams = $stateParams;
		this.CommandesCommunicationService = CommandesCommunicationService;
		this.CommandesService = CommandesService;
		this.notification = notification;
		this._ = _;
		this.FacturesService = FacturesService;
		this.$translate = $translate;
		this.addZone = false;
		this.newProduitType = 'prestation';
		this.tarifs = [];
		this.tvaList = [];
	}

	async $onInit() {
		this.unregisterReinit = this.CommandesCommunicationService.registerReinit(() => this.initForm());
		const prestationsList = await this.CommandesService.getPrestations();
		// On retire les prestations pour les indices de révision
		this.prestations = prestationsList.filter((prestation) => prestation.modeDeclenchement < 3 || prestation.modeDeclenchement == 6); //ajout du mode de declenchement ticket de rechargement
		this.transports = await this.CommandesService.getTransports();

		// SL gestion de la prestation déjà existante dans la liste
		if (this.groupe.prestations.length > 0) {
			for (let index = 0; index < this.prestations.length; index++) {
				const element = this.prestations[index];
				if (this._.findIndex(this.groupe.prestations, { prestationId: element.id }) > -1) {
					element.disabled = true;
				}
			}
		}

		// SL gestion du transport déjà existant dans la liste
		if (this.groupe.transports.length > 0) {
			for (let index = 0; index < this.transports.length; index++) {
				const element = this.transports[index];
				if (this._.findIndex(this.groupe.transports, { transportId: element.id }) > -1) {
					element.disabled = true;
				}
			}
		}

		this.bennes = await this.CommandesService.getBennes();
		this.commmandeZone = null;
		// this.findCommandeZone();
		this.tvaList = await this.CommandesService.getTvaList();
	}

	async findCommandeZone(idSiteComFromTransport, idGrilleZoneKm) {
		try {
			this.commmandeZone = await this.CommandesService.getZoneForTransport(
				idSiteComFromTransport,
				this.commande.entete.chantierId,
				this.commande.entete.lieuId,
				idGrilleZoneKm
			);
			return this.commmandeZone.idZone;
		} catch (ex) {
			if (ex.status == 400) {
				this.notification.info(ex.data);
			} else {
				this.notification.error(ex.data);
			}
		}
	}

	$onDestroy() {
		this.unregisterReinit();
	}

	async addPrestation(newPrestation) {
		// Lorsque le selectBox change vers vide on n'execute pas la fronction
		if (this.selectedPrestation == null) {
			return;
		}

		var newPrestation = new CommandeLignePrestation(this.selectedPrestation, this.groupe.produit);

		await this.articleSelected(newPrestation);

		this.groupe.prestations.push(newPrestation);

		this.selectedPrestation = null;
		this.recalculeLignes();
	}

	async addTransport() {
		// Lorsque le selectBox change vers vide on n'execute pas la fronction
		if (this.selectedTransport == null) {
			return;
		}

		const newTransport = new CommandeLigneTransport(this.selectedTransport, this.groupe.produit);
		if (newTransport.prixZone == true) {
			newTransport.idZone = await this.findCommandeZone(newTransport.siteCommercialId, newTransport.idGrilleZoneKm);
		}

		await this.articleSelected(newTransport);

		this.groupe.transports.push(newTransport);

		this.selectedTransport = null;
		this.recalculeLignes();
	}

	async changeBenne(transport) {
		if (transport.prixZone == true) {
			transport.idZone = await this.findCommandeZone(transport.siteCommercialId, transport.idGrilleZoneKm);
		}
		await this.articleSelected(transport);
		transport.prixAchat = 0;
	}

	removeLigne(ligne, index) {
		switch (ligne.typePrestationLie) {
			case 0:
			case '0':
				index >= 0 && this.scl.splice(index, 1);
				break;
			case 2:
			case '2':
				index >= 0 && this.groupe.prestations.splice(index, 1);
				break;
			case 1:
			case '1':
				index >= 0 && this.groupe.transports.splice(index, 1);
				break;
		}
		this.recalculeLignes();
	}

	changeTva(element) {
		const index = this._.findIndex(this.tvaList, { id: element.tvaId });
		element.tauxTVA = this.tvaList[index].taux;
		this.recalculeLignes();
	}

	changeLigne() {
		this.recalculeLignes();
	}

	async articleSelected(newArticle) {
		let articleId = 0;
		switch (newArticle.typePrestationLie) {
			case 1:
			case '1': // transport
				articleId = newArticle.transportId;
				break;
			case 2:
			case '2': // prestation
				articleId = newArticle.prestationId;
				break;
			default:
				break;
		}

		// recherche le tarif (Prix Standard)
		let prixArticle = null;
		try {
			prixArticle = await this.FacturesService.getPSArticle(
				articleId,
				newArticle.typePrestationLie,
				this.commande.entete.societeId,
				newArticle.siteCommercialId,
				this.commande.entete.clientId,
				this.commande.entete.chantierId,
				newArticle.producteurId,
				newArticle.benneId,
				newArticle.idZone
			);
		} catch (ex) {
			// si exception businessrule, mettre 0
			this.notification.warning(ex.data);
			if (ex.status == 404) {
				newArticle.prixStandard = 0;
				newArticle.tvaId = 0;
			}
		}

		if (prixArticle != null) {
			newArticle.prixStandard = prixArticle.prixStandard;
			newArticle.prixVente = prixArticle.prixUnitaire;
			newArticle.tvaId = prixArticle.idTVA;

			if (prixArticle.prixStandard == -1) {
				newArticle.prixStandard = null;
				this.notification.warning(this.$translate.instant('FACTURES.NO_PRIX_FOUND'));
			}
		}

		if (newArticle.tvaId == 0) {
			// recherche taux TVA par défault
			let code = '';
			const filtre = {
				chantierId: this.commande.entete.chantierId,
				siteCommercialId: newArticle.siteCommercialId,
				date: this.commande.entete.dateCreation,
				achatVente: 1
			};

			switch (newArticle.typePrestationLie) {
				case 1:
				case '1': // transport
					code = 'tvaTransp';
					filtre.transportId = articleId;
					break;
				case 2:
				case '2': // prestation
					code = 'tvaPresta';
					filtre.prestationId = articleId;
					break;
				default:
					break;
			}

			try {
				const tempTVA = await this.FacturesService.getTVAArticle(code, filtre);

				if (tempTVA.elements.length > 0) {
					const sortedListTVA = _.sortBy(tempTVA.elements, function (tvaItem) {
						return -tvaItem.dateApplication;
					});

					if (sortedListTVA[0].tvaId != null) {
						newArticle.tvaId = sortedListTVA[0].tvaId;
					}
				} else {
					// this.notification.warning(this.$translate.instant('FACTURES.TVANOTFOUND'));
				}
			} catch (ex) {
				this.notification.error(ex.data);
			}
		}

		if (newArticle.tvaId == 0) {
			this.notification.warning(this.$translate.instant('FACTURES.TVANOTFOUND'));
		}
	}

	async updateTgapExclure(id) {
		await this.CommandesService.updateTgapExclure(id, 1);
	}
}
