import SiteFormController from './site.form.controller';

export default {
    bindings: {
        modalInstance: '=',
        genreSite: '<',
        idSitePourCompte: '<'
    },
    template: require('./site.form.html'),
    controller: SiteFormController
};
