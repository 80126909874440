const serviceUrl = `${__configuration.apiUrl}/massia/granulometrie-type-produit`;

class GranulometrieTypeProduit {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getGranulometries() {
        const res = await this.$http.get(serviceUrl);
        return res.data;
    }
}

export default GranulometrieTypeProduit;
