(function (angular, undefined) {
    'use strict';

    angular.module('blocks.treeview')
        .run(run);
    run.$inject = ['$templateCache'];

    function run($templateCache) {

        $templateCache.put('blocks/treeview/ac.treeview.node.menu.html',
            '    <div class="dropdown treeview-node-menu position-fixed" ' +
            '         id="{{ ::tree.config.name }}-node-menu">' +
            '        <ul class="dropdown-menu" role="menu">' +
            '            <li class="text-center text-muted">' +
            '               <p class="margin-10">' +
            '                   <span title="{{ menu.getSelectedNode(tree.config.name).label }}">{{ menu.getSelectedNode(tree.config.name).label | TruncateEllipsis: 20 }}</span>' +
            '               </p>' +
            '            </li>' +
            '            <li class="divider margin-top-0"></li>' +
            '            <!-- Boutons d\'actions -->' +
            '            <li class="padding-0-10 text-center" ng-if="menu.canRefreshNode() || menu.canCreateNode() || menu.canEditNode() || tree.canRenameNode(menu.getSelectedNode(tree.config.name)) || menu.canRemoveNode()">' +
            '               <!-- refresh noeud -->' +
            '               <button class="btn btn-sm btn-default "' +
            '                       ng-if="menu.canRefreshNode()"' +
            '                       ng-click="menu.refreshNode()"' +
            '                       uib-tooltip="{{\'TREEVIEW_REFRESH_NODE\' | translate}}">' +
            '                   <span class="glyphicon glyphicon-refresh"></span>' +
            '               </button>' +
            '               <!-- créer noeud -->' +
            '               <button class="btn btn-sm btn-default "' +
            '                       ng-if="menu.canCreateNode()"' +
            '                       ng-click="menu.createNode()"' +
            '                       uib-tooltip="{{\'TREEVIEW_ADD_NODE\' | translate}}">' +
            '                   <span class="glyphicon glyphicon-plus"></span>' +
            '               </button>' +
            '               <!-- editer noeud -->' +
            '               <button class="btn btn-sm btn-default "' +
            '                       ng-if="menu.canEditNode()"' +
            '                       ng-click="menu.editNode()"' +
            '                       uib-tooltip="{{\'TREEVIEW_EDIT_NODE\' | translate}}">' +
            '                   <span class="glyphicon glyphicon-pencil"></span>' +
            '               </button>' +
            '               <!-- renommer noeud -->' +
            '               <button class="btn btn-sm btn-default "' +
            '                       ng-if="(tree.canRenameNode())"' +
            '                       ng-click="tree.renameNode()"' +
            '                       uib-tooltip="{{\'TREEVIEW_RENAME_NODE\' | translate}}">' +
            '                   <span class="glyphicon glyphicon-font"></span>' +
            '               </button>' +
            '               <!-- monter le noeud -->' +
            '               <button class="btn btn-sm btn-default "' +
            '                       ng-if="(menu.canMoveNodeUp())"' +
            '                       ng-click="menu.moveNodeUp()"' +
            '                       uib-tooltip="{{\'TREEVIEW_MOVE_NODE_UP\' | translate}}">' +
            '                   <span class="glyphicon glyphicon-chevron-up"></span>' +
            '               </button>' +
            '               <!-- descendre le noeud -->' +
            '               <button class="btn btn-sm btn-default "' +
            '                       ng-if="(menu.canMoveNodeDown())"' +
            '                       ng-click="menu.moveNodeDown()"' +
            '                       uib-tooltip="{{\'TREEVIEW_MOVE_NODE_DOWN\' | translate}}">' +
            '                   <span class="glyphicon glyphicon-chevron-down"></span>' +
            '               </button>' +
            '               <!-- supprimer noeud -->' +
            '               <button class="btn btn-sm btn-default "' +
            '                       ng-if="menu.canRemoveNode()"' +
            '                       ng-click="menu.removeNode()"' +
            '                       uib-tooltip="{{\'TREEVIEW_DELETE_NODE\' | translate}}">' +
            '                   <span class="glyphicon glyphicon-remove"></span>' +
            '               </button>' +
            '            </li>' +
            '            <!-- actions ajoutées via config -->' +
            '            <li class="divider" ng-if="(tree.config.nodeActions.length > 0)"></li>' +
            '            <li ng-repeat="action in tree.config.nodeActions" ng-if="(tree.canDisplayAction(action, menu.getSelectedNode(tree.config.name)))">' +
            '                <a ng-click="action.function(menu.getSelectedNode(tree.config.name))" translate="{{ action.name }}"></a>' +
            '            </li>' +
            '            <li class="treeview-no-actions">' +
            '               <span class="glyphicon glyphicon-info-sign margin-right-6"></span><span translate="{{\'TREEVIEW_NO_ACTIONS\' | translate}}"></span>' +
            '            </li>' +
            '        </ul>' +
            '    </div>' +
            '');
    }
})(angular);