export default class ArchivageFtpModalController {
    static $inject = ['FTPArchiveeService', 'notification', '$uibModal'];

    /**
     * Construct
     */
    constructor(FTPArchiveeService, notification, $uibModal) {
        this.notification = notification;
        this.FTPArchiveeService = FTPArchiveeService;
        this.modal = $uibModal;
    }

    async $onInit() {
        this.newFtpA = { code: '', libelle: '', idType: null };
        this.typesOrig = await this.FTPArchiveeService.getAllTypes();
    }

    $onDestroy() {}
    async checkCodeUnicity() {
        const input = this.newFtpA.code;
        this.codeValid = null;
        if (input != '') {
            try {
                const data = await this.FTPArchiveeService.codeExists('FTP', input);
                this.codeValid = !data;
            } catch (err) {
                this.notification.error(err);
            }
        }
    }

    async appliquer() {
        if (this.newFtpA && this.newFtpA.code && this.newFtpA.libelle && this.newFtpA.idType && this.codeValid) {
            this.modalInstance.close(this.newFtpA);
        }
    }

    async cancel() {
        this.modalInstance.close();
    }

    selectItem(data) {
        this.newFtpA.idType = data.id;
        data.isSelected = true;
    }
}
