ContactValidator.$inject = ['validator'];

export default function ContactValidator(validator) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').lengthBetweenValues(0, 15).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance.ruleFor('nom').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('nom').lengthBetweenValues(0, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance.ruleFor('prenom').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('prenom').lengthBetweenValues(0, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    return instance;
}
