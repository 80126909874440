import validatorBuilder from 'validator-builder';

let factureArticleValidator = null;

export default class FactureArticle {
    constructor(data) {
        data = data || [];
        this.codeArticle = data.codeArticle;
        this.ht = data.ht;
        this.idArticle = data.idArticle;
        this.idFacture = data.idFacture;
        this.idTicket = data.idTicket;
        this.idTicketProduit = data.idTicketProduit;
        this.isFrancoForFactById = data.isFrancoForFactById;
        this.isTransporteForFactById = data.isTransporteForFactById;
        this.isVisible = data.isVisible;
        this.nomArticle = data.nomArticle;
        this.prixUnitaire = data.prixUnitaire;
        this.prixUnitaireFranco = data.prixUnitaireFranco;
        this.quantite = data.quantite;
        this.remise = data.remise;
        this.ristourne = data.ristourne;
        this.prixStandard = data.prixStandard;
        this.idTVA = data.idTVA;
        this.tauxTVA = data.tauxTVA;
        this.typeArticle = data.typeArticle;
        this.typeArticle = data.typeArticle;
        this.typeArticleEnum = data.typeArticleEnum;
        this.typePrix = data.typePrix || 1;
        this.typePrixEnum = data.typePrixEnum;
        this.unite = data.unite;
        this.uniteArrondi = data.uniteArrondi;
        this.idFactSomeThing = data.idFactSomeThing;
        this.idChantier = data.idChantier;
        this.nomChantier = data.nomChantier;
        this.codeChantier = data.codeChantier;
        this.idLieu = data.idLieu;
        this.nomLieu = data.nomLieu;
        this.codeLieu = data.codeLieu;
        this.idProducteur = data.idProducteur;
        this.codeProducteur = data.codeProducteur;
        this.nomProducteur = data.nomProducteur;
        this.libelleTypeBenne = data.libelleTypeBenne;

        this.idSiteCommercial = data.idSiteCommercial;
        this.nomSiteCommercial = data.nomSiteCommercial;
    }

    isValid() {
        factureArticleValidator = factureArticleValidator || validatorBuilder.getInstanceFor('FactureArticle');
        const validationResults = factureArticleValidator.validate(this);
        return validationResults.isValid;
    }
}
