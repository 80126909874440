const serviceUrl = `${__configuration.apiUrl}/massia/synthAdmin/synthMod`;

export default class SyntheseModelesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
        this.url = `${__configuration.apiUrl}/massia/setting/synthese`;
    }

    //GetAll
    async getSyntheseModeles(filters, sorts, pagination, activite, typeSynthese) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/synthese/model`, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || { skip: 0, take: 0 }),
                activite: JSON.stringify(activite || null),
                typeSynthese: typeSynthese
            }
        });

        return res.data;
    }

    //GetOne
    async getSyntheseModeleById(id) {
        const url = `${__configuration.apiUrl}/massia/synthese/model/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    //Create
    async createSyntheseModele(selectedSyntheseModele) {
        const res = await this.$http.post(`${__configuration.apiUrl}/massia/synthese/model`, selectedSyntheseModele);
        return res.data;
    }

    //Update
    async updateSyntheseModele(selectedSyntheseModele) {
        const url = `${__configuration.apiUrl}/massia/synthese/model/${selectedSyntheseModele.id}`;
        return this.$http.put(url, selectedSyntheseModele);
    }

    //Delete
    async deleteSyntheseModeleById(id) {
        const url = `${__configuration.apiUrl}/massia/synthese/model/${id}`;
        return this.$http.delete(url);
    }

    // pour remplir le iSteven de sélection des critères
    async getCriteresISteven(typeSynthId, domaine, select = false) {
        const url = `${this.url}/critere`;
        const res = await this.$http.get(url, {
            params: {
                typeSynthese: typeSynthId,
                domaine: domaine,
                select: select
            }
        });
        return res.data;
    }

    async getTypeSyntheses(activite) {
        const res = await this.$http.get(`${this.url}/type-synthese`, {
            params: {
                app: activite
            }
        });
        return res.data;
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }
}
