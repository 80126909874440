import { copy } from 'angular';
import { getNatureNamesByValues } from './../../../services/natures.enum';

export default class MesureFormEnteteController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'MesuresService',
        'MesuresTypesService',
        'ModalService',
        'notification',
        '$translate',
        'MassiaApplicationService',
        'RouterHistoryService'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        MesuresService,
        MesuresTypesService,
        ModalService,
        notification,
        $translate,
        MassiaApplicationService,
        RouterHistoryService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.MesuresService = MesuresService;
        this.MesuresTypesService = MesuresTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.MassiaApplicationService = MassiaApplicationService;
        this.natures = [];
        this.naturesType = getNatureNamesByValues();
        this.RouterHistoryService = RouterHistoryService;
    }

    async $onInit() {
        this.types = this.types || [];
        this.application = this.MassiaApplicationService.getApplication();
        this.getTypes();
        this.loadNatures();
        await this.getNiveauVisibilite();
        if (!this.redirection) {
            const that = this;
        }
    }

    getIdxMesPrincipale() {
        return this.mesure.sousMesures.findIndex((x) => x.isDefault); //this.mesure.sousMesures.findIndex(x => x.code === this.mesure.entete.code);
    }

    $onDestroy() {
        if (this.watchOne) {
            this.watchOne();
        }
    }

    async getNiveauVisibilite() {
        if (this.mesure.entete.typeId) {
            this.mesure.entete.niveauVisibilite = await this.MesuresTypesService.GetNiveauVisibilite(this.mesure.entete.typeId);
        }
    }

    async loadNatures() {
        this.natures = [];

        try {
            const natures = this.MesuresService.getNatures();

            angular.forEach(natures, (type, id) => {
                if ((this.application == 'laboratoire' || id !== '8') && id !== '11') {
                    this.natures.push({
                        idNature: parseInt(id),
                        label: this.$translate.instant(`MESURES.NATURES.${id}`)
                    });
                }
            });
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    getDomaineCourant() {
        if (
            this.$state.current.name === 'mesures.edit' ||
            this.$state.current.name === 'mesures.new' ||
            this.$state.current.name === 'mesures.duplicate'
        ) {
            return 'mesures';
        } else if (
            this.$state.current.name === 'mesctrl.edit' ||
            this.$state.current.name === 'mesctrl.new' ||
            this.$state.current.name === 'mesctrl.duplicate'
        ) {
            return 'mesctrl';
        } else if (
            this.$state.current.name === 'messecu.edit' ||
            this.$state.current.name === 'messecu.new' ||
            this.$state.current.name === 'messecu.duplicate'
        ) {
            return 'messecu';
        } else if (
            this.$state.current.name === 'essais.edit' ||
            this.$state.current.name === 'essais.new' ||
            this.$state.current.name === 'essais.duplicate'
        ) {
            return 'essais';
        }
    }

    onUpdateAdditionalInfoMesure(additionnalInfoMesure) {
        this.mesure.entete.additionnalInfoMesure = additionnalInfoMesure;
    }

    async createType() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'MESURES.CONFIRM_CREATE_TYPE.TITLE',
            modalMsg: 'MESURES.CONFIRM_CREATE_TYPE.MESSAGE',
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(() => this.$state.go(this.getDomaineCourant() + '.newType'));
    }

    async getTypes() {
        this.startLoading();

        try {
            this.types = await this.MesuresTypesService.getAll(this.getDomaineCourant());
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.mesure.entete.codeExists = await this.MesuresService.codeExists(code);
                } else {
                    this.mesure.entete.codeExists = true; //MN: le code ne doit contenir que" a-zA-Z0-9_|""
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.mesure.entete.codeExists = null;
        }
    }

    traduit(symbole) {
        return this.MesuresService.traduitSymbole(symbole);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
