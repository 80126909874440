export default class ControleDetailController {
    static $inject = ['$stateParams', '$state', 'ControlesService', 'notification', 'ControlesCommunicationService'];

    constructor($stateParams, $state, ControlesService, notification, ControlesCommunicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.ControlesService = ControlesService;
        this.notification = notification;
        this.ControlesCommunicationService = ControlesCommunicationService;
    }

    $onInit() {
        this.loading = false;
        this.controle = {};
        this.controleId = undefined;
        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.initControle();
    }

    async initControle() {
        this.controleId = this.$stateParams.id;

        this.startLoading();
        try {
            await this.getControleEntete(this.controleId);
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('controles.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getControleEntete(controleId) {
        this.controle.entete = await this.ControlesService.getControleEnteteById(controleId);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
