(function (angular, undefined) {
    'use strict';

    angular.module('blocks.smart.table')
        .directive('stPersistentTableHeader', stPersistentTableHeader);

    stPersistentTableHeader.$inject = ['$window', '_'];

    function stPersistentTableHeader($window, _) {
        return {
            require: '^stTable',
            link: function (scope, element, attributes, controller) {
                let windowElement = angular.element($window);
                const originalHeader = angular.element(element[0]).find('thead');
                const resizeObserver = new ResizeObserver((entries) => {
                    changeOnResize();
                });
                var header = {
                    original: null,
                    clone: null
                };

                init();

                function init() {
                    resizeObserver.observe(element[0]);
                    windowElement.bind("scroll", _.throttle(changeOnScroll, 20));
                    windowElement.bind("resize", _.throttle(changeOnResize, 20));

                    scope.$on('$destroy', destroy);
                }

                function changeOnScroll() {
                    let boundingElement = element[0].getBoundingClientRect();
                    const el = angular.element(document.querySelector('#app-header'))[0].children[0].children[0];
                    const top = el.getBoundingClientRect().bottom;
                    if (element
                        && boundingElement.top < top
                        && boundingElement.bottom > 0
                        && !_.some(getFixedHeader())) {
                        addFixedHeader(top);
                    } else if (boundingElement.top >= 0 || boundingElement.bottom <= 0) {
                        removeFixedHeader();
                    }
                }

                function changeOnResize() {
                    changeOnScroll();
                    if (getFixedHeader() !== undefined) {
                        if (header && header.original) {
                            let child = header.original.children()[0];
                            let cellsCol = child.children;
                            let cellsNumber = cellsCol.length;
                            for (let i = 0; i < cellsNumber; i++) {
                                let width = cellsCol[i].offsetWidth;
                                let cell = angular.element(header.clone.children()[0].children[i]);
                                cell.css('width', width + 'px');
                            }
                        }

                    }
                }

                function addFixedHeader(top) {
                    //le header est hors de vue
                    header = {
                        clone: originalHeader.clone(),
                        original: originalHeader
                    };

                    header.clone.attr('fixedHeader', 'true');
                    header.original.after(header.clone);

                    let cellsCol = header.clone.children()[0].children;
                    let cellsNumber = cellsCol.length;
                    for (let i = 0; i < cellsNumber; i++) {
                        let attr = cellsCol[i].attributes;
                        let width = cellsCol[i].offsetWidth;
                        let cell = angular.element(header.clone.children()[0].children[i]);

                        for (let j = 0; j < attr.length; j++) {
                            const atr = attr[j];
                            if (atr.name === 'class') {
                                cell.attr(atr.name, atr.value.replace(/width-[0-9]+p/gi, ''));
                            }
                        }
                        cell.css('width', width + 'px');
                    }
                    header.clone.css('position', 'fixed');
                    header.clone.css('top', top + 'px');
                    header.clone.css('right', '');
                    header.clone.css('left', '');
                    header.clone.css('z-index', '10');
                    header.clone.css('cursor', 'pointer');
                    header.clone.css('background', 'white');
                    header.clone.css('padding-top', '8px');
                    header.clone.css('visibility', 'visible');

                    header.original.css('visibility', 'hidden');
                }

                function getFixedHeader() {
                    return angular.element(document.querySelector("[fixedHeader]"));
                }

                function removeifExist() {
                    let oldheader = getFixedHeader();
                    if (oldheader !== undefined) {
                        oldheader.remove();
                    }
                }

                function removeFixedHeader() {
                    removeifExist();
                    let tableHeader = originalHeader;
                    tableHeader.css('visibility', 'visible');
                }

                function destroy() {
                    windowElement.unbind("scroll", changeOnScroll);
                    windowElement.unbind("resize", changeOnResize);
                    resizeObserver.unobserve(element[0]);
                }
            }
        };
    }
})(angular);