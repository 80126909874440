(function (angular, undefined) {
    'use strict';

    angular.module('blocks.helpers').directive('acAutofocus', acAutofocus);
    acAutofocus.$inject = ['$timeout', '$document'];

    function acAutofocus($timeout, $document) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var onDestroy = scope.$on('$destroy', dispose);
                var timer;
                var time = 250;
                var body = $document[0].body;

                if (body.classList.contains('modal-open') && globalHelpers.isInternetExplorer()) {
                    // sous IE, on attend la fin de l'animation de la modale avant
                    // d'appliquer l'autofocus
                    time = 550;
                }

                scope.$watch(attrs.acAutofocus, function (newValue) {
                    if (newValue != false) { // jshint ignore:line
                        setFocus();
                    }
                });

                function setFocus() {
                    if (attrs.acAutofocus !== 'false') {
                        timer = $timeout(function () {
                            focus(element);
                        }, time);
                    }
                }

                function focus(element) {
                    var elt = element[0];
                    switch (elt.tagName) {
                        case 'DIV':
                        case 'FORM':
                            // dans le cas d'un élement contenant un input (comme une directive),
                            // on applique le focus sur le premier input trouvé
                            focus(element.find('INPUT', 'TEXTAREA', 'SELECT'));
                            break;
                        default:
                            elt.focus();
                            break;
                    }
                }

                function dispose() {
                    if (timer !== undefined) {
                        $timeout.cancel(timer);
                        timer = undefined;
                    }

                    if (onDestroy !== undefined) {
                        onDestroy();
                    }
                }
            }
        };
    }
})(angular);
