export default function Configure($stateProvider) {
    $stateProvider.state('series-tamis-labo', {
        parent: 'common',
        url: '/series-tamis-labo',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.ESSAIS_NORMES" | translate }}'
        }
    });

    $stateProvider.state('series-tamis-labo.list', {
        url: '/list',
        template: '<series-tamis></series-tamis>',
        rights: { domain: 'seriedim', right: 'read', application: 'laboratoire' },
        ncyBreadcrumb: {
            label: '{{ "SERIES_TAMIS.BREADCRUMBS.SERIES_TAMIS_LIST" | translate}}'
        },
        navigation: {
            menu: 'essais_normes_seul',
            translate: 'SERIES_TAMIS.BREADCRUMBS.SERIES_TAMIS_LIST',
            order: 1
        },
        search: 'SERIES_TAMIS.BREADCRUMBS.SERIES_TAMIS_LIST'
    });

    $stateProvider.state('series-tamis-labo.list.detail', {
        url: '/{id}',
        template: '<serie-tamis-detail></serie-tamis-detail>',
        rights: { domain: 'seriedim', right: 'read' },
        ncyBreadcrumb: {
            parent: 'series-tamis-labo.list',
            label: '{{ "SERIES_TAMIS.BREADCRUMBS.SERIE_TAMIS_DETAIL" | translate}}'
        }
    });

    $stateProvider.state('series-tamis-labo.new', {
        url: '/new',
        template: '<serie-tamis-form></serie-tamis-form>',
        rights: { domain: 'seriedim', right: 'create' },
        ncyBreadcrumb: {
            parent: 'series-tamis-labo.list',
            label: '{{ "SERIES_TAMIS.BREADCRUMBS.SERIE_TAMIS_NEW" | translate}}'
        },
        search: 'SERIES_TAMIS.BREADCRUMBS.SERIE_TAMIS_NEW'
    });

    $stateProvider.state('series-tamis-labo.edit', {
        url: '/{id}/edit',
        template: '<serie-tamis-form></serie-tamis-form>',
        rights: { domain: 'seriedim', right: 'update' },
        ncyBreadcrumb: {
            parent: 'series-tamis-labo.list',
            label: '{{ "SERIES_TAMIS.BREADCRUMBS.SERIE_TAMIS_EDIT" | translate }}'
        }
    });

    $stateProvider.state('series-tamis-labo.newType', {
        url: '/new-type',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        params: { parentState: 'series-tamis-labo.new' },
        ncyBreadcrumb: {
            parent: 'series-tamis-labo.new',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });

    $stateProvider.state('series-tamis-labo.duplicate', {
        url: '/{id}/duplicate',
        template: '<serie-tamis-form></serie-tamis-form>',
        rights: { domain: 'seriedim', right: 'create' },
        ncyBreadcrumb: {
            parent: 'series-tamis-labo.list',
            label: '{{ "SERIES_TAMIS.BREADCRUMBS.SERIE_TAMIS_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });
}

Configure.$inject = ['$stateProvider'];
