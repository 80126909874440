import EditDistancesModalController from './edit-distances-modal.controller';

export default {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: require('./edit-distances-modal.html'),
    controller: EditDistancesModalController
};
