import GestionStocksAjustementController from './gestion.stocks.ajustement.controller';

export default {
    bindings: {
        ligne: '=',
        modalInstance: '='
    },
    template: require('./gestion.stocks.ajustement.html'),
    controller: GestionStocksAjustementController
};
