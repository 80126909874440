export default function Configure($stateProvider) {
    $stateProvider.state('zone-abattages-qse', {
        parent: 'common',
        url: '/zone-abattages-qse',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.ENVIRONNEMENT" | translate }}'
        }
    });

    $stateProvider.state('zone-abattages-qse.list', {
        url: '/list',
        template: '<zone-abattages></zone-abattages>',
        rights: { domain: 'zonesabat', right: 'read', application: 'qse' },
        ncyBreadcrumb: {
            label: '{{ "ZONEABATTAGES.BREADCRUMBS.ZONEABATTAGES_LIST" | translate}}'
        },
        // on affiche la liste des zone-abattages dans la navigation
        navigation: {
            menu: 'environnement_tirs_mine',
            translate: 'ZONEABATTAGES.BREADCRUMBS.ZONEABATTAGES_LIST',
            order: 1
        },
        // la liste des zone-abattages fait partie des points d'entrée recherchables dans la navigation
        search: 'ZONEABATTAGES.BREADCRUMBS.ZONEABATTAGES_LIST'
    });

    $stateProvider.state('zone-abattages-qse.list.detail', {
        url: '/{id}',
        template: '<zone-abattages-detail></zone-abattages-detail>',
        rights: { domain: 'zonesabat', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher zone-abattages > détail
            parent: 'zone-abattages-qse.list',
            label: '{{ "ZONEABATTAGES.BREADCRUMBS.ZONEABATTAGE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('zone-abattages-qse.new', {
        url: '/new',
        template: '<zone-abattages-form></zone-abattages-form>',
        rights: { domain: 'zonesabat', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher zone-abattages > ajouter
            parent: 'zone-abattages-qse.list',
            label: '{{ "ZONEABATTAGES.BREADCRUMBS.ZONEABATTAGE_NEW" | translate}}'
        },
        // l'ajout d'une entite fait partie des points d'entrée recherchables dans l'application
        search: 'ZONEABATTAGES.BREADCRUMBS.ZONEABATTAGE_NEW'
    });

    $stateProvider.state('zone-abattages-qse.edit', {
        url: '/{id}/edit',
        template: '<zone-abattages-form></zone-abattages-form>',
        rights: { domain: 'zonesabat', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher zone-abattages > modifier
            parent: 'zone-abattages-qse.list',
            label: '{{ "ZONEABATTAGES.BREADCRUMBS.ZONEABATTAGE_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
