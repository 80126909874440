import SocieteCoordonnees from './societe.coordonnees.model';

export default class SocieteCoordonneesController {
    static $inject = ['$state', '$stateParams', 'SocietesService', 'SocietesTypesService', 'ModalService', 'notification', '$translate'];

    constructor($state, $stateParams, SocietesService, SocietesTypesService, ModalService, notification, $translate) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SocietesService = SocietesService;
        this.SocietesTypesService = SocietesTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
    }

    $onInit() {
        this.societe = this.societe || {};
        this.coordonnees = new SocieteCoordonnees(this.societe.coordonnees);
        this.getListeBanques();
        if (this.societe.id) {
            this.getListeCoordonnees(this.societe.id);
        }
        this.popoverModifCoordonnees = {
            templateUrl: 'popoverModifCoordonnees.html'
        };
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.coordonnees.codeExists = await this.SocietesService.codeExists(code);
                }
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.coordonnees.codeExists = null;
        }
    }

    async getListeBanques() {
        this.startLoading();
        try {
            this.listeBanques = await this.SocietesService.getBanques();
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.stopLoading();
        }
    }
    async getListeCoordonnees(idSociete) {
        this.startLoading();
        try {
            this.societe.listeCoordonnees = await this.SocietesService.getCoordonnees(idSociete);
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.stopLoading();
        }
    }
    async deleteCoordonnees(coord) {
        this.startLoading();
        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('SOCIETES.COORDONNEES.DELETE.TITLE'),
            modalMsg: this.$translate.instant('SOCIETES.COORDONNEES.DELETE.MESSAGE'),
            headerClass: 'modal-danger'
        });

        modalInstance.result.then(async () => {
            try {
                const id = await this.SocietesService.deleteCoordonnees(coord.id);
                this.notification.success('SOCIETES.COORDONNEES.DELETE.SUCCESS');
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            } finally {
                this.getListeCoordonnees(this.societe.id);
            }
        });
        this.stopLoading();
    }
    async addRIB() {
        this.startLoading();
        try {
            this.new.idSociete = this.societe.id;
            this.new.nomBanque = this.new.banque.libelle;
            this.new.idBanque = this.new.banque.id;
            this.newCoord = new SocieteCoordonnees(this.new);
            const id = await this.SocietesService.persistCoordonnees(this.newCoord);
            this.getListeCoordonnees(this.societe.id);
            this.new = {};
            this.notification.success('SOCIETES.COORDONNEES.CREATED');
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.stopLoading();
        }
    }
    initUpdate(coord, index) {
        this.edit = angular.copy(coord);
        this.edit.banque = {
            id: coord.banqueId,
            libelle: coord.nomBanque
        };
        this.clickedPopoverIndex = index;
    }
    async updateRIB() {
        this.edit.nomBanque = this.edit.banque.libelle;
        this.edit.banqueId = this.edit.banque.id;
        this.startLoading();
        try {
            const id = await this.SocietesService.updateCoordonnees(this.edit.id, this.edit);
            this.edit = {};
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.getListeCoordonnees(this.societe.id);
            this.stopLoading();
        }
    }

    setBanque() {
        if (this.new.banque.codeBanque != '') {
            this.new.codeBanque = this.new.banque.codeBanque;
        } else if (this.new.banque.bic != '' && this.new.banque.bic != null) {
            const codeBanqueFromBIC = this.new.banque.bic.substring(0, 4);
            this.new.codeBanque = codeBanqueFromBIC;
        }

        if (this.new.banque.bic != '' && this.new.banque.bic != null) {
            const codePaysFromBIC = this.new.banque.bic.substring(4, 6);
            this.new.banque.codePays = codePaysFromBIC;
            this.new.iban = codePaysFromBIC;
        } else {
            this.notification.warning(this.$translate.instant('SOCIETES.COORDONNEES.MISSINGDATABANQUE'));
        }
    }

    calcIban() {
        if (this.new.codeBanque != null && this.new.guichet != null && this.new.compte != null && this.new.rib != null) {
            // check the number of characters

            // la méthode GetIban donne le même résultat
            // let result = this.GetIban();

            // on parcourt les lettres du code pays
            // on les convertie en ascii et on retranche 55
            // let codePaysConverted = "";
            // for (let index = 0; index < this.new.banque.codePays.length; index++) {
            //     const element = this.new.banque.codePays[index];
            //     let a = element.charCodeAt(0);
            //     codePaysConverted = codePaysConverted + (a - 55);
            // }

            // // SL le numéro de compte peut avoir une lettre. donc il faut l'enlever
            // var numb = this.new.compte.match(/\d/g);
            // numb = numb.join("");

            // var letters = this.new.compte.replace(/[0-9]/g, '');
            // let newLetters = "";
            // for (let index2 = 0; index2 < letters.length; index2++) {
            //     const element2 = letters[index2];
            //     let b = element2.charCodeAt(0);
            //     newLetters = newLetters + (b - 55);
            // }
            // console.log("1", newLetters);

            // on concatene les éléments nécessaires
            const concatElts = this.new.codeBanque + this.new.guichet + this.new.compte + this.new.rib + this.new.banque.codePays + '00';
            // on parcourt la concatenation, et on converti les lettres en ascii puis on retranche 55
            let numberToModulo = '';
            for (let char = 0; char < concatElts.length; char++) {
                const element = concatElts[char];
                if (isNaN(element)) {
                    // convert and add
                    const c = element.charCodeAt(0);
                    const elementConverted = c - 55;
                    numberToModulo = numberToModulo + elementConverted;
                } else {
                    //add
                    numberToModulo = numberToModulo + element;
                }
            }

            // calculer le modulo 97 et on retranche 98 et on obtient la clé de contrôle
            const cleControle = 98 - (numberToModulo % 97);
            this.new.iban = this.new.banque.codePays + cleControle + this.new.codeBanque + this.new.guichet + this.new.compte + this.new.rib;
        }
    }

    // méthode récupérée sur wikipedia
    // https://fr.wikipedia.org/wiki/Basic_Bank_Account_Number#Conversion_du_RIB_en_IBAN_en_JavaScript
    GetIban() {
        const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let tmp = '';
        tmp =
            '' +
            String(this.new.codeBanque) +
            String(this.new.guichet) +
            String(this.new.compte) +
            String(this.new.rib) +
            String(this.new.banque.codePays) +
            '00';
        tmp = tmp.toUpperCase();
        tmp = tmp.split('');

        for (let i = 0; i < tmp.length; i++) {
            if (alphabet.indexOf(tmp[i]) != -1) {
                tmp[i] = String(alphabet.indexOf(tmp[i]) + 10);
            }
        }

        tmp = tmp.join('');
        const moduloRemainder = parseInt(tmp % 97);
        let ibanKey = (98 - moduloRemainder).toString();
        if (ibanKey.length == 1) {
            ibanKey = '0' + ibanKey;
        }
        return this.new.banque.codePays + ibanKey + this.new.codeBanque + this.new.guichet + this.new.compte + this.new.rib;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
