export default function Configure($stateProvider) {
    $stateProvider.state('synthese', {
        parent: 'common',
        url: '/control',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.ANALYSE" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
