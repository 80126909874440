import { ui } from 'angular';
import { Component, OnDestroy, OnInit } from '../../../core/decorators';
import { FilterService } from '../../../core/http/filter.service';
import { FilterDto } from './models/filter.dto';
import { ProductService } from '../../../core/http/product.service';
import { Produit } from '../../../core/models/produit/Produit';

@Component({
    selector: 'massia-filter',
    bindings: {
        domain: '@',
        callback: '&',
        idType: '<',
        idFamille: '<'
    },
    template: require('./massia-filter.tpl.html')
})
export class MassiaFilterComponent implements OnInit, OnDestroy {
    $scope: ng.IScope;
    filterSvc: FilterService;
    filters: Promise<FilterDto[]>;
    modalSvc: ui.bootstrap.IModalService;
    domain: string;
    produitsService: ProductService;
    selectedFilters: FilterDto;
    formatedFilters: FilterDto;
    filtredProducts: Produit[];
    callback: Function;
    idType: number;
    idFamille: number;
    unregister: (() => void)[] = [];

    /* @ngInject */
    constructor($scope: ng.IScope, FilterService: FilterService, $uibModal: ui.bootstrap.IModalService, ProduitsService: ProductService) {
        this.filterSvc = FilterService;
        this.modalSvc = $uibModal;
        this.produitsService = ProduitsService;
        this.$scope = $scope;
    }

    async ngOnInit(): Promise<void> {
        this.unregister.push(
            this.$scope.$watch(
                () => this.idType,
                () => {
                    if (this.idType) {
                        this.filters = this.filterSvc.getAllFilter(this.domain, this.idType, this.idFamille);
                    }
                },
                true
            )
        );
        this.unregister.push(
            this.$scope.$watch(
                () => this.idFamille,
                () => {
                    if (this.idFamille) {
                        this.filters = this.filterSvc.getAllFilter(this.domain, this.idType, this.idFamille);
                    }
                },
                true
            )
        );
    }
    ngOnDestroy(): void {
        for (const un of this.unregister) {
            un();
        }
    }

    async openModal() {
        try {
            const filters = this.filters;
            const modal = this.modalSvc.open({
                templateUrl: 'filterModal.html',
                size: 'lg',
                windowClass: 'modern-modal',
                controllerAs: '$ctrl',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance: ui.bootstrap.IModalInstanceService) {
                        const $ctrl = this;

                        $ctrl.loadFilter = async () => {
                            $ctrl.filters = await filters;
                        };

                        $ctrl.ok = async () => {
                            let result: FilterDto[] = [];
                            for (const filter of $ctrl.filters) {
                                const values = filter.values;
                                if (Array.isArray(values) && values.some((x) => x.selected)) {
                                    const selected: FilterDto = {
                                        ...filter,
                                        values: [...filter.values.filter((x: any) => x.selected)]
                                    };
                                    result.push(selected);
                                } else if (
                                    (filter.typeChamp === 4 && filter.selected) ||
                                    (filter.values &&
                                        typeof filter.values !== 'undefined' &&
                                        (typeof filter.values === 'string' ||
                                            typeof filter.values === 'number' ||
                                            (typeof filter.values === 'object' && filter.typeChamp === 5)))
                                ) {
                                    result.push({
                                        ...filter
                                    });
                                }
                            }
                            $uibModalInstance.close(result);
                        };

                        $ctrl.cancel = () => {
                            $uibModalInstance.dismiss('cancel');
                        };
                    }
                ]
            });

            const res: FilterDto[] = await modal.result;
            this.callback({ $filters: res });
        } catch (err) {
            // Empty statement cause modal dismiss
        }
    }
}
