export default class TransporteurInterfaceController {
	static $inject = ['$scope', 'SitesService', 'notification'];

	constructor($scope, SitesService, notification) {
		this.$scope = $scope;
		this.SitesService = SitesService;
		this.notification = notification;
	}

	$onInit() {
		this.site = this.site || {};
		this.unregister = this.$scope.$watch(
			() => this.isOpen,
			() => {
				if (this.isOpen && !this.interface) {
					this.loadInterface();
				}
			},
			true
		);
	}

	async loadInterface() {
		this.startLoading();
		try {
			this.typeInterfaces = await this.SitesService.getTypeInterface();
			this.site.interface = await this.SitesService.getTransporteurInterface(this.site.id);
		} catch (ex) {
			this.notification.error(ex);
		} finally {
			this.stopLoading();
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
