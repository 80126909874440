import L from 'leaflet/dist/leaflet';

export default class PrelevementCarteController {
    constructor($stateParams, PrelevementsCommunicationService, PrelevementsService, notification, ModalService, $uibModal) {
        this.$stateParams = $stateParams;
        this.PrelevementsCommunicationService = PrelevementsCommunicationService;
        this.PrelevementsService = PrelevementsService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.initCarte();
        this.latitudeDepart = this.saisie.latitude;
        this.longitudeDepart = this.saisie.longitude;
    }

    async initCarte() {
        this.zoom = 5;
        this.longitude = 3.5;
        this.latitude = 45.5;
        if (this.saisie && this.saisie.longitude) {
            this.longitude = this.saisie.longitude;
        }
        if (this.saisie && this.saisie.latitude) {
            this.latitude = this.saisie.latitude;
        }
        if (this.saisie && this.saisie.longitude && this.saisie.latitude) {
            this.zoom = 18;
        } else {
            if (this.saisie && this.saisie.pointMesure && this.saisie.pointMesure.id && this.modeLocalisation == 0) {
                const ptMesure = await this.PrelevementsService.getPointMesure(this.saisie.pointMesure.id);
                if (ptMesure.longitude && ptMesure.latitude) {
                    this.saisie.longitude = ptMesure.longitude;
                    this.saisie.latitude = ptMesure.latitude;
                    this.longitude = ptMesure.longitude;
                    this.latitude = ptMesure.latitude;
                    this.zoom = 18;
                }
            } else if (this.saisie && this.saisie.producteur && this.saisie.producteur.id) {
                const site = await this.PrelevementsService.getSite(this.saisie.producteur.id);
                if (site.longitude && site.latitude) {
                    this.saisie.longitude = site.longitude;
                    this.saisie.latitude = site.latitude;
                    this.longitude = site.longitude;
                    this.latitude = site.latitude;
                    this.zoom = 15;
                }
            }
        }
        this.map = L.map('map').setView([this.latitude, this.longitude], this.zoom); //par défaut

        this.map.scrollWheelZoom.disable();

        this.map.on(
            'mapReady',
            function (map) {
                setTimeout(() => {
                    map.invalidateSize();
                }, 0);
            },
            this
        );

        //Couches
        const Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            //z:niveau de zoom, x et y coordonnées du pavé
            attribution: '&copy; Les contributeurs <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            maxZoom: 20 //zoom maximum de la carte
        });
        Mapnik.addTo(this.map);

        //ajout du plan topo
        this.planTopo();

        this.blueMarker = L.icon({
            iconUrl: 'assets/img/pin_GB.png',
            iconSize: [64, 64], // size of the icon
            iconAnchor: [8, 55] // point of the icon which will correspond to marker's location
        });

        if (this.modeLocalisation == 1) {
            this.marqueurPtMes = L.marker([this.latitude, this.longitude], { icon: this.blueMarker, draggable: true });
            this.marqueurPtMes.on(
                'dragend',
                function (event) {
                    const marker = event.target;
                    const position = marker.getLatLng();
                    this.changePositionMarqueur(position);
                },
                this
            );
            this.marqueurPtMes.addTo(this.map);
            this.map.on(
                'click',
                function (event) {
                    this.changePositionMarqueur(event.latlng);
                },
                this
            );
            this.map.invalidateSize();
            this.afficheCarte();
        } else {
            this.marqueurPtMes = L.marker([this.latitude, this.longitude], { icon: this.blueMarker, draggable: false });
            this.marqueurPtMes.addTo(this.map);
        }

        this.map.panTo(new L.LatLng(this.latitude, this.longitude));
    }

    async planTopo() {
        if (this.saisie && this.saisie.producteur && this.saisie.producteur.id) {
            const planTopoSite = await this.PrelevementsService.getPlanTopo(this.saisie.producteur.id);
            if (planTopoSite) {
                //var mapExtent = [planTopoSite.longitudeMin, planTopoSite.latitudeMin, planTopoSite.longitudeMax, planTopoSite.latitudeMax];
                const mapMinZoom = 15;
                const mapMaxZoom = 20;
                /*var bounds = new L.LatLngBounds(
                    new L.LatLng(mapExtent[1], mapExtent[0]),
                    new L.LatLng(mapExtent[3], mapExtent[2]));
                this.map.fitBounds(bounds);*/
                const options = {
                    idSite: this.saisie.producteur.id,
                    annee: planTopoSite.annee,
                    minZoom: mapMinZoom,
                    maxZoom: mapMaxZoom,
                    opacity: 1.0,
                    attribution: 'Rendered with <a href="http://www.maptiler.com/">MapTiler</a>',
                    tms: false
                };
                L.tileLayer('assets/topo/{idSite}/{annee}/{z}/{x}/{y}.png', options).addTo(this.map);
            }
        }
    }

    changePositionMarqueur(evtLatLgn) {
        this.marqueurPtMes.setLatLng(evtLatLgn).update();
        this.saisie.latitude = evtLatLgn.lat;
        this.saisie.longitude = evtLatLgn.lng;
        this.map.invalidateSize();
        this.afficheCarte();
    }

    afficheCarte() {
        //est-ouest:latitude, nord-sud:longitude
        if (this.map && (this.longitude != this.saisie.longitude || this.latitude != this.saisie.latitude)) {
            let longitude = this.saisie.longitude;
            let latitude = this.saisie.latitude;
            if (!longitude || !latitude) {
                longitude = 3.5;
                latitude = 45.5;
            }
            if (this.longitude != this.saisie.longitude || this.latitude != this.saisie.latitude) {
                this.longitude = longitude;
                this.latitude = latitude;
                this.map.panTo(new L.LatLng(this.latitude, this.longitude));
            }
        }
    }

    cancel() {
        this.saisie.latitude = this.latitudeDepart;
        this.saisie.longitude = this.longitudeDepart;
        this.modalInstance.close();
    }

    async ok() {
        if (this.modeLocalisation != 1) {
            this.saisie.latitude = this.latitudeDepart;
            this.saisie.longitude = this.longitudeDepart;
        }
        this.modalInstance.close(this.saisie);
    }
}

PrelevementCarteController.$inject = [
    '$stateParams',
    'PrelevementsCommunicationService',
    'PrelevementsService',
    'notification',
    'ModalService',
    '$uibModal'
];
