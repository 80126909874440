const serviceUrl = `${__configuration.apiUrl}/massia/grillesTarifaires/prix`;

export default class TarifsService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getTarifs(domaine, id, filters) {
		const monFiltre = JSON.stringify(filters);
		const url = `${__configuration.apiUrl}/massia/grillesTarifaires/${domaine}/${id}/prix?filters=${monFiltre}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getGrilles(domaine, id, filters) {
		const monFiltre = JSON.stringify(filters);
		const url = `${__configuration.apiUrl}/massia/grilles/${domaine}/${id}?filters=${monFiltre}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSocietes() {
		const siteUrl = `${__configuration.apiUrl}/massia/societes-commerciales`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getTransporteurs() {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-transporteurs/headers`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getSitesCommerciauxBySociete(societeId) {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-commerciaux/header/${societeId}`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async createGrille(grille) {
		const url = `${__configuration.apiUrl}/massia/grilles`;
		const result = await this.$http.post(url, grille);
		return result.data;
	}

	async updateGrille(grille) {
		const url = `${__configuration.apiUrl}/massia/grilles/${grille.id}`;
		const result = await this.$http.put(url, grille);
		return result.data;
	}

	async updateGrilleStock(grille) {
		const url = `${__configuration.apiUrl}/massia/grillesStock/${grille.id}`;
		const result = await this.$http.put(url, grille);
		return result.data;
	}

	async deleteGrille(grilleId) {
		const url = `${__configuration.apiUrl}/massia/grilles/${grilleId}`;
		const result = await this.$http.delete(url);
		return result.data;
	}

	async getGrille(grilleId) {
		const url = `${__configuration.apiUrl}/massia/grilles/${grilleId}`;
		const result = await this.$http.get(url);
		return result.data;
	}

	async getGrilleTransport(grilleId, filters, sorts, pagination) {
		const url = `${__configuration.apiUrl}/massia/grilles/prix-transport/${grilleId}`;

		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async getGrilleGranulat(grilleId, filters, sorts, pagination) {
		const url = `${__configuration.apiUrl}/massia/grilles/prix-granulat/${grilleId}`;

		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async getGrilleEnrobe(grilleId, filters, sorts, pagination) {
		const url = `${__configuration.apiUrl}/massia/grilles/prix-enrobe/${grilleId}`;

		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async getGrilleLiant(grilleId, filters, sorts, pagination) {
		const url = `${__configuration.apiUrl}/massia/grilles/prix-liant/${grilleId}`;

		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async getGrillePrestation(grilleId, filters, sorts, pagination) {
		const url = `${__configuration.apiUrl}/massia/grilles/prix-prestation/${grilleId}`;

		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async getGrilleByCode(grilleCode) {
		const url = `${__configuration.apiUrl}/massia/grilles/code/${grilleCode}`;
		const result = await this.$http.get(url);
		return result.data;
	}

	async createPrix(prix) {
		const url = `${__configuration.apiUrl}/massia/prix`;
		const result = await this.$http.post(url, prix);
		return result.data;
	}

	async updatePrix(prix) {
		const url = `${__configuration.apiUrl}/massia/prix/${prix.id}`;
		const result = await this.$http.put(url, prix);
		return result.data;
	}

	async deletePrix(ids) {
		const deleteUrl = `${__configuration.apiUrl}/massia/prix`;
		return this.$http.delete(deleteUrl, {
			params: {
				ids: JSON.stringify(ids || [])
			}
		});
	}

	async getGrillesParentes(filters) {
		const monFiltre = JSON.stringify(filters);
		const url = `${__configuration.apiUrl}/massia/grilles/parentes?filters=${monFiltre}`;
		const result = await this.$http.get(url);
		return result.data;
	}

	async getTvaListe() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/tvas/headers`);
		return res.data;
	}

	async getGrillesStock(siteCommercialId) {
		const url = `${__configuration.apiUrl}/massia/grillesStock/siteCommercial/${siteCommercialId}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getGrilleStock(grilleId) {
		const url = `${__configuration.apiUrl}/massia/grillesStock/${grilleId}`;
		const result = await this.$http.get(url);
		return result.data;
	}

	async deleteGrilleStock(grilleId) {
		const url = `${__configuration.apiUrl}/massia/grillesStock/${grilleId}`;
		const result = await this.$http.delete(url, grilleId);
		return result.data;
	}

	async createLigneStock(ligne) {
		const url = `${__configuration.apiUrl}/massia/grilleStockLigne`;
		const result = await this.$http.post(url, ligne);
		return result.data;
	}

	async updateLigneStock(ligne) {
		const url = `${__configuration.apiUrl}/massia/grilleStockLigne/${ligne.id}`;
		const result = await this.$http.put(url, ligne);
		return result.data;
	}

	async deleteLigneStock(ligne) {
		const url = `${__configuration.apiUrl}/massia/grilleStockLigne/${ligne.id}`;
		const result = await this.$http.delete(url, ligne);
		return result.data;
	}

	async createGrilleStock(grille) {
		const url = `${__configuration.apiUrl}/massia/grillesStock`;
		const result = await this.$http.post(url, grille);
		return result.data;
	}

	async exportEnrobes(modeImpr, grilleId, dateAchat, typePrix) {
		const url = `${__configuration.apiUrl}/massia/grillesTarifaires/print`;
		const res = await this.$http.get(url, {
			params: {
				type: typePrix,
				modeImpr: JSON.stringify(modeImpr),
				grilleId: grilleId,
				dateAchat: dateAchat
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getTypesPrix() {
		const url = 'assets/enum/typePrix.json';
		const res = await this.$http.get(url);
		return res.data;
	}
}
