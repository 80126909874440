import validatorBuilder from 'validator-builder';

let uniteValidator = null;

export default class Unite {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.modeFactureEtPeseeValue = data.modeFactureEtPeseeValue || 0;
        this.arrondi = data.arrondi || 0;
        this.symbole = data.symbole;
        this.libelleExists = data.libelleExists || null;
        this.libelleInvalid = data.libelleInvalid || null;
        this.quantiteLieeAuProduit = data.quantiteLieeAuProduit;
        this.conversion = data.conversion || null;
        this.isUnitaire = data.isUnitaire;
    }

    isValid() {
        uniteValidator = uniteValidator || validatorBuilder.getInstanceFor('Unite');
        const validationResults = uniteValidator.validate(this);
        return validationResults.isValid;
    }
}
