import validatorBuilder from 'validator-builder';

let siteIndicateurValidator = null;

export default class SiteIndicateur {
    constructor(data) {
        data = data || {};
        this.objectifsIndicateurs = data.objectifsIndicateurs || [];
    }

    isValid() {
        siteIndicateurValidator = siteIndicateurValidator || validatorBuilder.getInstanceFor('SiteIndicateur');
        const validationResults = siteIndicateurValidator.validate(this);
        return validationResults.isValid;
    }
}
