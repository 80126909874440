SyntheseEntitiesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'SyntheseEntitiesService',
    'notification',
    'MassiaApplicationService',
    '$uibModal',
    'SyntheseEntitiesCdcService',
    '$transitions'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function SyntheseEntitiesController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    SyntheseEntitiesService,
    notification,
    MassiaApplicationService,
    $uibModal,
    SyntheseEntitiesCdcService,
    $transitions
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    let selectedSyntheseEntityId = undefined;

    vm.loading = false;

    vm.params = $stateParams;
    vm.state = $state;
    vm.$uibModal = $uibModal;
    vm.$state = $state;
    vm.MassiaApplicationService = MassiaApplicationService;

    vm.syntheseEntities = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };
    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.selectSyntheseEntity = selectSyntheseEntity;
    vm.isSyntheseEntitySelected = isSyntheseEntitySelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadSyntheseEntities = loadSyntheseEntities;
    vm.setSelected = setSelected;
    vm.deleteSyntheseEntity = deleteSyntheseEntity;
    vm.openSynthese = openSynthese;

    function init() {
        //écouter l'event lié au changement de route
        //une fois on change l'url, et on arrive sur la page "syntheseentities.list", on désélectionne la ligne sélectionnée (s'il y en a)
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));
        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.idSynth) });
        }

        vm.activite = vm.MassiaApplicationService.getApplication();
        getTypeSyntheses();
    }

    init();

    async function getTypeSyntheses() {
        try {
            const tempList = await SyntheseEntitiesService.getTypeSyntheses();
            vm.listeTypesSynthese = _.filter(tempList, { activite: vm.activite });
        } catch (err) {
            if (err.data) {
                notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
        }
    }

    function stateChangeSuccessFunc(event, toState) {
        /*if (toState.name === 'syntheseentities.list') {
            selectedSyntheseEntityId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }*/
        if (!$state.params.idSynth) {
            vm.setSelected();
        } else if ($state.params.idSynth) {
            vm.setSelected({ id: parseInt($state.params.idSynth) });
        }
    }

    function setSelected(syntheseEntity) {
        if (syntheseEntity && syntheseEntity.id) {
            selectedSyntheseEntityId = $stateParams.idSynth;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            selectedSyntheseEntityId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return (
            $state &&
            $state.current &&
            $state.current.name &&
            $state.current.name === 'syntheseentities.list.detail' &&
            $stateParams &&
            $stateParams.idSynth
        );
    }

    function selectSyntheseEntity(syntheseEntity) {
        if (syntheseEntity && syntheseEntity.id) {
            if (selectedSyntheseEntityId !== syntheseEntity.id) {
                vm.openSynthese(syntheseEntity);
            }
        }
    }

    function isSyntheseEntitySelected(syntheseEntity) {
        return syntheseEntity && syntheseEntity.id && selectedSyntheseEntityId === syntheseEntity.id;
    }

    function showDetail(syntheseEntityId) {
        //vm.slideMargin['margin-right'] = vm.slideWidth;
        selectedSyntheseEntityId = syntheseEntityId;
        $state.go('syntheseentities.list.detail', { idSynth: syntheseEntityId });
    }

    function closeDetail() {
        selectedSyntheseEntityId = undefined;
        $state.go('syntheseentities.list');
    }

    async function deleteSyntheseEntity(syntheseEntity) {
        if (syntheseEntity && syntheseEntity.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('SYNTHENTS.DELETE.TITLE', { code: syntheseEntity.libelle }),
                modalMsg: $translate.instant('SYNTHENTS.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    if (syntheseEntity.typeDeSynthese === 13) {
                        await SyntheseEntitiesService.deleteSyntheseEntityCdcById(syntheseEntity.id);
                    }
                    await SyntheseEntitiesService.deleteSyntheseEntityById(syntheseEntity.id);
                    notification.success($translate.instant('SYNTHENTS.DELETE.SUCCESS'));

                    selectedSyntheseEntityId = undefined;

                    // si jamais la vue détail est affichée,
                    // on va faire une rédirection vers la vue de la grille, afin de fermer la vue détail
                    if (vm.params.id) {
                        $state.go('syntheseentities.list');
                    }
                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);
                    loadSyntheseEntities();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    async function loadSyntheseEntities(tableState) {
        startLoading();

        //quand on rafraîchit la grille, on revient sur "syntheseentities.list", et on ferme la vue détail d'un syntheseEntity
        //sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
        if ($state && $state.current && $state.current.name !== 'syntheseentities.list') {
            $state.go('syntheseentities.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.syntheseEntities = [];

        try {
            const data = await SyntheseEntitiesService.getSyntheseEntities(filters, sorts, pagination, vm.activite);
            vm.syntheseEntities = data.items;
            for (let i = 0; i < vm.syntheseEntities.length; i++) {
                vm.syntheseEntities[i].typeDeSyntheseLibelle = $translate.instant(vm.syntheseEntities[i].typeDeSyntheseLibelle);
            }
            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }

    function openSynthese(syntheseEntity) {
        const _this = vm;
        this.$uibModal
            .open({
                template:
                    '<syntheseentities-form modal-instance="$ctrl.uibModalInstance" synth-for-gene="$ctrl.synthForGene"></syntheseentities-form>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.uibModalInstance = $uibModalInstance;
                        $ctrl.synthForGene = syntheseEntity.id;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl',
                windowClass: 'modal-fit mas-scrollbar',
                backdrop: false
            })
            .result.then(
                function (result) {
                    //_this.closeDetail();
                    if (result) {
                        //result contient une liste (prelevementBlock)
                        _this.$state.go('syntheseentities.result', { obj: result });
                    }
                },
                function (reason) {}
            );
    }
}
