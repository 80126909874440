import GrillesController from './grilles.controller';

export default {
    bindings: {
        source: '=',
        domaine: '=',
        idGrille: '=',
        modalInstance: '=',
        resolve: '<'
    },
    template: require('./grilles.html'),
    controller: GrillesController
};
