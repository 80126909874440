export default function Configure($stateProvider) {
    $stateProvider.state('tickets', {
        parent: 'common',
        url: '/tickets',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
        }
    });

    $stateProvider.state('tickets.list', {
        url: '/list',
        template: '<tickets></tickets>',
        rights: { domain: 'tickets', right: 'read', application: 'gestion' },
        ncyBreadcrumb: {
            label: '{{ "TICKETS.BREADCRUMBS.TICKETS_LIST" | translate}}'
        },
        // on affiche la liste des tickets dans la navigation
        navigation: {
            menu: 'pesees_livraisons',
            translate: 'TICKETS.BREADCRUMBS.TICKETS_LIST',
            order: 1
        },
        // la liste des tickets fait partie des points d'entrée recherchables dans la navigation
        search: 'TICKETS.BREADCRUMBS.TICKETS_LIST'
    });

    $stateProvider.state('tickets.search', {
        url: '/search',
        template: '<ticket-search></ticket-search>',
        rights: { domain: 'tickets', right: 'read' },
        ncyBreadcrumb: {
            parent: 'tickets.list',
            label: '{{ "TICKETS.BREADCRUMBS.TICKET_SEARCH" | translate}}'
        },
        search: 'TICKETS.BREADCRUMBS.TICKET_SEARCH'
    });

    $stateProvider.state('tickets.detail', {
        url: '/{id}',
        template: '<ticket-detail></ticket-detail>',
        rights: { domain: 'tickets', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher tickets > détail
            parent: 'tickets.list',
            label: '{{ "TICKETS.BREADCRUMBS.TICKET_DETAIL" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
