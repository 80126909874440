import validatorBuilder from 'validator-builder';
import VehiculeEntete from '../vehicule-entete/vehicule.entete.model';

let vehiculeValidator = null;

export default class Vehicule {
    constructor(data = {}) {
        this.id = data.id;
        this.entete = new VehiculeEntete(data.entete);
    }

    isValid() {
        vehiculeValidator = vehiculeValidator || validatorBuilder.getInstanceFor('Vehicule');
        const validationResults = vehiculeValidator.validate(this);
        return validationResults.isValid;
    }
}
