import SyntheseEntitiesCarteDeControleController from './syntheseentities.cartedecontrole.controller';

export default {
    bindings: {
        syntheseEntity: '=',
        forGeneration: '=',
        ongletOpen: '=',
        refresh: '&',
        that: '<',
        getSynthese: '<'
    },

    template: require('./syntheseentities.cartedecontrole.html'),
    controller: SyntheseEntitiesCarteDeControleController
};
