export default class IndiceRevisionDetailController {
    static $inject = ['$stateParams', '$state', 'IndicesRevisionService', 'notification'];

    constructor($stateParams, $state, IndicesRevisionService, notification) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.IndicesRevisionService = IndicesRevisionService;
        this.notification = notification;
    }

    $onInit() {
        this.loading = false;
        this.indiceRevision = {};
        this.indiceRevisionId = undefined;
        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.initIndiceRevision();
    }

    async initIndiceRevision() {
        this.indiceRevisionId = this.$stateParams.id;

        this.startLoading();
        try {
            await this.getIndiceRevision(this.indiceRevisionId);
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('indices-revision.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getIndiceRevision(indiceRevisionId) {
        this.indiceRevision = await this.IndicesRevisionService.getIndiceRevisionById(indiceRevisionId);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
