import { Pipe } from "../../../core/decorators";
import { PipeTransform } from "../../../core/decorators/pipe";

@Pipe({
    name: 'isNumber'
})
export class IsNumber implements PipeTransform {
    transform(input: any) {
        if (input && isFinite(input)) {
            if (typeof input === 'number') return true;
        }
        return false;
    }

}