export default class PaiementDetailController {
    static $inject = ['$stateParams', '$state', 'PaiementsService', 'PaiementsTypesService', 'PaiementsCommunicationService', 'notification'];

    constructor($stateParams, $state, PaiementsService, PaiementsTypesService, PaiementsCommunicationService, notification) {
        this.params = $stateParams;
        this.$state = $state;
        this.PaiementsService = PaiementsService;
        this.PaiementsTypesService = PaiementsTypesService;
        this.PaiementsCommunicationService = PaiementsCommunicationService;
        this.notification = notification;
        this.loading = false;
        this.paiement = {};
        this.paiementId = undefined;
        this.types = {};

        //par défaut, on ouvert l'onglet "Entête"
        this.ongletOpen = {
            isEnteteOpen: true,
            isEcheanceOpen: false
        };
    }

    async $onInit() {
        this.paiementId = this.params.id;
        this.startLoading();
        try {
            this.paiement.entete = await this.PaiementsService.getPaiementEnteteById(this.paiementId);
            this.types = await this.PaiementsTypesService.getPaiementTypes();
            this.type = await this.PaiementsTypesService.getType(this.paiement.entete.type);

            this.modeCalcul = await this.PaiementsService.getCalcModes();
            for (var i = 0; i < this.modeCalcul.length; i++) {
                if (this.modeCalcul[i].id == this.paiement.entete.modeCalcul) {
                    this.libelleModeCalcul = this.modeCalcul[i].libelle;
                    break;
                }
            }
            this.calcUnits = await this.PaiementsService.getCalcUnits();
            for (var i = 0; i < this.calcUnits.length; i++) {
                if (this.calcUnits[i].code == this.paiement.entete.echeanceUnite) {
                    this.libelleUnite = this.calcUnits[i].libelle;
                    break;
                }
            }
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('paiements.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
