import validatorBuilder from 'validator-builder';

let nomenclatureEnteteValidator = null;

export default class NomenclatureEntete {
	constructor(data = {}) {
		this.id = data.id;
		this.code = data.code;
		this.libelle = data.libelle;
		this.isDap = data.isDap;
		this.isDangereux = data.isDangereux;
		this.restriction = data.restriction;
		this.restrictionDocument = data.restrictionDocument;
	}

	isValid() {
		nomenclatureEnteteValidator = nomenclatureEnteteValidator || validatorBuilder.getInstanceFor('NomenclatureEntete');
		const validationResults = nomenclatureEnteteValidator.validate(this);
		return validationResults.isValid;
	}
}
