import OffreDemandeController from './offre.demande.controller';

export default {
    bindings: {
        offre: '=',
        isOpen: '=',
        dateDemandeCreation: '='
    },
    template: require('./offre.demande.html'),
    controller: OffreDemandeController
};
