import CommandeEnteteController from './commande.entete.controller';

export default {
    bindings: {
        commande: '=',
        isOpen: '=',
        hasTransport: '=',
        sortedCommandeLignes: '=',
        loadSiteCommerciaux: '&'
    },
    template: require('./commande.entete.html'),
    controller: CommandeEnteteController
};
