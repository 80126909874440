import Profil from './profil.model';

export default class ProfilFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        '$translate',
        '$timeout',
        'notification',
        '_',
        'ProfilsService',
        'DomainesService',
        'MassiaApplicationService',
        'MOPService'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        $translate,
        $timeout,
        notification,
        _,
        ProfilsService,
        DomainesService,
        MassiaApplicationService,
        MOPService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.$timeout = $timeout;
        this.notification = notification;
        this._ = _;
        this.ProfilsService = ProfilsService;
        this.DomainesService = DomainesService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.MOPService = MOPService;
    }

    // Component
    $onInit() {
        this.MOPService.setMop([{ title: 'gestion des profils et utilisateurs', filename: 'ProfilsEtutilisateurs.pdf', application: 'massia' }]);
        this.loading = true;
        this.profil = {};
        this.loadProfilTimeout = null;
        this.ongletOpen = {
            isFormOpen: true,
            isDomaineOpen: true
        };

        this.reset();
    }

    $onDestroy() {
        this.$timeout.cancel(this.loadProfilTimeout);
        this.MOPService.resetMop();
    }

    // Méthode publiques
    async appliquer() {
        const id = await this.sauvegarder();

        if (id && this.profil.id) {
            this.reset();
        } else if (id) {
            this.$state.go('profils.edit', { id: id });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    async annuler() {
        this.$state.go('profils.list');
    }

    async reset() {
        this.startLoading();

        let data = {
            id: this.$stateParams.id
        };

        if (data.id) {
            try {
                data = await this.ProfilsService.getProfil(data.id, this.MassiaApplicationService.getApplication());
            } catch (ex) {
                this.notification.error(ex.data);
            }
        }

        this.profil = new Profil(data);

        this.domaines = await this.refreshDomaines();
        this.preselectDomaines();

        if (this.$stateParams.duplicate) {
            this.profil.id = undefined;
            this.profil.id.libelle += '_copie';
        }

        this.ongletOpen = {
            isFormOpen: true,
            isDomaineOpen: true
        };

        this.$timeout.cancel(this.loadProfilTimeout);
        this.loadProfilTimeout = this.$timeout(() => {
            this.stopLoading();
        });
    }

    async checkLibelleUnicity(id, libelle) {
        this.profil.libelleExists = false;

        try {
            //if (libelle.match(/^[a-zA-Z0-9_|]*$/)) {
            this.profil.libelleExists = await this.ProfilsService.libelleExists(id, libelle);
            //}
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    // Methodes privées
    async sauvegarder() {
        if (this.profil.isValid() && !this.profil.libelleExists) {
            this.startLoading();

            try {
                let id = false;

                if (this.profil.id) {
                    await this.ProfilsService.updateProfil(this.profil.id, this.profil, this.MassiaApplicationService.getApplication());
                    this.notification.success(this.$translate.instant('EDITER_SUCCESS', { entity: this.profil.libelle }));
                    id = this.profil.id;
                } else {
                    id = await this.ProfilsService.createProfil(this.profil);
                    this.notification.success(this.$translate.instant('AJOUTER_SUCCESS', { entity: this.profil.libelle }));
                }

                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        } else {
            this.$scope.$broadcast('ProfilValidations');
        }
    }

    // Domaines
    deleteDomaine(domaine) {
        const index = this._.findIndex(this.profil.niveauxAccesDomaines, { idDomaine: domaine.idDomaine });
        if (index >= 0) {
            this.profil.niveauxAccesDomaines.splice(index, 1);
            domaine.selected = false;
        }
    }

    updateDomaine(domaine, droit) {
        const updated = this._.find(this.profil.niveauxAccesDomaines, { idDomaine: domaine.idDomaine });
        if (updated.droits[droit] !== undefined) {
            updated && (updated.droits[droit] = !updated.droits[droit]);
        }
    }

    selectDomaine(domaine) {
        const index = this._.findIndex(this.profil.niveauxAccesDomaines, { idDomaine: domaine.idDomaine });
        if (index < 0) {
            domaine.droits = this.DomainesService.getFullDroits(domaine.niveauMax);
            domaine.niveauAcces = this.DomainesService.getFullBitmask();
            this.profil.niveauxAccesDomaines.push(domaine);
        }

        this.$scope.$broadcast('Profil.niveauxAccesDomainesPropertyClearValidation');
    }

    // Loading
    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    //New
    async refreshDomaines() {
        let data;
        try {
            data = await this.DomainesService.getDomaines(this.MassiaApplicationService.getApplication());
        } catch (ex) {
            data = [];
        }
        return data;
    }

    setSelectedDomaines() {
        this._.forEach(this.domaines, (domaine) => {
            if (domaine.selected) {
                this.selectDomaine(domaine);
            } else {
                this.deleteDomaine(domaine);
            }
        });
    }

    preselectDomaines() {
        this._.forEach(this.profil.niveauxAccesDomaines, (accessLevel) => {
            const domaine = this._.find(this.domaines, { idDomaine: accessLevel.idDomaine });
            if (domaine) {
                domaine.selected = true;
                domaine.niveauAcces = accessLevel.niveauAcces;
                domaine.droits = accessLevel.droits;
            }
        });
    }
}
