ControlesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'ControlesService',
	'notification',
	'$transitions',
	'MOPService'
];

export default function ControlesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	ControlesService,
	notification,
	$transitions,
	MOPService
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	vm.selectedControleId = undefined;
	vm.lastSelected = undefined;

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.controles = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectControle = selectControle;
	vm.isControleSelected = isControleSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadControles = loadControles;
	vm.deleteControle = deleteControle;
	vm.setSelected = setSelected;
	vm.eraseAllFilters = eraseAllFilters;
	vm.MOPService = MOPService;

	function init() {
		vm.MOPService.setMop([{ title: 'CONTROLES.BREADCRUMBS.CONTROLES_LIST', filename: 'TypesDeControles.pdf', application: 'gestion' }]);
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	init();

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(controle) {
		if (controle && controle.id) {
			vm.selectedControleId = $stateParams.id;
			vm.lastSelected = vm.selectedControleId;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			vm.selectedControleId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state && $state.current && $state.current.name && $state.current.name === 'controles.list.detail' && $stateParams && $stateParams.id;
	}

	function selectControle(controle) {
		if (controle && controle.id) {
			if (vm.selectedControleId !== controle.id) {
				vm.selectedControleId = controle.id;
				showDetail(controle.id);
			} else {
				vm.selectedControleId = undefined;
				closeDetail();
			}
		}
	}

	function isControleSelected(controle) {
		return controle && controle.id && vm.selectedControleId === controle.id;
	}

	function showDetail(controleId) {
		$state.go('controles.list.detail', { id: controleId });
	}

	function closeDetail() {
		$state.go('controles.list');
	}

	async function loadControles(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'controles.list') {
			$state.go('controles.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.controles = [];

		try {
			const data = await ControlesService.getControles(filters, sorts, pagination);

			vm.controles = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteControle(controle) {
		if (controle && controle.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('TRANSPORTS.DELETE.TITLE', { code: controle.libelle }),
				modalMsg: $translate.instant('TRANSPORTS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await ControlesService.deleteControleById(controle.id);
					notification.success($translate.instant('TRANSPORTS.DELETE.SUCCESS'));

					vm.selectedControleId = undefined;

					if (vm.params.id) {
						vm.state.go('controles.list');
					}

					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

					loadControles();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function eraseAllFilters() {
		loadControles();
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
