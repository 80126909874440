export default class CalculStatistiqueDetailController {
    static $inject = ['$stateParams', '$state', 'CalculsStatistiquesService', 'notification', 'MassiaApplicationService'];

    constructor($stateParams, $state, CalculsStatistiquesService, notification, MassiaApplicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.CalculsStatistiquesService = CalculsStatistiquesService;
        this.notification = notification;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        this.loading = false;
        this.calcul = {};

        this.initCalcul();
    }

    async initCalcul() {
        this.calcul = { id: this.$stateParams.id };
        if (this.calcul.id) {
            this.startLoading();
            try {
                this.calcul = await this.CalculsStatistiquesService.getCalculStatistiqueById(this.calcul.id);
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListCalcul();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListCalcul();
        }
    }

    goListCalcul() {
        this.$state.go('calculs.list', {}, { reload: true });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
