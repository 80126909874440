//Config
import PrefacturationConfig from './_config/prefacturation.config';
import PrefacturationRoutes from './_config/prefacturation.routes';

//Components
import PrefacturationFormComponent from './components/prefacturation-form';
import PrefacturationDetailComponent from './components/prefacturation-detail';
import PrefaGrilleComponent from './components/prefacturation-grille';

//services
import PrefacturationService from './services/prefacturation.service';
import PrefacturationCommunicationService from './services/prefacturation.communication.service';

//Validateurs
import PrefacturationValidator from './components/prefacturation-form/prefacturation.validator';

const moduleName = 'app.massia.common.prefacturation';

angular
    .module(moduleName, [])
    .config(PrefacturationConfig)
    .config(PrefacturationRoutes)
    .service('PrefacturationService', PrefacturationService)
    .service('PrefacturationCommunicationService', PrefacturationCommunicationService)
    .component('prefacturationForm', PrefacturationFormComponent)
    .component('prefacturationDetail', PrefacturationDetailComponent)
    .component('prefaGrille', PrefaGrilleComponent)
    .factory('PrefacturationValidator', PrefacturationValidator);

export default moduleName;
