import validatorBuilder from 'validator-builder';

let fileImportValidator = null;

export default class FileImport {
    constructor(data) {
        data = data || {};
        this.Id = data.id;
        this.Code = data.code;
        this.Libelle = data.libelle;
        this.Drive = data.drive || null;
        this.Directory = data.directory || null;
        this.Extension = data.extension;
        this.Mesures = data.Mesures || null;
        this.CodeEssai = data.CodeEssai || null;
        this.IdPrelevmt = data.IdPrelevmt || null;
        this.DomaineLibelle = data.domaineLibelle || null;
        this.DateCreation = data.dateCreation || null;
        this.Size = data.size;
    }
    isValid() {
        fileImportValidator = fileImportValidator || validatorBuilder.getInstanceFor('TemplateImpression');
        const validationResults = fileImportValidator.validate(this);
        return validationResults.isValid;
    }
}
