// Du fait d'une limitation d'angularJS
// le validateur doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
SiteCaracteristiquesTransporteurValidator.$inject = ['validator'];

export default function SiteCaracteristiquesTransporteurValidator(validator) {
    const instance = new validator();
    // ici se placent habituellement les règles de validation
    // ce validateur est un cas particulier car il n'a pas de propriétés fixes, tout est dynamique
    instance.generateRules = generateRules;

    /**
     * Il est nécessaire de générer les règles de validation à la main
     * Car les règles sont nettoyées dans le cadre du formulaire dynamique
     * (à chaque changement de formulaire on supprime les règles automatiques
     * ajoutées par le formulaire dynamique)
     */
    function generateRules() {
        // instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    }

    return instance;
}
