export default class SitesTypesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    /*async getAll() {
        const url = `${__configuration.apiUrl}/massia/domaines/Sites/types`;
        const res = await this.$http.get(url);
        return res.data;
    }*/

    async getAllSiteUtilisateurTypes() {
        const url = `${__configuration.apiUrl}/massia/domaines/SiteUtil/types`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getAllSiteClientTypes() {
        const url = `${__configuration.apiUrl}/massia/domaines/SiteClient/types`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getAllSiteFournisseurTypes() {
        const url = `${__configuration.apiUrl}/massia/domaines/SiteFourn/types`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getAllSiteProducteurTypes() {
        const url = `${__configuration.apiUrl}/massia/domaines/SiteProd/types`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getAllSiteTransporteurTypes() {
        const url = `${__configuration.apiUrl}/massia/domaines/SiteTransp/types`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getAllSiteLaboratoireTypes() {
        const url = `${__configuration.apiUrl}/massia/domaines/SiteLabo/types`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getAllSiteProprietaireTypes() {
        const url = `${__configuration.apiUrl}/massia/domaines/SiteProprio/types`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getAllSiteLocataireTypes() {
        const url = `${__configuration.apiUrl}/massia/domaines/SiteLocat/types`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getAllSiteAuditeurTypes() {
        const url = `${__configuration.apiUrl}/massia/domaines/SiteAudit/types`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getCaracteristiques(id) {
        const url = `${__configuration.apiUrl}/massia/types/${id}/format-caracteristiques`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async GetNiveauVisibilite(id) {
        const url = `${__configuration.apiUrl}/massia/types/${id}/niveau-visibilite`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
