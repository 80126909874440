import SuiviDroitBitumeConfig from './_config/suivi.droit.bitume.config';
import SuiviDroitBitumeRoutes from './_config/suivi.droit.bitume.routes';
import SuiviDroitBitumeComponent from './components/suivi-droit-bitume';
import SuiviDroitBitumeService from './services/suivi.droit.bitume.service';

const moduleName = 'app.parametrageGeneral.suivi.droit.bitume';

angular
    .module(moduleName, [])
    .config(SuiviDroitBitumeConfig)
    .config(SuiviDroitBitumeRoutes)
    .service('SuiviDroitBitumeService', SuiviDroitBitumeService)
    .component('suiviDroitBitume', SuiviDroitBitumeComponent);

export default moduleName;
