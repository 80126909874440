(function (angular, undefined) {
    'use strict';

    var constants = {
        color: '#333333',
        lightTrail: 'rgba(255, 255, 255, 0.4)',
        darkTrail: 'rgba(0, 0, 0, 0.1)',
        size: '36',
        borderSize: '5',
    };

    angular.module('blocks.loading').constant('LoadersDefaultValues', constants);
})(angular);
