export default class ModalInfoFormatController {
    constructor() {}

    visible = false;

    async $onInit() {}

    close() {
        this.visible = !this.visible;
    }
}
