TvaConfigurationController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'TvaConfigurationService',
    'TvaCommunicationService',
    'notification',
    '_',
    '$uibModal'
];

export default function TvaConfigurationController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    TvaConfigurationService,
    TvaCommunicationService,
    notification,
    _,
    $uibModal
) {
    const vm = this;

    vm.loading = false;
    vm.isOpen = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm._ = _;
    vm.$uibModal = $uibModal;
    vm.tvas = [];

    vm.loadTvas = loadTvas;
    vm.updateTva = updateTva;
    vm.createTva = createTva;
    vm.deleteTva = deleteTva;
    vm.activeEditMode = activeEditMode;
    vm.desactiveEditMode = desactiveEditMode;

    vm.$onInit = () => {
        vm.unregister = $scope.$watch(
            () => vm.isOpen,
            () => {
                if (vm.isOpen) {
                    loadTvas();
                }
            },
            true
        );
        getPays();
        getTaux();
    };

    vm.$onDestroy = () => {
        vm.unregister();
    };

    async function loadTvas() {
        startLoading();
        try {
            if (vm.id && vm.isOpen) {
                vm.tvas = await TvaConfigurationService.getTvaConfiguration(vm.id, vm.type);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function getPays() {
        startLoading();
        try {
            const res = await TvaConfigurationService.getPays();
            vm.pays = res.items;
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function getTaux() {
        startLoading();
        try {
            vm.taux = await TvaConfigurationService.getTaux();
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function updateTva(tva) {
        startLoading();
        try {
            await TvaConfigurationService.updateTvaConfiguration(tva);
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            tva.isEditMode = false;
            vm.tvaIsEditMode = false;
            loadTvas();
            stopLoading();
        }
    }

    async function createTva() {
        if (vm.paysToAdd && vm.dateToAdd && (vm.tvaVenteToAdd || vm.tvaAchatToAdd)) {
            startLoading();
            try {
                const data = {
                    idPays: vm.paysToAdd,
                    idTvaVente: vm.tvaVenteToAdd,
                    idTvaAchat: vm.tvaAchatToAdd,
                    id: vm.id,
                    type: vm.type,
                    date: vm.dateToAdd
                };
                await TvaConfigurationService.createTvaConfiguration(data);
            } catch (ex) {
                notification.error(ex.data);
            } finally {
                vm.paysToAdd = null;
                vm.tvaVenteToAdd = null;
                vm.tvaAchatToAdd = null;
                vm.dateToAdd = null;
                loadTvas();
                stopLoading();
            }
        } else {
            notification.error($translate.instant('TVA.PAYS_OBLIGATOIRE'));
        }
    }

    async function deleteTva(tva) {
        if (tva) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('TVA.DELETE.TITLE', { code: tva.taux }),
                modalMsg: $translate.instant('TVA.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await TvaConfigurationService.deleteTvaConfigurationById(tva.idAchat, tva.idVente);
                    notification.success($translate.instant('TVA.DELETE.SUCCESS'));
                    loadTvas();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function activeEditMode(tva) {
        tva.isEditMode = true;
        vm.tvaTemp = {
            id: tva.id,
            idPays: tva.idPays,
            idTvaVente: tva.idTvaVente,
            idTvaAchat: tva.idTvaAchat,
            pays: tva.pays,
            date: tva.date,
            tauxVente: tva.tauxVente,
            tauxAchat: tva.tauxAchat
        };
        vm.tvaIsEditMode = true;
    }

    function desactiveEditMode(index, tva) {
        tva.isEditMode = false;
        vm.tvas[index] = vm.tvaTemp;
        vm.tvaIsEditMode = false;
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }
}
