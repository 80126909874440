import EtatReglementsConfig from './_config/etat.reglements.config';
import EtatReglementsRoutes from './_config/etat.reglements.routes';

import EtatReglementsComponent from './components/etat-reglements';

import EtatReglementsService from './services/etat.reglements.service';

const moduleName = 'app.massia.gestion.etat.reglements';

angular
    .module(moduleName, [])
    .config(EtatReglementsConfig)
    .config(EtatReglementsRoutes)
    .service('EtatReglementsService', EtatReglementsService)
    .component('etatReglements', EtatReglementsComponent);

export default moduleName;
