export default class FormuleArchivesController {
    /* @ngInject */
    constructor($state, $stateParams, FormuleService, ModalService, notification, $translate, $uibModal, globalizationManagementService) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.FormuleService = FormuleService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.$uibModal = $uibModal;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
        this.displayDateMask = globalizationManagementService.getCurrentLanguage().displayDateMask;
    }

    $onInit() {
        const archives = angular.copy(this.formules.liste);
        /* if (typeof this.formules.liste != 'undefined') {
            archives.pop();
        } */
        this.archives = archives.filter((e) => e.dateArchivage !== null);
    }

    async openVisualisation(id) {
        if (id) {
            const formuleArchiver = await this.FormuleService.getFormuleById(id);
            console.log(this.produit);
            const modalInstance = this.$uibModal.open({
                component: 'visualisation',
                resolve: {
                    formule: () => formuleArchiver,
                    produit: () => this.produit
                },
                backdrop: false,
                size: 'xxl'
            });

            return modalInstance.result;
        }
    }

    /* openVisualisation(id) {
        var _this = this;
        let source = null;
        for (let i = 0; i < this.archives.length; i++) {
            if (this.archives[i].idComposition === id) {
                source = this.archives[i];
                break;
            }
        }
        _this.source = source;
        this.$uibModal
            .open({
                template: '<visualisation source="$ctrl.source" modal-instance="$ctrl.uibModalInstance"></visualisation>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        var $ctrl = this;
                        $ctrl.source = _this.source;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl'
            })
            .result.then(
                function (result) {
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    } */

    async deleteArchive(archive) {
        try {
            await this.FormuleService.deleteArchiveById(archive.idComposition);
            const index = this.archives.indexOf(archive);
            this.archives.splice(index, 1);
            this.notification.success(this.$translate.instant('FORMULES.DELETE.ARCHIVE_SUCCESS'));
        } catch (err) {
            this.notification.error(err.data);
        }

        // var _this = this;
        // console.log(_this);

        // if (archive  && archive.idComposition) {
        //     const modalInstance = this.ModalService.confirm({
        //         modalTitle: this.$translate.instant('OFFRES.DELETE.TITLE', {code: archive.codeComposition}),
        //         modalMsg: this.$translate.instant('OFFRES.DELETE.MESSAGE'),
        //         headerClass: 'modal-danger'
        //     });

        //     modalInstance.result
        //         .then(async function(_this) {
        //             try {
        //                 console.log(_this);
        //                 await _this.FormuleService.deleteArchiveById(archive.idComposition);
        //                 //_this.notification.success(this.$translate.instant('OFFRES.DELETE.SUCCESS'));
        //             } catch (ex) {
        //                 //_this.notification.error(ex.data);
        //             }
        //         }, this);
        // }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
