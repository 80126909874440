export default function Configure($stateProvider) {
    $stateProvider.state('tva', {
        parent: 'common',
        url: '/tva',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('tva.list', {
        url: '/list',
        template: '<tva></tva>',
        rights: { domain: 'tva', right: 'read' },
        ncyBreadcrumb: {
            parent: 'tva',
            label: '{{ "TVA.BREADCRUMBS.TVA_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'TVA.BREADCRUMBS.TVA_LIST',
            order: 40,
            navigationCls: 'menu-item-separator'
        },
        search: 'TVA.BREADCRUMBS.TVA_LIST'
    });

    $stateProvider.state('tva.list.detail', {
        url: '/{id}',
        template: '<tva-detail></tva-detail>',
        rights: { domain: 'tva', right: 'read' },
        ncyBreadcrumb: {
            parent: 'tva.list',
            label: '{{ "TVA.BREADCRUMBS.TVA_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('tva.new', {
        url: '/new',
        template: '<tva-form></tva-form>',
        rights: { domain: 'tva', right: 'create' },
        ncyBreadcrumb: {
            parent: 'tva.list',
            label: '{{ "TVA.BREADCRUMBS.TVA_NEW" | translate}}'
        },
        search: 'TVA.BREADCRUMBS.TVA_NEW'
    });

    $stateProvider.state('tva.edit', {
        url: '/{id}/edit',
        template: '<tva-form></tva-form>',
        rights: { domain: 'tva', right: 'update' },
        ncyBreadcrumb: {
            parent: 'tva.list',
            label: '{{ "TVA.BREADCRUMBS.TVA_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
