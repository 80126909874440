ProduitEnteteValidator.$inject = ['validator'];

export default function ProduitEnteteValidator(validator) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 20).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance
        .ruleFor('code')
        .matches(/^[a-zA-Z0-9_|]*$/)
        .withMessage('CODE_CHARACTERS_NOK');

    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').length(1, 200).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    instance.ruleFor('typeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    instance.ruleFor('codeExists').notEqual(true).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('sitesIds').must(elementsNotEmpty).withMessage('VALIDATION_TOO_SHORT_LISTE');

    function elementsNotEmpty(obj, elements = []) {
        return elements.length > 0;
    }

    return instance;
}
