export default class GestionStocksProductionController {
    static $inject = ['$stateParams', 'StocksCommunicationService', 'StocksService', 'notification', 'ModalService', '$uibModal'];

    constructor($stateParams, StocksCommunicationService, StocksService, notification, ModalService, $uibModal) {
        this.$stateParams = $stateParams;
        this.StocksCommunicationService = StocksCommunicationService;
        this.StocksService = StocksService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.quantite = 0;
        this.consoTheo = [];
        for (let index = 0; index < this.ligne.composition.length; index++) {
            const currentComp = {
                idProduit: this.ligne.composition[index].idProduit,
                codeProduit: this.ligne.composition[index].codeProduit,
                libelleProduit: this.ligne.composition[index].libelleProduit,
                idSiteProducteur: this.ligne.composition[index].idSiteProducteur,
                siteProducteur: this.ligne.composition[index].siteProducteur,
                idEmplacement: 0,
                emplacements: this.ligne.composition[index].emplacements,
                pourcent: this.ligne.composition[index].pourcent,
                stockFinal: this.ligne.composition[index].stockFinal
            };
            this.consoTheo.push(currentComp);
        }
    }

    changeQuantite() {
        for (let index = 0; index < this.consoTheo.length; index++) {
            const currentFormule = this.consoTheo[index];
            currentFormule.quantite = (currentFormule.pourcent * this.quantite) / 100;
            currentFormule.stockApresProduction = currentFormule.stockFinal - currentFormule.quantite;
        }
    }

    annuler() {
        this.modalInstance.close(false);
    }

    async confirmer() {
        //ajouter la nouvelle production stockée
        const prodStock = {
            quantite: this.quantite
        };
        if (!this.ligne.newProductionsStockees) {
            this.ligne.newProductionsStockees = [];
        }
        this.ligne.newProductionsStockees.push(prodStock);
        //recalculer la variation, la production stockée et le stock final de la ligne
        if (this.ligne.variation.valeur) {
            this.ligne.variation.valeur = this.ligne.variation.valeur + this.quantite;
        } else {
            this.ligne.variation.valeur = this.quantite;
        }
        if (this.ligne.stockFinal.valeur) {
            this.ligne.stockFinal.valeur = this.ligne.stockFinal.valeur + this.quantite;
        } else {
            this.ligne.stockFinal.valeur = this.quantite;
        }
        if (this.ligne.productionStockee.valeur) {
            this.ligne.productionStockee.valeur = this.ligne.productionStockee.valeur + this.quantite;
        } else {
            this.ligne.productionStockee.valeur = this.quantite;
        }
        //renvoyer les conso théoriques
        this.modalInstance.close(this.consoTheo);
    }
}
