import DocumentCEEntete from './document.ce.entete.model';

export default class DocumentCEEnteteController {
	static $inject = [
		'_',
		'$state',
		'$stateParams',
		'DocumentsCECommunicationService',
		'DocumentsCEService',
		'ModalService',
		'notification',
		'NormesService',
		'ImagesService',
		'SitesService',
		'$filter',
		'MassiaRightsService'
	];

	constructor(
		_,
		$state,
		$stateParams,
		DocumentsCECommunicationService,
		DocumentsCEService,
		ModalService,
		notification,
		NormesService,
		ImagesService,
		SitesService,
		$filter,
		MassiaRightsService
	) {
		this._ = _;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.DocumentsCECommunicationService = DocumentsCECommunicationService;
		this.DocumentsCEService = DocumentsCEService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.NormesService = NormesService;
		this.imagesService = ImagesService;
		this.SitesService = SitesService;
		this.$filter = $filter;
		this.MassiaRightsService = MassiaRightsService;
	}

	$onInit() {
		this.certificationAccess = this.MassiaRightsService.userHasRight('certification', 'read', 'laboratoire');
		this.loading = false;
		if (this.$stateParams.id) {
			this.isEditMode = true;
		}
		this.docce = this.docce || {};
		this.entete = new DocumentCEEntete(this.docce.entete);

		this.producteurs = this.producteurs || [];
		this.produits = this.produits || [];
		this.certificats = this.certificats || [];

		this.selectedNormes = [];
		//this.producteurSelected = {};
		//this.produitSelected = {};

		// on sauvegarde la fonction de désabonnement pour l'invoquer au destroy
		// il est important de noter la façon de s'abonner :
		// on utilise les arrow functions, () => {},
		// elles permettent de sauvegarder l'état du 'this'
		// et d'éviter la perte du contexte lors de l'appel de la fonction depuis un autre controller
		this.unregisterReinit = this.DocumentsCECommunicationService.registerReinit(() => this.initForm());
		//this.preselectSites();
		this.loadImage();
		//* si domaine Certification
		if (this.certificationAccess) {
			this.changeProduit(this.entete.idProduit);
		}
	}

	// si un changement est fait dans le parent,
	// on met a jour les donnees dans l enfant
	$onChanges(objChanges) {
		if (objChanges.docce) {
			this.getProducteurs();
			this.changeProducteur(this.docce.entete.idProducteur);
			this.getNormes();
		}
	}

	// SL si angular voit que les donnees originales sont differentes des donnees dupliquees
	// le parent recupere les donnees de l enfant
	$doCheck() {
		if (!angular.equals(this.entete, this.docce.entete)) {
			this.onUpdate({
				entete: angular.copy(this.entete)
			});
		}
	}

	$onDestroy() {
		this.unregisterReinit();
	}

	changeSelectedNormes() {
		this.normes = [];
		if (this.entete.normesIds && this.entete.normesIds.length > 0) {
			this.normes = this.allNormes.filter((norme) => {
				return this.entete.normesIds.indexOf(norme.id) >= 0;
			});
		}
		this.entete.normes = this.entete.normesIds;
		if (this.entete.idCertificat && this.entete.idCertificat > 0) {
			const certificat = this.certificats.find((x) => x.idCertificat == this.entete.idCertificat);
			this.checkNormesCertificat(certificat);
		}
	}

	async getProducteurs() {
		try {
			this.producteurs = await this.DocumentsCEService.getProducteurs();
			this.producteurs = this.$filter('selectInIsteven')(this.producteurs, 'id', this.entete.idProducteur, 'selected');
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async changeProducteur(idProducteur) {
		if (idProducteur) {
			await this.getProduits(idProducteur);
			await this.getInfosCertif(idProducteur);
		}
	}

	async changeProduit(idProduit) {
		if (idProduit && this.entete.idProducteur) {
			await this.getCertificats(idProduit, this.entete.idProducteur);
		}
	}

	async getInfosCertif(idProducteur) {
		const site = await this.SitesService.getSiteEnteteById(idProducteur, 4, 'laboratoire'); //producteur
		this.entete.numeroOrganisme = site.numOrganisme;
		this.saveSiteNum = site.numCertificat;
		if (this.docce.entete.idCertificat === 0) {
			this.entete.numeroCertificat = site.numCertificat;
		}
	}

	async getProduits(idProducteur) {
		try {
			this.produits = await this.DocumentsCEService.getProduits(idProducteur);
			this.produits = this.$filter('selectInIsteven')(this.produits, 'id', this.entete.idProduit, 'selected');
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async getNormes() {
		this.startLoading();
		try {
			this.allNormes = await this.DocumentsCEService.getNormes();
			this.allNormesCopy = angular.copy(this.allNormes);
			if (this.isEditMode == true && this.entete.normesIds) {
				this.allNormes.forEach((item) => {
					if (this.entete.normesIds.indexOf(item.id) !== -1) {
						item.selected = true;
					}
				});
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getCertificats(idProduit, idProducteur) {
		try {
			//* liste des certificats pour le producteur/produit
			this.certificats = await this.DocumentsCEService.getCertificats(idProduit, idProducteur);

			//* si aucun certificat affiche le numéro de certificat du site

			if (this.certificats) {
				//  //* séléction des normes en fonction des normes liées aux certificats
				//  const normesCertifies = this.getNormesCertifie();
				//  this.allNormes = this.allNormesCopy.filter(norme => {
				//      return normesCertifies.indexOf(norme.id) >= 0;
				//  });

				//* si un seul certificat → il est selectionné par defaut et on affiche son numero
				if (this.certificats.length === 1) {
					this.certificats[0].selected = true;
					this.entete.numeroCertificat = this.certificats[0].numero;
					this.entete.idCertificat = this.certificats[0].idCertificat;
					this.entete.normesCertifie = this.certificats[0].normes.map((x) => x.id);
					this.checkNormesCertificat(this.certificats[0]);
				} else {
					//* si plusieurs certificat affiche le numero du certificat séléctionner sinon rien
					const selected = this.certificats.find((x) => x.id === this.entete.idCertificat);
					if (selected) {
						this.certificats = this.$filter('selectInIsteven')(this.certificats, 'id', this.entete.idCertificat, 'selected');
						this.entete.numeroCertificat = selected.numero;
						this.entete.idCertificat = selected.idCertificat;
						this.entete.normesCertifie = selected.normes.map((x) => x.id);
					}
				}
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async loadImage() {
		try {
			const data = await this.imagesService.getImages([], [], { skip: 0, take: 2000 });
			this.imageList = data.items;
			if (!this.entete.idLogo) {
				const img = this._.find(this.imageList, function (elt) {
					return elt.code == 'LOGO_CE';
				});
				if (img != undefined) {
					this.entete.idLogo = img.id;
				}
			}
			this.showImage();
		} catch (error) {
			console.error(error);
		}
	}

	async showImage(idLogo = 0) {
		const id = this.entete.idLogo ? this.entete.idLogo : idLogo;
		if (id) {
			try {
				const data = await this.imagesService.getShowImageById(id);
				this.image = data;
			} catch (error) {
				console.error(error);
			}
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	// isteeven
	selectItem(data, theme) {
		if (theme !== 'idCertificat') {
			this.entete[theme] = data.id;
		}

		if (theme === 'idProducteur') {
			this.changeProducteur(data.id);
		}

		if (this.certificationAccess) {
			if (theme === 'idProduit') {
				this.changeProduit(data.id);
			}

			if (theme === 'idCertificat') {
				if (data.idCertificat == this.entete.idCertificat) {
					//unselect

					const idx = this.certificats.findIndex((x) => x.idCertificat == this.entete.idCertificat);
					this.certificats[idx].selected = false;
					this.entete.idCertificat = 0;
					this.entete.normesCertifie = null;
					this.entete.numeroCertificat = this.saveSiteNum;
				} else {
					this.entete.idCertificat = data.idCertificat;
					this.entete.numeroCertificat = data.numero;
					this.entete.normesCertifie = data.normes.map((x) => x.id);
					this.checkNormesCertificat(data);
				}
			}
		}
	}

	getNormesCertifie() {
		let ids = [];
		this.certificats.forEach((certificat) => {
			const idsNormes = certificat.normes.map((x) => x.id);
			ids = [...ids, ...idsNormes];
		});
		return ids.filter(this.onlyUnique);
	}
	onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}

	checkNormesCertificat(certificat) {
		const certificatNormes = certificat.normes.map((x) => x.id);
		this.validity = true;
		this.normeserror = [];

		if (this.entete.normesIds) {
			for (let i = 0; i < this.entete.normesIds.length; i++) {
				const element = this.entete.normesIds[i];
				if (certificatNormes.indexOf(element.id) === -1) {
					this.validity = false;
					this.normeserror.push(element);
				}
			}
		}
	}
}
