import validatorBuilder from 'validator-builder';
import ChantierEntete from './entete/chantier.entete.model';
import ChantierCaracteristiques from './caracteristiques/chantier.caracteristiques.model';

let chantierValidator = null;

export default class Chantier {
    constructor(data = {}) {
        this.id = data.id;
        this.entete = new ChantierEntete(data.entete);
        this.caracteristiques = new ChantierCaracteristiques(data.caracteristiques);
    }

    isValid() {
        chantierValidator = chantierValidator || validatorBuilder.getInstanceFor('Chantiers');
        const validationResults = chantierValidator.validate(this);
        return validationResults.isValid;
    }
}
