export default function Configure($stateProvider) {
    $stateProvider.state('grilles-zones-kilometriques', {
        parent: 'gestion',
        url: '/grilles-zones-kilometriques',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('grilles-zones-kilometriques.list', {
        url: '/list',
        template: '<grilles-zones-kilometriques></grilles-zones-kilometriques>',
        rights: { domain: 'grillezonekm', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "GRILLES_ZONES_KILOMETRIQUES.BREADCRUMBS.GRILLES_ZONES_KILOMETRIQUES_LIST" | translate}}'
        },
        // on affiche la liste des grilles de zones kilométriques dans la navigation
        navigation: {
            menu: 'tarification_transport',
            translate: 'GRILLES_ZONES_KILOMETRIQUES.BREADCRUMBS.GRILLES_ZONES_KILOMETRIQUES_LIST',
            order: 4
        },
        // la liste des grilles de zones kilométriques fait partie des points d'entrée recherchables dans la navigation
        search: 'GRILLES_ZONES_KILOMETRIQUES.BREADCRUMBS.GRILLES_ZONES_KILOMETRIQUES_LIST'
    });

    $stateProvider.state('grilles-zones-kilometriques.list.detail', {
        url: '/{id}',
        template: '<grille-zones-kilometriques-detail></grille-zones-kilometriques-detail>',
        rights: { domain: 'grillezonekm', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher grilles de zones kilométriques > détail
            parent: 'grilles-zones-kilometriques.list',
            label: '{{ "GRILLES_ZONES_KILOMETRIQUES.BREADCRUMBS.GRILLE_ZONES_KILOMETRIQUES_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('grilles-zones-kilometriques.new', {
        url: '/new',
        template: '<grille-zones-kilometriques-form></grille-zones-kilometriques-form>',
        rights: { domain: 'grillezonekm', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher grilles de zones kilométriques > ajouter
            parent: 'grilles-zones-kilometriques.list',
            label: '{{ "GRILLES_ZONES_KILOMETRIQUES.BREADCRUMBS.GRILLE_ZONES_KILOMETRIQUES_NEW" | translate}}'
        },
        // l'ajout d'une grilles de zones kilométriques fait partie des points d'entrée recherchables dans l'application
        search: 'GRILLES_ZONES_KILOMETRIQUES.BREADCRUMBS.GRILLE_ZONES_KILOMETRIQUES_NEW'
    });

    $stateProvider.state('grilles-zones-kilometriques.edit', {
        url: '/{id}/edit',
        template: '<grille-zones-kilometriques-form></grille-zones-kilometriques-form>',
        rights: { domain: 'grillezonekm', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher grilles de zones kilométriques > modifier
            parent: 'grilles-zones-kilometriques.list',
            label: '{{ "GRILLES_ZONES_KILOMETRIQUES.BREADCRUMBS.GRILLE_ZONES_KILOMETRIQUES_EDIT" | translate }}'
        }
    });

    $stateProvider.state('grilles-zones-kilometriques.duplicate', {
        url: '/{id}/duplicate',
        template: '<grille-zones-kilometriques-form></grille-zones-kilometriques-form>',
        rights: { domain: 'grillezonekm', right: 'update' },
        ncyBreadcrumb: {
            parent: 'caractergrilles-zones-kilometriquesistiques.list',
            label: '{{ "GRILLES_ZONES_KILOMETRIQUES.BREADCRUMBS.GRILLE_ZONES_KILOMETRIQUES_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });
}

Configure.$inject = ['$stateProvider'];
