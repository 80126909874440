export default class SyntheseEntitiesCdcService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
        this.serviceUrl = `${__configuration.apiUrl}/massia/laboratoire/cartedecontrole`;
    }

    async getCdcParamsById(id) {
        const url = `${this.serviceUrl}/${id}`;
        const res = await this.$http.get(url);

        return res.data;
    }

    async createCdcParams(data) {
        try {
            res = await await this.$http.post(this.serviceUrl, data);
            return res.data;
        } catch (e) {
            throw e.data;
        }
    }

    async updateCdcParams(id, data) {
        try {
            const url = `${this.serviceUrl}/${id}`;
            const res = await this.$http.put(url, data);
            return res.data;
        } catch (e) {
            console.log(e);
            throw e.data;
        }
    }

    async deleteCdcParams(id) {
        const url = `${this.serviceUrl}/delete/${id}`;
        await this.$http.delete(url);
        return true;
    }

    async print(cdc, filename) {
        const url = `${this.serviceUrl}/print`;
        const res = await this.$http.post(url, {
            template: filename,
            cdc: cdc
        });

        return res.data ? res.data : null;
    }
}
