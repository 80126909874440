import validatorBuilder from 'validator-builder';

let factureArticleValidator = null;

export default class ArticleBlock {
    constructor(data) {
        data = data || [];
        this.idChantier = data.idChantier;
        this.nomChantier = data.nomChantier;
        this.idSiteCommercial = data.idSiteCommercial;
        this.nomSiteCommercial = data.nomSiteCommercial;
        this.factureArticles = data.factureArticles || [];
    }

    isValid() {
        factureArticleValidator = factureArticleValidator || validatorBuilder.getInstanceFor('FactureArticle');
        const validationResults = factureArticleValidator.validate(this);
        return validationResults.isValid;
    }
}
