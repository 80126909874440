export default function Configure($stateProvider) {
    $stateProvider.state('code-automatique', {
        parent: 'common',
        url: '/code-automatique'
        //abstract: true,
        /*    views: {
            'common': {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true,
            label: '{{ "LAYOUT.NAVIGATION.OUTILS" | translate }}'
        }
    });

    $stateProvider.state('code-automatique.list', {
        url: '/form',
        template: '<code-automatique></code-automatique>',
        rights: {domain: 'codeauto', right: 'read'},
        ncyBreadcrumb: {
            label: '{{ "CODE_AUTOMATIQUE.BREADCRUMBS.CODE_AUTOMATIQUE" | translate}}'
        },

        // fait partie des points d'entrée recherchables dans la navigation
        search: 'CODE_AUTOMATIQUE.BREADCRUMBS.CODE_AUTOMATIQUE'*/
    });
}

Configure.$inject = ['$stateProvider'];
