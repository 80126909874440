const serviceUrl = `${__configuration.apiUrl}/massia/pre-facturation`;

export default class PrefacturationService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async genererPreFacturation(prefacturation, withControlDate) {
        const data = {
            dateDebut: prefacturation.dateDebut,
            dateFin: prefacturation.dateFin,
            dateGeneration: prefacturation.dateGeneration,
            idSociete: prefacturation.idSociete,
            nomSociete: prefacturation.nomSociete,
            hasDetailLignes: prefacturation.hasDetailLignes,
            isRecalerFranco: false,
            isRecalerTransportAFacturer: false,
            isRecalerLieuParChantier: prefacturation.isRecalerLieuParChantier,
            isRevaloriserTicket: prefacturation.isRevaloriserTicket,
            isValoriserTicket: prefacturation.isValoriserTicket,
            listeIdSitesCommerciaux: prefacturation.listeIdSitesCommerciaux,
            listeIdTypeClient: prefacturation.listeIdTypeClient,
            listeIdCliFour: prefacturation.listeIdCliFour,
            listeIdChantiers: prefacturation.listeIdChantiers,
            salarie: prefacturation.salarie,
            achatVente: prefacturation.achatVente
        };
        const url = `${__configuration.apiUrl}/massia/pre-facturation/${withControlDate}`;
        const result = await this.$http.post(url, data);
        return result.data;
    }

    async genererFacturation(withoutFacture, factureSelected) {
        const url = `${__configuration.apiUrl}/massia/pre-facturation/${withoutFacture}`;
        const result = await this.$http.put(url, factureSelected);
        return result.data;
    }

    async getSocietesCom() {
        const url = `${__configuration.apiUrl}/massia/societes-commerciales`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSitesCommerciauxBySociete(dateDebut, dateFin, idSociete, achatVente) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-commerciaux/headerPreFa/${idSociete}`, {
            params: {
                dateDebut: JSON.stringify(dateDebut),
                dateFin: JSON.stringify(dateFin),
                achatVente: JSON.stringify(achatVente)
            }
        });
        return res.data;
    }

    async getNomClients(idUtilisateur, dateDebut, dateFin, idSociete, achatVente, listeIdType, listeIdSitesCommerciaux) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-clients/headerPreFa/utilisateur/${idUtilisateur}`, {
            params: {
                dateDebut: JSON.stringify(dateDebut),
                dateFin: JSON.stringify(dateFin),
                idSociete: JSON.stringify(idSociete),
                achatVente: JSON.stringify(achatVente),
                listeIdType: listeIdType,
                listeIdSiteCom: listeIdSitesCommerciaux
            }
        });
        return res.data;
    }

    async getNomFournisseurs(idUtilisateur, dateDebut, dateFin, idSociete, achatVente, listeIdSitesCommerciaux) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-fournisseurs/headerPreFa/utilisateur/${idUtilisateur}`, {
            params: {
                dateDebut: JSON.stringify(dateDebut),
                dateFin: JSON.stringify(dateFin),
                idSociete: JSON.stringify(idSociete),
                achatVente: JSON.stringify(achatVente),
                listeIdSiteCom: listeIdSitesCommerciaux
            }
        });
        return res.data;
    }

    async getNomChantiers(idUtilisateur, dateDebut, dateFin, idSociete, listeIdClients, achatVente) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/chantiers/headerPreFa/utilisateur/${idUtilisateur}`, {
            params: {
                dateDebut: JSON.stringify(dateDebut),
                dateFin: JSON.stringify(dateFin),
                idSociete: JSON.stringify(idSociete),
                listeIdClients: JSON.stringify(listeIdClients),
                achatVente: JSON.stringify(achatVente)
            }
        });
        return res.data;
    }

    async getPersonnes(valeur, idsociete, idSites) {
        const url = `${__configuration.apiUrl}/massia/personnes/headerPreFa`;
        const filtres = {
            valeur: valeur,
            idsociete: idsociete,
            idSites: idSites
        };
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {})
            }
        });

        return res.data;
    }

    async getPSArticleFromGrille(idGrille, articleObject) {
        let typeArticleAdjust = null;
        switch (articleObject.typeArticle) {
            case 0:
                typeArticleAdjust = '0';
                break;
            case 1:
                typeArticleAdjust = '1';
                break;
            case 2:
                typeArticleAdjust = '2';
                break;
            case 3:
                typeArticleAdjust = '3';
                break;
            case 4:
                typeArticleAdjust = '4';
                break;

            default:
                break;
        }

        const url = `${__configuration.apiUrl}/massia/grillesTarifaires/pixArticleFromGrille`;
        const res = await this.$http.get(url, {
            params: {
                idGrille: JSON.stringify(idGrille || null),
                idArticle: JSON.stringify(articleObject.idArticle || null),
                typeArticle: JSON.stringify(typeArticleAdjust || null),
                idProducteur: JSON.stringify(articleObject.idProducteur || null),
                idTypeBenne: JSON.stringify(articleObject.idTypeBenne || null),
                idZone: JSON.stringify(articleObject.idZone || null),
                isEnrobe: JSON.stringify(articleObject.isEnrobe || false),
                idSiteCommercial: JSON.stringify(articleObject.idSiteCommercial || null),
                dateLivraison: JSON.stringify(articleObject.dateLivraison || null)
            }
        });
        return res.data;
    }

    async printPrefa(data, template) {
        const d = {
            prefa: data,
            template: template
        };
        const url = `${__configuration.apiUrl}/massia/pre-facturation/print-prefa`;
        const res = await this.$http.post(url, d);
        return res.data;
    }
}
