export default function accordeonOpenDirective() {
    return {
        retrict: 'A',
        replace: false,
        transclude: false,
        scope: {
            accordeonOpen: '='
        },
        link: function (scope, element, attrs) {
            element.bind('click', onAccordeonOpen);

            function onAccordeonOpen() {
                if (scope.hasOwnProperty('accordeonOpen')) {
                    for (const key in scope.accordeonOpen) {
                        if (scope.accordeonOpen.hasOwnProperty(key)) {
                            scope.accordeonOpen[key] = true;
                        }
                    }
                    scope.$apply();
                }
            }
        }
    };
}
