import SocieteFormNiveauxController from './societe.form.niveaux.controller';

export default {
    bindings: {
        societe: '<',
        form: '=',
        onUpdate: '&'
    },
    template: require('./societe.form.niveaux.html'),
    controller: SocieteFormNiveauxController
};
