import validatorBuilder from 'validator-builder';

let indicesRevisionGenerationValidator = null;

export default class IndicesRevisionGeneration {
    constructor(data = {}) {
        this.objectSociete = data.objectSociete;
        this.objectSiteCommercial = data.objectSiteCommercial;
        this.dateDebut = data.dateDebut;
        this.dateFin = data.dateFin;
        this.datePrixNegocies = data.datePrixNegocies;
        this.partTransport = data.partTransport;
        this.isEnFonctionPrestation = false;
        this.garderFrancoIdentique = false;
    }

    isValid() {
        indicesRevisionGenerationValidator = indicesRevisionGenerationValidator || validatorBuilder.getInstanceFor('IndicesRevisionGeneration');
        const validationResults = indicesRevisionGenerationValidator.validate(this);
        return validationResults.isValid;
    }
}
