import LabelCeRoutes from './_config/label.ce.routes';
import LabelCeConfiguration from './_config/label.ce.config';
import LabelCeService from './services/label.ce.service';
import LabelCeComponent from './components/label-ce';
import LabelCeListComponent from './components/label-ce-list';

const moduleName = 'app.massia.common.label.ce';

angular
    .module(moduleName, [])
    .config(LabelCeConfiguration)
    .config(LabelCeRoutes)
    .service('LabelCeService', LabelCeService)
    .component('labelCe', LabelCeComponent)
    .component('labelCeList', LabelCeListComponent);

export default moduleName;
