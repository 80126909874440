import validatorBuilder from 'validator-builder';

let gestionStocksFiltresValidator = null;

export default class GestionStocksFiltres {
    constructor(data) {
        data = data || {};
        this.idSite = data.idSite || null;
        this.dateDebut = data.dateDebut || null;
        this.dateFin = data.dateFin || null;
        this.eltNiv1 = data.eltNiv1 || null;
        this.eltNiv2 = data.eltNiv2 || null;
    }

    isValid() {
        gestionStocksFiltresValidator = gestionStocksFiltresValidator || validatorBuilder.getInstanceFor('GestionStocksFiltres');
        const validationResults = gestionStocksFiltresValidator.validate(this);
        return validationResults.isValid;
    }
}
