import ProspectsConfig from './_config/prospects.config';
import ProspectsRoutes from './_config/prospects.routes';
import ProspectsComponent from './components/prospects';
import ProspectsService from './services/prospects.service';
import ProspectsCommunicationService from './services/prospects.communication.service';

import ProspectFormComponent from './components/prospect-form';
import ProspectDetailComponent from './components/prospect-detail';
import ProspectEnteteComponent from './components/prospect-entete';

import ProspectValidator from './components/prospect-form/prospect.validator';
import ProspectEnteteValidator from './components/prospect-entete/prospect.entete.validator';

const moduleName = 'app.parametrageGeneral.prospects';

angular
    .module(moduleName, [])
    .config(ProspectsConfig)
    .config(ProspectsRoutes)
    .service('ProspectsService', ProspectsService)
    .service('ProspectsCommunicationService', ProspectsCommunicationService)
    .component('prospects', ProspectsComponent)
    .component('prospectForm', ProspectFormComponent)
    .component('prospectDetail', ProspectDetailComponent)
    .component('prospectEntete', ProspectEnteteComponent)
    .factory('ProspectValidator', ProspectValidator)
    .factory('ProspectEnteteValidator', ProspectEnteteValidator);

export default moduleName;
