import Theme from './theme.model';

export default class ThemeFormController {
    static $inject = [
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'ThemesService',
        'notification',
        '$location',
        '$anchorScroll',
        'MassiaApplicationService',
        '$uibModal'
    ];

    constructor($scope, $timeout, $state, $stateParams, ThemesService, notification, $location, $anchorScroll, MassiaApplicationService, $uibModal) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ThemesService = ThemesService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$uibModal = $uibModal;
    }

    $onInit() {
        this.theme = {};
        this.loading = false;
        this.getMetiers();
        this.reset();
    }

    $onDestroy() {
        this.$timeout.cancel(this.updateThemeTimeout);
    }

    async reset() {
        this.startLoading();

        let data = {
            id: this.$stateParams.id
        };

        if (this.$stateParams.id) {
            try {
                data = await this.ThemesService.getThemeById(data.id);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
                this.annuler();
            }
        }
        this.theme = new Theme(data);
        /*if (this.$stateParams.duplicate) {
            this.theme.id = undefined;
            this.theme.code += '_copie';
        } */

        this.$timeout.cancel(this.updateThemeTimeout);
        this.updateThemeTimeout = this.$timeout(() => this.stopLoading());
    }

    async getMetiers() {
        this.startLoading();
        try {
            this.metiers = await this.ThemesService.getMetiers(this.MassiaApplicationService.getApplication());
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.theme.codeExists = await this.ThemesService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.theme.codeExists = null;
        }
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                let id = false;
                if (this.theme.id) {
                    await this.ThemesService.updateTheme(this.theme);
                    this.notification.success('THEMES.UPDATED');
                    id = this.theme.id;
                } else {
                    id = await this.ThemesService.createTheme(this.theme);
                    this.notification.success('THEMES.CREATED');
                }

                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async appliquer() {
        const id = await this.sauvegarder();

        if (id && this.theme.id) {
            this.reset();
        } else if (id) {
            if (this.MassiaApplicationService.getApplication() == 'laboratoire') {
                this.$state.go('themeslabo.edit', { id: id });
            } else {
                this.$state.go('themes.edit', { id: id });
            }
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    annuler() {
        if (this.MassiaApplicationService.getApplication() == 'laboratoire') {
            this.$state.go('themeslabo.list');
        } else {
            this.$state.go('themes.list');
        }
    }

    checkValidity() {
        let validity = true;

        if (!this.theme.isValid()) {
            validity = false;
            //this.$scope.$broadcast('themeValidations');
        }
        return validity;
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
