const naturesByValue = {
    0: 'textecourt',
    1: 'textelong',
    2: 'date',
    3: 'numerique',
    4: 'booleen',
    5: 'elementliste',
    7: 'heure',
    /*8: 'granulo',
	10: 'essaigenerique', // Mantis 7993
	11: 'essaiiteratif',*/
    9: 'elementlisteperso',
    60: 'ensembletextecourt',
    61: 'ensembledates',
    62: 'ensemblenumerique',
    63: 'ensembleelementliste',
    64: 'ensembleelementlisteperso',
    70: 'undefined'
};

const naturesValuesByName = {
    textecourt: 0,
    textelong: 1,
    date: 2,
    numerique: 3,
    booleen: 4,
    elementliste: 5,
    heure: 7,
    /*'granulo': 8,
	'essaigenerique': 10, // Mantis 7993
	'essaiiteratif': 11,*/
    elementlisteperso: 9,
    ensembletextecourt: 60,
    ensembledates: 61,
    ensemblenumerique: 62,
    ensembleelementliste: 63,
    ensembleelementlisteperso: 64,
    undefined: 70,
    representationgraphique: 71
};

export function getNatureNamesByValues() {
    return angular.copy(naturesByValue);
}

export function getNatureNamesWithValues() {
    return angular.copy(naturesValuesByName);
}
