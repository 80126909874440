import CarnetsSuiviConfig from './_config/carnets.suivi.config';
import CarnetsSuiviRoutes from './_config/carnets.suivi.routes';
import CarnetsSuiviComponent from './components/carnets-suivi';
import CarnetsSuiviService from './services/carnets.suivi.service';

const moduleName = 'app.massia.gestion.carnets.suivi';

angular
    .module(moduleName, [])
    .config(CarnetsSuiviConfig)
    .config(CarnetsSuiviRoutes)
    .service('CarnetsSuiviService', CarnetsSuiviService)
    .component('carnetsSuivi', CarnetsSuiviComponent);

export default moduleName;
