const serviceUrl = `${__configuration.apiUrl}/massia/gestion/indices-revision`;

import { getTypeNamesByValues } from './types.enum';

export default class IndicesRevisionService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
        this.getTypes = () => getTypeNamesByValues();
    }

    async getIndicesRevision(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });
        return res.data;
    }

    async getIndiceRevisionById(idIndiceRevision) {
        const url = `${serviceUrl}/${idIndiceRevision}`;

        const res = await this.$http.get(url);
        return res.data;
    }

    async deleteIndiceRevisionById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async createIndiceRevision(indiceRevision) {
        const result = await this.$http.post(serviceUrl, indiceRevision);
        return result.data;
    }

    async updateIndiceRevision(indiceRevision) {
        const url = `${serviceUrl}/${indiceRevision.id}`;
        return this.$http.put(url, indiceRevision);
    }

    //indiceRevisionId optionnel, passer null si c'est une creation de grille
    //sinon l'id de la grille qui est en train d'être editée
    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getTypesArticles() {
        const url = 'assets/enum/factures/typeArticle.json';
        const res = await this.$http.get(url);
        return res.data;
    }

    async getNomSocietes(valeur) {
        const nomSocieteUrl = `${__configuration.apiUrl}/massia/societes-commerciales/typeAhead/${valeur}`;
        const res = await this.$http.get(nomSocieteUrl);
        return res.data;
    }

    async getNomSitesCommerciauxBySociete(idSociete, valeur) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-commerciaux/typeAhead/${idSociete}/${valeur}`);
        return res.data;
    }

    async getTransporteurs() {
        const siteUrl = `${__configuration.apiUrl}/massia/sites-transporteurs/headers`;
        const res = await this.$http.get(siteUrl);

        return res.data;
    }

    async calculerRevisionsPrix(indiceRevisionGeneration) {
        const data = {
            dateDebut: indiceRevisionGeneration.dateDebut,
            dateFin: indiceRevisionGeneration.dateFin,
            datePrixNegocies: indiceRevisionGeneration.datePrixNegocies,
            idSociete: indiceRevisionGeneration.idSociete,
            nomSociete: indiceRevisionGeneration.nomSociete,
            idSiteCommercial: indiceRevisionGeneration.idSiteCommercial,
            nomSiteCommercial: indiceRevisionGeneration.nomSiteCommercial,
            partTransport: indiceRevisionGeneration.partTransport,
            isEnFonctionPrestation: indiceRevisionGeneration.isEnFonctionPrestation,
            garderFrancoIdentique: indiceRevisionGeneration.garderFrancoIdentique,
            siteTransportId: indiceRevisionGeneration.garderFrancoIdentique ? indiceRevisionGeneration.siteTransportId : null
        };
        const result = await this.$http.post(`${__configuration.apiUrl}/massia/gestion/revisions-prix`, data);
        return result.data;
    }

    async genererRevision(data) {
        const result = await this.$http.put(`${__configuration.apiUrl}/massia/gestion/revisions-prix`, data);
        return result.data;
    }
}
