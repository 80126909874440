const serviceUrl = `${__configuration.apiUrl}/massia/plannings/livraison-planifiee-livree`;

export default class LivraisonPlanifieeLivreeService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getLivraisonPlanifieeLivree(filters, sorts, pagination, idSociete, date, idChauffeur, idTracteur) {
        const filtres = {
            idSociete: idSociete,
            date: date,
            idChauffeur: idChauffeur,
            idTracteur: idTracteur
        };
        const res = await this.$http.get(serviceUrl, {
            params: {
                filtres: JSON.stringify(filtres || {}),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getSocietes() {
        const url = `${__configuration.apiUrl}/massia/societes-commerciales`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getChauffeurs(idSociete) {
        //if (idSociete == null)
        idSociete = -1;
        const chauffUrl = `${__configuration.apiUrl}/massia/personnes/chauffeurs`;
        const res = await this.$http.get(chauffUrl, {
            params: {
                societeId: idSociete,
                siteTransporteurId: -1
            }
        });
        return res.data;
    }

    async getTracteurs() {
        const url = `${__configuration.apiUrl}/massia/gestion/tracteurs/header`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async exportLivraison(modeImpr, filters, sorts, idSociete, date, idChauffeur, idTracteur) {
        const filtres = {
            idSociete: idSociete,
            date: date,
            idChauffeur: idChauffeur,
            idTracteur: idTracteur
        };
        const url = `${serviceUrl}/print`;
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {}),
                modeImpr: JSON.stringify(modeImpr),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || [])
            },
            responseType: 'arraybuffer'
        });
        return res;
    }
}
