export default function Configure($stateProvider) {
    $stateProvider.state('badges', {
        parent: 'gestion',
        url: '/badges',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
        }
    });

    $stateProvider.state('badges.list', {
        url: '/list',
        template: '<badges></badges>',
        rights: { domain: 'badges', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "BADGES.BREADCRUMBS.BADGES_LIST" | translate}}'
        },
        // on affiche la liste des badges dans la navigation
        navigation: {
            menu: 'pesees_livraisons',
            translate: 'BADGES.BREADCRUMBS.BADGES_LIST',
            order: 4
        },
        // la liste des badges fait partie des points d'entrée recherchables dans la navigation
        search: 'BADGES.BREADCRUMBS.BADGES_LIST'
    });

    $stateProvider.state('badges.list.detail', {
        url: '/{id}',
        template: '<badge-detail></badge-detail>',
        rights: { domain: 'badges', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher badges > détail
            parent: 'badges.list',
            label: '{{ "BADGES.BREADCRUMBS.BADGE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('badges.new', {
        url: '/new',
        template: '<badge-form></badge-form>',
        rights: { domain: 'badges', right: 'create' },
        ncyBreadcrumb: {
            parent: 'badges.list',
            label: '{{ "BADGES.BREADCRUMBS.BADGE_NEW" | translate}}'
        },
        search: 'BADGES.BREADCRUMBS.BADGE_NEW'
    });

    $stateProvider.state('badges.edit', {
        url: '/{id}/edit',
        template: '<badge-form></badge-form>',
        rights: { domain: 'badges', right: 'update' },
        ncyBreadcrumb: {
            parent: 'badges.list',
            label: '{{ "BADGES.BREADCRUMBS.BADGE_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
