/* eslint-disable max-lines */
/* eslint-disable max-depth */
/* eslint-disable default-case */
/* eslint-disable complexity */
/* eslint-disable curly */
/* eslint-disable brace-style */

// import Chart from 'chart.js';
import numeral from 'numeral';
import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-essai-generique',
    bindings: {
        model: '=',
        field: '=',
        sousMesuresComplet: '=',
        onDeleteMesure: '&',
        onExitMesure: '&'
    },
    transclude: true,
    template: require('./massia.essai.generique.tpl.html')
})
export class MassiaEssaiGeneriqueController {
    static $inject = [
        '$scope',
        '$timeout',
        '$translate',
        '_',
        'NatureInputTypeMapping',
        'FormulesService',
        'MesuresService',
        'ColorPickerService',
        '$uibModal'
    ];

    constructor($scope, $timeout, $translate, _, NatureInputTypeMapping, FormulesService, MesuresService, ColorPickerService, $uibModal) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this._ = _;
        this.NatureInputTypeMapping = NatureInputTypeMapping;
        this.FormulesService = FormulesService;
        this.MesuresService = MesuresService;
        this.ColorPickerService = ColorPickerService;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        Object.values(this.field.generiqueAdditionalInformation.essaiLie.sousEssais).map(function (e) {
            e.inputCls = 'col-xs-12 col-sm-0';
            return e;
        });

        this.fieldId = '_' + this.field.id;

        this.row = { data: {} };

        const nbValeurs =
            this.model.hasOwnProperty(this.fieldId) && this.model[this.fieldId].value && this.model[this.fieldId].value.constructor === Array
                ? this.model[this.fieldId].value.length
                : 0;
        if (nbValeurs > 1 || this.field.generiqueAdditionalInformation.isIteratif) {
            const nb = this.field.generiqueAdditionalInformation.nbIteration ? this.field.generiqueAdditionalInformation.nbIteration : nbValeurs;
            this.addRows(nb);
        } else {
            for (let e in this.field.generiqueAdditionalInformation.essaiLie.sousEssais) {
                const sse = this.field.generiqueAdditionalInformation.essaiLie.sousEssais[e];
                if (this.model['_' + sse.id] && this.model['_' + sse.id].value)
                    this.row['_' + sse.id].value =
                        this.model['_' + sse.id].value.constructor === Array ? this.model['_' + sse.id].value[0] : this.model['_' + sse.id].value;
                else this.row['_' + sse.id].value = null;
            }
        }

        if (this.sousMesuresComplet.format) {
            const that = this;
            this.principal = false;
            const essaiFound = this.sousMesuresComplet.format.find(function (e) {
                return e.code === that.field.generiqueAdditionalInformation.essaiLie.code;
            });
            if (essaiFound) this.principal = true;
        }

        this.unregister = [];
        this.unregister.push(
            this.$scope.$watch(
                () => this.model,
                (newValue, oldValue) => {
                    if (newValue && !angular.equals(newValue, oldValue)) {
                        for (let e in this.field.generiqueAdditionalInformation.essaiLie.sousEssais) {
                            const sse = this.field.generiqueAdditionalInformation.essaiLie.sousEssais[e];
                            if (this.model['_' + sse.id] && this.model['_' + sse.id].value)
                                this.row['_' + sse.id].value =
                                    this.model['_' + sse.id].value.constructor === Array
                                        ? this.model['_' + sse.id].value[0]
                                        : this.model['_' + sse.id].value;
                            else this.row['_' + sse.id].value = null;
                        }
                    }
                }
            )
        );
    }

    $onDestroy() {
        for (let i = 0; i < this.unregister.length; i++) {
            this.unregister[i]();
        }
    }

    getColspan(array) {
        return array.filter((e) => e.idNature !== 10 && e.idNature !== 11).length + 1;
    }

    addRows(nb) {
        this.fieldIdNature = 10;
        this.field.nature = 'EssaiGenerique';
        this.field.generiqueAdditionalInformation.isIteratif = true;
        this.field.generiqueAdditionalInformation.nbIteration = nb;
    }

    async mesureDeleted(sousessai) {
        this.onDeleteMesure({
            mesure: sousessai
        });
    }

    async mesureBlured(sousessai) {
        for (let e in this.field.generiqueAdditionalInformation.essaiLie.sousEssais) {
            const sse = this.field.generiqueAdditionalInformation.essaiLie.sousEssais[e];
            this.model['_' + sse.id].value = [this.row['_' + sse.id].value];
        }

        const finalVal = {};
        if (!finalVal[sousessai.id]) finalVal[sousessai.id] = [];
        finalVal[sousessai.id].push(this.model[sousessai.id].value);
        finalVal[sousessai.id] = this.flatDeep(finalVal[sousessai.id]);

        this.onExitMesure({
            mesure: sousessai,
            values: finalVal
        });
    }

    nbIterationChanged() {
        if (this.field.generiqueAdditionalInformation.nbIteration >= 1) this.addRows(this.field.generiqueAdditionalInformation.nbIteration);
    }

    flatDeep(arr) {
        return arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? this.flatDeep(val) : val), []);
    }
}
