const serviceUrl = `${__configuration.apiUrl}/massia/performance/arrets`;

export default class ArretsService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getArrets(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getArretsHeaders() {
        const url = `${serviceUrl}/header`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getArretsByTypeHeaders(type) {
        const url = `${serviceUrl}/header/${type}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async deleteArretById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getArretById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createArret(selectedArret) {
        const result = await this.$http.post(serviceUrl, selectedArret);
        return result.data;
    }

    async updateArret(selectedArret) {
        const url = `${serviceUrl}/${selectedArret.id}`;
        return this.$http.put(url, selectedArret);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }
}
