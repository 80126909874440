import { Pipe } from '../../../core/decorators';
import { PipeTransform } from '../../../core/decorators/pipe';

@Pipe({
    name: 'gridRound',
})
export class gridRound implements PipeTransform {
    filter: ng.IFilterService
    /* @ngInject */
    constructor($filter: ng.IFilterService) {
        this.filter = $filter;
    }
    transform(input: any, decimal: number) {
        // @ts-ignore
        if (typeof input === 'number') return input.round(decimal);
        return input;
    }
}
