import { SiteService } from '../../../../../core/http/site.service';
import { FilterType } from '../../../../../core/interface/FilterProperty';
import { SiteGenre } from '../../../../../core/enum/SiteGenre';
import MassiaApplicationService from '../../../../massia/layout/components/massia-application/massia.application.service';

export default class ProduitCopyModalMixdesignController {
    /* @ngInject */
    constructor(notification, ProduitsService, FormuleService, $state, SiteService, MassiaApplicationService, RouterHistoryService) {
        this.notification = notification;
        this.ProduitsService = ProduitsService;
        this.FormuleService = FormuleService;
        this.RouterHistoryService = RouterHistoryService;
        this.$state = $state;

        this.tableauComposant = [];
        this.tableauComposantEau = [];
        this.listeEaux = [];
        this.idProducteur = null;
        this.formule = null;
        this.allEauChanger = true;
        /**
         * @type SiteService
         */
        this.SiteService = SiteService;
        /**
         * @type MassiaApplicationService
         */
        this.massiaSvc = MassiaApplicationService;
    }

    async $onInit() {
        this.startLoading();
        try {
            /*pour la copy des md */
            this.idProducteur = this.resolve.producteurOrigin;

            await this.getSites();
            this.formule = await this.loadFormules();
            if (this.resolve.producteurOrigin !== this.resolve.idProducteur) {
                const data = this.allSites.find((x) => x.id === this.resolve.idProducteur);
                await this.selectProducteur(data, this.allSites);
            }
        } catch (error) {
            this.notification.error(error);
        }
        this.stopLoading();
    }

    async getSites() {
        try {
            const sites = await this.SiteService.getAll(
                [
                    {
                        criterion: 'familles.famille.id',
                        type: FilterType.EQUALS,
                        value: this.resolve.produit.type.famille.id
                    },
                    {
                        criterion: 'genre',
                        type: FilterType.EQUALS,
                        value: SiteGenre.Producteur
                    }
                ],
                [],
                {},
                {},
                {
                    application: this.massiaSvc.getApplication()
                }
            );
            //let sites = await this.ProduitsService.getSites();
            this.allSites = sites.items;
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    /** FORMULES *************************************************************************************************************************/
    async loadFormules() {
        try {
            if (this.resolve && this.resolve.produit && this.resolve.producteurOrigin && this.resolve.produit.id) {
                const formules = angular.copy(
                    this.resolve.produit.formules
                ); /*  await this.ProduitsService.getProduitFormules(this.resolve.produit.id); */

                const idx = formules.findIndex((x) => x.producteur.id === this.resolve.producteurOrigin);
                if (formules && idx > -1) {
                    const formule = await this.FormuleService.getFormuleByProduitProducteur(this.resolve.produit.id, this.resolve.producteurOrigin);

                    return formule;
                }
            }
        } catch (ex) {
            this.notification.error(ex);
        }
    }

    async checkSiteClientLiee(formule) {
        if (formule) {
            const tableauComposant = [];
            const tableauComposantEau = [];
            for (let i = 0; i < formule.composants.length; i++) {
                const composant = formule.composants[i];
                const siteClientLiee = await this.ProduitsService.getProduitSiteLiesLabo(composant.idComposant, composant.idProducteur);
                if (composant.codeFamille !== 'EAU') {
                    const idxProducteur = siteClientLiee[0].detailClient.findIndex((x) => x === this.idProducteur);
                    if (idxProducteur === -1) {
                        tableauComposant.push(composant);
                    }
                } else {
                    const idxProducteurEau = siteClientLiee[0].detailClient.findIndex((x) => x === this.idProducteur);
                    if (idxProducteurEau === -1) {
                        tableauComposantEau.push(composant);
                    }
                }
            }
            this.tableauComposant = tableauComposant;
            this.tableauComposantEau = tableauComposantEau;
        }
    }
    /*******************************************************************************************************************************/

    async selectProducteur(data, listProducteur) {
        if (data) {
            if (data.id !== this.idProducteur) {
                this.idProducteur = data.id;
            } else {
                listProducteur.map((e) => {
                    if (data.id === e.id) {
                        return (e.selected = !e.selected);
                    }
                });
                this.idProducteur = null;
            }
            if (this.idProducteur && this.formule) {
                await this.checkSiteClientLiee(this.formule);
                await this.getElementEauSelectionable(this.tableauComposantEau);
                if (this.tableauComposantEau && this.tableauComposantEau.length === 0) {
                    this.allEauChanger = true;
                } else {
                    this.allEauChanger = false;
                }
            } else {
                this.tableauComposant = [];
                this.tableauComposantEau = [];
                this.allEauChanger = true;
            }
            // console.log('idproducteur => : ', this.idProducteur);
        }
    }

    async selectEau(data, composantEau) {
        if (data) {
            if (data.produitId !== composantEau.idRemplacement) {
                composantEau.idProduitRemplacement = data.produitId;
                composantEau.idProducteurRemplacement = data.producteurId;
            } else {
                composantEau.listeEaux.map((e) => {
                    if (data.produitId === e.produitId) {
                        return (e.selected = !e.selected);
                    }
                });
                composantEau.idProduitRemplacement = null;
                composantEau.idProducteurRemplacement = null;
                this.allEauChanger = false;
            }
            this.checkChangementEau();
        }
    }

    async getElementEauSelectionable(tableauComposantEau) {
        try {
            if (tableauComposantEau) {
                const listeEaux = [];
                let res = await this.FormuleService.getClientLabProduits(this.idProducteur, this.resolve.produit.id);

                res = res.map((e) => {
                    if (e.produit && e.produit.familleCode === 'EAU') {
                        listeEaux.push(e.produit);
                    }
                });

                for (let i = 0; i < tableauComposantEau.length; i++) {
                    const element = tableauComposantEau[i];
                    element.listeEaux = angular.copy(listeEaux);
                }
            }

            return tableauComposantEau;
        } catch (err) {
            return this.notification.error(err);
        }
    }

    checkChangementEau() {
        if (this.tableauComposantEau && this.tableauComposantEau.length > 0) {
            const idxEauNonChanger = this.tableauComposantEau.findIndex((x) => x.idRemplacement === null);
            if (idxEauNonChanger > -1) {
                this.allEauChanger = false;
            } else {
                this.allEauChanger = true;
            }
        } else {
            this.allEauChanger = true;
        }
    }

    async ok() {
        try {
            if (this.allEauChanger) {
                let id = 0;
                id = await this.ProduitsService.generateProduitNorme(
                    this.resolve.newProduit,
                    this.massiaSvc.getApplication(),
                    this.idProducteur,
                    this.formule?.idComposition || 0,
                    this.tableauComposantEau
                );
                this.notification.success('PRODUITS.CREATED');
                if (id) {
                    this.close({
                        $value: id
                    });
                    this.RouterHistoryService.ignoreNextRoute();
                    this.$state.go('produits.edit', { id: id });
                }
            }
        } catch (error) {
            this.notification.error(error.data);
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
