lifeCycleHelper.$inject = ['$window', 'MassiaAuthService', 'MassiaApplicationService', 'AuthConstant'];
const isElectron = () => false;
export default function lifeCycleHelper($window, MassiaAuthService, MassiaApplicationService, AuthConstant) {
    const authData = MassiaAuthService.localStorageService.get(AuthConstant.MASSIA_USER_DATA_STORAGE);
    if (authData && authData.login && authData.login.toLowerCase() === __configuration.godUser) {
        return {};
    }

    if (isElectron()) {
        /*  require('electron')
            .remote.getCurrentWindow()
            .on('close', () => {
                MassiaAuthService.deleteToken(MassiaApplicationService.getApplication());
            });
        require('electron').ipcRenderer.on('logout-user', async (ev, res) => {
            MassiaAuthService.deleteToken(MassiaApplicationService.getApplication());
        }); */
    } else {
        $window.onbeforeunload = async function (e) {
            e.returnValue = 'Votre session va être déconnectée, êtes-vous sûr de vouloir poursuivre ?';
            MassiaAuthService.deleteToken(MassiaApplicationService.getApplication());

            return 'Votre session va être déconnectée, êtes-vous sûr de vouloir poursuivre ?';
        };
    }

    $window.onunload = function () {};

    $window.onload = async function (e) {
        //MassiaAuthService.refreshToken(MassiaApplicationService.getApplication());
    };

    return {};
}
