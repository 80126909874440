import BadgesConfig from './_config/badges.config';
import BadgesRoutes from './_config/badges.routes';

import BadgesComponent from './components/badges';
import BadgeDetailComponent from './components/badge-detail';
import BadgeFormComponent from './components/badge-form';

import BadgesService from './services/badges.service';
import BadgesCommunicationService from './services/badges.communication.service';

import BadgeValidator from './components/badge-form/badge.validator';

const moduleName = 'app.massia.gestion.badges';

angular
    .module(moduleName, [])
    .config(BadgesConfig)
    .config(BadgesRoutes)
    .service('BadgesService', BadgesService)
    .service('BadgesCommunicationService', BadgesCommunicationService)
    .component('badges', BadgesComponent)
    .component('badgeDetail', BadgeDetailComponent)
    .component('badgeForm', BadgeFormComponent)
    .factory('BadgeValidator', BadgeValidator);
export default moduleName;
