import FormuleCalculCo2Modal from './formule.calcul.co2.modal.controller';

export default {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: require('./formule.calcul.co2.modal.html'),
    controller: FormuleCalculCo2Modal
};
