import { Injectable } from '../../../core/decorators';

@Injectable('MassiaConsoleService')
export class MassiaConsoleService {
    constructor() {
        this.level = {
            info: {
                title: 'INFO',
                color: '#0099ff',
                size: '18px'
            },
            warning: {
                title: 'WARNING',
                color: '#ff9933',
                size: '18px'
            },
            error: {
                title: 'ERROR',
                color: '#ff3300',
                size: '24px'
            }
        };
    }

    setStyle(level, style) {
        this.level[level].color = style.color ? style.color : this.level[level].color;
        this.level[level].size = style.size ? style.size : this.level[level].size;
    }

    writeConsole(level, body) {
        body = body ? body : '';
        if (typeof body !== 'string') {
            console.log(
                `%c${this.level[level].title} | `,
                `color: ${this.level[level].color}; font-weight: bold; font-size: ${this.level[level].size};`,
                body
            );
        } else {
            console.log(
                `%c${this.level[level].title} | ${body}`,
                `color: ${this.level[level].color}; font-weight: bold; font-size: ${this.level[level].size};`
            );
        }
    }
}
