import validatorBuilder from 'validator-builder';

let tacheEnteteValidator = null;

export default class TacheEntete {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.typeId = data.typeId;
        this.priorite = data.priorite || 1;
        this.etat = data.etat || 0;
        this.pourcentageAvancement = data.pourcentageAvancement || 0;
        this.codeExists = data.codeExists || null;
        this.niveauVisibilite = 0;
        this.creePar = data.creePar;
        this.personnes = data.personnes || [];
        this.personnesIds = data.personnesIds || [];
        this.debut = data.debut;
        this.echeance = data.echeance;
    }

    isValid() {
        tacheEnteteValidator = tacheEnteteValidator || validatorBuilder.getInstanceFor('TacheEntete');
        const validationResults = tacheEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
