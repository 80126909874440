export default class SyntheseEntitiesExclusionController {
    constructor(
        $scope,
        $state,
        $stateParams,
        $translate,
        notification,
        moment,
        $uibModal,
        MassiaApplicationService,
        SyntheseEntitiesService,
        SyntheseEntitiesCommunicationService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.notification = notification;
        this.moment = moment;
        this.$uibModal = $uibModal;

        this.MassiaApplicationService = MassiaApplicationService;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
    }

    async $onInit() {
        this.dragOptions = {
            placeholder: 'table-row-ui-placeholder',
            revert: true,
            animation: 200,
            axis: 'y',
            'ui-preserve-size': true
        };

        this.activite = this.MassiaApplicationService.getApplication();

        this.startLoading();
        await this.getExclusions();
        this.stopLoading();
    }

    $onDestroy() {}

    async initForm() {}

    async getExclusions() {
        try {
            this.listeExclusions = await this.SyntheseEntitiesService.getExclusions();

            this.listeExclusions.map(function (e) {
                e.codeLibelle = e.code + ' / ' + e.libelle;
                return e;
            });
        } catch (err) {
            this.listeExclusions = [];

            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.exclusionsFiltered = this.listeExclusions;
        }
    }

    async addExclusion() {
        this.syntheseEntity.listeSyntheseExclusion.push({ isEdit: true });
    }
    async removeExclusion(index) {
        this.syntheseEntity.listeSyntheseExclusion.splice(index, 1);
    }

    async selectExclusion(exclusion) {
        if (exclusion && exclusion.code) {
            if (exclusion.code.constructor === Object) {
                exclusion.libelle = exclusion.code.libelle;
                exclusion.formule = exclusion.code.formule;
                exclusion.id = exclusion.code.id;
                exclusion.code = exclusion.code.code;
                exclusion.isEdit = false;
            }
        } else {
            exclusion.code = '';
            exclusion.libelle = '';
            exclusion.formule = '';
            exclusion.id = -1;
            exclusion.isEdit = true;
        }
    }

    async changeExclusion(exclusion, ev) {
        if (ev.target.value) {
            this.exclusionsFiltered = this.listeExclusions.filter(function (e) {
                return e.codeLibelle.toLowerCase().indexOf(ev.target.value.toLowerCase()) >= 0;
            });
        } else {
            this.exclusionsFiltered = this.listeExclusions;
        }

        if (!this.exclusionsFiltered || this.exclusionsFiltered.length <= 0) {
            exclusion.libelle = '';
            exclusion.formule = '';
            exclusion.id = -1;
            exclusion.isEdit = true;
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}

SyntheseEntitiesExclusionController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'notification',
    'moment',
    '$uibModal',
    'MassiaApplicationService',
    'SyntheseEntitiesService',
    'SyntheseEntitiesCommunicationService'
];
