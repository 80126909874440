export default function Configure($stateProvider) {
    $stateProvider.state('prestations', {
        parent: 'gestion',
        url: '/prestations',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('prestations.list', {
        url: '/list',
        template: '<prestations></prestations>',
        rights: { domain: 'prestation', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "PRESTATIONS.BREADCRUMBS.PRESTATIONS_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'PRESTATIONS.BREADCRUMBS.PRESTATIONS_LIST',
            order: 33
        },
        search: 'PRESTATIONS.BREADCRUMBS.PRESTATIONS_LIST'
    });

    $stateProvider.state('prestations.list.detail', {
        url: '/{id}',
        template: '<prestation-detail></prestation-detail>',
        rights: { domain: 'prestation', right: 'read' },
        ncyBreadcrumb: {
            parent: 'prestations.list',
            label: '{{ "PRESTATIONS.BREADCRUMBS.PRESTATION_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('prestations.new', {
        url: '/new',
        template: '<prestation-form></prestation-form>',
        rights: { domain: 'prestation', right: 'create' },
        ncyBreadcrumb: {
            parent: 'prestations.list',
            label: '{{ "PRESTATIONS.BREADCRUMBS.PRESTATION_NEW" | translate}}'
        },
        search: 'PRESTATIONS.BREADCRUMBS.PRESTATION_NEW'
    });

    $stateProvider.state('prestations.edit', {
        url: '/{id}/edit',
        template: '<prestation-form></prestation-form>',
        rights: { domain: 'prestation', right: 'update' },
        ncyBreadcrumb: {
            parent: 'prestations.list',
            label: '{{ "PRESTATIONS.BREADCRUMBS.PRESTATION_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
