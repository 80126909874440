MonitoringController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'MonitoringService',
    'notification'
];

export default function MonitoringController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    MonitoringService,
    notification
) {
    const vm = this;

    let previousTableState;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.monitoring = [];

    vm.slideWidth = '1200px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.selectMonitoring = selectMonitoring;
    vm.isMonitoringSelected = isMonitoringSelected;
    vm.loadMonitoring = loadMonitoring;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;

    function init() {}

    init();

    async function loadMonitoring(tableState) {
        startLoading();

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.monitoring = [];
        await getStatut();
        try {
            const data = await MonitoringService.getMonitoring(filters, sorts, pagination);

            vm.monitoring = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function selectMonitoring(moni) {
        if (moni && moni.id) {
            if (vm.selectedMonitoringId !== moni.id) {
                vm.selectedMonitoringId = moni.id;
                showDetail(moni.id);
            } else {
                vm.selectedMonitoringId = undefined;
                closeDetail();
            }
        }
    }

    async function getStatut() {
        vm.statut = await MonitoringService.getStatut();
    }

    function isMonitoringSelected(moni) {
        return moni && moni.id && vm.selectedMonitoringId === moni.id;
    }

    function showDetail(id) {
        $state.go('monitoring.list.detail', { id: id });
    }

    function closeDetail() {
        $state.go('monitoring.list');
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {}
}
