const serviceUrl = `${__configuration.apiUrl}/massia/gestion/suivi-droit-bitume`;

export default class SuiviDroitBitumeService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getSuiviDroitBitume(idSiteCommercial, dateDebut, dateFin, id) {
        const url = `${serviceUrl}/${id}`;
        const filtres = {
            idSiteCommercial: idSiteCommercial,
            dateDebut: dateDebut,
            dateFin: dateFin
        };
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {})
            }
        });

        return res.data;
    }

    async printSuiviDroitbitume(idSiteCommercial, dateDebut, dateFin, id) {
        const url = `${serviceUrl}/print/${id}`;
        const filtres = {
            idSiteCommercial: idSiteCommercial,
            dateDebut: dateDebut,
            dateFin: dateFin
        };
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {})
            },
            responseType: 'arraybuffer'
        });
        return res;
    }

    async createSuiviDroitbitume(data) {
        const result = await this.$http.post(serviceUrl, data);
        return result.data;
    }

    async updateSuiviDroitbitume(id) {
        const url = `${serviceUrl}/${id}`;
        const data = {};
        return await this.$http.put(url, data);
    }

    async getSiteCommerciaux() {
        const url = `${__configuration.apiUrl}/massia/sites-commerciaux/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getQuotaBitumeClotures(idSite) {
        const url = `${serviceUrl}/headers/${idSite}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async deleteClotureById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }
}
