import ActivationSiteSynchroConfig from './_config/activation.site.synchro.config';
import ActivationSiteSynchroRoutes from './_config/activation.site.synchro.routes';

import ActivationSiteSynchroService from './services/activation.site.synchro.service';

import ActivationSiteSynchroComponent from './components/activation-site-synchro';

const moduleName = 'app.massia.gestion.activation.site.synchro';

angular
    .module(moduleName, [])
    .config(ActivationSiteSynchroConfig)
    .config(ActivationSiteSynchroRoutes)
    .service('ActivationSiteSynchroService', ActivationSiteSynchroService)
    .component('activationSiteSynchro', ActivationSiteSynchroComponent);

export default moduleName;
