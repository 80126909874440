import HistoriqueComposantController from './historique.composant.controller';

export default {
    bindings: {
        id: '=',
        domaine: '=',
        isOpen: '=',
        onUpdate: '&'
    },
    template: require('./historique.composant.html'),
    controller: HistoriqueComposantController
};
