export default class RemiseFinAnneeGenerationController {
    static $inject = ['$stateParams', '$state', 'RemisesFinAnneeService', 'notification'];

    constructor($stateParams, $state, RemisesFinAnneeService, notification) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.RemisesFinAnneeService = RemisesFinAnneeService;
        this.notification = notification;
    }

    async $onInit() {
        this.loading = false;
        this.generation = {};
        await this.initGeneration();
    }

    async initGeneration() {
        // si on recoit bien l objet
        if (this.$state.params.obj) {
            if (this.$state.params.obj.length != 0) {
                try {
                    this.startLoading();
                    // on met a jour les donnees du scope 'detail'
                    this.generation = this.$state.params.obj;
                } catch (ex) {
                    this.notification.error(ex.data);
                } finally {
                    this.stopLoading();
                }
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
