export default class CommandeImpressionPrixHelperController {
    constructor() {
        this.commandeInfo = this.getCommandeInfo();
        this.societeInfo = this.getSocieteInfo();
        this.clientInfo = this.getClientInfo();
        this.chantierInfo = this.getChantierInfo();
        this.lignesInfo = this.getLignesInfo();
    }

    getCommandeInfo() {
        return [
            {
                balise: '{#Commande}',
                traduction: 'COMMANDES.COMMANDE_PRINT_HELPER.CODE'
            },
            {
                balise: '{#Devise}',
                traduction: 'COMMANDES.DEVISE'
            },
            {
                balise: '{#Etat}',
                traduction: 'COMMANDES.ETAT'
            },
            {
                balise: '{#Offre}',
                traduction: 'COMMANDES.GENERE_FROM_OFFRE'
            },
            {
                balise: '{#Proposition}',
                traduction: 'COMMANDES.PROPOSITION'
            },
            {
                balise: '{#Commentaire}',
                traduction: 'COMMANDES.COMMENTAIRE'
            },
            {
                balise: '{#Date_Creation}',
                traduction: 'COMMANDES.DATE_DEMANDE'
            },
            {
                balise: '{#Date_DebutLivraison}',
                traduction: 'COMMANDES.DATE_DEBUT_LIVRAISON'
            },
            {
                balise: '{#Date_FinLivraison}',
                traduction: 'COMMANDES.DATE_FIN_LIVRAISON'
            },
            {
                balise: '{#Total_HT}',
                traduction: 'COMMANDES.HT'
            },
            {
                balise: '{#Total_TVA}',
                traduction: 'COMMANDES.TVA'
            },
            {
                balise: '{#Total_TTC}',
                traduction: 'COMMANDES.TTC'
            }
        ];
    }
    getSocieteInfo() {
        return [
            {
                balise: '{#Societe}',
                traduction: 'COMMANDES.SOCIETE'
            },
            {
                balise: '{#Societe_Code}',
                traduction: 'SOCIETES.CODE'
            },
            {
                balise: '{#Societe_Adresse}',
                traduction: 'SOCIETES.ADRESSE'
            },
            {
                balise: '{#Societe_Ville}',
                traduction: 'SOCIETES.VILLE'
            },
            {
                balise: '{#Societe_CP}',
                traduction: 'SOCIETES.CODEPOSTAL'
            },
            {
                balise: '{#Societe_Fax}',
                traduction: 'SOCIETES.FAX'
            },
            {
                balise: '{#Societe_Tel}',
                traduction: 'SOCIETES.TELEPHONE'
            },
            {
                balise: '{#Societe_RS}',
                traduction: 'SOCIETES.RAISON'
            },
            {
                balise: '{#Societe_TVAIntra}',
                traduction: 'SOCIETES.TVAINTRACOMMUNAUTAIRE'
            },
            {
                balise: '{#Societe_SIREN}',
                traduction: 'SOCIETES.SIREN'
            },
            {
                balise: '{#Societe_LOGO}',
                traduction: 'SOCIETES.LOGO'
            }
        ];
    }
    getClientInfo() {
        return [
            {
                balise: '{#Client}',
                traduction: 'COMMANDES.CLIENT'
            },
            {
                balise: '{#Client_Code}',
                traduction: 'SOCIETES.CODE'
            },
            {
                balise: '{#Client_Adresse}',
                traduction: 'SOCIETES.ADRESSE'
            },
            {
                balise: '{#Client_Ville}',
                traduction: 'SOCIETES.VILLE'
            },
            {
                balise: '{#Client_CP}',
                traduction: 'SOCIETES.CODEPOSTAL'
            },
            {
                balise: '{#Client_Fax}',
                traduction: 'SOCIETES.FAX'
            },
            {
                balise: '{#Client_Tel}',
                traduction: 'SOCIETES.TELEPHONE'
            },
            {
                balise: '{#Client_Logo}',
                traduction: 'SOCIETES.Logo'
            },
            {
                balise: '{#Client_Reference}',
                traduction: 'COMMANDES.REFERENCE'
            }
        ];
    }
    getChantierInfo() {
        return [
            {
                balise: '{#Chantier}',
                traduction: 'COMMANDES.CHANTIER'
            },
            {
                balise: '{#Chantier_Code}',
                traduction: 'SOCIETES.CODE'
            },
            {
                balise: '{#Chantier_Adresse}',
                traduction: 'SOCIETES.ADRESSE'
            },
            {
                balise: '{#Chantier_Ville}',
                traduction: 'SOCIETES.VILLE'
            },
            {
                balise: '{#Chantier_CP}',
                traduction: 'SOCIETES.CODEPOSTAL'
            },
            {
                balise: '{#Chantier_RefClient}',
                traduction: 'COMMANDES.REFERENCE'
            },
            {
                balise: '{#Chantier_Debut}',
                traduction: 'COMMANDES.DATE_DEBUT'
            },
            {
                balise: '{#Chantier_Fin}',
                traduction: 'COMMANDES.DATE_FIN'
            }
        ];
    }
    getLignesInfo() {
        return {
            propertiesProduit: [
                {
                    balise: '{Produit[Libelle]}',
                    traduction: 'COMMANDES.PRODUIT'
                },
                {
                    balise: '{Produit[PrixStandard]}',
                    traduction: 'COMMANDES.PRIX_STANDARD'
                },
                {
                    balise: '{Produit[PrixVente]}',
                    traduction: 'COMMANDES.PRIX_VENTE'
                },
                {
                    balise: '{Produit[PrixFranco]}',
                    traduction: 'COMMANDES.PRIX_FRANCO'
                },
                {
                    balise: '{Produit[QuantiteCommandee]}',
                    traduction: 'COMMANDES.QUANTITE_COMMANDEE'
                },
                {
                    balise: '{Produit[QuantiteLivree]}',
                    traduction: 'COMMANDES.QUANTITE_LIVREE'
                },
                {
                    balise: '{Produit[SiteCommercial]}',
                    traduction: 'COMMANDES.SITE_COMMERCIAL'
                },
                {
                    balise: '{Produit[Producteur]}',
                    traduction: 'COMMANDES.SITE_PRODUCTEUR'
                },
                {
                    balise: '{Produit[Unite]}',
                    traduction: 'COMMANDES.UNITE'
                },
                {
                    balise: '{Produit[TauxTVA]}',
                    traduction: 'COMMANDES.TVA'
                },
                {
                    balise: '{Produit[TgapExclue]}',
                    traduction: 'OFFRES.TGAP_EXCLURE'
                },
                {
                    balise: '{Produit[ES]}',
                    traduction: 'COMMANDES.E_S'
                }
            ],
            propertiesPresta: [
                {
                    balise: '{Libelle}',
                    traduction: 'COMMANDES.PRESTATION'
                },
                {
                    balise: '{PrixStandard}',
                    traduction: 'COMMANDES.PRIX_STANDARD'
                },
                {
                    balise: '{PrixVente}',
                    traduction: 'COMMANDES.PRIX_VENTE'
                },
                {
                    balise: '{PrixFranco}',
                    traduction: 'COMMANDES.PRIX_FRANCO'
                },
                {
                    balise: '{QuantiteCommandee}',
                    traduction: 'COMMANDES.QUANTITE_COMMANDEE'
                },
                {
                    balise: '{QuantiteLivree}',
                    traduction: 'COMMANDES.QUANTITE_LIVREE'
                },
                {
                    balise: '{SiteCommercial}',
                    traduction: 'COMMANDES.SITE_COMMERCIAL'
                },
                {
                    balise: '{Producteur}',
                    traduction: 'COMMANDES.SITE_PRODUCTEUR'
                },
                {
                    balise: '{Unite}',
                    traduction: 'COMMANDES.UNITE'
                },
                {
                    balise: '{TauxTVA}',
                    traduction: 'COMMANDES.TVA'
                },
                {
                    balise: '{TgapExclue}',
                    traduction: 'OFFRES.TGAP_EXCLURE'
                },
                {
                    balise: '{ES}',
                    traduction: 'COMMANDES.E_S'
                },
                {
                    balise: '{PrixBenne}',
                    traduction: 'COMMANDES.PRIXBENNE'
                },
                {
                    balise: '{PrixZone}',
                    traduction: 'COMMANDES.PRIXZONE'
                }
            ],
            propertiesTransport: [
                {
                    balise: '{Libelle}',
                    traduction: 'COMMANDES.TRANSPORT'
                },
                {
                    balise: '{PrixStandard}',
                    traduction: 'COMMANDES.PRIX_STANDARD'
                },
                {
                    balise: '{PrixVente}',
                    traduction: 'COMMANDES.PRIX_VENTE'
                },
                {
                    balise: '{PrixFranco}',
                    traduction: 'COMMANDES.PRIX_FRANCO'
                },
                {
                    balise: '{QuantiteCommandee}',
                    traduction: 'COMMANDES.QUANTITE_COMMANDEE'
                },
                {
                    balise: '{QuantiteLivree}',
                    traduction: 'COMMANDES.QUANTITE_LIVREE'
                },
                {
                    balise: '{SiteCommercial}',
                    traduction: 'COMMANDES.SITE_COMMERCIAL'
                },
                {
                    balise: '{Producteur}',
                    traduction: 'COMMANDES.SITE_PRODUCTEUR'
                },
                {
                    balise: '{Unite}',
                    traduction: 'COMMANDES.UNITE'
                },
                {
                    balise: '{TauxTVA}',
                    traduction: 'COMMANDES.TVA'
                },
                {
                    balise: '{TgapExclue}',
                    traduction: 'OFFRES.TGAP_EXCLURE'
                },
                {
                    balise: '{ES}',
                    traduction: 'COMMANDES.E_S'
                },
                {
                    balise: '{PrixBenne}',
                    traduction: 'COMMANDES.PRIXBENNE'
                },
                {
                    balise: '{PrixZone}',
                    traduction: 'COMMANDES.PRIXZONE'
                }
            ],
            propertiesArticles: [
                {
                    balise: '{Libelle}',
                    traduction: 'COMMANDES.ARTICLE_LIBELLE'
                },
                {
                    balise: '{PrixStandard}',
                    traduction: 'COMMANDES.PRIX_STANDARD'
                },
                {
                    balise: '{PrixVente}',
                    traduction: 'COMMANDES.PRIX_VENTE'
                },
                {
                    balise: '{PrixFranco}',
                    traduction: 'COMMANDES.PRIX_FRANCO'
                },
                {
                    balise: '{QuantiteCommandee}',
                    traduction: 'COMMANDES.QUANTITE_COMMANDEE'
                },
                {
                    balise: '{QuantiteLivree}',
                    traduction: 'COMMANDES.QUANTITE_LIVREE'
                },
                {
                    balise: '{SiteCommercial}',
                    traduction: 'COMMANDES.SITE_COMMERCIAL'
                },
                {
                    balise: '{Producteur}',
                    traduction: 'COMMANDES.SITE_PRODUCTEUR'
                },
                {
                    balise: '{Unite}',
                    traduction: 'COMMANDES.UNITE'
                },
                {
                    balise: '{TauxTVA}',
                    traduction: 'COMMANDES.TVA'
                },
                {
                    balise: '{TgapExclue}',
                    traduction: 'OFFRES.TGAP_EXCLURE'
                },
                {
                    balise: '{ES}',
                    traduction: 'COMMANDES.E_S'
                },
                {
                    balise: '{PrixBenne}',
                    traduction: 'COMMANDES.PRIXBENNE'
                },
                {
                    balise: '{PrixZone}',
                    traduction: 'COMMANDES.PRIXZONE'
                }
            ]
        };
    }
    close() {
        this.modalInstance.dismiss('close');
    }
}
