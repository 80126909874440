import DomainesAvecTypesSelectController from './domaines.avec.types.select.controller';

export default {
    template: require('./domaines.avec.types.select.tpl.html'),
    controller: DomainesAvecTypesSelectController,
    bindings: {
        disabled: '<',
        clearOnSelect: '<',
        selected: '<',
        excludes: '<',
        onSelect: '&',
        personnalise: '='
    }
};
