export default {
    Defini: {
        id: 1,
        libelle: 'Défini',
        value: 'defini'
    },
    Libre: {
        id: 2,
        libelle: 'Saisie libre',
        value: 'libre'
    },
    Pays: {
        id: 3,
        libelle: 'Pays',
        value: 'pays'
    }
};
