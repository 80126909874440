import Pickr from '@simonwep/pickr';
//import '@simonwep/pickr/dist/themes/classic.min.css';
import '@simonwep/pickr/dist/themes/monolith.min.css';
import { IScope } from 'angular';
import { Directive, Input, OnDestroy, OnInit, Output } from '../../../core/decorators';
//import '@simonwep/pickr/dist/themes/nano.min.css';

@Directive({ selector: '[massiaColorPicker]' })
export class MassiaColorPicker implements OnInit, OnDestroy {
    @Output() massiaColorPicker: CallableFunction;
    @Input() massiaColorPickerDisabled: boolean;
    @Input() couleur: any;
    @Input() disableOpacity: boolean;

    pickr: Pickr;

    /* @ngInject */
    constructor(public $scope: IScope, public $element: JQuery, public ColorPickerService: any, public $translate: any) {}

    ngOnDestroy(): void {
        if (this.pickr) {
            this.pickr.destroyAndRemove();
        }
    }

    ngOnInit(): void {
        this.$element.addClass('color-picker');
        this.createPickrOptions().then((res) => {
            this.pickr = Pickr.create(res);

            this.pickr.on('save', (color: any, instance: any) => {
                this.massiaColorPicker({
                    $color: {
                        HSVA: color.toHSVA(),
                        HSLA: color.toHSLA(),
                        RGBA: color.toRGBA(),
                        HEXA: color.toHEXA()
                    }
                });
                instance.hide();
            });

            this.pickr.on('cancel', (color: any, instance: any) => {
                instance.hide();
            });
        });
    }

    async createPickrOptions(): Promise<Pickr.Options> {
        try {
            return {
                el: '.color-picker',
                container: '.app-shell',
                theme: 'monolith', // or 'monolith', or 'nano'
                swatches: null,
                lockOpacity: false,
                disabled: this.massiaColorPickerDisabled,
                default:
                    typeof this.couleur === 'string'
                        ? this.couleur
                        : this.ColorPickerService.hslToHex(
                              this.couleur ? this.couleur.hue : 0,
                              this.couleur ? this.couleur.saturation : 0,
                              this.couleur ? this.couleur.luminosity : 0
                          ),
                components: {
                    // Main components
                    preview: true,
                    opacity: true,
                    hue: true,
                    // Input / output Options
                    interaction: {
                        hex: true,
                        rgba: true,
                        hsla: true,
                        hsva: true,
                        cmyk: true,
                        input: true,
                        cancel: true,
                        save: true
                    }
                },
                strings: {
                    save: await this.$translate('VALIDER'),
                    cancel: await this.$translate('ANNULER')
                }
            };
        } catch (err) {
            console.error(err);
        }
    }
}
