/* @ngInject */
export default class SyntheseEnteteController {
    constructor(SyntheseSettingService, MassiaApplicationService, $stateParams, $scope) {
        this.SyntheseSettingService = SyntheseSettingService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$stateParams = $stateParams;
        this.$scope = $scope;
    }
    async $onInit() {
        try {
            this.codeprev = this.model.code;
            this.setduplicate(false);
            const res = await this.SyntheseSettingService.loadTypeSynthese(this.MassiaApplicationService.getApplication());
            this.typeSynthese = res;
            if (this.$stateParams.type && !this.$stateParams.id) {
                const type = this.typeSynthese.find((x) => x.id == this.$stateParams.type);
                if (type) {
                    this.model.typeSynthese = type.id;
                }
            }
        } catch (err) {
            console.error(err);
        }
    }
    setduplicate(needInversion = true)
    {
        if (needInversion)
        {
            this.model.isduplicate = !this.model.isduplicate;
        }
        if (this.model.isduplicate === true)
        {
            this.model.code += "_COPIE";
        }
        else
        {
            this.model.code = this.codeprev;
        }
    }
    $onDestroy() {}

    selectType() {
        this.model.typeSynthese = this.selectedType[0] != null ? this.selectedType[0].id : null;
    }
}
