import SynthesesConsoClientController from '../../../gestion/syntheses/components/syntheses-conso-client/syntheses-conso-client.controller';

export default function JoursFeriesConfiguration($translatePartialLoaderProvider) {
    // On configure la gestion de la traduction en lui indiquant quel fichier json il doit charger pour ce module

    //cannot use MassiaApplicationService here...
    const url = window.location.href.replace('http://', '').replace('https://', '');
    const parts = url.split('/');
    if (parts.findIndex((e) => e.indexOf('performance.html') >= 0) >= 0) {
        $translatePartialLoaderProvider.addPart('jours.chomes');
    } else {
        $translatePartialLoaderProvider.addPart('jours.feries');
    }
}

JoursFeriesConfiguration.$inject = ['$translatePartialLoaderProvider'];
