import SiteZoneStockagesController from './site.zone.stockages.controller';

export default {
    bindings: {
        site: '<',
        isOpen: '=',
        onUpdate: '&'
    },
    template: require('./site.zone.stockages.html'),
    controller: SiteZoneStockagesController
};
