export default {
    textecourt: 'text',
    textelong: 'textarea',
    date: 'date',
    heure: 'heure',
    numerique: 'number',
    booleen: 'bool',
    elementliste: 'select',
    analysegranulo: 'granulo',
    ensembletextecourt: 'multitext',
    ensembledates: 'multidate',
    ensemblenumerique: 'multinumber',
    ensembleelementliste: 'multiselect',
    multikeyvalue: 'multikeyvalue',
    undefined: 'text'
};

export const natureEnum = {
    0: 'texteCourt',
    1: 'texteLong',
    2: 'date',
    3: 'numerique',
    4: 'booleen',
    5: 'elementListe',
    7: 'heure',
    60: 'ensembleTexteCourt',
    61: 'ensembleDates',
    62: 'ensembleNumerique',
    63: 'ensembleElementListe',
    70: 'undefined'
};
