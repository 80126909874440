import StockClotureMoisPopupController from './stock.cloture.mois.popup.controller';

export default {
    bindings: {
        modalInstance: '=',
        resolve: '<'
    },
    template: require('./stock.cloture.mois.popup.html'),
    controller: StockClotureMoisPopupController
};
