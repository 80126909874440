import RemisesFinAnneeConfig from './_config/remises.fin.annee.config';
import RemisesFinAnneeRoutes from './_config/remises.fin.annee.routes';

import RemisesFinAnneeService from './services/remises.fin.annee.service';

import RemisesFinAnneeComponent from './components/remises-fin-annee';
import RemiseFinAnneeDetailComponent from './components/remise-fin-annee-detail';
import RemiseFinAnneeFormComponent from './components/remise-fin-annee-form';
import RemiseFinAnneeValidator from './components/remise-fin-annee-form/remise.fin.annee.validator';
import RemiseFinAnneeGenerationComponent from './components/remise-fin-annee-generation';

const moduleName = 'app.massia.gestion.remises.fin.annee';

angular
    .module(moduleName, [])
    .config(RemisesFinAnneeConfig)
    .config(RemisesFinAnneeRoutes)
    .service('RemisesFinAnneeService', RemisesFinAnneeService)
    .factory('RemiseFinAnneeValidator', RemiseFinAnneeValidator)
    .component('remisesFinAnnee', RemisesFinAnneeComponent)
    .component('remiseFinAnneeForm', RemiseFinAnneeFormComponent)
    .component('remiseFinAnneeDetail', RemiseFinAnneeDetailComponent)
    .component('remiseFinAnneeGeneration', RemiseFinAnneeGenerationComponent);

export default moduleName;
