(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.treeview')
        .directive('acTreeviewLeaf', acTreeviewLeaf);

    acTreeviewLeaf.$inject = ['$templateCache', 'RecursionHelper'];

    /* @ngInject */
    function acTreeviewLeaf($templateCache, RecursionHelper) {
        var directive = {
            restrict: 'A',
            template: function () {
                return $templateCache.get('blocks/treeview/ac.treeview.leaf.tpl.html');
            },
            compile: function (element) {
                return RecursionHelper.compile(element, function (scope, iElement, iAttrs, controller, transcludeFn) {
                    scope.leaf = scope.child;
                });
            }
        };
        return directive;
    }
})(angular);