(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.dragdrop')
        .directive('acDraggable', draggable);
    draggable.$inject = ['acDragDropService'];

    /* @ngInject */
    function draggable(acDragDropService) {
        /* jshint validthis: true */
        var directive = {
            link: link,
            restrict: 'A'
        };
        return directive;

        function link(scope, element, attrs) {
            var el = element[0];
            var onDestroy = scope.$on('$destroy', dispose);

            scope.$watch(attrs.acDraggableDisabled, function (newValue, oldValue) {
                if (newValue === true) {
                    el.draggable = false;
                    el.removeEventListener('dragstart', dragstart);
                    el.removeEventListener('dragend', dragend);
                }
                else {
                    el.draggable = true;
                    el.addEventListener('dragstart', dragstart, false);
                    el.addEventListener('dragend', dragend, false);
                }
            }, true);

            function dragstart(e) {
                if (e.stopPropagation) e.stopPropagation();

                acDragDropService.setDraggedObject(scope.$eval(attrs.acDraggable));

                if (attrs.acDraggableParent) {
                    acDragDropService.setParentObject(scope.$eval(attrs.acDraggableParent));
                }

                if (attrs.acDraggableDragEnd) {
                    acDragDropService.setDragEndFunction(scope.$eval(attrs.acDraggableDragEnd));
                }

                if (attrs.acDraggableDragStart) {
                    scope.$eval(attrs.acDraggableDragStart)(acDragDropService.getDraggedObject());
                }

                e.dataTransfer.effectAllowed = 'move';
                e.dataTransfer.setData('text', 'acPlanningDraggable');

                this.classList.add('dragged');
                return false;
            }

            function dragend(e) {
                acDragDropService.unsetDraggedObject();

                this.classList.remove('dragged');
                return false;
            }

            function dispose() {
                el.draggable = false;

                el.removeEventListener('dragstart', dragstart);
                el.removeEventListener('dragend', dragend);

                acDragDropService.unsetDraggedObject();

                if (onDestroy !== undefined) {
                    onDestroy();
                }
            }
        }
    }
})(angular);