export default class SyntheseEntityResistanceTabDetailsController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'SyntheseEntitiesCommunicationService',
		'_',
		'notification',
		'MassiaApplicationService',
		'$translate',
		'SyntheseEntitiesService',
		'NormesService',
		'$filter',
		'ColorPickerService',
		'$uibModal',
		'moment',
		'globalizationManagementService',
		'Interpreteur',
		'ParametreProgrammeService'
	];

	constructor(
		$scope,
		$state,
		$stateParams,
		SyntheseEntitiesCommunicationService,
		_,
		notification,
		MassiaApplicationService,
		$translate,
		SyntheseEntitiesService,
		NormesService,
		$filter,
		ColorPickerService,
		$uibModal,
		moment,
		globalizationManagementService,
		Interpreteur,
		ParametreProgrammeService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$filter = $filter;
		this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
		this._ = _;
		this.notification = notification;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$translate = $translate;
		this.SyntheseEntitiesService = SyntheseEntitiesService;
		this.NormesService = NormesService;
		this.ColorPickerService = ColorPickerService;
		this.$uibModal = $uibModal;
		this.moment = moment;
		this.globalizationManagementService = globalizationManagementService;
		this.Interpreteur = Interpreteur;
		this.ParametreProgrammeService = ParametreProgrammeService;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async $onInit() {
		this.unregisterReinit = this.SyntheseEntitiesCommunicationService.registerReinit(() => this.initForm());
		this.Interpreteur.init(undefined, undefined);
		this.Interpreteur.calculatedFormules = [];

		this.carBetClResist = await this.ParametreProgrammeService.getOneByCode('CarBetClResist');

		this.resultat = this.resultat || null;
		this.controle = this.controle || {};
		this.produit = this.produit || null;
		this.isFamilleBeton = this.isFamilleBeton || null;
		this.produitId = this.produit ? this.produit.id : 0;
		this.type = this.type || null;

		this.produitId = this.id.toString();
		if (this.type === 'famille') {
			this.produitId = 'F' + this.id;
		}
		const that = this;
		this.controleSpecs = angular.copy(this.controle.specs);
		if (this.controleSpecs && this.controleSpecs.length > 1) {
			const specType = this.controleSpecs.find(function (spec) {
				return spec.libelleGamme ? spec.libelleGamme.toLowerCase().startsWith(that.type) : false;
			});
			if (specType) {
				this.controleSpecs = specType.essais.filter(function (e) {
					return e.code === that.resultat.entete.titresEssais[0].code;
				});
			}
		} else if (this.controleSpecs && this.controleSpecs.length > 0) {
			this.controleSpecs = this.controleSpecs[0].essais.filter(function (e) {
				return e.code === that.resultat.entete.titresEssais[0].code;
			});
		}

		if (
			this.resultat.lignes &&
			this.resultat.lignes.length > 0 &&
			this.resultat.lignes[0].valeursEssais &&
			this.resultat.lignes[0].valeursEssais.length > 0
		) {
			this.resultat[this.produitId] = {};
			this.resultat[this.produitId].statsBeton = {};

			const stats = this.resultat.stats; //les stats sont ici dans le bon ordre

			for (let e = 0; e < this.model.formattedResult.entete.titresEssais.length; e++) {
				const ess = this.model.formattedResult.entete.titresEssais[e];

				let iStat = 0;
				for (let i = 0; i < stats.length; i++) {
					//stats.length - 1; i >= 0; i--) {
					const stat = stats[i];

					if (
						(stat.groupementIndex === 0 && this.type !== 'famille') ||
						(stat.groupementIndex === 1 && this.type === 'famille') ||
						(stat.groupementIndex === 2 && this.type === 'famille')
					) {
						// MN: Si statCode est demandé dans deux groupementIndex(1 et 2): son groupementIndex=21(2+1)
						if (this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code] != null) {
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].groupementIndex = 21;
						} else {
							//const specEssai = specEssais.find(function (s) { return s.formuleCalcStat === stat.formule; });
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code] = /*specEssai ? angular.copy(specEssai) :*/ {};
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].idCalcStat = stat.idCalcStat;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].values = { nb: 0, nbConforme: 0, nbNonConforme: 0 };
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].titre = stat.titre;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].code = stat.code;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].formule = stat.formule;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].isGroupement = stat.isGroupement;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].isGroupementGlissant = stat.isGroupementGlissant;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].nbValGroupe = stat.nbValGroupe;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].limiterGrandeur = stat.limiterGrandeur;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].arrondiDeLEssai = stat.arrondiDeLEssai;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].arrondi = stat.arrondi;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].isCumul = stat.isCumul;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].nbValMinCumul = stat.nbValMinCumul;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].isTranspose = stat.isTranspose;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].isLastGroupOnly = stat.isLastGroupOnly;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].arrondi = stat.arrondi;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].arrondiDeLEssai = stat.arrondiDeLEssai;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].isComplementaire = ess.isComplementaire;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].groupementIndex = stat.groupementIndex;
							this.resultat[this.produitId].statsBeton[stat.code + '_' + ess.code].decimaleSupp = stat.decimaleSupp;
						}
						if (stat.groupementIndex !== 2) {
							iStat++;
						}
					}
					// else {
					//     this.resultat.stats.splice(i, 1);
					// }
				}

				this.resultat[this.produitId].nbStats = iStat;
			}
		}
		this.lignes = angular.copy(this.resultat.lignes);
		if (this.lignes && this.lignes.length > 0) {
			if (this.lignes[0].datePrelevement) {
				this.lignes = this.lignes.sort((a, b) => {
					const db = this.moment(b.datePrelevement, this.dateFormat).unix();
					const da = this.moment(a.datePrelevement, this.dateFormat).unix();
					return da - db;
				});
			}
		}
		await this.formateSynthese(this.produit);
		//mise à jour les Pv exlures (venant de l'onglet "Famille")
		this.$scope.$on('listePvsAExclure', (e, resultat) => {
			resultat.produits.forEach((produit) => {
				this.formateSynthese(produit);
			});
		});
	}

	$onDestroy() {
		this.unregisterReinit();
	}

	async initForm() {}
	async formateSynthese(produit, param) {
		this.currentStep = 0; //Etape 1
		this.currentSide = param && param.isExclus ? 1 : 0; //Actifs / Exclus
		const that = this;
		for (let e = 0; e < this.model.formattedResult.entete.titresEssais.length; e++) {
			const ess = this.model.formattedResult.entete.titresEssais[e];
			let lines = null;
			let ecartTypeAdopte = undefined;
			if (this.type === 'famille') {
				lines = this.lignes
					? this.lignes.filter(function (e) {
							if (e) {
								const isInFamille = e.famille && 'F' + e.famille.idFamille === that.produitId;
								return (
									(((!param || !param.isExclus) && !e.excluded) || (param && param.isExclus && e.excluded)) &&
									(that.produitId === 'F0' || isInFamille)
								);
							}
					  })
					: [];
				ecartTypeAdopte = this.famille.currentETA;
			} else {
				lines = this.lignes
					? this.lignes.filter(function (e) {
							if (e) {
								return (
									(((!param || !param.isExclus) && !e.excluded) || (param && param.isExclus && e.excluded)) &&
									(that.produitId === '0' ||
										(that.model.synthese.isCritere2ProduitCom
											? e.idProduitCom.toString() === that.produitId
											: e.idProduit.toString() === that.produitId))
								);
							}
					  })
					: [];
				ecartTypeAdopte = this.produit.currentETA;
			}

			const values = [];
			const transposees = [];
			if (this.resultat[this.produitId]) {
				this.resultat[this.produitId].lignes = [];
				for (let i = 0; i < lines.length; i++) {
					const line = lines[i];
					const mainEssai = line.valeursEssais
						? line.valeursEssais.find(function (z) {
								return z.idEssai == ess.id;
						  })
						: undefined;
					const arrondi = mainEssai.arrondi;
					const value = mainEssai && mainEssai.valeur ? parseFloat(this.arrondiValeur(mainEssai.valeur, arrondi)) : 0;
					values.push(value);

					//chercher les transposées (que si demandé par les stats)

					if (this.resultat[this.produitId].statsBeton) {
						let calcTransp = false;
						for (const stat in this.resultat[this.produitId].statsBeton) {
							if (stat.endsWith(ess.code)) {
								if (this.resultat[this.produitId].statsBeton[stat].isTranspose) {
									calcTransp = true;
									break;
								}
							}
						}
						if (line.famille && line.famille.regleTranspoFormule && calcTransp) {
							const valueT = mainEssai && mainEssai.valeurTranspo ? parseFloat(mainEssai.valeurTranspo) : 0;
							if (!valueT && value) {
								const formuleTransp = line.famille.regleTranspoFormule.replace(/RMES/g, value);
								const valueT = await this.Interpreteur.calculeFormule(formuleTransp);
							}
							transposees.push(valueT);
						}
					}

					if (!line.stats) {
						line.stats = {};
					}
					this.resultat[this.produitId].lignes.push(line);
				}

				if (values.length > 0) {
					const completeEssai = this.model.synthese.listeLiensSETS.find((e) => e.idEssai === ess.id);
					const essStat = completeEssai.listeSousEssaisHeaders.find(function (y) {
						return y.codeSsEss == ess.code;
					});
					for (const stat in this.resultat[this.produitId].statsBeton) {
						if (stat.endsWith(ess.code)) {
							let iArrondi = 2;
							if (this.resultat[this.produitId].statsBeton[stat].arrondiDeLEssai) {
								const newArrondi =
									this.resultat[this.produitId].statsBeton[stat].decimaleSupp !== 0 &&
									this.resultat[this.produitId].statsBeton[stat].decimaleSupp !== null
										? this.Interpreteur.formatDecSupp(
												essStat.arrondi,
												this.resultat[this.produitId].statsBeton[stat].decimaleSupp
										  )
										: essStat.arrondi;
								iArrondi = newArrondi;
							} else {
								if (
									this.resultat[this.produitId].statsBeton[stat].arrondi != undefined &&
									this.resultat[this.produitId].statsBeton[stat].arrondi != null
								) {
									iArrondi = this.resultat[this.produitId].statsBeton[stat].arrondi;
								}
							}

							//if (stat !== 'VAL') {
							//si il y a des parenthèses (), les remplir avec les valeurs, sinon terminer avec (valeurs)
							const formule = this.resultat[this.produitId].statsBeton[stat].formule;
							const params = {
								isGroupement: this.resultat[this.produitId].statsBeton[stat].isGroupement,
								isGroupementGlissant: this.resultat[this.produitId].statsBeton[stat].isGroupementGlissant,
								nbValGroupe: this.resultat[this.produitId].statsBeton[stat].nbValGroupe,
								isCumul: this.resultat[this.produitId].statsBeton[stat].isCumul,
								nbValMinCumul: this.resultat[this.produitId].statsBeton[stat].nbValMinCumul || 1,
								isTranspose: this.resultat[this.produitId].statsBeton[stat].isTranspose,
								isLastGroupOnly: this.resultat[this.produitId].statsBeton[stat].isLastGroupOnly
							};
							const strForFormule = params.isTranspose ? transposees.join(';') : values.join(';');

							let resultat = [];
							if (
								this.resultat[this.produitId].statsBeton[stat].formule !== '@VAL' &&
								this.resultat[this.produitId].statsBeton[stat].formule !== '@VAL()'
							) {
								const currentFormule = formule.replace(/\(\)/g, '');
								resultat = await this.Interpreteur.calculeFormuleWithParams(currentFormule + '(' + strForFormule + ')', params);
								if (resultat.constructor !== Array && parseFloat(resultat)) {
									resultat = [resultat];
								} //resultat = [resultat.toFixed(2)];

								for (let iVal = 0; iVal < resultat.length; iVal++) {
									let resultatCourant = resultat[iVal];
									//gestion des min/max
									if (this.resultat[this.produitId].statsBeton[stat].limiterGrandeur) {
										if (
											essStat.grandeurMinimum != undefined &&
											essStat.grandeurMinimum != null &&
											essStat.grandeurMinimum > resultatCourant
										) {
											resultatCourant = essStat.grandeurMinimum;
										}
										if (
											essStat.grandeurMaximum != undefined &&
											essStat.grandeurMaximum != null &&
											essStat.grandeurMaximum < resultatCourant
										) {
											resultatCourant = essStat.grandeurMaximum;
										}
									}
									//gestion de l'arrondi
									resultat[iVal] = this.arrondiValeur(resultatCourant, iArrondi);
								}
							}

							let iGrp = 0;
							let iGls = 0;
							let iCum = 0;
							const linesArray = []; // JN: 1355
							for (let l = 0; l < lines.length; l++) {
								const line = this.resultat[this.produitId].lignes[l];

								let nbValStat = 0;
								if (
									this.resultat[this.produitId].statsBeton[stat].formule === '@VAL' ||
									this.resultat[this.produitId].statsBeton[stat].formule === '@VAL()'
								) {
									nbValStat = 1;
									let valeurInd = params.isTranspose ? transposees[l] : values[l];
									//gestion des min/max
									if (this.resultat[this.produitId].statsBeton[stat].limiterGrandeur) {
										if (
											essStat.grandeurMinimum != undefined &&
											essStat.grandeurMinimum != null &&
											essStat.grandeurMinimum > valeurInd
										) {
											valeurInd = essStat.grandeurMinimum;
										}
										if (
											essStat.grandeurMaximum != undefined &&
											essStat.grandeurMaximum != null &&
											essStat.grandeurMaximum < valeurInd
										) {
											valeurInd = essStat.grandeurMaximum;
										}
									}
									//gestion de l'arrondi
									valeurInd = this.arrondiValeur(valeurInd, iArrondi);
									line.stats[stat] = { value: valeurInd };
								} else if (params.isCumul && l + 1 >= params.nbValMinCumul) {
									nbValStat = l + 1;
									line.stats[stat] = { value: resultat[iCum] };
									iCum++;
								} else if (!params.isCumul && !params.isGroupement && l === lines.length - 1) {
									nbValStat = l + 1;
									line.stats[stat] = { value: resultat[0] };
								} else if (!params.isCumul && params.isGroupement && params.isLastGroupOnly) {
									nbValStat = params.nbValGroupe;
									if (l == lines.length - 1 && lines.length >= nbValStat) {
										line.stats[stat] = { value: resultat[resultat.length - 1] };
									} else {
										line.stats[stat] = { value: '' };
									}
								} else if (
									!params.isCumul &&
									params.isGroupement &&
									!params.isGroupementGlissant &&
									(l + 1) % params.nbValGroupe === 0
								) {
									nbValStat = params.nbValGroupe;
									line.stats[stat] = { value: resultat[iGrp] };
									iGrp++;
								} else if (!params.isCumul && params.isGroupement && params.isGroupementGlissant && l + 1 >= params.nbValGroupe) {
									nbValStat = params.nbValGroupe;
									line.stats[stat] = { value: resultat[iGls] };
									iGls++;
								} else {
									line.stats[stat] = { value: '' };
								}

								if (line.dicEssaisCompoFck && line.dicEssaisCompoFck[ess.id]) {
									line.fck = line.dicEssaisCompoFck[ess.id];
								}

								if (line && line.valeursEssais) {
									const index = this.model.synthese.listeSyntheseCritereAffichage.findIndex((e) => {
										return e.champ == 20;
									});
									if (index >= 0 && line.valeursAffichage.length > index) {
										line.valeursAffichage[index].valeur = line.fck;
									}
								}

								if (line.stats[stat] && line.stats[stat].value == '-1') {
									line.stats[stat].value = '';
								}
								let fckRef = 0;
								const betonsRef = this.resultat.produits.filter((x) => {
									return x.famille.isBetonReference;
								});
								const betonRef = betonsRef.find((x) => x.famille.idFamille == line.famille.idFamille);
								const prel = this.resultat.lignes.find((p) => p.codeProduit === betonRef?.code);
								if (prel?.caractProduitCom && prel?.caractProduitCom.length > 0) {
									const caractRes = prel?.caractProduitCom.find((c) => c.code === this.carBetClResist);
									const limite = this.limEssai?.find((x) => x.libelleEltCaract == caractRes?.value?.value);
									fckRef = limite ? limite.limiteInferieur : 0;
								} else {
									fckRef = lines[l].famille ? lines[l].famille.fckBetonReference : fck;
								}
								const conformite = await this.checkConformite(
									this.resultat[this.produitId].statsBeton[stat],
									line.stats[stat].value,
									values,
									this.getPrelevementControle(line, ess.controle),
									ecartTypeAdopte,
									line.fck,
									fckRef,
									nbValStat.toString(),
									iArrondi,
									ess.id,
									ess.code
								);
								line.stats[stat].conformite = conformite;
								line.stats[stat].groupementIndex = this.resultat[this.produitId].statsBeton[stat].groupementIndex;
								linesArray.push(line);
							}
							this.resultat[this.produitId].lignes = linesArray; //JN: 1355 trigger the vanilla watcher of tabresume
						}
					}
				}
			}
		}

		/*if (this.famille) {
            if (values.length > 0) {
                this.famille.currentETA = await this.interpreteur.calculeFormuleWithParams('@ECT(' + values.join(';') + ')');
                this.famille.currentETA = this.famille.currelintETA.toFixed(2);
                this.famille.nbValues = values.length;
            } else {
                this.famille.currentETA = '/';
                this.famille.nbValues = 0;
            }
        }*/
		if (this.resultat[this.produitId]) {
			delete this.resultat[this.produitId].isTreated;
		}
	}

	arrondiValeur(valeur, arrondi) {
		const objetToRound = [{ key: valeur, val: valeur, rounder: arrondi }];
		return this.Interpreteur.getRoundedValue(objetToRound);
	}

	getPrelevementControle(prelevement, controle) {
		const prelevementControle = angular.copy(controle) || {};
		if (prelevement) {
			let caract = null;
			if (prelevement.caractProduitCom) {
				caract = prelevement.caractProduitCom.find(function (e) {
					return prelevementControle.seuils && prelevementControle.seuils[0] && e.id === prelevementControle.seuils[0].idCaracteristique;
				});
			} else {
				caract = prelevement.caractProduit.find(function (e) {
					return prelevementControle.seuils && prelevementControle.seuils[0] && e.id === prelevementControle.seuils[0].idCaracteristique;
				});
			}
			if (caract) {
				prelevementControle.seuil = prelevementControle.seuils.find(function (e) {
					return e.codeEltCaract === caract.value.key;
				});
			}
			delete prelevementControle.seuils;

			return prelevementControle;
		}
	}

	async checkConformite(stat, valeur, allValues, controle, ecartTypeAdopte, fck, fckref, nbValStat, iArrondi, idEssai, codeEssai) {
		if (stat.code == 'MOYTR') {
		}

		const result = { isConforme: true, isAlerte: false, isEmpty: true, limInf: 0, limSup: 0, alrtInf: 0, alrtSup: 0 };
		delete stat.hide;
		if (controle && controle.specs && controle.specs.length > 0) {
			const _this = this;
			const controleSpecs = controle.specs.filter(function (spec) {
				return (
					spec.idCalcStat === stat.idCalcStat &&
					spec.idEssai === idEssai &&
					spec.codeSousEssai === codeEssai &&
					(!spec.codeGamme ||
						(spec.codeGamme.startsWith('GAMME_FAMI') && _this.type === 'famille') ||
						(spec.codeGamme.startsWith('GAMME_BÉTO') && _this.type !== 'famille'))
				);
			});
			let controleSpec = undefined;
			if (controleSpecs && controleSpecs.length > 0) {
				//vérifier les conditions
				let iSpec = 0;
				while (iSpec < controleSpecs.length && !controleSpec) {
					let condSpec = controleSpecs[iSpec].condition;
					if (!condSpec) {
						controleSpec = controleSpecs[iSpec];
					} else {
						condSpec = condSpec.replace(/@NBR\(\)/g, nbValStat.toString());
						condSpec = condSpec.replace(/@NBR/g, nbValStat.toString());
						const resCondSpec = await this.Interpreteur.calculeFormule(condSpec);

						if (resCondSpec) {
							controleSpec = controleSpecs[iSpec];
						}
					}
					iSpec++;
				}
			}

			const val = parseFloat(valeur);
			if (val >= 0 && controleSpec) {
				result.controleSpec = controleSpec;
				const fAlrtInf = await this.getBorne(
					controleSpec.alerteInferieure,
					allValues,
					controle.seuil,
					ecartTypeAdopte,
					fck,
					fckref,
					iArrondi
				);
				const fAlrtSup = await this.getBorne(
					controleSpec.alerteSuperieure,
					allValues,
					controle.seuil,
					ecartTypeAdopte,
					fck,
					fckref,
					iArrondi
				);
				const fLimInf = await this.getBorne(
					controleSpec.limiteBetonInferieure,
					allValues,
					controle.seuil,
					ecartTypeAdopte,
					fck,
					fckref,
					iArrondi
				);
				const fLimSup = await this.getBorne(
					controleSpec.limiteBetonSuperieure,
					allValues,
					controle.seuil,
					ecartTypeAdopte,
					fck,
					fckref,
					iArrondi
				);
				if (fLimInf >= 0) {
					result.isConforme = fLimInf <= val;
				}

				if (result.isConforme && fLimSup >= fLimInf) {
					result.isConforme = fLimSup >= val;
				}

				if (result.isConforme && !result.isAlerte && fAlrtInf > 0 && fLimInf >= 0) {
					result.isAlerte = fAlrtInf >= val && fLimInf <= val;
				}
				if (result.isConforme && !result.isAlerte && fAlrtSup > fAlrtInf && fLimSup >= fLimInf) {
					result.isAlerte = fAlrtSup <= val && fLimSup >= val;
				}

				result.limInf = Number(fLimInf);
				result.limSup = fLimSup || fLimSup >= fLimInf ? Number(fLimSup) : '';
				result.alrtInf = Number(fAlrtInf);
				result.alrtSup = fAlrtSup || fAlrtSup >= fAlrtInf ? Number(fAlrtSup) : '';

				result.isEmpty = false;
			} else {
				result.limInf = null;
				result.limSup = null;
				result.alrtInf = null;
				result.alrtSup = null;
			}
		}

		if (result.isEmpty) {
			stat.hide = true;
		}

		return result;
	}

	async getBorne(value, values, seuil, ecartTypeAdopte, fck, fckref, iArrondi) {
		let bNonNumerique = false;
		//SP 22/06/20 : vérifie en + qu'il y a du texte dans value, car 'parseFloat(0.77*ETA)' renvoie 0.77 !
		if (
			value &&
			(value.indexOf('VCMIN') > 0 ||
				value.indexOf('VCMAX') > 0 ||
				value.indexOf('ETA') > 0 ||
				value.indexOf('RCTHEO') > 0 ||
				value.indexOf('RCREF') > 0)
		) {
			bNonNumerique = true;
		}
		let dbValue = parseFloat(value);
		if (bNonNumerique || (!dbValue && value)) {
			value = value.replace(/VCMIN/g, (seuil && seuil.limiteInferieur ? seuil.limiteInferieur : 0).toString());
			value = value.replace(/VCMAX/g, (seuil && seuil.limiteSuperieur ? seuil.limiteSuperieur : 0).toString());
			value = value.replace(/ETA/g, (ecartTypeAdopte ? ecartTypeAdopte : 0).toString());
			value = value.replace(/RCTHEO/g, (fck ? fck : 0).toString());
			value = value.replace(/RCREF/g, (fckref ? fckref : 0).toString());

			if (value.indexOf('@') >= 0) {
				const formule = value.trim();
				this.Interpreteur.listeValeursStr = values.join(';');
				const idbValue = await this.Interpreteur.calculeFormule(formule);
				value = value.replace(formule, idbValue);
			}
			dbValue = await this.Interpreteur.calculeFormuleWithParams(value);
			dbValue = this.arrondiValeur(dbValue, iArrondi);
		}

		return dbValue || 0;
	}

	async deleteProduit(produit) {
		if (produit) {
			const pIndex = this.resultat.produits.findIndex(function (e) {
				return e.id === produit.id;
			});
			this.resultat.produits.splice(pIndex, 1);
			delete this.resultat[produit.id];

			// await this.prepareConformitesData();
			// await this.prepareGraphData();
		}
	}

	goProduit(produit) {
		this.$state.go('produits.edit', { id: produit.id });
	}

	async exclurePrelevement(prelevement, produit) {
		if (prelevement) {
			prelevement.excluded = true;
			const ligne = this.lignes.find(function (e) {
				return e.idPrelevement == prelevement.idPrelevement;
			});
			if (ligne) {
				ligne.excluded = true;
			}

			const modelLigne = this.resultat.lignes.find(function (e) {
				return e.idPrelevement == prelevement.idPrelevement;
			});
			if (modelLigne) {
				modelLigne.excluded = true;
			}

			// if (!produit)
			//     produit = this.resultat.produits.find(function (e) { return e.id === prelevement.idProduit; });
			if (produit) {
				produit.idsPrelevement.splice(produit.idsPrelevement.indexOf(prelevement.idPrelevement), 1);
			}

			await this.formateSynthese(produit, this.currentSide === 1 ? { isExclus: true } : null);

			if (this.onUpdate) {
				const id = this.mainEssai.idEssai;
				const code = this.mainEssai.codeEssai;
				const lib = this.mainEssai.libelleEssai;
				this.onUpdate({
					idEssai: id,
					codeEssai: code,
					libelleEssai: lib
				});
			}
			//pour mise à jour la liste des prélèvements quand on exclure un ou plusieurs PVs entre les onglets(Famille/Béton)
			if (this.onExclure) {
				const idPrelevementExclure = prelevement.idPrelevement;
				this.onExclure({ idPrelevementExclure: idPrelevementExclure });
			}
		}
	}

	async changeETA() {
		await this.formateSynthese(this.produit, null);
	}

	async inclurePrelevement(prelevement, produit) {
		if (prelevement) {
			delete prelevement.excluded;
			const ligne = this.lignes.find(function (e) {
				return e.idPrelevement == prelevement.idPrelevement;
			});
			if (ligne) {
				delete ligne.excluded;
			}

			const modelLigne = this.resultat.lignes.find(function (e) {
				return e.idPrelevement == prelevement.idPrelevement;
			});
			if (modelLigne) {
				delete modelLigne.excluded;
			}

			// if (!produit)
			//     produit = this.resultat.produits.find(function (e) { return e.id === prelevement.idProduit; });
			if (produit) {
				produit.idsPrelevement.push(prelevement.idPrelevement);
			}
			await this.formateSynthese(produit, this.currentSide === 1 ? { isExclus: true } : null);
			// await this.prepareConformitesData();
			// await this.prepareGraphData();
			// if (this.currentStep === 0) //Etape 1
			//     this.formateSyntheseStep1(produit, this.currentSide === 1 ? { isExclus: true } : null);
			// else
			//     this.formateSyntheseStep2(produit, this.currentSide === 1 ? { isExclus: true } : null);
			if (this.onUpdate) {
				const id = this.mainEssai.idEssai;
				const code = this.mainEssai.codeEssai;
				const lib = this.mainEssai.libelleEssai;
				this.onUpdate({
					idEssai: id,
					codeEssai: code,
					libelleEssai: lib
				});
			}
			//pour mise à jour la liste des prélèvements quand on inclure un ou plusieurs PVs entre les onglets(Famille/Béton)
			if (this.onInclure) {
				const idPrelevementInclure = prelevement.idPrelevement;
				this.onInclure({ idPrelevementInclure: idPrelevementInclure });
			}

			// Mise à jour Produit
			this.produit = angular.copy(produit);
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	goPrelevement(prelevement) {
		this.$state.go('prelevements.edit-saisie', { id: prelevement.idPrelevement, idFamille: prelevement.idFamille });
	}

	getObjectFilter(i) {
		let txtJSON = JSON.stringify({ dynamicPattern: true, pattern: 'valeursAffichage[' + i + '].valeur' });
		while (txtJSON.indexOf('"') >= 0) {
			txtJSON = txtJSON.replace('"', '$');
		}

		//console.log(txtJSON);

		return txtJSON;
	}

	async openPrelevement(lig) {
		const _this = this;
		_this.id = lig.idPrelevement;
		_this.idFamille = lig.idFamille;
		_this.codeDomaine = lig.codeDomaine;
		this.$uibModal
			.open({
				template:
					'<prelevement modal-instance="$ctrl.uibModalInstance" id="$ctrl.id" id-famille="$ctrl.idFamille" code-domaine="$ctrl.codeDomaine"></prelevement>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						//$ctrl.objSynthese = _this.objSynthese;
						$ctrl.uibModalInstance = $uibModalInstance;
						$ctrl.id = _this.id;
						$ctrl.idFamille = _this.idFamille;
						$ctrl.codeDomaine = _this.codeDomaine;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl',
				backdrop: false
			})
			.result.then(
				async function (result) {
					if (result) {
						//await _this.regenererSynthese();
						//_this.$state.go('syntheseentities.result', { obj: _this.model });
						if (_this.onReinit) {
							_this.onReinit();
						}
					}
				},
				function (reason) {}
			);
	}

	checkIfNoResistanceViseePourRegleRvref() {
		return this.resultat.familles[0].regleTranspoFormuleRefName.includes('RVREF');
	}

	checkIfNoResistanceEstimeePourRegleReref() {
		return this.resultat.familles[0].regleTranspoFormuleRefName.includes('REREF');
	}
}
