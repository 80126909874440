export default class CarnetPaiementController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'ModalService',
        'CarnetsService',
        'notification',
        '$translate',
        '$filter',
        '_',
        '$uibModal'
    ];

    constructor($scope, $state, $stateParams, ModalService, CarnetsService, notification, $translate, $filter, _, $uibModal) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ModalService = ModalService;
        this.CarnetsService = CarnetsService;
        this.notification = notification;
        this.$translate = $translate;
        this.$filter = $filter;
        this.$uibModal = $uibModal;
    }

    $onInit() {
        this.startLoading();

        this.getListeModesPaiement();

        this.stopLoading();
    }

    async getListeModesPaiement() {
        if (!this.listeModesPaiement) {
            this.startLoading();
            try {
                this.listeModesPaiement = await this.CarnetsService.getModesPaiement();
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            } finally {
                this.stopLoading();
            }
        }
    }

    cancel() {
        this.modalInstance.dismiss();
    }

    confirmer() {
        if (this.idModePaiement) {
            this.modalInstance.close(this.idModePaiement);
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
