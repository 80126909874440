(function () {
    'use strict';

    angular.module('blocks.helpers').service('PromiseHelpers', PromiseHelpers);

    PromiseHelpers.$inject = ['$http', '$q'];

    function PromiseHelpers($http, $q) {
        this.returnGetPromise = function (url) {
            var deferred = $q.defer();

            $http.get(url)
                .then(function (config) {
                    deferred.resolve(config.data);
                })
                .catch(function (config) {
                    deferred.reject(config.data);
                });

            return deferred.promise;
        };
    }
})();
