import ProduitNorme from './produit.norme.model';
/* @ngInject */
export default class ProduitGenerationNormeController {
    constructor(
        _,
        $scope,
        $timeout,
        $state,
        $stateParams,
        $translate,
        ProduitsCommunicationService,
        ProduitsService,
        notification,
        $location,
        $anchorScroll,
        CodeAutomatiqueService,
        MassiaApplicationService,
        RouterHistoryService,
        ModalService,
        $uibModal
    ) {
        this._ = _;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.ProduitsCommunicationService = ProduitsCommunicationService;
        this.ProduitsService = ProduitsService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.CodeAutomatiqueService = CodeAutomatiqueService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.RouterHistoryService = RouterHistoryService;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        try {
            this.firstLaunchCopy = false;
            // Propriétés
            this.produit = {};
            this.loading = false;
            this.calcul = 0;
            this.form = {
                entete: {
                    codeChecked: false,
                    codeGenere: '',
                    libelle: '',
                    appelation: ''
                },
                caracteristiques: {}
            };
            this.libelleGenere = '';
            this.appelationGenere = '';

            if (!this.code) {
                this.code = await this.checkCodeAuto();
                // console.log(this.code);
            }

            this.ongletOpen = {};
            // Configuration
            await this.reset();

            this.application = this.MassiaApplicationService.getApplication();
            if (this.$stateParams.id) {
                this.firstLaunchCopy = true;
            }
            /* console.log(angular.copy(this.produit)); */
            if (this.produit && this.produit.entete && this.produit.entete.idSite) {
                this.producteurOrigin = this.produit.entete.idSite;
            }
        } catch (error) {
            console.error(error);
        }
    }

    $onDestroy() {
        this.$timeout.cancel(this.updateProduitTimeout);
    }

    async reset() {
        try {
            this.startLoading();

            const data = {
                id: this.$stateParams.id
            };
            if (data?.id && data.id > 0) {
                //duplication d'un produit génerer
                data.entete = await this.ProduitsService.getProduitEnteteById(data.id);
                data.entete.id = 0;
                data.entete.code = null;
                data.entete.libelle = null;
                if (data.entete?.sites && data.entete.sites.length === 1) {
                    data.entete.idSite = data.entete.sitesIds[0];
                } else {
                    data.entete.sites = [];
                    data.entete.sitesIds = [];
                }
                data.caracteristiques = await this.ProduitsService.getProduitValeursCaracteristiquesAssociationsById(data.id);
            }
            this.produit = new ProduitNorme(data);
            this.produit.id = this.$stateParams && this.$stateParams.produit ? this.$stateParams.produit.id : 0;

            this.ongletOpen = {
                isEnteteOpen: true,
                isCaracteristiqueOpen: true
            };

            this.$timeout.cancel(this.updateProduitTimeout);
            this.updateProduitTimeout = this.$timeout(() => this.stopLoading());
        } catch (error) {
            console.error(error);
        }
    }

    async checkCodeAuto() {
        try {
            const code = await this.CodeAutomatiqueService.getCodeAutomatique(this.MassiaApplicationService.getApplication());
            const res = code.find((x) => x.domaineCode === 'Produits');

            return res;
        } catch (err) {
            console.error(err);
        }
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();

            try {
                if (
                    this.application === 'gestion' &&
                    this.produit &&
                    this.produit.entete &&
                    this.produit.entete.granulometrie > 0 &&
                    (!this.produit.entete.idGrandD || !this.produit.entete.idPetitd)
                ) {
                    await this.modalVerificationdetD();
                }

                if (this.application === 'laboratoire' && __configuration.licenceLaboratoire === '2' && this.$stateParams.produit) {
                    await this.openModalMd(this.$stateParams.produit, this.produit);
                } else {
                    let id = false;
                    id = await this.ProduitsService.generateProduitNorme(this.produit, this.MassiaApplicationService.getApplication());
                    this.notification.success('PRODUITS.GENERATED');
                    return id;
                }
            } catch (ex) {
                this.notification.error(ex.data);

                if (ex.data === this.$translate.instant('PRODUITS.GENERATION_NORME.PRODUIT_LIBELLE_EXISTANT')) {
                    await this.modalProduitEdit();
                }

                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async appliquer() {
        const id = await this.sauvegarder();
        if (id) {
            this.reset();
            this.RouterHistoryService.ignoreNextRoute();
            this.$state.go('produits.edit', { id: id });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    async annuler() {
        if (!this.RouterHistoryService.back()) {
            this.$state.go('produits.list');
        }
    }

    checkValidity() {
        let validity = true;

        //on va seulement scroller et focus sur le premier onglet où il y a une erreur
        let firstScroll = true;
        if (!this.produit.entete.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('entete');
                firstScroll = false;
            }

            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.$scope.$broadcast('produitEnteteNormeValidations');
        } else {
            validity = true;
        }
        if (!this.produit.caracteristiques.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('caracteristiques');
                firstScroll = false;
            }

            this.ongletOpen.isCaracteristiqueOpen = true;
            validity = false;
            this.$scope.$broadcast('produitCaracteristiquesValidations');
        }

        if (this.produit.siteLies && this.produit.siteLies.length > 0) {
            let isValid = true;
            for (let i = this.produit.siteLies.length - 1; i >= 0; i--) {
                const detail = this.produit.siteLies[i];
                if (detail) {
                    if (!detail.producteurId || !detail.detailClient) {
                        isValid = false;
                        break;
                    }

                    const producteurExist = this.produit.entete.sitesIds.find((e) => e === detail.producteurId);
                    if (!producteurExist) {
                        this.produit.siteLies.splice(i, 1);
                    }
                }
            }
            if (!isValid) {
                validity = false;
                this.notification.warning('PRODUITS.SITES_LIES_INCOMPLET');
            }
        }

        return validity;
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    async onUpdateEntete(entete) {
        if (entete && this.produit && this.produit.entete) {
            const refresh =
                this.produit.entete.idGrandD != entete.idGrandD ||
                this.produit.entete.idPetitd != entete.idPetitd ||
                this.produit.entete.idSite != entete.idSite ||
                this.firstLaunchCopy;

            this.produit.entete = entete;

            if (this.produit.entete.typeId && refresh) {
                this.calculIdentification();
                this.firstLaunchCopy = false;
            }
        }
    }

    async onUpdateCaracteristiques(caracteristiques) {
        this.produit.caracteristiques = caracteristiques;
        this.calculIdentification();
    }

    async calculIdentification() {
        this.calcul = this.calcul + 1;
        const identifiant = await this.ProduitsService.calculeIdentifiantNorme(this.produit);

        if (this.code && this.code.estCodeAutomatique && identifiant && !identifiant.code) {
            this.form.entete.codeGenere = 'auto_tmp';
        } else if (this.produit.entete.code && identifiant.code === '') {
            this.form.entete.codeGenere = this.produit.entete.code;
        } else {
            this.form.entete.codeGenere = identifiant.code;
        }

        this.libelleGenere = identifiant.libelle;
        this.produit.entete.libelle = this.libelleGenere;
        this.appelationGenere = identifiant.appelation;
        this.calcul = this.calcul - 1;

        if (this.form.entete.codeGenere && identifiant.code !== '') {
            this.form.entete.codeChecked = true;
        } else {
            this.form.entete.codeChecked = false;
        }

        if (!this.form.entete.libelle || this.form.entete.libelle != this.libelleGenere) {
            this.form.entete.libelle = this.libelleGenere;
        }

        if (!this.form.entete.appelation || this.form.entete.appelation != this.appelationGenere) {
            this.form.entete.appelation = this.appelationGenere;
        }
    }

    async modalVerificationdetD() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('PRODUITS.GENERATION_NORME.TITLE'),
            modalMsg: this.$translate.instant('PRODUITS.GENERATION_NORME.MSG'),
            headerClass: 'modal-warning'
        });
        try {
            await modalInstance.result;
        } catch (ex) {
            console.error(ex.data);
        }
    }

    async modalProduitEdit() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('PRODUITS.GENERATION_NORME.PRODUIT_LIBELLE_EXISTANT'),
            modalMsg: this.$translate.instant('PRODUITS.GENERATION_NORME.MSG_PRODUIT_LIBELLE_EXISTANT'),
            headerClass: 'modal-info'
        });
        try {
            await modalInstance.result;
            const id = await this.ProduitsService.getProduitIdByLibelle(this.libelleGenere);

            if (id) {
                this.$state.go('produits.edit', { id: id.id });
            }
        } catch (ex) {
            console.error(ex.data);
        }
    }

    onUpdateNiveaux(niveaux) {
        this.produit.niveaux = niveaux;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    async openModalMd(produit, newProduit) {
        const modal = this.$uibModal.open({
            component: 'produitCopyModalMixDesign',
            animation: true,
            size: 'md',
            resolve: {
                produit: () => produit,
                idProducteur: () => this.produit.entete.idSite,
                newProduit: () => newProduit,
                producteurOrigin: () => this.producteurOrigin
            }
        });

        modal.result
            .then((res) => {})
            .catch((err) => {
                console.error(err);
                // Modal dismissed.
            });
    }
}
