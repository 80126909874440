import PaysConfig from './_config/pays.config';
import PaysRoutes from './_config/pays.routes';
import PaysComponent from './components/pays';
import PaysService from './services/pays.service';
import PaysCommunicationService from './services/pays.communication.service';

import PaysFormComponent from './components/pays-form';
import PaysFormEnteteComponent from './components/pays-form/entete';

import PaysValidator from './components/pays-form/pays.validator';
import PaysEnteteValidator from './components/pays-form/entete/pays.entete.validator';

const moduleName = 'app.massia.common.pays';

angular
    .module(moduleName, [])
    .config(PaysConfig)
    .config(PaysRoutes)
    .service('PaysService', PaysService)
    .service('PaysCommunicationService', PaysCommunicationService)
    .component('pays', PaysComponent)
    .component('paysForm', PaysFormComponent)
    .component('paysFormEntete', PaysFormEnteteComponent)
    .factory('PaysValidator', PaysValidator)
    .factory('PaysEnteteValidator', PaysEnteteValidator);

export default moduleName;
