(function (angular, undefined) {
    'use strict';

    angular.module('blocks.datepicker').run(run);
    run.$inject = ['$templateCache'];

    function run($templateCache) {
        $templateCache.put(
            'ac-datepicker.tpl.html',
            '<p class="input-group">' +
                '	<input type="{{acDatepickerCtrl.config.type}}" ' +
                '      	   id="{{acDatepickerCtrl.id}}"' +
                '      	   name="{{acDatepickerCtrl.name}}"' +
                '      	   placeholder="{{acDatepickerCtrl.config.placeholder}}"' +
                '          class="form-control" style="text-align:right"' +
                '	       uib-datepicker-popup="{{acDatepickerCtrl.config.format}}"' +
                '	       ng-model="acDatepickerCtrl.inputModel"' +
                "	       ng-model-options=\"{ updateOn: 'default blur', debounce: { 'default': 250, 'blur': 0 }, allowInvalid: true }\"" +
                '	       ac-expose-ng-model-ctrl="[acDatepickerCtrl, acDatepickerCtrl.inputModelCtrlName]"' +
                '          ng-readonly="acDatepickerCtrl.inputIsDisabled()"' +
                '	       is-open="acDatepickerCtrl.datepickerOpened"' +
                '	       datepicker-options="acDatepickerCtrl.config.datepickerOptions"' +
                '          datepicker-popup-template-url="{{acDatepickerCtrl.config.datepickerPopupTemplateUrl}}"' +
                '          datepicker-template-url="{{acDatepickerCtrl.config.datepickerTemplateUrl}}"' +
                '          popup-placement="{{acDatepickerCtrl.config.popupPlacement}}"' +
                '          clear-text="{{acDatepickerCtrl.config.clearText | translate}}"' +
                '          close-text="{{acDatepickerCtrl.config.closeText | translate}}"' +
                '          close-on-date-selection="{{acDatepickerCtrl.config.closeOnDateSelection}}"' +
                '          current-text="{{acDatepickerCtrl.config.currentText | translate}}"' +
                '          datepicker-append-to-body="{{acDatepickerCtrl.config.datepickerAppendToBody}}"' +
                '          on-open-focus="{{acDatepickerCtrl.config.onpOpenFocus}}"' +
                '          show-button-bar="{{acDatepickerCtrl.config.showButtonBar}}"' +
                '          ac-autofocus="{{acDatepickerCtrl.acAutofocus || false}}" ' +
                '          ng-blur="onBlur($event)"/> ' +
                '' +
                '	<span class="input-group-btn">' +
                '		<button type="button" class="btn btn-calendar"' +
                '		        ng-click="acDatepickerCtrl.openPopup()"' +
                '               ng-disabled="acDatepickerCtrl.acDatepickerDisabled">' +
                '			<span class="glyphicon glyphicon-calendar"></span>' +
                '		</button>' +
                '	</span>' +
                '</p>' +
                ''
        );

        $templateCache.put(
            'ac-datepicker.popup.html',
            '<ul class="uib-datepicker-popup dropdown-menu uib-position-measure" ' +
                '    dropdown-nested ng-if="isOpen" ng-keydown="keydown($event)" ng-click="$event.stopPropagation()">' +
                '  <li ng-transclude></li>' +
                '  <li ng-if="showButtonBar" class="uib-button-bar">' +
                '    <span class="btn-group pull-left">' +
                '      <button ng-hide="datepickerOptions.config.acDisableTodayButton" type="button" class="btn btn-sm btn-info uib-datepicker-current" ng-click="select(\'today\', $event)" ng-disabled="isDisabled(\'today\')">{{ getText(\'current\') }}</button>' +
                '      <button ng-hide="datepickerOptions.config.acDisableClearButton" type="button" class="btn btn-sm btn-danger uib-clear" ng-click="select(null, $event)">{{ getText(\'clear\') }}</button>' +
                '    </span>' +
                '    <button type="button" class="btn btn-sm btn-success pull-right uib-close" ng-click="close($event)">{{ getText(\'close\') }}</button>' +
                '  </li>' +
                '</ul>' +
                ''
        );
    }
})(angular);
