export default function Configure($stateProvider) {
    $stateProvider.state('remises-fin-annee', {
        parent: 'gestion',
        url: '/remises-fin-annee',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
        }
    });

    $stateProvider.state('remises-fin-annee.list', {
        url: '/list',
        template: '<remises-fin-annee></remises-fin-annee>',
        rights: { domain: 'rfa', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "REMISES_FIN_ANNEE.BREADCRUMBS.REMISES_FIN_ANNEE_LIST" | translate}}'
        },
        // on affiche la liste des rfa dans la navigation
        navigation: {
            menu: 'facturation',
            translate: 'REMISES_FIN_ANNEE.BREADCRUMBS.REMISES_FIN_ANNEE_LIST',
            order: 14
        },
        // la liste des rfa fait partie des points d'entrée recherchables dans la navigation
        search: 'REMISES_FIN_ANNEE.BREADCRUMBS.REMISES_FIN_ANNEE_LIST'
    });

    $stateProvider.state('remises-fin-annee.list.detail', {
        url: '/{id}',
        template: '<remise-fin-annee-detail></remise-fin-annee-detail>',
        rights: { domain: 'rfa', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher rfa > détail
            parent: 'remises-fin-annee.list',
            label: '{{ "REMISES_FIN_ANNEE.BREADCRUMBS.REMISE_FIN_ANNEE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('remises-fin-annee.new', {
        url: '/new',
        template: '<remise-fin-annee-form></remise-fin-annee-form>',
        rights: { domain: 'rfa', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher rfa > ajouter
            parent: 'remises-fin-annee.list',
            label: '{{ "REMISES_FIN_ANNEE.BREADCRUMBS.REMISE_FIN_ANNEE_NEW" | translate}}'
        },
        // l'ajout d'une rfa fait partie des points d'entrée recherchables dans l'application
        search: 'REMISES_FIN_ANNEE.BREADCRUMBS.REMISE_FIN_ANNEE_NEW'
    });

    $stateProvider.state('remises-fin-annee.edit', {
        url: '/{id}/edit',
        template: '<remise-fin-annee-form></remise-fin-annee-form>',
        rights: { domain: 'rfa', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher rfa > modifier
            parent: 'remises-fin-annee.list',
            label: '{{ "REMISES_FIN_ANNEE.BREADCRUMBS.REMISE_FIN_ANNEE_EDIT" | translate }}'
        }
    });

    $stateProvider.state('remises-fin-annee.generation', {
        url: '/resultat',
        params: {
            obj: null
        },
        template: '<remise-fin-annee-generation></remise-fin-annee-generation>',
        rights: { domain: 'rfa', right: 'buildsynthesis' },
        ncyBreadcrumb: {
            parent: 'remises-fin-annee.list',
            label: '{{ "REMISES_FIN_ANNEE.BREADCRUMBS.REMISE_FIN_ANNEE_RESULTAT_GENERATION" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
