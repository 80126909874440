import validatorBuilder from 'validator-builder';

let profilValidator = null;

export default class Profil {
    constructor(data) {
        data = data || {};
        this.id = data.id || undefined;
        this.libelle = data.libelle || '';
        this.niveauxAccesDomaines = data.niveauxAccesDomaines || [];
    }

    isValid() {
        profilValidator = profilValidator || validatorBuilder.getInstanceFor('Profil');
        const validationResults = profilValidator.validate(this);
        return validationResults.isValid;
    }
}
