import FormuleLaboCreationController from './formule.labo.creation.controller';

export default {
    bindings: {
        formule: '=',
        percentChange: '&',
        quantityChange: '&',
        onCoefSelected: '<',
        famille: '<',
        onVerifRemiseCube: '&',
        onRemiseCube: '&',
        resolve: '='
    },
    controller: FormuleLaboCreationController,
    template: require('./formule.labo.creation.html')
};
