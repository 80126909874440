const serviceUrl = `${__configuration.apiUrl}/massia/mouvements-stock`;

export default class StocksCloturesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getSitesCommerciaux(valeur) {
        const url = `${__configuration.apiUrl}/massia/sites-commerciaux/typeAhead/${valeur}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async clotureJournaliere(siteId, date) {
        const data = {
            date: date,
            idSiteCommercial: siteId
        };
        const url = `${serviceUrl}/cloture-jour`;
        const res = await this.$http.put(url, data);
        return res.data;
    }

    async preclotureMensuelle(siteId, date, dateStockFinal) {
        const data = {
            idSiteCommercial: siteId,
            estPreCloture: true,
            date: date,
            dateStockFinal: dateStockFinal
        };
        const url = `${serviceUrl}/cloture-mois`;
        const res = await this.$http.put(url, data);
        return res.data;
    }

    async clotureMensuelle(siteId, date, dateStockFinal, idPreCloture) {
        const data = {
            idSiteCommercial: siteId,
            estPreCloture: false,
            date: date,
            dateStockFinal: dateStockFinal,
            idPreCloture: idPreCloture
        };
        const url = `${serviceUrl}/cloture-mois`;
        const res = await this.$http.put(url, data);
        return res.data;
    }

    async getDerniereCloture(idSite) {
        const url = `${serviceUrl}/derniere-cloture-jour/${idSite}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async estCloture(siteId, date) {
        const data = {
            date: JSON.stringify(date),
            idSite: JSON.stringify(siteId)
        };
        const url = `${serviceUrl}/est-jour-cloture`;
        const res = await this.$http.get(url, {
            params: data
        });
        return res.data;
    }

    async getClotureJour(siteId, date) {
        const data = {
            date: JSON.stringify(date),
            idSite: JSON.stringify(siteId)
        };
        const url = `${serviceUrl}/get-cloture-jour`;
        const res = await this.$http.get(url, {
            params: data
        });
        return res.data;
    }

    async decloturer(siteId, date) {
        const data = {
            date: date,
            idSiteCommercial: siteId
        };
        const url = `${serviceUrl}/decloture-jour`;
        const res = await this.$http.put(url, data);
        return res.data;
    }

    async getPeriodeEnCoursCloture(idSite) {
        const url = `${serviceUrl}/get-periode-cloture/${idSite}`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
