import validatorBuilder from 'validator-builder';

let factureEnteteValidator = null;

export default class FactureEntete {
    constructor(data) {
        data = data || {};
        this.idFacture = data.idFacture || null;
        this.code = data.code || null;
        this.idSociete = data.idSociete || null;
        this.nomSociete = data.nomSociete || null;
        this.deviseSymbole = data.deviseSymbole || null;
        this.idPays = data.idPays || null;

        // SL pour l'état de la facture, soit on est en édition et donc on récupère celle en bdd
        // soit on est en création, et donc on prend la valeur par défaut : Modifiable
        this.idEtatFacture = data.idEtatFacture || 0;
        this.etatFacture = data.etatFacture || 'Modifiable';

        this.idTypeFacture = data.idTypeFacture;
        this.typeFacture = data.typeFacture;
        this.dateGeneration = data.dateGeneration || null;
        this.dateRevisionPrix = data.dateRevisionPrix || null;
        this.dateEcheance = data.dateEcheance || null;
        this.isComplet = data.isComplet;
        this.identReglement = data.identReglement || null;
        this.acompte = data.acompte || null;
        this.idSiteCommercial = data.idSiteCommercial || null;
        this.nomSiteCommercial = data.nomSiteCommercial || null;
        this.idClient = data.idClient || null;
        this.nomClient = data.nomClient || null;
        this.isClientGenerique = data.isClientGenerique;
        this.idClientDivers = data.idClientDivers || null;
        this.nomClientDivers = data.nomClientDivers || null;
        this.idChantier = data.idChantier || null;
        this.nomChantier = data.nomChantier || null;
        this.idModePaiement = data.idModePaiement || null;
        this.libelleModePaiement = data.libelleModePaiement || null;
        this.idPresentation = data.idPresentation || null;
        this.libellePresentation = data.libellePresentation || null;
        this.isModifiable = data.isModifiable;
        this.isSiteModifiable = data.isSiteModifiable;
        this.remarque = data.remarque || null;
        this.commentaire = data.commentaire || null;

        this.idPieceFacturationLiee = data.idPieceFacturationLiee || null;
        this.avoirsLies = data.avoirsLies || null;
        this.factureOriginale = data.factureOriginale || null;
        this.refacturationsLies = data.refacturationsLies || null;

        this.factureProduits = data.factureProduits || [];
        this.facturePrestations = data.facturePrestations || [];
        this.factureArticles = data.factureArticles || [];

        this.listeArticlesByBlock = data.listeArticlesByBlock || [];
        this.natureFacture = data.natureFacture || [];
        this.isFranco = data.isFranco;
        this.montantTTCFacture = data.montantTTCFacture;
    }

    isValid() {
        factureEnteteValidator = factureEnteteValidator || validatorBuilder.getInstanceFor('FactureEntete');
        const validationResults = factureEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
