const apiUrl = `${__configuration.apiUrl}/massia/distance-poids-co2`;

export default class SiteDistancePoidsCo2Service {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getAllDistancesPoidsCo2(idProducteur) {
        const res = await this.$http.get(`${apiUrl}/all/${idProducteur}`);
        return res.data;
    }

    async getAllDistancesPoidsCo2ByDate(idProducteur, date = null) {
        const url = `${apiUrl}/all-by-date/${idProducteur}`;
        const res = await this.$http.put(url, date);
        return res.data;
    }

    async updateDistancesPoidsCo2(data) {
        const res = await this.$http.put(apiUrl, data);
        return res.data;
    }
}