export default class PrelevementConfigImpressionController {
    constructor(
        $translate,
        $scope,
        $state,
        notification,
        PrelevementsConfigImpressionService,
        MesuresService,
        CaracteristiquesService,
        MassiaRightsService,
        _
    ) {
        (this.$translate = $translate),
            (this.$scope = $scope),
            (this.$state = $state),
            (this.notification = notification),
            (this.PrelevementsConfigImpressionService = PrelevementsConfigImpressionService),
            (this.MesuresService = MesuresService),
            (this.CaracteristiquesService = CaracteristiquesService);
        this.MassiaRightsService = MassiaRightsService;
        this._ = _;

        this.idSelected = 0;
        this.addConfiguration = false;
        this.certifEssai = false;
        this.istEvenIconProduit = false;
        this.istEvenIconEssai = false;
    }

    async $onInit() {
        this.certificationAccess = this.MassiaRightsService.userHasRight('certification', 'print', 'laboratoire');
        await this.reset();
        await this.onLoad();

        if (this.certificationAccess) {
            //vérifie si au moins un essai du prelèvement est certifié
            this.certifEssai = this.verifyEssaisCertifie();

            //si le produit est certifié charge les données sur la certif
            if (this.saisie.certifProduit > 0) {
                this.certificatProduit = await this.PrelevementsConfigImpressionService.getCertificatProduit(this.saisie.certifProduit);
            }

            //si un essai / le produit est certifié charge la liste des images pour le choix de l'icone
            if (this.certifEssai || this.saisie.certifProduit > 0) {
                const images = await this.PrelevementsConfigImpressionService.getImagesListe();
                this.imagesProduit = angular.copy(images);
                this.imagesEssai = angular.copy(images);
            }

            //sert a la config pour l'impression des infos du certificat du produit
            this.initCertifConfigValues();
        }
    }

    $onDestroy() {}

    async reset() {
        await this.chargementListesConfig();
        await this.chargementListesIsteven();
        await this.getCertificationList();
    }

    async chargementListesIsteven() {
        await this.etatList();
        await this.origineList();
    }

    async chargementListesConfig() {
        const listConfig = await this.PrelevementsConfigImpressionService.getAllConfiguration(this.saisie.id);
        if (listConfig) {
            this.listConfig = angular.copy(listConfig);
        }
        if (this.idSelected) {
            const idx = this.listConfig.findIndex((x) => x.id === this.idSelected);
            if (idx > -1) {
                this.listConfig[idx].selected = true;
            }
        }
    }

    initCertifConfigValues() {
        this.saisie.configImpression.produit = {
            icone: null,
            logo: null,
            numero: null,
            start: null,
            end: null,
            libelle: null
        };

        this.saisie.configImpression.essai = {
            icone: null,
            logo: false
        };
    }

    changeSwitchLibelleCarac(model) {
        this.saisie.configImpression.switch.caracteristiqueLibelle = model;
    }

    changeSwitchCodeCarac(model) {
        this.saisie.configImpression.switch.caracteristiqueCode = model;
    }

    changeSwitchCodeEssai(model) {
        this.saisie.configImpression.switch.essaisCode = model;
    }

    changeSwitchLibelleEssai(model) {
        this.saisie.configImpression.switch.essaisLibelle = model;
    }

    verifyEssaisCertifie() {
        let certifie = false;

        this.saisie.mesures.forEach((essai) => {
            if (essai.idEssaiCertifie !== null) {
                certifie = true;
            }
        });

        return certifie;
    }

    async onLoad() {
        if (this.idSelected === 0) {
            this.saisie.configImpression = { switch: {} };
            this.resetSwitch();
        }
        this.saisie.configImpression.etatSousEss = angular.copy(this.listEtatSousEss.filter((x) => x.selected === true));
        this.saisie.configImpression.etatEssai = angular.copy(this.listEtatEssai.filter((x) => x.selected === true));
        this.saisie.configImpression.etatCaracteristique = angular.copy(this.listEtatCaracteristique.filter((x) => x.selected === true));

        this.switchEtatEssai();
        this.switchEtatSousEssai();
        this.switchEtatCaracteristique();
        this.chargementListEssais();
        this.chargementListCaracteristique();
        this.resetCertificationSelection();
        this.selectCertificationConfigValues();
    }

    addConfig() {
        this.idSelected = 0;
        if (this.addConfiguration) {
            if (this.listConfig && this.listConfig.length > 0) {
                const idx = this.listConfig.findIndex((x) => x.selected);
                if (idx > -1) {
                    this.idSelected = this.listConfig[idx].id;
                }
                if (this.idSelected > 0) {
                    this.onLoad();
                }
            }
        } else {
            this.listConfig.map((x) => {
                return (x.selected = false);
            });
            this.saisie.configImpression.libelle = null;
            this.resetSwitch();
        }

        return (this.addConfiguration = !this.addConfiguration);
    }

    async resetSwitch() {
        this.saisie.configImpression.switch = {
            essaisPrelev: true,
            essaisRef: false,
            essaisChoisi: false,

            essaisAvecValeur: true,
            essaisSansValeur: true,
            essaisTermines: true,
            essaisNonTermines: true,

            essaiPrincipal: false,

            sousEssaisAvecValeur: true,
            sousEssaisSansValeur: true,

            essaisCode: true,
            essaisLibelle: true,

            caracteristiquePrelev: true,
            caracteristiqueChoisi: false,
            caracteristiqueAvecValeur: true,
            caracteristiqueSansValeur: true,

            caracteristiqueCode: true,
            caracteristiqueLibelle: true
        };
    }

    async origineList() {
        const listOrigineEssai = await this.PrelevementsConfigImpressionService.getListOrigineImpression();
        this.listOrigineEssai = [];
        this.listOrigineCaracteristique = [];
        for (let i = 0; i < listOrigineEssai.length; i++) {
            const el = listOrigineEssai[i];
            el.libelle = this.$translate.instant(el.translate);
            if (i === 0) {
                el.selected = true;
            }
            if (this.idSelected) {
                this.recuperationOrigineEssais(el);
            }
            this.listOrigineEssai.push(angular.copy(el));
            if (i !== 1) {
                if (this.idSelected) {
                    this.recuperationOrigineCaracteristique(el);
                }
                this.listOrigineCaracteristique.push(angular.copy(el));
            }
        }
    }

    async etatList() {
        const etatEssai = await this.PrelevementsConfigImpressionService.getEtatEssaiList();
        this.listEtatEssai = [];
        this.listEtatSousEss = [];
        this.listEtatCaracteristique = [];
        for (let i = 0; i < etatEssai.length; i++) {
            const el = etatEssai[i];
            el.libelle = this.$translate.instant(el.translate);
            el.selected = true;
            if (this.idSelected) {
                this.recuperationEtatEssai(el);
            }
            this.listEtatEssai.push(angular.copy(el));
            if (i < 2) {
                if (this.idSelected) {
                    this.recuperationEtatSsEssai(el);
                    this.listEtatSousEss.push(angular.copy(el));
                    this.recuperationEtatCaracteristique(el);
                    this.listEtatCaracteristique.push(angular.copy(el));
                } else {
                    this.listEtatSousEss.push(angular.copy(el));
                    this.listEtatCaracteristique.push(angular.copy(el));
                }
            }
        }
    }

    async getCertificationList() {
        //liste des valeurs à selectionner pour affichage sur le template (essai / produit)

        this.essaisOptions = await this.PrelevementsConfigImpressionService.getListCertificationEssaiOptions();
        this.essaisOptions.forEach((element) => {
            element.libelle = this.$translate.instant(element.translate);
        });

        this.produitOptions = await this.PrelevementsConfigImpressionService.getListCertificationProduitOptions();
        this.produitOptions.forEach((element) => {
            element.libelle = this.$translate.instant(element.translate);
        });
    }

    selectCertificationData(data, type) {
        if (type == 'produit') {
            this.selectProduitData(data);
        } else {
            this.selectEssaisData(data);
        }
    }

    selectProduitData(data) {
        switch (data.value) {
            case 'logo':
                this.saisie.configImpression.produit.logo = data.selected ? this.certificatProduit.logo : null;
                break;
            case 'dates':
                this.saisie.configImpression.produit.start = data.selected ? this.certificatProduit.start : null;
                this.saisie.configImpression.produit.end = data.selected ? this.certificatProduit.end : null;
                break;
            case 'numero':
                this.saisie.configImpression.produit.numero = data.selected ? this.certificatProduit.numero : null;
                break;
            case 'icone':
                if (data.selected === true) {
                    this.istEvenIconProduit = true;
                } else {
                    this.istEvenIconProduit = false;
                    this.saisie.configImpression.produit.icone = null;
                }
                break;
            case 'libelle':
                this.saisie.configImpression.produit.libelle = data.selected ? this.certificatProduit.libelleCG : null;
                break;
            default:
                break;
        }
    }

    selectEssaisData(data) {
        if (data.value == 'logo') {
            this.saisie.configImpression.essai.logo = data.selected;
        } else {
            if (data.selected === true) {
                this.saisie.configImpression.essai.logo = false;
                this.istEvenIconEssai = true;
            } else {
                this.saisie.configImpression.essai.icone = null;
                this.istEvenIconEssai = false;
            }
        }
    }

    selectIcone(data, type) {
        if (type == 'produit') {
            this.saisie.configImpression.produit.icone = data;
        } else {
            this.saisie.configImpression.essai.icone = data;
        }
    }

    recuperationEtatEssai(element) {
        if (this.saisie.configImpression.switch) {
            switch (element.id) {
                case 0:
                    if (this.saisie.configImpression.switch.essaisAvecValeur === true) {
                        element.selected = true;
                    } else {
                        element.selected = false;
                    }
                    break;
                case 1:
                    if (this.saisie.configImpression.switch.essaisSansValeur === true) {
                        element.selected = true;
                    } else {
                        element.selected = false;
                    }
                    break;
                case 2:
                    if (this.saisie.configImpression.switch.essaisTermines === true) {
                        element.selected = true;
                    } else {
                        element.selected = false;
                    }
                    break;
                case 3:
                    if (this.saisie.configImpression.switch.essaisNonTermines === true) {
                        element.selected = true;
                    } else {
                        element.selected = false;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    recuperationEtatSsEssai(element) {
        if (this.saisie.configImpression.switch) {
            switch (element.id) {
                case 0:
                    if (this.saisie.configImpression.switch.sousEssaisAvecValeur === true) {
                        element.selected = true;
                    } else {
                        element.selected = false;
                    }
                    break;
                case 1:
                    if (this.saisie.configImpression.switch.sousEssaisSansValeur === true) {
                        element.selected = true;
                    } else {
                        element.selected = false;
                    }
                    break;

                default:
                    break;
            }
        }
    }

    recuperationEtatCaracteristique(element) {
        if (this.saisie.configImpression.switch) {
            switch (element.id) {
                case 0:
                    if (this.saisie.configImpression.switch.caracteristiqueAvecValeur === true) {
                        element.selected = true;
                    } else {
                        element.selected = false;
                    }
                    break;
                case 1:
                    if (this.saisie.configImpression.switch.caracteristiqueSansValeur === true) {
                        element.selected = true;
                    } else {
                        element.selected = false;
                    }
                    break;

                default:
                    break;
            }
        }
    }

    recuperationOrigineEssais(element) {
        if (this.saisie.configImpression.switch) {
            switch (element.id) {
                case 0:
                    if (this.saisie.configImpression.switch.essaisPrelev === true) {
                        element.selected = true;
                    } else {
                        element.selected = false;
                    }
                    break;
                case 1:
                    if (this.saisie.configImpression.switch.essaisRef === true) {
                        element.selected = true;
                    } else {
                        element.selected = false;
                    }
                    break;
                case 2:
                    if (this.saisie.configImpression.switch.essaisChoisi === true) {
                        element.selected = true;
                    } else {
                        element.selected = false;
                    }
                    break;

                default:
                    break;
            }
        }
    }

    recuperationOrigineCaracteristique(element) {
        if (this.saisie.configImpression.switch) {
            switch (element.id) {
                case 0:
                    if (this.saisie.configImpression.switch.caracteristiquePrelev === true) {
                        element.selected = true;
                    } else {
                        element.selected = false;
                    }
                    break;
                case 2:
                    if (this.saisie.configImpression.switch.caracteristiqueChoisi === true) {
                        element.selected = true;
                    } else {
                        element.selected = false;
                    }
                    break;

                default:
                    break;
            }
        }
    }

    async selectConfig(data) {
        if (data) {
            this.idSelected = data.id;
            this.saisie.configImpression.libelle = data.libelle;
            this.saisie.configImpression.switch = {
                essaisPrelev: data.essaisPrelev,
                essaisRef: data.essaisRef,
                essaisChoisi: data.essaisChoisi,

                essaisAvecValeur: data.essaisAvecValeur,
                essaisSansValeur: data.essaisSansValeur,
                essaisTermines: data.essaisTermines,
                essaisNonTermines: data.essaisNonTermines,

                sousEssaisAvecValeur: data.sousEssaisAvecValeur,
                sousEssaisSansValeur: data.sousEssaisSansValeur,

                essaisCode: data.essaisCode,
                essaisLibelle: data.essaisLibelle,

                caracteristiquePrelev: data.caracteristiquePrelev,
                caracteristiqueChoisi: data.caracteristiqueChoisi,
                caracteristiqueAvecValeur: data.caracteristiqueAvecValeur,
                caracteristiqueSansValeur: data.caracteristiqueSansValeur,

                caracteristiqueCode: data.caracteristiqueCode,
                caracteristiqueLibelle: data.caracteristiqueLibelle,

                essaiPrincipal: data.sousEssaiPrincipal
            };
            await this.reset();
            await this.onLoad();
        }
    }

    switchEtatEssai() {
        this.saisie.configImpression.switch.essaisAvecValeur = false;
        this.saisie.configImpression.switch.essaisSansValeur = false;
        this.saisie.configImpression.switch.essaisTermines = false;
        this.saisie.configImpression.switch.essaisNonTermines = false;

        if (this.saisie.configImpression && this.saisie.configImpression.etatEssai.length > 0) {
            for (let i = 0; i < this.saisie.configImpression.etatEssai.length; i++) {
                const el = this.saisie.configImpression.etatEssai[i];

                switch (el.id) {
                    case 0:
                        this.saisie.configImpression.switch.essaisAvecValeur = true;
                        break;
                    case 1:
                        this.saisie.configImpression.switch.essaisSansValeur = true;
                        break;
                    case 2:
                        this.saisie.configImpression.switch.essaisTermines = true;
                        break;
                    case 3:
                        this.saisie.configImpression.switch.essaisNonTermines = true;
                        break;

                    default:
                        break;
                }
            }
        }
    }

    switchEtatSousEssai() {
        this.saisie.configImpression.switch.sousEssaisAvecValeur = false;
        this.saisie.configImpression.switch.sousEssaisSansValeur = false;

        if (this.saisie.configImpression && this.saisie.configImpression.etatSousEss.length > 0) {
            for (let i = 0; i < this.saisie.configImpression.etatSousEss.length; i++) {
                const el = this.saisie.configImpression.etatSousEss[i];

                switch (el.id) {
                    case 0:
                        this.saisie.configImpression.switch.sousEssaisAvecValeur = true;

                        break;
                    case 1:
                        this.saisie.configImpression.switch.sousEssaisSansValeur = true;
                        break;

                    default:
                        break;
                }
            }
        }
    }

    switchEtatCaracteristique() {
        this.saisie.configImpression.switch.caracteristiqueAvecValeur = false;
        this.saisie.configImpression.switch.caracteristiqueSansValeur = false;

        if (this.saisie.configImpression && this.saisie.configImpression.etatCaracteristique.length > 0) {
            for (let i = 0; i < this.saisie.configImpression.etatCaracteristique.length; i++) {
                const el = this.saisie.configImpression.etatCaracteristique[i];

                switch (el.id) {
                    case 0:
                        this.saisie.configImpression.switch.caracteristiqueAvecValeur = true;
                        break;
                    case 1:
                        this.saisie.configImpression.switch.caracteristiqueSansValeur = true;
                        break;
                    default:
                        break;
                }
            }
        }
    }

    selectOrigine(data, type, list) {
        if (data) {
            if (type === 'essais') {
                switch (data.id) {
                    case 0:
                        this.saisie.configImpression.switch.essaisPrelev = true;
                        this.saisie.configImpression.switch.essaisRef = false;
                        this.saisie.configImpression.switch.essaisChoisi = false;

                        break;
                    case 1:
                        this.saisie.configImpression.switch.essaisPrelev = false;
                        this.saisie.configImpression.switch.essaisRef = true;
                        this.saisie.configImpression.switch.essaisChoisi = false;
                        break;
                    case 2:
                        this.saisie.configImpression.switch.essaisPrelev = false;
                        this.saisie.configImpression.switch.essaisRef = false;
                        this.saisie.configImpression.switch.essaisChoisi = true;
                        break;
                    default:
                        break;
                }
                this.chargementListEssais();
            }
            if (type === 'caracteristique') {
                switch (data.id) {
                    case 0:
                        this.saisie.configImpression.switch.caracteristiquePrelev = true;
                        this.saisie.configImpression.switch.caracteristiqueChoisi = false;

                        break;
                    case 2:
                        this.saisie.configImpression.switch.caracteristiquePrelev = false;
                        this.saisie.configImpression.switch.caracteristiqueChoisi = true;
                        break;
                    default:
                        break;
                }
                this.chargementListCaracteristique();
            }
        }
    }

    async chargementListEssais() {
        this.listEssaisTries = [];
        // essais du prelevement
        if (this.saisie.configImpression.switch.essaisPrelev && this.saisie.mesures && this.saisie.mesures.length > 0) {
            this.listEssaisTries = this.saisie.mesures;
        }
        // essais de la reference
        else if (
            this.saisie.configImpression.switch.essaisRef &&
            this.saisie &&
            this.saisie.bornes &&
            this.saisie.bornes.length > 0 &&
            this.saisie.mesures &&
            this.saisie.mesures.length > 0
        ) {
            const listSousEssaiRef = angular.copy(this.saisie.bornes);
            const mesures = angular.copy(this.saisie.mesures);
            const data = await this.PrelevementsConfigImpressionService.getAllEssaisFromReference(
                listSousEssaiRef,
                this.saisie.trame !== null && this.saisie.trame.hasOwnProperty('id') ? this.saisie.trame.id : undefined
            );

            for (const i in mesures) {
                const mesure = mesures[i];
                for (const j in mesure.sousEssais) {
                    const sousEssaiMesure = mesure.sousEssais[j];
                    if (sousEssaiMesure.hasOwnProperty('granuloAdditionalInformation')) {
                        if (
                            sousEssaiMesure.granuloAdditionalInformation != null &&
                            sousEssaiMesure.granuloAdditionalInformation.hasOwnProperty('tamis')
                        ) {
                            const listTamis = sousEssaiMesure.granuloAdditionalInformation.tamis;
                            const idEssai = mesure.id;
                            const idSousEssai = sousEssaiMesure.id;

                            for (const k in listSousEssaiRef) {
                                const sousEssaiRef = listSousEssaiRef[k];
                                if (sousEssaiRef.idEssai === idEssai && sousEssaiRef.idSousEssai === idSousEssai) {
                                    for (const m in data) {
                                        const essaiRef = data[m];
                                        if (essaiRef.id === idEssai) {
                                            for (const p in essaiRef.sousEssais) {
                                                const sousEssaiData = essaiRef.sousEssais[p];
                                                sousEssaiData.granuloAdditionalInformation == null
                                                    ? (sousEssaiData.granuloAdditionalInformation = { tamis: [] })
                                                    : '';
                                                if (sousEssaiData.id === idSousEssai) {
                                                    const idxRef = listTamis.findIndex((x) => x.idTamis === sousEssaiRef.idSousEssaiTamis);
                                                    if (idxRef > -1) {
                                                        sousEssaiData.granuloAdditionalInformation.tamis.push(listTamis[idxRef]);
                                                        sousEssaiData.granuloAdditionalInformation.tamis = this._.orderBy(
                                                            sousEssaiData.granuloAdditionalInformation.tamis,
                                                            'ouverture',
                                                            'desc'
                                                        );
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            data.forEach((element) => {
                this.listEssaisTries.push(element);
            });
        }
        // essais choisis
        else if (this.saisie.configImpression.switch.essaisChoisi && this.saisie.mesures && this.saisie.mesures.length > 0) {
            this.listEssaisTries = this.saisie.mesures;
        }
        this.saisie.configImpression.listEssais = this.listEssaisTries;
    }

    async chargementListCaracteristique() {
        this.listCaracteristiquesTries = [];

        // caracteristiques du prelevement
        if (
            this.saisie.configImpression.switch.caracteristiquePrelev &&
            this.saisie &&
            this.saisie.caracteristiqueDtos &&
            this.saisie.caracteristiqueDtos.length > 0
        ) {
            for (let i = 0; i < this.saisie.caracteristiqueDtos.length; i++) {
                const el = this.saisie.caracteristiqueDtos[i];
                if (this.saisie.niveauVisibilite >= el.niveauVisibilite) {
                    this.listCaracteristiquesTries.push(el);
                }
            }
        }

        // caracteristiques choisis
        if (
            this.saisie.configImpression.switch.caracteristiqueChoisi &&
            this.saisie.caracteristiqueDtos &&
            this.saisie.caracteristiqueDtos.length > 0
        ) {
            for (let i = 0; i < this.saisie.caracteristiqueDtos.length; i++) {
                const el = this.saisie.caracteristiqueDtos[i];
                if (this.saisie.niveauVisibilite >= el.niveauVisibilite) {
                    this.listCaracteristiquesTries.push(el);
                    this.saisie.configImpression.listCarac = this.listCaracteristiquesTries;
                }
            }
        }
        this.saisie.configImpression.listCarac = this.listCaracteristiquesTries;
    }

    selectEtatEssai() {
        this.saisie.configImpression.etatEssai = [];
        if (this.tmp && this.tmp.listEtatEssai) {
            this.saisie.configImpression.etatEssai = this.tmp.listEtatEssai;
        }
        this.switchEtatEssai();
    }

    selectEtatSousEssai() {
        this.saisie.configImpression.etatSousEss = [];
        if (this.tmp && this.tmp.listEtatSousEss) {
            this.saisie.configImpression.etatSousEss = this.tmp.listEtatSousEss;
        }
        this.switchEtatSousEssai();
    }

    selectEtatCaracteristique() {
        this.saisie.configImpression.etatCaracteristique = [];
        if (this.tmp && this.tmp.listEtatCaracteristique) {
            this.saisie.configImpression.etatCaracteristique = this.tmp.listEtatCaracteristique;
        }
        this.switchEtatCaracteristique();
    }

    selectEssai() {
        this.saisie.configImpression.selectedEssai = [];
        if (this.tmp && this.tmp.listEssaisTries) {
            this.saisie.configImpression.selectedEssai = this.tmp.listEssaisTries;
        }
    }

    selectCarac() {
        this.saisie.configImpression.selectedCarac = [];
        if (this.tmp && this.tmp.listCarac) {
            this.saisie.configImpression.selectedCarac = this.tmp.listCarac;
        }
    }

    async saveConfig() {
        try {
            if (this.saisie && this.saisie.configImpression && this.saisie.configImpression.switch) {
                const tmp = {
                    libelle: this.saisie.configImpression.libelle,
                    essaisPrelev: this.saisie.configImpression.switch.essaisPrelev,
                    essaisRef: this.saisie.configImpression.switch.essaisRef,
                    essaisChoisi: this.saisie.configImpression.switch.essaisChoisi,
                    essaisAvecValeur: this.saisie.configImpression.switch.essaisAvecValeur,
                    essaisSansValeur: this.saisie.configImpression.switch.essaisSansValeur,
                    essaisTermines: this.saisie.configImpression.switch.essaisTermines,
                    essaisNonTermines: this.saisie.configImpression.switch.essaisNonTermines,
                    sousEssaisAvecValeur: this.saisie.configImpression.switch.sousEssaisAvecValeur,
                    sousEssaisSansValeur: this.saisie.configImpression.switch.sousEssaisSansValeur,
                    essaisCode: this.saisie.configImpression.switch.essaisCode,
                    essaisLibelle: this.saisie.configImpression.switch.essaisLibelle,
                    caracteristiquePrelev: this.saisie.configImpression.switch.caracteristiquePrelev,
                    caracteristiqueChoisi: this.saisie.configImpression.switch.caracteristiqueChoisi,
                    caracteristiqueAvecValeur: this.saisie.configImpression.switch.caracteristiqueAvecValeur,
                    caracteristiqueSansValeur: this.saisie.configImpression.switch.caracteristiqueSansValeur,
                    caracteristiqueCode: this.saisie.configImpression.switch.caracteristiqueCode,
                    caracteristiqueLibelle: this.saisie.configImpression.switch.caracteristiqueLibelle,
                    certificatEssaiLogo: false,
                    certificatEssaiIcone: this.saisie.configImpression.essai.icone !== null ? this.saisie.configImpression.essai.icone.id : null,
                    certificatProduitLogo: this.saisie.configImpression.produit.logo !== null ? true : false,
                    certificatProduitIcone:
                        this.saisie.configImpression.produit.icone !== null ? this.saisie.configImpression.produit.icone.id : null,
                    certificatProduitNumero: this.saisie.configImpression.produit.numero !== null ? true : false,
                    certificatProduitDate:
                        this.saisie.configImpression.produit.start !== null || this.saisie.configImpression.produit.end !== null ? true : false,
                    certificatProduitLibelle: this.saisie.configImpression.produit.libelle !== null ? true : false,
                    sousEssaiPrincipal: this.saisie.configImpression.switch?.essaiPrincipal
                };

                let id = null;
                if (tmp && tmp.libelle != null && this.idSelected != 0) {
                    id = await this.PrelevementsConfigImpressionService.updateConfiguration(this.saisie.id, this.idSelected, tmp);
                } else {
                    id = await this.PrelevementsConfigImpressionService.createConfiguration(this.saisie.id, tmp);
                }
                if (id) {
                    this.notification.success(this.$translate.instant('PRELEVEMENTS.IMPRESSION.SUCCESS'));
                    this.addConfiguration = false;
                    this.idSelected = angular.copy(id.id);

                    this.onLoad();
                }
            }
        } catch (error) {
            this.notification.error(this.$translate.instant('PRELEVEMENTS.IMPRESSION.ERROR'));
        }
    }

    async supprConfig() {
        try {
            if (this.idSelected && this.idSelected !== 0) {
                await this.PrelevementsConfigImpressionService.deleteConfiguration(this.saisie.id, this.idSelected);
                this.notification.success('PRELEVEMENTS.IMPRESSION.DELETE.DELETED');
                this.idSelected = 0;
                this.$onInit();
            }
        } catch (err) {
            this.notification.error(err.data);
        }
    }

    selectCertificationConfigValues(data) {
        if (this.idSelected > 0) {
            const selection = this.listConfig.find((x) => x.id === this.idSelected);

            if (this.certifEssai) {
                this.essaisConfigValues(selection);
            }
            if (this.saisie.certifProduit > 0) {
                this.produitConfigValues(selection);
            }
        }
    }

    essaisConfigValues(selection) {
        if (selection.certificatEssaiIcone !== null) {
            const icone = this.imagesEssai.find((x) => x.id === selection.certificatEssaiIcone);
            icone.selected = true;
            this.saisie.configImpression.essai.icone = icone;
            this.istEvenIconEssai = true;
            const iconeOptions = this.essaisOptions.find((x) => x.value === 'icone');
            iconeOptions.selected = true;
        }

        this.saisie.configImpression.essai.logo = selection.certificatEssaiLogo;
    }

    produitConfigValues(selection) {
        this.saisie.configImpression.produit.logo = selection.certificatProduitLogo ? this.certificatProduit.logo : null;
        this.saisie.configImpression.produit.start = selection.certificatProduitDate ? this.certificatProduit.start : null;
        this.saisie.configImpression.produit.end = selection.certificatProduitDate ? this.certificatProduit.end : null;
        this.saisie.configImpression.produit.libelle = selection.certificatProduitLibelle ? this.certificatProduit.libelleCG : null;
        this.saisie.configImpression.produit.numero = selection.certificatProduitNumero ? this.certificatProduit.numero : null;

        if (selection.certificatProduitIcone !== null) {
            const icone = this.imagesProduit.find((x) => x.id === selection.certificatProduitIcone);
            icone.selected = true;
            this.saisie.configImpression.produit.icone = icone;
            this.istEvenIconProduit = true;
            const iconeOptions = this.produitOptions.find((x) => x.value === 'icone');
            iconeOptions.selected = true;
        }
    }

    resetCertificationSelection() {
        this.istEvenIconEssai = false;
        this.essaisOptions.forEach((el) => (el.selected = false));
        if (this.imagesEssai) {
            this.imagesEssai.forEach((img) => (img.selected = false));
        }

        this.istEvenIconProduit = false;
        this.produitOptions.forEach((el) => (el.selected = false));
        if (this.imagesProduit) {
            this.imagesProduit.forEach((img) => (img.selected = false));
        }

        this.initCertifConfigValues();
    }

    debug() {
        console.log('debug =>', angular.copy(this.saisie));
        console.log('debug 2=>', angular.copy(this.saisie.configImpression.switch));
    }

    selectAll(select) {
        if (!select) {
            this.saisie.configImpression.produit.logo = null;
            this.saisie.configImpression.produit.start = null;
            this.saisie.configImpression.produit.end = null;
            this.saisie.configImpression.produit.libelle = null;
            this.saisie.configImpression.produit.numero = null;
            this.saisie.configImpression.produit.icone = null;
            this.istEvenIconProduit = false;
        } else {
            this.istEvenIconProduit = true;
            this.saisie.configImpression.produit.logo = this.certificatProduit.logo;
            this.saisie.configImpression.produit.start = this.certificatProduit.start;
            this.saisie.configImpression.produit.end = this.certificatProduit.end;
            this.saisie.configImpression.produit.libelle = this.certificatProduit.libelleCG;
            this.saisie.configImpression.produit.numero = this.certificatProduit.numero;
            const icone = this.imagesProduit.find((x) => x.id === selection.certificatProduitIcone);
            icone.selected = true;
            this.saisie.configImpression.produit.icone = icone;
            const iconeOptions = this.produitOptions.find((x) => x.value === 'icone');
            iconeOptions.selected = true;
        }
    }
}

PrelevementConfigImpressionController.$inject = [
    '$translate',
    '$scope',
    '$state',
    'notification',
    'PrelevementsConfigImpressionService',
    'MesuresService',
    'CaracteristiquesService',
    'MassiaRightsService',
    '_'
];
