import ProduitFormProducteursController from './produit.form.producteurs.controller';

export default {
    bindings: {
        modalInstance: '=',
        produit: '=',
        app: '<'
    },
    template: require('./produit.form.producteurs.html'),
    controller: ProduitFormProducteursController
};
