(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.filters')
        .config(AppConfig);
    AppConfig.$inject = ['$translatePartialLoaderProvider'];

    /* @ngInject */
    function AppConfig($translatePartialLoaderProvider) {
        $translatePartialLoaderProvider.setPart('fr-FR', 'blocks.filters', {
            'BLOCKS_FILTERS_YES': 'OUI',
            'BLOCKS_FILTERS_NO': 'NON'
        });

        $translatePartialLoaderProvider.setPart('en-US', 'blocks.filters', {
            'BLOCKS_FILTERS_YES': 'YES',
            'BLOCKS_FILTERS_NO': 'NO'
        });

        $translatePartialLoaderProvider.setPart('es-ES', 'blocks.filters', {
            'BLOCKS_FILTERS_YES': 'Si',
            'BLOCKS_FILTERS_NO': 'No'
        });

        $translatePartialLoaderProvider.setPart('nl-BE', 'blocks.filters', {
            'BLOCKS_FILTERS_YES': 'JA',
            'BLOCKS_FILTERS_NO': 'NIET'
        });

        $translatePartialLoaderProvider.addPart('blocks.filters');
    }
})(angular);