export class SaveAsModalController {
	/* @ngInject */
	constructor($scope, $translate, $state, $stateParams, $timeout, notification) {
		this.$scope = $scope;
		this.$translate = $translate;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$timeout = $timeout;
		this.notification = notification;
	}
	$onInit() {
		this.libelle = this.resolve?.libelle + '_copy';
		this.code = this.resolve?.code + '_copy';
	}

	valider() {
		if (this.checkCode()) {
			this.modalInstance.close({
				libelle: this.libelle,
				code: this.code
			});
		} else {
			this.notification.error('CODE_CHARACTERS_NOK', null, {
				timeOut: 2000,
				extendedTimeOut: 0,
				closeButton: false
			});
		}
	}
	cancel() {
		this.modalInstance.dismiss();
	}

	checkCode() {
		return this.code.match(/^[a-zA-Z0-9_]*$/);
	}
}
export default {
	bindings: {
		modalInstance: '=',
		resolve: '='
	},
	controller: SaveAsModalController,
	template: require('./save.as.modal.tpl.html')
};
