import TypesConfig from './_config/types.config';
import TypesRoutes from './_config/types.routes';

import TypesService from './services/types.service';
import GranulometrieTypeProduitService from './services/granulometrie.type.produit.service';

import TypesComponent from './components/types';
import TypeDetailComponent from './components/type-detail';
import TypeFormComponent from './components/type-form';
import TypeValidator from './components/type-form/type.validator';

import TypeCaracteristiquesDetailComponent from './components/type-caracteristiques-detail';
import TypeCaracteristiquesFormComponent from './components/type-caracteristiques-form';

const moduleName = 'app.massia.common.types';

angular
    .module(moduleName, [])
    .config(TypesConfig)
    .config(TypesRoutes)
    .service('TypesService', TypesService)
    .service('GranulometrieTypeProduitService', GranulometrieTypeProduitService)
    .factory('TypeValidator', TypeValidator)
    .component('types', TypesComponent)
    .component('typeForm', TypeFormComponent)
    .component('typeDetail', TypeDetailComponent)
    .component('typeCaracteristiquesDetail', TypeCaracteristiquesDetailComponent)
    .component('typeCaracteristiquesForm', TypeCaracteristiquesFormComponent);

export default moduleName;
