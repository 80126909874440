export default function Configure($stateProvider) {
    $stateProvider.state('offres', {
        parent: 'gestion',
        url: '/offres',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.GESTION_COMMERCIALE" | translate }}'
        }
    });

    $stateProvider.state('offres.list', {
        url: '/list',
        template: '<offres></offres>',
        rights: { domain: 'offres', right: 'read' },
        ncyBreadcrumb: {
            parent: 'offres',
            label: '{{ "OFFRES.BREADCRUMBS.OFFRES_LIST" | translate}}'
        },
        navigation: {
            menu: 'gestion_commerciale_commercial',
            translate: 'OFFRES.BREADCRUMBS.OFFRES_LIST',
            order: 1
        },
        search: 'OFFRES.BREADCRUMBS.OFFRES_LIST'
    });

    $stateProvider.state('offres.new', {
        url: '/new',
        template: '<offre-form></offre-form>',
        rights: { domain: 'offres', right: 'create' },
        ncyBreadcrumb: {
            parent: 'offres.list',
            label: '{{ "OFFRES.BREADCRUMBS.OFFRE_NEW" | translate }}'
        }
    });

    $stateProvider.state('offres.edit', {
        url: '/{id}/edit',
        template: '<offre-form></offre-form>',
        rights: { domain: 'offres', right: 'update' },
        ncyBreadcrumb: {
            parent: 'offres.list',
            label: '{{ "OFFRES.BREADCRUMBS.OFFRE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('offres.commande', {
        url: '/proposition/{id}/suivi-par/{personneId}/commande',
        template: '<offre-commande></offre-commande>',
        rights: { domain: 'offres', right: 'update' },
        params: {
            clientId: undefined,
            chantierId: undefined
        },
        ncyBreadcrumb: {
            parent: 'offres.edit',
            label: '{{ "OFFRES.BREADCRUMBS.OFFRE_COMMANDER" | translate }}'
        }
    });

    $stateProvider.state('offres.newClient', {
        url: '/proposition/:propositionId/new-client/from-prospect/:prospectId/suivi-par/:personneId',
        template: '<site-form></site-form>',
        rights: { domain: 'siteclient', right: 'create' },
        params: {
            parentState: 'offres.commande',
            genre: 'clients',
            fromProspect: 'true'
        },
        ncyBreadcrumb: {
            parent: 'offres.commande({id:id})',
            label: '{{ "OFFRES.BREADCRUMBS.OFFRE_NEW_CLIENT" | translate }}'
        }
    });

    $stateProvider.state('offres.newChantier', {
        url: '/proposition/:propositionId/new-chantier/client/{clientId:int}/lieu/{lieuId:int}',
        template: '<chantier-form></chantier-form>',
        rights: { domain: 'chantiers', right: 'create' },
        params: {
            parentState: 'offres.commande',
            fromProposition: 'true'
        },
        ncyBreadcrumb: {
            parent: 'offres.commande({id:id})',
            label: '{{ "OFFRES.BREADCRUMBS.OFFRE_NEW_CHANTIER" | translate }}'
        }
    });

    $stateProvider.state('offres.validation', {
        url: '/validation-devis',
        template: '<offre-validation-devis></offre-validation-devis>',
        rights: { domain: 'offrevaliddev', right: 'read' },
        ncyBreadcrumb: {
            parent: 'offres.list',
            label: '{{ "OFFRES.BREADCRUMBS.VALIDATION_DEVIS" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
