const serviceUrl = `${__configuration.apiUrl}/massia/gestion/encours`;

export default class EncoursService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getEncours(filters, sorts, pagination, idSociete, idClient, isAlerte, isDepasse) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {}),
                idSociete: JSON.stringify(idSociete || null),
                idClient: JSON.stringify(idClient || null),
                isAlerte: JSON.stringify(isAlerte),
                isDepasse: JSON.stringify(isDepasse)
            }
        });

        return res.data;
    }

    async getSocietesCom() {
        const url = `${__configuration.apiUrl}/massia/societes-commerciales`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getClients(valeur) {
        const url = `${__configuration.apiUrl}/massia/sites-clients/typeAhead/${valeur}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getLastDateCalculAuto() {
        const url = `${serviceUrl}/date`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async export(modeImpr, filters, sorts, idSociete, idClient, isAlerte, isDepasse) {
        const url = `${serviceUrl}/print`;
        const res = await this.$http.get(url, {
            params: {
                modeImpr: JSON.stringify(modeImpr),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                idSociete: JSON.stringify(idSociete || null),
                idClient: JSON.stringify(idClient || null),
                isAlerte: JSON.stringify(isAlerte),
                isDepasse: JSON.stringify(isDepasse)
            },
            responseType: 'arraybuffer'
        });
        return res;
    }

    async calculEncours() {
        const url = `${serviceUrl}/calcul`;
        return await this.$http.put(url);
    }
}
