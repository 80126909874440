import validatorBuilder from 'validator-builder';

let trameSaisieValidator = null;

export default class TrameSaisie {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.typeId = data.typeId;
        this.typeProduitId = data.typeProduitId || null;
        this.petitDProduitId = data.petitDProduitId || null;
        this.grandDProduitId = data.grandDProduitId || null;
        this.codeExists = data.codeExists || null;
        this.isEnabled = data.isEnabled;
        this.sitesProducteurs = data.sitesProducteurs || [];
        this.sitesProducteursIds = data.sitesProducteursIds || [];
        this.produits = data.produits || [];
        this.produitsIds = data.produitsIds || [];
        this.mesures = data.mesures || [];
        this.typeMesureId = 0;
        this.isMobile = data.isMobile;
        this.ordreMobile = data.ordreMobile;
        this.delaiPurge = data.delaiPurge;
    }

    isValid() {
        trameSaisieValidator = trameSaisieValidator || validatorBuilder.getInstanceFor('TrameSaisie');
        const validationResults = trameSaisieValidator.validate(this);
        return validationResults.isValid;
    }
}
