import validatorBuilder from 'validator-builder';

let campagneCaracteristiquesValidator = null;

export default class CampagneCaracteristiques {
    constructor(data) {
        campagneCaracteristiquesValidator = campagneCaracteristiquesValidator || validatorBuilder.getInstanceFor('CampagneCaracteristiques');
        campagneCaracteristiquesValidator.clearRules();
        campagneCaracteristiquesValidator.generateRules();

        this.data = {};
        for (const element in data) {
            if (element !== 'data') {
                this[element] = data[element];
                this.data[element] = {
                    id: parseInt(element),
                    value: getFormattedValues(data[element]),
                    additionalInformations: { key: 'clé', value: 'valeur' }
                };
            }
        }

        function getFormattedValues(element) {
            if (Array.isArray(element)) {
                const result = _.map(element, function (e) {
                    if (e && e.hasOwnProperty('key')) {
                        return { value: e.key };
                    }
                    return { value: e };
                });
                return result;
            }
            return element;
        }
    }

    isValid() {
        const validationResults = campagneCaracteristiquesValidator.validate(this);
        return validationResults.isValid;
    }
}
