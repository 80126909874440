TachesLieesController.$inject = ['$scope', '$state', '$stateParams', '$translate', 'ModalService', 'TachesService', 'notification', '_'];

export default function TachesLieesController($scope, $state, $stateParams, $translate, ModalService, TachesService, notification, _) {
    const vm = this;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm._ = _;
    vm.tacheLiee = [];

    vm.loadTaches = loadTaches;
    vm.print = print;
    vm.sauvegarder = sauvegarder;
    vm.createTache = createTache;

    vm.deleteFiltre = deleteFiltre;
    vm.selectFiltre = selectFiltre;
    vm.refreshFiltre = refreshFiltre;
    vm.setSelectedFiltre = setSelectedFiltre;
    vm.preselectFiltre = preselectFiltre;

    async function loadTaches() {
        startLoading();

        vm.tacheLiee = {};

        try {
            if (vm.id) {
                vm.tacheLiee = await TachesService.getTachesLiees(parseInt(vm.id), vm.domaine);

                vm.donneeFiltreeTache = [];

                vm._.forEach(vm.tacheLiee.taches, (dFiltree) => {
                    vm.donneeFiltreeTache.push(dFiltree.idTache);
                });

                vm.filtreTaches = await refreshFiltre();
                vm.preselectFiltre();
            } else {
                vm.tacheLiee.isVisible = false;
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function createTache() {
        const modalInstance = ModalService.confirm({
            modalTitle: 'TACHES.CONFIRM_CREATE_TACHE.TITLE',
            modalMsg: 'TACHES.CONFIRM_CREATE_TACHE.MESSAGE',
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(() => $state.go('taches.newTache', { idOrigine: parseInt(vm.id), domaine: vm.domaine, theme: vm.theme }));
    }

    function deleteFiltre(filtre) {
        const index = vm.donneeFiltreeTache.indexOf(filtre.id);
        if (index >= 0) {
            vm.donneeFiltreeTache.splice(index, 1);
            filtre.selected = false;
        }
    }

    function selectFiltre(filtre) {
        const index = vm.donneeFiltreeTache.indexOf(filtre.id);
        if (index < 0) {
            vm.donneeFiltreeTache.push(filtre.id);
        }
    }

    async function refreshFiltre() {
        let data;
        try {
            data = await TachesService.getFiltres();
        } catch (ex) {
            data = [];
        }
        return data;
    }

    function setSelectedFiltre() {
        vm._.forEach(vm.filtreTaches, (filtre) => {
            if (filtre.selected) {
                vm.selectFiltre(filtre);
            } else {
                vm.deleteFiltre(filtre);
            }
        });
    }

    function preselectFiltre() {
        vm._.forEach(vm.donneeFiltreeTache, (id) => {
            const filtre = vm._.find(vm.filtreTaches, { id: id });
            if (filtre) {
                filtre.selected = true;
            }
        });
    }

    async function sauvegarder() {
        setSelectedFiltre();
        startLoading();
        try {
            await TachesService.updateFiltre(parseInt(vm.id), vm.domaine, vm.donneeFiltreeTache);
            notification.success('TACHES.UPDATED');
        } catch (ex) {
            notification.error(ex.data);
            return false;
        } finally {
            loadTaches();
            stopLoading();
        }
    }
}
