(function (angular, undefined) {
    'use strict';

    angular.module('blocks.treeview')
        .run(run);
    run.$inject = ['$templateCache'];

    function run($templateCache) {

        $templateCache.put('blocks/treeview/ac.treeview.root.tpl.html',
            '<div class="ac-treeview-tree">' +
            '    <!-- En cas de problèmes, afficher un message -->' +
            '    <div ng-if="::tree.readme" ng-include="\'ac.treeview.readme.tpl.html\'"></div>' +
            '' +
            '    <!-- Recherche et actions de configuration -->' +
            '    <div ng-if="::(!tree.readme)" ng-include="::(tree.config.configurationMenuTemplateUrl || \'blocks/treeview/ac.treeview.configuration.menu.html\')"></div>' +
            '' +
            '    <!-- Menu clic droit des noeuds -->' +
            '    <div ng-if="::(!tree.readme)" ng-include="tree.config.nodeMenuTemplateUrl || \'blocks/treeview/ac.treeview.node.menu.html\'"' +
            '         ng-controller="AcTreeviewMenuController as menu"' +
            '         ng-init="menu.init(tree.config.name)"></div>' +
            '' +
            '    <!-- Le treeview en lui-même -->' +
            '    <ol class="ac-treeview-root-list list-style-type-none display-inline-block"' +
            '        ng-if="::(!tree.error)">' +
            '        <li class="padding-10" ng-show="tree.isLoading[\'root\']">' +
            '           <div class="ac-loading-wrapper-left">' +
            '               <div ac-loader></div> ' +
            '           </div>' +
            '        </li>' +
            '        <li class="padding-0" ng-show="!tree.isLoading[\'root\'] && tree.items.length === 0 && !tree.readme && tree.searchTerm === undefined">' +
            '            <div class="alert-info padding-10">' +
            '                <span translate="TREEVIEW_NO_ROOT"></span>' +
            '            </div>' +
            '        </li>' +
            '        <li class="padding-0" ng-show="!tree.isLoading[\'root\'] && tree.items.length === 0 && !tree.readme && tree.searchTerm !== undefined">' +
            '            <div class="alert-warning padding-10">' +
            '                <span translate="TREEVIEW_NO_RESULTS"></span>' +
            '            </div>' +
            '        </li>' +
            '        <li ng-repeat="child in tree.items"' +
            '            id="{{::child.id}}"' +
            '            ng-hide="tree.isLoading[\'root\']"' +
            '            ng-include="::(tree.config.nodeTemplateUrl || \'blocks/treeview/ac.treeview.node.tpl.html\')">' +
            '        </li>' +
            '    </ol>' +
            '</div>'
        );
    }
})(angular);