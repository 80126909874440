const serviceUrl = `${__configuration.apiUrl}/massia/unites`;
const serviceUrlList = `${__configuration.apiUrl}/massia/unites-list`;

export default class UnitesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getUnites(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getUnitesSelect(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrlList, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });
        return res.data;
    }

    async getUniteCompo(id) {
        const res = await this.$http.get(serviceUrl + '/compo', {
            params: {
                id: id
            }
        });

        return res.data;
    }

    async deleteUniteById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getUniteById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createUnite(selectedUnite) {
        const res = await this.$http.post(serviceUrl, selectedUnite);
        return res.data;
    }

    async updateUnite(selectedUnite) {
        const url = `${serviceUrl}/${selectedUnite.id}`;
        return this.$http.put(url, selectedUnite);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }
}
