import { IRequestShortcutConfig } from 'angular';
import ApiService from '../../app/services/api.service';
import { FilterProperty } from './FilterProperty';
import { PagedResult } from './PagedResult.interface';
import { PaginationProperty } from './PaginationProperty.interface';

export interface MassiaHttpService<GetAll, GetOne, Create, Update, Delete> {
    apiSvc: ApiService;
    url: string;
    getAll(filters?: FilterProperty[], sorts?: any[], pagination?: PaginationProperty): Promise<PagedResult<GetAll>>;
    getOne(id: number): Promise<GetOne>;
    create(data: any): Promise<Create | void>;
    update(id: number, data: any): Promise<Update | void>;
    delete(id: number): Promise<Delete | void>;
}

export abstract class BaseHttpService<GetAll, GetOne, Create, Update, Delete> implements MassiaHttpService<GetAll, GetOne, Create, Update, Delete> {
    protected apiSvc: ApiService;
    protected url: string;
    constructor(ApiService: ApiService) {
        this.apiSvc = ApiService;
    }
    getAll(
        filters?: FilterProperty[],
        sorts?: any[],
        pagination?: PaginationProperty,
        config?: IRequestShortcutConfig,
        params?: any
    ): Promise<PagedResult<GetAll>> {
        let queryParams: any = {};
        if(filters) {
            queryParams['filters'] = JSON.stringify(filters);
        }
        if(sorts) {
            queryParams['sorts'] = JSON.stringify(sorts);
        }
        if(pagination) {
            queryParams['pagination'] = JSON.stringify(pagination);
        }
        return this.apiSvc.get(this.url, {
            ...config,
            params: {
                ...queryParams,
                ...params
            },
        });
    }
    getOne(id: number, config?: IRequestShortcutConfig): Promise<GetOne> {
        return this.apiSvc.get(`${this.url}/${id}`, config);
    }
    create(data: any, config?: IRequestShortcutConfig): Promise<Create | void> {
        return this.apiSvc.post(`${this.url}`, data, config);
    }
    update(id: number, data: any, config?: IRequestShortcutConfig): Promise<Update | void> {
        return this.apiSvc.put(`${this.url}/${id}`, data, config);
    }
    delete(id: number, config?: IRequestShortcutConfig): Promise<Delete | void> {
        return this.apiSvc.delete(`${this.url}/${id}`, config);
    }
}
