GrilleTarifairesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'GrilleTarifairesService',
    'notification',
    '$uibModal'
];

export default function GrilleTarifairesController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    GrilleTarifairesService,
    notification,
    $uibModal
) {
    const vm = this;

    let previousTableState;
    const watchers = [];

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.$translate = $translate;
    vm.$uibModal = $uibModal;
    vm.grilleTarifaires = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };
    vm.itemsByPageOptions = [100, 200, 500];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.loadGrilleTarifaires = loadGrilleTarifaires;
    vm.print = print;
    vm.selectGrilleTarifaire = selectGrilleTarifaire;

    async function init() {}

    init();

    async function loadGrilleTarifaires(tableState) {
        startLoading();

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.grilleTarifaires = [];

        try {
            const data = await GrilleTarifairesService.getGrilleTarifaires(filters, sorts, pagination);

            vm.grilleTarifaires = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function selectGrilleTarifaire(grilleTarifaire) {
        if (grilleTarifaire && grilleTarifaire.id) {
            showDetail(grilleTarifaire.id);
        }
    }

    function showDetail(id) {
        openGrille(id);
    }

    function openGrille(idGrille) {
        const _this = vm;
        vm.$uibModal
            .open({
                template:
                    '<tarifs source="$ctrl.source" domaine="$ctrl.domaine" id-grille="$ctrl.idGrille" modal-instance="$ctrl.uibModalInstance"></tarifs>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = null;
                        $ctrl.domaine = null;
                        $ctrl.idGrille = idGrille;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl'
            })
            .result.then(
                function (result) {
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    async function print() {
        const modalInstance = ModalService.confirm({
            modalTitle: vm.$translate.instant('GRILLES.PRINTCHECKED.TITLE'),
            modalMsg: vm.$translate.instant('GRILLES.PRINTCHECKED.MESSAGE'),
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(async function () {
            const fileName = Date.now();

            const filters = PaginationService.getFilters(previousTableState);
            const sorts = PaginationService.getSorts(previousTableState);
            const pagination = PaginationService.getPagination(previousTableState);

            const resultat = await GrilleTarifairesService.print(filters, sorts, pagination);

            const data = resultat.data;
            const status = resultat.status;
            let headers = resultat.headers;

            headers = headers();

            const contentType = headers['content-type'];

            const fileExtention = '.xlsx';
            const linkElement = document.createElement('a');
            try {
                const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                const url = window.URL.createObjectURL(blob);
                linkElement.setAttribute('href', url);
                linkElement.setAttribute('download', fileName + fileExtention);

                const clickEvent = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: false
                });
                linkElement.dispatchEvent(clickEvent);
            } catch (ex) {
            } finally {
            }
        });
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
