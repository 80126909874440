import OffrePropositionController from './offre.proposition.controller';

export default {
    bindings: {
        offre: '=',
        proposition: '=',
        isOpen: '<',
        index: '<',
        print: '&',
        command: '&'
    },
    template: require('./offre.proposition.html'),
    controller: OffrePropositionController
};
