const serviceUrl = `${__configuration.apiUrl}/massia/offres`;

export default class OffresService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getOffres(filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async deleteOffreById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async getOffreEnteteById(id) {
		const url = `${serviceUrl}/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getProposition(id) {
		const url = `${__configuration.apiUrl}/massia/propositions/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async createOffre(offre) {
		offre.caracteristiques = this.formatCaracteristiques(offre.caracteristiques);

		const result = await this.$http.post(serviceUrl, offre);
		return result.data;
	}

	async duplicateOffre(id) {
		const url = `${__configuration.apiUrl}/massia/offres/${id}/duplicate`;
		const result = await this.$http.post(url);
		return result.data;
	}

	async updateOffre(offre) {
		console.log(offre.caracteristiques);
		offre.caracteristiques = this.formatCaracteristiques(offre.caracteristiques);
		console.log(offre.caracteristiques);
		const url = `${serviceUrl}/${offre.entete.id}`;
		// On retire les proposition temporaire qui sont seulement utiliser pour afficher la liaison dans le front
		const off = angular.copy(offre);
		off.entete.propositions = off.entete.propositions.map((x) => {
			x.proposition = null;
			return x;
		});
		return this.$http.put(url, off);
	}

	async orderProposition(idProposition, clientId, chantierId, societeId, personneId) {
		const url = `${__configuration.apiUrl}/massia/propositions/${idProposition}/order`;
		const data = {
			clientId: clientId,
			chantierId: chantierId,
			societeId: societeId,
			personneId: personneId
		};
		return this.$http.post(url, data);
	}

	async getProduitsLies(filters) {
		const monFiltre = JSON.stringify(filters);
		const uniteUrl = `${__configuration.apiUrl}/massia/parametres/produits/lies?filters=${monFiltre}`;
		const res = await this.$http.get(uniteUrl);

		return res.data.produits;
	}

	async getPrestations() {
		const url = `${__configuration.apiUrl}/massia/prestations`;
		const res = await this.$http.get(url);

		return res.data.items;
	}

	async getTransports() {
		const url = `${__configuration.apiUrl}/massia/transports`;
		const res = await this.$http.get(url);

		return res.data.items;
	}

	async getSitesProducteurs() {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-producteurs/all`;
		const res = await this.$http.get(siteUrl);

		return res.data;
	}

	// getSiteCommerciaux by societe
	async getSitesCommerciaux(idSociete) {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-commerciaux/header/${idSociete}`;
		const res = await this.$http.get(siteUrl);

		return res.data;
	}

	async getBennes() {
		const siteUrl = `${__configuration.apiUrl}/massia/gestion/type-bennes/headers`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getSocietes() {
		const siteUrl = `${__configuration.apiUrl}/massia/societes-commerciales`;
		const res = await this.$http.get(siteUrl);

		return res.data;
	}

	async getSocieteById(id) {
		const application = 'gestion';
		const url = `${__configuration.apiUrl}/massia/societes/${id}/entete/${application}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getClientsAvecCompteSurSociete(idSociete, search) {
		const url = `${__configuration.apiUrl}/massia/sites-clients/typeAheadCompteOuvert/${idSociete}/${search}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getClients(idSociete) {
		const url = `${__configuration.apiUrl}/massia/sites-clients/header/${idSociete}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getProspects() {
		const siteUrl = `${__configuration.apiUrl}/massia/prospects`;
		const res = await this.$http.get(siteUrl);

		return res.data.items;
	}

	async getProspectsBySociete(idSociete) {
		const siteUrl = `${__configuration.apiUrl}/massia/prospectsBySociete/${idSociete}`;
		const res = await this.$http.get(siteUrl);

		return res.data;
	}

	async getContactsBySite(idSite) {
		const siteUrl = `${__configuration.apiUrl}/massia/personnes/contacts-by-site/headers/${idSite}`;
		const res = await this.$http.get(siteUrl);

		return res.data;
	}

	async getPersonnes(idSociete) {
		const siteUrl = `${__configuration.apiUrl}/massia/personnes/salarie-et-non-affecte/headers/${idSociete}`;
		const res = await this.$http.get(siteUrl);
		return res.data; //.items;
	}

	async getOffreTypes() {
		const url = `${__configuration.apiUrl}/massia/domaines/offres/types`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getPaysById(id) {
		const url = `${__configuration.apiUrl}/massia/pays/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getChantiers(clientId) {
		const url = `${__configuration.apiUrl}/massia/chantiers`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify([{ criterion: 'siteId', value: clientId, type: 'eq' }]),
				sorts: JSON.stringify([]),
				pagination: JSON.stringify({ skip: 0, take: 0 })
			}
		});

		return res.data.items;
	}

	async getLieux(valeur, idClient) {
		const url = `${__configuration.apiUrl}/massia/lieux/typeAhead/${valeur}/${idClient}`;
		const res = await this.$http.get(url);
		return res.data; //.items;
	}

	async getProspect(id) {
		const url = `${__configuration.apiUrl}/massia/prospects/${id}/entete`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getEtats() {
		const url = 'assets/enum/offres/etats.json';
		const res = await this.$http.get(url);
		return res.data;
	}

	async print(id, fileName, isValide, isDevis, idProposition = null) {
		const url = `${__configuration.apiUrl}/massia/offres/${id}/print`;
		const res = await this.$http.post(url, {
			template: fileName,
			isDevis: isDevis,
			isValid: isValide,
			idProposition: idProposition
		});
		return res.data;
	}

	async printOld(id, fileName, isValide, isDevis, idProposition = null) {
		const url = `${__configuration.apiUrl}/massia/offres/${id}/${idProposition}/print/${fileName}/${isValide}/${isDevis}`;
		const res = await this.$http.get(url, {
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getTarifProduit(clientId, siteCommercialId, produitId, producteurId) {
		const uniteUrl = `${__configuration.apiUrl}/massia/grillesTarifaires/siteCommercial/${siteCommercialId}/prixProduit/${produitId}/${producteurId}?filters={"achatVente": true, "clientId": ${clientId}}`;
		const res = await this.$http.get(uniteUrl);

		return res.data;
	}

	async getTarifPrestation(clientId, siteCommercialId, prestationId, prixProduitLie) {
		let uniteUrl = `${__configuration.apiUrl}/massia/grillesTarifaires/siteCommercial/${siteCommercialId}/prixPrestation/${prestationId}?filters={"achatVente": true, "clientId": ${clientId}}`;
		if (prixProduitLie) {
			uniteUrl = `${__configuration.apiUrl}/massia/grillesTarifaires/siteCommercial/${siteCommercialId}/prixPrestation/${prestationId}?filters={"achatVente": true, "clientId": ${clientId}, "prixProduitLie": ${prixProduitLie}}`;
		}
		const res = await this.$http.get(uniteUrl);

		return res.data;
	}

	async getTarifTransport(clientId, siteCommercialId, transportId, benneId, zoneId, lieuLivraisonId) {
		const uniteUrl = `${__configuration.apiUrl}/massia/grillesTarifaires/siteCommercial/${siteCommercialId}/prixTransport/${transportId}/${benneId}/${zoneId}/${lieuLivraisonId}?filters={"achatVente": true, "clientId": ${clientId}}`;
		const res = await this.$http.get(uniteUrl);

		return res.data;
	}

	async getTarifTransportBySociete(clientId, societeId, transportId, benneId, zoneId, lieuLivraisonId) {
		const uniteUrl = `${__configuration.apiUrl}/massia/grillesTarifaires/societe/${societeId}/prixTransport/${transportId}/${benneId}/${zoneId}/${lieuLivraisonId}?filters={"achatVente": true, "clientId": ${clientId}}`;
		const res = await this.$http.get(uniteUrl);

		return res.data;
	}

	async getSiteById(id) {
		const genre = 1; // SL pour ne pas faire le traitement de recuperation des EtatCompteOuvert
		const application = 'gestion';
		const url = `${__configuration.apiUrl}/massia/sites/${id}/entete/genre/${genre}/${application}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getDistancier(isChantier, lieuDeparts, lieuArrivee) {
		const url = `${__configuration.apiUrl}/massia/distanciers`;

		const filtres = {
			isChantier: isChantier,
			lieuDepartIds: lieuDeparts,
			lieuArriveeIds: lieuArrivee
		};
		const res = await this.$http.get(url, {
			params: {
				filtres: JSON.stringify(filtres || {})
			}
		});

		return res.data;
	}

	async estCodeAuto() {
		const url = `${serviceUrl}/est-code-auto`;
		try {
			const res = await this.$http.get(url);
			return res.data;
		} catch (err) {
			if (err.status === 404) {
				return false;
			}
			throw err;
		}
	}

	async exportListeOffres(modeImpr, filters, sorts) {
		const url = `${serviceUrl}/print`;
		const res = await this.$http.get(url, {
			params: {
				modeImpr: JSON.stringify(modeImpr),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || [])
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getTvaList() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/tvas/headers`);
		return res.data;
	}

	async exportTemplate(id, fileName, idTemplate) {
		const url = `${__configuration.apiUrl}/massia/offres/export-template/${id}/print/${fileName}/${idTemplate}`;
		const res = await this.$http.get(url, {
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getTemplates() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/offres/export-template/get`);
		return res.data;
	}

	async deleteTemplate(id) {
		const url = `${__configuration.apiUrl}/massia/offres/export-template/${id}`;
		return this.$http.delete(url);
	}

	async setChantierParameter(idChantier, date, elementCode, entreeSortie) {
		const url = `${__configuration.apiUrl}/massia/parametres/valeur`;
		const element = {
			elementCode: elementCode,
			valeur: true,
			chantierId: idChantier,
			entreeSortie: entreeSortie,
			dateApplication: date,
			elementType: 'bool',
			date: date,
			codeDomaine: 'Chantiers',
			hasToCheckPont: false
		};
		const res = await this.$http.post(url, element);
		return new Promise((resolve) => resolve(res.data));
	}

	async getOffreValeursCaracteristiquesAssociationsById(id) {
		const data = await this.getOffreValeursCaracteristiquesById(id);
		const result = {};

		for (const element in data) {
			if (data[element] && !Array.isArray(data[element])) {
				if (data[element].value === 'True') {
					data[element].value = 'true';
				}
				if (data[element].value === 'False') {
					data[element].value = 'false';
				}

				if (data[element].value && data[element].value.key) {
					result[data[element].id] = data[element].value.key;
				} else {
					result[data[element].id] = data[element].value;
				}
			}
		}
		return result;
	}

	async getOffreValeursCaracteristiquesById(id) {
		const url = `${serviceUrl}/${id}/valeurs-caracteristiques`;
		const res = await this.$http.get(url);
		const result = [];

		res.data.forEach(function (element) {
			const caract = {
				id: element.id,
				code: element.code,
				idNature: element.idNature,
				indice: element.indice,
				label: element.label,
				numericAdditionalInformation: element.numericAdditionalInformation,
				value: element.elementsValues || element.elementValue || element.values || element.value
			};
			result.push(caract);
		});

		return result;
	}

	//pour create et update
	formatCaracteristiques(caracteristiques) {
		const data = [];
		for (const property in caracteristiques) {
			let keyValueObject;
			//On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variables interne Js.
			if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
				const propertyVal = caracteristiques[property];
				if (Array.isArray(propertyVal)) {
					if (_.some(propertyVal, 'key')) {
						keyValueObject = {
							IdCaracteristique: parseInt(property),
							values: _.map(propertyVal, 'key')
						};
					} else {
						keyValueObject = {
							IdCaracteristique: parseInt(property),
							values: propertyVal
						};
					}
				} else {
					keyValueObject = {
						IdCaracteristique: parseInt(property),
						value: propertyVal
					};
				}
				data.push(keyValueObject);
			}
		}
		return data;
	}

	async updateTgapExclure(id, type) {
		const url = `${__configuration.apiUrl}/massia/gestion/tgap-exclure/${id}/${type}`;
		return await this.$http.put(url);
	}

	async getConfigValidationDevis() {
		const id = 1; // temporairement il ny a qu'une config..
		const result = await this.$http.get(`${__configuration.apiUrl}/massia/validation-devis/${id}`);
		return result.data;
	}

	async createConfigValidationDevis(configValidationDevis) {
		const result = await this.$http.post(`${__configuration.apiUrl}/massia/validation-devis`, configValidationDevis);
		return result.data;
	}

	async updateConfigValidationDevis(configValidationDevis) {
		const result = await this.$http.put(`${__configuration.apiUrl}/massia/validation-devis`, configValidationDevis);
		return result.data;
	}

	async requestValidationOffre(data) {
		const result = await this.$http.post(`${__configuration.apiUrl}/massia/validation-devis/request-validation`, data);
		return result.data;
	}
	async responseValidationOffre(data) {
		const result = await this.$http.post(`${__configuration.apiUrl}/massia/validation-devis/response-validation`, data);
		return result.data;
	}

	async getFranco(idClient, idSociete) {
		const url = `${serviceUrl}/param-franco/${idClient}/${idSociete}`;
		const res = await this.$http.get(url);
		return res.data;
	}
}
