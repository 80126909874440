import ControleEnteteController from './controle.entete.controller';

export default {
    bindings: {
        controle: '=',
        isOpen: '='
    },
    template: require('./controle.entete.html'),
    controller: ControleEnteteController
};
