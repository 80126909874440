import { copy, isNumber } from 'angular';
import { DebugLevel } from '../../../../../../../../../libs/massia-component/massia-service/logger.service';

export default class SyntheseAffichageController {
	/* @ngInject */
	constructor(
		SyntheseModelesService,
		notification,
		$translate,
		$scope,
		$rootScope,
		$timeout,
		SyntheseSettingService,
		SyntheseEntitiesService,
		SyntheseEntityService,
		$uibModal,
		MassiaApplicationService,
		Logger
	) {
		this.SyntheseModelesService = SyntheseModelesService;
		this.SyntheseEntitiesService = SyntheseEntitiesService;
		this.SyntheseEntityService = SyntheseEntityService;
		this.notification = notification;
		this.$uibModal = $uibModal;
		this.logSvc = Logger;
		/**
		 * @type ng.IScope
		 */
		this.$scope = $scope;
		/**
		 * @type ng.IRootScopeService
		 */
		this.$rootScope = $rootScope;
		/**
		 * @type ng.ITimeoutService
		 */
		this.$timeout = $timeout;
		/**
		 * @type ng.translate.ITranslateService
		 */
		this.$translate = $translate;
		this.SyntheseSettingService = SyntheseSettingService;
		/**
		 * @type MassiaApplicationService
		 */
		this.MassiaApplicationService = MassiaApplicationService;
	}
	$onInit() {
		this.modal = {
			_1: false,
			_2: false,
			_3: false,
			_0: false
		};
		this.dragOptions = {
			stop: (e, ui) => {
				for (const key in this.elements) {
					if (Object.hasOwnProperty.call(this.elements, key)) {
						this.elements[key] = this.elements[key].map((x, i) => {
							x.ordre = i;
							return x;
						});
					}
				}
			}
		};
		this.application = this.MassiaApplicationService.getApplication();
		this.scopeForTreeView(this.$scope);
		this.$scope.$broadcast('angular-ui-tree:collapse-all');
		this.selectedGroup = [];
		//this._loadDefaultValue();
		this.unregister = [
			this.$scope.$watch(
				() => this.conversion,
				() => {
					if (this.conversion && this.conversion.length > 0) {
						this.unit = this.conversion.distinctBy((v) => v.uniteReference.id);
					}
				},
				true
			),
			this.$scope.$watch(
				() => this.synthese.typeSynthese,
				() => {
					this._loadDefaultValue();
				},
				true
			)
		];
	}
	$onDestroy() {
		if (this.unregister) {
			for (let i = 0; i < this.unregister.length; i++) {
				const u = this.unregister[i];
				u();
			}
		}
	}

	_selectRupture() {}

	async _loadDefaultValue() {
		try {
			this.listCritere = [];
			const allLoad = [this.loadCriteres(), this.loadDonneeVal(), this.loadTraitement()];
			const res = await Promise.all(allLoad);
			this.listCritere = [...res[0], res[1][1], res[1][0]];
			if (res[2]) {
				this.listCritere.push(res[2]);
			}

			this.SyntheseEntityService.setSyntheseLoaded(true);
		} catch (err) {
			this.logSvc.log(DebugLevel.ERROR, err);
		}
	}

	haveDate() {
		return this.table.donneeVal.entete.find((x) => x.champ === 1);
	}

	getListGroup(list) {
		const t = copy(list);
		return t.filter((x) => x.selected);
	}

	selectGroup() {
		this.synthese.ruptures = this.selectedGroup.map((x) => {
			return {
				champ: x.champ,
				IdDonneeSel: x.idDonneeSelectionable
			};
		});
	}

	getTypeSynthese(bloc) {
		let type = [];
		if (bloc && bloc.length > 0) {
			for (let i = 0; i < bloc.length; i++) {
				const b = bloc[i];
				if (b.modelSelection && b.modelSelection.modelSelectionTypes) {
					type = [...new Set(type.concat(b.modelSelection.modelSelectionTypes.map((x) => x.idTypeSynthese)))];
				}
			}
		}

		return type;
	}

	async loadTable() {
		try {
			if (this.synthese.typeSynthese) {
				const res = await this.SyntheseSettingService.loadTable(this.synthese.typeSynthese);
				this.tableDomaine = res;
			}
		} catch (err) {
			this.logSvc.log(DebugLevel.ERROR, err);
		}
	}

	selectTable() {
		this.synthese.idDomaine = this.selectedTable[0] !== null ? this.selectedTable[0].idDomaine : null;
	}

	async loadCriteres() {
		this.loadCritere = true;
		try {
			let result = [];
			const aff = {
				critereAffichageFieldDtos: [],
				idDomaine: null,
				libelle: this.$translate.instant('SYNTHENTS.AFFICHAGE'),
				title: undefined
			};
			if (this.synthese.typeSynthese) {
				result = await this.SyntheseModelesService.getCriteresISteven(this.synthese.typeSynthese, 'criteres', false);
				aff.critereAffichageFieldDtos = result;
			}
			return [aff];
		} catch (err) {
			this.logSvc.log(DebugLevel.ERROR, err);
			this.notification.error(err.data);
		}
		this.loadCritere = false;
	}

	async loadDonneeVal() {
		try {
			if (this.synthese.typeSynthese) {
				const res = await this.SyntheseSettingService.loadDonneeValeur(this.synthese.typeSynthese);
				const donnee = {
					idDomaine: -1,
					libelle: this.$translate.instant('SYNTHENTS.DONNEE_VAL'),
					critereAffichageFieldDtos: [
						...res
							.filter((x) => x.type === 3)
							.map((x) => {
								return {
									type: 3,
									caracteristiques: null,
									champComposition: null,
									compositions: null,
									idCaracteristique: null,
									idDonneeSelectionable: null,
									channel: 'E',
									libelle: this.$translate.instant(`SYNTHENTS.DON_VAL.${x.translate}`),
									searchProperty: `${this.$translate.instant('SYNTHENTS.DONNEE_VAL')}/${this.$translate.instant(
										`SYNTHENTS.DON_VAL.${x.translate}`
									)}`,
									typeCritere: x.id,
									champ: null
								};
							})
					]
				};
				const entete = {
					idDomaine: -1,
					libelle: this.$translate.instant('SYNTHENTS.AFFICHAGES._2'),
					critereAffichageFieldDtos: [
						...res
							.filter((x) => x.type === 2)
							.map((x) => {
								return {
									type: 2,
									caracteristiques: null,
									champComposition: null,
									compositions: null,
									idCaracteristique: null,
									idDonneeSelectionable: null,
									channel: 'D',
									libelle: this.$translate.instant(`SYNTHENTS.DON_VAL.${x.translate}`),
									searchProperty: `${this.$translate.instant('SYNTHENTS.AFFICHAGES._2')}/${this.$translate.instant(
										`SYNTHENTS.DON_VAL.${x.translate}`
									)}`,
									typeCritere: x.id,
									champ: null
								};
							})
					]
				};
				//this.listCritere.push(field);
				return [entete, donnee];
			}
		} catch (err) {
			this.logSvc.log(DebugLevel.ERROR, err);
		}
	}

	async loadTraitement() {
		try {
			const tempList = await this.SyntheseEntitiesService.getCalculsStatistiques(this.activite);

			//enlever les fuseaux (gérés uniquement sur la courbe granulo)
			const allFuseau = tempList.filter((elt) => elt.isFuseau);
			if (allFuseau && allFuseau.length > 0) {
				for (let i = 0; i < allFuseau.length; i++) {
					const idxDel = tempList.findIndex((elt) => elt.id === allFuseau[i].id);
					tempList.splice(idxDel, 1);
				}
			}
			const res = {
				idDomaine: -1,
				libelle: this.$translate.instant('SYNTHENTS.TRAITEMENT'),
				critereAffichageFieldDtos: tempList.map((x) => {
					return {
						type: 0,
						libelle: x.libelle,
						caracteristiques: null,
						compositions: null,
						champ: null,
						champComposition: null,
						channel: 'C',
						idCaracteristique: null,
						idDonneeSelectionable: null,
						typeCritere: x.id,
						searchProperty: this.$translate.instant('SYNTHENTS.TRAITEMENT') + '/' + x.libelle,
						isTraitement: true //  flag pour cacher la roue dentée pour les traitements
					};
				})
			};
			// console.log(res);
			//this.criteres.traitements = [res];
			//this.listCritere = this.listCritere.concat(res);
			return res;
		} catch (err) {
			this.logSvc.log(DebugLevel.ERROR, err);
			this.notification.error(err.data);
		}
	}

	scopeForTreeView($scope) {
		$scope.toggleColapsed = this.toggle;
		$scope.selectNode = this.selectItem;
		$scope.visible = (item) => {
			const isIncluded = (x) => x.searchProperty.toLowerInvariant().includes(this.query.toLowerInvariant());
			const checkField = (e) => e && e.length > 0 && e.filter(isIncluded).length > 0;
			return !(
				this.query &&
				this.query.length > 0 &&
				!(
					(item.searchProperty && isIncluded(item)) ||
					(item && checkField(item.critereAffichageFieldDtos)) ||
					(item && checkField(item.compositions)) ||
					(item && checkField(item.caracteristiques))
				)
			);
		};
		$scope.collapse = (brd) => {
			this.$scope.$broadcast(brd);
		};
		$scope.checkSelectedCrit = this.checkSelectedCrit;
		$scope.isSelectionable = this.isSelectionable;
		$scope.getModel = this.getModel;
		$scope.isOnTable = this.isOnTable;
	}

	isSelectionable(item) {
		if (!item) {
			return;
		}
		return (
			(item.critereAffichageFieldDtos && item.critereAffichageFieldDtos.length > 0) ||
			(item.caracteristiques && item.caracteristiques.length > 0) ||
			(item.compositions && item.compositions.length > 0)
		);
	}

	getModel(item) {
		if (!item) {
			return;
		}
		if (!item.title) {
			item.title = item.searchProperty;
		}
		if (item.critereAffichageFieldDtos && item.critereAffichageFieldDtos.length > 0) {
			return item.critereAffichageFieldDtos;
		}
		if (item.caracteristiques && item.caracteristiques.length > 0) {
			return item.caracteristiques;
		}
		if (item.compositions && item.compositions.length > 0) {
			return item.compositions;
		}
	}

	isOnTable = (item) => {
		if (!this.isSelectionable(item)) {
			const el = this.synthese.critereAffichages.find((x) => this.recursifCheckOnTable(x, item));
			if (el) {
				const type = typeof item.type !== 'number' ? '_1' : `_${item.type}`;
				item.style = el.style;
				item.ordre = el.ordre;
				item.title = el.title || item.searchProperty;
				if (!this.elements[type]) {
					this.elements[type] = [];
				}
				this.elements[type].push(item);
				this.elements[type].sort((a, b) => a.ordre - b.ordre);
				return (item.selected = true);
			}
		}
		return false;
	};

	isSameCritere(model, item) {
		return (
			model.champ === item.typeCritere &&
			model.champCompo === item.champComposition &&
			model.idCaracteristique === item.idCaracteristique &&
			model.idDonneeSelectionable === item.idDonneeSelectionable &&
			(typeof model.type === 'number' ? model.type : 1) === (typeof item.type === 'number' ? item.type : 1)
		);
	}

	recursifCheckOnTable(model, item) {
		if (this.isSameCritere(model, item)) {
			return model;
		}
		if (model.nestedCritere.length > 0) {
			return model.nestedCritere.find((x) => this.recursifCheckOnTable(x, item));
		}
		return null;
	}

	checkSelectedCrit(node) {
		if (this.synthese.critereAffichages && this.synthese.critereAffichages.length > 0) {
			const idx = this.synthese.critereAffichages.findIndex((x) => this.sameCritere(x, node));
			if (idx >= 0) {
				node.selected = true;
				this.synthese.critereAffichages[idx].label = node.searchProperty;
			}
		}
	}

	selectItem = (node) => {
		const type = typeof node.type !== 'number' ? '_1' : `_${node.type}`;
		if (!this.elements[type]) {
			this.elements[type] = [];
		}
		if (node.selected) {
			node.ordre = this.elements[type].length + 1;
			this.elements[type].push(node);
		} else {
			const idx = this.elements[type].findIndex((e) => {
				const tmp = { ...e };
				tmp.selected = false;
				return JSON.stringify(tmp) === JSON.stringify(node);
			});
			if (idx > -1) {
				this.elements[type].splice(idx, 1);
			}
		}
	};

	// Arrow fx for bind this
	toggle(scope) {
		//console.log(scope);
		scope.toggle();
	}

	treeOptions() {
		return {};
	}

	onDelete(model, index) {
		if (isNumber(index)) {
			model.splice(index, 1);
		}
	}
}
