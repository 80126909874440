import PlanImpressionPrixConfig from './_config/plan.impression.prix.config';
import PlanImpressionPrixRoutes from './_config/plan.impression.prix.routes';
import PlanImpressionPrixService from './services/plan.impression.prix.service';

import PlanImpressionPrixComponent from './components/plan-impression-prix';
import PlanImpressionPrixValidator from './components/plan-impression-prix/plan.impression.prix.validator';
import PlanImpressionPrixHelper from './components/plan-impression-prix-helper';
import PlanImpressionPrixFranco from './components/plan-impression-prix-franco';

const moduleName = 'app.parametrageGeneral.plan.impression.prix';

angular
    .module(moduleName, [])
    .config(PlanImpressionPrixConfig)
    .config(PlanImpressionPrixRoutes)
    .service('PlanImpressionPrixService', PlanImpressionPrixService)
    .component('planImpressionPrix', PlanImpressionPrixComponent)
    .component('planImpressionPrixHelper', PlanImpressionPrixHelper)
    .component('planImpressionPrixFranco', PlanImpressionPrixFranco)
    .factory('planImpressionPrixValidator', PlanImpressionPrixValidator);

export default moduleName;
