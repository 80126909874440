export default function Configure($stateProvider) {
    $stateProvider.state('historique', {
        parent: 'common',
        url: '/historique',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true,
            label: '{{ "LAYOUT.NAVIGATION.OUTILS" | translate }}'
        }
    });

    $stateProvider.state('historique.list', {
        url: '/list',
        template: '<historique></historique>',
        rights: { domain: 'histo', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "HISTORIQUE.BREADCRUMBS.HISTORIQUE" | translate}}'
        },
        // on affiche l'historique dans la navigation
        /*navigation: {
            // navigationCls: 'hidden-xs hidden-sm', // TODO exemple : ce state n'apparaitra pas sur mobile
            menu: 'transport_no_category',
            translate: 'HISTORIQUE.BREADCRUMBS.HISTORIQUE',
            order: 3
        },*/
        // la l'historique' fait partie des points d'entrée recherchables dans la navigation
        search: 'HISTORIQUE.BREADCRUMBS.HISTORIQUE'
    });
}

Configure.$inject = ['$stateProvider'];
