export default function Configure($stateProvider) {
    $stateProvider.state('normes-labo', {
        parent: 'common',
        url: '/normes-labo',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.ESSAIS_NORMES" | translate }}'
        }
    });

    $stateProvider.state('normes-labo.list', {
        url: '/list',
        template: '<normes></normes>',
        rights: { domain: 'normes', right: 'read', application: 'laboratoire' },
        ncyBreadcrumb: {
            parent: 'normes-labo',
            label: '{{ "NORMES.BREADCRUMBS.NORMES_LIST" | translate}}'
        },
        navigation: {
            menu: 'essais_normes_seul',
            translate: 'NORMES.BREADCRUMBS.NORMES_LIST',
            order: 61
        },
        search: 'NORMES.BREADCRUMBS.NORMES_LIST'
    });

    $stateProvider.state('normes-labo.list.detail', {
        url: '/{id}',
        template: '<norme-detail></norme-detail>',
        rights: { domain: 'normes', right: 'read' },
        ncyBreadcrumb: {
            parent: 'normes-labo.list',
            label: '{{ "NORMES.BREADCRUMBS.NORME_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('normes-labo.new', {
        url: '/new',
        template: '<norme-form></norme-form>',
        rights: { domain: 'normes', right: 'create' },
        ncyBreadcrumb: {
            parent: 'normes-labo.list',
            label: '{{ "NORMES.BREADCRUMBS.NORME_NEW" | translate}}'
        },
        search: 'NORMES.BREADCRUMBS.NORME_NEW'
    });

    $stateProvider.state('normes-labo.edit', {
        url: '/{id}/edit',
        template: '<norme-form></norme-form>',
        rights: { domain: 'normes', right: 'update' },
        ncyBreadcrumb: {
            parent: 'normes-labo.list',
            label: '{{ "NORMES.BREADCRUMBS.NORME_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
