export default class Niveau {
    /**
     * Constructeur d'un niveau
     * @param data {
     *     idNiveau: {int} identifiant du niveau,
     *     label: {string} libellé du formulaire,
     *     depth: {int} profondeur dans l'arborescence,
     *     all: {array} Tous les items sélectionnables
     *     select: {array} liste des items sélectionnables filtrés par les choix parents,
     *     saisi: {boolean} champs de saisi libre,
     *     idChildrens: tableaux des idNiveaux de niveaux enfants,
     *     isCollapse: niveau plié ou déplié
     * }
     */
    constructor(data = {}) {
        (this.idNiveau = data.idNiveau),
            (this.label = data.label),
            (this.depth = data.depth || 0),
            (this.all = data.all || []),
            (this.select = data.select || []),
            (this.saisi = data.saisi || false),
            (this.idSousNiveaux = data.idSousNiveaux || []);
        this.isOpen = true;
        this.isCollapse = false;
    }
}
