(function () {
    'use strict';
    angular.module('blocks.helpers').service('HttpService', HttpService);
    HttpService.$inject = ['$q', '$log'];

    function HttpService($q, $log) {

        var service = this;

        service.invoke = invoke;
        service.toQueryString = toQueryString;

        return service;

        function invoke(request, isReturningFullErrorResponse) {
            var deferred = $q.defer();

            if ((typeof request.then) == 'function' && (typeof request.catch) == 'function') { // jshint ignore:line
                request
                    .then(function (response) {
                        deferred.resolve(response.data);
                    })
                    .catch(function (response) {
                        $log.error(response.data, response.status);
                        if (isReturningFullErrorResponse) {
                            deferred.reject(response);
                        } else {
                            deferred.reject(response.data);
                        }
                    });
            } else {
                $log.error(request + ' is not a function !');
                deferred.reject('not a function ');
            }
            return deferred.promise;
        }

        // Permet la transformation d'un objet en QueryString en utilisant le premier niveau de propriétés comme nom de paramètre
        // {prop:{value:0}, prop2:'toto'} -> prop={value:0}&prop2=toto
        function toQueryString(data) {
            if (data && typeof(data) === 'object') {
                return _.map(data,function(v,k){
                    var value = v;
                    if(value !== undefined) {
                        if (value !== null && angular.isObject(value)) {
                            value = JSON.stringify(value);
                        }
                        return encodeURIComponent(k) + '=' + encodeURIComponent(value);
                    }
                }).join('&');
            }
            return '';
        }

    }
})();
