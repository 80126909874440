export default class PrelevementExportModalController {
    constructor($translate, $scope, notification, ExcelService) {
        this.t = $translate;
        this.scope = $scope;
        this.prelevement = this.resolve.plv;
        this.notification = notification;
        this.tmpSelect = {};
        this.excelService = ExcelService;
    }

    $onInit() {
        this.createSelectList();
        this.getConfig(this.prelevement.id);
    }

    $onDestroy() {}

    async getConfig(id) {
        try {
            const data = await this.excelService.getAllConfig(id);
            //console.log(data);
        } catch (err) {
            console.log(err);
        }
    }

    createSelectList() {
        this.choice = [
            {
                libelle: 'Code prélèvement',
                value: this.prelevement.code,
                ref: 0,
                selected: true
            },
            {
                libelle: 'Date du prélèvement',
                value: this.prelevement.date,
                ref: 1,
                selected: true
            },
            {
                libelle: 'Site auditeur',
                value: this.prelevement.auditeur,
                ref: 2,
                selected: true
            },
            {
                libelle: 'Site producteur',
                value: this.prelevement.producteur,
                ref: 3,
                selected: true
            },
            {
                libelle: 'Référence',
                value: this.prelevement.reference,
                ref: 4,
                selected: true
            },
            {
                libelle: 'Produit',
                value: this.prelevement.produit,
                ref: 5,
                selected: true
            },
            {
                libelle: 'Prélèvement effectué par',
                value: this.prelevement.faitPar,
                ref: 6,
                selected: true
            },
            {
                libelle: 'Liste des essais',
                value: this.prelevement.essai,
                ref: 7,
                selected: true
            },
            {
                libelle: 'Essai',
                value: 'selectEss',
                ref: 8,
                selected: false
            }
        ];
        this.select = angular.copy(this.choice);
    }

    ok() {
        let isValid = true;
        for (let i = 0; i < this.choice.length; i++) {
            if (
                this.choice[i].value === '' ||
                this.choice[i].value === 'select' ||
                this.choice[i].value === 'selectEss' ||
                this.choice[i].value === 'selectSess'
            ) {
                this.choice.splice(i, 1);
                i--;
            }
        }
        for (let i = 0; i < this.choice.length; i++) {
            if (!this.choice[i].cell && this.choice[i].selected) {
                isValid = false;
            }
        }
        if (isValid) {
            this.modalInstance.close(this.choice.filter((x) => x.selected));
        } else {
            this.notification.error('Cellule manquante');
        }
    }

    close() {
        this.modalInstance.close();
    }

    add() {
        this.choice.push({
            libelle: '',
            value: 'select',
            ref: -1,
            selected: false
        });
    }

    onSelect(index, tmp) {
        tmp = angular.copy(tmp);
        if (tmp.sousEssais) {
            this.choice[index].libelle = 'Selection Sous Essai';
            this.choice[index].value = 'selectSess';
            this.choice[index].ref = this.choice.length;
            this.selectSess = tmp.sousEssais;
            this.choice[index].selected = false;
            this.parent = tmp;
        } else if (tmp.nature) {
            this.choice[index].libelle = tmp.libelle;
            this.choice[index].value = angular.isArray(tmp.valeur) ? tmp.valeur : tmp.valeur + ' ' + tmp.unite;
            this.choice[index].ref = this.choice.length;
            this.choice[index].selected = true;
            this.choice[index].parent = this.parent.libelle;
        } else {
            this.choice[index] = tmp;
            this.choice[index].selected = true;
            this.choice[index].ref = this.choice.length;
        }
        this.tmpSelect = {};
    }

    isArray(value) {
        if (angular.isArray(value)) {
            return true;
        }
        return false;
    }
}

PrelevementExportModalController.$inject = ['$translate', '$scope', 'notification', 'ExcelService'];
