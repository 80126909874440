import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-essai-iteratif',
    bindings: {
        model: '=',
        field: '=',
        nvxVisibilite: '<',
        sousMesuresComplet: '=',
        onDeleteMesure: '&',
        onExitMesure: '&'
    },
    transclude: true,
    template: require('./massia.essai.iteratif.tpl.html')
})
export class MassiaEssaiIteratifController {
    static $inject = [
        '$scope',
        '$timeout',
        '$translate',
        '_',
        'NatureInputTypeMapping',
        'FormulesService',
        'MesuresService',
        'ColorPickerService',
        '$uibModal'
    ];

    constructor($scope, $timeout, $translate, _, NatureInputTypeMapping, FormulesService, MesuresService, ColorPickerService, $uibModal) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this._ = _;
        this.NatureInputTypeMapping = NatureInputTypeMapping;
        this.FormulesService = FormulesService;
        this.MesuresService = MesuresService;
        this.ColorPickerService = ColorPickerService;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        Object.values(this.field.generiqueAdditionalInformation.essaiLie.sousEssais).map((e) => {
            e.inputCls = 'col-xs-12 col-sm-0';
            return e;
        });

        this.refreshRows();

        await this.refreshContext();

        this.principal =
            Object.values(this.sousMesuresComplet.sousEssais).findIndex((e) => e.code === this.field.generiqueAdditionalInformation.essaiLie.code) >=
            0;

        this.unregister = [];
        // this.unregister.push(this.$scope.$watch(() => this.model, () => {
        //     this.refreshRows();
        // }, true));
        this.unregister.push(
            this.$scope.$watch(
                () => this.sousMesuresComplet.recharge,
                () => {
                    this.refreshRows();
                }
            )
        );

        //relance les calculs sur essais iteratifs
        if (this.onExitMesure) {
            this.onExitMesure();
        }
    }

    $onDestroy() {
        for (let i = 0; i < this.unregister.length; i++) {
            this.unregister[i]();
        }
    }

    getColspan(array) {
        return Object.values(array).filter((e) => e.idNature !== 10 && e.idNature !== 11).length + 1;
    }

    addRow() {
        for (let e in this.field.generiqueAdditionalInformation.essaiLie.sousEssais) {
            const sse = this.field.generiqueAdditionalInformation.essaiLie.sousEssais[e];
            this.model['_' + sse.id].value.push(sse.default || sse.default === 0 ? sse.default : '');
            this.model['_' + sse.id].isNew = true;
        }

        const lastRow = angular.copy(this.rows[this.rows.length - 1]);
        for (let key in lastRow) {
            if (key && lastRow[key]) {
                if (key !== 'data' && key !== 'name' && key !== 'idIteration') {
                    lastRow[key].value = this.model[key].default ? this.model[key].default : '';
                }
            }
        }

        if (lastRow) {
            if (lastRow.name) {
                const indexTiret = lastRow.name.indexOf('-');
                if (indexTiret > 0) {
                    lastRow.name = lastRow.name.substring(0, lastRow.name.indexOf('-')) + '-' + this.rows.length;
                } else {
                    lastRow.name = lastRow.name + '-' + this.rows.length;
                }
            }

            this.rows.push(lastRow);
        } else {
            this.refreshRows();
        }
        this.field.generiqueAdditionalInformation.nbIteration = this.rows.length;
    }

    async deleteRow(index) {
        for (let i in this.field.generiqueAdditionalInformation.essaiLie.sousEssais) {
            const sse = this.field.generiqueAdditionalInformation.essaiLie.sousEssais[i];
            this.model['_' + sse.id].value.splice(index, 1);
        }
        this.rows.splice(index, 1);
        this.field.generiqueAdditionalInformation.nbIteration = this.rows.length;
        await this.refreshContext();
    }

    async refreshContext() {
        const values = {};

        for (let i in this.field.generiqueAdditionalInformation.essaiLie.sousEssais) {
            const sse = this.field.generiqueAdditionalInformation.essaiLie.sousEssais[i];

            values['_' + sse.id] = null;
            if (this.model['_' + sse.id] && this.model['_' + sse.id].value && this.model['_' + sse.id].value.constructor === Array) {
                values['_' + sse.id] = this.model['_' + sse.id].value;
            } else {
                values['_' + sse.id] = [null];
            }
        }
        for (let i in values) {
            this.model[i].value = values[i];
        }

        // if (this.rows.length <= 1) {
        //     this.field.idNature = 10;
        //     this.field.nature = 'EssaiGenerique';
        //     this.field.generiqueAdditionalInformation.isIteratif = false;
        //     this.field.generiqueAdditionalInformation.nbIteration = null;
        //     this.field.nbIteration = 1;
        // }
    }

    async mesureDeleted(sousessai) {
        this.onDeleteMesure({
            mesure: sousessai
        });
    }

    refreshRows() {
        const nbIterations = angular.copy(this.field.generiqueAdditionalInformation.nbIteration);
        const obj = this.field.generiqueAdditionalInformation.essaiLie.sousEssais;
        const sseRef = obj[Object.keys(obj)[0]];
        const nbValeurs = this.field.generiqueAdditionalInformation.nbIteration
            ? this.field.generiqueAdditionalInformation.nbIteration
            : this.model.hasOwnProperty('_' + sseRef.id) &&
              this.model['_' + sseRef.id].value &&
              this.model['_' + sseRef.id].value.constructor === Array
            ? this.model['_' + sseRef.id].value.length
            : 0;

        let iCount = nbValeurs === 0 ? nbIterations : nbValeurs;
        for (let e in this.field.generiqueAdditionalInformation.essaiLie.sousEssais) {
            const sse = this.field.generiqueAdditionalInformation.essaiLie.sousEssais[e];
            if (
                this.model.hasOwnProperty('_' + sse.id) &&
                this.model['_' + sse.id].value &&
                this.model['_' + sse.id].value.constructor === Array &&
                this.model['_' + sse.id].value.length > iCount
            ) {
                iCount = this.model['_' + sse.id].value.length;
            }
        }

        this.rows = [];

        for (let e in this.field.generiqueAdditionalInformation.essaiLie.sousEssais) {
            const sse = this.field.generiqueAdditionalInformation.essaiLie.sousEssais[e];

            for (let i = 0; i < iCount; i++) {
                let model = null;

                if (!this.rows[i]) {
                    model = angular.copy(this.model);
                    model.name = sse.id + '-' + i;
                    // utile pour l'affichage de la conformite des iterations
                    model.idIteration = i;
                    this.rows.push(model);
                }

                if (this.rows[i]) {
                    model = this.rows[i];
                }

                const ssId = `_${sse.id}`;
                const haveValue =
                    model && model[ssId] && model[ssId].value && model[ssId].value[i] !== null && typeof model[ssId].value[i] !== 'undefined';
                model[ssId].value = haveValue ? model[ssId].value[i] : '';
            }
        }
        this.field.generiqueAdditionalInformation.nbIteration = this.rows.length;
    }

    mesureBlured(sousessai) {
        const value = {};
        for (let i = 0; i < this.rows.length; i++) {
            const row = this.rows[i];

            for (let e in this.field.generiqueAdditionalInformation.essaiLie.sousEssais) {
                const sse = this.field.generiqueAdditionalInformation.essaiLie.sousEssais[e];
                if (!this.model['_' + sse.id].value || this.model['_' + sse.id].value.constructor !== Array) {
                    this.model['_' + sse.id].value = [];
                }

                const val = row['_' + sse.id].value;
                this.model['_' + sse.id].value[i] = val;
            }
        }

        this.onExitMesure({
            mesure: sousessai,
            values: value
        });
    }

    async nbIterationChanged() {
        if (
            this.field.generiqueAdditionalInformation.nbIteration >= 1 &&
            this.field.generiqueAdditionalInformation.nbIteration !== this.rows.length
        ) {
            const nbRowToAdd = this.field.generiqueAdditionalInformation.nbIteration - this.rows.length;
            if (nbRowToAdd <= 0) {
                for (let i = Math.abs(nbRowToAdd); i > 0; i--) {
                    await this.deleteRow(this.rows.length - 1);
                }
            } else {
                for (let i = 0; i < nbRowToAdd; i++) {
                    this.addRow();
                }
            }
            for (let i in this.field.generiqueAdditionalInformation.essaiLie.sousEssais) {
                const sousessai = this.field.generiqueAdditionalInformation.essaiLie.sousEssais[i];
                this.mesureBlured(sousessai);
            }
        }
    }

    flatDeep(arr) {
        return arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? this.flatDeep(val) : val), []);
    }
}
