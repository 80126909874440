UnitesSelectController.$inject = ['UnitesService', '_'];

export default function UnitesSelectController(UnitesService, _) {
    /* jshint validthis: true */
    const vm = this;

    // Propriétés
    vm.loading = false;
    vm.unites = [];

    // Méthodes
    vm.selectUnite = selectUnite;
    vm.refreshUnites = refreshUnites;
    vm.filterUnites = filterUnites;

    // Evènements
    vm.$onInit = onInit;

    // Méthodes publiques
    function onInit() {
        vm.refreshUnites();
        vm.excludes = vm.excludes || [];
        vm.clearOnSelect = vm.clearOnSelect || false;
        vm.selected = vm.selected || null;
        vm.disabled = vm.disabled || false;
    }

    function selectUnite(item, model) {
        vm.onSelect ? vm.onSelect({ unite: item }) : (vm.selected = item);

        if (vm.clearOnSelect) {
            vm.selected = undefined;
        }
    }

    async function refreshUnites() {
        let data;

        try {
            if (!vm.type) {
                data = await UnitesService.getUnitesSelect();
            } /*else if (vm.type === 'uCompo') {
                data = await UnitesService.getUniteCompo(vm.idFamille);
            }*/
        } catch (ex) {
            data = [];
        }
        vm.unites = data;
        if (vm.selected && vm.selected.id) {
            vm.selected = _.find(vm.unites, { id: vm.selected.id });
        }
    }

    function filterUnites(item) {
        return !_.find(vm.excludes, { id: item.id });
    }
}
