export default function Configure($stateProvider) {
    $stateProvider.state('factures', {
        parent: 'common',
        url: '/factures',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
        }
    });

    $stateProvider.state('factures.list', {
        url: '/list',
        params: {
            obj: null
        },
        template: '<factures></factures>',
        rights: { domain: 'factures', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "FACTURES.BREADCRUMBS.FACTURES_LIST" | translate}}'
        },
        navigation: {
            menu: 'facturation',
            translate: 'FACTURES.BREADCRUMBS.FACTURES_LIST',
            order: 2
        },
        search: 'FACTURES.BREADCRUMBS.FACTURES_LIST'
    });

    $stateProvider.state('factures.detail', {
        url: '/{id:int}',
        template: '<facture-detail></facture-detail>',
        rights: { domain: 'factures', right: 'read' },
        ncyBreadcrumb: {
            parent: 'factures.list',
            label: '{{ "FACTURES.BREADCRUMBS.FACTURE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('factures.edit', {
        url: '/{id:int}/edit',
        template: '<facture-form></facture-form>',
        rights: { domain: 'factures', right: 'update' },
        ncyBreadcrumb: {
            parent: 'factures.list',
            label: '{{ "FACTURES.BREADCRUMBS.FACTURE_EDIT" | translate }}'
        }
    });

    $stateProvider.state('factures.fromcarnet', {
        url: '/{id:int}/edit/{idCarnet}',
        template: '<facture-form></facture-form>',
        rights: { domain: 'factures', right: 'update' },
        ncyBreadcrumb: {
            parent: 'factures.list',
            label: '{{ "FACTURES.BREADCRUMBS.FACTURE_EDIT" | translate }}'
        }
    });

    $stateProvider.state('factures.duplicate', {
        url: '/{id:int}/duplicate',
        template: '<facture-form></facture-form>',
        rights: { domain: 'factures', right: 'update' },
        params: {
            isDuplicate: true
        },
        ncyBreadcrumb: {
            parent: 'factures.list',
            label: '{{ "FACTURES.BREADCRUMBS.FACTURE_EDIT" | translate }}'
        }
    });

    $stateProvider.state('factures.new', {
        url: '/new',
        template: '<facture-form></facture-form>',
        rights: { domain: 'factures', right: 'create' },
        ncyBreadcrumb: {
            parent: 'factures.list',
            label: '{{ "FACTURES.BREADCRUMBS.FACTURE_NEW" | translate}}'
        },
        search: 'FACTURES.BREADCRUMBS.FACTURE_NEW'
    });

    $stateProvider.state('factures.pont', {
        url: '/pont',
        template: '<factures-pont></factures-pont>',
        rights: { domain: 'pontcomptable', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "FACTURES.BREADCRUMBS.FACTURES_PONT" | translate}}'
        },
        navigation: {
            menu: 'facturation',
            translate: 'FACTURES.BREADCRUMBS.FACTURES_PONT',
            order: 3
        },
        search: 'FACTURES.BREADCRUMBS.FACTURES_PONT'
    });
}

Configure.$inject = ['$stateProvider'];
