LivraisonPlanifieeLivreeController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'LivraisonPlanifieeLivreeService',
    'notification'
];

export default function LivraisonPlanifieeLivreeController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    LivraisonPlanifieeLivreeService,
    notification
) {
    const vm = this;

    let previousTableState;
    const watchers = [];

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.livraisons = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };
    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.loadLivraison = loadLivraison;
    vm.exporter = exporter;
    vm.changeSociete = changeSociete;

    async function init() {
        vm.isFiltrer = true;
        vm.societes = await LivraisonPlanifieeLivreeService.getSocietes();
        vm.chauffeurs = await LivraisonPlanifieeLivreeService.getChauffeurs(vm.idSociete);
        vm.tracteurs = await LivraisonPlanifieeLivreeService.getTracteurs();
        vm.ongletOpen = {
            isFiltreOpen: true
        };
    }

    async function changeSociete() {
        vm.chauffeurs = await LivraisonPlanifieeLivreeService.getChauffeurs(vm.idSociete);
    }

    init();

    async function loadLivraison(tableState) {
        startLoading();

        if ($state && $state.current && $state.current.name !== 'livraison-planifiee-livree.list') {
            $state.go('livraison-planifiee-livree.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.livraisons = [];

        try {
            if (vm.date && vm.idSociete) {
                const data = await LivraisonPlanifieeLivreeService.getLivraisonPlanifieeLivree(
                    filters,
                    sorts,
                    pagination,
                    vm.idSociete,
                    vm.date,
                    vm.idChauffeur,
                    vm.idTracteur
                );
                vm.livraisons = data.items;

                if (previousTableState) {
                    PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
                }
                vm.isFiltrer = true;
                vm.ongletOpen = {
                    isFiltreOpen: false
                };
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function exporter(modeImpression) {
        try {
            const filters = PaginationService.getFilters(previousTableState);
            const sorts = PaginationService.getSorts(previousTableState);
            const fileName = 'Etat_planifiee_livree';
            let resultat = undefined;
            try {
                resultat = await LivraisonPlanifieeLivreeService.exportLivraison(
                    modeImpression,
                    filters,
                    sorts,
                    vm.idSociete,
                    vm.date,
                    vm.idChauffeur,
                    vm.idTracteur
                );
            } catch (ex) {
                //si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
                const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
                notification.error('Livraison.' + msgException);
                return false;
            }
            if (resultat) {
                const data = resultat.data;
                const status = resultat.status;
                let headers = resultat.headers;
                headers = headers();

                const contentType = headers['content-type'];

                const linkElement = document.createElement('a');
                try {
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    if (modeImpression == 0) {
                        linkElement.setAttribute('download', fileName + '.pdf');
                    } else {
                        linkElement.setAttribute('download', fileName + '.xlsx');
                    }

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                } catch (ex) {
                } finally {
                }
                return true;
            }
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
