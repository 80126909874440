import PaiementEntete from './paiement.entete.model';
let unregisterReinit;

export default class PaiementEnteteController {
    static $inject = [
        '$stateParams',
        'PaiementsCommunicationService',
        'PaiementsService',
        'PaiementsTypesService',
        'notification',
        'moment',
        'globalizationManagementService'
    ];

    constructor(
        $stateParams,
        PaiementsCommunicationService,
        PaiementsService,
        PaiementsTypesService,
        notification,
        moment,
        globalizationManagementService
    ) {
        this.$stateParams = $stateParams;
        this.PaiementsCommunicationService = PaiementsCommunicationService;
        this.PaiementsService = PaiementsService;
        this.PaiementsTypesService = PaiementsTypesService;
        this.notification = notification;
        this.loading = false;
        this.codeLoading = false;
        this.isEditMode = false;
        this.showAdvanced = false;
        this.dispJourFinDeMois = false;
        this.moment = moment;

        this.dateFormatage = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    async $onInit() {
        unregisterReinit = this.PaiementsCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        unregisterReinit();
    }

    initForm() {
        const entete = {};
        this.getTypes();
        this.unites = [];
        this.modesCalcul = [];

        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
        } else {
            this.isEditMode = false;
        }
        this.populateForm();
        this.getUnites();
        this.loadModesCalcul();
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.paiement.entete.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.paiement.entete.codeExists = await this.PaiementsService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }
    async getTypes() {
        this.startLoading();
        try {
            this.types = await this.PaiementsTypesService.getPaiementTypes();
            this.hasTypes = this.types.length > 0;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }
    async loadModesCalcul() {
        try {
            this.modesCalcul = await this.PaiementsService.getCalcModes();
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }
    async getUnites() {
        try {
            this.unites = await this.PaiementsService.getCalcUnits();
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    populateForm() {
        this.startLoading();
        if (
            typeof this.paiement.entete.typeId !== 'undefined' &&
            this.paiement.entete.typeId != 1 &&
            this.paiement.entete.typeId != 10 &&
            this.paiement.entete.typeId != 0
        ) {
            this.showAdvanced = true;
            try {
                this.getUnites();
                this.loadModesCalcul();
                this.showJourFinDeMois();
            } catch (ex) {
                this.notification.error(ex.data);
            }
        } else {
            this.showAdvanced = false;
        }
        this.stopLoading();
    }

    showJourFinDeMois() {
        this.startLoading();
        if (this.paiement.entete.modeCalcul == 11 || this.paiement.entete.modeCalcul == 21) {
            this.dispJourFinDeMois = true;
        } else {
            this.dispJourFinDeMois = false;
        }
        this.stopLoading();
    }
    addDays(objetDate, nbToAdd) {
        return new Date(objetDate.setDate(objetDate.getDate() + parseInt(nbToAdd)));
    }
    addMonths(objetDate, nbToAdd) {
        return new Date(objetDate.setMonth(objetDate.getMonth() + nbToAdd));
    }
    derniereDateMois(objetDate) {
        return new Date(objetDate.getFullYear(), objetDate.getMonth() + 1, 0);
    }
    dernierJourMois(objetDate) {
        return new Date(objetDate.getFullYear(), objetDate.getMonth(), 0).getDate();
    }
    AjouteEcheanceSelonUnite(dateFacture, echeance, echeanceUnite) {
        if (echeanceUnite.toLowerCase() == 'j') {
            dateFacture = this.addDays(dateFacture, echeance);
        } else if (echeanceUnite.toLowerCase() == 'm') {
            dateFacture = this.AddMonths(dateFacture, echeance);
        }
        return dateFacture;
    }
    dateFormat(objetDate) {
        const mois = objetDate.getMonth() + 1 < 10 ? '0' + (objetDate.getMonth() + 1) : objetDate.getMonth() + 1;
        return objetDate.getDate() + '/' + mois + '/' + objetDate.getFullYear();
    }
    processDate(date) {
        try {
            const rDate = this.moment(date, this.dateFormatage);
            return rDate;
        } catch (e) {
            this.notification.error(ex.data + 'Vous devez saisir une date de facture');
        }
    }
    async processCalc() {
        const tempPaiementObject = {};
        tempPaiementObject.idModePaiement = null;
        tempPaiementObject.type = this.paiement.entete.typeId;
        tempPaiementObject.echeance = this.paiement.entete.echeance;
        tempPaiementObject.echeanceUnite = this.paiement.entete.echeanceUnite;
        tempPaiementObject.modeCalcul = this.paiement.entete.modeCalcul;
        tempPaiementObject.jourDuMois = this.paiement.entete.jourDuMois;
        tempPaiementObject.dateEcheance = new Date();
        this.paiement.entete.datePaiement = await this.PaiementsService.GetDateSimulated(this.paiement.entete.dateFacture, tempPaiementObject);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
