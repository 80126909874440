const serviceUrl = `${__configuration.apiUrl}/massia/prospects`;

export default class ProspectsService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getProspects(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async deleteProspectById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getProspectEnteteById(id) {
        const url = `${serviceUrl}/${id}/entete`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createProspect(entete) {
        const result = await this.$http.post(serviceUrl, entete);
        return result.data;
    }

    async updateEnteteProspect(entete) {
        const url = `${serviceUrl}/${entete.id}`;
        return this.$http.put(url, entete);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getSocietes() {
        const uniteUrl = `${__configuration.apiUrl}/massia/societes-commerciales`;
        const res = await this.$http.get(uniteUrl);

        return res.data;
    }

    async getPays() {
        const uniteUrl = `${__configuration.apiUrl}/massia/pays`;
        const res = await this.$http.get(uniteUrl);

        return res.data;
    }

    async estCodeAuto() {
        const url = `${serviceUrl}/est-code-auto`;
        try {
            const res = await this.$http.get(url);
            return res.data;
        } catch (err) {
            if (err.status === 404) {
                return false;
            }
            throw err;
        }
    }

    async exportListeProspects(modeImpr, filters, sorts) {
        const url = `${serviceUrl}/print`;
        const res = await this.$http.get(url, {
            params: {
                modeImpr: JSON.stringify(modeImpr),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || [])
            },
            responseType: 'arraybuffer'
        });
        return res;
    }
}
