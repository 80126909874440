import validatorBuilder from 'validator-builder';

let templateImpressionValidator = null;

export default class TemplateImpression {
    constructor(data) {
        data = data || {};
        this.Id = data.id;
        this.Code = data.code;
        this.Libelle = data.libelle;
        this.Drive = data.drive || null;
        this.Directory = data.directory || null;
        this.Extension = data.extension;
        this.IdDomaine = data.idDomaine || null;
        this.DomaineLibelle = data.domaineLibelle || null;
        this.DateCreation = data.dateCreation || null;
        this.Size = data.size;
    }
    isValid() {
        templateImpressionValidator = templateImpressionValidator || validatorBuilder.getInstanceFor('TemplateImpression');
        const validationResults = templateImpressionValidator.validate(this);
        return validationResults.isValid;
    }
}
