import { getNatureNamesByValues } from './../../services/natures.enum';
import Caracteristique from './caracteristique.model';

export default class CaracteristiqueFormController {
    /* @ngInject */
    constructor(
        $scope,
        $translate,
        $state,
        $stateParams,
        $timeout,
        notification,
        DomainesService,
        CaracteristiquesService,
        ModalService,
        RouterHistoryService,
        DocumentsService,
        MassiaApplicationService,
        MOPService
    ) {
        this.$scope = $scope;
        this.$translate = $translate;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$timeout = $timeout;
        this.notification = notification;
        this.DomainesService = DomainesService;
        this.CaracteristiquesService = CaracteristiquesService;
        this.ModalService = ModalService;
        this.RouterHistoryService = RouterHistoryService;

        this.caracteristique = new Caracteristique();
        this.natures = [];
        this.naturesType = getNatureNamesByValues();
        this.exemple = undefined;
        this.isEditMode = null;
        this.MassiaApplicationService = MassiaApplicationService;
        this.MOPService = MOPService;
    }

    async $onInit() {
        this.MOPService.setMop([
            { title: 'CARACTERISTIQUES.BREADCRUMBS.CARACTERISTIQUES_LIST', filename: 'TypesEtCaracteristiques.pdf', application: 'massia' }
        ]);
        this.ongletOpen = {
            isFormOpen: true,
            isHelpOpen: false,
            isNatureOpen: false,
            isDomainesOpen: false
        };
        //this.personnalise = false;
        this.app = this.MassiaApplicationService.getApplication();
        await this.reset();
        this.onPropertyChange(); //mettre à jour le this.personnalise
    }

    $onDestroy() {
        if (this.timer) {
            this.$timeout.cancel(this.timer);
            this.timer = undefined;
        }
        this.MOPService.resetMop();
    }

    async reset() {
        try {
            let data = {};

            if (this.$stateParams.id) {
                this.startLoading();
                data = await this.CaracteristiquesService.getCaracteristique(this.$stateParams.id);
            }

            if (this.$stateParams.duplicate) {
                data.id = undefined;
                data.code += '_copie';
            } else if (!data.id && data.code) {
                this.checkCodeUnicity(data.code);
            }

            this.caracteristique = new Caracteristique(data);

            this.loadNatures();
        } catch (ex) {
            this.notification.error(ex.data);
            this.annuler();
        } finally {
            this.stopLoading();
        }
    }

    async loadNatures() {
        this.natures = [];
        try {
            const natures = this.CaracteristiquesService.getNatures();

            angular.forEach(natures, (type, id) => {
                // si app != qualiB n'afficha pas les types/natures personnalisés
                if (this.app == 'qualib' && (id == 9 || id == 64)) {
                    this.natures.push({
                        idNature: parseInt(id),
                        label: this.$translate.instant(`CARACTERISTIQUES.NATURES.${id}`)
                    });
                } else if (id != 9 && id != 64) {
                    this.natures.push({
                        idNature: parseInt(id),
                        label: this.$translate.instant(`CARACTERISTIQUES.NATURES.${id}`)
                    });
                }
            });
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    onUpdateAdditionalInfo(additionnalInfo) {
        this.caracteristique.additionnalInfo = additionnalInfo;
        this.setExemple();
    }

    onPropertyChange() {
        if (this.caracteristique.idNature == 9 || this.caracteristique.idNature == 64) {
            this.personnalise = true;
            this.caracteristique.domaines = [];
        } else {
            this.personnalise = false;
        }
        this.setExemple();
    }

    setExemple() {
        this.exemple = undefined;

        if (this.caracteristique.idNature >= 0) {
            let content = null;

            if (this.caracteristique.additionnalInfo && this.caracteristique.additionnalInfo.elements) {
                content = angular.copy(this.caracteristique.additionnalInfo.elements.filter((x) => x !== null));
            }

            this.$timeout.cancel(this.timer);
            this.timer = this.$timeout(() => {
                this.exemple = {
                    field: {
                        label: this.caracteristique.libelle,
                        code: 'elements',
                        content: content,
                        elements: content
                    },
                    model: { data: {} }
                };
            });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (!this.RouterHistoryService.back()) {
            if (success) {
                this.annuler();
            }
        }
    }

    async appliquer() {
        this.RouterHistoryService.ignoreNextRoute();
        const id = await this.sauvegarder();

        //if (!this.RouterHistoryService.back()) {//non, valider ne quitte pas
        if (id && this.caracteristique.id) {
            this.reset();
        } else if (id) {
            this.$state.go('caracteristiques.edit', { id: id });
        }
        //}
    }

    async sauvegarder() {
        if (this.caracteristique.isValid()) {
            this.startLoading();
            try {
                let id = false;

                if (this.caracteristique.idTypeAide !== null) {
                    this.verifTypeAide(this.caracteristique.idTypeAide);
                }

                if (this.caracteristique.id) {
                    await this.CaracteristiquesService.updateCaracteristique(this.caracteristique.id, this.caracteristique);
                    this.notification.success(this.$translate.instant('EDITER_SUCCESS', { entity: this.caracteristique.libelle }));
                    id = this.caracteristique.id;
                } else {
                    id = await this.CaracteristiquesService.createCaracteristique(this.caracteristique);
                    this.notification.success(this.$translate.instant('AJOUTER_SUCCESS', { entity: this.caracteristique.libelle }));
                }

                return id;
            } catch (ex) {
                if (ex && ex.data) {
                    this.notification.error(ex.data);
                }
                return false;
            } finally {
                this.stopLoading();
            }
        } else {
            this.$scope.$broadcast('CaracteristiqueValidations');
            this.$scope.$broadcast('AdditionnalInfoValidations');
        }
    }

    deleteDomaine(domaine) {
        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('CARACTERISTIQUES.DOMAINES.DISSOCIER.TITLE', { code: domaine.nom }),
            modalMsg: this.$translate.instant('CARACTERISTIQUES.DOMAINES.DISSOCIER.MESSAGE', { code: this.caracteristique.code }),
            headerClass: 'modal-danger'
        });

        modalInstance.result.then(async () => {
            try {
                const index = _.findIndex(this.caracteristique.domaines, { idDomaine: domaine.idDomaine });
                index >= 0 && this.caracteristique.domaines.splice(index, 1);
                this.notification.success(this.$translate.instant('DISSOCIER_SUCCESS', { entity: domaine.nom }));
            } catch (ex) {
                this.notification.error(ex.data);
            }
        });
    }

    selectDomaine(domaine) {
        this.caracteristique.domaines.push(domaine);
    }

    async checkCodeUnicity(code) {
        this.caracteristique.codeExists = null;
        try {
            if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                this.caracteristique.codeExists = await this.CaracteristiquesService.codeExists(code, this.caracteristique.id);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    annuler() {
        if (!this.RouterHistoryService.back()) {
            if (this.$stateParams.parentState) {
                if (this.$stateParams.parentId) {
                    this.$state.go(this.$stateParams.parentState, { id: this.$stateParams.parentId });
                } else {
                    this.$state.go(this.$stateParams.parentState);
                }
            } else {
                this.$state.go('caracteristiques.list');
            }
        }
    }

    async selectItem(list, data) {
        if (data) {
            this.caracteristique.aideUrl = null;
            this.caracteristique.document = null;
            this.caracteristique.aideToolType = null;

            if (data.id !== this.caracteristique.idTypeAide) {
                this.caracteristique.idTypeAide = data.id;
            } else {
                list = list.map((e) => (e.selected = !e.selected));

                this.caracteristique.idTypeAide = null;
                if (this.caracteristique.aideUrl) {
                    this.caracteristique.aideUrl = null;
                }
                if (this.caracteristique.document) {
                    this.caracteristique.document = null;
                }
                if (this.caracteristique.aideToolType) {
                    this.caracteristique.aideToolType = null;
                }
            }

            if (this.caracteristique.idTypeAide === 3) {
                await this.getDocumentListe();
            } else if (this.caracteristique.idTypeAide === 2) {
                this.caracteristique.aideUrl = 'http://';
            }
        }
    }

    selectDocument(list, data) {
        if (data) {
            if (!this.caracteristique.document || (this.caracteristique.document && data.id !== this.caracteristique.document.id)) {
                this.caracteristique.document = data;
            } else {
                list = list.map((e) => (e.selected = !e.selected));

                if (this.caracteristique.document) {
                    this.caracteristique.document = null;
                }
            }
        }
    }

    async getDocumentListe() {
        try {
            const doc = await this.DocumentsService.getDocuments([], [], { skip: 0, take: 0 });
            if (doc) {
                this.documents = doc.items;
            }
        } catch (ex) {}
    }

    verifTypeAide(id) {
        try {
            switch (id) {
                case 1:
                    if (
                        !this.caracteristique.aideToolType ||
                        this.caracteristique.aideToolType.length === 0 ||
                        this.caracteristique.aideToolType === ''
                    ) {
                        throw this.$translate.instant('CARACTERISTIQUES.TYPE_AIDE.INFO_BULLE');
                    }
                    break;

                case 2:
                    if (
                        !this.caracteristique.aideUrl ||
                        this.caracteristique.aideUrl.length === 0 ||
                        this.caracteristique.aideUrl === '' ||
                        this.caracteristique.aideUrl === 'http://' ||
                        (this.caracteristique.aideUrl && !this.caracteristique.aideUrl.startsWith('http://')) ||
                        (!this.caracteristique.aideUrl.endsWith('.fr') &&
                            !this.caracteristique.aideUrl.endsWith('.com') &&
                            !this.caracteristique.aideUrl.endsWith('.be') &&
                            !this.caracteristique.aideUrl.endsWith('.org'))
                    ) {
                        throw this.$translate.instant('CARACTERISTIQUES.TYPE_AIDE.LIEN');
                    }
                    break;
                case 3:
                    if (!this.caracteristique.document) {
                        throw this.$translate.instant('CARACTERISTIQUES.TYPE_AIDE.DOCUMENT');
                    }
                    break;
                default:
                    break;
            }
        } catch (msg) {
            throw this.notification.error(msg);
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startLoadingExemple() {
        this.exempleLoading = true;
    }

    stopLoadingExemple() {
        this.exempleLoading = false;
    }
}
