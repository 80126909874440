CalculStatistiqueValidator.$inject = ['validator'];

export default function CalculStatistiqueValidator(validator) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance.ruleFor('decimaleSupp').must((val) => Number.isInteger(val.decimaleSupp)).withMessage('VALIDATION_NOT_INTEGER');
    instance.ruleFor('formule').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;
}
