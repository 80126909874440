import SyntheseEntitiesService from '../../../../../../../../../../syntheseentities/services/syntheseentities.service';
import CritereValeur from '../../../../../../../../../models/criteres.valeur.models';

/* @ngInject */
export default class CritrereDateController {
    constructor($scope, moment, SyntheseEntitiesService, notification, MassiaApplicationService) {
        this.m = moment;
        this.$scope = $scope;
        /**
         * @type SyntheseEntitiesService
         */
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.notification = notification;
        this.activite = MassiaApplicationService.getApplication();
    }
    async $onInit() {
        try {
            this.u = this.$scope.$watch(
                () => this.isGammeDate,
                () => {
                    this.changeGammeDate();
                },
                true
            );
            if (!this.critere.modelSelectionCritereValeur[0]) {
                this.critere.modelSelectionCritereValeur[0] = new CritereValeur();
                this.critere.modelSelectionCritereValeur[0].typeRecherche = 21;
            }
            this.isGammeDate = this.critere.modelSelectionCritereValeur[0].typeRecherche === 21;
            this.castStringToDate();
            await this.loadPeriodesGlissantes();
        } catch (err) {
            console.error(err);
        }
    }
    $onDestroy() {
        if (this.u) {
            this.u();
        }
    }

    async loadPeriodesGlissantes() {
        try {
            this.periodesGlissantes = await this.SyntheseEntitiesService.getPeriodesGlissantes(this.activite);
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    changeGammeDate() {
        if (this.isGammeDate) {
            this.critere.modelSelectionCritereValeur[0].typeRecherche = 21;
        }
    }

    castStringToDate() {
        if (this.critere.modelSelectionCritereValeur[0]) {
            if (!this.isGammeDate) {
                this.critere.modelSelectionCritereValeur[0].valeur1 = parseInt(this.critere.modelSelectionCritereValeur[0].valeur1);
            }
        }
    }

    resetDate() {
        this.critere.modelSelectionCritereValeur[0].valeur1 = null;
        this.critere.modelSelectionCritereValeur[0].valeur2 = null;
    }
}
