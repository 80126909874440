import * as angular from 'angular';
import { copy } from 'angular';
import { Injectable } from '../../../core/decorators';

@Injectable('AggregationService')
export class AggregationService {
    /* @ngInject */
    constructor() {}

    private coreAccumulate(aggregation: any, value: any) {
        this.initAggregation(aggregation);
        if (angular.isUndefined(aggregation.stats.accumulator)) {
            aggregation.stats.accumulator = [];
        }
        if (!isNaN(value)) {
            aggregation.stats.accumulator.push(value);
        }
    }

    private initAggregation(aggregation: any) {
        /* To be used in conjunction with the cleanup finalizer */
        if (angular.isUndefined(aggregation.stats)) {
            aggregation.stats = { sum: 0 };
        }
    }

    private increment(obj: any, prop: string) {
        /* if the property on obj is undefined, sets to 1, otherwise increments by one */
        if (angular.isUndefined(obj[prop])) {
            obj[prop] = 1;
        } else {
            obj[prop]++;
        }
    }

    public aggregator() {
        var accumulate = {
            numValue: (aggregation: any, fieldValue: any, numValue: any) => this.coreAccumulate(aggregation, numValue),
            fieldValue: (aggregation: any, fieldValue: any) => this.coreAccumulate(aggregation, fieldValue),
        };
        var mode = (aggregation: any, fieldValue: any) => {
            this.initAggregation(aggregation);
            var thisValue = fieldValue;
            if (angular.isUndefined(thisValue) || thisValue === null) {
                thisValue = aggregation.col.grid.options.groupingNullLabel;
            }
            this.increment(aggregation.stats, thisValue);
            if (aggregation.stats[thisValue] > aggregation.maxCount || angular.isUndefined(aggregation.maxCount)) {
                aggregation.maxCount = aggregation.stats[thisValue];
                aggregation.value = thisValue;
            }
        };
        var sumSquareErr = (aggregation: any, fieldValue: any, numValue: any) => {
            this.initAggregation(aggregation);
            if (!isNaN(numValue)) {
                this.increment(aggregation.stats, 'count');
            }
            aggregation.stats.sum += numValue || 0;
            accumulate.numValue(aggregation, fieldValue, numValue);
        };
        return {
            accumulate,
            mode,
            sumSquareErr,
        };
    }

    public finalizer() {
        const moyWithoutNullValue = (aggregation: any) => {
            aggregation.stats.accumulator.sort();
            var arrLength = aggregation.stats.accumulator.length;
            aggregation.value = aggregation.stats.accumulator.reduce((a: any, b: any) => a + b, 0) / arrLength;
            if (isNaN(aggregation.value)) aggregation.value = '';
            this.cleanup(aggregation);
        };
        const sumSquareErr = (aggregation: any) => {
            aggregation.value = 0;
            if (aggregation.count !== 0) {
                var mean = aggregation.stats.sum / aggregation.stats.count,
                    error;

                angular.forEach(aggregation.stats.accumulator, function (value) {
                    error = value - mean;
                    aggregation.value += error * error;
                });
            }
        };
        const variance = (aggregation: any) => {
            sumSquareErr(aggregation);
            aggregation.value = aggregation.value / aggregation.stats.count;
            this.cleanup(aggregation);
            aggregation.rendered = Math.round(aggregation.value * 100) / 100;
        };
        const varianceP = (aggregation: any) => {
            sumSquareErr(aggregation);
            if (aggregation.count !== 0) {
                aggregation.value = aggregation.value / (aggregation.stats.count - 1);
            }
            this.cleanup(aggregation);
        };
        const stDev = (aggregation: any) => {
            variance(aggregation);
            aggregation.value = Math.sqrt(aggregation.value);
            aggregation.rendered = Math.round(aggregation.value * 100) / 100;
        };
        const stDevP = (aggregation: any) => {
            varianceP(aggregation);
            aggregation.value = Math.sqrt(aggregation.value);
            aggregation.rendered = Math.round(aggregation.value * 100) / 100;
        };

        return {
            moyWithoutNullValue,
            sumSquareErr,
            variance,
            varianceP,
            stDev,
            stDevP,
        };
    }

    cleanup(aggregation: any) {
        delete aggregation.stats;
        if (angular.isUndefined(aggregation.rendered)) {
            aggregation.rendered = aggregation.value;
        }
    }
}
