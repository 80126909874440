const serviceUrl = `${__configuration.apiUrl}/massia/synthAdmin/synthMod`;

export default class SyntheseModelesOldService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
        this.url = `${__configuration.apiUrl}/massia/synthese/setting`;
    }

    async getSyntheseModeles(filters, sorts, pagination, activite) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {}),
                activite: JSON.stringify(activite || null)
            }
        });

        return res.data;
    }

    async getTypeSyntheses(activite) {
        const res = await this.$http.get(`${this.url}/type-synthese`, {
            params: {
                app: activite
            }
        });
        return res.data;
    }

    async deleteSyntheseModeleById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getSyntheseModeleById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createSyntheseModele(selectedSyntheseModele) {
        const res = await this.$http.post(serviceUrl, selectedSyntheseModele);
        return res.data;
    }

    async updateSyntheseModele(selectedSyntheseModele) {
        const url = `${serviceUrl}/${selectedSyntheseModele.id}`;
        return this.$http.put(url, selectedSyntheseModele);
    }

    // pour remplir le iSteven de sélection des critères
    async getCriteresISteven(typeSynthId, domaine, select = false) {
        const url = `${__configuration.apiUrl}/massia/synthese/critere`;
        const res = await this.$http.get(url, {
            params: {
                application: typeSynthId,
                domaine: domaine,
                selection: select
            }
        });
        return res.data;
    }
}
