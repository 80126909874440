export default class Rvmodal {
    /* @ngInject */
    constructor() {}

    $onInit() {}

    $onDestroy() {}

    annuler() {
        this.close({ $value: { isOk: false } });
    }

    ok() {
        this.close({ $value: { isOk: true, betModified: this.resolve.betonsCopie } });
        // A rajouter pour impacter toutes les compos :", editAllCompos: this.editAllCompos"
    }
}
