export default class MainElectronController {
    toto = false;
    constructor($timeout, notification, $translate) {
        this.notification = notification;
        if (this.toto) {
            /* const { ipcRenderer } = require('electron');
            ipcRenderer.send('check-update');
            ipcRenderer.on('block', (ev, msg) => {
                this.block = msg;
            });
            ipcRenderer.on('error', (ev, err) => {
                this.handleElectronError(err);
                console.error(err);
            });
            ipcRenderer.on('config', (ev, err) => {
                $timeout(() => {
                    this.openConfig();
                }, 0);
            }); */
        }
    }

    $onInit() {
        this.config = false;
        if (this.toto) {
            this.checkServer();
        }
    }

    $onDestroy() {}

    checkServer() {
        if (this.toto) {
            const ElectronStore = window.require('electron-store');
            const store = new ElectronStore();
            if (!store.get('server')) {
                return this.notification.error('ELECERROR.NO_SERVER', '', {
                    tapToDismiss: true,
                    onTap: () => this.openConfig(),
                    autoDismiss: false
                });
            }
        }
    }

    openConfig() {
        this.config = true;
    }

    close() {
        this.config = false;
    }

    handleElectronError(err) {
        switch (err.code) {
            case 'ERR_UPDATER_CHANNEL_FILE_NOT_FOUND':
                this.notification.warning('ELECERROR.ERR_UPDATER_CHANNEL_FILE_NOT_FOUND');
                break;

            default:
                break;
        }
    }
}

MainElectronController.$inject = ['$timeout', 'notification', '$translate'];
