import { Directive, HostListener } from '../../../core/decorators';

@Directive({ selector: '[tabVerticalInput]' })
export class TabVerticalInput {
    constructor() {}

    @HostListener('keydown', ['$event'])
    handleTab(e: KeyboardEvent) {
        var code = e.key;
        //Code 9 = Tabulation
        if (code === 'Tab') {
            var focus = $(':focus');
            var td = focus.parent();
            var allTr = td.closest('tbody').children('tr');
            var tri = allTr.index(td.parent('tr'));
            var ntri = tri + 1;
            var allTd = td.parent('tr').children('td');
            var tdi = allTd.index(td);

            if (ntri > allTr.length - 1) {
                ntri = 0;
                tdi++;
            }
            var ntr = $(allTr.get(ntri));
            var ntd = $(ntr.children('td').get(tdi));
            // var l = ntr.children('td').has('input').length; mantis 5951 : la directive ne sappliquer pas sur les 3 dernières colonne JN

            if (ntd.children('input').length > 0) { //(tdi < l + 1) mantis 5951
                e.preventDefault();
                ntd.children('input:first').focus().select();
            }
        }
    }
}
