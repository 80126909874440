import validatorBuilder from 'validator-builder';
import PaysEntete from './entete/pays.entete.model';

let paysValidator = null;

export default class Pays {
    constructor(data = {}) {
        this.id = data.id;
        this.entete = new PaysEntete(data.entete);
    }

    isValid() {
        paysValidator = paysValidator || validatorBuilder.getInstanceFor('Pays');
        const validationResults = paysValidator.validate(this);
        return validationResults.isValid;
    }
}
