import FactureArticlesBlockController from './facture.articles.block.controller';

export default {
    bindings: {
        facture: '=',
        block: '<',
        isOpen: '<',
        index: '<',
        isDuplicate: '='
    },
    template: require('./facture.articles.block.html'),
    controller: FactureArticlesBlockController
};
