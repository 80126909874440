const serviceUrl = `${__configuration.apiUrl}/massia/zones`;
const serviceUrlList = `${__configuration.apiUrl}/massia/zones-list`;

export default class ZonesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getZones(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getZonesSelect(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrlList, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });
        return res.data;
    }

    async deleteZoneById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getZoneById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createZone(selectedZone) {
        const res = await this.$http.post(serviceUrl, selectedZone);
        return res.data;
    }

    async updateZone(selectedZone) {
        const url = `${serviceUrl}/${selectedZone.id}`;
        return this.$http.put(url, selectedZone);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }
}
