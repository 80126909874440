(function (angular, undefined) {
    'use strict';

    /* Exemple d'utilisation
     * ac-text-contrasted="{{<string>}}" // couleur du background en hexadécimal
     */

    angular
        .module('blocks.helpers')
        .directive('acTextContrasted', acTextContrasted);
    acTextContrasted.$inject = [];

    /* @ngInject */
    function acTextContrasted() {
        var directive = {
            link: link,
            restrict: 'A'
        };
        return directive;

        function link(scope, element, attrs) {
            var textColor, bgRGBColor, unObserve;
            var onDestroy = scope.$on('$destroy', dispose);

            init();

            function init() {
                // on observe les changements de l'attribut pour changer la couleur en temps réel
                unObserve = attrs.$observe('acTextContrasted', function (bgColor) {
                    getContrastedText(angular.copy(bgColor));
                });
            }

            function getContrastedText(bgColor) {
                if (bgColor && bgColor.length > 0) {
                    bgRGBColor = getRGBFromHex(bgColor);
                    textColor = colourIsLight(bgRGBColor.red, bgRGBColor.green, bgRGBColor.blue) ? '#000000' : '#ffffff';
                    element[0].style.color = textColor;
                }
            }

            function getRGBFromHex(color) {
                var rgb = {};
                var hex = (color.charAt(0) === '#') ? color.substring(1, 7) : color;
                if (hex.length === 6) {
                    rgb.red = parseInt(hex.substring(0, 2), 16);
                    rgb.green = parseInt(hex.substring(2, 4), 16);
                    rgb.blue = parseInt(hex.substring(4, 6), 16);
                }
                return rgb;
            }

            function colourIsLight(r, g, b) {
                // Counting the perceptive luminance
                // human eye favors green color...
                var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
                return (a < 0.5);
            }

            function dispose() {
                if (unObserve !== undefined) {
                    unObserve();
                }
                if (onDestroy !== undefined) {
                    onDestroy();
                }
            }

        }
    }
})(angular);