ProspectEnteteValidator.$inject = ['validator'];

export default function ProspectEnteteValidator(validator) {
    const instance = new validator();

    instance.ruleFor('code').notEmpty().when(isNotCodeAuto).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 15).when(isNotCodeAuto).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    instance.ruleFor('societeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;

    function isNotCodeAuto(obj, prop) {
        return !obj.estCodeAuto;
    }
}
