export default class MassiaHeaderController {
    static $inject = ['MassiaAuthService', 'MOPService'];

    constructor(MassiaAuthService, MOPService) {
        // Properties
        this.close = false;
        this.MassiaAuthService = MassiaAuthService;
        this.MOPService = MOPService;
    }

    async $onInit() {
        this.open = false;
        this.user = await this.MassiaAuthService.getAuthenticatedUser();
        this.close = this.user.preferences.hideHeader == 'true';

        this.environmentLabel = __configuration.environmentLabel || null;
    }

    openFirstMOP()
    {
        let firstMOP = this.MOPService.currentMop[0];
        this.MOPService.downloadMOPfile(firstMOP.filename, firstMOP.application);
    }

    openCloseHeader() {
        this.close = !this.close;
        this.MassiaAuthService.updatePreferenceUtilisateur(this.user.id, 'hideHeader', this.close);
    }

    toggled(open) {
        console.log('Dropdown is now: ', open);
    }
}
