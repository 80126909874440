import 'jquery-ui';
import 'jquery-ui-sortable-animation';
import localStorageModule from 'angular-local-storage';
import translateModule from 'angular-translate';
import 'angular-translate-interpolation-messageformat';
import 'angular-translate-loader-partial';
import uirouter from '@uirouter/angularjs';
import bluebirdPromiseModule from 'angular-bluebird-promises';
import angularMomentModule from 'angular-moment';
import angularDynamicLocale from 'angular-dynamic-locale';
import angularSanitize from 'angular-sanitize';
import angularAnimate from 'angular-animate';
import 'angular-ui-bootstrap';
import angularToastrModule from 'angular-toastr';
import uiSelect from 'ui-select';
import 'angular-ui-sortable';
import 'moment-duration-format';
import 'angular-breadcrumb';
import 'blocks.context.menu';
import 'blocks.datepicker';
import 'blocks.dragdrop';
import 'blocks.filters';
import 'blocks.helpers';
import 'blocks.i18n';
import 'blocks.loading';
import 'blocks.lodash';
import 'blocks.modal';
import 'blocks.multiselect';
import 'blocks.notification';
import 'blocks.pageslide';
import 'blocks.smart.table';
import 'blocks.treeview';
import 'blocks.validation';
import 'angular-ui-scroll';
import 'angular-ui-tree';
import ngFileUpload from 'ng-file-upload';
import angularChart from 'angular-chart.js';
import rzModule from 'angularjs-slider';
import colorPicker from 'angular-radial-color-picker';
import 'angular-bootstrap-nav-tree';
import 'angular-ui-scroll';
import 'angular-input-masks';
import 'angular-ui-grid/ui-grid';
import 'angular-ui-grid/ui-grid.min.css';
import UiGridColumn from '../libs/blocks.ui.grouping.column/ui-grid-group-columns';
import { httpModule } from './http/http.module';
//import { uiGridOverride } from './_config/ui.grid.override';
import { validatorModule } from './validator/validator.module';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const mName = 'massia.core';
angular.module(mName, [
	localStorageModule,
	bluebirdPromiseModule,
	translateModule,
	angularMomentModule,
	angularDynamicLocale,
	angularAnimate,
	angularSanitize,
	angularToastrModule,
	colorPicker,
	'ui.bootstrap',
	uirouter,
	uiSelect,
	'ui.utils.masks',
	'ui.scroll',
	'ui.sortable',
	'ui.grid',
	'ui.grid.grouping',
	'ui.grid.treeView',
	'ui.grid.treeBase',
	'ui.grid.resizeColumns',
	'ui.grid.moveColumns',
	'ui.grid.emptyBaseLayer',
	'ui.grid.autoResize',
	'ui.grid.pinning',
	'ui.grid.exporter',
	'ui.grid.edit',
	'ui.grid.saveState',
	UiGridColumn.name,
	'ncy-angular-breadcrumb',
	// blocks
	'blocks.context.menu',
	'blocks.datepicker',
	'blocks.dragdrop',
	'blocks.filters',
	'blocks.helpers',
	'blocks.i18n',
	'blocks.loading',
	'blocks.lodash',
	'blocks.modal',
	'blocks.multiselect',
	'blocks.notification',
	'blocks.pageslide',
	'blocks.smart.table',
	'blocks.treeview',
	'blocks.validation',
	ngFileUpload,
	'ang-drag-drop',
	angularChart,
	rzModule,
	//textAngularModule,
	'angularBootstrapNavTree',
	'ui.scroll',
	'ui.tree',
	httpModule,
	validatorModule
]);
//.run(uiGridOverride);
export default mName;
