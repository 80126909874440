export default class ProduitFormProducteursValeurReferenceCubeModal {
    /* @ngInject */
    constructor() {}

    $onInit() {
        this.isArchive = false;
    }

    $onDestroy() {}

    annuler() {
        this.close({ $value: { isArchive: this.isArchive, isOk: false } });
    }

    ok() {
        this.close({ $value: { isArchive: this.isArchive, isOk: true } });
    }
}
