const serviceUrl = `${__configuration.apiUrl}/massia/normes`;

export default class NormesService {
    static $inject = ['$http'];

    constructor($http, TypesNiveauxHierarchique) {
        this.$http = $http;
        this.TypesNiveauxHierarchique = TypesNiveauxHierarchique;
    }

    async getNormes(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async deleteNormeById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getNormeById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createNorme(norme) {
        const result = await this.$http.post(serviceUrl, norme);
        return result.data;
    }

    async updateNorme(norme) {
        const data = norme;

        const url = `${serviceUrl}/${norme.id}`;
        return await this.$http.put(url, data);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getmodeCalculET() {
        const url = 'assets/enum/normes/modeCalculET.json';
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSeriesTamis() {
        const url = `${__configuration.apiUrl}/massia/seriestamis/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSpecifications(idNorme) {
        const url = `${__configuration.apiUrl}/massia/normes/${idNorme}/specifications`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async saveSpecifications(idNorme, specifications) {
        const url = `${__configuration.apiUrl}/massia/normes/${idNorme}/specifications`;
        const res = await this.$http.post(url, specifications);
        return res.data;
    }

    async getCategories(idNorme) {
        const url = `${__configuration.apiUrl}/massia/normes/${idNorme}/categories`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async deleteCategorieById(idNorme, id) {
        const url = `${__configuration.apiUrl}/massia/normes/${idNorme}/categories/${id}`;
        return this.$http.delete(url);
    }

    async createOrUpdateCategorie(idNorme, categorie) {
        let url = `${__configuration.apiUrl}/massia/normes/${idNorme}/categories`;

        if (categorie.id > 0) {
            url = `${__configuration.apiUrl}/massia/normes/${idNorme}/categories/${categorie.id}`;
            await this.$http.put(url, categorie);
            return categorie.id;
        }
        const cat = await this.$http.post(url, categorie);
        return cat.data;
    }

    async getEssaisList(domaine, idFamille) {
        const url = `${__configuration.apiUrl}/massia/normes/essais/${domaine}/${idFamille}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getNormeSpecList(famille) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/normes-specifiques/${famille}`); //this.$http.get("assets/enum/normes/normeSpecifique.json");
        return res.data;
    }
}
