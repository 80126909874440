(function (angular, globalHelpers, undefined) {
    'use strict';

    angular
        .module('blocks.modal')
        .directive('acModalDrag', acModalDrag);
    acModalDrag.$inject = ['$document'];

    /**
     * Afin d'utiliser cette directive,
     * il suffit de mettre ac-modal-drag="true"
     * ou n'importe quelle variable qui s'initialise à true ac-modal-drag="{{ctrl.canResize}}"
     * sur l'élément que l'on souhaite utiliser en tant que draggable dans une modale
     */
    function acModalDrag($document) {
        var directive = {
            link: link,
            restrict: 'A'
        };
        return directive;

        function link(scope, element, attrs) {
            var startX, startY, x, y, modal, modalEl, modalDialog, modalDialogEl;
            var registeredEvents;

            if (attrs.acModalDrag === 'true') {
                registeredEvents = [
                    scope.$on('$destroy', dispose)
                ];

                init();
            }

            function init() {
                initValues();
                modal = globalHelpers.findAncestorByClassName(element[0], 'modal');
                modalDialog = globalHelpers.findAncestorByClassName(element[0], 'modal-dialog');
                if (modal && modalDialog) {
                    modalEl = angular.element(modal);
                    modalDialogEl = angular.element(modalDialog);

                    element[0].style.cursor = 'move';

                    element.on('mousedown', initializeDrag);
                    element.on('dblclick', resetPosition);

                }
            }

            function initValues() {
                startX = startY = x = y = 0;
            }

            function initializeDrag(event) {
                // Prevent default dragging of selected content
                event.preventDefault();
                modalEl[0].style.cursor = 'move';

                startX = event.screenX - x;
                startY = event.screenY - y;
                modalEl.on('mousemove', mousemove);
                $document.on('mouseup', mouseup);
            }


            function resetPosition() {
                initValues();
                // on replace la modale à son point de départ
                modalDialogEl.css({
                    top: 0,
                    left: 0
                });
            }

            function mousemove(event) {
                y = event.screenY - startY;
                x = event.screenX - startX;
                modalDialogEl.css({
                    top: y + 'px',
                    left: x + 'px'
                });
            }

            function mouseup() {
                modalEl[0].style.cursor = 'default';
                modalEl.unbind('mousemove', mousemove);
                $document.unbind('mouseup', mouseup);
            }

            function dispose() {
                initValues();
                angular.forEach(registeredEvents, function (unregisterEvent) {
                    unregisterEvent();
                });

                if (modalEl) {
                    modalEl.unbind('mousemove', mousemove);
                }

                modalEl[0].style.cursor = 'default';

                $document.unbind('mouseup', mouseup);
                element.unbind('mousedown', initializeDrag);
                element.unbind('dblclick', resetPosition);
            }
        }
    }
})(angular, window.globalHelpers);