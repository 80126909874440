export default class SettingMailController {
    constructor($scope, SettingPortailService) {
        this.$scope = $scope;
        this.SettingPortailService = SettingPortailService;
    }
    $onInit() {
        this.parseInt = parseInt;
    }
    $onDestroy() {}
}

SettingMailController.$inject = ['$scope', 'SettingPortailService'];
