export default class GammesProduitsController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        '$translate',
        'PaginationService',
        'GammesProduitsService',
        'notification',
        'ModalService',
        '$transitions',
        'MOPService'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        $translate,
        PaginationService,
        GammesProduitsService,
        notification,
        ModalService,
        $transitions,
        MOPService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.PaginationService = PaginationService;
        this.GammesProduitsService = GammesProduitsService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$transitions = $transitions;
        this.MOPService = MOPService;
    }

    async $onInit() {
        this.MOPService.setMop([
            { title: 'GAMMES_PRODUITS.BREADCRUMBS.GAMME_PRODUITS_LIST', filename: 'GammeDeProduits.pdf', application: 'laboratoire' }
        ]);
        this.gammesProduits = [];
        this.selectedId = undefined;
        this.lastSelected = undefined;

        this.loading = false;
        this.params = this.$stateParams;

        this.slideWidth = '500px';
        this.slideMargin = {
            'margin-right': '0px',
            'transition-duration': '0.5s',
            'transition-animation': 'margin-right'
        };

        this.itemsByPageOptions = [20, 100, 200];

        this.watchers = [];
        this.$transitions.onSuccess({}, this.stateChangeSuccessFunc);
        //this.watchers.push(this.$scope.$on('$stateChangeSuccess', this.stateChangeSuccessFunc));

        if (this.shouldSetSelectedId()) {
            this.setSelected({ id: parseInt(this.$state.params.id) });
        }

        this.initHeaders();
    }

    $onDestroy = () => {
        this.MOPService.resetMop();
    };

    initHeaders() {
        this.gammesProduitsHeaders = [
            {
                title: 'GAMMES_PRODUITS.CODE',
                options: {
                    sort: true,
                    filter: true,
                    filterType: 1,
                    default: true
                },
                property: 'code'
            },
            {
                title: 'GAMMES_PRODUITS.LIBELLE',
                options: {
                    sort: true,
                    filter: true,
                    filterType: 1
                },
                property: 'libelle'
            },
            {
                title: 'GAMMES_PRODUITS.FAMILLE',
                options: {
                    sort: true,
                    filter: true,
                    filterType: 1
                },
                property: 'familleLibelle'
            },
            {
                title: 'GAMMES_PRODUITS.ACTIONS',
                class: 'width-5p',
                button: true,
                icon: 'glyphicon-refresh'
            }
        ];

        this.gammesProduitsButton = [
            {
                tooltip: 'GAMMES_PRODUITS.SHOW',
                callback: this.show,
                icon: 'glyphicon-search',
                right: 'read'
            },
            {
                tooltip: 'GAMMES_PRODUITS.EDIT',
                callback: this.edit,
                icon: 'glyphicon-pencil',
                right: 'update'
            },
            {
                tooltip: 'GAMMES_PRODUITS.DELETE.TITLE',
                callback: this.delete,
                icon: 'glyphicon-trash',
                right: 'delete'
            }
        ];
    }

    setSelected(gammeProduit) {
        if (gammeProduit && gammeProduit.id) {
            this.selectedId = this.$stateParams.id;
            this.lastSelected = this.selectedId;
            this.slideMargin['margin-right'] = this.slideWidth;
        } else {
            this.selectedId = undefined;
            this.slideMargin['margin-right'] = '0px';
        }
        //console.log(gammeProduit);
    }

    shouldSetSelectedId() {
        return (
            this.$state &&
            this.$state.current &&
            this.$state.current.libelle &&
            this.$state.current.libelle === 'gammes-produits.list.detail' &&
            this.$stateParams &&
            this.$stateParams.id
        );
    }

    stateChangeSuccessFunc = (event, toState) => {
        if (!this.$stateParams.id) {
            this.setSelected();
        } else if (this.$stateParams.id) {
            this.setSelected({ id: parseInt(this.$stateParams.id) });
        }
    };

    selectGammeProduit(gammeProduit) {
        if (gammeProduit && gammeProduit.id) {
            if (this.selectedId !== gammeProduit.id) {
                this.selectedId = gammeProduit.id;
                this.show(gammeProduit.id);
            } else {
                this.selectedId = undefined;
                this.closeDetail();
            }
        }
    }

    isGammeProduitSelected(gammeProduit) {
        return gammeProduit && gammeProduit.id && this.selectedId === gammeProduit.id;
    }

    /* Arrow fx for bind */
    loadGammesProduits = async (tableState) => {
        const that = this;
        /* if (!this.startLoading)
            that = this.$parent.$parent.$ctrl; */

        this.startLoading();
        if (tableState) {
            this.previousTableState = tableState;
        }

        const filters = this.PaginationService.getFilters(this.previousTableState);
        const sorts = this.PaginationService.getSorts(this.previousTableState);
        const pagination = this.PaginationService.getPagination(this.previousTableState);
        try {
            const data = await this.GammesProduitsService.getGammesProduits(filters, sorts, pagination);
            this.gammesProduits = data.items;

            if (this.previousTableState) {
                this.PaginationService.setTableState(data.skip, data.take, data.total, this.previousTableState);
            }
            this.stopLoading();
        } catch (err) {
            this.notification.error(err.data);
            this.stopLoading();
        }
    };

    async show(data, _this) {
        const that = _this.$parent.$parent.$parent.$ctrl;
        that.$state.go('gammes-produits.list.detail', { id: data.id, gamme: data });
    }

    async edit(data, _this) {
        const that = _this.$parent.$parent.$parent.$ctrl;
        that.$state.go('gammes-produits.edit', { id: data.id, gamme: data });
    }

    async delete(data, _this) {
        const that = _this.$parent.$parent.$parent.$ctrl;

        if (data && data.id) {
            const modalInstance = that.ModalService.confirm({
                modalTitle: that.$translate.instant('GAMMES_PRODUITS.DELETE.TITLE', { libelle: data.libelle }),
                modalMsg: that.$translate.instant('GAMMES_PRODUITS.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                that.startLoading();
                try {
                    await that.GammesProduitsService.deleteGammeProduitById(data.id);
                    that.notification.success(that.$translate.instant('GAMMES_PRODUITS.DELETE.SUCCESS'));

                    const famIndex = that.gammesProduits.findIndex(function (e) {
                        return e.id === data.id;
                    });
                    that.gammesProduits.splice(famIndex, 1);
                } catch (ex) {
                    that.notification.error(ex.data);
                } finally {
                    that.stopLoading();
                }
            });
        }
    }

    eraseAllFilters() {
        this.loadGammesProduits();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
