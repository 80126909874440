import validatorBuilder from 'validator-builder';

let documentEnteteValidator = null;

export default class DocumentEntete {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.typeId = data.typeId;
        this.source = data.source;
        this.taille = data.taille;
        this.fileName = data.fileName;
        this.niveauVisibilite = 0;
    }

    isValid() {
        documentEnteteValidator = documentEnteteValidator || validatorBuilder.getInstanceFor('DocumentEntete');
        const validationResults = documentEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
