import validatorBuilder from 'validator-builder';

let valorisationStocksFiltresValidator = null;

export default class ValorisationStocksFiltres {
    constructor(data) {
        data = data || {};
        this.idSite = data.idSite || null;
        //this.date = data.date || null;
        this.eltNiv1 = data.eltNiv1 || null;
        this.eltNiv2 = data.eltNiv2 || null;
        this.eltNiv3 = data.eltNiv3 || null;
        this.annee = data.annee || null;
        this.idPeriode = data.idPeriode || null;
    }

    isValid() {
        valorisationStocksFiltresValidator = valorisationStocksFiltresValidator || validatorBuilder.getInstanceFor('ValorisationStocksFiltres');
        const validationResults = valorisationStocksFiltresValidator.validate(this);
        return validationResults.isValid;
    }
}
