SiteEnteteValidator.$inject = ['validator'];

export default function SiteEnteteValidator(validator) {
    const instance = new validator();

    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 15).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance
        .ruleFor('code')
        .matches(/^[a-zA-Z0-9_|]*$/)
        .withMessage('CODE_CHARACTERS_NOK');

    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    instance.ruleFor('codeExists').notEqual(true).withMessage('VALIDATION_NOTEMPTY');

    /*instance.ruleFor('typeUtilisateurId').notEmpty().when(isUtilisateur).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeClientId').notEmpty().when(isClient).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeFournisseurId').notEmpty().when(isFournisseur).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeProducteurId').notEmpty().when(isProducteur).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeTransporteurId').notEmpty().when(isTransporteur).withMessage('VALIDATION_NOTEMPTY');*/

    instance.ruleFor('typeUtilisateurId').greaterThan(-1).when(isUtilisateur).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeClientId').greaterThan(-1).when(isClient).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeFournisseurId').greaterThan(-1).when(isFournisseur).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeProducteurId').greaterThan(-1).when(isProducteur).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeTransporteurId').greaterThan(-1).when(isTransporteur).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeLaboratoireId').greaterThan(-1).when(isLaboratoire).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeProprietaireId').greaterThan(-1).when(isProprietaire).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeLocataireId').greaterThan(-1).when(isLocataire).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeAuditeurId').greaterThan(-1).when(isAuditeur).withMessage('VALIDATION_NOTEMPTY');

    instance.ruleFor('societeId').greaterThan(-1).when(mandatorySociete).withMessage('VALIDATION_NOTEMPTY');

    instance.ruleFor('paysId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    // instance.ruleFor('lieuId').notEqual(-1).when(isUtilisateur).withMessage('VALIDATION_NOTEMPTY');

    return instance;

    function isClient(obj, prop) {
        return obj.isClient;
    }

    function isFournisseur(obj, prop) {
        return obj.isFournisseur;
    }

    function isProducteur(obj, prop) {
        return obj.isProducteur;
    }

    function isTransporteur(obj, prop) {
        return obj.isTransporteur;
    }

    function isUtilisateur(obj, prop) {
        return obj.isUtilisateur;
    }

    function isLaboratoire(obj, prop) {
        return obj.isLaboratoire;
    }

    function isProprietaire(obj, prop) {
        return obj.isProprietaire;
    }

    function isLocataire(obj, prop) {
        return obj.isLocataire;
    }

    function isAuditeur(obj, prop) {
        return obj.isAuditeur;
    }

    function mandatorySociete(obj, prop) {
        if (obj.appName == 'laboratoire' && obj.isLaboratoire) {
            return true;
        } else if (obj.appName == 'gestion' && obj.isUtilisateur) {
            return true;
        } else if (obj.appName == 'performance' && obj.isProducteur) {
            return true;
        } else if (obj.appName == 'qse' && obj.isAuditeur) {
            return true;
        }

        return false;
    }
}
