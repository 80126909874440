import validatorBuilder from 'validator-builder';

let factureRecapValidator = null;

export default class FactureRecapitulatif {
    constructor(data) {
        data = data || {};
        this.denomination = data.denomination;
        this.id = data.id;
        this.montantHT = data.montantHT;
        this.montantTTC = data.montantTTC;
        this.montantTVA = data.montantTVA;
    }

    isValid() {
        factureRecapValidator = factureRecapValidator || validatorBuilder.getInstanceFor('FactureRecapitulatif');
        const validationResults = factureRecapValidator.validate(this);
        return validationResults.isValid;
    }
}
