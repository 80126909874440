const stylesFuseauByValue = {
    0: 'aucun',
    1: 'rempli'
};

const stylesFuseauValuesByName = {
    aucun: 0,
    rempli: 1
};

export function getStyleFuseauNamesByValues() {
    return angular.copy(stylesFuseauByValue);
}

export function getStyleFuseauNamesWithValues() {
    return angular.copy(stylesFuseauValuesByName);
}
