import PrelevementCarteController from './prelevement.carte.controller';

export default {
    bindings: {
        entete: '=',
        modeLocalisation: '<',
        modalInstance: '='
    },
    template: require('./prelevement.carte.html'),
    controller: PrelevementCarteController
};
