import TypeBasculesConfig from './_config/type.bascules.config';
import TypeBasculesRoutes from './_config/type.bascules.routes';
import TypeBasculesComponent from './components/type-bascules';
import TypeBasculesService from './services/type.bascules.service';
import PariteTypeBasculesService from './services/parite.type.bascules.service';
import TypeBasculesCommunicationService from './services/type.bascules.communication.service';

import TypeBasculeFormComponent from './components/type-bascules-form';
import TypeBasculeDetailComponent from './components/type-bascules-detail';

import TypeBasculeValidator from './components/type-bascules-form/type.bascules.validator';

const moduleName = 'app.massia.gestion.type.bascules';

angular
    .module(moduleName, [])
    .config(TypeBasculesConfig)
    .config(TypeBasculesRoutes)
    .service('TypeBasculesService', TypeBasculesService)
    .service('PariteTypeBasculesService', PariteTypeBasculesService)
    .service('TypeBasculesCommunicationService', TypeBasculesCommunicationService)
    .component('typeBascules', TypeBasculesComponent)
    .component('typeBasculesForm', TypeBasculeFormComponent)
    .component('typeBasculesDetail', TypeBasculeDetailComponent)
    .factory('TypeBasculeValidator', TypeBasculeValidator);

export default moduleName;
