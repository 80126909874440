/* @ngInject */
export default class BlocSelectionService {
    constructor(ApiService) {
        this.api = ApiService;
    }

    getAll(idSynthese) {
        let params = {};
        if (idSynthese >= 0) {
            params = {
                params: {
                    type: idSynthese
                }
            };
        }
        return this.api.get('massia/synthese/bloc-selection', params);
    }

    getOne(idBloc, idSynthese) {
        let params = {};
        idSynthese = idSynthese > 0 ? idSynthese : null;
        params = {
            params: {
                synthese: idSynthese
            }
        };
        return this.api.get(`massia/synthese/bloc-selection/${idBloc}`, params);
    }
}
