export default function Configure($stateProvider) {
    $stateProvider.state('provenances', {
        parent: 'common',
        url: '/provenances',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });
    $stateProvider.state('provenances.list', {
        url: '/list',
        template: '<chantiers></chantiers>',
        rights: { domain: 'chantiers', right: 'read' },
        ncyBreadcrumb: {
            parent: 'provenances',
            label: '{{ "CHANTIERS.BREADCRUMBS.PROVENANCE_LIST" | translate}}'
        }
    });
    $stateProvider.state('provenances.list.detail', {
        url: '/{id}',
        params: {
            genre: 'provenances'
        },
        template: '<chantier-detail></chantier-detail>',
        rights: { domain: 'chantiers', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher provenances > détail
            parent: 'provenances.list',
            label: '{{ "CHANTIERS.BREADCRUMBS.PROVENANCE_DETAIL" | translate }}'
        }
    });
    $stateProvider.state('provenances.new', {
        url: '/new',
        params: {
            genre: 'provenances'
        },
        template: '<chantier-form></chantier-form>',
        rights: { domain: 'chantiers', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher provenances > ajouter
            parent: 'provenances.list',
            label: '{{ "CHANTIERS.BREADCRUMBS.PROVENANCE_NEW" | translate}}'
        },
        // l'ajout d'un provenance fait partie des points d'entrée recherchables dans l'application
        search: 'CHANTIERS.BREADCRUMBS.PROVENANCE_NEW'
    });
    $stateProvider.state('provenances.edit', {
        url: '/{id}/edit',
        params: {
            genre: 'provenances'
        },
        template: '<chantier-form></chantier-form>',
        rights: { domain: 'chantiers', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher provenances > modifier
            parent: 'provenances.list',
            label: '{{ "CHANTIERS.BREADCRUMBS.PROVENANCE_EDIT" | translate }}'
        }
    });
}
Configure.$inject = ['$stateProvider'];
