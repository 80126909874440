(function (angular, undefined) {
    'use strict';

    angular.module('blocks.filters').filter('TruncateEllipsis', TruncateEllipsis);
    TruncateEllipsis.$inject = ['domHelper'];

    function TruncateEllipsis(domHelper) {
        return domHelper.truncateEllipsis;
    }

    angular.module('blocks.filters').filter('TruncateEllipsisSmallScreen', TruncateEllipsisSmallScreen);
    TruncateEllipsisSmallScreen.$inject = ['domHelper', '$window'];

    function TruncateEllipsisSmallScreen(domHelper, $window) {
        return function (value, max, wordwise, tail) {
            if ($window.innerWidth < 1575) {
                return domHelper.truncateEllipsis(value, max, wordwise, tail);
            }
            else {
                return value;
            }
        };
    }

})(angular);

