export default function Configure($stateProvider) {
    $stateProvider.state('themeslabo', {
        parent: 'common',
        url: '/themeslabo',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('themeslabo.list', {
        url: '/list',
        template: '<themes></themes>',
        rights: { domain: 'themeslabo', right: 'read', application: 'laboratoire' },
        ncyBreadcrumb: {
            parent: 'themeslabo',
            label: '{{ "THEMES.BREADCRUMBS.THEMES_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'THEMES.BREADCRUMBS.THEMES_LIST',
            order: 2
        },
        search: 'THEMES.BREADCRUMBS.THEMES_LIST'
    });

    $stateProvider.state('themeslabo.new', {
        url: '/new',
        template: '<theme-form></theme-form>',
        rights: { domain: 'themeslabo', right: 'create' },
        ncyBreadcrumb: {
            parent: 'themeslabo.list',
            label: '{{ "THEMES.BREADCRUMBS.THEME_NEW" | translate}}'
        },
        search: 'THEMES.BREADCRUMBS.THEME_NEW'
    });

    $stateProvider.state('themeslabo.edit', {
        url: '/{id}/edit',
        template: '<theme-form></theme-form>',
        rights: { domain: 'themeslabo', right: 'update' },
        ncyBreadcrumb: {
            parent: 'themeslabo.list',
            label: '{{ "THEMES.BREADCRUMBS.THEME_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
