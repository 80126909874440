import PlanificationJobPrefacturationsConfig from './_config/planification.job.prefacturations.config';
import PlanificationJobPrefacturationsRoutes from './_config/planification.job.prefacturations.routes';
import PlanificationJobPrefacturationsComponent from './components/planification-job-prefacturations';
import PlanificationJobPrefacturationsService from './services/planification.job.prefacturations.service';

const moduleName = 'app.massia.gestion.planification.job.prefacturations';

angular
    .module(moduleName, [])
    .config(PlanificationJobPrefacturationsConfig)
    .config(PlanificationJobPrefacturationsRoutes)
    .service('PlanificationJobPrefacturationsService', PlanificationJobPrefacturationsService)
    .component('planificationJobPrefacturations', PlanificationJobPrefacturationsComponent);

export default moduleName;
