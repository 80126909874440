(function () {
    'use strict';

    /* Exemple d'utilisation
     * <span ac-checkbox="[bool] ctrl.test"
     *       ac-checkbox-on-change="[function] ex: ctrl.onTestChange()"
     *       ac-checkbox-label="[string]" (le string est traduit le cas échéant)
     *       ac-checkbox-disabled="[bool]"
     * ></span>
     */

    angular.module('blocks.helpers')
        .directive('acCheckbox', acCheckbox);

    function acCheckbox() {

        return {
            restrict: 'A',
            scope: {
                acCheckbox: '=?',
                acCheckboxOnChange: '&?',
                acCheckboxLabel: '@?',
                acCheckboxLabelValues: '@?',
                acCheckboxDisabled: '=?'
            },
            template: '<span class="ac-checkbox" ng-class="{\'pointer\': !acCheckboxDisabled, \'ac-checkbox-disabled\': acCheckboxDisabled}" ng-click="checkUncheck()">' +
            '   <span class="glyphicon"' +
            '       ng-class="{\'glyphicon-check\': acCheckbox, \'glyphicon-unchecked\': !acCheckbox}"' +
            '   ></span>' +
            '   <span class="margin-0-5" ng-show="acCheckboxLabel" translate="{{acCheckboxLabel}}" translate-values="{{acCheckboxLabelValues}}"></span>' +
            '</span>',
            controller: acCheckboxController
        };
    }

    acCheckboxController.$inject = ['$scope'];
    function acCheckboxController($scope) {
        $scope.checkUncheck = checkUncheck;

        if ($scope.acCheckbox === undefined) {
            $scope.acCheckbox = false;
        }

        function checkUncheck() {
            if (!$scope.acCheckboxDisabled) {
                $scope.acCheckbox = !$scope.acCheckbox;

                var onChange = angular.isFunction($scope.acCheckboxOnChange) ? $scope.acCheckboxOnChange() : undefined;

                if (angular.isFunction(onChange)) {
                    onChange($scope.acCheckbox);
                }
            }
        }
    }
})();