import Paiement from './paiement.model';
import PaiementEntete from '../paiement-entete/paiement.entete.model';

export default class PaiementFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'PaiementsCommunicationService',
        'PaiementsService',
        'notification',
        '$location',
        '$anchorScroll',
        'MOPService'
    ];

    constructor($scope, $state, $stateParams, PaiementsCommunicationService, PaiementsService, notification, $location, $anchorScroll, MOPService) {
        this.$scope = $scope;
        this.$state = $state;
        this.params = $stateParams;
        this.PaiementsCommunicationService = PaiementsCommunicationService;
        this.PaiementsService = PaiementsService;
        this.notification = notification;
        this.isEditMode = false;
        this.paiement = {};
        this.loading = false;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.MOPService = MOPService;
    }

    $onInit() {
        this.MOPService.setMop([{ title: 'PAIEMENTS.BREADCRUMBS.PAIEMENTS_LIST', filename: 'ModesPaiement.pdf', application: 'gestion' }]);
        this.setPaiement(this.params.id);
    }

    $onDestroy = () => {
        this.MOPService.resetMop();
    };

    async setPaiement(paiementId) {
        if (paiementId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                const entete = await this.PaiementsService.getPaiementEnteteById(paiementId);
                this.paiement.entete = new PaiementEntete(entete);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('paiements.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.paiement = new Paiement();
        }
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                let id = false;
                if (this.paiement.entete.id) {
                    this.updatePaiement();
                    this.notification.success('PAIEMENTS.UPDATED');
                    id = this.paiement.entete.id;
                } else {
                    id = await this.createPaiement();
                    this.notification.success('PAIEMENTS.CREATED');
                }
                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async valider() {
        const returnedId = await this.sauvegarder();
        if (returnedId) {
            this.$state.go('paiements.edit', { id: returnedId });
        }
    }

    async validerEtFermer() {
        const success = await this.sauvegarder();
        if (success) {
            this.backToPreviousState();
        }
    }
    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('paiements.list');
    }

    reinit() {
        this.setPaiement(this.params.id);
        this.PaiementsCommunicationService.raiseReinit();
    }

    checkValidity() {
        let validity = true;

        if (!this.paiement.entete.isValid()) {
            validity = false;
            this.$scope.$broadcast('paiementEnteteValidations');
        }

        return validity;
    }

    async createPaiement() {
        this.startLoading();
        this.paiement.entete = new PaiementEntete(this.paiement.entete);
        try {
            const returnedId = await this.PaiementsService.createPaiement(this.paiement.entete);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updatePaiement() {
        this.startLoading();
        this.paiement.entete = new PaiementEntete(this.paiement.entete);
        try {
            const id = await this.PaiementsService.updatePaiement(this.paiement.entete);
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
