import validatorBuilder from 'validator-builder';
import MesureEntete from './entete/mesure.entete.model';
import MesureCaracteristiques from './caracteristiques/mesure.caracteristiques.model';
import MesureSousMesures from './sous-mesures/mesure.sous.mesures.model';
import MesureCourbeGranulo from './courbe-granulo/mesure.courbe.granulo.model';

let mesureValidator = null;

export default class Mesure {
    constructor(data = {}) {
        this.id = data.entete ? data.entete.idEssai : undefined;
        this.entete = new MesureEntete(data.entete);
        this.sousMesures = data.sousMesures || []; //new MesureSousMesures(data.sousMesures);
        this.caracteristiques = new MesureCaracteristiques(data.caracteristiques);
        this.courbeGranulo = new MesureCourbeGranulo(data.courbeGranulo);
        this.typesProduits = data.typesProduits || [];
        this.config = {
            enteteExcel: null,
            configExcel: null,
            configToRemove: null
        };
        this.idTypeAide = data.idTypeAide || null;
        this.aideUrl = data.aideUrl || null;
        this.aideToolType = data.aideToolType || null;
        this.document = data.document || null;
    }

    isValid() {
        mesureValidator = mesureValidator || validatorBuilder.getInstanceFor('Mesure');
        const validationResults = mesureValidator.validate(this);
        return validationResults.isValid;
    }
}
