import AffectationPostePeseeConfig from './_config/affectation.poste.pesee.config';
import AffectationPostePeseeRoutes from './_config/affectation.poste.pesee.routes';

import AffectationPostePeseeService from './services/affectation.poste.pesee.service';

import AffectationPostePeseeComponent from './components/affectation-poste-pesee';
import AffectationPostePeseeValidator from './services/affectation.poste.pesee.validator';

const moduleName = 'app.massia.gestion.affectation.poste.pesee';

angular
    .module(moduleName, [])
    .config(AffectationPostePeseeConfig)
    .config(AffectationPostePeseeRoutes)
    .service('AffectationPostePeseeService', AffectationPostePeseeService)
    .factory('AffectationPostePeseeValidator', AffectationPostePeseeValidator)
    .component('affectationPostePesee', AffectationPostePeseeComponent);

export default moduleName;
