import validatorBuilder from 'validator-builder';

let grillesValidator = null;

export default class Grilles {
    constructor(data = {}) {
        this.id = data.id;
        this.type = 0; //data.type;
        this.estVente = true; //data.estVente;
        this.libelle = data.libelle;
        this.societeId = data.societeId;
        this.siteCommercialId = data.siteCommercialId;
        this.transporteurId = data.transporteurId;
        this.typeClientId = data.typeClientId;
        this.clientId = data.clientId;
        this.chantierId = data.chantierId;
        this.dateDebut = data.dateDebut;
        this.dateFin = data.dateFin;
        this.duplicateChildren = 'false';
        this.formType = 'new';
        this.originalGrilleId = null;
        this.augmenterPrixProduits = true;
        this.pourcentageAugmentation = 0;
        this.regleArrondi = '0';
        this.isPrixInitial = false;
        this.isPrixAugmente = false;
    }

    isValid() {
        grillesValidator = grillesValidator || validatorBuilder.getInstanceFor('Grilles');
        const validationResults = grillesValidator.validate(this);
        return validationResults.isValid;
    }
}
