SynthesesConsoClientController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'SynthesesService',
    'notification',
    'moment',
    'DataShareService',
    '_',
    '$uibModal',
    'globalizationManagementService'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function SynthesesConsoClientController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    SynthesesService,
    notification,
    moment,
    DataShareService,
    _,
    $uibModal,
    globalizationManagementService
) {
    const vm = this;
    const watchers = [];

    let previousTableState;

    vm.loading = false;

    vm.params = $stateParams;
    vm.state = $state;
    vm.moment = moment;
    vm.DataShareService = DataShareService;
    vm._ = _;
    vm.$uibModal = $uibModal;

    vm.synthesesConsoClient = [];
    vm.isSearchOngletOpen = true;
    vm.searchingFormSynthesesConsoClient = {};
    vm.searchingFormSynthesesConsoClient.entity = 'synthConsoCli';
    vm.savedPagination = {};
    vm.savedPagination.entity = 'pagination';
    vm.societesComLoaded = [];
    vm.sitesComLoaded = [];
    vm.typesClientLoaded = [];
    vm.searchingFormSynthesesConsoClient.societeComIdSelected = null;
    vm.searchingFormSynthesesConsoClient.listeIdSitesCommerciaux = [];
    vm.searchingFormSynthesesConsoClient.listeIdProduitsEnrobes = [];
    vm.searchingFormSynthesesConsoClient.typeClientIdSelected = null;

    vm.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;

    // initialise les dates de debut et fin au premier et dernier jour du mois en cours
    const maDate = new Date();
    const y = maDate.getFullYear();
    const m = maDate.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    // SL Mantis 387 RG 3
    vm.searchingFormSynthesesConsoClient.dateDebut = vm.moment(firstDay).format(vm.dateFormat);
    // SL Mantis 387 RG 4
    vm.searchingFormSynthesesConsoClient.dateFin = vm.moment(lastDay).format(vm.dateFormat);

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };
    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[1];

    vm.loadSynthesesConsoClient = loadSynthesesConsoClient;
    vm.reset = reset;
    vm.licenceGest = __configuration.licenceGest;
    vm.exporter = exporter;
    vm.getSitesCom = getSitesCom;
    vm.saveSelectedSitesCommerciaux = saveSelectedSitesCommerciaux;
    vm.saveSelectedProduits = saveSelectedProduits;
    vm.getTypesClient = getTypesClient;

    async function init() {
        //écouter l'event lié au changement de route
        //une fois on change l'url, et on arrive sur la page "synthesesConsoClient.list", on désélectionne la ligne sélectionnée (s'il y en a)
        watchers.push($scope.$on('$destroy', dispose));

        if (_.isEmpty(vm.DataShareService.savedData) == false) {
            // on cherche l'entité synthConsoCli
            const item = _.find(vm.DataShareService.savedData, { entity: 'synthConsoCli' });
            if (item != null) {
                await updateSearchForm();
            }
        }

        await getSocietesCom();
        await getSitesCom();
        await getProduitsEnrobes();
        await getTypesClient();

        if (vm.searchingFormSynthesesConsoClient.societeComIdSelected != null) {
            await loadSynthesesConsoClient(null);
        }
    }

    init();

    async function updateSearchForm() {
        vm.searchingFormSynthesesConsoClient = vm.DataShareService.getDataShare('synthConsoCli');
    }

    async function getSocietesCom() {
        vm.societesComLoaded = await SynthesesService.getSocietesCom();
        // SL Mantis 387 RG 1
        if (vm.societesComLoaded.length == 1) {
            vm.searchingFormSynthesesConsoClient.societeComIdSelected = vm.societesComLoaded[0].id;
        }
    }

    async function getSitesCom() {
        vm.sitesCommerciauxLoaded = await SynthesesService.getSitesCom(vm.searchingFormSynthesesConsoClient.societeComIdSelected);
    }

    async function loadSynthesesConsoClient(tableState) {
        startLoading();
        vm.DataShareService.setDataShare(vm.searchingFormSynthesesConsoClient, 'synthConsoCli');

        // gestion de la sauvegarde et de la restitution de la pagination
        const objectToManage = {};
        objectToManage.tableState = tableState;
        objectToManage.savedPagination = vm.savedPagination;
        objectToManage.itemsByPageOptions = vm.itemsByPageOptions;
        objectToManage.itemsByPage = vm.itemsByPage;
        const resultObject = await vm.DataShareService.managePaginationContextuelle(objectToManage);
        if (resultObject) {
            tableState = resultObject.tableState;
            vm.savedPagination = resultObject.savedPagination;
            vm.itemsByPageOptions = resultObject.itemsByPageOptions;
            vm.itemsByPage = resultObject.itemsByPage;
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.synthesesConsoClient = [];

        try {
            const data = await SynthesesService.getSynthesesConsoClient(filters, sorts, pagination, vm.searchingFormSynthesesConsoClient);
            // console.log(data);
            vm.synthesesConsoClient = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            if (ex.status == 400) {
                notification.warning(ex.data);
            }
            if (vm.searchingFormSynthesesConsoClient.societeComIdSelected != null) {
                notification.error(ex.data);
            }
        } finally {
            stopLoading();
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }

    function reset() {
        vm.searchingFormSynthesesConsoClient.societeComIdSelected = null;
        vm.searchingFormSynthesesConsoClient.listeIdSitesCommerciaux = [];
        vm.searchingFormSynthesesConsoClient.listeIdProduitsEnrobes = [];
        vm.searchingFormSynthesesConsoClient.typeClientIdSelected = null;
        // vm.sitesComLoaded = [];

        // initialise les dates de debut et fin au premier et dernier jour du mois en cours
        const maDate = new Date();
        const y = maDate.getFullYear();
        const m = maDate.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);
        vm.searchingFormSynthesesConsoClient.dateDebut = vm.moment(firstDay).format(vm.dateFormat);
        vm.searchingFormSynthesesConsoClient.dateFin = vm.moment(lastDay).format(vm.dateFormat);
        vm.synthesesConsoClient = [];
        getSocietesCom();
        getSitesCom();
        getProduitsEnrobes();
    }

    async function exporter(modeImpression) {
        try {
            const filters = PaginationService.getFilters(previousTableState);
            const sorts = PaginationService.getSorts(previousTableState);
            const fileName = 'Liste_synthesesConsoClient';
            let resultat = undefined;
            try {
                resultat = await SynthesesService.exportListeSynthesesConsoClient(modeImpression, vm.searchingFormSynthesesConsoClient);
            } catch (ex) {
                //si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
                const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
                notification.error('SYNTHESES.' + msgException);
                return false;
            }
            if (resultat) {
                const data = resultat.data;
                const status = resultat.status;
                let headers = resultat.headers;
                headers = headers();

                const contentType = headers['content-type'];

                const linkElement = document.createElement('a');
                try {
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    if (modeImpression == 0) {
                        linkElement.setAttribute('download', fileName + '.pdf');
                    } else {
                        linkElement.setAttribute('download', fileName + '.xlsx');
                    }

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                } catch (ex) {
                } finally {
                }
                return true;
            }
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        }
    }

    function saveSelectedSitesCommerciaux() {
        this.searchingFormSynthesesConsoClient.listeIdSitesCommerciaux = [];
        for (let i = 0; i < this.selectedSitesCommerciaux.length; i++) {
            const siteCom = this.selectedSitesCommerciaux[i];
            this.searchingFormSynthesesConsoClient.listeIdSitesCommerciaux.push(siteCom.id);
        }
    }

    async function getTypesClient() {
        vm.typesClientLoaded = await SynthesesService.getTypesClient();

        if (vm.typesClientLoaded.length == 1) {
            vm.searchingFormSynthesesConsoClient.typeClientIdSelected = vm.typesClientLoaded[0].id;
        }
    }

    async function getProduitsEnrobes() {
        vm.produitsLoaded = await SynthesesService.getProduitsEnrobes();
        //console.log(vm.produitsLoaded);
    }

    function saveSelectedProduits() {
        this.searchingFormSynthesesConsoClient.listeIdProduitsEnrobes = [];
        for (let i = 0; i < this.selectedProduits.length; i++) {
            const prod = this.selectedProduits[i];
            this.searchingFormSynthesesConsoClient.listeIdProduitsEnrobes.push(prod.id);
        }
    }
}
