export default function Configure($stateProvider) {
    $stateProvider.state('inerte-declaration-annuelle', {
        parent: 'gestion',
        url: '/inerte-declaration-annuelle',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
        }
    });

    $stateProvider.state('inerte-declaration-annuelle.list', {
        url: '/list',
        template: '<inerte-declaration-annuelle></inerte-declaration-annuelle>',
        rights: { domain: 'inerte', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "INERTES.DECLARATION_ANNUELLE.TITLE" | translate}}'
        },
        // on affiche la liste des bennes dans la navigation
        navigation: {
            menu: 'inertes',
            translate: 'INERTES.DECLARATION_ANNUELLE.TITLE',
            order: 14
        },
        // la liste des bennes fait partie des points d'entrée recherchables dans la navigation
        search: 'INERTES.DECLARATION_ANNUELLE.TITLE'
    });
}

Configure.$inject = ['$stateProvider'];
