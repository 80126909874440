import SiteFormQualibController from './site.form.qualib.controller';

export default {
    bindings: {
        site: '=',
        form: '=',
        onUpdate: '&'
    },
    template: require('./site.form.qualib.html'),
    controller: SiteFormQualibController
};
