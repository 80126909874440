import GestionStocksProductionRetraiteeController from './gestion.stocks.production.retraitee.controller';

export default {
    bindings: {
        ligne: '=',
        arrondi: '=',
        modalInstance: '='
    },
    template: require('./gestion.stocks.production.retraitee.html'),
    controller: GestionStocksProductionRetraiteeController
};
