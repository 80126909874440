const serviceUrl = `${__configuration.apiUrl}/massia/paiements`;

export default class PaiementsService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getPaiements(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });
        return res.data;
    }
    async deletePaiementById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }
    async getPaiementEnteteById(id) {
        const url = `${serviceUrl}/${id}/entete`;
        const res = await this.$http.get(url);
        return res.data;
    }
    async createPaiement(paiement) {
        const data = {
            code: paiement.code,
            libelle: paiement.libelle,
            type: paiement.typeId,
            isComptantBascule: paiement.isComptantBascule
        };
        if (typeof paiement.echeance !== 'undefined') {
            data.echeance = paiement.echeance;
        } else {
            data.echeance = null;
        }
        if (typeof paiement.jourDuMois !== 'undefined') {
            data.jourDuMois = paiement.jourDuMois;
        } else {
            data.jourDuMois = null;
        }
        if (typeof paiement.modeCalcul !== 'undefined') {
            data.modeCalcul = paiement.modeCalcul;
        } else {
            data.modeCalcul = null;
        }
        if (typeof paiement.echeanceUnite !== 'undefined') {
            data.echeanceUnite = paiement.echeanceUnite;
        } else {
            data.echeanceUnite = null;
        }

        try {
            const result = await this.$http.post(serviceUrl, data);
            return result.data;
        } catch (err) {
            if (err.status === 404) {
                return false;
            }
            throw err;
        }
    }
    async updatePaiement(paiement) {
        const data = {
            id: paiement.id,
            code: paiement.code,
            type: paiement.typeId,
            libelle: paiement.libelle,
            isComptantBascule: paiement.isComptantBascule
        };

        if (typeof paiement.echeance !== 'undefined') {
            data.echeance = paiement.echeance;
        } else {
            data.echeance = null;
        }
        if (typeof paiement.jourDuMois !== 'undefined') {
            data.jourDuMois = paiement.jourDuMois;
        } else {
            data.jourDuMois = null;
        }
        if (typeof paiement.modeCalcul !== 'undefined') {
            data.modeCalcul = paiement.modeCalcul;
        } else {
            data.modeCalcul = null;
        }
        if (typeof paiement.echeanceUnite !== 'undefined') {
            data.echeanceUnite = paiement.echeanceUnite;
        } else {
            data.echeanceUnite = null;
        }

        const url = `${serviceUrl}/${data.id}`;
        return this.$http.put(url, data);
    }

    async getCalcModes() {
        const url = 'assets/enum/paiements/modeCalcul.json';
        const res = await this.$http.get(url);
        return res.data;
    }
    async getCalcUnits() {
        const url = 'assets/enum/paiements/unites.json';
        const res = await this.$http.get(url);
        return res.data;
    }
    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async GetDateSimulated(dateGeneration, paiement) {
        const url = `${serviceUrl}/simulation`;
        const res = await this.$http.get(url, {
            params: {
                dateGeneration: JSON.stringify(dateGeneration || []),
                paiement: JSON.stringify(paiement || [])
            }
        });
        return res.data;
    }
}
