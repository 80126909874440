export default function Configure($stateProvider) {
    $stateProvider.state('motifs-depreciation-stock', {
        parent: 'common',
        url: '/motifs-depreciation-stock',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.PARAMETRAGE" | translate }}'
        }
    });

    $stateProvider.state('motifs-depreciation-stock.list', {
        url: '/list',
        template: '<motifs-depreciation-stock></motifs-depreciation-stock>',
        rights: { domain: 'motifdeprecstk', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "MOTIFS_DEPRECIATION_STOCK.BREADCRUMBS.MOTIFS_LIST" | translate}}'
        },
        // on affiche la liste des motifs dans la navigation
        navigation: {
            menu: 'stocks_parametrage',
            translate: 'MOTIFS_DEPRECIATION_STOCK.BREADCRUMBS.MOTIFS_LIST',
            order: 2
        },
        // la liste des motifs fait partie des points d'entrée recherchables dans la navigation
        search: 'MOTIFS_DEPRECIATION_STOCK.BREADCRUMBS.MOTIFS_LIST'
    });

    $stateProvider.state('motifs-depreciation-stock.new', {
        url: '/new',
        template: '<motif-depreciation-stock-form></motif-depreciation-stock-form>',
        rights: { domain: 'motifdeprecstk', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher motifs > ajouter
            parent: 'motifs-depreciation-stock.list',
            label: '{{ "MOTIFS_DEPRECIATION_STOCK.BREADCRUMBS.MOTIF_NEW" | translate}}'
        },
        // l'ajout d'une motif fait partie des points d'entrée recherchables dans l'application
        search: 'MOTIFS_DEPRECIATION_STOCK.BREADCRUMBS.MOTIF_NEW'
    });

    $stateProvider.state('motifs-depreciation-stock.edit', {
        url: '/{id}/edit',
        template: '<motif-depreciation-stock-form></motif-depreciation-stock-form>',
        rights: { domain: 'motifdeprecstk', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher motifs > modifier
            parent: 'motifs-depreciation-stock.list',
            label: '{{ "MOTIFS_DEPRECIATION_STOCK.BREADCRUMBS.MOTIF_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
