import validatorBuilder from 'validator-builder';

let typeBenneValidator = null;

export default class TypeBenne {
    constructor(data) {
        data = data || {};
        this.id = data.id || undefined;
        this.code = data.code || undefined;
        this.libelle = data.libelle || undefined;
        this.idUnite = data.idUnite || undefined;
        this.ptac = data.ptac || undefined;
        this.poids = data.poids || undefined;
    }

    isValid() {
        typeBenneValidator = typeBenneValidator || validatorBuilder.getInstanceFor('TypeBenne');
        const validationResults = typeBenneValidator.validate(this);
        return validationResults.isValid;
    }
}
