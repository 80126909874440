import { IAugmentedJQuery } from 'angular';
import { Directive, HostListener } from '../../../core/decorators';

@Directive({ selector: '[massiaScrollDrag]' })
export class MassiaScrollDrag {
    private startX: number;
    private scrollLeft: number;
    private isDown: boolean = false;

    /* @ngInject */
    constructor(public $element: IAugmentedJQuery) {}

    @HostListener('mousedown', ['$event'])
    mouseDown(e: MouseEvent) {
        this.isDown = true;
        this.$element[0].classList.add('active');
        this.startX = e.pageX - this.$element[0].offsetLeft;
        this.scrollLeft = this.$element[0].scrollLeft;
    }

    @HostListener('mouseleave mouseup', ['$event'])
    mouseLeave(e: MouseEvent) {
        this.isDown = false;
        this.$element[0].classList.remove('active');
    }

    @HostListener('mousemove', ['$event'])
    mouseMove(e: MouseEvent) {
        if (!this.isDown) return;
        e.preventDefault();
        const x = e.pageX - this.$element[0].offsetLeft;
        const walk = (x - this.startX) * 1;
        this.$element[0].scrollLeft = this.scrollLeft - walk;
    }
}
