/* eslint-disable max-lines */
/* eslint-disable max-depth */
/* eslint-disable default-case */
/* eslint-disable complexity */
/* eslint-disable curly */
/* eslint-disable brace-style */

// import Chart from 'chart.js';
import numeral from 'numeral';
import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-chart',
    bindings: {
        uid: '@',
        model: '<',
        field: '<',
        xaxeAngleRotation: '@',
        yaxeAngleRotation: '@',
        height: '@',
        maxWidth: '@',
        loaded: '&'
    },
    transclude: true,
    template: require('./massia.chart.tpl.html')
})
export class MassiaChartController {
    static $inject = [
        '$scope',
        '$timeout',
        '$translate',
        '_',
        'NatureInputTypeMapping',
        'FormulesService',
        'MesuresService',
        'ColorPickerService',
        '$uibModal',
        'moment',
        'globalizationManagementService'
    ];

    constructor(
        $scope,
        $timeout,
        $translate,
        _,
        NatureInputTypeMapping,
        FormulesService,
        MesuresService,
        ColorPickerService,
        $uibModal,
        moment,
        globalizationManagementService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this._ = _;
        this.NatureInputTypeMapping = NatureInputTypeMapping;
        this.FormulesService = FormulesService;
        this.MesuresService = MesuresService;
        this.ColorPickerService = ColorPickerService;
        this.$uibModal = $uibModal;
        this.moment = moment;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    async $onInit() {
        this.uid = this.uid || 'granulograph';
        //this.recalculGranuloEnCours = false;
        //this.model[this.field.id] = this.getDataValue(this.field.id) || this.field.default;
        //this.recalculeGranulo(false);
        this.paramGranulo = await this.MesuresService.getMesureCourbeGranuloById(this.model.idEssai);
        this.paramGranulo.limiterPassants = !(this.paramGranulo.limiterPassants === null || this.paramGranulo.limiterPassants === undefined);

        //this.courbeGranulo();

        const that = this;
        this.$scope.$watch(
            '$ctrl.model',
            function (newValue, oldValue) {
                that.courbeGranulo();
            },
            true
        );

        // const that = this;
        // this.$timeout(function () {
        //     if (that.model.style.height) {
        //         const value = parseFloat(that.model.style.height.replace('px', ''));
        //         that.model.style.height = (value + 1) + 'px';
        //     }
        // }, 0);
    }

    async courbeGranulo() {
        const that = this;
        if (this.paramGranulo) {
            this.labels = [];
            this.series = [];
            this.data = [];
            this.datasetOverride = [];

            //données
            let minPassant = 100;
            let maxPassant = 0;

            let courbeIndex = 0;
            let indComposant = 0;
            var indiceCourbes = [];
            var listDataSetOverride = [];
            let granulo = true;

            for (const serie in this.model) {
                var objIndiceCourbe = undefined;
                //pour tous les array de valeurs autres que les tamis
                if (
                    serie !== 'Abscisse' &&
                    this.model[serie].values &&
                    this.model[serie].values.constructor === Array &&
                    this.model[serie].values.length === this.model.nbElement
                ) {
                    //On récupère les valeurs
                    courbeIndex++;
                    let dataType = undefined;
                    const dataValues = [];
                    for (let i = 0; i < this.model.nbElement; i++) {
                        if (this.model.Abscisse[i].value) {
                            if (serie.startsWith('courbe_') || serie.startsWith('recompo_moyenne')) {
                                dataType = 'VAL';

                                if (!this.paramGranulo.deriverCourbe) {
                                    if (
                                        this.model[serie].values[i] &&
                                        this.model[serie].values[i].pourcPassantArrondi !== undefined &&
                                        this.model[serie].values[i].pourcPassantArrondi !== ''
                                    ) {
                                        dataValues.push({ x: this.model.Abscisse[i].value, y: this.model[serie].values[i].pourcPassantArrondi });
                                        if (!this.paramGranulo.limiterPassants) {
                                            if (this.model[serie].values[i].pourcPassant > maxPassant) {
                                                maxPassant = this.model[serie].values[i].pourcPassantArrondi;
                                            }
                                            if (this.model[serie].values[i].pourcPassant < minPassant) {
                                                minPassant = this.model[serie].values[i].pourcPassantArrondi;
                                            }
                                        }
                                    } else {
                                        // if(this.model.override && this.model.override.displayAllAbscisses) {
                                        dataValues.push({ x: this.model.Abscisse[i].value, y: null });
                                    }
                                } else if (
                                    this.model[serie].values[i] &&
                                    this.model[serie].values[i].pourcRefPartielArrondi !== undefined &&
                                    this.model[serie].values[i].pourcRefPartielArrondi !== ''
                                ) {
                                    dataValues.push({ x: this.model.Abscisse[i].value, y: this.model[serie].values[i].pourcRefPartielArrondi });
                                } else {
                                    // if(this.model.override && this.model.override.displayAllAbscisses) {
                                    dataValues.push({ x: this.model.Abscisse[i].value, y: null });
                                }
                            } else {
                                const stat = this.model[serie].values[i];
                                if (stat !== undefined && stat !== '') {
                                    dataType = serie;

                                    dataValues.push({ x: this.model.Abscisse[i].value, y: stat });
                                    if (!this.paramGranulo.limiterPassants) {
                                        if (stat > maxPassant) {
                                            maxPassant = stat;
                                        }
                                        if (stat < minPassant) {
                                            minPassant = stat;
                                        }
                                    }
                                } else {
                                    // if(this.model.override && this.model.override.displayAllAbscisses) {
                                    dataValues.push({ x: this.model.Abscisse[i].value, y: null });
                                }
                            }
                        }
                    }

                    //On les injecte dans les variables du graphique
                    let paramTrace = undefined;
                    let fillTrace = false;

                    if (serie.startsWith('courbe_')) {
                        //aller chercher les composants
                        if (this.paramGranulo.listeTracesComposants.length > indComposant) {
                            paramTrace = this.paramGranulo.listeTracesComposants[indComposant];
                        } else {
                            paramTrace = {
                                formePoint: 1,
                                styleTrace: 1,
                                taillePoint: 1,
                                couleurTrace: { hue: 240, saturation: 100, luminosity: 50 },
                                couleurFuseau: null,
                                epaisseurTrace: 1,
                                couleurPoint: null,
                                valeurPoint: 1
                            };
                        }
                        indComposant++;
                    } else if (serie.startsWith('recompo_moyenne')) {
                        paramTrace = this.paramGranulo.listeTracesResultats.find(function (e) {
                            return e.formule1 === '@MOY';
                        });
                        if (!paramTrace)
                            paramTrace = {
                                formePoint: 1,
                                styleTrace: 1,
                                taillePoint: 1,
                                couleurTrace: { hue: 240, saturation: 100, luminosity: 50 },
                                couleurFuseau: null,
                                epaisseurTrace: 1,
                                couleurPoint: null,
                                valeurPoint: 1
                            };
                    } else if (serie.startsWith('recompo_vise')) {
                        paramTrace = this.paramGranulo.listeIndicesReferences[0].listeTracesReferences.find(function (e) {
                            return e.formule1 === '@VTYPE';
                        });
                        if (!paramTrace)
                            paramTrace = {
                                formePoint: 2,
                                styleTrace: 1,
                                taillePoint: 2,
                                couleurTrace: { hue: 0, saturation: 100, luminosity: 50 },
                                couleurFuseau: null,
                                epaisseurTrace: 1,
                                couleurPoint: null,
                                valeurPoint: 0
                            };
                    } else if (serie.startsWith('recompo_bornemini')) {
                        paramTrace = this.paramGranulo.listeIndicesReferences[0].listeTracesReferences.find(function (e) {
                            return e.formule1 === '@VSI';
                        });
                        if (!paramTrace)
                            paramTrace = {
                                formePoint: 1,
                                styleTrace: 1,
                                taillePoint: 1,
                                couleurTrace: { hue: 240, saturation: 100, luminosity: 50 },
                                couleurFuseau: null,
                                epaisseurTrace: 1,
                                couleurPoint: null,
                                valeurPoint: 0
                            };
                    } else if (serie.startsWith('recompo_bornemaxi')) {
                        paramTrace = this.paramGranulo.listeIndicesReferences[0].listeTracesReferences.find(function (e) {
                            return e.formule1 === '@VSS';
                        });
                        if (!paramTrace)
                            paramTrace = this.paramGranulo.listeIndicesReferences[0].listeTracesReferences.find(function (e) {
                                return e.formule2 === '@VSS';
                            });
                        if (!paramTrace)
                            paramTrace = {
                                formePoint: 1,
                                styleTrace: 1,
                                taillePoint: 1,
                                couleurTrace: { hue: 240, saturation: 100, luminosity: 50 },
                                couleurFuseau: null,
                                epaisseurTrace: 1,
                                couleurPoint: null,
                                valeurPoint: 0
                            };
                    } else if (!this.model[serie].isControle) {
                        //si la courbe est dans un fuseau et en minimum
                        fillTrace = false;
                        paramTrace = this.paramGranulo.listeTracesResultats.find(function (e) {
                            return e.formule1 === '@' + dataType;
                        });
                        if (paramTrace && paramTrace.formule2 != '') {
                            if (paramTrace.styleFuseau != 0) {
                                objIndiceCourbe = { formule: '@' + dataType, indice: courbeIndex, formuleCorresp: paramTrace.formule2 };
                                fillTrace = 1;
                            }
                        } else {
                            //si la courbe est dans un fuseau et en maximum
                            let paramTrace2 = this.paramGranulo.listeTracesResultats.find(function (e) {
                                return e.formule2 === '@' + dataType;
                            });
                            if (paramTrace2) {
                                paramTrace = paramTrace2;
                                if (paramTrace.styleFuseau != 0) {
                                    fillTrace = 1;
                                    objIndiceCourbe = { formule: '@' + dataType, indice: courbeIndex, formuleCorresp: paramTrace.formule1 };
                                }
                            }
                        }
                    } else if (this.paramGranulo.listeIndicesReferences && this.paramGranulo.listeIndicesReferences.length > 0) {
                        //si la courbe est dans un fuseau et en minimum
                        fillTrace = false;
                        paramTrace = this.paramGranulo.listeIndicesReferences[0].listeTracesReferences.find(function (e) {
                            return e.formule1 === '@' + dataType;
                        });
                        if (paramTrace && paramTrace.formule2) {
                            if (paramTrace.styleFuseau != 0) {
                                objIndiceCourbe = { formule: '@' + dataType, indice: courbeIndex, formuleCorresp: paramTrace.formule2 };
                                fillTrace = 1;
                            }
                        } else {
                            //si la courbe est dans un fuseau et en maximum
                            let paramTrace2 = this.paramGranulo.listeIndicesReferences[0].listeTracesReferences.find(function (e) {
                                return e.formule2 === '@' + dataType;
                            });
                            if (paramTrace2) {
                                paramTrace = paramTrace2;
                                if (paramTrace.styleFuseau != 0) {
                                    fillTrace = 1;
                                    objIndiceCourbe = { formule: '@' + dataType, indice: courbeIndex, formuleCorresp: paramTrace.formule1 };
                                }
                            }
                        }
                    }

                    if (paramTrace) {
                        //remettre dans l'ordre ascendant des tamis !! obligatoire pour les tracés de fuseau
                        let dataValInverse = [];
                        for (var k = dataValues.length - 1; k >= 0; k--) dataValInverse.push(dataValues[k]);

                        this.data.push(dataValInverse);

                        if (!this.paramGranulo.deriverCourbe) this.series.push(this.model[serie].label);
                        else this.series.push(this.$translate.instant('MESURES.POURC_REFUS_CUMUL'));

                        listDataSetOverride.push(this.getParamTrace(paramTrace, fillTrace, this.model[serie].hidden));
                        if (objIndiceCourbe) {
                            objIndiceCourbe.indiceDataSetOverride = listDataSetOverride.length - 1;
                            indiceCourbes.push(objIndiceCourbe);
                        }
                    } else courbeIndex--;
                }
            }

            if (!this.model.override || this.model.override.useGranConfig) {
                for (let i = 0; i < listDataSetOverride.length; i++) {
                    var idxCourbe = this._.find(indiceCourbes, { indiceDataSetOverride: i });
                    if (idxCourbe) {
                        var fillFus = this._.find(indiceCourbes, { formule: idxCourbe.formuleCorresp });
                        var fillFus2 = this._.find(indiceCourbes, { formule: idxCourbe.formule });
                        if (fillFus && fillFus2) {
                            if (fillFus.indice > fillFus2.indice) listDataSetOverride[i].fill = fillFus2.indice - 1;
                            else listDataSetOverride[i].fill = fillFus.indice - 1;
                        } else listDataSetOverride[i].fill = false;
                    }
                    listDataSetOverride[i].spanGaps = true;
                    this.datasetOverride.push(listDataSetOverride[i]);
                }
            } else {
                //this.datasetOverride = this.model.override;
                for (let i = 0; i < listDataSetOverride.length; i++) {
                    this.datasetOverride.push(this.model.override);
                }
            }

            const labelIndexes = {};
            this.labels = this.model.Abscisse.map(function (e) {
                return e.value;
            }); //label des tamis

            let typeAxeX = 'logarithmic';

            if (this.labels[0] && this.labels[0]._d) {
                //moment
                this.labels = this.labels.map((e) => e.toDate());
            }

            if (this.labels[0] && this.labels[0].constructor === Date) {
                granulo = false;
                typeAxeX = 'time';
                this.labels.sort(function (a, b) {
                    return a.getTime() - b.getTime();
                });
                this.labels.forEach(function (value) {
                    const intDate = value.getTime();

                    if (!labelIndexes[intDate]) labelIndexes[intDate] = that.moment(value, that.dateFormat).format(that.dateFormat);
                });
                this.data.map(function (e) {
                    e.sort(function (a, b) {
                        return a.x.toDate().getTime() - b.x.toDate().getTime();
                    });
                });
            } else {
                this.labels.sort(function (a, b) {
                    return parseFloat(a) - parseFloat(b);
                });
                this.labels.forEach(function (value) {
                    const floatVal = parseFloat(value);
                    if (!labelIndexes[floatVal]) labelIndexes[floatVal] = value;
                });

                this.data.map(function (e) {
                    e.sort(function (a, b) {
                        return parseFloat(a.x) - parseFloat(b.x);
                    });

                    e.forEach(function (value) {
                        const floatVal = parseFloat(value.x);
                        value.x = floatVal.toString();
                    });
                });
            }

            //OPTIONS : légende et axes
            let positionLegende = this.getPositionLegende(this.paramGranulo.positionLegende);
            let displayLegende = true;
            let typeAxeY = 'linear';
            let displayAxeX = true;
            let displayAxeY = true;
            let inverserX = false;
            let inverserY = false;
            let lisser = 0;
            if (positionLegende === '') {
                positionLegende = 'right';
                displayLegende = false;
            }
            if (this.model.override && this.model.override.positionLegende) {
                positionLegende = this.model.override.positionLegende;
            }

            if (this.paramGranulo.echelleAbscisses === 0 && typeAxeX != 'time') {
                typeAxeX = 'linear';
            }
            if (this.paramGranulo.echelleOrdonnees === 1) {
                typeAxeY = 'logarithmic';
            }
            if (this.paramGranulo.afficheAbscisses === false) {
                displayAxeX = false;
            }
            if (this.paramGranulo.afficheOrdonnees === false) {
                displayAxeY = false;
            }
            if (this.paramGranulo.inverserX === true) {
                inverserX = true;
            }
            if (this.paramGranulo.inverserY === true) {
                inverserY = true;
            }
            if (this.paramGranulo.lisser === true) {
                lisser = 0.4;
            }
            //TODO taille légende

            if (this.model.override && this.model.override.typeAxeX) {
                typeAxeX = this.model.override.typeAxeX;
            }
            if (this.model.override && this.model.override.typeAxeY) {
                typeAxeY = this.model.override.typeAxeY;
            }
            if (this.model.override && this.model.override.displayAxeX) {
                displayAxeX = this.model.override.displayAxeX;
            }
            if (this.model.override && this.model.override.displayAxeY) {
                displayAxeY = this.model.override.displayAxeY;
            }
            if (this.model.override && this.model.override.inverserX) {
                inverserX = this.model.override.inverserX;
            }
            if (this.model.override && this.model.override.inverserY) {
                inverserY = this.model.override.inverserY;
            }

            this.chartType = this.model.override && this.model.override.chartType ? this.model.override.chartType : 'line';

            if (this.paramGranulo.limiterPassants) {
                minPassant = 0;
                maxPassant = 100;
            }

            const titreAxeX = this.model.titreX
                ? this.model.titreX
                : this.$translate.instant('SYNTHENTS.DIMENSIONS') +
                  (this.model.Abscisse[0] && this.model.Abscisse[0].unite ? ' (' + this.model.Abscisse[0].unite + ')' : '');

            var chartLabels = undefined;
            if (typeAxeX != 'time')
                chartLabels = Object.keys(labelIndexes)
                    .map(function (e) {
                        return parseFloat(e);
                    })
                    .sort(function (a, b) {
                        return parseFloat(a) - parseFloat(b);
                    });
            this.options = {
                clip: false,
                plugins: {
                    filler: {
                        propagate: false
                    },
                    title: {
                        display: true
                    },
                    tooltip: {
                        enable: true,
                        position: 'nearest',
                        usePointStyle: true,
                        callbacks: {
                            title: function (tooltipItem, data) {
                                if (labelIndexes[tooltipItem[0].label]) return labelIndexes[tooltipItem[0].label];
                                else return tooltipItem[0].label.toString(10); //pour garder en décimal si affichage logarithmique
                            }
                        }
                    },
                    legend: {
                        display: displayLegende,
                        position: positionLegende
                    }
                },
                interaction: {
                    intersect: false,
                    mode: 'index'
                },
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    line: {
                        tension: lisser //ne pas lisser : Mantis 3432//sur paramétrage Mantis 4192
                    }
                },
                scales: {
                    x: {
                        axis: 'x',
                        display: true,
                        type: typeAxeX,
                        grid: {
                            display: displayAxeX
                        },
                        position: 'bottom',
                        title: {
                            display: true,
                            text: titreAxeX
                        },
                        min: chartLabels[0], //this.model[this.field.id][this.model[this.field.id].length - 1].value.ouvertureTamis,
                        max: chartLabels[chartLabels.length - 1], //this.model[this.field.id][0].value.ouvertureTamis,
                        reverse: inverserX,
                        ticks: {
                            //type: typeAxeX,
                            autoSkip: false,
                            maxRotation: this.xaxeAngleRotation ? this.xaxeAngleRotation : 0,
                            minRotation: this.xaxeAngleRotation ? this.xaxeAngleRotation : 0,
                            callback: function (value, indexData) {
                                if (labelIndexes[value]) return labelIndexes[value];
                                else return value.toString(10); //pour garder en décimal si affichage logarithmique
                            },
                            fontSize: 10
                        }, //personnaliser les ticks
                        afterBuildTicks: (myChart) =>
                            (myChart.ticks = Object.keys(labelIndexes)
                                .map((e) => parseFloat(e))
                                .sort((a, b) => parseFloat(a) - parseFloat(b))
                                .map((e) => ({ value: e })))
                    },
                    y: {
                        axis: 'y',
                        type: typeAxeY,
                        display: true,
                        grid: {
                            display: displayAxeY
                        },
                        position: 'left',
                        title: {
                            display: true,
                            text: this.model.titreY1 ? this.model.titreY1 : this.$translate.instant('MESURES.POURC_PASSANTS')
                        },
                        reverse: inverserY,
                        ticks: {
                            autoSkip: false,
                            maxRotation: this.yaxeAngleRotation ? this.yaxeAngleRotation : 0,
                            minRotation: this.yaxeAngleRotation ? this.yaxeAngleRotation : 0,
                            callback: function (value) {
                                if (value) return value.toString(10);
                            },
                            fontSize: 10
                        }
                    }
                }
            };
            if (granulo) {
                this.options.scales.y.min = 0;
                this.options.scales.y.max = 100;
            }
            this.graphGran = {
                type: this.chartType,
                data: {
                    datasets: this.data.map((x, i) => ({
                        data: x.filter(({ y }) => typeof y !== 'undefined' || y || typeof y === 'number'),
                        label: this.series[i],
                        ...this.datasetOverride[i]
                    }))
                },
                options: this.options
            };
            if (this.loaded) {
                this.loaded();
            }
        }
    }

    getParamTrace(paramTrace, fill, hidden) {
        const stylePoint = this.getStylePoint(paramTrace.formePoint);
        const styleTrace = this.getStyleTrace(paramTrace.styleTrace);
        let taillePoint = paramTrace.taillePoint;
        if (stylePoint === '') {
            taillePoint = 0;
        }
        const override = {
            fill: fill,
            /* yAxisID: 'y-axis-1',
            xAxisID: 'x-axis', */
            borderWidth: paramTrace.epaisseurTrace,
            pointBorderWidth: taillePoint,
            pointStyle: stylePoint,
            pointRadius: taillePoint,
            borderDash: styleTrace,
            hidden: hidden,
            datalabels: {
                display: paramTrace.valeurPoint,
                formatter: function (value) {
                    return value.y;
                }
                //color: '#FFCE56'
            }
        };

        if (fill) {
            override.borderColor = this.getColorFromHSL(paramTrace.couleurTrace);
            override.backgroundColor = paramTrace.couleurFuseau
                ? this.getColorFromHSL(paramTrace.couleurFuseau)
                : this.getColorFromHSL(paramTrace.couleurTrace);
            if (paramTrace.styleTrace == 0) override.showLine = false;
            override.fillColor = paramTrace.couleurFuseau
                ? this.getColorFromHSL(paramTrace.couleurFuseau)
                : this.getColorFromHSL(paramTrace.couleurTrace);
            override.pointBorderColor = paramTrace.couleurPoint
                ? this.getColorFromHSL(paramTrace.couleurPoint)
                : this.getColorFromHSL(paramTrace.couleurTrace);
            override.pointBackgroundColor = paramTrace.couleurPoint
                ? this.getColorFromHSL(paramTrace.couleurPoint)
                : this.getColorFromHSL(paramTrace.couleurTrace);
        } else {
            override.borderColor = this.getColorFromHSL(paramTrace.couleurTrace);
            override.backgroundColor = this.getColorFromHSL(paramTrace.couleurTrace);
            if (paramTrace.styleTrace == 0) override.showLine = false;
            override.fillColor = this.getColorFromHSL(paramTrace.couleurTrace);
            override.pointBorderColor = this.getColorFromHSL(paramTrace.couleurPoint);
            override.pointBackgroundColor = this.getColorFromHSL(paramTrace.couleurPoint);
        }
        return override;
    }

    getColorFromHSL(coul) {
        if (coul) {
            let hexa = this.ColorPickerService.hslToRgb(coul.hue, coul.saturation, coul.luminosity);
            return 'rgba(' + hexa.red + ',' + hexa.green + ',' + hexa.blue + ', ' + (coul.alpha || coul.alpha === 0 ? coul.alpha : '1') + ')';
        } else return 'rgb(0,0,0)';
    }

    getPositionLegende(position) {
        switch (position) {
            case 0: //'pas_legende',
                return '';
            case 1: //'droite',
                break;
            case 2: //'gauche',
                return 'left';
            case 3: //'haut',
                return 'top';
            case 4: //'bas'
                return 'bottom';
        }
        return 'right';
    }

    getStyleTrace(style) {
        switch (style) {
            case 0:
            case 1:
                return [];
            case 2:
                return [2, 2];
            case 3:
                return [6, 6];
            case 4:
                return [10, 6];
            case 5:
                return [10, 15];
        }
        return [];
    }

    getStylePoint(style) {
        let stylePoint = 'circle;';
        switch (style) {
            case 0:
                stylePoint = '';
                break;
            case 1:
                stylePoint = 'circle';
                break;
            case 2:
                stylePoint = 'cross';
                break;
            case 3:
                stylePoint = 'crossRot';
                break;
            case 4:
                stylePoint = 'dash';
                break;
            case 5:
                stylePoint = 'line';
                break;
            case 6:
                stylePoint = 'rect';
                break;
            case 7:
                stylePoint = 'rectRounded';
                break;
            case 8:
                stylePoint = 'rectRot';
                break;
            case 9:
                stylePoint = 'star';
                break;
            case 10:
                stylePoint = 'triangle';
                break;
        }
        return stylePoint;
    }
    exportGraphique() {
        const url = this.graphGran.toBase64Image();
        document.getElementById('url').src = url;
        //this.courbeGranulo();
    }
}
