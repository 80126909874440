export default class StockClotureMoisController {
    static $inject = ['$state', '$stateParams', 'notification', 'ModalService', '$uibModal'];

    constructor($state, $stateParams, notification, ModalService, $uibModal) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.notification = notification;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        const _this = this;
        _this.source = this.cellule;
        this.$uibModal
            .open({
                template: '<stock-cloture-mois-popup modal-instance="$ctrl.uibModalInstance"></stock-cloture-mois-popup>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = _this.source;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xl',
                backdrop: false
            })
            .result.then(
                function (result) {
                    _this.$state.go('gestion.home');
                },
                function (reason) {
                    _this.$state.go('gestion.home');
                }
            );
    }
}
