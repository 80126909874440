import Mesure from './mesure.model';
import MesureSousMesures from './sous-mesures/mesure.sous.mesures.model';

export default class MesureFormController {
	static $inject = [
		'$scope',
		'$timeout',
		'$state',
		'$stateParams',
		'MesuresCommunicationService',
		'MesuresService',
		'notification',
		'$location',
		'$anchorScroll',
		'_',
		'MassiaApplicationService',
		'$uibModal',
		'$translate',
		'ExcelService',
		'MesuresTypesService',
		'$filter',
		'RouterHistoryService',
		'MaterielService',
		'EssaisMaterielService',
		'ModalService',
		'MOPService'
	];

	constructor(
		$scope,
		$timeout,
		$state,
		$stateParams,
		MesuresCommunicationService,
		MesuresService,
		notification,
		$location,
		$anchorScroll,
		_,
		MassiaApplicationService,
		$uibModal,
		$translate,
		ExcelService,
		MesuresTypesService,
		$filter,
		RouterHistoryService,
		MaterielService,
		EssaisMaterielService,
		ModalService,
		MOPService
	) {
		/**
		 * @type ng.IScope
		 */
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.MesuresCommunicationService = MesuresCommunicationService;
		this.MesuresService = MesuresService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.$translate = $translate;
		this._ = _;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$uibModal = $uibModal;
		this.ongletOpen = {
			isEnteteOpen: false,
			isSousMesuresOpen: false,
			isTypesProduitsOpen: false,
			isCourbeGranuloOpen: false,
			isCaracteristiqueOpen: false,
			isAccreditationOpen: false
		};
		this.ongletOpenVal = {
			isEnteteOpen: 'entete',
			isSousMesuresOpen: 'sousMesures',
			isTypesProduitsOpen: 'typesProduits',
			isCourbeGranuloOpen: 'courbeGranulo',
			isCaracteristiqueOpen: 'caracteristiques',
			isAccreditationOpen: 'accreditations'
		};
		this.ExcelService = ExcelService;
		this.MesuresTypesService = MesuresTypesService;
		this.$filter = $filter;
		this.RouterHistoryService = RouterHistoryService;
		this.MaterielService = MaterielService;
		this.EssaisMaterielService = EssaisMaterielService;
		this.ModalService = ModalService;
		this.MOPService = MOPService;
	}

	async $onInit() {
		this.MOPService.setMop([{ title: 'MESURES.BREADCRUMBS.ESSAIS_LIST', filename: 'Essais.pdf', application: 'laboratoire' }]);
		try {
			this.startLoading();
			if (this.getDomaineCourant() == 'essais') {
				await this.getTypesProduits();
			} //SP à faire avant le reset !!
			await this.reset();
			this.sousMesuresInvalid = false;
			await this.loadMateriel();
			await this.getLinkedMateriels();

			this.unregister = [
				this.$scope.$watch(
					() => this.mesure,
					(newV, oldV, scope) => {
						if (this.mesure) {
							this.onUpdateEntete(this.mesure);
							if (this.mesure.entete && Number.isInteger(this.mesure.entete.additionnalInfoMesure.config)) {
								this.loadConfig(this.mesure.entete.additionnalInfoMesure.config);
								this.ongletOpen.isExcelOpen = true;
								this.ongletOpen.isSousMesuresOpen = true;
								document.getElementById('configExcel').scrollIntoView({
									behavior: 'smooth'
								});
							}
							if (this.mesure.sousMesures) {
								this.tmpSousMesures = this.mesure.sousMesures;
							}
						}
					},
					true
				)
			];
			this.stopLoading();
		} catch (err) {
			console.error(err);
			this.stopLoading();
		}
	}

	// matériel séctions
	async loadMateriel() {
		try {
			const data = await this.MaterielService.getAll([], [], { skip: 0, take: 5000 }, 'laboratoire');
			this.materiels = data.items;
		} catch (err) {
			console.log(err);
		}
	}

	getHeader() {
		if (this.getDomaineCourant() === 'mesures' || this.getDomaineCourant() === 'messecu') {
			return 'MESURES.SOUS_MESURES';
		}
		if (this.getDomaineCourant() === 'mesctrl') {
			return 'MESURES.SOUS_CONTROLES';
		}
		if (this.getDomaineCourant().startsWith('essais')) {
			return 'MESURES.SOUS_ESSAIS';
		}
	}

	async getLinkedMateriels() {
		try {
			let data;
			const id = this.$stateParams.id;
			this.LinkedEssais = [];
			if (id) {
				data = await this.EssaisMaterielService.getLinkedEssais(id, 'essai');
				this.LinkedEssais = data;
			}
			this.selectMateriels();
			return data;
		} catch (err) {
			this.notification.error(err.data);
		}
	}

	selectMateriels() {
		if (this.LinkedEssais && this.materiels) {
			this.materiels.forEach((l) => {
				const i = this.LinkedEssais.find((L) => L.idMateriel === l.id);
				l.selected = i ? true : false;
				l.inDb = i ? true : false;
				if (!i) {
					l.linkedParams = new EssaisMateriel();
					l.linkedParams.idMateriel = l.id;
					l.linkedParams.idEssai = this.$stateParams.id ? this.$stateParams.id : 0;
				} else {
					l.linkedParams = i;
				}
			});
		}
	}

	deleteMateriel(materiel) {
		const mat = this.materiels.find((m) => m.id === materiel.id);
		mat.selected = false;
	}

	async addIdToLinkedMateriels(id) {
		try {
			this.selectedMateriels.forEach((x) => {
				x.linkedParams.idEssai = id;
			});
		} catch (e) {}
	}

	async handleLinkMateriel() {
		if (this.selectedMateriels) {
			this.selectedMateriels.forEach(async (l) => {
				if (l.inDb === true && l.selected === true) {
					await this.EssaisMaterielService.putEssaiMateriel(l.linkedParams);
				} else if (l.inDb === false && l.selected === true) {
					await this.EssaisMaterielService.postEssaiMateriel(l.linkedParams);
				}
			});
		}
		if (this.materiels) {
			this.materiels.forEach(async (l) => {
				if (l.inDb === true && l.selected === false) {
					await this.EssaisMaterielService.deleteEssaiMateriel(l.linkedParams);
				}
			});
		}

		await this.loadMateriel();
		await this.getLinkedMateriels();
	}

	//

	async getTypesProduits() {
		try {
			this.typesProduits = await this.MesuresTypesService.getAll('produits');
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	$onDestroy() {
		for (let i = 0; i < this.unregister.length; i++) {
			const u = this.unregister[i];
			u();
		}
		this.MOPService.resetMop();
	}

	async loadConfig(id) {
		if (id > -1) {
			this.tmpConf = await this.ExcelService.getOneConfig(id);
		} else {
			this.tmpConf = {
				id: id
			};
		}
	}

	getDomaineCourant() {
		return this.$state.current.name.substring(0, this.$state.current.name.indexOf('.'));
	}

	hasGranulo() {
		const idx = this._.findIndex(this.mesure?.sousMesures, { idNature: 8 });
		return idx > -1;
	}

	async reset() {
		try {
			this.mesure = null;
			const data = {
				id: this.$stateParams.id
			};

			const temp = this.RouterHistoryService.data;
			if (temp) {
				this.mesure = temp.mesure;
				this.redirection = true;
			} else {
				if (data.id) {
					try {
						data.entete = await this.MesuresService.getMesureEnteteById(data.id);
						data.sousMesures = null; // await this.MesuresService.getMesureSousMesuresById(data.id);
						data.caracteristiques = await this.MesuresService.getMesureValeursCaracteristiquesAssociationsById(data.id);
						data.courbeGranulo = await this.MesuresService.getMesureCourbeGranuloById(data.id);
						data.typesProduits = await this.MesuresService.getMesureTypesProduitsById(data.id);
					} catch (err) {
						if (err.data) {
							this.notification.error(err.data);
						} else {
							throw err;
						}
						this.annuler();
					}
				}
				this.mesure = new Mesure(data);
				this.selectedTypesProduits = angular.copy(this.mesure.typesProduits);
				if (this.$stateParams.duplicate) {
					this.mesure.id = undefined;
					this.mesure.entete.code += '_copie';
					this.mesure.traiteDuplication = true;
				}
				if (this.mesure.id) {
					this.mesure.certifications = await this.MesuresService.getCertificatsEssai(this.mesure.id);
					this.RouterHistoryService.data = {
						mesure: this.mesure
					};
				}
				if (!this.mesure.id) {
					this.typesProduits.map((e) => {
						e.selected = true;
						return e;
					});
				}
			}

			this.ongletOpen = {
				isEnteteOpen: true,
				isSousMesuresOpen: true,
				isTypesProduitsOpen: false,
				isCourbeGranuloOpen: false,
				isCaracteristiqueOpen: false,
				isAccreditationOpen: false
			};
			if (this.mesure.entete.additionnalInfoMesure.idConfigExcel > 0) {
				this.loadConfig(this.mesure.entete.additionnalInfoMesure.idConfigExcel);
			}
			if (this.mesure.id && this.selectedTypesProduits && this.typesProduits) {
				this.typesProduits.forEach((item) => {
					if (this._.findIndex(this.selectedTypesProduits, { id: item.id }) !== -1) {
						item.selected = true;
					} else {
						item.selected = false;
					}
				});
			}
		} catch (e) {
			console.error(e);
		}
	}

	async sauvegarder() {
		if (this.checkValidity()) {
			this.startLoading();
			try {
				this.resetDefaultValuesForListesElements();

				let id = false;
				//console.log(angular.copy(this.mesure));
				if (this.mesure.id) {
					if (this.mesure.entete.idNature === 12) {
						const conf = await this.saveConfigExcel(this.mesure.config.enteteExcel);
						if (conf != undefined && conf != null) {
							this.mesure.entete.additionnalInfoMesure.idConfigExcel = conf.id;
						}
						await this.MesuresService.updateMesure(this.mesure);
						this.saveCellExcel(conf.id, this.mesure.id, this.mesure.config.configExcel, this.mesure.config.configToRemove);
					} else {
						if (this.mesure.sousMesures && this.mesure.sousMesures.length > 0) {
							for (let j = 0; j < this.mesure.sousMesures.length; j++) {
								const Ssmes = this.mesure.sousMesures[j];

								Ssmes.position = j;
								if (
									Ssmes.additionnalInfoMesure.idNature === 5 &&
									Ssmes.additionnalInfoMesure.elements &&
									Ssmes.additionnalInfoMesure.elements.length > 0
								) {
									for (let i = 0; i < Ssmes.additionnalInfoMesure.elements.length; i++) {
										const el = Ssmes.additionnalInfoMesure.elements[i];
										el.ordre = i + 1;
									}
								}
							}
						}

						await this.MesuresService.updateMesure(this.mesure);
					}
					await this.MesuresService.manageCertificats(this.mesure);
					if (this.getDomaineCourant() == 'mesures' || this.getDomaineCourant() == 'messecu') {
						this.notification.success('MESURES.MESURE_UPDATED');
					} else if (this.getDomaineCourant() == 'mesctrl') {
						this.notification.success('MESURES.CONTROLE_UPDATED');
					} else if (this.getDomaineCourant().startsWith('essais')) {
						this.notification.success('MESURES.ESSAI_UPDATED');
					}
					id = this.mesure.id;
				} else {
					if (this.mesure.entete.idNature === 12) {
						const conf = await this.saveConfigExcel(this.mesure.config.enteteExcel);
						if (conf != undefined && conf != null) {
							this.mesure.entete.additionnalInfoMesure.idConfigExcel = conf.id;
						}
						id = await this.MesuresService.createMesure(this.mesure);
						this.saveCellExcel(conf.id, id, this.mesure.config.configExcel, this.mesure.config.configToRemove);
					} else {
						id = await this.MesuresService.createMesure(this.mesure);
					}
					if (this.getDomaineCourant() == 'mesures' || this.getDomaineCourant() == 'messecu') {
						this.notification.success('MESURES.MESURE_CREATED');
					} else if (this.getDomaineCourant() == 'mesctrl') {
						this.notification.success('MESURES.CONTROLE_CREATED');
					} else if (this.getDomaineCourant().startsWith('essais')) {
						this.notification.success('MESURES.ESSAI_CREATED');
					}
					await this.addIdToLinkedMateriels(id);
				}
				this.handleLinkMateriel();

				return id;
			} catch (ex) {
				console.error(ex);
				if (ex.data == "Suppression impossible, l'enregistrement est utilisé.") {
					this.notification.info('MESURES.NATURE_MODI_NON_AUTO');
				} else {
					this.notification.error(ex.data);
				}
				return false;
			} finally {
				this.stopLoading();
			}
		}
	}

	async saveConfigExcel(config) {
		try {
			if (config.isValid()) {
				if (config.id) {
					const id = await this.ExcelService.updateConfig(config.id, config);
					config.id = id;
					return config;
				}
				return await this.ExcelService.createConfig(config);
			}
		} catch (err) {
			throw err;
		}
	}

	async saveCellExcel(idConfig, idEssai, listConfig, listToRemove) {
		try {
			let valid = true;
			for (let i = 0; i < listConfig.length; i++) {
				if (listConfig[i].libelle) {
					listConfig[i].idEssai = idEssai;
				}
				if (!listConfig[i].isValid()) {
					valid = false;
					break;
				}
			}
			if (valid) {
				const data = {
					cellToUpdate: listConfig,
					cellToDelete: listToRemove
				};
				await this.ExcelService.updateConfigCell(idConfig, data);
			}
		} catch (err) {}
	}

	async appliquer() {
		this.RouterHistoryService.ignoreNextRoute();
		const id = await this.sauvegarder();
		//if (id && !this.RouterHistoryService.back()) {//non, valider ne quitte pas
		if (id && this.mesure.id) {
			this.reset();
		} else if (id) {
			this.$state.go(this.getDomaineCourant() + '.edit', { id: id });
		}
		//}
	}

	async confirmer() {
		const success = await this.sauvegarder();
		if (success) {
			if (!this.RouterHistoryService.back()) {
				this.annuler();
			}
		}
	}

	annuler() {
		if (!this.RouterHistoryService.back()) {
			this.$state.go(this.getDomaineCourant() + '.list');
		}
	}

	checkValidity() {
		let validity = true;

		let firstScroll = true;
		if (!this.mesure.entete.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}

			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('mesureEnteteValidations');
		}

		if (!this.mesure.caracteristiques.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('caracteristiques');
				firstScroll = false;
			}

			this.ongletOpen.isCaracteristiqueOpen = true;
			validity = false;
			this.$scope.$broadcast('mesureCaracteristiquesValidations');
		}

		//TODO SP Vérifier la validité des sous-mesures
		this.sousMesuresInvalid = false;
		if (!this.checkValiditySousMesures()) {
			if (firstScroll) {
				this.scrollToOnglet('sousMesures');
				firstScroll = false;
			}

			this.ongletOpen.isSousMesuresOpen = true;
			validity = false;
			this.sousMesuresInvalid = true;
			this.$scope.$broadcast('mesureSousMesuresValidations');
		}

		if (this.hasGranulo()) {
			const traceResultatToRemove = [];
			for (var i = 0; i < this.mesure.courbeGranulo.listeTracesResultats.length; i++) {
				if (!this.mesure.courbeGranulo.listeTracesResultats[i].idCalculStat) {
					traceResultatToRemove.push(i);
				}
			}
			for (var i = traceResultatToRemove.length - 1; i >= 0; i--) {
				this.mesure.courbeGranulo.listeTracesResultats.splice(traceResultatToRemove[i], 1);
			}

			if (!this.mesure.courbeGranulo || !this.mesure.courbeGranulo.isValid()) {
				if (firstScroll) {
					this.scrollToOnglet('courbeGranulo');
					firstScroll = false;
				}

				this.ongletOpen.isCourbeGranuloOpen = true;
				validity = false;
				this.$scope.$broadcast('mesureCourbeGranuloValidations');
			}
		}

		return validity;
	}

	checkValiditySousMesures() {
		let validity = true;
		const codes = [];
		let msg = '';
		for (let i = 0; /*validity &&*/ i < this.mesure.sousMesures.length; i++) {
			let sousmes = this.mesure.sousMesures[i]; //MesureSousMesures
			sousmes = new MesureSousMesures(sousmes);
			//doublon de code
			if (codes.indexOf(sousmes.code) !== -1) {
				validity = false;
				if (msg != '') {
					msg += ', ';
				}
				if (this.getDomaineCourant() == 'mesures' || this.getDomaineCourant() == 'messecu') {
					msg += this.$translate.instant('MESURES.DOUBLON_CODE_SOUS_MESURE', { code: sousmes.code });
				} else if (this.getDomaineCourant() == 'mesctrl') {
					msg += this.$translate.instant('MESURES.DOUBLON_CODE_SOUS_CONTROLE', { code: sousmes.code });
				} else if (this.getDomaineCourant().startsWith('essais')) {
					msg += this.$translate.instant('MESURES.DOUBLON_CODE_SOUS_ESSAI', { code: sousmes.code });
				}
			} else {
				codes.push(sousmes.code);
			}
			if (!sousmes.isValid()) {
				validity = false;
				if (msg != '') {
					msg += ', ';
				}
				if (this.getDomaineCourant() == 'mesures' || this.getDomaineCourant() == 'messecu') {
					msg += this.$translate.instant('MESURES.SOUS_MESURE_INVALIDE', {
						code: sousmes.code,
						libelle: sousmes.libelle
					});
				} else if (this.getDomaineCourant() == 'mesctrl') {
					msg += this.$translate.instant('MESURES.SOUS_CONTROLE_INVALIDE', {
						code: sousmes.code,
						libelle: sousmes.libelle
					});
				} else if (this.getDomaineCourant().startsWith('essais')) {
					msg += this.$translate.instant('MESURES.SOUS_ESSAI_INVALIDE', {
						code: sousmes.code,
						libelle: sousmes.libelle
					});
				}
			}
		}
		if (!validity) {
			this.notification.error(msg);
		}
		return validity;
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	onUpdateEntete(mesure) {
		// this.mesure.entete = entete;
		if (mesure && mesure.sousMesures) {
			// mettre à jour la sous-mesure principale
			const indice = mesure.sousMesures.findIndex((x) => x.isDefault);
			if (indice > -1) {
				mesure.sousMesures[indice] = {
					...mesure.sousMesures[indice],
					code: mesure.entete.code,
					libelle: mesure.entete.libelle,
					idNature: mesure.entete.idNature,
					nature: mesure.entete.nature,
					additionnalInfoMesure: mesure.entete.additionnalInfoMesure
					//formule: mesure.entete.formule,
					//defaultValue: mesure.entete.defaultValue
				};
			}
			/* SP 09/08/22 : non pas de lien entre un sous-essai date et la date de planification
            //Mise à jour la SousEssai qui est en Date et qui est reliée à la planification
            //Check nombre de SousEssai en format Date
            let numberSsEssaiDate = 0;
            this._.forEach(this.mesure.sousMesures, (mesure) => {
                if (mesure.idNature == 2) {
                    numberSsEssaiDate += 1;
                }
            });
            //MN: S'il y a qu'une seule SousEssai en format Date (idNature=2), le formule de celle-ci prendra la valeur "entete.planification"
            if (numberSsEssaiDate == 1) {
                const indice = mesure.sousMesures.findIndex((x) => x.idNature == 2);
                if (indice > -1) {
                    mesure.sousMesures[indice] = {
                        ...mesure.sousMesures[indice],
                        formule: mesure.entete.formulePlanification
                    };
                }
            }*/
		}
	}

	onUpdateSousMesures(sousMesures) {
		this.mesure.sousMesures = sousMesures || [];
	}

	onUpdateCaracteristiques(caracteristiques) {
		this.mesure.caracteristiques = caracteristiques;
	}

	onUpdateCourbeGranulo(courbeGranulo) {
		this.mesure.courbeGranulo = courbeGranulo;
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	// retire la valeur par default du sous essai au format "Liste d'éléments" lorsque ce dernier a été supprimé
	resetDefaultValuesForListesElements() {
		const sousMesures = this.mesure.sousMesures;
		for (const sousMesure of sousMesures) {
			if (sousMesure.idNature === 5 && sousMesure.defaultValue) {
				const ssMes = sousMesure.additionnalInfoMesure.elements.find((x) => x.key === sousMesure.defaultValue);
				if (!ssMes) {
					sousMesure.defaultValue = null;
				}
			}
		}
		this.mesure.sousMesures = sousMesures;
	}
}
