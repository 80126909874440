//Config
import NomenclaturesConfig from './_config/nomenclatures.config';
import NomenclaturesRoutes from './_config/nomenclatures.routes';

//Components
import NomenclaturesComponent from './components/nomenclatures';
import NomenclatureFormComponent from './components/nomenclature-form';

import NomenclatureFormEnteteComponent from './components/nomenclature-form/entete';

//services
import NomenclaturesService from './services/nomenclatures.service';

//Validateurs
import NomenclatureValidator from './components/nomenclature-form/nomenclature.validator';
import NomenclatureEnteteValidator from './components/nomenclature-form/entete/nomenclature.entete.validator';

const moduleName = 'app.massia.gestion.nomenclatures';

angular
	.module(moduleName, [])
	.config(NomenclaturesConfig)
	.config(NomenclaturesRoutes)
	.service('NomenclaturesService', NomenclaturesService)
	.component('nomenclatures', NomenclaturesComponent)
	.component('nomenclatureForm', NomenclatureFormComponent)
	.component('nomenclatureFormEntete', NomenclatureFormEnteteComponent)
	.factory('NomenclatureValidator', NomenclatureValidator)
	.factory('NomenclatureEnteteValidator', NomenclatureEnteteValidator);

export default moduleName;
