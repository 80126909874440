const serviceUrl = `${__configuration.apiUrl}/massia/produits`;

export default class FormuleAdditionCoefService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getAdditionCoefByFormule(idProduit, idComposition) {
        const url = `${__configuration.apiUrl}/massia/produits/${idProduit}/compositions/${idComposition}/addition/${idComposition}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createAdditionCoef(idProduit, idComposition, data) {
        const url = `${__configuration.apiUrl}/massia/produits/${idProduit}/compositions/${idComposition}/addition/create`;
        const res = await this.$http.post(url, data);
        return res.data;
    }

    async deleteAdditionCoef(idProduit, idComposition, data) {
        const url = `${__configuration.apiUrl}/massia/produits/${idProduit}/compositions/${idComposition}/addition/delete`;
        return this.$http.post(url, data);
    }
}
