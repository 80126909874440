import Presse3rModalImport from './presse3r.modal.import.model';

export default class Presse3rModalImportController {
    /* @ngInject */
    constructor(_, $scope, $stateParams, $timeout, MassiaApplicationService, Presse3rExportService, MesuresService) {
        this._ = _;
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.timeout = $timeout;
        this.massiaAppService = MassiaApplicationService;
        this.Presse3rExportService = Presse3rExportService;
        this.MesuresService = MesuresService;
        this.loading = false;
        this.type = 'info';
        this.value = 0;
        this.codeExist = false;
        this.enumMachineType = {
            1: 'PRESSE_3R.ENUM.1',
            2: 'PRESSE_3R.ENUM.2'
        };
        this.results = [];
        this.resultImportMessage = {
            0: 'DONE',
            1: 'PRELEV_NOT_FOUND',
            2: 'BAD_FILE',
            3: 'ERROR'
        };

        this.selectedFiles = [];
    }

    async $onInit() {
        this.famille = this.resolve.famille;
        this.haveIterateOrder = this.resolve.haveIterateOrder;

        this.form = {
            datas: [],
            files: []
        };
    }

    async change(event) {
        this.selectedFiles = event.target.files;
        for (let i = 0; i < this.selectedFiles.length; i++) {
            const file = this.getFileAndExtension(this.selectedFiles[i].name);

            const fileData = new Presse3rModalImport();
            fileData.libelle = file[0];
            fileData.extension = file[1];
            fileData.famille = this.famille;
            // fileData.machineType = this.form.machineType; //Todo surement a prendre en compte si differentes machines

            this.form.datas[i] = fileData;
        }

        this.fileInfoOpen = true;
        this.loading = false;
    }

    async importFiles() {
        if (!this.selectedFiles || this.selectedFiles.length === 0) {
            this.progress = 'Please choose a file.';
            return;
        }

        this.loading = true;
        // Disable cancel button in HTML
        this.isCharging = true;
        // Set progress bar to 50% with state message
        this.value = 50;
        this.progress = 'DOCUMENTS.SENDING_STATE.PROGRESS';
        try {
            let isFullTransfertSuccess = true;

            const formData = new FormData();
            formData.append('infos', angular.toJson(this.form.datas));

            this._.forEach(this.selectedFiles, (f, index) => formData.append(this.form.datas[index].libelle, f));

            this.results = await this.Presse3rExportService.uploadFile(formData);

            //TODO Gestion d'erreurs

            if (this.results.find((res) => res.success === false)) {
                isFullTransfertSuccess = false;
            }

            this.progress = 'DOCUMENTS.SENDING_STATE.DONE';
            this.type = 'success';
            if (!isFullTransfertSuccess) {
                this.progress = 'DOCUMENTS.SENDING_STATE.PRELEV_NOT_FOUND';
                this.type = 'warning';
            }
            this.value = 100;
            this.fileInfoOpen = true;
            this.isCharging = false;
        } catch (err) {
            console.log(err);
            this.type = 'danger';
            this.value = 100;
            this.progress = err.data.message || err.data;
            this.isCharging = false;
        }
    }

    cancel() {
        this.$scope.$parent.$dismiss();
    }

    getFileAndExtension(file) {
        return file.split('.');
    }

    selectFiles() {
        document.getElementById('file-upload').click();
    }
}
