export default function Configure($stateProvider) {
    $stateProvider.state('parametrage-general', {
        parent: 'common',
        url: '/parametrage-general',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.OUTILS" | translate }}'
        }
    });

    $stateProvider.state('parametrage-general.edit', {
        url: '/edit',
        template: '<parametrage-general></parametrage-general>',
        rights: { domain: 'paramprog', right: 'read' },
        ncyBreadcrumb: {
            parent: 'parametrage-general',
            label: '{{ "PARAMETRAGE_GENERAL.BREADCRUMBS.PARAMETRAGE_GENERAL" | translate }}'
        },
        search: 'PARAMETRAGE_GENERAL.BREADCRUMBS.PARAMETRAGE_GENERAL'
    });
}

Configure.$inject = ['$stateProvider'];
