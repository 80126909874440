export default function Configure($stateProvider) {
    $stateProvider.state('motif-refus-admission', {
        parent: 'gestion',
        url: '/motif-refus-admission',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
        }
    });

    $stateProvider.state('motif-refus-admission.list', {
        url: '/list',
        template: '<motif-refus-admission></motif-refus-admission>',
        rights: { domain: 'motifrefusadmis', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "MOTIFS_REFUS_ADMISSION.BREADCRUMBS.MOTIFS_LIST" | translate}}'
        },
        // on affiche la liste des motifs dans la navigation
        navigation: {
            menu: 'inertes',
            translate: 'MOTIFS_REFUS_ADMISSION.BREADCRUMBS.MOTIFS_LIST',
            order: 34
        },
        // la liste des motifs fait partie des points d'entrée recherchables dans la navigation
        search: 'MOTIFS_REFUS_ADMISSION.BREADCRUMBS.MOTIFS_LIST'
    });

    $stateProvider.state('motif-refus-admission.new', {
        url: '/new',
        template: '<motif-refus-admission-form></motif-refus-admission-form>',
        rights: { domain: 'motifrefusadmis', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher motifs > ajouter
            parent: 'motif-refus-admission.list',
            label: '{{ "MOTIFS_REFUS_ADMISSION.BREADCRUMBS.MOTIF_NEW" | translate}}'
        },
        // l'ajout d'une motif fait partie des points d'entrée recherchables dans l'application
        search: 'MOTIFS_REFUS_ADMISSION.BREADCRUMBS.MOTIF_NEW'
    });

    $stateProvider.state('motif-refus-admission.edit', {
        url: '/{id}/edit',
        template: '<motif-refus-admission-form></motif-refus-admission-form>',
        rights: { domain: 'motifrefusadmis', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher motifs > modifier
            parent: 'motif-refus-admission.list',
            label: '{{ "MOTIFS_REFUS_ADMISSION.BREADCRUMBS.MOTIF_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
