const serviceUrl = `${__configuration.apiUrl}/massia/synthAdmin/synthEnt`;

export default class SyntheseEntitiesService {
    static $inject = ['$http', '_', 'FilterHelperService', '$translate'];

    constructor($http, _, FilterHelperService, $translate) {
        this.$http = $http;
        this._ = _;
        this.FilterHelperService = FilterHelperService;
        this.$translate = $translate;
    }

    async getSyntheseEntities(filters, sorts, pagination, activite) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {}),
                activite: JSON.stringify(activite || null)
            }
        });
        return res.data;
    }

    async getTypeSyntheses() {
        const url = 'assets/enum/synthadmin/typeSynthese.json';
        const res = await this.$http.get(url);
        for (let i = 0; i < res.data.length; i++) {
            if (res && res.data && res.data.length > 0) {
                const data = res.data[i];
                data.libelle = this.$translate.instant(data.translate);
            }
        }
        const listeTriee = res.data.sort((a, b) => {
            const fa = a.libelle.toLowerCase();
            const fb = b.libelle.toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
        return listeTriee;
    }

    async deleteSyntheseEntityById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async deleteSyntheseEntityUserById(id) {
        const url = `${serviceUrl}/delete-for-user/${id}`;
        return this.$http.delete(url);
    }

    async deleteSyntheseEntityCdcById(id) {
        const url = `${serviceUrl}/deleteCdc/${id}`;
        return this.$http.delete(url);
    }

    async getSyntheseEntityById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSyntheseEntityByIdForUser(id) {
        const url = `${serviceUrl}/for-user/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createSyntheseEntity(selectedSyntheseEntity) {
        const res = await this.$http.post(serviceUrl, selectedSyntheseEntity);
        return res.data;
    }

    async updateSyntheseEntity(selectedSyntheseEntity) {
        const url = `${serviceUrl}/${selectedSyntheseEntity.id}`;
        return this.$http.put(url, selectedSyntheseEntity);
    }

    async updateSyntheseEntityForUser(selectedSyntheseEntity) {
        const url = `${serviceUrl}/for-user/${selectedSyntheseEntity.id}`;
        return this.$http.put(url, selectedSyntheseEntity);
    }

    async getModelesSynthese(typeDeSyntheseId) {
        const url = `${__configuration.apiUrl}/massia/synthAdmin/synthMod/headers/${typeDeSyntheseId}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getTrameById(id) {
        const url = `${__configuration.apiUrl}/massia/trames/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    /*async getReferencesByNormeForSynthese(codeDomaine, listproduitids, listproducteurids) {
        let url = `${__configuration.apiUrl}/massia/references/header-for-synthese/${codeDomaine}`;
        let res = await this.$http.get(url, {
            params: {
                listproduitids: JSON.stringify(listproduitids || []),
                listproducteurids: JSON.stringify(listproducteurids || [])
            }
        });
        return res.data;
    }*/

    async getReferencesByParamsForSynthese(lstIdsProduit, lstIdsSiteProd, lstIdsSiteClient, periodes, idFamille, recompo) {
        if (idFamille) {
            let referenceUrl = `${__configuration.apiUrl}/massia/references/by-filtre`;
            if (recompo) {
                referenceUrl = `${__configuration.apiUrl}/massia/references/by-filtre-recompo`;
            }
            const parametres = {
                lstIdsSiteProd: lstIdsSiteProd || 0,
                lstIdsProduit: lstIdsProduit || 0,
                lstIdsSiteClient: lstIdsSiteClient || 0,
                periodes: periodes || null,
                idFamille: idFamille || 0
            };
            const res = await this.$http.get(referenceUrl, {
                params: {
                    parametres: JSON.stringify(parametres || null)
                }
            });
            let lstRetour = res.data;
            lstRetour = this._.sortBy(lstRetour, function (elt) {
                return elt.normeCoLib;
            });
            return lstRetour;
        }
        return [];
    }

    async getTramesForSynthese(codeDomaine, listproducteurids) {
        const url = `${__configuration.apiUrl}/massia/trames/header-for-synthese/${codeDomaine}`;
        const res = await this.$http.get(url, {
            params: {
                listproducteurids: JSON.stringify(listproducteurids || [])
            }
        });
        return res.data;
    }

    /* async getEssais(typeSynth, idModeleSynthese) {
        if (idModeleSynthese) {
            let url = `${__configuration.apiUrl}/massia/mesures/headers/${typeSynth}`;
            if (idModeleSynthese) url += `/${idModeleSynthese}`;

            const res = await this.$http.get(url);
            return res.data;
        } else return [];
    } */

    async getEssais(modele, old = false) {
        if (modele) {
            const url = `${__configuration.apiUrl}/massia/mesures/headers/synthese`;

            const res = await this.$http.get(url, {
                params: {
                    modele: Array.isArray(modele) ? modele.join(',') : modele,
                    old: old ? true : false
                }
            });
            return res.data;
        }
        return [];
    }

    async getNormes(idFamille) {
        const url = `${__configuration.apiUrl}/massia/normes`;
        const filters = [];
        filters.push(this.FilterHelperService.eqFilter(idFamille, 'IdFamille'));

        const res = await this.$http.get(url, {
            params: {
                filters: JSON.stringify(filters),
                sorts: JSON.stringify([]),
                pagination: JSON.stringify({ skip: 0, take: 1000 })
            }
        });
        return res.data ? res.data.items : [];
    }

    async getNQAs() {
        const url = `${__configuration.apiUrl}/massia/nqa`;
        const res = await this.$http.get(url);
        return res.data.items;
    }

    async getExclusions() {
        const url = `${__configuration.apiUrl}/massia/syntheseexclusions`;
        const res = await this.$http.get(url);
        return res.data.items;
    }

    async getSousEssais(listEssaisId) {
        const url = `${__configuration.apiUrl}/massia/sous-mesures`;
        const res = await this.$http.post(url, listEssaisId);
        return res.data;
    }

    async getNormeSousEssais(idNorme) {
        const url = `${__configuration.apiUrl}/massia/sous-mesures/${idNorme}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getTamis(listSousEssaisId) {
        const url = `${__configuration.apiUrl}/massia/seriestamis-tamis`;
        const res = await this.$http.get(url, {
            params: {
                listids: JSON.stringify(listSousEssaisId || [])
            }
        });
        return res.data;
    }

    async getDimensionsRemarquables() {
        const url = `${__configuration.apiUrl}/massia/dim-rq`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getIdsFromTable(critere, block, idFamille) {
        let listIdsToSend = [];
        if (critere.selectedEltsTable && critere.selectedEltsTable.length > 0) {
            listIdsToSend = critere.selectedEltsTable.map((a) => a.id);
        }
        //SP gestion des filtres entre tables
        const objFiltre = { sitesProducteurs: [], produits: [], typeProduits: [] };
        if (critere.domaineCode == 'Produits' || critere.domaineCode == 'ProduitsCom' || critere.domaineCode == 'FamBeton') {
            //chercher si il y a un/des producteur(s)
            let crits = this._.find(block.listeSyntheseCritereValeurLignes, { domaineCode: 'SiteProd' });
            if (crits && crits.selectedEltsTable) {
                const idCrit = [];
                crits.selectedEltsTable.forEach((crit) => {
                    idCrit.push(crit.id);
                });
                objFiltre.sitesProducteurs = idCrit;
            }
            //filter Produit by type produits
            if (critere.domaineCode == 'Produits' || critere.domaineCode == 'FamBeton') {
                if (critere.libelleTotalChamp.includes('Type')) {
                    crits = block.listeSyntheseCritereValeurLignes.find((x) => x.domaineCode === 'Produits' && x.libelleTotalChamp.includes('Type'));
                } else {
                    crits = block.listeSyntheseCritereValeurLignes.find((x) => x.domaineCode === 'Produits');
                }
            } else if (critere.domaineCode == 'ProduitsCom') {
                if (critere.libelleTotalChamp.includes('Type')) {
                    crits = block.listeSyntheseCritereValeurLignes.find(
                        (x) => x.domaineCode === 'ProduitsCom' && x.libelleTotalChamp.includes('Type')
                    );
                } else {
                    crits = block.listeSyntheseCritereValeurLignes.find((x) => x.domaineCode === 'ProduitsCom');
                }
            }
            if (crits && crits.selectedEltsTable) {
                const idCrit = [];
                crits.selectedEltsTable.forEach((crit) => {
                    idCrit.push(crit.id);
                });
                if (crits.champ === 5) {
                    objFiltre.typeProduits = idCrit;
                } else {
                    objFiltre.produits = idCrit;
                }
            }
        } else if (critere.domaineCode == 'SiteProd') {
            //chercher si il y a un/des produit(s)
            let crits = block.listeSyntheseCritereValeurLignes.filter((x) => x.domaineCode === 'Produits');
            if (crits && crits.length > 0) {
                for (let i = 0; i < crits.length; ++i) {
                    const crit = crits[i];
                    if (crit.selectedEltsTable) {
                        const idCrit = [];
                        crit.selectedEltsTable.forEach((elt) => {
                            idCrit.push(elt.id);
                        });
                        if (crit.champ === 5) {
                            objFiltre.typeProduits = idCrit;
                        } else {
                            objFiltre.produits = idCrit;
                        }
                    }
                }
            }
            crits = this._.find(block.listeSyntheseCritereValeurLignes, { domaineCode: 'SiteProd' });
            if (crits && crits.selectedEltsTable) {
                const idCrit = [];
                crits.selectedEltsTable.forEach((crit) => {
                    idCrit.push(crit.id);
                });
                objFiltre.sitesProducteurs = idCrit;
            }
        }
        //la famille est donnée par le modèle de sélection
        /*else if (critere.domaineCode == 'Famille') {
            //chercher si il y a un/des produit(s)
            let critere = this._.find(block.listeSyntheseCritereValeurLignes, { domaineCode: 'Famille' });
            if (critere) {
                if (critere.selectedEltsTable) {
                    let idCrit = [];
                    critere.selectedEltsTable.forEach((crit) => { idCrit.push(crit.id); });
                    objFiltre.familles = idCrit;
                }
            }
        }*/
        const url = `${serviceUrl}/${critere.domaineCode}/typeAhead/${critere.champ}/${idFamille}`;
        const res = await this.$http.get(url, {
            params: {
                // pour éviter de renvoyer dans le typeAhead des éléments qui sont déjà dans la liste de sélection
                listids: JSON.stringify(listIdsToSend || []),
                // si la ligne est une carac, il faut l'Id pour filtrer sur la carac correspondante
                idCaracteristique: JSON.stringify(critere.idCaracteristique || null),
                filtres: JSON.stringify(objFiltre)
            }
        });
        return res.data;
    }

    async getSynthSelection(id) {
        const url = `${serviceUrl}/${id}/selection`;
        const res = await this.$http.get(url);
        return res.data;
    }

    // pour les numériques
    async getOperators() {
        const url = 'assets/enum/synthadmin/syntheseOperators.json';
        const res = await this.$http.get(url);
        return res.data;
    }

    // pour les dates
    async getPeriodesGlissantes(activite) {
        let url = '';
        if (activite == 'laboratoire') {
            url = 'assets/enum/synthadmin/synthesePeriodesGlissantesLabo.json';
        } else if (activite == 'qse') {
            url = 'assets/enum/synthadmin/synthesePeriodesGlissantesQse.json';
        } else if (activite === 'gestion') {
            url = 'assets/enum/synthadmin/synthesePeriodesGlissantesGestion.json';
        }

        const res = await this.$http.get(url);
        return res.data;
    }

    // période glissante ou gamme de temps
    async getChoixPeriode() {
        const url = 'assets/enum/synthadmin/choixPeriode.json';
        const res = await this.$http.get(url);
        return res.data;
    }

    // Conformite pour Prélèvement
    async getListConformite() {
        const url = 'assets/enum/synthadmin/conformite.json';
        const res = await this.$http.get(url);
        return res.data;
    }

    // Clôture des Prélèvements
    async getOptionCloture() {
        const url = 'assets/enum/synthadmin/cloture.json';
        const res = await this.$http.get(url);
        return res.data;
    }
    async getCalculsStatistiques(activite) {
        const url = `${__configuration.apiUrl}/massia/calculs-statistiques`; //pas -list pour avoir aussi les formules
        const res = await this.$http.get(url, {
            params: {
                filters: JSON.stringify([]),
                sorts: JSON.stringify([]),
                pagination: JSON.stringify({ skip: 0, take: 0 }),
                application: JSON.stringify(activite)
            }
        });
        return res.data.items;
    }

    async genererSynthese(synthEnt, activite) {
        const url = `${serviceUrl}/generer/${activite}`;
        try {
            const result = await this.$http.post(url, synthEnt);
            return result.data;
        } catch (exc) {
            if (exc.data) {
                exc.data.message = this.$translate.instant(exc.data.message);
                throw exc;
            }
        }
    }

    async regenererSynthese(synthEnt, activite) {
        const url = `${serviceUrl}/regenerer/${activite}`;
        const result = await this.$http.post(url, synthEnt);
        return result.data;
    }

    async getSelectionFromModele(idModeleSelection) {
        const url = `${serviceUrl}/selection-modele/${idModeleSelection}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async printFTP(data, template) {
        const url = `${serviceUrl}/export-ftp`;
        const res = await this.$http.post(url, {
            ftp: data,
            template: template
        });
        return res.data;
    }
    async printSyntheseExcel(data, template, config) {
        const url = `${serviceUrl}/export-synthese-excel`;
        const res = await this.$http.post(url, {
            data: data,
            template: template,
            configExcel: config
        });
        return res.data;
    }
    async printBilanPrel(data, template) {
        const url = `${serviceUrl}/export-synth-prels`;
        const res = await this.$http.post(url, {
            data: data,
            template: template
        });
        return res.data;
    }
    async printRegistre3(data, template) {
        const url = `${serviceUrl}/export-registre3`;
        const res = await this.$http.post(url, {
            data: data,
            template: template
        });
        return res.data;
    }
    async printRecompo(data, template) {
        const url = `${serviceUrl}/export-recompo`;
        const res = await this.$http.post(url, {
            data: data,
            template: template
        });
        return res.data;
    }
    async printCtrlAcq(data, template) {
        const url = `${serviceUrl}/export-ctrl-acq`;
        const res = await this.$http.post(url, {
            acq: data,
            template: template
        });
        return res.data;
    }

    async printRapportConformite(data, template) {
        const url = `${serviceUrl}/export-rapport-conformite`;
        const res = await this.$http.post(url, {
            rapport: data,
            template: template
        });
        return res.data;
    }

    async saveFTPArchivee(ftpa) {
        const ftpaUrl = `${__configuration.apiUrl}/massia/ftp-archivee`;
        const res = await this.$http.post(ftpaUrl, ftpa);
        return res.data;
    }

    async GetSerieTamis(idEssai) {
        const serieTamUrl = `${__configuration.apiUrl}/massia/seriestamis-tamis/by-essai/${idEssai}`;
        const result = await this.$http.get(serieTamUrl);
        return result.data;
    }

    GetDecompo(id) {
        return this.$http.get(`${serviceUrl}/decomposition`, {
            params: {
                id: id
            }
        });
    }

    CreateDecompo(id, data) {
        data.idSynthese = id;
        return this.$http.post(`${serviceUrl}/decomposition`, data);
    }

    async printCdcBeton(essais, ssEssais, synthese, laboratoire, template, stats, referentiel, table) {
        const url = `${serviceUrl}/export-cdc-beton`;

        const res = await this.$http.post(url, {
            essais: essais,
            ssEssais: ssEssais,
            synthese: synthese,
            laboratoire: laboratoire,
            template: template,
            stats: stats,
            referentiel: referentiel,
            displayedTable: table
        });

        return res.data;
    }

    async getCalendriersEnrobes(idProducteur) {
        const calendUrl = `${__configuration.apiUrl}/massia/laboratoire/calendar/frequence-nce/byProducteur/${idProducteur}`;
        const result = await this.$http.get(calendUrl);
        return result.data;
    }

    async printCtrlEnr(ctrlEnr, filename) {
        const url = `${serviceUrl}/print-enr`;
        const res = await this.$http.post(url, {
            template: filename,
            ctrl: ctrlEnr
        });

        return res.data ? res.data : null;
    }

    async exporter(lignes, affichages) {
        const url = `${serviceUrl}/export-excel-prelevement`;
        const res = await this.$http.post(
            url,
            {
                params: {
                    lignes: lignes,
                    affichages: affichages
                }
            },
            {
                responseType: 'arraybuffer'
            }
        );
        return res;
    }

    async ExportExcelRegistre3(data) {
        const url = `${serviceUrl}/export-excel-registre3`;
        const res = await this.$http.post(
            url,
            {
                data: data
            },
            {
                responseType: 'arraybuffer'
            }
        );

        return res;
    }
}
