import { BonLivraison } from '../bon-livraison/BonLivraison';
import Prelevement from '../prelevement.model';

export default class PrelevementEnteteController {
	/*@ngInject */
	constructor(
		$rootScope,
		$scope,
		$state,
		$stateParams,
		CaracteristiquesTypesService,
		PrelevementsService,
		ModalService,
		notification,
		$translate,
		$uibModal,
		MassiaApplicationService,
		SitesService,
		$filter,
		MassiaRightsService,
		TypesService,
		SaisieProductionService,
		RouterHistoryService,
		moment,
		globalizationManagementService,
		ProduitsService,
		TicketsService,
		BonsService,
		MassiaAuthService,
		PrelevementImportDataService,
		$timeout,
		FormuleService,
		_,
		FormuleCalculsService,
		ChantiersService
	) {
		this.FormuleCalculsService = FormuleCalculsService;
		this._ = _;
		this.FormuleService = FormuleService;
		this.$timeout = $timeout;
		this.$rootScope = $rootScope;
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.CaracteristiquesTypesService = CaracteristiquesTypesService;
		this.PrelevementsService = PrelevementsService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.$translate = $translate;
		this.$uibModal = $uibModal;
		this.MassiaApplicationService = MassiaApplicationService;
		this.SitesService = SitesService;
		this.$filter = $filter;
		this.MassiaRightsService = MassiaRightsService;
		this.TypesService = TypesService;
		this.SaisieProductionService = SaisieProductionService;
		this.RouterHistoryService = RouterHistoryService;
		this.moment = moment;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
		this.ProduitsService = ProduitsService;
		this.TicketsService = TicketsService;
		this.BonsService = BonsService;
		this.MassiaAuthService = MassiaAuthService;
		this.PrelevementImportDataService = PrelevementImportDataService;
		this.ChantiersService = ChantiersService;
	}

	async $onInit() {
		this.hasCommercialProduct = this.saisie.famille?.typeProduits.findIndex((tp) => tp.idTypeLie > 0) > -1;
		this.certificationAccess = this.MassiaRightsService.userHasRight('certification', 'read', 'laboratoire');
		this.canAccessSaisieProd = this.MassiaRightsService.userHasRight('saisieprod', 'read');
		this.displaySaisieProduction = false;
		this.personnes = [];
		this.pointsMesure = [];
		this.unregister = [];
		this.produits = [];
		this.oldProduits = [];
		this.produitsCommerciaux = [];

		this.siteAuditeurs = [this.saisie.auditeur];
		this.siteLaboratoires = [this.saisie.auditeur];
		this.siteProductions = [this.saisie.producteur];
		this.siteClients = [this.saisie.client];
		this.produits = [this.saisie.produit];
		this.bonsLivraison = [this.saisie.bonLivraison];
		this.bonsLivraisonOrigine = [];
		this.saisie.certifProduit = null;

		await this.getCodeAuto();
		this.modeLocalisation =
			this.MassiaApplicationService.getApplication() === 'qse'
				? this.PrelevementsService.getLocalisationPointsMesure(this.$stateParams.idTheme)
				: 2;
		if (!this.campagne) {
			await this.getTypes();
			await this.loadPersonnes();
		}
		if (this.MassiaApplicationService.getApplication() === 'qse') {
			await this.loadPointsMesure();
		} else {
			await this.loadProduits();
		}
		await this.getNiveauVisibilite();

		await this.loadListIsteven();

		if (this.certificationAccess) {
			await this.checkProductCertificat();
		}

		this.saisie = this.saisie || {};

		//? commenté : si l'heure n'est pas renseignée sur un prelèvement il faut la laisser vide
		// if (!this.saisie.heure /*!this.saisie.hasOwnProperty('heure')*/) {
		//     this.saisie.heure = new Date();
		// }
		/**
		 * Bons livraison
		 *      → initialise le service, permet de récupérer le contenu du fichier de configuration
		 * Pour le moment spécifique EQIOM
		 */

		if (__configuration.licenceLaboratoire === '2' && this.saisie.famille && this.saisie.famille.code === 'BPE') {
			await this.PrelevementImportDataService.init(this.dateFormat);
			await this.checkEssais();
			await this.getSaisieValuesFromBL();
			// await this.getDataFromPrelevement();
		}
	}

	log(data) {
		console.log(data);
	}

	// saisie des productions
	async getProduit() {
		const produitSelected = this.produits.find((x) => x.selected);
		if (produitSelected) {
			try {
				this.selectedProductType = await this.TypesService.getTypeById(produitSelected.idType);
				this.displaySaisieProduction = this.selectedProductType.uniteProduction ? true : false;
				//* ajoute le jour de production à la date du prél si il n'en existe pas
				if (this.displaySaisieProduction && !this.saisie.joursProduction) {
					this.saisie.joursProduction = [];
					const jourProd = new JourProduction();
					jourProd.valeur = 0;
					this.saisie.joursProduction[0] = jourProd;
				}
				return produitSelected;
			} catch (e) {
				console.error(e);
			}
		}
		return null;
	}

	async manageSaisieProduction() {
		if (this.canAccessSaisieProd) {
			try {
				const produit = await this.getProduit();
				if (produit && this.displaySaisieProduction) {
					const date = this.saisie.date;

					await this.getJourProductionByProductByDate(produit, date);
					// this.getIdxOfProductionDay()
				}
			} catch (err) {
				console.error(err);
			}
		}
	}

	async getJourProductionByProductByDate(produit, date) {
		try {
			const query = new Query();
			query.idProduit = produit.id;
			query.period.du = date;
			query.period.au = date;
			this.saisie.joursProduction = await this.SaisieProductionService.getOneJourProduction(query);

			if (this.saisie.joursProduction?.length === 0 && produit.id && this.saisie.producteur.id) {
				const updateObject = new Response();
				const jourProd = new JourProduction();
				jourProd.valeur = 0;
				jourProd.date = date;
				jourProd.idProduit = produit.id;
				jourProd.idSiteProducteur = this.saisie.producteur.id;
				updateObject.listJourProduction.push(jourProd);
				jourProd.id = await this.SaisieProductionService.update(updateObject, true);
				this.saisie.joursProduction[0] = jourProd;

				//! Recursion ?
				// this.getJourProductionByProductByDate(produit, date)
			}
			this.jourProduction = this.saisie.joursProduction[0];
		} catch (e) {
			console.error('getSaisieProd', e);
		}
	}

	//      this.saisieProduction = getSaisieProduction(produit.id)
	getIdxOfProductionDay() {
		this.idxOfProductionDay = {};
		const x = (this.idxOfProductionDay.idxProduit = this.saisie.saisieProduction.produits.findIndex(
			(p) => p.produit.id === this.saisie.produit.id
		));
		const y = (this.idxOfProductionDay.idxJour = this.saisie.saisieProduction.produits[x].joursProduction.findIndex(
			(jp) => jp.date === this.saisie.date
		));

		const idxNotFound = -1;
		if (this.idxOfProductionDay.idxJour === idxNotFound) {
			const productionDayToAdd = new JourProduction();
			// let date = this.moment(this.saisie.date, this.dateFormat).toDate();

			productionDayToAdd.date = this.saisie.date;
			productionDayToAdd.produit = this.saisie.saisieProduction.produits[x].produit;
			this.saisie.saisieProduction.produits[x].joursProduction.push(productionDayToAdd);
			this.getIdxOfProductionDay();
		}
	}

	async loadListIsteven() {
		try {
			await this.loadSiteAuditeurs();
			await this.loadSiteLaboratoires();
			await this.loadSiteProduction();
			await this.loadSiteClients();
			//ne pas charger la liste totale des chantiers → filtrer par client
			await this.loadSiteChantiers();
			await this.loadBonLivraison();
			// this.getType();
		} catch (e) {
			console.error(e);
		}
	}

	async loadSiteAuditeurs() {
		this.siteAuditeurs = await this.SitesService.getAllSitesAuditeurs();
	}

	async loadSiteLaboratoires() {
		this.siteLaboratoires = await this.SitesService.getSitesLaboratoiresFiltreUser();
	}

	async loadSiteProduction() {
		this.siteProductions = await this.getSitesProducteurs();
	}

	async loadSiteClients() {
		this.siteClients = await this.SitesService.getAllSitesClients();
	}

	async loadSiteChantiers() {
		this.siteChantiers = [];
		if (this.saisie && this.saisie.client && this.saisie.client.id) {
			const res = await this.ChantiersService.getChantiersByClients(this.saisie.client.id);
			if (res && res.length > 0) {
				this.siteChantiers = res;
			}
		}
		if (this.saisie?.chantier?.id > 0) {
			const idx = this.siteChantiers.findIndex((x) => x.id === this.saisie.chantier.id);
			this.siteChantiers[idx].selected = true;

			if (this.saisie?.bonLivraison?.id > 0) {
				const selected = this.bonsLivraison.find((x) => x.id === this.saisie.bonLivraison.id);
				this.disabledChantier = selected?.chantier?.id === this.saisie?.chantier?.id;
			} else {
				this.disabledChantier = true;
			}
		}
	}

	getDomaineCourant() {
		if (this.codeDomaine) {
			return this.codeDomaine;
		} else if (
			this.$state.current.name === 'saisies.edit-prelevement' ||
			this.$state.current.name === 'saisies.new-prelevement' ||
			this.$state.current.name === 'saisies.duplicate-prelevement' ||
			this.$state.current.name === 'saisies.edit-campagne' ||
			this.$state.current.name === 'saisies.new-campagne' ||
			this.$state.current.name === 'saisies.duplicate-campagne'
		) {
			return 'saisies';
		} else if (
			this.$state.current.name === 'saisiectrl.edit-prelevement' ||
			this.$state.current.name === 'saisiectrl.new-prelevement' ||
			this.$state.current.name === 'saisiectrl.duplicate-prelevement' ||
			this.$state.current.name === 'saisiectrl.edit-campagne' ||
			this.$state.current.name === 'saisiectrl.new-campagne' ||
			this.$state.current.name === 'saisiectrl.duplicate-campagne'
		) {
			return 'saisiectrl';
		} else if (
			this.$state.current.name === 'saisiesecu.edit-prelevement' ||
			this.$state.current.name === 'saisiesecu.new-prelevement' ||
			this.$state.current.name === 'saisiesecu.duplicate-prelevement' ||
			this.$state.current.name === 'saisiesecu.edit-campagne' ||
			this.$state.current.name === 'saisiesecu.new-campagne' ||
			this.$state.current.name === 'saisiesecu.duplicate-campagne'
		) {
			return 'saisiesecu';
		} else if (
			this.$state.current.name === 'prelevements.edit-prelevement' ||
			this.$state.current.name === 'prelevements.new-prelevement' ||
			this.$state.current.name === 'prelevements.duplicate-prelevement' ||
			this.$state.current.name === 'prelevements.edit-campagne' ||
			this.$state.current.name === 'prelevements.new-campagne' ||
			this.$state.current.name === 'prelevements.duplicate-campagne'
		) {
			return 'prelevements';
		}
	}

	async getCodeAuto() {
		try {
			this.saisie.estCodeAuto = await this.PrelevementsService.estCodeAuto(this.getDomaineCourant());
			if (this.saisie.estCodeAuto) {
				this.saisie.afficheCode = await this.PrelevementsService.afficheCode(this.getDomaineCourant());
			} else {
				this.saisie.afficheCode = true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async getNiveauVisibilite() {
		if (this.saisie.type.id) {
			this.saisie.niveauVisibilite = await this.CaracteristiquesTypesService.GetNiveauVisibilite(this.saisie.type.id);
		}
	}

	async loadPointsMesure() {
		/*var loadingActu = this.loading;
        if (!loadingActu)
            this.startLoading();*/
		this.startPointMesureLoading();
		if (this.saisie.producteur.id) {
			this.pointsMesure = await this.PrelevementsService.getPointsMesure(
				this.saisie.producteur.id,
				this.idTheme ? this.idTheme : this.$stateParams.idTheme
			);
		}
		this.stopPointMesureLoading();
		/*if (!loadingActu)
            this.stopLoading();*/
	}

	async loadProduits() {
		if (this.saisie.producteur && this.saisie.producteur.id) {
			this.produits = await this.PrelevementsService.getProduits(
				this.saisie.producteur.id,
				this.idFamille ? this.idFamille : this.$stateParams.idFamille
			);
			this.oldProduits = this.produits;
			if (this.saisie.famille.code === 'BPE' && this.saisie.produit && this.saisie.produit.id) {
				await this.loadProduitsCommerciaux();
			}
			await this.manageSaisieProduction();
		} else {
			this.produits = [];
			this.produitsCommerciaux = [];
		}
	}

	async loadProduitsCommerciaux() {
		if (this.saisie.produit && this.saisie.produit.id) {
			const res = await this.ProduitsService.getAllProduitCommerciaux(this.saisie.produit.id, [], []);

			this.produitsCommerciaux = res.produitsCom ?? [];

			if (this.saisie.produit.id > 0) {
				await this.filterBL();
			}
		} else {
			this.produitsCommerciaux = [];
		}
	}

	async changePersonnes() {
		await this.loadPersonnes();
	}

	async changeProduit() {
		await this.loadProduits();
	}

	async changeProducteur() {
		await this.loadSiteProduction();
	}

	async loadPersonnes() {
		if (this.saisie.auditeur && this.saisie.auditeur.id) {
			this.personnes = await this.PrelevementsService.getPersonnes(this.saisie.auditeur.id);
			// Set the current user as 'made by' by default
			if (!this.$stateParams.id && this.saisie.personneFaitPar && !this.saisie.personneFaitPar.id) {
				this.saisie.personneFaitPar.id = this.MassiaAuthService.getAuthenticatedUser().id;
			}
		}
	}

	async loadBonLivraison() {
		await this.loadAllBLByDateAndProducteur();
		await this.getEnteteFromBL();
		if (this.saisie.bonLivraison && this.saisie.bonLivraison.id > 0) {
			this.blLoaded();
		}
	}

	async reloadEssaiCertifications() {
		this.saisie.mesures = await this.PrelevementsService.getSaisieListeMesuresById(this.saisie.id, this.saisie.auditeur.id);
	}

	async createType() {
		const modalInstance = this.ModalService.confirm({
			modalTitle: 'PRELEVEMENTS.CONFIRM_CREATE_TYPE.TITLE',
			modalMsg: 'PRELEVEMENTS.CONFIRM_CREATE_TYPE.MESSAGE',
			headerClass: 'modal-warning'
		});
		modalInstance.result.then(() => {
			this.saisie.outsourceModel(this.moment, this.dateFormat);
			this.RouterHistoryService.data = this.saisie;
			this.$state.go('prelevements.new-type-prelevement');
		});
	}

	async getTypes() {
		this.startTypesLoading();

		try {
			this.types = await this.CaracteristiquesTypesService.getAll(
				this.idTheme ? this.idTheme : this.$stateParams.idTheme,
				this.getDomaineCourant(),
				this.idFamille ? this.idFamille : this.$stateParams.idFamille
			);
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopTypesLoading();
		}
	}

	async getSitesProducteurs() {
		let sites = [];
		try {
			// Récupère la liste des producteurs liés au laboratoire (auditeur.id)
			sites = await this.PrelevementsService.getSitesProducteurs(
				'null',
				this.MassiaApplicationService.getApplication(),
				this.saisie.auditeur.id
			);
			if (sites.length > 0) {
				// Récupère la liste des producteurs par rapport à la famille
				const sitesProducteursByFamille = await this.SitesService.getSitesProducteursByFamille(
					this.MassiaApplicationService.getApplication(),
					this.saisie.famille.code
				);
				// Filtre et retourne les producteurs liés au laboratoire en fonction de la famille donnée
				return sites.filter((site) => sitesProducteursByFamille.some((x) => x.id === site.id));
			}
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async checkCodeUnicity(code) {
		if (code) {
			this.startCodeLoading();

			try {
				if (code.match(/^[a-zA-Z0-9_|]*$/)) {
					this.saisie.codeExists = await this.PrelevementsService.codeExists(code);
				}
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				this.stopCodeLoading();
			}
		} else {
			this.saisie.codeExists = null;
		}
	}

	openModal = function (size, parentSelector) {
		const _this = this;
		if (_this.modeLocalisation < 2) {
			this.$uibModal
				.open({
					template:
						'<prelevement-carte entete="$ctrl.saisie" mode-localisation="$ctrl.modeLocalisation" modal-instance="$ctrl.uibModalInstance"></prelevement-carte>',
					controller: [
						'$uibModalInstance',
						function ($uibModalInstance) {
							const $ctrl = this;
							$ctrl.saisie = _this.saisie;
							$ctrl.modeLocalisation = _this.modeLocalisation;
							$ctrl.uibModalInstance = $uibModalInstance;
						}
					],
					controllerAs: '$ctrl',
					size: 'xxl',
					backdrop: false
				})
				.result.then(
					function (result) {
						_this.saisie.longitude = result.longitude;
						_this.saisie.latitude = result.latitude;
					},
					function (reason) {
						// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
					}
				);
		}
	};

	async dateBlured(input) {
		let isDateChange = false;
		if (this.saisie.date !== this.saisie.datePrecedente) {
			isDateChange = true;
		}
		this.saisie.date = angular.copy(this.saisie.datePrecedente);

		await this.loadAllBLByDateAndProducteur();
		if (__configuration.licenceLaboratoire === '2' && this.saisie.famille.code === 'BPE' && isDateChange) {
			this.saisie.produit.id = null;
			this.saisie.produitCommercial.id = null;
			this.saisie.bonLivraison.id = null;
			this.saisie.client.id = null;
		}

		this.saisie.refreshDate = this.saisie.refreshDate + 1;

		const copySaisie = angular.copy(this.saisie);
		if (this.saisie.id && copySaisie) {
			copySaisie.outsourceModel(this.moment, this.dateFormat);
			const tmpPlv = await this.PrelevementsService.getPrelevementById(this.saisie.id, copySaisie);
			this.saisie.updateHeader(tmpPlv, this.moment, this.dateFormat);
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}

	startPointMesureLoading() {
		this.loadingPointsMesure = true;
	}

	stopPointMesureLoading() {
		this.loadingPointsMesure = false;
	}

	startPersonnesLoading() {
		this.loadingPersonnes = true;
	}

	stopPersonnesLoading() {
		this.loadingPersonnes = false;
	}

	startTypesLoading() {
		this.loadingTypes = true;
	}

	stopTypesLoading() {
		this.loadingTypes = false;
	}

	async selectProduitCom(output, data, theme) {
		if (data != null) {
			if (data.idProduit == this.saisie.produitCommercial.id) {
				this.saisie.produitCommercial.id = undefined;
			} else {
				this.saisie.produitCommercial.id = data.idProduit;
			}
		}
		if (__configuration.licenceLaboratoire === '2' && this.saisie.famille.code === 'BPE') {
			await this.getSaisieValuesPrelevement();
		}
	}

	// isteeven
	async selectItem(output, data, theme) {
		this.saisie = this.createDeepProp(output, this.saisie, theme);
		if (theme === 'auditeur.id') {
			this.purgeFormulaire(theme);
			await this.changeProducteur();
			await this.changePersonnes();
			//si l'utilisateur a accès aux certifications
			//modification laboratoire → requête pour vérifier si les essais du prélèvement sont certifiés pour ce laboratoire
			if (this.certificationAccess) {
				await this.reloadEssaiCertifications();
			}
		} else if (theme === 'producteur.id') {
			this.purgeFormulaire(theme);
			// pour avoir un producteur complet dans this.saisie
			this.saisie.producteur = this.siteProductions.find((x) => x.id === this.saisie.producteur.id);
			await this.changeProduit();
			await this.loadAllBLByDateAndProducteur();
		} else if (theme === 'produit.id') {
			// pour avoir un produit complet dans this.saisie
			this.saisie.produit = this.produits.find((x) => x.id === this.saisie.produit.id);
			//si l'utilisateur a accès aux certifications
			//modification produit → requête pour vérifier si le produit est certifié pour la norme de la ref
			if (this.certificationAccess) {
				await this.checkProductCertificat(data.id, this.saisie.producteur.id);
			}
			if (this.canAccessSaisieProd) {
				await this.manageSaisieProduction();
			}
			await this.loadProduitsCommerciaux();
			if (__configuration.licenceLaboratoire === '2' && this.saisie.famille.code === 'BPE') {
				await this.getSaisieValuesFormule();
			}
		} else if (theme === 'bonLivraison.id') {
			if (__configuration.licenceLaboratoire === '2' && this.saisie.famille.code === 'BPE' && !this.saisie.bonLivraison.id) {
				this.saisie.produit.id = null;
				this.saisie.produitCommercial.id = null;
				this.saisie.client.id = null;
			}

			//todo reload data !!
			await this.getEnteteFromBL(this.saisie.bonLivraison.id);
			if (__configuration.licenceLaboratoire === '2' && this.saisie.famille.code === 'BPE') {
				await this.getSaisieValuesFormule();
				await this.getSaisieValuesFromBL();
				await this.getSaisieValuesPrelevement();
				this.blLoaded();
			}
		} else if (theme === 'client.id') {
			if (this.saisie && this.saisie.client && this.saisie.client.id) {
				await this.loadSiteChantiers();
			} else {
				this.siteChantiers = [];
				if (this.saisie && this.saisie.chantier && this.saisie.chantier.id) {
					this.saisie.chantier = null;
				}
			}
		}

		const copySaisie = angular.copy(this.saisie);
		if (copySaisie && this.moment) {
			copySaisie.outsourceModel(this.moment, this.dateFormat);
		}

		await this.filterBL();
	}

	createDeepProp(out, obj, path, r = 0) {
		let val = null;
		if (path) {
			const arrPart = path.split('.');
			arrPart.splice(0, r);

			if (arrPart.length === 1 && out && out.length > 0) {
				val = out[0]?.idSalarie || out[0]?.id;
			}

			if (typeof path.split('.')[r] === 'undefined') {
				return;
			}
			obj[path.split('.')[r]] = val || this.createDeepProp(out, {}, arrPart.join('.'), ++r);
		}

		return obj;
	}

	async modalBonLivraison(betCharge) {
		// à vérifier si modifiaction l'ouverture de la modale depuis la liste des BL
		const modalInstance = this.ModalService.open({
			component: 'prelevementBonLivraison',
			animation: true,
			size: 'lg',
			resolve: {
				saisie: () => this.saisie,
				bonLivraison: () => betCharge
			}
		});

		try {
			await modalInstance.result;
		} catch (ex) {}
	}

	goToProduit(id) {
		this.saisie.outsourceModel(this.moment, this.dateFormat);
		this.RouterHistoryService.data = this.saisie;
		if (id) {
			return this.$state.go('produits.edit', { id: id });
		}
		return this.$state.go('produits.new');
	}

	async checkProductCertificat(idProduit = null, idProducteur = null) {
		try {
			if (this.saisie.id) {
				this.saisie.certifProduit = await this.PrelevementsService.getProductCertifs(this.saisie.id, idProduit, idProducteur);
			}
		} catch (error) {
			console.error(error);
		}
	}

	async loadAllBLByDateAndProducteur() {
		if (this.saisie.date && this.saisie.producteur && this.saisie.producteur.id) {
			try {
				const filters = [{ criterion: 'site.id', value: this.saisie.producteur.id, type: 'Eq' }];
				if (this.saisie.date) {
					filters.push({ criterion: 'date', value: this.saisie.date, type: 'Eq' });
				}
				const res = await this.BonsService.getAll(filters, [], { skip: 0, take: 99999 });
				this.bonsLivraisonOrigine = res.items;
				await this.filterBL();
			} catch (err) {
				this.notification.error(err.data);
			}
		} else {
			this.bonsLivraisonOrigine = [];
		}
	}

	async filterBL() {
		const bonsLivraisonCopy = angular.copy(this.bonsLivraisonOrigine);

		// 'ticket.numero' flated for the multi select useability
		bonsLivraisonCopy.forEach((element) => {
			element.ticketNumero = element.ticket.numero.toString();
			element.ticketId = element.ticket.id;
		});
		this.bonsLivraison = bonsLivraisonCopy;
	}

	async getEnteteFromBL(id = null) {
		let bonLivSelected;
		if (id !== null) {
			bonLivSelected = angular.copy(this.bonsLivraison).find((x) => x.id == id);
		} else {
			bonLivSelected = angular.copy(this.bonsLivraison).find((x) => x.selected);
		}
		if (!bonLivSelected && this.saisie?.bonLivraison?.id) {
			bonLivSelected = this.bonsLivraison.find((x) => x.ticket.id === this.saisie.bonLivraison.id);
		}
		if (bonLivSelected) {
			const bonLivraison = new BonLivraison(bonLivSelected);
			this.saisie.produit = bonLivraison.produit;

			if (bonLivraison.client?.id) {
				this.saisie.client = bonLivraison.client;
			}

			if (bonLivraison.chantier?.id) {
				await this.loadSiteChantiers();
				this.saisie.chantier = bonLivraison.chantier;
			}

			await this.loadProduitsCommerciaux();
			this.saisie.produitCommercial = this.produitsCommerciaux.find((x) => x.code === bonLivraison.ticketRMS.cpid) || {};
			this.saisie.produitCommercial.id = this.saisie.produitCommercial.idProduit;
		}
	}

	purgeFormulaire(theme) {
		if (this.saisie) {
			this.produits = [];
			this.produitsCommerciaux = [];
			if (this.saisie.produit) {
				this.saisie.produit.id = 0;
			}
			if (this.saisie.bonLivraison) {
				this.saisie.bonLivraison.id = 0;
			}
			if (theme === 'auditeur.id') {
				this.bonsLivraison = [];
				this.bonsLivraisonOrigine = [];
				if (this.saisie.producteur) {
					this.saisie.producteur.id = 0;
				}
			}
		}
	}

	async getSaisieValuesFromBL() {
		if (this.saisie.bonLivraison && this.saisie.bonLivraison.id > 0) {
			const chargeId = this.bonsLivraison.find((x) => x.ticket.id === this.saisie.bonLivraison.id)?.id;
			if (chargeId) {
				const data = await this.TicketsService.getBetChargeById(chargeId);
				const bl = new BonLivraison(data);
				await this.PrelevementImportDataService.setBL(bl);
				this.saisie = await this.PrelevementImportDataService.getDataFromBl(angular.copy(this.saisie));
			}
		}
	}

	//Grace au producteur et au produit, on a la formule
	async getSaisieValuesFormule() {
		this.saisie = await this.PrelevementImportDataService.getDataFromFormule(angular.copy(this.saisie));
	}

	//Grace au produit commercial
	async getSaisieValuesPrelevement() {
		this.saisie = await this.PrelevementImportDataService.getDataFromPrelevement(angular.copy(this.saisie));
	}

	/**
	 * Vérifie que tous les essais mentionnés dans le fichier de config sont pésents dans la liste des prélèvements
	 * si non les ajoute
	 */
	async checkEssais() {
		//* vérifie que tous les essais à remplir soient présents sur le prélèvement
		const missings = await this.PrelevementImportDataService.checkEssais(this.saisie);
		//* sinon on les ajoute
		if (missings.length > 0) {
			const mesures = await this.PrelevementsService.getMesuresComplet(0, null, null, missings);
			let tmp = angular.copy(this.saisie);
			tmp.mesures = [];
			if (mesures && mesures.length > 0) {
				// if(mesures.length !== missings.length){
				//     //? notification → essais non trouvés (vérifier le paramétrage)
				// }
				for (let i = 0; i < mesures.length; i++) {
					//ajoute la propr value aux sous-Essais
					mesures[i].sousEssais = this._.map(mesures[i].sousEssais, (item) => {
						return this._.extend({}, item, { value: null });
					});
				}
				tmp.mesures = mesures;
				tmp = new Prelevement(tmp, this.moment, this.dateFormat);
				this.saisie.mesures = [...this.saisie.mesures, ...tmp.mesures];
			}
			// else{
			//    //? notification → essais non trouvés (vérifier le paramétrage)
			// }
		}
	}
}
