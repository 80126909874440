EncoursController.$inject = ['$scope', '$state', '$stateParams', '$translate', 'ModalService', 'PaginationService', 'EncoursService', 'notification'];

export default function EncoursController($scope, $state, $stateParams, $translate, ModalService, PaginationService, EncoursService, notification) {
	const vm = this;

	let previousTableState;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.encours = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [50, 200, 1000];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.loadEncours = loadEncours;
	vm.getClients = getClients;
	vm.reset = reset;
	vm.exporter = exporter;
	vm.calculer = calculer;

	async function init() {
		await getSocietesCom();
		await getLastDateCalculAuto();
		vm.isAlerte = '0';
		vm.isDepasse = '0';
	}

	init();

	async function getSocietesCom() {
		vm.societes = await EncoursService.getSocietesCom();
		if (vm.societes.length == 1) {
			vm.societeId = vm.societes[0].id;
		}
	}

	async function getLastDateCalculAuto() {
		const lastDate = await EncoursService.getLastDateCalculAuto();
		vm.lastDateAuto = $translate.instant('ENCOURS.MSG_LAST_DATE_AUTO', { date: lastDate.dateAuto });
		vm.lastDateManuelle = lastDate.dateManuelle;
	}

	async function getClients(valeur) {
		const clients = await EncoursService.getClients(valeur);
		return clients;
	}

	async function loadEncours(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'encours.list') {
			$state.go('encours.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.encours = [];

		try {
			const isEncoursAlerte = !vm.isAlerte || vm.isAlerte == '0' ? null : vm.isAlerte == '1' ? true : false;
			const isEncoursDepasse = !vm.isDepasse || vm.isDepasse == '0' ? null : vm.isDepasse == '1' ? true : false;
			const data = await EncoursService.getEncours(
				filters,
				sorts,
				pagination,
				vm.societeId,
				vm.client ? vm.client.id : null,
				isEncoursAlerte,
				isEncoursDepasse
			);

			vm.encours = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	function reset() {
		vm.isAlerte = '0';
		vm.isDepasse = '0';
		vm.societeId = null;
		vm.client = null;
		vm.encours = [];
		getSocietesCom();
	}

	async function exporter(modeImpression) {
		try {
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const fileName = 'Liste_encours';
			let resultat = undefined;
			try {
				const isEncoursAlerte = vm.isAlerte == '0' ? null : vm.isAlerte == '1' ? true : false;
				const isEncoursDepasse = vm.isDepasse == '0' ? null : vm.isDepasse == '1' ? true : false;
				resultat = await EncoursService.export(
					modeImpression,
					filters,
					sorts,
					vm.societeId,
					vm.client ? vm.client.id : null,
					isEncoursAlerte,
					isEncoursDepasse
				);
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('ENCOURS.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				const status = resultat.status;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
				} finally {
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		}
	}

	async function calculer() {
		if (vm.lastDateManuelle) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('ENCOURS.CALCUL_POPUP.TITLE'),
				modalMsg: $translate.instant('ENCOURS.CALCUL_POPUP.MESSAGE', { date: vm.lastDateManuelle }),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(calculEncours);
		} else {
			calculEncours();
		}
	}

	async function calculEncours() {
		startLoading();
		try {
			const data = await EncoursService.calculEncours();
			notification.success($translate.instant('ENCOURS.CALCUL_POPUP.SUCCESS'));
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			loadEncours();
			stopLoading();
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
