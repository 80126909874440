import Motif from './motif.model';

export default class MotifFormController {
    static $inject = ['$scope', '$state', '$stateParams', 'MotifsCommunicationService', 'MotifsService', 'notification', '$location'];

    constructor($scope, $state, $stateParams, MotifsCommunicationService, MotifsService, notification, $location) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.MotifsCommunicationService = MotifsCommunicationService;
        this.MotifsService = MotifsService;
        this.notification = notification;
        this.$location = $location;
    }

    async $onInit() {
        this.isEditMode = false;
        this.motif = {};
        this.loading = false;
        this.setMotif(this.$stateParams.id);
        this.unregisterReinit = this.MotifsCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    initForm() {
        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
            const thatMotif = {
                id: this.motif.id,
                libelle: this.motif.libelle
            };
        } else {
            this.isEditMode = false;
        }
    }

    async setMotif(motifId) {
        if (motifId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                const stillThatMotif = await this.MotifsService.getMotifById(motifId);
                this.motif = new Motif(stillThatMotif);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('motifs.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.motif = new Motif();
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.motif.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.motif.codeExists = await this.MotifsService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    // boutons de validation
    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                if (this.motif.id) {
                    const tmp = await this.updateMotif();
                    this.notification.success('MOTIFS.UPDATED');
                    return this.motif.id;
                }
                const tmp = await this.createMotif();
                this.notification.success('MOTIFS.CREATED');
                return tmp;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        } else {
            return false;
        }
    }

    async valider() {
        const returnedId = await this.sauvegarder();
        if (returnedId) {
            this.$state.go('motifs.edit', { id: returnedId });
        }
    }
    async validerEtFermer() {
        const returnedId = this.sauvegarder();
        this.backToPreviousState();
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('motifs.list');
    }

    async createMotif() {
        this.startLoading();
        try {
            const returnedId = await this.MotifsService.createMotif(this.motif);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateMotif() {
        this.startLoading();
        try {
            const returnedId = await this.MotifsService.updateMotif(this.motif);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    reinit() {
        this.setMotif(this.$stateParams.id);
        this.MotifsCommunicationService.raiseReinit();
    }

    checkValidity() {
        this.$scope.$broadcast('motifValidations');
        return this.motif.isValid();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
