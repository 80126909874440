import { Injectable } from "../decorators";
import { BaseHttpService } from "../interface/MassiaHttpService.interface";

@Injectable("TypeService")
export class TypeService extends BaseHttpService<any, any, any, any, any> {
    /* @ngInject */
    constructor(ApiService: ApiService) {
        super(ApiService);
        this.url = 'v2/massia/types';
    }
}