export default function Configure($stateProvider) {
    $stateProvider.state('tgap-exclure', {
        parent: 'gestion',
        url: '/tgap-exclure',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    // $stateProvider.state('suivi-droit-bitume.list', {
    //     url: '/list',
    //     template: '<suivi-droit-bitume></suivi-droit-bitume>',
    //     rights: {domain: 'droitbitume', right: 'read'},
    //     ncyBreadcrumb: {
    //         label: '{{ "SUIVI_DROIT_BITUME.BREADCRUMBS.SUIVI_DROIT_BITUME_LIST" | translate}}'
    //     },
    //      navigation: {
    //         menu: 'stocks_no_category',
    //         translate: 'SUIVI_DROIT_BITUME.BREADCRUMBS.SUIVI_DROIT_BITUME_LIST',
    //         order: 4,
    //     },
    //     search: 'SUIVI_DROIT_BITUME.BREADCRUMBS.SUIVI_DROIT_BITUME_LIST'
    // });
}

Configure.$inject = ['$stateProvider'];
