export default function Configure($stateProvider) {
    $stateProvider.state('dispatching', {
        parent: 'gestion',
        url: '/dispatching',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.TRANSPORT" | translate }}'
        }
    });

    $stateProvider.state('dispatching.list', {
        url: '/list',
        template: '<dispatching></dispatching>',
        rights: { domain: 'dmdlivrs', right: 'read' },
        ncyBreadcrumb: {
            parent: 'dispatching',
            label: '{{ "DISPATCHING.BREADCRUMBS.DISPATCHING_LIST" | translate}}'
        },
        navigation: {
            menu: 'transport_plannings',
            translate: 'DISPATCHING.BREADCRUMBS.DISPATCHING_LIST',
            order: 12
        },
        search: 'DISPATCHING.BREADCRUMBS.DISPATCHING_LIST'
    });
}

Configure.$inject = ['$stateProvider'];
