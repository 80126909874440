import { IScope } from 'angular';
import { Component, OnInit } from '../../../core/decorators';

interface CustomScope extends IScope {
    match: any;
}

interface MultiSelectModalResolve {
    mode: string;
    onClick: (item: any, tick: string) => void;
    numberPerPage: Number;
    tickProperty: string;
    onSelectAll: (selected?: boolean, item?: any, search?: string) => void;
    disableProperty: string;
    placeholder: string;
    searchProperty: string;
    inputModel: Array<any>;
    getModel: (item: any) => any;
    getSearch: (scope: ng.IScope) => void;
    getNoSearch: (scope: ng.IScope) => void;
}

@Component({
    selector: 'massiaMultiSelectModal',
    bindings: {
        resolve: '<',
        modalInstance: '<'
    },
    controller: MassiaMultiSelectModalController,
    template: require('./massia.multi.select.modal.html')
})
export class MassiaMultiSelectModalController implements OnInit {
    resolve: MultiSelectModalResolve;
    modalInstance: ng.ui.bootstrap.IModalInstanceService;
    scope: ng.IScope;
    search: string = '';
    currentPage: number = 1;
    mode: string;
    onClick: (item: any, tick: string) => void;
    numberPerPage: Number;
    tickProperty: string;
    onSelectAll: (selected?: boolean, item?: any, search?: string) => void;
    disableProperty: string;
    placeholder: string;
    searchProperty: string;
    inputModel: any[];
    getModel: (item: any) => any;
    getSearch: (scope: IScope) => void;
    getNoSearch: (scope: IScope) => void;

    /* @ngInject */
    constructor($scope: ng.IScope) {
        this.scope = $scope;
    }

    ngOnInit(): void {
        this.mode = this.resolve.mode;
        this.onClick = this.resolve.onClick;
        this.numberPerPage = this.resolve.numberPerPage;
        this.tickProperty = this.resolve.tickProperty;
        this.onSelectAll = this.resolve.onSelectAll;
        this.disableProperty = this.resolve.disableProperty;
        this.placeholder = this.resolve.placeholder;
        this.searchProperty = this.resolve.searchProperty;
        this.inputModel = this.resolve.inputModel;
        this.getModel = this.resolve.getModel;
        this.getSearch = this.resolve.getSearch;
        this.getNoSearch = this.resolve.getNoSearch;
        this.currentPage = 1;
    }

    log(data: any) {
        console.log(data);
    }

    ok() {
        this.modalInstance.close();
    }

    cancel() {
        this.modalInstance.dismiss('cancel');
    }

    simulateScope(scope: CustomScope, item: any) {
        scope.match = {
            label: item
        };
        return scope;
    }

    checkSingle(item: any) {
        if (!item[this.resolve.disableProperty]) {
            item[this.tickProperty] = !item[this.tickProperty];
            if (this.resolve.mode === 'single') {
                this.modalInstance.close({
                    itemClick: true,
                    clicked: item
                });
            } else {
                this.resolve.onClick(item, item[this.resolve.tickProperty]);
            }
        }
    }

    selectAll(selected: any) {
        if (selected) {
            this.modalInstance.close({
                selectAll: true,
                search: this.search
            });
        } else if (!selected) {
            this.modalInstance.close({
                selectNone: true
            });
        }
    }
}
