import SocieteFormController from './societe.form.controller';

export default {
    bindings: {
        modalInstance: '=',
        genreSociete: '<'
    },
    template: require('./societe.form.html'),
    controller: SocieteFormController
};
