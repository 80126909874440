import validatorBuilder from 'validator-builder';
import CampagneEntete from './entete/campagne.entete.model';
import CampagneCaracteristiques from './caracteristiques/campagne.caracteristiques.model';
import Prelevement from '../prelevement/prelevement.model';

let campagneValidator = null;

export default class Campagne {
    constructor(data = {}) {
        this.id = data.id;
        this.entete = new CampagneEntete(data.entete);
        this.caracteristiques = new CampagneCaracteristiques(data.caracteristiques);
        this.listeSaisies = data.listeSaisies;
        if (!this.listeSaisies) {
            this.listeSaisies = { saisies: [] };
        } else {
            const saisiesFormattees = [];
            for (let i = 0; i < this.listeSaisies.saisies.length; i++) {
                saisiesFormattees.push(new Prelevement(this.listeSaisies.saisies[i]));
            }
            this.listeSaisies.saisies = saisiesFormattees;
        }
        this.typeSaisiesId = data.typeSaisiesId;
        this.trameId = data.trameId;
        this.referenceControleId = data.referenceControleId;
    }

    isValid() {
        campagneValidator = campagneValidator || validatorBuilder.getInstanceFor('Campagne');
        const validationResults = campagneValidator.validate(this);
        return validationResults.isValid;
    }
}
