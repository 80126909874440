import CampagneEntete from './campagne.entete.model';

export default class CampagneFormEnteteController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'CampagnesService',
        'CampagnesTypesService',
        'PrelevementsService',
        'ModalService',
        'notification',
        '$translate',
        '$uibModal'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        CampagnesService,
        CampagnesTypesService,
        PrelevementsService,
        ModalService,
        notification,
        $translate,
        $uibModal
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.CampagnesService = CampagnesService;
        this.CampagnesTypesService = CampagnesTypesService;
        this.PrelevementsService = PrelevementsService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.campagne = this.campagne || {};
        this.entete = new CampagneEntete(this.campagne.entete);
        //if (this.$stateParams && this.$stateParams.id) {//SP 30/10/18 le faire même en création pour récupérer les infos venant de la grille
        this.setCampagneAsTypeAhead();
        //}
        this.getCodeAuto();
        this.modeLocalisation = await this.PrelevementsService.getLocalisationPointsMesure(this.$stateParams.idTheme);
        this.getTypes();
        this.personnes = [];
        await this.loadPersonnes();
        await this.getNiveauVisibilite();
    }

    /*$onChanges(objChanges) {
        if (objChanges.campagne) {
            this.getTypes();
        }
    }*/

    async getCodeAuto() {
        try {
            this.entete.estCodeAuto = await this.CampagnesService.estCodeAuto('campagnes'); //TODO SP chercher le domaine
            if (this.entete.estCodeAuto) {
                this.entete.afficheCode = await this.CampagnesService.afficheCode('campagnes'); //TODO SP chercher le domaine
            } else {
                this.entete.afficheCode = true;
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async getNiveauVisibilite() {
        if (this.entete.typeId) {
            this.entete.niveauVisibilite = await this.CampagnesTypesService.GetNiveauVisibilite(this.entete.typeId);
        }
    }

    async loadPersonnes() {
        if (this.entete.siteAuditeurId) {
            this.personnes = await this.PrelevementsService.getPersonnes(this.entete.siteAuditeurId);
        }
    }

    $doCheck() {
        if (!angular.equals(this.entete, this.campagne.entete)) {
            this.entete.conformite = this.campagne.entete.conformite;
            this.onUpdate({
                entete: angular.copy(this.entete)
            });
        }
    }

    async createType() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'PRELEVEMENTS.CONFIRM_CREATE_TYPE.TITLE',
            modalMsg: 'PRELEVEMENTS.CONFIRM_CREATE_TYPE.MESSAGE',
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(() => this.$state.go('prelevements.new-type-campagne'));
    }

    async getTypes() {
        this.startLoading();

        try {
            this.types = await this.CampagnesTypesService.getAll(this.$stateParams.idTheme);
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getSitesProducteurs(valeur) {
        let sites = [];
        this.startSiteProducteurLoading();
        try {
            sites = await this.PrelevementsService.getSitesProducteurs(valeur);
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopSiteProducteurLoading();
        }
        return sites;
    }

    async getSitesAuditeurs(valeur) {
        let sites = [];
        this.startSiteAuditeurLoading();
        try {
            sites = await this.PrelevementsService.getSitesAuditeurs(valeur);
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopSiteAuditeurLoading();
        }
        return sites;
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.entete.codeExists = await this.CampagnesService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.entete.codeExists = null;
        }
    }

    setTypeAheadAs(val) {
        if (!this.campagne) {
            this.campagne = {};
        }
        switch (val) {
            case 'siteprod':
                this.entete.siteProducteurId = this.siteProducteur.id;
                this.entete.siteProducteur = this.siteProducteur.libelle;
                //this.entete.siteProducteurCode = this.siteProducteur.code;
                break;
            case 'siteaudit':
                this.entete.siteAuditeurId = this.siteAuditeur.id;
                this.entete.siteAuditeur = this.siteAuditeur.libelle;
                //this.entete.siteAuditeurCode = this.siteAuditeur.code;
                this.loadPersonnes();
                break;
        }
    }

    setCampagneAsTypeAhead() {
        if (this.campagne.entete.siteProducteurId) {
            this.siteProducteur = {
                id: this.campagne.entete.siteProducteurId,
                //code: this.campagne.entete.siteProducteurCode,
                libelle: this.campagne.entete.siteProducteur
                //codeEtLib: this.campagne.entete.siteProducteurId ? this.campagne.entete.siteProducteur+' ['+this.campagne.entete.siteProducteurCode+']' : ''
            };
        }
        if (this.campagne.entete.siteAuditeurId) {
            this.siteAuditeur = {
                id: this.campagne.entete.siteAuditeurId,
                //code: this.campagne.entete.siteAuditeurCode,
                libelle: this.campagne.entete.siteAuditeur
                //codeEtLib: this.campagne.entete.siteAuditeurId ? this.campagne.entete.siteAuditeur+' ['+this.campagne.entete.siteAuditeurCode+']' : ''
            };
        }
    }

    videSiteProducteur() {
        this.siteProducteur = {
            id: undefined,
            //code: undefined,
            libelle: undefined
            //codeEtLib: ''
        };
        this.entete.siteProducteurId = undefined;
        this.entete.siteProducteur = undefined;
        //this.entete.siteProducteurCode = undefined;
    }

    videSiteAuditeur() {
        this.siteAuditeur = {
            id: undefined,
            //code: undefined,
            libelle: undefined
            //codeEtLib: ''
        };
        this.entete.siteAuditeurId = undefined;
        this.entete.siteAuditeur = undefined;
        //this.entete.siteAuditeurCode = undefined;
    }

    openModal = function (size, parentSelector) {
        const _this = this;
        this.$uibModal.open({
            template:
                '<campagne-carte entete="$ctrl.entete" mode-localisation="$ctrl.modeLocalisation" liste-saisies="$ctrl.listeSaisies" modal-instance="$ctrl.uibModalInstance"></campagne-carte>',
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    const $ctrl = this;
                    $ctrl.entete = _this.entete;
                    $ctrl.modeLocalisation = _this.modeLocalisation;
                    $ctrl.listeSaisies = _this.campagne.listeSaisies;
                    $ctrl.uibModalInstance = $uibModalInstance;
                }
            ],
            controllerAs: '$ctrl',
            size: 'xxl',
            backdrop: false
        });
    };

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }

    startSiteProducteurLoading() {
        this.loadingSiteProducteurs = true;
    }

    stopSiteProducteurLoading() {
        this.loadingSiteProducteurs = false;
    }

    startSiteAuditeurLoading() {
        this.loadingSiteAuditeurs = true;
    }

    stopSiteAuditeurLoading() {
        this.loadingSiteAuditeurs = false;
    }
}
