CommandeEnteteValidator.$inject = ['validator'];

export default function CommandeEnteteValidator(validator) {
    const instance = new validator();

    instance.ruleFor('code').notEmpty().when(isNotCodeAuto).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('societeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('clientId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('chantierId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('dateCreation').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;

    function isNotCodeAuto(obj, prop) {
        return !obj.estCodeAuto;
    }
}
