export default class PlanningModalController {
    static $inject = ['$stateParams', 'DispatchingCommunicationService', 'DispatchingService', 'notification', 'ModalService', '$uibModal', 'moment'];

    constructor($stateParams, DispatchingCommunicationService, DispatchingService, notification, ModalService, $uibModal, moment) {
        this.$stateParams = $stateParams;
        this.DispatchingCommunicationService = DispatchingCommunicationService;
        this.DispatchingService = DispatchingService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
        this.moment = moment;
    }

    async $onInit() {
        this.planningTour = {};
        this.planningTour.date = this.date;
        this.planningTour.idDemande = this.demande.id;
        this.planningTour.idEquipage = this.equipage.id;
        this.heureDebutLivraison = this.demande.heureDebut;
        this.heureFinLivraison = this.demande.heureFin;
        this.planningTour.dureeTour = this.demande.dureeTour;
        this.planningTour.quantiteLivrerTour = this.equipage.poidsMoyen;

        this.zones = await this.DispatchingService.getZones();
    }

    annuler() {
        this.modalInstance.close(false);
    }

    async confirmer() {
        if (
            this.planningTour.heureDebutLivraison &&
            this.planningTour.heureFinLivraison &&
            this.planningTour.dureeTour &&
            this.planningTour.quantiteLivrerTour
        ) {
            if (this.checkDateValidation()) {
                if (this.demande.quantiteRestantePlanifier > this.planningTour.quantiteTotaleLivree) {
                    await this.createPlanningTour();
                    this.notification.success('DISPATCHING.PLANNING_CREATED');
                    this.modalInstance.close(true);
                } else {
                    this.notification.error('DISPATCHING.QUANTITE_ERROR');
                }
            } else {
                this.notification.error('DISPATCHING.DATE_ERROR');
            }
        } else {
            this.notification.error('DISPATCHING.DATA_EMPTY');
        }
    }

    async createPlanningTour() {
        this.startLoading();
        try {
            return await this.DispatchingService.createPlanningTour(this.planningTour);
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    checkDateValidation() {
        const hd = this.planningTour.heureDebutLivraison;
        const d = hd.split(':');
        const debut = +d[0] * 60 + +d[1];

        const hf = this.planningTour.heureFinLivraison;
        const f = hf.split(':');
        const fin = +f[0] * 60 + +f[1];

        let resultat = true;
        if (debut > fin) {
            resultat = false;
        }
        return resultat;
    }

    getQuantiteTotaleLivree() {
        if (
            this.planningTour.heureDebutLivraison &&
            this.planningTour.heureFinLivraison &&
            this.planningTour.dureeTour &&
            this.planningTour.quantiteLivrerTour
        ) {
            const hd = this.planningTour.heureDebutLivraison;
            const d = hd.split(':');
            const debut = +d[0] * 60 + +d[1];

            const hf = this.planningTour.heureFinLivraison;
            const f = hf.split(':');
            const fin = +f[0] * 60 + +f[1];

            const diff = fin - debut;
            this.planningTour.nombreTour = Math.floor(diff / this.planningTour.dureeTour);

            this.planningTour.quantiteTotaleLivree = this.planningTour.nombreTour * this.planningTour.quantiteLivrerTour;
        }
    }

    initHeureDebutLivraison(time) {
        this.planningTour.heureDebutLivraison = time;
        this.getQuantiteTotaleLivree();
    }

    initHeureFinLivraison(time) {
        this.planningTour.heureFinLivraison = time;
        this.getQuantiteTotaleLivree();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
