import ReglementsController from './reglements.controller';

export default {
    bindings: {
        idFacture: '=',
        dateFacture: '=',
        montant: '=',
        avoir: '='
    },
    template: require('./reglements.html'),
    controller: ReglementsController
};
