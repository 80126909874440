export default class PrelevEssaiMaterielsService {
    constructor($http) {
        this.$http = $http;
        this.url = `${__configuration.apiUrl}/massia/metrologie/PrelevEssaiMateriel`;
    }

    async getPrelevEssai(idSaisie, idMesure) {
        const res = await this.$http.get(`${this.url}/${idSaisie}/${idMesure}`);
        return res.data;
    }

    async getMateriels(idSaisie) {
        const res = await this.$http.get(`${this.url}/materiels/${idSaisie}`);
        return res.data;
    }

    async putMateriel(selectedMateriel) {
        const res = await this.$http.put(`${this.url}/`, selectedMateriel);
    }

    async postMateriel(selectedMateriel) {
        const res = await this.$http.post(`${this.url}/`, selectedMateriel);
    }

    async deleteMateriel(selectedMateriel) {
        const res = await this.$http.delete(`${this.url}/${selectedMateriel.idPrelevement}/${selectedMateriel.idEssai}`);
    }
}
PrelevEssaiMaterielsService.$inject = ['$http'];
