tvaValidator.$inject = ['validator', 'validationHelper'];

export default function tvaValidator(validator, validationHelper) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 7).withMessage('VALIDATION_TOO_LONG_7');
    instance.ruleFor('taux').greaterThan(-1).when(isNotCombine).withMessage('VALIDATION_NOTEMPTY');

    return instance;

    function isNotCombine(obj, prop) {
        return !obj.isTaxeCombine;
    }
}
