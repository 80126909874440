import TgapExclureController from './tgap.exclure.controller';

export default {
    bindings: {
        idClient: '=',
        idChantier: '=',
        isOpen: '='
    },
    template: require('./tgap.exclure.html'),
    controller: TgapExclureController
};
