export default class ReferentielComposantController {
    static $inject = ['$scope', '$stateParams', 'ReferentielsCommunicationService', 'ReferentielsService', 'notification', '$uibModal'];

    constructor($scope, $stateParams, ReferentielsCommunicationService, ReferentielsService, notification, $uibModal) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.ReferentielsCommunicationService = ReferentielsCommunicationService;
        this.ReferentielsService = ReferentielsService;
        this.notification = notification;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.unregister = this.$scope.$watch(
            () => this.isOpen,
            () => {
                if (this.isOpen && !this.referentiels) {
                    this.loadReferentiels();
                }
            },
            true
        );
    }

    $onDestroy() {
        this.unregister();
    }

    async loadReferentiels() {
        if (this.id) {
            this.startLoading();
            try {
                this.referentiels = await this.ReferentielsService.getReferentielComposant(this.id);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            }
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
