const serviceUrl = `${__configuration.apiUrl}/massia/lieux`;

export default class LieuxService {
    static $inject = ['$http', 'TypesNiveauxHierarchique'];

    constructor($http, TypesNiveauxHierarchique) {
        this.$http = $http;
        this.TypesNiveauxHierarchique = TypesNiveauxHierarchique;
    }

    async getLieux(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    // SL get permettant de remplir les combobox
    async getLieuxHeaders() {
        const url = `${serviceUrl}/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async deleteLieuById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getLieuEnteteById(id) {
        const url = `${serviceUrl}/${id}/entete`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getLieuValeursCaracteristiquesAssociationsById(id) {
        const data = await this.getLieuValeursCaracteristiquesById(id);
        const result = {};

        for (const element in data) {
            if (data[element] && !Array.isArray(data[element])) {
                if (data[element].value === 'True') {
                    data[element].value = 'true';
                }
                if (data[element].value === 'False') {
                    data[element].value = 'false';
                }

                if (data[element].value && data[element].value.key) {
                    result[data[element].id] = data[element].value.key;
                } else {
                    result[data[element].id] = data[element].value;
                }
            }
        }
        return result;
    }

    async getLieuValeursCaracteristiquesById(id) {
        const url = `${serviceUrl}/${id}/valeurs-caracteristiques`;
        const res = await this.$http.get(url);
        const result = [];

        res.data.forEach(function (element) {
            const caract = {
                id: element.id,
                code: element.code,
                idNature: element.idNature,
                indice: element.indice,
                label: element.label,
                numericAdditionalInformation: element.numericAdditionalInformation,
                value: element.elementsValues || element.elementValue || element.values || element.value
            };
            result.push(caract);
        });

        return result;
    }

    async createLieu(lieu) {
        const data = {
            code: lieu.entete.code,
            libelle: lieu.entete.libelle,
            adresse: lieu.entete.adresse,
            codePostal: lieu.entete.codePostal,
            ville: lieu.entete.ville,
            idType: lieu.entete.typeId,
            caracteristiques: [],
            niveaux: lieu.niveaux
        };

        data.caracteristiques = this.formatCaracteristiques(lieu.caracteristiques);

        const result = await this.$http.post(serviceUrl, data);

        return result.data;
    }

    formatCaracteristiques(caracteristiques) {
        const data = [];
        for (const property in caracteristiques) {
            let keyValueObject;
            //On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variables interne Js.
            if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
                const propertyVal = caracteristiques[property];
                if (Array.isArray(propertyVal)) {
                    if (_.some(propertyVal, 'key')) {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: _.map(propertyVal, 'key')
                        };
                    } else {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: propertyVal
                        };
                    }
                } else {
                    keyValueObject = {
                        IdCaracteristique: parseInt(property),
                        valeur: propertyVal
                    };
                }
                data.push(keyValueObject);
            }
        }
        return data;
    }

    async updateLieu(lieu) {
        const data = {
            libelle: lieu.entete.libelle,
            adresse: lieu.entete.adresse,
            codePostal: lieu.entete.codePostal,
            ville: lieu.entete.ville,
            idType: lieu.entete.typeId,
            caracteristiques: [],
            niveaux: lieu.niveaux
        };

        data.caracteristiques = this.formatCaracteristiques(lieu.caracteristiques);

        const url = `${serviceUrl}/${lieu.id}`;
        return await this.$http.put(url, data);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    //le retour de cette methode doit correspond au format predefini dans mock/lieux/lieux.niveaux.json
    async getLieuxNiveaux() {
        const url = `${__configuration.apiUrl}/massia/domaines/lieu/niveaux-hierarchiques`;
        const res = await this.$http.get(url);
        return this.buildLieuxNiveaux(res.data);
    }

    //methode de conversion du format retourne par le serveur vers le format attendu par le front
    buildLieuxNiveaux(niveaux) {
        let newNiveaux = [];

        if (niveaux && niveaux.length > 0) {
            const self = this;

            newNiveaux = _.map(niveaux, function (niveau) {
                return {
                    //le resultat retourne par le serveur ait le type int pour id du niveau,
                    //cependant, selon json attendu par le front, il est necessire que cet id soit en format string (pour que le reste puisse fonctionner comme prevu)
                    //on convertit donc l'id de int a string ici
                    idNiveau: niveau.id.toString(),

                    label: niveau.label,
                    saisi: niveau.type === self.TypesNiveauxHierarchique.Libre.value,
                    sousNiveaux: self.buildLieuxNiveaux(niveau.items)
                };
            });
        }

        return newNiveaux;
    }

    //le retour de cette methode doit correspond au format predefini dans mock/lieux/lieux.elements.json
    async getLieuxElements() {
        const url = `${__configuration.apiUrl}/massia/domaines/lieu/elements-hierarchiques-by-niveau`;
        const res = await this.$http.get(url);
        return res.data;
    }

    //le retour de cette methode doit correspond au format predefini dans mock/lieux/lieux.valeurs.json
    async getLieuValeursNiveauxById(id) {
        const url = `${serviceUrl}/${id}/elements-hierarchiques`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async estCodeAuto() {
        const url = `${serviceUrl}/est-code-auto`;
        try {
            const res = await this.$http.get(url);
            return res.data;
        } catch (err) {
            if (err.status === 404) {
                return false;
            }
            throw err;
        }
    }

    async exportListeLieux(modeImpr, filters, sorts) {
        const url = `${serviceUrl}/print`;
        const res = await this.$http.get(url, {
            params: {
                modeImpr: JSON.stringify(modeImpr),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || [])
            },
            responseType: 'arraybuffer'
        });
        return res;
    }
}
