MonitoringSynchroServeurClientController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'PaginationService',
    'MonitoringSynchroService',
    'notification',
    'moment'
];

export default function MonitoringSynchroServeurClientController(
    $scope,
    $state,
    $stateParams,
    PaginationService,
    MonitoringSynchroService,
    notification,
    moment
) {
    const vm = this;

    let customFilters = null;
    let isFirstLaunch = true;
    let previousTableState;

    vm.domTab = ['Chantiers', 'Divers', 'Factures', 'Tickets', 'Vehicule', 'SiteTransp', 'Lieu', 'Dap'];
    vm.moment = moment;
    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.monitoring = [];
    vm.slideWidth = '1200px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right'
    };
    vm.itemsByPageOptions = [100, 500, 1000];
    vm.itemsByPage = vm.itemsByPageOptions[0];
    vm.loadMonitoring = loadMonitoring;
    vm.errorList = [];
    vm.popover = 'errorList.html';
    vm.sitesList = [];
    vm.domaineList = [];
    vm.searchingForm = {};
    vm.searchingForm.siteSelected = null;
    vm.searchingForm.domaineSelected = null;
    vm.searchingForm.stateSelected = null;
    vm.searchingForm.startDate = null;
    vm.searchingForm.endDate = null;
    vm.isSearchOngletOpen = true;

    async function init() {
        localStorage.removeItem(vm.state.current.name);
    }

    init();

    async function loadMonitoring(tableState) {
        startLoading();
        if (tableState) {
            previousTableState = tableState;
        }

        let filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        if (isFirstLaunch) {
            customFilters = setFirstLaunchDate();
        }

        if (customFilters) {
            filters = [...filters, ...customFilters];
        } else {
            initFilters();
            filters = [...filters, ...customFilters];
        }

        vm.monitoring = [];
        try {
            const data = await MonitoringSynchroService.getMonitoringServeurClient(filters, sorts, pagination);

            vm.monitoring = data.items;
            customFilters = null;
            if (isFirstLaunch) {
                if (vm.sites) {
                    vm.sitesList = vm.sites;
                }

                if (vm.domaines && vm.domTab) {
                    vm.domaineList = vm.domaines.filter((d) => vm.domTab.includes(d.code));
                }
                isFirstLaunch = false;
            }

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    $scope.getErrorList = function (errorList) {
        vm.errorList = errorList;
    };

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    $scope.search = () => {
        initFilters();
        loadMonitoring();
    };

    $scope.reset = () => {
        customFilters = [];
        loadMonitoring();
        vm.searchingForm.siteSelected = null;
        vm.searchingForm.domaineSelected = null;
        vm.searchingForm.stateSelected = null;
        vm.searchingForm.startDate = null;
        vm.searchingForm.endDate = null;
    };

    function getFormatedDate(date) {
        const dateArray = date.split('/');
        const formatedDate = new Date(Date.parse(dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0]));
        formatedDate.setMonth(formatedDate.getMonth());
        formatedDate.setHours(formatedDate.getHours() + 1);
        return formatedDate.toISOString();
    }

    $scope.print = async () => {
        try {
            const ajdhui = Date.now();
            const fileName = 'Liste_monitoring_serveur_vers_client' + vm.moment(ajdhui).format('DDMMYY');
            const sorts = PaginationService.getSorts(previousTableState);
            const resultat = await MonitoringSynchroService.printServeurClient(customFilters, sorts);
            const data = resultat.data;
            const status = resultat.status;
            let headers = resultat.headers;

            headers = headers();

            const contentType = headers['content-type'];

            const linkElement = document.createElement('a');
            try {
                const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                const url = window.URL.createObjectURL(blob);
                linkElement.setAttribute('href', url);
                linkElement.setAttribute('download', fileName + '.xlsx');

                const clickEvent = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: false
                });
                linkElement.dispatchEvent(clickEvent);
            } catch (ex) {
                notification.error(ex.data);
            }
            return true;
        } catch (ex) {
            notification.error(ex.data);

            return false;
        }
    };

    function setFirstLaunchDate() {
        // pour le premier lancement
        customFilters = [];
        // recuperation de la date du jour
        const date = new Date();
        const d = vm.moment(date).format('DD/MM/YYYY');
        vm.searchingForm.startDate = d;
        vm.searchingForm.endDate = d;

        // ajout au custom filter date debut et date de fin
        let filter = {};
        filter.criterion = 'dateDemandeSynchro';
        filter.type = 'Ge';
        filter.value = d;
        customFilters.push(filter);

        filter = {};
        filter.criterion = 'dateDemandeSynchro';
        filter.type = 'Lt';
        filter.value = d;
        customFilters.push(filter);

        return customFilters;
    }

    function initFilters() {
        customFilters = [];
        const options = vm.searchingForm;
        if (options.siteSelected) {
            const filter = {};
            filter.criterion = 'siteDemande.id';
            filter.type = 'Eq';
            filter.value = options.siteSelected;
            customFilters.push(filter);
        }
        if (options.startDate) {
            const filter = {};
            filter.criterion = 'dateDemandeSynchro';
            filter.type = 'Ge';
            filter.value = getFormatedDate(options.startDate);
            customFilters.push(filter);
        }
        if (options.endDate) {
            const filter = {};
            filter.criterion = 'dateDemandeSynchro';
            filter.type = 'Lt';
            const d = new Date(getFormatedDate(options.endDate));
            d.setDate(d.getDate() + 1);
            filter.value = d.toISOString();
            customFilters.push(filter);
        }
        if (options.domaineSelected) {
            const filter = {};
            filter.criterion = 'domainFichier.id';
            filter.type = 'Eq';
            filter.value = options.domaineSelected;
            customFilters.push(filter);
        }
        if (typeof options.stateSelected === 'boolean' || options.stateSelected === '###') {
            const filter = {};
            filter.criterion = 'synchroSuccess';
            filter.type = 'Eq';
            filter.value = options.stateSelected;
            customFilters.push(filter);
        }
    }
}
