CaracteristiquesSelectController.$inject = ['_'];

export default function CaracteristiquesSelectController(_) {
    /* jshint validthis: true */
    const vm = this;

    // Propriétés
    vm.caracteristiques = [];

    // Méthodes
    vm.loadCaracteristiques = loadCaracteristiques;
    vm.selectCaracteristique = selectCaracteristique;
    vm.filterCaracteristiques = filterCaracteristiques;

    // Evènements
    vm.$onInit = onInit;

    // Méthodes publiques
    function onInit() {
        vm.loadCaracteristiques();
        vm.clearOnSelect = vm.clearOnSelect || false;
        vm.excludes = vm.excludes || [];
    }

    function selectCaracteristique(item, model) {
        vm.onSelect({ item: item, model: model });

        if (vm.clearOnSelect) {
            vm.selected = undefined;
        }
    }

    async function loadCaracteristiques() {
        let data = [];

        try {
            data = await this.onLoad();
        } catch (e) {
            throw e;
        }

        vm.caracteristiques = data;
    }

    function filterCaracteristiques(item) {
        return !_.find(vm.excluded, { id: item.id });
    }
}
