export default class TemplateDetailController {
    constructor(TemplateImpressionService, $stateParams, notification) {
        this.templateImpressionService = TemplateImpressionService;
        this.params = $stateParams;
        this.notification = notification;
    }

    $onInit() {
        this.startLoading();
        this.loadTemplate();
        this.ongletOpen = {
            isEnteteOpen: true
        };
    }

    async loadTemplate() {
        try {
            const data = await this.templateImpressionService.getTemplateById(this.params.id);
            this.template = data;
        } catch (err) {
            this.notification.error(err.data);
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}

TemplateDetailController.$inject = ['TemplateImpressionService', '$stateParams', 'notification'];
