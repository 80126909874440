import profilsComponent from './components/profils';
import profilDetailComponent from './components/profil-detail';
import profilFormComponent from './components/profil-form';

import ProfilsService from './services/profils.service';
import ProfilValidator from './components/profil-form/profil.validator';

import profilsRoutes from './_config/profils.routes';
import profilsConfig from './_config/profils.config';

const moduleName = 'app.massia.common.profils';

angular
    .module(moduleName, [])
    .config(profilsConfig)
    .config(profilsRoutes)
    .factory('ProfilValidator', ProfilValidator)
    .service('ProfilsService', ProfilsService)
    .component('profils', profilsComponent)
    .component('profilDetail', profilDetailComponent)
    .component('profilForm', profilFormComponent);

export default moduleName;
