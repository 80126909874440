export default function Configure($stateProvider) {
    $stateProvider.state('banques', {
        parent: 'gestion',
        url: '/banques',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('banques.list', {
        url: '/list',
        template: '<banques></banques>',
        rights: { domain: 'banque', right: 'read' },
        ncyBreadcrumb: {
            parent: 'banques',
            label: '{{ "BANQUES.BREADCRUMBS.BANQUES_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'BANQUES.BREADCRUMBS.BANQUES_LIST',
            order: 30
        },
        search: 'BANQUES.BREADCRUMBS.BANQUES_LIST'
    });

    $stateProvider.state('banques.list.detail', {
        url: '/{id}',
        template: '<banque-detail></banque-detail>',
        rights: { domain: 'banque', right: 'read' },
        ncyBreadcrumb: {
            parent: 'banques.list',
            label: '{{ "BANQUES.BREADCRUMBS.BANQUE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('banques.new', {
        url: '/new',
        template: '<banque-form></banque-form>',
        rights: { domain: 'banque', right: 'create' },
        ncyBreadcrumb: {
            parent: 'banques.list',
            label: '{{ "BANQUES.BREADCRUMBS.BANQUE_NEW" | translate}}'
        },
        search: 'BANQUES.BREADCRUMBS.BANQUE_NEW'
    });

    $stateProvider.state('banques.edit', {
        url: '/{id}/edit',
        template: '<banque-form></banque-form>',
        rights: { domain: 'banque', right: 'update' },
        ncyBreadcrumb: {
            parent: 'banques.list',
            label: '{{ "BANQUES.BREADCRUMBS.BANQUE_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
