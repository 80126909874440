import Niveau from './niveau.model';

export default class NiveauxHierarchiquesFormController {
    static $inject = ['_'];

    // Contructeur
    constructor(_) {
        // Services
        this._ = _;

        // Propriétés
        this.loading = true;
        this.niveaux = [];
    }

    // Evénements Controller
    $onInit() {
        // Bindings propriétés
        this.trame = this.trame || [];
        this.elements = this.elements || {};
        this.model = this.model || {};
    }

    $onChanges(changesObj) {
        // Bindings changes
        if (changesObj) {
            if (changesObj.trame) {
                this.setNiveaux(changesObj.trame.currentValue);
            }

            if (changesObj.elements) {
                this.setElements(changesObj.elements.currentValue);
            }

            if (changesObj.model) {
                this.setElements(this.elements);
            }
        }
    }

    // Méthodes
    setNiveaux(niveaux = [], depth = 0) {
        if (this._.isArray(niveaux)) {
            // Dans le cas d'1 à N racines, on peut imaginer avoir un niveau parent (phantom)
            // On a donc des sousNiveau de ce niveau phantom
            this._.forEach(niveaux, (sousNiveau) => {
                // Liste les niveaux enfants
                const idSousNiveaux = this._.map(sousNiveau.sousNiveaux, (sousNiveau) => sousNiveau.idNiveau);
                // Initialise le niveau
                const niveau = new Niveau({
                    idNiveau: sousNiveau.idNiveau,
                    label: sousNiveau.label,
                    depth: depth,
                    all: [],
                    select: [],
                    saisi: sousNiveau.saisi,
                    idSousNiveaux: idSousNiveaux
                });
                // Ajoute le niveau à la liste des niveaux
                this.niveaux.push(niveau);
                // On initialise le model du niveau
                this.model[niveau.idNiveau] = this.model[niveau.idNiveau] || [];
                // Pour les enfants de chaque niveau
                this.setNiveaux(sousNiveau.sousNiveaux, depth + 1);
            });
            // On initialise les valeurs des éléments définis
            this.setElements(this.elements);
        }
    }

    setElements(racineElements = {}) {
        // Pour chaque niveaux racines, on initialise ces valeurs
        this._.forEach(racineElements, (elements, idNiveau) => this.updateElements(idNiveau, elements));
    }

    updateElements(idNiveau = 0, elements = []) {
        const niveau = this._.find(this.niveaux, { idNiveau: idNiveau });
        const sousElements = {};

        if (niveau) {
            niveau.elements = elements;
            niveau.select = [];

            // Pour chaque choix possible pour le niveau
            this._.forEach(niveau.elements, (value) => {
                // On ajoute le choix dans le select
                niveau.select.push({
                    id: value.id,
                    label: value.label
                });
            });

            if (!niveau.saisi) {
                // On met à jour le model selon les choix disponibles (néttoie le model des éléments qui ne sont plus sélectionnable)
                this.model[idNiveau] = this._.filter(this.model[idNiveau], (idElement) => {
                    return this._.find(niveau.select, { id: idElement });
                });
            }

            // Pour chaque choix possible pour le niveau
            this._.forEach(niveau.elements, (element) => {
                // Pour chaques choix des niveau enfants enfant
                this._.forEach(element.sousElements, (elementsSousNiveau, idSousNiveau) => {
                    // * Saisi libre : on met à jour tous ces enfants
                    // * Si aucuns choix parents : on prend tous les choix disponible dans le noeud enfant
                    // * Si choix parents : on prend seulement les choix dépendant du choix parent
                    if (niveau.saisi || this.model[idNiveau].length === 0 || this.model[idNiveau].indexOf(element.id) >= 0) {
                        sousElements[idSousNiveau] = (sousElements[idSousNiveau] || []).concat(elementsSousNiveau);
                    }
                });
            });

            // Pour chaques noeud enfant, on met à jour ces valeurs selon les valeurs disponibles
            this._.forEach(niveau.idSousNiveaux, (idSousNiveau) => {
                this.updateElements(idSousNiveau, sousElements[idSousNiveau] || []);
            });
        }
    }

    collapseNiveau(niveau) {
        niveau.isOpen = !niveau.isOpen;
        this.collapseSousNiveau(niveau.idSousNiveaux, !niveau.isOpen);
    }

    collapseSousNiveau(idSousNiveaux, collapsed) {
        if (this._.isArray(idSousNiveaux)) {
            idSousNiveaux.forEach((idSousNiveau) => {
                const sousNiveau = this._.find(this.niveaux, { idNiveau: idSousNiveau });

                if (sousNiveau) {
                    // Déplier les sousNiveau ouvert
                    if (sousNiveau.isOpen) {
                        this.collapseSousNiveau(sousNiveau.idSousNiveaux, collapsed);
                    }

                    sousNiveau.isCollapse = collapsed;
                }
            });
        }
    }

    getLabel(id, idNiveau) {
        const niveau = this._.find(this.niveaux, { idNiveau: idNiveau });
        const element = this._.find(niveau.select, { id: id });
        let label = '';

        if (niveau.saisi) {
            label = this.model[idNiveau];
        } else if (element) {
            label = element.label;
        }

        return label;
    }

    startLoading() {
        this.loading = false;
    }

    stopLoading() {
        this.loading = false;
    }
}
