export default class TransportDetailController {
    static $inject = ['$stateParams', '$state', 'TransportsService', 'notification', 'TransportsCommunicationService'];

    constructor($stateParams, $state, TransportsService, notification, TransportsCommunicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.TransportsService = TransportsService;
        this.notification = notification;
        this.TransportsCommunicationService = TransportsCommunicationService;
    }

    $onInit() {
        this.loading = false;
        this.transport = {};
        this.transportId = undefined;
        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.initTransport();
    }

    async initTransport() {
        this.transportId = this.$stateParams.id;

        this.startLoading();
        try {
            await this.getTransportEntete(this.transportId);
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('transports.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getTransportEntete(transportId) {
        this.transport.entete = await this.TransportsService.getTransportEnteteById(transportId);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
