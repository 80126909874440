BadgesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'BadgesService',
    'notification',
    '_',
    '$uibModal',
    '$transitions',
    'MOPService'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function BadgesController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    BadgesService,
    notification,
    _,
    $uibModal,
    $transitions,
    MOPService
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    let selectedBadgeId = undefined;

    vm.loading = false;

    vm.params = $stateParams;
    vm.state = $state;
    vm._ = _;
    vm.$uibModal = $uibModal;

    vm.badges = [];

    vm.slideWidth = '600px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };
    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.selectBadge = selectBadge;
    vm.isBadgeSelected = isBadgeSelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadBadges = loadBadges;
    vm.setSelected = setSelected;
    vm.licenceGest = __configuration.licenceGest;
    vm.exporter = exporter;
    vm.deleteBadge = deleteBadge;
    vm.MOPService = MOPService;

    async function init() {
        vm.MOPService.setMop([{ title: 'BADGES.BREADCRUMBS.BADGES_LIST', filename: 'Badges.pdf', application: 'gestion' }]);
        //écouter l'event lié au changement de route
        //une fois on change l'url, et on arrive sur la page "badges.list", on désélectionne la ligne sélectionnée (s'il y en a)
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        //quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    init();

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    function stateChangeSuccessFunc(event, toState) {
        if (!$stateParams.id) {
            //vm.setSelected();
            selectedBadgeId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        } /*else if (toState.name === 'badges.list.detail') {
            vm.setSelected({ id: parseInt($state.params.id) });
        }*/
    }

    function setSelected(badge) {
        if (badge && badge.id) {
            selectedBadgeId = $stateParams.id;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            selectedBadgeId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return $state && $state.current && $state.current.name && $state.current.name === 'badges.list.detail' && $stateParams && $stateParams.id;
    }

    function selectBadge(badge) {
        if (badge && badge.id) {
            //si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedBadgeId, et charger la vue détail pour le badge sélectionné
            if (vm.selectedBadgeId !== badge.id) {
                vm.selectedBadgeId = badge.id;
                showDetail(badge.id);
            } else {
                //si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
                vm.selectedBadgeId = undefined;
                closeDetail();
            }
        }
    }

    function isBadgeSelected(badge) {
        return badge && badge.id && vm.selectedBadgeId === badge.id;
    }

    function showDetail(badgeId) {
        vm.slideMargin['margin-right'] = vm.slideWidth;
        selectedBadgeId = badgeId;
        $state.go('badges.list.detail', { id: badgeId });
    }

    function closeDetail() {
        selectedBadgeId = undefined;
        $state.go('badges.list');
    }

    async function deleteBadge(badge) {
        if (badge && badge.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('BADGES.DELETE.TITLE', { code: badge.code }),
                modalMsg: $translate.instant('BADGES.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await BadgesService.deleteBadgeById(badge.id);
                    notification.success($translate.instant('BADGES.DELETE.SUCCESS'));
                    selectedBadgeId = undefined;
                    // si jamais la vue détail est affichée,
                    // on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
                    if (vm.params.id) {
                        $state.go('badges.list');
                    }
                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);
                    loadBadges();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    async function loadBadges(tableState) {
        startLoading();

        //quand on rafraîchit la grille, on revient sur "badges.list", et on ferme la vue détail d'un badge
        //sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
        if ($state && $state.current && $state.current.name !== 'badges.list') {
            $state.go('badges.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.badges = [];

        try {
            const data = await BadgesService.getBadges(filters, sorts, pagination);

            vm.badges = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }

    async function exporter(modeImpression) {
        try {
            const filters = PaginationService.getFilters(previousTableState);
            const sorts = PaginationService.getSorts(previousTableState);
            const fileName = 'Liste_badges';
            let resultat = undefined;
            try {
                resultat = await BadgesService.exportListeBadges(modeImpression, filters, sorts);
            } catch (ex) {
                //si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
                const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
                notification.error('BADGES.' + msgException);
                return false;
            }
            if (resultat) {
                const data = resultat.data;
                const status = resultat.status;
                let headers = resultat.headers;
                headers = headers();

                const contentType = headers['content-type'];

                const linkElement = document.createElement('a');
                try {
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    if (modeImpression == 0) {
                        linkElement.setAttribute('download', fileName + '.pdf');
                    } else {
                        linkElement.setAttribute('download', fileName + '.xlsx');
                    }

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                } catch (ex) {
                } finally {
                }
                return true;
            }
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        }
    }
}
