(function (angular, undefined) {
    'use strict';

    var defaultValidationTemplate =
        '<span class="validation-message padding-0" style="position:absolute; margin-left: -9px;">' +
        '   <i class="glyphicon glyphicon-exclamation-sign validation-sign" ' +
        '      uib-popover="{{validation.error | translate}}" ' +
        '      popover-placement="{{validation.popoverPlacement}}" ' +
        '      popover-append-to-body="{{validation.appendToBody}}" ' +
        '      popover-trigger="\'mouseenter\'"> ' +
        '   </i>' +
        '</span>';

    var smallValidationTemplate = 
        '<small class="small-validation">{{ validation.error | translate }}</small>'

    angular.module('blocks.validation')
        .constant('defaultValidationTemplate', defaultValidationTemplate)
        .constant('smallValidationTemplate', smallValidationTemplate);

})(angular);