export default class ImportNormeModalController {
    static $inject = ['ReferencesControleService', '$uibModal', '_', 'NormesService', '$filter'];

    constructor(ReferencesControleService, $uibModal, _, NormesService, $filter) {
        this.ReferencesControleService = ReferencesControleService;
        this.$uibModal = $uibModal;
        this._ = _;
        this.NormesService = NormesService;
        this.$filter = $filter;
    }

    async $onInit() {
        this.newSpecifications = [];
        this.selNorme = {};
        this.filtreProduit = true;
        this.normesOrig = await this.ReferencesControleService.getNormes();
        if (this.idNorme) {
            const that = this;
            const normeSelect = this._.find(this.normesOrig, function (elt) {
                return elt.id == that.idNorme;
            });
            this.selNorme = { isSelected: true, id: this.idNorme, libelle: normeSelect.libelle, norme: normeSelect.norme, n: normeSelect.n };

            this.setNormeAheadAs(this.selNorme);
        }
        await this.iStevenNorme();
    }

    async iStevenNorme() {
        const res = await this.NormesService.getNormeSpecList('');
        const normeTrie = [];
        for (let i = 0; i < res.length; i++) {
            const element = res[i];
            element.list = this.normesOrig.filter((x) => x.norme === element.id);
            normeTrie.push(element);
        }

        this.normeTrie = this.$filter('istevenFormat')(normeTrie, 'libelle', 'list');
    }

    changeNorme() {
        const that = this;
        this.normes = this.normesOrig.filter(function (e) {
            return e.libelle.toLowerCase().indexOf(that.selNorme.toLowerCase()) >= 0;
        });

        if (this.selNorme.isSelected) {
            this.selNorme = null;
            this.idNormeChx = null;
        }
    }

    async setNormeAheadAs(data) {
        if (data) {
            this.normOrig = this.normesOrig.map((x) => {
                if (x.id !== data.id) {
                    x.selected = false;
                }
                return x;
            });
            this.idNormeChx = data.id;
            this.selNorme.id = data.id;
            this.selNorme.isSelected = true;
            this.nbrResultat = data.n;
        }
        const idProduitChx = this.filtreProduit && this.idProduit ? this.idProduit : 0;
        this.specifications = await this.ReferencesControleService.getSpecificationsNorme(this.idNormeChx, idProduitChx);
        //trier !
        this.specifications.specifications = this._.sortBy(this.specifications.specifications, [
            'libelleCategorie',
            'petitD',
            'grandD',
            'libelleGamme'
        ]);
    }

    async appliquer() {
        if (this.idNormeChx && this.specifications) {
            const resultat = {
                idNorme: this.idNormeChx,
                specChoisies: this._.filter(this.specifications.specifications, { checked: true }),
                n: this.nbrResultat
            };
            this.modalInstance.close(resultat);
        }
    }

    async cancel() {
        this.modalInstance.close();
    }
}
