ClientsDiversController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'ClientsDiversService',
	'notification',
	'$transitions',
	'MOPService'
];

export default function ClientsDiversController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	ClientsDiversService,
	notification,
	$transitions,
	MOPService
) {
	const vm = this;

	let previousTableState;
	let selectedClientDiversId = undefined;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.clientsDivers = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectClientDivers = selectClientDivers;
	vm.isClientDiversSelected = isClientDiversSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadClientsDivers = loadClientsDivers;
	vm.deleteClientDivers = deleteClientDivers;
	vm.setSelected = setSelected;
	vm.exporter = exporter;
	vm.MOPService = MOPService;

	function init() {
		vm.MOPService.setMop([{ title: 'CLIENTSDIVERS.BREADCRUMBS.CLIENTSDIVERS_LIST', filename: 'ClientsDivers.pdf', application: 'gestion' }]);
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		//$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	init();

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(clientDivers) {
		if (clientDivers && clientDivers.id) {
			vm.selectedClientDiversId = $stateParams.id;
			vm.lastSelected = vm.selectedClientDiversId;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			vm.selectedClientDiversId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state.current.name === 'clients-divers.list.detail' && $stateParams.id;
	}

	function selectClientDivers(clientDivers) {
		if (clientDivers && clientDivers.id) {
			if (vm.selectedClientDiversId != clientDivers.id) {
				vm.selectedClientDiversId = clientDivers.id;
				showDetail(clientDivers.id);
			} else {
				vm.selectedClientDiversId = undefined;
				closeDetail();
			}
		}
	}

	function isClientDiversSelected(clientDivers) {
		return clientDivers && clientDivers.id && selectedClientDiversId === clientDivers.id;
	}

	function showDetail(clientDiversId) {
		$state.go('clients-divers.list.detail', { id: clientDiversId });
	}

	function closeDetail() {
		selectedClientDiversId = undefined;
		$state.go('clients-divers.list');
	}

	async function loadClientsDivers(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'clients-divers.list') {
			$state.go('clients-divers.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.clientsDivers = [];

		try {
			const data = await ClientsDiversService.getClientsDivers(filters, sorts, pagination);

			vm.clientsDivers = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteClientDivers(clientDivers) {
		if (clientDivers && clientDivers.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('CLIENTSDIVERS.DELETE.TITLE', { code: clientDivers.libelle }),
				modalMsg: $translate.instant('CLIENTSDIVERS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await ClientsDiversService.deleteClientDiversById(clientDivers.id);
					notification.success($translate.instant('CLIENTSDIVERS.DELETE.SUCCESS'));

					selectedClientDiversId = undefined;

					if (vm.params.id) {
						vm.state.go('clients-divers.list');
					}

					loadClientsDivers();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function exporter(modeImpression) {
		try {
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const fileName = 'Liste_clients';
			let resultat = undefined;
			try {
				resultat = await ClientsDiversService.exportListeClients(modeImpression, filters, sorts);
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('CLIENTS.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				const status = resultat.status;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
				} finally {
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
