import Element from './modale.creation.element.model';

export default class ModaleCreationTrameController {
    static $inject = ['$scope', '$uibModalInstance', '$translate', 'notification', 'data'];

    constructor($scope, $uibModalInstance, $translate, notification, data) {
        this.$scope = $scope;
        this.$uibModalInstance = $uibModalInstance;
        this.$translate = $translate;
        this.notification = notification;
        this.data = data;
        this.element = new Element();
        this.ElementService = data.service;
        this.parentNode = data.parentNode;
        this.trames = [];
    }

    $onInit() {
        this.loading = {};
        this.nom = {};
        this.type = {};
        this.loading = false;
        this.getTrames();
    }

    async getTrames() {
        this.trames = await this.ElementService.getAvailableNiveaux(this.data.parentNode);
    }

    async confirmer() {
        if (this.element.isValid()) {
            let result = false;

            try {
                this.startLoading();
                result = await this.ElementService.createNode(this.parentNode, this.element);
                this.notification.success(this.$translate.instant('AJOUTER_SUCCESS', { entity: this.element.nom }));
                this.$uibModalInstance.close(result);
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.$scope.$broadcast('ElementValidations');
        }
    }

    annuler() {
        this.$uibModalInstance.dismiss();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.element.codeExists = await this.ElementService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.element.codeExists = null;
        }
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
