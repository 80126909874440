import validatorBuilder from 'validator-builder';
import OffreValidationProduits from './offre.validation.produits.model';

let niveauxValidator = null;

export default class OffreValidationNiveaux {
    constructor(data) {
        data = data || {};

        this.id = data.id || null;
        this.niveau = data.niveau || 1;
        this.idProfilDeValidation = data.idProfilDeValidation || null;
        this.isSeuilMontant = data.isSeuilMontant || false;
        this.isSeuilRemiseProduit = data.isSeuilRemiseProduit || false;
        this.isSeuilVolume = data.isSeuilVolume || false;
        this.modeSeuilVolume = data.modeSeuilVolume + '' || null;
        this.modeSeuilMontant = data.modeSeuilMontant + '' || null;
        this.valeurMaxMontant = data.valeurMaxMontant || null;
        this.valeurMaxVolume = data.valeurMaxVolume || null;
        this.valeurMinMontant = typeof data.valeurMinMontant === 'number' ? data.valeurMinMontant : null;
        this.valeurMinVolume = typeof data.valeurMinVolume === 'number' ? data.valeurMinVolume : null;
        this.unite = data.unite || null;
        const devisProduits = data.validationDevisProduits?.map((x) => new OffreValidationProduits(x));
        this.validationDevisProduits = devisProduits || [];
    }
    isValid() {
        niveauxValidator = niveauxValidator || validatorBuilder.getInstanceFor('OffreValidationNiveaux');
        const validationResults = niveauxValidator.validate(this);
        return validationResults.isValid;
    }
}
