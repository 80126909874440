import DemandesLivraisonsConfig from './_config/demandes.livraisons.config';
import DemandesLivraisonsRoutes from './_config/demandes.livraisons.routes';

import DemandesLivraisonsComponent from './components/demandes-livraisons';
import DemandeLivraisonFormComponent from './components/demande-livraison-form';

import DemandesLivraisonsService from './services/demandes.livraisons.service';
import DemandeLivraisonValidator from './components/demande-livraison-form/demande.livraison.validator';

const moduleName = 'app.massia.gestion.plannings.demandes.livraisons';

angular
    .module(moduleName, [])
    .config(DemandesLivraisonsConfig)
    .config(DemandesLivraisonsRoutes)
    .service('DemandesLivraisonsService', DemandesLivraisonsService)
    .component('demandesLivraisons', DemandesLivraisonsComponent)
    .component('demandeLivraisonForm', DemandeLivraisonFormComponent)
    .factory('DemandeLivraisonValidator', DemandeLivraisonValidator);

export default moduleName;
