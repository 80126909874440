ChantierEnteteValidator.$inject = ['validator'];

export default function ChantierEnteteValidator(validator) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().when(isNotCodeAuto).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    //instance.ruleFor('etat').notEmpty().withMessage('VALIDATION_NOTEMPTY'); // TODO SL voir pourquoi en mettant 0, ca ne passe pas

    instance.ruleFor('typeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    // TODO SL a decommenter quand on aura trouve la solution concernant les typeAHead
    //instance.ruleFor('nomSiteClient.id').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    /*instance.ruleFor('telephone').matches(/(0|\+33)\d{9}/).when(isCompleted).withMessage('VALIDATION_PHONE');
    instance.ruleFor('fax').matches(/(0|\+33)\d{9}/).when(isCompleted).withMessage('VALIDATION_FAX');*/
    return instance;

    function isCompleted(obj, prop) {
        return prop != null && typeof prop !== undefined && prop != '';
    }

    function isNotCodeAuto(obj, prop) {
        return !obj.estCodeAuto;
    }
}
