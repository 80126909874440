import SitePontComptableController from './site.pont.comptable.controller';

export default {
    bindings: {
        site: '=',
        onUpdate: '&'
    },
    template: require('./site.pont.comptable.html'),
    controller: SitePontComptableController
};
