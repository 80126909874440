import NatureAdditionnalInfoMesureController from './nature.additionnal.info.mesure.controller';

export default {
    bindings: {
        entete: '=',
        sousMesures: '=',
        popover: '@'
    },
    template: require('./templates/base.tpl.html'),
    controller: NatureAdditionnalInfoMesureController
};
