// on importe le helper qui nous permet de récupérer les validateurs
import validatorBuilder from 'validator-builder';

let siteCaracteristiquesLocataireValidator = null;

export default class SiteCaracteristiquesLocataire {
    // le constructeur reçoit les données que l'on envoie lors d'un new SiteCaracteristiques([données])
    constructor(data) {
        // on va chercher le validateur s'il n'existe pas déjà
        siteCaracteristiquesLocataireValidator =
            siteCaracteristiquesLocataireValidator || validatorBuilder.getInstanceFor('SiteCaracteristiquesLocataire');
        // nettoyage et génération des règles de validation pour le formulaire dynamique
        siteCaracteristiquesLocataireValidator.clearRules();
        siteCaracteristiquesLocataireValidator.generateRules();

        this.data = {};
        for (const element in data) {
            if (element !== 'data') {
                this[element] = data[element];
                this.data[element] = {
                    id: parseInt(element),
                    value: getFormattedValues(data[element]),
                    additionalInformations: { key: 'clé', value: 'valeur' }
                };
            }
        }

        function getFormattedValues(element) {
            if (Array.isArray(element)) {
                const result = _.map(element, function (e) {
                    if (e && e.hasOwnProperty('key')) {
                        return { value: e.key };
                    }
                    return { value: e };
                });
                return result;
            }
            return element;
        }
    }

    // cette methode, en conjonction avec ng-fluent-validation (porté par blocks.validation)
    // permet de passer l'ensemble des règles de validation sur le modèle
    // et retourne l'état de validité de celui-ci
    isValid() {
        const validationResults = siteCaracteristiquesLocataireValidator.validate(this);
        return validationResults.isValid;
    }
}
