export default function Configure($stateProvider) {
    $stateProvider.state('test-api', {
        parent: 'gestion',
        url: '/test-api',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        }
    });

    $stateProvider.state('test-api.run', {
        url: '/run',
        template: '<test-api></test-api>',
        rights: { domain: 'banque', right: 'read' }
    });
}

Configure.$inject = ['$stateProvider'];
