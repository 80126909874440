/**
 * @version 1.4.13
 * @license MIT
 */
(function (ng, undefined) {
	ng.module('blocks.smart.table').run([
		'$templateCache',
		function ($templateCache) {
			$templateCache.put(
				'template/blocks.smart.table/pagination.html',
				'<nav ng-if="pages.length >= 2"><ul class="pagination">' +
					'<li ng-repeat="page in pages" ng-class="{active: page==currentPage}"><a ng-click="selectPage(page)">{{page}}</a></li>' +
					'</ul></nav>'
			);
		}
	]);

	ng.module('blocks.smart.table').constant('stConfig', {
		pagination: {
			template: 'template/blocks.smart.table/pagination.html',
			itemsByPage: 10,
			displayedPages: 5
		},
		search: {
			delay: 400 // ms
		},
		select: {
			mode: 'single',
			selectedClass: 'st-selected'
		},
		sort: {
			sortClass: 'st-sort',
			ascentClass: 'st-sort-ascent',
			descentClass: 'st-sort-descent'
		},
		pipe: {
			delay: 100 //ms
		}
	});
	ng.module('blocks.smart.table')
		.controller('stTableController', [
			'$rootScope',
			'$scope',
			'$parse',
			'$filter',
			'$attrs',
			'$window',
			'$state',
			function StTableController($rootScope, $scope, $parse, $filter, $attrs, $window, $state) {
				const propertyName = $attrs.stTable;
				const displayGetter = $parse(propertyName);
				const displaySetter = displayGetter.assign;
				let safeGetter;
				let orderBy = $filter('orderBy');
				let filter = $filter('filter');
				let safeCopy = copyRefs(displayGetter($scope));
				let canSave = true;
				let tableState = {
					sort: {},
					search: {},
					pagination: {
						start: 0
					}
				};

				/**
				 * return the current state of the table
				 * @returns {{sort: {}, search: {}, pagination: {start: number}}}
				 */
				this.tableState = function getTableState() {
					return tableState;
				};

				this.setTableState = function saveTableState(ts) {
					tableState = ts;
					return;
				};

				this.isSaveDisabled = function getSaveState() {
					return canSave !== true;
				};

				this.setSaveState = function toogleSave(value) {
					canSave = value;
					return;
				};

				/**
				 * return the filter user preference
				 * @returns {}
				 */
				this.getFiltersUserPreferences = function getFiltersUserPreferences() {
					const filters = {};
					const strLocalUser = localStorage.getItem('massia.MassiaPersistsUserData');
					if (strLocalUser) {
						const localUser = JSON.parse(strLocalUser);
						if (localUser && localUser.selectedNavSite && localUser.selectedNavSite.id) {
							const headColumns = $attrs.$$element[0].querySelectorAll('table tr th[st-filter]');
							if (headColumns) {
								for (let i = 0; i < headColumns.length; i++) {
									const columnName = headColumns[i].getAttribute('st-filter');
									switch (columnName) {
										case 'liste':
										case 'site':
										case 'nomSite':
											filters[columnName] = localUser.selectedNavSite.libelle;
											break;
									}
								}
							}
						}
					}

					return filters;
				};

				/**
				 * Apply the filter user preference
				 * @returns void
				 */
				this.applyUserFilterPreferences = function applyUserFilterPreferences(force = false) {
					const tableState = this.tableState();
					const filters = this.getFiltersUserPreferences();

					if (tableState && filters && Object.keys(filters).length > 0) {
						if (!tableState.search) {
							tableState.search = { predicateObject: {} };
						}
						if (!tableState.search.predicateObject) {
							tableState.search.predicateObject = {};
						}

						for (const key in filters) {
							if (!tableState.search.predicateObject.hasOwnProperty(key) || force) {
								tableState.search.predicateObject[key] = filters[key];
							}
						}
					} else {
						if (tableState && tableState.search && tableState.search.predicateObject) {
							delete tableState.search.predicateObject.liste;
							delete tableState.search.predicateObject.site;
							delete tableState.search.predicateObject.nomSite;
						}
					}
				};

				// $rootScope.$on('userContextChange', (e) => {
				//     this.applyUserFilterPreferences(true);
				//     this.setSearchPredicates(tableState.search.predicateObject, false);
				// });

				this.applyUserFilterPreferences();

				let filtered;
				let pipeAfterSafeCopy = true;
				const ctrl = this;
				let lastSelected;

				this.safeCopy = safeCopy;

				function copyRefs(src) {
					return src ? [].concat(src) : [];
				}

				function updateSafeCopy() {
					safeCopy = copyRefs(safeGetter($scope));
					if (pipeAfterSafeCopy === true) {
						ctrl.pipe();
					}
				}

				if ($attrs.stSafeSrc) {
					safeGetter = $parse($attrs.stSafeSrc);
					$scope.$watch(
						function () {
							const safeSrc = safeGetter($scope);
							return safeSrc ? safeSrc.length : 0;
						},
						function (newValue, oldValue) {
							if (newValue !== safeCopy.length) {
								updateSafeCopy();
							}
						}
					);
					$scope.$watch(
						function () {
							return safeGetter($scope);
						},
						function (newValue, oldValue) {
							if (newValue !== oldValue) {
								updateSafeCopy();
							}
						}
					);
				}

				/**
				 * sort the rows
				 * @param {Function | String} predicate - function or string which will be used as predicate for the sorting
				 * @param [reverse] - if you want to reverse the order
				 */
				this.sortBy = function sortBy(predicate, reverse) {
					tableState.sort.predicate = predicate;
					tableState.sort.reverse = reverse === true;

					if (ng.isFunction(predicate)) {
						tableState.sort.functionName = predicate.name;
					} else {
						delete tableState.sort.functionName;
					}

					tableState.pagination.start = 0;
					return this.pipe();
				};

				/**
				 * search matching rows
				 * @param {String} input - the input string
				 * @param {String} [predicate] - the property name against you want to check the match, otherwise it will search on all properties
				 */
				this.search = function search(input, predicate) {
					const predicateObject = tableState.search.predicateObject || {};
					const prop = predicate ? predicate : '$';

					input = ng.isString(input) ? input.trim() : input;
					predicateObject[prop] = input;
					// to avoid to filter out null value
					if (!input) {
						delete predicateObject[prop];
					}
					tableState.search.predicateObject = predicateObject;
					tableState.pagination.start = 0;
					return this.pipe();
				};

				/**
				 * this will chain the operations of sorting and filtering based on the current table state (sort options, filtering, ect)
				 */
				this.pipe = function pipe() {
					const pagination = tableState.pagination;
					let output;
					filtered = tableState.search.predicateObject ? filter(safeCopy, tableState.search.predicateObject) : safeCopy;
					if (tableState.sort.predicate) {
						filtered = orderBy(filtered, tableState.sort.predicate, tableState.sort.reverse);
					}

					pagination.itemsCount = filtered.length;

					if (pagination.number !== undefined) {
						pagination.numberOfPages = filtered.length > 0 ? Math.ceil(filtered.length / pagination.number) : 1;
						pagination.start =
							pagination.start >= filtered.length ? (pagination.numberOfPages - 1) * pagination.number : pagination.start;
						output = filtered.slice(pagination.start, pagination.start + parseInt(pagination.number));
					}
					displaySetter($scope, output || filtered);
				};

				/**
				 * select a dataRow (it will add the attribute isSelected to the row object)
				 * @param {Object} row - the row to select
				 * @param {String} [mode] - "single" or "multiple" (multiple by default)
				 */
				this.select = function select(row, mode) {
					const rows = safeCopy;
					const index = rows.indexOf(row);
					if (index !== -1) {
						if (mode === 'single') {
							row.isSelected = row.isSelected !== true;
							if (lastSelected) {
								lastSelected.isSelected = false;
							}
							lastSelected = row.isSelected === true ? row : undefined;
						} else {
							rows[index].isSelected = !rows[index].isSelected;
						}
					}
				};

				/**
				 * take a slice of the current sorted/filtered collection (pagination)
				 *
				 * @param {Number} start - start index of the slice
				 * @param {Number} number - the number of item in the slice
				 */
				this.slice = function splice(start, number) {
					tableState.pagination.start = start;
					tableState.pagination.number = number;
					return this.pipe();
				};

				this.getFilteredCollection = function getFilteredCollection() {
					return filtered || safeCopy;
				};

				/**
				 * Use a different filter function than the angular FilterFilter
				 * @param filterName the name under which the custom filter is registered
				 */
				this.setFilterFunction = function setFilterFunction(filterName) {
					filter = $filter(filterName);
				};

				/**
				 * Use a different function than the angular orderBy
				 * @param sortFunctionName the name under which the custom order function is registered
				 */
				this.setSortFunction = function setSortFunction(sortFunctionName) {
					orderBy = $filter(sortFunctionName);
				};

				/**
				 * Usually when the safe copy is updated the pipe function is called.
				 * Calling this method will prevent it, which is something required when using a custom pipe function
				 */
				this.preventPipeOnWatch = function preventPipe() {
					pipeAfterSafeCopy = false;
				};

				this.getSearchPredicates = function getSearchPredicates() {
					return angular.copy(tableState.search.predicateObject);
				};

				//le paramètre doNotPipe permet de lancer la méthode sans appeler le pipe (utile lors du reset complet resetTableState
				this.setSearchPredicates = function setSearchPredicates(predicateObj, doNotPipe) {
					tableState.search.predicateObject = angular.copy(predicateObj);
					$scope.$broadcast('event:stPredicateObjectSetted', tableState.search.predicateObject);

					if (doNotPipe === true) {
						return;
					}

					this.pipe();
				};

				this.resetSort = function resetSort(doNotPipe) {
					tableState.sort = {};
					tableState.pagination.start = 0;

					if (doNotPipe === true) {
						return;
					}

					this.pipe();
				};

				this.resetTableState = function resetTableState() {
					const doNotPipe = true;

					this.resetSort(doNotPipe);
					this.setSearchPredicates({}, doNotPipe);

					this.pipe();
				};
			}
		])
		.directive('stTable', function () {
			return {
				restrict: 'A',
				controller: 'stTableController',
				link: function (scope, element, attr, ctrl) {
					if (attr.stSetFilter) {
						ctrl.setFilterFunction(attr.stSetFilter);
					}

					if (attr.stSetSort) {
						ctrl.setSortFunction(attr.stSetSort);
					}
				}
			};
		});

	ng.module('blocks.smart.table').directive('stDisableSave', [
		function () {
			return {
				require: 'stTable',
				link: {
					pre: function (scope, element, attr, ctrl) {
						ctrl.setSaveState(false);
					}
				}
			};
		}
	]);
	ng.module('blocks.smart.table').directive('stSearch', [
		'stConfig',
		'$timeout',
		function (stConfig, $timeout) {
			return {
				require: '^stTable',
				scope: {
					predicate: '=?stSearch'
				},
				link: function (scope, element, attr, ctrl) {
					const tableCtrl = ctrl;
					let promise = null;
					const throttle = attr.stDelay || stConfig.search.delay;

					scope.$watch('predicate', function (newValue, oldValue) {
						if (newValue !== oldValue) {
							ctrl.tableState().search = {};
							tableCtrl.search(element[0].value || '', newValue);
						}
					});

					//table state -> view
					scope.$watch(
						function () {
							return ctrl.tableState().search;
						},
						function (newValue, oldValue) {
							const predicateExpression = scope.predicate || '$';
							if (newValue.predicateObject && newValue.predicateObject[predicateExpression] !== element[0].value) {
								element[0].value = newValue.predicateObject[predicateExpression] || '';
							}
						},
						true
					);

					// view -> table state
					element.bind('input', function (evt) {
						evt = evt.originalEvent || evt;
						if (promise !== null) {
							$timeout.cancel(promise);
						}
						promise = $timeout(function () {
							tableCtrl.search(evt.target.value, scope.predicate || '');
							promise = null;
						}, throttle);
					});
				}
			};
		}
	]);

	ng.module('blocks.smart.table').directive('stSelectRow', [
		'stConfig',
		function (stConfig) {
			return {
				restrict: 'A',
				require: '^stTable',
				scope: {
					row: '=stSelectRow'
				},
				link: function (scope, element, attr, ctrl) {
					const mode = attr.stSelectMode || stConfig.select.mode;
					element.bind('click', function () {
						scope.$apply(function () {
							ctrl.select(scope.row, mode);
						});
					});

					scope.$watch('row.isSelected', function (newValue) {
						if (newValue === true) {
							element.addClass(stConfig.select.selectedClass);
						} else {
							element.removeClass(stConfig.select.selectedClass);
						}
					});
				}
			};
		}
	]);

	ng.module('blocks.smart.table').directive('stSort', [
		'stConfig',
		'$parse',
		'$state',
		function (stConfig, $parse, $state) {
			return {
				restrict: 'A',
				require: '^stTable',
				link: function (scope, element, attr, ctrl) {
					let predicate = attr.stSort;
					const getter = $parse(predicate);
					let index = 0;
					const classAscent = attr.stClassAscent || stConfig.sort.ascentClass;
					const classDescent = attr.stClassDescent || stConfig.sort.descentClass;
					const stateClasses = [classAscent, classDescent];
					let sortDefault;

					element.addClass(stConfig.sort.sortClass);
					const dataFromStorage = JSON.parse(localStorage.getItem($state.current.name));
					if (attr.stSortDefault && !dataFromStorage) {
						sortDefault = scope.$eval(attr.stSortDefault) !== undefined ? scope.$eval(attr.stSortDefault) : attr.stSortDefault;
					}

					//view --> table state
					function sort() {
						index++;
						predicate = ng.isFunction(getter(scope)) ? getter(scope) : attr.stSort;
						if (index % 3 === 0 && attr.stSkipNatural === undefined) {
							//manual reset
							index = 0;
							ctrl.tableState().sort = {};
							ctrl.tableState().pagination.start = 0;
							ctrl.pipe();
						} else {
							ctrl.sortBy(predicate, index % 2 === 0);
						}
					}

					element.bind('click', function sortClick() {
						if (predicate) {
							scope.$apply(sort);
						}
					});

					if (sortDefault) {
						index = sortDefault === 'reverse' ? 1 : 0;
						sort();
					}

					//table state --> view
					scope.$watch(
						function () {
							return ctrl.tableState().sort;
						},
						function (newValue) {
							if (newValue.predicate !== predicate) {
								index = 0;
								element.removeClass(classAscent).removeClass(classDescent);
							} else {
								index = newValue.reverse === true ? 2 : 1;
								element.removeClass(stateClasses[index % 2]).addClass(stateClasses[index - 1]);
							}
						},
						true
					);
				}
			};
		}
	]);

	ng.module('blocks.smart.table').directive('stPagination', [
		'stConfig',
		'$state',
		'$translate',
		function (stConfig, $state, $translate) {
			return {
				restrict: 'EA',
				require: '^stTable',
				scope: {
					stItemsByPage: '=?',
					stDisplayedPages: '=?',
					stPageChange: '&'
				},
				templateUrl: function (element, attrs) {
					if (attrs.stTemplate) {
						return attrs.stTemplate;
					}
					return stConfig.pagination.template;
				},
				link: function (scope, element, attrs, ctrl) {
					const currentLocation = $translate.instant($state?.current?.name);
					const paginationSettingFromSessionStorage = JSON.parse(sessionStorage.getItem('paginationSettings_' + currentLocation));

					scope.stItemsByPage = scope.stItemsByPage ? +scope.stItemsByPage : stConfig.pagination.itemsByPage;
					scope.stDisplayedPages = scope.stDisplayedPages ? +scope.stDisplayedPages : stConfig.pagination.displayedPages;

					//gestion enregistrement de la pagination
					scope.stItemsByPage = paginationSettingFromSessionStorage?.numberByPage || scope.stItemsByPage;

					scope.currentPage = 1;
					scope.safeCurrentPage = { page: 1 };
					scope.pages = [];
					scope.itemsCount = 0;

					function redraw() {
						const paginationState = ctrl.tableState().pagination;
						let start = 1;
						let end;
						let i;
						const prevPage = scope.currentPage;

						//enregistrement de la page courante
						if (paginationState.page) {
							const paginationSettingFromSessionStorage = JSON.parse(sessionStorage.getItem('paginationSettings_' + currentLocation));
							sessionStorage.setItem(
								'paginationSettings_' + currentLocation,
								JSON.stringify({ ...paginationSettingFromSessionStorage, paginationState: paginationState })
							);
						}

						scope.currentPage = Math.floor(paginationState.start / paginationState.number) + 1;

						start = Math.max(start, scope.currentPage - Math.abs(Math.floor(scope.stDisplayedPages / 2)));
						end = start + scope.stDisplayedPages;

						if (end > paginationState.numberOfPages) {
							end = paginationState.numberOfPages + 1;
							start = Math.max(1, end - scope.stDisplayedPages);
						}

						scope.pages = [];
						scope.numPages = paginationState.numberOfPages || 0;
						scope.itemsCount = paginationState.itemsCount || 0;

						for (i = start; i < end; i++) {
							scope.pages.push(i);
						}

						scope.safeCurrentPage.page = scope.currentPage;

						if (prevPage !== scope.currentPage) {
							scope.stPageChange({ newPage: scope.currentPage });
						}
					}

					//table state --> view
					scope.$watch(
						function () {
							return ctrl.tableState().pagination;
						},
						redraw,
						true
					);

					//scope --> table state  (--> view)
					scope.$watch('stItemsByPage', function (newValue, oldValue) {
						//enregistrement du nombre d'élément par page
						const paginationSettingFromSessionStorage = JSON.parse(sessionStorage.getItem('paginationSettings_' + currentLocation));
						sessionStorage.setItem(
							'paginationSettings_' + currentLocation,
							JSON.stringify({ ...paginationSettingFromSessionStorage, domain: currentLocation, numberByPage: newValue })
						);

						if (newValue !== oldValue) {
							scope.selectPage(1);
						}
					});

					scope.$watch('stDisplayedPages', redraw);

					//view -> table state
					scope.selectPage = function (page) {
						if (page > 0 && page <= scope.numPages) {
							ctrl.slice((page - 1) * scope.stItemsByPage, scope.stItemsByPage);
						}
					};

					if (!ctrl.tableState().pagination.number) {
						ctrl.slice(0, scope.stItemsByPage);
					}
				}
			};
		}
	]);

	ng.module('blocks.smart.table').directive('stPipe', [
		'$rootScope',
		'$timeout',
		'stConfig',
		'$window',
		'$state',
		function ($rootScope, $timeout, stConfig, $window, $state) {
			return {
				require: 'stTable',
				scope: {
					stPipe: '=',
					deregisterListener: '='
				},
				link: {
					pre: function (scope, element, attrs, ctrl) {
						let pipePromise = null;
						let broadcastUserCtx = null;
						scope.$on('$destroy', () => {
							cancelTimeout();

							if (broadcastUserCtx !== undefined) {
								broadcastUserCtx();
							}
						});

						const savedTableState = $window.localStorage.getItem($state.current.name);
						if (savedTableState) {
							const tableState = ctrl.tableState();
							const data = JSON.parse(savedTableState);
							tableState.sort = data.sort;
							tableState.search = data.search;
							ctrl.setTableState(tableState);
						}

						broadcastUserCtx = $rootScope.$on('userContextChange', (e) => {
							ctrl.applyUserFilterPreferences(true);
							ctrl.setSearchPredicates(ctrl.tableState().search.predicateObject, false);
						});

						if (ng.isFunction(scope.stPipe)) {
							ctrl.preventPipeOnWatch();
							ctrl.pipe = function () {
								cancelTimeout();
								pipePromise = $timeout(function () {
									scope.stPipe(ctrl.tableState(), ctrl);
									const tableState = ctrl.tableState();

									const userFilters = ctrl.getFiltersUserPreferences();
									const currentFilters = angular.copy(tableState.search);
									if (userFilters && Object.keys(userFilters).length > 0 && currentFilters && currentFilters.predicateObject) {
										for (const key in userFilters) {
											//On est dans le cas ou le filtre vient de la préférence utilisateur, on ne sauvegarde donc pas
											if (
												currentFilters.predicateObject.hasOwnProperty(key) &&
												currentFilters.predicateObject[key] === userFilters[key]
											) {
												delete currentFilters.predicateObject[key];
											}
										}
									}

									if (!ctrl.isSaveDisabled()) {
										const localStorage = $window.localStorage;
										localStorage.setItem(
											$state.current.name,
											JSON.stringify({
												sort: tableState.sort,
												search: currentFilters
											})
										);
									}
								}, stConfig.pipe.delay);
							};
						}

						function cancelTimeout() {
							if (pipePromise !== undefined) {
								$timeout.cancel(pipePromise);
								pipePromise = undefined;
							}
						}
					},

					post: function (scope, element, attrs, ctrl) {
						ctrl.pipe();
					}
				}
			};
		}
	]);
})(angular);
