const isElectron = () => false;
import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-electron-config',
    bindings: {
        config: '=',
        progress: '<',
        block: '<'
    },
    template: require('./massia.electron.configuration.tpl.html')
})
export class MassiaElectronConfigurationController {
    constructor(ElectronService) {
        this.ElectronService = ElectronService;
    }

    $onInit() {
        this.url = null;
        this.downloaded = false;
        this.download = false;
        if (isElectron()) {
            /* require('electron').ipcRenderer.on('info', (ev, info) => {
                this.download = false;
                this.downloaded = true;
                setTimeout(() => {
                    require('electron').ipcRenderer.send('reboot', 'reboot');
                }, 5000);
            });
            require('electron').ipcRenderer.on('downloaded-percent', (ev, down) => {
                this.progress = Math.round(down.percent);
            }); */
        }
    }

    $onDestroy() {}

    async checkServer() {
        this.checking = true;
        try {
            const res = await this.ElectronService.CheckServer(this.url);
            if (res === 'MassiaServer') {
                const Store = __non_webpack_require__('electron-store');
                let store = new Store();
                store.set('server', this.url);
                __configuration.authUrl = `${this.url}token`;
                __configuration.apiUrl = `${this.url}api`;
                this.checked = 'ok';
                this.setupComplete = true;
                setTimeout(() => {
                    //require('electron').ipcRenderer.send('restart', true);
                }, 5000);
            } else {
                this.checked = 'remove';
            }
        } catch (err) {
            console.log(err);
            this.checked = 'remove';
        }
    }

    install() {
        //require('electron').ipcRenderer.send('install', 'install');
        this.download = true;
    }
}

MassiaElectronConfigurationController.$inject = ['ElectronService'];
