import validatorBuilder from 'validator-builder';
import ClientDiversEntete from '../client-divers-entete/client.divers.entete.model';

let clientDiversValidator = null;

export default class ClientDivers {
    constructor(data) {
        if (data) {
            this.entete = new ClientDiversEntete(data.entete);
        } else {
            this.entete = new ClientDiversEntete();
        }
    }

    isValid() {
        clientDiversValidator = clientDiversValidator || validatorBuilder.getInstanceFor('ClientDivers');
        const validationResults = clientDiversValidator.validate(this);
        return validationResults.isValid;
    }
}
