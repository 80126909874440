export default class PreviewLoginController {
    /* @ngInject */
    constructor($scope) {
        this.$scope = $scope;
        this.displayModal = false;
        this.defaultBg = '/assets/img/portail/portail_default_login_bg.jpg';
    }

    async $onInit() {
        this.loginBg = this.defaultBg;
        const that = this;
        this.$scope.$watch(
            '$ctrl.background',
            function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue == null || newValue == '' || newValue == undefined) {
                        that.loginBg = that.defaultBg;
                    } else {
                        that.loginBg = newValue;
                    }
                }
            },
            true
        );
    }

    closeModal() {
        this.displayModal = false;
    }
}
