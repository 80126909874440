(function (angular, undefined) {
    'use strict';

    angular.module('blocks.smart.table')
        .directive('stFilterCombobox', ['$timeout', '_', function ($timeout, _) {
            return {
                require: '^stTable',
                restrict: 'A',
                transclude: true,
                templateUrl: 'blocks/smart-table/filter-combobox.html',
                scope: {
                    stFilterItems: '=',
                    predicate: '=stFilterCombobox',
                    predicateName: '@stFilterCombobox'
                },
                link: function (scope, element, attr, ctrl) {
                    var timer;
                    var stTableData = ctrl.safeCopy;
                    var unregisterDestroyEvent = scope.$on('$destroy', dispose);
                    var unregisterFilterSettedEvent = scope.$on('event:stPredicateObjectSetted', predicateObjectUpdated);
                    var slideboxEl, pinnedTableEl;
                    var first = true;

                    scope.filtering = false;
                    scope.currentFilter = '';
                    element.addClass('st-filter');

                    // Customize item model attr used
                    scope.stFilterCustomId = attr.stFilterCustomId || 'id';
                    scope.stFilterCustomLabel = attr.stFilterCustomLabel || 'label';

                    if (attr.stFilterItems === undefined) {
                        scope.stFilterItems = [];
                        var filter = _.getDistinctPropertyValues(stTableData, scope.predicateName);
                        angular.forEach(filter, function (value, key) {
                            scope.stFilterItems.push({id: value, label: value});
                        });
                    }

                    // On utilise le filtre custom
                    ctrl.setFilterFunction('acTableFilter');

                    // pour récupérer l'info quand on modifie le tableState
                    scope.$watch(function () {
                        return ctrl.tableState().search;
                    }, function (newValue, oldValue) {
                        if (newValue.predicateObject && newValue.predicateObject[scope.predicateName]) {
                            scope.predicate = {txt: newValue.predicateObject[scope.predicateName]};
                            if(scope.predicate && scope.predicate.txt && first) {
                                scope.predicate = {
                                    txt: newValue.predicateObject[scope.predicateName].value
                                };
                                setSavedFilter();
                                first = false;
                            }
                        }
                    });

                    scope.eraseFilter = function () {
                        // si une valeur est entrée
                        if (scope.predicate) {
                            // on l'efface
                            scope.predicate.txt = undefined;
                        }
                        scope.currentFilter = '';
                        // on lance ensuite la recherche à vide (pour rappatrier tout)
                        ctrl.search(undefined, scope.predicateName);
                        scope.filtering = false;
                        scope.filter.isOpen = false;
                    };

                    scope.filter = function () {
                        if (scope.predicate && scope.predicate.txt) {
                            scope.setFilterInfos();
                            scope.applyFilter();
                            scope.filter.isOpen = false;
                        }
                    };

                    scope.setFilterInfos = function () {
                        if (scope.predicate && scope.predicate.txt) {
                            scope.currentFilter = getItemLabelById(scope.predicate.txt);
                            scope.filtering = true;
                        } else {
                            scope.filtering = false;
                        }
                    };

                    scope.applyFilter = function () {
                        if (scope.predicate && scope.predicate.txt) {
                            ctrl.search({value: scope.predicate.txt, comparator: true}, scope.predicateName);
                        }
                    };

                    // Dans le cas d'une table pinned, on ferme le dropdown au scroll
                    var slidebox = globalHelpers.findAncestorByClassName(element[0], "slidebox");
                    var pinnedTable = globalHelpers.findAncestorByClassName(element[0], "st-table-pinned");

                    if (slidebox) {
                        slideboxEl = angular.element(slidebox);
                        slideboxEl.bind('scroll', onScroll);
                    }

                    if (pinnedTable) {
                        pinnedTableEl = angular.element(pinnedTable);
                        pinnedTableEl.bind('scroll', onScroll);
                    }

                    function getItemLabelById(id) {
                        var item = _.find(scope.stFilterItems, function (e) {
                            return e[scope.stFilterCustomId] == id; // Comparaison == voulue (une valeur peut être en string et l'autre en int par exemple
                        });

                        return item ? item[scope.stFilterCustomLabel] : undefined;
                    }

                    function setSavedFilter() {
                        if(!scope.stFilterItems){
                            setTimeout(setSavedFilter, 500);
                        } else {
                            scope.filter();
                        }
                    }

                    function onScroll(e) {
                        timer = $timeout(function () {
                            scope.filter.isOpen = false;
                        });
                    }

                    function predicateObjectUpdated(evt, predicateObject) {
                        if (angular.isObject(predicateObject) && angular.isObject(predicateObject[scope.predicateName]) && predicateObject[scope.predicateName] !== undefined) {
                            var item = findInList(predicateObject[scope.predicateName].value);

                            if (item) {
                                scope.predicate = {txt: item[scope.stFilterCustomId].toString()};
                            } else {
                                predicateObject[scope.predicateName] = undefined; // L'élément n'existe pas dans la liste, on retire le filtre du predicateObject
                            }
                        } else {
                            scope.predicate = {};
                        }

                        scope.setFilterInfos();
                    }

                    function findInList(id) {
                        var item = _.find(scope.stFilterItems, function (e) {
                            return e[scope.stFilterCustomId] == id; // Comparaison == voulue (une valeur peut être en string et l'autre en int par exemple
                        });

                        return item;
                    }

                    function dispose() {
                        unregisterFilterSettedEvent();
                        unregisterDestroyEvent();
                        element.removeClass('st-filter');

                        if (timer) {
                            $timeout.cancel(timer);
                        }
                        if (slideboxEl !== undefined) {
                            slideboxEl.unbind('scroll', onScroll);
                        }
                        if (pinnedTableEl !== undefined) {
                            pinnedTableEl.unbind('scroll', onScroll);
                        }
                    }
                }
            };
        }]);
})(angular);