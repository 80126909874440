const serviceUrl = `${__configuration.apiUrl}/massia/gestion/inerte-declaration`;

export default class InerteDeclarationAnnuelleService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async print(idSite, annee, fileName) {
        const url = `${serviceUrl}/${idSite}/${annee}/${fileName}`;
        const res = await this.$http.get(url, {
            responseType: 'arraybuffer'
        });
        return res;
    }

    async getSites() {
        const url = `${__configuration.apiUrl}/massia/sites-commerciaux/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
