import ApiService from '../../app/services/api.service';
import { Injectable } from '../decorators';
import { BaseHttpService } from '../interface/MassiaHttpService.interface';
import { SerieTamis } from '../models/serieTamis/SerieTamis';
import { SerieTamisDetail } from '../models/serieTamis/SerieTamisDetail';

@Injectable('SerieTamisService')
export class SerieTamisService extends BaseHttpService<SerieTamis, SerieTamisDetail, any, any, any> {
    /* @ngInject */
    constructor(ApiService: ApiService) {
        super(ApiService);
        this.url = 'v2/massia/serie-tamis';
    }
}
