import ApiService from '../../app/services/api.service';
import { Injectable } from '../decorators';
import { BaseHttpService } from '../interface/MassiaHttpService.interface';
import { ElementCaracteristique } from '../models/element-caracteristique/ElementCaracteristique';

@Injectable('CaracteristiqueService')
export class CaracteristiqueService extends BaseHttpService<any, any, any, any, any> {
    /* @ngInject */
    constructor(ApiService: ApiService) {
        super(ApiService);
        this.url = 'v2/massia/caracteristiques';
    }

    getAllElement(id: number): Promise<ElementCaracteristique[]> {
        return this.apiSvc.get(`${this.url}/${id}/elements`)
    }
}