import PlanningModalController from './planning.modal.controller';

export default {
    bindings: {
        equipage: '=',
        demande: '=',
        date: '=',
        modalInstance: '='
    },
    template: require('./planning.modal.html'),
    controller: PlanningModalController
};
