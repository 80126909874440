import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-graph-config',
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: require('./massia.graph.config.tpl.html')
})
export class MassiaGraphConfigController {
    constructor() {}
    $onInit() {
        this.pointStyle = this.createPointStyle();
        this.barType = this.createBarType();
        this.series = this.resolve.data;
        this.dataset = this.resolve.dataset;
        this.type = this.resolve.type;
    }
    $onDestroy() {}

    onColorSave(color, index, model, type) {
        this.dataset[index][model] = color[type].toString();
    }

    createBarType() {
        return [
            {
                label: 'GRAPH.BAR.LINE',
                value: 'line'
            },
            {
                label: 'GRAPH.BAR.BAR',
                value: 'bar'
            },
            {
                label: 'GRAPH.BAR.BAR_HORIZONTAL',
                value: 'horizontalBar'
            },
            {
                label: 'GRAPH.BAR.RADAR',
                value: 'radar'
            },
            {
                label: 'GRAPH.BAR.PIE',
                value: 'pie'
            },
            {
                label: 'GRAPH.BAR.DONUT',
                value: 'doughnut'
            } /* 
            {
                label: 'GRAPH.BAR.POLAR',
                value: 'polarArea',
            },
            {
                label: 'GRAPH.BAR.BUBBLE',
                value: 'bubble',
            },
            {
                label: 'GRAPH.BAR.SCATTER',
                value: 'scatter',
            }, */
        ];
    }

    createPointStyle() {
        return [
            {
                label: 'GRAPH.POINT_STYLE.CIRCLE',
                value: 'circle'
            },
            {
                label: 'GRAPH.POINT_STYLE.CROSS',
                value: 'cross'
            },
            {
                label: 'GRAPH.POINT_STYLE.CROSS_ROT',
                value: 'crossRot'
            },
            {
                label: 'GRAPH.POINT_STYLE.DASH',
                value: 'dash'
            },
            {
                label: 'GRAPH.POINT_STYLE.LINE',
                value: 'line'
            },
            {
                label: 'GRAPH.POINT_STYLE.RECT',
                value: 'rect'
            },
            {
                label: 'GRAPH.POINT_STYLE.RECT_ROUNDED',
                value: 'rectRounded'
            },
            {
                label: 'GRAPH.POINT_STYLE.RECT_ROT',
                value: 'rectRot'
            },
            {
                label: 'GRAPH.POINT_STYLE.STAR',
                value: 'star'
            },
            {
                label: 'GRAPH.POINT_STYLE.TRIANGLE',
                value: 'triangle'
            }
        ];
    }

    ok() {
        this.close({
            $value: {
                dataset: this.dataset,
                type: this.type
            }
        });
    }
}
