import FactureRecap from './recap.model';
let unregisterReinit;

export default class FactureRecapController {
    static $inject = ['$stateParams', 'FacturesCommunicationService', 'FacturesService', 'notification'];

    constructor($stateParams, FacturesCommunicationService, FacturesService, notification) {
        this.$stateParams = $stateParams;
        this.FacturesCommunicationService = FacturesCommunicationService;
        this.FacturesService = FacturesService;
        this.notification = notification;
        this.loading = false;
        this.codeLoading = false;
        this.isEditMode = false;
    }

    async $onInit() {
        unregisterReinit = this.FacturesCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        unregisterReinit();
    }

    initForm() {}

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
