export default class SharedFileService {
	constructor($http) {
		this.$http = $http;
		this.url = `${__configuration.apiUrl}/massia/portail/file`;
	}

	getAll(filters = [], sorts = [], pagination = { skip: 0, take: 50 }) {
		return this.$http.get(this.url, {
			params: {
				filters: JSON.stringify(filters),
				sorts: JSON.stringify(sorts),
				pagination: JSON.stringify(pagination)
			}
		});
	}

	getOne(id) {
		return this.$http.get(`${this.url}/${id}`);
	}

	create(data) {
		return this.$http.post(this.url, data);
	}

	delete(id) {
		return this.$http.delete(`${this.url}/${id}`);
	}
}

SharedFileService.$inject = ['$http'];
