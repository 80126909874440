import Import3rForm from './import.3r.form.model';
import Import3rParam from './import.3r.param.model';

export default class Import3rFormController {
    /* @ngInject */
    constructor(
        $scope,
        $state,
        $stateParams,
        $translate,
        $uibModal,
        MesuresService,
        CaracteristiquesService,
        SitesService,
        Import3rService,
        notification,
        _
    ) {
        this._ = _;
        this.SitesService = SitesService;
        this.CaracteristiquesService = CaracteristiquesService;
        this.MesuresService = MesuresService;
        this.$scope = $scope;
        this.$state = $state;
        this.params = $stateParams;
        this.$translate = $translate;
        this.Import3rService = Import3rService;
        this.notification = notification;
        this.$uibModal = $uibModal;
        this.isValid = false;
        this.haveOneEssaiParam = false;
        this.haveOneCaractParam = false;
        this.haveLabo = false;
        this.loading = false;
        this.isEditMode = false;
        this.enumMachineType = {
            'PRESSE_3R.ENUM.1': 1,
            'PRESSE_3R.ENUM.2': 2
        };
        this.lastEssaiRowValid = false;
        this.lastCaractRowValid = false;
    }

    async $onInit() {
        try {
            this.essaisListByType = [];
            this.laboList = [];
            this.essaisList = [];
            this.caractList = [];

            this.config = new Import3rForm(this.params.id ? await this.getConfig(this.params.id) : {});
            await this.getLaboList();

            await this.getEssaiList();

            // en édition
            if (this.config.essaisList.length > 0) {
                for (const essaisList of this.config.essaisList) {
                    // on passe les essais en selected
                    essaisList.essais.forEach((x) => {
                        essaisList.essaisList.find((y) => {
                            return y.id === x.id;
                        }).selected = true;
                    });
                    // relance le mécanisme pour les sous essais
                    this.getSousEssaiList(essaisList);
                }
            }

            await this.getCaractList();

            this.isLastEssaiRowValid();

            this.isLastCaractRowValid();

            this.machineTypeSelect = Object.entries(this.enumMachineType).find((x) => x[1] === this.config.machineType);
            this.isFormValid();

            this.$scope.$watch('$ctrl.config.laboratoire', () => {
                this.haveLabo = !!this.config.laboratoire;
            });

            this.$scope.$watchCollection('$ctrl.config.essaisList[0]', () => {
                const { libelle, sousEssai } = this.config.essaisList[0];
                this.haveOneCaractParam = !(!libelle || !sousEssai);
            });

            this.$scope.$watchCollection('$ctrl.config.caractList[0]', () => {
                if (this.config.caractList.length > 1) {
                    const { libelle, caracteristique } = this.config.caractList[0];
                    this.haveOneCaractParam = !(!libelle || !caracteristique);
                }
            });
        } catch (e) {
            console.log('🔥', e.data);
        }
    }

    async getConfig(configId) {
        this.startLoading();
        try {
            this.isEditMode = true;
            return await this.Import3rService.getParams3rConfigById(configId);
        } catch (ex) {
            this.backToPreviousState();
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getLaboList() {
        this.laboList = await this.SitesService.getSitesLaboratoiresFiltreUser();
    }

    async selectLabo(laboSelected) {
        if (laboSelected.length > 0) {
            this.config.laboratoire = laboSelected[0];
        } else {
            this.config.laboratoire = null;
        }
    }

    async getEssaiList() {
        let essaisList = [];
        if (this.config.machineType === 1) {
            essaisList = await this.MesuresService.getSousMesuresSimplyList('essais', 'BPE');
        } else if (this.config.machineType === 2) {
            essaisList = await this.MesuresService.getSousMesuresSimplyList('essais', 'CIM');
        }
        this.essaisListByType = essaisList;
        await this.sortEssaisList();

        if (this.config.essaisList.length > 0) {
            this.config.essaisList.map((x) => (x.essaisList = angular.copy(this.essaisList)));
        }
    }

    async sortEssaisList() {
        this.essaisList = this.essaisListByType
            .filter((e) => e.essaiCode === e.sousEssaiCode)
            .map((e) => {
                e.code = e.essaiCode;
                e.libelle = e.essaiLibelle;
                e.id = e.essaiId;
                return angular.copy(e);
            });
    }

    async getSousEssaiList(paramSelected) {
        await this.sortSousEssaisList(paramSelected);
    }

    async sortSousEssaisList(paramSelected) {
        const { essais } = paramSelected;
        if (essais && essais.length > 0) {
            // pour chaque essai
            let ssessList = essais.flatMap((essai) => {
                //je récupère la liste des sous essais
                return this.essaisListByType
                    .filter((e) => e.essaiId === essai.id)
                    .map((e) => {
                        e.code = e.sousEssaiCode;
                        e.libelle = e.sousEssaiLibelle;
                        e.id = e.sousEssaiId;
                        return angular.copy(e);
                    });
            });
            //supprime les doublons par le code
            ssessList = this._.uniqBy(ssessList, 'code');

            paramSelected.sousEssaiSelect = null;
            paramSelected.sousEssaisList = [...ssessList];
        } else {
            paramSelected.essais = null;
            paramSelected.sousEssai = null;
            paramSelected.sousEssaiSelect = null;
        }
    }

    async selectSousEssai(paramSelected) {
        if (paramSelected.sousEssaiSelect.length > 0) {
            paramSelected.sousEssai = paramSelected.sousEssaiSelect[0];
        } else {
            paramSelected.sousEssai = null;
        }
        this.isLastEssaiRowValid();
    }

    addEssaiRow() {
        if (this.lastEssaiRowValid) {
            this.config.essaisList = [
                ...this.config.essaisList,
                {
                    libelle: '',
                    essai: null,
                    sousEssai: null,
                    essaiSelect: [],
                    essaisList: angular.copy(this.essaisList)
                }
            ];
        }
        this.isLastEssaiRowValid();
    }

    deleteParamEssai(index) {
        this.config.essaisList.splice(index, 1);
        if (this.config.essaisList.length < 1) {
            this.config.essaisList = [
                {
                    libelle: '',
                    essai: null,
                    sousEssai: null
                }
            ];
        }
    }

    async getCaractList() {
        const caracteristiqueList = await this.CaracteristiquesService.getCaracteristiques(
            [
                {
                    criterion: 'flatDomainesLibelles',
                    value: 'prelevements',
                    type: 'Contains'
                }
            ],
            null,
            null
        );
        this.caractList = caracteristiqueList ? caracteristiqueList.items : [];
        if (this.config.caractList.length === 0) {
            this.config.caractList.push(new Import3rParam());
        }
        this.config.caractList.map((x) => (x.caractList = angular.copy(this.caractList)));
    }

    selectCaract(paramSelected) {
        if (paramSelected.caractSelect.length > 0) {
            paramSelected.caracteristique = paramSelected.caractSelect[0];
        } else {
            paramSelected.caracteristique = null;
        }
        this.isLastCaractRowValid();
    }

    addCaractRow() {
        if (this.lastCaractRowValid) {
            this.config.caractList = [
                ...this.config.caractList,
                {
                    libelle: '',
                    caracteristique: null,
                    caractSelect: [],
                    caractList: angular.copy(this.caractList)
                }
            ];
        }
        this.isLastCaractRowValid();
    }

    deleteParamCarac(index) {
        this.config.caractList.splice(index, 1);
        if (this.config.caractList.length < 1) {
            this.config.caractList = [
                {
                    libelle: '',
                    caracteristique: null
                }
            ];
        }
    }

    async confirmer() {
        try {
            if (!this.areEssaiParamRowCompleted() || !this.areCaractParamRowCompleted()) {
                return this.notification.info(this.$translate.instant('PRESSE_3R.PARAM_ROW_INCOMPLET'));
            }
            if (this.isEditMode) {
                await this.Import3rService.updateParam3rConfig(this.config);
            } else {
                await this.Import3rService.createParam3rConfig(this.config);
            }

            this.notification.success(this.$translate.instant('PRESSE_3R.SUCCESS', { libelle: this.config.libelle }));
            this.$state.go('import-3r.list', {}, { reload: true });
        } catch (ex) {
            return this.notification.error(ex.data);
        }
    }

    async reinit() {
        if (this.isEditMode === true) {
            await this.getConfig(this.params.id);
        } else {
            this.config = new Import3rForm();
        }
    }

    isLastEssaiRowValid() {
        if (this.config.essaisList.length > 0) {
            const { libelle, essais, sousEssai, essaiIdentifieur } = this.config.essaisList[this.config.essaisList.length - 1];
            this.lastEssaiRowValid = !!(libelle && essais && sousEssai && essaiIdentifieur);
        }
    }

    isLastCaractRowValid() {
        if (this.config.caractList.length > 0) {
            const { libelle, caracteristique, essaiIdentifieur } = this.config.caractList[this.config.caractList.length - 1];
            this.lastCaractRowValid = !!(libelle && caracteristique && essaiIdentifieur);
        }
    }

    resetEssaisValue() {
        this.config = {
            essaisList: null,
            caractList: null,
            ...this.config
        };
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('import-3r.list');
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    isFormValid() {
        this.config.isValid();
    }

    debug() {
        console.log(' 🔥 THIS 🔥', this);
    }

    areEssaiParamRowCompleted() {
        if (this.config.essaisList.length > 1) {
            return !this.config.essaisList.find((x) => !x.libelle || !x.sousEssai);
        }
        return true;
    }

    areCaractParamRowCompleted() {
        if (this.config.caractList.length > 1) {
            return !this.config.caractList.find((x) => !x.libelle || !x.caracteristique);
        }
        return true;
    }

    async getNoIterationAlert(haveIterateOrder) {
        console.log('🚀: Import3rFormController -> foobar -> haveIterateOrder', _.cloneDeep(haveIterateOrder));
        if (!haveIterateOrder) {
            try {
                const modalInstance = this.$uibModal.open({
                    template: `
                    <div class="modal-body" id="modal-body">
                        <div ng-if="!$ctrl.loading">
                            <h4 class="modal-title" id="modal-title"><strong>
                            <span translate="{{'PRESSE_3R.CONFIG.POPUP.TITRE_ALERT_ITERATE_ORDER'}}"></span>
                            </strong></h4>
                            <div class="alert alert-danger" style="padding:10px; margin:10px 0;">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span translate="{{'PRESSE_3R.CONFIG.POPUP.MESSAGE_ALERT_ITERATE_ORDER'}}"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-primary" type="button" ng-click="$ctrl.close()">
                                <span class="massia-icon-door_exit"></span>
                                <span class="hidden-xs" translate="FERMER"></span>
                            </button>
                        </div>
                    </div>`,
                    controller: [
                        '$uibModalInstance',
                        function ($uibModalInstance) {
                            const $ctrl = this;
                            $ctrl.close = () => $uibModalInstance.close();
                        }
                    ],
                    controllerAs: '$ctrl',
                    backdrop: 'static',
                    animation: true
                });

                const res = await modalInstance.result;
            } catch (err) {
                console.error(err);
                this.notification.error(err.data ?? err);
            }
        }
    }
}
