FormuleValidator.$inject = ['validator'];

export default function FormuleValidator(validator) {
    const instance = new validator();
    // instance.ruleFor('pourcentageTotal').must(total100).withMessage('VALIDATION_TOO_SHORT_LISTE');

    // function total100(pourcentageTotal) {
    //     return pourcentageTotal == 100;
    // }
    return instance;
}
