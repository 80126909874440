import ParametreTransportController from './parametre.transport.controller';

export default {
    bindings: {
        societe: '<',
        onUpdate: '&'
    },
    template: require('./parametre.transport.html'),
    controller: ParametreTransportController
};
