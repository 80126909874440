export default class ControleResultatControler {
    static $inject = ['$stateParams', 'ControlesCommunicationService', 'ControlesService', 'notification', 'ModalService', '$translate'];

    constructor($stateParams, ControlesCommunicationService, ControlesService, notification, ModalService, $translate) {
        this.$stateParams = $stateParams;
        this.ControlesCommunicationService = ControlesCommunicationService;
        this.ControlesService = ControlesService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$translate = $translate;
    }

    async $onInit() {
        this.loadResultats();
    }

    async loadResultats() {
        if (this.idControle) {
            this.startLoading();
            try {
                this.resultats = await this.ControlesService.getControleResultats(this.idControle);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('controles.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        }
    }

    async deleteResultat(resultat) {
        const _this = this;
        if (resultat && resultat.id) {
            const modalInstance = this.ModalService.confirm({
                modalTitle: this.$translate.instant('CONTROLES.RESULTAT.DELETE.TITLE'),
                modalMsg: this.$translate.instant('CONTROLES.RESULTAT.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                _this.startLoading();
                try {
                    resultat.idPrestation = _this.idControle;
                    await _this.ControlesService.deleteResultatById(resultat.id);
                    _this.notification.success(_this.$translate.instant('CONTROLES.RESULTAT.DELETE.SUCCESS'));
                    _this.loadResultats();
                } catch (ex) {
                    _this.notification.error(ex.data);
                } finally {
                    _this.stopLoading();
                }
            });
        }
    }

    activeEditMode(resultat) {
        resultat.isEditMode = true;
    }

    desactiveEditMode(resultat) {
        resultat.isEditMode = false;
    }

    async createResultat(resultat) {
        if (resultat && resultat.code && resultat.libelle) {
            this.startLoading();
            try {
                resultat.idPrestation = this.idControle;
                await this.ControlesService.createResultat(resultat);
                this.notification.success(this.$translate.instant('CONTROLES.RESULTAT.CREATE.SUCCESS'));
                this.loadResultats();
                this.newResultat = {};
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        }
    }

    async updateResultat(resultat) {
        if (resultat && resultat.id && resultat.code && resultat.libelle) {
            this.startLoading();
            try {
                resultat.idPrestation = this.idControle;
                await this.ControlesService.updateResultat(resultat);
                //this.notification.success(this.$translate.instant('CONTROLES.RESULTAT.UPDATE.SUCCESS'));
                this.loadResultats();
                this.newResultat = {};
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        }
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
