import OffreEnteteController from './offre.entete.controller';

export default {
    bindings: {
        offre: '=',
        isOpen: '=',
        dateDemandeCreation: '=',
        personnes: '=',
        isValidate: '=',
        responseValidation: '&',
        isUserCanModifyOffer: '='
    },
    template: require('./offre.entete.html'),
    controller: OffreEnteteController
};
