import InerteDeclarationAnnuelleConfig from './_config/inerte.declaration.annuelle.config';
import InerteDeclarationAnnuelleRoutes from './_config/inerte.declaration.annuelle.routes';
import InerteDeclarationAnnuelleComponent from './components/inerte-declaration-annuelle';
import InerteDeclarationAnnuelleService from './services/inerte.declaration.annuelle.service';

const moduleName = 'app.parametrageGeneral.inerteDeclarationAnnuelle';

angular
    .module(moduleName, [])
    .config(InerteDeclarationAnnuelleConfig)
    .config(InerteDeclarationAnnuelleRoutes)
    .service('InerteDeclarationAnnuelleService', InerteDeclarationAnnuelleService)
    .component('inerteDeclarationAnnuelle', InerteDeclarationAnnuelleComponent);

export default moduleName;
