import { copy } from 'angular';
import ModeleSelection from '../../../../../../models/modele.selection.models';
import SyntheseCacheService from '../../../../../../services/synthese.cache.service';

/* @ngInject */
export default class SyntheseBlocSelectionController {
	/**
	 *
	 * @param {import("angular").IScope} $scope
	 */
	constructor(
		$scope,
		$state,
		SyntheseModelesService,
		SyntheseEntitiesService,
		MassiaRightsService,
		CodeAutomatiqueService,
		MassiaApplicationService,
		SyntheseCacheService,
		localStorageService,
		AuthConstant,
		$timeout
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.SyntheseModelesService = SyntheseModelesService;
		this.SyntheseEntitiesService = SyntheseEntitiesService;
		this.MassiaRightsService = MassiaRightsService;
		this.CodeAutomatiqueService = CodeAutomatiqueService;
		this.MassiaApplicationService = MassiaApplicationService;
		this.localStorageService = localStorageService;
		this.AuthConstant = AuthConstant;
		this.$timeout = $timeout;
		/**
		 * @type SyntheseCacheService
		 */
		this.SyntheseCacheService = SyntheseCacheService;
	}

	async $onInit() {
		try {
			this.u = [];
			this.app = this.MassiaApplicationService.getApplication();
			this.updateRight = this.MassiaRightsService.userHasRight('blocsel', 'update');
			this.createRight = this.MassiaRightsService.userHasRight('blocsel', 'create');
			this.canUpdate = this.bloc.id > 0 ? this.createRight : this.updateRight;
			this.code = await this.codeAuto();
			this.modeleSelection = this.modeleSelection.map((x) => {
				x.canUpdate = !this.canUpdate;
				return x;
			});
			this.needEssai = true;
			this.open = {
				crit: true,
				essai: true
			};

			this.dragOptions = {
				placeholder: 'card bg-primary mas-m-5',
				animation: 200
			};

			this.bloc.modelSelection = new ModeleSelection(this.bloc.modelSelection);
			this.currentModele = await this.loadModeleSelection();
			if (this.bloc.modelSelection.modelSelectionTypes.findIndex((x) => x.idTypeSynthese === 17) > -1) {
				this.needEssai = false;
			}
		} catch (err) {
			console.error(err);
		}
	}

	$onDestroy() {
		for (let i = 0; i < this.u.length; i++) {
			const u = this.u[i];
			u();
		}
		if (this.unsub) {
			this.unsub.unsubscribe();
		}
	}

	async codeAuto() {
		try {
			const code = await this.CodeAutomatiqueService.getCodeAutomatique(this.MassiaApplicationService.getApplication());
			const res = code.find((x) => x.domaineCode === 'BlocSel');
			return res;
		} catch (err) {
			console.error(err);
		}
	}

	async getEssais() {
		try {
			if (this.bloc.modelSelection && this.bloc.modelSelection.id && this.SyntheseCacheService.essais) {
				this.essaisLoaded = this.essaisLoaded.filter((x) => x.typeProduit.some((t) => t.idFamille === this.bloc.modelSelection.famille.id));
				/* let essais = await this.SyntheseCacheService.getEssais([this.bloc.modelSelection.id]);
                this.essaisLoaded = essais; */
			}
		} catch (err) {
			console.error(err);
		}
	}

	async loadModeleSelection() {
		try {
			if (this.bloc.modelSelection && this.bloc.modelSelection.id) {
				return await this.SyntheseModelesService.getSyntheseModeleById(this.bloc.modelSelection.id);
			}
			return {};
		} catch (err) {
			console.error(err);
		}
	}

	async selectModel(data) {
		this.bloc.modelSelection.id = data.id;
		this.currentModele = this.bloc.modelSelection = await this.loadModeleSelection();
	}

	selectEssais() {
		if (this.selectedEssais) {
			let i = 0;
			const essais = [];
			while (i < this.selectedEssais.length) {
				// eslint-disable-next-line no-loop-func
				const current = this.bloc.blocSelectionEssais.find((e) => e.id === this.selectedEssais[i].id);
				let tmp = {
					...this.selectedEssais[i],
					complementaire: false,
					blocSelectionSousEssais: current ? current.blocSelectionSousEssais : [],
					order: this.bloc.blocSelectionEssais.length
				};
				if (current) {
					tmp = { ...tmp, ...current };
				}
				essais.push(tmp);
				i++;
			}
			this.bloc.blocSelectionEssais = essais;
		}
	}

	applyGlobalProducteur() {
		const localUser = this.localStorageService.get(this.AuthConstant.MASSIA_PERSISTS_USER_DATA_STORAGE);
		const selectedNavSiteId = localUser.selectedNavSite?.id;

		if (
			selectedNavSiteId &&
			this.bloc.modelSelection &&
			this.bloc.modelSelection.modelSelectionCriteres &&
			this.bloc.modelSelection.modelSelectionCriteres.length > 0
		) {
			const affSiteProd = this.bloc.modelSelection.modelSelectionCriteres.find(
				(e) => e.typeCritere === 7 && e.donneeSelectionable.domaine.code === 'SiteProd'
			);
			if (affSiteProd && !affSiteProd.modelSelectionCritereValeur[0].idValeur) {
				affSiteProd.modelSelectionCritereValeur[0].idValeur = selectedNavSiteId;
			}
		}
	}

	setLinkedProducteurFilter() {
		if (
			this.bloc.modelSelection &&
			this.bloc.modelSelection.modelSelectionCriteres &&
			this.bloc.modelSelection.modelSelectionCriteres.length > 0
		) {
			const affSiteProd = this.bloc.modelSelection.modelSelectionCriteres.find(
				(e) => e.typeCritere === 7 && e.donneeSelectionable.domaine.code === 'SiteProd'
			);
			if (affSiteProd) {
				for (let i = 0; i < affSiteProd.modelSelectionCritereValeur.length; i++) {
					this.u.push(
						this.$scope.$watch(
							() => affSiteProd.modelSelectionCritereValeur[i],
							() => {
								this.loading = true;

								this.$timeout(() => {
									this.bloc.modelSelection.modelSelectionCriteres.map((e) => {
										if (e.id !== affSiteProd.id) {
											e.producteursSelectionCritereValeur = affSiteProd.modelSelectionCritereValeur;
										}
									});
									this.loading = false;
								}, 0);
							}
						)
					);
				}

				this.bloc.modelSelection.modelSelectionCriteres.map((e) => {
					if (e.id !== affSiteProd.id) {
						e.producteursSelectionCritereValeur = affSiteProd.modelSelectionCritereValeur;
					}
				});
			}
		}
	}
}
