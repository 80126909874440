export default function FileRoute($stateProvider) {
    $stateProvider
        .state('portail.file', {
            url: '/file',
            abstract: true,
            template: '<ui-view></ui-view>',
            ncyBreadcrumb: {
                skip: true
                //label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}',
            }
        })
        .state('portail.file.list', {
            url: '',
            rights: { domain: 'portail', right: 'read' },
            template: '<portail-file-list></portail-file-list>',
            ncyBreadcrumb: {
                skip: true
                //label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}',
            }
        })
        .state('portail.file.add', {
            url: '/add',
            rights: { domain: 'portail', right: 'create' },
            template: '<portail-file-add></portail-file-add>',
            ncyBreadcrumb: {
                skip: true,
                label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}'
            },
            search: 'PORTAIL.BREADCRUMBS.USERS_NEW'
        })
        .state('portail.file.edit', {
            url: '/:id/edit',
            rights: { domain: 'portail', right: 'update' },
            template: '<portail-file-add></portail-file-add>',
            params: {
                obj: null
            },
            ncyBreadcrumb: {
                skip: true,
                label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}'
            },
            search: 'PORTAIL.BREADCRUMBS.USERS_NEW'
        });

    /* $stateProvider.state('portail.user', {
        url: '/utilisateur',
        template: '<zone-abattages></zone-abattages>',
        rights: { domain: 'portail.user', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}',
        },
        // la liste des zone-abattages fait partie des points d'entrée recherchables dans la navigation
        search: 'PORTAIL.BREADCRUMBS.PORTAIL',
    }); */
}

FileRoute.$inject = ['$stateProvider'];
