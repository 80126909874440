MotifRefusAdmissionController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'MotifRefusAdmissionService',
	'notification'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function MotifRefusAdmissionController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	MotifRefusAdmissionService,
	notification
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	let selectedMotifRefusAdmissionId = undefined;

	vm.loading = false;
	vm.stateParams = $stateParams;
	vm.motifRefusAdmission = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.isMotifRefusAdmissionSelected = isMotifRefusAdmissionSelected;
	vm.loadMotifRefusAdmission = loadMotifRefusAdmission;
	vm.deleteMotifRefusAdmission = deleteMotifRefusAdmission;

	function init() {
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "motifs.list", on désélectionne la ligne sélectionnée (s'il y en a)
		watchers.push($scope.$on('$stateChangeSuccess', stateChangeSuccessFunc));
		watchers.push($scope.$on('$destroy', dispose));
	}

	init();

	function stateChangeSuccessFunc(event, toState) {
		if (toState.name === 'motif-refus-admission.list') {
			selectedMotifRefusAdmissionId = undefined;
		}
	}

	function isMotifRefusAdmissionSelected(motifRefusAdmission) {
		return motifRefusAdmission && motifRefusAdmission.id && selectedMotifRefusAdmissionId === motifRefusAdmission.id;
	}

	async function loadMotifRefusAdmission(tableState) {
		startLoading();

		//quand on rafraîchit la grille, on revient sur "motifs.list", et on ferme la vue détail d'un motif
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== 'motif-refus-admission.list') {
			$state.go('motif-refus-admission.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.motifRefusAdmission = [];

		try {
			const data = await MotifRefusAdmissionService.getMotifRefusAdmission(filters, sorts, pagination);
			vm.motifRefusAdmission = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteMotifRefusAdmission(motifRefusAdmission) {
		if (motifRefusAdmission && motifRefusAdmission.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('MOTIFS_REFUS_ADMISSION.DELETE.TITLE', { libelle: motifRefusAdmission.libelle }),
				modalMsg: $translate.instant('MOTIFS_REFUS_ADMISSION.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await MotifRefusAdmissionService.deleteMotifRefusAdmissionById(motifRefusAdmission.id);
					notification.success($translate.instant('MOTIFS_REFUS_ADMISSION.DELETE.SUCCESS'));

					selectedMotifRefusAdmissionId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
					if (vm.stateParams.id) {
						$state.go('motif-refus-admission.list');
					}

					loadMotifRefusAdmission();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
