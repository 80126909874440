export default class ImportBlFichierPopupController {
    static $inject = ['$scope', '$stateParams', 'notification', 'ModalService', '$uibModal', 'Upload', 'ImportBlService'];

    constructor($scope, $stateParams, notification, ModalService, $uibModal, Upload, ImportBlService) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
        this.Upload = Upload;
        this.ImportBlService = ImportBlService;
    }

    async $onInit() {
        this.data = {
            file: {},
            importBl: this.importBl
        };
        this.rapport = '';
        this.rapportCreate = '';
        this.isFileSelected = false;
        this.buttonValideForCreate = false;
    }

    annuler() {
        this.modalInstance.close();
    }

    openFileUploader() {
        document.getElementById('file-upload').click();
    }

    async change($files) {
        this.data = {
            importBl: this.importBl,
            file: $files[0]
        };
        this.loading = false;
        this.isFileSelected = true;
    }
    // afficher le rapport de traitement du fichier
    async getRapport() {
        try {
            this.loading = true;
            if (this.data) {
                var rapport = await this.ImportBlService.upload(this.data);
            }
            //affichage du rapport en list dans textarea
            const indexRapport = rapport.length;
            for (let i = 0; i < indexRapport - 1; i++) {
                if (rapport[i].length > 0) {
                    const str = 'Ligne [' + (i + 2) + '] \n';
                    this.rapport += str;
                    rapport[i].forEach((e) => {
                        this.rapport += e;
                    });
                }
            }

            this.rapport += '\n' + rapport[indexRapport - 1][0]; // derni�re ligne du rapport de control
            if (rapport[indexRapport - 1][0].includes('VALIDER')) {
                this.buttonValideForCreate = true;
            }
            this.loading = false;
        } catch (error) {
            this.notification.error('TICKETS.IMPORT_BL.ERROR.FICHIER');
            this.loading = false;
        }
    }

    // afficher le rapport de l'import des BLS
    async createBLs() {
        this.loading = true;

        if (this.data) {
            const rapportCreateFromDatabase = await this.ImportBlService.createBLsImport(this.data);

            //affichage du rapport en list dans textarea
            const indexRapport = rapportCreateFromDatabase.length;
            for (let i = 0; i < indexRapport - 1; i++) {
                if (rapportCreateFromDatabase[i] != '') {
                    const str = 'Ligne [' + (i + 2) + '] \n';
                    this.rapportCreate += str;
                    rapportCreateFromDatabase[i].forEach((e) => {
                        this.rapportCreate += e;
                    });
                }
            }
            this.rapportCreate += '\n' + rapportCreateFromDatabase[indexRapport - 1][0];
        }
        this.loading = false;
        this.rapport = '';
    }
}
