import RegistreConfig from './_config/registres.routes';
import RegistresRoutes from './_config/registres.config';

import RndtsApiCallbackController from './components/rndts-api-callback';
import RegistresComponent from './components/registres';
import { RegistresService } from './services/registres.service';

const moduleName = 'app.massia.gestion.registre.eco.organismes';

angular
	.module(moduleName, [])
	.config(RegistreConfig)
	.config(RegistresRoutes)
	.service('registresService', RegistresService)
	.component('registres', RegistresComponent)
	.component('rndtsApiCallback', RndtsApiCallbackController);

export default moduleName;
