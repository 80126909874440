import { Injectable } from '../../../../core/decorators';
import * as angular from 'angular';

@Injectable('PropositionBestTrameRefService')
export class PropositionBestTrameRefService {
    /* @ngInject */
    constructor() {}

    public getBestTrame(trames: any, saisie: any) {
        const grandDiametre: number = angular.copy(saisie.produit.grandDiametre);
        let petitDiametre: number = angular.copy(saisie.produit.petitDiametre);
        const idType: number = angular.copy(saisie.type.id);
        const famille: any = angular.copy(saisie.famille);
        const idProducteur: number = angular.copy(saisie.producteur.id);
        // d’abord par rapport au produit et au producteur,
        // sinon par rapport au produit,
        // sinon par rapport au d/D du produit et au producteur,
        //console.log(trames);

        if (famille.code === 'BPE') {
            petitDiametre = null;
        }
        let tramesTemp: any = [];

        // sinon par rapport au d/D du produit
        if (grandDiametre !== null && petitDiametre !== null) {
            //console.log('Dd');
            tramesTemp = trames.filter((x: any) => x.grandDiametre === grandDiametre && x.petitDiametre === petitDiametre);
        } else if (grandDiametre !== null && petitDiametre === null) {
            //console.log('D');
            tramesTemp = trames.filter((x: any) => x.grandDiametre === grandDiametre);
        } else if (grandDiametre === null && petitDiametre !== null) {
            //console.log('d');
            tramesTemp = trames.filter((x: any) => x.petitDiametre === petitDiametre);
        }
        trames = tramesTemp.length > 0 ? tramesTemp : trames;
        //console.log(trames);

        // sinon par rapport au type de produit du produit et au producteur,
        if (idType !== null && idProducteur !== null) {
            tramesTemp = trames.filter((x: any) => {
                if (x.type && x.type.id && x.sitesProduction.length > 0) {
                    return !!(x.sitesProduction.find((y: any) => y.id === idProducteur) && x.type.id === idType);
                }
            });
        }
        trames = tramesTemp.length > 0 ? tramesTemp : trames;
        //console.log(trames);

        // sinon par rapport au type de produit,
        if (idType !== null) {
            tramesTemp = trames.filter((x: any) => {
                if (x.type && x.type.id) {
                    return x.type.id === idType;
                }
            });
        }
        trames = tramesTemp.length > 0 ? tramesTemp : trames;
        //console.log(trames);

        // sinon par rapport au producteur,
        if (idProducteur !== null) {
            tramesTemp = trames.filter((p: any) => {
                if (p.sitesProduction.length > 0) {
                    return !!p.sitesProduction.find((y: any) => y.id === idProducteur);
                }
            });
        }
        trames = tramesTemp.length > 0 ? tramesTemp : trames;
        //console.log(trames);
        return trames;
    }

    public getBestRef(refs: any, saisie: any) {
        const idProducteur: number = angular.copy(saisie.producteur.id);
        const idProduit: number = angular.copy(saisie.produit.id);
        const idClient: number = angular.copy(saisie.client.id);

        let refsTemp: any = [];
        // console.log(refs);

        // D'abord par rapport au client, au produit et au producteur
        if (idClient && idProducteur && idProduit) {
            // console.log('client + product + produit', idClient, idProducteur, idProduit);
            refsTemp = refs.filter((x: any) => {
                // console.log('xxxx', x);
                if (x.sitesClients.length > 0 && x.sitesProduction.length > 0 && x.produits.length > 0) {
                    return (
                        x.sitesClients.find((client: { id: number }) => client.id === idClient) &&
                        x.sitesProduction.find((producteur: { id: number }) => producteur.id === idProducteur) &&
                        x.produits.find((produit: { id: number }) => produit.id === idProduit)
                    );
                }
            });
        }
        // console.log(refsTemp);
        refs = refsTemp.length > 0 ? refsTemp : refs;
        // console.log(refs);

        // sinon par rapport au produit et au producteur,
        if (idProducteur && idProduit) {
            // console.log('product + produit', idProducteur, idProduit);
            refsTemp = refs.filter((x: any) => {
                if (x.sitesProduction.length > 0 && x.produits.length > 0) {
                    return (
                        x.sitesProduction.find((producteur: { id: number }) => producteur.id === idProducteur) &&
                        x.produits.find((produit: { id: number }) => produit.id === idProduit)
                    );
                }
            });
        }
        refs = refsTemp.length > 0 ? refsTemp : refs;
        // console.log(refs);

        // sinon par rapport au produit,
        if (idProduit) {
            // console.log('produit', idProduit);
            refsTemp = refs.filter((x: any) => {
                if (x.produits.length > 0) {
                    return x.produits.find((produit: { id: number }) => produit.id === idProduit);
                }
            });
        }
        refs = refsTemp.length > 0 ? refsTemp : refs;
        // console.log(refs);

        // sinon par rapport au client et au producteur,
        if (idClient && idProducteur) {
            // console.log('client + product', idClient, idProducteur);
            refsTemp = refs.filter((x: any) => {
                if (x.sitesClients.length > 0 && x.sitesProduction.length > 0) {
                    return (
                        x.sitesClients.find((client: { id: number }) => client.id === idClient) &&
                        x.sitesProduction.find((producteur: { id: number }) => producteur.id === idProducteur)
                    );
                }
            });
        }
        refs = refsTemp.length > 0 ? refsTemp : refs;
        // console.log(refs);

        // sinon par rapport au producteur,
        if (idProducteur) {
            // console.log('product', idProducteur);
            refsTemp = refs.filter((x: any) => {
                if (x.sitesProduction.length > 0) {
                    return x.sitesProduction.find((producteur: { id: number }) => producteur.id === idProducteur);
                }
            });
        }
        refs = refsTemp.length > 0 ? refsTemp : refs;
        // console.log(refs);

        return refs;
    }
}

// export interface Saisie {
//     client: SitesClient;
//     famille: Famille;
//     produit: Produit;
//     producteur: SitesProduction;
//
// }
//
// export type Trame = {
//     grandDiametre: number;
//     petitDiametre: number;
//     produits: Produit;
//     sitesProduction: SitesProduction;
//     sitesClients: SitesClient;
//     type: Type;
// }
//
// export type Ref = {
//     produits: Produit;
//     sitesProduction: SitesProduction;
//     sitesClients: SitesClients;
// }
//
// export type Produit = {
//     type: Type;
//     grandDiametre: number;
//     petitDiametre: number;
//     id: number
// }
//
// export type SitesProduction = {
//     id: number
// }
//
// export type SitesClients = {
//     id: number
// }
//
// export type Famille = {
//     code: string;
//     id: number
// }
//
// export type Type = {
//     id: number
// }
