export default function Configure($stateProvider) {
    $stateProvider.state('stocks-preclotures', {
        parent: 'gestion',
        url: '/stocks-preclotures',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            skip: true,
            label: '{{ "LAYOUT.NAVIGATION.STOCK_CLOTURE" | translate }}'
        }
    });

    // $stateProvider.state('stocks-preclotures.jour', {
    //     url: '/cloture-jour',
    //     template: '<stock-cloture-jour></stock-cloture-jour>',
    //     rights: {domain: 'cloturejour', right: 'read'},
    //     ncyBreadcrumb: {
    //         skip: true,
    //         label: '{{ "STOCKS_CLOTURES.BREADCRUMBS.CLOTURE_JOURNALIERE" | translate}}'
    //     },
    //     // on affiche la liste des motifs dans la navigation
    //     navigation: {
    //         menu: 'stocks_cloture',
    //         translate: 'STOCKS_CLOTURES.BREADCRUMBS.CLOTURE_JOURNALIERE',
    //         order: 5
    //     },
    //     // la liste des motifs fait partie des points d'entrée recherchables dans la navigation
    //     search: 'STOCKS_CLOTURES.BREADCRUMBS.CLOTURE_JOURNALIERE'
    // });

    $stateProvider.state('stocks-preclotures.precloture-mois', {
        url: '/precloture-mois',
        template: '<stock-precloture-mois></stock-precloture-mois>',
        rights: { domain: 'stockprecloture', right: 'read' },
        ncyBreadcrumb: {
            skip: true,
            label: '{{ "STOCKS_CLOTURES.BREADCRUMBS.CLOTURE_MENSUELLE" | translate}}'
        },
        // on affiche la liste des motifs dans la navigation
        navigation: {
            menu: 'stocks_cloture',
            translate: 'STOCKS_CLOTURES.BREADCRUMBS.CLOTURE_MENSUELLE',
            order: 5
        },
        // la liste des motifs fait partie des points d'entrée recherchables dans la navigation
        search: 'STOCKS_CLOTURES.BREADCRUMBS.CLOTURE_MENSUELLE'
    });

    // $stateProvider.state('stocks-clotures.cloture-mois', {
    //     url: '/cloture-mois',
    //     template: '<stock-cloture-mois></stock-cloture-mois>',
    //     rights: {domain: 'stockcloture', right: 'read'},
    //     ncyBreadcrumb: {
    //         skip: true,
    //         label: '{{ "STOCKS_CLOTURES.BREADCRUMBS.CLOTURE_MENSUELLE" | translate}}'
    //     },
    //     // on affiche la liste des motifs dans la navigation
    //     navigation: {
    //         menu: 'stocks_cloture',
    //         translate: 'STOCKS_CLOTURES.BREADCRUMBS.CLOTURE_MENSUELLE',
    //         order: 5
    //     },
    //     // la liste des motifs fait partie des points d'entrée recherchables dans la navigation
    //     search: 'STOCKS_CLOTURES.BREADCRUMBS.CLOTURE_MENSUELLE'
    // });
}

Configure.$inject = ['$stateProvider'];
