OffreEnteteValidator.$inject = ['validator', 'moment'];

export default function OffreEnteteValidator(validator, moment) {
    const instance = new validator();

    instance.ruleFor('dateCreation').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('dateValidite').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('idType').greaterThan(0).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('etat').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('dateValidite').must(etreInferieureA).withMessage('DATE_VALIDATION_COMPARE_WARNING');
    instance.ruleFor('propositions').must(tvaNotNotEmpty).withMessage('DATE_VALIDATION_COMPARE_WARNING');

    function etreInferieureA(objetEntete, dateValidite) {
        // dateDemande <= dateValidite
        // dateValidite => dateDemande
        return true;
    }

    function tvaNotNotEmpty(objetEntete, propositions) {
        let result = true;
        if (Array.isArray(propositions) && propositions.length > 0) {
            propositions.forEach((element) => {
                // propositions contains producteurs (as array)
                if (Array.isArray(element.producteurs) && element.producteurs.length > 0) {
                    element.producteurs.forEach((prod) => {
                        // producteurs contains produits (as array) which contains 'tauxTVA' which need to has value
                        if (Array.isArray(prod.produits) && prod.produits.length > 0) {
                            if (prod.produits.findIndex((p) => p.tauxTVA === null || p.tauxTVA === undefined || p.tauxTVA === '') >= 0) {
                                result = false;
                            }
                        }
                    });
                }
            });
        }
        return result;
    }
    return instance;
}
