export class BonLivraison {
    public id: number;
    public date: Date;
    public produit: {
        id: number;
        code: string;
        code2: string;
        libelle: string;
        famille: string;
        idType: number;
        appellationCom: string;
    };

    public producteur: {
        id: number;
        code: string;
        libelle: string;
    };
    public client: {
        id: number;
        code: string;
        libelle: string;
    };
    public chantier: {
        id: number;
        code: string;
        libelle: string;
    };

    public ticket: {
        id: number;
        numero: number | string;
    };

    public ticketRMS: {
        id: number;
        cpid: string;
        numBLLong: string;
        volumeBL: number;
        zone: string;
        realMixDensity: number;
    };

    public gachees: Gachee[];
    public charge: {
        famille: string;
        composants: Composant[];
    }[];
    public composants: Composant[];
    public familles: string[];

    constructor(data: any = null) {
        if (data !== null) {
            this.id = data?.id;
            this.date = data?.date;
            this.client = {
                id: data?.client?.id,
                code: data?.client?.code,
                libelle: data?.client?.libelle
            };
            this.chantier = {
                id: data?.chantier?.id,
                code: data?.chantier?.code,
                libelle: data?.chantier?.libelle
            };
            this.producteur = {
                id: data?.site?.id,
                code: data?.site?.code,
                libelle: data?.site?.libelle
            };
            this.produit = {
                id: data?.produit?.id,
                code: data?.produit?.code,
                code2: data?.produit?.code2,
                libelle: data?.produit?.libelle,
                famille: data?.produit?.famille,
                idType: data?.produit?.idType,
                appellationCom: data?.produit?.appellationCom
            };
            this.ticket = {
                id: data?.ticket?.id,
                numero: data?.ticket?.numero
            };
            this.ticketRMS = data?.ticket?.tickRMS
                ? {
                      id: data.ticket.tickRMS.id,
                      cpid: data.ticket.tickRMS.cpid,
                      numBLLong: data.ticket.tickRMS.numBLLong,
                      volumeBL: data.ticket.tickRMS.volumeBL,
                      zone: data.ticket.tickRMS.zone,
                      realMixDensity: data.ticket.tickRMS.realMixDensity
                  }
                : null;

            this.setGachees(data.betGachee);
            this.setGacheeComp(data.betGacheeComplementaires);
            this.setCharge();
        }
    }

    public setGachees(data: any[]): void {
        this.gachees = [];
        this.charge = [];
        this.familles = [];
        if (!data || data.length === 0) {
            return;
        }

        const maxGachee = Math.max.apply(
            Math,
            data.map(x => x.numGachee as number)
        );

        for (let i = 1; i <= maxGachee; i++) {
            const gachComposants = data.filter(x => x.numGachee === i);
            const familles = gachComposants.map(x => x.produit.famille) as string[];
            this.familles = [...this.familles, ...familles];
            const gachee = new Gachee();
            gachee.mixTemps = gachComposants[0].mixTemp;
            gachee.numGachee = i;
            gachee.familles = [];
            gachee.allComposants = Array.from(gachComposants, c => new Composant(c));

            for (let j = 0; j < familles.length; j++) {
                const famille = familles[j];

                const idx = gachee.familles.findIndex(x => x.famille === famille);
                if (idx === -1) {
                    const item = {
                        famille: famille,
                        composants: Array.from(
                            gachComposants.filter(x => x.produit.famille === famille),
                            c => new Composant(c)
                        )
                    };

                    gachee.familles.push(item);
                }
            }

            this.gachees.push(gachee);
            this.charge = [...this.charge, ...gachee.familles];
        }

        this.familles = [...new Set(this.familles)];
    }

    public setCharge(): void {
        const formatted = [];
        this.composants = [];
        if (!this.charge || this.charge.length === 0) {
            return;
        }

        for (let i = 0; i < this.familles.length; i++) {
            const famille = this.familles[i];
            const item = {
                famille: famille,
                composants: [] as Composant[]
            };
            const composantsFamille = this.charge.filter(x => x.famille === famille).map(x => x.composants);
            const merged = [].concat.apply([], composantsFamille) as Composant[];
            for (let j = 0; j < merged.length; j++) {
                const composant = merged[j];

                const index = item.composants.findIndex(x => x.produit.id === composant.produit.id);
                if (index > -1) {
                    item.composants[index].poidsReel += composant.poidsReel;
                    item.composants[index].poidsTheo += composant.poidsTheo;
                    item.composants[index].volume += composant.volume;
                } else {
                    item.composants.push(new Composant(composant));
                }
            }

            this.composants = [...this.composants, ...item.composants];
            formatted.push(item);
        }

        this.charge = formatted;
    }

    private setGacheeComp(data: any[]): void {
        for (let i = 0; i < this.gachees.length; i++) {
            const element = this.gachees[i];

            const comp = data.find(x => x.numGachee == element.numGachee);

            if (comp) {
                this.gachees[i].complementaire = new GacheeComplementaire(comp);
            }
        }
    }
}

export class Gachee {
    public open: boolean = false;
    public numGachee: number;
    public mixTemps: number;
    public allComposants: Composant[];
    public complementaire: GacheeComplementaire;
    public familles: {
        famille: string;
        composants: Composant[];
    }[];
}

export class Composant {
    public id: number;
    public poidsReel: number;
    public poidsTheo: number;
    public volume: number;
    public w: number | string;
    public hygroMesuree: number;
    public produit: {
        id: number;
        code: string;
        code2: string;
        libelle: string;
        famille: string;
        idType: number;
    };

    constructor(data: any) {
        this.id = data?.id;
        this.poidsReel = data?.poidsReel;
        this.poidsTheo = data?.poidsTheo;
        this.volume = data?.volume;
        this.hygroMesuree = data?.hygroMesuree;
        this.produit = {
            id: data?.produit?.id,
            code: data?.produit?.code,
            code2: data?.produit?.code2,
            libelle: data?.produit?.libelle,
            famille: data?.produit?.famille,
            idType: data?.produit?.idType
        };
    }
}

export class GacheeComplementaire {
    public id: number;
    public numGachee: number;
    public date: Date;
    public volume: number;
    public corrWater: number;
    public sumWA24: number;
    public realMixDensity: number;
    public totWaterContent: number;
    public sumTotWater: number;
    public sumWaterCalc: number;
    public sumAggDryCalc: number;
    public sumCement: number;
    public sumAdditions: number;
    public sumAdditives: number;
    public totalMixingTime: number;
    public mixingPowerMW: number;
    public sumAdmixtures: number;
    public sumTotalMat: number;
    public sumESurC: number;
    public sumEGSurC: number;
    public sumESurLeq: number;

    constructor(data: any) {
        Object.assign(this, data);
        this.setTotalMateriaux();
        this.setESurC();
        this.setEGSurC();
    }

    setTotalMateriaux() {
        this.sumTotalMat = this.sumCement + this.sumAdditions + this.sumAdmixtures + this.sumAggDryCalc;
    }

    setESurC() {
        this.sumESurC = (this.sumTotWater + this.sumWaterCalc - this.sumWA24) / this.sumCement;
    }

    setEGSurC() {
        this.sumEGSurC = (this.sumTotWater + this.sumWaterCalc) / this.sumCement;
    }
}
