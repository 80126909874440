const serviceUrl = `${__configuration.apiUrl}/massia/doc-ce`;

export default class DocumentsCEService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getDocumentsCE(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async deleteDocumentCEById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getDocumentCEEnteteById(id) {
        const url = `${serviceUrl}/${id}/entete`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createDocumentCE(docce, valeurs) {
        const data = {
            //code: docce.entete.code,
            libelle: docce.entete.libelle,
            idProducteur: docce.entete.idProducteur,
            idProduit: docce.entete.idProduit,
            notes: docce.entete.notes,
            date: docce.entete.date,
            idImage: docce.entete.idLogo,
            normesIds: docce.entete.normesIds,
            certif: docce.caracteristique.certif,
            reference: docce.entete.reference,
            dateApposition: docce.entete.dateApposition,
            valeurs: valeurs,
            numeroCertificat: docce.entete.numeroCertificat
        };
        const result = await this.$http.post(serviceUrl, data);
        return result.data;
    }

    async updateDocumentCE(docce, valeurs) {
        const data = {
            libelle: docce.entete.libelle,
            idProducteur: docce.entete.idProducteur,
            idProduit: docce.entete.idProduit,
            idCertificat: docce.entete.idCertificat,
            notes: docce.entete.notes,
            date: docce.entete.date,
            normesIds: docce.entete.normesIds,
            idImage: docce.entete.idLogo,
            certif: docce.caracteristique.certif,
            reference: docce.entete.reference,
            dateApposition: docce.entete.dateApposition,
            valeurs: valeurs,
            numeroCertificat: docce.entete.numeroCertificat
        };
        const url = `${serviceUrl}/${docce.id}`;
        return await this.$http.put(url, data);
    }

    /*async codeExists(code) {
        let url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }*/

    async getNormes() {
        const normeUrl = `${__configuration.apiUrl}/massia/normes/headers-ce`;
        const res = await this.$http.get(normeUrl);
        return res.data;
    }

    async getProducteurs(application) {
        const siteUrl = `${__configuration.apiUrl}/massia/sites-producteurs/headers/${application}`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async getProduits(idProducteur) {
        const siteUrl = `${__configuration.apiUrl}/massia/produits/produitsProducteur/${idProducteur}`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async getProduitById(idProduit) {
        const siteUrl = `${__configuration.apiUrl}/massia/produits/doc-ce-product/${idProduit}`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async getEssaiByDocCEId(idDoc, idNorme) {
        const siteUrl = `${__configuration.apiUrl}/massia/doc-ce/essai/${idDoc}/${idNorme}`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async print(id, idNorme, filename, isNormeParPage) {
        const url = isNormeParPage ? `${serviceUrl}/print` : `${serviceUrl}/print-tableau`;
        /*let data = {
            templateName : info.libelle + info.extension,
            outputName : Date.now() + info.extension
        }*/
        const res = await this.$http.post(url, undefined, {
            params: {
                idDocCE: id,
                idNorme: idNorme,
                filename: filename
            }
        });
        return res.data ? res.data : null;
    }

    async getCategoriesNorme(idNorme) {
        const normeCategUrl = `${__configuration.apiUrl}/massia/normes/${idNorme}/categories`;
        const res = await this.$http.get(normeCategUrl);
        return res.data;
    }

    async getCertificats(idProduit, idProducteur) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/laboratoire/certificat-produit`, {
            params: {
                producteur: idProducteur,
                produit: idProduit
            }
        });
        return res.data;
    }
}
