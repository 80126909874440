import HistoriqueConfig from './_config/historique.config';
import HistoriqueRoutes from './_config/historique.routes';
import HistoriqueComponent from './components/historique';
import HistoriqueComposantComponent from './components/historique-composant';
import HistoriqueService from './services/historique.service';
import HistoriqueCommunicationService from './services/historique.communication.service';

const moduleName = 'app.massia.common.historique';

angular
    .module(moduleName, [])
    .config(HistoriqueConfig)
    .config(HistoriqueRoutes)
    .service('HistoriqueService', HistoriqueService)
    .service('HistoriqueCommunicationService', HistoriqueCommunicationService)
    .component('historique', HistoriqueComponent)
    .component('historiqueComposant', HistoriqueComposantComponent);

export default moduleName;
