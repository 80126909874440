import validatorBuilder from 'validator-builder';
import Import3rParam from './import.3r.param.model';

let form3rValidator = null;

export default class Import3rForm {
    constructor(data) {
        data = data || {};
        this.id = data.id || null;
        this.libelle = data.libelle || null;
        this.isActive = data.isActive || false;
        this.haveIterateOrder = typeof data.haveIterateOrder !== 'undefined' ? data.haveIterateOrder : true;
        this.laboratoire = data.laboratoire || null;
        this.machineType = data.machineType || 1;
        this.essaisList = data.essaisList || [
            {
                id: null,
                libelle: '',
                essaiIdentifieur: '',
                sousEssai: null,
                essaisList: [],
                essais: []
            }
        ];
        this.essaisList = this.essaisList.map((x) => new Import3rParam(x));
        this.caractList = data.caractList || [
            {
                id: null,
                libelle: '',
                essaiIdentifieur: '',
                caracteristique: null,
                caractList: [],
                caractSelect: []
            }
        ];
        this.caractList = this.caractList.map((x) => new Import3rParam(x));
    }

    isValid() {
        form3rValidator = form3rValidator || validatorBuilder.getInstanceFor('Import3rForm');
        const validationResults = form3rValidator.validate(this);
        return validationResults.isValid;
    }
}
