export default class SyntheseEntitiesAffichageController {
    constructor(
        $scope,
        $state,
        $stateParams,
        $translate,
        notification,
        moment,
        $uibModal,
        MassiaApplicationService,
        SyntheseEntitiesService,
        SyntheseEntitiesCommunicationService,
        SyntheseModelesOldService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.notification = notification;
        this.moment = moment;
        this.$uibModal = $uibModal;

        this.MassiaApplicationService = MassiaApplicationService;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
        this.SyntheseModelesService = SyntheseModelesOldService;
    }

    async $onInit() {
        this.dragOptions = {
            placeholder: 'table-row-ui-placeholder',
            revert: true,
            animation: 200,
            axis: 'y',
            'ui-preserve-size': true
        };

        this.activite = this.MassiaApplicationService.getApplication();

        this.startLoading();
        await this.getTrames();
        await this.loadCriteresAffichage();
        this.stopLoading();
    }

    $onDestroy() {}

    async getTrames() {
        const typesSyntheses = await this.getTypeSyntheses();

        let codeDomaine = null;
        if (typesSyntheses) {
            const typeSynthese = typesSyntheses.find((e) => {
                return e.id === this.syntheseEntity.typeDeSynthese;
            });
            if (typeSynthese) {
                codeDomaine = typeSynthese.domaine;
            }
        }

        await this.extractIdsProduitProducteur();

        try {
            this.trames = await this.SyntheseEntitiesService.getTramesForSynthese(codeDomaine, this.listproducteurids);
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async getTypeSyntheses() {
        let listeTypesSynthese = [];
        try {
            const typeSyntheses = await this.SyntheseEntitiesService.getTypeSyntheses();
            listeTypesSynthese = typeSyntheses.filter((e) => {
                return e.activite === this.activite;
            });
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            }
        }

        return listeTypesSynthese;
    }

    async loadCriteresAffichage() {
        try {
            if (!this.activite) {
                throw new Error('Application not found');
            }
            const result = await this.SyntheseModelesService.getCriteresISteven(this.activite, 'criteres');

            for (let i = 0; i < result.length; i++) {
                const res = result[i];
                if (!res.msGroup && res.libelle) {
                    delete res.msGroup;
                }
            }

            for (let i = 0; i < result.length; i++) {
                result[i].ticked = this.tickSelectedCritAffTra(result[i], 'aff');
            }

            this.listIsteven = result;
        } catch (err) {
            this.notification.error(err.data);
        }
    }

    // extraire les ids des produits et des producteurs de la liste des critères pour recharger la liste des références
    extractIdsProduitProducteur() {
        this.listproduitids = [];
        this.listproducteurids = [];
        if (
            this.syntheseEntity &&
            this.syntheseEntity.listeSyntheseCritereValeurBlocks &&
            this.syntheseEntity.listeSyntheseCritereValeurBlocks.length > 0
        ) {
            for (let index = 0; index < this.syntheseEntity.listeSyntheseCritereValeurBlocks.length; index++) {
                const block = this.syntheseEntity.listeSyntheseCritereValeurBlocks[index];

                if (block.listeSyntheseCritereValeurLignes && block.listeSyntheseCritereValeurLignes.length > 0) {
                    for (let j = 0; j < block.listeSyntheseCritereValeurLignes.length; j++) {
                        const ligne = block.listeSyntheseCritereValeurLignes[j];

                        // si le critère est un type de champ IdTable ET que ce n'est pas une caractéristique
                        if (ligne.typeDeChamp === 7 && ligne.champ !== 3) {
                            // si c'est un critère produit
                            if (ligne.domaineCode === 'Produits') {
                                if (ligne.selectedEltsTable && ligne.selectedEltsTable.length > 0) {
                                    const tempListIds1 = ligne.selectedEltsTable.map((a) => a.id);
                                    this.listproduitids = this.listproduitids.concat(tempListIds1);
                                }
                            }

                            // si c'est un critère producteur
                            if (ligne.domaineCode === 'SiteProd') {
                                if (ligne.selectedEltsTable && ligne.selectedEltsTable.length > 0) {
                                    const tempListIds2 = ligne.selectedEltsTable.map((a) => a.id);
                                    this.listproducteurids = this.listproducteurids.concat(tempListIds2);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    tickSelectedCritAffTra(line, whatList) {
        let toCheck = false;
        if (!line.champCompo) {
            line.champCompo = null;
        }
        if (whatList === 'aff') {
            const index = this.syntheseEntity.listeSyntheseCritereAffichage.findIndex(function (element) {
                if (
                    element.idDonneeSelectionnable === line.idDonneeSelectionnable &&
                    element.idCaracteristique === line.idCaracteristique &&
                    element.idType === line.idType &&
                    element.champ === line.champ &&
                    element.champCompo === line.champCompo
                ) {
                    return true;
                }
                return false;
            });
            if (index > -1) {
                toCheck = true;
            }
        } else if (whatList === 'tra') {
            if (
                this.syntheseEntity.listeSyntheseCritereTraitement.findIndex((e) => {
                    return e.idCalcStat === line.idCalcStat;
                }) >= 0
            ) {
                toCheck = true;
            }
        }
        return toCheck;
    }

    updateCritereAffichage(listFromBDD, listOriginal, whatList) {
        for (let i = 0; i < listOriginal.length; i++) {
            const crit = listOriginal[i];

            if (crit.msGroup === true || crit.msGroup === false) {
                continue;
            }

            let index = -1;
            if (this.syntheseEntity && listFromBDD && listFromBDD.length > 0) {
                if (whatList === 'aff') {
                    index = listFromBDD.findIndex(function (x) {
                        if (
                            x.idDonneeSelectionnable === crit.idDonneeSelectionnable &&
                            x.idCaracteristique === crit.idCaracteristique &&
                            x.idType === crit.idType &&
                            x.champ === crit.champ &&
                            x.champCompo === crit.champCompo
                        ) {
                            return true;
                        }
                        return false;
                    });
                } else if (whatList === 'tra') {
                    index = listFromBDD.findIndex(function (x) {
                        if (x.idCalcStat === crit.idCalcStat) {
                            return true;
                        }
                        return false;
                    });
                }
            }
            if (index === -1) {
                // s'il n'existe pas encore
                if (crit.ticked) {
                    // cochée
                    this.addCritereAffTra(crit, whatList, listFromBDD);
                } else if (!crit.ticked) {
                    // décochée
                    // ne rien faire
                }
            } else {
                // s'il existe déjà
                if (crit.ticked) {
                    // cochée
                    // ne rien faire
                } else if (!crit.ticked) {
                    // décochée
                    this.deleteCritereAffichage(crit, whatList, listFromBDD);
                }
            }
        }
    }

    // pour ajouter un critère dans la liste avec ses valeurs par défaut
    addCritereAffTra(crit, whatList, listToAdd) {
        const lineToAdd = {};
        lineToAdd.id = -1;
        if (whatList === 'aff') {
            lineToAdd.position = this.syntheseEntity.listeSyntheseCritereAffichage.length + 1;
            lineToAdd.idSynthCritAffichage = crit.idSynthCritAffichage;
            lineToAdd.idDonneeSelectionnable = crit.idDonneeSelectionnable;
            lineToAdd.champ = crit.champ;
            lineToAdd.champCompo = crit.champCompo;
            lineToAdd.idCaracteristique = crit.idCaracteristique;
            lineToAdd.titre = crit.titre;
            lineToAdd.ordreRupture = null;
            lineToAdd.idType = crit.idType;
            lineToAdd.codeDomaine = crit.codeDomaine;
            lineToAdd.libelleTotalChamp = crit.libelleDomaine + ' / ' + crit.libelleChamp; // valeur par défaut
            if (crit.idCaracteristique) {
                /*if (crit.libelleCaracteristique) {
                    lineToAdd.libelleTotalChamp = lineToAdd.libelleTotalChamp + " / " + crit.libelleCaracteristique;
                } else {
                    lineToAdd.libelleTotalChamp = lineToAdd.libelleTotalChamp + " / " + crit.libelleDonneeSelectionnable;
                }*/
                lineToAdd.libelleTotalChamp = crit.libelleDonneeSelectionnable;
            }
            lineToAdd.titre = lineToAdd.libelleTotalChamp; // valeur par défaut
            listToAdd.push(lineToAdd);
        } else if (whatList === 'tra') {
            lineToAdd.position = this.syntheseEntity.listeSyntheseCritereTraitement.length + 1;
            lineToAdd.idSynthCritTraitement = crit.idSynthCritTraitement;
            lineToAdd.idCalcStat = crit.idCalcStat;
            lineToAdd.libelle = crit.libelle;
            lineToAdd.code = crit.code;
            lineToAdd.formule = crit.formule;
            lineToAdd.formule2 = crit.formule2;
            lineToAdd.couleurFond = { hue: 0, saturation: 50, luminosity: 100 };
            lineToAdd.couleurTexte = { hue: 0, saturation: 50, luminosity: 0 };
            lineToAdd.isImprimable = true;
            listToAdd.push(lineToAdd);
        }
    }

    deleteCritereAffichage(line, whatList, listToDelete) {
        if (whatList === 'aff') {
            const idxDel = listToDelete.findIndex((x) => {
                return (
                    x.idDonneeSelectionnable === line.idDonneeSelectionnable &&
                    x.idCaracteristique === line.idCaracteristique &&
                    x.idType === line.idType &&
                    x.champ === line.champ &&
                    x.champCompo === line.champCompo
                );
            });

            listToDelete.splice(idxDel, 1);
        } else if (whatList === 'tra') {
            const idxDel = listToDelete.findIndex((x) => {
                return x.idCalcStat === line.idCalcStat;
            });

            listToDelete.splice(idxDel, 1);
        }
    }

    deleteCritere(elt) {
        const crit = this.syntheseEntity.listeSyntheseCritereAffichage.indexOf(elt);
        this.syntheseEntity.listeSyntheseCritereAffichage.splice(crit, 1);

        const traitement = this.listIsteven.find((e) => {
            return (
                e.idDonneeSelectionnable === elt.idDonneeSelectionnable &&
                e.idCaracteristique === elt.idCaracteristique &&
                e.idType === elt.idType &&
                e.champ === elt.champ &&
                e.champCompo === elt.champCompo
            );
        });
        traitement.ticked = false;
    }

    supprimerTousCriteres() {
        this.syntheseEntity.listeSyntheseCritereAffichage = [];

        this.listIsteven.map((e) => {
            delete e.ticked;
            return e;
        });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}

SyntheseEntitiesAffichageController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'notification',
    'moment',
    '$uibModal',
    'MassiaApplicationService',
    'SyntheseEntitiesService',
    'SyntheseEntitiesCommunicationService',
    'SyntheseModelesOldService'
];
