const serviceUrl = `${__configuration.apiUrl}/massia/parametres`;

export default class CentreGestionService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getParametres(domaine, id) {
        // let url = `mock/centre-de-gestion/nouveau.json`;
        const url = `${__configuration.apiUrl}/massia/parametres/domaine/${domaine}/${id}`;
        // let url = `${__configuration.apiUrl}/massia/parametres/domaine/SiteClient/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }
    async getParametersWithFilter(domaine, id, filtres) {
        const monFiltre = JSON.stringify(filtres);
        const url = `${__configuration.apiUrl}/massia/parametres/domaine/${domaine}/${id}?filters=${monFiltre}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    // http://localhost:53432/api/massia/parametres/domaine/${domaine}/${idClient}
    // http://localhost:53432/api/massia/parametres/domaine/SiteClient/${idClient}

    // Utile pour client + societe ou client + site (pour exemple)
    // http://localhost:53432/api/massia/parametres/domaine/SiteClient/46?filters={"societeId": 12}
    // http://localhost:53432/api/massia/parametres/domaine/SiteClient/46?filters={"societeId": 12}
    // http://localhost:53432/api/massia/parametres/domaine/SiteClient/46?filters={"siteCommercialId": 12}

    //#region Listes
    /********************
     * Listes
     ********************/
    async getSites() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites/header`);
        return res.data;
    }
    async getTransports() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/prestations/headers/transport`);
        return res.data;
    }
    async getTypesBenne() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/gestion/type-bennes/headers`);
        return res.data;
    }
    async getModesPaiement() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/paiements/headers`);
        return res.data;
    }
    async getModeleImpressionTicket() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/gestion/modeles-impression-ticket/header`);
        return res.data;
    }
    async getModeleImpressionBadge() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/gestion/modeles-impression-ticket/header`);
        return res.data;
    }
    async getModesTarage() {
        const res = await this.$http.get('assets/enum/mode-tarage/mode.tarage.json');
        return res.data;
    }
    async getControleCreationDap() {
        const res = await this.$http.get('assets/enum/controle-creation-dap/controle.creation.dap.json');
        return res.data;
    }
    async getTvas() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/tvas/headers`);
        return res.data;
    }
    async getProducteurs() {
        const application = 'all'; //SP 05/10/18 pour laisser non filtré sur états compte
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-producteurs/headers/${application}`);
        return res.data;
    }
    async getListePourDomaine(domaine, domaineEntree) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/affichages/headers/${domaine}/${domaineEntree}`);
        return res.data;
    }

    // #region pour tous les articles dans la liste des Taux de TVA niveau Chantier + articles liés
    async getProduitsSelectionnables(filtres) {
        const monFiltre = JSON.stringify(filtres);
        const url = `${__configuration.apiUrl}/massia/parametres/produitsDisponibles?filters=${monFiltre}`;
        const res = await this.$http.get(url);
        return res.data;
    }
    async getPrestationsSelectionnables(filtres) {
        const monFiltre = JSON.stringify(filtres);
        const url = `${__configuration.apiUrl}/massia/parametres-prestations-disponibles?filters=${monFiltre}`;
        const res = await this.$http.get(url);
        return res.data;
    }
    async getTransportsSelectionnables(filtres) {
        const monFiltre = JSON.stringify(filtres);
        const url = `${__configuration.apiUrl}/massia/parametres-transports-disponibles?filters=${monFiltre}`;
        const res = await this.$http.get(url);
        return res.data;
    }
    // #endregion

    // #region pour tous les articles dans la liste des Taux de TVA niveau Pays
    async getAllProduits() {
        const url = `${__configuration.apiUrl}/massia/produits/allHeader`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getAllPrestations() {
        const url = `${__configuration.apiUrl}/massia/prestations/forCdg`;
        const res = await this.$http.get(url);
        return res.data;
    }
    async getAllTransports() {
        const url = `${__configuration.apiUrl}/massia/transports`;
        const res = await this.$http.get(url);
        return res.data;
    }
    // #endregion

    //#endregion

    //#region Filtres
    /********************
     *  filtres
     *******************/
    async getSocieteEntete(id) {
        const application = 'gestion';
        const url = `${__configuration.apiUrl}/massia/societes/${id}/entete/${application}`;
        const res = await this.$http.get(url);
        return res.data;
    }
    async getSocietes(val) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/societes-commerciales/typeAhead/${val}`);
        return res.data;
    }
    async getTypesClient() {
        const domaine = 'SiteClient';
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/domaines/${domaine}/types`);
        return res.data;
    }
    async getSiteCom(val, idSociete) {
        if (idSociete != null) {
            const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-commerciaux/typeAhead/${idSociete}/${val}`);
            return res.data;
        }
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-commerciaux/typeAhead/${val}`);
        return res.data;
    }
    async getTransporteurs(val) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-transporteurs/typeAhead/${val}`);
        return res.data;
    }
    async getSitesClients(val, idSociete) {
        if (idSociete != null) {
            const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-clients/typeAhead/${idSociete}/${val}`);
            return res.data;
        } else {
            const idUtilisateur = 0;
            const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-clients/typeAhead/${val}/utilisateur/${idUtilisateur}`);
            return res.data;
        }
        return res.data;
    }
    async getChantiers(val) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/chantiers/headers/${val}`);
        return res.data;
    }

    async getTypesSociete(domaine) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/types/${domaine}/headers`);
        return res.data;
    }
    //#endregion

    //#region Actions
    /********************
     *  Actions
     *******************/
    async update(element) {
        const url = `${__configuration.apiUrl}/massia/parametres/valeur/${element.id}`;
        const res = await this.$http.put(url, element);
        return new Promise((resolve) => resolve(res.data));
    }
    async insert(element) {
        const url = `${__configuration.apiUrl}/massia/parametres/valeur`;
        const res = await this.$http.post(url, element);
        return new Promise((resolve) => resolve(res.data));
    }
    async effacer(id) {
        const url = `${__configuration.apiUrl}/massia/parametres/valeur/${id}`;
        const res = await this.$http.delete(url);
        return new Promise((resolve) => resolve(res.data));
    }
    async deleteMultiple(listeIdsToDelete) {
        const url = `${__configuration.apiUrl}/massia/parametres/delete`;
        const res = await this.$http.put(url, listeIdsToDelete);
        return new Promise((resolve) => resolve(res.data));
    }
    async insertMultiple(listElements) {
        const url = `${__configuration.apiUrl}/massia/parametres/multi-valeurs`;
        const res = await this.$http.post(url, listElements);
        return new Promise((resolve) => resolve(res.data));
    }
    //#endregion

    async getProduitsLibelle(filters) {
        const monFiltre = JSON.stringify(filters);
        const uniteUrl = `${__configuration.apiUrl}/massia/parametres/produits/libelle?filters=${monFiltre}`;
        const res = await this.$http.get(uniteUrl);

        return res.data;
    }

    async getTransportsLibelle(filters) {
        const monFiltre = JSON.stringify(filters);
        const uniteUrl = `${__configuration.apiUrl}/massia/parametres/transports/libelle?filters=${monFiltre}`;
        const res = await this.$http.get(uniteUrl);

        return res.data;
    }

    async getExportParametres() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/export-parametres/headers`);
        return res.data;
    }

    async printExportParametres(idParametres) {
        const url = `${__configuration.apiUrl}/massia/export-parametres/print`;
        const filtres = {
            idParametres: idParametres
        };
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {})
            },
            responseType: 'arraybuffer'
        });
        return res;
    }
}
