const serviceUrl = `${__configuration.apiUrl}/massia/carnets`;

export default class CarnetsService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getCarnets(filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async getCarnetById(id) {
		const url = `${serviceUrl}/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getPrestations(idSociete, idSiteCommercial, idClient, idChantier, prestationType, modeDeclenchement) {
		const url = `${serviceUrl}/headers`;

		const filtres = {
			idSociete: idSociete || null,
			idSiteCommercial: idSiteCommercial || null,
			idClient: idClient || null,
			idChantier: idChantier || null,
			prestationType: prestationType,
			modeDeclenchement: modeDeclenchement || null
		};

		const res = await this.$http.get(url, {
			params: {
				filtres: JSON.stringify(filtres || {})
			}
		});

		return res.data;
	}

	async getSitesCommerciaux(idSociete) {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-commerciaux/header/${idSociete}`;
		const res = await this.$http.get(siteUrl);

		return res.data;
	}

	async getBennes() {
		const siteUrl = `${__configuration.apiUrl}/massia/gestion/type-bennes/headers`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getSocietes() {
		const siteUrl = `${__configuration.apiUrl}/massia/societes-commerciales/header`;
		const res = await this.$http.get(siteUrl);

		return res.data;
	}

	async getClients() {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-clients/headers`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getChantiers(idSite) {
		const dateFacture = null;
		const siteUrl = `${__configuration.apiUrl}/massia/chantiers/header/${idSite}/${dateFacture}`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getFiltreSitesCommerciaux(idSociete) {
		let listSocieteIds = [];
		if (idSociete) {
			listSocieteIds = [idSociete];
		}
		const application = 'gestion';
		const url = `${__configuration.apiUrl}/massia/filtres-utilisateur/user-filter/${application}`;
		const res = await this.$http.get(url, {
			params: {
				listeIdSocietes: JSON.stringify(listSocieteIds)
			}
		});
		return res.data;
	}

	async createCarnet(carnet, withFacture, idModePaiement) {
		const url = `${serviceUrl}/${withFacture}/${idModePaiement}`;
		const result = await this.$http.post(url, carnet);
		return result.data;
	}

	async updateCarnet(carnet, withFacture) {
		const url = `${serviceUrl}/${carnet.id}/${withFacture}`;
		const result = this.$http.put(url, carnet);
		return result;
	}

	async deleteCarnetById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async disabledBons(id, idCarnet) {
		const url = `${serviceUrl}/disabled-bons/${id}`;
		return await this.$http.put(url, idCarnet);
	}

	async enabledBons(id, idCarnet) {
		const url = `${serviceUrl}/enabled-bons/${id}`;
		return await this.$http.put(url, idCarnet);
	}

	async getPSArticle(idArticle, typeArticle, idSociete, idSiteCommercial, idClient, idChantier, idProducteur) {
		const url = `${__configuration.apiUrl}/massia/grillesTarifaires/pixArticle`;
		const res = await this.$http.get(url, {
			params: {
				idArticle: JSON.stringify(idArticle || null),
				typeArticle: JSON.stringify(typeArticle),
				idSociete: JSON.stringify(idSociete || null),
				idSiteCommercial: JSON.stringify(idSiteCommercial || null),
				idClient: JSON.stringify(idClient || null),
				idChantier: JSON.stringify(idChantier || null),
				idProducteur: JSON.stringify(idProducteur || null),
				idTypeBenne: JSON.stringify(null),
				idZone: JSON.stringify(null)
			}
		});
		return res.data;
	}

	async print(id, fileName) {
		const url = `${serviceUrl}/print/${id}`;
		const res = await this.$http.get(url, {
			params: {
				fileName: JSON.stringify(fileName || '')
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async updateCarnetPerime() {
		const url = `${serviceUrl}/perime`;
		return this.$http.put(url);
	}

	async updateCarnetDateFin(id, dateFin) {
		const url = `${serviceUrl}/changeDateFin/${id}`;
		const data = {
			date: dateFin
		};
		return this.$http.put(url, data);
	}

	async genererAvoir(id) {
		const url = `${serviceUrl}/avoir/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getModesPaiement() {
		const serviceUrl = `${__configuration.apiUrl}/massia/paiements/headers`;
		const res = await this.$http.get(serviceUrl);
		return res.data;
	}
}
