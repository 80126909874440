import validatorBuilder from 'validator-builder';

let carnetValidator = null;

export default class Carnet {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code || 'PROSPECTS.AUTOMATIQUE';
        this.societeId = data.societeId;
        this.societe = data.societe;
        this.siteId = data.siteId;
        this.site = data.site;
        this.clientId = data.clientId;
        this.client = data.client;
        this.chantierId = data.chantierId;
        this.chantier = data.chantier;
        this.typeBenneId = data.typeBenneId;
        this.typeBenne = data.typeBenne;
        this.etat = data.etat;
        this.dateDebut = data.dateDebut;
        this.dateFin = data.dateFin;
        this.sites = data.sites || [];
        this.bons = data.bons || [];
        this.prestationId = data.prestationId;
        this.quantite = data.quantite;
        this.isModifiable = data.isModifiable;
        this.idFacture = data.idFacture;
        this.numeroFacture = data.numeroFacture;
        this.etatFacture = data.etatFacture;
        this.devise = data.devise;
    }

    isValid() {
        carnetValidator = carnetValidator || validatorBuilder.getInstanceFor('Carnet');
        const validationResults = carnetValidator.validate(this);
        return validationResults.isValid;
    }
}
