import FacturesCheckpontController from './factures.checkpont.controller';

export default {
    bindings: {
        modalInstance: '=',
        source: '='
    },
    template: require('./factures.checkpont.html'),
    controller: FacturesCheckpontController
};
