/* @ngInject */
export default function ModelSelectionValidator(validator, MassiaApplicationService) {
    const m = new validator();

    m.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    m.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    if (MassiaApplicationService.getApplication() === 'laboratoire') {
        m.ruleFor('famille').must(haveAnId).withMessage('VALIDATION_NOTEMPTY');
    }
    m.ruleFor('modelSelectionTypes').must(containType).withMessage('VALIDATION_NOTEMPTY');
    m.ruleFor('code').lengthBetweenValues(0, 15).withMessage('VALIDATION_TOO_LONG_15');
    m.ruleFor('libelle').lengthBetweenValues(0, 100).withMessage('VALIDATION_TOO_LONG_100');

    return m;
}

function haveAnId(model) {
    return model.famille && model.famille.id >= 0;
}

function containType(model) {
    return model.modelSelectionTypes && model.modelSelectionTypes.length > 0;
}
