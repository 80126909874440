const serviceUrl = `${__configuration.apiUrl}/massia/trames`;

export default class TramesSaisiesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getTrames(filters, sorts, pagination, domaine) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                domaine: JSON.stringify(domaine || {}),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getTrameById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSitesProducteurs() {
        const application = 'all'; //SP 05/10/18 pour laisser non filtré sur états compte
        const url = `${__configuration.apiUrl}/massia/sites-producteurs/headers/${application}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getProduitsByProducteurs(idsProducteurs) {
        const url = `${__configuration.apiUrl}/massia/produits/produits-dimensions-by-producteurs/header`;
        const res = await this.$http.get(url, {
            params: {
                idsProducteurs: JSON.stringify(idsProducteurs)
            }
        });
        return res.data;
    }

    async getTamis(idTypeProduit) {
        const url = `${__configuration.apiUrl}/massia/seriestamis-tamis/by-type-produit/${idTypeProduit}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async codeExists(code) {
        const url = `${__configuration.apiUrl}/massia/uniqueness/code/${'TrameSaisie'}/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async createTrame(trame) {
        const result = await this.$http.post(serviceUrl, trame);
        return result.data;
    }

    async updateTrame(trame) {
        const url = `${serviceUrl}/${trame.id}`;
        return await this.$http.put(url, trame);
    }

    async deleteTrameById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async exportListeTrames(domaine, modeImpr, filters, sorts) {
        const url = `${serviceUrl}/print`;
        const res = await this.$http.get(url, {
            params: {
                modeImpr: JSON.stringify(modeImpr),
                domaine: JSON.stringify(domaine),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || [])
            },
            responseType: 'arraybuffer'
        });
        return res;
    }
}
