import TypesClientsController from './types.clients.controller';

export default {
    bindings: {
        listes: '=',
        frontFilters: '='
    },
    template: require('./types.clients.html'),
    controller: TypesClientsController
};
