export default function Configure($stateProvider) {
    $stateProvider.state('impression', {
        parent: 'common',
        url: '/template',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.GENERAL" | translate }}'
        }
    });

    $stateProvider.state('impression.list', {
        url: '/list',
        template: '<template-list></template-list>',
        rights: { domain: 'template', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "IMPRESSION.BREADCRUMBS.IMPRESSION_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'IMPRESSION.BREADCRUMBS.IMPRESSION_LIST',
            order: 10,
            navigationCls: 'menu-item-separator'
        },
        search: 'IMPRESSION.BREADCRUMBS.IMPRESSION_LIST'
    });

    $stateProvider.state('impression.list.detail', {
        url: '/{id}',
        template: '<template-detail></template-detail>',
        rights: { domain: 'template', right: 'read' },
        ncyBreadcrumb: {
            parent: 'impression.list',
            label: '{{ "IMPRESSION.BREADCRUMBS.IMPRESSION_DETAIL" | translate }}'
        }
    });

    /*$stateProvider.state('impression.new', {
        url: '/new',
        template: '<tache-form></tache-form>',
        rights: {domain: 'taches', right: 'create'},
        ncyBreadcrumb: {
            parent: 'taches.list()',
            label: '{{ "TACHES.BREADCRUMBS.TACHE_NEW" | translate}}'
        },
        search: 'TACHES.BREADCRUMBS.TACHE_NEW'
    });*/
}

Configure.$inject = ['$stateProvider'];
