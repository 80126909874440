(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.smart.table')
        .directive('acDynamicColumnsHead', acDynamicColumnsHead);

    acDynamicColumnsHead.$inject = ['$compile', '$parse', '$log'];

    /* @ngInject */
    function acDynamicColumnsHead($compile, $parse, $log) {
        var directive = {
            require: '^stTable',
            link: link,
            restrict: 'A'
        };
        return directive;

        function link(scope, element, attrs, stTableCtrl) {
            var parsedAttributes, generatedTemplate, generatedSort, generatedFilter, compiledElement;

            // on récupère la configuration de la directive
            parsedAttributes = $parse(attrs.acDynamicColumnsHead)(scope);
            // en cas de problème on arrête tout et on prévient le dev
            if (!parsedAttributes || (parsedAttributes && !parsedAttributes.column)) {
                $log.warn('acDynamicColumnsHead: column definition missing');
                return;
            }
            // on publie les propriétés nécessaires à la compilation dans le scope
            scope.column = parsedAttributes.column;

            generatedSort = getGeneratedSort(scope.column);
            generatedFilter = getGeneratedFilter(scope.column);

            // on créé le template dynamiquement
            var cssClassColumn = 'ac-dynamic-columns-head';
            if(scope.column.customClass && scope.column.customClass.length > 0){
                cssClassColumn += ' ' + scope.column.customClass;
            }

            if (!scope.column.sortDisabled) {
                cssClassColumn += ' st-sort';
            }

            generatedTemplate = '' +
                '<th class="' + cssClassColumn + '" ' +
                '    ' + generatedSort + ' ' +
                '    ' + generatedFilter + ' ' +
                '   >' +
                '   <span class="ac-dynamic-columns-head-label" ng-bind-html="::(column.label | abstractFilter:column.labelFilter | translate)"></span> ' +
                '</th>' +
                '';

            // on compile le template pour en faire un élément angular
            compiledElement = $compile(generatedTemplate)(scope, undefined, {transcludeControllers: {stTable: {instance: stTableCtrl}}});

            // on remplace l'élément courant par l'élément compilé
            element.replaceWith(compiledElement);

            function getGeneratedSort(column) {
                var generatedSort;
                var property = column.sortProperty ? column.sortProperty : column.property;

                //on ne génère le tri que si c'est nécessaire
                generatedSort = !column.sortDisabled ? ' st-sort="' + property + '" ' : '';

                if (!column.sortDisabled && column.sortDefault) {
                    generatedSort += ' st-sort-default="' + column.sortDefault + '" ';
                }

                return generatedSort;
            }

            function getGeneratedFilter(column) {
                var generatedFilter;
                var property = column.filterProperty ? column.filterProperty : column.property;
                scope.filterItems = [];

                if (column && column.filter) {
                    switch (column.filter) {
                        case 'contains':
                            generatedFilter = ' st-filter="' + property + '" ';
                            break;
                        case 'combobox':
                        case 'combobox-multiple':
                            // on envoie les items dans le scope afin de faire fonctionner le binding de la directive st-filter-combobox
                            scope.filterItems[column.property] = column.filterItems;
                            generatedFilter = ' st-filter-' + column.filter + '="' + property + '" ';
                            generatedFilter += ' st-filter-items="filterItems[column.property] " ';
                            break;
                        case 'numeric':
                        case 'date':
                        case 'bool':
                        default:
                            generatedFilter = ' st-filter-' + column.filter + '="' + property + '" ';
                            break;
                    }
                }
                return generatedFilter;
            }
        }
    }
})(angular);