import validatorBuilder from 'validator-builder';

let commandeLigneTransportValidator = null;

export default class CommandeLigneTransport {
    constructor(transport, produit) {
        this.id = -1;
        this.prestation = transport.libelle;
        this.prestationId = transport.id;
        this.transportId = transport.id;
        this.siteCommercial = produit.siteCommercial;
        this.siteCommercialId = produit.siteCommercialId;
        this.producteur = produit.producteur;
        this.producteurId = produit.producteurId;
        this.produitId = produit.produitId;
        this.benneId = 0;
        this.quantiteCommandee = 1;
        this.typeES = produit.typeES;
        this.typePrestationLie = '1';
        this.prixBenne = transport.prixBenne;
        this.prixZone = transport.prixZone;
        this.prixStandard = 0;
        this.uniteLibelle = transport.unite;
        this.uniteId = transport.uniteId;
        this.tvaId = 0;
        this.idGrilleZoneKm = transport.idGrilleZoneKm;
        this.idZone = null;
    }

    isValid() {
        commandeLigneTransportValidator = commandeLigneTransportValidator || validatorBuilder.getInstanceFor('CommandeLigneTransport');
        const validationResults = commandeLigneTransportValidator.validate(this);
        return validationResults.isValid;
    }
}
