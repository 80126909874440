export default function Configure($stateProvider) {
    $stateProvider.state('label-ce', {
        parent: 'common',
        url: '/label',
        //abstract: true,
        views: {
            laboratoire: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true,
            parent: 'label-ce',
            label: '{{ "LAYOUT.NAVIGATION.LABEL_CE" | translate }}'
        }
    });

    $stateProvider.state('label-ce.list', {
        url: '/list',
        template: '<label-ce-list></label-ce-list>',
        ncyBreadcrumb: {
            label: '{{ "LABEL_CE.BREADCRUMBS.LABEL_CE_LIST" | translate}}'
        },
        navigation: {
            menu: 'ce_caract_and_label',
            translate: 'LABEL_CE.BREADCRUMBS.LABEL_CE_LIST',
            order: 2
        },
        search: 'LABEL_CE.BREADCRUMBS.LABEL_CE_LIST'
    });
}

Configure.$inject = ['$stateProvider'];
