import PaiementsConfig from './_config/paiements.config';
import PaiementsRoutes from './_config/paiements.routes';
import PaiementsComponent from './components/paiements';
import PaiementsService from './services/paiements.service';
import PaiementsTypesService from './services/paiements.types.service';
import PaiementsCommunicationService from './services/paiements.communication.service';

import PaiementFormComponent from './components/paiement-form';
import PaiementEnteteComponent from './components/paiement-entete';
import PaiementDetailComponent from './components/paiement-detail';

import PaiementValidator from './components/paiement-form/paiement.validator';
import PaiementEnteteValidator from './components/paiement-entete/paiement.entete.validator';

const moduleName = 'app.massia.gestion.paiements';

angular
    .module(moduleName, [])
    .config(PaiementsConfig)
    .config(PaiementsRoutes)
    .service('PaiementsService', PaiementsService)
    .service('PaiementsTypesService', PaiementsTypesService)
    .service('PaiementsCommunicationService', PaiementsCommunicationService)
    .component('paiements', PaiementsComponent)
    .component('paiementForm', PaiementFormComponent)
    .component('paiementEntete', PaiementEnteteComponent)
    .component('paiementDetail', PaiementDetailComponent)
    .factory('PaiementValidator', PaiementValidator)
    .factory('PaiementEnteteValidator', PaiementEnteteValidator);

export default moduleName;
