import TypeCaracteristiquesFormController from './type.caracteristiques.form.controller';

export default {
    template: require('./type.caracteristiques.form.tpl.html'),
    controller: TypeCaracteristiquesFormController,
    bindings: {
        disabled: '<',
        caracteristiques: '=',
        onDelete: '&',
        onUpdate: '&',
        onEdit: '&',
        mobile: '<'
    }
};
