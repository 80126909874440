export default function Configure($stateProvider) {
    $stateProvider.state('societes-producteur', {
        parent: 'common',
        url: '/societes-producteur',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('societes-producteur.list', {
        url: '/list',
        template: '<societes></societes>',
        rights: { domain: 'societes', right: 'read' },
        params: {
            genre: '4'
        },
        ncyBreadcrumb: {
            label: '{{ "SOCIETES.BREADCRUMBS.SOCIETES_PRODUCTEUR_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_donnees',
            translate: 'SOCIETES.BREADCRUMBS.SOCIETES_PRODUCTEUR_LIST',
            order: 7
        },
        search: 'SOCIETES.BREADCRUMBS.SOCIETES_PRODUCTEUR_LIST'
    });

    $stateProvider.state('societes-producteur.niveaux', {
        url: '/niveaux',
        template: '<societe-config-niveaux></societe-config-niveaux>',
        rights: { domain: 'nivh', right: 'read' },
        params: {
            genre: '4'
        },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher societes > niveaux
            parent: 'societes-producteur.list({genre: "4"})',
            label: '{{ "SOCIETES.BREADCRUMBS.SOCIETE_NIVEAUX" | translate }}'
        }
    });

    $stateProvider.state('societes-producteur.list.detail', {
        url: '/{id}',
        template: '<societe-detail></societe-detail>',
        rights: { domain: 'societes', right: 'read' },
        params: {
            genre: '4'
        },
        ncyBreadcrumb: {
            parent: 'societes-producteur.list({genre: "4"})',
            label: '{{ "SOCIETES.BREADCRUMBS.SOCIETE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('societes-producteur.new', {
        url: '/new',
        template: '<societe-form></societe-form>',
        rights: { domain: 'societes', right: 'create' },
        params: {
            genre: '4'
        },
        ncyBreadcrumb: {
            parent: 'societes-producteur.list({genre: "4"})',
            label: '{{ "SOCIETES.BREADCRUMBS.SOCIETE_PRODUCTEUR_NEW" | translate}}'
        },
        search: 'SOCIETES.BREADCRUMBS.SOCIETE_PRODUCTEUR_NEW'
    });

    $stateProvider.state('societes-producteur.edit', {
        url: '/{id}/edit',
        template: '<societe-form></societe-form>',
        rights: { domain: 'societes', right: 'update' },
        params: {
            genre: '4'
        },
        ncyBreadcrumb: {
            parent: 'societes-producteur.list({genre: "4"})',
            label: '{{ "SOCIETES.BREADCRUMBS.SOCIETE_PRODUCTEUR_EDIT" | translate }}'
        }
    });

    $stateProvider.state('societes-producteur.newType', {
        url: '/new-type',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        params: {
            parentState: 'societes-producteur.new',
            genre: '4'
        },
        ncyBreadcrumb: {
            parent: 'societes-producteur.new',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
