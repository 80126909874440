/* 
 * An Angular service which helps with creating recursive directives.
 * @author Mark Lagendijk
 * @license MIT
 */
(function (angular, undefined) {
    'use strict';
    angular.module('blocks.treeview')
        .factory('RecursionHelper', RecursionHelper);

    RecursionHelper.$inject = ['$compile'];

    function RecursionHelper($compile) {
        return {
            /**
             * Manually compiles the element, fixing the recursion loop.
             * @param element
             * @param [link] A post-link function, or an object with function(s) registered via pre and post properties.
             * @returns An object containing the linking functions.
             */
            compile: function (element, link) {
                // Normalize the link parameter
                if (angular.isFunction(link)) {
                    link = {post: link};
                }

                // Break the recursion loop by removing the contents
                var contents = element.contents().remove();
                var compiledContents;
                return {
                    pre: (link && link.pre) ? link.pre : null,
                    /**
                     * Compiles and re-adds the contents
                     */
                    post: function (scope, element) {
                        // Call the post-linking function, if any
                        // Link function is called before $compile to ensure correct order of nested directives link evaluation
                        // this means that the given link function will not have access to the dom of the sub-templates (generated by $compile)
                        if (link && link.post) {
                            link.post.apply(null, arguments);
                        }

                        // Compile the contents
                        if (!compiledContents) {
                            compiledContents = $compile(contents);
                        }

                        // Re-add the compiled contents to the element
                        compiledContents(scope, function (clone) {
                            element.append(clone);
                        });
                    }
                };
            }
        };
    }
})(angular);