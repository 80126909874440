export default class ReferenceControleFormController {
    static $inject = ['$scope', '$timeout', '$state', '$stateParams', '$translate', 'ReferencesControleTypesService', '_'];

    constructor($scope, $timeout, $state, $stateParams, $translate, ReferencesControleTypesService, _) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.ReferencesControleTypesService = ReferencesControleTypesService;
        this._ = _;
    }

    async $onInit() {
        this.loading = false;
        this.mesures = this.mesures || [];
        this.specificationsNorme = this.specificationsNorme || { categories: [], specifications: [] };
        this.domaineMesure = this.domaine;
        this.idNorme = this.idNorme;
        this.calculsStatistiques = this.calculsStatistiques || [];
        this.referenceControle = this.referenceControle;

        this.setIstevenCalcStats();
        this.onDelete = this.onDelete || angular.noop;
        this.onDeleteItem = this.onDeleteItem || angular.noop;
        this.onDeleteAll = this.onDeleteAll || angular.noop;
        this.onUpdateCategorie = this.onUpdateCategorie || angular.noop;
        this.onUpdateCalcStat = this.onUpdateCalcStat || angular.noop;
        this.onSelectMesure = this.onSelectMesure || angular.noop;
        this.onChangeXt = this.onChangeXt || angular.noop;
        this.onDuplicate = this.onDuplicate || angular.noop;

        this.typesMesures = null;
        this.getTypesMesures();
    }

    async getTypesMesures() {
        try {
            this.typesMesures = await this.ReferencesControleTypesService.getAllMesures(this.domaineMesure, this.referenceControle.familleId);
            const itemVide = { id: 0, libelle: this.$translate.instant('REFERENCES.AUCUN_FILTRE') };
            this.typesMesures.splice(0, 0, itemVide);
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    getDomaineCourant() {
        const domaine = this.$state.current.name;
        return domaine.substring(0, domaine.indexOf('.'));
    }

    async selectMesure(mesure) {
        if (this.onSelectMesure) {
            this.onSelectMesure({ mesure: mesure });
        }
    }

    setIstevenCalcStats() {
        if (this.mesures.length > 0) {
            for (let i = 0; i < this.mesures.length; i++) {
                const mesure = this.mesures[i];
                mesure.calculStatListe = angular.copy(this.calculsStatistiques);
                const calculStat = mesure.calculStatListe.find((x) => x.id === mesure.calculId);
                if (calculStat) {
                    calculStat.selected = true;
                }
            }
        }
    }

    selectCalculStat(data, index) {
        if (data.selected) {
            this.mesures[index].calculId = data.id;
            this.onUpdateCalcStat({ mesure: this.mesures[index] });
        }
    }
}
