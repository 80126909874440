export default class ProfilsService {
    static $inject = ['$http', '$timeout', 'DomainesService'];

    constructor($http, $timeout, DomainesService) {
        this.$http = $http;
        this.$timeout = $timeout;
        this.DomainesService = DomainesService;
        this.getProfilUrl = () => `${__configuration.apiUrl}/massia/profils`;
    }

    // GET
    async getProfils(filters, sorts, pagination, nomApplication) {
        const res = await this.$http.get(this.getProfilUrl(), {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {}),
                appName: JSON.stringify(nomApplication || null)
            }
        });

        return res.data;
    }

    async getProfil(id, nomApplication) {
        const res = await this.$http.get(`${this.getProfilUrl()}/${id}`, {
            params: {
                appName: JSON.stringify(nomApplication || null)
            }
        });
        res.data.niveauxAccesDomaines.forEach((domaine) => {
            domaine.droits = this.DomainesService.getDroitsByBitmask(domaine.niveauAcces, domaine.niveauMax);
        }, this);

        return res.data;
    }

    async getProfilEntete(id) {
        const res = await this.$http.get(`${this.getProfilUrl()}/${id}/entete`);
        return res.data;
    }

    async getDomaines(id, filters, sorts, pagination, nomApplication) {
        const res = await this.$http.get(`${this.getProfilUrl()}/${id}/niveaux-acces-domaines`, {
            params: {
                id: id,
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {}),
                appName: JSON.stringify(nomApplication || null)
            }
        });

        if (res.data && res.data.items && res.data.items.length > 0) {
            angular.forEach(
                res.data.items,
                (domaine) => {
                    domaine.droits = this.DomainesService.getDroitsByBitmask(domaine.niveauAcces, domaine.niveauMax);
                },
                this
            );
        }

        return res.data;
    }

    async libelleExists(id, libelle) {
        if (!id) {
            id = 0;
        }
        if (!libelle) {
            libelle = '';
        }

        const url = `${this.getProfilUrl()}/libelle-unicity/${libelle}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    // POST
    async createProfil(profil) {
        const data = {
            libelle: profil.libelle,
            niveauxAccesDomaines: profil.niveauxAccesDomaines.map((domaine) => {
                domaine.niveauAcces = this.DomainesService.getBitmaskByDroits(domaine.droits);
                return domaine;
            })
        };

        const result = await this.$http.post(this.getProfilUrl(), data);

        return result.data;
    }

    // PUT
    async updateProfil(id, profil, nomApplication) {
        const data = {
            libelle: profil.libelle,
            appName: nomApplication,
            niveauxAccesDomaines: profil.niveauxAccesDomaines.map((domaine) => {
                domaine.niveauAcces = this.DomainesService.getBitmaskByDroits(domaine.droits);
                return domaine;
            })
        };

        return this.$http.put(`${this.getProfilUrl()}/${id}`, data);
    }

    // DELETE
    async deleteProfilById(idProfil) {
        const url = `${this.getProfilUrl()}/${idProfil}`;
        return this.$http.delete(url);
    }
}
