import DistanciersConfig from './_config/distanciers.config';
import DistanciersRoutes from './_config/distanciers.routes';
import DistanciersComponent from './components/distanciers';
import DistanciersService from './services/distanciers.service';

const moduleName = 'app.parametrageGeneral.distanciers';

angular
    .module(moduleName, [])
    .config(DistanciersConfig)
    .config(DistanciersRoutes)
    .service('DistanciersService', DistanciersService)
    .component('distanciers', DistanciersComponent);

export default moduleName;
