ProduitEnteteNormeValidator.$inject = ['validator'];

export default function ProduitEnteteNormeValidator(validator) {
    const instance = new validator();
    instance.ruleFor('typeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('idSite').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    instance
        .ruleFor('code')
        .matches(/^[a-zA-Z0-9_|]*$/)
        .withMessage('CODE_CHARACTERS_NOK');
    instance.ruleFor('codeExists').notEqual(true).withMessage('VALIDATION_NOTEMPTY');
    return instance;
}
