import PromiseConfigurator from './_config/promise.run';

import AppConfiguration from './_config/app.config';
import DynamicLocaleConfiguration from './_config/dynamic.locale.config';
import MassiaRoutes from './_config/massia.routes';

import layoutModule from './layout';
import authenticationModule from './authentication';
import httpPending from './http-pending';
import loginModule from './login';

import LastHistoryConfig from './_config/last.history.config';
import CreatePrototype from './_config/prototype.run';
import MassiaChartPlugin from './_config/chart.plugin';

import electron from './electron';
import { uiGridOverride } from './_config/ui.grid.override';

const moduleNameApp = 'app.massia';
const module = [
    layoutModule,
    authenticationModule,
    httpPending,
    loginModule,
    electron,
    [
        '$httpProvider',
        function ($httpProvider) {
            $httpProvider.interceptors.push([
                '$rootScope',
                '$q',
                function ($rootScope, $q) {
                    return {
                        responseError: function (response) {
                            $rootScope.$broadcast('httpError', response);
                            return $q.reject(response);
                        }
                    };
                }
            ]);
        }
    ]
];
angular
    .module(moduleNameApp, module)
    .config([
        '$locationProvider',
        ($locationProvider) => {
            $locationProvider.hashPrefix('!');
        }
    ])
    .config(AppConfiguration)
    .config(DynamicLocaleConfiguration)
    .config(MassiaRoutes)
    .run(CreatePrototype)
    .run(PromiseConfigurator)
    .run(LastHistoryConfig)
    .run(MassiaChartPlugin)
    .run(uiGridOverride)
    .run(['lifeCycleHelper', function (lifeCycleHelper) {}]);
export default moduleNameApp;
