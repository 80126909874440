CarnetValidator.$inject = ['validator'];

export default function CarnetValidator(validator) {
    const instance = new validator();
    instance.ruleFor('societeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('clientId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('siteId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeBenneId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('dateDebut').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('dateFin').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('sites').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('prestationId').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;
}
