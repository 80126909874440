import MotifDepreciationStock from './motif.depreciation.stock.model';

export default class MotifDepreciationStockFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'MotifsDepreciationStockCommunicationService',
        'MotifsDepreciationStockService',
        'notification',
        '$location'
    ];

    constructor($scope, $state, $stateParams, MotifsDepreciationStockCommunicationService, MotifsDepreciationStockService, notification, $location) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.MotifsDepreciationStockCommunicationService = MotifsDepreciationStockCommunicationService;
        this.MotifsDepreciationStockService = MotifsDepreciationStockService;
        this.notification = notification;
        this.$location = $location;
    }

    async $onInit() {
        this.isEditMode = false;
        this.motifDepreciationStock = {};
        this.loading = false;
        this.setMotifDepreciationStock(this.$stateParams.id);
        this.unregisterReinit = this.MotifsDepreciationStockCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    initForm() {
        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
            const thatMotifDepreciationStock = {
                id: this.motifDepreciationStock.id,
                libelle: this.motifDepreciationStock.libelle
            };
        } else {
            this.isEditMode = false;
        }
    }

    async setMotifDepreciationStock(motifDepreciationStockId) {
        if (motifDepreciationStockId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                const stillThatMotifDepreciationStock = await this.MotifsDepreciationStockService.getMotifDepreciationStockById(
                    motifDepreciationStockId
                );
                this.motifDepreciationStock = new MotifDepreciationStock(stillThatMotifDepreciationStock);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('motifs-depreciation-stock.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.motifDepreciationStock = new MotifDepreciationStock();
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.motifDepreciationStock.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.motifDepreciationStock.codeExists = await this.MotifsDepreciationStockService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    // boutons de validation
    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                if (this.motifDepreciationStock.id) {
                    const tmp = await this.updateMotifDepreciationStock();
                    this.notification.success('MOTIFS_DEPRECIATION_STOCK.UPDATED');
                    return this.motifDepreciationStock.id;
                }
                const tmp = await this.createMotifDepreciationStock();
                this.notification.success('MOTIFS_DEPRECIATION_STOCK.CREATED');
                return tmp;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        } else {
            return false;
        }
    }

    async valider() {
        const returnedId = await this.sauvegarder();
        if (returnedId) {
            this.$state.go('motifs-depreciation-stock.edit', { id: returnedId });
        }
    }
    async validerEtFermer() {
        const returnedId = this.sauvegarder();
        this.backToPreviousState();
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('motifs-depreciation-stock.list');
    }

    async createMotifDepreciationStock() {
        this.startLoading();
        try {
            const returnedId = await this.MotifsDepreciationStockService.createMotifDepreciationStock(this.motifDepreciationStock);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateMotifDepreciationStock() {
        this.startLoading();
        try {
            const returnedId = await this.MotifsDepreciationStockService.updateMotifDepreciationStock(this.motifDepreciationStock);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    reinit() {
        this.setMotifDepreciationStock(this.$stateParams.id);
        this.MotifsDepreciationStockCommunicationService.raiseReinit();
    }

    checkValidity() {
        this.$scope.$broadcast('motifDepreciationStockValidations');
        return this.motifDepreciationStock.isValid();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
