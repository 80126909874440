(function (angular, undefined) {
    'use strict';

    angular.module('blocks.treeview')
        .run(run);
    run.$inject = ['$templateCache'];

    function run($templateCache) {

        $templateCache.put('blocks/treeview/ac.treeview.leaf.tpl.html', '' +
            '<div ng-class="{\'node-selected\': tree.isSelected(leaf), \'item-inactive\': leaf.active == false}"' +
            '     ng-mousedown="tree.select(leaf)"' +
            '     class="node-leaf drag-drop noselect {{::tree.getStyle(leaf)}}">' +
            '' +
            '   <div class="node-name vertical-align-middle">' +
            '       <!-- Icone -->' +
            '       <a class="btn btn-link btn-xs node-icon padding-0">' +
            '           <span class="node-leaf-icon glyphicon glyphicon-{{ ::leaf.icon || tree.config.leafTypesIcons[leaf.type] }}"></span>' +
            '       </a>' +
            '' +
            '       <!-- Nom du noeud -->' +
            '       <span class="node-label node-leaf-label"' +
            '             title="{{ ::leaf.label }}"' +
            '             ng-bind="::leaf.label"></span>' +
            '   </div>' +
            '</div>' +
            '');
    }
})(angular);