const serviceUrl = `${__configuration.apiUrl}/massia/motif-annulations`;
// const serviceUrlList = `${__configuration.apiUrl}/massia/unites-list`;

export default class MotifsService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getMotifs(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getMotifsSelect(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async deleteMotifById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getMotifById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createMotif(selectedMotif) {
        const data = {
            code: selectedMotif.code,
            libelle: selectedMotif.libelle
        };
        const result = await this.$http.post(serviceUrl, data);
        return result.data;
    }

    async updateMotif(selectedMotif) {
        const data = {
            id: selectedMotif.id,
            code: selectedMotif.code,
            libelle: selectedMotif.libelle
        };
        const url = `${serviceUrl}/${data.id}`;
        return this.$http.put(url, data);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }
}
