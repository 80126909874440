import MassiaPrintModalController from '../../../../../libs/massia-component/massia-print-modal/massia.print.modal';

DocumentsCEController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'MassiaApplicationService',
    'ModalService',
    'PaginationService',
    'DocumentsCEService',
    'notification',
    'TemplateImpressionService',
    '$uibModal',
    '$transitions',
    'MOPService'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function DocumentsCEController(
    $scope,
    $state,
    $stateParams,
    $translate,
    MassiaApplicationService,
    ModalService,
    PaginationService,
    DocumentsCEService,
    notification,
    TemplateImpressionService,
    $uibModal,
    $transitions,
    MOPService
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    let selectedDocumentCEId = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.MassiaApplicationService = MassiaApplicationService;
    vm.documentsCE = [];
    vm.application = '';

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.selectDocumentCE = selectDocumentCE;
    vm.isDocumentCESelected = isDocumentCESelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadDocumentsCE = loadDocumentsCE;
    vm.deleteDocumentCE = deleteDocumentCE;
    vm.duplicateDocumentCE = duplicateDocumentCE;
    vm.setSelected = setSelected;
    vm.getApplication = getApplication;
    vm.MOPService = MOPService;

    function init() {
        vm.MOPService.setMop([{ title: 'DOCUMENTS_CE.BREADCRUMBS.DOCUMENTS_CE_LIST', filename: 'DDPDocuments.pdf', application: 'laboratoire' }]);
        vm.application = MassiaApplicationService.getApplication();
        //écouter l'event lié au changement de route
        //une fois on change l'url, et on arrive sur la page "documents-ce.list", on désélectionne la ligne sélectionnée (s'il y en a)
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        //$transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        //quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    init();

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    function stateChangeSuccessFunc(event, toState) {
        if (!$state.params.id) {
            vm.setSelected();
        } else if ($state.params.id) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    function setSelected(docce) {
        if (docce && docce.id) {
            selectedDocumentCEId = $stateParams.id;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            selectedDocumentCEId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return (
            $state && $state.current && $state.current.name && $state.current.name === 'documents.ce.list.detail' && $stateParams && $stateParams.id
        );
    }

    function selectDocumentCE(docce) {
        if (docce && docce.id) {
            //si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedDocumentCEId, et charger la vue détail pour le docce sélectionné
            if (vm.selectedDocumentCEId !== docce.id) {
                vm.selectedDocumentCEId = docce.id;
                showDetail(docce.id);
            } else {
                //si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
                vm.selectedDocumentCEId = undefined;
                closeDetail();
            }
        }
    }

    function isDocumentCESelected(docce) {
        return docce && docce.id && vm.selectedDocumentCEId === docce.id;
    }

    function showDetail(docceId) {
        if (MassiaApplicationService.getApplication() == 'gestion') {
            $state.go('documents-ce.list.detail', { id: docceId });
        } else {
            $state.go('documents-ce-labo.list.detail', { id: docceId });
        }
    }

    function closeDetail() {
        if (MassiaApplicationService.getApplication() == 'gestion') {
            $state.go('documents-ce.list');
        } else {
            $state.go('documents-ce-labo.list');
        }
    }

    async function loadDocumentsCE(tableState) {
        startLoading();

        //quand on rafraîchit la grille, on revient sur "documents-ce.list", et on ferme la vue détail d'un docce
        //sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
        if ($state && $state.current && $state.current.name !== 'documents-ce.list' && $state.current.name !== 'documents-ce-labo.list') {
            if (MassiaApplicationService.getApplication() == 'gestion') {
                $state.go('documents-ce.list');
            } else {
                $state.go('documents-ce-labo.list');
            }
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.documentsCE = [];

        try {
            const data = await DocumentsCEService.getDocumentsCE(filters, sorts, pagination);

            vm.documentsCE = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteDocumentCE(docce) {
        if (docce && docce.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('DOCUMENTS_CE.DELETE.TITLE', { code: docce.libelle }),
                modalMsg: $translate.instant('DOCUMENTS_CE.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await DocumentsCEService.deleteDocumentCEById(docce.id);
                    notification.success($translate.instant('DOCUMENTS_CE.DELETE.SUCCESS'));

                    selectedDocumentCEId = undefined;

                    // si jamais la vue détail est affichée,
                    // on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
                    if (vm.params.id) {
                        vm.state.go('documents-ce.list');
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadDocumentsCE();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    $scope.openPrint = async (docce, isNormeParPage) => {
        try {
            const res = await TemplateImpressionService.downloadModal('docce');
            const template = await DocumentsCEService.print(docce.id, 0, res.filename, isNormeParPage);
            template.pdf = res.pdf;
            if (res === 'cancel') {
                throw res;
            }
            notification.info($translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
                autoDismiss: false
            });
            await TemplateImpressionService.downloadTemplate(template.filename, template, 'docce');
        } catch (err) {
            //if(err !== "cancel") notification.error(err.data);
            console.error(err);
        }
    };

    function getApplication() {
        return MassiaApplicationService.getApplication();
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }

    async function duplicateDocumentCE(document) {
        if (document && document.id) {
            $state.go('documents-ce.duplicate', { id: document.id });
        }
    }
}
