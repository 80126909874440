import JourFerie from './jour.ferie.model';

export default class JourFerieFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'JoursFeriesCommunicationService',
        'JoursFeriesService',
        'notification',
        '$location',
        'moment',
        '$translate',
        'globalizationManagementService',
        'RecurrenceService',
        'MOPService'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        JoursFeriesCommunicationService,
        JoursFeriesService,
        notification,
        $location,
        moment,
        $translate,
        globalizationManagementService,
        RecurrenceService,
        MOPService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.JoursFeriesCommunicationService = JoursFeriesCommunicationService;
        this.JoursFeriesService = JoursFeriesService;
        this.notification = notification;
        this.$location = $location;
        this.moment = moment;
        this.$translate = $translate;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
        this.RecurrenceService = RecurrenceService;
        this.RecurrenceService.isUseHours = false;
        this.MOPService = MOPService;
    }

    async $onInit() {
        this.MOPService.setMop([{ title: 'JOURS_FERIES.BREADCRUMBS.JOURS_FERIES_LIST', filename: 'JoursFeries.pdf', application: 'massia' }]);
        this.isEditMode = false;
        this.jourFerie = {};
        this.loading = false;
        this.openAllOnglets();
        this.setJourFerie(this.$stateParams.id);
        this.unregisterReinit = this.JoursFeriesCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        this.unregisterReinit();
        this.MOPService.resetMop();
    }

    initForm() {
        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
        } else {
            this.isEditMode = false;
        }
        this.RecurrenceService.initFormulaireNouvelleDate(true);
    }

    async setJourFerie(jourFerieId) {
        if (jourFerieId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                const data = await this.JoursFeriesService.getJourFerieById(jourFerieId);
                this.jourFerie = new JourFerie(data);
                if (this.$stateParams.duplicate) {
                    this.jourFerie.id = undefined;
                    this.isEditMode = false;
                    this.jourFerie.code += '_copie';
                    await this.checkCodeUnicity(this.jourFerie.code);
                }
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('jours-feries.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.jourFerie = new JourFerie();
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.jourFerie.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.jourFerie.codeExists = await this.JoursFeriesService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    // boutons de validation
    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                if (this.jourFerie.id) {
                    await this.updateJourFerie();
                    this.notification.success('JOURS_FERIES.UPDATED');
                    return this.jourFerie.id;
                }
                const tmp = await this.createJourFerie();
                this.notification.success('JOURS_FERIES.CREATED');
                return tmp;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async valider() {
        const returnedId = await this.sauvegarder();
        if (returnedId) {
            this.$state.go('jours-feries.edit', { id: returnedId });
        }
    }
    async validerEtFermer() {
        this.sauvegarder();
        this.backToPreviousState();
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('jours-feries.list');
    }

    async createJourFerie() {
        this.startLoading();
        try {
            const returnedId = await this.JoursFeriesService.createJourFerie(this.jourFerie);
            this.notification.success('JOURS_FERIES.CREATED');
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateJourFerie() {
        this.startLoading();
        try {
            const returnedId = await this.JoursFeriesService.updateJourFerie(this.jourFerie);
            this.notification.success('JOURS_FERIES.UPDATED');
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    reinit() {
        this.setJourFerie(this.$stateParams.id);
        this.JoursFeriesCommunicationService.raiseReinit();
    }

    checkValidity() {
        let validity = true;
        if (!this.jourFerie.isValid()) {
            validity = false;
        }
        this.$scope.$broadcast('jourFerieValidations');
        return validity;
    }

    openFirstOnglet() {
        //par défaut, on ouvert l'onglet "Entête"
        this.ongletOpen = {
            isEnteteOpen: true
        };
    }

    openAllOnglets() {
        //par défaut, on ouvert l'onglet "Entête"
        this.ongletOpen = {
            isEnteteOpen: true,
            isJoursFeriesOpen: true
        };
    }

    closeAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: false
        };
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
