import OffresConfig from './_config/offres.config';
import OffresRoutes from './_config/offres.routes';
import OffresComponent from './components/offres';
import OffresService from './services/offres.service';
import OffresCommunicationService from './services/offres.communication.service';

import OffreFormComponent from './components/offre-form';
import OffreEnteteComponent from './components/offre-entete';
import OffreDemandeComponent from './components/offre-demande';
import OffrePropositionComponent from './components/offre-proposition';
import OffreProducteurComponent from './components/offre-producteur';
import OffreCommandeComponent from './components/offre-commande';
import OffreValidationDevis from './components/offre-validation-devis';
import OffreValidationNiveau from './components/offre-validation-devis/offre-validation-niveau';
import OffreResultatValidationComponent from './components/offre-resultat-validation';
//import OffreCaracteristiquesComponent  from './components/offre-caracteristiques';
import OffreImpressionHelper from './components/offre-impression-helper';
import MiniFormContactComponent from '../../common/personnes/components/mini-form/contact';

import OffreValidator from './components/offre-form/offre.validator';
import OffreEnteteValidator from './components/offre-entete/offre.entete.validator';
import OffreDemandeValidator from './components/offre-demande/offre.demande.validator';
import OffrePropositionValidator from './components/offre-proposition/offre.proposition.validator';

const moduleName = 'app.parametrageGeneral.offres';

angular
    .module(moduleName, [])
    .config(OffresConfig)
    .config(OffresRoutes)
    .service('OffresService', OffresService)
    .service('OffresCommunicationService', OffresCommunicationService)
    .component('offres', OffresComponent)
    .component('offreForm', OffreFormComponent)
    .component('offreEntete', OffreEnteteComponent)
    .component('offreDemande', OffreDemandeComponent)
    .component('offreProposition', OffrePropositionComponent)
    .component('offreProducteur', OffreProducteurComponent)
    .component('offreCommande', OffreCommandeComponent)
    .component('offreValidationDevis', OffreValidationDevis)
    .component('offreValidationNiveau', OffreValidationNiveau)
    .component('offreResultatValidation', OffreResultatValidationComponent)
    //.component('offreCaracteristiques', OffreCaracteristiquesComponent)
    .component('offreImpressionHelper', OffreImpressionHelper)
    .component('miniFormContact', MiniFormContactComponent)
    .factory('OffreValidator', OffreValidator)
    .factory('OffrePropositionValidator', OffrePropositionValidator)
    .factory('OffreEnteteValidator', OffreEnteteValidator)
    .factory('OffreDemandeValidator', OffreDemandeValidator);

export default moduleName;
