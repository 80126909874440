export default function MassiaAuthConfigurator(
    $log,
    $rootScope,
    $state,
    $translate,
    LoginService,
    MassiaAuthService,
    MassiaRightsService,
    MassiaApplicationService,
    $window,
    $transitions
) {
    /* On enregistre ici les events levés par l'auth-interceptor
     * authService.loginConfirmed() : event:auth-loginConfirmed
     * authService.loginCancelled() : event:auth-loginCancelled
     * 401 : event:auth-loginRequired
     * 403 : event:auth-forbidden
     */
    $rootScope.$on('event:auth-loginRequired', (event, args) => MassiaAuthService.onLoginRequired(event, args));
    $rootScope.$on('event:auth-forbidden', (event, args) => MassiaAuthService.onForbidden(event, args));
    $rootScope.$on('httpError', (event, args) => MassiaAuthService.onHttpError(event, args));

    $transitions.onStart({}, (transition) => {
        stateChangeStartEvent(transition, transition.to(), transition.params('to'), transition.from(), transition.params('from'));
    });

    // on s'abonne au changement de route pour vérifier si l'utilisateur est authentifié
    // et s'il a le droit de voir l'écran vers lequel il navigue
    //deprecated
    //$rootScope.$on('$stateChangeStart', stateChangeStartEvent);

    function stateChangeStartEvent(transition, toState, toParams, fromState, fromParams) {
        // si le state permet les anonymes alors on ne vérifie rien
        if (!toState.allowAnonymous) {
            const authenticated = MassiaAuthService.isUserAuthenticated();
            if (!authenticated && toState.name !== 'login') {
                // s'il n'est pas loggé, et ce d'où qu'il vienne, on le ramène sur le login
                $log.warn($translate.instant('USER_ACCESS_VIOLATION'));

                // on le dirige vers le login
                // et on sauvegarde la source pour le rediriger après le login là où il voulait aller
                $state.go('login', { sessionExpired: true, originalState: toState, originalStateParams: JSON.stringify(toParams) });

                // on empêche le changement de state
                transition.abort();
            } else {
                if (toState.external) {
                    let url = toState.url;
                    if (toState.rewriteUrl) {
                        url = toState.rewriteUrl(url);
                    }
                    $window.open(url, '_self;'); //$location.url(toState.url);

                    transition.abort();
                }
                // si le state définit des droits on checke si l'utilisateur les possède
                // sinon on ne fait rien, l'utilisateur peut y aller
                if (toState.rights) {
                    const domain = toState.rights.domain;
                    const right = toState.rights.right;
                    const application = toState.rights.application;

                    if (domain && right) {
                        const userHasRight = MassiaRightsService.userHasRightForState(domain, right, application);

                        if (!userHasRight) {
                            const redirection = LoginService.getPostLoginRedirection();
                            $state.go(redirection, { missingRight: true, originalState: toState });

                            // s'il n'a pas le droit d'afficher l'écran on le renvoie sur l'accueil
                            transition.abort();
                        }
                    }
                }
            }
        }
    }
}

MassiaAuthConfigurator.$inject = [
    '$log',
    '$rootScope',
    '$state',
    '$translate',
    'LoginService',
    'MassiaAuthService',
    'MassiaRightsService',
    'MassiaApplicationService',
    '$window',
    '$transitions'
];
