import MotifsDepreciationStockConfig from './_config/motifs.depreciation.stock.config';
import MotifsDepreciationStockRoutes from './_config/motifs.depreciation.stock.routes';
import MotifsDepreciationStockComponent from './components/motifs-depreciation-stock';
import MotifsDepreciationStockService from './services/motifs.depreciation.stock.service';
import MotifsDepreciationStockCommunicationService from './services/motifs.depreciation.stock.communication.service';
import MotifDepreciationStockFormComponent from './components/motif-depreciation-stock-form';
import MotifDepreciationStockValidator from './components/motif-depreciation-stock-form/motif.depreciation.stock.validator';

const moduleName = 'app.massia.common.motifs.depreciation.stock';

angular
    .module(moduleName, [])
    .config(MotifsDepreciationStockConfig)
    .config(MotifsDepreciationStockRoutes)
    .service('MotifsDepreciationStockService', MotifsDepreciationStockService)
    .service('MotifsDepreciationStockCommunicationService', MotifsDepreciationStockCommunicationService)
    .component('motifsDepreciationStock', MotifsDepreciationStockComponent)
    .component('motifDepreciationStockForm', MotifDepreciationStockFormComponent)
    .factory('MotifDepreciationStockValidator', MotifDepreciationStockValidator);

export default moduleName;
