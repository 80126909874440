import NiveauxHierarchiquesController from './niveaux.hierarchiques.controller';

export default {
    template: require('./niveaux.hierarchiques.tpl.html'),
    controller: NiveauxHierarchiquesController,
    bindings: {
        name: '<',
        trameConfig: '<',
        trameService: '<',
        elementsConfig: '<',
        elementsService: '<'
    }
};
