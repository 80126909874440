(function (angular, undefined) {
    'use strict';

    angular.module('blocks.filters').filter('ReduceIfMoreThan', ReduceIfMoreThan);

    function ReduceIfMoreThan() {
        return function (value, max) {
            // si la valeur est supérieure au max annoncé
            if (value && value.length > max) {
                // on retourne la première lettre suivie d'un point
                return value[0] + '.';
            }
            else {
                return value;
            }
        };
    }

})(angular);