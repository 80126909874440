export default class PlanImpressionPrixFrancoController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'ModalService',
        'PlanImpressionPrixService',
        'notification',
        '$translate',
        '$filter',
        '_',
        '$uibModal',
        'moment',
        'globalizationManagementService'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        ModalService,
        PlanImpressionPrixService,
        notification,
        $translate,
        $filter,
        _,
        $uibModal,
        moment,
        globalizationManagementService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ModalService = ModalService;
        this.PlanImpressionPrixService = PlanImpressionPrixService;
        this.notification = notification;
        this.$translate = $translate;
        this.$filter = $filter;
        this.$uibModal = $uibModal;
        this.moment = moment;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    async $onInit() {
        this.startLoading();

        await this.getTarifs();

        this.stopLoading();
    }

    async getTarifs() {
        this.loading = true;
        let filters = {};

        switch (this.domaine) {
            case 'typeClient':
                filters = {
                    typeClientId: this.id
                };
                break;
            case 'client':
                filters = {
                    clientId: this.id
                };
                break;
            case 'chantier':
                filters = {
                    chantierId: this.id
                };
                break;
            case 'aucun':
                break;
        }

        filters.achatVente = true;
        filters.date = this.moment(this.date, this.dateFormat).format(this.dateFormat) || this.moment().format(this.dateFormat);

        try {
            const data = await this.PlanImpressionPrixService.getTarifs(this.urlDomainName, this.urlDomainIds, filters);
            this.tarifs = data.transports.filter((tarif) => tarif.prix);
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }

        this.loading = false;
    }

    cancel() {
        this.modalInstance.dismiss();
    }

    confirmer(prix) {
        if (prix) {
            this.modalInstance.close(prix);
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
