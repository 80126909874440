import MesureSousMesures from './mesure.sous.mesures.model';
import AdditionnalInfoMesure from '../nature-additionnal-info-mesure/additionnal.info.mesure.model';

export default class MesureSousMesuresController {
    static $inject = ['$scope', '$state', '$stateParams', 'MesuresService', 'ModalService', 'notification', '$translate', '$filter'];

    constructor($scope, $state, $stateParams, MesuresService, ModalService, notification, $translate, $filter) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.MesuresService = MesuresService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.$filter = $filter;
        this.newLigne = false;
    }

    async $onInit() {
        this.dragOptions = {
            placeholder: 'table-row-ui-placeholder',
            axis: 'y',
            animation: 200,
            'ui-floating': true,
            'ui-preserve-size': true
        };
        this.mesure = this.mesure || {};
        if (this.$stateParams.duplicate) {
            await this.getSousMesures(this.$stateParams.id);
            this.modifierSousMesurePrincipale();
        } else {
            if (this.mesure.id) {
                await this.getSousMesures(this.mesure.id);
                const idxSsMesPrcpl = this.mesure.sousMesures.findIndex((x) => x.code === this.mesure.entete.code);
                this.mesure.sousMesures[idxSsMesPrcpl].isDefault = true;
            } else {
                this.mesure.sousMesures = [];
            }
        }
        if (this.mesure.sousMesures && this.mesure.sousMesures.length === 0) {
            this.ajouterLigne();
            this.mesure.sousMesures[0].isDefault = true;
            this.mesure.sousMesures[0].code = this.mesure.entete.code;
            this.mesure.sousMesures[0].libelle = this.mesure.entete.libelle;
        }
        this.loadNatures();
        this.popoverFormatMesure = {
            templateUrl: 'popoverFormatMesure.html'
        };
        this.validPossible = true;

        const allEssais = await this.MesuresService.getEssais(null);
        const indexGRAN = allEssais.findIndex(function (e) {
            return e.code === 'GRAN';
        });
        if (indexGRAN >= 0) {
            allEssais.splice(indexGRAN, 1);
        }
        this.essaisGeneriques = allEssais.filter(function (e) {
            return e.idNature === 10;
        });
        this.essaisNonGeneriques = allEssais.filter(function (e) {
            return e.idNature !== 10;
        });
    }

    modifierSousMesurePrincipale() {
        if (this.mesure.sousMesures) {
            let indice = -1;
            for (let i = 0; i < this.mesure.sousMesures.length; i++) {
                if (this.mesure.sousMesures[i].code + '_copie' == this.mesure.entete.code) {
                    indice = i;
                }
            }
            if (indice > -1) {
                this.mesure.sousMesures[indice].code = this.mesure.entete.code;
                this.mesure.sousMesures[indice].isDefault = true;
            }
        }
    }

    loadNatures() {
        this.natures = [];

        try {
            const natures = this.MesuresService.getNatures();

            angular.forEach(natures, (type, id) => {
                this.natures.push({
                    idNature: parseInt(id),
                    label: this.$translate.instant(`MESURES.NATURES.${id}`)
                });
            });
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    getDomaineCourant() {
        return this.$state.current.name.substring(0, this.$state.current.name.indexOf('.'));
    }

    async getSousMesures(idMesure) {
        this.startLoading();
        try {
            const sousMesuresBDD = await this.MesuresService.getMesureSousMesuresById(idMesure);
            this.mesure.sousMesures = [];
            if (sousMesuresBDD) {
                for (let i = 0; i < sousMesuresBDD.length; i++) {
                    const ssMes = new MesureSousMesures(sousMesuresBDD[i]);
                    this.mesure.sousMesures.push(ssMes);
                }
            }
            this.mesure.sousMesures = this.$filter('orderBy')(this.mesure.sousMesures, 'position', false);
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.stopLoading();
        }
    }

    convertVisibiliteToEntier(sousMesure) {
        if (typeof sousMesure.niveauVisibilite === 'string') {
            sousMesure.niveauVisibilite = parseInt(sousMesure.niveauVisibilite.replace(' ', ''));
        }
    }

    supprimerToutesLignes() {
        this.mesure.sousMesures = this.mesure.sousMesures.filter((x) => x.isDefault);
    }

    supprimerSousMesure(sousMesure) {
        const indice = this.mesure.sousMesures.indexOf(sousMesure);
        this.mesure.sousMesures.splice(indice, 1);
    }

    ajouterLigne() {
        const obj = {
            nature: { idNature: 70 },
            idNature: 70,
            additionnalInfoMesure: new AdditionnalInfoMesure(70),
            obligatoire: false,
            niveauVisibilite: 0
        };
        const sousMesure = new MesureSousMesures(obj);
        this.mesure.sousMesures.push(sousMesure);
    }

    debug(data) {
        console.log(data);
    }

    async changeFormat(sousMesure) {
        /*   sousMesure.additionnalInfoMesure.idNature = sousMesure.nature.idNature;
		   sousMesure.idNature = sousMesure.nature.idNature;*/
        if (sousMesure.nature.idNature === 10) {
            if (sousMesure.code) {
                let essai = null;
                /*if (sousMesure.nature.idNature === 10)*/
                if (sousMesure.idNature === 10) {
                    essai = this.essaisGeneriques.find(function (e) {
                        return e.code.toLowerCase() === sousMesure.code.toLowerCase();
                    });
                }

                if (essai && essai.code !== this.mesure.code) {
                    sousMesure.code = essai.code;
                    sousMesure.libelle = essai.libelle;
                    sousMesure.additionnalInfoMesure.sousMesuresDetail = await this.MesuresService.getMesureSousMesuresByCode(
                        this.mesure.id,
                        sousMesure.code
                    );
                    sousMesure.additionnalInfoMesure.idEssaiInclus = essai.id;
                } else {
                    sousMesure.code = '';
                    sousMesure.libelle = '';
                }
            } else {
                sousMesure.code = '';
                sousMesure.libelle = '';
            }
        }

        sousMesure.additionnalInfoMesure.iterable = sousMesure.additionnalInfoMesure.nbIteration > 1;
    }

    async selectEssai(sousMesure) {
        if (sousMesure && sousMesure.code && sousMesure.code.code !== this.mesure.code) {
            sousMesure.additionnalInfoMesure.idEssaiInclus = sousMesure.code.id;
            sousMesure.libelle = sousMesure.code.libelle;
            sousMesure.code = sousMesure.code.code;
            sousMesure.additionnalInfoMesure.sousMesuresDetail = await this.MesuresService.getMesureSousMesuresByCode(
                this.mesure.id,
                sousMesure.code
            );
            sousMesure.id = -1;
        } else {
            sousMesure.code = '';
            sousMesure.libelle = '';
        }
    }

    async changeEssai(sousMesure, ev) {
        if (sousMesure.idNature === 10) {
            if (ev.target.value) {
                this.essaisFiltered = this.essaisGeneriques.filter(function (e) {
                    return e.codeLibelle.toLowerCase().indexOf(ev.target.value.toLowerCase()) >= 0;
                });
            } else {
                this.essaisFiltered = this.essaisGeneriques;
            }
        }
    }

    initUpdateNature(sousMesure, index) {
        if (!this.edit) {
            //console.log('initUpdateNature',sousMesure, index);
            //if (!this.edit) this.edit = {};
            /*if (!sousMesure.idNature && sousMesure.nature.idNature) {
				sousMesure.idNature = sousMesure.nature.idNature;
			}*/
            this.edit = angular.copy(sousMesure); /*{
                ...this.edit,
                ...angular.copy(sousMesure),
            };*/
            this.clickedPopoverIndex = index;
        }
    }

    /*onUpdateAdditionalInfoMesure(additionnalInfoMesure) {
		if (this.edit != {}) {
			this.edit.additionnalInfoMesure = additionnalInfoMesure;
			this.validPossible = true;
			if (this.edit.additionnalInfoMesure.idNature == 5 || this.edit.additionnalInfoMesure.idNature == 63) {
				let obj = {};
				let nbElt = 0;
				for (var i = 0; i < this.edit.additionnalInfoMesure.elements.length; i++) {
					var elt = this.edit.additionnalInfoMesure.elements[i];
					if (!elt.key || !elt.value) {
						this.validPossible = false;
					}
					if (!obj[elt.key]) {
						obj[elt.key] = 1;
						nbElt++;
					}
				}
				if (nbElt < this.edit.additionnalInfoMesure.elements.length) {
					this.validPossible = false;
				}
			}
		}
	}*/

    closePopOver() {
        this.edit = null;
        this.clickedPopoverIndex = null;
    }

    updateFormat(entete) {
        //console.log('updateFormat',entete);
        this.mesure.sousMesures[this.clickedPopoverIndex].additionnalInfoMesure = entete.additionnalInfoMesure;
        this.edit = undefined;
        const sousMesure = this.mesure.sousMesures[this.clickedPopoverIndex];
        if (sousMesure && sousMesure.additionnalInfoMesure && sousMesure.additionnalInfoMesure.nbIteration) {
            sousMesure.additionnalInfoMesure.iterable = sousMesure.additionnalInfoMesure.nbIteration > 1;
        }
    }

    getTooltipNature(sousMesure) {
        if (sousMesure.idNature && sousMesure.additionnalInfoMesure) {
            if (sousMesure.idNature === 3 || sousMesure.idNature === 62) {
                return this.$translate.instant('MESURES.INFO_NUMERIQUE', {
                    unite: sousMesure.additionnalInfoMesure.nomUnite,
                    decimale: sousMesure.additionnalInfoMesure.decimale
                });
            }
            let res = '';
            for (let i = 0; i < sousMesure.additionnalInfoMesure.elements.length; i++) {
                if (i < 10) {
                    if (i > 0) {
                        res += ', ';
                    }
                    res += sousMesure.additionnalInfoMesure.elements[i].value;
                } else {
                    if (i == 10) {
                        res += '...';
                    }
                }
            }
            return res;
        }
    }

    /** selection valeur par default des listes d'éléments des sous-essais */
    onItemClick(data, model) {
        if (data.key !== model.defaultValue) {
            model.defaultValue = data.key;
        } else {
            const idx = model.additionnalInfoMesure.elements.findIndex((x) => x.key === data.key);
            if (idx > -1) {
                model.additionnalInfoMesure.elements[idx].selected = false;
                model.defaultValue = null;
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
