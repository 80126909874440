const serviceUrl = `${__configuration.apiUrl}/massia/motif-refus-admission`;

export default class MotifRefusAdmissionService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getMotifRefusAdmission(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async deleteMotifRefusAdmissionById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getMotifRefusAdmissionById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createMotifRefusAdmission(selectedMotifRefusAdmission) {
        const data = {
            code: selectedMotifRefusAdmission.code,
            libelle: selectedMotifRefusAdmission.libelle
        };
        const result = await this.$http.post(serviceUrl, data);
        return result.data;
    }

    async updateMotifRefusAdmission(selectedMotifRefusAdmission) {
        const data = {
            id: selectedMotifRefusAdmission.id,
            code: selectedMotifRefusAdmission.code,
            libelle: selectedMotifRefusAdmission.libelle
        };
        const url = `${serviceUrl}/${data.id}`;
        return this.$http.put(url, data);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }
}
