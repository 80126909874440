export default function Configure($stateProvider) {
    $stateProvider.state('associes', {
        parent: 'common',
        url: '/associes',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('associes.list', {
        url: '/list',
        template: '<associes></associes>',
        rights: { domain: 'associes', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "ASSOCIES.BREADCRUMBS.ASSOCIES_LIST" | translate}}'
        },
        // on affiche la liste des associes dans la navigation
        navigation: {
            menu: 'donnees_parametres_donnees',
            translate: 'ASSOCIES.BREADCRUMBS.ASSOCIES_LIST',
            order: 5
        },
        // la liste des associes fait partie des points d'entrée recherchables dans la navigation
        search: 'ASSOCIES.BREADCRUMBS.ASSOCIES_LIST'
    });

    $stateProvider.state('associes.new', {
        url: '/new',
        template: '<associe-form></associe-form>',
        rights: { domain: 'associes', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher associes > ajouter
            parent: 'associes.list',
            label: '{{ "ASSOCIES.BREADCRUMBS.ASSOCIE_NEW" | translate}}'
        },
        // l'ajout d'une associe fait partie des points d'entrée recherchables dans l'application
        search: 'ASSOCIES.BREADCRUMBS.ASSOCIE_NEW'
    });

    $stateProvider.state('associes.edit', {
        url: '/{id}/edit',
        template: '<associe-form></associe-form>',
        rights: { domain: 'associes', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher associes > modifier
            parent: 'associes.list',
            label: '{{ "ASSOCIES.BREADCRUMBS.ASSOCIE_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
