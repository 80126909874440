export default function Configure($stateProvider) {
    $stateProvider.state('clients', {
        parent: 'common',
        url: '/clients',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('clients.list', {
        url: '/:genre/list',
        template: '<sites></sites>',
        rights: { domain: 'siteclient', right: 'read', application: 'gestion;laboratoire' },
        ncyBreadcrumb: {
            label: '{{ "SITES.BREADCRUMBS.SITES_CLIENTS_LIST" | translate}}'
        }
    });

    $stateProvider.state('clients.niveaux', {
        url: '/:genre/niveaux',
        template: '<site-config-niveaux></site-config-niveaux>',
        rights: { domain: 'nivh', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher sites > niveaux
            parent: 'clients.list({genre: "clients"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_NIVEAUX" | translate }}'
        }
    });

    $stateProvider.state('clients.list.detail', {
        url: '/:genre/{id}',
        template: '<site-detail></site-detail>',
        ncyBreadcrumb: {
            parent: 'clients.list({genre: "clients"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('clients.new', {
        url: '/:genre/new',
        template: '<site-form></site-form>',
        rights: { domain: 'siteclient', right: 'create' },
        ncyBreadcrumb: {
            parent: 'clients.list({genre: "clients"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_NEW_CLIENT" | translate}}'
        },
        search: 'SITES.BREADCRUMBS.SITE_NEW_CLIENT'
    });

    $stateProvider.state('clients.edit', {
        url: '/:genre/{id}/edit',
        template: '<site-form></site-form>',
        rights: { domain: 'siteclient', right: 'update' },
        ncyBreadcrumb: {
            parent: 'clients.list({genre: "clients"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_EDIT_CLIENT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
