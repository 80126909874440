import ImagesDownloadController from './images.download.controller';

export default {
    bindings: {
        id: '=',
        domaine: '='
    },
    template: require('./images.download.html'),
    controller: ImagesDownloadController
};
