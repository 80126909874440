export default function Configure($stateProvider) {
    $stateProvider.state('centre-gestion', {
        parent: 'common',
        url: '/centre-gestion',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('centre-gestion.list', {
        url: '/list',
        template: '<export-form></export-form>',
        rights: { domain: 'centregestion', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "CENTRE_GESTION.EXPORT_PARAMETRAGE" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'CENTRE_GESTION.EXPORT_PARAMETRAGE',
            order: 72
        },
        search: 'CENTRE_GESTION.EXPORT_PARAMETRAGE'
    });
}

Configure.$inject = ['$stateProvider'];
