import { Socket } from 'socket.io-client';
export default class PrelevementGestionColonneTamisModalController {
    /* @ngInject */
    constructor(ColonneTamisService, _, notification, SocketService) {
        this.ColonneTamisService = ColonneTamisService;
        this._ = _;
        this.notification = notification;
        this.SocketService = SocketService;
    }

    async $onInit() {
        this.startLoading();
        this.mesuresOriginal = angular.copy(this.resolve.mesure);

        await this.getColonnesTamis();

        this.setTamisDisabled();
        this.stopLoading();
    }

    $onDestroy() {}

    async getColonnesTamis() {
        const data = await this.ColonneTamisService.getAll([], [], { skip: 0, take: 100000 });
        this.colonneTamis = data.items
            .filter((x) => x.laboratoire.id === this.resolve.prelevement.auditeur.id)
            .sort((a, b) => a.tamis.ouverture - b.tamis.ouverture);
        // si un laboratoire est present et mis a default dans les colonnes disponible on le selectionne
        if (
            this.resolve.prelevement &&
            this.resolve.prelevement.auditeur &&
            this.resolve.prelevement.auditeur &&
            this.colonneTamis &&
            this.colonneTamis.length > 0
        ) {
            if (this.resolve.colonneSelected) {
                // this.colonneTamisSelected = this.colonneTamis.find(x=>x.id == this.resolve.colonneSelected);
                this.colonneTamis = this.colonneTamis.map((x) => {
                    if (x.id === this.resolve.colonneSelected) {
                        x.selected = true;
                        this.colonneTamisSelected = x;
                    } else {
                        x.selected = false;
                    }
                    return x;
                });
            } else {
                this.colonneTamis = this.colonneTamis.map((x) => {
                    if (x.laboratoire.id === this.resolve.prelevement.auditeur.id && x.isDefault) {
                        x.selected = true;
                        this.colonneTamisSelected = x;
                    }
                    return x;
                });
            }
        }
    }

    selectItem(data, list) {
        if (data) {
            if (!this.colonneTamisSelected || data.id !== this.colonneTamisSelected.id) {
                this.colonneTamisSelected = data;
                this.setTamisDisabled();
            } else {
                list = list.map((e) => (e.selected = false));
                this.colonneTamisSelected = null;
            }
        }
    }

    setTamisDisabled() {
        if (
            this.colonneTamisSelected &&
            this.colonneTamisSelected.tamis &&
            this.colonneTamisSelected.tamis.length > 0 &&
            this.resolve.mesure &&
            this.resolve.mesure.granuloAdditionalInformation &&
            this.resolve.mesure.granuloAdditionalInformation.tamis &&
            this.resolve.mesure.granuloAdditionalInformation.tamis.length > 0
        ) {
            this._.forEach(this.colonneTamisSelected.tamis, (item) => {
                const tamisCourant = this.resolve.mesure.granuloAdditionalInformation.tamis.findIndex(
                    (x) => x.ouverture === item.tamis.ouverture && x.isDisplay
                );

                if (tamisCourant > -1) {
                    item.enabled = true;
                    if (
                        this.resolve.mesure.granuloAdditionalInformation.tamis[tamisCourant].refus &&
                        typeof this.resolve.mesure.granuloAdditionalInformation.tamis[tamisCourant].refus === 'number'
                    ) {
                        item.refus = this.resolve.mesure.granuloAdditionalInformation.tamis[tamisCourant].refus.toFixed(2);
                        item.poidsBrut = Number.parseFloat(item.refus) + Number.parseFloat(item.tare);
                    } else {
                        item.refus = null;
                        item.poidsBrut = null;
                    }
                }
            });
        }
    }

    setPoidsBrutRefusPartiel(item) {
        if (item && item.poidsBrut && typeof item.poidsBrut === 'number') {
            const idxItemCourant = this.resolve.mesure.granuloAdditionalInformation.tamis.findIndex(
                (x) => x.ouverture === item.tamis.ouverture && x.isDisplay
            );
            if (idxItemCourant > -1) {
                item.asChanged = true;
                item.newRefus = item.poidsBrut - item.tare;
                if (item.newRefus != item.refus) {
                    item.refus = item.newRefus.toFixed(2);
                }
            }
        }
    }

    setTareColonne(item) {
        if (item && typeof item.tare === 'number') {
            this.colonneTamisSelected.hasChanged = true;
        }
    }

    async ok() {
        let msg = false;
        if (this.colonneTamisSelected && this.colonneTamisSelected.hasChanged) {
            const id = await this.ColonneTamisService.update(this.colonneTamisSelected);
            if (id && id > 0) {
                msg = true;
            }
        }

        this.close({ $value: { colonne: this.colonneTamisSelected, message: msg } });
    }

    close() {
        this.resolve.mesure = angular.copy(this.mesuresOriginal);
        this.modalInstance.dismiss(this.resolve.mesure);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    getPoids(item) {
        /**
         * @type {Socket}
         */
        const socket = this.SocketService.getSocket();
        if (!socket.connected) {
            this.notification.warning('CONFIGURATION_BALANCE.EVENT.NO_SERVICE');
            return;
        }
        if (this.resolve.configurationBalance) {
            socket.on('receive com poids', (poids) => {
                if (poids && poids !== undefined && !isNaN(poids)) {
                    let poidsDouble = Number(poids);
                    if (isNaN(poidsDouble)) {
                        poidsDouble = poids.includes('.')
                            ? Number(String.replace(poids.toString(), '.', ','))
                            : Number(String.replace(poids.toString(), ',', '.'));
                    }
                    if (!isNaN(poidsDouble)) {
                        item.poidsBrut = poidsDouble;
                        item.asChanged = true;
                    } else {
                        this.notification.warning('CONFIGURATION_BALANCE.EVENT.ERROR_POIDS');
                    }
                } else {
                    this.notification.warning('CONFIGURATION_BALANCE.EVENT.ERROR_POIDS');
                }
            });

            socket.on('com err', (err) => {
                this.notification.warning(err);
            });

            socket.emit('connect com', this.resolve.configurationBalance);
        } else {
            this.notification.warning('CONFIGURATION_BALANCE.EVENT.NO_BALANCE');
        }
    }
}
