import SiteIndicateur from './site.indicateur.model';

export default class SiteIndicateurController {
    static $inject = ['$stateParams', 'SitesService', 'notification', '_', 'moment', '$uibModal', 'globalizationManagementService'];

    constructor($stateParams, SitesService, notification, _, moment, $uibModal, globalizationManagementService) {
        this.$stateParams = $stateParams;
        this.SitesService = SitesService;
        this.notification = notification;
        this._ = _;
        this.moment = moment;
        this.$uibModal = $uibModal;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    async $onInit() {
        this.loading = false;
        this.isEditMode = false;
        this.initForm();
    }

    $onDestroy() {}

    initForm() {}

    archiver() {
        const _this = this;
        this._.each(this.site.indicateur.objectifsIndicateurs, function (indicateur) {
            if (indicateur.objectifCourant && indicateur.objectifCourant.estActuel && indicateur.objectifCourant.date) {
                indicateur.objectifsArchives.push(angular.copy(indicateur.objectifCourant));
                indicateur.objectifCourant = {
                    id: null,
                    date: _this.moment().format(_this.dateFormat),
                    valeur: null,
                    estActuel: false
                };
            }
        });
        this.notification.success('SITES.ARCHIVES_OK');
    }

    afficheArchives(indicateur) {
        indicateur.objectifsArchives = this._.sortBy(indicateur.objectifsArchives, 'date').reverse();
        const modalInstance = this.$uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'voirArchivesModalContent',
            controllerAs: '$ctrl',
            controller: [
                '$uibModalInstance',
                '_',
                function ($uibModalInstance, _) {
                    this.objectifs = indicateur.objectifsArchives;
                    this.nomIndicateur = indicateur.libelle;
                    this.$uibModalInstance = $uibModalInstance;
                    this._ = _;

                    this.onDelete = function (id) {
                        const idx = this._.findIndex(this.objectifs, { id: id.id });
                        if (idx > -1) {
                            this.objectifs.splice(idx, 1);
                        }
                    };

                    this.onDeleteAll = function () {
                        this.objectifs = [];
                    };

                    this.ok = function () {
                        this.$uibModalInstance.close(this.objectifs);
                    };
                    this.cancel = function () {
                        this.$uibModalInstance.close();
                    };
                }
            ]
        });

        modalInstance.result.then(function (result) {
            if (result) {
                indicateur.objectifsArchives = result;
            }
        });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
