import { getNatureNamesByValues } from './natures.enum';
import { getPositionLegendeNamesByValues } from './positions.legende.enum';
import { getStyleEchelleNamesByValues } from './styles.echelle.enum';
import { getStyleTraceNamesByValues } from './styles.trace.enum';
import { getFormePointNamesByValues } from './formes.point.enum';
import { getStyleFuseauNamesByValues } from './styles.fuseau.enum';

const serviceUrl = `${__configuration.apiUrl}/massia/mesures`;

export default class MesuresService {
    static $inject = ['$http', 'ColorPickerService', '$translate'];

    constructor($http, ColorPickerService, $translate) {
        this.$http = $http;
        this.ColorPickerService = ColorPickerService;
        this.$translate = $translate;
        this.getUrlUnites = () => `${__configuration.apiUrl}/massia/unites-list`;
        this.getNatures = () => getNatureNamesByValues();
        this.getPositionsLegende = () => getPositionLegendeNamesByValues();
        this.getStylesEchelle = () => getStyleEchelleNamesByValues();
        this.getStylesTrace = () => getStyleTraceNamesByValues();
        this.getFormesPoint = () => getFormePointNamesByValues();
        this.getStylesFuseau = () => getStyleFuseauNamesByValues();
    }

    async getMesures(filters, sorts, pagination, domaine) {
        let dom = domaine;
        if (dom == 'mesctrl') {
            dom = 'controles';
        }
        const res = await this.$http.get(serviceUrl, {
            params: {
                domaine: JSON.stringify(dom || {}),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getMesuresList(domaine, typeMesure, typeFiltreTheme, typeProduit) {
        let dom = domaine;
        if (dom == 'mesctrl') {
            dom = 'controles';
        }
        const url = `${__configuration.apiUrl}/massia/mesures-list`;
        const res = await this.$http.get(url, {
            params: {
                domaine: JSON.stringify(dom || {}),
                idTypeMesure: JSON.stringify(typeMesure || 0),
                idTypeFiltreTheme: JSON.stringify(typeFiltreTheme || 0),
                idTypeProduit: JSON.stringify(typeProduit || 0)
            }
        });
        return res.data;
    }

    async getTamisList(idMesure) {
        const url = `${__configuration.apiUrl}/massia/tamis-list`;
        const res = await this.$http.get(url, {
            params: {
                idMesure: JSON.stringify(idMesure || 0)
            }
        });
        return res.data;
    }

    async getSousMesuresList(domaine, typeMesure, typeFiltreTheme, idFamille) {
        let dom = domaine;
        if (dom === 'mesctrl') {
            dom = 'controles';
        }
        const url = `${__configuration.apiUrl}/massia/sous-mesures-list`;
        const res = await this.$http.get(url, {
            params: {
                domaine: JSON.stringify(dom || {}),
                idTypeMesure: JSON.stringify(typeMesure || 0),
                idTypeFiltreTheme: JSON.stringify(typeFiltreTheme || 0),
                idFamille: JSON.stringify(idFamille || 0)
            }
        });
        return res.data;
    }

    async getSousMesuresSimplyList(domaine, codeFamille) {
        let dom = domaine;
        if (dom === 'mesctrl') {
            dom = 'controles';
        }
        const url = `${__configuration.apiUrl}/massia/sous-mesures-list`;
        const res = await this.$http.get(url, {
            params: {
                domaine: JSON.stringify(dom || {}),
                codeFamille: JSON.stringify(codeFamille || '')
            }
        });
        return res.data;
    }

    async deleteMesureById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getMesureEnteteById(id) {
        const url = `${serviceUrl}/${id}/entete`;
        const res = await this.$http.get(url);
        res.data.idNature = res.data.nature.idNature;
        return res.data;
    }

    async getMesureSousMesuresById(id) {
        const url = `${serviceUrl}/${id}/sous-mesures`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getMesureSousMesuresByCode(id, code) {
        const url = `${serviceUrl}/${id}/sous-mesures/${code}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getMesureCourbeGranuloById(id) {
        const url = `${serviceUrl}/${id}/courbe-granulo`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getMesureTypesProduitsById(id) {
        const url = `${serviceUrl}/${id}/types-produits`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getMesureValeursCaracteristiquesAssociationsById(id) {
        const data = await this.getMesureValeursCaracteristiquesById(id);
        const result = {};

        for (const element in data) {
            if (data[element] && !Array.isArray(data[element])) {
                if (data[element].value === 'True') {
                    data[element].value = 'true';
                }
                if (data[element].value === 'False') {
                    data[element].value = 'false';
                }

                if (data[element].value && data[element].value.key) {
                    result[data[element].id] = data[element].value.key;
                } else {
                    result[data[element].id] = data[element].value;
                }
            }
        }
        return result;
    }

    async getMesureValeursCaracteristiquesById(id) {
        const url = `${serviceUrl}/${id}/valeurs-caracteristiques`;
        const res = await this.$http.get(url);
        const result = [];
        res.data.forEach(function (element) {
            const caract = {
                id: element.id,
                code: element.code,
                idNature: element.idNature,
                indice: element.indice,
                label: element.label,
                numericAdditionalInformation: element.numericAdditionalInformation,
                value: element.elementsValues || element.elementValue || element.values || element.value
            };
            result.push(caract);
        });
        return result;
    }

    async createMesure(mesure) {
        const data = {
            code: mesure.entete.code,
            libelle: mesure.entete.libelle,
            type: mesure.entete.typeId,
            symboleNorme: mesure.entete.symboleNorme,
            norme: mesure.entete.norme,
            visibiliteImpression: mesure.entete.visibiliteImpression,
            visibiliteSaisie: mesure.entete.visibiliteSaisie,
            visibiliteStat: mesure.entete.visibiliteStat,
            formulePlanification: mesure.entete.formulePlanification,
            idTypeAide: mesure.entete.idTypeAide,
            aideUrl: mesure.entete.aideUrl,
            aideToolType: mesure.entete.aideToolType,
            document: mesure.entete.document,
            caracteristiques: []
        };
        data.sousMesures = this.formatSousMesures(mesure.sousMesures, mesure);
        data.caracteristiques = this.formatCaracteristiques(mesure.caracteristiques);
        data.courbeGranulo = mesure.courbeGranulo;
        data.typesProduits = mesure.typesProduits;
        const result = await this.$http.post(serviceUrl, data);
        return result.data;
    }

    formatSousMesures(sousMesures, mesure = null) {
        const data = [];
        const idxSousMesurePrincipale = sousMesures.find((x) => x.code === mesure.entete.code);
        for (let i = 0; i < sousMesures.length; i++) {
            const ssMes = sousMesures[i];
            if (mesure && mesure.entete && mesure.entete.additionnalInfoMesure && i === idxSousMesurePrincipale) {
                if (ssMes.additionnalInfoMesure) {
                    ssMes.additionnalInfoMesure.decimale = mesure.entete.additionnalInfoMesure.decimale;
                    ssMes.additionnalInfoMesure.grandeurMaximum = mesure.entete.additionnalInfoMesure.grandeurMaximum;
                    ssMes.additionnalInfoMesure.grandeurMinimum = mesure.entete.additionnalInfoMesure.grandeurMinimum;
                    ssMes.additionnalInfoMesure.idUnite = mesure.entete.additionnalInfoMesure.idUnite;
                }
            }
            if (ssMes.nature) {
                ssMes.nature.idNature = ssMes.idNature;
            } else {
                ssMes.nature = { idNature: ssMes.idNature };
            }
            data.push(ssMes);
        }
        return data;
    }

    formatCaracteristiques(caracteristiques) {
        const data = [];
        for (const property in caracteristiques) {
            let keyValueObject;
            //On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variables interne Js.
            if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
                const propertyVal = caracteristiques[property];
                if (Array.isArray(propertyVal)) {
                    if (_.some(propertyVal, 'key')) {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: _.map(propertyVal, 'key')
                        };
                    } else {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: propertyVal
                        };
                    }
                } else {
                    keyValueObject = {
                        IdCaracteristique: parseInt(property),
                        valeur: propertyVal
                    };
                }
                data.push(keyValueObject);
            }
        }
        return data;
    }

    async updateMesure(mesure) {
        const data = {
            id: mesure.id,
            libelle: mesure.entete.libelle,
            type: mesure.entete.typeId,
            symboleNorme: mesure.entete.symboleNorme,
            norme: mesure.entete.norme,
            visibiliteImpression: mesure.entete.visibiliteImpression,
            visibiliteSaisie: mesure.entete.visibiliteSaisie,
            visibiliteStat: mesure.entete.visibiliteStat,
            formulePlanification: mesure.entete.formulePlanification,
            formule: mesure.entete.formule,
            texteNonConforme: mesure.entete.texteNonConforme,
            idTypeAide: mesure.entete.idTypeAide,
            aideUrl: mesure.entete.aideUrl,
            aideToolType: mesure.entete.aideToolType,
            document: mesure.entete.document
        };
        data.caracteristiques = [];
        data.caracteristiques = this.formatCaracteristiques(mesure.caracteristiques);
        data.sousMesures = this.formatSousMesures(mesure.sousMesures, mesure);
        data.courbeGranulo = this.formatCourbeGranulo(mesure.courbeGranulo);
        data.typesProduits = mesure.typesProduits;
        const url = `${serviceUrl}/${mesure.id}`;
        return await this.$http.put(url, data);
    }

    formatCourbeGranulo(courbeGranulo) {
        const data = angular.copy(courbeGranulo);
        if (courbeGranulo && courbeGranulo.listeIndicesReferences) {
            data.listeIndicesReferences = [];
            for (let i = 0; i < courbeGranulo.listeIndicesReferences.length; i++) {
                const obj = { indice: courbeGranulo.listeIndicesReferences[i].indice, listeTracesReferences: [] };
                for (let j = 0; j < courbeGranulo.listeIndicesReferences[i].listeTracesReferences.length; j++) {
                    const trace = courbeGranulo.listeIndicesReferences[i].listeTracesReferences[j];
                    if (trace.idCalculStat) {
                        trace.indice = obj.indice;
                        obj.listeTracesReferences.push(trace);
                    }
                }
                data.listeIndicesReferences.push(obj);
            }
        }
        return data;
    }

    /*getColorFromPicker(coul) {
        if (coul) {
            let hexa = this.ColorPickerService.hslToRgb(coul.hue, coul.saturation, coul.luminosity);
            return 'rgb(' + hexa.red + ',' + hexa.green + ',' + hexa.blue + ')';
        }
        else
            return 'rgb(0,0,0)';
    }*/

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getUnites() {
        const url = `${this.getUrlUnites()}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getCalculsStatsResultats() {
        const url = `${__configuration.apiUrl}/massia/calculs-statistiques-list/resultat-granulo`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getCalculsStatsReferences() {
        const url = `${__configuration.apiUrl}/massia/calculs-statistiques-list/references`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSeriesTamis() {
        const url = `${__configuration.apiUrl}/massia/seriestamis/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getTamis(idSerie) {
        const url = `${__configuration.apiUrl}/massia/seriestamis/${idSerie}/entete`;
        const res = await this.$http.get(url);
        if (res.data) {
            return res.data.tamis;
        }
        return [];
    }

    async exportListeMesures(domaine, modeImpr, filters, sorts) {
        const url = `${serviceUrl}/print`;
        let dom = domaine;
        if (dom == 'mesctrl') {
            dom = 'controles';
        }
        const res = await this.$http.get(url, {
            params: {
                modeImpr: JSON.stringify(modeImpr),
                domaine: JSON.stringify(dom),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || [])
            },
            responseType: 'arraybuffer'
        });
        return res;
    }

    async getEssais(valeur) {
        const url = `${serviceUrl}/typeahead/${valeur}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    traduitSymbole(symbole) {
        if (symbole) {
            let symboleTraduit = '';
            let symboleATraduire = '';
            let encoursTraduction = false;
            for (let i = 0; i < symbole.length; i++) {
                if (symbole[i] === '[' && !encoursTraduction) {
                    encoursTraduction = true;
                } else {
                    if (symbole[i] === ']' && encoursTraduction) {
                        symboleTraduit += symboleATraduire;
                        symboleATraduire = '';
                        encoursTraduction = false;
                    } else {
                        if (!encoursTraduction) {
                            symboleTraduit += symbole[i];
                        } else {
                            symboleATraduire += this.traduitGrec(symbole[i]);
                        }
                    }
                }
            }
            return symboleTraduit;
        }
        return '';
    }

    traduitGrec(lettre) {
        switch (lettre) {
            case 'a':
                return '\u03b1';
            case 'b':
                return '\u03b2';
            case 'g':
                return '\u03b3';
            case 'd':
                return '\u03b4';
            case 'e':
                return '\u03b5';
            case 'z':
                return '\u03b6';
            case 'h':
                return '\u03b7';
            case 'q':
                return '\u03b8';
            case 'i':
                return '\u2129'; //'\u03b9';
            case 'k':
                return '\u03ba';
            case 'l':
                return '\u03bb';
            case 'm':
                return '\u03bc';
            case 'n':
                return '\u03bd';
            case 'x':
                return '\u03be';
            case 'o':
                return '\u03bf';
            case 'p':
                return '\u03c0';
            case 'r':
                return '\u03c1';
            case 's':
                return '\u03c3';
            case 't':
                return '\u03c4';
            case 'u':
                return '\u03c5';
            case 'j':
                return '\u03c6';
            case 'c':
                return '\u03c7';
            case 'y':
                return '\u03c8';
            case 'w':
                return '\u03c9';
            case 'f':
                return '\u03a6';
            /*case 'f':
        case 'v':*/

            case 'A':
                return '\u0391';
            case 'B':
                return '\u0392';
            case 'G':
                return '\u0393';
            case 'D':
                return '\u0394';
            case 'E':
                return '\u0395';
            case 'Z':
                return '\u0396';
            case 'H':
                return '\u0397';
            case 'Q':
                return '\u0398';
            case 'I':
                return '\u0399';
            case 'K':
                return '\u039a';
            case 'L':
                return '\u039b';
            case 'M':
                return '\u039c';
            case 'N':
                return '\u039d';
            case 'X':
                return '\u039e';
            case 'O':
                return '\u039f';
            case 'P':
                return '\u03a0';
            case 'R':
                return '\u03a1';
            case 'S':
                return '\u03a3';
            case 'T':
                return '\u03a4';
            case 'U':
                return '\u03a5';
            /*case 'F':
                return '\u03a6';*/
            case 'C':
                return '\u03a7';
            case 'Y':
                return '\u03a8';
            case 'W':
                return '\u03a9';
            case 'V':
                return '\u03c2';
            /*case 'J':*/

            default:
                return lettre;
        }
    }

    async getAllCertificatTypeEssai() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/laboratoire/certificat?typeCertif=2`);
        return res.data.items;
    }

    async getAllCertificatTypeEssaiByLaboratoire(labo) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/laboratoire/certificat?siteCertifie=${labo.id}&typeCertif=2`);
        return res.data.items;
    }

    async getLaboratoires() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/laboratoire/certificat-essai/laboratoires`);
        return res.data;
    }

    async getCertificatsEssai(idEssai) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/laboratoire/certificat-essai?essai=${idEssai}`);
        return res.data;
    }

    async manageCertificats(mesure) {
        const res = await this.$http.post(`${__configuration.apiUrl}/massia/laboratoire/certificat-essai/${mesure.id}`, mesure.certifications);
        return res.data;
    }

    async getSourcesNbEchantillonsList() {
        const data = await this.$http.get('assets/enum/mesures/source.nb.echantillon.enum.json');
        return data.data.map((x) => {
            x.libelle = this.$translate.instant(x.translate);
            return x;
        });
    }
}
