export default function MassiaRoutes($stateProvider) {
    $stateProvider.state('common', {
        parent: 'massia',
        url: '',
        abstract: true,
        views: {
            header: {
                template: '<massia-header></massia-header>'
            },
            main: {
                template: '<div ui-view="common" class="mas-h-100"></div>'
            }
        },
        ncyBreadcrumb: {
            skip: true
        }
    });
}

MassiaRoutes.$inject = ['$stateProvider', 'MassiaApplicationProvider'];
