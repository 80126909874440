import NormesConfig from './_config/normes.config';
import NormesRoutes from './_config/normes.routes';
import NormesLaboRoutes from './_config/normes.labo.routes';

import NormesComponent from './components/normes';
import NormeFormComponent from './components/norme-form';
import NormeSpecificationsComponent from './components/norme-form/norme-specifications';
import NormeDetailComponent from './components/norme-detail';
import NormeDetailSpecificationsComponent from './components/norme-detail/norme-detail-specifications';

import NormesService from './services/normes.service';
import NormesCommunicationService from './services/normes.communication.service';

import NormeValidator from './components/norme-form/norme.validator';

const moduleName = 'app.massia.common.normes';

angular
    .module(moduleName, [])
    .config(NormesConfig)
    .config(NormesRoutes)
    .config(NormesLaboRoutes)
    .service('NormesService', NormesService)
    .service('NormesCommunicationService', NormesCommunicationService)
    .component('normes', NormesComponent)
    .component('normeForm', NormeFormComponent)
    .component('normeSpecifications', NormeSpecificationsComponent)
    .component('normeDetail', NormeDetailComponent)
    .component('normeDetailSpecifications', NormeDetailSpecificationsComponent)
    .factory('NormeValidator', NormeValidator);

export default moduleName;
