import ProspectFormController from './prospect.form.controller';

export default {
    bindings: {
        modalInstance: '=',
        idSociete: '='
    }, // récupérer la modal venant de l'offre
    template: require('./prospect.form.html'),
    controller: ProspectFormController
};
