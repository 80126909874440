export default function Configure($stateProvider) {
    $stateProvider.state('seuilctrl', {
        parent: 'common',
        url: '/seuilctrl',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.SECURITE_MATERIEL" | translate }}'
        }
    });

    $stateProvider.state('seuilctrl.list', {
        url: '/list',
        template: '<references-controle></references-controle>',
        rights: { domain: 'seuilctrl', right: 'read' },
        ncyBreadcrumb: {
            parent: 'seuilctrl',
            label: '{{ "REFERENCES.BREADCRUMBS.REFERENCES_LIST" | translate}}'
        },
        navigation: {
            menu: 'securite_materiel_donnees',
            translate: 'REFERENCES.BREADCRUMBS.REFERENCES_LIST',
            order: 6
        },
        search: 'REFERENCES.BREADCRUMBS.REFERENCES_LIST'
    });

    $stateProvider.state('seuilctrl.list.detail', {
        url: '/{id}',
        template: '<reference-controle-detail></reference-controle-detail>',
        rights: { domain: 'seuilctrl', right: 'read' },
        ncyBreadcrumb: {
            parent: 'seuilctrl.list',
            label: '{{ "REFERENCES.BREADCRUMBS.REFERENCE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('seuilctrl.new', {
        url: '/new',
        template: '<reference-controle-form></reference-controle-form>',
        rights: { domain: 'seuilctrl', right: 'create' },
        ncyBreadcrumb: {
            parent: 'seuilctrl.list',
            label: '{{ "REFERENCES.BREADCRUMBS.REFERENCE_NEW" | translate}}'
        },
        search: 'REFERENCES.BREADCRUMBS.REFERENCE_NEW'
    });

    $stateProvider.state('seuilctrl.edit', {
        url: '/{id}/edit',
        template: '<reference-controle-form></reference-controle-form>',
        rights: { domain: 'seuilctrl', right: 'update' },
        ncyBreadcrumb: {
            parent: 'seuilctrl.list',
            label: '{{ "REFERENCES.BREADCRUMBS.REFERENCE_EDIT" | translate }}'
        }
    });

    $stateProvider.state('seuilctrl.newType', {
        url: '/new-type',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        params: { parentState: 'seuilctrl.new' },
        ncyBreadcrumb: {
            parent: 'seuilctrl.new',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });

    $stateProvider.state('seuilctrl.duplicate', {
        url: '/{id}/duplicate',
        template: '<reference-controle-form></reference-controle-form>',
        rights: { domain: 'seuilctrl', right: 'create' },
        ncyBreadcrumb: {
            parent: 'seuilctrl.list',
            label: '{{ "REFERENCES.BREADCRUMBS.REFERENCE_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });
}

Configure.$inject = ['$stateProvider'];
