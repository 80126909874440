DomainesDetailController.$inject = ['DomainesService', 'MassiaApplicationService', 'PaginationService', 'notification', '_'];

export default function DomainesDetailController(DomainesService, MassiaApplicationService, PaginationService, notification, _) {
    /* jshint validthis: true */
    const vm = this;
    let previousTableState;

    // Propriétés
    vm.loading = true;
    vm.droits = [];
    vm.domaines = [];

    // Méthodes
    vm.loadDomaines = loadDomaines;

    // Evènements
    vm.$onInit = onInit;

    // Méthodes publiques
    function onInit() {
        vm.loading = false;
        vm.displayDroits = vm.displayDroits !== false;

        if (vm.displayDroits !== false) {
            vm.droits = DomainesService.getDroits();
        }

        vm.onLoad = vm.onLoad || angular.noop;
    }

    async function loadDomaines(tableState) {
        startLoading();

        previousTableState = tableState || previousTableState;
        try {
            const data = await vm.onLoad({
                filters: PaginationService.getFilters(previousTableState),
                sorts: PaginationService.getSorts(previousTableState),
                pagination: PaginationService.getPagination(previousTableState),
                appName: MassiaApplicationService.getApplication()
            });
            vm.domaines = data.items;

            PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    // Méthodes privées
    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }
}
