export default class RemiseFinAnneeDetailController {
    static $inject = ['$stateParams', '$state', 'RemisesFinAnneeService', 'notification'];

    constructor($stateParams, $state, RemisesFinAnneeService, notification) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.RemisesFinAnneeService = RemisesFinAnneeService;
        this.notification = notification;
    }

    $onInit() {
        this.loading = false;
        this.remiseFinAnnee = {};
        this.remiseFinAnneeId = undefined;
        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.initRemiseFinAnnee();
    }

    async initRemiseFinAnnee() {
        this.remiseFinAnneeId = this.$stateParams.id;

        this.startLoading();
        try {
            await this.getRemiseFinAnnee(this.remiseFinAnneeId);
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('remises-fin-annee.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getRemiseFinAnnee(remiseFinAnneeId) {
        this.remiseFinAnnee = await this.RemisesFinAnneeService.getRemiseFinAnneeById(remiseFinAnneeId);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
