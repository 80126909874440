export default class ProduitFormProducteursController {
	/* @ngInject */
	constructor(
		$scope,
		ProduitsService,
		notification,
		_,
		$timeout,
		moment,
		$translate,
		MassiaApplicationService,
		globalizationManagementService,
		$state,
		ModalService,
		FormuleService,
		ProductService
	) {
		this.$scope = $scope;
		this.ProduitsService = ProduitsService;
		this.notification = notification;
		this._ = _;
		this.$timeout = $timeout;
		this.moment = moment;
		this.$translate = $translate;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$state = $state;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
		this.ModalService = ModalService;
		this.FormuleService = FormuleService;
		this.activeProducteur = true;
		this.ProductService = ProductService;
	}
	producteurs;
	produit;

	async $onInit() {
		this.certifications = [];
		if (this.produit.id) {
			this.loadCertification(this.produit.id);
		}
		this.input = [];
	}

	$onDestroy() {
		//this.unregister();
	}

	async loadCertification(id) {
		try {
			const certifications = await this.ProduitsService.getCertification(id);
			this.certifications = certifications;
			//console.log(certifications)
			this.checkCertif(certifications);
		} catch (err) {
			console.error(err);
		}
	}

	checkCertif(certifications) {
		if (this.produit.producteurs) {
			this._.forEach(this.produit.producteurs, (producteur) => {
				const certif = certifications.find((c) => c.idProducteur === producteur.id);
				if (typeof certif !== 'undefined') {
					producteur.certified = certif.id;
				}
			});
		}
	}

	canUpdateOrCreateFormule(producteur) {
		if (this.produit.id) {
			const idx = this.produit.formules.findIndex((x) => x.producteur.id === producteur.id);
			if (idx > -1) {
				return 1; // Update possible
			}
			return 2; // Create possible
		}
		return 0; // Création de produit
	}

	openVRE(producteur) {
		producteur.openCp = false;
		if (producteur.open !== undefined) {
			producteur.open = !producteur.open;
		} else {
			producteur.open = true;
		}

		producteur.startDate = this.moment().format(this.dateFormat);
	}

	openCP(producteur) {
		producteur.open = false;
		if (producteur.openCp !== undefined) {
			producteur.openCp = !producteur.openCp;
		} else {
			producteur.openCp = true;
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	async changeActiveStatus(producteur, checked) {
		this.startLoading();
		try {
			producteur.isActivated = checked;

			if (producteur.isActivated) {
				const that = this;
				const modalInstance = this.ModalService.confirm({
					modalTitle: that.$translate.instant('PRODUITS.DESACTIVE.TITLE_2'),
					modalMsg: that.$translate.instant('PRODUITS.DESACTIVE.MESSAGE_2'),
					headerClass: 'modal-danger'
				});

				await modalInstance.result;
				await that.ProduitsService.activeProduitProducteur(that.produit.id, producteur.id);
				that.notification.success('PRODUITS.DESACTIVE.SUCCESS_REACTIVE_2');
				//raffraichir les CC/MD réactivés
				//SP 15/02/21 pas beau mais je ne sais pas comment recharger la page sinon
				const produit = await that.ProductService.getOne(that.produit.id, {
					params: {
						application: that.app
					}
				});
				//console.log(produit);
				that.produit = produit; //<CreateUpdateProduit>

				/**/
			} else {
				const that = this;
				const modalInstance = this.ModalService.confirm({
					modalTitle: that.$translate.instant('PRODUITS.DESACTIVE.TITLE_3'),
					modalMsg: that.$translate.instant('PRODUITS.DESACTIVE.MESSAGE_3'),
					headerClass: 'modal-danger'
				});

				modalInstance.result.then(
					async function () {
						await that.ProduitsService.desactiveProduitProducteur(that.produit.id, producteur.id);
						that.notification.success('PRODUITS.DESACTIVE.SUCCESS_DESACTIVE_2');
					},
					async function () {
						producteur.isActivated = !checked;
					}
				);
			}
		} catch (ex) {
			console.log('catch', ex);
			producteur.isActivated = !checked;
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async changeAllActiveStatus(checked) {
		this.produit.isActivated = checked;

		if (this.produit.isActivated) {
			await this.ProduitsService.activeProduit(this.produit.id);
			this.notification.success('PRODUITS.ACTIVED');
		} else {
			await this.ProduitsService.desactiveProduit(this.produit.id);
			this.notification.success('PRODUITS.DESACTIVED');
		}

		// if (this.produit.producteurs) {
		//     if (this.produit.isActivated) {
		//         this._.forEach(this.produit.producteurs, producteur => {
		//             this.ProduitsService.activeProduitProducteur(this.produit.id, producteur.id);
		//         });

		//         this.notification.success('PRODUITS.ACTIVED');
		//     }
		//     else {
		//         this._.forEach(this.produit.producteurs, producteur => {
		//             this.ProduitsService.desactiveProduitProducteur(this.produit.id, producteur.id);
		//         });

		//         this.notification.success('PRODUITS.DESACTIVED');
		//     }
		// }
	}

	redirectToProducteur(producteur) {
		this.$state.go('producteurs.edit', { id: producteur.id, genre: 'producteurs' });
	}

	async deleteFormule(index) {
		const that = this;

		const modalInstance = this.ModalService.confirm({
			modalTitle: this.$translate.instant('FORMULES.DELETE.TITLE'),
			modalMsg: this.$translate.instant('FORMULES.DELETE.MESSAGE'),
			headerClass: 'modal-danger'
		});

		modalInstance.result.then(async function () {
			that.startLoading();
			try {
				await that.ProduitsService.deleteProduitFormules(that.produit.id, that.produit.producteurs[index].id);
				const idxFormule = that.produit.formules.findIndex((x) => x.producteur.id === that.producteurs[index].id);
				that.formules.splice(idxFormule, 1);
				that.produit.formules.find((x) => x.producteur.id === that.producteurs[index].id).hasToBeCreated = true;
			} catch (ex) {
				that.notification.error(ex.data);
			} finally {
				that.stopLoading();
			}
		});
	}

	/********************************* Graphique ************************************/
	async afficheCourbe(producteur) {
		producteur.openChart = false;
		if (this.isReady) {
			this.isReady = false;
			this.chart = null;
		} else {
			this.isReady = false;
			if (!this.chart) {
				this.chart = this.createChartOptions('graphGran');
			} else {
				this.chart = null;
				this.chart = this.createChartOptions('graphGran');
			}

			const tableauGranulat = [];
			let count = 0;
			const composant = {};
			composant.idComposant = count;
			composant.libelle = this.produit.libelle;
			composant.idElement = this.produit.id; // idProduit
			composant.idProducteur = producteur.id; // idProducteur
			try {
				const tab = await this.getFtpArchiveeparComposant(composant, count);
				// on verifie qu'on a recupere un ftpA pour le composant en cours
				if (Object.keys(tab).length > 0) {
					tableauGranulat.push(tab);
					// recuperation des data pour la courbe
					this.recuperationDataChart(tableauGranulat, count);
					count += 1;
					this.isReady = true;
					producteur.openChart = true;
				} else {
					producteur.openChart = false;
				}
			} catch (error) {
				this.notification.error(error.data);
			}
		}
	}

	async getFtpArchiveeparComposant(composant, count) {
		let getFtpArchivee = null;
		const lignes = {};
		try {
			getFtpArchivee = await this.ProduitsService.getFTPs(composant);
		} catch (error) {
			console.log(error);
		}
		//recuperation des ftpA par composant
		if (!getFtpArchivee || getFtpArchivee.length == 0) {
			this.notification.info('FORMULES.GRAPHIQUE.GRANULAT_NON_FTP');
			return lignes;
		}
		if (getFtpArchivee && getFtpArchivee.length > 0) {
			for (let j = 0; j < getFtpArchivee[0].valeurs.length; j++) {
				const val = getFtpArchivee[0].valeurs[j];
				if (val.formuleCalcStat === '@FTPMOY' || val.formuleCalcStat === '@MOY') {
					if (!lignes[composant.idComposant]) {
						lignes[composant.idComposant] = [];
					}
					lignes[composant.idComposant].push(val);
				}
			}
			//Serie (nom des courbes)
			this.chart.series.push(composant.libelle);
			// preparation des tableau (vide) pour les data de chaque courbes
			if (!this.chart.data[count]) {
				this.chart.data[count] = [];
			}

			//recuperation des labels pour affichage par composant
			this._.forEach(lignes[composant.idComposant], (value) => {
				const v = parseFloat(value.valeur);
				const idx = this.chart.labels.findIndex((x) => x === `${value.libelleSsEssTamis}`);
				if (idx === -1) {
					this.chart.labels.push(`${value.libelleSsEssTamis}`);
				}
			});
			this.chart.labels.sort((a, b) => Number.parseFloat(a) - Number.parseFloat(b));
			//renvoie du tableau des datas (non trier)
			return lignes;
		}
	}

	recuperationDataChart(tableauGranulat, index) {
		//console.log(tableauGranulat,index);

		this._.forEach(tableauGranulat[index], (ligne) => {
			if (!this.chart.data[index]) {
				this.chart.data[index] = [];
			}
			this.chart.data[index] = Array.from(this.chart.labels, (l, v) => {
				const val = ligne.find((x) => x.libelleSsEssTamis === l);
				return val?.valeur;
			});
		});
	}

	createChartOptions(pId) {
		return {
			id: pId,
			type: 'line',
			labels: [],
			data: [],
			series: [],
			datasetOverride: [],
			options: {
				spanGaps: true,
				fill: false,
				scales: {
					y: {
						type: 'linear',
						display: true,
						position: 'left',
						max: 100,
						min: 0,
						ticks: {
							stepSize: 10
						}
					}
				}
			}
		};
	}

	interpoler(labels, arrayToInterpol) {
		let xa = 0;
		let xb = Number.parseFloat(labels[labels.length - 1].replace(',', '.'));

		let ya = 0;
		let yb = undefined;

		let iStartInterpolation = undefined;

		for (let t = 0; t < labels.length; t++) {
			if (t === 0 && !arrayToInterpol[t] && arrayToInterpol[t] !== 0) {
				iStartInterpolation = 0;
			}

			if (t > 0 && !arrayToInterpol[t] && arrayToInterpol[t] !== 0 && iStartInterpolation === undefined) {
				xa = Number.parseFloat(labels[t - 1].replace(',', '.'));
				ya = Number.parseFloat(arrayToInterpol[t - 1]);

				iStartInterpolation = t;
			} else if (t > 0 && arrayToInterpol[t] && iStartInterpolation >= 0) {
				xb = Number.parseFloat(labels[t].replace(',', '.'));
				yb = Number.parseFloat(arrayToInterpol[t]);

				for (let i = iStartInterpolation; i <= t; i++) {
					const x = Number.parseFloat(labels[i].replace(',', '.'));

					const formule = ((xb - x) / (xb - xa)) * ya;
					arrayToInterpol[i] = (formule + ((x - xa) / (xb - xa)) * yb).toFixed(2);

					xa = Number.parseFloat(labels[i].replace(',', '.'));
					ya = Number.parseFloat(arrayToInterpol[i]);
				}

				iStartInterpolation = undefined;
			}
		}

		if (iStartInterpolation >= 0) {
			for (let i = iStartInterpolation; i < labels.length; i++) {
				arrayToInterpol[i] = '100';
			}
		}

		return arrayToInterpol;
	}
}
