(function (angular, undefined) {
    'use strict';
    angular
        .module('blocks.datepicker')
        .directive('uibDatepickerPopup', uibDatepickerPopup);

    uibDatepickerPopup.$inject = ['moment'];

    function uibDatepickerPopup(moment) {
        return {
            require: ['ngModel', 'uibDatepickerPopup', '^?acDatepicker'],
            // on souhaite que la nouvelle instance de la directive que l'on crée soit appelée en deuxième
            // ainsi on peut manipuler et modifier les propriétés mises par bootstrap
            priority: 1,

            link: function (scope, element, attrs, ctrls) {
                var ngModelCtrl = ctrls[0];
                var acDatepickerCtrl = ctrls[2];

                //on ne rajoute un comportement personnalisé que si la directive est appelée conjointement à acDatepicker
                if (acDatepickerCtrl) {
                    //pour s'assurer que les éventuels parsers présent dans l'instance ui.bootstrap soit invoqués après le notre

                    // on supprime les comportements bootstrap
                    ngModelCtrl.$validators = {};
                    ngModelCtrl.$viewChangeListeners = [];
                    ngModelCtrl.$parsers = [];
                    // ngModelCtrl.$formatters = []; // on ne supprime pas les formatters car ils définissent la date dans le popup

                    // on écrase le $$parserName bootstrap pour éviter les conflits
                    ngModelCtrl.$$parserName = 'bootstrap-date';

                    // on ajoute notre parser
                    ngModelCtrl.$parsers.push(customParser);
                    ngModelCtrl.$parsers.push(acDatepickerCtrl.setModel);
                }

                function customParser(viewValue) {
                    var output;
                    // moment se débrouille pour construire la date avec le format qu'on lui passe
                    var momentValue = moment(viewValue, acDatepickerCtrl.config.outputFormat);

                    // si l'année est inférieure à 10 on a un comportement étrange
                    // on passe à -0001 et la directive affiche n'importe quoi (des dates négatives)
                    // on estime que si l'utilisateur met 0 à 9 ou 0x c'est qu'il veut dire 200x
                    if (momentValue.year() < 10) {
                        var year = momentValue.year() + 2000;
                        momentValue.year(year);
                    }

                    // on créé un string à partir de ce moment
                    if (acDatepickerCtrl.config && momentValue.isValid()) {
                        output = momentValue.format(acDatepickerCtrl.config.outputFormat);
                    }
                    // on met à jour la $viewValue
                    // pour que la valeur complétée soit prise en compte par les $parsers ui.bootstrap
                    ngModelCtrl.$viewValue = output || viewValue;

                    return output || viewValue;
                }
            }
        };
    }
})(angular);