export default function Configure($stateProvider) {
    $stateProvider.state('chantiers-grilles', {
        parent: 'gestion',
        url: '/chantiers-grilles',
        views: {
            gestion: {
                template: '<chantiers-grilles></chantiers-grilles>'
            }
        },
        rights: { domain: 'chantiers', right: 'read' },
        ncyBreadcrumb: {
            label: 'Duplication grilles chantier'
        }
    });
}

Configure.$inject = ['$stateProvider'];
