import LieuEntete from './lieu.entete.model';

export default class LieuFormEnteteController {
    static $inject = ['$state', '$stateParams', 'LieuxService', 'LieuxTypesService', 'ModalService', 'notification', 'MassiaApplicationService'];

    constructor($state, $stateParams, LieuxService, LieuxTypesService, ModalService, notification, MassiaApplicationService) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.LieuxService = LieuxService;
        this.LieuxTypesService = LieuxTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    async $onInit() {
        this.lieu = this.lieu || {};
        this.entete = new LieuEntete(this.lieu.entete);
        this.getCodeAuto();
        this.types = this.types || [];
        await this.getNiveauVisibilite();
    }

    async getNiveauVisibilite() {
        if (this.entete.typeId) {
            this.entete.niveauVisibilite = await this.LieuxTypesService.GetNiveauVisibilite(this.entete.typeId);
        }
    }

    $onChanges(objChanges) {
        if (objChanges.lieu) {
            this.getTypes();
        }
    }

    $doCheck() {
        if (!angular.equals(this.entete, this.lieu.entete)) {
            this.onUpdate({
                entete: angular.copy(this.entete)
            });
        }
    }

    async getCodeAuto() {
        try {
            this.entete.estCodeAuto = await this.LieuxService.estCodeAuto();
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async createType() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'LIEUX.CONFIRM_CREATE_TYPE.TITLE',
            modalMsg: 'LIEUX.CONFIRM_CREATE_TYPE.MESSAGE',
            headerClass: 'modal-warning'
        });
        if (this.MassiaApplicationService.getApplication() == 'gestion') {
            modalInstance.result.then(() => this.$state.go('lieux.newType'));
        } else {
            modalInstance.result.then(() => this.$state.go('lieux-donnee.newType'));
        }
    }

    async getTypes() {
        this.startLoading();

        try {
            this.types = await this.LieuxTypesService.getAll();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.entete.codeExists = await this.LieuxService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.entete.codeExists = null;
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
