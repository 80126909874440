import CentreGestionController from './centre.gestion.controller';

export default {
    bindings: {
        source: '=',
        domaine: '=',
        isTransport: '=',
        modalInstance: '=',
        resolve: '<'
    },
    template: require('./centre.gestion.html'),
    controller: CentreGestionController
};
