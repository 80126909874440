const serviceUrl = `${__configuration.apiUrl}/massia/import-donnees`;

export default class ImportDonneesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async loadDomaines() {
        const res = await this.$http.get(serviceUrl);
        return res.data;
    }

    async getTemplate(domaine, applicationName) {
        let res = null;
        if (domaine) {
            // if (domaine.b64File) {
            //     const byteCharacters = atob(domaine.b64File);

            //     const byteNumbers = new Array(byteCharacters.length);
            //     for (let i = 0; i < byteCharacters.length; i++) {
            //         byteNumbers[i] = byteCharacters.charCodeAt(i);
            //     }

            //     res = new Uint8Array(byteNumbers);
            // }
            // else {
            const url = `assets/templates/${applicationName}/${domaine.code}.xlsx`;
            res = await this.$http.get(url, {
                responseType: 'arraybuffer'
            });
            res = res.data;
            //}
        }

        return res;
    }

    async import(dataToImport) {
        const res = await this.$http.post(serviceUrl, dataToImport);
        return res.data;
    }

    async insert(code) {
        const url = `${serviceUrl}/${code}`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
