import OffreProducteurController from './offre.producteur.controller';

export default {
    bindings: {
        proposition: '=',
        producteur: '=',
        totaux: '=',
        isOpen: '=',
        indexproducteur: '=',
        offre: '='
    },
    template: require('./offre.producteur.html'),
    controller: OffreProducteurController
};
