class MixDesignController {
    /* @ngInject */
    constructor() {}
    $onInit() {}
    $onDestroy() {}
}
export default {
    bindings: {
        produit: '=',
        app: '<'
    },
    controller: MixDesignController,
    template: require('./mix-design.html')
};
