DocumentModalValidator.$inject = ['validator', 'validationHelper'];

export default function DocumentModalValidator(validator, validationHelper) {
    const instance = new validator();
    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('extension').must(BeAValidExtension).withMessage('VALIDATION_FORMAT_INCORRECT');
    instance.ruleFor('idType').notEmpty().withMessage('VALIDATION_NOEMPTY');

    function BeAValidExtension(file) {
        const extArray = [
            'pdf',
            'docx',
            'doc',
            'docm',
            'xlsx',
            'xls',
            'pptx',
            'ppt',
            'jpg',
            'png',
            'bmp',
            'gif',
            'txt',
            'zip',
            'rar',
            'PDF',
            'DOCX',
            'DOC',
            'DOCM',
            'XLSX',
            'XLS',
            'PPTX',
            'PPT',
            'JPG',
            'PNG',
            'BMP',
            'GIF',
            'TXT',
            'ZIP',
            'RAR'
        ];
        if (extArray.includes(file.extension)) {
            return true;
        }
        return false;
    }

    return instance;
}
