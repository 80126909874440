import TypeBennesConfig from './_config/type.bennes.config';
import TypeBennesRoutes from './_config/type.bennes.routes';

import TypeBennesService from './services/type.bennes.service';

import TypeBennesComponent from './components/type-bennes';
import TypeBenneDetailComponent from './components/type-benne-detail';
import TypeBenneFormComponent from './components/type-benne-form';
import TypeBenneValidator from './components/type-benne-form/type.benne.validator';

const moduleName = 'app.massia.gestion.type.bennes';

angular
    .module(moduleName, [])
    .config(TypeBennesConfig)
    .config(TypeBennesRoutes)
    .service('TypeBennesService', TypeBennesService)
    .factory('TypeBenneValidator', TypeBenneValidator)
    .component('typeBennes', TypeBennesComponent)
    .component('typeBenneForm', TypeBenneFormComponent)
    .component('typeBenneDetail', TypeBenneDetailComponent);

export default moduleName;
