(function () {
    'use strict';

    angular.module('blocks.smart.table').run(Run);
    Run.$inject = ['$templateCache'];

    function Run($templateCache) {
        $templateCache.put('blocks/smart-table/pagination.html',
            '<div class="pagination-wrapper text-center clearfix" ng-if="numPages >= 0">      ' +
            '    <ul class="pagination pull-left" ng-if="numPages > 1">                                                                     ' +
            '        <li>                                                                                                                   ' +
            '            <a href="" ng-click="selectPage(1);" ng-disabled="currentPage == 1">                                               ' +
            '                <span class="glyphicon glyphicon-step-backward" aria-hidden="true"></span>                                     ' +
            '            </a>                                                                                                               ' +
            '        </li>                                                                                                                  ' +
            '        <li>                                                                                                                   ' +
            '            <a href="" ng-click="selectPage(currentPage - 1);" ng-disabled="currentPage == 1">                                 ' +
            '                <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>                                     ' +
            '            </a>                                                                                                               ' +
            '        </li>                                                                                                                  ' +
            '        <li class="active">                                                                                                    ' +
            '            <a class="pagination-current-page"><span ng-bind="currentPage"></span></a>                                         ' +
            '        </li>                                                                                                                  ' +
            '        <li>                                                                                                                   ' +
            '            <a href="" ng-click="selectPage(currentPage + 1)" ng-disabled="currentPage == numPages">' +
            '                <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>                                    ' +
            '            </a>                                                                                                               ' +
            '        </li>' +
            '        <li>' +
            '            <a href="" ng-click="selectPage(numPages);" ng-disabled="currentPage == numPages">' +
            '                <span class="glyphicon glyphicon-step-forward" aria-hidden="true"></span>' +
            '            </a>' +
            '        </li>' +
            '    </ul>' +
            '    <b style="line-height:34px" class="hidden-xs hidden-sm" translate="PAGINATION_ELEMENTS" translate-values="{ NUM_RESULTS: itemsCount }" translate-interpolation="messageformat"></b>' +
            '    <b style="line-height:34px" class="hidden-md hidden-lg" translate="PAGINATION_ELEMENTS_SHORT" translate-values="{ NUM_RESULTS: itemsCount }" translate-interpolation="messageformat"></b>' +
            '    <div ng-if="numPages > 1" class="pull-right text-right">' +
            '       <form class="form-horizontal" ng-submit="selectPage(safeCurrentPage.page);">' +
            '            <label class="display-inline-block margin-0-5 text-right">Page</label>' +
            '                                                                                                                             ' +
            '            <div class="display-inline-block  margin-0-5">' +
            '                <input type="text" class="form-control text-center" ng-model="safeCurrentPage.page">' +
            '            </div>' +
            '            <label class="display-inline-block margin-0-5 text-left">/ {{ numPages || \'1\' }}</label>' +
            '       </form>' +
            '    </div>' +
            '</div>' +
            ''
        );
    }
})();