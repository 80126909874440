import validatorBuilder from 'validator-builder';

let syntheseEntityValidator = null;

export default class SyntheseEntity {
    constructor(data) {
        data = data || {};
        this.id = data.id || -1;
        this.idFamille = data.idFamille || -1;
        this.libelle = data.libelle;
        this.typeDeSynthese = data.typeDeSynthese;
        this.listeSyntheseCritereValeurBlocks = data.listeSyntheseCritereValeurBlocks || [];
        this.listeDefaultSyntheseCriteres = data.listeDefaultSyntheseCriteres || [];
        this.idModeleSynthese = data.idModeleSynthese || null;
        this.typeDeSyntheseLibelle = data.typeDeSyntheseLibelle;
        this.modeleSyntheseLibelle = data.modeleSyntheseLibelle || null;

        this.idReference = data.idReference;
        this.critereF2 = data.critereF2;
        this.isCritere2ProduitCom = data.isCritere2ProduitCom;

        this.showEssaisReferenceControle = data.showEssaisReferenceControle;
        this.showEssaisRealises = data.showEssaisRealises;
        this.showEssaisHorsReference = data.showEssaisHorsReference;
        this.hideEssaisHorsReference = data.hideEssaisHorsReference;
        this.hideEssaisNonRealises = data.hideEssaisNonRealises;
        this.hasInterpolationValeursManquantes = data.hasInterpolationValeursManquantes;

        if (data.typeDeConformite) {
            if (data.typeDeConformite.length > 0) {
                this.isProdBeton = parseInt(data.typeDeConformite[0]) ? true : false;
            }
            if (data.typeDeConformite.length > 1) {
                this.isProdFamilleBeton = parseInt(data.typeDeConformite[1]) ? true : false;
            }
            if (data.typeDeConformite.length > 2) {
                this.isProdContinue = parseInt(data.typeDeConformite[2]) ? true : false;
            }
            if (data.typeDeConformite.length > 3) {
                this.isProdInitiale = parseInt(data.typeDeConformite[3]) ? true : false;
            }
        }

        this.triEssais = data.triEssais || 1;
        this.idTrame = data.idTrame;
        this.idNorme = data.idNorme;
        this.idNQA = data.idNQA;
        this.nce = data.nce;

        this.byMonth = data.byMonth || false;
        this.byProducteur = data.byProducteur || false;

        this.listeLiensSETS = data.listeLiensSETS || [];
        this.listeSyntheseCritereAffichage = data.listeSyntheseCritereAffichage || [];
        this.listeSyntheseCritereTraitement = data.listeSyntheseCritereTraitement || [];
        this.listeSyntheseExclusion = data.listeSyntheseExclusion || [];
        this.listeCompositionProduit = data.listeCompositionProduit || [];

        this.ftpArchivee = data.ftpArchivee || null;

        if (this.listeLiensSETS && this.listeLiensSETS.length > 0) {
            this.listeLiensSETS.map((e) => {
                if (!e.provenancesEssai) {
                    e.provenancesEssai = ['0', '0', '0', '0'];
                }
                return e;
            });
        }

        this.isSpecificForUser = data.isSpecificForUser;
        this.carteDeControle = data.carteDeControle || {};
    }

    isValid() {
        syntheseEntityValidator = syntheseEntityValidator || validatorBuilder.getInstanceFor('SyntheseEntity');
        const validationResults = syntheseEntityValidator.validate(this);
        return validationResults.isValid;
    }
}
