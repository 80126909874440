DemandeLivraisonValidator.$inject = ['validator'];

export default function DemandeLivraisonValidator(validator) {
    const instance = new validator();
    instance.ruleFor('societeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('siteDepartId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('dateDemande').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('clientId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('siteProduitId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('lieuId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('quantiteDemandee').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('dureeTrajet').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('reference').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('heureDebutLivraison').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('heureFinLivraison').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;
}
