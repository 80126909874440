import SiteFormProduitsProducteurController from './site.produits.producteur.controller';

export default {
    bindings: {
        produits: '=',
        isClient: '<'
    },
    template: require('./site.produits.producteur.html'),
    controller: SiteFormProduitsProducteurController
};
