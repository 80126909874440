import { Caracteristique } from '../../../../../../core/models/caracteristique/Caracteristique';
import { ValeurCaracteristique } from '../../../../../../core/models/valeur-caracteristique/ValeurCaracteristique';

class CaractNumeriqueController {
    caracteristique: Caracteristique;
    valeurs: ValeurCaracteristique[];
    model: ValeurCaracteristique;
    index: number;
    defaultRounded: number = 2;
    defaultValue: String;
    /* @ngInject */
    constructor() { }
    $onInit() {
        const value = this.valeurs.find(x => x.idCaracteristique === this.caracteristique.id);
        if (!value) {
            this.model = <ValeurCaracteristique>{
                id: 0,
                idCaracteristique: this.caracteristique.id,
                value: this.defaultValue || this.defaultValue === '0' ? this.defaultValue : null
            };
            this.valeurs.push(this.model);
        } else {
            value.value = Number(value.value);
            this.model = value;
        }
    }
    $onDestroy() { }
    getStep() {
        let rounded = this.defaultRounded;
        if (this.caracteristique.arrondi) {
            rounded = this.caracteristique.arrondi;
        }
        let start = '1';
        for (let i = 0; i < rounded; i++) {
            start += '0';
        }

        return 1 / parseInt(start);
    }
}
export default {
    bindings: {
        caracteristique: '<',
        valeurs: '=',
        isObligatoire: '<',
        lecture: '<',
        defaultValue: '='
    },
    controller: CaractNumeriqueController,
    template: require('./caracteristique-numerique.html')
};
