export default class ModificationTraceModalController {
    constructor($scope, $state, $stateParams) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.borderWidth = 3;
        this.pointBorderWidth = 1;
    }

    $onInit() {
        this.sliderOptions = {
            precision: 2,
            step: 1,
            hidePointerLabels: false
        };

        if (this.graphique.trace.lineTension === 0) {
            this.modelCourbe = false;
        } else {
            this.modelCourbe = true;
        }
        this.initialisationMassiaSlider();
    }

    initialisationMassiaSlider() {
        if (this.graphique.trace.borderWidth) {
            this.borderWidth = this.graphique.trace.borderWidth;
        }

        if (this.graphique.trace.pointBorderWidth) {
            this.pointBorderWidth = this.graphique.trace.pointBorderWidth;
        }
    }

    $onDestroy() {}

    appliquer() {
        if (this.borderWidth !== this.graphique.trace.borderWidth) {
            this.graphique.trace.borderWidth = this.borderWidth;
        }
        if (this.pointBorderWidth !== this.graphique.trace.pointBorderWidth) {
            this.graphique.trace.pointBorderWidth = this.pointBorderWidth;
        }
        this.modalInstance.close(this.graphique);
    }

    annuler() {
        this.modalInstance.dismiss();
    }

    onSelectColor(color, graphique, type) {
        if (type === 'ligne') {
            var newProperties = {
                borderColor: color.HEXA.toString()
            };
        } else {
            var newProperties = {
                pointBorderColor: color.HEXA.toString(),
                pointBackgroundColor: color.HEXA.toString()
            };
        }
        graphique.trace = { ...graphique.trace, ...newProperties };
    }

    changeTension(model, graphique) {
        if (model) {
            var newProperties = {
                lineTension: undefined
            };
        } else {
            var newProperties = {
                lineTension: 0
            };
        }
        graphique.trace = { ...graphique.trace, ...newProperties };
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}

ModificationTraceModalController.$inject = ['$scope', '$state', '$stateParams'];
