export default class SousMesuresSelectController {
    static $inject = ['$scope', '$timeout', '$state', '$stateParams', '$translate', 'NormesService'];

    constructor($scope, $timeout, $state, $stateParams, $translate, NormesService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.NormesService = NormesService;
    }

    // Méthodes publiques
    $onInit() {
        this.excludes = this.excludes || [];
        this.clearOnSelect = this.clearOnSelect || false;
        this.selected = this.selected || [];
        this.disabled = this.disabled || false;
        this.domaine = this.domaine || 'mesures';
        this.typeMesure = this.typeMesure || 0;
        this.typeFiltreTheme = this.typeFiltreTheme || 0;
        this.idFamille = this.idFamille || 0;

        const that = this;

        this.$timeout(function () {
            that.$scope.$watch('$ctrl.typeFiltreTheme', function (newVal, oldVal) {
                if (newVal && oldVal && newVal !== oldVal) {
                    that.reloadMesures();
                }
            });
            that.$scope.$watch('$ctrl.typeMesure', function (newVal, oldVal) {
                if (newVal && oldVal && newVal !== oldVal) {
                    that.reloadMesures();
                }
            });
            that.$scope.$watch('$ctrl.idFamille', function (newVal, oldVal) {
                if (newVal && oldVal && newVal !== oldVal) {
                    that.reloadMesures();
                }
            });
            that.$scope.$watchCollection('$ctrl.selected', function (newCol, oldCol) {
                if (newCol && oldCol) {
                    that.refreshMesures();
                }
            });
        }, 0);

        this.reloadMesures();
    }

    selectMesure() {
        if (this.selected) {
            this.selected = this.selected.filter((x) => {
                if (x.tamis && this.selectedMesures.find((e) => e.ouvertureTamis === x.ouvertureTamis)) {
                    return true;
                } else if (!x.tamis && this.selectedMesures.find((e) => e.idSousEssai === x.sousEssaiId)) {
                    return true;
                }

                return false;
            });

            const copy = angular.copy(this.selected);
            this.selected = [];
            this.reset();
            for (let i = 0; i < this.selectedMesures.length; i++) {
                var item = this.selectedMesures[i];
                const essai = this.mesures.find((e) => {
                    return e.msGroup && e.id === item.idEssai;
                });

                let libelleSousEssai = item.code + ' / ' + item.libelle.split('(')[0].trim();
                if (item.isTamis) {
                    libelleSousEssai = item.ouvertureTamis.toString();
                }

                item.id = 0;
                item.sousEssaiCodeLibelle = libelleSousEssai;
                item.sousEssaiId = item.idSousEssai;
                item.essaiId = item.idEssai;
                item.tamis = item.isTamis;
                if (essai) {
                    item.essaiCodeLibelle = essai.libelle.split('|')[1].split('(')[0].trim() + ' (' + essai.code + ')';
                }
                item.libelleComplet = item.essaiCodeLibelle + ' : ' + item.sousEssaiCodeLibelle;
                item.position = 0;

                if (copy) {
                    const mesuresSelected = copy.find((e) => {
                        const b1 =
                            !item.isDimensionRemarquable &&
                            !e.isDimensionRemarquable &&
                            !item.isTamis &&
                            !e.tamis &&
                            item.idEssai === e.essaiId &&
                            item.idSousEssai === e.sousEssaiId;
                        const b2 = item.isTamis && e.tamis && item.idEssai === e.essaiId && item.ouvertureTamis === e.ouvertureTamis;
                        const b3 =
                            item.isDimensionRemarquable &&
                            e.isDimensionRemarquable &&
                            item.idEssai === e.essaiId &&
                            item.formuleDimension === e.formuleDimension;

                        return b1 || b2 || b3;
                    });
                    if (mesuresSelected) {
                        item = mesuresSelected;
                    }
                }

                if (this.onSelect) {
                    this.onSelect({ mesure: item });
                }
            }
        }
    }

    async reloadMesures() {
        await this.initEssaisList();
        await this.refreshMesures();
    }

    async refreshMesures() {
        if (this.mesures) {
            this.mesures.map((e) => {
                delete e.selected;
            });
            if (this.selected && this.selected.length) {
                for (let i = 0; i < this.selected.length; i++) {
                    const s = this.selected[i];
                    const mesuresSelected = this.mesures.find((e) => {
                        const b1 =
                            !e.isDimensionRemarquable &&
                            !s.isDimensionRemarquable &&
                            !e.isTamis &&
                            !s.tamis &&
                            e.idEssai === s.essaiId &&
                            e.idSousEssai === s.sousEssaiId;
                        const b2 = e.isTamis && s.tamis && e.idEssai === s.essaiId && e.ouvertureTamis === s.ouvertureTamis;
                        const b3 =
                            e.isDimensionRemarquable &&
                            s.isDimensionRemarquable &&
                            e.idEssai === s.essaiId &&
                            e.formuleDimension === s.formuleDimension;

                        return b1 || b2 || b3;
                    });
                    if (mesuresSelected) {
                        mesuresSelected.selected = true;
                    }
                }
            }
        }
    }

    async initEssaisList() {
        const rootListEssais = await this.NormesService.getEssaisList('essais', this.idFamille);
        if (rootListEssais && rootListEssais.length > 0) {
            const mesures = [];
            let allTypetypeEssais = [];
            for (let i = 0; i < rootListEssais.length; i++) {
                allTypetypeEssais = allTypetypeEssais.concat(rootListEssais[i].essais);
            }

            if (allTypetypeEssais && allTypetypeEssais.length > 0) {
                for (let e = 0; e < allTypetypeEssais.length; e++) {
                    const essai = allTypetypeEssais[e];
                    essai.msGroup = true;
                    essai.isEssai = true;
                    mesures.push(essai); //push de l'essai

                    const se = essai.sousEssais.find((el) => {
                        return el.code === essai.code && !el.isDimensionRemarquable && !el.isTamis;
                    });
                    if (se) {
                        se.isSousEssai = true;
                        se.idSousEssai = essai.id;
                        se.idEssai = essai.id;
                    }

                    essai.sousEssais = essai.sousEssais.filter((el) => {
                        return !el.isDimensionRemarquable;
                    });
                    for (let sse = 0; sse < essai.sousEssais.length; sse++) {
                        const sousEssai = essai.sousEssais[sse];
                        //if (sousEssai.id !== se.id) {
                        sousEssai.isSousEssai = true;
                        sousEssai.idSousEssai = sousEssai.id;
                        sousEssai.position = sousEssai.position;
                        sousEssai.idEssai = essai.id;
                        mesures.push(sousEssai);
                        //}
                    }
                    mesures.push({ msGroup: false });
                }
                this.mesures = mesures;
            }
        }
    }

    filterMesures(item) {
        return !this.excludes.find((e) => {
            return e.idSousEssai === item.id;
        });
    }
}
