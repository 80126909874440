import EtatCompteController from './site.etat.compte.controller';

export default {
    bindings: {
        site: '<',
        form: '=',
        onUpdate: '&'
    },
    template: require('./site.etat.compte.html'),
    controller: EtatCompteController
};
