import validatorBuilder from 'validator-builder';

let normeValidator = null;

export default class Norme {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.descriptif = data.descriptif;
        this.normeSpec = data.normeSpec || 0;

        this.enveloppe = data.enveloppe || null;
        this.nbrEssaiMinProducteur = data.nbrEssaiMinProducteur || null;
        this.nbrEssaiMinAcquereur = data.nbrEssaiMinAcquereur || null;
        this.modeCalculET = data.modeCalculET || null;
        this.idSerieTamis = data.idSerieTamis || null;

        this.idFamille = data.idFamille || null;
        this.codeFamille = data.codeFamille || null;
        this.libelleFamille = data.libelleFamille || null;
        this.controleET = data.controleET || null;
    }
    isValid() {
        normeValidator = normeValidator || validatorBuilder.getInstanceFor('Norme');
        const validationResults = normeValidator.validate(this);
        return validationResults.isValid;
    }
}
