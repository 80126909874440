import validatorBuilder from 'validator-builder';
import FormuleComposant from './creation/formule.composant.model';

let formuleValidator = null;

export default class Formule {
    constructor(data = {}) {
        this.id = data.id || null;
        this.composants = [];
        this.normeSpec = data.normeSpec;
        if (typeof data.composants !== 'undefined') {
            for (let i = 0; i < data.composants.length; i++) {
                const composant = data.composants[i];
                this.composants.push(new FormuleComposant(composant));
            }
        }
        this.pourcentageTotal = data.pourcentageTotal || 0;
        this.prixCessionTotal = data.prixCessionTotal || 0;
        this.idProduit = data.produitId || null;
        this.code = data.producteurId + '' + data.code;
        this.idProducteur = data.producteurId || null;
        this.squelette = data.squelette ? new Formule(data.squelette) : null;
        this.resistanceVisee = data.resistanceVisee || null;
        this.resistanceEstimee = data.resistanceEstimee || null;
        this.volumeAir = data.volumeAir || null;
        this.ecartTypeAdopte = data.ecartTypeAdopte || null;
        this.MVT = data.MVT || null;
        this.tempsMalaxage = data.tempsMalaxage || null;
        this.compoAddCoef = data.compoAddCoef || [];
        this.conformite = undefined;
        this.isEnabledProduct = data.isEnabledProduct || true;
        this.isEnabledSiteProduct = data.isEnabledSiteProduct || true;
        this.dosageKg = data.dosageKg || false;
        this.eauAdjuvantSelected = data.eauAdjuvantSelected || false;
    }

    isValid() {
        formuleValidator = formuleValidator || validatorBuilder.getInstanceFor('Formule');
        const validationResults = formuleValidator.validate(this);
        return validationResults.isValid;
    }
}
