import InventaireStocksLeveeController from './inventaire.stocks.levee.controller';

export default {
    bindings: {
        levee: '=',
        modalInstance: '='
    },
    template: require('./inventaire.stocks.levee.html'),
    controller: InventaireStocksLeveeController
};
