export default function SettingRoute($stateProvider) {
    $stateProvider
        .state('portail.setting', {
            url: '/setting',
            abstract: true,
            template: '<portail-setting></portail-setting>',
            ncyBreadcrumb: {
                skip: true
                //label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}',
            }
        })
        // .state('portail.setting.server', {
        //     url: '',
        //     rights: { domain: 'portail', right: 'create' },
        //     template: '<portail-setting-server></portail-setting-server>',
        //     ncyBreadcrumb: {
        //         skip: true,
        //         //label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}',
        //     },
        // })
        .state('portail.setting.mail', {
            url: '/mail',
            rights: { domain: 'portail', right: 'create' },
            template: '<portail-setting-mail></portail-setting-mail>',
            ncyBreadcrumb: {
                skip: true
                //label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}',
            }
            //search: 'PORTAIL.BREADCRUMBS.USERS_NEW',
        })
        .state('portail.setting.doc', {
            url: '/document',
            rights: { domain: 'portail', right: 'create' },
            template: '<portail-setting-doc></portail-setting-doc>',
            ncyBreadcrumb: {
                skip: true
            }
        })
        .state('portail.setting.display', {
            url: '/display',
            rights: { domain: 'portail', right: 'create' },
            template: '<portail-setting-display></portail-setting-display>',
            ncyBreadcrumb: {
                skip: true
            }
        });
}

SettingRoute.$inject = ['$stateProvider'];
