(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.treeview')
        .controller('AcTreeviewMenuController', AcTreeviewMenuController);
    AcTreeviewMenuController.$inject = [
        'AcTreeviewCommunicationService'
    ];

    /* @ngInject */
    function AcTreeviewMenuController(AcTreeviewCommunicationService) {

        /* jshint validthis: true */
        var vm = this;
        var treeviewName, treeviewConfig, treeviewService, treeviewFunctions, functionsScope;

        // propriétés
        // méthodes publiques
        vm.getSelectedNode = AcTreeviewCommunicationService.getSelectedNode;
        vm.init = init;
        vm.canRefreshNode = canRefreshNode;
        vm.refreshNode = refreshNode;
        vm.canEditNode = canEditNode;
        vm.editNode = editNode;
        vm.canCreateNode = canCreateNode;
        vm.createNode = createNode;
        vm.canRemoveNode = canRemoveNode;
        vm.removeNode = removeNode;
        vm.canMoveNodeUp = canMoveNodeUp;
        vm.moveNodeUp = moveNodeUp;
        vm.canMoveNodeDown = canMoveNodeDown;
        vm.moveNodeDown = moveNodeDown;
        // events
        //region Initialisation
        function init(name) {
            treeviewName = name;
            treeviewConfig = AcTreeviewCommunicationService.getTreeviewConfig(treeviewName) || {};
            treeviewService = AcTreeviewCommunicationService.getTreeviewService(treeviewName) || {};
            treeviewFunctions = treeviewConfig.functions || {};
            functionsScope = treeviewConfig.functionsScope || this;
        }

        //endregion Initialisation

        //region Méthodes publiques

        function canRefreshNode() {
            var node = AcTreeviewCommunicationService.getSelectedNode(treeviewName);

            // si une fonction de est renseignée, on l'exécute
            if (angular.isFunction(treeviewFunctions.canRefreshNode)) {
                return treeviewFunctions.canRefreshNode.call(functionsScope, node);
            } else {
                return true;
            }
        }

        function refreshNode() {
            var node = AcTreeviewCommunicationService.getSelectedNode(treeviewName);

            if (angular.isFunction(treeviewFunctions.refreshNode)) {
                return treeviewFunctions.refreshNode.call(functionsScope, node);
            } else {
                AcTreeviewCommunicationService.raiseNodeRefreshFunction(treeviewName, node.id, undefined, node);
            }
        }

        function canEditNode() {
            var node = AcTreeviewCommunicationService.getSelectedNode(treeviewName);
            return node
                && angular.isFunction(treeviewFunctions.editNode)
                && angular.isFunction(treeviewFunctions.canEditNode)
                && treeviewFunctions.canEditNode.call(functionsScope, node);
        }

        function editNode() {
            var node = AcTreeviewCommunicationService.getSelectedNode(treeviewName);
            treeviewFunctions.editNode.call(functionsScope, node);
        }

        function canCreateNode() {
            var parent = AcTreeviewCommunicationService.getSelectedNode(treeviewName);
            return parent
                && angular.isFunction(treeviewFunctions.createNode)
                && angular.isFunction(treeviewFunctions.canCreateNode)
                && treeviewFunctions.canCreateNode.call(functionsScope, parent);
        }

        function createNode() {
            var parent = AcTreeviewCommunicationService.getSelectedNode(treeviewName);
            treeviewFunctions.createNode.call(functionsScope, parent);
        }

        function canRemoveNode() {
            var node = AcTreeviewCommunicationService.getSelectedNode(treeviewName);
            return node
                && angular.isFunction(treeviewFunctions.removeNode)
                && angular.isFunction(treeviewFunctions.canRemoveNode)
                && treeviewFunctions.canRemoveNode.call(functionsScope, node);
        }

        function removeNode() {
            var node = AcTreeviewCommunicationService.getSelectedNode(treeviewName);
            treeviewFunctions.removeNode.call(functionsScope, node);
        }

        function canMoveNodeUp() {
            var node = AcTreeviewCommunicationService.getSelectedNode(treeviewName);

            return node
                && angular.isFunction(treeviewFunctions.moveNodeUp)
                && angular.isFunction(treeviewFunctions.canMoveNodeUp)
                && treeviewFunctions.canMoveNodeUp.call(functionsScope, node);
        }

        function moveNodeUp() {
            var node = AcTreeviewCommunicationService.getSelectedNode(treeviewName);
            treeviewFunctions.moveNodeUp.call(functionsScope, node);
        }

        function canMoveNodeDown() {
            var node = AcTreeviewCommunicationService.getSelectedNode(treeviewName);

            return node
                && angular.isFunction(treeviewFunctions.moveNodeDown)
                && angular.isFunction(treeviewFunctions.canMoveNodeDown)
                && treeviewFunctions.canMoveNodeDown.call(functionsScope, node);
        }

        function moveNodeDown() {
            var node = AcTreeviewCommunicationService.getSelectedNode(treeviewName);
            treeviewFunctions.moveNodeDown.call(functionsScope, node);
        }

        //endregion Méthodes publiques

        //region Méthodes privées
        //endregion Méthodes privées

        //region Events
        //endregion Events
    }
})(angular);