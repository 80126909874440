import { controllers } from 'chart.js';

export default class SyntheseEntityCdcController {
    /* @ngInject */
    constructor(
        $scope,
        $state,
        $stateParams,
        $filter,
        $timeout,
        $uibModal,
        notification,
        MassiaApplicationService,
        CalculsStatistiquesService,
        SyntheseEntitiesCdcService,
        ModalService,
        moment,
        globalizationManagementService,
        TemplateImpressionService,
        $translate,
        SyntheseEntitiesService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.$timeout = $timeout;
        this.$uibModal = $uibModal;
        this.notification = notification;
        this.MassiaApplicationService = MassiaApplicationService;
        this.CalculsStatistiquesService = CalculsStatistiquesService;
        this.SyntheseEntitiesCdcService = SyntheseEntitiesCdcService;
        this.ModalService = ModalService;
        this.moment = moment;
        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
        this.TemplateImpressionService = TemplateImpressionService;
        this.$translate = $translate;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
    }

    async $onInit() {
        this.resetChart();
        this.startLoading();
        this.model = this.$state.params.obj || {};
        if (this.$state.params.obj === null) {
            this.$state.go('syntheseentities.list');
        } else {
            this.idxSynthese = 0;
            this.idxEssai = 0;
            if (this.model.synthese.carteDeControle.syntheseLiee && this.model.synthese.carteDeControle.syntheseLiee.length > 0) {
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].selected = true;
                await this.formatIstevenEssai();
            }
            if (this.model.synthese.carteDeControle.syntheseLiee.length === 0) {
                this.notification.warning('SYNTHENTS.CARTE_DE_CONTROLE.NOTIFICATIONS.CHOOSE_AN_ITEM');
                return this.$state.go('syntheseentities.list');
            } else if (
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res &&
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete &&
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais &&
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais.length > 0
            ) {
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].selected = true;
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].graphique = {};
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].maxY = 0;
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].minY = 0;
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].base = false;
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].printable = false;
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].courbesSelected = [];
            }
            
            await this.getCalculStats();

            this.modelGraph = {
                essai: this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai]
            };
            const idxCourbe = this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].listCalculStats.findIndex((x) => x.code === 'VAL');

            this.modelGraph.courbes = [];

            if (idxCourbe > -1) {
                this.modelGraph.courbes.push(this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].listCalculStats[idxCourbe]);
            }
            if (this.modelGraph.courbes) {
                this.ajouterCourbe(this.idxSynthese, this.idxEssai);
            }
            await this.loadCdcParams(this.model.synthese.id);

            this.stopLoading();
        }
    }

    $onDestroy() {}

    async loadCdcParams(id) {
        this.cdcParams = await this.SyntheseEntitiesCdcService.getCdcParamsById(id);
        for (let i = 0; i < this.cdcParams.length; i++) {
            const element = this.cdcParams[i];

            const idxToSplice = this.model.synthese.carteDeControle.syntheseLiee.findIndex((x) => x.synthese.id === element.idSyntheseEnCours);

            if (idxToSplice > -1) {
                const idxSyntheseEnCours = this.model.synthese.carteDeControle.syntheseLiee.findIndex(
                    (x) => x.synthese.id === element.idSyntheseEnCours
                );
                const idxOngletEssaiEnCours = this.model.synthese.carteDeControle.syntheseLiee[
                    idxSyntheseEnCours
                ].res.entete.titresSousEssais.findIndex((x) => x.id === element.idSousEssaiEnCours || x.id === element.idTamisEnCours);

                if (idxSyntheseEnCours !== -1 && idxOngletEssaiEnCours !== -1) {
                    this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseEnCours].res.entete.titresSousEssais[
                        idxOngletEssaiEnCours
                    ].commentaire = element.commentaire;

                    this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseEnCours].res.entete.titresSousEssais[idxOngletEssaiEnCours].maxY =
                        element.yMax;
                    this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseEnCours].res.entete.titresSousEssais[idxOngletEssaiEnCours].minY =
                        element.yMin;
                    this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseEnCours].res.entete.titresSousEssais[idxOngletEssaiEnCours].base =
                        element.base100;
                    this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseEnCours].res.entete.titresSousEssais[
                        idxOngletEssaiEnCours
                    ].printable = element.printable;
                    this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseEnCours].res.entete.titresSousEssais[
                        idxOngletEssaiEnCours
                    ].chartBackgroundColor = element.couleurDeFond;
                    this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseEnCours].res.entete.titresSousEssais[
                        idxOngletEssaiEnCours
                    ].courbesSelected = [];
                    if (idxSyntheseEnCours === 0 && idxOngletEssaiEnCours === 0) {
                        this.chartBackgroundColor = element.couleurDeFond;
                    }
                }

                for (let j = 0; j < element.courbes.length; j++) {
                    const c = element.courbes[j];

                    if (!this.modelGraph.courbes && this.modelGraph.courbes.length === 0) {
                        this.modelGraph.courbes = [];
                    }
                    this.modelGraph.trace = {};

                    const idxSyntheseEssai = this.model.synthese.carteDeControle.syntheseLiee.findIndex((x) => x.synthese.id === c.idSyntheseEssai);

                    if (idxSyntheseEssai !== -1) {
                        const idxEssaiSelectionner = this.model.synthese.carteDeControle.syntheseLiee[
                            idxSyntheseEssai
                        ].res.entete.titresSousEssais.findIndex(
                            (x) => (x.id === c.idSsEssai && c.idSsEssai > -1) || (x.id === c.idTamis && c.idTamis > -1)
                        );

                        if (idxEssaiSelectionner !== -1) {
                            this.modelGraph.essai =
                                this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseEssai].res.entete.titresSousEssais[idxEssaiSelectionner];
                            this.modelGraph.essai.idSynthese = this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseEssai].synthese.id;
                            this.modelGraph.essai.libelleSynthese =
                                this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseEssai].synthese.libelle;

                            if (c.lissage === 1) {
                                this.modelGraph.trace.lineTension = undefined;
                            } else {
                                this.modelGraph.trace.lineTension = 0;
                            }
                            if (c.lineHeight) {
                                this.modelGraph.trace.borderWidth = c.lineHeight;
                            }
                            if (c.lineColor) {
                                this.modelGraph.trace.borderColor = c.lineColor;
                            }
                            if (c.pointColor) {
                                this.modelGraph.trace.pointBorderColor = c.pointColor;
                            }
                            if (c.pointSize) {
                                this.modelGraph.trace.pointBorderWidth = c.pointSize;
                            }
                            if (c.pointType) {
                                this.modelGraph.trace.pointStyle = c.pointType;
                            }
                        }
                    }
                    const idxCalcStat = this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].listCalculStats.findIndex(
                        (x) => x.id === c.idCalculStat
                    );
                    if (idxCalcStat !== -1) {
                        this.modelGraph.courbes.push(this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].listCalculStats[idxCalcStat]);
                    }
                    this.ajouterCourbe(idxSyntheseEnCours, idxOngletEssaiEnCours);
                }
            }
            this.modelGraph = {};
        }
    }

    ajoutCourbeCourante() {
        // ajout courbes de l'ecran (l'essais en cours)
        this.modelGraph = { essai: this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai] };
        const idxCourbe = this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].listCalculStats.findIndex((x) => x.code === 'VAL');
        this.modelGraph.courbes = [];

        if (idxCourbe > -1) {
            this.modelGraph.courbes.push(this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].listCalculStats[idxCourbe]);
        }
        if (this.modelGraph.courbes) {
            this.ajouterCourbe(this.idxSynthese, this.idxEssai);
        }
    }

    resetChart() {
        this.chart = {
            type: 'line',
            labels: [],
            data: [],
            series: [],
            dataSetOverride: [],
            options: {
                spanGaps: true,
                type: 'linear',
                chartAreaColor: this.chartBackgroundColor || null,
                legend: {
                    display: true,
                    position: 'bottom'
                },
                fill: false,
                scales: {
                    yAxes: [
                        {
                            display: true,
                            position: 'left',
                            ticks: {
                                max: 0,
                                min: 0
                            },
                            gridLines: {}
                        }
                    ]
                }
            }
        };
    }

    async formatIstevenEssai() {
        if (!this.list) {
            this.list = [];
            for (let i = 0; i < this.model.synthese.carteDeControle.syntheseLiee.length; i++) {
                const element = this.model.synthese.carteDeControle.syntheseLiee[i];
                const listEssais = angular.copy(element.res.entete.titresSousEssais);
                if (listEssais && listEssais.length > 0) {
                    for (let j = 0; j < listEssais.length; j++) {
                        const ess = listEssais[j];
                        ess.libelle = ess.displayTitle;
                        ess.idSynthese = element.synthese.id;
                        ess.libelleSynthese = element.synthese.libelle;
                    }
                }
                const tmp = {
                    libelle: element.synthese.libelle,
                    listEssais: listEssais
                };
                this.list.push(tmp);
            }
        }
        this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].listEssais = this.$filter('istevenFormat')(
            this.list,
            'libelle',
            'listEssais'
        );
    }

    async getCalculStats() {
        // on recupere la liste des calcul statistique
        const listCalculStats = await this.CalculsStatistiquesService.getCalculsStatistiquesSelect();
        // on met la liste a jours
        this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].listCalculStats = listCalculStats;
    }

    async changeTab(synthese) {
        this.model.synthese.carteDeControle.syntheseLiee.map(async (e) => {
            // on deselectionne les autres syntheses
            if (e.selected === true) {
                e.selected = false;
            }
            return e;
        });

        synthese.selected = true;

        this.idxSynthese = this.model.synthese.carteDeControle.syntheseLiee.findIndex((x) => x.selected === synthese.selected);
        this.model.synthese.carteDeControle.syntheseLiee.map(async (f) => {
            // on charge la liste des essais et des calculs statistiques correspondant à la synthese courante
            if (f.selected === true) {
                f.listCalculStats = angular.copy(synthese.listCalculStats);

                if (!f.listCalculStats) {
                    await this.getCalculStats();
                }

                await this.formatIstevenEssai();

                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[0].selected = true;
            }
            return f;
        });

        if (this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[0].chartBackgroundColor) {
            this.chartBackgroundColor =
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[0].chartBackgroundColor;
        } else {
            this.chartBackgroundColor = null;
        }

        if (
            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[0].courbesSelected &&
            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[0].courbesSelected.length > 0
        ) {
            this.prepareNewGraphData(
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[0].courbesSelected
            );
        }

        this.changeTabEssai(this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[0]);
    }

    changeTabEssai(essai) {
        essai.selected = true;
        this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais.map((e) => {
            if (e.id !== essai.id) {
                e.selected = false;
            }

            return e;
        });

        this.idxEssai = this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais.findIndex(
            (e) => e.selected === essai.selected
        );
        if (this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].chartBackgroundColor) {
            this.chartBackgroundColor =
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].chartBackgroundColor;
        } else {
            this.chartBackgroundColor = null;
        }

        if (
            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].courbesSelected &&
            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].courbesSelected
        ) {
            this.prepareNewGraphData(
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].courbesSelected
            );
        } else {
            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].courbesSelected = [];
            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].graphique = {};
        }

        this.ajoutCourbeCourante();
    }

    selectEssai(data) {
        this.modelGraph.essai = data;
    }

    selectCalculStatistique() {
        this.modelGraph.courbes = [];
        if (this.tmp.calculStatistiqueSelected && this.tmp.calculStatistiqueSelected.length > 0) {
            this.modelGraph.courbes = angular.copy(this.tmp.calculStatistiqueSelected);
        }
    }

    ajouterCourbe(idxSyntheseEnCours, idxOngletEssaiEnCours) {
        if (this.modelGraph && this.modelGraph.essai && this.modelGraph.courbes && this.modelGraph.courbes.length > 0) {
            for (let i = 0; i < this.modelGraph.courbes.length; i++) {
                const element = this.modelGraph.courbes[i];
                const tmpTrace = {
                    fill: false,
                    lineTension: 0
                };

                const tmp = {
                    essai: this.modelGraph.essai,
                    courbe: element,
                    trace: { ...tmpTrace, ...this.modelGraph.trace },
                    valeur: -1,
                    isCourbe: true
                };
                let idxSyntheseIsteven = -1;

                if (this.modelGraph.essai && this.modelGraph.essai.idSynthese) {
                    idxSyntheseIsteven = this.model.synthese.carteDeControle.syntheseLiee.findIndex(
                        (x) => x.synthese.id === this.modelGraph.essai.idSynthese
                    );
                } else {
                    idxSyntheseIsteven = this.model.synthese.carteDeControle.syntheseLiee.findIndex(
                        (x) => x.synthese.id === this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].synthese.id
                    );
                }

                // on verifie quel est la synthese selectionner dans les essais
                if (idxSyntheseIsteven > -1) {
                    // on verifie pour chaque calcul stats s'il existe une valeur  : s'il y a une valeur ce n'est pas une courbes.
                    const indexValeur = this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseIsteven].res.stats.findIndex(
                        (x) => x.formule === element.formule
                    );
                    if (indexValeur > -1) {
                        const idxEssai = this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseIsteven].res.entete.titresSousEssais.findIndex(
                            (x) => x.id === this.modelGraph.essai.id
                        );
                        if (idxEssai > -1) {
                            tmp.valeur =
                                this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseIsteven].res.stats[indexValeur].statsEssais[
                                    idxEssai
                                ].valeur;

                            if (tmp.valeur && typeof Number.parseFloat(tmp.valeur) !== 'number') {
                                var indexStr = tmp.valeur.indexOf('/');
                            }

                            if (typeof Number.parseFloat(tmp.valeur) !== 'number' || indexStr !== -1) {
                                tmp.trace = {};
                                tmp.isCourbe = false;
                            }
                        }
                    }

                    //on creer un tableau temporaire a vide
                    let courbesSelected = [];

                    if (idxSyntheseEnCours > -1 && idxOngletEssaiEnCours > -1) {
                        if (
                            this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseEnCours].res.entete.titresSousEssais[idxOngletEssaiEnCours]
                                .courbesSelected
                        ) {
                            // si il existe on rempli le temporaire avec l'ancien tableau
                            courbesSelected = angular.copy(
                                this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseEnCours].res.entete.titresSousEssais[
                                    idxOngletEssaiEnCours
                                ].courbesSelected
                            );

                            const index = this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[
                                this.idxEssai
                            ].courbesSelected.findIndex(
                                (x) => x.essai.id === tmp.essai.id && x.courbe.id === element.id && x.essai.idSynthese === tmp.essai.idSynthese
                            );
                            // s'il existe pas
                            if (index === -1) {
                                // on l'ajoute au tableau
                                courbesSelected.push(tmp);
                            }
                        } else {
                            courbesSelected.push(tmp);
                        }

                        if (idxSyntheseEnCours > -1) {
                            if (idxOngletEssaiEnCours > -1) {
                                this.model.synthese.carteDeControle.syntheseLiee[idxSyntheseEnCours].res.entete.titresSousEssais[
                                    idxOngletEssaiEnCours
                                ].courbesSelected = courbesSelected;
                            }
                        }
                    } else {
                        if (
                            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai]
                                .courbesSelected
                        ) {
                            // si il existe on rempli le temporaire avec l'ancien tableau
                            courbesSelected = angular.copy(
                                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai]
                                    .courbesSelected
                            );
                        }

                        if (
                            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai]
                                .courbesSelected &&
                            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai]
                                .courbesSelected.length > 0 &&
                            this.tmp &&
                            this.tmp.calculStatistiqueSelected &&
                            this.tmp.calculStatistiqueSelected.length > 0
                        ) {
                            // on verifie que l'element courant n'est pas deja dans le tableau
                            const index = this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[
                                this.idxEssai
                            ].courbesSelected.findIndex(
                                (x) => x.essai.id === tmp.essai.id && x.courbe.id === element.id && x.essai.idSynthese === tmp.essai.idSynthese
                            );

                            // s'il existe pas
                            if (index === -1) {
                                // on l'ajoute au tableau
                                courbesSelected.push(tmp);
                            } else {
                                this.notification.warning('SYNTHENTS.CARTE_DE_CONTROLE.NOTIFICATIONS.COURBE_EXIST');
                            }
                        } else {
                            courbesSelected.push(tmp);
                        }

                        if (this.idxSynthese > -1) {
                            if (this.idxEssai > -1) {
                                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[
                                    this.idxEssai
                                ].courbesSelected = courbesSelected;
                            }
                        }
                    }

                    if (
                        this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai]
                            .courbesSelected &&
                        this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].courbesSelected
                            .length > 0
                    ) {
                        this.prepareNewGraphData(
                            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai]
                                .courbesSelected
                        );
                    }

                    if (this.tmp && (this.tmp.essaiSelected || this.tmp.calculStatistiqueSelected)) {
                        this.remiseAZeroDesIsteven(true, true);
                    }
                }
            }
        }
    }

    remiseAZeroDesIsteven(essai, calculStats) {
        if (essai) {
            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].listEssais.map((e) => {
                if (e.selected === true) {
                    e.selected = false;

                    return e;
                }
            });
            this.tmp.essaiSelected = [];
        }
        if (calculStats) {
            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].listCalculStats.map((c) => {
                if (c.selected === true) {
                    c.selected = false;

                    return c;
                }
            });
            this.tmp.calculStatistiqueSelected = [];
        }
    }

    supprimerLigne(tableau, index) {
        tableau.splice(index, 1);
        this.prepareNewGraphData(tableau);
    }

    prepareNewGraphData(tableau) {
        this.resetChart();
        tableau = tableau.filter((x) => x.isCourbe);
        tableau = tableau.map((x) => {
            let idxValeurs = -1;
            if (x.essai.idSynthese) {
                idxValeurs = this.model.synthese.carteDeControle.syntheseLiee.findIndex((s) => s.synthese.id === x.essai.idSynthese);
            } else {
                idxValeurs = this.model.synthese.carteDeControle.syntheseLiee.findIndex(
                    (s) => s.synthese.id === this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].synthese.id
                );
            }

            let listPrelevement = [];
            if (idxValeurs > -1) {
                listPrelevement = this.getListPrelevement(idxValeurs);
            }
            x.prelevement = listPrelevement;
            return x;
        });

        let forSort = [];
        for (let i = 0; i < tableau.length; i++) {
            const table = tableau[i];
            const listPrelevement = table.prelevement;

            let valList = [];
            for (let j = 0; j < listPrelevement.length; j++) {
                const plv = listPrelevement[j];
                let valeur = null;
                if (table.essai.isTamis === true) {
                    //on recupere les valeurs d'essai
                    const tamis = plv.listeTamisLignes.find(
                        (x) => x.idEssai === table.essai.idEssai && x.ouvertureTamis === table.essai.ouvertureTamis
                    );

                    if (tamis && tamis.passant && table.valeur === -1) {
                        valeur = tamis.passant;
                    } else if (table.valeur > -1 && table.valeur !== '' && typeof Number.parseFloat(table.valeur) === 'number') {
                        valeur = Number.parseFloat(table.valeur);
                    }
                } else {
                    const essai = plv.listeSousEssaiLignes.find((x) => x.idSousEssai === table.essai.id);

                    if (essai && essai.valeur && table.valeur === -1) {
                        valeur = essai.valeur;
                    } else if (table.valeur > -1 && table.valeur !== '' && typeof Number.parseFloat(table.valeur) === 'number') {
                        valeur = Number.parseFloat(table.valeur);
                    }
                }

                if (valeur !== null && typeof valeur !== 'undefined' && typeof Number.parseFloat(valeur) === 'number') {
                    if (!this.chart.labels.includes(plv.dateFormat)) {
                        this.chart.labels.push(plv.dateFormat);
                    }
                    valList[this.chart.labels.findIndex((l) => l === plv.dateFormat)] = Number.parseFloat(valeur);
                }
            }

            if (this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].base) {
                valList = this.base100(valList);
            }

            if (valList && valList.length > 0) {
                this.chart.data.push(valList);
                const libSynthese = table.essai.libelleSynthese
                    ? table.essai.libelleSynthese
                    : this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].synthese.libelle;
                const key = table.essai.titre + '/' + libSynthese + '/' + table.courbe.code;
                this.chart.series.push(key);
                if (table.trace !== {}) {
                    this.chart.dataSetOverride[i] = table.trace;
                }
            } else {
                this.chart.dataSetOverride[i] = { fill: false };
            }

            forSort = this.chart.labels
                .slice()
                .map((x, ix) => {
                    const r = {
                        val: x,
                        _bix: ix
                    };
                    return r;
                })
                .sort((a, b) => {
                    const tmpa = a.val.split('/');
                    const dateA = new Date(tmpa[2], tmpa[1] - 1, tmpa[0]);

                    const tmpb = b.val.split('/');
                    const dateB = new Date(tmpb[2], tmpb[1] - 1, tmpb[0]);

                    return dateA - dateB;
                })
                .map((r, ix) => {
                    r._aix = ix;
                    return r;
                });

            const flatValues = [];
            this.chart.data.map((x) =>
                x.map((e) => {
                    if (e !== null && typeof e !== 'undefined') {
                        flatValues.push(parseFloat(e));
                    }
                })
            );

            const newRes = [];
            for (let i = 0; i < forSort.length; i++) {
                const sort = forSort[i];
                for (let j = 0; j < this.chart.data.length; j++) {
                    const copy = angular.copy(this.chart.data[j]);
                    if (!newRes[j]) {
                        newRes[j] = [];
                    }

                    newRes[j][sort._aix] = copy[sort._bix];
                }
            }

            this.chart.data = newRes;
            this.chart.labels = forSort.map((x) => x.val);
            if (flatValues && flatValues.length > 0) {
                const max = Math.max(...flatValues);
                const min = Math.min(...flatValues);

                if (
                    this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].maxY &&
                    this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].maxY !== 0 &&
                    this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].maxY > max
                ) {
                    this.chart.options.scales.yAxes[0].ticks.max =
                        this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].maxY;
                } else {
                    this.chart.options.scales.yAxes[0].ticks.max = max;
                    this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].maxY =
                        Number.parseFloat(Number.parseFloat(max).toFixed(2));
                }

                if (
                    this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].minY &&
                    this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].minY !== 0 &&
                    this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].minY < min
                ) {
                    this.chart.options.scales.yAxes[0].ticks.min =
                        this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].minY;
                } else {
                    this.chart.options.scales.yAxes[0].ticks.min = min;
                    this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].minY =
                        Number.parseFloat(Number.parseFloat(min).toFixed(2));
                }
            }

            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].graphique = this.chart;
        }
    }

    getListPrelevement(idxValeurs) {
        const plv = this.model.synthese.carteDeControle.syntheseLiee[idxValeurs].result.prelevementBlock;
        return plv
            .sort((a, b) => {
                const db = this.moment(b.datePrelevement, this.dateFormat).unix();
                const da = this.moment(a.datePrelevement, this.dateFormat).unix();
                return da - db;
            })
            .map((p) => {
                p.dateFormat = p.datePrelevement.split(' ')[0];
                return p;
            });
    }

    changeYMin(value) {
        if (
            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].graphique &&
            typeof Number.parseFloat(value) === 'number'
        ) {
            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].minY =
                Number.parseFloat(value);
            this.prepareNewGraphData(
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].courbesSelected
            );
        }
    }

    changeYMax(value) {
        if (
            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].graphique &&
            typeof Number.parseFloat(value) === 'number'
        ) {
            this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].maxY =
                Number.parseFloat(value);
            this.prepareNewGraphData(
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].courbesSelected
            );
        }
    }

    base100(val) {
        // on recupere la premiere valeur (point d'origine et on creer le multiplicatueur de la regle de trois.)
        const multiplicateur = 100 / val.find((x) => x !== null && typeof x !== 'undefined');
        return val.map((x) => multiplicateur * x);
    }

    changeBase(model) {
        this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].base = model;
        this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].minY = 0;
        this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].maxY = 0;
        if (this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].courbesSelected) {
            this.prepareNewGraphData(
                this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].courbesSelected
            );
        }
    }

    changePrint(model) {
        this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].printable = model;
    }

    async validerCdc() {
        try {
            let success = false;
            const synthEnCours = await this.SyntheseEntitiesService.getSyntheseEntityById(this.model.synthese.id);

            if (
                this.model.synthese.carteDeControle.syntheseLiee &&
                synthEnCours.carteDeControle.syntheseLiee &&
                this.model.synthese.carteDeControle.syntheseLiee.length > synthEnCours.carteDeControle.syntheseLiee.length
            ) {
                const res = [];
                for (let i = 0; i < this.model.synthese.carteDeControle.syntheseLiee.length; i++) {
                    const element = this.model.synthese.carteDeControle.syntheseLiee[i];
                    const tmp = {
                        idSynthese: element.synthese.id,
                        libelle: element.synthese.libelle,
                        synthese: element.synthese,
                        typeDeSynthese: element.synthese.typeDeSyntheseLibelle
                    };
                    res.push(tmp);
                }
                synthEnCours.carteDeControle.syntheseLiee = res;
                returnedId = await this.SyntheseEntitiesService.updateSyntheseEntity(synthEnCours);
                this.notification.success('SYNTHENTS.UPDATED');
            } else {
                success = true;
            }
            if (success) {
                const toDelete = [];
                const syntheseCdcParams = [];
                for (let i = 0; i < this.model.synthese.carteDeControle.syntheseLiee.length; i++) {
                    const synthLiee = this.model.synthese.carteDeControle.syntheseLiee[i];
                    for (let j = 0; j < synthLiee.res.entete.titresSousEssais.length; j++) {
                        const essai = synthLiee.res.entete.titresSousEssais[j];

                        if (essai.courbesSelected && essai.courbesSelected.length > 0) {
                            const tmpModelCdcParams = {
                                idSyntheseCdc: this.model.synthese.id,
                                idSyntheseEnCours: synthLiee.synthese.id,
                                idSsEssaiEnCours: essai.isTamis !== true ? essai.id : null,
                                idTamisEnCours: essai.isTamis === true ? essai.id : null,
                                commentaire: essai.commentaire,
                                printable: essai.printable,
                                yMax: essai.maxY,
                                yMin: essai.minY,
                                base100: essai.base,
                                couleurDeFond: essai.chartBackgroundColor,
                                courbes: []
                            };

                            for (let k = 0; k < essai.courbesSelected.length; k++) {
                                const courbe = essai.courbesSelected[k];
                                tmpModelCdcParams.courbes.push({
                                    idSsEssai: courbe.essai.isTamis !== true ? courbe.essai.id : null,
                                    idTamis: courbe.essai.isTamis === true ? courbe.essai.id : null,
                                    idCalculStat: courbe.courbe.id,
                                    idSyntheseEssai: courbe.essai.idSynthese,
                                    lissage: courbe.trace.lineTension || 1,
                                    lineHeight: courbe.trace.borderWidth,
                                    lineColor: courbe.trace.borderColor,
                                    pointColor: courbe.trace.pointBorderColor,
                                    pointSize: courbe.trace.pointBorderWidth,
                                    pointType: courbe.trace.pointStyle
                                });
                            }
                            syntheseCdcParams.push(tmpModelCdcParams);
                        } else {
                            const idxToDelete = this.cdcParams.findIndex(
                                (x) =>
                                    x.idSyntheseEnCours === synthLiee.synthese.id &&
                                    ((x.idSousEssaiEnCours === essai.id && x.idSousEssaiEnCours > -1) ||
                                        (x.idTamisEnCours === essai.id && x.idTamisEnCours > -1))
                            );
                            if (idxToDelete > -1) {
                                toDelete.push(this.cdcParams[idxToDelete]);
                            }
                        }
                    }
                }

                if (toDelete && toDelete.length > 0) {
                    for (let l = 0; l < toDelete.length; l++) {
                        const del = toDelete[l];
                        await this.SyntheseEntitiesCdcService.deleteCdcParams(del.id);
                    }
                }

                for (let k = 0; k < syntheseCdcParams.length; k++) {
                    const newSave = syntheseCdcParams[k];
                    const idxCdcP = this.cdcParams.findIndex(
                        (x) =>
                            x.idSyntheseEnCours === newSave.idSyntheseEnCours &&
                            (x.idSousEssaiEnCours === newSave.idSousEssaiEnCours || x.idTamisEnCours === newSave.idTamisEnCours)
                    );

                    if (this.cdcParams && this.cdcParams.length > 0 && idxCdcP > -1) {
                        const id = await this.SyntheseEntitiesCdcService.updateCdcParams(this.cdcParams[idxCdcP].id, newSave);
                        this.notification.success('SYNTHENTS.CARTE_DE_CONTROLE.NOTIFICATIONS.UPDATE');
                    } else {
                        const id = await this.SyntheseEntitiesCdcService.createCdcParams(newSave);
                        this.notification.success('SYNTHENTS.CARTE_DE_CONTROLE.NOTIFICATIONS.SUCCESS');
                    }
                }
            }
            this.$onInit();
        } catch (error) {
            console.error(error);
        }
    }

    modifTrace(graphique, index, tableau) {
        try {
            const that = this;
            this.$uibModal
                .open({
                    template:
                        '<modification-trace-modal modal-instance="$ctrl.uibModalInstance" graphique="$ctrl.graphique"></modification-trace-modal>',
                    controller: [
                        '$uibModalInstance',
                        function ($uibModalInstance) {
                            const $ctrl = this;
                            $ctrl.uibModalInstance = $uibModalInstance;
                            $ctrl.graphique = graphique;
                        }
                    ],
                    controllerAs: '$ctrl',
                    size: 'xxl',
                    backdrop: false
                })
                .result.then(
                    async function (result) {
                        that.prepareNewGraphData(tableau);
                    },
                    function (result) {}
                );
        } catch (ex) {
            this.notification.error(ex);
        }
    }

    onSelectColor(color) {
        this.chartBackgroundColor = color.HEXA.toString();
        this.model.synthese.carteDeControle.syntheseLiee[this.idxSynthese].res.entete.titresSousEssais[this.idxEssai].chartBackgroundColor =
            this.chartBackgroundColor;
        this.chart.options.chartAreaColor = this.chartBackgroundColor;
    }

    openSynthese(syntheseEntity) {
        const _this = this;
        this.$uibModal
            .open({
                template:
                    '<syntheseentities-form modal-instance="$ctrl.uibModalInstance" synth-for-gene="$ctrl.synthForGene"></syntheseentities-form>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.uibModalInstance = $uibModalInstance;
                        $ctrl.synthForGene = syntheseEntity.id;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl',
                backdrop: false
            })
            .result.then(
                function (result) {
                    //_this.closeDetail();
                    if (result) {
                        //result contient une liste (prelevementBlock)
                        _this.$state.go('syntheseentities.result', { obj: result });
                    }
                },
                function (reason) {}
            );
    }

    async print() {
        this.printable = true;
        try {
            await this.recuperationGraphMulti();
            $('#tmp_print').ready(async () => {
                const tmp = {};
                tmp.essais = [];

                for (let i = 0; i < this.model.synthese.carteDeControle.syntheseLiee.length; i++) {
                    const synth = this.model.synthese.carteDeControle.syntheseLiee[i];
                    for (let j = 0; j < synth.res.entete.titresSousEssais.length; j++) {
                        const sousEssai = synth.res.entete.titresSousEssais[j];

                        const essai = {
                            libelleCdc: this.model.synthese.libelle,
                            typeCdc: this.model.synthese.typeDeSyntheseLibelle,
                            libelleSyntheseEnCours: synth.synthese.libelle,
                            libelleSynthese: sousEssai.libelleSynthese,
                            libelleEssaiEnCours: sousEssai.displayTitle,
                            maxY: sousEssai.maxY,
                            minY: sousEssai.minY,
                            commentaire: sousEssai.commentaire,
                            printable: sousEssai.printable,
                            tableau: []
                        };
                        if (sousEssai.isTamis && essai.libelleEssaiEnCours) {
                            essai.libelleEssaiEnCours = 'Tamis ' + essai.libelleEssaiEnCours;
                        }

                        if (sousEssai.printable) {
                            const el = document.getElementById(`graph_tmp_${i}_${synth.toPrint.findIndex((x) => x.id === sousEssai.id)}`);
                            essai.canvas = el ? el.toDataURL('image/png', 1.0) : null;
                        }

                        if (sousEssai.courbesSelected && sousEssai.courbesSelected.length > 0) {
                            for (let k = 0; k < sousEssai.courbesSelected.length; k++) {
                                const tab = sousEssai.courbesSelected[k];
                                const courbe = {
                                    libelleCalcul: tab.courbe.libelle,
                                    libelleEssai: tab.essai.displayTitle,
                                    libelleSynthese: tab.essai.libelleSynthese,
                                    valeur: tab.valeur != -1 ? tab.valeur : ' - '
                                };
                                essai.tableau.push(courbe);
                            }
                            tmp.essais.push(essai);
                        }
                    }
                }
                this.printable = false;

                const cdc = tmp;
                const res = await this.TemplateImpressionService.downloadModal('cdc');
                if (res === 'cancel') {
                    throw res;
                }
                this.notification.info(this.$translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
                    autoDismiss: false,
                    progressBar: true
                });
                const template = await this.SyntheseEntitiesCdcService.print(cdc, res.filename);
                template.pdf = res.pdf;
                await this.TemplateImpressionService.downloadTemplate(template.filename, template, 'cdc');
            });

            return this.cdc;
        } catch (err) {
            console.log(err);
            this.printable = false;
            if (err !== 'cancel') {
                this.notification.error(err.data);
            }
        }
    }

    async recuperationGraphMulti() {
        for (let i = 0; i < this.model.synthese.carteDeControle.syntheseLiee.length; i++) {
            const syn = this.model.synthese.carteDeControle.syntheseLiee[i];
            await this.changeTab(syn);

            for (let j = 0; j < syn.res.entete.titresSousEssais.length; j++) {
                const ess = syn.res.entete.titresSousEssais[j];
                this.changeTabEssai(ess);
            }

            syn.toPrint = syn.res.entete.titresSousEssais.filter((x) => x.printable);
        }
        return;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
