import controller from './massia.application.controller';

export default {
    template: require('./massia.application.html'),
    controller: controller,
    bindings: {
        application: '@',
        client: '@'
    }
};
