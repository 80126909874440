import { statsList, typeSynth } from './syntheseentities.constant';

/* @ngInject */
export default class SyntheseEntitiesResult {
    constructor(
        $scope,
        $state,
        $stateParams,
        SyntheseEntitiesCommunicationService,
        _,
        notification,
        moment,
        $uibModal,
        FormulesService,
        ColorPickerService,
        MassiaApplicationService,
        $translate,
        SyntheseEntitiesService,
        TemplateImpressionService,
        ReferencesControleService,
        CalculsStatistiquesService,
        FTPArchiveeService,
        $timeout,
        SharedFileService,
        globalizationManagementService,
        Interpreteur,
        NormesService,
        MassiaRightsService,
        SettingPortailService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
        this._ = _;
        this.notification = notification;
        this.moment = moment;
        this.$uibModal = $uibModal;
        this.FormulesService = FormulesService;
        this.ColorPickerService = ColorPickerService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$translate = $translate;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.TemplateImpressionService = TemplateImpressionService;
        this.ReferencesControleService = ReferencesControleService;
        this.CalculsStatistiquesService = CalculsStatistiquesService;
        this.FTPArchiveeService = FTPArchiveeService;
        this.$timeout = $timeout;
        this.SharedFileService = SharedFileService;
        this.globalizationManagementService = globalizationManagementService;
        this.Interpreteur = Interpreteur;
        this.NormesService = NormesService;
        this.MassiaRightsService = MassiaRightsService;
        this.SettingPortailService = SettingPortailService;
        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    async $onInit() {
        //this.unregisterReinit = this.SyntheseEntitiesCommunicationService.registerReinit(() => this.initForm());
        this.portailAccess = this.MassiaRightsService.userHasRight('portail', 'read', 'laboratoire');
        this.isHtmlReady = false;
        this.resultat = {};
        this.graphData = this.graphData || {};
        this.conformiteSynthese = -1;
        this.Interpreteur.init(undefined, undefined);
        await this.initForm();

        this.ordreLigne = { tableau: 'aff', indice: 0 }; //ordonne par défaut par le premier champ en affichage
        this.isModeEdit = false;
        this.datasource = {
            get: (index, count, success) => {
                this.lazyLoading = true;
                this.$timeout(() => {
                    // make it zero-based instead of 1-based.
                    index--;
                    const result = [];
                    if (this.resultLines) {
                        for (let i = index; i <= index + (count - 1); i++) {
                            if (!this.resultLines[i]) {
                                continue;
                            }
                            result.push(this.resultLines[i]);
                        }
                    }
                    success(result);
                    this.lazyLoading = false;
                }, 100);
            }
        };
    }

    $onDestroy() {
        //this.unregisterReinit();
    }

    async initForm() {
        try {
            if (this.$state.params.obj && this.$state.params.obj.length != 0) {
                this.startLoading();
                // on met a jour les donnees du scope 'resultat'
                this.obj = this.$state.params.obj;
                if (this.obj.synthese.typeDeSynthese !== typeSynth.carteDeControle) {
                    await this.controle();
                    await this.formateSousEssais();
                    await this.formateCalculs();
                    await this.formateTableau();
                } else {
                    const copy = angular.copy(this.obj);
                    for (let i = 0; i < copy.synthese.carteDeControle.syntheseLiee.length; i++) {
                        const synth = copy.synthese.carteDeControle.syntheseLiee[i];
                        const res = await this.SyntheseEntitiesService.genererSynthese(synth.synthese, 'laboratoire');
                        this.obj = { resultat: res, synthese: synth.synthese };
                        await this.controle();
                        await this.formateSousEssais();
                        await this.formateCalculs();
                        await this.formateTableau();
                        copy.synthese.carteDeControle.syntheseLiee[i] = {
                            ...this.obj,
                            res: this.resultat
                        };
                    }
                    this.obj = copy;
                }

                await this.checkTypeSynth();
            } else {
                this.$state.go('syntheseentities.list');
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async controle() {
        const tempList = await this.SyntheseEntitiesService.getCalculsStatistiques(this.activite);
        const tmpStatList = statsList.filter((e) => e.typeSynth.includes(this.obj.synthese.typeDeSynthese));
        for (let i = 0; i < tmpStatList.length; i++) {
            const stat = tmpStatList[i];
            const tmp = this.obj.synthese.listeSyntheseCritereTraitement.find((e) => e.formule === stat.stat && !e.isGroupe);
            if (!tmp) {
                const statTmp = tempList.find((e) => e.formule === stat.stat && !e.isGroupe && !e.isFuseau);
                if (statTmp) {
                    this.obj.synthese.listeSyntheseCritereTraitement.push(statTmp);
                }
            }
        }
    }

    async checkTypeSynth() {
        switch (this.obj.synthese.typeDeSynthese) {
            case typeSynth.ftp:
                await this.prepareGraphData();
                this.isHtmlReady = true;
                break;

            case typeSynth.recompo:
                this.goRecomposition();
                break;

            case typeSynth.consistance:
                this.goConsistance();
                break;

            case typeSynth.resistance:
                this.goResistance();
                break;

            case typeSynth.controleAcquereur:
                await this.prepareGraphData();
                this.goControleAcquereur();
                break;
            case typeSynth.decompo:
                this.goDecompo();
                break;
            case typeSynth.registre:
                if (this.obj.synthese.byMonth && this.obj.synthese.byProducteur) {
                    this.getByMonthAndProducteur();
                } else if (this.obj.synthese.byMonth) {
                    this.getByMonth();
                } else if (this.obj.synthese.byProducteur) {
                    this.getByProducteur();
                }
                this.isHtmlReady = true;
                break;
            case typeSynth.carteDeControle:
                await this.goCarteDeControle();
                break;
            case typeSynth.controleEnrobes:
                await this.goControleEnrobe();
                break;
            default:
                this.isHtmlReady = true;
                break;
        }
    }

    async interpoler() {
        const essaisSerieDim = [];
        for (let j = 0; j < this.obj.resultat.prelevementBlock.length; j++) {
            var prelevement = this.obj.resultat.prelevementBlock[j];
            let newListeTamisLignes = [];
            //enlever les passants nuls
            newListeTamisLignes = this._.reject(newListeTamisLignes, function (elt) {
                return elt.passant == null;
            });
            if (
                prelevement.listeTamisLignes &&
                prelevement.listeTamisLignes.length > 0 &&
                prelevement.petitDiametre != undefined &&
                prelevement.petitDiametre != null &&
                prelevement.grandDiametre
            ) {
                let v1;
                let v2;
                let r1;
                let r2 = null;
                for (let i = 0; i < this.sousEssaisTamis.length; i++) {
                    const sse = this.sousEssaisTamis[i];
                    if (sse.tamis) {
                        //chercher les tamis de la série
                        const serieTamEss = this._.find(essaisSerieDim, { idEssai: sse.idEssai });
                        let serieTam = undefined;
                        if (!serieTamEss) {
                            try {
                                serieTam = await this.SyntheseEntitiesService.GetSerieTamis(sse.idEssai);
                            } catch (ex) {
                                this.notification.error(ex.data);
                            }
                            //exclure le tamis de 0
                            serieTam = this._.reject(serieTam, function (elt) {
                                return elt.ouverture == 0;
                            });
                            essaisSerieDim.push({ idEssai: sse.idEssai, serie: serieTam });
                        } else {
                            serieTam = serieTamEss.serie;
                        }

                        if (serieTam && serieTam.length > 0) {
                            //exclure le tamis de 0
                            serieTam = this._.reject(serieTam, function (elt) {
                                return elt.ouverture == 0;
                            });
                            var r = sse.ordre;
                            let valeur = '';
                            var tamisPrelev = this._.find(prelevement.listeTamisLignes, {
                                idEssai: sse.idEssai,
                                idTamis: sse.idTamis
                            });
                            if (tamisPrelev) {
                                valeur = this.arrondiValeur(tamisPrelev.passant, sse.arrondi);
                            }
                            if (valeur == '' || valeur == null) {
                                const newObj = {
                                    idPrelevement: prelevement.idPrelevement,
                                    idEssai: sse.idEssai,
                                    idTamis: sse.idTamis,
                                    ouvertureTamis: r,
                                    unite: sse.unite,
                                    arrondi: sse.arrondi,
                                    isComplementaire: false,
                                    dimensionRemarquable: null
                                };

                                //récupération des valeurs à des tamis inférieurs
                                const tamisInf = this._.filter(prelevement.listeTamisLignes, function (elt) {
                                    return elt.ouvertureTamis < r && elt.passant != null;
                                });
                                if (!tamisInf || tamisInf.length == 0) {
                                    //chercher le tamis min pour le produit si pas encore de valeur trouvée dans le prélèvement
                                    //if (v1 == null) {
                                    v1 = 0;
                                    if (r >= prelevement.petitDiametre) {
                                        let tamisPourMin = this._.filter(serieTam, function (elt) {
                                            return elt.ouverture <= prelevement.petitDiametre;
                                        });
                                        if (tamisPourMin && tamisPourMin.length > 0) {
                                            tamisPourMin = this._.sortBy(tamisPourMin, function (elt) {
                                                return elt.ouvertureTamis;
                                            });
                                            r1 = tamisPourMin[0].ouverture;
                                        } else {
                                            r1 = serieTam[0].ouverture;
                                        }
                                    } else {
                                        r1 = 9999;
                                    }
                                }

                                //récupération des valeurs à des tamis supérieurs
                                let tamisSup = this._.filter(prelevement.listeTamisLignes, function (elt) {
                                    return elt.ouvertureTamis > r && elt.passant != null;
                                });
                                if (tamisSup && tamisSup.length > 0) {
                                    tamisSup = this._.sortBy(tamisSup, function (elt) {
                                        return elt.ouvertureTamis;
                                    });
                                    v2 = tamisSup[0].passant;
                                    r2 = tamisSup[0].ouvertureTamis;
                                } else {
                                    //chercher le tamis max pour le produit si plus de valeur suivante dans le prélèvement
                                    v2 = 100;
                                    if (r <= prelevement.grandDiametre) {
                                        let tamisPourMax = this._.filter(serieTam, function (elt) {
                                            return elt.ouverture >= prelevement.grandDiametre;
                                        });
                                        if (tamisPourMax && tamisPourMax.length > 0) {
                                            tamisPourMax = this._.sortBy(tamisPourMax, function (elt) {
                                                return elt.ouvertureTamis;
                                            });
                                            r2 = tamisPourMax[tamisPourMax.length - 1].ouverture;
                                        } else {
                                            r2 = serieTam[serieTam.length - 1].ouverture;
                                        }
                                    } else {
                                        r2 = 0;
                                    }
                                }
                                if (v1 != 0 || v2 != 100) {
                                    if (r1 < r2) {
                                        if (r > r2) {
                                            r = r2;
                                        }
                                        if (r < r1) {
                                            r = r1;
                                        }

                                        try {
                                            r2 = Math.log(r2 / r);
                                            r1 = Math.log(r / r1);
                                            r = (v1 * r2 + v2 * r1) / (r1 + r2);
                                            if (r > 100) {
                                                r = 100;
                                            }
                                            newObj.passant = r;
                                            newObj.interpole = true;
                                        } catch (ex) {
                                            v1 = valeur;
                                            if (tamisPrelev != undefined) {
                                                newListeTamisLignes.push(angular.copy(tamisPrelev));
                                            }
                                            this.notification.error(ex.data);
                                        }
                                    } else {
                                        if (r > r2) {
                                            r = 100;
                                        } else {
                                            r = 0;
                                        }
                                        newObj.passant = r;
                                        newObj.interpole = true;
                                    }
                                    v1 = newObj.passant;
                                    newListeTamisLignes.push(newObj);
                                } else if (tamisPrelev != undefined) {
                                    newListeTamisLignes.push(angular.copy(tamisPrelev));
                                }
                            } else {
                                v1 = valeur;
                                if (tamisPrelev != undefined) {
                                    newListeTamisLignes.push(angular.copy(tamisPrelev));
                                }
                            }
                            r1 = sse.ordre;
                        } else if (tamisPrelev != undefined) {
                            newListeTamisLignes.push(angular.copy(tamisPrelev));
                        }
                    } else if (tamisPrelev != undefined) {
                        newListeTamisLignes.push(angular.copy(tamisPrelev));
                    }
                }
                prelevement.listeTamisLignes = angular.copy(newListeTamisLignes);
            }
        }
    }

    ordonneLignes() {
        //TODO: trier les lignes
    }

    async ordonneEssais() {
        if (this.obj.synthese.typeDeSynthese !== 7 && this.obj.synthese.typeDeSynthese !== 8) {
            if (this.obj.synthese.typeDeSynthese === 10) {
                //contrôle acquéreur, trier en fonction de la FTPA
                if (this.obj.synthese.ftpArchivee) {
                    if (!this.obj.synthese.ftpArchivee.essais) {
                        this.obj.synthese.ftpArchivee = await this.FTPArchiveeService.getFTPArchiveeById(this.obj.synthese.ftpArchivee.id);
                    }
                    for (let idxEss = 0; idxEss < this.essais.length; idxEss++) {
                        const essaiFTP = this.essais[idxEss];
                        if (essaiFTP) {
                            const essaiFTPA = this.obj.synthese.ftpArchivee.essais.find(function (e) {
                                return e.idEssai === essaiFTP.idEssai;
                            });
                            if (essaiFTPA) {
                                const ordreA = essaiFTPA.ordre;
                                this.essais[idxEss].ordreTrame = ordreA;
                            }
                        }
                    }
                    this.essais = _.orderBy(this.essais, ['ordreTrame']);
                    //this.essais = this.essais.sort(ThenBy.firstBy('ordreTrame'));
                }
            } /*  else { //Pour que les essais se trient par ordre manuel daffichage dans la configuration
                switch (this.obj.synthese.triEssais) {
                    case 1: //code
                        this.essais = this.essais.sort(ThenBy.firstBy('codeEssai'));
                        break;
                    case 2: //libellé
                        this.essais = this.essais.sort(ThenBy.firstBy('libelleEssai'));
                        break;
                    case 3: //symbole
                        this.essais = this.essais.sort(ThenBy.firstBy('symboleNormeEssai'));
                        break;
                    case 4: //Trame
                        const trame = await this.SyntheseEntitiesService.getTrameById(this.obj.synthese.idTrame);
                        for (var idxEss = 0; idxEss < this.essais.length; idxEss++) {
                            this.essais[idxEss].ordreTrame = 99999;
                        }
                        for (var idxTrame = 0; idxTrame < trame.mesures.length; idxTrame++) {
                            let idx = 0;
                            while (idx < this.essais.length && this.essais[idx].idEssai != trame.mesures[idxTrame].essaiId) {
                                idx++;
                            }
                            if (idx < this.essais.length) {
                                this.essais[idx].ordreTrame = trame.mesures[idxTrame].position;
                            }
                        }
                        this.essais = this.essais.sort(ThenBy.firstBy('ordreTrame').thenBy('libelleEssai'));
                        break;
                }
            } */
            //this.sousEssaisTamis = this.sousEssaisTamis.sort(ThenBy.firstBy('ordre').thenBy('libelle'));
        }
    }

    async formateTableau() {
        try {
            const estFTP = this.obj.synthese.typeDeSynthese === 1;
            const estCtrlAcq = this.obj.synthese.typeDeSynthese === 10;
            if (
                this.obj.synthese.hasInterpolationValeursManquantes ||
                this.obj.synthese.typeDeSynthese === 2 ||
                this.obj.synthese.typeDeSynthese === 12
            ) {
                await this.interpoler();
            }

            if (estCtrlAcq && !this.obj.synthese.ftpArchivee) {
                this.notification.warning(this.$translate.instant('FTP_ARCHIVEE.MANQUANT'));
            }

            const that = this;
            //reformater le tableau pour ne pas avoir à calculer les valeurs/stats à la volée + pouvoir éditer
            this.obj.synthese.listeSyntheseCritereAffichage = _.orderBy(this.obj.synthese.listeSyntheseCritereAffichage, ['position']);
            this.resultat = { entete: { titresAffichage: [], titresEssais: [], titresSousEssais: [] }, lignes: [], stats: [] };
            //entête : colonnes d'affichage
            for (let j = 0; j < this.obj.synthese.listeSyntheseCritereAffichage.length; j++) {
                const colonneEntete = { titre: this.obj.synthese.listeSyntheseCritereAffichage[j].titre };
                this.resultat.entete.titresAffichage.push(colonneEntete);
            }
            //entête : colonnes d'essais
            await this.ordonneEssais(); //trier les essais
            for (let j = 0; j < this.essais.length; j++) {
                //essais
                //console.log(this.essais[j]);
                const colonneEssai = {
                    id: this.essais[j].idEssai,
                    libelle: this.essais[j].libelleEssai,
                    code: this.essais[j].codeEssai,
                    symbole: this.setSymboleEssai(this.essais[j].symboleNormeEssai, this.essais[j].codeEssai),
                    isComplementaire: this.essais[j].isComplementaire,
                    nomSelection: this.essais[j].nomSelection
                };

                if (colonneEssai.nomSelection == '' && this.obj.synthese.listeSyntheseCritereValeurBlocks.length > 1) {
                    colonneEssai.nomSelection = this.obj.synthese.listeSyntheseCritereValeurBlocks[0].nomComposant;
                }
                let nbSSess = 0;
                var sousEssaisTamisActu = this._.filter(this.sousEssaisTamis, { idEssai: this.essais[j].idEssai });
                sousEssaisTamisActu = this._.sortBy(sousEssaisTamisActu, 'ordre');
                for (let k = 0; k < sousEssaisTamisActu.length; k++) {
                    let nbIteration = 1;
                    if (sousEssaisTamisActu[k].iterable && sousEssaisTamisActu[k].nbIteration > 0) {
                        //nbIteration = sousEssaisTamisActu[k].nbIteration;
                        for (let i = 0; i < this.obj.resultat.prelevementBlock.length; i++) {
                            const prelevement = this.obj.resultat.prelevementBlock[i];
                            const prelSousEssais = prelevement.listeSousEssaiLignes;

                            const lstSsEss = prelSousEssais.filter(function (e) {
                                return e.idEssai == that.essais[j].idEssai && e.idSousEssai == that.sousEssaisTamis[k].idSousEssai;
                            });
                            nbIteration = lstSsEss.length > nbIteration ? lstSsEss.length : nbIteration;
                        }
                    }
                    for (let i = 0; i < nbIteration; i++) {
                        const titre = sousEssaisTamisActu[k].libelle;
                        let idSousEssaiTamis = 0;
                        if (sousEssaisTamisActu[k].tamis) {
                            idSousEssaiTamis = this.essais[j].selectedTamis
                                ? this.essais[j].selectedTamis.find((x) => x.idTamis === sousEssaisTamisActu[k].idTamis).idSousEssaiTamis
                                : this.essais[j].listeTamisHeaders.find((x) => x.idTamis === sousEssaisTamisActu[k].idTamis).idSousEssaiTamis;
                        }
                        const colonneSsEssaiTamis = {
                            id: sousEssaisTamisActu[k].tamis ? idSousEssaiTamis : sousEssaisTamisActu[k].idSousEssai,
                            idTamis: sousEssaisTamisActu[k].idTamis,
                            idEssai: this.essais[j].idEssai,
                            codeSsEss: sousEssaisTamisActu[k].code,
                            displayTitle: titre,
                            titre: sousEssaisTamisActu[k].libelle,
                            unite: sousEssaisTamisActu[k].unite,
                            isComplementaire: colonneEssai.isComplementaire,
                            isTamis: sousEssaisTamisActu[k].tamis,
                            horsRef: sousEssaisTamisActu[k].horsRef,
                            dimRq: sousEssaisTamisActu[k].dimRq,
                            iterationIndex: i,
                            indiceSelection: this.essais[j].indiceSelection,
                            ouvertureTamis: sousEssaisTamisActu[k].ouvertureTamis,
                            libelleEssai: sousEssaisTamisActu[k].libelleEssai,
                            symbole: this.setSymboleEssai(this.essais[j].symboleNormeEssai, this.essais[j].codeEssai)
                        };
                        this.resultat.entete.titresSousEssais.push(colonneSsEssaiTamis);
                        nbSSess++;
                    }
                }

                colonneEssai.nbSousEssais = nbSSess;
                this.resultat.entete.titresEssais.push(colonneEssai);
            }
            //prélèvements (lignes)
            //récupère les essais en bloc
            if (this.obj && this.obj.resultat && this.obj.resultat.prelevementBlock) {
                const plkEss = this._.map(this.obj.resultat.prelevementBlock, 'listeSousEssaiLignes');
                const essaisFlat = this._.flatten(plkEss);
                const plkTam = this._.map(this.obj.resultat.prelevementBlock, 'listeTamisLignes');
                const tamisFlat = this._.flatten(plkTam);
                this.ordonneLignes(); //trier les lignes
                for (let i = 0; i < this.obj.resultat.prelevementBlock.length; i++) {
                    const prelevement = this.obj.resultat.prelevementBlock[i];
                    const ligne = {
                        idPrelevement: prelevement.idPrelevement,
                        valeursAffichage: [],
                        valeursEssais: [],
                        idFamille: prelevement.idFamille,
                        codeDomaine: prelevement.codeDomaine,
                        compoResVisee: prelevement.compoResVisee,
                        compoResEstime: prelevement.compoResEstime,
                        ecartee: false,
                        numsBloc: prelevement.numsBloc
                    };

                    //valeurs d'affichage
                    for (let j = 0; j < this.obj.synthese.listeSyntheseCritereAffichage.length; j++) {
                        const affich = this.obj.synthese.listeSyntheseCritereAffichage[j];
                        const valAff = { valeur: this.getInfoAffichage(prelevement, affich) };
                        if (affich.codeDomaine === 'Produits' && affich.champ === 67) {
                            affich.hasOwnProperty('codeDomaine') &&
                            affich.hasOwnProperty('champ') &&
                            affich.codeDomaine === 'Produits' &&
                            affich.champ === 67
                                ? (valAff.titre = 'compoResVisee')
                                : '';
                        }
                        ligne.valeursAffichage.push(valAff);
                    }

                    //valeurs d'essais
                    for (let j = 0; j < this.resultat.entete.titresSousEssais.length; j++) {
                        const titre = this.resultat.entete.titresSousEssais[j].titre;
                        const sse = this.sousEssaisTamis.find(function (e) {
                            return e.libelle === titre;
                        });
                        this.resultat.entete.titresSousEssais[j].code = sse.codeSsEss;
                        const valeurEssPrel = this.getResultatEssai(prelevement, sse);
                        const valEss = {
                            valeur: valeurEssPrel.valeur,
                            interpole: valeurEssPrel.interpole,
                            dimRq: valeurEssPrel.dimRq,
                            arrondi: sse.arrondi,
                            tamis: sse.tamis,
                            idEssai: sse.idEssai,
                            idSousEssai: sse.idSousEssai,
                            id: sse.idSousEssai,
                            idTamis: sse.idTamis,
                            isComplementaire: this.resultat.entete.titresSousEssais[j].isComplementaire,
                            codeSousEssai: titre && titre.toString().indexOf(' /') >= 0 ? titre.substring(0, titre.indexOf(' /')) : titre,
                            code: sse.code,
                            isDimRq: sse.dimRq
                        };

                        //vérifier la conformité
                        const borne = estCtrlAcq ? this.getBornesFTPA(sse) : this.getBornes(sse, '@VAL');
                        let priseEnCompteU = false;
                        if (estCtrlAcq) {
                            const nbrFTP = sse.idTamis
                                ? this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                                      idEssai: sse.idEssai,
                                      isTamis: true,
                                      idTamis: sse.idTamis,
                                      formuleCalcStat: '@NBR'
                                  })
                                : this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                                      idEssai: sse.idEssai,
                                      isTamis: false,
                                      idSsEss: sse.idSousEssai,
                                      formuleCalcStat: '@NBR'
                                  });
                            const nbValeursReelFrn = nbrFTP && nbrFTP.length == 1 ? parseFloat(nbrFTP[0].valeur) : 0;
                            const nReferenceAcq = this.obj.synthese.ftpArchivee.nombreResultatsFournisseur;
                            if (nReferenceAcq && nbValeursReelFrn < nReferenceAcq) {
                                //moins de n valeurs du fournisseur
                                if (!this.obj.synthese.critereF2 && borne) {
                                    const tabFiltreAcq = sse.idTamis
                                        ? this._.filter(tamisFlat, { idEssai: sse.idEssai, idTamis: sse.idTamis })
                                        : this._.filter(essaisFlat, { idEssai: sse.idEssai, idSousEssai: sse.idSousEssai });
                                    const nbValeursReelAcq = tabFiltreAcq.length;
                                    if (nReferenceAcq && nbValeursReelAcq >= nReferenceAcq) {
                                        priseEnCompteU = true;
                                    }
                                } else if (this.obj.synthese.critereF2) {
                                    priseEnCompteU = true;
                                }
                            } else {
                                priseEnCompteU = true; //si + de n valeurs fournisseur, vérifier valeurs entre VSI-U et VSS+U (avec U)
                            }
                        } else {
                            if (!this.obj.synthese.critereF2 && borne) {
                                const tabFiltre = sse.idTamis
                                    ? this._.filter(tamisFlat, { idEssai: sse.idEssai, idTamis: sse.idTamis })
                                    : this._.filter(essaisFlat, { idEssai: sse.idEssai, idSousEssai: sse.idSousEssai });
                                const nbValeursReel = tabFiltre.length;
                                const nReference = this.obj.resultat.referentiel ? this.obj.resultat.referentiel.nombreResultatsN : null;
                                if (nReference && nbValeursReel >= nReference) {
                                    priseEnCompteU = true;
                                }
                            } else if (this.obj.synthese.critereF2) {
                                priseEnCompteU = true;
                            }
                        }
                        valEss.conformite = this.controleConformite(valEss.valeur, borne, priseEnCompteU);
                        ligne.valeursEssais.push(valEss);
                    }

                    this.resultat.lignes.push(ligne);
                }
            }
            //traitements stats
            await this.relanceTraitementsStat();
        } catch (error) {
            console.error(error.data);
        }
    }

    async relanceTraitementsStat() {
        this.calculs = _.orderBy(this.calculs, ['position']);
        //this.calculs = this.calculs.sort(ThenBy.firstBy('position'));
        this.resultat.stats = [];
        const estFTP = this.obj.synthese.typeDeSynthese === 1;
        let ctrlMoyFTP = estFTP && this.obj.resultat.referentiel ? this.obj.resultat.referentiel.enveloppeNorme : null;
        let ctrlEctFTP = estFTP && this.obj.resultat.referentiel ? this.obj.resultat.referentiel.controleETNorme : null;
        const estCtrlAcq = this.obj.synthese.typeDeSynthese === 10;
        if (estCtrlAcq) {
            ctrlMoyFTP = this.obj.synthese.ftpArchivee.enveloppeNorme;
            ctrlEctFTP = this.obj.synthese.ftpArchivee.controleETNorme;
        }
        const plkEss = this._.map(this.obj.resultat.prelevementBlock, 'listeSousEssaiLignes');
        const essaisFlat = this._.flatten(plkEss);
        const plkTam = this._.map(this.obj.resultat.prelevementBlock, 'listeTamisLignes');
        const tamisFlat = this._.flatten(plkTam);

        const statEcartType =
            estFTP || estCtrlAcq
                ? this.obj.synthese.listeSyntheseCritereTraitement.find(function (e) {
                      return e.formule === '@ECT' && !e.isGroupe;
                  })
                : null;
        const statMoyenne =
            estFTP || estCtrlAcq
                ? this.obj.synthese.listeSyntheseCritereTraitement.find(function (e) {
                      return e.formule === '@MOY' && !e.isGroupe;
                  })
                : null;
        for (let i = 0; i < this.calculs.length; i++) {
            const stat = {
                titre: this.calculs[i].libelle,
                statsEssais: [],
                couleurFond: this.getColorFromHSL(this.calculs[i].couleurFond),
                couleurTexte: this.getColorFromHSL(this.calculs[i].couleurTexte),
                groupementIndex: this.calculs[i].groupementIndex,
                formule: this.calculs[i].formule,
                code: this.calculs[i].code,
                isImprimable: this.calculs[i].isImprimable,
                isControle: this.calculs[i].isControle,
                idCalcStat: this.calculs[i].idCalcStat,
                isGroupement: this.calculs[i].isGroupement,
                isGroupementGlissant: this.calculs[i].isGroupementGlissant,
                nbValGroupe: this.calculs[i].nbValGroupe,
                isCumul: this.calculs[i].isCumul,
                nbValMinCumul: this.calculs[i].nbValMinCumul,
                isTranspose: this.calculs[i].isTranspose,
                isLastGroupOnly: this.calculs[i].isLastGroupOnly,
                arrondi: this.calculs[i].arrondi,
                arrondiDeLEssai: this.calculs[i].arrondiDeLEssai,
                decimaleSupp: this.calculs[i].decimaleSupp
            };
            if (this.calculs[i].estMini) {
                stat.titre += ' (' + this.$translate.instant('SYNTHENTS.MINIMUM') + ')';
            } else if (this.calculs[i].estMaxi) {
                stat.titre += ' (' + this.$translate.instant('SYNTHENTS.MAXIMUM') + ')';
            }

            for (let j = 0; j < this.resultat.entete.titresSousEssais.length; j++) {
                const ssEss = this.resultat.entete.titresSousEssais[j];
                const ess = this.resultat.entete.titresEssais.find((e) => e.id === this.resultat.entete.titresSousEssais[j].idEssai);

                ssEss.code = ess.code;
                const titre = this.resultat.entete.titresSousEssais[j].titre;
                const sse = this.sousEssaisTamis.find(function (e) {
                    return e.libelle === titre;
                });

                sse.codeEssai = ess.code;
                const statlEss = {
                    valeur: await this.getStatEssai(this.calculs[i], sse),
                    isComplementaire: sse.isComplementaire,
                    conformite: -1,
                    idEssai: ssEss.idEssai,
                    codeEssai: ess.code,
                    codeSousEssai: ssEss.codeSsEss,
                    idSsEss: ssEss.id
                };
                if (this.calculs[i].isDate) {
                    statlEss.isDate = true;
                }
                if (statlEss.valeur !== null && statlEss.valeur !== undefined && statlEss.valeur !== '') {
                    let borneStat = undefined;
                    let estFTPEss = estFTP;
                    let ignoreAcq = false;

                    if (estCtrlAcq) {
                        //si moins de n valeurs fournisseur en contrôle acquéreur, on fait les mêmes contrôles qu'en FTP
                        const nbrFTP = sse.idTamis
                            ? this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                                  idEssai: sse.idEssai,
                                  isTamis: true,
                                  idTamis: sse.idTamis,
                                  formuleCalcStat: '@NBR'
                              })
                            : this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                                  idEssai: sse.idEssai,
                                  isTamis: false,
                                  idSsEss: sse.idSousEssai,
                                  formuleCalcStat: '@NBR'
                              });
                        const nbValeursReelFrn = nbrFTP && nbrFTP.length == 1 ? parseFloat(nbrFTP[0].valeur) : 0;
                        const nReferenceAcq = this.obj.synthese.ftpArchivee.nombreResultatsFournisseur;
                        if (nReferenceAcq && nbValeursReelFrn < nReferenceAcq) {
                            estFTPEss = true;
                            ignoreAcq = true;
                            //ne faire les contrôles sur moyenne et écart-type que si critère F2 (forcé ou bVal>=n)
                            if (!this.obj.synthese.critereF2) {
                                var tabFiltre = sse.idTamis
                                    ? this._.filter(tamisFlat, { idEssai: sse.idEssai, idTamis: sse.idTamis })
                                    : this._.filter(essaisFlat, { idEssai: sse.idEssai, idSousEssai: sse.idSousEssai });
                                var nbValeursReel = tabFiltre.length;
                                if (nReferenceAcq && nbValeursReel < nReferenceAcq) {
                                    estFTPEss = false;
                                }
                            }
                        }
                    } else if (estFTPEss) {
                        //ne faire les contrôles sur moyenne et écart-type que si critère F2 (forcé ou bVal>=n)
                        if (!this.obj.synthese.critereF2) {
                            var tabFiltre = sse.idTamis
                                ? this._.filter(tamisFlat, { idEssai: sse.idEssai, idTamis: sse.idTamis })
                                : this._.filter(essaisFlat, { idEssai: sse.idEssai, idSousEssai: sse.idSousEssai });
                            var nbValeursReel = tabFiltre.length;
                            const nReference = this.obj.resultat.referentiel ? this.obj.resultat.referentiel.nombreResultatsN : null;
                            if (nReference && nbValeursReel < nReference) {
                                estFTPEss = false;
                            }
                        }
                    }

                    if (estFTPEss && statEcartType) {
                        //FTP : contrôles en dur
                        borneStat = estCtrlAcq ? this.getBornesFTPA(sse) : this.getBornes(sse, '@VAL');
                        if (borneStat != null && borneStat != undefined) {
                            //chercher l'écart-type
                            const ect = await this.getStatEssai(statEcartType, sse);
                            //contrôle de la moyenne entre VSI+1.25*ECT et VSS-1.25*ECT (1.25 paramétrable)
                            if (this.calculs[i].formule == '@MOY' && ctrlMoyFTP && statMoyenne) {
                                //chercher la moyenne
                                const moy = await this.getStatEssai(statMoyenne, sse);
                                try {
                                    const borneControle = {
                                        borneSuperieure:
                                            borneStat.borneSuperieure != null &&
                                            borneStat.borneSuperieure != undefined &&
                                            borneStat.borneSuperieure != ''
                                                ? Number(borneStat.borneSuperieure) - Number(ctrlMoyFTP) * ect
                                                : null,
                                        borneInferieure:
                                            borneStat.borneInferieure != null &&
                                            borneStat.borneInferieure != undefined &&
                                            borneStat.borneInferieure != ''
                                                ? Number(borneStat.borneInferieure) + Number(ctrlMoyFTP) * ect
                                                : null
                                    };
                                    statlEss.conformite = this.controleConformite(moy, borneControle, false);
                                } catch (ex) {
                                    console.error('erreur moy FTP', ect, ctrlMoyFTP);
                                }
                            }
                            //contrôle de l'écart-type inférieur à e/3.3 (3.3 paramétrable)
                            if (
                                this.calculs[i].formule == '@ECT' &&
                                ctrlEctFTP &&
                                borneStat.isETControle &&
                                borneStat.etendueReference != null &&
                                borneStat.etendueReference != undefined &&
                                borneStat.etendueReference != ''
                            ) {
                                //récupérer e
                                try {
                                    const borneControle = { borneSuperieure: Number(borneStat.etendueReference) / Number(ctrlEctFTP) };
                                    statlEss.conformite = this.controleConformite(ect, borneControle, false);
                                } catch (ex) {
                                    console.error('erreur ect FTP', borneStat.etendueReference, ctrlEctFTP);
                                }
                            }
                            //n'afficher l'écart-type max que si il y a un contrôle de l'écart-type max (e/3.3 par défaut)
                            if (
                                ctrlEctFTP &&
                                (this.calculs[i].formule === '@ENORME()/' + ctrlEctFTP || this.calculs[i].formule === '@ENORME/' + ctrlEctFTP) &&
                                !borneStat.isETControle
                            ) {
                                statlEss.valeur = '';
                            }
                        }
                    } else if (estCtrlAcq) {
                        //Contrôle acquéreur avec nb val fournisseur>=n : contrôles en dur
                        borneStat = this.getBornesFTPA(sse);
                        const tabFiltreAcq = sse.idTamis
                            ? this._.filter(tamisFlat, { idEssai: sse.idEssai, idTamis: sse.idTamis })
                            : this._.filter(essaisFlat, { idEssai: sse.idEssai, idSousEssai: sse.idSousEssai });
                        const nbValeursReelAcq = tabFiltreAcq.length;
                        const naReferenceAcq = this.obj.synthese.ftpArchivee.nombreResultatsAcquereur;
                        if (!ignoreAcq && naReferenceAcq && nbValeursReelAcq >= naReferenceAcq) {
                            //au moins na valeurs de l'acquéreur, contrôle de la moyenne
                            //contrôle de la moyenne Xa entre Xf-U et Xf+U (Xa moyenne acquéreur, Xf moyenne fournisseur)
                            if (this.calculs[i].formule == '@MOY' && statMoyenne) {
                                //chercher la moyenne du fournisseur
                                const moyFTP = sse.idTamis
                                    ? this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                                          idEssai: sse.idEssai,
                                          isTamis: true,
                                          idTamis: sse.idTamis,
                                          formuleCalcStat: '@MOY'
                                      })
                                    : this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                                          idEssai: sse.idEssai,
                                          isTamis: false,
                                          idSsEss: sse.idSousEssai,
                                          formuleCalcStat: '@MOY'
                                      });
                                const moyenneFrnXf = moyFTP && moyFTP.length == 1 ? parseFloat(moyFTP[0].valeur) : 0;
                                //chercher la moyenne de l'acquéreur
                                const moyXa = await this.getStatEssai(statMoyenne, sse);
                                try {
                                    const borneControle = {
                                        borneSuperieure:
                                            borneStat.incertitudeReference != null &&
                                            borneStat.incertitudeReference != undefined &&
                                            borneStat.incertitudeReference != ''
                                                ? moyenneFrnXf + parseFloat(borneStat.incertitudeReference)
                                                : null,
                                        borneInferieure:
                                            borneStat.incertitudeReference != null &&
                                            borneStat.incertitudeReference != undefined &&
                                            borneStat.incertitudeReference != ''
                                                ? moyenneFrnXf - parseFloat(borneStat.incertitudeReference)
                                                : null
                                    };
                                    statlEss.conformite = this.controleConformite(moyXa, borneControle, false);
                                } catch (ex) {
                                    console.error('erreur moy CTRLACQ', ect, ctrlMoyFTP);
                                }
                            }
                        }
                    } else {
                        borneStat = this.getBornes(sse, this.calculs[i].formule);
                        if (borneStat != null && borneStat != undefined) {
                            statlEss.conformite = this.controleConformite(statlEss.valeur, borneStat, false);
                        }
                    }
                }
                stat.statsEssais.push(statlEss);
            }
            this.resultat.stats.push(stat);
        }
        // On traite le CS de conformite après les calculs de conformite
        // de toutes les valeurs d'essai et de toutes les autres CS
        this.resultat.stats.forEach((stat) => {
            if (stat.formule == '@CONF') {
                const lignesEssais = [];
                this.resultat.lignes.forEach((element) => {
                    if (!element.ecartee) {
                        lignesEssais.push(element.valeursEssais);
                    }
                });
                const statEssai = stat.statsEssais;
                const statsEssais = this._.map(this.resultat.stats, 'statsEssais');
                const tabConformites = ['Conforme', 'Alerte', 'Non Conforme', 'Conforme (U)', 'Non Contrôlé'];

                for (let i = 0; i < statEssai.length; i++) {
                    const lstConfByEssai = [];
                    const lstConfByStat = [];
                    let lignesConf = [];
                    let statsConf = [];

                    lignesEssais.forEach((ligne) => {
                        lstConfByEssai.push(ligne[i].conformite);
                    });
                    statsEssais.forEach((statEss) => {
                        lstConfByStat.push(statEss[i].conformite);
                    });

                    lignesConf = this._.uniq(lstConfByEssai);
                    statsConf = this._.uniq(lstConfByStat);
                    const lstConformites = this._.union(lignesConf, statsConf);
                    let confConverted = this.getConformite(lstConformites);
                    if (confConverted === null || typeof confConverted === 'undefined') {
                        confConverted = 4;
                    }
                    stat.statsEssais[i] = Object.assign({}, stat.statsEssais[i], { valeur: tabConformites[confConverted] });
                }
            }
        });
        this.verifieConformiteSynthese();
        await this.prepareGraphData();
    }

    verifieConformiteSynthese() {
        this.conformiteSynthese = -1;
        // Récupérer les conformités des valeurs qui ne sont pas ecartees
        let lignesEssais = [];
        this.resultat.lignes.forEach((element) => {
            if (!element.ecartee) {
                lignesEssais.push(element.valeursEssais);
            }
        });
        lignesEssais = this._.flatten(lignesEssais);
        let lignesConf = this._.map(lignesEssais, 'conformite');
        lignesConf = this._.uniq(lignesConf);
        //récupérer les conformités des stats
        let statsEssais = this._.map(this.resultat.stats, 'statsEssais');
        statsEssais = this._.flatten(statsEssais);
        let statsConf = this._.map(statsEssais, 'conformite');
        statsConf = this._.uniq(statsConf);
        const conformites = this._.union(lignesConf, statsConf);
        this.conformiteSynthese = this.getConformite(conformites);
    }

    getConformite(conformites) {
        if (this._.includes(conformites, 2)) {
            return 2;
        }
        if (this._.includes(conformites, 3)) {
            return 3;
        }
        if (this._.includes(conformites, 1)) {
            return 1;
        }
        if (this._.includes(conformites, 0)) {
            return 0;
        }
    }
    controleConformite(valeur, borne, priseEnCompteU) {
        if (!borne || valeur === null || valeur === undefined || valeur === '') {
            return -1;
        }
        let conformite = 0;
        if (borne.borneInferieure) {
            if (valeur < parseFloat(borne.borneInferieure)) {
                if (borne.incertitudeReference && priseEnCompteU) {
                    if (valeur < parseFloat(borne.borneInferieure) - parseFloat(borne.incertitudeReference)) {
                        conformite = 2;
                    } else {
                        conformite = 3;
                    }
                } else {
                    conformite = 2;
                }
            } else {
                if (borne.alerteInferieure && valeur < parseFloat(borne.alerteInferieure)) {
                    conformite = 1;
                }
            }
        }
        if (borne.borneSuperieure) {
            if (valeur > parseFloat(borne.borneSuperieure)) {
                if (borne.incertitudeReference && priseEnCompteU) {
                    if (valeur > parseFloat(borne.borneSuperieure) + parseFloat(borne.incertitudeReference)) {
                        conformite = 2;
                    } else {
                        conformite = 3;
                    }
                } else {
                    conformite = 2;
                }
            } else {
                if (conformite < 2 && parseFloat(borne.alerteSuperieure) && valeur > parseFloat(borne.alerteSuperieure)) {
                    conformite = 1;
                }
            }
        }
        return conformite;
    }

    isSousEssaiTamisSansValeur(objSsEss) {
        for (let idSETSV = 0; idSETSV < this.obj.resultat.prelevementBlock.length; idSETSV++) {
            const res = this.getResultatEssai(this.obj.resultat.prelevementBlock[idSETSV], objSsEss).valeur;
            if (res != undefined && res != null && res != '') {
                return false;
            }
        }
        return true;
    }

    async formateSousEssais() {
        this.essais = [];
        this.sousEssaisTamis = [];

        if (
            this.obj.synthese.typeDeSynthese === typeSynth.controleAcquereur &&
            (!this.obj.synthese.listeLiensSETS || this.obj.synthese.listeLiensSETS.length <= 0) &&
            this.obj.synthese.ftpArchivee
        ) {
            this.obj.synthese.listeLiensSETS = await this.FTPArchiveeService.getFTPEssais(this.obj.synthese.ftpArchivee.id);
            if (this.obj.synthese.listeLiensSETS) {
                for (let i = 0; i < this.obj.synthese.listeLiensSETS.length; i++) {
                    const lien = this.obj.synthese.listeLiensSETS[i];
                    if (lien.resultFromGetTamisBySousMesuresMethode && lien.resultFromGetTamisBySousMesuresMethode.length > 0) {
                        lien.listeTamisHeaders = await this.formatListeTamisHeaders(lien.resultFromGetTamisBySousMesuresMethode);
                    }
                }
            }
        }

        //Les essais ajoutés manuellement
        if (this.obj.synthese.listeLiensSETS) {
            for (let i = 0; i < this.obj.synthese.listeLiensSETS.length; i++) {
                const ess = this.obj.synthese.listeLiensSETS[i];
                //mettre à jour le nom de sélection s'il a été choisi
                if (ess.indiceSelection != null && ess.indiceSelection != undefined) {
                    const selection = this._.find(this.obj.synthese.listeSyntheseCritereValeurBlocks, { indiceSelection: ess.indiceSelection });
                    if (selection) {
                        ess.nomSelection = selection.nomComposant;
                    }
                }
                let nbEnfants = 0;
                //sous-essais
                if (ess.listeSousEssaisHeaders) {
                    // && (!ess.listeTamisHeaders || ess.listeTamisHeaders.length === 0)) {
                    for (let j = 0; j < ess.listeSousEssaisHeaders.length; j++) {
                        const sousEssai = ess.listeSousEssaisHeaders[j];
                        if (sousEssai.selected && sousEssai.idNature !== 8) {
                            //TODO: SP ne pas mettre le sous-essai d'analyse granulo : là, ignore tous les autres sous-essais de l'essai granulo
                            const objSsEss = {
                                libelle: sousEssai.codeLibelleSousEssai,
                                code: sousEssai.codeSsEss,
                                tamis: false,
                                idEssai: sousEssai.idEssai,
                                idSousEssai: sousEssai.idSousEssai,
                                idNature: sousEssai.idNature,
                                idTamis: undefined,
                                isComplementaire: ess.isComplementaire,
                                arrondi: sousEssai.arrondi,
                                unite: sousEssai.unite,
                                ordre: sousEssai.ordre, //TODO: aller chercher ordre sous-essai,
                                grandeurMinimum: sousEssai.grandeurMinimum,
                                grandeurMaximum: sousEssai.grandeurMaximum,
                                normeEssai: ess.normeEssai,
                                ouvertureTamis: null,
                                libelleEssai: ess.libelleEssai,
                                symbole: this.setSymboleEssai(ess.symboleNormeEssai, ess.codeEssai)
                            };
                            objSsEss.horsRef = this.getBornes(objSsEss, '@VAL') == undefined;
                            if (this.obj.synthese.listeSyntheseCritereValeurBlocks.length > 1) {
                                if (ess.indiceSelection) {
                                    objSsEss.indiceSelection = ess.indiceSelection;
                                } else {
                                    objSsEss.indiceSelection = this.obj.synthese.listeSyntheseCritereValeurBlocks[0].indiceSelection;
                                }
                            }
                            this.sousEssaisTamis.push(objSsEss);
                            nbEnfants++;
                        }
                    }
                }
                //tamis
                if (ess.listeTamisHeaders) {
                    for (let j = 0; j < ess.listeTamisHeaders.length; j++) {
                        const tamis = ess.listeTamisHeaders[j];
                        if (tamis.selected) {
                            const objTamis = {
                                libelle: tamis.label,
                                tamis: true,
                                idEssai: ess.idEssai,
                                idSousEssai: undefined,
                                idTamis: tamis.idTamis,
                                isComplementaire: ess.isComplementaire,
                                arrondi: tamis.arrondi,
                                unite: tamis.unite,
                                ordre: tamis.ordre,
                                grandeurMinimum: 0,
                                grandeurMaximum: 100,
                                normeEssai: '',
                                ouvertureTamis: parseFloat(tamis.label),
                                libelleEssai: ess.libelleEssai,
                                symbole: this.setSymboleEssai(ess.symboleNormeEssai, ess.codeEssai),
                                dimRq: tamis.dimensionRemarquable || ''
                            };
                            objTamis.horsRef = this.getBornes(objTamis, '@VAL') == undefined;
                            if (this.obj.synthese.listeSyntheseCritereValeurBlocks.length > 1) {
                                if (ess.indiceSelection) {
                                    objTamis.indiceSelection = ess.indiceSelection;
                                } else {
                                    objTamis.indiceSelection = this.obj.synthese.listeSyntheseCritereValeurBlocks[0].indiceSelection;
                                }
                            }

                            if (
                                !(this.obj.synthese.hideEssaisHorsReference && objTamis.horsRef) &&
                                !(this.obj.synthese.hideEssaisNonRealises && this.isSousEssaiTamisSansValeur(objTamis))
                            ) {
                                this.sousEssaisTamis.push(objTamis);
                                nbEnfants++;
                            } else {
                                tamis.selected = false;

                                // const tamisIndex = ess.selectedTamis.findIndex(function (e) { return e.idTamis === objTamis.idTamis; });
                                // if (tamisIndex >= 0) {
                                //     ess.selectedTamis.splice(tamisIndex, 1);
                                //     tamis.selected = false;
                                // }
                            }
                        }
                    }
                }
                //dim Rq
                if (ess.listeDimRqHeaders) {
                    for (let j = 0; j < ess.listeDimRqHeaders.length; j++) {
                        const dimRq = ess.listeDimRqHeaders[j];
                        if (dimRq.selected) {
                            const objDimRq = {
                                libelle: dimRq.label,
                                tamis: true,
                                dimRq: true,
                                idEssai: ess.idEssai,
                                idSousEssai: undefined,
                                idTamis: dimRq.idTamis,
                                idDimRq: dimRq.idDimRq,
                                isComplementaire: ess.isComplementaire,
                                arrondi: dimRq.arrondi,
                                unite: dimRq.unite,
                                ordre: dimRq.ordre,
                                grandeurMinimum: 0,
                                grandeurMaximum: 100,
                                normeEssai: '',
                                ouvertureTamis: null, //parseFloat(dimRq.label),
                                libelleEssai: ess.libelleEssai,
                                symbole: this.setSymboleEssai(ess.symboleNormeEssai, ess.codeEssai)
                            };
                            objDimRq.horsRef = true; //this.getBornes(objDimRq, '@VAL') == undefined;
                            if (this.obj.synthese.listeSyntheseCritereValeurBlocks.length > 1) {
                                if (ess.indiceSelection) {
                                    objDimRq.indiceSelection = ess.indiceSelection;
                                } else {
                                    objDimRq.indiceSelection = this.obj.synthese.listeSyntheseCritereValeurBlocks[0].indiceSelection;
                                }
                            }

                            if (
                                !(this.obj.synthese.hideEssaisHorsReference && objDimRq.horsRef) /*&&
                                !(this.obj.synthese.hideEssaisNonRealises && this.isSousEssaiTamisSansValeur(objDimRq))*/ //TODO: voir si utile à faire autrement
                            ) {
                                this.sousEssaisTamis.push(objDimRq);
                                nbEnfants++;
                            } else {
                                dimRq.selected = false;
                            }
                        }
                    }
                }
                if (nbEnfants > 0) {
                    ess.ordreTrame = undefined;
                    const objEss = ess;
                    objEss.ordreTrame = undefined;
                    objEss.nbEnfants = nbEnfants;
                    this.essais.push(objEss);
                }
            }
        }
    }

    async formatListeTamisHeaders(temp) {
        const tempListeTamisHeaders = [];
        for (let i = 0; i < temp.length; i++) {
            const blocSousEssai = temp[i];
            const grpElt = {
                idTamis: -1,
                idSerieTamis: blocSousEssai.idSerieTamis,
                label: blocSousEssai.codeLibelleSerieTamis,

                msGroup: true,
                selected: false
            };
            tempListeTamisHeaders.push(grpElt);

            for (let index = 0; index < blocSousEssai.listeTamisHeaders.length; index++) {
                const tamis = blocSousEssai.listeTamisHeaders[index];
                const tamisElt = {
                    idTamis: tamis.idTamis,
                    idSerieTamis: tamis.idSerieTamis,
                    dimRq: tamis.dimensionRemarquable,
                    // SL on convertit la valeur int en string car une fois dans le iSteven,
                    // 0 n'est pas considéré comme une valeur donc affiche "label"
                    label: tamis.valeurTamis.toString(),
                    selected: tamis.selected || false,
                    arrondi: tamis.arrondi,
                    unite: blocSousEssai.unite,
                    ordre: tamis.ordre
                };
                tempListeTamisHeaders.push(tamisElt);
            }
        }

        return tempListeTamisHeaders;
    }

    getColspanEss(index) {
        return this.essais[index].nbEnfants;
    }

    async formateCalculs() {
        this.calculs = [];
        let position = 0;
        const tempList = await this.SyntheseEntitiesService.getCalculsStatistiques(this.activite);
        for (let i = 0; i < this.obj.synthese.listeSyntheseCritereTraitement.length; i++) {
            const calc = this.obj.synthese.listeSyntheseCritereTraitement[i];
            if (calc === undefined) {
                continue;
            }
            calc.estMini = false;
            calc.estMaxi = false;
            calc.couleurTexte = calc.couleurTexte ? calc.couleurTexte : { hue: 0, saturation: 0, luminosity: 1 };
            calc.couleurFond = calc.couleurFond ? calc.couleurFond : { hue: 0, saturation: 0, luminosity: 100 };

            position = position + 1;
            const libelleSvg = calc.libelle;

            //VP: Vu avec SP le 22/01, je touche pas, je rajoute mon cas au dessous
            if (calc.formule2) {
                //calc.estMini = true;
                //chercher le calcul stat simple
                const calcSimple = tempList.find(function (e) {
                    return e.formule === calc.formule && !e.isGroupe && !e.isFuseau;
                });
                if (calcSimple) {
                    calc.code = calcSimple.code;
                    calc.libelle = calcSimple.libelle;
                    calc.idCalcStat = calcSimple.id;
                    calc.isGroupement = calcSimple.isGroupement;
                    calc.isGroupementGlissant = calcSimple.isGroupementGlissant;
                    calc.nbValGroupe = calcSimple.nbValGroupe;
                    calc.arrondi = calc.arrondi;
                    calc.arrondiDeLEssai = calc.arrondiDeLEssai;
                    calc.isCumul = calcSimple.isCumul;
                    calc.nbValMinCumul = calcSimple.nbValMinCumul;
                    calc.isTranspose = calcSimple.isTranspose;
                    calc.isLastGroupOnly = calcSimple.isLastGroupOnly;
                    calc.decimaleSupp = calcSimple.decimaleSupp;
                } else {
                    calc.estMini = true;
                }
            }

            if (calc.formule) {
                const calcSimple = tempList.find(function (e) {
                    return e.code === calc.code && !e.isGroupe && !e.isFuseau;
                });
                if (calcSimple) {
                    calc.code = calcSimple.code;
                    calc.libelle = calcSimple.libelle;
                    calc.idCalcStat = calcSimple.id;
                    calc.isGroupement = calcSimple.isGroupement;
                    calc.isGroupementGlissant = calcSimple.isGroupementGlissant;
                    calc.nbValGroupe = calcSimple.nbValGroupe;
                    calc.arrondi = calc.arrondi;
                    calc.arrondiDeLEssai = calc.arrondiDeLEssai;
                    calc.isCumul = calcSimple.isCumul;
                    calc.nbValMinCumul = calcSimple.nbValMinCumul;
                    calc.isTranspose = calcSimple.isTranspose;
                    calc.isLastGroupOnly = calcSimple.isLastGroupOnly;
                    calc.decimaleSupp = calcSimple.decimaleSupp;
                }
            }
            if (calc.formule2) {
                const calcSimple = tempList.find(function (e) {
                    return e.formule === calc.formule2 && !e.isGroupe && !e.isFuseau;
                });
                const calc2 = {
                    idSynthCritTraitement: calc.idSynthCritTraitement,
                    idCalcStat: calcSimple ? calcSimple.id : calc.idCalcStat,
                    libelle: calcSimple ? calcSimple.libelle : libelleSvg,
                    code: calcSimple ? calcSimple.code : calc.code,
                    position: position,
                    couleurTexte: calc.couleurTexte ? calc.couleurTexte : { hue: 0, saturation: 0, luminosity: 1 },
                    couleurFond: calc.couleurFond ? calc.couleurFond : { hue: 0, saturation: 0, luminosity: 100 },
                    formule: calc.formule2,
                    formule2: '',
                    arrondi: calc.arrondi,
                    arrondiDeLEssai: calc.arrondiDeLEssai,
                    estMaxi: calcSimple ? false : true,
                    estMini: false,
                    isImprimable: calc.isImprimable,
                    isControle: calc.isControle,
                    isGroupement: calcSimple ? calcSimple.isGroupement : false,
                    isGroupementGlissant: calcSimple ? calcSimple.isGroupementGlissant : false,
                    nbValGroupe: calcSimple ? calcSimple.nbValGroupe : null,
                    isCumul: calcSimple ? calcSimple.isCumul : false,
                    nbValMinCumul: calcSimple ? calcSimple.nbValMinCumul : null,
                    isTranspose: calcSimple ? calcSimple.isTranspose : false,
                    isLastGroupOnly: calcSimple ? calcSimple.isLastGroupOnly : false,
                    decimaleSupp: calcSimple.decimaleSupp
                };
                this.calculs.push(calc2);
                position = position + 1;
            }
            calc.position = position;
            this.calculs.push(calc);
        }
    }

    getInfoAffichage(lig, affich) {
        // console.log(affich.codeDomaine);
        switch (affich.codeDomaine) {
            case 'FamBeton':
                if (lig.familleBetonProduit) {
                    switch (affich.champ) {
                        case 1:
                            return lig.familleBetonProduit.codeFamille;
                        case 2:
                            return lig.familleBetonProduit.libelleFamille;
                    }
                }
                break;
            case 'Prelevements':
            case 'Saisies':
                switch (affich.champ) {
                    case 1:
                        return lig.codePrelevement;
                    case 2:
                        return lig.libellePrelevement;
                    case 9:
                        return this.moment(lig.datePrelevement, this.dateFormat + ' HH:mm').format(this.dateFormat);
                    case 16:
                        return this.moment(lig.datePrelevement, this.dateFormat + ' HH:mm').format('HH:mm');
                    case 3:
                        const car = this._.find(lig.listeCaracteristiquesPrelevement, {
                            idCaracteristique: affich.idCaracteristique,
                            idEnregistrement: lig.idPrelevement
                        });
                        if (car) {
                            return car.valeur;
                        }
                        break;
                    case 5:
                        return lig.typePrelevement;
                    case 35:
                        return lig.faitParPrelevement;
                }
                break;
            case 'SiteProd':
                switch (affich.champ) {
                    case 1:
                        return lig.codeProducteur;
                    case 2:
                        return lig.libelleProducteur;
                    case 3:
                        const car = this._.find(lig.listeCaracteristiquesProducteur, {
                            idCaracteristique: affich.idCaracteristique,
                            idEnregistrement: lig.idProducteur
                        });
                        if (car) {
                            return car.valeur;
                        }
                        break;
                    case 5:
                        return lig.typeProducteur;
                    case 21:
                        return lig.societeProducteur;
                    // case 21:
                    //     return lig.rsProducteur;
                }
                break;
            case 'SiteClient':
                switch (affich.champ) {
                    case 1:
                        return lig.codeClient;
                    case 2:
                        return lig.libelleClient;
                    case 3:
                        const car = this._.find(lig.listeCaracteristiquesClient, {
                            idCaracteristique: affich.idCaracteristique,
                            idEnregistrement: lig.idClient
                        });
                        if (car) {
                            return car.valeur;
                        }
                        break;
                    case 5:
                        return lig.typeClient;
                    case 21:
                        return lig.societeClient;
                    // case 21:
                    //     return lig.rsClient;
                }
                break;
            case 'SiteLabo':
            case 'SiteAudit':
                switch (affich.champ) {
                    case 1:
                        return lig.codeLaboAudit;
                    case 2:
                        return lig.libelleLaboAudit;
                    case 3:
                        const car = this._.find(lig.listeCaracteristiquesLaboratoireAuditeur, {
                            idCaracteristique: affich.idCaracteristique,
                            idEnregistrement: lig.idLaboAudit
                        });
                        if (car) {
                            return car.valeur;
                        }
                        break;
                    case 5:
                        return lig.typeLaboAudit;
                    case 21:
                        return lig.societeLaboAudit;
                    // case 21:
                    //     return lig.rsLaboAudit;
                }
                break;
            case 'Themes':
                switch (affich.champ) {
                    case 1:
                        return lig.codeTheme;
                    case 2:
                        return lig.libelleTheme;
                }
                break;
            case 'Chantiers':
                switch (affich.champ) {
                    case 1:
                        return lig.codeChantier;
                    case 2:
                        return lig.libelleChantier;
                    case 9:
                        return lig.dateDebutChantier;
                    case 11:
                        return lig.dateFinChantier;
                    case 3:
                        const car = this._.find(lig.listeCaracteristiquesChantier, {
                            idCaracteristique: affich.idCaracteristique,
                            idEnregistrement: lig.idChantier
                        });
                        if (car) {
                            return car.valeur;
                        }
                        break;
                    case 5:
                        return lig.typeChantier;
                }
                break;
            case 'ProduitsCom':
                switch (affich.champ) {
                    case 1:
                        return lig.codeProduitCom;
                    case 2:
                        return lig.libelleProduitCom;
                    case 5:
                        return lig.typeProduitCom;
                    case 10:
                        return lig.familleProduitCom;
                    case 3:
                        const car = this._.find(lig.listeCaracteristiquesProduitCom, {
                            idCaracteristique: affich.idCaracteristique,
                            idEnregistrement: lig.idProduitCom
                        });
                        if (car) {
                            return car.valeur;
                        }
                        break;
                    case 118:
                        return lig.code2ProduitCom;
                }
                break;
            case 'Produits':
                switch (affich.champ) {
                    case 1:
                        return lig.codeProduit;
                    case 2:
                        return lig.libelleProduit;
                    case 14:
                        return lig.petitDiametre;
                    case 15:
                        return lig.grandDiametre;
                    case 3:
                        const car = this._.find(lig.listeCaracteristiquesProduit, {
                            idCaracteristique: affich.idCaracteristique,
                            idEnregistrement: lig.idProduit
                        });
                        if (car) {
                            return car.valeur;
                        }
                        break;
                    case 5:
                        return lig.typeProduit;
                    case 10:
                        return lig.familleProduit;
                    case 17:
                        return this.checkCompo(lig, affich);
                    case 67:
                        return lig.compoResVisee;
                    case 68:
                        return lig.compoResEstime;
                    case 20:
                        return lig.dicEssaisCompoFck;
                    case 118:
                        return lig.code2Produit;
                }
                break;
            case 'Entites':
                switch (affich.champ) {
                    case 1:
                        return lig.codePointMesure;
                    case 2:
                        return lig.libellePointMesure;
                    case 3:
                        const car = this._.find(lig.listeCaracteristiquesPointMesure, {
                            idCaracteristique: affich.idCaracteristique,
                            idEnregistrement: lig.idPointMesure
                        });
                        if (car) {
                            return car.valeur;
                        }
                        break;
                    case 5:
                        return lig.typePointMesure;
                }
                break;
            case 'Tickets':
                switch (affich.champ) {
                    case 37:
                        return lig.numeroBL;
                }
                break;
        }
        return '';
    }

    checkCompo(ligne, affichage) {
        let result = [];
        switch (affichage.champCompo) {
            case 1: //Code
                result = [];
                for (let i = 0; i < ligne.listeCompositionProduit.length; i++) {
                    const compo = ligne.listeCompositionProduit[i];
                    if (compo.typeProduit === affichage.codeType) {
                        result.push(compo.code);
                    }
                }
                return result;
            case 2: //Libelle
                result = [];
                for (let i = 0; i < ligne.listeCompositionProduit.length; i++) {
                    const compo = ligne.listeCompositionProduit[i];
                    if (compo.typeProduit === affichage.codeType) {
                        result.push(compo.libelleProduit);
                    }
                }
                return result;
            case 5: //Type
                break;
            case 10: //Famille
                result = [];
                for (let i = 0; i < ligne.listeCompositionProduit.length; i++) {
                    const compo = ligne.listeCompositionProduit[i];
                    if (compo.typeProduit === affichage.codeType) {
                        result.push(compo.libelleProduit);
                    }
                }
                return result;
            case 18: //Quantite
                result = [];
                for (let i = 0; i < ligne.listeCompositionProduit.length; i++) {
                    const compo = ligne.listeCompositionProduit[i];
                    if (compo.typeProduit === affichage.codeType) {
                        result.push(compo.quantite);
                    }
                }
                return result;
            case 90: //Producteur
                result = [];
                for (let i = 0; i < ligne.listeCompositionProduit.length; i++) {
                    const compo = ligne.listeCompositionProduit[i];
                    if (compo.typeProduit === affichage.codeType) {
                        result.push(compo.producteurLibelle);
                    }
                }
                return result;
            default:
                break;
        }
    }

    getResultatEssai(lig, essai, index = 0) {
        const resultatEssai = { valeur: '', interpole: false };
        const tmp = lig.listeSousEssaiLignes.find((e) => e.idEssai === essai.idEssai && e.idSousEssai === essai.idSousEssai);
        if (tmp) {
            essai.idNature = tmp.idNature;
        }
        //si il n'y a qu'un bloc de sélection ou que l'essai vient bien du bon bloc
        if (
            this.obj.synthese.listeSyntheseCritereValeurBlocks.length == 1 ||
            this.obj.synthese.typeDeSynthese == 2 ||
            lig.numsBloc.includes(essai.indiceSelection)
        ) {
            //si c'est un essai
            if (!essai.tamis) {
                const lstSousEssais = lig.listeSousEssaiLignes.filter(function (e) {
                    return e.idEssai === essai.idEssai && e.idSousEssai === essai.idSousEssai;
                });
                for (let i = 0; i < lstSousEssais.length; i++) {
                    const sousEssai = lstSousEssais[i];
                    if (sousEssai) {
                        if (sousEssai.idNature === 10 || sousEssai.idNature === 11) {
                            if (lstSousEssais[i] === null) {
                                lstSousEssais[i] = [];
                            }
                            lstSousEssais[i] = sousEssai.valeur;
                            if (!Array.isArray(lstSousEssais[i])) {
                                if (
                                    essai.arrondi !== undefined &&
                                    essai.arrondi !== '' &&
                                    (sousEssai.idNatureOrigine === 3 || sousEssai.idNatureOrigine === 62)
                                ) {
                                    lstSousEssais[i] = this.arrondiValeur(lstSousEssais[i], sousEssai.arrondi);
                                }
                            } else {
                                for (let j = 0; j < lstSousEssais[i].length; j++) {
                                    if (
                                        essai.arrondi !== undefined &&
                                        essai.arrondi !== '' &&
                                        (sousEssai.idNatureOrigine === 3 || sousEssai.idNatureOrigine === 62)
                                    ) {
                                        lstSousEssais[i][j] = this.arrondiValeur(lstSousEssais[i][j], sousEssai.arrondi);
                                    }
                                }
                            }
                        } else if (
                            sousEssai.arrondi !== undefined &&
                            sousEssai.arrondi !== '' &&
                            (sousEssai.idNature === 3 || sousEssai.idNature === 62)
                        ) {
                            lstSousEssais[i] = this.arrondiValeur(sousEssai.valeur, sousEssai.arrondi);
                        } else {
                            lstSousEssais[i] = sousEssai.valeur;
                        }
                    }
                }

                if (essai.idNature !== 10 && essai.idNature !== 11) {
                    if (lstSousEssais && lstSousEssais.length > index) {
                        resultatEssai.valeur = lstSousEssais[index];
                    } else {
                        resultatEssai.valeur = lstSousEssais[0];
                    }
                } else {
                    resultatEssai.valeur = lstSousEssais;
                }
            }
            //si c'est un tamis
            else {
                if (!essai.dimRq) {
                    const tamis = this._.find(lig.listeTamisLignes, { idEssai: essai.idEssai, idTamis: essai.idTamis });
                    if (tamis) {
                        resultatEssai.valeur = this.arrondiValeur(tamis.passant, essai.arrondi);
                        resultatEssai.interpole = tamis.interpole;
                        resultatEssai.dimRq = tamis.dimensionRemarquable;
                    }
                } else {
                    //si c'est une dimRq
                    const dimRq = this._.find(lig.listeTamisLignes, {
                        idEssai: essai.idEssai,
                        dimensionRemarquable: essai.libelle
                    });
                    if (dimRq) {
                        resultatEssai.valeur = this.arrondiValeur(dimRq.passant, essai.arrondi);
                        resultatEssai.interpole = dimRq.interpole;
                        resultatEssai.dimRq = dimRq.dimensionRemarquable;
                    }
                }
            }
        }
        // console.log(resultatEssai);
        return resultatEssai;
    }

    arrondiValeur(valeur, arrondi) {
        const objetToRound = [{ key: valeur, val: valeur, rounder: arrondi }];
        return this.Interpreteur.getRoundedValue(objetToRound);
    }

    async getStatEssai(stat, essStat) {
        let listVal = undefined;
        let listConf = undefined;
        let listDimRq = undefined;
        let resultat = undefined;
        let formule = stat.formule;

        //stats particulières
        if (stat.formule.includes('@NORME')) {
            const essNorme = essStat.normeEssai;
            if (stat.formule == '@NORME') {
                return essNorme;
            }
            formule = formule.replace(/@NORME/g, essNorme);
        }
        if (stat.formule.includes('@DATEDERN')) {
            const dateDern = this.getDerniereDateValeurs(essStat);
            if (stat.formule == '@DATEDERN') {
                stat.isDate = true;
                return dateDern;
            }
            formule = formule.replace(/@DATEDERN/g, dateDern);
        }
        if (stat.formule.includes('@DIMRQ')) {
            if (!listDimRq) {
                listDimRq = this.getListeDimRq(essStat);
            }
            if (listDimRq.length > 0) {
                if (stat.formule == '@DIMRQ') {
                    return listDimRq[0];
                }
                formule = formule.replace(/@DIMRQ/g, listDimRq[0]);
            }
        }
        if (stat.formule.includes('@NBCONF')) {
            let resultatNbConf = 0;
            if (!listConf) {
                listConf = this.getListeConformites(essStat);
            }
            if (listConf.length > 0) {
                const conformes = _.filter(listConf, function (num) {
                    return num == 0;
                });
                if (conformes) {
                    resultatNbConf = conformes.length;
                } else {
                    resultatNbConf = 0;
                }
            }
            formule = formule.replace(/@NBCONF/g, resultatNbConf);
        }
        if (stat.formule.includes('@POURCCONF')) {
            let resultatPrcConf = 0;
            if (!listConf) {
                listConf = this.getListeConformites(essStat);
            }
            if (listConf.length > 0) {
                const conformes = _.filter(listConf, function (num) {
                    return num == 0;
                });
                if (conformes) {
                    resultatPrcConf = (conformes.length * 100) / listConf.length;
                } else {
                    resultatPrcConf = 0;
                }
            }
            formule = formule.replace(/@POURCCONF/g, resultatPrcConf);
        }

        // On récupère la catégorie de la norme de l'essai
        if (stat.formule.includes('@CATEG')) {
            const normeCatEssai = this.getNormeCatEssai(essStat);
            if (stat.formule == '@CATEG') {
                return normeCatEssai;
            }
            formule = formule.replace(/@CATEG/g, normeCatEssai);
        }

        //construire la liste de valeurs
        if (!listVal) {
            listVal = await this.getListeValeurs(essStat, stat);
        }
        let strForFormule = '';
        if (listVal.length > 0) {
            for (let i = 0; i < listVal.length; i++) {
                const val = essStat.idNature === 3 || essStat.idNature === 62 ? this.FormulesService.strEnDouble(listVal[i]) : listVal[i];
                if (strForFormule != '') {
                    strForFormule += ';';
                }
                strForFormule += val;
            }
        }

        if (this.Interpreteur) {
            this.Interpreteur.calculatedFormules = [];
        }

        //stats récupérant des valeurs de références
        if (this.obj.synthese.typeDeSynthese === 10) {
            this.Interpreteur.borne = this.getBornesFTPA(essStat);
        } else {
            this.Interpreteur.borne = this.getBornes(essStat, '@VAL');
        }

        this.Interpreteur.listeValeursStr = strForFormule;

        //traiter la demande de moyenne de la FTPA à part
        let ignoreFormule = false;
        if (this.obj.synthese.typeDeSynthese === 10 && formule.includes('@FTPMOY')) {
            const moyFTP = essStat.idTamis
                ? this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                      idEssai: essStat.idEssai,
                      isTamis: true,
                      idTamis: essStat.idTamis,
                      formuleCalcStat: '@MOY'
                  })
                : this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                      idEssai: essStat.idEssai,
                      isTamis: false,
                      idSsEss: essStat.idSousEssai,
                      formuleCalcStat: '@MOY'
                  });
            if (moyFTP && moyFTP.length == 1) {
                formule = formule.replace(/@FTPMOY/g, moyFTP[0].valeur);
            } else {
                ignoreFormule = true;
            }
        }
        //appel au calcul de formule
        resultat = ignoreFormule ? '' : await this.Interpreteur.calculeFormule(formule, false, essStat.idNature);

        if (resultat !== undefined && resultat !== null && resultat !== '') {
            if (!(essStat.idNature === 0 || essStat.idNature === 1 || essStat.idNature === 5 || essStat.idNature === 63 || essStat.idNature === 60)) {
                if (!isNaN(resultat)) {
                    //gestion des min/max
                    if (stat.limiterGrandeur) {
                        if (essStat.grandeurMinimum != undefined && essStat.grandeurMinimum != null && essStat.grandeurMinimum > resultat) {
                            resultat = essStat.grandeurMinimum;
                        }
                        if (essStat.grandeurMaximum != undefined && essStat.grandeurMaximum != null && essStat.grandeurMaximum < resultat) {
                            resultat = essStat.grandeurMaximum;
                        }
                    }
                    //gestion de l'arrondi
                    if (stat.arrondiDeLEssai) {
                        let newArrondi = stat.decimaleSupp !== 0 && stat.decimaleSupp !== null ? this.Interpreteur.formatDecSupp(essStat.arrondi, stat.decimaleSupp) : essStat.arrondi;
                        return this.arrondiValeur(resultat, newArrondi);
                    }
                    if (stat.arrondi != undefined && stat.arrondi != null) {
                        return this.arrondiValeur(resultat, stat.arrondi);
                    }
                    return this.arrondiValeur(resultat, 0);
                }
            } else {
                return resultat;
            }
        }
        return '';
    }

    getBornes(essStat, formuleCalcul) {
        if (this.obj.resultat && this.obj.resultat.referentiel) {
            if (essStat.tamis) {
                return this._.find(this.obj.resultat.referentiel.mesures, {
                    essaiId: essStat.idEssai,
                    tamis: essStat.tamis,
                    ouvertureTamis: parseFloat(essStat.libelle),
                    calculFormule: formuleCalcul
                });
                //return this.obj.resultat.referentiel.mesures.find(x => x.essaiId === essStat.idEssai && x.tamis === essStat.tamis);
            }
            return this._.find(this.obj.resultat.referentiel.mesures, {
                essaiId: essStat.idEssai,
                tamis: essStat.tamis,
                sousEssaiId: essStat.idSousEssai,
                calculFormule: formuleCalcul
            });
        }
        return undefined;
    }

    getBornesFTPA(essStat) {
        //créer la borne à partir des valeurs de la FTP
        const borneFTPA = {};
        //VSI
        const vsiFTP = essStat.idTamis
            ? this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: true,
                  idTamis: essStat.idTamis,
                  formuleCalcStat: '@VSI'
              })
            : this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: false,
                  idSsEss: essStat.idSousEssai,
                  formuleCalcStat: '@VSI'
              });
        if (vsiFTP && vsiFTP.length == 1) {
            borneFTPA.borneInferieure = vsiFTP[0].valeur;
        }
        //VSS
        const vssFTP = essStat.idTamis
            ? this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: true,
                  idTamis: essStat.idTamis,
                  formuleCalcStat: '@VSS'
              })
            : this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: false,
                  idSsEss: essStat.idSousEssai,
                  formuleCalcStat: '@VSS'
              });
        if (vssFTP && vssFTP.length == 1) {
            borneFTPA.borneSuperieure = vssFTP[0].valeur;
        }
        //U
        const uFTP = essStat.idTamis
            ? this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: true,
                  idTamis: essStat.idTamis,
                  formuleCalcStat: '@UNORME'
              })
            : this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: false,
                  idSsEss: essStat.idSousEssai,
                  formuleCalcStat: '@UNORME'
              });
        if (uFTP && uFTP.length == 1) {
            borneFTPA.incertitudeReference = uFTP[0].valeur;
        }
        //e
        const eFTP = essStat.idTamis
            ? this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: true,
                  idTamis: essStat.idTamis,
                  formuleCalcStat: '@ENORME'
              })
            : this._.filter(this.obj.synthese.ftpArchivee.valeurs, {
                  idEssai: essStat.idEssai,
                  isTamis: false,
                  idSsEss: essStat.idSousEssai,
                  formuleCalcStat: '@ENORME'
              });
        if (eFTP && eFTP.length == 1) {
            borneFTPA.etendueReference = eFTP[0].valeur;
        }
        const essFTPA = essStat.idTamis
            ? this._.filter(this.obj.synthese.ftpArchivee.essais, {
                  idEssai: essStat.idEssai,
                  isTamis: true,
                  idSsEssTamis: essStat.idTamis
              })
            : this._.filter(this.obj.synthese.ftpArchivee.essais, {
                  idEssai: essStat.idEssai,
                  isTamis: false,
                  idSsEss: essStat.idSousEssai
              });
        if (essFTPA && essFTPA.length == 1) {
            borneFTPA.isETControle = essFTPA[0].isCtrlEcartType;
        }
        return borneFTPA;
    }

    async getListeValeurs(essStat, stat) {
        let listVal = [];

        if (this.Interpreteur) {
            this.Interpreteur.calculatedFormules = [];
        }
        for (let i = 0; i < this.resultat.lignes.length; i++) {
            const ligneResultat = this.resultat.lignes[i];
            if (!ligneResultat.ecartee) {
                const originalPrelv = this.obj.resultat.prelevementBlock.find((e) => e.idPrelevement === ligneResultat.idPrelevement);

                if (!essStat.tamis) {
                    const sousEssai = this._.find(ligneResultat.valeursEssais, {
                        tamis: essStat.tamis,
                        idEssai: essStat.idEssai,
                        idSousEssai: essStat.idSousEssai
                    });
                    //console.log(sousEssai);
                    if (sousEssai && sousEssai.valeur !== null && sousEssai.valeur !== undefined && sousEssai.valeur !== '') {
                        if (
                            stat.isTranspose &&
                            originalPrelv &&
                            originalPrelv.familleBetonProduit &&
                            originalPrelv.familleBetonProduit.regleTranspoFormule
                        ) {
                            let arrValues = sousEssai.valeur;
                            if (!Array.isArray(arrValues)) {
                                arrValues = [arrValues];
                            }

                            for (let j = 0; j < arrValues.length; j++) {
                                const formuleTransp = originalPrelv.familleBetonProduit.regleTranspoFormule.replace(/RMES/g, arrValues[j]);
                                sousEssai.valeurTranspo = await this.Interpreteur.calculeFormule(formuleTransp);
                                listVal.push(sousEssai.valeurTranspo);
                            }
                        } else {
                            listVal.push(sousEssai.valeur);
                            listVal = this._.flatten(listVal);
                        }
                    }
                } else {
                    const tamis = this._.find(ligneResultat.valeursEssais, {
                        tamis: true,
                        idEssai: essStat.idEssai,
                        idTamis: essStat.idTamis
                    });
                    if (tamis && tamis.valeur !== null && tamis.valeur !== undefined && tamis.valeur !== '') {
                        if (
                            stat.isTranspose &&
                            originalPrelv &&
                            originalPrelv.familleBetonProduit &&
                            originalPrelv.familleBetonProduit.regleTranspoFormule
                        ) {
                            let arrValues = tamis.valeur;
                            if (!Array.isArray(arrValues)) {
                                arrValues = [arrValues];
                            }

                            for (let j = 0; j < arrValues.length; j++) {
                                const formuleTransp = originalPrelv.familleBetonProduit.regleTranspoFormule.replace(/RMES/g, arrValues[j]);
                                tamis.valeurTranspo = await this.Interpreteur.calculeFormule(formuleTransp);

                                listVal.push(tamis.valeurTranspo);
                            }
                        } else {
                            listVal.push(tamis.valeur);
                        }
                    }
                }
            }
        }

        return listVal;
    }

    getListeDimRq(essStat) {
        const listDimRq = [];
        this.resultat.lignes.forEach((ligneResultat) => {
            if (!ligneResultat.ecartee) {
                if (essStat.tamis) {
                    const tamis = this._.find(ligneResultat.valeursEssais, {
                        tamis: true,
                        idEssai: essStat.idEssai,
                        idTamis: essStat.idTamis
                    });
                    if (tamis && tamis.dimRq) {
                        listDimRq.push(tamis.dimRq);
                    }
                }
            }
        });
        return listDimRq;
    }

    getListeConformites(essStat) {
        const listConf = [];
        this.resultat.lignes.forEach((ligneResultat) => {
            if (!ligneResultat.ecartee) {
                if (!essStat.tamis) {
                    const sousEssai = this._.find(ligneResultat.valeursEssais, {
                        tamis: essStat.tamis,
                        idEssai: essStat.idEssai,
                        idSousEssai: essStat.idSousEssai
                    });
                    if (sousEssai && sousEssai.valeur) {
                        listConf.push(sousEssai.conformite);
                    }
                } else {
                    const tamis = this._.find(ligneResultat.valeursEssais, {
                        tamis: true,
                        idEssai: essStat.idEssai,
                        idTamis: essStat.idTamis
                    });
                    if (tamis && tamis.valeur) {
                        listConf.push(tamis.conformite);
                    }
                }
            }
        });
        return listConf;
    }

    getDerniereDateValeurs(essStat) {
        let date = undefined;
        this.obj.resultat.prelevementBlock.forEach((lig) => {
            if (!essStat.tamis) {
                const sousEssai = this._.find(lig.listeSousEssaiLignes, {
                    idEssai: essStat.idEssai,
                    idSousEssai: essStat.idSousEssai
                });
                if (sousEssai && sousEssai.valeur) {
                    if (!date) {
                        date = this.moment(lig.datePrelevement, this.dateFormat + ' HH:mm'); //SP 14/10/22 ne pas formater sinon ne compare pas des dates
                    } else {
                        const nouvDate = this.moment(lig.datePrelevement, this.dateFormat + ' HH:mm'); /*.format(this.dateFormat)*/ //SP 14/10/22 ne pas formater sinon ne compare pas des dates
                        if (date < nouvDate) {
                            date = nouvDate;
                        }
                    }
                }
            } else {
                const tamis = this._.find(lig.listeTamisLignes, { idEssai: essStat.idEssai, idTamis: essStat.idTamis });
                if (tamis && tamis.passant) {
                    if (!date) {
                        date = this.moment(lig.datePrelevement, this.dateFormat + ' HH:mm'); //SP 14/10/22 ne pas formater sinon ne compare pas des dates
                    } else {
                        const nouvDate = this.moment(lig.datePrelevement, this.dateFormat + ' HH:mm'); /*.format(this.dateFormat)*/ //SP 14/10/22 ne pas formater sinon ne compare pas des dates
                        if (date < nouvDate) {
                            date = nouvDate;
                        }
                    }
                }
            }
        });
        return !date ? undefined : date.format(this.dateFormat);
    }

    getColorFromHSL(coul) {
        if (coul) {
            const hexa = this.ColorPickerService.hslToRgb(coul.hue, coul.saturation, coul.luminosity);
            return 'rgba(' + hexa.red + ',' + hexa.green + ',' + hexa.blue + ', ' + (coul.alpha || coul.alpha === 0 ? coul.alpha : '1') + ')';
        }
        return 'rgb(0,0,0,100)';
    }

    openSynthese() {
        const _this = this;
        _this.paramsSynthese = this.obj.synthese;
        this.$uibModal
            .open({
                template:
                    '<syntheseentities-form modal-instance="$ctrl.uibModalInstance" synth-for-gene="undefined" params-synthese="$ctrl.paramsSynthese"></syntheseentities-form>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.paramsSynthese = _this.paramsSynthese;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl',
                backdrop: false
            })
            .result.then(
                async function (result) {
                    if (result) {
                        _this.$state.go('syntheseentities.result', { obj: result });
                        _this.obj = result;
                        await _this.formateSousEssais();
                        await _this.formateCalculs();
                        await _this.formateTableau();
                        await _this.prepareGraphData();
                    }
                },
                function (reason) {}
            );
    }

    async openPrelevement(lig) {
        const _this = this;
        _this.id = lig.idPrelevement;
        _this.idFamille = lig.idFamille;
        _this.codeDomaine = lig.codeDomaine;
        this.$uibModal
            .open({
                template:
                    '<prelevement modal-instance="$ctrl.uibModalInstance" id="$ctrl.id" id-famille="$ctrl.idFamille" code-domaine="$ctrl.codeDomaine"></prelevement>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.objSynthese = _this.objSynthese;
                        $ctrl.uibModalInstance = $uibModalInstance;
                        $ctrl.id = _this.id;
                        $ctrl.idFamille = _this.idFamille;
                        $ctrl.codeDomaine = _this.codeDomaine;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl',
                backdrop: false
            })
            .result.then(
                async function (result) {
                    if (result) {
                        await _this.regenererSynthese();
                    }
                },
                function (reason) {}
            );
    }

    async regenererSynthese() {
        const res = await this.SyntheseEntitiesService.genererSynthese(this.obj.synthese, this.MassiaApplicationService.getApplication());
        this.obj.resultat = res;
        await this.formateSousEssais();
        await this.formateCalculs();
        await this.formateTableau();
        await this.prepareGraphData();
    }

    goRecomposition() {
        const model = angular.copy(this.obj);
        model.formattedResult = angular.copy(this.resultat);
        model.essais = angular.copy(this.essais);
        model.sousEssaisTamis = angular.copy(this.sousEssaisTamis);
        model.calculs = angular.copy(this.calculs);

        this.$state.go('syntheseentities.recomposition', { obj: model });
    }

    goConsistance() {
        const model = angular.copy(this.obj);
        model.formattedResult = angular.copy(this.resultat);
        model.essais = angular.copy(this.essais);
        model.sousEssaisTamis = angular.copy(this.sousEssaisTamis);
        model.calculs = angular.copy(this.calculs);

        this.$state.go('syntheseentities.consistance', { obj: model });
    }

    goResistance() {
        const model = angular.copy(this.obj);
        model.formattedResult = angular.copy(this.resultat);
        model.essais = angular.copy(this.essais);
        model.sousEssaisTamis = angular.copy(this.sousEssaisTamis);
        model.calculs = angular.copy(this.calculs);

        this.$state.go('syntheseentities.resistance', { obj: model });
    }

    goControleAcquereur() {
        const model = angular.copy(this.obj);
        model.formattedResult = angular.copy(this.resultat);
        model.essais = angular.copy(this.essais);
        model.sousEssaisTamis = angular.copy(this.sousEssaisTamis);
        model.calculs = angular.copy(this.calculs);
        model.conformiteSynthese = this.conformiteSynthese;
        model.graphData = this.graphData;

        this.$state.go('syntheseentities.ctrlacquereur', { obj: model });
    }

    goControleEnrobe() {
        const model = angular.copy(this.obj);
        model.formattedResult = angular.copy(this.resultat);
        model.essais = angular.copy(this.essais);
        model.sousEssaisTamis = angular.copy(this.sousEssaisTamis);
        model.calculs = angular.copy(this.calculs);
        model.conformiteSynthese = this.conformiteSynthese;
        model.graphData = this.graphData;

        this.$state.go('syntheseentities.ctrlenrobes', { obj: model });
    }

    goDecompo() {
        const model = angular.copy(this.obj);
        model.formattedResult = angular.copy(this.resultat);
        model.essais = angular.copy(this.essais);
        model.sousEssaisTamis = angular.copy(this.sousEssaisTamis);
        model.calculs = angular.copy(this.calculs);

        this.$state.go('syntheseentities.decompo', { obj: model });
    }

    async goBack() {
        const model = angular.copy(this.obj);
        model.formattedResult = angular.copy(this.resultat);
        model.essais = angular.copy(this.essais);
        model.sousEssaisTamis = angular.copy(this.sousEssaisTamis);
        model.calculs = angular.copy(this.calculs);

        this.$state.go('syntheseentities.edit', { id: this.obj.synthese.id, obj: model });
    }

    async goCarteDeControle() {
        const model = angular.copy(this.obj);
        for (let i = 0; i < model.synthese.carteDeControle.syntheseLiee.length; i++) {
            const element = model.synthese.carteDeControle.syntheseLiee[i];
            const res = await this.SyntheseEntitiesService.genererSynthese(element.synthese, this.MassiaApplicationService.getApplication());
            element.result = angular.copy(res);
        }
        this.$state.go('syntheseentities.cdc', { obj: model });
    }

    /*initUpdateValeur(lig, essai, index) {
        this.edit = { lig: angular.copy(lig), essai: angular.copy(essai), valeur: this.getResultatEssai(lig,essai)};
        this.clickedPopoverIndex = index;
        }

    async updateValeur() {
        if (!this.edit.essai.tamis) {
            let sousEssai = this._.find(this.edit.lig.listeSousEssaiLignes, { idEssai: this.edit.essai.idEssai, idSousEssai: this.edit.essai.idSousEssai });
            if (sousEssai)
                sousEssai.valeur = this.edit.valeur;
        }
        else {
            let tamis = this._.find(this.edit.lig.listeTamisLignes, { idEssai: this.edit.essai.idEssai, idTamis: this.edit.essai.idTamis });
            if (tamis)
                tamis.passant = this.edit.valeur;
        }
    }*/
    async removePrelevement(lig) {
        lig.ecartee = true;
        await this.relanceTraitementsStat();
    }

    async changeValeur(essai) {
        //gérer l'arrondi
        essai.valeur = this.arrondiValeur(essai.valeur, essai.arrondi);
        //vérifier la conformité
        const borne = this.getBornes(essai, '@VAL');
        essai.conformite = this.controleConformite(essai.valeur, borne);
        //relancer les calculs stats
        await this.relanceTraitementsStat();
    }

    async prepareGraphData() {
        this.graphData = { Abscisse: [] };
        for (let i = 0; i < this.resultat.stats.length; i++) {
            const stat = angular.copy(this.resultat.stats[i]);
            const nomSerie = stat.formule.substring(1);
            if (!stat.formule.includes('@CONF') && !stat.statsEssais.find((x) => x.isDate)) {
                this.graphData[nomSerie] = {};
                this.graphData[nomSerie].label = stat.titre;
                this.graphData[nomSerie].isControle = stat.isControle;
                this.graphData[nomSerie].hidden = false;
                this.graphData[nomSerie].values = [];
                for (let j = this.resultat.entete.titresSousEssais.length - 1; j >= 0; j--) {
                    const ssEss = angular.copy(this.resultat.entete.titresSousEssais[j]);
                    if (ssEss.isTamis) {
                        if (i == 0) {
                            const val = { value: Number(ssEss.titre) };
                            this.graphData.Abscisse.push(Object.assign({}, ssEss, val));
                        }
                        if (
                            stat.statsEssais[j].valeur != undefined &&
                            stat.statsEssais[j].valeur != null &&
                            stat.statsEssais[j].valeur != ''
                            //! Attention si on ne prends pas les valeurs 'string' la courbe n'est pas complète ou affichage bizarre !!!
                            // typeof stat.statsEssais[j].valeur !== 'string'
                        ) {
                            if (typeof stat.statsEssais[j].valeur === 'string') {
                                stat.statsEssais[j].valeur = Number.parseFloat(stat.statsEssais[j].valeur).toFixed(stat.statsEssais[j].valeur.substring(stat.statsEssais[j].valeur.indexOf('.') + 1).length);
                            }
                            this.graphData[nomSerie].values.push(Number(stat.statsEssais[j].valeur).toFixed(stat.statsEssais[j].valeur.substring(stat.statsEssais[j].valeur.indexOf('.') + 1).length));
                        } else {
                            //SP 16/11/20 (5469) ne pas afficher 0 ou 100 si il n'y a pas l'autre valeur...
                            if (stat.formule.includes('@VSI')) {
                                //chercher VSS
                                const statVSS = this._.find(this.resultat.stats, { formule: '@VSS' });
                                if (
                                    statVSS &&
                                    statVSS.statsEssais[j].valeur != undefined &&
                                    statVSS.statsEssais[j].valeur != null &&
                                    statVSS.statsEssais[j].valeur != ''
                                ) {
                                    this.graphData[nomSerie].values.push(0);
                                } else {
                                    this.graphData[nomSerie].values.push(undefined);
                                }
                            } else if (stat.formule.includes('@LI')) {
                                //chercher LS
                                const statLS = this._.find(this.resultat.stats, { formule: '@LS' });
                                if (
                                    statLS &&
                                    statLS.statsEssais[j].valeur != undefined &&
                                    statLS.statsEssais[j].valeur != null &&
                                    statLS.statsEssais[j].valeur != ''
                                ) {
                                    this.graphData[nomSerie].values.push(0);
                                } else {
                                    this.graphData[nomSerie].values.push(undefined);
                                }
                            } else if (stat.formule.includes('@VSS')) {
                                //chercher VSI
                                const statVSI = this._.find(this.resultat.stats, { formule: '@VSI' });
                                if (
                                    statVSI &&
                                    statVSI.statsEssais[j].valeur != undefined &&
                                    statVSI.statsEssais[j].valeur != null &&
                                    statVSI.statsEssais[j].valeur != ''
                                ) {
                                    this.graphData[nomSerie].values.push(100);
                                } else {
                                    this.graphData[nomSerie].values.push(undefined);
                                }
                            } else if (stat.formule.includes('@LS')) {
                                //chercher LI
                                const statLI = this._.find(this.resultat.stats, { formule: '@LI' });
                                if (
                                    statLI &&
                                    statLI.statsEssais[j].valeur != undefined &&
                                    statLI.statsEssais[j].valeur != null &&
                                    statLI.statsEssais[j].valeur != ''
                                ) {
                                    this.graphData[nomSerie].values.push(100);
                                } else {
                                    this.graphData[nomSerie].values.push(undefined);
                                }
                            } else {
                                this.graphData[nomSerie].values.push(undefined);
                            }
                        }
                    }
                }
            }
        }
        this.graphData.nbElement = this.graphData.Abscisse.length;
        this.graphData.idEssai = this.graphData.Abscisse.length > 0 ? this.graphData.Abscisse[0].idEssai : 0;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    getByProducteur() {
        this.resultLines = this._.groupBy(this.obj.resultat.prelevementBlock, (prel) => {
            return prel.idProducteur;
        });

        this.resultLines = Object.keys(this.resultLines).map((i) => {
            this.resultLines[i].idProducteur = i;
            this.resultLines[i].producteur = this.resultLines[i][0].libelleProducteur;
            return this.resultLines[i];
        });

        for (let i = 0; i < this.resultLines.length; i++) {
            const prel = this.resultLines[i];
            this.resultLines[i].resultat = [];
            let res = null;
            for (let j = 0; j < prel.length; j++) {
                res = this._.filter(this.resultat.lignes, (result) => {
                    if (result.idPrelevement === prel[j].idPrelevement) {
                        return result;
                    }
                });
                this.resultLines[i].resultat.push(res[0]);
            }
        }
    }

    getByMonth() {
        this.obj.resultat.prelevementBlock = this.obj.resultat.prelevementBlock.sort((a, b) => {
            const db = this.moment(b.datePrelevement, this.dateFormat).unix();
            const da = this.moment(a.datePrelevement, this.dateFormat).unix();
            return da - db;
        });
        this.resultLines = this._.groupBy(this.obj.resultat.prelevementBlock, (prel) => {
            const date = this.moment(prel.datePrelevement, this.dateFormat).toDate();

            const index = this.getMonth(date.getMonth());
            prel.date = index;
            prel.year = date.getFullYear();
            return `${index} ${prel.year}`;
        });

        this.resultLines = Object.keys(this.resultLines).map((i) => {
            this.resultLines[i].date = this.resultLines[i]['0'].date;
            this.resultLines[i].year = this.resultLines[i]['0'].year;
            return this.resultLines[i];
        });

        for (let i = 0; i < this.resultLines.length; i++) {
            const prel = this.resultLines[i];
            this.resultLines[i].resultat = [];
            let res = null;
            for (let j = 0; j < prel.length; j++) {
                res = this._.filter(this.resultat.lignes, (result) => {
                    if (result.idPrelevement === prel[j].idPrelevement) {
                        return result;
                    }
                });
                this.resultLines[i].resultat.push(res[0]);
            }
        }

        this.resultLines = this.resultLines.reverse();
    }

    getByMonthAndProducteur() {
        this.resultLines = this._.groupBy(this.obj.resultat.prelevementBlock, (prel) => {
            const date = this.moment(prel.datePrelevement, this.dateFormat);
            const index = this.getMonth(date.getMonth());
            prel.date = index;
            return [index, prel.idProducteur];
        });

        this.resultLines = Object.keys(this.resultLines).map((i) => {
            this.resultLines[i].date = i.split(',')[0];
            this.resultLines[i].idProducteur = i;
            this.resultLines[i].producteur = this.resultLines[i][0].libelleProducteur;
            return this.resultLines[i];
        });

        for (let i = 0; i < this.resultLines.length; i++) {
            const prel = this.resultLines[i];
            this.resultLines[i].resultat = [];
            let res = null;
            for (let j = 0; j < prel.length; j++) {
                res = this._.filter(this.resultat.lignes, (result) => {
                    if (result.idPrelevement === prel[j].idPrelevement) {
                        return result;
                    }
                });
                this.resultLines[i].resultat.push(res[0]);
            }
        }
    }

    getMonth(month) {
        month = parseInt(month);
        switch (month) {
            case 0:
                return 'SYNTHENTS.MONTH.JANUARY';

            case 1:
                return 'SYNTHENTS.MONTH.FEBRUARY';

            case 2:
                return 'SYNTHENTS.MONTH.MARCH';

            case 3:
                return 'SYNTHENTS.MONTH.APRIL';

            case 4:
                return 'SYNTHENTS.MONTH.MAY';

            case 5:
                return 'SYNTHENTS.MONTH.JUNE';

            case 6:
                return 'SYNTHENTS.MONTH.JULY';

            case 7:
                return 'SYNTHENTS.MONTH.AUGUST';

            case 8:
                return 'SYNTHENTS.MONTH.SEPTEMBER';

            case 9:
                return 'SYNTHENTS.MONTH.OCTOBER';

            case 10:
                return 'SYNTHENTS.MONTH.NOVEMBER';

            case 11:
                return 'SYNTHENTS.MONTH.DECEMBER';

            default:
                return '';
        }
    }

    genereFTPArchivee(resultModal) {
        const ftpInfo = this.genereFTPInfos(false);
        const ftpa = {
            id: 0,
            code: resultModal.code,
            libelle: resultModal.libelle,
            date: this.moment().format(this.dateFormat),
            debEngag: ftpInfo.dateMin,
            finEngag: ftpInfo.dateMax,
            idProducteur: ftpInfo.producteur.id,
            idProduit: ftpInfo.produit.id,
            idNorme: ftpInfo.referentiel ? ftpInfo.referentiel.normeId : undefined,
            idType: resultModal.idType,
            selections: [],
            valeurs: [],
            essais: []
        };
        const that = this;
        //Sélections
        for (var i = 0; i < this.obj.synthese.listeSyntheseCritereValeurBlocks.length; i++) {
            //dates min/max
            const prelsBlocI = this._.filter(this.obj.resultat.prelevementBlock, function (p) {
                return p.numsBloc.includes(that.obj.synthese.listeSyntheseCritereValeurBlocks[i].indiceSelection);
            });
            let dateMinBlock = undefined;
            let dateMaxBlock = undefined;
            if (prelsBlocI && prelsBlocI.length > 0) {
                const datesPrel = this._.map(prelsBlocI, 'datePrelevement');
                dateMinBlock = this.moment(
                    new Date(
                        Math.min.apply(
                            null,
                            datesPrel.map((e) => {
                                return that.moment(e, this.dateFormat + ' HH:mm');
                            })
                        )
                    )
                ).format(this.dateFormat);
                dateMaxBlock = this.moment(
                    new Date(
                        Math.max.apply(
                            null,
                            datesPrel.map((e) => {
                                return that.moment(e, this.dateFormat + ' HH:mm');
                            })
                        )
                    )
                ).format(this.dateFormat);
            }
            ftpa.selections.push({
                id: -i - 1,
                nomSel: this.obj.synthese.listeSyntheseCritereValeurBlocks[i].nomComposant,
                dateDeb: dateMinBlock,
                dateFin: dateMaxBlock,
                isPrincipale: i == 0
            });
        }
        //Essais et Valeurs
        for (let j = 0; j < this.resultat.entete.titresSousEssais.length; j++) {
            const ssEssTit = this.resultat.entete.titresSousEssais[j];
            ftpa.essais.push({
                id: 0,
                ordre: j,
                isTamis: ssEssTit.isTamis,
                isComplementaire: ssEssTit.isComplementaire,
                isCtrlEcartType: false, //TODO: chercher si il y a un contrôle de l'écart-type sur la référence
                idEssai: ssEssTit.idEssai,
                idSsEss: ssEssTit.isTamis ? undefined : ssEssTit.id,
                idSsEssTamis: ssEssTit.isTamis ? ssEssTit.id : undefined,
                ftpSel: ftpa.selections[ssEssTit.indiceSelection]
            });
            for (let k = 0; k < this.resultat.stats.length; k++) {
                const stat = this.resultat.stats[k];
                ftpa.valeurs.push({
                    id: 0,
                    isTamis: ssEssTit.isTamis,
                    idEssai: ssEssTit.idEssai,
                    idSsEss: ssEssTit.isTamis ? undefined : ssEssTit.id,
                    idSsEssTamis: ssEssTit.isTamis ? ssEssTit.id : undefined,
                    idCalcStat: stat.idCalcStat,
                    valeur: stat.statsEssais[j].valeur
                });
            }
        }
        return ftpa;
    }

    async archiveFTP() {
        const modalInstance = this.$uibModal.open({
            template: '<archivage-ftp-modal modal-instance="$ctrl.uibModalInstance"></archivage-ftp-modal>',
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    const $ctrl = this;
                    $ctrl.uibModalInstance = $uibModalInstance;
                }
            ],
            controllerAs: '$ctrl',
            size: 'md',
            backdrop: false
        });
        const resModal = await modalInstance.result;
        if (resModal) {
            const ftpa = this.genereFTPArchivee(resModal);
            try {
                const idFtpA = await this.SyntheseEntitiesService.saveFTPArchivee(ftpa);
                this.notification.success(this.$translate.instant('SYNTHENTS.FTPA_GENEREE'));
            } catch (err) {
                this.notification.error(err.data);
            }
        }
    }

    async printFTP() {
        const ftp = this.genereFTPInfos(true);
        const codeProduit = ftp.produit.code;

        ftp.periode = this.setPeriode(codeProduit);
        if (this.obj?.resultat?.prelevementBlock?.length > 0) {
            //dates min/max sélection principale
            const that = this;
            const prelsBlocI = this._.filter(this.obj.resultat.prelevementBlock, function (p) {
                return p.numsBloc.includes(that.obj.synthese.listeSyntheseCritereValeurBlocks[0].indiceSelection);
            });
            if (prelsBlocI && prelsBlocI.length > 0) {
                const datesPrel = this._.map(prelsBlocI, 'datePrelevement');
                ftp.dateFirst = this.moment(
                    new Date(
                        Math.min.apply(
                            null,
                            datesPrel.map((e) => {
                                return that.moment(e, this.dateFormat + ' HH:mm');
                            })
                        )
                    )
                ).format(this.dateFormat);
                ftp.dateLast = this.moment(
                    new Date(
                        Math.max.apply(
                            null,
                            datesPrel.map((e) => {
                                return that.moment(e, this.dateFormat + ' HH:mm');
                            })
                        )
                    )
                ).format(this.dateFormat);
            }

            const lstDistinctClientId = this.obj.resultat.prelevementBlock
                .map((e) => e.idClient)
                .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                });
            if (lstDistinctClientId.length == 1 && lstDistinctClientId[0] !== 0) {
                ftp.client = { id: lstDistinctClientId[0] };
            }
        }
        //récupérer la courbe granulo
        const granulo = document.getElementById('granulograph');
        if (granulo != null) {
            const base64 = granulo.toDataURL('image/png');
            ftp.canvas = base64;
        } else {
            ftp.canvas = null;
        }

        try {
            //nom du fichier par defaut pour le partage avec le portail
            this.portailOption = {
                envoiAuto: false,
                nameAuto: false,
                customFilename: null
            };
            if (this.portailAccess) {
                const configAutoPortail = (await this.SettingPortailService.getDocSetting('FTP')).data;
                if (configAutoPortail) {
                    this.portailOption.envoiAuto = configAutoPortail.envoiAuto;
                    this.portailOption.nameAuto = configAutoPortail.filenameAuto;
                    const ftpData = {
                        producteur: ftp.producteur,
                        laboratoire: ftp.laboratoire,
                        produit: ftp.produit
                    };
                    if (configAutoPortail.filenameAuto) {
                        this.portailOption.customFilename = (
                            await this.SettingPortailService.getAutoFilename(0, 'FTP', configAutoPortail.format, ftpData)
                        ).data;
                    }
                }
            }
            this.config = {
                comment: '',
                title: '',
                typeSynth: this.obj.synthese.typeDeSynthese,
                id: 0,
                libelle: '',
                printCompl: true,
                caracts: {
                    print: true,
                    withValues: true,
                    withoutValues: false,
                    list: []
                },
                printPrels: false,
                printCertif: true,
                printDimRq: true,
                dateDeb: this.moment().add(1, 'd'),
                dateFin: this.moment().add(6, 'M'),
                modele: null,
                saveModel: false
            };

            const modalInstance = this.$uibModal.open({
                animation: true,
                component: 'ftpPrintConfig',
                size: 'md',
                resolve: {
                    config: () => {
                        return this.config;
                    },
                    portailOption: () => {
                        return this.portailOption;
                    },
                    portail: () => {
                        return this.portailOption.envoiAuto;
                    },
                    portailAccess: () => {
                        return this.portailAccess;
                    },
                    produit: () => {
                        return ftp.produit;
                    },
                    modele: () => {
                        return null;
                    },
                    selectedClients: () => {
                        return [];
                    },
                    configExcel: () => {
                        return null;
                    }
                }
            });
            const res = await modalInstance.result;

            if (res === 'cancel') {
                throw res;
            }
            this.notification.info(this.$translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
                autoDismiss: false
            });
            this.config.dateDeb = this.moment(this.config.dateDeb, this.dateFormat);
            this.config.dateFin = this.moment(this.config.dateFin, this.dateFormat);
            ftp.config = this.config;
            if (this.config.printPrels) {
                const lignes = this.resultat.lignes.filter((x) => {
                    return !x.ecartee;
                });
                const results = angular.copy(this.resultat);
                results.lignes = lignes;
                ftp.resultat = results;
            }
            if (this.config.printDimRq) {
                const listDimRq = this.obj?.synthese.listeLiensSETS?.find((x) => x.listeDimRqHeaders);
                const normativePart = ftp.statsEssais1;
                await this.getDimRqForTamis(listDimRq, normativePart);
            }
            let template;
            const toPdf = res.pdf;
            if (res.configExcel && ['.xlsx', '.xlsm'].includes(res.modele.extension)) {
                template = await this.SyntheseEntitiesService.printSyntheseExcel(ftp, res.modele.id, res.configExcel);
            } else {
                template = await this.SyntheseEntitiesService.printFTP(ftp, res.modele.filename);
            }

            template.pdf = res.pdf;
            await this.TemplateImpressionService.downloadTemplate(template.filename, template, 'bilannorm');
            if (res.portail) {
                const model = {
                    filename: res.portailOption.customFilename,
                    original: template.filename,
                    dateGeneration: new Date(),
                    domain: 'FTP',
                    laboratoires: [ftp.laboratoire.id],
                    producteurs: [ftp.producteur.id],
                    produits: [ftp.produit.id],
                    norme: ftp.referentiel ? ftp.referentiel.normeId : undefined,
                    clients: res.selectedClients,
                    template: res.modele?.id
                };
                await this.SharedFileService.create(model);
            }
        } catch (err) {
            if (err !== 'cancel') {
                this.notification.error(err.data);
            }
        }
    }

    genereFTPInfos(avecStat) {
        const ftpInfo = this.getInfoForPrint();

        const prelsBloc0 = this._.filter(this.obj.resultat.prelevementBlock, function (p) {
            return p.numsBloc.includes(0);
        });

        ftpInfo.dateMin = this.moment().add(1, 'days').format(this.dateFormat);
        ftpInfo.dateMax = this.moment().add(6, 'months').format(this.dateFormat);
        if (avecStat) {
            const titresSousEssaisNonComplementaires = this._.filter(this.resultat.entete.titresSousEssais, { isComplementaire: false });

            const valeursEssais = this._.map(titresSousEssaisNonComplementaires, function (b) {
                const valeur = {
                    title: b.displayTitle,
                    codeSsEss: b.isTamis ? b.ouvertureTamis : b.codeSsEss != null ? b.codeSsEss : '',
                    unite: b.unite || '',
                    libelleEssai: b.isTamis ? '' : b.libelleEssai,
                    symbole: b.symbole || b.code || '',
                    codeEssai: b.code || '',
                    isComplementaire: b.isComplementaire,
                    isTamis: b.isTamis,
                    dimRq: b.isTamis ? b.dimRq : ''
                };
                return { valeur: valeur };
            });
            const _this = this;

            //partie normative
            const stats1 = angular.copy(this.resultat.stats.filter((e) => e.isImprimable && e.isControle));
            ftpInfo.statsEssais1 = this.getStatsFtpForPrint(stats1, angular.copy(valeursEssais), false, true);

            //résultats de production
            const stats2 = angular.copy(this.resultat.stats.filter((e) => e.isImprimable && !e.isControle));
            ftpInfo.statsEssais2 = this.getStatsFtpForPrint(stats2, angular.copy(valeursEssais));

            //essais complémentaires
            const titresSousEssaisComplementaires = this._.filter(this.resultat.entete.titresSousEssais, { isComplementaire: true });
            const valeursEssaisCompl = this._.map(titresSousEssaisComplementaires, function (b) {
                return {
                    valeur: b.displayTitle,
                    unite: b.unite
                };
            });
            const complem = this._.filter(this.resultat.stats, { isImprimable: true, isControle: false, formule: '@MOY' });
            const complementaires = [];
            if (complem && complem.length > 0) {
                const valeurs = _this._.filter(complem[0].statsEssais, { isComplementaire: true });
                for (let i = 0; i < valeurs.length; i++) {
                    if (valeurs[i].valeur != '') {
                        complementaires.push({
                            libelle: valeursEssaisCompl[i].valeur,
                            valeur: valeurs[i].valeur,
                            unite: valeursEssaisCompl[i].unite
                        });
                    }
                }
            }
            ftpInfo.complementaires = complementaires;

            const valeursEssaisComp = this._.map(titresSousEssaisComplementaires, function (b) {
                const valeur = {
                    title: b.displayTitle,
                    codeSsEss: b.isTamis ? b.ouvertureTamis : b.codeSsEss != null ? b.codeSsEss : '',
                    unite: b.unite || '',
                    libelleEssai: b.isTamis ? '' : b.libelleEssai,
                    symbole: b.symbole || b.code || '',
                    codeEssai: b.code || '',
                    isComplementaire: b.isComplementaire,
                    isTamis: b.isTamis
                };
                return { valeur: valeur };
            });
            //ajout des stats sur les essais complémentaires (mantis 7869)
            ftpInfo.statsComplementaires = [];

            if (complementaires && complementaires.length > 0) {
                ftpInfo.statsComplementaires = [
                    ...this.getStatsFtpForPrint(stats1, angular.copy(valeursEssaisComp), true),
                    ...this.getStatsFtpForPrint(stats2, angular.copy(valeursEssaisComp), true)
                ];
            }
        }
        return ftpInfo;
    }

    rgbStringToIntArray(rgb) {
        let array = [];
        if (rgb.includes('rgba')) {
            array = rgb.replace('rgba(', '').replace(')', '').split(',');
        } else {
            array = rgb.replace('rgb(', '').replace(')', '').split(',');
        }
        array = array.map((x) => Number.parseInt(x));
        return array;
    }

    rgbToHex(rgb) {
        return rgb
            .map((x) => {
                const hex = x.toString(16);
                return hex.length === 1 ? '0' + hex : hex;
            })
            .join('');
    }

    async fuseauRegularite() {
        const ftpInfo = this.genereFTPInfos(false);
        //récupérer les valeurs moyennes
        const resMoy = this._.filter(this.resultat.stats, { formule: '@MOY' });
        const moyennesEssais = [];
        if (resMoy && resMoy.length > 0) {
            for (var i = 0; i < resMoy[0].statsEssais.length; i++) {
                if (resMoy[0].statsEssais[i].valeur != '') {
                    //chercher l'essai
                    const essai = this._.find(this.resultat.entete.titresEssais, { id: this.resultat.entete.titresSousEssais[i].idEssai });
                    let idFRTam = this.resultat.entete.titresSousEssais[i].id;
                    if (this.resultat.entete.titresSousEssais[i].isTamis) {
                        //aller chercher IdSsEssTamis au lieu de IdTamisSerie
                        const essaiFRTam = this._.find(this.obj.synthese.listeLiensSETS, { codeEssai: essai.code });
                        const tamisFRTam = this._.find(essaiFRTam.listeTamisHeaders, {
                            idTamis: this.resultat.entete.titresSousEssais[i].idTamis /*idFRTam*/
                        });
                        idFRTam = tamisFRTam.idSousEssaiTamis;
                    }
                    moyennesEssais.push({
                        id: idFRTam,
                        idEssai: this.resultat.entete.titresSousEssais[i].idEssai,
                        codeEssai: essai.code,
                        symboleEssai: this.setSymboleEssai(essai.symbole, essai.code),
                        isTamis: this.resultat.entete.titresSousEssais[i].isTamis,
                        libelle: this.resultat.entete.titresSousEssais[i].displayTitle,
                        valeur: resMoy[0].statsEssais[i].valeur,
                        libelleEssai: this.resultat.entete.titresSousEssais[i].libelleEssai,
                        ouvertureTamis: this.resultat.entete.titresSousEssais[i].ouvertureTamis,
                        unite: this.resultat.entete.titresSousEssais[i].unite
                    });
                }
            }
        }

        const _this = this;
        _this.idProduit = ftpInfo.produit.id;
        _this.moyennes = moyennesEssais;
        const modalInstance = this.$uibModal.open({
            template:
                '<fuseau-regularite-modal modal-instance="$ctrl.uibModalInstance" id-produit="$ctrl.idProduit" moyennes="$ctrl.moyennes"></fuseau-regularite-modal>',
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    const $ctrl = this;
                    $ctrl.uibModalInstance = $uibModalInstance;
                    $ctrl.idProduit = _this.idProduit;
                    $ctrl.moyennes = _this.moyennes;
                }
            ],
            controllerAs: '$ctrl',
            size: 'xxl',
            backdrop: false
        });
        const resModal = await modalInstance.result;

        const calculStatDefaut = await this.CalculsStatistiquesService.getCalculStatistiqueValeur();
        if (resModal) {
            const reference = {
                id: 0,
                code: resModal.code,
                libelle: resModal.libelle,
                typeId: resModal.idType,
                familleId: null,
                dateDebut: resModal.dateDebut,
                dateFin: resModal.dateFin,
                sitesProducteurs: [],
                sitesProducteursIds: [ftpInfo.producteur.id],
                mesures: [],
                typeMesureId: 0,
                idNorme: resModal.idNorme,
                caracteristiques: [],
                idLogo: undefined,
                sitesClients: [],
                sitesClientsIds: [],
                produits: [],
                produitsIds: [resModal.idProduit],
                normeNorme: resModal.normeNorme,
                nombreResultatsN: null
            };

            for (var i = 0; i < resModal.essais.length; i++) {
                const mesure = {
                    id: 0,
                    essaiCodeLibelle: '',
                    essaiId: resModal.essais[i].essaiId,
                    sousEssaiCodeLibelle: '',
                    sousEssaiId: resModal.essais[i].sousEssaiId,
                    libelleComplet: '',
                    calculLibelle: '',
                    calculId: calculStatDefaut.id,
                    calculFormule: '',
                    condition: resModal.essais[i].condition,
                    borneInferieure: resModal.essais[i].borneInferieure,
                    borneSuperieure: resModal.essais[i].borneSuperieure,
                    alerte: null,
                    unite: '',
                    arrondi: undefined,
                    libelleEssai: '',
                    codeEssai: '',
                    symboleNormeEssai: '',
                    limiteInferieure: resModal.essais[i].limiteInferieure,
                    limiteSuperieure: resModal.essais[i].limiteSuperieure,
                    moyenne: resModal.essais[i].moyenne,
                    etendueReference: resModal.essais[i].etendueReference,
                    incertitudeReference: resModal.essais[i].incertitudeReference,
                    isETControle: undefined,
                    isFiltre: undefined,
                    niveauVisibilite: undefined,
                    ordre: undefined,
                    categorieId: resModal.essais[i].categorieId,
                    tamis: resModal.essais[i].tamis,
                    alerteInferieure: '',
                    limiteBetonInferieure: '',
                    limiteBetonSuperieure: '',
                    alerteSuperieure: '',
                    ouvertureTamis: resModal.essais[i].ouvertureTamis,
                    grandeurMinimum: undefined,
                    grandeurMaximum: undefined
                };
                reference.mesures.push(mesure);
            }

            try {
                const id = await this.ReferencesControleService.createReference(reference);
                this.notification.success('REFERENCES.CREATED');
                this.$state.go('seuilgran.edit', { id: id });
            } catch (err) {
                this.notification.error(err.data);
            }
        }
    }

    getFtpClients(obj) {
        let clients = [];

        try {
            const valeursBlocks = obj.synthese.listeSyntheseCritereValeurBlocks;
            for (let i = 0; i < valeursBlocks.length; i++) {
                const blockClients = valeursBlocks[i].listeSyntheseCritereValeurLignes.find((x) => x.domaineCode == 'SiteClient'); //sites clients
                if (blockClients) {
                    clients = [...clients, ...blockClients.selectedCritAff.map((x) => x.id)];
                }
            }
            return [...new Set(clients)];
        } catch (error) {
            return clients;
        }
    }

    setSymboleEssai(symbole, code) {
        if (!symbole || symbole === '') {
            return code;
        }
        return symbole;
    }

    async printSynthPrels(exporter) {
        const data = {};
        const ref = this.obj.synthese.idReference
            ? this.obj.synthese.listeReferences.find((x) => x.referenceId == this.obj.synthese.idReference)
            : null;
        const criteres = this.obj.synthese.listeSyntheseCritereValeurBlocks;
        let array = [];
        for (let i = 0; i < criteres.length; i++) {
            array = [...array, ...criteres[i].listeSyntheseCritereValeurLignes];
        }
        data.synthese = {
            libelle: this.obj.synthese.libelle,
            type: this.obj.synthese.typeDeSyntheseLibelle,
            reference: ref
        };
        data.info = data.info = this.getInfoForPrint();
        data.caractProductor = this.obj.resultat.prelevementBlock[0].listeCaracteristiquesProducteur;
        const datesPrels = this.obj.resultat.prelevementBlock.map((x) => x.datePrelevement).sort();
        data.dateFirst = datesPrels[0];
        data.dateLast = datesPrels[datesPrels.length - 1];

        data.results = this.resultat;
        data.essais = this.obj.synthese.listeLiensSETS;
        data.critere = array;
        data.conformiteSynthese = this.conformiteSynthese;
        data.stats = this.getFormattedStats();

        try {
            if (exporter) {
                /* ************************************ Export Excel ********************************************************* */
                //  console.log(this.resultat);
                const fileName = 'SynthesePrelevement';
                const resultat = await this.SyntheseEntitiesService.exporter(this.resultat, this.resultat.entete.titresAffichage);
                // console.log(resultat);
                if (resultat) {
                    const data = resultat.data;
                    const status = resultat.status;
                    let headers = resultat.headers;
                    headers = headers();

                    const contentType = headers['content-type'];

                    const linkElement = document.createElement('a');
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    linkElement.setAttribute('download', fileName + '.xlsx');

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                }
                /* ************************************************************************************************************** */
            } else {
                const res = await this.TemplateImpressionService.downloadModal('bilanprel', true);

                if (res === 'cancel') {
                    throw res;
                }
                this.notification.info(this.$translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
                    autoDismiss: false
                });

                const template = await this.SyntheseEntitiesService.printBilanPrel(data, res.filename);
                template.pdf = res.pdf;
                await this.TemplateImpressionService.downloadTemplate(template.filename, template, 'bilanprel');
            }
        } catch (err) {
            if (err !== 'cancel') {
                this.notification.error(err.data);
            }
        }
    }

    async printRegistre3(excel = false) {
        const data = {};

        const criteres = this.obj.synthese.listeSyntheseCritereValeurBlocks;
        let array = [];
        for (let i = 0; i < criteres.length; i++) {
            array = [...array, ...criteres[i].listeSyntheseCritereValeurLignes];
        }
        data.synthese = {
            libelle: this.obj.synthese.libelle,
            type: this.obj.synthese.typeDeSyntheseLibelle
        };
        data.info = this.getInfoForPrint();
        data.results = this.resultat;
        data.critere = array;
        data.printPerMonth = this.obj.synthese.byMonth;
        data.printPerProducteur = this.obj.synthese.byProducteur;
        if (data.printPerMonth || data.printPerProducteur) {
            data.registreData = this.obj.resultat.prelevementBlock;
        }

        if (!excel) {
            var res = await this.TemplateImpressionService.downloadModal('registrebeton', true);

            if (res === 'cancel') {
                throw res;
            }
        }

        this.notification.info(this.$translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
            autoDismiss: false
        });
        try {
            if (!excel) {
                const template = await this.SyntheseEntitiesService.printRegistre3(data, res.filename);
                template.pdf = res.pdf;
                await this.TemplateImpressionService.downloadTemplate(template.filename, template, 'registre');
            } else {
                const fileName = 'registre';
                const resultat = await this.SyntheseEntitiesService.ExportExcelRegistre3(data);
                if (resultat) {
                    const data = resultat.data;
                    const status = resultat.status;
                    let headers = resultat.headers;
                    headers = headers();

                    const contentType = headers['content-type'];

                    const linkElement = document.createElement('a');
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    linkElement.setAttribute('download', fileName + '.xlsx');

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                }
            }
        } catch (err) {
            if (err !== 'cancel') {
                this.notification.error(err.data);
            }
        }
    }

    formatResultforPrint() {}

    getStatsFtpForPrint(listeStats, valEssais, complementaire = false, normativePart = false) {
        const stats = listeStats.map((a) => {
            const res = {
                libelle: a.titre,
                code: a.code
            };
            const stat = a.statsEssais.filter((x) => x.isComplementaire === complementaire);
            res.valeurs = stat.map((x, i) => {
                x.isTamis = valEssais[i].valeur.isTamis;
                if (a.couleurFond) {
                    x.valeur = {
                        color: this.rgbToHex(this.rgbStringToIntArray(a.couleurFond)).toUpperCase(),
                        value: x.valeur
                    };
                }

                if (x.conformite > 0) {
                    let text = null;
                    switch (x.conformite) {
                        case 1:
                            text = 'FF6600';
                            break;
                        case 2:
                            text = 'FF0000';
                            break;
                        case 3:
                            text = '0000FF';
                            break;
                    }

                    if (a.couleurFond) {
                        x.valeur.text = text;
                    } else {
                        x.valeur = {
                            text: text,
                            value: x.valeur
                        };
                    }
                }

                return x;
            });
            return res;
        });
        stats.splice(0, 0, {
            titre: '',
            code: '',
            codeLibelle: '',
            codeUnite: '',
            codeSymbole: '',
            libelle: '',
            libelleUnite: '',
            libelleSymbole: '',
            unite: '',
            symbole: '',
            codeLibelleSymbole: '',
            codeLibelleUnite: '',
            codeLibelleUniteSymbole: '',
            valeurs: angular.copy(valEssais)
        });

        return stats;
    }

    getInfoForPrint() {
        const objet = {};
        if (this.obj?.resultat?.prelevementBlock && this.obj?.resultat?.prelevementBlock.length > 0) {
            const first = this.obj.resultat.prelevementBlock[0];
            //? site laboratoire
            objet.laboratoire = {
                id: first.idLaboAudit,
                code: first.codeLaboAudit,
                libelle: first.libelleLaboAudit,
                societe: first.societeLaboAudit,
                raisonSociale: first.rsLaboAudit
            };

            //? site producteur
            objet.producteur = {
                id: first.idProducteur,
                code: first.codeProducteur,
                libelle: first.libelleProducteur,
                societe: first.societeProducteur,
                raisonSociale: first.rsProducteur,
                caracteristiques: first.listeCaracteristiquesProducteur
            };

            //? produit (+caractéristiques + famille béton + composition)
            objet.produit = {
                id: first.idProduit,
                code: first.codeProduit,
                libelle: first.libelleProduit,
                type: first.typeProduit,
                familleBeton: first.familleBetonProduit,
                caracteristiques: first.listeCaracteristiquesProduit,
                composition: first.listeCompositionProduit,
                petitd: first.petitDiametre,
                grandD: first.grandDiametre
            };
        } else {
            this.notification.error(this.$translate.instant('SYNTHENTS.ERREUR.NONE_RESULT'));
        }
        objet.referentiel = {
            id: this.obj?.resultat?.referentiel?.id,
            libelle: this.obj?.resultat?.referentiel ? this.obj.resultat.referentiel.libelle : '',
            norme: this.obj?.resultat?.referentiel ? this.obj.resultat.referentiel.libelleNorme : '',
            normeId: this.obj?.resultat?.referentiel?.idNorme,
            categorie: this.obj?.resultat?.referentiel ? this.obj.resultat.referentiel.categorie : ''
        };
        return objet;
    }

    getFormattedStats() {
        const valeursEssais = this._.map(this.resultat.entete.titresSousEssais, function (b) {
            const valeur = {
                title: b.displayTitle,
                codeSsEss: b.isTamis ? b.ouvertureTamis : b.codeSsEss != null ? b.codeSsEss : '',
                unite: b.unite || '',
                libelleEssai: b.isTamis ? '' : b.libelleEssai,
                symbole: b.symbole || b.code || '',
                codeEssai: b.code || '',
                isComplementaire: b.isComplementaire,
                isTamis: b.isTamis
            };
            return { valeur: valeur };
        });
        const statsFormatted = this.getStatsFtpForPrint(angular.copy(this.resultat.stats), angular.copy(valeursEssais));
        const statsFormatted2 = this.getStatsFtpForPrint(angular.copy(this.resultat.stats), angular.copy(valeursEssais), true);

        for (let i = 1; i < statsFormatted.length; i++) {
            statsFormatted[i].valeurs = [...statsFormatted[i].valeurs, ...statsFormatted2[i].valeurs];
        }
        return statsFormatted;
    }

    getNormeCatEssai(essStat) {
        let normeCat = null;
        if (!essStat.horsRef) {
            if (this.obj.resultat && this.obj.resultat.referentiel) {
                const lstMesures = this.obj.resultat.referentiel.mesures;
                lstMesures.forEach((element) => {
                    if (
                        (!essStat.tamis && element.idSousEssai === essStat.idSousEssai && element.essaiId === essStat.idEssai) ||
                        (essStat.tamis && essStat.ouvertureTamis === element.ouvertureTamis)
                    ) {
                        normeCat = element.normeCategorie;
                    }
                });
            }
        }
        return normeCat;
    }

    blocHaveProductAndPeriod(bloc, codeProduit) {
        const havePeriod = bloc.listeSyntheseCritereValeurLignes.find((x) => x.domaineCode == 'Prelevements' && x.champ == 9);

        const haveProduit = bloc.listeSyntheseCritereValeurLignes.find((x) => {
            const selectedCritAff = x.isteven?.find((x) => x.valeur);
            return x.domaineCode == 'Produits' && selectedCritAff && selectedCritAff.hasOwnProperty('codeLibelle')
                ? selectedCritAff.codeLibelle.split('/')[0]?.trim()
                : codeProduit == false;
        });

        return !!havePeriod && !!haveProduit;
    }

    setPeriode(codeProduit) {
        const periodesBloc = this.obj.synthese.listeSyntheseCritereValeurBlocks.filter((x) => this.blocHaveProductAndPeriod(x, codeProduit) === true);
        const periodes = periodesBloc.map((x) => x.listeSyntheseCritereValeurLignes.find((x) => x.domaineCode == 'Prelevements' && x.champ == 9));

        if (periodes && periodes.length === 1) {
            return periodes[0];
        } else if (periodes && periodes.length > 1) {
            const datesDeb = periodes
                .filter((x) => x.dateDebut !== null)
                .map((x) => {
                    return this.moment(x.dateDebut, 'D-M-YYYY');
                });
            let debut = null;
            if (datesDeb && datesDeb !== undefined && datesDeb !== null) {
                debut = this.moment.min(datesDeb).format('DD/MM/YYYY');
            }

            const datesFin = periodes
                .filter((x) => x.dateFin !== null)
                .map((x) => {
                    return this.moment(x.dateFin, 'D-M-YYYY');
                });
            let fin = null;
            if (datesFin && datesFin !== undefined && datesFin !== null) {
                fin = this.moment.max(datesFin).format('DD/MM/YYYY');
            }

            periodes[0].dateDebut = debut;
            periodes[0].dateFin = fin;
            return periodes[0];
        }
        return null;
    }

    async getDimRqForTamis(listDimRq, normativePart) {
        const dims = { d: this.obj?.resultat.prelevementBlock[0].petitDiametre, D: this.obj?.resultat.prelevementBlock[0].grandDiametre };
        const listTamisHeaders = normativePart[0].valeurs.filter((x) => x.valeur.isTamis === true);
        const newListDimRq = [];
        listDimRq.listeDimRqHeaders.forEach((element) => {
            const dAndD = /[d]{1}[\w\W]*[D]{1}/g;
            const result = element.label.match(dAndD)
                ? eval(element.label.replace('d', dims.d.toString()).replace('D', dims.D.toString()))
                : element.label.includes('d')
                ? eval(element.label.replace('d', dims.d.toString()))
                : element.label.includes('D')
                ? eval(element.label.replace('D', dims.D.toString()))
                : '';
            let newDimRq = {};
            if (result !== null && result !== undefined) {
                if (result === 0) {
                    newDimRq = {
                        key: result,
                        key_2: result,
                        value: element.label
                    };
                } else if (Math.floor(result) !== 0) {
                    const key = result.round(1);
                    const key_2 = parseFloat(result.toString().substring(0, 3));
                    newDimRq = {
                        key: key,
                        key_2: key_2,
                        value: element.label
                    };
                } else {
                    const round = 3;
                    const dec = result.toString().substring(2);
                    const length = parseInt(dec.length);
                    const key = length > round ? result.round(round - 1) : result.round(length);
                    const key_2 =
                        length > round
                            ? parseFloat(result.toString().substring(0, round + 1))
                            : parseFloat(result.toString().substring(0, length + 2));
                    newDimRq = {
                        key: key,
                        key_2: key_2,
                        value: element.label
                    };
                }
                newListDimRq.push(newDimRq);
            }
        });
        listTamisHeaders.forEach((element) => {
            if (element.valeur.codeSsEss === dims.d) {
                element.valeur.dimRq = 'd';
            } else if (element.valeur.codeSsEss === dims.D) {
                element.valeur.dimRq = 'D';
            } else {
                let search_1 = null;
                search_1 = newListDimRq.find((x) => x.key === element.valeur.codeSsEss || x.key_2 === element.valeur.codeSsEss);
                if (search_1 !== null && search_1 !== undefined) {
                    element.valeur.dimRq = search_1.value;
                }
            }
        });
    }
}
