export default function Configure($stateProvider, MassiaApplicationProvider) {
    $stateProvider.state('syntheseentities', {
        parent: 'common',
        url: '/syntheseentities',
        rights: { domain: 'synthadmin', right: 'read', application: 'laboratoire' },
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.ANALYSE" | translate }}'
        }
    });

    $stateProvider.state('syntheseentities.list', {
        url: '/list',
        template: '<syntheseentities></syntheseentities>',
        rights: { domain: 'synthadmin', right: 'read', application: 'laboratoire' },
        ncyBreadcrumb: {
            parent: 'syntheseentities',
            label: '{{ "SYNTHENTS.BREADCRUMBS.SYNTHENTS_LIST" | translate}}'
        },
        // on affiche la liste des syntheseentities dans la navigation
        navigation: {
            menu: 'analyse_direct',
            translate: 'SYNTHENTS.BREADCRUMBS.SYNTHENTS_LIST',
            order: 2,
            navigationCls: 'menu-item-separator'
        },
        // la liste des syntheseentities fait partie des points d'entrée recherchables dans la navigation
        search: 'SYNTHENTS.BREADCRUMBS.SYNTHENTS_LIST'
    });

    $stateProvider.state('syntheseentities.list.detail', {
        url: '/{idSynth}',
        template: '<syntheseentity-detail></syntheseentity-detail>',
        rights: { domain: 'synthadmin', right: 'read', application: 'laboratoire' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher syntheseentities > détail
            parent: 'syntheseentities.list',
            label: '{{ "SYNTHENTS.BREADCRUMBS.SYNTHENT_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('syntheseentities.new', {
        url: '/new',
        template: '<syntheseentities-form></syntheseentities-form>',
        rights: { domain: 'synthadmin', right: 'create', application: 'laboratoire' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher syntheseentities > ajouter
            parent: 'syntheseentities.list',
            label: '{{ "SYNTHENTS.BREADCRUMBS.SYNTHENT_NEW" | translate}}'
        },
        // l'ajout d'une syntheseentity fait partie des points d'entrée recherchables dans l'application
        search: 'SYNTHENTS.BREADCRUMBS.SYNTHENT_NEW'
    });

    $stateProvider.state('syntheseentities.edit', {
        url: '/{id}/edit',
        params: {
            obj: null
        },
        template: '<syntheseentities-form></syntheseentities-form>',
        rights: { domain: 'synthadmin', right: 'update', application: 'laboratoire' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher syntheseentities > modifier
            parent: 'syntheseentities.list',
            label: '{{ "SYNTHENTS.BREADCRUMBS.SYNTHENT_EDIT" | translate }}'
        }
    });

    $stateProvider.state('syntheseentities.result', {
        url: '/result',
        params: {
            obj: null
        },
        template: '<syntheseentities-result></syntheseentities-result>',
        rights: { domain: 'synthadmin', right: 'buildsynthesis', application: 'laboratoire' },
        ncyBreadcrumb: {
            parent: 'syntheseentities.list',
            label: '{{ "SYNTHENTS.BREADCRUMBS.SYNTHENT_RESULTAT" | translate }}'
        }
    });

    $stateProvider.state('syntheseentities.duplicate', {
        url: '/{id}/duplicate',
        template: '<syntheseentities-form></syntheseentities-form>',
        rights: { domain: 'synthadmin', right: 'create', application: 'laboratoire' },
        ncyBreadcrumb: {
            parent: 'syntheseentities.list',
            label: '{{ "SYNTHENTS.BREADCRUMBS.SYNTHENT_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });

    $stateProvider.state('syntheseentities.recomposition', {
        url: '/recomposition',
        params: {
            obj: null
        },
        template: '<syntheseentities-recomposition></syntheseentities-recomposition>',
        rights: { domain: 'synthadmin', right: 'buildsynthesis', application: 'laboratoire' },
        ncyBreadcrumb: {
            parent: 'syntheseentities.result',
            label: '{{ "SYNTHENTS.RECOMPOSITION" | translate }}'
        }
    });

    $stateProvider.state('syntheseentities.consistance', {
        url: '/consistance',
        params: {
            obj: null
        },
        template: '<syntheseentities-consistance></syntheseentities-consistance>',
        rights: { domain: 'synthadmin', right: 'buildsynthesis', application: 'laboratoire' },
        ncyBreadcrumb: {
            parent: 'syntheseentities.result',
            label: '{{ "SYNTHENTS.CARTE_CONTROLE_CONSISTANCE" | translate }}'
        }
    });

    $stateProvider.state('syntheseentities.resistance', {
        url: '/resistance',
        params: {
            obj: null
        },
        template: '<syntheseentities-resistance></syntheseentities-resistance>',
        rights: { domain: 'synthadmin', right: 'buildsynthesis', application: 'laboratoire' },
        ncyBreadcrumb: {
            parent: 'syntheseentities.result',
            label: '{{ "SYNTHENTS.CARTE_CONTROLE_RESISTANCE" | translate }}'
        }
    });

    $stateProvider.state('syntheseentities.ctrlacquereur', {
        url: '/ctrlacquereur',
        params: {
            obj: null
        },
        template: '<syntheseentities-ctrlacquereur></syntheseentities-ctrlacquereur>',
        rights: { domain: 'synthadmin', right: 'buildsynthesis', application: 'laboratoire' },
        ncyBreadcrumb: {
            parent: 'syntheseentities.result',
            label: '{{ "SYNTHENTS.CONTROLE_ACQUEREUR" | translate }}'
        }
    });

    $stateProvider.state('syntheseentities.decompo', {
        url: '/decomposition',
        params: {
            obj: null
        },
        template: '<syntheseentities-decompo></syntheseentities-decompo>',
        rights: { domain: 'synthadmin', right: 'buildsynthesis', application: 'laboratoire' },
        ncyBreadcrumb: {
            parent: 'syntheseentities.result',
            label: '{{ "SYNTHENTS.DECOMPO.TITLE" | translate }}'
        }
    });

    $stateProvider.state('syntheseentities.cdc', {
        url: '/cartedecontrole',
        params: {
            obj: null
        },
        template: '<syntheseentities-cdc></syntheseentities-cdc>',
        rights: { domain: 'synthadmin', right: 'buildsynthesis', application: 'laboratoire' },
        ncyBreadcrumb: {
            parent: 'syntheseentities.result',
            label: '{{ "SYNTHENTS.CARTE_DE_CONTROLE.TITLE_ACCORDEON" | translate }}'
        }
    });

    $stateProvider.state('syntheseentities.ctrlenrobes', {
        url: '/ctrlenrobes',
        params: {
            obj: null
        },
        template: '<syntheseentities-ctrlenrobes></syntheseentities-ctrlenrobes>',
        rights: { domain: 'synthadmin', right: 'buildsynthesis', application: 'laboratoire' },
        ncyBreadcrumb: {
            parent: 'syntheseentities.result',
            label: '{{ "SYNTHENTS.BREADCRUMBS.CONTROLE_ENROBES" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider', 'MassiaApplicationProvider'];
