import PreviewHomeController from './preview.home.modal.controller';

export default {
    template: require('./preview.home.modal.tpl.html'),
    controller: PreviewHomeController,
    bindings: {
        settings: '=',
        images: '='
    }
};
