import validatorBuilder from 'validator-builder';

let dapEnteteValidator = null;

export default class DapEntete {
	constructor(data = {}) {
		this.id = data.id;
		this.numero = data.numero;
		this.dateCreation = data.dateCreation;
		this.dateValidite = data.dateValidite;
		this.actif = data.actif ? data.actif : data.etat == 1;
		this.signatureClient = data.signatureClient;
		this.signatureProducteurDechet = data.signatureProducteurDechet;
		this.quantite = data.quantite;
		this.siteCommercial = data.siteCommercial;
		this.siteCommercialId = data.siteCommercialId;
		this.siteCommercialCode = data.siteCommercialCode;
		this.producteur = data.producteur;
		this.producteurId = data.producteurId;
		this.producteurCode = data.producteurCode;
		this.producteurDechet = data.producteurDechet;
		this.producteurDechetId = data.producteurDechetId;
		this.producteurDechetCode = data.producteurDechetCode;
		this.nomenclatureDechetId = data.nomenclatureDechetId;
		this.clientDetenteur = data.clientDetenteur;
		this.clientDetenteurId = data.clientDetenteurId;
		this.clientDetenteurCode = data.clientDetenteurCode;
		this.clientDetenteurIsDuBatiment = data.clientDetenteurIsDuBatiment;
		this.chantierOrigine = data.chantierOrigine;
		this.chantierOrigineId = data.chantierOrigineId;
		this.chantierOrigineCode = data.chantierOrigineCode;
		this.lieuOrigine = data.lieuOrigine;
		this.lieuOrigineId = data.lieuOrigineId;
		this.lieuOrigineCode = data.lieuOrigineCode;
		this.controles = data.controles || [];
		this.controlesIds = data.controlesIds || [];
		this.estCodeAuto = data.estCodeAuto;
		this.nomenclatureIds = data.nomenclatureIds || [];
		this.transporteurIds = data.transporteurIds || [];
		this.transporteurSignatures = data.transporteurSignatures || [];
		this.isSignatureValide = data.isSignatureValide;
		this.isReferenceBasias = data.isReferenceBasias;
		this.isReferenceBasol = data.isReferenceBasol;
		this.isContamine = data.isContamine;
		this.isPotentielContamine = data.isPotentielContamine;
		this.documentAnalyse = data.documentAnalyse;
		this.isComplete = data.isComplete;
		this.documentNomenclatures = data.documentNomenclatures;
		this.isBLRefus = data.isBLRefus;
		this.longitude = data.longitude;
		this.latitude = data.latitude;
		this.isChantierDuBatiment = data.isChantierDuBatiment;
		this.ecoOrganisme = data.ecoOrganisme;
	}

	isValid() {
		dapEnteteValidator = dapEnteteValidator || validatorBuilder.getInstanceFor('DapEntete');
		const validationResults = dapEnteteValidator.validate(this);
		return validationResults.isValid;
	}
}
