// Du fait d'une limitation d'angularJS
// le validateur doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
SiteCertificationCeValidator.$inject = ['validator'];

export default function SiteCertificationCeValidator(validator) {
    const instance = new validator();

    // instance.ruleFor('libOrganisme').length(1,100);
    // instance.ruleFor('numOrganisme').length(1,15);
    // instance.ruleFor('numCertif').length(1,100);
    // instance.ruleFor('nomCertif').length(1,100);

    return instance;
}
