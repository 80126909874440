const serviceUrl = `${__configuration.apiUrl}/massia/qualib/referentiels`;

export default class ReferentielsService {
    static $inject = ['$http'];

    constructor($http, TypesNiveauxHierarchique) {
        this.$http = $http;
        this.TypesNiveauxHierarchique = TypesNiveauxHierarchique;
    }

    async getList(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getReferentielById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async createReferentiel(referentiel) {
        const result = await this.$http.post(serviceUrl, referentiel);
        return result.data;
    }

    async updateReferentiel(referentiel) {
        const url = `${serviceUrl}/${referentiel.id}`;
        return await this.$http.put(url, referentiel);
    }

    async deleteReferentielById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getReferentielValeursCaracteristiquesAssociationsById(id) {
        const data = await this.getReferentielValeursCaracteristiquesById(id);
        const result = {};
        for (const element in data) {
            if (data[element] && !Array.isArray(data[element])) {
                if (data[element].value === 'True') {
                    data[element].value = 'true';
                }
                if (data[element].value === 'False') {
                    data[element].value = 'false';
                }

                if (data[element].value && data[element].value.key) {
                    result[data[element].id] = data[element].value.key;
                } else {
                    result[data[element].id] = data[element].value;
                }
            }
        }
        return result;
    }

    async getReferentielValeursCaracteristiquesById(id) {
        const url = `${serviceUrl}/${id}/valeurs-caracteristiques`;
        const res = await this.$http.get(url);
        const result = [];
        res.data.forEach(function (element) {
            const caract = {
                id: element.id,
                code: element.code,
                idNature: element.idNature,
                indice: element.indice,
                label: element.label,
                numericAdditionalInformation: element.numericAdditionalInformation,
                value: element.elementsValues || element.elementValue || element.values || element.value
            };
            result.push(caract);
        });
        return result;
    }

    async getTypeValeursCaracteristiquesById(id, idTypeProduit) {
        const url = `${serviceUrl}/${id}/type-valeurs-caracteristiques/${idTypeProduit}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getReferentielComposant(id) {
        const url = `${__configuration.apiUrl}/massia/qualib/referentiel-composant/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async estCodeAuto() {
        const url = `${serviceUrl}/est-code-auto`;
        try {
            const res = await this.$http.get(url);
            return res.data;
        } catch (err) {
            if (err.status === 404) {
                return false;
            }
            throw err;
        }
    }
}
