import ProduitsCommerciauxCalculModalController from './produits.commerciaux.calcul.modal.controller';

export default {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: require('./produits.commerciaux.calcul.modal.html'),
    controller: ProduitsCommerciauxCalculModalController
};
