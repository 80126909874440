const serviceUrl = `${__configuration.apiUrl}/massia/GrillesZonesKm`;

export default class GrillesZonesKilometriquesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getGrillesZonesKilometriques(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });
        return res.data;
    }

    async getGrilleZonesKilometriquesById(idGrilleZonesKilometriques) {
        const url = `${serviceUrl}/${idGrilleZonesKilometriques}`;

        const res = await this.$http.get(url);
        return res.data;
    }

    async deleteGrilleZonesKilometriquesById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async createGrilleZonesKilometriques(grille) {
        const result = await this.$http.post(serviceUrl, grille);
        return result.data;
    }

    async updateGrilleZonesKilometriques(grille) {
        const url = `${serviceUrl}/${grille.id}`;
        return this.$http.put(url, grille);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getZonesSelect(filters, sorts, pagination) {
        const zoneUrl = `${__configuration.apiUrl}/massia/zones`;
        const res = await this.$http.get(zoneUrl, {
            params: {
                filters: undefined, //JSON.stringify(filters || []),
                sorts: undefined, //JSON.stringify(sorts || []),
                pagination: undefined //JSON.stringify(pagination || {})
            }
        });
        return res.data.items;
    }
}
