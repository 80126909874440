import { Injectable } from '../../../core/decorators';

@Injectable('ElectronService')
export class ElectronService {
    constructor($http) {
        this.http = $http;
    }

    async CheckServer(url) {
        const res = await this.http.get(`${url}api/massia/app`);
        return res.data;
    }
}

ElectronService.$inject = ['$http'];
