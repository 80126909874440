// import TVAController from './tva.model';

export default class CdgTvaController {
    static $inject = [
        '$state',
        '$stateParams',
        'CentreGestionService',
        'ModalService',
        'notification',
        '$translate',
        '$filter',
        '_',
        'FacturesService',
        '$timeout',
        'globalizationManagementService'
    ];

    datasource = {};
    adapt = {};

    constructor(
        $state,
        $stateParams,
        CentreGestionService,
        ModalService,
        notification,
        $translate,
        $filter,
        _,
        FacturesService,
        $timeout,
        globalizationManagementService
    ) {
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.CentreGestionService = CentreGestionService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.$filter = $filter;
        this._ = _;
        this.FacturesService = FacturesService;
        this.eligible = ['tvaProduits', 'tvaPresta', 'tvaTransp'];

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    $onInit() {
        this.datasource = {
            get: (index, count, success) => {
                this.$timeout(() => {
                    // make it zero-based instead of 1-based.
                    index--;
                    const result = [];
                    const copy = this.$filter('filter')(this.myList, this.filtreTva[this.parametre.codeParametre]);
                    // var copy = this.$filter('filter')(angular.copy(this.myList), this.filtreTva[this.parametre.codeParametre]);
                    if (copy) {
                        for (let i = index; i <= index + (count - 1); i++) {
                            if (!copy[i]) {
                                continue;
                            }
                            result.push(copy[i]);
                        }
                    }
                    success(result);
                }, 100);
            }
        };
        this.parametre = this.parametre;
        this.allSelected = false;
        this.changedValue = [false, false];
        this.tvaGenerale = [];
        this.tva = [];
        this.getSelectionnable();
        this.getlistes();
        this.filtreTva = [];
        this.cptOK = 0;
        this.cptNOK = 0;
    }

    filterTva() {
        if (this.filtreProduit) {
            this.myList = this.myList.filter((x) => x.libelle.toUpperCase().includes(this.filtreProduit.toUpperCase()));
            this.isFlitrer = true;
        } else {
            this.getSelectionnable();
            this.isFlitrer = false;
        }
    }

    getHeritage(element, achatVente, parent) {
        let IsHerited = null;
        if (achatVente == 0) {
            IsHerited = element.isHeritedAchat;
        } else if (achatVente == 1) {
            IsHerited = element.isHeritedVente;
        }

        if (IsHerited == true) {
            return '<span class="text-info">' + this.$translate.instant('CENTRE_GESTION.HERITAGE', { libelle: parent.parentLibelle }) + '</span>';
        } else if (IsHerited == false) {
            return '<span class="text-success">' + this.$translate.instant('CENTRE_GESTION.VAL_PROPRE') + '</span>';
        }
        return '<span>' + this.$translate.instant('CENTRE_GESTION.DEFAULTVALUE') + '</span>';
    }

    async getlistes() {
        await this.getTvas();
    }

    async getSelectionnable() {
        this.tvaGenerale[this.parametre.codeParametre] = [];
        this.tva[this.parametre.codeParametre] = [];

        switch (this.parametre.codeParametre) {
            case 'tvaProduits':
                try {
                    this.listes.allProduits = [];
                    if (this.domaine == 'Pays') {
                        this.listes.allProduits = await this.CentreGestionService.getAllProduits();
                    } else if (this.domaine == 'Chantiers' && (this.frontFilters.siteCommercial || this.frontFilters.societes)) {
                        // le service getProduitsSelectionnables() ne renvoie pas la même structure d'objet que le service getAllProduits()
                        // il faut donc l'adapter directement ici pour ne pas tout casser ailleurs...
                        this.listes.produitsSelectionnables = await this.CentreGestionService.getProduitsSelectionnables(this.filterToBack);
                        if (this.listes.produitsSelectionnables.length > 0) {
                            console.log(this.listes.produitsSelectionnables);
                            for (let index = 0; index < this.listes.produitsSelectionnables.length; index++) {
                                const item = this.listes.produitsSelectionnables[index];
                                var tempElt = {
                                    id: item.produitId,
                                    code: item.produitCode,
                                    libelle: item.produitLibelle
                                };
                                this.listes.allProduits.push(tempElt);
                            }
                        }
                    }

                    this.myList = [];
                    for (var i = 0; i < this.listes.allProduits.length; i++) {
                        var element = this.listes.allProduits[i];

                        // le taux de TVA étant applicable pour tous les produits peu importe le producteur
                        // on se retrouve avec des "doublons" dans l'affichage
                        const produitAlreadyListed = this._.find(this.myList, { produitId: element.id });
                        if (produitAlreadyListed) {
                            continue;
                        }

                        var obj = {
                            produitId: element.id,
                            libelle: '[' + element.code + '] ' + element.libelle,
                            tvaIdVente: 0,
                            dateVente: '...',
                            tvaIdAchat: 0,
                            dateAchat: '...',
                            codeParametre: this.parametre.codeParametre
                        };
                        var foundVente = this._.find(this.parametre.donnees[1].valeurs, { produitId: element.id });
                        if (typeof foundVente !== 'undefined') {
                            obj.tvaIdVente = Number(foundVente.tvaId);
                            obj.dateVente = foundVente.dateApplication;
                            obj.idVente = foundVente.id;
                            obj.isHeritedVente = foundVente.isHerited;
                        } else {
                            // si on trouve pas la valeur, on prend celle par défaut
                            obj.tvaIdVente = Number(this.parametre.donnees[2].valeurs[0].valeur);
                            obj.isHeritedVente = null;
                        }

                        var foundAchat = this._.find(this.parametre.donnees[0].valeurs, { produitId: element.id });
                        if (typeof foundAchat !== 'undefined') {
                            obj.tvaIdAchat = Number(foundAchat.tvaId);
                            obj.dateAchat = foundAchat.dateApplication;
                            obj.idAchat = foundAchat.id;
                            obj.isHeritedAchat = foundAchat.isHerited;
                        } else {
                            // si on trouve pas la valeur, on prend celle par défaut
                            obj.tvaIdAchat = Number(this.parametre.donnees[2].valeurs[0].valeur);
                            obj.isHeritedAchat = null;
                        }

                        this.myList.push(obj);
                    }
                } catch (err) {
                    if (err.data) {
                        this.notification.error(err.data);
                    } else {
                        throw err;
                    }
                }
                break;
            case 'tvaPresta':
                try {
                    this.listes.allPrestations = {};
                    this.listes.allPrestations = [];

                    if (this.domaine == 'Pays') {
                        this.listes.allPrestations = await this.CentreGestionService.getAllPrestations();
                    } else if (this.domaine == 'Chantiers' && (this.frontFilters.siteCommercial || this.frontFilters.societes)) {
                        // le service getProduitsLies() ne renvoie pas la même structure d'objet que le service getAllProduits()
                        // il faut donc l'adapter directement ici pour ne pas tout casser ailleurs...
                        if (this.listes.prestationsSelectionnables.length > 0) {
                            for (let index = 0; index < this.listes.prestationsSelectionnables.length; index++) {
                                const item = this.listes.prestationsSelectionnables[index];
                                var tempElt = {
                                    id: item.prestationId,
                                    code: item.prestationCode,
                                    libelle: item.prestationLibelle
                                };
                                this.listes.allPrestations.push(tempElt);
                            }
                        }
                    }

                    this.myList = [];
                    for (var i = 0; i < this.listes.allPrestations.length; i++) {
                        var element = this.listes.allPrestations[i];
                        var obj = {
                            prestationId: element.id,
                            libelle: '[' + element.code + '] ' + element.libelle,
                            tvaIdVente: 0,
                            dateVente: '...',
                            tvaIdAchat: 0,
                            dateAchat: '...',
                            codeParametre: this.parametre.codeParametre
                        };
                        var foundVente = this._.find(this.parametre.donnees[1].valeurs, { prestationId: element.id });
                        if (typeof foundVente !== 'undefined') {
                            obj.tvaIdVente = Number(foundVente.tvaId);
                            obj.dateVente = foundVente.dateApplication;
                            obj.idVente = foundVente.id;
                            obj.isHeritedVente = foundVente.isHerited;
                        } else {
                            // si on trouve pas la valeur, on prend celle par défaut
                            obj.tvaIdVente = Number(this.parametre.donnees[2].valeurs[0].valeur);
                            obj.isHeritedVente = null;
                        }

                        var foundAchat = this._.find(this.parametre.donnees[0].valeurs, { prestationId: element.id });
                        if (typeof foundAchat !== 'undefined') {
                            obj.tvaIdAchat = Number(foundAchat.tvaId);
                            obj.dateAchat = foundAchat.dateApplication;
                            obj.idAchat = foundAchat.id;
                            obj.isHeritedAchat = foundAchat.isHerited;
                        } else {
                            // si on trouve pas la valeur, on prend celle par défaut
                            obj.tvaIdAchat = Number(this.parametre.donnees[2].valeurs[0].valeur);
                            obj.isHeritedAchat = null;
                        }

                        this.myList.push(obj);
                    }
                } catch (err) {
                    if (err.data) {
                        this.notification.error(err.data);
                    } else {
                        throw err;
                    }
                }

                break;
            case 'tvaTransp':
                try {
                    this.listes.allTransports = {};
                    this.listes.allTransports.items = [];

                    if (this.domaine == 'Pays') {
                        this.listes.allTransports = await this.CentreGestionService.getAllTransports();
                    } else if (this.domaine == 'Chantiers' && (this.frontFilters.siteCommercial || this.frontFilters.societes)) {
                        // le service getProduitsLies() ne renvoie pas la même structure d'objet que le service getAllProduits()
                        // il faut donc l'adapter directement ici pour ne pas tout casser ailleurs...
                        if (this.listes.transportsSelectionnables.length > 0) {
                            for (let index = 0; index < this.listes.transportsSelectionnables.length; index++) {
                                const item = this.listes.transportsSelectionnables[index];
                                var tempElt = {
                                    id: item.transportId,
                                    code: item.transportCode,
                                    libelle: item.transportLibelle
                                };
                                this.listes.allTransports.items.push(tempElt);
                            }
                        }
                    }
                    this.myList = [];
                    for (var i = 0; i < this.listes.allTransports.items.length; i++) {
                        var element = this.listes.allTransports.items[i];
                        var obj = {
                            transportId: element.id,
                            libelle: '[' + element.code + '] ' + element.libelle,
                            tvaIdVente: 0,
                            dateVente: '...',
                            tvaIdAchat: 0,
                            dateAchat: '...',
                            codeParametre: this.parametre.codeParametre
                        };
                        var foundVente = this._.find(this.parametre.donnees[1].valeurs, { transportId: element.id });
                        if (typeof foundVente !== 'undefined') {
                            obj.tvaIdVente = Number(foundVente.tvaId);
                            obj.dateVente = foundVente.dateApplication;
                            obj.idVente = foundVente.id;
                            obj.isHeritedVente = foundVente.isHerited;
                        } else {
                            // si on trouve pas la valeur, on prend celle par défaut
                            obj.tvaIdVente = Number(this.parametre.donnees[2].valeurs[0].valeur);
                            obj.isHeritedVente = null;
                        }

                        var foundAchat = this._.find(this.parametre.donnees[0].valeurs, { transportId: element.id });
                        if (typeof foundAchat !== 'undefined') {
                            obj.tvaIdAchat = Number(foundAchat.tvaId);
                            obj.dateAchat = foundAchat.dateApplication;
                            obj.idAchat = foundAchat.id;
                            obj.isHeritedAchat = foundAchat.isHerited;
                        } else {
                            // si on trouve pas la valeur, on prend celle par défaut
                            obj.tvaIdAchat = Number(this.parametre.donnees[2].valeurs[0].valeur);
                            obj.isHeritedAchat = null;
                        }

                        this.myList.push(obj);
                    }
                } catch (err) {
                    if (err.data) {
                        this.notification.error(err.data);
                    } else {
                        throw err;
                    }
                }
                break;
        }
    }

    async appliquerTva(codeParametre, av) {
        for (let i = 0; i < this.myList.length; i++) {
            const element = this.myList[i];
            if (element.selected) {
                // SL récupération du taux de TVA dans la partie action en lot
                if (av == 1) {
                    element.tvaIdVente = this.tvaGenerale[codeParametre][av];
                } else {
                    element.tvaIdAchat = this.tvaGenerale[codeParametre][av];
                }

                await this.changingValue(element, av, false);
            }
        }

        this.notification.success(this.$translate.instant('CENTRE_GESTION.MESSAGES.SAVED', { nombre: this.cptOK }));

        if (this.cptNOK > 0) {
            this.notification.error(this.$translate.instant('CENTRE_GESTION.MESSAGES.NOT_SAVED', { nombre: this.cptNOK }));
            return new Promise((reject) => {
                reject(false);
            });
        }

        // recharge la page
        this.onDelete();
    }

    selectAll(codeParametre) {
        const withFilter = this.$filter('filter')(this.myList, this.filtreTva[this.parametre.codeParametre]);
        for (let i = 0; i < withFilter.length; i++) {
            const foundVente = this._.findIndex(this.myList, withFilter[i]);
            const element = this.myList[foundVente];
            element.selected = !this.allSelected;
        }
    }

    async getTvas() {
        if (!this.listes.tva) {
            try {
                this.listes.tva = await this.CentreGestionService.getTvas();
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            }
        }
    }

    async reinit(objetChamps, av) {
        let idToDelete = 0;
        if (av == 0) {
            idToDelete = objetChamps.idAchat;
        } else if (av == 1) {
            idToDelete = objetChamps.idVente;
        }
        try {
            await this.CentreGestionService.effacer(idToDelete);
            this.onDelete();
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            // this.onDelete();
        }
    }

    async changingValue(data, av, isSingle) {
        !this.dateApplication ? (this.dateApplication = this.moment().format(this.dateFormat)) : this.dateApplication;

        // SL initialisation des booleans d'action
        let ToCreate = false;
        let ToUpdate = false;
        let ToDelete = false;

        // SL on détermine si on doit faire un Create, un Update ou un Delete
        if (av == 1) {
            if (data.dateVente == '...') {
                // SL Create d'office !
                ToCreate = true;
            } else {
                if (data.dateVente == this.dateApplication) {
                    if (data.tvaIdVente == null) {
                        ToDelete = true;
                    } else {
                        ToUpdate = true;
                    }
                } else {
                    if (data.tvaIdVente == null) {
                        // SL nothing to do
                    } else {
                        ToCreate = true;
                    }
                }
            }
        } else {
            if (data.dateAchat == '...') {
                ToCreate = true;
            } else {
                if (data.dateAchat == this.dateApplication) {
                    if (data.tvaIdAchat == null) {
                        ToDelete = true;
                    } else {
                        ToUpdate = true;
                    }
                } else {
                    if (data.tvaIdAchat == null) {
                        // SL nothing to do
                    } else {
                        ToCreate = true;
                    }
                }
            }
        }

        // SL initialisation de l'objet
        const element = {
            libelle: data.libelle,
            achatVente: av,
            tvaId: null,
            valeur: false,
            codeDomaine: this.domaine,
            dateApplication: this.dateApplication
        };

        // SL mapping pour avoir le bon code dans la table ParametreElement
        switch (data.codeParametre) {
            case 'tvaProduits':
                element.elementCode = 'tauxTVAProduit';
                break;
            case 'tvaPresta':
                element.elementCode = 'tauxTVAPrestations';
                break;
            case 'tvaTransp':
                element.elementCode = 'tauxTVATransports';
                break;

            default:
                break;
        }

        // SL récupération de l'Id Pays ou Chantier selon l'entité où l'on se trouve
        switch (this.domaine) {
            case 'Pays':
                element.paysId = this.source.id;
                break;
            case 'Chantiers':
                element.chantierId = this.source.id;
                break;
            default:
                break;
        }

        // SL récupération de l'Id de l'article
        if (data.produitId) {
            element.produitId = data.produitId;
        } else if (data.transportId) {
            element.transportId = data.transportId;
        } else if (data.prestationId) {
            element.prestationId = data.prestationId;
        }

        // SL récupération du bon taux de TVA en fonction du paramètre AchatVente
        if (av == 1) {
            element.tvaId = data.tvaIdVente;
            element.id = data.idVente;
        } else {
            element.tvaId = data.tvaIdAchat;
            element.id = data.idAchat;
        }

        try {
            let resultOk = null;

            // SL appel du bon service en fonction des booleans d'action
            if (ToCreate) {
                resultOk = await this.CentreGestionService.insert(element);
                // SL si on est en mode single, et si c'est ok, on recharge la page
                // on met à jour la ligne
                if (resultOk != null && isSingle) {
                    if (av == 1) {
                        data.idVente = resultOk;
                        data.isHeritedVente = false;
                        data.dateVente = this.dateApplication;
                    } else {
                        data.idAchat = resultOk;
                        data.isHeritedAchat = false;
                        data.dateAchat = this.dateApplication;
                    }
                }
            } else if (ToUpdate) {
                resultOk = await this.CentreGestionService.update(element);
            } else if (ToDelete) {
                resultOk = await this.CentreGestionService.effacer(element.id);
                this.onDelete();
            }

            // SL si on est mode lot, on comptabilise si c'est actions sont ok ou non pour le toaster
            if (isSingle == false) {
                if (resultOk != null) {
                    this.cptOK++;
                } else {
                    this.cptNOK++;
                }
            }
        } catch (err) {
            this.cptNOK++; // pour le toaster
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            // I don't know
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
