import validatorBuilder from 'validator-builder';
import OffreEntete from '../offre-entete/offre.entete.model';
import OffreDemande from '../offre-demande/offre.demande.model';

let offreValidator = null;

export default class Offre {
    constructor(data) {
        if (data) {
            this.entete = new OffreEntete(data);
            this.demande = new OffreDemande(data);
        } else {
            this.entete = new OffreEntete();
            this.demande = new OffreDemande();
        }
    }

    isValid() {
        offreValidator = offreValidator || validatorBuilder.getInstanceFor('Offre');
        const validationResults = offreValidator.validate(this);
        return validationResults.isValid;
    }
}
