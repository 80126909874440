import validatorBuilder from 'validator-builder';

let societesAssocieesValidator = null;

export default class SocietesAssociees {
    constructor(data = {}) {
        this.id = data.id;
        this.societeId = data.idSociete;
        this.nomAssocie = data.nomAssocie;
        this.associeId = data.idAssocie;
        this.pourcentage = data.pourcentage;
    }

    isValid() {
        societesAssocieesValidator = societesAssocieesValidator || validatorBuilder.getInstanceFor('SocietesAssociees');
        const validationResults = societesAssocieesValidator.validate(this);
        return validationResults.isValid;
    }
}
