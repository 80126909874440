export default function Configure($stateProvider) {
    $stateProvider.state('monitoringsynchro', {
        parent: 'gestion',
        url: '/monitoring',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true,
            label: '{{ "LAYOUT.NAVIGATION.OUTILS" | translate }}'
        }
    });

    $stateProvider.state('monitoringsynchro.list', {
        url: '/synchro',
        template: '<monitoring-synchro></monitoring-synchro>',
        rights: { domain: 'monitoringsync', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "MONITORING_SYNCHRO.BREADCRUMBS.MONITORING" | translate}}'
        },
        search: 'MONITORING_SYNCHRO.BREADCRUMBS.MONITORING'
    });
}

Configure.$inject = ['$stateProvider'];
