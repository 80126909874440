import MotifRefusAdmissionConfig from './_config/motif.refus.admission.config';
import MotifRefusAdmissionRoutes from './_config/motif.refus.admission.routes';
import MotifRefusAdmissionComponent from './components/motif-refus-admission';
import MotifRefusAdmissionService from './services/motif.refus.admission.service';
import MotifRefusAdmissionCommunicationService from './services/motif.refus.admission.communication.service.js';
import MotifRefusAdmissionFormComponent from './components/motif-refus-admission-form';
import MotifRefusAdmissionValidator from './components/motif-refus-admission-form/motif.refus.admission.validator';

const moduleName = 'app.massia.common.motif.refus.admission';

angular
    .module(moduleName, [])
    .config(MotifRefusAdmissionConfig)
    .config(MotifRefusAdmissionRoutes)
    .service('MotifRefusAdmissionService', MotifRefusAdmissionService)
    .service('MotifRefusAdmissionCommunicationService', MotifRefusAdmissionCommunicationService)
    .component('motifRefusAdmission', MotifRefusAdmissionComponent)
    .component('motifRefusAdmissionForm', MotifRefusAdmissionFormComponent)
    .factory('MotifRefusAdmissionValidator', MotifRefusAdmissionValidator);

export default moduleName;
