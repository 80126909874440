//Config
import PrelevementsConfig from './_config/prelevements.config';
import PrelevementsRoutes from './_config/prelevements.routes';

//Components
import CampagneComponent from './components/campagne';
import CampagneEnteteComponent from './components/campagne/entete';
import CampagneCarteComponent from './components/campagne/entete/carte';
import CampagneCaracteristiquesComponent from './components/campagne/caracteristiques';

import PrelevementComponent from './components/prelevement';
import PrelevementEnteteComponent from './components/prelevement/entete';
import PrelevementBonLivraisonComponent from './components/prelevement/bon-livraison';
import PrelevementCarteComponent from './components/prelevement/entete/carte';
import PrelevementListeMesuresComponent from './components/prelevement/liste-mesures';
import PrelevementValeurMesureComponent from './components/prelevement/liste-mesures/valeur-mesure';

import PrelevementExportModalComponent from './components/prelevement/export-modal';
import PrelevementConfigImpressionComponent from './components/prelevement/config-impression';
import PrelevementGestionColonneTamisComponent from './components/prelevement/gestion-colonne-tamis-modal';

import PrelevementsComponent from './components/prelevements';
import Presse3rModalImportComponent from './components/presse3r-modal-import';

import ImportGranulo from './components/prelevement/liste-mesures/valeur-mesure/modal-import-granulo';

// services
import CampagnesTypesService from './services/campagnes.types.service';
import CampagnesService from './services/campagnes.service';

import PrelevementsService from './services/prelevements.service';
import PrelevementsCommunicationService from './services/prelevements.communication.service';
import PrelevementsConfigImpressionService from './services/prelevements.config.impression.service';

import PrelevEssaiMaterielsService from './services/prelev.essai.materiel.service';

import PrelevementImportDataService from './services/prelevement.import.data.service';
import { PropositionBestTrameRefService } from './services/proposition.best.trame.ref.service';
import Presse3rExportService from './services/presse3r.export.service';

//Validateurs
import CampagneValidator from './components/campagne/campagne.validator';
import CampagneCaracteristiquesValidator from './components/campagne/caracteristiques/campagne.caracteristiques.validator';
import CampagneEnteteValidator from './components/campagne/entete/campagne.entete.validator';

import PrelevementValidator from './components/prelevement/prelevement.validator';
import PrelevementMesureValidator from './components/prelevement/prelevement.mesure.validator';
import Presse3rModalImportValidator from './components/presse3r-modal-import/presse3r.modal.import.validator';

const moduleName = 'app.massia.common.prelevements';

angular
    .module(moduleName, [])
    .config(PrelevementsConfig)
    .config(PrelevementsRoutes)

    .service('PrelevementsService', PrelevementsService)
    .service('PrelevementsCommunicationService', PrelevementsCommunicationService)
    .service('PrelevementsConfigImpressionService', PrelevementsConfigImpressionService)
    .service('CampagnesTypesService', CampagnesTypesService)
    .service('CampagnesService', CampagnesService)
    .service('PrelevEssaiMaterielsService', PrelevEssaiMaterielsService)
    .service('PrelevementImportDataService', PrelevementImportDataService)
    .service('PropositionBestTrameRefService', PropositionBestTrameRefService)
    .service('Presse3rExportService', Presse3rExportService)
    .component('prelevements', PrelevementsComponent)
    .component('prelevement', PrelevementComponent)
    .component('prelevementEntete', PrelevementEnteteComponent)
    .component('prelevementBonLivraison', PrelevementBonLivraisonComponent)
    .component('prelevementCarte', PrelevementCarteComponent)
    .component('prelevementListeMesures', PrelevementListeMesuresComponent)
    .component('prelevementValeurMesure', PrelevementValeurMesureComponent)
    .component('prelevementExportModal', PrelevementExportModalComponent)
    .component('prelevementGestionColonneTamisComponent', PrelevementGestionColonneTamisComponent)
    .component('prelevementConfigImpression', PrelevementConfigImpressionComponent)

    .component('campagne', CampagneComponent)
    .component('campagneEntete', CampagneEnteteComponent)
    .component('campagneCarte', CampagneCarteComponent)
    .component('campagneCaracteristiques', CampagneCaracteristiquesComponent)
    .component('presse3rModalImport', Presse3rModalImportComponent)
    .component('modalImportGranulo', ImportGranulo)

    .factory('PrelevementValidator', PrelevementValidator)
    .factory('PrelevementMesureValidator', PrelevementMesureValidator)
    .factory('Presse3rModalImportValidator', Presse3rModalImportValidator)

    .factory('CampagneValidator', CampagneValidator)
    .factory('CampagneCaracteristiquesValidator', CampagneCaracteristiquesValidator)
    .factory('CampagneEnteteValidator', CampagneEnteteValidator);

export default moduleName;
