import PlanImpressionPrixFrancoController from './plan.impression.prix.franco.controller';

export default {
    bindings: {
        modalInstance: '=',
        domaine: '=',
        urlDomainName: '=',
        urlDomainIds: '=',
        id: '=',
        date: '='
    },
    template: require('./plan.impression.prix.franco.html'),
    controller: PlanImpressionPrixFrancoController
};
