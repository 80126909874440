import validatorBuilder from 'validator-builder';

let deviseEnteteValidator = null;

export default class DeviseEntete {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.symbole = data.symbole;
        this.arrondi = data.arrondi;
        this.codeExists = data.codeExists || null;
        this.codeInvalid = data.codeInvalid || null;
    }

    isValid() {
        deviseEnteteValidator = deviseEnteteValidator || validatorBuilder.getInstanceFor('DeviseEntete');
        const validationResults = deviseEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
