import { copy } from 'angular';
import { Injectable } from '../../../core/decorators';

@Injectable('RouterHistoryService')
export class RouterHistoryService {
    MAX_HISTORY = 15; // Nombre d'historique maximum enregistrer
    isBack = false; // Pour detecter si on doit ou non enregistrer la route dans l'historique (utile pour quand on fait un retour)
    history = []; // Le tableau d'historique
    modelList = []; // Liste des model pour les retour
    ignoreNext = false;

    constructor($state) {
        this.$state = $state;
        this.history = $state.history || []; // Aucune raison que le tableau n'existe pas mais l'initialise si c'est le cas
        this.modelList = []; // Aucune raison que le tableau n'existe pas mais l'initialise si c'est le cas
    }

    // Permet de definir les donnée à garder quand on reviendra sur une route
    set data(value) {
        this.modelList.push({
            model: value,
            name: this.$state.current.name
        });
    }

    // Permet de recuperer les données que l'on avais defini pour cette route
    get data() {
        const idx = this.modelList.findIndex((x) => x.name === this.$state.current.name);
        var model = null;
        if (idx > -1) {
            model = angular.copy(this.modelList[idx].model);
            this.modelList.splice(idx, 1);
        }
        return model;
    }

    // La fonction init est lancer au demarage de l'application aucune raison de le lancer manualement par la suite elle se gere toute seule
    init(to, from, fromParams) {
        if (!this.ignoreNext && !this.isBack && from.name !== '') {
            // Si on est pas sur un retour
            from.params = fromParams; // Recup des paramètres à conserver

            if (this.history.length >= this.MAX_HISTORY) {
                // Si l'historique est deja complet on supprime le plus ancien et on rajoute le nouveau
                this.history.splice(0, 1);
            }

            this._removeModelIfExist(to); // Si on reviens sur une route en aillant couper l'aller retour on efface ce que l'on garder en mémoire

            this.history.push(from);
        }
        if (this.ignoreNext) {
            this._removeModelIfExist(to);
        }
        this.ignoreNext = false;
        this.isBack = false;
    }

    ignoreNextRoute() {
        this.ignoreNext = true;
    }

    // Pour retourner sur la deniere route
    back(newParams = {}) {
        // this.history
        // console.log("🚀 ~ file: router.history.service.js ~ line 66 ~ RouterHistoryService ~ back ~ this.history", this.history)
        if (this.history.length > 0) {
            // On check si on à bien un historique

            // On recupere la route sur laquel on doit se rendre
            const last = this.history[this.history.length - 1];

            // On concatene les parametre de retour avec les nouveau (si besoin de rajouter des parametre)
            var params = {
                ...last.params,
                ...newParams
            };

            this.isBack = true; // Indique que c'est un retour pour ne pas l'enregistrer dans l'historique
            this.$state.go(last.name, params); // On part sur la route
            this.history.splice(this.history.length - 1, 1); // On supprime la route de notre historique puisque c'est un retour
            return true;
        }

        return false;
    }

    _removeModelIfExist(to) {
        const idx = this.modelList.findIndex((x) => x.name === to.name);

        if (idx > -1) {
            this.modelList.splice(idx, 1);
        }
    }
}

RouterHistoryService.$inject = ['$state'];
