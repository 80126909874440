import SerieTamis from './serie.tamis.model';

export default class SerieTamisFormController {
    static $inject = [
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'SeriesTamisCommunicationService',
        'SeriesTamisService',
        'notification',
        '$location',
        '$anchorScroll',
        '_',
        '$uibModal',
        'SeriesTamisTypesService',
        '$filter',
        '$translate',
        'MassiaApplicationService',
        'MOPService'
    ];

    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        SeriesTamisCommunicationService,
        SeriesTamisService,
        notification,
        $location,
        $anchorScroll,
        _,
        $uibModal,
        SeriesTamisTypesService,
        $filter,
        $translate,
        MassiaApplicationService,
        MOPService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SeriesTamisCommunicationService = SeriesTamisCommunicationService;
        this.SeriesTamisService = SeriesTamisService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this._ = _;
        this.$uibModal = $uibModal;
        this.SeriesTamisTypesService = SeriesTamisTypesService;
        this.$filter = $filter;
        this.$translate = $translate;
        this.MassiaApplicationService = MassiaApplicationService;

        this.ongletOpen = {
            isEnteteOpen: false,
            isCaracteristiqueOpen: false
        };
        this.ongletOpenVal = {
            isEnteteOpen: 'entete',
            isCaracteristiqueOpen: 'caracteristiques'
        };
        this.MOPService = MOPService;
    }

    async $onInit() {
        this.MOPService.setMop([{ title: 'SERIES_TAMIS.BREADCRUMBS.SERIES_TAMIS_LIST', filename: 'SeriesdeTamis.pdf', application: 'laboratoire' }]);
        this.serieTamis = {};
        this.types = this.types || [];
        this.getTypes();
        this.loading = false;
        this.form = {
            entete: {},
            caracteristiques: {}
        };
        await this.getNiveauVisibilite();
        await this.reset();
        this.getUnites();
    }

    async getUnites() {
        this.startLoading();
        try {
            const res = await this.SeriesTamisService.getUnites();
            this.unites = res.items;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getNiveauVisibilite() {
        if (this.serieTamis.idType) {
            this.serieTamis.niveauVisibilite = await this.SeriesTamisTypesService.GetNiveauVisibilite(this.serieTamis.idType);
        }
    }

    $onDestroy() {
        this.$timeout.cancel(this.updateSerieTamisTimeout);
        this.MOPService.resetMop();
    }

    async reset() {
        this.startLoading();

        let data = {
            id: this.$stateParams.id
        };

        if (data.id) {
            try {
                data = await this.SeriesTamisService.getSerieTamisEnteteById(data.id);
                data.caracteristiques = await this.SeriesTamisService.getSerieTamisValeursCaracteristiquesAssociationsById(data.id);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
                this.annuler();
            }
        } else {
            data.isEnabled = true;
        }
        this.serieTamis = new SerieTamis(data);

        if (this.$stateParams.duplicate) {
            this.serieTamis.id = undefined;
            this.serieTamis.code += '_copie';
            this.serieTamis.traiteDuplication = true;
        }
        this.ongletOpen = {
            isEnteteOpen: true,
            isOuverturesOpen: false,
            isCaracteristiqueOpen: false
        };

        this.$timeout.cancel(this.updateSerieTamisTimeout);
        this.updateSerieTamisTimeout = this.$timeout(() => this.stopLoading());
        this.trieTamis();
        if (this.serieTamis.tamis && this.serieTamis.tamis.length == 0) {
            this.ajouterLigneTamis();
        }
    }

    trieTamis() {
        this.serieTamis.tamis = this.$filter('orderBy')(this.serieTamis.tamis, 'ouverture');
    }

    supprimerToutesLignesTamis() {
        this.serieTamis.tamis.splice(0, this.serieTamis.tamis.length);
        this.ajouterLigneTamis();
    }

    ajouterLigneTamis() {
        const obj = {
            id: 0,
            ouverture: undefined
        };
        this.serieTamis.tamis.push(obj);
    }

    deleteTamis(tamis) {
        const indice = this.serieTamis.tamis.indexOf(tamis);
        this.serieTamis.tamis.splice(indice, 1);
        if (this.serieTamis.tamis && this.serieTamis.tamis.length == 0) {
            this.ajouterLigneTamis();
        }
    }

    formatCaracteristiques(caracteristiques) {
        const data = [];
        for (const property in caracteristiques) {
            let keyValueObject;
            //On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variables interne Js.
            if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
                const propertyVal = caracteristiques[property];
                if (Array.isArray(propertyVal)) {
                    if (_.some(propertyVal, 'key')) {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: _.map(propertyVal, 'key')
                        };
                    } else {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: propertyVal
                        };
                    }
                } else {
                    keyValueObject = {
                        IdCaracteristique: parseInt(property),
                        valeur: propertyVal
                    };
                }
                data.push(keyValueObject);
            }
        }
        return data;
    }

    async sauvegarder() {
        if (this.checkPasDoublonLigne()) {
            if (this.checkValidity()) {
                if (this.serieTamis.idType == null) {
                    this.notification.error(this.$translate.instant('SERIES_TAMIS.NOTYPE'));
                    return false;
                }

                this.startLoading();

                // conversion de la liste des caracteristiques au bon format
                const clonedCarac = Object.assign({}, this.serieTamis.caracteristiques);
                this.serieTamis.caracteristiques = this.formatCaracteristiques(clonedCarac);

                try {
                    let id = false;
                    if (this.serieTamis.id) {
                        await this.SeriesTamisService.updateSerieTamis(this.serieTamis);
                        this.notification.success('SERIES_TAMIS.UPDATED');
                        id = this.serieTamis.id;
                    } else {
                        id = await this.SeriesTamisService.createSerieTamis(this.serieTamis);
                        this.notification.success('SERIES_TAMIS.CREATED');
                    }

                    return id;
                } catch (ex) {
                    this.notification.error(ex.data);
                    return false;
                } finally {
                    this.stopLoading();
                }
            }
        } else {
            this.notification.error(this.$translate.instant('SERIES_TAMIS.DOUBLON_LIGNE'));
            return false;
        }
    }

    async appliquer() {
        const id = await this.sauvegarder();

        if (id && this.serieTamis.id) {
            this.reset();
        } else if (id) {
            if (this.MassiaApplicationService.getApplication() == 'laboratoire') {
                this.$state.go('series-tamis-labo.edit', { id: id });
            } else {
                this.$state.go('series-tamis.edit', { id: id });
            }
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    annuler() {
        if (this.MassiaApplicationService.getApplication() == 'laboratoire') {
            this.$state.go('series-tamis-labo.list');
        } else {
            this.$state.go('series-tamis.list');
        }
    }

    checkPasDoublonLigne() {
        const lignes = [];
        let ok = true;
        for (let i = 0; ok && i < this.serieTamis.tamis.length; i++) {
            const eltActu = this.serieTamis.tamis[i];
            if (lignes.indexOf(eltActu) !== -1) {
                ok = false;
            } else {
                lignes.push(eltActu);
            }
        }
        return ok;
    }

    checkValidity() {
        let validity = true;

        const tamisToRemove = [];
        for (var i = 0; i < this.serieTamis.tamis.length; i++) {
            // SL la valeur 0 est considérée comme non valide donc il vaut mieux tester null et undefined
            if (this.serieTamis.tamis[i].ouverture == null || this.serieTamis.tamis[i].ouverture == undefined) {
                tamisToRemove.push(i);
            }
        }
        for (var i = tamisToRemove.length - 1; i >= 0; i--) {
            this.serieTamis.tamis.splice(tamisToRemove[i], 1);
        }

        let firstScroll = true;
        if (!this.serieTamis.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('entete');
                firstScroll = false;
            }

            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.$scope.$broadcast('serieTamisValidations');
        }
        return validity;
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    async createType() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'SERIES_TAMIS.CONFIRM_CREATE_TYPE.TITLE',
            modalMsg: 'SERIES_TAMIS.CONFIRM_CREATE_TYPE.MESSAGE',
            headerClass: 'modal-warning'
        });
        const stateToGo = this.MassiaApplicationService.getApplication() == 'laboratoire' ? 'series-tamis-labo.newType' : 'series-tamis.newType';
        modalInstance.result.then(() => this.$state.go(stateToGo));
    }

    async getTypes() {
        this.startLoading();

        try {
            this.types = await this.SeriesTamisTypesService.getAll();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.serieTamis.codeExists = await this.SeriesTamisService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.serieTamis.codeExists = null;
        }
    }

    onUpdateCaracteristiques(caracteristiques) {
        this.serieTamis.caracteristiques = caracteristiques;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
