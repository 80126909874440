(function () {
    'use strict';

    angular.module('blocks.validation')
        .service('validationHelper', validationHelper);
    validationHelper.$inject = ['moment', 'globalizationManagementService'];

    function validationHelper(moment, globalizationManagementService) {
        /* jshint validthis: true */
        var helper = this;

        helper.isValidDatepicker = isValidDatepicker;
        helper.notEmptyDatepicker = notEmptyDatepicker;
        helper.isValidDecimal = isValidDecimal;
        helper.isValidNegativeDecimal = isValidNegativeDecimal;
        helper.isValidInteger = isValidInteger;
        helper.isValidNegativeInteger = isValidNegativeInteger;
        helper.isValidEmail = isValidEmail;
        helper.numericParser = numericParser;
        helper.numericFormatter = numericFormatter;
        helper.isPostalCode = isPostalCode;
        helper.isPhoneNumber = isPhoneNumber;
        helper.isNotEmptyNullNorUndefined = isNotEmptyNullNorUndefined;
        helper.isValidDescriptionLength = isValidDescriptionLength;
        helper.isValidLabelLength = isValidLabelLength;
        helper.isNotEmptyArray = isNotEmptyArray;

        const dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;

        function isNotEmptyNullNorUndefined(object, value) {
            return value !== undefined && value !== null;
        }

        function isValidDatepicker(object, value) {
            var date, dateValue = value;
            // si la date arrive en tant que string, on la parse selon la locale
            if (typeof dateValue === 'string') {
                dateValue = moment(dateValue, dateFormat)
            }
            date = new Date(dateValue);
            return !isNaN(date);
        }

        function notEmptyDatepicker(object, value) {
            return (value !== undefined) && (value !== '');
        }

        function isValidDecimal(object, value) {
            var reg = new RegExp(/^\d*(,?\.?\d*)?$/);
            if (value && value != '') {
                return reg.test(value.toString().replace(/\s/g, ''));
            }
            else {
                return true;
            }
        }

        function isValidNegativeDecimal(object, value) {
            var reg = new RegExp(/^-?\d*(,?\.?\d*)?$/);
            if (value && value != '') {
                return reg.test(value.toString().replace(/\s/g, ''));
            }
            else {
                return true;
            }
        }

        function isValidInteger(object, value) {
            var reg = new RegExp(/^\d*$/);
            if (value && value != '') {
                return reg.test(value.toString().replace(/\s/g, ''));
            }
            else {
                return true;
            }
        }

        function isValidNegativeInteger(object, value) {
            var reg = new RegExp(/^-?\d*$/);
            if (value && value != '') {
                return reg.test(value.toString().replace(/\s/g, ''));
            }
            else {
                return true;
            }
        }

        function isValidEmail(object, value) {
            var reg = new RegExp(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/);
            if (value && value != '') {
                return reg.test(value);
            }
            else {
                return true;
            }
        }

        function numericParser(value) {
            // on teste si le nombre ne contient bien que des nombres et une seule virgule ou un seul point
            if (value && isValidNegativeDecimal(null, value)) {
                // on transforme l'input en un float ou NaN
                var tempValue = parseFloat(value.toString().replace(/\s/g, '').replace(',', '.'));
                if (!isNaN(tempValue)) {
                    return tempValue;
                }
            }
            return value;
        }

        function numericFormatter(value) {
            if (value) {
                return value.toString().replace('.', ',');
            }
        }

        function isPostalCode(object, property) {
            var reg = new RegExp(/^0[1-9]|[1-8][0-9]|9[0-8]|2A|2B[0-9]{3}$/);

            if (property && property != '') {
                return reg.test(property);
            }
            else {
                return true;
            }
        }

        function isPhoneNumber(object, property) {
            var reg = new RegExp(/^(0)[1-9](\s?\d{2}){4}$/);

            if (property && property != '') {
                return reg.test(property);
            }
            else {
                return true;
            }
        }

        function isValidDescriptionLength(object) {
            if (object.description) {
                return object.description.length <= 511;
            } else {
                return true;
            }
        }

        function isValidLabelLength(object) {
            if (object.label) {
                return object.label.length <= 255;
            } else {
                return true;
            }
        }

        function isNotEmptyArray(object, property) {
            return property && property.length > 0 && property[0] !== null && property[0] !== '' && property[0] !== undefined;
        }
    }

})();
