export default function Configure($stateProvider) {
    $stateProvider.state('taches', {
        parent: 'common',
        url: '/taches',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.GENERAL" | translate }}'
        }
    });

    $stateProvider.state('list', {
        parent: 'taches',
        url: '/list',
        template: '<taches></taches>',
        rights: { domain: 'taches', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "TACHES.BREADCRUMBS.TACHES_LIST" | translate}}'
        },
        navigation: {
            menu: 'qse_general_no_category',
            translate: 'TACHES.BREADCRUMBS.TACHES_LIST',
            order: 2
        },
        search: 'TACHES.BREADCRUMBS.TACHES_LIST'
    });

    $stateProvider.state('detail', {
        parent: 'taches',
        url: '/{id}',
        template: '<tache-detail></tache-detail>',
        rights: { domain: 'taches', right: 'read' },
        ncyBreadcrumb: {
            parent: 'taches',
            label: '{{ "TACHES.BREADCRUMBS.TACHE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('new', {
        parent: 'taches',
        url: '/new',
        template: '<tache-form></tache-form>',
        rights: { domain: 'taches', right: 'create' },
        ncyBreadcrumb: {
            parent: 'taches',
            label: '{{ "TACHES.BREADCRUMBS.TACHE_NEW" | translate}}'
        },
        search: 'TACHES.BREADCRUMBS.TACHE_NEW'
    });

    $stateProvider.state('newTache', {
        parent: 'taches',
        url: '/new/{idOrigine}/{domaine}/{theme}',
        template: '<tache-form></tache-form>',
        rights: { domain: 'taches', right: 'create' },
        ncyBreadcrumb: {
            parent: 'taches',
            label: '{{ "TACHES.BREADCRUMBS.TACHE_NEW" | translate}}'
        }
    });

    $stateProvider.state('edit', {
        parent: 'taches',
        url: '/{id}/edit',
        template: '<tache-form></tache-form>',
        rights: { domain: 'taches', right: 'update' },
        ncyBreadcrumb: {
            parent: 'taches',
            label: '{{ "TACHES.BREADCRUMBS.TACHE_EDIT" | translate }}'
        }
    });

    /*$stateProvider.state('newType', { parent: 'taches',
        url: '/new-type',
        template: '<type-form></type-form>',
        rights: {domain: 'types', right: 'create'},
        params: {
            parentState:'taches.new'
        },
        ncyBreadcrumb: {
            parent:'taches',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });*/
}

Configure.$inject = ['$stateProvider'];
