//Config
import BanquesConfig from './_config/banques.config';
import BanquesRoutes from './_config/banques.routes';

//Components
import BanquesComponent from './components/banques';
import BanqueFormComponent from './components/banque-form';
import BanqueDetailComponent from './components/banque-detail';

import BanqueFormEnteteComponent from './components/banque-form/entete';

//services
import BanquesService from './services/banques.service';
import BanquesCommunicationService from './services/banques.communication.service';

//Validateurs
import BanqueValidator from './components/banque-form/banque.validator';
import BanqueEnteteValidator from './components/banque-form/entete/banque.entete.validator';

const moduleName = 'app.massia.gestion.banques';

angular
    .module(moduleName, [])
    .config(BanquesConfig)
    .config(BanquesRoutes)
    .service('BanquesService', BanquesService)
    .service('BanquesCommunicationService', BanquesCommunicationService)
    .component('banques', BanquesComponent)
    .component('banqueForm', BanqueFormComponent)
    .component('banqueDetail', BanqueDetailComponent)
    .component('banqueFormEntete', BanqueFormEnteteComponent)
    .factory('BanqueValidator', BanqueValidator)
    .factory('BanqueEnteteValidator', BanqueEnteteValidator);

export default moduleName;
