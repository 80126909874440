import ControlesConfig from './_config/controles.config';
import ControlesRoutes from './_config/controles.routes';
import ControlesComponent from './components/controles';
import ControlesService from './services/controles.service';
import ControlesCommunicationService from './services/controles.communication.service';

import ControleFormComponent from './components/controle-form';
import ControleDetailComponent from './components/controle-detail';
import ControleEnteteComponent from './components/controle-entete';
import ControleResultatComponent from './components/controle-resultat';

import ControleValidator from './components/controle-form/controle.validator';
import ControleEnteteValidator from './components/controle-entete/controle.entete.validator';

const moduleName = 'app.parametrageGeneral.controles';

angular
    .module(moduleName, [])
    .config(ControlesConfig)
    .config(ControlesRoutes)
    .service('ControlesService', ControlesService)
    .service('ControlesCommunicationService', ControlesCommunicationService)
    .component('controles', ControlesComponent)
    .component('controleForm', ControleFormComponent)
    .component('controleDetail', ControleDetailComponent)
    .component('controleEntete', ControleEnteteComponent)
    .component('controleResultat', ControleResultatComponent)
    .factory('ControleValidator', ControleValidator)
    .factory('ControleEnteteValidator', ControleEnteteValidator);

export default moduleName;
