import Zone from './zones.model';

export default class ZoneFormController {
    static $inject = ['$scope', '$state', '$stateParams', 'ZonesCommunicationService', 'ZonesService', 'notification', '$location'];

    constructor($scope, $state, $stateParams, ZonesCommunicationService, ZonesService, notification, $location) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ZonesCommunicationService = ZonesCommunicationService;
        this.ZonesService = ZonesService;
        this.notification = notification;
        this.$location = $location;
    }

    async $onInit() {
        this.isEditMode = false;
        this.zone = {};
        this.loading = false;
        this.unregisterReinit = this.ZonesCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    initForm() {
        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
            this.setZone(this.$stateParams.id);
        } else {
            this.isEditMode = false;
        }
    }

    async setZone(zoneId) {
        if (zoneId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                const stillThatZone = await this.ZonesService.getZoneById(zoneId);
                this.zone = new Zone(stillThatZone);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('zones.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.zone.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.zone.codeExists = await this.ZonesService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    // boutons de validation
    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                if (this.zone.id) {
                    const tmp = await this.updateZone();
                    this.notification.success('ZONES.UPDATED');
                    return this.zone.id;
                }
                const tmp = await this.createZone();
                this.notification.success('ZONES.CREATED');
                return tmp;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        } else {
            return false;
        }
    }

    async valider() {
        const returnedId = await this.sauvegarder();
        if (returnedId) {
            this.$state.go('zones.edit', { id: returnedId });
        }
    }
    async validerEtFermer() {
        const returnedId = this.sauvegarder();
        this.backToPreviousState();
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('zones.list');
    }

    async createZone() {
        this.startLoading();
        try {
            const returnedId = await this.ZonesService.createZone(this.zone);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateZone() {
        this.startLoading();
        try {
            const returnedId = await this.ZonesService.updateZone(this.zone);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    reinit() {
        this.setZone(this.$stateParams.id);
        this.ZonesCommunicationService.raiseReinit();
    }

    checkValidity() {
        this.$scope.$broadcast('zoneValidations');
        this.zone = new Zone(this.zone);
        return this.zone.isValid();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
