// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
import { FilterType } from '../../../../../core/interface/FilterProperty';

/* @ngInject */
export default function ProduitsController(
    $scope,
    $state,
    $stateParams,
    $translate,
    MassiaApplicationService,
    ModalService,
    PaginationService,
    ProduitsService,
    notification,
    DataShareService,
    FamillesService,
    SitesService,
    TypesService,
    $uibModal,
    $transitions,
    ProductService,
    FilterService,
    MOPService,
    ParametreProgrammeService,
    MassiaAuthService,
    DonneeFiltreeUtilisateursService
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    let selectedProduitId = undefined;
    vm.currentTableState = null;
    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.MassiaApplicationService = MassiaApplicationService;
    vm.DataShareService = DataShareService;
    vm.produits = [];
    vm.familles = [];
    vm.types = [];
    vm.siteClients = [];
    vm.savedPagination = {};
    vm.savedPagination.entity = 'pagination';
    vm.$uibModal = $uibModal;
    vm.FilterService = FilterService;
    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.selectProduit = selectProduit;
    vm.isProduitSelected = isProduitSelected;
    vm.showDetail = showDetail;
    vm.edit = edit;
    vm.closeDetail = closeDetail;
    vm.loadProduits = loadProduits;
    vm.deleteProduit = deleteProduit;
    vm.setSelected = setSelected;
    vm.exporter = exporter;
    vm.isFiltered = false;
    vm.duplicateProduit = duplicateProduit;

    vm.isSearchOngletOpen = true;
    vm.manageFamilleSelect = manageFamilleSelect;
    vm.manageTypeSelect = manageTypeSelect;
    vm.manageSiteProdSelect = manageSiteProdSelect;
    vm.manageSiteLieSelect = manageSiteLieSelect;
    vm.initMultiSelects = initMultiSelects;
    vm.reinitMultiSelectProducteur = reinitMultiSelectProducteur;
    vm.MOPService = MOPService;
    vm.ParametreProgrammeService = ParametreProgrammeService;
    vm.MassiaAuthService = MassiaAuthService;
    vm.DonneeFiltreeUtilisateursService = DonneeFiltreeUtilisateursService;

    vm.$onInit = async () => {
        try {
            vm.searchingFormProduits = {
                entity: 'produit',
                laboratoireIdSelected: null,
                familleIdSelected: null,
                familleCodeSelected: null,
                siteProdIdSelected: null,
                siteLieIdSelected: null,
                typeIdSelected: null
            };
            vm.application = vm.MassiaApplicationService.getApplication();
            //écouter l'event lié au changement de route
            //une fois on change l'url, et on arrive sur la page "produits.list", on désélectionne la ligne sélectionnée (s'il y en a)
            $transitions.onSuccess({}, stateChangeSuccessFunc);

            //quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
            if (shouldSetSelectedId()) {
                vm.setSelected({ id: parseInt($state.params.id) });
            }
            // s'il y a quelque chose dans le DataShare
            // vm.DataShareService.savedData
            if (_.isEmpty(vm.DataShareService.savedData) === false) {
                // on cherche l'entité saisie
                const item = _.find(vm.DataShareService.savedData, { entity: 'produit' });

                if (item !== null) {
                    updateSearchForm();
                }
            }

            await getFamilles();
            await getTypes();
            await getSitesProducteurs();
            await getSitesLies();
            initMultiSelects();
            vm.showCode2 = false;
            getLicence();
        } catch (err) {
            console.warn(err);
        }

        if (vm.application === 'laboratoire') {
            vm.MOPService.setMop([{ title: 'PRODUITS.BREADCRUMBS.PRODUITS_LIST', filename: 'Produits.pdf', application: 'laboratoire' }]);
        }

        if (vm.application === 'gestion') {
            vm.MOPService.setMop([{ title: 'Gestion Big-bag', filename: 'GestionBig-Bags.pdf', application: 'gestion' }]);
        }
    };

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    // recuperation des donnees du DataShare
    function updateSearchForm() {
        vm.searchingFormProduits = {
            ...vm.searchingFormProduits,
            ...vm.DataShareService.getDataShare('produit')
        };
    }

    function getLicence() {
        //MN: pour "Eqiom", on affiche les CODE2(SAP)
        if (__configuration.licenceLaboratoire === '2') {
            vm.showCode2 = true;
        }
    }

    async function duplicateProduit(produit) {
        vm.DataShareService.setDataShare(vm.searchingFormProduits, 'produit'); // updateDataShare
        if (produit && produit.id) {
            const p = await ProductService.getOne(produit.id);
            /******************************************* Specifique EQIOM MixDesign *****************************/
            if (__configuration.licenceLaboratoire === '2' && produit.type.id) {
                //const type = await TypesService.getTypeById(produit.type.id);
                if (produit.type.code === 'TEWIL100') {
                    if (p && p.type && p.type.typeProduit && p.type.typeProduit.codeNormalisable) {
                        $state.go('produits.duplicateNorme', { id: produit.id, produit: p });
                    }
                    //openModalMd(produit);
                } else {
                    if (p && p.type && p.type.typeProduit && p.type.typeProduit.codeNormalisable) {
                        $state.go('produits.duplicateNorme', { id: produit.id });
                    } else {
                        $state.go('produits.duplicate', { id: produit.id });
                    }
                }
                /****************************************************************************************************/
            } else {
                if (p && p.type && p.type.typeProduit && p.type.typeProduit.codeNormalisable) {
                    $state.go('produits.duplicateNorme', { id: produit.id });
                } else {
                    $state.go('produits.duplicate', { id: produit.id });
                }
            }
        }
    }

    this.onSelectedFilter = ($filters) => {
        vm.filters = $filters;
        loadProduits(vm.currentTableState);
    };

    function stateChangeSuccessFunc(event, toState) {
        if (!$state.params.id) {
            vm.setSelected();
        } else if ($state.params.id) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    function setSelected(produit) {
        if (produit && produit.id) {
            selectedProduitId = $stateParams.id;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            selectedProduitId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return $state && $state.current && $state.current.name && $state.current.name === 'produits.list.detail' && $stateParams && $stateParams.id;
    }

    function selectProduit(produit) {
        if (produit && produit.id) {
            //si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedProduitId, et charger la vue détail pour le produit sélectionné
            if (vm.selectedProduitId !== produit.id) {
                vm.selectedProduitId = produit.id;
                showDetail(produit.id);
            } else {
                //si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
                vm.selectedProduitId = undefined;
                closeDetail();
            }
        }
    }

    function isProduitSelected(produit) {
        return produit && produit.id && vm.selectedProduitId === produit.id;
    }

    function showDetail(produitId) {
        vm.DataShareService.setDataShare(vm.searchingFormProduits, 'produit'); // updateDataShare
        $state.go('produits.list.detail', { id: produitId });
    }

    function edit(produit) {
        //ui-sref="produits.edit({id: produit.id})"
        vm.DataShareService.setDataShare(vm.searchingFormProduits, 'produit'); // updateDataShare
        $state.go('produits.edit', { id: produit.id });
    }

    function closeDetail() {
        $state.go('produits.list');
    }

    async function loadProduits(tableState) {
        startLoading();
        // gestion de la sauvegarde et de la restitution de la pagination
        const objectToManage = {};
        objectToManage.tableState = tableState;
        objectToManage.savedPagination = vm.savedPagination;
        objectToManage.itemsByPageOptions = vm.itemsByPageOptions;
        objectToManage.itemsByPage = vm.itemsByPage;
        const resultObject = await vm.DataShareService.managePaginationContextuelle(objectToManage);
        if (resultObject) {
            tableState = resultObject.tableState;
            vm.savedPagination = resultObject.savedPagination;
            vm.itemsByPageOptions = resultObject.itemsByPageOptions;
            vm.itemsByPage = resultObject.itemsByPage;
        }

        //quand on rafraîchit la grille, on revient sur "produits.list", et on ferme la vue détail d'un produit
        //sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
        if ($state && $state.current && $state.current.name !== 'produits.list') {
            $state.go('produits.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        let filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);
        vm.produits = [];
        vm.saveFilters = filters;

        try {
            filters = getFilter(filters);
            const application = vm.MassiaApplicationService.getApplication();
            if (vm.MassiaApplicationService.getApplication() === 'laboratoire') {
                if (vm.searchingFormProduits.familleIdSelected && vm.searchingFormProduits.familleIdSelected > 0) {
                    const data = await vm.FilterService.applyFilter('produits', {
                        application,
                        isFiltered: false,
                        filters,
                        sorts,
                        pagination,
                        filterDtos: vm.filters
                    });
                    vm.produits = data.items;
                    if (previousTableState) {
                        PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
                    }
                }
            } else {
                const data = await ProductService.getAll(filters, sorts, pagination, null, {
                    application: vm.application,
                    customFilters: JSON.stringify(vm.filters)
                });
                vm.produits = data.items;
                if (previousTableState) {
                    PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
                }
            }
            vm.currentTableState = previousTableState || tableState;
        } catch (ex) {
            console.error(ex);
            //notification.error($translate.instant(ex.data));
        } finally {
            stopLoading();
        }
    }

    function getFilter(filters) {
        if (!vm.searchingFormProduits) {
            return filters;
        }
        if (vm.searchingFormProduits.familleIdSelected) {
            filters = [
                ...filters,
                {
                    criterion: 'type.famille.id',
                    type: FilterType.EQUALS,
                    value: vm.searchingFormProduits.familleIdSelected
                }
            ];
        }
        if (vm.searchingFormProduits.typeIdSelected) {
            filters = [
                ...filters,
                {
                    criterion: 'type.id',
                    type: FilterType.EQUALS,
                    value: vm.searchingFormProduits.typeIdSelected
                }
            ];
        }
        if (vm.searchingFormProduits.siteProdIdSelected && vm.searchingFormProduits.siteProdIdSelected.length > 0) {
            filters = [
                ...filters,
                {
                    criterion: 'siteProduct.site.id',
                    type: FilterType.IS_IN,
                    value: vm.searchingFormProduits.siteProdIdSelected
                }
            ];
        }
        if (vm.searchingFormProduits.siteLieIdSelected && vm.searchingFormProduits.siteLieIdSelected.length > 0) {
            filters = [
                ...filters,
                {
                    criterion: 'siteClientLab.siteClient.id',
                    type: FilterType.IS_IN,
                    value: vm.searchingFormProduits.siteLieIdSelected
                }
            ];
        }
        return filters;
    }

    async function deleteProduit(produit) {
        if (produit && produit.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('PRODUITS.DELETE.TITLE', { code: produit.libelle }),
                modalMsg: $translate.instant('PRODUITS.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await ProductService.delete(produit.id);
                    notification.success($translate.instant('PRODUITS.DELETE.SUCCESS'));

                    selectedProduitId = undefined;

                    // si jamais la vue détail est affichée,
                    // on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
                    if (vm.params.id) {
                        vm.state.go('produits.list');
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadProduits();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    async function exporter(modeImpression) {
        try {
            const filters = PaginationService.getFilters(previousTableState);
            const sorts = PaginationService.getSorts(previousTableState);
            const fileName = 'Liste_produits';
            let resultat = undefined;

            try {
                resultat = await ProduitsService.exportListeProduits(
                    modeImpression,
                    filters,
                    sorts,
                    vm.isFiltered,
                    vm.MassiaApplicationService.getApplication(),
                    vm.searchingFormProduits.familleIdSelected,
                    vm.searchingFormProduits.typeIdSelected,
                    vm.searchingFormProduits.siteProdIdSelected,
                    vm.searchingFormProduits.siteLieIdSelected
                );
            } catch (ex) {
                //si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
                const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
                notification.error('PRODUITS.' + msgException);
                return false;
            }
            if (resultat) {
                const data = resultat.data;
                let headers = resultat.headers;
                headers = headers();

                const contentType = headers['content-type'];

                const linkElement = document.createElement('a');
                try {
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    if (modeImpression == 0) {
                        linkElement.setAttribute('download', fileName + '.pdf');
                    } else {
                        linkElement.setAttribute('download', fileName + '.xlsx');
                    }

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                } catch (ex) {}
                return true;
            }
        } catch (ex) {
            notification.error(ex.data);
            return false;
        }
    }

    async function getTypes() {
        if (vm.searchingFormProduits.familleCodeSelected) {
            vm.types = await TypesService.getType('produits', vm.searchingFormProduits.familleCodeSelected);
        }
        if (vm.types && vm.types.length === 1) {
            vm.searchingFormProduits.typeIdSelected = vm.types[0].id;
            const selectedType = vm.types.find((x) => x.id === vm.searchingFormProduits.typeIdSelected);
            if (selectedType) {
                selectedType.selected = true;
            }
        } else {
            vm.searchingFormProduits.typeIdSelected = vm.searchingFormProduits.typeIdSelected || null;
        }
    }

    async function getSitesProducteurs() {
        if (vm.searchingFormProduits.familleCodeSelected) {
            vm.sitesProd = await SitesService.getSitesProducteursByFamille(
                MassiaApplicationService.application,
                vm.searchingFormProduits.familleCodeSelected
            );
        }
        //enlever les producteurs qui ne sont plus présents de la liste des sélectionnés
        if (vm.searchingFormProduits.siteProdIdSelected) {
            vm.searchingFormProduits.siteProdIdSelected = vm.searchingFormProduits.siteProdIdSelected.filter((item) => {
                return vm.sitesProd.find((x) => x.id === item);
            });
        }

        if (vm.sitesProd && vm.sitesProd.length === 1) {
            vm.searchingFormProduits.siteProdIdSelected = [];
            vm.searchingFormProduits.siteProdIdSelected.push(vm.sitesProd[0].id);
            vm.sitesProd[0].selected = true;
        } else if (vm.sitesProd) {
            if (!vm.searchingFormProduits.siteProdIdSelected) {
                vm.searchingFormProduits.siteProdIdSelected = [];
            }

            vm.sitesProd = vm.sitesProd.map((x) => {
                if (vm.searchingFormProduits.siteProdIdSelected.includes(x.id)) {
                    x.selected = true;
                }
                return x;
            });
        }

        reinitMultiSelectProducteur();
    }

    async function getFamilles() {
        let res = [];
        if (vm.MassiaApplicationService.getApplication() === 'laboratoire') {
            res = await FamillesService.getFamillesFiltrees();
        } else {
            res = await FamillesService.getFamilles();
        }
        vm.familles = res.map((e) => {
            return {
                id: e.idFamille,
                label: e.nom,
                code: e.code
            };
        });
        if (vm.familles.length === 1) {
            vm.searchingFormProduits.familleIdSelected = vm.familles[0].id;
            vm.searchingFormProduits.familleCodeSelected = vm.familles[0].code;
        }
        await getDefaultselectedfamily(vm.familles);
    }

    function reinitMultiSelectProducteur() {
        vm.sitesProdsSelected = [];
        if (vm.searchingFormProduits.siteProdIdSelected && vm.sitesProd) {
            for (let i = 0; i < vm.searchingFormProduits.siteProdIdSelected.length; i++) {
                const selectedSiteProd = vm.sitesProd.find((x) => x.id === vm.searchingFormProduits.siteProdIdSelected[i]);
                if (selectedSiteProd) {
                    selectedSiteProd.selected = true;
                    vm.sitesProdsSelected.push(selectedSiteProd);
                }
            }
        }
    }

    async function initMultiSelects() {
        // getFamilleSelected
        if (vm.searchingFormProduits.familleIdSelected) {
            const selectedFamille = vm.familles.find((x) => x.id === vm.searchingFormProduits.familleIdSelected);
            if (selectedFamille) {
                selectedFamille.selected = true;
            }
        }

        // getTypeSelected
        if (vm.searchingFormProduits.typeIdSelected) {
            const selectedType = vm.types.find((x) => x.id === vm.searchingFormProduits.typeIdSelected);
            if (selectedType) {
                selectedType.selected = true;
            }
        }

        reinitMultiSelectProducteur();

        if (vm.searchingFormProduits.siteLieIdSelected && vm.siteClients) {
            for (let i = 0; i < vm.searchingFormProduits.siteLieIdSelected.length; i++) {
                const selectedSiteLie = vm.siteClients.find((x) => x.id === vm.searchingFormProduits.siteLieIdSelected[i]);
                if (selectedSiteLie) {
                    selectedSiteLie.selected = true;
                    vm.sitesLiesSelected.push(selectedSiteLie);
                }
            }
        }
    }

    async function manageFamilleSelect() {
        try {
            vm.searchingFormProduits.familleIdSelected = vm.famillesLoadedSelected[0]?.id;
            vm.searchingFormProduits.familleCodeSelected = vm.famillesLoadedSelected[0]?.code;
            vm.searchingFormProduits.typeIdSelected = null;
            vm.filters = [];
            await changeFamille();
        } catch (error) {
            console.error('manageFamilleSelect', error);
        }
    }

    async function manageTypeSelect() {
        vm.searchingFormProduits.typeIdSelected = vm.typeSelected[0]?.id;
        vm.filters = [];
    }

    async function manageSiteProdSelect() {
        if (vm.sitesProdsSelected && vm.sitesProdsSelected.length > 0) {
            vm.searchingFormProduits.siteProdIdSelected = [];
            for (let i = 0; i < vm.sitesProdsSelected.length; i++) {
                vm.searchingFormProduits.siteProdIdSelected.push(vm.sitesProdsSelected[i].id);
            }
        } else {
            vm.searchingFormProduits.siteProdIdSelected = null;
        }
    }

    async function manageSiteLieSelect() {
        if (vm.sitesLiesSelected && vm.sitesLiesSelected.length > 0) {
            vm.searchingFormProduits.siteLieIdSelected = [];
            for (let i = 0; i < vm.sitesLiesSelected.length; i++) {
                vm.searchingFormProduits.siteLieIdSelected.push(vm.sitesLiesSelected[i].id);
            }
        } else {
            vm.searchingFormProduits.siteLieIdSelected = null;
        }
    }

    async function changeFamille() {
        try {
            if (vm.searchingFormProduits.familleCodeSelected) {
                await getTypes();
                await getSitesProducteurs();
            }
        } catch (ex) {
            notification.error(ex.data);
        }
    }

    async function getSitesLies() {
        let res = [];
        if (vm.MassiaApplicationService.getApplication() === 'laboratoire') {
            const filter = [
                {
                    criterion: 'genre',
                    value: '4',
                    type: 'Eq'
                }
            ];
            res = (await SitesService.getSitesClients(filter, null, null, null))?.items;
        } else {
            res = await SitesService.getAllSitesClients();
        }
        vm.siteClients = res;
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    async function getDefaultselectedfamily(familyList) {
        if (familyList) {
            const currentUser = vm.MassiaAuthService.getAuthenticatedUser();
            const userParameters = await vm.DonneeFiltreeUtilisateursService.getDonneeFiltreeUtilisateur(
                currentUser.id,
                vm.MassiaApplicationService.getApplication()
            );
            const familySelectedInUserParam = familyList.find((family) => family.id === userParameters.idDefaultFamily);

            if (familySelectedInUserParam) {
                familySelectedInUserParam.selected = true;
                vm.searchingFormProduits.familleCodeSelected = familySelectedInUserParam.code;
                vm.searchingFormProduits.familleIdSelected = familySelectedInUserParam.id;
                loadProduits();
            } else {
                const CodeFamilyInGeneralParam = await vm.ParametreProgrammeService.getOneByCode('FamPdtDefaut');

                if (CodeFamilyInGeneralParam) {
                    const familySelectedInGeneralParam = familyList.find((family) => family.code === CodeFamilyInGeneralParam);
                    familySelectedInGeneralParam.selected = true;
                    vm.searchingFormProduits.familleCodeSelected = familySelectedInGeneralParam.code;
                    vm.searchingFormProduits.familleIdSelected = familySelectedInGeneralParam.id;
                    loadProduits();
                }
            }
        }
    }
}
