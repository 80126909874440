import EntityCaracteristiques from './entity.caracteristiques.model';

export default class EntityCaracteristiquesController {
    /* @ngInject */
    constructor(
        $state,
        _,
        CaracteristiquesTypesService,
        notification,
        $timeout,
        $scope,
        RouterHistoryService,
        DocumentsService,
        moment,
        globalizationManagementService,
        Interpreteur
    ) {
        this.$state = $state;
        this._ = _;
        this.CaracteristiquesTypesService = CaracteristiquesTypesService;
        this.notification = notification;
        this.$timeout = $timeout;
        this.$scope = $scope;
        this.RouterHistoryService = RouterHistoryService;
        this.DocumentsService = DocumentsService;

        this.moment = moment;
        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
        this.Interpreteur = Interpreteur;
    }

    async $onInit() {
        this.loading = false;
        this.typeFormat = this.typeFormat || [];
        this.model = this.model || {};
        this.niveauVisibilite = 0;
        this.lecture = this.lecture || false;
        this.populate = this.populate || false;

        if (!this.model.id) {
            if (this.model.entete.id)
                this.model.id = this.model.entete.id;
        }

        this.ongletOpen = {
            isCaracteristiqueOpen: false
        };

        await this.initCaracteristiques(this.forceRefresh === undefined ? true : this.forceRefresh);

        if (this.model.niveauVisibilite || this.model.niveauVisibilite === 0) {
            this.$scope.$watch('$ctrl.model.niveauVisibilite', () => {
                this.niveauVisibilite = this.model.niveauVisibilite || 0;
                this.refreshCaracteristiques();
            });
        } else if (this.model && this.model.entete && (this.model.entete.niveauVisibilite || this.model.entete.niveauVisibilite === 0)) {
            this.$scope.$watch('$ctrl.model.entete.niveauVisibilite', () => {
                this.niveauVisibilite = this.model.entete.niveauVisibilite || 0;
                this.refreshCaracteristiques();
            });
        }

        this.Interpreteur.init(this.model, null);
        this.Interpreteur.formatCaract = this.typeFormat;
        this.initConditions = false;
    }

    async initCaracteristiques(forceRefresh = false) {
        this.idType =
            this.idType || (this.model.type && this.model.type.id) || (this.model.entete && (this.model.entete.typeId || this.model.entete.idType));
        if (
            !this.model.caracteristiques ||
            Object.keys(this.model.caracteristiques)?.length <= 0 ||
            //this.model.caracteristiques.constructor !== Array ||
            forceRefresh
        ) {
            // si c'est des carateristiques de'un NEW saisie (avant de valider), on récupère les données que user a déjà tappé mais pas encoré save
            if (!this.model.id) {
                this.model.caracteristiques = angular.copy(this.model.caracteristiques);
            } else {
                const caracts = await this.CaracteristiquesTypesService.getEntiteValeursCaracteristiquesAssociationsById(
                    this.model.id || (this.model.entete && this.model.entete.id) || 0,
                    this.idType || 0,
                    this.entity
                );
                if (caracts) {
                    if (!this.model.caracteristiques) {
                        this.model.caracteristiques = { data: {} };
                    }
                    for (const k in caracts) {
                        if (k !== 'undefined' && !this.model.caracteristiques[k]) {
                            this.model.caracteristiques[k] = caracts[k];

                            if (!this.model.caracteristiques.data[k]) {
                                this.model.caracteristiques.data[k] = { id: k, value: '' };
                            }
                            this.model.caracteristiques.data[k].value = caracts[k];
                            if (this.model.traiteDuplication && this.model.caracteristiqueDtos) {
                                const caract = this.model.caracteristiqueDtos.find((x) => x.id === Number.parseFloat(k));
                                if (caract && !caract.isDuplicable) {
                                    this.model.caracteristiques.data[k].value = undefined;
                                    this.model.caracteristiques[k] = undefined;
                                }
                            }
                        }
                    }
                    if (this.model.traiteDuplication) {
                        delete this.model.traiteDuplication;
                    }
                }
            }
        }

        this.fullCaracteristiques = this.model.caracteristiques;
        this.caracteristiques = new EntityCaracteristiques(this.model.caracteristiques);
        this.model.caracteristiques = new EntityCaracteristiques(this.model.caracteristiques);
    }

    $onDestroy() {
        this.$timeout.cancel(this.typeFormatTimeout);
    }

    async $onChanges(objChanges) {
        if (objChanges.idType) {
            this.initConditions = false;
            if (!objChanges.idType.isFirstChange()) {
                this.caracteristiques = new EntityCaracteristiques();
            }

            await this.getTypeFormat(objChanges.idType.currentValue);
        }
    }

    // cette fonction est appelée automatiquement
    // a chaque digest (dirty check) d'angular
    async $doCheck() {
        //recalcul des conditions d'affichage dès qu'on a les infos pour le faire
        if (!this.initConditions && this.typeFormat && this.typeFormat.length > 0) {
            await this.recalculeConditions();
            this.initConditions = true;
        }
        //SP 07/12/19 pourquoi commenté ?
        if (!angular.equals(this.caracteristiques, this.model.caracteristiques) && this.onUpdate) {
            this.onUpdate({
                caracteristiques: angular.copy(this.caracteristiques)
            });
        }
    }

    async getTypeFormat(idType) {
        if (idType) {
            // on démarre l'animation de loading avant le chargement des données
            this.startLoading();

            // on encapsule l'appel serveur dans un try...catch pour récupérer les erreurs
            try {
                // on récupère les données depuis le service qui interroge le serveur
                const typeFormat = await this.CaracteristiquesTypesService.getCaracteristiques(idType);

                // on trie le tableau selon la propriété 'position'
                // on publie le tableau dans le this pour l'afficher dans la vue
                this.typeFormat = this._.sortBy(typeFormat, 'position');
                await this.refreshCaracteristiques();
            } catch (ex) {
                console.error(ex);
                // en cas de problème on notifie l'utilisateur
                this.notification.error(ex.data);
            } finally {
                // On supprimer le timeout précédent (fuite mémoire)
                this.$timeout.cancel(this.typeFormatTimeout);

                // une fois l'appel terminé, on stoppe l'animation de loading
                this.typeFormatTimeout = this.$timeout(() => {
                    this.stopLoading();
                });
            }
        }
    }

    async refreshCaracteristiques() {
        this.filteredFormat = null;
        this.Interpreteur.formatCaract = this.typeFormat;
        this.filteredFormat = this.typeFormat.filter((e) => {
            if (this.model.id) {
                e.default = '';
            }
            return e.niveauVisibilite <= this.niveauVisibilite;
        });
        const typeFormatNumberLine = Math.ceil(this.filteredFormat.length / 3);
        this.typeFormatMod3 = [];
        for (let i = 0; i < typeFormatNumberLine; i++) {
            this.typeFormatMod3.push(i * 3);
        }

        // if (!this.model.caracteristiques || (this.model.caracteristiques.data && Object.keys(this.model.caracteristiques.data).length === 0))
        //     this.model.caracteristiques = angular.copy(this.caracteristiques);

        //Fix pour récuperer les niveau de visibilité sur les caractéristique des prélèvements
        for (let i = 0; i < this.typeFormat.length; i++) {
            const car = this.typeFormat[i];
            if (this.model.caracteristiques && this.model.caracteristiques.data && this.model.caracteristiques.data[`${car.id}`]) {
                this.model.caracteristiques.data[`${car.id}`].caracteristique = car;
            }
        }
    }

    addValCaracteristique(format = null) {
        if (this.model && this.model.outsourceModel) {
            this.model.outsourceModel(this.moment, this.dateFormat);
            this.RouterHistoryService.data = this.model;
        }

        if (format) {
            return this.$state.go('caracteristiques.edit', { id: format.id });
        }
        return this.$state.go('caracteristiques.new');
    }

    async saveCaracteristiques() {
        await this.CaracteristiquesTypesService.setEntiteValeursCaracteristiquesById(
            this.model.id,
            this.idType,
            this.entity,
            this.model.caracteristiques
        );
    }

    async exitMesure(idCaract) {
        let updateCaract = false;
        console.log(this.model);
        const nouvValeur = this.model.caracteristiques[idCaract];
        if (!this.model.caracteristiques.data[idCaract]) {
            this.model.caracteristiques.data[idCaract] = { id: idCaract, value: '' };
            updateCaract = true;
        } else if (this.model.caracteristiques.data[idCaract] != nouvValeur) {
            updateCaract = true;
        }

        this.model.caracteristiques.data[idCaract].value = this.model.caracteristiques[idCaract];

        if (!this.ignoreDoCheck && updateCaract) {
            this.ignoreDoCheck = true;

            await this.recalculeFormules();
            this.onUpdate({
                caracteristiques: angular.copy(this.model.caracteristiques)
            });
            await this.recalculeConditions();
            this.ignoreDoCheck = false;
        }
    }

    async recalculeFormules() {
        let recharge = false;
        if (this.Interpreteur) {
            this.Interpreteur.calculatedFormules = [];
            this.Interpreteur.obj = this.model;
            // this.Interpreteur.obj.caracteristiques = this.Interpreteur.obj.caracteristiques; //! a voir
            this.Interpreteur.formatCaract = this.typeFormat;
        }
        let idx1 = 0;
        while (idx1 < this.typeFormat.length) {
            const format = this.typeFormat[idx1];
            if (format.formula) {
                try {
                    const oldVal = this.caracteristiques[idx1];
                    this.Interpreteur.essaiAppel = undefined;
                    this.Interpreteur.forceIter = undefined;
                    let newVal = await this.Interpreteur.calculeFormule(format.formula);
                    if (format.nature == 'Numerique' && newVal !== '') {
                        let arrondi = format.numericAdditionalInformation.nombreDeDecimales;
                        const grandeurMinimum = format.numericAdditionalInformation.grandeurMinimum;
                        const grandeurMaximum = format.numericAdditionalInformation.grandeurMaximum;
                        const x = this.Interpreteur.strEnDouble(newVal);
                        if (!isNaN(x)) {
                            //gestion des min/max
                            if (grandeurMinimum != undefined && grandeurMinimum != null && grandeurMinimum > newVal) {
                                newVal = grandeurMinimum;
                            }
                            if (grandeurMaximum != undefined && grandeurMaximum != null && grandeurMaximum < newVal) {
                                newVal = grandeurMaximum;
                            }
                            //gestion de l'arrondi
                            if (arrondi == 0) {
                                newVal = Math.round(x);
                            } else {
                                if (!arrondi) {
                                    arrondi = 2;
                                }
                                newVal = x.toFixed(arrondi);
                            }
                        } else {
                            newVal = null;
                        }
                    }
                    if (oldVal !== newVal) {
                        this.model.caracteristiques[format.id] = newVal;
                        if (this.model.caracteristiques.data) {
                            this.model.caracteristiques.data[format.id].value = newVal;
                        }
                        recharge = true;
                    }
                } catch (ex) {}
            }
            idx1++;
        }
        if (recharge) {
            this.typeFormat = angular.copy(this.typeFormat);
        }
    }

    async recalculeConditions() {
        if (this.typeFormat) {
            let rechargeCond = false;
            if (this.Interpreteur) {
                this.Interpreteur.calculatedFormules = [];
                this.Interpreteur.obj = this.site;
                this.Interpreteur.formatCaract = this.typeFormat;
            }
            let idx1 = 0;
            while (idx1 < this.typeFormat.length) {
                const format = this.typeFormat[idx1];
                if (format.condition) {
                    try {
                        const oldCond = angular.copy(format.hide);
                        this.Interpreteur.essaiAppel = undefined;
                        this.Interpreteur.forceIter = undefined;
                        const newCond = await this.Interpreteur.calculeFormule(format.condition);

                        if (oldCond !== (newCond !== true && newCond !== 'true')) {
                            format.hide = newCond !== true && newCond !== 'true';
                            rechargeCond = true;
                        }
                    } catch (ex) {
                        console.error(ex);
                    }
                }
                idx1++;
            }
            if (rechargeCond) {
                this.typeFormat = angular.copy(this.typeFormat);
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
