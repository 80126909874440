import MotifsAjustementStockConfig from './_config/motifs.ajustement.stock.config';
import MotifsAjustementStockRoutes from './_config/motifs.ajustement.stock.routes';
import MotifsAjustementStockComponent from './components/motifs-ajustement-stock';
import MotifsAjustementStockService from './services/motifs.ajustement.stock.service';
import MotifsAjustementStockCommunicationService from './services/motifs.ajustement.stock.communication.service';
import MotifAjustementStockFormComponent from './components/motif-ajustement-stock-form';
import MotifAjustementStockValidator from './components/motif-ajustement-stock-form/motif.ajustement.stock.validator';

const moduleName = 'app.massia.common.motifs.ajustement.stock';

angular
    .module(moduleName, [])
    .config(MotifsAjustementStockConfig)
    .config(MotifsAjustementStockRoutes)
    .service('MotifsAjustementStockService', MotifsAjustementStockService)
    .service('MotifsAjustementStockCommunicationService', MotifsAjustementStockCommunicationService)
    .component('motifsAjustementStock', MotifsAjustementStockComponent)
    .component('motifAjustementStockForm', MotifAjustementStockFormComponent)
    .factory('MotifAjustementStockValidator', MotifAjustementStockValidator);

export default moduleName;
