const serviceUrl = `${__configuration.apiUrl}/massia/clients-divers`;

export default class ClientsDiversService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getClientsDivers(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async deleteClientDiversById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getClientDiversEnteteById(id) {
        const url = `${serviceUrl}/${id}/entete`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createClientDivers(entete) {
        const result = await this.$http.post(serviceUrl, entete);
        return result.data;
    }

    async updateEnteteClientDivers(entete) {
        const url = `${serviceUrl}/${entete.id}`;
        return await this.$http.put(url, entete);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getSocietes() {
        const uniteUrl = `${__configuration.apiUrl}/massia/societes-commerciales/header`;
        const res = await this.$http.get(uniteUrl);

        return res.data;
    }

    async getPays() {
        const uniteUrl = `${__configuration.apiUrl}/massia/pays`;
        const res = await this.$http.get(uniteUrl);

        return res.data;
    }

    async exportListeClients(modeImpr, filters, sorts) {
        const url = `${serviceUrl}/print`;
        const res = await this.$http.get(url, {
            params: {
                modeImpr: JSON.stringify(modeImpr),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || [])
            },
            responseType: 'arraybuffer'
        });
        return res;
    }

    async getNomSitesClients(val, idSociete) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-clients/typeAheadCompteOuvert/${idSociete}/${val}`);
        return res.data;
    }

    async rapprocherClient(idClient, idclientDivers) {
        const url = `${serviceUrl}/rapprocher/${idClient}/${idclientDivers}`;
        return await this.$http.put(url);
    }
}
