import validatorBuilder from 'validator-builder';

let gammeProduitValidator = null;

export default class GammeProduit {
    constructor(data = {}) {
        this.id = data.id || 0;
        this.code = data.code || null;
        this.libelle = data.libelle || null;
        this.idFamille = data.idFamille || null;
        this.familleLibelle = data.familleLibelle || null;
    }

    isValid() {
        gammeProduitValidator = gammeProduitValidator || validatorBuilder.getInstanceFor('GammeProduit');
        const validationResults = gammeProduitValidator.validate(this);
        return validationResults.isValid;
    }
}
