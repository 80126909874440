import TicketImportController from './ticket.import.controller';

export default {
    bindings: {
        modalInstance: '=',
        rapport: '='
    },
    template: require('./ticket.import.html'),
    controller: TicketImportController
};
