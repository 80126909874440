export default function Configure($stateProvider) {
    $stateProvider.state('jours-feries', {
        parent: 'common',
        url: '/jours-feries',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('jours-feries.list', {
        url: '/list',
        template: '<jours-feries></jours-feries>',
        rights: { domain: 'jferies', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "JOURS_FERIES.BREADCRUMBS.JOURS_FERIES_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'JOURS_FERIES.BREADCRUMBS.JOURS_FERIES_LIST',
            order: 70
        },
        search: 'JOURS_FERIES.BREADCRUMBS.JOURS_FERIES_LIST'
    });

    $stateProvider.state('jours-feries.list.detail', {
        url: '/{id}',
        template: '<jour-ferie-detail></jour-ferie-detail>',
        rights: { domain: 'jferies', right: 'read' },
        ncyBreadcrumb: {
            parent: 'jours-feries.list',
            label: '{{ "JOURS_FERIES.BREADCRUMBS.JOURS_FERIES_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('jours-feries.new', {
        url: '/new',
        template: '<jour-ferie-form></jour-ferie-form>',
        rights: { domain: 'jferies', right: 'create' },
        ncyBreadcrumb: {
            parent: 'jours-feries.list',
            label: '{{ "JOURS_FERIES.BREADCRUMBS.JOURS_FERIES_NEW" | translate}}'
        },
        search: 'JOURS_FERIES.BREADCRUMBS.JOURS_FERIES_NEW'
    });

    $stateProvider.state('jours-feries.edit', {
        url: '/{id}/edit',
        template: '<jour-ferie-form></jour-ferie-form>',
        rights: { domain: 'jferies', right: 'update' },
        params: {
            genre: '1'
        },
        ncyBreadcrumb: {
            parent: 'jours-feries.list',
            label: '{{ "JOURS_FERIES.BREADCRUMBS.JOURS_FERIES_EDIT" | translate }}'
        }
    });

    $stateProvider.state('jours-feries.duplicate', {
        url: '/{id}/duplicate',
        template: '<jour-ferie-form></jour-ferie-form>',
        rights: { domain: 'jferies', right: 'create' },
        ncyBreadcrumb: {
            parent: 'jours-feries.list',
            label: '{{ "JOURS_FERIES.BREADCRUMBS.JOURS_FERIES_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });
}

Configure.$inject = ['$stateProvider'];
