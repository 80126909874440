export default class BanqueDetailController {
    static $inject = ['$stateParams', '$state', 'BanquesService', 'notification', 'BanquesCommunicationService'];

    constructor($stateParams, $state, BanquesService, notification, BanquesCommunicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.BanquesService = BanquesService;
        this.notification = notification;
        this.BanquesCommunicationService = BanquesCommunicationService;
    }

    $onInit() {
        this.loading = false;
        this.banque = {};
        this.initBanque();
    }

    async initBanque() {
        this.banque = { id: this.$stateParams.id };
        if (this.banque.id) {
            this.startLoading();
            try {
                this.banque = await this.BanquesService.getBanqueById(this.banque.id);
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListBanque();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListBanque();
        }
    }

    async getBanque(banqueId) {
        this.banque = await this.BanquesService.getBanqueById(banqueId);
    }

    goListBanque() {
        this.$state.go('banques.list', {}, { reload: true });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
