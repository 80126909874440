import GrilleZonesKilometriques from './grille.zones.kilometriques.model';

export default class GrilleZonesKilometriquesFormController {
    static $inject = ['$scope', '$state', '$stateParams', 'GrillesZonesKilometriquesService', 'notification', '$location'];

    constructor($scope, $state, $stateParams, GrillesZonesKilometriquesService, notification, $location) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.GrillesZonesKilometriquesService = GrillesZonesKilometriquesService;
        this.notification = notification;
        this.$location = $location;
        this.newLigne = false;
    }

    async $onInit() {
        this.grilleZonesKilometriques = {};
        this.loading = false;
        this.getZones();
        await this.reset();
    }

    async getZones() {
        this.startLoading();
        try {
            this.zones = await this.GrillesZonesKilometriquesService.getZonesSelect();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async reset() {
        this.startLoading();

        let data = {
            id: this.$stateParams.id
        };
        if (data.id) {
            try {
                data = await this.GrillesZonesKilometriquesService.getGrilleZonesKilometriquesById(data.id);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('grilles-zones-kilometriques.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            }
        }

        if (this.$stateParams.duplicate) {
            data.id = undefined;
            data.code += '_copie';
        } else if (!data.id && data.code) {
            this.checkCodeUnicity(data.code);
        }

        this.grilleZonesKilometriques = new GrilleZonesKilometriques(data);
        if (!this.grilleZonesKilometriques.id) {
            this.grilleZonesKilometriques.achatVente = 1;
        } else {
            if (this.grilleZonesKilometriques.estVente) {
                this.grilleZonesKilometriques.achatVente = 1;
            } else {
                this.grilleZonesKilometriques.achatVente = 2;
            }
        }
        this.stopLoading();
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.grilleZonesKilometriques.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.grilleZonesKilometriques.codeExists = await this.GrillesZonesKilometriquesService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    // boutons de validation
    async sauvegarder() {
        const continuer = true;
        if (this.newLigne || !this.grilleZonesKilometriques.zones || this.grilleZonesKilometriques.zones.length == 0) {
            if (!(await this.addZone(false))) {
                return false;
            }
        }
        if (continuer && this.checkValidity()) {
            this.startLoading();
            try {
                this.grilleZonesKilometriques.estVente = this.grilleZonesKilometriques.achatVente == 1;
                if (this.grilleZonesKilometriques.id) {
                    const tmp = await this.updateGrilleZonesKilometriques();
                    this.notification.success('GRILLES_ZONES_KILOMETRIQUES.UPDATED');
                    return this.grilleZonesKilometriques.id;
                }
                const tmp = await this.createGrilleZonesKilometriques();
                this.notification.success('GRILLES_ZONES_KILOMETRIQUES.CREATED');
                return tmp;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        } else {
            return false;
        }
    }

    supprimerToutesLignes() {
        this.grilleZonesKilometriques.zones.splice(0, this.grilleZonesKilometriques.zones.length);
    }

    removeZone(conv) {
        const i = this.grilleZonesKilometriques.zones.indexOf(conv);
        this.grilleZonesKilometriques.zones.splice(i, 1);
    }

    ajouterLigne() {
        this.newLigne = true;
    }

    removeNewLigne() {
        this.newLigne = false;
    }

    async addZone(newLigne) {
        if (this.newDeKm != undefined && this.newAKm && this.newZone) {
            /*var trouve=false;
            if(this.grilleZonesKilometriques.zones)
            {
                for(var ind=0; ind<this.grilleZonesKilometriques.zones.length;ind++)
                {
                    if(this.grilleZonesKilometriques.zones[ind].idGrilleZonesKilometriques==this.newGrilleZonesKilometriquesConv)
                        trouve=true;
                }
            }
            if(trouve)
            {
                this.notification.error('GRILLES_ZONES_KILOMETRIQUES.CONVERSION_EXISTANTE_UNITE');
                return false;
            }
            else
            {*/
            const newZoneKm = { id: -1, idZone: this.newZone, kilometresA: this.newAKm, kilometresDe: this.newDeKm };
            if (!this.grilleZonesKilometriques.zones) {
                this.grilleZonesKilometriques.zones = [];
            }
            this.grilleZonesKilometriques.zones.push(newZoneKm);
            this.newZone = undefined;
            this.newAKm = undefined;
            this.newDeKm = undefined;
            if (newLigne) {
                this.ajouterLigne();
            }
            return true;
            // }
        }

        if (this.newDeKm || this.newAKm || this.newZone) {
            this.notification.error('GRILLES_ZONES_KILOMETRIQUES.ZONE_KILOMETRES_OBLIGATOIRES');
            return false;
        }
        return true; //pas de nouvelle ligne à créer
    }

    convertDeKmToDecimal() {
        if (typeof this.newDeKm === 'string') {
            this.newDeKm = parseFloat(this.newDeKm.replace(',', '.').replace(' ', ''));
        }
    }

    convertKmDeToDecimal(zonekm) {
        if (typeof zonekm.kilometresDe === 'string') {
            zonekm.kilometresDe = parseFloat(zonekm.kilometresDe.replace(',', '.').replace(' ', ''));
        }
    }

    convertAKmToDecimal() {
        if (typeof this.newAKm === 'string') {
            this.newAKm = parseFloat(this.newAKm.replace(',', '.').replace(' ', ''));
        }
    }

    convertKmAToDecimal(zonekm) {
        if (typeof zonekm.kilometresA === 'string') {
            zonekm.kilometresA = parseFloat(zonekm.kilometresA.replace(',', '.').replace(' ', ''));
        }
    }

    async valider() {
        const returnedId = await this.sauvegarder();
        if (returnedId) {
            this.newLigne = false;
            this.$state.go('grilles-zones-kilometriques.edit', { id: returnedId });
        }
    }
    async validerEtFermer() {
        const success = await this.sauvegarder();
        if (success) {
            this.annuler();
        }
    }

    annuler() {
        this.$state.go('grilles-zones-kilometriques.list');
    }

    async createGrilleZonesKilometriques() {
        this.startLoading();
        try {
            const returnedId = await this.GrillesZonesKilometriquesService.createGrilleZonesKilometriques(this.grilleZonesKilometriques);
            this.notification.success('GRILLES_ZONES_KILOMETRIQUES.CREATED');
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateGrilleZonesKilometriques() {
        this.startLoading();
        try {
            const returnedId = await this.GrillesZonesKilometriquesService.updateGrilleZonesKilometriques(this.grilleZonesKilometriques);
            this.notification.success('GRILLES_ZONES_KILOMETRIQUES.UPDATED');
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    reinit() {
        this.setGrilleZonesKilometriques(this.$stateParams.id);
        this.GrillesZonesKilometriquesCommunicationService.raiseReinit();
    }

    checkValidity() {
        this.$scope.$broadcast('uniteValidations');
        return this.grilleZonesKilometriques.isValid();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
