import validatorBuilder from 'validator-builder';
import NomenclatureEntete from './entete/nomenclature.entete.model';

let nomenclatureValidator = null;

export default class Nomenclature {
	constructor(data = {}) {
		this.id = data.id;
		this.entete = new NomenclatureEntete(data.entete);
	}

	isValid() {
		nomenclatureValidator = nomenclatureValidator || validatorBuilder.getInstanceFor('Nomenclature');
		const validationResults = nomenclatureValidator.validate(this);
		return validationResults.isValid;
	}
}
