import validatorBuilder from 'validator-builder';

let etatCompteValidator = null;

export default class EtatCompte {
    constructor(data = {}) {
        this.id = data.id;
        this.codeSociete = data.codeSociete;
        this.isCompteClientOuvert = data.isCompteClientOuvert;
        this.montantEnCoursAutorise = data.montantEnCoursAutorise;
        this.montantEnCoursDuMois = data.montantEnCoursDuMois;
        this.nomSociete = data.nomSociete;
        this.societeId = data.societeId;
    }

    isValid() {
        etatCompteValidator = etatCompteValidator || validatorBuilder.getInstanceFor('EtatCompteSites');
        const validationResults = etatCompteValidator.validate(this);
        return validationResults.isValid;
    }
}
