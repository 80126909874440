import io from 'socket.io-client';

export default class SocketService {
    /* @ngInject */
    constructor() {
        this.serviceAvalaible = false;
        if (!this.socket && __configuration.service && __configuration.service !== '') {
            try {
                this.socket = io(__configuration.service);
                this.serviceAvalaible = true;
            } catch (error) {
                console.log('🚀 ~ file: socket.service.js ~ line 12 ~ SocketService ~ constructor ~ error', error);
                this.serviceAvalaible = false;
            }
        }
    }

    getSocket() {
        if (!this.socket && __configuration.service && __configuration.service != '') {
            this.socket = io(__configuration.service);
        }
        return this.socket;
    }

    getServiceState() {
        if (!this.serviceAvalaible) {
            this.getSocket();
        }
        return this.serviceAvalaible || this.socket?.connected;
    }

    async closeConnection() {
        if (this.socket) {
            this.socket.emit('disconnect');
        }
    }
}
