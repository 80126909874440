import TicketsExportCsvPopupController from './tickets.export.csv.popup.controller';

export default {
    bindings: {
        modalInstance: '=',
        resolve: '<'
    },
    template: require('./tickets.export.csv.popup.html'),
    controller: TicketsExportCsvPopupController
};
