BanquesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'BanquesService',
    'notification',
    '$transitions',
    'MOPService'
];

export default function BanquesController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    BanquesService,
    notification,
    $transitions,
    MOPService
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    vm.selectedBanqueId = undefined;
    vm.lastSelected = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.banques = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.selectBanque = selectBanque;
    vm.isBanqueSelected = isBanqueSelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadBanques = loadBanques;
    vm.deleteBanque = deleteBanque;
    vm.setSelected = setSelected;
    vm.eraseAllFilters = eraseAllFilters;
    vm.MOPService = MOPService;

    function init() {
        vm.MOPService.setMop([{ title: 'BANQUES.BREADCRUMBS.BANQUES_LIST', filename: 'Banques.pdf', application: 'gestion' }]);
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    init();

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    function stateChangeSuccessFunc(event, toState) {
        if (!$state.params.id) {
            vm.setSelected();
        } else if ($state.params.id) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    function setSelected(banque) {
        if (banque && banque.id) {
            vm.selectedBanqueId = $stateParams.id;
            vm.lastSelected = vm.selectedBanqueId;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            vm.selectedBanqueId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return $state && $state.current && $state.current.name && $state.current.name === 'banques.list.detail' && $stateParams && $stateParams.id;
    }

    function selectBanque(banque) {
        if (banque && banque.id) {
            if (vm.selectedBanqueId !== banque.id) {
                vm.selectedBanqueId = banque.id;
                showDetail(banque.id);
            } else {
                vm.selectedBanqueId = undefined;
                closeDetail();
            }
        }
    }

    function isBanqueSelected(banque) {
        return banque && banque.id && vm.selectedBanqueId === banque.id;
    }

    function showDetail(banqueId) {
        $state.go('banques.list.detail', { id: banqueId });
    }

    function closeDetail() {
        $state.go('banques.list');
    }

    async function loadBanques(tableState) {
        startLoading();

        if ($state && $state.current && $state.current.name !== 'banques.list') {
            $state.go('banques.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.banques = [];

        try {
            const data = await BanquesService.getBanques(filters, sorts, pagination);

            vm.banques = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteBanque(banque) {
        if (banque /*&& banque.canDelete*/ && banque.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('BANQUES.DELETE.TITLE', { code: banque.libelle }),
                modalMsg: $translate.instant('BANQUES.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await BanquesService.deleteBanqueById(banque.id);
                    notification.success($translate.instant('BANQUES.DELETE.SUCCESS'));

                    vm.selectedBanqueId = undefined;

                    if (vm.params.id) {
                        vm.state.go('banques.list');
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadBanques();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function eraseAllFilters() {
        loadBanques();
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
