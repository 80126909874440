export default class SyntheseEntityDetailController {
    static $inject = [
        '$stateParams',
        '$state',
        'SyntheseEntitiesService',
        'notification',
        'SyntheseEntitiesCommunicationService',
        'DataShareService',
        'MassiaApplicationService'
    ];

    constructor(
        $stateParams,
        $state,
        SyntheseEntitiesService,
        notification,
        SyntheseEntitiesCommunicationService,
        DataShareService,
        MassiaApplicationService
    ) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.notification = notification;
        this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
        this.DataShareService = DataShareService;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        this.loading = false;
        this.syntheseEntity = {};

        this.openAllOnglets();
        this.initSyntheseEntity();
    }

    async initSyntheseEntity() {
        this.syntheseEntity = {
            id: this.$stateParams.idSynth
        };

        if (this.syntheseEntity.id) {
            this.startLoading();

            try {
                // on ajoute await afin de ne pas passer trop rapidement dans le finally
                // et ainsi on garde le loading le temps nécessaire
                this.syntheseEntity = await this.SyntheseEntitiesService.getSyntheseEntityById(this.syntheseEntity.id);
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListSyntheseEntity();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListSyntheseEntity();
        }
    }

    goListSyntheseEntity() {
        this.$state.go('syntheseentities.list', {}, { reload: true });
    }

    closeAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: false,
            isListeSelectionOpen: false,
            isCtrlOpen: false,
            isEssaisOpen: false,
            isListeAffichagesOpen: false,
            isListeTraitementsOpen: false
        };
    }

    openAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: true,
            isListeSelectionOpen: true,
            isCtrlOpen: true,
            isEssaisOpen: true,
            isListeAffichagesOpen: true,
            isListeTraitementsOpen: true
        };
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
