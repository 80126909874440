export default class FormuleFormConfigImpressionController {
    constructor($translate, $scope, $state, notification, MassiaRightsService) {
        (this.$translate = $translate),
            (this.$scope = $scope),
            (this.$state = $state),
            (this.notification = notification),
            (this.MassiaRightsService = MassiaRightsService);
    }

    async $onInit() {}

    $onDestroy() {}
}
