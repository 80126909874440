(function (angular, undefined) {
    'use strict';

    angular.module('blocks.datepicker').controller('AcDatepickerController', AcDatepickerController);
    AcDatepickerController.$inject = ['$scope', 'moment', '$log', '_', 'AcDatepickerService', 'globalizationManagementService'];

    /* @ngInject */
    function AcDatepickerController($scope, moment, $log, _, AcDatepickerService, globalizationManagementService) {
        /* jshint validthis: true */
        var vm = this;
        var inputModelCtrlName, inputModelCtrl, config;

        // propriétés
        vm.inputModel = undefined;

        // bind to controller
        // vm.model
        // vm.modelName
        // vm.config
        // vm.id
        // vm.name
        // vm.acDatepickerDisabled
        // vm.acAutofocus
        // vm.acDatepickerValidationBefore
        // vm.acDatepickerValidationAfter
        // vm.acDatepickerValidationStrict
        // vm.acDatepickerValidationMessage

        // init
        // vm.ngModelCtrl

        vm.datepickerOpened = false;
        vm.inputModelCtrlName = inputModelCtrlName = 'inputNgModelCtrl';

        // méthodes publiques
        vm.openPopup = openPopup;
        vm.inputIsDisabled = inputIsDisabled;
        vm.formatInputModel = formatInputModel;
        vm.setModel = setModel;

        // events
        var registeredEvents = [$scope.$on('$destroy', dispose), $scope.$watchCollection(getModelCtrlErrors, setModelValidity)];

        init();

        //region Initialisation
        function init() {
            vm.config = config = initConfig();
        }

        //endregion Initialisation

        //region Méthodes publiques
        function openPopup() {
            vm.datepickerOpened = true;
        }

        function inputIsDisabled() {
            return vm.datepickerOpened || vm.acDatepickerDisabled || vm.config.disabledInput;
        }

        //endregion Méthodes publiques

        //region Méthodes privées
        function initConfig() {
            var userConfig, defaultConfig, fullConfig;
            userConfig = vm.config || {};
            defaultConfig = AcDatepickerService.getDefaultConfig();

            // si le format n'est pas défini par l'utilisateur
            if (!userConfig.format && !userConfig.outputFormat) {
                // on gère le format automatiquement en fonction de la langue
                var language = globalizationManagementService.getCurrentLanguage();
                if (language) {
                    userConfig.format = language.displayDateMask;
                    userConfig.outputFormat = language.dateFormat;
                }
                else {
                    userConfig.format = 'MM/dd/yyyy';
                    userConfig.outputFormat = 'MM/DD/YYYY';
                }
            }

            // on merge la config utilisateur et la config par défaut
            fullConfig = angular.extend(defaultConfig, userConfig);

            //on utilise la propriété datepickerOptions qui est passée à la directive ui.bootstrap pour paramétrer le template custom
            fullConfig.datepickerOptions.config = {
                acDisableTodayButton: fullConfig.disableTodayButton,
                acDisableClearButton: fullConfig.disableClearButton,
            };

            //si l'option pour désactiver la sélection de weekend est à true,
            //on renseigne la propriété correspondante dans l'objet de configuration du datepicker
            if (fullConfig.datepickerOptions.weekendDisabled) {
                fullConfig.datepickerOptions.dateDisabled = function disableWeekends(param) {
                    if (param.mode === 'day') {
                        var weekendRange = [6, 0];
                        var dayOfweek = param.date.getDay();
                        return _.includes(weekendRange, dayOfweek); // renvoie si le jour est un samedi ou un dimanche
                    }
                };
            }

            return fullConfig;
        }

        function setModel(value) {
            // value arrive en tant que string
            // défini par le format de ui-bootstrap config.format
            // doublé par config.outputFormat pour le format moment
            var momentValue,
                output = value;

            // moment se débrouille pour construire la date avec le format qu'on lui passe
            momentValue = moment(value, config.outputFormat);
            // si un mode de sortie est défini, on l'applique
            if (config && momentValue.isValid()) {
                switch (config.outputMode) {
                    case 'moment':
                        output = momentValue;
                        break;
                    case 'date':
                        output = momentValue.toDate();
                        break;
                    case 'string': // jslint
                    /* falls through */ default:
                        output = momentValue.format(config.outputFormat);
                        break;
                }
            }

            // si le model a changé
            if (vm.model !== output) {
                // on applique la nouvelle valeur
                vm.model = output;
                // on indique au ngModelCtrl qu'il est dirty pour permettre la validation
                vm.ngModelCtrl.$setDirty();
            }

            //on invoque la fonction renseignée dans la config
            if (angular.isFunction(vm.config.onDateSelected)) {
                vm.config.onDateSelected(vm.model);
            }

            return output;
        }

        function formatInputModel(value) {
            var dateModel,
                model = angular.copy(value);
            var logError = true;
            if (model !== undefined && model !== null) {
                // on vérifie le type de l'objet
                if (angular.isDate(model)) {
                    dateModel = moment(model);
                    logError = false;
                } else if (moment.isMoment(model)) {
                    dateModel = model.isValid() ? model.toDate() : model;
                    logError = dateModel === model;
                } else if (angular.isString(model)) {
                    if (model.length > 0) {
                        var m = moment(model, vm.config.outputFormat);
                        if (m.isValid()) dateModel = m.toDate();
                        else dateModel = model;

                        logError = dateModel === model;
                    } else {
                        logError = false;
                    }
                }
                vm.inputModel = dateModel;
            } else {
                vm.inputModel = undefined;
                logError = false;
            }

            if (logError) {
                log('model invalid format');
            }
        }

        function log(message) {
            if (vm.model !== undefined && vm.model !== null) {
                $log.warn(message);
            }
        }

        function getModelCtrlErrors() {
            return vm.ngModelCtrl.$error;
        }

        function setModelValidity(newVal, oldVal) {
            if (inputModelCtrl === undefined && vm[inputModelCtrlName] !== undefined) {
                inputModelCtrl = vm[inputModelCtrlName];
            }

            if (inputModelCtrl !== undefined) {
                if (_.isEmpty(newVal)) {
                    inputModelCtrl.$setValidity('input-date', true);
                } else {
                    inputModelCtrl.$setValidity('input-date', false);
                }
            }
        }

        //endregion Méthodes privées

        //region Events
        function dispose() {
            angular.forEach(registeredEvents, function (unregisterEvent) {
                unregisterEvent();
            });
        }

        //endregion Events
    }
})(angular);
