import { CaracteristiqueService } from '../../../../../../core/http/caracteristique.service';
import { Caracteristique } from '../../../../../../core/models/caracteristique/Caracteristique';
import { ElementCaracteristique } from '../../../../../../core/models/element-caracteristique/ElementCaracteristique';
import { ValeurCaracteristique } from '../../../../../../core/models/valeur-caracteristique/ValeurCaracteristique';

class CaractListeController {
    caractSvc: CaracteristiqueService;
    caracteristique: Caracteristique;
    valeurs: ValeurCaracteristique[];
    model: ValeurCaracteristique;
    liste: ElementCaracteristique[] = [];
    index: number;
    isObligatoire: boolean;
    defaultValue: String;
    /* @ngInject */
    constructor(CaracteristiqueService: CaracteristiqueService) {
        this.caractSvc = CaracteristiqueService;
    }
    $onInit() {
        const value = this.valeurs.find(x => x.idCaracteristique === this.caracteristique.id);
        if (!value) {
            this.model = <ValeurCaracteristique>{
                id: 0,
                idCaracteristique: this.caracteristique.id,
                value: this.defaultValue || this.defaultValue === '0' ? this.defaultValue : null
            };
            this.valeurs.push(this.model);
        } else {
            this.model = value;
        }
        this.loadElement();
    }
    $onDestroy() {}

    async loadElement() {
        const elements = await this.caractSvc.getAllElement(this.caracteristique.id);
        this.liste = elements;
    }

    selectElement(output: ElementCaracteristique[]) {
        if (output && output.length > 0) {
            this.model.value = output[0].code;
        } else {
            this.model.value = null;
        }
    }
}
export default {
    bindings: {
        caracteristique: '<',
        valeurs: '=',
        isObligatoire: '<',
        lecture: '<',
        defaultValue: '='
    },
    controller: CaractListeController,
    template: require('./caracteristique-liste.html')
};
