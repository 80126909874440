const serviceUrl = `${__configuration.apiUrl}/massia/facteur-emission-co2`;

export default class FacteurEmissionCo2Service {
    static $inject = ['$http', 'notification', '$translate', 'localStorageService', 'AuthConstant'];

    constructor($http) {
        this.$http = $http;
    }

    async getAllFacteursEmissionCo2() {
        return this.$http.get(serviceUrl);
    }

    async getAllFacteursEmissionCo2ByDate(date = null) {
        const url = `${serviceUrl}/by-date`;
        return this.$http.put(url, date);
    }

    async deleteFacteursEmissionCo2ByIdFamille(idFamille) {
        return this.$http.post(`${serviceUrl}/by-idfamille/${idFamille}`);
    }

    async deleteFacteursEmissionCo2(id) {
        return this.$http.delete(`${serviceUrl}/${idFamille}`);
    }

    async deleteAllFacteursEmissionCo2ByIds(data) {
        const url = `${serviceUrl}/delete-all`;
        return this.$http.post(url, data);
    }

    async updateFacteursEmissionCo2(data) {
        return this.$http.put(serviceUrl, data);
    }
}
