import validatorBuilder from 'validator-builder';

let prefacturationValidator = null;

export default class Prefacturation {
    constructor(data = {}) {
        this.objectSociete = data.objectSociete;

        this.dateDebut = data.dateDebut;
        this.dateFin = data.dateFin;
        this.dateGeneration = data.dateGeneration;
        this.hasDetailLignes = true;
        this.isRecalerFranco = false;
        this.isRecalerTransportAFacturer = false;
        this.isRecalerLieuParChantier = false;
        this.isRevaloriserTicket = true;
        this.isValoriserTicket = true;

        this.listeIdSitesCommerciaux = [];
        this.salarie = {
            id: 0,
            libelle: null
        };
        this.listeIdTypeClient = [];
        this.listeIdCliFour = [];
        this.listeIdChantiers = [];

        this.searchingFormPreFa = {};
        this.searchingFormPreFa.entity = 'preFa';
        this.searchingFormPreFa.societeComIdSelected = null;
        this.searchingFormPreFa.dateDebut = null;
        this.searchingFormPreFa.dateFin = null;

        this.achatVente = null;
        this.DateFinTicket = data.DateFinTicket;
    }

    isValid() {
        prefacturationValidator = prefacturationValidator || validatorBuilder.getInstanceFor('Prefacturation');
        const validationResults = prefacturationValidator.validate(this);
        return validationResults.isValid;
    }
}
