export default class DonneeFiltreesController {
    static $inject = ['$scope', '$state', '$stateParams', '$translate', '$timeout', 'notification', '_', 'DonneeFiltreesService'];

    constructor($scope, $state, $stateParams, $translate, $timeout, notification, _, DonneeFiltreesService) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.$timeout = $timeout;
        this.notification = notification;
        this._ = _;
        this.DonneeFiltreesService = DonneeFiltreesService;
    }

    $onInit() {
        this.loading = true;
        this.donneeFiltree = [];
        this.loadFiltreTimeout = null;
        this.type = this.$stateParams.type;

        this.reset();
    }

    $onDestroy() {
        this.$timeout.cancel(this.loadProfilTimeout);
    }

    async valider() {
        await this.sauvegarder();
        this.backToPreviousState();
    }

    annuler() {
        this.backToPreviousState();
    }

    async reset() {
        this.startLoading();

        const data = {
            id: this.$stateParams.id,
            type: this.$stateParams.type
        };

        if (data.id) {
            try {
                this.donneeFiltree = await this.DonneeFiltreesService.getDonneeFiltree(data.id, data.type);
            } catch (ex) {
                this.notification.error(ex.data);
            }
        }

        const filtres = await this.refreshFiltres();

        this._.forEach(this.donneeFiltree.filtreUtilisateurs, (filtreUtilisateur) => {
            filtreUtilisateur.filtres = [];
            angular.copy(filtres, filtreUtilisateur.filtres);
            this.preselectFiltres(filtreUtilisateur);
        });

        this.$timeout.cancel(this.loadFiltreTimeout);
        this.loadFiltreTimeout = this.$timeout(() => {
            this.stopLoading();
        });
    }

    async sauvegarder() {
        this._.forEach(this.donneeFiltree.filtreUtilisateurs, (filtreUtilisateur) => {
            this.setSelectedFiltres(filtreUtilisateur);
        });
        this.startLoading();
        try {
            await this.DonneeFiltreesService.updateFiltre(this.donneeFiltree);
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        } finally {
            this.stopLoading();
        }
    }

    deleteFiltre(filtreUtilisateur, filtre) {
        const index = filtreUtilisateur.idFiltreUtilisateurs.indexOf(filtre.id);
        if (index >= 0) {
            filtreUtilisateur.idFiltreUtilisateurs.splice(index, 1);
            filtre.selected = false;
        }
    }

    selectFiltre(filtreUtilisateur, filtre) {
        const index = filtreUtilisateur.idFiltreUtilisateurs.indexOf(filtre.id);
        if (index < 0) {
            filtreUtilisateur.idFiltreUtilisateurs.push(filtre.id);
        }
    }

    async refreshFiltres() {
        let data;
        try {
            data = await this.DonneeFiltreesService.getFiltres();
        } catch (ex) {
            data = [];
        }
        return data;
    }

    setSelectedFiltres(filtreUtilisateur) {
        this._.forEach(filtreUtilisateur.filtres, (filtre) => {
            if (filtre.selected) {
                this.selectFiltre(filtreUtilisateur, filtre);
            } else {
                this.deleteFiltre(filtreUtilisateur, filtre);
            }
        });
    }

    preselectFiltres(filtreUtilisateur) {
        this._.forEach(filtreUtilisateur.idFiltreUtilisateurs, (id) => {
            const filtre = this._.find(filtreUtilisateur.filtres, { id: id });
            if (filtre) {
                filtre.selected = true;
            }
        });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    backToPreviousState() {
        if (this.type == 1) {
            this.$state.go('societes.edit', { id: this.$stateParams.id });
        }
        if (this.type == 2) {
            this.$state.go('sites.edit', { id: this.$stateParams.id, genre: 'commerciaux' });
        }
    }
}
