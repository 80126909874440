export default function Configure($stateProvider) {
    $stateProvider.state('plan-impression-prix', {
        parent: 'gestion',
        url: '/plan-impression-prix',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.GESTION_COMMERCIALE" | translate }}'
        }
    });

    $stateProvider.state('plan-impression-prix.print', {
        url: '/print',
        template: '<plan-impression-prix domaine="planimprprix"></plan-impression-prix>',
        rights: { domain: 'planimprprix', right: 'create' },
        ncyBreadcrumb: {
            parent: 'plan-impression-prix',
            label: '{{ "PLAN_IMPRESSION_PRIX.BREADCRUMBS.PLAN_IMPRESSION_PRIX_PRINT" | translate }}'
        },
        navigation: {
            menu: 'gestion_commerciale_commercial',
            translate: 'PLAN_IMPRESSION_PRIX.BREADCRUMBS.PLAN_IMPRESSION_PRIX',
            order: 5
        }
    });
}

Configure.$inject = ['$stateProvider'];
