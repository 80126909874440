import TarifsConfig from './_config/tarifs.config';
import TarifsComponent from './components/tarifs';
import GrillesComponent from './components/grilles';
import TarifsService from './services/tarifs.service';

const moduleName = 'app.massia.gestion.tarifs';

angular
    .module(moduleName, [])
    .config(TarifsConfig)
    .service('TarifsService', TarifsService)
    .component('tarifs', TarifsComponent)
    .component('grilles', GrillesComponent);

export default moduleName;
