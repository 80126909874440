(function (angular, undefined) {
    'use strict';

    angular.module('blocks.i18n.globalization')
        .run(run);
    run.$inject = ['globalizationConstants', 'globalizationManagementService'];

    function run(globalizationConstants, globalizationManagementService) {
        // on définit le langage par défaut dans le run plutôt que dans le config
        // car on a besoin d'accéder à tmhDynamicLocale qui est un service et non un provider
        setDefaultLanguage();

        function setDefaultLanguage() {
            
            globalizationManagementService.setCurrentLanguage(globalizationConstants.applicationLanguage);
        }
    }
})(angular);