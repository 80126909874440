import GestionStocksTransfertController from './gestion.stocks.transfert.controller';

export default {
    bindings: {
        ligne: '=',
        modalInstance: '=',
        produits: '=',
        emplacements: '='
    },
    template: require('./gestion.stocks.transfert.html'),
    controller: GestionStocksTransfertController
};
