import MesureFormSousMesuresController from './mesure.sous.mesures.controller';

export default {
    bindings: {
        mesure: '<',
        onUpdate: '&'
    },
    template: require('./mesure.sous.mesures.html'),
    controller: MesureFormSousMesuresController
};
