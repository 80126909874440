import validatorBuilder from 'validator-builder';

let typeBasculeValidator = null;

export default class TypeBascule {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.transmissionVitesse = data.transmissionVitesse;
        this.transmissionBitStart = data.transmissionBitStart;
        this.transmissionBitStop = data.transmissionBitStop;
        this.transmissionBitDate = data.transmissionBitDate;
        this.transmissionParite = data.transmissionPariteValue || 0;
        this.connexionAdresseIP = data.connexionAdresseIP;
        this.connexionPort = data.connexionPort;
        this.connexionProtocole = data.connexionProtocole;
        this.codeExists = data.codeExists || null;
        this.codeInvalid = data.codeInvalid || null;
        this.avecPasserelleBorne = data.avecPasserelleBorne;
        this.isSerieIp = data.isSerieIp;
    }

    isValid() {
        typeBasculeValidator = typeBasculeValidator || validatorBuilder.getInstanceFor('TypeBascule');
        const validationResults = typeBasculeValidator.validate(this);
        return validationResults.isValid;
    }
}
