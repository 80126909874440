import AddTransportModal from './add-transport-modal.controller';

export default {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: require('./add-transport-modal.html'),
    controller: AddTransportModal
};
