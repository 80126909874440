export default function PortailRoute($stateProvider) {
    $stateProvider
        .state('portail', {
            parent: 'common',
            url: '/portail',
            abstract: true,
            rights: { domain: 'portail', right: 'read' },
            views: {
                common: {
                    template: '<portail-home></portail-home>'
                }
            },
            /* navigation: {
            menu: 'donnees_parametres_no_category',
            translate: 'PORTAIL.BREADCRUMBS.PORTAIL',
            order: 1,
        }, */
            ncyBreadcrumb: {
                // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
                skip: true,
                label: '{{ "LAYOUT.NAVIGATION.PORTAIL" | translate }}'
            }
        })
        .state('portail.home', {
            url: '',
            //abstract: true,
            rights: { domain: 'portail', right: 'read' },
            template: '<portail-stats></portail-stats>',
            /* navigation: {
                menu: 'donnees_parametres_no_category',
                translate: 'PORTAIL.BREADCRUMBS.PORTAIL',
                order: 1,
            }, */
            ncyBreadcrumb: {
                // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
                skip: true,
                label: '{{ "LAYOUT.NAVIGATION.PORTAIL" | translate }}'
            }
        });

    /* $stateProvider.state('portail.user', {
        url: '/utilisateur',
        template: '<zone-abattages></zone-abattages>',
        rights: { domain: 'portail.user', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}',
        },
        // la liste des zone-abattages fait partie des points d'entrée recherchables dans la navigation
        search: 'PORTAIL.BREADCRUMBS.PORTAIL',
    }); */
}

PortailRoute.$inject = ['$stateProvider'];
