import validatorBuilder from 'validator-builder';
import ControleEntete from '../controle-entete/controle.entete.model';

let controleValidator = null;

export default class Controle {
    constructor(data) {
        if (data) {
            this.entete = new ControleEntete(data.entete);
        } else {
            this.entete = new ControleEntete();
        }
    }

    isValid() {
        controleValidator = controleValidator || validatorBuilder.getInstanceFor('Controle');
        const validationResults = controleValidator.validate(this);
        return validationResults.isValid;
    }
}
