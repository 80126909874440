import LivraisonPlanifieeLivreeConfig from './_config/livraison.planifiee.livree.config';
import LivraisonPlanifieeLivreeRoutes from './_config/livraison.planifiee.livree.routes';

import LivraisonPlanifieeLivreeComponent from './components/livraison-planifiee-livree';

import LivraisonPlanifieeLivreeService from './services/livraison.planifiee.livree.service';

const moduleName = 'app.massia.gestion.plannings.livraison.planifiee.livree';

angular
    .module(moduleName, [])
    .config(LivraisonPlanifieeLivreeConfig)
    .config(LivraisonPlanifieeLivreeRoutes)
    .service('LivraisonPlanifieeLivreeService', LivraisonPlanifieeLivreeService)
    .component('livraisonPlanifieeLivree', LivraisonPlanifieeLivreeComponent);

export default moduleName;
