import { Pipe } from "../../../core/decorators";
import { PipeTransform } from "../../../core/decorators/pipe";

@Pipe({
    name: 'isArray'
})
export class IsArray implements PipeTransform {
    transform(input: any) {
        return Array.isArray(input);
    }
}