export default class InventaireStocksMouvementController {
    static $inject = [
        '$stateParams',
        'StocksCommunicationService',
        'StocksService',
        'notification',
        'ModalService',
        '$uibModal',
        'moment',
        'globalizationManagementService'
    ];

    constructor(
        $stateParams,
        StocksCommunicationService,
        StocksService,
        notification,
        ModalService,
        $uibModal,
        moment,
        globalizationManagementService
    ) {
        this.$stateParams = $stateParams;
        this.StocksCommunicationService = StocksCommunicationService;
        this.StocksService = StocksService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
        this.moment = moment;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    async $onInit() {}

    annuler() {
        this.inventaire.dateIntegration = null;
        this.modalInstance.close(false);
    }

    async confirmer() {
        if (this.inventaire.dateIntegration) {
            if (this.checkDateValidation()) {
                await this.createMouvement();
                this.notification.success('STOCKS.INVENTAIRELIG.MOUVEMENT_CREATED');
                this.modalInstance.close(false);
            } else {
                this.notification.error('STOCKS.DATE_INTEGRATION_ERROR');
            }
        } else {
            this.notification.error('STOCKS.DATE_EMPTY');
        }
    }

    async createMouvement() {
        this.startLoading();
        try {
            return await this.StocksService.createMouvement(this.inventaire);
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    checkDateValidation() {
        const dateIntegration = this.moment(this.inventaire.dateIntegration, this.dateFormat);
        const dateLevee = this.moment(this.inventaire.dateLevee, this.dateFormat);

        // let resultat = false;
        // if(this.moment(dateIntegration).isBetween(firstDay, lastDay, null, '[]'))
        //     resultat = true;
        let resultat = true;
        if (dateLevee > dateIntegration) {
            resultat = false;
        }
        return resultat;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
