import { Chart } from 'chart.js';
import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-graph',
    bindings: {
        graphId: '@',
        graphTitle: '<',
        graphSeries: '<',
        graphLabels: '<',
        graphData: '<',
        graphDatasetOverride: '=',
        graphSeeFullscreen: '<',
        graphSeeOptions: '<',
        graphOptions: '<',
        graphType: '='
    },
    template: require('./massia.graph.tpl.html')
})
export class MassiaGraphController {
    constructor($scope, $uibModal) {
        /**
         * @type ng.IScope
         */
        this.$scope = $scope;
        this.colors = [
            '#97BBCD', // blue
            '#DCDCDC', // light grey
            '#F7464A', // red
            '#46BFBD', // green
            '#FDB45C', // yellow
            '#949FB1', // grey
            '#4D5360' // dark grey
        ];
        this.$uibModal = $uibModal;
        this.fullscreen = false;
    }

    $onInit() {
        this.id = this.graphId || 'massia-graph';
        this.unregister = [];
        this.createChart();
    }

    $onDestroy() {}

    $onChanges(changes) {
        if (this.chart) {
            /* if (changes.graphSeries) {
                this.graphSeries = changes.graphSeries.currentValue;
            }

            if (changes.graphData) {
                this.graphData = changes.graphData.currentValue;
            }

            if (changes.graphLabels) {
                this.graphLabels = changes.graphLabels.currentValue;
            }

            if (changes.graphTitle) {
                this.graphTitle = changes.graphTitle.currentValue;
            }

            if (changes.graphDatasetOverride) {
                this.graphDatasetOverride = changes.graphDatasetOverride.currentValue;
            }

            if (changes.graphOptions) {
                this.graphOptions = changes.graphOptions.currentValue;
            }

            if (changes.graphType) {
                this.graphType = changes.graphType.currentValue;
            } */
            this.updateGraph();
        }
    }

    createChart() {
        var defaultOptions = this.createOptions();
        console.log(defaultOptions);
        $(() => {
            var ctx = this.createChartDomElement();
            this.initGraph(ctx, defaultOptions);
        });
    }

    initGraph(element, config) {
        this.chart = new Chart(element, config);
    }

    updateGraph() {
        this.update = true;
        var data = this.createOptions();
        console.log(data);
        this.chart.options = data.options;
        this.chart.data = data.data;
        this.chart.update();
        this.update = false;
    }

    createOptions() {
        var options = this.graphOptions ? this.graphOptions : {};
        return {
            type: this.graphType || 'bar',
            data: {
                labels: this.graphLabels || [],
                datasets: this.createDataset()
            },
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: this.graphTitle || '',
                        position: 'top',
                        font: { size: 24 }
                    },
                    legend: {
                        display: true,
                        position: 'bottom'
                    },
                    datalabels: {
                        display: false
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                    intersect: false,
                    mode: 'index'
                },
                elements: {
                    line: {
                        tension: 0,
                        fill: false
                    }
                },
                scales: {
                    x: {},
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        max: 100,
                        min: 0,
                        ticks: {
                            stepSize: 10
                        }
                    }
                },
                ...options
            }
        };
    }

    createDataset() {
        var dataset = [];
        if (this.graphData) {
            for (let i = 0; i < this.graphData.length; i++) {
                const d = this.graphData[i];
                const override = this.graphDatasetOverride && this.graphDatasetOverride[i] ? this.graphDatasetOverride[i] : {};
                var res = {
                    spanGaps: true,
                    ...this.convertColor(this.colors[i] || null),
                    ...override,
                    label: this.graphSeries[i] || null,
                    data: d
                };
                if (!this.graphDatasetOverride[i]) this.graphDatasetOverride[i] = {};
                this.graphDatasetOverride[i] = {
                    ...this.graphDatasetOverride[i],
                    ...res
                };
                dataset.push(res);
            }
        }
        return dataset;
    }

    createChartDomElement() {
        return document.getElementById(this.id).getContext('2d');
    }

    toogleFullScreen() {
        this.fullscreen = !this.fullscreen;
        this.goFullScreen();
    }

    generateRandomColor() {
        var num = () => Math.floor(Math.random() * 256);
        var res = 'rgba(' + num() + ', ' + num() + ', ' + num() + ', 0.7)';
        return res;
    }

    goFullScreen() {
        var el = $(`#${this.id}`).closest('.fullscreen-graph');
        if (this.fullscreen) {
            el.addClass('fullscreen-active');
        } else {
            el.removeClass('fullscreen-active');
        }
    }

    openSetting() {
        var modalInstance = this.$uibModal.open({
            animation: true,
            component: 'massiaGraphConfig',
            appendTo: $('#app-main'),
            resolve: {
                dataset: () => this.graphDatasetOverride,
                data: () => this.chart.data,
                type: () => this.graphType
            }
        });

        modalInstance.result.then(
            (res) => {
                console.log(res);
                this.graphDatasetOverride = res.dataset;
                if (res.type && res.type !== this.type) {
                    this.graphType = res.type;
                    this.chart.destroy();
                    this.createChart();
                }
            },
            () => {
                console.log('Modal dismissed at: ' + new Date());
            }
        );
    }

    getRandomColor() {
        var color = [this.getRandomInt(0, 255), this.getRandomInt(0, 255), this.getRandomInt(0, 255)];
        return this.getColor(color);
    }

    convertColor(color) {
        // Allows RGB and RGBA colors to be input as a string: e.g.: "rgb(159,204,0)", "rgba(159,204,0, 0.5)"
        if (typeof color === 'string' && color[0] === 'r') return this.getColor(this.rgbStringToRgb(color));
        // Allows hex colors to be input as a string.
        if (typeof color === 'string' && color[0] === '#') return this.getColor(this.hexToRgb(color.substr(1)));
        // Allows colors to be input as an object, bypassing getColor() entirely
        if (typeof color === 'object' && color !== null) return color;
        return this.getRandomColor();
    }

    getColor(color) {
        var alpha = color[3] || 1;
        color = color.slice(0, 3);
        return {
            backgroundColor: this.rgba(color, 0.6),
            pointBackgroundColor: this.rgba(color, alpha),
            pointHoverBackgroundColor: this.rgba(color, 0.8),
            borderColor: this.rgba(color, alpha),
            pointBorderColor: '#fff',
            pointHoverBorderColor: this.rgba(color, alpha)
        };
    }

    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    hexToRgb(hex) {
        var bigint = parseInt(hex, 16),
            r = (bigint >> 16) & 255,
            g = (bigint >> 8) & 255,
            b = bigint & 255;

        return [r, g, b];
    }

    rgbStringToRgb(color) {
        var match = color.match(/^rgba?\(([\d,.]+)\)$/);
        if (!match) throw new Error('Cannot parse rgb value');
        color = match[1].split(',');
        return color.map(Number);
    }

    rgba(color, alpha) {
        // rgba not supported by IE8
        return 'rgba(' + color.concat(alpha).join(',') + ')';
    }
}

MassiaGraphController.$inject = ['$scope', '$uibModal'];
