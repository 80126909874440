import SyntheseEntityCritere from '../syntheseentitiescritere.model';

export default class SyntheseEntitiesSelectionController {
    constructor(
        _,
        $scope,
        $state,
        $stateParams,
        $translate,
        notification,
        moment,
        $uibModal,
        $timeout,
        MassiaApplicationService,
        SyntheseEntitiesService,
        SyntheseEntitiesCommunicationService,
        globalizationManagementService
    ) {
        (this._ = _), (this.$scope = $scope);
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.notification = notification;
        this.moment = moment;
        this.$uibModal = $uibModal;
        this.$timeout = $timeout;
        this.MassiaApplicationService = MassiaApplicationService;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    async $onInit() {
        this.dragOptions = {
            placeholder: 'table-row-ui-placeholder',
            revert: true,
            animation: 200,
            axis: 'y',
            'ui-preserve-size': true
        };

        this.activite = this.MassiaApplicationService.getApplication();
        this.startLoading();
        await this.getReferences();
        await this.getTrames();
        await this.loadOperators();
        await this.loadPeriodesGlissantes();
        this.stopLoading();

        const that = this;
        this.$timeout(async function () {
            that.addWatchers();
        }, 0);
    }

    addWatchers() {
        const that = this;
        this.$scope.$watchCollection('$ctrl.syntheseEntity.idModeleSynthese', function (newMod, oldMod) {
            if (newMod !== oldMod) {
                that.getReferences();
            }
        });
        this.$scope.$watchCollection('$ctrl.syntheseEntity.idFamille', function (newMod, oldMod) {
            if (newMod !== oldMod) {
                that.getReferences();
            }
        });
    }

    $onDestroy() {}

    async initForm() {}

    async getReferences() {
        if (this.syntheseEntity && this.syntheseEntity.idFamille) {
            await this.extractIdsProduitProducteur();

            try {
                if (this.syntheseEntity.typeDeSynthese && this.syntheseEntity.typeDeSynthese !== 13) {
                    const periodes = [];
                    if (this.syntheseEntity.listeSyntheseCritereValeurBlocks) {
                        this.syntheseEntity.listeSyntheseCritereValeurBlocks.map((e) => {
                            e.listeSyntheseCritereValeurLignes.map((f) => {
                                if (f.typeDeChamp === 5) {
                                    periodes.push({
                                        dateDebut: f.dateDebut,
                                        dateFin: f.dateFin,
                                        periodeGlissante: f.periodeGlissante,
                                        valeurTemporelle: f.valeurTemporelle,
                                        valeurTemporelle2: f.valeurTemporelle2
                                    });
                                }
                                return f;
                            });
                            return e;
                        });
                    }
                    const tempList = await this.SyntheseEntitiesService.getReferencesByParamsForSynthese(
                        this.listproduitids,
                        this.listproducteurids,
                        this.listclientids,
                        periodes,
                        this.syntheseEntity.idFamille,
                        this.syntheseEntity.typeDeSynthese == 2
                    );
                    // pour mettre au bon format pour le iSteven
                    this.syntheseEntity.listeReferences = await this.formattingListReferenceByNorme(tempList);
                } else if (
                    this.syntheseEntity.typeDeSynthese &&
                    this.syntheseEntity.typeDeSynthese === 13 &&
                    this.syntheseEntity.carteDeControle.syntheseLiee &&
                    this.syntheseEntity.carteDeControle.syntheseLiee.length > 0
                ) {
                    for (let i = 0; i < this.syntheseEntity.carteDeControle.syntheseLiee.length; i++) {
                        const element = this.syntheseEntity.carteDeControle.syntheseLiee[i].synthese;

                        const periodes = [];
                        if (element.listeSyntheseCritereValeurBlocks) {
                            element.listeSyntheseCritereValeurBlocks.map((e) => {
                                e.listeSyntheseCritereValeurLignes.map((f) => {
                                    if (f.typeDeChamp === 5) {
                                        periodes.push({
                                            dateDebut: f.dateDebut,
                                            dateFin: f.dateFin,
                                            periodeGlissante: f.periodeGlissante,
                                            valeurTemporelle: f.valeurTemporelle,
                                            valeurTemporelle2: f.valeurTemporelle2
                                        });
                                    }
                                    return f;
                                });
                                return e;
                            });
                        }
                        const tempList = await this.SyntheseEntitiesService.getReferencesByParamsForSynthese(
                            this.listproduitids,
                            this.listproducteurids,
                            this.listclientids,
                            periodes,
                            element.idFamille,
                            element.typeDeSynthese == 2
                        );
                        // pour mettre au bon format pour le iSteven
                        element.listeReferences = await this.formattingListReferenceByNorme(tempList);
                    }
                }
            } catch (err) {
                console.error('erreur syntheseentities.selection.controller getReferences');
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            }
        }
    }

    async getTrames() {
        const typesSyntheses = await this.getTypeSyntheses();

        let codeDomaine = null;
        if (typesSyntheses) {
            const typeSynthese = typesSyntheses.find((e) => {
                return e.id === this.syntheseEntity.typeDeSynthese;
            });
            if (typeSynthese) {
                codeDomaine = typeSynthese.domaine;
            }
        }

        await this.extractIdsProduitProducteur();

        try {
            this.trames = await this.SyntheseEntitiesService.getTramesForSynthese(codeDomaine, this.listproducteurids);
        } catch (err) {
            console.error('erreur syntheseentities.selection.controller getTrames');
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async loadOperators() {
        try {
            this.operators = await this.SyntheseEntitiesService.getOperators();
        } catch (ex) {
            console.error('erreur syntheseentities.selection.controller loadOperators');
            this.notification.error(ex.data);
        }
    }

    async loadPeriodesGlissantes() {
        try {
            this.periodesGlissantes = await this.SyntheseEntitiesService.getPeriodesGlissantes(this.activite);
        } catch (ex) {
            console.error('erreur syntheseentities.selection.controller loadPeriodesGlissantes');
            this.notification.error(ex.data);
        }
    }

    async getListConformite() {
        //get List des options: Prelevement8conformites
        let liste = [];
        try {
            liste = await this.SyntheseEntitiesService.getListConformite();
        } catch (ex) {
            console.error('erreur syntheseentities.selection.controller getListConformite');
            this.notification.error(ex.data);
        }
        return liste;
    }

    async getOptionCloture() {
        //get List des options: Prelevement_Cloture
        let liste = [];
        try {
            liste = await this.SyntheseEntitiesService.getOptionCloture();
        } catch (ex) {
            console.error('erreur syntheseentities.selection.controller getOptionCloture');
            this.notification.error(ex.data);
        }
        return liste;
    }

    // appel pour les typeAhead pour les lignes o� on enregistre des �l�ments de type IdTable
    async getIstevenListForCrit(crit, block) {
        // pour les conformites/clôture de prélèvement
        if (crit && (crit.libelleTotalChamp == 'Prélèvements / Conformité' || crit.libelleTotalChamp == 'Prélèvements / Clôture')) {
            // mise à jour la liste de conformite/cloture (valeur = true)
            // filtre crit.isteven % crit.selectedEltsTable, pour avoir les libelles qui correspondent aux valeurs des conformite "0,1,2,3,-1"/ cloture "0,1"
            if (crit.selectedEltsTable != null) {
                const list1 = await this.getIdsFromTable(crit, block); // get data from BDD
                const list2 = crit.selectedEltsTable;
                // récupere les libelles (Values from BDD)
                for (var i = 0; i < list1.length; i++) {
                    list2.forEach((b) => {
                        if (b.id == list1[i].id) {
                            b.libelle = list1[i].libelle;
                        }
                    });
                }
                // Distinct les libelles
                const temp = this._.uniqBy(list2, 'libelle');
                let list = [];
                if (crit.libelleTotalChamp == 'Prélèvements / Conformité') {
                    list = await this.getListConformite();
                }
                if (crit.libelleTotalChamp == 'Prélèvements / Clôture') {
                    list = await this.getOptionCloture();
                }
                // Map sur la liste crit.istevenPrelevemnt pour affichage des elements sélectionnés dans massia multi select
                // Attention: si aucune prelevement ne corespond à l'option sélectioné, celle-ci ne sera pas présenté selectionné dans  massia multi select
                for (var i = 0; i < list.length; i++) {
                    temp.forEach((c) => {
                        if (list[i].codeLibelle == c.libelle) {
                            list[i].valeur = true;
                        }
                    });
                }
                crit.istevenPrelevemnt = list;
            } else {
                if (crit.libelleTotalChamp == 'Prélèvements / Conformité') {
                    crit.istevenPrelevemnt = await this.getListConformite();
                }
                if (crit.libelleTotalChamp == 'Prélèvements / Clôture') {
                    crit.istevenPrelevemnt = await this.getOptionCloture();
                }
                crit.isteven = await this.getIdsFromTable(crit, block);
            }
        }
        if (crit) {
            const list = await this.getIdsFromTable(crit, block); // get Data from BDD
            crit.isteven = this.getSelected(crit, list);
        }
    }

    async getTypeSyntheses() {
        let listeTypesSynthese = [];
        try {
            const typeSyntheses = await this.SyntheseEntitiesService.getTypeSyntheses();
            listeTypesSynthese = typeSyntheses.filter((e) => {
                return e.activite === this.activite;
            });
        } catch (err) {
            console.error('erreur syntheseentities.selection.controller getTypeSyntheses');
            if (err.data) {
                this.notification.error(err.data);
            }
        }

        return listeTypesSynthese;
    }

    async getIdsFromTable(critere, block) {
        if (this.syntheseEntity.idFamille) {
            try {
                const res = await this.SyntheseEntitiesService.getIdsFromTable(critere, block, this.syntheseEntity.idFamille);
                return res;
            } catch (ex) {
                console.error('erreur syntheseentities.selection.controller getIdsFromTable');
                this.notification.error(ex.data);
            }
        } else {
            return [];
        }
    }

    getSelected(crit, list) {
        const listIsteven = list;
        if (crit) {
            const listSel = crit.selectedEltsTable;
            if (listIsteven && listIsteven.length > 0 && listSel && listSel.length > 0) {
                let tmp;
                for (let i = 0; i < listSel.length; i++) {
                    const e = listSel[i];
                    tmp = listIsteven.map((x) => {
                        if (x.id === e.id) {
                            x.valeur = true;
                        }
                        return x;
                    });
                }
                return tmp;
            }
        }
        return list;
    }

    deleteCriteresBlock(block) {
        // on supprime l'�l�ment
        const idxDel = this.syntheseEntity.listeSyntheseCritereValeurBlocks.findIndex((e) => {
            return e.id === block.id && e.indiceSelection === block.indiceSelection;
        });
        if (idxDel >= 0) {
            this.syntheseEntity.listeSyntheseCritereValeurBlocks.splice(idxDel, 1);
        }

        // on recale toutes les positions du restant de la liste
        for (let index = 0; index < this.syntheseEntity.listeSyntheseCritereValeurBlocks.length; index++) {
            const element = this.syntheseEntity.listeSyntheseCritereValeurBlocks[index];
            element.indiceSelection = index;
        }
    }

    videDates(crit) {
        crit.dateDebut = null;
        crit.dateFin = null;
    }

    async unselectAll(crit, block) {
        crit.selectedEltsTable = null;
        crit.selectedCritAff = null;
        crit.selectElementTable = null;
        crit.isteven.forEach((element) => {
            element.valeur = false;
        });
        await this.updateForProduitsSiteProd(crit, block);
    }

    async updateForProduitsSiteProd(crit, block) {
        // si on ajoute un produit ou producteur, on recharge la liste des r�f�rences
        if (crit.champ !== 3 && (crit.domaineCode === 'Produits' || crit.domaineCode === 'ProduitsCom' || crit.domaineCode === 'SiteProd')) {
            await this.getReferences();
            //if (crit.domaineCode === 'SiteProd')
            await this.getTrames();
            //si on ajoute un produit, recharger la liste des producteurs
            if (crit.domaineCode === 'Produits' || crit.domaineCode === 'ProduitsCom') {
                let critere = block.listeSyntheseCritereValeurLignes.find((e) => {
                    return e.domaineCode === 'SiteProd';
                });
                if (critere) {
                    await this.getIstevenListForCrit(critere, block);
                }

                if (crit.libelleTotalChamp.includes('Type')) {
                    critere = block.listeSyntheseCritereValeurLignes.find((e) => {
                        return e.domaineCode === 'Produits' && e.libelleTotalChamp.includes('type');
                    });
                    if (critere) {
                        await this.getIstevenListForCrit(critere, block);
                    }
                } else if (crit.libelleTotalChamp.includes('libellé')) {
                    critere = block.listeSyntheseCritereValeurLignes.find((e) => {
                        return e.domaineCode === 'Produits' && e.libelleTotalChamp.includes('libellé');
                    });
                    if (critere) {
                        await this.getIstevenListForCrit(critere, block);
                    }
                }
            }
            //si on ajoute un producteur, recharger la liste des produits
            if (crit.domaineCode === 'SiteProd') {
                const critere = block.listeSyntheseCritereValeurLignes.find((e) => {
                    if (e.domaineCode === 'Produits') {
                        return e.libelleTotalChamp.includes('Libellé') || e.libelleTotalChamp.includes('Code');
                    }
                    return false;
                });
                if (critere) {
                    await this.getIstevenListForCrit(critere, block);
                }

                // Tri des familles bétons par producteur
                const critereFamilleBetons = block.listeSyntheseCritereValeurLignes.find((e) => {
                    return e.domaineCode === 'FamBeton';
                });
                if (critereFamilleBetons) {
                    await this.getIstevenListForCrit(critereFamilleBetons, block);
                }
            }
        }
    }

    async addElementToList(crit, block, table = null, unique = false, list = null, data = null) {
        if (crit && (crit.libelleTotalChamp == 'Prélèvements / Conformité' || crit.libelleTotalChamp == 'Prélèvements / Clôture')) {
            if (crit.selectedEltsTable == null) {
                crit.selectedEltsTable = [];
            }
            if (table.valeur == true) {
                // quand on selectionne, on joute les résultats dans la liste de crit.selectedEltsTable
                var list = crit.isteven.filter((x) => x.libelle == table.codeLibelle);
                if (list.length == 0) {
                    this.notification.info('SYNTHENTS.INFO.NO_RESULTAT');
                } else {
                    crit.selectedEltsTable = this._.concat(crit.selectedEltsTable, list);
                }
            } else {
                // quand on dé-selectionne, on remove les résultats dans la liste de crit.selectedEltsTable
                var list = crit.isteven.filter((x) => x.libelle == table.codeLibelle);
                if (list != null) {
                    const listTemp = crit.selectedEltsTable;
                    for (var i = 0; i < listTemp.length; i++) {
                        list.forEach((e) => {
                            if (listTemp[i].id == e.id) {
                                listTemp.splice(i, 1);
                            }
                        });
                    }
                    crit.selectedEltsTable = listTemp;
                }
            }
            //console.log(crit.selectedEltsTable)
        } else if (crit && table) {
            const values = table.filter((x) => x.valeur);
            crit.selectedEltsTable = values;
        } else {
            if (crit.selectElementTable && crit.selectElementTable.id) {
                if (!crit.selectedEltsTable) {
                    crit.selectedEltsTable = [];
                }
                crit.selectedEltsTable.push(crit.selectElementTable);
                crit.selectElementTable = null;
            }
        }
        await this.updateForProduitsSiteProd(crit, block);
    }

    addBlock() {
        const nbrBlock = this.syntheseEntity.listeSyntheseCritereValeurBlocks.length;
        const critBlock = new SyntheseEntityCritere(nbrBlock, this.syntheseEntity.listeDefaultSyntheseCriteres);
        if (this.syntheseEntity.typeDeSynthese == 2) {
            critBlock.nomComposant = this.$translate.instant('SYNTHENTS.COMPOSANT_NOM', { num: nbrBlock + 1 });
        } else {
            critBlock.nomComposant = this.$translate.instant('SYNTHENTS.SELECTION_NOM', { num: nbrBlock + 1 });
        }
        this.syntheseEntity.listeSyntheseCritereValeurBlocks.push(critBlock);
    }

    // extraire les ids des produits et des producteurs de la liste des crit�res pour recharger la liste des r�f�rences
    extractIdsProduitProducteur() {
        this.listproduitids = [];
        this.listproducteurids = [];
        this.listclientids = [];
        if (
            this.syntheseEntity &&
            this.syntheseEntity.listeSyntheseCritereValeurBlocks &&
            this.syntheseEntity.listeSyntheseCritereValeurBlocks.length > 0
        ) {
            for (let index = 0; index < this.syntheseEntity.listeSyntheseCritereValeurBlocks.length; index++) {
                const block = this.syntheseEntity.listeSyntheseCritereValeurBlocks[index];

                if (block.listeSyntheseCritereValeurLignes && block.listeSyntheseCritereValeurLignes.length > 0) {
                    for (let j = 0; j < block.listeSyntheseCritereValeurLignes.length; j++) {
                        const ligne = block.listeSyntheseCritereValeurLignes[j];

                        // si le crit�re est un type de champ IdTable ET que ce n'est pas une caract�ristique
                        if (ligne.typeDeChamp === 7 && ligne.champ !== 3) {
                            // si c'est un critère produit
                            if (ligne.domaineCode === 'Produits') {
                                if (ligne.selectedEltsTable && ligne.selectedEltsTable.length > 0) {
                                    const tempListIds1 = ligne.selectedEltsTable.map((a) => a.id);
                                    this.listproduitids = this.listproduitids.concat(tempListIds1);
                                }
                            }

                            // si c'est un crit�re producteur
                            if (ligne.domaineCode === 'SiteProd') {
                                if (ligne.selectedEltsTable && ligne.selectedEltsTable.length > 0) {
                                    const tempListIds2 = ligne.selectedEltsTable.map((a) => a.id);
                                    this.listproducteurids = this.listproducteurids.concat(tempListIds2);
                                }
                            }

                            // si c'est un crit�re client
                            if (ligne.domaineCode === 'SiteClient') {
                                if (ligne.selectedEltsTable && ligne.selectedEltsTable.length > 0) {
                                    const tempListIds2 = ligne.selectedEltsTable.map((a) => a.id);
                                    this.listclientids = this.listclientids.concat(tempListIds2);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // pour formatter la liste des r�f�rences par norme pour �tre utilis�e dans le iSteven
    formattingListReferenceByNorme(listToFormat) {
        let tmp = null;
        const temp = [];
        const toReturn = [];
        for (let index = 0; index < listToFormat.length; index++) {
            const currentValue = listToFormat[index];
            if (tmp === null) {
                tmp = currentValue.normeId;
                toReturn.push({
                    libelle: '<span style="font-weight:bold">' + currentValue.normeCoLib + '</span>',
                    msGroup: true,
                    normeId: currentValue.normeId,
                    valeur: false
                });
            } else {
                if (tmp !== currentValue.normeId) {
                    toReturn.push({
                        msGroup: false,
                        valeur: false
                    });
                    tmp = currentValue.normeId;
                    toReturn.push({
                        libelle: '<span style="font-weight:bold">' + currentValue.normeCoLib + '</span>',
                        msGroup: true,
                        normeId: currentValue.normeId,
                        valeur: false
                    });
                }
            }
            const newlist = {
                valeur: false,
                referenceId: currentValue.id,
                normeId: currentValue.normeId,
                libelle: currentValue.libelle + ' [' + currentValue.code + ']',
                normeLibelle: currentValue.normeCoLib
            };

            toReturn.push(newlist);

            if (index === listToFormat.length - 1) {
                toReturn.push({
                    msGroup: false,
                    valeur: false
                });
            }
        }
        temp.push(toReturn);
        const result = _.flatten(temp);

        return new Promise((resolve) => resolve(result));
    }

    parseDate(dateString) {
        let res = false;
        if (dateString) {
            const date = this.moment(dateString, this.dateFormat);
            if (date) {
                res = date instanceof Date && !isNaN(date);
            }
        }

        return res;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}

SyntheseEntitiesSelectionController.$inject = [
    '_',
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'notification',
    'moment',
    '$uibModal',
    '$timeout',
    'MassiaApplicationService',
    'SyntheseEntitiesService',
    'SyntheseEntitiesCommunicationService',
    'globalizationManagementService'
];
