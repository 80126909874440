const positionsLegendeByValue = {
    0: 'pas_legende',
    1: 'droite',
    2: 'gauche',
    3: 'haut',
    4: 'bas'
};

const positionsLegendeValuesByName = {
    pas_legende: 0,
    droite: 1,
    gauche: 2,
    haut: 3,
    bas: 4
};

export function getPositionLegendeNamesByValues() {
    return angular.copy(positionsLegendeByValue);
}

export function getPositionLegendeNamesWithValues() {
    return angular.copy(positionsLegendeValuesByName);
}
