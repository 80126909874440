import TgapExclureConfig from './_config/tgap.exclure.config';
import TgapExclureRoutes from './_config/tgap.exclure.routes';
import TgapExclureComponent from './components/tgap-exclure';
import TgapExclureService from './services/tgap.exclure.service';
import TgapExclureCommunicationService from './services/tgap.exclure.communication.service';

const moduleName = 'app.parametrageGeneral.tgap.exclure';

angular
    .module(moduleName, [])
    .config(TgapExclureConfig)
    .config(TgapExclureRoutes)
    .service('TgapExclureService', TgapExclureService)
    .service('TgapExclureCommunicationService', TgapExclureCommunicationService)
    .component('tgapExclure', TgapExclureComponent);

export default moduleName;
