import validatorBuilder from 'validator-builder';

let templateImpressionValidator = null;

export default class DocumentModal {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.idType = data.idType || null;
        this.libelleType = data.libelleType || null;
        this.estCodeAuto = data.estCodeAuto || null;

        this.drive = data.drive || null;
        this.directory = data.directory || null;
        this.extension = data.extension;
        this.dateCreation = data.dateCreation || null;
        this.size = data.size;
    }
    isValid() {
        templateImpressionValidator = templateImpressionValidator || validatorBuilder.getInstanceFor('DocumentModal');
        const validationResults = templateImpressionValidator.validate(this);
        return validationResults.isValid;
    }
}
