import CommandeLigneController from './commande.ligne.controller';

export default {
    bindings: {
        index: '=',
        groupe: '=',
        scl: '=',
        commande: '=',
        chantierid: '=',
        recalculeLignes: '&',
        hasTransport: '='
    },
    template: require('./commande.ligne.html'),
    controller: CommandeLigneController
};
