import SitePiecesFacturationController from './site.pieces.facturation.controller';

export default {
	bindings: {
		site: '<',
		onUpdate: '&',
		total: '='
	},
	template: require('./site.pieces.facturation.html'),
	controller: SitePiecesFacturationController
};
