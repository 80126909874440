import CampagneCarteController from './campagne.carte.controller';

export default {
    bindings: {
        entete: '=',
        modeLocalisation: '<',
        modalInstance: '=',
        listeSaisies: '<'
    },
    template: require('./campagne.carte.html'),
    controller: CampagneCarteController
};
