import _ from 'lodash';

(function (angular, _, undefined) {
    'use strict';

    angular.module('blocks.lodash')
        .constant('_', _);

    _.mixin({
        'findNested': findNested,
        'findDeep': findDeep,
        'findByValues': findByValues,
        'getDistinctPropertyValues': getDistinctPropertyValues,
        'sortByLowerCase': sortByLowerCase
    });

    /** Filter collection using array of values
     * var filtered = _.findByValues(collections, "id", [1,3,4]);
     */
    function findByValues(collection, property, values) {
        return _.filter(collection, function (item) {
            return _.includes(values, item[property]);
        });
    }

    /** Permet de trouver une propriété profondément enfouie dans une collection
     *  exemple : _.findNested({'aa': 1, 'bb': 2, 'cc': {'d':{'x':9}}, dd:{'d':{'y':9}}}, 'd');
     *  résultat : [{x: 9}, {y: 9}]
     */
    function findNested(obj, key, memo) {
        var i, proto = Object.prototype, ts = proto.toString;
        ('[object Array]' !== ts.call(memo)) && (memo = []);
        for (i in obj) {
            if (proto.hasOwnProperty.call(obj, i)) {
                if (i === key) {
                    memo.push(obj[i]);
                } else if ('[object Array]' === ts.call(obj[i]) || '[object Object]' === ts.call(obj[i])) {
                    findNested(obj[i], key, memo);
                }
            }
        }
        return memo;
    }

    function findDeep(items, attrs) {

        function match(value) {
            if (value) {
                for (var key in attrs) {
                    if (!_.isUndefined(value) && value != null) {
                        if (attrs[key] !== value[key]) {
                            return false;
                        }
                    }
                }
                return true;
            }
            else {
                return false;
            }
        }

        function traverse(value) {
            var result;
            _.forEach(value, function (val) {
                if (match(val)) {
                    result = val;
                    return false;
                }

                if (_.isObject(val) || _.isArray(val)) {
                    result = traverse(val);
                }

                if (result) {
                    return false;
                }
            });

            return result;
        }

        return traverse(items);
    }

    /***
     * Retourne une liste de valeurs distinctes de la propriété donnée dans un tableau donné
     * @param items - tableau d'objets
     * @param property - propriété souhaitée
     * @returns {Array}
     */
    function getDistinctPropertyValues(items, property) {
        var result = _.map(items, property);
        var resultuniq = _.uniq(result);
        return _.sortBy(resultuniq);
    }

    /***
     * Retourne un tableau trié par sa propriété en minuscule
     * @param items
     * @param property
     * @returns {Array}
     */
    function sortByLowerCase(items, property) {
        return _.sortBy(items, function (item) {
            return item[property].toLowerCase();
        });
    }
})(angular, window._);