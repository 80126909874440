import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-slider-detail',
    bindings: {
        ongletOpen: '=',
        title: '@',
        loading: '<'
    },
    template: require('./massia.slider.detail.tpl.html'),
    transclude: true
})
export class MassiaSliderDetailController {
    constructor() {}

    $onInit() {}
}
