//Config
import SeriesTamisConfig from './_config/series.tamis.config';
import SeriesTamisRoutes from './_config/series.tamis.routes';
import SeriesTamisLaboRoutes from './_config/series.tamis.labo.routes';

//Components
import SeriesTamisComponent from './components/series-tamis';
import SerieTamisDetailComponent from './components/serie-tamis-detail';
import SerieTamisFormComponent from './components/serie-tamis-form';
import SerieTamisFormCaracteristiquesComponent from './components/serie-tamis-form/caracteristiques';

//Services
import SeriesTamisService from './services/series.tamis.service';
import SeriesTamisTypesService from './services/series.tamis.types.service';
import SeriesTamisCommunicationService from './services/series.tamis.communication.service';

//Validateurs
import SerieTamisValidator from './components/serie-tamis-form/serie.tamis.validator';
import SerieTamisCaracteristiquesValidator from './components/serie-tamis-form/caracteristiques/serie.tamis.caracteristiques.validator';

const moduleName = 'app.massia.common.series.tamis';

angular
    .module(moduleName, [])
    .config(SeriesTamisConfig)
    .config(SeriesTamisRoutes)
    .config(SeriesTamisLaboRoutes)
    .service('SeriesTamisService', SeriesTamisService)
    .service('SeriesTamisTypesService', SeriesTamisTypesService)
    .service('SeriesTamisCommunicationService', SeriesTamisCommunicationService)
    .component('seriesTamis', SeriesTamisComponent)
    .component('serieTamisDetail', SerieTamisDetailComponent)
    .component('serieTamisForm', SerieTamisFormComponent)
    .component('serieTamisFormCaracteristiques', SerieTamisFormCaracteristiquesComponent)
    .factory('SerieTamisValidator', SerieTamisValidator)
    .factory('SerieTamisCaracteristiquesValidator', SerieTamisCaracteristiquesValidator);

export default moduleName;
