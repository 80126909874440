import CaracteristiqueController from './caracteristique.controller';

export default {
    bindings: {
        docce: '<',
        form: '=',
        onUpdate: '&',
        normes: '=',
        certif: '<'
    },
    template: require('./caracteristique.html'),
    controller: CaracteristiqueController
};
