export default class PrestationDetailController {
    static $inject = ['$stateParams', '$state', 'PrestationsService', 'notification', 'PrestationsCommunicationService'];

    constructor($stateParams, $state, PrestationsService, notification, PrestationsCommunicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.PrestationsService = PrestationsService;
        this.notification = notification;
        this.PrestationsCommunicationService = PrestationsCommunicationService;
    }

    $onInit() {
        this.loading = false;
        this.prestation = {};
        this.prestationId = undefined;
        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.initPrestation();
    }

    async initPrestation() {
        this.prestationId = this.$stateParams.id;

        this.startLoading();
        try {
            await this.getPrestationEntete(this.prestationId);
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('prestations.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getPrestationEntete(prestationId) {
        this.prestation.entete = await this.PrestationsService.getPrestationEnteteById(prestationId);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
