export default function Configure($stateProvider) {
    $stateProvider.state('compte-ouverts', {
        parent: 'common',
        url: '/compte-ouverts',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('compte-ouverts.list', {
        url: '/list',
        template: '<compte-ouverts-export></compte-ouverts-export>',
        rights: { domain: 'centregestion', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "SITES.COMPTE_OUVERTS" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'SITES.COMPTE_OUVERTS',
            order: 74
        },
        search: 'SITES.COMPTE_OUVERTS'
    });
}

Configure.$inject = ['$stateProvider'];
