TransportEnteteValidator.$inject = ['validator'];

export default function TransportEnteteValidator(validator) {
    const instance = new validator();

    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    return instance;
}
