export default class SyntheseEntitiesCarteDeControleController {
    /* @ngInject */
    constructor($scope, $state, $stateParams, SyntheseEntitiesService, MassiaApplicationService, $filter, $translate) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$filter = $filter;
        this.$translate = $translate;
    }

    async $onInit() {
        this.ongletOpen = {
            isCarteDeControlesOpen: true,
            isEnteteOpen: true,
            isListeSelectionOpen: true
        };
        if (!this.syntheseEntity.carteDeControle.syntheseLiee) {
            this.syntheseEntity.carteDeControle.syntheseLiee = [];
        }

        this.activite = this.MassiaApplicationService.getApplication();

        await this.loadSynthese();

        if (
            this.syntheseEntity &&
            this.syntheseEntity.carteDeControle &&
            this.syntheseEntity.carteDeControle.syntheseLiee &&
            this.syntheseEntity.carteDeControle.syntheseLiee.length > 0
        ) {
            for (let i = 0; i < this.syntheseEntity.carteDeControle.syntheseLiee.length; i++) {
                const element = this.syntheseEntity.carteDeControle.syntheseLiee[i];
                element.ongletOpen = {};
                element.ongletOpen = {
                    isListeSelectionOpen: true,
                    isControlesOpen: true,
                    isEssaisOpen: true
                };
            }
        }
    }

    async loadSynthese() {
        try {
            this.startLoading();
            if (this.syntheseEntity.typeDeSynthese) {
                await this.iStevenSynthese();
            }
            this.stopLoading();
        } catch (error) {
            console.error(error);
        }
    }

    async iStevenSynthese() {
        const res = await this.SyntheseEntitiesService.getSyntheseEntities([], [], { skip: 0, take: 0 }, this.activite);
        for (let i = 0; i < res.items.length; i++) {
            res.items[i].typeDeSyntheseLibelle = this.$translate.instant(res.items[i].typeDeSyntheseLibelle);
        }
        const grouped = res.items.filter((x) => x.typeDeSynthese === 1 || x.typeDeSynthese === 5).groupBy((x) => x.typeDeSyntheseLibelle);
        const result = [];
        for (const key in grouped) {
            if (grouped.hasOwnProperty(key)) {
                const element = grouped[key];
                result.push({
                    libelle: key,
                    list: element
                });
            }
        }
        this.syntheseTrie = this.$filter('istevenFormat')(result, 'libelle', 'list');
    }

    async selectItem() {
        const res = [];
        for (let i = 0; i < this.dataSelected.length; i++) {
            const element = this.dataSelected[i];
            const tmp = {
                idSynthese: element.id,
                synthese: await this.getSynthese(element.id, this.that),
                libelle: element.libelle,
                typeDeSynthese: element.typeDeSyntheseLibelle
            };
            res.push(tmp);
        }
        this.syntheseEntity.carteDeControle.syntheseLiee = res;
        this.refresh({
            that: this.that
        });
    }

    $onDestroy() {}

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
