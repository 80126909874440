export default function HttpPendingRequestsConfigurator($window, HttpPending) {
    angular.element($window).on('beforeunload', beforeUnload);

    function beforeUnload() {
        // avant de refresh, on annule toutes les requêtes HTTP non terminées
        HttpPending.cancelAll();
    }
}

HttpPendingRequestsConfigurator.$inject = ['$window', 'HttpPending'];
