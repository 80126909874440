import Signature from './mini.form.signature.model';

export default class SignatureFormController {
    static $inject = ['_', '$scope', 'ImagesService', 'MassiaApplicationService', 'notification'];

    newSignature = new Signature();
    displayModal = false;

    constructor(_, $scope, ImagesService, MassiaApplicationService, notification) {
        this._ = _;
        this.$scope = $scope;
        this.ImagesService = ImagesService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.notification = notification;
        this.codeLoading = false;
        this.codeExists;
        this.displayModal = false;
    }

    async $onInit() {}

    async valider() {
        this.newSignature.idType = await this.ImagesService.getIdTypeSignature();
        if (this.checkValidity()) {
            try {
                this.newSignature.id = await this.ImagesService.createSignature(this.newSignature);
                await this.ImagesService.onFileSelect(this.newSignature.file, this.newSignature.id, this.newSignature.code);
                this.personne.idSignature = this.newSignature.id;
                this.selectNewSignature();
                this.closeModal();
            } catch (ex) {
                this.notification.error(ex.data);
            }
        }
    }

    checkValidity() {
        let validity = true;
        if (!this.newSignature.isValid()) {
            validity = false;
            this.$scope.$broadcast('signatureValidations');
        }

        return validity;
    }

    closeModal() {
        this.displayModal = false;
        this.newSignature = new Signature();
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.codeExists = await this.ImagesService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.entete.codeExists = null;
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }

    async onFileSelect($files) {
        this.newSignature.file = $files;
    }
}
