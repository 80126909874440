import ImportBlRoute from './_config/import.bl.route';
import ImportBlConfiguration from './_config/import.bl.config';
import ImportBlService from './services/import.bl.service';
import ImportBlFormComponent from './components/import-bl-form';
import importBlFichierPopupComponent from './components/import-bl-fichier-popup';

const moduleName = 'app.massia.gestion.import.bl.externes';

angular
    .module(moduleName, [])
    .config(ImportBlConfiguration)
    .config(ImportBlRoute)
    .service('ImportBlService', ImportBlService)
    .component('importBlForm', ImportBlFormComponent)
    .component('importBlFichierPopup', importBlFichierPopupComponent);

export default moduleName;
