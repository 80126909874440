import ApiService from '../../app/services/api.service';
import { Injectable } from '../decorators';
import { BaseHttpService } from '../interface/MassiaHttpService.interface';
import { SeuilBetGachDetail } from '../models/seuil-bet-gach/SeuilBetGachDetail';

@Injectable('SeuilBetGachService')
export class SeuilBetGachService extends BaseHttpService<SeuilBetGachDetail, SeuilBetGachDetail, SeuilBetGachDetail, SeuilBetGachDetail, any> {
    /* @ngInject */
    constructor(ApiService: ApiService) {
        super(ApiService);
        this.url = 'v2/massia/seuil-bet-gachs';
    }
}
