export default class FeuilleRouteController {
    static $inject = ['$state', '$stateParams', 'notification', 'ModalService', '$uibModal'];

    constructor($state, $stateParams, notification, ModalService, $uibModal) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.notification = notification;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        const _this = this;
        //_this.source = this.cellule;
        this.$uibModal
            .open({
                template: '<feuille-route-popup modal-instance="$ctrl.uibModalInstance"></feuille-route-popup>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        //$ctrl.source = _this.source;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xl',
                backdrop: false
            })
            .result.then(
                function (result) {
                    //console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    //console.info(result);
                    _this.$state.go('gestion.home');
                },
                function (reason) {
                    //console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                    _this.$state.go('gestion.home');
                }
            );
    }
}
