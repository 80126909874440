(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.treeview')
        .directive('acTreeviewNode', acTreeviewNode);

    acTreeviewNode.$inject = ['RecursionHelper', '$templateCache'];

    /* @ngInject */
    function acTreeviewNode(RecursionHelper, $templateCache) {
        var directive = {
            restrict: 'A',
            template: function () {
                return $templateCache.get('blocks/treeview/ac.treeview.node.tpl.html');
            },
            compile: function (element) {
                return RecursionHelper.compile(element, function (scope, iElement, iAttrs, controller, transcludeFn) {
                    scope.parentFolder = scope.folder;
                });
            }
        };
        return directive;
    }
})(angular);