export default class PrelevementsConfigImpressionService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
        this.serviceUrl = `${__configuration.apiUrl}/massia/prelevements/{idPlv}/configprint`;
    }

    async getEtatEssaiList() {
        const res = await this.$http.get('assets/enum/prelevement-impression/impression.etat.essai.enum.json');
        return res.data;
    }

    async getListOrigineImpression() {
        const res = await this.$http.get('assets/enum/prelevement-impression/impression.essai.enum.json');
        return res.data;
    }

    async getListCertificationProduitOptions() {
        const res = await this.$http.get('assets/enum/certifications/print.certificat.produit.enum.json');
        return res.data;
    }

    async getListCertificationEssaiOptions() {
        const res = await this.$http.get('assets/enum/certifications/print.certificat.essai.enum.json');
        return res.data;
    }

    async getAllConfiguration(idPlv) {
        const url = `${this.serviceUrl.replace('{idPlv}', idPlv)}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getOneConfiguration(idPlv, id) {
        const url = `${this.serviceUrl.replace('{idPlv}', idPlv)}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createConfiguration(idPlv, data) {
        try {
            const url = `${this.serviceUrl.replace('{idPlv}', idPlv)}`;
            const res = await this.$http.post(url, data);
            return res.data;
        } catch (e) {
            throw e.data;
        }
    }

    async updateConfiguration(idPlv, idConfig, data) {
        try {
            const url = `${this.serviceUrl.replace('{idPlv}', idPlv)}/${idConfig}`;
            const res = await this.$http.put(url, data);
            return res.data;
        } catch (e) {
            throw e.data;
        }
    }

    async deleteConfiguration(idPlv, idConfig) {
        const url = `${this.serviceUrl.replace('{idPlv}', idPlv)}/${idConfig}`;
        await this.$http.delete(url);
        return true;
    }

    async getCertificatProduit(idCertififProduit) {
        const url = `${__configuration.apiUrl}/massia/laboratoire/certificat-produit/${idCertififProduit}`;
        const res = await this.$http.get(url);
        return res.data;
    }
    async getImagesListe() {
        const url = `${__configuration.apiUrl}/massia/images`;
        const res = await this.$http.get(url);
        return res.data.items;
    }

    async getIconeBase64(icone) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/images/${icone.id}`);
        return res.data;
    }

    async getAllEssaisFromReference(listSsEssais, idTrame = -1) {
        const data = await this.$http.post(`${__configuration.apiUrl}/massia/essais/data/${idTrame}`, listSsEssais);
        return data.data;
    }
}

PrelevementsConfigImpressionService.$inject = ['$http'];
