const serviceUrl = `${__configuration.apiUrl}/massia/performance/zone-abattages`;

export default class ZoneAbattagesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getZoneAbattages(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async deleteZoneAbattageById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getZoneAbattageById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSites(application) {
        const url = `${__configuration.apiUrl}/massia/sites-producteurs/headers/${application}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createZoneAbattage(selectedZoneAbattage) {
        const data = {
            id: selectedZoneAbattage.id,
            code: selectedZoneAbattage.code,
            libelle: selectedZoneAbattage.libelle,
            idSite: selectedZoneAbattage.idSite
        };

        const result = await this.$http.post(serviceUrl, data);
        return result.data;
    }

    async updateZoneAbattage(selectedZoneAbattage) {
        const data = {
            id: selectedZoneAbattage.id,
            code: selectedZoneAbattage.code,
            libelle: selectedZoneAbattage.libelle,
            idSite: selectedZoneAbattage.idSite
        };

        const url = `${serviceUrl}/${data.id}`;
        return this.$http.put(url, data);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }
}
