const serviceUrl = `${__configuration.apiUrl}/massia/compte-ouverts`;

export default class CentreGestionService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getGenres() {
        const url = 'assets/enum/sites/genre.json';
        const res = await this.$http.get(url);
        return res.data;
    }

    async print(idGenres) {
        const url = `${serviceUrl}/print`;
        const filtres = {
            idGenres: idGenres
        };
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {})
            },
            responseType: 'arraybuffer'
        });
        return res;
    }
}
