//Config
import ProduitsConfig from './_config/produits.config';
import ProduitsRoutes from './_config/produits.routes';

//Services
import ProduitsService from './services/produits.service';
import ProduitsTypesService from './services/produits.types.service';
import ProduitsCommunicationService from './services/produits.communication.service';
import ProduitNiveauxTrameService from './components/produit-config-niveaux/produit.niveaux.trame.service';
import ProduitNiveauxElementsService from './components/produit-config-niveaux/produit.niveaux.elements.service';
import AppellationsCommercialesService from './services/appellations.service';
import ProduitStockNiveauxTrameService from './components/produit-stock-config-niveaux/produit.stock.niveaux.trame.service';
import ProduitStockNiveauxElementsService from './components/produit-stock-config-niveaux/produit.stock.niveaux.elements.service';

//Components
import ProduitsComponent from './components/produits';
import ProduitDetailComponent from './components/produit-detail';

import ProduitGenerationNormeComponent from './components/produit-generation-norme';
import ProduitGenerationNormeEnteteComponent from './components/produit-generation-norme/entete';

import ProduitFormComponent from './components/produit-form';
import ProduitFormEnteteComponent from './components/produit-form/entete';
import ProduitFormCaracteristiquesComponent from './components/produit-form/caracteristiques';
import ProduitFormNiveauxComponent from './components/produit-form/niveaux';
import ProduitFormNiveauxStocksComponent from './components/produit-form/niveaux-stocks';
import ProduitFormSpecificitesComponent from './components/produit-form/specificites';
import ProduitConfigNiveauxComponent from './components/produit-config-niveaux';
import ProduitStockConfigNiveauxComponent from './components/produit-stock-config-niveaux';
import TypesNiveauxHierarchique from './components/produit-config-niveaux/produit.niveaux.trame.constant';
import AppellationsCommercialesComponent from './components/produit-form/appellations';
import ProduitFormFormulesComponent from './components/produit-form/formules';
import ProduitFormSiteLiesComponent from './components/produit-form/site-lies';
import ProduitFormSocieteProduitComponent from './components/produit-form/societe-produit';
import ProduitFormSiteLiesLaboComponent from './components/produit-form/site-lies-labo';
import produitsCommerciaux from './components/produit-form/produits-commerciaux';
import ProduitFormProducteursComponent from './components/produit-form/producteurs';
import ProduitFormProducteursValeurReferenceComponent from './components/produit-form/producteurs/valeur-reference';
import ProduitFormQuotaBitumeComponent from './components/produit-form/quota-bitume';
import ProduitFormEcoOrganismeComponent from './components/produit-form/eco-organisme';
import ProduitFormProducteursCertificationComponent from './components/produit-form/producteurs/certification';
import ProduitCopyModalMixdesignComponent from './components/produit-copy-modal-mixdesign';
import ProduitsCommerciauxModalComponent from './components/produit-form/produits-commerciaux-calcul-modal';
import ProduitFormProducteursValeurReferenceCubeModal from './components/produit-form/producteurs/eqiom-mise-au-cube-modal';
import ProduitFormCoefficientkComponent from './components/produit-form/coefficientk';
import ProduitFormAssocieComponent from './components/produit-form/associes';

import entete from './components/produit-form/components/entete/entete.controller';
import caracteristique from '../caracteristiques/components/caracteristique-component/caracteristique.controller';

//Validators
import ProduitValidator from './components/produit-form/produit.validator';
import ProduitEnteteValidator from './components/produit-form/entete/produit.entete.validator';
import ProduitEnteteNormeValidator from './components/produit-generation-norme/entete/produit.entete.norme.validator';
import ProduitCaracteristiquesValidator from './components/produit-form/caracteristiques/produit.caracteristiques.validator';
import caracteristiqueTextController from '../caracteristiques/components/caracteristique-component/caracteristique-text/caracteristique-text.controller';
import caracteristiqueBoolController from '../caracteristiques/components/caracteristique-component/caracteristique-bool/caracteristique-bool.controller';
import caracteristiqueDateController from '../caracteristiques/components/caracteristique-component/caracteristique-date/caracteristique-date.controller';
import caracteristiqueListeController from '../caracteristiques/components/caracteristique-component/caracteristique-liste/caracteristique-liste.controller';
import caracteristiqueNumeriqueController from '../caracteristiques/components/caracteristique-component/caracteristique-numerique/caracteristique-numerique.controller';
import caracteristiqueTextLongController from '../caracteristiques/components/caracteristique-component/caracteristique-texte-long/caracteristique-texte-long.controller';
import caracteristiqueListTextController from '../caracteristiques/components/caracteristique-component/caracteristique-list-text/caracteristique-list-text.controller';
import caracteristiqueListDateController from '../caracteristiques/components/caracteristique-component/caracteristique-list-date/caracteristique-list-date.controller';
import caracteristiqueListElementController from '../caracteristiques/components/caracteristique-component/caracteristique-list-element/caracteristique-list-element.controller';
import caracteristiqueListNumeriqueController from '../caracteristiques/components/caracteristique-component/caracteristique-list-numerique/caracteristique-list-numerique.controller';
import mixDesignController from './components/produit-form/mix-design/mix-design.controller';
import ProduitCoefficientkValidator from './components/produit-form/coefficientk/produit.coefficientk.validator';

const moduleName = 'app.massia.common.produits';

angular
	.module(moduleName, [])
	.config(ProduitsConfig)
	.config(ProduitsRoutes)
	.constant('TypesNiveauxHierarchique', TypesNiveauxHierarchique)
	.service('ProduitsService', ProduitsService)
	.service('ProduitsTypesService', ProduitsTypesService)
	.service('ProduitsCommunicationService', ProduitsCommunicationService)
	.service('ProduitNiveauxTrameService', ProduitNiveauxTrameService)
	.service('ProduitNiveauxElementsService', ProduitNiveauxElementsService)
	.service('AppellationsCommercialesService', AppellationsCommercialesService)
	.service('ProduitStockNiveauxTrameService', ProduitStockNiveauxTrameService)
	.service('ProduitStockNiveauxElementsService', ProduitStockNiveauxElementsService)
	.component('produits', ProduitsComponent)
	.component('produitForm', ProduitFormComponent)
	.component('produitGenerationNorme', ProduitGenerationNormeComponent)
	.component('produitGenerationNormeEntete', ProduitGenerationNormeEnteteComponent)
	.component('produitDetail', ProduitDetailComponent)
	.component('produitFormEntete', ProduitFormEnteteComponent)
	.component('produitFormCaracteristiques', ProduitFormCaracteristiquesComponent)
	.component('produitFormNiveaux', ProduitFormNiveauxComponent)
	.component('produitFormNiveauxStocks', ProduitFormNiveauxStocksComponent)
	.component('produitFormSpecificites', ProduitFormSpecificitesComponent)
	.component('produitConfigNiveaux', ProduitConfigNiveauxComponent)
	.component('produitStockConfigNiveaux', ProduitStockConfigNiveauxComponent)
	.component('appellationsCommerciales', AppellationsCommercialesComponent)
	.component('produitFormFormules', ProduitFormFormulesComponent)
	.component('produitFormSiteLies', ProduitFormSiteLiesComponent)
	.component('produitFormSocieteProduit', ProduitFormSocieteProduitComponent)
	.component('produitFormCommerciaux', produitsCommerciaux)
	.component('produitFormCommerciauxCalculModal', ProduitsCommerciauxModalComponent)
	.component('produitFormProducteurs', ProduitFormProducteursComponent)
	.component('produitFormProducteursValeurReference', ProduitFormProducteursValeurReferenceComponent)
	.component('produitFormQuotaBitume', ProduitFormQuotaBitumeComponent)
	.component('produitFormEcoOrganisme', ProduitFormEcoOrganismeComponent)
	.component('produitFormProducteursValeurReferenceCubeModal', ProduitFormProducteursValeurReferenceCubeModal)
	.component('produitFormProducteursCertification', ProduitFormProducteursCertificationComponent)
	.component('produitFormSiteLiesLabo', ProduitFormSiteLiesLaboComponent)
	.component('produitCopyModalMixDesign', ProduitCopyModalMixdesignComponent)
	.component('produitFormCoefficientk', ProduitFormCoefficientkComponent)
	.component('produitEntete', entete)
	.component('produitCaracteristique', caracteristique)
	.component('caractText', caracteristiqueTextController)
	.component('caractBool', caracteristiqueBoolController)
	.component('caractDate', caracteristiqueDateController)
	.component('caractListe', caracteristiqueListeController)
	.component('caractNumerique', caracteristiqueNumeriqueController)
	.component('caractTextLong', caracteristiqueTextLongController)
	.component('caractListText', caracteristiqueListTextController)
	.component('caractListDate', caracteristiqueListDateController)
	.component('caractListElement', caracteristiqueListElementController)
	.component('caractListNumerique', caracteristiqueListNumeriqueController)
	.component('produitMixDesign', mixDesignController)
	.component('produitFormAssocies', ProduitFormAssocieComponent)
	.factory('ProduitValidator', ProduitValidator)
	.factory('ProduitEnteteValidator', ProduitEnteteValidator)
	.factory('ProduitEnteteNormeValidator', ProduitEnteteNormeValidator)
	.factory('ProduitCaracteristiquesValidator', ProduitCaracteristiquesValidator)
	.factory('ProduitCoefficientkValidator', ProduitCoefficientkValidator);

export default moduleName;
