import { equals, IAugmentedJQuery } from 'angular';
import { Chart, ChartDataset, ChartItem, ChartOptions, ChartTypeRegistry } from 'chart.js';
import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '../../../core/decorators';

@Component({
    selector: 'chart'
})
export class ChartComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input('@') chartId: string | number;
    @Input() chartType: keyof ChartTypeRegistry;
    @Input() chartLabels: unknown[] | undefined;
    @Input() chartDatasets: ChartDataset[];
    @Input() chartOptions: ChartOptions;
    private chart: Chart;
    constructor(private $element: IAugmentedJQuery) {
        'ngInject';
    }
    ngOnInit() {
        if (!this.chartId) throw new Error('ChartId cannot be null');
    }
    ngOnDestroy() {
        this.chart && this.chart.destroy();
        this.chart = null;
    }
    ngOnChanges(changes: SimpleChanges<any>): void {
        if (this.chart) {
            if (changes.chartDatasets && !equals(changes.chartDatasets.previousValue, changes.chartDatasets.currentValue)) {
                this.chart.data.datasets = changes.chartDatasets.currentValue;
            }
            if (changes.chartOptions && !equals(changes.chartOptions.previousValue, changes.chartOptions.currentValue)) {
                this.chart.options = changes.chartOptions.currentValue;
            }
            if (changes.chartLabels && !equals(changes.chartLabels.previousValue, changes.chartLabels.currentValue)) {
                this.chart.data.labels = changes.chartLabels.currentValue;
            }
            if (changes.chartType && !equals(changes.chartType.previousValue, changes.chartType.currentValue)) {
                this.chart.destroy();
                this.createChart();
            }
            this.chart && this.chart.update();
        }
    }
    ngAfterViewInit(): void {
        this.createChart();
    }

    createChart() {
        const canvas = document.createElement('canvas');
        canvas.id = `${this.chartId}`;
        canvas.style.width = '100%';
        canvas.style.height = '100%';
        this.$element.append(canvas);
        const myChart: ChartItem = <HTMLCanvasElement>document.getElementById(`${this.chartId}`);
        if (!myChart) {
            console.warn('[Chart] : No canvas found');
        }
        //console.log(this.chart, myChart);
        if (!this.chart && myChart) {
            this.chart = new Chart(myChart.getContext('2d'), {
                type: this.chartType,
                data: {
                    labels: this.chartLabels,
                    datasets: this.chartDatasets
                },
                options: this.chartOptions
            });
        }
    }
}
