import validatorBuilder from 'validator-builder';
import SiteEntete from './entete/site.entete.model';
import SiteObjectif from './objectif/site.objectif.model';
import SiteIndicateur from './indicateur/site.indicateur.model';
import SiteCaracteristiquesClient from './caracteristiques-client/site.caracteristiques.client.model';
import SiteCaracteristiquesCommercial from './caracteristiques-commercial/site.caracteristiques.commercial.model';
import SiteCaracteristiquesFournisseur from './caracteristiques-fournisseur/site.caracteristiques.fournisseur.model';
import SiteCaracteristiquesProducteur from './caracteristiques-producteur/site.caracteristiques.producteur.model';
import SiteCaracteristiquesTransporteur from './caracteristiques-transporteur/site.caracteristiques.transporteur.model';
import SiteCaracteristiquesLaboratoire from './caracteristiques-laboratoire/site.caracteristiques.laboratoire.model';
import SiteCaracteristiquesProprietaire from './caracteristiques-proprietaire/site.caracteristiques.proprietaire.model';
import SiteCaracteristiquesLocataire from './caracteristiques-locataire/site.caracteristiques.locataire.model';
import SiteCaracteristiquesAuditeur from './caracteristiques-auditeur/site.caracteristiques.auditeur.model';

let siteValidator = null;

export default class Site {
    constructor(data = {}, application) {
        this.id = data.id;
        this.entete = new SiteEntete(data.entete);
        this.etatCompteSites = this.entete.etatCompteSites;
        this.objectif = application == 'gestion' ? new SiteObjectif(data.objectifs) : undefined;
        this.indicateur = application == 'performance' ? new SiteIndicateur(data.indicateurs) : undefined;
        this.caracteristiquesUtilisateur =
            application == 'performance' || application == 'gestion'
                ? new SiteCaracteristiquesCommercial(data.caracteristiquesUtilisateur)
                : undefined;
        this.caracteristiquesClient =
            application == 'gestion' || application == 'laboratoire' ? new SiteCaracteristiquesClient(data.caracteristiquesClient) : undefined;
        this.caracteristiquesProducteur = new SiteCaracteristiquesProducteur(data.caracteristiquesProducteur);
        this.caracteristiquesFournisseur = new SiteCaracteristiquesFournisseur(data.caracteristiquesFournisseur);
        this.caracteristiquesTransporteur =
            application == 'gestion' ? new SiteCaracteristiquesTransporteur(data.caracteristiquesTransporteur) : undefined;
        this.caracteristiquesLaboratoire =
            application == 'laboratoire' ? new SiteCaracteristiquesLaboratoire(data.caracteristiquesLaboratoire) : undefined;
        this.caracteristiquesProprietaire =
            application == 'qse' ? new SiteCaracteristiquesProprietaire(data.caracteristiquesProprietaire) : undefined;
        this.caracteristiquesLocataire = application == 'qse' ? new SiteCaracteristiquesLocataire(data.caracteristiquesLocataire) : undefined;
        this.caracteristiquesAuditeur = application == 'qse' ? new SiteCaracteristiquesAuditeur(data.caracteristiquesAuditeur) : undefined;
        this.niveaux = data.niveaux || {};
        this.inertes = application == 'gestion' ? (data.entete ? (data.entete.inertes ? data.entete.inertes : []) : []) : undefined;
        this.zoneStockages = application == 'gestion' ? (data.entete ? (data.entete.zoneStockages ? data.entete.zoneStockages : []) : []) : undefined;
        this.genreSite = data.genreSite;
        this.genreLibelle = data.genreLibelle;
        this.latitude = data.entete ? data.entete.latitude : undefined;
        this.longitude = data.entete ? data.entete.longitude : undefined;
        this.certificationCe = {
            libOrganisme: data.entete ? data.entete.libOrganisme : null,
            numOrganisme: data.entete ? data.entete.numOrganisme : null,
            nomCertificat: data.entete ? data.entete.nomCertificat : null,
            numCertificat: data.entete ? data.entete.numCertificat : null
        };
        this.codeReference =
            application == 'gestion' ? (data.entete ? (data.entete.codeReference ? data.entete.codeReference : undefined) : undefined) : undefined;
        this.ctrlFamilleSurface = data.ctrlFamilleSurface;
        this.regleDateEssai = data.regleDateEssai;
        this.enableSubstitutionPerso = data.enableSubstitutionPerso;
    }

    isValid() {
        siteValidator = siteValidator || validatorBuilder.getInstanceFor('Site');
        const validationResults = siteValidator.validate(this);
        return validationResults.isValid;
    }
}
