import FamillesMultiSelectController from './familles.multi.select.controller';

export default {
    template: require('./familles.multi.select.tpl.html'),
    controller: FamillesMultiSelectController,
    bindings: {
        disabled: '<',
        model: '<',
        modelArrayProperty: '<',
        multiSelect: '<',
        onSelect: '&'
    }
};
