const serviceUrl = `${__configuration.apiUrl}/massia/gestion/plan-impression-prix`;

export default class PlanImpressionPrixService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async generate(print) {
        const url = `${serviceUrl}/print/${print.domaine}/${print.templateId}`;
        const res = await this.$http.post(url, print, {
            responseType: 'arraybuffer'
        });
        return res;
    }

    async exporter(data) {
        const url = `${serviceUrl}/export`;
        const res = await this.$http.post(url, data, {
            responseType: 'arraybuffer'
        });
        return res;
    }

    async getSocietesCommerciales() {
        const url = `${__configuration.apiUrl}/massia/societes-commerciales`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSitesCommerciaux(idSociete) {
        const url = `${__configuration.apiUrl}/massia/sites-commerciaux/header/${idSociete}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    // async getClients(idSociete) {
    //     const url = `${__configuration.apiUrl}/massia/sites-clients/header/${idSociete}`;
    //     const res = await this.$http.get(url);
    //     return res.data;
    // }

    async getClients(filters, sorts, pagination, idSociete) {
        const url = `${serviceUrl}/clients/${idSociete}`;
        const res = await this.$http.get(url, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });
        return res.data;
    }

    async getClientSelections(pIdSociete, idTypes) {
        const url = `${__configuration.apiUrl}/massia/sites-clients/type/header/${pIdSociete}`;
        const res = await this.$http.get(url, {
            params: {
                idTypes: JSON.stringify(idTypes || [])
            }
        });
        return res.data;
    }

    async getChantierByClients(idClient) {
        const url = `${__configuration.apiUrl}/massia/chantiers/headersByClient/${idClient}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getChantierPrixByClients(idClient, idSocComm, idsSitesCommerciaux, dateDebut, dateFin, mode) {
        //mode -> 0 : All, 1 : Avec, 2 : Sans
        const arrIdsSiteComm = idsSitesCommerciaux.join(',');
        const url = `${__configuration.apiUrl}/massia/chantiers/headersAvecPrixByClient/${idClient}/${idSocComm}/${arrIdsSiteComm}/${dateDebut}/${dateFin}/${mode}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getTarifs(domaine, ids, filters) {
        const monFiltre = JSON.stringify(filters);
        const arrIds = ids.join(',');
        const url = `${__configuration.apiUrl}/massia/grillesTarifaires/${domaine}/prix?ids=${arrIds}&filters=${monFiltre}`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
