import VehiculeEntete from './vehicule.entete.model';
let ptraTracteur = 9999;
let ptacBenne = 9999;

export default class VehiculeEnteteController {
	static $inject = ['$stateParams', 'VehiculesCommunicationService', 'VehiculesService', 'VehiculesTypesService', 'notification'];

	constructor($stateParams, VehiculesCommunicationService, VehiculesService, VehiculesTypesService, notification) {
		this.$stateParams = $stateParams;
		this.VehiculesCommunicationService = VehiculesCommunicationService;
		this.VehiculesService = VehiculesService;
		this.VehiculesTypesService = VehiculesTypesService;
		this.notification = notification;
	}

	async $onInit() {
		this.loading = false;
		this.isEditMode = false;
		this.hasTypes = false;

		this.unregisterReinit = this.VehiculesCommunicationService.registerReinit(() => this.initForm());
		this.initForm();
	}

	$onDestroy() {
		this.unregisterReinit();
	}

	initForm() {
		let entete = {};

		if (this.$stateParams && this.$stateParams.id) {
			this.isEditMode = true;
			entete = this.vehicule.entete;
		} else {
			this.isEditMode = false;
		}

		this.vehicule.entete = new VehiculeEntete(entete);
		this.getTypes();
		this.getSites();
		this.getChauffeurs();

		this.getImmatBennes();
		this.getImmatTracteurs();
	}

	async videTransporteur() {
		this.vehicule.entete.siteId = undefined;
		this.videChauffeur();
	}

	async videChauffeur() {
		this.vehicule.entete.chauffeurId = undefined;
	}

	async modifyPTAC(pValue) {
		if (pValue == 1 && this.vehicule.entete.objetImmatTracteur != undefined && this.vehicule.entete.objetImmatTracteur != 'string') {
			ptraTracteur = typeof this.vehicule.entete.objetImmatTracteur.ptraTracteur;
		} else {
			ptraTracteur = 9999;
		}
		if (pValue == 2 && this.vehicule.entete.objetImmatBenne != undefined && this.vehicule.entete.objetImmatBenne != 'string') {
			ptacBenne = this.vehicule.entete.objetImmatBenne.ptacBenne;
		} else {
			ptraTracteur = 9999;
		}
		this.vehicule.entete.ptacVehicule = Math.min(ptraTracteur, ptacBenne);
	}

	async getTypes() {
		this.startLoading();

		try {
			this.types = await this.VehiculesTypesService.getAll();
			this.hasTypes = this.types.length > 0;
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getImmatBennes(valeur) {
		try {
			const res = await this.VehiculesService.getImmatBennes(valeur);
			return res;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async getImmatTracteurs(valeur) {
		try {
			const res = await this.VehiculesService.getImmatTracteurs(valeur);
			return res;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async getChauffeurs() {
		this.startLoading();
		try {
			let idTransporteur = this.vehicule.entete.siteId;
			if (!idTransporteur) {
				idTransporteur = -1;
			}
			const res = await this.VehiculesService.getChauffeurs(idTransporteur);
			this.chauffeurs = res;
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getSites() {
		this.startLoading();
		try {
			this.sites = await this.VehiculesService.getSites();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
