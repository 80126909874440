export default function Configure($stateProvider) {
    $stateProvider.state('prelevements', {
        parent: 'common',
        url: '/prelevements',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.PRELEVEMENTS" | translate }}'
        }
    });

    $stateProvider.state('prelevements.list', {
        url: '/list',
        params: {
            obj: null,
            idFamille: null
        },
        template: '<prelevements></prelevements>',
        rights: { domain: 'prelevements', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "PRELEVEMENTS.BREADCRUMBS.PRELEVEMENTS_LIST" | translate}}'
        },
        navigation: {
            menu: 'laboratoire_prelevements',
            translate: 'PRELEVEMENTS.BREADCRUMBS.PRELEVEMENTS_LIST',
            order: 1,
            navigationCls: 'menu-item-separator'
        },
        search: 'PRELEVEMENTS.BREADCRUMBS.PRELEVEMENTS_LIST'
    });

    $stateProvider.state('prelevements.new-prelevement', {
        url: '/{idFamille}/new-prelevement',
        template: '<prelevement></prelevement>',
        rights: { domain: 'prelevements', right: 'create' },
        ncyBreadcrumb: {
            parent: 'prelevements.list',
            label: '{{ "PRELEVEMENTS.BREADCRUMBS.PRELEVEMENT_NEW" | translate}}'
        },
        search: 'PRELEVEMENTS.BREADCRUMBS.PRELEVEMENT_NEW'
    });

    $stateProvider.state('prelevements.edit-prelevement', {
        url: '/{idFamille}/{id}/edit-prelevement',
        template: '<prelevement></prelevement>',
        rights: { domain: 'prelevements', right: 'update' },
        ncyBreadcrumb: {
            parent: 'prelevements.list',
            label: '{{ "PRELEVEMENTS.BREADCRUMBS.PRELEVEMENT_EDIT" | translate}}'
        }
    });

    $stateProvider.state('prelevements.new-type-prelevement', {
        url: '/new-type-prelevement',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        params: { parentState: 'prelevements.new-prelevement' },
        ncyBreadcrumb: {
            parent: 'prelevements.new-prelevement',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });

    $stateProvider.state('prelevements.duplicate-prelevement', {
        url: '/{idFamille}/{id}/duplicate-prelevement',
        template: '<prelevement></prelevement>',
        rights: { domain: 'prelevements', right: 'create' },
        ncyBreadcrumb: {
            parent: 'prelevements.list',
            label: '{{ "PRELEVEMENTS.BREADCRUMBS.PRELEVEMENT_COPY" | translate}}'
        },
        params: {
            duplicate: true
        }
    });
}

Configure.$inject = ['$stateProvider'];
