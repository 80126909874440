(function (angular, undefined) {
    'use strict';

    angular.module('blocks.i18n.globalization').factory('globalizationManagementService', globalizationManagementService);
    globalizationManagementService.$inject = ['globalizationConstants', '$translate', 'tmhDynamicLocale', '$log', 'moment', '_', 'i18nService'];

    function globalizationManagementService(globalizationConstants, $translate, tmhDynamicLocale, $log, moment, _, i18nService) {
        var service = {
            getCurrentLanguage: getCurrentLanguage,
            getAvailableLanguages: getAvailableLanguages,
            setCurrentLanguage: setCurrentLanguage,
        };

        return service;

        function getCurrentLanguage() {
            var currentLanguage = $translate.proposedLanguage() || $translate.use();

            return angular.copy(getLanguageFromCode(currentLanguage));
        }

        function getAvailableLanguages() {
            return angular.copy(globalizationConstants.availableLanguages);
        }

        function setCurrentLanguage(lang) {
            var language = getLanguageFromCode(lang);
            i18nService.setCurrentLang(language.code);
            $translate.use(language.code);
            moment.locale(language.code);
            tmhDynamicLocale.set(language.iso.toLowerCase());
        }

        function getLanguageFromCode(code) {
            var language, search;

            if (code) {
                switch (code.length) {
                    case 5: // fr-FR
                        search = { iso: code };
                        break;
                    case 2: // fr
                    default:
                        search = { code: code };
                        break;
                }
                language = _.find(globalizationConstants.availableLanguages, search);
            }

            // si le langage n'est pas disponible (ou si le code est mauvais)
            if (!language) {
                // on renvoie la langue par défaut
                language = _.find(globalizationConstants.availableLanguages, { code: globalizationConstants.fallbackLanguage });
                // et on prévient dans la console
                $log.warn('"' + code + '" language not supported, going to fallback language:' + language);
            }

            return language;
        }
    }
})(angular);
