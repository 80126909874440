import Nomenclature from './nomenclature.model';

export default class NomenclatureFormController {
	static $inject = [
		'$scope',
		'$timeout',
		'$state',
		'$stateParams',
		'NomenclaturesService',
		'notification',
		'$location',
		'$anchorScroll',
		'_',
		'MassiaApplicationService',
		'MOPService'
	];

	constructor(
		$scope,
		$timeout,
		$state,
		$stateParams,
		NomenclaturesService,
		notification,
		$location,
		$anchorScroll,
		_,
		MassiaApplicationService,
		MOPService
	) {
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.NomenclaturesService = NomenclaturesService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this._ = _;
		this.MassiaApplicationService = MassiaApplicationService;
		this.MOPService = MOPService;
	}

	$onInit() {
		//this.MOPService.setMop([{ title: 'BANQUES.BREADCRUMBS.BANQUES_LIST', filename: 'Banques.pdf', application: 'gestion' }]);
		this.nomenclature = {};
		this.loading = false;

		this.reset();
	}

	$onDestroy() {
		this.$timeout.cancel(this.updateNomenclatureTimeout);
		//this.MOPService.resetMop();
	}

	async reset() {
		this.startLoading();

		const data = {
			id: this.$stateParams.id
		};

		if (data.id) {
			try {
				data.entete = await this.NomenclaturesService.getNomenclatureById(data.id);
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
				this.annuler();
			}
		}
		this.nomenclature = new Nomenclature(data);
		this.$timeout.cancel(this.updateNomenclatureTimeout);
		this.updateNomenclatureTimeout = this.$timeout(() => this.stopLoading());
	}

	async sauvegarder() {
		if (this.checkValidity()) {
			this.startLoading();

			try {
				let id = false;
				if (this.nomenclature.id) {
					await this.NomenclaturesService.updateNomenclature(this.nomenclature);
					this.notification.success('NOMENCLATURES.UPDATED');
					id = this.nomenclature.id;
				} else {
					id = await this.NomenclaturesService.createNomenclature(this.nomenclature);
					this.notification.success('NOMENCLATURES.CREATED');
				}

				return id;
			} catch (ex) {
				this.notification.error(ex.data);
				return false;
			} finally {
				this.stopLoading();
			}
		}
	}

	async appliquer() {
		const id = await this.sauvegarder();
		if (id && this.nomenclature.id) {
			this.reset();
		} else if (id) {
			this.$state.go('nomenclatures.edit', { id: id });
		}
	}

	async confirmer() {
		const success = await this.sauvegarder();

		if (success) {
			this.annuler();
		}
	}

	annuler() {
		this.$state.go('nomenclatures.list');
	}

	checkValidity() {
		let validity = true;
		if (!this.nomenclature.entete.isValid()) {
			validity = false;
			this.$scope.$broadcast('nomenclatureEnteteValidations');
		}
		return validity;
	}

	onUpdateEntete(entete) {
		this.nomenclature.entete = entete;
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
