const serviceUrl = `${__configuration.apiUrl}/massia/gestion/planification-job-prefacturations`;

export default class PlanificationJobPrefacturationsService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getPlanificationJobPrefacturations(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async deletePlanificationJobPrefacturationById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = this.$http.delete(url);
        return res;
    }

    async createPlanificationJobPrefacturation(planification) {
        const result = await this.$http.post(serviceUrl, planification);
        return result.data;
    }

    async updatePlanificationJobPrefacturation(planification) {
        const url = `${serviceUrl}/${planification.id}`;
        return await this.$http.put(url, planification);
    }

    async getSites() {
        const url = `${__configuration.apiUrl}/massia/sites-commerciaux/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
