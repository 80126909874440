import ZonesConfig from './_config/zones.config';
import ZonesRoutes from './_config/zones.routes';
import ZonesComponent from './components/zones';
import ZonesService from './services/zones.service';
import ZonesCommunicationService from './services/zones.communication.service';
import ZonesFormComponent from './components/zones-form';
import ZoneValidator from './components/zones-form/zones.validator';

const moduleName = 'app.massia.gestion.zones';

angular
    .module(moduleName, [])
    .config(ZonesConfig)
    .config(ZonesRoutes)
    .service('ZonesService', ZonesService)
    .service('ZonesCommunicationService', ZonesCommunicationService)
    .component('zones', ZonesComponent)
    .component('zonesForm', ZonesFormComponent)
    .factory('ZoneValidator', ZoneValidator);

export default moduleName;
