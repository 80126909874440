export default function Configure($stateProvider) {
    $stateProvider.state('import-bl-externes', {
        parent: 'gestion',
        url: '/import-bl-externes',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.IMPORT_BL" | translate }}'
        }
    });

    $stateProvider.state('import-bl-externes.new', {
        url: '/new',
        template: '<import-bl-form></import-bl-form>',
        rights: { domain: 'tickimport', right: 'create' },
        ncyBreadcrumb: {
            label: '{{ "IMPORT_BL_EXTERNES.IMPORT_BL_NEW" | translate}}'
        },
        navigation: {
            menu: 'pesees_livraisons',
            translate: 'IMPORT_BL_EXTERNES.IMPORT_BL_NEW',
            order: 3,
            navigationCls: 'menu-item-separator'
        },

        // l'ajout d'un arret fait partie des points d'entrée recherchables dans l'application
        search: 'IMPORT_BL_EXTERNES.IMPORT_BL_NEW'
    });
    /*     $stateProvider.state('import-bl-externes.fichier', {
        url:'/fichier',
        template: '<import-bl-fichier-popup></import-bl-fichier-popup>',
        rights: { domaine: "tickimport", right: 'create'},
        ncyBreadcrumb: {
            label: '{{ "IMPORT_BL_EXTERNES.IMPORT_BL_NEW" | translate}}'
        },
        // l'ajout d'un arret fait partie des points d'entrée recherchables dans l'application
        search: "IMPORT_BL_EXTERNES.IMPORT_BL_NEW"
    });
 */
}

Configure.$inject = ['$stateProvider'];
