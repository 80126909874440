const serviceUrl = `${__configuration.apiUrl}/massia/jours-feries`;

export default class RecurrenceService {
    static $inject = ['$http', 'notification', 'moment', '$translate', 'globalizationManagementService'];

    constructor($http, notification, moment, $translate, globalizationManagementService) {
        this.$http = $http;
        this.notification = notification;
        this.moment = moment;
        this.$translate = $translate;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    ajouter(lstDates, lstRecurrences) {
        const date = this.nouvRecurrence == 1 || this.nouvRecurrence == 2;
        if (date) {
            const newJFerieDate = {
                id: undefined,
                idSite: this.idSite,
                libelleSite: this.libelleSite,
                idArret: this.idArret,
                libelleArret: this.libelleArret,
                idProcess: this.idProcess,
                libelleProcess: this.libelleProcess,
                dateDebut: this.nouvRecurrence === 1 ? this.newDateSimple : this.newPeriodeDebut,
                dateFin: this.nouvRecurrence === 1 ? undefined : this.newPeriodeFin,
                duree: this.nouvRecurrence === 2 ? undefined : this.moment(this.newDateSimpleDuree).format(this.dateFormat + ' HH:mm')
            };

            if (newJFerieDate.dateDebut) {
                const date = this.moment(newJFerieDate.dateDebut, this.dateFormat).toDate();
                const hours = this.nouvRecurrence == 1 ? this.newDateSimpleHeure : this.newPeriodeDebutHeure;
                if (hours && !isNaN(hours.getTime()) && this.isUseHours) {
                    newJFerieDate.dateDebut = this.moment(
                        new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours.getHours(), hours.getMinutes())
                    ).format(this.dateFormat + ' HH:mm');
                } else {
                    newJFerieDate.dateDebut = this.moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(), null, null)).format(
                        this.dateFormat + ' HH:mm'
                    );
                }
            }
            if (newJFerieDate.dateFin) {
                const date = this.moment(newJFerieDate.dateFin, this.dateFormat).toDate();
                const hours = this.nouvRecurrence == 1 ? undefined : this.newPeriodeFinHeure;
                if (hours && !isNaN(hours.getTime()) && this.isUseHours) {
                    newJFerieDate.dateFin = this.moment(
                        new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours.getHours(), hours.getMinutes())
                    ).format(this.dateFormat + ' HH:mm');
                } else {
                    newJFerieDate.dateFin = this.moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(), null, null)).format(
                        this.dateFormat + ' HH:mm'
                    );
                }
            }

            if (
                this.nouvRecurrence == 2 &&
                this.moment(newJFerieDate.dateFin, this.dateFormat).toDate() < this.moment(newJFerieDate.dateDebut, this.dateFormat).toDate()
            ) {
                this.notification.error('JOURS_FERIES.DATES_PERIODE_INCORRECTES');
            } else if (this.nouvRecurrence == 1 && this.isUseHours && (!newJFerieDate.duree || newJFerieDate.duree === 'Invalid date')) {
                this.notification.error('JOURS_FERIES.DATES_PERIODE_INCORRECTES');
            } else {
                lstDates.push(newJFerieDate);
                this.initFormulaireNouvelleDate(false);
            }
        } else {
            let jourSemaine = undefined;
            let numSemaine = undefined;
            let numJourMois = undefined;
            let moisAnnee = undefined;
            switch (this.nouvPeriodeRecurrence) {
                case 1: //jours
                    break;
                case 2: //semaines
                    jourSemaine = this.newJourSemSemaine;
                    break;
                case 3: //mois
                    if (this.newMoisChoix == 1) {
                        numJourMois = this.newNumJourMois;
                    } else {
                        jourSemaine = this.newJourSemMois;
                        numSemaine = this.newNumSemaineMois;
                    }
                    break;
                case 4: //ans
                    if (this.newAnChoix == 1) {
                        numJourMois = this.newNumJourAn;
                        moisAnnee = this.newMoisAnnee;
                    } else {
                        jourSemaine = this.newJourSemAn;
                        numSemaine = this.newNumSemaineAn;
                        moisAnnee = this.newMoisAnnee2;
                    }
                    break;
            }
            const newJFerieRecurrent = {
                id: undefined,
                idSite: this.idSite,
                libelleSite: this.libelleSite,
                idArret: this.idArret,
                libelleArret: this.libelleArret,
                idProcess: this.idProcess,
                libelleProcess: this.libelleProcess,
                tousLesNb: this.newNbRecurrence,
                recurrence: this.nouvPeriodeRecurrence,
                jourSemaine: jourSemaine,
                numSemaine: numSemaine,
                numJourMois: numJourMois,
                moisAnnee: moisAnnee,
                datePremiereOccurrence: this.newPremiereOccurrence,
                duree: this.moment(this.newPremiereOccurrenceDuree).format(this.dateFormat + ' HH:mm')
            };

            if (newJFerieRecurrent.datePremiereOccurrence) {
                const date = this.moment(newJFerieRecurrent.datePremiereOccurrence, this.dateFormat).toDate();
                const hours = this.newPremiereOccurrenceHeure;
                if (hours && !isNaN(hours.getTime()) && this.newPremiereOccurrenceDuree) {
                    newJFerieRecurrent.datePremiereOccurrence = this.moment(
                        new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours.getHours(), hours.getMinutes())
                    ).format(this.dateFormat + ' HH:mm');
                } else {
                    newJFerieRecurrent.datePremiereOccurrence = this.moment(
                        new Date(date.getFullYear(), date.getMonth(), date.getDate(), null, null)
                    ).format(this.dateFormat + ' HH:mm');
                }
            }

            if (this.isUseHours && (!newJFerieRecurrent.duree || newJFerieRecurrent.duree === 'Invalid date')) {
                this.notification.error('JOURS_FERIES.DATES_PERIODE_INCORRECTES');
            } else {
                lstRecurrences.push(newJFerieRecurrent);
                this.initFormulaireNouvelleDate(false);
            }
        }
    }

    formateDate(dateFeriee) {
        let str = '';
        if (!dateFeriee.dateFin) {
            str += this.$translate.instant('JOURS_FERIES.LE_DATE');
            str += ' ';

            str += this.isUseHours ? dateFeriee.dateDebut : this.moment(dateFeriee.dateDebut, this.dateFormat + ' HH:mm').format(this.dateFormat);
        } else {
            str += this.$translate.instant('JOURS_FERIES.DU_PERIODE');
            str += ' ';
            str += this.isUseHours ? dateFeriee.dateDebut : this.moment(dateFeriee.dateDebut, this.dateFormat + ' HH:mm').format(this.dateFormat);
            str += ' ';
            str += this.$translate.instant('JOURS_FERIES.AU_PERIODE');
            str += ' ';
            str += this.isUseHours ? dateFeriee.dateFin : this.moment(dateFeriee.dateFin, this.dateFormat + ' HH:mm').format(this.dateFormat);
        }

        if (this.isUseHours && dateFeriee.duree && dateFeriee.duree !== 'Invalid date') {
            const hours = this.moment(dateFeriee.duree, this.dateFormat + ' HH:mm').format('HH:mm');
            if (hours && hours !== '00:00') {
                str += ' ' + this.$translate.instant('JOURS_FERIES.PENDANT') + ' ' + hours;
            }
        }

        return str;
    }

    formateRecurrence(ferieRecurrent) {
        let recurr = '';
        let jourSem = '';
        let numjour = '';
        let numSem = '';
        let moisAn = '';
        switch (ferieRecurrent.recurrence) {
            case 1:
                recurr = this.$translate.instant('JOURS_FERIES.JOURS');
                break;
            case 2:
                recurr = this.$translate.instant('JOURS_FERIES.SEMAINES');
                jourSem = this.getJourSemaine(ferieRecurrent.jourSemaine);
                break;
            case 3:
                recurr = this.$translate.instant('JOURS_FERIES.MOIS');
                if (ferieRecurrent.numJourMois) {
                    numjour = ferieRecurrent.numJourMois;
                } else {
                    jourSem = this.getJourSemaine(ferieRecurrent.jourSemaine);
                    if (ferieRecurrent.numSemaine == '-1') {
                        numSem = this.$translate.instant('JOURS_FERIES.DE_LA_DERNIERE_SEMAINE');
                    } else {
                        numSem = this.$translate.instant('JOURS_FERIES.DE_LA_SEMAINE_NUM', { numsem: ferieRecurrent.numSemaine });
                    }
                }
                break;
            case 4:
                recurr = this.$translate.instant('JOURS_FERIES.ANS');
                moisAn = this.getMoisAnnee(ferieRecurrent.moisAnnee);
                if (ferieRecurrent.numJourMois) {
                    numjour = ferieRecurrent.numJourMois;
                } else {
                    jourSem = this.getJourSemaine(ferieRecurrent.jourSemaine);
                    if (ferieRecurrent.numSemaine == '-1') {
                        numSem = this.$translate.instant('JOURS_FERIES.DE_LA_DERNIERE_SEMAINE');
                    } else {
                        numSem = this.$translate.instant('JOURS_FERIES.DE_LA_SEMAINE_NUM', { numsem: ferieRecurrent.numSemaine });
                    }
                }
                break;
        }
        let str = this.$translate.instant('JOURS_FERIES.TOUS_LES_X_RECURR', { nb: ferieRecurrent.tousLesNb, recurr: recurr });
        if (numjour != '') {
            str += ' ' + this.$translate.instant('JOURS_FERIES.LE_NUMERO_JOUR', { numjour: numjour });
        }
        if (jourSem != '') {
            str += ' ' + this.$translate.instant('JOURS_FERIES.LE_JOUR_SEMAINE', { joursem: jourSem });
        }
        if (numSem != '') {
            str += ' ' + numSem;
        }
        if (moisAn != '') {
            str += ' ' + this.$translate.instant('JOURS_FERIES.DE_MOIS_ANNEE', { mois: moisAn });
        }

        if (this.isUseHours) {
            str += ', ' + this.$translate.instant('JOURS_FERIES.A_PARTIR_DU', { date: ferieRecurrent.datePremiereOccurrence });
        } else {
            str +=
                ', ' +
                this.$translate.instant('JOURS_FERIES.A_PARTIR_DU', {
                    date: this.moment(ferieRecurrent.datePremiereOccurrence, this.dateFormat + ' HH:mm').format(this.dateFormat)
                });
        }

        if (this.isUseHours && ferieRecurrent.duree && ferieRecurrent.duree !== 'Invalid date') {
            const hours = this.moment(ferieRecurrent.duree, this.dateFormat + ' HH:mm').format('HH:mm');
            if (hours && hours !== '00:00') {
                str += ' ' + this.$translate.instant('JOURS_FERIES.PENDANT') + ' ' + hours;
            }
        }

        return str;
    }

    getMoisAnnee(mois) {
        let moisAn = '';
        switch (mois) {
            case '1':
                moisAn = this.$translate.instant('JOURS_FERIES.JANVIER');
                break;
            case '2':
                moisAn = this.$translate.instant('JOURS_FERIES.FEVRIER');
                break;
            case '3':
                moisAn = this.$translate.instant('JOURS_FERIES.MARS');
                break;
            case '4':
                moisAn = this.$translate.instant('JOURS_FERIES.AVRIL');
                break;
            case '5':
                moisAn = this.$translate.instant('JOURS_FERIES.MAI');
                break;
            case '6':
                moisAn = this.$translate.instant('JOURS_FERIES.JUIN');
                break;
            case '7':
                moisAn = this.$translate.instant('JOURS_FERIES.JUILLET');
                break;
            case '8':
                moisAn = this.$translate.instant('JOURS_FERIES.AOUT');
                break;
            case '9':
                moisAn = this.$translate.instant('JOURS_FERIES.SEPTEMBRE');
                break;
            case '10':
                moisAn = this.$translate.instant('JOURS_FERIES.OCTOBRE');
                break;
            case '11':
                moisAn = this.$translate.instant('JOURS_FERIES.NOVEMBRE');
                break;
            case '12':
                moisAn = this.$translate.instant('JOURS_FERIES.DECEMBRE');
                break;
        }
        return moisAn;
    }

    getJourSemaine(jrSem) {
        let jourSem = '';
        switch (jrSem) {
            case '1':
                jourSem = this.$translate.instant('JOURS_FERIES.LUNDI');
                break;
            case '2':
                jourSem = this.$translate.instant('JOURS_FERIES.MARDI');
                break;
            case '3':
                jourSem = this.$translate.instant('JOURS_FERIES.MERCREDI');
                break;
            case '4':
                jourSem = this.$translate.instant('JOURS_FERIES.JEUDI');
                break;
            case '5':
                jourSem = this.$translate.instant('JOURS_FERIES.VENDREDI');
                break;
            case '6':
                jourSem = this.$translate.instant('JOURS_FERIES.SAMEDI');
                break;
            case '7':
                jourSem = this.$translate.instant('JOURS_FERIES.DIMANCHE');
                break;
        }
        return jourSem;
    }

    initFormulaireNouvelleDate(reinitTout) {
        if (reinitTout) {
            this.nouvRecurrence = 1;
            this.nouvPeriodeRecurrence = 1;
        }
        const ajd = this.moment(new Date()).format(this.dateFormat);
        const ajdHeure = this.isUseHours ? new Date() : undefined;
        this.newDateSimple = ajd;
        this.newDateSimpleHeure = ajdHeure;
        this.newPeriodeDebut = ajd;
        this.newPeriodeDebutHeure = ajdHeure;
        this.newPeriodeFin = ajd;
        this.newPeriodeFinHeure = ajdHeure;
        this.newNbRecurrence = 1;
        this.newPremiereOccurrence = ajd;
        this.newPremiereOccurrenceHeure = ajdHeure;
        this.newJourSemSemaine = '7';
        this.newNumJourMois = 31;
        this.newMoisChoix = 1;
        this.newJourSemMois = '7';
        this.newNumSemaineMois = '-1';
        this.newAnChoix = 1;
        this.newNumJourAn = 31;
        this.newMoisAnnee = '12';
        this.newJourSemAn = '7';
        this.newNumSemaineAn = '-1';
        this.newMoisAnnee2 = '12';

        this.newDateSimpleDuree = null;
        this.newPremiereOccurrenceDuree = null;
    }

    deleteDate(dateFeriee, lstDates) {
        const index = lstDates.indexOf(dateFeriee);
        lstDates.splice(index, 1);

        return lstDates;
    }

    deleteRecurrence(ferieRecurrent, lstRecurrrences) {
        const index = lstRecurrrences.indexOf(ferieRecurrent);
        lstRecurrrences.splice(index, 1);

        return lstRecurrrences;
    }
}
