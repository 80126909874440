export default function Configure($stateProvider) {
    $stateProvider.state('lieux', {
        parent: 'common',
        url: '/lieux',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.TRANSPORT" | translate }}'
        }
    });

    $stateProvider.state('lieux.list', {
        url: '/list',
        template: '<lieux></lieux>',
        rights: { domain: 'lieu', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "LIEUX.BREADCRUMBS.LIEUX_LIST" | translate}}'
        },
        // on affiche la liste des lieux dans la navigation
        navigation: {
            // navigationCls: 'hidden-xs hidden-sm', // TODO exemple : ce state n'apparaitra pas sur mobile
            menu: 'tarification_transport',
            translate: 'LIEUX.BREADCRUMBS.LIEUX_LIST',
            order: 7
        },
        // la liste des lieux fait partie des points d'entrée recherchables dans la navigation
        search: 'LIEUX.BREADCRUMBS.LIEUX_LIST'
    });

    $stateProvider.state('lieux.niveaux', {
        url: '/niveaux',
        template: '<lieu-config-niveaux></lieu-config-niveaux>',
        rights: { domain: 'nivh', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher lieux > niveaux
            parent: 'lieux.list',
            label: '{{ "LIEUX.BREADCRUMBS.LIEU_NIVEAUX" | translate }}'
        }
    });

    $stateProvider.state('lieux.new', {
        url: '/new',
        template: '<lieu-form></lieu-form>',
        rights: { domain: 'lieu', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher lieux > ajouter
            parent: 'lieux.list',
            label: '{{ "LIEUX.BREADCRUMBS.LIEU_NEW" | translate}}'
        },
        // l'ajout d'un lieu fait partie des points d'entrée recherchables dans l'application
        search: 'LIEUX.BREADCRUMBS.LIEU_NEW'
    });

    $stateProvider.state('lieux.list.detail', {
        url: '/{id}',
        template: '<lieu-detail></lieu-detail>',
        rights: { domain: 'lieu', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher lieux > détail
            parent: 'lieux.list',
            label: '{{ "LIEUX.BREADCRUMBS.LIEU_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('lieux.edit', {
        url: '/{id}/edit',
        template: '<lieu-form></lieu-form>',
        rights: { domain: 'lieu', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher lieux > modifier
            parent: 'lieux.list',
            label: '{{ "LIEUX.BREADCRUMBS.LIEU_EDIT" | translate }}'
        }
    });

    $stateProvider.state('lieux.newType', {
        url: '/new-type',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        params: { parentState: 'lieux.new' },
        ncyBreadcrumb: {
            parent: 'lieux.new',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
