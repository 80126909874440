/**
 * @param {Array} list array of formated isteven list
 * @param {string} property item property in isteven formated list
 * @param {(string|number|Array)} value value to compare with item property
 * @param {string} tick property of selected element
 * @param {string} arrayProperty property of array in property
 * @returns {Array} array with tick property set to true
 */

import { Pipe } from "../../../core/decorators";
import * as _ from 'lodash';
import { PipeTransform } from "../../../core/decorators/pipe";

@Pipe({
    name: 'selectInIsteven'
})
export class SelectInIsteven implements PipeTransform {
    transform(list: any[], property: string, value: string | number | any[], tick: string, arrayProperty: string) {
        if(!list) {
            return list
        }
        if (Array.isArray(value)) {
            if (!arrayProperty) throw new Error('Must contains an array property value');
            var tmp: any[] = [];
            for (let i = 0; i < value.length; i++) {
                let e = value[i];
                let val = e;
                if(arrayProperty.includes('.')) {
                    const props = arrayProperty.split('.');
                    _.forEach(props, (prop) => {
                        val = val[prop];
                    });
                } else {
                    val = e[arrayProperty];
                }
                for (let j = 0; j < list.length; j++) {
                    let el = list[j];
                    if (el[property] === val) {
                        tmp.push(el[property]);
                        el[tick] = true;
                    } else if (!tmp.includes(el[property])) {
                        el[tick] = false;
                    }
                }
            }
        } else {
            for (let i = 0; i < list.length; i++) {
                let e = list[i];
                if (e[property] === value) {
                    e[tick] = true;
                } else {
                    e[tick] = false;
                }
            }
        }

        return list;
    }
}
