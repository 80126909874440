import { copy } from 'angular';
import SyntheseCacheService from '../../../../../../services/synthese.cache.service';
import ApiService from '../../../../../../../../services/api.service';

/* @ngInject */
export default class SyntheseControleController {
	constructor(
		$scope,
		SyntheseEntitiesService,
		notification,
		moment,
		ReferencesControleService,
		MesuresService,
		MassiaRightsService,
		$uibModal,
		SyntheseCacheService,
		ApiService,
		$filter
	) {
		this.SyntheseEntitiesService = SyntheseEntitiesService;
		this.notification = notification;
		this.m = moment;
		this.$scope = $scope;
		this.ReferencesControleService = ReferencesControleService;
		this.MesureService = MesuresService;
		this.MassiaRightsService = MassiaRightsService;
		this.$filter = $filter;
		this.$uibModal = $uibModal;
		/**
		 * @type SyntheseCacheService
		 */
		this.SyntheseCacheService = SyntheseCacheService;
		/**
		 * @type ApiService
		 */
		this.ApiService = ApiService;
	}

	async $onInit() {
		console.log(this);
		this.unregister = [];
		this.canUpdate = this.MassiaRightsService.userHasRight('blocsel', 'update');
		this.dragOptions = {
			placeholder: 'table-row-ui-placeholder',
			animation: 200,
			'ui-preserve-size': true,
			stop: () => {
				this.model.essais = this.model.essais.map((x, index) => {
					x.order = index;
					return x;
				});
			}
		};
		this.model.essais = this.model.essais || [];
		this.loadAll();
		this.unregister.push(
			this.$scope.$watch(
				() => this.model.blocSelections,
				() => this.loadAll(),
				true
			)
		);
		this.unregister.push(
			this.$scope.$watch(
				() => this.essai,
				() => this.refreshModelEssai(),
				true
			)
		);
		this.unsub = this.SyntheseCacheService.essais.subscribe((val) => {
			this.refreshEssai(copy(val));
			this.loadAll(copy(val));
		});
	}

	log(d) {
		console.log(d);
	}

	loadAll(ess) {
		this.getNormes();
		this.getReference();
		this.getEssais(ess);
		this.loadEssai();
	}

	$onDestroy() {
		for (let i = 0; i < this.unregister.length; i++) {
			const u = this.unregister[i];
			u();
		}
		this.unsub.unsubscribe();
	}

	async getNormes() {
		try {
			if (this.model.blocSelections && this.model.blocSelections.length > 0) {
				if (!this.model.controle) {
					this.model.controle = {};
				}

				if (this.model.typeSynthese === 17) {
					this.seuilBetGach = (await this.ApiService.get('/v2/massia/seuil-bet-gachs')).items;
					this.seuilBetGach = this.$filter('selectInIsteven')(this.seuilBetGach, 'id', this.model.controle.seuilBetGach.id, 'selected');
				} else {
					let listeNormes = [];
					let familleIds = [];
					if (this.model.blocSelections.some((x) => x.modelSelection)) {
						familleIds = [
							...new Set(this.model.blocSelections.filter((x) => x.modelSelection !== null).map((x) => x.modelSelection.famille.id))
						];
					}
					for (let i = 0; i < familleIds.length; i++) {
						const id = familleIds[i];
						if (!id) {
							continue;
						}
						const tmpNorme = await this.SyntheseEntitiesService.getNormes(id);
						listeNormes = listeNormes.concat(tmpNorme).filter((v, i, a) => a.findIndex((n) => n.id === v.id) === i);
					}
					this.normes = listeNormes;
				}
			}
		} catch (err) {
			console.error(err);
		}
	}

	async getReference() {
		try {
			let refs = [];
			if (this.model.blocSelections && this.model.blocSelections.length > 0) {
				const periodes = this.getCriterePeriode(
					this.model.blocSelections.map((x) =>
						x.modelSelection && x.modelSelection.modelSelectionCriteres
							? x.modelSelection.modelSelectionCriteres.filter((e) => e.typeCritere === 5)
							: []
					)
				);
				const productsIds = this.getIds(this.model.blocSelections, 'Produits');
				const producteursIds = this.getIds(this.model.blocSelections, 'SiteProd');
				const clientIds = this.getIds(this.model.blocSelections, 'SiteClient');
				for (let i = 0; i < this.model.blocSelections.length; i++) {
					const bloc = this.model.blocSelections[i];
					if (bloc.modelSelection && bloc.modelSelection.famille) {
						refs = refs.concat(
							await this.SyntheseEntitiesService.getReferencesByParamsForSynthese(
								productsIds,
								producteursIds,
								clientIds,
								periodes.length > 0 ? periodes : null,
								bloc.modelSelection.famille.id,
								this.model.typeDeSynthese == 2
							)
						);
					}
				}
			} else {
				refs.push(
					await this.SyntheseEntitiesService.getReferencesByParamsForSynthese(null, null, null, null, null, this.model.typeDeSynthese == 2)
				);
			}
			this.references = [...new Map(refs.map((x) => [x.id, x])).values()];
			if (this.model.controle && this.model.controle.norme && this.model.controle.norme.id > 0) {
				this.references = this.references.filter((x) => x.normeId === this.model.controle.norme.id);
			}
			this.loadActualReference();
		} catch (err) {
			console.error(err);
		}
	}

	async loadActualReference() {
		try {
			if (this.model && this.model.controle && this.model.controle.reference && this.model.controle.reference.id) {
				this.actualReference = await this.ReferencesControleService.getReferenceById(this.model.controle.reference.id);
				if (this.actualReference) {
					this.actualReference.mesures = this.actualReference.mesures.distinctBy((x) => x.essaiId && x.sousEssaiId);
				}
			}
		} catch (err) {
			console.error(err);
		}
	}

	isInRef(essai) {
		if (this.actualReference && this.actualReference.mesures) {
			return this.actualReference.mesures.some((x) => x.essaiId === essai.id);
		}
		return false;
	}

	async getEssais(ess) {
		try {
			this.refreshEssai(ess);
			//this.essaisLoaded = essais;
		} catch (err) {
			console.error(err);
		}
	}

	refreshEssai(essais) {
		if (this.model.blocSelections && this.model.blocSelections.length > 0) {
			const model = this.model.blocSelections.filter((x) => x.modelSelection && x.modelSelection.id);
			if (!model || model.length === 0) {
				return;
			}
			const idsModelSel = model.map((x) => x.modelSelection.id);
			if (essais) {
				const ess = copy(essais.filter((x) => x.typeProduit.some((t) => idsModelSel.includes(t.idFamille))));
				let i = 0;
				while (i < ess.length) {
					if (
						this.model.essais.some((x) => x.id === ess[i].id) &&
						this.model.blocSelections.some((x) => x.blocSelectionEssais.some((e) => e.id === ess[i].id))
					) {
						ess[i].disabled = !this.canUpdate;
					}
					i++;
				}
				for (let j = 0; j < this.model.essais.length; j++) {
					const ess = this.model.essais[j];
					this.loadSousEssai(ess);
				}
				this.essaisLoaded = essais;
			}
		}
	}

	selectSeuilBetGach(data) {
		if (data.selected) {
			this.model.controle.seuilBetGach = {
				id: data.id
			};
		} else {
			this.model.controle.seuilBetGach = {};
		}
	}

	selectRef(data) {
		if (!this.model.controle) {
			this.model.controle = {};
		}
		this.model.controle.reference = {
			id: data.id
		};
		this.getReference();
	}

	selectNorme(data) {
		if (!this.model.controle) {
			this.model.controle = {};
		}

		if (data.selected) {
			this.model.controle.norme = {
				id: data.id
			};
		} else {
			this.model.controle.norme = {};
		}
		// console.log(this.model, data);
		if (this.model.controle.reference) {
			if (this.actualReference && this.actualReference.idNorme !== data.id) {
				this.model.controle.reference = null;
				this.actualReference = null;
			}
		}
		this.getReference();
	}

	getIds(blocSelection, domaine) {
		let Ids = [];
		const tmp = blocSelection.map((x) =>
			x.modelSelection && x.modelSelection.modelSelectionCriteres
				? x.modelSelection.modelSelectionCriteres.filter((e) => e.donneeSelectionable && e.donneeSelectionable.domaine.code === domaine)
				: []
		);

		for (let i = 0; i < tmp.length; i++) {
			const criteres = tmp[i];
			for (let j = 0; j < criteres.length; j++) {
				const critere = criteres[j].modelSelectionCritereValeur;
				const res = critere.filter((x) => x.id > -1).map((x) => x.idValeur);
				Ids = Ids.concat(res);
			}
		}
		return Ids.filter((x) => x !== null);
	}

	addEssaiRef() {
		this.addingEssai = true;
		if (this.actualReference && this.actualReference.mesures && this.actualReference.mesures.length > 0) {
			const essais = this.getEssaiRef();
			// console.log(this);
			essais.forEach((ess) => {
				if (!this.model.essais.some((x) => x.id === ess.id)) {
					this.model.essais.push(ess);
				}
			});
		}
		this.addingEssai = false;
	}

	essaiSelect() {
		if (this.selectedEssais) {
			let i = 0;
			const essais = [];
			while (i < this.selectedEssais.length) {
				const current = this.model.essais.find((e) => e.id === this.selectedEssais[i].id);
				let tmp = {
					...this.selectedEssais[i],
					complementaire: false,
					blocSelectionSousEssais: current ? current.blocSelectionSousEssais : [],
					order: this.model.essais.length + 1
				};
				if (current) {
					tmp = { ...tmp, ...current };
				}
				essais.push(tmp);
				i++;
			}
			this.model.essais = essais.sort((a, b) => a.order - b.order);
		}
	}

	multiSelectEssai(data, essai) {
		this.checkTamis(
			essai,
			this.selected[essai.id].map((x) => x.id)
		);
		essai.blocSelectionSousEssais = this.multiSelect(this.selected[essai.id]);
	}

	deleteEssaiRef() {
		this.addingEssai = true;
		if (this.actualReference && this.actualReference.mesures && this.actualReference.mesures.length > 0) {
			const essais = this.getEssaiRef();
			let i = 0;
			while (i < this.essai.length) {
				const ess = this.essai[i];
				if (!essais.some((x) => x.id === ess.id)) {
					this.essai.splice(
						this.essai.findIndex((x) => x.id === ess.id),
						1
					);
					i--;
				}
				i++;
			}
		}
		this.addingEssai = false;
	}

	refreshModelEssai() {
		if (this.model.essais && this.essai) {
			this.model.essais = this.essai.filter((x) => !x.blocSelection);
		}
	}

	getEssaiRef() {
		const res = [];
		const essais = this.actualReference.mesures.groupBy((x) => x.essaiId);
		for (const id in essais) {
			if (essais.hasOwnProperty(id)) {
				const sousEssai = essais[id];
				const obj = {
					complementaire: false,
					id: sousEssai[0].essaiId,
					code: sousEssai[0].codeEssai,
					libelle: sousEssai[0].libelleEssai,
					idNature: sousEssai[0].nature,
					blocSelection: this.model.blocSelections.map((x) => {
						const tmp = angular.copy(x);
						delete tmp.blocSelectionEssais;
						return tmp;
					})
				};
				obj.blocSelectionSousEssais = sousEssai
					.filter((x) => !x.tamis)
					.map((x) => {
						return {
							code: x.sousEssaiCode,
							id: x.idSousEssai,
							libelle: x.sousEssaiLibelle,
							nature: {
								idNature: x.nature
							}
						};
					});
				const tamis = sousEssai.filter((x) => x.tamis);
				if (tamis && tamis.length > 0) {
					const ssess = tamis.groupBy((x) => x.idSousEssai);
					for (const key in ssess) {
						if (ssess.hasOwnProperty(key)) {
							const element = ssess[key][0];
							obj.blocSelectionSousEssais.push({
								code: element.sousEssaiCode,
								id: element.idSousEssai,
								libelle: element.sousEssaiLibelle,
								nature: {
									idNature: element.nature
								}
							});
						}
					}
					obj.serieTamis = {
						id: tamis[0].idSerieTamis,
						tamis: tamis.map((x) => {
							return {
								id: x.sousEssaiId,
								ouverture: parseFloat(x.sousEssaiCodeLibelle)
							};
						})
					};
				}
				res.push(obj);
			}
		}
		return res;
	}

	getCriterePeriode(blocs) {
		const periode = [];
		for (let i = 0; i < blocs.length; i++) {
			const criteres = blocs[i];
			for (let j = 0; j < criteres.length; j++) {
				if (criteres[j].modelSelectionCritereValeur[0]) {
					const critere = criteres[j].modelSelectionCritereValeur[0];
					let per = {};
					const isGammeDate = critere.typeRecherche === 21;
					if (isGammeDate) {
						per = {
							dateDebut: this.m(critere.valeur1).toDate(),
							dateFin: this.m(critere.valeur2).toDate(),
							periodeGlissante: critere.typeRecherche
						};
					} else {
						per = {
							dateFin: this.m(critere.valeur2).toDate(),
							periodeGlissante: critere.typeRecherche,
							valeurTemporelle: parseInt(critere.valeur1)
						};
					}
					periode.push(per);
				}
			}
		}
		return periode;
	}

	async deleteEssai(essai) {
		try {
			const idx = this.model.essais.findIndex((e) => e.id === essai.id);
			if (essai.blocSelection) {
				const modal = this.$uibModal.open({
					animation: true,
					templateUrl: 'delete_bloc.html',
					controllerAs: '$ctrl',
					controller: [
						'$scope',
						'$uibModalInstance',
						function ($scope, $uibModalInstance) {
							$scope.essai = essai;

							$scope.ok = () => {
								$uibModalInstance.close();
							};

							$scope.cancel = () => {
								$uibModalInstance.dismiss();
							};
						}
					]
				});

				await modal.result;
				const idxBlocEssai = essai.blocSelection.blocSelectionEssais.findIndex((e) => e.id === essai.id);
				essai.blocSelection.blocSelectionEssais.splice(idxBlocEssai, 1);
			}
			this.model.essais.splice(idx, 1);
		} catch (err) {
			console.error(err);
		}
	}

	loadEssai() {
		let essais = [];
		if (this.model.blocSelections) {
			for (let i = 0; i < this.model.blocSelections.length; i++) {
				const bloc = this.model.blocSelections[i];
				essais = essais.concat(
					bloc.blocSelectionEssais.map((x) => {
						const tmp = angular.copy(x);
						const current = this.model.essais.find((e) => e.id === x.id);
						tmp.order = this.model.essais.length + 1;
						if (current) {
							tmp.order = current.order;
						}
						tmp.disabled = !this.canUpdate;
						tmp.blocSelection = bloc;
						return tmp;
					})
				);
			}
		}
		const current = this.model.essais ? this.model.essais.filter((x) => !essais.some((e) => e.id === x.id)) : [];
		this.model.essais = [...current, ...essais].sort((a, b) => a.order - b.order);
	}

	loadSousEssai(essai) {
		try {
			if (!essai.tmpSousEssai && this.essaisLoaded && this.essaisLoaded.length > 0) {
				//essai.tmpSousEssai = await this.MesureService.getMesureSousMesuresById(essai.id);
				essai.tmpSousEssai = this.essaisLoaded.find((x) => x.id === essai.id).sousEssais;
				if (!essai.blocSelectionSousEssais || (essai.blocSelectionSousEssais && essai.blocSelectionSousEssais.length === 0)) {
					essai.blocSelectionSousEssais = [essai.tmpSousEssai.find((x) => x.code === essai.code)];
				}
			}
			this.checkTamis(
				essai,
				essai.blocSelectionSousEssais.map((x) => x.id)
			);
		} catch (err) {
			console.error(err);
		}
	}

	updateBlocSelection(data, essai, index) {
		this.checkTamis(
			essai,
			essai.blocSelectionSousEssais.map((x) => x.id)
		);
	}

	multiSelect(output) {
		const list = [];
		for (let i = 0; i < output.length; i++) {
			const element = output[i];
			list.push(element);
		}
		return list;
	}

	multiSelectTamis(data, essai) {
		essai.serieTamis.tamis = this.multiSelect(
			essai.selectedTamis.map((x) => {
				x.id = x.idTamis;
				return x;
			})
		);
	}

	async checkTamis(essai, liste) {
		try {
			if (essai.idNature == 8) {
				essai.tmpSerieTamis = await this.SyntheseEntitiesService.getTamis(liste);
				if (essai.blocSelection !== null) {
					essai.tmpSerieTamis = essai.tmpSerieTamis.map((x) => {
						x.canUpdate = !this.canUpdate;
						return x;
					});
				}
				if (essai.serieTamis) {
					this.serieSelect(
						essai.tmpSerieTamis.find((x) => x.idSerieTamis === essai.serieTamis.id),
						essai
					);
				}
			}
		} catch (err) {
			console.error(err);
		}
	}

	serieSelect(data, essai) {
		essai.tmpTamis = data.listeTamisHeaders.map((x) => {
			x.valeurTamis = `${x.valeurTamis}`;
			x.canUpdate = !this.canUpdate;
			return x;
		});
		essai.serieTamis = {
			id: data.idSerieTamis,
			tamis: essai.serieTamis ? essai.serieTamis.tamis : []
		};
	}
}
