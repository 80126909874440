DemandesLivraisonsController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'DemandesLivraisonsService',
    'notification'
];

export default function DemandesLivraisonsController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    DemandesLivraisonsService,
    notification
) {
    const vm = this;

    let previousTableState;
    //let selectedIndiceRevisionId = undefined;
    const watchers = [];

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.demandeLivraisons = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };
    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.loadDemandeLivraisons = loadDemandeLivraisons;
    vm.deleteDemandeLivraison = deleteDemandeLivraison;

    function init() {
        // $transitions.onSuccess({}, stateChangeSuccessFunc);
        // watchers.push($scope.$on('$destroy', dispose));
        // // if (shouldSetSelectedId()) {
        // //     selectedIndiceRevisionId = $stateParams.id;
        // // }
    }

    init();

    async function loadDemandeLivraisons(tableState) {
        startLoading();

        if ($state && $state.current && $state.current.name !== 'demandes-livraisons.list') {
            $state.go('demandes-livraisons.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        if (previousTableState == undefined) {
        }

        console.log(previousTableState);
        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.demandeLivraisons = [];

        try {
            const data = await DemandesLivraisonsService.getDemandeLivraisons(filters, sorts, pagination);
            vm.demandeLivraisons = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteDemandeLivraison(demandeLivraison) {
        if (demandeLivraison && demandeLivraison.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('DEMANDES_LIVRAISONS.DELETE.TITLE', { code: demandeLivraison.code }),
                modalMsg: $translate.instant('DEMANDES_LIVRAISONS.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await DemandesLivraisonsService.deleteDemandeLivraisonById(demandeLivraison.id);
                    notification.success($translate.instant('DEMANDES_LIVRAISONS.DELETE.SUCCESS'));
                    //selectedDemandeLivraisonId = undefined;
                    if (vm.params.id) {
                        vm.state.go('demandes-livraisons.list');
                    }
                    loadDemandeLivraisons();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
