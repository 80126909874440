import TamisSelectController from './tamis.select.controller';

export default {
    template: require('./tamis.select.tpl.html'),
    controller: TamisSelectController,
    bindings: {
        disabled: '<',
        clearOnSelect: '<',
        selected: '<',
        excludes: '<',
        onSelect: '&',
        idMesure: '<',
        listTamis: '<'
    }
};
