MotifsAjustementStockController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'MotifsAjustementStockService',
	'notification',
	'$transitions'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function MotifsAjustementStockController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	MotifsAjustementStockService,
	notification,
	$transitions
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	let selectedMotifAjustementStockId = undefined;

	vm.loading = false;
	vm.stateParams = $stateParams;
	vm.motifsAjustementStock = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.isMotifAjustementStockSelected = isMotifAjustementStockSelected;
	vm.loadMotifsAjustementStock = loadMotifsAjustementStock;
	vm.deleteMotifAjustementStock = deleteMotifAjustementStock;

	function init() {
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "motifs.list", on désélectionne la ligne sélectionnée (s'il y en a)
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		//$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));
	}

	init();

	function stateChangeSuccessFunc(event, toState) {
		if (!$stateParams.id) {
			selectedMotifAjustementStockId = undefined;
		}
	}

	function isMotifAjustementStockSelected(motifAjustementStock) {
		return motifAjustementStock && motifAjustementStock.id && selectedMotifAjustementStockId === motifAjustementStock.id;
	}

	async function loadMotifsAjustementStock(tableState) {
		startLoading();

		//quand on rafraîchit la grille, on revient sur "motifs.list", et on ferme la vue détail d'un motif
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== 'motifs-ajustement-stock.list') {
			$state.go('motifs-ajustement-stock.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.motifsAjustementStock = [];

		try {
			const data = await MotifsAjustementStockService.getMotifsAjustementStock(filters, sorts, pagination);
			vm.motifsAjustementStock = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteMotifAjustementStock(motifAjustementStock) {
		if (motifAjustementStock && motifAjustementStock.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('MOTIFS_AJUSTEMENT_STOCK.DELETE.TITLE', { libelle: motifAjustementStock.libelle }),
				modalMsg: $translate.instant('MOTIFS_AJUSTEMENT_STOCK.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await MotifsAjustementStockService.deleteMotifAjustementStockById(motifAjustementStock.id);
					notification.success($translate.instant('MOTIFS_AJUSTEMENT_STOCK.DELETE.SUCCESS'));

					selectedMotifAjustementStockId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
					if (vm.stateParams.id) {
						$state.go('motifs-ajustement-stock.list');
					}

					loadMotifsAjustementStock();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
