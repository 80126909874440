export default class DocumentCEDetailController {
    static $inject = ['$stateParams', '$state', 'DocumentsCEService', 'notification', 'DocumentsCECommunicationService', 'MassiaApplicationService'];

    constructor($stateParams, $state, DocumentsCEService, notification, DocumentsCECommunicationService, MassiaApplicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.DocumentsCEService = DocumentsCEService;
        this.notification = notification;
        this.DocumentsCECommunicationService = DocumentsCECommunicationService;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        this.loading = false;
        this.docce = {};
        //par défaut, on ouvre l'onglet "Entête"
        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.initDocumentCE();
    }

    async initDocumentCE() {
        this.docce = {
            id: this.$stateParams.id
        };

        if (this.docce.id) {
            this.startLoading();

            try {
                // on ajoute await afin de ne pas passer trop rapidement dans le finally
                // et ainsi on garde le loading le temps nécessaire
                this.docce.entete = await this.DocumentsCEService.getDocumentCEEnteteById(this.docce.id);
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListDocumentCE();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListDocumentCE();
        }
    }

    async getDocumentCEEntete(docceId) {
        this.docce.entete = await this.DocumentsCEService.getDocumentCEEnteteById(docceId);
    }

    goListDocumentCE() {
        if (this.MassiaApplicationService.getApplication() == 'gestion') {
            this.$state.go('documents-ce.list', {}, { reload: true });
        } else {
            this.$state.go('documents-ce-labo.list', {}, { reload: true });
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
