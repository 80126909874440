import SiteIndicateurController from './site.indicateur.controller';

export default {
    bindings: {
        site: '=',
        isOpen: '='
    },
    template: require('./site.indicateur.html'),
    controller: SiteIndicateurController
};
