PrestationsController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'PrestationsService',
	'notification',
	'$transitions',
	'MOPService'
];

export default function PrestationsController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	PrestationsService,
	notification,
	$transitions,
	MOPService
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	vm.selectedPrestationId = undefined;
	vm.lastSelected = undefined;

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.prestations = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectPrestation = selectPrestation;
	vm.isPrestationSelected = isPrestationSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadPrestations = loadPrestations;
	vm.deletePrestation = deletePrestation;
	vm.setSelected = setSelected;
	vm.eraseAllFilters = eraseAllFilters;
	vm.MOPService = MOPService;

	function init() {
		vm.MOPService.setMop([
			{ title: 'Gestion des prestations', filename: 'GestionsPrestations.pdf', application: 'gestion' },
			{ title: 'Contribution energétique transport', filename: 'ContributionEnergetiqueTransport.pdf', application: 'gestion' }
		]);
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}

		getModes();
	}

	init();

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(prestation) {
		if (prestation && prestation.id) {
			vm.selectedPrestationId = $stateParams.id;
			vm.lastSelected = vm.selectedPrestationId;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			vm.selectedPrestationId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return (
			$state && $state.current && $state.current.name && $state.current.name === 'prestations.list.detail' && $stateParams && $stateParams.id
		);
	}

	function selectPrestation(prestation) {
		if (prestation && prestation.id) {
			if (vm.selectedPrestationId !== prestation.id) {
				vm.selectedPrestationId = prestation.id;
				showDetail(prestation.id);
			} else {
				vm.selectedPrestationId = undefined;
				closeDetail();
			}
		}
	}

	function isPrestationSelected(prestation) {
		return prestation && prestation.id && vm.selectedPrestationId === prestation.id;
	}

	async function getModes() {
		vm.modes = await PrestationsService.getModesDeclenchement();
	}

	function showDetail(prestationId) {
		$state.go('prestations.list.detail', { id: prestationId });
	}

	function closeDetail() {
		$state.go('prestations.list');
	}

	async function loadPrestations(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'prestations.list') {
			$state.go('prestations.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.prestations = [];

		try {
			const data = await PrestationsService.getPrestations(filters, sorts, pagination);

			vm.prestations = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deletePrestation(prestation) {
		if (prestation && prestation.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('PRESTATIONS.DELETE.TITLE', { code: prestation.libelle }),
				modalMsg: $translate.instant('PRESTATIONS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await PrestationsService.deletePrestationById(prestation.id);
					notification.success($translate.instant('PRESTATIONS.DELETE.SUCCESS'));

					vm.selectedPrestationId = undefined;

					if (vm.params.id) {
						vm.state.go('prestations.list');
					}

					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

					loadPrestations();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function eraseAllFilters() {
		loadPrestations();
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
