import SyntheseEntitiesControleController from './syntheseentities.controle.controller';

export default {
    bindings: {
        syntheseEntity: '=',
        forGeneration: '=',
        ongletOpen: '='
    },
    template: require('./syntheseentities.controle.html'),
    controller: SyntheseEntitiesControleController
};
