import ClientDiversModalController from './client.divers.modal.controller';

export default {
    bindings: {
        clientDiversId: '=',
        societeId: '=',
        modalInstance: '='
    },
    template: require('./client.divers.modal.html'),
    controller: ClientDiversModalController
};
