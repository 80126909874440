export default class MassiaApplicationController {
    static $inject = ['$scope', 'MassiaApplicationService', 'MassiaClientService', 'MassiaAuthService'];

    constructor($scope, MassiaApplicationService, MassiaClientService, MassiaAuthService) {
        this.$scope = $scope;
        this.MassiaApplicationService = MassiaApplicationService;
        this.MassiaClientService = MassiaClientService;
        this.MassiaAuthService = MassiaAuthService;
    }

    $onInit() {
        this.application = this.MassiaApplicationService.application;
        if (this.application === 'massia') {
            this.application = __configuration.defaultApp;
        }
        this.watcher = this.$scope.$watch(
            () => this.MassiaApplicationService.application,
            () => {
                this.application = this.MassiaApplicationService.application;
            }
        );
        this.MassiaApplicationService.setApplication(this.application);
        this.MassiaClientService.setClient(this.client);
        this.MassiaAuthService.authenticateUser(this.MassiaApplicationService.getApplication());

        this.preventMouseWheelOnInputNumber();
    }

    $onDestroy() {
        if (this.watcher) {
            this.watcher();
        }
        this.MassiaApplicationService.resetApplication();
    }

    preventMouseWheelOnInputNumber() {
        document.addEventListener('onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll', function (event) {
            if (
                event &&
                event.currentTarget &&
                event.currentTarget.activeElement &&
                event.currentTarget.activeElement.type &&
                event.currentTarget.activeElement.type === 'number'
            ) {
                event.currentTarget.activeElement.blur();
                //event.preventDefault();
            }
        });
    }
}
