export default class JourFerieDetailController {
    static $inject = [
        '$stateParams',
        '$state',
        'JoursFeriesService',
        'notification',
        'JoursFeriesCommunicationService',
        'moment',
        '_',
        '$scope',
        'globalizationManagementService'
    ];

    constructor(
        $stateParams,
        $state,
        JoursFeriesService,
        notification,
        JoursFeriesCommunicationService,
        moment,
        _,
        $scope,
        globalizationManagementService
    ) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.JoursFeriesService = JoursFeriesService;
        this.notification = notification;
        this.JoursFeriesCommunicationService = JoursFeriesCommunicationService;
        this.moment = moment;
        this._ = _;
        this.$scope = $scope;
        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    async $onInit() {
        this.loading = false;
        this.jourFerie = {};
        this.dt = new Date();
        this.changementDate = false;
        this.jourFerie = {
            id: this.$stateParams.id
        };
        await this.chargeJoursFeries();
        await this.initJourFerie();
        const _this = this;
        this.$scope.$watch('$ctrl.dt', async function (newValue, oldValue) {
            await _this.chargeJoursFeries(true);
        });
    }

    async initJourFerie() {
        if (this.jourFerie.id) {
            await this.chargeJoursFeries();
            const _this = this;
            this.optionsCalendar = {
                customClass: function (dateMode) {
                    const dateActu = dateMode.date;
                    if (dateMode.mode == 'day') {
                        if (!_this.changementDate) {
                            const jourActu = _this.moment(dateActu.toISOString());
                            const actuformate = jourActu.format(this.dateFormat);
                            if (_this.calendrier && _this.calendrier.jours) {
                                const jrTemp = _this.moment(_this.calendrier.jours[15].date.toISOString, this.dateFormat);
                                if (
                                    jourActu.format('DD') == '15' &&
                                    (jourActu.format('MM') != jrTemp.format('MM') || jourActu.format('YYYY') != jrTemp.format('YYYY'))
                                ) {
                                    _this.changementDate = true;
                                    _this.dt = jourActu._d;
                                } else {
                                    const jour = _this._.find(_this.calendrier.jours, { date: actuformate });
                                    if (!jour) {
                                        return 'calendrier-hors-date';
                                    }

                                    if (jour.estFerie) {
                                        return 'calendrier-hors-date';
                                    }
                                }
                            }
                        }
                    }
                },
                showWeeks: false
            };
        } else {
            this.goListJoursFeries();
        }
    }

    async chargeJoursFeries() {
        this.startLoading();
        const y = this.dt.getFullYear();
        const m = this.dt.getMonth();
        const firstDay = new Date(y, m, 1).toISOString();
        const lastDay = new Date(y, m + 1, 0).toISOString();
        const debutMois = this.moment(firstDay).format(this.dateFormat);
        const finMois = this.moment(lastDay).format(this.dateFormat);
        try {
            this.calendrier = await this.JoursFeriesService.getFeriesByJourFerieId(this.jourFerie.id, debutMois, finMois);
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
        if (this.changementDate) {
            this.changementDate = false;
            this.dt = angular.copy(this.dt);
        }
    }

    goListJoursFeries() {
        this.$state.go('jours-feries.list', {}, { reload: true });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
