const serviceUrl = `${__configuration.apiUrl}/massia/affectation-poste-pesee`;

export default class AffectationPostePeseeService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getAffectations() {
        const res = await this.$http.get(serviceUrl);
        return res.data;
    }

    async setAffectation(affectation) {
        const res = await this.$http.post(serviceUrl, [affectation]);
        return res.data;
    }

    async setAffectations(affectations) {
        const res = await this.$http.post(serviceUrl, affectations);
        return res.data;
    }
}
