import WithDateController from './with.date.controller';

export default {
    bindings: {
        frontFilters: '=',
        filterToBack: '=',
        parametre: '=',
        listes: '<',
        libelles: '=',
        source: '<',
        domaine: '<',
        onUpdate: '&',
        onDelete: '&',
        dateApplication: '='
    },
    template: require('./with-date.html'),
    controller: WithDateController
};
