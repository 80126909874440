(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.datepicker', [
            'angularMoment',
            'pascalprecht.translate',
            'ui.bootstrap',
            'blocks.helpers',
            'blocks.lodash',
            'blocks.validation',
            'blocks.i18n'
        ]);
})(angular);
