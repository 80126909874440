SyntheseEntityValidator.$inject = ['validator'];

export default function SyntheseEntityValidator(validator) {
    const instance = new validator();

    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').lengthBetweenValues(0, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance.ruleFor('idTrame').notEmpty().when(isTriTrame).withMessage('VALIDATION_NOTEMPTY');

    return instance;

    function isTriTrame(obj, prop) {
        return obj.triEssais == 4;
    }
}
