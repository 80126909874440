import FormuleCalculVerificationController from './formule.calcul.verification.controller';

export default {
    bindings: {
        formule: '=',
        parametresFormulation: '=',
        produit: '<',
        isCalculated: '='
    },
    controller: FormuleCalculVerificationController,
    template: require('./formule.calcul.verification.html')
};
