import Contact from './mini.form.contact.model';

export default class PersonneFormController {
    static $inject = ['_', '$scope', 'PersonnesService', 'MassiaApplicationService', 'notification'];

    newContact = new Contact();
    displayModal = false;

    constructor(_, $scope, PersonnesService, MassiaApplicationService, notification) {
        this._ = _;
        this.$scope = $scope;
        this.PersonnesService = PersonnesService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.notification = notification;
    }

    async $onInit() {
        this.newObject = this.newContact;
        if (!this.newContact) {
            this.newContact = new Contact();
        }
    }

    async valider() {
        this.newContact.idSite = this.client != null ? this.client.id : null;

        if (this.checkValidity()) {
            try {
                this.newContact.id = await this.createPersonne();
                this.selectNewContact();
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.closeModal();
            }
        }
    }

    async createPersonne() {
        try {
            const returnedId = await this.PersonnesService.createPersonne(this.newContact, this.MassiaApplicationService.getApplication());
            this.notification.success('PERSONNES.CREATED');

            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    checkValidity() {
        let validity = true;
        if (!this.newContact.isValid()) {
            validity = false;
            this.$scope.$broadcast('contactValidations');
        }

        if (this.newContact.isDematBLSMS && (this.newContact.telMobile == null || this.newContact.telMobile == '')) {
            this.notification.error('PERSONNES.TELMOBILE_MANQUANT');
            validity = false;
        }

        if (this.newContact.isDematBLMail && (this.newContact.mail == null || this.newContact.mail == '')) {
            this.notification.error('PERSONNES.MAIL_MANQUANT');
            validity = false;
        }

        return validity;
    }

    closeModal() {
        this.displayModal = false;
        this.newContact = new Contact();
    }
}
