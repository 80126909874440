EquipagesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'EquipagesService',
    'notification'
];

export default function EquipagesController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    EquipagesService,
    notification
) {
    const vm = this;

    const watchers = [];

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.equipages = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };
    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.loadEquipages = loadEquipages;
    vm.deleteEquipage = deleteEquipage;
    vm.createEquipage = createEquipage;
    vm.updateEquipage = updateEquipage;
    vm.activeEditMode = activeEditMode;
    vm.desactiveEditMode = desactiveEditMode;
    vm.getVehicules = getVehicules;
    vm.videVehicule = videVehicule;
    vm.changeSociete = changeSociete;
    vm.changeChauffeur = changeChauffeur;
    vm.setTypeAheadAs = setTypeAheadAs;
    vm.societeId = null;
    vm.siteId = null;

    async function init() {
        vm.societes = await EquipagesService.getSocietes();
        vm.sites = await EquipagesService.getSites();
        vm.ongletOpen = {
            isFiltreOpen: true
        };
        vm.isFiltrer = false;
    }

    init();

    function videVehicule() {
        vm.newVehicule = undefined;
    }

    async function getVehicules(valeur) {
        const vehicules = await EquipagesService.getVehicules(valeur);
        return vehicules;
    }

    async function loadEquipages() {
        startLoading();

        vm.equipages = [];

        try {
            if (vm.date && (vm.societeId || vm.siteId)) {
                vm.newVehicule = undefined;
                vm.equipages = await EquipagesService.getEquipages(vm.date, vm.societeId, vm.siteId);
                vm.entete = await EquipagesService.getEquipageEntete(vm.date, vm.societeId, vm.siteId);
                vm.isFiltrer = true;
                vm.chauffeurs = await EquipagesService.getChauffeurs(vm.societeId, vm.siteId);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function changeSociete() {
        vm.sites = await EquipagesService.getSites(vm.societeId);
    }

    async function changeChauffeur() {
        if (!vm.newEquipage) {
            vm.newEquipage = {};
        }
        vm.newEquipage.isDisponible = vm.chauffeurSelected.isPresent;
        vm.newEquipage.chauffeurId = vm.chauffeurSelected.id;
    }

    async function deleteEquipage(equipage) {
        if (equipage && equipage.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('EQUIPAGES.DELETE.TITLE', { code: equipage.code }),
                modalMsg: $translate.instant('EQUIPAGES.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await EquipagesService.deleteEquipageById(equipage.id);
                    notification.success($translate.instant('EQUIPAGES.DELETE.SUCCESS'));
                    //selectedDemandeLivraisonId = undefined;
                    if (vm.params.id) {
                        vm.state.go('equipage.list');
                    }
                    loadEquipages();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function activeEditMode(equipage) {
        equipage.isEditMode = true;
        this.oldCommentaire = equipage.commentaire;
        setEquipageAsTypeAhead(equipage);
    }

    function desactiveEditMode(equipage) {
        if (this.oldCommentaire != equipage.commentaire) {
            equipage.commentaire = this.oldCommentaire;
        }
        equipage.isEditMode = false;
    }

    async function createEquipage(equipage) {
        equipage.vehiculeId = vm.newVehicule.id;
        if (equipage && equipage.chauffeurId && equipage.vehiculeId) {
            // const modalInstance = ModalService.confirm({
            //     modalTitle: $translate.instant('EQUIPAGES.CREATE.TITLE'),
            //     modalMsg: $translate.instant('EQUIPAGES.CREATE.MESSAGE'),
            //     headerClass: 'modal-danger'
            // });

            // modalInstance.result
            //     .then(async function() {
            startLoading();
            try {
                equipage.societeId = vm.societeId;
                equipage.siteId = vm.siteId;
                equipage.date = vm.date;
                await EquipagesService.createEquipage(equipage);
                notification.success($translate.instant('EQUIPAGES.CREATE.SUCCESS'));
                loadEquipages();
                vm.newEquipage = {};
            } catch (ex) {
                notification.error(ex.data);
            } finally {
                stopLoading();
            }
            // });
        }
    }

    async function updateEquipage(equipage) {
        if (equipage && equipage.chauffeurId && equipage.vehiculeId) {
            // const modalInstance = ModalService.confirm({
            //     modalTitle: $translate.instant('EQUIPAGES.UPDATE.TITLE'),
            //     modalMsg: $translate.instant('EQUIPAGES.UPDATE.MESSAGE'),
            //     headerClass: 'modal-danger'
            // });

            // modalInstance.result
            //     .then(async function() {
            startLoading();
            try {
                equipage.societeId = vm.societeId;
                equipage.siteId = vm.siteId;
                equipage.date = vm.date;
                await EquipagesService.updateEquipage(equipage);
                notification.success($translate.instant('EQUIPAGES.UPDATE.SUCCESS'));
                vm.newEquipage = {};
                loadEquipages();
            } catch (ex) {
                notification.error(ex.data);
            } finally {
                stopLoading();
            }
            // });
        }
    }

    function setTypeAheadAs(equipage) {
        equipage.vehiculeId = vm.vehicule.id;
        equipage.vehicule = vm.vehicule.immatriculations;
    }

    function setEquipageAsTypeAhead(equipage) {
        vm.vehicule = {
            id: equipage.vehiculeId,
            immatriculations: equipage.vehicule
        };
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
