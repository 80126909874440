export default function Configure($stateProvider) {
    $stateProvider.state('carnetsSuivi', {
        parent: 'gestion',
        url: '/carnetsSuivi',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
        }
    });

    $stateProvider.state('carnetsSuivi.list', {
        url: '/list',
        template: '<carnets-suivi></carnets-suivi>',
        rights: { domain: 'carnets', right: 'read' },
        ncyBreadcrumb: {
            parent: 'carnetsSuivi',
            label: '{{ "CARNETS_SUIVI.BREADCRUMBS.CARNETS_LIST" | translate}}'
        },
        navigation: {
            menu: 'pesees_livraisons',
            translate: 'CARNETS_SUIVI.BREADCRUMBS.CARNETS_LIST',
            order: 8
        },
        search: 'CARNETS_SUIVI.BREADCRUMBS.CARNETS_LIST'
    });

    // $stateProvider.state('carnets.list.detail', {
    //     url: '/{id}',
    //     template: '<carnet-detail></carnet-detail>',
    //     rights: {domain: 'carnets', right: 'read'},
    //     ncyBreadcrumb: {
    //         // on décide que la liste est le parent des autres states
    //         // afin d'afficher chantiers > détail
    //         parent: 'carnets.list',
    //         label: '{{ "CARNETS.BREADCRUMBS.CARNET_DETAIL" | translate }}'
    //     }
    // });

    // $stateProvider.state('carnets.new', {
    //     url: '/new',
    //     template: '<carnet-form></carnet-form>',
    //     rights: {domain: 'carnets', right: 'create'},
    //     ncyBreadcrumb: {
    //         // on décide que la liste est le parent des autres states
    //         // afin d'afficher chantiers > ajouter
    //         parent: 'carnets.list',
    //         label: '{{ "CARNETS.BREADCRUMBS.CARNET_NEW" | translate}}'
    //     },
    //     search: 'CARNETS.BREADCRUMBS.CARNET_NEW'
    // });

    // $stateProvider.state('carnets.edit', {
    //     url: '/{id}/edit',
    //     template: '<carnet-form></carnet-form>',
    //     rights: {domain: 'carnets', right: 'update'},
    //     ncyBreadcrumb: {
    //         // on décide que la liste est le parent des autres states
    //         // afin d'afficher chantiers > modifier
    //         parent: 'carnets.list',
    //         label: '{{ "CARNETS.BREADCRUMBS.CARNET_EDIT" | translate }}'
    //     }
    // });
}

Configure.$inject = ['$stateProvider'];
