import validatorBuilder from 'validator-builder';

let produitEnteteNormeValidator = null;

export default class ProduitEnteteNorme {
    constructor(data = {}) {
        this.id = data.id;
        this.typeId = data.typeId;
        this.typeCode = data.typeCode;
        this.idSite = data.idSite;
        this.idSerieDim = data.idSerieDim;
        this.tamisGrandD = data.tamisGrandD;
        this.tamisPetitd = data.tamisPetitd;
        this.idPetitd = data.idPetitd;
        this.idGrandD = data.idGrandD;
        this.granulometrie = data.granulometrie;
        this.code = data.code;
        this.libelle = data.libelle;
        this.appelation = data.appelation;

        this.sites = data.sites || [];
        this.sitesIds = data.sitesIds || [];
    }

    isValid() {
        produitEnteteNormeValidator = produitEnteteNormeValidator || validatorBuilder.getInstanceFor('ProduitEnteteNorme');
        const validationResults = produitEnteteNormeValidator.validate(this);
        return validationResults.isValid;
    }
}
