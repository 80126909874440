import validatorBuilder from 'validator-builder';

let offreProducteurValidator = null;

export default class OffreProducteur {
    constructor(typeES, siteCommercial) {
        this.id = -1;
        this.ligne = 1;
        this.typeES = typeES || 1;
        this.commentaire = 'data.commentaire';
        this.idSiteCommercial = siteCommercial.id;
        this.siteCommercial = siteCommercial.libelle;
        this.produits = [];
    }

    isValid() {
        offreProducteurValidator = offreProducteurValidator || validatorBuilder.getInstanceFor('OffreProducteur');
        const validationResults = offreProducteurValidator.validate(this);
        return validationResults.isValid;
    }
}
