import { getNatureNamesByValues } from '../../../caracteristiques/services/natures.enum';

export default class SerieTamisDetailController {
    static $inject = ['$stateParams', '$state', 'SeriesTamisService', 'notification', 'SeriesTamisCommunicationService', 'MassiaApplicationService'];

    constructor($stateParams, $state, SeriesTamisService, notification, SeriesTamisCommunicationService, MassiaApplicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.SeriesTamisService = SeriesTamisService;
        this.notification = notification;
        this.SeriesTamisCommunicationService = SeriesTamisCommunicationService;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        this.loading = false;
        this.serieTamis = {};
        this.caracteristiques = [];

        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.initSerieTamis();
    }

    async initSerieTamis() {
        this.serieTamis = { id: this.$stateParams.id };
        if (this.serieTamis.id) {
            this.startLoading();
            try {
                this.serieTamis = await this.SeriesTamisService.getSerieTamisEnteteById(this.serieTamis.id);
                this.caracteristiques = await this.SeriesTamisService.getSerieTamisValeursCaracteristiquesById(this.serieTamis.id);
                this.setCaracteristiques(this.caracteristiques);
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListSerieTamis();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListSerieTamis();
        }
    }

    async setCaracteristiques(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiques = [];
        caracteristiques.forEach((c) => {
            this.caracteristiques.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    goListSerieTamis() {
        if (this.MassiaApplicationService.getApplication() == 'laboratoire') {
            this.$state.go('series-tamis-labo.list', {}, { reload: true });
        } else {
            this.$state.go('series-tamis.list', {}, { reload: true });
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
