(function () {
    'use strict';

    angular.module('blocks.helpers').directive('acEnter', acEnter);

    function acEnter() {
        return {
            restrict: 'A',
            link : function(scope, element, attrs) {
                element.bind('keydown keypress', function (event) {
                    if(event.which === 13) {
                        scope.$apply(function (){
                            scope.$eval(attrs.acEnter);
                        });

                        event.preventDefault();
                    }
                });
            }
        };
    }
})();
