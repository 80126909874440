import { UserService } from '../../../../../../../../../core/http/user.service';
import { FilterType } from '../../../../../../../../../core/interface/FilterProperty';
import Synthese from '../../../../../../models/synthese.model';

class SyntheseSettingController {
    private $scope: ng.IScope;
    private userSvc: UserService;
    private $filter: ng.IFilterFunction;
    model: Synthese;
    selectedUser: any;
    users: any;
    /* @ngInject */
    constructor($scope: ng.IScope, UserService: UserService, $filter: ng.IFilterFunction) {
        this.$scope = $scope;
        this.userSvc = UserService;
        this.$filter = $filter;
    }
    $onInit(): void {
        this.loadUser();
    }
    $onDestroy(): void {}

    private async loadUser(): Promise<void> {
        var users = await this.userSvc.getAll(
            [
                { criterion: 'isUtilisateur', value: true, type: FilterType.EQUALS },
                { criterion: 'isEnabled', value: true, type: FilterType.EQUALS },
            ],
            [],
            { skip: 0, take: 0 }
        );
        this.users = this.$filter('selectInIsteven')(users.items, 'id', this.model.blocklist, 'selected', 'id');
    }

    selectUser(): void {
        this.model.blocklist = this.selectedUser;
    }
}

export default {
    bindings: {
        model: '=',
    },
    controller: SyntheseSettingController,
    template: require('./synthese.setting.tpl.html'),
};
