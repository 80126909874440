NomenclaturesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'NomenclaturesService',
	'notification',
	'$transitions'
	//'MOPService'
];

export default function NomenclaturesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	NomenclaturesService,
	notification,
	$transitions
	//MOPService
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.nomenclatures = [];
	vm.isEnabled = true;

	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.loadNomenclatures = loadNomenclatures;
	vm.deleteNomenclature = deleteNomenclature;
	vm.activeNomenclature = activeNomenclature;
	vm.eraseAllFilters = eraseAllFilters;
	//vm.MOPService = MOPService;

	function init() {
		//vm.MOPService.setMop([{ title: 'PAIEMENTS.BREADCRUMBS.PAIEMENTS_LIST', filename: 'ModesPaiement.pdf', application: 'gestion' }]);
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));
	}

	init();

	vm.$onDestroy = () => {
		//vm.MOPService.resetMop();
	};

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	async function loadNomenclatures(tableState) {
		startLoading();

		//quand on rafraîchit la grille, on revient sur "Paiements.list", et on ferme la vue détail d'un Paiement
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== 'nomenclatures.list') {
			$state.go('nomenclatures.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.nomenclatures = [];

		try {
			const data = await NomenclaturesService.getNomenclatures(vm.isEnabled, filters, sorts, pagination);
			vm.nomenclatures = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function activeNomenclature(nomenclature) {
		if (nomenclature && nomenclature.id) {
			let deleteTitle = $translate.instant('NOMENCLATURES.DESACTIVE.TITLE', { code: nomenclature.libelle });
			let deleteMessage = $translate.instant('NOMENCLATURES.DESACTIVE.MESSAGE');
			let deleteSuccess = $translate.instant('NOMENCLATURES.DESACTIVE.SUCCESS');
			if (!this.isEnabled) {
				deleteTitle = $translate.instant('NOMENCLATURES.ACTIVE.TITLE', { code: nomenclature.libelle });
				deleteMessage = $translate.instant('NOMENCLATURES.ACTIVE.MESSAGE');
				deleteSuccess = $translate.instant('NOMENCLATURES.ACTIVE.SUCCESS');
			}

			const modalInstance = ModalService.confirm({
				modalTitle: deleteTitle,
				modalMsg: deleteMessage,
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await NomenclaturesService.activeNomenclatureById(nomenclature.id);
					notification.success(deleteSuccess);

					if (vm.params.id) {
						vm.state.go('nomenclatures.list');
					}
					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);
					loadNomenclatures();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function deleteNomenclature(nomenclature) {
		if (nomenclature && nomenclature.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('NOMENCLATURES.DELETE.TITLE', { code: nomenclature.libelle }),
				modalMsg: $translate.instant('NOMENCLATURES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await NomenclaturesService.deleteNomenclatureById(nomenclature.id);
					notification.success($translate.instant('NOMENCLATURES.DELETE.SUCCESS'));

					if (vm.params.id) {
						vm.state.go('nomenclatures.list');
					}
					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);
					loadNomenclatures();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function eraseAllFilters() {
		loadPaiements();
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
