import { copy } from 'angular';
import { SiteService } from '../../../../../../core/http/site.service';

export default class ProduitFormSiteLiesController {
    constructor(ProduitsService, notification, $scope, _, $timeout, $translate, SiteService, SitesService, ModalService) {
        this.ProduitsService = ProduitsService;
        this.notification = notification;
        this.$scope = $scope;
        this._ = _;
        this.$timeout = $timeout;
        this.$translate = $translate;
        this.displayPrice = false;
        /**
         * @type SiteService
         */
        this.siteSvc = SiteService;
        this.SitesService = SitesService;
        this.ModalService = ModalService;
    }

    async $onInit() {
        this.clients = [];
        this.input = [];
        this.siteClient = null;
        this.table = [];
        this.ongletOpen = {
            isEnteteOpen: true,
            isProducteursOpen: true,
            isSocieteLaboOpen: true
        };
        this.unregister = [];
        this.unregister.push(
            this.$scope.$watchCollection(
                () => this.produit.producteurs,
                () => {
                    const clients = [];
                    this._.forEach(this.produit.producteurs, (producteur) => {
                        const client = this.produit.siteLinked?.find((x) => x.producteur.id === producteur.id);
                        if (client) {
                            clients.push(client);
                        } else {
                            const newClient = {
                                producteur: producteur,
                                clients: []
                            };
                            clients.push(newClient);
                        }
                    });
                    this.produit.siteLinked = clients;
                    this.clients = clients;
                }
            )
        );
        this.loadSiteClient();
        /** Init price list */
        if (__configuration.licenceLaboratoire === '2') {
            // Eqiom's BPE's price is set automatically
            if (this.produit.type?.famille?.code !== 'BPE' && this.produit.type?.code !== 'GRADCURV') {
                this.displayPrice = true;
                //this.producteurPrice({ producteurs: this.produit.siteLinked, initList: true, pIsNeeded: false });
            } else {
                this.displayPrice = false;
            }
        } else {
            this.displayPrice = true;
            //this.producteurPrice({ producteurs: this.produit.siteLinked, initList: true, pIsNeeded: false });
        }
    }

    $onDestroy() {
        for (let i = 0; i < this.unregister.length; i++) {
            this.unregister[i]();
        }
    }

    getClient(producteur) {
        let linked = this.produit.siteLinked?.find((x) => x.producteur.id === producteur.id);
        if (linked) {
            return linked.clients;
        }
        linked = {
            producteur: producteur,
            clients: []
        };
        if (!this.produit.siteLinked) {
            this.produit.siteLinked = [];
        }
        this.produit.siteLinked.push(linked);
        return linked.clients;
    }

    async loadSiteClient() {
        //sites clients et producteurs

        /*************** list de tout les client */
        // const allSites = await this.siteSvc.getAll([
        //     {
        //         criterion: 'genre',
        //         type: FilterType.EQUALS,
        //         value: SiteGenre.Client
        //     }
        // ]);
        const filter = [
            {
                criterion: 'genre',
                value: '4',
                type: 'Eq'
            }
        ];
        const allSites = await this.SitesService.getSitesClients(filter, null, null, null);
        this.siteClient = allSites.items;
    }

    initSiteClient(index, clients) {
        this.$timeout(() => {
            if (this.siteClient) {
                const clone = this._.cloneDeep(this.siteClient);
                this.input[index] = clone;
            } else {
                this.input[index] = clients;
                this.$timeout(() => {
                    this.initSiteClient(index, clients);
                }, 500);
            }
        }, 0);
    }

    selectClients(producteur, selectedSite) {
        const linked = this.produit.siteLinked?.find((x) => x.producteur.id === producteur.id);

        if (selectedSite && selectedSite.length > 0) {
            for (let i = 0; i < selectedSite.length; i++) {
                const newClient = selectedSite[i];

                const idx = linked.clients.findIndex((e) => e.id === newClient.id);
                if (idx === -1) {
                    //  console.log(newClient);
                    newClient.isActivated = true;
                } else {
                    newClient.isActivated = linked.clients[idx].isActivated;
                }

                /*  selectedSite.map(e => {
                    if (e.isActivated && e.id !== client.id) e.isActivated = true;

                    return e;
                }); */
            }
        }

        linked.clients = selectedSite;

        // console.log(this.produit, linked.clients, selectedSite);
    }

    cloneTable(table, index, toClone) {
        table[index] = copy(toClone);
    }

    debug(item) {
        // console.log(item, this.produit);
    }

    /* async loadSiteLiesLabodada() {
        if (!this.loading) {
            this.startLoading();
            try {
                this.produit.siteLies = [];
                if (this.produit.entete.sites && this.produit.entete.sites.length > 0) {
                    this.ongletOpen.isSiteLiesLaboOpen = true;
                    //list des clients lies au producteur lies au produit
                    const res = await this.ProduitsService.getProduitSiteLiesLabo(this.produit.entete.id || 0, 0);
                    for (let i = 0; i < this.produit.entete.sitesIds.length; i++) {
                        const producteurId = this.produit.entete.sitesIds[i];
                        const idxElem = this.produit.siteLies.findIndex(e => e.producteurId === producteurId);
                        if (idxElem < 0) {
                            let produitSiteLiesLabo = null;
                            let idxRes = -1;
                            if (res && res.length > 0) {
                                idxRes = res.findIndex(e => e.producteurId === producteurId);
                            }

                            if (!res || res.length === 0 || idxRes < 0) {
                                produitSiteLiesLabo = new ProduitSiteLiesLabo();
                                produitSiteLiesLabo.producteurId = producteurId;
                                produitSiteLiesLabo.produitId = this.produit.entete.id;
                                produitSiteLiesLabo.libelleProducteur = this.produit.entete.sites[i];
                            } else {
                                produitSiteLiesLabo = new ProduitSiteLiesLabo(res[idxRes]);
                            }

                            this.produit.siteLies.push(produitSiteLiesLabo);
                        }
                    }
                } else {
                    this.ongletOpen.isSiteLiesLaboOpen = false;
                }
            } catch (ex) {
                this.notification.error(ex);
            } finally {
                this.stopLoading();
            }
        }
    } */

    /**isteeven*/
    getSelected(items, table, theme) {
        if (table && items) {
            if (Array.isArray(items[theme])) {
                const list = items[theme];
                for (let i = 0; i < list.length; i++) {
                    const l = list[i];
                    const index = table.findIndex((x) => x.id === l);
                    if (index !== -1) {
                        table[index].selected = true;
                    }
                }
            } else {
                const index = table.findIndex((x) => x.id === items[theme]);
                if (index !== -1) {
                    table[index].selected = true;
                }
            }
        }
    }

    async changeActiveStatus(client, producteur, checked) {
        this.startLoading();
        try {
            client.isActivated = checked;
            if (client.isActivated) {
                const that = this;
                const modalInstance = this.ModalService.confirm({
                    modalTitle: that.$translate.instant('PRODUITS.DESACTIVE.TITLE_4'),
                    modalMsg: that.$translate.instant('PRODUITS.DESACTIVE.MESSAGE_4'),
                    headerClass: 'modal-danger'
                });

                modalInstance.result.then(
                    async function () {
                        try {
                            await that.ProduitsService.activeProduitSiteLie(that.produit.id, producteur.id, client.id);
                            that.notification.success('PRODUITS.DESACTIVE.SUCCESS_REACTIVE_4');
                        } catch (ex) {
                            client.isActivated = !checked;
                        }
                    },
                    async function () {
                        client.isActivated = !checked;
                    }
                );
            } else {
                const that = this;
                const modalInstance = this.ModalService.confirm({
                    modalTitle: that.$translate.instant('PRODUITS.DESACTIVE.TITLE_5'),
                    modalMsg: that.$translate.instant('PRODUITS.DESACTIVE.MESSAGE_5'),
                    headerClass: 'modal-danger'
                });

                modalInstance.result.then(
                    async function () {
                        await that.ProduitsService.desactiveProduitSiteLie(that.produit.id, producteur.id, client.id);
                        that.notification.success('PRODUITS.DESACTIVE.SUCCESS_DESACTIVE_5');
                    },
                    async function () {
                        client.isActivated = !checked;
                    }
                );
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    /**isteeven*/
    selectItem(data, items, theme) {
        const tmp = items[theme].findIndex((x) => x === data.id);
        if (tmp === -1) {
            items[theme].push(data.id);
        } else {
            items[theme].splice(tmp, 1);
        }
    }

    /**isteeven*/
    getTable(items, table, theme) {
        items[theme] = angular.copy(table);
    }
    /**isteeven*/
    selectAll(items, theme) {
        items.detailClient = items[theme].map((x) => x.id);
    }
    /**isteeven*/
    selectNone(items) {
        items.detailClient = [];
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    static $inject = ['ProduitsService', 'notification', '$scope', '_', '$timeout', '$translate', 'SiteService', 'SitesService', 'ModalService'];
}
