import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-gauge',
    bindings: {
        id: '@',
        titre: '<',
        min: '<',
        max: '<',
        realValue: '<',
        info: '<',
        unit: '<',
        precision: '<',
        ranges: '<'
    },
    template: require('./massia.gauge.tpl.html')
})
export class MassiaGaugeController {
    constructor($scope, $sce, $templateCache) {
        this.$scope = $scope;
        this.$sce = $sce;
        this.$templateCache = $templateCache;
    }

    $onInit() {
        this.titre = this.titre ? this.titre : 0;
        this.min = this.min ? this.min : 0;
        this.max = this.max ? this.max : 100;
        this.realValue = this.realValue ? this.realValue : 0;
        this.info = this.info ? this.info : 0;
        this.unit = this.unit ? this.unit : '';
        this.precision = this.precision ? this.precision : 2;
        this.ranges = this.ranges ? this.ranges : [];
        this.fontStyle = "16px 'Helvetica Neue', Helvetica, Arial, sans-serif";

        // [{
        //     min: 0,
        //     max: 50,
        //     color: '#3F9C35'
        // }, {
        //     min: 50,
        //     max: 100,
        //     color: '#FF0000'
        // }];
    }

    async $onChanges(data) {}
}

MassiaGaugeController.$inject = ['$scope', '$sce', '$templateCache'];
