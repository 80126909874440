import PrestationsConfig from './_config/prestations.config';
import PrestationsRoutes from './_config/prestations.routes';
import PrestationsComponent from './components/prestations';
import PrestationsService from './services/prestations.service';
import PrestationsCommunicationService from './services/prestations.communication.service';

import PrestationFormComponent from './components/prestation-form';
import PrestationDetailComponent from './components/prestation-detail';
import PrestationEnteteComponent from './components/prestation-entete';

import PrestationValidator from './components/prestation-form/prestation.validator';
import PrestationEnteteValidator from './components/prestation-entete/prestation.entete.validator';

const moduleName = 'app.parametrageGeneral.prestations';

angular
    .module(moduleName, [])
    .config(PrestationsConfig)
    .config(PrestationsRoutes)
    .service('PrestationsService', PrestationsService)
    .service('PrestationsCommunicationService', PrestationsCommunicationService)
    .component('prestations', PrestationsComponent)
    .component('prestationForm', PrestationFormComponent)
    .component('prestationDetail', PrestationDetailComponent)
    .component('prestationEntete', PrestationEnteteComponent)
    .factory('PrestationValidator', PrestationValidator)
    .factory('PrestationEnteteValidator', PrestationEnteteValidator);

export default moduleName;
