import DynamicFormController from './dynamic.form.controller';

export default {
    bindings: {
        configuration: '=',
        readonly: '<',
        field: '<',
        nature: '<',
        model: '=',
        modelName: '@',
        validationDisabled: '=',
        lecture: '<',
        bornes: '<',
        supprimable: '<',
        onDeleteMesure: '&',
        niveauVisibilite: '=',
        principal: '<',
        onExitMesure: '&',
        displayLabel: '<',
        xaxeAngleRotation: '@',
        yaxeAngleRotation: '@',
        rightImportGranulo: '<',
        saisie: '='
    },
    template: require('./templates/base.tpl.html'),
    controller: DynamicFormController
};
