export default function Configure($stateProvider) {
    $stateProvider.state('devises', {
        parent: 'common',
        url: '/devises',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('devises.list', {
        url: '/list',
        template: '<devises></devises>',
        rights: { domain: 'devise', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "DEVISES.BREADCRUMBS.DEVISES_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'DEVISES.BREADCRUMBS.DEVISES_LIST',
            order: 21
        },
        search: 'DEVISES.BREADCRUMBS.DEVISES_LIST'
    });

    $stateProvider.state('devises.list.detail', {
        url: '/{id}',
        template: '<devise-detail></devise-detail>',
        rights: { domain: 'devise', right: 'read' },
        ncyBreadcrumb: {
            parent: 'devises.list',
            label: '{{ "DEVISES.BREADCRUMBS.DEVISE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('devises.new', {
        url: '/new',
        template: '<devise-form></devise-form>',
        rights: { domain: 'devise', right: 'create' },
        ncyBreadcrumb: {
            parent: 'devises.list',
            label: '{{ "DEVISES.BREADCRUMBS.DEVISE_NEW" | translate}}'
        },
        search: 'DEVISES.BREADCRUMBS.DEVISE_NEW'
    });

    $stateProvider.state('devises.edit', {
        url: '/{id}/edit',
        template: '<devise-form></devise-form>',
        rights: { domain: 'devise', right: 'update' },
        ncyBreadcrumb: {
            parent: 'devises.list',
            label: '{{ "DEVISES.BREADCRUMBS.DEVISE_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
