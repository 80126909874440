import angular from 'angular';
import Formule from './formule.model';
/* @ngInject */
export default class FormuleFormController {
	constructor(
		_,
		$scope,
		$timeout,
		$state,
		$stateParams,
		FormulesCommunicationService,
		FormuleService,
		notification,
		$location,
		$anchorScroll,
		$uibModal,
		moment,
		MassiaApplicationService,
		globalizationManagementService,
		TemplateImpressionService,
		ProduitsService,
		ParametreProgrammeService,
		RouterHistoryService,
		ModalService,
		$translate
	) {
		this._ = _;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.FormulesCommunicationService = FormulesCommunicationService;
		this.FormuleService = FormuleService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.$uibModal = $uibModal;
		this.moment = moment;
		this.MassiaApplicationService = MassiaApplicationService;
		this.TemplateImpressionService = TemplateImpressionService;
		this.ProduitsService = ProduitsService;
		this.ParametreProgrammeService = ParametreProgrammeService;
		this.RouterHistoryService = RouterHistoryService;
		this.ModalService = ModalService;
		this.$translate = $translate;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
		this.chart = null;
		this.chartRecompo = null;
		this.isArchiveAuto = false;
	}

	$onInit() {
		// Propriétés
		this.formules = {};
		this.loading = false;
		this.formule = new Formule();
		this.update = false;
		this.app = this.MassiaApplicationService.getApplication();

		// Configuration
		this.reset();
		if (this.formules.producteurId) {
			this.getProducteur();
		}
		if (this.formules.produitId) {
			this.getProduit();
		}
	}

	$onDestroy() {
		this.$timeout.cancel(this.updateFormuleTimeout);
	}

	backToPreviousState() {
		if (this.$state.params.data && this.$state.params.data.base) {
			this.$state.go(this.$state.params.data.base);
		} else {
			this.$state.go('produits.edit', { id: this.$stateParams.produitId });
		}
	}

	async reset() {
		this.startLoading();
		this.formules = {
			enCours: {},
			archives: {},
			produitId: this.$stateParams.produitId,
			producteurId: this.$stateParams.producteurId
		};

		this.formule.idProduit = this.formules.produitId;
		this.formule.idProducteur = this.formules.producteurId;

		if (this.formules.produitId && this.formules.producteurId) {
			try {
				this.formules.liste = await this.FormuleService.getFormuleFor(this.formules.produitId, this.formules.producteurId);

				if (this.formules.liste.length > 0) {
					this.update = true;
				}
			} catch (err) {
				if (err.data) {
					this.notification.warning(err.data);
				} else {
					throw err;
				}
			}
		}

		this.ongletOpen = {
			isEnteteOpen: true,
			isArchivesOpen: false
		};

		this.$timeout.cancel(this.updateFormuleTimeout);
		this.updateFormuleTimeout = this.$timeout(() => this.stopLoading());
	}

	async getProducteur() {
		try {
			this.producteur = await this.FormuleService.getProducteurById(this.formules.producteurId, this.MassiaApplicationService.getApplication());
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}
	async getProduit() {
		try {
			this.produit = await this.FormuleService.getProduitById(this.formules.produitId);
			this.formule.codeFamilleProduit = this.produit.famille;
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}

	async sauvegarder() {
		try {
			this.startLoading();
			if (this.checkComposantEmpty()) {
				const err = { data: 'FORMULES.COMPOSANTS.EMPTY_QUANTITE' };
				throw err;
			}

			this.formule.code = this.producteur.code + '' + this.produit.code;
			this.formule.idProducteur = this.producteur.id;
			this.formule.IdProduitCompose = this.produit.id;

			if (this.checkValidity()) {
				let id = false;

				this.saveOrdre();

				if (this.formule.dateCreation !== null) {
					if (this.update) {
						id = await this.FormuleService.updateFormule(this.formule.idComposition, this.formule);
						this.notification.success('FORMULES.MESSAGES.BODY.UPDATED');
					} else {
						id = await this.FormuleService.createFormule(this.formule);

						this.notification.success('FORMULES.MESSAGES.BODY.CREATED');
						this.formule.idComposition = id;
						this.update = true;
					}
					this.reset();
				} else {
					this.notification.error('FORMULES.ERROR');
					return -1;
				}
				return 1;
			}
		} catch (err) {
			if (err && err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
		this.stopLoading();
	}

	// Modification: Archivage automatique (ou pas) selon Parametre-programme
	async archivageAutomatiqueOuPas() {
		// récupérer le paramètre programme par code "BooProdFormAchi"
		const BooProdFormAchi = await this.ParametreProgrammeService.getOneByCode('BooProdFormAchi');
		this.isArchiveAuto = BooProdFormAchi;
		// Archivage automatique
		if (BooProdFormAchi == '1') {
			await this.archiver();
		} // Non Archivage automatique
		else {
			this.notification.info(this.$translate.instant('FORMULES.NOT_ARCHIVED'));
		}
	}

	saveOrdre() {
		for (let i = 0; i < this.formule.composants.length; i++) {
			this.formule.composants[i].ordre = i + 1;
		}
	}

	checkComposantEmpty() {
		if (this.formule && this.formule.composants && this.formule.composants.length > 0) {
			const index = this.formule.composants.findIndex((x) => x.poids === null || x.poids === '');
			if (index > -1) {
				return true;
			}
		}
	}

	async appliquer() {
		if (!this.isValidated) {
			this.isValidated = true;
			const id = await this.sauvegarder();
			if (id && this.formule.id) {
				this.reset();
			} else if (id) {
				if (this.$state.current.name == 'formules.produit') {
					this.$state.go('formules.produit', { id: id });
				} else {
					this.$state.go('formules.edit', { id: id });
				}
			}

			this.isValidated = false;
		}
	}

	async confirmer() {
		if (!this.isValidated) {
			this.isValidated = true;
			try {
				const success = await this.sauvegarder();
				if (success != -1) {
					this.annuler();
				}
			} catch (error) {
				this.notification.error(error.data);
			}
			this.isValidated = false;
		}
	}

	checkConformiteFormule() {
		if (this.formule && this.formule.vdc && this.formule.vdc.length > 0) {
			const idxLocked = this.formule.vdc.findIndex((x) => x.blocageAlerte === 2 && x.conformite === false);
			const idxWarning = this.formule.vdc.findIndex((x) => x.blocageAlerte === 1 && x.conformite === false);
			if (idxLocked > -1) {
				this.formule.locked = true;
			} else {
				this.formule.locked = false;
			}
			if (idxWarning > -1) {
				this.formule.warning = true;
			} else {
				this.formule.warning = false;
			}
			return this.formule;
		}
	}

	async annuler() {
		this.RouterHistoryService.ignoreNextRoute();
		if (this.formule.idProduitCompose) {
			this.$state.go('produits.edit', { id: this.formule.idProduitCompose });
		} else {
			this.backToPreviousState();
		}
	}

	async archiver(dateArchivage) {
		this.formule.code = this.producteur.code + '' + this.produit.code;
		this.formule.idProducteur = this.producteur.id;
		this.formule.IdProduitCompose = this.produit.id;
		let id = false;

		if (dateArchivage) {
			this.formule.dateArchivage = dateArchivage;
		}

		id = await this.FormuleService.archiveFormule(this.formule.idComposition, this.formule, this.app);
		this.notification.success('FORMULES.MESSAGES.BODY.ARCHIVED');
		this.formule.idComposition = id;
		this.update = true;

		if (!this.isArchiveAuto) {
			this.reset();
		} else {
			const formule = await this.FormuleService.getFormuleByProduitProducteur(this.formule.idProduit, this.formule.idProducteur);

			if (formule) {
				this.formule.dateCreation = formule.dateCreation;
				this.formule.dateFinValidite = formule.dateFinValidite;
			}
			this._.forEach(this.formule.composants, (composant) => {
				composant.id = 0;
			});
			this._.forEach(this.formule.compoAddCoef, (compoAddCoef) => {
				compoAddCoef.id = 0;
			});
		}
	}

	async openArchive() {
		await this.sauvegarder();
		let result = null;
		try {
			const modalInstance = this.$uibModal.open({
				component: 'formuleDate',
				resolve: {
					dateArchive: () => this.formule.dateArchivage
				},
				backdrop: false
			});

			result = await modalInstance.result;
			if (result) {
				this.archiver(result);
			}
		} catch (ex) {
			console.error(ex);
		}
	}

	checkValidity() {
		let validity = true;

		//on va seulement scroller et focus sur le premier onglet où il y a une erreur
		let firstScroll = true;
		if (this.checkForGestion()) {
			if (firstScroll) {
				this.scrollToOnglet('creation');
				firstScroll = false;
			}

			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('formuleCreationValidations');

			this.notification.error('FORMULES.MESSAGES.BODY.INCOMPLETE');
		}

		return validity;
	}

	async print() {
		try {
			this.formule.produit = angular.copy(this.produit);
			const domain = 'compo';
			this.resolver = {
				formule: () => this.formule
			};

			if (this.chart) {
				//récupérer la courbe granulo
				const granulo = document.getElementById('graphGran');
				const recompo = document.getElementById('graphRecompo');
				if (granulo != null) {
					const base64 = granulo.toDataURL('image/png');
					this.formule.graphGran = base64;
					const base64V2 = recompo.toDataURL('image/png');
					this.formule.graphRecompo = base64V2;
				} else {
					this.formule.graphGran = null;
					this.formule.graphRecompo = null;
				}
			}
			const formule = angular.copy(this.formule);

			if (this.formule && this.formule.composants && this.formule.composants.length > 0) {
				_.forEach(formule.composants, (composant) => {
					composant.poids = Number.parseFloat(composant.poids).toFixed(2);
				});
			}

			const res = await this.TemplateImpressionService.downloadModal(domain);
			if (res === 'cancel') {
				throw this.notification.warning(this.$translate.instant('FORMULES.PRINTCANCELED'));
			}
			const template = await this.FormuleService.print(formule, res.filename);
			template.pdf = res.pdf;

			await this.TemplateImpressionService.downloadTemplate(template.filename, template, domain);
		} catch (err) {
			if (err) {
				this.notification.error(err);
			}
		}
	}

	async duplicateFrom() {
		try {
			let res = null;
			const modalInstance = this.$uibModal.open({
				component: 'duplicate',
				resolve: {
					source: () => this.source
				},
				animation: true,
				backdrop: false,
				size: 'md'
			});
			res = await modalInstance.result;
			if (res) {
				const formule = await this.FormuleService.getFormuleById(res.selected);
				if (formule) {
					formule.idProducteur = parseFloat(this.formule.idProducteur);
				}
				this.formule = angular.copy(formule);
			}
		} catch (error) {
			console.error('⚾️ ~ duplicateFrom ~ error:', error);
		}
	}

	// duplicateFrom() {
	// 	const _this = this;
	// 	//_this.source = this.produit;
	// 	const modaleDuplication = this.$uibModal.open({
	// 		template: '<duplicate source="$ctrl.source" modal-instance="$ctrl.uibModalInstance"></duplicate>',
	// 		controller: [
	// 			'$uibModalInstance',
	// 			function ($uibModalInstance) {
	// 				const $ctrl = this;
	// 				$ctrl.source = _this.formule;
	// 				$ctrl.uibModalInstance = $uibModalInstance;
	// 			}
	// 		],
	// 		controllerAs: '$ctrl',
	// 		size: 'm'
	// 	});
	// 	modaleDuplication.result.then(
	// 		function (result) {
	// 			_this.getCurrentFormula(result.selected);
	// 		},
	// 		function (reason) {}
	// 	);
	// }

	checkForGestion() {
		return this.formule.pourcentageTotal != 100;
	}

	async composantMultiple() {
		const modalInstance = this.ModalService.confirm({
			modalTitle: this.$translate.instant('FORMULES.MODAL.MULTIPLE_COMPOSANTS.TITLE'),
			modalMsg: this.$translate.instant('FORMULES.MODAL.MULTIPLE_COMPOSANTS.MESSAGE'),
			headerClass: 'modal-warning'
		});

		try {
			await modalInstance.result;

			return true;
		} catch (ex) {
			return false;
		}
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	onUpdateCreation(enCours) {
		this.formules.enCours = enCours;
	}

	onUpdateCaracteristiques(caracteristiques) {
		this.formule.caracteristiques = caracteristiques;
	}

	onUpdateNiveaux(niveaux) {
		this.formule.niveaux = niveaux;
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	reinit() {
		this.reset();
	}
}
