/* eslint-disable brace-style */
/* eslint-disable complexity */
/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable curly */
/* eslint-disable max-depth */

// import ModalInfoFormatController from '../../../portail/portail-setting/components/setting-doc/modal-info-format/modal.info.format.controller';

export default class SyntheseEntityRecompositionController {
    /* @ngInject */
    constructor(
        $scope,
        $state,
        $stateParams,
        SyntheseEntitiesCommunicationService,
        _,
        notification,
        MassiaApplicationService,
        $translate,
        SyntheseEntitiesService,
        $filter,
        ColorPickerService,
        $uibModal,
        moment,
        globalizationManagementService,
        TemplateImpressionService,
        Interpreteur
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
        this._ = _;
        this.notification = notification;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$translate = $translate;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.ColorPickerService = ColorPickerService;
        this.$uibModal = $uibModal;
        this.moment = moment;
        this.Interpreteur = Interpreteur;
        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
        this.TemplateImpressionService = TemplateImpressionService;
    }

    async $onInit() {
        try {
            this.unregisterReinit = this.SyntheseEntitiesCommunicationService.registerReinit(() => this.initForm());

            this.model = this.$state.params.obj || {};
            this.resultat = {};
            this.encours = false;
            this.lastOptimisation = { ecart: 99999, moyennes: [], pourcentages: [] };
            this.tHTot = '';
            this.ecart = '';
            this.arrayRef = { vss: [], vsi: [], xt: [], ecart: [], coeff: [], coeffHF: [], vssinterpol: [], vsiinterpol: [], xtinterpol: [] };
            this.arrayData = []; // tableau des données [colTamis][idProduit] : pourcPassant
            this.arrayPond = []; //tendances des produits (0 à 4)
            this.arrayMin = []; //minimum de % pour chaque produit
            this.arrayMax = []; //maximum de % pour chaque produit
            this.arrayRefEssais = { vss: [], vsi: [], xt: [], ecart: [], coeff: [], coeffHF: [], vssinterpol: [], vsiinterpol: [], xtinterpol: [] };
            this.arrayDataEssais = []; // tableau des données [colTamis][idProduit] : pourcPassant

            this.startLoading();
            this.initForm();

            this.graphData = this.graphData || {};
            this.essaisData = {};
            await this.prepareGraphData();
            this.initTableaux();
            this.calculeRecompo();
            this.stopLoading();

            this.form = {
                courbeGranulo: {}
            };
        } catch (error) {
            console.log(error);
        }
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    initForm() {
        if (this.model && this.model.synthese) {
            try {
                this.startLoading();
                // on met a jour les donnees du scope 'resultat'
                if (this.model.synthese.listeSyntheseCritereValeurBlocks && this.model.synthese.listeSyntheseCritereValeurBlocks.length > 0) {
                    const that = this;
                    //chercher le critère produit
                    this.resultat.produits = this.model.synthese.listeSyntheseCritereValeurBlocks.map(function (p) {
                        //récupérer l'indice de sélection
                        const numSel = p.indiceSelection;
                        //chercher les prélèvements correspondants
                        const prelevsBlock = that._.filter(that.model.resultat.prelevementBlock, function (a) {
                            return that._.includes(a.numsBloc, numSel);
                        });
                        if (prelevsBlock && prelevsBlock.length > 0) {
                            p.idProduit = prelevsBlock[0].idProduit;
                            p.pcentmin = 0;
                            p.pcentmax = 100;
                            p.indexTendance = 2;
                            p.disabled = false;
                            return p;
                        }
                    });

                    this.resultat.produits = this.resultat.produits.filter((e) => e);
                }
                this.formateTableauSynthese();
                this.stopLoading();
            } catch (ex) {
                this.stopLoading();
                this.notification.error(this.$translate.instant('SYNTHENTS.RECOMPO.ERREUR_SEL'));
            }
        } else this.$state.go('syntheseentities.list');
    }

    formateTableauSynthese() {
        this.resultat.entete = { titresAffichage: [], titresEssais: [], titresSousEssais: [] };
        this.resultat.lignes = [];
        this.resultat.stats = [];

        //remettre le total à 100% si ce n'est pas le cas
        let tousSaisis = true;
        let totPourcent = 0;
        for (let a = 0; a < this.resultat.produits.length; a++) {
            if (!this.resultat.produits[a].pourcentageComposant) {
                this.resultat.produits[a].pourcentageComposant = 0;
                tousSaisis = false;
            }

            totPourcent += this.resultat.produits[a].pourcentageComposant;
        }

        if (!totPourcent) {
            const pcent = parseFloat((100 / this.resultat.produits.length).toFixed(2));
            for (let a = 0; a < this.resultat.produits.length; a++) this.resultat.produits[a].pourcentageComposant = pcent;
        } else if (totPourcent != 100) {
            if (totPourcent > 100) {
                //si on a une saisie pour tous mais que le total est supérieur 100%, on remet tout sur une base 100%
                for (let a = 0; a < this.resultat.produits.length; a++)
                    this.resultat.produits[a].pourcentageComposant = parseFloat(
                        ((this.resultat.produits[a].pourcentageComposant * 100) / totPourcent).toFixed(2)
                    );
            } else if (!tousSaisis) {
                //si au moins un produit n'est pas saisi, alors on garde le % des produits saisis et on calcule les manquants
                const diffTo100 = Math.abs(100 - totPourcent);

                const nbPdtNonSaisi = this.resultat.produits.filter((e) => {
                    return !e.pourcentageComposant;
                }).length;
                const pcentARepartir = parseFloat((diffTo100 / nbPdtNonSaisi).toFixed(2));

                this.resultat.produits.map((e) => {
                    if (!e.pourcentageComposant) e.pourcentageComposant = pcentARepartir;
                });
            }
        }

        this.resultat.entete.titresEssais = this.model.formattedResult.entete.titresEssais;
        this.resultat.entete.titresSousEssais = this.model.formattedResult.entete.titresSousEssais;
        const that = this;
        const lignes = this.model.formattedResult.lignes.filter(function (e) {
            const prelevement = that.model.resultat.prelevementBlock.find(function (p) {
                return p.idPrelevement === e.idPrelevement;
            });
            if (prelevement) {
                const produit = that.resultat.produits.find(function (p) {
                    return p.idProduit === prelevement.idProduit;
                });

                if (produit) e.pourcentageComposant = produit.pourcentageComposant + '%';
                e.nbProduits = that.model.resultat.prelevementBlock.filter(function (p2) {
                    return p2.idProduit === prelevement.idProduit;
                }).length;

                if (prelevement.idProduit !== that.lastProductId) e.position = 1;

                that.lastProductId = prelevement.idProduit;
                e.idProduit = prelevement.idProduit;
                e.codePrelevement = prelevement.codePrelevement;
                e.libelleProduit = prelevement.libelleProduit;

                e.datePrelevement = that.moment(prelevement.datePrelevement, that.dateFormat + ' HH:mm').format(that.dateFormat);
                return true;
            }
        });
        this.resultat.lignes = lignes;
        this.resultat.stats = this.model.formattedResult.stats;
        /*this.resultat.recompoStats = [];
        //ajout des stats paramétrées
        /*for (let i = 0; i < this.resultat.stats.length; i++) {
            if(this.resultat.stats[i].formule!='VSI' && this.resultat.stats[i].formule!='VSS' && this.resultat.stats[i].titre!='VTYPE')
                this.resultat.recompoStats.push(this.resultat.stats[i]);
        }*/
    }

    async formateTableauComposant(produit) {
        this.model.synthese.listeSyntheseCritereValeurBlocks = this.model.synthese.listeSyntheseCritereValeurBlocks.map((x) => {
            x.selected = false;
            return x;
        });
        this.formateTableauSynthese();

        //Ligne par composant
        const produitId = produit.idProduit;
        this.resultat.lignes = this.resultat.lignes.filter(function (e) {
            return e.idProduit === produitId;
        });

        //Recalcul des stats pour les composants
        const moyenne = this.model.calculs.find(function (c) {
            return c.formule === '@MOY';
        });
        if (moyenne) {
            const stat = {
                titre: moyenne.libelle,
                statsEssais: [],
                couleurFond: this.getColorFromHSL(moyenne.couleurFond),
                couleurTexte: this.getColorFromHSL(moyenne.couleurTexte)
            };

            for (let j = 0; j < this.model.sousEssaisTamis.length; j++) {
                const statlEss = { valeur: await this.getMoyenneComposant(this.model.sousEssaisTamis[j], j) };
                stat.statsEssais.push(statlEss);
            }

            this.resultat.stats = [stat];
        }
        produit.selected = true;
    }

    async getMoyenneComposant(essStat, essIndex) {
        let listVal = undefined;
        let resultat = undefined;

        if (!listVal) listVal = this.getListeValeurs(essIndex);
        if (listVal.length > 0) {
            let resultSom = 0.0;
            for (let i = 0; i < listVal.length; i++) resultSom += listVal[i];
            resultat = resultSom / listVal.length;
        }
        if (resultat !== undefined && resultat !== '') {
            const objetToRound = [{ key: resultat, val: resultat, rounder: essStat.arrondi }];
            resultat = this.Interpreteur.getRoundedValue(objetToRound);
        }
        //SP 14/01/20 correction arrondi
        else {
            resultat = '';
        }

        return resultat;
    }

    getListeValeurs(essIndex) {
        const listVal = [];
        this.resultat.lignes.forEach((lig) => {
            let valeur = undefined;
            if (lig.valeursEssais[essIndex].valeur) valeur = lig.valeursEssais[essIndex].valeur;

            if (valeur !== undefined && valeur !== '') listVal.push(parseFloat(valeur));
        });
        return listVal;
    }

    getColorFromHSL(coul) {
        if (coul) {
            const hexa = this.ColorPickerService.hslToRgb(coul.hue, coul.saturation, coul.luminosity);
            return 'rgba(' + hexa.red + ',' + hexa.green + ',' + hexa.blue + ', ' + (coul.alpha || coul.alpha === 0 ? coul.alpha : '1') + ')';
        }
        return 'rgb(0,0,0)';
    }

    getColspanEss(index) {
        return this.model.essais[index].nbEnfants;
    }

    async displayFiller(display) {
        if (display) {
            const filler = {
                nomComposant: this.$translate.instant('SYNTHENTS.RECOMPO.FILLER_APPORT'),
                pcentmin: 0,
                pcentmax: 100,
                indexTendance: 2,
                idProduit: 999,
                pourcentageComposant: 0
            };
            this.resultat.produits.push(filler);
        } else {
            const i = this.resultat.produits.findIndex(function (p) {
                return p.idProduit === 999;
            });
            if (i >= 0) this.resultat.produits.splice(i, 1);
        }

        await this.prepareGraphData();
        //this.doOptimisation();
        this.calculeRecompo();
    }

    //Calcul de la courbe recomposée en fonction des pourcentages actuels
    calculeRecompo() {
        const precis = 1;
        for (let n = 0; n < this.graphData.Abscisse.length; n++) {
            let moyValue = 0;
            for (let p = 0; p < this.resultat.produits.length; p++) {
                const curProduit = this.resultat.produits[p];

                let prodValue = 0;
                try {
                    const moys = this._.find(this.moyennesParProduitReelles, function (elt) {
                        return elt.idProduit == curProduit.idProduit;
                    });
                    if (moys) prodValue = moys.moyennesTamis[n];
                    if (!prodValue) prodValue = 0;
                } catch (e) {
                    prodValue = 0;
                }

                prodValue = (prodValue * curProduit.pourcentageComposant) / (100 * precis);
                moyValue += prodValue;
            }
            this.graphData.recompo_moyenne.values[n] = {
                pourcPassant: moyValue < 0 && n > 0 ? '' : moyValue,
                pourcPassantArrondi: moyValue < 0 && n > 0 ? '' : parseFloat(moyValue.toFixed(2)) /*Math.round(moyValue)*/, //TODO: arrondi à 0, 1 ou 2 selon précision des calculs
                pourcRefPartiel: '',
                pourcRefPartielArrondi: ''
            };
            if (this.arrayRef.xt[n] != '' && this.graphData.recompo_moyenne.values[n].pourcPassant != '')
                this.arrayRef.ecart[n] = Math.abs(parseFloat(this.arrayRef.xt[n] - this.graphData.recompo_moyenne.values[n].pourcPassant)).toFixed(2);
        }
        this.calculeRecompoEssais();
    }

    calculeRecompoEssais() {
        const precis = 1;
        for (let n = 0; n < this.essaisData.Abscisse.length; n++) {
            let moyValue = 0;
            for (let p = 0; p < this.resultat.produits.length; p++) {
                const curProduit = this.resultat.produits[p];

                let prodValue = 0;
                try {
                    const moys = this._.find(this.moyennesParProduitReelles, function (elt) {
                        return elt.idProduit == curProduit.idProduit;
                    });
                    if (moys) prodValue = moys.moyennesEssais[n];
                    if (!prodValue) prodValue = 0;
                } catch (e) {
                    prodValue = 0;
                }

                prodValue = (prodValue * curProduit.pourcentageComposant) / (100 * precis);
                moyValue += prodValue;
            }
            this.essaisData.recompo_moyenne.values[n] = {
                pourcPassant: moyValue < 0 && n > 0 ? '' : moyValue,
                pourcPassantArrondi: moyValue < 0 && n > 0 ? '' : parseFloat(moyValue.toFixed(2)) /*Math.round(moyValue)*/, //TODO: arrondi à 0, 1 ou 2 selon précision des calculs
                pourcRefPartiel: '',
                pourcRefPartielArrondi: ''
            };
            if (this.arrayRefEssais.xt[n] != '' && this.essaisData.recompo_moyenne.values[n].pourcPassant != '')
                this.arrayRefEssais.ecart[n] = Math.abs(
                    parseFloat(this.arrayRefEssais.xt[n] - this.essaisData.recompo_moyenne.values[n].pourcPassant)
                ).toFixed(2);
        }
    }

    //wil ModifVal
    changeValue(produit, params) {
        if (!produit.disabled) {
            if (Math.abs(params.frc) > Math.abs(params.tot)) params.frc = params.tot;
            if (produit.pourcentageComposant + params.frc < produit.pcentmin) {
                params.tot = params.tot - produit.pcentmin + produit.pourcentageComposant;
                produit.pourcentageComposant = parseFloat(produit.pcentmin.toFixed(2));
            } else if (produit.pourcentageComposant + params.frc > produit.pcentmax) {
                params.tot = params.tot + produit.pcentmin - produit.pourcentageComposant;
                produit.pourcentageComposant = parseFloat(produit.pcentmax.toFixed(2));
            } else {
                params.tot = params.tot - params.frc;
                produit.pourcentageComposant = parseFloat((produit.pourcentageComposant + params.frc).toFixed(2));
            }
        }
    }

    //wil Repercute
    valueChanged(produit, produit2, stop) {
        if (!this.encours) {
            this.encours = true;
            const precis = 1;
            let nb = 0;
            const params = { tot: Math.round(100 * precis), frc: 0 };

            for (let i = 0; i < this.resultat.produits.length; i++) {
                if (!this.resultat.produits[i].disabled) nb += 1;
                params.tot = params.tot - this.resultat.produits[i].pourcentageComposant;
            }

            if (nb > 1) {
                nb = nb - 1;
                if (produit2) nb = nb - 1;
                if (params.tot > 0) params.frc = Math.floor((params.tot + nb - 1) / nb);
                else params.frc = Math.floor((params.tot - nb + 1) / nb);

                for (let i = 0; i < this.resultat.produits.length; i++) {
                    const prodId = this.resultat.produits[i].idProduit;
                    if (produit && prodId == produit.idProduit) continue;
                    if (produit2 && prodId == produit2.idProduit) continue;

                    if (params.tot != 0) this.changeValue(this.resultat.produits[i], params);
                }

                if (params.tot != 0) {
                    params.frc = params.tot;
                    this.changeValue(produit, params);
                } else {
                    this.changeValue(produit, params);
                    if (!stop) this.valueChanged(produit, produit2, true);
                }
            }

            if (!stop) this.calculeRecompo();
            this.encours = false;
        }
    }

    //wil Edit11Exit
    minChange(produit) {
        try {
            produit.pcentmin = parseFloat(produit.pcentmin);
            if (!produit.pcentmin) produit.pcentmin = 0;
        } catch (e) {
            produit.pcentmin = 0;
        }

        this.valueChanged(produit, null);
    }

    //wil Edit1Exit
    pcentChange(produit) {
        if (!this.encours) {
            try {
                produit.pourcentageComposant = parseFloat(produit.pourcentageComposant);
                if (!produit.pourcentageComposant) produit.pourcentageComposant = 0;
            } catch (e) {
                produit.pourcentageComposant = 0;
            }

            this.valueChanged(produit, null);
        }
    }

    //wil Edit21Exit
    maxChange(produit) {
        try {
            produit.pcentmax = parseFloat(produit.pcentmax);
            if (!produit.pcentmax) produit.pcentmax = 100;
        } catch (e) {
            produit.pcentmax = 100;
        }

        this.valueChanged(produit, null);
    }

    //wil Edit31Exit OK SP 11/05/20
    tonnageChange(produit) {
        const r = 0;
        let rTot = 0;

        try {
            produit.tonnage = parseFloat(produit.tonnage);
            if (!produit.tonnage) produit.tonnage = 0;
        } catch (e) {
            produit.tonnage = 0;
        }

        for (let i = 0; i < this.resultat.produits.length; i++) {
            const curProduit = this.resultat.produits[i];

            let tonnage = 0;
            try {
                tonnage = parseFloat(curProduit.tonnage);
                if (!tonnage) tonnage = 0;
            } catch (e) {
                tonnage = 0;
            }

            rTot = rTot + tonnage;
        }
        this.tHTot = rTot.toFixed(1);
        if (rTot > 0) {
            for (let i = 0; i < this.resultat.produits.length; i++) {
                const curProduitUpd = this.resultat.produits[i];
                let tonnPdt = 0;
                try {
                    tonnPdt = parseFloat(curProduitUpd.tonnage);
                    if (!tonnPdt) tonnPdt = 0;
                } catch (e) {
                    tonnPdt = 0;
                }
                curProduitUpd.tonnage = tonnPdt.toFixed(1);
                curProduitUpd.pourcentageComposant = parseFloat(((100 * tonnPdt) / rTot).toFixed(2));
            }
        }

        this.valueChanged(produit, null);
    }

    async prepareGraphData() {
        const that = this;
        this.graphData = { Abscisse: [], style: { height: '400px' } };

        //Quel index de tamis doit on garder pour l'analyse ?
        const lstKeepTamisIndexes = [];

        //1/Récupérer les tamis à afficher
        const oGRAN = this.model.essais.find(function (e) {
            return e.listeTamisHeaders && e.listeTamisHeaders.length > 0;
        });
        if (oGRAN) {
            if (oGRAN.listeTamisHeaders) {
                for (let t = 0; t < oGRAN.listeTamisHeaders.length; t++) {
                    if (oGRAN.listeTamisHeaders[t].selected) {
                        const refTamis = oGRAN.listeTamisHeaders[t];

                        const tamisIndex = this.model.formattedResult.entete.titresSousEssais.findIndex(function (e) {
                            return e.isTamis && e.titre === refTamis.label;
                        });
                        lstKeepTamisIndexes.push(tamisIndex);
                        const tamis = this.model.formattedResult.entete.titresSousEssais[tamisIndex];
                        tamis.label = this.$translate.instant('MESURES.TAMIS');

                        this.graphData.Abscisse.push(Object.assign({}, tamis, refTamis));
                    }
                }
            }

            this.graphData.nbElement = this.graphData.Abscisse.length;
            this.graphData.idEssai = oGRAN.idEssai;
        }

        //On construit l'objet reprséentant la courbe de recomposition !! la mettre en premier !
        this.graphData.recompo_moyenne = { label: this.$translate.instant('SYNTHENTS.RECOMPO.RECOMPOSITION'), values: [] };
        this.graphData.recompo_bornemini = { label: this.$translate.instant('SYNTHENTS.RECOMPO.MINI'), values: [] };
        this.graphData.recompo_bornemaxi = { label: this.$translate.instant('SYNTHENTS.RECOMPO.MAXI'), values: [] };
        this.graphData.recompo_vise = { label: this.$translate.instant('SYNTHENTS.RECOMPO.VISE'), values: [] };

        //2/Récupérer les données de chaque prélèvement (moyennes par produit)
        const lstProduitTamis = {};
        //créer les courbes composants
        if (this.resultat && this.resultat.produits) {
            for (let p = 0; p < this.resultat.produits.length; p++) {
                const produit = this.resultat.produits[p];
                const idProduit = produit.idProduit;

                this.graphData['courbe_' + idProduit] = {};
                this.graphData['courbe_' + idProduit].label = produit.nomComposant;
                this.graphData['courbe_' + idProduit].hidden = true;
                this.graphData['courbe_' + idProduit].values = [];
                this.graphData['courbe_' + idProduit].isControle = false;

                lstProduitTamis[idProduit] = [];
                const subPrelevements = this.model.resultat.prelevementBlock.filter(function (e) {
                    return e.idProduit === idProduit;
                });
                const nbPrelevements = subPrelevements && subPrelevements.length > 0 ? subPrelevements.length : 1;
                for (let sp = 0; sp < nbPrelevements; sp++) {
                    lstProduitTamis[idProduit][sp] = [];

                    const lstTamis =
                        subPrelevements && subPrelevements[sp] && subPrelevements[sp].listeTamisLignes ? subPrelevements[sp].listeTamisLignes : [];
                    for (let t = 0; t < this.graphData.Abscisse.length; t++) {
                        const tamis = lstTamis.find(function (e) {
                            return e != undefined && e.idTamis === that.graphData.Abscisse[t].idTamis && e.passant != null;
                        });
                        let passant = '';

                        //On rajoute des infos sur les tamis
                        if (tamis) {
                            that.graphData.Abscisse[t].idEssai = tamis.idEssai;
                            that.graphData.Abscisse[t].idPrelevement = tamis.idPrelevement;
                            that.graphData.Abscisse[t].idSousEssai = tamis.idSousEssai;
                            that.graphData.Abscisse[t].isComplementaire = tamis.isComplementaire;
                            that.graphData.Abscisse[t].value = tamis.ouvertureTamis
                                ? tamis.ouvertureTamis
                                : parseFloat(that.graphData.Abscisse[t].label.replace(',', '.'));
                            that.graphData.Abscisse[t].passant = tamis.passant;
                            try {
                                passant = /*parseFloat(*/ parseFloat(tamis.passant); //TODO: chercher l'arrondiPoucent du tamis, arrondi n'existe pas
                            } catch (e) {
                                passant = '';
                            }
                        }

                        that.graphData.Abscisse[t].value = that.graphData.Abscisse[t].value
                            ? that.graphData.Abscisse[t].value
                            : parseFloat(that.graphData.Abscisse[t].label.replace(',', '.'));
                        lstProduitTamis[idProduit][sp][t] = passant;
                    }
                }
            }
        }

        //récupérer les valeurs composants
        this.moyennesParProduitReelles = [];
        for (const idProduit in lstProduitTamis) {
            const moyennesRelles = [];
            const moyennesArrondies = [];
            for (let i = 0; i < lstProduitTamis[idProduit][0].length; i++) {
                let moyenne = 0;
                let nbElement = 0;
                for (let j = 0; j < lstProduitTamis[idProduit].length; j++) {
                    const value = lstProduitTamis[idProduit][j][i];
                    if (value !== undefined && value !== '') {
                        moyenne += parseFloat(value);
                        nbElement++;
                    }
                }

                moyenne = moyenne >= 0 && nbElement > 0 ? moyenne / nbElement : undefined;
                //const strMoyenne = moyenne != undefined ? moyenne.toFixed(2) : '';
                const strMoyenneArrondi = moyenne != undefined ? /*Math.round(moyenne)*/ moyenne.toFixed(2) : ''; //TODO: arrondi à 0, 1 ou 2 selon précision des calculs
                const strMoyenneRef = '';
                const strMoyenneRefArrondi = '';
                this.graphData['courbe_' + idProduit].values.push({
                    pourcPassant: /*strMoyenne*/ moyenne,
                    pourcPassantArrondi: strMoyenneArrondi,
                    pourcRefPartiel: strMoyenneRef,
                    pourcRefPartielArrondi: strMoyenneRefArrondi
                });
                moyennesRelles.push(moyenne);
                moyennesArrondies.push(moyenne?.toFixed(2));
            }
            this.moyennesParProduitReelles.push({
                idProduit: idProduit,
                nomProduit: this.graphData['courbe_' + idProduit].label,
                moyennesTamis: moyennesRelles,
                moyennesArrondiesTamis: moyennesArrondies,
                ordreProduit: this.resultat.produits.findIndex(function (e) {
                    return e.idProduit == idProduit;
                })
            });
        }
        this.moyennesParProduitReelles = this._.orderBy(this.moyennesParProduitReelles, ['ordreProduit']);

        //3/Récupérer les autres données de stats
        if (this.model.formattedResult && this.model.formattedResult.stats) {
            for (let s = 0; s < this.model.formattedResult.stats.length; s++) {
                const stat = this.model.formattedResult.stats[s];
                if (stat.formule == '@VTYPE' || stat.formule == '@VSS' || stat.formule == '@VSI') {
                    const titreStat = stat.formule.substring(1);
                    this.graphData[titreStat] = {};
                    this.graphData[titreStat].label = stat.titre;
                    this.graphData[titreStat].values = [];
                    this.graphData[titreStat].isControle = stat.isControle;

                    this.graphData[titreStat].hidden = true; //stat.formule != '@VSI' && stat.formule != '@VSS' && stat.formule != '@LI' && stat.formule != '@LS';

                    for (let t = 0; t < lstKeepTamisIndexes.length; t++) {
                        const valeurTamis = stat.statsEssais[lstKeepTamisIndexes[t]];
                        valeurTamis.valeur = valeurTamis.valeur ? valeurTamis.valeur : '';
                        this.graphData[titreStat].values.push(valeurTamis.valeur);
                    }
                }
            }
        }

        //On construit la courbe de l'apport de Filler
        if (this.resultat && this.resultat.produits) {
            const i = this.resultat.produits.findIndex(function (p) {
                return p.idProduit === 999;
            });
            if (i >= 0) {
                for (let t = 0; t < this.graphData.Abscisse.length; t++) {
                    const valeurTamis = this.graphData.Abscisse[t].value;

                    let valFiller = 100;
                    if (parseFloat(valeurTamis) < 0.05) valFiller = 0.0;
                    else if (parseFloat(valeurTamis) <= 0.063) valFiller = 80.0;
                    else if (parseFloat(valeurTamis) <= 0.125) valFiller = 95.0;

                    this.graphData.courbe_999.values[t] = {
                        pourcPassant: valFiller,
                        pourcPassantArrondi: valFiller,
                        pourcRefPartiel: '',
                        pourcRefPartielArrondi: ''
                    };
                }
            }
        }
        await this.prepareEssaisData();
    }

    async prepareEssaisData() {
        const that = this;
        this.essaisData = { Abscisse: [], style: { height: '400px' } };

        //Quel index de tamis doit on garder pour l'analyse ?
        const lstKeepSsEssaiIndexes = [];

        for (let i = 0; i < this.model.essais.length; i++) {
            var ess = this.model.essais[i];
            if (ess.listeSousEssaisHeaders !== null) {
                for (let j = 0; j < ess.listeSousEssaisHeaders.length; j++) {
                    var ssEss = ess.listeSousEssaisHeaders[j];
                    if (ssEss.selected && ssEss.format != 8) {
                        this.essaisData.Abscisse.push({ essai: ess, ssEssai: ssEss });
                        const ssEssIndex = this.model.formattedResult.entete.titresSousEssais.findIndex(function (e) {
                            return !e.isTamis && e.idEssai == ess.idEssai && e.titre === ssEss.codeLibelleSousEssai;
                        });
                        lstKeepSsEssaiIndexes.push(ssEssIndex);
                    }
                }
            }
        }

        //1/Récupérer les tamis à afficher
        /*const oGRAN = this.model.essais.find(function (e) {
            return e.listeTamisHeaders && e.listeTamisHeaders.length > 0;
        });
        if (oGRAN) {
            if (oGRAN.listeTamisHeaders) {
                for (let t = 0; t < oGRAN.listeTamisHeaders.length; t++) {
                    if (oGRAN.listeTamisHeaders[t].selected) {
                        const refTamis = oGRAN.listeTamisHeaders[t];

                        const tamisIndex = this.model.formattedResult.entete.titresSousEssais.findIndex(function (e) {
                            return e.isTamis && e.titre === refTamis.label;
                        });
                        lstKeepSsEssaiIndexes.push(tamisIndex);
                        const tamis = this.model.formattedResult.entete.titresSousEssais[tamisIndex];
                        tamis.label = this.$translate.instant('MESURES.TAMIS');

                        this.graphData.Abscisse.push(Object.assign({}, tamis, refTamis));
                    }
                }
            }

            this.graphData.nbElement = this.graphData.Abscisse.length;
            this.graphData.idEssai = oGRAN.idEssai;
        }*/

        //On construit l'objet reprséentant la courbe de recomposition !! la mettre en premier !
        this.essaisData.recompo_moyenne = { label: this.$translate.instant('SYNTHENTS.RECOMPO.RECOMPOSITION'), values: [] };
        this.essaisData.recompo_bornemini = { label: this.$translate.instant('SYNTHENTS.RECOMPO.MINI'), values: [] };
        this.essaisData.recompo_bornemaxi = { label: this.$translate.instant('SYNTHENTS.RECOMPO.MAXI'), values: [] };
        this.essaisData.recompo_vise = { label: this.$translate.instant('SYNTHENTS.RECOMPO.VISE'), values: [] };

        //2/Récupérer les données de chaque prélèvement (moyennes par produit)
        const lstProduitSsEssais = {};
        //créer les courbes composants
        if (this.resultat && this.resultat.produits) {
            for (let p = 0; p < this.resultat.produits.length; p++) {
                const produit = this.resultat.produits[p];
                const idProduit = produit.idProduit;

                this.essaisData['courbe_' + idProduit] = {};
                this.essaisData['courbe_' + idProduit].label = produit.nomComposant;
                this.essaisData['courbe_' + idProduit].hidden = true;
                this.essaisData['courbe_' + idProduit].values = [];
                this.essaisData['courbe_' + idProduit].isControle = false;

                lstProduitSsEssais[idProduit] = [];
                const subPrelevements = this.model.resultat.prelevementBlock.filter(function (e) {
                    return e.idProduit === idProduit;
                });
                const nbPrelevements = subPrelevements && subPrelevements.length > 0 ? subPrelevements.length : 1;
                for (let sp = 0; sp < nbPrelevements; sp++) {
                    lstProduitSsEssais[idProduit][sp] = [];

                    const lstSousEssais =
                        subPrelevements && subPrelevements[sp] && subPrelevements[sp].listeSousEssaiLignes
                            ? subPrelevements[sp].listeSousEssaiLignes
                            : [];
                    for (let t = 0; t < this.essaisData.Abscisse.length; t++) {
                        const ssEssai = lstSousEssais.find(function (e) {
                            return (
                                e != undefined &&
                                e.idEssai === that.essaisData.Abscisse[t].essai.idEssai &&
                                e.idSousEssai === that.essaisData.Abscisse[t].ssEssai.idSousEssai &&
                                e.valeur != null
                            );
                        });
                        let valeur = '';

                        //On rajoute des infos sur les ssEssai
                        if (ssEssai) {
                            that.essaisData.Abscisse[t].idEssai = ssEssai.idEssai;
                            that.essaisData.Abscisse[t].idPrelevement = ssEssai.idPrelevement;
                            that.essaisData.Abscisse[t].idSousEssai = ssEssai.idSousEssai;
                            that.essaisData.Abscisse[t].isComplementaire = ssEssai.isComplementaire;
                            /*that.essaisData.Abscisse[t].value = ssEssai.ouvertureTamis
                                ? ssEssai.ouvertureTamis
                                : parseFloat(that.essaisData.Abscisse[t].label.replace(',', '.'));*/
                            that.essaisData.Abscisse[t].valeur = ssEssai.valeur;
                            try {
                                valeur = parseFloat(ssEssai.valeur); //TODO: chercher l'arrondiPoucent du tamis, arrondi n'existe pas
                            } catch (e) {
                                valeur = '';
                            }
                        }

                        /*that.essaisData.Abscisse[t].value = that.essaisData.Abscisse[t].value
                            ? that.essaisData.Abscisse[t].value
                            : parseFloat(that.essaisData.Abscisse[t].label.replace(',', '.'));*/
                        lstProduitSsEssais[idProduit][sp][t] = valeur;
                    }
                }
            }
        }

        //récupérer les valeurs composants
        //this.moyennesParProduitReelles = [];
        for (const idProduit in lstProduitSsEssais) {
            const moyennesRelles = [];
            const moyennesArrondies = [];
            for (let i = 0; i < lstProduitSsEssais[idProduit][0].length; i++) {
                let moyenne = 0;
                let nbElement = 0;
                for (let j = 0; j < lstProduitSsEssais[idProduit].length; j++) {
                    const value = lstProduitSsEssais[idProduit][j][i];
                    if (value !== undefined && value !== '') {
                        moyenne += parseFloat(value);
                        nbElement++;
                    }
                }

                moyenne = moyenne >= 0 && nbElement > 0 ? moyenne / nbElement : undefined;
                //const strMoyenne = moyenne != undefined ? moyenne.toFixed(2) : '';
                //const strMoyenneArrondi = moyenne != undefined ? moyenne.toFixed(2) : ''; //TODO: arrondi à 0, 1 ou 2 selon précision des calculs
                //const strMoyenneRef = '';
                //const strMoyenneRefArrondi = '';
                this.essaisData['courbe_' + idProduit].values.push({
                    pourcPassant: moyenne
                    //pourcPassantArrondi: strMoyenneArrondi,
                    //pourcRefPartiel: strMoyenneRef,
                    //pourcRefPartielArrondi: strMoyenneRefArrondi
                });
                moyennesRelles.push(moyenne);
                moyennesArrondies.push(moyenne === undefined ? undefined : moyenne.toFixed(2));
            }
            const moyParProdReel = this.moyennesParProduitReelles.find(function (e) {
                return e.idProduit === idProduit;
            });
            moyParProdReel.moyennesEssais = moyennesRelles;
            moyParProdReel.moyennesArrondiesEssais = moyennesArrondies;
        }

        //3/Récupérer les autres données de stats
        if (this.model.formattedResult && this.model.formattedResult.stats) {
            for (let s = 0; s < this.model.formattedResult.stats.length; s++) {
                const stat = this.model.formattedResult.stats[s];
                if (stat.formule == '@VTYPE' || stat.formule == '@VSS' || stat.formule == '@VSI') {
                    const titreStat = stat.formule.substring(1);
                    this.essaisData[titreStat] = {};
                    this.essaisData[titreStat].label = stat.titre;
                    this.essaisData[titreStat].values = [];
                    this.essaisData[titreStat].isControle = stat.isControle;

                    this.essaisData[titreStat].hidden = true; //stat.formule != '@VSI' && stat.formule != '@VSS' && stat.formule != '@LI' && stat.formule != '@LS';

                    for (let t = 0; t < lstKeepSsEssaiIndexes.length; t++) {
                        const valeurEssai = stat.statsEssais[lstKeepSsEssaiIndexes[t]];
                        valeurEssai.valeur = valeurEssai.valeur ? valeurEssai.valeur : '';
                        this.essaisData[titreStat].values.push(valeurEssai.valeur);
                    }
                }
            }
        }

        /*//On construit la courbe de l'apport de Filler
        if (this.resultat && this.resultat.produits) {
            const i = this.resultat.produits.findIndex(function (p) {
                return p.idProduit === 999;
            });
            if (i >= 0) {
                for (let t = 0; t < this.essaisData.Abscisse.length; t++) {
                    const valeurTamis = this.essaisData.Abscisse[t].value;

                    let valFiller = 100;
                    if (parseFloat(valeurTamis) < 0.05) valFiller = 0.0;
                    else if (parseFloat(valeurTamis) <= 0.063) valFiller = 80.0;
                    else if (parseFloat(valeurTamis) <= 0.125) valFiller = 95.0;

                    this.graphessaisDataData['courbe_999'].values[t] = {
                        pourcPassant: valFiller,
                        pourcPassantArrondi: valFiller,
                        pourcRefPartiel: '',
                        pourcRefPartielArrondi: ''
                    };
                }
            }
        }*/
    }

    changeMaxi(index) {
        const vss = parseFloat(this.arrayRef.vss[index]);
        if (vss == null || vss == undefined) this.arrayRef.vss[index] = '';
        this.graphData.recompo_bornemaxi.values[index] = this.arrayRef.vss[index];
        if (this.arrayRef.xt[index] == '') {
            //pas de Xt sur la référence, on prend (VSI+VSS)/2
            if (this.arrayRef.vsi[index] != '' && this.arrayRef.vss[index] != '') {
                this.arrayRef.xt[index] = (this.arrayRef.vsi[index] + this.arrayRef.vss[index]) / 2;
                this.graphData.recompo_vise.values[index] = this.arrayRef.vss[index];
            }
        }
    }

    changeMaxiEssai(index) {
        const vss = parseFloat(this.arrayRefEssais.vss[index]);
        if (vss == null || vss == undefined) this.arrayRefEssais.vss[index] = '';
        this.essaisData.recompo_bornemaxi.values[index] = this.arrayRefEssais.vss[index];
        if (this.arrayRefEssais.xt[index] == '') {
            //pas de Xt sur la référence, on prend (VSI+VSS)/2
            if (this.arrayRefEssais.vsi[index] != '' && this.arrayRefEssais.vss[index] != '') {
                this.arraarrayRefEssaisyRef.xt[index] = (this.arrayRefEssais.vsi[index] + this.arrayRefEssais.vss[index]) / 2;
                this.essaisData.recompo_vise.values[index] = this.arrayRefEssais.vss[index];
            }
        }
    }

    changeMini(index) {
        const vsi = parseFloat(this.arrayRef.vsi[index]);
        if (vsi == null || vsi == undefined) this.arrayRef.vsi[index] = '';
        this.graphData.recompo_bornemini.values[index] = this.arrayRef.vsi[index];
        if (this.arrayRef.xt[index] == '') {
            //pas de Xt sur la référence, on prend (VSI+VSS)/2
            if (this.arrayRef.vsi[index] != '' && this.arrayRef.vss[index] != '') {
                this.arrayRef.xt[index] = (this.arrayRef.vsi[index] + this.arrayRef.vss[index]) / 2;
                this.graphData.recompo_vise.values[index] = this.arrayRef.vss[index];
            }
        }
    }

    changeMiniEssai(index) {
        const vsi = parseFloat(this.arrayRefEssais.vsi[index]);
        if (vsi == null || vsi == undefined) this.arrayRefEssais.vsi[index] = '';
        this.essaisData.recompo_bornemini.values[index] = this.arrayRefEssais.vsi[index];
        if (this.arrayRefEssais.xt[index] == '') {
            //pas de Xt sur la référence, on prend (VSI+VSS)/2
            if (this.arrayRefEssais.vsi[index] != '' && this.arrayRefEssais.vss[index] != '') {
                this.arrayRefEssais.xt[index] = (this.arrayRefEssais.vsi[index] + this.arrayRefEssais.vss[index]) / 2;
                this.essaisData.recompo_vise.values[index] = this.arrayRefEssais.vss[index];
            }
        }
    }

    changeVise(index) {
        const xt = parseFloat(this.arrayRef.xt[index]);
        if (xt == null || xt == undefined) this.arrayRef.xt[index] = '';
        this.graphData.recompo_vise.values[index] = this.arrayRef.xt[index];
        if (this.arrayRef.xt[index] != '' && this.graphData.recompo_moyenne.values[index].pourcPassant != '')
            this.arrayRef.ecart[index] = Math.abs(
                parseFloat(this.arrayRef.xt[index] - this.graphData.recompo_moyenne.values[index].pourcPassant)
            ).toFixed(2);
    }

    changeViseEssai(index) {
        const xt = parseFloat(this.arrayRefEssais.xt[index]);
        if (xt == null || xt == undefined) this.arrayRefEssais.xt[index] = '';
        this.essaisData.recompo_vise.values[index] = this.arrayRefEssais.xt[index];
        if (this.arrayRefEssais.xt[index] != '' && this.essaisData.recompo_moyenne.values[index].pourcPassant != '')
            this.arrayRefEssais.ecart[index] = Math.abs(
                parseFloat(this.arrayRefEssais.xt[index] - this.essaisData.recompo_moyenne.values[index].pourcPassant)
            ).toFixed(2);
    }

    openSynthese() {
        const _this = this;
        this.$uibModal
            .open({
                template:
                    '<syntheseentities-form modal-instance="$ctrl.uibModalInstance" synth-for-gene="undefined" params-synthese="$ctrl.paramsSynthese"></syntheseentities-form>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.paramsSynthese = _this.model.synthese;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl',
                backdrop: false
            })
            .result.then(
                async function (result) {
                    if (result) {
                        //result contient une liste (prelevementBlock)
                        _this.$state.go('syntheseentities.result', { obj: result });
                    }
                },
                function (reason) {}
            );
    }

    async endRecomposition() {
        this.$state.go('syntheseentities.list', { obj: this.model });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    initTableaux() {
        const NbColTamis = this.graphData.Abscisse.length;
        for (let idProduit = 0; idProduit < this.resultat.produits.length; idProduit++) {
            const produit = this.resultat.produits[idProduit];

            // pondération
            this.arrayPond[idProduit] = parseFloat(produit.indexTendance);
            //min
            this.arrayMin[idProduit] = produit.pcentmin ? parseFloat(produit.pcentmin) : 0;
            //max
            this.arrayMax[idProduit] = produit.pcentmax ? parseFloat(produit.pcentmax) : 100;

            const graphProduct = this.graphData['courbe_' + produit.idProduit];
            //poucentage par produit
            for (let col = 0; col < NbColTamis; col++) {
                if (!this.arrayData[col]) this.arrayData[col] = [];

                const valPcent = parseFloat(graphProduct.values[col].pourcPassant);
                this.arrayData[col][idProduit] = valPcent ? valPcent : /*-1*/ '';

                if (idProduit == 0) {
                    //ne faire qu'une fois
                    //this.arrayRef = { vss: [], vsi: [], xt: [], ecart: [], coeff: [], coeffHF: [] };
                    this.arrayRef.coeff[col] = 1.0;
                    this.arrayRef.coeffHF[col] = 1.0;
                    const xt = this.graphData.VTYPE.values;
                    this.arrayRef.xt[col] = xt && xt[col] ? parseFloat(xt[col]) : /*-1*/ '';
                    const vsi = this.graphData.VSI.values;
                    this.arrayRef.vsi[col] = vsi && vsi[col] ? parseFloat(vsi[col]) : /*-1*/ '';
                    const vss = this.graphData.VSS.values;
                    this.arrayRef.vss[col] = vss && vss[col] ? parseFloat(vss[col]) : /*-1*/ '';
                    if (this.arrayRef.xt[col] == /*-1*/ '') {
                        //pas de Xt sur la référence, on prend (VSI+VSS)/2
                        if (this.arrayRef.vsi[col] != '' && this.arrayRef.vss[col] != '')
                            this.arrayRef.xt[col] = (this.arrayRef.vsi[col] + this.arrayRef.vss[col]) / 2;
                    }
                    this.graphData.recompo_vise.values[col] = this.arrayRef.xt[col];
                    this.graphData.recompo_bornemaxi.values[col] = this.arrayRef.vss[col];
                    this.graphData.recompo_bornemini.values[col] = this.arrayRef.vsi[col];
                }
            }
        }
        this.initTableauxEssais();
    }

    initTableauxEssais() {
        const NbColEssais = this.essaisData.Abscisse.length;
        for (let idProduit = 0; idProduit < this.resultat.produits.length; idProduit++) {
            const produit = this.resultat.produits[idProduit];

            // pondération
            this.arrayPond[idProduit] = parseFloat(produit.indexTendance);
            //min
            this.arrayMin[idProduit] = produit.pcentmin ? parseFloat(produit.pcentmin) : 0;
            //max
            this.arrayMax[idProduit] = produit.pcentmax ? parseFloat(produit.pcentmax) : 100;

            const graphProduct = this.essaisData['courbe_' + produit.idProduit];
            //poucentage par produit
            for (let col = 0; col < NbColEssais; col++) {
                if (!this.arrayDataEssais[col]) this.arrayDataEssais[col] = [];

                const valPcent = parseFloat(graphProduct.values[col].pourcPassant);
                this.arrayDataEssais[col][idProduit] = valPcent ? valPcent : /*-1*/ '';

                if (idProduit == 0) {
                    //ne faire qu'une fois
                    //this.arrayRef = { vss: [], vsi: [], xt: [], ecart: [], coeff: [], coeffHF: [] };
                    this.arrayRefEssais.coeff[col] = 1.0;
                    this.arrayRefEssais.coeffHF[col] = 1.0;
                    const xt = this.essaisData.VTYPE.values;
                    this.arrayRefEssais.xt[col] = xt && xt[col] ? parseFloat(xt[col]) : /*-1*/ '';
                    const vsi = this.essaisData.VSI.values;
                    this.arrayRefEssais.vsi[col] = vsi && vsi[col] ? parseFloat(vsi[col]) : /*-1*/ '';
                    const vss = this.essaisData.VSS.values;
                    this.arrayRefEssais.vss[col] = vss && vss[col] ? parseFloat(vss[col]) : /*-1*/ '';
                    if (this.arrayRefEssais.xt[col] == /*-1*/ '') {
                        //pas de Xt sur la référence, on prend (VSI+VSS)/2
                        if (this.arrayRefEssais.vsi[col] != '' && this.arrayRefEssais.vss[col] != '')
                            this.arrayRefEssais.xt[col] = (this.arrayRefEssais.vsi[col] + this.arrayRefEssais.vss[col]) / 2;
                    }
                    this.essaisData.recompo_vise.values[col] = this.arrayRefEssais.xt[col];
                    this.essaisData.recompo_bornemaxi.values[col] = this.arrayRefEssais.vss[col];
                    this.essaisData.recompo_bornemini.values[col] = this.arrayRefEssais.vsi[col];
                }
            }
        }
    }

    interpoler(arrayToInterpol) {
        const newListeTamisLignes = [];
        if (arrayToInterpol && arrayToInterpol.length > 0) {
            let v1;
            let v2;
            let r1;
            let r2 = null;
            for (let i = 0; i < this.graphData.Abscisse.length; i++) {
                let r = this.graphData.Abscisse[i].ouvertureTamis;
                let valeur = arrayToInterpol[i];
                if (valeur == '' || valeur == null || valeur == undefined) {
                    valeur = '';

                    //récupération des valeurs à des tamis inférieurs
                    r1 = 9999;
                    v1 = 0;
                    let parc1 = i - 1;
                    while (r1 == 9999 && parc1 > -1) {
                        if (newListeTamisLignes[parc1] != '') {
                            r1 = this.graphData.Abscisse[parc1].ouvertureTamis;
                            v1 = newListeTamisLignes[parc1];
                        }
                        parc1--;
                    }
                    if (r1 == 9999) r1 = this.graphData.Abscisse[0].ouvertureTamis;

                    //récupération des valeurs à des tamis supérieurs
                    r2 = 0;
                    v2 = 100;
                    let parc2 = i + 1;
                    while (r2 == 0 && parc2 < arrayToInterpol.length) {
                        if (arrayToInterpol[parc2] != '') {
                            r2 = this.graphData.Abscisse[parc2].ouvertureTamis;
                            v2 = arrayToInterpol[parc2];
                        }
                        parc2++;
                    }
                    if (v1 != 0 || v2 != 100) {
                        if (r1 < r2) {
                            if (r > r2) r = r2;
                            if (r < r1) r = r1;

                            try {
                                r2 = Math.log(r2 / r);
                                r1 = Math.log(r / r1);
                                r = (v1 * r2 + v2 * r1) / (r1 + r2);
                                if (r > 100) r = 100;
                                valeur = r;
                            } catch (ex) {
                                v1 = valeur;
                                newListeTamisLignes.push(valeur);
                                this.notification.error(ex.data);
                            }
                        } else {
                            if (r > r2) r = 100;
                            else r = 0;
                            valeur = r;
                        }
                        v1 = valeur;
                        newListeTamisLignes.push(valeur);
                    } else newListeTamisLignes.push(valeur);
                } else {
                    v1 = valeur;
                    newListeTamisLignes.push(valeur);
                }
                r1 = this.graphData.Abscisse[i].ouvertureTamis;
            }
        }
        return newListeTamisLignes;
    }

    //BOptimiser_Click : appelé au clic sur le bouton optimiser
    doOptimisation() {
        //interpoler les bornes mini/maxi/visé
        this.arrayRef.vssinterpol = this.interpoler(this.arrayRef.vss);
        this.arrayRef.vsiinterpol = this.interpoler(this.arrayRef.vsi);
        this.arrayRef.xtinterpol = this.interpoler(this.arrayRef.xt);

        this.optimisation();
    }

    //ref double[] arrayMoy,ref double[] arrayRef,int[] arrayPond,int nbTamis
    ecartCourbe(nbTamis, nbEssais) {
        const coeffPonder = 0.09;
        let ecartE = 0;
        let ecartE0 = undefined;
        const tabN = [];
        const tabN2 = [];
        //prise en compte de la pondération sur les composants
        for (let ecartN = 0; ecartN < nbTamis; ecartN++) {
            tabN[ecartN] = this.graphData.recompo_moyenne.values[ecartN].pourcPassant;
            ecartE0 = 1;
            for (let j = 0; j < this.arrayPond.length; j++) {
                if (this.arrayPond[j] != 2) {
                    tabN[ecartN] = tabN[ecartN] + this.arrayData[ecartN][j] * (this.arrayPond[j] - 2) * coeffPonder; //attention, pondérations de 0 à 4 au lieu de -2 à 2
                    ecartE0 += (this.arrayPond[j] - 2) * coeffPonder;
                }
            }
            tabN[ecartN] = tabN[ecartN] / ecartE0;
        }
        for (let ecartN = 0; ecartN < nbEssais; ecartN++) {
            tabN2[ecartN] = this.essaisData.recompo_moyenne.values[ecartN].pourcPassant;
            ecartE0 = 1;
            for (let j = 0; j < this.arrayPond.length; j++) {
                if (this.arrayPond[j] != 2) {
                    tabN2[ecartN] = tabN2[ecartN] + this.arrayDataEssais[ecartN][j] * (this.arrayPond[j] - 2) * coeffPonder; //attention, pondérations de 0 à 4 au lieu de -2 à 2
                    ecartE0 += (this.arrayPond[j] - 2) * coeffPonder;
                }
            }
            tabN2[ecartN] = tabN2[ecartN] / ecartE0;
        }
        for (let i = 0; i < nbTamis; i++) {
            let ecartE0 = undefined;
            const vsi = parseFloat(this.arrayRef.vsi[i]);
            const vsiinterpol = parseFloat(this.arrayRef.vsiinterpol[i]);
            const bvsi = this.arrayRef.vsi[i] != '' && vsi != undefined && vsi != null;
            const vss = parseFloat(this.arrayRef.vss[i]);
            const vssinterpol = parseFloat(this.arrayRef.vssinterpol[i]);
            const bvss = this.arrayRef.vss[i] != '' && vss != undefined && vss != null;
            const xt = parseFloat(this.arrayRef.xt[i]);
            const bxt = this.arrayRef.xt[i] != '' && xt != undefined && xt != null;
            const xtinterpol = parseFloat(this.arrayRef.xtinterpol[i]);
            if (bxt) ecartE0 = Math.abs(xtinterpol - tabN[i]);
            else if (!bvss) {
                if (bvsi && vsiinterpol > tabN[i]) ecartE0 = Math.abs(vsiinterpol - tabN[i]);
                else ecartE0 = 0;
            } else if (!bvsi) {
                if (vssinterpol < tabN[i]) ecartE0 = Math.abs(vssinterpol - tabN[i]);
                else ecartE0 = 0;
            } else ecartE0 = Math.abs((vssinterpol + vsiinterpol) / 2 - tabN[i]);
            if (tabN[i] > vssinterpol || tabN[i] < vsiinterpol) ecartE += ecartE0 * this.arrayRef.coeffHF[i];
            ecartE += ecartE0 * this.arrayRef.coeff[i];
        }
        for (let i = 0; i < nbEssais; i++) {
            let ecartE0 = undefined;
            const vsi = parseFloat(this.arrayRefEssais.vsi[i]);
            //let vsiinterpol = parseFloat(this.arrayRefEssais.vsiinterpol[i]);
            const bvsi = this.arrayRefEssais.vsi[i] != '' && vsi != undefined && vsi != null;
            const vss = parseFloat(this.arrayRefEssais.vss[i]);
            //let vssinterpol = parseFloat(this.arrayRefEssais.vssinterpol[i]);
            const bvss = this.arrayRefEssais.vss[i] != '' && vss != undefined && vss != null;
            const xt = parseFloat(this.arrayRefEssais.xt[i]);
            const bxt = this.arrayRefEssais.xt[i] != '' && xt != undefined && xt != null;
            //let xtinterpol = parseFloat(this.arrayRefEssais.xtinterpol[i]);
            if (bxt) ecartE0 = Math.abs(xt - tabN2[i]);
            else if (!bvss) {
                if (bvsi && vsi > tabN2[i]) ecartE0 = Math.abs(vsi - tabN2[i]);
                else ecartE0 = 0;
            } else if (!bvsi) {
                if (vss < tabN2[i]) ecartE0 = Math.abs(vss - tabN2[i]);
                else ecartE0 = 0;
            } else ecartE0 = Math.abs((vss + vsi) / 2 - tabN2[i]);
            if (tabN2[i] > vss || tabN2[i] < vsi) ecartE += ecartE0 * this.arrayRefEssais.coeffHF[i];
            ecartE += ecartE0 * this.arrayRefEssais.coeff[i];
        }
        this.ecart = ecartE;
        return ecartE;
    }

    //Boolean[] arrayModifValid,int Numligne
    possible(Numligne) {
        return !this.resultat.produits[Numligne].disabled;
    }

    //ref Boolean[] arrayModifValid,int Numligne,Boolean b
    permetDeplacement(Numligne, b) {
        if (Numligne > -1 && Numligne < this.resultat.produits.length) this.resultat.produits[Numligne].disabled = !b;
    }

    //ref double[] arrayPoucentag,int Numligne,int Sens,int[] arrayMin,int[] arrayMax
    modifBar(Numligne, SensMB) {
        if (Numligne > -1 && Numligne < this.resultat.produits.length) {
            const imin = this.arrayMin[Numligne];
            const imax = this.arrayMax[Numligne];
            if (this.resultat.produits[Numligne].pourcentageComposant + SensMB < imin) this.resultat.produits[Numligne].pourcentageComposant = imin;
            else if (this.resultat.produits[Numligne].pourcentageComposant + SensMB > imax)
                this.resultat.produits[Numligne].pourcentageComposant = imax;
            else this.resultat.produits[Numligne].pourcentageComposant += SensMB;
        }
    }

    //ref double[] arrayPoucentag,int NumLigne,int Sens,int[] arrayMin,int[] arrayMax //SP OK 11/05/20
    verifBar(NumLigne, SensVB) {
        this.modifBar(NumLigne, SensVB);
    }

    //int i1,int i2,int Sens,int Nbligne,int numproduit,ref double[] arrayOldPoucentage,double[] arrayPoucentage,Boolean[] arrayModifValid,int[] arrayMin,int[] arrayMax
    modifie(i1M, i2M, sensM /*, Nbligne, numproduit, arrayOldPoucentage, arrayModifValid*/) {
        const oldPourcent = [];
        for (let iModifie = 0; iModifie < this.resultat.produits.length; iModifie++)
            oldPourcent[iModifie] = angular.copy(this.resultat.produits[iModifie].pourcentageComposant);
        this.verifBar(i1M, sensM);
        this.verifBar(i2M, Math.floor(sensM / 2));
        this.repercute(i1M, i2M);
        return oldPourcent;
    }

    //ref double[] arrayPoucentage,Boolean[] arrayModifValid,int NumProduit,ref int frc,ref int tot
    modifVal(frc, tot, NumProduit, precis) {
        if (!this.resultat.produits[NumProduit].disabled) {
            if (Math.abs(frc) > Math.abs(tot)) frc = tot;
            if (this.resultat.produits[NumProduit].pourcentageComposant + frc < this.arrayMin[NumProduit]) {
                tot = tot - this.arrayMin[NumProduit] + Math.round(this.resultat.produits[NumProduit].pourcentageComposant);
                this.resultat.produits[NumProduit].pourcentageComposant = this.arrayMin[NumProduit];
            } else if (this.resultat.produits[NumProduit].pourcentageComposant + frc > this.arrayMax[NumProduit]) {
                tot = tot + this.arrayMax[NumProduit] - Math.round(this.resultat.produits[NumProduit].pourcentageComposant);
                this.resultat.produits[NumProduit].pourcentageComposant = this.arrayMax[NumProduit];
            } else {
                tot = tot - frc;
                this.resultat.produits[NumProduit].pourcentageComposant += frc;
            }
        }
        return { f: frc, t: tot };
    }

    //ref double[] arrayPoucentage,Boolean[] arrayModifValid,int NumProduit,int NbrProduit,int num,int num2
    repercute(/*arrayModifValid, NumProduit, NbrProduit,*/ num, num2) {
        const NbrProduit = this.resultat.produits.length;
        let bRetour = undefined;
        if (!this.encours) {
            this.encours = true;
            const precis = 1;
            let tot = Math.round(100 * precis);
            let nb = 0;
            let frc = 0;
            for (let i = 0; i < NbrProduit; i++) {
                if (!this.resultat.produits[i].disabled) nb += 1;
                tot = tot - this.resultat.produits[i].pourcentageComposant;
            }

            if (nb > 1) {
                nb--;
                if (num2 != -1)
                    // gerer le different
                    nb--;
                if (tot > 0) frc = Math.floor((tot + nb - 1) / nb);
                else frc = Math.floor((tot - nb + 1) / nb);
                for (let i = 0; i < NbrProduit; i++) {
                    if (i != num && i != num2 && tot != 0) {
                        const objMV = this.modifVal(frc, tot, i, precis);
                        frc = objMV.f;
                        tot = objMV.t;
                    }
                }
            }
            if (tot != 0) {
                // gerer le different
                frc = tot;
                bRetour = false;
                if (num > -1) {
                    const objMV = this.modifVal(frc, tot, num, precis);
                    frc = objMV.f;
                    tot = objMV.t;
                }
            } else {
                bRetour = true;
            }
            //TODO: maj du graphique
            this.calculeRecompo();
            this.encours = false;
        }
        return bRetour;
    }

    //ref TBilan Recompo,ref TSelSynthese selSynth,int[] arrayPond,int[] arrayMin,int[] arrayMax, double[] arrayRef.xt,double[,] arrayData,double[] arrayPoucentage
    //Recherchecompositionoptimale1Click
    optimisation() {
        const precis = 1;
        const NbProduit = this.resultat.produits.length;
        const NbColTamis = this.graphData.Abscisse.length;
        const NbColEssais = this.essaisData.Abscisse.length;
        const arrayModifValid = [];
        let arrayOldPoucentage = [];
        const calculApprofondi = 0;
        this.calculeRecompo();
        let sens = Math.round(precis * 25);
        for (let i1 = 0; i1 < NbProduit; i1++) {
            arrayModifValid[i1] = this.possible(i1);
            this.permetDeplacement(i1, false);
        }
        let e1 = this.ecartCourbe(NbColTamis, NbColEssais);
        let e2 = 0;
        let m1 = 0;
        let m2 = 0;
        do {
            let Ameliore = false;
            for (let i1 = 0; i1 < NbProduit; i1++) {
                if (arrayModifValid[i1]) {
                    this.permetDeplacement(i1, true);
                    for (let i2 = -1; i2 < NbProduit * calculApprofondi; i2++) {
                        if (i2 == -1 || (i2 != i1 && arrayModifValid[i2])) {
                            this.permetDeplacement(i2, true);
                            for (let j = 1; j <= 2; j++) {
                                sens = -sens;
                                let fin = false;
                                e2 = e1;
                                do {
                                    for (let n1 = 0; n1 < NbProduit; n1++) {
                                        if (n1 != i1 && n1 != i2 && arrayModifValid[n1]) {
                                            this.permetDeplacement(n1, true);
                                            for (let n2 = -1; n2 < NbProduit * calculApprofondi; n2++) {
                                                if (n2 == -1 || (n2 > n1 && n2 != i1 && n2 != i2 && arrayModifValid[n2])) {
                                                    this.permetDeplacement(n2, true);
                                                    arrayOldPoucentage = this.modifie(i1, i2, sens);
                                                    const e3 = this.ecartCourbe(NbColTamis, NbColEssais);
                                                    if (e3 < e2) {
                                                        e2 = e3;
                                                        m1 = n1;
                                                        m2 = n2;
                                                    }
                                                    for (
                                                        let iremet2 = 0;
                                                        iremet2 < NbProduit;
                                                        iremet2++ // remet
                                                    )
                                                        this.resultat.produits[iremet2].pourcentageComposant = arrayOldPoucentage[iremet2];
                                                    this.permetDeplacement(n2, false);
                                                }
                                            }
                                            this.permetDeplacement(n1, false);
                                        } // fin if ((n1!=i1)&&((n1!=i2)&&arrayModifValid[n1]))
                                    } // fin for n1
                                    if (e2 < e1) {
                                        this.permetDeplacement(m1, true);
                                        this.permetDeplacement(m2, true);
                                        arrayOldPoucentage = this.modifie(i1, i2, sens); // verif modifie
                                        this.permetDeplacement(m2, false);
                                        this.permetDeplacement(m1, false);
                                        Ameliore = true;
                                        e1 = e2;
                                    } else fin = true;
                                } while (!fin);
                            } // fin for j
                            this.permetDeplacement(i2, false);
                        } // fin (n2=2)or..
                        this.permetDeplacement(i1, false);
                    } // fin for i2
                } // fni Tab1
            } // fin i1
            if (Ameliore) sens++;
            else sens = Math.floor(sens / 2);
        } while (sens != 0);

        const i2 = 0;
        for (let i1 = 0; i1 < NbProduit; i1++) {
            if (arrayModifValid[i1]) {
                this.permetDeplacement(i1, true);
                this.modifie(i1, i2, sens); // verif modifie
            }
        }
        this.notification.success('SYNTHENTS.RECOMPO.OPTIMISATION_TERMINEE');
    }

    setDataToLoad() {
        this.numberOfLineToLoad = 10;
        this.tmpListe = angular.copy(this.resultat.lignes);
        this.tmpListe.splice(this.numberOfLineToLoad - 1, this.tmpListe.length - 1);
    }

    loadData() {
        for (let i = 0; i < this.numberOfLineToLoad - 1; i++) {
            const index = this.tmpListe.length - 1 + this.numberOfLineToLoad;
            if (this.resultat.lignes[index]) {
                this.tmpListe.push(this.resultat.lignes[index]);
            }
        }
    }

    async print() {
        const enteteSousEssais = Array.from(this.resultat.entete.titresSousEssais, (x) => {
            return {
                code: x.code,
                codeSsEss: x.isTamis ? x.ouvertureTamis.toString() : x.codeSsEss,
                isTamis: x.isTamis,
                label: x.label,
                libelleEssai: x.libelleEssai,
                ouvertureTamis: 0,
                symbole: x.symbole != null ? x.symbole : x.code,
                titre: x.titre,
                unite: x.unite
            };
        });

        const petitsd = [];
        const grandsD = [];
        const composants = Array.from(this.resultat.produits, (x) => {
            const moyennes = this.moyennesParProduitReelles.find((m) => m.idProduit == x.idProduit);
            const firstPrel = this.model.resultat.prelevementBlock.find((p) => p.idProduit == x.idProduit);

            if ((firstPrel.petitDiametre && firstPrel.petitDiametre > 0) || firstPrel.petitDiametre === 0) petitsd.push(firstPrel.petitDiametre);
            if (firstPrel.grandDiametre && firstPrel.grandDiametre > 0) grandsD.push(firstPrel.grandDiametre);
            const values = moyennes.moyennesArrondiesTamis.map((x, i) => {
                return {
                    ...enteteSousEssais[i],
                    valeur: x
                };
            });
            return {
                libelle: x.nomComposant,
                petitd: firstPrel.petitDiametre,
                grandD: firstPrel.grandDiametre,
                pourcentage: x.pourcentageComposant + ' %',
                pourcentageMin: x.pcentmin + ' %',
                pourcentageMax: x.pcentmax + ' %',
                valeurs: values,
                producteurCode: firstPrel.codeProducteur,
                producteurLibelle: firstPrel.libelleProducteur
            };
        });

        const vsi = {
            valeurs: angular.copy(this.graphData.recompo_bornemini.values).map((x, i) => {
                return {
                    ...enteteSousEssais[i],
                    valeur: x
                };
            }),
            label: 'VSI'
        };

        const vss = {
            valeurs: angular.copy(this.graphData.recompo_bornemaxi.values).map((x, i) => {
                return {
                    ...enteteSousEssais[i],
                    valeur: x
                };
            }),
            label: 'VSS'
        };

        const stats1 = [vsi, vss];

        const recompo = {
            label: this.graphData.recompo_moyenne.label,
            // valeurs: this.graphData.recompo_moyenne.values.map(x => x.pourcPassantArrondi)
            valeurs: this.graphData.recompo_moyenne.values.map((x, i) => {
                return {
                    ...enteteSousEssais[i],
                    valeur: x.pourcPassantArrondi
                };
            })
        };
        const vise = {
            valeurs: angular.copy(this.graphData.recompo_vise.values).map((x, i) => {
                return {
                    ...enteteSousEssais[i],
                    valeur: x
                };
            }),
            label: this.graphData.recompo_vise.label
        };
        const ecart = {
            label: 'Ecart',
            valeurs: []
        };
        for (let i = 0; i < vise.valeurs.length; i++) {
            let val = '';
            if (vise.valeurs[i] != '') {
                const ecart = Number(vise.valeurs[i].valeur) - Number(recompo.valeurs[i].valeur);
                val = Math.abs(ecart.toFixed(2));
                // if (val < 0) val = val * (-1)
            }

            ecart.valeurs.push({
                ...enteteSousEssais[i],
                valeur: val
            });
        }
        const stats2 = [recompo, vise, ecart];

        const data = {
            recompoPetitd: Math.min(...petitsd),
            recompoGrandD: Math.max(...grandsD),
            laboratoire: this.getLaboratoireForPrint(),
            producteurs: [],
            produits: [],
            referentiel: {
                categorie: this.model.resultat.referentiel?.categorie,
                code: this.model.resultat.referentiel?.code,
                idLogo: this.model.resultat.referentiel?.idLogo,
                idNorme: this.model.resultat.referentiel?.idNorme,
                libelle: this.model.resultat.referentiel?.libelle,
                libelleNorme: this.model.resultat.referentiel?.libelleNorme
            },
            dateDebut: this.model.resultat.referentiel?.dateDebut,
            dateFin: this.model.resultat.referentiel?.dateFin,
            composants: composants,
            statsEssais1: stats1,
            statsEssais2: stats2,
            graph: null
        };
        const courbe = document.getElementById('granulograph');
        if (courbe != null) {
            const base64 = courbe.toDataURL('image/png');
            data.graph = base64;
        }

        const res = await this.TemplateImpressionService.downloadModal('RECOMPO', true);
        if (res === 'cancel') {
            throw res;
        }
        this.notification.info(this.$translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
            autoDismiss: false
        });
        try {
            const template = await this.SyntheseEntitiesService.printRecompo(data, res.filename);
            template.pdf = res.pdf;
            await this.TemplateImpressionService.downloadTemplate(template.filename, template, 'recompo');
        } catch (err) {
            if (err !== 'cancel') {
                this.notification.error(err.data);
            }
        }
    }

    getLaboratoireForPrint() {
        const first = this.model.resultat.prelevementBlock[0];
        return {
            id: first.idLaboAudit,
            code: first.codeLaboAudit,
            libelle: first.libelleLaboAudit,
            societe: first.societeLaboAudit,
            raisonSociale: first.rsLaboAudit
        };
    }
}
