export default class GrilleZonesKilometriquesDetailController {
    static $inject = ['$stateParams', '$state', 'GrillesZonesKilometriquesService', 'notification'];

    constructor($stateParams, $state, GrillesZonesKilometriquesService, notification) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.GrillesZonesKilometriquesService = GrillesZonesKilometriquesService;
        this.notification = notification;
    }

    $onInit() {
        this.loading = false;
        this.grilleZonesKilometriques = {};
        this.grilleZonesKilometriquesId = undefined;
        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.initGrilleZonesKilometriques();
    }

    async initGrilleZonesKilometriques() {
        this.grilleZonesKilometriquesId = this.$stateParams.id;

        this.startLoading();
        try {
            await this.getGrilleZonesKilometriques(this.grilleZonesKilometriquesId);
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('grilles-zones-kilometriques.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getGrilleZonesKilometriques(grilleZonesKilometriquesId) {
        this.grilleZonesKilometriques = await this.GrillesZonesKilometriquesService.getGrilleZonesKilometriquesById(grilleZonesKilometriquesId);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
