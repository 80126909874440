import validatorBuilder from 'validator-builder';

let offreDemandeValidator = null;

export default class OffreDemande {
    constructor(data) {
        data = data || {};
        this.idSociete = data.idSociete;
        //this.client = data.client;
        this.idClient = data.idClient;
        this.idProspect = data.prospectId;
        this.demandeNumero = data.demandeNumero;
        this.demandeLibelle = data.demandeLibelle;
        this.demandeReference = data.demandeReference;
        this.demandeDateCreation = data.demandeDateCreation;
        this.demandeDateValidite = data.demandeDateValidite;
        this.demandeDateDebut = data.demandeDateDebut;
        this.demandeDateFin = data.demandeDateFin;
        this.adresse = data.adresse;
        this.codePostal = data.codePostal;
        this.ville = data.ville;
        this.idContact = data.idContact;

        if (data.idClient) {
            this.client = {
                id: data.idClient,
                libelle: data.client,
                isGenerique: data.clientIsGenerique
            };
        } else {
            this.client = null;
        }
    }

    isValid() {
        offreDemandeValidator = offreDemandeValidator || validatorBuilder.getInstanceFor('OffreDemande');
        const validationResults = offreDemandeValidator.validate(this);
        return validationResults.isValid;
    }
}
