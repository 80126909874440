export default function Configure($stateProvider) {
    $stateProvider.state('essais', {
        parent: 'common',
        url: '/essais',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.ESSAIS_NORMES" | translate }}'
        }
    });

    $stateProvider.state('essais.list', {
        url: '/list',
        template: '<mesures></mesures>',
        rights: { domain: 'essais', right: 'read', application: 'laboratoire;qualib' },
        ncyBreadcrumb: {
            parent: 'essais',
            label: '{{ "MESURES.BREADCRUMBS.ESSAIS_LIST" | translate}}'
        },
        navigation: {
            menu: 'essais_normes_seul',
            translate: 'MESURES.BREADCRUMBS.ESSAIS_LIST',
            order: 5,
            navigationCls: 'menu-item-separator'
        },
        search: 'MESURES.BREADCRUMBS.ESSAIS_LIST'
    });

    $stateProvider.state('essais.list.detail', {
        url: '/{id}',
        template: '<mesure-detail></mesure-detail>',
        rights: { domain: 'essais', right: 'read' },
        ncyBreadcrumb: {
            parent: 'essais.list',
            label: '{{ "MESURES.BREADCRUMBS.ESSAI_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('essais.new', {
        url: '/new',
        template: '<mesure-form></mesure-form>',
        rights: { domain: 'essais', right: 'create' },
        ncyBreadcrumb: {
            parent: 'essais.list',
            label: '{{ "MESURES.BREADCRUMBS.ESSAI_NEW" | translate}}'
        },
        search: 'MESURES.BREADCRUMBS.ESSAI_NEW'
    });

    $stateProvider.state('essais.edit', {
        url: '/{id}/edit',
        template: '<mesure-form></mesure-form>',
        rights: { domain: 'essais', right: 'update' },
        ncyBreadcrumb: {
            parent: 'essais.list',
            label: '{{ "MESURES.BREADCRUMBS.ESSAI_EDIT" | translate }}'
        }
    });

    $stateProvider.state('essais.newType', {
        url: '/new-type',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        params: { parentState: 'essais.new' },
        ncyBreadcrumb: {
            parent: 'essais.new',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });

    $stateProvider.state('essais.duplicate', {
        url: '/{id}/duplicate',
        template: '<mesure-form></mesure-form>',
        rights: { domain: 'essais', right: 'create' },
        ncyBreadcrumb: {
            parent: 'essais.list',
            label: '{{ "MESURES.BREADCRUMBS.ESSAI_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });
}

Configure.$inject = ['$stateProvider'];
