export default class PreviewHomeController {
    /* @ngInject */
    constructor(SettingPortailService, $scope) {
        this.displayModal = false;
        this.SettingPortailService = SettingPortailService;
        this.$scope = $scope;
    }

    async $onInit() {
        this.setStyle();

        const that = this;
        this.$scope.$watch(
            '$ctrl.settings',
            function (newValue, oldValue) {
                that.setStyle();
            },
            true
        );
    }

    closeModal() {
        this.displayModal = false;
    }

    setStyle() {
        const primary = `#${this.settings.theme?.colorPrimary}`;
        const accent = `#${this.settings.theme?.colorAccent}`;

        this.bgPrimary = {
            'background-color': primary
        };
        this.bgAccent = {
            'background-color': accent
        };
        this.borderAccent = {
            'border-color': accent
        };
        this.homeToolbar = {
            background: this.hexToRGB(primary, 0.35),
            color: primary
        };
        this.tile = {
            color: this.hexToRGB(primary, 0.7),
            'box-shadow': `5px 5px 15px -1px ${this.hexToRGB(primary, 0.35)}`
        };

        this.tileIcon = {
            color: this.hexToRGB(primary, 0.7),
            'border-color': accent
        };

        this.tileDoc = {
            'background-color': this.hexToRGB(accent, 0.7)
        };
    }

    hexToRGB(hex, alpha) {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    }
}
