export default function Configure($stateProvider) {
    $stateProvider.state('commandes', {
        parent: 'gestion',
        url: '/commandes',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.GESTION_COMMERCIALE" | translate }}'
        }
    });

    $stateProvider.state('commandes.list', {
        url: '/list',
        template: '<commandes></commandes>',
        rights: { domain: 'commandes', right: 'read' },
        ncyBreadcrumb: {
            parent: 'commandes',
            label: '{{ "COMMANDES.BREADCRUMBS.COMMANDES_LIST" | translate}}'
        },
        navigation: {
            menu: 'gestion_commerciale_commercial',
            translate: 'COMMANDES.BREADCRUMBS.COMMANDES_LIST',
            order: 2,
            navigationCls: 'menu-item-separator'
        },
        search: 'COMMANDES.BREADCRUMBS.COMMANDES_LIST'
    });

    $stateProvider.state('commandes.new', {
        url: '/new',
        template: '<commande-form></commande-form>',
        rights: { domain: 'commandes', right: 'create' },
        ncyBreadcrumb: {
            parent: 'commandes.list',
            label: '{{ "COMMANDES.BREADCRUMBS.COMMANDE_NEW" | translate}}'
        },
        search: 'COMMANDES.BREADCRUMBS.COMMANDE_NEW'
    });

    $stateProvider.state('commandes.edit', {
        url: '/{id}/edit',
        template: '<commande-form></commande-form>',
        rights: { domain: 'commandes', right: 'update' },
        ncyBreadcrumb: {
            parent: 'commandes.list',
            label: '{{ "COMMANDES.BREADCRUMBS.COMMANDE_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
