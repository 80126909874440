(function (angular, undefined) {
    'use strict';

    angular.module('blocks.treeview')
        .run(run);
    run.$inject = ['$templateCache'];

    function run($templateCache) {

        $templateCache.put('blocks/treeview/ac.treeview.node.tpl.html',
            '<!-- Folder -->' +
            '<div ng-if="::(!tree.isLeafType(child.type))"' +
            '     ac-treeview-folder></div>' +
            '' +
            '<!-- Leaf -->' +
            '<div ng-if="::(tree.isLeafType(child.type))" ' +
            '     ac-treeview-leaf></div>' +
            '');
    }
})(angular);