(function (angular, undefined) {
    'use strict';

    angular.module('blocks.filters').filter('PhoneNumber', PhoneNumber);

    function PhoneNumber() {
        return function (input) {
            var ret = input;
            if(input !== undefined && input !== null && input !== ''){
                switch(input.length) {
                    case 10:
                        ret = input.replace(/(.{2})/g, "$1 ");
                        break;
                    default:
                        ret = input.replace(/(.{3})/g, "$1 ");
                        break;
                }
            }

            return ret;
        };
    }

})(angular);

