import { Caracteristique } from "../../../../../../core/models/caracteristique/Caracteristique"
import { ValeurCaracteristique } from "../../../../../../core/models/valeur-caracteristique/ValeurCaracteristique";

class CaractTextController {
    caracteristique: Caracteristique;
    valeurs: ValeurCaracteristique[];
    model: ValeurCaracteristique;
    index: number;
    defaultValue: String;
    /* @ngInject */
    constructor(){}
    $onInit(){
        const value = this.valeurs.find(x => x.idCaracteristique === this.caracteristique.id);
        if(!value) {
            this.model = <ValeurCaracteristique>{
                id: 0,
                idCaracteristique: this.caracteristique.id,
                value: this.defaultValue || this.defaultValue === '0' ? this.defaultValue : null
            }
            this.valeurs.push(this.model);
        }
        else {
            this.model = value;
        }
    }
    $onDestroy() {}
}
export default {
    bindings: {
        caracteristique: '<',
        valeurs: '=',
        isObligatoire: '<',
        lecture: '<',
        defaultValue: '='
    },
    controller: CaractTextController,
    template: require('./caracteristique-text.html')
}