import ProduitFormSiteLiesController from './produit.form.site.lies.controller';

export default {
    bindings: {
        produit: '=',
        isOpen: '='
    },
    template: require('./produit.form.site.lies.html'),
    controller: ProduitFormSiteLiesController
};
