// on importe le controller car les components angularJS
// permettent l'ajout direct d'une fonction dans la propriété "controller"
import PrelevementValeurMesureController from './prelevement.valeur.mesure.controller';

export default {
    bindings: {
        // on bind avec '<' (binding one way)
        // quand on ne veut pas pouvoir modifier l'objet dans le component (seulement dans l'appelant)
        //onUpdate: '&',
        // onDelete: '&',
        //form: '=',
        // on bind avec '=' (double binding)
        // quand on veut pouvoir modifier l'objet des deux cotés (appelant et component)
        mesure: '=',
        materiels: '<',
        prelevMateriels: '=',
        mesuresMateriels: '=',
        saisie: '=',
        isOpen: '<',
        refresh: '&',
        launchCalcul: '&',
        isImportable: '<',
        blDataLoaded: '=',
        configBalance: '='
    },
    template: require('./prelevement.valeur.mesure.html'),
    controller: PrelevementValeurMesureController
    // si on ne déclare pas de 'controllerAs', par défaut ce sera '$ctrl'
};
