import validatorBuilder from 'validator-builder';
import TransportEntete from '../transport-entete/transport.entete.model';

let transportValidator = null;

export default class Transport {
    constructor(data) {
        if (data) {
            this.entete = new TransportEntete(data.entete);
        } else {
            this.entete = new TransportEntete();
        }
    }

    isValid() {
        transportValidator = transportValidator || validatorBuilder.getInstanceFor('Transport');
        const validationResults = transportValidator.validate(this);
        return validationResults.isValid;
    }
}
