export default class MassiaDBService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getAvailableDB() {
        const response = await this.$http.get('mock/availabledb.mock.json');
        return response.data;
    }
}
