(function (angular, globalConfiguration, undefined) {
    'use strict';

    var fallbackLanguage = 'fr';
    var constants = {
        availableLanguages: [
            {code: 'fr', iso: 'fr-FR', locale: 'fr', name: 'Français', dateFormat: 'DD/MM/YYYY', displayDateMask: 'dd/MM/yyyy'},
            { code: 'en', iso: 'en-US', locale: 'en-gb', name: 'English', dateFormat: 'MM/DD/YYYY', displayDateMask: 'MM/dd/yyyy'},
            { code: 'es', iso: 'es-ES', locale: 'es', name: 'Espanol', dateFormat: 'DD/MM/YYYY', displayDateMask: 'dd/MM/yyyy'},
            {code: 'nl', iso: 'nl-BE', locale: 'nl', name: 'Hollandais', dateFormat: 'DD/MM/YYYY', displayDateMask: 'dd/MM/yyyy'},
        ],
        fallbackLanguage: fallbackLanguage,
        libsLocation: globalConfiguration && globalConfiguration.libsLocation ? globalConfiguration.libsLocation : 'node_modules',
        //applicationLanguage: globalConfiguration && globalConfiguration.defaultLanguage ? globalConfiguration.defaultLanguage : 'es'
        applicationLanguage: __configuration.preferredLanguage ? __configuration.preferredLanguage : fallbackLanguage
    };

    angular.module('blocks.i18n.globalization').constant('globalizationConstants', constants);
})(angular, window.globalConfiguration);