import ProduitFormQuotaBitumeController from './produit.form.quota.bitume.controller';

export default {
    bindings: {
        produit: '=',
        isOpen: '='
    },
    template: require('./produit.form.quota.bitume.html'),
    controller: ProduitFormQuotaBitumeController
};
