export default class Presse3rExportService {
    /* @ngInject */
    constructor($http) {
        this.$http = $http;
    }

    async uploadFile(formData) {
        const res = await this.$http.post(`${__configuration.apiUrl}/massia/prelevements/import-presse3r`, formData, {
            // transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
        return res.data;
    }
}
