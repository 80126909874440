import Logo from './mini.form.logo.model';

export default class LogoFormController {
    static $inject = ['_', '$scope', 'ImagesService', 'MassiaApplicationService', 'notification'];

    displayModal = false;

    constructor(_, $scope, ImagesService, MassiaApplicationService, notification) {
        this._ = _;
        this.$scope = $scope;
        this.ImagesService = ImagesService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.notification = notification;
        this.codeLoading = false;
        this.codeExists;
        this.imageTooLarge = false;
        this.fileTooBig = false;
        this.displayModal = false;
        this.newLogo = new Logo();
    }

    async $onInit() {}

    async valider() {
        this.newLogo.idType = await this.ImagesService.getIdTypeLogo();
        if (this.checkValidity()) {
            try {
                this.newLogo.id = await this.ImagesService.createLogo(this.newLogo);
                await this.ImagesService.onFileSelect(this.newLogo.file, this.newLogo.id, this.newLogo.code);

                this.entete.idLogo = this.newLogo.id;
                this.selectNewLogo();
                this.closeModal();
            } catch (ex) {
                console.log('error ', ex.data);
                this.notification.error(ex.data);
            }
        }
    }

    checkValidity() {
        let validity = true;
        if (!this.newLogo.isValid()) {
            validity = false;
            this.$scope.$broadcast('logoValidations');
        }
        this.imageTooLarge = this.newLogo?.$errors?.downloadFile?.find((x) => x == 'IMAGE_TOO_LARGE');
        this.fileTooBig = this.newLogo?.$errors?.downloadFile?.find((x) => x == 'FILE_TOO_LARGE');

        return validity;
    }

    closeModal() {
        this.displayModal = false;
        this.newLogo = new Logo();
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.codeExists = await this.ImagesService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.entete.codeExists = null;
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }

    async onFileSelect($files) {
        this.newLogo.file = $files;
        if (this?.newLogo?.file && this.newLogo.file[0]) {
            const fr = new FileReader();

            fr.onload = () => {
                const img = new Image();

                img.onload = () => {
                    this.newLogo.imgWidth = img.width;
                    this.newLogo.imgHeight = img.height;
                    this.newLogo.imgSize = this.newLogo.file[0].size;
                    this.checkValidity();
                    this.$scope.$apply();
                };

                img.src = fr.result;
            };
            fr.readAsDataURL(this?.newLogo?.file[0]);
        }
    }
}
