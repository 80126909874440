import validatorBuilder from 'validator-builder';

let tvaValidator = null;

export default class Tva {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.taux = data.taux;
        this.compteDebit = data.compteDebit;
        this.compteEncaissement = data.compteEncaissement;
        this.isTaxeCombine = data.isTaxeCombine;
        this.idTvaLies = data.idTvaLies;
    }
    isValid() {
        tvaValidator = tvaValidator || validatorBuilder.getInstanceFor('Tva');
        const validationResults = tvaValidator.validate(this);
        return validationResults.isValid;
    }
}
