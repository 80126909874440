import AdditionnalInfo from './additionnal.info.model';

export default class NatureAdditionnalInfoController {
    static $inject = ['_', 'CaracteristiquesService', '$translate'];

    constructor(_, CaracteristiquesService, $translate) {
        this.CaracteristiquesService = CaracteristiquesService;
        this.model = new AdditionnalInfo();
        this.$translate = $translate;
    }

    $onInit() {
        // Initialisation
        this.model = new AdditionnalInfo(this.idNature, this.additionnalInfo);

        // Configuration
        this.loadUnites();
    }

    $onChanges(objChanges) {
        if (objChanges.idNature) {
            this.model.idNature = objChanges.idNature.currentValue;
            this.templateUrl = this.getDynamicTpl();
        }
    }

    $doCheck() {
        if (!angular.equals(this.additionnalInfo, this.model)) {
            this.onUpdate({
                additionnalInfo: angular.copy(this.model)
            });
        }
    }

    getDynamicTpl() {
        let templateInputName = '';
        let templateUrl = '';

        switch (this.idNature) {
            case 3:
            case 62:
                templateInputName = 'numeric';
                break;
            case 5:
            case 63:
                this.elementsField = {
                    label: this.$translate.instant('CARACTERISTIQUES.FIELDS.ELEMENTS'),
                    id: 'elements'
                };
                templateInputName = 'elements';
                break;
            default:
                break;
        }

        if (templateInputName) {
            templateUrl = `field-template.${templateInputName}.html`;
        }

        return templateUrl;
    }

    async loadUnites() {
        this.unites = [];

        try {
            this.unites = await this.CaracteristiquesService.getUnites();
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    onChangeUnite() {
        this.onUniteChange({ unite: this.unite });
    }
}
