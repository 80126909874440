TypesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'TypesService',
	'MassiaApplicationService',
	'notification',
	'$transitions',
	'MOPService'
];

export default function TypesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	TypesService,
	MassiaApplicationService,
	notification,
	$transitions,
	MOPService
) {
	const vm = this;

	let previousTableState;
	let selectedTypeId = undefined;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.types = [];
	vm.slideWidth = '900px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right'
	};

	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectType = selectType;
	vm.isTypeSelected = isTypeSelected;
	vm.closeDetail = closeDetail;
	vm.loadTypes = loadTypes;
	vm.deleteType = deleteType;
	vm.editType = editType;
	vm.newType = newType;
	vm.duplicateType = duplicateType;
	vm.clientFilter = false;
	vm.sectorFilter = false;
	vm.exporter = exporter;
	vm.MOPService = MOPService;

	vm.mainRoute = $state && $state.current && $state.current.name ? $state.current.name.split('.')[0] : 'types';

	vm.$onInit = () => {
		vm.MOPService.setMop([{ title: 'TYPES.BREADCRUMBS.TYPES_LIST', filename: 'TypesEtCaracteristiques.pdf', application: 'massia' }]);
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		if (shouldSetSelectedId()) {
			selectedTypeId = $stateParams.id;
		}

		if ($state && $state.current && $state.current.name == 'types.clientlist') {
			vm.clientFilter = true;
		}

		if ($state && $state.current && $state.current.name == 'types.produitlist') {
			vm.clientFilter = true;
		}

		if ($state && $state.current && $state.current.name == 'secteurs.list') {
			vm.sectorFilter = true;
		}
	};

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function duplicateType(type) {
		//$state.go(vm.mainRoute + '.new', {type: type});
		if (type && type.id) {
			$state.go(vm.mainRoute + '.duplicate', { id: type.id });
		}
	}
	function stateChangeSuccessFunc(event, toState) {
		if (!$stateParams.id) {
			selectedTypeId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return (
			$state &&
			$state.current &&
			$state.current.name &&
			$state.current.name === vm.mainRoute + '.list.detail' &&
			$stateParams &&
			$stateParams.id
		);
	}

	function selectType(type) {
		if (type && type.id) {
			if (selectedTypeId !== type.id) {
				showDetail(type.id);
			} else {
				//si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
				closeDetail();
			}
		}
	}

	function isTypeSelected(type) {
		return type && type.id && selectedTypeId === type.id;
	}

	function showDetail(typeId) {
		/*let modalInstance = ModalService.info({
            modalTitle: $translate.instant('TYPES.DETAIL.MODAL_HEADER', {libelle: libelle}),
            modalMsg: this.getTooltipDroits(droits),
            headerClass: 'modal-info'
        });*/

		vm.slideMargin['margin-right'] = vm.slideWidth;
		selectedTypeId = typeId;
		$state.go(vm.mainRoute + '.list.detail', { id: typeId });
	}

	function closeDetail() {
		selectedTypeId = undefined;
		$state.go(vm.mainRoute + '.list');
	}

	async function loadTypes(tableState) {
		startLoading();

		// if ($state && $state.current && $state.current.name !== vm.mainRoute + '.list') {
		//     $state.go(vm.mainRoute + '.list');
		// }

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.types = [];

		try {
			// if ($state && $state.current && $state.current.name == 'types.clientlist') {
			//     alert('1')
			//     let data = await TypesService.getTypes(filters, sorts, pagination, MassiaApplicationService.getApplication());
			// } else {
			//     let data = await TypesService.getTypes(filters, sorts, pagination, MassiaApplicationService.getApplication());
			//     alert('2')
			// }

			if ($state && $state.current && $state.current.name == 'types.clientlist') {
				filters.push({ criterion: 'domaine', value: 'Sites clients', type: 'Contains' });
			}

			if ($state && $state.current && $state.current.name == 'types.produitlist') {
				filters.push({ criterion: 'domaine', value: 'Produits', type: 'Contains' });
			}

			if ($state && $state.current && $state.current.name == 'secteurs.list') {
				filters.push({ criterion: 'domaine', value: ['Affectations', 'Process'], type: 'In' });
			}

			const application = MassiaApplicationService.getApplication();

			const data = await TypesService.getTypes(filters, sorts, pagination, application);

			if (application == 'performance' && $state.current.name == 'types.list') {
				//Gérer dans le menu Secteurs
				vm.types = data.items.filter((e) => e.domaine != 'Affectations' && e.domaine != 'Process');
			} else {
				vm.types = data.items;
			}

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteType(type) {
		if (type && type.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('TYPES.DELETE.TITLE', { code: type.libelle }),
				modalMsg: $translate.instant('TYPES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await TypesService.deleteTypeById(type.id);
					notification.success($translate.instant('TYPES.DELETE.SUCCESS'));
					selectedTypeId = undefined;
					if (vm.params.id) {
						vm.state.go(vm.mainRoute + '.list');
					}
					loadTypes();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function editType(type) {
		if (type && type.id) {
			vm.state.go(vm.mainRoute + '.edit', { id: type.id });
		}
	}

	async function newType() {
		vm.state.go(vm.mainRoute + '.new');
	}

	async function exporter(modeImpression) {
		try {
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const fileName = 'Liste_Types';
			let resultat = undefined;
			try {
				resultat = await TypesService.exportListeTypes(modeImpression, filters, sorts, MassiaApplicationService.getApplication());
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('TYPES.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				const status = resultat.status;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
				} finally {
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
