(function () {
    'use strict';

    angular
        .module('blocks.treeview', [
            'pascalprecht.translate',
            'ngSanitize',
            /* Blocks */
            'blocks.helpers',
            'blocks.lodash',
            'blocks.notification',
            'blocks.validation',
            'blocks.context.menu',
            'blocks.filters',
            'blocks.dragdrop'
        ]);
})();