import validatorBuilder from 'validator-builder';

let societeEnteteValidator = null;

export default class SocieteEntete {
	constructor(data = {}) {
		this.id = data.id;
		this.code = data.code;
		this.libelle = data.libelle;
		this.raison = data.raison;
		this.associeId = data.associeId;
		this.typeId = data.typeId;
		this.paysId = data.paysId;
		this.idLogo = data.idLogo;
		this.isClient = data.isClient;
		this.isFournisseur = data.isFournisseur;
		this.isProducteur = data.isProducteur;
		this.isTransporteur = data.isTransporteur;
		this.isUtilisateur = data.isUtilisateur;
		this.isLaboratoire = data.isLaboratoire;
		this.isProprietaire = data.isProprietaire;
		this.isLocataire = data.isLocataire;
		this.isAuditeur = data.isAuditeur;
		this.adresse = data.adresse;
		this.codePostal = data.codePostal;
		this.ville = data.ville;
		this.telephone = data.telephone;
		this.fax = data.fax;
		this.mail = data.mail;
		this.website = data.website;
		this.siren = data.siren;
		this.tvaIntracommunautaire = data.tvaIntracommunautaire;
		this.mentionFacture = data.mentionFacture;
		this.codeExists = data.codeExists || null;
		this.niveauVisibilite = 0;
		this.tokenCerbere = data.tokenCerbere;
		this.syderep = data.syderep;
	}

	isValid() {
		societeEnteteValidator = societeEnteteValidator || validatorBuilder.getInstanceFor('SocieteEntete');
		const validationResults = societeEnteteValidator.validate(this);
		return validationResults.isValid;
	}
}
