import InventaireStockForm from './inventaire.stock.form.model';

export default class InventaireStockFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        '$translate',
        'ModalService',
        'StocksService',
        'StocksCommunicationService',
        'notification',
        '_',
        'moment',
        '$uibModal'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        $translate,
        ModalService,
        StocksService,
        StocksCommunicationService,
        notification,
        _,
        moment,
        $uibModal
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.params = $stateParams;
        this.$translate = $translate;
        this.ModalService = ModalService;
        this.StocksService = StocksService;
        this.StocksCommunicationService = StocksCommunicationService;
        this.notification = notification;
        this._ = _;
        this.moment = moment;
        this.$uibModal = $uibModal;

        this.isEditMode = false;
        this.loading = false;
        this.listeEltsNiv1 = [];
        this.sitesComLoaded = [];
        this.isValidationDisabled = true;

        this.closeAllOnglets();
    }

    async $onInit() {
        this.openFirstOnglet();

        this.ongletOpen = {
            isEnteteOpen: true,
            isInventaireStocksOpen: false
        };

        await this.initInventaire();
        if (this.isEditMode == false) {
            await this.getEltsNiv1();
            await this.getSites();
        } else {
            this.loadInventaireStockProduits(
                this.inventaire.idSiteCommercial,
                this.inventaire.eltNiv1,
                this.inventaire.idInventaire,
                this.inventaire.dateLevee
            );
        }
    }

    async generer() {
        this.loadInventaireStockProduits(
            this.inventaire.idSiteCommercial,
            this.inventaire.eltNiv1,
            this.inventaire.idInventaire,
            this.inventaire.dateLevee
        );
    }

    async loadInventaireStockProduits(idSiteCommercial, eltNiv1, idInventaire, date) {
        if (idSiteCommercial && eltNiv1 && date) {
            this.inventaire.produits = await this.StocksService.getInventaireStockProduits(idSiteCommercial, eltNiv1, idInventaire, date);
            this.ongletOpen.isInventaireStocksOpen = true;

            if (this.inventaire.produits.lignes.length > 0) {
                this.isValidationDisabled = false;
            }
        }
    }

    async initInventaire() {
        if (this.params && this.params.id) {
            this.isEditMode = true;
            this.startLoading();

            try {
                this.inventaire = {
                    id: this.params.id
                };
                const inventaireStockForm = await this.StocksService.getInventaireById(this.inventaire.id);
                this.inventaire = new InventaireStockForm(inventaireStockForm);
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListInventaire();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.inventaire = {};
            this.inventaire = new InventaireStockForm();
        }
    }

    openLevee(ligne) {
        const _this = this;
        _this.source = ligne;
        this.$uibModal
            .open({
                template: '<inventaire-stocks-levee levee="$ctrl.source" modal-instance="$ctrl.uibModalInstance"></inventaire-stocks-levee>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = _this.source;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl',
                backdrop: false
            })
            .result.then(
                function (result) {
                    if (result) {
                        _this.getTotal();
                    }
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    getTotal() {
        let totalVolume = 0;
        let totalStock = 0;
        let totalEcart = 0;
        this.inventaire.produits.lignes.forEach(function (element) {
            totalVolume = totalVolume + element.volume;
            totalStock = totalStock + element.stockReleve;
            totalEcart = totalEcart + element.ecart;
        }, this);
        this.inventaire.produits.volumeTotal = totalVolume;
        this.inventaire.produits.stockReleveTotal = totalStock;
        this.inventaire.produits.ecartTotal = totalEcart;
    }

    async openMouvement() {
        this.updateInventaire();
        const _this = this;
        _this.source = this.inventaire;
        this.$uibModal
            .open({
                template:
                    '<inventaire-stocks-mouvement inventaire="$ctrl.source" modal-instance="$ctrl.uibModalInstance"></inventaire-stocks-mouvement>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = _this.source;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'l',
                backdrop: false
            })
            .result.then(
                function (result) {},
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    async createInventaire() {
        this.startLoading();
        try {
            const res = await this.StocksService.createInventaire(this.inventaire);
            this.notification.success('STOCKS.INVENTAIRELIG.CREATED');
            return res;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateInventaire() {
        this.startLoading();
        try {
            const res = await this.StocksService.updateInventaire(this.inventaire);
            this.notification.success('STOCKS.INVENTAIRELIG.UPDATED');
            return res;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getEltsNiv1() {
        this.startLoading();
        try {
            this.listeEltsNiv1 = await this.StocksService.getElementsNiv1();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getSites() {
        this.startLoading();
        try {
            this.sitesComLoaded = await this.StocksService.getSites();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async confirmer() {
        if (this.isEditMode) {
            await this.updateInventaire();
        } else {
            const id = await this.createInventaire();

            if (id && this.inventaire.id) {
                this.reset();
            } else if (id) {
                this.$state.go('stocks.inventaireedit', { id: id });
            }
        }
    }

    async confirmerEtFermer() {
        if (this.isEditMode) {
            await this.updateInventaire();
            this.annuler();
        } else {
            const success = await this.createInventaire();

            if (success) {
                this.newInventaireId = success;
                this.annuler();
            }
        }
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('stocks.inventaire');
    }

    openFirstOnglet() {
        //par défaut, on ouvert l'onglet "Entête"
        this.ongletOpen = {
            isEnteteOpen: true
        };
    }

    closeAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: false,
            isInventaireStocksOpen: false
        };
    }

    async print() {
        this.startLoading();
        const fileName = Date.now();

        const resultat = await this.StocksService.printInventaireStock(
            this.inventaire.idSiteCommercial,
            this.inventaire.eltNiv1,
            this.inventaire.idInventaire,
            this.inventaire.dateLevee
        );

        const data = resultat.data;
        const status = resultat.status;
        let headers = resultat.headers;

        headers = headers();

        const contentType = headers['content-type'];

        const fileExtention = '.xlsx';
        const linkElement = document.createElement('a');
        try {
            const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
            const url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute('download', fileName + fileExtention);

            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(clickEvent);
        } catch (ex) {
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
