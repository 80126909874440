class MassiaAuthorizeDirective {
    constructor(MassiaRightsService, ngIfDirective) {
        this.MassiaRightsService = MassiaRightsService;
        this.ngIfDirective = ngIfDirective[0];

        // définition de la directive
        this.restrict = 'A';
        this.transclude = this.ngIfDirective.transclude;
        this.priority = this.ngIfDirective.priority;
        this.terminal = this.ngIfDirective.terminal;
        this.scope = {
            domain: '@',
            right: '@',
            application: '@'
        };
    }
    link(scope, element, attrs, ctrl) {
        const domain = scope.domain;
        const right = scope.right;
        const application = scope.application;
        // par défaut s'il n'y a pas de valeur on affiche
        let canShow = true;

        canShow = this.MassiaRightsService.userHasRight(domain, right, application);

        // on set l'attribut ng-if à true ou false
        attrs.ngIf = function () {
            return canShow;
        };
        // on applique les règles du ng-if (notamment la disparition de la DOM)
        this.ngIfDirective.link.apply(this.ngIfDirective, arguments);
    }

    static create() {
        return new MassiaAuthorizeDirective(...arguments);
    }
}

MassiaAuthorizeDirective.create.$inject = ['MassiaRightsService', 'ngIfDirective'];

export default MassiaAuthorizeDirective;
