import validatorBuilder from 'validator-builder';

let commandeLigneProduitValidator = null;

export default class CommandeLigneProduit {
    constructor(selectedProduit, siteCommercial, typeES) {
        this.id = -1;
        this.prixVente = 0;
        this.prixStandard = 0;
        this.produit = selectedProduit.libelle;
        this.siteCommercial = '';
        this.siteCommercialId = siteCommercial;
        this.producteur = selectedProduit.producteurLibelle;
        this.producteurId = selectedProduit.producteurId;
        this.produitId = selectedProduit.produitId;
        this.quantiteCommandee = 1;
        this.typeES = typeES;
        this.typePrestationLie = '0';
        this.uniteLibelle = '';
        this.uniteId = selectedProduit.uniteId;
        this.tauxTva = '';
        this.tvaId = 0;
    }

    isValid() {
        commandeLigneProduitValidator = commandeLigneProduitValidator || validatorBuilder.getInstanceFor('CommandeLigneProduit');
        const validationResults = commandeLigneProduitValidator.validate(this);
        return validationResults.isValid;
    }
}
