import ProduitFormSpecificitesController from './produit.form.specificites.controller';

export default {
    bindings: {
        produit: '<',
        typeId: '<',
        app: '<'
    },
    template: require('./produit.form.specificites.html'),
    controller: ProduitFormSpecificitesController
};
