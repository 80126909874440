export default function scrollTop() {
    return {
        retrict: 'A',
        link: function (scope, element, attrs) {
            // element.bind('click', accordeonToggleFunc);
            // function accordeonToggleFunc() {
            //     if (scope.hasOwnProperty('accordeonToggle')) {
            //         var isOpen = scope.accordeonToggle.isEnteteOpen || scope.accordeonToggle.isFormOpen;
            //         for (let key in scope.accordeonToggle) {
            //             scope.accordeonToggle[key] = !isOpen;
            //         }
            //         scope.$apply();
            //     }
            // }
        }
    };
}
