export default class AccessPortail {
    constructor(data = {}) {
        this.id = data.id;
        this.idDomain = data.idDomain;
        this.codeDomain = data.codeDomain;
        this.accessLevel = data.accessLevel;
        this.read = data.read || false;
        this.update = data.update || false;
        this.create = data.create || false;
        this.delete = data.delete || false;
        this.print = data.print || false;
        this.build = data.build || false;
    }
}
