BadgeValidator.$inject = ['validator'];

export default function BadgeValidator(validator) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 15).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance
        .ruleFor('code')
        .matches(/^[a-zA-Z0-9_|]*$/)
        .withMessage('CODE_CHARACTERS_NOK');
    instance
        .ruleFor('quantiteTicketChargement')
        .matches(/^([0-9]|[0-9]+([\.,]?[0-9]+))$/)
        .withMessage('QUANTITE_TICKET_CHARGEMENT_NOK');
    instance.ruleFor('idTypeBenne').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;
}
