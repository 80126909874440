import ModaleCreationElementsController from './modale-creation/modale.creation.elements.controller';

export default class NiveauxHierarchiquesElementsController {
    static $inject = ['$translate', 'ModalService', 'AcTreeviewCommunicationService', 'TypesNiveauxHierarchique'];

    constructor($translate, ModalService, AcTreeviewCommunicationService, TypesNiveauxHierarchique) {
        // Injections
        this.$translate = $translate;
        this.ModalService = ModalService;
        this.AcTreeviewCommunicationService = AcTreeviewCommunicationService;
        this.TypesNiveauxHierarchique = TypesNiveauxHierarchique;

        // Properties
        this.config = {};
        this.service = {};
    }

    // Component events
    $onInit() {
        this.config = this.buildConfig(this.treeviewConfig);
        this.service = this.treeviewService;
        this.service.setTreeviewName(this.config.name);
    }

    async $onChanges(objChanges) {
        if (objChanges.nodeUpdated) {
            try {
                const rootNode = await this.service.getNode(this.service.getRootId());
                this.AcTreeviewCommunicationService.raiseNodeRefreshFunction(this.config.name, rootNode.id, rootNode.expanded, rootNode);
            } catch (ex) {}
        }
    }

    // Actions
    buildConfig(treeviewConfig) {
        treeviewConfig.functions = treeviewConfig.functions || {};

        return {
            functionsScope: this,
            name: treeviewConfig.name,
            displayTreeActions: treeviewConfig.displayTreeActions || false,
            dropDisabled: treeviewConfig.dropDisabled && true,
            searchDisabled: treeviewConfig.searchDisabled || true,
            autoExpand: treeviewConfig.autoExpand || true,
            rootType: treeviewConfig.rootType || 'root',
            rootTypeIcon: treeviewConfig.rootTypeIcon || 'list-alt',
            leafTypes: treeviewConfig.leafTypes || [],
            leafTypesIcons: treeviewConfig.leafTypesIcons || {},
            functions: {
                getStyle: this.onGetStyle,
                canRefreshNode: this.onCanRefreshNode,
                canToggleNode: this.onCanToggleNode,
                getNodeIcon: this.onGetNodeIcon,
                canRenameNode: this.onCanRenameNode,
                renameNode: this.onRenameNode,
                canCreateNode: this.onCanCreateNode,
                createNode: this.onCreateNode,
                canRemoveNode: this.onCanRemoveNode,
                removeNode: this.onRemoveNode,
                canMoveNodeUp: this.onCanMoveNodeUp,
                moveNodeUp: this.onMoveNodeUp,
                canMoveNodeDown: this.onCanMoveNodeDown,
                moveNodeDown: this.onMoveNodeDown,
                canDrop: this.onCanDrop,
                changeNodeParent: this.onChangeNodeParent
            }
        };
    }

    // Override configuration metier
    onGetStyle() {
        if (angular.isFunction(this.treeviewConfig.getStyle)) {
            this.treeviewConfig.getStyle.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.getStyle.apply(this, arguments);
        }
    }

    onCanRefreshNode() {
        if (angular.isFunction(this.treeviewConfig.onCanRefreshNode)) {
            this.treeviewConfig.onCanRefreshNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.canRefreshNode.apply(this, arguments);
        }
    }

    onCanToggleNode() {
        if (angular.isFunction(this.treeviewConfig.canToggleNode)) {
            this.treeviewConfig.canToggleNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.canToggleNode.apply(this, arguments);
        }
    }

    onGetNodeIcon() {
        if (angular.isFunction(this.treeviewConfig.getNodeIcon)) {
            this.treeviewConfig.getNodeIcon.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.getNodeIcon.apply(this, arguments);
        }
    }

    onCanRenameNode() {
        if (angular.isFunction(this.treeviewConfig.canRenameNode)) {
            this.treeviewConfig.canRenameNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.canRenameNode.apply(this, arguments);
        }
    }

    onCanCreateNode() {
        if (angular.isFunction(this.treeviewConfig.canCreateNode)) {
            this.treeviewConfig.canCreateNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.canCreateNode.apply(this, arguments);
        }
    }

    onCreateNode() {
        if (angular.isFunction(this.treeviewConfig.createNode)) {
            this.treeviewConfig.createNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.createNode.apply(this, arguments);
        }
    }

    onCanRemoveNode() {
        if (angular.isFunction(this.treeviewConfig.canRemoveNode)) {
            this.treeviewConfig.canRemoveNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.canRemoveNode.apply(this, arguments);
        }
    }

    onRenameNode(item) {
        this.service.renameNode(item);
    }

    onRemoveNode() {
        if (angular.isFunction(this.treeviewConfig.removeNode)) {
            this.treeviewConfig.removeNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.removeNode.apply(this, arguments);
        }
    }

    onCanMoveNodeUp() {
        if (angular.isFunction(this.treeviewConfig.canMoveNodeUp)) {
            this.treeviewConfig.canMoveNodeUp.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.canMoveNodeUp.apply(this, arguments);
        }
    }

    onMoveNodeUp() {
        if (angular.isFunction(this.treeviewConfig.moveNodeUp)) {
            this.treeviewConfig.moveNodeUp.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.moveNodeUp.apply(this, arguments);
        }
    }

    onCanMoveNodeDown() {
        if (angular.isFunction(this.treeviewConfig.canMoveNodeDown)) {
            this.treeviewConfig.canMoveNodeDown.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.canMoveNodeDown.apply(this, arguments);
        }
    }

    onMoveNodeDown() {
        if (angular.isFunction(this.treeviewConfig.moveNodeDown)) {
            this.treeviewConfig.moveNodeDown.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.moveNodeDown.apply(this, arguments);
        }
    }

    onCanDrop(source, target) {
        return this.service.canDrop(source, target);
    }

    onChangeNodeParent(node, targetId, target, parent) {
        this.service.changeNodeParent(node, targetId, target, parent);
    }

    // Configuration treeview
    getStyle(item) {
        if (item && item.id === this.service.getRootId()) {
            return 'root-item';
        }
    }

    canRefreshNode(item) {
        return item && item.type !== this.TypesNiveauxHierarchique.Libre.value && item.type !== this.TypesNiveauxHierarchique.Pays.value;
    }

    canToggleNode(item) {
        return item && item.id !== this.service.getRootId() && item.type !== this.TypesNiveauxHierarchique.Libre.value;
    }

    getNodeIcon(item) {
        let icon = 'glyphicon-folder-open';

        if (item.type === this.TypesNiveauxHierarchique.Libre.value) {
            icon = 'glyphicon-play';
        } else if (!item.expanded) {
            icon = 'glyphicon-folder-close';
        }

        return icon;
    }

    canRenameNode(item) {
        return !(
            item &&
            (item.id === this.service.getRootId() ||
                item.type === this.TypesNiveauxHierarchique.Libre.value ||
                item.type === this.TypesNiveauxHierarchique.Pays.value)
        );
    }

    renameNode(item) {
        this.service.renameNode(item);
    }

    //on ne peut pas ajouter un sous element pour un element de type pays ou saisi libre
    canCreateNode(item) {
        return !(item && (item.type === this.TypesNiveauxHierarchique.Libre.value || item.type === this.TypesNiveauxHierarchique.Pays.value));
    }

    async createNode(parent) {
        const modalInstance = this.ModalService.open({
            template: require('./modale-creation/modale.creation.element.tpl.html'),
            controller: ModaleCreationElementsController,
            controllerAs: '$ctrl',
            size: 'md',
            resolve: {
                data: () => {
                    return {
                        parentNode: parent,
                        service: this.service
                    };
                }
            }
        });
    }

    canRemoveNode(item) {
        return !(
            item &&
            (item.id === this.service.getRootId() ||
                item.type === this.TypesNiveauxHierarchique.Pays.value ||
                item.type === this.TypesNiveauxHierarchique.Libre.value)
        );
    }

    removeNode(item) {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'SUPPRIMER',
            modalMsg: this.$translate.instant('SUPPRIMER_CONFIRMATION', { entity: item.label }),
            headerClass: 'modal-danger'
        });

        modalInstance.result.then(() => this.service.removeNode(item));
    }

    canMoveNodeUp(item) {
        return (
            item &&
            item.id !== this.service.getRootId() &&
            item.type !== this.TypesNiveauxHierarchique.Pays.value &&
            item.type !== this.TypesNiveauxHierarchique.Libre.value
        );
    }

    moveNodeUp(item) {
        this.service.moveNode('up', item);
    }

    canMoveNodeDown(item) {
        return (
            item &&
            item.id !== this.service.getRootId() &&
            item.type !== this.TypesNiveauxHierarchique.Pays.value &&
            item.type !== this.TypesNiveauxHierarchique.Libre.value
        );
    }

    moveNodeDown(item) {
        this.service.moveNode('down', item);
    }
}
