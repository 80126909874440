import validatorBuilder from 'validator-builder';
import LigneInventaireStockForm from './ligne.inventaire.stock.form.model';

let inventaireStockFormValidator = null;

export default class InventaireStockForm {
    constructor(data) {
        data = data || {};
        this.idInventaire = data.idInventaire || null;
        this.libelle = data.libelle || null;
        this.societe = data.societe || null; // libellé de la Société
        this.idSiteCommercial = data.idSiteCommercial || null;
        this.siteCommercial = data.siteCommercial || null; // libellé du Site Commercial
        this.auteur = data.auteur || null; // prénom et nom de l'auteur
        this.dateLevee = data.dateLevee || null;
        this.commentaire = data.commentaire || null;
        this.dateIntegration = data.dateIntegration || null;
        this.idEltNiv1 = data.idEltNiv1 || null;
        this.eltNiv1 = data.eltNiv1 || null;
        this.libelleEltNiv1 = data.libelleEltNiv1 || null;

        this.produits = data.produits;
        // this.lignes = [];
        // if (typeof data.lignes != 'undefined') {
        //     for (var i = 0; i < data.lignes.length; i++) {
        //         var element = data.lignes[i];
        //         this.lignes.push(new LigneInventaireStockForm(element))
        //     }
        // }
    }

    isValid() {
        inventaireStockFormValidator = inventaireStockFormValidator || validatorBuilder.getInstanceFor('InventaireStockForm');
        const validationResults = inventaireStockFormValidator.validate(this);
        return validationResults.isValid;
    }
}
