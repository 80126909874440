import validatorBuilder from 'validator-builder';

let documentCEEnteteValidator = null;

export default class DocumentCEEntete {
    constructor(data = {}) {
        this.id = data.id;
        //this.code = data.code;
        this.libelle = data.libelle;
        this.idProducteur = data.idProducteur;
        this.idProduit = data.idProduit;
        this.idCertificat = data.idCertificat;
        this.normes = data.normes;
        this.normesIds = data.normesIds;
        this.date = data.date;
        this.notes = data.notes;
        this.dateApposition = data.dateApposition;
        this.reference = data.reference;
        this.numeroOrganisme = data.numeroOrganisme;
        this.numeroCertificat = data.numeroCertificat;

        this.idLogo = data.idLogo;
        this.idDocument = data.idDocument;

        // si besoin, on indique une valeur par défaut
        // le (xxx || yyy) va être égal à yyy si xxx est undefined, null, false ou égal à 0
        //this.codeExists = data.codeExists || null;
        //todo codeInvalid non utilise actuellement, a confirmer
        // this.codeInvalid = data.codeInvalid || null;
    }

    isValid() {
        documentCEEnteteValidator = documentCEEnteteValidator || validatorBuilder.getInstanceFor('DocumentCEEntete');
        const validationResults = documentCEEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
