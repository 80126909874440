(function (angular, globalHelpers, undefined) {
    'use strict';

    angular
        .module('blocks.modal')
        .directive('acModalResize', acModalResize);
    acModalResize.$inject = ['$document'];

    /**
     * Afin d'utiliser cette directive,
     * il suffit de mettre ac-modal-resize="true"
     * ou n'importe quelle variable qui s'initialise à true ac-modal-resize="{{ctrl.canResize}}"
     * sur l'élément que l'on souhaite resizer au sein d'une modale
     */
    function acModalResize($document) {
        var directive = {
            link: link,
            restrict: 'A'
        };
        return directive;

        function link(scope, element, attrs) {
            var startY, startHeight, initialHeight;
            var startX, startWidth, initialWidth;
            var resizer, resizerEl;
            var modal, modalEl, modalContent, modalContentEl, modalBody, modalHeader, modalHeaderEl;
            var registeredEvents;

            if (attrs.acModalResize === 'true') {
                registeredEvents = [
                    scope.$on('$destroy', dispose)
                ];

                init();
            }

            function init() {
                modalBody = element[0];
                modal = globalHelpers.findAncestorByClassName(element[0], 'modal');
                // on récupère le modal-content plutot que le modal-dialog en raison de l'alignement centré
                // on ne souhaite redimensionner que vers la droite (et non pas des deux cotés en même temps)
                modalContent = globalHelpers.findAncestorByClassName(element[0], 'modal-content');
                if (modal && modalContent) {
                    modalEl = angular.element(modal);
                    modalContentEl = angular.element(modalContent);
                    initializeModalBody();
                    initializeModalHeader();
                    initializeResizer();
                }
            }

            function initializeModalBody() {
                if (modalBody) {
                    // on permet l'overflow pour avoir le scroll vertical
                    modalBody.style['overflow-y'] = 'auto';
                    modalBody.style['overflow-x'] = 'hidden';
                }
            }

            function initializeModalHeader() {
                // on récupère le modalHeader
                modalHeader = modalContentEl[0].querySelector('.modal-header');
                if (modalHeader) {
                    modalHeaderEl = angular.element(modalHeader);
                    // pour lui mettre un event au double clic permettant de réinitialiser les valeurs
                    modalHeaderEl.bind('dblclick', resetSize);
                }
            }

            function initializeResizer() {
                // afin de placer le resizer on rend la modale position relative
                modalContentEl.css({position: 'relative'});
                // on créé un élément qui prendra les events de resize
                resizer = document.createElement('div');
                // on lui ajoute l'event permettant la resize
                resizerEl = angular.element(resizer);
                resizerEl.bind('mousedown', initDrag);
                // on lui donne une classe pour pouvoir le styler de l'extérieur
                resizer.classList.add('modal-resizer');

                angular.element(resizer).css({
                    position: 'absolute',
                    bottom: '0',
                    right: '0',
                    height: '10px',
                    width: '10px',
                    background: 'black',
                    cursor: 'se-resize'
                });
                // on l'ajoute à la modale
                modalContentEl.append(resizer);
            }

            function initDrag(event) {
                // Prevent default dragging of selected content
                event.preventDefault();

                startY = event.clientY;
                startX = event.clientX;

                // on sauvegarde les hauteur et largeur initiales
                if (!initialHeight) {
                    // la hauteur est celle du body (les headers et footers ne changent pas)
                    initialHeight = modalBody.offsetHeight + 20;
                }
                if (!initialWidth) {
                    // la largeur est celle du modal-content au complet
                    initialWidth = modalContentEl[0].offsetWidth;
                }

                startHeight = modalBody.offsetHeight;
                startWidth = modalBody.offsetWidth;

                modalEl.bind('mousemove', doDrag);
                $document.bind('mouseup', stopDrag);
            }

            function doDrag(e) {
                var offsetY = startHeight + e.clientY - startY;
                // on empêche la hauteur d'être plus petite que 50
                if (offsetY > 50) {
                    modalBody.style.height = offsetY + 'px';
                }
                var offsetX = startWidth + e.clientX - startX;
                // on empêche une largeur plus petite que l'initiale
                // pour éviter les problèmes de style
                if (offsetX > initialWidth) {
                    modalContentEl[0].style.width = offsetX + 'px';
                }
            }

            function stopDrag() {
                modalEl.unbind('mousemove', doDrag);
                $document.unbind('mouseup', stopDrag);
            }

            function resetSize() {
                modalBody.style.height = initialHeight + 'px';
                modalContentEl[0].style.width = initialWidth + 'px';
            }

            function dispose() {
                angular.forEach(registeredEvents, function (unregisterEvent) {
                    unregisterEvent();
                });

                if (modalHeaderEl) {
                    modalHeaderEl.unbind('dblclick', resetSize);
                }

                if (modalEl) {
                    modalEl.unbind('mousemove', doDrag);
                }

                if (resizer) {
                    resizer.parentNode.removeChild(resizer);
                    resizer = undefined;
                }

                $document.unbind('mouseup', stopDrag);
            }


        }
    }
})(angular, window.globalHelpers);