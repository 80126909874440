export default function UserListController(
    $state,
    $stateParams,
    PaginationService,
    UtilisateurPortailService,
    notification,
    ModalService,
    $translate
) {
    const $ctrl = this;
    let previousTableState;
    this.$onInit = () => {
        _toggleLoading(true);
        $ctrl.itemsByPageOptions = [20, 50, 100];
        $ctrl.userList = [];
        $ctrl.loadUserList = loadUser;
    };

    this.$onDestroy = () => {};

    var loadUser = async (tableState) => {
        try {
            _toggleLoading(true);
            if (tableState) {
                previousTableState = tableState;
            }
            const filters = PaginationService.getFilters(previousTableState);
            const sorts = PaginationService.getSorts(previousTableState);
            const pagination = PaginationService.getPagination(previousTableState);

            const res = await UtilisateurPortailService.getAll(filters, sorts, pagination);

            $ctrl.userList = res.data.items;
            UtilisateurPortailService.users = res.data.items;
            if (previousTableState) {
                PaginationService.setTableState(res.data.skip, res.data.take, res.data.total, previousTableState);
            }
            _toggleLoading();
        } catch (err) {
            console.error(err);
            if (err.data && err.data.message) {
                notification.error(err.data.message);
            } else {
                notification.error(err.message);
            }
            _toggleLoading();
        }
    };

    var _toggleLoading = (value) => {
        $ctrl.loading = value || !$ctrl.loading;
    };

    $ctrl.update = (user) => {
        $state.go('portail.users.edit', {
            id: user.id,
            obj: user
        });
    };

    $ctrl.delete = (user) => {
        ModalService.confirm({
            modalTitle: $translate.instant('PORTAIL.USER.DELETE.TITLE'),
            modalMsg: `${$translate.instant('PORTAIL.USER.DELETE.MESSAGE')} ${user.login}`,
            headerClass: 'modal-danger'
        }).result.then(async () => {
            try {
                await UtilisateurPortailService.delete(user.id);
                notification.success('PORTAIL.USER.DELETE.SUCCESS');
                return await loadUser();
            } catch (err) {
                console.log(err);
                if (err.data && err.data.message) {
                    return notification.error(err.data.message);
                }
                return notification.error(err.message);
            }
        });
    };
}

UserListController.$inject = [
    '$state',
    '$stateParams',
    'PaginationService',
    'UtilisateurPortailService',
    'notification',
    'ModalService',
    '$translate'
];
