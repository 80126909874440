export default function MassiaRoutes($stateProvider) {
    $stateProvider.state('massia', {
        abstract: true,
        url: '',
        views: {
            massia: {
                template: '<layout></layout>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            skip: true
        }
    });
}
MassiaRoutes.$inject = ['$stateProvider'];
