import DeviseEntete from './devise.entete.model';
let unregisterReinit;

export default class DeviseEnteteController {
    static $inject = ['$stateParams', 'DevisesCommunicationService', 'DevisesService', 'notification'];

    constructor($stateParams, DevisesCommunicationService, DevisesService, notification) {
        this.$stateParams = $stateParams;
        this.DevisesCommunicationService = DevisesCommunicationService;
        this.DevisesService = DevisesService;
        this.notification = notification;
        this.loading = false;
        this.codeLoading = false;
        this.isEditMode = false;
        this.hasTypes = false;
    }

    async $onInit() {
        unregisterReinit = this.DevisesCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        unregisterReinit();
    }

    initForm() {
        let entete = {};

        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
            entete = {
                code: this.devise.entete.code,
                id: this.devise.entete.id,
                symbole: this.devise.entete.symbole,
                arrondi: this.devise.entete.arrondi
            };
        } else {
            this.isEditMode = false;
        }

        this.devise.entete = new DeviseEntete(entete);
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.devise.entete.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.devise.entete.codeExists = await this.DevisesService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
