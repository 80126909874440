/* @ngInject */
export default class FamillesMultiSelectController {
    // Propriétés
    loading = false;
    familles = [];
    selectedFamilles = [];

    constructor(FamillesService, MassiaApplicationService) {
        this.FamillesService = FamillesService;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    // Méthodes publiques
    async $onInit() {
        await this.loadFamilles();
    }

    selectFamille() {
        return this.onSelect({ familles: this.selectedFamilles });
    }

    async loadFamilles() {
        let data;
        try {
            if (this.MassiaApplicationService.getApplication() == 'laboratoire') {
                data = await this.FamillesService.getFamillesFiltrees();
            } else {
                data = await this.FamillesService.getFamilles();
            }
        } catch (ex) {
            data = [];
        }
        this.familles = data;
        return;
    }
}
