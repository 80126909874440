import validatorBuilder from 'validator-builder';

let themeValidator = null;

export default class Theme {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.hasCampagne = data.hasCampagne;
        this.localiserPointsMesure = data.localiserPointsMesure;
        this.couleurPointsMesure = data.couleurPointsMesure;
        this.idMetier = data.idMetier;
        this.metierLibelle = data.metierLibelle;
        this.codeExists = data.codeExists || null;
    }

    isValid() {
        themeValidator = themeValidator || validatorBuilder.getInstanceFor('Theme');
        const validationResults = themeValidator.validate(this);
        return validationResults.isValid;
    }
}
