import FactureArticle from './articles.model';
let unregisterReinit;

export default class ArticlesChantierController {
    static $inject = [
        '$stateParams',
        '$state',
        '$translate',
        'notification',
        'FacturesCommunicationService',
        'FacturesService',
        'ArticlesService',
        '$filter'
    ];

    constructor($stateParams, $state, $translate, notification, FacturesCommunicationService, FacturesService, ArticlesService, $filter) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.params = $stateParams;
        this.$translate = $translate;
        this.FacturesCommunicationService = FacturesCommunicationService;
        this.FacturesService = FacturesService;
        this.ArticlesService = ArticlesService;
        this.$filter = $filter;
        this.notification = notification;
        this.loading = false;
        this.codeLoading = false;
        this.isEditMode = false;
    }

    async $onInit() {
        this.dateEcheanceFormule = null;
        this.messageFormule = 'Masquer produits avec formules expirées';
        unregisterReinit = this.FacturesCommunicationService.registerReinit(() => this.initForm());
        this.popoverTypePrix = {
            templateUrl: 'popoverTypePrix.html'
        };
        this.objectToUpdate = {};
        this.factureArticle = new FactureArticle();

        this.loadTypesTicket();
        this.getTVA();
        this.getArticles();
    }

    $onDestroy() {
        unregisterReinit();
    }

    newArticleFormVisible() {
        if (!this.isEditMode && this.facture.entete.isModifiable && this.facture.entete && this.facture.entete.idSociete) {
            return true;
        }
        return false;
    }

    /* récupérer le lien cliqué */
    initUpdate(index, bill, typeArticle) {
        this.newArticle = angular.copy(bill);
        if (typeArticle === 'product') {
            this.objectToUpdate = this.facture.factureProduits;
        } else if (typeArticle === 'prestation') {
            this.objectToUpdate = this.facture.facturePrestations;
        }
        this.realIndexInArray = this.objectToUpdate.indexOf(bill);

        /* Sert pour la fermeture de la popover
        a donc besoin du faux index */
        this.clickedPopoverIndex = index;
    }

    /* calculs */
    async calculate() {
        await this.convertStringToDecimal(this.factureArticle);

        if (typeof this.factureArticle.typePrix !== 'undefined' && typeof this.factureArticle.quantite !== 'undefined') {
            switch (this.factureArticle.typePrix) {
                case 0: // Aucune remise
                    this.factureArticle.ht = 0;
                    this.factureArticle.prixUnitaire = 0;
                    this.factureArticle.remise = 0;
                    this.factureArticle.ristourne = 0;
                    break;
                case 1: // "libelle": "Négocié"
                    this.factureArticle.ristourne = this.factureArticle.prixStandard - this.factureArticle.prixUnitaire;
                    this.factureArticle.remise = (this.factureArticle.ristourne * 100) / this.factureArticle.prixStandard;
                    this.factureArticle.ht = this.factureArticle.prixUnitaire * this.factureArticle.quantite;
                    break;
                case 2: // "libelle": "Remise"
                    this.factureArticle.ristourne = (this.factureArticle.prixStandard * this.factureArticle.remise) / 100;
                    this.factureArticle.prixUnitaire = this.factureArticle.prixStandard - this.factureArticle.ristourne;
                    this.factureArticle.ht = this.factureArticle.prixUnitaire * this.factureArticle.quantite;
                    break;
                case 3: // "libelle": "Ristourne"
                    this.factureArticle.prixUnitaire = this.factureArticle.prixStandard - this.factureArticle.ristourne;
                    this.factureArticle.remise = (this.factureArticle.ristourne * 100) / this.factureArticle.prixStandard;
                    this.factureArticle.ht = this.factureArticle.prixUnitaire * this.factureArticle.quantite;
                    break;
            }
        }
    }

    calcul() {
        this.calculate(this.new);
    }

    addArticle() {
        const newArticle = {
            idFacture: this.params.id,
            idArticle: this.factureArticle.article[0].id,
            idSite: this.factureArticle.article[0].producteurId,
            idTicketProduit: null,
            idSiteCommercial: this.factureArticle.idSiteCommercial,
            idChantier: this.factureArticle.idChantier,
            idLieu: this.factureArticle.idLieu,
            idTicket: null,
            codeArticle: this.factureArticle.article[0].produitCode,
            nomArticle: this.factureArticle.article[0].produitLibelle,
            quantite: this.factureArticle.quantite,
            unite: this.factureArticle.unite.id,
            prixStandard: this.factureArticle.prixStandard,
            remise: this.factureArticle.remise,
            ristourne: this.factureArticle.ristourne,
            prixUnitaire: this.factureArticle.prixUnitaire,
            prixUnitaireFranco: null,
            ht: this.factureArticle.ht,
            idTVA: this.factureArticle.idTVA,
            typeArticle: this.factureArticle.typeArticle,
            typePrix: this.factureArticle.typePrix,
            // typeArticle: Number(this.factureArticle.typeArticle),
            // typeArticleEnum: function () {
            //     if (Number(this.factureArticle.typeArticle) == 2 || Number(this.factureArticle.typeArticle) == 1) {
            //         return 'prestation';
            //     } else {
            //         return "produit";
            //     }
            // },

            isFrancoForFactById: false,
            isTransporteForFactById: false,
            isVisible: true
        };

        if (newArticle.idTVA != null) {
            this.createArticle(newArticle);
        } else {
            this.notification.error(this.$translate.instant('FACTURES.NOTVA'));
        }
    }

    showTypePrixfields() {
        if (this.factureArticle && typeof this.factureArticle.typePrix !== 'undefined') {
            return true;
        }
        return false;
    }

    async changeProduitformule() {
        if (this.dateEcheanceFormule == null) {
            this.dateEcheanceFormule = this.facture.entete.dateFin ? this.facture.entete.dateFin : this.facture.entete.dateGeneration;
            this.messageFormule = 'Afficher produits avec formules expirées';
        } else {
            this.dateEcheanceFormule = null;
            this.messageFormule = 'Masquer produits avec formules expirées';
        }

        await this.getElementsSelectionnables();
    }

    async getElementsSelectionnables() {
        this.factureArticle.unite = {};
        const filtre = {
            societeId: this.facture.entete.idSociete,
            clientId: this.facture.entete.idClient,
            entreeSortie: 1,
            achatVente: 1
        };

        // SL les services attendent soit un élément avec une valeur, soit d'élément du tout
        if (this.facture.entete.idSiteCommercial) {
            filtre.siteCommercialId = this.facture.entete.idSiteCommercial;
        }

        if (this.factureArticle.idChantier) {
            filtre.chantierId = this.factureArticle.idChantier;
        }

        try {
            const typeArticle = Number(this.factureArticle.typeArticle);
            switch (typeArticle) {
                case 0:
                    filtre.date = this.dateEcheanceFormule;
                    this.produitsSelectionnables = await this.FacturesService.getProduitsSelectionnables(filtre);
                    this.produitsLies = await this.prepareListeElementsLies(this.produitsSelectionnables);
                    break;
                case 1:
                    this.transportsSelectionnables = await this.FacturesService.getTransportsSelectionnables(filtre);
                    this.transportsLies = await this.prepareListeElementsLies(this.transportsSelectionnables);
                    break;
                case 2:
                    this.prestationsSelectionnables = await this.FacturesService.getPrestationsSelectionnables(filtre);
                    this.prestationsLiees = await this.prepareListeElementsLies(this.prestationsSelectionnables);
                    break;
            }
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    prepareListeElementsLies(elementsATraiter) {
        const elementsSelectionnablesOrdonnes = this.$filter('orderBy')(elementsATraiter, 'producteurLibelle');
        let tmp = null;
        const temp = [];
        for (let index = 0; index < elementsSelectionnablesOrdonnes.length; index++) {
            const currentValue = elementsSelectionnablesOrdonnes[index];
            const toReturn = [];
            if (currentValue.hasOwnProperty('prestationId')) {
                currentValue.libelle = currentValue.prestationLibelle;
                currentValue.valeur = false;
                currentValue.id = currentValue.prestationId;
                toReturn.push(currentValue);
            } else if (currentValue.hasOwnProperty('transportId')) {
                currentValue.libelle = currentValue.transportLibelle;
                currentValue.valeur = false;
                currentValue.id = currentValue.transportId;
                toReturn.push(currentValue);
            } else {
                //#region Gestion des groupes
                if (tmp == null) {
                    //creation du premier groupe
                    tmp = currentValue.producteurId;
                    toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
                } else {
                    if (tmp != currentValue.producteurId) {
                        //création des autres groupes
                        toReturn.push({ msGroup: false });
                        tmp = currentValue.producteurId;
                        toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
                    }
                }
                //#endregion
                currentValue.libelle = currentValue.produitCode + ' - ' + currentValue.produitLibelle;
                currentValue.valeur = false;
                currentValue.id = currentValue.produitId;
                toReturn.push(currentValue);

                if (index == elementsSelectionnablesOrdonnes.length - 1) {
                    toReturn.push({ msGroup: false });
                }
            }
            temp.push(toReturn);
        }
        const sorties = _.flatten(temp);
        return new Promise((resolve) => resolve(sorties));
    }
    getElmsLies() {
        const typeArticle = Number(this.factureArticle.typeArticle);
        switch (typeArticle) {
            case 0:
                return this.produitsLies;
                break;
            case 1:
                return this.transportsLies;
                break;
            case 2:
                return this.prestationsLiees;
                break;
        }
    }
    //#region Getters
    // async getChantiers(id) {
    //     try {
    //         this.listeChantiers = await this.FacturesService.getChantiers(id, null);
    //     } catch (err) {
    //         if (err.data) this.notification.error(err.data);
    //         else throw err
    //     }
    // }
    async getArticles() {
        return this.produitsLies;

        if (this.facture.entete.idSiteCommercial) {
            try {
                this.listeArticles = await this.ArticlesService.getArticles(this.facture.entete.idSiteCommercial, this.facture.entete.dateGeneration);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            }
        }
    }
    async getTVA() {
        if (!this.listeTVA) {
            this.startLoading();
            try {
                this.listeTVA = await this.ArticlesService.getListeTVA();
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            } finally {
                this.stopLoading();
            }
        }
    }
    async loadTypesTicket() {
        if (!this.typesTicket) {
            this.startLoading();
            try {
                this.typesTicket = await this.FacturesService.getTypeTicket();
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            } finally {
                this.stopLoading();
            }
        }
    }

    //#endregion
    setArticle(produit) {
        this.factureArticle.ticket = produit;
        this.factureArticle.codeArticle = produit.codeProduit;
        this.factureArticle.nomArticle = produit.produitLibelle;
        switch (produit.typeArticle) {
            case 2:
                //presatation
                this.factureArticle.typeArticleText = this.$translate.instant('FACTURES.PRESTATION');
                break;
            case 3:
                //produit
                this.factureArticle.typeArticleText = this.$translate.instant('FACTURES.PRODUIT');
                break;
        }
    }

    setChantier(chantier) {
        this.factureArticle.idChantier = chantier.id;
    }

    setSiteCommercial(siteCommercial) {
        this.factureArticle.idSiteCommercial = siteCommercial.id;
    }

    setLieu(lieu) {
        this.factureArticle.idLieu = lieu.id;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }

    async updateArticle() {
        this.startLoading();
        try {
            await this.FacturesService.updateArticle(this.newArticle);
            this.notification.success('FACTURES.ARTICLES.UPDATED');
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            await this.refresh();
            this.stopLoading();
        }
    }

    async createArticle(article) {
        this.startLoading();
        try {
            const val = await this.FacturesService.createArticle(article);
            this.notification.success('FACTURES.ARTICLES.CREATED');
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.factureArticle = {
                typeArticle: null,
                chantier: null,
                article: null,
                quantite: 0,
                unite: null,
                prixStandard: null,
                typePrix: 1,
                remise: null,
                ristourne: null,
                prixUnitaire: null,
                ht: null,
                idTVA: null
            };

            await this.refresh();
            this.stopLoading();
        }
    }

    async deleteArticle(bill) {
        this.startLoading();
        try {
            await this.FacturesService.deleteArticle(bill.idFactSomeThing, bill.typeArticle);
            this.notification.success('FACTURES.ARTICLES.DELETED');
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            await this.refresh();
            this.stopLoading();
        }
    }

    async refresh() {
        if (this.params.id) {
            this.startLoading();
            try {
                const fact = await this.FacturesService.getFactureById(this.params.id);
                this.facture.recapitulatifLignes = fact.recapitulatifLignes;
                this.facture.entete.factureArticles = fact.factureArticles;
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListFacture();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListFacture();
        }
    }

    async getNomChantiersByClient(valeur) {
        try {
            const res = await this.FacturesService.getNomChantiersByClient(valeur, this.facture.entete.idClient);
            return res;
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async getNomSitesCommerciaux(valeur) {
        try {
            const res = await this.FacturesService.getNomSitesCommerciaux(valeur, this.facture.entete.idSociete);
            return res;
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async getNomLieux(valeur) {
        try {
            const res = await this.FacturesService.getNomLieux(valeur);
            return res;
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async articleSelected() {
        // recherche l unite de l article
        try {
            this.factureArticle.unite = await this.FacturesService.getUniteArticle(
                this.factureArticle.article[0].id,
                this.factureArticle.typeArticle
            );
        } catch (ex) {
            this.notification.error(ex.data);
        }

        // recherche taux TVA par défault
        let code = '';
        const filtre = {
            chantierId: this.factureArticle.idChantier,
            paysId: this.facture.entete.idPays,
            date: this.facture.entete.dateGeneration,
            achatVente: 1
        };

        switch (this.factureArticle.typeArticle) {
            case '0': // produit
                code = 'tvaProduits';
                filtre.produitId = this.factureArticle.article[0].id;
                break;
            case '1': // transport
                code = 'tvaTransp';
                filtre.transportId = this.factureArticle.article[0].id;
                break;
            case '2': // prestation
                code = 'tvaPresta';
                filtre.prestationId = this.factureArticle.article[0].id;
                break;
            default:
                break;
        }

        try {
            const tempTVA = await this.FacturesService.getTVAArticle(code, filtre);

            if (tempTVA.elements.length > 0) {
                const sortedListTVA = _.sortBy(tempTVA.elements, function (tvaItem) {
                    return -tvaItem.dateApplication;
                });
                this.factureArticle.idTVA = sortedListTVA[0].tvaId;
            } else {
                this.notification.warning(this.$translate.instant('FACTURES.TVANOTFOUND'));
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }

        // recherche le tarif (Prix Standard)
        try {
            this.factureArticle.prixStandard = await this.FacturesService.getPSArticle(
                this.factureArticle.article[0].id,
                this.factureArticle.typeArticle,
                this.facture.entete.idSociete,
                this.facture.entete.idSiteCommercial,
                this.facture.entete.idClient,
                this.factureArticle.idChantier,
                this.factureArticle.article[0].producteurId
            );
        } catch (ex) {
            // si exception businessrule, mettre 0
            this.notification.warning(ex.data);
            if (ex.status == 404) {
                this.factureArticle.prixStandard = 0;
            }
        }
    }

    async convertStringToDecimal(article) {
        if (typeof article.quantite === 'string') {
            article.quantite = article.quantite.replace(',', '.').replace(' ', '');
        }
        if (typeof article.prixUnitaire === 'string') {
            article.prixUnitaire = article.prixUnitaire.replace(',', '.').replace(' ', '');
        }
        if (typeof article.remise === 'string') {
            article.remise = article.remise.replace(',', '.').replace(' ', '');
        }
        if (typeof article.ristourne === 'string') {
            article.ristourne = article.ristourne.replace(',', '.').replace(' ', '');
        }
    }
}
