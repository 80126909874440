export default function RequestRoute($stateProvider) {
    $stateProvider
        .state('portail.request', {
            url: '/request',
            abstract: true,
            template: '<ui-view></ui-view>',
            ncyBreadcrumb: {
                skip: true
            }
        })
        .state('portail.request.list', {
            url: '',
            rights: { domain: 'portail', right: 'read' },
            template: '<portail-request-list></portail-request-list>',
            ncyBreadcrumb: {
                skip: true
            }
        });
}

RequestRoute.$inject = ['$stateProvider'];
