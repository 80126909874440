import { data } from 'jquery';

export default class DecompositionController {
    constructor($state, ProduitsService, $uibModal, SyntheseEntitiesService, notification) {
        this.notification = notification;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.ProduitsService = ProduitsService;

        this.passantD = 90;
        this.allPercent = false;
        this.sliderOptions = {
            precision: 2,
            step: 1
        };
        this.display = {};
        this.debit = null;
        this.fullscreen = false;
        this.chart = this.createChartOptions();
    }

    async $onInit() {
        this.loading = true;
        if (!this.$state.params || (this.$state.params && !this.$state.params.obj)) {
            return this.$state.go('syntheseentities.list');
        }
        this.essai = this.$state.params.obj.essais[0];
        this.tamis = this.essai.resultFromGetTamisBySousMesuresMethode[0].listeTamisHeaders;
        this.selectedTamis = this.$state.params.obj.sousEssaisTamis;
        this.tamis = this.tamis.filter((thisTamisElement) =>
            this.selectedTamis.find((selectedTamisElement) => thisTamisElement.ordre === selectedTamisElement.ordre)
        );

        if (this.$state.params.obj.resultat.prelevementBlock[0]) {
            this.produit = await this.getProduit(this.$state.params.obj.resultat.prelevementBlock[0].idProduit);
            if (this.produit.tamisGrandD) {
                this.tamis = this.tamis.filter((t) => t.valeurTamis <= this.produit.tamisGrandD * 2);
            }
        }
        const valueTamisGrouped = this.formatTamis(this.$state.params.obj.resultat.prelevementBlock); //Tamis grouper par ouverture avec toute les valeurs pour chaque ouverture
        if (valueTamisGrouped == null) {
            this.notification.info('SYNTHENTS.DECOMPO.NON_TAMIS');
            return this.$state.go('syntheseentities.list');
        }
        this.getAllMoyenneTamis(this.tamis, valueTamisGrouped);
        this.formatChart();
        this.display = this.$state.params.obj.formattedResult;
        let res = (await this.SyntheseEntitiesService.GetDecompo(this.$state.params.obj.synthese.id)).data;
        if (res) {
            res = res;
            this.model = res;
            this.model.id = res.id;
            if (res.coupes) {
                for (let i = 0; i < res.coupes.length; i++) {
                    const element = res.coupes[i];
                    const t = this.tamis.find((x) => x.valeurTamis === element.tamis);
                    if (t) {
                        t.isCut = true;
                        t.passantD = element.passantD;
                        t.efficace = element.efficace;
                    }
                }
            }
            if (res.override) {
                this.chart.datasetOverride = res.override.sort((a, b) => a.lineIndex - b.lineIndex);
            }
            this.calculDecompo();
        } else {
            this.model = {
                passantD: 90,
                traitement: false,
                debit: null,
                type: 'line',
                id: -1
            };
        }
        this.loading = false;
    }

    formatTamis(listePrelevement) {
        let allTamisResultat = [];
        for (let i = 0; i < listePrelevement.length; i++) {
            const element = listePrelevement[i];
            if (!element.ecartee && element.listeTamisLignes.length != 0) {
                allTamisResultat = allTamisResultat.concat(element.listeTamisLignes);
            }
        }
        if (allTamisResultat.length != 0) {
            return allTamisResultat.groupBy((x) => x.ouvertureTamis);
        }
        return null;
    }

    getAllMoyenneTamis(tamis, value) {
        for (let i = 0; i < tamis.length; i++) {
            const element = tamis[i];
            const valeurTamis = value[`${element.valeurTamis}`];
            //exclure les elts sans valeur
            valeurTamis = valeurTamis.filter((v) => v.passant !== null && v.passant !== undefined);
            if (valeurTamis) {
                const total = valeurTamis.length;
                const sum = valeurTamis.reduce((a, b) => a + b.passant, 0);
                element.passant = Number.parseFloat(sum / total).round(2);
            }
        }
        tamis = tamis.map((x) => {
            x.passant = Number.parseFloat(x.passant);
            x.originalPassant = x.passant;
            return x;
        });
    }

    async getProduit(idProduit) {
        try {
            const res = await this.ProduitsService.getProduitEnteteById(idProduit);
            return res;
        } catch (err) {
            console.log(err);
        }
    }

    cutLine(line) {
        line.isCut = true;
        line.originalPassant = line.passant;
        line.efficace = 100;
        line.passantD = 100;
        this.onValueChange();
    }

    uncutLine(line) {
        if (line.isCut) {
            line.passant = line.originalPassant;
            line.isCut = false;
            this.onValueChange();
        }
    }

    formatChart() {
        this.chart.series = ['Moyennne'];
        this.chart.data = [this.tamis.map((x) => x.passant)];
        this.chart.labels = this.tamis.map((x) => `${x.valeurTamis}`);
    }

    onDebitChange() {
        const refs = this.tamis.filter((x) => x.isCut);
        if (this.model.debit) {
            for (let i = 0; i < refs.length; i++) {
                var ref = refs[i];
                if (ref.proportion) {
                    const res = (this.model.debit * ref.proportion) / 100;
                    ref.debit = res.round(2);
                }
            }
        } else {
            for (let i = 0; i < refs.length; i++) {
                var ref = refs[i];
                ref.debit = null;
            }
        }
    }

    calculDecompo(allP = false) {
        const refs = this.tamis.filter((x) => x.isCut);
        let before = null;
        let qTot = 100;
        const copyTamis = angular.copy(this.tamis);
        for (let i = 0; i < refs.length; i++) {
            const res = [];
            const last = [];
            let findedGoodPassant = false;
            let libTam = null;
            const ref = refs[i];
            const refPassant = copyTamis.find((x) => x.valeurTamis === ref.valeurTamis).passant;
            const coef = refPassant / ref.passantD;
            const proportion = (coef * qTot * ref.efficace) / 100;
            console.log('🚀: DecompositionController -> calculDecompo -> proportion', proportion);

            qTot = qTot - proportion;
            ref.proportion = proportion.round(2);
            for (let j = 0; j < copyTamis.length; j++) {
                const tami = copyTamis[j];
                let val = tami.passant / coef;
                if (val < 0) {
                    val = 0;
                } else if (val > 100) {
                    val = 100;
                }
                res.push(angular.copy(val).round(2));

                let val2 = (tami.passant - coef * (ref.efficace / 100) * val) / (1 - (coef * ref.efficace) / 100);
                tami.passant = val2;
                if (i === refs.length - 1) {
                    if (val2 < 0) {
                        val2 = 0;
                    } else if (val2 > 100) {
                        val2 = 100;
                    }
                    last.push(angular.copy(val2).round(2));
                }

                if (val >= this.passantD && !findedGoodPassant) {
                    findedGoodPassant = true;
                    libTam = tami.valeurTamis;
                }
            }

            this.chart.data.push(res);
            if (i === 0) {
                this.chart.series.push(`0 / ${libTam} (${proportion.round(2)} %)`);
            } else if (before && libTam) {
                this.chart.series.push(`${before} / ${libTam} (${proportion.round(2)} %)`);
            }
            if (i === refs.length - 1) {
                this.chart.data.push(last);
                this.chart.series.push(`Passant > ${libTam} (${qTot.round(2)} %)`);
            }
            before = libTam;
        }

        if (allP && 100 - qTot > 0) {
            for (let i = 0; i < refs.length; i++) {
                const ref = refs[i];
                const nProp = (ref.proportion * 100) / (100 - qTot);
                ref.proportion = nProp.round(2);
            }
        }

        this.onDebitChange();
    }

    onValueChange(allP) {
        this.formatChart();
        this.calculDecompo(allP);
    }

    createChartOptions() {
        return {
            type: 'line',
            datasetOverride: [],
            options: {
                scales: {
                    yAxes: [
                        {
                            type: 'linear',
                            display: true,
                            position: 'left',
                            ticks: {
                                max: 100,
                                min: 0,
                                step: 10
                            }
                        }
                    ]
                }
            }
        };
    }

    async onSave() {
        try {
            const tamisCut = this.tamis.filter((x) => x.isCut);
            const data = {
                ...this.model,
                id: this.model.id,
                coupes: [],
                override: []
            };
            for (let i = 0; i < tamisCut.length; i++) {
                const element = tamisCut[i];
                data.coupes.push({
                    efficace: element.efficace,
                    passantD: element.passantD,
                    tamis: element.valeurTamis
                });
            }
            for (let i = 0; i < this.chart.datasetOverride.length; i++) {
                const element = this.chart.datasetOverride[i];
                data.override.push({
                    backgroundColor: element.backgroundColor,
                    borderColor: element.borderColor,
                    borderWidth: element.borderWidth,
                    lineIndex: i,
                    lineTension: element.lineTension,
                    order: element.order,
                    pointBackgroundColor: element.pointBackgroundColor,
                    pointBorderColor: element.pointBorderColor,
                    pointBorderWidth: element.pointBorderWidth,
                    pointRadius: element.pointRadius,
                    pointRotation: element.pointRotation,
                    pointStyle: element.pointStyle,
                    showLine: element.showLine,
                    spanGaps: element.spanGaps,
                    fill: element.fill
                });
            }

            await this.SyntheseEntitiesService.CreateDecompo(this.$state.params.obj.synthese.id, data);
            this.notification.success('SYNTHENTS.DECOMPO.SAVED');
        } catch (err) {
            this.notification.error(err);
        }
    }

    openSynthese() {
        const _this = this;
        this.$uibModal
            .open({
                template:
                    '<syntheseentities-form modal-instance="$ctrl.uibModalInstance" synth-for-gene="undefined" params-synthese="$ctrl.paramsSynthese"></syntheseentities-form>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.paramsSynthese = _this.$state.params.obj.synthese;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl',
                backdrop: false
            })
            .result.then(
                async function (result) {
                    if (result) {
                        //result contient une liste (prelevementBlock)
                        //TODO: Rafraichir le résultat

                        _this.$state.go('syntheseentities.result', { obj: result });
                    }
                },
                function (reason) {}
            );
    }

    async openPrelevement(lig) {
        const _this = this;
        _this.id = lig.idPrelevement;
        _this.idFamille = lig.idFamille;
        _this.codeDomaine = lig.codeDomaine;
        this.$uibModal
            .open({
                template:
                    '<prelevement modal-instance="$ctrl.uibModalInstance" id="$ctrl.id" id-famille="$ctrl.idFamille" code-domaine="$ctrl.codeDomaine"></prelevement>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        //$ctrl.objSynthese = _this.objSynthese;
                        $ctrl.uibModalInstance = $uibModalInstance;
                        $ctrl.id = _this.id;
                        $ctrl.idFamille = _this.idFamille;
                        $ctrl.codeDomaine = _this.codeDomaine;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl',
                backdrop: false
            })
            .result.then(
                async function (result) {
                    if (result) {
                        //await _this.regenererSynthese();
                        const res = await _this.SyntheseEntitiesService.genererSynthese(_this.model.synthese, 'laboratoire');
                        _this.model.resultat = res;
                        _this.$state.go('syntheseentities.result', { obj: _this.model });
                    }
                },
                function (reason) {}
            );
    }

    async removePrelevement(lig) {
        lig.ecartee = true;
        await this.relanceTraitementsStat();
        await this.lancerTraitement(); //ne relance pas les traits stats
        //this.$state.go('syntheseentities.result', { obj: this.$state.params.obj });//relance toute la stat : ignore l'écartement
    }

    $onDestroy() {}
}

DecompositionController.$inject = ['$state', 'ProduitsService', '$uibModal', 'SyntheseEntitiesService', 'notification'];
