export const typeSynth = {
    ftp: 1,
    recompo: 2,
    bilanConformite: 4,
    synthPlv: 5,
    synthSaisie: 6,
    consistance: 7,
    resistance: 8,
    registre: 9,
    controleAcquereur: 10,
    decompo: 12,
    carteDeControle: 13,
    controleEnrobes: 18
};

export const statsList = [
    {
        stat: '@VTYPE',
        typeSynth: [typeSynth.recompo, typeSynth.consistance, typeSynth.resistance, typeSynth.controleAcquereur]
    },
    {
        stat: '@VSI',
        typeSynth: [typeSynth.recompo, typeSynth.consistance, typeSynth.resistance, typeSynth.controleAcquereur]
    },
    {
        stat: '@VSS',
        typeSynth: [typeSynth.recompo, typeSynth.consistance, typeSynth.resistance, typeSynth.controleAcquereur]
    },
    {
        stat: '@MOY',
        typeSynth: [typeSynth.ftp]
    },
    {
        stat: '@ECT',
        typeSynth: [typeSynth.ftp]
    }
];
