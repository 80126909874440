import SyntheseEntitiesSelectionController from './syntheseentities.selection.controller';

export default {
    bindings: {
        syntheseEntity: '=',
        forGeneration: '=',
        ongletOpen: '='
    },
    template: require('./syntheseentities.selection.html'),
    controller: SyntheseEntitiesSelectionController
};
