export default class VehiculeDetailController {
    static $inject = [
        '$stateParams',
        '$state',
        'VehiculesService',
        'notification',
        'VehiculesCommunicationService',
        'globalizationManagementService'
    ];

    constructor($stateParams, $state, VehiculesService, notification, VehiculesCommunicationService, globalizationManagementService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.VehiculesService = VehiculesService;
        this.notification = notification;
        this.VehiculesCommunicationService = VehiculesCommunicationService;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
        this.displayDateMask = globalizationManagementService.getCurrentLanguage().displayDateMask;
    }

    $onInit() {
        this.loading = false;
        this.vehicule = {};
        this.vehiculeId = undefined;
        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.initVehicule();
    }

    async initVehicule() {
        this.vehiculeId = this.$stateParams.id;

        this.startLoading();
        try {
            await this.getVehiculeEntete(this.vehiculeId);
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('vehicules.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getVehiculeEntete(vehiculeId) {
        this.vehicule.entete = await this.VehiculesService.getVehiculeEnteteById(vehiculeId);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
