import ImportNormeModalController from './import.norme.modal.controller';

const moduleName = 'app.massia.importnormereference';

export default {
    template: require('./import.norme.modal.html'),
    controller: ImportNormeModalController,
    bindings: {
        modalInstance: '=',
        idProduit: '=',
        idNorme: '='
    }
};
