import { NgModule } from '../decorators';
import { HierarchicalLevelService } from './hierarchical-level.service';
import { UniteConversionService } from './unite-conversion.service';
import { UserService } from './user.service';
import { ProductService } from './product.service';
import { SiteService } from './site.service';
import { TypeService } from './type.service';
import { CaracteristiqueService } from './caracteristique.service';
import { GammeService } from './gamme.service';
import { SerieTamisService } from './serie-tamis.service';
import { BetConstituantService } from './bet-constituant';
import { FamilleService } from './famille';
import { SeuilBetGachService } from './seuil-bet-gach';
import { FilterService } from './filter.service';

export const httpModule = 'app.core.http';

@NgModule({
    id: httpModule,
    providers: [
        UniteConversionService,
        UserService,
        HierarchicalLevelService,
        ProductService,
        SiteService,
        TypeService,
        CaracteristiqueService,
        GammeService,
        SerieTamisService,
        BetConstituantService,
        FamilleService,
        SeuilBetGachService,
        FilterService
    ]
})
export class HttpServiceModule {}
