import ElementsLiesController from './elements.lies.controller';

export default {
    bindings: {
        domaine: '<',
        frontFilters: '=',
        filterToBack: '=',
        parametre: '=',
        listes: '=',
        libelles: '=',
        source: '<',
        domaine: '<',
        filtresAppliques: '<',
        onUpdate: '&',
        onDelete: '&',
        dateApplication: '='
    },
    template: require('./elements.lies.html'),
    controller: ElementsLiesController
};
