import FormuleComposant from '../formule.composant.model';

export default class FormuleGestionCreationController {
	/* @ngInject */
	constructor(
		$scope,
		_,
		$state,
		$stateParams,
		FormulesCommunicationService,
		FormuleService,
		ModalService,
		notification,
		SitesService,
		$filter,
		$uibModal,
		$translate,
		moment,
		MassiaApplicationService
	) {
		this.$scope = $scope;
		this._ = _;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.params = $stateParams;
		this.FormulesCommunicationService = FormulesCommunicationService;
		this.FormuleService = FormuleService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.SitesService = SitesService;
		this.$filter = $filter;
		this.$uibModal = $uibModal;
		this.$translate = $translate;
		this.moment = moment;
		this.MassiaService = MassiaApplicationService;
	}

	$onInit() {
		this.IdProduit = this.params.produitId;
		this.IdProducteur = this.params.producteurId;
		const _this = this;
		this.$scope.$watch('$ctrl.formule.datePrixCession', function (newValue, oldValue) {
			_this.datePrixCessionChange();
		});
		this.typeComposant = '1';
		this.sortableOptions();
		this.getElementsSelectionnables();
		this.rechercheDesPrix();
	}

	$onDestroy() {
		// this.unregisterReinit();
	}

	async getElementsSelectionnables() {
		try {
			const typeComposant = Number(this.typeComposant);
			switch (typeComposant) {
				case 0:
					if (typeof this.prestations === 'undefined') {
						this.allPrestations = await this.FormuleService.getAllPrestations();
						this.prestations = await this.prepareListeElementsLies(this.allPrestations);
					}
					this.liste = this.prestations;
					break;
				case 1:
					this.allProduits = await this.FormuleService.getAllProduits(this.formules.producteurId, this.formules.produitId, 'gestion');
					const idProducteur = this.params.producteurId;
					const idProduit = this.IdProduit;
					const indexProduit = this.allProduits.findIndex((p) => p.produitId == idProduit && p.producteurId == idProducteur);
					this.allProduits.splice(indexProduit, 1);
					this.produits = await this.prepareListeElementsLies(this.allProduits);
					this.liste = this.produits;
					const granulat = this.formule.composants.filter((x) => x.codeFamille === 'GRAN' || x.familleCode === 'GRAN');
					if (this.formule.squelette && this.formule.squelette.composants && this.app === 'laboratoire') {
						this.removeGranForSelect();
					}
					break;
				case 2:
					this.liste = [];
					break;
			}
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}
	async prepareListeElementsLies(elementsATraiter) {
		const elementsSelectionnablesOrdonnes = this.$filter('orderBy')(elementsATraiter, 'producteurLibelle');
		let tmp = null;
		const temp = [];
		for (let index = 0; index < elementsSelectionnablesOrdonnes.length; index++) {
			const currentValue = elementsSelectionnablesOrdonnes[index];
			const toReturn = [];

			if (currentValue.hasOwnProperty('modeDeclenchement')) {
				currentValue.idComposant = currentValue.id;
				toReturn.push(currentValue);
			} else {
				//#region Gestion des groupes
				if (tmp == null) {
					//creation du premier groupe
					tmp = currentValue.producteurId;
					toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
				} else {
					if (tmp != currentValue.producteurId) {
						//création des autres groupes
						toReturn.push({ msGroup: false });
						tmp = currentValue.producteurId;
						toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
					}
				}
				//#endregion

				currentValue.libelle = currentValue.produitCode + ' - ' + currentValue.produitLibelle;
				currentValue.valeur = false;
				currentValue.idComposant = currentValue.produitId;
				currentValue.code = currentValue.produitCode;
				currentValue.IdProducteur = currentValue.producteurId;
				currentValue.libelleFamille = currentValue.familleLibelle;

				if (!(currentValue.produitId == this.formule.idProduit && currentValue.producteurId == this.formule.idProducteur)) {
					toReturn.push(currentValue);
				}

				if (index == elementsSelectionnablesOrdonnes.length - 1) {
					toReturn.push({ msGroup: false });
				}
			}
			temp.push(toReturn);
		}
		const sorties = _.flatten(temp);
		return new Promise((resolve) => resolve(sorties));
	}

	async deleteSingleComposant(obj) {
		let index = null;
		let skull = false;
		for (let i = 0; i < this.formule.composants.length; i++) {
			const composant = this.formule.composants[i];
			if (obj.isInSquelette && composant.isInSquelette) {
				composant.isInSquelette = false;
				skull = true;
			}
			if (obj.ordre === composant.ordre) {
				index = i;
			}
		}
		if (typeof obj.id !== 'undefined' && obj.id !== null) {
			this.notification.warning(this.$translate.instant('FORMULES.MESSAGES.BODY.DOIT_ARCHIVER'));
		}
		if (skull) {
			this.formule.squelette = null;
		}
		this.formule.composants.splice(index, 1);
		const granulat = this.formule.composants.filter((x) => x.codeFamille === 'GRAN' || x.familleCode === 'GRAN');
		if (granulat.length === 0 || skull) {
			await this.getElementsSelectionnables();
		}
		this.calculTotal();
	}

	async getPrixComposant(CompoProducteurId, ComposantId, ComposantProducteurId, TypeComposant) {
		try {
			var prix = undefined;
			if (TypeComposant == 0) {
				prix = await this.FormuleService.getPrixAchatPrestation(CompoProducteurId, ComposantId);
			} else {
				prix = await this.FormuleService.getPrixAchatProduit(CompoProducteurId, ComposantId, ComposantProducteurId);
			}
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			return prix;
		}
	}

	async getPourcentagePerte(CompoProducteurId, ComposantId, ComposantProducteurId, TypeComposant) {
		try {
			var pourcPerte = await this.FormuleService.getPourcentagePerte(CompoProducteurId, ComposantId, ComposantProducteurId);
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			return pourcPerte;
		}
	}

	// INFOS  addComposant n'enregistre pas car la composition est nouvelle, donc l'archivage de la précédente composition sera nécessaire
	async addComposant() {
		//#LABO
		this.isSqueletteGranulaire = false;
		this.isGranulatSelected = false;
		if (this.composant[0].familleCode === 'GRAN' && this.app === 'laboratoire') {
			if (this.tempSkull) {
				this.isSqueletteGranulaire = true;
			} else {
				this.isGranulatSelected = true;
			}
		}
		//#ENDLABO

		if (this.composantPasDejaDansFormule()) {
			this.lastOrdre = Number(this.lastOrdre) + 1;
			let composant = undefined;
			const prix = await this.getPrixComposant(
				this.formule.idProducteur,
				this.composant[0].idComposant,
				this.composant[0].producteurId,
				this.typeComposant
			);
			if (this.typeComposant == 0) {
				composant = new FormuleComposant({
					idComposant: this.composant[0].idComposant,
					ordre: this.lastOrdre,
					idProducteur: null,
					libelleProducteur: '',
					code: this.composant[0].code,
					codeFamille: this.composant[0].codeFamille,
					libelle: this.composant[0].libelle,
					unite: '',
					quantite: null, //this.quantite,
					libelleFamille: 'Prestation',
					type: this.typeComposant,
					coutIncorporation: prix ? prix.prix : 0,
					pourcentagePerte: '',
					prixCession: prix ? prix.prix : 0,
					editCompo: false,
					idElement: this.composant[0].idComposant,
					unitePesee: this.composant[0].unitePesee
				});
			} else {
				if (this.app === 'laboratoire' && this.isSqueletteGranulaire) {
					this.formule.squelette = this.tempSkull;
				}
				if (!this.formule.squelette) {
					composant = new FormuleComposant({
						idComposant: this.composant[0].idComposant,
						ordre: this.lastOrdre,
						idProducteur: this.composant[0].producteurId || null,
						libelleProducteur: this.composant[0].producteurLibelle,
						code: this.composant[0].code,
						codeFamille: this.composant[0].codeFamille,
						libelle: this.composant[0].libelle.substring(this.composant[0].code.length + 3),
						unite: '', //'%',
						quantite: this.quantite || 0,
						libelleFamille: this.composant[0].libelleFamille,
						type: this.typeComposant,
						//coutIncorporation: coutIncorporation,
						//pourcentagePerte: perte,
						//prixCession: prixCession,
						editCompo: false,
						idElement: this.composant[0].idComposant,
						unitePesee: this.composant[0].unitePesee
					});
					this.calculePrixComposant(composant, prix);
				}
			}
			if (typeof this.formule.composants === 'undefined') {
				this.formule.composants = [];
			}
			if (
				this.formule.squelette &&
				this.formule.squelette.composants &&
				Array.isArray(this.formule.squelette.composants) &&
				this.isSqueletteGranulaire &&
				this.formule.squelette.composants.length > 0
			) {
				this.squelette = composant;
				for (let i = 0; i < this.formule.squelette.composants.length; i++) {
					const compo = this.formule.squelette.composants[i];
					const numberForQuantity = (100 - Number.parseInt(this.formule.pourcentageTotal, 10)) / 100;
					const obj = new FormuleComposant({
						idComposant: compo.idElement,
						ordre: this.lastOrdre,
						idProducteur: compo.idProducteur || null,
						libelleProducteur: compo.libelleProducteur,
						code: compo.code,
						codeFamille: compo.codeFamille,
						libelle: compo.libelle.substring(compo.code.length + 3),
						unite: '', //'%',
						quantite: compo.quantite * numberForQuantity,
						libelleFamille: compo.libelleFamille,
						type: compo.typeElement,
						editCompo: false,
						idElement: compo.idComposant,
						isInSquelette: true,
						unitePesee: this.composant[0].unitePesee
					});
					this.formule.composants.push(angular.copy(obj));
					this.lastOrdre++;
				}
			} else {
				this.formule.composants.push(angular.copy(composant));
			}
			this.calculTotal();
			this.typeComposant = 1;
			this.composant = '';
			this.quantite = '';
			this.liste.map((x) => {
				x.valeur = false;
			});
			this.maxOrdre = angular.copy(this.lastOrdre);
			this.directSave = false;
			if (this.isSqueletteGranulaire) {
				this.removeGranForSelect();
			} else if (this.isGranulatSelected) {
				await this.removeSkulOfSelected();
			}
		} else {
			this.notification.error(this.$translate.instant('FORMULES.MESSAGES.BODY.COMPOSANT_DEJA_PRESENT'));
		}
	}

	removeGranForSelect() {
		for (let i = 0; i < this.liste.length; i++) {
			const compo = this.liste[i];
			if (compo.familleCode === 'GRAN') {
				this.liste.splice(i, 1);
				i--;
			}
		}
	}

	async removeSkulOfSelected() {
		for (let i = 0; i < this.liste.length; i++) {
			const compo = this.liste[i];
			if ((compo.familleCode === 'GRAN' || compo.codeFamille === 'GRAN') && compo.isCompose) {
				const tmp = await this.FormuleService.getFormuleBySquelette(compo.produitId, compo.producteurId);
				if (tmp) {
					this.liste.splice(i, 1);
					i--;
				}
			}
		}
	}

	async calculePrixComposant(composant, prix) {
		// ATTENTION  INFOS  Le prix de cession est égal au prix d’incorporation * pourcentage du composant * (1 + pourcentage de perte du composant).
		// try {
		//     var grilleStock = this.FormuleService.getGrilleStock(this.formules.producteurId, this.composant[0].idComposant, this.composant[0].producteurId);
		// } catch (err) {
		//     if (err.data) this.notification.error(err.data);
		//     else throw err
		// }
		// ATTENTION  INFOS  Le coût d’incorporation correspond au prix d’achat du produit composant + une ristourne sur matière première
		const coutIncorporation = prix ? (typeof prix.ristourne !== 'undefined' ? prix.prix + prix.ristourne : prix.prix) : 0;
		let perte = await this.getPourcentagePerte(this.formule.idProducteur, composant.idComposant, composant.idProducteur, composant.type);
		if (!perte) {
			perte = 0;
		}
		const prixCession = coutIncorporation * (composant.quantite / 100) * (1 + perte / 100);
		// ATTENTION  INFOS  Le prix de cession est égal au prix d’incorporation * pourcentage du composant * (1 + pourcentage de perte du composant).
		// var prixCession = coutIncorporation * this.quantite * (1 + grilleStock.<<perte>>);
		composant.coutIncorporation = coutIncorporation;
		composant.pourcentagePerte = perte;
		composant.prixCession = prixCession;
	}

	composantPasDejaDansFormule() {
		if (this.formule.composants) {
			for (let i = 0; i < this.formule.composants.length; i++) {
				const composant = this.formule.composants[i];
				if (this.typeComposant == composant.type && this.composant[0].idComposant == composant.idComposant) {
					if (composant.type == 0) {
						return false;
					} else if (this.composant[0].producteurId == composant.idProducteur) {
						return false;
					}
				}
			}
		}
		return true;
	}

	async valideComposant(elm) {
		if (elm.isInSquelette) {
			this.modalInstance = this.$uibModal.open({
				animation: true,
				ariaLabelledBy: 'modal-validation',
				ariaDescribedBy: 'modal-body-validation',
				templateUrl: 'validModal.html',
				size: 'md',
				controller: function ($scope, $uibModalInstance) {
					$scope.ok = function () {
						$uibModalInstance.close();
					};

					$scope.cancel = function () {
						$uibModalInstance.dismiss('cancel');
					};
				}
			});

			try {
				const res = await this.modalInstance.result;
				for (let i = 0; i < this.formule.composants.length; i++) {
					const compo = this.formule.composants[i];
					if (compo.isInSquelette) {
						compo.isInSquelette = false;
					}
				}
				this.formule.squelette = null;
				elm.poids = this.tmpPoids;
				this.getElementsSelectionnables();
			} catch (err) {
				//Modal closed
			}
		} else {
			elm.poids = this.tmpPoids;
		}

		const prix = await this.getPrixComposant(this.formule.idProducteur, elm.idComposant, elm.idProducteur, elm.type);
		this.calculePrixComposant(elm, prix);
		this.calculTotal();
		if (this.directSave == true) {
			this.updateSingleComposant(elm);
		}
		elm.editCompo = false;
		this.tmpQuantite = '';
	}

	annuleComposant(elm) {
		elm.editCompo = false;
		elm.quantite = this.tmpQuantite;
	}

	editComposant(elm) {
		this.tmpQuantite = elm.quantite;
		this.tmpPoids = elm.poids;
		elm.editCompo = true;
	}

	calculTotal() {
		let totalPourcentage = 0;
		let totalPrixCession = 0;
		this.formule.composants.forEach(function (element) {
			totalPourcentage = totalPourcentage + element.quantite;
			totalPrixCession = totalPrixCession + element.prixCession;
		}, this);
		this.formule.pourcentageTotal = totalPourcentage.toFixed(4);
		this.formule.prixCessionTotal = totalPrixCession;
	}

	async checkCodeUnicity(code) {
		if (!code) {
			this.formule.enCours.codeExists = null;
		} else {
			this.startCodeLoading();
			try {
				if (code.match(/^[a-zA-Z0-9_|]*$/)) {
					this.enCours.codeExists = await this.FormuleService.codeExists(code);
				}
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				this.stopCodeLoading();
			}
		}
	}

	async updateSingleComposant(composant) {
		if (this.formule.dateCreation == this.oldDateCreation) {
			try {
				const update = await this.FormuleService.updateComposant(this.formule.idProduitCompose, this.formule.idComposition, composant);
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}

	async datePrixCessionChange() {
		for (let i = 0; i < this.formule.composants.length; i++) {
			await this.rechercheDesPrix(this.formule.composants[i]);
		}

		this.calculTotal();
	}

	sortableOptions() {
		this.dragOptions = {
			placeholder: 'table-row-ui-placeholder',
			revert: true,
			animation: 200,
			axis: 'y',
			'ui-preserve-size': true
		};
	}
}
