import * as angular from 'angular';

let unregisterReinit;

export default class FactureArticlesBlockController {
	/* @ngInject */

	constructor(
		$scope,
		$stateParams,
		$state,
		$translate,
		notification,
		FacturesCommunicationService,
		FacturesService,
		ArticlesService,
		$filter,
		moment,
		CommandesService,
		_,
		globalizationManagementService
	) {
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.$state = $state;
		this.params = $stateParams;
		this.$translate = $translate;
		this.FacturesCommunicationService = FacturesCommunicationService;
		this.FacturesService = FacturesService;
		this.ArticlesService = ArticlesService;
		this.$filter = $filter;
		this.moment = moment;
		this.CommandesService = CommandesService;
		this._ = _;
		this.notification = notification;
		this.loading = false;
		this.codeLoading = false;
		this.isEditMode = false;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async $onInit() {
		this.dateEcheanceFormule = null;
		this.messageFormule = 'Masquer produits avec formules expirées';
		unregisterReinit = this.FacturesCommunicationService.registerReinit(() => this.initForm());
		this.popoverTypePrix = {
			templateUrl: 'popoverTypePrix.html'
		};
		this.objectToUpdate = {};
		// this.factureArticle = new ArticleBlock();

		this.loadTypesTicket();
		this.getTVA();
		this.getArticles();
		this.articlesDisplayed = 50;
		this.tmp = false;

		this.$scope.$watch('$ctrl.isOpen', (newValue, oldValue) => {
			if (newValue) {
				this.domReady(this.WhenDomLoaded());
				this.tmp = true;
			}
		});
	}
	domReady = (callBack) => {
		document.addEventListener('DOMContentLoaded', callBack);
		if (
			(document.readyState === 'interactive' || document.readyState === 'complete') &&
			document.visibilityState === 'visible' &&
			document.visibilityState !== 'prerender'
		) {
			if (this.block?.factureArticles?.length > this.articlesDisplayed) {
				callBack();
			}
		}
		this.tmp = true;
	};

	WhenDomLoaded = () => {
		this.articlesDisplayed += 50;
		this.domReady(() => this.WhenDomLoaded());
	};

	$onDestroy() {
		unregisterReinit();
	}

	newArticleFormVisible() {
		if (!this.isEditMode && this.facture.entete.isModifiable && this.facture.entete && this.facture.entete.idSociete) {
			return true;
		}
		return false;
	}

	/* récupérer le lien cliqué */
	initUpdate(index, bill, typeArticle) {
		this.newArticle = angular.copy(bill);
		// quand on affiche la pop-up, on revient en positif pour le l'utilisateur ne soit pas perdu
		if (this.facture.entete.typeFacture == 'Avoir Manuel sur Quantité') {
			this.newArticle.quantite = Math.abs(this.newArticle.quantite);
		}

		if (this.facture.entete.typeFacture == 'Avoir Manuel Financier') {
			this.newArticle.prixUnitaire = Math.abs(this.newArticle.prixUnitaire);
		}

		if (typeArticle === 'product') {
			this.objectToUpdate = this.facture.factureProduits;
		} else if (typeArticle === 'prestation') {
			this.objectToUpdate = this.facture.facturePrestations;
		}

		if (this.objectToUpdate) {
			this.realIndexInArray = this.objectToUpdate.indexOf(bill);
		}

		/* Sert pour la fermeture de la popover
        a donc besoin du faux index */
		this.clickedPopoverIndex = index;
	}

	/* calculs */
	async calculate() {
		await this.convertStringToDecimal(this.factureArticle);
		if (typeof this.factureArticle.typePrix !== 'undefined') {
			switch (this.factureArticle.typePrix) {
				case 0: // Aucune remise
					this.factureArticle.ht = 0;
					this.factureArticle.prixUnitaire = 0;
					this.factureArticle.valeur = 0;
					break;
				case 1: // "libelle": "Négocié"
					this.factureArticle.prixUnitaire = this.factureArticle.valeur;
					break;
				case 2: // "libelle": "Remise"
					if (this.factureArticle.prixStandard != null) {
						//this.factureArticle.prixUnitaire = this.factureArticle.prixStandard * (1 - (this.factureArticle.valeur / 100));
						this.factureArticle.prixUnitaire = (this.factureArticle.prixStandard * (100 - this.factureArticle.valeur)) / 100;
					}
					break;
				case 3: // "libelle": "Ristourne"
					if (this.factureArticle.prixStandard != null) {
						this.factureArticle.prixUnitaire = this.factureArticle.prixStandard - this.factureArticle.valeur;
					}
					break;
				case 4: // "libelle": "Marge %"
					if (this.factureArticle.prixStandard != null) {
						this.factureArticle.prixUnitaire = this.factureArticle.prixStandard * (1 + this.factureArticle.valeur / 100);
					}
					break;
				case 5: // "libelle": "Marge"
					if (this.factureArticle.prixStandard != null) {
						this.factureArticle.prixUnitaire = this.factureArticle.prixStandard + this.factureArticle.valeur;
					}
					break;
			}
		}

		this.factureArticle.prixUnitaire = Math.round((this.factureArticle.prixUnitaire + Number.EPSILON) * 100) / 100;
		if (typeof this.factureArticle.quantite !== 'undefined') {
			this.factureArticle.ht = this.factureArticle.prixUnitaire * this.factureArticle.quantite;
		}
	}

	calcul() {
		this.calculate(this.new);
	}

	addArticle() {
		let valeur = String(this.factureArticle.valeur);
		valeur = valeur.replace(',', '.');
		this.factureArticle.valeur = Number(valeur);

		const newArticle = {
			idFacture: this.params.id,
			idArticle: this.factureArticle.article[0].id,
			idSite: this.factureArticle.article[0].producteurId,
			idTicketProduit: null,
			idLieu: this.factureArticle.idLieu,
			idTicket: null,
			codeArticle: this.factureArticle.article[0].produitCode,
			nomArticle: this.factureArticle.article[0].produitLibelle,
			quantite: this.factureArticle.quantite,
			unite: this.factureArticle.unite.id,
			prixStandard: this.factureArticle.prixStandard,
			valeur: this.factureArticle.valeur,
			prixUnitaire: this.factureArticle.prixUnitaire,
			prixUnitaireFranco: this.factureArticle.prixUnitaireFranco,
			ht: this.factureArticle.ht,
			idTVA: this.factureArticle.idTVA,
			typeArticle: this.factureArticle.typeArticle,
			typePrix: this.factureArticle.typePrix,
			isFrancoForFactById: this.facture.entete.isFranco,
			isTransporteForFactById: false,
			isVisible: true,
			idChantier: this.block.idChantier,
			idSiteCommercial: this.block.idSiteCommercial
		};

		if (newArticle.idTVA != null) {
			this.createArticle(newArticle);
		} else {
			this.notification.error(this.$translate.instant('FACTURES.NOTVA'));
		}
	}

	showTypePrixfields() {
		if (this.factureArticle && typeof this.factureArticle.typePrix !== 'undefined') {
			return true;
		}
		return false;
	}

	async changeProduitformule() {
		if (this.dateEcheanceFormule == null) {
			this.dateEcheanceFormule = this.facture.entete.dateFin ? this.facture.entete.dateFin : this.facture.entete.dateGeneration;
			this.messageFormule = 'Afficher produits avec formules expirées';
		} else {
			this.dateEcheanceFormule = null;
			this.messageFormule = 'Masquer produits avec formules expirées';
		}

		await this.getElementsSelectionnables();
	}

	async getElementsSelectionnables() {
		this.factureArticle.unite = {};
		const filtre = {
			societeId: this.facture.entete.idSociete,
			clientId: this.facture.entete.idClient,
			entreeSortie: null,
			achatVente: 1,
			date: this.moment(this.facture.entete.dateGeneration, this.dateFormat).format(this.dateFormat)
		};
		// SL les services attendent soit un élément avec une valeur, soit d'élément du tout
		if (this.block.idSiteCommercial) {
			filtre.siteCommercialId = this.block.idSiteCommercial;
		}

		if (this.block.idChantier) {
			filtre.chantierId = this.block.idChantier;
		}

		try {
			const typeArticle = Number(this.factureArticle.typeArticle);
			switch (typeArticle) {
				case 0:
					// filtre.date = this.dateEcheanceFormule;
					this.produitsSelectionnables = await this.FacturesService.getProduitsSelectionnables(filtre);
					this.produitsLies = await this.prepareListeElementsLies(this.produitsSelectionnables);
					break;
				case 1:
					this.transportsSelectionnables = await this.FacturesService.getTransportsSelectionnables(filtre);
					this.transportsLies = await this.prepareListeElementsLies(this.transportsSelectionnables);
					break;
				case 2:
					this.prestationsSelectionnables = await this.FacturesService.getPrestationsSelectionnables(filtre);
					this.prestationsLiees = await this.prepareListeElementsLies(this.prestationsSelectionnables);
					break;
			}
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}

	prepareListeElementsLies(elementsATraiter) {
		const elementsSelectionnablesOrdonnes = this.$filter('orderBy')(elementsATraiter, 'producteurLibelle');
		let tmp = null;
		const temp = [];
		for (let index = 0; index < elementsSelectionnablesOrdonnes.length; index++) {
			const currentValue = elementsSelectionnablesOrdonnes[index];
			const toReturn = [];
			if (currentValue.hasOwnProperty('prestationId')) {
				currentValue.libelle = currentValue.prestationLibelle;
				currentValue.valeur = false;
				currentValue.id = currentValue.prestationId;
				toReturn.push(currentValue);
			} else if (currentValue.hasOwnProperty('transportId')) {
				currentValue.libelle = currentValue.transportLibelle;
				currentValue.valeur = false;
				currentValue.id = currentValue.transportId;
				toReturn.push(currentValue);
			} else {
				//#region Gestion des groupes
				if (tmp == null) {
					//creation du premier groupe
					tmp = currentValue.producteurId;
					toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
				} else {
					if (tmp != currentValue.producteurId) {
						//création des autres groupes
						toReturn.push({ msGroup: false });
						tmp = currentValue.producteurId;
						toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
					}
				}
				//#endregion
				currentValue.libelle = currentValue.produitCode + ' - ' + currentValue.produitLibelle;
				currentValue.valeur = false;
				currentValue.id = currentValue.produitId;
				toReturn.push(currentValue);

				if (index == elementsSelectionnablesOrdonnes.length - 1) {
					toReturn.push({ msGroup: false });
				}
			}
			temp.push(toReturn);
		}
		const sorties = _.flatten(temp);
		return new Promise((resolve) => resolve(sorties));
	}

	getElmsLies() {
		if (this.factureArticle) {
			const typeArticle = Number(this.factureArticle.typeArticle);
			switch (typeArticle) {
				case 0:
					return this.produitsLies;
				case 1:
					return this.transportsLies;
				case 2:
					return this.prestationsLiees;
			}
		}
	}

	async getArticles() {
		return this.produitsLies;
	}

	async getTVA() {
		if (!this.listeTVA) {
			this.startLoading();
			try {
				this.listeTVA = await this.ArticlesService.getListeTVA();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.stopLoading();
			}
		}
	}

	async loadTypesTicket() {
		if (!this.typesTicket) {
			this.startLoading();
			try {
				this.typesTicket = await this.FacturesService.getTypeTicket();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.stopLoading();
			}
		}
	}

	//#endregion
	setArticle(produit) {
		this.factureArticle.ticket = produit;
		this.factureArticle.codeArticle = produit.codeProduit;
		this.factureArticle.nomArticle = produit.produitLibelle;
		switch (produit.typeArticle) {
			case 2:
				//presatation
				this.factureArticle.typeArticleText = this.$translate.instant('FACTURES.PRESTATION');
				break;
			case 3:
				//produit
				this.factureArticle.typeArticleText = this.$translate.instant('FACTURES.PRODUIT');
				break;
		}
	}

	setChantier(chantier) {
		this.factureArticle.idChantier = chantier.id;
	}

	setLieu(lieu) {
		this.factureArticle.idLieu = lieu.id;
		this.articleSelected();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}

	async updateArticle() {
		this.startLoading();
		try {
			await this.FacturesService.updateArticle(this.newArticle);
			this.notification.success('FACTURES.ARTICLES.UPDATED');
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			await this.refresh();
			this.stopLoading();
		}
	}

	async createArticle(article) {
		if (this.isDuplicate) {
			article.idProducteur = article.idSite;
			this.block.factureArticles.push(article);
			this.factureArticle = {
				typeArticle: null,
				chantier: null,
				article: null,
				quantite: 0,
				unite: null,
				prixStandard: null,
				typePrix: 1,
				valeur: null,
				prixUnitaire: null,
				ht: null,
				idTVA: null
			};
		} else {
			this.startLoading();
			try {
				console.log("🚀 ~ FactureArticlesBlockController ~ createArticle ~ article:", article)
				const val = await this.FacturesService.createArticle(article);
				this.notification.success('FACTURES.ARTICLES.CREATED');
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				this.factureArticle = {
					typeArticle: null,
					chantier: null,
					article: null,
					quantite: 0,
					unite: null,
					prixStandard: null,
					typePrix: 1,
					valeur: null,
					prixUnitaire: null,
					ht: null,
					idTVA: null
				};

				await this.refresh();
				this.stopLoading();
			}
		}
	}

	async deleteArticle(bill) {
		if (this.isDuplicate) {
			const index = this._.findIndex(this.block.factureArticles, bill);
			this.block.factureArticles.splice(index, 1);
		} else {
			this.startLoading();
			try {
				await this.FacturesService.deleteArticle(bill.idFactSomeThing, bill.typeArticle);
				this.notification.success('FACTURES.ARTICLES.DELETED');
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				await this.refresh();
				this.stopLoading();
			}
		}
	}

	async reverseQuantite(bill) {
		this.startLoading();
		try {
			await this.FacturesService.reverseQuantite(bill.idFactSomeThing, bill.typeArticle);
			this.notification.success('FACTURES.ARTICLES.REVERSE_QUANTITE');
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			await this.refresh();
			this.stopLoading();
		}
	}

	async refresh() {
		if (this.params.id) {
			this.startLoading();
			try {
				const fact = await this.FacturesService.getFactureById(this.params.id);
				this.facture.recapitulatifLignes = fact.recapitulatifLignes;
				this.facture.entete.factureArticles = fact.factureArticles;
				this.facture.entete.listeArticlesByBlock = fact.listeArticlesByBlock;
			} catch (ex) {
				if (ex.status === 404) {
					this.goListFacture();
				}
				this.notification.error(ex.data);
			} finally {
				this.stopLoading();
			}
		} else {
			this.goListFacture();
		}
	}

	async getNomChantiersByClient(valeur) {
		try {
			const res = await this.FacturesService.getNomChantiersByClient(valeur, this.facture.entete.idClient);
			return res;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async getNomLieux(valeur) {
		try {
			const res = await this.FacturesService.getNomLieux(valeur);
			return res;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async articleSelected() {
		// recherche l unite de l article
		if (this.factureArticle.article.length > 0) {
			try {
				this.factureArticle.unite = await this.FacturesService.getUniteArticle(
					this.factureArticle.article[0].id,
					this.factureArticle.typeArticle
				);
			} catch (ex) {
				this.notification.error(ex.data);
				this.factureArticle.idTVA = 1;
			}

			// recherche de la zone
			let idZone = null;
			if (this.factureArticle.article[0].prixZone && this.factureArticle.article[0].prixZone == true) {
				let tempLieuId = null;
				if (this.factureArticle.lieu) {
					tempLieuId = this.factureArticle.lieu.id || null;
				}

				const tempZone = await this.CommandesService.getZoneForTransport(
					this.block.idSiteCommercial,
					this.block.idChantier,
					tempLieuId,
					this.factureArticle.article[0].idGrilleZoneKm
				);

				this.factureArticle.libelleZone = tempZone.libelleZone;
				idZone = tempZone.idZone;
			}

			// recherche le tarif (Prix Standard)
			let prixArticle = null;
			try {
				prixArticle = await this.FacturesService.getPSArticle(
					this.factureArticle.article[0].id,
					this.factureArticle.typeArticle,
					this.facture.entete.idSociete,
					this.block.idSiteCommercial,
					this.facture.entete.idClient,
					this.block.idChantier,
					this.factureArticle.article[0].producteurId,
					null,
					idZone
				);
			} catch (ex) {
				// si exception businessrule, mettre 0
				this.notification.warning(ex.data);
				if (ex.status == 404) {
					this.factureArticle.prixStandard = 0;
					this.factureArticle.idTVA = 1;
				}
			}

			if (prixArticle != null) {
				this.factureArticle.prixStandard = prixArticle.prixStandard;
				this.factureArticle.typePrix = prixArticle.typePrix;
				this.factureArticle.prixUnitaire = prixArticle.prixUnitaire;
				this.factureArticle.idTVA = prixArticle.idTVA;
				this.factureArticle.valeur = prixArticle.valeur;

				if (this.facture.entete.isFranco) {
					this.factureArticle.prixUnitaireFranco = prixArticle.prixTransporte;
				}

				if (prixArticle.prixStandard == -1) {
					this.factureArticle.prixStandard = null;
					this.notification.warning(this.$translate.instant('FACTURES.NO_PRIX_FOUND'));
				}
			}

			if (this.factureArticle.idTVA == 0) {
				// recherche taux TVA par défault
				let code = '';
				const filtre = {
					chantierId: this.block.idChantier,
					paysId: this.facture.entete.idPays,
					date: this.facture.entete.dateGeneration,
					achatVente: 1
				};

				switch (this.factureArticle.typeArticle) {
					case '0': // produit
						code = 'tvaProduits';
						filtre.produitId = this.factureArticle.article[0].id;
						break;
					case '1': // transport
						code = 'tvaTransp';
						filtre.transportId = this.factureArticle.article[0].id;
						break;
					case '2': // prestation
						code = 'tvaPresta';
						filtre.prestationId = this.factureArticle.article[0].id;
						break;
					default:
						break;
				}

				try {
					const tempTVA = await this.FacturesService.getTVAArticle(code, filtre);

					if (tempTVA.elements.length > 0) {
						const sortedListTVA = _.sortBy(tempTVA.elements, function (tvaItem) {
							return -tvaItem.dateApplication;
						});

						if (sortedListTVA[0].tvaId != null) {
							this.factureArticle.tvaId = sortedListTVA[0].tvaId;
						}
					}
				} catch (ex) {
					this.notification.error(ex.data);
				}
			}

			if (this.factureArticle.tvaId == 0) {
				this.notification.warning(this.$translate.instant('FACTURES.TVANOTFOUND'));
				this.factureArticle.idTVA = 1;
			}
		}
	}

	async convertStringToDecimal(article) {
		if (typeof article.quantite === 'string') {
			article.quantite = article.quantite.replace(',', '.').replace(' ', '');
		}
		if (typeof article.prixStandard === 'string') {
			article.prixStandard = article.prixStandard.replace(',', '.').replace(' ', '');
		}
		if (typeof article.prixUnitaire === 'string') {
			article.prixUnitaire = article.prixUnitaire.replace(',', '.').replace(' ', '');
		}
		if (typeof article.remise === 'string') {
			article.remise = article.remise.replace(',', '.').replace(' ', '');
		}
		if (typeof article.ristourne === 'string') {
			article.ristourne = article.ristourne.replace(',', '.').replace(' ', '');
		}
	}

	activeEditMode(bill) {
		if (bill.nomArticleReplace == null || bill.nomArticleReplace == '') {
			bill.nomArticleReplace = bill.nomArticle;
		}
		bill.isEditMode = true;
	}

	desactiveEditMode(bill) {
		bill.isEditMode = false;
	}

	async updateLibelleProduit(bill) {
		if (bill.idFactSomeThing) {
			try {
				await this.FacturesService.updateLibelleProduit(bill.idFactSomeThing, bill.nomArticleReplace, bill.typeArticle);
				this.notification.success(this.$translate.instant('FACTURES.ARTICLES.UPDATED'));
				bill.isEditMode = false;
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
			}
		}
	}

	async resetLibelle(bill) {
		bill.nomArticleReplace = null;
		this.updateLibelleProduit(bill);
	}

	async updateFactureCommentaire(bill) {
		if (this.isDuplicate) {
			bill.isEditMode = false;
		} else {
			if (bill.idFactSomeThing) {
				try {
					if (bill.libelleArticle.length > 255) {
						this.notification.error(this.$translate.instant('FACTURES.COMMENTAIRE.ERROR'));
					} else {
						await this.FacturesService.updateFactureCommentaire(bill.idFactSomeThing, bill.libelleArticle);
						this.notification.success(this.$translate.instant('FACTURES.COMMENTAIRE.UPDATED'));
						bill.isEditMode = false;
					}
				} catch (ex) {
					this.notification.error(ex.data);
				} finally {
				}
			}
		}
	}

	async deleteFactureCommentaire(bill) {
		if (this.isDuplicate) {
			const index = this._.findIndex(this.block.factureArticles, bill);
			this.block.factureArticles.splice(index, 1);
		} else {
			this.startLoading();
			try {
				await this.FacturesService.deleteFactureCommentaire(bill.idFactSomeThing);
				this.notification.success('FACTURES.COMMENTAIRE.DELETED');
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				await this.refresh();
				this.stopLoading();
			}
		}
	}

	async createFactureCommentaire(bill, isBefore) {
		if (this.isDuplicate) {
			const newCommentaire = {
				idFactSomeThing: 0,
				libelleArticle: '',
				typeArticle: 5
			};
			let index = this._.findIndex(this.block.factureArticles, bill);
			if (isBefore) {
				index = index - 1;
			} else {
				index = index + 1;
			}
			this.block.factureArticles.splice(index, 0, newCommentaire);
		} else {
			this.startLoading();
			try {
				const idCommentaire = await this.FacturesService.createFactureCommentaire(bill, isBefore);
				this.notification.success('FACTURES.COMMENTAIRE.CREATED');
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				await this.refresh();
				//this.OpenUpdateCommentaire(idCommentaire);
				this.stopLoading();
			}
		}
	}

	OpenUpdateCommentaire(idCommentaire) {
		const index = this._.findIndex(this.block.factureArticles, { idFactSomeThing: idCommentaire, typeArticle: 5 });
	}
}
