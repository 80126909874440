import TemplateImpression from './template.model';

export default class templateFormController {
    /* @ngInject */
    constructor($scope, $stateParams, $timeout, $translate, TemplateImpressionService, MassiaApplicationService, DomainesService) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.loading = false;
        this.type = 'info';
        this.timeout = $timeout;
        this.$translate = $translate;
        this.templateImpressionService = TemplateImpressionService;
        this.value = 0;
        this.cancelButton = 'IMPRESSION.CANCEL';
        this.codeExist = false;
        this.massiaAppService = MassiaApplicationService;
        this.domainService = DomainesService;
    }

    $onInit() {
        this.$scope.$broadcast('TemplateImpressionValidator');
        this.loadDomaines();
        this.form = {
            data: this.resolve && this.resolve.data && this.resolve.data.id ? new TemplateImpression(this.resolve.data) : new TemplateImpression(),
            file: {}
        };
        this.form.data.isValid();
        this.checkCode();
    }

    async loadDomaines() {
        try {
            const app = await this.massiaAppService.getApplication();
            const res = await this.domainService.getDomaines(app);
            this.removeUnnecessaryDomain(res);
            this.domaines = res;
        } catch (err) {
            console.error(err.data);
        }
    }

    removeUnnecessaryDomain(domains) {
        for (let i = 0; i < domains.length; i++) {
            if (!domains[i].droits || domains[i].droits.print == undefined) {
                //if(domains[i].niveauMax < 31){//SP : NON, prendre si droit sur impression
                domains.splice(i, 1);
                i--;
            }
        }
    }

    async ok(update = false) {
        this.loading = true;
        this.fileInfoOpen = false;
        if (this.form.data.isValid()) {
            this.value = 50;
            this.progress = this.$translate.instant('IMPRESSION.ENVOI_EN_COURS');
            try {
                let data = null;
                if (this.resolve.data && this.resolve.data.id) {
                    this.form.data.DateCreation = null;
                    data = await this.templateImpressionService.update(this.form);
                } else {
                    this.form.data.DateCreation = null;
                    data = await this.templateImpressionService.upload(this.form);
                }
                this.form.data = new TemplateImpression(data);
                this.form.data.SizeCoverted = this.form.data.Size / 100 + 'ko';
                this.value = 100;
                this.type = 'success';
                if (!update) {
                    this.progress = this.$translate.instant('IMPRESSION.UPLOAD_REUSSI');
                    this.fileInfoOpen = true;
                } else {
                    this.progress = this.$translate.instant('IMPRESSION.MAJ_REUSSIE');
                }
            } catch (err) {
                console.log(err);
                this.value = 100;
                this.type = 'danger';
                this.progress = err.data.message;
            }
            this.cancelButton = this.$translate.instant('FERMER');
        }
    }

    cancel() {
        this.$scope.$parent.$dismiss();
    }

    change(event) {
        const fileInfo = event.target.files[0];
        this.form.file = fileInfo;
        const file = this.getFileAndExtension(fileInfo.name);
        this.filename = file[0];
        this.form.data.Extension = file[1];
        this.form.data.Size = fileInfo.size;
        this.form.data.SizeCoverted = this.getSizeInfo(fileInfo.size);
        this.form.data.DateCreation = fileInfo.lastModified;
        this.fileInfoOpen = true;
        this.loading = false;
    }

    getSizeInfo(size) {
        let calc = size / 1000; //kilo-octet;

        if (calc > 1000) {
            calc = calc / 1000;
            return `${calc.toFixed(2)}mo`;
        }
        return `${calc.toFixed(2)}ko`;
    }

    getFileAndExtension(file) {
        const result = file.split('.');
        return result;
    }

    openFileUploader() {
        document.getElementById('file-upload').click();
    }

    async checkCode() {
        if (!this.form.data.Code) {
            this.form.data.Code = Date.now().toString().substring(4);
        }
        if (!this.resolve.data) {
            var result = await this.templateImpressionService.checkCode(this.form.data.Code);
        } else {
            result = true;
        }
        this.codeExist = result ? false : true;
    }

    checkform(form) {
        console.log(form);
    }
}
