import * as angular from 'angular';
import * as _ from 'lodash';

export default class ParametrageProgrammeController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'ParametrageGeneralService',
		'$translate',
		'SitesService',
		'NomenclaturesService',
		'ChantiersTypesService',
		'SitesTypesService',
		'notification'
	];

	constructor(
		$scope,
		$state,
		$stateParams,
		ParametrageGeneralService,
		$translate,
		SitesService,
		NomenclaturesService,
		ChantiersTypesService,
		SitesTypesService,
		notification
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.ParametrageGeneralService = ParametrageGeneralService;
		this.$translate = $translate;
		this.SitesService = SitesService;
		this.NomenclaturesService = NomenclaturesService;
		this.ChantiersTypesService = ChantiersTypesService;
		this.SitesTypesService = SitesTypesService;
		this.notification = notification;
	}

	async $onInit() {
		this.loading = true;

		this.groupesParamProg = await this.ParametrageGeneralService.getGroupes();
		this.paramProgConfig = await this.ParametrageGeneralService.getParametres();

		await this.loadList();
		this.formatListeParametre();
		this.loading = false;
	}

	// listes pour les multiSelect - à mettre à jour si ajout de nouveaux paramètres
	async loadList() {
		this.transporteurs = (await this.SitesService.getSitesTransporteurs())?.items || [];
		this.nomenclatures = (await this.NomenclaturesService.getNomenclatures(true))?.items || [];
		this.niveauxElts = (await this.ParametrageGeneralService.getElementsNiveauxStocks()) || [];
		this.niveaux = (await this.ParametrageGeneralService.getNiveauxStocks()) || [];
		this.chantiers = await this.ChantiersTypesService.getAll();
		this.clients = await this.SitesTypesService.getAllSiteClientTypes();
		this.fournisseurs = await this.SitesTypesService.getAllSiteFournisseurTypes();
		this.transports = await this.SitesTypesService.getAllSiteTransporteurTypes();
	}

	annuler() {
		this.formatListeParametre();
	}

	// Format de la liste des param prog → tri par groupe / traductions / format des données
	formatListeParametre() {
		this.params = angular.copy(this.parametres);
		this.params = angular.copy(this.parametres).map((p) => {
			return this.setParam(p);
		});

		// cas particulier des niveaux hierarchiques - a revoir si ajout de niveaux
		this.params = this.parametres.map((p) => {
			return this.setParam(p);
		});

		this.paramProgListe = angular.copy(this.groupesParamProg).map((gp) => {
			return {
				...gp,
				parametres: this.params.filter((x) => x.groupe == gp.nom)
			};
		});
	}

	getTraductionProp(code) {
		return `PARAMETRE_PROGRAMME.PARAM.${_.toUpper(code)}`;
	}

	setParam(p) {
		const param = {
			...p,
			...angular.copy(this.paramProgConfig.find((pg) => pg.code == p.cle))
		};
		param.libelle = this.getTraductionProp(param.cle);

		if (param.type === 'Boolean') {
			param.valeur = param.valeur == '1' || param.valeur.toLower() == 'true' || param.valeur === true;
		} else if (param.type === 'String' && param.listeElt) {
			const name = angular.copy(param.listeElt.liste);
			param.listeElt.liste = angular.copy(this[param.listeElt.liste]);
			if (param.listeElt?.parent) {
				this.setListeNiveauxHierarchique(name, param);
			}
			if (param.valeur && param.valeur != '') {
				if (param.listeElt.multi) {
					const values = param.valeur.split(',');
					param.listeElt.liste.map((el) => {
						el.selected = values.includes(el.code);
						if (el.selected) {
							param.listeElt.selected.push(el);
						}
						return el;
					});
				} else {
					param.listeElt.liste.map((el) => {
						el.selected = el.code == param.valeur || el.label == param.valeur;
						if (el.selected) {
							param.listeElt.selected.push(el);
						}
						return el;
					});
				}
			}
		}
		return param;
	}

	setListeNiveauxHierarchique(name, param, p = null) {
		let parent;
		if (p) {
			parent = p;
		} else {
			parent = this.params.find((x) => x.cle === param.listeElt.parent)?.listeElt?.liste?.find((x) => x.selected);
		}

		if (!parent || parent.length == 0) {
			param.disabled = true;
			param.listeElt.liste = [];
			return param;
		}
		if (name === 'niveaux') {
			param.listeElt.liste = parent.items;
		} else {
			param.listeElt.liste = angular.copy(this.niveauxElts.filter((x) => x.id.niveauHierarchiqueId == parent.id));
		}
		return param;
	}

	updateSelect(param) {
		this.loadingNiveaux = true;
		if (param.groupe === 'Stock' && param.listeElt.selected?.length == 1 && param.listeElt.isNiveau) {
			const niveauxEnfants = param.listeElt.selected[0].items;
			const child = this.paramProgListe.find((x) => x.nom === 'Stock')?.parametres?.filter((p) => p.listeElt.parent === param.cle);

			for (let i = 0; i < child.length; i++) {
				if (child[i].listeElt.isNiveau) {
					this.updateNiveauEnfant(child[i], niveauxEnfants);
				} else {
					this.updateElementEnfant(child[i], param.listeElt.selected[0].id);
				}
			}
		} else if (param.groupe === 'Stock' && param.listeElt.selected?.length == 0) {
			const gpStockIndex = this.paramProgListe.findIndex((x) => x.nom === 'Stock');
			this.paramProgListe[gpStockIndex].parametres.map((x) => {
				const listeName = x.isNiveau ? 'niveaux' : 'niveauxElts';
				if (x.listeElt.parent == param.cle) {
					x = this.setListeNiveauxHierarchique(listeName, x, param.listeElt.selected);
				}
				return x;
			});
		}
		this.paramProgListe = angular.copy(this.paramProgListe);
		this.loadingNiveaux = false;
	}

	updateNiveauEnfant(niv, listeElts) {
		const gpStockIndex = this.paramProgListe.findIndex((x) => x.nom === 'Stock');
		if (gpStockIndex != -1) {
			const index = this.paramProgListe[gpStockIndex].parametres.findIndex((p) => p.cle === niv.cle);
			if (index != -1) {
				const haveElts = listeElts?.length > 0;
				this.paramProgListe[gpStockIndex].parametres[index].listeElt.liste = angular.copy(listeElts);
				this.paramProgListe[gpStockIndex].parametres[index].disabled = !haveElts;
			}
		}
	}

	updateElementEnfant(param, idParent) {
		const gpStockIndex = this.paramProgListe.findIndex((x) => x.nom === 'Stock');
		if (gpStockIndex != -1) {
			const index = this.paramProgListe[gpStockIndex].parametres.findIndex((p) => p.cle === param.cle);
			if (index != -1) {
				const elts = this.niveauxElts.filter((x) => x.id.niveauHierarchiqueId == idParent);
				const haveElts = elts?.length > 0;
				this.paramProgListe[gpStockIndex].parametres[index].listeElt.liste = angular.copy(elts);
				this.paramProgListe[gpStockIndex].parametres[index].disabled = !haveElts;
			}
		}
	}

	async saveParamProg() {
		const groupesParametres = [
			{
				nomGroupe: 'ParamProg',
				parametres: this.parametres
			}
		];
		this.getParamProgValues();
		await this.ParametrageGeneralService.updateParametrageGeneral(groupesParametres, 'gestion');
		this.notification.success('PARAMETRAGE_GENERAL.UPDATED');
	}

	getParamProgValues() {
		for (let i = 0; i < this.parametres.length; i++) {
			const elementToSave = this.parametres[i];
			let paramsResult = [];
			for (let i = 0; i < this.paramProgListe.length; i++) {
				const gp = this.paramProgListe[i];
				paramsResult = [...paramsResult, ...gp.parametres];
			}
			const elementResult = _.find(paramsResult, (x) => x.cle === elementToSave.cle);
			let valeur = elementResult.valeur;
			if (elementResult) {
				valeur = this.getValeur(elementResult);
			}
			elementToSave.valeur = valeur;
		}
	}

	getValeur(element) {
		if (element.type === 'Boolean') {
			return element.valeur ? '1' : '0';
		} else if (element.type === 'String' && element.listeElt) {
			let val = '';
			if (element.listeElt.selected && element.listeElt.selected.length > 0) {
				const first = element.listeElt.selected[0];
				if (first.hasOwnProperty('code')) {
					val = element.listeElt.selected.map((x) => x.code).join();
				} else if (first.hasOwnProperty('label')) {
					val = element.listeElt.selected.map((x) => x.label).join();
				}
			}
			return val;
		}
		return element.valeur;
	}
}
