export default class StockPreclotureMoisPopupController {
    static $inject = ['$scope', '$stateParams', 'StocksCloturesService', 'notification', 'ModalService', '$uibModal', 'moment'];

    constructor($scope, $stateParams, StocksCloturesService, notification, ModalService, $uibModal, moment) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.StocksCloturesService = StocksCloturesService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
        this.moment = moment;
    }

    annuler() {
        this.modalInstance.close();
    }

    // async appliquer() {
    //     await this.sauvegarder();
    // }

    // async confirmer() {
    //     const success = await this.sauvegarder();

    //     if (success) {
    //         this.annuler();
    //     }
    // }

    async $onInit() {
        const siteInit = await this.StocksCloturesService.getSitesCommerciaux(null);
        if (siteInit.length == 1) {
            this.siteSearch = {
                id: siteInit[0].id,
                libelle: siteInit[0].libelle,
                code: siteInit[0].code
            };
        }
    }

    async getPeriode() {
        try {
            const siteId = this.siteSearch ? this.siteSearch.id : null;
            if (siteId) {
                this.periode = await this.StocksCloturesService.getPeriodeEnCoursCloture(siteId);
                this.date = this.periode.date;
                this.dateCloture = this.periode.dateCloture;
                this.datePreCloture = this.periode.datePreCloture;
                this.isCloture = this.periode.isCloture;
                this.dateStock = this.periode.dateStock;
            } else {
                this.periode = undefined;
                this.date = undefined;
                this.dateCloture = undefined;
                this.datePreCloture = undefined;
                this.isCloture = false;
                this.dateStock = undefined;
            }
        } catch (ex) {
            this.notification.error(ex.data);
            this.periode = undefined;
            this.date = undefined;
            this.dateCloture = undefined;
            this.datePreCloture = undefined;
            this.isCloture = false;
            this.dateStock = undefined;
            return false;
        }
    }

    async getSites(valeur) {
        const sites = await this.StocksCloturesService.getSitesCommerciaux(valeur);
        return sites;
    }

    async preCloturer() {
        try {
            this.startLoading();
            const siteId = this.siteSearch ? this.siteSearch.id : null;
            if (siteId && this.date && this.dateStockFinal) {
                await this.StocksCloturesService.preclotureMensuelle(siteId, this.periode.date, this.dateStockFinal);
                this.notification.success('STOCKS_CLOTURES.PRECLOTURE_MOIS_EFFECTUEE');
                this.annuler();
                return true;
            }

            this.notification.error('STOCKS_CLOTURES.CLOTURE_MOIS_PARAMETRE_MANQUANT');
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        } finally {
            this.stopLoading();
        }
    }

    async cloturer() {
        try {
            this.startLoading();
            const siteId = this.siteSearch ? this.siteSearch.id : null;
            if (siteId && this.date) {
                await this.StocksCloturesService.clotureMensuelle(siteId, this.periode.date, this.dateStockFinal, this.periode.idPreCloture);
                this.notification.success('STOCKS_CLOTURES.CLOTURE_MOIS_EFFECTUEE');
                this.annuler();
                return true;
            }

            this.notification.error('STOCKS_CLOTURES.CLOTURE_MOIS_PARAMETRE_MANQUANT');
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
