export default function Configure($stateProvider) {
    $stateProvider.state('unites', {
        parent: 'common',
        url: '/unites',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('unites.list', {
        url: '/list',
        template: '<unites></unites>',
        rights: { domain: 'unite', right: 'read' },
        ncyBreadcrumb: {
            parent: 'unites',
            label: '{{ "UNITES.BREADCRUMBS.UNITES_LIST" | translate}}'
        },
        // on affiche la liste des unites dans la navigation
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'UNITES.BREADCRUMBS.UNITES_LIST',
            order: 20
        },
        // la liste des unites fait partie des points d'entrée recherchables dans la navigation
        search: 'UNITES.BREADCRUMBS.UNITES_LIST'
    });

    $stateProvider.state('unites.list.detail', {
        url: '/{id}',
        template: '<unite-detail></unite-detail>',
        rights: { domain: 'unite', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher unites > détail
            parent: 'unites.list',
            label: '{{ "UNITES.BREADCRUMBS.UNITE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('unites.new', {
        url: '/new',
        template: '<unites-form></unites-form>',
        rights: { domain: 'unite', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher unites > ajouter
            parent: 'unites.list',
            label: '{{ "UNITES.BREADCRUMBS.UNITE_NEW" | translate}}'
        },
        // l'ajout d'une unite fait partie des points d'entrée recherchables dans l'application
        search: 'UNITES.BREADCRUMBS.UNITE_NEW'
    });

    $stateProvider.state('unites.edit', {
        url: '/{id}/edit',
        template: '<unites-form></unites-form>',
        rights: { domain: 'unite', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher unites > modifier
            parent: 'unites.list',
            label: '{{ "UNITES.BREADCRUMBS.UNITE_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
