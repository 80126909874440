import ReferentielComposantController from './Referentiel.composant.controller';

export default {
    bindings: {
        id: '=',
        isOpen: '=',
        onUpdate: '&'
    },
    template: require('./referentiel.composant.html'),
    controller: ReferentielComposantController
};
