export default class DomainesService {
    static $inject = ['$http'];
    static droits = {
        read: 1,
        create: 2,
        update: 4,
        delete: 8,
        print: 16,
        open: 32,
        buildSynthesis: 64
    };

    constructor($http) {
        this.$http = $http;

        this.getDroits = () => angular.copy(DomainesService.droits);

        this.getUrlDomaine = () => `${__configuration.apiUrl}/massia/domaines`;
        this.getUrlDomaineTypes = () => `${__configuration.apiUrl}/massia/domainestypes`;
    }

    async getDomaines(applicationName, onlyTypes) {
        let url = this.getUrlDomaine();
        if (onlyTypes) {
            url = this.getUrlDomaineTypes();
        }
        const res = await this.$http.get(url, {
            params: {
                appName: applicationName
            }
        });

        if (res.data && res.data.length > 0) {
            angular.forEach(
                res.data,
                (domaine) => {
                    domaine.droits = this.getDroitsByBitmask(domaine.niveauAcces, domaine.niveauMax);
                },
                this
            );
        }

        return res.data;
    }

    getBitmaskByDroits(droits = []) {
        let bitmask = 0;

        angular.forEach(this.getDroits(), (bit, mask) => {
            bitmask += droits[mask] ? bit : 0;
        });

        return bitmask;
    }

    getDroitsByBitmask(bitmask, niveauMax) {
        const droits = {};

        angular.forEach(this.getDroits(), (bit, mask) => {
            droits[mask] = (bit & niveauMax) > 0 ? (bit & bitmask) > 0 : undefined;
        });

        return droits;
    }

    getFullDroits(niveauMax) {
        return this.getDroitsByBitmask(this.getFullBitmask(niveauMax), niveauMax);
    }

    getFullBitmask(niveauMax) {
        let sum = 0;

        angular.forEach(this.getDroits(), (bit, mask) => {
            sum += (bit & niveauMax) > 0 ? bit : 0;
        });
        return sum;
    }

    async getEnumCode() {
        const data = await this.$http.get('assets/enum/domaine/code.json');
        return data.data;
    }
}
