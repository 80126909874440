const serviceUrl = `${__configuration.apiUrl}/massia/performance/zone-gisements`;

export default class ZoneGisementsService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getZoneGisements(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async deleteZoneGisementById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getZoneGisementById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSites(application) {
        const url = `${__configuration.apiUrl}/massia/sites-producteurs/headers/${application}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createZoneGisement(selectedZoneGisement) {
        const data = {
            id: selectedZoneGisement.id,
            code: selectedZoneGisement.code,
            libelle: selectedZoneGisement.libelle,
            idSite: selectedZoneGisement.idSite
        };

        return this.$http.post(serviceUrl, data);
    }

    async updateZoneGisement(selectedZoneGisement) {
        const data = {
            id: selectedZoneGisement.id,
            libelle: selectedZoneGisement.libelle,
            idSite: selectedZoneGisement.idSite
        };

        const url = `${serviceUrl}/${data.id}`;
        return this.$http.put(url, data);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }
}
