import DAP from './dap.model';

export default class DAPFormController {
    static $inject = [
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'DAPsCommunicationService',
        'DAPsService',
        'DocumentsService',
        'notification',
        '$location',
        '$anchorScroll',
        '$translate',
        '$uibModal',
        'ModalService'
    ];

    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        DAPsCommunicationService,
        DAPsService,
        DocumentsService,
        notification,
        $location,
        $anchorScroll,
        $translate,
        $uibModal,
        ModalService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.DAPsCommunicationService = DAPsCommunicationService;
        this.DAPsService = DAPsService;
        this.DocumentsService = DocumentsService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.$translate = $translate;
        this.$uibModal = $uibModal;
        this.ModalService = ModalService;
    }

    $onInit() {
        this.dap = {};
        this.loading = false;

        this.reset();
    }

    $onDestroy() {
        this.$timeout.cancel(this.updateDAPTimeout);
    }

    async reset() {
        this.startLoading();

        const data = {
            id: this.$stateParams.id
        };

        if (data.id) {
            try {
                data.entete = await this.DAPsService.getDapEnteteById(data.id);
                this.dap = new DAP(data);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
                this.annuler();
            }
        } else {
            this.dap = new DAP(data);
            this.dap.entete.actif = true;
        }

        this.ongletOpen = {
            isEnteteOpen: true,
            isTicketsOpen: false
        };

        this.$timeout.cancel(this.updateDAPTimeout);
        this.updateDAPTimeout = this.$timeout(() => this.stopLoading());
    }

    openFirstOnglet() {
        this.ongletOpen = {
            isEnteteOpen: true,
            isTicketsOpen: false
        };
    }

    closeAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: false,
            isTicketsOpen: false
        };
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                let id = false;
                this.dap.entete.etat = this.dap.entete.actif ? 1 : 0;
                if (this.dap.id) {
                    await this.DAPsService.updateDAP(this.dap);
                    this.notification.success('DAPS.UPDATED');
                    id = this.dap.id;
                } else {
                    id = await this.DAPsService.createDAP(this.dap);
                    this.notification.success('DAPS.CREATED');
                }

                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async appliquer() {
        const id = await this.sauvegarder();
        if (id && this.dap.id) {
            this.reset();
        } else if (id) {
            this.$state.go('daps.edit', { id: id });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    annuler() {
        this.$state.go('daps.list');
    }

    reinit() {
        this.openFirstOnglet();
        this.setDAP(this.$stateParams.id);
        this.DAPsCommunicationService.raiseReinit();
    }

    checkValidity() {
        let validity = true;
        let firstScroll = true;
        if (!this.dap.entete.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('entete');
                firstScroll = false;
            }

            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.notification.error(this.$translate.instant('DAPS.CHAMPS_OBLIGATOIRES'));
            this.$scope.$broadcast('dapEnteteValidations');
        }
        if (
            !this.dap.entete.siteCommercialId ||
            !this.dap.entete.producteurId ||
            !this.dap.entete.transporteurIds[0] ||
            !this.dap.entete.clientDetenteurId ||
            !this.dap.entete.lieuOrigineId ||
            !this.dap.entete.chantierOrigineId
        ) {
            if (firstScroll) {
                this.scrollToOnglet('entete');
                firstScroll = false;
            }

            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.notification.error(this.$translate.instant('DAPS.CHAMPS_OBLIGATOIRES'));
        }
        return validity;
    }

    async createDocument() {
        const _this = this;

        this.$uibModal
            .open({
                template: '<document-modal-form type-selected="$ctrl.typeSelected" modal-instance="$ctrl.uibModalInstance"></document-modal-form>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.typeSelected = 'S_DAP_ANALYSE';
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'md'
            })
            .result.then(
                async function (document) {
                    const lstIdNamePair = {};
                    lstIdNamePair[document.id] = document.libelle;
                    await _this.DocumentsService.updateFiltre(_this.dap.id, 28, lstIdNamePair);
                    _this.reset();
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    async print() {
        const fileName = this.dap.entete.numero;
        const resultat = await this.DAPsService.print(this.dap.id);

        const data = resultat.data;
        const status = resultat.status;
        let headers = resultat.headers;

        headers = headers();

        const contentType = headers['content-type'];

        const linkElement = document.createElement('a');
        try {
            const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
            const url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute('download', fileName + '.pdf');

            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(clickEvent);
        } catch (ex) {
        } finally {
        }
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    onUpdateEntete(entete) {
        this.dap.entete = entete;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
