export default function Configure($stateProvider) {
    $stateProvider.state('personnes', {
        parent: 'common',
        url: '/personnes',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('personnes.list', {
        url: '/:genre/list',
        template: '<personnes></personnes>',
        rights: { domain: 'personnel', right: 'read' },
        ncyBreadcrumb: {
            parent: 'personnes',
            label: '{{ "PERSONNES.BREADCRUMBS.PERSONNES_LIST" | translate}}'
        },
        // // on affiche la liste des personnes dans la navigation
        // navigation: {
        //     menu: 'donnees_parametres_donnees',
        //     translate: 'PERSONNES.BREADCRUMBS.PERSONNES_LIST'
        // },
        // // la liste des personnes fait partie des points d'entrée recherchables dans la navigation
        search: 'PERSONNES.BREADCRUMBS.PERSONNES_LIST'
    });

    $stateProvider.state('personnes.list.detail', {
        url: '/{id}',
        template: '<personne-detail></personne-detail>',
        //rights: {domain: 'personnel', right: 'read'},
        ncyBreadcrumb: {
            parent: 'personnes.list',
            label: '{{ "PERSONNES.BREADCRUMBS.PERSONNE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('personnes.new', {
        url: '/:genre/new',
        template: '<personnes-form></personnes-form>',
        rights: { domain: 'personnel', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher personnes > ajouter
            parent: 'personnes.list({genre: "personne"})',
            label: '{{ "PERSONNES.BREADCRUMBS.PERSONNE_NEW" | translate}}'
        },
        // l'ajout d'un arret fait partie des points d'entrée recherchables dans l'application
        search: 'PERSONNES.BREADCRUMBS.PERSONNE_NEW'
    });

    $stateProvider.state('personnes.edit', {
        url: '/:genre/{id}/edit',
        template: '<personnes-form></personnes-form>',
        //rights: {domain: 'personnel', right: 'update'},
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher personnes > modifier
            parent: 'personnes.list({genre: "personne"})',
            label: '{{ "PERSONNES.BREADCRUMBS.PERSONNE_EDIT" | translate }}'
        }
    });

    /*$stateProvider.state('personnes.newType', {
        url: '/new-type',
        template: '<type-form></type-form>',
        rights: {domain: 'types', right: 'create'},
        params: {parentState:'personnes.new'},
        ncyBreadcrumb: {
            parent: 'personnes.new({genre: "personne"})',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });*/
}

Configure.$inject = ['$stateProvider'];
