(function () {
    'use strict';

    angular
        .module('blocks.notification')
        .factory('notification', notification);

    notification.$inject = ['toastr', '$translate'];

    /* @ngInject */
    function notification(toastr, $translate) {
        var service = {
            clear: toastr.clear,
            remove: toastr.remove,
            error: error,
            info: info,
            success: success,
            warning: warning
        };

        return service;

        function error (message, title, optionsOverride) {
            var msg = checkErrorMessage(message);

            var messageTranslated = $translate.instant(msg);
            var titleTranslated = title ? $translate.instant(title) : undefined;

            toastr.error(messageTranslated, titleTranslated, optionsOverride);
        }

        function info (message, title, optionsOverride) {
            var messageTranslated = message ? $translate.instant(message) : undefined;
            var titleTranslated = title ? $translate.instant(title) : undefined;

            toastr.info(messageTranslated, titleTranslated, optionsOverride);
        }

        function success (message, title, optionsOverride) {
            var messageTranslated = message ? $translate.instant(message) : undefined;
            var titleTranslated = title ? $translate.instant(title) : undefined;

            toastr.success(messageTranslated, titleTranslated, optionsOverride);
        }

        function warning (message, title, optionsOverride) {
            var messageTranslated = message ? $translate.instant(message) : undefined;
            var titleTranslated = title ? $translate.instant(title) : undefined;

            toastr.warning(messageTranslated, titleTranslated, optionsOverride);
        }

        function checkErrorMessage(message) {
            var msg;
            if (!message) {
                msg = 'UNKNOWN_ERROR';
            }
            else if (message.message !== undefined) {
                msg = message.message;
            }
            else if (typeof message.indexOf === 'function' && message.indexOf('<!DOCTYPE') !== -1) {
                // si le message est en html on ne l'affiche pas
                msg = 'UNKNOWN_ERROR';
            }
            else {
                msg = message;
            }

            // Si malgré tout le message est vide
            if (!msg) {
                msg = 'UNKNOWN_ERROR';
            }

            return msg;
        }

    }
})();