import ProspectEnteteController from './prospect.entete.controller';

export default {
    bindings: {
        prospect: '=',
        isOpen: '='
    },
    template: require('./prospect.entete.html'),
    controller: ProspectEnteteController
};
