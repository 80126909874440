import validatorBuilder from 'validator-builder';

let indiceRevisionValidator = null;

export default class IndiceRevision {
    constructor(data) {
        data = data || {};
        this.id = data.id || undefined;
        this.code = data.code || undefined;
        this.libelle = data.libelle || undefined;
        this.typeArticle = data.typeArticle || 0;
        this.indices = data.indices || undefined;
    }

    isValid() {
        indiceRevisionValidator = indiceRevisionValidator || validatorBuilder.getInstanceFor('IndiceRevision');
        const validationResults = indiceRevisionValidator.validate(this);
        return validationResults.isValid;
    }
}
