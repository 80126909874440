const serviceUrl = `${__configuration.apiUrl}/massia/produits`;

export default class FormuleService {
	static $inject = ['$http', '$translate'];

	constructor($http, $translate) {
		this.$http = $http;
		/**
		 * @type ng.translate.ITranslateService
		 */
		this.$translate = $translate;
	}

	async getFormules(filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async getAllFormules(idProducteur) {
		const url = `${__configuration.apiUrl}/massia/produits/compositions/header/${idProducteur}`;
		const res = await this.$http.get(url);

		return res.data;
	}

	async getAllProduits(id, idProd, app) {
		const url = `${__configuration.apiUrl}/massia/produits/producteursProduits`;

		const filters = {
			siteCommercialId: id,
			produitId: idProd || null,
			application: app || null
		};

		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || {})
			}
		});

		return res.data;
	}

	async getClientLabProduits(id, idProduit) {
		const url = `${__configuration.apiUrl}/massia/produits/client-lab`;

		const res = await this.$http.get(url, {
			params: {
				id: id,
				idProduit: idProduit
			}
		});

		return res.data;
	}

	async getClientLabProduitsWithDate(id, idProduit, dateFinValidite) {
		const url = `${__configuration.apiUrl}/massia/produits/client-lab-with-date`;

		const res = await this.$http.get(url, {
			params: {
				id: id,
				idProduit: idProduit,
				dateFinValidite: JSON.stringify(dateFinValidite || '')
			}
		});

		return res.data;
	}

	async getFormuleBySquelette(idProduit, idProducteur) {
		const url = `${__configuration.apiUrl}/massia/produits/compositions/squelette/${idProduit}/${idProducteur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getFormuleById(id, idSite) {
		const url = `${__configuration.apiUrl}/massia/produits/compositions/${id}/${idSite}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getFormuleByProduitProducteur(idProduit, idProducteur) {
		const url = `${__configuration.apiUrl}/massia/produits/compositions/by-produit-producteur/${idProduit}/${idProducteur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getFormuleByProduitProducteurLight(idProduit, idProducteur) {
		const url = `${__configuration.apiUrl}/massia/produits/compositions/by-produit-producteur-light/${idProduit}/${idProducteur}`;
		const res = await this.$http.get(url, {
			params: {
				lightCompo: true
			}
		});
		return res.data;
	}

	async createFormule(formule) {
		const url = `${__configuration.apiUrl}/massia/produits/compositions/creation`;
		const res = await this.$http.post(url, formule);
		return res.data;
	}

	async updateFormule(idComposition, formule) {
		const url = `${__configuration.apiUrl}/massia/produits/compositions/${idComposition}`;
		const res = await this.$http.put(url, formule);
		return res.data;
	}

	async updateProduitsFormule(idComposition, formule) {
		const url = `${__configuration.apiUrl}/massia/produits/compositions/produits/${idComposition}`;
		const res = await this.$http.put(url, formule);
		return res.data;
	}

	async updatePrestationsFormule(idComposition, formule) {
		const url = `${__configuration.apiUrl}/massia/produits/compositions/prestations/${idComposition}`;
		const res = await this.$http.put(url, formule);
		return res.data;
	}

	async archiveFormule(idComposition, formule, app) {
		const url = `${__configuration.apiUrl}/massia/produits/compositions/archive/${idComposition}/${app}`;
		const res = await this.$http.put(url, formule);
		return res.data;
	}

	async updateComposant(idProduit, idComposition, composant) {
		const url = `${__configuration.apiUrl}/massia/produits/${idProduit}/compositions/${idComposition}/composants/${composant.id}`;
		const res = await this.$http.put(url, composant);
		return res.data;
	}

	async getAllPrestations(filters, sorts, pagination) {
		const url = `${__configuration.apiUrl}/massia/prestations`;
		const res = await this.$http.get(url);
		return res.data.items;
	}

	async getProduitsComposables(filters, sorts, pagination, application) {
		const url = `${__configuration.apiUrl}/massia/produits/produitsComposables/`;
		const res = await this.$http.get(url, {
			params: {
				application: JSON.stringify(application),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});
		return res.data;
	}

	async getFormuleFor(produitId, producteurId) {
		const url = `${__configuration.apiUrl}/massia/produits/compositions/listeParCouple`;
		const res = await this.$http.get(url, {
			params: {
				idsProduitProducteur: JSON.stringify([produitId, producteurId])
			}
		});
		return res.data;
	}

	async codeExists(code) {
		const url = `${serviceUrl}/code-unicity/${code}`;
		const result = await this.$http.get(url);
		return result.data;
	}

	async getPrixAchatProduit(idProducteurProduitCompose, idProduitComposant, idProducteurComposant, date) {
		// INFOS  Attention ici on a choisi de considérer le producteur comme un site commercial pour récupérer les tarifs d'achat.
		const filtre = {
			achatVente: false,
			date: date
		};
		const domaine = 'siteCommercial';
		const url = `${__configuration.apiUrl}/massia/grillesTarifaires/${domaine}/${idProducteurProduitCompose}/prixProduit/${idProduitComposant}/${idProducteurComposant}`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filtre || [])
			}
		});
		return res.data;
	}

	async getPourcentagePerte(idProducteurProduitCompose, idProduitComposant, idProducteurComposant, date) {
		// INFOS  Attention ici on a choisi de considérer le producteur comme un site commercial pour récupérer les tarifs d'achat.
		const filtre = {
			date: date
		};
		const url = `${__configuration.apiUrl}/massia/grilleStockLigne/siteCommercial/${idProducteurProduitCompose}/produit/${idProduitComposant}/producteur/${idProducteurComposant}`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filtre || [])
			}
		});
		return res.data ? res.data.pourcentagePerte : 0;
	}

	async getPrixAchatPrestation(idProducteurProduitCompose, idPrestation, date) {
		// INFOS  Attention ici on a choisi de considérer le producteur comme un site commercial pour récupérer les tarifs d'achat.
		const filtre = {
			achatVente: false,
			date: date
		};
		const domaine = 'siteCommercial';
		const url = `${__configuration.apiUrl}/massia/grillesTarifaires/${domaine}/${idProducteurProduitCompose}/prixPrestation/${idPrestation}`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filtre || [])
			}
		});
		return res.data;
	}

	async getProduitById(id) {
		const url = `${__configuration.apiUrl}/massia/produits/${id}/entete`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getGrilleStock(siteCommercialId, produitId, producteurId) {
		const url = `${__configuration.apiUrl}/massia/grilleStockLigne/siteCommercial/${siteCommercialId}/produit/${produitId}/producteur/${producteurId}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getProducteurById(id, application) {
		const url = `${__configuration.apiUrl}/massia/sites/${id}`;
		const res = await this.$http.get(url);

		return res.data;
	}

	async deleteArchiveById(id) {
		const url = `${serviceUrl}/compositions/${id}`;
		return this.$http.delete(url);
	}

	async getValeurReference(idProduit, idSite, codeEssai, dateFin, dateDebut = null) {
		const url = `${__configuration.apiUrl}/massia/laboratoire/valeurs-references/valeur-reference`;
		const res = await this.$http.get(url, {
			params: {
				idSite: idSite,
				idProduit: idProduit,
				codeParam: codeEssai,
				dateFin: dateFin,
				dateDebut: dateDebut
			}
		});
		return res.data;
	}

	async getValeursReference(lstIdProduit, lstIdSite, lstCodeEssai) {
		const url = `${__configuration.apiUrl}/massia/laboratoire/valeurs-references/valeurs-references`;
		const res = await this.$http.get(url, {
			params: {
				idsSite: JSON.stringify(lstIdProduit),
				idsProduit: JSON.stringify(lstIdSite),
				codesParams: JSON.stringify(lstCodeEssai)
			}
		});
		return res.data;
	}

	async getOneByNormeAddition(idNorme, idAddition) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/laboratoire/coefficientk` + '/' + idNorme + '/' + idAddition);
		return res.data;
	}

	async getImprimable() {
		const data = await this.$http.get('assets/enum/formule/select.imprimable.enum.json');
		return data.data.map((x) => {
			x.libelle = this.$translate.instant(x.translate);
			return x;
		});
	}

	async print(formule, filename) {
		const url = `${__configuration.apiUrl}/massia/produits/compositions/print`;
		const res = await this.$http.post(url, {
			template: filename,
			formule: formule
		});

		return res.data ? res.data : null;
	}

	async getFormuleByDate(idProduit, idProducteur, date) {
		const url = `${__configuration.apiUrl}/massia/produits/compositions/date/${idProduit}/${idProducteur}`;
		const res = await this.$http.get(url, {
			params: {
				date: date
			}
		});
		return res.data;
	}
}
