const serviceUrl = `${__configuration.apiUrl}/massia/monitoring-synchro`;

export default class MonitoringSynchroService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getMonitoringClientServeur(filters, sorts, pagination, callBack) {
        const res = await this.$http.get(serviceUrl + '/client-serveur', {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });
        if (callBack) {
            callBack(res.data);
        }
        return res.data;
    }

    async getMonitoringServeurClient(filters, sorts, pagination, callBack) {
        const res = await this.$http.get(serviceUrl + '/serveur-client', {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        if (callBack) {
            callBack(res.data);
        }
        return res.data;
    }

    async printClientServeur(filters, sorts) {
        const res = await this.$http.get(serviceUrl + '/print/clt-srv', {
            params: {
                modeImpr: 1,
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || [])
            },
            responseType: 'arraybuffer'
        });
        return res;
    }

    async printServeurClient(filters, sorts) {
        const res = await this.$http.get(serviceUrl + '/print/srv-clt', {
            params: {
                modeImpr: 1,
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || [])
            },
            responseType: 'arraybuffer'
        });
        return res;
    }

    moveFailedSynchroXML(nomFichier, extension, id) {
        return this.$http.get(serviceUrl + '/move-failed-synchro-xml', {
            params: {
                fichier: nomFichier,
                extension: extension,
                id: id
            },
            responseType: 'arraybuffer'
        });
    }
}
