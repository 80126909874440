import AppellationsCommercialesController from './appellations.controller';

export default {
    bindings: {
        produit: '=',
        app: '<',
        form: '=',
        onUpdate: '&'
    },
    template: require('./appellations.html'),
    controller: AppellationsCommercialesController
};
