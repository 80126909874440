const serviceUrl = `${__configuration.apiUrl}/massia/parametre-programme`;

export default class ParametreProgrammeService {
    constructor($http, $translate) {
        this.$http = $http;
        this.$translate = $translate;
        this.serviceUrl = `${__configuration.apiUrl}/massia`;
    }

    async getParametreProgramme() {
        const res = await this.$http.get(`${serviceUrl}`);

        return res.data;
    }

    async updateParametreProgramme(data) {
        {
            const res = await this.$http.put(`${serviceUrl}`, data);

            return res.data;
        }
    }

    async getParamProgRessource(resource) {
        const url = 'assets/enum/param-prog/' + resource + '.json';
        const res = await this.$http.get(url);
        let resultat = res.data;
        resultat.forEach((x) => (x.libelle = this.$translate.instant(x.libelle)));
        return resultat;
    }

    async getOneByCode(code) {
        const res = await this.$http.get(`${serviceUrl}/code`, {
            params: {
                cle: code
            }
        });
        return res.data;
    }

    // async getEssaiList() {
    //     let url = `${__configuration.apiUrl}/massia/mesures-list`;
    //     let res = await this.$http.get(url, {
    //         params: {
    //             domaine: JSON.stringify('essais' || {}),
    //             idTypeMesure: JSON.stringify(0),
    //             idTypeFiltreTheme: JSON.stringify(0)
    //         }
    //     });

    //     return res.data;
    // }
}

ParametreProgrammeService.$inject = ['$http', '$translate'];
