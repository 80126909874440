export default function Configure($stateProvider) {
    $stateProvider.state('formules', {
        parent: 'common',
        url: '/formules',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('formules.list', {
        url: '/list',
        template: '<formules></formules>',
        rights: { domain: 'compo', right: 'read', application: 'gestion' },
        ncyBreadcrumb: {
            label: '{{ "FORMULES.BREADCRUMBS.FORMULES_LIST" | translate}}'
        },
        // on affiche la liste des formules dans la navigation
        navigation: {
            menu: 'donnees_parametres_donnees',
            translate: 'FORMULES.BREADCRUMBS.FORMULES_LIST',
            order: 10,
            navigationCls: 'menu-item-separator'
        },
        // la liste des formules fait partie des points d'entrée recherchables dans la navigation
        search: 'FORMULES.BREADCRUMBS.FORMULES_LIST'
    });

    // $stateProvider.state('formules.new', {
    //     url: '/new',
    //     template: '<formule-form></formule-form>',
    //     rights: {domain: 'compo', right: 'create'},
    //     ncyBreadcrumb: {
    //         // on décide que la liste est le parent des autres states
    //         // afin d'afficher formules > ajouter
    //         parent: 'produits.edit',
    //         label: '{{ "FORMULES.BREADCRUMBS.FORMULE_NEW" | translate}}'
    //     },
    //     // l'ajout d'un produit fait partie des points d'entrée recherchables dans l'application
    //     search: 'PRODUITS.BREADCRUMBS.PRODUIT_NEW'
    // });

    $stateProvider.state('formules.edit', {
        url: '/{produitId}/{producteurId}/edit',
        template: '<formule-form></formule-form>',
        params: { data: null },
        rights: { domain: 'compo', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher formules > modifier
            parent: 'formules.list',
            label: '{{ "FORMULES.BREADCRUMBS.FORMULE_EDIT" | translate }}'
        }
    });

    $stateProvider.state('formules.produit', {
        url: '/{produitId}/{producteurId}/produit',
        template: '<formule-form></formule-form>',
        rights: { domain: 'compo', right: 'update' },
        ncyBreadcrumb: {
            label: '{{ "FORMULES.BREADCRUMBS.FORMULE_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
