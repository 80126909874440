export default class SiteFormProduitsProducteurController {
    /* @ngInject */
    constructor($scope, notification, MassiaApplicationService, $uibModal, $translate, ModalService, ProduitsService, $stateParams) {
        this.$scope = $scope;
        this.notification = notification;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$uibModal = $uibModal;
        this.$translate = $translate;
        this.ModalService = ModalService;
        this.ProduitsService = ProduitsService;
        this.$stateParams = $stateParams;
        this.search = '';
        this.loading = false;
        this.currentPage = 1;
        this.itemsByPageOptions = [20, 100, 200];
        this.itemsPerPage = this.itemsByPageOptions[0];
    }

    async $onInit() {
        this.startLoading();
        this.application = this.MassiaApplicationService.getApplication();
        this.refreshPages();
        this.stopLoading();
    }

    $onDestroy() {}

    async deleteProduit(produit) {
        if (produit && produit.id) {
            const modalInstance = this.ModalService.confirm({
                modalTitle: this.$translate.instant('PRODUITS.PRODUCTEURS.DELETE_PRODUITS.TITLE', { code: produit.libelle }),
                modalMsg: this.$translate.instant('PRODUITS.PRODUCTEURS.DELETE_PRODUITS.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async () => {
                try {
                    // Delete relation site produit (this.$stateParams.id === IdProducteur)
                    await this.ProduitsService.deleteProducteurs(produit.id, this.$stateParams.id);
                    // Non, on ne supprime pas le produit..
                    // await this.ProduitsService.deleteProduitById(produit.id);
                    // this.notification.success($translate.instant('PRODUITS.DELETE.SUCCESS'));
                    this.startLoading();

                    // Recupère l'index du produit de la liste des produits
                    const index = this.produits.findIndex((x) => x.id == produit.id);

                    // Si produit non trouvé dans la liste, envoyer une erreur pour eviter tout types de conflits
                    if (index < 0) {
                        throw { data: this.$translate.instant('PRODUITS.NO_PRODUCT_LIST', { libelle: produit.libelle }) };
                    }

                    // Enleve le produits de la liste des produits
                    this.produits.splice(index, 1);

                    this.notification.success(this.$translate.instant('PRODUITS.PRODUCTEURS.DELETE_PRODUITS.SUCCESS'));
                } catch (ex) {
                    this.notification.error(ex.data);
                } finally {
                    this.stopLoading();
                }
            });
        }
    }

    refreshPages() {
        this.numPages = Math.ceil(this.produits.length / this.itemsPerPage) != 0 ? Math.ceil(this.produits.length / this.itemsPerPage) : 1;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
