import ParametrageProgrammeController from './parametre.programme.controller';

export default {
	template: require('./parametre.programme.template.html'),
	controller: ParametrageProgrammeController,
	bindings: {
		parametres: '=',
		params: '='
	}
};
