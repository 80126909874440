TransportsController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'TransportsService',
	'notification',
	'$transitions',
	'MOPService'
];

export default function TransportsController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	TransportsService,
	notification,
	$transitions,
	MOPService
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	vm.selectedTransportId = undefined;
	vm.lastSelected = undefined;

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.transports = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectTransport = selectTransport;
	vm.isTransportSelected = isTransportSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadTransports = loadTransports;
	vm.deleteTransport = deleteTransport;
	vm.setSelected = setSelected;
	vm.eraseAllFilters = eraseAllFilters;
	vm.MOPService = MOPService;

	function init() {
		vm.MOPService.setMop([
			{ title: 'TRANSPORTS.BREADCRUMBS.TRANSPORTS_LIST', filename: 'ModeOperatoirePlanningsTransport.pdf', application: 'gestion' }
		]);
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	init();

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(transport) {
		if (transport && transport.id) {
			vm.selectedTransportId = $stateParams.id;
			vm.lastSelected = vm.selectedTransportId;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			vm.selectedTransportId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state && $state.current && $state.current.name && $state.current.name === 'transports.list.detail' && $stateParams && $stateParams.id;
	}

	function selectTransport(transport) {
		if (transport && transport.id) {
			if (vm.selectedTransportId !== transport.id) {
				vm.selectedTransportId = transport.id;
				showDetail(transport.id);
			} else {
				vm.selectedTransportId = undefined;
				closeDetail();
			}
		}
	}

	function isTransportSelected(transport) {
		return transport && transport.id && vm.selectedTransportId === transport.id;
	}

	function showDetail(transportId) {
		$state.go('transports.list.detail', { id: transportId });
	}

	function closeDetail() {
		$state.go('transports.list');
	}

	async function loadTransports(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'transports.list') {
			$state.go('transports.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.transports = [];

		try {
			const data = await TransportsService.getTransports(filters, sorts, pagination);

			vm.transports = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteTransport(transport) {
		if (transport && transport.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('TRANSPORTS.DELETE.TITLE', { code: transport.libelle }),
				modalMsg: $translate.instant('TRANSPORTS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await TransportsService.deleteTransportById(transport.id);
					notification.success($translate.instant('TRANSPORTS.DELETE.SUCCESS'));

					vm.selectedTransportId = undefined;

					if (vm.params.id) {
						vm.state.go('transports.list');
					}

					loadTransports();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function eraseAllFilters() {
		loadTransports();
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
