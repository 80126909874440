import { copy } from 'angular';

export default class FTPController {
    /* @ngInject */
    constructor($scope, $state, SyntheseEntityService, MesuresService, $translate, moment, $timeout, uiGridConstants, notification) {
        this.$scope = $scope;
        this.$state = $state;
        this.SyntheseEntityService = SyntheseEntityService;
        this.MesuresService = MesuresService;
        this.$translate = $translate;
        this.notification = notification;
        this.$timeout = $timeout;
        /**
         * @type import("moment");
         */
        this.moment = moment;
        this.uiGridConstants = uiGridConstants;
    }

    async $onInit() {
        try {
            this.grid = [];
            this.essaiGranulo = {};
            this.chart = [];
            this.excluded = [];
            this.calculStat = ['@VSS', '@VSI', '@MOY'];
            this.result = await this.generate();
            await this.getEssaiGranulo();

            this.criterions = [];
            this.$scope.cellClass = this.cellClass;
            this.$scope.delete = this.deletePlv;
            this.$scope.update = this.updatePlv;
            this.convertValue(this.result.results);
            this.createTable(this.result.results);
            this.createGraph();
            this.loading = false;
            this.softReload = false;
        } catch (err) {
            console.error(err);
        }
    }

    cellClass(row, col) {
        const c = [];
        const entity = row.entity.columns[col.colDef.originalField];
        if (row.isRowHeader) {
            c.push('ui-grid-row-header-cell');
        }
        if (entity && entity.conformite) {
            switch (entity.conformite) {
                case 1:
                    c.push('alerte');
                    break;
                case 2:
                    c.push('non-conforme');
                    break;
                case 3:
                    c.push('tolerance-conforme');
                    break;
                default:
                    break;
            }
        }
        return c.join(' ');
    }

    $onDestroy() {}

    convertValue(results) {
        for (let i = 0; i < results.length; i++) {
            const table = results[i].grid;
            results[i].grid.body = table.body.map((x) => {
                const col = x.columns;
                for (const key in col) {
                    if (Object.hasOwnProperty.call(col, key)) {
                        const element = col[key];
                        if (element.idEssai > 0 || element.idTamis > 0) {
                            const val = parseFloat(element.value);
                            if (!Number.isNaN(val)) {
                                element.value = val;
                            }
                        }
                    }
                }
                return x;
            });
        }
    }

    createTable(results) {
        for (let i = 0; i < results.length; i++) {
            const table = results[i].grid;
            if (!this.grid) {
                this.grid = [];
            }
            this.grid[i] = {
                enableGridMenu: true,
                enableSorting: false,
                exporterCsvFilename: 'ftp.csv',
                exporterMenuPdf: false,
                exporterMenuSelectedData: false,
                exporterMenuVisibleData: false,
                rowTemplate: `<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" 
                class="ui-grid-cell" ng-style="{ 'color': row.entity.text, 'background-color': row.entity.background }" 
                ng-class="grid.appScope.cellClass(row, col)"  ui-grid-cell></div>`,
                //exporterPdfTableStyle: { margin: [5, 5, 5, 5] },
                /* exporterPdfFooter: function (currentPage, pageCount) {
                    return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
                },
                exporterPdfOrientation: 'portrait', */
                exporterExcelFilename: 'ftp.xlsx',
                exporterExcelSheetName: 'ftp',
                onRegisterApi: (gridApi) => {
                    this.gridApi = gridApi;
                    gridApi.edit.on.afterCellEdit(this.$scope, (row, col, newVal, oldVal) => {
                        const val = parseFloat(newVal);
                        const entity = row.columns[col.originalField];
                        newVal = val;
                        entity.value = val;
                        if (Number.isNaN(val)) {
                            entity.value = oldVal;
                            newVal = oldVal;
                        }
                        this.updateCell(entity.value, entity.idEssai, entity.idTamis, row.id);
                    });
                },
                columnDefs: [
                    ...table.header.map((x) => {
                        x.minWidth = 100;
                        x.maxWidth = 1200;
                        x.originalField = x.field;
                        x.field = 'columns.' + x.field + '.value';
                        x.headerCellClass = 'bg-primary';
                        x.cellEditableCondition = ($scope) => {
                            const entity = $scope.row.entity.columns[$scope.col.colDef.originalField];
                            return entity.idEssai > 0 || entity.idTamis > 0;
                        };
                        return x;
                    }),
                    ...[
                        {
                            minWidth: 50,
                            width: 100,
                            maxWidth: 1200,
                            enableSorting: false,
                            name: this.$translate.instant('ACTION'),
                            headerCellClass: 'bg-primary',
                            cellTemplate: `<div ng-if="!row.entity.footer" class="mas-d-flex mas-ai-center mas-jc-space-e">
                                <button class="btn btn-link mas-p-0" ng-click="grid.appScope.update(row, col)"><span class="glyphicon glyphicon-edit"></span></button>
                                <button class="btn btn-link mas-p-0" ng-click="grid.appScope.delete(row, col)"><span class="glyphicon glyphicon-remove"></span></button>
                            </div>`
                        }
                    ]
                ],
                data: table.body
            };
        }
    }

    async generate() {
        try {
            const synthese = this.synthese;
            return await this.SyntheseEntityService.generate(synthese.id, synthese, synthese.typeSynthese, this.excluded, this.criterions);
        } catch (err) {
            console.error(err);
        }
    }

    async getEssaiGranulo() {
        let ssGran = null;
        let esGran = null;
        let abort = false;
        for (let i = 0; i < this.result.results.length && !abort; i++) {
            const res = this.result.results[i];
            for (let j = 0; j < res.prelevements.length && !abort; j++) {
                const p = res.prelevements[j];
                for (let k = 0; k < p.essais.length && !abort; k++) {
                    const e = p.essais[k];
                    for (let f = 0; f < e.sousEssais.length && !abort; f++) {
                        const ss = e.sousEssais[f];
                        if (ss.tamis && ss.tamis.length > 0) {
                            ssGran = ss;
                            esGran = e;
                            abort = true;
                        }
                    }
                }
            }
        }
        if (esGran) {
            this.essaiGranulo = await this.MesuresService.getMesureCourbeGranuloById(esGran.id);
        }
    }

    createGraph() {
        const res = [];
        for (let ix = 0; ix < this.result.results.length; ix++) {
            const table = this.result.results[ix];
            var chart = {};
            chart.series = [];
            chart.data = [];
            chart.labels = [];
            chart.datasetOverride = [];
            for (let i = 0; i < table.traitements.length; i++) {
                const traitement = table.traitements[i];
                const tamis = traitement.valeurs.filter((x) => x.tamis);
                if (tamis.length == 0) {
                    continue;
                }
                if (chart.labels.length == 0) {
                    chart.labels = tamis.map((x) => {
                        const res = parseFloat(x.ouverture);
                        if (Number.isNaN(res)) {
                            return null;
                        }
                        return res;
                    });
                }
                let hidden = true;
                if (this.calculStat.includes(traitement.code)) {
                    hidden = false;
                }
                chart.datasetOverride[i] = {
                    hidden,
                    tension: this.essaiGranulo && this.essaiGranulo.lisser ? 0.4 : 0
                };
                chart.series.push(traitement.calculStat);
                chart.data.push(
                    tamis.map((x, index) => {
                        const res = parseFloat(x.valeurs);
                        if (Number.isNaN(res)) {
                            return null;
                        }
                        return {
                            y: res,
                            x: chart.labels[index]
                        };
                    })
                );
            }
            chart = { ...chart, ...this.createChartOptions(0, 100, 10, chart.labels) };
            if (this.chart.length > 0 && this.chart[ix]) {
                chart.open = this.chart[ix].open;
            }
            this.createChartWithEssaiGranulo(chart, table);
            res.push(chart);
        }
        this.chart = res;
    }

    createChartWithEssaiGranulo(chart, table) {
        if (this.essaiGranulo && this.essaiGranulo.listeIndicesReferences) {
            for (let i = 0; i < this.essaiGranulo.listeIndicesReferences.length; i++) {
                const inRef = this.essaiGranulo.listeIndicesReferences[i];
                const allTrace = this.essaiGranulo.listeIndicesReferences[i].listeTracesReferences
                    .concat(this.essaiGranulo.listeTracesComposants)
                    .concat(this.essaiGranulo.listeTracesResultats);
                for (let j = 0; j < allTrace.length; j++) {
                    const trace = allTrace[j];
                    const idx1 = table.traitements.findIndex((x) => x.code === trace.formule1);
                    const idx2 = table.traitements.findIndex((x) => x.code === trace.formule2);
                    if (idx1 > -1) {
                        chart.datasetOverride[idx1] = this._createDataset(chart.datasetOverride[idx1], trace);
                        if (idx2 > -1) {
                            chart.datasetOverride[idx2] = this._createDataset(chart.datasetOverride[idx2], trace);
                            chart.datasetOverride[idx1].fill = idx2;
                        }
                    }
                }
            }
        }
        /* let vss = table.traitements.findIndex((x) => x.code === '@VSS');
        let vsi = table.traitements.findIndex((x) => x.code === '@VSI');
        if (vss > -1 && vsi > -1) {
            chart.datasetOverride[vss].fill = vsi;
        } */
        return chart;
    }

    _createDataset(dataset, trace) {
        if (!trace.couleurPointAuto) {
            dataset.pointBorderColor = this._hslToHex(trace.couleurPoint.hue, trace.couleurPoint.saturation, trace.couleurPoint.luminosity);
            dataset.pointBackgroundColor = this._hslToHex(trace.couleurPoint.hue, trace.couleurPoint.saturation, trace.couleurPoint.luminosity);
        }

        if (!trace.couleurTraceAuto) {
            dataset.borderColor = this._hslToHex(trace.couleurTrace.hue, trace.couleurTrace.saturation, trace.couleurTrace.luminosity);
        }

        if (!trace.couleurFuseauAuto) {
            dataset.backgroundColor = this._hslToHex(trace.couleurFuseau.hue, trace.couleurFuseau.saturation, trace.couleurFuseau.luminosity);
        }

        dataset.borderWidth = trace.epaisseurTrace;
        dataset.pointBorderWidth = trace.taillePoint;
        dataset.pointStyle = this._getStylePoint(trace.formePoint);

        return dataset;
    }

    createChartOptions(min = 0, max = 100, step = 10, tamis) {
        return {
            open: false,
            seeOption: false,
            type: 'line',
            legend: {
                display: true,
                position: this.essaiGranulo ? this.getPositionLegende(this.essaiGranulo.positionLegende) : 'bottom'
            },
            options: {
                scales: {
                    yAxes: [
                        {
                            type: 'linear',
                            scaleLabel: {
                                labelString: this.$translate.instant('SYNTHENTS.GRAPH_PASSANT'),
                                display: true
                            },
                            display: this.essaiGranulo ? this.essaiGranulo.afficheOrdonnees : true,
                            position: 'left',
                            ticks: {
                                max: max,
                                min: min,
                                step: step
                            }
                        }
                    ],
                    xAxes: [
                        {
                            type: 'logarithmic',
                            scaleLabel: {
                                labelString: this.$translate.instant('SYNTHENTS.GRAPH_TAMIS'),
                                display: true
                            },
                            display: this.essaiGranulo ? this.essaiGranulo.afficheAbscisses : true,
                            position: 'bottom',
                            autoSkip: false,
                            ticks: {
                                max: Math.max(...tamis),
                                min: Math.min(...tamis),
                                callback: (value, index, values) => {
                                    return value.toString(10);
                                }
                            },
                            afterBuildTicks: function (chart) {
                                chart.ticks = tamis;
                            }
                        }
                    ]
                }
            }
        };
    }

    getPositionLegende(position) {
        switch (position) {
            case 0: //'pas_legende',
                return '';
            case 1: //'droite',
                break;
            case 2: //'gauche',
                return 'left';
            case 3: //'haut',
                return 'top';
            case 4: //'bas'
                return 'bottom';
        }
        return 'right';
    }

    _getStylePoint(style) {
        let stylePoint = 'circle;';
        switch (style) {
            case 0:
                stylePoint = '';
                break;
            case 1:
                stylePoint = 'circle';
                break;
            case 2:
                stylePoint = 'cross';
                break;
            case 3:
                stylePoint = 'crossRot';
                break;
            case 4:
                stylePoint = 'dash';
                break;
            case 5:
                stylePoint = 'line';
                break;
            case 6:
                stylePoint = 'rect';
                break;
            case 7:
                stylePoint = 'rectRounded';
                break;
            case 8:
                stylePoint = 'rectRot';
                break;
            case 9:
                stylePoint = 'star';
                break;
            case 10:
                stylePoint = 'triangle';
                break;
        }
        return stylePoint;
    }

    toggleGraph(chart) {
        chart.open = !chart.open;
    }

    log(data) {
        console.log(data);
    }

    /* deletePlv($pIndex, index, id, log) {
        this.excluded.push(id);
        this.result.results.splice($pIndex, 1);
        this.result.table[$pIndex].body.splice(index, 1);
    } */

    // Arrow fx for bind
    deletePlv = (row, col) => {
        const id = row.entity.id;
        const idx = this.result.results.findIndex((x) => x.grid.body.some((b) => b === row.entity));
        const idx2 = idx > -1 && this.result.results[idx].grid.body.findIndex((x) => x === row.entity);
        if (typeof idx2 === 'number' && idx2 > -1) {
            this.excluded.push(id);
            this.result.results[idx].grid.body.splice(idx2, 1);
        }
    };

    /* updatePlv(id) {
        this.$state.go('prelevements.edit-saisie', { id: id, idFamille: 10 });
    } */

    // Arrow fx for bind
    updatePlv = (row, col) => {
        const id = row.entity.id;
        this.$state.go('prelevements.edit-saisie', { id: id, idFamille: 10 });
    };

    updateCell(value, idSousEssai, idTamis, idPrelevement) {
        if (!value || value === '') {
            return;
        }
        const idx = this.criterions.findIndex(
            (x) =>
                x.model === (idTamis && idTamis > 0 ? 'Tamis' : 'SousEssai') &&
                x.property1 === idPrelevement &&
                x.property2 === (idTamis && idTamis > 0 ? idTamis : idSousEssai)
        );
        if (idx > -1) {
            this.criterions[idx] = {
                model: idTamis && idTamis > 0 ? 'Tamis' : 'SousEssai',
                property1: idPrelevement,
                property2: idTamis && idTamis > 0 ? idTamis : idSousEssai,
                value: value
            };
        } else {
            this.criterions.push({
                model: idTamis && idTamis > 0 ? 'Tamis' : 'SousEssai',
                property1: idPrelevement,
                property2: idTamis && idTamis > 0 ? idTamis : idSousEssai,
                value: value
            });
        }
    }

    async recalculate() {
        this.notification.info('SYNTHENTS.CALC_IN_PROGRESS', null, {
            timeOut: 0,
            extendedTimeOut: 0,
            closeButton: false
        });
        const result = await this.generate();
        this.result = result;
        this.createTable(this.result.results);
        this.createGraph();
        if (this.gridApi) {
            this.gridApi.core.refresh();
        }
        this.notification.clear();
    }

    _hslToHex(h, s, l) {
        l /= 100;
        const a = (s * Math.min(l, 1 - l)) / 100;
        const f = (n) => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color)
                .toString(16)
                .padStart(2, '0'); // convert to Hex and prefix "0" if needed
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    }

    openSynthese() {
        this.$state.go('synthese.synthese.edit', { id: this.synthese.id, synthese: true });
    }
}
