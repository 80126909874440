import validatorBuilder from 'validator-builder';

let referentielValidator = null;

export default class Referentiel {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.dateDebut = data.dateDebut;
        this.dateFin = data.dateFin;
        this.idType = data.idType;
        this.idTypeProduit = data.idTypeProduit;
        this.famille = data.famille;
        this.familleCode = data.familleCode;
        this.familleId = data.familleId;
        this.caracteristiques = data.caracteristiques || [];
        this.caracteristiqueTypeProduits = data.caracteristiqueTypeProduits || [];
        this.caracteristiqueProduits = data.caracteristiqueProduits || [];
        this.mesures = data.mesures || [];
    }
    isValid() {
        referentielValidator = referentielValidator || validatorBuilder.getInstanceFor('Referentiel');
        const validationResults = referentielValidator.validate(this);
        return validationResults.isValid;
    }
}
