import SyntheseEntityResistanceTabResumeController from './syntheseentities.resistance.tabresume.controller';

export default {
    template: require('./syntheseentities.resistance.tabresume.html'),
    controller: SyntheseEntityResistanceTabResumeController,
    bindings: {
        id: '<',
        model: '<',
        resultat: '<',
        controle: '<',
        produit: '<',
        famille: '<',
        type: '@'
    }
};
