NomenclatureEnteteValidator.$inject = ['validator'];

export default function NomenclatureEnteteValidator(validator) {
	const instance = new validator();
	instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('code').length(1, 15).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
	instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('libelle').length(1, 200).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

	return instance;
}
