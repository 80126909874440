FormulesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'FormuleService',
    'notification',
    '_',
    '$filter',
    'DataShareService',
    'MassiaApplicationService',
    '$transitions'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function FormulesController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    FormuleService,
    notification,
    _,
    $filter,
    DataShareService,
    MassiaApplicationService,
    $transitions
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    let selectedFormuleId = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.formules = [];
    vm.savedPagination = {};
    vm.savedPagination.entity = 'pagination';
    vm.DataShareService = DataShareService;

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.selectFormule = selectFormule;
    vm.isFormuleSelected = isFormuleSelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadFormules = loadFormules;
    vm.deleteFormule = deleteFormule;

    async function init() {
        //écouter l'event lié au changement de route
        //une fois on change l'url, et on arrive sur la page "formules.list", on désélectionne la ligne sélectionnée (s'il y en a)
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        //$transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        // getProduitsComposables();
    }

    init();

    // async function getProduitsComposables() {
    //     try {
    //         vm.formules = await FormuleService.getProduitsComposables();
    //         // vm.produits = await prepareListeElementsLies(produitsSelectionnables);
    //     } catch (err) {
    //         if (err.data) notification.error(err.data);
    //         else throw err
    //     }
    // }

    function stateChangeSuccessFunc(event, toState) {
        if (!$state.params.id) {
            vm.selectFormule();
        } else if ($state.params.id) {
            vm.selectFormule({ id: parseInt($state.params.id) });
        }
    }

    function selectFormule(formule) {
        if (formule && formule.id) {
            //si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedFormuleId, et charger la vue détail pour le formule sélectionné
            if (vm.selectedFormuleId !== formule.id) {
                vm.selectedFormuleId = formule.id;
                showDetail(formule.id);
            } else {
                //si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
                vm.selectedFormuleId = undefined;
                closeDetail();
            }
        }
    }

    function isFormuleSelected(formule) {
        return formule && formule.id && vm.selectedFormuleId === formule.id;
    }

    function showDetail(formuleId) {
        $state.go('formules.list.detail', { id: formuleId });
    }

    function closeDetail() {
        $state.go('formules.list');
    }

    async function loadFormules(tableState) {
        startLoading();

        // gestion de la sauvegarde et de la restitution de la pagination
        const objectToManage = {};
        objectToManage.tableState = tableState;
        objectToManage.savedPagination = vm.savedPagination;
        objectToManage.itemsByPageOptions = vm.itemsByPageOptions;
        objectToManage.itemsByPage = vm.itemsByPage;
        const resultObject = await vm.DataShareService.managePaginationContextuelle(objectToManage);
        if (resultObject) {
            tableState = resultObject.tableState;
            vm.savedPagination = resultObject.savedPagination;
            vm.itemsByPageOptions = resultObject.itemsByPageOptions;
            vm.itemsByPage = resultObject.itemsByPage;
        }

        //quand on rafraîchit la grille, on revient sur "formules.list", et on ferme la vue détail d'un formule
        //sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
        if ($state && $state.current && $state.current.name !== 'formules.list') {
            $state.go('formules.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.formules = [];

        try {
            const data = await FormuleService.getProduitsComposables(filters, sorts, pagination, MassiaApplicationService.getApplication());
            vm.formules = data.items;
            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteFormule(formule) {
        if (formule && formule.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('PRODUITS.DELETE.TITLE', { code: formule.libelle }),
                modalMsg: $translate.instant('PRODUITS.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await FormuleService.deleteFormuleById(formule.id);
                    notification.success($translate.instant('PRODUITS.DELETE.SUCCESS'));

                    selectedFormuleId = undefined;

                    // si jamais la vue détail est affichée,
                    // on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
                    if (vm.params.id) {
                        vm.state.go('formules.list');
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadFormules();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
