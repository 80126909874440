import RecompositionController from './recomposition.controller';

export default {
    bindings: {
        synthese: '<',
        loading: '='
    },
    controller: RecompositionController,
    template: require('./recomposition.tpl.html')
};
