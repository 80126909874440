import { getNatureNamesByValues } from '../../../caracteristiques/services/natures.enum';

export default class LieuDetailController {
    static $inject = ['$stateParams', '$state', 'LieuxService', 'notification', 'LieuxCommunicationService', 'MassiaApplicationService'];

    constructor($stateParams, $state, LieuxService, notification, LieuxCommunicationService, MassiaApplicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.LieuxService = LieuxService;
        this.notification = notification;
        this.LieuxCommunicationService = LieuxCommunicationService;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        this.loading = false;
        this.lieu = {};
        this.caracteristiques = [];

        //par défaut, on ouvre l'onglet "Entête"
        this.ongletOpen = {
            isEnteteOpen: true,
            isCaracteristiqueOpen: false,
            isNiveauxHierarchiqueOpen: false
        };

        this.initLieu();
    }

    async initLieu() {
        this.lieu = {
            id: this.$stateParams.id
        };

        if (this.lieu.id) {
            this.startLoading();

            try {
                // on ajoute await afin de ne pas passer trop rapidement dans le finally
                // et ainsi on garde le loading le temps nécessaire

                this.lieu.entete = await this.LieuxService.getLieuEnteteById(this.lieu.id);
                this.lieu.caracteristiques = await this.LieuxService.getLieuValeursCaracteristiquesById(this.lieu.id);
                this.lieu.niveaux = await this.LieuxService.getLieuValeursNiveauxById(this.lieu.id);

                this.setCaracteristiques(this.lieu.caracteristiques);
                await this.loadNiveaux();
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListLieu();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListLieu();
        }
    }

    async getLieuEntete(lieuId) {
        this.lieu.entete = await this.LieuxService.getLieuEnteteById(lieuId);
    }

    async setCaracteristiques(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiques = [];
        caracteristiques.forEach((c) => {
            this.caracteristiques.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    async loadNiveaux() {
        this.startLoading();

        try {
            this.niveaux = await this.LieuxService.getLieuxNiveaux();
            this.elements = await this.LieuxService.getLieuxElements();
        } catch (ex) {
            this.notification.error(ex);
        } finally {
            this.stopLoading();
        }
    }

    goListLieu() {
        if (this.MassiaApplicationService.getApplication() == 'gestion') {
            this.$state.go('lieux.list', {}, { reload: true });
        } else {
            this.$state.go('lieux-donnee.list', {}, { reload: true });
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
