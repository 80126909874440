import validatorBuilder from 'validator-builder';
import BanqueEntete from './entete/banque.entete.model';

let banqueValidator = null;

export default class Banque {
    constructor(data = {}) {
        this.id = data.id;
        this.entete = new BanqueEntete(data.entete);
    }

    isValid() {
        banqueValidator = banqueValidator || validatorBuilder.getInstanceFor('Banque');
        const validationResults = banqueValidator.validate(this);
        return validationResults.isValid;
    }
}
