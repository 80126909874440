import ApiService from '../../app/services/api.service';
import { Injectable } from '../decorators';
import { BaseHttpService } from '../interface/MassiaHttpService.interface';

@Injectable('SiteService')
export class SiteService extends BaseHttpService<any, any, any, any, any> { //TODO Changer les types any avec de vrais interface 
    /* @ngInject */
    constructor(ApiService: ApiService) {
        super(ApiService);
        this.url = 'v2/massia/sites';
    }
}
