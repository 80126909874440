import FTPArchiveeService from './../../../../laboratoire/ftp-archivee/services/ftparchivee.service';

export default class ImportFtpModalController {
    //#region Déclaration type
    selectedFtp: any = {};
    FTPArchiveeService: FTPArchiveeService;
    modalInstance: any;
    lstFTP: any;
    //#endregion

    /* @ngInject */
    constructor(FTPArchiveeService: FTPArchiveeService, $filter: any) {
        this.FTPArchiveeService = FTPArchiveeService;
    }

    async $onInit() {
        this.lstFTP = await this.FTPArchiveeService.getAllFTPArchivee([], [], { skip: 0, take: 0 });
    }

    async selectFtp(data: any): Promise<void> {
        if (data && data.id) {
            this.selectedFtp = await this.FTPArchiveeService.getFTPArchiveeById(data.id);
        }
    }

    appliquer() {
        this.modalInstance.close(this.selectedFtp);
    }

    async cancel() {
        this.modalInstance.close();
    }
}
