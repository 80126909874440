export default class FormulesService {
    static $inject = [];

    constructor() {}

    strEnDouble(str) {
        if (typeof str === 'string') {
            return parseFloat(str.replace(',', '.'));
        } else if (typeof str === 'number') {
            return str;
        }
        return NaN;
    }
}
