(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.treeview')
        .controller('AcTreeviewController', AcTreeviewController);
    AcTreeviewController.$inject = [
        '$scope',
        '$timeout',
        '$q',
        '$translate',
        '_',
        'notification',
        'NodeModel',
        'AcTreeviewCommunicationService'
    ];

    function AcTreeviewController($scope,
                                  $timeout,
                                  $q,
                                  $translate,
                                  _,
                                  notification,
                                  NodeModel,
                                  AcTreeviewCommunicationService) {
        var vm = this;
        var treeviewName, treeviewService, treeviewConfig, treeviewFunctions, functionsScope;
        var dragInternallyDisabled;
        var itemSelected, timer;

        vm.items = [];
        vm.config = {};
        vm.service = {};
        vm.renamedItemId = {};
        vm.isLoading = [];
        vm.searchTerm = undefined;
        // méthodes publiques
        /* ihm */
        vm.isContextMenuDisabled = isContextMenuDisabled;
        vm.displayNode = displayNode;
        vm.isLeafType = isLeafType;
        vm.isFolderType = isFolderType;
        vm.dragDropDisabled = dragDropDisabled;
        vm.canDrop = canDrop;
        vm.dropDisabled = dropDisabled;
        vm.getStyle = getStyle;
        vm.isSelected = isSelected;
        vm.disableDrag = disableDrag;
        vm.enableDrag = enableDrag;
        /* actions */
        vm.select = select;
        vm.expandAll = expandAll;
        vm.collapseAll = collapseAll;
        vm.getNodeIcon = getNodeIcon;
        vm.toggle = toggle;
        vm.openNode = openNode;
        vm.dropNode = dropNode;
        vm.canDisplayAction = canDisplayAction;
        vm.search = search;
        vm.resetSearch = resetSearch;
        /* create root */
        vm.canCreateRootNode = canCreateRootNode;
        vm.createRootNode = createRootNode;
        /* renaming */
        vm.canRenameNode = canRenameNode;
        vm.renameNode = renameNode;
        vm.confirmRename = confirmRename;
        vm.cancelRename = cancelRename;
        vm.clearRename = clearRename;
        // events
        var onDestroy = $scope.$on('$destroy', dispose);

        var onStateChangeSuccess = $scope.$on('$stateChangeSuccess', stateChangeSuccess);

        init();

        function init() {
            treeviewConfig = $scope.acTreeview;
            if ($scope.acTreeviewService) {
                treeviewService = buildTreeviewService($scope.acTreeviewService);
            }
            if (treeviewConfig && treeviewService) {
                // on set le nom s'il est disponible
                if (treeviewConfig.name) {
                    treeviewName = treeviewConfig.name;
                }
                // sinon on en met un par défaut
                else {
                    treeviewConfig.name = treeviewName = Math.floor((Math.random() * 10000) + 1);
                }

                functionsScope = treeviewConfig.functionsScope || this;

                AcTreeviewCommunicationService.setTreeviewConfig(treeviewName, treeviewConfig);
                AcTreeviewCommunicationService.setTreeviewService(treeviewName, treeviewService);
                AcTreeviewCommunicationService.registerNodeRefreshFunction(treeviewName, refresh);
                AcTreeviewCommunicationService.registerTreeviewReloadFunction(treeviewName, loadRoot);

                vm.config = treeviewConfig;
                treeviewFunctions = treeviewConfig.functions || {};

                // on sélectionne le premier noeud éventuellement, sinon on charge tous les éléments root
                if (treeviewConfig.autoSelectNode) {
                    loadAndSelect(treeviewConfig.autoSelectNode);
                }
                else {
                    loadRoot();
                }
            }
            else {
                vm.readme = buildReadme();
                treeviewConfig = treeviewService = {};
            }
        }

        //endregion Initialisation

        //region Méthodes publiques
        function isLeafType(type) {
            return treeviewConfig.leafTypes
                && treeviewConfig.leafTypes.indexOf(type) !== -1;
        }

        function isFolderType(type) {
            return treeviewConfig.folderTypes
                && treeviewConfig.folderTypes.indexOf(type) !== -1;
        }

        function isContextMenuDisabled(type) {
            return treeviewConfig.readOnly
                || isLeafType(type)
                || (treeviewConfig.contextMenuDisabled
                && treeviewConfig.contextMenuDisabled.indexOf(type) !== -1);
        }

        function isLegalTreeviewDiagram(type) {
            return treeviewConfig.treeDiagram
                && treeviewConfig.treeDiagram.indexOf(type) !== -1;
        }

        function displayNode(item) {
            var display = true;
            // est-ce une feuille et doit-on masquer les feuilles ?
            if (item && treeviewConfig.hideLeaf && isLeafType(item.type) === true) {
                display = false;
            }
            // le type est-il dans l'arborescence autorisée ?
            else if (item && isLegalTreeviewDiagram(item.type) === false) {
                display = false;
            }

            return display;
        }

        function dragDropDisabled(item) {
            var disableCustom = false;
            if (angular.isFunction(treeviewFunctions.dragDropDisabled)) {
                disableCustom = treeviewFunctions.dragDropDisabled.call(functionsScope, item);
            }
            return treeviewConfig.readOnly
                || treeviewConfig.dropDisabled
                || vm.renamedItemId.length > 0
                || dragInternallyDisabled
                || disableCustom;
        }

        function disableDrag() {
            dragInternallyDisabled = true;
        }

        function enableDrag() {
            dragInternallyDisabled = false;
        }

        function canDrop(source, target) {
            if (source === target || isChildOf(source, target)) {
                return false;
            }

            if (angular.isFunction(treeviewFunctions.canDrop)) {
                return treeviewFunctions.canDrop.call(functionsScope, source, target);
            }
            // s'il y a une arborescence définie, on respecte l'ordre
            else if (treeviewConfig.treeDiagram && treeviewConfig.treeDiagram.length > 0) {
                var itemTypePosition = treeviewConfig.treeDiagram.indexOf(source.type);
                var targetTypePosition = treeviewConfig.treeDiagram.indexOf(target.type);

                return itemTypePosition === (targetTypePosition + 1);
            }
            else {
                return true;
            }
        }

        function dropDisabled(source, target) {
            if (source && !treeviewConfig.canDropOnLeaf && treeviewConfig.leafTypes) {
                return treeviewConfig.leafTypes.indexOf(target.type) !== -1;
            }

            return false;
        }

        function getStyle(item) {
            if (angular.isFunction(treeviewFunctions.getStyle)) {
                return treeviewFunctions.getStyle.call(functionsScope, item);
            }
            else {
                return '';
            }
        }

        function isSelected(item) {
            return item
                && itemSelected
                && itemSelected.id === item.id
                && itemSelected.type === item.type;
        }

        function loadRoot(disableExpand) {
            startRootLoading();
            vm.items = [];
            vm.searchTerm = undefined;
            treeviewService.getRootNodes(disableExpand)
                .then(function (data) {
                    vm.items = data;
                    if (data && data[0] && treeviewConfig.autoSelectFirstNode) {
                        select(data[0]);
                    }
                })
                .catch(notifyError)
                .finally(stopRootLoading);
        }

        function loadAndSelect(node) {
            startRootLoading();
            vm.items = [];
            vm.searchTerm = undefined;
            treeviewService.getSelectedNode(node)
                .then(function (data) {
                    vm.items = data;
                    var identifier = {id: node.id};
                    /* s'il y a un type on le renseigne pour la recherche
                     * sinon on ne l'ajoute pas car cela reviendrait à dire
                     * "je veux les noeuds dont le type existe et est spécifiquement égal à undefined"
                     */
                    if (node && node.type !== undefined) {
                        identifier.type = node.type;
                    }
                    var selectedNode = _.findDeep(data, identifier);

                    if (selectedNode) {
                        select(selectedNode);
                        // on ouvre le noeud sélectionné
                        openNode(selectedNode);
                    }
                })
                .catch(notifyError)
                .finally(stopRootLoading);
        }

        function dropNode(item, target, parent) {
            if (angular.isFunction(treeviewFunctions.dropNode)) {
                treeviewFunctions.dropNode.call(functionsScope, item, target, parent, onDrop);
            }
            else {
                onDrop(item, target, parent);
            }
        }

        function onDrop(item, target, parent) {
            var deferred = $q.defer();

            if (item.parentId === target.id && treeviewTypeComparison(parent, target)) {
                notification.error('TREEVIEW_VALIDATION_CANNOT_DROP_IN_SAME_PARENT');
                deferred.reject();
            }
            else {
                var promise;
                setNodeLoading(item, true);
                setNodeLoading(target, true);
                setNodeLoading(parent, true);
                if (item && isLeafType(item.type)) {
                    // si c'est un type leaf
                    promise = treeviewService.changeLeafParent(item, target.id, target, parent);
                }
                else {
                    // si c'est un type node
                    promise = treeviewService.changeNodeParent(item, target.id, target, parent);
                }
                promise
                    .then(function (data) {
                        deferred.resolve(data);
                        if (parent) {
                            // on rafraichit le parent pour faire disparaitre l'item drag&dropé
                            refresh(parent.id, true, parent)
                                .then(function () {
                                    // on rafraichit la cible pour faire apparaitre l'item drap&dropé
                                    refresh(target.id, true, target)
                                        .then(function () {
                                            // si la cible est le parent du parent de l'item, on réouvre le parent
                                            if (parent.parentId === target.id && treeviewTypeComparison(parent, target)) {
                                                refresh(parent.id, true, parent);
                                            }
                                        })
                                        .finally(function () {
                                            setNodeLoading(item, false);
                                            setNodeLoading(target, false);
                                            setNodeLoading(parent, false);
                                        });
                                })
                                .catch(function () {
                                    setNodeLoading(item, false);
                                    setNodeLoading(target, false);
                                    setNodeLoading(parent, false);
                                });
                        }
                    })
                    .catch(function (response) {
                        deferred.reject(response);
                        setNodeLoading(item, false);
                        setNodeLoading(target, false);
                        setNodeLoading(parent, false);
                        notifyError(response);
                    });
            }

            return deferred.promise;
        }

        function setNodeLoading(node, loading) {
            if (node) {
                vm.isLoading[node.type + '.' + node.id] = loading;
            }
        }

        function select(item) {
            clearRename();
            clearSelected();
            selectItem(item);

            // on sauvegarde la sélection pour la transmettre à d'autres controllers
            if (item && !isLeafType(item.type)) {
                timer = $timeout(function () {
                    AcTreeviewCommunicationService.setSelectedNode(treeviewName, item);
                }, 60);
            }

            // si une fonction de select est renseignée, on l'exécute
            if (angular.isFunction(treeviewFunctions.selectNode)) {
                treeviewFunctions.selectNode.call(functionsScope, item);
            }
        }

        function expandAll() {
            startRootLoading();
            vm.items = [];
            treeviewService.getAllNodes()
                .then(function (data) {
                    vm.items = data;
                })
                .catch(notifyError)
                .finally(stopRootLoading);
        }

        function collapseAll() {
            clearRename();
            clearSelected();
            loadRoot(true);
        }

        function getNodeIcon(item) {
            if (angular.isFunction(treeviewFunctions.getNodeIcon)) {
                return treeviewFunctions.getNodeIcon.call(functionsScope, item);
            }
            else {
                return item.expanded ? treeviewConfig.iconOpened || 'glyphicon-folder-open' : treeviewConfig.iconClosed || 'glyphicon-folder-close';
            }
        }

        function toggle(item) {
            clearRename();
            var canToggle = true;
            if (angular.isFunction(treeviewFunctions.canToggleNode)) {
                canToggle = treeviewFunctions.canToggleNode.call(functionsScope, item);
            }
            if (canToggle) {
                if (item.expanded) {
                    closeNode(item);
                }
                else {
                    openNode(item);
                }
            }
        }

        function openNode(item, isRecursive) {
            if (item && !isLeafType(item.type) && !item.expanded) {
                setNodeLoading(item, true);
                item.expanded = true;
                treeviewService.getChildrenByNodeId(item.id, item)
                    .then(function (data) {
                        item.items = data;
                        if (isRecursive) {
                            _.forEach(item.items, function (i) {
                                openNode(i, true);
                            });
                        }
                    })
                    .catch(notifyError)
                    .finally(function () {
                        setNodeLoading(item, false);
                    });
            }
        }

        function closeNode(item) {
            if (!treeviewConfig.forbidNodeClosure) {
                item.expanded = false;
                item.items = [];
            }
        }

        function canDisplayAction(action, item) {
            // si la fonction existe on l'execute
            if (action && angular.isFunction(action.canDisplayFunction)) {
                return action.canDisplayFunction(item);
            }
            else {
                // sinon on affiche l'action par défaut
                return true;
            }
        }

        function canRenameNode() {
            // si une fonction de est renseignée, on l'exécute
            if (angular.isFunction(treeviewFunctions.canRenameNode) && itemSelected) {
                return treeviewFunctions.canRenameNode.call(functionsScope, itemSelected);
            }
            else {
                return true;
            }
        }

        function renameNode() {
            if (itemSelected) {
                vm.renamedItemId = itemSelected.type + '.' + itemSelected.id;
                itemSelected.editLabel = itemSelected.label;
            }
        }

        function confirmRename() {
            var node = new NodeModel.Node(itemSelected);
            node.label = itemSelected.editLabel;

            if (!node.isValid() && node.validationResults().errors[0]) {
                notification.error(node.validationResults().errors[0].errorMessage);
            }
            else {
                // s'il y a un parent
                if (itemSelected.parentId && itemSelected.parentId > 0) {
                    // on récupère les noeud du même niveau que le noeud renommé (les enfants de son parent)
                    // pour vérifier que le nom est unique au sein du parent
                    treeviewService.getChildrenByNodeId(itemSelected.parentId, itemSelected)
                        .then(function (data) {
                            var match = _.find(data, {label: node.label});
                            if (match && match.id != node.id) {
                                var errorMsg = $translate.instant('TREEVIEW_VALIDATION_UNIQUE_LABEL', {label: node.label});
                                notification.error(errorMsg);
                            }
                            else {
                                treeviewService.renameNode(node)
                                    .then(function () {
                                        itemSelected.label = itemSelected.editLabel;
                                        refresh(itemSelected.id, itemSelected.expanded, itemSelected);
                                        select(itemSelected);
                                    })
                                    .catch(notifyError);
                            }
                        })
                        .catch(notifyError);
                }
                // sinon on renomme le noeud
                else {
                    treeviewService.renameNode(node)
                        .then(function () {
                            itemSelected.label = itemSelected.editLabel;
                            refresh(itemSelected.id, itemSelected.expanded, itemSelected);
                            select(itemSelected);
                        })
                        .catch(notifyError);
                }
            }
        }

        function cancelRename() {
            clearRename();
            clearSelected();
        }

        function clearRename() {
            vm.renamedItemId = {};
        }

        function search(term) {
            if (vm.searchTerm && vm.searchTerm.length > 0) {
                startRootLoading();
                vm.items = [];
                treeviewService.search(term)
                    .then(function (data) {
                        vm.items = data;
                    })
                    .catch(notifyError)
                    .finally(stopRootLoading);
            }
        }

        function resetSearch() {
            if (vm.searchTerm !== undefined) {
                vm.searchTerm = '';
                loadRoot();
            }
        }

        function canCreateRootNode() {
            return angular.isFunction(treeviewFunctions.createRootNode)
                && angular.isFunction(treeviewFunctions.canCreateRootNode)
                && treeviewFunctions.canCreateRootNode.call(functionsScope);
        }

        function createRootNode() {
            treeviewFunctions.createRootNode.call(functionsScope);
        }

        //endregion Méthodes publiques

        //region Méthodes privées
        function refresh(id, expand, node) {
            return treeviewService.getNode(id, node)
                .then(function (data = {}) {
                    if (data && data.hasOwnProperty('id')) {
                        let item = null;

                        // on cherche l'item dans l'arborescence complète
                        if (treeviewConfig.typeComparisonEnabled === true) {
                            // on cherche par type si spécifié
                            item = _.findDeep(vm.items, {id: data.id, type: data.type});
                        } else {
                            // sinon le couple id/parentId est unique
                            item = _.findDeep(vm.items, {id: data.id, parentId: data.parentId});
                        }

                        if (item) {
                            angular.extend(item, data);

                            if (expand || item.expanded) {
                                item.expanded = true;

                                setNodeLoading(item, true);
                                return treeviewService.getChildrenByNodeId(item.id, item)
                                    .then(function (data) {
                                        item.items = data;
                                    })
                                    .catch(notifyError)
                                    .finally(function () {
                                        setNodeLoading(item, false);
                                    });
                            }
                        }
                    }
                });
        }

        function clearSelected() {
            itemSelected = {};
        }


        function selectItem(item) {
            itemSelected = item;
        }

        function isChildOf(item, target) {
            var ret = false;

            if (item) {
                _.forEach(item.items, function (i) {
                    ret = ret || (i.id == target.id && i.type == target.type) || isChildOf(i, target);
                });
            }

            return ret;
        }

        function startRootLoading() {
            vm.isLoading['root'] = true;
        }

        function stopRootLoading() {
            vm.isLoading['root'] = false;
        }

        function buildTreeviewService(scopeService) {
            var service = {};
            service.getAllNodes = scopeService.getAllNodes;
            service.getRootNodes = scopeService.getRootNodes;
            service.getSelectedNode = scopeService.getSelectedNode ? scopeService.getSelectedNode : scopeService.getRootNodes;
            service.getNode = scopeService.getNode;
            service.getChildrenByNodeId = scopeService.getChildrenByNodeId;
            service.changeNodeParent = scopeService.changeNodeParent;
            service.changeLeafParent = scopeService.changeLeafParent;
            service.createNode = scopeService.createNode;
            service.updateNode = scopeService.updateNode;
            service.renameNode = scopeService.renameNode;
            service.deleteNode = scopeService.deleteNode;
            service.getNodeTypes = scopeService.getNodeTypes;
            service.search = scopeService.search;

            return service;
        }

        function buildReadme() {
            var readme = {};
            readme.msg = 'TREEVIEW_README';
            readme.usage = '<div ac-treeview="config" ac-treeview-service="service"></div>';
            readme.help = {
                rootType: 'Root',
                rootTypeIcon: 'flowchart',
                iconOpened: 'icone de dossier ouvert',
                iconClosed: 'icone de dossier fermé',
                orderByFunction: 'func',
                leafTypes: ['User'],
                leafTypesIcons: {'User': 'user'},
                hideLeaf: 'bool (masque les feuilles définies au dessus)',
                disableNodeTypes: 'bool (empeche de changer le type d\'un noeud)',
                treeDiagram: "display only those types: ['Root', 'Service', 'Geography']",
                dropDisabled: 'bool (empêche le drag&drop)',
                displayTreeActions: 'bool (affiche les actions générales - créer un root, ouvrir tout, fermer tout, disable drag&drop)',
                forbidNodeClosure: 'bool (interdit la fermeture des noeuds - par défaut false',
                readOnly: 'bool (empêche toute action)',
                searchDisabled: 'bool (désactive la recherche)',
                configActions: [{
                    function: 'func',
                    canDisplayFunction: 'func',
                    name: 'function name (ajoutée au menu contextuel)'
                }],
                nodeActions: [{
                    function: 'func',
                    canDisplayFunction: 'func',
                    name: 'function name (ajoutée au menu contextuel)'
                }],
                templateUrl: 'path/to/custom/template',
                autoExpand: 'bool (permet l\'ouverture d\'un noeud contenant des items)',
                canDropOnLeaf: 'bool (permet le drop dur un noeud feuille)',
                functions: {
                    stateChangeSuccess: 'func',
                    getStyle: 'func',
                    dragDropDisabled: 'func',
                    getNodeIcon: 'func',
                    canToggleNode: 'func',
                    canDrop: 'func',
                    dropNode: 'func',
                    selectNode: 'func',
                    canRenameNode: 'func',
                    canCreateRootNode: 'func',
                    createRootNode: 'func',
                    canRefreshNode: 'func',
                    refreshNode: 'func',
                    canEditNode: 'func',
                    editNode: 'func',
                    canCreateNode: 'func',
                    createNode: 'func',
                    canRemoveNode: 'func',
                    removeNode: 'func'
                },
                labels: { // tous les labels sont traduits automatiquement
                    addRoot: '<string>',
                    expandAll: '<string>',
                    collapseAll: '<string>',
                    enableDrag: '<string>',
                    disableDrag: '<string>'
                }
            };
            readme.service = {
                getRootNodes: 'getRootNodes',
                getNode: 'getNode',
                getChildrenByNodeId: 'getChildrenByNodeId',
                changeNodeParent: 'changeNodeParent',
                changeLeafParent: 'changeLeafParent',
                createNode: 'createNode',
                updateNode: 'updateNode',
                renameNode: 'renameNode',
                deleteNode: 'deleteNode',
                getNodeTypes: 'getNodeTypes',
                search: 'search'
            };

            return readme;
        }

        function notifyError(error) {
            notification.error(error);
        }

        function treeviewTypeComparison(node, anotherNode) {
            var ret = true;

            if (treeviewConfig.typeComparisonEnabled === true) {
                ret = node.type === anotherNode.type;
            }

            return ret;
        }

        //endregion Méthodes privées

        //region Events
        function stateChangeSuccess(event, toState, toParams, fromState, fromParams) {
            // si une fonction est renseignée, on l'exécute
            if (angular.isFunction(treeviewFunctions.stateChangeSuccess)) {
                // on envoie la fonction select en paramètre pour permettre la sélection d'un noeud
                treeviewFunctions.stateChangeSuccess.call(functionsScope, event, toState, toParams, fromState, fromParams, select);
            }
            // sinon rien
        }

        function dispose() {
            if (onDestroy !== undefined) {
                onDestroy();
            }
            if (onStateChangeSuccess !== undefined) {
                onStateChangeSuccess();
            }
            if (timer !== undefined) {
                $timeout.cancel(timer);
                timer = undefined;
            }
            AcTreeviewCommunicationService.unregisterNodeRefreshFunction(treeviewName);
            AcTreeviewCommunicationService.unregisterTreeviewReloadFunction(treeviewName);
            AcTreeviewCommunicationService.resetSelectedNode(treeviewName);
        }

        //endregion Events
    }
})(angular);