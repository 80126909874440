(function (angular, undefined) {
	angular.module('blocks.smart.table').service('PaginationService', PaginationService);

	PaginationService.$inject = ['FilterHelperService', 'SortHelperService', 'moment', '$window', '$state', '$translate'];

	function PaginationService(FilterHelperService, SortHelperService, moment, $window, $state, $translate) {
		const config = {
			itemsPerPage: '20'
		};

		const service = {
			take: config.itemsPerPage,
			getFilterInfos: getFilterInfos,
			filters: FilterHelperService,
			sorts: SortHelperService,
			getFilters: getFilters,
			getSorts: getSorts,
			getPagination: getPagination,
			setTableState: setTableState,
			saveTableState: saveTableState,
			getTableStateAfterDelete: getTableStateAfterDelete
		};

		return service;

		function getFilterInfos(tableState) {
			let filter = getFilter();

			if (tableState !== undefined && tableState.pagination !== undefined) {
				// partie pagination
				const pagination = tableState.pagination;
				const skip = pagination.start || 0; // offset
				const take = pagination.number || config.itemsPerPage;

				// partie tris
				let sort = {};
				if (tableState.sort && tableState.sort.predicate) {
					sort = {
						criterion: tableState.sort.predicate,
						isDescending: tableState.sort.reverse
					};
				}

				const filters = tableState.search.predicateObject;

				filter = getFilter(skip, take, sort, filters);
			}

			return filter;
		}

		function getTableStateAfterDelete(tableState, take) {
			if (tableState && tableState.pagination) {
				const itemsCount = tableState.pagination.itemsCount - 1;
				const skip = tableState.pagination.start;

				if (skip >= itemsCount) {
					tableState.pagination.start = skip - take;
				}
			}

			return tableState;
		}

		function getFilter(skip, take, order, filters) {
			return {
				pagination: {
					skip: skip || 0,
					take: take || service.take
				},
				order: order || {},
				filters: filters || []
			};
		}

		function getFilters(tableState) {
			const output = [];
			if (tableState && tableState.pagination) {
				angular.forEach(tableState.search.predicateObject, function (exp, predicate) {
					if (Array.isArray(exp) && exp.length > 0) {
						// si c'est un tableau
						if (exp.length == 1) {
							output.push(FilterHelperService.eqFilter(exp[0], predicate));
						} else {
							output.push(FilterHelperService.inFilter(exp, predicate));
						}
					} else if (angular.isObject(exp) && exp.value && exp.comparator) {
						// si c'est un objet et qu'il définit un comparateur
						// (exemple : comparator:true signifie comparaison stricte)
						if (exp.comparator) {
							output.push(FilterHelperService.eqFilter(exp.value, predicate));
						} else {
							output.push(FilterHelperService.startsWithFilter(exp.value, predicate));
						}
					} else if (angular.isObject(exp) && (exp.min !== undefined || exp.max !== undefined)) {
						// si c'est un objet qui définit un min ou un max
						if (exp.min !== undefined) {
							output.push(FilterHelperService.geFilter(exp.min, predicate));
						}

						if (exp.max !== undefined) {
							output.push(FilterHelperService.leFilter(exp.max, predicate));
						}
					} else if (angular.isObject(exp) && (exp.dateMin || exp.dateMax)) {
						// si c'est un objet qui définit une dateMin ou une dateMax
						const dateMin = exp.dateMin ? moment(exp.dateMin).toISOString() : undefined;
						const dateMax = exp.dateMax ? moment(exp.dateMax).add(1, 'day').toISOString() : undefined;

						if (dateMin) {
							output.push(FilterHelperService.geFilter(dateMin, predicate));
						}

						if (dateMax) {
							output.push(FilterHelperService.ltFilter(dateMax, predicate));
						}
					} else if (angular.isObject(exp) && (exp.yes || exp.no)) {
						// si c'est un objet qui définit un booléen
						if (exp.yes && exp.no) {
							output.push(FilterHelperService.inFilter([true, false], predicate));
						} else if (exp.yes) {
							output.push(FilterHelperService.eqFilter(true, predicate));
						} else if (exp.no) {
							output.push(FilterHelperService.eqFilter(false, predicate));
						}
					} else if (exp !== undefined && exp.trim() !== '') {
						output.push(FilterHelperService.containsFilter(exp, predicate));
					}
				});
			}
			return output;
		}

		function getSorts(tableState) {
			const sort = [];
			if (tableState && tableState.pagination) {
				if (tableState.sort && tableState.sort.predicate) {
					sort.push({
						criterion: tableState.sort.predicate,
						isDescending: tableState.sort.reverse
					});
				}
			}
			return sort;
		}

		function getPagination(tableState) {
			const currentLocation = $translate.instant($state?.current?.name);
			const paginationSettingFromSessionStorage = JSON.parse(sessionStorage.getItem('paginationSettings_' + currentLocation));

			if (
				typeof tableState !== 'undefined' &&
				paginationSettingFromSessionStorage &&
				paginationSettingFromSessionStorage?.paginationState?.page != undefined &&
				tableState?.pagination?.page === undefined
			) {
			
				tableState.pagination = paginationSettingFromSessionStorage.paginationState;
			}

			const pagination = tableState ? tableState.pagination : {};
			return {
				skip: pagination.start || 0,
				take: pagination.number || config.itemsPerPage
			};
		}

		function setPagination(tableState) {
			const pagination = tableState ? tableState.pagination : {};
			return {
				skip: pagination?.start || 0,
				take: pagination?.number || config.itemsPerPage
			};
		}

		/*
         // nbPages : nombre de page total
         // currentPage : page à afficher
         // itemsCount : nombre d'items
         // take : nombre d'items par page
         // tableState smart-table dont il faut setter les paramètres
         */
		function setTableState(skip, take, total, tableState) {
			tableState.pagination.page = Math.floor(skip / take) + 1;
			tableState.pagination.start = skip;
			tableState.pagination.numberOfPages = Math.ceil(total / take);
			tableState.pagination.itemsCount = total;
		}

		function saveTableState(tableState) {}
	}
})(angular);
