import ProduitFormNiveauxController from './produit.form.niveaux.controller';

export default {
    bindings: {
        produit: '=',
        onUpdate: '&'
    },
    template: require('./produit.form.niveaux.html'),
    controller: ProduitFormNiveauxController
};
