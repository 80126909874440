import validatorBuilder from 'validator-builder';

let imageEnteteValidator = null;

export default class ImageEntete {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.idType = data.idType;
        this.source = data.source;
        this.taille = data.taille;
        this.fileName = data.fileName;
        this.extension = data.extension;
        this.niveauVisibilite = 0;
    }

    isValid() {
        imageEnteteValidator = imageEnteteValidator || validatorBuilder.getInstanceFor('ImageEntete');
        const validationResults = imageEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
