/*@ngInject*/
export default class PortailUserAddRequestController {
    constructor($scope, $state, notification, UserRequestService) {
        this.$scope = $scope;
        this.$state = $state;
        this.notification = notification;
        this.UserRequestService = UserRequestService;
    }
    $onInit() {
        this.request = [];
        this.loadAll();
    }
    $onDestroy() {}

    async loadAll() {
        try {
            this.request = (await this.UserRequestService.getAllAddUserRequest()).data || [];
        } catch (error) {
            this.notification.error(error.data.message);
        }
    }

    async closeReq(request, validate) {
        request.loading = true;
        request.status = validate ? 1 : 2;
        try {
            const res = await this.UserRequestService.updateAddUserRequest(request);
            if (validate) {
                this.notification.success('PORTAIL.USER.UPDATE_REQUEST_VALID');
            } else {
                this.notification.success('PORTAIL.USER.UPDATE_REQUEST_REFUS');
            }

            if (res.data == 'Email Error') {
                this.notification.warning('PORTAIL.USER.UPDATE_REQUEST_MAIL_ERROR');
            } else {
                this.notification.success('PORTAIL.USER.UPDATE_REQUEST_MAIL');
            }
            const idx = this.request.findIndex((x) => x.id === request.id);
            this.request.splice(idx, 1);
        } catch (error) {
            this.notification.error(error.data.message);
        }
    }

    redirectTo(page, request) {
        // if(page == "compte portail"){
        //     let accountId = request.claiment.id;
        // }else{
        //     let personneId = request.claiment.idPersonne;
        // }
    }
}
