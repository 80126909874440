export default class ParametrageGeneralController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'ParametrageGeneralService',
		'notification',
		'$location',
		'MassiaApplicationService',
		'$translate'
	];

	constructor($scope, $state, $stateParams, ParametrageGeneralService, notification, $location, MassiaApplicationService, $translate) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.ParametrageGeneralService = ParametrageGeneralService;
		this.notification = notification;
		this.$location = $location;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$translate = $translate;
	}

	async $onInit() {
		this.loading = false;
		this.parametre = 0;
		this.app = this.MassiaApplicationService.getApplication();
		/*this.unregisterReinit = this.AssociesCommunicationService.registerReinit(() => this.initForm());*/
		this.initForm();
	}

	$onDestroy() {
		//this.unregisterReinit();
	}

	async initForm() {
		this.groupesParametres = await this.ParametrageGeneralService.getParametrageGeneral(this.MassiaApplicationService.getApplication());
		this.paramsProg = _.find(this.groupesParametres, (x) => x.nomGroupe == 'PARAMETRAGE_GENERAL.PARAM_PROG');
		this.paramsProgResult = [];
		this.formateValeurs();
		for (let i = 0; i < this.groupesParametres.length; i++) {
			const element = this.groupesParametres[i];
			this.actualisation(element.parametres);
		}

		this.actualisation(this.groupesParametres[0].parametres);
	}

	actualisation(parametre) {
		this.parametres = parametre;
		if (parametre) {
			this.parametre = parametre[0].typeParametre;
		}
	}

	async valider() {
		this.startLoading();
		try {
			this.getParamProgValues();
			await this.ParametrageGeneralService.updateParametrageGeneral(this.groupesParametres, this.MassiaApplicationService.getApplication());
			this.notification.success('PARAMETRAGE_GENERAL.UPDATED');
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.formateValeurs();
			this.stopLoading();
		}
	}

	formateValeurs() {
		this.groupesParametres.forEach((groupe) => {
			groupe.parametres.forEach((parametre) => {
				switch (parametre.typeParametre) {
					case 1: //booléen
						if (parametre.valeur && parametre.valeur == 'true') {
							parametre.valeur = true;
						} else {
							parametre.valeur = false;
						}
						break;
					case 7: //entier
						if (parametre.valeur) {
							parametre.valeur = Number(parametre.valeur);
						}
						break;
				}
			});
		});
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
