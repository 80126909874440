import MiniFormLogoController from './mini.form.logo.controller';

export default {
    bindings: {
        entete: '=',
        selectNewLogo: '&'
    },
    template: require('./mini.form.logo.html'),
    controller: MiniFormLogoController
};
