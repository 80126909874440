const serviceUrl = `${__configuration.apiUrl}/massia/gestion/inerte-registre`;

export default class InerteRegistreAdmissionService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async print(idSite, debut, fin, fileName) {
        const filtres = {
            idSite: idSite,
            debut: debut,
            fin: fin,
            fileName: fileName
        };
        const res = await this.$http.get(serviceUrl, {
            params: {
                filtres: JSON.stringify(filtres || {})
            },
            responseType: 'arraybuffer'
        });

        return res;
    }

    async getSites() {
        const url = `${__configuration.apiUrl}/massia/sites-commerciaux/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
