//import PontComptable from './pont.comptable.model';

export default class PontComptableController {
    static $inject = ['$state', '$stateParams', 'SocietesService', 'SocietesTypesService', 'ModalService', 'notification', '$translate', '$filter'];

    constructor($state, $stateParams, SocietesService, SocietesTypesService, ModalService, notification, $translate, $filter) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SocietesService = SocietesService;
        this.SocietesTypesService = SocietesTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.$filter = $filter;
    }

    $onInit() {
        this.getTypePontComptable();

        this.lignesEcriture = [
            {
                ordre: this.societe.pontComptable.ordreComptePrestation,
                valeur: this.societe.pontComptable.parametrageLibelleComptePrestation,
                libelle: this.$translate.instant('SOCIETES.PONT_COMPTABLE.LIBELLE_COMPTE_PRESTATION'),
                prop: 'parametrageLibelleComptePrestation'
            },
            {
                ordre: this.societe.pontComptable.ordreCompteProduit,
                valeur: this.societe.pontComptable.parametrageLibelleCompteProduit,
                libelle: this.$translate.instant('SOCIETES.PONT_COMPTABLE.LIBELLE_COMPTE_PRODUIT'),
                prop: 'parametrageLibelleCompteProduit'
            },
            {
                ordre: this.societe.pontComptable.ordreCompteTVA,
                valeur: this.societe.pontComptable.parametrageLibelleCompteTVA,
                libelle: this.$translate.instant('SOCIETES.PONT_COMPTABLE.LIBELLE_COMPTE_TVA'),
                prop: 'parametrageLibelleCompteTVA'
            },
            {
                ordre: this.societe.pontComptable.ordreCompteTiers,
                valeur: this.societe.pontComptable.parametrageLibelleCompteTiers,
                libelle: this.$translate.instant('SOCIETES.PONT_COMPTABLE.LIBELLE_COMPTE_TIERS'),
                prop: 'parametrageLibelleCompteTiers'
            },
            {
                ordre: this.societe.pontComptable.ordreCompteTransport,
                valeur: this.societe.pontComptable.parametrageLibelleCompteTransport,
                libelle: this.$translate.instant('SOCIETES.PONT_COMPTABLE.LIBELLE_COMPTE_TRANSPORT'),
                prop: 'parametrageLibelleCompteTransport'
            }
        ];
        this.lignesEcriture = this.$filter('orderBy')(this.lignesEcriture, 'ordre', false);
    }

    updateLignesEcriture() {
        const comptePrestation = this.$filter('filter')(this.lignesEcriture, { prop: 'parametrageLibelleComptePrestation' });
        this.societe.pontComptable.ordreComptePrestation = comptePrestation[0].ordre;
        this.societe.pontComptable.parametrageLibelleComptePrestation = comptePrestation[0].valeur;

        const compteProduit = this.$filter('filter')(this.lignesEcriture, { prop: 'parametrageLibelleCompteProduit' });
        this.societe.pontComptable.ordreCompteProduit = compteProduit[0].ordre;
        this.societe.pontComptable.parametrageLibelleCompteProduit = compteProduit[0].valeur;

        const compteTva = this.$filter('filter')(this.lignesEcriture, { prop: 'parametrageLibelleCompteTVA' });
        this.societe.pontComptable.ordreCompteTVA = compteTva[0].ordre;
        this.societe.pontComptable.parametrageLibelleCompteTVA = compteTva[0].valeur;

        const compteTiers = this.$filter('filter')(this.lignesEcriture, { prop: 'parametrageLibelleCompteTiers' });
        this.societe.pontComptable.ordreCompteTiers = compteTiers[0].ordre;
        this.societe.pontComptable.parametrageLibelleCompteTiers = compteTiers[0].valeur;

        const compteTransport = this.$filter('filter')(this.lignesEcriture, { prop: 'parametrageLibelleCompteTransport' });
        this.societe.pontComptable.ordreCompteTransport = compteTransport[0].ordre;
        this.societe.pontComptable.parametrageLibelleCompteTransport = compteTransport[0].valeur;
    }

    updateOrdre(item, sens) {
        const index = this.lignesEcriture.indexOf(item);
        if (sens == 1) {
            this.lignesEcriture[index].ordre = this.lignesEcriture[index].ordre - 1;
            const prevIndex = this.lignesEcriture.indexOf(item) - 1;
            this.lignesEcriture[prevIndex].ordre = this.lignesEcriture[prevIndex].ordre + 1;
        } else {
            this.lignesEcriture[index].ordre = parseInt(this.lignesEcriture[index].ordre) + 1;
            const nextIndex = this.lignesEcriture.indexOf(item) + 1;
            this.lignesEcriture[nextIndex].ordre = parseInt(this.lignesEcriture[nextIndex].ordre) - 1;
        }
        this.lignesEcriture = this.$filter('orderBy')(this.lignesEcriture, 'ordre', false);

        this.updateLignesEcriture();
    }

    async getTypePontComptable() {
        this.listeSocietes = await this.SocietesService.getTypePontComptable();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
