import CalculStatistique from './calcul.statistique.model';

export default class CalculStatistiqueFormController {
    static $inject = [
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'CalculsStatistiquesService',
        'notification',
        'MassiaApplicationService',
        'MOPService'
    ];

    constructor($scope, $timeout, $state, $stateParams, CalculsStatistiquesService, notification, MassiaApplicationService, MOPService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.CalculsStatistiquesService = CalculsStatistiquesService;
        this.notification = notification;
        this.MassiaApplicationService = MassiaApplicationService;
        this.MOPService = MOPService;
    }

    $onInit() {
        this.MOPService.setMop([
            { title: 'CALCULS_STATS.BREADCRUMBS.CALCULS_STATS_LIST', filename: 'CalculsStatistiques.pdf', application: 'massia' }
        ]);
        this.calculStatistique = {};
        this.loading = false;
        this.reset();
    }

    $onDestroy() {
        this.$timeout.cancel(this.updateCalculTimeout);
        this.MOPService.resetMop();
    }

    async reset() {
        this.startLoading();

        let data = {
            id: this.$stateParams.id
        };

        if (data.id) {
            try {
                data = await this.CalculsStatistiquesService.getCalculStatistiqueById(data.id);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
                this.annuler();
            }
        }
        this.calculStatistique = new CalculStatistique(data);
        if (this.$stateParams.duplicate) {
            this.calculStatistique.id = undefined;
            this.calculStatistique.code += '_copie';
        }

        this.$timeout.cancel(this.updateCalculTimeout);
        this.updateCalculTimeout = this.$timeout(() => this.stopLoading());
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.calculStatistique.codeExists = await this.CalculsStatistiquesService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.calculStatistique.codeExists = null;
        }
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();

            try {
                let id = false;
                if (this.calculStatistique.id) {
                    await this.CalculsStatistiquesService.updateCalculStatistique(this.calculStatistique);
                    this.notification.success('CALCULS_STATS.UPDATED');
                    id = this.calculStatistique.id;
                } else {
                    id = await this.CalculsStatistiquesService.createCalculStatistique(this.calculStatistique);
                    this.notification.success('CALCULS_STATS.CREATED');
                }

                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async appliquer() {
        const id = await this.sauvegarder();

        if (id && this.calculStatistique.id) {
            this.reset();
        } else if (id) {
            this.$state.go('calculs.edit', { id: id });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    annuler() {
        this.$state.go('calculs.list');
    }

    checkValidity() {
        let validity = true;

        if (!this.calculStatistique.isValid()) {
            validity = false;
            this.$scope.$broadcast('calculStatistiqueValidations');
        }
        return validity;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
