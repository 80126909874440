const typesByValue = {
    0: 'produit',
    1: 'transport',
    2: 'prestation'
};

const typesValuesByName = {
    produit: 0,
    transport: 1,
    prestation: 2
};

export function getTypeNamesByValues() {
    return angular.copy(typesByValue);
}

export function getTypeNamesWithValues() {
    return angular.copy(typesValuesByName);
}
