import _ from 'lodash';

export default class PortailSettingController {
    constructor(SettingPortailService, notification) {
        this.SettingPortailService = SettingPortailService;
        this.notification = notification;
    }

    async $onInit() {
        await this.loadAll();
    }

    $onDestroy() {}

    async loadAll() {
        this.setting = (await this.SettingPortailService.getSetting()).data || {};

        this.SettingPortailService.config = {
            url: this.getModel('Url'),
            autoMail: this.getModel('MailAuto'),
            contact: this.getModel('Contact'),
            passSender: this.getModel('MailPassSender'),
            portSender: this.getModel('MailPortSender'),
            serverSender: this.getModel('MailServerSender'),
            isSsl: this.getModel('MailIsSsl'),
            aliasSender: this.getModel('MailAliasSender'),
            web: this.getModel('Web')
        };
    }

    getModel(code) {
        if (!this.setting.find((x) => x.code === code)) {
            return {
                id: -1,
                code: code,
                value: ''
            };
        }
        return this.setting.find((x) => x.code === code);
    }

    setModel(code, model) {
        if (model) {
            const idx = this.setting.findIndex((x) => x.code === code);
            if (idx > -1) {
                if (this.setting[idx].value !== model.value) {
                    model.value = `${model.value}`;
                    return (this.setting[idx] = model);
                }
                return;
            }
            return this.setting.push(model);
        }
    }

    async save() {
        try {
            this.setModel('Url', this.SettingPortailService.config.url);
            this.setModel('MailAuto', this.SettingPortailService.config.autoMail);
            this.setModel('Contact', this.SettingPortailService.config.contact);
            this.setModel('MailPassSender', this.SettingPortailService.config.passSender);
            this.setModel('MailPortSender', this.SettingPortailService.config.portSender);
            this.setModel('MailServerSender', this.SettingPortailService.config.serverSender);
            this.setModel('MailIsSsl', this.SettingPortailService.config.isSsl);
            this.setModel('MailAliasSender', this.SettingPortailService.config.aliasSender);
            this.setModel('Web', this.SettingPortailService.config.web);
            await this.SettingPortailService.saveSetting(this.setting);
            await this.saveDocSettings();
            await this.saveDisplaySetting();
            this.notification.success('PORTAIL.SETTING.SAVED');
            await this.loadAll();
        } catch (error) {
            if (error.data) {
                return this.notification.error(error.data.message);
            }
            return this.notification.error(error.message);
        }
    }

    async saveDocSettings() {
        const toCreate = _.filter(this.SettingPortailService.docSetting, (item) => {
            return item?.create;
        });
        const toUpdate = _.filter(this.SettingPortailService.docSetting, (item) => {
            return item?.update && !item?.create;
        });

        if (toCreate && toCreate.length > 0) {
            await this.SettingPortailService.saveDocSetting(toCreate);
        }

        if (toUpdate && toUpdate.length > 0) {
            await this.SettingPortailService.updateDocSetting(toUpdate);
        }
    }

    async saveDisplaySetting() {
        const old = this.SettingPortailService.oldDisplaySetting;
        const toSave = this.SettingPortailService.displaySetting;

        if (old == null && !toSave.isNull()) {
            const id = (await this.SettingPortailService.createDisplaySetting(toSave)).data;
            this.SettingPortailService.displaySetting.id = id;
            this.SettingPortailService.oldDisplaySetting = this.SettingPortailService.displaySetting;
        } else {
            if (old && old.id) {
                await this.SettingPortailService.updateDisplaySetting(toSave);
            }
        }
    }
}

PortailSettingController.$inject = ['SettingPortailService', 'notification'];
