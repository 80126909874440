DevisesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'DevisesService',
	'notification',
	'$transitions',
	'MOPService'
];

export default function DevisesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	DevisesService,
	notification,
	$transitions,
	MOPService
) {
	const vm = this;

	let previousTableState;
	let selectedDeviseId = undefined;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.devises = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};

	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectDevise = selectDevise;
	vm.isDeviseSelected = isDeviseSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadDevises = loadDevises;
	vm.deleteDevise = deleteDevise;
	vm.MOPService = MOPService;

	vm.$onInit = () => {
		vm.MOPService.setMop([{ title: 'DEVISES.BREADCRUMBS.DEVISES_LIST', filename: 'UnitesEtDevises.pdf', application: 'massia' }]);
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "devises.list", on désélectionne la ligne sélectionnée (s'il y en a)
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		//$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		//quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
		if (shouldSetSelectedId()) {
			selectedDeviseId = $stateParams.id;
		}
	};

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function stateChangeSuccessFunc(event, toState) {
		if (!$stateParams.id) {
			selectedDeviseId = undefined;
		}
	}

	function shouldSetSelectedId() {
		return $state && $state.current && $state.current.name && $state.current.name === 'devises.list.detail' && $stateParams && $stateParams.id;
	}

	function selectDevise(devise) {
		if (devise && devise.id) {
			//si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedDeviseId, et charger la vue détail pour le devise sélectionné
			if (selectedDeviseId !== devise.id) {
				showDetail(devise.id);
			} else {
				//si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
				closeDetail();
			}
		}
	}

	function isDeviseSelected(devise) {
		return devise && devise.id && selectedDeviseId === devise.id;
	}

	function showDetail(deviseId) {
		selectedDeviseId = deviseId;
		$state.go('devises.list.detail', { id: deviseId });
	}

	function closeDetail() {
		selectedDeviseId = undefined;
		$state.go('devises.list');
	}

	async function loadDevises(tableState) {
		startLoading();

		//quand on rafraîchit la grille, on revient sur "devises.list", et on ferme la vue détail d'un devise
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== 'devises.list') {
			$state.go('devises.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.devises = [];

		try {
			const data = await DevisesService.getDevises(filters, sorts, pagination);

			vm.devises = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteDevise(devise) {
		if (devise && devise.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('DEVISES.DELETE.TITLE', { code: devise.code }),
				modalMsg: $translate.instant('DEVISES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await DevisesService.deleteDeviseById(devise.id);
					notification.success($translate.instant('DEVISES.DELETE.SUCCESS'));

					selectedDeviseId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une redirection vers la vue de la grille, afin de fermer la vue détail
					if (vm.params.id) {
						vm.state.go('devises.list');
					}

					loadDevises();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
