import validatorBuilder from 'validator-builder';

let offreProducteurLigneProduitValidator = null;

export default class OffreProducteurLigneProduit {
    constructor() {
        this.id = -1;
        this.typeArticle = 0;
        this.quantite = 1;
        this.prixVente = null;
        this.typePrix = null;
        this.prixStandard = null;
        this.prixProduitTransporte = null;
        this.prixAchatTransport = null;
        this.idPrestation = null;
        this.prestation = null;
        this.unite = null;
        this.IdUnite = null;
        this.idBenne = null;
        this.tvaId = 1;
    }

    isValid() {
        offreProducteurLigneProduitValidator = offreProducteurLigneProduitValidator || validatorBuilder.getInstanceFor('OffreProducteurLigneProduit');
        const validationResults = offreProducteurLigneProduitValidator.validate(this);
        return validationResults.isValid;
    }
}
