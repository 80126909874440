import UnitesSelectController from './unites.select.controller';

export default {
    template: require('./unites.select.tpl.html'),
    controller: UnitesSelectController,
    bindings: {
        disabled: '<',
        clearOnSelect: '<',
        selected: '<',
        excludes: '<',
        onSelect: '&',
        type: '@',
        idFamille: '<'
    }
};
