import PrestationEnteteController from './prestation.entete.controller';

export default {
    bindings: {
        prestation: '=',
        isOpen: '='
    },
    template: require('./prestation.entete.html'),
    controller: PrestationEnteteController
};
