import FormuleComposant from '../creation/formule.composant.model';

export default class VisualisationController {
	/* @ngInject */
	constructor(
		_,
		$scope,
		$timeout,
		$state,
		$stateParams,
		FormulesCommunicationService,
		FormuleService,
		notification,
		$location,
		$anchorScroll,
		$uibModal,
		moment,
		MassiaApplicationService,
		globalizationManagementService
	) {
		this._ = _;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.FormulesCommunicationService = FormulesCommunicationService;
		this.FormuleService = FormuleService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.$uibModal = $uibModal;
		this.moment = moment;
		this.MassiaApplicationService = MassiaApplicationService;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	$onInit() {
		this.startLoading();

		console.log(this.resolve);
		// Propriétés
		this.resolve.formule.idProduit = this.resolve.formule.idProduitCompose;
		this.application = this.MassiaApplicationService.getApplication();
		//this.getCurrentFormula(this.resolve.source.idComposition);
		this.stopLoading();
	}

	async getCurrentFormula(id) {
		try {
			this.formule = await this.FormuleService.getFormuleById(id);
			this.formule.datePrixCession = this.moment().format(this.dateFormat);
			this.formule.idProduit = this.formule.idProduitCompose;
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			const listeComposants = [];
			for (let i = 0; i < this.formule.composants.length; i++) {
				let composant = angular.copy(this.formule.composants[i]); //utilise pour pas relancer le watcher dans FormuleCalculVerificationController
				if (composant.ordre > this.maxOrdre) {
					this.maxOrdre = composant.ordre;
				}
				await this.rechercheDesPrix(composant);
				composant = new FormuleComposant(composant);
				listeComposants.push(composant);
			}
			this.lastOrdre = angular.copy(this.maxOrdre);
			this.formule.composants = listeComposants;
			this.oldDateCreation = angular.copy(this.formule.dateCreation);
			this.calculTotal();
		}
	}

	async rechercheDesPrix(composant) {
		let prixAchat;
		let stock = null;
		if (composant.idProducteur) {
			prixAchat = await this.FormuleService.getPrixAchatProduit(
				this.formule.idProducteur,
				composant.idElement,
				composant.idProducteur,
				this.formule.datePrixCession
			);
			stock = await this.FormuleService.getPourcentagePerte(
				this.formule.idProducteur,
				composant.idElement,
				composant.idProducteur,
				this.formule.datePrixCession
			);
		} else {
			prixAchat = await this.FormuleService.getPrixAchatPrestation(
				this.formule.idProducteur,
				composant.idElement,
				this.formule.datePrixCession
			);
		}

		composant.coutIncorporation = prixAchat.prix;
		if (prixAchat.ristourne) {
			composant.coutIncorporation = composant.coutIncorporation + prixAchat.ristourne;
		}
		composant.pourcentagePerte = stock;
		if (composant.idProducteur) {
			composant.prixCession = ((composant.coutIncorporation * composant.quantite) / 100) * (1 + composant.pourcentagePerte / 100);
		} else {
			composant.prixCession = composant.coutIncorporation;
		}
	}

	calculTotal() {
		let totalPourcentage = 0;
		let totalPrixCession = 0;
		this.formule.composants.forEach(function (element) {
			totalPourcentage = totalPourcentage + element.quantite;
			totalPrixCession = totalPrixCession + element.prixCession;
		}, this);
		this.formule.pourcentageTotal = totalPourcentage;
		this.formule.prixCessionTotal = totalPrixCession;
	}

	$onDestroy() {
		this.$timeout.cancel(this.updateFormuleTimeout);
	}

	cancel() {
		this.modalInstance.dismiss();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
