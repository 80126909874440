import { getNatureNamesByValues } from '../../../caracteristiques/services/natures.enum';
import ProduitCommerciaux from '../produit-form/produits-commerciaux/produits.commerciaux.model';
export default class ProduitDetailController {
    static $inject = ['$stateParams', '$state', 'ProduitsService', 'notification', 'ProduitsCommunicationService', 'MassiaApplicationService'];

    constructor($stateParams, $state, ProduitsService, notification, ProduitsCommunicationService, MassiaApplicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.ProduitsService = ProduitsService;
        this.notification = notification;
        this.ProduitsCommunicationService = ProduitsCommunicationService;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        this.loading = false;
        this.produit = {};
        this.caracteristiques = [];

        this.mixDesign = [];
        this.concreteCode = [];
        //par défaut, on ouvre l'onglet "Entête"
        this.ongletOpen = {
            isEnteteOpen: true,
            isCaracteristiqueOpen: false,
            isNiveauxHierarchiqueOpen: false,
            isAppellationsCommercialesOpen: false,
            isValeurReferenceOpen: false,
            isProduitCommerciauxOpen: false,
            isMixDesignOpen: false
        };

        this.initProduit();

        if (this.MassiaApplicationService.getApplication() === 'laboratoire') {
            this.getSocieteProduct();
        }
        this.showCode2 = false;
        this.getLicence();
    }
    getLicence() {
        //MN: pour "Eqiom", on affiche les CODE2(SAP)
        if (__configuration.licenceLaboratoire === '2') {
            this.showCode2 = true;
        }
    }

    async initProduit() {
        this.produit = {
            id: this.$stateParams.id
        };

        if (this.produit.id) {
            this.startLoading();

            try {
                // on ajoute await afin de ne pas passer trop rapidement dans le finally
                // et ainsi on garde le loading le temps nécessaire
                this.produit.entete = await this.ProduitsService.getProduitEnteteById(this.produit.id);
                this.produit.caracteristiques = await this.ProduitsService.getProduitValeursCaracteristiquesById(this.produit.id);
                this.produit.niveaux = await this.ProduitsService.getProduitValeursNiveauxById(this.produit.id);
                this.produit.appellationsCommerciales = await this.ProduitsService.getAppellationsCommerciales(this.produit.id);
                this.produit.specificites = await this.ProduitsService.getProduitSpecificites(this.produit.entete.typeId);
                this.setValeursReferences();
                this.setCaracteristiques(this.produit.caracteristiques);
                if (this.MassiaApplicationService.getApplication() === 'laboratoire') {
                    this.getMixDesign();
                    this.getConcreteCode();
                }
                await this.loadNiveaux();
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListProduit();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListProduit();
        }
    }

    async getProduitEntete(produitId) {
        this.produit.entete = await this.ProduitsService.getProduitEnteteById(produitId);
    }

    async getSocieteProduct() {
        const res = await this.ProduitsService.getAllSocieteProduct(
            [{ criterion: 'produit.id', value: this.produit.id, type: 'Eq' }],
            [],
            { skip: 0, take: 99999 },
            this.produit.id
        );
        this.societeProduct = res.items;
    }

    async setCaracteristiques(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiques = [];
        caracteristiques.forEach((c) => {
            this.caracteristiques.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    async setValeursReferences() {
        const producteurs = await this.ProduitsService.getProducteurs(this.produit.id);
        const vr = [];
        producteurs.forEach((element) => {
            if (element.valeursReference.length > 1) {
                element.valeursReference.forEach((value) => {
                    vr.push(value);
                });
            } else if (element.valeursReference.length > 0) {
                vr.push(element.valeursReference[0]);
            }
        });

        this.produit.valeursReferences = vr;
    }

    async getReferenceValeur() {
        const res = await this.ProduitsService.getProducteurs(this.produit.id);
        return res;
    }

    async loadNiveaux() {
        this.startLoading();

        try {
            this.niveaux = await this.ProduitsService.getProduitsNiveaux();
            this.elements = await this.ProduitsService.getProduitsElements();
        } catch (ex) {
            this.notification.error(ex);
        } finally {
            this.stopLoading();
        }
    }

    goListProduit() {
        this.$state.go('produits.list', {}, { reload: true });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    async getMixDesign() {
        if (this.produit.entete?.typeCode === 'TEWIL101') {
            const data = await this.ProduitsService.getAllMixDesign(this.produit.id, [], []);
            this.mixDesign = data?.produitsCom || [];
        }
    }

    async getConcreteCode() {
        if (this.produit.entete?.typeObj?.idTypeLie) {
            const data = await this.ProduitsService.getAllProduitCommerciaux(this.produit.id, [], []);
            this.concreteCode = data?.produitsCom || [];
        }
    }
}
