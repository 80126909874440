import ProduitFormSiteLiesLaboController from './produit.form.site.lies.labo.controller';

export default {
    bindings: {
        produit: '<',
        sites: '<',
        that: '=',
        ongletOpen: '=',
        producteurPrice: '&'
    },
    template: require('./produit.form.site.lies.labo.html'),
    controller: ProduitFormSiteLiesLaboController
};
