const serviceUrl = `${__configuration.apiUrl}/massia/syntheses`;

export default class SynthesesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getSynthesesConsoClient(filters, sorts, pagination, searchingFormSynthesesConsoClient) {
        const url = `${serviceUrl}/consoClient`;
        const res = await this.$http.get(url, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {}),
                societeComIdSelected: JSON.stringify(searchingFormSynthesesConsoClient.societeComIdSelected || null),
                typeClientIdSelected: JSON.stringify(searchingFormSynthesesConsoClient.typeClientIdSelected || null),
                dateDebut: JSON.stringify(searchingFormSynthesesConsoClient.dateDebut || null),
                dateFin: JSON.stringify(searchingFormSynthesesConsoClient.dateFin || null),
                listeIdProduitsEnrobes: JSON.stringify(searchingFormSynthesesConsoClient.listeIdProduitsEnrobes || null),
                listeIdSitesCommerciaux: JSON.stringify(searchingFormSynthesesConsoClient.listeIdSitesCommerciaux || null)
            }
        });

        return res.data;
    }

    async getSocietesCom() {
        const url = `${__configuration.apiUrl}/massia/societes-commerciales`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSitesCom(pIdSociete) {
        const url = `${__configuration.apiUrl}/massia/sites-commerciaux/header/${pIdSociete}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async exportListeSynthesesConsoClient(modeImpr, searchingFormSynthesesConsoClient) {
        const url = `${serviceUrl}/consoClient/print/${modeImpr}`;
        const res = await this.$http.get(url, {
            params: {
                societeComIdSelected: JSON.stringify(searchingFormSynthesesConsoClient.societeComIdSelected || null),
                typeClientIdSelected: JSON.stringify(searchingFormSynthesesConsoClient.typeClientIdSelected || null),
                dateDebut: JSON.stringify(searchingFormSynthesesConsoClient.dateDebut || null),
                dateFin: JSON.stringify(searchingFormSynthesesConsoClient.dateFin || null),
                listeIdProduitsEnrobes: JSON.stringify(searchingFormSynthesesConsoClient.listeIdProduitsEnrobes || null),
                listeIdSitesCommerciaux: JSON.stringify(searchingFormSynthesesConsoClient.listeIdSitesCommerciaux || null),
                modeImpr: JSON.stringify(modeImpr)
            },
            responseType: 'arraybuffer'
        });
        return res;
    }

    async getTypesClient() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/types/SiteClient/headers`);
        return res.data;
    }

    async getProduitsEnrobes() {
        const url = `${__configuration.apiUrl}/massia/produits/enrobes`;

        const res = await this.$http.get(url);
        return res.data;
    }
}
