import BlocSelectionCritereValeurController from './bloc.selection.critere.form.valeur.controller';

export default {
    bindings: {
        critere: '=',
        canUpdate: '='
    },
    controller: BlocSelectionCritereValeurController,
    template: require('./bloc.selection.critere.form.valeur.tpl.html')
};
