import validatorBuilder from 'validator-builder';
import FactureEntete from './entete/facture.entete.model';
import FactureArticles from './articles-chantier/articles.model';
import FactureRecapitulatif from './recap/recap.model';

let factureValidator = null;

export default class Facture {
    constructor(data) {
        this.entete = new FactureEntete(data.entete) || {};

        this.recapitulatifLignes = [];
        if (typeof data.entete !== 'undefined' && typeof data.entete.recapitulatifLignes !== 'undefined') {
            for (let i = 0; i < data.entete.recapitulatifLignes.length; i++) {
                const element = data.entete.recapitulatifLignes[i];
                this.recapitulatifLignes.push(new FactureRecapitulatif(element));
            }
        }
    }

    isValid() {
        factureValidator = factureValidator || validatorBuilder.getInstanceFor('Facture');
        const validationResults = factureValidator.validate(this);
        return validationResults.isValid;
    }
}
