import AffectationRoute from './_config/portail.affectation.routes';
import affectationList from './components/affectation-list';
import affectationForm from './components/affectation-form';

const moduleName = 'app.massia.common.portail.affectation';

angular
    .module(moduleName, [])
    .config(AffectationRoute)
    .component('portailAffectationList', affectationList)
    .component('portailAffectationForm', affectationForm);
export default moduleName;
