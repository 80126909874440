export default function Configure($stateProvider) {
    $stateProvider.state('messecu', {
        parent: 'common',
        url: '/messecu',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.SECURITE_PERSONNEL" | translate }}'
        }
    });

    $stateProvider.state('messecu.list', {
        url: '/list',
        template: '<mesures></mesures>',
        rights: { domain: 'messecu', right: 'read' },
        ncyBreadcrumb: {
            parent: 'messecu',
            label: '{{ "MESURES.BREADCRUMBS.MESURES_LIST" | translate}}'
        },
        navigation: {
            menu: 'securite_personnel_donnees',
            translate: 'MESURES.BREADCRUMBS.MESURES_LIST',
            order: 5
        },
        search: 'MESURES.BREADCRUMBS.MESURES_LIST'
    });

    $stateProvider.state('messecu.list.detail', {
        url: '/{id}',
        template: '<mesure-detail></mesure-detail>',
        rights: { domain: 'messecu', right: 'read' },
        ncyBreadcrumb: {
            parent: 'messecu.list',
            label: '{{ "MESURES.BREADCRUMBS.MESURE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('messecu.new', {
        url: '/new',
        template: '<mesure-form></mesure-form>',
        rights: { domain: 'messecu', right: 'create' },
        ncyBreadcrumb: {
            parent: 'messecu.list',
            label: '{{ "MESURES.BREADCRUMBS.MESURE_NEW" | translate}}'
        },
        search: 'MESURES.BREADCRUMBS.MESURE_NEW'
    });

    $stateProvider.state('messecu.edit', {
        url: '/{id}/edit',
        template: '<mesure-form></mesure-form>',
        rights: { domain: 'messecu', right: 'update' },
        ncyBreadcrumb: {
            parent: 'messecu.list',
            label: '{{ "MESURES.BREADCRUMBS.MESURE_EDIT" | translate }}'
        }
    });

    $stateProvider.state('messecu.newType', {
        url: '/new-type',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        params: { parentState: 'messecu.new' },
        ncyBreadcrumb: {
            parent: 'messecu.new',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });

    $stateProvider.state('messecu.duplicate', {
        url: '/{id}/duplicate',
        template: '<mesure-form></mesure-form>',
        rights: { domain: 'messecu', right: 'create' },
        ncyBreadcrumb: {
            parent: 'messecu.list',
            label: '{{ "MESURES.BREADCRUMBS.MESURE_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });
}

Configure.$inject = ['$stateProvider'];
