import ParametrageGeneralConfig from './_config/parametrage.general.config';
import ParametrageGeneralRoutes from './_config/parametrage.general.routes';
import ParametrageGeneralComponent from './components/parametrage-general';
import ParametrageGeneralService from './services/parametrage.general.service';
import ParametreProgrammeComponent from './components/parametre-programme';
const moduleName = 'app.massia.common.parametrage.general';

angular
	.module(moduleName, [])
	.config(ParametrageGeneralConfig)
	.config(ParametrageGeneralRoutes)
	.service('ParametrageGeneralService', ParametrageGeneralService)
	.component('parametrageGeneral', ParametrageGeneralComponent)
	.component('parametreProgramme', ParametreProgrammeComponent);

export default moduleName;
