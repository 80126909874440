import CaracteristiquesConfig from './_config/caracteristiques.config';
import CaracteristiquesRoutes from './_config/caracteristiques.routes';

import CaracteristiquesService from './services/caracteristiques.service';
import CaracteristiqueValidator from './components/caracteristique-form/caracteristique.validator';
import AdditionnalInfoValidator from './components/nature-additionnal-info/additionnal.info.validator';
import EntityCaracteristiquesValidator from './components/entity-caracteristiques/entity.caracteristiques.validator';

import CaracteristiquesComponent from './components/caracteristiques';
import CaracteristiqueDetailComponent from './components/caracteristique-detail';
import CaracteristiqueFormComponent from './components/caracteristique-form';
import CaracteristiquesSelectComponent from './components/caracteristiques-select';
import EntityCaracteristiquesComponent from './components/entity-caracteristiques';

import NatureAdditionnalInfo from './components/nature-additionnal-info';
import ElementCaracteristiqueService from './services/element.caracteristique.service';
import CaracteristiquesTypesService from './services/caracteristiques.types.service';

const moduleName = 'app.massia.common.caracteristiques';

angular
    .module(moduleName, [])
    .run([
        '$templateCache',
        ($templateCache) => {
            $templateCache.put(
                'field-template.elements.html',
                require('./components/nature-additionnal-info/templates/field-template.elements.html')
            );
            $templateCache.put('field-template.numeric.html', require('./components/nature-additionnal-info/templates/field-template.numeric.html'));
        }
    ])
    .config(CaracteristiquesConfig)
    .config(CaracteristiquesRoutes)
    .service('CaracteristiquesService', CaracteristiquesService)
    .service('CaracteristiqueValidator', CaracteristiqueValidator)
    .service('AdditionnalInfoValidator', AdditionnalInfoValidator)
    .service('EntityCaracteristiquesValidator', EntityCaracteristiquesValidator)
    .service('ElementCaracteristiqueService', ElementCaracteristiqueService)
    .service('CaracteristiquesTypesService', CaracteristiquesTypesService)
    .component('caracteristiques', CaracteristiquesComponent)
    .component('caracteristiquesSelect', CaracteristiquesSelectComponent)
    .component('caracteristiqueDetail', CaracteristiqueDetailComponent)
    .component('caracteristiqueForm', CaracteristiqueFormComponent)
    .component('natureAdditionnalInfo', NatureAdditionnalInfo)
    .component('entityCaracteristiques', EntityCaracteristiquesComponent);

export default moduleName;
