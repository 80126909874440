export default function Configure($stateProvider) {
    $stateProvider.state('produits', {
        parent: 'common',
        url: '/produits',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('produits.list', {
        url: '/list',
        template: '<produits></produits>',
        rights: { domain: 'produits', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "PRODUITS.BREADCRUMBS.PRODUITS_LIST" | translate}}'
        },
        // on affiche la liste des produits dans la navigation
        navigation: {
            menu: 'donnees_parametres_donnees',
            translate: 'PRODUITS.BREADCRUMBS.PRODUITS_LIST',
            order: 9,
            navigationCls: 'menu-item-separator'
        },
        // la liste des produits fait partie des points d'entrée recherchables dans la navigation
        search: 'PRODUITS.BREADCRUMBS.PRODUITS_LIST'
    });

    $stateProvider.state('produits.niveaux', {
        url: '/niveaux',
        template: '<produit-config-niveaux></produit-config-niveaux>',
        rights: { domain: 'nivh', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher produits > niveaux
            parent: 'produits.list',
            label: '{{ "PRODUITS.BREADCRUMBS.PRODUIT_NIVEAUX" | translate }}'
        }
    });

    $stateProvider.state('produits.niveauxStocks', {
        url: '/niveaux-stocks',
        template: '<produit-stock-config-niveaux></produit-stock-config-niveaux>',
        rights: { domain: 'nivh', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher produits > niveaux
            parent: 'produits.list',
            label: '{{ "PRODUITS.BREADCRUMBS.PRODUIT_NIVEAUX_STOCKS" | translate }}'
        }
    });

    $stateProvider.state('produits.new', {
        url: '/new',
        template: '<produit-form></produit-form>',
        rights: { domain: 'produits', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher produits > ajouter
            parent: 'produits.list',
            label: '{{ "PRODUITS.BREADCRUMBS.PRODUIT_NEW" | translate}}'
        },
        // l'ajout d'un produit fait partie des points d'entrée recherchables dans l'application
        search: 'PRODUITS.BREADCRUMBS.PRODUIT_NEW'
    });

    $stateProvider.state('produits.norme', {
        url: '/norme',
        template: '<produit-generation-norme></produit-generation-norme>',
        rights: { domain: 'produitidauto', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher produits > ajouter
            parent: 'produits.list',
            label: '{{ "PRODUITS.BREADCRUMBS.PRODUIT_GENERATE" | translate}}'
        },
        // l'ajout d'un produit fait partie des points d'entrée recherchables dans l'application
        search: 'PRODUITS.BREADCRUMBS.PRODUIT_GENERATE'
    });

    $stateProvider.state('produits.list.detail', {
        url: '/{id}',
        template: '<produit-detail></produit-detail>',
        rights: { domain: 'produits', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher produits > détail
            parent: 'produits.list',
            label: '{{ "PRODUITS.BREADCRUMBS.PRODUIT_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('produits.edit', {
        url: '/{id}/edit',
        template: '<produit-form></produit-form>',
        rights: { domain: 'produits', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher produits > modifier
            parent: 'produits.list',
            label: '{{ "PRODUITS.BREADCRUMBS.PRODUIT_EDIT" | translate }}'
        },
        params: {
            edit: true
        }
    });

    $stateProvider.state('produits.duplicate', {
        url: '/{id}/duplicate',
        template: '<produit-form></produit-form>',
        rights: { domain: 'produits', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher produits > copier
            parent: 'produits.list',
            label: '{{ "PRODUITS.BREADCRUMBS.PRODUIT_COPY" | translate }}'
        },
        params: {
            duplicate: true
        }
    });

    $stateProvider.state('produits.newType', {
        url: '/new-type',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        params: { parentState: 'produits.new' },
        ncyBreadcrumb: {
            parent: 'produits.new',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });

    $stateProvider.state('produits.duplicateNorme', {
        url: '/norme/{id}/duplicate',
        template: '<produit-generation-norme></produit-generation-norme>',
        rights: { domain: 'produitidauto', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher produits > ajouter
            parent: 'produits.list',
            label: '{{ "PRODUITS.BREADCRUMBS.PRODUIT_REGENERATE" | translate}}'
        },
        params: {
            produit: null
        },
        // l'ajout d'un produit fait partie des points d'entrée recherchables dans l'application
        search: 'PRODUITS.BREADCRUMBS.PRODUIT_REGENERATE'
    });
}

Configure.$inject = ['$stateProvider'];
