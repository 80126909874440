import MonitoringSynchroServeurClientController from './monitoring.synchro.serveur.client.controller';

export default {
    bindings: {
        sites: '<',
        domaines: '<'
    },
    template: require('./monitoring.synchro.serveur.client.html'),
    controller: MonitoringSynchroServeurClientController
};
