import validatorBuilder from 'validator-builder';

let paysEnteteValidator = null;

export default class PaysEntete {
	constructor(data = {}) {
		this.id = data.id;
		this.code = data.code;
		this.libelle = data.libelle;
		this.deviseId = data.deviseId;
		this.codeExists = data.codeExists || null;
		this.isMembreUE = data.isMembreUE || false;
	}

	isValid() {
		paysEnteteValidator = paysEnteteValidator || validatorBuilder.getInstanceFor('PaysEntete');
		const validationResults = paysEnteteValidator.validate(this);
		return validationResults.isValid;
	}
}
