RemisesFinAnneeController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'RemisesFinAnneeService',
	'notification',
	'$transitions'
];

export default function RemisesFinAnneeController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	RemisesFinAnneeService,
	notification,
	$transitions
) {
	const vm = this;

	let previousTableState;
	let selectedRemiseFinAnneeId = undefined;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.remisesFinAnnee = [];
	vm.societes = [];
	vm.nature = null;

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectRemiseFinAnnee = selectRemiseFinAnnee;
	vm.isRemiseFinAnneeSelected = isRemiseFinAnneeSelected;
	vm.closeDetail = closeDetail;
	vm.loadRemisesFinAnnee = loadRemisesFinAnnee;
	vm.deleteRemiseFinAnnee = deleteRemiseFinAnnee;
	vm.genererRemiseFinAnnee = genererRemiseFinAnnee;

	async function init() {
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));
		vm.societes = await RemisesFinAnneeService.getSocietes();
		if (shouldSetSelectedId()) {
			selectedRemiseFinAnneeId = $stateParams.id;
		}
	}

	vm.$onInit = () => init();

	function stateChangeSuccessFunc(event, toState) {
		if (!$stateParams.id) {
			selectedRemiseFinAnneeId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return (
			$state &&
			$state.current &&
			$state.current.name &&
			$state.current.name === 'remises-fin-annee.list.detail' &&
			$stateParams &&
			$stateParams.id
		);
	}

	function selectRemiseFinAnnee(remiseFinAnnee) {
		if (remiseFinAnnee && remiseFinAnnee.id) {
			if (selectedRemiseFinAnneeId !== remiseFinAnnee.id) {
				showDetail(remiseFinAnnee.id);
			} else {
				//si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
				closeDetail();
			}
		}
	}

	function isRemiseFinAnneeSelected(remiseFinAnnee) {
		return remiseFinAnnee && remiseFinAnnee.id && selectedRemiseFinAnneeId === remiseFinAnnee.id;
	}

	function showDetail(remiseFinAnneeId) {
		/*let modalInstance = ModalService.info({
            modalTitle: $translate.instant('REMISES_FIN_ANNEE.DETAIL.MODAL_HEADER', {libelle: libelle}),
            modalMsg: this.getTooltipDroits(droits),
            headerClass: 'modal-info'
        });*/

		vm.slideMargin['margin-right'] = vm.slideWidth;
		selectedRemiseFinAnneeId = remiseFinAnneeId;
		$state.go('remises-fin-annee.list.detail', { id: remiseFinAnneeId });
	}

	function closeDetail() {
		selectedRemiseFinAnneeId = undefined;
		$state.go('remises-fin-annee.list');
	}

	async function loadRemisesFinAnnee(tableState) {
		startLoading('chargement');

		if ($state && $state.current && $state.current.name !== 'remises-fin-annee.list') {
			$state.go('remises-fin-annee.list');
		}
		console.log(_.cloneDeep(tableState));
		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.remisesFinAnnee = [];

		try {
			const data = await RemisesFinAnneeService.getRemisesFinAnnee(filters, sorts, pagination);

			vm.remisesFinAnnee = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteRemiseFinAnnee(remiseFinAnnee) {
		if (remiseFinAnnee && remiseFinAnnee.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('REMISES_FIN_ANNEE.DELETE.TITLE', { libelle: remiseFinAnnee.libelle }),
				modalMsg: $translate.instant('REMISES_FIN_ANNEE.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading('delete');
				try {
					await RemisesFinAnneeService.deleteRemiseFinAnneeById(remiseFinAnnee.id);
					notification.success($translate.instant('REMISES_FIN_ANNEE.DELETE.SUCCESS'));
					selectedRemiseFinAnneeId = undefined;
					if (vm.params.id) {
						vm.state.go('remises-fin-annee.list');
					}
					loadRemisesFinAnnee();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function genererRemiseFinAnnee(rfa) {
		startLoading('generation');
		try {
			const resultatGeneration = await RemisesFinAnneeService.genererFacturesRFA(rfa);
			// si on a un objet, on va sur la page detail en passant l objet
			if (resultatGeneration.nbFacturesSelectionnees != 0) {
				try {
					$state.go('remises-fin-annee.generation', { obj: resultatGeneration });
				} catch (ex) {
					notification.error(ex.data);
				}
			} else {
				$state.go('remises-fin-annee.list');
				notification.info('REMISES_FIN_ANNEE.WARNING_GENERATION');
			}
		} catch (ex) {
			notification.error(ex.data);
			$state.go('remises-fin-annee.list');
		} finally {
			stopLoading();
		}
	}

	function startLoading(nature) {
		vm.loading = true;
		vm.nature = nature;
	}

	function stopLoading() {
		vm.loading = false;
		vm.nature = null;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
