import ProduitCopyModalMixdesignController from './produit.copy.modal.mixdesign.controller';

export default {
    bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<'
    },
    controller: ProduitCopyModalMixdesignController,
    template: require('./produit.copy.modal.mixdesign.html')
};
