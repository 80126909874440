export default class ClientDiversDetailController {
    static $inject = ['$stateParams', '$state', 'ClientsDiversService', 'notification', 'ClientsDiversCommunicationService'];

    constructor($stateParams, $state, ClientsDiversService, notification, ClientsDiversCommunicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.ClientsDiversService = ClientsDiversService;
        this.notification = notification;
        this.ClientsDiversCommunicationService = ClientsDiversCommunicationService;
    }

    $onInit() {
        this.loading = false;
        this.clientDivers = {};
        this.clientDiversId = undefined;

        this.initClientDivers();
    }

    async initClientDivers() {
        this.clientDiversId = this.$stateParams.id;

        this.startLoading();
        try {
            await this.getClientDiversEntete(this.clientDiversId);
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('clients-divers.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getClientDiversEntete(clientDiversId) {
        this.clientDivers.entete = await this.ClientsDiversService.getClientDiversEnteteById(clientDiversId);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
