// on met tout dans le controller à cause de la façon dont fonctionne le treeview
/* nécessaires au fonctionnement des niveaux hiérarchiques */
export default function TrameTreeviewServiceModel(config, onTrameChange) {
    const vm = this;
    const overrides = {
        changeNodeParent: onChangeNodeParent,
        createNode: onCreateNode,
        renameNode: onRenameNode,
        removeNode: onRemoveNode,
        moveNode: onMoveNode,
        codeExists: onCodeExists
    };

    // Méthodes
    vm.onTrameChange = onTrameChange;

    // Service
    // Pour chaque fonction du service
    // * Si un override, on prend cette fonction
    // * Sinon on prend la fonction par défaut
    angular.forEach(config, (func, name) => {
        vm[name] = overrides[name] ? overrides[name] : config[name];
    });

    // Méthodes
    async function onRenameNode(node) {
        const updateNode = await config.renameNode(node);
        onTrameChange(updateNode);
        return updateNode;
    }

    async function onChangeNodeParent(node, targetId, target, parent) {
        const updateNode = await config.changeNodeParent(node, targetId, target, parent);
        onTrameChange(updateNode);
        return updateNode;
    }

    async function onCreateNode(parent, node) {
        const newNode = await config.createNode(parent, node);
        onTrameChange(newNode);
        return newNode;
    }

    async function onRemoveNode(node) {
        await config.removeNode(node);
        onTrameChange(node);
        return node;
    }

    async function onMoveNode(direction, node) {
        await config.moveNode(direction, node);
        onTrameChange(node);
        return node;
    }

    //dans cette fonction, on verifi si un code pour un nouveau niveau hierarchique existe,
    //on n'a donc pas besoin d'update l'arbre
    async function onCodeExists(code) {
        try {
            return await config.codeExists(code);
        } catch (ex) {}
    }
}
