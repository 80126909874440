import PrelevementExportController from './prelevement.export.modal.controller';

export default {
    bindings: {
        resolve: '<',
        modalInstance: '<'
    },
    template: require('./prelevement.export.modal.html'),
    controller: PrelevementExportController
};
