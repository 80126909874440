import validatorBuilder from 'validator-builder';
import AdditionnalInfoMesure from '../nature-additionnal-info-mesure/additionnal.info.mesure.model';

let mesureSousMesuresValidator = null;

export default class MesureSousMesures {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.position = data.position;
        const setNature = function () {
            if (data.nature && data.nature.idNature >= 0) {
                return data.nature.idNature;
            } else if (data.idNature) {
                return data.idNature;
            }
            return undefined;
        };
        this.idNature = setNature();
        this.additionnalInfoMesure = new AdditionnalInfoMesure(this.idNature, data.additionnalInfoMesure);
        this.formule = data.formule;
        this.defaultValue = data.defaultValue;
        this.obligatoire = data.obligatoire;
        this.niveauVisibilite = data.niveauVisibilite;
    }

    isValid() {
        mesureSousMesuresValidator = mesureSousMesuresValidator || validatorBuilder.getInstanceFor('MesureSousMesures');
        const validationResults = mesureSousMesuresValidator.validate(this);
        return validationResults.isValid && this.additionnalInfoMesure.isValid();
    }
}
