import BonsConfig from './_config/bons.config';
import BonsRoutes from './_config/bons.routes';
import BonsComponent from './components/bons';
import BonsService from './services/bons.service';
import BonsCommunicationService from './services/bons.communication.service';

const moduleName = 'app.parametrageGeneral.bons';

angular
    .module(moduleName, [])
    .config(BonsConfig)
    .config(BonsRoutes)
    .service('BonsService', BonsService)
    .service('BonsCommunicationService', BonsCommunicationService)
    .component('bons', BonsComponent);

export default moduleName;
