export default function ProduitCoefficientkValidator(validator, moment, globalizationManagementService) {
    const instance = new validator();

    instance
        .ruleFor('coefK')
        .matches(/^\d*(\.\d{0,2})?$/)
        .withMessage('VALIDATION_NOT_DECIMAL');
    instance.ruleFor('coefK').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('normeSpecifique').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('date').must(beADate).withMessage('DATE_VALIDATION_WARNING');

    const dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;

    function beADate(coefficientK) {
        let date = null;
        if (!coefficientK.date || angular.isDate(coefficientK.date)) {
            return true;
        }
        if (coefficientK.date && typeof coefficientK.date === 'string' && coefficientK.date.includes('/')) {
            date = moment(coefficientK.date, dateFormat);
        }
        return moment(date, moment.ISO_8601).isValid();
    }

    return instance;
}

ProduitCoefficientkValidator.$inject = ['validator', 'moment', 'globalizationManagementService'];
