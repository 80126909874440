export default function Configure($stateProvider) {
    $stateProvider.state('grille-tarifaires', {
        parent: 'gestion',
        url: '/grille-tarifaires',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('grille-tarifaires.list', {
        url: '/list',
        template: '<grille-tarifaires></grille-tarifaires>',
        rights: { domain: 'grilletarif', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "GRILLES.BREADCRUMBS.GRILLE_TARIFAIRES_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'GRILLES.BREADCRUMBS.GRILLE_TARIFAIRES_LIST',
            order: 73,
            navigationCls: 'menu-item-separator'
        },
        search: 'GRILLES.BREADCRUMBS.GRILLE_TARIFAIRES_LIST'
    });
}

Configure.$inject = ['$stateProvider'];
