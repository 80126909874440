(function (angular, undefined) {
    'use strict';

    angular.module('blocks.loading').run(run);
    run.$inject = ['$templateCache'];

    function run($templateCache) {
        $templateCache.put('blocks/loading.simple.html', '<div class="ac-loading-spinner text-primary" ng-style="loader.style"></div>');

        $templateCache.put(
            'blocks/loading.double.html',
            '<div class="ac-loading-double-spinner outer" ng-style="loader.style"><div class="ac-loading-double-spinner inner" ng-style="loader.style"></div></div>'
        );
    }
})(angular);
