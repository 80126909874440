import ImageFormEnteteController from './image.form.entete.controller';

export default {
    bindings: {
        image: '<',
        form: '=',
        onUpdate: '&',
        onUpdateImage: '&'
    },
    template: require('./image.form.entete.html'),
    controller: ImageFormEnteteController
};
