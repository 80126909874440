import SiteCoordonnees from './site.coordonnees.model';

export default class SiteCoordonneesController {
    static $inject = ['$state', '$stateParams', 'SitesService', 'SitesTypesService', 'ModalService', 'notification', '$translate'];

    constructor($state, $stateParams, SitesService, SitesTypesService, ModalService, notification, $translate) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SitesService = SitesService;
        this.SitesTypesService = SitesTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
    }

    $onInit() {
        this.site = this.site || {};
        this.coordonnees = new SiteCoordonnees(this.site.coordonnees);
        this.getListeBanques();
        if (this.site.id) {
            this.getListeCoordonnees(this.site.id);
        }
        this.popoverModifCoordonnees = {
            templateUrl: 'popoverModifCoordonnees.html'
        };
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.coordonnees.codeExists = await this.SitesService.codeExists(code);
                }
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.coordonnees.codeExists = null;
        }
    }

    async getListeBanques() {
        this.startLoading();
        try {
            this.listeBanques = await this.SitesService.getBanques();
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.stopLoading();
        }
    }
    async getListeCoordonnees(idSite) {
        this.startLoading();
        try {
            this.site.listeCoordonnees = await this.SitesService.getCoordonnees(idSite);
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.stopLoading();
        }
    }
    async deleteCoordonnees(coord) {
        this.startLoading();
        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('SITES.COORDONNEES.DELETE.TITLE'),
            modalMsg: this.$translate.instant('SITES.COORDONNEES.DELETE.MESSAGE'),
            headerClass: 'modal-danger'
        });

        modalInstance.result.then(async () => {
            try {
                const id = await this.SitesService.deleteCoordonnees(coord.id);
                this.notification.success('SITES.COORDONNEES.DELETE.SUCCESS');
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            } finally {
                this.getListeCoordonnees(this.site.id);
            }
        });
        this.stopLoading();
    }
    async addRIB() {
        this.startLoading();
        try {
            if (this.new.banque && this.new.codeBanque && this.new.guichet && this.new.compte && this.new.rib) {
                this.new.idSite = this.site.id;
                this.new.nomBanque = this.new.banque.libelle;
                this.new.idBanque = this.new.banque.id;
                this.newCoord = new SiteCoordonnees(this.new);
                const id = await this.SitesService.persistCoordonnees(this.newCoord);
                this.getListeCoordonnees(this.site.id);
                this.new = {};
                this.notification.success('SITES.COORDONNEES.CREATED');
            }
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.stopLoading();
        }
    }
    initUpdate(coord, index) {
        this.edit = angular.copy(coord);
        this.edit.banque = {
            id: coord.banqueId,
            libelle: coord.nomBanque
        };
        this.clickedPopoverIndex = index;
    }
    async updateRIB() {
        this.startLoading();
        this.edit.nomBanque = this.edit.banque.libelle;
        this.edit.banqueId = this.edit.banque.id;
        try {
            const id = await this.SitesService.updateCoordonnees(this.edit.id, this.edit);
            this.edit = {};
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.getListeCoordonnees(this.site.id);
            this.stopLoading();
        }
    }

    setBanque() {
        if (this.new.banque.codeBanque != '') {
            this.new.codeBanque = this.new.banque.codeBanque;
        } else if (this.new.banque.bic != '' && this.new.banque.bic != null) {
            const codeBanqueFromBIC = this.new.banque.bic.substring(0, 4);
            this.new.codeBanque = codeBanqueFromBIC;
        }

        if (this.new.banque.bic != '' && this.new.banque.bic != null) {
            const codePaysFromBIC = this.new.banque.bic.substring(4, 6);
            this.new.banque.codePays = codePaysFromBIC;
            this.new.iban = codePaysFromBIC;
        } else {
            this.notification.warning(this.$translate.instant('SOCIETES.COORDONNEES.MISSINGDATABANQUE'));
        }
    }

    calcIban() {
        if (this.new.codeBanque != null && this.new.guichet != null && this.new.compte != null && this.new.rib != null) {
            // on concatene les éléments nécessaires
            const concatElts = this.new.codeBanque + this.new.guichet + this.new.compte + this.new.rib + this.new.banque.codePays + '00';
            // on parcourt la concatenation, et on converti les lettres en ascii puis on retranche 55
            let numberToModulo = '';
            for (let char = 0; char < concatElts.length; char++) {
                const element = concatElts[char];
                if (isNaN(element)) {
                    // convert and add
                    const c = element.charCodeAt(0);
                    const elementConverted = c - 55;
                    numberToModulo = numberToModulo + elementConverted;
                } else {
                    //add
                    numberToModulo = numberToModulo + element;
                }
            }

            // calculer le modulo 97 et on retranche 98 et on obtient la clé de contrôle
            const cleControle = 98 - (numberToModulo % 97);
            this.new.iban = this.new.banque.codePays + cleControle + this.new.codeBanque + this.new.guichet + this.new.compte + this.new.rib;
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
