(function (angular, undefined) {
    'use strict';

    angular.module('blocks.filters').filter('BooleanStartFilter', BooleanStartFilter);

    function BooleanStartFilter() {
        return function(text) {
            if (text) {
                return '*';
            }
        };
    }

})(angular);

