import ReferentielCaracteristiqueTypeProduits from './referentiel.caracteristique.type.produits.model';

export default class ReferentielCaracteristiqueTypeProduitsController {
    static $inject = ['_', 'ReferentielTypesService', 'notification', '$timeout', '$scope', '$filter'];

    constructor(_, ReferentielTypesService, notification, $timeout, $scope, $filter) {
        this._ = _;
        this.ReferentielTypesService = ReferentielTypesService;
        this.notification = notification;
        this.$timeout = $timeout;
        this.$scope = $scope;
        this.$filter = $filter;
    }

    $onInit() {
        this.loading = false;
        this.typeFormat = this.typeFormat || [];
        this.referentiel = this.referentiel || {};
        this.referentiel.niveauVisibilite = 0;
        this.caracteristiqueTypeProduits = new ReferentielCaracteristiqueTypeProduits(this.referentiel.caracteristiqueTypeProduits);
    }

    $onDestroy() {
        this.$timeout.cancel(this.typeFormatTimeout);
    }

    async $onChanges(objChanges) {
        if (objChanges.typeId) {
            if (!objChanges.typeId.isFirstChange()) {
                this.caracteristiqueTypeProduits = new ReferentielCaracteristiqueTypeProduits();
            }

            await this.getTypeFormat(objChanges.typeId.currentValue);

            this.selectedCaracteristique = [];
            this.localCaracteristiqueList = angular.copy(this.typeFormat);

            await this.getSelectedIsteven();
            await this.openEltCaract();
            this.getSelectedIstevenProduit();
        }
    }

    async getSelectedIsteven() {
        this.localCaracteristiqueList = this.$filter('selectInIsteven')(
            this.localCaracteristiqueList,
            'id',
            this.referentiel.caracteristiqueProduits,
            'selected',
            'id'
        );
        this.selectedCaracteristique = this.localCaracteristiqueList.filter((x) => x.selected);
    }

    getSelectedIstevenProduit() {
        for (let i = 0; i < this.referentiel.caracteristiqueTypeProduits.length; i++) {
            const eltCaract = this.referentiel.caracteristiqueTypeProduits[i];
            eltCaract.list = this.$filter('selectInIsteven')(eltCaract.list, 'code', this.referentiel.caracteristiqueProduits, 'selected', 'valeur');
        }
    }

    async openEltCaract() {
        if (this.selectedCaracteristique && this.selectedCaracteristique.length > 0) {
            try {
                this.referentiel.caracteristiqueTypeProduits = [];

                for (let i = 0; i < this.selectedCaracteristique.length; i++) {
                    const element = this.selectedCaracteristique[i];
                    const idCarac = element.id;
                    const libelleCaract = element.label;
                    this.eltList = await this.ReferentielTypesService.getElementsFromCaracteristique(element.id);

                    this.referentiel.caracteristiqueTypeProduits.push({
                        id: idCarac,
                        libelle: libelleCaract,
                        list: this.eltList
                    });
                }
                this.getSelectedIstevenProduit();
            } catch (er) {
                console.error(er);
            }
        }
    }

    // cette fonction est appelée automatiquement
    // a chaque digest (dirty check) d'angular
    // $doCheck() {
    //     if (!angular.equals(this.caracteristiqueTypeProduits, this.referentiel.caracteristiqueTypeProduits)) {
    //         this.onUpdate({
    //             caracteristiqueTypeProduits: angular.copy(this.caracteristiqueTypeProduits)
    //         });
    //     }
    // }

    async getTypeFormat(typeId) {
        if (typeId) {
            // on démarre l'animation de loading avant le chargement des données
            this.startLoading();

            // on encapsule l'appel serveur dans un try...catch pour récupérer les erreurs
            try {
                // on récupère les données depuis le service qui interroge le serveur
                const typeFormat = await this.ReferentielTypesService.getCaracteristiques(typeId);
                // on trie le tableau selon la propriété 'position'
                // on publie le tableau dans le this pour l'afficher dans la vue
                this.typeFormat = this._.sortBy(typeFormat, 'position');
                if (this.referentiel.traiteDuplication) {
                    if (this.caracteristiqueTypeProduits) {
                        this.typeFormat.forEach((x) => {
                            if (
                                !x.isDuplicable &&
                                this.caracteristiqueTypeProduits.data[x.id] &&
                                this.caracteristiqueTypeProduits.data[x.id].value !== undefined &&
                                this.caracteristiqueTypeProduits.data[x.id].value !== null
                            ) {
                                this.caracteristiqueTypeProduits.data[x.id].value = undefined;
                                this.caracteristiqueTypeProduits[x.id] = undefined;
                            }
                        });
                    }
                    delete this.referentiel.traiteDuplication;
                }
            } catch (ex) {
                // en cas de problème on notifie l'utilisateur
                this.notification.error(ex.data);
            } finally {
                // On supprimer le timeout précédent (fuite mémoire)
                this.$timeout.cancel(this.typeFormatTimeout);

                // une fois l'appel terminé, on stoppe l'animation de loading
                this.typeFormatTimeout = this.$timeout(() => {
                    this.stopLoading();
                });
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
