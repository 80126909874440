import FamillesConfig from './_config/familles.config';
import FamillesService from './services/familles.service';
import FamillesSelectComponent from './components/familles-select';
import FamillesMultiSelectComponent from './components/familles-multi-select';

const moduleName = 'app.massia.common.familles';

angular
    .module(moduleName, [])
    .config(FamillesConfig)
    .service('FamillesService', FamillesService)
    .component('famillesSelect', FamillesSelectComponent)
    .component('famillesMultiSelect', FamillesMultiSelectComponent);

export default moduleName;
