import { getNatureNamesByValues } from './natures.enum';

export default class CaracteristiquesService {
    static $inject = ['$http', '$translate'];

    constructor($http, $translate) {
        this.$http = $http;
        this.$translate = $translate;
        this.getUrlCaracteristiques = () => `${__configuration.apiUrl}/massia/caracteristiques`;
        this.getUrlDomaines = () => `${__configuration.apiUrl}/massia/domaines`;
        this.getUrlUnites = () => `${__configuration.apiUrl}/massia/unites-list`;
        this.getNatures = () => getNatureNamesByValues();
    }

    async getUnites() {
        const url = `${this.getUrlUnites()}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getCaracteristiques(filters, sorts, pagination) {
        const res = await this.$http.get(this.getUrlCaracteristiques(), {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || { skip: 0, take: 0 })
            }
        });

        return res.data;
    }

    async getDomaines(idCarac, filters, sorts, pagination, nomApplication) {
        const res = await this.$http.get(`${this.getUrlCaracteristiques()}/${idCarac}/domaines`, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {}),
                codeMetierToFilter: JSON.stringify(nomApplication || null)
            }
        });
        return res.data;
    }

    async getCaracteristique(idCaracteristique, nomApplication) {
        const url = `${this.getUrlCaracteristiques()}/${idCaracteristique}`;
        const res = await this.$http.get(url, {
            params: {
                codeMetierToFilter: JSON.stringify(nomApplication || null)
            }
        });

        res.data.idNature = res.data.nature.idNature;
        return res.data;
    }

    async getCaracteristiquesByDomaine(id) {
        const res = await this.$http.get(`${this.getUrlDomaines()}/${id}/caracteristiques`);
        return res.data || [];
    }

    async codeExists(code, idCarac) {
        const url = `${this.getUrlCaracteristiques()}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async createCaracteristique(caracteristique) {
        const data = caracteristique.getData();
        const result = await this.$http.post(this.getUrlCaracteristiques(), data);
        return result.data;
    }

    async updateCaracteristique(id, caracteristique) {
        const data = caracteristique.getData();
        const url = `${this.getUrlCaracteristiques()}/${id}`;
        return this.$http.put(url, data);
    }

    async deleteCaracteristique(caracteristique) {
        const url = `${this.getUrlCaracteristiques()}/${caracteristique.id}`;
        return this.$http.delete(url);
    }

    async getCaracteristiqueList(domaine, famille) {
        const url = `${__configuration.apiUrl}/massia/caracteristiques/caracteristique-domaine`;
        const res = await this.$http.get(url, {
            params: {
                domaine: domaine,
                famille: famille
            }
        });
        return res.data;
    }

    async GetCaracteristiqueByDomaineFormatType(domaine, format, type) {
        const url = `${__configuration.apiUrl}/massia/caracteristiques/${domaine}/${format}/${type}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getCaracteristiqueElementByCode(code) {
        const url = `${__configuration.apiUrl}/massia/caracteristiques/elements-by-code/${code}`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
