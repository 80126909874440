export default class ProduitFormSpecificitesController {
    static $inject = ['ProduitsService', 'notification', 'MassiaApplicationService'];

    constructor(ProduitsService, notification, MassiaApplicationService) {
        this.ProduitsService = ProduitsService;
        this.notification = notification;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        this.productionTypes = [];
        this.loadSpecificites();
        this.loadProductionTypes();
    }

    async loadProductionTypes() {
        this.productionTypes = await this.ProduitsService.getTypesProduction();
    }

    selectedTypeProd(selectedProd) {
        if (selectedProd?.length > 0) {
            this.produit.typeProduction = selectedProd[0];
        }
    }
    loadSpecificites() {
        this.startLoading();

        try {
            if (this.typeId) {
                this.type = this.produit.typeProduit;
            }
        } catch (ex) {
            this.notification.error(ex);
        } finally {
            this.stopLoading();
        }
    }

    async $onChanges(objChanges) {
        if (objChanges.typeId) {
            if (!objChanges.typeId.isFirstChange()) {
                await this.loadSpecificites();
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
