import controller from './formule.form.creation.controller';

export default {
    bindings: {
        formules: '=',
        formule: '=',
        producteur: '=',
        update: '<',
        onUpdate: '&',
        produit: '<',
        chartRecompo: '=',
        chart: '=',
        isCalculated: '='
    },
    template: require('./formule.form.creation.html'),
    controller: controller
};
