export default function Configure($stateProvider) {
    $stateProvider.state('syntheses', {
        parent: 'common',
        url: '/syntheses',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.STOCKS" | translate }}'
        }
    });

    $stateProvider.state('syntheses.list', {
        url: '/list',
        template: '<syntheses-conso-client></syntheses-conso-client>',
        rights: { domain: 'synthconsocli', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "SYNTHESES.BREADCRUMBS.SYNTHESES_CONSO_CLIENT_LIST" | translate}}'
        },
        navigation: {
            menu: 'stocks_no_category',
            translate: 'SYNTHESES.BREADCRUMBS.SYNTHESES_CONSO_CLIENT_LIST',
            order: 5,
            navigationCls: 'menu-item-separator'
        },
        search: 'SYNTHESES.BREADCRUMBS.SYNTHESES_CONSO_CLIENT_LIST'
    });

    // $stateProvider.state('syntheses.list.detail', {
    //     url: '/{id}',
    //     template: '<synthese-detail></synthese-detail>',
    //     rights: {domain: 'synthconsocli', right: 'read'},
    //     ncyBreadcrumb: {
    //         parent: 'syntheses.list',
    //         label: '{{ "SYNTHESES.BREADCRUMBS.SYNTHESE_DETAIL" | translate }}'
    //     }
    // });
}

Configure.$inject = ['$stateProvider'];
