import ProduitEnteteNorme from './produit.entete.norme.model';
import { FilterType } from '../../../../../../core/interface/FilterProperty';
import { SiteGenre } from '../../../../../../core/enum/SiteGenre';

/* @ngInject */
export default class ProduitEnteteController {
    constructor(
        _,
        $state,
        $stateParams,
        ProduitsCommunicationService,
        ProduitsService,
        ProduitsTypesService,
        TypesService,
        ModalService,
        notification,
        MassiaApplicationService,
        SitesService,
        SiteService
    ) {
        this._ = _;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ProduitsCommunicationService = ProduitsCommunicationService;
        this.ProduitsService = ProduitsService;
        this.ProduitsTypesService = ProduitsTypesService;
        this.TypesService = TypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.MassiaApplicationService = MassiaApplicationService;
        this.siteSvc = SiteService;
        this.SitesService = SitesService;
    }

    async $onInit() {
        this.loading = false;
        if (this.$stateParams.id) {
            this.isEditMode = true;
        }
        this.produit = this.produit || {};
        this.produit.entete = this.produit.entete || {};
        this.entete = new ProduitEnteteNorme(this.produit.entete);
        this.types = this.types || [];

        this.sites = this.sites || [];

        this.serieTamisPetitd = {};
        this.serieTamisGrandD = {};

        this.typeSelected = {};

        this.getTypes();
        //this.getTamis();

        // on sauvegarde la fonction de désabonnement pour l'invoquer au destroy
        // il est important de noter la façon de s'abonner :
        // on utilise les arrow functions, () => {},
        // elles permettent de sauvegarder l'état du 'this'
        // et d'éviter la perte du contexte lors de l'appel de la fonction depuis un autre controller
        this.unregisterReinit = this.ProduitsCommunicationService.registerReinit(() => this.initForm());
        await this.getNiveauVisibilite();

        this.onUpdate({
            entete: angular.copy(this.entete)
        });
        //console.log(this.typeSelected);
    }

    async getNiveauVisibilite() {
        if (this.entete.typeId) {
            this.entete.niveauVisibilite = await this.ProduitsTypesService.GetNiveauVisibilite(this.entete.typeId);
        }
    }

    // SL si un changement est fait dans le parent,
    // on met a jour les donnees dans l enfant
    // $onChanges(objChanges) {
    //     if (objChanges.produit) {
    //         this.getTypes();
    //         // this.getSites();
    //         this.getTamis();
    //     }
    // }

    // SL si angular voit que les donnees originales sont differentes des donnees dupliquees
    // le parent recupere les donnees de l enfant
    $doCheck() {
        if (!angular.equals(this.entete, this.produit.entete)) {
            this.onUpdate({
                entete: angular.copy(this.entete)
            });
        }
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    async createType() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'PRODUITS.CONFIRM_CREATE_TYPE.TITLE',
            modalMsg: 'PRODUITS.CONFIRM_CREATE_TYPE.MESSAGE',
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(() => this.$state.go('produits.newType'));
    }

    async getTypes() {
        this.startLoading();
        try {
            this.types = await this.ProduitsTypesService.getAllNorme();
            this.changeType(this.entete.typeId);
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async changeType(selected) {
        if (selected && this.produit && this.produit.entete && this.entete) {
            this.typeSelected = await this.TypesService.getTypeById(selected);
            if (this.typeSelected) {
                this.produit.entete.idSerieDim = this.typeSelected.idSerieDimensionnelleTamis;
                this.produit.entete.granulometrie = this.typeSelected.enumGranulometrie;
                this.produit.entete.typeCode = this.typeSelected.code;
            }

            this.entete.idSerieDim = this.produit.entete.idSerieDim;
            this.entete.granulometrie = this.produit.entete.granulometrie;
            this.entete.typeCode = this.produit.entete.typeCode;
            this.getTamis();
        } else {
            this.serieTamisPetitd = {};
            this.serieTamisGrandD = {};
        }
        this.getNiveauVisibilite();
        await this.getSites();
        // console.log(this.typeSelected);
    }

    async getTamis() {
        this.startLoading();
        try {
            if (this.produit && this.produit.entete && this.produit.entete.idSerieDim) {
                this.serieTamisPetitd = await this.ProduitsService.getTamis(this.produit.entete.idSerieDim);
                angular.copy(this.serieTamisPetitd, this.serieTamisGrandD);
                /*for(var i = this.serieTamisGrandD.tamis.length - 1; i >= 0; i--){
                    if(this.serieTamisGrandD.tamis[i].ouverture == 0){
                        this.serieTamisGrandD.tamis.splice(i,1);
                    }
                } */ //SP 31/07/17 remise en place tamis D à 0 pour les boues
            } else {
                this.serieTamisPetitd = {};
                this.serieTamisGrandD = {};
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getSites() {
        if (this.typeSelected.idFamille) {
            this.startLoading();
            try {
                //this.sites = await this.ProduitsService.getProducteursList();
                // this.sites = await this.SitesService.getSitesProducteursByFamille(
                //     this.MassiaApplicationService.getApplication(),
                //     'BPE'
                // );
                const sites = await this.siteSvc.getAll([
                    {
                        criterion: 'familles.famille.id',
                        type: FilterType.EQUALS,
                        value: this.typeSelected?.idFamille
                    },
                    {
                        criterion: 'genre',
                        type: FilterType.CONTAINS,
                        value: SiteGenre.Producteur
                    }
                ]);
                this.sites = sites.items;
            } catch (ex) {
                console.error(ex);
            } finally {
                this.stopLoading();
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.produit.entete.codeExists = null;
        } else {
            this.startCodeLoading();
            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.entete.codeExists = await this.ProduitsService.codeExists(code);
                    this.entete.code = code;
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        }
    }

    changeLibelle(libelle) {
        if (libelle) {
            this.entete.libelleGenere = libelle;
            this.entete.libelle = libelle;
        }
    }

    changeAppelation(appelation) {
        if (appelation) {
            this.entete.appelationGenere = appelation;
            this.entete.appelation = appelation;
        }
    }

    selectProducteur() {
        const currentSites = [];
        if (this.entete.idSite) {
            const idx = this.sites.findIndex((e) => e.id === this.entete.idSite);
            if (idx >= 0) {
                currentSites.push({ id: this.sites[idx].id, libelle: this.sites[idx].libelle });
            }
        }

        this.entete.sites = currentSites.map((e) => e.libelle);
        this.entete.sitesIds = currentSites.map((e) => e.id);
        this.entete.reloadSitesLies = !this.entete.reloadSitesLies;

        this.onUpdate({
            entete: angular.copy(this.entete)
        });
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
