import validatorBuilder from 'validator-builder';

let DemandeLivraisonValidator = null;

export default class DemandeLivraison {
    constructor(data) {
        data = data || {};
        this.id = data.id || undefined;
        this.code = data.code || undefined;
        this.societeId = data.societeId;
        this.societe = data.societe;
        this.siteDepartId = data.siteDepartId;
        this.siteDepart = data.siteDepart;
        this.dateDemande = data.dateDemande;
        this.priorite = data.priorite;
        this.achatVente = data.achatVente;
        this.clientId = data.clientId;
        this.client = data.client;
        this.libelle = data.libelle || undefined;
        this.chantierId = data.chantierId;
        this.chantier = data.chantier;
        this.reference = data.reference;
        this.commandeId = data.commandeId;
        this.commande = data.commande;
        this.contactId = data.contactId;
        this.contact = data.contact;
        this.lieuId = data.lieuId;
        this.lieuCode = data.lieuCode;
        this.lieu = data.lieu;
        this.sens = data.sens;
        this.siteProduitId = data.siteProduitId;
        this.quantiteDemandee = data.quantiteDemandee;
        this.dureeTrajet = data.dureeTrajet;
        this.heureDebutChantier = data.heureDebutChantier;
        this.heureDebutLivraison = data.heureDebutLivraison;
        this.heureFinLivraison = data.heureFinLivraison;
        this.commentaireClient = data.commentaireClient;
        this.commentaireLivraison = data.commentaireLivraison;
        this.commentaireInterne = data.commentaireInterne;
        this.suivis = data.suivis;
        this.quantiteTotaleLivree = data.quantiteTotaleLivree;
        this.quantiteTotaleRestanteLivrer = data.quantiteTotaleRestanteLivrer;
        this.quantiteTotaleRestantePlanifier = data.quantiteTotaleRestantePlanifier;
    }

    isValid() {
        DemandeLivraisonValidator = DemandeLivraisonValidator || validatorBuilder.getInstanceFor('DemandeLivraison');
        const validationResults = DemandeLivraisonValidator.validate(this);
        return validationResults.isValid;
    }
}
