import TrameTreeviewServiceModel from './trame.treeview.service.model';
import ModaleCreationTrameController from './modale-creation/modale.creation.trame.controller';

export default class NiveauxHierarchiquesTrameController {
    static $inject = ['$translate', 'ModalService', 'AcTreeviewCommunicationService', 'TypesNiveauxHierarchique'];

    constructor($translate, ModalService, AcTreeviewCommunicationService, TypesNiveauxHierarchique) {
        // Injections
        this.$translate = $translate;
        this.ModalService = ModalService;
        this.AcTreeviewCommunicationService = AcTreeviewCommunicationService;
        this.TypesNiveauxHierarchique = TypesNiveauxHierarchique;

        // Properties
        this.config = {};
        this.service = {};
    }

    // Component events
    $onInit() {
        this.config = this.buildConfig(this.treeviewConfig);
        this.service = this.buildService(this.treeviewService);
        this.service.setTreeviewName(this.config.name);
    }

    // Actions
    onTreeviewChange(node) {
        this.onTrameChange({ node: node });
    }

    buildService(treeviewService) {
        return new TrameTreeviewServiceModel(treeviewService, (node) => this.onTreeviewChange(node));
    }

    buildConfig(treeviewConfig) {
        treeviewConfig.functions = treeviewConfig.functions || {};

        return {
            functionsScope: this,
            name: treeviewConfig.name,
            displayTreeActions: treeviewConfig.displayTreeActions || false,
            dropDisabled: treeviewConfig.dropDisabled || true,
            searchDisabled: treeviewConfig.searchDisabled || true,
            autoExpand: treeviewConfig.autoExpand || true,
            rootType: treeviewConfig.rootType || 'root',
            rootTypeIcon: treeviewConfig.rootTypeIcon || 'list-alt',
            orderByFunction: treeviewConfig.orderByFunction || undefined,
            functions: {
                getStyle: this.onGetStyle,
                canRefreshNode: this.onCanRefreshNode,
                canToggleNode: this.onCanToggleNode,
                getNodeIcon: this.onGetNodeIcon,
                canRenameNode: this.onCanRenameNode,
                renameNode: this.onRenameNode,
                canCreateNode: this.onCanCreateNode,
                createNode: this.onCreateNode,
                canRemoveNode: this.onCanRemoveNode,
                removeNode: this.onRemoveNode,
                canMoveNodeUp: this.onCanMoveNodeUp,
                moveNodeUp: this.onMoveNodeUp,
                canMoveNodeDown: this.onCanMoveNodeDown,
                moveNodeDown: this.onMoveNodeDown
            }
        };
    }

    // Override configuration metier
    onGetStyle() {
        if (angular.isFunction(this.treeviewConfig.getStyle)) {
            this.treeviewConfig.getStyle.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.getStyle.apply(this, arguments);
        }
    }

    onCanRefreshNode() {
        if (angular.isFunction(this.treeviewConfig.onCanRefreshNode)) {
            this.treeviewConfig.onCanRefreshNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.canRefreshNode.apply(this, arguments);
        }
    }

    onCanToggleNode() {
        if (angular.isFunction(this.treeviewConfig.canToggleNode)) {
            this.treeviewConfig.canToggleNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.canToggleNode.apply(this, arguments);
        }
    }

    onGetNodeIcon() {
        if (angular.isFunction(this.treeviewConfig.getNodeIcon)) {
            this.treeviewConfig.getNodeIcon.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.getNodeIcon.apply(this, arguments);
        }
    }

    onCanRenameNode() {
        if (angular.isFunction(this.treeviewConfig.canRenameNode)) {
            this.treeviewConfig.canRenameNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.canRenameNode.apply(this, arguments);
        }
    }

    onRenameNode() {
        if (angular.isFunction(this.treeviewConfig.renameNode)) {
            this.treeviewConfig.renameNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.renameNode.apply(this, arguments);
        }
    }

    onCanCreateNode() {
        if (angular.isFunction(this.treeviewConfig.canCreateNode)) {
            this.treeviewConfig.canCreateNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.canCreateNode.apply(this, arguments);
        }
    }

    onCreateNode() {
        if (angular.isFunction(this.treeviewConfig.createNode)) {
            this.treeviewConfig.createNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.createNode.apply(this, arguments);
        }
    }

    onCanRemoveNode() {
        if (angular.isFunction(this.treeviewConfig.canRemoveNode)) {
            this.treeviewConfig.canRemoveNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.canRemoveNode.apply(this, arguments);
        }
    }

    onRemoveNode() {
        if (angular.isFunction(this.treeviewConfig.removeNode)) {
            this.treeviewConfig.removeNode.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.removeNode.apply(this, arguments);
        }
    }

    onCanMoveNodeUp() {
        if (angular.isFunction(this.treeviewConfig.canMoveNodeUp)) {
            this.treeviewConfig.canMoveNodeUp.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.canMoveNodeUp.apply(this, arguments);
        }
    }

    onMoveNodeUp() {
        if (angular.isFunction(this.treeviewConfig.moveNodeUp)) {
            this.treeviewConfig.moveNodeUp.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.moveNodeUp.apply(this, arguments);
        }
    }

    onCanMoveNodeDown() {
        if (angular.isFunction(this.treeviewConfig.canMoveNodeDown)) {
            this.treeviewConfig.canMoveNodeDown.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.canMoveNodeDown.apply(this, arguments);
        }
    }

    onMoveNodeDown() {
        if (angular.isFunction(this.treeviewConfig.moveNodeDown)) {
            this.treeviewConfig.moveNodeDown.apply(this.treeviewConfig.functionsScope, arguments);
        } else {
            return this.moveNodeDown.apply(this, arguments);
        }
    }

    // Configuration treeview
    getStyle(item) {
        if (item && item.id === 'root') {
            return 'root-item';
        }
    }

    canRefreshNode(item) {
        return item && item.type !== this.TypesNiveauxHierarchique.Libre.value && item.type !== this.TypesNiveauxHierarchique.Pays.value;
    }

    canToggleNode(item) {
        return item && item.id !== 'root' && item.type !== this.TypesNiveauxHierarchique.Libre.value;
    }

    getNodeIcon(item) {
        if (item.type === this.TypesNiveauxHierarchique.Libre.value) {
            return 'glyphicon-play';
        } else if (item.type === this.TypesNiveauxHierarchique.Pays.value) {
            return 'glyphicon-globe';
        }
        return item.expanded ? 'glyphicon-folder-open' : 'glyphicon-folder-close';
    }

    canRenameNode(item) {
        return !(item && item.id === 'root');
    }

    renameNode(item) {
        this.service.renameNode(item);
    }

    canCreateNode(item) {
        return !(item && (item.type === this.TypesNiveauxHierarchique.Libre.value || item.type === this.TypesNiveauxHierarchique.Pays.value));
    }

    async createNode(parent) {
        const modalInstance = this.ModalService.open({
            template: require('./modale-creation/modale.creation.trame.tpl.html'),
            controller: ModaleCreationTrameController,
            controllerAs: '$ctrl',
            size: 'md',
            resolve: {
                data: () => {
                    return {
                        parentNode: parent,
                        service: this.service
                    };
                }
            }
        });
    }

    canRemoveNode(item) {
        return !(item && item.id === 'root');
    }

    removeNode(item) {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'SUPPRIMER',
            modalMsg: this.$translate.instant('SUPPRIMER_CONFIRMATION', { entity: item.label }),
            headerClass: 'modal-danger'
        });

        modalInstance.result.then(() => this.service.removeNode(item));
    }

    canMoveNodeUp(item) {
        return item && item.id !== 'root';
    }

    moveNodeUp(item) {
        this.service.moveNode('up', item);
    }

    canMoveNodeDown(item) {
        return item && item.id !== 'root';
    }

    moveNodeDown(item) {
        this.service.moveNode('down', item);
    }
}
