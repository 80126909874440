const serviceUrl = `${__configuration.apiUrl}/massia/appellation`;

export default class AppellationsCommercialesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getProduits(idProduit, filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                idProduit: idProduit,
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });
        return res.data;
    }

    async getSiteClientsByName(valeur) {
        const idUtilisateur = 0;
        //SR : changement de route : la route précédente ne récupère que 100 éléments (sans raison ici car le filtre est coté client)
        //const nomSiteClientUrl = `${__configuration.apiUrl}/massia/sites-clients/typeAhead/${valeur}/utilisateur/${idUtilisateur}`;
        const nomSiteClientUrl = `${__configuration.apiUrl}/massia/sites-clients/all`;
        const res = await this.$http.get(nomSiteClientUrl);
        return res.data;
    }
}
