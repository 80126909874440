import ValorisationStocksFiltres from './valorisation.stocks.filtres.model';

ValorisationStocksController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'StocksService',
    'StocksCommunicationService',
    'notification',
    '_',
    'moment',
    '$uibModal',
    'MOPService'
];

export default function ValorisationStocksController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    StocksService,
    StocksCommunicationService,
    notification,
    _,
    moment,
    $uibModal,
    MOPService
) {
    const vm = this;
    const watchers = [];

    vm.ongletOpen = {
        isEnteteOpen: true,
        isValorisationStocksOpen: false
    };

    vm.loadValorisationStocks = loadValorisationStocks;
    vm.getSitesCommerciaux = getSitesCommerciaux;
    vm.getEltsNiv1 = getEltsNiv1;
    vm.getEltsNiv2 = getEltsNiv2;
    vm.getEltsNiv3 = getEltsNiv3;
    vm.typeAnalyseDemande = '';
    vm.openDepreciationQte = openDepreciationQte;
    vm.openDepreciationPrix = openDepreciationPrix;
    vm.changeSite = changeSite;
    vm.siteCommercial = undefined;
    vm.videSiteCommercial = videSiteCommercial;
    vm.changePeriode = changePeriode;
    vm.libPeriodeCloture = '';
    vm.estSaisiePossible = false;
    vm.print = print;
    vm.MOPService = MOPService;

    async function init() {
        vm.MOPService.setMop([{ title: 'STOCKS.BREADCRUMBS.ETAT_VALORISATION_STOCKS', filename: 'GestiondesStocks.pdf', application: 'gestion' }]);
        //getSites();
        getEltsNiv1();
        vm.valorisationStocksFiltres = new ValorisationStocksFiltres();
        const maDate = new Date();
        vm.valorisationStocksFiltres.annee = maDate.getFullYear();
        const _this = vm;
        $scope.$watch('$ctrl.valorisationStocksFiltres.idPeriode', function (newValue, oldValue) {
            _this.changePeriode(newValue);
        });
        /*$scope.$watch('$ctrl.valorisationStocksFiltres.annee', function (newValue, oldValue) {
            _this.changePeriode(newValue);
        });*/

        const siteInit = await StocksService.getSitesCommerciaux(null);
        if (siteInit.length == 1) {
            vm.siteCommercial = {
                id: siteInit[0].id,
                libelle: siteInit[0].libelle,
                code: siteInit[0].code
            };
        }
    }

    init();

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    async function changeSite() {
        vm.valorisationStocksFiltres.idSite = vm.siteCommercial ? vm.siteCommercial.id : null;
        if (vm.siteCommercial && vm.valorisationStocksFiltres.annee) {
            vm.periodes = await StocksService.getPeriodes(vm.valorisationStocksFiltres.annee, vm.siteCommercial.id);
        } else {
            vm.periodes = [];
        }
    }

    async function changePeriode() {
        // vm.estSaisiePossible = false;
        vm.estSaisiePossible = true;
        if (vm.valorisationStocksFiltres.idPeriode) {
            const idx = _.findIndex(vm.periodes, { idCloture: vm.valorisationStocksFiltres.idPeriode });
            if (idx > -1) {
                if (vm.periodes[idx].estCloture) {
                    vm.libPeriodeCloture = $translate.instant('STOCKS.PERIODE_CLOTUREE');
                } else {
                    vm.libPeriodeCloture = $translate.instant('STOCKS.PERIODE_NON_CLOTUREE');
                    vm.estSaisiePossible = true;
                }
            } else {
                vm.libPeriodeCloture = '';
            }
        } else {
            vm.libPeriodeCloture = '';
        }
    }

    async function loadValorisationStocks() {
        if (vm.valorisationStocksFiltres.isValid()) {
            startLoading();

            vm.ongletOpen = {
                isEnteteOpen: true,
                isValorisationStocksOpen: true
            };

            vm.valorisationStock = {};

            try {
                vm.valorisationStock = await StocksService.getValorisationStocks(
                    vm.valorisationStocksFiltres.idSite,
                    vm.valorisationStocksFiltres.idPeriode,
                    vm.valorisationStocksFiltres.eltNiv1,
                    vm.valorisationStocksFiltres.eltNiv2,
                    vm.valorisationStocksFiltres.eltNiv3,
                    vm.typeAnalyseDemande,
                    vm.valorisationStocksFiltres.annee
                );
            } catch (ex) {
                notification.error(ex.data);
            } finally {
                stopLoading();
            }
        } else {
            $scope.$broadcast('valorisationStocksFiltresValidations');
        }
    }

    function videSiteCommercial() {
        vm.siteCommercial = undefined;
    }

    /*async function getSites() {
        startLoading();
        try {
            vm.sites = await StocksService.getSites();
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }*/

    async function getSitesCommerciaux(valeur) {
        const sitesCommerciaux = await StocksService.getSitesCommerciaux(valeur);
        return sitesCommerciaux;
    }

    async function getEltsNiv1() {
        startLoading();
        try {
            vm.eltsNiv1 = await StocksService.getElementsNiv1();
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function getEltsNiv2() {
        startLoading();
        try {
            if (vm.valorisationStocksFiltres.eltNiv1) {
                vm.eltsNiv2 = await StocksService.getElementsNiv2(vm.valorisationStocksFiltres.eltNiv1);
            } else {
                vm.eltsNiv2 = undefined;
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function getEltsNiv3() {
        startLoading();
        try {
            if (vm.valorisationStocksFiltres.eltNiv1 && vm.valorisationStocksFiltres.eltNiv2) {
                vm.eltsNiv3 = await StocksService.getElementsNiv3(vm.valorisationStocksFiltres.eltNiv1, vm.valorisationStocksFiltres.eltNiv2);
            } else {
                vm.eltsNiv3 = undefined;
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }

    function openDepreciationQte(valorisationStockLigne) {
        const _this = this;
        _this.source = valorisationStockLigne;
        $uibModal
            .open({
                template: '<depreciation-stock-qte ligne="$ctrl.source" modal-instance="$ctrl.uibModalInstance"></depreciation-stock-qte>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = _this.source;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl',
                backdrop: false
            })
            .result.then(
                async function (result) {
                    if (result) {
                        startLoading();
                        try {
                            await StocksService.sauveDepreciation(
                                true,
                                vm.valorisationStocksFiltres.idSite,
                                vm.valorisationStocksFiltres.idPeriode,
                                valorisationStockLigne.idProduit,
                                valorisationStockLigne.idSiteProducteur,
                                valorisationStockLigne.idEmplacement,
                                valorisationStockLigne.idMotifFinalQte,
                                valorisationStockLigne.stockFinalQuantiteDepreciee,
                                undefined
                            );
                        } catch (ex) {
                            notification.error(ex.data);
                        } finally {
                            stopLoading();
                        }
                        loadValorisationStocks();
                    }
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    function openDepreciationPrix(valorisationStockLigne) {
        const _this = this;
        _this.source = valorisationStockLigne;
        $uibModal
            .open({
                template: '<depreciation-stock-prix ligne="$ctrl.source" modal-instance="$ctrl.uibModalInstance"></depreciation-stock-prix>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = _this.source;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl',
                backdrop: false
            })
            .result.then(
                async function (result) {
                    if (result) {
                        startLoading();
                        try {
                            await StocksService.sauveDepreciation(
                                false,
                                vm.valorisationStocksFiltres.idSite,
                                vm.valorisationStocksFiltres.idPeriode,
                                valorisationStockLigne.idProduit,
                                valorisationStockLigne.idSiteProducteur,
                                valorisationStockLigne.idEmplacement,
                                valorisationStockLigne.idMotifFinalPrix,
                                undefined,
                                valorisationStockLigne.stockFinalPrixDeprecie
                            );
                        } catch (ex) {
                            notification.error(ex.data);
                        } finally {
                            stopLoading();
                        }
                        loadValorisationStocks();
                    }
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    async function print() {
        startLoading();
        const fileName = Date.now();

        const resultat = await StocksService.printValorisationStock(
            vm.valorisationStocksFiltres.idSite,
            vm.valorisationStocksFiltres.idPeriode,
            vm.valorisationStocksFiltres.eltNiv1,
            vm.valorisationStocksFiltres.eltNiv2,
            vm.valorisationStocksFiltres.eltNiv3,
            vm.typeAnalyseDemande,
            vm.valorisationStocksFiltres.annee
        );

        const data = resultat.data;
        const status = resultat.status;
        let headers = resultat.headers;

        headers = headers();

        const contentType = headers['content-type'];

        const fileExtention = '.xlsx';
        const linkElement = document.createElement('a');
        try {
            const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
            const url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute('download', fileName + fileExtention);

            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(clickEvent);
        } catch (ex) {
        } finally {
            //this.stopLoading();
        }
        stopLoading();
    }
}
