export default function Configure($stateProvider) {
    $stateProvider.state('modelessaisiesm', {
        parent: 'common',
        url: '/modelessaisiesm',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.SECURITE_MATERIEL" | translate }}'
        }
    });

    $stateProvider.state('modelessaisiesm.list', {
        url: '/list',
        template: '<modeles-saisies></modeles-saisies>',
        rights: { domain: 'modelessaisiesm', right: 'read' },
        ncyBreadcrumb: {
            parent: 'modelessaisiesm',
            label: '{{ "MODELES_SAISIES.BREADCRUMBS.MODELES_SAISIES_LIST" | translate}}'
        },
        navigation: {
            menu: 'securite_materiel_donnees',
            translate: 'MODELES_SAISIES.BREADCRUMBS.MODELES_SAISIES_LIST',
            order: 6
        },
        search: 'MODELES_SAISIES.BREADCRUMBS.MODELES_SAISIES_LIST'
    });

    $stateProvider.state('modelessaisiesm.list.detail', {
        url: '/{id}',
        template: '<modele-saisie-detail></modele-saisie-detail>',
        rights: { domain: 'modelessaisiesm', right: 'read' },
        ncyBreadcrumb: {
            parent: 'modelessaisiesm.list',
            label: '{{ "MODELES_SAISIES.BREADCRUMBS.MODELE_SAISIE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('modelessaisiesm.new', {
        url: '/new',
        template: '<modele-saisie-form></modele-saisie-form>',
        rights: { domain: 'modelessaisiesm', right: 'create' },
        ncyBreadcrumb: {
            parent: 'modelessaisiesm.list',
            label: '{{ "MODELES_SAISIES.BREADCRUMBS.MODELE_SAISIE_NEW" | translate}}'
        },
        search: 'MODELES_SAISIES.BREADCRUMBS.MODELE_SAISIE_NEW'
    });

    $stateProvider.state('modelessaisiesm.edit', {
        url: '/{id}/edit',
        template: '<modele-saisie-form></modele-saisie-form>',
        rights: { domain: 'modelessaisiesm', right: 'update' },
        ncyBreadcrumb: {
            parent: 'modelessaisiesm.list',
            label: '{{ "MODELES_SAISIES.BREADCRUMBS.MODELE_SAISIE_EDIT" | translate }}'
        }
    });

    $stateProvider.state('modelessaisiesm.newType', {
        url: '/new-type',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        params: { parentState: 'modelessaisiesm.new' },
        ncyBreadcrumb: {
            parent: 'modelessaisiesm.new',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });

    $stateProvider.state('modelessaisiesm.duplicate', {
        url: '/{id}/duplicate',
        template: '<modele-saisie-form></modele-saisie-form>',
        rights: { domain: 'modelessaisiesm', right: 'create' },
        ncyBreadcrumb: {
            parent: 'modelessaisiesm.list',
            label: '{{ "MODELES_SAISIES.BREADCRUMBS.MODELE_SAISIE_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });
}

Configure.$inject = ['$stateProvider'];
