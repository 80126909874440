export default function accordeonCloseDirective() {
    return {
        retrict: 'A',
        replace: false,
        transclude: false,
        scope: {
            accordeonClose: '='
        },
        link: function (scope, element, attrs) {
            element.bind('click', onAccordeonOpen);

            function onAccordeonOpen() {
                if (scope.hasOwnProperty('accordeonClose')) {
                    for (const key in scope.accordeonClose) {
                        if (scope.accordeonClose.hasOwnProperty(key)) {
                            scope.accordeonClose[key] = false;
                        }
                    }
                    scope.$apply();
                }
            }
        }
    };
}
