export default class InerteDeclarationAnnuelleController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'InerteDeclarationAnnuelleService',
        'notification',
        '$location',
        '$anchorScroll',
        '$translate'
    ];

    constructor($scope, $state, $stateParams, InerteDeclarationAnnuelleService, notification, $location, $anchorScroll, $translate) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.InerteDeclarationAnnuelleService = InerteDeclarationAnnuelleService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.$translate = $translate;

        this.ongletOpen = {
            isEnteteOpen: true
        };
    }

    async $onInit() {
        this.getSites();
    }

    async print() {
        if (this.annee && this.idSite) {
            this.startLoading();
            const fileName = Date.now();
            const resultat = await this.InerteDeclarationAnnuelleService.print(this.idSite, this.annee, fileName);

            const data = resultat.data;
            const status = resultat.status;
            let headers = resultat.headers;

            headers = headers();

            const contentType = headers['content-type'];

            const linkElement = document.createElement('a');
            try {
                const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                const url = window.URL.createObjectURL(blob);
                linkElement.setAttribute('href', url);
                linkElement.setAttribute('download', fileName + '.zip');

                const clickEvent = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: false
                });
                linkElement.dispatchEvent(clickEvent);
            } catch (ex) {
            } finally {
                this.stopLoading();
            }
        }
    }

    async getSites() {
        this.startLoading();
        try {
            this.sites = await this.InerteDeclarationAnnuelleService.getSites();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async appliquer() {
        await this.print();
    }

    async confirmer() {
        await this.print();
        this.annuler();
    }

    annuler() {
        this.$state.go('gestion.home');
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
