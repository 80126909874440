/* @ngInject */
export default class MesureFormAccreditationController {
    constructor(MesuresService, notification, ModalService, $translate, $state) {
        this.MesuresService = MesuresService;
        this.notification = notification;
        this.modalService = ModalService;
        this.$translate = $translate;
        this.$state = $state;
    }

    laboratoires = [];

    async $onInit() {
        this.loading = true;
        this.laboratoires = await this.MesuresService.getLaboratoires();
        this.selectedLaboratoires();
        this.loading = false;
    }

    selectedLaboratoires() {
        this.laboratoires.forEach((labo) => {
            const index = this.mesure.certifications.findIndex((x) => x.idSiteCertifie == labo.id);
            if (index > -1) {
                labo.selected = true;
            }
        });
    }

    redirectToLaboratoire(labo) {
        this.$state.go('laboratoires.edit', { id: labo.id, genre: 'laboratoires' });
    }

    deleteAllLaboratoireCertification(labo) {
        const i = this.laboratoireSelect.findIndex((x) => x.id == labo.id);
        this.laboratoireSelect.splice(i, 1);

        const j = this.laboratoires.findIndex((x) => x.id == labo.id);
        this.laboratoires[j].selected = false;

        const indexes = this.getAllIndexes(this.mesure.certifications, labo.id);

        indexes.forEach((i) => {
            this.mesure.certifications.splice(i, 1);
        });
    }

    removeLaboratoire(labo) {
        const modalInstance = this.modalService.confirm({
            modalTitle: this.$translate.instant('CERTIFICATIONS.ESSAIS.MODAL.TITLE'),
            modalMsg: this.$translate.instant('CERTIFICATIONS.ESSAIS.MODAL.TEXTE'),
            headerClass: 'modal-danger'
        });

        modalInstance.result
            .then(() => {
                this.deleteAllLaboratoireCertification(labo);
            })
            .catch(() => {
                this.laboratoireSelect.push(labo);
            });
    }

    async openLaboCertificat(labo) {
        if (labo.open) {
            labo.open = false;
        } else {
            labo.certifs = this.mesure.certifications.filter((x) => x.idSiteCertifie == labo.id);
            //ajoute une prop = [] des certificats type essais du labo
            labo.certifsTypeEssai = await this.MesuresService.getAllCertificatTypeEssaiByLaboratoire(labo);
            labo.open = true;
        }
    }
    getAllIndexes(arr, val) {
        const indexes = [];
        let i;
        for (i = 0; i < arr.length; i++) {
            if (arr[i].idSiteCertifie === val) {
                indexes.push(i);
            }
        }
        return indexes.reverse();
    }

    addCertifEssai(data, labo) {
        const index = labo.certifs.findIndex((x) => x.idCertificat == data.id);
        if (index === -1) {
            //add certification
            const ce = {
                codeCg: data.certificatGenerique.code,
                end: data.dateFin,
                id: null,
                idCertificat: data.id,
                idCg: data.certificatGenerique.id,
                idEssai: this.mesure.id,
                idSiteAcc: data.idSiteAcccrediteur,
                idSiteCertifie: labo.id,
                libelleCg: data.libelleCg,
                logoPath: data.logoPath,
                numero: data.numCertificat,
                start: data.dateDebut,
                libelleSiteAcc: '',
                codeSiteAcc: '',
                logoLibelle: ''
            };
            if (data.siteAccrediteur !== null) {
                ce.libelleSiteAcc = data.siteAccrediteur.libelle;
                ce.codeSiteAcc = data.siteAccrediteur.code;
            }
            if (data.certificatGenerique.logo !== null) {
                ce.logoLibelle = data.certificatGenerique.logo.libelle;
            }
            this.mesure.certifications.push(ce);
            labo.certifs.push(ce);
        } else {
            //remove certification
            const i = this.mesure.certifications.findIndex((x) => x.idCertificat == data.id);
            this.mesure.certifications.splice(i, 1);
            const j = labo.certifs.findIndex((x) => x.idCertificat == data.id);
            labo.certifs.splice(j, 1);
        }
    }
}
