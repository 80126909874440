CommandesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'CommandesService',
	'notification',
	'DataShareService',
	'globalizationManagementService'
];

export default function CommandesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	CommandesService,
	notification,
	DataShareService,
	globalizationManagementService
) {
	const vm = this;

	let previousTableState;
	let selectedCommandeId = undefined;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.DataShareService = DataShareService;
	vm.state = $state;
	vm.commandes = [];
	vm.societes = [];
	vm.savedPagination = {};
	vm.savedPagination.entity = 'pagination';

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};

	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.etats = [
		{
			id: 0,
			libelle: 'Confirmée'
		},
		{
			id: 1,
			libelle: 'En cours'
		},
		{
			id: 2,
			libelle: 'Cloturée'
		},
		{
			id: 3,
			libelle: 'Annulée'
		}
	];

	vm.selectCommande = selectCommande;
	vm.addCommande = addCommande;
	vm.loadCommandes = loadCommandes;
	vm.deleteCommande = deleteCommande;
	vm.exporter = exporter;
	vm.dupliquer = dupliquer;

	vm.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	vm.displayDateMask = globalizationManagementService.getCurrentLanguage().displayDateMask;

	async function init() {
		vm.societes = await CommandesService.getSocietes();
	}

	init();

	function selectCommande(commande) {
		$state.go('commandes.edit', { id: commande.id });
	}

	async function loadCommandes(tableState) {
		startLoading();

		// gestion de la sauvegarde et de la restitution de la pagination
		const objectToManage = {};
		objectToManage.tableState = tableState;
		objectToManage.savedPagination = vm.savedPagination;
		objectToManage.itemsByPageOptions = vm.itemsByPageOptions;
		objectToManage.itemsByPage = vm.itemsByPage;
		const resultObject = await vm.DataShareService.managePaginationContextuelle(objectToManage);
		if (resultObject) {
			tableState = resultObject.tableState;
			vm.savedPagination = resultObject.savedPagination;
			vm.itemsByPageOptions = resultObject.itemsByPageOptions;
			vm.itemsByPage = resultObject.itemsByPage;
		}

		if ($state && $state.current && $state.current.name !== 'commandes.list') {
			$state.go('commandes.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.commandes = [];

		try {
			const data = await CommandesService.getCommandes(filters, sorts, pagination);
			vm.commandes = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
			console.log(vm.commandes);
		}
	}

	async function deleteCommande(commande) {
		if (commande && commande.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('COMMANDES.DELETE.TITLE', { code: commande.code }),
				modalMsg: $translate.instant('COMMANDES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await CommandesService.deleteCommandeById(commande.id);
					notification.success($translate.instant('COMMANDES.DELETE.SUCCESS'));

					selectedCommandeId = undefined;

					if (vm.params.id) {
						vm.state.go('commandes.list');
					}

					loadCommandes();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function exporter(modeImpression) {
		try {
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const fileName = 'Liste_commandes';
			let resultat = undefined;
			try {
				resultat = await CommandesService.exportListeCommandes(modeImpression, filters, sorts);
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('COMMANDES.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				const status = resultat.status;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
				} finally {
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		}
	}

	async function dupliquer(commande) {
		const modalInstance = ModalService.confirm({
			modalTitle: $translate.instant('COMMANDES.DUPLICATE.TITLE', { code: commande.code }),
			modalMsg: $translate.instant('COMMANDES.DUPLICATE.MESSAGE'),
			headerClass: 'modal-info'
		});

		modalInstance.result.then(async function () {
			startLoading();
			try {
				const duplicatedCommandeId = await CommandesService.duplicateCommande(commande.id);
				$state.go('commandes.edit', { id: duplicatedCommandeId });
			} catch (ex) {
				notification.error(ex.data);
			} finally {
				stopLoading();
			}
		});
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	async function addCommande() {
		const idCommande = await CommandesService.createCommande();
		$state.go('commandes.edit', { id: idCommande.data });
	}
}
