import Type from './type.model';

/* @ngInject */
export default class TypeFormController {
    sousTypes = [];

    constructor(
        _,
        $scope,
        $state,
        $stateParams,
        $translate,
        notification,
        ModalService,
        $timeout,
        TypesService,
        CaracteristiquesService,
        GranulometrieTypeProduitService,
        MassiaApplicationService,
        $uibModal,
        ElementCaracteristiqueService,
        ProduitsService,
        MassiaRightsService,
        NormesService,
        RouterHistoryService,
        MOPService,
        ModelesSaisiesService,
        TemplateImpressionService
    ) {
        this.RouterHistoryService = RouterHistoryService;
        this.$scope = $scope;
        this.$state = $state;
        this.$translate = $translate;
        this.$stateParams = $stateParams;
        this.notification = notification;
        this.ModalService = ModalService;
        this.TypesService = TypesService;
        this.CaracteristiquesService = CaracteristiquesService;
        this.GranulometrieTypeProduitService = GranulometrieTypeProduitService;
        this.$timeout = $timeout;
        this._ = _;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$uibModal = $uibModal;
        this.ElementCaracteristiqueService = ElementCaracteristiqueService;
        this.ProduitsService = ProduitsService;
        this.MassiaRightsService = MassiaRightsService;
        this.NormesService = NormesService;
        this.MOPService = MOPService;
        this.ModelesSaisiesService = ModelesSaisiesService;
        this.modelesSaisieList = [];
        this.selectedModeleSaisie;
        this.TemplateImpressionService = TemplateImpressionService;
        this.templateImpressionList = [];
    }

    // Component
    async $onInit() {
        this.MOPService.setMop([{ title: 'TYPES.BREADCRUMBS.TYPES_LIST', filename: 'TypesEtCaracteristiques.pdf', application: 'massia' }]);
        this.domaine = undefined;
        this.codeDomaine = undefined;
        this.theme = undefined;
        this.famille = undefined;
        this.unitePesee = undefined;
        this.uniteFacturation = undefined;
        this.serieDimensionnelleTamis = undefined;
        this.loading = false;
        this.hasEnregistrements = false;
        this.isEditMode = false;
        this.isNormalise = false;
        this.granulometrie = undefined;
        this.ongletOpen = {
            isFormOpen: true,
            isCaracteristiquesOpen: false,
            isCoLiCourtOpen: false,
            isTypeProduitOpen: false
        };
        this.canAccessSaisieProd = await this.MassiaRightsService.userHasRight('saisieprod', 'read');
        this.canSetSerieDim = false;
        this.canAccessPrelMobil = await this.MassiaRightsService.userHasRight('prelmobil', 'read');
        this.allowMobileSetting = false;

        this.mainRoute = this.$state && this.$state.current && this.$state.current.name ? this.$state.current.name.split('.')[0] : 'types';

        this.formuleChoices = await this.getEnumFormule();

        try {
            await this.reset();
            if (this.canAccessSaisieProd) {
                await this.loadProductionTypes();
            }
        } catch (e) {
            console.error(e);
        }
        await this.getTypeProduit();
        //console.log(angular.copy(this.type));
        this.normesSpecList = await this.getNormesSpecificqueList(this.type.codeFamille);

        this.getModelesSaisie();
        this.getTemplatesImpression();
        console.log(this.type)
    }

    $onDestroy() {
        this.$timeout.cancel(this.selectDomaineTimeout);
        this.$timeout.cancel(this.selectFamilleTimeout);
        this.$timeout.cancel(this.selectUnitePeseeTimeout);
        this.$timeout.cancel(this.selectUniteCompoTimeout);
        this.$timeout.cancel(this.selectUniteFacturationTimeout);
        this.$timeout.cancel(this.loadTypeTimeout);
        this.$timeout.cancel(this.selectModelSaisieTimeout);
        this.$timeout.cancel(this.selectTemplateImpressionTimeout);
        this.MOPService.resetMop();
    }

    async loadGranulometrie() {
        return (this.granulometries = await this.GranulometrieTypeProduitService.getGranulometries());
    }

    // Public
    async loadProductionTypes() {
        this.productionTypes = await this.ProduitsService.getTypesProduction();
        const selectedProd = this.productionTypes.find((l) => l.id === this.type.idProductionType);
        if (selectedProd) {
            selectedProd.selected = true;
        }
    }

    async reset() {
        this.startLoading();
        this.loadGranulometrie();
        this.getSerieTamis();
        let data = {
            id: this.$stateParams.id
        };
        if (data.id) {
            try {
                data = await this.TypesService.getTypeById(data.id);
                this.getSousTypesFournisseurs(data.idDomaine);
                this.isEditMode = true;
                this.hasEnregistrements = data.hasEnregistrements;
            } catch (ex) {
                this.notification.error(ex.data || ex);
                this.annuler();
            }
        }

        this.isNormalise = data.isNormalise !== false;
        this.type = new Type(data);
        this.addSerieDimentionnelleAuth();
        if (!this.$stateParams.id) {
            this.type.isRecompose = true;
        }
        if (this.$stateParams.duplicate) {
            this.type.id = undefined;
            this.type.code += '_copie';
            this.hasEnregistrements = false;
        }

        if (this.type.idDomaine) {
            this.domaine = {
                idDomaine: this.type.idDomaine,
                codeDomaine: this.type.codeDomaine
            };
            this.codeDomaine = this.type.codeDomaine;
            if (this.codeDomaine == 'Prelevements' && this.canAccessPrelMobil) {
                this.allowMobileSetting = true;
            }
        } else {
            this.domaine = undefined;
            this.codeDomaine = undefined;
        }

        if (this.type.idFamille) {
            this.famille = {
                idFamille: this.type.idFamille,
                codeFamille: this.type.codeFamille
            };
        } else {
            this.famille = undefined;
        }

        if (this.type.idTheme) {
            this.theme = {
                idTheme: this.type.idTheme
            };
        } else {
            this.theme = undefined;
        }

        if (this.type.idUnitePesee) {
            this.unitePesee = {
                id: this.type.idUnitePesee
            };
        } else {
            this.unitePesee = undefined;
        }

        if (this.type.idUniteCompo) {
            this.uniteCompo = {
                id: this.type.idUniteCompo
            };
        } else {
            this.uniteCompo = undefined;
        }

        if (this.type.idUniteFacturation) {
            this.uniteFacturation = {
                id: this.type.idUniteFacturation
            };
        } else {
            this.uniteFacturation = undefined;
        }

        if (this.type.idSerieDimensionnelleTamis) {
            this.serieDimensionnelleTamis = {
                idSerieDimensionnelleTamis: this.type.idSerieDimensionnelleTamis
            };
        } else {
            this.serieDimensionnelleTamis = undefined;
        }

        this.ongletOpen = {
            isFormOpen: true,
            isCaracteristiquesOpen: false,
            isCoLiCourtOpen: false,
            isTypeProduitOpen: false
        };
        this.getThemes();
        //remettre les ordres "normaux"
        for (let i = 0; i < this.type.caracteristiques.length; i++) {
            this.type.caracteristiques[i].position = i;

            const tmpEltList = await this.ElementCaracteristiqueService.getAll(null, this.type.caracteristiques[i].code);
            if (tmpEltList && tmpEltList.length > 0) {
                this.type.caracteristiques[i].eltList = tmpEltList;
            }
        }
        this.$timeout.cancel(this.loadTypeTimeout);
        this.loadTypeTimeout = this.$timeout(() => {
            this.stopLoading();
        });
    }

    async getTypeProduit() {
        try {
            if (this.codeDomaine == 'Produits' && this.type.codeFamille) {
                this.typeLies = await this.TypesService.getType(this.codeDomaine, this.type.codeFamille);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    selectType(data, list) {
        if (data) {
            if (data.id !== this.type.idTypeLie) {
                this.type.idTypeLie = data.id;
            } else {
                list = list.map((e) => (e.selected = !e.selected));
                this.type.idTypeLie = null;
            }
            //console.log(data, this.type, list)
        }
    }

    async getSerieTamis() {
        try {
            this.serieTamis = await this.TypesService.getSerieTamis();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
        }
    }

    async getThemes() {
        try {
            if (this.type.codeDomaine) {
                this.themes = await this.TypesService.getThemes(this.type.codeDomaine);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
        }
    }

    async appliquer() {
        const id = await this.sauvegarder();

        if (id && this.type.id) {
            this.reset();
        } else if (id) {
            this.$state.go(this.mainRoute + '.edit', { id: id }, { reload: true });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    annuler() {
        let returnState;
        if (!this.RouterHistoryService.back()) {
            if (this.$stateParams.parentState) {
                returnState = this.$stateParams.parentState;
            } else {
                returnState = this.mainRoute + '.list';
            }
            return this.$state.go(returnState);
        }
    }

    async selectDomaine(domaine) {
        this.type.idDomaine = undefined;
        this.type.codeDomaine = undefined;
        this.ongletOpen.isCaracteristiquesOpen = true;
        this.$scope.$broadcast('Type.idDomainePropertyClearValidation');

        this.$timeout.cancel(this.selectDomaineTimeout);
        if (domaine.code == 'SiteFourn') {
            await this.getSousTypesFournisseurs(domaine.idDomaine);
        }
        if (domaine.code == 'Prelevements' && this.canAccessPrelMobil) {
            this.allowMobileSetting = true;
        }
        this.selectDomaineTimeout = this.$timeout(() => {
            this.type.idDomaine = domaine.idDomaine;
            this.type.codeDomaine = domaine.code;
            this.domaine = domaine;
            this.codeDomaine = domaine.code;
            this.type.caracteristiques = [];
            this.getThemes();
        });
    }

    async getSousTypesFournisseurs(idDomaine) {
        this.sousTypes = await this.TypesService.getSousTypesFournisseurs(idDomaine);
        if (this.type.sousTypeFournisseur != null) {
            let enumValue = this.type.sousTypeFournisseur;
            for (let i = this.sousTypes.length - 1; i >= 0; i--) {
                const ssType = this.sousTypes[i];
                if (enumValue - ssType.id >= 0) {
                    ssType.selected = true;
                    enumValue -= ssType.id;
                }
            }
            // Unselect the 'none' case
            if (this.type.sousTypeFournisseur > 0) {
                this.sousTypes[0].selected = false;
            }
        }
    }

    selectFamille(famille) {
        console.log(famille)
        this.type.idFamille = undefined;
        //this.$scope.$broadcast('Type.idFamillePropertyClearValidation');

        this.$timeout.cancel(this.selectFamilleTimeout);

        this.selectFamilleTimeout = this.$timeout(() => {
            this.type.idFamille = famille.idFamille;
            this.type.codeFamille = famille.code;
            this.famille = famille;
        });
    }

    selectUnitePesee(unite) {
        this.type.idUnitePesee = undefined;
        //this.$scope.$broadcast('Type.idUnitePeseePropertyClearValidation');

        this.$timeout.cancel(this.selectUnitePeseeTimeout);

        this.selectUnitePeseeTimeout = this.$timeout(() => {
            this.type.idUnitePesee = unite.id;
            this.unitePesee = unite;
        });
    }

    selectUniteCompo(unite) {
        this.type.idUniteCompo = undefined;
        //this.$scope.$broadcast('Type.idUniteCompoPropertyClearValidation');

        this.$timeout.cancel(this.selectUniteCompoTimeout);

        this.selectUniteCompoTimeout = this.$timeout(() => {
            this.type.idUniteCompo = unite.id;
            this.uniteCompo = unite;
        });
    }

    selectUniteProduction(unite) {
        this.type.uniteProduction = unite;
        this.uniteProduction = unite;
    }

    selectUniteFacturation(unite) {
        this.type.idUniteFacturation = undefined;
        //this.$scope.$broadcast('Type.idUniteFacturationPropertyClearValidation');

        this.$timeout.cancel(this.selectUniteFacturationTimeout);

        this.selectUniteFacturationTimeout = this.$timeout(() => {
            this.type.idUniteFacturation = unite.id;
            this.uniteFacturation = unite;
        });
    }

    async checkCodeUnicity(code) {
        this.type.codeExists = null;

        try {
            if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                this.type.codeExists = await this.TypesService.codeExists(code, this.type.id);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async sauvegarder() {
        if (this.canAccessSaisieProd && this.typeProduction) {
            this.type.idProductionType = this.typeProduction[0]?.id;
            this.type.codeProductionType = this.typeProduction[0]?.code;
        }

        if (this.type.isValid() && !this.type.codeExists) {
            this.startLoading();

            try {
                let id = false;
                if (this.type.id) {
                    await this.TypesService.updateType(this.type.id, this.type);
                    this.notification.success(this.$translate.instant('EDITER_SUCCESS', { entity: this.type.libelle }));
                    id = this.type.id;
                } else {
                    id = await this.TypesService.createType(this.type);
                    this.notification.success(this.$translate.instant('AJOUTER_SUCCESS', { entity: this.type.libelle }));
                }
                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        } else {
            this.$scope.$broadcast('TypeValidations');
        }
    }

    //#region Caractéristiques
    async loadCaracteristiques() {
        this.domaine.caracteristiques = [];

        try {
            this.domaine.caracteristiques = await this.CaracteristiquesService.getCaracteristiquesByDomaine(this.type.idDomaine);
        } catch (ex) {
            this.notification.error(ex.data);
        }

        return this.domaine.caracteristiques;
    }

    async selectCaracteristique(item, model) {
        item.position = this.type.caracteristiques.length;
        item.isObligatoire = false;
        item.defaultValue = '';
        item.niveauVisibilite = 0;
        item.eltList = [];
        item.isDuplicable = true;
        item.mobile = false;
        item.isJournalise = false;
        item.condition = '';
        this.type.caracteristiques.push(item);

        item.eltList = await this.ElementCaracteristiqueService.getAll(null, item.code);

        // SL ajouter à la volée le bloc de carac
        await this.addCaracBlock(item);
    }

    selectAllCaracteristique() {
        const all = this.domaine.caracteristiques.filter((c) => {
            return this._.findIndex(this.type.caracteristiques, { id: c.id }) < 0;
        });

        all.forEach((item) => this.selectCaracteristique(item));

        // SL TODO faire l'ajout de toutes les blocks de carac
    }

    async updateCaracteristique(index, next) {
        const item = this._.find(this.type.caracteristiques, { position: index });
        const item2 = this._.find(this.type.caracteristiques, { position: next });

        if (item && item2) {
            item.position = next;
            item2.position = index;
        }

        // SL modifier la position du bloc de carac
        await this.moveCaracBlock(index, next);
    }

    editCaracteristique(caracteristique) {
        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('QUITTER_TITLE', { entity: caracteristique.code }),
            modalMsg: this.$translate.instant('QUITTER_CONFIRMATION'),
            headerClass: 'modal-warning'
        });

        if (this.type.id) {
            modalInstance.result.then(() => {
                this.$state.go(this.mainRoute + '.editCaracteristiqueFromEdit', {
                    id: caracteristique.id,
                    parentId: this.type.id
                });
            });
        } else {
            modalInstance.result.then(() => {
                this.$state.go(this.mainRoute + '.editCaracteristiqueFromNew', { id: caracteristique.id });
            });
        }
    }

    setIdentificationCaracteristique(caracteristique) {
        /*const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('QUITTER_TITLE', {entity: caracteristique.code}),
            modalMsg: this.$translate.instant('QUITTER_CONFIRMATION'),
            headerClass: 'modal-warning'
        });

        if (this.type.id) {
            modalInstance.result.then(() => {
                this.$state.go(this.mainRoute + '.editCaracteristiqueFromEdit', {id: caracteristique.id, parentId: this.type.id});
            });
        } else {
            modalInstance.result.then(() => {
                this.$state.go(this.mainRoute + '.editCaracteristiqueFromNew', {id: caracteristique.id});
            });
        }*/
    }

    deleteCaracteristique(caracteristique) {
        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('TYPES.CARACTERISTIQUES.DISSOCIER.TITLE', { code: caracteristique.libelle }),
            modalMsg: this.$translate.instant('TYPES.CARACTERISTIQUES.DISSOCIER.MESSAGE', { code: this.type.libelle }),
            headerClass: 'modal-danger'
        });

        modalInstance.result.then(() => {
            const index = this._.findIndex(this.type.caracteristiques, { id: caracteristique.id });

            if (index >= 0) {
                this.type.caracteristiques.splice(index, 1);
            }

            for (let i = 0; i < this.type.caracteristiques.length; i++) {
                this.type.caracteristiques[i].position = i;
            }

            //this.notification.success(this.$translate.instant('DISSOCIER_SUCCESS', { entity: caracteristique.code }));

            // SL supprimer le bloc de carac
            this.deleteCaracBlock(index);
        });
    }

    deleteAllCaracteristique() {
        this.type.caracteristiques = [];
        this.type.listeBlockCouplesCoLiCourt = [];
    }

    //#endregion

    // #region Gestion list des Codes et Libellés Courts
    async addCaracBlock(item) {
        if (item.idNature == 5) {
            const neoBlock = {};
            neoBlock.position = item.position;
            neoBlock.listeCouplesCoLiCourt = [];

            // appel server pour avoir les info éléments carac
            let data = [];
            try {
                data = await this.TypesService.getElementsFromCaracteristique(item.id);
            } catch (ex) {
                this.notification.error(ex.data);
            }

            // boucle sur chaque eltCarac
            for (let index = 0; index < data.length; index++) {
                const element = data[index];

                const neoCouple = {};

                neoCouple.libelleCarac = item.libelle;
                neoCouple.position = item.position;
                neoCouple.idCarac = item.id;
                neoCouple.idTCarType = 0;
                neoCouple.idConfIdentProdNorm = 0;
                neoCouple.libelleCourt = '';
                neoCouple.codeCourt = '';
                neoCouple.appelationCourt = '';

                neoCouple.libelle = element.libelle;
                neoCouple.code = element.code;
                neoCouple.appelation = element.appelation;
                neoCouple.idEltCaract = element.idEltCaract;

                neoBlock.listeCouplesCoLiCourt.push(neoCouple);
            }

            this.type.listeBlockCouplesCoLiCourt.push(neoBlock);
        }
    }

    deleteCaracBlock(index) {
        if (this.type.listeBlockCouplesCoLiCourt && this.type.listeBlockCouplesCoLiCourt.length > 0) {
            if (index >= 0) {
                this.type.listeBlockCouplesCoLiCourt.splice(index, 1);
            }

            for (let i = 0; i < this.type.listeBlockCouplesCoLiCourt.length; i++) {
                this.type.listeBlockCouplesCoLiCourt[i].position = i;
                for (let j = 0; j < this.type.listeBlockCouplesCoLiCourt[i].listeCouplesCoLiCourt.length; j++) {
                    this.type.listeBlockCouplesCoLiCourt[i].listeCouplesCoLiCourt[j].position = i;
                }
            }
        }
    }

    moveCaracBlock(index, next) {
        const item = this._.find(this.type.listeBlockCouplesCoLiCourt, { position: index });
        const item2 = this._.find(this.type.listeBlockCouplesCoLiCourt, { position: next });

        if (item && item2) {
            item.position = next;
            item2.position = index;
        }
    }

    // #endregion

    isDomaineProduit() {
        if (this.codeDomaine) {
            return this.codeDomaine == 'Produits';
        }
        if (this.domaine) {
            return this.domaine.code == 'Produits';
        }
        return false;
    }

    isDomaineEligibleFamille() {
        if (this.codeDomaine) {
            return (
                /*this.codeDomaine == 'Essais' ||*/ this.codeDomaine == 'SeuilGran' ||
                this.codeDomaine == 'Prelevements' ||
                this.codeDomaine == 'RefSaisie' ||
                this.codeDomaine == 'Referentiel'
            );
        }
        if (this.domaine) {
            return (
                this.domaine.code == 'Produits' ||
                this.domaine.code == 'SeuilGran' ||
                this.codeDomaine == 'Prelevements' ||
                this.codeDomaine == 'RefSaisie' ||
                this.codeDomaine == 'Referentiel'
            );
        }
        return false;
    }

    hasDomaineTheme() {
        if (this.MassiaApplicationService.getApplication() == 'qse' /* || this.MassiaApplicationService.getApplication() == 'laboratoire'*/) {
            let domToCompare = this.codeDomaine;
            if (!domToCompare && this.domaine) {
                domToCompare = this.domaine.code;
            }
            if (domToCompare) {
                return (
                    domToCompare == 'Campagnes' ||
                    domToCompare == 'Entites' ||
                    domToCompare == 'Mesures' ||
                    domToCompare == 'Saisies' ||
                    domToCompare == 'Seuils' ||
                    domToCompare == 'RefSaisieENV' ||
                    domToCompare == 'CampagnesCtrl' ||
                    domToCompare == 'MatEqui' ||
                    domToCompare == 'Controles' ||
                    domToCompare == 'SaisieCtrl' ||
                    domToCompare == 'SeuilCtrl' ||
                    domToCompare == 'RefSaisieCtrl' ||
                    domToCompare == 'CampagnesSecu' ||
                    domToCompare == 'EntiteSecu' ||
                    domToCompare == 'MesSecu' ||
                    domToCompare == 'SaisieSecu' ||
                    domToCompare == 'SeuilSecu' ||
                    domToCompare == 'RefSaisieSecu'
                    /*|| domToCompare == 'CampagnesLabo' || domToCompare == 'Produits' || domToCompare == 'Essais' || domToCompare == 'Prelevements' || domToCompare == 'SeuilGran' || domToCompare == 'RefSaisie'*/
                );
            }
        }
        return false;
    }

    // Loading
    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    openTarifs() {
        const _this = this;
        _this.source = this.type;
        _this.domaine = 'typeClient';
        this.$uibModal
            .open({
                template: '<tarifs source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></tarifs>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = _this.source;
                        $ctrl.domaine = _this.domaine;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl'
            })
            .result.then(
                function (result) { },
                function (reason) { }
            );
    }

    openGrilles() {
        const _this = this;
        _this.source = this.type;
        _this.domaine = 'typeClient';
        this.$uibModal
            .open({
                template: '<grilles source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></grilles>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = _this.source;
                        $ctrl.domaine = _this.domaine;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl'
            })
            .result.then(
                function (result) { },
                function (reason) { }
            );
    }

    open(domaine) {
        const _this = this;
        _this.source = {};
        _this.source.entete = this.type;
        _this.source.id = this.type.id;
        _this.domaine = domaine;
        this.$uibModal
            .open({
                template: '<centre-gestion source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></centre-gestion>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = _this.source;
                        $ctrl.domaine = _this.domaine;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl'
            })
            .result.then(
                function (result) {
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    changeLibelleCourt(e, elt) {
        elt.libelleCourt = e.target.value;
    }

    changeCodeCourt(e, elt) {
        elt.codeCourt = e.target.value;
    }

    changeAppelationCourt(e, elt) {
        elt.appelationCourt = e.target.value;
    }

    // authorise à ajouter une serie dimentionnelle (en modification du type) si la granulométrie est d/D ou Dmax et qu'il n'y en a pas.
    addSerieDimentionnelleAuth() {
        if (!this.hasEnregistrements) {
            this.canSetSerieDim = true;
        }
        if (this.hasEnregistrements && !this.type.idSerieDimensionnelleTamis && this.type.enumGranulometrie > 0) {
            this.canSetSerieDim = true;
        }
    }

    selectSousType() {
        this.type.sousTypeFournisseur = 0;
        if (this.sousTypesSelected.length > 0) {
            this.type.sousTypeFournisseur = this.sousTypesSelected.reduce(function (prev, cur) {
                return prev + cur.id;
            }, 0);
        }
    }

    async getEnumFormule() {
        try {
            const res = await this.TypesService.getEnumFormule();
            return res;
        } catch (error) {
            return this.notification.error(error);
        }
    }

    selectFormuleChoices(data, list) {
        if (data) {
            if (data.id !== this.type.formuleChoice) {
                this.type.formuleChoice = data.id;
            } else {
                list = list.map((e) => (e.selected = !e.selected));
                this.type.formuleChoice = null;
            }
        }
    }

    selectNormesSpec(data, list) {
        if (data) {
            if (data.id !== this.type.idNormeSpec) {
                this.type.idNormeSpec = data.id;
            } else {
                list = list.map((e) => (e.selected = !e.selected));
                this.type.idNormeSpec = null;
            }
        }
    }

    async getNormesSpecificqueList(famille) {
        try {
            const res = await this.NormesService.getNormeSpecList(famille);
            return res;
        } catch (error) {
            return this.notification.error(error);
        }
    }

    async getModelesSaisie() {
        const list = await this.ModelesSaisiesService.getModeles([], [], {}, 'modelessaisies');
        this.modelesSaisieList = list.items;

        if (this.type.idModeleSaisie) {
            const modelesSaisieSelectionne = this.modelesSaisieList.find(modelesSaisie => modelesSaisie.id == this.type.idModeleSaisie);
            if (modelesSaisieSelectionne) {
                modelesSaisieSelectionne.selected = true;
            }
        }
    }

    async getTemplatesImpression() {
        const list = await this.TemplateImpressionService.getTemplateList([], [], {});
        this.templateImpressionList = list.items;

        if (this.type.idTemplateImpression) {
            const templateImpressionSelectionne = this.templateImpressionList.find(templateImpression => templateImpression.id == this.type.idTemplateImpression);
            if (templateImpressionSelectionne) {
                templateImpressionSelectionne.selected = true;
            }
        }
    }

    selectModeleSaisie(modelSaisie) {
        this.type.idModeleSaisie = undefined;

        if (modelSaisie.length > 0) {
            this.$timeout.cancel(this.selectModelSaisieTimeout);
            this.selectModelSaisieTimeout = this.$timeout(() => {
                this.type.idModeleSaisie = modelSaisie[0].id;
            });
        }
    }

    selectTemplateImpression(TemplateImpression) {
        this.type.idTemplateImpression = undefined;

        if (TemplateImpression.length > 0) {
            this.$timeout.cancel(this.selectTemplateImpressionTimeout);
            this.selectTemplateImpressionTimeout = this.$timeout(() => {
                this.type.idTemplateImpression = TemplateImpression[0].id;
            });
        }
    }

    onColorSave(color, type) {
        console.log(angular.copy(color))
        console.log(angular.copy(type))
        if (color) {
            type.backgroundColor = color.HEXA.toString();

        }
    }

    debug() {
        console.log(angular.copy(this.type))
    }
}
