import ZoneGisement from './zone.gisements.model';

export default class ZoneGisementFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'ZoneGisementsCommunicationService',
        'ZoneGisementsService',
        'notification',
        '$location',
        'MassiaApplicationService'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        ZoneGisementsCommunicationService,
        ZoneGisementsService,
        notification,
        $location,
        MassiaApplicationService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ZoneGisementsCommunicationService = ZoneGisementsCommunicationService;
        this.ZoneGisementsService = ZoneGisementsService;
        this.notification = notification;
        this.$location = $location;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    async $onInit() {
        this.isEditMode = false;
        this.zoneGisement = {};
        this.sites = this.sites || [];
        this.loading = false;
        this.setZoneGisement(this.$stateParams.id);
        this.unregisterReinit = this.ZoneGisementsCommunicationService.registerReinit(() => this.initForm());
        this.getSites();
        this.initForm();
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    initForm() {
        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
            const thatZoneGisement = {
                id: this.zoneGisement.id,
                libelle: this.zoneGisement.libelle,
                code: this.zoneGisement.code
            };
        } else {
            this.isEditMode = false;
        }
    }

    async getSites() {
        this.startLoading();
        try {
            this.sites = await this.ZoneGisementsService.getSites(this.MassiaApplicationService.getApplication());
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async setZoneGisement(zoneGisementId) {
        if (zoneGisementId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                const stillThatZoneGisement = await this.ZoneGisementsService.getZoneGisementById(zoneGisementId);
                this.zoneGisement = new ZoneGisement(stillThatZoneGisement);
            } catch (ex) {
                if (ex.status === 404) {
                    if (this.MassiaApplicationService.getApplication() == 'performance') {
                        this.$state.go('zone-gisements.list', {}, { reload: true });
                    } else {
                        this.$state.go('zone-gisements-qse.list', {}, { reload: true });
                    }
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.zoneGisement = new ZoneGisement();
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.zoneGisement.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.zoneGisement.codeExists = await this.ZoneGisementsService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    confirmer() {
        if (this.checkValidity()) {
            if (this.isEditMode) {
                this.updateZoneGisement();
            } else {
                this.createZoneGisement();
            }
        }
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        if (this.MassiaApplicationService.getApplication() == 'performance') {
            this.$state.go('zone-gisements.list');
        } else {
            this.$state.go('zone-gisements-qse.list');
        }
    }

    reinit() {
        this.setZoneGisement(this.$stateParams.id);
        this.ZoneGisementsCommunicationService.raiseReinit();
    }

    checkValidity() {
        let validity = true;
        if (!this.zoneGisement.isValid()) {
            validity = false;
        }
        this.$scope.$broadcast('zoneGisementValidations');
        return validity;
    }

    async createZoneGisement() {
        this.startLoading();
        try {
            await this.ZoneGisementsService.createZoneGisement(this.zoneGisement);
            this.notification.success('ZONEGISEMENTS.CREATED');
            this.backToPreviousState();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateZoneGisement() {
        this.startLoading();
        try {
            await this.ZoneGisementsService.updateZoneGisement(this.zoneGisement);
            this.notification.success('ZONEGISEMENTS.UPDATED');
            this.backToPreviousState();
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
