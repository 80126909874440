import DepreciationStockQteController from './depreciation.stock.qte.controller';

export default {
    bindings: {
        ligne: '=',
        modalInstance: '='
    },
    template: require('./depreciation.stock.qte.html'),
    controller: DepreciationStockQteController
};
