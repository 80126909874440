export default class NormeSpecificationsController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'NormesCommunicationService',
        'NormesService',
        'notification',
        '$location',
        '$timeout',
        '$translate',
        'MassiaApplicationService',
        'CalculsStatistiquesService'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        NormesCommunicationService,
        NormesService,
        notification,
        $location,
        $timeout,
        $translate,
        MassiaApplicationService,
        CalculsStatistiquesService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.NormesCommunicationService = NormesCommunicationService;
        this.NormesService = NormesService;
        this.notification = notification;
        this.$location = $location;
        this.itemsByPageOptions = [20, 100, 200];
        this.$timeout = $timeout;
        this.$translate = $translate;
        this.MassiaApplicationService = MassiaApplicationService;
        this.CalculsStatistiquesService = CalculsStatistiquesService;
    }

    // Méthodes publiques
    async $onInit() {
        this.loading = false;
        this.categories = this.categories || [];
        this.caracteristiques = [
            { id: 1, libelle: 'Intrinseque' },
            { id: 2, libelle: 'Fabrication' }
        ];
        this.specification = this.specification || {};
        this.norme = this.norme;
        this.domaine = this.domaine;
        this.isSubTable = false;
        this.essais = [];
        this.calculsStatistiques = [];

        this.onAdd = this.onAdd || angular.noop;
        this.onDelete = this.onDelete || angular.noop;
        this.onDeleteAll = this.onDeleteAll || angular.noop;
        this.onUpdateCategorie = this.onUpdateCategorie || angular.noop;
        this.onSelectEssai = this.onSelectEssai || angular.noop;

        //this.essai = this.essai || undefined;
        // if (this.essai) {
        //     if (this.essai.constructor === Array)
        //         this.specification.essais = this.essai;
        //     else
        //         this.specification.essais = [this.essai];

        //     this.essai = undefined;
        //     this.isSubTable = true;
        // }

        //await this.initEssaisList();
        await this.getCalculsStatistiques();

        const that = this;
        this.$scope.$watch('$ctrl.norme.idFamille', function (newValue, oldValue) {
            if (newValue /*&& newValue != oldValue*/) {
                that.initEssaisList();
            }
        });

        this.$scope.$watch('$ctrl.specification.essais', function (newValue, oldValue) {
            if (newValue && newValue != oldValue) {
                that.setListeCalculsStatistiquesIstEven();
            }
        });
    }

    async initEssaisList() {
        try {
            this.essais = [];
            const rootListEssais = await this.NormesService.getEssaisList('essais', this.norme.idFamille);
            if (rootListEssais && rootListEssais.length > 0) {
                for (let indR = 0; indR < rootListEssais.length; indR++) {
                    const typeEssais = rootListEssais[indR];
                    if (typeEssais && typeEssais.essais) {
                        for (let e = 0; e < typeEssais.essais.length; e++) {
                            const essai = typeEssais.essais[e];
                            essai.msGroup = true;
                            essai.isEssai = true;
                            this.essais.push(essai); //push de l'essai

                            const se = essai.sousEssais.find(function (el) {
                                return el.code === essai.code && !el.isDimensionRemarquable && !el.isTamis;
                            });
                            if (se) {
                                se.isSousEssai = true;
                                se.idSousEssai = essai.id;
                                se.idEssai = essai.id;
                            }

                            for (let sse = 0; sse < essai.sousEssais.length; sse++) {
                                const sousEssai = essai.sousEssais[sse];
                                //if (sousEssai.id !== se.id) {
                                sousEssai.isSousEssai = true;
                                sousEssai.idSousEssai = sousEssai.isTamis || sousEssai.isDimensionRemarquable ? se.id : sousEssai.id;
                                sousEssai.position = sousEssai.isTamis || sousEssai.isDimensionRemarquable ? se.position : sousEssai.position;
                                sousEssai.idEssai = essai.id;
                                this.essais.push(sousEssai);
                                //}
                            }

                            this.essais.push({ msGroup: false });
                        }
                    }
                }
            }
            if (this.specification && this.specification.essais) {
                this.setListeCalculsStatistiquesIstEven();
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    essaisSelected() {
        const that = this;

        let selectedSousEssais = this.essais.filter(function (e) {
            return e.selected === true;
        });
        selectedSousEssais = selectedSousEssais.map(function (e) {
            if (e.selected === true) {
                const lastTestAdded = {};
                lastTestAdded.id = -1;

                const essai = that.essais.find(function (el) {
                    return el.isEssai && el.id === e.idEssai;
                });
                lastTestAdded.libelle = essai.libelle;
                lastTestAdded.code = essai.code;
                lastTestAdded.position = e.position;
                lastTestAdded.libelleSousEssai =
                    e.ouvertureTamis !== undefined && e.ouvertureTamis !== null
                        ? e.ouvertureTamis
                        : e.formuleDimension !== undefined && e.formuleDimension !== null
                        ? e.formuleDimension
                        : e.libelle;

                if (that.idDefaultCalcStat) {
                    lastTestAdded.idCalcStat = that.idDefaultCalcStat;
                }

                if (e.isTamis) {
                    lastTestAdded.idTamis = e.id;
                    lastTestAdded.idSousEssai = e.idSousEssai;
                } else if (e.isDimensionRemarquable) {
                    lastTestAdded.idDimRq = e.id;
                    lastTestAdded.idSousEssai = e.idSousEssai;
                } else if (that.norme.codeFamille === 'BPE') {
                    lastTestAdded.idEssai = e.idEssai;
                    lastTestAdded.idSousEssai = e.id;
                } else {
                    lastTestAdded.idSousEssai = e.idSousEssai;
                }
                //lastTestAdded.ouvertureTamis = e.ouvertureTamis;
                lastTestAdded.csListe = angular.copy(that.calculsStatistiques);
                if (lastTestAdded.idCalcStat) {
                    const cs = lastTestAdded.csListe.find((x) => x.id === lastTestAdded.idCalcStat);
                    if (cs) {
                        cs.selected = true;
                    }
                }

                return lastTestAdded;
            }
        });

        if (this.onSelectEssai && selectedSousEssais && selectedSousEssais.length > 0) {
            this.onSelectEssai({ param: selectedSousEssais });

            this.selectedEssai = null;
            this.selectedSousEssais = [];

            this.essais.map(function (e) {
                delete e.selected;
            });
        }
    }

    duplicateLine(essai, index) {
        const newEss = angular.copy(essai);
        newEss.id = 0;
        this.specification.essais.splice(index + 1, 0, newEss);
    }

    async selectSousEssai(essai) {
        essai.idSousEssai = essai.libelleSousEssai.id;
        essai.libelleSousEssai = essai.libelleSousEssai.libelle;
    }

    async changeSousEssai(essai, ev) {
        const sse = this.essais.find(function (e) {
            return e.idSousEssai === essai.idSousEssai && e.isSousEssai;
        });
        const es = this.essais.find(function (e) {
            return e.id === sse.idEssai && e.isEssai;
        });

        if (ev.target.value) {
            this.sseFiltered = es.sousEssais.filter(function (e) {
                return e.libelle.toLowerCase().indexOf(ev.target.value.toLowerCase()) >= 0;
            });
        } else {
            this.sseFiltered = es.sousEssais;
        }
    }

    async getCalculsStatistiques() {
        try {
            this.calculsStatistiques = await this.CalculsStatistiquesService.getCalculsStatistiquesSelect();
            const defaultCalcStat = this.calculsStatistiques.find(function (e) {
                return e.formule === '@VAL';
            });
            if (defaultCalcStat) {
                this.idDefaultCalcStat = defaultCalcStat.id;
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    setListeCalculsStatistiquesIstEven() {
        for (let i = 0; i < this.specification.essais.length; i++) {
            const essai = this.specification.essais[i];
            if (!essai.csListe || essai.csListe.length <= 0) {
                essai.csListe = angular.copy(this.calculsStatistiques);
            }

            if (essai.idCalcStat) {
                const cs = essai.csListe.find((x) => x.id === essai.idCalcStat);
                if (cs) {
                    cs.selected = true;
                }
            }
        }
    }

    calculStatistiqueSelected(idEssai, data) {
        const essai = this.specification.essais.find((x) => x.id == idEssai);
        console.log(idEssai);
        if (data.selected) {
            essai.idCalcStat = data.id;
        } else {
            essai.idCalcStat = null;
        }
    }
}
