import { StateRegistry } from '@uirouter/angularjs';
import SyntheseResultController from '../components/synthese-result/synthese.result.controller';

/**
 *
 * @param {StateRegistry} $stateRegistry
 * @param {ng.IRootScopeService} $rootScope
 */
export default function Configure($stateRegistry, MassiaApplicationService, $rootScope) {
    $rootScope.$watch(
        () => MassiaApplicationService.application,
        () => {
            const app = MassiaApplicationService.getApplication();
            console.log(app);
            const menu = app === 'gestion' || app === 'performance' ? 'massia_synth_synth' : 'analyse_direct';
            const domain = app === 'gestion' || app === 'performance' ? 'massiasynthapp' : /*'synthadmin'*/ 'controlepesee'; //SP 25/08/21 masquer nouvelles synthèeses labo
            // temporaire tant qu'il n'y a qu'une seule synth labo V2
            const labelMenuSynthV2 = app === 'laboratoire' ? 'TYPE_SYNTHESE.CONTROLEPESEE' : 'SYNTHENTS.BREADCRUMBS.SYNTHENTS_LIST_NEW';
            if ($stateRegistry.get('synthese.synthese')) {
                $stateRegistry.deregister('synthese.synthese');
            }
            $stateRegistry.register({
                name: 'synthese.synthese',
                url: '/synthese',
                abstract: true,
                template: '<ui-view></ui-view>'
            });
            if ($stateRegistry.get('synthese.synthese.list')) {
                $stateRegistry.deregister('synthese.synthese.list');
            }
            $stateRegistry.register({
                name: 'synthese.synthese.list',
                url: '',
                template: '<synthese-list></synthese-list>',
                rights: { domain: domain, right: 'read' },

                ncyBreadcrumb: {
                    parent: 'synthese',
                    label: '{{ "SYNTHENTS.BREADCRUMBS.SYNTHENTS_LIST" | translate}}'
                },
                // on affiche la liste des syntheseentities dans la navigation
                navigation: {
                    menu: menu,
                    rights: { domain: domain, right: 'read' },
                    translate: labelMenuSynthV2, // temporaire tant qu'il n'y a qu'une seule synth labo V2
                    order: 2,
                    navigationCls: 'menu-item-separator'
                },
                // la liste des syntheseentities fait partie des points d'entrée recherchables dans la navigation
                search: 'SYNTHENTS.BREADCRUMBS.SYNTHENTS_LIST'
            });
            if ($stateRegistry.get('synthese.synthese.new')) {
                $stateRegistry.deregister('synthese.synthese.new');
            }
            $stateRegistry.register({
                name: 'synthese.synthese.new',
                url: '/new',
                component: 'syntheseTable',
                rights: { domain: domain, right: 'create' },
                ncyBreadcrumb: {
                    parent: 'synthese.synthese.list',
                    label: '{{ "SYNTHENTS.BREADCRUMBS.SYNTHENT_NEW" | translate}}'
                },
                search: 'SYNTHENTS.BREADCRUMBS.SYNTHENT_NEW',
                params: {
                    type: null,
                    base: 0
                }
            });
            if ($stateRegistry.get('synthese.synthese.generate')) {
                $stateRegistry.deregister('synthese.synthese.generate');
            }
            $stateRegistry.register({
                name: 'synthese.synthese.generate',
                url: '/:id/generate',
                params: {
                    synthese: null
                },
                template: require('../components/synthese-result/synthese.result.controller.html'),
                controller: SyntheseResultController,
                controllerAs: '$ctrl',
                rights: { domain: domain, right: 'buildsynthesis' },
                ncyBreadcrumb: {
                    parent: 'synthese.synthese.list',
                    label: '<span>{{ $ctrl.synthese.libelle }}</span>'
                }
            });
            if ($stateRegistry.get('synthese.synthese.edit')) {
                $stateRegistry.deregister('synthese.synthese.edit');
            }
            $stateRegistry.register({
                name: 'synthese.synthese.edit',
                url: '/:id?{user:bool}',
                component: 'syntheseTable',
                rights: { domain: domain, right: 'update' },
                params: {
                    synthese: null,
                    user: false
                },
                //rights: { domain: this.domain, right: 'update' },
                ncyBreadcrumb: {
                    parent: 'synthese.synthese.list',
                    label: '{{ "SYNTHENTS.BREADCRUMBS.SYNTHENT_EDIT" | translate}}'
                }
            });
        }
    );
}

Configure.$inject = ['$stateRegistry', 'MassiaApplicationService', '$rootScope'];
