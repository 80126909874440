//Config
import FormulesConfig from './_config/formules.config';
import FormulesRoutes from './_config/formules.routes';

//Services
import FormuleService from './services/formules.service';
import FormulesCommunicationService from './services/formules.communication.service';

import FormuleCalculsService from './services/formules.calculs.service';
import FormuleAdditionCoefService from './services/formules.addition.coef.service';

//Components
import FormulesComponent from './components/formules';
import FormuleDateComponent from './components/formule-date';

import FormuleFormComponent from './components/formule-form';
import FormuleFormCreationComponent from './components/formule-form/creation';
import FormuleFormConfigImpressionController from './components/formule-form/creation/formule-form-config-impression';
import FormulesArchivesComponent from './components/formule-form/archives';
import DuplicateComponent from './components/formule-form/duplicate';
import VisualisationComponent from './components/formule-form/visualisation';
import FormuleCalculCo2Modal from './components/formule-form/creation/formule-calcul-co2-modal';

//Validators
import FormuleValidator from './components/formule-form/formule.validator';
import FormuleComposantValidator from './components/formule-form/creation/formule.composant.validator';
import formuleLaboCreation from './components/formule-form/creation/formule-labo-creation';
import formuleGestionCreation from './components/formule-form/creation/formule-gestion-creation';
import formuleCalculVerification from './components/formule-form/creation/formule-calcul-verification';

const moduleName = 'app.massia.common.formules';

angular
    .module(moduleName, [])
    .config(FormulesConfig)
    .config(FormulesRoutes)
    .service('FormuleService', FormuleService)
    .service('FormulesCommunicationService', FormulesCommunicationService)
    .service('FormuleCalculsService', FormuleCalculsService)
    .service('FormuleAdditionCoefService', FormuleAdditionCoefService)
    .component('formules', FormulesComponent)
    .component('formuleDate', FormuleDateComponent)
    .component('formuleForm', FormuleFormComponent)
    .component('formuleFormCreation', FormuleFormCreationComponent)
    .component('duplicate', DuplicateComponent)
    .component('visualisation', VisualisationComponent)
    .component('formuleArchives', FormulesArchivesComponent)
    .component('formuleLaboCreation', formuleLaboCreation)
    .component('formuleGestionCreation', formuleGestionCreation)
    .component('formuleCalculVerification', formuleCalculVerification)
    .component('formuleFormConfigImpressionController', FormuleFormConfigImpressionController)
    .component('formuleCalculCo2Modal', FormuleCalculCo2Modal)
    .factory('FormuleValidator', FormuleValidator)
    .factory('FormuleComposantValidator', FormuleComposantValidator);

export default moduleName;
