import Referentiel from './referentiel.model';
export default class ReferentielFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'ReferentielsCommunicationService',
        'ReferentielsService',
        'ReferentielTypesService',
        'notification',
        '$location',
        '$filter',
        '_'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        ReferentielsCommunicationService,
        ReferentielsService,
        ReferentielTypesService,
        notification,
        $location,
        $filter,
        _
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ReferentielsCommunicationService = ReferentielsCommunicationService;
        this.ReferentielsService = ReferentielsService;
        this.ReferentielTypesService = ReferentielTypesService;
        this.notification = notification;
        this.$location = $location;
        this.$filter = $filter;
        this._ = _;
        this.ongletOpen = {
            isEnteteOpen: true
        };
    }

    async $onInit() {
        this.isEditMode = false;
        this.referentiel = {};
        this.loading = false;
        this.unregisterReinit = this.ReferentielsCommunicationService.registerReinit(() => this.initForm());

        this.domaineMesure = 'essais';
        this.form = {
            entete: {},
            caracteristiques: {}
        };

        this.getTypes();
        await this.getTypeProduits();

        this.initForm();
        this.getCodeAuto();
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    async initForm() {
        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
            await this.setReferentiel(this.$stateParams.id);
            this.typeProduit = this.typeProduits.find((x) => x.id === this.referentiel.idTypeProduit);
        } else {
            this.isEditMode = false;
            this.referentiel = new Referentiel();
        }
    }

    async getTypes() {
        try {
            this.types = await this.ReferentielTypesService.getAll('referentiel');
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async getTypeProduits() {
        try {
            this.typeProduits = await this.ReferentielTypesService.getAll('produits');
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    changeTypeProduit() {
        if (this.typeProduit) {
            this.referentiel.idTypeProduit = this.typeProduit.id;
            this.referentiel.familleId = this.typeProduit.idFamille;
        } else {
            this.referentiel.idTypeProduit = null;
            this.referentiel.familleId = null;
        }
    }

    async setReferentiel(referentielId) {
        if (referentielId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                const result = await this.ReferentielsService.getReferentielById(referentielId);
                result.caracteristiques = await this.ReferentielsService.getReferentielValeursCaracteristiquesAssociationsById(referentielId);
                if (result.idTypeProduit) {
                    result.caracteristiqueProduits = await this.ReferentielsService.getTypeValeursCaracteristiquesById(
                        result.id,
                        result.idTypeProduit
                    );
                }
                this.referentiel = new Referentiel(result);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('referentiels.list', {}, { reload: true });
                }

                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
        }
    }

    async getCodeAuto() {
        try {
            this.estCodeAuto = await this.ReferentielsService.estCodeAuto();
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.referentiel.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.referentiel.codeExists = await this.ReferentielsService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    formatCaracteristiques(caracteristiques) {
        const data = [];
        for (const property in caracteristiques) {
            let keyValueObject;
            //On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux letiables interne Js.
            if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
                const propertyVal = caracteristiques[property];
                if (Array.isArray(propertyVal)) {
                    if (_.some(propertyVal, 'key')) {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: _.map(propertyVal, 'key')
                        };
                    } else {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: propertyVal
                        };
                    }
                } else {
                    keyValueObject = {
                        IdCaracteristique: parseInt(property),
                        valeur: propertyVal
                    };
                }
                data.push(keyValueObject);
            }
        }
        return data;
    }

    formatCaracteristiqueProduits(caracteristiques) {
        const data = [];
        for (const property in caracteristiques) {
            const propertyVal = caracteristiques[property];
            if (propertyVal.list) {
                const propFilter = propertyVal.list.filter((x) => x.selected);
                for (const x in propFilter) {
                    const caract = propFilter[x];
                    if (caract.idCarac && caract.code) {
                        const caracteristique = {
                            id: caract.idCarac,
                            valeur: caract.code
                        };
                        data.push(caracteristique);
                    }
                }
            }
        }
        return data;
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();

            const clonedCarac = Object.assign({}, this.referentiel.caracteristiques);
            this.referentiel.caracteristiques = this.formatCaracteristiques(clonedCarac);
            this.referentiel.caracteristiqueProduits = this.formatCaracteristiqueProduits(this.referentiel.caracteristiqueTypeProduits);
            try {
                if (this.referentiel.id) {
                    const tmp = await this.updateReferentiel();
                    this.notification.success('REFERENTIELS.UPDATED');
                    return this.referentiel.id;
                }
                const tmp = await this.createReferentiel();
                this.notification.success('REFERENTIELS.CREATED');
                return tmp;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        } else {
            return false;
        }
    }

    async valider() {
        const returnedId = await this.sauvegarder();
        if (returnedId) {
            this.$state.go('referentiels.edit', { id: returnedId });
        }
    }
    async validerEtFermer() {
        const returnedId = this.sauvegarder();
        this.backToPreviousState();
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('referentiels.list');
    }

    async createReferentiel() {
        this.startLoading();
        try {
            const returnedId = await this.ReferentielsService.createReferentiel(this.referentiel);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateReferentiel() {
        this.startLoading();
        try {
            const returnedId = await this.ReferentielsService.updateReferentiel(this.referentiel);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    onUpdateCaracteristiques(caracteristiques) {
        this.referentiel.caracteristiques = caracteristiques;
    }

    onUpdateCaracteristiqueTypeProduits(caracteristiqueTypeProduits) {
        this.referentiel.caracteristiqueTypeProduits = caracteristiqueTypeProduits;
    }

    reinit() {
        this.setReferentiel(this.$stateParams.id);
        this.ReferentielsCommunicationService.raiseReinit();
    }

    checkValidity() {
        this.$scope.$broadcast('referentielValidations');
        return this.referentiel.isValid();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }

    selectMesure(mesure) {
        if (!this.referentiel.mesures) {
            this.referentiel.mesures = [];
        }

        const lstFindMesures = this.referentiel.oldMesures ? this.referentiel.oldMesures.filter((e) => e.sousEssaiId === mesure.sousEssaiId) : [];

        if (lstFindMesures.length <= 0) {
            if (mesure.id <= 0) {
                mesure.libelleComplet = mesure.essaiCodeLibelle + ' : ' + mesure.sousEssaiCodeLibelle;
            }
            this.referentiel.mesures.splice(mesure.position, 0, mesure);
            this.trieEssais();
        } else {
            for (let i = 0; i < lstFindMesures.length; i++) {
                mesure = angular.copy(lstFindMesures[i]);
                this.referentiel.mesures.splice(mesure.position, 0, mesure);
            }

            this.trieEssais();
        }
    }

    trieEssais() {
        this.referentiel.mesures = this.$filter('orderBy')(
            this.referentiel.mesures,
            ['tamis', 'essaiCodeLibelle', 'sousEssaiCodeLibelle', 'calculLibelle'],
            false,
            function (v1, v2) {
                let retour = 0;
                if (v1.type == 'boolean') {
                    if (v1.value != v2.value) {
                        if (v1.value) {
                            retour = -1;
                        } else {
                            retour = 1;
                        }
                    } else {
                        retour = 0;
                    }
                } else if (v1.value) {
                    if (v1.value.includes('(')) {
                        retour = v1.value.localeCompare(v2.value);
                    } else {
                        //tri descendant pour les tamis
                        const d1 = parseFloat(v1.value.replace(',', '.').replace(' ', ''));
                        const d2 = parseFloat(v2.value.replace(',', '.').replace(' ', ''));
                        if (d1 != d2) {
                            if (d1 > d2) {
                                retour = -1;
                            } else {
                                retour = 1;
                            }
                        } else {
                            retour = 0;
                        }
                    }
                }
                return retour;
            }
        );

        for (let i = 0; i < this.referentiel.mesures.length; i++) {
            this.referentiel.mesures[i].position = i;
        }
    }

    deleteAllMesures() {
        this.referentiel.mesures.splice(0, this.referentiel.mesures.length);
    }

    deleteMesure(mesure) {
        const lstFindMesures = this.referentiel.mesures.filter((e) => e.sousEssaiId === mesure.sousEssaiId);
        if (lstFindMesures.length > 0) {
            for (let j = 0; j < lstFindMesures.length; j++) {
                const indice = this.referentiel.mesures.indexOf(lstFindMesures[j]);
                this.referentiel.mesures.splice(indice, 1);
            }

            for (let i = 0; i < this.referentiel.mesures.length; i++) {
                this.referentiel.mesures[i].position = i;
            }
        }
    }

    deleteMesureItem(mesure) {
        const indice = this.referentiel.mesures.indexOf(mesure);
        this.referentiel.mesures.splice(indice, 1);
        for (let i = 0; i < this.referentiel.mesures.length; i++) {
            this.referentiel.mesures[i].position = i;
        }
    }

    duplicateMesure(mesure) {
        const indice = this.referentiel.mesures.indexOf(mesure);
        const newMesure = angular.copy(mesure);
        newMesure.id = -1;
        this.referentiel.mesures.splice(indice + 1, 0, newMesure);

        for (let i = 0; i < this.referentiel.mesures.length; i++) {
            this.referentiel.mesures[i].position = i;
        }
    }
}
