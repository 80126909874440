(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.treeview')
        .directive('acTreeviewFolder', acTreeviewFolder);

    acTreeviewFolder.$inject = ['$templateCache', 'RecursionHelper'];

    /* @ngInject */
    function acTreeviewFolder($templateCache, RecursionHelper) {
        var directive = {
            restrict: 'A',
            template: function () {
                return $templateCache.get('blocks/treeview/ac.treeview.folder.tpl.html');
            },
            compile: function (element) {
                return RecursionHelper.compile(element, function (scope, iElement, iAttrs, controller, transcludeFn) {
                    scope.folder = scope.child;
                });
            }
        };

        return directive;
    }
})(angular);