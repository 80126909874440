export default function Configure($stateProvider) {
    $stateProvider.state('caracteristiques', {
        parent: 'common',
        url: '/caracteristiques',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('caracteristiques.list', {
        url: '/list',
        template: '<caracteristiques></caracteristiques>',
        rights: { domain: 'caracts', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "CARACTERISTIQUES.BREADCRUMBS.CARACTERISTIQUES_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'CARACTERISTIQUES.BREADCRUMBS.CARACTERISTIQUES_LIST',
            order: 3
        },
        search: 'CARACTERISTIQUES.BREADCRUMBS.CARACTERISTIQUES_LIST'
    });

    $stateProvider.state('caracteristiques.list.detail', {
        url: '/{id}',
        template: '<caracteristique-detail></caracteristique-detail>',
        rights: { domain: 'caracts', right: 'read' },
        ncyBreadcrumb: {
            parent: 'caracteristiques.list',
            label: '{{ "CARACTERISTIQUES.BREADCRUMBS.CARACTERISTIQUE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('caracteristiques.new', {
        url: '/new',
        template: '<caracteristique-form></caracteristique-form>',
        rights: { domain: 'caracts', right: 'create' },
        ncyBreadcrumb: {
            parent: 'caracteristiques.list',
            label: '{{ "CARACTERISTIQUES.BREADCRUMBS.CARACTERISTIQUE_NEW" | translate}}'
        },
        search: 'CARACTERISTIQUES.BREADCRUMBS.CARACTERISTIQUE_NEW'
    });

    $stateProvider.state('caracteristiques.edit', {
        url: '/{id}/edit',
        template: '<caracteristique-form></caracteristique-form>',
        rights: { domain: 'caracts', right: 'update' },
        ncyBreadcrumb: {
            parent: 'caracteristiques.list',
            label: '{{ "CARACTERISTIQUES.BREADCRUMBS.CARACTERISTIQUE_EDIT" | translate}}'
        }
    });

    $stateProvider.state('caracteristiques.duplicate', {
        url: '/{id}/duplicate',
        template: '<caracteristique-form></caracteristique-form>',
        rights: { domain: 'caracts', right: 'update' },
        ncyBreadcrumb: {
            parent: 'caracteristiques.list',
            label: '{{ "CARACTERISTIQUES.BREADCRUMBS.CARACTERISTIQUE_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });
}

Configure.$inject = ['$stateProvider'];
