const serviceUrl = `${__configuration.apiUrl}/massia/valeurs-caracteristiques`;

export default class CaracteristiquesTypesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getAll(idTheme, domaine, idFamille) {
        let url = '';
        if (idTheme) {
            url = `${__configuration.apiUrl}/massia/domaines/${domaine}/types/by-theme/${idTheme}`;
        } else {
            url = `${__configuration.apiUrl}/massia/domaines/${domaine}/types/filtre-famille/${idFamille}`;
        }
        const res = await this.$http.get(url);
        return res.data;
    }

    async getCaracteristiques(id) {
        const url = `${__configuration.apiUrl}/massia/types/${id}/format-caracteristiques`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async GetNiveauVisibilite(id) {
        const url = `${__configuration.apiUrl}/massia/types/${id}/niveau-visibilite`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getEntiteValeursCaracteristiquesAssociationsById(id, idType, entity) {
        const data = await this.getEntiteValeursCaracteristiquesById(id, idType, entity);
        const result = {};
        if (Array.isArray(data) && data.length > 0) {
            for (const element in data) {
                if (data[element] && !Array.isArray(data[element])) {
                    if (data[element].value === 'True') {
                        data[element].value = 'true';
                    }
                    if (data[element].value === 'False') {
                        data[element].value = 'false';
                    }
                    if (data[element].value && data[element].value.key) {
                        result[data[element].id] = data[element].value.key;
                    } else {
                        if (result[data[element].id]) {
                            result[data[element].id].push(data[element].value[0]);
                        } else {
                            result[data[element].id] = data[element].value;
                        }
                    }
                }
            }
        }

        return result;
    }

    async getEntiteValeursCaracteristiquesById(id, idType, entity) {
        const url = `${serviceUrl}/${id}/${idType}/${entity}`;
        const res = await this.$http.get(url);
        const result = [];
        res.data.forEach(function (element) {
            const caract = {
                id: element.idCaracteristique,
                code: element.code,
                idNature: element.idNature,
                indice: element.indice,
                label: element.label,
                numericAdditionalInformation: element.numericAdditionalInformation,
                value: element.elementsValues || element.elementValue || element.values || element.value
            };
            result.push(caract);
        });
        return result;
    }

    //pour create et update
    formatCaracteristiques(caracteristiques) {
        const data = [];
        if (caracteristiques) {
            for (const property in caracteristiques) {
                let keyValueObject;
                //On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variables interne Js.
                if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
                    const propertyVal = caracteristiques[property];
                    if (Array.isArray(propertyVal)) {
                        if (_.some(propertyVal, 'key')) {
                            keyValueObject = {
                                idCaracteristique: parseInt(property),
                                values: _.map(propertyVal, 'key')
                            };
                        } else {
                            keyValueObject = {
                                idCaracteristique: parseInt(property),
                                values: propertyVal
                            };
                        }
                    } else {
                        keyValueObject = {
                            idCaracteristique: parseInt(property),
                            value: propertyVal
                        };
                    }
                    data.push(keyValueObject);
                }
            }
        }
        return data;
    }

    async setEntiteValeursCaracteristiquesById(id, idType, entity, data) {
        console.log(data);
        const lightData = this.formatCaracteristiques(data);
        console.log(lightData);
        const url = `${serviceUrl}/${id}/${idType}/${entity}`;
        const res = await this.$http.post(url, lightData);

        return data;
    }
}
