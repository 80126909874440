import Import3rConfiguration from './_config/import.3r.config';
import Import3rRoutes from './_config/import.3r.routes';

// Services
import Import3rService from './services/import.3r.service';

// Components
import Import3rListComponent from './components/import-3r-list';
import Import3rFormComponent from './components/import-3r-form';

// Validator
import { Import3rFormValidator } from './components/import-3r-form/import.3r.form.validator';
import { Import3rParamValidator } from './components/import-3r-form/import.3r.param.validator';

const moduleName = 'app.massia.common.import.3r';

angular
    .module(moduleName, [])
    .config(Import3rConfiguration)
    .config(Import3rRoutes)
    .service('Import3rService', Import3rService)
    .component('import3rList', Import3rListComponent)
    .component('import3rForm', Import3rFormComponent)
    .factory('Import3rFormValidator', Import3rFormValidator)
    .factory('Import3rParamValidator', Import3rParamValidator);

export default moduleName;
