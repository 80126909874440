(function () {
    'use strict';

    angular.module('blocks.helpers').service('domHelper', domHelper);
    domHelper.$inject = ['$timeout', '$window'];

    function domHelper($timeout, $window) {
        /* jshint validthis: true */
        var service = this;

        service.getFirstParentByClass = getFirstParentByClass;
        service.focus = focus;
        service.truncateEllipsis = truncateEllipsis;

        function getFirstParentByClass(elt, className, depth) {
            var elementParent = elt.parent();
            for (var i = depth; i >= 0; i--) {
                if (elementParent.hasClass(className)) {
                    return elementParent;
                }
                elementParent = elementParent.parent();
            }
        }

        function focus(id) {
            $timeout(function () {
                var element = $window.document.getElementById(id);
                if (element) {
                    element.focus();
                }
            }, 250);
        }

        function truncateEllipsis(value, max, wordwise, tail) {
            var actualTail = tail === undefined ? '...' : tail;

            if (!value) {
                return '';
            }

            max = parseInt(max, 10);
            if (!max) {
                return value;
            }
            if (value.length <= max) {
                return value;
            }

            value = value.toString().substr(0, max);
            if (wordwise) {
                var lastspace = value.lastIndexOf(' ');
                if (lastspace !== -1) {
                    value = value.substr(0, lastspace);
                }
            }

            return value + actualTail;
        }
    }
})();
