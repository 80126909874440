import TvaConfig from './_config/tva.config';
import TvaRoutes from './_config/tva.routes';
import TvaComponent from './components/tva';
import TvaService from './services/tva.service';
import TvaCommunicationService from './services/tva.communication.service';
import TvaFormComponent from './components/tva-form';
import TvaValidator from './components/tva-form/tva.validator';

import TvaConfigurationComponent from './components/tva-configuration';
import TvaConfigurationService from './services/tva.configuration.service';

const moduleName = 'app.massia.common.tva';

angular
    .module(moduleName, [])
    .config(TvaConfig)
    .config(TvaRoutes)
    .service('TvaService', TvaService)
    .service('TvaConfigurationService', TvaConfigurationService)
    .service('TvaCommunicationService', TvaCommunicationService)
    .component('tva', TvaComponent)
    .component('tvaForm', TvaFormComponent)
    .component('tvaConfiguration', TvaConfigurationComponent)
    .factory('TvaValidator', TvaValidator);

export default moduleName;
