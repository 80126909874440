SocieteEnteteValidator.$inject = ['validator'];

export default function SocieteEnteteValidator(validator) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    instance.ruleFor('mail').emailAddress().when(isCompleted).withMessage('VALIDATION_EMAIL');
    /*instance.ruleFor('telephone').matches(/(0|\+33)\d{9}/).when(isCompleted).withMessage('VALIDATION_PHONE');
    instance.ruleFor('fax').matches(/(0|\+33)\d{9}/).when(isCompleted).withMessage('VALIDATION_FAX');*/

    instance.ruleFor('paysId').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;

    function isCompleted(obj, prop) {
        return prop != null && typeof prop !== undefined && prop != '';
    }
}
