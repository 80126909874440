import DomainPortail from './DomainPortail';

export default class DocumentSetting {
    constructor(data = {}) {
        this.id = data.id || null;
        this.envoiAuto = data.envoiAuto || false;
        this.filenameAuto = data.filenameAuto || false;
        this.format = data.format || null;
        this.domain = new DomainPortail(data.domain) || null;
        this.create = false;
        this.update = false;
    }
}
