import Prestation from './prestation.model';
import PrestationEntete from '../prestation-entete/prestation.entete.model';

export default class PrestationFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'PrestationsCommunicationService',
        'PrestationsService',
        'notification',
        '$location',
        '$anchorScroll',
        'MassiaApplicationService',
        '$uibModal',
        'MOPService'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        PrestationsCommunicationService,
        PrestationsService,
        notification,
        $location,
        $anchorScroll,
        MassiaApplicationService,
        $uibModal,
        MOPService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.PrestationsCommunicationService = PrestationsCommunicationService;
        this.PrestationsService = PrestationsService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$uibModal = $uibModal;
        this.MOPService = MOPService;
    }

    $onInit() {
        this.MOPService.setMop([
            { title: 'Gestion des prestations', filename: 'GestionsPrestations.pdf', application: 'gestion' },
            { title: 'Contribution energétique transport', filename: 'ContributionEnergetiqueTransport.pdf', application: 'gestion' }
        ]);
        this.isEditMode = false;
        this.prestation = {};
        this.loading = false;

        this.setPrestation(this.$stateParams.id);
    }

    $onDestroy = () => {
        this.MOPService.resetMop();
    };

    async setPrestation(prestationId) {
        if (prestationId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                await this.setPrestationEntete(prestationId);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('prestations.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.prestation = new Prestation();
        }
        this.ongletOpen = {
            isEnteteOpen: true
        };
    }

    async setPrestationEntete(prestationId) {
        const entete = await this.PrestationsService.getPrestationEnteteById(prestationId);
        this.prestation.entete = new PrestationEntete(entete);
    }

    async appliquer() {
        const id = await this.sauvegarder();
        if (id) {
            this.$state.go('prestations.edit', { id: id });
            this.ongletOpen = {
                isEnteteOpen: true
            };
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                let id = false;
                if (this.isEditMode) {
                    await this.PrestationsService.updateEntetePrestation(this.prestation.entete);
                    this.notification.success('PRESTATIONS.UPDATED');
                    id = this.prestation.entete.id;
                } else {
                    id = await this.PrestationsService.createPrestation(this.prestation.entete);
                    this.notification.success('PRESTATIONS.CREATED');
                }

                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    openFirstOnglet() {
        this.ongletOpen = {
            isEnteteOpen: true
        };
    }

    closeAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: false
        };
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('prestations.list');
    }

    reinit() {
        this.openFirstOnglet();
        this.setPrestation(this.$stateParams.id);
        this.PrestationsCommunicationService.raiseReinit();
    }

    checkValidity() {
        this.closeAllOnglets();
        let validity = true;

        let firstScroll = true;
        if (!this.prestation.entete.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('entete');
                firstScroll = false;
            }

            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.$scope.$broadcast('prestationEnteteValidations');
        }

        return validity;
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    open(domaine) {
        const _this = this;
        _this.source = this.prestation;
        _this.source.id = this.prestation.entete.id;
        _this.domaine = domaine;
        this.$uibModal
            .open({
                template: '<centre-gestion source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></centre-gestion>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = _this.source;
                        $ctrl.domaine = _this.domaine;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl'
            })
            .result.then(
                function (result) {
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }
}
