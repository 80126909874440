import validatorBuilder from 'validator-builder';

let produitsValidator = null;

export default class OffreValidationProduits {
	constructor(data) {
		data = data || {};

		this.id = data.id || null;
		this.modeSeuil = data.modeSeuil ? data.modeSeuil + '' : '1';
		this.valeurMin = typeof data.valeurMin === 'number' ? data.valeurMin : null;
		this.valeurMax = data.valeurMax || null;
		this.typeProduit = data.typeProduit || [];
	}
	isValid() {
		produitsValidator = produitsValidator || validatorBuilder.getInstanceFor('OffreValidationProduits');
		const validationResults = produitsValidator.validate(this);
		return validationResults.isValid;
	}
}
