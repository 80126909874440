/**
 * Usage :
 * import validatorBuilder from 'validator-builder'
 * const builder = validatorBuilder();
 *
 * let validator = builder.createInstance();
 * validator.ruleFor('name').notEmpty()...
 *
 */

import angular from 'angular';

let injector;

export default {
    getInstanceFor: getInstanceFor
};

function getInstanceFor(modelName) {
    if (!injector) {
        injector = angular.element(document.body).injector();
    }

    const instance = injector.get(`${modelName}Validator`);
    return instance;
}
