export default class UniteDetailController {
    static $inject = [
        '$stateParams',
        '$state',
        'UnitesService',
        'notification',
        'UnitesCommunicationService',
        'DataShareService',
        'MassiaApplicationService'
    ];

    constructor($stateParams, $state, UnitesService, notification, UnitesCommunicationService, DataShareService, MassiaApplicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.UnitesService = UnitesService;
        this.notification = notification;
        this.UnitesCommunicationService = UnitesCommunicationService;
        this.DataShareService = DataShareService;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        this.loading = false;
        this.unite = {};

        this.closeAllOnglets();
        this.openAllOnglets();
        this.openFirstOnglet();

        this.initUnite();
    }

    async initUnite() {
        this.unite = {
            id: this.$stateParams.id
        };

        if (this.unite.id) {
            this.startLoading();

            try {
                // on ajoute await afin de ne pas passer trop rapidement dans le finally
                // et ainsi on garde le loading le temps nécessaire
                this.unite = await this.UnitesService.getUniteById(this.unite.id);
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListUnite();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListUnite();
        }
    }

    goListUnite() {
        this.$state.go('unites.list', {}, { reload: true });
    }

    openFirstOnglet() {
        this.ongletOpen = {
            isEnteteOpen: true,
            isConversionOpen: false
        };
    }
    closeAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: false,
            isConversionOpen: false
        };
    }
    openAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: true,
            isConversionOpen: true
        };
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
