GrillesZonesKilometriquesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'GrillesZonesKilometriquesService',
	'notification',
	'$transitions'
];

export default function GrillesZonesKilometriquesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	GrillesZonesKilometriquesService,
	notification,
	$transitions
) {
	const vm = this;

	let previousTableState;
	let selectedGrilleZonesKilometriquesId = undefined;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.grillesZonesKilometriques = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectGrilleZonesKilometriques = selectGrilleZonesKilometriques;
	vm.isGrilleZonesKilometriquesSelected = isGrilleZonesKilometriquesSelected;
	vm.closeDetail = closeDetail;
	vm.loadGrillesZonesKilometriques = loadGrillesZonesKilometriques;
	vm.deleteGrilleZonesKilometriques = deleteGrilleZonesKilometriques;
	vm.duplicateGrilleKm = duplicateGrilleKm;

	function init() {
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));
		if (shouldSetSelectedId()) {
			selectedGrilleZonesKilometriquesId = $stateParams.id;
		}
	}

	init();

	function stateChangeSuccessFunc(event, toState) {
		if (!$stateParams.id) {
			selectedGrilleZonesKilometriquesId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return (
			$state &&
			$state.current &&
			$state.current.name &&
			$state.current.name === 'grilles-zones-kilometriques.list.detail' &&
			$stateParams &&
			$stateParams.id
		);
	}

	function selectGrilleZonesKilometriques(GrilleZonesKilometriques) {
		if (GrilleZonesKilometriques && GrilleZonesKilometriques.id) {
			if (selectedGrilleZonesKilometriquesId !== GrilleZonesKilometriques.id) {
				showDetail(GrilleZonesKilometriques.id);
			} else {
				//si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
				closeDetail();
			}
		}
	}

	function duplicateGrilleKm(grilleKm) {
		if (grilleKm && grilleKm.id) {
			$state.go('grilles-zones-kilometriques.duplicate', { id: grilleKm.id });
		}
	}

	function isGrilleZonesKilometriquesSelected(GrilleZonesKilometriques) {
		return GrilleZonesKilometriques && GrilleZonesKilometriques.id && selectedGrilleZonesKilometriquesId === GrilleZonesKilometriques.id;
	}

	function showDetail(GrilleZonesKilometriquesId) {
		/*let modalInstance = ModalService.info({
            modalTitle: $translate.instant('GRILLES_ZONES_KILOMETRIQUES.DETAIL.MODAL_HEADER', {libelle: libelle}),
            modalMsg: this.getTooltipDroits(droits),
            headerClass: 'modal-info'
        });*/

		vm.slideMargin['margin-right'] = vm.slideWidth;
		selectedGrilleZonesKilometriquesId = GrilleZonesKilometriquesId;
		$state.go('grilles-zones-kilometriques.list.detail', { id: GrilleZonesKilometriquesId });
	}

	function closeDetail() {
		selectedGrilleZonesKilometriquesId = undefined;
		$state.go('grilles-zones-kilometriques.list');
	}

	async function loadGrillesZonesKilometriques(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'grilles-zones-kilometriques.list') {
			$state.go('grilles-zones-kilometriques.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.grillesZonesKilometriques = [];

		try {
			const data = await GrillesZonesKilometriquesService.getGrillesZonesKilometriques(filters, sorts, pagination);

			vm.grillesZonesKilometriques = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteGrilleZonesKilometriques(GrilleZonesKilometriques) {
		if (GrilleZonesKilometriques && GrilleZonesKilometriques.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('GRILLES_ZONES_KILOMETRIQUES.DELETE.TITLE', { libelle: GrilleZonesKilometriques.libelle }),
				modalMsg: $translate.instant('GRILLES_ZONES_KILOMETRIQUES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await GrillesZonesKilometriquesService.deleteGrilleZonesKilometriquesById(GrilleZonesKilometriques.id);
					notification.success($translate.instant('GRILLES_ZONES_KILOMETRIQUES.DELETE.SUCCESS'));
					selectedGrilleZonesKilometriquesId = undefined;
					if (vm.params.id) {
						vm.state.go('grilles-zones-kilometriques.list');
					}
					loadGrillesZonesKilometriques();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
