export default class NormeDetailController {
    static $inject = [
        '$stateParams',
        '$state',
        'NormesService',
        'notification',
        'NormesCommunicationService',
        '$timeout',
        'MassiaApplicationService'
    ];

    constructor($stateParams, $state, NormesService, notification, NormesCommunicationService, $timeout, MassiaApplicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.NormesService = NormesService;
        this.notification = notification;
        this.NormesCommunicationService = NormesCommunicationService;
        this.$timeout = $timeout;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        this.form = {
            entete: {},
            categories: {},
            specifications: {}
        };

        this.ongletOpen = {
            isEnteteOpen: true,
            isSpecificationsOpen: false,
            isCategoriesOpen: false
        };

        this.norme = {};
        this.specifications = [];
        this.specTree = {};
        this.specData = {};
        this.specification = {};
        this.selectedTreeNode = {};

        this.loading = false;

        this.initNorme();
        this.application = this.MassiaApplicationService.getApplication();
    }

    async initNorme() {
        this.norme = { id: this.$stateParams.id };
        if (this.norme.id) {
            this.startLoading();
            try {
                this.norme = await this.NormesService.getNormeById(this.norme.id);
                this.specifications = await this.NormesService.getSpecifications(this.norme.id);
            } catch (ex) {
                if (ex.status === 404) {
                    if (this.MassiaApplicationService.getApplication() == 'gestion') {
                        this.$state.go('normes.list', {}, { reload: true });
                    } else {
                        this.$state.go('normes-labo.list', {}, { reload: true });
                    }
                }
                this.notification.error(ex.data);
            } finally {
                this.initSpecificationsTreeView();
                this.stopLoading();
            }
        } else {
            if (this.MassiaApplicationService.getApplication() == 'gestion') {
                this.$state.go('normes.list', {}, { reload: true });
            } else {
                this.$state.go('normes-labo.list', {}, { reload: true });
            }
        }
    }

    initSpecificationsTreeView() {
        if (this.norme.codeFamille == 'GRAN') {
            this.specData = [];

            if (this.norme && this.specifications) {
                for (let i = 0; i < this.specifications.length; i++) {
                    const spec = this.specifications[i];

                    if (!spec.isDeleted && spec.essais && spec.essais.length > 0) {
                        const treeChildSpec = {
                            id: spec.idCategorie,
                            label: spec.libelleCategorie ? spec.libelleCategorie : 'N.C', //(spec.ordreCategorie ? spec.ordreCategorie + ' ' : '') + (spec.libelleCategorie ? spec.libelleCategorie : 'N.C'),
                            data: spec,
                            level: 2
                        };

                        const specKey = spec.petitD + '_' + spec.grandD;
                        let treeItemSpec = this.specData.find(function (e) {
                            return e.id == specKey;
                        });
                        if (!treeItemSpec) {
                            treeItemSpec = {
                                id: specKey,
                                label:
                                    (spec.petitD ? 'd' + spec.petitD + '/' : '') +
                                    (spec.grandD ? 'D' + spec.grandD : '') +
                                    (spec.libelleGamme ? ' - ' + spec.libelleGamme : ''),
                                data: spec,
                                children: [treeChildSpec],
                                level: 1
                            };

                            if (treeItemSpec.label.endsWith('/')) {
                                treeItemSpec.label = treeItemSpec.label.substring(0, treeItemSpec.label.length - 1);
                            }
                            if (treeItemSpec.label.startsWith(' - ')) {
                                treeItemSpec.label = treeItemSpec.label.substring(3);
                            }

                            this.specData.push(treeItemSpec);
                        } else {
                            treeItemSpec.children.push(treeChildSpec);
                        }
                    }
                }
            }

            const that = this;
            this.$timeout(function () {
                that.specTree.collapse_all();
            }, 0);
        } else {
            this.selectedSpecification = this.specifications.length === 1 ? this.specifications[0] : {};
            if (this.selectedSpecification.essais.length <= 0) {
                this.selectedSpecification.essais.push({ IsAdding: true, id: 0 });
            }
        }
    }

    async getNorme(normeId) {
        this.norme = await this.NormesService.getNormeById(normeId);
        this.specifications = await this.NormesService.getSpecifications(normeId);
        this.initSpecificationsTreeView();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    onTreeItemSelected(item) {
        if (item.level < 2) {
            this.specification = null;
        } else {
            this.specification = item.data;
        }
    }
}
