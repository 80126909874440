const serviceUrl = `${__configuration.apiUrl}/massia/carnets-suivi`;

export default class CarnetsSuiviService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getSitesCommerciaux(idSociete) {
        const siteUrl = `${__configuration.apiUrl}/massia/sites-commerciaux/header/${idSociete}`;
        const res = await this.$http.get(siteUrl);

        return res.data;
    }

    async getSocietes() {
        const siteUrl = `${__configuration.apiUrl}/massia/societes-commerciales`;
        const res = await this.$http.get(siteUrl);

        return res.data;
    }

    async getClients() {
        const siteUrl = `${__configuration.apiUrl}/massia/sites-clients/headers`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async getChantiers(idSite) {
        const dateFacture = null;
        const siteUrl = `${__configuration.apiUrl}/massia/chantiers/header/${idSite}/${dateFacture}`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async getCarnets(filtres) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filtres: JSON.stringify(filtres || {})
            }
        });

        return res.data;
    }

    async print(ids, fileName, isPdf, etatBon) {
        const url = `${serviceUrl}/print`;
        const filtres = {
            ids: ids || [],
            isPdf: isPdf,
            fileName: fileName || '',
            etatBon: etatBon
        };
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {})
            },
            responseType: 'arraybuffer'
        });
        return res;
    }
}
