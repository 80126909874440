export default function Configure($stateProvider) {
    $stateProvider.state('documents-ce', {
        parent: 'common',
        url: '/documents-ce',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('documents-ce.list', {
        url: '/list',
        template: '<documents-c-e></documents-c-e>',
        rights: { domain: 'docce', right: 'read', application: 'gestion' },
        ncyBreadcrumb: {
            label: '{{ "DOCUMENTS_CE.BREADCRUMBS.DOCUMENTS_CE_LIST" | translate}}'
        },
        // on affiche la liste des documents ce dans la navigation
        navigation: {
            menu: 'donnees_parametres_donnees',
            translate: 'DOCUMENTS_CE.BREADCRUMBS.DOCUMENTS_CE_LIST',
            order: 9.2
        },
        // la liste des documents ce fait partie des points d'entrée recherchables dans la navigation
        search: 'DOCUMENTS_CE.BREADCRUMBS.DOCUMENTS_CE_LIST'
    });

    $stateProvider.state('documents-ce.new', {
        url: '/new',
        template: '<document-c-e-form></document-c-e-form>',
        rights: { domain: 'docce', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher documents ce > ajouter
            parent: 'documents-ce.list',
            label: '{{ "DOCUMENTS_CE.BREADCRUMBS.DOCUMENT_CE_NEW" | translate}}'
        },
        // l'ajout d'un document ce fait partie des points d'entrée recherchables dans l'application
        search: 'DOCUMENTS_CE.BREADCRUMBS.DOCUMENT_CE_NEW'
    });

    $stateProvider.state('documents-ce.list.detail', {
        url: '/{id}',
        template: '<document-c-e-detail></document-c-e-detail>',
        rights: { domain: 'docce', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher document ce > détail
            parent: 'documents-ce.list',
            label: '{{ "DOCUMENTS_CE.BREADCRUMBS.DOCUMENT_CE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('documents-ce.edit', {
        url: '/{id}/edit',
        template: '<document-c-e-form></document-c-e-form>',
        rights: { domain: 'docce', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher document ce > modifier
            parent: 'documents-ce.list',
            label: '{{ "DOCUMENTS_CE.BREADCRUMBS.DOCUMENT_CE_EDIT" | translate }}'
        }
    });

    $stateProvider.state('documents-ce.duplicate', {
        url: '/{id}/duplicate',
        template: '<document-c-e-form></document-c-e-form>',
        rights: { domain: 'docce', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher document ce > copier
            parent: 'documents-ce.list',
            label: '{{ "DOCUMENTS_CE.BREADCRUMBS.PRODUIT_COPY" | translate }}'
        },
        params: {
            duplicate: true
        }
    });
}

Configure.$inject = ['$stateProvider'];
