import Facture from '../../facture-form/facture.model';

export default class ModaleUpdateComptantController {
	/* @ngInject */
	constructor($state, $translate, PaiementsService, ClientsDiversService, notification, FacturesService) {
		this.$translate = $translate;
		this.PaiementsService = PaiementsService;
		this.ClientsDiversService = ClientsDiversService;
		this.notification = notification;
		this.$state = $state;
		this.FacturesService = FacturesService;
	}

	async $onInit() {
		this.newDivers = {
			id: 0,
			code: '',
			libelle: '',
			civilite: '',
			nomSociete: this.resolve.facture.nomSociete,
			idSociete: this.resolve.facture.idSociete,
			isBloquagePesee: false,
			isOuverture: false
		};
		this.displayDiversForm = false;
		this.modePaiement = this.resolve.facture?.idModePaiement;
		this.clientDivers = this.resolve.facture?.idClientDivers;
		this.loading = true;
		//liste des modes de paiement comptant bascule
		this.modesPaiement = [];
		this.getPaiementsComptant();
		//liste des clients divers
		this.divers = [];
		this.getDivers();
		this.loading = false;
	}

	async getPaiementsComptant() {
		const typeComptant = {
			criterion: 'isComptantBascule',
			value: true,
			type: 'Eq'
		};
		try {
			const res = await this.PaiementsService.getPaiements([typeComptant]);
			this.modesPaiement = res.items;
		} catch (error) {
			this.notification.error('Liste modes de paiement \\r' + error.data);
		}
	}
	async getDivers() {
		try {
			const criterions = [
				{
					criterion: 'societeId',
					value: this.resolve.facture.idSociete,
					type: 'Eq'
				},
				{
					criterion: 'isBloquagePesee',
					value: false,
					type: 'Eq'
				}
			];
			const res = await this.ClientsDiversService.getClientsDivers(criterions);
			this.divers = res.items.filter((x) => x.isBloquagePesee === false);
		} catch (error) {
			this.notification.error('Liste Clients Divers \n\r' + error.data);
		}
	}
	dismiss() {
		this.modalInstance.dismiss('cancel');
	}
	save() {
		this.resolve.facture.idModePaiement = this.modePaiement;
		this.resolve.facture.idClientDivers = this.clientDivers;
		this.FacturesService.updateFacture(this.resolve.facture)
			.then((res) => {
				this.notification.success('FACTURES.UPDATED');
				this.modalInstance.close('updated');
			})
			.catch((error) => {
				this.notification.error(error.data);
				this.modalInstance.dismiss('cancel');
			});
	}
	dismissDivers() {
		this.newDivers = {
			id: 0,
			code: '',
			libelle: '',
			civilite: '',
			nomSociete: this.resolve.facture.nomSociete,
			idSociete: this.resolve.facture.idSociete,
			isBloquagePesee: false,
			isOuverture: false
		};
		this.displayDiversForm = !this.displayDiversForm;
	}
	async saveDivers() {
		try {
			this.clientDivers = await this.ClientsDiversService.createClientDivers(this.newDivers);
			this.notification.success('CLIENTSDIVERS.CREATED');
			this.newDivers.id = this.clientDivers;
			this.divers.push(this.newDivers);
		} catch (error) {
			this.notification.error(error.data);
		} finally {
			this.dismissDivers();
		}
	}

	async checkCodeUnicity(code) {
		this.loadingCode = true;
		if (!code) {
			this.newDivers.codeExists = null;
			return;
		}
		try {
			if (code.match(/^[a-zA-Z0-9_]*$/)) {
				this.newDivers.codeExists = await this.ClientsDiversService.codeExists(code);
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.loadingCode = false;
		}
	}

	selectItem(item, data) {
		if (item === 'divers') {
			this.clientDivers = data?.id;
		} else {
			this.modePaiement = data?.id;
		}
	}
}
