export default class DAPDetailController {
    static $inject = ['$stateParams', '$state', 'DAPsService', 'notification', 'DAPsCommunicationService'];

    constructor($stateParams, $state, DAPsService, notification, DAPsCommunicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.DAPsService = DAPsService;
        this.notification = notification;
        this.DAPsCommunicationService = DAPsCommunicationService;
    }

    $onInit() {
        this.loading = false;
        this.dap = {};
        this.dapId = undefined;
        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.initDAP();
    }

    async initDAP() {
        this.dapId = this.$stateParams.id;

        this.startLoading();
        try {
            await this.getDapEntete(this.dapId);
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('daps.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getDapEntete(dapId) {
        this.dap.entete = await this.DAPsService.getDapEnteteById(dapId);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
