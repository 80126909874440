export default class ProduitFormNiveauxController {
    /* @ngInject */
    constructor(ProduitsService, notification) {
        this.ProduitsService = ProduitsService;
        this.notification = notification;
    }

    $onInit() {
        // Propriétés
        this.loading = true;
        this.produit = this.produit || {};
        this.values = angular.copy(this.produit.niveaux);
        this.niveaux = this.niveaux || [];
        this.elements = this.elements || [];
        this.loadNiveaux();
    }

    async loadNiveaux() {
        this.startLoading();

        try {
            this.niveaux = await this.ProduitsService.getProduitsNiveaux();
            this.elements = await this.ProduitsService.getProduitsElements();
        } catch (ex) {
            this.notification.error(ex);
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
