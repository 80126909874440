export default class SyntheseResultController {
    /* @ngInject */
    constructor($scope, $state, SyntheseEntityService, $element, $compile, RouterHistoryService, $timeout) {
        this.$scope = $scope;
        this.$state = $state;
        this.$element = $element;
        this.$compile = $compile;
        this.SyntheseEntityService = SyntheseEntityService;
        this.RouterHistoryService = RouterHistoryService;
        /**
         * @type ng.ITimeoutService
         */
        this.$timeout = $timeout;
    }

    async $onInit() {
        try {
            const { id } = this.$state.params;
            this.cancelBtn = false;
            this.loading = true;
            this.error = null;
            this.synthese = await this._loadSynthese(id);
            this._loadTemplate();
            this.$timeout(() => {
                this.toggleCancelbtn();
            }, 10000);
        } catch (err) {
            this.handleError(err);
        }
    }

    $onDestroy() {}

    async _loadSynthese(id) {
        try {
            if (this.$state.params.synthese) {
                return this.$state.params.synthese;
            }
            return await this.SyntheseEntityService.getOne(id);
        } catch (err) {
            console.error(err);
        }
    }

    _loadTemplate() {
        let template = '';
        switch (this.synthese.typeSynthese) {
            case 1: // FTP
                template = 'ftp-result';
                break;
            case 2:
                template = 'recomposition-result';
                break;
            case 14: // BL
                template = 'bon-livraison-result';
                break;
            case 16: // Production
                template = 'production-result';
                break;
            case 17: // Controole de pesse
                template = 'controle-pesee-result';
                break;
            default:
                break;
        }
        this.$element.append(this.$compile(this._getFormatedComponent(template))(this.$scope));
    }

    _getFormatedComponent(template) {
        return `<${template} synthese="$ctrl.synthese" loading="$ctrl.loading" error="$ctrl.handleError(error)"></${template}>`;
    }

    handleError(err) {
        if (err.data && err.data.message) {
            this.error = err.data.message;
            this.toggleCancelbtn();
            this.timer = this.$timeout(() => {
                this.cancel();
            }, 2000);
        }
    }

    toggleCancelbtn() {
        this.cancelBtn = true;
    }

    cancel() {
        if (this.timer) {
            this.$timeout.cancel(this.timer);
        }
        if (!this.RouterHistoryService.back()) {
            this.$state.go('synthese.synthese.list');
        }
    }
}
