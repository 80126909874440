export default class RecompositionController {
    /* @ngInject */
    constructor($scope, $state, SyntheseEntityService) {
        this.$scope = $scope;
        this.$state = $state;
        this.SyntheseEntityService = SyntheseEntityService;
    }

    async $onInit() {
        this.chart = [];
        this.result = await this.generate();
        this.loading = false;
    }

    $onDestroy() {}

    async generate() {
        try {
            const synthese = this.synthese;
            return await this.SyntheseEntityService.generate(synthese.id, synthese, synthese.typeSynthese);
        } catch (err) {
            console.error(err);
        }
    }
}
