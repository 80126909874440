import ProduitCommercial from './produits.commercial.model';

/* @ngInject */
export default function produitsCommerciauxController($scope, notification, ProduitsService, $uibModal, FormuleService, ProductService) {
	const _this = this;

	_this.$onInit = async function () {
		$scope.selectedType = {};
		$scope.isFiltered = false;
		$scope.products = [];
		$scope.productsList = [];

		_this.unregister = $scope.$watch(
			() => _this.produit.type,
			() => {
				if (_this.produit.type?.typeProduit?.typeLiee) {
					loadProducts(_this.produit.type.typeProduit.typeLiee, _this.produit.producteurs);
				}
			},
			true
		);
		$scope.loading = true;
	};

	_this.$onDestroy = function () {
		_this.unregister();
	};

	_this.deleteItem = deleteItem;
	/* _this.debug = debug; */
	_this.onSelect = (data) => {
		if (data.selected) {
			compareArrayAndAdd(data);
		} else {
			const deletedItem = _this.produit.produitCommerciaux.findIndex((e) => e.id === data.id);
			if (deletedItem !== -1) {
				_this.deleteItem(_this.produit.produitCommerciaux.findIndex((e) => e.id === data.id));
			}
		}
	};

	async function compareArrayAndAdd(data) {
		try {
			data.idProduit = data.id;
			//data.id = null;
			data.selected = !data.selected;
			data.isEnabled = true;

			_this.produit.produitCommerciaux.push(new ProduitCommercial(data));
			// console.log(_this.produit.produitCommerciaux);
		} catch (err) {
			if (data.selected) {
				data.selected = !data.selected;
			}
		}
	}

	/** FORMULES */
	async function loadFormules() {
		try {
			if (_this.produit) {
				_this.formules = await ProduitsService.getProduitFormules(_this.produit.id);
				if (_this.formules && _this.formules.length > 0) {
					_this.formule = _this.formules[0];
					_this.form = await FormuleService.getFormuleByProduitProducteur(_this.formule.produitId, _this.formule.producteurId);
				}
			}
		} catch (ex) {
			notification.error(ex);
		}
	}

	async function loadProducts(typeLiee, producteurs) {
		try {
			$scope.products = [];
			const filters = [];
			if (producteurs.length > 0) {
				filters.push({
					criterion: 'siteProduct.site.id',
					type: 'In',
					value: producteurs.map((x) => x.id)
				});
			}
			filters.push({
				criterion: 'type.id',
				type: 'Eq',
				value: typeLiee.id
			});
			filters.push({
				criterion: 'isEnabled',
				type: 'Eq',
				value: true
			});
			const res2 = await ProductService.getAll(filters, null, null, {
				application: _this.app
			});
			//enlever les CC passifs :
			if (producteurs.length > 0) {
				res2.items = res2.items.filter((e) => e.producteurs.find((p) => p.id == producteurs[0].id)?.isActivated);
			}
			$scope.products = res2.items.map((e) => new ProduitCommercial(e));
		} catch (err) {
			notification.error(err.message);
		}
	}

	async function deleteItem(index) {
		_this.produit.produitCommerciaux.splice(index, 1);
	}

	async function verifCalcul(produit, produitCc, parametresFormulation) {
		if (produit && produit.id) {
			const modalInstance = $uibModal.open({
				component: 'produitFormCommerciauxCalculModal',
				resolve: {
					produit: () => produit,
					produitCc: () => produitCc,
					parametresFormulation: () => parametresFormulation
				},
				backdrop: false,
				size: 'xxl'
			});

			return modalInstance.result;
		}
	}

	/*
    function debug(produit) {
        console.log(produit);
    } */
}
