export default function Configure($stateProvider) {
    $stateProvider.state('stocks', {
        parent: 'gestion',
        url: '/stocks',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.STOCKS" | translate }}'
        }
    });

    $stateProvider.state('stocks.gestion', {
        url: '/gestion',
        template: '<gestion-stocks></gestion-stocks>',
        rights: { domain: 'stockjournalier', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "STOCKS.BREADCRUMBS.GESTION_STOCKS" | translate}}'
        },
        navigation: {
            menu: 'stocks_no_category',
            translate: 'STOCKS.BREADCRUMBS.GESTION_STOCKS',
            order: 1
        },
        search: 'STOCKS.BREADCRUMBS.GESTION_STOCKS'
    });

    $stateProvider.state('stocks.inventaire', {
        url: '/inventaire',
        template: '<inventaire-stocks></inventaire-stocks>',
        rights: { domain: 'stockinventaire', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "STOCKS.BREADCRUMBS.INVENTAIRE_STOCKS" | translate}}'
        },
        navigation: {
            menu: 'stocks_no_category',
            translate: 'STOCKS.BREADCRUMBS.INVENTAIRE_STOCKS',
            order: 2
        },
        search: 'STOCKS.BREADCRUMBS.INVENTAIRE_STOCKS'
    });

    $stateProvider.state('stocks.inventaireedit', {
        url: '/{id:int}/edit',
        template: '<inventaire-stock-form></inventaire-stock-form>',
        rights: { domain: 'stockinventaire', right: 'read' },
        ncyBreadcrumb: {
            parent: 'stocks.inventaire',
            label: '{{ "STOCKS.BREADCRUMBS.INVENTAIRE_EDIT" | translate}}'
        },
        search: 'STOCKS.BREADCRUMBS.INVENTAIRE_EDIT'
    });

    $stateProvider.state('stocks.inventairenew', {
        url: '/new',
        template: '<inventaire-stock-form></inventaire-stock-form>',
        rights: { domain: 'stockinventaire', right: 'read' },
        ncyBreadcrumb: {
            parent: 'stocks.inventaire',
            label: '{{ "STOCKS.BREADCRUMBS.INVENTAIRE_NEW" | translate}}'
        },
        search: 'STOCKS.BREADCRUMBS.INVENTAIRE_NEW'
    });

    $stateProvider.state('stocks.valorisation', {
        url: '/valorisation',
        template: '<valorisation-stocks></valorisation-stocks>',
        rights: { domain: 'stockvaloris', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "STOCKS.BREADCRUMBS.ETAT_VALORISATION_STOCKS" | translate}}'
        },
        navigation: {
            menu: 'stocks_no_category',
            translate: 'STOCKS.BREADCRUMBS.ETAT_VALORISATION_STOCKS',
            order: 3
        },
        search: 'STOCKS.BREADCRUMBS.ETAT_VALORISATION_STOCKS'
    });
}

Configure.$inject = ['$stateProvider'];
