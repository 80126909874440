export default function Configure($stateProvider) {
    $stateProvider.state('distanciers', {
        parent: 'gestion',
        url: '/distanciers',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.TRANSPORT" | translate }}'
        }
    });

    $stateProvider.state('distanciers.list', {
        url: '/list',
        template: '<distanciers></distanciers>',
        rights: { domain: 'distancier', right: 'read' },
        ncyBreadcrumb: {
            parent: 'distanciers',
            label: '{{ "DISTANCIERS.BREADCRUMBS.DISTANCIERS_LIST" | translate}}'
        },
        navigation: {
            menu: 'tarification_transport',
            translate: 'DISTANCIERS.BREADCRUMBS.DISTANCIERS_LIST',
            order: 8
        },
        search: 'DISTANCIERS.BREADCRUMBS.DISTANCIERS_LIST'
    });
}

Configure.$inject = ['$stateProvider'];
