import validatorBuilder from 'validator-builder';

let banqueEnteteValidator = null;

export default class BanqueEntete {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.paysId = data.paysId;
        this.adresse = data.adresse;
        this.codePostal = data.codePostal;
        this.ville = data.ville;
        this.telephone = data.telephone;
        this.fax = data.fax;
        this.codeBanque = data.codeBanque;
        this.bic = data.bic;
        this.commentaire = data.commentaire;
    }

    isValid() {
        banqueEnteteValidator = banqueEnteteValidator || validatorBuilder.getInstanceFor('BanqueEntete');
        const validationResults = banqueEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
