import FormuleGestionCreationController from './formule.gestion.creation.controller';

export default {
	bindings: {
		formule: '=',
		formules: '=',
		rechercheDesPrix: '&',
		isEditMode: '='
	},
	controller: FormuleGestionCreationController,
	template: require('./formule.gestion.creation.html')
};
