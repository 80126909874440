AssociesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'AssociesService',
	'notification',
	'$transitions'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function AssociesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	AssociesService,
	notification,
	$transitions
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	let selectedAssocieId = undefined;

	vm.loading = false;
	vm.stateParams = $stateParams;
	vm.associes = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.isAssocieSelected = isAssocieSelected;
	vm.loadAssocies = loadAssocies;
	vm.deleteAssocie = deleteAssocie;

	function init() {
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "associes.list", on désélectionne la ligne sélectionnée (s'il y en a)
		//$transitions.onSuccess({}, stateChangeSuccessFunc);
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));
	}

	init();

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			selectedAssocieId = undefined;
		}
	}

	function isAssocieSelected(associe) {
		return associe && associe.id && selectedAssocieId === associe.id;
	}

	async function loadAssocies(tableState) {
		startLoading();

		//quand on rafraîchit la grille, on revient sur "associes.list", et on ferme la vue détail d'un associe
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== 'associes.list') {
			$state.go('associes.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.associes = [];

		try {
			const data = await AssociesService.getAssocies(filters, sorts, pagination);
			vm.associes = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteAssocie(associe) {
		if (associe && associe.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('ASSOCIES.DELETE.TITLE', { libelle: associe.libelle }),
				modalMsg: $translate.instant('ASSOCIES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await AssociesService.deleteAssocieById(associe.id);
					notification.success($translate.instant('ASSOCIES.DELETE.SUCCESS'));

					selectedAssocieId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
					if (vm.stateParams.id) {
						$state.go('associes.list');
					}

					loadAssocies();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
