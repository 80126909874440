let registeredReinit;

export default class SynthesesCommunicationService {
    static $inject = ['_'];

    constructor(_) {
        this._ = _;
        registeredReinit = [];
    }

    registerReinit(func) {
        if (this._.isFunction(func)) {
            const identity = this._.uniqueId();
            registeredReinit.push({ identity: identity, func: func });

            // On retourne la fonction de unregister
            return () => this.unregisterReinit(identity);
        }
    }

    unregisterReinit(identity) {
        this._.remove(registeredReinit, { identity: identity });
    }

    raiseReinit(params) {
        this._.forEach(registeredReinit, (x) => x.func(params));
    }
}
