const serviceUrl = `${__configuration.apiUrl}/massia/monitoring`;

export default class MonitoringService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getMonitoring(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getMonitoringDetail(id) {
        const url = `${serviceUrl}/${id}/detail`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getStatut() {
        const url = 'assets/enum/monitoring/statut.json';
        const res = await this.$http.get(url);
        return res.data;
    }
}
