export default class EditDistancesModal {
    /* @ngInject */
    constructor() {}

    async $onInit() {
        console.log(this);
    }

    $onDestroy() {}

    annuler() {
        this.close({ $value: { isOk: false } });
    }

    ok() {
        this.close({ $value: { isOk: true, filter: this.filter } });
        // A rajouter pour impacter toutes les compos :", editAllCompos: this.editAllCompos"
    }
}