import { StateService } from '@uirouter/angularjs';
import { RegistresService } from '../../../registres/services/registres.service';

export default class RndtsApiCallbackController {
	/* @ngInject */
	$scope: ng.IScope;
	$state: StateService;
	$uibModal: ng.ui.bootstrap.IModalService;
	$translate: any;
	notification: any;
	registresService: RegistresService;
	PaginationService: any;	
	globalizationManagementService: any;
	currRes: any;
	result: any;
	isApiKeyError: boolean
	constructor(
		$scope: ng.IScope,
		$state: StateService,
		$uibModal: ng.ui.bootstrap.IModalService,
		registresService: RegistresService,
		notification: any,
		$translate: any,
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$translate = $translate;
		this.$uibModal = $uibModal;
		this.notification = notification;
		this.registresService = registresService;
		this.isApiKeyError = false;
		
	}

	async $onInit() {
		this.result = this.registresService.getApiResult();
		this.currRes = this.result[0];
		console.log("Resultats API", this.result);
	}

	redirectToDAP(id : any)
	{
		let url = this.$state.href("daps.edit", {id: id});
		window.open(url, '_dap');
	}

	redirectToTicket(id : any)
	{
		let url = this.$state.href("tickets.detail", {id: id});
		window.open(url, '_tickets');
	}

	isTransporteur(key: string)
	{
		let reg = /transporteurs/;
		return reg.test(key);
	}

	getTranspoInfo(key:string)
	{
		let d = key.split("]")
		return this.$translate.instant(`API.transporteurs${d[1]}`);
	}
}
