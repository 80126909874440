MesureSousMesuresValidator.$inject = ['validator'];

export default function MesureSousMesuresValidator(validator) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    // instance.ruleFor('typeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    //instance.ruleFor('idNature').greaterThan(-1).withMessage('VALIDATION_NOTEMPTY');
    //instance.ruleFor('idNature').lessThan(70).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('idNature').must(verifyNature).withMessage('VALIDATION_TOO_SHORT_LISTE');

    function verifyNature(obj, idNature) {
        if (idNature) {
            //SP : sinon en texte court, passe à undefined et plante le validateur
            return obj.idNature > -1 && obj.idNature < 70;
        }
        return true;
    }

    return instance;
}
