import Pays from './pays.model';

export default class PaysFormController {
    static $inject = [
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'PaysCommunicationService',
        'PaysService',
        'notification',
        '$location',
        '$anchorScroll',
        '_',
        'MassiaApplicationService',
        '$uibModal',
        'MOPService'
    ];

    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        PaysCommunicationService,
        PaysService,
        notification,
        $location,
        $anchorScroll,
        _,
        MassiaApplicationService,
        $uibModal,
        MOPService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.PaysCommunicationService = PaysCommunicationService;
        this.PaysService = PaysService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this._ = _;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$uibModal = $uibModal;
        this.MOPService = MOPService;
    }

    $onInit() {
        this.MOPService.setMop([{ title: 'PAYS.BREADCRUMBS.PAYS_LIST', filename: 'Pays.pdf', application: 'massia' }]);
        this.pays = {};
        this.loading = false;
        this.form = {
            entete: {}
        };

        // Configuration
        this.reset();
        // this.setWatchers();
    }

    $onDestroy() {
        this.$timeout.cancel(this.updatePaysTimeout);
        this.MOPService.resetMop();
    }

    openCDG(domaine) {
        const _this = this;
        _this.source = this.pays;
        _this.domaine = domaine;
        this.$uibModal
            .open({
                template: '<centre-gestion source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></centre-gestion>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = _this.source;
                        $ctrl.domaine = _this.domaine;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl'
            })
            .result.then(
                function (result) {
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    async reset() {
        this.startLoading();

        const data = {
            id: this.$stateParams.id
        };

        if (data.id) {
            try {
                data.entete = await this.PaysService.getPaysEnteteById(data.id);
            } catch (ex) {
                this.notification.error(ex.data);
                this.annuler();
            }
        }
        this.pays = new Pays(data);
        if (this.$stateParams.duplicate) {
            this.pays.id = undefined;
            this.pays.entete.code += '_copie';
        }

        this.$timeout.cancel(this.updatePaysTimeout);
        this.updatePaysTimeout = this.$timeout(() => this.stopLoading());
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();

            try {
                let id = false;
                if (this.pays.id) {
                    await this.PaysService.updatePays(this.pays);
                    this.notification.success('PAYS.UPDATED');
                    id = this.pays.id;
                } else {
                    id = await this.PaysService.createPays(this.pays);
                    this.notification.success('PAYS.CREATED');
                }

                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async appliquer() {
        const id = await this.sauvegarder();

        if (id && this.pays.id) {
            this.reset();
        } else if (id) {
            this.$state.go('pays.edit', { id: id });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();
        if (success) {
            this.$state.go('pays.list');
        }
    }

    annuler() {
        this.$state.go('pays.list');
    }

    filtrer() {
        this.$state.go('donnee-filtrees.list', { id: this.pays.id, type: 1 });
    }

    checkValidity() {
        let validity = true;
        if (!this.pays.entete.isValid()) {
            validity = false;
            this.$scope.$broadcast('paysEnteteValidations');
        }
        return validity;
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    onUpdateEntete(entete) {
        this.pays.entete = entete;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
