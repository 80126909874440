import DomainesConfig from './_config/domaines.config';
import DomainesService from './services/domaines.service';
import DomainesDetailComponent from './components/domaines-detail';
import DomainesEditComponent from './components/domaines-edit';
import DomainesSelectComponent from './components/domaines-select';
import DomainesAvecTypesSelectComponent from './components/domaines-avec-types-select';

const moduleName = 'app.massia.common.domaines';

angular
    .module(moduleName, [])
    .config(DomainesConfig)
    .service('DomainesService', DomainesService)
    .component('domainesDetail', DomainesDetailComponent)
    .component('domainesEdit', DomainesEditComponent)
    .component('domainesSelect', DomainesSelectComponent)
    .component('domainesAvecTypesSelect', DomainesAvecTypesSelectComponent);

export default moduleName;
