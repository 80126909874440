import validatorBuilder from 'validator-builder';

let loginDataValidator = null;

export default class LoginData {
    constructor(data) {
        data = data || {};

        this.userName = data.userName;
        this.password = data.password;
        this.database = data.database;
    }

    isValid() {
        loginDataValidator = loginDataValidator || validatorBuilder.getInstanceFor('LoginData');
        const validationResults = loginDataValidator.validate(this);
        return validationResults.isValid;
    }
}
