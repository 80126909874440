(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.treeview')
        .factory('AcTreeviewCommunicationService', AcTreeviewCommunicationService);
    AcTreeviewCommunicationService.$inject = ['$log'];

    /* @ngInject */
    function AcTreeviewCommunicationService($log) {
        var treeviewConfig = [];
        var treeviewService = [];
        var selectedNode = [];
        var nodeRefreshFunction = [];
        var treeviewReloadFunction = [];

        var service = {
            getTreeviewConfig: getTreeviewConfig,
            setTreeviewConfig: setTreeviewConfig,

            getTreeviewService: getTreeviewService,
            setTreeviewService: setTreeviewService,

            getSelectedNode: getSelectedNode,
            setSelectedNode: setSelectedNode,
            resetSelectedNode: resetSelectedNode,

            registerNodeRefreshFunction: registerNodeRefreshFunction,
            unregisterNodeRefreshFunction: unregisterNodeRefreshFunction,
            raiseNodeRefreshFunction: raiseNodeRefreshFunction,

            registerTreeviewReloadFunction: registerTreeviewReloadFunction,
            unregisterTreeviewReloadFunction: unregisterTreeviewReloadFunction,
            raiseTreeviewReloadFunction: raiseTreeviewReloadFunction
        };

        return service;

        // region config
        function getTreeviewConfig(name) {
            return angular.copy(treeviewConfig[name]);
        }

        function setTreeviewConfig(name, config) {
            if (config) {
                treeviewConfig[name] = angular.copy(config);
            }
        }

        function getTreeviewService(name) {
            return angular.copy(treeviewService[name]);
        }

        function setTreeviewService(name, service) {
            if (service) {
                treeviewService[name] = angular.copy(service);
            }
        }

        // endregion

        // region selectedNode
        function getSelectedNode(name) {
            return angular.copy(selectedNode[name]);
        }

        function setSelectedNode(name, node) {
            if (node) {
                selectedNode[name] = angular.copy(node);
            }
        }

        function resetSelectedNode(name) {
            selectedNode[name] = {};
        }

        // endregion

        // region node refresh
        function registerNodeRefreshFunction(name, func) {
            if (angular.isFunction(func)) {
                nodeRefreshFunction[name] = func;
            }
            else {
                $log.warn(func + 'is not a function');
            }
        }

        function unregisterNodeRefreshFunction(name) {
            nodeRefreshFunction[name] = angular.noop;
        }

        function raiseNodeRefreshFunction(name, id, expand, node) {
            if (angular.isFunction(nodeRefreshFunction[name])) {
                nodeRefreshFunction[name](id, expand, node);
            }
        }

        // endregion

        // region treeview reload
        function registerTreeviewReloadFunction(name, func) {
            if (angular.isFunction(func)) {
                treeviewReloadFunction[name] = func;
            }
            else {
                $log.warn(func + 'is not a function');
            }
        }

        function unregisterTreeviewReloadFunction(name) {
            treeviewReloadFunction[name] = angular.noop;
        }

        function raiseTreeviewReloadFunction(name, params) {
            if (angular.isFunction(treeviewReloadFunction[name])) {
                treeviewReloadFunction[name](params);
            }
        }

        // endregion
    }
})(angular);