import validatorBuilder from 'validator-builder';

let syntheseModeleValidator = null;

export default class SyntheseModele {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.libelle = data.libelle;
        this.typeDeSynthese = data.typeDeSynthese;
        this.listeSyntheseCritereElement = data.listeSyntheseCritereElement || [];
        this.typeDeSyntheseLibelle = data.typeDeSyntheseLibelle;
        this.idFamille = data.idFamille;
        this.libelleFamille = data.libelleFamille;
    }

    isValid() {
        syntheseModeleValidator = syntheseModeleValidator || validatorBuilder.getInstanceFor('SyntheseModele');
        const validationResults = syntheseModeleValidator.validate(this);
        return validationResults.isValid;
    }
}
