import { ICompileService, IScope } from 'angular';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '../../../core/decorators';

@Component({
    selector: 'massia-dynamic-component'
})
export class MassiaDynamicComponent implements OnInit {
    @Input('=') model: any;
    @Input('@') name: string;

    /* @ngInject */
    constructor(private $scope: IScope, private $compile: ICompileService, private $element: JQuery) { }

    ngOnInit() {
        this.renderComponent(this.name, this.model);
    }

    renderComponent(name: string, model: any) {
        var template = '<' + name;
        var scope: any = this.$scope.$new();
        if (model && !Array.isArray(model)) {
            scope.model = model;
            template += ' model="model"';
        } else if (model && Array.isArray(model)) {
            for (let i = 0; i < model.length; i++) {
                const m = model[i];
                scope[m.name] = m.data;
                template += ` ${this.toKebabCase(m.name)}=${m.name}`;
            }
        }

        template += `></${name}>`;
        this.$element.append(this.$compile(template)(scope));
    }

    toKebabCase(str: string) {
        return str.replace(/([A-Z])/g, (correspondance: string) => `-${correspondance.toLowerCase()}`);
    }
}
