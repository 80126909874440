const serviceUrl = `${__configuration.apiUrl}/massia/activation-site-synchro`;

export default class ActivationSiteSynchroService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getActivations() {
        const res = await this.$http.get(serviceUrl);
        return res.data;
    }

    async setActivations(id) {
        const res = await this.$http.post(serviceUrl, id);
        return res.data;
    }
}
