import PrelevementBonLivraisonController from './prelevement.bon.livraison.controller';

export default {
    bindings: {
        /* saisie: '=',
        bonLivraison: '<', */

        close: '&',
        dismiss: '&',
        resolve: '<'
    },
    template: require('./prelevement.bon.livraison.html'),
    controller: PrelevementBonLivraisonController
};
