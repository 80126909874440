
import ApiService from '../../app/services/api.service';
import { Injectable } from '../decorators';
import { BaseHttpService } from '../interface/MassiaHttpService.interface';
import { Produit } from '../models/produit/Produit';
import { ProduitDetail } from '../models/produit/ProduitDetail';

@Injectable('ProductService')
export class ProductService extends BaseHttpService<Produit, ProduitDetail, any, any, any> {
    /* @ngInject */
    constructor(ApiService: ApiService) {
        super(ApiService);
        this.url = 'v2/massia/products';
    }
}
