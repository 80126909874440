MockValidator.$inject = ['validator'];

export default function MockValidator(validator) {
    //const instance = new validator();
    const instance = {};
    instance.something = function () {
        return 5;
    };
    return instance;
}
