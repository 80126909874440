import { copy } from 'angular';

/* @ngInject */
export default class ApiService {
    /**
     *
     * @param {ng.IHttpService} $http
     */
    constructor($http, notification, $log, $translate) {
        /**
         * @type ng.IHttpService
         */
        this._$http = $http;
        /**
         * @type ng.ILogService
         */
        this.$log = $log;
        this.$translate = $translate;
        const originalUrl = this._getFormatedUrl(__server);
        this._apiUrl = `${originalUrl}api/`;
        this.notification = notification;
    }

    /**
     *
     * @param {string} url
     * @param {ng.IRequestShortcutConfig} config
     */
    get(url, config = {}) {
        return new Promise((resolve, reject) => {
            this._$http
                .get(`${this._apiUrl}${url}`, config)
                .then((res) => resolve(res.data))
                .catch((err) => {
                    reject(err);
                    this._handleError(err);
                });
        });
    }

    /**
     *
     * @param {string} url
     * @param {ng.IRequestShortcutConfig} config
     */
    post(url, data, config = {}) {
        return new Promise((resolve, reject) => {
            this._$http
                .post(`${this._apiUrl}${url}`, data, config)
                .then((res) => resolve(res.data))
                .catch((err) => {
                    reject(err);
                    this._handleError(err);
                });
        });
    }

    /**
     *
     * @param {string} url
     * @param {ng.IRequestShortcutConfig} config
     */
    put(url, data, config = {}) {
        return new Promise((resolve, reject) => {
            this._$http
                .put(`${this._apiUrl}${url}`, data, config)
                .then((res) => resolve(res.data))
                .catch((err) => {
                    reject(err);
                    this._handleError(err);
                });
        });
    }

    /**
     *
     * @param {string} url
     * @param {ng.IRequestShortcutConfig} config
     */
    patch(url, data, config = {}) {
        return new Promise((resolve, reject) => {
            this._$http
                .patch(`${this._apiUrl}${url}`, data, config)
                .then((res) => resolve(res.data))
                .catch((err) => {
                    reject(err);
                    this._handleError(err);
                });
        });
    }

    /**
     *
     * @param {string} url
     * @param {ng.IRequestShortcutConfig} config
     */
    delete(url, config = {}) {
        return new Promise((resolve, reject) => {
            this._$http
                .delete(`${this._apiUrl}${url}`, config)
                .then((res) => resolve(res.data))
                .catch((err) => {
                    reject(err);
                    this._handleError(err);
                });
        });
    }

    _handleError = (err) => {
        if (err.status === -1) {
            this.notification.error('SERVER_ERROR_COMMUNICATE');
        } else if (err.data && err.data.message) {
            const msg = this.$translate.instant(`${err.data.message}`);
            this.notification.error(msg || err.data.message);
        } else if (err.data) {
            this.notification.error(err.data);
        } else {
            this.notification.error(err);
        }
        this.$log.error(err);
    };

    _getFormatedUrl(url) {
        if (url[url.length - 1] !== '/') {
            return (url += '/');
        }
        return url;
    }
}
