// const labo_2 = 'assets/prel-bl-config/eqiom.json';

export default class PrelevementImportDataService {
    /* @ngInject */

    constructor($http, TicketsService, moment, FormuleService, ParametreProgrammeService) {
        this.$http = $http;
        this.moment = moment;
        this.FormuleService = FormuleService;
        this.TicketsService = TicketsService;
        this.ParametreProgrammeService = ParametreProgrammeService;
    }

    async init(dateFormat) {
        this.dateFormat = dateFormat;
        const jsonFile = `assets/prel-bl-config/config_${__configuration.licenceLaboratoire.toString()}.json`;
        this.config = (await this.$http.get(jsonFile)).data;
    }

    checkEssais(prelevement) {
        const missings = [];
        const essais = [...new Set(this.config.config_BL.map((x) => x.essai))];

        for (let i = 0; i < essais.length; i++) {
            if (!prelevement.mesures.find((x) => x.code === essais[i])) {
                missings.push(essais[i]);
            }
        }
        return missings;
    }

    async setBL(Bl = null) {
        this.bonLivraison = Bl;
    }

    getDataFromBl(prelevement) {
        for (let i = 0; i < this.config.config_BL.length; i++) {
            const item = this.config.config_BL[i];

            if (item.type === 'simple' && typeof item.property === 'string') {
                const value = this.getSimpleValue(item.property, this.bonLivraison);
                if (value) {
                    prelevement = this.setSousEssaiSimpleValue(value, item, prelevement);
                }
            } else if (item.type === 'simple' && Array.isArray(item.property)) {
                let value = '';
                for (let i = 0; i < item.property.length; i++) {
                    const prop = item.property[i];
                    const val = this.getSimpleValue(prop, this.bonLivraison);
                    if (val && i === 0) {
                        value = val;
                    }
                    if (val && i !== 0) {
                        value += ' / ' + val;
                    }
                }
                if (value) {
                    prelevement = this.setSousEssaiSimpleValue(value, item, prelevement);
                }
            } else if (item.type === 'date') {
                const date = this.getSimpleValue(item.property, this.bonLivraison);
                if (date) {
                    const value = this.getDateValueFromBl(date, item.format);
                    if (value) {
                        prelevement = this.setSousEssaiDateValue(value, item, prelevement);
                    }
                }
            } else if (item.type === 'list') {
                const liste = this.getListeFromBl(item);
                if (liste && liste.length > 0) {
                    const sum = liste.reduce((a, b) => a + b);

                    if (item.calcul === 'sum') {
                        prelevement = this.setSousEssaiDateValue(sum, item, prelevement);
                    } else {
                        const moy = sum / liste.length;
                        prelevement = this.setSousEssaiDateValue(moy, item, prelevement);
                    }
                }
            }
        }
        return prelevement;
    }

    getDateValueFromBl(date, format) {
        if (format === 'date') {
            if (typeof date === 'string') {
                const d = new Date(date);
                return this.moment(d).format(this.dateFormat);
            }
        } else if (format === 'heure') {
            if (typeof date === 'string') {
                return new Date(date);
            }
        }
    }

    setSousEssaiSimpleValue(value, item, prelevement) {
        const essaiIdx = prelevement.mesures.findIndex((x) => x.code === item.essai);
        if (essaiIdx !== -1) {
            const sousEssaiList = Object.values(prelevement.mesures[essaiIdx].sousEssais);

            const sousEssai = sousEssaiList.find((x) => x.code === item['sous-essai']);

            if (sousEssai) {
                const idx = `_${sousEssai.id}`;
                const idNature = sousEssai.idNature;

                value = idNature === 3 ? Number(value) : value.toString();

                prelevement.mesures[essaiIdx].sousEssais[idx].value = value;
            }
            //
            const sousEssGeneriqueList = sousEssaiList.filter((x) => x.idNature === 10);
            if (sousEssGeneriqueList.length > 0) {
                for (const sousEssGen of Object.values(sousEssGeneriqueList)) {
                    const idSousEssGen = `_${sousEssGen.id}`;

                    const ssEssInEssGen = Object.values(sousEssGen.generiqueAdditionalInformation.essaiLie.sousEssais).find(
                        (x) => x.code === item['sous-essai']
                    );
                    if (ssEssInEssGen) {
                        const idxEssGen = `_${ssEssInEssGen.id}`;
                        const idNatureEssGen = ssEssInEssGen.idNature;

                        value = idNatureEssGen === 3 ? Number(value) : value.toString();

                        prelevement.mesures[essaiIdx].sousEssais[idSousEssGen].generiqueAdditionalInformation.essaiLie.sousEssais[idxEssGen].value = [
                            value
                        ];
                    }
                }
            }
        }
        return prelevement;
    }

    setSousEssaiDateValue(value, item, prelevement) {
        const essaiIdx = prelevement.mesures.findIndex((x) => x.code === item.essai);
        if (essaiIdx !== -1) {
            const sousEssai = Object.values(prelevement.mesures[essaiIdx].sousEssais).find((x) => x.code === item['sous-essai']);

            if (sousEssai) {
                const idx = `_${sousEssai.id}`;
                const idNature = sousEssai.idNature;

                if (idNature === 2 && typeof value === 'object') {
                    value = value.format(this.dateFormat);
                }

                if (item.format === 'heure' && idNature === 0) {
                    value = this.moment(value).format('hh:mm');
                }

                prelevement.mesures[essaiIdx].sousEssais[idx].value = value;
            }
        }
        return prelevement;
    }

    getListeFromBl(item) {
        const array = this.getSimpleValue(item.property, this.bonLivraison);
        let arrayCopie = angular.copy(array);

        const propertiesArray = item['array-property'].split('.');

        const filterProp = item['filter-array-property'];
        const filterValue = item['filter-array-value'];
        const propertyList = item['property-list'];
        let exist = true;

        if (array && typeof array === 'object' && array.length && array.length > 0) {
            if (propertyList && propertiesArray.length == 1) {
                const prop = propertiesArray[0];
                const nouvArrayCopie = arrayCopie[0][propertyList];
                if ((nouvArrayCopie[0] && !nouvArrayCopie[0].hasOwnProperty(prop)) || (!nouvArrayCopie[0] && !nouvArrayCopie.hasOwnProperty(prop))) {
                    exist = false;
                }
            } else {
                //* vérifie que dans le tableau la prop existe
                for (let i = 0; i < propertiesArray.length; i++) {
                    const prop = propertiesArray[i];
                    if ((arrayCopie[0] && !arrayCopie[0].hasOwnProperty(prop)) || (!arrayCopie[0] && !arrayCopie.hasOwnProperty(prop))) {
                        exist = false;
                    } else {
                        if (arrayCopie[0] && arrayCopie[0].hasOwnProperty(prop)) {
                            arrayCopie = arrayCopie[0][prop];
                        } else if (!arrayCopie[0] && !arrayCopie.hasOwnProperty(prop)) {
                            arrayCopie = arrayCopie[prop];
                        }
                    }
                }
            }

            if (!exist) {
                return false;
            }
            let liste = angular.copy(array);
            if (propertyList && propertiesArray.length == 1) {
                const nouvList = [];
                for (let i = 0; i < array.length; i++) {
                    const listA = array[i][propertyList];
                    const prop = propertiesArray[0];
                    for (let j = 0; j < listA.length; j++) {
                        if (filterProp && filterValue) {
                            const filterArray = filterProp.split('.');
                            let propAfiltrer = listA[j];
                            for (let k = 0; k < filterArray.length; k++) {
                                if (propAfiltrer.hasOwnProperty(filterArray[k])) {
                                    propAfiltrer = propAfiltrer[filterArray[k]];
                                }
                            }
                            if (propAfiltrer == filterValue) {
                                nouvList.push(listA[j][prop]);
                            }
                        } else {
                            nouvList.push(listA[j][prop]);
                        }
                    }
                }
                liste = nouvList;
            } else {
                for (let i = 0; i < propertiesArray.length; i++) {
                    const prop = propertiesArray[i];
                    liste = liste.map((x) => x[prop]);
                }
            }

            //todo check null // undefined values pour les calculs
            // ! → voir comment les traiter si on supprime les valeurs ou si on ne calcul pas ??
            return liste.filter((x) => x !== null && x !== undefined);
        }

        return false;
    }

    async getDataFromPrelevement(prelevement) {
        for (let i = 0; i < this.config.config_PRELEVEMENT.length; i++) {
            const item = this.config.config_PRELEVEMENT[i];
            if (item.type === 'simple' && typeof item.property === 'string') {
                const value = this.getSimpleValue(item.property, prelevement);
                if (value) {
                    prelevement = this.setSousEssaiSimpleValue(value, item, prelevement);
                }
            } else if (item.type === 'list') {
                const value = await this.getListePrelevement(item, prelevement);

                if (value) {
                    prelevement = this.setSousEssaiSimpleValue(value, item, prelevement);
                }
            }
        }
        return prelevement;
    }

    async getListePrelevement(item, prelevement) {
        const object = this.getSimpleValue(item.property, prelevement);

        if (item.code) {
            const codeParamService = await this.ParametreProgrammeService.getOneByCode(item.code);
            const propertiesArrayList = this.getSimpleValue(item['array-property'], object);

            if (codeParamService && propertiesArrayList && propertiesArrayList.length > 0) {
                const array = propertiesArrayList.find((x) => x.code === codeParamService);

                return array.elementValue ? array.elementValue.key : array.value;
            }
        } else {
            const propertiesArrayList = this.getSimpleValue(item['array-property'], object);

            if (propertiesArrayList && propertiesArrayList.length > 0) {
                if (propertiesArrayList && propertiesArrayList.length === 1) {
                    return propertiesArrayList[0].appelation;
                }
                return propertiesArrayList.map((e) => e.appelation).join(' // ');
            }
        }
    }

    async getDataFromFormule(prelevement) {
        const { produit, producteur } = prelevement;
        let value;
        if (produit && producteur) {
            // const formule = await this.FormuleService.getFormuleByProduitProducteur(produit.id, producteur.id);
            const formule = await this.FormuleService.getFormuleByProduitProducteurLight(produit.id, producteur.id);

            if (formule) {
                for (const item of this.config.config_FORMULE) {
                    if (item.type === 'simple' && typeof item.property === 'string') {
                        value = this.getSimpleValue(item.property, formule);
                    } else if (item.type === 'list') {
                        value = this.getListeFormule(item, formule);
                    }
                    if (typeof value !== 'undefined' && value !== null) {
                        prelevement = this.setSousEssaiSimpleValue(value, item, prelevement);
                    }
                }
            }
        }
        return prelevement;
    }

    getListeFormule(item, formule) {
        const array = this.getSimpleValue(item.property, formule);
        if (array && array.length > 0) {
            return array.length > 0 ? array[0][item['array-property']] : null;
        }
    }

    getSimpleValue(property, source) {
        const propertiesArray = property.split('.');
        let obj = angular.copy(source);
        for (const prop of propertiesArray) {
            if (obj && obj.hasOwnProperty(prop)) {
                obj = obj[prop];
            }
        }
        return obj;
    }
}
