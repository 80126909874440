(function () {
    'use strict';

    angular.module('blocks.helpers')
        .directive('acDynamicController', acDynamicController);
    acDynamicController.$inject = ['$compile', '$parse'];

    function acDynamicController($compile, $parse) {
        return {
            restrict: 'A',
            terminal: true,
            priority: 100000,
            link : function(scope, element, attrs) {
                var ctrl = $parse(element.attr('ac-dynamic-controller'))(scope);
                var ctrlAs = $parse(element.attr('ac-dynamic-controllerAs'))(scope);
                var initData = $parse(element.attr('ac-dynamic-init'))(scope);

                var ctrlName = ctrl + ' as ' + ctrlAs;
                var init = ctrlAs + '.init(' + initData + ')';

                element.removeAttr('ac-dynamic-controller');
                element.removeAttr('ac-dynamic-controllerAS');
                element.removeAttr('ac-dynamic-init');

                element.attr('ng-controller', ctrlName);
                element.attr('ng-init', init);
                $compile(element)(scope);
            }
        };
    }
})();
