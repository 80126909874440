import validatorBuilder from 'validator-builder';

let zoneValidator = null;

export default class Zone {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
    }

    isValid() {
        zoneValidator = zoneValidator || validatorBuilder.getInstanceFor('Zone');
        const validationResults = zoneValidator.validate(this);
        return validationResults.isValid;
    }
}
