/* @ngInject */
export default class BlocSelectionCritereValeurController {
    /**
     *
     * @param {import("angular").IScope} $scope
     * @param {JQLite} $element
     */
    constructor($scope, $state, $element, EnumChamp) {
        this.EnumChamp = EnumChamp;
        this.$scope = $scope;
    }
    $onInit() {
        this.fieldsetStyle = {
            border: '1px solid #b7b7b7',
            background: 'white'
        };
        this.legendStyle = {
            'border-radius': '5px',
            color: 'white',
            'box-shadow': '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)'
            /* 'font-weight': 'bold', */
        };
    }
    $onDestroy() {}
}
