ElementValidator.$inject = ['validator'];

export default function ElementValidator(validator) {
    const instance = new validator();

    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance
        .ruleFor('code')
        .matches(/^[a-zA-Z0-9_|]*$/)
        .withMessage('CODE_CHARACTERS_NOK');

    instance.ruleFor('nom').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('nom').length(1, 100).withMessage('VALIDATION_TOO_LONG_100');
    instance.ruleFor('typeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    instance.ruleFor('codeExists').notEqual(true).withMessage('VALIDATION_NOTEMPTY');

    return instance;
}
