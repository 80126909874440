RemiseFinAnneeValidator.$inject = ['validator'];

export default function RemiseFinAnneeValidator(validator) {
	const instance = new validator();

	instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('code').length(1, 15).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
	instance
		.ruleFor('code')
		.matches(/^[a-zA-Z0-9_|]*$/)
		.withMessage('CODE_CHARACTERS_NOK');

	instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

	instance.ruleFor('societeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('siteCommercialId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('dateDebut').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('dateFin').notEmpty().withMessage('VALIDATION_NOTEMPTY');

	return instance;
}
