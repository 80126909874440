const serviceUrl = `${__configuration.apiUrl}/massia/taches`;
const serviceLieesUrl = `${__configuration.apiUrl}/massia/taches-liees`;

export default class TachesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getTaches(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });
        return res.data;
    }

    async deleteTacheById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getTacheEnteteById(id) {
        const url = `${serviceUrl}/${id}/entete`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getTacheValeursCaracteristiquesAssociationsById(id) {
        const data = await this.getTacheValeursCaracteristiquesById(id);
        const result = {};

        for (const element in data) {
            if (data[element] && !Array.isArray(data[element])) {
                if (data[element].value === 'True') {
                    data[element].value = 'true';
                }
                if (data[element].value === 'False') {
                    data[element].value = 'false';
                }

                if (data[element].value && data[element].value.key) {
                    result[data[element].id] = data[element].value.key;
                } else {
                    result[data[element].id] = data[element].value;
                }
            }
        }
        return result;
    }

    async getTacheValeursCaracteristiquesById(id) {
        const url = `${serviceUrl}/${id}/valeurs-caracteristiques`;
        const res = await this.$http.get(url);
        const result = [];
        res.data.forEach(function (element) {
            const caract = {
                id: element.id,
                code: element.code,
                idNature: element.idNature,
                indice: element.indice,
                label: element.label,
                numericAdditionalInformation: element.numericAdditionalInformation,
                value: element.elementsValues || element.elementValue || element.values || element.value
            };
            result.push(caract);
        });
        return result;
    }

    async createTache(tache) {
        const data = {
            code: tache.entete.code,
            libelle: tache.entete.libelle,
            typeId: tache.entete.typeId,
            priorite: tache.entete.priorite,
            etat: tache.entete.etat,
            debut: tache.entete.debut,
            echeance: tache.entete.echeance,
            pourcentageAvancement: tache.entete.pourcentageAvancement,
            personnesIds: tache.entete.personnesIds,
            caracteristiques: []
        };
        data.caracteristiques = this.formatCaracteristiques(tache.caracteristiques);

        const result = await this.$http.post(serviceUrl, data);

        return result.data;
    }

    formatCaracteristiques(caracteristiques) {
        const data = [];
        for (const property in caracteristiques) {
            let keyValueObject;
            //On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variables interne Js.
            if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
                const propertyVal = caracteristiques[property];
                if (Array.isArray(propertyVal)) {
                    if (_.some(propertyVal, 'key')) {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: _.map(propertyVal, 'key')
                        };
                    } else {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: propertyVal
                        };
                    }
                } else {
                    keyValueObject = {
                        IdCaracteristique: parseInt(property),
                        valeur: propertyVal
                    };
                }
                data.push(keyValueObject);
            }
        }
        return data;
    }

    async updateTache(tache) {
        const data = tache.entete;
        data.caracteristiques = [];
        data.caracteristiques = this.formatCaracteristiques(tache.caracteristiques);

        const url = `${serviceUrl}/${tache.id}`;
        return await this.$http.put(url, data);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getEtats() {
        const url = 'assets/enum/taches/etats.json';
        const res = await this.$http.get(url);
        return res.data;
    }

    async getPriorites() {
        const url = 'assets/enum/taches/priorites.json';
        const res = await this.$http.get(url);
        return res.data;
    }

    async getPersonnes() {
        const personneUrl = `${__configuration.apiUrl}/massia/personnes/utilisateurs`;
        const res = await this.$http.get(personneUrl);
        return res.data;
    }

    async updateFiltre(id, domaine, idTaches) {
        const data = {
            id: id,
            domaine: domaine,
            idTaches: idTaches
        };
        const url = `${serviceLieesUrl}`;
        return this.$http.put(url, data);
    }

    async getFiltres() {
        const url = `${serviceUrl}/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getTachesLiees(id, domaine) {
        const url = `${serviceLieesUrl}/${id}/${domaine}`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
