import { Component, OnDestroy, OnInit } from '../../../../../core/decorators';

@Component({
    selector: 'filter-numeric',
    bindings: {
        filter: '=',
        model: '='
    },
    template: require('./filter-numeric.tpl.html')
})
export class FilterNumericComponent implements OnInit, OnDestroy {
    constructor() {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
