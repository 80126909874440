import * as _ from 'lodash';
import validatorBuilder from '../../libs/blocks.validation/src/components/validator.builder';
import { Injectable } from '../decorators';

@Injectable('ValidatorHelper')
export class ValidatorHelper {
    static getValidation<T>(obj: T, validatorName: string): any {
        validatorName = validatorName.replace('Validator', '');
        validatorName = validatorName;
        const validator = validatorBuilder.getInstanceFor(validatorName);
        const result = validator.validate(obj);
        return result;
    }

    static isValid<T>(obj: T, validatorName: string): boolean {
        validatorName = validatorName.replace('Validator', '');
        validatorName = validatorName;
        const validator = validatorBuilder.getInstanceFor(validatorName);
        const result = validator.validate(obj);
        return result.isValid;
    }

    static getErrors<T>(obj: T, validatorName: string): any {
        validatorName = validatorName.replace('Validator', '');
        validatorName = validatorName;
        const validator = validatorBuilder.getInstanceFor(validatorName);
        const result = validator.validate(obj);
        return result.errors;
    }
}
