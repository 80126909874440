import Banque from './banque.model';

export default class BanqueFormController {
    static $inject = [
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'BanquesCommunicationService',
        'BanquesService',
        'notification',
        '$location',
        '$anchorScroll',
        '_',
        'MassiaApplicationService',
        'MOPService'
    ];

    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        BanquesCommunicationService,
        BanquesService,
        notification,
        $location,
        $anchorScroll,
        _,
        MassiaApplicationService,
        MOPService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.BanquesCommunicationService = BanquesCommunicationService;
        this.BanquesService = BanquesService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this._ = _;
        this.MassiaApplicationService = MassiaApplicationService;
        this.MOPService = MOPService;
    }

    $onInit() {
        this.MOPService.setMop([{ title: 'BANQUES.BREADCRUMBS.BANQUES_LIST', filename: 'Banques.pdf', application: 'gestion' }]);
        this.banque = {};
        this.loading = false;

        // Configuration
        this.reset();
        // this.setWatchers();
    }

    $onDestroy() {
        this.$timeout.cancel(this.updateBanqueTimeout);
        this.MOPService.resetMop();
    }

    async reset() {
        this.startLoading();

        const data = {
            id: this.$stateParams.id
        };

        if (data.id) {
            try {
                data.entete = await this.BanquesService.getBanqueById(data.id);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
                this.annuler();
            }
        }
        this.banque = new Banque(data);
        this.$timeout.cancel(this.updateBanqueTimeout);
        this.updateBanqueTimeout = this.$timeout(() => this.stopLoading());
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();

            try {
                let id = false;
                if (this.banque.id) {
                    await this.BanquesService.updateBanque(this.banque);
                    this.notification.success('BANQUES.UPDATED');
                    id = this.banque.id;
                } else {
                    id = await this.BanquesService.createBanque(this.banque);
                    this.notification.success('BANQUES.CREATED');
                }

                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async appliquer() {
        const id = await this.sauvegarder();
        if (id && this.banque.id) {
            this.reset();
        } else if (id) {
            this.$state.go('banques.edit', { id: id });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    annuler() {
        this.$state.go('banques.list');
    }

    checkValidity() {
        let validity = true;
        if (!this.banque.entete.isValid()) {
            validity = false;
            this.$scope.$broadcast('banqueEnteteValidations');
        }
        return validity;
    }

    onUpdateEntete(entete) {
        this.banque.entete = entete;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
