import PersonneFormWidgetEssaisPlanifiesController from './personne.form.widget.essais.planifies.controller';

export default {
    bindings: {
        personne: '=',
        onUpdate: '&',
        form: '='
    },

    template: require('./personne.form.widget.essais.planifies.html'),
    controller: PersonneFormWidgetEssaisPlanifiesController
};
