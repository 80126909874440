import PersonnesConfig from './_config/personnes.config';
import PersonnesRoutes from './_config/personnes.routes';
import PersonnesPerfRoutes from './_config/personnes-perf.routes';
import ContactsRoutes from './_config/contacts.routes';
import ChauffeursRoutes from './_config/chauffeurs.routes';
import UtilisateursRoutes from './_config/utilisateurs.routes';
import PersonnesComponent from './components/personnes';
import PersonnesService from './services/personnes.service';
import PersonnesCommunicationService from './services/personnes.communication.service';
import PersonnesTypesService from './services/personnes.types.service';

import PersonnesFormComponent from './components/personnes-form';
import PersonneFormCaracteristiquesComponent from './components/personnes-form/caracteristiques';
import PersonneDetailComponent from './components/personne-detail';

import PersonneValidator from './components/personnes-form/personnes.validator';
import PersonneCaracteristiquesValidator from './components/personnes-form/caracteristiques/personne.caracteristiques.validator';
import UtilisateurPasswordValidator from './components/utilisateur-password-form//utilisateur.password.validator';
import ContactValidator from './components/mini-form/contact/mini.form.contact.validator';
import MiniFormSignatureComponent from '../../common/images/components/mini-form/signature';
import SignatureValidator from '../../common/images/components/mini-form/signature/mini.form.signature.validator';
import PersonneFormWidgetEssaisPlanifies from './components/personnes-form/widget-essais-planifies';
import PersonneFormWidgetCreationPlv from './components/personnes-form/widget-creation-plv';

const moduleName = 'app.massia.performance.personnes';

angular
    .module(moduleName, [])
    .config(PersonnesConfig)
    .config(PersonnesRoutes)
    .config(PersonnesPerfRoutes)
    .config(ChauffeursRoutes)
    .config(UtilisateursRoutes)
    .config(ContactsRoutes)
    .service('PersonnesService', PersonnesService)
    .service('PersonnesCommunicationService', PersonnesCommunicationService)
    .service('PersonnesTypesService', PersonnesTypesService)
    .component('personnes', PersonnesComponent)
    .component('personnesForm', PersonnesFormComponent)
    .component('personneFormCaracteristiques', PersonneFormCaracteristiquesComponent)
    .component('personneDetail', PersonneDetailComponent)
    .component('miniFormSignature', MiniFormSignatureComponent)
    .component('personneFormWidgetEssaisPlanifies', PersonneFormWidgetEssaisPlanifies)
    .component('personneFormWidgetCreationPlv', PersonneFormWidgetCreationPlv)
    .factory('PersonneValidator', PersonneValidator)
    .factory('PersonneCaracteristiquesValidator', PersonneCaracteristiquesValidator)
    .factory('UtilisateurPasswordValidator', UtilisateurPasswordValidator)
    .factory('ContactValidator', ContactValidator)
    .factory('SignatureValidator', SignatureValidator);

export default moduleName;
