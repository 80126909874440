import { getNatureNamesByValues } from '../../../caracteristiques/services/natures.enum';

export default class TacheDetailController {
    static $inject = ['$stateParams', '$state', 'TachesService', 'notification', 'TachesCommunicationService'];

    constructor($stateParams, $state, TachesService, notification, TachesCommunicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.TachesService = TachesService;
        this.notification = notification;
        this.TachesCommunicationService = TachesCommunicationService;
    }

    $onInit() {
        this.loading = false;
        this.tache = {};
        this.caracteristiques = [];

        this.ongletOpen = {
            isEnteteOpen: true,
            isCaracteristiqueOpen: false
        };

        this.initTache();
    }

    async initTache() {
        this.tache = { id: this.$stateParams.id };
        if (this.tache.id) {
            this.startLoading();
            try {
                this.tache.entete = await this.TachesService.getTacheEnteteById(this.tache.id);
                this.tache.caracteristiques = await this.TachesService.getTacheValeursCaracteristiquesById(this.tache.id);
                this.setCaracteristiques(this.tache.caracteristiques);
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListTache();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListTache();
        }
    }

    async setCaracteristiques(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiques = [];
        caracteristiques.forEach((c) => {
            this.caracteristiques.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    goListTache() {
        this.$state.go('taches.list', {}, { reload: true });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
