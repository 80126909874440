CalculsStatistiquesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'CalculsStatistiquesService',
    'notification',
    'MassiaApplicationService',
    '$transitions',
    'MOPService'
];

export default function CalculsStatistiquesController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    CalculsStatistiquesService,
    notification,
    MassiaApplicationService,
    $transitions,
    MOPService
) {
    const vm = this;
    const watchers = [];
    //toto
    let previousTableState;
    vm.selectedCalculId = undefined;
    vm.lastSelected = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.calculs = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.selectCalcul = selectCalcul;
    vm.isCalculSelected = isCalculSelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadCalculs = loadCalculs;
    vm.deleteCalcul = deleteCalcul;
    vm.setSelected = setSelected;
    vm.eraseAllFilters = eraseAllFilters;
    vm.MOPService = MOPService;

    vm.$onInit = () => {
        vm.MOPService.setMop([{ title: 'CALCULS_STATS.BREADCRUMBS.CALCULS_STATS_LIST', filename: 'CalculsStatistiques.pdf', application: 'massia' }]);
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        //$transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    };

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    function stateChangeSuccessFunc(event, toState) {
        if (!$state.params.id) {
            vm.setSelected();
        } else if ($state.params.id) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    function setSelected(calcul) {
        if (calcul && calcul.id) {
            vm.selectedCalculId = $stateParams.id;
            vm.lastSelected = vm.selectedCalculId;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            vm.selectedCalculId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return $state && $state.current && $state.current.name && $state.current.name === 'calculs.list.detail' && $stateParams && $stateParams.id;
    }

    function selectCalcul(calcul) {
        if (calcul && calcul.id) {
            if (vm.selectedCalculId !== calcul.id) {
                vm.selectedCalculId = calcul.id;
                showDetail(calcul.id);
            } else {
                vm.selectedCalculId = undefined;
                closeDetail();
            }
        }
    }

    function isCalculSelected(calcul) {
        return calcul && calcul.id && vm.selectedCalculId === calcul.id;
    }

    function showDetail(calculId) {
        $state.go('calculs.list.detail', { id: calculId });
    }

    function closeDetail() {
        $state.go('calculs.list');
    }

    async function loadCalculs(tableState) {
        startLoading();

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.calculs = [];

        try {
            const data = await CalculsStatistiquesService.getCalculsStatistiques(
                filters,
                sorts,
                pagination,
                MassiaApplicationService.getApplication()
            );

            vm.calculs = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteCalcul(calcul) {
        if (calcul /*&& calcul.canDelete*/ && calcul.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('CALCULS_STATS.DELETE.TITLE', { libelle: calcul.libelle }),
                modalMsg: $translate.instant('CALCULS_STATS.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await CalculsStatistiquesService.deleteCalculStatistiqueById(calcul.id);
                    notification.success($translate.instant('CALCULS_STATS.DELETE.SUCCESS'));

                    vm.selectedCalculId = undefined;

                    if (vm.params.id) {
                        vm.state.go('calculs.list');
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadCalculs();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function eraseAllFilters() {
        loadCalculs();
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
