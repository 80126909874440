import PontComptableController from './pont.comptable.controller';

export default {
    bindings: {
        societe: '<',
        onUpdate: '&'
    },
    template: require('./pont.comptable.html'),
    controller: PontComptableController
};
