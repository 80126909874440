import TicketsConfig from './_config/tickets.config';
import TicketsRoutes from './_config/tickets.routes';
import TicketsComponent from './components/tickets';
import AnnulationPopupComponent from './components/annulation-popup';
import TicketImportComponent from './components/ticket-import';
import TicketComposantComponent from './components/ticket-composant';
import TicketsService from './services/tickets.service';
import TicketsCommunicationService from './services/tickets.communication.service';

import TicketDetailComponent from './components/ticket-detail';
import TicketSearch from './components/ticket-search';
import TicketsExportCsvPopupComponent from './components/tickets-export-csv-popup';
import ticketCommentaireComponent from './components/ticket-commentaire';

const moduleName = 'app.massia.common.tickets';

angular
    .module(moduleName, [])
    .config(TicketsConfig)
    .config(TicketsRoutes)
    .service('TicketsService', TicketsService)
    .service('TicketsCommunicationService', TicketsCommunicationService)
    .component('tickets', TicketsComponent)
    .component('annulationPopup', AnnulationPopupComponent)
    .component('ticketImport', TicketImportComponent)
    .component('ticketComposant', TicketComposantComponent)
    .component('ticketDetail', TicketDetailComponent)
    .component('ticketSearch', TicketSearch)
    .component('ticketsExportCsvPopup', TicketsExportCsvPopupComponent)
    .component('ticketCommentaireComponent', ticketCommentaireComponent);

export default moduleName;
