ModelesSaisiesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'ModelesSaisiesService',
    'notification',
    'MassiaApplicationService',
    '$transitions',
    'MOPService'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function ModelesSaisiesController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    ModelesSaisiesService,
    notification,
    MassiaApplicationService,
    $transitions,
    MOPService
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    vm.selectedModeleId = undefined;
    vm.lastSelected = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.modeles = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];
    vm.getTypesCM = getTypesCM;
    vm.getTypesSM = getTypesSM;
    vm.getThemes = getThemes;
    vm.getFamilles = getFamilles;
    vm.getApplication = getApplication;

    vm.selectModele = selectModele;
    vm.isModeleSelected = isModeleSelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadModeles = loadModeles;
    vm.deleteModele = deleteModele;
    vm.setSelected = setSelected;
    vm.eraseAllFilters = eraseAllFilters;
    vm.getDomaineCourant = getDomaineCourant;
    vm.duplicateModele = duplicateModele;
    vm.exporter = exporter;
    vm.afficheCode = true;
    vm.MOPService = MOPService;

    async function init() {
        vm.MOPService.setMop([
            { title: 'MODELES_SAISIES.BREADCRUMBS.MODELES_SAISIES_LIST', filename: 'ModelesDeSaisie.pdf', application: 'laboratoire' }
        ]);
        //écouter l'event lié au changement de route
        //une fois on change l'url, et on arrive sur la page "modeles.list", on désélectionne la ligne sélectionnée (s'il y en a)
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        //$transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        //quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
        vm.afficheCode = await ModelesSaisiesService.afficheCode(getDomaineCourant());
        await getTypesSM();
        getTypesCM();
        getThemes();
        getFamilles();
        // getModelesPlvmnt();
    }

    init();

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    function getDomaineCourant() {
        if ($state.current.name === 'modelessaisies.list' || $state.current.name === 'modelessaisies.list.detail') {
            return 'modelessaisies';
        } else if ($state.current.name === 'modelessaisiese.list' || $state.current.name === 'modelessaisiese.list.detail') {
            return 'modelessaisiese';
        } else if ($state.current.name === 'modelessaisiesm.list' || $state.current.name === 'modelessaisiesm.list.detail') {
            return 'modelessaisiesm';
        } else if ($state.current.name === 'modelessaisiesp.list' || $state.current.name === 'modelessaisiesp.list.detail') {
            return 'modelessaisiesp';
        }
    }

    function getApplication() {
        return MassiaApplicationService.getApplication();
    }

    function isActuListe() {
        return $state.current.name.indexOf('list') >= 0;
    }

    function stateChangeSuccessFunc(event, toState) {
        if (!$state.params.id) {
            vm.setSelected();
        } else {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    async function getTypesCM(event, toState) {
        vm.typesCM = await ModelesSaisiesService.getTypesCM(getDomaineCourant());
    }

    async function getTypesSM(event, toState) {
        vm.typesSM = await ModelesSaisiesService.getTypesSM(getDomaineCourant());
    }

    async function getThemes(event, toState) {
        vm.themes = await ModelesSaisiesService.getThemes(getDomaineCourant());
    }

    async function getFamilles(event, toState) {
        vm.familles = await ModelesSaisiesService.getFamilles();
    }

    function setSelected(modele) {
        if (modele && modele.id) {
            vm.selectedModeleId = $stateParams.id;
            vm.lastSelected = vm.selectedModeleId;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            vm.selectedModeleId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return $state && $state.current && $state.current.name && !isActuListe() && $stateParams && $stateParams.id;
    }

    function selectModele(modele) {
        if (modele && modele.id) {
            //si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedModeleId, et charger la vue détail pour le modele sélectionné
            if (vm.selectedModeleId !== modele.id) {
                vm.selectedModeleId = modele.id;
                showDetail(modele.id);
            } else {
                //si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
                vm.selectedModeleId = undefined;
                closeDetail();
            }
        }
    }

    function isModeleSelected(modele) {
        return modele && modele.id && vm.selectedModeleId === modele.id;
    }

    function showDetail(modeleId) {
        const route = getDomaineCourant();
        $state.go(route + '.detail', { id: modeleId });
    }

    function closeDetail() {
        $state.go(getDomaineCourant() + '.list');
    }

    // async function getModelesPlvmnt() {
    //     try {
    //         for (let i = 0; i < vm.modeles.length; i++) {
    //             vm.modeles[i].prelevement = vm.typesSM.find(x => x.id == vm.modeles[i].typeSMId)?.libelle;
    //         }
    //     }
    //     catch (err) {
    //         console.error(err);
    //     }
    // }
    async function loadModeles(tableState) {
        startLoading();

        //quand on rafraîchit la grille, on revient sur "modeles.list", et on ferme la vue détail d'un docce
        //sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
        if ($state && $state.current && !isActuListe() /*$state.current.name !== 'modeles.list'*/) {
            $state.go(getDomaineCourant() + '.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.modeles = [];

        try {
            const data = await ModelesSaisiesService.getModeles(filters, sorts, pagination, getDomaineCourant());

            vm.modeles = data.items;
            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteModele(modele) {
        if (modele && modele.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('MODELES_SAISIES.DELETE.TITLE', { libelle: modele.libelle }),
                modalMsg: $translate.instant('MODELES_SAISIES.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await ModelesSaisiesService.deleteModeleById(modele.id);
                    notification.success($translate.instant('MODELES_SAISIES.DELETE.SUCCESS'));

                    vm.selectedModeleId = undefined;

                    // si jamais la vue détail est affichée,
                    // on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
                    if (vm.params.id) {
                        vm.state.go(getDomaineCourant() + '.list');
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadModeles();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function duplicateModele(modele) {
        if (modele && modele.id) {
            $state.go(getDomaineCourant() + '.duplicate', { id: modele.id });
        }
    }

    function eraseAllFilters() {
        loadModeles();
    }

    async function exporter(modeImpression) {
        try {
            const filters = PaginationService.getFilters(previousTableState);
            const sorts = PaginationService.getSorts(previousTableState);
            const fileName = 'Liste_modeles';
            let resultat = undefined;
            try {
                resultat = await ModelesSaisiesService.exportListeModeles(getDomaineCourant(), modeImpression, filters, sorts);
            } catch (ex) {
                //si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
                const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
                notification.error('MODELES_SAISIES.' + msgException);
                return false;
            }
            if (resultat) {
                const data = resultat.data;
                const status = resultat.status;
                let headers = resultat.headers;
                headers = headers();

                const contentType = headers['content-type'];

                const linkElement = document.createElement('a');
                try {
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    if (modeImpression == 0) {
                        linkElement.setAttribute('download', fileName + '.pdf');
                    } else {
                        linkElement.setAttribute('download', fileName + '.xlsx');
                    }

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                } catch (ex) {
                } finally {
                }
                return true;
            }
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
