import StockPreclotureMoisPopupController from './stock.precloture.mois.popup.controller';

export default {
    bindings: {
        modalInstance: '=',
        resolve: '<'
    },
    template: require('./stock.precloture.mois.popup.html'),
    controller: StockPreclotureMoisPopupController
};
