export default function Configure($stateProvider) {
    $stateProvider.state('pays', {
        parent: 'common',
        url: '/pays',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('pays.list', {
        url: '/list',
        template: '<pays></pays>',
        rights: { domain: 'pays', right: 'read' },
        ncyBreadcrumb: {
            parent: 'pays',
            label: '{{ "PAYS.BREADCRUMBS.PAYS_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'PAYS.BREADCRUMBS.PAYS_LIST',
            order: 71,
            navigationCls: 'menu-item-separator'
        },
        search: 'PAYS.BREADCRUMBS.PAYS_LIST'
    });

    $stateProvider.state('pays.new', {
        url: '/new',
        template: '<pays-form></pays-form>',
        rights: { domain: 'pays', right: 'create' },
        ncyBreadcrumb: {
            parent: 'pays.list',
            label: '{{ "PAYS.BREADCRUMBS.PAYS_NEW" | translate}}'
        },
        search: 'PAYS.BREADCRUMBS.PAYS_NEW'
    });

    $stateProvider.state('pays.edit', {
        url: '/{id}/edit',
        template: '<pays-form></pays-form>',
        rights: { domain: 'pays', right: 'update' },
        ncyBreadcrumb: {
            parent: 'pays.list',
            label: '{{ "PAYS.BREADCRUMBS.PAYS_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
