import TachesLieesController from './taches.liees.controller';

export default {
    bindings: {
        id: '=',
        domaine: '=',
        theme: '='
    },
    template: require('./taches.liees.html'),
    controller: TachesLieesController
};
