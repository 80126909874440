NormeDetailSpecificationsController.$inject = [];

export default function NormeDetailSpecificationsController() {
    const vm = this;

    // Propriétés
    vm.loading = true;
    vm.displayMesures = [];

    // Méthodes

    // Evènements
    vm.$onInit = onInit;

    // Méthodes publiques
    function onInit() {
        vm.loading = false;
        vm.categories = vm.categories || [];
        vm.specification = vm.specification || {};
        vm.norme = vm.norme || {};
        vm.domaine = vm.domaine || {};
        vm.isSubTable = false;
    }
}
