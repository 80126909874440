export default class GestionStocksAjoutProduitController {
    static $inject = ['$stateParams', 'StocksCommunicationService', 'StocksService', 'notification', 'ModalService', '$uibModal'];

    constructor($stateParams, StocksCommunicationService, StocksService, notification, ModalService, $uibModal) {
        this.$stateParams = $stateParams;
        this.StocksCommunicationService = StocksCommunicationService;
        this.StocksService = StocksService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.getZonesStockage();
    }

    async getZonesStockage() {
        try {
            this.emplacements = await this.StocksService.getZonesStockage(this.idSite);
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    annuler() {
        this.modalInstance.close(false);
    }

    async confirmer() {
        if (this.produitSelected && this.emplacement) {
            const response = {
                idProduit: this.produitSelected.id,
                idEmplacement: this.emplacement.id,
                emplacement: this.emplacement.libelle
            };
            this.modalInstance.close(response);
        } else {
            this.modalInstance.close(false);
        }
    }
}
