import { IAugmentedJQuery, IScope } from 'angular';
import { Directive, Input, OnInit } from '../../../core/decorators';

@Directive({ selector: '[massiaVirtualScroll]' })
export class MassiaVirtualScroll implements OnInit {
    @Input('=') massiaVirtualScroll: any;
    @Input('@') limitTo: string;

    /* @ngInject */
    constructor(public $scope: IScope, public $element: IAugmentedJQuery) {}

    ngOnInit(): void {
        var nbr = parseInt(this.limitTo);
        var elem = document.getElementById('app-main');
        var w = this.$element[0];
        $(w).on('wheel', () => {
            this.$scope.$apply(() => {
                var i = elem.clientHeight - w.clientHeight + w.offsetTop + elem.offsetTop + 100;
                var j = i + window.scrollY;
                if (j >= w.scrollHeight) {
                    this.massiaVirtualScroll = this.massiaVirtualScroll + nbr;
                }
            });
        });
    }
}
