import ApiService from '../../app/services/api.service';
import { Injectable } from '../decorators';
import { BaseHttpService } from '../interface/MassiaHttpService.interface';
import { BetConstituant } from '../models/bet-constituants/BetConstituant';
import { BetConstituantDetail } from '../models/bet-constituants/BetConstituantDetail';

@Injectable('BetConstituantService')
export class BetConstituantService extends BaseHttpService<BetConstituant, BetConstituantDetail, BetConstituantDetail, BetConstituantDetail, any> {
    /* @ngInject */
    constructor(ApiService: ApiService) {
        super(ApiService);
        this.url = 'v2/massia/bet-constituants';
    }
}
