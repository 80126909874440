import ProduitFormSocieteProduitController from './produit.form.societe.produit.controller';

export default {
    bindings: {
        produit: '=',
        ongletOpen: '='
    },
    template: require('./produit.form.societe.produit.html'),
    controller: ProduitFormSocieteProduitController
};
