const serviceUrl = `${__configuration.apiUrl}/massia/calculs-statistiques`;
const serviceUrlList = `${__configuration.apiUrl}/massia/calculs-statistiques-list`;

export default class CalculsStatistiquesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getCalculsStatistiques(filters, sorts, pagination, application) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {}),
                application: JSON.stringify(application)
            }
        });

        return res.data;
    }

    async getCalculsStatistiquesSelect() {
        //utilisé dans les références
        const res = await this.$http.get(serviceUrlList);
        return res.data;
    }

    async deleteCalculStatistiqueById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getCalculStatistiqueById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getCalculStatistiqueValeur() {
        const url = `${serviceUrl}/valeur`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createCalculStatistique(calcstat) {
        calcstat.calculActuMin = calcstat.minMax == 1;
        const result = await this.$http.post(serviceUrl, calcstat);
        return result.data;
    }

    async updateCalculStatistique(calcstat) {
        calcstat.calculActuMin = calcstat.minMax == 1;
        const url = `${serviceUrl}/${calcstat.id}`;
        return await this.$http.put(url, calcstat);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }
}
