export default function Configure($stateProvider) {
    $stateProvider.state('normes', {
        parent: 'common',
        url: '/normes',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('normes.list', {
        url: '/list',
        template: '<normes></normes>',
        rights: { domain: 'normes', right: 'read', application: 'gestion' },
        ncyBreadcrumb: {
            parent: 'normes',
            label: '{{ "NORMES.BREADCRUMBS.NORMES_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'NORMES.BREADCRUMBS.NORMES_LIST',
            order: 61,
            navigationCls: 'menu-item-separator'
        },
        search: 'NORMES.BREADCRUMBS.NORMES_LIST'
    });

    $stateProvider.state('normes.list.detail', {
        url: '/{id}',
        template: '<norme-detail></norme-detail>',
        rights: { domain: 'normes', right: 'read' },
        ncyBreadcrumb: {
            parent: 'normes.list',
            label: '{{ "NORMES.BREADCRUMBS.NORME_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('normes.new', {
        url: '/new',
        template: '<norme-form></norme-form>',
        rights: { domain: 'normes', right: 'create' },
        ncyBreadcrumb: {
            parent: 'normes.list',
            label: '{{ "NORMES.BREADCRUMBS.NORME_NEW" | translate}}'
        },
        search: 'NORMES.BREADCRUMBS.NORME_NEW'
    });

    $stateProvider.state('normes.edit', {
        url: '/{id}/edit',
        template: '<norme-form></norme-form>',
        rights: { domain: 'normes', right: 'update' },
        ncyBreadcrumb: {
            parent: 'normes.list',
            label: '{{ "NORMES.BREADCRUMBS.NORME_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
