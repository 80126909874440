export default class PortailStatsCardController {
    constructor($state) {
        this.$state = $state;
    }
    $onInit() {
        console.log(this.stats);
    }

    $onDestroy() {}

    goTo(stats) {
        if (stats.link) {
            this.$state.go(stats.link);
        }
    }
}

PortailStatsCardController.$inject = ['$state'];
