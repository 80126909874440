import ImportFtpModalController from './import.ftp.modal.controller';

const moduleName = 'app.massia.importftpreference';

export default {
    template: require('./import.ftp.modal.html'),
    controller: ImportFtpModalController,
    bindings: {
        modalInstance: '=',
        selectedFtp: '='
    }
};
