import validatorBuilder from 'validator-builder';

let societeCoordonneesValidator = null;

export default class SocieteCoordonnees {
    constructor(data = {}) {
        this.id = data.id;
        this.nomBanque = data.nomBanque;
        this.banqueId = data.idBanque;
        this.codeBanque = data.codeBanque;
        this.guichet = data.guichet;
        this.compte = data.compte;
        this.rib = data.rib;
        this.iban = data.iban;
        this.isDefaut = data.isDefaut;
        this.societeId = data.idSociete;
    }

    isValid() {
        societeCoordonneesValidator = societeCoordonneesValidator || validatorBuilder.getInstanceFor('SocieteCoordonneesBancaires');
        const validationResults = societeCoordonneesValidator.validate(this);
        return validationResults.isValid;
    }
}
