import Devise from './devise.model';
import DeviseEntete from '../devise-entete/devise.entete.model';

export default class DeviseFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'DevisesCommunicationService',
        'DevisesService',
        'notification',
        '$location',
        '$anchorScroll',
        'MOPService'
    ];

    constructor($scope, $state, $stateParams, DevisesCommunicationService, DevisesService, notification, $location, $anchorScroll, MOPService) {
        this.$scope = $scope;
        this.$state = $state;
        this.params = $stateParams;
        this.DevisesCommunicationService = DevisesCommunicationService;
        this.DevisesService = DevisesService;
        this.notification = notification;
        this.isEditMode = false;
        this.devise = {};
        this.loading = false;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.MOPService = MOPService;

        this.closeAllOnglets();
    }

    $onInit() {
        this.MOPService.setMop([{ title: 'DEVISES.BREADCRUMBS.DEVISES_LIST', filename: 'UnitesEtDevises.pdf', application: 'massia' }]);
        this.openFirstOnglet();
        this.setDevise(this.params.id);
    }

    $onDestroy = () => {
        this.MOPService.resetMop();
    };

    async setDevise(deviseId) {
        if (deviseId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                const entete = await this.DevisesService.getDeviseEnteteById(deviseId);
                this.devise.entete = new DeviseEntete(entete);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('devises.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.devise = new Devise();
        }
    }

    confirmer() {
        if (this.checkValidity()) {
            if (this.isEditMode) {
                this.updateDevise();
            } else {
                this.createDevise();
            }
        }
    }

    openFirstOnglet() {
        //par défaut, on ouvert l'onglet "Entête"
        this.ongletOpen = {
            isEnteteOpen: true
        };
    }

    closeAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: false
        };
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('devises.list');
    }

    reinit() {
        this.openFirstOnglet();
        this.setDevise(this.params.id);
        this.DevisesCommunicationService.raiseReinit();
    }

    checkValidity() {
        this.closeAllOnglets();
        let validity = true;

        //on va seulement scroller et focus sur le premier onglet où il y a une erreur
        let firstScroll = true;

        if (!this.devise.entete.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('entete');
                firstScroll = false;
            }

            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.$scope.$broadcast('deviseEnteteValidations');
        }

        return validity;
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    async createDevise() {
        this.startLoading();
        try {
            await this.DevisesService.createDevise(this.devise.entete);
            this.notification.success('DEVISES.CREATED');
            this.backToPreviousState();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateDevise() {
        this.startLoading();
        try {
            await this.DevisesService.updateDevise(this.devise.entete);
            this.notification.success('DEVISES.UPDATED');
            this.backToPreviousState();
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
