(function () {
    'use strict';

    /* Exemple d'utilisation
     * ac-truncate-auto='item.title'
     * ac-truncate-auto-max='90'
     */

    angular.module('blocks.helpers')
        .directive('acTruncateAuto', acTruncateAuto);
    acTruncateAuto.$inject = ['$window', 'domHelper', '$parse', '$timeout'];

    function acTruncateAuto($window, domHelper, $parse, $timeout) {
        var w = angular.element($window);

        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var originalText = $parse(attrs.acTruncateAuto)(scope);
                var truncateMax = parseInt(attrs.acTruncateAutoMax || 9999);
                var unregisterDestroyEvent = scope.$on('$destroy', dispose);

                w.bind('resize', resize);

                resize();

                function resize() {
                    var elementWidth = element[0].clientWidth;
                    var fontSizeValue = parseInt(getComputedStyle(element[0]).getPropertyValue('font-size')) || 14;
                    var truncateValue = elementWidth / (fontSizeValue / 1.75);
                    // on truncate le texte
                    var truncated = domHelper.truncateEllipsis(originalText, Math.min(truncateValue, truncateMax));
                    $timeout(function() {
                        element.html(truncated);
                    });
                }

                function dispose() {
                    if (unregisterDestroyEvent !== undefined) {
                        unregisterDestroyEvent();
                    }
                    w.unbind('resize', resize);
                }
            }
        };
    }
})();