(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.dragdrop')
        .factory('acDragDropService', acDragDropService);
    acDragDropService.$inject = [];

    /* @ngInject */
    function acDragDropService() {
        var draggedObject;
        var parentObject;
        var dragEndFunction = angular.noop;

        var service = {
            getDraggedObject: getDraggedObject,
            setDraggedObject: setDraggedObject,
            unsetDraggedObject: unsetDraggedObject,

            getParentObject: getParentObject,
            setParentObject: setParentObject,
            unsetParentObject: unsetParentObject,

            setDragEndFunction: setDragEndFunction,
            unsetDragEndFunction: unsetDragEndFunction
        };

        return service;

        function getDraggedObject() {
            return draggedObject;
        }

        function setDraggedObject(object) {
            draggedObject = object;
        }

        function unsetDraggedObject() {
            if (angular.isFunction(dragEndFunction)) {
                dragEndFunction(draggedObject, parentObject);
                unsetDragEndFunction();
            }

            unsetParentObject();

            draggedObject = undefined;
        }

        function getParentObject() {
            return parentObject;
        }

        function setParentObject(object) {
            parentObject = object;
        }

        function unsetParentObject() {
            parentObject = undefined;
        }

        function setDragEndFunction(func) {
            if (angular.isFunction(func)) {
                dragEndFunction = func;
            }
        }

        function unsetDragEndFunction() {
            dragEndFunction = angular.noop;
        }
    }
})(angular);