import validatorBuilder from 'validator-builder';

let serieTamisValidator = null;

export default class SerieTamis {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.idType = data.typeId || null;
        this.idUnite = data.idUnite || null;
        this.codeExists = data.codeExists || null;
        this.tamis = data.tamis || [];
        this.caracteristiques = data.caracteristiques || [];
    }

    isValid() {
        serieTamisValidator = serieTamisValidator || validatorBuilder.getInstanceFor('SerieTamis');
        const validationResults = serieTamisValidator.validate(this);
        return validationResults.isValid;
    }
}
