export default function Configure($stateProvider) {
    $stateProvider.state('etat-reglements', {
        parent: 'gestion',
        url: '/etat-reglements',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
        }
    });

    $stateProvider.state('etat-reglements.list', {
        url: '/list',
        template: '<etat-reglements></etat-reglements>',
        rights: { domain: 'factreglement', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "ETAT_REGLEMENTS.BREADCRUMBS.ETAT_REGLEMENTS_LIST" | translate}}'
        },
        // on affiche la liste des grilles de zones kilométriques dans la navigation
        navigation: {
            menu: 'facturation',
            translate: 'ETAT_REGLEMENTS.BREADCRUMBS.ETAT_REGLEMENTS_LIST',
            order: 4,
            navigationCls: 'menu-item-separator'
        },
        // la liste des grilles de zones kilométriques fait partie des points d'entrée recherchables dans la navigation
        search: 'ETAT_REGLEMENTS.BREADCRUMBS.ETAT_REGLEMENTS_LIST'
    });
}

Configure.$inject = ['$stateProvider'];
