const serviceUrl = `${__configuration.apiUrl}/massia/distanciers`;

export default class DistanciersService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getZones() {
        const url = `${__configuration.apiUrl}/massia/zones/header`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getDistancier(isChantier, lieuDeparts, lieuArrivee) {
        const filtres = {
            isChantier: isChantier,
            lieuDepartIds: lieuDeparts,
            lieuArriveeIds: lieuArrivee
        };
        const res = await this.$http.get(serviceUrl, {
            params: {
                filtres: JSON.stringify(filtres || {})
            }
        });

        return res.data;
    }

    async setDistancier(data) {
        const result = await this.$http.post(serviceUrl, data);
        return result.data;
    }

    async getLieux(valeur, idClient) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/lieux/typeAhead/${valeur}/${idClient}`);
        return res.data;
    }

    async getLieuxBySiteCommercial(valeur) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/lieux/typeAheadCommercial/${valeur}`);
        return res.data;
    }

    async getChantiers(valeur) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/chantiers/headers/${valeur}`);
        return res.data;
    }

    async exportListeDistanciers(isChantier, lieuDeparts, lieuArrivees) {
        const filtres = {
            isChantier: isChantier,
            lieuDeparts: lieuDeparts,
            lieuArrivees: lieuArrivees
        };

        const url = `${serviceUrl}/print`;
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {})
            },
            responseType: 'arraybuffer'
        });
        return res;
    }
}
