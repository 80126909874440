import validatorBuilder from 'validator-builder';

let badgeValidator = null;

export default class Badge {
    constructor(data) {
        data = data || {};
        this.id = data.id || undefined;
        this.code = data.code || undefined;
        this.referenceClient = data.referenceClient || undefined;
        this.dateTare = data.dateTare || undefined;
        this.hasImpressionTicket = data.hasImpressionTicket || undefined;
        this.hasPlanificationObligatoire = data.hasPlanificationObligatoire || undefined;
        this.hasImpressionTicketChargement = data.hasImpressionTicketChargement || undefined;

        if (data.typeEntreeSortie == undefined) {
            this.typeEntreeSortie = 1;
        } else {
            this.typeEntreeSortie = data.typeEntreeSortie;
        }
        if (data.typeTare == undefined) {
            this.typeTare = 2;
        } else {
            this.typeTare = data.typeTare;
        }
        if (data.typeAchatVente == undefined) {
            this.typeAchatVente = 1;
        } else {
            this.typeAchatVente = data.typeAchatVente;
        }
        this.idSiteClient = data.idSiteClient || undefined;
        this.nomSiteClient = data.nomSiteClient || '';
        this.idLieu = data.idLieu || undefined;
        this.nomLieu = data.nomLieu || '';
        this.codeLieu = data.codeLieu || '';
        this.idSiteTransporteur = data.idSiteTransporteur || undefined;
        this.nomSiteTransporteur = data.nomSiteTransporteur || '';
        this.idSociete = data.idSociete || undefined;
        this.nomSociete = data.nomSociete || '';
        this.idTypeBenne = data.idTypeBenne || undefined;
        this.nomTypeBenne = data.nomTypeBenne || '';
        this.idCommande = data.idCommande || undefined;
        this.nomCommande = data.nomCommande || '';
        this.idChauffeur = data.idChauffeur || undefined;
        this.nomChauffeur = data.nomChauffeur || '';
        this.idVehicule = data.idVehicule || undefined;
        this.immatsVehicule = data.immatsVehicule || '';
        this.chantiers = data.chantiers || [];
        this.chantiersIds = data.chantiersIds || [];
        this.sitesCommerciaux = data.sitesCommerciaux || [];
        this.sitesCommerciauxIds = data.sitesCommerciauxIds || [];
        this.produits = data.produits || [];
        this.quantiteTicketChargement = data.quantiteTicketChargement || undefined;
        this.isActif = data.isActif;
    }

    isValid() {
        badgeValidator = badgeValidator || validatorBuilder.getInstanceFor('Badge');
        const validationResults = badgeValidator.validate(this);
        return validationResults.isValid;
    }
}
