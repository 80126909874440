import CritereValeur from './criteres.valeur.models';

export class Critere {
    constructor(data) {
        this.id = data.id || -1;
        this.libelle = this.id === -1 ? data.searchProperty : data.libelle || data.searchProperty;
        this.idModele = data.idModele;
        this.donneeSelectionable = data.donneeSelectionable;
        this.idDonneeSelectionable = data.idDonneeSelectionable || data.donneeSelectionable.id;
        this.idCaracteristique = data.idCaracteristique && data.idCaracteristique > -1 ? data.idCaracteristique : null;
        this.champComposition = data.champ === 17 && data.champComposition > -1 ? data.champComposition : null;
        this.champ = data.champ;
        this.isObligatoire = data.isObligatoire !== null && typeof data.isObligatoire !== 'undefined' ? data.isObligatoire : false;
        this.isUnique = data.isUnique !== null && typeof data.isUnique !== 'undefined' ? data.isUnique : false;
        this.ordre = data.ordre || 0;
        this.typeCritere = data.typeCritere;
        this.label = data.searchProperty;
        this.domainCode = data.domainCode;
        this.idNiveauHierarchique = data.idNiveauHierarchique || null;
        const valeurs = data.modelSelectionCritereValeur || [];
        this.modelSelectionCritereValeur = valeurs.map((x) => new CritereValeur(x));
    }
}
