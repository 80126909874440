import validatorBuilder from 'validator-builder';

let lieuEnteteValidator = null;

export default class LieuEntete {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        (this.adresse = data.adresse), (this.codePostal = data.codePostal);
        this.ville = data.ville;
        this.typeId = data.typeId;
        // si besoin, on indique une valeur par défaut
        // le (xxx || yyy) va être égal à yyy si xxx est undefined, null, false ou égal à 0
        this.codeExists = data.codeExists || null;
        //todo codeInvalid non utilise actuellement, a confirmer
        // this.codeInvalid = data.codeInvalid || null;
        this.estCodeAuto = data.estCodeAuto;
        this.niveauVisibilite = 0;
    }

    isValid() {
        lieuEnteteValidator = lieuEnteteValidator || validatorBuilder.getInstanceFor('LieuEntete');
        const validationResults = lieuEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
