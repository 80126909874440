import _ from 'lodash';
import MassiaApplicationService from '../../app/massia/layout/components/massia-application/massia.application.service';
import { ValidatorHelper } from '../helpers/validator-helper';
import { CreateUpdateProduit, PrixProduit, Coefficientk } from '../models/produit/UpdateProduit';
import { Type } from '../models/type/Type';

ProductValidator.$inject = ['validator', 'MassiaApplicationService'];
export function ProductValidator(validator: any, MassiaApplicationService: MassiaApplicationService) {
    const app = MassiaApplicationService.getApplication();
    const instance = new validator();
    instance.ruleFor('code').must(beCodeNotEmpty).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').must(beCodeGoodLength).withMessage('VALIDATION_TOO_LONG');
    instance.ruleFor('code').must(beCodeGoodStructure).withMessage('CODE_CHARACTERS_NOK');

    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').length(1, 200).withMessage('VALIDATION_TOO_LONG');
    //instance.ruleFor('')

    instance.ruleFor('type').must(beSetted).withMessage('VALIDATION_NOTEMPTY');

    instance.ruleFor('producteurs').must(beNotEmpty).withMessage('PRODUITS.NO_PRODUCTEUR');
    if (app === 'laboratoire') {
        instance.ruleFor('societeLaboratoire').must(beNotEmpty).withMessage('PRODUITS.NO_LABO_SOCIETE');

        if (__configuration.licenceLaboratoire === '2') {
            instance.ruleFor('prix').must(beSetForAllProducteurClient).withMessage('PRODUITS.PRODUIT_SITE_CLIENT.ERREUR.PRICE_MISSING');
        }

        instance.ruleFor('coefficientK').must(beEachValid).withMessage('PRODUITS.COEFFICIENTK.NON_VALID');
        instance.ruleFor('coefficientK').must(beUniq).withMessage('PRODUITS.COEFFICIENTK.UNIQUE');
    }

    function beEachValid(obj: CreateUpdateProduit, element: Coefficientk[]) {
        let isValid: boolean = true;
        _.forEach(element, (el) => {
            if (!ValidatorHelper.isValid(el, 'ProduitCoefficientk')) {
                isValid = false;
                return;
            }
        });
        return isValid;
    }

    function beUniq(obj: CreateUpdateProduit, element: Coefficientk[]) {
        const l = element.length;
        // @ts-ignore
        const l2 = _.uniqWith(
            element,
            (el1, el2) =>
                el1.normeSpecifique === el2.normeSpecifique &&
                el1.date === el2.date &&
                el1.producteur === el2.producteur &&
                el1.client === el2.client &&
                el1.typeCiment === el2.typeCiment &&
                el1.classeResistanceCiment === el2.classeResistanceCiment
        ).length;
        return l >= l2;
    }

    function beSetted(obj: CreateUpdateProduit, element: Type) {
        return element.id > 0;
    }

    function beNotEmpty(obj: CreateUpdateProduit, elements: any[] = []) {
        return elements?.length > 0;
    }

    function beSetForAllProducteurClient(obj: CreateUpdateProduit, price: PrixProduit[]) {
        let isSet = true;
        if (obj.type?.famille?.code !== 'BPE' && obj.type?.code !== 'GRADCURV') {
            _.forEach(obj.siteLinked, ({ producteur, clients }) => {
                _.forEach(clients, (client) => {
                    const prix = price?.find(
                        (x) => x.producteur.id === producteur.id && x.client.id === client.id && ((x.prix && x.prix.length > 0) || x.prix === 0)
                    );
                    if (!prix) {
                        isSet = false;
                    }
                    return isSet; // lodash return false is equivalent of break
                });
                return isSet; // lodash return false is equivalent break
            });
        }
        return isSet;
    }

    function beCodeNotEmpty(obj: CreateUpdateProduit) {
        if (obj.isCodeAutomatique) {
            return true;
        }
        return obj.code && obj.code.length > 0;
    }

    function beCodeGoodLength(obj: CreateUpdateProduit) {
        if (obj.isCodeAutomatique) {
            return true;
        }
        return obj.code.length >= 1 && obj.code.length <= 20;
    }

    function beCodeGoodStructure(obj: CreateUpdateProduit) {
        if (obj.isCodeAutomatique) {
            return true;
        }
        return obj.code && obj.code.length > 0 && obj.code.match(/^[a-zA-Z0-9_|]*$/);
    }

    return instance;
}
