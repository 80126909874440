TemplateImpressionValidator.$inject = ['validator', 'validationHelper'];

export default function TemplateImpressionValidator(validator, validationHelper) {
    const instance = new validator();
    instance.ruleFor('Libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('Extension').must(BeAValidExtension).withMessage('VALIDATION_FORMAT_INCORRECT');
    instance.ruleFor('IdDomaine').notEmpty().withMessage('VALIDATION_NOEMPTY');

    function BeAValidExtension(file) {
        const extArray = ['docx', 'xlsx', 'DOCX', 'XLSX', '.docx', '.xlsx', '.DOCX', '.XLSX', '.xlsm', 'xlsm', 'XLSM', '.XLSM'];
        if (extArray.includes(file.Extension)) {
            return true;
        }
        return false;
    }

    return instance;
}
