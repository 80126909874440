export default class WithDateController {
	static $inject = ['$state', '$stateParams', 'CentreGestionService', 'ModalService', 'notification', '$translate', '$filter'];

	constructor($state, $stateParams, CentreGestionService, ModalService, notification, $translate, $filter) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.CentreGestionService = CentreGestionService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.$translate = $translate;
		this.$filter = $filter;
		this.eligible = [
			'compteTiers',
			'modesPaiement',
			'compteAnalytiqueVenteAchat',
			'compteVenteAchat',
			'compteComplVenteAchat',
			'compteStock',
			'compteConsommation',
			'modePaiement',
			'protocoleSecurite'
		];
	}

	$onInit() {
		this.codeParam = this.parametre.codeParametre;
		if (this.eligibleWithDate(this.codeParam)) {
			this.parametre = this.parametre;
			this.entreeSortie = this.parametre.entreeSortie;
			this.achatVente = this.parametre.achatVente;
			this.oldDateApplication = [this.parametre.donnees[0].valeurs[0].dateApplication, this.parametre.donnees[1].valeurs[0].dateApplication];
			this.changedValue = [false, false];
			this.tmpValeurs = [];
		}

		if (
			this.codeParam == 'compteComplVenteAchat' &&
			this.source &&
			this.source.checkPont &&
			this.source.checkPont.dateApplicationCompteAV == null
		) {
			this.parametre.donnees[1].valeurs[0].dateApplication = this.dateApplication;
		}

		if (this.codeParam == 'compteVenteAchat' && this.source && this.source.checkPont && this.source.checkPont.dateApplicationCompteAV == null) {
			this.parametre.donnees[1].valeurs[0].dateApplication = this.dateApplication;
		}

		if (
			this.codeParam == 'compteAnalytiqueVenteAchat' &&
			this.source &&
			this.source.checkPont &&
			this.source.checkPont.dateApplicationCompteAnalytiqueAV == null
		) {
			this.parametre.donnees[1].valeurs[0].dateApplication = this.dateApplication;
		}

		// cas particulier du protocole de sécurité
		// s'il y a une date, c'est que c'est applicable
		if (this.codeParam == 'protocoleSecurite') {
			if (this.parametre.donnees[0].valeurs[0].listeHistoriqueParDate.length > 0) {
				this.parametre.donnees[0].valeurs[0].valeur = true;
			}
			if (this.parametre.donnees[1].valeurs[0].listeHistoriqueParDate.length > 0) {
				this.parametre.donnees[1].valeurs[0].valeur = true;
			}
		}
	}

	isPontComptable() {
		if (
			this.codeParam == 'compteStock' ||
			this.codeParam == 'compteConsommation' ||
			this.codeParam == 'compteAnalytiqueVenteAchat' ||
			this.codeParam == 'compteVenteAchat' ||
			this.codeParam == 'compteComplVenteAchat'
		) {
			return true;
		}
		return false;
	}

	isProtocoleSecurite() {
		if (this.codeParam == 'protocoleSecurite') {
			return true;
		}
		return false;
	}

	eligibleWithDate(codeParametre) {
		return this.eligible.indexOf(codeParametre) !== -1 ? true : false;
	}

	getHeritage(parent) {
		if (parent == null) {
			return '<span class="text-success">' + this.$translate.instant('CENTRE_GESTION.VAL_PROPRE') + '</span>';
		} else if (parent.idParent != -1 && parent.domaine != null) {
			return '<span class="text-info">' + this.$translate.instant('CENTRE_GESTION.HERITAGE', { libelle: parent.parentLibelle }) + '</span>';
		} else if (parent.domaine == 'defaultValue') {
			return '<span>' + this.$translate.instant('CENTRE_GESTION.DEFAULTVALUE') + '</span>';
		}

		this.notification.error("Une erreur d'héritage a été détectée.");
	}

	async reinit(objetChamps) {
		try {
			await this.CentreGestionService.effacer(objetChamps.id);
			this.onDelete();
			// this.parametre.donnees[objetChamps.entreeSortie || objetChamps.achatVente].valeurs[0] = retour.valeurs[0];
			// this.parametre.donnees[objetChamps.entreeSortie || objetChamps.achatVente].parent = retour.parent;
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}

	isDisabled(es) {
		if (this.parametre.donnees[es].valeurs.length > 0) {
			if (
				typeof this.parametre.donnees[es].valeurs[0].dateApplication === 'undefined' ||
				this.parametre.donnees[es].valeurs[0].dateApplication === '' ||
				this.parametre.donnees[es].valeurs[0].dateApplication === null
			) {
				return true;
			}
			return false;
		}
	}
	async upsertElement(valeur, objet, av) {
		if (typeof objet.valeurs[0].dateApplication !== 'undefined' && objet.valeurs[0].dateApplication != null) {
			const element = angular.copy(objet.valeurs[0]);
			const parent = angular.copy(objet.parent);

			// cas ou on sélectionne "Sélectionner", avoir le même comportement que la gomme
			if (objet.valeurs[0].valeur == 0) {
				this.reinit(objet.valeurs[0]);
			} else {
				if (this.frontFilters) {
					if (this.filterToBack) {
						const entries = Object.entries(this.filterToBack);
						for (let i = 0; i < entries.length; i++) {
							element[entries[i][0]] = entries[i][1];
						}
					}
					// SL pas besoin de modifier la date. l'algo a l'air de faire le job
					element.dateApplication = objet.valeurs[0].dateApplication;
					element.achatVente = av;
					element.codeDomaine = this.domaine;
				}
				try {
					if (parent == null) {
						if (this.oldDateApplication[av] == element.dateApplication) {
							await this.CentreGestionService.update(element);
						} else {
							var nouvelId = await this.CentreGestionService.insert(element);
							objet.valeurs[0].id = nouvelId;
							objet.parent = null;
							this.oldDateApplication[element.achatVente || element.entreeSortie] = element.dateApplication;
							this.tmpValeurs[av] = angular.copy(element);
						}
					} else {
						var nouvelId = await this.CentreGestionService.insert(element);
						// SL pour la maj des données de l'objet,
						// il ne faut pas passer par la référence, mais par l'objet directement
						objet.valeurs[0].id = nouvelId;
						objet.parent = null;
						this.oldDateApplication[element.achatVente || element.entreeSortie] = element.dateApplication;
						this.tmpValeurs[av] = angular.copy(element);
					}
				} catch (err) {
					if (err.data) {
						this.notification.error(err.data);
					} else {
						throw err;
					}
				} finally {
					this.changedValue[av] = true;
					// this.activeTab = groupIndex;
				}
			}
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}
}
