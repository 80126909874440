import { Pipe } from '../../../core/decorators';
import { PipeTransform } from '../../../core/decorators/pipe';

@Pipe({
    name: 'conditional'
})
export class Conditional implements PipeTransform {
    /* @ngInject */
    constructor() {}

    transform(array: any[], search: any, filterProps: any) {
        const keys = filterProps.split(' ');
        const loweredSearch = search.toLowerCase();
        return array.filter((x) => {
            let keep = false;
            for (const key of keys) {
                const value = '' + x[key];
                const lowered = value.toLowerCase();
                if (lowered.includes(loweredSearch)) {
                    keep = true;
                    break;
                }
            }
            return keep;
        });
    }
}
