import moment from 'moment';
import _ from 'lodash';
import Prelevement from '../../../app/common/prelevements/components/prelevement/prelevement.model';
import { Injectable } from '../../../core/decorators';
@Injectable('Interpreteur')
export class Interpreteur {
    /* @ngInject */
    constructor(PrelevementsService, SitesService, globalizationManagementService, FormulesService) {
        this.PrelevementsService = PrelevementsService;
        this.SitesService = SitesService;
        this.FormulesService = FormulesService;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    init(obj, valeursRef) {
        this.obj = obj;
        this.valeursRef = valeursRef;
        this.formatCaract = undefined; //pour caractéristiques

        this.calculatedFormules = [];
        this.borne = null; //pour les seuils et autres champs venant des normes et références
        this.listeValeursStr = undefined;
        this.forceIter = undefined; //pour essais génériques/itératifs
        this.essaiAppel = undefined; //pour essais génériques/itératifs
        this.conditionVALM = undefined;
    }

    async calculeFormule(formule, recursion = false, idNatureOrigine = -1) {
        if (formule == null) return '';
        if (
            formule == '=#VIDE' ||
            formule == '#VIDE=' ||
            formule == '=#NULL' ||
            formule == '#NULL=' ||
            formule == 'null=#VIDE' ||
            formule == '#VIDE=null' ||
            formule == 'null=#NULL' ||
            formule == '#NULL=null' ||
            formule == 'undefined=#VIDE' ||
            formule == '#VIDE=undefined' ||
            formule == 'undefined=#NULL' ||
            formule == '#NULL=undefined' ||
            formule == '()'
        )
            return true;
        var resultatFormule = '';
        let ignoreDejaCalcule = formule.includes('@ITER') || formule.includes('@ESSAPPEL'); /*|| this.forceIter != undefined*/ //enlevé SP 04/03/21 sinon recalcule la moyenne pour toutes les itérations (Calcys)
        var idxCalculated = _.findIndex(this.calculatedFormules, { formule: formule });
        if (idxCalculated > -1 && !ignoreDejaCalcule) resultatFormule = this.calculatedFormules[idxCalculated].resultat;
        else {
            //if (this.borne)
            formule = this.traiteBornes(formule, this.borne);
            if (formule == 'IGNORE_BORNE_ABS') return '';
            if (formule.includes('@ITER') && this.forceIter != undefined) formule = formule.replace(/@ITER/g, this.forceIter);
            if (formule.includes('@ESSAPPEL') && this.essaiAppel != undefined) formule = formule.replace(/@ESSAPPEL/g, this.essaiAppel);

            // if (formule.includes('@D') && this.obj) {
            //     if (this.obj.hasOwnProperty('produit'))
            //         formule = formule.replace(/@D/g, this.obj.produit.grandD);
            //     else if (this.obj.entete)
            //         formule = formule.replace(/@D/g, this.obj.entete.grandD);
            //     else
            //         formule = formule.replace(/@D/g, this.obj.grandD);
            // }
            // if (formule.includes('@d') && this.obj) {
            //     if (this.obj.hasOwnProperty('produit'))
            //         formule = formule.replace(/@d/g, this.obj.produit.petitd);
            //     else if (this.obj.entete)
            //         formule = formule.replace(/@d/g, this.obj.entete.petitd);
            //     else
            //         formule = formule.replace(/@d/g, this.obj.petitd);
            // }

            try {
                if (formule.match(/^-\d*(\.\d*)?$/g)) {
                    //MN: si c'est un formule qui ne contient qu'un number négatif, on le stocke directement dans le résultat
                    resultatFormule = formule;
                } else resultatFormule = await this.recursion(formule.trim(), idNatureOrigine);
                this.calculatedFormules.push({ formule: formule, resultat: resultatFormule });
                // console.info(formule + ' = ' + resultatFormule);
            } catch (ex) {
                //console.info('catch formule ' + formule, ex);
            }
        }
        if (!recursion && this.isEntreGuillemets(resultatFormule)) return resultatFormule.substring(1, resultatFormule.length - 1);
        else return resultatFormule;
    }
    async calculeFormuleWithParams(formule, params, recursion = false) {
        let noMatch = true;
        const finalCompleteValues = [];
        if (params && formule.indexOf('(') >= 0) {
            const formuleTitle = formule.substring(0, formule.indexOf('('));
            const formuleValues = formule
                .substring(formule.indexOf('(') + 1, formule.indexOf(')'))
                .split(';')
                .map(function (e) {
                    return parseFloat(e);
                });

            if (params.isGroupement || params.isCumul) {
                const grpValues = params.isCumul
                    ? this.prepareCumulParams(formuleValues, params)
                    : this.prepareGroupementParams(formuleValues, params);
                if (grpValues && grpValues.length > 0) {
                    noMatch = false;
                    for (let i = 0; i < grpValues.length; i++) {
                        let val = await this.calculeFormule(formuleTitle + '(' + grpValues[i].join(';') + ')', false);
                        val = parseFloat(val) ? parseFloat(val) : 0;
                        //val = val.toFixed(2);//SP 02/06/20 : on n'arrondi pas à 2 arbitrairement, on traitera l'arrondi au retour de calcul de formule
                        finalCompleteValues.push(val);
                    }
                }
            }
        }

        if (noMatch) return await this.calculeFormule(formule, true);

        return finalCompleteValues;
    }

    prepareCumulParams(formuleValues, params) {
        let cumValues = null;
        const nbMinVal = params.nbValMinCumul || 1;
        cumValues = [];
        let values = [];
        for (let i = 0; i < formuleValues.length; i++) {
            values.push(formuleValues[i]);

            if (i + 1 >= nbMinVal /*(i > 0 && values.length % params.nbValGroupe === 0) || i === formuleValues.length - 1*/) {
                cumValues.push(angular.copy(values));

                /*if (params.isGroupementGlissant)
                    values.splice(0, 1);
                else
                    values = [];*/
            }
        }

        return cumValues;
    }

    prepareGroupementParams(formuleValues, params) {
        let grpValues = null;
        if (params.nbValGroupe) {
            grpValues = [];
            let values = [];
            for (let i = 0; i < formuleValues.length; i++) {
                values.push(formuleValues[i]);

                if ((i > 0 && values.length % params.nbValGroupe === 0) || i === formuleValues.length - 1) {
                    grpValues.push(angular.copy(values));

                    if (params.isGroupementGlissant) values.splice(0, 1);
                    else values = [];
                }
            }
        }

        return grpValues;
    }

    traiteBornes(formuleBorne, borne) {
        //if (borne) {
        if (formuleBorne.includes('@ECART')) formuleBorne = formuleBorne.replace(/@ECART/g, '@MOY()-@VTYPE');
        if (formuleBorne.includes('@VSI')) {
            if (borne && borne.borneInferieure != undefined && borne.borneInferieure != null && borne.borneInferieure != '')
                formuleBorne = formuleBorne.replace(/@VSI/g, borne.borneInferieure);
            else return 'IGNORE_BORNE_ABS';
        }
        if (formuleBorne.includes('@VSS')) {
            if (borne && borne.borneSuperieure != undefined && borne.borneSuperieure != null && borne.borneSuperieure != '')
                formuleBorne = formuleBorne.replace(/@VSS/g, borne.borneSuperieure);
            else return 'IGNORE_BORNE_ABS';
        }
        if (formuleBorne.includes('@LI')) {
            if (borne && borne.limiteInferieure != undefined && borne.limiteInferieure != null && borne.limiteInferieure != '')
                formuleBorne = formuleBorne.replace(/@LI/g, borne.limiteInferieure);
            else return 'IGNORE_BORNE_ABS';
        }
        if (formuleBorne.includes('@LS')) {
            if (borne && borne.limiteSuperieure != undefined && borne.limiteSuperieure != null && borne.limiteSuperieure != '')
                formuleBorne = formuleBorne.replace(/@LS/g, borne.limiteSuperieure);
            else return 'IGNORE_BORNE_ABS';
        }
        if (formuleBorne.includes('@UNORME')) {
            if (borne && borne.incertitudeReference != undefined && borne.incertitudeReference != null && borne.incertitudeReference != '')
                formuleBorne = formuleBorne.replace(/@UNORME/g, borne.incertitudeReference);
            else {
                if (formuleBorne === '@UNORME') return 'IGNORE_BORNE_ABS';
                else formuleBorne = formuleBorne.replace(/@UNORME/g, '0'); //return 'IGNORE_BORNE_ABS';//SP 23/10/20 si pas de U, calculer quand-même VSI-U par exemple
            }
        }
        if (formuleBorne.includes('@ENORME')) {
            if (borne && borne.etendueReference != undefined && borne.etendueReference != null && borne.etendueReference != '')
                formuleBorne = formuleBorne.replace(/@ENORME/g, borne.etendueReference);
            else return 'IGNORE_BORNE_ABS';
        }
        if (formuleBorne.includes('@VTYPE')) {
            if (borne && borne.moyenne != undefined && borne.moyenne != null && borne.moyenne != '')
                formuleBorne = formuleBorne.replace(/@VTYPE/g, borne.moyenne);
            else return 'IGNORE_BORNE_ABS';
        }
        //}
        return formuleBorne;
    }

    async recursion(formuleRecursion, idNatureOrigine) {
        //Gestion des priorités
        let priorite = 10;
        let j = 0;
        let nivmin = formuleRecursion.length + 1;
        if (idNatureOrigine === 2 || this.isDate(formuleRecursion)) priorite = 10;
        else if (this.isEntreGuillemets(formuleRecursion)) return formuleRecursion /*.substring(1, formuleRecursion.length - 1)*/;
        else {
            let niv = 1;
            for (var k = formuleRecursion.length - 1; k >= 0; k--) {
                switch (formuleRecursion[k]) {
                    case ')':
                        niv++;
                        break;
                    case '(':
                        niv--;
                        break;
                    default:
                        if (niv < nivmin) {
                            nivmin = niv;
                            priorite = 10;
                        }
                        if (niv == nivmin) {
                            let nprio;
                            switch (formuleRecursion[k]) {
                                case '<':
                                case '=':
                                case '>':
                                case '!':
                                case '{':
                                case '}':
                                    nprio = 1;
                                    break;
                                case '+':
                                case '-':
                                case '$':
                                    nprio = 2;
                                    break;
                                case '*':
                                case '/':
                                    nprio = 3;
                                    break;
                                case '^':
                                    nprio = 4;
                                    break;
                                case '@':
                                case '#':
                                    nprio = 9;
                                    break;
                                default:
                                    nprio = 10;
                                    break;
                            }
                            if (nprio < priorite) {
                                j = k;
                                priorite = nprio;
                            }
                        }
                        break;
                }
            }
        }
        if (nivmin > 1 && nivmin <= formuleRecursion.length) {
            formuleRecursion = formuleRecursion.slice(nivmin - 1);
            formuleRecursion = formuleRecursion.slice(0, formuleRecursion.length - nivmin + 1);
            j = j - nivmin + 1;
        }
        switch (priorite) {
            case 10:
                if (formuleRecursion == '') return '';
                else if (formuleRecursion == '?') {
                    throw formuleRecursion;
                } else return formuleRecursion;
            case 9:
                let Operateur = formuleRecursion[0];
                formuleRecursion = formuleRecursion.slice(1, formuleRecursion.length);
                if (Operateur == '#') {
                    if (formuleRecursion == 'PI') return Math.PI;
                    else if (formuleRecursion == 'VRAI') return true;
                    else if (formuleRecursion == 'FAUX') return false;
                    else if (formuleRecursion == 'VIDE') return '';
                    else if (formuleRecursion == 'NUL') return '';
                    //null
                    else {
                        throw formuleRecursion;
                    }
                } else if (Operateur == '@') {
                    if (formuleRecursion.indexOf('(') == -1 && this.listeValeursStr)
                        formuleRecursion = formuleRecursion + '(' + this.listeValeursStr + ')';
                    if (formuleRecursion.indexOf('()') > -1 && this.listeValeursStr)
                        formuleRecursion = formuleRecursion.replace(/\(\)/g, '(' + this.listeValeursStr + ')');
                    return await this.fonction(formuleRecursion, idNatureOrigine);
                } else {
                    throw formuleRecursion;
                }
            default:
                return await this.operation(j, formuleRecursion);
        }
    }

    async operation(j, formuleOperation) {
        var bString = false;
        var strR1 = '';
        var strR2 = '';
        var s1 = '';
        var s2 = '';
        var r1 = undefined;
        var r2 = undefined;
        var Membre = 0;
        var erreur = false;
        if (j > 1) {
            if (formuleOperation[j] == '=') {
                if (formuleOperation.indexOf('>=') > -1) {
                    formuleOperation = formuleOperation.replace(/>=/g, '}');
                    j--;
                } else if (formuleOperation.indexOf('<=') > -1) {
                    formuleOperation = formuleOperation.replace(/<=/g, '{');
                    j--;
                } else if (formuleOperation.indexOf('!=') > -1) {
                    formuleOperation = formuleOperation.replace(/!=/g, '!');
                    j--;
                }
            } else if (formuleOperation[j] == '>' && formuleOperation[j - 1] == '<') {
                j--;
                formuleOperation = formuleOperation.replace(/<>/g, '!');
            }
        }
        var Operateur = formuleOperation[j];
        //#region recherche r1 - r2
        bString = false;
        if (Membre != 2) {
            if (j <= 0) {
                r1 = undefined; //SI PREMIER MEMBRE VIDE, =0//SP 10/02/22 vide!=0
                strR1 = '';
            } else {
                try {
                    let resTmpA = await this.calculeFormule(formuleOperation.substring(0, j), true);
                    try {
                        if (resTmpA === undefined || resTmpA === null || resTmpA === '' /*|| isNaN(resTmpA)*/)
                            //SP : NON, si c'est du texte IsNaN renvoit true, c'est pas bon
                            r1 = undefined;
                        else {
                            r1 = this.strEnDouble(resTmpA); //CALCUL MEMBRE 1 (formule debut -> operateur)
                            if (isNaN(r1)) {
                                erreur = false;
                                s1 = resTmpA;
                                bString = true;
                                r1 = undefined;
                            } else strR1 = resTmpA;
                        }
                    } catch (err) {
                        erreur = false;
                        s1 = resTmpA;
                        bString = true;
                        r1 = undefined;
                    }
                } catch (err) {
                    erreur = true;
                }
            }
        }
        if (Membre != 1) {
            if (j >= formuleOperation.length) {
                if (bString) s2 = '';
                //SI 2EME MEMBRE VIDE, =0
                else {
                    r2 = undefined; //SP 10/02/22 vide!=0
                    strR2 = '';
                }
            } else if (Membre == 0 && Operateur == ';') {
                if (!erreur && !bString) {
                    try {
                        let resTmpE = await this.calculeFormule(formuleOperation.substring(j + 1, formuleOperation.length), true);
                        try {
                            if (resTmpE === undefined || resTmpE === null || resTmpE === '' /*|| isNaN(resTmpE)*/)
                                //SP : NON, si c'est du texte IsNaN renvoit true, c'est pas bon
                                r2 = undefined;
                            else {
                                r2 = this.strEnDouble(resTmpE); //CALCUL MEMBRE 2 (formule operateur -> FIN)
                                if (isNaN(r2)) {
                                    erreur = false;
                                    if (!bString) s1 = r1;
                                    bString = true;
                                    s2 = resTmpE;
                                    r2 = undefined;
                                } else strR2 = resTmpE;
                            }
                        } catch (err) {
                            erreur = false;
                            if (!bString) s1 = r1;
                            bString = true;
                            s2 = resTmpE;
                            r2 = undefined;
                        }
                    } catch (err) {
                        erreur = true;
                    }
                }
            } else if (!erreur) {
                try {
                    let resTmpF = await this.calculeFormule(formuleOperation.substring(j + 1, formuleOperation.length), true);
                    try {
                        if (resTmpF === undefined || resTmpF === null || resTmpF === '' /*|| isNaN(resTmpF)*/)
                            //SP : NON, si c'est du texte IsNaN renvoit true, c'est pas bon
                            r2 = undefined;
                        else {
                            r2 = this.strEnDouble(resTmpF);
                            if (isNaN(r2)) {
                                erreur = false;
                                if (!bString) s1 = r1.toString();
                                bString = true;
                                s2 = resTmpF;
                                r2 = undefined;
                            } else strR2 = resTmpF;
                        }
                    } catch (err) {
                        erreur = false;
                        if (!bString) s1 = r1;
                        bString = true;
                        s2 = resTmpF;
                        r2 = undefined;
                    }
                } catch (err) {
                    erreur = true;
                }
            }
        }
        //#endregion
        if (!erreur) {
            if (!bString && (r1 === undefined || r2 === undefined) && Operateur != '=' && Operateur != '!') {
                return '';
            } else
                switch (Operateur) {
                    case '+':
                        if (strR1 == '' && strR2 == '') {
                            return '';
                        } else {
                            if (bString) {
                                //traiter les guillemets
                                const guillemets = this.isEntreGuillemets(s1) || this.isEntreGuillemets(s2);
                                if (this.isEntreGuillemets(s1)) s1 = s1.substring(1, s1.length - 1);
                                if (this.isEntreGuillemets(s2)) s2 = s2.substring(1, s2.length - 1);
                                if (guillemets) {
                                    s1 = '"' + s1;
                                    s2 = s2 + '"';
                                }
                                if (!isNaN(s1) && !isNaN(s2)) {
                                    return s1.concat(s2);
                                }
                                return '';
                            } else return r1 + r2;
                        }
                    case '-':
                        if (strR1 == '' && strR2 == '') return '';
                        else return r1 - r2;
                    case '*':
                        if (strR1 == '' && strR2 == '') return '';
                        else return r1 * r2;
                    case '/':
                        if (strR1 == '' && strR2 == '') return '';
                        else if (r2 != 0) return r1 / r2;
                        else throw formuleOperation;
                    case '^':
                        if (strR1 == '' && strR2 == '') return '';
                        else return Math.exp(Math.log(r1) * r2);
                    case '<':
                        if (strR1 == '' && strR2 == '') return '';
                        else return r1 < r2;
                    case '>':
                        if (strR1 == '' && strR2 == '') return '';
                        else return r1 > r2;
                    case '=':
                    case '!':
                        if (bString) {
                            const typeS1 = typeof s1;
                            const typeS2 = typeof s2;
                            if (typeS1 == 'string' && typeS2 == 'boolean') {
                                s2 = s2.toString();
                            }
                            if (typeS2 == 'string' && typeS1 == 'boolean') {
                                s1 = s1.toString();
                            }

                            //traiter les guillemets
                            if (this.isEntreGuillemets(s1)) s1 = s1.substring(1, s1.length - 1);
                            if (this.isEntreGuillemets(s2)) s2 = s2.substring(1, s2.length - 1);
                            return (Operateur == '=') == (s1 == s2);
                        } else if (strR1 == '' && strR2 == '') {
                            return '';
                        } else {
                            return Math.abs(r1 - r2) <= Math.abs(r1 * 1e-8) == (Operateur == '=');
                        }
                    case '{':
                        if (strR1 == '' && strR2 == '') return '';
                        else return r1 <= r2;
                    case '}':
                        if (strR1 == '' && strR2 == '') return '';
                        else return r1 >= r2;
                    default:
                        throw formuleOperation;
                }
        }
        throw formuleOperation;
    }

    async fonction(formuleFonction, idNatureOrigine) {
        if (formuleFonction.indexOf('@') > -1) {
            let positionDebutParcourt = formuleFonction.indexOf('(');
            let nbErreurAffiles = 0;
            while (formuleFonction.indexOf('(', positionDebutParcourt + 1) != -1) {
                let positionDebutParamSsFormule = positionDebutParcourt;
                for (let i = 0; i < formuleFonction.length; i++) {
                    if (formuleFonction[i] == '(') positionDebutParamSsFormule = i;
                }
                if (positionDebutParamSsFormule != positionDebutParcourt) {
                    let positionDebutSsFormule = 0;
                    let positionFinSsFormule = 0;
                    for (let i = positionDebutParamSsFormule - 1; i >= 0; i--) {
                        if (formuleFonction[i] == '@') {
                            positionDebutSsFormule = i;
                            i = 0;
                        }
                        if (formuleFonction[i] == ')' || formuleFonction[i] == '(') {
                            positionDebutSsFormule = positionDebutParamSsFormule;
                            i = 0;
                        }
                    }
                    for (let i = positionDebutParamSsFormule; i < formuleFonction.length; i++) {
                        if (formuleFonction[i] == ')') {
                            positionFinSsFormule = i;
                            i = formuleFonction.length;
                        }
                    }
                    if (positionDebutSsFormule != 0 && positionFinSsFormule != 0) {
                        let ssFormule = formuleFonction.substring(
                            positionDebutSsFormule,
                            positionDebutSsFormule + (positionFinSsFormule - positionDebutSsFormule) + 1
                        );
                        let resTmp = await this.calculeFormule(ssFormule, true);
                        formuleFonction = formuleFonction.replace(ssFormule, resTmp);
                        nbErreurAffiles = 0;
                    } else {
                        nbErreurAffiles++;
                        if (nbErreurAffiles > 10) {
                            throw 'Erreur syntaxe ' + formuleFonction;
                        }
                    }
                }
            }
        }
        var indexParth = formuleFonction.indexOf('(');
        var debFormule = formuleFonction;
        if (indexParth > -1) debFormule = formuleFonction.substring(0, indexParth);
        switch (debFormule) {
            case 'NON':
                let formuleNon = formuleFonction.substring(4, formuleFonction.length - 1);
                let resNon = await this.calculeFormule(formuleNon, true);
                return !resNon;
            case 'SI':
                return await this.condition(formuleFonction.substring(3, formuleFonction.length - 1));
            case 'ET':
            case 'OU':
                let argmts = formuleFonction.substring(3, formuleFonction.length - 1);
                let chaines = argmts.split(';');
                let booleans = [];
                for (var i = 0; i < chaines.length; i++) {
                    let resInt = await this.calculeFormule(chaines[i], true);
                    if (resInt === 'true') resInt = true;
                    else if (resInt === 'false') resInt = false;
                    booleans.push(resInt);
                }
                if (debFormule == 'ET') {
                    let resBool = true;
                    for (var b = 0; b < booleans.length; b++) if (!booleans[b]) resBool = false;
                    return resBool;
                } else {
                    let resBool = false;
                    for (var b = 0; b < booleans.length; b++) if (booleans[b]) resBool = true;
                    return resBool;
                }
            case 'ABS':
                let formuleAbs = formuleFonction.substring(4, formuleFonction.length - 1);
                let resAbs = await this.calculeFormule(formuleAbs, true);
                return Math.abs(resAbs);
            case 'MIN':
                if (formuleFonction == debFormule) return null;
                else return await this.stat(formuleFonction.substring(4, formuleFonction.length - 1), 0);
            case 'MAX':
                if (formuleFonction == debFormule) return null;
                else return await this.stat(formuleFonction.substring(4, formuleFonction.length - 1), 1);
            case 'MOY':
                if (formuleFonction == debFormule) return null;
                else if (idNatureOrigine === 0 || idNatureOrigine === 1 || idNatureOrigine === 5 || idNatureOrigine === 63 || idNatureOrigine === 60)
                    //sur FTP si essai au format texte, renvoyer le texte pour la moyenne (ADO 2910)
                    return formuleFonction.substring(4, formuleFonction.length - 1);
                else return await this.stat(formuleFonction.substring(4, formuleFonction.length - 1), 2);
            case 'ECT':
                if (formuleFonction == debFormule) return null;
                else return await this.stat(formuleFonction.substring(4, formuleFonction.length - 1), 5);
            case 'ECT1':
                return await this.stat('[-1]' + formuleFonction.substring(5, formuleFonction.length - 1), 5);
            case 'NBR':
                if (formuleFonction == debFormule) return 0;
                else return await this.stat(formuleFonction.substring(4, formuleFonction.length - 1), 6);
            case 'PGAUSS':
                if (formuleFonction == debFormule) return null;
                else return await this.stat(formuleFonction.substring(7, formuleFonction.length - 1), 8);
            case 'NBRSI':
                return await this.stat(formuleFonction.substring(6, formuleFonction.length - 1), 10);
            case 'SOMSI':
                return await this.stat(formuleFonction.substring(6, formuleFonction.length - 1), 11);
            case 'DER':
                if (formuleFonction == debFormule) return null;
                else return await this.stat(formuleFonction.substring(4, formuleFonction.length - 1), 13);
            case 'PRE':
                if (formuleFonction == debFormule) return null;
                else return await this.stat(formuleFonction.substring(4, formuleFonction.length - 1), 14);
            case 'TEND':
                if (formuleFonction == debFormule) return null;
                else return await this.stat(formuleFonction.substring(5, formuleFonction.length - 1), 15);
            case 'VAL':
                if (formuleFonction == debFormule) return null;
                else return await this.stat(formuleFonction.substring(4, formuleFonction.length - 1), 16);
            case 'VAR':
                if (formuleFonction == debFormule) return null;
                else return await this.stat(formuleFonction.substring(4, formuleFonction.length - 1), 17);
            case 'SOM':
                if (formuleFonction == debFormule) return null;
                else return await this.stat(formuleFonction.substring(4, formuleFonction.length - 1), 18);
            case 'CDATE':
                let cDateValue = null;
                const strDateValue = await this.calculeDate(formuleFonction.substring(6, formuleFonction.length - 1));
                if (strDateValue) cDateValue = moment(strDateValue, this.dateFormat).format(this.dateFormat);
                return cDateValue;
            case 'MOYSI':
                return await this.stat(formuleFonction.substring(6, formuleFonction.length - 1), 19);
            case 'LOG':
                let formuleLog = formuleFonction.substring(4, formuleFonction.length - 1);
                let resLog = await this.calculeFormule(formuleLog, true);
                return Math.log10(resLog);
            case 'LN':
                let formuleLn = formuleFonction.substring(3, formuleFonction.length - 1);
                let resLn = await this.calculeFormule(formuleLn, true);
                return Math.log(resLn);
            case 'ROUND':
                let formuleRound = formuleFonction.substring(6, formuleFonction.length - 1);
                return await this.arrondi(formuleRound);
            case 'DIFFD':
                const datesInFormula = formuleFonction.match(/\(([^;]+);([^;]+);?([^;]*)\)/);
                if (!datesInFormula) break;
                const [, startDate, endDate, formatDuration] = datesInFormula; //virgule en début requise pour ne pas récupéré le 1er élément dans datesInFormula
                const momentStartDate = moment(startDate, this.dateFormat);
                const momentEndDate = moment(endDate, this.dateFormat);

                if (momentStartDate.isBefore(momentEndDate)) {
                    let dateDurationFormat = 'days';
                    if (formatDuration === 'M') {
                        dateDurationFormat = 'months';
                    } else if (formatDuration === 'A') {
                        dateDurationFormat = 'years';
                    }
                    return momentEndDate.diff(momentStartDate, dateDurationFormat);
                }
            default:
                return await this.getCalculBdd(formuleFonction, debFormule);
        }
    }

    async calculeDate(formuleDate) {
        try {
            let chaines = formuleDate.split(';');
            if (chaines.length == 5) {
                let resDate = await this.calculeFormule(chaines[0], true);
                let res1 = await this.calculeFormule(chaines[1], true);
                let res2 = await this.calculeFormule(chaines[2], true);
                let res3 = await this.calculeFormule(chaines[3], true);
                let res4 = await this.calculeFormule(chaines[4], true);
                if (this.isDate(resDate)) {
                    let dateDepart = this.strEnDate(resDate);
                    if (this.isEntier(res1)) {
                        let arg1 = this.strEnInt(res1);
                        if (this.isEntier(res2)) {
                            let arg2 = this.strEnInt(res2);
                            if (this.isEntier(res3)) {
                                let arg3 = this.strEnInt(res3);
                                if (this.isEntier(res4)) {
                                    let arg4 = this.strEnInt(res4);
                                    let dateCalcul = dateDepart.add(arg1, 'days').add(arg2, 'weeks').add(arg3, 'months').add(arg4, 'years');
                                    return dateCalcul;
                                } else {
                                    throw 'erreur syntaxe calcul date (arg. 4 non numérique) ' + formuleDate;
                                }
                            } else {
                                throw 'erreur syntaxe calcul date (arg. 3 non numérique) ' + formuleDate;
                            }
                        } else {
                            throw 'erreur syntaxe calcul date (arg. 2 non numérique) ' + formuleDate;
                        }
                    } else {
                        throw 'erreur syntaxe calcul date (arg. 1 non numérique) ' + formuleDate;
                    }
                } else {
                    throw 'erreur syntaxe calcul date (pas de date de départ) ' + formuleDate;
                }
            } else throw 'erreur syntaxe calcul date (nb argmt incorrect) ' + formuleDate;
        } catch (err) {
            throw 'erreur syntaxe calcul date ' + formuleDate;
        }
    }

    async condition(formuleCondition) {
        let sCond = '';
        let sPart1 = '';
        let sPart2 = '';
        let iParthOuv = 0;
        let iParthFerm = 0;
        let numPart = 0;
        let condErr = false;
        for (let i = 0; i < formuleCondition.length; i++) {
            if (formuleCondition[i] == ';' && iParthOuv == iParthFerm) numPart++;
            else {
                switch (numPart) {
                    case 0:
                        sCond = sCond + formuleCondition[i];
                        break;
                    case 1:
                        sPart1 = sPart1 + formuleCondition[i];
                        break;
                    case 2:
                        sPart2 = sPart2 + formuleCondition[i];
                        break;
                    case 3:
                        condErr = true;
                        break;
                }
                if (formuleCondition[i] == '(') iParthOuv++;
                else if (formuleCondition[i] == ')') iParthFerm++;
            }
        }
        if (numPart != 2 || iParthOuv != iParthFerm) condErr = true;
        if (!condErr) {
            let objResultatCond = await this.calculeFormule(sCond, true);
            if (objResultatCond == 'false') objResultatCond = 0;
            if (objResultatCond) return await this.calculeFormule(sPart1, true);
            else return await this.calculeFormule(sPart2, true);
        } else throw 'erreur syntaxe condition ' + formuleCondition;
    }

    async getCalculBdd(formuleBDD, typCalc) {
        switch (typCalc) {
            case 'CONF':
                let objetConf = undefined;
                if (formuleBDD == typCalc) {
                    objetConf = this.obj;
                }
                if (objetConf != undefined) {
                    return objetConf.entete.conformite;
                }
                throw 'erreur conformité ' + formuleBDD;
            case 'DATE':
                if (formuleBDD == typCalc) {
                    if (this.obj.date) return this.obj.date;
                    //prélèvement
                    else return moment(this.obj.datePrelevement, this.dateFormat + ' HH:mm').format(this.dateFormat); //Feuille de casse
                }
                throw 'erreur syntaxe date ' + formuleBDD;
            case 'MES':
                let argmtsMes = formuleBDD.substring(typCalc.length + 1, formuleBDD.length - 1);
                let argsTabMes = argmtsMes.split(';');
                let codeEssai = argsTabMes[0];
                if (codeEssai.indexOf('@') > -1)
                    //si il y a une formule pour récupérer le code de l'essai
                    codeEssai = await this.calculeFormule(codeEssai, true);
                let codeSsEssai = argsTabMes[1];

                let mesTemp = _.find(this.obj.mesures, { code: codeEssai });
                if (mesTemp) {
                    if (mesTemp && mesTemp.sousEssais) {
                        let formatSousMes = _.find(mesTemp.sousEssais, { code: codeSsEssai });
                        if (formatSousMes) {
                            if (formatSousMes.formule) {
                                let val = await this.calculeFormule(formatSousMes.formule, true);
                                if (val) return true;
                                else return false;
                            } else {
                                let val = formatSousMes.value;
                                if (val) return true;
                                else return false;
                            }
                        } else return false;
                    } else return false;
                } else return false; //SP 10/03/20 non sur les valeurs de référence
            case 'TYPE':
                if (this.obj.entete) return this.obj.entete.typeCode;
            case 'VALC':
                let objetCaract = undefined;
                let codeCaract = '';
                let argmtsCaract = formuleBDD.substring(typCalc.length + 1, formuleBDD.length - 1);
                let argsTabCaract = argmtsCaract.split(';');
                if (argsTabCaract.length == 1) {
                    objetCaract = this.obj;
                    codeCaract = argmtsCaract;
                } else if (argsTabCaract.length == 2) {
                    codeCaract = argsTabCaract[1];
                    //recherche de l'objet
                    let domaineObj = '';
                    switch (argsTabCaract[0]) {
                        case '@CM':
                            domaineObj = 'campagnes';
                            break;
                        case '@ENT':
                            domaineObj = 'entites';
                            break;
                        case '@SITE':
                            domaineObj = 'siteprod';
                            break;
                    }
                    if (domaineObj != '') {
                        objetCaract = await this.getObjetAvecDomaine(this.obj, domaineObj);
                    }
                }
                if (objetCaract != undefined) {
                    if (objetCaract.caracteristiques) {
                        if (this.formatCaract) {
                            let formatCar = _.find(this.formatCaract, { code: codeCaract });
                            let valCar = objetCaract.caracteristiques[formatCar.id];
                            if (Array.isArray(valCar)) {
                                return valCar[0].value;
                            }
                            return valCar;
                        } else {
                            let idCaract = await this.getIdCaract(codeCaract);
                            return objetCaract.caracteristiques[idCaract];
                        }
                    }
                }
                throw 'erreur caract ' + formuleBDD;
            case 'VALM':
            case 'VALMSI':
                let argmtsValM = formuleBDD.substring(typCalc.length + 1, formuleBDD.length - 1);
                let argsTabValM = argmtsValM.split(';');
                let conditionRetour = this.conditionVALM;
                let arrayPourCondition = [];
                if (typCalc == 'VALMSI') {
                    if (argsTabValM.length > 2) {
                        //garder les 2 derniers arguments => essai/sous-essai, le reste = tableau des élts pour condition + condition
                        for (let iCond = 0; iCond < argsTabValM.length - 3; iCond++) arrayPourCondition.push(argsTabValM[iCond]);
                        this.conditionVALM = argsTabValM[argsTabValM.length - 3];
                        if (this.conditionVALM == '') throw 'erreur syntaxe stat ' + formuleBDD;
                        argsTabValM = _.drop(argsTabValM, arrayPourCondition.length + 1);
                    } else throw 'erreur syntaxe stat ' + formuleBDD;
                }
                if (argsTabValM.length == 2 || (argsTabValM.length == 3 && argsTabValM[0] != '@RECH')) {
                    let codeEssai = argsTabValM[0];
                    if (codeEssai.indexOf('@') > -1)
                        //si il y a une formule pour récupérer le code de l'essai
                        codeEssai = await this.calculeFormule(codeEssai, true);
                    let codeSsEssai = argsTabValM[1];
                    if (this.obj.mesures) {
                        //prélèvement (=> pas feuille de casse)
                        let mesTemp = _.find(this.obj.mesures, { code: codeEssai });
                        if (mesTemp) {
                            if (mesTemp.sousEssais) {
                                let formatSousMes = _.find(mesTemp.sousEssais, { code: codeSsEssai });
                                if (formatSousMes) {
                                    if (formatSousMes.formule) {
                                        let resTmp = await this.calculeFormule(formatSousMes.formule, true);
                                        if (!resTmp && formatSousMes.forceSaisie) {
                                            resTmp = formatSousMes.value;
                                        }
                                        if (typCalc == 'VALMSI') this.conditionVALM = conditionRetour;
                                        return resTmp;
                                    } else {
                                        if (typCalc == 'VALMSI') this.conditionVALM = conditionRetour;
                                        return formatSousMes.value;
                                    }
                                } else {
                                    //si le sous-essai n'est pas dans format, chercher s'il y a des essais génériques
                                    var essaisGeneriques = _.filter(mesTemp.sousEssais, { idNature: 10 });
                                    var essaisIteratifs = _.filter(mesTemp.sousEssais, { idNature: 11 });
                                    essaisGeneriques = _.union(essaisGeneriques, essaisIteratifs);
                                    var addInfosGeneriq = _.map(essaisGeneriques, 'generiqueAdditionalInformation');
                                    var essaisLiesGeneriq = _.map(addInfosGeneriq, 'essaiLie');
                                    var ssEssGeneriq = _.map(essaisLiesGeneriq, 'sousEssais');
                                    ssEssGeneriq = _.flatten(ssEssGeneriq.map((e) => Object.values(e)));

                                    let formatSousMesGen = _.find(ssEssGeneriq, { code: codeSsEssai });
                                    if (formatSousMesGen) {
                                        let resIter = formatSousMesGen.value;
                                        if (formatSousMesGen.formule) {
                                            if (Array.isArray(resIter)) {
                                                let nbIter = resIter.length;
                                                let resArrayIter = [];
                                                for (let iIter = 0; iIter < nbIter; iIter++) {
                                                    try {
                                                        let conditionOk = true;
                                                        if (this.conditionVALM) {
                                                            if (arrayPourCondition && arrayPourCondition.length > iIter) {
                                                                conditionOk = await this.calculeFormule(
                                                                    arrayPourCondition[iIter] + this.conditionVALM,
                                                                    true
                                                                );
                                                            } else {
                                                                conditionOk = await this.calculeFormule(
                                                                    this.conditionVALM
                                                                        .replace(/@ITER/g, iIter)
                                                                        .replace(/@ESSAPPEL/g, this.essaiAppel || codeEssai),
                                                                    true
                                                                );
                                                            }
                                                        }
                                                        if (conditionOk) {
                                                            let resTmpIter = await this.calculeFormule(
                                                                formatSousMesGen.formule
                                                                    .replace(/@ITER/g, iIter)
                                                                    .replace(/@ESSAPPEL/g, this.essaiAppel || codeEssai),
                                                                true
                                                            );
                                                            if (!resTmpIter && formatSousMesGen.forceSaisie) {
                                                                resTmpIter = resIter[iIter];
                                                            }
                                                            resArrayIter.push(resTmpIter);
                                                        }
                                                    } catch (ex) {
                                                        //console.info('err formule essai itératif', ex.data);
                                                    }
                                                }
                                                if (this.forceIter != undefined || argsTabValM.length == 3) {
                                                    var iterationCherchee = argsTabValM.length == 3 ? argsTabValM[2] : this.forceIter;
                                                    if (iterationCherchee < resArrayIter.length) {
                                                        if (typCalc == 'VALMSI') this.conditionVALM = conditionRetour;
                                                        return resArrayIter[iterationCherchee];
                                                    }
                                                    if (typCalc == 'VALMSI') this.conditionVALM = conditionRetour;
                                                    return '';
                                                } else {
                                                    let tabEntier = '';
                                                    for (let iIter2 = 0; iIter2 < resArrayIter.length; iIter2++) {
                                                        if (
                                                            resArrayIter[iIter2] != undefined &&
                                                            resArrayIter[iIter2] != null &&
                                                            !isNaN(resArrayIter[iIter2])
                                                        ) {
                                                            if (iIter2 > 0) tabEntier += ';';
                                                            tabEntier += resArrayIter[iIter2];
                                                        }
                                                    }
                                                    if (typCalc == 'VALMSI') this.conditionVALM = conditionRetour;
                                                    return tabEntier;
                                                }
                                            } else {
                                                // console.info('PAS UN TABLEAU', formatSousMesGen);
                                            }
                                        } else {
                                            if (Array.isArray(resIter)) {
                                                if (argsTabValM.length == 3) {
                                                    if (argsTabValM[2] < resIter.length) {
                                                        if (typCalc == 'VALMSI') this.conditionVALM = conditionRetour;
                                                        return resIter[argsTabValM[2]];
                                                    } else {
                                                        if (typCalc == 'VALMSI') this.conditionVALM = conditionRetour;
                                                        return '';
                                                    }
                                                }
                                                //VP : commenté le 15/01 -> mantis 5539 (formule sur l'ensemble des itérations)
                                                // else if (this.forceIter != undefined) {
                                                //     if (this.forceIter < resIter.length) {
                                                //         if (typCalc == 'VALMSI') this.conditionVALM = conditionRetour;
                                                //         return resIter[this.forceIter];
                                                //     }
                                                //     else {
                                                //         if (typCalc == 'VALMSI') this.conditionVALM = conditionRetour;
                                                //         return '';
                                                //     }
                                                // }
                                                else {
                                                    let strIter = '';
                                                    for (var iIter = 0; iIter < resIter.length; iIter++) {
                                                        if (iIter > 0) strIter += ';';
                                                        let conditionOk = true;
                                                        try {
                                                            if (this.conditionVALM) {
                                                                conditionOk = await this.calculeFormule(
                                                                    this.conditionVALM
                                                                        .replace(/@ITER/g, iIter)
                                                                        .replace(/@ESSAPPEL/g, this.essaiAppel || codeEssai),
                                                                    true
                                                                );
                                                            }
                                                            if (conditionOk) strIter += resIter[iIter];
                                                        } catch (ex) {
                                                            //  console.info('err condition essai itératif', ex.data);
                                                        }
                                                    }
                                                    if (typCalc == 'VALMSI') this.conditionVALM = conditionRetour;
                                                    return strIter;
                                                }
                                            } else {
                                                if (typCalc == 'VALMSI') this.conditionVALM = conditionRetour;
                                                return resIter;
                                            }
                                        }
                                    } else {
                                        if (typCalc == 'VALMSI') this.conditionVALM = conditionRetour;
                                        return ''; //null
                                    }
                                }
                            } else {
                                if (typCalc == 'VALMSI') this.conditionVALM = conditionRetour;
                                return ''; //null
                            }
                        } else {
                            if (typCalc == 'VALMSI') this.conditionVALM = conditionRetour;
                            return ''; //null
                        }
                    } else {
                        //feuille de casse
                        var formatSousMesFC = _.filter(this.obj.listeSousEssaiLignes, { codeEssai: codeEssai, codeSousEssai: codeSsEssai });
                        if (formatSousMesFC && formatSousMesFC.length > 0) {
                            if (
                                (this.forceIter == null || this.forceIter == undefined) &&
                                formatSousMesFC &&
                                formatSousMesFC.length > 0 &&
                                formatSousMesFC[0].formule &&
                                formatSousMesFC[0].formule.indexOf('@ESSAPPEL') > -1
                            ) {
                                let resToutesIter = '';
                                for (var iResFC = 0; iResFC < formatSousMesFC.length; iResFC++) {
                                    this.forceIter = formatSousMesFC[iResFC].iterationReelle;

                                    try {
                                        let conditionOk = true;
                                        if (this.conditionVALM) {
                                            if (arrayPourCondition && arrayPourCondition.length > this.forceIter) {
                                                conditionOk = await this.calculeFormule(
                                                    arrayPourCondition[this.forceIter] + this.conditionVALM,
                                                    true
                                                );
                                            } else {
                                                conditionOk = await this.calculeFormule(
                                                    this.conditionVALM
                                                        .replace(/@ITER/g, this.forceIter)
                                                        .replace(/@ESSAPPEL/g, this.essaiAppel || codeEssai),
                                                    true
                                                );
                                            }
                                        }
                                        if (conditionOk) {
                                            let resTmpFCIter = await this.calculeFormule(
                                                formatSousMesFC[iResFC].formule.replace(/@ESSAPPEL/g, this.essaiAppel || codeEssai),
                                                true
                                            );
                                            if (iResFC > 0) resToutesIter += ';';
                                            resToutesIter += resTmpFCIter;
                                        }
                                    } catch (ex) {
                                        //  console.info('err feuille essai formule essai itératif', ex.data);
                                    }
                                }
                                this.forceIter = undefined;
                                return resToutesIter;
                            } else {
                                var valIterationFC = this.forceIter
                                    ? _.find(formatSousMesFC, { iterationReelle: this.forceIter })
                                    : formatSousMesFC[0];
                                if (!valIterationFC && !formatSousMesFC[0].idEssaiInclus) {
                                    valIterationFC = formatSousMesFC[0];
                                }
                                if (valIterationFC) {
                                    if (valIterationFC.formule) {
                                        let resTmpFC = await this.calculeFormule(
                                            valIterationFC.formule.replace(/@ESSAPPEL/g, this.essaiAppel || codeEssai),
                                            true
                                        );
                                        return resTmpFC;
                                    } else {
                                        return valIterationFC.valeur;
                                    }
                                } else {
                                    return '';
                                }
                            }
                        } else return '';
                    }
                } else if (argsTabValM.length == 3) {
                    //3 paramètre, le premier pour rechercher le prélèvement

                    if (argsTabValM[0] == '@RECH') {
                        let objetRechSaisie = await this.getLastSaisie(this.obj);
                        if (objetRechSaisie) {
                            let codeEssai = argsTabValM[1];
                            if (codeEssai.indexOf('@') > -1)
                                //si il y a une formule pour récupérer le code de l'essai
                                codeEssai = await this.calculeFormule(codeEssai, true);
                            let codeSsEssai = argsTabValM[2];
                            let mesure = _.find(objetRechSaisie.mesures, { code: codeEssai });
                            let sousMesure = _.find(mesure.sousEssais, { code: codeSsEssai });
                            return sousMesure.value;
                        } else return '';
                    } else return '';
                }
                throw 'erreur valeur de mesure ' + formuleBDD;
            case 'VALR':
                let argmtsValR = formuleBDD.substring(typCalc.length + 1, formuleBDD.length - 1);
                if (argmtsValR.indexOf(';') > -1) {
                    let argsTabValR = argmtsValR.split(';');
                    if (argsTabValR.length == 2) {
                        let codeEssai = argsTabValR[0];
                        if (codeEssai.indexOf('@') > -1)
                            //si il y a une formule pour récupérer le code de l'essai
                            codeEssai = await this.calculeFormule(codeEssai, true);
                        let codeSsEssai = argsTabValR[1];
                        //rechercher dans les valeurs de référence
                        if (this.valeursRef) {
                            let valRef = _.find(this.valeursRef, { codeEssai: codeEssai, codeSousEssai: codeSsEssai });
                            if (valRef) return valRef.valeur;
                        } else return '';
                    } else return '';
                } else {
                    let codeEssai = argmtsValR;
                    if (codeEssai.indexOf('@') > -1)
                        //si il y a une formule pour récupérer le code de l'essai
                        (codeEssai = await this.calculeFormule(codeEssai)), true;
                    if (this.valeursRef) {
                        let valRef = _.find(this.valeursRef, { codeEssai: codeEssai, codeSousEssai: codeEssai });
                        if (valRef) return valRef.valeur;
                        else return '';
                    } else return '';
                }
            case 'VALT':
                let argmtsValT = formuleBDD.substring(typCalc.length + 1, formuleBDD.length - 1);
                let argsTabValT = argmtsValT.split(';');
                if (argsTabValT.length == 2) {
                    let codeEssai = argsTabValT[0];
                    if (codeEssai.indexOf('@') > -1)
                        //si il y a une formule pour récupérer le code de l'essai
                        codeEssai = await this.calculeFormule(codeEssai, true);
                    let tamis = argsTabValT[1];
                    if (tamis.indexOf('@') > -1)
                        //si il y a une formule pour récupérer le code du tamis
                        tamis = await this.calculeFormule(tamis, true);
                    let mesTemp = _.find(this.obj.mesures, { code: codeEssai });
                    if (mesTemp && mesTemp.sousEssais) {
                        let formatSousMes = _.find(mesTemp.sousEssais, { idNature: 8 });
                        if (formatSousMes) {
                            let valTamis = formatSousMes.granuloAdditionalInformation.tamis;
                            let valeurDuTamis = _.filter(valTamis, function (elt) {
                                return elt.ouverture == Number(tamis);
                            });
                            // si le tamis fait parti de la série de tamis
                            if (valeurDuTamis && valeurDuTamis.length > 0) {
                                // si le tamis a une valeur de % de passant → renvoi cette valeur
                                if (valeurDuTamis[0].pourcPassant || valeurDuTamis[0].pourcPassant == 0) {
                                    return valeurDuTamis[0].pourcPassant;
                                }
                                // sinon calcul par extrapolation de la valeur du % de passant
                                else {
                                    const valTamisDisplayed = valTamis.filter((x) => {
                                        return x.isDisplay && (x.pourcPassant || x.pourcPassant == 0 || x.ouverture == valeurDuTamis[0].ouverture);
                                    });
                                    let idxTamis = valTamisDisplayed.findIndex((x) => {
                                        return x.ouverture == valeurDuTamis[0].ouverture;
                                    });
                                    if (idxTamis === -1) {
                                        valTamisDisplayed.push(valeurDuTamis[0]);
                                        _.sortBy(valTamisDisplayed, ['ouverture'], ['desc']); // revoir le tri
                                        idxTamis = valTamisDisplayed.findIndex((x) => x.ouverture == valeurDuTamis[0].ouverture);
                                    }
                                    let prevIndex = idxTamis - 1;
                                    let nextIndex = idxTamis + 1;
                                    if (valTamisDisplayed[nextIndex] && valTamisDisplayed[prevIndex]) {
                                        return this.extrapolationLN(
                                            valTamisDisplayed[nextIndex],
                                            valTamisDisplayed[prevIndex],
                                            valeurDuTamis[0].ouverture
                                        );
                                    }

                                    return '';
                                }
                            } else return ''; //null
                        } else return ''; //null
                    } else return ''; //null
                } else if (argsTabValM.length == 3) {
                    //3 paramètre, le premier pour rechercher le prélèvement
                    let objetRechSaisie = undefined;
                    if (argsTabValM[0] == '@RECH') objetRechSaisie = await this.getLastSaisie(this.obj);
                    if (objetRechSaisie) {
                        let codeEssai = argsTabValM[1];
                        if (codeEssai.indexOf('@') > -1)
                            //si il y a une formule pour récupérer le code de l'essai
                            codeEssai = await this.calculeFormule(codeEssai, true);
                        let codeSsEssai = argsTabValM[2];
                        let mesure = _.find(objetRechSaisie.mesures, { code: codeEssai });
                        let sousEss = _.find(mesure.sousEssais, { code: codeSsEssai });
                        return sousEss.value;
                    }
                }
                throw 'erreur valeur de mesure ' + formuleBDD;
            case 'D':
                if (this.obj /*&& this.obj.constructor*/) {
                    //SP 16/06/21 à priori .constructor ne passe pas en version buildée...
                    //if (this.obj.constructor.name === 'Prelevement') {
                    if (this.obj.hasOwnProperty('produit')) return this.obj.produit.grandD;
                    //}

                    if (this.obj.entete) return this.obj.entete.grandD;
                    else return this.obj.grandD;
                } else return '';
            case 'd':
                if (this.obj /*&& this.obj.constructor*/) {
                    //SP 16/06/21 à priori .constructor ne passe pas en version buildée...
                    //if (this.obj.constructor.name === 'Prelevement') {
                    if (this.obj.hasOwnProperty('produit')) return this.obj.produit.petitd;
                    //}

                    if (this.obj.entete) return this.obj.entete.petitd;
                    else return this.obj.petitd;
                } else return '';
            case 'MVT':
                if (this.obj) {
                    if (this.obj.hasOwnProperty('produit') && this.obj.produit.hasOwnProperty('composition') && this.obj.produit.composition != null)
                        return this.obj.produit.composition.mvt;
                } else return '';
            case 'ETA':
                if (this.obj) {
                    if (this.obj.hasOwnProperty('produit') && this.obj.produit.hasOwnProperty('composition') && this.obj.produit.composition != null)
                        return this.obj.produit.composition.ecartTypeAdopte;
                } else return '';
            case 'RESEST':
                if (this.obj) {
                    if (this.obj.hasOwnProperty('produit') && this.obj.produit.hasOwnProperty('composition') && this.obj.produit.composition != null)
                        return this.obj.produit.composition.resistanceEstimee;
                } else return '';
            case 'RESVIS':
                if (this.obj) {
                    if (this.obj.hasOwnProperty('produit') && this.obj.produit.hasOwnProperty('composition') && this.obj.produit.composition != null)
                        return this.obj.produit.composition.resistanceVisee;
                } else return '';
            case 'TMPMALAX':
                if (this.obj) {
                    if (this.obj.hasOwnProperty('produit') && this.obj.produit.hasOwnProperty('composition') && this.obj.produit.composition != null)
                        return this.obj.produit.composition.tempsMalaxage;
                } else return '';
            case 'AIRVOL':
                if (this.obj) {
                    if (this.obj.hasOwnProperty('produit') && this.obj.produit.hasOwnProperty('composition') && this.obj.produit.composition != null)
                        return this.obj.produit.composition.volumeAir;
                } else return '';
            case 'DP':
                let argmtsTamPass = formuleBDD.substring(typCalc.length + 1, formuleBDD.length - 1);
                let argsTabTamPass = argmtsTamPass.split(';');
                if (argsTabTamPass.length == 2) {
                    let codeEssai = argsTabTamPass[0];
                    if (codeEssai.indexOf('@') > -1)
                        //si il y a une formule pour récupérer le code de l'essai
                        codeEssai = await this.calculeFormule(codeEssai, true);

                    let pCentTamis = argsTabTamPass[1];
                    if (pCentTamis.indexOf('@') > -1)
                        //si il y a une formule pour récupérer le pourcentage de passant
                        pCentTamis = await this.calculeFormule(pCentTamis, true);

                    let mesTemp = _.find(this.obj.mesures, { code: codeEssai });
                    if (mesTemp && mesTemp.sousEssais) {
                        let formatSousMes = _.find(mesTemp.sousEssais, { idNature: 8 });
                        if (formatSousMes) {
                            let valTamis = formatSousMes.granuloAdditionalInformation.tamis.filter((e) => e.isDisplay);
                            if (valTamis && valTamis.length > 0) {
                                let booFindExactly = true;
                                let valeurDuTamis = _.filter(valTamis, function (elt) {
                                    return parseFloat(elt.pourcPassant) == parseFloat(pCentTamis);
                                });
                                if (!valeurDuTamis || valeurDuTamis.length <= 0) {
                                    booFindExactly = false;
                                    //Extrapolation linéaire
                                    valeurDuTamis = [];
                                    let valTamisInterpoled = this.interpoler(
                                        valTamis.map((e) => e.ouverture).reverse(),
                                        valTamis.map((e) => e.pourcPassant).reverse()
                                    );
                                    valTamisInterpoled = valTamisInterpoled.reverse();

                                    let xyInf = { x: 0, y: 0 };
                                    let xySup = { x: 0, y: 100 };
                                    _.each(valTamis, function (elt, i) {
                                        if (parseFloat(valTamisInterpoled[i]) == parseFloat(pCentTamis)) {
                                            valeurDuTamis.push(elt);
                                            booFindExactly = true;
                                        } else if (!booFindExactly) {
                                            if (parseFloat(valTamisInterpoled[i]) <= parseFloat(pCentTamis) && xyInf.y === 0) {
                                                xyInf.x = parseFloat(elt.ouverture);
                                                xyInf.y = parseFloat(valTamisInterpoled[i]);
                                            } else if (parseFloat(valTamisInterpoled[i]) >= parseFloat(pCentTamis)) {
                                                xySup.x = parseFloat(elt.ouverture);
                                                xySup.y = parseFloat(valTamisInterpoled[i]);
                                            }
                                        }
                                    });

                                    if (!booFindExactly) {
                                        //Calcul de la regression linéaire à 2 paramètres
                                        //Calcul du coeff directeur a
                                        //let a = (xySup.y - xyInf.y) / (xySup.x - xyInf.x);
                                        //Cacul du b
                                        //let b = xyInf.y - (a * xyInf.x);
                                        //Cacul de l'ouverture tamis avec l'équation
                                        //let o = ((parseFloat(pCentTamis) - b) / a).toString();

                                        //Regression logarithmique à 2 paramètres
                                        //y = Exp((Valeur*ln(d2/d1)-v1*ln(d2)+v2*ln(d1))/(v2-v1))
                                        let o = Math.exp(
                                            (parseFloat(pCentTamis) * Math.log(xySup.x / xyInf.x) -
                                                xyInf.y * Math.log(xySup.x) +
                                                xySup.y * Math.log(xyInf.x)) /
                                                (xySup.y - xyInf.y)
                                        );
                                        valeurDuTamis.push({ ouverture: o.toString() });
                                    }
                                }

                                if (valeurDuTamis && valeurDuTamis.length > 0) {
                                    return valeurDuTamis[0].ouverture; //On prend l'ouverture la plus grand;
                                } else return ''; //null
                            } else return ''; //null
                        } else return ''; //null
                    } else return ''; //null
                }
                throw 'erreur valeur de mesure ' + formuleBDD;
            case 'TP':
                let argmtsTamNext = formuleBDD.substring(typCalc.length + 1, formuleBDD.length - 1);
                let argsTabTamNext = argmtsTamNext.split(';');
                if (argsTabTamNext.length == 2) {
                    let codeEssai = argsTabTamNext[0];
                    if (codeEssai.indexOf('@') > -1)
                        //si il y a une formule pour récupérer le code de l'essai
                        codeEssai = await this.calculeFormule(codeEssai, true);

                    let ouvTamTheo = argsTabTamNext[1];
                    if (ouvTamTheo.indexOf('@') > -1)
                        //si il y a une formule pour récupérer le pourcentage de passant
                        ouvTamTheo = await this.calculeFormule(ouvTamTheo, true);
                    else ouvTamTheo = parseFloat(ouvTamTheo.replace(',', '.'));

                    let mesTemp = _.find(this.obj.mesures, { code: codeEssai });
                    if (mesTemp && mesTemp.sousEssais) {
                        let formatSousMes = _.find(mesTemp.sousEssais, { idNature: 8 });
                        if (formatSousMes) {
                            let valTamis = formatSousMes.granuloAdditionalInformation.tamis;
                            if (valTamis && valTamis.length > 0) {
                                let xInf = 0;
                                let xSup = 0;
                                _.each(valTamis, function (elt, i) {
                                    if (parseFloat(elt.ouverture) <= ouvTamTheo && xInf === 0) {
                                        xInf = parseFloat(elt.ouverture);
                                    } else if (parseFloat(elt.ouverture) >= ouvTamTheo) {
                                        xSup = parseFloat(elt.ouverture);
                                    }
                                });

                                if (xInf - ouvTamTheo > xSup - ouvTamTheo) return xSup.toString();
                                else return xInf.toString();
                            } else return ''; //null
                        } else return ''; //null
                    } else return ''; //null
                }
                throw 'erreur valeur de mesure ' + formuleBDD;
        }
        throw 'erreur getCalculBdd ' + formuleBDD;
    }

    async arrondi(formuleArrondi) {
        let sDec = '';
        let sPArr = '';
        let iParthOuv = 0;
        let iParthFerm = 0;
        let numPart = 0;
        let ArrErr = false;
        for (var i = 0; i < formuleArrondi.length; i++) {
            if (formuleArrondi[i] == ';' && iParthOuv == iParthFerm) numPart++;
            else {
                switch (numPart) {
                    case 0:
                        sDec = sDec + formuleArrondi[i];
                        break;
                    case 1:
                        sPArr = sPArr + formuleArrondi[i];
                        break;
                    case 2:
                        ArrErr = true;
                        break;
                }
                if (formuleArrondi[i] == '(') iParthOuv++;
                else if (formuleArrondi[i] == ')') iParthFerm++;
            }
        }
        if (numPart != 1 || iParthOuv != iParthFerm) ArrErr = true;
        if (!ArrErr) {
            try {
                let resTmp = await this.calculeFormule(sDec, true);
                if (resTmp === '' || resTmp === null || resTmp === undefined) {
                    return resTmp;
                } else {
                    let d = this.strEnDouble(resTmp);
                    if (!isNaN(d)) {
                        resTmp = await this.calculeFormule(sPArr, true);
                        if (resTmp === '' || resTmp === null || resTmp === undefined) {
                            return resTmp;
                        } else {
                            let n = this.strEnInt(resTmp);
                            return d.toFixed(n);
                        }
                    }
                }
            } catch {
                throw 'erreur syntaxe arrondi ' + formuleArrondi;
            }
        } else {
            return '';
        }
    }

    async stat(formuleStat, TypStat) {
        let result = '';
        let conditionStat = '';
        let listVal = '';
        let MthdEctN_1 = TypStat == 5 && formuleStat.startsWith('[-1]');
        if (MthdEctN_1) formuleStat = formuleStat.substring(4);
        let ALTabVal = [];
        //#region récupération de la condition pour SOMSI et NBRSI
        if ((TypStat == 10 && TypStat == 11) || TypStat == 19) {
            let aos = formuleStat.split(';');
            if (aos.length > 1) {
                conditionStat = aos[0];
                formuleStat = formuleStat.substring(conditionStat.length + 1, formuleStat.length);
                if (conditionStat == '') throw 'erreur syntaxe stat ' + formuleStat;
            } else throw 'erreur syntaxe stat ' + formuleStat;
        }
        //#endregion
        //#region remplir TabVal
        let sPartie = '';
        while (formuleStat != '') {
            //#region récupérer prochaine partie
            sPartie = '';
            if (formuleStat.startsWith('@')) {
                let nbParth = 0;
                let nbParth2 = 0;
                while (!(formuleStat == '' || (nbParth == nbParth2 && formuleStat.StartsWith(';')))) {
                    sPartie = sPartie + formuleStat.substring(0, 1);
                    formuleStat = formuleStat.substring(1);
                    if (sPartie.endsWith('(')) nbParth++;
                    else if (sPartie.endsWith(')')) nbParth2++;
                }
                if (formuleStat != '' && formuleStat.startsWith(';')) formuleStat = formuleStat.substring(1);
            } else if (formuleStat.indexOf(';') > -1) {
                sPartie = formuleStat.split(';')[0];
                formuleStat = formuleStat.substring(sPartie.length + 1);
            } else {
                sPartie = formuleStat;
                formuleStat = '';
            }
            //#endregion
            //#region traiter partie
            if (sPartie != '') {
                if (sPartie.startsWith('@')) {
                    let sRes = await this.calculeFormule(sPartie, true);
                    if (sRes != '' && sRes != sPartie) {
                        if (formuleStat != '') formuleStat = sRes + ';' + formuleStat;
                        else formuleStat = sRes;
                    }
                } else {
                    ALTabVal.push(sPartie);
                    if (listVal != '') listVal = listVal + ';';
                    listVal = listVal + ALTabVal[ALTabVal.length - 1];
                }
            }
            //#endregion
        }
        //#endregion
        //#region traitement de TabVal et TabErr en fonction de la stat
        let v = 0;
        switch (TypStat) {
            case 0:
            case 1:
                let bPrem = true;
                for (var j = 0; j < ALTabVal.length; j++) {
                    if (ALTabVal[j].toString() != '') {
                        try {
                            let v2 = this.strEnDouble(ALTabVal[j].toString());
                            if (isNaN(v2)) v2 = null;
                            if (bPrem) {
                                v = v2;
                                bPrem = false;
                            } else if ((TypStat == 0 && v > v2) || (TypStat == 1 && v < v2)) {
                                v = this.strEnDouble(ALTabVal[j].toString());
                                if (isNaN(v)) v = null;
                            }
                        } catch (err) {}
                    }
                }
                break;
            case 2:
                //nbrCond = 0;
                v = 0;
                for (var j = 0; j < ALTabVal.length; j++) {
                    let x = this.strEnDouble(ALTabVal[j].toString());
                    if (!isNaN(x)) v += x;
                }
                v = v / ALTabVal.length;
                break;
            case 3:
            case 4:
            case 10: //NBRSI
                v = 0;
                for (var j = 0; j < ALTabVal.length; j++) {
                    try {
                        let interpRes = await this.calculeFormule(ALTabVal[j].toString() + conditionStat, true);
                        if (interpRes === 'true') interpRes = true;
                        else if (interpRes === 'false') interpRes = false;
                        if (
                            (TypStat > 9 && interpRes) || //TODO: SP vérifier booléen ?
                            TypStat < 10
                        ) {
                            v++;
                        }
                    } catch (err) {}
                }
                break;
            case 11: //SOMSI
                for (var j = 0; j < ALTabVal.length; j++) {
                    try {
                        let interpRes = await this.calculeFormule(ALTabVal[j].toString() + conditionStat, true);
                        if (interpRes === 'true') interpRes = true;
                        else if (interpRes === 'false') interpRes = false;
                        if (
                            (TypStat > 9 && interpRes) || //TODO: SP vérifier booléen ?
                            TypStat < 10
                        ) {
                            let x = this.strEnDouble(ALTabVal[j].toString());
                            if (!isNaN(x)) v = v + x;
                        }
                    } catch (err) {}
                }
                /*if ((TypStat < 10) && (TypStat != 3))
                    if (ALTabVal.length > 0) {
                        v = v / ALTabVal.length;
                        if (TypStat != 2) {
                            let v2 = 0;
                            for (var j = 0; j < ALTabVal.length; j++) {
                                let x = this.strEnDouble(ALTabVal[j].toString());
                                if (!isNaN(x))
                                    v2 = v2 + (x - v) * (x - v);
                            }
                            v = v2 / ALTabVal.length;
                            if (TypStat == 5) {
                                if (!(MthdEctN_1))
                                    v = Math.sqrt(v2 / ALTabVal.length);
                                else
                                    if (ALTabVal.length > 1)
                                        v = Math.sqrt(v2 / (ALTabVal.length - 1));
                            }
                        }
                    }
                    else
                        throw 'erreur syntaxe stat ' + formuleStat;*/
                break;
            case 6:
            case 7:
            case 8:
            case 9:
                break;
            case 12:
                if (ALTabVal.length > 1) {
                    try {
                        v = this.strEnDouble(ALTabVal[ALTabVal.length - 2].toString());
                        if (!isNaN(v)) {
                            let v2 = this.strEnDouble(ALTabVal[ALTabVal.length - 1].toString());
                            if (!isNaN(v2)) {
                                if (v > v2) v = -1;
                                else if (v < v2) v = 1;
                                else v = 0;
                            }
                        }
                    } catch (err) {
                        throw 'erreur syntaxe stat ' + formuleStat;
                    }
                } else throw 'erreur syntaxe stat ' + formuleStat;
                break;
            case 13:
                if (ALTabVal.length > 0)
                    try {
                        v = this.strEnDouble(ALTabVal[ALTabVal.length - 1].toString());
                        if (isNaN(v)) v = null;
                    } catch (err) {
                        throw 'erreur syntaxe stat ' + formuleStat;
                    }
                break;
            case 14:
                if (ALTabVal.length > 0)
                    try {
                        v = this.strEnDouble(ALTabVal[0].toString());
                        if (isNaN(v)) v = null;
                    } catch (err) {
                        throw 'erreur syntaxe stat ' + formuleStat;
                    }
                break;
            case 15:
                if (ALTabVal.length > 1)
                    try {
                        let Der = this.strEnDouble(ALTabVal[ALTabVal.length - 1].toString());
                        if (!isNaN(Der)) {
                            let avantDer = this.strEnDouble(ALTabVal[ALTabVal.length - 2].toString());
                            if (!isNaN(avantDer)) {
                                if (Der < avantDer) v = -1;
                                else if (Der > avantDer) v = 1;
                                else v = 0;
                            }
                        }
                    } catch (err) {
                        throw 'erreur syntaxe stat ' + formuleStat;
                    }
                break;
            case 5:
            case 17:
                if (ALTabVal.length > 1)
                    try {
                        let moyenne = 0;
                        for (var z = 0; z < ALTabVal.length; z++) {
                            let d = this.strEnDouble(ALTabVal[z]);
                            if (!isNaN(d)) moyenne += d;
                        }
                        moyenne = moyenne / ALTabVal.length;
                        let sommeCarres = 0;
                        for (var z = 0; z < ALTabVal.length; z++) {
                            let d = this.strEnDouble(ALTabVal[z]);
                            if (!isNaN(d)) sommeCarres += Math.pow(d - moyenne, 2);
                        }
                        if (MthdEctN_1) v = sommeCarres / (ALTabVal.length - 1);
                        else v = sommeCarres / ALTabVal.length /*-1*/; //FRQ 7381 SP 12/07/16
                        if (TypStat == 5) v = Math.sqrt(v);
                    } catch (err) {
                        throw 'erreur syntaxe stat ' + formuleStat;
                    }
                else return null;
                break;
            case 18:
                if (ALTabVal.length > 1)
                    try {
                        v = 0;
                        for (var z = 0; z < ALTabVal.length; z++) {
                            let x = this.strEnDouble(ALTabVal[z]);
                            if (!isNaN(x)) v += x;
                        }
                    } catch (err) {
                        throw 'erreur syntaxe stat ' + formuleStat;
                    }
                break;
            case 19: //MOYSI
                let nbrMoy = 0;
                for (var j = 0; j < ALTabVal.length; j++) {
                    try {
                        let frmMoySi = ALTabVal[j].toString() + conditionStat;
                        let interpRes = await this.calculeFormule(frmMoySi, true);
                        if (
                            (TypStat > 9 && interpRes) || //TODO: SP vérifier booléen ?
                            TypStat < 10
                        ) {
                            let x = this.strEnDouble(ALTabVal[j].toString());
                            if (!isNaN(x)) {
                                v = v + x;
                                nbrMoy = nbrMoy + 1;
                            }
                        }
                    } catch (err) {}
                }
                if (nbrMoy > 0) v = v / nbrMoy;
                break;
            default:
                throw 'erreur syntaxe stat ' + formuleStat;
        }
        if (TypStat < 6 || TypStat > 9) return v;
        else {
            switch (TypStat) {
                case 6:
                    return ALTabVal.length;
                case 7:
                    return listVal;
                case 9:
                    if (ALTabVal.length > 0)
                        try {
                            return Convert.ToDateTime(ALTabVal[0]).toString('d'); //TODO: SP Convert.ToDateTime ??
                        } catch (err) {
                            return ALTabVal[0].toString();
                        }
                    break;
                case 8:
                    if (ALTabVal.length > 0)
                        try {
                            return Convert.ToDateTime(ALTabVal[ALTabVal.length - 1]).toString('d'); //TODO: SP Convert.ToDateTime ??
                        } catch (err) {
                            return ALTabVal[ALTabVal.length - 1].toString();
                        }
                    break;
                case 16:
                    if (ALTabVal.length > 0) return '';
                    for (var i = 0; i < ALTabVal.length; i++) {
                        result += ALTabVal[i].toString();
                        if (i < ALTabVal.length - 1) result += ';';
                    }
                    return result;
            }
        }
        //#endregion
        throw 'erreur syntaxe stat ' + formuleStat;
    }

    async getObjetAvecDomaine(obj, domaine) {
        switch (domaine) {
            case 'saisies':
            case 'campagnes':
                return obj;
            case 'siteprod':
                if (obj.entete.siteProducteurId) return await this.getSiteProducteur(obj.entete.siteProducteurId);
                break;
            case 'entites':
                if (obj.entete.pointMesureId) return await this.getPointMesure(obj.entete.pointMesureId);
                break;
        }
        return undefined;
    }

    /* recherche la dernière saisie avant la saisie courante avec mêmes siteprod, type, point de mesure/produit */
    async getLastSaisie(saisie) {
        if (
            saisie.entete.siteProducteurId &&
            saisie.entete.typeId &&
            (saisie.entete.pointMesureId || saisie.entete.produitId) &&
            saisie.entete.date
        ) {
            let parametres = {
                idSiteProd: saisie.entete.siteProducteurId,
                idType: saisie.entete.typeId,
                idPointMesure: saisie.entete.pointMesureId,
                idProduit: saisie.entete.produitId,
                date: saisie.entete.date
            };
            let url = `${__configuration.apiUrl}/massia/prelevements/precedente`;
            let res = await this.$http.get(url, {
                params: {
                    parametres: JSON.stringify(parametres || {})
                }
            });
            let resultat = new Prelevement(res.data, moment, this.dateFormat);

            return resultat;
        }
        return undefined;
    }

    async getPointMesure(ptMesureId) {
        let data = { id: ptMesureId };
        if (data.id) {
            try {
                data.entete = await this.getPointMesureEnteteById(data.id);
                data.caracteristiques = await this.getPointMesureValeursCaracteristiquesById(data.id);
                data.caractAvecCode = true;
            } catch (ex) {
                data = undefined;
            }
        }
        return data;
    }

    async getPointMesureEnteteById(id) {
        let url = `${__configuration.apiUrl}/massia/qse/points-mesure/${id}/entete`;
        let res = await this.$http.get(url);
        return res.data;
    }

    async getPointMesureValeursCaracteristiquesById(id) {
        let url = `${__configuration.apiUrl}/massia/qse/points-mesure/${id}/valeurs-caracteristiques`;
        let res = await this.$http.get(url);
        let result = [];
        res.data.forEach(function (element) {
            let caract = {
                id: element.id,
                code: element.code,
                idNature: element.idNature,
                indice: element.indice,
                label: element.label,
                numericAdditionalInformation: element.numericAdditionalInformation,
                value: element.elementsValues || element.elementValue || element.values || element.value
            };
            result.push(caract);
        });
        return result;
    }

    async getSiteProducteur(siteId) {
        let data = { id: siteId };
        if (data.id) {
            try {
                data.entete = await SitesService.getSiteEnteteById(data.id, 4); //producteur=4
                data.caracteristiques = await SitesService.getSiteValeursCaracteristiquesProducteurById(data.id);
                data.caractAvecCode = true;
                data.niveaux = await SitesService.getSiteValeursNiveauxById(data.id);
            } catch (err) {
                data = undefined;
            }
        }
        return data;
    }

    async getIdCaract(code) {
        try {
            let url = `${__configuration.apiUrl}/massia/caracteristiques/id-by-code/${code}`;
            let res = await this.$http.get(url);

            return res.data;
        } catch (ex) {
            // console.info(ex);
        }
    }

    isDate(str) {
        var formats = this.dateFormat;
        let dt = moment(str, formats, true);
        let isValid = dt.isValid();
        return isValid;
    }

    isEntier(str) {
        try {
            let i = parseInt(str, 10);
            return !isNaN(i);
        } catch (err) {
            return false;
        }
    }

    isEntreGuillemets(str) {
        if (str && typeof str == 'string') return str.startsWith('"') && str.endsWith('"');
        return false;
    }

    strEnDouble(str) {
        if (typeof str == 'string') {
            if (this.isDate(str)) return NaN;

            return parseFloat(str.replace(',', '.'));
        } else if (typeof str == 'number') return str;
        return NaN;
    }

    strEnDate(str) {
        var formats = [moment.ISO_8601, this.dateFormat];
        let dt = moment(str, formats, true);
        return dt;
    }

    strEnInt(str) {
        return parseInt(str, 10);
    }

    interpoler(labels, arrayToInterpol) {
        let xa = 0;
        let xb = Number.parseFloat(labels[labels.length - 1].toString().replace(',', '.'));

        let ya = 0;
        let yb = undefined;

        let iStartInterpolation = undefined;

        for (let t = 0; t < labels.length; t++) {
            if (t === 0 && !arrayToInterpol[t] && arrayToInterpol[t] !== 0) {
                iStartInterpolation = 0;
            }

            if (t > 0 && !arrayToInterpol[t] && arrayToInterpol[t] !== 0 && iStartInterpolation === undefined) {
                xa = Number.parseFloat(labels[t - 1].toString().replace(',', '.'));
                ya = Number.parseFloat(arrayToInterpol[t - 1]);

                iStartInterpolation = t;
            } else if (t > 0 && arrayToInterpol[t] && iStartInterpolation >= 0) {
                xb = Number.parseFloat(labels[t].toString().replace(',', '.'));
                yb = Number.parseFloat(arrayToInterpol[t]);

                for (let i = iStartInterpolation; i <= t; i++) {
                    const x = Number.parseFloat(labels[i].toString().replace(',', '.'));

                    let formule = ((xb - x) / (xb - xa)) * ya;
                    arrayToInterpol[i] = (formule + ((x - xa) / (xb - xa)) * yb).toFixed(2);

                    xa = Number.parseFloat(labels[i].toString().replace(',', '.'));
                    ya = Number.parseFloat(arrayToInterpol[i]);
                }

                iStartInterpolation = undefined;
            }
        }

        if (iStartInterpolation >= 0) {
            for (let i = iStartInterpolation; i < labels.length; i++) {
                arrayToInterpol[i] = '100';
            }
        }

        return arrayToInterpol;
    }

    getRoundedValue(valuesToRound) {
        // On passe par tableau de valeurs
        valuesToRound.forEach((x) => {
            if (typeof x.key === 'undefined' || typeof x.val === 'undefined' || typeof x.rounder === 'undefined') {
                return valuesToRound.length > 1 ? valuesToRound : valuesToRound[0].val;
            } else if (
                !isNaN(this.FormulesService.strEnDouble(x.val)) &&
                !isNaN(this.FormulesService.strEnDouble(x.key)) &&
                !isNaN(this.FormulesService.strEnDouble(x.rounder))
            ) {
                let key = parseFloat(x.key);
                if (x.rounder == 0) x.rounder = 0.01;
                let rounder = x.rounder !== null ? parseFloat(x.rounder) : null;
                let nbDecToRound = 0;
                if (rounder !== null && rounder % 1 !== 0) {
                    if (x.rounder.toString().includes('e')) {
                        nbDecToRound = parseFloat(x.rounder.toString().slice(-1));
                    } else {
                        nbDecToRound = x.rounder.toString().substring(x.rounder.toString().indexOf('.') + 1).length;
                    }
                }
                if (rounder !== 0 && rounder !== null) {
                    let multiplicator = Math.floor(key / rounder);
                    let lowLimit = (rounder * multiplicator).toFixed(nbDecToRound);
                    let highLimit = (rounder * (multiplicator + 1)).toFixed(nbDecToRound);
                    if (key - lowLimit >= highLimit - key) {
                        x.val = highLimit;
                    } else {
                        x.val = lowLimit;
                    }
                } else {
                    x.val = x.key.toString();
                }
                if (rounder !== 0 && rounder !== null) {
                    let multiplicator = Math.floor(key / rounder);
                    let lowLimit = (rounder * multiplicator).toFixed(nbDecToRound);
                    let highLimit = (rounder * (multiplicator + 1)).toFixed(nbDecToRound);
                    if (key - lowLimit >= highLimit - key) {
                        x.val = highLimit;
                    } else {
                        x.val = lowLimit;
                    }
                } else {
                    x.val = x.key.toString();
                }
            } else {
                x.val = null;
            }
        });
        if (valuesToRound.length > 1) {
            return valuesToRound;
        } else {
            return valuesToRound[0].val;
        }
    }

    formatDecSupp(rounder, decSupp) {
        let newRounder = null;
        if (
            decSupp % 1 !== 0 ||
            isNaN(rounder) ||
            isNaN(decSupp) ||
            rounder === null ||
            (typeof rounder === 'string' && rounder === '') ||
            decSupp === null ||
            (typeof decSupp === 'string' && decSupp === '')
        ) {
            return (newRounder = rounder);
        } else {
            if (rounder !== null && typeof rounder === 'string') rounder = parseFloat(rounder);
            if (decSupp !== null && typeof decSupp === 'string') decSupp = parseFloat(decSupp);

            const negative = Math.sign(decSupp) === -1;

            if (negative) {
                decSupp = Math.abs(decSupp);
            }
            if (rounder % 1 === 0) {
                newRounder = negative ? rounder * Math.pow(10, decSupp) : rounder / Math.pow(10, decSupp);
            } else {
                newRounder = negative ? rounder * Math.pow(10, decSupp) : rounder / Math.pow(10, decSupp);
            }
            return newRounder;
        }
    }

    /**
     * Extrapolation logarithmique: calcul la valeur théorique du % de passant à une ouverture de tamis donnée en fonction du tamis precedent et suivant
     * @param t1 objet correpondant au tamis précedent (ouverture + pourcentage de passant)
     * @param t2 objet correpondant au tamis suivant (ouverture + pourcentage de passant)
     * @param D ouverture de tamis pour lequel chercher la valeur de % de passant
     * @returns le % de passant théorique
     */
    extrapolationLN(t1, t2, D) {
        const d1 = Number(t1.ouverture.toString().replace(',', '.'));
        const d2 = Number(t2.ouverture.toString().replace(',', '.'));
        const v1 = Number(t1.pourcPassant);
        const v2 = Number(t2.pourcPassant);
        D = Number(D.toString().replace(',', '.'));
        let y = (v1 * Math.log(d2 / D) + v2 * Math.log(D / d1)) / (Math.log(D / d1) + Math.log(d2 / D));
        return y;
    }
}
