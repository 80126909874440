import MiniFormSignatureController from './mini.form.signature.controller';

export default {
    bindings: {
        personne: '=',
        selectNewSignature: '&'
    },
    template: require('./mini.form.signature.html'),
    controller: MiniFormSignatureController
};
