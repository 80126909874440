import ProduitGenerationNormeEnteteController from './produit.generation.norme.entete.controller';

export default {
    bindings: {
        produit: '=',
        form: '=',
        onUpdate: '&',
        code: '=',
        libelle: '=',
        appelation: '='
    },
    template: require('./produit.generation.norme.entete.html'),
    controller: ProduitGenerationNormeEnteteController
};
