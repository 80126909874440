const typesTareByValue = {
    0: 'jour',
    1: 'systematique',
    2: 'aucune'
};

const typesTareValuesByName = {
    jour: 0,
    systematique: 1,
    aucune: 2
};

export function getTypeTareNamesByValues() {
    return angular.copy(typesTareByValue);
}

export function getTypeTareNamesWithValues() {
    return angular.copy(typesTareValuesByName);
}
