import ModaleUpdateComptantController from './modale.update.comptant.controller';

export default {
    bindings: {
        resolve: '=',
        modalInstance: '<'
    },
    template: require('./modale.update.comptant.html'),
    controller: ModaleUpdateComptantController
};
