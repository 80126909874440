import SiteCoordonneesController from './site.coordonnees.controller';

export default {
    bindings: {
        site: '<',
        form: '=',
        onUpdate: '&'
    },
    template: require('./site.coordonnees.html'),
    controller: SiteCoordonneesController
};
