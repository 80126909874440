import validatorBuilder from 'validator-builder';
import DapEntete from '../dap-entete/dap.entete.model';

let dapValidator = null;

export default class DAP {
    constructor(data = {}) {
        this.id = data.id;
        this.entete = new DapEntete(data.entete);
    }

    isValid() {
        dapValidator = dapValidator || validatorBuilder.getInstanceFor('DAP');
        const validationResults = dapValidator.validate(this);
        return validationResults.isValid;
    }
}
