export default class ProduitFormAssocieController {
	static $inject = ['$scope', 'ProduitsService', 'notification', 'AssociesService'];

	constructor($scope, ProduitsService, notification, AssociesService) {
		this.$scope = $scope;
		this.ProduitsService = ProduitsService;
		this.notification = notification;
		this._ = _;
		this.allAssocies = [];
		this.AssociesService = AssociesService;
		this.associes = [];
		this.siteLinked = [];
		this.producteurs = [];
		this.sitesCom = [];
		this.tmp = {
			associes: [],
			producteur: null,
			siteCom: null
		};
	}

	$onInit = async () => {
		this.unregister = [];
		this.unregister.push(
			this.$scope.$watch(
				() => this.isOpen,
				async () => {
					if (this.isOpen) {
						await this.loadData();
					}
				},
				true
			)
		);
		this.unregister.push(
			this.$scope.$watch(
				() => this.produit.siteLinked,
				async () => {
					await this.manageSites();
				},
				true
			)
		);
	};

	$onDestroy = () => {
		for (let i = 0; i < this.unregister.length; i++) {
			this.unregister[i]();
		}
	};

	async loadData() {
		this.startLoading();
		try {
			this.associes = await this.ProduitsService.getAssocies();
			await this.manageSites();
		} catch (ex) {
			this.notification.error(ex);
		} finally {
			this.stopLoading();
		}
	}

	async ajouterAssociesProduit() {
		this.tmp.associes = this.selectedAssocies;
		this.tmp.producteur = this.selectedProducteur[0];
		this.tmp.siteCom = this.selectedSiteCom[0];
		this.setAssociesProduit(angular.copy(this.tmp));
		this.tmp.associes = [];
		this.tmp.producteur = null;
		this.tmp.siteCom = null;

		this.associes = this.associes.map((x) => {
			return {
				...x,
				selected: false
			};
		});
		await this.manageSites();
	}

	async manageSites() {
		this.siteLinked = this.produit.siteLinked;
		if (this.siteLinked && this.siteLinked.length > 0) {
			this.rappel = false;
			this.producteurs = [];
			this.siteLinked.forEach((x) => {
				if (this.producteurs.findIndex((p) => p.id == x.producteur.id) == -1) {
					this.producteurs.push({
						id: x.producteur.id,
						code: x.producteur.code,
						libelle: x.producteur.libelle,
						selected: false
					});
				}
			});
			this.selectSitesCom(this.siteLinked);
		} else {
			if (!this.rappel) {
				this.rappel = true;
				this.siteLinked = await this.ProduitsService.getProduitSiteLies(this.produit.id);
				this.produit.siteLinked = this.siteLinked;
			}
		}
	}

	// filtre les sites com en fonction des sites liés et de la selection d'un producteur
	filtreSiteCom(data) {
		if (data && this.selectedSiteCom.length == 0) {
			const tmp = this.siteLinked.filter((x) => x.producteur.id == data.id);
			this.selectSitesCom(tmp);
		}
	}

	// filtre les producteurs en fonction des sites liés et de la selection d'un site com
	filtreProducteurs(data) {
		if (data && this.selectedProducteur.length == 0) {
			const producteurEntree = this.siteLinked.map((x) => {
				if (x.entree.findIndex((x) => x.id == data.id) == -1) {
					return {
						...x.producteur,
						selected: false
					};
				}
			});
			const producteurSortie = this.siteLinked.map((x) => {
				if (x.sortie.findIndex((x) => x.id == data.id) == -1) {
					return {
						...x.producteur,
						selected: false
					};
				}
			});
			this.producteurs = this._.unionBy(producteurEntree, producteurSortie, 'id');
		}
	}

	//liste → liste de siteLies
	selectSitesCom(liste) {
		this.sitesCom = [];
		for (let i = 0; i < liste.length; i++) {
			const link = liste[i];
			const tmp = new Set([...link.entree, ...link.sortie]);
			tmp.forEach((x) => {
				if (this.sitesCom.findIndex((s) => s.id == x) == -1) {
					this.sitesCom.push({
						id: x.id,
						code: x.code,
						libelle: x.libelle,
						selected: false
					});
				}
			});
		}
	}

	setAssociesProduit(item) {
		if (item.associes.length > 1) {
			for (let i = 0; i < item.associes.length; i++) {
				this.addAssocie(item, i);
			}
		} else {
			this.addAssocie(item, 0);
		}
	}

	addAssocie(item, i) {
		const associeProduit = {
			id: -1,
			associe: item.associes[i],
			siteProducteur: item.producteur,
			siteCommercial: item.siteCom
		};
		if (this.checkUniq(associeProduit)) {
			this.produit.associes.push(associeProduit);
			this._.sortBy(this.produit.associes, 'associe.libelle');
		} else {
			this.notification.info(
				`un lien est déjà défini pour l'associé ${associeProduit.associe.libelle}, le producteur ${associeProduit.siteProducteur.libelle} et le site commercial ${associeProduit.siteCommercial.libelle}`
			);
		}
	}

	checkUniq(item) {
		if (this.produit.associes != null && this.produit.associes.length > 0) {
			const idx = this.produit.associes.findIndex(
				(x) =>
					x.associe.id == item.associe.id && x.siteProducteur.id == item.siteProducteur.id && x.siteCommercial.id == item.siteCommercial.id
			);
			return idx == -1;
		}
		return true;
	}

	deleteLink(item, index) {
		this.loading = true;
		this.produit.associes.splice(index, 1);
		this.loading = false;
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
