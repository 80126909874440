export enum FilterType {
    CONTAINS = 'Contains',
    EQUALS = 'Eq',
    NOT_EQUALS = 'Neq',
    GREATER_THAN = 'Gt',
    LESS_THAN = 'Lt',
    GREATER_THAN_OR_EQUALS = 'Ge',
    LESS_THAN_OR_EQUALS = 'Le',
    IS_IN = 'In',
    START_WITH = 'StartsWith',
    END_WITH = 'EndsWith',
}
export interface FilterProperty {
    criterion: string;
    value: any;
    type: FilterType;
}
