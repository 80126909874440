export enum SiteGenre {
    Utilisateur = 1 << 0,
    Client = 1 << 1,
    Producteur = 1 << 2,
    Fournisseur = 1 << 3,
    Transporteur = 1 << 4,
    Laboratoire = 1 << 5,
    Proprietaire = 1 << 6,
    Locataire = 1 << 7,
    Auditeur = 1 << 8
}
