import { IAugmentedJQuery, IScope, ITranscludeFunction } from 'angular';
import { Directive, OnInit } from '../../../core/decorators';

@Directive({
    selector: '[inject]',
})
export class MassiaInjectDirective implements OnInit {
    /* @ngInject */
    constructor(public $scope: IScope, public $element: IAugmentedJQuery, public $transclude: ITranscludeFunction) {}

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        if (!this.$transclude) {
            throw new Error(
                'ngTransclude orphan Illegal use of ngTransclude directive in the template! No parent directive that requires a transclusion found. Element: {0}' +
                    this.$element
            );
        }
        var innerScope = this.$scope.$new();
        this.$transclude(innerScope, (clone) => {
            this.$element.empty();
            this.$element.append(clone);
            this.$element.on('$destroy', function () {
                innerScope.$destroy();
            });
        });
    }
}
