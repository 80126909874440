import * as _ from 'angular-translate';
import date from '../../../synthese/components/synthese/components/synthese-form-modal/components/synthese-bloc-selection/bloc-selection-critere-form-valeur/templates/date';

export default class FacteurEmissionCo2Controller {
    /*@ngInject*/
    constructor(
        $scope,
        $state,
        $stateParams,
        $translate,
        ModalService,
        FacteurEmissionCo2Service,
        FamillesService,
        TransportService,
        _,
        moment,
        globalizationManagementService,
        $uibModal,
        notification
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.ModalService = ModalService;
        this.FacteurEmissionCo2Service = FacteurEmissionCo2Service;
        this.FamillesService = FamillesService;
        this._ = _;
        this.addFactEmiFamily = false;
        this.familySelected = [];
        this.moment = moment;
        this.$uibModal = $uibModal;
        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
        this.notification = notification;
        this.TransportService = TransportService;
        this.dateValidite = null;
        this.defaultIdsFamilyProduct = [1, 2, 3, 6, 7, 10];
        this.listFamilyFiltered = [];
        this.listFamilyDisplayed = [];
    }

    async $onInit() {
        await this.load();
    }

    async load() {
        this.list = await this.FacteurEmissionCo2Service.getAllFacteursEmissionCo2();
        this.listEnabledValues = await this.selectEnabledValues();
        this.listDisabledValues = await this.selectEnabledValues(true);
        this.listBdd = this._.cloneDeep(this.list.data);
        this.listFormatted = await this.formatList(this.listEnabledValues);
        this.listFamily = await this.FamillesService.getFamillesFiltrees();
        this.listFamilyFiltered = this.getFilteredFamily(this.listFamily, this.listFormatted);
        this.listFamilyByDefault = this.getFilteredFamily(this.listFamily, this.listFormatted, true);
        this.dateValidite = await this.getValidityDate();
        this.transports = await this.TransportService.getAllTransports();
        this.isEnabledTransport = await this.getIsEnabledTransports();
    }

    async selectEnabledValues(disabled = false) {
        let listEnabledValues = [];
        if (this.list && this.list.data) {
            this._.filter(this.list.data, (o) => {
                let key = this._.findKey(this.list.data, (k) => {
                    return k === o;
                });
                let values = [];
                this._.filter(o, function (f) {
                    if (!disabled && !f.dateArchivage) {
                        listEnabledValues.push(f);
                    } else if (disabled && f.dateArchivage) {
                        values.push(f);
                    }
                });
                if (disabled && values.length > 0) {
                    let dateVal = this.moment(new Date(values[0].dateValidite), this.dateFormat).format(this.dateFormat);
                    let dateArch = this.moment(new Date(values[0].dateArchivage), this.dateFormat).format(this.dateFormat);
                    listEnabledValues.push({
                        libelle: 'Date de validité : ' + dateVal + ' - Date Archivage : ' + dateArch,
                        values: values
                    });
                }
            });
        }
        return listEnabledValues;
    }

    async manageListArchives() {
        if (this.archiveSelected && this.archiveSelected.length > 0) {
            this.listFormatted = await this.formatList(this.archiveSelected[0].values, true);
            this.dateValidite = await this.getValidityDate();
        } else {
            await this.load();
        }
    }

    async getIsEnabledTransports() {
        let isEnabledTransport = this._.filter(this.transports, function (o) {
            return o.isEnabled === true;
        });
        return isEnabledTransport;
    }
    async getValidityDate() {
        if (this.listFormatted && this.listFormatted.length > 0) {
            const date = new Date(this.listFormatted[0].dateValidite);
            return this.moment(date, this.dateFormat);
        } else {
            return null;
        }
    }

    async formatList(list, isArchive = false) {
        const listFormatted = [];
        let count = 0;
        if (list && list.length > 0) {
            list.forEach((element) => {
                if ((!isArchive && element.transport.isEnabled) || isArchive) {
                    const idx = listFormatted.findIndex((x) => x.idFamille === element.famille.id);
                    if (idx >= 0) {
                        listFormatted[idx].listFactEmi.push({
                            idFactEmi: element.id,
                            idFamille: element.famille.id,
                            idTransport: element.transport.id,
                            codeTransport: element.transport.code,
                            isEnabledTransport: element.transport.isEnabled,
                            libelleTransport: element.transport.libelle,
                            valeur: element.valeur
                        });
                    } else {
                        listFormatted.push({
                            isArchive: isArchive ? true : false,
                            id: count,
                            idFamille: element.famille.id,
                            codeFamille: element.famille.code,
                            libelleFamille: element.famille.libelle,
                            dateValidite: element.dateValidite,
                            dateArchivage: element.dateArchivage,
                            listFactEmi: [
                                {
                                    idFactEmi: element.id,
                                    idFamille: element.famille.id,
                                    idTransport: element.transport.id,
                                    codeTransport: element.transport.code,
                                    isEnabledTransport: element.transport.isEnabled,
                                    libelleTransport: element.transport.libelle,
                                    valeur: element.valeur
                                }
                            ]
                        });
                        count++;
                    }
                }
            });
        } else {
            this.addFactEmiFamily = true;
        }
        return listFormatted;
    }

    getFilteredFamily(listFamily, listFactEmi, forNewColumn = false) {
        const defaultIdsFamilyProduct = this.defaultIdsFamilyProduct;
        let listFamilyFiltered = [];
        if (forNewColumn) {
            listFamilyFiltered = this._.filter(listFamily, function (p) {
                if (defaultIdsFamilyProduct.find((x) => x == p.idFamille)) {
                    return p;
                }
            });
        } else {
            listFamilyFiltered = this._.filter(listFamily, function (p) {
                if (!listFactEmi.find((x) => x.idFamille == p.idFamille)) {
                    return p;
                }
            });
        }
        return listFamilyFiltered;
    }

    addFamily() {
        this.addFactEmiFamily = true;
    }

    async deleteFamily(idFamille) {
        await this.FacteurEmissionCo2Service.deleteFacteursEmissionCo2ByIdFamille(idFamille);
        await this.load();
    }

    addNewLine() {
        if (this.listFormatted && this.listFormatted.length > 0) {
            this.addFactEmiFamily = false;
            const lengthTab = this.listFormatted.length;
            const listFactEmi = angular.copy(this.listFormatted[0].listFactEmi);
            this.listFormatted.push({
                id: lengthTab,
                idFamille: this.familySelected[0].idFamille,
                codeFamille: this.familySelected[0].code,
                libelleFamille: this.familySelected[0].nom,
                date: this.checkDate(),
                listFactEmi: []
            });
            const newListFactEmi = [];
            listFactEmi.forEach((facteur) => {
                newListFactEmi.push({
                    idFactEmi: 0,
                    idFamille: this.familySelected[0].idFamille,
                    idTransport: facteur.idTransport,
                    codeTransport: facteur.codeTransport,
                    isEnabledTransport: facteur.isEnabledTransport,
                    libelleTransport: facteur.libelleTransport,
                    valeur: 0
                });
            });
            this._.last(this.listFormatted).listFactEmi = newListFactEmi;
            this.unselectFamilySelected();

            delete this.listFamilyFiltered.find((x) => x.selected).selected;
        } else {
            if (this.transports && this.transports.length > 0) {
                this.addFactEmiFamily = false;
                this.listFormatted.push({
                    id: 0,
                    idFamille: this.familySelected[0].idFamille,
                    codeFamille: this.familySelected[0].code,
                    libelleFamille: this.familySelected[0].nom,
                    date: this.moment(new Date(), this.dateFormat),
                    listFactEmi: []
                });
                this.transports.forEach((transport) => {
                    this.listFormatted[0].listFactEmi.push({
                        idFactEmi: 0,
                        idFamille: this.familySelected[0].idFamille,
                        idTransport: transport.id,
                        codeTransport: transport.code,
                        isEnabledTransport: transport.isEnabled,
                        libelleTransport: transport.libelle,
                        valeur: 0
                    });
                });
                this.unselectFamilySelected();
            } else {
                this.notification.error(this.$translate.instant('FACTEUR-EMISSION-CO2.TRANSPORT.EMPTY_TRANSPORTS'));
            }
        }
    }

    unselectFamilySelected() {
        this._.filter(this.listFamilyFiltered, (x) => {
            if (x.selected === true) x.selected = false;
        });
    }

    checkDate() {
        if (!this.dateValidite && this.listFormatted.length <= 0) {
            this.notification.error(this.$translate.instant('FACTEUR-EMISSION-CO2.TRANSPORT.VALIDITY_DATE_ERROR'));
        } else if (this.dateValidite && this.dateValidite !== null) {
            return this.moment(this.dateValidite, this.dateFormat);
        } else {
            return this.moment(new Date(), this.dateFormat);
        }
    }

    async addNewColumn() {
        if (!this.dateValidite && this.listFormatted.length <= 0) {
            this.notification.error(this.$translate.instant('FACTEUR-EMISSION-CO2.TRANSPORT.VALIDITY_DATE_ERROR'));
        } else {
            const lstFamilyProduct = this.listFamilyByDefault;
            const dateValidite = this.dateValidite;
            const dateForTransport =
                this.listFormatted && this.listFormatted.length > 0
                    ? this.listFormatted[0].dateValidite
                    : this.moment(this.dateValidite, this.dateFormat);
            let result = null;
            const modalInstance = this.$uibModal.open({
                component: 'addTransportModal',
                resolve: {},
                size: 'xs',
                backdrop: true
            });
            result = await modalInstance.result;
            if (result.isOk) {
                if (result.codeTransport && result.codeTransport !== '' && result.libelleTransport && result.libelleTransport !== '') {
                    try {
                        const res = await this.TransportService.updateTransport([
                            {
                                id: 0,
                                code: result.codeTransport,
                                libelle: result.libelleTransport,
                                date: dateForTransport,
                                IsEnabled: true
                            }
                        ]);
                        const transport = res.data[0];
                        if (this.listFormatted && this.listFormatted.length > 0) {
                            this.listFormatted.forEach((element) => {
                                element.listFactEmi.push({
                                    idFactEmi: element.id,
                                    idFamille: element.idFamille,
                                    idTransport: transport.id,
                                    codeTransport: transport.code,
                                    isEnabledTransport: transport.isEnabled,
                                    libelleTransport: transport.libelle,
                                    valeur: 0
                                });
                            });
                        } else {
                            const lengthTab = this.listFormatted ? this.listFormatted.length : 0;
                            let count = lengthTab;
                            lstFamilyProduct.forEach((famille) => {
                                this.listFormatted.push({
                                    id: lengthTab,
                                    idFamille: famille.idFamille,
                                    codeFamille: famille.code,
                                    libelleFamille: famille.nom,
                                    dateValidite: dateValidite,
                                    dateArchivage: null,
                                    listFactEmi: [
                                        {
                                            idFactEmi: 0,
                                            idFamille: famille.idFamille,
                                            idTransport: transport.id,
                                            codeTransport: transport.code,
                                            isEnabledTransport: transport.isEnabled,
                                            libelleTransport: transport.libelle,
                                            valeur: 0
                                        }
                                    ]
                                });
                                count++;
                            });
                        }
                        await this.appliquer(false);

                        this.notification.success(this.$translate.instant('FACTEUR-EMISSION-CO2.MODAL.SUCCESS'));
                    } catch (error) {
                        this.notification.error(this.$translate.instant(error.data));
                    }
                } else {
                    this.notification.error(this.$translate.instant('FACTEUR-EMISSION-CO2.MODAL.LENGTH_ERROR'));
                }
            }
        }
    }

    async rmvColumn(id) {
        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('FACTEUR-EMISSION-CO2.MODAL.DELETE_TRANSPORT.TITLE'),
            modalMsg: this.$translate.instant('FACTEUR-EMISSION-CO2.MODAL.DELETE_TRANSPORT.MESSAGE'),
            headerClass: 'modal-danger'
        });

        modalInstance.result.then(async () => {
            try {
                const res = await this.TransportService.disabledTransport(id);
                this.notification.success(this.$translate.instant('FACTEUR-EMISSION-CO2.TRANSPORT.DISABLED'));
                await this.load();
            } catch (ex) {
                this.notification.info(this.$translate.instant('FACTEUR-EMISSION-CO2.TRANSPORT.KEPT'));
            }
        });
    }

    async appliquer(notificationOn = true) {
        if (!this.dateValidite || this.dateValidite == null) {
            this.notification.error(this.$translate.instant('FACTEUR-EMISSION-CO2.TRANSPORT.VALIDITY_DATE_ERROR'));
        } else {
            try {
                let res = await this.formatForSave(this.listFormatted);
                await this.FacteurEmissionCo2Service.updateFacteursEmissionCo2(res);
                if (notificationOn) {
                    this.notification.success(this.$translate.instant('FACTEUR-EMISSION-CO2.UPDATED'));
                }
                await this.load();
            } catch (error) {
                this.notification.error(error.data);
            }
        }
    }

    async formatForSave(lines, isArchived = false) {
        const linesToSave = [];
        const dateValidite = this.checkDate();
        let dateArchivage = new Date();
        dateArchivage = this.moment(dateArchivage, this.dateFormat);
        this._.forEach(lines, function (line) {
            const listFactEmi = line.listFactEmi;
            for (let index = 0; index < listFactEmi.length; index++) {
                const temp = listFactEmi[index];
                linesToSave.push({
                    id: temp.idFactEmi,
                    famille: {
                        id: temp.idFamille
                    },
                    transport: {
                        id: temp.idTransport
                    },
                    dateValidite: dateValidite,
                    dateArchivage: isArchived ? dateArchivage : null,
                    valeur: temp.valeur
                });
            }
        });
        return linesToSave;
    }

    async deleteAll() {
        if (this.listFormatted && this.listFormatted.length > 0) {
            const date = new Date(this.listFormatted[0].dateValidite);
            const modalInstance = this.ModalService.confirm({
                modalTitle: this.$translate.instant('FACTEUR-EMISSION-CO2.FACTORS.MODAL_TITLE'),
                modalMsg:
                    this.$translate.instant('FACTEUR-EMISSION-CO2.FACTORS.MODAL_MESSAGE') +
                    this.moment(date, this.dateFormat).format(this.dateFormat),
                headerClass: 'modal-danger'
            });
            modalInstance.result.then(async () => {
                try {
                    const listIdsFacts = await this.getListIdsToDelete();
                    const res = await this.FacteurEmissionCo2Service.deleteAllFacteursEmissionCo2ByIds(listIdsFacts);
                    this.notification.success(this.$translate.instant('FACTEUR-EMISSION-CO2.FACTORS.ALL_DELETED'));
                    await this.load();
                } catch (ex) {
                    this.notification.info(this.$translate.instant('FACTEUR-EMISSION-CO2.FACTORS.ALL_KEPT'));
                }
            });
        }
    }

    async getListIdsToDelete() {
        let listIds = [];
        if (this.listFormatted && this.listFormatted.length > 0) {
            this._.forEach(this.listFormatted, (l) => {
                this._.forEach(l.listFactEmi, (e) => {
                    listIds.push({ idFactEmi: e.idFactEmi });
                });
            });
        }
        listIds = this._.map(listIds, 'idFactEmi');
        return listIds;
    }

    async archiveAll() {
        if (this.listFormatted && this.listFormatted.length > 0) {
            let res = await this.formatForSave(this.listFormatted, true);
            await this.FacteurEmissionCo2Service.updateFacteursEmissionCo2(res);
            if (res.length > 0) {
                this.notification.success(this.$translate.instant('FACTEUR-EMISSION-CO2.ARCHIVE.SUCCESS'));
            }
            await this.load();
        }
    }

    async annuler() {
        await this.load();
    }

    startLoading() {
        vm.loading = true;
    }

    stopLoading() {
        vm.loading = false;
    }
}
