import MonitoringConfig from './_config/monitoring.config';
import MonitoringRoutes from './_config/monitoring.routes';
import MonitoringComponent from './components/monitoring';
import MonitoringDetailComponent from './components/monitoring-detail';
import MonitoringService from './services/monitoring.service';

const moduleName = 'app.massia.common.monitoring';

angular
    .module(moduleName, [])
    .config(MonitoringConfig)
    .config(MonitoringRoutes)
    .service('MonitoringService', MonitoringService)
    .component('monitoring', MonitoringComponent)
    .component('monitoringDetail', MonitoringDetailComponent);

export default moduleName;
