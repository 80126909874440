export default function UserRoute($stateProvider) {
    $stateProvider
        .state('portail.users', {
            url: '/user',
            abstract: true,
            template: '<ui-view></ui-view>',
            ncyBreadcrumb: {
                skip: true
                //label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}',
            }
        })
        .state('portail.users.list', {
            url: '',
            rights: { domain: 'portail', right: 'read' },
            template: '<portail-user-list></portail-user-list>',
            ncyBreadcrumb: {
                skip: true
                //label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}',
            },
            search: 'PORTAIL.BREADCRUMBS.USERS'
        })
        .state('portail.users.add', {
            url: '/add',
            rights: { domain: 'portail', right: 'create' },
            template: '<portail-user-add></portail-user-add>',
            ncyBreadcrumb: {
                skip: true,
                label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}'
            },
            search: 'PORTAIL.BREADCRUMBS.USERS_NEW'
        })
        .state('portail.users.edit', {
            url: '/:id/edit',
            rights: { domain: 'portail', right: 'update' },
            template: '<portail-user-add></portail-user-add>',
            params: {
                obj: null
            },
            ncyBreadcrumb: {
                skip: true,
                label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}'
            },
            search: 'PORTAIL.BREADCRUMBS.USERS_NEW'
        });
}

UserRoute.$inject = ['$stateProvider'];
