import SyntheseEntitiesConfig from './_config/syntheseentities.config';
import SyntheseEntitiesRoutes from './_config/syntheseentities.routes';
import SyntheseEntitiesComponent from './components/syntheseentities';
import SyntheseEntitiesService from './services/syntheseentities.service';
import SyntheseEntitiesCdcService from './services/syntheseentities.cdc.service';
import SyntheseEntitiesCommunicationService from './services/syntheseentities.communication.service';
import PrintConfigService from './services/print-config.service';

import SyntheseEntitiesAffichageComponent from './components/syntheseentities-form/syntheseentities-affichage';
import SyntheseEntitiesControleComponent from './components/syntheseentities-form/syntheseentities-controle';
import SyntheseEntitiesEssaisComponent from './components/syntheseentities-form/syntheseentities-essais';
import SyntheseEntitiesExclusionComponent from './components/syntheseentities-form/syntheseentities-exclusion';
import SyntheseEntitiesFTPComponent from './components/syntheseentities-form/syntheseentities-ftp';
import SyntheseEntitiesSelectionComponent from './components/syntheseentities-form/syntheseentities-selection';
import SyntheseEntitiesTraitementComponent from './components/syntheseentities-form/syntheseentities-traitement';
import SyntheseEntitiesCarteDeControleComponent from './components/syntheseentities-form/syntheseentities-cartedecontrole';
import FtpPrintConfigComponent from './components/syntheseentities-result/ftp-print-config';

import SyntheseEntitiesFormComponent from './components/syntheseentities-form';
import SyntheseEntityValidator from './components/syntheseentities-form/syntheseentities.validator';
import SyntheseEntityDetailComponent from './components/syntheseentity-detail';
import SyntheseEntitiesResultComponent from './components/syntheseentities-result';
import SyntheseEntitiesRecompositionComponent from './components/syntheseentities-recomposition';
import SyntheseEntitiesConsistanceComponent from './components/syntheseentities-consistance';
import SyntheseEntitiesResistanceComponent from './components/syntheseentities-resistance';
import SyntheseEntitiesResistanceTabResumeComponent from './components/syntheseentities-resistance/syntheseentities-resistance-tabresume';
import SyntheseEntitiesResistanceTabDetailsComponent from './components/syntheseentities-resistance/syntheseentities-resistance-tabdetails';
import ArchivageFtpModalComponent from './components/archivage-ftp-modal';
import FuseauRegulariteModalComponent from './components/fuseau-regularite-modal';
import SyntheseEntitiesCtrlAcquereurComponent from './components/syntheseentities-ctrlacquereur';
import SyntheseEntitiesCtrlEnrobesComponent from './components/syntheseentities-ctrlenrobes';
import SyntheseEntitiesDecompoComponent from './components/syntheseentities-decompo';
import SyntheseEntitiesCdcComponent from './components/syntheseentities-cdc';
import ModificationTraceModalComponent from './components/syntheseentities-cdc/modification-trace-modal';
import Rvmodal from './components/syntheseentities-resistance/edit-rv-modal';
import SyntheseEntitiesTraitements from './components/syntheseentities-traitements';

const moduleName = 'app.massia.common.syntheseentities';

angular
    .module(moduleName, [])
    .config(SyntheseEntitiesConfig)
    .config(SyntheseEntitiesRoutes)
    .service('SyntheseEntitiesCdcService', SyntheseEntitiesCdcService)
    .service('SyntheseEntitiesService', SyntheseEntitiesService)
    .service('SyntheseEntitiesCommunicationService', SyntheseEntitiesCommunicationService)
    .service('PrintConfigService', PrintConfigService)
    .component('syntheseentities', SyntheseEntitiesComponent)
    .component('syntheseentitiesAffichage', SyntheseEntitiesAffichageComponent)
    .component('syntheseentitiesControle', SyntheseEntitiesControleComponent)
    .component('syntheseentitiesEssais', SyntheseEntitiesEssaisComponent)
    .component('syntheseentitiesExclusion', SyntheseEntitiesExclusionComponent)
    .component('syntheseentitiesFtp', SyntheseEntitiesFTPComponent)
    .component('syntheseentitiesSelection', SyntheseEntitiesSelectionComponent)
    .component('syntheseentitiesTraitement', SyntheseEntitiesTraitementComponent)
    .component('syntheseentitiesForm', SyntheseEntitiesFormComponent)
    .component('syntheseentityDetail', SyntheseEntityDetailComponent)
    .component('syntheseentitiesResult', SyntheseEntitiesResultComponent)
    .component('syntheseentitiesRecomposition', SyntheseEntitiesRecompositionComponent)
    .component('syntheseentitiesConsistance', SyntheseEntitiesConsistanceComponent)
    .component('syntheseentitiesResistance', SyntheseEntitiesResistanceComponent)
    .component('syntheseentitiesResistanceTabresume', SyntheseEntitiesResistanceTabResumeComponent)
    .component('syntheseentitiesResistanceTabdetails', SyntheseEntitiesResistanceTabDetailsComponent)
    .component('archivageFtpModal', ArchivageFtpModalComponent)
    .component('fuseauRegulariteModal', FuseauRegulariteModalComponent)
    .component('syntheseentitiesCtrlacquereur', SyntheseEntitiesCtrlAcquereurComponent)
    .component('syntheseentitiesCtrlenrobes', SyntheseEntitiesCtrlEnrobesComponent)
    .component('syntheseentitiesDecompo', SyntheseEntitiesDecompoComponent)
    .component('syntheseentitiesCdc', SyntheseEntitiesCdcComponent)
    .component('syntheseentitiesCartedecontrole', SyntheseEntitiesCarteDeControleComponent)
    .component('modificationTraceModal', ModificationTraceModalComponent)
    .component('rvmodal', Rvmodal)
    .component('ftpPrintConfig', FtpPrintConfigComponent)
    .component('syntheseentitiesTraitements', SyntheseEntitiesTraitements)
    .factory('SyntheseEntityValidator', SyntheseEntityValidator);

export default moduleName;
