import TvaConfigurationController from './tva.configuration.controller';

export default {
    bindings: {
        id: '=',
        type: '='
    },
    template: require('./tva.configuration.html'),
    controller: TvaConfigurationController
};
