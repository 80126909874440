NormesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'NormesService',
    'notification',
    'MassiaApplicationService',
    '$transitions',
    'MOPService'
];

export default function NormesController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    NormesService,
    notification,
    MassiaApplicationService,
    $transitions,
    MOPService
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    vm.selectedNormeId = undefined;
    vm.lastSelected = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.normes = [];
    vm.normesSpec = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];
    vm.getTypes = getTypes;

    vm.selectNorme = selectNorme;
    vm.isNormeSelected = isNormeSelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadNormes = loadNormes;
    vm.deleteNorme = deleteNorme;
    vm.setSelected = setSelected;
    vm.eraseAllFilters = eraseAllFilters;
    vm.getClasses = getClasses;
    vm.getApplication = getApplication;
    vm.MOPService = MOPService;

    function getClasses(booleen, array1, array2) {
        if (booleen) {
            return array1;
        }
        return array2;
    }

    function init() {
        vm.MOPService.setMop([{ title: 'NORMES.BREADCRUMBS.NORMES_LIST', filename: 'Normes.pdf', application: 'laboratoire' }]);
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }

        getTypes();
        getNormesSpec();
    }

    init();

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    async function getNormesSpec() {
        return (vm.normesSpec = await NormesService.getNormeSpecList(''));
    }

    function stateChangeSuccessFunc(event, toState) {
        if (!$state.params.id) {
            vm.setSelected();
        } else {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    async function getTypes(event, toState) {
        // vm.types = await NormesTypesService.getAll();
    }

    function setSelected(norme) {
        if (norme && norme.id) {
            vm.selectedNormeId = $stateParams.id;
            vm.lastSelected = vm.selectedNormeId;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            vm.selectedNormeId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return (
            $state &&
            $state.current &&
            $state.current.name &&
            ($state.current.name === 'normes.list.detail' || $state.current.name === 'normes-labo.list.detail') &&
            $stateParams &&
            $stateParams.id
        );
    }

    function selectNorme(norme) {
        if (norme && norme.id) {
            if (vm.selectedNormeId !== norme.id) {
                vm.selectedNormeId = norme.id;
                vm.showDetail(norme.id);
            } else {
                vm.selectedNormeId = undefined;
                vm.closeDetail();
            }
        }
    }

    function isNormeSelected(norme) {
        return norme && norme.id && vm.selectedNormeId === norme.id;
    }

    function showDetail(normeId) {
        if (MassiaApplicationService.getApplication() == 'gestion') {
            $state.go('normes.list.detail', { id: normeId });
        } else {
            $state.go('normes-labo.list.detail', { id: normeId });
        }
    }

    function closeDetail() {
        if (MassiaApplicationService.getApplication() == 'gestion') {
            $state.go('normes.list');
        } else {
            $state.go('normes-labo.list');
        }
    }

    async function loadNormes(tableState) {
        startLoading();

        if ($state && $state.current && $state.current.name !== 'normes.list' && $state.current.name !== 'normes-labo.list') {
            if (MassiaApplicationService.getApplication() == 'gestion') {
                $state.go('normes.list');
            } else {
                $state.go('normes-labo.list');
            }
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.normes = [];

        try {
            const data = await NormesService.getNormes(filters, sorts, pagination);

            vm.normes = data.items;
            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteNorme(norme) {
        if (norme && norme.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('NORMES.DELETE.TITLE', { code: norme.libelle }),
                modalMsg: $translate.instant('NORMES.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await NormesService.deleteNormeById(norme.id);
                    notification.success($translate.instant('NORMES.DELETE.SUCCESS'));

                    vm.selectedNormeId = undefined;

                    if (vm.params.id) {
                        if (MassiaApplicationService.getApplication() == 'gestion') {
                            vm.state.go('normes.list');
                        } else {
                            vm.state.go('normes-labo.list');
                        }
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadNormes();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function eraseAllFilters() {
        loadNormes();
    }

    function getApplication() {
        return MassiaApplicationService.getApplication();
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
