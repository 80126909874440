import ProduitCommercial from './produits.commercial.model';

export default class ProduitCommerciaux {
    constructor(data) {
        this.idProduit = data.idProduit;
        this.codeProduit = data.codeProduit;
        this.code2 = data.code2 || 'N.C';
        this.libelleProduit = data.libelleProduit;
        this.produitsCom = this.setProduitCom(data.produitsCom);
    }

    setProduitCom(array) {
        if (array && Array.isArray(array)) {
            const tmp = [];
            for (let i = 0; i < array.length; i++) {
                tmp.push(new ProduitCommercial(array[i]));
            }
            return tmp;
        }
        return [];
    }
}
