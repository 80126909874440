Configure.$inject = ['$stateProvider'];

export default function Configure($stateProvider) {
    $stateProvider.state('profils', {
        parent: 'common',
        url: '/profils',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('profils.list', {
        url: '/list',
        template: '<profils></profils>',
        rights: { domain: 'droits', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "PROFILS.BREADCRUMBS.PROFILS_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_connexion',
            translate: 'PROFILS.BREADCRUMBS.PROFILS_LIST',
            order: 2
        },
        search: 'PROFILS.BREADCRUMBS.PROFILS_LIST'
    });

    $stateProvider.state('profils.list.detail', {
        url: '/{id}',
        template: '<profil-detail></profil-detail>',
        rights: { domain: 'droits', right: 'read' },
        ncyBreadcrumb: {
            parent: 'profils.list',
            label: '{{ "PROFILS.BREADCRUMBS.PROFILS_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('profils.new', {
        url: '/new',
        template: '<profil-form></profil-form>',
        rights: { domain: 'droits', right: 'create' },
        ncyBreadcrumb: {
            parent: 'profils.list',
            label: '{{ "PROFILS.BREADCRUMBS.PROFILS_NEW" | translate }}'
        },
        search: 'PROFILS.BREADCRUMBS.PROFILS_NEW'
    });

    $stateProvider.state('profils.edit', {
        url: '/{id}/edit',
        template: '<profil-form></profil-form>',
        rights: { domain: 'droits', right: 'update' },
        ncyBreadcrumb: {
            parent: 'profils.list',
            label: '{{ "PROFILS.BREADCRUMBS.PROFILS_EDIT" | translate }}'
        }
    });
}
