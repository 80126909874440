import { IAttributes, IAugmentedJQuery, ICompiledExpression, IDocumentService, IParseService, IScope, ITimeoutService } from 'angular';
import { OnDestroy, OnInit } from '../../../core/decorators';

export class MassiaClickOutside implements OnInit, OnDestroy {
    private classList: any;

    /* @ngInject */
    constructor(
        public $document: IDocumentService,
        public $parse: IParseService,
        public $timeout: ITimeoutService,
        public $element: IAugmentedJQuery,
        public $scope: IScope,
        public $attr: IAttributes
    ) {}

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.$timeout(() => {
            this.classList = this.$attr.outsideIfNot !== undefined ? this.$attr.outsideIfNot.split(/[ ,]+/) : [];

            // if the devices has a touchscreen, listen for this event
            if (this._hasTouch()) {
                this.$document.on('touchstart', () => {
                    setTimeout(this.eventHandler);
                });
            }

            // still listen for the click event even if there is touch to cater for touchscreen laptops
            this.$document.on('click', this.eventHandler);
        });
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        if (this._hasTouch()) {
            this.$document.off('touchstart', this.eventHandler);
        }

        this.$document.off('click', this.eventHandler);
    }

    private _hasTouch(): number | boolean {
        // works on most browsers, IE10/11 and Surface
        return 'ontouchstart' in window || navigator.maxTouchPoints;
    }

    eventHandler(e: any) {
        var i, element, r, id, classNames, l;

        // check if our element already hidden and abort if so
        if (this.$element.hasClass('ng-hide')) {
            return;
        }

        // if there is no click target, no point going on
        if (!e || !e.target) {
            return;
        }

        // loop through the available elements, looking for classes in the class list that might match and so will eat
        for (element = e.target; element; element = element.parentNode) {
            // check if the element is the same element the directive is attached to and exit if so (props @CosticaPuntaru)
            if (element === this.$element[0]) {
                return;
            }

            // now we have done the initial checks, start gathering id's and classes
            (id = element.id), (classNames = element.className), (l = this.classList.length);

            // Unwrap SVGAnimatedString classes
            if (classNames && classNames.baseVal !== undefined) {
                classNames = classNames.baseVal;
            }

            // if there are no class names on the element clicked, skip the check
            if (classNames || id) {
                // loop through the elements id's and classnames looking for exceptions
                for (i = 0; i < l; i++) {
                    //prepare regex for class word matching
                    r = new RegExp('\\b' + this.classList[i] + '\\b');

                    // check for exact matches on id's or classes, but only if they exist in the first place
                    if ((id !== undefined && r.test(id)) || (classNames && r.test(classNames))) {
                        // now let's exit out as it is an element that has been defined as being ignored for clicking outside
                        return;
                    }
                }
            }
        }

        // if we have got this far, then we are good to go with processing the command passed in via the click-outside attribute
        this.$timeout(() => {
            let fn: ICompiledExpression = this.$parse(this.$attr['onClickOutside']);
            fn(this.$scope, { event: e });
        });
    }
}
