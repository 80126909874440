export default function Configure($stateProvider) {
    $stateProvider.state('moduleMock', {
        parent: 'common',
        url: '/modulemock',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('moduleMock.componentMock', {
        url: '/componentmock',
        template: '<component-mock></component-mock>'
    });
}
Configure.$inject = ['$stateProvider'];
