export default class SiteInertesController {
    static $inject = ['$state', '$stateParams', 'SitesService', 'SitesTypesService', 'ModalService', 'notification', '$translate', 'moment', '_'];

    constructor($state, $stateParams, SitesService, SitesTypesService, ModalService, notification, $translate, moment, _) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SitesService = SitesService;
        this.SitesTypesService = SitesTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.moment = moment;
        this._ = _;
    }

    $onInit() {
        this.site = this.site || {};
        this.inertes = this.site.inertes;
        this.anneeToAdd = this.moment().year();
    }

    deleteInerte(inerte) {
        const index = this.inertes.indexOf(inerte);
        this.inertes.splice(index, 1);
    }

    async addInerte() {
        if (this.anneeToAdd && this.quantiteToAdd) {
            if (this.controleAnnee(this.anneeToAdd)) {
                const quantiteReel = await this.getQuantiteReel(this.site.id, this.anneeToAdd);

                const inerteToAdd = {};
                inerteToAdd.annee = this.anneeToAdd;
                inerteToAdd.quantite = this.quantiteToAdd;
                inerteToAdd.quantiteReel = quantiteReel;
                this.inertes.push(inerteToAdd);
                this.anneeToAdd = '';
                this.quantiteToAdd = '';
            }
        }
    }

    controleAnnee(annee) {
        let response = true;
        this._.forEach(this.inertes, (inerte) => {
            if (inerte.annee == annee) {
                response = false;
                return false;
            }
        });
        return response;
    }

    async getQuantiteReel(idSite, annee) {
        return await this.SitesService.getQuantiteReel(idSite, annee);
    }

    async updateInerte(inerte) {
        const quantiteReel = await this.getQuantiteReel(this.site.id, inerte.annee);
        inerte.quantiteReel = quantiteReel;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
