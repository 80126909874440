export default class GestionStocksAjustementController {
    static $inject = ['$stateParams', 'StocksCommunicationService', 'StocksService', 'notification', 'ModalService', '$uibModal'];

    constructor($stateParams, StocksCommunicationService, StocksService, notification, ModalService, $uibModal) {
        this.$stateParams = $stateParams;
        this.StocksCommunicationService = StocksCommunicationService;
        this.StocksService = StocksService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.quantite = 0;
        this.idMotif = 0;
        this.motifs = await this.StocksService.getMotifs();
    }

    annuler() {
        this.modalInstance.close(false);
    }

    async confirmer() {
        if (!this.idMotif) {
            this.notification.error('STOCKS.MOTIF_OBLIGATOIRE');
        } else {
            //ajouter le nouvel ajustement
            const ajust = {
                idMotifAjustement: this.idMotif,
                quantite: this.quantite
            };
            if (!this.ligne.newAjustements) {
                this.ligne.newAjustements = [];
            }
            this.ligne.newAjustements.push(ajust);
            //reclaculer la variation, l'ajustement et le stock final de la ligne
            if (this.ligne.variation.valeur) {
                this.ligne.variation.valeur = this.ligne.variation.valeur + this.quantite;
            } else {
                this.ligne.variation.valeur = this.quantite;
            }
            if (this.ligne.stockFinal.valeur) {
                this.ligne.stockFinal.valeur = this.ligne.stockFinal.valeur + this.quantite;
            } else {
                this.ligne.stockFinal.valeur = this.quantite;
            }
            if (this.ligne.ajustement.valeur) {
                this.ligne.ajustement.valeur = this.ligne.ajustement.valeur + this.quantite;
            } else {
                this.ligne.ajustement.valeur = this.quantite;
            }
            this.modalInstance.close(ajust);
        }
    }
}
