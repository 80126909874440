TicketsController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'TicketsService',
	'notification',
	'moment',
	'DataShareService',
	'_',
	'$uibModal',
	'globalizationManagementService',
	'$transitions'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function TicketsController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	TicketsService,
	notification,
	moment,
	DataShareService,
	_,
	$uibModal,
	globalizationManagementService,
	$transitions
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	let selectedTicketId = undefined;
	let listeTotalTicketIds = [];

	vm.loading = false;

	vm.params = $stateParams;
	vm.state = $state;
	vm.moment = moment;
	vm.DataShareService = DataShareService;
	vm._ = _;
	vm.$uibModal = $uibModal;

	vm.tickets = [];
	vm.isSearchOngletOpen = true;
	vm.searchingFormTickets = {};
	vm.searchingFormTickets.entity = 'ticket';
	vm.savedPagination = {};
	vm.savedPagination.entity = 'pagination';
	vm.societesComLoaded = [];
	vm.sitesComLoaded = [];
	vm.searchingFormTickets.societeComIdSelected = null;
	vm.searchingFormTickets.siteComIdSelected = null;

	vm.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;

	// initialise les dates de debut et fin au premier et dernier jour du mois en cours
	const maDate = new Date();
	const y = maDate.getFullYear();
	const m = maDate.getMonth();
	const firstDay = new Date(y, m, 1);
	const lastDay = new Date(y, m + 1, 0);
	// SL Mantis 387 RG 3
	vm.searchingFormTickets.dateDebut = vm.moment(firstDay).format(vm.dateFormat);
	// SL Mantis 387 RG 4
	vm.searchingFormTickets.dateFin = vm.moment(lastDay).format(vm.dateFormat);

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 200, 500, 1000];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectTicket = selectTicket;
	vm.isTicketSelected = isTicketSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadTickets = loadTickets;
	vm.reset = reset;
	vm.setSelected = setSelected;
	vm.licenceGest = __configuration.licenceGest;
	vm.exportSete = exportSete;
	vm.exporter = exporter;
	vm.getSitesCom = getSitesCom;
	vm.valoriserPrixAchat = valoriserPrixAchat;
	vm.exporterJyc = exporterJyc;
	vm.exporterRubisIcare = exporterRubisIcare;
	vm.openFileUploader = openFileUploader;
	vm.change = change;
	vm.formatDate = formatDate;
	vm.shouldShowComment = shouldShowComment;
	vm.shouldDisplayComment = shouldDisplayComment;
	vm.openCommentaire = openCommentaire

	async function init() {
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "tickets.list", on désélectionne la ligne sélectionnée (s'il y en a)
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		//quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}

		if (_.isEmpty(vm.DataShareService.savedData) == false) {
			// on cherche l'entité ticket
			const item = _.find(vm.DataShareService.savedData, { entity: 'ticket' });
			if (item != null) {
				await updateSearchForm();
			}
		}

		getEtats();
		await getSocietesCom();
		await getSitesCom();

		// SL Mantis 387 RG 5

		if (vm.searchingFormTickets.societeComIdSelected != null && vm.searchingFormTickets.siteComIdSelected != 0) {
			await loadTickets(previousTableState);
		}
		if (!vm.itemsByPage) {
			vm.itemsByPage = vm.itemsByPageOptions[0];
		}
	}

	init();

	async function updateSearchForm() {
		vm.searchingFormTickets = vm.DataShareService.getDataShare('ticket');
	}

	async function getSocietesCom() {
		vm.societesComLoaded = await TicketsService.getSocietesCom();
		// SL Mantis 387 RG 1
		if (vm.societesComLoaded.length == 1) {
			vm.searchingFormTickets.societeComIdSelected = vm.societesComLoaded[0].id;
		}
	}

	async function getSitesCom() {
		vm.sitesComLoaded = await TicketsService.getSitesCom(vm.searchingFormTickets.societeComIdSelected);
		// SL Mantis 387 RG 2
		if (vm.sitesComLoaded.length == 1) {
			vm.searchingFormTickets.siteComIdSelected = vm.sitesComLoaded[0].id;
		}
	}

	async function getEtats() {
		vm.etats = await TicketsService.getEtats();
	}

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(ticket) {
		if (ticket && ticket.id) {
			selectedTicketId = $stateParams.id;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			selectedTicketId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state && $state.current && $state.current.name && $state.current.name === 'tickets.list.detail' && $stateParams && $stateParams.id;
	}

	function selectTicket(ticket) {
		if (ticket && ticket.id) {
			//si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedTicketId, et charger la vue détail pour le ticket sélectionné
			if (vm.selectedTicketId !== ticket.id) {
				vm.selectedTicketId = ticket.id;
				showDetail(ticket.id);
			} else {
				//si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
				vm.selectedTicketId = undefined;
				closeDetail();
			}
		}
	}

	function isTicketSelected(ticket) {
		return ticket && ticket.id && vm.selectedTicketId === ticket.id;
	}

	function showDetail(ticketId) {
		vm.DataShareService.setDataShare(vm.searchingFormTickets, 'ticket');
		$state.go('tickets.detail', { id: ticketId });
	}

	function closeDetail() {
		$state.go('tickets.list');
	}

	function formatDate(date) {
		const dArr = date.split(' ');
		const ddmmyy = dArr[0];

		const spDay = ddmmyy.split('/');

		const day = spDay[0];
		const month = spDay[1];
		const year = spDay[2];
		const d = new Date();

		d.setUTCFullYear(year);
		d.setUTCMonth(month - 1);
		d.setUTCDate(day);

		return d;
	}


	function openCommentaire(ticket)
	{
		this.$uibModal.open({
			component: 'ticketCommentaireComponent',
			animation: true,
			size: 'lg',
			resolve:{
				ticket:()=>ticket
			}
		});
	}

	function shouldShowComment(ticket) {
		// console.log("🚀 ~ file: tickets.controller.js:232 ~ ticket:", ticket)
		let res = false;
		const regex = /^\s*$/;
		if (ticket.remarque != null && !regex.test(ticket.remarque)) {
			res = true;
		}
		if (ticket.remarqueClient != null && !regex.test(ticket.remarqueClient)) {
			res = true;
		}
		if (ticket.remarqueUtilisateur != null && !regex.test(ticket.remarqueUtilisateur)) {
			res = true;
		}
		// console.log("🚀 ~ file: tickets.controller.js:236 ~ res:", ticket.remarque, ticket.remarqueClient, ticket.remarqueUtilisateur)
		// console.log("🚀 ~ file: tickets.controller.js:236 ~ res:", res)
		return res;
	}

	function shouldDisplayComment(comment) {
		const regex = /^\s*$/;
		return !!comment && !regex.test(comment) ? comment : '';
	}

	async function loadTickets(tableState) {
		startLoading();
		if (previousTableState) {
			tableState = previousTableState;
		}
		// gestion de la sauvegarde et de la restitution de la pagination
		const objectToManage = {};
		objectToManage.tableState = tableState;
		objectToManage.savedPagination = vm.savedPagination;
		objectToManage.itemsByPageOptions = vm.itemsByPageOptions;
		objectToManage.itemsByPage = vm.itemsByPage;
		const resultObject = await vm.DataShareService.managePaginationContextuelle(objectToManage);
		if (resultObject) {
			tableState = resultObject.tableState;
			vm.savedPagination = resultObject.savedPagination;
			vm.itemsByPageOptions = resultObject.itemsByPageOptions;
			vm.itemsByPage = resultObject.itemsByPage;
		}

		//quand on rafraîchit la grille, on revient sur "tickets.list", et on ferme la vue détail d'un ticket
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== 'tickets.list') {
			$state.go('tickets.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		// ajout du tri par date si non
		if (!sorts.find((x) => x.criterion == 'dateHeureLivraison')) {
			sorts.push({
				criterion: 'dateHeureLivraison',
				isDescending: true
			});
		}
		const pagination = PaginationService.getPagination(previousTableState);

		vm.tickets = [];

		vm.DataShareService.setDataShare(vm.searchingFormTickets, 'ticket');

		try {
			const data = await TicketsService.getTickets(
				filters,
				sorts,
				pagination,
				vm.searchingFormTickets.societeComIdSelected,
				vm.searchingFormTickets.siteComIdSelected,
				vm.searchingFormTickets.dateDebut,
				vm.searchingFormTickets.dateFin,
				vm.numeroFacture
			);

			vm.tickets = data.items;
			listeTotalTicketIds = data.listeIds;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
			console.log(vm.tickets);
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}

	function reset() {
		vm.searchingFormTickets.societeComIdSelected = null;
		vm.searchingFormTickets.siteComIdSelected = null;

		// initialise les dates de debut et fin au premier et dernier jour du mois en cours
		const maDate = new Date();
		const y = maDate.getFullYear();
		const m = maDate.getMonth();
		const firstDay = new Date(y, m, 1);
		const lastDay = new Date(y, m + 1, 0);
		vm.searchingFormTickets.dateDebut = vm.moment(firstDay).format(vm.dateFormat);
		vm.searchingFormTickets.dateFin = vm.moment(lastDay).format(vm.dateFormat);
		vm.tickets = [];

		vm.DataShareService.setDataShare(vm.searchingFormTickets, 'ticket');

		getSocietesCom();
	}

	async function exportSete() {
		const _this = this;
		vm.$uibModal
			.open({
				template: '<tickets-export-csv-popup modal-instance="$ctrl.uibModalInstance"></tickets-export-csv-popup>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xl',
				backdrop: false
			})
			.result.then(
				function (result) {
					//console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					//console.info(result);
					//_this.$state.go('gestion.home');
				},
				function (reason) {
					//console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
					//_this.$state.go('gestion.home');
				}
			);
	}

	async function exporter(modeImpression) {
		try {
			startLoading();
			this.isLoadExport = true;
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const fileName = 'Liste_tickets';
			let resultat = undefined;
			try {
				resultat = await TicketsService.exportListeTickets(
					modeImpression,
					vm.searchingFormTickets.societeComIdSelected,
					vm.searchingFormTickets.siteComIdSelected,
					vm.searchingFormTickets.dateDebut,
					vm.searchingFormTickets.dateFin,
					filters,
					sorts
				);
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('TICKETS.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				const status = resultat.status;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
				} finally {
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		} finally {
			stopLoading();
			this.isLoadExport = false;
		}
	}

	async function exporterJyc() {
		try {
			startLoading();
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const pagination = PaginationService.getPagination(previousTableState);

			const fileName = 'JYC';
			let resultat = undefined;
			try {
				resultat = await TicketsService.exportJyc(
					filters,
					sorts,
					pagination,
					vm.searchingFormTickets.societeComIdSelected,
					vm.searchingFormTickets.siteComIdSelected,
					vm.searchingFormTickets.dateDebut,
					vm.searchingFormTickets.dateFin
				);
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('TICKETS.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				const status = resultat.status;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);

					linkElement.setAttribute('download', fileName + '.txt');

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
				} finally {
					stopLoading();
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		}
	}

	async function exporterRubisIcare() {
		startLoading();
		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		try {
			await TicketsService.exportRubisIcare(
				filters,
				sorts,
				pagination,
				vm.searchingFormTickets.societeComIdSelected,
				vm.searchingFormTickets.siteComIdSelected,
				vm.searchingFormTickets.dateDebut,
				vm.searchingFormTickets.dateFin
			);
			notification.success('TICKETS.RUBIS_SUCCESS');
		} catch (ex) {
			//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
			const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
			notification.error('TICKETS.' + msgException);
			return false;
		} finally {
			stopLoading();
		}
	}

	function valoriserPrixAchat() {
		if (listeTotalTicketIds.length > 0) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('TICKETS.VALOPA.TITLE'),
				modalMsg: $translate.instant('TICKETS.VALOPA.MESSAGE'),
				headerClass: 'modal-danger'
			});
			modalInstance.result.then(async function () {
				startLoading();
				try {
					await TicketsService.valoriserPrixAchat(
						listeTotalTicketIds,
						vm.searchingFormTickets.societeComIdSelected,
						vm.searchingFormTickets.siteComIdSelected,
						vm.searchingFormTickets.dateDebut,
						vm.searchingFormTickets.dateFin
					);
					notification.success($translate.instant('TICKETS.VALOPA.SUCCESS'));

					if (vm.params.id) {
						vm.state.go('tickets.list');
					}

					await vm.loadTickets(null);
				} catch (ex) {
					if (ex.status == 400) {
						notification.warning(ex.data);
					} else {
						notification.error(ex.data);
					}
				} finally {
					stopLoading();
				}
			});
		}
	}

	function openFileUploader() {
		document.getElementById('file-upload').click();
	}

	async function change(event) {
		startLoading();
		this.form = {
			file: {}
		};
		const fileInfo = event.target.files[0];
		vm.form.file = fileInfo;

		data = await TicketsService.upload(vm.form);
		if (data) {
			getRapport(data);
		}
		stopLoading();
	}

	function getRapport(rapport) {
		const _this = vm;
		vm.$uibModal
			.open({
				template: '<ticket-import modal-instance="$ctrl.uibModalInstance" rapport="$ctrl.rapport"></ticket-import>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.rapport = rapport;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'l'
			})
			.result.then(
				function (result) {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
				},
				function (reason) {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}
}
