export default function FileListController(
	SharedFileService,
	PaginationService,
	notification,
	ModalService,
	$translate,
	globalizationManagementService
) {
	const $ctrl = this;

	this.$onInit = () => {
		$ctrl.itemsByPageOptions = [20, 50, 100];
		$ctrl.fileList = [];
		$ctrl.loading = false;
		$ctrl.itemsByPageOptions = [20, 50, 100];
		$ctrl.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
		$ctrl.displayDateMask = globalizationManagementService.getCurrentLanguage().displayDateMask;
	};

	let previousTableState;
	this.$onDestroy = () => {};

	$ctrl.loadFile = async (tableState) => {
		try {
			_toggleLoading();
			if (tableState) {
				previousTableState = tableState;
			}
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const pagination = PaginationService.getPagination(previousTableState);

			const res = await SharedFileService.getAll(filters, sorts, pagination);
			$ctrl.fileList = res.data.items;

			if (previousTableState) {
				PaginationService.setTableState(res.data.skip, res.data.take, res.data.total, previousTableState);
			}
			_toggleLoading();
		} catch (err) {
			console.error(err);
			notification.error(err.message);
			_toggleLoading();
		}
	};

	var _toggleLoading = () => {
		$ctrl.loading = !$ctrl.loading;
	};

	// $ctrl.synchroniser = (file) => {};

	$ctrl.delete = (file) => {
		ModalService.confirm({
			modalTitle: $translate.instant('PORTAIL.FILE.DELETE.TITLE'),
			modalMsg: `${$translate.instant('PORTAIL.FILE.DELETE.MESSAGE')} ${file.filename}`,
			headerClass: 'modal-danger'
		}).result.then(async () => {
			try {
				await SharedFileService.delete(file.id);
				notification.success('PORTAIL.FILE.DELETE.SUCCESS');
				return await $ctrl.loadFile();
			} catch (err) {
				console.log(err);
				if (err.data && err.data.message) {
					return notification.error(err.data.message);
				}
				return notification.error(err.message);
			}
		});
	};
}

FileListController.$inject = [
	'SharedFileService',
	'PaginationService',
	'notification',
	'ModalService',
	'$translate',
	'globalizationManagementService'
];
