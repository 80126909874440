import SiteInertesController from './site.inertes.controller';

export default {
    bindings: {
        site: '<',
        onUpdate: '&'
    },
    template: require('./site.inertes.html'),
    controller: SiteInertesController
};
