import Carnet from './carnet.model';

export default class CarnetFormController {
    static $inject = [
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'CarnetsCommunicationService',
        'CarnetsService',
        'notification',
        '$location',
        '$anchorScroll',
        '$uibModal',
        '_',
        'ModalService',
        '$translate',
        'moment',
        'globalizationManagementService'
    ];

    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        CarnetsCommunicationService,
        CarnetsService,
        notification,
        $location,
        $anchorScroll,
        $uibModal,
        _,
        ModalService,
        $translate,
        moment,
        globalizationManagementService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.CarnetsCommunicationService = CarnetsCommunicationService;
        this.CarnetsService = CarnetsService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.$uibModal = $uibModal;
        this._ = _;
        this.ModalService = ModalService;
        this.$translate = $translate;
        this.moment = moment;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    async $onInit() {
        this.carnet = {};
        this.isEditMode = false;
        this.loading = false;
        this.form = {};

        await this.reset();

        this.societes = await this.CarnetsService.getSocietes();
        this.sites = await this.CarnetsService.getSitesCommerciaux(this.carnet.societeId);
        this.clients = await this.CarnetsService.getClients();
        if (this.carnet.clientId) {
            this.chantiers = await this.CarnetsService.getChantiers(this.carnet.clientId);
        }
        this.typeBennes = await this.CarnetsService.getBennes();
        await this.getPrestations();
        if (this.$stateParams.id) {
            this.setCarnetAsTypeAhead();
        }
        if (this.carnet.prestationId) {
            this.getPrixArcticle();
        }

        await this.initiateSiteCommerciale();

        if (__configuration.licenceGest == '10') {
            this.getPeriodeAnneeCivile();
        }

        if (this.isEditMode && __configuration.licenceGest != '10') {
            var _this = this;
            this.$scope.$watch('$ctrl.carnet.dateFin', function (newValue, oldValue) {
                _this.changeDateFin(newValue, oldValue);
            });
        }

        if (!this.isEditMode && __configuration.licenceGest != '10') {
            var _this = this;
            this.$scope.$watch('$ctrl.carnet.dateDebut', function (newValue, oldValue) {
                _this.updateDateFin();
            });
        }
    }

    $onDestroy() {
        this.$timeout.cancel(this.updateChantierTimeout);
    }

    async reset() {
        this.startLoading();

        let data = {
            id: this.$stateParams.id
        };

        if (data.id) {
            this.isEditMode = true;
            try {
                data = await this.CarnetsService.getCarnetById(data.id);
            } catch (ex) {
                this.notification.error(ex.data);
                this.annuler();
            }
        } else {
            data.isModifiable = true;
            this.isEditMode = false;
        }

        this.carnet = new Carnet(data);

        this.ongletOpen = {
            isEnteteOpen: true,
            isBonsOpen: true
        };
        this.withModification = false;

        this.checkBonEtat();

        this.listFactureIdsToPrint = [];
        if (this.carnet.idFacture) {
            this.listFactureIdsToPrint.push(this.carnet.idFacture);
        }

        this.stopLoading();
    }

    async getPrestations() {
        if (this.carnet.societeId && this.carnet.siteId && this.carnet.clientId) {
            this.prestations = await this.CarnetsService.getPrestations(
                this.carnet.societeId,
                this.carnet.siteId,
                this.carnet.clientId,
                this.carnet.chantierId,
                4
            );
        } else {
            this.prestations = [];
        }
    }

    async changeSociete() {
        this.sites = await this.CarnetsService.getSitesCommerciaux(this.carnet.societeId);
        await this.initiateSiteCommerciale();
        await this.getPrestations();
        this.withModification = true;
    }

    async changeSite() {
        await this.getPrestations();
        this.withModification = true;

        this.carnet.sites.push(this.carnet.siteId);
        const filtre = this._.find(this.inputFiltreSitesCommerciaux, { id: this.carnet.siteId });
        if (filtre) {
            filtre.selected = true;
        }
    }

    async changeChantier() {
        await this.getPrestations();
    }

    changeWith() {
        this.withModification = true;
    }

    updateDateFin() {
        const momentDate = this.moment(this.carnet.dateDebut, this.dateFormat);
        let duration = this.moment.duration(1, 'years');
        let date = momentDate.add(duration);
        duration = this.moment.duration(1, 'days');
        date = date.subtract(duration);
        this.carnet.dateFin = this.moment(date).format(this.dateFormat);
    }

    getPeriodeAnneeCivile() {
        const thisYear = new Date().getFullYear();
        this.carnet.dateDebut = new Date();
        this.carnet.dateFin = new Date('12/31/' + thisYear);
    }

    async initiateSiteCommerciale() {
        this.inputFiltreSitesCommerciaux = await this.refreshFiltreSitesCommerciaux();
        await this.preselectFiltreSitesCommerciaux();
    }

    async preselectFiltreSitesCommerciaux() {
        for (let index = 0; index < this.carnet.sites.length; index++) {
            const element = this.carnet.sites[index];
            const filtre = this._.find(this.inputFiltreSitesCommerciaux, { id: element });
            if (filtre) {
                filtre.selected = true;
            }
        }
    }

    async saveSelectedSitesCommerciaux() {
        this.carnet.sites = [];
        for (let i = 0; i < this.outputFiltreSitesCommerciaux.length; i++) {
            const siteCom = this.outputFiltreSitesCommerciaux[i];
            this.carnet.sites.push(siteCom.id);
        }
    }

    async refreshFiltreSitesCommerciaux() {
        let data;
        try {
            data = await this.CarnetsService.getFiltreSitesCommerciaux(this.carnet.societeId);
        } catch (ex) {
            data = [];
        }
        return data;
    }

    async setTypeAheadAs() {
        if (!this.carnet) {
            this.carnet = {};
        }
        this.carnet.clientId = this.siteClient.id;
        this.carnet.client = this.siteClient.libelle;
        this.chantiers = await this.CarnetsService.getChantiers(this.carnet.clientId);
        await this.getPrestations();
        this.withModification = true;
    }

    setCarnetAsTypeAhead() {
        this.siteClient = {
            id: this.carnet.clientId,
            libelle: this.carnet.client
        };
    }

    async sauvegarder(withFacture) {
        if (this.checkValidity()) {
            this.startLoading();

            try {
                let id = false;
                if (this.carnet.id) {
                    if (this.carnet.idFacture) {
                        const vm = this;
                        const modalInstance = this.ModalService.confirm({
                            modalTitle: this.$translate.instant('CARNETS.UPDATED.TITLE', { code: this.carnet.code }),
                            modalMsg: this.$translate.instant('CARNETS.UPDATED.MESSAGE', { code: this.carnet.numeroFacture }),
                            headerClass: 'modal-warning'
                        });
                        modalInstance.result.then(async function () {
                            const factureId = await vm.CarnetsService.updateCarnet(vm.carnet, withFacture);
                            vm.notification.success('CARNETS.UPDATED.SUCCESS');
                            id = vm.carnet.id;

                            if (factureId.data) {
                                const that = vm;
                                const modalInstance = vm.ModalService.confirm({
                                    modalTitle: vm.$translate.instant('CARNETS.UPDATED.TITLE', { code: vm.carnet.code }),
                                    modalMsg: vm.$translate.instant('CARNETS.CREATED.MESSAGE'),
                                    headerClass: 'modal-warning'
                                });
                                modalInstance.result.then(async function () {
                                    that.$state.go('factures.fromcarnet', { id: factureId.data, idCarnet: id });
                                });
                            }
                        });
                    } else {
                        const factureId = await this.CarnetsService.updateCarnet(this.carnet, withFacture);
                        this.notification.success('CARNETS.UPDATED.SUCCESS');
                        id = this.carnet.id;

                        if (factureId.data) {
                            const that = this;
                            const modalInstance = this.ModalService.confirm({
                                modalTitle: this.$translate.instant('CARNETS.UPDATED.TITLE', { code: this.carnet.code }),
                                modalMsg: this.$translate.instant('CARNETS.CREATED.MESSAGE'),
                                headerClass: 'modal-warning'
                            });
                            modalInstance.result.then(async function () {
                                that.$state.go('factures.fromcarnet', { id: factureId.data, idCarnet: id });
                            });
                        }
                    }
                } else {
                    if (withFacture) {
                        const _this = this;
                        this.$uibModal
                            .open({
                                template: '<carnet-paiement modal-instance="$ctrl.uibModalInstance"></carnet-paiement>',
                                controller: [
                                    '$uibModalInstance',
                                    function ($uibModalInstance) {
                                        const $ctrl = this;
                                        $ctrl.uibModalInstance = $uibModalInstance;
                                    }
                                ],
                                controllerAs: '$ctrl',
                                size: 'l',
                                backdrop: false
                            })
                            .result.then(
                                async function (result) {
                                    const idPaiement = result;
                                    if (result) {
                                        const result = await _this.CarnetsService.createCarnet(_this.carnet, withFacture, idPaiement);
                                        id = result.idCarnet;
                                        _this.notification.success('CARNETS.CREATED.SUCCESS');
                                        _this.$state.go('carnets.edit', { id: id });
                                    }
                                },
                                function (reason) {
                                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                                }
                            );
                    } else {
                        const result = await this.CarnetsService.createCarnet(this.carnet, withFacture, null);
                        id = result.idCarnet;
                        this.notification.success('CARNETS.CREATED.SUCCESS');

                        if (result.idFacture) {
                            const vm = this;
                            const modalInstance = this.ModalService.confirm({
                                modalTitle: this.$translate.instant('CARNETS.CREATED.TITLE'),
                                modalMsg: this.$translate.instant('CARNETS.CREATED.MESSAGE'),
                                headerClass: 'modal-warning'
                            });
                            modalInstance.result.then(async function () {
                                vm.$state.go('factures.fromcarnet', { id: result.idFacture, idCarnet: id });
                            });
                        }
                    }
                }

                this.withModification = false;
                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async facturer() {
        const id = await this.sauvegarder(true);
        if (id && this.carnet.id) {
            this.reset();
        } else if (id) {
            this.$state.go('carnets.edit', { id: id });
        }
    }

    async valider() {
        const id = await this.sauvegarder(false);
        if (id && this.carnet.id) {
            this.reset();
        } else if (id) {
            this.$state.go('carnets.edit', { id: id });
        }
    }

    async disabledBons(idBon) {
        this.startLoading();
        try {
            let id = null;
            if (idBon) {
                id = idBon;
                const bon = this._.find(this.carnet.bons, { id: idBon });
                bon.etat = 1;
            } else {
                for (let i = 0; i < this.carnet.bons.length; i++) {
                    const bon = this.carnet.bons[i];
                    if (bon.etat == 0) {
                        bon.etat = 1;
                    }
                }
            }
            await this.CarnetsService.disabledBons(id, this.carnet.id);
            this.checkBonEtat();
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        } finally {
            this.stopLoading();
        }
    }

    async enabledBons(idBon) {
        this.startLoading();
        try {
            let id = null;
            if (idBon) {
                id = idBon;
                const bon = this._.find(this.carnet.bons, { id: idBon });
                bon.etat = 0;
            } else {
                for (let i = 0; i < this.carnet.bons.length; i++) {
                    const bon = this.carnet.bons[i];
                    if (bon.etat == 1) {
                        bon.etat = 0;
                    }
                }
            }
            await this.CarnetsService.enabledBons(id, this.carnet.id);
            this.checkBonEtat();
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        } finally {
            this.stopLoading();
        }
    }

    checkBonEtat() {
        this.avoirIsDisabled = true;
        for (let index = 0; index < this.carnet.bons.length; index++) {
            const element = this.carnet.bons[index];
            if (element.etat == 1) {
                this.avoirIsDisabled = false;
            }
        }
    }

    async changePrestation() {
        this.getPrixArcticle();
        this.withModification = true;
    }

    async getPrixArcticle() {
        try {
            const prixArticle = await this.CarnetsService.getPSArticle(
                this.carnet.prestationId,
                2,
                this.carnet.societeId,
                this.carnet.siteId,
                this.carnet.clientId,
                this.carnet.chantierId,
                null
            );

            this.prestation = {};
            this.prestation.prixStandard = prixArticle.prixStandard || null;
            this.prestation.prixVente = prixArticle.valeur || null;
            this.prestation.tva = prixArticle.tauxTVA || null;
        } catch (ex) {
            this.prestation = {};
            this.prestation.prixStandard = null;
            this.prestation.prixVente = null;
            this.prestation.tva = null;
            return false;
        }
    }

    getPoidsNet() {
        let total = 0;
        this.carnet.bons.forEach(function (element) {
            total += element.poidsNet;
        }, this);

        return total;
    }

    getValeur() {
        let total = 0;
        this.carnet.bons.forEach(function (element) {
            total += element.valeur;
        }, this);

        return total;
    }

    getRestantsTotal() {
        let total = 0;
        this.carnet.bons.forEach(function (element) {
            if (element.etat == 0) {
                total += 1;
            }
        }, this);

        return total;
    }

    getRestants() {
        let total = 0;
        this.carnet.bons.forEach(function (element) {
            if (element.etat == 0 && element.ticket == null) {
                total += 1;
            }
        }, this);

        return total;
    }

    checkValidity() {
        let validity = true;
        if (!this.carnet.isValid()) {
            validity = false;
        }
        this.$scope.$broadcast('carnet');
        return validity;
    }

    async annuler() {
        this.backToPreviousState();
    }

    async print() {
        const vm = this;
        let msg = this.$translate.instant('CARNETS.PRINTCHECKED.MESSAGE');
        if (this.withModification) {
            msg = this.$translate.instant('CARNETS.PRINTCHECKED.MESSAGE_MODIFICATION');
        }

        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('CARNETS.PRINTCHECKED.TITLE'),
            modalMsg: msg,
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(async function () {
            //this.startLoading();
            const fileName = Date.now();
            const resultat = await vm.CarnetsService.print(vm.carnet.id, fileName);

            const data = resultat.data;
            const status = resultat.status;
            let headers = resultat.headers;

            headers = headers();

            const contentType = headers['content-type'];

            const linkElement = document.createElement('a');
            try {
                const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                const url = window.URL.createObjectURL(blob);
                linkElement.setAttribute('href', url);
                linkElement.setAttribute('download', fileName + '.pdf');

                const clickEvent = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: false
                });
                linkElement.dispatchEvent(clickEvent);
            } catch (ex) {
            } finally {
                //this.stopLoading();
            }
        });
    }

    printFacture() {
        const _this = this;

        this.$uibModal
            .open({
                template: '<factures-print source="$ctrl.listFactureIdsToPrint" modal-instance="$ctrl.uibModalInstance"></factures-print>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.listFactureIdsToPrint = _this.listFactureIdsToPrint;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'l'
            })
            .result.then(
                function (result) {
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    changeDateFin(newValue, oldValue) {
        if (oldValue && newValue && oldValue != newValue) {
            const vm = this;

            const modalInstance = this.ModalService.confirm({
                modalTitle: this.$translate.instant('CARNETS.CHANGER_DATE_FIN.TITLE'),
                modalMsg: this.$translate.instant('CARNETS.CHANGER_DATE_FIN.MESSAGE'),
                headerClass: 'modal-warning'
            });
            modalInstance.result.then(async function () {
                try {
                    await vm.CarnetsService.updateCarnetDateFin(vm.carnet.id, newValue);
                    vm.notification.success('CARNETS.CHANGER_DATE_FIN.SUCCESS');
                } catch (ex) {
                } finally {
                    await vm.reset();
                }
            });
        }
    }

    async genererAvoir() {
        const vm = this;

        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('CARNETS.AVOIR.TITLE'),
            modalMsg: this.$translate.instant('CARNETS.AVOIR.MESSAGE'),
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(async function () {
            try {
                const avoirId = await vm.CarnetsService.genererAvoir(vm.carnet.id);
                vm.notification.success('CARNETS.AVOIR.SUCCESS');
            } catch (ex) {
                vm.notification.error(ex.data);
                return false;
            } finally {
                await vm.reset();
            }
        });
    }

    backToPreviousState() {
        this.$state.go('carnets.list');
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
