(function () {
    'use strict';

    angular.module('blocks.helpers').directive('acScrollTop', acScrollTop);
    acScrollTop.$inject = ['$window'];

    function acScrollTop($window) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var offsetTop = getElementOffset(element[0]).top; // get element's offset top relative to document

                angular.element($window).on('scroll', function (e) {
                    var scrollOffset = angular.element($window)[0].scrollY? angular.element($window)[0].scrollY : angular.element($window)[0].pageYOffset;
                    var newOffset = Math.max(offsetTop - scrollOffset, 0);

                    element[0].style.top = newOffset + 'px';
                });
            }
        };
    }

    function getElementOffset(element) {
        var de = document.documentElement;
        var box = element.getBoundingClientRect();
        var top = box.top + window.pageYOffset - de.clientTop;
        var left = box.left + window.pageXOffset - de.clientLeft;
        return {top: top, left: left};
    }
})();
