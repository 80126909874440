import LoginPageController from './login.page.controller';

export default {
    //templateUrl: `app/massia/login/components/page/login.page.${client_key}.html`,
    templateUrl: () => {
        //let client = MassiaClientService.getClient();
        let templateName = 'login.page.default.html';

        const client = window.localStorage.getItem('massia.Client');
        if (__configuration.licencePerf === '1' || __configuration.licencePerf === '4') {
            templateName = 'login.page.eiffage.html';
        } else if (client && client !== 'null' && client !== 'undefined' && typeof client !== 'undefined') {
            templateName = 'login.page.' + JSON.parse(client.toLowerCase()) + '.html';
        }

        return templateName;
    },
    controller: LoginPageController
};
