(function (angular, undefined) {
    'use strict';

    angular.module('blocks.validation')
        .provider('ValidationService', ValidationServiceProvider);

    function ValidationServiceProvider() {
        var provider = this;
        var validationTemplate;

        provider.setValidationTemplate = setValidationTemplate;
        provider.$get = ValidationService;

        function setValidationTemplate(template) {
            validationTemplate = template;
        }

        ValidationService.$inject = ['$injector', '$log', 'defaultValidationTemplate', 'smallValidationTemplate'];
        function ValidationService($injector, $log, defaultValidationTemplate, smallValidationTemplate) {

            var validatorSuffix = 'Validator';

            var service = {
                getValidationTemplate: getValidationTemplate,
                getValidator: getValidator,
                createBinding: createBinding,
                validate: validate
            };

            return service;

            function getValidationTemplate(type = null) {
                switch (type) {
                    case "default":
                        return defaultValidationTemplate;
                
                    case "small":
                        return smallValidationTemplate;

                    default:
                        return defaultValidationTemplate;
                }
            }

            function validate(validator, model, property) {
                var validationResult = validator.validate(model);
                // si l'objet est invalide, on récupère les erreurs de validation de la propriété à laquelle on est attachés
                return getPropertyErrors(property, validationResult.errors);
            }

            function getValidator(modelName, obj) {
                var validatorName = getValidatorName(modelName);

                if ($injector.has(validatorName)) {
                    return $injector.get(validatorName);
                }
                else {
                    $log.warn('l\'injecteur n\'a pas trouvé "' + validatorName + '" dans l\'injecteur défini par "' + modelName+'"', obj);
                    // $log.warn('l\'entité ' + modelName + ' n\'a pas été trouvée dans l\'injecteur ' + validatorName, obj);
                    return null;
                }
            }

            function createBinding(bindingAttrs) {
                if (bindingAttrs) {
                    var binding = bindingAttrs.split('.');

                    if (binding && binding.length > 3) {
                        const controller = binding[0];
                        const property = binding[1];
                        binding.splice(0, 2);
                        const arrValue = binding.join('.');

                        return new BindingInfo(controller, property, arrValue);
                    }
                    else if (binding && binding.length >= 3) {
                        return new BindingInfo(binding[0], binding[1], binding[2]);
                    }
                    else {
                        $log.warn('model binding error in ValidationService with attrs' + bindingAttrs);
                        return null;
                    }
                }
                else {
                    $log.warn('model binding error in ValidationService, model is undefined');
                    return null;
                }
            }

            function toTitleCase(modelName) {
                return modelName.replace(/\w\S*/g, function (txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1);
                });
            }

            function getValidatorName(modelName) {
                return toTitleCase(modelName) + validatorSuffix;
            }

            function getPropertyErrors(propertyName, errors) {
                var propertyErrors = [];
                angular.forEach(errors, function (value, key) {
                    if (value.propertyName === propertyName) {
                        propertyErrors.push(value.errorMessage);
                    }
                });

                return propertyErrors;
            }

            function BindingInfo(controller, model, property, propertyValue) {
                this.Controller = controller;
                this.Model = model;
                this.Property = property;
            }
        }
    }
})(angular);
