// le model est principalement utilise pour formatter le retour du serveur, vers un objet adapte pour l'utilisation dans treeview
export default class LieuElementHierarchique {
    // le model est pour l'objectif de reformatter le resultat venant du serveur, et nous allons reconstruire id de l'element en prenant compte l'id du niveau et son code.
    // De la maniere, nous devons faire la meme chose puor son parentId.
    // pour les elements racines, nous donnons 'root' comme son parentId, sinon, on passe manuellement id du parent
    constructor(data, parentId) {
        this.parentId = parentId;
        this.id = this.buildId(data.id);
        this.expanded = data.expanded || false;
        this.label = data.label;
        this.typeLabel = data.typeLabel;
        this.position = data.position;
        this.type = data.type;
        this.items = this.setItems(data.items, this.id);
        this.canEdit = data.canEdit;
        this.canReorder = data.canReorder;
    }

    // a partir de l'id d'element retourne par le serveur, on construit l'id pour le front
    // en effet, l'entity ElementHierarchique n'a pas encore un id de type int, mais un id qui est compose de (idNiveau, codeElement)
    // du coup, pour le front, particulierement dans le treeview, on demande d'avoir un id de noeud en type simple/primitif
    // on fait la conversion ici
    // Nous ajoutons aussi l'id parent pour l'id d'un element, car pour les elements de type Pays,
    // si on n'a pas d'id parent, on n'arrive pas a distinguer les differents elements pays sous les differents elements de parent
    buildId(id) {
        return id && id.elementId && id.niveauHierarchiqueId
            ? `${id.niveauHierarchiqueId}${LieuElementHierarchique.seperator}${id.elementId}${
                  LieuElementHierarchique.seperator
              }${LieuElementHierarchique.getParentIdWithoutGrandParentId(this.parentId)}`
            : id;
    }

    setItems(items = []) {
        const parentId = this.id;

        return items.map((item) => {
            return new LieuElementHierarchique(item, parentId);
        });
    }

    //a partir de l'id (au front) de l'element, recuperer son idNiveau
    static getIdNiveau(id) {
        try {
            const parts = id.split(LieuElementHierarchique.seperator);
            return parts[0];
        } catch (ex) {
            return id;
        }
    }

    //a partir de l'id (au front) de l'element, recuperer son codeElement
    static getCodeElement(id) {
        try {
            const parts = id.split(LieuElementHierarchique.seperator);
            return parts[1];
        } catch (ex) {
            return id;
        }
    }

    //dans le constructeur, quand on recupere un id du parent, on a en effet dans ce id, l'id du parent, concate avec id du grand parent
    //mais, quand on build un id pour l'element courant, il nous suffit d'avoir id du parent
    static getParentIdWithoutGrandParentId(parentId) {
        if (parentId) {
            return `${this.getIdNiveau(parentId)}${LieuElementHierarchique.seperator}${this.getCodeElement(parentId)}`;
        }
    }

    //le seperateur, constant, permettant de construire l'id unique en prenant compte l'id du niveau d'element et son code
    static seperator = '$¤$';
}
