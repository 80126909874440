export default function Configure($stateProvider) {
    $stateProvider.state('activation-site-synchro', {
        parent: 'gestion',
        url: '/activation-site-synchro',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.OUTILS" | translate }}'
        }
    });

    $stateProvider.state('activation-site-synchro.edit', {
        url: '/',
        template: '<activation-site-synchro></activation-site-synchro>',
        rights: { domain: 'synchrosite', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "ACTIVATION_SYNCHRO_SITE.TITLE" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
