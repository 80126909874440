//import isElectron from 'is-electron';

const isElectron = () => false;
import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-essai-excel-plv',
    bindings: {
        sousMesure: '<',
        model: '<',
        popover: '@',
        refresh: '&'
    },
    template: require('./massia.essai.excel.plv.tpl.html')
})
export class MassiaEssaiExcelPlvController {
    constructor($uibModal, TemplateImpressionService, ExcelService, $stateParams, notification) {
        this.$uibModal = $uibModal;
        this.templateImpressionService = TemplateImpressionService;
        this.ExcelService = ExcelService;
        this.$stateParams = $stateParams;
        this.notification = notification;
    }

    $onInit() {
        this.downloaded = false;
    }

    $onDestroy() {}

    $onChanges() {}

    async getFile() {
        try {
            console.log('getFile');
            this.notification.info('Fichier en cours de chargement...');
            const idPrel = this.$stateParams.id || 0;
            const file = await this.ExcelService.getFile(idPrel, this.model.excelAdditionalInformation.idConfigExcel, this.model.essai.id);
            if (isElectron()) {
                /* var electron = require('electron');
                let res = await this.templateImpressionService.downloadTemplateElectron('temp_' + file.filename, file, 'pilot', (e) => {
                    console.log((e.loaded / e.total) * 100);
                });
                this.downloadFile(res.file);
                electron.remote.shell.openItem(require('path').join(electron.remote.app.getPath('downloads'), res.filename));
                this.filename = res.filename;
                this.fs = require('fs');
                this.fs.watchFile(
                    require('path').join(electron.remote.app.getPath('downloads'), res.filename),
                    {
                        interval: 1000,
                    },
                    async (curr, prev) => {
                        if (prev !== null && curr !== null && prev.mtimeMs < curr.mtimeMs) {
                            await this.change({
                                target: {
                                    files: [
                                        new File(
                                            [
                                                require('fs').readFileSync(
                                                    require('path').join(electron.remote.app.getPath('downloads'), res.filename)
                                                ).buffer,
                                            ],
                                            res.filename
                                        ),
                                    ],
                                },
                            });
                        }
                    }
                ); */
            } else {
                let filename = await this.templateImpressionService.downloadTemplate('temp_' + file.filename, file, 'pilot');
                this.filename = filename;
            }
            this.downloaded = true;
        } catch (err) {
            if (err.status == 404) {
                this.notification.error('Le fichier est introuvable');
            } else {
                this.notification.error(err.data);
            }
        }
    }

    async change(ev) {
        this.notification.info('Import du fichier en cours');
        let file = ev.target.files[0];
        try {
            const res = await this.ExcelService.uploadExcelResult(
                this.$stateParams.id,
                file,
                this.model.excelAdditionalInformation.idConfigExcel,
                this.model.essai.id
            );
            this.notification.success('Fichier importé avec succès');
            await this.refresh();
        } catch (err) {
            this.notification.error(err.data);
            console.log(err.data);
        }
    }

    downloadFile(configuration) {
        console.log(configuration);
        return require('fs').writeFile(configuration.path, Buffer.from(configuration.file), (res) => {});
    }

    openUpload() {
        document.getElementById('excel_upload').click();
    }
}

MassiaEssaiExcelPlvController.$inject = ['$uibModal', 'TemplateImpressionService', 'ExcelService', '$stateParams', 'notification'];
