import validatorBuilder from 'validator-builder';

let trameValidator = null;

export default class Trame {
    constructor(data) {
        data = data || {};

        this.typeId = data.typeId || undefined;
        this.nom = data.nom || undefined;
        this.code = data.code || undefined;
        this.codeExists = data.codeExists || null;
    }

    isValid() {
        trameValidator = trameValidator || validatorBuilder.getInstanceFor('Trame');
        const validationResults = trameValidator.validate(this);
        return validationResults.isValid;
    }
}
