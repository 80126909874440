export default class SiteJoursFeriesController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'SitesCommunicationService',
		'SitesService',
		'notification',
		'$location',
		'moment',
		'$translate',
		'$filter',
		'globalizationManagementService',
		'MassiaApplicationService',
		'ArretsService',
		'RecurrenceService',
		'ProcessusService'
	];

	constructor(
		$scope,
		$state,
		$stateParams,
		SitesCommunicationService,
		SitesService,
		notification,
		$location,
		moment,
		$translate,
		$filter,
		globalizationManagementService,
		MassiaApplicationService,
		ArretsService,
		RecurrenceService,
		ProcessusService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.SitesCommunicationService = SitesCommunicationService;
		this.SitesService = SitesService;
		this.notification = notification;
		this.$location = $location;
		this.moment = moment;
		this.$translate = $translate;
		this.$filter = $filter;
		this.ArretsService = ArretsService;
		this.RecurrenceService = RecurrenceService;
		this.ProcessusService = ProcessusService;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
		this.app = MassiaApplicationService.getApplication();
		this.RecurrenceService.isUseHours = true;
	}

	async $onInit() {
		this.jferiesite = {};
		this.loading = false;
		this.openAllOnglets();
		await this.getListesJoursFeries();
		await this.setJourFerie(this.$stateParams.id);

		this.unregisterReinit = this.SitesCommunicationService.registerReinit(() => this.initForm());
		this.initForm();
	}

	$onDestroy() {
		this.unregisterReinit();
	}

	initForm() {
		this.initFormulaireNouvelleDate(true);
	}

	async getListesJoursFeries() {
		this.feriesListe = await this.SitesService.getListesJoursFeries();
		const objVide = { id: undefined, libelle: '' };
		this.feriesListe.push(objVide);
		this.feriesListe = this.$filter('orderBy')(this.feriesListe, 'libelle');
	}

	async setJourFerie(siteId) {
		this.startLoading();
		try {
			this.jferiesite = await this.SitesService.getJourFerieSiteById(siteId);
		} catch (ex) {
			if (ex.status === 404) {
				this.$state.go(this.getUrlListe(), { genre: this.$stateParams.genre }, { reload: true });
			}
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async selectArret(arret) {
		if (arret && arret.selected && arret.id) {
			try {
				this.isEditMode = true;
				const a = await this.ArretsService.getArretById(arret.id);

				this.RecurrenceService.idArret = a.id;
				this.RecurrenceService.libelleArret = a.libelle;
			} catch (ex) {
				if (ex.status === 404) {
					this.$state.go(this.getRouteCourante() + '.list', {}, { reload: true });
				}
				this.notification.error(ex.data);
			}
		} else {
			this.isEditMode = false;
		}

		this.RecurrenceService.initFormulaireNouvelleDate(true);
	}

	selectProcess(data) {
		this.RecurrenceService.idProcess = data.id;
		this.RecurrenceService.libelleProcess = data.libelle;
	}

	// boutons de validation
	async sauvegarder() {
		this.startLoading();
		try {
			await this.updateJourFerieSite();
			this.notification.success('JOURS_FERIES.UPDATED');
			return true;
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		} finally {
			this.stopLoading();
		}
	}

	async valider() {
		if (await this.sauvegarder()) {
			this.$state.go(this.getUrlFerie(), { id: this.$stateParams.id, genre: this.$stateParams.genre });
		}
	}
	async validerEtFermer() {
		this.sauvegarder();
		this.backToPreviousState();
	}

	annuler() {
		this.backToPreviousState();
	}

	backToPreviousState() {
		this.$state.go(this.getUrlListe(), { genre: this.$stateParams.genre });
	}

	async updateJourFerieSite() {
		this.startLoading();
		try {
			const returnedId = await this.SitesService.updateJourFerieSite(this.jferiesite, this.$stateParams.id);

			if (this.app === 'performance' && this.plannifArret) {
				await this.ArretsService.updateArret(this.plannifArret);
			}

			this.notification.success('JOURS_FERIES.UPDATED');
			return returnedId;
		} catch (ex) {
			this.notification.error(ex.data);
			this.reinit();
		} finally {
			this.stopLoading();
		}
	}

	ajouter() {
		const date = this.nouvRecurrence == 1 || this.nouvRecurrence == 2;
		if (date) {
			const newJFerieDate = {
				id: undefined,
				dateDebut: this.nouvRecurrence == 1 ? this.newDateSimple : this.newPeriodeDebut,
				dateFin: this.nouvRecurrence == 1 ? undefined : this.newPeriodeFin,
				canDelete: true
			};
			if (this.nouvRecurrence == 2 && this.newPeriodeFin <= this.newPeriodeDebut) {
				this.notification.error('JOURS_FERIES.DATES_PERIODE_INCORRECTES');
			} else {
				this.jferiesite.dates.push(newJFerieDate);
				this.initFormulaireNouvelleDate(false);
			}
		} else {
			let jourSemaine = undefined;
			let numSemaine = undefined;
			let numJourMois = undefined;
			let moisAnnee = undefined;
			switch (this.nouvPeriodeRecurrence) {
				case 1: //jours
					break;
				case 2: //semaines
					jourSemaine = this.newJourSemSemaine;
					break;
				case 3: //mois
					if (this.newMoisChoix == 1) {
						numJourMois = this.newNumJourMois;
					} else {
						jourSemaine = this.newJourSemMois;
						numSemaine = this.newNumSemaineMois;
					}
					break;
				case 4: //ans
					if (this.newAnChoix == 1) {
						numJourMois = this.newNumJourAn;
						moisAnnee = this.newMoisAnnee;
					} else {
						jourSemaine = this.newJourSemAn;
						numSemaine = this.newNumSemaineAn;
						moisAnnee = this.newMoisAnnee2;
					}
					break;
			}
			const newJFerieRecurrent = {
				id: undefined,
				tousLesNb: this.newNbRecurrence,
				recurrence: this.nouvPeriodeRecurrence,
				jourSemaine: jourSemaine,
				numSemaine: numSemaine,
				numJourMois: numJourMois,
				moisAnnee: moisAnnee,
				datePremiereOccurrence: this.newPremiereOccurrence,
				canDelete: true
			};
			this.jferiesite.recurrences.push(newJFerieRecurrent);
			this.initFormulaireNouvelleDate(false);
		}
	}

	formateRecurrence(ferieRecurrent) {
		let recurr = '';
		let jourSem = '';
		let numjour = '';
		let numSem = '';
		let moisAn = '';
		switch (ferieRecurrent.recurrence) {
			case 1:
				recurr = this.$translate.instant('JOURS_FERIES.JOURS');
				break;
			case 2:
				recurr = this.$translate.instant('JOURS_FERIES.SEMAINES');
				jourSem = this.getJourSemaine(ferieRecurrent.jourSemaine);
				break;
			case 3:
				recurr = this.$translate.instant('JOURS_FERIES.MOIS');
				if (ferieRecurrent.numJourMois) {
					numjour = ferieRecurrent.numJourMois;
				} else {
					jourSem = this.getJourSemaine(ferieRecurrent.jourSemaine);
					if (ferieRecurrent.numSemaine == '-1') {
						numSem = this.$translate.instant('JOURS_FERIES.DE_LA_DERNIERE_SEMAINE');
					} else {
						numSem = this.$translate.instant('JOURS_FERIES.DE_LA_SEMAINE_NUM', { numsem: ferieRecurrent.numSemaine });
					}
				}
				break;
			case 4:
				recurr = this.$translate.instant('JOURS_FERIES.ANS');
				moisAn = this.getMoisAnnee(ferieRecurrent.moisAnnee);
				if (ferieRecurrent.numJourMois) {
					numjour = ferieRecurrent.numJourMois;
				} else {
					jourSem = this.getJourSemaine(ferieRecurrent.jourSemaine);
					if (ferieRecurrent.numSemaine == '-1') {
						numSem = this.$translate.instant('JOURS_FERIES.DE_LA_DERNIERE_SEMAINE');
					} else {
						numSem = this.$translate.instant('JOURS_FERIES.DE_LA_SEMAINE_NUM', { numsem: ferieRecurrent.numSemaine });
					}
				}
				break;
		}
		let str = this.$translate.instant('JOURS_FERIES.TOUS_LES_X_RECURR', { nb: ferieRecurrent.tousLesNb, recurr: recurr });
		if (numjour != '') {
			str += ' ' + this.$translate.instant('JOURS_FERIES.LE_NUMERO_JOUR', { numjour: numjour });
		}
		if (jourSem != '') {
			str += ' ' + this.$translate.instant('JOURS_FERIES.LE_JOUR_SEMAINE', { joursem: jourSem });
		}
		if (numSem != '') {
			str += ' ' + numSem;
		}
		if (moisAn != '') {
			str += ' ' + this.$translate.instant('JOURS_FERIES.DE_MOIS_ANNEE', { mois: moisAn });
		}
		str += ', ' + this.$translate.instant('JOURS_FERIES.A_PARTIR_DU', { date: ferieRecurrent.datePremiereOccurrence });
		if (!ferieRecurrent.canDelete) {
			str += ' (' + this.jferiesite.libelleJoursFeries + ')';
		}
		return str;
	}

	getMoisAnnee(mois) {
		let moisAn = '';
		switch (mois) {
			case '1':
				moisAn = this.$translate.instant('JOURS_FERIES.JANVIER');
				break;
			case '2':
				moisAn = this.$translate.instant('JOURS_FERIES.FEVRIER');
				break;
			case '3':
				moisAn = this.$translate.instant('JOURS_FERIES.MARS');
				break;
			case '4':
				moisAn = this.$translate.instant('JOURS_FERIES.AVRIL');
				break;
			case '5':
				moisAn = this.$translate.instant('JOURS_FERIES.MAI');
				break;
			case '6':
				moisAn = this.$translate.instant('JOURS_FERIES.JUIN');
				break;
			case '7':
				moisAn = this.$translate.instant('JOURS_FERIES.JUILLET');
				break;
			case '8':
				moisAn = this.$translate.instant('JOURS_FERIES.AOUT');
				break;
			case '9':
				moisAn = this.$translate.instant('JOURS_FERIES.SEPTEMBRE');
				break;
			case '10':
				moisAn = this.$translate.instant('JOURS_FERIES.OCTOBRE');
				break;
			case '11':
				moisAn = this.$translate.instant('JOURS_FERIES.NOVEMBRE');
				break;
			case '12':
				moisAn = this.$translate.instant('JOURS_FERIES.DECEMBRE');
				break;
		}
		return moisAn;
	}

	getJourSemaine(jrSem) {
		let jourSem = '';
		switch (jrSem) {
			case '1':
				jourSem = this.$translate.instant('JOURS_FERIES.LUNDI');
				break;
			case '2':
				jourSem = this.$translate.instant('JOURS_FERIES.MARDI');
				break;
			case '3':
				jourSem = this.$translate.instant('JOURS_FERIES.MERCREDI');
				break;
			case '4':
				jourSem = this.$translate.instant('JOURS_FERIES.JEUDI');
				break;
			case '5':
				jourSem = this.$translate.instant('JOURS_FERIES.VENDREDI');
				break;
			case '6':
				jourSem = this.$translate.instant('JOURS_FERIES.SAMEDI');
				break;
			case '7':
				jourSem = this.$translate.instant('JOURS_FERIES.DIMANCHE');
				break;
		}
		return jourSem;
	}

	initFormulaireNouvelleDate(reinitTout) {
		if (reinitTout) {
			this.nouvRecurrence = 1;
			this.nouvPeriodeRecurrence = 1;
		}
		const ajd = this.moment(new Date()).format(this.dateFormat);
		this.newDateSimple = ajd;
		this.newPeriodeDebut = ajd;
		this.newPeriodeFin = ajd;
		this.newNbRecurrence = 1;
		this.newPremiereOccurrence = ajd;
		this.newJourSemSemaine = '7';
		this.newNumJourMois = 31;
		this.newMoisChoix = 1;
		this.newJourSemMois = '7';
		this.newNumSemaineMois = '-1';
		this.newAnChoix = 1;
		this.newNumJourAn = 31;
		this.newMoisAnnee = '12';
		this.newJourSemAn = '7';
		this.newNumSemaineAn = '-1';
		this.newMoisAnnee2 = '12';
	}

	deleteDate(dateFeriee) {
		const index = this.jferiesite.dates.indexOf(dateFeriee);
		this.jferiesite.dates.splice(index, 1);
	}

	deleteRecurrence(ferieRecurrent) {
		const index = this.jferiesite.recurrences.indexOf(ferieRecurrent);
		this.jferiesite.recurrences.splice(index, 1);
	}

	async changeListeJFerie(data) {
		const idjferiesite = data.id;

		const datesSite = this.$filter('filter')(this.jferiesite.dates, { canDelete: true });
		const recurrencesSite = this.$filter('filter')(this.jferiesite.recurrences, { canDelete: true });
		this.jferiesite.dates = datesSite;
		this.jferiesite.recurrences = recurrencesSite;
		if (idjferiesite) {
			const nouvJFerie = await this.SitesService.getJourFerieById(idjferiesite);
			this.jferiesite.idJoursFeries = idjferiesite;
			this.jferiesite.dates = this.jferiesite.dates.concat(nouvJFerie.dates);
			this.jferiesite.recurrences = this.jferiesite.recurrences.concat(nouvJFerie.recurrences);
			this.jferiesite.libelleJoursFeries = nouvJFerie.libelle;
		}
	}

	getUrlFerie() {
		switch (this.$stateParams.genre) {
			case 'commerciaux':
				return 'sites.feries';
			case 'clients':
				return 'clients.feries';
			case 'producteurs':
				return 'producteurs.feries';
			case 'fournisseurs':
				return 'fournisseurs.feries';
			case 'transporteurs':
				return 'transporteurs.feries';
			case 'laboratoires':
				return 'laboratoires.feries';
			case 'proprietaires':
				return 'proprietaires.feries';
			case 'locataires':
				return 'locataires.feries';
			case 'auditeurs':
				return 'auditeurs.feries';
			default:
				return 'sites.feries';
		}
	}

	getUrlListe() {
		switch (this.$stateParams.genre) {
			case 'commerciaux':
				return 'sites.list';
			case 'clients':
				return 'clients.list';
			case 'producteurs':
				return 'producteurs.list';
			case 'fournisseurs':
				return 'fournisseurs.list';
			case 'transporteurs':
				return 'transporteurs.list';
			case 'laboratoires':
				return 'laboratoires.list';
			case 'proprietaires':
				return 'proprietaires.list';
			case 'locataires':
				return 'locataires.list';
			case 'auditeurs':
				return 'auditeurs.list';
			default:
				return 'sites.list';
		}
	}

	reinit() {
		this.setJourFerie(this.$stateParams.id);
		this.JoursFeriesCommunicationService.raiseReinit();
	}

	openFirstOnglet() {
		//par défaut, on ouvert l'onglet "Entête"
		this.ongletOpen = {
			isEnteteOpen: true
		};
	}

	openAllOnglets() {
		//par défaut, on ouvert l'onglet "Entête"
		this.ongletOpen = {
			isEnteteOpen: true,
			isJoursFeriesOpen: true,
			isArretProgrammeOpen: true
		};
	}

	closeAllOnglets() {
		this.ongletOpen = {
			isEnteteOpen: false
		};
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}
}
