import validatorBuilder from 'validator-builder';

let motifRefusAdmissionValidator = null;

export default class MotifRefusAdmission {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
    }

    isValid() {
        motifRefusAdmissionValidator = motifRefusAdmissionValidator || validatorBuilder.getInstanceFor('MotifRefusAdmission');
        const validationResults = motifRefusAdmissionValidator.validate(this);
        return validationResults.isValid;
    }
}
