SuiviDroitBitumeController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'SuiviDroitBitumeService',
    'notification',
    '_',
    'MOPService'
];

export default function SuiviDroitBitumeController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    SuiviDroitBitumeService,
    notification,
    _,
    MOPService
) {
    const vm = this;
    const watchers = [];

    vm.loading = false;
    vm.stateParams = $stateParams;
    vm.suiviDroitBitume = {};

    vm.loadSuiviLieuBitume = loadSuiviLieuBitume;
    vm.getSiteCommerciaux = getSiteCommerciaux;
    vm.getQuotaBitumeClotures = getQuotaBitumeClotures;
    vm.filtrer = filtrer;
    vm.print = print;
    vm.precloturer = precloturer;
    vm.cloturer = cloturer;
    vm.deleteCloture = deleteCloture;
    vm.isDisabled = true;
    vm.idCloture = 0;
    vm.MOPService = MOPService;

    vm.$onInit = () => {
        vm.MOPService.setMop([
            { title: 'SUIVI_DROIT_BITUME.BREADCRUMBS.SUIVI_DROIT_BITUME_LIST', filename: 'GestiondesStocks.pdf', application: 'gestion' }
        ]);
    };

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    async function loadSuiviLieuBitume() {
        startLoading();

        if ($state && $state.current && $state.current.name !== 'suivi-droit-bitume.list') {
            $state.go('suivi-droit-bitume.list');
        }

        vm.suiviDroitBitume = {};
        vm.siteCommercialId = 0;
        vm.idCloture = 0;
        vm.quotaBitumeClotures = [];

        try {
            getSiteCommerciaux();
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function getSiteCommerciaux() {
        startLoading();
        try {
            vm.siteCommercial = await SuiviDroitBitumeService.getSiteCommerciaux();
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function getQuotaBitumeClotures() {
        startLoading();
        try {
            vm.quotaBitumeClotures = await SuiviDroitBitumeService.getQuotaBitumeClotures(vm.siteCommercialId);
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function filtrer() {
        startLoading();
        if (vm.idCloture != 0 || (vm.siteCommercialId && vm.dateDebut && vm.dateFin)) {
            vm.suiviDroitBitume = await SuiviDroitBitumeService.getSuiviDroitBitume(vm.siteCommercialId, vm.dateDebut, vm.dateFin, vm.idCloture);
            vm.isDisabled = false;
        } else {
            notification.error('SUIVI_DROIT_BITUME.ERROR');
        }
        stopLoading();
    }

    async function print() {
        startLoading();
        const fileName = Date.now();

        const resultat = await SuiviDroitBitumeService.printSuiviDroitbitume(vm.siteCommercialId, vm.dateDebut, vm.dateFin, vm.idCloture);

        const data = resultat.data;
        const status = resultat.status;
        let headers = resultat.headers;

        headers = headers();

        const contentType = headers['content-type'];

        const fileExtention = '.xlsx';
        const linkElement = document.createElement('a');
        try {
            const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
            const url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute('download', fileName + fileExtention);

            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(clickEvent);
        } catch (ex) {
        } finally {
        }
        stopLoading();
    }

    async function precloturer() {
        startLoading();
        try {
            await SuiviDroitBitumeService.createSuiviDroitbitume(vm.suiviDroitBitume);
            notification.success('SUIVI_DROIT_BITUME.CREATED');
            loadSuiviLieuBitume();
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function cloturer() {
        startLoading();
        try {
            await SuiviDroitBitumeService.updateSuiviDroitbitume(vm.idCloture);
            notification.success('SUIVI_DROIT_BITUME.CLOTURED');
            loadSuiviLieuBitume();
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteCloture() {
        if (vm.idCloture) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('SUIVI_DROIT_BITUME.DELETE.TITLE'),
                modalMsg: $translate.instant('SUIVI_DROIT_BITUME.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await SuiviDroitBitumeService.deleteClotureById(vm.idCloture);
                    notification.success($translate.instant('SUIVI_DROIT_BITUME.DELETE.SUCCESS'));
                    loadSuiviLieuBitume();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
