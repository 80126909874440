(function (angular, undefined) {
    'use strict';

    var modalTypes = {
        DEFAULT: "DEFAULT",
        READ: "READ",
        CREATE: "CREATE",
        DUPLICATE: "DUPLICATE",
        UPDATE: "UPDATE"
    };

    angular.module('blocks.modal')
        .constant('modalTypes', modalTypes);
})(angular);