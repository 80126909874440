import MesuresOrdreController from './mesures.ordre.controller';

export default {
    template: require('./mesures.ordre.tpl.html'),
    controller: MesuresOrdreController,
    bindings: {
        mesures: '=',
        onDelete: '&',
        onUpdate: '&',
        onDeleteAll: '&',
        onUpdateOrdre: '&'
    }
};
