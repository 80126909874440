import CommandeEntete from './commande.entete.model';

export default class CommandeEnteteController {
	static $inject = ['$stateParams', '$translate', 'CommandesCommunicationService', 'CommandesService', 'notification', 'OffresService'];

	constructor($stateParams, $translate, CommandesCommunicationService, CommandesService, notification, OffresService) {
		this.$stateParams = $stateParams;
		this.$translate = $translate;
		this.CommandesCommunicationService = CommandesCommunicationService;
		this.CommandesService = CommandesService;
		this.notification = notification;
		this.OffresService = OffresService;
		this.listeCommerciaux = [];
	}

	async $onInit() {
		this.unregisterReinit = this.CommandesCommunicationService.registerReinit(() => this.initForm());
		this.societes = await this.CommandesService.getSocietes();
		this.types = await this.CommandesService.getCommandeTypes();
		const filter = [];
		if (this.commande.entete.clientId) {
			this.loadChantiers();
		}
		if (this.$stateParams.id) {
			this.setCommandeAsTypeAhead();
		}
		this.isEtatSelected = false;
		this.isEtatAnnulee = false;
		this.isEtatConfirmee = false;
		this.commande.canUpdate = false;

		if (!this.commande.entete.id) {
			this.commande.entete.etat = 0;
		}
		if (this.commande.entete.etat == 0) {
			this.commande.canUpdate = true;
			this.etatText = this.$translate.instant('COMMANDES.ETATS.CONFIRMEE');
			this.isEtatSelected = true;
			this.isEtatConfirmee = true;
		}
		if (this.commande.entete.etat == 1) {
			this.commande.canUpdate = true;
			this.isEtatSelected = true;
		}
		if (this.commande.entete.etat == 2) {
			this.etatText = this.$translate.instant('COMMANDES.ETATS.CLOTUREE');
		}
		if (this.commande.entete.etat == 3) {
			this.isEtatAnnulee = true;
			this.isEtatSelected = true;
		}
		if (this.commande.entete.societeId != null) {
			this.clients = await this.CommandesService.getClientsCombo(this.commande.entete.societeId, this.siteClient);
		}
		this.etatString = this.commande.entete.etat.toString();

		this.getCodeAuto();
		this.getCommandeDevise();

		if (!this.hasTransport) {
			this.commande.entete.isTransporte = false;
			this.commande.entete.isFranco = false;
		}
	}

	changeEtat() {
		this.commande.entete.etat = parseInt(this.etatString);
	}

	async getCodeAuto() {
		try {
			this.commande.entete.estCodeAuto = await this.CommandesService.estCodeAuto();
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async loadClients() {
		this.clients = await this.CommandesService.getClientsCombo(this.commande.entete.societeId);
	}

	async reloadClientsOnChange() {
		if (this.commande.entete.societeId != null) {
			this.clients = await this.CommandesService.getClientsCombo(this.commande.entete.societeId, this.siteClient);
			this.commande.entete.chantierId = null;
		}
	}

	async getNomClients(valeur) {
		try {
			const res = await this.CommandesService.getNomClients(valeur);
			return res;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async loadChantiers() {
		try {
			let id = this.commande.entete.clientId;
			if (this.commande.entete.clientId == 0) {
				id = null;
			}
			this.chantiers = await this.CommandesService.getChantiersCombo(id);
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}

	setTypeAheadAs(val) {
		if (!this.commande) {
			this.commande = {};
		}
		switch (val) {
			case 'siteClient':
				this.commande.entete.clientId = this.siteClient.id;
				this.commande.entete.nomClient = this.siteClient.libelle;
				this.loadChantiers();
				break;
			case 'lieu':
				this.commande.entete.lieuId = this.lieu.id;
				this.commande.entete.lieu = this.lieu.libelle;
				this.commande.entete.lieuCode = this.lieu.code;
				break;
		}
	}

	videLieu() {
		this.lieu = {
			id: undefined,
			code: undefined,
			libelle: undefined,
			codeEtLib: ''
		};
		this.commande.entete.lieuId = undefined;
		this.commande.entete.lieu = undefined;
		this.commande.entete.lieuCode = undefined;
	}

	// extraire les dates de Debut et de Fin du chantier sélectionné
	async setChantierDates() {
		this.chantiers.forEach(function (element) {
			if (element.id == this.commande.entete.chantierId) {
				this.commande.entete.dateFinChantier = element.dateFin;
				return;
			}
		}, this);
	}

	changeIsTransporte() {
		if (this.commande.entete.isTransporte == false) {
			this.commande.entete.isFranco = false;
		}
	}

	setCommandeAsTypeAhead() {
		this.siteClient = {
			id: this.commande.entete.clientId,
			libelle: this.commande.entete.nomClient
		};
		this.lieu = {
			id: this.commande.entete.lieuId,
			code: this.commande.entete.lieuCode,
			libelle: this.commande.entete.lieu,
			codeEtLib: this.commande.entete.lieuId ? this.commande.entete.lieu + ' [' + this.commande.entete.lieuCode + ']' : ''
		};
		this.loadCommerciaux(this.commande.entete.societeId);
	}

	$onDestroy() {
		this.unregisterReinit();
	}

	async getLieux(valeur) {
		const lieux = await this.CommandesService.getLieux(valeur);
		return lieux;
	}

	async changeSociete() {
		this.clients = null;
		this.siteClient = null;
		this.commande.entete.chantierId = null;
		this.commande.entete.clientId = null;
		this.getCommandeDevise();
		this.loadSiteCommerciaux();
		this.loadCommerciaux(this.commande.entete.societeId);
	}

	async getCommandeDevise() {
		if (this.commande.entete.societeId) {
			const selectedSociete = await this.CommandesService.getSocieteById(this.commande.entete.societeId);
			if (selectedSociete.paysId) {
				const pays = await this.CommandesService.getPaysById(selectedSociete.paysId);
				this.commande.devise = pays.symboleDevise;
				this.commande.entete.deviseId = pays.deviseId;
			}
		}
	}

	async loadCommerciaux(id) {
		this.listeCommerciaux = await this.OffresService.getPersonnes(id);
	}
}
