export default function Configure($stateProvider) {
	$stateProvider.state('nomenclatures', {
		parent: 'gestion',
		url: '/nomenclatures',
		views: {
			gestion: {
				template: '<ui-view></ui-view>'
			}
		},
		ncyBreadcrumb: {
			label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
		}
	});

	$stateProvider.state('nomenclatures.list', {
		url: '/list',
		template: '<nomenclatures></nomenclatures>',
		rights: { domain: 'nomenclature', right: 'read' },
		ncyBreadcrumb: {
			label: '{{ "NOMENCLATURES.BREADCRUMBS.NOMENCLATURES_LIST" | translate}}'
		},
		navigation: {
			menu: 'inertes',
			translate: 'NOMENCLATURES.BREADCRUMBS.NOMENCLATURES_LIST',
			order: 0
		},
		search: 'NOMENCLATURES.BREADCRUMBS.NOMENCLATURES_LIST'
	});

	$stateProvider.state('nomenclatures.new', {
		url: '/new',
		template: '<nomenclature-form></nomenclature-form>',
		rights: { domain: 'nomenclature', right: 'create' },
		ncyBreadcrumb: {
			parent: 'nomenclatures.list',
			label: '{{ "NOMENCLATURES.BREADCRUMBS.NOMENCLATURE_NEW" | translate}}'
		},
		search: 'NOMENCLATURES.BREADCRUMBS.NOMENCLATURE_NEW'
	});

	$stateProvider.state('nomenclatures.edit', {
		url: '/{id}/edit',
		template: '<nomenclature-form></nomenclature-form>',
		rights: { domain: 'nomenclature', right: 'update' },
		ncyBreadcrumb: {
			parent: 'nomenclatures.list',
			label: '{{ "NOMENCLATURES.BREADCRUMBS.NOMENCLATURE_EDIT" | translate }}'
		}
	});
}

Configure.$inject = ['$stateProvider'];
