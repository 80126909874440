import UtilisateurPasswordController from '../utilisateur-password-form/utilisateur.password.form.controller';

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS

/* @ngInject */
export default function PersonnesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	PersonnesService,
	PersonnesTypesService,
	notification,
	MassiaApplicationService,
	MassiaAuthService,
	DonneeFiltreeUtilisateursService,
	$transitions,
	MOPService
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	let selectedPersonneId = undefined;
	vm.lastSelected = undefined;

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.personnes = [];
	vm.profils = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};

	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];
	vm.getTypes = getTypes;

	vm.selectPersonne = selectPersonne;
	vm.isPersonneSelected = isPersonneSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadPersonnes = loadPersonnes;
	vm.deletePersonne = deletePersonne;
	vm.activatePersonne = activatePersonne;
	vm.setSelected = setSelected;
	vm.eraseAllFilters = eraseAllFilters;
	vm.updatePassword = updatePassword;
	vm.getProfils = getProfils;
	vm.getGenre = getGenre;
	vm.retourneAListe = retourneAListe;
	vm.getDomaine = getDomaine;
	vm.getUrl = getUrl;
	vm.getUrlListe = getUrlListe;
	vm.exporter = exporter;

	vm.isEnabled = true;
	vm.MOPService = MOPService;
	vm.state = $state;

	/* vm.canRead = false;
    vm.canCreate = false;
    vm.canUpdate = false;
    vm.canDelete = false; */

	vm.$onInit = () => {
		vm.MOPService.setMop([{ title: 'Gestion du personnel', filename: 'GestionDuPersonnel.pdf', application: 'massia' }]);
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "personnes.list", on désélectionne la ligne sélectionnée (s'il y en a)
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}

		getTypes();
		vm.user = MassiaAuthService.getAuthenticatedUser();
	};

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	async function getTypes(event, toState) {
		vm.types = await PersonnesTypesService.getAll();
	}

	function setSelected(personne) {
		if (personne && personne.id) {
			vm.selectedPersonneId = $stateParams.id;
			vm.lastSelected = vm.selectedPersonneId;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			vm.selectedPersonneId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state && $state.current && $state.current.name && $state.current.name === getUrlDetail() && $stateParams && $stateParams.id;
	}

	function selectPersonne(personne) {
		if (personne && personne.id) {
			if (vm.selectedPersonneId !== personne.id) {
				vm.selectedPersonneId = personne.id;
				showDetail(personne.id);
			} else {
				vm.selectedPersonneId = undefined;
				closeDetail();
			}
		}
	}

	function isPersonneSelected(personne) {
		return personne && personne.id && selectedPersonneId === personne.id;
	}

	function showDetail(personneId) {
		$state.go(getUrlDetail(), { id: personneId });
	}

	function closeDetail() {
		vm.retourneAListe();
	}

	async function loadPersonnes(tableState) {
		startLoading();

		vm.genre = getGenre();

		//quand on rafraîchit la grille, on revient sur "personnes.list", et on ferme la vue détail d'une personne
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== getUrlListe()) {
			vm.retourneAListe();
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.personnes = [];

		try {
			const data = await PersonnesService.getPersonnes(
				vm.isEnabled,
				vm.genre,
				filters,
				sorts,
				pagination,
				MassiaApplicationService.getApplication()
			);
			vm.personnes = data.items;
			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
			/* if (vm.personnes.length > 0) {
                vm.canRead = vm.personnes[0].canRead;
                vm.canCreate = vm.personnes[0].canCreate;
                vm.canUpdate = vm.personnes[0].canUpdate;
                vm.canDelete = vm.personnes[0].canDelete;
            } */
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deletePersonne(personne) {
		vm.donneeFiltreeUtilisateur = await DonneeFiltreeUtilisateursService.getDonneeFiltreeUtilisateur(
			personne.id,
			MassiaApplicationService.getApplication()
		);

		if (personne && personne.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('PERSONNES.DELETE.TITLE', { libelle: personne.nom }),
				modalMsg: $translate.instant('PERSONNES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					if (vm.donneeFiltreeUtilisateur) {
						if (
							vm.donneeFiltreeUtilisateur &&
							vm.donneeFiltreeUtilisateur.idFamilles &&
							vm.donneeFiltreeUtilisateur.idFamilles.length > 0
						) {
							vm.donneeFiltreeUtilisateur.idFamilles = [];
						}
						if (
							vm.donneeFiltreeUtilisateur &&
							vm.donneeFiltreeUtilisateur.idFiltreSites &&
							vm.donneeFiltreeUtilisateur.idFiltreSites.length > 0
						) {
							vm.donneeFiltreeUtilisateur.idFiltreSites = [];
						}
						if (
							vm.donneeFiltreeUtilisateur &&
							vm.donneeFiltreeUtilisateur.idFiltreSocietes &&
							vm.donneeFiltreeUtilisateur.idFiltreSocietes.length > 0
						) {
							vm.donneeFiltreeUtilisateur.idFiltreSocietes = [];
						}
						if (
							vm.donneeFiltreeUtilisateur &&
							vm.donneeFiltreeUtilisateur.idMetiers &&
							vm.donneeFiltreeUtilisateur.idMetiers.length > 0
						) {
							vm.donneeFiltreeUtilisateur.idMetiers = [];
						}
					}
					await DonneeFiltreeUtilisateursService.updateFiltreUtilisateur(
						vm.donneeFiltreeUtilisateur,
						MassiaApplicationService.getApplication()
					);

					await PersonnesService.deletePersonneById(personne.id);

					notification.success($translate.instant('PERSONNES.DELETE.SUCCESS'));

					selectedPersonneId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
					if (vm.params.id) {
						vm.retourneAListe();
					}

					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

					loadPersonnes();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function activatePersonne(idPersonne) {
		await PersonnesService.activatePersonne(idPersonne);
		loadPersonnes();
	}

	function eraseAllFilters() {
		loadPersonnes();
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}

	function updatePassword(personne) {
		selectedPersonneId = personne.id;

		ModalService.open({
			template: require('../utilisateur-password-form/utilisateur.password.form.html'),
			controller: UtilisateurPasswordController,
			controllerAs: '$ctrl',
			size: 'lg',
			resolve: {
				data: function () {
					return {
						id: personne.id,
						nom: personne.nom,
						prenom: personne.prenom
					};
				}
			}
		});
	}

	function getUrlListe() {
		switch ($stateParams.genre) {
			case 'personne':
				if (MassiaApplicationService.getApplication() == 'performance') {
					return 'personnes-perf.list';
				}
				return 'personnes.list';
			case 'utilisateur':
				return 'utilisateurs.list';
			case 'chauffeur':
				return 'chauffeurs.list';
			case 'contact':
				return 'contacts.list';
			default:
				return 'personnes.list';
		}
	}

	function getUrl() {
		switch ($stateParams.genre) {
			case 'personne':
				if (MassiaApplicationService.getApplication() == 'performance') {
					return 'personnes-perf';
				}
				return 'personnes';
			case 'utilisateur':
				return 'utilisateurs';
			case 'chauffeur':
				return 'chauffeurs';
			case 'contact':
				return 'contacts';
			default:
				return 'personnes';
		}
	}

	function getUrlDetail() {
		switch ($stateParams.genre) {
			case 'personne':
				if (MassiaApplicationService.getApplication() == 'performance') {
					return 'personnes-perf.list.detail';
				}
				return 'personnes.list.detail';
			case 'utilisateur':
				return 'utilisateurs.list.detail';
			case 'chauffeur':
				return 'chauffeurs.list.detail';
			case 'contact':
				return 'contacts.list.detail';
			default:
				return 'personnes.list.detail';
		}
	}

	function getDomaine() {
		switch ($stateParams.genre) {
			case 'personne':
				return 'personnel';
			case 'utilisateur':
				return 'utilisateurs';
			case 'chauffeur':
				return 'chauffeurs';
			case 'contact':
				return 'contacts';
			default:
				return 'personnel';
		}
	}

	function retourneAListe() {
		$state.go(vm.getUrlListe());
	}

	async function getProfils(personne) {
		vm.profils = personne.profilLibelles || [];

		if (vm.profils.length > 0) {
			vm.TooltipProfils = '<ul class="text-left">';
			vm.TooltipProfils += _.map(vm.profils, function (d) {
				return '<li>' + d + '</li>';
			}).join('');
			vm.TooltipProfils += '</ul>';
		} else {
			vm.TooltipProfils = null;
		}
	}

	function getGenre() {
		switch ($stateParams.genre) {
			case 'personne':
				return 1;
			case 'utilisateur':
				return 2;
			case 'chauffeur':
				return 3;
			case 'contact':
				return 4;
			default:
				return 1;
		}
	}

	async function exporter(modeImpression) {
		startLoading();
		try {
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const fileName = 'Liste_salaries';
			let resultat = undefined;
			try {
				resultat = await PersonnesService.exportListePersonnes(modeImpression, filters, sorts);
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('PERSONNES.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				const status = resultat.status;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
				} finally {
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		} finally {
			stopLoading();
		}
	}
}
