(function () {
    'use strict';

    /* Cette directive permet de "disabled" tous les champs html classiques d'un fieldset
    *  Dans le cas de directives (comme par exemple datepicker), il faut gérer la désactivation manuellement.
    *  Cette directive est nécessaire car IE ne désactive pas les champs via le markup HTML classique
    *  Exemple :
    *  <fieldset ac-fieldset-disabled="ctrl.isReadOnly">
    */

    angular.module('blocks.helpers').directive('acFieldsetDisabled', acFieldsetDisabled);
    acFieldsetDisabled.$inject = ['$compile'];

    function acFieldsetDisabled($compile) {
        return {
            restrict: 'A',
            scope: {
                acFieldsetDisabled: '=?'
            },
            link: function (scope, element, attrs) {
                scope.$watch(function () {
                    return scope.acFieldsetDisabled;
                }, function (acFieldsetDisabled) {
                    element.find('input').prop('disabled', acFieldsetDisabled);
                    element.find('textarea').prop('disabled', acFieldsetDisabled);
                    element.find('button').prop('disabled', acFieldsetDisabled);
                });
            }
        };
    }
})();
