import { copy, isNumber } from 'angular';

export default class SyntheseEntityConsistanceController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'SyntheseEntitiesCommunicationService',
		'_',
		'notification',
		'MassiaApplicationService',
		'$translate',
		'SyntheseEntitiesService',
		'NormesService',
		'ReferencesControleService',
		'ProduitsService',
		'$filter',
		'ColorPickerService',
		'$uibModal',
		'globalizationManagementService',
		'moment',
		'TemplateImpressionService',
		'Interpreteur',
		'$timeout'
	];

	constructor(
		$scope,
		$state,
		$stateParams,
		SyntheseEntitiesCommunicationService,
		_,
		notification,
		MassiaApplicationService,
		$translate,
		SyntheseEntitiesService,
		NormesService,
		ReferencesControleService,
		ProduitsService,
		$filter,
		ColorPickerService,
		$uibModal,
		globalizationManagementService,
		moment,
		TemplateImpressionService,
		Interpreteur,
		$timeout
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$filter = $filter;
		this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
		this._ = _;
		this.notification = notification;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$translate = $translate;
		this.SyntheseEntitiesService = SyntheseEntitiesService;
		this.NormesService = NormesService;
		this.ReferencesControleService = ReferencesControleService;
		this.ProduitsService = ProduitsService;
		this.ColorPickerService = ColorPickerService;
		this.$uibModal = $uibModal;
		this.globalizationManagementService = globalizationManagementService;
		this.moment = moment;
		this.TemplateImpressionService = TemplateImpressionService;
		this.Interpreteur = Interpreteur;
		this.$timeout = $timeout;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async $onInit() {
		this.unregisterReinit = this.SyntheseEntitiesCommunicationService.registerReinit(() => this.initForm());
		this.controles = [];
		this.obj = this.$state.params.obj;
		if (this.$state.params.obj) {
			this.Interpreteur.init(undefined, undefined);
			this.Interpreteur.calculatedFormules = [];

			this.form = {};
			this.ongletOpen = { carteControle: true };

			this.model = this.$state.params.obj || {};
			this.resultat = {};

			let nbPermutationMax = this.model.essais.length;
			const lstPermutedEssais = [];
			while (nbPermutationMax > 0) {
				const controleEssai = this.model.essais[nbPermutationMax - 1];
				if (controleEssai.isComplementaire && lstPermutedEssais.indexOf(controleEssai.idEssai) < 0) {
					this.updateEssaiPosition(nbPermutationMax - 1);
					lstPermutedEssais.push(controleEssai.idEssai);
				} else {
					nbPermutationMax--;
				}
			}

			this.startLoading();
			this.resultat = await this.preparePrelevements();
			this.stopLoading();

			this.conformites = [];
			for (let i = 0; i < this.model.formattedResult.entete.titresSousEssais.length; i++) {
				const sse = this.model.formattedResult.entete.titresSousEssais[i];
				if (sse.isForControle) {
					const essaiConformite = await this.prepareConformitesData(sse);
					if (essaiConformite && essaiConformite.lines && essaiConformite.lines[0].value > 0) {
						//si conformité et nb valeurs totale à l'étude > 0
						essaiConformite.graphData = await this.prepareGraphData(sse.idEssai, sse.id);

						this.conformites.push(essaiConformite);
					}
				}
			}
		} else {
			this.endConsistance();
		}
	}

	$onDestroy() {
		this.unregisterReinit();
	}

	async preparePrelevements() {
		const resultat = {};
		resultat.entete = { titresAffichage: [], titresEssais: [], titresSousEssais: [] };
		resultat.lignes = [];
		resultat.stats = [];
		resultat.produits = await this.prepareProduits();
		resultat.produitsCom = await this.prepareProduitsCom();

		resultat.entete.titresAffichage = this.model.formattedResult.entete.titresAffichage;
		resultat.entete.titresEssais = this.model.formattedResult.entete.titresEssais;
		if (this.model.formattedResult.stats) {
			resultat.stats = this.model.formattedResult.stats.filter(function (e) {
				return e.isImprimable;
			});
		}

		resultat.entete.titresSousEssais = this.model.formattedResult.entete.titresSousEssais;

		let lastProductId = null;
		const lignes = [];
		for (let l = 0; l < this.model.formattedResult.lignes.length; l++) {
			const line = this.model.formattedResult.lignes[l];
			//test de a présence de l'essai à contrôler
			//const isOkForControl = line.valeursEssais.findIndex(e => e.isForControle && e.valeur) >= 0;
			//if (isOkForControl) {
			const produit = resultat.produits.find(function (p) {
				return p.idsPrelevement.indexOf(line.idPrelevement) >= 0;
			});
			const produitCom = resultat.produitsCom.find(function (p) {
				return p.idsPrelevement.indexOf(line.idPrelevement) >= 0;
			});
			if (produit) {
				const prelevement = this.model.resultat.prelevementBlock.find(function (p) {
					return p.idPrelevement === line.idPrelevement;
				});
				//const produit = this.resultat.produits.find(function (p) { return p && p.idProduit === prelevement.idProduit; });

				//if (produit) e.pourcentageComposant = produit.pourcentageComposant + '%';
				line.nbProduits = resultat.produits.length;

				if (prelevement.idProduit !== lastProductId) {
					line.position = 1;
				}

				lastProductId = prelevement.idProduit;
				line.idProduit = prelevement.idProduit;
				line.caractProduit = await this.ProduitsService.getProduitValeursCaracteristiquesById(produit.idProduit);
				line.codePrelevement = prelevement.codePrelevement;
				line.libelleProduit = prelevement.libelleProduit;
				line.datePrelevement = prelevement.datePrelevement;
				line.ecartee = false;
			}
			if (produitCom && produitCom.id > 0) {
				const prelevement = this.model.resultat.prelevementBlock.find(function (p) {
					return p.idPrelevement === line.idPrelevement;
				});
				//const produit = this.resultat.produits.find(function (p) { return p && p.idProduit === prelevement.idProduit; });

				//if (produit) e.pourcentageComposant = produit.pourcentageComposant + '%';
				line.nbProduits = resultat.produits.length;

				if (prelevement.idProduitCom !== lastProductId) {
					line.position = 1;
				}

				lastProductId = prelevement.idProduitCom;
				line.idProduitCom = prelevement.idProduitCom;
				line.caractProduitCom = await this.ProduitsService.getProduitValeursCaracteristiquesById(produitCom.idProduitCom);
				line.codePrelevement = prelevement.codePrelevement;
				line.libelleProduitCom = prelevement.libelleProduitCom;
				line.datePrelevement = prelevement.datePrelevement;
				line.ecartee = false;
			}

			lignes.push(line);

			//}
		}

		resultat.lignes = lignes.sort((a, b) => {
			const db = this.moment(b.datePrelevement, this.dateFormat).unix();
			const da = this.moment(a.datePrelevement, this.dateFormat).unix();
			return da - db;
		});
		return angular.copy(resultat);
	}

	async prepareControle(idEssai) {
		let controle = {};
		//On récupère la référence, la norme si pas de correspondance entre la référence et l'essai courant
		if (this.model.synthese.idReference && (!controle.specs || controle.specs.length <= 0)) {
			if (!this.controleReference) {
				this.controleReference = await this.ReferencesControleService.getReferenceById(this.model.synthese.idReference);
			}
			controle = angular.copy(this.controleReference);

			controle.specs = controle.mesures
				? angular.copy(
						controle.mesures.filter(function (e) {
							return e.idEssai === idEssai;
						})
				  )
				: [];
			delete controle.mesures;
		}
		if (this.model.synthese.idNorme && (!controle.specs || controle.specs.length <= 0)) {
			if (!this.controleNorme) {
				this.controleNorme = await this.NormesService.getNormeById(this.model.synthese.idNorme);
				this.controleNorme.specs = await this.NormesService.getSpecifications(this.controleNorme.id);
			}
			controle = angular.copy(this.controleNorme);

			const specFinal = [];
			for (let i = 0; i < controle.specs.length; i++) {
				for (let j = 0; j < controle.specs[i].essais.length; j++) {
					const obj = controle.specs[i].essais[j];
					if (obj.idEssai === idEssai) {
						obj.codeEssai = obj.code;
						obj.calculFormule = obj.formuleCalcStat;
						specFinal.push(obj);
					}
				}
			}
			controle.specs = specFinal;
		}
		if (!controle.specs || controle.specs.length <= 0) {
			controle = {};
		}

		return controle;
	}

	async prepareProduits() {
		const produits = [];
		if (this.model) {
			try {
				// on met a jour les donnees du scope 'resultat'
				this.resultat = {};
				for (let i = 0; i < this.model.resultat.prelevementBlock.length; i++) {
					const prelev = this.model.resultat.prelevementBlock[i];

					const prelevement = this.model.formattedResult.lignes.find(function (p) {
						return p.idPrelevement === prelev.idPrelevement;
					});
					if (prelevement) {
						const emptyLine =
							prelevement.valeursEssais.findIndex(function (e) {
								return e.valeur;
							}) < 0;
						if (!emptyLine) {
							let findProduit = produits.find(function (e) {
								return e.idProduit === prelev.idProduit;
							});
							if (!findProduit) {
								findProduit = {
									id: prelev.idProduit,
									idProduit: prelev.idProduit,
									idsPrelevement: [prelev.idPrelevement],
									code: prelev.codeProduit,
									libelle: prelev.libelleProduit
								};
								//findProduit.caracteristiques = await this.ProduitsService.getProduitValeursCaracteristiquesById(prelev.idProduit);
								this.ongletOpen[prelev.idProduit] = true;
								produits.push(findProduit);
							} else {
								findProduit.idsPrelevement.push(prelev.idPrelevement);
							}
						}
					}
				}
			} catch (ex) {}
		} else {
			this.$state.go('syntheseentities.list');
		}

		return produits;
	}

	async prepareProduitsCom() {
		const produitsCom = [];
		if (this.model) {
			try {
				// on met a jour les donnees du scope 'resultat'
				this.resultat = {};
				for (let i = 0; i < this.model.resultat.prelevementBlock.length; i++) {
					const prelev = this.model.resultat.prelevementBlock[i];

					const prelevement = this.model.formattedResult.lignes.find(function (p) {
						return p.idPrelevement === prelev.idPrelevement;
					});
					if (prelevement) {
						const emptyLine =
							prelevement.valeursEssais.findIndex(function (e) {
								return e.valeur;
							}) < 0;
						if (!emptyLine) {
							let findProduit = produitsCom.find(function (e) {
								return e.idProduitCom === prelev.idProduitCom;
							});
							if (!findProduit) {
								findProduit = {
									id: prelev.idProduitCom,
									idProduitCom: prelev.idProduitCom,
									idsPrelevement: [prelev.idPrelevement],
									code: prelev.codeProduitCom,
									libelle: prelev.libelleProduitCom
								};
								//findProduit.caracteristiques = await this.ProduitsService.getProduitValeursCaracteristiquesById(prelev.idProduit);
								this.ongletOpen[prelev.idProduitCom] = true;
								produitsCom.push(findProduit);
							} else {
								findProduit.idsPrelevement.push(prelev.idPrelevement);
							}
						}
					}
				}
			} catch (ex) {}
		} else {
			this.$state.go('syntheseentities.list');
		}

		return produitsCom;
	}

	updateEssaiPosition(position) {
		const mainEssai = this.model.essais[position];
		mainEssai.isForControle = true;
		//this.model.essais.splice(position, 1);
		//this.model.essais.splice(0, 0, mainEssai);

		const titreEssai = this.model.formattedResult.entete.titresEssais[position];
		titreEssai.isForControle = true;
		//this.model.formattedResult.entete.titresEssais.splice(position, 1);
		//this.model.formattedResult.entete.titresEssais.splice(0, 0, titreEssai);

		for (let i1 = 0; i1 < this.model.formattedResult.entete.titresSousEssais.length; i1++) {
			const titreSsEssai = this.model.formattedResult.entete.titresSousEssais[i1];
			if (titreSsEssai && titreSsEssai.idEssai === mainEssai.idEssai) {
				titreSsEssai.isForControle = true;
				//this.model.formattedResult.entete.titresSousEssais.splice(i, 1);
				//this.model.formattedResult.entete.titresSousEssais.splice(0, 0, titreSsEssai);
			}
		}

		for (let i2 = 0; i2 < this.model.sousEssaisTamis.length; i2++) {
			const ssTamis = this.model.sousEssaisTamis[i2];
			if (ssTamis && ssTamis.idEssai === mainEssai.idEssai) {
				ssTamis.isForControle = true;
				//this.model.sousEssaisTamis.splice(i, 1);
				//this.model.sousEssaisTamis.splice(0, 0, ssTamis);
			}
		}

		this.model.formattedResult.lignes.map(function (l) {
			for (let i3 = 0; i3 < l.valeursEssais.length; i3++) {
				const valEssai = l.valeursEssais[i3];
				if (valEssai && valEssai.idEssai === mainEssai.idEssai) {
					valEssai.isForControle = true;
					//l.valeursEssais.splice(i, 1);
					//l.valeursEssais.splice(0, 0, valEssai);
				}
			}
		});

		this.model.formattedResult.stats.map(function (s) {
			for (let i4 = 0; i4 < s.statsEssais.length; i4++) {
				const valStat = s.statsEssais[i4];
				if (valStat && valStat.idEssai === mainEssai.idEssai) {
					valStat.isForControle = true;
					//s.statsEssais.splice(i, 1);
					//s.statsEssais.splice(0, 0, valStat);
				}
			}
		});

		this.mainEssai = mainEssai;
	}

	prepareGraphData(idEssai, idSsEss) {
		const graphData = { Abscisse: [], titreX: this.$translate.instant('SYNTHENTS.DATE'), titreY1: this.mainEssai.libelleEssai };

		//1/Récupérer les tamis à afficher
		const lignes = this.resultat.lignes;
		if (lignes && lignes.length > 0) {
			for (let l = 0; l < lignes.length; l++) {
				const ligne = lignes[l];

				//2/Récupérer les données de chaque prélèvement
				if (ligne && ligne.valeursEssais) {
					const sse = ligne.valeursEssais.find((e) => e.idEssai === idEssai && e.idSousEssai === idSsEss);
					const produit = this.resultat.produits.find(function (e) {
						return e.idsPrelevement.indexOf(ligne.idPrelevement) >= 0;
					});

					if (sse && produit) {
						const date = this.moment(ligne.datePrelevement, this.dateFormat);
						let dateIndex = graphData.Abscisse.findIndex((e) => e.value.format(this.dateFormat) === date.format(this.dateFormat));
						if (dateIndex < 0) {
							graphData.Abscisse.push({ label: this.$translate.instant('SYNTHENTS.DATE'), value: date });
							dateIndex = graphData.Abscisse.length - 1;
						}

						const key = produit.idProduit;
						if (!graphData['courbe_' + key]) {
							graphData['courbe_' + key] = {
								label: produit.libelle,
								idEssai: sse.idEssai,
								idSousEssai: sse.idSousEssai,
								values: [],
								hidden: sse.hidden
							};
						}

						for (const o in graphData) {
							if (o.startsWith('courbe_')) {
								const iValues = graphData[o].values.length;
								for (let i = iValues; i <= dateIndex; i++) {
									if (!graphData[o].values[i]) {
										graphData[o].values.push({
											pourcPassant: '',
											pourcPassantArrondi: '',
											pourcRefPartiel: '',
											pourcRefPartielArrondi: ''
										});
									}
								}
							}
						}

						graphData['courbe_' + key].values[dateIndex].pourcPassant = parseFloat(sse.valeur);
						graphData['courbe_' + key].values[dateIndex].pourcPassantArrondi = Math.round(sse.valeur, 2).toFixed(0);
						graphData['courbe_' + key].values[dateIndex].pourcRefPartiel = '';
						graphData['courbe_' + key].values[dateIndex].pourcRefPartielArrondi = '';
					}
				}
			}

			graphData.nbElement = graphData.Abscisse.length;
			graphData.idEssai = idEssai;
			graphData.override = {
				chartType: 'line',
				fill: false,
				typeAxeX: 'linear',
				typeAxeY: 'linear',
				displayAllAbscisses: true,
				spanGaps: true,
				positionLegende: 'bottom'
			};
		}

		return graphData;
	}

	chartLoaded(index) {
		this['hiddeInMainPage' + index] = true;
	}

	async prepareConformitesData(sse) {
		const conformites = {
			idEssai: sse.idEssai,
			libelleEssai: sse.libelleEssai,
			idSousEssai: sse.id,
			codeSousEssai: sse.codeSsEss,
			libelleSousEssai: sse.titre.replace(sse.codeSsEss + ' / ', ''),
			lines: []
		};

		conformites.lines.push({ title: 'Total', value: 0 });
		conformites.lines.push({ title: 'Conformes', value: 0 });
		conformites.lines.push({ title: 'En alerte', value: 0 });
		conformites.lines.push({ title: 'Non conformes', value: 0 });
		conformites.lines.push({ title: 'Niveau de Qualité Acceptable', value: 0 });

		if (sse && this.resultat.produits) {
			const controle = await this.prepareControle(sse.idEssai);
			this.controles[sse.idEssai] = controle;

			for (let p = 0; p < this.resultat.produits.length; p++) {
				const idProduit = this.resultat.produits[p].idProduit;
				const lines = this.resultat.lignes
					? this.resultat.lignes.filter(function (e) {
							return e.idProduit === idProduit;
					  })
					: [];

				for (let l = 0; l < lines.length; l++) {
					const ssessai = lines[l].valeursEssais.find(function (e) {
						return e.idEssai === sse.idEssai && e.idSousEssai === sse.id;
					});
					const allValues = lines.map(function (line) {
						if (line.valeursEssais) {
							const valueEssai = line.valeursEssais.find(function (e) {
								return e.idEssai === sse.idEssai && e.idSousEssai === sse.id;
							});

							if (valueEssai && valueEssai.valeur && valueEssai.valeur == '-1') {
								valueEssai.valeur = '';
							}

							if (valueEssai && valueEssai.valeur) {
								return valueEssai.valeur;
							}
						}
					});
					if (ssessai && ssessai.valeur) {
						const fck =
							lines[l].dicEssaisCompoFck && lines[l].dicEssaisCompoFck[essai.idEssai]
								? lines[l].dicEssaisCompoFck[essai.idEssai]
								: null;
						const fckRef = lines[l].famille ? lines[l].famille.fckBetonReference : fck;

						const gpc = this.getPrelevementControle(lines[l], sse.idEssai, sse.id, controle);
						const resConformite = await this.checkConformite('@VAL', ssessai.valeur, allValues, gpc, sse.idEssai, sse.id, fck, fckRef);
						ssessai.conformite = resConformite;
						conformites.lines[0].value += 1;
						conformites.lines[1].value += resConformite.isConforme && !resConformite.isEmpty ? 1 : 0;
						conformites.lines[2].value += resConformite.isAlerte && !resConformite.isEmpty ? 1 : 0;
						conformites.lines[3].value += !resConformite.isConforme && !resConformite.isAlerte && !resConformite.isEmpty ? 1 : 0;
					}

					const ssessaiIgnore = lines[l].valeursEssais.filter(function (e) {
						return e.idEssai !== sse.idEssai || e.idSousEssai !== sse.id;
					});
					ssessaiIgnore.map((e) => {
						if (!e.conformite || e.conformite.constructor !== Object || e.conformite.isEmpty) {
							e.conformite = { isEmpty: true };
						}
						return e;
					});
				}

				const nqa = controle.nqa
					? controle.nqa.details.find(function (e) {
							return e.nbResult2 >= lines.length;
					  })
					: { valeurAcceptee: 0 };
				if (!nqa.valeurAcceptee) {
					nqa.valeurAcceptee = 0;
				}
				conformites.lines[4].value = conformites.lines[3].value <= nqa.valeurAcceptee ? 'Oui' : 'Non';
			}

			for (let p = 0; p < this.resultat.stats.length; p++) {
				const stat = this.resultat.stats[p];

				for (let l = 0; l < stat.statsEssais.length; l++) {
					if (stat.statsEssais[l].idEssai === sse.idEssai && stat.statsEssais[l].idSsEss === sse.id) {
						const valStat = stat.statsEssais[l].valeur;
						const allValues = this.resultat.lignes.map(function (line) {
							if (line.valeursEssais) {
								const valueEssai = line.valeursEssais.find(function (e) {
									return e.idEssai === sse.idEssai && e.idSousEssai === sse.id;
								});

								if (valueEssai && valueEssai.valeur && valueEssai.valeur == '-1') {
									valueEssai.valeur = '';
								}

								if (valueEssai && valueEssai.valeur) {
									return valueEssai.valeur;
								}
							}
						});
						const gpcStat = this.getPrelevementControle(null, sse.idEssai, sse.id, controle);
						const resConformite = await this.checkConformite(stat.formule, valStat, allValues, gpcStat, sse.idEssai, sse.id, null, null);
						stat.statsEssais[l].conformite = resConformite;
					}
				}
			}
		}
		return conformites;
	}

	getPrelevementControle(prelevement, idEssai, idSsEss, controle) {
		const prelevementControle = angular.copy(controle);
		prelevementControle.seuil = null;
		if (prelevement) {
			let caract = null;
			if (prelevement.caractProduitCom) {
				//
				if (prelevementControle.seuils && prelevementControle.seuils.length > 0) {
					for (let i = 0; i < prelevementControle.seuils.length; i++) {
						const idCaract = prelevementControle.seuils[i].idCaracteristique;
						caract = prelevement.caractProduitCom.find((e) => e.id === idCaract);
						if (caract && prelevementControle.seuils[i].codeEltCaract === caract.value.key) {
							prelevementControle.seuil = prelevementControle.seuils[i];
						}
					}
				}
				/*  if (!caract) {
                     caract = prelevement.caractProduitCom.find(function (e) {
                         return prelevementControle.seuils[0] && e.id === prelevementControle.seuils[0].idCaracteristique;
                     });
                 } */
				/* if (caract) {
                    prelevementControle.seuil = prelevementControle.seuils.find(function (e) {
                        return e.codeEltCaract === caract.value.key;
                    });
                } */
			} else {
				if (prelevementControle.seuils && prelevementControle.seuils.length > 0) {
					for (let i = 0; i < prelevementControle.seuils.length; i++) {
						const idCaract = prelevementControle.seuils[i].idCaracteristique;
						caract = prelevement.caractProduit.find((e) => e.id === idCaract);
						if (caract && prelevementControle.seuils[i].codeEltCaract === caract.value.key) {
							prelevementControle.seuil = prelevementControle.seuils[i];
						}
					}
				}
				/*  if (!caract) {
                     caract = prelevement.caractProduit.find(function (e) {
                         return prelevementControle.seuils[0] && e.id === prelevementControle.seuils[0].idCaracteristique;
                     });
                 } */
				/*
                if (caract) {
                    prelevementControle.seuil = prelevementControle.seuils.find(function (e) {
                        return e.codeEltCaract === caract.value.key;
                    });
                } */
			}
		}
		delete prelevementControle.seuils;
		let controleSpecs = angular.copy(controle.specs);
		if (controleSpecs && controleSpecs.length > 1) {
			const specType = controleSpecs.find((spec) => !(spec.libelleCategorie && spec.libelleCategorie.toLowerCase().startsWith('famille')));
			if (specType && specType.essais) {
				controleSpecs = specType.essais.filter((e) => e.idEssai === idEssai && e.idSousEssai === idSsEss);
			}
		} else if (controleSpecs && controleSpecs.length == 1 && controleSpecs[0].essais) {
			controleSpecs = controleSpecs[0].essais.filter((e) => e.idEssai === idEssai && e.idSousEssai === idSsEss);
		}
		prelevementControle.specs = controleSpecs;
		return prelevementControle;
	}

	async checkConformite(stat, valeur, allValues, controle, idEssai, idSsEss, fck, fckref) {
		const result = {
			isConforme: false,
			isAlerte: false,
			isEmpty: true,
			limInf: undefined,
			limSup: undefined,
			alrtInf: undefined,
			alrtSup: undefined
		};
		if (valeur !== undefined && valeur !== null) {
			if (controle && controle.specs && controle.specs.length > 0) {
				const controleSpec = controle.specs.find(function (spec) {
					return spec.calculFormule === stat && spec.idEssai === idEssai && spec.idSousEssai === idSsEss;
				});
				const val = parseFloat(valeur);
				if (!isNaN(val) && controleSpec) {
					const fAlrtInf = await this.getBorne(controleSpec.alerteInferieure, allValues, controle.seuil, fck, fckref);
					const fAlrtSup = await this.getBorne(controleSpec.alerteSuperieure, allValues, controle.seuil, fck, fckref);

					const fLimInf = await this.getBorne(controleSpec.limiteBetonInferieure, allValues, controle.seuil, fck, fckref);
					const fLimSup = await this.getBorne(controleSpec.limiteBetonSuperieure, allValues, controle.seuil, fck, fckref);

					const fBorneInf = await this.getBorne(controleSpec.borneInferieure, allValues, controle.seuil, fck, fckref);
					const fBorneSup = await this.getBorne(controleSpec.borneSuperieure, allValues, controle.seuil, fck, fckref);

					result.limInf = fLimInf ? fLimInf : fBorneInf;
					result.limSup = fLimSup || fLimSup >= fLimInf ? fLimSup : fBorneSup ? fBorneSup : '';
					result.alrtInf = fAlrtInf;
					result.alrtSup = fAlrtSup || fAlrtSup >= fAlrtInf ? fAlrtSup : '';
					if (!result.limInf && !result.limSup) {
						result.isEmpty = true;
					} else {
						if (result.limInf != undefined) {
							result.isConforme = result.limInf <= val;
							if (result.isConforme && result.limSup != undefined && result.limSup !== '') {
								result.isConforme = result.limSup >= val;
							}
						} else if (result.limSup != undefined && result.limSup !== '') {
							result.isConforme = result.limSup >= val;
						}

						if (result.isConforme) {
							if (!result.isAlerte && fAlrtInf > 0 && result.limInf != undefined) {
								result.isAlerte = fAlrtInf >= val && result.limInf <= val;
							}
							if (!result.isAlerte && fAlrtSup > 0 && result.limSup != undefined && result.limSup !== '') {
								result.isAlerte = fAlrtSup <= val && result.limSup >= val;
							}
						}

						result.isEmpty = false;
					}
				}
			}
		}
		return result;
	}

	async getBorne(value, values, seuil, fck, fckref) {
		let bNonNumerique = false;
		//SP 22/06/20 : vérifie en + qu'il y a du texte dans value, car 'parseFloat(0.77*ETA)' renvoie 0.77 !
		if (
			value &&
			(value.indexOf('VCMIN') > 0 ||
				value.indexOf('VCMAX') > 0 ||
				value.indexOf('ETA') > 0 ||
				value.indexOf('RCTHEO') > 0 ||
				value.indexOf('RCREF') > 0)
		) {
			bNonNumerique = true;
		}
		let dbValue = parseFloat(value);
		if (bNonNumerique || (!dbValue && value)) {
			if (value.indexOf('@') >= 0) {
				const formuleParts = value.split('@');
				for (let i = 0; i < formuleParts.length; i++) {
					let formule = formuleParts[i];
					if (formule.indexOf('(') < 0) {
						formule = formule.indexOf('+') <= 0 ? formule : formule.substring(0, formule.indexOf('+'));
						formule = formule.indexOf('-') <= 0 ? formule : formule.substring(0, formule.indexOf('-'));
						formule = formule.indexOf('*') <= 0 ? formule : formule.substring(0, formule.indexOf('*'));
						formule = formule.indexOf('/') <= 0 ? formule : formule.substring(0, formule.indexOf('/'));
						formule = formule.indexOf('^') <= 0 ? formule : formule.substring(0, formule.indexOf('^'));
						formule = formule.indexOf('%') <= 0 ? formule : formule.substring(0, formule.indexOf('%'));
						formule = '@' + formule.trim();

						const idbValue = await this.Interpreteur.calculeFormuleWithParams(formule + '(' + values.join(';') + ')');
						value = value.replace(formule, idbValue.toFixed(2));
					}
				}
			}

			value = value.replace(/VCMIN/g, (seuil && seuil.limiteInferieur ? seuil.limiteInferieur : 0).toString());
			value = value.replace(/VCMAX/g, (seuil && seuil.limiteSuperieur ? seuil.limiteSuperieur : 0).toString());
			//value = value.replace(/ETA/g, (ecartTypeAdopte ? ecartTypeAdopte : 0).toString());
			value = value.replace(/RCTHEO/g, (fck ? fck : 0).toString());
			value = value.replace(/RCREF/g, (fckref ? fckref : 0).toString());
			dbValue = await this.Interpreteur.calculeFormuleWithParams(value);
			dbValue = this.arrondiValeur(dbValue, 1); //parseFloat(dbValue/*.toFixed(2)*/);
		}

		return dbValue || 0;
	}

	arrondiValeur(valeur, arrondi) {
		const objetToRound = [{ key: valeur, val: valeur, rounder: arrondi }];
		return this.Interpreteur.getRoundedValue(objetToRound);
	}

	openSynthese() {
		const _this = this;
		this.$uibModal
			.open({
				template:
					'<syntheseentities-form modal-instance="$ctrl.uibModalInstance" synth-for-gene="undefined" params-synthese="$ctrl.paramsSynthese"></syntheseentities-form>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.paramsSynthese = _this.model.synthese;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl',
				backdrop: false
			})
			.result.then(
				async function (result) {
					if (result) {
						//result contient une liste (prelevementBlock)
						//TODO: Rafraichir le résultat

						_this.$state.go('syntheseentities.result', { obj: result });
					}
				},
				function (reason) {}
			);
	}

	async print() {
		try {
			const res = await this.TemplateImpressionService.downloadModal('ccb');
			if (res === 'cancel') {
				throw res;
			}

			this.notification.info(this.$translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
				autoDismiss: false,
				progressBar: true
			});

			let lignes = [];
			if (this.model.formattedResult.lignes && this.model.formattedResult.lignes.length > 0) {
				lignes = this.model.formattedResult.lignes.sort((a, b) => {
					const db = this.moment(b.datePrelevement, this.dateFormat).unix();
					const da = this.moment(a.datePrelevement, this.dateFormat).unix();
					return da - db;
				});
			}

			// On indique les prélèvements écartés
			this._.forEach(this.resultat.lignes, (ligne) => {
				if (ligne.ecartee !== false) {
					const ligneFormatee = lignes.filter((x) => x.idPrelevement === ligne.idPrelevement);
					if (ligneFormatee !== null) {
						ligneFormatee[0].ecartee = true;
					}
				}
			});

			/* start */
			const model = this.getEssaisForPrint(lignes, true);
			const ssEssais = this.getEssaisForPrint(lignes);

			const statsEssais = this.getFormattedStats();
			const stats = { statsEssais };
			//console.log(stats);
			//console.log(model);

			const firstPrelTemp = this.model.resultat.prelevementBlock[0];
			const synthese = {
				libelle: this.model.synthese.libelle,
				typeDeSyntheseLibelle: this.model.synthese.typeDeSyntheseLibelle,
				dateDeb: this.model.synthese.listeSyntheseCritereValeurBlocks[0].listeSyntheseCritereValeurLignes[0].dateDebut,
				dateFin: this.model.synthese.listeSyntheseCritereValeurBlocks[0].listeSyntheseCritereValeurLignes[0].dateFin
			};
			const referentiel = {
				id: this.obj?.resultat?.referentiel?.id,
				libelle: this.obj?.resultat?.referentiel ? this.obj.resultat.referentiel.libelle : '',
				norme: this.obj?.resultat?.referentiel ? this.obj.resultat.referentiel.libelleNorme : '',
				normeId: this.obj?.resultat?.referentiel?.idNorme,
				categorie: this.obj?.resultat?.referentiel ? this.obj.resultat.referentiel.categorie : ''
			};
			const laboratoire = {
				id: firstPrelTemp.idLaboAudit,
				libelle: firstPrelTemp.libelleLaboAudit,
				raisonSociale: firstPrelTemp.rsLaboAudit,
				societe: firstPrelTemp.societeLaboAudit
			}; // Mantis 6438 JN
			/* end */

			const table = this.formatTableForPrint();
			const template = await this.SyntheseEntitiesService.printCdcBeton(
				model,
				ssEssais,
				synthese,
				laboratoire,
				res.filename,
				stats,
				referentiel,
				table
			);

			template.pdf = res.pdf;
			await this.TemplateImpressionService.downloadTemplate(template.filename, template, 'ccb');
			this.notification.clear();
		} catch (err) {
			console.error(err);
			if (err !== 'cancel') {
				this.notification.error(err.data);
			}
		}
	}

	async openPrelevement(lig) {
		const _this = this;
		_this.id = lig.idPrelevement;
		_this.idFamille = lig.idFamille;
		_this.codeDomaine = lig.codeDomaine;
		this.$uibModal
			.open({
				template:
					'<prelevement modal-instance="$ctrl.uibModalInstance" id="$ctrl.id" id-famille="$ctrl.idFamille" code-domaine="$ctrl.codeDomaine"></prelevement>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						//$ctrl.objSynthese = _this.objSynthese;
						$ctrl.uibModalInstance = $uibModalInstance;
						$ctrl.id = _this.id;
						$ctrl.idFamille = _this.idFamille;
						$ctrl.codeDomaine = _this.codeDomaine;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl',
				backdrop: false
			})
			.result.then(
				async function (result) {
					if (result) {
						//await _this.regenererSynthese();
						const res = await _this.SyntheseEntitiesService.genererSynthese(_this.model.synthese, 'laboratoire');
						_this.model.resultat = res;
						_this.$state.go('syntheseentities.result', { obj: _this.model });
					}
				},
				function (reason) {}
			);
	}

	async endConsistance() {
		this.$state.go('syntheseentities.list', { obj: this.model });
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	getFormattedStats() {
		const valeursEssais = this._.map(this.resultat.entete.titresSousEssais, function (b) {
			const valeur = {
				title: b.displayTitle,
				codeSsEss: b.isTamis ? b.ouvertureTamis : b.codeSsEss != null ? b.codeSsEss : '',
				unite: b.unite || '',
				libelleEssai: b.isTamis ? '' : b.libelleEssai,
				symbole: b.symbole || b.code || '',
				codeEssai: b.code || '',
				isComplementaire: b.isComplementaire
			};
			return { valeur: valeur };
		});

		const statsFormatted = this.getStatsForPrint(angular.copy(this.resultat.stats), angular.copy(valeursEssais));
		const statsFormatted2 = this.getStatsForPrint(angular.copy(this.resultat.stats), angular.copy(valeursEssais), true);

		for (let i = 1; i < statsFormatted.length; i++) {
			statsFormatted[i].valeurs = [...statsFormatted[i].valeurs, ...statsFormatted2[i].valeurs];
		}
		return statsFormatted;
	}
	getStatsForPrint(listeStats, valEssais, isForControle = false) {
		const stats = listeStats.map((a) => {
			const res = {
				libelle: a.titre,
				code: a.code
			};
			const stat = a.statsEssais.filter((x) => x.isForControle === isForControle);
			res.valeurs = stat.map((x) => {
				if (a.couleurFond) {
					const limInf = x.conformite != null ? x.conformite.limInf : null;
					const limSup = x.conformite != null ? x.conformite.limSup : null;
					if (limInf || limSup) {
						x.valeur = {
							color: this.rgbToHex(this.rgbStringToIntArray(a.couleurFond)).toUpperCase(),
							value: x.valeur + ' ' + x.conformite.limInf + '/' + x.conformite.limSup
						};
					} else {
						x.valeur = {
							color: this.rgbToHex(this.rgbStringToIntArray(a.couleurFond)).toUpperCase(),
							value: x.valeur
						};
					}
				}

				if (x.conformite && x.valeur.value != null && x.valeur.value != '') {
					if (x?.conformite && !x.conformite.isConforme) {
						x.valeur.text = 'FF0000';
					} else if (x.conformite && x.conformite.isAlerte) {
						x.valeur.text = 'FF7700';
					} else if (x.conformite && x.conformite.isConforme && !x.conformite.isAlerte) {
						x.valeur.text = '008000';
					}
				}
				return x;
			});
			return res;
		});
		stats.splice(0, 0, {
			titre: '',
			code: '',
			codeLibelle: '',
			codeUnite: '',
			codeSymbole: '',
			libelle: '',
			libelleUnite: '',
			libelleSymbole: '',
			unite: '',
			symbole: '',
			codeLibelleSymbole: '',
			codeLibelleUnite: '',
			codeLibelleUniteSymbole: '',
			valeurs: angular.copy(valEssais)
		});

		return stats;
	}
	rgbStringToIntArray(rgb) {
		let array = rgb.includes('rgba') ? rgb.replace('rgba(', '').replace(')', '').split(',') : rgb.replace('rgb(', '').replace(')', '').split(',');
		array = array.map((x) => Number.parseInt(x));
		return array;
	}

	rgbToHex(rgb) {
		return rgb
			.map((x) => {
				const hex = x.toString(16);
				return hex.length === 1 ? '0' + hex : hex;
			})
			.join('');
	}

	formatTableForPrint() {
		try {
			const affichageHeader = this.resultat.entete.titresAffichage;
			const essaiHeader = this.resultat.entete.titresSousEssais;
			// On ne retiens que les prélèvements qui ne sont pas écartés
			return Array.from(
				this.resultat.lignes.filter((x) => x.ecartee !== true),
				(x) => this.setLigne(affichageHeader, essaiHeader, x)
			);
		} catch (error) {
			// console.error("🚀 ~ file: syntheseentities.consistance.controller.js ~ line 1078 ~ SyntheseEntityConsistanceController ~ formatTableForPrint ~ error", error)
		}
	}

	setLigne(affichage, essai, ligne) {
		return {
			affichage: affichage.map((x, i) => {
				return {
					titre: x.titre,
					valeur: ligne.valeursAffichage[i].valeur
				};
			}),
			essai: essai.map((x, i) => {
				return {
					...x,
					valeur: this.setValeur(ligne.valeursEssais[i])
				};
			})
		};
	}

	setValeur(value) {
		let val = value.valeur || value.valeur == '0' ? value.valeur : ' ';

		if (isNumber(value.conformite.limInf) || isNumber(value.conformite.limSup)) {
			val += ' [' + value.conformite.limInf + '/' + value.conformite.limSup + ']';
		}
		if (value?.conformite && !value.conformite.isEmpty && !value.conformite.isConforme) {
			return {
				text: 'FF0000',
				value: val
			};
		} else if (value.conformite && !value.conformite.isEmpty && value.conformite.isAlerte) {
			return {
				text: 'FF7700',
				value: val
			};
		} else if (value.conformite && !value.conformite.isEmpty && value.conformite.isConforme && !value.conformite.isAlerte) {
			return {
				text: '008000',
				value: val
			};
		}
		return val;
	}

	getEssaisForPrint(lignes, isEssai = false) {
		const main = isEssai ? this.model.formattedResult.entete.titresEssais : this.model.formattedResult.entete.titresSousEssais;
		const model = [];
		let iLoop = 0;
		for (let e = 0; e < main.length; e++) {
			const mainModel = main[e];
			if (mainModel.isForControle) {
				const tmpModel = {
					libelle: this.model.synthese ? this.model.synthese.libelle : '',
					typeDeSyntheseLibelle: this.model.synthese.typeDeSyntheseLibelle,
					libelleEssai: isEssai ? mainModel.libelle : '',
					libelleSousEssai: isEssai ? '' : mainModel.displayTitle,
					codeEssai: isEssai ? mainModel.code : '',
					codeSousEssai: isEssai ? '' : mainModel.codeSsEss,
					normeEssai: this.controleNorme ? this.controleNorme.libelle : '',
					libelleProduit: this.model.resultat.prelevementBlock[0].libelleProduit,
					libelleProducteur: this.model.resultat.prelevementBlock[0].libelleProducteur,
					rsProducteur: this.model.resultat.prelevementBlock[0].rsProducteur, // Mantis 6438
					typeProduit: this.model.resultat.prelevementBlock[0].typeProduit,
					libelleLaboAudit: this.model.resultat.prelevementBlock[0].libelleLaboAudit,
					rsLaboAudit: this.model.resultat.prelevementBlock[0].rsLaboAudit,
					codeProduit: this.model.resultat.prelevementBlock[0].codeProduit,
					codeProducteur: this.model.resultat.prelevementBlock[0].codeProducteur,
					typeProducteur: this.model.resultat.prelevementBlock[0].typeProducteur,
					lignes: [],
					canvas: null,
					conformite: null
				};

				for (let i = 0; i < lignes.length; i++) {
					const valeur = lignes[i];

					//! Pour contruire la ligne utilisé "essai" et non "essValues" sinon les lignes n'ont pas toutes le même nb d'éléments et bloque l'impression
					const essValues = isEssai
						? valeur.valeursEssais.filter((ess) => ess.idEssai === mainModel.id && ess.valeur)
						: valeur.valeursEssais.filter((ess) => ess.idSousEssai === mainModel.id && ess.valeur);
					const essai = isEssai
						? valeur.valeursEssais.filter((ess) => ess.idEssai === mainModel.id)
						: valeur.valeursEssais.filter((ess) => ess.idSousEssai === mainModel.id);

					if (essValues && essValues.length > 0) {
						const ligneFormat = { ligne: [] };

						for (let j = 0; j < this.model.formattedResult.entete.titresAffichage.length; j++) {
							const entete = this.model.formattedResult.entete.titresAffichage[j];
							ligneFormat.ligne.push({ libelle: entete.titre, valeur: valeur.valeursAffichage[j].valeur });
						}

						for (let k = 0; k < essai.length; k++) {
							const sse = this.model.formattedResult.entete.titresSousEssais.find((s) => s.id == essai[k].idSousEssai);
							ligneFormat.ligne.push({ libelle: sse.displayTitle, valeur: essai[k]?.valeur });

							const ligne = this.resultat.lignes.find((x) => x.idPrelevement === valeur.idPrelevement);

							ligneFormat.ligne.push({
								libelle: 'Limite inférieure',
								valeur: {
									value: ligne && ligne.valeursEssais ? ligne.valeursEssais[e].conformite.limInf : ' ',
									text: '0000FF'
								}
							});

							ligneFormat.ligne.push({
								libelle: 'Limite supérieure',
								valeur: {
									value: ligne && ligne.valeursEssais ? ligne.valeursEssais[e].conformite.limSup : ' ',
									text: 'FF0000'
								}
							});
						}
						tmpModel.lignes.push(ligneFormat);
					}
				}
				const graph = document.getElementById('granulograph' + iLoop);
				if (graph) {
					tmpModel.canvas = graph.toDataURL('image/png');
				}

				if (this.conformites && this.conformites.length > iLoop) {
					tmpModel.conformites = this.conformites[iLoop].lines;
				}

				model.push(tmpModel);
				iLoop++;
			}
		}
		return model;
	}
}
