import FacturesCheckpont from './factures.checkpont.model';

export default class FacturesCheckpontController {
    static $inject = ['$scope', '$state', '$stateParams', 'ModalService', 'notification', '$translate', '$filter', '_', '$uibModal'];

    constructor($scope, $state, $stateParams, ModalService, notification, $translate, $filter, _, $uibModal) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.$filter = $filter;
        this.$uibModal = $uibModal;
    }

    $onInit() {
        this.startLoading();

        this.hasBeenModified = false;
        this.cdgOpened = false;

        this.stopLoading();
    }

    open(domaine, article) {
        this.cdgOpened = true;
        const _this = this;

        const source = {};
        source.id = article.idArticle;
        source.entete = {};
        source.entete.id = article.idArticle;
        source.entete.libelle = article.nomArticle;
        source.entete.nomType = domaine;
        source.entete.type = article.typeArticleEnum;

        source.filtresAppliques = true;
        source.checkPont = {};
        source.checkPont.dateApplicationCompteAV = article.dateApplicationCompteAV;
        source.checkPont.dateApplicationCompteAnalytiqueAV = article.dateApplicationCompteAnalytiqueAV;
        source.checkPont.dateGeneration = article.dateGeneration;
        source.checkPont.dateApplication = article.dateGeneration;
        source.checkPont.idTVA = article.idTVA;
        source.checkPont.societes = {};
        source.checkPont.societes.id = article.idSociete;
        source.checkPont.societes.libelle = article.nomSociete;

        if (article.idSiteCommercial != 0) {
            source.checkPont.siteCommercial = {};
            source.checkPont.siteCommercial.id = article.idSiteCommercial;
            source.checkPont.siteCommercial.libelle = article.nomSiteCommercial;
        }

        if (article.idTypeSociete != 0) {
            source.checkPont.idTypeSociete = article.idTypeSociete;
        }

        if (article.idClient != 0) {
            source.checkPont.siteClient = {};
            source.checkPont.siteClient.id = article.idClient;
            source.checkPont.siteClient.libelle = article.nomClient;
        }

        if (article.idTypeClient != 0) {
            source.checkPont.idTypeClient = article.idTypeClient;
        }

        if (article.idChantier != 0) {
            source.checkPont.chantier = {};
            source.checkPont.chantier.id = article.idChantier;
            source.checkPont.chantier.libelle = article.nomChantier;
        }

        if (article.idProducteur != 0) {
            source.checkPont.idProducteur = article.idProducteur;
        }

        if (article.typeArticle != 0) {
            source.type = article.typeArticleEnum;
        } else {
            source.entete.typeLibelle = article.nomTypeProduit;
            source.entete.typeId = article.idTypeProduit;
        }

        this.$uibModal
            .open({
                template: '<centre-gestion source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></centre-gestion>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = source;
                        $ctrl.domaine = domaine;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl'
            })
            .result.then(
                async function (result) {
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                    if (result) {
                        _this.hasBeenModified = result;
                        _this.cdgOpened = false;
                    }
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    cancel() {
        this.modalInstance.dismiss();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
