export default function HomeRoutes($stateProvider, $urlRouterProvider, LoginServiceProvider) {
    $stateProvider.state('gestion.home', {
        url: '/home',
        params: {
            missingRight: false,
            originalState: undefined
        },
        views: {
            gestion: {
                template: '<gestion-home></gestion-home>'
            }
        },
        ncyBreadcrumb: {
            skip: true
        },
        navigation: {
            icon: 'massia-icon-home',
            position: 'brand'
        },
        search: 'HOME.HOME'
    });
}

HomeRoutes.$inject = ['$stateProvider', '$urlRouterProvider', 'LoginServiceProvider'];
