const serviceUrl = `${__configuration.apiUrl}/massia/facture-reglement`;

export default class ReglementsService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getFactureReglements(id) {
        try {
            const res = await this.$http.get(`${serviceUrl}/${id}`);
            return res.data;
        } catch (err) {
            throw err.data;
        }
    }

    async createFactureReglement(data) {
        return this.$http.post(serviceUrl, data);
    }

    async updateFactureReglement(data) {
        return this.$http.put(serviceUrl, data);
    }

    async deleteFactureReglementById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getModesPaiement() {
        const serviceUrl = `${__configuration.apiUrl}/massia/paiements/headers`;
        const res = await this.$http.get(serviceUrl);
        return res.data;
    }

    async getDevises() {
        const serviceUrl = `${__configuration.apiUrl}/massia/devises/headers`;
        const res = await this.$http.get(serviceUrl);
        return res.data;
    }
}
