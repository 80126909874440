export default class ProspectDetailController {
    static $inject = ['$stateParams', '$state', 'ProspectsService', 'notification', 'ProspectsCommunicationService'];

    constructor($stateParams, $state, ProspectsService, notification, ProspectsCommunicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.ProspectsService = ProspectsService;
        this.notification = notification;
        this.ProspectsCommunicationService = ProspectsCommunicationService;
    }

    $onInit() {
        this.loading = false;
        this.prospect = {};
        this.prospectId = undefined;
        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.initProspect();
    }

    async initProspect() {
        this.prospectId = this.$stateParams.id;

        this.startLoading();
        try {
            await this.getProspectEntete(this.prospectId);
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('prospects.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getProspectEntete(prospectId) {
        this.prospect.entete = await this.ProspectsService.getProspectEnteteById(prospectId);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
