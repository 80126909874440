import validatorBuilder from 'validator-builder';

const pontComptableValidator = null;

export default class PontComptable {
    constructor(data = {}) {
        this.typePontComptable = data.typePontComptable;
        this.enumTypePontComptable = data.enumTypePontComptable;
        this.hasUtiliserMEPFacture = data.hasUtiliserMEPFacture || false;
        this.codeJournal = data.codeJournal;
        this.compteComptaGeneral = data.compteComptaGeneral;
        this.hasParametrageAnalytique = data.hasParametrageAnalytique || false;
        this.hasDetailClientDivers = data.hasDetailClientDivers || false;
        this.destinationFichierTransfert = data.destinationFichierTransfert;
        this.ordreCompteTiers = data.ordreCompteTiers || 1;
        this.parametrageLibelleCompteTiers = data.parametrageLibelleCompteTiers;
        this.ordreCompteProduit = data.ordreCompteProduit || 2;
        this.parametrageLibelleCompteProduit = data.parametrageLibelleCompteProduit;
        this.ordreCompteTransport = data.ordreCompteTransport || 3;
        this.parametrageLibelleCompteTransport = data.parametrageLibelleCompteTransport;
        this.ordreComptePrestation = data.ordreComptePrestation || 4;
        this.parametrageLibelleComptePrestation = data.parametrageLibelleComptePrestation;
        this.ordreCompteTVA = data.ordreCompteTVA || 5;
        this.parametrageLibelleCompteTVA = data.parametrageLibelleCompteTVA;
        this.hasDownload = data.hasDownload || false;
    }

    isValid() {
        return true;
        // pontComptableValidator = pontComptableValidator || validatorBuilder.getInstanceFor('PontComptable');
        // const validationResults = pontComptableValidator.validate(this);
        // return validationResults.isValid;
    }
}
