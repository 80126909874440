import IndiceRevision from './indice.revision.model';

export default class IndiceRevisionFormController {
    static $inject = ['_', '$scope', '$state', '$stateParams', '$translate', 'notification', '$timeout', 'IndicesRevisionService', '$filter'];

    constructor(_, $scope, $state, $stateParams, $translate, notification, $timeout, IndicesRevisionService, $filter) {
        this.$scope = $scope;
        this.$state = $state;
        this.$translate = $translate;
        this.$stateParams = $stateParams;
        this.notification = notification;
        this.IndicesRevisionService = IndicesRevisionService;
        this.$timeout = $timeout;
        this._ = _;
        this.$filter = $filter;
        //this.types = [];
        this.newLigne = false;
    }

    async $onInit() {
        this.loading = false;
        this.canEdit = true;
        this.editCode = undefined;
        this.reset();
    }

    // Public
    async reset() {
        this.startLoading();

        let data = {};

        if (this.$stateParams.id) {
            try {
                data = await this.IndicesRevisionService.getIndiceRevisionById(this.$stateParams.id);
            } catch (ex) {
                this.notification.error(ex.data || ex);
                this.annuler();
            }
        }
        this.canEdit = data.canEdit !== false;
        this.indiceRevision = new IndiceRevision(data);
        if (this.indiceRevision.code) {
            this.editCode = this.indiceRevision.code;
            this.checkCodeUnicity(this.editCode);
        }

        const tm = this.$timeout(() => {
            this.stopLoading();
            this.$timeout.cancel(tm);
        });
        //this.loadTypes();
    }

    /*async loadTypes() {
        this.types = [];

        try {
            let types = this.IndicesRevisionService.getTypes();

            angular.forEach(types, (type, id) => {
                this.types.push({
                    idType: parseInt(id),
                    label: this.$translate.instant(`INDICES_REVISION.TYPES_ARTICLE.${id}`),
                });
            });
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }*/

    ajouterLigne() {
        this.newLigne = true;
    }

    supprimerToutesLignes() {
        this.indiceRevision.indices.splice(0, this.indiceRevision.indices.length);
    }

    removeNewLigne() {
        this.newLigne = false;
    }

    async appliquer() {
        const id = await this.sauvegarder();
        if (id) {
            this.newLigne = false;
            this.$state.go('indices-revision.edit', { id: id });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    annuler() {
        this.$state.go('indices-revision.list');
    }

    async checkCodeUnicity(code) {
        if (code !== this.editCode) {
            this.indiceRevision.codeExists = null;

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.indiceRevision.codeExists = await this.IndicesRevisionService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            }
        } else {
            this.indiceRevision.codeExists = false;
        }
    }

    async sauvegarder() {
        if (this.newLigne || !this.indiceRevision.indices || this.indiceRevision.indices.length == 0) {
            if (!this.addIndice(false)) {
                return false;
            }
        }
        if (this.checkValidity()) {
            this.startLoading();

            try {
                let id = false;

                if (this.indiceRevision.id) {
                    await this.IndicesRevisionService.updateIndiceRevision(this.indiceRevision);
                    this.notification.success('INDICES_REVISION.UPDATED');
                    id = this.indiceRevision.id;
                } else {
                    id = await this.IndicesRevisionService.createIndiceRevision(this.indiceRevision);
                    this.notification.success('INDICES_REVISION.CREATED');
                }
                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    addIndice(newLigne) {
        if (this.newDate && this.newIndice) {
            let trouve = false;
            if (this.indiceRevision.indices) {
                for (let ind = 0; ind < this.indiceRevision.indices.length; ind++) {
                    if (this.indiceRevision.indices[ind].date == this.newDate) {
                        trouve = true;
                    }
                }
            }
            if (trouve) {
                this.notification.error('INDICES_REVISION.INDICE_EXISTANT_DATE');
                return false;
            }

            const newIndic = { id: -1, date: this.newDate, indice: this.newIndice };
            if (!this.indiceRevision.indices) {
                this.indiceRevision.indices = [];
            }
            this.indiceRevision.indices.push(newIndic);
            this.newDate = undefined;
            this.newIndice = undefined;
            if (newLigne) {
                this.ajouterLigne();
            }
            return true;
        }

        if (this.newIndice || this.newDate) {
            this.notification.error('INDICES_REVISION.DATE_INDICE_OBLIGATOIRES');
            return false;
        }
        return true; //pas de nouvelle ligne à créer
    }

    removeIndice(indic) {
        const i = this.indiceRevision.indices.indexOf(indic);
        this.indiceRevision.indices.splice(i, 1);
    }

    convertNewIndToDecimal() {
        if (typeof this.newIndice === 'string') {
            this.newIndice = parseFloat(this.newIndice.replace(',', '.').replace(' ', ''));
        }
    }

    convertIndiceToDecimal(indic) {
        if (typeof indic.indice === 'string') {
            indic.indice = parseFloat(indic.indice.replace(',', '.').replace(' ', ''));
        }
    }

    checkValidity() {
        if (!this.indiceRevision.isValid() /*|| this.indiceRevision.codeExists*/) {
            this.$scope.$broadcast('IndiceRevisionValidations');
            return false;
        }
        return true;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
