import DocumentSetting from '../../../models/DocumentSetting';
import DomainPortail from '../../../models/DomainPortail';

export default class SettingDocController {
	constructor($scope, SettingPortailService, DomainPortailService) {
		this.$scope = $scope;
		this.SettingPortailService = SettingPortailService;
		this.DomainPortailService = DomainPortailService;
	}
	async $onInit() {
		this.SettingPortailService.activesDomains = (await this.DomainPortailService.getAll()).data.items || [];
		await this.SettingPortailService.getDocSettings();
		this.visible = false;
		this.setListe();
	}
	$onDestroy() {}

	setListe() {
		const notConfiguredDomains = angular.copy(this.SettingPortailService.activesDomains);

		for (let i = 0; i < this.SettingPortailService.docSetting.length; i++) {
			const element = this.SettingPortailService.docSetting[i];
			const indexDomain = notConfiguredDomains.findIndex((x) => x.id == element.domain.id);
			if (indexDomain >= 0) {
				notConfiguredDomains.splice(indexDomain, 1);
			}
		}

		notConfiguredDomains.forEach((element) => {
			this.addDocSetting(element);
		});
	}

	addDocSetting(domain) {
		const newSetting = new DocumentSetting();
		newSetting.domain = new DomainPortail(domain);
		newSetting.create = true;
		this.SettingPortailService.docSetting.push(newSetting);
	}

	change(type, index) {
		if (type !== 'format') {
			this.SettingPortailService.docSetting[index][type] = !this.SettingPortailService.docSetting[index][type];

			if ((type = 'filenameAuto' && this.SettingPortailService.docSetting[index][type])) {
				if (!this.SettingPortailService.docSetting[index].format || this.SettingPortailService.docSetting[index].format) {
					this.SettingPortailService.docSetting[index].format = `${this.SettingPortailService.docSetting[index].domain.code}_[C]_[D]`;
				}
			}
		}
		this.SettingPortailService.docSetting[index].update = true;
	}
}

SettingDocController.$inject = ['$scope', 'SettingPortailService', 'DomainPortailService'];
