const serviceUrl = `${__configuration.apiUrl}/massia/themes`;

export default class ThemesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getThemes(filters, sorts, pagination, applicationName) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {}),
                appName: JSON.stringify(applicationName || null)
            }
        });
        return res.data;
    }

    async getThemesSelect() {
        const url = `${serviceUrl}/headers`;
        const res = await this.$http.get(serviceUrl);
        return res.data.items;
    }

    async deleteThemeById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getThemeById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createTheme(theme) {
        const result = await this.$http.post(serviceUrl, theme);
        return result.data;
    }

    async updateTheme(theme) {
        const url = `${serviceUrl}/${theme.id}`;
        return await this.$http.put(url, theme);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getMetiers(applicationName) {
        const url = `${__configuration.apiUrl}/massia/metiers`;
        const res = await this.$http.get(url, {
            params: {
                appName: JSON.stringify(applicationName || null)
            }
        });
        return res.data;
    }

    async getLocalisationsPointsMesure() {
        const url = 'assets/enum/themes/localisationPointMesure.json';
        const res = await this.$http.get(url);
        return res.data;
    }

    async getCouleursPointsMesure() {
        const url = 'assets/enum/themes/couleurPointMesure.json';
        const res = await this.$http.get(url);
        return res.data;
    }
}
