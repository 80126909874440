export default class SiteFormNiveauxController {
    static $inject = ['SitesService', 'notification'];

    constructor(SitesService, notification) {
        this.SitesService = SitesService;
        this.notification = notification;
    }

    $onInit() {
        // Propriétés
        this.loading = true;
        this.site = this.site || {};
        this.values = angular.copy(this.site.niveaux);
        this.niveaux = this.niveaux || [];
        this.elements = this.elements || [];
    }

    $onChanges(objChanges) {
        if (objChanges.site) {
            this.loadNiveaux();
        }
    }

    $doCheck() {
        if (!angular.equals(this.values, this.site.niveaux)) {
            this.onUpdate({
                niveaux: angular.copy(this.values)
            });
        }
    }

    async loadNiveaux() {
        this.startLoading();

        try {
            this.niveaux = await this.SitesService.getSitesNiveaux();
            this.elements = await this.SitesService.getSitesElements();
        } catch (ex) {
            this.notification.error(ex);
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
