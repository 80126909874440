TamisSelectController.$inject = ['$scope', 'MesuresService', '_', '$translate'];

export default function TamisSelectController($scope, MesuresService, _, $translate) {
    /* jshint validthis: true */
    const vm = this;
    const watchers = [];

    // Propriétés
    vm.loading = false;
    vm.tamis = [];

    // Méthodes
    vm.selectTamis = selectTamis;
    vm.refreshTamis = refreshTamis;
    vm.filterTamis = filterTamis;
    vm.getLabelSelectTamis = getLabelSelectTamis;

    // Evènements
    vm.$onInit = onInit;
    vm.$onChanges = onChanges;

    // Méthodes publiques
    function onInit() {
        vm.refreshTamis();
        vm.excludes = vm.excludes || [];
        vm.clearOnSelect = vm.clearOnSelect || false;
        vm.selected = vm.selected || null;
        vm.disabled = vm.disabled || false;
        vm.idMesure = vm.idMesure || 0;
        watchers.push($scope.$on('$destroy', dispose));
    }

    function selectTamis(item, mesure) {
        vm.onSelect ? vm.onSelect({ tamis: item, mesure: mesure }) : (vm.selected = item);

        if (vm.clearOnSelect) {
            vm.selected = undefined;
        }
    }

    async function onChanges(objChanges) {
        if (objChanges.idMesure) {
            await vm.refreshTamis();
        }
    }

    async function refreshTamis() {
        let data = [];

        if (!this.listTamis || this.listTamis.length <= 0) {
            try {
                data = await MesuresService.getTamisList(vm.idMesure);
            } catch (ex) {}
        } else {
            data = this.listTamis;
        }

        vm.tamis = data;
        if (vm.selected && vm.selected.id) {
            vm.selected = _.find(vm.tamis, { id: vm.selected.id });
        }
    }

    function filterTamis(item) {
        return !_.find(vm.excludes, { ouverture: item.ouverture });
    }

    function getLabelSelectTamis() {
        return $translate.instant('MESURES.SELECTIONNER_TAMIS');
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
