export default class SiteConfigNiveauxController {
    static $inject = ['SiteNiveauxTrameService', 'SiteNiveauxElementsService', 'TypesNiveauxHierarchique'];

    constructor(SiteNiveauxTrameService, SiteNiveauxElementsService, TypesNiveauxHierarchique) {
        this.SiteNiveauxTrameService = SiteNiveauxTrameService;
        this.SiteNiveauxElementsService = SiteNiveauxElementsService;
        this.TypesNiveauxHierarchique = TypesNiveauxHierarchique;
    }

    $onInit() {
        this.name = 'SiteNiveaux';

        this.treeviewTrameConfig = { orderByFunction: 'position' };

        this.treeviewTrameService = this.SiteNiveauxTrameService;

        this.treeviewElementsConfig = {
            dropDisabled: false,
            //les elements de types pays sont les feuilles, pas d'action possible pour l'instant
            leafTypes: [this.TypesNiveauxHierarchique.Pays.value, this.TypesNiveauxHierarchique.Libre.value],
            leafTypesIcons: {}
        };
        //on set la valeur de "leafTypesIcons" en deux etape,
        //car on a besoin d'utiliser la valeur d'une variable (constant this.TypesNiveauxHierarchique.Pays.value)
        //en tant que cle d'objet
        this.treeviewElementsConfig.leafTypesIcons[this.TypesNiveauxHierarchique.Pays.value] = 'globe';
        this.treeviewElementsConfig.leafTypesIcons[this.TypesNiveauxHierarchique.Libre.value] = 'play';

        this.treeviewElementsService = this.SiteNiveauxElementsService;
    }
}
