// Config
import SitesConfig from './_config/sites.config';
import SitesRoutes from './_config/sites.routes';
import ClientsRoutes from './_config/clients.routes';
import ProducteursRoutes from './_config/producteurs.routes';
import FournisseursRoutes from './_config/fournisseurs.routes';
import TransporteursRoutes from './_config/transporteurs.routes';
import LaboratoiresRoutes from './_config/laboratoires.routes';
import ProprietairesRoutes from './_config/proprietaires.routes';
import LocatairesRoutes from './_config/locataires.routes';
import AuditeursRoutes from './_config/auditeurs.routes';

//Composants
import SiteFormProduitsProducteurComponent from './components/site-form/produits-producteur';

import SitesComponent from './components/sites';
import SiteFormComponent from './components/site-form';
import SiteDetailComponent from './components/site-detail';
import SiteFormEnteteComponent from './components/site-form/entete';
import SiteObjectifComponent from './components/site-form/objectif';
import SiteIndicateurComponent from './components/site-form/indicateur';
//import SiteFormCaracteristiquesComponent from './components/site-form/caracteristiques';
import SiteFormCaracteristiquesClientComponent from './components/site-form/caracteristiques-client';
import SiteFormCaracteristiquesCommercialComponent from './components/site-form/caracteristiques-commercial';
import SiteFormCaracteristiquesFournisseurComponent from './components/site-form/caracteristiques-fournisseur';
import SiteFormCaracteristiquesProducteurComponent from './components/site-form/caracteristiques-producteur';
import SiteFormCaracteristiquesTransporteurComponent from './components/site-form/caracteristiques-transporteur';
import SiteFormCaracteristiquesLaboratoireComponent from './components/site-form/caracteristiques-laboratoire';
import SiteFormCaracteristiquesProprietaireComponent from './components/site-form/caracteristiques-proprietaire';
import SiteFormCaracteristiquesLocataireComponent from './components/site-form/caracteristiques-locataire';
import SiteFormCaracteristiquesAuditeurComponent from './components/site-form/caracteristiques-auditeur';
import SiteFormEtatCompteComponent from './components/site-form/etat-compte';
import SiteFormInerteComponent from './components/site-form/inertes';
import SiteFormZoneStockageComponent from './components/site-form/zone-stockages';
import SiteFormPiecesFacturationComponent from './components/site-form/pieces-facturation';
import SiteJoursFeriesComponent from './components/site-jours-feries';
// import SiteCertificationCeComponent from './components/site-form/certification-ce';
import SiteAccreditationComponent from './components/site-form/accreditation';
import SiteFormPontComptableComponent from './components/site-form/pont-comptable';
import SiteSpecificiteComponent from './components/site-form/specificite';

import SiteCoordonneesComponent from './components/site-form/coordonnees';
import SiteFormNiveauxComponent from './components/site-form/niveaux';
import SiteConfigNiveauxComponent from './components/site-config-niveaux';
import SiteImportBLComponent from './components/site-form/import-bl-externe';

//services
import SitesService from './services/sites.service';
import SitesTypesService from './services/sites.types.service';
import SitesCommunicationService from './services/sites.communication.service';
import SiteNiveauxTrameService from './components/site-config-niveaux/site.niveaux.trame.service';
import SiteNiveauxElementsService from './components/site-config-niveaux/site.niveaux.elements.service';
import SiteImportBLService from './services/site.import.bl.service';
import SiteDistancePoidsCo2Service from './services/site.distance.poidsCo2.service';

import SiteAccreditationService from './services/site.accreditation.service';
import TypesNiveauxHierarchique from './components/site-config-niveaux/site.niveaux.trame.constant';
import SiteFormQualibComponent from './components/site-form/qualib';

// Validation
import SiteValidator from './components/site-form/site.validator';
import SiteEnteteValidator from './components/site-form/entete/site.entete.validator';
import SiteObjectifValidator from './components/site-form/objectif/site.objectif.validator';
import SiteIndicateurValidator from './components/site-form/indicateur/site.indicateur.validator';
//import SiteCaracteristiquesValidator  from './components/site-form/caracteristiques/site.caracteristiques.validator';
import SiteCaracteristiquesClientValidator from './components/site-form/caracteristiques-client/site.caracteristiques.client.validator';
import SiteCaracteristiquesCommercialValidator from './components/site-form/caracteristiques-commercial/site.caracteristiques.commercial.validator';
import SiteCaracteristiquesFournisseurValidator from './components/site-form/caracteristiques-fournisseur/site.caracteristiques.fournisseur.validator';
import SiteCaracteristiquesProducteurValidator from './components/site-form/caracteristiques-producteur/site.caracteristiques.producteur.validator';
import SiteCaracteristiquesTransporteurValidator from './components/site-form/caracteristiques-transporteur/site.caracteristiques.transporteur.validator';
import SiteCaracteristiquesLaboratoireValidator from './components/site-form/caracteristiques-laboratoire/site.caracteristiques.laboratoire.validator';
import SiteCaracteristiquesProprietaireValidator from './components/site-form/caracteristiques-proprietaire/site.caracteristiques.proprietaire.validator';
import SiteCaracteristiquesLocataireValidator from './components/site-form/caracteristiques-locataire/site.caracteristiques.locataire.validator';
import SiteCaracteristiquesAuditeurValidator from './components/site-form/caracteristiques-auditeur/site.caracteristiques.auditeur.validator';
import SiteCoordonneesValidator from './components/site-form/coordonnees/site.coordonnees.validator';
// import SiteCertificationCeValidator from './components/site-form/certification-ce/site.certification.ce.validator';
import siteAccreditationValidator from './components/site-form/accreditation/site.accreditation.validator';
import EditDistancesModalComponent from './components/edit-distances-modal';
import FilterModalComponent from './components/edit-distances-modal/filter-modal';
import TransporteurInterface from './components/site-form/transporteur-interface';

const moduleName = 'app.massia.common.sites';

angular
	.module(moduleName, [])
	.config(SitesConfig)
	.config(SitesRoutes)
	.config(ClientsRoutes)
	.config(ProducteursRoutes)
	.config(FournisseursRoutes)
	.config(TransporteursRoutes)
	.config(LaboratoiresRoutes)
	.config(ProprietairesRoutes)
	.config(LocatairesRoutes)
	.config(AuditeursRoutes)
	.constant('TypesNiveauxHierarchique', TypesNiveauxHierarchique)
	.service('SitesService', SitesService)
	.service('SitesTypesService', SitesTypesService)
	.service('SitesCommunicationService', SitesCommunicationService)
	.service('SiteNiveauxTrameService', SiteNiveauxTrameService)
	.service('SiteNiveauxElementsService', SiteNiveauxElementsService)
	.service('SiteImportBLService', SiteImportBLService)
	.service('SiteAccreditationService', SiteAccreditationService)
	.service('SiteDistancePoidsCo2Service', SiteDistancePoidsCo2Service)
	.component('sites', SitesComponent)
	.component('siteForm', SiteFormComponent)
	.component('siteDetail', SiteDetailComponent)
	.component('siteFormEntete', SiteFormEnteteComponent)
	.component('siteObjectif', SiteObjectifComponent)
	.component('siteIndicateur', SiteIndicateurComponent)
	//.component('siteFormCaracteristiques', SiteFormCaracteristiquesComponent)
	.component('siteFormCaracteristiquesClient', SiteFormCaracteristiquesClientComponent)
	.component('siteFormCaracteristiquesCommercial', SiteFormCaracteristiquesCommercialComponent)
	.component('siteFormCaracteristiquesFournisseur', SiteFormCaracteristiquesFournisseurComponent)
	.component('siteFormCaracteristiquesProducteur', SiteFormCaracteristiquesProducteurComponent)
	.component('siteFormCaracteristiquesTransporteur', SiteFormCaracteristiquesTransporteurComponent)
	.component('siteFormCaracteristiquesLaboratoire', SiteFormCaracteristiquesLaboratoireComponent)
	.component('siteFormCaracteristiquesProprietaire', SiteFormCaracteristiquesProprietaireComponent)
	.component('siteFormCaracteristiquesLocataire', SiteFormCaracteristiquesLocataireComponent)
	.component('siteFormCaracteristiquesAuditeur', SiteFormCaracteristiquesAuditeurComponent)
	.component('siteFormProduitsProducteur', SiteFormProduitsProducteurComponent)
	.component('siteFormNiveaux', SiteFormNiveauxComponent)
	.component('siteEtatCompte', SiteFormEtatCompteComponent)
	.component('siteInertes', SiteFormInerteComponent)
	.component('siteZoneStockages', SiteFormZoneStockageComponent)
	.component('sitePiecesFacturation', SiteFormPiecesFacturationComponent)
	.component('siteConfigNiveaux', SiteConfigNiveauxComponent)
	.component('siteCoordonnees', SiteCoordonneesComponent)
	.component('siteJoursFeries', SiteJoursFeriesComponent)
	// .component('siteCertificationCe', SiteCertificationCeComponent)
	.component('siteAccreditation', SiteAccreditationComponent)
	.component('sitePontComptable', SiteFormPontComptableComponent)
	.component('siteSpecificite', SiteSpecificiteComponent)
	.component('siteImportBl', SiteImportBLComponent)
	.component('siteFormQualib', SiteFormQualibComponent)
	.component('editDistancesModal', EditDistancesModalComponent)
	.component('filterModal', FilterModalComponent)
	.component('transporteurInterface', TransporteurInterface)

	.factory('SiteValidator', SiteValidator)
	.factory('SiteEnteteValidator', SiteEnteteValidator)
	.factory('SiteObjectifValidator', SiteObjectifValidator)
	.factory('SiteIndicateurValidator', SiteIndicateurValidator)
	//.factory('SiteCaracteristiquesValidator', SiteCaracteristiquesValidator)
	.factory('SiteCaracteristiquesClientValidator', SiteCaracteristiquesClientValidator)
	.factory('SiteCaracteristiquesCommercialValidator', SiteCaracteristiquesCommercialValidator)
	.factory('SiteCaracteristiquesFournisseurValidator', SiteCaracteristiquesFournisseurValidator)
	.factory('SiteCaracteristiquesProducteurValidator', SiteCaracteristiquesProducteurValidator)
	.factory('SiteCaracteristiquesTransporteurValidator', SiteCaracteristiquesTransporteurValidator)
	.factory('SiteCaracteristiquesLaboratoireValidator', SiteCaracteristiquesLaboratoireValidator)
	.factory('SiteCaracteristiquesProprietaireValidator', SiteCaracteristiquesProprietaireValidator)
	.factory('SiteCaracteristiquesLocataireValidator', SiteCaracteristiquesLocataireValidator)
	.factory('SiteCaracteristiquesAuditeurValidator', SiteCaracteristiquesAuditeurValidator)
	.factory('SiteCoordonneesValidator', SiteCoordonneesValidator)
	// .factory('SiteCertificationCeValidator', SiteCertificationCeValidator)
	.factory('SiteAccreditationValidator', siteAccreditationValidator);

export default moduleName;
