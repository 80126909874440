import { Injectable } from '../decorators';
import ApiService from '../../app/services/api.service';
import { BaseHttpService } from '../interface/MassiaHttpService.interface';
import { UniteConversion } from '../models/UniteConversion';

@Injectable('UniteConversionService')
export class UniteConversionService extends BaseHttpService<UniteConversion, UniteConversion, UniteConversion, UniteConversion, UniteConversion> {
    /* @ngInject */
    constructor(ApiService: ApiService) {
        super(ApiService);
        this.url = 'v2/massia/unite-conversions';
    }
}
