export default function Configure($stateProvider) {
    $stateProvider.state('inerte-registre-admission', {
        parent: 'gestion',
        url: '/inerte-registre-admission',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
        }
    });

    $stateProvider.state('inerte-registre-admission.list', {
        url: '/list',
        template: '<inerte-registre-admission></inerte-registre-admission>',
        rights: { domain: 'inerte', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "INERTES.REGISTRE_ADMISSION.TITLE" | translate}}'
        },
        // on affiche la liste des bennes dans la navigation
        navigation: {
            menu: 'inertes',
            translate: 'INERTES.REGISTRE_ADMISSION.TITLE',
            order: 14
        },
        // la liste des bennes fait partie des points d'entrée recherchables dans la navigation
        search: 'INERTES.REGISTRE_ADMISSION.TITLE'
    });
}

Configure.$inject = ['$stateProvider'];
