import validatorBuilder from 'validator-builder';

let releveBonsImpressionValidator = null;
export default class RelevesBonsImpression {
	constructor(data = {}) {
		this.id = data.id || 0;
		this.societe = data.societe || {
			display: false,
			logo: false,
			libelle: false,
			code: false,
			adresse: false,
			tel: false,
			mail: false
		};
		this.client = data.client || {
			display: false,
			libelle: false,
			adresse: false,
			tel: false,
			mail: false,
			code: false
		};
		this.facture = data.facture;
		this.titre = data.titre || 'Relevé de bons';
		this.colonnes = data.colonnes;

		this.date = data.date;
		this.numerotation = data.numerotation;
		this.isActif = data?.isActif === true;
		this.producteur = data.producteur;
	}

	isValid() {
		releveBonsImpressionValidator = releveBonsImpressionValidator || validatorBuilder.getInstanceFor('ReleveBonsImpressionValidator');
		const validationResults = releveBonsImpressionValidator.validate(this);
		return validationResults.isValid;
	}
}
