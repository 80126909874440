import { copy } from 'angular';

export default class NatureAdditionnalInfoMesureController {
    static $inject = ['$scope', '_', 'MesuresService', '$translate', '$uibModal', 'ExcelService', 'notification', 'MassiaApplicationService'];

    constructor($scope, _, MesuresService, $translate, $uibModal, ExcelService, notification, MassiaApplicationService) {
        this._ = _;
        /**
         * @type ng.IScope
         */
        this.$scope = $scope;
        this.MesuresService = MesuresService;
        this.$translate = $translate;
        this.$uibModal = $uibModal;
        this.ExcelService = ExcelService;
        this.notification = notification;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        //this.entete = this.entete || {};
        this.application = this.MassiaApplicationService.getApplication();
        // Configuration
        if (this.application === 'qualib') {
            this.getSourcesNbEchantillonsList();
        }
        this.loadUnites();
        this.loadSeriesTamis();
        this.loadConfigExcel(this.entete.additionnalInfoMesure.idConfigExcel > -1 ? this.entete.additionnalInfoMesure.idConfigExcel : null);
        this.unregister = [];
        this.unregister.push(
            this.$scope.$watch(
                () => this.entete.idNature,
                () => {
                    if (Object.keys(this.entete).length !== 0) {
                        this.entete.additionnalInfoMesure.idNature = this.entete.idNature;
                        this.templateUrl = this.getDynamicTpl();
                    }
                },
                true
            )
        );
    }

    $onDestroy() {
        for (let i = 0; i < this.unregister.length; i++) {
            const u = this.unregister[i];
            u();
        }
    }
    /*

        $onChanges(objChanges) {
            console.log('$onChanges');
            console.log(objChanges);
            let { previousValue, currentValue } = objChanges.additionnalInfoMesure;
            if (currentValue.idNature !== previousValue.idNature) {
                console.log("{Nature Additional info} : onChanges", objChanges)
                this.entete.additionnalInfoMesure.idNature = objChanges.idNature.currentValue;
                this.templateUrl = this.getDynamicTpl();
            }
        } */

    getDynamicTpl() {
        let templateInputName = '';
        let templateUrl = '';

        switch (this.entete.idNature) {
            case 3:
            case 62:
                templateInputName = 'numeric';
                break;
            case 8:
                templateInputName = 'granulo';
                break;
            case 10:
                templateInputName = 'essaiiteratif';
                break;
            case 12:
                templateInputName = 'excel';
                break;
            case 5:
            case 63:
                this.elementsField = {
                    label: this.$translate.instant('MESURES.ELEMENTS'),
                    id: 'elements'
                };
                templateInputName = 'elements';
                break;
            default:
                break;
        }

        if (templateInputName) {
            templateUrl = `mesure-form/field-template.${templateInputName}.html`;
        }

        return templateUrl;
    }

    async loadSeriesTamis() {
        this.seriesTamis = [];

        try {
            this.seriesTamis = await this.MesuresService.getSeriesTamis();
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async loadUnites() {
        this.unites = [];

        try {
            this.unites = await this.MesuresService.getUnites();
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    onChangeUnite() {
        for (let i = 0; i < this.unites.length; i++) {
            if (this.unites[i].id == this.entete.additionnalInfoMesure.idUnite) {
                this.entete.additionnalInfoMesure.nomUnite = this.unites[i].libelle;
            }
        }
    }

    async onChangeSerie() {
        this.entete.additionnalInfoMesure.arrondisTamis = [];
        const tamis = await this.MesuresService.getTamis(this.entete.additionnalInfoMesure.idSerieTamis);
        for (let i = 0; i < tamis.length; i++) {
            const obj = { ouverture: tamis[i].ouverture, arrondiPourcent: 2, arrondiPoids: 2 };
            this.entete.additionnalInfoMesure.arrondisTamis.push(obj);
        }
    }

    async loadConfigExcel(id = null) {
        try {
            const res = await this.ExcelService.getAllConfig();
            this.configExcel = res;
            if (id) {
                for (let i = 0; i < res.length; i++) {
                    if (res[i].id === id) {
                        this.configSelected = res[i];
                        this.setConfigSelected({ id: id });
                    }
                }
            }
            this.configExcel.push({
                libelle: 'Nouvelle Configuration',
                id: -1
            });
            this.entete.additionnalInfoMesure.config = res;
        } catch (err) {
            console.log(err);
        }
    }

    setConfigSelected(item) {
        if (item) {
            this.entete.additionnalInfoMesure.config = item.id;
        }
    }

    async getSourcesNbEchantillonsList() {
        this.sourcesEchant = await this.MesuresService.getSourcesNbEchantillonsList();
    }
}
