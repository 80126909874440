import ApiService from '../../../app/services/api.service';
import { Injectable } from '../../../core/decorators/injectable';
import { BaseHttpService } from '../../../core/interface/MassiaHttpService.interface';

@Injectable('TypeAideService')
export class TypeAideService extends BaseHttpService<any, any, any, any, any> {
    protected $translate: any;
    protected $http: any;
    /* @ngInject */
    constructor(ApiService: ApiService, $translate: any, $http: any) {
        super(ApiService);
        this.url = 'assets/enum/type-aide/type.aide.enum.json';
        this.$translate = $translate;
        this.$http = $http;
    }

    async getTypeAide() {
        let res = await this.$http.get(`${this.url}`);
        for (let i = 0; i < res.data.length; i++) {
            if (res && res.data && res.data.length > 0) {
                const data = res.data[i];
                data.libelle = this.$translate.instant(data.translate);
            }
        }
        return res.data;
    }
}
