const stylesTraceByValue = {
    0: 'aucun',
    1: 'ligne',
    2: 'pointilles',
    3: 'tirets_courts',
    4: 'tirets_longs',
    5: 'tirets_espaces'
};

const stylesTraceValuesByName = {
    aucun: 0,
    ligne: 1,
    pointilles: 2,
    tirets_courts: 3,
    tirets_longs: 4,
    tirets_espaces: 5
};

export function getStyleTraceNamesByValues() {
    return angular.copy(stylesTraceByValue);
}

export function getStyleTraceNamesWithValues() {
    return angular.copy(stylesTraceValuesByName);
}
