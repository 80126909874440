import Coefficientk from './produit.coefficientk.model';

export default class ProduitFormCoefficientkController {
    /* @ngInject */
    constructor(
        NormesService,
        globalizationManagementService,
        CoefficientkService,
        ElementCaracteristiqueService,
        CaracteristiquesService,
        moment,
        $scope
    ) {
        this.NormesService = NormesService;
        this.CoefficientkService = CoefficientkService;
        this.ElementCaracteristiqueService = ElementCaracteristiqueService;
        this.CaracteristiquesService = CaracteristiquesService;
        this.moment = moment;
        this.$scope = $scope;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    async $onInit() {
        this.startLoading();

        // recuperation de la liste des normes specifiques
        const normesList = await this.NormesService.getNormeSpecList('BPE');
        this.normesList = angular.copy(normesList);

        // recuperation de la liste des producteur du produit
        const producteursList = this.produit.producteurs.map((x) => {
            if (x.selected) {
                x.selected = false;
            }
            return x;
        });
        this.producteursList = angular.copy(producteursList);

        // reuperation liste type ciment
        const caracteristiqueTypeCimentList = await this.ElementCaracteristiqueService.getAll('CarCimType');
        this.caracteristiqueTypeCimentList = angular.copy(caracteristiqueTypeCimentList);

        // recuperation liste des kprime
        const kprimeList = await this.CoefficientkService.getKPrime();
        this.kprimeList = angular.copy(kprimeList);

        const data = {
            producteursList: angular.copy(this.producteursList),
            kprimeList: angular.copy(this.kprimeList),
            normesList: angular.copy(this.normesList),
            clientsList: [],
            typeCimentList: angular.copy(caracteristiqueTypeCimentList),
            resistanceCimentList: []
        };

        if (this.produit && this.produit.coefficientK) {
            for (let i = 0; i < this.produit.coefficientK.length; i++) {
                const ligne = this.produit.coefficientK[i];
                ligne.normesList = angular.copy(normesList);
                ligne.producteursList = angular.copy(producteursList);
                ligne.kprimeList = angular.copy(kprimeList);
                ligne.date = ligne.date ? new Date(ligne.date) : null;
                ligne.clientsList = [];
                ligne.typeCimentList = angular.copy(caracteristiqueTypeCimentList);
                ligne.resistanceCimentList = [];
                if (ligne.producteur !== null) {
                    this.getClientList(ligne, ligne.producteur.id);
                }
            }
        }

        this.unregister = [];
        this.unregister.push(
            this.$scope.$watch(
                () => {
                    if (this.produit && this.produit.producteurs) {
                        return this.produit.producteurs;
                    }
                },
                async (newValue, oldValue) => {
                    if (!angular.equals(newValue, oldValue)) {
                        this.setListProducteur(this.produit.producteurs);
                    }
                }
            )
        );
        this.stopLoading();
    }

    async $onDestroy() {
        for (let i = 0; i < this.unregister.length; i++) {
            this.unregister[i]();
        }
    }

    getDate(date) {
        if (!date) {
            return null;
        }
        const dateString = this.moment(date).format(this.dateFormat);
        return dateString;
    }

    selectItem(data, ligne, theme, list) {
        if (data) {
            if (ligne[theme] != null && (ligne[theme].id === data.id || (theme === 'kPrime' && ligne[theme] === data.id))) {
                ligne[theme] = null;
                list = list.map((x) => {
                    if (x.selected) {
                        x.selected = false;
                    }
                    return x;
                });
            } else {
                if (theme === 'kPrime') {
                    ligne[theme] = data.id;
                } else {
                    ligne[theme] = data;
                }
            }
        }

        if (theme === 'producteur') {
            this.getClientList(ligne, data.id);
        }

        return ligne;
    }

    // recuperation des clients par producteur (si selectionné)
    getClientList(ligne, id) {
        if (id) {
            const clientsList = this.produit.siteLinked.find((x) => x.producteur.id === id).clients;
            ligne.clientsList = angular.copy(
                clientsList.map((x) => {
                    if (x.selected) {
                        x.selected = false;
                    }
                    if (ligne.client && ligne.client.id && x.id === ligne.client.id) {
                        x.selected = true;
                    }

                    return x;
                })
            );

            if (ligne.client) {
                if (!ligne.clientsList.find((x) => x.id === ligne.client.id)) {
                    ligne.client = null;
                }
            }
        }
    }

    // récuperation des producteur du produit
    setListProducteur(list) {
        if (list && list.length > 0) {
            _.forEach(this.produit.coefficientK, (ligne) => {
                list.map((x) => {
                    if (x.selected) {
                        x.selected = false;
                        if (ligne.producteur && x.id === ligne.producteur.id) {
                            x.selected = true;
                        }
                    }

                    return x;
                });

                if (ligne.producteur) {
                    if (!list.find(x.id === ligne.producteur.id)) {
                        ligne.producteur = null;
                        ligne.clientsList = [];
                    }
                }
                ligne.producteursList = angular.copy(list);
            });
        }
    }

    addLine() {
        /* recuperation des liste a mettre dans les istevens */
        const data = {
            producteursList: angular.copy(this.producteursList),
            clientsList: [],
            typeCimentList: angular.copy(this.caracteristiqueTypeCimentList),
            kprimeList: angular.copy(this.kprimeList),
            normesList: angular.copy(this.normesList),
            resistanceCimentList: []
        };
        /* creation d'un nouvelle ligne */
        const obj = new Coefficientk(data, this.moment, this.dateFormat);
        this.produit.coefficientK.push(obj);
    }

    removeLine(index) {
        if (typeof index === 'number') {
            this.produit.coefficientK.splice(index, 1);
        }
    }

    blurDate({ date, ligne }) {
        if (!date || date === '') {
            ligne.date = null;
        } else {
            ligne.date = this.moment(date, this.dateFormat).toDate();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
    debug(ligne) {
        console.log(angular.copy(ligne));
    }
}
