TypeBenneDetailController.$inject = ['TypeBennesService', '$state', 'notification'];

export default function TypeBenneDetailController(TypeBennesService, $state, notification) {
    /* jshint validthis: true */
    const vm = this;

    // Propriétés
    vm.loading = false;
    vm.typeBenne = {};

    // Méthodes

    // Evènements
    vm.$onInit = onInit;

    // Méthodes publiques
    function onInit() {
        loadTypeBenne();
    }

    // Méthodes privées
    async function loadTypeBenne() {
        startLoading();

        try {
            vm.typeBenne = await TypeBennesService.getTypeBenneById($state.params.id);
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    // Méthodes privées
    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }
}
