export default class SyntheseEntitiesTraitementController {
    /* @ngInject */
    constructor(
        $scope,
        $state,
        $stateParams,
        $translate,
        notification,
        moment,
        $uibModal,
        MassiaApplicationService,
        SyntheseEntitiesService,
        SyntheseEntitiesCommunicationService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.notification = notification;
        this.moment = moment;
        this.$uibModal = $uibModal;

        this.MassiaApplicationService = MassiaApplicationService;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
    }

    async $onInit() {
        this.dragOptions = {
            placeholder: 'table-row-ui-placeholder',
            revert: true,
            animation: 200,
            axis: 'y',
            'ui-preserve-size': true
        };

        this.activite = this.MassiaApplicationService.getApplication();

        this.startLoading();
        await this.getCalculsStatistiques();
        this.stopLoading();

        //Dans le cas d'une carte contrôle résistance, on profite de la propriété de groupement des traitements
        //Il peut y avoir plusieurs blocs de traitements possibles (1 pour tous béton, 1 pour les familles)
        //On récupère donc l'ensemble des traitement pour cette synthèse puis on les regroupe par leur index
        //On va ensuite réaliser l'initialisation de la liste de choix pour chacun
        if (this.syntheseEntity.typeDeSynthese === 8) {
            this.syntheseEntity.grpSyntheseCritereTraitement = [];

            this.criteresTraitement.map((e) => {
                delete e.ticked;
                return e;
            });

            const grp = this.groupBy(this.syntheseEntity.listeSyntheseCritereTraitement, 'groupementIndex');
            for (let iGrp = 0; iGrp < 3; iGrp++) {
                const syntheseCrtiAff = { criteresTraitement: angular.copy(this.criteresTraitement) };
                syntheseCrtiAff.listeSyntheseCritereTraitement = grp[iGrp] ? grp[iGrp] : [];
                for (let i = 0; i < syntheseCrtiAff.listeSyntheseCritereTraitement.length; i++) {
                    const crit = syntheseCrtiAff.criteresTraitement.find(function (e) {
                        return e.idCalcStat === syntheseCrtiAff.listeSyntheseCritereTraitement[i].idCalcStat;
                    });
                    if (crit) {
                        crit.ticked = true;
                    }
                }

                if (iGrp === 0) {
                    syntheseCrtiAff.titre = 'Affichage calculs par bétons';
                } else if (iGrp === 1) {
                    syntheseCrtiAff.titre = 'Affichage calculs statistiques familles bétons (Calcul en ligne - valeurs glissantes)';
                } else if (iGrp === 2) {
                    syntheseCrtiAff.titre = 'Affichage calculs statistiques familles bétons (Calcul en colonne - valeurs uniques)';
                } else {
                    syntheseCrtiAff.titre = 'Affichage calculs N.C.';
                }

                this.syntheseEntity.grpSyntheseCritereTraitement.push(syntheseCrtiAff);
            }
        }
    }

    $onDestroy() {}

    async initForm() {}

    groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    async getCalculsStatistiques() {
        try {
            const tempList = await this.SyntheseEntitiesService.getCalculsStatistiques(this.activite);

            //enlever les fuseaux (gérés uniquement sur la courbe granulo)
            const allFuseau = tempList.filter((elt) => {
                return elt.isFuseau;
            });
            if (allFuseau && allFuseau.length > 0) {
                for (let i = 0; i < allFuseau.length; i++) {
                    const idxDel = tempList.findIndex((elt) => {
                        return elt.id === allFuseau[i].id;
                    });
                    tempList.splice(idxDel, 1);
                }
            }

            this.criteresTraitement = await this.formattingListCalculsStatistiques(tempList);
            //console.log(this.criteresTraitement);
        } catch (err) {
            this.notification.error(err.data);
        }
    }

    formattingListCalculsStatistiques(listToFormat) {
        // pour cocher les critères déjà enregistrés
        let haveToTick = false;
        if (
            this.syntheseEntity &&
            this.syntheseEntity.listeSyntheseCritereTraitement &&
            this.syntheseEntity.listeSyntheseCritereTraitement.length > 0
        ) {
            haveToTick = true;
        }

        const toReturn = [];
        for (let index = 0; index < listToFormat.length; index++) {
            const currentValue = listToFormat[index];

            // on récupère les données nécessaires au bon enregistrement du critère
            const newlist = {
                ticked: false,
                idCalcStat: currentValue.id,
                libelle: currentValue.libelle,
                code: currentValue.code,
                formule: currentValue.formule,
                formule2: currentValue.formule2
            };

            if (haveToTick) {
                newlist.ticked = this.tickSelectedCritAffTra(newlist, 'tra');
            }

            toReturn.push(newlist);
        }

        return new Promise((resolve) => resolve(toReturn));
    }

    tickSelectedCritAffTra(line, whatList) {
        let toCheck = false;
        if (whatList === 'aff') {
            const index = this.syntheseEntity.listeSyntheseCritereAffichage.findIndex(function (element) {
                if (
                    element.idDonneeSelectionnable === line.idDonneeSelectionnable &&
                    element.idCaracteristique === line.idCaracteristique &&
                    element.idType === line.idType &&
                    (element.champ === line.champ || element.champCompo === line.champ)
                ) {
                    return true;
                }
                return false;
            });
            if (index > -1) {
                toCheck = true;
            }
        } else if (whatList === 'tra') {
            if (
                this.syntheseEntity.listeSyntheseCritereTraitement.findIndex((e) => {
                    return e.idCalcStat === line.idCalcStat;
                }) >= 0
            ) {
                toCheck = true;
            }
        }
        return toCheck;
    }

    updateCritereAffichage(listFromBDD, listOriginal, whatList) {
        for (let i = 0; i < listOriginal.length; i++) {
            const crit = listOriginal[i];

            if (crit.msGroup === true || crit.msGroup === false) {
                continue;
            }

            let index = -1;
            if (this.syntheseEntity && whatList === 'tra' && listFromBDD && listFromBDD.length > 0) {
                index = listFromBDD.findIndex((x) => {
                    return x.idCalcStat === crit.idCalcStat;
                });
            }

            if (index === -1) {
                // s'il n'existe pas encore
                if (crit.ticked) {
                    // cochée
                    this.addCritereAffTra(crit, whatList, listFromBDD);
                } else if (!crit.ticked) {
                    // décochée
                    // ne rien faire
                }
            } else {
                // s'il existe déjà
                if (crit.ticked) {
                    // cochée
                    // ne rien faire
                } else if (!crit.ticked) {
                    // décochée
                    this.deleteCritereAffichage(crit, whatList, listFromBDD);
                }
            }
        }

        for (let i = 0; i < listFromBDD.length; i++) {
            this.syntheseEntity.listeSyntheseCritereTraitement[i].couleurTexte.hue = 0;
            this.syntheseEntity.listeSyntheseCritereTraitement[i].couleurTexte.saturation = 0;
            this.syntheseEntity.listeSyntheseCritereTraitement[i].couleurTexte.luminosity = 0;
        }
    }

    // pour ajouter un critère dans la liste avec ses valeurs par défaut
    addCritereAffTra(crit, whatList, listToAdd) {
        const lineToAdd = {};
        lineToAdd.id = -1;
        if (whatList === 'tra') {
            lineToAdd.position = this.syntheseEntity.listeSyntheseCritereTraitement.length + 1;
            lineToAdd.idSynthCritTraitement = crit.idSynthCritTraitement;
            lineToAdd.idCalcStat = crit.idCalcStat;
            lineToAdd.libelle = crit.libelle;
            lineToAdd.code = crit.code;
            lineToAdd.formule = crit.formule;
            lineToAdd.formule2 = crit.formule2;
            lineToAdd.couleurFond = { hue: 0, saturation: 50, luminosity: 100 };
            lineToAdd.couleurTexte = { hue: 0, saturation: 50, luminosity: 0 };
            lineToAdd.isImprimable = true;
            listToAdd.push(lineToAdd);
        }
    }

    deleteCritereAffichage(line, whatList, listToDelete) {
        if (whatList === 'aff') {
            const idxDel = listToDelete.findIndex((x) => {
                return (
                    x.idDonneeSelectionnable === line.idDonneeSelectionnable &&
                    x.idCaracteristique === line.idCaracteristique &&
                    x.idType === line.idType &&
                    (x.champCompo === line.champ || x.champ === line.champ)
                );
            });

            listToDelete.splice(idxDel, 1);
        } else if (whatList === 'tra') {
            const idxDel = listToDelete.findIndex((x) => {
                return x.idCalcStat === line.idCalcStat;
            });

            listToDelete.splice(idxDel, 1);
        }
    }

    async createPicker($color, couleur) {
        const hsl = $color.HSLA;
        couleur.hue = hsl[0];
        couleur.saturation = hsl[1];
        couleur.luminosity = hsl[2];
        couleur.alpha = hsl[3];
    }

    deleteTraitement(elt) {
        const crit = this.syntheseEntity.listeSyntheseCritereTraitement.indexOf(elt);
        this.syntheseEntity.listeSyntheseCritereTraitement.splice(crit, 1);

        const traitement = this.criteresTraitement.find((e) => {
            return e.idCalcStat === elt.idCalcStat;
        });
        traitement.ticked = false;
    }

    supprimerTousTraitements() {
        this.syntheseEntity.listeSyntheseCritereTraitement = [];

        this.criteresTraitement.map((e) => {
            delete e.ticked;
            return e;
        });
    }

    deleteGrpTraitement(elt, traitement) {
        const crit = traitement.listeSyntheseCritereTraitement.indexOf(elt);
        traitement.listeSyntheseCritereTraitement.splice(crit, 1);

        const t = traitement.criteresTraitement.find((e) => {
            return e.idCalcStat === elt.idCalcStat;
        });
        t.ticked = false;
    }

    supprimerTousGrpTraitements(traitement) {
        traitement.listeSyntheseCritereTraitement = [];

        traitement.criteresTraitement.map((e) => {
            delete e.ticked;
            return e;
        });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
