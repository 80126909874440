//Config
import ImagesConfig from './_config/images.config';
import ImagesRoutes from './_config/images.routes';

//Components
import ImagesComponent from './components/images';
import ImageFormComponent from './components/image-form';
import ImageDetailComponent from './components/image-detail';

import ImageFormEnteteComponent from './components/image-form/entete';
import ImageFormCaracteristiquesComponent from './components/image-form/caracteristiques';

import ImagesDownloadComponent from './components/images-download';

//services
import ImagesService from './services/images.service';
import ImagesTypesService from './services/images.types.service';
import ImagesCommunicationService from './services/images.communication.service';

//Validateurs
import ImageValidator from './components/image-form/image.validator';
import ImageEnteteValidator from './components/image-form/entete/image.entete.validator';
import ImageCaracteristiquesValidator from './components/image-form/caracteristiques/image.caracteristiques.validator';

const moduleName = 'app.massia.common.images';

angular
    .module(moduleName, [])
    .config(ImagesConfig)
    .config(ImagesRoutes)
    .service('ImagesService', ImagesService)
    .service('ImagesTypesService', ImagesTypesService)
    .service('ImagesCommunicationService', ImagesCommunicationService)
    .component('images', ImagesComponent)
    .component('imageForm', ImageFormComponent)
    .component('imageDetail', ImageDetailComponent)
    .component('imageFormEntete', ImageFormEnteteComponent)
    .component('imageFormCaracteristiques', ImageFormCaracteristiquesComponent)
    .component('imagesDownload', ImagesDownloadComponent)
    .factory('ImageValidator', ImageValidator)
    .factory('ImageEnteteValidator', ImageEnteteValidator)
    .factory('ImageCaracteristiquesValidator', ImageCaracteristiquesValidator);

export default moduleName;
