import TemplateImpressionConfiguration from './_config/template.impression.config';
import TemplateImpressionRoute from './_config/template.impression.routes';
import templateList from './components/template-list';
import TemplateImpressionService from './services/template.impression.service';
import templateForm from './components/template-form';
import TemplateImpressionValidator from './components/template-form/template.validator';
import templateDetail from './components/template-detail';

const moduleName = 'app.massia.common.impression';

angular
    .module(moduleName, [])
    .config(TemplateImpressionConfiguration)
    .config(TemplateImpressionRoute)
    .component('templateList', templateList)
    .component('templateForm', templateForm)
    .component('templateDetail', templateDetail)
    .service('TemplateImpressionService', TemplateImpressionService)
    .factory('TemplateImpressionValidator', TemplateImpressionValidator);

export default moduleName;
