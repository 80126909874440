import BlocSelectionService from './bloc.selection.service';
import SyntheseCacheService from './synthese.cache.service';
import SyntheseEntityService from './synthese.entity.service';
import SyntheseSettingService from './synthese.setting.service';
import SyntheseModelesCommunicationService from './synthesemodeles.communication.service';
import SyntheseModelesService from './synthesemodeles.service';

const moduleName = 'app.massia.common.synthese.service';

angular
    .module(moduleName, [])
    .service('SyntheseSettingService', SyntheseSettingService)
    .service('SyntheseCacheService', SyntheseCacheService)
    .service('SyntheseModelesService', SyntheseModelesService)
    .service('SyntheseEntityService', SyntheseEntityService)
    .service('BlocSelectionService', BlocSelectionService)
    .service('SyntheseModelesCommunicationService', SyntheseModelesCommunicationService);

export default moduleName;
