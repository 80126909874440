import DAPAccuseAcceptationPopupController from './dap.accuse.acceptation.popup.controller';

export default {
    bindings: {
        modalInstance: '=',
        resolve: '<',
        dap: '='
    },
    template: require('./dap.accuse.acceptation.popup.html'),
    controller: DAPAccuseAcceptationPopupController
};
