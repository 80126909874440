import validatorBuilder from 'validator-builder';
import PersonneCaracteristiques from './caracteristiques/personne.caracteristiques.model';

let personneValidator = null;

export default class Personne {
	constructor(data) {
		data = data || {};
		this.id = data.id;
		this.code = data.code;
		this.codeExists = data.codeExists || null;
		this.codeInvalid = data.codeInvalid || null;
		this.nom = data.nom;
		this.prenom = data.prenom;
		this.idType = data.idType;
		this.idSociete = data.idSociete;
		this.idSite = data.idSite;
		this.mail = data.mail;
		this.telFixe = data.telFixe;
		this.telMobile = data.telMobile;
		this.isChauffeur = data.isChauffeur;
		this.isUtilisateur = data.isUtilisateur;
		this.isPersonne = data.isPersonne;
		this.isContact = data.isContact;
		this.login = data.login;
		this.password = data.password;
		this.passwordConfirm = data.password;
		this.profilIds = data.profilIds || [];
		this.loginExists = data.loginExists || null;

		this.affectations = data.affectations || [];
		this.horaires = data.horaires || [];
		this.lastConnection = data.lastConnection;
		this.horaireDefautDebut = data.horaireDefautDebut;
		this.horaireDefautFin = data.horaireDefautFin;
		this.caracteristiques = new PersonneCaracteristiques(data.caracteristiques);
		this.niveauVisibilite = 0;
		this.legalNoticeAccept = data.legalNoticeAccept;
		this.showEvolutions = data.showEvolutions;
		this.automaticPassword = data.automaticPassword;

		this.idSiteDepartDefaut = data.idSiteDepartDefaut;
		this.isPresent = data.isPresent;
		this.fonction = data.fonction;
		this.isDematBLSMS = data.isDematBLSMS;
		this.isDematBLMail = data.isDematBLMail;
		this.idSignature = data.idSignature;
		this.isEssaisPlanifies = data.isEssaisPlanifies;
		this.initiales = data.initiales;
		this.isCreatePrelevement = data.isCreatePrelevement;

		this.raisonSociale = data.raisonSociale;
		this.siret = data.siret;
		this.adresse = data.adresse;
		this.codePostal = data.codePostal;
		this.ville = data.ville;
	}

	isValid() {
		personneValidator = personneValidator || validatorBuilder.getInstanceFor('Personne');
		const validationResults = personneValidator.validate(this);
		return validationResults.isValid;
	}
}
