export default class IndicesRevisionResultatController {
    static $inject = ['$stateParams', '$state', 'IndicesRevisionService', 'notification'];

    constructor($stateParams, $state, IndicesRevisionService, notification) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.IndicesRevisionService = IndicesRevisionService;
        this.notification = notification;
    }

    async $onInit() {
        this.loading = false;
        this.revision = {};
        await this.initRevision();
    }

    async initRevision() {
        // si on recoit bien l objet
        if (this.$state.params.obj) {
            if (this.$state.params.obj.length != 0) {
                try {
                    this.startLoading();
                    // on met a jour les donnees du scope 'detail'
                    this.revision = this.$state.params.obj;
                } catch (ex) {
                    this.notification.error(ex.data);
                } finally {
                    this.stopLoading();
                }
            }
        }
    }

    async genererRevision() {
        const resultatFa = await this.IndicesRevisionService.genererRevision(this.revision);
        // si on a un objet, on va sur la page liste de factures en passant l objet
        if (resultatFa.length != 0) {
            try {
                this.$state.go('factures.list', { obj: resultatFa });
            } catch (ex) {
                this.notification.error(ex.data);
            }
        } else {
            this.$state.go('revisions-prix.generation');
            this.notification.info('PREFACTURATION.WARNINGNOFACTURE');
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
