import ApiService from '../../app/services/api.service';
import { Injectable } from '../decorators';
import { FilterProperty } from '../interface/FilterProperty';
import { BaseHttpService } from '../interface/MassiaHttpService.interface';
import { PaginationProperty } from '../interface/PaginationProperty.interface';
import { SortProperty } from '../interface/SortProperty';

@Injectable('FilterService')
export class FilterService extends BaseHttpService<any, any, any, any, any> {
    /* @ngInject */
    constructor(ApiService: ApiService) {
        super(ApiService);
        this.url = 'v2/massia/filter';
    }

    getAllFilter(domain: string, idType?: number, idFamille?: number) {
        const params: { domain: string; type?: number; famille?: number } = {
            domain
        };
        idType && (params['type'] = idType);
        idFamille && (params['famille'] = idFamille);
        return this.apiSvc.get(`${this.url}`, { params });
    }

    applyFilter(
        domain: string,
        filters: {
            application: string;
            isFiltered: boolean;
            filters: FilterProperty[];
            sorts: SortProperty[];
            pagination: PaginationProperty;
            filterDtos: any;
        }
    ) {
        return this.apiSvc.post(`${this.url}/${domain}`, filters);
    }
}
