export default function EnumReplace() {
    return function (valeur, liste) {
        let notfound = 0;
        if (typeof liste !== 'undefined') {
            for (let i = 0; i < liste.length; i++) {
                const element = liste[i];
                if (element.id == valeur) {
                    return element.libelle;
                }
                notfound++;
            }
            if (notfound == liste.length) {
                console.group('EnumReplace(Filter)');
                console.log("L'enum n'a pas trouvé de correspondance avec les données suivantes : ");
                console.log(valeur);
                console.log(liste);
                console.groupEnd();
            }
        }
    };
}
