//Config
import TachesConfig from './_config/taches.config';
import TachesRoutes from './_config/taches.routes';

//Components
import TachesComponent from './components/taches';
import TacheFormComponent from './components/tache-form';
import TacheDetailComponent from './components/tache-detail';

import TacheFormEnteteComponent from './components/tache-form/entete';
import TacheFormCaracteristiquesComponent from './components/tache-form/caracteristiques';

import TachesLieesComponent from './components/taches-liees';

//services
import TachesService from './services/taches.service';
import TachesTypesService from './services/taches.types.service';
import TachesCommunicationService from './services/taches.communication.service';

//Validateurs
import TacheValidator from './components/tache-form/tache.validator';
import TacheEnteteValidator from './components/tache-form/entete/tache.entete.validator';
import TacheCaracteristiquesValidator from './components/tache-form/caracteristiques/tache.caracteristiques.validator';

const moduleName = 'app.massia.common.taches';

angular
    .module(moduleName, [])
    .config(TachesConfig)
    .config(TachesRoutes)
    .service('TachesService', TachesService)
    .service('TachesTypesService', TachesTypesService)
    .service('TachesCommunicationService', TachesCommunicationService)
    .component('taches', TachesComponent)
    .component('tacheForm', TacheFormComponent)
    .component('tacheDetail', TacheDetailComponent)
    .component('tacheFormEntete', TacheFormEnteteComponent)
    .component('tacheFormCaracteristiques', TacheFormCaracteristiquesComponent)
    .component('tachesLiees', TachesLieesComponent)
    .factory('TacheValidator', TacheValidator)
    .factory('TacheEnteteValidator', TacheEnteteValidator)
    .factory('TacheCaracteristiquesValidator', TacheCaracteristiquesValidator);

export default moduleName;
