import SyntheseEntitiesTraitementController from './syntheseentities.traitement.controller';

export default {
    bindings: {
        syntheseEntity: '=',
        forGeneration: '=',
        ongletOpen: '='
    },
    template: require('./syntheseentities.traitement.html'),
    controller: SyntheseEntitiesTraitementController
};
