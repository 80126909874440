UtilisateurPasswordValidator.$inject = ['validator'];

export default function UtilisateurPasswordValidator(validator) {
    const instance = new validator();

    instance.ruleFor('password').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('passwordConfirm').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('passwordConfirm').must(confirmPassword).withMessage('UTILISATEURS.PASSWORD_NOT_SAME');

    return instance;

    function confirmPassword(obj, prop) {
        if (obj && prop) {
            return obj.password === prop;
        }

        return true;
    }
}
