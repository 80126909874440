import ApiService from "../../app/services/api.service";
import { Injectable } from "../decorators";
import { BaseHttpService } from "../interface/MassiaHttpService.interface";
import { Gamme } from "../models/gamme/Gamme";

@Injectable("GammeService")
export class GammeService extends BaseHttpService<Gamme, Gamme, Gamme, Gamme, Gamme> {
    /* @ngInject */
    constructor(ApiService: ApiService) {
        super(ApiService);
        this.url = "v2/massia/gammes";
    }
}