export default class CarnetsSuiviController {
    static $inject = [
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'CarnetsSuiviService',
        'notification',
        '$location',
        '$anchorScroll',
        '$uibModal',
        '_',
        'ModalService',
        '$translate'
    ];

    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        CarnetsSuiviService,
        notification,
        $location,
        $anchorScroll,
        $uibModal,
        _,
        ModalService,
        $translate
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.CarnetsSuiviService = CarnetsSuiviService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.$uibModal = $uibModal;
        this._ = _;
        this.ModalService = ModalService;
        this.$translate = $translate;
    }

    async $onInit() {
        this.carnetSuivi = {};
        this.carnetSuivi.etat = '-1';
        this.carnetSuivi.etatBon = '-1';
        this.loading = false;
        this.form = {};

        await this.reset();

        this.societes = await this.CarnetsSuiviService.getSocietes();
        this.sites = await this.CarnetsSuiviService.getSitesCommerciaux(this.carnetSuivi.societeId);
        this.clients = await this.CarnetsSuiviService.getClients();
        if (this.carnetSuivi.siteId) {
            this.chantiers = await this.CarnetsSuiviService.getChantiers(this.carnetSuivi.siteId);
        }
    }

    async reset() {
        this.startLoading();

        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.stopLoading();
    }

    async changeSociete() {
        this.sites = await this.CarnetsSuiviService.getSitesCommerciaux(this.carnetSuivi.societeId);
    }

    async changeSite() {
        this.chantiers = await this.CarnetsSuiviService.getChantiers(this.carnetSuivi.siteId);
    }

    async filtrer() {
        const filtres = {
            societeId: this.carnetSuivi.societeId || null,
            siteId: this.carnetSuivi.siteId || null,
            clientId: this.siteClient != null ? this.siteClient.id : null,
            chantierId: this.carnetSuivi.chantierId || null,
            dateDebut: this.carnetSuivi.dateDebut || null,
            dateFin: this.carnetSuivi.dateFin || null,
            etat: this.carnetSuivi.etat == -1 ? null : this.carnetSuivi.etat,
            dateDebutPassageTicket: this.carnetSuivi.dateDebutPassageTicket || null,
            dateFinPassageTicket: this.carnetSuivi.dateFinPassageTicket || null,
            numeroTicket: this.carnetSuivi.numeroTicket || null,
            numeroFacture: this.carnetSuivi.numeroFacture || null,
            etatBon: this.carnetSuivi.etatBon == -1 ? null : this.carnetSuivi.etatBon
        };

        this.resultat = await this.CarnetsSuiviService.getCarnets(filtres);
    }

    async print(isPdf) {
        const vm = this;

        const fileName = Date.now();

        const ids = [];
        for (let i = 0; i < vm.resultat.carnets.length; i++) {
            const carnet = vm.resultat.carnets[i];
            ids.push(carnet.id);
        }

        const resultat = await vm.CarnetsSuiviService.print(ids, fileName, isPdf, this.carnetSuivi.etatBon == -1 ? null : this.carnetSuivi.etatBon);

        const data = resultat.data;
        const status = resultat.status;
        let headers = resultat.headers;

        headers = headers();

        const contentType = headers['content-type'];

        const fileExtention = isPdf ? '.pdf' : '.xlsx';
        const linkElement = document.createElement('a');
        try {
            const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
            const url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute('download', fileName + fileExtention);

            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(clickEvent);
        } catch (ex) {
        } finally {
            //this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
