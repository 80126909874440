const serviceUrl = `${__configuration.apiUrl}/massia/jours-feries`;

export default class JoursFeriesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getJoursFeries(filters, sorts, pagination, calendar = false, full = false) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {}),
                calendar: calendar,
                full: full
            }
        });

        return res.data;
    }

    async deleteJourFerieById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getJourFerieById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getFeriesByJourFerieId(id, dateDeb, dateFin) {
        const filtres = {
            idJourFerie: id,
            dateDebut: dateDeb,
            dateFin: dateFin
        };
        const url = `${serviceUrl}/feries`;
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {})
            }
        });
        return res.data;
    }

    async createJourFerie(jFerie) {
        const result = await this.$http.post(serviceUrl, jFerie);
        return result.data;
    }

    async updateJourFerie(jFerie) {
        const url = `${serviceUrl}/${jFerie.id}`;
        return this.$http.put(url, jFerie);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }
}
