import ClientDiversEnteteController from './client.divers.entete.controller';

export default {
    bindings: {
        clientDivers: '=',
        isOpen: '='
    },
    template: require('./client.divers.entete.html'),
    controller: ClientDiversEnteteController
};
