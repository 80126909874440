import mainElectron from './components/main';
import configurationElectron from './components/configuration';

import ConfigTranslation from './_config/config.translation';

const moduleName = 'app.massia.electron';

angular
    .module(moduleName, [])
    .config(ConfigTranslation)
    .component('mainElectron', mainElectron)
    .component('configurationElectron', configurationElectron);

export default moduleName;
