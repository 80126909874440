export default class SyntheseEntitiesControleController {
    /* @ngInject */
    constructor(
        $scope,
        $state,
        $stateParams,
        $translate,
        notification,
        moment,
        $uibModal,
        MassiaApplicationService,
        SyntheseEntitiesService,
        SyntheseEntitiesCommunicationService,
        ReferencesControleService,
        NceService,
        SitesService,
        ProduitsService,
        NormesService,
        _
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.notification = notification;
        this.moment = moment;
        this.$uibModal = $uibModal;
        this._ = _;
        this.MassiaApplicationService = MassiaApplicationService;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
        this.ReferencesControleService = ReferencesControleService;
        this.NceService = NceService;
        this.SitesService = SitesService;
        this.ProduitsService = ProduitsService;
        this.NormesService = NormesService;
        this.codeFamille = null;
        this.siteProdIsSelected = false;
        this.allFiltersAreReady = false;
    }

    async $onInit() {
        try {
            this.startLoading();
            this.dragOptions = {
                placeholder: 'table-row-ui-placeholder',
                revert: true,
                animation: 200,
                axis: 'y',
                'ui-preserve-size': true
            };

            this.activite = this.MassiaApplicationService.getApplication();

            const that = this;
            that.$scope.$watchCollection('$ctrl.syntheseEntity.listeReferences', async function (newColl, oldColl) {
                if (newColl) {
                    that.listeReferencesByNorme = angular.copy(that.syntheseEntity.listeReferences);
                    // pour cocher la valeur enregistrée OU supprimer du formulaire la référence
                    if (that.syntheseEntity.idReference !== 0) {
                        const ref = that.listeReferencesByNorme.find((e) => {
                            return e.referenceId === that.syntheseEntity.idReference;
                        });
                        if (!ref) {
                            that.listeRefsHorsFiltre = await that.getAllRefs();
                            that.selectedRef.push(
                                that._.find(that.listeRefsHorsFiltre, function (o) {
                                    return o.id === that.syntheseEntity.idReference;
                                })
                            );
                            if (!that.selectedRef || typeof that.selectedRef === undefined) {
                                that.syntheseEntity.idReference = 0;
                            } else {
                                await that.selectedRefWithoutFilters();
                            }
                        } else {
                            ref.valeur = true;
                        }
                    }

                    that.getNormes(false); //SP 02/06/20 : sinon recharge la liste des références et la désélectionne, on tourne en rond !

                    // if (oldColl && newColl !== oldColl) {
                    //     that.syntheseEntity.idReference = 0;
                    // }
                }
            });
            await this.getNormes(true);
            if (this.activite === 'laboratoire') {
                await this.getNQAs();
                await this.getNce();
            }
            this.selectRefWithoutFilter = false;
            await this.getCodeFamille();
        } catch (err) {
            console.error(err);
        } finally {
            this.stopLoading();
        }
    }

    $onDestroy() {}

    async getNce() {
        try {
            const listeNce = await this.NceService.getAllNce();
            this.listeNce = listeNce.items;
        } catch (err) {
            this.notification.error(err.data);
        }
    }

    async getNormes(rechargeRef) {
        if (this.syntheseEntity.idFamille) {
            try {
                // pour récupérer les données en bdd
                this.listeNormes = await this.SyntheseEntitiesService.getNormes(this.syntheseEntity.idFamille);

                const that = this;
                if (this.syntheseEntity.idNorme) {
                    const norme = this.listeNormes.find((e) => e.id === this.syntheseEntity.idNorme);
                    this.syntheseEntity.norme = [norme];

                    if (rechargeRef && this.syntheseEntity.listeReferences) {
                        this.listeReferencesByNorme = this.syntheseEntity.listeReferences.filter((e) => e.normeId === this.syntheseEntity.idNorme);
                    }
                } else if (rechargeRef && this.syntheseEntity.listeReferences) {
                    this.listeReferencesByNorme = this.syntheseEntity.listeReferences;
                }
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            }
        }
    }

    async getNQAs() {
        try {
            this.listeNQAs = (await this.SyntheseEntitiesService.getNQAs()) || [];
            this.listeNQAs.map(function (e) {
                e.displayLibelle = e.libelle; // + ' (' + e.nbResult1 + ' à ' + e.nbResult2 + ')';
                return e;
            });

            if (this.syntheseEntity.idNQA) {
                const nqa = this.listeNQAs.find((e) => {
                    return e.id === this.syntheseEntity.idNQA;
                });
                nqa.selected = true;
            }
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async getTypeSyntheses() {
        let listeTypesSynthese = [];
        try {
            const typeSyntheses = await this.SyntheseEntitiesService.getTypeSyntheses();
            listeTypesSynthese = typeSyntheses.filter((e) => {
                return e.activite === this.activite;
            });
        } catch (err) {
            console.log('erreur syntheseentities.controle.controller getTypeSyntheses');
            if (err.data) {
                this.notification.error(err.data);
            }
        }

        return listeTypesSynthese;
    }

    // extraire les ids des produits et des producteurs de la liste des critères pour recharger la liste des références
    extractIdsProduitProducteur() {
        this.listproduitids = [];
        this.listproducteurids = [];
        if (
            this.syntheseEntity &&
            this.syntheseEntity.listeSyntheseCritereValeurBlocks &&
            this.syntheseEntity.listeSyntheseCritereValeurBlocks.length > 0
        ) {
            for (let index = 0; index < this.syntheseEntity.listeSyntheseCritereValeurBlocks.length; index++) {
                const block = this.syntheseEntity.listeSyntheseCritereValeurBlocks[index];

                if (block.listeSyntheseCritereValeurLignes && block.listeSyntheseCritereValeurLignes.length > 0) {
                    for (let j = 0; j < block.listeSyntheseCritereValeurLignes.length; j++) {
                        const ligne = block.listeSyntheseCritereValeurLignes[j];

                        // si le critère est un type de champ IdTable ET que ce n'est pas une caractéristique
                        if (ligne.typeDeChamp === 7 && ligne.champ !== 3) {
                            // si c'est un critère produit
                            if (ligne.domaineCode === 'Produits') {
                                if (ligne.selectedEltsTable && ligne.selectedEltsTable.length > 0) {
                                    const tempListIds1 = ligne.selectedEltsTable.map((a) => a.id);
                                    this.listproduitids = this.listproduitids.concat(tempListIds1);
                                }
                            }

                            // si c'est un critère producteur
                            if (ligne.domaineCode === 'SiteProd') {
                                if (ligne.selectedEltsTable && ligne.selectedEltsTable.length > 0) {
                                    const tempListIds2 = ligne.selectedEltsTable.map((a) => a.id);
                                    this.listproducteurids = this.listproducteurids.concat(tempListIds2);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    changeCarteResistance(prop, ev) {
        if (prop === 'isProdInitiale') {
            this.syntheseEntity.isProdInitiale = ev.target.checked;
            this.syntheseEntity.isProdContinue = !ev.target.checked;
        } else if (prop === 'isProdContinue') {
            this.syntheseEntity.isProdInitiale = !ev.target.checked;
            this.syntheseEntity.isProdContinue = ev.target.checked;
        } else if (prop === 'isProdFamilleBeton') {
            this.syntheseEntity.isProdFamilleBeton = ev.target.checked;
            this.syntheseEntity.isProdBeton = !ev.target.checked;
        } else if (prop === 'isProdBeton') {
            this.syntheseEntity.isProdFamilleBeton = !ev.target.checked;
            this.syntheseEntity.isProdBeton = ev.target.checked;
        }
    }

    async selectNce(data, list) {
        if (data) {
            if (!this.syntheseEntity.nce || data.id !== this.syntheseEntity.nce.id) {
                this.syntheseEntity.nce = data;
            } else {
                list = list.map((e) => (e.selected = !e.selected));
                this.syntheseEntity.nce = null;
            }
        }
    }

    async selectNorme() {
        //if (this.syntheseEntity.norme && this.syntheseEntity.norme.length > 0) {
        if (
            this.selectedNorme &&
            this.selectedNorme.length > 0 &&
            (!this.syntheseEntity.norme ||
                this.syntheseEntity.norme.length == 0 ||
                !this.syntheseEntity.norme[0] ||
                this.syntheseEntity.norme[0].id !== this.selectedNorme[0].id)
        ) {
            this.syntheseEntity.norme = this.selectedNorme;
            const idNorme = this.syntheseEntity.norme[0].id;

            //SP 26/11/20 : ajout des essais de la norme à refaire, this.essaisLoaded n'existe pas, la déselection de listeLiensSETS ne fonctionne pas
            /*this.syntheseEntity.listeLiensSETS = [];
            if(this.essaisLoaded)
                this.essaisLoaded = this.essaisLoaded.map(function (e) {
                    e.selected = false;
                    return e;
                });*/

            if (idNorme) {
                this.listeReferencesByNorme = this.syntheseEntity.listeReferences.filter((e) => {
                    return e.normeId === idNorme;
                });
            } else {
                this.listeReferencesByNorme = this.syntheseEntity.listeReferences;
            }

            /*const essais = await this.SyntheseEntitiesService.getNormeSousEssais(idNorme);
            for (let i = 0; i < essais.length; i++) {

                this.syntheseEntity.listeLiensSETS.splice(0, 0, essais[i]);

                if (this.syntheseEntity.typeDeSynthese === 8) {
                    const idxSsEssPrcp = essais[i].listeSousEssaisHeaders.findIndex((e) => {
                        return e.codeLibelleSousEssai === essais[i].codeEssai + ' / ' + essais[i].libelleEssai;
                    });
                    essais[i].listeSousEssaisHeaders[idxSsEssPrcp].selected = true;
                } else {
                    for (let idxSsEssPrcp = 0; idxSsEssPrcp < essais[i].listeSousEssaisHeaders.length; idxSsEssPrcp++) {
                        essais[i].listeSousEssaisHeaders[idxSsEssPrcp].selected = true;
                    }
                }*/

            /*const idxEssPrcp = this.essaisLoaded ? this.essaisLoaded.findIndex((e) => {
                return e.codeLibelle === essais[i].codeEssai + ' / ' + essais[i].libelleEssai;
            }) : -1;
            if (idxEssPrcp >= 0) this.essaisLoaded[idxEssPrcp].selected = true;*/

            /*if (i === 0) {
                essais[i].isComplementaire = true;
            }
        }*/
            this.listeRefsHorsFiltre = await this.searchMatches(this.listeRefsHorsFiltre, this.selectedNorme[0].id, 'idNorme');
        } else if (this.selectedNorme && this.selectedNorme.length > 0 && this.syntheseEntity.norme && this.syntheseEntity.norme.length >= 0) {
            this.syntheseEntity.norme = this.selectedNorme;
            this.listeRefsHorsFiltre = await this.searchMatches(this.listeRefsHorsFiltre, this.selectedNorme[0].id, 'idNorme');
        } else {
            this.listeNormes = this.listeNormes.map((x) => {
                x.selected = false;
                return x;
            });
            this.syntheseEntity.norme = [];
            this.syntheseEntity.idNorme = -1;
            this.listeReferencesByNorme = this.syntheseEntity.listeReferences; //[];
            this.selectedNorme = [];
        }
        /*} else {
            this.syntheseEntity.norme = this.selectedNorme;
        }*/
    }

    async unselectNorme() {
        const elemUntick = this.listeNormes.find((e) => {
            return e.selected === true;
        });
        if (elemUntick) {
            elemUntick.selected = false;
        }

        this.syntheseEntity.norme = [];
        this.listeReferencesByNorme = this.syntheseEntity.listeReferences; //remettre toutes les références
    }

    async selectRef() {
        if (
            this.selectedRef &&
            this.selectedRef.length > 0 &&
            this.selectedRef[0].referenceId !== null &&
            typeof this.selectedRef[0].referenceId !== 'undefined'
        ) {
            if (this.syntheseEntity.idReference === this.selectedRef[0].referenceId) {
                this.syntheseEntity.idReference = -1;
                this.listeReferencesByNorme = this.listeReferencesByNorme.map((x) => {
                    x.valeur = false;
                    return x;
                });
            } else {
                this.syntheseEntity.idReference = this.selectedRef[0].referenceId;
            }
        } else {
            this.syntheseEntity.idReference = -1;
        }
    }

    async unselectRef() {
        const refToUntick = this.listeReferencesByNorme.find((e) => {
            return e.referenceId === this.syntheseEntity.idReference;
        });
        refToUntick.valeur = false;
        this.syntheseEntity.idReference = 0;
    }

    async unselectNQA() {
        const elemUntick = this.listeNQAs.find((e) => {
            return e.selected;
        });
        elemUntick.selected = false;
        this.syntheseEntity.NQA = null;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    async unfiltredReferences() {
        this.selectedRef = [];
        this.listeProducteurs = null;
        if (this.codeFamille !== null) {
            this.listeRefsHorsFiltre = await this.getAllRefs();
            this.listeProducteurs = await this.SitesService.getSitesProducteursByFamille(
                this.MassiaApplicationService.getApplication(),
                this.codeFamille
            );
        }
        this.selectRefWithoutFilter = true;
        this.allFiltersAreReady = true;
    }

    async getAllRefs() {
        return await this.ReferencesControleService.getReferencesByFamille(this.syntheseEntity.idFamille);
    }

    async selectedRefWithoutFilters() {
        if (this.selectedRef && this.selectedRef.length > 0) {
            const selectedRef = angular.copy(this.selectedRef);
            const idxLastElem = this._.findLastIndex(this.listeReferencesByNorme, function (o) {
                return o.referenceId === selectedRef[0].id;
            });
            if (idxLastElem > 0) {
                const refToActived = this.listeReferencesByNorme.find((e) => {
                    return e.referenceId === selectedRef[0].id;
                });
                refToActived.valeur = true;
                this.syntheseEntity.idReference = selectedRef[0].id;
            } else {
                this.syntheseEntity.idReference = selectedRef[0].id;
                let spcKeySearch = null;
                if (this.listeReferencesByNorme && this.listeReferencesByNorme.length > 0) {
                    spcKeySearch = Object.keys(this.listeReferencesByNorme[0]).filter(function (k) {
                        return _.includes(k, 'spc');
                    });
                    spcKeySearch = spcKeySearch[0];
                } else {
                    spcKeySearch = 'spacingProperty';
                }
                if (selectedRef[0].idNorme !== null && typeof selectedRef[0].idNorme !== undefined) {
                    const idxLastElemOfNorme = this._.findLastIndex(this.listeReferencesByNorme, function (o) {
                        return o.normeId === selectedRef[0].idNorme;
                    });
                    const normeToAdd = await this.NormesService.getNormeById(selectedRef[0].idNorme);
                    if (normeToAdd) {
                        if (idxLastElemOfNorme <= 0) {
                            this.listeReferencesByNorme.push({
                                libelle: '"<span style="font-weight:bold">' + normeToAdd.libelle + '</span>"',
                                msGroup: true,
                                normeId: normeToAdd.id,
                                valeur: false
                            });
                            this.listeReferencesByNorme.push({
                                libelle: selectedRef[0].libelle + ' [' + selectedRef[0].code + ']',
                                normeId: normeToAdd.id,
                                normeLibelle: normeToAdd.libelle,
                                referenceId: selectedRef[0].id,
                                [spcKeySearch]: 2,
                                valeur: true
                            });
                        } else {
                            const refToAdd = {
                                libelle: selectedRef[0].libelle + ' ' + selectedRef[0].code,
                                normeId: normeToAdd.id,
                                normeLibelle: normeToAdd.libelle,
                                referenceId: selectedRef[0].id,
                                [spcKeySearch]: 2,
                                valeur: true
                            };
                            const idxLastRefOfNorme = this._.findLastIndex(this.listeReferencesByNorme, function (o) {
                                return o.normeId === normeToAdd.id;
                            });
                            this.listeReferencesByNorme.splice(idxLastRefOfNorme + 1, 0, refToAdd);
                        }
                    }
                } else {
                    const refToAdd = {
                        libelle: selectedRef[0].libelle + ' ' + selectedRef[0].code,
                        normeId: 0,
                        normeLibelle: 'Aucune Norme',
                        referenceId: selectedRef[0].id,
                        [spcKeySearch]: 2,
                        valeur: true
                    };
                    const idxLastRef = this._.findLastIndex(this.listeReferencesByNorme, function (o) {
                        return o.normeId === 0;
                    });
                    this.listeReferencesByNorme.splice(idxLastRef + 1, 0, refToAdd);
                }
            }
            this.selectRefWithoutFilter = false;
        } else {
            this.notification.error('SYNTHENTS.SELECT_REF_ERROR');
        }
    }

    async manageSiteProdSelect() {
        let newSiteProd = false;
        if (!this.oldSiteProdSelected || this.oldSiteProdSelected === null || this.oldSiteProdSelected[0].id !== this.siteProdSelected[0].id) {
            newSiteProd = true;
        }
        let result = null;
        if (this.siteProdIsSelected) {
            if (newSiteProd) {
                this.oldSiteProdSelected = angular.copy(this.siteProdSelected);
                result = await this.ProduitsService.getProduitByCodeFamilleProducteur(this.codeFamille, this.siteProdSelected[0].id);
                this.Products = result;
                this.listeRefsHorsFiltre = await this.searchMatches(
                    this.listeRefsHorsFiltre,
                    this.siteProdSelected[0].id,
                    'sitesProducteursIds',
                    true
                );
                this.listLittleD = this._.filter(
                    _.uniqBy(
                        _.map(result, (r) => {
                            if (r.petitDiametre !== null && typeof r.petitDiametre !== undefined) {
                                const d = {
                                    libelle: r.petitDiametre
                                };
                                return typeof d !== undefined ? d : '';
                            }
                        }),
                        'libelle'
                    ),
                    function (f) {
                        return typeof f !== undefined ? f : '';
                    }
                );
                this.listGreatD = this._.filter(
                    _.uniqBy(
                        _.map(result, (r) => {
                            if (r.grandDiametre !== null && typeof r.grandDiametre !== undefined) {
                                const d = {
                                    libelle: r.grandDiametre
                                };
                                return typeof d !== undefined ? d : '';
                            }
                        }),
                        'libelle'
                    ),
                    function (f) {
                        return typeof f !== undefined ? f : '';
                    }
                );
            } else {
                this.listeRefsHorsFiltre = await this.searchMatches(
                    this.listeRefsHorsFiltre,
                    this.siteProdSelected[0].id,
                    'sitesProducteursIds',
                    true
                );
            }
        } else {
            this.siteProdIsSelected = false;
            await this.isFilteredOn();
            this.Products = null;
        }
    }

    async manageProductSelect() {
            this.listeRefsHorsFiltre = await this.searchMatches(this.listeRefsHorsFiltre, this.ProductSelected[0].id, 'produitsIds', true);
    }

    async manageDSelect(ouverture) {
        let result = [];
        if (ouverture === 'd') {
            let productsFiltred = await this.searchMatches(this.Products, this.littleDSelected[0].libelle, 'petitDiametre');
            this.products = productsFiltred;
            if (productsFiltred !== null) {
                let listIdsProducts = productsFiltred.map(function (e) {
                    return e.id;
                });
                for (let index = 0; index < listIdsProducts.length; index++) {
                    const id = listIdsProducts[index];
                    let resTemp = await this.searchMatches(this.listeRefsHorsFiltre, id, 'produitsIds', true);
                    result.push(resTemp);
                }
                result = _.flatten(result);
                this.listeRefsHorsFiltre = this._.uniq(result);
            } else {
                this.listeRefsHorsFiltre = [];
            }
        } else if (ouverture === 'D') {
            let productsFiltred = await this.searchMatches(this.Products, this.greatDSelected[0].libelle, 'grandDiametre');
            this.products = productsFiltred;
            if (productsFiltred !== null) {
                let listIdsProducts = productsFiltred.map(function (e) {
                    return e.id;
                });
                for (let index = 0; index < listIdsProducts.length; index++) {
                    const id = listIdsProducts[index];
                    let resTemp = await this.searchMatches(this.listeRefsHorsFiltre, id, 'produitsIds', true);
                    result.push(resTemp);
                }
                result = _.flatten(result);
                this.listeRefsHorsFiltre = this._.uniq(result);
            } else {
                this.listeRefsHorsFiltre = [];
            }
        }
    }

    async getCodeFamille() {
        if (this.syntheseEntity && this.syntheseEntity.idFamille) {
            switch (this.syntheseEntity.idFamille) {
                case 6:
                    this.codeFamille = 'BPE';
                    break;
                case 7:
                    this.codeFamille = 'CIM';
                    break;
                case 9:
                    this.codeFamille = 'ENROB';
                    break;
                case 10:
                    this.codeFamille = 'GRAN';
                    break;
                default:
                    break;
            }
        }
    }

    async searchMatches(list, id, propertyName, propNamIsList = false) {
        let result = null;
        if (list && list.length > 0 && id && id != null && propertyName !== '') {
            if (propNamIsList) {
                result = this._.filter(list, function (p) {
                    return _.includes(p[propertyName], id);
                });
            } else {
                result = this._.filter(list, function (p) {
                    return p[propertyName] === id;
                });
            }
        }
        return result;
    }

    async manageSelectNorme() {
        this.listeRefsHorsFiltre = await this.searchMatches(this.listeRefsHorsFiltre, this.selectedNorme[0].id, 'idNorme');
    }

    async isFilteredOn() {
        if (!this.isSelectedNorme && !this.siteProdIsSelected && !this.ProductIsSelected && !this.littleDIsSelected && !this.GreatDIsSelected) {
            this.listeRefsHorsFiltre = await this.getAllRefs();
        }
    }

    async lauchAllFilters(filter) {
        this.allFiltersAreReady = false;
        switch (filter) {
            case 'product':
                if (this.ProductSelected && this.ProductSelected.length > 0) {
                    this.ProductIsSelected = true;
                } else {
                    this.ProductIsSelected = false;
                }
                break;
            case 'productor':
                this.ProductIsSelected = false;
                this.littleDIsSelected = false;
                this.GreatDIsSelected = false;
                if (this.siteProdSelected && this.siteProdSelected.length > 0) {
                    this.siteProdIsSelected = true;
                } else {
                    this.siteProdIsSelected = false;
                }
                break;
            case 'norme':
                if (this.selectedNorme && this.selectedNorme.length > 0) {
                    this.isSelectedNorme = true;
                } else {
                    this.isSelectedNorme = false;
                }
                break;
            case 'd':
                if (this.littleDSelected && this.littleDSelected.length > 0) {
                    this.littleDIsSelected = true;
                } else {
                    this.littleDIsSelected = false;
                }
                break;
            case 'D':
                if (this.greatDSelected && this.greatDSelected.length > 0) {
                    this.GreatDIsSelected = true;
                } else {
                    this.GreatDIsSelected = false;
                }
                break;
            default:
                break;
        }
        this.listeRefsHorsFiltre = await this.getAllRefs();
        this.isSelectedNorme ? await this.selectNorme() : '';
        this.littleDIsSelected ? await this.manageDSelect('d') : '';
        this.GreatDIsSelected ? await this.manageDSelect('D') : '';
        this.ProductIsSelected ? await this.manageProductSelect() : '';
        this.siteProdIsSelected ? await this.manageSiteProdSelect() : '';
        this.allFiltersAreReady = true;
        await this.isFilteredOn();
    }

    async checkReference(reference) {
        if (!this.selectedRef || this.selectedRef.length <= 0) {
            this.selectedRef.push(reference);
        } else {
            if (this.selectedRef[0].id === reference.id) {
                this.selectedRef = [];
            } else {
                this.selectedRef = [];
                this.selectedRef.push(reference);
            }
        }
        this.listeRefsHorsFiltre.map(function (e) {
            e.selected = e.id === reference.id;
        });
    }
}
