import validatorBuilder from 'validator-builder';

let calculStatistiqueValidator = null;

export default class CalculStatistique {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.formule = data.formule;
        this.formule2 = data.formule2;
        this.isFuseau = data.isFuseau || false;
        this.codeExists = data.codeExists || null;
        this.arrondi = data.arrondi || 0;
        this.arrondiDeLEssai = data.arrondiDeLEssai || false;
        this.isControle = data.isControle || false;
        this.isGroupement = data.isGroupement || false;
        this.nbValGroupe = data.nbValGroupe || 0;
        this.isGroupementGlissant = data.isGroupementGlissant || false;
        this.limiterGrandeur = data.limiterGrandeur || false;
        this.isCumul = data.isCumul || false;
        this.nbValMinCumul = data.nbValMinCumul || 0;
        this.isTranspose = data.isTranspose || false;
        this.isLastGroupOnly = data.isLastGroupOnly || false;
        this.decimaleSupp = data.decimaleSupp || 0;
    }

    isValid() {
        calculStatistiqueValidator = calculStatistiqueValidator || validatorBuilder.getInstanceFor('CalculStatistique');
        const validationResults = calculStatistiqueValidator.validate(this);
        return validationResults.isValid;
    }
}
