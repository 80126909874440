MesuresSelectController.$inject = ['$scope', 'MesuresService', 'MassiaApplicationService', '_', '$translate', '$filter'];

export default function MesuresSelectController($scope, MesuresService, MassiaApplicationService, _, $translate, $filter) {
    /* jshint validthis: true */
    const vm = this;
    const watchers = [];

    // Propriétés
    vm.loading = false;
    vm.mesures = [];

    // Méthodes
    vm.selectMesure = selectMesure;
    vm.refreshMesures = refreshMesures;
    vm.filterMesures = filterMesures;
    vm.getLabelSelectMesure = getLabelSelectMesure;

    // Evènements
    vm.$onInit = onInit;
    vm.$onChanges = onChanges;

    // Méthodes publiques
    function onInit() {
        vm.refreshMesures();
        vm.useTable = vm.useTable ?? true;
        vm.excludes = vm.excludes || [];
        vm.clearOnSelect = vm.clearOnSelect || false;
        vm.selected = vm.selected || null;
        vm.disabled = vm.disabled || false;
        vm.domaine = vm.domaine || 'mesures';
        vm.typeMesure = vm.typeMesure || 0;
        vm.typeFiltreTheme = vm.typeFiltreTheme || 0;
        vm.typeProduit = vm.typeProduit || 0;
        watchers.push($scope.$on('typeFiltreTheme', refreshMesures));
        watchers.push($scope.$on('typeMesure', refreshMesures));
        watchers.push($scope.$on('typeProduit', refreshMesures));
        watchers.push($scope.$on('$destroy', dispose));
    }

    /* function selectMesure(item, model) {
        vm.onSelect ? vm.onSelect({mesure: item}) : vm.selected = item;

        if (vm.clearOnSelect) {
            vm.selected = undefined;
        }
    } */

    async function onChanges(objChanges) {
        if (objChanges.typeFiltreTheme || objChanges.typeMesure || objChanges.domaine || objChanges.typeProduit || objChanges.excludes) {
            await vm.refreshMesures();
        }
    }

    async function refreshMesures() {
        let data = [];
        try {
            if (vm.domaine) {
                data = await MesuresService.getMesuresList(vm.domaine, vm.typeMesure, vm.typeFiltreTheme, vm.typeProduit);
            }
        } catch (ex) {}
        vm.mesures = filterMesures(data);
        if (vm.selected && vm.selected.id) {
            vm.selected = _.find(vm.mesures, { essaiId: vm.selected.id });
        }
    }

    function filterMesures(array) {
        for (let i = 0; i < array.length; i++) {
            const e = array[i];
            if (vm.excludes.find((x) => x.essaiId === e.id) || e.isGenerique) {
                array.splice(i, 1);
                i--;
            }
            e.search = `${e.code} ${e.libelle} ${e.symbol}`;
        }
        return array;
    }

    function selectMesure(selected, data) {
        if (vm.onSelect) {
            // vm.onSelect({ mesure: data }); => double la ligne d'essai
            for (let i = 0; i < selected.length; i++) {
                const e = selected[i];
                vm.onSelect ? vm.onSelect({ mesure: e }) : (vm.selected = e);
                if (vm.useTable) {
                    const index = vm.mesures.findIndex((x) => x.id == e.id); //MN: enlever l'essai depuis la liste des esais
                    vm.mesures.splice(index, 1);
                    vm.mesures = vm.mesures.map((x) => {
                        x.selected = false;
                        return x;
                    });
                }
            }
        }
    }

    /* function filterMesures(item) {
        return !_.find(vm.excludes, {essaiId: item.id});
    } */

    function getLabelSelectMesure() {
        switch (vm.domaine) {
            case 'mesctrl':
                return $translate.instant('MESURES.SELECTIONNER_CONTROLE');
            case 'essais':
                return $translate.instant('MESURES.SELECTIONNER_ESSAI');
        }
        return $translate.instant('MESURES.SELECTIONNER');
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
