FactureArticleValidator.$inject = ['validator'];

export default function FactureArticleValidator(validator) {
    const instance = new validator();
    instance.ruleFor('quantite').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('typePrix').must(notEmpty).withMessage('VALIDATION_NOTEMPTY');

    // instance.ruleFor('ht').greaterThanOrEqual(0).withMessage('VALIDATION_GRATHER_THAN');

    function notEmpty(obj) {
        return obj.typePrix !== '' && obj.typePrix !== null && typeof obj.typePrix !== 'undefined';
    }
    return instance;
}
