import ProduitFormProducteursValeurReferenceCubeModal from './produit.form.producteurs.eqiom.mise.au.cube.modal.controller';

export default {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: require('./produit.form.producteurs.eqiom.mise.au.cube.modal.html'),
    controller: ProduitFormProducteursValeurReferenceCubeModal
};
