HistoriqueController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'HistoriqueService',
    'notification'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function HistoriqueController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    HistoriqueService,
    notification
) {
    const vm = this;

    let previousTableState;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.historique = [];

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.loadHistorique = loadHistorique;

    function init() {
        getEvenements();
    }

    async function getEvenements() {
        vm.evenements = await HistoriqueService.getEvenements();
    }

    init();

    async function loadHistorique(tableState) {
        startLoading();

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.historique = [];

        try {
            const data = await HistoriqueService.getHistorique(filters, sorts, pagination);

            vm.historique = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {}
}
