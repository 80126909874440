import SiteSpecificiteController from './site.specificite.controller';

export default {
    bindings: {
        site: '=',
        isOpen: '=',
        onUpdate: '&'
    },
    template: require('./site.specificite.html'),
    controller: SiteSpecificiteController
};
