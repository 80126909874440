import GestionStocksProductionTraiteeController from './gestion.stocks.production.traitee.controller';

export default {
    bindings: {
        ligne: '=',
        modalInstance: '='
    },
    template: require('./gestion.stocks.production.traitee.html'),
    controller: GestionStocksProductionTraiteeController
};
