import { isNumber } from 'angular';
import MassiaApplicationService from '../../app/massia/layout/components/massia-application/massia.application.service';
import { SeuilBetGachTolDetail } from '../models/seuil-bet-gach-tol/SeuilBetGachTolDetail';
import { Type } from '../models/type/Type';

SeuilBetGachTolValidator.$inject = ['validator', 'MassiaApplicationService'];
export function SeuilBetGachTolValidator(validator: any, MassiaApplicationService: MassiaApplicationService) {
    const instance = new validator();

    instance.ruleFor('pourcent').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('gachee').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('unite').must(uniteNotEmpty).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('betConstituant').must(beSetted).withMessage('VALIDATION_NOTEMPTY');

    function beSetted(obj: SeuilBetGachTolDetail, element: Type) {
        if (element?.id) return element.id > 0;
        else return false;
    }

    function uniteNotEmpty(obj: SeuilBetGachTolDetail, element: any) {
        if (element !== undefined && element !== null && isNumber(element)) return true;
        else return false;
    }

    return instance;
}
