import NatureInputTypeMapping, { natureEnum } from './form-dynamique/natures.input.mapping.constant';
import DynamicFormComponent from './form-dynamique';
import AccordeonDirectives from './accordeon';
import popoverDirectives from './popover';
import inputDirectives from './input';

const moduleName = 'app.massia.directives';

angular
    .module(moduleName, [])
    .run([
        '$templateCache',
        ($templateCache) => {
            $templateCache.put('form-dynamic/field-template.bool.html', require('./form-dynamique/templates/field-template.bool.html'));
            $templateCache.put('form-dynamic/field-template.date.html', require('./form-dynamique/templates/field-template.date.html'));
            $templateCache.put('form-dynamic/field-template.excel.html', require('./form-dynamique/templates/field-template.excel.html'));
            $templateCache.put('form-dynamic/field-template.granulo.html', require('./form-dynamique/templates/field-template.granulo.html'));
            $templateCache.put('form-dynamic/field-template.heure.html', require('./form-dynamique/templates/field-template.heure.html'));
            $templateCache.put('form-dynamic/field-template.multidate.html', require('./form-dynamique/templates/field-template.multidate.html'));
            $templateCache.put(
                'form-dynamic/field-template.multikeyvalue.html',
                require('./form-dynamique/templates/field-template.multikeyvalue.html')
            );
            $templateCache.put('form-dynamic/field-template.multinumber.html', require('./form-dynamique/templates/field-template.multinumber.html'));
            $templateCache.put('form-dynamic/field-template.multiselect.html', require('./form-dynamique/templates/field-template.multiselect.html'));
            $templateCache.put('form-dynamic/field-template.multitext.html', require('./form-dynamique/templates/field-template.multitext.html'));
            $templateCache.put('form-dynamic/field-template.number.html', require('./form-dynamique/templates/field-template.number.html'));
            $templateCache.put('form-dynamic/field-template.select.html', require('./form-dynamique/templates/field-template.select.html'));
            $templateCache.put('form-dynamic/field-template.text.html', require('./form-dynamique/templates/field-template.text.html'));
            $templateCache.put('form-dynamic/field-template.textarea.html', require('./form-dynamique/templates/field-template.textarea.html'));
            $templateCache.put('form-dynamic/readonly.tpl.html', require('./form-dynamique/templates/readonly.tpl.html'));
        }
    ])
    .constant('NatureInputTypeMapping', NatureInputTypeMapping)
    .constant('NatureEnum', natureEnum)
    .directive('accordeonOpen', AccordeonDirectives.open)
    .directive('accordeonClose', AccordeonDirectives.close)
    .directive('accordeonToggle', AccordeonDirectives.toggle)
    .directive('popoverClose', popoverDirectives.close)
    .directive('inputNbdecimal', inputDirectives.nbdecimal)
    .component('formDynamique', DynamicFormComponent);

export default moduleName;
