// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
/* @ngInject */
export default function FacturesPontController(
	$http,
	$window,
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	FacturesService,
	notification,
	_,
	$uibModal,
	DataShareService,
	moment,
	globalizationManagementService,
	MOPService
) {
	const vm = this;

	let previousTableState;

	vm.itemsByPageOptions = [20, 100, 1000, 1500, 2000, 2500, 3000];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	$scope.caseCochee = false;
	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm._ = _;
	vm.DataShareService = DataShareService;
	vm.factures = [];
	vm.moment = moment;
	this.notification = notification;
	// initialisation de l'onglet recherche
	vm.isSearchOngletOpen = true;
	vm.searchingFormFactures = {};
	vm.searchingFormFactures.entity = 'facturePont';
	vm.societesComLoaded = [];
	vm.searchingFormFactures.societeComIdSelected = null;

	vm.searchingFormFactures.selectedSitesCommerciaux = [];
	vm.searchingFormFactures.selectedTypesClient = [];
	vm.searchingFormFactures.selectedClients = [];

	vm.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	vm.MOPService = MOPService;

	// initialise les dates de debut et fin au premier et dernier jour du mois en cours
	const maDate = new Date();
	const y = maDate.getFullYear();
	const m = maDate.getMonth();
	const firstDay = new Date(y, m, 1);
	const lastDay = new Date(y, m + 1, 0);
	vm.searchingFormFactures.dateDebut = vm.moment(firstDay).format(vm.dateFormat);
	vm.searchingFormFactures.dateFin = vm.moment(lastDay).format(vm.dateFormat);

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right'
	};

	vm.loadFactures = loadFactures;
	vm.checkAllFactures = checkAllFactures;
	vm.$uibModal = $uibModal;
	vm.$translate = $translate;

	vm.reset = reset;
	vm.exporter = exporter;
	vm.loadIStevensInput = loadIStevensInput;
	vm.saveSelectedSitesCommerciaux = saveSelectedSitesCommerciaux;
	vm.saveSelectedTypesClient = saveSelectedTypesClient;
	vm.saveSelectedClients = saveSelectedClients;
	vm.genererPont = genererPont;
	vm.genererJournal = genererJournal;
	vm.passerEnComptabilise = passerEnComptabilise;
	vm.isButtonEnabled = false;
	vm.isFactureChecked = isFactureChecked;
	// vm.debug = debug;

	async function init() {
		vm.MOPService.setMop([{ title: 'FACTURES.BREADCRUMBS.FACTURES_PONT', filename: 'PontComptable.pdf', application: 'gestion' }]);
		// s'il y a quelque chose dans le DataShare
		if (_.isEmpty(vm.DataShareService.savedData) == false) {
			// on cherche l'entité facture
			const item = _.find(vm.DataShareService.savedData, { entity: 'facturePont' });
			if (item != null) {
				await updateSearchForm();
			}
		}

		await getSocietesCom();
		if (vm.searchingFormFactures.societeComIdSelected) {
			await loadIStevensInput();
		}
	}

	init();

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	// recuperation des donnees du DataShare
	async function updateSearchForm() {
		vm.searchingFormFactures = vm.DataShareService.getDataShare('facturePont');
	}

	async function getSocietesCom() {
		vm.societesComLoaded = await FacturesService.getSocietesCom();
		// SL Mantis 387 RG 1
		if (vm.societesComLoaded.length == 1) {
			vm.searchingFormFactures.societeComIdSelected = vm.societesComLoaded[0].id;
		}
	}

	// méthode appelée si on change la valeur de la société
	async function loadIStevensInput() {
		await getSitesCom();
		await getTypesClient();
		await getClients();
	}

	async function getSitesCom() {
		vm.sitesCommerciauxLoaded = await FacturesService.getSitesCom(vm.searchingFormFactures.societeComIdSelected);
	}

	async function getTypesClient() {
		vm.typesClientLoaded = await FacturesService.getTypesClient('SiteClient');
	}

	async function getClients() {
		vm.clientsLoaded = await FacturesService.getClients(
			vm.searchingFormFactures.societeComIdSelected,
			vm.searchingFormFactures.selectedTypesClient
		);
	}

	// méthode appelée quand on clique sur la loupe (recherche)
	async function loadFactures(tableState) {
		startLoading();
		vm.DataShareService.setDataShare(vm.searchingFormFactures, 'facturePont');
		if ($state) {
			if (tableState) {
				previousTableState = tableState;
			}

			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const pagination = PaginationService.getPagination(previousTableState);

			vm.factures = [];
			if (vm.searchingFormFactures.societeComIdSelected) {
				try {
					vm.isGenererActif = vm.searchingFormFactures.isComptabilise ? true : false;
					const data = await FacturesService.getFacturesForPont(filters, sorts, pagination, vm.searchingFormFactures);

					vm.factures = data.items;

					if (previousTableState) {
						PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
					}
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			} else {
				stopLoading();
			}
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function checkAllFactures(value) {
		vm.factures.forEach(function (element) {
			element.isChecked = value;
		}, this);
		isFactureChecked();
	}

	function isFactureChecked() {
		if (vm.factures.find((x) => x.isChecked)) {
			vm.isButtonEnabled = true;
		} else {
			vm.isButtonEnabled = false;
		}
	}

	function reset() {
		vm.searchingFormFactures = {};
		vm.searchingFormFactures.societeComIdSelected = null;
		resetMultiSelect();

		// initialise les dates de debut et fin au premier et dernier jour du mois en cours
		const maDate = new Date();
		const y = maDate.getFullYear();
		const m = maDate.getMonth();
		const firstDay = new Date(y, m, 1);
		const lastDay = new Date(y, m + 1, 0);
		vm.searchingFormFactures.dateDebut = vm.moment(firstDay).format(vm.dateFormat);
		vm.searchingFormFactures.dateFin = vm.moment(lastDay).format(vm.dateFormat);
		vm.factures = [];
		getSocietesCom();
	}

	function resetMultiSelect() {
		vm.searchingFormFactures.selectedSitesCommerciaux = [];
		for (let index = 0; index < vm.sitesCommerciauxLoaded.length; index++) {
			const element = vm.sitesCommerciauxLoaded[index];
			element.selected = false;
		}

		vm.searchingFormFactures.selectedTypesClient = [];
		for (let index = 0; index < vm.typesClientLoaded.length; index++) {
			const element = vm.typesClientLoaded[index];
			element.selected = false;
		}

		vm.searchingFormFactures.selectedClients = [];
		for (let index = 0; index < vm.clientsLoaded.length; index++) {
			const element = vm.clientsLoaded[index];
			element.selected = false;
		}
	}

	function saveSelectedSitesCommerciaux() {
		vm.searchingFormFactures.selectedSitesCommerciaux = [];
		for (let i = 0; i < this.selectedSitesCommerciaux.length; i++) {
			const siteCom = this.selectedSitesCommerciaux[i];
			vm.searchingFormFactures.selectedSitesCommerciaux.push(siteCom.id);
		}
	}

	function saveSelectedClients() {
		vm.searchingFormFactures.selectedClients = [];
		for (let i = 0; i < this.selectedClients.length; i++) {
			const client = this.selectedClients[i];
			vm.searchingFormFactures.selectedClients.push(client.id);
		}
	}

	async function saveSelectedTypesClient() {
		vm.searchingFormFactures.selectedTypesClient = [];
		for (let i = 0; i < this.selectedTypesClient.length; i++) {
			const typeClient = this.selectedTypesClient[i];
			vm.searchingFormFactures.selectedTypesClient.push(typeClient.id);
		}
		await getClients();
	}

	async function genererJournal() {
		const listFactureIdsToUse = [];
		vm.factures.forEach(function (element) {
			if (element.isChecked == true) {
				listFactureIdsToUse.push(element.id);
			}
		}, this);
		if (listFactureIdsToUse.length > 0) {
			startLoading();
			const fileName = Date.now();
			const resultat = await FacturesService.genereJournal(listFactureIdsToUse);

			const data = resultat.data;
			const status = resultat.status;
			let headers = resultat.headers;

			headers = headers();

			const contentType = headers['content-type'];

			const linkElement = document.createElement('a');
			try {
				const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
				const url = window.URL.createObjectURL(blob);
				linkElement.setAttribute('href', url);
				linkElement.setAttribute('download', fileName + '.xlsx');

				const clickEvent = new MouseEvent('click', {
					view: window,
					bubbles: true,
					cancelable: false
				});
				linkElement.dispatchEvent(clickEvent);
			} catch (ex) {
			} finally {
				stopLoading();
			}
		}
	}

	async function genererPont(isSimulation) {
		let resultat = undefined;
		const listFactureIdsToUse = [];
		try {
			startLoading();
			if (isSimulation) {
				this.isLoadSimulation = true;
			} else {
				this.isLoadGeneration = true;
			}
			vm.factures.forEach(function (element) {
				if (element.isChecked == true) {
					listFactureIdsToUse.push(element.id);
				}
			}, this);

			if (listFactureIdsToUse.length > 0) {
				const fileName = 'PONT';
				try {
					//Si ce n'est pas une simulation, on alerte du caractère irreversible de l'action (facture état 'comptabilisé')
					if (!isSimulation) {
						const modalInstance = ModalService.confirm({
							modalTitle: $translate.instant('FACTURES.COMPTACHECKED.TITLE'),
							modalMsg: $translate.instant('FACTURES.COMPTACHECKED.MESSAGE'),
							headerClass: 'modal-danger'
						});
						//Si l'utilisateur est ok, alors on lance le traitement avec passage en état 'comptabilisé'
						await modalInstance.result.then(async function () {
							resultat = await FacturesService.generePontComptable(listFactureIdsToUse, isSimulation);
						});
					} else {
						resultat = await FacturesService.generePontComptable(listFactureIdsToUse, isSimulation);
					}
					if (resultat) {
						const extension = __configuration.licenceGest == 28 ? '.tra' : '.txt';
						const data = resultat.data;
						const status = resultat.status;
						let headers = resultat.headers;
						headers = headers();
						const contentType = headers['content-type'];
						if (contentType != null && status != 204) {
							// Il est possible que le service ne renvoi pas de fichier sur le navigateur du client, car un mode permet de l'enregistrer directement sur le serveur
							const linkElement = document.createElement('a');
							try {
								const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
								const url = window.URL.createObjectURL(blob);
								linkElement.setAttribute('href', url);
								linkElement.setAttribute('download', fileName + extension);
								const clickEvent = new MouseEvent('click', {
									view: window,
									bubbles: true,
									cancelable: false
								});
								linkElement.dispatchEvent(clickEvent);
							} catch (ex) {
							} finally {
							}
						}
						if (status == 200 || status == 206) {
							this.notification.success('FACTURES.FILE_COMPTA_UPLOADED', '', 1000);
							if (!isSimulation && status == 200) {
								this.notification.success('FACTURES.FILE_COMPTA_GENERATED', '', 1000);
							} else if (!isSimulation && status == 206) {
								this.notification.warning('FACTURES.FILE_COMPTA_ERROR', '', 1000);
							}
						} else if (status == 204) {
							this.notification.success('FACTURES.FILE_COMPTA_GENERATED', '', 1000);
						}

						//Si des factures ont été comptabilisées, alors il faut recharger la page
						if (!isSimulation) {
							await loadFactures();
						}
						return true;
					}
				} catch (ex) {
					//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
					// const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
					notification.error(ex.data);
					return false;
				}
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		} finally {
			stopLoading();
			this.isLoadGeneration = false;
			this.isLoadSimulation = false;
			if (listFactureIdsToUse.length > 0) {
				getInfoResultat(listFactureIdsToUse, isSimulation);
			}
		}
	}

	async function getInfoResultat(listFactureIdsToUse, isSimulation) {
		const resultat = await FacturesService.getInfoResultat(listFactureIdsToUse, isSimulation);
		let messagePont = '';
		resultat.quantites.forEach(function (element) {
			messagePont += $translate.instant('FACTURES.INFO_PONT.MESSAGE_QUANTITE', { unite: element.unite, valeur: element.valeur }) + '<br />';
		}, this);

		const modalInstance = ModalService.info({
			modalTitle: $translate.instant('FACTURES.INFO_PONT.TITLE'),
			modalMsg: messagePont + $translate.instant('FACTURES.INFO_PONT.MESSAGE_MONTANT', { montant: resultat.montantHt }),
			headerClass: 'modal-info'
		});
	}

	async function exporter(modeImpression) {
		console.log(vm.searchingFormFactures);
		if (vm.searchingFormFactures.societeComIdSelected) {
			try {
				const filters = PaginationService.getFilters(previousTableState);
				const sorts = PaginationService.getSorts(previousTableState);
				const fileName = 'Liste_factures';
				let resultat = undefined;
				try {
					resultat = await FacturesService.printFacturesForPont(filters, sorts, vm.searchingFormFactures);
				} catch (ex) {
					//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
					const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
					notification.error('FACTURES.' + msgException);
					return false;
				}
				if (resultat) {
					const data = resultat.data;
					const status = resultat.status;
					let headers = resultat.headers;
					headers = headers();

					const contentType = headers['content-type'];

					const linkElement = document.createElement('a');
					try {
						const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
						const url = window.URL.createObjectURL(blob);
						linkElement.setAttribute('href', url);
						if (modeImpression == 0) {
							linkElement.setAttribute('download', fileName + '.pdf');
						} else {
							linkElement.setAttribute('download', fileName + '.xlsx');
						}

						const clickEvent = new MouseEvent('click', {
							view: window,
							bubbles: true,
							cancelable: false
						});
						linkElement.dispatchEvent(clickEvent);
					} catch (ex) {
					} finally {
					}
					return true;
				}
			} catch (ex) {
				this.notification.error(ex.data);
				return false;
			}
		}
	}

	// function debug() {
	//     console.log(this);
	// }

	async function passerEnComptabilise() {
		const listFactureIdsToUse = [];

		startLoading();
		vm.factures.forEach(function (element) {
			if (element.isChecked === true) {
				listFactureIdsToUse.push(element.id);
			}
		}, this);

		try {
			if (listFactureIdsToUse.length > 0) {
				//on alerte du caractère irreversible de l'action (facture état 'comptabilisé')
				const modalInstance = ModalService.confirm({
					modalTitle: $translate.instant('FACTURES.PASSER_COMPTAB_CHECKED.TITLE'),
					modalMsg: $translate.instant('FACTURES.PASSER_COMPTAB_CHECKED.MESSAGE'),
					headerClass: 'modal-danger'
				});
				//Si l'utilisateur est ok, alors on lance le passage en état 'comptabilisé'
				await modalInstance.result.then(async () => {
					const resultat = await FacturesService.passerEnComptabilise(listFactureIdsToUse);
					if (resultat) {
						this.notification.success('FACTURES.PASSER_COMPTAB_OK');
					}
				});
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			stopLoading();
			if (listFactureIdsToUse.length > 0) {
				getInfoResultat(listFactureIdsToUse, isSimulation);
			}
		}
	}
}
