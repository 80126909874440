import FormuleComposant from './formule.composant.model';

export default class FormuleCreationController {
	static $inject = [
		'$scope',
		'_',
		'$state',
		'$stateParams',
		'FormulesCommunicationService',
		'FormuleService',
		'MassiaApplicationService',
		'$filter',
		'$uibModal',
		'moment'
	];

	constructor($scope, _, $state, $stateParams, FormulesCommunicationService, FormuleService, MassiaApplicationService, $filter, $uibModal, moment) {
		this.$scope = $scope;
		this.$filter = $filter;
		this._ = _;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.FormulesCommunicationService = FormulesCommunicationService;
		this.FormuleService = FormuleService;
		this.MassiaService = MassiaApplicationService;
		this.$uibModal = $uibModal;
		this.moment = moment;
	}

	async $onInit() {
		/* var _this = this;
        this.$scope.$watch('$ctrl.formule.datePrixCession', function (newValue, oldValue) {
            _this.datePrixCessionChange();
        }); */

		this.loading = true;
		this.isSqueletteGranulaire = false;
		this.lastOrdre = 0;
		this.maxOrdre = 0;
		this.app = this.MassiaService.getApplication();
		this.tmpQuantite = 0;
		//this.loadNormes();
		this.normeIsSelected = false;

		this.formules = this.formules || {};
		if (typeof this.formule == 'undefined' || typeof this.formules.liste == 'undefined' || this.formules.liste.length == 0) {
			const idProduitSvg = this.formule.idProduit;
			const idProducteurSvg = this.formule.idProducteur;
			this.formule = {};
			this.formule.pourcentageTotal = 0;
			this.formule.prixCessionTotal = 0;
			this.formule.idProduit = idProduitSvg;
			this.formule.idProducteur = idProducteurSvg;
			this.formule.calculAuto = false;
			this.directSave = false;
			this.stopLoading();
		} else {
			const liste = angular.copy(this.formules.liste);
			const formulaList = this.$filter('orderBy')(liste, 'idComposition', true);
			this.tmpFormule = angular.copy(formulaList[0]);
			// this.tmpIdCompo = angular.copy(formulaList[0].idComposition)
			// this.tmpIdProducteur = angular.copy(formulaList[0].idProducteur);
			// this.tmpIdProduit = angular.copy(formulaList[0].idProduit);
			await this.getCurrentFormula(formulaList[0].idComposition);
			this.directSave = true;
		}
		// on sauvegarde la fonction de désabonnement pour l'invoquer au destroy
		// il est important de noter la façon de s'abonner :
		// on utilise les arrow functions, () => {},
		// elles permettent de sauvegarder l'état du 'this'
		// et d'éviter la perte du contexte lors de l'appel de la fonction depuis un autre controller
		this.unregisterReinit = this.FormulesCommunicationService.registerReinit(() => this.initForm());
	}

	$onDestroy() {
		//this.unregisterComposantWatcher();
	}

	sortableOptions() {
		this.dragOptions = {
			placeholder: 'table-row-ui-placeholder',
			revert: true,
			animation: 200,
			axis: 'y',
			'ui-preserve-size': true
		};
	}

	async getCurrentFormula(id) {
		try {
			this.startLoading();
			this.formule = await this.FormuleService.getFormuleById(id, this.formule.idProducteur);
			this.formule.datePrixCession = this.moment().format('DD/MM/YYYY');
			if (this.tmpFormule) {
				this.formule.commentaire = this.tmpFormule.commentaire;
				this.formule.idComposition = this.tmpFormule.idComposition;
				this.formule.idProducteur = this.tmpFormule.idProducteur;
				this.formule.idProduit = this.tmpFormule.idProduit;
			}
			this.formule.calculAuto = true;
			const listeComposants = [];
			if (this.formule.squelette && this.formule.squelette.composants) {
				this.formule.composants.concat(
					this.formule.squelette.composants.map((e) => {
						e.isInSquelette = true;
						return e;
					})
				);
			}
			for (let i = 0; i < this.formule.composants.length; i++) {
				let composant = this.formule.composants[i];
				if (!composant.isInSquelette) {
					this.tmpQuantite += composant.quantite;
				}
				if (this.app === 'gestion') {
					await this.rechercheDesPrix(composant);
				}
				composant = new FormuleComposant(composant);

				listeComposants.push(composant);
			}
			this.lastOrdre = angular.copy(this.maxOrdre);
			this.formule.composants = this.$filter('orderBy')(listeComposants, 'ordre');
			this.oldDateCreation = angular.copy(this.formule.dateCreation);
			this.stopLoading();
		} catch (err) {
			this.stopLoading();
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}

	async rechercheDesPrix() {
		for (let i = 0; i < this.formule.composants.length; i++) {
			const composant = this.formule.composants[i];
			var prixAchat;
			let stock = null;
			if (composant.idProducteur) {
				prixAchat = await this.FormuleService.getPrixAchatProduit(
					this.formule.idProducteur,
					composant.idElement,
					composant.idProducteur,
					this.formule.datePrixCession
				);
				stock = await this.FormuleService.getPourcentagePerte(
					this.formule.idProducteur,
					composant.idElement,
					composant.idProducteur,
					this.formule.datePrixCession
				);
			} else {
				prixAchat = await this.FormuleService.getPrixAchatPrestation(
					this.formule.idProducteur,
					composant.idElement,
					this.formule.datePrixCession
				);
			}

			composant.coutIncorporation = prixAchat.prix;
			if (prixAchat.ristourne) {
				this.formule.composants[i].coutIncorporation = composant.coutIncorporation + prixAchat.ristourne;
			}
			this.formule.composants[i].pourcentagePerte = stock;
			if (composant.idProducteur) {
				this.formule.composants[i].prixCession =
					((composant.coutIncorporation * composant.quantite) / 100) * (1 + composant.pourcentagePerte / 100);
			} else {
				this.formule.composants[i].prixCession = composant.coutIncorporation;
			}
		}
	}

	duplicateFrom() {
		const _this = this;
		//_this.source = this.produit;
		const modaleDuplication = this.$uibModal.open({
			template: '<duplicate source="$ctrl.source" modal-instance="$ctrl.uibModalInstance"></duplicate>',
			controller: [
				'$uibModalInstance',
				function ($uibModalInstance) {
					const $ctrl = this;
					$ctrl.source = _this.formule;
					$ctrl.uibModalInstance = $uibModalInstance;
				}
			],
			controllerAs: '$ctrl',
			size: 'm'
		});
		modaleDuplication.result.then(
			function (result) {
				_this.getCurrentFormula(result.selected);
			},
			function (reason) {}
		);
	}

	onSelectCoefK(compo, $scope) {
		for (let i = 0; i < this.formule.composants.length; i++) {
			const e = this.formule.composants[i];
			if (e.idElement !== compo.idElement && compo.codeFamille === 'ADD') {
				e.selectCoef = false;
			}
		}
		$scope.$broadcast('initCalcul', compo);
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}
}
