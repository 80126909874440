import ProduitFormProducteursCertificationController from './produit.form.producteurs.certification.controller';

export default {
    bindings: {
        certifications: '=',
        producteur: '=',
        produit: '='
    },
    template: require('./produit.form.producteurs.certification.html'),
    controller: ProduitFormProducteursCertificationController
};
