export default function AffectationRoute($stateProvider) {
    $stateProvider
        .state('portail.affectation', {
            url: '/affectation',
            abstract: true,
            template: '<ui-view></ui-view>',
            ncyBreadcrumb: {
                skip: true
                //label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}',
            }
        })
        .state('portail.affectation.list', {
            url: '',
            rights: { domain: 'portail', right: 'read' },
            template: '<portail-affectation-list></portail-affectation-list>',
            ncyBreadcrumb: {
                skip: true
                //label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}',
            }
            //? search: 'PORTAIL.BREADCRUMBS.USERS',
        })
        .state('portail.affectation.add', {
            url: '/add',
            rights: { domain: 'portail', right: 'create' },
            template: '<portail-affectation-form></portail-affectation-form>',
            ncyBreadcrumb: {
                skip: true,
                label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}'
            }
            //? search: 'PORTAIL.BREADCRUMBS.USERS_NEW',
        })
        .state('portail.affectation.edit', {
            url: '/:id/edit',
            rights: { domain: 'portail', right: 'update' },
            template: '<portail-affectation-form></portail-affectation-form>',
            params: {
                obj: null
            },
            ncyBreadcrumb: {
                skip: true,
                label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}'
            }
            //? search: 'PORTAIL.BREADCRUMBS.USERS_NEW',
        });
}

AffectationRoute.$inject = ['$stateProvider'];
