import * as _ from 'lodash';

import Grille from './grilles.model';
import Prix from './prix.model';

export default class TarifController {
	/* @ngInject */

	constructor(
		$scope,
		$state,
		$stateParams,
		SocietesService,
		TarifsService,
		SocietesTypesService,
		ModalService,
		notification,
		$translate,
		moment,
		$filter,
		_,
		globalizationManagementService,
		PaginationService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.SocietesService = SocietesService;
		this.TarifsService = TarifsService;
		this.SocietesTypesService = SocietesTypesService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.$translate = $translate;
		this.moment = moment;
		this.$filter = $filter;
		this._ = _;
		this.PaginationService = PaginationService;
		this.sitesCommerciaux = [];
		this.grilles = [];
		this.tvaListe = [];
		this.showNewGrilleForm = false;
		this.showDuplicateCodeGrilleForm = false;
		this.newGrille = new Grille();
		this.selectedGrille = null;
		this.grilleModele = null;
		this.displayedGrille = null;
		this.formulaireTitre = 'Ajouter une nouvelle grille';
		this.prixTypes = null;
		this.grillesParentes = [];

		this.transporteurs = [];
		this.originalGrilleCode = null;
		this.dateSimulationAchat = this.moment().format(this.dateFormat);
		this.isLoading = false;
		this.dateTest = this.moment().format(this.dateFormat);

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
		this.displayDateMask = globalizationManagementService.getCurrentLanguage().displayDateMask;

		this.itemsByPageOptions = [50, 200, 1000];
		this.itemsByPage = this.itemsByPageOptions[0];
		this.initialVal = {};

		this.testOpen = false;
	}

	async $onInit() {
		if (this.idGrille) {
			const grille = {
				id: this.idGrille
			};
			this.displayGrille(grille);
		} else {
			this.selectedSens = 'true';
			if (this.domaine == 'societe' || this.domaine == 'siteCommercial') {
				this.getGrilles();
			} else {
				this.societes = await this.TarifsService.getSocietes();
				this.selectedSocieteId = this.societes[0].id;
				await this.loadSitesCommerciaux(this.selectedSocieteId);
			}

			this.getTypesPrix();
			this.tvaListe = await this.TarifsService.getTvaListe();
			this.getGrilles();
			this.getTransporteurs();
			this.htmlPopover = '<input type="text" class="form-control" placeholder="Text input">';
			this.initNewGrille();

			const _this = this;
			this.$scope.$watch('$ctrl.newGrille.dateDebut', function (newValue, oldValue) {
				_this.getGrillesParentes();
			});
			this.$scope.$watch('$ctrl.newGrille.dateFin', function (newValue, oldValue) {
				_this.getGrillesParentes();
			});

			this.isTransportOpen = false;
		}
	}

	$onDestroy() {
		this.unregister();
	}

	initNewGrille() {
		this.formulaireTitre = 'Ajouter une nouvelle grille';
		this.newGrille = new Grille();
		this.newGrille.societeId = null;
		this.newGrille.siteCommercialId = null;
		this.newGrille.typeClientId = null;
		this.newGrille.clientId = null;
		this.newGrille.chantierId = null;

		switch (this.domaine) {
			case 'societe':
				this.newGrille.societeId = this.source.id;
				break;
			case 'siteCommercial':
				this.newGrille.siteCommercialId = this.source.id;
				break;
			case 'typeClient':
				this.newGrille.typeClientId = this.source.id;
				break;
			case 'client':
				this.newGrille.clientId = this.source.id;
				break;
			case 'chantier':
			case 'provenance':
				this.newGrille.chantierId = this.source.id;
				break;
		}
	}

	changePrixInitial() {
		if (this.newGrille.isPrixInitial == true) {
			this.newGrille.isPrixAugmente = false;
		}
	}

	changePrixAugmente() {
		if (this.newGrille.isPrixAugmente == true) {
			this.newGrille.isPrixInitial = false;
		}
	}

	ajouterNewGrille() {
		this.showNewGrilleForm = !this.showNewGrilleForm;
		this.initNewGrille();
	}

	dispose() {
		watchers.forEach((x) => x());
	}

	async loadSitesCommerciaux(societeId) {
		this.sitesCommerciaux = await this.TarifsService.getSitesCommerciauxBySociete(this.selectedSocieteId);
	}

	async getGrillesParentes() {
		let urlDomainName = 'societe';
		let urlDomainId = this.selectedSocieteId;
		let filters = {};
		if (this.selectedSens != 'stock') {
			if (this.selectedSiteCommercialId) {
				urlDomainName = 'siteCommercial';
				urlDomainId = this.selectedSiteCommercialId;
			}

			switch (this.domaine) {
				case 'societe':
					urlDomainName = 'societe';
					urlDomainId = this.source.id;
					break;
				case 'siteCommercial':
					urlDomainName = 'siteCommercial';
					urlDomainId = this.source.id;
					break;
				case 'typeClient':
					filters = {
						typeClientId: this.source.id
					};
					break;
				case 'client':
					filters = {
						clientId: this.source.id
					};
					break;
				case 'chantier':
				case 'provenance':
					filters = {
						chantierId: this.source.id
					};
					break;
			}

			filters.achatVente = this.selectedSens;

			if (this.selectedTransporteur != null) {
				filters.transporteurId = this.selectedTransporteur;
			}

			if (urlDomainName == 'societe') {
				filters.societeId = urlDomainId;
			} else {
				filters.siteCommercialId = urlDomainId;
			}

			filters.dateDebut = this.newGrille.dateDebut;
			filters.dateFin = this.newGrille.dateFin;

			this.grillesParentes = await this.TarifsService.getGrillesParentes(filters);
			this.grillesParentes.push({ id: null, libelle: '' });
		}
	}

	async changeSens() {
		if (this.selectedSens == 'stock') {
			this.getGrillesStock();
		} else {
			this.getGrilles();
		}
	}

	async changeTransporteur() {
		this.getGrilles();
	}

	async changeSociete() {
		await this.loadSitesCommerciaux(this.selectedSocieteId);
		this.sitesCommerciaux.unshift({ id: null, libelle: '' });
		this.getGrilles();
	}

	async changeSiteCommercial() {
		this.getGrilles();
	}

	cancel() {
		this.modalInstance.dismiss();
	}

	async getTransporteurs() {
		try {
			const data = await this.TarifsService.getTransporteurs();
			this.transporteurs = data;
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
		this.transporteurs.unshift({ id: null, libelle: '' });
	}

	async getGrillesStock() {
		const urlDomainId = this.source.id;
		const data = await this.TarifsService.getGrillesStock(urlDomainId);
		this.grilles = data;
	}

	async getGrilles() {
		let urlDomainName = 'societe';
		let urlDomainId = this.selectedSocieteId;
		let filters = {};

		if (this.selectedSiteCommercialId) {
			urlDomainName = 'siteCommercial';
			urlDomainId = this.selectedSiteCommercialId;
		}

		switch (this.domaine) {
			case 'societe':
				urlDomainName = 'societe';
				urlDomainId = this.source.id;
				break;
			case 'siteCommercial':
				urlDomainName = 'siteCommercial';
				urlDomainId = this.source.id;
				break;
			case 'typeClient':
				filters = {
					typeClientId: this.source.id
				};
				break;
			case 'client':
				filters = {
					clientId: this.source.id
				};
				break;
			case 'chantier':
			case 'provenance':
				filters = {
					chantierId: this.source.id
				};
				break;
		}

		filters.achatVente = this.selectedSens;

		if (this.selectedTransporteur != null) {
			filters.transporteurId = this.selectedTransporteur;
		}

		try {
			if (this.selectedSens == 'stock') {
				this.grilles = await this.TarifsService.getGrillesStock(urlDomainId);
			} else {
				this.grilles = await this.TarifsService.getGrilles(urlDomainName, urlDomainId, filters);
			}
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}

		if (this.selectedSens != 'stock') {
			this.getGrillesParentes(urlDomainName, urlDomainId, filters);
		}

		this.selectedGrille = null;
	}

	async updatePrix(produit) {
		const old = produit.valeur;
		const valeurNumbered = this.convertStringToDecimal(produit.valeur);

		if (produit.valeur == '') {
			produit.valeur = 0;
			if (produit.prixCessionArrondi !== null) {
				produit.prix = produit.prixCessionArrondi;
			}
		}

		let prixDeReference = 0;

		if (produit.prixStandard != null && produit.prixStandard.prix > 0) {
			prixDeReference = produit.prixStandard.prix;
		} else if (produit.prixCessionArrondi != null) {
			prixDeReference = produit.prixCessionArrondi;
		}

		switch (produit.type) {
			case 0: // aucun
				break;
			case 1: // négocié
				produit.prix = valeurNumbered;
				break;
			case 2: // remise %
				produit.prix = prixDeReference - Math.round(((valeurNumbered * prixDeReference) / 100) * 100) / 100;
				break;
			case 3: // remise
				produit.prix = prixDeReference - valeurNumbered;
				break;
			case 4: // marge %
				produit.prix = prixDeReference + Math.round(((valeurNumbered * prixDeReference) / 100) * 100) / 100;
				break;
			case 5: // marge
				produit.prix = prixDeReference + valeurNumbered;
				break;
			default:
				break;
		}
		//Quickfix pour la présentation LME le comportement de la disquette devrait être le même dans la fiche stock
		if (this.selectedSens == 'stock') {
			if (ligne.savedValeur === ligne.valeurNumbered) {
				ligne.isChanging = 0;
			} else if (old !== valeurNumbered) {
				produit.isChanging = 1;
			}
		} else {
			produit.isChanging = 1;
		}
	}

	closeAddForm() {
		this.showNewGrilleForm = false;
		this.showDuplicateCodeGrilleForm = false;
		this.newGrille = new Grille();
		this.grilleModele = null;
	}

	async loadOrignalGrille() {
		let originalGrille = '';
		try {
			originalGrille = await this.TarifsService.getGrilleByCode(this.originalGrilleCode);
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			if (!originalGrille) {
				this.notification.error('Aucune grille trouvée pour ce code');
			} else {
				this.newGrille.libelle = originalGrille.libelle;
				this.newGrille.originalGrilleId = originalGrille.id;
				this.newGrille.formType = 'duplicateByCode';
				this.newGrille.dateDebut = this.$filter('date')(originalGrille.dateDebut, 'dd/MM/yyyy', 'UTC');
				this.newGrille.dateFin = this.$filter('date')(originalGrille.dateFin, 'dd/MM/yyyy', 'UTC');
				this.formulaireTitre = 'Duplication de la grille : ' + originalGrille.libelle;
				this.showNewGrilleForm = true;
				this.showDuplicateCodeGrilleForm = false;
			}
		}
	}

	async addGrille() {
		switch (this.domaine) {
			case 'societe':
				this.newGrille.societeId = this.source.id;
				break;
			case 'siteCommercial':
				this.newGrille.siteCommercialId = this.source.id;
				break;
			case 'typeClient':
				this.newGrille.typeClientId = this.source.id;
				break;
			case 'client':
				this.newGrille.clientId = this.source.id;
				break;
			case 'chantier':
			case 'provenance':
				this.newGrille.chantierId = this.source.id;
				break;
		}
		if (!this.newGrille.libelle) {
			this.notification.error('Le libellé ne peut pas être nul.');
			return;
		}

		if (this.newGrille.dateDebut == null || this.newGrille.dateFin == null) {
			this.notification.error('Les dates ne peuvent pas être nulles.');
			return;
		}

		let isValid = true;
		if (this.moment.utc(this.newGrille.dateDebut, 'DD/MM/YYYY').isAfter(this.moment.utc(this.newGrille.dateFin, 'DD/MM/YYYY'))) {
			isValid = false;
		}
		const newGrilleDebut = this.moment.utc(this.newGrille.dateDebut, 'DD/MM/YYYY');
		const newGrilleFin = this.moment.utc(this.newGrille.dateFin, 'DD/MM/YYYY');
		angular.forEach(
			this.grilles,
			function (uneGrille) {
				const oldDateDebut = this.moment.utc(uneGrille.dateDebut, 'DD/MM/YYYY');
				const oldDateFin = this.moment.utc(uneGrille.dateFin, 'DD/MM/YYYY');
				// vérifie que les dates de début et fin de la nouvelle grille ne soient pas inclus dans une grille déjà existante
				if (
					newGrilleDebut.isBetween(oldDateDebut, oldDateFin, 'days', '[]') ||
					newGrilleFin.isBetween(oldDateDebut, oldDateFin, 'days', '[]')
				) {
					isValid = false;
				}
			},
			this
		);

		// verifier si les dates de la grille client sont entre les dates de la grille parente
		if (this.newGrille.clientId != null && this.newGrille.chantierId == null && this.newGrille.grilleParente != null) {
			const grilleParenteIndex = this._.findIndex(this.grillesParentes, {
				id: this.newGrille.grilleParente
			});
			const grilleParente = this.grillesParentes[grilleParenteIndex];

			if (this.moment.utc(this.newGrille.dateDebut, 'DD/MM/YYYY').isBefore(this.moment.utc(grilleParente.dateDebut, 'YYYY/MM/DD'))) {
				isValid = false;
				this.notification.error(
					'La date de début ne peut pas etre avant la date de début de la grille parente : ' +
						this.moment(grilleParente.dateDebut, 'YYYY/MM/DD').format('DD/MM/YYYY') +
						'.'
				);
			}

			if (this.moment.utc(this.newGrille.dateFin, 'DD/MM/YYYY').isAfter(this.moment.utc(grilleParente.dateFin, 'YYYY/MM/DD'))) {
				isValid = false;
				this.notification.error(
					'La date de fin ne peut pas etre apres la date de fin de la grille parente : ' +
						this.moment(grilleParente.dateFin, 'YYYY/MM/DD').format('DD/MM/YYYY') +
						'.'
				);
			}
		}

		if (isValid) {
			if (this.newGrille.societeId == null || this.newGrille.societeId == undefined) {
				this.newGrille.societeId = this.selectedSocieteId;
			}

			if (this.selectedSiteCommercialId) {
				this.newGrille.siteCommercialId = this.selectedSiteCommercialId;
				this.newGrille.societeId = null;
			}

			this.newGrille.estVente = this.selectedSens;

			if (this.selectedTransporteur != null) {
				this.newGrille.transporteurId = this.selectedTransporteur;
			}

			this.isLoading = true;

			try {
				if (this.selectedSens == 'stock') {
					await this.TarifsService.createGrilleStock(this.newGrille);
					this.getGrillesStock();
				} else {
					await this.TarifsService.createGrille(this.newGrille);
					this.getGrilles();
				}
			} catch (ex) {
				this.isLoading = false;
				this.notification.error(ex.data);
			}

			this.isLoading = false;

			this.newGrille = new Grille();

			switch (this.domaine) {
				case 'societe':
					this.newGrille.societeId = this.source.id;
					break;
				case 'siteCommercial':
					this.newGrille.siteCommercialId = this.source.id;
					break;
				case 'typeClient':
					this.newGrille.typeClientId = this.source.id;
					break;
				case 'client':
					this.newGrille.clientId = this.source.id;
					break;
				case 'chantier':
				case 'provenance':
					this.newGrille.chantierId = this.source.id;
					break;
			}

			this.showNewGrilleForm = false;
			this.showDuplicateCodeGrilleForm = false;
			this.grilleModele = null;
		} else {
			this.notification.error('Les dates saisies ne sont pas valides ou rentrent en conflit avec une autre grille.');
		}
	}

	async displayGrille(grille) {
		this.isLoading = true;
		this.selectedGrille = grille;
		if (this.selectedSens == 'stock') {
			this.displayedGrille = await this.TarifsService.getGrilleStock(grille.id);
			//Il y a moyen de faire plus simple comme copier l'objet de lignes
			angular.forEach(this.displayedGrille.lignes, (x) => {
				x.savedValeur = x.valeur;
				x.savedDensite = x.densite;
				x.savedPourcentagePerte = x.pourcentagePerte;
			});
		} else {
			// this.displayedGrille = await this.TarifsService.getGrille(grille.id);
			this.displayedGrille = [];
		}
		this.isLoading = false;
	}

	async displayGrilleTransport(tableState) {
		try {
			var that = this;
			if (!that.startLoadingTransport) {
				that = this.$parent.$parent.$ctrl;
			}
			let previousTableState;
			that.startLoadingTransport();

			if (tableState) {
				previousTableState = tableState;
			}

			const filters = that.PaginationService.getFilters(previousTableState);
			const sorts = that.PaginationService.getSorts(previousTableState);
			const pagination = that.PaginationService.getPagination(previousTableState);

			const data = await that.TarifsService.getGrilleTransport(that.selectedGrille.id, filters, sorts, pagination);

			that.displayedGrille.prixTransports = data.items;
			if (previousTableState) {
				that.PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			that.notification.error(ex.data);
		} finally {
			that.stopLoadingTransport();
		}
	}

	displayGrilleGranulat = async (tableState) => {
		try {
			var that = this;

			if (!that.startLoadingTransport) {
				that = this.$parent.$parent.$ctrl;
			}
			let previousTableState;
			that.startLoadingTransport();

			if (tableState) {
				previousTableState = tableState;
			}

			const filters = that.PaginationService.getFilters(previousTableState);
			const sorts = that.PaginationService.getSorts(previousTableState);
			const pagination = that.PaginationService.getPagination(previousTableState);

			const data = await that.TarifsService.getGrilleGranulat(that.selectedGrille.id, filters, sorts, pagination);

			that.displayedGrille.prixGranulats = data.items;
			if (previousTableState) {
				that.PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			that.notification.error(ex.data);
		} finally {
			that.stopLoadingTransport();
		}
	};

	async displayGrilleEnrobe(tableState) {
		try {
			var that = this;
			if (!that.startLoadingTransport) {
				that = this.$parent.$parent.$ctrl;
			}
			let previousTableState;
			that.startLoadingTransport();

			if (tableState) {
				previousTableState = tableState;
			}

			const filters = that.PaginationService.getFilters(previousTableState);
			const sorts = that.PaginationService.getSorts(previousTableState);
			const pagination = that.PaginationService.getPagination(previousTableState);

			const data = await that.TarifsService.getGrilleEnrobe(that.selectedGrille.id, filters, sorts, pagination);

			that.displayedGrille.prixEnrobes = data.items;
			if (previousTableState) {
				that.PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			that.notification.error(ex.data);
		} finally {
			that.stopLoadingTransport();
		}
	}

	async displayGrilleLiant(tableState) {
		try {
			var that = this;
			if (!that.startLoadingTransport) {
				that = this.$parent.$parent.$ctrl;
			}
			let previousTableState;
			that.startLoadingTransport();

			if (tableState) {
				previousTableState = tableState;
			}

			const filters = that.PaginationService.getFilters(previousTableState);
			const sorts = that.PaginationService.getSorts(previousTableState);
			const pagination = that.PaginationService.getPagination(previousTableState);

			const data = await that.TarifsService.getGrilleLiant(that.selectedGrille.id, filters, sorts, pagination);

			that.displayedGrille.prixLiants = data.items;
			if (previousTableState) {
				that.PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			that.notification.error(ex.data);
		} finally {
			that.stopLoadingTransport();
		}
	}

	async displayGrillePrestation(tableState) {
		try {
			var that = this;
			if (!that.startLoadingTransport) {
				that = this.$parent.$parent.$ctrl;
			}
			let previousTableState;
			that.startLoadingTransport();

			if (tableState) {
				previousTableState = tableState;
			}

			const filters = that.PaginationService.getFilters(previousTableState);
			const sorts = that.PaginationService.getSorts(previousTableState);
			const pagination = that.PaginationService.getPagination(previousTableState);

			const data = await that.TarifsService.getGrillePrestation(that.selectedGrille.id, filters, sorts, pagination);

			that.displayedGrille.prixPrestations = data.items;
			if (previousTableState) {
				that.PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			that.notification.error(ex.data);
		} finally {
			that.stopLoadingTransport();
		}
	}

	startLoadingTransport() {
		this.loadingTransport = true;
	}

	stopLoadingTransport() {
		this.loadingTransport = false;
	}

	async changePrixAchat(lignePrix) {
		if (lignePrix.prixAchat == '') {
			lignePrix.prixAchat = null;
		}

		lignePrix.prix = this.$filter('number')(lignePrix.prix, 2);

		lignePrix.isChanging = 1;
	}

	async addPrix(produit) {
		let result;
		produit.isLoading = 1;
		try {
			if (this.selectedSens == 'stock') {
				/*  if(produit.valeur == undefined || produit.densite == undefined || produit.pourcentagePerte == undefined){
                    throw "Mauvaise décimale repérée";
                } */
				produit.savedValeur = produit.valeur;
				produit.savedDensite = produit.densite;
				produit.savedPourcentagePerte = produit.pourcentagePerte;

				produit.grilleStockId = this.selectedGrille.id;
				if (produit.id > 0) {
					result = await this.TarifsService.updateLigneStock(produit);
				} else {
					var newPrix = new Prix();
					produit.grilleTarifaireId = this.selectedGrille.id;
					result = this.TarifsService.createLigneStock(produit);
					produit.id = result.idLigne;
				}
			} else {
				if (produit.id > 0) {
					result = await this.TarifsService.updatePrix(produit);
				} else {
					var newPrix = new Prix();
					produit.grilleTarifaireId = this.selectedGrille.id;
					result = await this.TarifsService.createPrix(produit);
					produit.id = result.idPrix;
				}
			}
		} catch (e) {
			console.error(e);
		}

		produit.isChanging = 0;
		produit.isLoading = 0;
		produit.dateModification = result.dateModification;
	}

	async deletePrix(prix) {
		prix.isLoading = 1;
		if (this.selectedSens == 'stock') {
			await this.TarifsService.deleteLigneStock(prix);
			prix.densite = null;
			prix.pourcentagePerte = null;
		} else {
			const ids = [prix.id];
			await this.TarifsService.deletePrix(ids);
			prix.type = 1;
			prix.prixAchat = null;
			prix.transporte = null;
		}

		prix.isLoading = 0;
		prix.id = 0;
		prix.valeur = null;
		prix.dateModification = null;
	}

	async deletePrixProduits() {
		const ids = [];
		angular.forEach(
			this.displayedGrille.prixGranulats,
			function (prix) {
				if (!prix.isEnrobe && prix.type && prix.id) {
					ids.push(prix.id);
					prix.type = 1;
					prix.prixAchat = null;
					prix.transporte = null;
					prix.id = 0;
					prix.valeur = null;
					prix.dateModification = null;
				}
			},
			this
		);
		await this.TarifsService.deletePrix(ids);
	}

	async deletePrixEnrobes() {
		const ids = [];
		angular.forEach(
			this.displayedGrille.prixEnrobes,
			function (prix) {
				if (prix.type && prix.id) {
					ids.push(prix.id);
					prix.type = 1;
					prix.prixAchat = null;
					prix.transporte = null;
					prix.id = 0;
					prix.valeur = null;
					prix.dateModification = null;
				}
			},
			this
		);
		await this.TarifsService.deletePrix(ids);
	}

	async deletePrixLiants() {
		const ids = [];
		angular.forEach(
			this.displayedGrille.prixLiants,
			function (prix) {
				if (prix.type && prix.id) {
					ids.push(prix.id);
					prix.type = 1;
					prix.prixAchat = null;
					prix.transporte = null;
					prix.id = 0;
					prix.valeur = null;
					prix.dateModification = null;
				}
			},
			this
		);
		await this.TarifsService.deletePrix(ids);
	}

	async deletePrixLignes() {
		const ids = [];
		angular.forEach(
			this.displayedGrille.lignes,
			function (prix) {
				if (prix.id) {
					ids.push(prix.id);
					prix.type = 1;
					prix.prixAchat = null;
					prix.transporte = null;
					prix.id = 0;
					prix.valeur = null;
					prix.dateModification = null;
				}
			},
			this
		);
		await this.TarifsService.deletePrix(ids);
	}

	async deletePrixPrestations() {
		const ids = [];
		angular.forEach(
			this.displayedGrille.prixPrestations,
			function (prix) {
				if (prix.type && prix.id) {
					ids.push(prix.id);
					prix.type = 1;
					prix.prixAchat = null;
					prix.transporte = null;
					prix.id = 0;
					prix.valeur = null;
					prix.dateModification = null;
				}
			},
			this
		);
		await this.TarifsService.deletePrix(ids);
	}

	async deletePrixTransports() {
		const ids = [];
		angular.forEach(
			this.displayedGrille.prixTransports,
			function (prix) {
				if (prix.type && prix.id) {
					ids.push(prix.id);
					prix.type = 1;
					prix.prixAchat = null;
					prix.transporte = null;
					prix.id = 0;
					prix.valeur = null;
					prix.dateModification = null;
				}
			},
			this
		);
		await this.TarifsService.deletePrix(ids);
	}

	async saveAllTransports() {
		angular.forEach(
			this.displayedGrille.prixTransports,
			function (prix) {
				if (prix.isChanging) {
					this.addPrix(prix);
				}
			},
			this
		);
	}

	async saveAllPrestations() {
		angular.forEach(
			this.displayedGrille.prixPrestations,
			function (prix) {
				if (prix.isChanging) {
					this.addPrix(prix);
				}
			},
			this
		);
	}

	async saveAllProduits() {
		angular.forEach(
			this.displayedGrille.prixGranulats,
			function (prix) {
				if (prix.isChanging) {
					this.addPrix(prix);
				}
			},
			this
		);
	}

	async saveAllEnrobes() {
		angular.forEach(
			this.displayedGrille.prixEnrobes,
			function (prix) {
				if (prix.isChanging) {
					this.addPrix(prix);
				}
			},
			this
		);
	}

	async saveAllLiants() {
		angular.forEach(
			this.displayedGrille.prixLiants,
			function (prix) {
				if (prix.isChanging) {
					this.addPrix(prix);
				}
			},
			this
		);
	}

	async saveAllLignes() {
		angular.forEach(
			this.displayedGrille.lignes,
			function (ligne) {
				if (ligne.isChanging) {
					this.addPrix(ligne);
				}
			},
			this
		);
	}

	isActualGrille(grille) {
		if (this.moment.utc().isBetween(grille.dateDebut, grille.dateFin, null, '[)')) {
			return true;
		}
		return false;
	}

	changeGrilleParente() {
		if (this.newGrille.grilleParente != null) {
			const grilleParenteIndex = this._.findIndex(this.grillesParentes, {
				id: this.newGrille.grilleParente
			});
			this.newGrille.dateDebut = this.$filter('date')(this.grillesParentes[grilleParenteIndex].dateDebut, 'dd/MM/yyyy', 'UTC');
			this.newGrille.dateFin = this.$filter('date')(this.grillesParentes[grilleParenteIndex].dateFin, 'dd/MM/yyyy', 'UTC');
		}
	}

	async changeTransporte(lignePrix) {
		lignePrix.isChanging = 1;
	}

	async changeRistourne(lignePrix) {
		if (lignePrix.valeur) {
			lignePrix.isChanging = 1;
		}
	}

	applyMargeGlobale(typeProduit) {
		if (typeProduit == 'granulats') {
			angular.forEach(
				this.displayedGrille.prixGranulats,
				function (ligne) {
					if (this.typeGranulat == 1) {
						ligne.type = this.typeGranulat;
						ligne.valeur = this.margeGlobaleGranulats;
						this.updatePrix(ligne);
					} else {
						if (ligne.prixStandard.prix && this.selectedGrille.grilleParenteId) {
							ligne.type = this.typeGranulat;
							ligne.valeur = this.margeGlobaleGranulats;
							this.updatePrix(ligne);
						}
					}
				},
				this
			);
		} else if (typeProduit == 'enrobes') {
			angular.forEach(
				this.displayedGrille.prixEnrobes,
				function (ligne) {
					ligne.type = this.typeEnrobe;
					ligne.valeur = this.margeGlobaleEnrobes;
					this.updatePrix(ligne);
				},
				this
			);
		} else if (typeProduit == 'liants') {
			angular.forEach(
				this.displayedGrille.prixLiants,
				function (ligne) {
					ligne.type = this.typeLiant;
					ligne.valeur = this.margeGlobaleLiants;
					this.updatePrix(ligne);
				},
				this
			);
		} else if (typeProduit == 'prestations') {
			angular.forEach(
				this.displayedGrille.prixPrestations,
				function (ligne) {
					if (this.typePrestation == 1) {
						ligne.type = this.typePrestation;
						ligne.valeur = this.margeGlobalePrestations;
						this.updatePrix(ligne);
					} else {
						if (ligne.prixStandard.prix && this.selectedGrille.grilleParenteId) {
							ligne.type = this.typePrestation;
							ligne.valeur = this.margeGlobalePrestations;
							this.updatePrix(ligne);
						}
					}
				},
				this
			);
		} else if (typeProduit == 'transports') {
			angular.forEach(
				this.displayedGrille.prixTransports,
				function (ligne) {
					if (this.typeTransport == 1) {
						ligne.type = this.typeTransport;
						ligne.valeur = this.margeGlobaleTransports;
						this.updatePrix(ligne);
					} else {
						if (ligne.prixStandard.prix && this.selectedGrille.grilleParenteId) {
							ligne.type = this.typeTransport;
							ligne.valeur = this.margeGlobaleTransports;
							this.updatePrix(ligne);
						}
					}
				},
				this
			);
		}
	}

	updateDensity(ligne) {
		const old = ligne.densite;
		ligne.densite = this.convertStringToDecimal(ligne.densite);
		if (ligne.savedDensite === ligne.densite) {
			ligne.isChanging = 0;
		} else if (old !== ligne.densite) {
			ligne.isChanging = 1;
		}
	}

	updatePercentPerte(ligne) {
		const old = ligne.pourcentagePerte;
		ligne.pourcentagePerte = this.convertStringToDecimal(ligne.pourcentagePerte);
		if (ligne.savedPourcentagePerte === ligne.pourcentagePerte) {
			ligne.isChanging = 0;
		} else if (old !== ligne.pourcentagePerte) {
			ligne.isChanging = 1;
		}
	}

	montreDisquette(ligne) {
		if (ligne.savedValeur == ligne.valeur && ligne.savedDensite == ligne.densite && ligne.savedPourcentagePerte == ligne.pourcentagePerte) {
			ligne.isChanging = 0;
		} else {
			ligne.isChanging = 1;
		}
	}

	convertStringToDecimal(value) {
		if (typeof value === 'string') {
			// SL parseFloat() à garder !!
			return parseFloat(value.replace(',', '.').replace(' ', ''));
		}
		return value;
	}

	duplicateGrille(grille) {
		this.formulaireTitre = 'Duplication de la grille : ' + grille.libelle;
		this.showDuplicateCodeGrilleForm = false;
		this.showNewGrilleForm = true;
		this.grilleModele = angular.copy(grille);
		this.newGrille = angular.copy(grille);
		this.newGrille.libelle = this.grilleModele.libelle + ' (copie)';
		this.newGrille.dateFin = this.moment.utc(this.grilleModele.dateFin, 'DD/MM/YYYY');
		this.newGrille.dateDebut = this.moment.utc(this.grilleModele.dateDebut, 'DD/MM/YYYY');
		this.newGrille.formType = 'duplicate';
		this.newGrille.grilleParente = null;
		this.newGrille.originalGrilleId = grille.id;
		this.newGrille.augmenterPrixProduits = false;
		this.newGrille.pourcentageAugmentation = 0;
		this.newGrille.regleArrondi = '0';
	}

	editGrille(grille) {
		this.formulaireTitre = 'Edition de la grille : ' + grille.libelle;
		this.showDuplicateCodeGrilleForm = false;
		this.showNewGrilleForm = true;
		this.newGrille = Object.assign({}, grille);
		this.newGrille.formType = 'edit';
		this.newGrille.dateDebut = grille.dateDebut;
		this.newGrille.dateFin = grille.dateFin;
		this.newGrille.grilleParente = grille.grilleParenteId;

		this.getGrillesParentes();
	}

	async updateGrille() {
		if (!this.newGrille.libelle) {
			this.notification.error('Le libellé ne peut pas être nul.');
			return;
		}

		if (this.newGrille.dateDebut == null || this.newGrille.dateFin == null || this.newGrille.dateDebut == '' || this.newGrille.dateFin == '') {
			this.notification.error('Les dates ne peuvent pas être nulles.');
			return;
		}

		let isValid = true;
		if (this.moment.utc(this.newGrille.dateDebut, 'DD/MM/YYYY').isAfter(this.moment.utc(this.newGrille.dateFin, 'DD/MM/YYYY'))) {
			isValid = false;
		}

		angular.forEach(
			this.grilles,
			function (uneGrille) {
				if (uneGrille.id != this.newGrille.id) {
					// test si les dates de la nouvelle grille contiennent d'autres grilles
					if (
						this.moment.utc(uneGrille.dateDebut).isAfter(this.moment.utc(this.newGrille.dateDebut, 'DD/MM/YYYY')) &&
						this.moment.utc(uneGrille.dateDebut).isBefore(this.moment.utc(this.newGrille.dateFin, 'DD/MM/YYYY'))
					) {
						isValid = false;
					}

					if (
						this.moment.utc(uneGrille.dateFin).isAfter(this.moment.utc(this.newGrille.dateDebut, 'DD/MM/YYYY')) &&
						this.moment.utc(uneGrille.dateFin).isBefore(this.moment.utc(this.newGrille.dateFin, 'DD/MM/YYYY'))
					) {
						isValid = false;
					}

					// test si les dates de la nouvelle grille sont comprises dans d'autres grilles
					if (
						this.moment.utc(this.newGrille.dateDebut, 'DD/MM/YYYY').isSameOrAfter(uneGrille.dateDebut) &&
						this.moment.utc(this.newGrille.dateFin, 'DD/MM/YYYY').isSameOrBefore(uneGrille.dateFin)
					) {
						isValid = false;
					}
				}
			},
			this
		);

		if (isValid) {
			try {
				if (this.selectedSens == 'stock') {
					const data = await this.TarifsService.updateGrilleStock(this.newGrille);
				} else {
					const data = await this.TarifsService.updateGrille(this.newGrille);
				}
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		} else {
			this.notification.error('Les dates saisies ne sont pas valides ou rentrent en conflit avec une autre grille.');
		}

		this.showNewGrilleForm = false;
		this.getGrilles();
	}

	async deleteGrille(grille) {
		const confirmation = confirm(
			'Etes vous sur de vouloir supprimer cette grilles, les grilles enfant seront attachées à la grille parente si elle existe.'
		);
		if (confirmation) {
			try {
				if (this.selectedSens == 'stock') {
					const data = await this.TarifsService.deleteGrilleStock(grille.id);
				} else {
					const data = await this.TarifsService.deleteGrille(grille.id);
				}
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}

			this.getGrilles();
		}
	}

	async exporter(modeImpression, typePrix) {
		const dateAchat = this.dateSimulationAchat;
		try {
			this.startLoading();
			if (typePrix == 0) {
				var fileName = 'Simulation_de_prix_enrobés';
			} else {
				var fileName = 'Simulation_de_prix_liants';
			}
			let resultat = undefined;
			try {
				resultat = await this.TarifsService.exportEnrobes(modeImpression, this.selectedGrille.id, dateAchat, typePrix);
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('CHANTIERS.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				const status = resultat.status;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
				} finally {
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		} finally {
			this.stopLoading();
		}
	}

	async getTypesPrix() {
		if (!this.prixTypes) {
			try {
				this.prixTypes = await this.TarifsService.getTypesPrix();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
			}
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
