import * as _ from 'lodash';
import { CaracteristiqueService } from '../../../../../core/http/caracteristique.service';
import { FilterType } from '../../../../../core/interface/FilterProperty';
import { Caracteristique } from '../../../../../core/models/caracteristique/Caracteristique';
import { Type } from '../../../../../core/models/type/Type';
import { ValeurCaracteristique } from '../../../../../core/models/valeur-caracteristique/ValeurCaracteristique';
import { Interpreteur } from '../../../../../libs/massia-component/massia-formules/interpreteur';
import DocumentsService from '../../../documents/services/documents.service';
class CaracteristiqueController {
    domaine: string;
    type: Type;
    valeurs: ValeurCaracteristique[];
    visibilite: number = 1;
    duplicate: boolean;
    isDuplicTraite: boolean = false;
    caractSvc: CaracteristiqueService;
    DocumentsService: DocumentsService;
    caracteristiques: Caracteristique[];
    $scope: ng.IScope;
    unregister: Function[] = [];
    notification: any;
    interpreteur: Interpreteur;
    visibility: { [key: number]: boolean } = {};

    /* @ngInject */
    constructor(
        CaracteristiqueService: CaracteristiqueService,
        DocumentsService: DocumentsService,
        $scope: ng.IScope,
        notification: any,
        Interpreteur: Interpreteur
    ) {
        this.caractSvc = CaracteristiqueService;
        this.DocumentsService = DocumentsService;
        this.$scope = $scope;
        this.notification = notification;
        this.interpreteur = Interpreteur;
    }

    $onInit() {
        this.unregister.push(
            this.$scope.$watch(
                () => this.type,
                () => {
                    this.loadCaracteristique();
                },
                true
            ),
            this.$scope.$watch(
                () => this.valeurs,
                () => {
                    this.getVisibility();
                },
                true
            )
        );
    }

    $onDestroy() {
        _.forEach(this.unregister, (u) => u());
    }

    async loadCaracteristique() {
        const caracts = await this.caractSvc.getAll([
            {
                criterion: 'caracteristiqueTypes.type.id',
                type: FilterType.EQUALS,
                value: this.type.id
            },
            {
                criterion: 'domaines.code',
                type: FilterType.EQUALS,
                value: this.domaine
            }
        ]);
        this.caracteristiques = caracts.items;
        this.getVisibility();
        for (let i = 0; i < this.caracteristiques.length; i++) {
            const c = this.caracteristiques[i];

            const value = this.valeurs.find((x) => x.idCaracteristique === c.id);
            const carType = this.getCaractType(c);
            if (carType) {
                if (!value) {
                    if (carType && (carType.valeur || carType.valeur === '0')) {
                        const valCar = <ValeurCaracteristique>{
                            id: 0,
                            idCaracteristique: c.id,
                            value: carType.valeur
                        };

                        this.valeurs.push(valCar);
                    }
                } else {
                    //on a une valeur, si on est en duplication et valCaract non à récupérer, l'enlever
                    if (this.duplicate && !this.isDuplicTraite) {
                        if (carType && !carType.isDuplicable) {
                            const idx = this.valeurs.indexOf(value);
                            const nouv = <ValeurCaracteristique>{
                                id: 0,
                                idCaracteristique: c.id,
                                value: null
                            };
                            //this.valeurs = this.valeurs.splice(idx, 1);
                            this.valeurs = this.valeurs.filter((x) => x.idCaracteristique !== c.id);
                            this.valeurs.push(nouv);
                        }
                    }
                }
            }
        }
        this.valeurs = this.valeurs.filter(x => this.caracteristiques.some(c => c.id === x.idCaracteristique));
        this.isDuplicTraite = true;
    }

    getCaractType(caract: Caracteristique) {
        const type = caract.types.find((x) => x.type.id === this.type.id);
        return type;
    }

    getVisibility() {
        this.interpreteur.init({ caracteristiques: _.groupBy(this.valeurs, 'idCaracteristique') }, null);
        this.interpreteur.formatCaract = this.caracteristiques;
        _.forEach(this.caracteristiques, async (caract) => {
            let isVisible = true;
            const caractType = this.getCaractType(caract);
            if (caractType?.condition) {
                const formule = await this.interpreteur.calculeFormule(caractType.condition, false);
                isVisible = formule === 'true';
            }
            this.visibility[caract.id] = isVisible;
        });
    }

    isVisible(caract: Caracteristique) {
        const caractType = this.getCaractType(caract);
        return (this.type.niveauVisibilite || 0) >= caractType?.visibilite && this.visibility[caract.id];
    }

    async downloadDocument(fileName: string, extension: string, code: string) {
        try {
            const resultat = await this.DocumentsService.print(fileName, extension, code);

            const data = resultat.data;
            const status = resultat.status;
            let headers = resultat.headers;

            headers = headers();

            const contentType = headers['content-type'];

            const linkElement = document.createElement('a');

            const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
            const url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute('download', fileName + '.' + extension);

            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(clickEvent);
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }
}

export default {
    bindings: {
        valeurs: '=',
        type: '<',
        domaine: '<',
        visibilite: '<',
        lecture: '<',
        duplicate: '<'
    },
    controller: CaracteristiqueController,
    template: require('./caracteristique.html')
};
