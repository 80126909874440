PlanificationJobPrefacturationsController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'PlanificationJobPrefacturationsService',
	'notification'
];

export default function PlanificationJobPrefacturationsController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	PlanificationJobPrefacturationsService,
	notification
) {
	const vm = this;

	let previousTableState;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.planifications = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.loadPlanificationJobPrefacturations = loadPlanificationJobPrefacturations;
	vm.deletePlanificationJobPrefacturation = deletePlanificationJobPrefacturation;
	vm.createPlanificationJobPrefacturation = createPlanificationJobPrefacturation;
	vm.updatePlanificationJobPrefacturation = updatePlanificationJobPrefacturation;
	vm.activeEditMode = activeEditMode;
	vm.desactiveEditMode = desactiveEditMode;

	async function init() {
		vm.sites = await PlanificationJobPrefacturationsService.getSites();
	}

	init();

	async function loadPlanificationJobPrefacturations(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'planification-job-prefacturations.list') {
			$state.go('planification-job-prefacturations.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.planifications = [];
		vm.newPlanification = {
			isMoisEnCours: true
		};

		try {
			const data = await PlanificationJobPrefacturationsService.getPlanificationJobPrefacturations(filters, sorts, pagination);

			vm.planifications = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deletePlanificationJobPrefacturation(planification) {
		if (planification && planification.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('PLANIFICATION_JOB_PREFACTURATIONS.DELETE.TITLE'),
				modalMsg: $translate.instant('PLANIFICATION_JOB_PREFACTURATIONS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await PlanificationJobPrefacturationsService.deletePlanificationJobPrefacturationById(planification.id);
					notification.success($translate.instant('PLANIFICATION_JOB_PREFACTURATIONS.DELETE.SUCCESS'));
					loadPlanificationJobPrefacturations();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function activeEditMode(planification) {
		planification.isEditMode = true;
	}

	function desactiveEditMode(planification) {
		planification.isEditMode = false;
		loadPlanificationJobPrefacturations();
	}

	async function createPlanificationJobPrefacturation(planification) {
		if (planification.isMoisEnCours) {
			planification.jourDebut = null;
			planification.jourFin = null;
		}
		if (planification && planification.idSite && (planification.isMoisEnCours || (planification.jourDebut && planification.jourFin))) {
			startLoading();
			try {
				await PlanificationJobPrefacturationsService.createPlanificationJobPrefacturation(planification);
				notification.success($translate.instant('PLANIFICATION_JOB_PREFACTURATIONS.CREATE.SUCCESS'));
				loadPlanificationJobPrefacturations();
				vm.newPlanification = {};
			} catch (ex) {
				notification.error(ex.data);
			} finally {
				stopLoading();
			}
		}
	}

	async function updatePlanificationJobPrefacturation(planification) {
		if (planification.isMoisEnCours) {
			planification.jourDebut = null;
			planification.jourFin = null;
		}
		if (
			planification &&
			planification.idSite &&
			planification.id &&
			(planification.isMoisEnCours || (planification.jourDebut && planification.jourFin))
		) {
			startLoading();
			try {
				await PlanificationJobPrefacturationsService.updatePlanificationJobPrefacturation(planification);
				notification.success($translate.instant('PLANIFICATION_JOB_PREFACTURATIONS.UPDATE.SUCCESS'));
				vm.newPlanification = {};
				loadPlanificationJobPrefacturations();
			} catch (ex) {
				notification.error(ex.data);
			} finally {
				stopLoading();
			}
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
