import validatorBuilder from 'validator-builder';

let additionnalInfoMesureValidator = null;

export default class AdditionnalInfoMesure {
    constructor(idNature, data = {}) {
        this.id = data.id;
        this.idNature = idNature;
        this.decimale = data.decimale || 0;
        this.idUnite = data.idUnite;
        this.nomUnite = data.nomUnite;
        this.elements = data.elements || [];
        this.idSsEssaiMasse = data.idSsEssaiMasse;
        this.codeSsEssaiMasse = data.codeSsEssaiMasse;
        this.idSerieTamis = data.idSerieTamis;
        this.arrondisTamis = data.arrondisTamis || [];
        this.idEssaiInclus = data.idEssaiInclus;
        this.iterable = data.iterable;
        this.nbIteration = data.nbIteration;
        this.sourceNbEchant = data.sourceNbEchant;
        this.isNbEchContreEssaiDouble = data.isNbEchContreEssaiDouble;
        this.nbEchContreEssai = data.nbEchContreEssai;
        this.nbEchEssaiCerib = data.nbEchEssaiCerib;
        this.isNbEchContreEssaiCeribDouble = data.isNbEchContreEssaiCeribDouble;
        this.nbEchContreEssaiCerib = data.nbEchContreEssaiCerib;
        this.template = data.template;
        this.temptemplate = data.temptemplate || {};
        this.idConfigExcel = data.idConfigExcel;
        this.grandeurMinimum = data.grandeurMinimum;
        this.grandeurMaximum = data.grandeurMaximum;

        this.data = data.data || {};
        this.sousMesuresDetail = data.sousMesuresDetail || [];
    }

    isValid() {
        additionnalInfoMesureValidator = additionnalInfoMesureValidator || validatorBuilder.getInstanceFor('AdditionnalInfoMesure');
        const validationResults = additionnalInfoMesureValidator.validate(this);
        return validationResults.isValid;
    }
}
