import Badge from './badge.model';

export default class BadgeFormController {
	static $inject = [
		'$scope',
		'$timeout',
		'$state',
		'$stateParams',
		'BadgesCommunicationService',
		'BadgesService',
		'notification',
		'$location',
		'$anchorScroll',
		'_',
		'$uibModal',
		'$translate',
		'moment',
		'$filter',
		'globalizationManagementService',
		'MOPService',
		'ModalService'
	];

	constructor(
		$scope,
		$timeout,
		$state,
		$stateParams,
		BadgesCommunicationService,
		BadgesService,
		notification,
		$location,
		$anchorScroll,
		_,
		$uibModal,
		$translate,
		moment,
		$filter,
		globalizationManagementService,
		MOPService,
		ModalService
	) {
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.BadgesCommunicationService = BadgesCommunicationService;
		this.BadgesService = BadgesService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this._ = _;
		this.$uibModal = $uibModal;
		this.$translate = $translate;
		this.moment = moment;
		this.$filter = $filter;
		this.typesES = [];
		this.typesAV = [];
		this.typesTare = [];
		this.newZonesStock = [];
		this.newDocsCE = [];
		this.newLigne = false;
		this.zoneStockages = [];
		this.isAlertDap = false;
		this.MOPService = MOPService;
		this.ModalService = ModalService;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async $onInit() {
		this.MOPService.setMop([{ title: 'BADGES.BREADCRUMBS.BADGES_LIST', filename: 'Badges.pdf', application: 'gestion' }]);
		this.badge = {};
		this.loading = false;
		await this.reset();
		if (this.$stateParams.id) {
			this.setBadgeAsTypeAhead();
		}
	}

	$onDestroy() {
		this.$timeout.cancel(this.updateBadgeTimeout);
		this.MOPService.resetMop();
	}

	async reset() {
		this.startLoading();

		let data = {
			id: this.$stateParams.id
		};
		if (data.id) {
			try {
				data = await this.BadgesService.getBadgeById(data.id);
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
				this.annuler();
			}
		}
		this.badge = new Badge(data);
		if (this.badge.dateTare) {
			this.badge.heureTare = this.moment(this.badge.dateTare, this.dateFormat + ' HH:mm');
		}
		this.loadTypesES();
		this.loadTypesAV();
		this.loadTypesTare();
		this.getProduitsLies();
		this.selectedSitesCommerciaux = [];
		await this.getSitesCommerciaux();
		this.selectedChantiers = [];
		await this.getChantiers();
		this.typesBenne = await this.BadgesService.getTypesBenne();
		this.$timeout.cancel(this.updateBadgeTimeout);
		this.updateBadgeTimeout = this.$timeout(() => this.stopLoading());
		this.loadZoneStockages();
	}

	async loadZoneStockages() {
		let idSiteCommercial = null;
		if (this.badge.sitesCommerciauxIds && this.badge.sitesCommerciauxIds.length == 1) {
			idSiteCommercial = this.badge.sitesCommerciauxIds[0];
		}
		try {
			this.zoneStockages = await this.BadgesService.getZonesStockage(idSiteCommercial);
		} catch (err) {
			this.zoneStockages = [];
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}

	async setTypeAheadAs(val) {
		if (!this.badge) {
			this.badge = {};
		}
		switch (val) {
			case 'transporteur':
				this.changeAlertDap();
				this.badge.idSiteTransporteur = this.transporteur.id;
				this.badge.nomSiteTransporteur = this.transporteur.libelle;
				break;
			case 'client':
				this.changeAlertDap();
				this.badge.idSiteClient = this.client.id;
				this.badge.nomSiteClient = this.client.libelle;
				this.getChantiers();
				this.getProduitsLies();
				break;
			case 'lieu':
				this.changeAlertDap();
				this.badge.idLieu = this.lieu.id;
				this.badge.nomLieu = this.lieu.libelle;
				this.badge.codeLieu = this.lieu.code;
				break;
			case 'commande':
				this.badge.idCommande = this.commande.id;
				this.badge.nomCommande = this.commande.libelle;
				this.getProduitsLies();
				break;
			case 'vehicule':
				this.badge.idVehicule = this.vehicule.id;
				this.badge.immatsVehicule = this.vehicule.immatriculations;
				if (this.vehicule.idTypeBenne) {
					this.badge.idTypeBenne = this.vehicule.idTypeBenne;
				}
				if (this.vehicule.idSiteTransport) {
					this.badge.idSiteTransporteur = this.vehicule.idSiteTransport;
					this.badge.nomSiteTransporteur = this.vehicule.siteTransport;
					this.transporteur = {
						id: this.vehicule.idSiteTransport,
						libelle: this.vehicule.siteTransport
					};
				}
				break;
			case 'chauffeur':
				this.badge.idChauffeur = this.chauffeur.id;
				this.badge.nomChauffeur = this.chauffeur.nomPrenom;
				break;
			case 'societe':
				this.badge.idSociete = this.societe.id;
				this.badge.nomSociete = this.societe.libelle;
				this.getSitesCommerciaux();
				this.getProduitsLies();
				break;
		}
	}

	videTransporteur() {
		this.transporteur = undefined;
		this.badge.idSiteTransporteur = undefined;
		this.badge.nomSiteTransporteur = undefined;
	}

	async getTransporteurs(valeur) {
		const transporteurs = await this.BadgesService.getTransporteurs(valeur);
		return transporteurs;
	}

	videSociete() {
		this.societe = undefined;
		this.badge.idSociete = undefined;
		this.badge.nomSociete = undefined;
	}

	async getSocietes(valeur) {
		const societes = await this.BadgesService.getSocietes(valeur);
		return societes;
	}

	async changeES() {
		this.getProduitsLies();
	}

	async changeAV() {
		this.getProduitsLies();
	}

	videClient() {
		this.client = undefined;
		this.badge.idSiteClient = undefined;
		this.badge.nomSiteClent = undefined;
		this.getChantiers();
	}

	async getClients(valeur) {
		const clients = await this.BadgesService.getClients(this.badge.idSociete, valeur);
		return clients;
	}

	videLieu() {
		this.lieu = undefined;
		this.badge.idLieu = undefined;
		this.badge.nomLieu = undefined;
	}

	async getLieux(valeur) {
		const lieux = await this.BadgesService.getLieux(valeur);
		return lieux;
	}

	videCommande() {
		this.commande = undefined;
		this.badge.idCommande = undefined;
		this.badge.nomCommande = undefined;
		this.getProduitsLies();
	}

	async getCommandes(valeur) {
		const commandes = await this.BadgesService.getCommandes(valeur, this.badge.chantiersIds);
		return commandes;
	}

	videVehicule() {
		this.vehicule = undefined;
		this.badge.idVehicule = undefined;
		this.badge.immatsVehicule = undefined;
	}

	async getVehicules(valeur) {
		const vehicules = await this.BadgesService.getVehicules(valeur);
		return vehicules;
	}

	videChauffeur() {
		this.chauffeur = undefined;
		this.badge.idChauffeur = undefined;
		this.badge.nomChauffeur = undefined;
	}

	async getChauffeurs(valeur) {
		const chauffeurs = await this.BadgesService.getChauffeurs(valeur);
		return chauffeurs;
	}

	setBadgeAsTypeAhead() {
		this.societe = {
			id: this.badge.idSociete,
			libelle: this.badge.nomSociete
		};
		this.transporteur = {
			id: this.badge.idSiteTransporteur,
			libelle: this.badge.nomSiteTransporteur
		};
		this.client = {
			id: this.badge.idSiteClient,
			libelle: this.badge.nomSiteClient
		};
		this.lieu = {
			id: this.badge.idLieu,
			code: this.badge.codeLieu,
			libelle: this.badge.nomLieu,
			codeEtLib: this.badge.idLieu ? this.badge.nomLieu + ' [' + this.badge.codeLieu + ']' : ''
		};
		this.commande = {
			id: this.badge.idCommande,
			code: this.badge.nomCommande,
			libelle: this.badge.nomCommande,
			codeLibelle: this.badge.nomCommande
		};
		this.vehicule = {
			id: this.badge.idVehicule,
			immatriculations: this.badge.immatsVehicule
		};
		this.chauffeur = {
			id: this.badge.idChauffeur,
			nomPrenom: this.badge.nomChauffeur
		};
	}

	async loadTypesES() {
		this.typesES = [];

		try {
			const typesES = this.BadgesService.getTypesES();
			angular.forEach(typesES, (type, id) => {
				this.typesES.push({
					idType: parseInt(id),
					label: this.$translate.instant(`BADGES.TYPES_ES.${id}`)
				});
			});
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async loadTypesAV() {
		this.typesAV = [];

		try {
			const typesAV = this.BadgesService.getTypesAV();
			angular.forEach(typesAV, (type, id) => {
				this.typesAV.push({
					idType: parseInt(id),
					label: this.$translate.instant(`BADGES.TYPES_AV.${id}`)
				});
			});
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async loadTypesTare() {
		this.typesTare = [];

		try {
			const typesTare = this.BadgesService.getTypesTare();
			angular.forEach(typesTare, (type, id) => {
				this.typesTare.push({
					idType: parseInt(id),
					label: this.$translate.instant(`BADGES.TYPES_TARE.${id}`)
				});
			});
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async getSitesCommerciaux() {
		this.startLoading();

		try {
			this.allSitesCommerciaux = await this.BadgesService.getSitesCommerciaux(this.badge.idSociete);
			if (this.badge && this.badge.sitesCommerciauxIds) {
				this.allSitesCommerciaux.forEach((item) => {
					if (this.badge.sitesCommerciauxIds.indexOf(item.id) !== -1) {
						item.selected = true;
					}
				});
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getChantiers() {
		this.allChantiers = [];
		if (this.badge.idSiteClient) {
			this.startLoading();
			try {
				this.allChantiers = await this.BadgesService.getChantiers(this.badge.idSiteClient);
				if (this.badge && this.badge.chantiersIds) {
					this.allChantiers.forEach((item) => {
						if (this.badge.chantiersIds.indexOf(item.id) !== -1) {
							item.selected = true;
						}
					});
				}
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				this.stopLoading();
			}
		}
	}

	async getProduitsLies() {
		if (
			this.badge.idSiteClient &&
			(this.badge.idCommande || this.badge.idSociete || (this.badge.sitesCommerciauxIds && this.badge.sitesCommerciauxIds.length == 1))
		) {
			const filtre = {
				clientId: this.badge.idSiteClient,
				entreeSortie: this.badge.typeEntreeSortie,
				achatVente: this.badge.typeAchatVente
			};

			if (this.badge.sitesCommerciauxIds && this.badge.sitesCommerciauxIds.length == 1) {
				filtre.siteCommercialId = this.badge.sitesCommerciauxIds[0];
			} else if (this.badge.idSociete) {
				filtre.societeId = this.badge.idSociete;
			}

			if (this.badge.chantiersIds && this.badge.chantiersIds.length == 1) {
				filtre.chantierId = this.badge.chantiersIds[0];
			}

			this.produitsSelectionnables = await this.BadgesService.getProduitsSelectionnables(this.badge.idCommande, filtre);
			let produitsATraiter = this.produitsSelectionnables.produits;
			if (this.badge.idCommande) {
				produitsATraiter = this.produitsSelectionnables;
			}
			this.produitsLies = await this.prepareListeElementsLies(produitsATraiter);
		} else {
			this.produitsLies = [];
		}
	}

	prepareListeElementsLies(elementsATraiter) {
		const elementsSelectionnablesOrdonnes = this.$filter('orderBy')(elementsATraiter, 'producteurLibelle');
		let tmp = null;
		const temp = [];
		for (let index = 0; index < elementsSelectionnablesOrdonnes.length; index++) {
			const currentValue = elementsSelectionnablesOrdonnes[index];
			const toReturn = [];
			//#region Gestion des groupes
			if (tmp == null) {
				//creation du premier groupe
				tmp = currentValue.producteurId;
				toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
			} else {
				if (tmp != currentValue.producteurId) {
					//création des autres groupes
					toReturn.push({ msGroup: false });
					tmp = currentValue.producteurId;
					toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
				}
			}
			//#endregion
			currentValue.libelle = currentValue.produitCode + ' - ' + currentValue.produitLibelle;
			currentValue.valeur = false;
			currentValue.id = currentValue.produitId;
			toReturn.push(currentValue);

			if (index == elementsSelectionnablesOrdonnes.length - 1) {
				toReturn.push({ msGroup: false });
			}
			temp.push(toReturn);
		}
		const sorties = _.flatten(temp);
		return new Promise((resolve) => resolve(sorties));
	}

	siteCommercialSelected() {
		this.getProduitsLies();
	}

	async produitSelected() {
		await this.getNewDocumentsCE();
		if (this.newProduit[0].codeFamille == 'INER') {
			await this.getNomenclatures(this.newProduit[0].produitId);
		}
	}

	async nomenclatureSelected() {
		await this.getDap(this.newProduit[0].nomenclature.id, this.newProduit[0].producteurId);
	}

	async getNewDocumentsCE() {
		let idProduit = 0;
		let idProducteur = 0;
		if (this.newProduit) {
			idProduit = this.newProduit[0].produitId;
			idProducteur = this.newProduit[0].producteurId;
		} else {
			this.newDocsCE = [];
		}
		try {
			this.newDocsCE = await this.BadgesService.getDocumentsCE(idProduit, idProducteur);
		} catch (err) {
			this.newDocsCE = [];
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}

	async getNomenclatures(produitId) {
		this.nomenclatures = await this.BadgesService.getNomenclatures(produitId);
	}

	async checkCodeUnicity(code) {
		if (code) {
			this.startCodeLoading();

			try {
				if (code.match(/^[a-zA-Z0-9_|]*$/)) {
					this.badge.codeExists = await this.BadgesService.codeExists(code);
				}
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				this.stopCodeLoading();
			}
		} else {
			this.badge.codeExists = null;
		}
	}

	ajouterLigne() {
		this.newLigne = true;
	}

	supprimerToutesLignes() {
		this.badge.produits.splice(0, this.badge.produits.length);
	}

	removeNewLigne() {
		this.newLigne = false;
	}

	async addProduit(newLigne) {
		if (this.newProduit && this.newProduit[0]) {
			let trouve = false;
			if (this.badge.produits) {
				for (let ind = 0; ind < this.badge.produits.length; ind++) {
					if (
						this.badge.produits[ind].idProduit == this.newProduit[0].produitId &&
						this.badge.produits[ind].idSiteProducteur == this.newProduit[0].producteurId
					) {
						trouve = true;
					}
				}
			}
			if (trouve) {
				this.notification.error('BADGES.PRODUIT_EXISTANT');
				return false;
			}

			const newProd = {
				idBadgeProduit: -1,
				idProduit: this.newProduit[0].produitId,
				codeProduit: this.newProduit[0].produitCode,
				libelleProduit: this.newProduit[0].produitLibelle,
				idSiteProducteur: this.newProduit[0].producteurId,
				libelleSiteProducteur: this.newProduit[0].producteurLibelle,
				idZoneStockage: this.newZone,
				zones: this.zoneStockages,
				idNomenclature: this.newProduit[0].nomenclature?.id,
				libelleNomenclature: this.newProduit[0].nomenclature?.libelle,
				idDap: this.newProduit[0].dap?.id,
				libelleDap: this.newProduit[0].dap?.libelle,
				codeFamille: this.newProduit[0].codeFamille
			};
			if (!this.badge.produits) {
				this.badge.produits = [];
			}
			this.badge.produits.push(newProd);
			this.newProduit.splice(0, 1);
			this.newDocCE = undefined;
			this.newQuantite = undefined;
			this.newZone = undefined;
			if (newLigne) {
				this.ajouterLigne();
			}
			this.getProduitsLies();
			return true;
		}

		if ((this.newProduit && this.newProduit[0]) || this.newZone /*|| this.newDocCE || this.newQuantite*/) {
			this.notification.error('BADGES.PRODUIT_OBLIGATOIRE');
			return false;
		}
		return true; //pas de nouvelle ligne à créer
	}

	removeProduit(prod) {
		const i = this.badge.produits.indexOf(prod);
		this.badge.produits.splice(i, 1);
	}

	async sauvegarder() {
		let id = false;
		let haveDap = false;
		for (let index = 0; index < this.badge.produits.length; index++) {
			const currentValue = this.badge.produits[index];
			if (currentValue.idDap) {
				haveDap = true;
				break;
			}
		}
		if (this.isAlertDap && haveDap) {
			const _this = this;
			const modalInstance = this.ModalService.confirm({
				modalTitle: this.$translate.instant('BADGES.ALERT_DAP'),
				modalMsg: this.$translate.instant('BADGES.ALERT_DAP_MESSAGE'),
				headerClass: 'modal-danger'
			});
			modalInstance.result.then(async function () {
				for (let index = 0; index < _this.badge.produits.length; index++) {
					const currentValue = _this.badge.produits[index];
					currentValue.idDap = null;
					currentValue.libelleDap = '';
				}
				id = _this.sauvegarderBadge();
			});
		} else {
			id = this.sauvegarderBadge();
		}
		return id;
	}

	async sauvegarderBadge() {
		let continuer = true;
		if (this.newLigne || !this.badge.produits || this.badge.produits.length == 0) {
			if (!(await this.addProduit(false))) {
				continuer = false;
			}
		}
		if (continuer && this.checkValidity()) {
			this.badge.dateTare = this.moment(this.badge.dateTare, this.dateFormat + ' HH:mm').format(this.dateFormat);
			if (this.badge.dateTare && !this.badge.heureTare) {
				this.badge.heureTare = '00:00';
			}
			this.badge.dateTare = this.badge.dateTare + ' ' + this.moment(this.badge.heureTare, 'HH:mm').format('HH:mm');
			this.startLoading();
			try {
				let id = false;
				if (this.badge.id) {
					await this.BadgesService.updateBadge(this.badge);
					this.notification.success('BADGES.UPDATED');
					id = this.badge.id;
				} else {
					id = await this.BadgesService.createBadge(this.badge);
					this.notification.success('BADGES.CREATED');
				}

				return id;
			} catch (ex) {
				this.notification.error(ex.data);
				return false;
			} finally {
				this.stopLoading();
			}
		} else {
			return false;
		}
	}

	async appliquer() {
		const id = await this.sauvegarder();
		if (id) {
			this.newLigne = false;
			this.$state.go('badges.edit', { id: id });
		}
	}

	async confirmer() {
		const success = await this.sauvegarder();

		if (success) {
			this.annuler();
		}
	}

	annuler() {
		this.$state.go('badges.list');
	}

	checkValidity() {
		this.badge.dateTare = this.badge.dateTare == 'Invalid date 00:00' ? undefined : this.badge.dateTare;
		let validity = true;
		if (
			!this.badge.isValid() ||
			!this.badge.idVehicule ||
			/*!this.badge.idSiteClient ||*/
			!this.badge.idSociete ||
			!this.badge.sitesCommerciauxIds ||
			this.badge.sitesCommerciauxIds.length < 1 ||
			(this.badge.hasImpressionTicketChargement && this.badge.quantiteTicketChargement == undefined)
		) {
			validity = false;
			this.notification.error(this.$translate.instant('BADGES.CHAMPS_OBLIGATOIRES'));
		}
		return validity;
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}

	async siteCommercialClosed() {
		this.changeAlertDap();
		const filtre = {
			societeId: this.badge.idSociete
		};
		if (this.badge.sitesCommerciauxIds.length > 0) {
			filtre.siteCommercialId = this.badge.sitesCommerciauxIds;
		}
		data = await this.BadgesService.getParamImpressionTicketChargement('impressionTickCharg', filtre);
		//Il suffit qu'un seul site commercial ait le tag d'impression ticket chargement pour toggle le bool à true
		const tab_elem = new Array();
		data.elements.forEach((x) => (x.entreeSortie == 0 ? tab_elem.push(x.valeur) : 0));
		this.badge.hasImpressionTicketChargement = tab_elem.includes('true') ? true : false;
	}

	async getDap(idNomenclature, idSiteProducteur) {
		//this.startLoading();
		try {
			this.daps = await this.BadgesService.getDap(
				this.badge.sitesCommerciauxIds[0],
				this.badge.idSiteTransporteur,
				this.badge.idSiteClient,
				this.badge.chantiersIds[0],
				this.badge.idLieu,
				idNomenclature,
				idSiteProducteur
			);
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			//this.stopLoading();
		}
	}

	async activeEditMode(prod) {
		prod.isEditMode = true;
		this.newLigne = null;
		if (prod.codeFamille == 'INER') {
			await this.getNomenclatures(prod.idProduit);
			await this.getDap(prod.idNomenclature, prod.idSiteProducteur);
		}
	}

	async nomenclatureUpdateSelected(prod) {
		await this.getDap(prod.idNomenclature, prod.idSiteProducteur);
		prod.idDap = null;
		prod.libelleDap = '';
	}

	desactiveEditMode(prod) {
		if (prod.idZoneStockage && this.zoneStockages.length > 0) {
			prod.libelleZoneStockage = this.zoneStockages.find((x) => x.id == prod.idZoneStockage).libelle;
		} else {
			prod.libelleZoneStockage = '';
		}
		if (prod.idNomenclature && this.nomenclatures.length > 0) {
			prod.libelleNomenclature = this.nomenclatures.find((x) => x.id == prod.idNomenclature).libelle;
		} else {
			prod.libelleNomenclature = '';
		}
		if (prod.idDap && this.daps.length > 0) {
			prod.libelleDap = this.daps.find((x) => x.id == prod.idDap).libelle;
		} else {
			prod.libelleDap = '';
		}
		prod.isEditMode = false;
	}

	changeAlertDap() {
		this.isAlertDap = true;
	}
}
