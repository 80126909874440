export default class ImportBLFormController {
    static $inject = [
        '_',
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'ModalService',
        'notification',
        'MassiaApplicationService',
        'ImportBlService',
        'localStorageService',
        'AuthConstant',
        '$uibModal',
        'moment'
    ];

    constructor(
        _,
        $scope,
        $timeout,
        $state,
        $stateParams,
        ModalService,
        notification,
        MassiaApplicationService,
        ImportBlService,
        localStorageService,
        AuthConstant,
        $uibModal,
        moment
    ) {
        this._ = _;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
        this.notification = notification;
        this.MassiaApplicationService = MassiaApplicationService;
        this.ImportBlService = ImportBlService;
        this.localStorageService = localStorageService;
        this.AuthConstant = AuthConstant;
        this.moment = moment;
        this.ongletOpen = {
            isEnteteOpen: true,
            isHoraireOpen: false,
            isAffectationOpen: false,
            isCaracteristiqueOpen: false
        };
    }

    async $onInit() {
        this.importBL = {};
        this.sitesCommerciaux = [];
        this.siteBLs = [];
        this.siteClients = [];
        this.userData = {};
        this.siteSociete = {};
        this.loading = false;
        this.showTypeBL = false;
        this.buttonNoValide = true;
        this.showNotification = false;
        await this.getSitesByUser();
    }

    /*********** get sites commerciaux with BL import  *************/
    async getSitesCommerciauxWithBl() {
        this.startLoading();
        const listSites = await this.getSitesByUser();
        for (let i = 0; i < listSites.length; i++) {
            const listBl = await this.getSiteImportBL(listSites[i].id);
            //console.length(listeBL);
            if (listBl.length > 0) {
                this.sitesCommerciaux.push(listSites[i]);
            }
        }
    }

    /*********** get sites commerciaux by user connecté  *************/
    async getSitesByUser() {
        this.startLoading();
        // récupérer data du user connecté
        const userData = await this.localStorageService.get(this.AuthConstant.MASSIA_USER_DATA_STORAGE);
        if (userData.id) {
            try {
                this.sitesCommerciaux = await this.ImportBlService.getSitesByUser();
            } catch (ex) {
                this.notification.error(ex.data);
            }
        }
        this.stopLoading();
        // Si l’utilisateur n’a accès qu’à un site commercial
        if (this.sitesCommerciaux.length == 1) {
            this.importBL.siteCommercialId = this.sitesCommerciaux[0].id;
            this.getSiteBLWithLibelle();
        }
        // console.length(this.sitesCommerciaux);
        //return sitesCommerciaux;
    }

    /*********** get type import BL by site commercial  *************/
    // recuperer les libelles des BL déjà ensegistrées dans BDD par utillisateur
    async getSiteBLWithLibelle() {
        this.startLoading();
        this.showTypeBL = false;
        this.importBL.typeImportBL = null;
        this.importBL.isClientSelected = null;
        this.importBL.isPeriodeSelected = null;
        const listeBL = await this.getAllImportBL();
        const siteBLs = await this.getSiteImportBL(this.importBL.siteCommercialId);
        // liste des BL dans ENUM
        for (var i = 0; i < listeBL.length; i++) {
            _.map(siteBLs, function (o) {
                if (o.typeImportBL == listeBL[i].id) {
                    o.libelle = listeBL[i].libelle;
                }
            });
        }
        this.siteBLs = angular.copy(siteBLs);
        // quand user a selectionné un site commercial
        if (this.siteBLs.length >= 1) {
            this.showTypeBL = true;
        }
        // si user n'a qu'un type de BL importé qui sera la valeur par défault
        if (this.siteBLs.length == 1) {
            this.importBL.typeImportBL = this.siteBLs[0].typeImportBL;
            this.importBL.isClientSelected = this.siteBLs[0].isClientSelected;
            this.importBL.isPeriodeSelected = this.siteBLs[0].isPeriodeSelected;
        } // en cas de plusieurs type BL importés, je les initialise en null
        else if (this.siteBLs.length == 0) {
            this.showNotification = true;
            this.importBL.typeImportBL = null;
            this.importBL.isClientSelected = null;
            this.importBL.isPeriodeSelected = null;
        }
        if (this.importBL.typeImportBL && this.importBL.siteCommercialId) {
            this.getsiteClient();
        }
        this.validationImportBL();
        this.stopLoading();
    }

    // liste (Objets) de toutes les modèles de BL externes dans assets/enum/sites/importBLExterne.json
    async getAllImportBL() {
        try {
            var listeBL = await this.ImportBlService.getAllImportBLExterne();
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
        return listeBL;
    }

    async getSiteImportBL(siteCommercialId) {
        //[ids]
        let listeTypeBls = [];
        this.startLoading();
        if (siteCommercialId) {
            try {
                listeTypeBls = await this.ImportBlService.getSiteBLs(siteCommercialId); // [ids]
            } catch (ex) {
                this.notification.error(ex.data);
            }
        }
        this.stopLoading();
        if (listeTypeBls.length == 0) {
            this.showNotification = true;
        }
        return angular.copy(listeTypeBls);
    }

    /*********** get options by type import BL  *************/
    getOptions() {
        const importBL = this.importBL;
        if (importBL.typeImportBL) {
            _.map(this.siteBLs, function (o) {
                if (o.typeImportBL == importBL.typeImportBL) {
                    importBL.isClientSelected = o.isClientSelected;
                    importBL.isPeriodeSelected = o.isPeriodeSelected;
                }
            });
        }
        this.importBL = importBL;
        this.getsiteClient();
        //initialiser les valeurs par rapport tyep import BL
        this.importBL.siteClientId = null;
        this.importBL.siteClientLibelle = null;
        this.importBL.dateDebut = null;
        this.importBL.dateFin = null;
        this.validationImportBL();
    }

    async getsiteClient() {
        this.startLoading();
        //recuperer la société (id) que Site Comercial est attachée
        try {
            this.siteSociete = await this.ImportBlService.getSiteByIdWithIdSociete(this.importBL.siteCommercialId);
        } catch (ex) {
            this.notification.error(ex.data);
        }
        const idSociete = angular.copy(this.siteSociete.idSociete);
        //console.log(idSociete);
        // recuperer les clients qui ont les comptes ouverts de la société
        //TODOS : la liste est longue à recupere et n'affiche pas entiere (100/1800 en BDD)
        if (idSociete) {
            try {
                this.siteClients = await this.ImportBlService.getSiteCompteOuverteBySociete(idSociete);
                this.importBL.idSociete = idSociete;
            } catch (ex) {
                this.notification.error(ex.data);
            }
        } else {
            this.siteClients = [];
        }
        this.validationImportBL();
        this.stopLoading();
    }

    saveSelectedClients(data) {
        if (data) {
            this.importBL.siteClientId = data.id;
            this.importBL.siteClientLibelle = data.libelle;
        } else {
            this.importBL.siteClientId = null;
            this.importBL.siteClientLibelle = null;
        }
        this.validationImportBL();
    }

    validationImportBL() {
        this.startLoading();
        this.buttonNoValide = true;
        if (this.importBL.siteCommercialId && this.importBL.typeImportBL) {
            if (!this.importBL.isClientSelected && !this.importBL.isPeriodeSelected) {
                this.buttonNoValide = false;
            } else if (
                this.importBL.isClientSelected &&
                this.importBL.isPeriodeSelected &&
                this.importBL.siteClientId != null &&
                this.importBL.dateDebut != null &&
                this.importBL.dateFin != null
            ) {
                this.buttonNoValide = false;
            } else if (this.importBL.isClientSelected && this.importBL.siteClientId != null && !this.importBL.isPeriodeSelected) {
                this.buttonNoValide = false;
            } else if (
                !this.importBL.isClientSelected &&
                this.importBL.isPeriodeSelected &&
                this.importBL.dateDebut != null &&
                this.importBL.dateFin != null
            ) {
                this.buttonNoValide = false;
            }
        }
        this.stopLoading();
    }
    // Modal pour upload le fichier BL externe
    getBlFichier(size) {
        this.importBL = angular.copy(this.importBL);

        const vm = this;
        this.$uibModal
            .open({
                template: '<import-bl-fichier-popup modal-instance="$ctrl.uibModalInstance" import-bl="$ctrl.importBl"></import-bl-fichier-popup>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.importBl = vm.importBL;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: size
            })
            .result.then(
                function (result) {},
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
