export default function UserAddRequestRoute($stateProvider) {
    $stateProvider
        .state('portail.user-add-request', {
            url: '/user-request',
            abstract: true,
            template: '<ui-view></ui-view>',
            ncyBreadcrumb: {
                skip: true
            }
        })
        .state('portail.user-add-request.list', {
            url: '/liste',
            rights: { domain: 'portail', right: 'read' },
            template: '<portail-user-add-request-list></portail-user-add-request-list>',
            ncyBreadcrumb: {
                skip: true
            }
        });
}

UserAddRequestRoute.$inject = ['$stateProvider'];
