import validatorBuilder from 'validator-builder';

let excelModalValidator = null;
let excelFormValidator = null;

export default class ExcelModal {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.activ = data.activ || true;
        this.libelle = data.libelle;
        this.champs = data.champs || null;
        this.pour = data.pour || null;
        this.cell = data.cell || null;
        this.orient = data.orient || 'horiz';
        this.pages = data.pages || 1;
        this.codePrelevement = data.codePrelevement;
        this.idPrelevement = data.idPrelevement;
        this.codeSousEssai = data.codeSousEssai;
        this.idSousEssai = data.idSousEssai;
        this.idEssai = data.idEssai;
        this.libelleEssai = data.libelleEssai;
        this.codeSousEssai = data.codeSousEssai;
        this.libelleSousEssai = data.libelleSousEssai;
        this.libelleConfig = data.libelleConfig;
        this.codeConfig = data.codeConfig;
        this.idConfig = data.idConfig;
    }

    isValid() {
        excelModalValidator = excelModalValidator || validatorBuilder.getInstanceFor('ExcelModal');
        const validationResults = excelModalValidator.validate(this);
        return validationResults.isValid;
    }
}

export class ExcelForm {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code || new Date().getTime();
        this.libelle = data.libelle;
        this.idTemplate = data.idTemplate;
        this.libelleTemplate = data.libelleTemplate;
    }

    isValid() {
        excelFormValidator = excelFormValidator || validatorBuilder.getInstanceFor('ExcelForm');
        const validationResults = excelFormValidator.validate(this);
        return validationResults.isValid;
    }
}
