const naturesByValue = {
    0: 'textecourt',
    1: 'textelong',
    2: 'date',
    3: 'numerique',
    4: 'booleen',
    5: 'elementliste',
    8: 'granulo',
    10: 'essaigenerique',
    /*11: 'essaiiteratif',*/
    12: 'excel',
    60: 'ensembletextecourt',
    61: 'ensembledates',
    62: 'ensemblenumerique',
    63: 'ensembleelementliste',
    70: 'undefined',
    71: 'representationgraphique'
};

const naturesValuesByName = {
    textecourt: 0,
    textelong: 1,
    date: 2,
    numerique: 3,
    booleen: 4,
    elementliste: 5,
    granulo: 8,
    essaigenerique: 10,
    /*'essaiiteratif': 11,*/
    excel: 12,
    ensembletextecourt: 60,
    ensembledates: 61,
    ensemblenumerique: 62,
    ensembleelementliste: 63,
    undefined: 70,
    representationgraphique: 71
};

export function getNatureNamesByValues() {
    return angular.copy(naturesByValue);
}

export function getNatureNamesWithValues() {
    return angular.copy(naturesValuesByName);
}
