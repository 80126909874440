import NomenclatureFormEnteteController from './nomenclature.form.entete.controller';

export default {
	bindings: {
		nomenclature: '<',
		form: '=',
		onUpdate: '&'
	},
	template: require('./nomenclature.form.entete.html'),
	controller: NomenclatureFormEnteteController
};
