VehiculeEnteteValidator.$inject = ['validator'];

export default function VehiculeEnteteValidator(validator) {
    const instance = new validator();

    instance.ruleFor('typeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    //instance.ruleFor('objetImmatBenne.id').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;
}
