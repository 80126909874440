import validatorBuilder from 'validator-builder';

export default class AffectationPostePeseeController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'AffectationPostePeseeService',
        'SitesService',
        'notification',
        '$location',
        'PaginationService',
        '$translate'
    ];

    constructor($scope, $state, $stateParams, AffectationPostePeseeService, SitesService, notification, $location, PaginationService, $translate) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.AffectationPostePeseeService = AffectationPostePeseeService;
        this.SitesService = SitesService;
        this.notification = notification;
        this.$location = $location;
        this.PaginationService = PaginationService;
        this.$translate = $translate;
    }

    async $onInit() {
        this.mainAttestation = {};

        await this.reset();
    }

    async loadAffectations() {
        this.affectations = await this.AffectationPostePeseeService.getAffectations();
        this.affectations = this.affectations || [];
    }

    async loadSitesCommerciaux(tableState) {
        this.startLoading();
        try {
            const filters = this.PaginationService.getFilters(this.previousTableState);
            const sorts = this.PaginationService.getSorts(this.previousTableState);
            const pagination = this.PaginationService.getPagination(this.previousTableState);
            pagination.take = 0;

            const data = await this.SitesService.getSitesCommerciaux(filters, sorts, pagination);
            this.sites = data.items;

            this.tmpSites = [];
            this.societes = [];
            this.sites.forEach((s) => {
                if (s.societeId) {
                    const societe = this.societes.find((e) => e.id === s.societeId);
                    if (!societe) {
                        this.societes.push({ id: s.societeId, libelle: s.societe });
                    }
                }
            });
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    refreshSites(affectation) {
        this.tmpSites = angular.copy(this.sites).filter((e) => e.societeId === affectation.idSociete);

        //On peut enregistrer plusieurs code poste pour un même site
        // const lstAffectationsSites = this.affectations.filter(e => !e.isEdit).map(e => e.idSite);
        // this.tmpSites = this.tmpSites.filter(e => lstAffectationsSites.indexOf(e.id) < 0);
    }

    async addAffectation() {
        const affectation = angular.copy(this.mainAttestation);
        if (this.checkValidity(affectation)) {
            affectation.libelleSociete = this.societes.find((e) => e.id === affectation.idSociete).libelle;
            affectation.libelleSite = this.tmpSites.find((e) => e.id === affectation.idSite).libelle;
            this.affectations.push(affectation);

            await this.sauvegarder(affectation);

            this.mainAttestation = {};
        }
    }

    editAffectation(index) {
        const affectation = this.affectations[index];
        if (affectation) {
            affectation.isEdit = true;
            this.refreshSites(affectation);
        }
    }

    async saveAffectation(index) {
        const affectation = this.affectations[index];

        if (this.checkValidity(affectation)) {
            affectation.libelleSociete = this.societes.find((e) => e.id === affectation.idSociete).libelle;
            affectation.libelleSite = this.tmpSites.find((e) => e.id === affectation.idSite).libelle;
            delete affectation.isEdit;

            await this.sauvegarder(affectation);
        }
    }

    async deleteAffectation(index) {
        const affectation = this.affectations[index];
        affectation.isDelete = true;

        await this.sauvegarder(affectation);
    }

    checkValidity(affectation) {
        this.affectationPostePeseeValidator = this.affectationPostePeseeValidator || validatorBuilder.getInstanceFor('AffectationPostePesee');
        const validationResults = this.affectationPostePeseeValidator.validate(affectation);
        return validationResults.isValid;
    }

    async sauvegarder(affectation) {
        this.startLoading();
        try {
            await this.AffectationPostePeseeService.setAffectation(affectation);
            this.notification.success('AFFECTATION_POSTE_PESEE.CREATED');
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        } finally {
            await this.loadAffectations();
            this.stopLoading();
        }
    }

    async reset() {
        this.startLoading();
        try {
            await this.loadAffectations();
            await this.loadSitesCommerciaux();
        } catch (ex) {
            this.notification.error(ex.data);
        }
        this.stopLoading();
    }

    annuler() {
        this.$state.go('affectation-poste-pesee.edit');
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
