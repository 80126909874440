import SettingRoute from './_config/portail.setting.routes';
import settingCompo from './components';
import settingMail from './components/setting-mail';
import settingServer from './components/setting-server';
import settingDoc from './components/setting-doc';
import settingDisplay from './components/setting-display';
import settingDocModal from './components/setting-doc/modal-info-format';
import previewLogin from './components/modales/preview-login';
import previewHome from './components/modales/preview-home';

const moduleName = 'app.massia.common.portail.setting';

angular
    .module(moduleName, [])
    .config(SettingRoute)
    .component('portailSetting', settingCompo)
    .component('portailSettingMail', settingMail)
    .component('portailSettingDoc', settingDoc)
    .component('portailSettingServer', settingServer)
    .component('portailSettingInfoFormat', settingDocModal)
    .component('portailSettingDisplay', settingDisplay)
    .component('previewLogin', previewLogin)
    .component('previewHome', previewHome);
export default moduleName;
