import CarnetsConfig from './_config/carnets.config';
import CarnetsRoutes from './_config/carnets.routes';
import CarnetsComponent from './components/carnets';
import CarnetsService from './services/carnets.service';
import CarnetsCommunicationService from './services/carnets.communication.service';

import CarnetFormComponent from './components/carnet-form';
import CarnetDetailComponent from './components/carnet-detail';
import CarnetPaiementComponent from './components/carnet-paiement';

import CarnetValidator from './components/carnet-form/carnet.validator';

const moduleName = 'app.massia.gestion.carnets';

angular
    .module(moduleName, [])
    .config(CarnetsConfig)
    .config(CarnetsRoutes)
    .service('CarnetsService', CarnetsService)
    .service('CarnetsCommunicationService', CarnetsCommunicationService)
    .component('carnets', CarnetsComponent)
    .component('carnetForm', CarnetFormComponent)
    .component('carnetDetail', CarnetDetailComponent)
    .component('carnetPaiement', CarnetPaiementComponent)
    .factory('CarnetValidator', CarnetValidator);

export default moduleName;
