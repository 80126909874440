export default class ModeleSaisieDetailController {
    static $inject = [
        '$stateParams',
        '$state',
        'ModelesSaisiesService',
        'notification',
        'ModelesSaisiesCommunicationService',
        'MassiaApplicationService'
    ];

    constructor($stateParams, $state, ModelesSaisiesService, notification, ModelesSaisiesCommunicationService, MassiaApplicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.ModelesSaisiesService = ModelesSaisiesService;
        this.notification = notification;
        this.ModelesSaisiesCommunicationService = ModelesSaisiesCommunicationService;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        this.loading = false;
        this.modele = {};

        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.initModele();
    }

    getRouteCourante() {
        if (this.$state.current.name === 'modelessaisies.list' || this.$state.current.name === 'modelessaisies.list.detail') {
            return 'modelessaisies';
        } else if (this.$state.current.name === 'modelessaisiese.list' || this.$state.current.name === 'modelessaisiese.list.detail') {
            return 'modelessaisiese';
        } else if (this.$state.current.name === 'modelessaisiesp.list' || this.$state.current.name === 'modelessaisiesp.list.detail') {
            return 'modelessaisiesp';
        } else if (this.$state.current.name === 'modelessaisiesm.list' || this.$state.current.name === 'modelessaisiesm.list.detail') {
            return 'modelessaisiesm';
        }
    }

    async initModele() {
        this.afficheCode = await this.ModelesSaisiesService.afficheCode(this.getRouteCourante());
        this.modele = { id: this.$stateParams.id };
        if (this.modele.id) {
            this.startLoading();
            try {
                this.modele = await this.ModelesSaisiesService.getModeleById(this.modele.id);
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListModele();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListModele();
        }
    }

    getApplication() {
        return this.MassiaApplicationService.getApplication();
    }

    goListModele() {
        this.$state.go(this.getRouteCourante() + '.list', {}, { reload: true });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
