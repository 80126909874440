export default class StockClotureJourPopupController {
    static $inject = [
        '$scope',
        '$stateParams',
        'StocksCloturesService',
        'notification',
        'ModalService',
        '$uibModal',
        'moment',
        '$translate',
        'globalizationManagementService'
    ];

    constructor(
        $scope,
        $stateParams,
        StocksCloturesService,
        notification,
        ModalService,
        $uibModal,
        moment,
        $translate,
        globalizationManagementService
    ) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.StocksCloturesService = StocksCloturesService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
        this.moment = moment;
        this.$translate = $translate;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    setWatchers() {
        const _this = this;
        this.$scope.$watch('$ctrl.date', function (newValue, oldValue) {
            _this.verifieCloture();
        });
        this.$scope.$watch('$ctrl.siteSearch', function (newValue, oldValue) {
            _this.verifieCloture();
        });
    }

    annuler() {
        this.modalInstance.close();
    }

    async appliquer() {
        if (this.checkDateValidation()) {
            const success = await this.modalToSave();
            if (success) {
                await this.getDerniereCloture();
            }
        } else {
            this.notification.error('STOCKS.DATE_CLOTURE_ERROR');
        }
    }

    async confirmer() {
        if (this.checkDateValidation()) {
            const success = await this.modalToSave();
            if (success) {
                this.annuler();
            }
        } else {
            this.notification.error('STOCKS.DATE_CLOTURE_ERROR');
        }
    }

    async $onInit() {
        this.isCloture = false;
        this.setWatchers();
        const siteInit = await this.StocksCloturesService.getSitesCommerciaux(null);
        if (siteInit.length == 1) {
            this.siteSearch = {
                id: siteInit[0].id,
                libelle: siteInit[0].libelle,
                code: siteInit[0].code
            };
        }
    }

    async getSites(valeur) {
        const sites = await this.StocksCloturesService.getSitesCommerciaux(valeur);
        return sites;
    }

    async verifieCloture() {
        if (this.date.indexOf('/') > -1) {
            this.isCloture = false;
            try {
                const siteId = this.siteSearch ? this.siteSearch.id : null;
                if (siteId && this.date) {
                    this.isCloture = await this.StocksCloturesService.estCloture(siteId, this.date);
                    if (this.isCloture) {
                        this.derniereCloture = await this.StocksCloturesService.getClotureJour(siteId, this.date);
                    } else {
                        this.getDerniereCloture();
                    }
                } else {
                    this.getDerniereCloture();
                }
            } catch (ex) {
                this.notification.error(ex.data);
            }
        }
    }

    async modalToSave() {
        try {
            let modalToSaveReturn = false;
            const dateToCompare = this.moment(new Date()).format(this.dateFormat);

            if (this.date == dateToCompare) {
                const modalInstance = this.ModalService.confirm({
                    modalTitle: this.$translate.instant('STOCKS_CLOTURES.CLOTURE_JOURNALIERE.TITLE'),
                    modalMsg: this.$translate.instant('STOCKS_CLOTURES.CLOTURE_JOURNALIERE.MESSAGE'),
                    headerClass: 'modal-warning'
                });

                const _this = this;
                await modalInstance.result;
                modalToSaveReturn = await this.sauvegarder();
            } else {
                modalToSaveReturn = await this.sauvegarder();
            }
            return modalToSaveReturn;
        } catch (error) {}
    }

    async sauvegarder() {
        try {
            this.startLoading();
            const siteId = this.siteSearch ? this.siteSearch.id : null;
            if (siteId && this.date) {
                await this.StocksCloturesService.clotureJournaliere(siteId, this.date);
                this.notification.success('STOCKS_CLOTURES.CLOTURE_JOURNEE_EFFECTUEE');
                this.getDerniereCloture();
                return true;
            }

            this.notification.error('STOCKS_CLOTURES.CLOTURE_JOURNEE_PARAMETRE_MANQUANT');
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        } finally {
            this.stopLoading();
        }
    }

    async getDerniereCloture() {
        if (!this.isCloture) {
            const siteId = this.siteSearch ? this.siteSearch.id : null;
            if (siteId) {
                this.derniereCloture = await this.StocksCloturesService.getDerniereCloture(siteId);
            } else {
                this.derniereCloture = '';
            }
        }
    }

    checkDateValidation() {
        const maDate = new Date();
        const newDate = this.moment(maDate, this.dateFormat);

        const date = this.moment(this.date, this.dateFormat);

        let resultat = true;
        if (date > newDate) {
            resultat = false;
        }

        return resultat;
    }

    async decloturer() {
        try {
            this.startLoading();
            const siteId = this.siteSearch ? this.siteSearch.id : null;
            if (siteId && this.date) {
                await this.StocksCloturesService.decloturer(siteId, this.date);
                this.verifieCloture();
                this.notification.success('STOCKS_CLOTURES.DECLOTURE_JOURNEE_EFFECTUEE');
                this.annuler();
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
