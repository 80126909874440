(function (angular, undefined) {
    'use strict';

    angular.module('blocks.smart.table')
        .directive('stController', stController);

    stController.$inject = ['$log'];

    function stController($log) {
        return {
            require: 'stTable',
            link: function (scope, element, attr, ctrl) {
                var exposeOn = scope.$eval(attr.stController);

                if (!angular.isObject(exposeOn)) {
                    $log.warn('st-controller must be an empty object');
                } else {
                    angular.extend(exposeOn, ctrl);
                }
            }
        };
    }
})(angular);
