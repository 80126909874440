import ModificationTraceModalController from './modification.trace.modal.controller';

export default {
    bindings: {
        modalInstance: '=',
        resolve: '<',
        graphique: '='
    },
    template: require('./modification.trace.modal.html'),
    controller: ModificationTraceModalController
};
