export default function Configure($stateProvider) {
    $stateProvider.state('documents', {
        parent: 'common',
        url: '/documents',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('documents.list', {
        url: '/list',
        template: '<documents></documents>',
        rights: { domain: 'documents', right: 'read' },
        ncyBreadcrumb: {
            parent: 'documents',
            label: '{{ "DOCUMENTS.BREADCRUMBS.DOCUMENTS_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_docimg',
            translate: 'DOCUMENTS.BREADCRUMBS.DOCUMENTS_LIST',
            order: 16,
            navigationCls: 'menu-item-separator'
        },
        search: 'DOCUMENTS.BREADCRUMBS.DOCUMENTS_LIST'
    });

    $stateProvider.state('documents.list.detail', {
        url: '/{id}',
        template: '<document-detail></document-detail>',
        rights: { domain: 'documents', right: 'read' },
        ncyBreadcrumb: {
            parent: 'documents.list',
            label: '{{ "DOCUMENTS.BREADCRUMBS.DOCUMENT_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('documents.new', {
        url: '/new',
        template: '<document-form></document-form>',
        rights: { domain: 'documents', right: 'create' },
        ncyBreadcrumb: {
            parent: 'documents.list',
            label: '{{ "DOCUMENTS.BREADCRUMBS.DOCUMENT_NEW" | translate}}'
        },
        search: 'DOCUMENTS.BREADCRUMBS.DOCUMENT_NEW'
    });

    $stateProvider.state('documents.newForTemplate', {
        url: '/new/for-template/{idOffre}',
        template: '<document-form></document-form>',
        rights: { domain: 'documents', right: 'create' },
        ncyBreadcrumb: {
            parent: 'documents.list',
            label: '{{ "DOCUMENTS.BREADCRUMBS.DOCUMENT_NEW" | translate}}'
        },
        search: 'DOCUMENTS.BREADCRUMBS.DOCUMENT_NEW'
    });

    $stateProvider.state('documents.newDocument', {
        url: '/new/{idOrigine}/{domaine}/{theme}',
        template: '<document-form></document-form>',
        rights: { domain: 'documents', right: 'create' },
        ncyBreadcrumb: {
            parent: 'documents.list',
            label: '{{ "DOCUMENTS.BREADCRUMBS.DOCUMENT_NEW" | translate}}'
        }
    });

    $stateProvider.state('documents.newModalDocument', {
        url: '/new/{idOrigine}/{typeOrigine}',
        template: '<document-modal-form></document-modal-form>',
        rights: { domain: 'documents', right: 'create' },
        ncyBreadcrumb: {
            parent: 'documents.list',
            label: '{{ "DOCUMENTS.BREADCRUMBS.DOCUMENT_NEW" | translate}}'
        }
    });

    $stateProvider.state('documents.edit', {
        url: '/{id}/edit',
        template: '<document-form></document-form>',
        rights: { domain: 'documents', right: 'update' },
        ncyBreadcrumb: {
            parent: 'documents.list',
            label: '{{ "DOCUMENTS.BREADCRUMBS.DOCUMENT_EDIT" | translate }}'
        }
    });

    $stateProvider.state('documents.newType', {
        url: '/new-type',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        params: { parentState: 'documents.new' },
        ncyBreadcrumb: {
            parent: 'documents.new',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
