import * as angular from 'angular';
import CompoAddCoef from '../../../../models/CompoAddCoef.model';
import FormuleComposant from '../formule.composant.model';

export default class FormuleLaboCreationController {
	liste = [];
	pTotal = 0;
	qTotal = 0;
	totalPrix = 0;
	listNomImprime = [];

	/* @ngInject */
	constructor(
		$stateParams,
		FormuleService,
		$filter,
		notification,
		$uibModal,
		$scope,
		$rootScope,
		$translate,
		CoefficientkService,
		FormuleCalculsService,
		_,
		ProduitsService,
		ModalService,
		moment,
		Interpreteur
	) {
		this.params = $stateParams;
		this.FormuleService = FormuleService;
		this.$filter = $filter;
		this.notification = notification;
		this.$uibModal = $uibModal;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.$translate = $translate;
		this.CoefficientkService = CoefficientkService;
		this.FormuleCalculsService = FormuleCalculsService;
		this._ = _;
		this.ProduitsService = ProduitsService;
		this.ModalService = ModalService;
		this.moment = moment;
		this.Interpreteur = Interpreteur;
	}

	async $onInit() {
		this.loading = true;
		this.showDetailComposantEqiom = __configuration.licenceLaboratoire === '2';
		this.IdProduit = this.params.produitId;
		this.IdProducteur = this.params.producteurId;
		this.sortableOptions();
		this.GetElementSelectionable();
		this.valParamLiant = await this.FormuleCalculsService.getCaractParamProg('SelPctTotLiant');
		this.calculFormulePcentMassique = (await this.FormuleCalculsService.getCaractParamProg('BooGestFormMass')) === '1';
		this.valParamAbGran = await this.FormuleCalculsService.getCaractParamProg('EssAbGran');
		this.calculPourcentAdjuvant = await this.FormuleCalculsService.getCaractParamProg('SelPctTotLiant');
		if (!this.calculPourcentAdjuvant) {
			this.calculPourcentAdjuvant = '3';
		} //par défaut C+Add ou C seul si Add correctrice

		this.listeNomImprime = await this.getEnumImprimable();
		if (this.formule.composants && this.formule.composants.length > 0) {
			this.coefKComposition();
			this.checkIsInSquelette();
			this.GetRatioOfGran();
			this.TotalGran();
			this.TotalQuantity();
			this.TotalPoids();
			this.TotalVolume();
			this.ChangeWeight(this.formule.composants);
			this.formatValRef();
		} else if (!this.formule.composants) {
			this.formule.composants = [];
		}
		this.loading = false;
		console.log(this.formule);
	}

	formatValRef() {
		const valRef = this._.uniqBy(this._.flatten(this.formule.composants.map((x) => x.valeursDeRef)), 'codeEssai');
		this._.forEach(this.formule.composants, (value, key) => {
			const val = {};
			this._.forEach(value.valeursDeRef, (value) => {
				val[value.codeEssai] = value.valeur;
			});
			value.valeurDeRef = val;
		});
		this.valRef = valRef;
		this.getUniteQteEauAbs(valRef);
	}

	getUniteQteEauAbs(valRef) {
		this._.forEach(valRef, (value) => {
			if (value.codeEssai == this.valParamAbGran) {
				this.uniteQteEauAbs = value.symbolSousEssai;
			}
		});
	}

	async getEnumImprimable() {
		try {
			const res = await this.FormuleService.getImprimable();
			return res;
		} catch (error) {
			return this.notification.error(error);
		}
	}

	coefKComposition() {
		if (this.formule.idAddition && this.formule.coefficientK) {
			const idx = this.formule.composants.findIndex((x) => x.idComposant === this.formule.idAddition);
			if (idx > -1) {
				this.formule.composants[idx].coefk = this.formule.coefficientK;
			}
		}
	}

	$onDestroy() {}

	GetRatioOfGran() {
		this.formule.composants = this.formule.composants.map((e) => {
			if (e.codeFamille === 'GRAN') {
				e.ratio = Number.parseFloat(e.quantite) / 100;
				e.originalPoids = Number.parseFloat(angular.copy(e.poids));
				e.originalPercent = Number.parseFloat(angular.copy(e.quantite));
			}
			return e;
		});
	}

	async GetElementSelectionable() {
		try {
			this.removeGranFromList = false;
			let res = await this.FormuleService.getClientLabProduitsWithDate(this.IdProducteur, this.IdProduit, this.formule.dateFinValidite);

			res = await this.CheckGranInCompo(res);
			res = res.map((e) => {
				if (e.msGroup === null) {
					delete e.msGroup;
				}
				if (e.searchProperty === null) {
					delete e.searchProperty;
				}
				return e;
			});

			if (!this.formule.codeFamille) {
				const produit = await this.ProduitsService.getProduitEnteteById(this.IdProduit);

				this.liste = res.filter((x) =>
					produit.famille === 'BPE'
						? x.rootCode !== produit.famille && x.code !== produit.code
						: produit.famille === 'GRAN'
						? x.rootCode === produit.famille && x.rootCode !== 'BPE' && x.code !== produit.code
						: x.code !== produit.code
				);
			} else {
				this.liste = res.filter((x) =>
					this.formule.codeFamilleProduit === 'BPE'
						? x.rootCode !== this.formule.codeFamilleProduit && x.code !== this.formule.codeComposition
						: this.formule.codeFamilleProduit === 'GRAN'
						? x.rootCode === this.formule.codeFamilleProduit && x.rootCode !== 'BPE' && x.code !== this.formule.codeComposition
						: x.code !== this.formule.codeComposition
				);
			}
		} catch (err) {
			return this.notification.error(err);
		}
	}

	sortableOptions() {
		this.dragOptions = {
			placeholder: 'table-row-ui-placeholder',
			revert: true,
			animation: 200,
			axis: 'y',
			'ui-preserve-size': true
		};
	}

	checkIsInSquelette() {
		if (this.formule.squelette && this.formule.squelette.composants && this.formule.squelette.composants.length > 0) {
			const listId = this.formule.squelette.composants.map((e) => e.idElement);

			for (let i = 0; i < this.formule.composants.length; i++) {
				const element = this.formule.composants[i];
				if (listId.includes(element.idElement)) {
					element.isInSkeleton = true;
				}
			}
		}
	}

	async CheckGranInCompo(list) {
		if (this.formule.composants && this.formule.composants.length > 0) {
			const gran = this.formule.composants.filter((e) => e.codeFamille === 'GRAN');
			if (this.formule.squelette && this.formule.squelette.composants && this.formule.squelette.composants.length > 0) {
				list = this.RemoveGranFromList(list);
				this.removeGranFromList = true;
			} else if (gran.length > 0) {
				list = await this.RemoveSkeletonFromList(list);
			}
		}
		return list;
	}

	RemoveGranFromList(list) {
		list = list.filter((e) => e.rootCode !== 'GRAN' && e.produit?.familleCode !== 'GRAN');

		return list;
	}

	async AddComposant(composant) {
		const order = ['GRAN', 'CIM', 'ADD', 'ADJ', 'AJOUT', 'EAU'];

		if (!this.IsInCompo(composant.produit)) {
			if (composant.produit.familleCode === 'ADD') {
				const coefKActuel = null;

				/*   if (this.formule.compoAddCoef && this.formule.compoAddCoef.length > 0) {
                    coefKActuel = await this.modalCoefkChanged();
                } */

				let caracteristiqueTypeCiment = null;
				let caracteristiqueClasseCiment = null;
				if (this.formule && this.formule.composants && this.formule.composants.length > 0) {
					if (this.formule.composants.find((x) => x.codeFamille === 'CIM')) {
						const paramType = await this.FormuleCalculsService.getCaractParamProg('CarCimType');
						caracteristiqueTypeCiment = this.formule.composants
							.find((x) => x.codeFamille === 'CIM')
							.valeurCaracteristiques?.find((x) => x.code === paramType);

						const paramRes = await this.FormuleCalculsService.getCaractParamProg('CarCimRes');
						caracteristiqueClasseCiment = this.formule.composants
							.find((x) => x.codeFamille === 'CIM')
							.valeurCaracteristiques?.find((x) => x.code === paramRes);
					}
				}

				this.addComposantAddition(composant, caracteristiqueTypeCiment, caracteristiqueClasseCiment, coefKActuel);
			}

			if (composant.produit.familleCode === 'CIM') {
				if (this.formule.composants.find((x) => x.familleCode === 'ADD')) {
					const coefKActuel = await this.modalCoefkChanged();
					this.addComposantCiment(composant, coefKActuel);
				}
			}

			if (composant.produit.familleCode === 'GRAN' && composant.produit.isCompose) {
				const tmpSkeleton = await this.FormuleService.getFormuleBySquelette(composant.produit.produitId, composant.produit.producteurId);
				if (tmpSkeleton) {
					if (this.formule.composants.findIndex((c) => c.codeFamille === 'GRAN') > -1) {
						const res = await this.focus({ isInSkeleton: true }, 'FORMULES.VALID_RELATION.DELETE_GRAN');
						//Cancel adding if user doesn't want delete the current granulars
						if (!res) {
							return;
						}
					}
					this.formule.squelette = tmpSkeleton;
					for (let i = 0; i < tmpSkeleton.composants.length; i++) {
						const e = tmpSkeleton.composants[i];
						e.ratio = e.quantite / 100;
						this.AddComposantToList(e, true);
					}
				} else {
					this.AddComposantToList(composant.produit, false);
				}
			} else {
				this.AddComposantToList(composant.produit, false);
			}
		} else {
			this.notification.warning('FORMULES.COMPOSANTS.IS_IN_COMPO');
		}
		this.GetElementSelectionable();
		this.selectedComposant = [];
		this.liste = this.liste.map((e) => {
			e.valeur = false;
			return e;
		});
		this.TotalQuantity();
		this.TotalPoids();
		this.TotalVolume();
	}

	async AddComposantToList(composant, skeleton) {
		if (!this.formule.composants) {
			this.formule.composants = [];
		}

		const compo = new FormuleComposant(composant, skeleton);
		if (skeleton && compo) {
			compo.id = 0;
		}
		compo.listeNomImprime = angular.copy(this.listeNomImprime);
		// on recherche l'organisation des composants dans le tableau
		const lastIndexOfFamilly = this.formule.composants.findLastIndex((x) => x.codeFamille === compo.codeFamille);
		if (lastIndexOfFamilly > -1) {
			this.formule.composants.splice(lastIndexOfFamilly + 1, 0, compo);
		} else {
			const idxOfPreviousFamilly = await this.findPreviousFamilly(compo);

			this.formule.composants.splice(idxOfPreviousFamilly + 1, 0, compo);
		}
		this.formatValRef();
	}

	async findPreviousFamilly(composant) {
		// l'ordre d'ajout des composant de base est (GRAN,CIM, ADD, ADJ,AJOUT,EAU);

		let idx = -1;
		switch (composant.codeFamille) {
			case 'GRAN':
				return idx;
			case 'CIM':
				idx = this.formule.composants.findLastIndex((x) => x.codeFamille === 'GRAN');

				return idx;
			case 'ADD':
				idx = this.formule.composants.findLastIndex((x) => x.codeFamille === 'CIM');
				if (idx === -1) {
					idx = this.formule.composants.findLastIndex((x) => x.codeFamille === 'GRAN');
				}
				return idx;
			case 'ADJ':
				idx = this.formule.composants.findLastIndex((x) => x.codeFamille === 'ADD');
				if (idx === -1) {
					idx = this.formule.composants.findLastIndex((x) => x.codeFamille === 'CIM');
					if (idx === -1) {
						idx = this.formule.composants.findLastIndex((x) => x.codeFamille === 'GRAN');
					}
				}
				return idx;
			case 'AJOUT':
				idx = this.formule.composants.findLastIndex((x) => x.codeFamille === 'ADJ');
				if (idx === -1) {
					idx = this.formule.composants.findLastIndex((x) => x.codeFamille === 'ADD');
					if (idx === -1) {
						idx = this.formule.composants.findLastIndex((x) => x.codeFamille === 'CIM');
						if (idx === -1) {
							idx = this.formule.composants.findLastIndex((x) => x.codeFamille === 'GRAN');
						}
					}
				}

				return idx;
			case 'EAU':
				idx = this.formule.composants.length; /* findLastIndex(x => x.codeFamille === 'AJOUT')
                if (idx === -1) {
                    idx = this.formule.composants.findLastIndex(x => x.codeFamille === 'ADJ')
                    if (idx === -1) {
                        idx = this.formule.composants.findLastIndex(x => x.codeFamille === 'ADD')
                        if (idx === -1) {
                            idx = this.formule.composants.findLastIndex(x => x.codeFamille === 'CIM')
                            if (idx === -1) {
                                idx = this.formule.composants.findLastIndex(x => x.codeFamille === 'GRAN')
                            }
                        }
                    }
                } */
				return idx;
			default:
				return idx;
		}
	}

	addComposantAddition(composant, caracteristiqueTypeCiment, caracteristiqueClasseCiment, coefKActuel) {
		// recherche des composant Additions
		const add = this.formule.composants.filter((e) => e.codeFamille === 'ADD');
		const idx = add.findIndex((x) => x.selectCoef === true);
		// s'il n'y a pas de composant Addition dans la formule courante
		if (idx === -1) {
			//on selectionne par defaut l'addition
			composant.produit ? (composant.produit.selectCoef = false) : (composant.selectCoef = false);
		} else {
			// sinon on selectionne pas cette addition
			composant.produit ? (composant.produit.selectCoef = composant.produit.selectCoef) : (composant.selectCoef = composant.selectCoef);
		}

		//si une norme est selectionner on recupere le coefficientK correspondant
		if (this.formule.normeSpec && this.formule.normeSpec.id) {
			let coefK = null;

			const lstMatchCoeffK = composant.produit.coefficientK
				.filter((x) => x.normeSpecifique.id && this.formule.normeSpec.id && x.normeSpecifique.id === this.formule.normeSpec.id)
				.map((x) => {
					x.matchValue = 0;
					return x;
				});
			if (lstMatchCoeffK.length > 0) {
				const lstmatchCriteriaCoefKProp = {
					date: 'date',
					producteur: 'producteur.id',
					client: 'client.id',
					typeCiment: 'typeCiment.code',
					classResistance: 'classeResistanceCiment.code'
				};
				const objMatchFormule = {
					date: this.formule.dateFinValidite,
					producteur: composant.produit ? composant.produit.producteurId : composant.producteurId,
					client: this.formule.idProducteur,
					typeCiment: caracteristiqueTypeCiment?.value,
					classResistance: caracteristiqueClasseCiment?.value
				};

				for (const m in lstmatchCriteriaCoefKProp) {
					for (let c = lstMatchCoeffK.length - 1; c >= 0; c--) {
						const valFormule = objMatchFormule[m];
						if (valFormule || valFormule === 0) {
							const propCoef = lstmatchCriteriaCoefKProp[m];
							const valCoefK = this.getDeepValue(lstMatchCoeffK[c], propCoef);
							if (valCoefK || valCoefK === 0) {
								if (m === 'date') {
									const dtCoefK = this.moment(valCoefK).toDate();
									const dtFormule = this.moment(valFormule, 'DD/MM/YYYY HH:mm').toDate();

									if (dtFormule < dtCoefK) {
										lstMatchCoeffK.splice(c, 1);
									}
								} else if (valFormule != valCoefK) {
									lstMatchCoeffK.splice(c, 1);
								} else {
									lstMatchCoeffK[c].matchValue++;
								}
							}
						}
					}
				}

				coefK = lstMatchCoeffK.sort((a, b) => {
					return b.matchValue - a.matchValue;
				})[0];
			}
			if (coefK) {
				composant.produit ? (composant.produit.coefk = coefK.coefK) : (composant.coefk = coefK.coefK);
			} else {
				composant.produit ? (composant.produit.coefk = 0) : (composant.coefk = 0);
			}
		} else {
			if (coefKActuel && typeof composant.coefk === 'number') {
				//composant.produit ? (composant.produit.coefk = coefKActuel) : (composant.coefk = coefKActuel);
			} else {
				// si pas de norme CoefficientK = 0
				composant.produit ? (composant.produit.coefk = 0) : (composant.coefk = 0);
			}
		}

		let selectedCoef = true;
		if (this.formule.compoAddCoef && this.formule.compoAddCoef.length > 0 && this.formule.compoAddCoef.find((x) => x.selected)) {
			selectedCoef = false;
		}

		const data = new CompoAddCoef({
			idCompo: this.formule.idComposition,
			idAddition: composant.produit ? composant.produit.produitId : composant.idComposant,
			coefK: composant.produit ? composant.produit.coefk : composant.coefk,
			selected: selectedCoef
		});
		if (!this.formule.compoAddCoef) {
			this.formule.compoAddCoef = [];
			this.formule.compoAddCoef.push(data);
		} else {
			const idxk = this.formule.compoAddCoef.findIndex((x) =>
				x.idAddition === composant.produit ? composant.produit.produitId : composant.idComposant
			);
			if (idxk > -1) {
				this.formule.compoAddCoef[idxk] = data;
			} else {
				this.formule.compoAddCoef.push(data);
			}
		}
	}

	getDeepValue(obj, path) {
		const p = path.split('.');
		const len = p.length;
		for (let i = 0; i < len; i++) {
			if (obj) {
				obj = obj[p[i]];
			} else {
				return null;
			}
		}
		return obj;
	}

	async addComposantCiment(composant, coefKActuel) {
		let caracteristiqueTypeCiment = null;
		let caracteristiqueClasseCiment = null;

		if (composant) {
			// on enferme la cractereristique type du ciment
			const paramType = await this.FormuleCalculsService.getCaractParamProg('CarCimType');
			caracteristiqueTypeCiment = composant.produit.valeurCaracteristiques.find((x) => x.code === paramType);
			// on enferme la caracteristique type de classe de resistance du ciment
			const paramRes = await this.FormuleCalculsService.getCaractParamProg('CarCimRes');
			caracteristiqueClasseCiment = composant.produit.valeurCaracteristiques.find((x) => x.code === paramRes);
		}

		const composantAdd = this.formule.composants.find((x) => x.codeFamille === 'ADD');
		if (composantAdd && composantAdd.length > 0) {
			this.addComposantAddition(composantAdd, caracteristiqueTypeCiment, caracteristiqueClasseCiment, coefKActuel);
		}
	}

	async RemoveSkeletonFromList(list) {
		for (let i = 0; i < list.length; i++) {
			if (list[i].produit) {
				for (let j = 0; j < list[i].produit.length; j++) {
					if (list[i].produit[j].familleCode === 'GRAN' && list[i].produit[j].isCompose) {
						const tmp = await this.FormuleService.getFormuleBySquelette(list[i].produit[j].produitId, list[i].produit[j].producteurId);
						if (tmp) {
							list[i].produit.splice(j, 1);
							j--;
						}
					}
				}
			}
		}

		return list;
	}

	IsInCompo(composant) {
		if (this.formule.composants) {
			const index = this.formule.composants.findIndex(
				(e) => e.idComposant === composant.produitId && e.idProducteur === composant.producteurId
			);
			if (index > -1) {
				return true;
			}
		}

		return false;
	}

	async DeleteComposant(index) {
		try {
			const composant = this.formule.composants[index];
			if (composant.isInSkeleton) {
				//pop up lien squelette
				const res = await this.liaisonSqueletteGran();
				if (!res) {
					throw this.notification.warning('FORMULES.MODAL.SQUELETTE.KEEP');
				}
			}

			this.formule.composants.splice(index, 1);

			if (this.formule.compoAddCoef) {
				const idx = this.formule.compoAddCoef.findIndex((x) => x.idAddition === composant.idComposant);
				if (idx > -1) {
					this.formule.compoAddCoef.splice(idx, 1);
				}

				const tmp = angular.copy(this.formule.compoAddCoef);
				this.formule.compoAddCoef = tmp;
			}

			this.GetElementSelectionable();
			this.TotalQuantity();
			this.TotalPoids();
			this.TotalVolume();
		} catch (error) {
			console.error('delete', error);
		}
	}

	TotalQuantity() {
		const composants = this.formule.composants;
		if (this.formule.codeFamilleProduit === 'ENROB') {
			this.gTotal = composants.reduce((a, { quantite }) => a + (Number.parseFloat(quantite) || 0), 0);

			this.gTotal = Number.parseFloat(this.gTotal).toFixed(2);
			this.formule.pourcentageTotal = Number.parseFloat(this.gTotal);
		} else {
			this.qTotal = composants
				.filter(({ codeFamille }) => codeFamille !== 'ADJ')
				.reduce((a, { poids }) => a + (Number.parseFloat(poids) || 0), 0);

			this.gTotal = composants
				.filter(({ codeFamille }) => codeFamille === 'GRAN')
				.reduce((a, { quantite }) => a + (Number.parseFloat(quantite) || 0), 0);

			this.gTotal = Number.parseFloat(this.gTotal).toFixed(2);
			this.formule.pourcentageTotal = Number.parseFloat(this.gTotal);
		}
	}

	TotalPoids() {
		const composants = this.formule.composants;
		this.formule.totalPoids = composants.reduce((a, { poids }) => a + (Number.parseFloat(poids) || 0), 0);
		this.formule.totalPoids = this.formule.totalPoids.toFixed(2);
	}

	TotalVolume() {
		const composants = this.formule.composants;
		this.formule.totalVolume = composants.reduce((a, { volume }) => a + (Number.parseFloat(volume) || 0), 0);
		this.formule.totalVolume += Number.parseFloat(this.formule.volumeAir) || 0;
		this.formule.totalVolume = this.formule.totalVolume.toFixed(2);

		this.onVerifRemiseCube({ volume: parseFloat(this.formule.totalVolume) });
	}

	TotalGran() {
		const composants = this.formule.composants;
		this.gpTotal = composants
			.filter(({ codeFamille }) => codeFamille === 'GRAN')
			.reduce((a, { poids }) => a + (Number.parseFloat(poids) || 0), 0);
		this.gpTotal = this.gpTotal.toFixed(2);
		//calcul de la qté eau abs totale des granulats
		this.formule.totalAbsGran = composants
			.filter(({ codeFamille }) => codeFamille === 'GRAN')
			.reduce((a, { poidsAbs }) => a + (Number.parseFloat(poidsAbs) || 0), 0);
		this.formule.totalAbsGran = this.formule.totalAbsGran.toFixed(2);
	}

	TotalPrix() {
		const composants = this.formule.composants;
		this.totalPrix = composants
			.filter(({ prix }) => prix && (prix.valeur || prix.valeur === 0))
			.reduce((a, { prix, poids }) => a + (Number.parseFloat(prix.valeur) * poids) / 1000, 0);
		this.totalPrix = Number.parseFloat(this.totalPrix).toFixed(2);
	}

	async calculPoidsSelonVolume(composant) {
		if (composant.volume && composant.mvt) {
			composant.poids = (Number.parseFloat(composant.volume) * Number.parseFloat(composant.mvt)).toFixed(2);
		}

		if (composant.codeFamille === 'ADJ') {
			this.calculQuantiteAdjV2(composant);
		}
	}

	async ChangeWeight(compo, forcePcent = -1) {
		try {
			if (compo.isInSkeleton) {
				//pop up lien squelette
				const res = await this.liaisonSqueletteGran();

				if (!res) {
					compo.poids = compo.originalPoids;
					throw this.notification.warning('FORMULES.MODAL.SQUELETTE.REINIT');
				}
			}

			if (compo.codeFamille === 'ADJ') {
				this.calculQuantiteAdjV2(compo);
			}

			//recalculer les qtés d'eau absorbées
			if (compo.pourcAbs) {
				const absorption = (Number.parseFloat(compo.poids) * Number.parseFloat(compo.pourcAbs)) / 100;
				compo.poidsAbs = absorption.toFixed(2);
			}

			const lstGranComposants = this.formule.composants.filter((e) => e.codeFamille === 'GRAN');

			this.TotalGran();
			const totalPdsGran = Number.parseFloat(this.gpTotal) || 0;

			let volTotalGran = 0;
			if (compo.mvt) {
				// calcul du volume du composant en cours
				const volume = Number.parseFloat(compo.poids) / Number.parseFloat(compo.mvt);
				compo.volume = volume.toFixed(2);

				// si cest un granulats
				if (compo.codeFamille === 'GRAN') {
					// que l'on est en % MASSIQUE
					if (this.calculFormulePcentMassique) {
						// on calcul le % du composant par rapport au poids renseigner et au poids total des granulats
						compo.quantite = totalPdsGran > 0 ? ((Number.parseFloat(compo.poids) || 0) * 100) / totalPdsGran : '0';
					} else {
						// si on est en % VOLUMIQUE
						// on calcul le volume Total des granulats de la formules
						this._.forEach(this.formule.composants, (composantsGran) => {
							if (composantsGran.codeFamille === 'GRAN') {
								volTotalGran += Number.parseFloat(composantsGran.volume);
							}
						});

						// on calcul le % du composants en cours
						compo.quantite = (Number.parseFloat(compo.volume) * 100) / Number.parseFloat(volTotalGran);

						// si le composant est le seul granulats le % est de  100 %
						if (lstGranComposants.length <= 1) {
							compo.quantite = '100.0';
						}
					}
				}
			}

			// si on est dans une formule BETON et que les calcul sont automatiques
			if (this.formule.calculAuto && this.famille !== 'GRAN' && this.famille !== 'ENROB') {
				for (let i = 0; i < lstGranComposants.length; i++) {
					const e = lstGranComposants[i];
					// pour chaque composants granulat restant

					if (this.calculFormulePcentMassique) {
						// que l'on est en % MASSIQUE
						// on calcul le nouveau  poids par rapport au poids total des granulats
						e.quantite = totalPdsGran > 0 ? ((Number.parseFloat(e.poids) || 0) * 100) / totalPdsGran : '0';
						const newPds = Number.parseFloat(e.poids) || 0;
						// on calcul le nouveau volume par rapport au NewPoids calculer avant
						e.volume = newPds / Number.parseFloat(e.mvt);
					} else {
						if (compo.codeFamille === 'GRAN' && e.idComposant !== compo.idComposant) {
							// si on est en % VOLUMIQUE
							// on calcul le nouveau % de chaque composant par rapport a son volume (qui reste constant) et au volume total des granulats calculer plus haut
							const newQuantite = (Number.parseFloat(e.volume) * 100) / Number.parseFloat(volTotalGran);
							e.quantite = Number.parseFloat(newQuantite);
						}
					}
				}
			}

			//si modif de ciment ou addition, recalculer les quantités d'adjuvant en fonction du pourcentage
			if (compo.codeFamille === 'CIM' || compo.codeFamille === 'ADD') {
				const compoAdj = this.formule.composants.filter(({ codeFamille }) => codeFamille === 'ADJ');
				if (compoAdj && compoAdj.length > 0) {
					for (let i = 0; i < compoAdj.length; i++) {
						const composantAdj = compoAdj[i];
						this.calculPoidsAdjV2(composantAdj);
					}
				}
			}

			this.TotalPrix();
			this.TotalPoids();
			this.TotalVolume();
			this.TotalQuantity();
		} catch (error) {
			console.error('weight', error);
		}
	}

	async ChangePercent(compo) {
		try {
			if (compo.isInSkeleton) {
				//pop up lien squelette
				const res = await this.liaisonSqueletteGran();
				if (!res) {
					compo.quantite = compo.originalPercent;
					throw this.notification.warning('FORMULES.MODAL.SQUELETTE.REINIT');
				}
			}

			const othersGran = this.formule.composants.filter(
				(e) => e.codeFamille === 'GRAN' && e.idComposant !== compo.idComposant && (e.mvt || e.mvt == '0')
			);

			this.TotalQuantity();
			const ecartPcent = 100 - this.formule.pourcentageTotal;
			const pcentToDispatch = ecartPcent / othersGran.length;
			const poidsTotal = Number.parseFloat(this.gpTotal) || 0;
			let volTotalGran = 0;

			this._.forEach(this.formule.composants, (composantsGran) => {
				if (composantsGran.codeFamille === 'GRAN') {
					volTotalGran += Number.parseFloat(composantsGran.volume);
				}
			});

			if (this.formule.calculAuto) {
				for (let i = 0; i < othersGran.length; i++) {
					othersGran[i].quantite = (Number.parseFloat(othersGran[i].quantite) || 0) + pcentToDispatch;
					if (this.calculFormulePcentMassique) {
						othersGran[i].poids = ((Number.parseFloat(othersGran[i].quantite) || 0) / 100) * poidsTotal;
						othersGran[i].volume = othersGran[i].poids / (Number.parseFloat(othersGran[i].mvt) || 0);
					} else {
						othersGran[i].volume = ((Number.parseFloat(othersGran[i].quantite) || 0) / 100) * Number.parseFloat(volTotalGran);
						othersGran[i].poids = (Number.parseFloat(othersGran[i].volume) || 0) * (Number.parseFloat(othersGran[i].mvt) || 0);
					}
					othersGran[i].quantite = othersGran[i].quantite.toFixed(2);
					othersGran[i].poids = othersGran[i].poids.toFixed(2);
					othersGran[i].volume = othersGran[i].volume.toFixed(2);
				}
			}
			let v = 0;
			let m = 0;

			if (this.calculFormulePcentMassique) {
				m = ((Number.parseFloat(compo.quantite) || 0) / 100) * poidsTotal;
				v = m / (Number.parseFloat(compo.mvt) || 0);
			} else {
				v = ((Number.parseFloat(compo.quantite) || 0) / 100) * Number.parseFloat(volTotalGran);
				m = v * (Number.parseFloat(compo.mvt) || 0);
			}

			compo.volume = v.toFixed(2);
			compo.poids = m.toFixed(2);

			this.TotalQuantity();
			this.TotalPoids();
			this.TotalVolume();
		} catch (error) {
			console.error('percent', error);
		}
	}

	//Calculate weight of each granular curve product with the changed value && without changing quantity (%)
	verifSkeletonValue(composant) {
		const poidsMaxSquelete = (composant.poids * 100) / composant.quantite;
		const squelette = this.formule.squelette;

		// Some have 'idElement' empty, other is 'idComposant'. Condition is then adapted
		const conditionId = composant.idElement !== undefined ? 'idElement' : 'idComposant';

		if (squelette && squelette.composants && squelette.composants.findIndex((x) => x[conditionId] === composant[conditionId]) !== -1) {
			for (let i = 0; i < squelette.composants.length; i++) {
				let compo2 = squelette.composants[i];
				if (compo2[conditionId] !== composant[conditionId]) {
					compo2 = this.formule.composants.find((x) => x[conditionId] === compo2[conditionId]);
					compo2.poids = (compo2.quantite * poidsMaxSquelete) / 100;
				}
			}
		}
	}

	async focus(compo, translateCode = 'FORMULES.VALID_RELATION.BREAK') {
		try {
			if (compo.isInSkeleton) {
				const modalInstance = this.$uibModal.open({
					animation: true,
					ariaLabelledBy: 'modal-validation',
					ariaDescribedBy: 'modal-body-validation',
					templateUrl: 'validModal.html',
					size: 'md',
					backdrop: 'static',
					controller: ($scope, $uibModalInstance) => {
						$scope.translateLine = translateCode;
						$scope.ok = () => {
							$uibModalInstance.close();
						};

						$scope.cancel = () => {
							$uibModalInstance.dismiss('cancel');
						};
					}
				});
				await modalInstance.result;
				translateCode = translateCode.split('.');

				// Modal used multi time, to interact with 'GRAN' product
				if (translateCode.length >= 3) {
					if (translateCode[2] === 'BREAK') {
						//break relation between granular curve and its products
						this.clearSkeleton();
					} else if (translateCode[2] === 'DELETE_GRAN') {
						//Take out every granular product which isn't in the granular curve
						this.formule.composants = this.formule.composants.filter((c) => c.codeFamille !== 'GRAN' || c.isInSkeleton === true);
					} else {
						//Take out every granular product
						this.formule.composants = this.formule.composants.filter((c) => c.codeFamille !== 'GRAN');
					}
				}
				return true;
			}
		} catch (err) {
			const activeElement = document.activeElement;
			if (activeElement) {
				activeElement.blur();
			}
		}
	}

	clearSkeleton() {
		this.formule.composants = this.formule.composants.map((x) => {
			x.isInSkeleton = false;
			return x;
		});

		this.formule.squelette = null;
		this.GetElementSelectionable();
	}

	coefChange(compo) {
		this.formule.composants.map((c) => {
			if (
				(c.idComposant !== compo.idComposant && c.codeFamille === 'ADD') ||
				(c.idComposant === compo.idComposant && c.codeFamille === 'ADD' && c.idProducteur !== compo.idProducteur)
			) {
				c.selectCoef = false;
			}
		});

		this.$rootScope.$broadcast('coefKChanged', compo);

		const adjuvants = this.formule.composants.filter(({ codeFamille }) => codeFamille === 'ADJ');
		if (adjuvants && adjuvants.length > 0) {
			for (let i = 0; i < adjuvants.length; i++) {
				const composantAdj = adjuvants[i];
				this.calculPoidsAdjV2(composantAdj);
			}
		}
	}

	selectItem(data, list, composant) {
		if (data) {
			if (data.id !== composant.nomImprime) {
				composant.nomImprime = data.id;
			} else {
				list = list.map((e) => (e.selected = !e.selected));
				composant.nomImprime = null;
			}
		}
	}

	async calculPoidsAdjV2(adjuvant) {
		if (this.formule.composants && this.formule.composants.length > 0) {
			let poidsCiment = 0;
			let poidsAdd = 0;
			let poidsLiantPrisEnCompte = 0;
			if (this.calculPourcentAdjuvant == '2') {
				//pourcentage de liant équivalent, aller chercher le liant équivalent
				if (this.formule.vdc) {
					const idx = this.formule.vdc.findIndex((a) => a.code === 'CKA');
					if (idx > -1 && this.formule.vdc[idx]) {
						poidsLiantPrisEnCompte = this.formule.vdc[idx].calcul;
					}
				}
			} else {
				const addition = this.formule.composants.filter((x) => x.codeFamille === 'ADD');
				const ciment = this.formule.composants.filter((x) => x.codeFamille === 'CIM');
				if (addition && addition.length > 0) {
					for (let i = 0; i < addition.length; i++) {
						const add = addition[i];
						if (
							this.calculPourcentAdjuvant == '1' || //pourcentage de liant total
							(add.selectCoef && this.calculPourcentAdjuvant == '3') //pourcentage de liant hors correcteur granulaire
						) {
							poidsAdd += Number.parseFloat(add.poids);
						}
					}
				}
				if (ciment && ciment.length > 0) {
					for (let i = 0; i < ciment.length; i++) {
						const cim = ciment[i];
						poidsCiment += Number.parseFloat(cim.poids);
					}
				}
				poidsLiantPrisEnCompte = poidsCiment + poidsAdd;
			}
			const nouveauPoids = (poidsLiantPrisEnCompte * adjuvant.quantite) / 100;
			adjuvant.volume = (nouveauPoids / adjuvant.mvt).toFixed(2);
			adjuvant.poids = nouveauPoids.toFixed(2);
			return adjuvant;
		}
	}

	async calculQuantiteAdjV2(adjuvant) {
		if (this.formule.composants && this.formule.composants.length > 0) {
			let poidsCiment = 0;
			let poidsAdd = 0;
			let poidsLiantPrisEnCompte = 0;
			if (this.calculPourcentAdjuvant == '2') {
				//pourcentage de liant équivalent, aller chercher le liant équivalent
				if (this.formule.vdc) {
					const idx = this.formule.vdc.findIndex((a) => a.code === 'CKA');
					if (idx > -1 && this.formule.vdc[idx]) {
						poidsLiantPrisEnCompte = this.formule.vdc[idx].calcul;
					}
				}
			} else {
				const addition = this.formule.composants.filter((x) => x.codeFamille === 'ADD');
				const ciment = this.formule.composants.filter((x) => x.codeFamille === 'CIM');

				if (addition && addition.length > 0) {
					for (let i = 0; i < addition.length; i++) {
						const add = addition[i];
						if (
							this.calculPourcentAdjuvant == '1' || //pourcentage de liant total
							(add.selectCoef && this.calculPourcentAdjuvant == '3') //pourcentage de liant hors correcteur granulaire
						) {
							poidsAdd += Number.parseFloat(add.poids);
						}
					}
				}

				if (ciment && ciment.length > 0) {
					for (let i = 0; i < ciment.length; i++) {
						const cim = ciment[i];
						poidsCiment += Number.parseFloat(cim.poids);
					}
				}
				poidsLiantPrisEnCompte = poidsCiment + poidsAdd;
			}
			if (poidsLiantPrisEnCompte != 0) {
				const nouvelleQuantite = (adjuvant.poids * 100) / poidsLiantPrisEnCompte;
				adjuvant.quantite = nouvelleQuantite.toFixed(2);
			}
			return adjuvant;
		}
	}

	formatNumber(compo, codeEssai = null) {
		if (codeEssai && compo && compo.valeurDeRef) {
			if (compo.valeurDeRef[codeEssai]) {
				const objetToRound = [
					{
						key: compo.valeurDeRef[codeEssai],
						val: compo.valeurDeRef[codeEssai],
						rounder: this._.find(compo.valeursDeRef, (x) => x.codeEssai === codeEssai)?.arrondiEssai ?? 0.01
					}
				];
				return this.Interpreteur.getRoundedValue(objetToRound);
			}
			return '';
		}
		return compo || compo === 0 ? parseFloat(compo).toFixed(2) : '';
	}

	async modalCoefkChanged() {
		const modalInstance = this.ModalService.confirm({
			modalTitle: this.$translate.instant('FORMULES.MODAL.TITLE'),
			modalMsg: this.$translate.instant('FORMULES.MODAL.MESSAGE'),
			headerClass: 'modal-warning'
		});

		try {
			await modalInstance.result;
			// conservation du coefk enregistrer sur la formule
			let coefKActuel = 0;

			if (this.formule && this.formule.compoAddCoef && this.formule.compoAddCoef.length > 0) {
				coefKActuel = this.formule.compoAddCoef[0].coefK;
			}
			return coefKActuel;
		} catch (ex) {
			return null;
		}
	}

	async liaisonSqueletteGran() {
		const modalInstance = this.ModalService.confirm({
			modalTitle: this.$translate.instant('FORMULES.MODAL.SQUELETTE.TITLE'),
			modalMsg: this.$translate.instant('FORMULES.MODAL.SQUELETTE.MESSAGE'),
			headerClass: 'modal-warning'
		});

		try {
			await modalInstance.result;
			//suppression du liens avec le squelette granulaire
			this.clearSkeleton();

			return true;
		} catch (ex) {
			return false;
		}
	}
}
