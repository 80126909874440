AffectationPostePeseeValidator.$inject = ['validator'];

export default function AffectationPostePeseeValidator(validator) {
    const instance = new validator();

    instance.ruleFor('codePA').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('codePA').length(1, 30).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance
        .ruleFor('codePA')
        .matches(/^[a-zA-Z0-9\_\|\-]+$/)
        .withMessage('CODE_CHARACTERS_NOK');

    instance.ruleFor('idSociete').greaterThan(0).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('idSite').greaterThan(0).withMessage('VALIDATION_NOTEMPTY');

    return instance;
}
