import validatorBuilder from 'validator-builder';

let utilisateurPasswordValidator = null;

export default class UtilisateurPassword {
    constructor(data) {
        this.id = data.id;
        this.nom = data.nom;
        this.prenom = data.prenom;
        this.password = undefined;
        this.passwordConfirm = undefined;
    }

    isValid() {
        utilisateurPasswordValidator = utilisateurPasswordValidator || validatorBuilder.getInstanceFor('UtilisateurPassword');
        const validationResults = utilisateurPasswordValidator.validate(this);
        return validationResults.isValid;
    }
}
