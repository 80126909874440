ThemesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'ThemesService',
    'notification',
    'MassiaApplicationService',
    '$transitions'
];

export default function ThemesController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    ThemesService,
    notification,
    MassiaApplicationService,
    $transitions
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    vm.selectedThemeId = undefined;
    vm.lastSelected = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.themes = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];
    vm.getMetiers = getMetiers;
    vm.getLocalisationsPointsMesure = getLocalisationsPointsMesure;
    vm.getCouleursPointsMesure = getCouleursPointsMesure;

    vm.selectTheme = selectTheme;
    vm.isThemeSelected = isThemeSelected;
    /*vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;*/
    vm.loadThemes = loadThemes;
    vm.deleteTheme = deleteTheme;
    vm.setSelected = setSelected;
    vm.eraseAllFilters = eraseAllFilters;
    vm.application = MassiaApplicationService.getApplication();

    async function init() {
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));
        await getMetiers();
        await getLocalisationsPointsMesure();
        await getCouleursPointsMesure();
        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    init();

    function stateChangeSuccessFunc(event, toState) {
        if (!$stateParams.id) {
            vm.setSelected();
        } /* else if (toState.name === 'themes.list.detail') {
            vm.setSelected({id: parseInt($state.params.id)});
        }*/
    }

    async function getMetiers() {
        vm.metiers = await ThemesService.getMetiers(MassiaApplicationService.getApplication());
    }

    async function getLocalisationsPointsMesure() {
        startLoading();
        try {
            vm.localisations = await ThemesService.getLocalisationsPointsMesure();
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            stopLoading();
        }
    }

    async function getCouleursPointsMesure() {
        startLoading();
        try {
            vm.couleurs = await ThemesService.getCouleursPointsMesure();
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            stopLoading();
        }
    }

    function setSelected(theme) {
        if (theme && theme.id) {
            vm.selectedThemeId = $stateParams.id;
            vm.lastSelected = vm.selectedThemeId;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            vm.selectedThemeId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return $state && $state.current && $state.current.name && $state.current.name === 'themes.list.detail' && $stateParams && $stateParams.id;
    }

    function selectTheme(theme) {
        if (theme && theme.id) {
            if (vm.selectedThemeId !== theme.id) {
                vm.selectedThemeId = theme.id;
                //showDetail(theme.id);
            } else {
                vm.selectedThemeId = undefined;
                //closeDetail();
            }
        }
    }

    function isThemeSelected(theme) {
        return theme && theme.id && vm.selectedThemeId === theme.id;
    }

    /*function showDetail(themeId) {
        $state.go('themes.list.detail', {id: themeId});
    }

    function closeDetail() {
        $state.go('themes.list');
    }*/

    async function loadThemes(tableState) {
        startLoading();

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.themes = [];

        try {
            const data = await ThemesService.getThemes(filters, sorts, pagination, MassiaApplicationService.getApplication());

            vm.themes = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteTheme(theme) {
        if (theme /*&& theme.canDelete*/ && theme.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('THEMES.DELETE.TITLE', { libelle: theme.libelle }),
                modalMsg: $translate.instant('THEMES.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await ThemesService.deleteThemeById(theme.id);
                    notification.success($translate.instant('THEMES.DELETE.SUCCESS'));

                    vm.selectedThemeId = undefined;

                    if (vm.params.id) {
                        vm.state.go('themes.list');
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadThemes();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function eraseAllFilters() {
        loadThemes();
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
