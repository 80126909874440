export default class SitePiecesFacturationController {
	static $inject = ['$state', '$stateParams', 'SitesService', 'SitesTypesService', 'ModalService', 'notification', '$translate', '_', '$filter'];

	constructor($state, $stateParams, SitesService, SitesTypesService, ModalService, notification, $translate, _, $filter) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.SitesService = SitesService;
		this.SitesTypesService = SitesTypesService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.$translate = $translate;
		this._ = _;
		this.$filter = $filter;
		this.pages = {
			current: 1,
			total: 0
		};
	}

	async $onInit() {
		this.total = null;
		if (this.site.id) {
			await this.loadPageData(1);
		}
	}

	async loadPageData(number) {
		this.loading = true;
		number = number == -1 ? this.pages.current : number;
		try {
			const paginationData = await this.SitesService.getPieces(this.site.id, number);
			this.pieces = paginationData?.items || [];
			if (paginationData && paginationData.items) {
				this.pages = {
					current: number,
					total: Math.ceil(paginationData.total / paginationData.take)
				};
				this.total = paginationData.total;
			}
		} catch (error) {
			console.error('🚀 ~ file: site.pieces.facturation.controller.js:40 ~ SitePiecesFacturationController ~ loadPageData ~ error:', error);
		} finally {
			this.loading = false;
		}
	}

	async print(id, fileName, extension) {
		this.startLoading();
		try {
			const resultat = await this.SitesService.print(fileName);
			const data = resultat.data;
			const status = resultat.status;
			let headers = resultat.headers;
			headers = headers();
			
			const contentType = headers['content-type'];
			const linkElement = document.createElement('a');

			const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
			const url = window.URL.createObjectURL(blob);
			linkElement.setAttribute('href', url);
			linkElement.setAttribute('download', fileName + '.' + extension);
			const clickEvent = new MouseEvent('click', {
				view: window,
				bubbles: true,
				cancelable: false
			});
			linkElement.dispatchEvent(clickEvent);

		} catch (ex) {
			this.notification.error(this.$translate.instant('DOCUMENTS.PIECE_FACT_NOT_FOUND', {libelle: fileName}));
		} finally {
			this.stopLoading();
		}
	}

	startLoading() {}
	stopLoading() {}
}
