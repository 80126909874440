/* eslint-disable no-trailing-spaces */

import { IAugmentedJQuery, ICompileService, IScope, ITimeoutService } from 'angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Directive, Input, OnDestroy, OnInit, Output } from '../../../core/decorators';

@Directive({ selector: '[massiaTableMultiselect]' })
/* eslint-disable complexity */
export class MassiaTableMultiselect implements OnInit, OnDestroy {
    @Input('=') massiaTableMultiselect: any;
    @Input('@') instanceModelName: any;
    @Input('=')
    set refresh(value: any) {
        this.refreshObs.next(value);
    }
    @Output('&') onItemChecked: CallableFunction;

    private refreshObs: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private refreshSub: Subscription;

    /* @ngInject */
    constructor(public $compile: ICompileService, public $timeout: ITimeoutService, public $scope: IScope, public $element: IAugmentedJQuery) { }

    ngOnInit(): void {
        this.refreshObs = new BehaviorSubject(this.refresh);

        const table = this.$element[0];
        const tHead = this.$element.children('thead')[0];
        const tBody = this.$element.children('tbody')[0];

        if (tHead) this.createHeadRowCheckBox(this.$scope, table, tHead, tBody);

        //this.createBodyRowsCheckBox($scope, table, tHead, tBody);
        this.refreshSub = this.refreshObs.subscribe((evt) => {
            this.createBodyRowsCheckBox(this.$scope, table, tHead, tBody);
        });
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.refreshSub.unsubscribe();
    }

    createHeadRowCheckBox($scope: IScope, table: HTMLElement, tHead: HTMLElement, tBody: HTMLElement) {
        const that = this;
        $(() => {
            const headAllTr = tHead.getElementsByTagName('tr');

            if (headAllTr && headAllTr.length > 0) {
                const firstTr = headAllTr[0];
                const firstTh = firstTr.getElementsByTagName('th')[0];

                const newTh = document.createElement('th');
                newTh.classList.add('flex');
                newTh.style.justifyContent = 'center';
                newTh.innerHTML = '<input type="checkbox" />';
                newTh.addEventListener(
                    'click',
                    (ev) => {
                        let bodyAllTr: HTMLCollectionOf<HTMLTableRowElement> = null;
                        if (tBody) bodyAllTr = tBody.getElementsByTagName('tr');
                        else bodyAllTr = table.getElementsByTagName('tr');

                        if (bodyAllTr && bodyAllTr.length > 0) {
                            let tBodyAllTr: HTMLTableRowElement[] = Array.from(bodyAllTr).filter((e) => {
                                if (e.className.indexOf(that.instanceModelName) >= 0) return e;
                            });
                            for (let i = 0; i < tBodyAllTr.length; i++) {
                                const currTr = tBodyAllTr[i];
                                const currTd = currTr.getElementsByTagName('td')[0];
                                $(currTd.firstChild).prop('checked', $(ev.target).is(":checked"));
                            }
                        }

                        this.$timeout(() => {
                            that.onItemChecked({ index: -1, checked: $(ev.target).is(":checked") });
                        }, 0)
                    },
                    false
                );

                firstTr.insertBefore(newTh, firstTh);
            }
        })
    }

    createBodyRowsCheckBox($scope: IScope, table: HTMLElement, tHead: HTMLElement, tBody: HTMLElement) {
        const that = this;
        $(() => {
            let firstTh = $(tHead.getElementsByTagName('tr')[0].getElementsByTagName('th')[0].firstChild);
            firstTh.prop('checked', false);
            firstTh.prop('disable', false);
            firstTh.prop('disabled', false);


            let bodyAllTr: HTMLTableRowElement[] = null;
            if (tBody) bodyAllTr = Array.from(tBody.getElementsByTagName('tr'));
            else bodyAllTr = Array.from(table.getElementsByTagName('tr'));

            if (bodyAllTr) {
                bodyAllTr = bodyAllTr.filter((e) => {
                    if (e.className.indexOf(that.instanceModelName) >= 0) return e;
                });
            }

            if (bodyAllTr && bodyAllTr.length > 0) {
                for (let i = 0; i < bodyAllTr.length; i++) {
                    const currTr = bodyAllTr[i];
                    const currTd = currTr.getElementsByTagName('td')[0];

                    if ($(currTd.firstChild).attr('type') !== 'checkbox') {
                        const newTd = document.createElement('td');
                        newTd.classList.add('flex');
                        newTd.style.justifyContent = 'center';
                        newTd.innerHTML = '<input type="checkbox" />';

                        newTd.addEventListener(
                            'click',
                            (ev) => {
                                $(newTd.firstChild).prop('checked', $(ev.target).is(":checked"));
                                this.$timeout(() => {
                                    that.onItemChecked({ index: i, checked: $(ev.target).is(":checked") });
                                }, 0);
                            },
                            false
                        );
                        currTr.insertBefore(newTd, currTd);
                    }
                }
            } else {
                $(tHead.getElementsByTagName('tr')[0].getElementsByTagName('th')[0].firstChild).prop('disable', true);
                $(tHead.getElementsByTagName('tr')[0].getElementsByTagName('th')[0].firstChild).prop('disabled', true);
            }
        });
    }
}
