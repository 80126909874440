export default class SocieteFormEnteteController {
	static $inject = [
		'$state',
		'$stateParams',
		'SocietesService',
		'SocietesTypesService',
		'ModalService',
		'ImagesService',
		'notification',
		'$translate',
		'MassiaApplicationService',
		'MOPService',
		'TemplateImpressionService',
		'$uibModal'
	];

	constructor(
		$state,
		$stateParams,
		SocietesService,
		SocietesTypesService,
		ModalService,
		ImagesService,
		notification,
		$translate,
		MassiaApplicationService,
		MOPService,
		TemplateImpressionService,
		$uibModal
	) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.SocietesService = SocietesService;
		this.SocietesTypesService = SocietesTypesService;
		this.ModalService = ModalService;
		this.ImagesService = ImagesService;
		this.notification = notification;
		this.$translate = $translate;
		this.MassiaApplicationService = MassiaApplicationService;
		this.MOPService = MOPService;
		this.templateImpressionService = TemplateImpressionService;
		this.fileEntete = null;
		this.filename = 'Choisir un fichier';
		this.currentModelMode = 0;
		this.hasExistingEntete = false;
		this.hasSurimpressionAccess = false;
		this.currentFileEntete = null;
		this.$uibModal = $uibModal;
	}

	async $onInit() {
		this.societe = this.societe || {};
		this.entete = this.societe.entete;
		this.types = this.types || [];
		this.pays = this.pays || [];
		this.associes = this.associes || [];
		/*this.textMaskConfigTel = {
            showMask:false,
            mask: [0, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
        }*/
		this.getLogos();
		const application = this.MassiaApplicationService.getApplication();
		if (application == 'gestion') {
			this.listeTypesSite = [
				{
					id: 'isUtilisateur',
					libelle: this.$translate.instant('SOCIETES.ISUTILISATEUR'),
					selected: this.entete.isUtilisateur,
					disabled: this.genre == '1'
				},
				{
					id: 'isClient',
					libelle: this.$translate.instant('SOCIETES.ISCLIENT'),
					selected: this.entete.isClient,
					disabled: this.genre == '2'
				},
				{
					id: 'isFournisseur',
					libelle: this.$translate.instant('SOCIETES.ISFOURNISSEUR'),
					selected: this.entete.isFournisseur,
					disabled: this.genre == '8'
				},
				{
					id: 'isTransporteur',
					libelle: this.$translate.instant('SOCIETES.ISTRANSPORTEUR'),
					selected: this.entete.isTransporteur,
					disabled: this.genre == '16'
				},
				{
					id: 'isProducteur',
					libelle: this.$translate.instant('SOCIETES.ISPRODUCTEUR'),
					selected: this.entete.isProducteur,
					disabled: this.genre == '4'
				}
			];
		} else if (application == 'qse') {
			this.listeTypesSite = [
				{
					id: 'isFournisseur',
					libelle: this.$translate.instant('SOCIETES.ISFOURNISSEUR'),
					selected: this.entete.isFournisseur,
					disabled: this.genre == '8'
				},
				{
					id: 'isProducteur',
					libelle: this.$translate.instant('SOCIETES.ISPRODUCTEUR'),
					selected: this.entete.isProducteur,
					disabled: this.genre == '4'
				},
				{
					id: 'isAuditeur',
					libelle: this.$translate.instant('SOCIETES.ISAUDITEUR'),
					selected: this.entete.isAuditeur,
					disabled: this.genre == '256'
				},
				{
					id: 'isProprietaire',
					libelle: this.$translate.instant('SOCIETES.ISPROPRIETAIRE'),
					selected: this.entete.isProprietaire,
					disabled: this.genre == '64'
				},
				{
					id: 'isLocataire',
					libelle: this.$translate.instant('SOCIETES.ISLOCATAIRE'),
					selected: this.entete.isLocataire,
					disabled: this.genre == '128'
				}
			];
		} else if (application == 'performance') {
			this.listeTypesSite = [
				{
					id: 'isProducteur',
					libelle: this.$translate.instant('SOCIETES.ISPRODUCTEUR'),
					selected: this.entete.isProducteur,
					disabled: this.genre == '4'
				},
				{
					id: 'isUtilisateur',
					libelle: this.$translate.instant('SOCIETES.ISUTILISATEUR'),
					selected: this.entete.isUtilisateur,
					disabled: this.genre == '1'
				},
				{
					id: 'isFournisseur',
					libelle: this.$translate.instant('SOCIETES.ISFOURNISSEUR'),
					selected: this.entete.isFournisseur,
					disabled: this.genre == '8'
				}
			];
		} else if (application == 'laboratoire') {
			this.listeTypesSite = [
				{
					id: 'isClient',
					libelle: this.$translate.instant('SOCIETES.ISCLIENT'),
					selected: this.entete.isClient,
					disabled: this.genre == '2'
				},
				{
					id: 'isProducteur',
					libelle: this.$translate.instant('SOCIETES.ISPRODUCTEUR'),
					selected: this.entete.isProducteur,
					disabled: this.genre == '4'
				},
				{
					id: 'isLaboratoire',
					libelle: this.$translate.instant('SOCIETES.ISLABORATOIRE'),
					selected: this.entete.isLaboratoire,
					disabled: this.genre == '32'
				},
				{
					id: 'isFournisseur',
					libelle: this.$translate.instant('SOCIETES.ISFOURNISSEUR'),
					selected: this.entete.isFournisseur,
					disabled: this.genre == '8'
				}
			];
		}
		this.getNiveauVisibilite();
		this.initSurimpressionValeur();
	}

	async getLogos() {
		this.startLoading();
		try {
			this.logos = await this.SocietesService.getLogos();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getNiveauVisibilite() {
		if (this.entete.typeId) {
			this.entete.niveauVisibilite = await this.SocietesTypesService.GetNiveauVisibilite(this.entete.typeId);
		}
	}

	dispatch() {
		const application = this.MassiaApplicationService.getApplication();

		if (application == 'gestion') {
			this.entete.isUtilisateur =
				this.entete.isClient =
				this.entete.isFournisseur =
				this.entete.isTransporteur =
				this.entete.isProducteur =
					false;
		} else if (application == 'performance') {
			this.entete.isUtilisateur = this.entete.isFournisseur = this.entete.isProducteur = false;
		} else if (application == 'qse') {
			this.entete.isFournisseur =
				this.entete.isProducteur =
				this.entete.isProprietaire =
				this.entete.isLocataire =
				this.entete.isAuditeur =
					false;
		} else if (application == 'laboratoire') {
			this.entete.isFournisseur = this.entete.isProducteur = this.entete.isLaboratoire = false;
		}

		angular.forEach(
			this.output.listeTypesSite,
			function (valeur, cle) {
				this.entete[valeur] = true;
			},
			this
		);
	}

	$onChanges(objChanges) {
		if (objChanges.societe) {
			this.getTypes();
			this.getPays();
			this.getAssocies();
		}
	}

	$doCheck() {
		if (!angular.equals(this.entete, this.societe.entete)) {
			this.onUpdate({
				entete: angular.copy(this.entete)
			});
		}
	}

	async createType() {
		const modalInstance = this.ModalService.confirm({
			modalTitle: 'SOCIETES.CONFIRM_CREATE_TYPE.TITLE',
			modalMsg: 'SOCIETES.CONFIRM_CREATE_TYPE.MESSAGE',
			headerClass: 'modal-warning'
		});
		modalInstance.result.then(() => this.$state.go('societes.newType'));
	}

	async getTypes() {
		this.startLoading();

		try {
			this.types = await this.SocietesTypesService.getAll();
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async checkCodeUnicity(code) {
		if (code) {
			this.startCodeLoading();

			try {
				if (code.match(/^[a-zA-Z0-9_|]*$/)) {
					this.entete.codeExists = await this.SocietesService.codeExists(code);
				}
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				this.stopCodeLoading();
			}
		} else {
			this.entete.codeExists = null;
		}
	}

	async getPays() {
		this.startLoading();
		try {
			const res = await this.SocietesService.getPays();
			this.paysListe = res.items;
			if (this.paysListe.length == 1) {
				this.entete.paysId = this.paysListe[0].id;
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async getAssocies() {
		this.startLoading();
		try {
			const res = await this.SocietesService.getAssocies();
			this.associesListe = res;
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}

	async selectNewLogo() {
		this.logos = await this.SocietesService.getLogos();
	}

	openFileUploader() {
		document.getElementById('file-upload').click();
	}

	async SetDefaultSurimpressionValeur() {
		this.hasExistingEntete = await this.isEnteteTemplateExist();
	}

	async initSurimpressionValeur() {
		this.hasSurimpressionAccess = (await this.SocietesService.getAccessSurimpression()) == 1 ? true : false;
		this.hasExistingEntete = await this.isEnteteTemplateExist();
		if (this.hasExistingEntete) {
			this.currentFileEntete = await this.getEnteteTemplate();
		}
		console.log('🐞', this.currentFileEntete);
	}

	async isEnteteTemplateExist() {
		return (await this.getEnteteTemplate()) != null;
	}

	async getEnteteTemplate() {
		let res = null;
		const templates = await this.templateImpressionService.getTemplateList();
		for (let i = 0; i < templates.items.length; i++) {
			if (templates.items[i].libelle == this.societe.entete.code + '_facture_entete') {
				res = templates.items[i];
			}
		}

		return res;
	}

	getSizeInfo(size) {
		let calc = size / 1000; //kilo-octet;

		if (calc > 1000) {
			calc = calc / 1000;
			return `${calc.toFixed(2)}mo`;
		}
		return `${calc.toFixed(2)}ko`;
	}

	getFileAndExtension(file) {
		const result = file.split('.');
		return result;
	}

	async change(event) {
		this.notification.info(this.$translate.instant('SOCIETES.FACTURE_ENTETE_ACTION.NOTIF_SENDING'));

		const formData = {
			data: {},
			file: {}
		};

		this.fileEntete = event.target.files[0];
		this.filename = this.fileEntete.name;

		const fileData = this.getFileAndExtension(this.fileEntete.name);
		formData.file = this.fileEntete;
		formData.data.extension = fileData[1];
		formData.data.idDomaine = 174; //Sociétés
		formData.data.libelle = this.societe.entete.code + '_facture_entete';

		this.currentModelMode = 1;
		try {
			let result = null;
			if ((await this.isEnteteTemplateExist()) == false) {
				console.log('Upload');
				result = await this.templateImpressionService.upload(formData);
				this.notification.clear();
				this.notification.success(this.$translate.instant('SOCIETES.FACTURE_ENTETE_ACTION.NOTIF_SUCCESS_UPLOAD'));
			} else {
				const currentFile = await this.getEnteteTemplate();
				formData.data.id = currentFile.id;

				console.log('Update');
				result = await this.templateImpressionService.update(formData);
				this.notification.clear();
				this.notification.success(this.$translate.instant('SOCIETES.FACTURE_ENTETE_ACTION.NOTIF_SUCCESS_UPDATE'));
			}

			console.log(result);
			this.hasExistingEntete = true;
			this.currentModelMode = 1;
		} catch (error) {
			console.log('🪝Error Catched on Upload', error);
			this.notification.clear();
			this.notification.error(this.$translate.instant('SOCIETES.FACTURE_ENTETE_ACTION.NOTIF_FAIL_UPLOAD'));
		}
		console.log('File Changed', event);
	}

	async deleteCurrent() {
		if (await this.isEnteteTemplateExist()) {
			const enteteFileData = await this.getEnteteTemplate();

			console.log('Prompt Delete', enteteFileData);
			const modalInstance = this.ModalService.confirm({
				modalTitle: this.$translate.instant('IMPRESSION.MODAL.TITLE', { code: enteteFileData.libelle }),
				modalMsg: this.$translate.instant('SOCIETES.FACTURE_ENTETE_ACTION.PROMPT_DELETE_CONFIRMATION'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async () => {
				try {
					await this.templateImpressionService.deleteTemplateById(enteteFileData.id);
					this.hasExistingEntete = false;
					this.notification.success(this.$translate.instant('IMPRESSION.MODAL.SUCCESS'));
				} catch (ex) {
					this.notification.error(ex.data);
				}
			});
		} else {
			this.notification.error(this.$translate.instant('SOCIETES.FACTURE_ENTETE_ACTION.NOTIF_FAIL_DELETE'));
		}
	}

	async downloadCurrent() {
		const modele = await this.getEnteteTemplate();
		this.templateImpressionService.downloadOriginalTemplate(modele.filename);
	}

	openHelper() {
		const modalInstance = this.$uibModal.open({
			animation: true,
			size: 'lg',
			component: 'enteteImpressionHelper'
		});
	}
}
