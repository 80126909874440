DocumentsController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'DocumentsService',
    'DocumentsTypesService',
    'notification',
    '$transitions',
    'MOPService'
];

export default function DocumentsController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    DocumentsService,
    DocumentsTypesService,
    notification,
    $transitions,
    MOPService
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    vm.selectedDocumentId = undefined;
    vm.lastSelected = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.documents = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];
    vm.getTypes = getTypes;

    vm.selectDocument = selectDocument;
    vm.isDocumentSelected = isDocumentSelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadDocuments = loadDocuments;
    vm.deleteDocument = deleteDocument;
    vm.setSelected = setSelected;
    //vm.eraseAllFilters = eraseAllFilters;

    vm.print = print;
    vm.MOPService = MOPService;

    vm.$onInit = () => {
        vm.MOPService.setMop([{ title: 'DOCUMENTS.BREADCRUMBS.DOCUMENTS_LIST', filename: 'Documents.pdf', application: 'massia' }]);
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        //$transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }

        getTypes();
    };

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    function stateChangeSuccessFunc(event, toState) {
        if (!$state.params.id) {
            vm.setSelected();
        } else if ($state.params.id) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    async function getTypes(event, toState) {
        vm.types = await DocumentsTypesService.getAll();
    }

    function setSelected(document) {
        if (document && document.id) {
            vm.selectedDocumentId = $stateParams.id;
            vm.lastSelected = vm.selectedDocumentId;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            vm.selectedDocumentId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return $state && $state.current && $state.current.name && $state.current.name === 'documents.list.detail' && $stateParams && $stateParams.id;
    }

    function selectDocument(document) {
        if (document && document.id) {
            if (vm.selectedDocumentId !== document.id) {
                vm.selectedDocumentId = document.id;
                showDetail(document.id);
            } else {
                vm.selectedDocumentId = undefined;
                closeDetail();
            }
        }
    }

    function isDocumentSelected(document) {
        return document && document.id && vm.selectedDocumentId === document.id;
    }

    function showDetail(documentId) {
        $state.go('documents.list.detail', { id: documentId });
    }

    function closeDetail() {
        $state.go('documents.list');
    }

    async function loadDocuments(tableState) {
        startLoading();

        if ($state && $state.current && $state.current.name !== 'documents.list') {
            $state.go('documents.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.documents = [];

        try {
            const data = await DocumentsService.getDocuments(filters, sorts, pagination);

            vm.documents = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    this.getTooltipHtml = (document) => {
        let res = '';
        if (document.elements.length > 0) {
            res = '<ul class="text-left">';
            res += document.elements
                .map((x) => {
                    return `<li>${x.type} - ${x.code} - ${x.libelle}</li>`;
                })
                .slice(0, 5)
                .join('');
            res += document.elements.length > 5 ? '<li>...</li>' : '';
            res += '</ul>';
        } else {
            res = null;
        }

        this.tooltip = res;
    };

    async function deleteDocument(document) {
        if (document && document.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('DOCUMENTS.DELETE.TITLE', { code: document.libelle }),
                modalMsg: $translate.instant('DOCUMENTS.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await DocumentsService.deleteDocumentById(document.id);
                    notification.success($translate.instant('DOCUMENTS.DELETE.SUCCESS'));

                    vm.selectedDocumentId = undefined;

                    if (vm.params.id) {
                        vm.state.go('documents.list');
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadDocuments();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    // function eraseAllFilters() {
    //     loadDocuments();
    // }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }

    async function print(fileName, extension, code) {
        startLoading();
        const resultat = await DocumentsService.print(fileName, extension, code);

        const data = resultat.data;
        const status = resultat.status;
        let headers = resultat.headers;

        headers = headers();

        const contentType = headers['content-type'];

        const linkElement = document.createElement('a');
        try {
            const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
            const url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute('download', fileName + '.' + extension);

            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(clickEvent);
        } catch (ex) {
        } finally {
            stopLoading();
        }
    }
}
