import PreviewLoginController from './preview.login.modal.controller';

export default {
    template: require('./preview.login.modal.tpl.html'),
    controller: PreviewLoginController,
    bindings: {
        background: '=',
        logo: '='
    }
};
