export default function PortailAffectationListController(
    $state,
    $stateParams,
    AffectationPortailService,
    PaginationService,
    notification,
    ModalService,
    $translate
) {
    const $ctrl = this;

    this.$onInit = () => {
        _toggleLoading(true);
        $ctrl.itemsByPageOptions = [20, 50, 100];
        $ctrl.affectationList = [];
        $ctrl.loadList = loadAffectation;
    };

    _toggleLoading = (value) => {
        $ctrl.loading = value || !$ctrl.loading;
    };

    loadAffectation = async (tableState) => {
        try {
            _toggleLoading(true);
            if (tableState) {
                previousTableState = tableState;
            }
            const filters = PaginationService.getFilters(previousTableState);
            const sorts = PaginationService.getSorts(previousTableState);
            const pagination = PaginationService.getPagination(previousTableState);

            const res = await AffectationPortailService.getAll(filters, sorts, pagination);
            $ctrl.affectationList = res.data.items;

            if (previousTableState) {
                PaginationService.setTableState(res.data.skip, res.data.take, res.data.total, previousTableState);
            }
            _toggleLoading();
        } catch (err) {
            console.error(err);
            if (err.data && err.data.message) {
                notification.error(err.data.message);
            } else {
                notification.error(err.message);
            }
            _toggleLoading();
        }
    };

    $ctrl.update = (affectation) => {
        $state.go('portail.affectation.edit', {
            id: affectation.id
        });
    };

    $ctrl.delete = (affectation) => {
        // ModalService.confirm({
        //     modalTitle: $translate.instant('PORTAIL.USER.DELETE.TITLE'),
        //     modalMsg: `${$translate.instant('PORTAIL.USER.DELETE.MESSAGE')} ${user.login}`,
        //     headerClass: 'modal-danger',
        // }).result.then(async () => {
        //     try {
        //         await UtilisateurPortailService.delete(user.id);
        //         notification.success('PORTAIL.USER.DELETE.SUCCESS');
        //         return await loadUser();
        //     } catch (err) {
        //         console.log(err);
        //         if (err.data && err.data.message) {
        //             return notification.error(err.data.message);
        //         }
        //         return notification.error(err.message);
        //     }
        // });
    };
}

PortailAffectationListController.$inject = [
    '$state',
    '$stateParams',
    'AffectationPortailService',
    'PaginationService',
    'notification',
    'ModalService',
    '$translate'
];
