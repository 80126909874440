import validatorBuilder from 'validator-builder';

let contactValidator = null;

export default class Contact {
    constructor() {
        this.id = null;
        this.code = null;
        this.nom = null;
        this.prenom = null;
        this.isContact = true;
        this.idSite = null;
        this.isDematBLSMS = false;
        this.isDematBLMail = false;
        this.mail = null;
        this.telMobile = null;
    }

    isValid() {
        contactValidator = contactValidator || validatorBuilder.getInstanceFor('Contact');
        const validationResults = contactValidator.validate(this);
        return validationResults.isValid;
    }
}
