export default class CarnetDetailController {
    static $inject = ['$stateParams', '$state', 'CarnetsService', 'notification', 'CarnetsCommunicationService'];

    constructor($stateParams, $state, CarnetsService, notification, CarnetsCommunicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.CarnetsService = CarnetsService;
        this.notification = notification;
        this.CarnetsCommunicationService = CarnetsCommunicationService;
    }

    $onInit() {
        this.loading = false;
        this.carnet = {};
        this.carnetId = undefined;

        this.ongletOpen = {
            isEnteteOpen: true,
            isBonOpen: false
        };

        this.initCarnet();
    }

    async initCarnet() {
        this.carnetId = this.$stateParams.id;

        this.startLoading();
        try {
            this.carnet = await this.CarnetsService.getCarnetById(this.carnetId);
            //console.log(this.carnet);
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('carnets.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
