import MonitoringSynchroConfig from './_config/monitoring.synchro.config';
import MonitoringSynchroRoutes from './_config/monitoring.synchro.routes';
import MonitoringSynchroComponent from './components/monitoring-synchro';
import MonitoringSynchroClientServeurComponent from './components/monitoring-synchro/monitoring-synchro-client-serveur';
import MonitoringSynchroServeurClientComponent from './components/monitoring-synchro/monitoring-synchro-serveur-client';
import MonitoringSynchroService from './services/monitoring.synchro.service';

const moduleName = 'app.massia.gestion.monitoring.synchro';

angular
    .module(moduleName, [])
    .config(MonitoringSynchroConfig)
    .config(MonitoringSynchroRoutes)
    .service('MonitoringSynchroService', MonitoringSynchroService)
    .component('monitoringSynchro', MonitoringSynchroComponent)
    .component('monitoringSynchroClientServeur', MonitoringSynchroClientServeurComponent)
    .component('monitoringSynchroServeurClient', MonitoringSynchroServeurClientComponent);
export default moduleName;
