const serviceUrl = `${__configuration.apiUrl}/massia/gestion/tgap-exclure`;

export default class TgapExclureService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getTgapExclure(idClient, idChantier) {
        const url = `${serviceUrl}/${idClient}/${idChantier}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async deleteTgapExclureById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async createTgapExclure(tgapExclure) {
        const res = await this.$http.post(serviceUrl, tgapExclure);
        return res.data;
    }
}
