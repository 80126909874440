/**
 * Created by: Massia CLI
 */

export default class RsiService {
    constructor($http, ElementCaracteristiqueService) {
        this.serviceUrl = `${__configuration.apiUrl}/massia/rsi`;
        this.$http = $http;
        this.ElementCaracteristiqueService = ElementCaracteristiqueService;
    }

    async getAll(filters, sorts, pagination) {
        const res = await this.$http.get(this.serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });
        return res.data;
    }

    async getConformite(id) {
        const res = await this.$http.get('assets/enum/conformite.json');
        return res.find((e) => e.id === id);
    }

    async getOne(id) {
        const res = await this.$http.get(`${this.serviceUrl}/${id}`);
        return res.data;
    }

    async save(data) {
        const res = await this.$http.post(this.serviceUrl, data);
        return res.data;
    }

    async update(data, id) {
        const res = await this.$http.put(this.serviceUrl + '/' + id, data);
        return res.data;
    }

    async delete(id) {
        const res = await this.$http.delete(this.serviceUrl + '/' + id);
        return res.data;
    }

    async getConfig() {
        const res = await this.$http.get(`${this.serviceUrl}/config`);
        return res.data;
    }

    async getKProduit(idproduit) {
        const res = await this.$http.get(`${this.serviceUrl}/coef-k`, {
            params: {
                id: idproduit
            }
        });
        return res.data;
    }

    async getTypeCiment(idProduit) {
        const res = await this.$http.get(`${this.serviceUrl}/ciment`, {
            params: {
                id: idProduit
            }
        });

        return res.data;
    }

    async getCoefEau(idProduit, idProducteur, date) {
        const res = await this.$http.get(`${this.serviceUrl}/coef-eau`, {
            params: {
                id: idProduit,
                idProducteur: idProducteur,
                date: date
            }
        });

        return res.data;
    }

    async getMasseVolumique(idProduit, idProducteur, date) {
        const res = await this.$http.get(`${this.serviceUrl}/masse-volumique`, {
            params: {
                idProduit: idProduit,
                idProducteur: idProducteur,
                date: date
            }
        });

        return res.data;
    }

    async getTypeCim(i, v) {
        const a = await this.$http.get(`${__configuration.apiUrl}/massia/parametre-programme/code`, {
            params: {
                cle: 'CarCimType'
            }
        });
        const b = await this.ElementCaracteristiqueService.getValeur(i, a.data);
        const c = await this.$http.get(`${__configuration.apiUrl}/massia/parametre-programme/cle`, {
            params: {
                code: b.data
            }
        });
        const d = await this.$http.get('assets/enum/rsi/ciment.enum.json');
        return d.data[c.data];
    }

    /*
    SP 26/03/20 non utilisé
    async getTypeAdd(code) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/parametre-programme/cle`, {
            params: {
                code: code
            }
        });

        return res.data;
    }*/

    async getKPrime() {
        const res = await this.$http.get('assets/enum/coefk/kprime.enum.json');
        return res.data;
    }

    async print(dataRsi, filename) {
        const url = `${__configuration.apiUrl}/massia/rsi/print`;
        const res = await this.$http.post(url, {
            template: filename,
            dataRsi: dataRsi
        });

        return res.data ? res.data : null;
    }
}

RsiService.$inject = ['$http', 'ElementCaracteristiqueService'];
