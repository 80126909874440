VehiculesATarerController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'VehiculesService',
	'notification'
];

export default function VehiculesATarerController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	VehiculesService,
	notification
) {
	const vm = this;

	let previousTableState;

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.vehicules = [];
	vm.siteSearch = { id: 0, libelle: '' };

	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.loadVehicules = loadVehicules;
	vm.exporter = exporter;
	vm.getSites = getSites;
	vm.isSearchOngletOpen = true;

	async function init() {
		if (vm.siteSearch.id != 0) {
			await loadVehicules(null);
		}
	}

	init();

	async function getSites(valeur) {
		const sites = await VehiculesService.getSitesCommerciaux(valeur);
		return sites;
	}

	async function loadVehicules(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'vehicules.a-tarer') {
			$state.go('vehicules.a-tarer');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.vehicules = [];

		try {
			const siteId = vm.siteSearch ? vm.siteSearch.id : null;
			if (siteId && siteId != 0) {
				const data = await VehiculesService.getVehiculesATarer(siteId, filters, sorts, pagination);
				vm.vehicules = data.items;
				if (previousTableState) {
					PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
				}
			}
		} catch (ex) {
			const msgException = ex.data;
			notification.error('VEHICULES.' + msgException);
		} finally {
			stopLoading();
		}
	}

	async function exporter(modeImpression) {
		try {
			const siteId = vm.siteSearch ? vm.siteSearch.id : null;
			if (siteId) {
				const fileName = 'Listing_tare_vehicule';
				let resultat = undefined;
				const filters = PaginationService.getFilters(previousTableState);
				const sorts = PaginationService.getSorts(previousTableState);
				try {
					resultat = await VehiculesService.exportListingTare(siteId, modeImpression, filters, sorts);
				} catch (ex) {
					const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
					notification.error('VEHICULES.' + msgException);
					return false;
				}
				if (resultat) {
					const data = resultat.data;
					const status = resultat.status;
					let headers = resultat.headers;
					headers = headers();

					const contentType = headers['content-type'];

					const linkElement = document.createElement('a');
					try {
						const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
						const url = window.URL.createObjectURL(blob);
						linkElement.setAttribute('href', url);
						if (modeImpression == 0) {
							linkElement.setAttribute('download', fileName + '.pdf');
						} else {
							linkElement.setAttribute('download', fileName + '.xlsx');
						}

						const clickEvent = new MouseEvent('click', {
							view: window,
							bubbles: true,
							cancelable: false
						});
						linkElement.dispatchEvent(clickEvent);
					} catch (ex) {
						notification.error(ex.data);
						return false;
					} finally {
					}
					return true;
				}
			} else {
				notification.error('VEHICULES.SITE_COMMERCIAL_MANQUANT');
			}
		} catch (ex) {
			notification.error(ex.data);
			return false;
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
