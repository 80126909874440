export default function Configure($stateProvider) {
    $stateProvider.state('seuilgran', {
        parent: 'common',
        url: '/seuilgran',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.SAISIES" | translate }}'
        }
    });

    $stateProvider.state('seuilgran.list', {
        url: '/list',
        template: '<references-controle></references-controle>',
        rights: { domain: 'seuilgran', right: 'read' },
        ncyBreadcrumb: {
            parent: 'seuilgran',
            label: '{{ "REFERENCES.BREADCRUMBS.REFERENCES_LIST" | translate}}'
        },
        navigation: {
            menu: 'laboratoire_donnees',
            translate: 'REFERENCES.BREADCRUMBS.REFERENCES_LIST',
            order: 6,
            navigationCls: 'menu-item-separator'
        },
        search: 'REFERENCES.BREADCRUMBS.REFERENCES_LIST'
    });

    $stateProvider.state('seuilgran.list.detail', {
        url: '/{id}',
        template: '<reference-controle-detail></reference-controle-detail>',
        rights: { domain: 'seuilgran', right: 'read' },
        ncyBreadcrumb: {
            parent: 'seuilgran.list',
            label: '{{ "REFERENCES.BREADCRUMBS.REFERENCE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('seuilgran.new', {
        url: '/new',
        template: '<reference-controle-form></reference-controle-form>',
        rights: { domain: 'seuilgran', right: 'create' },
        ncyBreadcrumb: {
            parent: 'seuilgran.list',
            label: '{{ "REFERENCES.BREADCRUMBS.REFERENCE_NEW" | translate}}'
        },
        search: 'REFERENCES.BREADCRUMBS.REFERENCE_NEW'
    });

    $stateProvider.state('seuilgran.edit', {
        url: '/{id}/edit',
        template: '<reference-controle-form></reference-controle-form>',
        rights: { domain: 'seuilgran', right: 'update' },
        ncyBreadcrumb: {
            parent: 'seuilgran.list',
            label: '{{ "REFERENCES.BREADCRUMBS.REFERENCE_EDIT" | translate }}'
        }
    });

    // $stateProvider.state('seuilsecu.newType', {
    //     url: '/new-type',
    //     template: '<type-form></type-form>',
    //     rights: {domain: 'types', right: 'create'},
    //     params: {parentState:'seuilsecu.new'},
    //     ncyBreadcrumb: {
    //         parent: 'seuilsecu.new',
    //         label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
    //     }
    // });

    $stateProvider.state('seuilgran.duplicate', {
        url: '/{id}/duplicate',
        template: '<reference-controle-form></reference-controle-form>',
        rights: { domain: 'seuilgran', right: 'create' },
        ncyBreadcrumb: {
            parent: 'seuilgran.list',
            label: '{{ "REFERENCES.BREADCRUMBS.REFERENCE_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });
}

Configure.$inject = ['$stateProvider'];
