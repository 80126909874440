CarnetsController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'CarnetsService',
    'notification',
    '$uibModal',
    '$transitions'
];

export default function CarnetsController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    CarnetsService,
    notification,
    $uibModal,
    $transitions
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    let selectedCarnetId = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.$uibModal = $uibModal;
    vm.carnets = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right'
    };
    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.selectCarnet = selectCarnet;
    vm.isCarnetSelected = isCarnetSelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadCarnets = loadCarnets;
    vm.deleteCarnet = deleteCarnet;
    vm.updateCarnetPerime = updateCarnetPerime;
    vm.setSelected = setSelected;
    vm.printFacture = printFacture;

    function init() {
        //écouter l'event lié au changement de route
        //une fois on change l'url, et on arrive sur la page "chantiers.list", on désélectionne la ligne sélectionnée (s'il y en a)
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        //quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    init();

    function stateChangeSuccessFunc(event, toState) {
        if (!$state.params.id) {
            vm.setSelected();
        } else if ($state.params.id) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    function setSelected(carnet) {
        if (carnet && carnet.id) {
            selectedCarnetId = $stateParams.id;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            selectedCarnetId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return $state && $state.current && $state.current.name && $state.current.name === 'carnets.list.detail' && $stateParams && $stateParams.id;
    }

    function selectCarnet(carnet) {
        if (carnet && carnet.id) {
            if (selectedCarnetId !== carnet.id) {
                showDetail(carnet.id);
            } else {
                //si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
                closeDetail();
            }
        }
    }

    function isCarnetSelected(carnet) {
        return carnet && carnet.id && selectedCarnetId === carnet.id;
    }

    function showDetail(carnetId) {
        selectedCarnetId = carnetId;
        $state.go('carnets.list.detail', { id: carnetId });
    }

    function closeDetail() {
        selectedCarnetId = undefined;
        $state.go('carnets.list');
    }

    async function loadCarnets(tableState) {
        startLoading();

        if ($state && $state.current && $state.current.name !== 'carnets.list') {
            $state.go('carnets.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.carnets = [];

        try {
            const data = await CarnetsService.getCarnets(filters, sorts, pagination);

            vm.carnets = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteCarnet(carnet) {
        if (carnet && carnet.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('CARNETS.DELETE.TITLE', { code: carnet.code }),
                modalMsg: $translate.instant('CARNETS.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await CarnetsService.deleteCarnetById(carnet.id);
                    notification.success($translate.instant('CARNETS.DELETE.SUCCESS'));
                    if (vm.params.id) {
                        vm.state.go('carnets.list');
                    }
                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadCarnets();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    async function updateCarnetPerime() {
        const modalInstance = ModalService.confirm({
            modalTitle: $translate.instant('CARNETS.PASSER_PERIMER.TITLE'),
            modalMsg: $translate.instant('CARNETS.PASSER_PERIMER.MESSAGE'),
            headerClass: 'modal-danger'
        });

        modalInstance.result.then(async function () {
            startLoading();
            try {
                await CarnetsService.updateCarnetPerime();
                notification.success($translate.instant('CARNETS.PASSER_PERIMER.SUCCESS'));
            } catch (ex) {
                notification.error(ex.data);
            } finally {
                stopLoading();
            }
        });
    }

    function printFacture(idFacture) {
        vm.listFactureIdsToPrint = [];
        if (idFacture) {
            vm.listFactureIdsToPrint.push(idFacture);
        }
        const _this = vm;

        vm.$uibModal
            .open({
                template: '<factures-print source="$ctrl.listFactureIdsToPrint" modal-instance="$ctrl.uibModalInstance"></factures-print>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.listFactureIdsToPrint = _this.listFactureIdsToPrint;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'l'
            })
            .result.then(
                function (result) {
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
