import { StateParams, StateService } from '@uirouter/angularjs';
import PrelevementsService from '../../../services/prelevements.service';
import ProduitsService from '../../../../produits/services/produits.service';
import TicketsService from '../../../../tickets/services/tickets.service';
import TypesService from '../../../../types/services/types.service';
import MassiaApplicationService from '../../../../../massia/layout/components/massia-application/massia.application.service';
import Prelevement from '../prelevement.model';
import { BonLivraison, Gachee, Composant } from './BonLivraison';
import * as angular from 'angular';
import { getDefaultCompilerOptions } from 'typescript';

export default class PrelevementBonLivraisonController {
    /* @ngInject */
    //#region Déclaration type
    loading: boolean;
    produitCommercial: any;
    appellationCommercialeProduit: string = '';
    enumUniteFamille: any;
    hydrometrie: Array<any> = [];
    listFamilles: Array<any> = [];
    // sortedGachee: Array<any> = [];
    saisie: Prelevement;
    bonLivraison: any;
    $scope: ng.IScope;
    $state: StateService;
    $stateParams: StateParams;
    notification: any;
    PrelevementsService: PrelevementsService;
    MassiaApplicationService: MassiaApplicationService;
    ProduitsService: ProduitsService;
    TicketsService: TicketsService;
    TypesService: TypesService;
    BL: BonLivraison;
    gachArray: any[];
    resolve: any;
    //#endregion

    constructor(
        $scope: ng.IScope,
        $stateParams: StateParams,
        PrelevementsService: PrelevementsService,
        notification: any,
        MassiaApplicationService: MassiaApplicationService,
        TypesService: TypesService,
        TicketsService: TicketsService,
        ProduitsService: ProduitsService
    ) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.PrelevementsService = PrelevementsService;
        this.notification = notification;
        this.MassiaApplicationService = MassiaApplicationService;
        this.ProduitsService = ProduitsService;
        this.TicketsService = TicketsService;
        this.TypesService = TypesService;
    }

    async $onInit(): Promise<void> {
        this.startLoading();
        this.enumUniteFamille = {
            Ciment: 'kg',
            Granulats: 'kg',
            Adjuvants: 'kg',
            Eau: 'l',
            Additions: 'kg'
        };
        try {
            // Get by id to collect all the wanted items
            const resBl = await this.TicketsService.getBetChargeById(this.resolve.bonLivraison.id);
            this.BL = this.formatGachee(resBl);

            if (this.resolve && this.resolve.saisie && this.resolve.saisie.produitCommercial) {
                this.produitCommercial = this.resolve.saisie.produitCommercial; //.libelle + '[' + this.resolve.saisie.produitCommercial.code + ']';
                if (
                    this.resolve.saisie.produitCommercial.appelationComs &&
                    this.resolve.saisie.produitCommercial.appelationComs.length > 0 &&
                    this.resolve.saisie.produitCommercial.appelationComs.length === 1
                ) {
                    return (this.appellationCommercialeProduit = this.resolve.saisie.produitCommercial.appelationComs[0].appelation);
                }
                return (this.appellationCommercialeProduit = this.resolve.saisie.produitCommercial.appelationComs
                    .map((e: any) => e.appelation)
                    .join(' // '));
            } else if (
                this.resolve &&
                this.resolve.bonLivraison &&
                this.resolve.bonLivraison.ticket &&
                this.resolve.bonLivraison.ticket.tickRMS &&
                this.resolve.bonLivraison.ticket.tickRMS.cpid
            ) {
                const pdtsCom = await this.ProduitsService.getAllProduitCommerciaux(this.resolve.bonLivraison.produit.id, null, null);
                if (pdtsCom && pdtsCom.produitsCom) {
                    const produitCom = pdtsCom.produitsCom.find((x: any) => x.code === this.resolve.bonLivraison.ticket.tickRMS.cpid);
                    if (produitCom) {
                        this.produitCommercial = produitCom; //.libelle + '[' + produitCom.code + ']';
                        if (produitCom.appelationComs && produitCom.appelationComs.length > 0 && produitCom.appelationComs.length === 1) {
                            return (this.appellationCommercialeProduit = produitCom.appelationComs[0].appelation);
                        }
                        return (this.appellationCommercialeProduit = produitCom.appelationComs.map((e: any) => e.appelation).join(' // '));
                    }
                }
            }

            this.hydrometrie = resBl.betGachee.filter((e: any) => e.w && e.w > 0);

            // Take the product that has a water content value
        } catch (err) {
            this.notification.error(err.data);
        } finally {
            this.stopLoading();
        }
    }

    $onDestroy(): void {}

    async loadFamilleTypeProduct(list: Array<any>, famille: string): Promise<void> {
        const element = list.find((e) => e.libelle === famille);
        element.typeProduit = await this.ProduitsService.getProduitSpecificites(element.betGachee[0].produit.idType);
    }

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }

    public formatGachee(data: any): BonLivraison {
        const BL = new BonLivraison(data);
        return BL;
    }
}
