const serviceUrl = `${__configuration.apiUrl}/massia/type-bascules`;

export default class TypeBasculesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getTypeBascules(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async deleteTypeBasculeById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getTypeBasculeById(id) {
        const url = `${serviceUrl}/${id}/entete`; // TODO SL supprimer entete quand le back sera a jour
        const res = await this.$http.get(url);
        return res.data;
    }

    async createTypeBascule(selectedTypeBascule) {
        const data = {
            code: selectedTypeBascule.code,
            libelle: selectedTypeBascule.libelle,
            transmissionVitesse: selectedTypeBascule.transmissionVitesse,
            transmissionBitStart: selectedTypeBascule.transmissionBitStart,
            transmissionBitStop: selectedTypeBascule.transmissionBitStop,
            transmissionBitDate: selectedTypeBascule.transmissionBitDate,
            transmissionParite: selectedTypeBascule.transmissionParite,
            connexionAdresseIP: selectedTypeBascule.connexionAdresseIP,
            connexionPort: selectedTypeBascule.connexionPort,
            connexionProtocole: selectedTypeBascule.connexionProtocole,
            avecPasserelleBorne: selectedTypeBascule.avecPasserelleBorne,
            isSerieIp: selectedTypeBascule.isSerieIp
        };

        return this.$http.post(serviceUrl, data);
    }

    async updateTypeBascule(selectedTypeBascule) {
        const data = {
            code: selectedTypeBascule.code,
            libelle: selectedTypeBascule.libelle,
            transmissionVitesse: selectedTypeBascule.transmissionVitesse,
            transmissionBitStart: selectedTypeBascule.transmissionBitStart,
            transmissionBitStop: selectedTypeBascule.transmissionBitStop,
            transmissionBitDate: selectedTypeBascule.transmissionBitDate,
            transmissionParite: selectedTypeBascule.transmissionParite,
            connexionAdresseIP: selectedTypeBascule.connexionAdresseIP,
            connexionPort: selectedTypeBascule.connexionPort,
            connexionProtocole: selectedTypeBascule.connexionProtocole,
            avecPasserelleBorne: selectedTypeBascule.avecPasserelleBorne,
            isSerieIp: selectedTypeBascule.isSerieIp
        };

        const url = `${serviceUrl}/${selectedTypeBascule.id}`;
        return this.$http.put(url, data);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getProtocoles() {
        const url = `${__configuration.apiUrl}/massia/protocole-type-bascules`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
