import ChantiersConfig from './_config/chantiers.config';
import ChantiersRoutes from './_config/chantiers.routes';
import ProvenancesRoutes from './_config/provenances.routes';
import ChantiersComponent from './components/chantiers';
import ChantiersService from './services/chantiers.service';
import ChantiersTypesService from './services/chantiers.types.service';
import ChantiersCommunicationService from './services/chantiers.communication.service';

import ChantierFormComponent from './components/chantier-form';
import ChantierDetailComponent from './components/chantier-detail';
import ChantierFormEnteteComponent from './components/chantier-form/entete';
import ChantierFormCaracteristiquesComponent from './components/chantier-form/caracteristiques';
import ChantierComposantComponent from './components/chantier-composant';

import ChantierValidator from './components/chantier-form/chantier.validator';
import ChantierEnteteValidator from './components/chantier-form/entete/chantier.entete.validator';
import ChantierCaracteristiquesValidator from './components/chantier-form/caracteristiques/chantier.caracteristiques.validator';

const moduleName = 'app.massia.common.chantiers';

angular
    .module(moduleName, [])
    .config(ChantiersConfig)
    .config(ChantiersRoutes)
    .config(ProvenancesRoutes)
    .service('ChantiersService', ChantiersService)
    .service('ChantiersTypesService', ChantiersTypesService)
    .service('ChantiersCommunicationService', ChantiersCommunicationService)
    .component('chantiers', ChantiersComponent)
    .component('chantierForm', ChantierFormComponent)
    .component('chantierDetail', ChantierDetailComponent)
    .component('chantierFormEntete', ChantierFormEnteteComponent)
    .component('chantierFormCaracteristiques', ChantierFormCaracteristiquesComponent)
    .component('chantierComposant', ChantierComposantComponent)
    .factory('ChantierValidator', ChantierValidator)
    .factory('ChantierEnteteValidator', ChantierEnteteValidator)
    .factory('ChantierCaracteristiquesValidator', ChantierCaracteristiquesValidator);

export default moduleName;
