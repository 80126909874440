const serviceUrl = `${__configuration.apiUrl}/massia/parite-type-bascules`;

class PariteTypeBascule {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getParites() {
        const res = await this.$http.get(serviceUrl);
        return res.data;
    }
}

export default PariteTypeBascule;
