import validatorBuilder from 'validator-builder';

let prelevementMesureValidator = null;

export default class PrelevementMesure {
    constructor(data = {}) {
        prelevementMesureValidator = prelevementMesureValidator || validatorBuilder.getInstanceFor('PrelevementMesure');
        prelevementMesureValidator.clearRules();
        prelevementMesureValidator.generateRules();

        this.data = data;
        this.data.forceSaisie = data.forceSaisie;
        if (data.value || data.value === 0) {
            this.data.value = data.value;
        } else if (data.valeur || data.valeur === 0) {
            this.data.value = data.valeur;
        } else if (data.default !== null && typeof data.default !== 'undefined' && data.isNew) {
            this.data.value = data.default;
        } else if (data.nature === 'ElementListe') {
            if (data.valeur) {
                this.data.value = data.valeur;
            } else if (data.valeurs) {
                this.data.value = data.valeurs;
            } else {
                this.data.value = [];
            }
        } else if (data.valeurs) {
            this.data.value = data.valeurs;
        } else {
            this.data.value = [];
        }
    }

    isValid() {
        const validationResults = prelevementMesureValidator.validate(this);
        return validationResults.isValid;
    }
}
