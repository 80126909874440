import PortailConfiguration from './_config/portail.config';
import PortailRoute from './_config/portail.routes';
import home from './portail-home';
import stats from './portail-stats';
import settingModule from './portail-setting';
import userModule from './portail-user';
import groupModule from './portail-group';
import fileModule from './portail-file';
import requestModule from './portail-request';
import userAddRequestModule from './portail-user-add-request';
import affectationModule from './portail-affectation';
import UtilisateurPortailService from './service/utilisateurPortail.service';
import GroupPortailService from './service/groupPortail.service';
import DomainPortailService from './service/domainPortail.service';
import SharedFileService from './service/sharedFile.service';
import SettingPortailService from './service/settingPortail.service';
import UserRequestService from './service/userRequest.service';
import portailStatsCard from './portail-stats/portail-stats-card';
import portailConstant from './_config/portail.constant';
import AffectationPortailService from './service/affectationPortail.service';

const moduleName = 'app.massia.common.portail';

angular
    .module(moduleName, [userModule, groupModule, fileModule, settingModule, requestModule, userAddRequestModule, affectationModule])
    .config(PortailConfiguration) //Route
    .config(PortailRoute) //Traduction
    .constant('configPortail', portailConstant)
    .component('portailHome', home)
    .component('portailStats', stats)
    .component('portailStatsCard', portailStatsCard)
    .service('UtilisateurPortailService', UtilisateurPortailService)
    .service('GroupPortailService', GroupPortailService)
    .service('DomainPortailService', DomainPortailService)
    .service('SharedFileService', SharedFileService)
    .service('SettingPortailService', SettingPortailService)
    .service('UserRequestService', UserRequestService)
    .service('AffectationPortailService', AffectationPortailService);
export default moduleName;
