import validatorBuilder from 'validator-builder';

let mesureCourbeGranuloValidator = null;

export default class MesureCourbeGranulo {
    constructor(data = {}) {
        this.id = data.id;
        this.positionLegende = data.positionLegende;
        this.tailleLegende = data.tailleLegende;
        this.afficheAbscisses = data.afficheAbscisses;
        this.echelleAbsicces = data.echelleAbsicces;
        this.tousTamis = data.tousTamis;
        this.afficheOrdonnees = data.afficheOrdonnees;
        this.echelleOrdonnees = data.echelleOrdonnees;
        this.limiterPassants = data.limiterPassants;
        this.inverserX = data.inverserX;
        this.inverserY = data.inverserY;
        this.deriverCourbe = data.deriverCourbe;
        this.lisser = data.lisser;
        this.listeTracesResultats = data.listeTracesResultats || [];
        this.listeIndicesReferences = data.listeIndicesReferences || [];
        this.listeTracesComposants = data.listeTracesComposants || [];
    }

    isValid() {
        mesureCourbeGranuloValidator = mesureCourbeGranuloValidator || validatorBuilder.getInstanceFor('MesureCourbeGranulo');
        const validationResults = mesureCourbeGranuloValidator.validate(this);
        return validationResults.isValid;
    }
}
