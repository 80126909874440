import TransportsConfig from './_config/transports.config';
import TransportsRoutes from './_config/transports.routes';
import TransportsComponent from './components/transports';
import TransportsService from './services/transports.service';
import TransportsCommunicationService from './services/transports.communication.service';

import TransportFormComponent from './components/transport-form';
import TransportDetailComponent from './components/transport-detail';
import TransportEnteteComponent from './components/transport-entete';

import TransportValidator from './components/transport-form/transport.validator';
import TransportEnteteValidator from './components/transport-entete/transport.entete.validator';

const moduleName = 'app.parametrageGeneral.transports';

angular
    .module(moduleName, [])
    .config(TransportsConfig)
    .config(TransportsRoutes)
    .service('TransportsService', TransportsService)
    .service('TransportsCommunicationService', TransportsCommunicationService)
    .component('transports', TransportsComponent)
    .component('transportForm', TransportFormComponent)
    .component('transportDetail', TransportDetailComponent)
    .component('transportEntete', TransportEnteteComponent)
    .factory('TransportValidator', TransportValidator)
    .factory('TransportEnteteValidator', TransportEnteteValidator);

export default moduleName;
