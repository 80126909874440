(function (angular, undefined) {
    'use strict';

    angular.module('blocks.datepicker')
        .config(appConfig);

    appConfig.$inject = ['$translatePartialLoaderProvider'];

    /* @ngInject */
    function appConfig($translatePartialLoaderProvider) {
        $translatePartialLoaderProvider.setPart('fr-FR', 'blocks.datepicker', {
            'TODAY': 'Aujourd\'hui',
            'CLEAR': 'Vider',
            'CLOSE': 'Fermer'
        });

        $translatePartialLoaderProvider.setPart('en-US', 'blocks.datepicker', {
            'TODAY': 'Today',
            'CLEAR': 'Clear',
            'CLOSE': 'Close'
        });

        $translatePartialLoaderProvider.setPart('es-ES', 'blocks.datepicker', {
            'TODAY': 'Hoy',
            'CLEAR': 'Vacía',
            'CLOSE': 'Cerca'
        });

        $translatePartialLoaderProvider.setPart('nl-BE', 'blocks.datepicker', {
            'TODAY': 'Vandaag',
            'CLEAR': 'Leeg',
            'CLOSE': 'Sluiten'
        });

        $translatePartialLoaderProvider.addPart('blocks.datepicker');
    }
})(angular);