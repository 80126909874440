(function (angular, undefined) {
    'use strict';

    angular.module('blocks.filters').filter('BooleanYesNoFilter', BooleanYesNoFilter);

    function BooleanYesNoFilter() {
        return function(text) {
            if (text) {
                return 'BLOCKS_FILTERS_YES';
            }
            return 'BLOCKS_FILTERS_NO';
        };
    }

})(angular);

