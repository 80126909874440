SocietesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'SocietesService',
    'SocietesTypesService',
    'notification',
    'Upload',
    '_',
    'MassiaApplicationService',
    '$transitions',
    'MOPService'
];

export default function SocietesController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    SocietesService,
    SocietesTypesService,
    notification,
    Upload,
    _,
    MassiaApplicationService,
    $transitions,
    MOPService
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    vm.selectedSocieteId = undefined;
    vm.lastSelected = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.MOPService = MOPService;
    vm.societes = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];
    vm.getTypes = getTypes;

    vm.selectSociete = selectSociete;
    vm.isSocieteSelected = isSocieteSelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadSocietes = loadSocietes;
    vm.deleteSociete = deleteSociete;
    vm.setSelected = setSelected;
    vm.eraseAllFilters = eraseAllFilters;
    vm.getUrl = getUrl;
    vm.getApplication = getApplication;

    vm.$onInit = () => {
        if (vm.getApplication() === 'laboratoire') {
            vm.MOPService.setMop([{ title: 'SOCIETES.BREADCRUMBS.SOCIETES_LIST', filename: 'SocietesMassia.pdf', application: 'laboratoire' }]);
        }

        $transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }

        getGenres();
        getTypes();
    };

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    function stateChangeSuccessFunc(event) {
        const id = parseInt($state.params.id);
        if (id) {
            setSelected({ id: id });
        } else {
            setSelected();
        }
    }

    async function getGenres() {
        vm.genres = await SocietesService.getGenres();
    }

    async function getTypes(event, toState) {
        vm.types = await SocietesTypesService.getAll();
    }

    function setSelected(societe) {
        if (societe && societe.id) {
            vm.selectedSocieteId = $stateParams.id;
            vm.lastSelected = vm.selectedSocieteId;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            vm.selectedSocieteId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return $state && $state.current && $state.current.name && $state.current.name === 'societes.list.detail' && $stateParams && $stateParams.id;
    }

    function selectSociete(societe) {
        if (societe && societe.id) {
            if (vm.selectedSocieteId !== societe.id) {
                vm.selectedSocieteId = societe.id;
                showDetail(societe.id);
            } else {
                vm.selectedSocieteId = undefined;
                closeDetail();
            }
        }
    }

    function isSocieteSelected(societe) {
        return societe && societe.id && vm.selectedSocieteId === societe.id;
    }

    function showDetail(societeId) {
        $state.go(getUrlDetail(), { id: societeId });
    }

    function closeDetail() {
        $state.go(getUrlListe());
    }

    async function loadSocietes(tableState) {
        startLoading();

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.societes = [];

        try {
            const data = await SocietesService.getSocietes(filters, sorts, pagination, $stateParams.genre, vm.getApplication());

            vm.societes = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteSociete(societe) {
        if (societe /*&& societe.canDelete*/ && societe.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('SOCIETES.DELETE.TITLE', { code: societe.libelle }),
                modalMsg: $translate.instant('SOCIETES.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await SocietesService.deleteSocieteById(societe.id);
                    notification.success($translate.instant('SOCIETES.DELETE.SUCCESS'));

                    vm.selectedSocieteId = undefined;

                    if (vm.params.id) {
                        vm.state.go('societes.list');
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadSocietes();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function eraseAllFilters() {
        loadSocietes();
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }

    function getUrl() {
        switch ($stateParams.genre) {
            case '1':
                return 'societes';
            case '2':
                return 'societes-client';
            case '4':
                return 'societes-producteur';
            case '8':
                return 'societes-fournisseur';
            case '16':
                return 'societes-transporteur';
            case '32':
                return 'societes-laboratoire';
            case '64':
                return 'societes-proprietaire';
            case '128':
                return 'societes-locataire';
            case '256':
                return 'societes-auditeur';
            default:
                return 'societes';
        }
    }

    function getUrlDetail() {
        switch ($stateParams.genre) {
            case '1':
                return 'societes.list.detail';
            case '2':
                return 'societes-client.list.detail';
            case '4':
                return 'societes-producteur.list.detail';
            case '8':
                return 'societes-fournisseur.list.detail';
            case '16':
                return 'societes-transporteur.list.detail';
            case '32':
                return 'societes-laboratoire.list.detail';
            case '64':
                return 'societes-proprietaire.list.detail';
            case '128':
                return 'societes-locataire.list.detail';
            case '256':
                return 'societes-auditeur.list.detail';
            default:
                return 'societes.list.detail';
        }
    }

    function getApplication() {
        return MassiaApplicationService.getApplication();
    }

    function getUrlListe() {
        switch ($stateParams.genre) {
            case '1':
                return 'societes.list';
            case '2':
                return 'societes-client.list';
            case '4':
                return 'societes-producteur.list';
            case '8':
                return 'societes-fournisseur.list';
            case '16':
                return 'societes-transporteur.list';
            case '32':
                return 'societes-laboratoire.list';
            case '64':
                return 'societes-proprietaire.list';
            case '128':
                return 'societes-locataire.list';
            case '256':
                return 'societes-auditeur.list';
            default:
                return 'societes.list';
        }
    }
}
