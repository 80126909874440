export default class GestionStocksAjustementController {
    static $inject = ['$stateParams', 'StocksCommunicationService', 'StocksService', 'notification', 'ModalService', '$uibModal'];

    constructor($stateParams, StocksCommunicationService, StocksService, notification, ModalService, $uibModal) {
        this.$stateParams = $stateParams;
        this.StocksCommunicationService = StocksCommunicationService;
        this.StocksService = StocksService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.quantite = 0;
        this.idMotif = 0;
        this.motifs = await this.StocksService.getMotifs();
    }

    annuler() {
        this.modalInstance.close(false);
    }

    async confirmer() {
        //ajouter la nouvelle prod traitée
        const prod = {
            quantite: this.quantite
        };
        if (!this.ligne.newProductionsTraitees) {
            this.ligne.newProductionsTraitees = [];
        }
        this.ligne.newProductionsTraitees.push(prod);
        //reclaculer la variation, la prod traitée et le stock final de la ligne
        if (this.ligne.variation.valeur) {
            this.ligne.variation.valeur = this.ligne.variation.valeur + this.quantite;
        } else {
            this.ligne.variation.valeur = this.quantite;
        }
        if (this.ligne.stockFinal.valeur) {
            this.ligne.stockFinal.valeur = this.ligne.stockFinal.valeur + this.quantite;
        } else {
            this.ligne.stockFinal.valeur = this.quantite;
        }
        if (this.ligne.productionTraitee.valeur) {
            this.ligne.productionTraitee.valeur = this.ligne.productionTraitee.valeur + this.quantite;
        } else {
            this.ligne.productionTraitee.valeur = this.quantite;
        }
        this.modalInstance.close(prod);
    }
}
