import ChantierFormEnteteController from './chantier.form.entete.controller';

export default {
    bindings: {
        chantier: '<',
        form: '=',
        onUpdate: '&'
    },
    template: require('./chantier.form.entete.html'),
    controller: ChantierFormEnteteController
};
