import { StateRegistry } from '@uirouter/angularjs';

export default class MassiaNavigationController {
    static $inject = [
        '_',
        '$translate',
        '$state',
        'MassiaApplicationService',
        'MassiaNavigationService',
        'MassiaNavigationConfigConstant',
        'MassiaRightsService',
        'MassiaAuthService',
        'MassiaClientService',
        '$stateRegistry',
        '$timeout'
    ];

    constructor(
        _,
        $translate,
        $state,
        MassiaApplicationService,
        MassiaNavigationService,
        MassiaNavigationConfigConstant,
        MassiaRightsService,
        MassiaAuthService,
        MassiaClientService,
        $stateRegistry,
        $timeout
    ) {
        // Services
        this._ = _;
        this.$state = $state;
        this.$translate = $translate;
        this.MassiaApplicationService = MassiaApplicationService;
        this.MassiaNavigationService = MassiaNavigationService;
        this.MassiaNavigationConfigConstant = MassiaNavigationConfigConstant;
        this.MassiaRightsService = MassiaRightsService;
        this.MassiaAuthService = MassiaAuthService;
        this.MassiaClientService = MassiaClientService;
        this.$stateRegistry = $stateRegistry;
        this.$timeout = $timeout;

        this.maxItem = 10;

        // Properties
        this.collapse = true;
        this.brand = null;
        this.search = {
            open: false,
            term: '',
            all: this.getSearchsByStates(this.$state.get()),
            navigationItems: []
        };
    }

    $onInit() {
        this.customInit();
        this.$stateRegistry.onStatesChanged((event) => {
            this.$timeout(() => {
                switch (event) {
                    case 'deregistered':
                    case 'registered':
                        this.customInit();
                        break;
                    default:
                        break;
                }
            }, 100);
        });
    }

    customInit() {
        const activite = this.MassiaApplicationService.getApplication();
        this.search.all = this.getSearchsByStates(this.$state.get());
        this.getNavigation(activite);
        this.getTools(activite);
        // une fois que tout est enregistré dans le service, on publie à la vue les items de navigation
        this.res = this.MassiaNavigationService.getItems();

        if (this.res) {
            this.calculStyle(this.res);
        }
    }

    $onDestroy() {
        this.MassiaNavigationService.clearItems();
    }

    getSearchsByStates(states = []) {
        return states
            .filter((state) => {
                return (
                    state &&
                    state.search &&
                    (state.rights === undefined ||
                        this.MassiaRightsService.userHasRight(state.rights.domain, state.rights.right, state.rights.application))
                );
            })
            .map((state) => {
                return {
                    name: state.name,
                    search: this.$translate.instant(state.search)
                };
            });
    }

    onSearch(select) {
        this.search.term = select;

        this.search.items = this.search.all.filter(function (state) {
            return select && state.search && state.search.toLowerCase().indexOf(select.toLowerCase()) >= 0;
        }, this);
    }

    onSelect(item) {
        if (item && item.name) {
            this.$state.go(item.name);
            this.search.open = false;
        }
    }

    getNavigation(activite) {
        const navigationDropdowns = angular.copy(this.MassiaNavigationConfigConstant.navigationMenus).filter((navMenu) => {
            if (!(activite != 'gestion' && navMenu.id === 'transport')) {
                //masquer transport hors gestion
                return (
                    navMenu &&
                    // on retire ceux que l'utilisateur n'a pas le droit de voir
                    (navMenu.rights === undefined ||
                        this.MassiaRightsService.userHasRight(navMenu.rights.domain, navMenu.rights.right, navMenu.rights.application)) &&
                    //on retire ceux qui ont une prop de restriction client
                    (navMenu.clientRestriction === undefined || navMenu.clientRestriction.indexOf(this.MassiaClientService.getClient()) >= 0)
                );
            }
        });

        let states = this._.chain(this.$state.get())
            .filter((state) => {
                return (
                    state &&
                    // on ne prend que les states concernés par la navigation
                    state.navigation &&
                    // on retire ceux que l'utilisateur n'a pas le droit de voir
                    (state.rights === undefined ||
                        this.MassiaRightsService.userHasRight(state.rights.domain, state.rights.right, state.rights.application)) &&
                    !(activite === 'gestion' && state.navigation.menu === 'donnees_parametres_donnees' && state.rights.domain === 'lieu') //enlever l'accès aux lieu via données
                );
            })
            .map((state) => {
                // on enregistre le nom du state dans la configuration de la navigation
                state.navigation.sref = state.name;
                state.navigation.id = state.name;
                // puis on ne récupère que la configuration de navigation
                return state.navigation;
            })
            .value();

        //si 2x clotures périodiques, on supprime 1 des 2
        const precloture = this._.find(states, { sref: 'stocks-preclotures.precloture-mois' });
        const cloture = this._.find(states, { sref: 'stocks-clotures.precloture-mois' });
        if (precloture && cloture) {
            states = this._.reject(states, function (item) {
                return item.sref === 'stocks-preclotures.precloture-mois';
            });
        }
        // on récupère la brand
        this.brand = this._.find(states, { position: 'brand', sref: `${this.MassiaApplicationService.getApplication()}.home` });
        // on groupe par menu afin d'ajouter rapidement les states dans les menus pré-définis
        const navigationMenuItems = this._.groupBy(states, 'menu');
        // si jamais il y a des states sans menu défini, on les ajoute à la suite de la navigation par dropdown
        this._.forEach(navigationMenuItems[undefined], (item) => {
            this.MassiaNavigationService.addItem(item, activite);
        });
        // on construit la navigation
        this._.forEach(navigationDropdowns, (menu) => {
            const idsToDelete = [];
            // pour chaque sous-menu, on enregistre les states correspondant
            this._.forEach(menu.items, (submenu) => {
                //supprimer les sous-menus en dur que l'utilisateur n'a pas le droit de voir
                const idsSPToDelete = [];
                this._.forEach(submenu.items, (sousmenudur) => {
                    if (
                        !(
                            sousmenudur.rights === undefined ||
                            this.MassiaRightsService.userHasRight(sousmenudur.rights.domain, sousmenudur.rights.right, sousmenudur.rights.application)
                        )
                    ) {
                        idsSPToDelete.push(sousmenudur.id);
                    }
                });

                if (submenu.id !== undefined && navigationMenuItems[submenu.id] !== undefined) {
                    // s'il existe déjà des items en dur, on les garde
                    submenu.items = submenu.items.concat(navigationMenuItems[submenu.id]);
                }

                if (idsSPToDelete.length > 0) {
                    idsSPToDelete.forEach((id) => this._.remove(submenu.items, { id: id }));
                }

                // s'il n'y a pas de state enregistrés dans ce menu
                if (submenu.items !== undefined && submenu.items.length === 0) {
                    // on le prépare à la suppression
                    idsToDelete.push(submenu.id);
                }
            });
            // une fois le traitement passé, on nettoie les menu principaux
            if (idsToDelete.length > 0) {
                idsToDelete.forEach((id) => this._.remove(menu.items, { id: id }));
            }
            // puis on ajoute le menu à la navigation
            if (!menu.items || menu.items.length > 0) {
                this.MassiaNavigationService.addItem(menu, activite);
            }
        });
    }

    debug(item) {
        //
    }

    getTools(activite) {
        const navigationTools = angular.copy(this.MassiaNavigationConfigConstant.tools);
        this.MassiaNavigationService.addItem(navigationTools, activite);
    }

    onCollapse($event, dropdown, navItem) {
        $event.stopPropagation();
        navItem.map((x) => {
            if (x.id === dropdown.id) {
                x.collapse = true;
            } else {
                x.collapse = false;
            }
        });
    }

    calculStyle(navigation) {
        navigation = angular.copy(navigation);
        for (let i = 0; i < navigation.length; i++) {
            if (navigation[i].items && navigation[i].items.length !== 0 && screen.width > 768) {
                const items = navigation[i].items;
                for (let k = 0; k < items.length; k++) {
                    if (items[k].items && items[k].items.length !== 0) {
                        items[k].ulStyle = {
                            display: 'grid',
                            'grid-auto-flow': 'column',
                            'grid-template-rows': 'repeat(' + this.maxItem + ', auto)'
                        };
                    }
                }
            }
        }
        this.navigationItems = navigation;
    }

    closeAllCollapsed(array) {
        array.map((e) => (e.collapse = false));
    }

    onClose(item, drop) {
        const elem = document.getElementById(item.id);
        elem.click();

        setTimeout(() => {
            drop.collapse = false;
        }, 100);
    }

    async changeApplication(menu) {
        const client = this.MassiaClientService.getClient();
        if (client && menu['href_' + client]) {
            window.location.href = menu['href_' + client];
        } else {
            /* let pathName = '';
            const paths = window.location.pathname.split('/');
            for (let i = 0; i < paths.length; i++) {
                if (paths[i] && !paths[i].endsWith('.html')) {
                    pathName += paths[i] + '/';
                }
            } */

            const application = menu.href.replace('.html', '');
            this.MassiaNavigationService.clearItems();
            this.$state.go(`${application}.home`);
            this.MassiaApplicationService.setApplication(application);
            await this.MassiaAuthService.authenticateUser(application);
            this.$onInit();
            /* window.location.href = window.location.origin + '/' + pathName + menu.href; */
        }
    }
}
