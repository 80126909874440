import { StateProvider } from '@uirouter/angularjs';

/**
 *
 * @param {StateProvider} $stateProvider
 */
export default function Configure($stateProvider, MassiaApplicationProvider) {
    const app = MassiaApplicationProvider.app;

    $stateProvider.state('producteurs', {
        parent: 'common',
        url: '/producteurs',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('producteurs.list', {
        url: '/:genre/list',
        template: '<sites></sites>',
        rights: { domain: 'siteprod', right: 'read' },
        ncyBreadcrumb: {
            parent: 'producteurs',
            label: '{{ "SITES.BREADCRUMBS.SITES_PRODUCTEURS_LIST" | translate}}'
        }
    });

    $stateProvider.state('producteurs.niveaux', {
        url: '/:genre/niveaux',
        template: '<site-config-niveaux></site-config-niveaux>',
        rights: { domain: 'nivh', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher sites > niveaux
            parent: 'producteurs.list({genre: "producteurs"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_NIVEAUX" | translate }}'
        }
    });

    $stateProvider.state('producteurs.list.detail', {
        url: '/:genre/{id}',
        template: '<site-detail></site-detail>',
        ncyBreadcrumb: {
            parent: 'producteurs.list({genre: "producteurs"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('producteurs.new', {
        url: '/:genre/new',
        template: '<site-form></site-form>',
        rights: { domain: 'siteprod', right: 'create' },
        ncyBreadcrumb: {
            parent: 'producteurs.list({genre: "producteurs"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_NEW_PRODUCTEUR" | translate}}'
        },
        search: 'SITES.BREADCRUMBS.SITE_NEW_PRODUCTEUR'
    });

    $stateProvider.state('producteurs.edit', {
        url: '/:genre/{id}/edit',
        template: '<site-form></site-form>',
        rights: { domain: 'siteprod', right: 'update' },
        ncyBreadcrumb: {
            parent: 'producteurs.list({genre: "producteurs"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_EDIT_PRODUCTEUR" | translate }}'
        }
    });

    /*$stateProvider.state('producteurs.points-mesure', {
        url: '/:genre/{idSite}/points-mesure',
        template: '<points-mesure></points-mesure>',
        rights: {domain: 'siteprod', right: 'update'},
        ncyBreadcrumb: {
            parent: 'producteurs.list({genre: "producteurs"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_EDIT_PRODUCTEUR" | translate }}'
        }
    });*/
    $stateProvider.state('producteurs.feries', {
        url: '/:genre/{id}/feries',
        template: '<site-jours-feries></site-jours-feries>',
        rights: { domain: 'jferies', right: 'create' },
        ncyBreadcrumb: {
            parent: 'producteurs.list({genre: "producteurs"})',
            label: app === 'performance' ? '{{ "SITES.BREADCRUMBS.JOURS_CHOMES" | translate }}' : '{{ "SITES.BREADCRUMBS.JOURS_FERIES" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider', 'MassiaApplicationProvider'];
