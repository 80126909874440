import { ICompileService, IDocumentService, IParseService, IRootScopeService, IScope, IQService, ISCEService, IWindowService, IAugmentedJQuery } from "angular";
import * as angular from 'angular';
import { Directive, Input, OnInit } from "../../../core/decorators";

@Directive({ selector: '[context-menu]' })
export class ContextMenu implements OnInit {
    @Input() contextMenuEmptyText: any;
    @Input() contextMenuOn: any;
    @Input() allowEventPropagation: any;
    @Input() contextMenuClass: any;
    @Input() model: any;
    @Input() contextMenuOrientation: any;
    @Input() contextMenu: any;
    @Input() closeMenuOn: any;

    _contextMenus: any[] = [];
    _clickedElement: any = null;
    _emptyText: string = 'empty';

    DEFAULT_ITEM_TEXT: string = '"New Item';

    /* @ngInject */
    constructor(public $scope: IScope, public $rootScope: IRootScopeService, public ContextMenuEvents: any,
        public $parse: IParseService, public $q: IQService, public $sce: ISCEService, public $element: IAugmentedJQuery,
        public $document: IDocumentService, public $window: IWindowService, public $compile: ICompileService) {

    }

    ngOnInit(): void {
        let t = this;
        var openMenuEvents = ['contextmenu'];
        t._emptyText = t.contextMenuEmptyText || 'empty';

        if (t.contextMenuOn && typeof t.contextMenuOn === 'string') {
            openMenuEvents = t.contextMenuOn.split(',');
        }

        angular.forEach(openMenuEvents, (openMenuEvent) => {
            t.$element.on(openMenuEvent.trim(), (event) => {
                // Cleanup any leftover contextmenus(there are cases with longpress on touch where we
                // still see multiple contextmenus)
                t.removeAllContextMenus(t);

                if (!t.allowEventPropagation) {
                    event.stopPropagation();
                    event.preventDefault();
                }

                // Don't show context menu if on touch device and element is draggable
                if (t.isTouchDevice() && t.$element.attr('draggable') === 'true') {
                    return false;
                }

                // Remove if the user clicks outside
                t.$document.find('body').on('mousedown touchstart', (e) => t.removeOnOutsideClickEvent(e, t));
                // Remove the menu when the scroll moves
                t.$document.on('scroll', t.removeOnScrollEvent);

                t._clickedElement = event.currentTarget;
                $(t._clickedElement).addClass('context');

                t.$scope.$apply(() => {
                    var options = t.contextMenu;
                    var customClass = t.contextMenuClass;
                    var modelValue = t.model;
                    var orientation = t.contextMenuOrientation;

                    t.$q.when(options).then((promisedMenu) => {
                        if (angular.isFunction(promisedMenu)) {
                            //  support for dynamic items
                            promisedMenu = promisedMenu.call(t.$scope, t.$scope, event, modelValue);
                        }
                        var params = {
                            $scope: t.$scope,
                            event: event,
                            options: promisedMenu,
                            modelValue: modelValue,
                            level: 0,
                            customClass: customClass,
                            orientation: orientation,
                        };
                        t.$rootScope.$broadcast(t.ContextMenuEvents.ContextMenuOpening, { context: t._clickedElement });
                        t.renderContextMenu(params, t);
                    });
                });

                // Remove all context menus if the scope is destroyed
                t.$scope.$on('$destroy', () => {
                    t.removeAllContextMenus(t);
                });
            });
        });

        if (t.closeMenuOn) {
            t.$scope.$on(t.closeMenuOn, () => {
                t.removeAllContextMenus(t);
            });
        }
    };

    createAndAddOptionText(params: any) {
        // Destructuring:
        var $scope = params.$scope;
        var item = params.item;
        var event = params.event;
        var modelValue = params.modelValue;
        var $promises = params.$promises;
        var nestedMenu = params.nestedMenu;
        var $li = params.$li;
        var leftOriented = String(params.orientation).toLowerCase() === 'left';

        var optionText = null;

        if (item.html) {
            if (angular.isFunction(item.html)) {
                // runs the function that expects a jQuery/jqLite element
                optionText = item.html($scope);
            } else {
                // Incase we want to compile html string to initialize their custom directive in html string
                if (item.compile) {
                    optionText = this.$compile(item.html)($scope);
                } else {
                    // Assumes that the developer already placed a valid jQuery/jqLite element
                    optionText = item.html;
                }
            }
        } else {
            var $a = $('<a>');
            var $anchorStyle: any = {};

            if (leftOriented) {
                $anchorStyle.textAlign = 'right';
                $anchorStyle.paddingLeft = '8px';
            } else {
                $anchorStyle.textAlign = 'left';
                $anchorStyle.paddingRight = '8px';
            }

            $a.css($anchorStyle);
            $a.addClass('dropdown-item');
            $a.attr({ tabindex: '-1', href: '#' });

            var textParam = item.text || item[0];
            var text = this.DEFAULT_ITEM_TEXT;

            if (typeof textParam === 'string') {
                text = textParam;
            } else if (typeof textParam === 'function') {
                text = textParam.call($scope, $scope, event, modelValue);
            }

            var $promise = this.$q.when(text);
            $promises.push($promise);
            $promise.then(function (pText: string) {
                if (nestedMenu) {
                    var $arrow;
                    var $boldStyle: any = {
                        fontFamily: 'monospace',
                        fontWeight: 'bold',
                    };

                    if (leftOriented) {
                        $arrow = '&lt;';
                        $boldStyle.float = 'left';
                    } else {
                        $arrow = '&gt;';
                        $boldStyle.float = 'right';
                    }

                    var $bold = $('<strong style="font-family:monospace;font-weight:bold;float:right;">' + $arrow + '</strong>');
                    $bold.css($boldStyle);
                    $a.css('cursor', 'default');
                    $a.append($bold);
                }
                $a.append(pText);
            });

            optionText = $a;
        }

        $li.append(optionText);

        return optionText;
    }

    /**
     * Process each individual item
     *
     * Properties of params:
     * - $scope
     * - event
     * - modelValue
     * - level
     * - item
     * - $ul
     * - $li
     * - $promises
     */
    processItem(params: any, t: ContextMenu) {
        var nestedMenu = t.extractNestedMenu(params);

        // if html property is not defined, fallback to text, otherwise use default text
        // if first item in the item array is a function then invoke .call()
        // if first item is a string, then text should be the string.

        var text = t.DEFAULT_ITEM_TEXT;
        var currItemParam = angular.extend({}, params);
        var item = params.item;
        var enabled = item.enabled === undefined ? item[2] : item.enabled;

        currItemParam.nestedMenu = nestedMenu;
        currItemParam.enabled = t.resolveBoolOrFunc(enabled, params);
        currItemParam.text = t.createAndAddOptionText(currItemParam);

        t.registerCurrentItemEvents(currItemParam, t);
    }

    /*
     * Registers the appropriate mouse events for options if the item is enabled.
     * Otherwise, it ensures that clicks to the item do not propagate.
     */
    registerCurrentItemEvents(params: any, t: ContextMenu) {
        // Destructuring:
        var item = params.item;
        var $ul = params.$ul;
        var $li = params.$li;
        var $scope = params.$scope;
        var modelValue = params.modelValue;
        var level = params.level;
        var event = params.event;
        var text = params.text;
        var nestedMenu = params.nestedMenu;
        var enabled = params.enabled;
        var orientation = String(params.orientation).toLowerCase();
        var customClass = params.customClass;

        if (enabled) {
            var openNestedMenu = ($event: any) => {
                t.removeContextMenus(t, level + 1);
                /*
                 * The object here needs to be constructed and filled with data
                 * on an "as needed" basis. Copying the data from event directly
                 * or cloning the event results in unpredictable behavior.
                 */
                /// adding the original event in the object to use the attributes of the mouse over event in the promises
                var ev = {
                    pageX: orientation === 'left' ? event.pageX - $ul[0].offsetWidth + 1 : event.pageX + $ul[0].offsetWidth - 1,
                    pageY: $ul[0].offsetTop + $li[0].offsetTop - 3,
                    // eslint-disable-next-line angular/window-service
                    view: event.view || window,
                    target: event.target,
                    event: $event,
                };

                /*
                 * At t point, nestedMenu can only either be an Array or a promise.
                 * Regardless, passing them to `when` makes the implementation singular.
                 */
                t.$q.when(nestedMenu).then((promisedNestedMenu) => {
                    if (angular.isFunction(promisedNestedMenu)) {
                        //  support for dynamic subitems
                        promisedNestedMenu = promisedNestedMenu.call($scope, $scope, event, modelValue, text, $li);
                    }
                    var nestedParam = {
                        $scope: $scope,
                        event: ev,
                        options: promisedNestedMenu,
                        modelValue: modelValue,
                        level: level + 1,
                        orientation: orientation,
                        customClass: customClass,
                    };
                    t.renderContextMenu(nestedParam);
                });
            };

            $li.on('click', ($event: any) => {
                if ($event.which == 1) {
                    $event.preventDefault();
                    $scope.$apply(() => {
                        var cleanupFunction = () => {
                            $(event.currentTarget).removeClass('context');
                            t.removeAllContextMenus(t);
                        };
                        var clickFunction = angular.isFunction(item.click) ? item.click : angular.isFunction(item[1]) ? item[1] : null;

                        if (clickFunction) {
                            var res = clickFunction.call($scope, $scope, event, modelValue, text, $li);
                            if (res === undefined || res) {
                                cleanupFunction();
                            }
                        } else {
                            cleanupFunction();
                        }
                    });
                }
            });

            $li.on('mouseover', ($event: any) => {
                $scope.$apply(() => {
                    if (nestedMenu) {
                        openNestedMenu($event);
                    } else {
                        t.removeContextMenus(t, level + 1);
                    }
                });
            });
        } else {
            t.setElementDisabled($li);
        }
    }

    /**
     * @param params - an object containing the `item` parameter
     * @returns an Array or a Promise containing the children,
     *          or null if the option has no submenu
     */
    extractNestedMenu(params: any) {
        // Destructuring:
        var item = params.item;

        // New implementation:
        if (item.children) {
            if (angular.isFunction(item.children)) {
                // Expects a function that returns a Promise or an Array
                return item.children();
            } else if (angular.isFunction(item.children.then) || angular.isArray(item.children)) {
                // Returns the promise
                // OR, returns the actual array
                return item.children;
            }

            return null;
        } else {
            // nestedMenu is either an Array or a Promise that will return that array.
            // NOTE: This might be changed soon as it's a hangover from an old implementation

            return angular.isArray(item[1]) || (item[1] && angular.isFunction(item[1].then))
                ? item[1]
                : angular.isArray(item[2]) || (item[2] && angular.isFunction(item[2].then))
                    ? item[2]
                    : angular.isArray(item[3]) || (item[3] && angular.isFunction(item[3].then))
                        ? item[3]
                        : null;
        }
    }

    /**
     * Responsible for the actual rendering of the context menu.
     *
     * The parameters in params are:
     * - $scope = the scope of this context menu
     * - event = the event that triggered this context menu
     * - options = the options for this context menu
     * - modelValue = the value of the model attached to this context menu
     * - level = the current context menu level (defauts to 0)
     * - customClass = the custom class to be used for the context menu
     */
    renderContextMenu(params: any, t?: ContextMenu) {
        /// <summary>Render context menu recursively.</summary>

        // Destructuring:
        var $scope = params.$scope;
        var event = params.event;
        var options = params.options;
        var modelValue = params.modelValue;
        var level = params.level;
        var customClass = params.customClass;

        // Initialize the container. This will be passed around
        var $ul = t.initContextMenuContainer(params);
        params.$ul = $ul;

        // Register t level of the context menu
        t._contextMenus.push($ul);

        /*
         * t object will contain any promises that we have
         * to wait for before trying to adjust the context menu.
         */
        var $promises: any[] = [];
        params.$promises = $promises;

        angular.forEach(options, (item) => {
            if (item === null) {
                t.appendDivider($ul);
            } else {
                // If displayed is anything other than a function or a boolean
                var displayed = t.resolveBoolOrFunc(item.displayed, params);

                // Only add the <li> if the item is displayed
                if (displayed) {
                    var $li = $('<li>');
                    if (item.class) {
                        $li.addClass(item.class);
                    }
                    var itemParams = angular.extend({}, params);
                    itemParams.item = item;
                    itemParams.$li = $li;
                    t.processItem(itemParams, t);
                    if (t.resolveBoolOrFunc(item.hasTopDivider, itemParams, false)) {
                        t.appendDivider($ul);
                    }
                    $ul.append($li);
                    if (t.resolveBoolOrFunc(item.hasBottomDivider, itemParams, false)) {
                        t.appendDivider($ul);
                    }
                }
            }
        });

        if ($ul.children().length === 0) {
            var $emptyLi = angular.element('<li>');
            t.setElementDisabled($emptyLi);
            $emptyLi.html('<a>' + t._emptyText + '</a>');
            $ul.append($emptyLi);
        }

        t.$document.find('body').append($ul);

        t.doAfterAllPromises(params, t);

        t.$rootScope.$broadcast(t.ContextMenuEvents.ContextMenuOpened, {
            context: t._clickedElement,
            contextMenu: $ul,
            params: params,
        });
    }

    /**
     * calculate if drop down menu would go out of screen at left or bottom
     * calculation need to be done after element has been added (and all texts are set; thus the promises)
     * to the DOM the get the actual height
     */
    doAfterAllPromises(params: any, t: ContextMenu) {
        // Desctructuring:
        var $ul = params.$ul;
        var $promises = params.$promises;
        var level = params.level;
        var event = params.event;
        var leftOriented = String(params.orientation).toLowerCase() === 'left';

        t.$q.all($promises).then(() => {
            var topCoordinate = event.pageY;
            var menuHeight = angular.element($ul[0]).prop('offsetHeight');
            var winHeight = t.$window.pageYOffset + event.view.innerHeight;

            /// the 20 pixels in second condition are considering the browser status bar that sometimes overrides the element
            if (topCoordinate > menuHeight && winHeight - topCoordinate < menuHeight + 20) {
                topCoordinate = event.pageY - menuHeight;
                /// If the element is a nested menu, adds the height of the parent li to the topCoordinate to align with the parent
                if (level && level > 0) {
                    topCoordinate += event.event.currentTarget.offsetHeight;
                }
            } else if (winHeight <= menuHeight) {
                // If it really can't fit, reset the height of the menu to one that will fit
                angular.element($ul[0]).css({ height: winHeight - 5, 'overflow-y': 'scroll' });
                // ...then set the topCoordinate height to 0 so the menu starts from the top
                topCoordinate = 0;
            } else if (winHeight - topCoordinate < menuHeight) {
                var reduceThresholdY = 5;
                if (topCoordinate < reduceThresholdY) {
                    reduceThresholdY = topCoordinate;
                }
                topCoordinate = winHeight - menuHeight - reduceThresholdY;
            }

            var leftCoordinate = event.pageX;
            var menuWidth = angular.element($ul[0]).prop('offsetWidth');
            var winWidth = event.view.innerWidth + window.pageXOffset;
            var padding = 5;

            if (leftOriented) {
                if (winWidth - leftCoordinate > menuWidth && leftCoordinate < menuWidth + padding) {
                    leftCoordinate = padding;
                } else if (leftCoordinate < menuWidth) {
                    var reduceThresholdX = 5;
                    if (winWidth - leftCoordinate < reduceThresholdX + padding) {
                        reduceThresholdX = winWidth - leftCoordinate + padding;
                    }
                    leftCoordinate = menuWidth + reduceThresholdX + padding;
                } else {
                    leftCoordinate = leftCoordinate - menuWidth;
                }
            } else {
                if (leftCoordinate > menuWidth && winWidth - leftCoordinate - padding < menuWidth) {
                    leftCoordinate = winWidth - menuWidth - padding;
                } else if (winWidth - leftCoordinate < menuWidth) {
                    var reduceThresholdX = 5;
                    if (leftCoordinate < reduceThresholdX + padding) {
                        reduceThresholdX = leftCoordinate + padding;
                    }
                    leftCoordinate = winWidth - menuWidth - reduceThresholdX - padding;
                }
            }

            $ul.css({
                display: 'block',
                position: 'absolute',
                left: leftCoordinate + 'px',
                top: topCoordinate + 'px',
            });
        });
    }

    /**
     * Creates the container of the context menu (a <ul> element),
     * applies the appropriate styles and then returns that container
     *
     * @return a <ul> jqLite/jQuery element
     */
    initContextMenuContainer(params: any) {
        // Destructuring
        var customClass = params.customClass;

        var $ul = $('<ul>');
        $ul.addClass('dropdown-menu massia-context-menu');
        $ul.attr({ role: 'menu' });
        $ul.css({
            display: 'block',
            position: 'absolute',
            left: params.event.pageX + 'px',
            top: params.event.pageY + 'px',
            padding: 0,
            'z-index': 10000,
        });

        if (customClass) {
            $ul.addClass(customClass);
        }

        return $ul;
    }

    isTouchDevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints; // works on most browsers | works on IE10/11 and Surface
    }

    /**
     * Removes the context menus with level greater than or equal
     * to the value passed. If undefined, null or 0, all context menus
     * are removed.
     */
    removeContextMenus(t: ContextMenu, level?: any) {
        while (t._contextMenus.length && (!level || t._contextMenus.length > level)) {
            var cm = t._contextMenus.pop();
            t.$rootScope.$broadcast(t.ContextMenuEvents.ContextMenuClosed, { context: t._clickedElement, contextMenu: cm });
            cm.remove();
        }
        if (!level) {
            t.$rootScope.$broadcast(t.ContextMenuEvents.ContextMenuAllClosed, { context: t._clickedElement });
        }
    }

    removeOnScrollEvent(e: any, t: ContextMenu) {
        t.removeAllContextMenus(t, e);
    }

    removeOnOutsideClickEvent(e: any, t?: ContextMenu) {
        var $curr = $(e.target);
        var shouldRemove = true;
        while ($curr.length) {
            if ($curr.hasClass('dropdown-menu')) {
                shouldRemove = false;
                break;
            } else {
                $curr = $curr.parent();
            }
        }
        if (shouldRemove) {
            t.removeAllContextMenus(t, e);
        }
    }

    removeAllContextMenus(t: ContextMenu, e?: any) {
        t.$document.find('body').off('mousedown touchstart', (e) => t.removeOnOutsideClickEvent(e, t));
        t.$document.off('scroll', t.removeOnScrollEvent);
        $(t._clickedElement).removeClass('context');
        t.removeContextMenus(t);
        t.$rootScope.$broadcast('');
    }

    isBoolean(a: any) {
        return a === false || a === true;
    }

    /** Resolves a boolean or a function that returns a boolean
     * Returns true by default if the param is null or undefined
     * @param a - the parameter to be checked
     * @param params - the object for the item's parameters
     * @param defaultValue - the default boolean value to use if the parameter is
     *  neither a boolean nor function. True by default.
     */
    resolveBoolOrFunc(a: any, params: any, defaultValue?: any) {
        var item = params.item;
        var $scope = params.$scope;
        var event = params.event;
        var modelValue = params.modelValue;

        defaultValue = this.isBoolean(defaultValue) ? defaultValue : true;

        if (this.isBoolean(a)) {
            return a;
        } else if (angular.isFunction(a)) {
            return a.call($scope, $scope, event, modelValue);
        } else {
            return defaultValue;
        }
    }

    appendDivider($ul: any) {
        var $li = angular.element('<li>');
        $li.addClass('divider');
        $ul.append($li);
    }

    setElementDisabled($li: any) {
        $li.on('click', function ($event: any) {
            $event.preventDefault();
        });
        $li.addClass('disabled');
    }
}
