import validatorBuilder from 'validator-builder';

let siteCoordonneesValidator = null;

export default class SiteCoordonnees {
    constructor(data = {}) {
        this.id = data.id;
        this.nomBanque = data.nomBanque;
        this.banqueId = data.idBanque;
        this.codeBanque = data.codeBanque;
        this.guichet = data.guichet;
        this.compte = data.compte;
        this.rib = data.rib;
        this.iban = data.iban;
        this.isDefaut = data.isDefaut;
        this.siteId = data.idSite;
    }

    isValid() {
        siteCoordonneesValidator = siteCoordonneesValidator || validatorBuilder.getInstanceFor('SiteCoordonneesBancaires');
        const validationResults = siteCoordonneesValidator.validate(this);
        return validationResults.isValid;
    }
}
