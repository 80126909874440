export default class OffreResultatValidationController {
    /* @ngInject */
    constructor($scope, $state, $stateParams, ModalService, OffresService, notification, $translate, $filter, _, $uibModal) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ModalService = ModalService;
        this.OffresService = OffresService;
        this.notification = notification;
        this.$translate = $translate;
        this.$filter = $filter;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.startLoading();
        this.stopLoading();
    }

    async transmettre() {
        if (this.isValidation) {
            this.modalInstance.close({ idValidateurs: this.idValidateurs, validations: this.source.validations });
        }
    }

    async cancel() {
        this.modalInstance.dismiss();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
