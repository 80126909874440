FamillesSelectController.$inject = ['FamillesService', '_', 'MassiaApplicationService'];

export default function FamillesSelectController(FamillesService, _, MassiaApplicationService) {
    /* jshint validthis: true */
    const vm = this;

    // Propriétés
    vm.loading = false;
    vm.familles = [];

    // Méthodes
    vm.selectFamille = selectFamille;
    vm.refreshFamilles = refreshFamilles;
    vm.filterFamilles = filterFamilles;

    // Evènements
    vm.$onInit = onInit;

    // Méthodes publiques
    function onInit() {
        vm.refreshFamilles();
        vm.excludes = vm.excludes || [];
        vm.clearOnSelect = vm.clearOnSelect || false;
        vm.selected = vm.selected || null;
        vm.disabled = vm.disabled || false;
    }

    function selectFamille(item, model) {
        vm.onSelect ? vm.onSelect({ famille: item }) : (vm.selected = item);

        if (vm.clearOnSelect) {
            vm.selected = undefined;
        }
    }

    async function refreshFamilles() {
        let data;
        try {
            if (MassiaApplicationService.getApplication() == 'laboratoire') {
                data = await FamillesService.getFamillesFiltrees();
            } else {
                data = await FamillesService.getFamilles();
            }
        } catch (ex) {
            data = [];
        }
        vm.familles = data;
        console.log(data)
        if (vm.selected && vm.selected.idFamille) {
            vm.selected = _.find(vm.familles, { idFamille: vm.selected.idFamille });
        }
    }

    function filterFamilles(item) {
        return !_.find(vm.excludes, { idFamille: item.idFamille });
    }
}
