import BanqueEntete from './banque.entete.model';

export default class BanqueFormEnteteController {
    static $inject = ['$state', '$stateParams', 'BanquesService', 'ModalService', 'notification', '$translate'];

    constructor($state, $stateParams, BanquesService, ModalService, notification, $translate) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.BanquesService = BanquesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
    }

    $onInit() {
        this.banque = this.banque || {};
        this.entete = new BanqueEntete(this.banque.entete);
        this.pays = this.pays || [];
    }

    $onChanges(objChanges) {
        if (objChanges.banque) {
            this.getPays();
        }
    }

    $doCheck() {
        if (!angular.equals(this.entete, this.banque.entete)) {
            this.onUpdate({
                entete: angular.copy(this.entete)
            });
        }
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.entete.codeExists = await this.BanquesService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.entete.codeExists = null;
        }
    }

    async getPays() {
        this.startLoading();
        try {
            const res = await this.BanquesService.getPays();
            this.paysListe = res.items;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
