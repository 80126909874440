(function () {
    'use strict';

    angular.module('blocks.loading').directive('acLoader', acLoader);
    acLoader.$inject = ['LoadersDefaultValues'];

    function acLoader(LoadersDefaultValues) {
        return {
            restrict: 'A',
            replace: true,
            scope: true,
            bindToController: {
                color: '<?acLoaderColor',
                size: '<?acLoaderSize',
                borderSize: '<?acLoaderBorderSize',
                trail: '<?acLoaderTrail',
            },
            templateUrl: function (element, attrs) {
                var templateUrl;
                switch (attrs.acLoader) {
                    case 'double-spinner':
                        templateUrl = 'blocks/loading.double.html';
                        break;
                    default:
                        templateUrl = 'blocks/loading.simple.html';
                }
                return templateUrl;
            },
            controller: angular.noop,
            controllerAs: 'loader',
            link: function (scope, elem, attr, ctrl) {
                init();

                function init() {
                    getDefaultValues();
                }

                function getDefaultValues() {
                    ctrl.style = {};
                    if(ctrl.size) {
                        ctrl.style['width'] = ctrl.size + 'px';
                        ctrl.style['height'] = ctrl.size + 'px';
                    } else {
                        ctrl.style['width'] = LoadersDefaultValues.size + 'px';
                        ctrl.style['height'] = LoadersDefaultValues.size + 'px';
                    }
                    if (ctrl.borderSize) {
                        ctrl.style['border-width'] = ctrl.borderSize + 'px';
                    } else {
                        ctrl.style['border-width'] = LoadersDefaultValues.borderSize + 'px';
                    }
                    if (ctrl.color) {
                        ctrl.style['color'] = ctrl.color;
                    }
                    if (!ctrl.style['border-color']) {
                        ctrl.style['border-color'] =
                            attr.acLoaderDarkTrail === undefined ? LoadersDefaultValues.lightTrail : LoadersDefaultValues.darkTrail;
                    } else {
                        ctrl.style['border-color'] = ctrl.trail;
                    }
                }
            },
        };
    }
})();
