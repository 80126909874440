import PaiementEnteteController from './paiement.entete.controller';

export default {
    bindings: {
        paiement: '=',
        isOpen: '='
    },
    template: require('./paiement.entete.html'),
    controller: PaiementEnteteController
};
