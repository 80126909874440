import SerieTamisCaracteristiques from './serie.tamis.caracteristiques.model';

export default class SerieTamisCaracteristiquesController {
    static $inject = ['_', 'SeriesTamisTypesService', 'notification', '$timeout', '$scope'];

    constructor(_, SeriesTamisTypesService, notification, $timeout, $scope) {
        this._ = _;
        this.SeriesTamisTypesService = SeriesTamisTypesService;
        this.notification = notification;
        this.$timeout = $timeout;
        this.$scope = $scope;
    }

    $onInit() {
        this.loading = false;
        this.typeFormat = this.typeFormat || [];
        this.serieTamis = this.serieTamis || {};
        this.serieTamis.niveauVisibilite = 0;
        this.caracteristiques = new SerieTamisCaracteristiques(this.serieTamis.caracteristiques);
        const _this = this;
        this.$scope.$watch('$ctrl.serieTamis.niveauVisibilite', function (newValue, oldValue) {
            _this.niveauVisibilite = _this.serieTamis.niveauVisibilite;
        });
    }

    $onDestroy() {
        this.$timeout.cancel(this.typeFormatTimeout);
    }

    $onChanges(objChanges) {
        if (objChanges.idType) {
            if (!objChanges.idType.isFirstChange()) {
                this.caracteristiques = new SerieTamisCaracteristiques();
            }

            this.getTypeFormat(objChanges.idType.currentValue);
        }
    }

    // cette fonction est appelée automatiquement
    // a chaque digest (dirty check) d'angular
    $doCheck() {
        if (!angular.equals(this.caracteristiques, this.serieTamis.caracteristiques)) {
            this.onUpdate({
                caracteristiques: angular.copy(this.caracteristiques)
            });
        }
    }

    async getTypeFormat(idType) {
        if (idType) {
            // on démarre l'animation de loading avant le chargement des données
            this.startLoading();

            // on encapsule l'appel serveur dans un try...catch pour récupérer les erreurs
            try {
                // on récupère les données depuis le service qui interroge le serveur
                const typeFormat = await this.SeriesTamisTypesService.getCaracteristiques(idType);
                // on trie le tableau selon la propriété 'position'
                // on publie le tableau dans le this pour l'afficher dans la vue
                this.typeFormat = this._.sortBy(typeFormat, 'position');
                if (this.serieTamis.traiteDuplication) {
                    if (this.caracteristiques) {
                        this.typeFormat.forEach((x) => {
                            if (
                                !x.isDuplicable &&
                                this.caracteristiques.data[x.id] &&
                                this.caracteristiques.data[x.id].value !== undefined &&
                                this.caracteristiques.data[x.id].value !== null
                            ) {
                                this.caracteristiques.data[x.id].value = undefined;
                                this.caracteristiques[x.id] = undefined;
                            }
                        });
                    }
                    delete this.serieTamis.traiteDuplication;
                }
            } catch (ex) {
                // en cas de problème on notifie l'utilisateur
                this.notification.error(ex.data);
            } finally {
                // On supprimer le timeout précédent (fuite mémoire)
                this.$timeout.cancel(this.typeFormatTimeout);

                // une fois l'appel terminé, on stoppe l'animation de loading
                this.typeFormatTimeout = this.$timeout(() => {
                    this.stopLoading();
                });
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
