(function (angular, undefined) {
    'use strict';

    angular.module('blocks.filters').filter('GetCheckboxIconClassFilter', GetCheckboxIconClassFilter);

    function GetCheckboxIconClassFilter() {
        return function(predicate) {
            var iconclass = 'glyphicon glyphicon-';

            iconclass += predicate ? 'check' : 'unchecked';

            return iconclass;
        };
    }

})(angular);