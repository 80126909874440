ProspectsController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'ProspectsService',
	'notification',
	'$transitions'
];

export default function ProspectsController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	ProspectsService,
	notification,
	$transitions
) {
	const vm = this;

	let previousTableState;
	let selectedProspectId = undefined;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.prospects = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectProspect = selectProspect;
	vm.isProspectSelected = isProspectSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadProspects = loadProspects;
	vm.deleteProspect = deleteProspect;
	vm.setSelected = setSelected;
	vm.exporter = exporter;

	function init() {
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	init();

	function stateChangeSuccessFunc(event, toState) {
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(prospect) {
		if (prospect && prospect.id) {
			vm.selectedProspectId = $stateParams.id;
			vm.lastSelected = vm.selectedProspectId;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			vm.selectedProspectId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state.current.name === 'prospects.list.detail' && $stateParams.id;
	}

	function selectProspect(prospect) {
		if (prospect && prospect.id) {
			if (selectedProspectId !== prospect.id) {
				showDetail(prospect.id);
			} else {
				closeDetail();
			}
		}
	}

	function isProspectSelected(prospect) {
		return prospect && prospect.id && selectedProspectId === prospect.id;
	}

	function showDetail(prospectId) {
		selectedProspectId = prospectId;
		$state.go('prospects.list.detail', { id: prospectId });
	}

	function closeDetail() {
		selectedProspectId = undefined;
		$state.go('prospects.list');
	}

	async function loadProspects(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'prospects.list') {
			$state.go('prospects.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.prospects = [];

		try {
			const data = await ProspectsService.getProspects(filters, sorts, pagination);

			vm.prospects = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteProspect(prospect) {
		if (prospect && prospect.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('PROSPECTS.DELETE.TITLE', { code: prospect.libelle }),
				modalMsg: $translate.instant('PROSPECTS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await ProspectsService.deleteProspectById(prospect.id);
					notification.success($translate.instant('PROSPECTS.DELETE.SUCCESS'));

					selectedProspectId = undefined;

					if (vm.params.id) {
						vm.state.go('prospects.list');
					}

					loadProspects();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function exporter(modeImpression) {
		try {
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const fileName = 'Liste_prospects';
			let resultat = undefined;
			try {
				resultat = await ProspectsService.exportListeProspects(modeImpression, filters, sorts);
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('PROSPECTS.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				const status = resultat.status;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
				} finally {
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
