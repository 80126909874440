import DeviseEnteteController from './devise.entete.controller';

export default {
    bindings: {
        devise: '=',
        isOpen: '='
    },
    template: require('./devise.entete.html'),
    controller: DeviseEnteteController
};
