export default class ClientDiversModalController {
    static $inject = [
        '$state',
        '$stateParams',
        'ClientsDiversCommunicationService',
        'ClientsDiversService',
        'notification',
        'ModalService',
        '$uibModal'
    ];

    constructor($state, $stateParams, ClientsDiversCommunicationService, ClientsDiversService, notification, ModalService, $uibModal) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ClientsDiversCommunicationService = ClientsDiversCommunicationService;
        this.ClientsDiversService = ClientsDiversService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.isShow = false;
    }

    annuler() {
        this.modalInstance.close(false);
    }

    async getNomSitesClients(valeur) {
        try {
            const res = await this.ClientsDiversService.getNomSitesClients(valeur, this.societeId);
            return res;
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async confirmer() {
        if (this.isShow) {
            if (this.siteClient) {
                this.rapprocherClient();
                this.$state.go('clients.edit', { id: this.siteClient.id, genre: 'clients' });
                this.annuler();
            }
        } else {
            this.$state.go('clients-divers.transformClient', { clientDiversId: this.clientDiversId });
            this.annuler();
        }
    }

    async rapprocherClient() {
        this.startLoading();
        try {
            return await this.ClientsDiversService.rapprocherClient(this.siteClient.id, this.clientDiversId);
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
