import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-form',
    bindings: {
        label: '@',
        labelClass: '@',
        form: '<',
        id: '@',
        content: '@'
    },
    transclude: true,
    template: require('./massia.form.tpl.html')
})
export class MassiaFormController {
    constructor() {}

    $onInit() {}
}
