import DomainesSelectController from './domaines.select.controller';

export default {
    template: require('./domaines.select.tpl.html'),
    controller: DomainesSelectController,
    bindings: {
        disabled: '<',
        clearOnSelect: '<',
        selected: '<',
        excludes: '<',
        onSelect: '&'
    }
};
