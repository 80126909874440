CampagneEnteteValidator.$inject = ['validator'];

export default function CampagneEnteteValidator(validator) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().when(isNotCodeAuto).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 15).when(isNotCodeAuto).withMessage('VALIDATION_TOO_LONG_15');
    instance.ruleFor('typeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    //instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    //instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance.ruleFor('siteProducteurId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('siteAuditeurId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('dateDebut').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('dateFin').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    return instance;

    function isNotCodeAuto(obj, prop) {
        return !obj.estCodeAuto;
    }
}
