export default class FactureDetailController {
	/* @ngInject */
	constructor(
		$stateParams,
		$state,
		FacturesService,
		notification,
		FacturesCommunicationService,
		RouterHistoryService,
		$uibModal,
		ModalService,
		$translate
	) {
		this.$stateParams = $stateParams;
		this.$state = $state;
		this.FacturesService = FacturesService;
		this.notification = notification;
		this.FacturesCommunicationService = FacturesCommunicationService;
		this.RouterHistoryService = RouterHistoryService;
		this.$uibModal = $uibModal;
		this.ModalService = ModalService;
		this.$translate = $translate;
	}

	$onInit() {
		this.loading = false;
		this.facture = {};

		this.closeAllOnglets();
		this.openAllOnglets();
		this.openFirstOnglet();

		this.initFacture();
	}

	async initFacture() {
		this.facture = {
			id: this.$stateParams.id
		};
		if (this.id) {
			this.facture = {
				id: this.id
			};
		}

		if (this.facture.id) {
			this.startLoading();

			try {
				// on ajoute await afin de ne pas passer trop rapidement dans le finally
				// et ainsi on garde le loading le temps nécessaire
				this.facture.dataById = await this.FacturesService.getFactureById(this.facture.id);

				// Si le navigateur est contrôler par un logiciel de test automatisé
				if (navigator.webdriver) {
					sessionStorage.setItem('factureSelenium', JSON.stringify(this.facture.dataById));
				}
			} catch (ex) {
				if (ex.status === 404) {
					this.goListFacture();
				}
				this.notification.error(ex.data);
			} finally {
				this.getDomaine();
				this.stopLoading();
			}
		} else {
			this.goListFacture();
		}
	}

	goListFacture() {
		this.$state.go('factures.list', {}, { reload: true });
	}

	openFirstOnglet() {
		this.ongletOpen = {
			isEnteteOpen: true,
			isRecapitulatifOpen: false,
			isArticlesParChantierOpen: false
		};
	}
	closeAllOnglets() {
		this.ongletOpen = {
			isEnteteOpen: false,
			isRecapitulatifOpen: false,
			isArticlesParChantierOpen: false
		};
	}
	openAllOnglets() {
		this.ongletOpen = {
			isEnteteOpen: true,
			isRecapitulatifOpen: true,
			isArticlesParChantierOpen: true
		};
	}

	getDomaine() {
		switch (this.facture.dataById.idTypeFacture) {
			case 0:
			case 2:
			case 5:
			case 6:
				this.domaine = 'factures';
				break;
			case 4:
			case 9:
				this.domaine = 'avoirmanuel';
				break;
			case 3:
			case 8:
				this.domaine = 'avoirauto';
				break;
			case 1:
				this.domaine = 'facturemanuel';
				break;
		}
	}

	annuler() {
		if (this.modalInstance) {
			this.fermerModal();
		} else {
			this.retournerListeFactures();
		}
	}

	fermerModal() {
		this.modalInstance.close('close');
	}

	retournerListeFactures() {
		if (!this.RouterHistoryService.back()) {
			this.goListFacture();
		}
	}

	print() {
		const listFactureIdsToPrint = [];
		listFactureIdsToPrint.push(this.facture.id);

		this.$uibModal
			.open({
				template: '<factures-print source="$ctrl.listFactureIdsToPrint" modal-instance="$ctrl.uibModalInstance"></factures-print>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.listFactureIdsToPrint = listFactureIdsToPrint;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'l'
			})
			.result.then(
				function (result) {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
				},
				function (reason) {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}

	async regenerer() {
		const modalInstance = this.ModalService.confirm({
			modalTitle: this.$translate.instant('FACTURES.REGENERER.TITLE'),
			modalMsg: this.$translate.instant('FACTURES.REGENERER.MESSAGE'),
			headerClass: 'modal-danger'
		});
		const that = this;
		modalInstance.result.then(async function () {
			that.regenererFacture();
		});
	}

	async regenererFacture(comptoirWithFiles = false) {
		this.startLoading();
		try {
			const tmp = await this.FacturesService.regenererFacture(this.facture.id, comptoirWithFiles);
			if (tmp) {
				this.notification.info(this.$translate.instant('FACTURES.REGENERER.ERROR'));
			}
			return tmp;
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			this.stopLoading();
			this.initFacture();
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	async openModaleComptant() {
		const modalInstance = this.ModalService.open({
			component: 'modaleUpdateComptant',
			animation: true,
			size: 'lg',
			resolve: {
				facture: () => this.facture.dataById
			}
		});

		try {
			const res = await modalInstance.result;
			if (res === 'updated') {
				this.notification.info('FACTURES.REGENERER.TITLE');
			}
		} catch (ex) {}
	}
}
