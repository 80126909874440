export default class TramesSaisiesTypesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getAll(domaine) {
        const url = `${__configuration.apiUrl}/massia/domaines/${domaine}/types`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getAllMesures(domaine, idFiltre) {
        if (!idFiltre) {
            idFiltre = 0;
        }
        const url = `${__configuration.apiUrl}/massia/domaines/${domaine}/types/filtre-theme/${idFiltre}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getAllProduits(idType) {
        if (!idType) {
            idType = 0;
        }
        const urlProd = `${__configuration.apiUrl}/massia/domaines/produits/types/filtre-type-autre/${idType}`;
        const res = await this.$http.get(urlProd);
        return res.data;
    }

    async getCaracteristiques(id) {
        const url = `${__configuration.apiUrl}/massia/types/${id}/format-caracteristiques`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
