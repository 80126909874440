const serviceUrl = `${__configuration.apiUrl}/massia/prelevements`;
// const serviceUrl = `${__configuration.apiUrl}/v2/massia/prelevement`;

export default class PrelevementsService {
    /* @ngInject */
    constructor($http, $translate, moment, ElementCaracteristiqueService, globalizationManagementService, CaracteristiquesTypesService) {
        this.$http = $http;
        this.moment = moment;
        this.ElementCaracteristiqueService = ElementCaracteristiqueService;
        this.CaracteristiquesTypesService = CaracteristiquesTypesService;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
        this.$translate = $translate;
    }

    async getSaisies(filters, sorts, pagination, idTheme, idSiteProd, dateDebut, dateFin, idModele, idFamille, idLaboratoire /*, customFilters*/) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {}),
                idTheme: idTheme || 0,
                idSiteProd: idSiteProd || 0,
                dateDebut: JSON.stringify(dateDebut || null),
                dateFin: JSON.stringify(dateFin || null),
                idModele: idModele || 0,
                idFamille: idFamille || 0,
                idLaboratoire: idLaboratoire || 0
                // customFilters: JSON.stringify(customFilters) || 0
            }
        });
        return res.data;
    }

    async getPrelevementById(id, saisie) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.post(url, saisie);
        return res.data;
    }

    async createSaisie(saisie) {
        saisie.caracteristiques = this.CaracteristiquesTypesService.formatCaracteristiques(saisie.caracteristiques);

        // data.caracteristiques = this.ElementCaracteristiqueService.formatCaracteristiques(saisie.caracteristiques);
        // data.mesures = this.formatMesures(saisie.mesures.valeursMesures.valeursParMesure);
        // data.essaisPlanifies = this.formatEssaisPlanifies(saisie.mesures.valeursMesures.valeursParMesure);
        // data.mesuresStructure = this.formatStructure(saisie.mesures.valeursMesures.valeursParMesure);
        const result = await this.$http.post(serviceUrl, saisie);
        return result.data;
    }

    async updateSaisie(saisie) {
        saisie.caracteristiques = this.CaracteristiquesTypesService.formatCaracteristiques(saisie.caracteristiques);

        //data.caracteristiques = this.ElementCaracteristiqueService.formatCaracteristiques(saisie.caracteristiques);
        // data.mesures = this.formatMesures(saisie.mesures.valeursMesures.valeursParMesure);
        // data.essaisPlanifies = this.formatEssaisPlanifies(saisie.mesures.valeursMesures.valeursParMesure);
        // data.mesuresStructure = this.formatStructure(saisie.mesures.valeursMesures.valeursParMesure);
        const result = await this.$http.put(`${serviceUrl}/${saisie.id}`, saisie);
        return result.data;
    }

    async deleteSaisieById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getSaisieListeMesuresById(id, idLabo) {
        if (!id) {
            id = 0;
        }

        const url = `${serviceUrl}/${id}/mesures?idLabo=${idLabo}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getThemes(codeDomaine) {
        const themeUrl = `${__configuration.apiUrl}/massia/themes/headers/${codeDomaine}`;
        const res = await this.$http.get(themeUrl);
        return res.data;
    }

    async getFamilles() {
        const familleUrl = `${__configuration.apiUrl}/massia/familles/filtre`;
        const res = await this.$http.get(familleUrl);
        return res.data;
    }

    async getSitesProd(application) {
        const siteUrl = `${__configuration.apiUrl}/massia/sites-producteurs/headers/${application}`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async getSitesGeoJSon(application) {
        const siteUrl = `${__configuration.apiUrl}/massia/sites-producteurs/geojson/${application}`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async hasThemeCampagne(idTheme) {
        const url = `${__configuration.apiUrl}/massia/themes/${Number(idTheme)}`;
        const res = await this.$http.get(url);
        return res.data.hasCampagne;
    }

    async getSitesProducteurs(valeur, application, idSiteInterv) {
        let url = '';
        if (idSiteInterv) {
            url = `${__configuration.apiUrl}/massia/sites-producteurs/typeAheadCompteOuvertSiteComm/${idSiteInterv}/${valeur}`;
        } else {
            url = `${__configuration.apiUrl}/massia/sites-producteurs/typeAhead/${application}/${valeur}`;
        }
        const res = await this.$http.get(url);
        console.log(res);
        return res.data;
    }

    async getPointsMesure(idSiteProd, idTheme) {
        const url = `${__configuration.apiUrl}/massia/qse/points-mesure/theme/${Number(idTheme)}/site/${idSiteProd}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getProduits(idSiteProd, idFamille) {
        const url = `${__configuration.apiUrl}/massia/produits/famille/${Number(idFamille)}/site/${idSiteProd}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getPersonnes(idSiteAuditeur) {
        const siteUrl = `${__configuration.apiUrl}/massia/personnes/salarie-et-non-affecte-site-auditeur/headers/${idSiteAuditeur}`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async getTrames(idFamille, idSite, idProduit) {
        /*if (!idSite)
			idSite = 0;
		var trameUrl = `${__configuration.apiUrl}/massia/trames/by-theme/${Number(idTheme)}/by-site/${idSite}`;
		let res = await this.$http.get(trameUrl);
		return res.data;*/
        const referenceUrl = `${__configuration.apiUrl}/massia/trames/by-filtre`;
        const res = await this.$http.get(referenceUrl, {
            params: {
                idFamille: JSON.stringify(idFamille || 0),
                idSiteProd: JSON.stringify(idSite || 0),
                idProduit: JSON.stringify(idProduit || 0),
                domaine: JSON.stringify('')
            }
        });
        return res.data;
    }

    async getReferences(idTheme, idSite) {
        if (!idSite) {
            idSite = 0;
        }
        const referenceUrl = `${__configuration.apiUrl}/massia/references/by-theme/${Number(idTheme)}/by-site/${idSite}`;
        const res = await this.$http.get(referenceUrl);
        return res.data;
    }

    async getReferencesByFiltre(idTheme, idSiteProd, idProduit, idSiteClient, date, idFamille) {
        const referenceUrl = `${__configuration.apiUrl}/massia/references/by-filtre`;
        const parametres = {
            idTheme: idTheme || 0,
            idSiteProd: idSiteProd || 0,
            idProduit: idProduit || 0,
            idSiteClient: idSiteClient || 0,
            date: date || null,
            idFamille: idFamille || 0
        };
        const res = await this.$http.get(referenceUrl, {
            params: {
                parametres: JSON.stringify(parametres || null)
            }
        });
        return res.data;
    }

    async getSaisieValeursCaracteristiquesAssociationsById(id) {
        const data = await this.getSaisieValeursCaracteristiquesById(id);
        const result = {};
        for (const element in data) {
            if (data[element] && !Array.isArray(data[element])) {
                if (data[element].value === 'True') {
                    data[element].value = 'true';
                }
                if (data[element].value === 'False') {
                    data[element].value = 'false';
                }

                if (data[element].value && data[element].value.key) {
                    result[data[element].id] = data[element].value.key;
                } else {
                    result[data[element].id] = data[element].value;
                }
            }
        }
        return result;
    }

    async getSaisieValeursCaracteristiquesById(id) {
        const url = `${serviceUrl}/${id}/valeurs-caracteristiques`;
        const res = await this.$http.get(url);
        const result = [];
        res.data.forEach(function (element) {
            const caract = {
                id: element.id,
                code: element.code,
                idNature: element.idNature,
                indice: element.indice,
                label: element.label,
                numericAdditionalInformation: element.numericAdditionalInformation,
                value: element.elementsValues || element.elementValue || element.values || element.value
            };
            result.push(caract);
        });
        return result;
    }

    async getMesuresComplet(saisieId, idsMesures, prelevement, codesMesures = null) {
        let tmpPlv;
        if (!saisieId) {
            saisieId = 0;
        }

        if (prelevement) {
            tmpPlv = angular.copy(prelevement);
            tmpPlv.mesures = []; //have to be not null
            tmpPlv.outsourceModel(this.moment, this.dateFormat);
        }

        const url = `${serviceUrl}/${Number(saisieId)}/mesures/prelevement-mesures`;
        const res = await this.$http.post(url, {
            prelevement: tmpPlv || null,
            idsMesures: idsMesures || [],
            codesMesures: codesMesures
        });

        const resultat = res.data;
        resultat.map((x) => {
            x.isNew = true;
            return x;
        });
        //this.traiteValeurs(resultat);
        return resultat;
    }

    traiteValeurs(resultTemp) {
        for (let i = 0; i < resultTemp.valeursParMesure.length; i++) {
            const data = [];
            resultTemp.valeursParMesure[i].valeurs.forEach(function (val) {
                const sousess = {
                    id: val.id,
                    code: val.code,
                    idNature: val.idNature,
                    indice: val.indice,
                    label: val.label,
                    numericAdditionalInformation: val.numericAdditionalInformation,
                    value: val.elementsValues || val.elementValue || val.values || val.value || val.tamisValues
                };
                data.push(sousess);
            });
            const result = {};

            for (const elt in data) {
                if (data[elt] && !Array.isArray(data[elt])) {
                    if (data[elt].value === 'True') {
                        data[elt].value = 'true';
                    }
                    if (data[elt].value === 'False') {
                        data[elt].value = 'false';
                    }
                    if (data[elt].value && data[elt].value.key) {
                        result[data[elt].id] = data[elt].value.key;
                    } else {
                        result[data[elt].id] = data[elt].value;
                    }
                }
            }
            resultTemp.valeursParMesure[i].valeurs = result;
        }
    }

    //pour create et update
    formatMesures(mesures) {
        const data = [];
        for (let i = 0; i < mesures.length; i++) {
            const mesure = mesures[i];
            for (const property in mesure.valeurs /*.data*/) {
                let keyValueObject;
                //On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variables interne Js.
                if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
                    let propertyVal = mesure.valeurs[property];
                    if (Array.isArray(propertyVal)) {
                        if (_.some(propertyVal, 'key')) {
                            keyValueObject = {
                                IdSousMesure: parseInt(property),
                                valeurs: _.map(propertyVal, 'key')
                            };
                        } else {
                            if (_.some(propertyVal, 'ouverture')) {
                                keyValueObject = {
                                    IdSousMesure: parseInt(property),
                                    tamis: propertyVal
                                };
                            } else if (_.some(propertyVal, 'value') && propertyVal.length > 0 && propertyVal[0].value.ouverture) {
                                const newPropertyVal = [];
                                for (var j = 0; j < propertyVal.length; j++) {
                                    newPropertyVal.push(propertyVal[j].value);
                                }
                                keyValueObject = {
                                    IdSousMesure: parseInt(property),
                                    tamis: newPropertyVal
                                };
                            } else if (_.some(propertyVal, 'value')) {
                                keyValueObject = {
                                    IdSousMesure: parseInt(property),
                                    valeurs: propertyVal.value
                                };
                            } else {
                                const pty = [];
                                for (var j = 0; j < propertyVal.length; j++) {
                                    if (Array.isArray(propertyVal[j])) {
                                        if (propertyVal[j][0] != null) {
                                            pty.push(propertyVal[j][0].value);
                                        }
                                    } else {
                                        pty.push(propertyVal[j]);
                                    }
                                }

                                keyValueObject = {
                                    IdSousMesure: parseInt(property),
                                    valeurs: pty
                                };
                            }
                        }
                    } else {
                        if (this.moment.isMoment(propertyVal)) {
                            propertyVal = propertyVal.format(this.dateFormat);
                        }
                        keyValueObject = {
                            IdSousMesure: parseInt(property),
                            valeur: propertyVal
                        };
                    }
                    data.push(keyValueObject);
                }
            }
        }
        return data;
    }

    //pour create et update
    formatEssaisPlanifies(essais) {
        const data = [];
        if (essais) {
            for (let i = 0; i < essais.length; i++) {
                const ess = essais[i];
                if (ess.planification) {
                    data.push({ idEssai: ess.idMesure, datePrevue: ess.datePrevue });
                }
            }
        }
        return data;
    }

    //pour create et update
    formatStructure(mesures) {
        const data = [];
        for (let i = 0; i < mesures.length; i++) {
            const mesure = mesures[i];

            const essai = angular.copy(mesure.essai);
            essai.sousEssaisMesures = angular.copy(mesure.format);
            data.push(essai);
        }
        return data;
    }

    async codeExists(code) {
        const url = `${__configuration.apiUrl}/massia/uniqueness/code/${'Prelevement'}/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getSite(id) {
        const siteUrl = `${__configuration.apiUrl}/massia/sites/${id}/entete/coordonnees-map`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async getPointMesure(id) {
        const ptMesureUrl = `${__configuration.apiUrl}/massia/qse/points-mesure/${id}/entete/coordonnees-map`;
        const res = await this.$http.get(ptMesureUrl);
        return res.data;
    }

    async getLocalisationPointsMesure(idTheme) {
        const themeUrl = `${__configuration.apiUrl}/massia/themes/mode-localisation/${Number(idTheme)}`;
        const res = await this.$http.get(themeUrl);
        return res.data;
    }

    async getPlanTopo(idSite) {
        const url = `${__configuration.apiUrl}/massia/qse/plans-topos/${idSite}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getMesuresTrame(idTrame) {
        const trameUrl = `${__configuration.apiUrl}/massia/trames/${idTrame}/mesures`;
        const res = await this.$http.get(trameUrl);
        return res.data;
    }

    async getTamisTrame(idTrame) {
        const trameUrl = `${__configuration.apiUrl}/massia/trames/${idTrame}/tamis`;
        const res = await this.$http.get(trameUrl);
        return res.data;
    }

    async getThemeById(idTheme) {
        const url = `${__configuration.apiUrl}/massia/themes/${Number(idTheme)}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getFamilleById(idFamille) {
        const url = `${__configuration.apiUrl}/massia/familles/${Number(idFamille)}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getLibelleSite(idSite) {
        const url = `${__configuration.apiUrl}/massia/sites/${idSite}/entete/libelle`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getLibelleProduit(idProduit) {
        const url = `${__configuration.apiUrl}/massia/produits/${idProduit}/entete/libelle`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async estCodeAuto(domaine) {
        const url = `${__configuration.apiUrl}/massia/code-automatique/est-code-auto/${domaine}`;
        try {
            const res = await this.$http.get(url);
            return res.data;
        } catch (err) {
            if (err.status === 404) {
                return false;
            }
            throw err;
        }
    }

    async afficheCode(domaine) {
        const url = `${__configuration.apiUrl}/massia/code-automatique/affiche-code/${domaine}`;
        try {
            const res = await this.$http.get(url);
            return res.data;
        } catch (err) {
            if (err.status === 404) {
                return false;
            }
            throw err;
        }
    }

    async getModelesSaisies(domaineSM, idTheme, idSiteProd, idFamille) {
        let domaine = 'modelessaisies';
        switch (domaineSM) {
            case 'saisies':
                domaine = 'modelessaisiese';
                break;
            case 'saisiectrl':
                domaine = 'modelessaisiesm';
                break;
            case 'saisiesecu':
                domaine = 'modelessaisiesp';
                break;
        }
        if (!idTheme) {
            idTheme = 0;
        }
        if (!idSiteProd) {
            idSiteProd = 0;
        }
        let modeleUrl = '';
        if (idTheme) {
            modeleUrl = `${__configuration.apiUrl}/massia/modeles-saisies/headers/${domaine}/${Number(idTheme)}/${idSiteProd}`;
        } else {
            modeleUrl = `${__configuration.apiUrl}/massia/modeles-saisies/headers-famille/${domaine}/${Number(idFamille)}/${idSiteProd}`;
        }
        const res = await this.$http.get(modeleUrl);
        return res.data;
    }

    async GetModeleById(modeleId) {
        const url = `${__configuration.apiUrl}/massia/modeles-saisies/${modeleId}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async print(id, filename, data) {
        const res = await this.$http.post(`${serviceUrl}/${id}/print`, data, {
            params: {
                filename: filename
            }
        });
        return res.data ? res.data : null;
    }

    async getValeursReference(saisie) {
        if (saisie.produit && saisie.produit.id && saisie.producteur && saisie.producteur.id && saisie.date) {
            const url = `${__configuration.apiUrl}/massia/laboratoire/valeurs-references/valeurs-essais`;
            const res = await this.$http.get(url, {
                params: {
                    idSite: JSON.stringify(saisie.producteur.id || null),
                    idProduit: JSON.stringify(saisie.produit.id || null),
                    date: JSON.stringify(saisie.date || null)
                }
            });
            return res.data;
        }
        return null;
    }

    async getNextValidDate(idSite, date) {
        const url = `${__configuration.apiUrl}/massia/jours-feries/next-valid-date/${idSite}`;
        try {
            const res = await this.$http.get(url, {
                params: {
                    date: date
                }
            });
            return res.data;
        } catch (err) {
            if (err.status === 404) {
                return false;
            }
            throw err;
        }
    }

    async getProductCertifs(idSaisie, idProduit, idProducteur) {
        const url = `${__configuration.apiUrl}/massia/laboratoire/certificat-produit/saisie/${idSaisie}`;

        const params = {};
        if (idProduit !== null) {
            params.idProduit = JSON.stringify(idProduit);
        }
        if (idProducteur !== null) {
            params.idProducteur = JSON.stringify(idProducteur);
        }
        const res = await this.$http.get(url, { params: params });
        return res.data;
    }

    async updateSaisiesCloture(saisiesToClose, saisiesToOpen) {
        try {
            const url = `${serviceUrl}/cloture`;
            const data = { datasToClose: saisiesToClose, datasToOpen: saisiesToOpen };
            return await this.$http.put(url, data);
        } catch (err) {
            throw err;
        }
    }

    async getConformiteValeursArrondies() {
        const a = await this.$http.get(`${__configuration.apiUrl}/massia/parametre-programme/code`, {
            params: {
                cle: 'BooConfArrond'
            }
        });
        return a.data;
    }

    async getConformitesList() {
        const data = await this.$http.get('assets/enum/prelevements-grille/conformite.enum.json');
        return data.data.map((x) => {
            x.libelle = this.$translate.instant(x.libelle);
            return x;
        });
    }

    async getCamsizerFileUploadAndProcessing({ data, file }) {
        const formData = new FormData();
        formData.append('info', angular.toJson(data));
        formData.append('file', file);
        const res = await this.$http.post(`${__configuration.apiUrl}/massia/prelevements/import-camsizer`, formData, {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined }
        });
        return res.data;
    }

    async uploadFileImportGranulo({ data, file }) {
        const formData = new FormData();
        formData.append('info', angular.toJson(data));
        formData.append('file', file);
        const res = await this.$http.post(`${__configuration.apiUrl}/massia/prelevements/import-granulo`, formData, {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined }
        });
        return res.data;
    }
}
