export default function Configure($stateProvider) {
    $stateProvider.state('synthesemodeles', {
        parent: 'common',
        url: '/synthesemodeles',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.ANALYSE" | translate }}'
        }
    });

    $stateProvider.state('list', {
        parent: 'synthesemodeles',
        url: '/list',
        template: '<synthesemodeles></synthesemodeles>',
        rights: { domain: 'synthadmin', right: 'read' },
        ncyBreadcrumb: {
            parent: 'synthesemodeles',
            label: '{{ "SYNTHMODS.BREADCRUMBS.SYNTHMODS_LIST" | translate}}'
        },
        // on affiche la liste des synthesemodeles dans la navigation
        navigation: {
            menu: 'analyse_synthese_admin',
            translate: 'SYNTHMODS.BREADCRUMBS.SYNTHMODS_LIST',
            order: 1,
            navigationCls: 'menu-item-separator'
        },
        // la liste des synthesemodeles fait partie des points d'entrée recherchables dans la navigation
        search: 'SYNTHMODS.BREADCRUMBS.SYNTHMODS_LIST'
    });

    $stateProvider.state('detail', {
        parent: 'synthesemodeles',
        url: '/{id}',
        template: '<synthesemodele-detail></synthesemodele-detail>',
        rights: { domain: 'synthadmin', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher synthesemodeles > détail
            parent: 'synthesemodeles',
            label: '{{ "SYNTHMODS.BREADCRUMBS.SYNTHMOD_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('new', {
        parent: 'synthesemodeles',
        url: '/new',
        template: '<synthesemodeles-form></synthesemodeles-form>',
        rights: { domain: 'synthadmin', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher synthesemodeles > ajouter
            parent: 'synthesemodeles',
            label: '{{ "SYNTHMODS.BREADCRUMBS.SYNTHMOD_NEW" | translate}}'
        },
        // l'ajout d'une synthesemodele fait partie des points d'entrée recherchables dans l'application
        search: 'SYNTHMODS.BREADCRUMBS.SYNTHMOD_NEW'
    });

    $stateProvider.state('edit', {
        parent: 'synthesemodeles',
        url: '/{id}/edit',
        template: '<synthesemodeles-form></synthesemodeles-form>',
        rights: { domain: 'synthadmin', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher synthesemodeles > modifier
            parent: 'synthesemodeles',
            label: '{{ "SYNTHMODS.BREADCRUMBS.SYNTHMOD_EDIT" | translate }}'
        }
    });

    $stateProvider.state('duplicate', {
        parent: 'synthesemodeles',
        url: '/{id}/duplicate',
        template: '<synthesemodeles-form></synthesemodeles-form>',
        rights: { domain: 'synthadmin', right: 'create' },
        ncyBreadcrumb: {
            parent: 'synthesemodeles',
            label: '{{ "SYNTHMODS.BREADCRUMBS.SYNTHMOD_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });
}

Configure.$inject = ['$stateProvider'];
