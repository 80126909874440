const serviceUrl = `${__configuration.apiUrl}/massia/performance/processus`;
const serviceEquipmtUrl = `${__configuration.apiUrl}/massia/performance/equipements`;

export default class ProcessusService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getProcessus(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || { skip: 0, take: 0 })
            }
        });
        return res.data;
    }

    async getProcessusOrdre(idSite) {
        const url = `${serviceUrl}/ordre/${idSite}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async deleteProcessById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getProcessById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getProcessusTypes() {
        const id = 'Process';
        const url = `${__configuration.apiUrl}/massia/domaines/${id}/types`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getEquipementTypes() {
        const id = 'Equipements';
        const url = `${__configuration.apiUrl}/massia/domaines/${id}/types`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getConfigurationTypes() {
        const id = 'Config';
        const url = `${__configuration.apiUrl}/massia/domaines/${id}/types`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getProcPrecedents(id) {
        const url = `${serviceUrl}/header/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSites() {
        const application = 'performance';
        const url = `${__configuration.apiUrl}/massia/sites-producteurs/headers/${application}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getProduits(id, valeur) {
        //let url = `${__configuration.apiUrl}/massia/produits/headers/${valeur}`;
        const url = `${__configuration.apiUrl}/massia/produits/produitsProducteur/headers`;
        const res = await this.$http.get(url, {
            params: {
                id: id,
                valeur: valeur
            }
        });
        return res.data;
    }

    async createProcess(data) {
        const result = await this.$http.post(serviceUrl, data);
        return result.data;
    }

    async updateProcess(data) {
        const url = `${serviceUrl}/${data.id}`;
        return this.$http.put(url, data);
    }

    async updateOrdrePosition(processus) {
        const data = [];
        for (let i = 0; i < processus.length; i++) {
            data.push({
                id: processus[i].id,
                ordre: processus[i].ordrePosition
            });
        }

        const url = `${serviceUrl}/ordre`;
        return this.$http.put(url, data);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async codeEquipementExists(code) {
        const url = `${serviceEquipmtUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getProcessIndicateurById(id) {
        const url = `${serviceUrl}/${id}/indicateur`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getEquipementsBySite(idSite) {
        const equipmtUrl = `${__configuration.apiUrl}/massia/performance/equipements/by-site/${idSite}`;
        const res = await this.$http.get(equipmtUrl);
        return res.data;
    }
}
