import Configure from './config/modeles.synthese.routes';
import SyntheseModelesComponent from './components/modeles-synthese-list/synthesemodeles.controller';
import SyntheseModeleFormComponent from './components/modeles-synthese-form';
import SyntheseModeleDetailComponent from './components/synthese-modeles-detail';

const moduleName = 'app.massia.common.synthese.models';

angular
    .module(moduleName, [])
    .run(Configure)
    .component('synthesemodeles', SyntheseModelesComponent)
    .component('synthesemodelesForm', SyntheseModeleFormComponent)
    .component('synthesemodeleDetail', SyntheseModeleDetailComponent);
//.factory('SyntheseModeleValidator', SyntheseModeleValidator);

export default moduleName;
