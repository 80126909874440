InventaireStockFormValidator.$inject = ['validator'];

export default function InventaireStockFormValidator(validator) {
    const instance = new validator();

    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('idSiteCommercial').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('dateLevee').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;
}
