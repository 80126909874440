import TacheEntete from './tache.entete.model';

export default class TacheFormEnteteController {
    static $inject = [
        '$state',
        '$stateParams',
        'TachesService',
        'TachesTypesService',
        'ModalService',
        'notification',
        '$translate',
        'MassiaAuthService',
        'moment'
    ];

    constructor($state, $stateParams, TachesService, TachesTypesService, ModalService, notification, $translate, MassiaAuthService, moment) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.TachesService = TachesService;
        this.TachesTypesService = TachesTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.MassiaAuthService = MassiaAuthService;
        this.moment = moment;
    }

    async $onInit() {
        this.tache = this.tache || {};
        this.entete = new TacheEntete(this.tache.entete);
        if (!this.entete.id) {
            const user = await this.MassiaAuthService.getAuthenticatedUser();
            this.entete.creePar = user.nom + ' ' + user.prenom;
            this.entete.debut = this.moment();
        }
        this.types = this.types || [];
        await this.getNiveauVisibilite();
        await this.getPersonnes();
    }

    async getNiveauVisibilite() {
        if (this.entete.typeId) {
            this.entete.niveauVisibilite = await this.TachesTypesService.GetNiveauVisibilite(this.entete.typeId);
        }
    }

    $onChanges(objChanges) {
        if (objChanges.tache) {
            this.getTypes();
            this.getPersonnes();
        }
    }

    async getPersonnes() {
        if (this.entete) {
            this.startLoading();
            try {
                this.allPersonnes = await this.TachesService.getPersonnes();
                if (this.entete.personnesIds.length > 0) {
                    this.allPersonnes.forEach((item) => {
                        if (this.entete.personnesIds.indexOf(item.id) !== -1) {
                            item.selected = true;
                        }
                    });
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        }
    }

    $doCheck() {
        if (!angular.equals(this.entete, this.tache.entete)) {
            this.onUpdate({
                entete: angular.copy(this.entete)
            });
        }
    }

    async createType() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'TACHES.CONFIRM_CREATE_TYPE.TITLE',
            modalMsg: 'TACHES.CONFIRM_CREATE_TYPE.MESSAGE',
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(() => this.$state.go('taches.newType'));
    }

    async getTypes() {
        this.startLoading();

        try {
            this.types = await this.TachesTypesService.getAll();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.entete.codeExists = await this.TachesService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.entete.codeExists = null;
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
