export default class TypeBasculeDetailController {
    static $inject = ['$stateParams', '$state', 'TypeBasculesService', 'notification', 'TypeBasculesCommunicationService'];

    constructor($stateParams, $state, TypeBasculesService, notification, TypeBasculesCommunicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.TypeBasculesService = TypeBasculesService;
        this.notification = notification;
        this.TypeBasculesCommunicationService = TypeBasculesCommunicationService;
    }

    $onInit() {
        this.loading = false;
        this.typeBascule = {};
        this.typeBasculeId = undefined;
        this.initTypeBascule();
    }

    async initTypeBascule() {
        // on récupère l'id dans les paramètres
        this.typeBasculeId = this.$stateParams.id;

        this.startLoading();
        try {
            // on ajoute await afin de ne pas passer trop rapidement dans le finally
            // et ainsi on garde le loading le temps nécessaire
            await this.getTypeBascule(this.typeBasculeId);
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('type-bascules.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getTypeBascule(typeBasculeId) {
        this.typeBascule = await this.TypeBasculesService.getTypeBasculeById(typeBasculeId);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
