MesureCourbeGranuloValidator.$inject = ['validator'];

export default function MesureCourbeGranuloValidator(validator) {
    const instance = new validator();

    /*instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance.ruleFor('idNature').greaterThan(-1).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('idNature').lessThan(70).withMessage('VALIDATION_NOTEMPTY');*/
    return instance;
}
