export default class ConfigurationController {
    toto = false;
    constructor() {
        if (this.toto) {
            const ElectronStore = window.require('electron-store');
            /* const electron = require('electron');
            this.store = new ElectronStore();
            this.config = {
                server: this.store.get('server'),
                downloadDirectory: this.store.get('downloadDirectory') || electron.remote.app.getPath('downloads')
            }; */
        }
    }

    saveConfig() {
        if (this.toto) {
            /* const { ipcRenderer } = require('electron');
            this.store.set('server', this.config.server);
            this.store.set('downloadDirectory', this.config.downloadDirectory);
            ipcRenderer.send('restart'); */
        }
    }

    onDirSelect($event) {
        if ($event.target.files && $event.target.files[0]) {
            this.config.downloadDirectory = $event.target.files[0].path;
        }
    }

    openSelectDir() {
        document.getElementById('downDir_elec').click();
    }
}
