//Config
import JoursFeriesConfig from './_config/jours.feries.config';
import JoursFeriesRoutes from './_config/jours.feries.routes';

//Components
import JoursFeriesComponent from './components/jours-feries';
import JourFerieFormComponent from './components/jour-ferie-form';
import JourFerieDetailComponent from './components/jour-ferie-detail';

//services
import JoursFeriesService from './services/jours.feries.service';
import JoursFeriesCommunicationService from './services/jours.feries.communication.service';
import RecurrenceService from './services/recurrence.service';

//Validateurs
import JourFerieValidator from './components/jour-ferie-form/jour.ferie.validator';

const moduleName = 'app.massia.common.jours.feries';

angular
    .module(moduleName, [])
    .config(JoursFeriesConfig)
    .config(JoursFeriesRoutes)
    .service('JoursFeriesService', JoursFeriesService)
    .service('JoursFeriesCommunicationService', JoursFeriesCommunicationService)
    .service('RecurrenceService', RecurrenceService)
    .component('joursFeries', JoursFeriesComponent)
    .component('jourFerieDetail', JourFerieDetailComponent)
    .component('jourFerieForm', JourFerieFormComponent)
    .factory('JourFerieValidator', JourFerieValidator);

export default moduleName;
