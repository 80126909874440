import PrefaGrilleController from './prefaGrille.controller';

export default {
    bindings: {
        selectedIdGrille: '=',
        articleObject: '=',
        modalInstance: '=',
        hasBeenModified: '='
    },
    template: require('./prefaGrille.html'),
    controller: PrefaGrilleController
};
