import DepreciationStockPrixController from './depreciation.stock.prix.controller';

export default {
    bindings: {
        ligne: '=',
        modalInstance: '='
    },
    template: require('./depreciation.stock.prix.html'),
    controller: DepreciationStockPrixController
};
