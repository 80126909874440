export default class AnnulationPopupController {
    static $inject = ['$stateParams', 'TicketsCommunicationService', 'TicketsService', 'notification', 'ModalService', '$uibModal'];

    constructor($stateParams, TicketsCommunicationService, TicketsService, notification, ModalService, $uibModal) {
        this.$stateParams = $stateParams;
        this.TicketsCommunicationService = TicketsCommunicationService;
        this.TicketsService = TicketsService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
        this.motifSelected = 1;
    }

    async $onInit() {
        this.getMotifs();
    }

    async getMotifs() {
        this.startLoading();
        try {
            this.motifs = await this.TicketsService.getMotifs();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    confirmer() {
        const data = {
            idMotif: this.motifSelected,
            commentaire: this.commentaire
        };
        this.modalInstance.close(data);
    }

    annuler() {
        this.modalInstance.close(false);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
