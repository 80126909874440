import SiteCertification from './site.accreditation.model';

export default class SiteAccreditationController {
	certificatGeneriques = [];
	cgByTypeSite = [];
	typesCertif = [];
	certificats = [];
	sitesAccrediteurs = [];
	toDelete = [];
	produits = [];
	essais = [];
	normes = [];
	// nombres de colonnes du tableau (si le site est producteur et labo, ajoute une col)
	colspan = 8;

	constructor($state, $stateParams, notification, SiteAccreditationService, PaginationService, $translate, globalizationManagementService) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.notification = notification;
		this.siteAccreditationService = SiteAccreditationService;
		this.PaginationService = PaginationService;
		this.$translate = $translate;
		this.globalizationManagementService = globalizationManagementService;
		this.displayDateMask = globalizationManagementService.getCurrentLanguage().displayDateMask;
	}

	async $onInit() {
		this.loading = true;
		//site
		this.site = this.site || {};
		if (this.site.isLaboratoire && this.site.isProducteur) {
			this.colspan = 9;
		}
		//types de certification (enum)
		this.typesCertif = await this.siteAccreditationService.getTypes();
		//liste des certificats génériques (ist even)
		this.certificatGeneriques = await this.siteAccreditationService.getListeCertificatGenerique();
		// certificats génériques filtrés en fonction du type de site (laboratoire → essais + sites, producteur → produits + sites)
		this.filterListeCertificatGeneriques();

		// liste des certificats liés au site
		this.certificats = this.certificats || [];
		this.certificats = await this.siteAccreditationService.getListeCertificatBySite(this.site);

		// liste des organismes d'accreditation
		this.sitesAccrediteurs = await this.siteAccreditationService.getListeSitesAccrediteurs();

		//liste des produits, normes et essais
		if (this.site.entete.isProducteur) {
			const produitsListe = await this.siteAccreditationService.getListeProduits(this.site);
			this.produits = produitsListe;
			const normesListe = await this.siteAccreditationService.getListeNormes();
			this.normes = normesListe.items;
		}
		if (this.site.entete.isLaboratoire) {
			const essaisListe = await this.siteAccreditationService.getListeEssais();
			this.essais = essaisListe.items;
		}

		this.loading = false;
	}
	$onChange() {}

	// filtre les certificats génériques par types en fonction du type de site
	filterListeCertificatGeneriques() {
		let types = [];
		if (this.site.entete.isLaboratoire && !this.site.entete.isProducteur) {
			//filtre les certifGenerique → essais et site
			types = this.typesCertif.filter((type) => {
				if (type.libelle == 'Site' || type.libelle == 'Essais') {
					return type;
				}
			});
		}

		if (this.site.entete.isProducteur && !this.site.entete.isLaboratoire) {
			//filtre les certifGenerique → produits et site
			types = this.typesCertif.filter((type) => {
				if (type.libelle == 'Site' || type.libelle == 'Produits') {
					return type.id;
				}
			});
		}

		if (this.site.entete.isProducteur && !this.site.entete.isLaboratoire) {
			//filtre les certifGenerique → produits et site
			types = this.typesCertif.filter((type) => {
				if (type.libelle == 'Site' || type.libelle == 'Produits') {
					return type.id;
				}
			});
		}

		if (this.site.entete.isProducteur && this.site.entete.isLaboratoire) {
			//filtre les certifGenerique → produits et site
			types = this.typesCertif.filter((type) => {
				if (type.libelle == 'Site' || type.libelle == 'Produits' || type.libelle == 'Essais') {
					return type.id;
				}
			});
		}
		this.cgByTypeSite = this.certificatGeneriques.filter((item) => {
			if (types.indexOf(item.typeCertif)) {
				return item;
			}
		});
	}

	//ajoute une nouvelle ligne au tableau des certificats pour le certificat génerique séléctionné
	addCertificat(data) {
		const newCertificat = new SiteCertification();
		newCertificat.idSiteCertifie = this.site.id;
		newCertificat.editable = true;
		newCertificat.sitesAccrediteurs = angular.copy(this.sitesAccrediteurs);

		newCertificat.idCertificatGen = data.id;
		newCertificat.certificatGenerique = new CertificatGenerique(data);

		if (this.site.entete.isProducteur) {
			newCertificat.listeProduits = angular.copy(this.produits);
			newCertificat.listeNormes = angular.copy(this.normes);
		}
		if (this.site.entete.isLaboratoire) {
			newCertificat.listeEssais = angular.copy(this.essais);
		}

		this.certificats.unshift(newCertificat);
		data.select = false;
	}

	// supprime une entrée du tableau
	delete(index) {
		this.certificats.splice(index, 1);
	}

	// permet d'éditer une ligne du tableau des certificats
	edit(item) {
		item.editable = true;
		item.sitesAccrediteurs = [...this.sitesAccrediteurs];

		if (item.idSiteAccrediteur != null) {
			item.sitesAccrediteurs.map((site) => {
				if (site.id == item.idSiteAccrediteur) {
					site.select = true;
				}
			});
		}
	}

	// séléction du site accréditeur
	selectSiteAccrediteur(data, index) {
		this.certificats[index].idSiteAccrediteur = data.id;
		this.certificats[index].siteAccrediteur = data;
	}

	// ajoute ou enlève un essai de la liste des essais certifiés
	addEssai(data, index) {
		//liste des essais certifié pour le certificat
		const liste = this.certificats[index].essais;

		//si la liste ne contient aucun essai
		if (liste.length === 0) {
			const certifEssai = {
				id: null,
				idEssai: data.id,
				idCertificat: this.certificats[index].id,
				codeEssai: data.code,
				libelleEssai: data.libelle
			};
			this.certificats[index].essais.push(certifEssai);
		} else {
			//vérifie si l'esai est présent dans la liste
			const indexEssai = liste.findIndex((x) => x.idEssai === data.id);

			if (indexEssai < 0) {
				//ajoute l'essai
				const certifEssai = {
					id: null,
					idEssai: data.id,
					idCertificat: this.certificats[index].id,
					codeEssai: data.code,
					libelleEssai: data.libelle
				};
				this.certificats[index].essais.push(certifEssai);
			} else {
				//retire l'essai de la liste
				this.certificats[index].essais.splice(indexEssai, 1);
			}
		}
	}

	addProduit(data, index) {
		const liste = this.certificats[index].displayedproduits;

		if (liste == undefined) {
			this.certificats[index].displayedproduits = [];
		}

		const indexProd = liste.findIndex((x) => x.idProduit === data.id);

		if (indexProd < 0) {
			const p = {
				idProduit: data.id,
				codeProduit: data.code,
				libelleProduit: data.libelle,
				normes: [],
				listeNormes: angular.copy(this.normes)
			};
			this.certificats[index].displayedproduits.push(p);
		} else {
			this.certificats[index].displayedproduits.splice(indexProd, 1);
			let idx = this.certificats[index].produits.findIndex((x) => x.idProduit === data.id);
			while (idx !== -1) {
				this.certificats[index].produits.splice(idx, 1);
				idx = this.certificats[index].produits.findIndex((x) => x.idProduit === data.id);
			}
		}
	}

	addProduitNorme(norme, indexParent, produit) {
		const add =
			produit.normes.findIndex((el) => {
				return el.id == norme.id;
			}) >= 0;

		if (add) {
			//ajout d'une norme pour le produit

			const pcn = {
				id: null,
				idNorme: norme.id,
				codeNorme: norme.code,
				libelleNorme: norme.libelle,
				idProduit: produit.idProduit,
				codeProduit: produit.codeProduit,
				libelleProduit: produit.libelleProduit
			};
			this.certificats[indexParent].produits.push(pcn);
		} else {
			const i = this.certificats[indexParent].produits.findIndex((el) => {
				return el.idProduit == produit.idProduit && el.idNorme == norme.id;
			});
			this.certificats[indexParent].produits.splice(i, 1);
		}
	}

	openAddEssai(item) {
		item.addProduct = false;
		if (item.addEssai == undefined) {
			item.addEssai = true;
			item.listeEssais = angular.copy(this.essais);
		} else {
			item.addEssai = !item.addEssai;
		}
		this.openRow(item);
	}

	openAddProduct(item) {
		item.addEssai = false;
		if (item.addProduct == undefined) {
			item.addProduct = true;
			item.listeProduits = angular.copy(this.produits);
			const pcertifs = angular.copy(item.produits);
			item.displayedproduits = this.formatListProduitCertifie(pcertifs);
		} else {
			item.addProduct = !item.addProduct;
		}
		this.openRow(item);
	}

	//? ouvre / ferme la ligne du tableau pour modif essais/produits
	openRow(item) {
		if (item.open == undefined) {
			item.open = true;
		} else {
			item.open = item.addEssai || item.addProduct;
		}
	}

	//? formate la liste des produits certifiés pour l'affichage
	formatListProduitCertifie(produitsCertifie) {
		const productsIds = [];
		const formatedProducts = [];
		produitsCertifie.forEach((element) => {
			if (productsIds.indexOf(element.idProduit) === -1) {
				productsIds.push(element.idProduit);
				const p = {
					idProduit: element.idProduit,
					codeProduit: element.codeProduit,
					libelleProduit: element.libelleProduit,
					normes: [],
					listeNormes: angular.copy(this.normes)
				};
				const n = {
					id: element.idNorme,
					code: element.codeNorme,
					libelle: element.libelleNorme
				};

				p.normes.push(n);
				formatedProducts.push(p);
			} else {
				const i = formatedProducts.findIndex((item) => {
					if (item.idProduit == element.idProduit) {
						return item;
					}
				});

				const n = {
					id: element.idNorme,
					code: element.codeNorme,
					libelle: element.libelleNorme
				};

				formatedProducts[i].normes.push(n);
			}
		});

		return formatedProducts;
	}
}

SiteAccreditationController.$inject = [
	'$state',
	'$stateParams',
	'notification',
	'SiteAccreditationService',
	'PaginationService',
	'$translate',
	'globalizationManagementService'
];
