export default class NiveauxHierarchiquesController {
    static $inject = [];

    constructor() {}

    $onInit() {
        this.nodeUpdated = this.nodeUpdated || {};
        this.name = this.name || '';
        this.updateName(this.name);
    }

    $onChanges(changesObj) {
        // Bindings changes
        if (changesObj.name) {
            this.updateName(changesObj.name.currentValue);
        }
    }

    updateName(name = '') {
        this.trameConfig.name = name + 'Trame';
        this.elementsConfig.name = name + 'Elements';
    }

    onTrameChange(node) {
        this.nodeUpdated = angular.copy(node);
    }
}
