import Prelevement from './prelevement.model';
import FileImport from './prelevement.import.model';

export default class PrelevementController {
	/**
	 * @param {import('angular').IScope} $scope
	 * @param {import('angular').IRootScopeService} $rootScope
	 */
	/* @ngInject */
	constructor(
		$scope,
		$timeout,
		$state,
		$stateParams,
		PrelevementsCommunicationService,
		PrelevementsService,
		notification,
		$location,
		$anchorScroll,
		_,
		MassiaApplicationService,
		$uibModal,
		DataShareService,
		ModalService,
		$translate,
		TemplateImpressionService,
		ExcelService,
		RouterHistoryService,
		moment,
		MesuresService,
		SharedFileService,
		globalizationManagementService,
		$rootScope,
		PrelevementsConfigImpressionService,
		MassiaRightsService,
		SaisieProductionService,
		PrelevEssaiMaterielsService,
		FormuleService,
		FormuleCalculsService,
		SettingPortailService,
		ProduitsService,
		ConfigurationBalanceService,
		SitesService,
		Interpreteur,
		MOPService
	) {
		/**
		 * @type ng.IRootScopeService
		 */
		this.$rootScope = $rootScope;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.PrelevementsCommunicationService = PrelevementsCommunicationService;
		this.PrelevementsService = PrelevementsService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this._ = _;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$uibModal = $uibModal;
		this.DataShareService = DataShareService;
		this.ModalService = ModalService;
		this.$translate = $translate;
		this.TemplateImpressionService = TemplateImpressionService;
		this.ongletOpen = {
			isEnteteOpen: true,
			isCaracteristiqueOpen: true,
			isListeMesuresOpen: false
		};
		this.ongletOpenVal = {
			isEnteteOpen: 'entete',
			isListeMesuresOpen: 'listeMesures'
		};
		this.ExcelService = ExcelService;
		this.RouterHistoryService = RouterHistoryService;
		this.moment = moment;
		this.MesuresService = MesuresService;
		this.SharedFileService = SharedFileService;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
		this.PrelevementsConfigImpressionService = PrelevementsConfigImpressionService;
		this.MassiaRightsService = MassiaRightsService;
		this.SaisieProductionService = SaisieProductionService;
		this.PrelevEssaiMaterielsService = PrelevEssaiMaterielsService;
		this.FormuleService = FormuleService;
		this.FormuleCalculsService = FormuleCalculsService;
		this.SettingPortailService = SettingPortailService;
		this.loadingMesures = true;
		this.ProduitsService = ProduitsService;
		this.ConfigurationBalanceService = ConfigurationBalanceService;
		this.SitesService = SitesService;
		this.Interpreteur = Interpreteur;
		this.MOPService = MOPService;
	}

	async $onInit() {
		this.MOPService.setMop([{ title: 'PRELEVEMENTS.BREADCRUMBS.PRELEVEMENTS_LIST', filename: 'Prelevements.pdf', application: 'laboratoire' }]);
		this.clotureAccess = this.MassiaRightsService.userHasRight('clotplv', 'update', 'laboratoire');
		this.portailAccess = this.MassiaRightsService.userHasRight('portail', 'read', 'laboratoire');
		this.camsizerAccess = this.MassiaRightsService.userHasRight('importcamsizer', 'read', 'laboratoire');
		this.unregister = [];
		this.configurationBalanceLabo = null;
		this.configurationBalanceMateriel = null;
		this.configBalance = null;
		const domaineCourant = this.getDomaineCourant();
		if (domaineCourant == 'saisies') {
			this.domaineMesure = 'mesures';
		} else if (domaineCourant == 'saisiesecu') {
			this.domaineMesure = 'messecu';
		} else if (domaineCourant == 'saisiectrl') {
			this.domaineMesure = 'controles';
		} else if (domaineCourant == 'prelevements') {
			this.domaineMesure = 'essais';
		}
		this.form = {
			//SP 07/12/19 ancien this.saisie.form
			data: this.resolve && this.resolve.data && this.resolve.data.id ? new FileImport(this.resolve.data) : new FileImport(),
			entete: {},
			listeMesures: {}
		};

		this.updateSaisieTimeout = this.$timeout(() => this.stopLoading());
		this.$scope.reset = this.reset;
		this.reset();
		// console.log(this.moment(this.moment().format(this.dateFormat)));
		this.$scope.$on('savePrelevBeforeImportPresse3r', async (e) => await this.sauvegarder());
		this.$scope.$on('ImportPresse3r', async (e) => await this.reset());

		// Communication balance

		this.unregister.push(
			this.$scope.$watch(
				() => this.saisie?.auditeur,
				async () => {
					if (this.saisie?.auditeur) {
						// vérifie si une balance est paramétrée pour le site
						const res = await this.SitesService.getSiteEnteteById(this.saisie.auditeur.id, 'laboratoire', 'laboratoire');

						if (res?.configurationBalance?.id) {
							this.configurationBalanceLabo = res.configurationBalance;
						} else {
							this.configurationBalanceLabo = null;
						}

						if (this.configurationBalanceLabo != null && !this.configurationBalanceMateriel?.id) {
							this.configBalance = new ConfigurationBalance(
								await this.ConfigurationBalanceService.getOneById(this.configurationBalanceLabo.id)
							);
						}
					}
				}
			)
		);
		this.unregister.push(
			this.$scope.$watch(
				() => this.configurationBalanceMateriel,
				async () => {
					if (this.configurationBalanceMateriel?.id) {
						this.configBalance = new ConfigurationBalance(
							await this.ConfigurationBalanceService.getOneById(this.configurationBalanceMateriel.id)
						);
					}
				}
			)
		);
		this.blDataLoaded = false;
	}

	$onDestroy() {
		this.$timeout.cancel(this.updateSaisieTimeout);
		for (let i = 0; i < this.unregister.length; i++) {
			this.unregister[i]();
		}
		this.MOPService.resetMop();
	}

	getDomaineCourant() {
		if (this.codeDomaine) {
			return this.codeDomaine;
		} else if (
			this.$state.current.name === 'saisies.edit-prelevement' ||
			this.$state.current.name === 'saisies.new-prelevement' ||
			this.$state.current.name === 'saisies.duplicate-prelevement'
		) {
			return 'saisies';
		} else if (
			this.$state.current.name === 'saisiectrl.edit-saprelevementisie' ||
			this.$state.current.name === 'saisiectrl.new-prelevement' ||
			this.$state.current.name === 'saisiectrl.duplicate-prelevement'
		) {
			return 'saisiectrl';
		} else if (
			this.$state.current.name === 'saisiesecu.edit-prelevement' ||
			this.$state.current.name === 'saisiesecu.new-prelevement' ||
			this.$state.current.name === 'saisiesecu.duplicate-prelevement'
		) {
			return 'saisiesecu';
		} else if (
			this.$state.current.name === 'prelevements.edit-prelevement' ||
			this.$state.current.name === 'prelevements.new-prelevement' ||
			this.$state.current.name === 'prelevements.duplicate-prelevement'
		) {
			return 'prelevements';
		}
	}

	async reset() {
		try {
			this.startLoading();

			let data = {
				id: this.id ? this.id : this.$stateParams.id //this.id=vient de synthèse
			};

			const tmp = this.RouterHistoryService.data;
			if (tmp) {
				this.saisie = new Prelevement(tmp, this.moment, this.dateFormat);
				this.saisie.forceRefreshCaracteristiques = false;

				//wait 1 cycle to let time subcoponents to reload
				this.$rootScope.$broadcast('headerDataChange', this.saisie);
			} else {
				if (data.id) {
					const prelCopy = angular.copy(this.saisie);
					if (prelCopy) {
						prelCopy.outsourceModel(this.moment, this.dateFormat);
					}
					data = await this.PrelevementsService.getPrelevementById(data.id, prelCopy);
					/**duplication */
					this.isEditMode = true;
					this.hasEnregistrements = data.hasEnregistrements;
					/****************************************************** */
				} else {
					if (this._.isEmpty(this.DataShareService.savedData) == false) {
						// on cherche l'entité saisie

						const dataShareSaisie = this.DataShareService.getDataShare('saisie');
						data = await this.affectToData(data, dataShareSaisie);
					}
				}

				this.saisie = new Prelevement(data, this.moment, this.dateFormat);
			}

			if (this.getDomaineCourant() != 'prelevements') {
				const res = await this.PrelevementsService.getThemeById(this.idTheme ? this.idTheme : this.$stateParams.idTheme);
				this.libelleTheme = res.libelle;
				this.isImportable(res.code);
			} else {
				const res = await this.PrelevementsService.getFamilleById(this.idFamille ? this.idFamille : this.$stateParams.idFamille);
				this.saisie.famille = res;
				this.isImportable(res.code);
			}

			this.ongletOpen = {
				isEnteteOpen: true,
				isCaracteristiqueOpen: true,
				isListeMesuresOpen: true
			};

			/**
			 * duplication
			 */
			if (this.$stateParams.duplicate) {
				this.saisie.code += '_copie';
				if (this.saisie.estCodeAuto) {
					this.saisie.code = '';
				}
				this.saisie.codeExists = false;
				this.saisie.traiteDuplication = true;
			}

			// this.saisie.produitCommerciaux = [];
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
			this.annuler();
		} finally {
			this.stopLoading();
		}
	}

	async affectToData(data, dataShareSaisie) {
		if (dataShareSaisie !== null) {
			if (dataShareSaisie.siteProdIdSelected) {
				data.producteur = {};
				data.producteur.id = dataShareSaisie.siteProdIdSelected;
				data.producteur.code = dataShareSaisie.siteProdCodeSelected;
				data.producteur.libelle = dataShareSaisie.siteProdLibelleSelected;
			}
			if (dataShareSaisie.modeleIdSelected) {
				const modeleSaisie = await this.PrelevementsService.GetModeleById(dataShareSaisie.modeleIdSelected);
				data.modeleSaisie = modeleSaisie;
				if (modeleSaisie.siteLaboId && (!data.auditeur || !data.auditeur.id)) {
					data.auditeur = {};
					data.auditeur.id = modeleSaisie.siteLaboId;
					data.auditeur.code = modeleSaisie.siteLaboCode;
					data.auditeur.libelle = modeleSaisie.siteLaboLibelle;
				}
				if (modeleSaisie.siteProdId && (!data.producteur || !data.producteur.id)) {
					data.producteur = {};
					data.producteur.id = modeleSaisie.siteProdId;
					data.producteur.code = modeleSaisie.siteProducteurCode;
					data.producteur.libelle = modeleSaisie.siteProducteurLibelle;
				}
				if (modeleSaisie.siteClientId && (!data.client || !data.client.id)) {
					data.client = {};
					data.client.id = modeleSaisie.siteClientId;
					data.client.code = modeleSaisie.siteClientCode;
					data.client.libelle = modeleSaisie.siteClientLibelle;
				}
				if (modeleSaisie.produitId && (!data.produit || !data.produit.id)) {
					data.produit = {};
					data.produit.id = modeleSaisie.produitId;
					data.produit.code = modeleSaisie.produitCode;
					data.produit.libelle = modeleSaisie.produitLibelle;
				}
				if (modeleSaisie.pointsMesuresIds && modeleSaisie.pointsMesuresIds.length == 1) {
					data.pointMesure = {};
					data.pointMesure.id = modeleSaisie.pointsMesuresIds[0];
					data.pointMesure.libelle = modeleSaisie.pointsMesures[0];
				}
				if (modeleSaisie.typeSMId) {
					data.type = {};
					data.type.id = modeleSaisie.typeSMId;
					data.type.code = modeleSaisie.typeSMCode;
					data.type.libelle = modeleSaisie.typeSMLibelle;
				}
				if (modeleSaisie.trameId) {
					data.trame = {};
					data.trame.id = modeleSaisie.trameId;
					data.trame.code = modeleSaisie.trameCode;
					data.trame.libelle = modeleSaisie.trameLibelle;
				}
				if (modeleSaisie.references && modeleSaisie.references.length == 1) {
					data.reference = {};
					data.reference.id = modeleSaisie.references[0].referenceId;
					data.reference.code = modeleSaisie.references[0].referenceCode;
					data.reference.libelle = modeleSaisie.references[0].referenceLibelle;
				}
				if (modeleSaisie.aideSaisie) {
					this.aideSaisie = modeleSaisie.aideSaisie;
				}
				if (modeleSaisie.personneId) {
					data.personneFaitPar = {};
					data.personneFaitPar.id = modeleSaisie.personneId;
				}
			}
		}

		return data;
	}

	async updateJourProduction() {
		if (this.saisie.joursProduction && this.saisie.joursProduction.length > 0) {
			const updateObject = new Response();
			this.saisie.joursProduction[0].idSiteProducteur = this.saisie.producteur.id;
			updateObject.listJourProduction = this.saisie.joursProduction;
			await this.SaisieProductionService.update(updateObject);
		}
	}

	isImportable(codeFamille) {
		const supportImport = {
			name: ['BPE', 'CIM']
		};
		if (this.$state.current.rights.right === 'update') {
			this.form.isImportable = supportImport.name.includes(codeFamille);
		}
	}

	async sauvegarder() {
		this.startLoading();
		try {
			console.log(this.saisie);
			if (this.$stateParams.duplicate) {
				this.saisie.id = undefined;
			}
			if (this.checkValidity()) {
				let id = false;
				const copySaisie = angular.copy(this.saisie);
				if (copySaisie) {
					copySaisie.outsourceModel(this.moment, this.dateFormat);
				}
				this.updateJourProduction();

				if (copySaisie.id) {
					id = await this.PrelevementsService.updateSaisie(copySaisie);
				} else {
					/**
					 * created : {
					 *  id: number,
					 *  code: string,
					 *  libelle: string
					 * }
					 */
					const created = await this.PrelevementsService.createSaisie(copySaisie);
					id = created.id;
				}

				// if (this.saisie.produitCommerciaux.length > 0) {
				// const res = await this.ProduitsService(this.saisie.id, { produitsCom: [this.produitCommerciaux] });
				// }

				//Metrologie
				await this.saveMetrologie(id);

				const msg =
					this.getDomaineCourant().indexOf('prelevements') >= 0 ? 'PRELEVEMENTS.UPDATED_PRELEVEMENT' : 'PRELEVEMENTS.UPDATED_SAISIE';
				this.notification.success(this.$translate.instant(msg));

				return id;
			}
			this.notification.error(this.$translate.instant('SAISIES_MESURES.MANDATORY_FIELD_MISSING'));
		} catch (ex) {
			if (ex && ex.data && ex.data.exceptionMessage) {
				this.notification.error(ex.data.exceptionMessage);
			} else {
				this.notification.error(ex.data);
			}
			return false;
		} finally {
			this.stopLoading();
		}
	}

	async saveMetrologie(id) {
		try {
			if (this.saisie && this.saisie.mesures && this.saisie.mesures.length > 0) {
				for (let m = 0; m < this.saisie.mesures.length; m++) {
					const mes = this.saisie.mesures[m];
					if (this.saisie.materiels[mes.id]) {
						for (let i = 0; i < this.saisie.materiels[mes.id].length; i++) {
							const l = this.saisie.materiels[mes.id][i];
							if (l.id && l.linkedParams) {
								l.linkedParams.idPrelevement = id;
								// if (l.linkedParams, l.linkedParams.idPrelevEssai) {§//}
								if (l.inDb === true && l.selected === true) {
									this.PrelevEssaiMaterielsService.putMateriel(l.linkedParams);
								} else if (l.inDb === false && l.selected === true) {
									// l.linkedParams.idEssai = this.mesure.id;
									this.PrelevEssaiMaterielsService.postMateriel(l.linkedParams);
								} else if (l.inDb === true && l.selected === false) {
									this.PrelevEssaiMaterielsService.deleteMateriel(l.linkedParams);
								}
							}
						}
					}
				}
			}
		} catch (e) {
			console.error(e);
		}
	}

	async appliquer() {
		const id = await this.sauvegarder();
		// this.$scope.$broadcast('saveMateriels');
		if (id && this.saisie.id) {
			this.reset(); //SP 07/12/19 commenté ? VP 09/12/20 : oui car pas forcément de raison de recharger
		} else if (id) {
			this.$state.go(this.getDomaineCourant() + '.edit-prelevement', {
				idTheme: this.idTheme ? this.idTheme : this.$stateParams.idTheme,
				id: id,
				idFamille: this.idFamille ? this.idFamille : this.$stateParams.idFamille
			});
		}
	}

	async confirmer() {
		const success = await this.sauvegarder();

		if (success) {
			if (this.modalInstance) {
				this.modalInstance.close(true);
			} else {
				this.annuler();
			}
		}
	}

	afficheAide() {
		const msg = this.aideSaisie.replace(/\n/g, '<br/>');
		const modalInstance = this.ModalService.info({
			modalTitle: this.$translate.instant('PRELEVEMENTS.AIDE_SAISIE'),
			modalMsg: this.$translate.instant(msg)
		});
	}

	async excelUpdated() {
		await this.reset();
	}

	ajoutMesure() {
		const _this = this;
		const modalInstance = this.$uibModal.open({
			animation: true,
			ariaLabelledBy: 'modal-title',
			ariaDescribedBy: 'modal-body',
			templateUrl: 'ajoutMesuresModalContent',
			controllerAs: '$ctrl',
			controller: [
				'$uibModalInstance',
				'_',
				function ($uibModalInstance, _) {
					this.typeFiltreId = _this.saisie.type.id;
					this.typeProduitId = _this.saisie.produit.idType;
					this.mesuresSaisies = [];
					this.mesuresAAjouter = [];
					this.domaineCourant = _this.getDomaineCourant();
					this.domaineMesure = _this.domaineMesure;
					for (let i = 0; i < _this.saisie.mesures.length; i++) {
						this.mesuresSaisies.push({
							essaiId: _this.saisie.mesures[i].id
						});
					}
					this.$uibModalInstance = $uibModalInstance;
					this._ = _;

					this.selectMesure = function (mesure) {
						const idx = this.mesuresAAjouter.findIndex((x) => x.id === mesure.id);

						if (idx < 0) {
							this.mesuresAAjouter.push(mesure);
						} else if (!mesure.selected) {
							this.mesuresAAjouter.splice(idx, 1);
						}
					};
					this.onDelete = function (id) {
						const idx = this._.findIndex(this.mesuresAAjouter, {
							id: id.id
						});
						if (idx > -1) {
							this.mesuresAAjouter.splice(idx, 1);
						}
					};
					this.onDeleteAll = function () {
						this.mesuresAAjouter = [];
					};

					this.ok = function () {
						this.$uibModalInstance.close({
							mesures: this.mesuresAAjouter
						});
					};
					this.cancel = function () {
						this.$uibModalInstance.close();
					};
				}
			]
		});

		modalInstance.result.then(async (result) => {
			if (result && result.mesures) {
				this.startLoading();
				try {
					const oldMesures = angular.copy(this.saisie.mesures);

					// initialise l'ordre des essais
					const nbEssaiSaisie = oldMesures.length - 1;
					oldMesures.sort((a, b) => a.indexSaisie - b.indexSaisie);
					let i = 0;
					oldMesures.forEach((element) => {
						element.indexSaisie = i++;
					});

					this.saisie.mesures = await this.PrelevementsService.getMesuresComplet(
						this.saisie.id,
						result.mesures.map((e) => e.id).concat(this.saisie.mesures.map((e) => e.id))
					);
					this.saisie = new Prelevement(this.saisie, this.moment, this.dateFormat);

					//diff if mes has to be deleted
					for (let i = this.saisie.mesures.length - 1; i >= 0; i--) {
						const el = this.saisie.mesures[i];
						el.indexSaisie = i;

						const oldMes = oldMesures.find((e) => e.code === el.code);
						if (!oldMes && result.mesures.map((e) => e.id).indexOf(el.id) < 0) {
							this.saisie.mesures.splice(i, 1);
						} else if (oldMes) {
							this.saisie.mesures[i] = oldMes;
						}
					}

					//diff if mes has to be added and not saved
					for (let i = oldMesures.length - 1; i >= 0; i--) {
						const oldMes = oldMesures[i];
						const el = this.saisie.mesures.find((e) => e.code === oldMes.code);
						if (!el) {
							this.saisie.mesures.push(oldMes);
						}
					}

					const toAddMesures = oldMesures.filter((e) => this.saisie.mesures.map((e) => e.id).indexOf(e.id) < 0);
					this.saisie.mesures = this.saisie.mesures.concat(toAddMesures);
				} catch (ex) {
					this.notification.error(ex.data);
				} finally {
					this.stopLoading();
				}
			}
		});
	}

	annuler() {
		if (this.modalInstance) {
			this.modalInstance.close();
		} else {
			if (!this.RouterHistoryService.back()) {
				this.$state.go(this.getDomaineCourant() + '.list', {
					idTheme: this.idTheme ? this.idTheme : this.$stateParams.idTheme,
					idFamille: this.idFamille ? this.idFamille : this.$stateParams.idFamille
				});
			} else {
				let comeFromHome = null;
				if (_.isEmpty(this.DataShareService.savedData) == false) {
					comeFromHome = _.find(this.DataShareService.savedData, { comeFromHome: true });
				}
				if (comeFromHome !== null && comeFromHome) {
					this.DataShareService.savedData = this.DataShareService.savedData.filter((x) => !x.comeFromHome);
					this.$state.go('laboratoire.home');
				} else {
					this.$state.go(this.getDomaineCourant() + '.list', {
						idTheme: this.idTheme ? this.idTheme : this.$stateParams.idTheme,
						idFamille: this.idFamille ? this.idFamille : this.$stateParams.idFamille
					});
				}
			}
		}

		this.stopLoading();
	}

	checkValidity() {
		let validity = true;

		let firstScroll = true;
		if (!this.saisie.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}

			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('saisieEnteteValidations');
		}
		if (!this.saisie.caracteristiques.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('caracteristiques');
				firstScroll = false;
			}

			this.ongletOpen.isCaracteristiqueOpen = true;
			validity = false;
			this.$scope.$broadcast('saisieCaracteristiquesValidations');
		}

		return validity;
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	async getTamisValeur(listEssWTamToFormat = null) {
		const array = [];
		let mesures = [];
		if (listEssWTamToFormat != null) {
			mesures = listEssWTamToFormat;
		} else {
			mesures = angular.copy(this.saisie.mesures);
		}
		for (let i = 0; i < mesures.length; i++) {
			let essaiGranulo = null;
			let tamis = null;
			const mesure = mesures[i];
			for (const k in mesure.sousEssais) {
				const ssEss = mesure.sousEssais[k];
				if (ssEss.hasOwnProperty('id') && ssEss.idNature === 8) {
					//analyse granulo
					essaiGranulo = mesure;
					tamis = ssEss.granuloAdditionalInformation.tamis.filter((e) => e.isDisplay);
					for (let j = 0; j < tamis.length; j++) {
						const t = tamis[j];

						t.ouverture = `${t.ouverture}`.replace(/,/g, '.');
						if (t && (t.conformite != null || typeof t.conformite !== 'undefined')) {
							if (t.conformite === 0) {
								t.pourcPassantConformite = {
									value: t.pourcPassant,
									text: '009900'
								};
								t.pourcPassantArrondiConformite = {
									value: t.pourcPassantArrondi,
									text: '009900'
								};
							} else if (t.conformite === 1) {
								t.pourcPassantConformite = {
									value: t.pourcPassant,
									text: 'FFAA00'
								};
								t.pourcPassantArrondiConformite = {
									value: t.pourcPassantArrondi,
									text: 'FFAA00'
								};
							} else if (t.conformite === 2) {
								t.pourcPassantConformite = {
									value: t.pourcPassant,
									text: 'FF0000'
								};
								t.pourcPassantArrondiConformite = {
									value: t.pourcPassantArrondi,
									text: 'FF0000'
								};
							} else if (t.conformite === 3) {
								t.pourcPassantConformite = {
									value: t.pourcPassant,
									text: '0000FF'
								};
								t.pourcPassantArrondiConformite = {
									value: t.pourcPassantArrondi,
									text: '0000FF'
								};
							} else {
								t.pourcPassantConformite = t.pourcPassant;
								t.pourcPassantArrondiConformite = t.pourcPassantArrondi;
							}
						} else {
							t.pourcPassantConformite = t.pourcPassant;
							t.pourcPassantArrondiConformite = t.pourcPassantArrondi;
						}
						if (t && t.bornes) {
							const borne = t.bornes;
							t.incertitude = borne.incertitude;
							if (
								(borne.alerteInferieur && typeof borne.alerteInferieur === 'number') ||
								(borne.alerteSuperieure && typeof borne.alerteSuperieure === 'number')
							) {
								t.alerteInferieure = {
									value: borne.alerteInferieur || borne.alerteInferieur == 0 ? borne.alerteInferieur + '' : '-',
									text: '000000'
								};
								t.alerteSuperieure = {
									value: borne.alerteSuperieure || borne.alerteSuperieure == 0 ? '' + borne.alerteSuperieure : '-',
									text: '000000'
								};
							}
							if (
								(borne.borneInferieure && typeof borne.borneInferieure === 'number') ||
								(borne.borneSuperieure && typeof borne.borneSuperieure === 'number')
							) {
								t.borneInferieure = {
									value: borne.borneInferieure || borne.borneInferieure == 0 ? borne.borneInferieure + '' : '-',
									text: '000000'
								};
								t.borneSuperieure = {
									value: borne.borneSuperieure || borne.borneSuperieure == 0 ? '' + borne.borneSuperieure : '-',
									text: '000000'
								};
							}
							if (
								(borne.toleranceInferieure && typeof borne.toleranceInferieure === 'number') ||
								(borne.toleranceSuperieure && typeof borne.toleranceSuperieure === 'number')
							) {
								t.toleranceInferieure = {
									value: borne.toleranceInferieure || borne.toleranceInferieure == 0 ? borne.toleranceInferieure + '' : '-',
									text: '000000'
								};
								t.toleranceSuperieure = {
									value: borne.toleranceSuperieure || borne.toleranceSuperieure == 0 ? '' + borne.toleranceSuperieure : '-',
									text: '000000'
								};
							}
						}
					}
				}
			}
			if (tamis && tamis.length > 0 && (tamis[0].value || tamis[0].value === 0)) {
				tamis = tamis ? this._.map(tamis, 'value') : null;
			}
			if (essaiGranulo) {
				const sousEssaisGran = Object.values(essaiGranulo.sousEssais).filter((x) => {
					return x.idNature != 8;
				});
				essaiGranulo.sousEssais = sousEssaisGran;
			}
			if (tamis && essaiGranulo) {
				array.push({ tamis: tamis, essai: essaiGranulo });
			}
		}

		return array;
	}

	verificationEssaiDansToPrint(tableau, essais) {
		if (tableau && essais) {
			if (!tableau.map((e) => e.id).includes(essais.id)) {
				return true;
			} else if (!tableau.map((e) => e.libelle).includes(essais.libelle)) {
				return true;
			}
		}
	}

	verificationCaracteristiqueDansToPrint(tableau, caracteristique) {
		if (tableau && caracteristique) {
			if (!tableau.map((e) => e.id).includes(caracteristique.code)) {
				return true;
			}
		}
	}
	//! After
	formatToPrintAfterConfig(essais, caracteristiques, configImpression) {
		if (configImpression && configImpression.switch) {
			let tmpEssaisToPrint = [];
			for (let i = 0; i < essais.length; i++) {
				const ess = essais[i];
				if (ess && ess.sousMesures && !configImpression.switch.essaisRef) {
					const essPrincipale = ess.sousMesures.find((x) => x.code === ess.code);
					if (essPrincipale) {
						// essais avec valeur
						if (configImpression.switch.essaisAvecValeur === true) {
							if (essPrincipale.valeur && essPrincipale.valeur !== '' && essPrincipale.valeur !== '-') {
								// essais Terminés
								if (configImpression.switch.essaisTermines === true) {
									const tmpEss = this.saisie.mesures.find((x) => x.id === ess.id);

									if (tmpEss.dateReelle) {
										if (this.verificationEssaiDansToPrint(tmpEssaisToPrint, ess)) {
											tmpEssaisToPrint.push(angular.copy(ess));
										}
									}
								}
								// essais non Terminés
								if (configImpression.switch.essaisNonTermines === true) {
									const tmpEss = this.saisie.mesures.find((x) => x.id === ess.id);

									if (!tmpEss.dateReelle) {
										if (this.verificationEssaiDansToPrint(tmpEssaisToPrint, ess)) {
											tmpEssaisToPrint.push(angular.copy(ess));
										}
									}
								}
							}
						}

						// essai sans valeur
						if (configImpression.switch.essaisSansValeur === true) {
							if (!essPrincipale.valeur || essPrincipale.valeur === '' || essPrincipale.valeur === '-' || essPrincipale.valeur === 0) {
								if (this.verificationEssaiDansToPrint(tmpEssaisToPrint, ess)) {
									tmpEssaisToPrint.push(angular.copy(ess));
								}
							}
						}
					}
				}

				// si essais de la ref
				const tmpEssaisTabFiltredForDelete = [];
				if (configImpression.switch.essaisRef === true) {
					// On récupère les essais du Plv
					const essaisPlv = essais;
					for (const essaiPlv of essaisPlv) {
						if (essaiPlv && essaiPlv.sousMesures) {
							// On récupère les essais de la Ref
							const essaisRef = configImpression.listEssais;
							for (const essaiRef of essaisRef) {
								if (configImpression.switch.essaisAvecValeur === true && configImpression.switch.essaisSansValeur === false) {
									if (essaiRef.id === essaiPlv.id) {
										const ssMesurePrincipale = essaiPlv.sousMesures.find((x) => x.code === essaiPlv.code);
										if (ssMesurePrincipale.valeur && ssMesurePrincipale.valeur !== '' && ssMesurePrincipale.valeur !== '-') {
											const essPrincipal = essaiPlv;
											essPrincipal.sousMesures[0] = ssMesurePrincipale;
											if (this.verificationEssaiDansToPrint(tmpEssaisToPrint, essPrincipal)) {
												tmpEssaisToPrint.push(essPrincipal);
											}
										}
									}
								} else if (configImpression.switch.essaisAvecValeur === false && configImpression.switch.essaisSansValeur === true) {
									if (essaiRef.id === essaiPlv.id) {
										const ssMesurePrincipale = essaiPlv.sousMesures.find((x) => x.code === essaiPlv.code);
										if (
											!ssMesurePrincipale.valeur ||
											ssMesurePrincipale.valeur === '' ||
											ssMesurePrincipale.valeur === '-' ||
											ssMesurePrincipale.valeur === 0
										) {
											const essPrincipal = essaiPlv;
											if (this.verificationEssaiDansToPrint(tmpEssaisToPrint, essPrincipal)) {
												tmpEssaisToPrint.push(essPrincipal);
											} else {
												const tmpEssaisTabFiltred = tmpEssaisToPrint.filter((x) => x.id !== essPrincipal.id);
												tmpEssaisTabFiltred.push(essPrincipal);
												tmpEssaisToPrint = tmpEssaisTabFiltred;
											}
										} else {
											tmpEssaisTabFiltredForDelete.push(essaiRef);
										}
									} else {
										if (this.verificationEssaiDansToPrint(tmpEssaisToPrint, essaiRef)) {
											tmpEssaisToPrint.push(essaiRef);
										}
									}
									const tmpEssaisFiltred = null;
									if (tmpEssaisTabFiltredForDelete !== null && tmpEssaisTabFiltredForDelete.length !== 0) {
										const tmpEssaisTabFiltred = tmpEssaisTabFiltredForDelete.find((x) => x.id === essaiRef.id);
										if (tmpEssaisTabFiltred != null && tmpEssaisTabFiltred.length !== 0) {
											tmpEssaisToPrint = tmpEssaisToPrint.filter((x) => x.id !== tmpEssaisTabFiltred.id);
										}
									}
								} else if (configImpression.switch.essaisAvecValeur === true && configImpression.switch.essaisSansValeur === true) {
									if (essaiRef.id === essaiPlv.id) {
										const essPrincipal = essaiPlv;
										if (this.verificationEssaiDansToPrint(tmpEssaisToPrint, essPrincipal)) {
											tmpEssaisToPrint.push(essPrincipal);
										} else {
											const tmpEssaisTabFiltred = tmpEssaisToPrint.filter((x) => x.id !== essPrincipal.id);
											tmpEssaisTabFiltred.push(essPrincipal);
											tmpEssaisToPrint = tmpEssaisTabFiltred;
										}
									} else {
										if (this.verificationEssaiDansToPrint(tmpEssaisToPrint, essaiRef)) {
											tmpEssaisToPrint.push(essaiRef);
										}
									}
								}
							}
						}
					}
				}
			}
			if (tmpEssaisToPrint && tmpEssaisToPrint.length > 0) {
				for (let j = 0; j < tmpEssaisToPrint.length; j++) {
					const essais = tmpEssaisToPrint[j];
					const tmpSsEss = [];
					let princ = null;

					//sous essai
					if (essais.sousMesures) {
						princ = essais.sousMesures.find((x) => x.code === essais.code);

						for (let k = 0; k < essais.sousMesures.length; k++) {
							const ssEss = essais.sousMesures[k];
							// avec valeur
							if (configImpression.switch.sousEssaisAvecValeur === true) {
								if (ssEss.valeur && ssEss.valeur !== '' && ssEss.valeur !== '-') {
									if (this.verificationEssaiDansToPrint(tmpSsEss, ssEss)) {
										tmpSsEss.push(angular.copy(ssEss));
									}
								}
							}
							// Sans valeur
							if (configImpression.switch.sousEssaisSansValeur === true) {
								if (!ssEss.valeur || ssEss.valeur === '' || ssEss.valeur === '-' || ssEss.valeur === 0) {
									if (this.verificationEssaiDansToPrint(tmpSsEss, ssEss)) {
										tmpSsEss.push(angular.copy(ssEss));
									}
								}
							}
						}
					}

					// sous essais iter

					if (essais.sousMesuresIter && essais.sousMesuresIter.length > 0) {
						const first = essais.sousMesuresIter[0];
						const sousEssaisIter = essais.sousMesuresIter[0].sousMes.map((x) => x.code);
						for (let k = 0; k < first.sousMes.length; k++) {
							const element = first.sousMes[k];
							let badColumn = false;
							if (configImpression.switch.sousEssaisAvecValeur && !configImpression.switch.sousEssaisSansValeur) {
								if (!element.valeur || element.valeur === '' || element.valeur === '-' || element.valeur === 0) {
									for (let l = 1; l < essais.sousMesuresIter.length; l++) {
										const child = essais.sousMesuresIter[l];
										if (child.sousMes[k].valeur && child.sousMes[k].valeur !== '' && child.sousMes[k].valeur !== '-') {
											break;
										} else if (l === essais.sousMesuresIter.length - 1) {
											badColumn = true;
										}
									}
								}
							} else if (configImpression.switch.sousEssaisSansValeur && !configImpression.switch.sousEssaisAvecValeur) {
								if (element.valeur && element.valeur !== '' && element.valeur !== '-') {
									for (let l = 1; l < essais.sousMesuresIter.length; l++) {
										const child = essais.sousMesuresIter[l];
										if (
											!child.sousMes[k].valeur ||
											child.sousMes[k].valeur === '' ||
											child.sousMes[k].valeur === '-' ||
											child.sousMes[k].valeur === 0
										) {
											break;
										} else if (l === essais.sousMesuresIter.length - 1) {
											badColumn = true;
										}
									}
								}
							}

							if (badColumn) {
								for (let m = 0; m < essais.sousMesuresIter.length; m++) {
									essais.sousMesuresIter[m].sousMes.splice(k, 1);
								}
							}
						}
						if (configImpression.switch.sousEssaisAvecValeur && !configImpression.switch.sousEssaisSansValeur) {
							essais.sousMesuresIter = this.removeEmptyCols(essais.sousMesuresIter, sousEssaisIter);
						}
					}

					if (princ && configImpression.switch.essaisAvecValeur === true) {
						if (princ.valeur || princ.valeur !== '' || princ.valeur !== '-') {
							if (this.verificationEssaiDansToPrint(tmpSsEss, princ)) {
								tmpSsEss.push(angular.copy(princ));
							}
						}
					}
					essais.sousMesures = tmpSsEss;
				}
			}

			if (tmpEssaisToPrint && tmpEssaisToPrint.length > 0) {
				// si essais choisis
				if (configImpression.switch.essaisChoisi === true && configImpression.listEssais) {
					const tmpSelected = [];

					for (let e = 0; e < tmpEssaisToPrint.length; e++) {
						const selectedEss = tmpEssaisToPrint[e];
						const idxSelected = configImpression.listEssais.findIndex((x) => x.selected === true && x.id === selectedEss.id);
						if (idxSelected > -1) {
							tmpSelected.push(selectedEss);
						}
					}
					if (tmpSelected && tmpSelected.length > 0) {
						tmpEssaisToPrint = tmpSelected;
					}
				}
				essais = tmpEssaisToPrint;
			} else {
				essais = [];
			}

			// code et libellé essais elt
			if (tmpEssaisToPrint && tmpEssaisToPrint.length > 0) {
				for (let e = 0; e < tmpEssaisToPrint.length; e++) {
					const essaisAModifier = tmpEssaisToPrint[e];
					for (let f = 0; f < essaisAModifier.sousMesures.length; f++) {
						const sousEssaisAModifier = essaisAModifier.sousMesures[f];
						// liste élements
						if (sousEssaisAModifier && sousEssaisAModifier.idNature === 5) {
							if (configImpression.switch.essaisCode && configImpression.switch.essaisLibelle) {
								sousEssaisAModifier.valeur = '[' + sousEssaisAModifier.value + '] ' + sousEssaisAModifier.valeur;
							} else if (configImpression.switch.essaisCode && !configImpression.switch.essaisLibelle) {
								sousEssaisAModifier.valeur = '[' + sousEssaisAModifier.value + '] ';
							} else if (!configImpression.switch.essaisCode && !configImpression.switch.essaisLibelle) {
								sousEssaisAModifier.valeur = this.$translate.instant('PRELEVEMENTS.IMPRESSION.DELETEBYUSER');
							}
						}
						if (sousEssaisAModifier && sousEssaisAModifier.idNature === 63) {
							sousEssaisAModifier.valeur = '';
							const selected = sousEssaisAModifier.content.filter((x) => x.selected);
							if (configImpression.switch.essaisCode && configImpression.switch.essaisLibelle) {
								for (let i = 0; i < selected.length; i++) {
									const element = selected[i];
									if (i > 0) {
										sousEssaisAModifier.valeur += '\n\r';
									}
									sousEssaisAModifier.valeur += '[' + element.key + '] ' + element.value;
								}
							} else if (configImpression.switch.essaisCode && !configImpression.switch.essaisLibelle) {
								for (let i = 0; i < selected.length; i++) {
									if (i > 0) {
										sousEssaisAModifier.valeur += '\n\r';
									}
									const element = selected[i];
									sousEssaisAModifier.valeur += '[' + element.key + '] ';
								}
							} else if (!configImpression.switch.essaisCode && configImpression.switch.essaisLibelle) {
								for (let i = 0; i < selected.length; i++) {
									if (i > 0) {
										sousEssaisAModifier.valeur += '\n\r';
									}
									const element = selected[i];
									sousEssaisAModifier.valeur += element.value;
								}
							} else if (!configImpression.switch.essaisCode && !configImpression.switch.essaisLibelle) {
								sousEssaisAModifier.valeur = this.$translate.instant('PRELEVEMENTS.IMPRESSION.DELETEBYUSER');
							}
							if (selected.length > 1) {
								sousEssaisAModifier.valeur += '\n\r';
							}
						}
					}

					/* if (essaisAModifier) {
                        if (configImpression.switch.essaisCode && !configImpression.switch.essaisLibelle) {
                            essaisAModifier.libelle = '[' + essaisAModifier.code + ']'
                        } else if (configImpression.switch.essaisCode && configImpression.switch.essaisLibelle) {
                            essaisAModifier.libelle = '[' + essaisAModifier.code + '] ' + essaisAModifier.libelle
                        } else if (!configImpression.switch.essaisCode && !configImpression.switch.essaisLibelle) {
                            essaisAModifier.libelle = this.$translate.instant('PRELEVEMENTS.IMPRESSION.DELETEBYUSER');
                        }
                    } */
				}
				essais = tmpEssaisToPrint;
			}

			let tmpCarac = [];
			for (let c = 0; c < caracteristiques.length; c++) {
				const carac = caracteristiques[c];
				if (this.saisie.niveauVisibilite >= carac.niveauVisibilite) {
					// carac avec valeur
					if (configImpression.switch.caracteristiqueAvecValeur === true) {
						if (carac.valeurCaracteristiques && carac.valeurCaracteristiques.length > 0) {
							if (this.verificationCaracteristiqueDansToPrint(tmpCarac, carac)) {
								tmpCarac.push(angular.copy(carac));
							}
						}
					}
					// carac avec valeur uniquement
					if (configImpression.switch.caracteristiqueSansValeur === true) {
						if (carac.valeurCaracteristiques && carac.valeurCaracteristiques.length === 0) {
							if (this.verificationCaracteristiqueDansToPrint(tmpCarac, carac)) {
								tmpCarac.push(angular.copy(carac));
							}
						}
					}
				}
			}

			if (tmpCarac && tmpCarac.length > 0) {
				// si caracteristique choisis
				if (configImpression.switch.caracteristiqueChoisi === true && configImpression.listCarac) {
					const tmpCaracSelected = [];

					for (let e = 0; e < tmpCarac.length; e++) {
						const selectedCarac = tmpCarac[e];
						const idxCaracSelected = configImpression.listCarac.findIndex((x) => x.selected === true && x.code === selectedCarac.code);
						if (idxCaracSelected > -1) {
							tmpCaracSelected.push(selectedCarac);
						}
					}
					if (tmpCaracSelected && tmpCaracSelected.length > 0) {
						tmpCarac = tmpCaracSelected;
					}
				}
				caracteristiques = tmpCarac;
			} else {
				caracteristiques = [];
			}

			// code et libellé carac elt
			if (tmpCarac && tmpCarac.length > 0 && configImpression.listCarac && configImpression.listCarac.length > 0) {
				for (let g = 0; g < configImpression.listCarac.length; g++) {
					const carac = configImpression.listCarac[g];

					for (let h = 0; h < tmpCarac.length; h++) {
						const caracAModifier = tmpCarac[h];
						const tmpCarValue = [];
						if (carac.id === caracAModifier.id) {
							if (caracAModifier.valeurCaracteristiques && caracAModifier.valeurCaracteristiques.length > 0) {
								for (let h = 0; h < caracAModifier.valeurCaracteristiques.length; h++) {
									const val = caracAModifier.valeurCaracteristiques[h];
									let value = '';
									if (caracAModifier.nature.idNature === 5) {
										const tmpVal = [];
										if (configImpression.switch.caracteristiqueCode) {
											tmpVal.push(`[${val.valeur}]`);
										}
										if (configImpression.switch.caracteristiqueLibelle) {
											tmpVal.push(`${val.libelle}`);
										}
										value = tmpVal.join(' ');
										if (!configImpression.switch.caracteristiqueCode && !configImpression.switch.caracteristiqueLibelle) {
											value = this.$translate.instant('PRELEVEMENTS.IMPRESSION.DELETEBYUSER');
										}
									} else {
										value = val.valeur;
									}
									tmpCarValue.push(value);
								}
							}
							caracAModifier.valeur = tmpCarValue.join(', ');
						}
					}
				}
				caracteristiques = tmpCarac;
			}
		}
		essais = this.getElementListeValuesGeneriques(essais, configImpression);
		//permet l'impression du prélèvement comme dans wil !!
		essais = this.specificFormat(essais);
		return {
			essais,
			caracteristiques
		};
	}

	async export() {
		try {
			let toPrint = this.formatForPrint();
			let caracteristiques = []; //this.getCaracteristiques(this.saisie.caracteristiquesDtos) || [];
			let arrayGranulo = await this.getTamisValeur();
			let granulo = { tamis: null, essaiGranulo: null };
			if (arrayGranulo && arrayGranulo.length > 0) {
				granulo = arrayGranulo[0];
			}
			let tamisToPrint = granulo.tamis;
			const essaiGranulo = granulo.essai;

			if (this.saisie.famille.code === 'BPE' || this.saisie.famille.code === 'ENROB') {
				this.formule = await this.FormuleService.getFormuleByDate(this.saisie.produit.id, this.saisie.producteur.id, this.saisie.date);
			}
			const id = this.id ? this.id : this.$stateParams.id;
			const domain = 'prelevements';
			//nom du fichier par defaut pour le partage avec le portail
			let customFilename = null;
			let portail = false;
			let nameAuto = false;
			if (this.portailAccess) {
				const configAutoPortail = (await this.SettingPortailService.getDocSetting('PREL')).data;
				if (configAutoPortail) {
					portail = configAutoPortail.envoiAuto;
					nameAuto = configAutoPortail.filenameAuto;
					if (configAutoPortail.filenameAuto) {
						customFilename = (await this.SettingPortailService.getAutoFilename(id, 'PREL', configAutoPortail.format)).data;
					}
				}
			}
			this.resolver = {
				saisie: () => this.saisie,
				customFilename: () => customFilename,
				portail: () => portail,
				nameAuto: () => nameAuto
			};
			const res = await this.TemplateImpressionService.downloadModal(
				domain,
				true,
				true,
				'<prelevement-config-impression saisie="$ctrl.saisie"><prelevement-config-impression/>',
				this.resolver
			);
			if (res === 'cancel') {
				throw res;
			}
			let canvas = document.getElementById('granulograph');
			if (canvas) {
				canvas = canvas.toDataURL('image/png', 1.0);
			} else {
				canvas = '';
			}
			let certifProduit = null;
			const listEssaisRef = angular.copy(res.saisie.configImpression.listEssais);
			if (res.saisie.configImpression) {
				if (res.saisie.configImpression.switch.essaisRef) {
					res.saisie.configImpression.listEssais = this.formatForPrint(res.saisie.configImpression.listEssais);
				}
				const tres = this.formatToPrintAfterConfig(toPrint, this.saisie.caracteristiqueDtos, res.saisie.configImpression);
				if (essaiGranulo) {
					essaiGranulo.sousEssais = this.formatGranuloAfterConfig(
						essaiGranulo,
						res.saisie.configImpression.switch?.sousEssaisAvecValeur,
						res.saisie.configImpression.switch?.sousEssaisSansValeur
					);
				}
				toPrint = this.setDatePlanification(tres.essais);
				caracteristiques = tres.caracteristiques;
				// essaiGranulo = formatGranulo(essaiGranulo);
				if (res.saisie.configImpression.produit) {
					certifProduit = res.saisie.configImpression.produit;
				}
				if (res.saisie.configImpression.essai) {
					toPrint = await this.formatEssaiToDisplayCertif(toPrint, essaiGranulo, res.saisie.configImpression.essai);
				}
				if (res.saisie.configImpression.switch.essaisRef && res.saisie.configImpression.listEssais) {
					const arrayGranulo = await this.getTamisValeur(listEssaisRef);
					let granulo = { tamis: null, essaiGranulo: null };
					if (arrayGranulo && arrayGranulo.length > 0) {
						granulo = arrayGranulo[0];
					}
					// granulo = await this.getTamisValeur(listEssaisRef);
					tamisToPrint = granulo.tamis;
					// On trie les essais dans l'ordre de la trame (ils sont dans l'ordre dans le retour du back)
					const lstIdsEssais = angular.copy(res.saisie.configImpression.listEssais).map((x) => x.id);
					const order = {};
					lstIdsEssais.forEach(function (a, i) {
						order[a] = i;
					});
					toPrint = toPrint.sort(function (a, b) {
						return order[a.id] - order[b.id];
					});
				}
			}
			arrayGranulo = this.getGraphesGranulo(arrayGranulo);
			const template = await this.PrelevementsService.print(id, res.filename, {
				canvas: canvas,
				tamis: tamisToPrint,
				granulo: essaiGranulo,
				mesures: toPrint,
				caracteristiques: caracteristiques,
				certifProduit: certifProduit,
				configCertifEssai: res.saisie.configImpression.essai,
				formule: this.formule,
				granulos: arrayGranulo
			});
			template.pdf = res.pdf;
			this.notification.info(this.$translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
				autoDismiss: false
			});
			await this.TemplateImpressionService.downloadTemplate(template.filename, template, domain);
			if (res.portail) {
				if (this.saisie.client && res.clients?.length > 0) {
					if (res.clients.findIndex((x) => x == this.saisie.client.id) == -1) {
						res.clients.push(this.saisie.client.id);
					}
				}
				const model = {
					filename: res.newFilename,
					original: template.filename,
					dateGeneration: new Date(),
					date: this.saisie.heure,
					domain: 'PREL',
					laboratoires: [this.saisie.auditeur.id],
					producteurs: [this.saisie.producteur.id],
					clients: res.clients,
					produits: [this.saisie.produit.id],
					norme: null,
					template: template.id,
					prelevement: id
				};
				await this.SharedFileService.create(model);
			}
		} catch (err) {
			console.error(err);
			this.notification.error(err.data);
		}
	}

	formatForPrint(listEssaisRef = null) {
		const mesures = [];
		let VMes = null;
		if (listEssaisRef != null) {
			VMes = listEssaisRef;
		} else {
			VMes = angular.copy(this.saisie.mesures);
		}
		for (let i = 0; i < VMes.length; i++) {
			const m = VMes[i];
			if (Object.values(m.sousEssais).some((e) => e.granuloAdditionalInformation && e.granuloAdditionalInformation.tamis.length > 0)) {
				continue;
			}
			for (const j in m.sousEssais) {
				const ssMes = m.sousEssais[j];

				if (ssMes.hasOwnProperty('id')) {
					ssMes.label = ssMes.libelle;

					if (m.niveauVisibiliteImpression >= ssMes.niveauVisibilite) {
						if (!m.sousMesures) {
							m.sousMesures = [];
						}
						if (!m.sousMesuresIter) {
							m.sousMesuresIter = [];
						}

						if (!ssMes.granuloAdditionalInformation || ssMes.granuloAdditionalInformation.tamis.length <= 0) {
							var valeur = null;
							if (
								!ssMes.generiqueAdditionalInformation ||
								(ssMes.generiqueAdditionalInformation && !ssMes.generiqueAdditionalInformation.isIteratif && ssMes.idNature !== 10)
							) {
								valeur = ssMes.value || ssMes.value == 0 ? ssMes.value : '-';
								if (ssMes.nature === 'Date') {
									const d = this.moment(valeur, this.dateFormat);
									if (d.isValid()) {
										valeur = d.format(this.dateFormat);
									} else {
										valeur = '-';
									}
								}
								if (ssMes.nature === 'ElementListe') {
									const tmp = ssMes.listeAdditionalInformation.elements.find((x) => x.key === valeur);
									valeur = tmp ? tmp.value : '';
								} else if (
									ssMes.numericAdditionalInformation &&
									ssMes.nature !== 'Date' &&
									ssMes.nature !== 'TexteCourt' &&
									ssMes.nature !== 'TexteLong'
								) {
									ssMes.unite = ssMes.numericAdditionalInformation.unite;
									if (ssMes.numericAdditionalInformation.nombreDeDecimales > -1 && !Number.isNaN(Number.parseFloat(valeur))) {
										const objetToRound = [
											{ key: valeur, val: valeur, rounder: ssMes.numericAdditionalInformation.nombreDeDecimales }
										];
										valeur = this.Interpreteur.getRoundedValue(objetToRound);
									}
								}
								ssMes.seuilBorne = null;
								ssMes.seuilAlerte = null;
								ssMes.seuilTolerance = null;
								if (ssMes.bornes) {
									const borne = ssMes.bornes;
									ssMes.incertitude = borne.incertitude;
									if (
										(borne.alerteInferieur !== null &&
											borne.alerteInferieur !== undefined &&
											typeof borne.alerteInferieur === 'number') ||
										(borne.alerteSuperieure !== null &&
											borne.alerteSuperieure !== undefined &&
											typeof borne.alerteSuperieure === 'number')
									) {
										ssMes.alerteInferieure = {
											value:
												borne.alerteInferieur !== null && borne.alerteInferieur !== undefined
													? borne.alerteInferieur + ''
													: '-',
											text: '000000'
										};
										ssMes.alerteSuperieure = {
											value:
												borne.alerteSuperieure !== null && borne.alerteSuperieure !== undefined
													? '' + borne.alerteSuperieure
													: '-',
											text: '000000'
										};
									}
									if (
										(borne.borneInferieure !== null &&
											borne.borneInferieure !== undefined &&
											typeof borne.borneInferieure === 'number') ||
										(borne.borneSuperieure !== null &&
											borne.borneSuperieure !== undefined &&
											typeof borne.borneSuperieure === 'number')
									) {
										ssMes.borneInferieure = {
											value:
												borne.borneInferieure !== null && borne.borneInferieure !== undefined
													? borne.borneInferieure + ''
													: '-',
											text: '000000'
										};
										ssMes.borneSuperieure = {
											value:
												borne.borneSuperieure !== null && borne.borneSuperieure !== undefined
													? '' + borne.borneSuperieure
													: '-',
											text: '000000'
										};
									}
									if (
										(borne.toleranceInferieure !== null &&
											borne.toleranceInferieure !== undefined &&
											typeof borne.toleranceInferieure === 'number') ||
										(borne.toleranceSuperieure !== null &&
											borne.toleranceSuperieure !== undefined &&
											typeof borne.toleranceSuperieure === 'number')
									) {
										ssMes.toleranceInferieure = {
											value:
												borne.toleranceInferieure !== null && borne.toleranceInferieure !== undefined
													? borne.toleranceInferieure + ''
													: '-',
											text: '000000'
										};
										ssMes.toleranceSuperieure = {
											value:
												borne.toleranceSuperieure !== null && borne.toleranceSuperieure !== undefined
													? '' + borne.toleranceSuperieure
													: '-',
											text: '000000'
										};
									}
								}
								if (ssMes.conformite > -1) {
									if (ssMes.conformite === 0) {
										valeur = {
											value: valeur,
											text: '009900'
										};
									} else if (ssMes.conformite === 1) {
										valeur = {
											value: valeur,
											text: 'FFAA00'
										};
									} else if (ssMes.conformite === 2) {
										valeur = {
											value: valeur,
											text: 'FF0000'
										};
									} else if (ssMes.conformite === 3) {
										valeur = {
											value: valeur,
											text: '0000FF'
										};
									}
									const va = angular.copy(valeur);
									va.value = '';
									va.color = va.text;
									va.text = null;
									if (!m.conformite) {
										m.conformite = va;
									} else if (m.conformite < ssMes.conformite) {
										m.conformite = va;
									}
									if (m.conformite && ssMes.conformite === 2 && m.conformite !== 2) {
										m.conformite = va;
									}
								}
								m.sousMesures.push({
									...ssMes,
									valeur: valeur
								});
							} else {
								const iter = ssMes.generiqueAdditionalInformation.nbIteration || 1;
								const ssIter = ssMes.generiqueAdditionalInformation.essaiLie.sousEssais;

								for (let k = 0; k < iter; k++) {
									const ssLib = [];
									//HOTFIX 2199 → IMPRESSION. Ne pas imprimer le n° de ligne des itérations (CALCYS)
									// var libelleIter = Object.values(ssIter).find(x => x.nature === 'EssaiGenerique').libelle;
									// var codeIter = Object.values(ssIter).find(x => x.nature === 'EssaiGenerique').code;

									// ssLib.push({
									//     idGenerique: ssMes.id,
									//     libelle: libelleIter,
									//     label: libelleIter,
									//     code: codeIter,
									//     valeur: iter === 1 ? '' : k + 1
									// });
									let i = 0;
									for (const f in ssIter) {
										const ss = ssIter[f];
										if (ss.hasOwnProperty('id')) {
											const unite = ss.numericAdditionalInformation ? ` ${ss.numericAdditionalInformation.unite}` : '';
											if (m.niveauVisibiliteImpression >= ss.niveauVisibilite) {
												if (ss.nature !== 'EssaiGenerique') {
													let v = ss.value;
													if (v && v[k]) {
														if (
															ss.numericAdditionalInformation &&
															ss.numericAdditionalInformation.nombreDeDecimales > -1 &&
															!Number.isNaN(Number.parseFloat(v[k]))
														) {
															const objetToRound = [
																{ key: v[k], val: v[k], rounder: ss.numericAdditionalInformation.nombreDeDecimales }
															];
															v[k] = this.Interpreteur.getRoundedValue(objetToRound);
														}
														v = v[k] + unite;
													} else {
														v = ' ';
													}
													// ajout de la conformité pour les essais génériques
													if (ss.conformite && ss.conformite[k]) {
														v = this.getConformite(ss.conformite[k].value, v);
													}
													ssLib.push({
														idGenerique: ssMes.id,
														libelle: ss.libelle,
														label: ss.libelle,
														code: ss.code,
														valeur: v
													});
												} else {
												}
											}
										}
										i++;
									}
									m.sousMesuresIter.push({
										sousMes: ssLib
									});
								}
							}
						}
					}
				}
			}
			mesures.push(m);
		}
		return mesures;
	}

	getConformite(conformite, valeur) {
		let v = null;
		if (conformite === 0) {
			return (v = {
				value: valeur,
				text: '009900'
			});
		} else if (conformite === 1) {
			return (v = {
				value: valeur,
				text: 'FFAA00'
			});
		} else if (conformite === 2) {
			return (v = {
				value: valeur,
				text: 'FF0000'
			});
		} else if (conformite === 3) {
			return (v = {
				value: valeur,
				text: '0000FF'
			});
		} else if (conformite === -1) {
			return (v = {
				value: valeur,
				text: '000000'
			});
		}
	}
	getCaracteristiques(caracteristiques) {
		if (caracteristiques && caracteristiques.data) {
			const res = [];
			for (const id in caracteristiques.data) {
				if (id !== 'undefined' && typeof id !== 'undefined' && caracteristiques.data.hasOwnProperty(id)) {
					const caract = caracteristiques.data[id].caracteristique;
					if (caract && this.saisie.niveauVisibilite >= caract.niveauVisibilite) {
						res.push({
							code: caract.code,
							libelle: caract.label,
							valeur: this.getCaractValue(caract.numericAdditionalInformation || null, caracteristiques.data[id])
						});
					}
				}
			}
			return res;
		}

		return null;
	}

	getCaractValue(info, obj) {
		var val = obj.value;
		if (info) {
			var val = Number.parseFloat(obj.value);
			if (!Number.isNaN(val)) {
				if (typeof info.nombreDeDecimales === 'number' && typeof info.nombreDeDecimales !== 'undefined' && info.nombreDeDecimales !== null) {
					const objetToRound = [{ key: val, val: val, rounder: info.nombreDeDecimales }];
					val = this.Interpreteur.getRoundedValue(objetToRound);
				}
				if (val && info.unite) {
					val = `${val} ${info.unite}`;
				}

				return val;
			}
		}
		if (obj.caracteristique && obj.caracteristique.content) {
			const res = obj.caracteristique.content.find((x) => x.key === obj.value);
			if (res) {
				val = res.value;
			}
		}

		return val || '';
	}

	async formatEssaiToDisplayCertif(essais, essaiGranulo, certifConfig) {
		if (certifConfig.icone !== null) {
			const iconeBase64 = await this.PrelevementsConfigImpressionService.getIconeBase64(certifConfig.icone);

			for (let i = 0; i < essais.length; i++) {
				if (essais[i].certifie) {
					essais[i].iconeCertificat = iconeBase64;
				}
			}
			if (essaiGranulo) {
				essaiGranulo.iconeCertificat = iconeBase64;
			}
		}

		if (!certifConfig.logo) {
			for (let i = 0; i < essais.length; i++) {
				essais[i].logoCertificat = null;
			}
			essais.forEach((essai) => {
				essai.logoCertificat = null;
			});
			if (essaiGranulo) {
				essaiGranulo.logoCertificat = null;
			}
		}
		return essais;
	}

	editCloture(ev, saisie) {
		if (saisie) {
			return (saisie.cloture = !saisie.cloture);
		}
	}

	async cloturerPlv(ev, saisie) {
		try {
			if (ev) {
				ev.stopPropagation();
				ev.stopImmediatePropagation();
				ev.preventDefault();
			}
			if (saisie && saisie.id) {
				if (!saisie.cloture) {
					const modalInstance = this.ModalService.confirm({
						modalTitle: this.$translate.instant('PRELEVEMENTS.CLOTURE_PRELEVEMENT', { libelle: saisie.libelle }),
						modalMsg: this.$translate.instant('PRELEVEMENTS.CLOTURERPLV'),
						headerClass: 'modal-warning'
					});

					modalInstance.result.then(async () => {
						await this.PrelevementsService.updateSaisiesCloture([saisie.id], []);
						this.saisie.cloture = !saisie.cloture;
						this.notification.success(this.$translate.instant('PRELEVEMENTS.CLOTURE_SUCCESS'));
					});
				} else {
					await this.PrelevementsService.updateSaisiesCloture([], [saisie.id]);
					this.saisie.cloture = !saisie.cloture;
					this.notification.success(this.$translate.instant('PRELEVEMENTS.OUVERTURE_SUCCESS'));
				}
			}
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async calculNorme() {
		const lstAllFamilles = ['CIM', 'ADD', 'ADJ', 'GRAN', 'EAU'];
		const lstLightComposants = [];
		this.formule.composants
			.filter((e) => lstAllFamilles.indexOf(e.codeFamille) >= 0)
			.forEach((composant) => {
				const lightCompo = lstLightComposants.find((c) => c.idProduit === composant.idElement && c.idProducteur === composant.idProducteur);
				if (!lightCompo) {
					lstLightComposants.push({ idProduit: composant.idElement, idProducteur: composant.idProducteur });
				}
			});

		//const lstLightComposants = this.formule.composants.filter(e => e.codeFamille === 'CIM').map(e => { return { idProduit: e.idElement, idProducteur: e.idProducteur }; });
		const lstAllValeursRef = await this.FormuleService.getValeursReference(
			[...new Set(lstLightComposants.map((e) => e.idProduit))],
			[...new Set(lstLightComposants.map((e) => e.idProducteur))],
			[
				'EssCV28Cim', //ciment
				'EssMV', //ciment, addition, adjuvant, eau, granulat
				'EssTESAdj', //adjuvant
				'EssKbGran', //granulat
				'EssAbGran', //granulat
				'EssWGran' //granulat
			]
		);

		lstAllValeursRef.forEach(function (valeurRef) {
			valeurRef.valeursCaracteristiquesProduit.forEach(function (caract) {
				caract.value = caract.elementsValues || caract.elementValue || caract.values || caract.value;
				delete caract.elementsValues;
				delete caract.elementValue;
				delete caract.values;
				delete caract.value;
			});
		});

		this.FormuleCalculsService.init(this.formule, this.parametresFormulation);

		this.formule.produits = await this.FormuleCalculsService.getProduitFormule();
		this.formule.composantCiments = await this.FormuleCalculsService.recuperationCiment(lstAllValeursRef);
		this.formule.composantAdditions = await this.FormuleCalculsService.recuperationAddition(lstAllValeursRef);
		this.formule.composantAdjuvants = await this.FormuleCalculsService.recuperationAdjuvant(lstAllValeursRef);
		this.formule.composantGranulats = await this.FormuleCalculsService.recuperationGranulat(lstAllValeursRef);
		this.formule.composantEaux = await this.FormuleCalculsService.recuperationEau(lstAllValeursRef);

		/**
		 * recuperation classe expo et classe de resistance
		 */

		if (this.formule.produits && this.listCc && this.listCc.length === 0) {
			// s'il n'y a pas de concrete code on prend les classes de res et d'expo du produit
			if (this.formule.produits.produitCaracteristique) {
				this.formule.classeExpo = await this.FormuleCalculsService.recuperationClasseExpo(this.formule.produits.produitCaracteristique);
			}
			if (this.formule.produits.produitCaracteristique) {
				this.formule.classeResistance = await this.FormuleCalculsService.recuperationClasseResistance(
					this.formule.produits.produitCaracteristique
				);
			}
		} else if (this.formule.produits && this.listCc && this.listCc.length > 0) {
			// s'il y a des concrete code on prend les classes de res et d'excpo du produitCc le plus contraignant
			this.formule.produitCommerciaux = await this.FormuleCalculsService.getProduitCommerciaux();

			if (!this.formule.classeExpo && this.formule.produits) {
				this.formule.classeExpo = await this.FormuleCalculsService.recuperationConcreteCodeExpo();
			}

			if (!this.formule.classeResistance && this.formule.produits) {
				this.formule.classeResistance = await this.FormuleCalculsService.recuperationConcreteCodeResistance();
			}

			if (!this.formule.dosageCiment && this.formule.produits) {
				this.formule.dosageCiment = await this.FormuleCalculsService.recuperationConcreteCodeCiment();
			}
		}

		this.formule.vdc = await this.FormuleCalculsService.getValeurDeControle(false);

		await this.FormuleCalculsService.assignationPourAffichage();
		await this.FormuleCalculsService.initCalcul();

		this.FormuleCalculsService.resetCache();
		await this.checkConformiterAlerte();
	}

	checkConformiterAlerte() {
		if (this.formule.idNorme && this.formule.vdc && this.formule.vdc.length > 0) {
			for (let i = 0; i < this.formule.vdc.length; i++) {
				const vdc = this.formule.vdc[i];
				if (Number.parseFloat(vdc.calcul) && Number.parseFloat(vdc.seuil)) {
					if (vdc.comparateur === ' >= ') {
						vdc.conformite = null;
						if (Number.parseFloat(vdc.calcul) >= Number.parseFloat(vdc.seuil)) {
							vdc.conformite = true;
						} else {
							vdc.conformite = false;
						}
					} else if (vdc.comparateur === ' <= ') {
						if (Number.parseFloat(vdc.calcul) <= Number.parseFloat(vdc.seuil)) {
							vdc.conformite = true;
						} else {
							vdc.conformite = false;
						}
					}
				}
			}
			return this.formule;
		}
	}

	setDatePlanification(liste) {
		for (let i = 0; i < liste.length; i++) {
			liste[i].datesPlanification = '';
			if (liste[i].planification && liste[i].planification !== '') {
				const prevu = liste[i].datePrevue != null ? liste[i].datePrevue : ' ';
				const reelle = liste[i].dateReelle != null ? liste[i].dateReelle : ' ';
				liste[i].datesPlanification = this.$translate.instant('PRELEVEMENTS.PREVU_REALISE', { prevu: prevu, reel: reelle });
			}
		}
		return liste;
	}

	getElementListeValuesGeneriques(essais, configImpression) {
		for (let i = 0; i < essais.length; i++) {
			const essai = essais[i];
			if (essai.sousMesuresIter && essai.sousMesuresIter.length > 0) {
				const generique = essai.sousEssais[`_${essai.sousMesuresIter[0].sousMes[0].idGenerique}`];
				const listeSousEssais = Object.values(generique.generiqueAdditionalInformation.essaiLie.sousEssais);

				for (let j = 0; j < essai.sousMesuresIter.length; j++) {
					const iteration = essai.sousMesuresIter[j].sousMes;

					for (let k = 0; k < iteration.length; k++) {
						const sousEssai = iteration[k];

						if (sousEssai.valeur && sousEssai.valeur !== '' && sousEssai.valeur !== '-') {
							const ssEssaiComplet = listeSousEssais.find((x) => x.code === sousEssai.code);
							if (ssEssaiComplet.idNature === 5) {
								let libelle = '';
								const code = sousEssai.valeur;

								if (configImpression.switch.essaisLibelle) {
									libelle = ssEssaiComplet.listeAdditionalInformation.elements.find((x) => x.key == sousEssai.valeur)?.value || '';
								}
								if (configImpression.switch.essaisLibelle && configImpression.switch.essaisCode) {
									essais[i].sousMesuresIter[j].sousMes[k].valeur = `[${code}] ${libelle}`;
								} else if (configImpression.switch.essaisLibelle && !configImpression.switch.essaisCode) {
									essais[i].sousMesuresIter[j].sousMes[k].valeur = libelle;
								}
							}
						}
					}
				}
			}
		}
		return essais;
	}

	specificFormat(essais) {
		const configImpression = this.saisie.configImpression;
		if (
			configImpression.switch?.essaiPrincipal ||
			(!configImpression.switch?.sousEssaisAvecValeur && !configImpression.switch?.sousEssaisSansValeur)
		) {
			for (let i = 0; i < essais.length; i++) {
				const principalIndex = essais[i].sousMesures.findIndex((x) => x.code === essais[i].code);
				if (!essais.principal) {
					essais[i].principal = angular.copy(essais[i].sousMesures[principalIndex]);
					essais[i].principal.norme = essais[i].norme;
				}

				if (configImpression.switch?.essaiPrincipal) {
					if (principalIndex >= 0) {
						essais[i].sousMesures.splice(principalIndex, 1);
					}
				}

				if (!configImpression.switch?.sousEssaisAvecValeur && !configImpression.switch?.sousEssaisSansValeur) {
					if (!configImpression.switch.sousEssaisAvecValeur && !configImpression.switch.sousEssaisSansValeur) {
						essais[i].sousMesures = [];
					}
				}
			}
		}
		return essais;
	}

	removeEmptyCols(sousMesuresIter, sousEssaisIter) {
		const copy = angular.copy(sousMesuresIter);
		let iter = 1;
		for (let i = 0; i < sousEssaisIter.length; i++) {
			const code = sousEssaisIter[i];
			const values = sousMesuresIter.map((x) => {
				const val = x.sousMes.find((ssE) => ssE.code === code)?.valeur;
				return val;
			});

			const toRemove = values.every((val) => {
				return !val || val === '' || val === ' ' || val === '-' || val === 0 || val === '[]';
			});

			if (toRemove) {
				//si une seule eprouvette affiche tous les sous essais iter (même vides)
				if (sousMesuresIter.length == 1 && i + 1 == sousEssaisIter.length && iter == sousEssaisIter.length) {
					return copy;
				}
				const idx = sousMesuresIter[0].sousMes.findIndex((x) => x.code == code);
				for (let j = 0; j < sousMesuresIter.length; j++) {
					sousMesuresIter[j].sousMes.splice(idx, 1);
				}
				iter++;
			}
		}
		return sousMesuresIter;
	}

	formatGranuloAfterConfig(essaiGranulo, avecValeur, sansValeur) {
		const sousEssais = essaiGranulo.sousEssais;
		const nivVis = essaiGranulo.niveauVisibiliteImpression;
		let tmp = [];
		if (avecValeur === true) {
			const array = sousEssais.filter((ssEss) => {
				return (
					((ssEss.valeur && ssEss.valeur !== '' && ssEss.valeur !== '-' && ssEss.valeur !== ' ') || ssEss.valeur == 0) &&
					ssEss.niveauVisibilite <= nivVis
				);
			});
			if (array && array.length > 0) {
				array.forEach((sousEss) => {
					if (sousEss.conformite == 0 || sousEss.conformite != -1) {
						sousEss.valeur = this.getConformite(sousEss.conformite, sousEss.valeur);
					}
				});
				tmp = [...array];
			}
		}

		if (sansValeur === true) {
			const array = sousEssais.filter((ssEss) => {
				return (
					(ssEss.valeur == null || ssEss.valeur == undefined || ssEss.valeur == '' || ssEss.valeur == '-' || ssEss.valeur == ' ') &&
					ssEss.niveauVisibilite <= nivVis
				);
			});
			if (array && array.length > 0) {
				tmp = [...tmp, ...array];
			}
		}
		tmp.forEach((sousEssais) => {
			if (sousEssais.bornes) {
				sousEssais.borneInferieure = sousEssais.bornes.borneInferieure !== null ? sousEssais.bornes.borneInferieure : null;
				sousEssais.borneSuperieure = sousEssais.bornes.borneSuperieure !== null ? sousEssais.bornes.borneSuperieure : null;
			}
		});
		return tmp;
	}

	async selectCamsizerFile() {
		// console.log('🚀: ', this.saisie.mesures);

		//On vide l'element en cas de multi importation
		document.getElementById('file-upload').value = '';

		//Si pas d'essais enregistré, on propose dessuite l'importation
		//Sinon popup d'avertissement
		if (this.saisie.mesures.length === 0) {
			document.getElementById('file-upload').click();
		} else {
			const modal = this.$uibModal.open({
				template: `<div class="modal-body" id="modal-body">
                    <div>
                        <h4 class="modal-title" id="modal-title"><strong>
                        <span translate="{{'PRELEVEMENTS.IMPORT_CAMSIZER.MODAL_ALERT_TITLE'}}"></span>
                        </strong></h4>
                        <div class="alert alert-danger" style="padding:10px; margin:10px 0;">
                            <div class="row">
                                <div class="col-md-10">
                                    <span translate="{{'PRELEVEMENTS.IMPORT_CAMSIZER.MODAL_ALERT_BODY'}}"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary" type="button" ng-click="$ctrl.valider()">
                            <span class="massia-icon-door_exit"></span>
                            <span class="hidden-xs" translate="VALIDER"></span>
                        </button>
                        <button class="btn btn-primary" type="button" ng-click="$ctrl.annuler()">
                        <span class="massia-icon-door_exit"></span>
                        <span class="hidden-xs" translate="ANNULER"></span>
                    </button>
                    </div>
                </div>`,
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						this.uibModalInstance = $uibModalInstance;
						this.valider = () => $uibModalInstance.close(true);
						this.annuler = () => $uibModalInstance.close(false);
					}
				],
				controllerAs: '$ctrl',
				size: 'xl'
			});
			const res = await modal.result;
			if (res) {
				document.getElementById('file-upload').click();
			}
		}
	}

	async importCamsizerFile(event) {
		this.startLoading();
		try {
			const formData = {
				data: {},
				file: {}
			};
			const element = event.target;
			const fileList = element.files;

			if (fileList) {
				formData.file = fileList[0];

				const file = this.getFileAndExtension(formData.file.name);

				formData.data.libelle = file[0];
				formData.data.extension = file[1];
			}

			const formatedCamsizerData = await this.PrelevementsService.getCamsizerFileUploadAndProcessing(formData);
			if (formatedCamsizerData) {
				//ajoute les essais au prelevement
				await this.getEssaisForImportCamsizer(formatedCamsizerData);

				await this.setEssaisWithCamsizerValues(formatedCamsizerData);

				await this.setSousEssaisTamisWithCamsizerValues(formatedCamsizerData);
			}
		} catch (err) {
			this.notification.error(this.$translate.instant(`PRELEVEMENTS.IMPORT_CAMSIZER.ERROR.${err.data}`));
		} finally {
			this.stopLoading();
		}
	}

	getFileAndExtension(file) {
		return file.split('.');
	}

	async getEssaisForImportCamsizer({ sousEssais, sousEssaisTamis }) {
		try {
			let essaisList = [];
			const essaisSelectedList = [];

			if (this.domaineMesure) {
				essaisList = await this.MesuresService.getMesuresList(this.domaineMesure, 0, this.saisie.type.id, this.saisie.produit.idType);
			}

			if (sousEssais.length > 0) {
				const camEssaiAllong = sousEssais.find((x) => x.libelle === 'Allongement');
				if (camEssaiAllong) {
					const essai = essaisList.find((x) => x.code === camEssaiAllong.code);
					if (typeof essai === 'undefined') {
						this.notification.error(this.$translate.instant('PRELEVEMENTS.IMPORT_CAMSIZER.ERROR.NO_ALLONG'));
					} else {
						essaisSelectedList.push(essai);
					}
				}

				const camEssaiFines = sousEssais.find((x) => x.libelle === 'Teneur en fines');
				if (camEssaiFines) {
					const essai = essaisList.find((x) => x.code === camEssaiFines.code);
					if (typeof essai === 'undefined') {
						this.notification.error(this.$translate.instant('PRELEVEMENTS.IMPORT_CAMSIZER.ERROR.NO_FINE'));
					} else {
						essaisSelectedList.push(essai);
					}
				}
			}

			if (sousEssaisTamis.length > 0) {
				const essai = essaisList.find((x) => x.hasGranulo);
				essaisSelectedList.push(essai);
			}

			this.saisie.mesures = await this.PrelevementsService.getMesuresComplet(
				this.saisie.id,
				essaisSelectedList.map((e) => e.id).concat(this.saisie.mesures.map((e) => e.id))
			);
			this.saisie = new Prelevement(this.saisie, this.moment, this.dateFormat);
		} catch (error) {
			console.log('🚀: PrelevementController -> getEssaisForImportCamsizer -> error', error);
		}
	}

	async setEssaisWithCamsizerValues({ sousEssais }) {
		_.forEach(sousEssais, (ssEssCamsizer) => {
			const essaisList = this.saisie.mesures.find((essai) => essai.code === ssEssCamsizer.code);

			_.forEach(essaisList.sousEssais, (ssEss) => {
				if (ssEss.code === ssEssCamsizer.code) {
					ssEss.value = ssEssCamsizer.valeur;
				}
			});
		});
	}

	async setSousEssaisTamisWithCamsizerValues({ sousEssaisTamis }) {
		let tamisList = null;
		//On recupère les tamis du sous-essai GRAN
		_.forEach(this.saisie.mesures, (saisieMesure) => {
			const essaiGranuloKey = _.findKey(saisieMesure.sousEssais, (x) => x.granuloAdditionalInformation !== null);
			if (essaiGranuloKey) {
				tamisList = saisieMesure.sousEssais[essaiGranuloKey].granuloAdditionalInformation.tamis;
				return false; // pour break le forEach de Lodash
			}
		});

		_.forEach(tamisList, (tamis) => {
			//on reset tous les tamis
			tamis.isDisplay = false;
			tamis.pourcPassant = null;

			// On set les valeurs importées
			if (sousEssaisTamis.some((x) => x.ouverture === tamis.ouverture)) {
				tamis.isDisplay = true;
				tamis.pourcPassant = sousEssaisTamis.find((x) => x.ouverture === tamis.ouverture).pourcentagePassant;
			}
		});
	}
	reloadCalcul() {
		this.blDataLoaded = true;
	}

	getGraphesGranulo(arrayGranulo) {
		if (arrayGranulo && arrayGranulo.length > 0) {
			for (let i = 0; i < arrayGranulo.length; i++) {
				let canvas;
				if (i === 0) {
					canvas = document.getElementById('granulograph');
				} else {
					canvas = document.getElementById(`granulograph_${i}`);
				}
				if (canvas) {
					canvas = canvas.toDataURL('image/png', 1.0);
				} else {
					canvas = '';
				}
				arrayGranulo[i].graph = canvas;
			}
		}
		return arrayGranulo;
	}
}
