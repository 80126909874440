(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.treeview')
        .directive('acTreeview', acTreeview);

    /* @ngInject */
    function acTreeview() {
        var directive = {
            restrict: 'A',
            scope: {
                acTreeview: '=',
                acTreeviewService: '='
            },
            controller: 'AcTreeviewController',
            controllerAs: 'tree',
            link: link,
            template: '<div ng-include="getTemplateUrl()"></div>'
        };

        return directive;
    }

    function link(scope, element, attrs) {
        var config = scope.acTreeview;
        var templateUrl = 'blocks/treeview/ac.treeview.root.tpl.html';

        if (config && config.templateUrl) {
            templateUrl = config.templateUrl;
        }

        scope.getTemplateUrl = function () {
            return templateUrl;
        };
    }
})(angular);