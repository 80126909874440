export default class MassiaClientService {
    constructor(localStorageService, AuthConstant) {
        this.localStorageService = localStorageService;
        this.AuthConstant = AuthConstant;
    }

    setClient(client) {
        if (client) {
            window.localStorage.setItem(this.AuthConstant.MASSIA_CLIENT_NAME, client);
        } else {
            client = window.localStorage.getItem(this.AuthConstant.MASSIA_CLIENT_NAME);
        }

        this.client = client;
    }

    getClient() {
        if (!this.client) {
            this.client = window.localStorage.getItem(this.AuthConstant.MASSIA_CLIENT_NAME);
        }

        return this.client;
    }
}

MassiaClientService.$inject = ['localStorageService', 'AuthConstant'];
