CaracteristiquesDetailController.$inject = ['TypesService', 'PaginationService', 'notification'];

export default function CaracteristiquesDetailController(TypesService, PaginationService, notification) {
    /* jshint validthis: true */
    const vm = this;
    let previousTableState;

    // Propriétés
    vm.loading = false;
    vm.displayCaracteristiques = [];

    //vm.caracteristiques = [];

    // Méthodes
    vm.loadCaracteristiques = loadCaracteristiques;

    // Evènements
    vm.$onInit = onInit;

    // Méthodes publiques
    function onInit() {}

    async function loadCaracteristiques(tableState) {
        startLoading();

        previousTableState = tableState || previousTableState;

        try {
            const data = await TypesService.getCaracteristiquesByType(
                vm.type.id,
                PaginationService.getFilters(previousTableState),
                PaginationService.getSorts(previousTableState),
                PaginationService.getPagination(previousTableState)
            );

            vm.caracteristiques = data.items;

            PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    // Méthodes privées
    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }
}
