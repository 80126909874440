import Prelevement from '../prelevement.model';

export default class PrelevementListeMesuresController {
	/* @ngInject */
	constructor(
		$rootScope,
		$scope,
		$state,
		$stateParams,
		PrelevementsService,
		ModalService,
		notification,
		$translate,
		_,
		$uibModal,
		$filter,
		DataShareService,
		SitesService,
		ProduitsService,
		moment,
		ReferencesControleService,
		globalizationManagementService,
		Interpreteur,
		MaterielService,
		RouterHistoryService,
		MassiaRightsService,
		EssaisMaterielService,
		PrelevEssaiMaterielsService,
		PropositionBestTrameRefService,
		FTPArchiveeService,
		$timeout
	) {
		this.$rootScope = $rootScope;
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.PrelevementsService = PrelevementsService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.$translate = $translate;
		this._ = _;
		this.$uibModal = $uibModal;
		this.$filter = $filter;
		this.DataShareService = DataShareService;
		this.SitesService = SitesService;
		this.ProduitsService = ProduitsService;
		this.moment = moment;
		this.ReferencesControleService = ReferencesControleService;
		this.globalizationManagementService = globalizationManagementService;
		this.Interpreteur = Interpreteur;
		this.MaterielService = MaterielService;
		this.RouterHistoryService = RouterHistoryService;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
		this.MassiaRightsService = MassiaRightsService;
		this.EssaisMaterielService = EssaisMaterielService;
		this.PrelevEssaiMaterielsService = PrelevEssaiMaterielsService;
		this.PropositionBestTrameRefService = PropositionBestTrameRefService;
		this.FTPArchiveeService = FTPArchiveeService;
		this.$timeout = $timeout;
	}

	async $onInit() {
		this.idTrameSelected = this.idTrameSelected || [];
		this.selectedRef = this.selectedRef || [];
		this.selectedFtpa = this.selectedFtpa || [];
		this.$scope.testObjectChildrenMesures = true;
		this.metroLoaded = false;
		this.ouvrirTout = true;
		this.dejaDansChargeTrameEtRef = false;
		this.saisie = this.saisie || {};
		this.conformiteArrondie = await this.PrelevementsService.getConformiteValeursArrondies();
		this.selectedByFTPA = this.selectedByFTPA || false;

		await this.loadMetrologieContext();
		await this.getValeursReference();

		this.trames = this.trames || [];
		this.references = this.saisie.reference.id ? [this.saisie.reference] : [];
		// On récupère la FTPA sauvegardée s'il y en a une
		this.ftpaSelected = this.saisie.ftpa.id ? [this.saisie.ftpa] : [];

		//this.changeTrame();//SP 09/03/21 await pour mantis 6218

		this.unregister = [];
		this.unregister.push(
			this.$scope.$watch(
				() => this.saisie.producteur?.id,
				() => {
					if (!this.saisie.producteur) {
						this.saisie.reference = {};
						this.saisie.trame = {};
						this.saisie.ftpa = {};
					}
					this.chargeTramesEtReferences();
					this.getAllFtpas();
				},
				true
			)
		);
		this.unregister.push(
			this.$scope.$watch(
				() => this.saisie.produit?.id,
				() => {
					if (!this.saisie.produit) {
						this.saisie.reference = {};
						this.saisie.trame = {};
						this.saisie.ftpa = {};
					}
					this.chargeTramesEtReferences();
					this.getAllFtpas();
				},
				true
			)
		);
		this.unregister.push(
			this.$scope.$watch(
				() => this.saisie.client?.id,
				() => {
					this.chargeTramesEtReferences();
				}
			)
		);
		this.unregister.push(
			this.$scope.$watch(
				() => this.saisie.refreshDate,
				async (newValue, oldValue) => {
					if (newValue !== oldValue) {
						if (this.parseDate(this.saisie.date)) {
							this.chargeTramesEtReferences();
						}
					}
				}
			)
		);

		if (this.campagne) {
			this.unregister.push(
				this.$scope.$watch(
					() => this.saisie.reference.id,
					async (newValue, oldValue) => {
						if (newValue) {
							this.references = [this.saisie.reference];
						}
						this.changeReferentielActu(this.saisie.reference.id, true);
					}
				)
			);
			this.unregister.push(
				this.$scope.$watch(
					() => this.saisie.trame.id,
					async (newValue, oldValue) => {
						if (newValue) {
							this.trames = [this.saisie.trame];
						}
						this.changeTrame();
					}
				)
			);
		}

		this.$rootScope.$on('headerDataChange', (e, arg) => {
			this.saisie = arg;
			this.getSousMesuresCompletBDD();
		});

		// Pour la proposition de best trame et ref à la creation
		const isNewPrelev =
			this.$state.current.name === 'prelevements.new-prelevement' &&
			this.saisie &&
			typeof this.saisie.id === 'undefined' &&
			!this.saisie.trame.id &&
			!this.saisie.reference.id;
		if (isNewPrelev) {
			const unbindProduit = this.$scope.$watch(
				() => this.saisie.produit,
				() => {
					if (this.saisie.produit && this.saisie.produit.id) {
						const saisie = angular.copy(this.saisie);
						this.propositionModal(saisie);
						// pour détruire le watcher apres un seul appel
						unbindProduit();
					}
				}
			);
		}

		this.unregister.push(
			this.$scope.$watch(
				() => this.saisie.mesures,
				async () => {
					//refresh conformite si reference saisie
					//  console.log(this.saisie);
					if (this.saisie.reference) {
						this.changeReferentielActu(this.saisie.reference.id, true);
					}
				},
				true
			)
		);

		/* if(this.saisie.trame.id){
            this.idTrameSelected.push({id:this.saisie.trame.id})
        } */
		await this.initFtpa();
		this.stopLoading();
	}

	$onDestroy() {
		for (let i = 0; i < this.unregister.length; i++) {
			this.unregister[i]();
		}
	}

	async initFtpa() {
		// hydrate le MMS avec la liste des FTPA et récupère les bornes de celle enregistrée
		await this.getAllFtpas();
		// Permet de charger le module lorsqu'on sait s'il y a une FTPA
		this.tabsReady = false;
		if (this.ftpaSelected.length > 0 && this.ftpaSelected[0].id) {
			await this.getBornesFromFTPA(this.ftpaSelected[0].id);
			this.selFtpa = this.ftpaSelected.length > 0 && this.ftpaSelected[0].id ? true : false;
		}
		this.$timeout(() => {
			this.tabsReady = true;
		});
	}

	async getValeursReference() {
		const valeursRef = await this.PrelevementsService.getValeursReference(this.saisie);
		this.Interpreteur.init(this.saisie, valeursRef); //SP 10/03/20non, MVT en valeurs de références
	}

	async loadMetrologieContext() {
		try {
			this.saisie.materiels = {};
			const canAccessMetrologie = this.MassiaRightsService.userHasRight('materiel', 'read', 'laboratoire');
			if (canAccessMetrologie) {
				if (this.saisie && this.saisie.mesures && this.saisie.mesures.length > 0) {
					const data = await this.MaterielService.getAll([], [], { skip: 0, take: 5000 }, 'laboratoire');
					this.materiels = data.items;
					if (this.materiels && this.materiels.length > 0 && this.saisie && this.saisie.auditeur) {
						this.materiels = angular.copy(this.materiels.filter((x) => x.idLaboratoire == this.saisie.auditeur.id));
					}

					const allPrelevMateriels = await this.PrelevEssaiMaterielsService.getMateriels(this.saisie.id || 0);
					this.allPrelevMateriels = {};
					const allMesuresMateriels = await this.EssaisMaterielService.getMesuresMateriels(
						this.saisie.mesures.map((x) => x.id),
						'essai'
					);
					this.allMesuresMateriels = {};

					this.saisie.mesures.forEach((e) => {
						this.saisie.materiels[e.id] = angular.copy(this.materiels);
						this.allPrelevMateriels[e.id] = allPrelevMateriels.filter((x) => x.idEssai === e.id);
						this.allMesuresMateriels[e.id] = allMesuresMateriels.filter((x) => x.idEssai === e.id && x.default == true);
					});
				}
			} else {
				this.metroLoaded = true;
			}
			this.checkGranuloMateriel();
		} catch (e) {
			console.error('loadMetrologieContext', e, this);
		}
		this.metroLoaded = true;
	}

	async majValeursReference() {
		this.Interpreteur.valeursRef = await this.PrelevementsService.getValeursReference(this.saisie);
	}

	async chargeTramesEtReferences() {
		if (!this.dejaDansChargeTrameEtRef) {
			this.dejaDansChargeTrameEtRef = true;
			if (!this.campagne) {
				await this.getTrames();
				await this.getReferences();
			}

			if (this.saisie.reference) {
				await this.changeReferentielActu(this.saisie.reference.id, true);
			}

			await this.majValeursReference();
			this.dejaDansChargeTrameEtRef = false;
		}
	}

	async getSousMesuresCompletBDD() {
		try {
			const oldMesures = angular.copy(this.saisie.mesures);
			this.saisie.mesures = await this.PrelevementsService.getMesuresComplet(
				this.saisie.id,
				this.saisie.mesures.map((e) => e.id),
				this.saisie
			);
			this.saisie = new Prelevement(this.saisie, this.moment, this.dateFormat);
			for (let i = 0; i < this.saisie.mesures.length; i++) {
				const mesure = this.saisie.mesures[i];
				const oldMesure = oldMesures.find((e) => e.id === mesure.id);

				if (oldMesure) {
					mesure.indexSaisie = oldMesure.indexSaisie;
					if (!oldMesure.hasOwnProperty('sousEssais')) {
						//vient depuis ajout de trame ou de référence
						mesure.isDansTrame = oldMesure.isDansTrame;
						mesure.isDeleted = oldMesure.isDeleted;
						mesure.isDansReference = oldMesure.isDansReference;
					} else {
						for (const j in mesure.sousEssais) {
							const ssMes = mesure.sousEssais[j];
							const oldSsMes = oldMesure.sousEssais[j];

							if (oldSsMes) {
								const isGranulo = ssMes.granuloAdditionalInformation != null;

								if (!isGranulo) {
									// permet de recuperer les ssess si import
									if (
										oldSsMes.generiqueAdditionalInformation &&
										ssMes.generiqueAdditionalInformation &&
										oldSsMes.generiqueAdditionalInformation.nbIteration === ssMes.generiqueAdditionalInformation.nbIteration
									) {
										ssMes.generiqueAdditionalInformation =
											oldSsMes.generiqueAdditionalInformation || ssMes.generiqueAdditionalInformation;
									}

									ssMes.value = oldSsMes.value || ssMes.value;
									ssMes.forceSaisie = oldSsMes.forceSaisie || ssMes.forceSaisie;
								} else {
									for (let t = 0; t < ssMes.granuloAdditionalInformation.tamis.length; t++) {
										const tamis = ssMes.granuloAdditionalInformation.tamis[t];
										const oldTamis = oldSsMes.granuloAdditionalInformation.tamis.find((e) => e.idTamis === tamis.idTamis);

										if (oldTamis) {
											if (oldTamis.isDisplay) {
												ssMes.granuloAdditionalInformation.tamis[t] = oldTamis;
											} else {
												tamis.passant = oldTamis.passant;
												tamis.isDisplay = oldTamis.isDisplay;
												if (oldTamis.hasOwnProperty('isDansTrame')) {
													tamis.isDansTrame = oldTamis.isDansTrame;
												}
												if (oldTamis.hasOwnProperty('isDansReference')) {
													tamis.isDansReference = oldTamis.isDansReference;
												}
												if (oldTamis.hasOwnProperty('isDeleted')) {
													tamis.isDeleted = oldTamis.isDeleted;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			for (let i = 0; i < oldMesures.length; i++) {
				const oldMesure = oldMesures[i];
				const mesure = this.saisie.mesures.find((e) => e.id === oldMesure.id);

				if (!mesure) {
					this.saisie.mesures.push(oldMesure);
				} else {
					if (!oldMesure.hasOwnProperty('sousEssais')) {
						//vient depuis ajout de trame ou de référence
						mesure.isDansTrame = oldMesure.isDansTrame;
						mesure.isDeleted = oldMesure.isDeleted;
						mesure.isDansReference = oldMesure.isDansReference;
					} else {
						const ssEssaiPrincipal = Object.values(mesure.sousEssais).find((x) => x.code == mesure.code);
						const essaiExcel = ssEssaiPrincipal.idNature == 12;

						for (const j in oldMesure.sousEssais) {
							const oldSsMes = oldMesure.sousEssais[j];
							const ssMes = mesure.sousEssais[j];

							if (ssMes) {
								const isGranulo = ssMes.granuloAdditionalInformation != null;
								if (isGranulo) {
									for (let t = 0; t < oldSsMes.granuloAdditionalInformation.tamis.length; t++) {
										const oldTamis = oldSsMes.granuloAdditionalInformation.tamis[t];
										let tamis = ssMes.granuloAdditionalInformation.tamis.find((e) => e.idTamis === oldTamis.idTamis);

										if (!tamis) {
											ssMes.granuloAdditionalInformation.tamis.push(oldTamis);
										} else {
											if (oldTamis.isDisplay) {
												tamis = oldTamis;
											} else {
												tamis.passant = oldTamis.passant;
												tamis.isDisplay = oldTamis.isDisplay;
												if (oldTamis.hasOwnProperty('isDansTrame')) {
													tamis.isDansTrame = oldTamis.isDansTrame;
												}
												if (oldTamis.hasOwnProperty('isDansReference')) {
													tamis.isDansReference = oldTamis.isDansReference;
												}
												if (oldTamis.hasOwnProperty('isDeleted')) {
													tamis.isDeleted = oldTamis.isDeleted;
												}
											}
										}
									}
								} else if (essaiExcel) {
									const single = [0, 1, 2, 3, 4];
									if (single.includes(ssMes.idNature)) {
										ssMes.value = ssMes.valeur;
									} else {
										ssMes.value = ssMes.valeurs;
									}
								}
							}
						}
					}
				}
			}

			//refresh conformite
			if (this.saisie.reference) {
				await this.changeReferentielActu(this.saisie.reference.id, true);
			}

			//refresh metro context
			await this.loadMetrologieContext();
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async getTrames() {
		let idSite = 0;
		if (this.saisie && this.saisie.producteur && this.saisie.producteur.id) {
			idSite = this.saisie.producteur.id;
		}
		let idProduit = 0;
		if (this.saisie && this.saisie.produit && this.saisie.produit.id) {
			idProduit = this.saisie.produit.id;
		}
		try {
			this.trames = await this.PrelevementsService.getTrames(this.idFamille ? this.idFamille : this.$stateParams.idFamille, idSite, idProduit);
			const index = this.trames.findIndex((x) => x.id === this.saisie.trame.id);
			if (index !== -1) {
				this.trames[index].selected = true;
			}
			if (!this.saisie.id && this._.isEmpty(this.DataShareService.savedData) == false) {
				// on cherche l'entité saisie
				const dataShareSaisie = this._.find(this.DataShareService.savedData, { entity: 'saisie' });
				if (dataShareSaisie != null) {
					if (dataShareSaisie.modeleIdSelected) {
						const modeleSaisie = await this.PrelevementsService.GetModeleById(dataShareSaisie.modeleIdSelected);
						if (modeleSaisie && modeleSaisie.trameId) {
							const index = this.trames.findIndex((x) => x.id === modeleSaisie.trameId);
							if (index !== -1) {
								this.trames[index].selected = true;
							}
							this.saisie.trame.id = modeleSaisie.trameId;
							this.saisie.trame.libelle = modeleSaisie.trameLibelle;
							await this.reloadTrame();
						}
					}
				}
			}
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async reloadTrame(data = null) {
		try {
			console.log(data);
			if (data && this.saisie.trame.id === data.id) {
				this.saisie.trame.id = null;
				this.idTrameSelected = [];
				const e = this.trames.find((x) => x.id === data.id);
				if (e) {
					e.selected = false;
				}
			} else if (this.idTrameSelected && this.idTrameSelected[0] && this.idTrameSelected[0].id) {
				this.saisie.trame.id = this.idTrameSelected[0].id;
			}

			this.startLoading();
			if (this.saisie.mesures.length > 0 && (!this.saisie.id || this.saisie.id < 0)) {
				for (let i = 0; i < this.saisie.mesures.length; i++) {
					var el = this.saisie.mesures[i];
					if (el.isDansTrame) {
						//mise en place d'un flag de suppression
						el.isDeleted = true;
					} else {
						el.position = el.position + 100;
					}
				}
			}
			//initialise l'ordre des esais
			this.saisie.mesures.sort((a, b) => a.indexSaisie - b.indexSaisie);
			let i = 0;
			this.saisie.mesures.forEach((element) => {
				element.indexSaisie = i++;
			}); //récupérer les mesures de la trame
			let mesuresTrames = [];
			if (this.saisie.trame.id) {
				mesuresTrames = await this.PrelevementsService.getMesuresTrame(this.saisie.trame.id);
			}
			//ajouter les mesures du tramequi ne sont pas présentes sur la saisie
			for (let i = 0; i < mesuresTrames.length; i++) {
				const idx = this.saisie.mesures.findIndex((x) => x.id === mesuresTrames[i].id);
				let j = this.saisie.mesures.length + 1;
				if (idx == -1) {
					if (!this.saisie.mesures && this.saisie.mesures.length === 0) {
						this.saisie.mesures = [];
					}
					mesuresTrames[i].isDansTrame = true;
					//ajouter indexSaisie pour les news mesures pour l'ordre d'affichage
					mesuresTrames[i].indexSaisie = j;
					j++;
					this.saisie.mesures.push(mesuresTrames[i]);
				} else {
					//suppression du flag de suppression (dans le cas ou l'essai doit être conservé)
					delete this.saisie.mesures[idx].isDeleted;
				}
			}
			await this.getSousMesuresCompletBDD();

			//récupérer les tamis de la trame
			let tamisTrames = [];
			if (this.saisie.trame.id) {
				tamisTrames = await this.PrelevementsService.getTamisTrame(this.saisie.trame.id);
			}

			await this.filterEssaiTrame(tamisTrames);

			if (this.saisie.reference && this.saisie.reference.id) {
				await this.changeReferentielActu(this.saisie.reference.id, false);
			}

			//suppression des mesures flaguées
			for (let i = this.saisie.mesures.length - 1; i >= 0; i--) {
				var el = this.saisie.mesures[i];
				if (el.isDeleted) {
					this.saisie.mesures.splice(i, 1);
				}
			}
		} catch (ex) {
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	async changeTrame(data = null) {
		if (this.idTrameSelected && this.idTrameSelected[0] && this.idTrameSelected[0].id) {
			this.saisie.trame.id = this.idTrameSelected[0].id;
		}
		//this.startLoading();
		if (this.saisie.mesures.length > 0) {
			for (let i = 0; i < this.saisie.mesures.length; i++) {
				const el = this.saisie.mesures[i];
				if (!el.isDansTrame) {
					el.position = el.position + 100;
				}
			}
		}

		await this.getSousMesuresCompletBDD();
		//récupérer les tamis de la trame
		let tamisTrames = [];
		if (this.saisie.trame.id) {
			tamisTrames = await this.PrelevementsService.getTamisTrame(this.saisie.trame.id);
		}
		await this.filterEssaiTrame(tamisTrames);

		if (this.saisie.reference) {
			await this.changeReferentielActu(this.saisie.reference.id, false);
		}
	}

	async filterEssaiTrame(tamisTrames) {
		for (let i = 0; i < tamisTrames.length; i++) {
			//trouver le sous-essai granulo
			const idxEssai = this._.findIndex(this.saisie.mesures, { id: tamisTrames[0].idEssai });
			const sousEssai = Object.values(this.saisie.mesures[idxEssai].sousEssais).find((e) => e.granuloAdditionalInformation);
			if (sousEssai) {
				const idx = sousEssai.granuloAdditionalInformation.tamis.findIndex((e) => e.ouverture === tamisTrames[i].ouverture);
				sousEssai.granuloAdditionalInformation.tamis[idx].isDansTrame = true;
				sousEssai.granuloAdditionalInformation.tamis[idx].isDisplay = true;
			}
		}
	}

	async getReferences() {
		let idSite = 0;
		if (this.saisie && this.saisie.producteur && this.saisie.producteur.id && this.saisie.produit && this.saisie.produit.id) {
			idSite = this.saisie.producteur.id;
			try {
				this.references = await this.PrelevementsService.getReferencesByFiltre(
					this.idTheme ? this.idTheme : this.$stateParams.idTheme,
					idSite,
					this.saisie.produit.id,
					this.saisie.client.id,
					this.saisie.date,
					this.idFamille ? this.idFamille : this.$stateParams.idFamille
				);

				let idxActu = this._.findIndex(this.references, { isDefaut: true });
				let idSelectionneActu = 0;
				if (idxActu > -1) {
					idSelectionneActu = this.references[idxActu].id;
				} else {
					idxActu = this._.findIndex(this.references, { id: this.saisie.reference.id });
				}
				if (idxActu > -1) {
					idSelectionneActu = this.references[idxActu].id;
				} else {
					idSelectionneActu = this.saisie.reference.id;
				}

				if ((idxActu < 0 || !this.references || this.references.length <= 0) && this.saisie.reference.id) {
					await this.unfilterReferences();
				}

				//en création, ne prendre que les références du modèle si il y en a qui sont spécifiées
				if (!this.saisie.id && this._.isEmpty(this.DataShareService.savedData) == false) {
					// on cherche l'entité saisie
					const dataShareSaisie = this._.find(this.DataShareService.savedData, { entity: 'saisie' });
					if (dataShareSaisie != null) {
						if (dataShareSaisie.modeleIdSelected) {
							const modeleSaisie = await this.PrelevementsService.GetModeleById(dataShareSaisie.modeleIdSelected);
							if (modeleSaisie && modeleSaisie.references && modeleSaisie.references.length > 0) {
								const refCopie = angular.copy(this.references);
								for (let i = refCopie.length - 1; i > -1; i--) {
									const idxRef = this._.findIndex(modeleSaisie.references, { referenceId: refCopie[i].id });
									if (idxRef == -1) {
										this.references.splice(i, 1);
									} else {
										//la référence est à garder
										//vérifier si c'est celle par défaut
										if (modeleSaisie.references[idxRef].estDefaut) {
											this.saisie.reference.id = modeleSaisie.references[idxRef].referenceId;
										}
										//vérifier si c'est celle pour conformité
										if (modeleSaisie.references[idxRef].estEnregistre) {
											idSelectionneActu = modeleSaisie.references[idxRef].referenceId;
										}
									}
								}
							}
						}
					}
				}

				const idx = this._.findIndex(this.references, {
					id: idSelectionneActu
				});
				if (idx > -1) {
					this.references[idx].isDefaut = true;
					this.references[idx].selected = true;
				}
			} catch (ex) {
				this.notification.error(ex.data);
			}
		}
	}

	async getAllFtpas() {
		if (this.saisie && this.saisie.producteur && this.saisie.producteur.id && this.saisie.produit && this.saisie.produit.id) {
			try {
				const composant = {
					idElement: this.saisie.produit.id,
					idProducteur: this.saisie.producteur.id
				};
				this.ftpa = await this.ProduitsService.getFTPs(composant);
				if (this.saisie.ftpa.id) {
					this.setSelectedFtpa(this.saisie.ftpa.id);
				}
			} catch (ex) {
				this.notification.error(ex.data);
			}
		}
	}

	async getAllReferences() {
		this.allReferences = await this.PrelevementsService.getReferencesByFiltre(
			this.idTheme ? this.idTheme : this.$stateParams.idTheme,
			0,
			0,
			0,
			null,
			this.$stateParams.idFamille
		);
		//ne prendre que les références du modèle si il y en a qui sont spécifiées
		if (!this.saisie.id && !this._.isEmpty(this.DataShareService.savedData)) {
			// on cherche l'entité saisie
			const dataShareSaisie = this._.find(this.DataShareService.savedData, { entity: 'saisie' });
			if (dataShareSaisie && dataShareSaisie.modeleIdSelected) {
				const modeleSaisie = await this.PrelevementsService.GetModeleById(dataShareSaisie.modeleIdSelected);
				if (modeleSaisie && modeleSaisie.references && modeleSaisie.references.length > 0) {
					const refCopie = angular.copy(this.allReferences);
					for (let i = refCopie.length - 1; i > -1; i--) {
						const idxRef = this._.findIndex(modeleSaisie.references, { referenceId: refCopie[i].id });
						if (idxRef === -1) {
							this.allReferences.splice(i, 1);
						}
					}
				}
			}
		}
	}

	async setPlanificationDetails(mesure) {
		//calcul de la date prévue si l'essai est planifié
		if (mesure.planification) {
			this.Interpreteur.essaiAppel = undefined;
			this.Interpreteur.forceIter = undefined;
			const resDatePrevue = await this.Interpreteur.calculeFormule(mesure.planification);
			if (resDatePrevue) {
				mesure.datePrevue = resDatePrevue;
			} //VP -> conversion de date dans l'interpréteur this.moment(resDatePrevue).format(this.dateFormat);

			if (mesure.datePrevue) {
				if (this.saisie.auditeur.id) {
					const dt = await this.PrelevementsService.getNextValidDate(this.saisie.auditeur.id, mesure.datePrevue);
					if (dt !== mesure.datePrevue) {
						mesure.datePrevueExact = mesure.datePrevue;
						mesure.datePrevue = dt;
					}
				}
			}
		}

		return mesure;
	}

	async getSsEssValueFromFormule(ssEss) {
		let newValIter = await this.Interpreteur.calculeFormule(ssEss.formule);

		if (ssEss.nature == 'Numerique' && newValIter != '') {
			const x = this.Interpreteur.strEnDouble(newValIter);
			if (!isNaN(x)) {
				let arrondi = ssEss.numericAdditionalInformation.nombreDeDecimales;

				const grandeurMinimum = ssEss.numericAdditionalInformation.grandeurMinimum;
				const grandeurMaximum = ssEss.numericAdditionalInformation.grandeurMaximum;

				//gestion des min/max
				if (grandeurMinimum != undefined && grandeurMinimum != null && grandeurMinimum > newValIter) {
					newValIter = grandeurMinimum;
				}
				if (grandeurMaximum != undefined && grandeurMaximum != null && grandeurMaximum < newValIter) {
					newValIter = grandeurMaximum;
				}
				//gestion de l'arrondi
				if (!arrondi) {
					if (arrondi !== 0) {
						arrondi = 2;
					}
				}
				const objetToRound = [{ key: x, val: x, rounder: arrondi }];
				newValIter = this.Interpreteur.getRoundedValue(objetToRound);
			} else {
				newValIter = null;
			}
		}

		if (ssEss.nature == 'Booleen' && (newValIter == true || newValIter == false)) {
			//ADO 2192
			//La valeur d'un sousEssai boolean est 'true' / 'false'
			newValIter = newValIter.toString();
		}

		return newValIter;
	}

	getConformite(obj, valeur) {
		let conformite = 0;
		if (typeof obj.bornes.borneInferieure === 'number') {
			if ((valeur || valeur == 0) && parseFloat(valeur) < obj.bornes.borneInferieure) {
				if (typeof obj.bornes.toleranceInferieure === 'number') {
					if ((valeur || valeur == 0) && parseFloat(valeur) < obj.bornes.toleranceInferieure) {
						conformite = 2;
					} else {
						conformite = 3;
					}
				} else {
					conformite = 2;
				}
			} else {
				if (typeof obj.bornes.alerteInferieure === 'number' && (valeur || valeur == 0) && parseFloat(valeur) < obj.bornes.alerteInferieure) {
					conformite = 1;
				}
			}
		}
		if (typeof obj.bornes.borneSuperieure === 'number') {
			if ((valeur || valeur == 0) && parseFloat(valeur) > obj.bornes.borneSuperieure) {
				if (typeof obj.bornes.toleranceSuperieure === 'number') {
					if ((valeur || valeur == 0) && parseFloat(valeur) > obj.bornes.toleranceSuperieure) {
						conformite = 2;
					} else {
						conformite = 3;
					}
				} else {
					conformite = 2;
				}
			} else {
				if (
					conformite < 2 &&
					typeof obj.bornes.alerteSuperieure === 'number' &&
					(valeur || valeur == 0) &&
					parseFloat(valeur) > obj.bornes.alerteSuperieure
				) {
					conformite = 1;
				}
			}
		}

		/*NonControle = -1,
        Conforme = 0,
        Alerte = 1,
        NonConforme = 2,
        ConformeU = 3 */

		return conformite;
	}

	async changeReferentielActu(nouvIdRef, bChangeReel) {
		if (this.selectedRef && this.selectedRef.length !== 0) {
			this.selectedByFTPA = false;
		}

		if (!this.isCalculing) {
			this.isCalculing = true;

			let bornes = [];
			let conformiteSaisie = -1;
			const valeursARecalculer = [];
			if (this.Interpreteur) {
				this.Interpreteur.calculatedFormules = [];
				this.Interpreteur.obj = this.saisie;
			}
			if (this.saisie.mesures) {
				if (bChangeReel && !this.campagne) {
					if (!nouvIdRef && this.saisie.reference.id) {
						nouvIdRef = this.saisie.reference.id;
					}

					this.saisie.reference.id = nouvIdRef;
					if (this.references && this.references.length > 0) {
						const reference = this.references.find((e) => e.id === nouvIdRef);
						if (reference) {
							const nouveauRef = await this.ReferencesControleService.getReferenceBorneById(
								nouvIdRef,
								this.saisie.produit.id,
								this.saisie.produitCommercial ? this.saisie.produitCommercial.id : 0
							);
							bornes = nouveauRef ? nouveauRef.bornes : [];
							reference.selected = true;
						}
					}
				}

				// recuperation des bornes afin de pouvoir trier sur les reference  à l'impression
				if (this.saisie && !this.selectedByFTPA && !this.selFtpa) {
					this.saisie.bornes = angular.copy(bornes);
				}
				if (this.campagne) {
					this.campagne.bornes = [];
				}
				//TODO: SP ordonner par position essai dans trame / position ssessai dans essai
				for (let idx1 = 0; idx1 < this.saisie.mesures.length; idx1++) {
					let mesure = this.saisie.mesures[idx1];

					mesure = await this.setPlanificationDetails(mesure);

					let recharge = false;
					for (const idx2 in mesure.sousEssais) {
						const ssEss = mesure.sousEssais[idx2];
						if (ssEss.hasOwnProperty('id')) {
							if (ssEss.idNature == 10 || ssEss.idNature == 11) {
								this.Interpreteur.essaiAppel = mesure.code;
								//rechercher les sous-essais itératifs et le nb max d'itérations
								let nbIter = ssEss.generiqueAdditionalInformation.nbIteration || 1;
								//let idsSsMesIter = this._.map(format.generiqueAdditionalInformation.essaiLie.sousEssais, 'id');
								//TODO: rechercher le nb max d'itérations dans les tableaux de valeur
								for (const idx3 in ssEss.generiqueAdditionalInformation.essaiLie.sousEssais) {
									const ssEssIter = ssEss.generiqueAdditionalInformation.essaiLie.sousEssais[idx3];
									if (ssEssIter.hasOwnProperty('id') && !ssEssIter.forceSaisie) {
										if (ssEssIter.formule) {
											if (ssEssIter.value.length > 1) {
												nbIter = ssEssIter.value.length;
											} //si on a déjà des valeurs, alors on considère que le nombre d'itérations a déjà été adapté au besoin utilisateur
											while (ssEssIter.value.length < nbIter) {
												ssEssIter.value.push('');
											}

											for (let idx4 = 0; idx4 < nbIter && idx4 < ssEssIter.value.length; idx4++) {
												this.Interpreteur.forceIter = idx4;

												const newValIter = await this.getSsEssValueFromFormule(ssEssIter);
												if (ssEssIter.value[idx4] !== newValIter) {
													ssEssIter.value[idx4] = newValIter;
													recharge = true;
												}
											}
										}
										// set les bornes des sous essais des éprouvettes
										if (this.saisie.bornes && this.saisie.bornes.length > 0) {
											const idxBornes = this.saisie.bornes.findIndex((x) => x.idSousEssai === ssEssIter.id);
											if (idxBornes > -1) {
												ssEssIter.bornes = angular.copy(this.saisie.bornes[idxBornes]);
												let conformite = [];
												for (let i = 0; i < ssEssIter.value.length; i++) {
													conformite = [
														...conformite,
														{
															id: i,
															value: this.getConformite(ssEssIter, ssEssIter.value[i])
														}
													];
												}
												ssEssIter.conformite = conformite;
											}
										}
									}
								}
								this.Interpreteur.forceIter = undefined;
								this.Interpreteur.essaiAppel = undefined;
							} else {
								ssEss.conformite = -1;
								const idSsEss = ssEss.id;
								//formule
								if (ssEss.formule /*&& !ssEss.forceSaisie*/) {
									//SP 11/02/22 calculer quand-même si on peut saisir
									if (ssEss.formule.indexOf('@CONF') != -1) {
										valeursARecalculer.push(idx1);
										//TODO: SP si valeurs manquantes, ne pas encore calculer
									} else {
										try {
											this.Interpreteur.essaiAppel = undefined;
											this.Interpreteur.forceIter = undefined;

											const newVal = await this.getSsEssValueFromFormule(ssEss);
											if (!(newVal == '' && ssEss.forceSaisie)) {
												//si pas de résultat trouvé et forceSaisie, on garde ce qu'on avait avant
												if (ssEss.value !== newVal) {
													ssEss.value = newVal;
													recharge = true;
												}
											}
										} catch (ex) {}
									}
								}
								//chercher la borne
								let listeBornes = this.saisie.bornes;
								if (this.campagne) {
									listeBornes = this.campagne.bornes;
								}
								if (!listeBornes) {
									listeBornes = [];
								}

								if (ssEss.nature !== 'AnalyseGranulo') {
									delete ssEss.bornes;
									ssEss.bornes = listeBornes.find((e) => e.idSousEssai === ssEss.id && !e.tamis) || {};
									if (
										ssEss.bornes &&
										(typeof ssEss.bornes.borneInferieure === 'number' || typeof ssEss.bornes.borneSuperieure === 'number')
									) {
										if (ssEss.value || ssEss.value === 0) {
											let valToControl = ssEss.value;
											if (
												this.conformiteArrondie === 1 &&
												ssEss.numericAdditionalInformation &&
												ssEss.numericAdditionalInformation.nombreDeDecimales
											) {
												const parsed = parseFloat(valToControl);
												if (parsed || parsed === 0) {
													if (typeof valToControl !== 'number') {
														const numberStr = valToControl.toString().replace(/\s/g, '').replace(',', '.');
														valToControl = parseFloat(numberStr);
													}
												}
												if (typeof valToControl === 'number') {
													const strArrond = valToControl.toFixed(ssEss.numericAdditionalInformation.nombreDeDecimales);
													valToControl = parseFloat(strArrond);
												}
											}
											ssEss.conformite = this.getConformite(ssEss, valToControl);
											if (ssEss.conformite === 2) {
												conformiteSaisie = ssEss.conformite;
											} else if (ssEss.conformite > conformiteSaisie && conformiteSaisie !== 2) {
												conformiteSaisie = ssEss.conformite;
											}
										}
									}
								} else {
									//granulo
									for (let t = 0; t < ssEss.granuloAdditionalInformation.tamis.length; t++) {
										const tamis = ssEss.granuloAdditionalInformation.tamis[t];

										delete tamis.bornes;
										tamis.bornes =
											listeBornes.find(
												(e) => e.idSousEssai === ssEss.id && e.tamis && tamis.idSousEssaiTamis == e.idSousEssaiTamis
											) || {};

										if (
											tamis.bornes &&
											(typeof tamis.bornes.borneInferieure === 'number' || typeof tamis.bornes.borneSuperieure === 'number')
										) {
											tamis.conformite = this.getConformite(
												tamis,
												this.conformiteArrondie === '1' || this.conformiteArrondie === 1
													? tamis.pourcPassantArrondi
													: tamis.pourcPassant
											);

											if (tamis.conformite === 2) {
												conformiteSaisie = tamis.conformite;
											} else if (tamis.conformite > conformiteSaisie && conformiteSaisie !== 2) {
												conformiteSaisie = tamis.conformite;
											}
										}
									}
								}
							}
						}
					}

					if (recharge) {
						mesure.recharge = !mesure.recharge;
					}
				}

				this.saisie.conformite = conformiteSaisie;
				//traitement des valeurs à recalculer
				for (let i = 0; i < valeursARecalculer.length; i++) {
					const mesure = this.saisie.mesures.find((e) => e.id === valeursARecalculer[i]);
					for (const j in mesure.sousEssais) {
						const ssEss = mesure.sousEssais[j];
						//formule
						if (ssEss.hasOwnProperty('id') && ssEss.formule) {
							const oldVal = ssEss.value;
							try {
								const newVal = await this.getSsEssValueFromFormule(ssEss);
								if (oldVal !== newVal) {
									ssEss.value = newVal;
									mesure.recharge = !mesure.recharge;
								}
							} catch (ex) {}
						}
					}
				}
			}

			delete this.isCalculing;
		}
	}

	changeReferentielDefaut(nouvDefautId) {
		if (!nouvDefautId && this.saisie.reference.id) {
			nouvDefautId = this.saisie.reference.id;
		}

		const idxActu = this._.findIndex(this.references, { isDefaut: true });
		const idxNouveau = this._.findIndex(this.references, {
			id: nouvDefautId
		});
		if (idxActu > -1) {
			this.references[idxActu].isDefaut = false;
		}
		if (idxActu !== idxNouveau && idxNouveau > -1) {
			this.references[idxNouveau].isDefaut = true;
			this.saisie.reference.id = nouvDefautId;
			this.references[idxNouveau].selected = true;
		} else {
			this.saisie.reference.id = undefined;
		}
	}

	async unfilterReferences() {
		if (!this.allReferences) {
			await this.getAllReferences();
		}

		this.initialReferences = angular.copy(this.references);
		this.saisie.initialReferenceId = this.saisie.reference.id;

		//this.saisie.reference.id = null;
		this.references = this.allReferences;

		this.unfilterRef = true;
	}

	async filterReferences() {
		this.saisie.reference.id = this.saisie.initialReferenceId;
		this.references = angular.copy(this.initialReferences);

		this.initialReferences = null;
		this.saisie.initialReferenceId = null;

		this.unfilterRef = false;
	}

	parseDate(dateString) {
		let res = false;
		if (dateString) {
			const date = this.moment(dateString, this.dateFormat);

			if (date) {
				res = date instanceof Date && !isNaN(date);
			}
		}

		return res;
	}

	startLoading() {
		this.loading = true;
		this.loadingMesures = true; //JN Bind Loading mesures (disable print on true)
	}

	stopLoading() {
		this.loading = false;
		this.loadingMesures = false; //JN Bind Loading mesures (disable print on true)
	}

	async onSelectReference(data = null) {
		this.saisie.ftpa.id = null;
		if (angular.copy(this.selectedFtpa) !== null && angular.copy(this.selectedFtpa.length > 0)) {
			const ftpa = this.ftpa.find((x) => x.id === this.selectedFtpa[0].id);
			ftpa.selected = false;
			this.selFtpa = false;
		}

		if (this.saisie && this.saisie.reference && data && data.id === this.saisie.reference.id) {
			this.saisie.reference.id = null;
			const ref = this.references.find((x) => x.id === data.id);
			ref.selected = false;
			this.saisie.initialReferenceId = null;
		} else if (!data) {
			this.saisie.initialReferenceId = null;
			this.saisie.reference.id = null;
			this.references.map((x) => {
				x.selected = false;
				return x;
			});
		}
		if (this.selectedRef && this.selectedRef[0]) {
			await this.changeReferentielActu(this.selectedRef[0].id, true);
		} else {
			await this.changeReferentielActu(this.saisie.reference.id, true);
		}
	}

	async onSelectFtpa(ftpaSelected = null) {
		if (this.saisie && this.saisie.ftpa && ftpaSelected.length > 0 && ftpaSelected[0].id !== this.saisie.ftpa.id) {
			this.saisie.ftpa.id = ftpaSelected[0].id;
		}
		if (ftpaSelected.length > 0 && ftpaSelected[0].hasOwnProperty('id')) {
			this.getBornesFromFTPA(ftpaSelected[0].id);
		} else {
			this.selFtpa = false;
			this.saisie.ftpa = {};
		}
		await this.changeReferentielActu(this.saisie.reference.id, true);
	}

	addTrame(data = null) {
		this.saisie.outsourceModel(this.moment, this.dateFormat);
		this.RouterHistoryService.data = this.saisie;
		if (data) {
			return this.$state.go('refsaisie.edit', { id: data[0].id });
		}
		return this.$state.go('refsaisie.new');
	}

	addReference(data = null) {
		this.saisie.outsourceModel(this.moment, this.dateFormat);
		this.RouterHistoryService.data = this.saisie;
		if (data) {
			return this.$state.go('seuilgran.edit', { id: data[0].id });
		}
		return this.$state.go('seuilgran.new');
	}

	addFtpa(data = null) {
		this.saisie.outsourceModel(this.moment, this.dateFormat);
		this.RouterHistoryService.data = this.saisie;
		if (data) {
			return this.$state.go('ftparchivee.edit', { id: data[0].id });
		}
		return this.$state.go('ftparchivee.add');
	}

	async addEssaisReference(references) {
		//this.startLoading();
		//effacer les mesures qui vienne de la référence précédemment sélectionnée
		for (let i = this.saisie.mesures.length - 1; i >= 0; i--) {
			const m = this.saisie.mesures[i];
			if (m.isDansReference && !t.isDansTrame) {
				this.saisie.mesures.splice(i, 1);
			} else if (m && m.sousEssais) {
				const sousEssai = Object.values(m.sousEssais).find((e) => e.idNature === 8);
				if (sousEssai) {
					if (sousEssai && sousEssai.granuloAdditionalInformation && sousEssai.granuloAdditionalInformation.tamis) {
						for (let j = sousEssai.granuloAdditionalInformation.tamis.length - 1; j >= 0; j--) {
							const t = sousEssai.granuloAdditionalInformation.tamis[j];
							if (t.isDisplay && t.isDansReference && !t.isDansTrame) {
								t.isDisplay = false;
							}
						}

						m.recharge = !m.recharge;
					}
				}
			}
		}

		references[0].isAjoutEssai = !references[0].isAjoutEssai;
		if (references[0].isAjoutEssai) {
			//récupérer les mesures de la référence
			let reference = null;
			let mesuresReference = [];
			reference = await this.ReferencesControleService.getReferenceById(references[0].id);
			if (reference) {
				mesuresReference = reference.mesures /*.filter(e => !e.tamis)*/
					.map((m) => {
						return m;
					});
			}

			//ajouter les mesures qui ne sont pas présentes sur la saisie
			for (let i = 0; i < mesuresReference.length; i++) {
				const mes = this.saisie.mesures.find((x) => x.id === mesuresReference[i].essaiId);
				if (!mes) {
					if (!this.saisie.mesures && this.saisie.mesures.length === 0) {
						this.saisie.mesures = [];
					}
					mesuresReference[i].isDansReference = true;
					mesuresReference[i].id = mesuresReference[i].essaiId;
					this.saisie.mesures.push(mesuresReference[i]);
				}
			}

			await this.getSousMesuresCompletBDD();
			//récupérer les tamis de la trame
			let tamisTrames = [];
			if (reference) {
				tamisTrames = reference.mesures
					.filter((e) => e.tamis)
					.map((m) => {
						const res = {
							// idEssai: m.essaiId,
							// idSousEssai: m.sousEssaiId,
							// idSsEssTamis: m.sousEssaiId,
							cumRefus: null,
							ouverture: m.ouvertureTamis,
							passant: null,
							pourcPassant: null,
							pourcRefPartiel: null,
							refus: null
						};

						const indexMesure = this.saisie.mesures.findIndex((e) => e.id === m.essaiId);
						if (indexMesure >= 0) {
							const format = Object.values(this.saisie.mesures[indexMesure].sousEssais).find((e) => e.idNature === 8);
							if (format) {
								if (
									format.granuloAdditionalInformation &&
									format.granuloAdditionalInformation.tamis &&
									format.granuloAdditionalInformation.tamis.constructor === Array
								) {
									const indexTamis = format.granuloAdditionalInformation.tamis.findIndex((e) => e.ouverture === res.ouverture);
									format.granuloAdditionalInformation.tamis[indexTamis].isDansReference = true;
									format.granuloAdditionalInformation.tamis[indexTamis].isDisplay = true;
								}
							}
						}

						return res;
					});
			}

			await this.filterEssaiTrame(tamisTrames);

			if (this.saisie.reference) {
				await this.changeReferentielActu(this.saisie.reference.id, false);
			}
		}
	}

	async addEssaisTrame(trames) {
		await this.reloadTrame();
	}

	async propositionModal(saisie) {
		const modalMsg = 'PRELEVEMENTS.BEST_TRAME_AND_REF';
		const modalInstance = this.ModalService.confirm({
			modalTitle: this.$translate.instant(modalMsg + '.TITLE'),
			modalMsg: this.$translate.instant(modalMsg + '.MESSAGE'),
			headerClass: 'modal-info'
		});
		await modalInstance.result.then(() => this.propositionBestTrameAndRef(saisie));
	}

	async propositionBestTrameAndRef(saisie) {
		// on récupère le producteur et le produit et la liste de trame
		if (!(saisie.producteur && saisie.produit)) {
			return;
		}

		if (this.trames.length > 0) {
			await this.propositionBestTrame();
		}
		if (this.references.length > 0) {
			await this.propositionBestRef();
		}
	}

	async propositionBestTrame() {
		const saisie = angular.copy(this.saisie);
		let trames = angular.copy(this.trames);

		if (trames.length > 0) {
			trames = await this.PropositionBestTrameRefService.getBestTrame(trames, saisie);
			// si plusieurs, on prend la 1ère par libellé.
			const trameSelected = trames.sort((a, b) => a.libelle - b.libelle)[0];

			if (trameSelected) {
				this.trames.find((x) => x.id === trameSelected.id).selected = true;
				if (this.idTrameSelected && this.idTrameSelected.length === 0) {
					this.idTrameSelected.push(trameSelected);
				}

				await this.reloadTrame();
			}
		}
	}

	async propositionBestRef() {
		const saisie = angular.copy(this.saisie);
		let refs = angular.copy(this.references);

		if (refs.length > 0) {
			refs = this.PropositionBestTrameRefService.getBestRef(refs, saisie);
			// si plusieurs, on prend la 1ère par libellé.
			const refSelected = refs.sort((a, b) => a.libelle - b.libelle)[0];

			if (refSelected) {
				this.references.find((x) => x.id === refSelected.id).selected = true;
				this.selectedRef.push(refSelected);
				await this.onSelectReference();
			}
		}
	}

	async getBornesFromFTPA(idFtpaSelected = null) {
		// Si on a pas d'id alors on va chercher la FTPA la plus récente
		if (idFtpaSelected === null) {
			this.selectedByFTPA = !this.selectedByFTPA;

			if (angular.copy(this.selectedFtpa) !== null && angular.copy(this.selectedFtpa.length > 0)) {
				const oldFtpa = this.ftpa.find((x) => x.id === this.selectedFtpa[0].id);
				oldFtpa.selected = false;
				this.selectedFtpa = null;
			}

			if (angular.copy(this.selectedByFTPA) === true) {
				// On va chercher les bornes de la derniere FTPA
				const result = await this.FTPArchiveeService.getFTPAByFilters(
					this.saisie.date,
					this.saisie.produit.id,
					this.saisie.producteur.id,
					null
				);

				this.setSelectedFtpa(result[0].idFtpa);
				this.saisie.ftpa.id = result[0].idFtpa;

				this.selFtpa = false;
				this.saisie.bornes = result;
				this.selectedRef = [];
				delete this.saisie.reference.id;
				// On recharge le front avec les nouvelles bornes
				await this.changeReferentielActu(null, false);
			} else {
				await this.changeReferentielActu(this.saisie.reference.id, true);
			}
			// Sinon on va chercher celle qui nous interesse
		} else {
			this.selFtpa = true;
			this.selectedByFTPA = false;

			const result = await this.FTPArchiveeService.getFTPAByFilters(
				this.saisie.date,
				this.saisie.produit.id,
				this.saisie.producteur.id,
				idFtpaSelected
			);

			this.saisie.bornes = result;
			delete this.saisie.reference.id;
			await this.changeReferentielActu(null, false);
		}
	}

	setSelectedFtpa(idFtpa) {
		const newFtpa = this.ftpa.find((x) => x.id === idFtpa);
		newFtpa.selected = true;
	}

	async checkGranuloMateriel() {
		let idMaterielGran = null;
		if (this.allMesuresMateriels) {
			for (const item of Object.values(this.allMesuresMateriels)) {
				if (item && item.length > 0) {
					for (let i = 0; i < item.length; i++) {
						const materielEssai = item[i];
						const essai = this.saisie.mesures.find((x) => x.id == materielEssai.idEssai);
						const sousEssai = Object.values(essai.sousEssais);
						const isGran = sousEssai.find((x) => x.idNature == 8) != null;
						if (isGran) {
							idMaterielGran = materielEssai.idMateriel;
						}
					}
				}
			}
			if (idMaterielGran) {
				const materiel = await this.MaterielService.getOneMaterielById(idMaterielGran);
				if (materiel.configurationBalance?.id) {
					this.configurationBalanceMateriel = materiel.configurationBalance;
				}
			}
		}
	}
}
