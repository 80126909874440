// on importe le helper qui nous permet de récupérer les validateurs
import validatorBuilder from 'validator-builder';
let siteAccreditaionValidator = null;

export default class SiteCertification {
	constructor(data) {
		data = data || {};
		this.certificatGenerique = null;
		this.dateDebut = data.dateDebut || null;
		this.dateFin = data.dateFin || null;
		this.essais = data.essais || [];
		this.id = data.Id || null;
		this.idCertificatGen = data.idCertificatGen || null;
		this.idSiteAccrediteur = data.idSiteAccrediteur || null;
		this.idSiteCertifie = data.idSiteCertifie || null;
		this.numCertificat = data.numCertificat || null;
		this.produits = data.produits || [];
		this.siteAccrediteur = data.siteAccrediteur || null;
		this.siteCertifie = data.siteCertifie || null;
	}

	// cette methode, en conjonction avec ng-fluent-validation (porté par blocks.validation)
	// permet de passer l'ensemble des règles de validation sur le modèle
	// et retourne l'état de validité de celui-ci
	isValid() {
		siteAccreditaionValidator = siteAccreditaionValidator || validatorBuilder.getInstanceFor('SiteAccreditation');
		const validationResults = siteAccreditaionValidator.validate(this);
		return validationResults.isValid;
	}
}
