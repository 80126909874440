export default function Configure($stateProvider) {
    $stateProvider.state('affectation-poste-pesee', {
        parent: 'gestion',
        url: '/affectation-poste-pesee',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.OUTILS" | translate }}'
        }
    });

    $stateProvider.state('affectation-poste-pesee.edit', {
        url: '/',
        template: '<affectation-poste-pesee></affectation-poste-pesee>',
        rights: { domain: 'postepesee', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "AFFECTATION_POSTE_PESEE.TITLE" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
