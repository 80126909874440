import InerteRegistreAdmissionConfig from './_config/inerte.registre.admission.config';
import InerteRegistreAdmissionRoutes from './_config/inerte.registre.admission.routes';
import InerteRegistreAdmissionComponent from './components/inerte-registre-admission';
import InerteRegistreAdmissionService from './services/inerte.registre.admission.service';

const moduleName = 'app.parametrageGeneral.inerteRegistreAdmission';

angular
    .module(moduleName, [])
    .config(InerteRegistreAdmissionConfig)
    .config(InerteRegistreAdmissionRoutes)
    .service('InerteRegistreAdmissionService', InerteRegistreAdmissionService)
    .component('inerteRegistreAdmission', InerteRegistreAdmissionComponent);

export default moduleName;
