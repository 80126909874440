import produitsCommerciauxController from './produits.commerciaux.controller';

export default {
    bindings: {
        producteurIds: '<',
        model: '=',
        produit: '=',
        app: '<'
    },
    template: require('./produits.commerciaux.html'),
    controller: produitsCommerciauxController
};
