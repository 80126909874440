(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.datepicker')
        .directive('acDatepicker', acDatepicker);

    acDatepicker.$inject = ['$filter', '$timeout', 'moment', 'ValidationService', 'AcDatepickerService'];

    /* @ngInject */
    function acDatepicker($filter, $timeout, moment, ValidationService, AcDatepickerService) {

        var directive = {
            restrict: 'A',
            require: ['acDatepicker', 'ngModel'],
            controller: 'AcDatepickerController',
            controllerAs: 'acDatepickerCtrl',
            templateUrl: 'ac-datepicker.tpl.html',
            scope: true, // on isole le scope
            bindToController: {
                id: '@?',
                name: '@?',
                acDatepickerDisabled: '=?',
                config: '=?acDatepicker',
                model: '=ngModel',
                ngModelName: '@ngModel',
                modelName: '@?',
                acAutofocus: '@?',
                acDatepickerValidationBefore: '@?',
                acDatepickerValidationAfter: '@?',
                acDatepickerValidationStrict: '=?',
                acDatepickerValidationMessage: '@?',
                ngBlurEvent: '&'
            },
            link: link
        };

        return directive;

        function link(scope, element, attrs, ctrls) {
            var acDatepickerCtrl = ctrls[0];
            var ngModelCtrl = ctrls[1];
            var inputNgModelCtrl, timer, config;

            scope.onBlur = onBlur;

            var registeredEvents = [
                scope.$on('$destroy', dispose)
            ];

            init();

            function init() {
                // on publie le ngModelController au controller de la directive
                acDatepickerCtrl.ngModelCtrl = ngModelCtrl;
                inputNgModelCtrl = acDatepickerCtrl[acDatepickerCtrl.inputModelCtrlName];
                config = acDatepickerCtrl.config;

                // on indique un formatter pour transformer les données de l'extérieur en date
                ngModelCtrl.$formatters.push(acDatepickerCtrl.formatInputModel);

                // mise en place de la validation par défaut le cas échéant
                if (attrs.hasOwnProperty('acValidation') && !attrs.hasOwnProperty('acNoDatepickerValidation')) {
                    setValidatorRules();
                }
            }

            // Default onBlur : complète le contenu du datepicker
            function onBlur(e) {
                timer = $timeout(function () {
                    var input = angular.element(e.target);
                    if (input) {
                        var value = angular.copy(acDatepickerCtrl.inputModel);

                        var format = config.format;
                        var output = value;

                        if (value !== undefined) {
                            if (moment.isMoment(value)) {
                                output = $filter('date')(value.toDate(), format);
                            }
                            else if (angular.isDate(value)) {
                                output = $filter('date')(value, format);
                            }
                        }

                        if (output && output !== input.val())
                            input.val(output);
                        
                        if (acDatepickerCtrl.ngBlurEvent)
                            acDatepickerCtrl.ngBlurEvent(input);
                    }
                });
            }

            function setValidatorRules() {
                // s'il y a un modelName on le récupère
                var binding = ValidationService.createBinding(acDatepickerCtrl.modelName || acDatepickerCtrl.ngModelName);

                // si la propriété est définie, on la force
                if (attrs.propertyName !== undefined && attrs.propertyName !== '' && attrs.propertyName !== null) {
                    binding.Property = attrs.propertyName;
                }

                if (binding) {
                    var validator = ValidationService.getValidator(binding.Model);

                    if (validator) {
                        validator.ruleFor(binding.Property)
                            .must(AcDatepickerService.validDate(config))
                            .withMessage('DATE_VALIDATION_WARNING');

                        setValidatorRulesForLinkedProperty(validator, binding.Property);
                    }
                }
            }

            function setValidatorRulesForLinkedProperty(validator, bindingProperty) {
                if (validator) {

                    var message = acDatepickerCtrl.acDatepickerValidationMessage || 'DATE_VALIDATION_COMPARE_WARNING';

                    //la valeur par défaut est false sauf si l'attribut est posé sans valeur définie (sinon on prend la valeur passée)
                    acDatepickerCtrl.acDatepickerValidationStrict = attrs.hasOwnProperty('acDatepickerValidationStrict') ? acDatepickerCtrl.acDatepickerValidationStrict !== false : false;

                    if (acDatepickerCtrl.acDatepickerValidationBefore) {
                        validator.ruleFor(bindingProperty)
                            .must(AcDatepickerService.compareDates(config, acDatepickerCtrl.acDatepickerValidationBefore, acDatepickerCtrl.acDatepickerValidationStrict, true))
                            .withMessage(message);
                    }

                    if (acDatepickerCtrl.acDatepickerValidationAfter) {
                        validator.ruleFor(bindingProperty)
                            .must(AcDatepickerService.compareDates(config, acDatepickerCtrl.acDatepickerValidationAfter, acDatepickerCtrl.acDatepickerValidationStrict))
                            .withMessage(message);
                    }
                }
            }

            function dispose() {
                angular.forEach(registeredEvents, function (unregisterEvent) {
                    unregisterEvent();
                });

                if (timer !== undefined) {
                    $timeout.cancel(timer);
                    timer = undefined;
                }
            }
        }
    }

})(angular);