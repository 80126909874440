export default function Configure($stateProvider) {
    $stateProvider.state('vehicules', {
        parent: 'gestion',
        url: '/vehicules',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.TRANSPORT" | translate }}'
        }
    });

    $stateProvider.state('vehicules.list', {
        url: '/list',
        template: '<vehicules></vehicules>',
        rights: { domain: 'vehicule', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "VEHICULES.BREADCRUMBS.VEHICULES_LIST" | translate}}'
        },
        navigation: {
            menu: 'vehicule',
            translate: 'VEHICULES.BREADCRUMBS.VEHICULES_LIST',
            order: 9
        },
        search: 'VEHICULES.BREADCRUMBS.VEHICULES_LIST'
    });

    $stateProvider.state('vehicules.list.detail', {
        url: '/{id}',
        template: '<vehicule-detail></vehicule-detail>',
        rights: { domain: 'vehicule', right: 'read' },
        ncyBreadcrumb: {
            parent: 'vehicules.list',
            label: '{{ "VEHICULES.BREADCRUMBS.VEHICULE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('vehicules.new', {
        url: '/new',
        template: '<vehicule-form></vehicule-form>',
        rights: { domain: 'vehicule', right: 'create' },
        ncyBreadcrumb: {
            parent: 'vehicules.list',
            label: '{{ "VEHICULES.BREADCRUMBS.VEHICULE_NEW" | translate}}'
        },
        search: 'VEHICULES.BREADCRUMBS.VEHICULE_NEW'
    });

    $stateProvider.state('vehicules.edit', {
        url: '/{id}/edit',
        template: '<vehicule-form></vehicule-form>',
        rights: { domain: 'vehicule', right: 'update' },
        ncyBreadcrumb: {
            parent: 'vehicules.list',
            label: '{{ "VEHICULES.BREADCRUMBS.VEHICULE_EDIT" | translate }}'
        }
    });

    $stateProvider.state('vehicules.a-tarer', {
        url: '/a-tarer',
        template: '<vehicules-a-tarer></vehicules-a-tarer>',
        rights: { domain: 'vehicule', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "VEHICULES.BREADCRUMBS.LISTING_TARE" | translate}}'
        },
        navigation: {
            menu: 'vehicule',
            translate: 'VEHICULES.BREADCRUMBS.LISTING_TARE',
            order: 10
        },
        search: 'VEHICULES.BREADCRUMBS.LISTING_TARE'
    });
}

Configure.$inject = ['$stateProvider'];
