(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.treeview')
        .config(AppConfig);
    AppConfig.$inject = ['$translatePartialLoaderProvider'];

    /* @ngInject */
    function AppConfig($translatePartialLoaderProvider) {
        $translatePartialLoaderProvider.setPart('fr-FR', 'blocks.treeview', {
            "TREEVIEW_README": "Configuration du treeview",
            "TREEVIEW_NO_ROOT": "Il n'y a pas d'élément racine dans l'arborescence",
            "TREEVIEW_NO_RESULTS": "La recherche n'a retourné aucun résultat",
            "TREEVIEW_REFRESH_NODE": "Rafraîchir",
            "TREEVIEW_SEARCH": "Rechercher",
            "TREEVIEW_RENAME_NODE": "Renommer",
            "TREEVIEW_EDIT_NODE": "Modifier",
            "TREEVIEW_ADD_NODE": "Ajouter",
            "TREEVIEW_DELETE_NODE": "Supprimer",
            "TREEVIEW_MOVE_NODE_UP": "Monter",
            "TREEVIEW_MOVE_NODE_DOWN": "Descendre",
            "TREEVIEW_ROOT_LABEL": "Racine",
            "TREEVIEW_CONFIG_TITLE": "Configuration",
            "TREEVIEW_ADD_ROOT": "Ajouter un élément 'Racine'",
            "TREEVIEW_EXPAND_ALL": "Ouvrir tous les éléments",
            "TREEVIEW_COLLAPSE_ALL": "Fermer tous les éléments",
            "TREEVIEW_ENABLE_DRAG": "Permettre le glisser-déposer",
            "TREEVIEW_DISABLE_DRAG": "Interdire le glisser-déposer",
            "TREEVIEW_VALIDATION_LABEL_NOTEMPTY": "Le titre d'un élément ne doit pas être vide",
            "TREEVIEW_VALIDATION_UNIQUE_LABEL": "Il existe déjà un élément du nom de \"{{ label }}\" dans ce dossier",
            "TREEVIEW_VALIDATION_CANNOT_DROP_IN_SAME_PARENT": "Il est impossible de déposer un item dans son propre dossier",
            "TREEVIEW_VALIDATION_TOO_LONG_50": "La longueur maximum du champ est 50 caractères",
            "TREEVIEW_VALIDATION_SPECIAL_CHARACTERS": "Seuls les caractères alphanumériques, les espaces et les symboles _ et ' sont autorisés",
            "TREEVIEW_NO_ACTIONS": "Aucune action disponible",
            "TREEVIEW_BUTTON_SAVE": "Sauvegarder",
            "TREEVIEW_BUTTON_CANCEL": "Annuler"
        });

        $translatePartialLoaderProvider.setPart('en-US', 'blocks.treeview', {
            "TREEVIEW_README": "Treeview configuration",
            "TREEVIEW_NO_ROOT": "There is no root elements in the treeview",
            "TREEVIEW_NO_RESULTS": "Search did not return any results",
            "TREEVIEW_REFRESH_NODE": "Refresh",
            "TREEVIEW_SEARCH": "Search",
            "TREEVIEW_RENAME_NODE": "Rename",
            "TREEVIEW_EDIT_NODE": "Edit",
            "TREEVIEW_ADD_NODE": "Add",
            "TREEVIEW_DELETE_NODE": "Remove",
            "TREEVIEW_MOVE_NODE_UP": "Up",
            "TREEVIEW_MOVE_NODE_DOWN": "Down",
            "TREEVIEW_ROOT_LABEL": "Root",
            "TREEVIEW_CONFIG_TITLE": "Configuration",
            "TREEVIEW_ADD_ROOT": "Addition of a 'Root' element",
            "TREEVIEW_EXPAND_ALL": "Open all elements",
            "TREEVIEW_COLLAPSE_ALL": "Close all elements",
            "TREEVIEW_ENABLE_DRAG": "Allow drag&drop",
            "TREEVIEW_DISABLE_DRAG": "Prevent drag&drop",
            "TREEVIEW_VALIDATION_LABEL_NOTEMPTY": "An element title must not be empty",
            "TREEVIEW_VALIDATION_UNIQUE_LABEL": "There already exists an element named \"{{ label }}\" in this folder",
            "TREEVIEW_VALIDATION_CANNOT_DROP_IN_SAME_PARENT": "It is not possible to drop an element in it's own folder",
            "TREEVIEW_VALIDATION_TOO_LONG_50": "Field max length is 50 characters",
            "TREEVIEW_VALIDATION_SPECIAL_CHARACTERS": "Only alphanumeric characters, spaces, and _ and ' symbols are allowed",
            "TREEVIEW_NO_ACTIONS": "No action possible",
            "TREEVIEW_BUTTON_SAVE": "Save",
            "TREEVIEW_BUTTON_CANCEL": "Cancel"
        });

        $translatePartialLoaderProvider.setPart('es-ES', 'blocks.treeview', {
            "TREEVIEW_README": "Configuración del treeview",
            "TREEVIEW_NO_ROOT": "No hay elementos raíces en la arborescencia",
            "TREEVIEW_NO_RESULTS": "Ningún resultado",
            "TREEVIEW_REFRESH_NODE": "Actualizar",
            "TREEVIEW_SEARCH": "Buscar",
            "TREEVIEW_RENAME_NODE": "Renombrar",
            "TREEVIEW_EDIT_NODE": "Modificar",
            "TREEVIEW_ADD_NODE": "Añadir",
            "TREEVIEW_DELETE_NODE": "Eliminar",
            "TREEVIEW_MOVE_NODE_UP": "Subir",
            "TREEVIEW_MOVE_NODE_DOWN": "Bajar",
            "TREEVIEW_ROOT_LABEL": "Raíz",
            "TREEVIEW_CONFIG_TITLE": "Configuración",
            "TREEVIEW_ADD_ROOT": "Añadir un elementos 'Raíz'",
            "TREEVIEW_EXPAND_ALL": "Abrir todos los elementos",
            "TREEVIEW_COLLAPSE_ALL": "Cerrar todos los elementos",
            "TREEVIEW_ENABLE_DRAG": "Permitir click and slide",
            "TREEVIEW_DISABLE_DRAG": "Prohibir el click and slide",
            "TREEVIEW_VALIDATION_LABEL_NOTEMPTY": "El título de un elemento no debe ser vacío",
            "TREEVIEW_VALIDATION_UNIQUE_LABEL": "Ya existe un elemento con el nombre \"{{ label }}\" en este expediente",
            "TREEVIEW_VALIDATION_CANNOT_DROP_IN_SAME_PARENT": "Es imposible depositar un item en su propio expediente",
            "TREEVIEW_VALIDATION_TOO_LONG_50": "La longitud máxima del campo es de 50 carácteres",
            "TREEVIEW_VALIDATION_SPECIAL_CHARACTERS": "Se autorizan únicamente carácteres alfanuméricos, espacios y símbolos _ y ' ",
            "TREEVIEW_NO_ACTIONS": "Ninguna acción disponible",
            "TREEVIEW_BUTTON_SAVE": "Guardar",
            "TREEVIEW_BUTTON_CANCEL": "Cancelar"
        });

        $translatePartialLoaderProvider.setPart('nl-BE', 'blocks.treeview', {
            "TREEVIEW_README": "De boomstructuur configureren",
            "TREEVIEW_NO_ROOT": "Er is geen wortelelement in de boom",
            "TREEVIEW_NO_RESULTS": "De zoekopdracht leverde geen resultaten op",
            "TREEVIEW_REFRESH_NODE": "Vernieuwen",
            "TREEVIEW_SEARCH": "Onderzoek",
            "TREEVIEW_RENAME_NODE": "Herdopen",
            "TREEVIEW_EDIT_NODE": "Modificeren",
            "TREEVIEW_ADD_NODE": "Toevoegen",
            "TREEVIEW_DELETE_NODE": "Verwijderen",
            "TREEVIEW_MOVE_NODE_UP": "Stijgen",
            "TREEVIEW_MOVE_NODE_DOWN": "Afdalen",
            "TREEVIEW_ROOT_LABEL": "Boomwortel",
            "TREEVIEW_CONFIG_TITLE": "Configuratie",
            "TREEVIEW_ADD_ROOT": "Een 'root'-element toevoegen",
            "TREEVIEW_EXPAND_ALL": "Alle objecten openen",
            "TREEVIEW_COLLAPSE_ALL": "Alle objecten sluiten",
            "TREEVIEW_ENABLE_DRAG": "Slepen en neerzetten toestaan",
            "TREEVIEW_DISABLE_DRAG": "Slepen en neerzetten verbieden",
            "TREEVIEW_VALIDATION_LABEL_NOTEMPTY": "De titel van een artikel mag niet leeg zijn",
            "TREEVIEW_VALIDATION_UNIQUE_LABEL": "Er is al een item met de naam \"{{ label }}\" Er is al een item met de naam X in deze map",
            "TREEVIEW_VALIDATION_CANNOT_DROP_IN_SAME_PARENT": "Het is onmogelijk om een item in uw eigen map te deponeren",
            "TREEVIEW_VALIDATION_TOO_LONG_50": "De maximale lengte van het veld is 50 tekens",
            "TREEVIEW_VALIDATION_SPECIAL_CHARACTERS": "Alleen alfanumerieke tekens, spaties en symbolen _ en ' zijn toegestaan",
            "TREEVIEW_NO_ACTIONS": "Geen actie beschikbaar",
            "TREEVIEW_BUTTON_SAVE": "Opslaan",
            "TREEVIEW_BUTTON_CANCEL": "Annuleren"
        });

        $translatePartialLoaderProvider.addPart('blocks.treeview');
    }
})(angular);