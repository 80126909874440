export default function Configure($stateProvider) {
    $stateProvider.state('indices-revision', {
        parent: 'gestion',
        url: '/indices-revision',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('indices-revision.list', {
        url: '/list',
        template: '<indices-revision></indices-revision>',
        rights: { domain: 'indrevision', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "INDICES_REVISION.BREADCRUMBS.INDICES_REVISION_LIST" | translate}}'
        },
        // on affiche la liste des grilles d'indices de révision dans la navigation
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'INDICES_REVISION.BREADCRUMBS.INDICES_REVISION_LIST',
            order: 38
        },
        // la liste des grilles d'indices de révision fait partie des points d'entrée recherchables dans la navigation
        search: 'INDICES_REVISION.BREADCRUMBS.INDICES_REVISION_LIST'
    });

    $stateProvider.state('indices-revision.list.detail', {
        url: '/{id}',
        template: '<indice-revision-detail></indice-revision-detail>',
        rights: { domain: 'indrevision', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher grilles d'indices de révision > détail
            parent: 'indices-revision.list',
            label: '{{ "INDICES_REVISION.BREADCRUMBS.INDICE_REVISION_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('indices-revision.new', {
        url: '/new',
        template: '<indice-revision-form></indice-revision-form>',
        rights: { domain: 'indrevision', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher indices révision > ajouter
            parent: 'indices-revision.list',
            label: '{{ "INDICES_REVISION.BREADCRUMBS.INDICE_REVISION_NEW" | translate}}'
        },
        // l'ajout d'une grilles d'indices de révision fait partie des points d'entrée recherchables dans l'application
        search: 'INDICES_REVISION.BREADCRUMBS.INDICE_REVISION_NEW'
    });

    $stateProvider.state('indices-revision.edit', {
        url: '/{id}/edit',
        template: '<indice-revision-form></indice-revision-form>',
        rights: { domain: 'indrevision', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher indices révision > modifier
            parent: 'indices-revision.list',
            label: '{{ "INDICES_REVISION.BREADCRUMBS.INDICE_REVISION_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
