import { StateService } from '@uirouter/angularjs';
import { LoDashStatic } from 'lodash';
import SyntheseEntityService from '../../../../services/synthese.entity.service';


export abstract class Synthese {
    $scope: ng.IScope;
    SyntheseEntityService: SyntheseEntityService;
    $state: StateService;
    $translate: any;
    $timeout: ng.ITimeoutService;
    _: LoDashStatic;
    uiGridConstants: uiGrid.IUiGridConstants;
    uiGridTemplate: any;
    watcher: (() => void)[];
    result: any;
    gridApi: uiGrid.IGridApi;
    grid: any;
    loading: boolean;
    synthese: Synthese;
    changeAgg: boolean;
    save: Function;
    generate: Function;
    refresh: boolean = false;
    setting: any;
    formatters: any = {};
    moment: any;
    maxAttemps: number = 10;
    attemps: number = 0;
    name: string;
    notification: any;
    gridOptions: any = {
        showGridFooter: true,
        enableGridMenu: true,
        enableRowHashing: false,
        flatEntityAccess: true,
        showColumnFooter: true,
        enableSorting: true,
        enableFiltering: true,
        // PDF Options
        exporterMenuPdf: false,
        exporterPdfDefaultStyle: { fontSize: 9 },
        exporterPdfTableStyle: { margin: [30, 30, 30, 30] },
        exporterPdfTableHeaderStyle: { fontSize: 10, bold: true, italics: true, color: 'red' },
        exporterPdfHeader: { text: 'My Header', style: 'headerStyle' },
        exporterPdfFooter: function (currentPage: any, pageCount: any) {
            return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
        },
        exporterPdfCustomFormatter: function (docDefinition: any) {
            docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
            docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
            return docDefinition;
        },
       
        exporterPdfOrientation: 'landscape',
        exporterPdfPageSize: 'A4',
        exporterPdfMaxGridWidth: 720,
        // End Pdf Options
        exporterMenuExcel: true,
        exporterMenuSelectedData: false,
        exporterMenuVisibleData: false,
        excessColumns: 12,
        excessRows: 12,
        groupingShowCounts: false
    };
    SyntheseEntitiesService: any;
    constructor(
        $scope: ng.IScope,
        SyntheseEntityService: SyntheseEntityService,
        $state: StateService,
        $translate: any,
        $timeout: ng.ITimeoutService,
        _: LoDashStatic,
        uiGridConstants: uiGrid.IUiGridConstants,
        uiGridTemplate: any,
        moment: any,
        name: string,
        notification: any
    ) {
        this.$scope = $scope;
        this.SyntheseEntityService = SyntheseEntityService;
        this.$state = $state;
        this.$translate = $translate;
        this.$timeout = $timeout;
        this._ = _;
        this.uiGridConstants = uiGridConstants;
        this.uiGridTemplate = uiGridTemplate;
        this.moment = moment;
        this.gridOptions.exporterCsvFilename = name + '.csv';
        this.gridOptions.exporterExcelFilename = name + '.xlsx';
        this.gridOptions.exporterExcelSheetName = name;
        this.gridOptions.groupingNullLabel = this.$translate.instant('NONE');
        this.notification = notification;
        console.log(this);
    }

    protected handleResize() {
        if (this.gridApi) {
            this.$timeout(() => {
                this.gridApi.core.handleWindowResize();
            }, 50);
        }
    }

    protected refreshGrid() {
        this.refresh = true;
        this.$timeout(() => {
            this.refresh = false;
        }, 0);
    }

    private resetAttemp() {
        this.attemps = 0;
    }

    protected setWatcherContent(callback?: () => void) {
        if (!this.gridApi && this.attemps < this.maxAttemps) {
            this.attemps++;
            this.$timeout(() => {
                this.setWatcherContent(callback);
            }, 500);
            return;
        }
        if (this.attemps === this.maxAttemps) {
            console.error('To many attemps to access gridApi. Is grid initialized ?');
            this.resetAttemp();
            return;
        }
        if (callback) {
            callback();
        }
    }

    protected excelFormatter = (grid: uiGrid.IGridApi, workbook: any, docDefinition: any) => {
        var stylesheet = workbook.getStyleSheet();

        var dateFormatter = stylesheet.createSimpleFormatter('date');
        this.formatters['date'] = dateFormatter;
        var dateFormatTest = stylesheet.createFormat({
            format: 'mm/dd/yyyy'
        });
        this.formatters['dateTest'] = dateFormatTest;
        var numberFormatter = stylesheet.createFormat({
            format: '#,##0.00'
        });
        this.formatters['numb'] = numberFormatter;
        var boldStyle = stylesheet.createFontStyle({
            size: 12,
            fontName: 'Calibri',
            bold: true
        });
        const rupt = stylesheet.createFormat({
            font: boldStyle.id,
            fill: { type: 'pattern', patternType: 'solid', fgColor: 'D0E0E3FF' }
        });
        this.formatters['rupt'] = rupt;
        var header = stylesheet.createFormat({
            font: { size: 12, fontName: 'Calibri', bold: true },
            alignment: { wrapText: false, horizontal: 'center' },
            fill: { type: 'pattern', patternType: 'solid', fgColor: 'F29400' }
        });
        this.formatters['header'] = header;
        Object.assign(docDefinition.styles, this.formatters);

        return docDefinition;
    };

    protected excelHeaderFormat = (header: any, workbook: any, sheetdata: any, docDefinition: any) => {
        console.warn(
            "[excelHeaderFormat]: Attention cette methode est en cours d'approuvement par l'auteur de la librairie elle sera valable dans une prochaine mise à jour"
        );
       
        // Attention cette methode est en cours d'approuvement par l'auteur de la librairie elle sera valable dans une prochaine mise à jour
        return { metadata: { style: this.formatters['header'].id } };
    };

    protected excelHeader = (grid: uiGrid.IGridApi, workbook: any, sheet: any, docDefinition: any) => {
     
        var headers = this.result.results[0].grid.header;
        var cols: any = [];
        var merge: { [key: string]: string[] } = {};
        let i = 0;
        this._.forEach(headers, h => {
            const adress = this.columnAdress(i + 1) + '1';
            if (!h.group) {
                cols.push({ value: '', metadata: { style: this.formatters['header'].id } });
            } else {
                if (!merge[h.group]) merge[h.group] = [];
                merge[h.group].push(adress);
                cols.push({ value: h.group, metadata: { style: this.formatters['header'].id } });
            }
            i++;
        });
        this._.forEach(merge, (value: string[]) => {
            if (value.length >= 2) {
                sheet.mergeCells(value[0], value[value.length - 1]);
            }
        });
        sheet.data.push(cols);
    };

    setFooter() {
        let footer = [];
        const columns = this.result.results[0].grid.body[0].columns;
        const keys = Object.keys(columns);
        this.result.results[0].grid.footer.forEach((item: any) => {
            let cols = [];
            let notSet: boolean = true;
            for (let i = 0; i < keys.length; i++) {
                const col = keys[i];
                if(!item.columns.hasOwnProperty(col)) {
                 cols.push("");
                }else{
                    if(notSet && cols[i - 1] == "") {
                        console.log(notSet, item.lineName)
                        cols[i - 1] = item.lineName;
                    }
                    cols.push(item.columns[col].value);
                    notSet= false;
                }
        }   
        footer.push(cols)
        }, this);
        // return array;
        return footer;
    }

    protected fieldFormat = (grid: uiGrid.IGridApi, row: any, gridCol: any, cellValue: any, type: string) => {
        /* console.log(gridCol, cellValue);
        if (cellValue === gridCol.displayName) {
            console.log(cellValue, gridCol.displayName, cellValue === gridCol.displayName, i);
            i++;
            return { metadata: { style: this.formatters['header'] } };
        } */
                if (type === 'excel' || typeof type === 'undefined') {
            if ('treeLevel' in row) {
                return { metadata: { style: this.formatters['rupt'].id } };
            }
            if (typeof cellValue === 'number') {
                return { value: Number(cellValue.round(2)), metadata: { type: 'number', style: this.formatters['numb'].id } };
            }
            if (typeof cellValue === 'string' && cellValue.match(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/)) {
                var date = this.moment(cellValue, 'DD/MM/YYYY');
                date = date.set('hour', 1);

                
                const elem = { value: date.toDate().getTime(), metadata: { type: 'date', style: this.formatters['date'].id } }
                return elem;
            }
        }
        return null;
    };

    protected onRegisterApi = (gridApi: uiGrid.IGridApi) => {
        this.gridApi = gridApi;
        if (this.synthese && this.synthese.setting) {
            this.$timeout(() => {
                const setting = JSON.parse(this.synthese.setting.setting);
                gridApi.saveState.restore(this.$scope, setting);
            }, 10);
        }
        //gridApi.core.refresh();
    };

    protected roundTreeAggregation(agg: any) {
        if (agg.type && agg.type === 'count' && agg.groupVal && agg.groupVal === 'Null') {
            agg.rendered = 'Aucun';
            agg.groupVal = 'Aucun';
            return;
        }
        if (!agg.type || agg.type === 'count') return;
        if (typeof agg.value === 'number') agg.value = agg.value.round(2);
        if (typeof agg.value === 'undefined' || agg.value === null) {
            agg.value = '';
            agg.label = '';
        }
        return (agg.rendered = agg.label + agg.value);
    }

    protected toColumnDefinition(header: any, callback: (header: any, columnDef: any) => void) {
        let columnDef: any = {};
        if (header && header.grouping) {
            columnDef.grouping = { groupPriority: header.grouping.groupPriority };
            columnDef.sort = {
                direction: this.uiGridConstants.ASC,
                priority: 0
            };
        }
        if (header && header.filter) {
            columnDef.filter = {
                placeholder: this.$translate.instant(header.filter.placeholder)
            };
        }
        if (header && header.filters) {
            columnDef.filters = header.filters.map((f: any) => {
                f.placeholder = this.$translate.instant(f.placeholder);
                return f;
            });
        }
        columnDef.displayName = header.displayName;
        columnDef.pinnedLeft = header.pinnedLeft;
        columnDef.pinnedRight = header.pinnedRight;
        if (header.sort) {
            columnDef.sort = header.sort;
        }
        if (header.type) {
            columnDef.type = header.type;
        }
        if (header.group) {
            columnDef.group = header.group;
        }
        columnDef.headerCellTemplate = this.uiGridTemplate.synthentsHeader;
        columnDef.minWidth = 80;
        //columnDef.maxWidth = 120;
        columnDef.width = '*';
        columnDef.originalField = header.field;
        columnDef.field = `columns.${header.field}.value`;
        columnDef.headerCellClass = 'bg-primary';
        columnDef.cellFilter = 'gridRound:2';
        columnDef.cellClass = this.setCellClass;
        columnDef.footerCellFilter = 'gridRound:2';
        columnDef.customTreeAggregationFinalizerFn = this.roundTreeAggregation;
        if (callback) {
            callback(header, columnDef);
        }
        return columnDef;
    }

    protected setCellClass(grid: any, row: any, col: any, rowRenderIndex: any, colRenderIndex: any) {
        const f = col.colDef.originalField;
        if (!row.entity.columns) {
            return;
        }
        const o = row.entity.columns[f];
        if (!o) {
            return;
        }
        if (o.type === 1) {
            return 'grid-cell-right';
        }
    }

    private columnAdress(col: number): string {
        if (col <= 26) {
            return String.fromCharCode(col + 64);
        }
        let div = col / 26;
        let mod = col % 26;
        if (mod == 0) {
            mod = 26;
            div--;
        }
        return this.columnAdress(div) + this.columnAdress(mod);
    }

    protected createFooterTemplate(footer: any) {
        let template = '';
        for (let i = 0; i < footer.length; i++) {
            template += this.uiGridTemplate.synthentsFooter(i);
        }
        return template;
    }

    protected aggregationFooter(row: any, fieldValue: any, footer: any) {
        var col = fieldValue.colDef;
        var res = [];
        for (let i = 0; i < footer.length; i++) {
            const f = footer[i];
            let val = f.columns[col.originalField] ? f.columns[col.originalField].value : null;
            // @ts-ignore
            if (typeof val === 'number') val = val.round(2);
            res[i] = {
                name: `${f.lineName} : `,
                val: `${val ? val : '-'}`,
                style: {
                    height: '30px',
                    background: f.background,
                    color: f.text
                }
            };
        }
        return res;
    }

    protected setColumnDefs({ header, footer }: { header: any; footer: any }) {
        return header.map((x: any) =>
            this.toColumnDefinition(x, (h, column) => {
                column.footerCellTemplate = this.createFooterTemplate(footer);
                if (!h.isAff) {
                    column.aggregationType = (aggregation: any, fieldValue: any) => this.aggregationFooter(aggregation, fieldValue, footer);
                }
            })
        );
    }

    protected SetFooterCellClass() {

    }
    abstract saveSynthese(): void;
    abstract genSynth(): void;

    async ExportPdf(isPaysage) {
        try {
            const footer = this.setFooter(); 
            const grid = this.result.results[0].grid;
            const resultat = await this.SyntheseEntityService.excelExport(grid, footer, this.synthese, true, isPaysage);
            if (resultat) {
                const data = resultat.data;
                const status = resultat.status;
                let headers = resultat.headers;
                // headers = headers();

                const contentType = headers['content-type'];

                const linkElement = document.createElement('a');
                const blob = new Blob([data], { type: contentType });
                const url = window.URL.createObjectURL(blob);
                linkElement.setAttribute('href', url);
                linkElement.setAttribute('download', 'export' + '.pdf');

                const clickEvent = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: false
                });
                linkElement.dispatchEvent(clickEvent);
            }
        } catch (ex) {
            this.notification.error("Microsoft Office n'a pas été détecté sur votre serveur. Veuillez contacter votre administrateur.");
        } finally {
        }
    }

    async ExportExcel() {
        const footer = this.setFooter(); 
        const grid = this.result.results[0].grid;
        const resultat = await this.SyntheseEntityService.excelExport(grid, footer, this.synthese, false);
        if (resultat) {
            const data = resultat.data;
            const status = resultat.status;
            let headers = resultat.headers;
            // headers = headers();

            const contentType = headers['content-type'];

            const linkElement = document.createElement('a');
            const blob = new Blob([data], { type: contentType });
            const url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute('download', 'export' + '.xlsx');

            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(clickEvent);
        }
    }
}
