const typesAVByValue = {
    0: 'achat',
    1: 'vente'
};

const typesAVValuesByName = {
    achat: 0,
    vente: 1
};

export function getTypeAVNamesByValues() {
    return angular.copy(typesAVByValue);
}

export function getTypeAVNamesWithValues() {
    return angular.copy(typesAVValuesByName);
}
