(function () {
    'use strict';

    angular.module('blocks.helpers')
        .service('directivesHelper', directivesHelper);

    function directivesHelper() {
        /* jshint validthis: true */
        this.setDefaultValue = setDefaultValue;
    }

    function setDefaultValue($attr, $defaultValue) {
        var ret = $defaultValue;

        if (angular.isDefined($attr)) {
            ret = $attr;
        }

        return ret;
    }

})();
