import validatorBuilder from 'validator-builder';
import ImageEntete from './entete/image.entete.model';
import ImageCaracteristiques from './caracteristiques/image.caracteristiques.model';

let imageValidator = null;

export default class Image {
    constructor(data = {}) {
        this.id = data.id;
        this.entete = new ImageEntete(data.entete);
        this.caracteristiques = new ImageCaracteristiques(data.caracteristiques);
    }

    isValid() {
        imageValidator = imageValidator || validatorBuilder.getInstanceFor('Image');
        const validationResults = imageValidator.validate(this);
        return validationResults.isValid;
    }
}
