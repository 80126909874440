import validatorBuilder from 'validator-builder';
import { v4 as uuidv4 } from 'uuid';

let offrePropositionValidator = null;

export default class OffreProposition {
    constructor(data) {
        data = data || {};
        this.id = -1;
        this.type = 'offre';
        this.code = data.code;
        this.uniteId = data.uniteId;
        this.modeDeclenchement = data.modeDeclenchement;
        this.libelle = data.libelle || 'Proposition';
        this.producteurs = [];
        this.canUpdate = true;
        this.propositionOrigineId = data.propositionOrigineId || null;
        this.proposition = data.proposition || null;
        this.uuid = data.uuid || uuidv4();
    }

    isValid() {
        offrePropositionValidator = offrePropositionValidator || validatorBuilder.getInstanceFor('OffreProposition');
        const validationResults = offrePropositionValidator.validate(this);
        return validationResults.isValid;
    }
}
