import CompoAddCoef from '../../../../models/CompoAddCoef.model';

export default class FormuleCalculVerificationController {
	/* @ngInject */
	constructor(
		NormesService,
		ProduitsService,
		ElementCaracteristiqueService,
		CoefficientkService,
		$scope,
		$translate,
		notification,
		FormuleService,
		FormuleCalculsService,
		ModalService,
		moment,
		$uibModal
	) {
		this.NormesService = NormesService;
		this.ProduitsService = ProduitsService;
		this.ElementCaracteristiqueService = ElementCaracteristiqueService;
		this.CoefficientkService = CoefficientkService;
		this.$scope = $scope;
		this.$translate = $translate;
		this.notification = notification;
		this.FormuleService = FormuleService;
		this.FormuleCalculsService = FormuleCalculsService;
		this.ModalService = ModalService;
		this.moment = moment;
		this.$uibModal = $uibModal;
		this.listCc = [];
		this.idSelected = -1;
	}

	async $onInit() {
		this.calculDone = true;

		if (this.formule.idComposition) {
			this.first = false;
			this.alreadyCalculated = true;
			this.isCalculated = true;
		} else {
			this.first = true;
			this.alreadyCalculated = false;
			this.isCalculated = false;
		}
		this.isReady = true;
		this.loadNormes();

		this.FormuleCalculsService.init(this.formule, this.parametresFormulation);

		// récuperation de la liste des concrete code du produit
		const produitCc = await this.ProduitsService.getAllProduitCommerciaux(Number.parseInt(this.formule.idProduit), null, null);
		if (produitCc && produitCc.produitsCom && produitCc.produitsCom.length > 0) {
			this.listCc = produitCc.produitsCom;
			this.formule.listCc = angular.copy(produitCc.produitsCom);
		}

		this.$scope.$on('coefKChanged', (e, compo) => {
			this.coefKChanged(compo);
		});

		this.unregister = await this.$scope.$watch(
			() => {
				if (this.formule && this.formule.composants) {
					return this.formule.composants;
				}
			},
			async (newValue, oldValue) => {
				if (!angular.equals(newValue, oldValue)) {
					if (this.timer) {
						clearTimeout(this.timer);
					}
					this.timer = setTimeout(() => {
						if ((!this.first && this.alreadyCalculated) || (this.formule && this.formule.normeSpec)) {
							this.launchCalcul();
						}
						this.first = false;
					}, 1000);
				}
			},
			true
		);

		this.formule.vdc = await this.FormuleCalculsService.getValeurDeControle();
	}

	async launchCalcul() {
		try {
			this.calculDone = false;
			this.isCalculated = false;

			this.FormuleCalculsService.init(this.formule, this.parametresFormulation);

			this.formule.produits = await this.FormuleCalculsService.getProduitFormule();
			const valeursDeReferenceCiments = [];
			const valeursDeReferenceAdditions = [];
			const valeursDeReferenceAdjuvants = [];
			const valeursDeReferenceGranulats = [];
			const valeursDeReferenceEaux = [];
			this.formule.composants
				.filter((e) => e.valeursDeRef)
				.forEach((composant) => {
					for (let i = 0; i < composant.valeursDeRef.length; i++) {
						switch (composant.codeFamille) {
							case 'CIM':
								valeursDeReferenceCiments.push(composant.valeursDeRef[i]);
								break;
							case 'ADD':
								valeursDeReferenceAdditions.push(composant.valeursDeRef[i]);
								break;
							case 'ADJ':
								valeursDeReferenceAdjuvants.push(composant.valeursDeRef[i]);
								break;
							case 'GRAN':
								valeursDeReferenceGranulats.push(composant.valeursDeRef[i]);
								break;
							case 'EAU':
								valeursDeReferenceEaux.push(composant.valeursDeRef[i]);
								break;
						}
					}
				});

			const AllValRef = valeursDeReferenceCiments.concat(
				valeursDeReferenceAdditions,
				valeursDeReferenceAdjuvants,
				valeursDeReferenceGranulats,
				valeursDeReferenceEaux
			);

			this.formule.facteurEmissionCO2 = await this.FormuleCalculsService.getFacteurEmisionCO2();
			this.formule.distanceCO2 = await this.FormuleCalculsService.getDistanceCO2ParProducteur();
			this.formule.composantCiments = await this.FormuleCalculsService.recuperationCiment(valeursDeReferenceCiments);
			this.formule.composantAdditions = await this.FormuleCalculsService.recuperationAddition(valeursDeReferenceAdditions);
			this.formule.composantGranulats = await this.FormuleCalculsService.recuperationGranulat(valeursDeReferenceGranulats);
			this.formule.composantAdjuvants = await this.FormuleCalculsService.recuperationAdjuvant(valeursDeReferenceAdjuvants);
			this.formule.composantEaux = await this.FormuleCalculsService.recuperationEau(valeursDeReferenceEaux);

			if (!this.formule.produitCommerciaux) {
				// s'il y a des concrete code on prend les classes de res et d'expo du produitCc le plus contraignant
				this.formule.produitCommerciaux = await this.FormuleCalculsService.getProduitCommerciaux(AllValRef);
			}
			/**
			 * recuperation classe expo et classe de resistance
			 */

			if (this.formule.produits && this.listCc && this.listCc.length === 0) {
				// s'il n'y a pas de concrete code on prend les classes de res et d'expo du produit
				if (this.formule.produits.produitCaracteristique) {
					this.formule.classeExpo = await this.FormuleCalculsService.recuperationClasseExpo(
						this.formule.produits.produitCaracteristique,
						false
					);
					this.formule.classeResistance = await this.FormuleCalculsService.recuperationClasseResistance(
						this.formule.produits.produitCaracteristique,
						false
					);
					this.formule.classeChlorure = await this.FormuleCalculsService.recuperationClasseChlorure(
						this.formule.produits.produitCaracteristique,
						false
					);
					this.formule.dosageCiment = await this.FormuleCalculsService.recuperationDosageCiment(
						this.formule.produits.produitCaracteristique,
						false
					);
					this.formule.classeSubGran = await this.FormuleCalculsService.recuperationClasseSubGran(
						this.formule.produits.produitCaracteristique,
						false
					);
				}
			} else if (this.formule.produits && this.listCc && this.listCc.length > 0) {
				if (!this.formule.classeExpo) {
					this.formule.classeExpo = await this.FormuleCalculsService.recuperationConcreteCodeExpo();
				}

				if (!this.formule.classeResistance) {
					this.formule.classeResistance = await this.FormuleCalculsService.recuperationConcreteCodeResistance();
				}

				if (!this.formule.classeChlorure) {
					this.formule.classeChlorure = await this.FormuleCalculsService.recuperationConcreteCodeChlorure();
				}

				if (!this.formule.dosageCiment) {
					this.formule.dosageCiment = await this.FormuleCalculsService.recuperationConcreteCodeCiment();
				}

				if (!this.formule.classeSubGran) {
					this.formule.classeSubGran = await this.FormuleCalculsService.recuperationConcreteCodeClasseSub();
				}
			}

			this.formule.vdc = await this.FormuleCalculsService.getValeurDeControle(false);

			await this.FormuleCalculsService.assignationPourAffichage();
			await this.FormuleCalculsService.initCalcul();

			this.FormuleCalculsService.resetCache();
			await this.checkConformiterAlerte();

			this.alreadyCalculated = true;
			this.isCalculated = true;
			this.calculDone = false;
			//console.log(angular.copy(this.formule))
		} catch (error) {
			/*  console.log(error); */
			this.notification.error(this.$translate.instant('FORMULES.CALCUL_ERROR'));
			this.alreadyCalculated = true;
			this.isCalculated = true;
			this.calculDone = false;
		}
	}

	$onDestroy() {
		this.unregister();
	}

	coefKChanged(compo) {
		if (!this.formule.composantAdditions) {
			this.formule.composantAdditions = {};
		}
		this.formule.composantAdditions.coefficientK = compo.selectCoef ? compo.coefk : 0;
		//this.formule.composantAdditions.additionPoids = compo.selectCoef ? compo.poids : 0;
		this.formule.idAddition = compo.selectCoef ? compo.idComposant : null;
		this.formule.coefficientK = compo.selectCoef ? compo.coefk : null;

		const data = new CompoAddCoef({
			idCompo: this.formule.idComposition,
			idAddition: compo.idElement,
			coefK: compo.coefk,
			selected: compo.selectCoef
		});

		if (this.formule.compoAddCoef && this.formule.compoAddCoef.length > 0) {
			const idx = this.formule.compoAddCoef.findIndex((x) => x.idAddition === compo.idElement);
			if (idx > -1) {
				this.formule.compoAddCoef.splice(idx, 1);
			}
		}

		if (!this.formule.compoAddCoef) {
			this.formule.compoAddCoef = [];
		}
		this.formule.compoAddCoef.push(data);

		if (compo.selectCoef) {
			this.selectCoefK(compo);
		}
	}

	selectCoefK(compo) {
		this.formule.compoAddCoef = this.formule.compoAddCoef.map((x) => {
			if (x.idAddition !== compo.idElement) {
				x.selected = false;
			}
			return x;
		});
	}

	async getCoefK(formule, coefKActuel) {
		let caracteristiqueTypeCiment = null;
		let caracteristiqueClasseCiment = null;
		if (formule.composants.find((x) => x.codeFamille === 'CIM')) {
			const paramType = await this.FormuleCalculsService.getCaractParamProg('CarCimType');

			caracteristiqueTypeCiment = formule.composants
				.find((x) => x.codeFamille === 'CIM')
				.valeurCaracteristiques.find((x) => x.code === paramType);

			const paramRes = await this.FormuleCalculsService.getCaractParamProg('CarCimRes');

			caracteristiqueClasseCiment = formule.composants
				.find((x) => x.codeFamille === 'CIM')
				.valeurCaracteristiques.find((x) => x.code === paramRes);
		}

		for (let i = 0; i < formule.composants.length; i++) {
			const element = formule.composants[i];
			if (element.codeFamille === 'ADD' && formule.normeSpec?.id) {
				// recherche des composant Additions
				const add = formule.composants.filter((e) => e.codeFamille === 'ADD');
				const idx = add.findIndex((x) => x.selectCoef === true && x.idComposant === element.idComposant);
				// s'il n'y a pas de composant Addition dans la formule courante
				if (idx === -1) {
					//on selectionne par defaut l'addition
					element.selectCoef = false;
				} else {
					// sinon remet le booléen enregistrer
					element.selectCoef = element.selectCoef;
				}
				//si une norme est selectionner on recupere le coefficientK correspondant
				if (formule.normeSpec && formule.normeSpec.id) {
					let coefK;
					if (coefKActuel && typeof parseFloat(element.coefk) === 'number') {
						// element.coefK = coefKActuel;
					} else {
						const lstMatchCoeffK = element.coefficientK
							? element.coefficientK
									.filter((x) => x.normeSpecifique.id && formule.normeSpec.id && x.normeSpecifique.id === formule.normeSpec.id)
									.map((x) => {
										x.matchValue = 0;
										return x;
									})
							: [];
						if (lstMatchCoeffK.length <= 0) {
							coefK = null;
						} else {
							const lstmatchCriteriaCoefKProp = {
								date: 'date',
								producteur: 'producteur.id',
								client: 'client.id',
								typeCiment: 'typeCiment.code',
								classResistance: 'classeResistanceCiment.code'
							};
							const objMatchFormule = {
								date: formule.dateFinValidite,
								producteur: element.idProducteur,
								client: formule.idProducteur,
								typeCiment: caracteristiqueTypeCiment?.value,
								classResistance: caracteristiqueClasseCiment?.value
							};

							for (const m in lstmatchCriteriaCoefKProp) {
								for (let c = lstMatchCoeffK.length - 1; c >= 0; c--) {
									const valFormule = objMatchFormule[m];
									if (valFormule || valFormule === 0) {
										const propCoef = lstmatchCriteriaCoefKProp[m];
										const valCoefK = this.getDeepValue(lstMatchCoeffK[c], propCoef);
										if (valCoefK || valCoefK === 0) {
											if (m === 'date') {
												const dtCoefK = this.moment(valCoefK).toDate();
												const dtFormule = this.moment(valFormule, 'DD/MM/YYYY HH:mm').toDate();

												if (dtFormule < dtCoefK) {
													lstMatchCoeffK.splice(c, 1);
												}
											} else if (valFormule != valCoefK) {
												lstMatchCoeffK.splice(c, 1);
											} else {
												lstMatchCoeffK[c].matchValue++;
											}
										}
									}
								}
							}

							coefK = lstMatchCoeffK.sort((a, b) => {
								return b.matchValue - a.matchValue;
							})[0];
						}

						// si on trouve un coefficientK sur le produit on l'assigne sinon coefficient K = 0
						if (coefK) {
							element.coefk = coefK.coefK;
						} else {
							element.coefk = 0;
						}
					}
				} else {
					if (coefKActuel && typeof element.coefk === 'number') {
						// element.coefk = coefKActuel;
					} else {
						// si pas de norme CoefficientK = 0
						element.coefk = 0;
					}
				}
				let selectedCoef = true;
				if (formule.compoAddCoef && formule.compoAddCoef.length > 0 && formule.compoAddCoef.find((x) => x.selected)) {
					selectedCoef = false;
				}
				const data = new CompoAddCoef({
					idCompo: formule.idComposition,
					idAddition: element.idElement,
					coefK: element.coefk,
					selected: selectedCoef
				});

				if (!formule.compoAddCoef) {
					formule.compoAddCoef = [];
				}

				const idxk = this.formule.compoAddCoef.findIndex((x) => x.idAddition === element.idComposant);
				if (idxk > -1) {
					this.formule.compoAddCoef[idxk] = data;
				} else {
					this.formule.compoAddCoef.push(data);
				}
			}
		}
		//console.log(angular.copy(this.formule));
	}

	getDeepValue(obj, path) {
		for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
			if (obj) {
				obj = obj[path[i]];
			} else {
				return null;
			}
		}
		return obj;
	}

	async onChangeAdj() {
		await this.FormuleCalculsService.initCalcul();
	}

	async loadNormes() {
		try {
			this.normes = await this.NormesService.getNormeSpecList(this.formule.codeFamilleProduit);

			for (let i = 0; i < this.normes.length; i++) {
				const el = this.normes[i];
				if (el.code === 'MARQ_CE') {
					this.normes.splice(i, 1);
				}
			}

			if (this.produit && this.produit.typeObj && this.produit.typeObj.normeSpec && this.formule && !this.formule.normeSpec) {
				const index = this.normes.findIndex((x) => x.id === this.produit.typeObj.normeSpec.id);
				if (index > -1) {
					this.normes[index].selected = true;
					this.formule.normeSpec = this.normes[index];
				}
			}
		} catch (ex) {
			console.error(ex);
		}
	}

	async onSelectionNorme(data, listNormesSpec) {
		if (data.id != this.formule.normeSpec) {
			this.formule.normeSpec = data;
		} else {
			listNormesSpec.map((e) => {
				if (data.id === this.formule.normeSpec?.id) {
					return (e.selected = !e.selected);
				}
			});
			this.formule.normeSpec = null;
		}
		this.normeIsSelected = true;
		if (data && this.formule.composants && this.formule.composants.length > 0) {
			if (this.formule.composants.find((x) => x.codeFamille === 'ADD')) {
				await this.modalCoefkChanged();
			}
		}

		if (this.formule && this.formule.codeFamilleProduit === 'BPE' && this.formule.normeSpec) {
			this.isCalculated = false;
		}

		if (this.alreadyCalculated && this.formule.composants && this.formule.composants.length > 0) {
			await this.launchCalcul();
		}
	}

	checkConformiterAlerte() {
		if (this.formule.normeSpec?.id && this.formule.vdc && this.formule.vdc.length > 0) {
			this.formule.conformite = true;
			for (let i = 0; i < this.formule.vdc.length; i++) {
				const vdc = this.formule.vdc[i];

				if (vdc.code !== 'AddAddC' && vdc.code !== 'SubMassiqGranulat') {
					vdc.conformite = null;
					if (vdc.seuil && typeof vdc.seuil !== 'number' /*&& vdc.calcul*/) {
						if (!vdc.calcul) {
							vdc.conformite = false;
						} else if (vdc.calcul.includes(vdc.seuil)) {
							vdc.conformite = true;
						} else {
							vdc.conformite = false;
							this.formule.conformite = false;
						}
					} else if (Number.parseFloat(vdc.calcul) && Number.parseFloat(vdc.seuil)) {
						if (vdc.comparateur === ' >= ') {
							if (Number.parseFloat(vdc.calcul) >= Number.parseFloat(vdc.seuil)) {
								vdc.conformite = true;
							} else {
								vdc.conformite = false;
								this.formule.conformite = false;
							}
						} else if (vdc.comparateur === ' <= ') {
							if (Number.parseFloat(vdc.calcul) <= Number.parseFloat(vdc.seuil)) {
								vdc.conformite = true;
							} else {
								vdc.conformite = false;
								this.formule.conformite = false;
							}
						}
					}
				}
			}
			//console.log(angular.copy(this.formule))
			return this.formule;
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	async onSelectionCc(data, listCc) {
		if (data && data.idProduit !== this.idSelected) {
			this.idSelected = data.idProduit;

			//let produitCcSelected = await this.FormuleCalculsService.getProduitCommerciaux(null, data.idProduit, this.formule);
			this.formule.produitCommerciaux = [data.valeursCaracteristique];
			this.formule.produitCc = data;
			this.formule.classeExpo = await this.FormuleCalculsService.recuperationConcreteCodeExpo();
			this.formule.classeResistance = await this.FormuleCalculsService.recuperationConcreteCodeResistance();
			this.formule.classeChlorure = await this.FormuleCalculsService.recuperationConcreteCodeChlorure();
			this.formule.dosageCiment = await this.FormuleCalculsService.recuperationConcreteCodeCiment();
		} else {
			this.formule.produitCommerciaux = [];
			this.formule.produitCommerciaux = await this.FormuleCalculsService.getProduitCommerciaux();
			this.formule.produitCc = null;
			this.formule.classeExpo = await this.FormuleCalculsService.recuperationClasseExpo(this.formule.produits.produitCaracteristique, false);
			this.formule.classeResistance = await this.FormuleCalculsService.recuperationClasseResistance(
				this.formule.produits.produitCaracteristique,
				false
			);
			this.formule.classeChlorure = await this.FormuleCalculsService.recuperationClasseChlorure(
				this.formule.produits.produitCaracteristique,
				false
			);
			this.formule.dosageCiment = await this.FormuleCalculsService.recuperationDosageCiment(
				this.formule.produits.produitCaracteristique,
				false
			);
			listCc = listCc.map((e) => {
				if (e.idProduit === data?.idProduit) {
					e.selected = !e.selected;
				}
			});
			this.idSelected = null;
		}
		await this.launchCalcul();
	}

	async modalCoefkChanged() {
		const modalInstance = this.ModalService.confirm({
			modalTitle: this.$translate.instant('FORMULES.MODAL.TITLE'),
			modalMsg: this.$translate.instant('FORMULES.MODAL.MESSAGE'),
			headerClass: 'modal-warning'
		});

		try {
			await modalInstance.result;
			// conservation du coefk enregistrer sur la formule
			let coefKActuel = 0;

			if (this.formule && this.formule.compoAddCoef && this.formule.compoAddCoef.length > 0) {
				coefKActuel = this.formule.compoAddCoef[0].coefK;
			}

			await this.getCoefK(this.formule, coefKActuel);
		} catch (ex) {
			// recuperation du coefk selon le changement effectué
			await this.getCoefK(this.formule);
		}
	}

	async detailCalculCo2Modal() {
		let result = null;
		const modalInstance = this.ModalService.open({
			component: 'formuleCalculCo2Modal',
			animation: true,
			size: 'xxl',
			resolve: {
				formule: () => this.formule
			}
		});
		result = await modalInstance.result;
	}
}
