import MesureFormCourbeGranuloController from './mesure.courbe.granulo.controller';

export default {
    bindings: {
        mesure: '<',
        form: '=',
        onUpdate: '&'
    },
    template: require('./mesure.courbe.granulo.html'),
    controller: MesureFormCourbeGranuloController
};
