import * as _ from "lodash";
import ReleveBonsService from "../../../services/releve.bons.service";
import RelevesBonsImpression from "./releves.bons.impression.model";
import { StateService } from "@uirouter/angularjs";


export default class RelevesBonsImpressionController {
	private _: any;
	$scope: any;
	$timeout: any;
	$state: StateService;
	$stateParams: any;
	notification: any;
	$uibModal: any;
    impression: RelevesBonsImpression;
	service: ReleveBonsService;
	loading: boolean;
	dragOptions = {
		placeholder: 'table-row-ui-placeholder',
		revert: true,
		animation: 200,
		axis: 'y',
		'ui-preserve-size': true
	};
	/* @ngInject */
	constructor(
		_: any,
		$scope: ng.IScope,
		$timeout: ng.ITimeoutService,
		$state: StateService,
		$stateParams: any,
		notification: any,
		$uibModal: ng.ui.bootstrap.IModalService,
		ReleveBonsService: ReleveBonsService
	) {
		this._ = _;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.notification = notification;
		this.$uibModal = $uibModal;
		this.service = ReleveBonsService;
	}

	async $onInit(): Promise<void> {
		// Configuration
		this.loading = true;
		await this.loadConfig();
		this.loading = false;
	}

	async loadConfig() {
		try {
			let saved = await this.service.getPrintConfiguration();
			this.impression = new RelevesBonsImpression(saved);
			this.impression.colonnes = _.orderBy(this.impression.colonnes, 'ordre', 'asc');
		} catch (error) {
			this.notification.error(error.data);
		}
	}

	moveElt(col : any, sens : string): void {
		const i = this.impression.colonnes.findIndex((x: any) => x.id = col.id);
        if (sens == 'up') {
			this.impression.colonnes[i].ordre--;
			this.impression.colonnes[i -1].ordre++;
           
        } else if (sens == 'down') {
			this.impression.colonnes[i].ordre++;
			this.impression.colonnes[i + 1].ordre--;
        
        }
		this.impression.colonnes = _.orderBy(this.impression.colonnes, 'ordre', 'asc');
    }

	getKeys(obj: object, isClient : boolean = false) : string[] {
		let keys : string[] = Object.keys(obj);
		if(isClient) keys.pop();
		return keys
	}

	async appliquer(close: boolean = false) {
		try {
			this.impression.colonnes.forEach((x : any, i: number) => { 
				x.ordre = i+1;
				
			})
			let res = await this.service.saveOrUpdatePrintConfiguration(this.impression);
			this.notification.success('RELEVE_BONS.IMPRESSION.SAVED');
			if(close) {
				this.annuler()
			}
		} catch (error) {
			this.notification.error(error.data);
		} 
	}

	annuler() {
		this.$state.go('releve-bons.list');
	}
}
