import ImportDonneesConfig from './_config/importdonnees.config';
import ImportDonneesRoutes from './_config/importdonnees.routes';
import ImportDonneesComponent from './components/importdonnees-form';
import ImportDonneesService from './services/importdonnees.service';

const moduleName = 'app.massia.common.importdonnees';

angular
    .module(moduleName, [])
    .config(ImportDonneesConfig)
    .config(ImportDonneesRoutes)
    .component('importdonnees', ImportDonneesComponent)
    .service('ImportDonneesService', ImportDonneesService);

export default moduleName;
