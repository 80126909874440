import ReleveBonsConfig from './_config/releve.bons.config';
import ReleveBonsRoutes from './_config/releve.bons.routes';
import ReleveBonsComponent from './components/releve-bons';
import ReleveBonsService from './services/releve.bons.service';
import ReleveBonsCommunicationService from './services/releve.bons.communication.service.js';

import ReleveBonsImpressionValidator from './components/releve-bons/impression/releves.bons.impression.validator';

import RelevesBonsImpressionComponent from './components/releve-bons/impression';
const moduleName = 'app.massia.common.releve.bons';

angular
	.module(moduleName, [])
	.config(ReleveBonsConfig)
	.config(ReleveBonsRoutes)
	.service('ReleveBonsService', ReleveBonsService)
	.service('ReleveBonsCommunicationService', ReleveBonsCommunicationService)
	.component('releveBons', ReleveBonsComponent)
	.component('releveBonsPrint', RelevesBonsImpressionComponent)
	.factory('ReleveBonsImpressionValidator', ReleveBonsImpressionValidator);

export default moduleName;
