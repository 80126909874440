export default class componentMockController {
    customSpice = 'wasabi';
    spice = 'very';
    spices = [];

    /* @ngInject */
    constructor($scope, MockService) {
        this.$scope = $scope;
        this.mockService = MockService;
    }

    $onInit() {
        this.$scope.test = '$scope not $ctrl';
        this.spices = this.mockService.getSpices();
    }

    spicy(str) {
        this.spice = str;
    }
}
