/* eslint-disable brace-style */
/* eslint-disable complexity */
/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable curly */
/* eslint-disable max-depth */
export default class SyntheseEntityResistanceTabResumeController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'SyntheseEntitiesCommunicationService',
        '_',
        'notification',
        'MassiaApplicationService',
        '$translate',
        'SyntheseEntitiesService',
        'NormesService',
        '$filter',
        'ColorPickerService',
        '$uibModal'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        SyntheseEntitiesCommunicationService,
        _,
        notification,
        MassiaApplicationService,
        $translate,
        SyntheseEntitiesService,
        NormesService,
        $filter,
        ColorPickerService,
        $uibModal
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
        this._ = _;
        this.notification = notification;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$translate = $translate;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.NormesService = NormesService;
        this.ColorPickerService = ColorPickerService;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.unregisterReinit = this.SyntheseEntitiesCommunicationService.registerReinit(() => this.initForm());

        this.resultat = this.resultat || {};
        this.controle = this.controle || {};
        this.produit = this.produit || null;
        this.famille = this.famille || null;
        this.produitId = this.produit ? this.produit.id : 0;
        this.type = this.type || null;

        this.produitId = this.id.toString();
        if (this.type === 'famille') this.produitId = 'F' + this.id;

        const that = this;
        const watchExpr = '$ctrl.resultat["' + this.produitId + '"].lignes';
        this.$scope.$watch(watchExpr, function (newValue, oldValue) {
            if (newValue && !that.resultat[that.produitId].isTreated) that.buildResume(newValue);
        });
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    async initForm() {}

    buildResume(prelevements) {
        const that = this;

        for (const statKey in this.resultat[this.produitId].statsBeton) {
            const stat = this.resultat[this.produitId].statsBeton[statKey];
            stat.values = { nb: 0, nbConforme: 0, nbNonConforme: 0 };
        }

        if (prelevements) {
            prelevements.map(function (prelevement, index) {
                if (prelevement) {
                    for (const stat in that.resultat[that.produitId].statsBeton) {
                        that.resultat[that.produitId].statsBeton[stat].libelle = stat.titre;
                        if (prelevement.stats && prelevement.stats[stat] && prelevement.stats[stat].value) {
                            that.resultat[that.produitId].statsBeton[stat].values.nb += 1;
                            if (index === prelevements.length - 1)
                                that.resultat[that.produitId].statsBeton[stat].values.val = prelevement.stats[stat].value;
                            if (prelevement.stats[stat].conformite) {
                                const conformite = prelevement.stats[stat].conformite.isConforme || prelevement.stats[stat].conformite.isAlerte;
                                if (conformite) that.resultat[that.produitId].statsBeton[stat].values.nbConforme += 1;
                                else that.resultat[that.produitId].statsBeton[stat].values.nbNonConforme += 1;
                            }
                        }
                    }

                    return prelevement;
                }
            });

            this.resultat[this.produitId].isTreated = true;
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
