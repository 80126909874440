//Config
import CalculsStatistiquesConfig from './_config/calculs.statistiques.config';
import CalculsStatistiquesRoutes from './_config/calculs.statistiques.routes';
import CalculsStatistiquesComponent from './components/calculs-statistiques';
import CalculsStatistiquesService from './services/calculs.statistiques.service';
//import CalculsStatistiquesCommunicationService from './services/calculs.statistiques.communication.service';

import CalculStatistiqueFormComponent from './components/calcul-statistique-form';
import CalculStatistiqueDetailComponent from './components/calcul-statistique-detail';

import CalculStatistiqueValidator from './components/calcul-statistique-form/calcul.statistique.validator';

const moduleName = 'app.massia.common.calculs.statistiques';

angular
    .module(moduleName, [])
    .config(CalculsStatistiquesConfig)
    .config(CalculsStatistiquesRoutes)
    .service('CalculsStatistiquesService', CalculsStatistiquesService)
    //.service('CalculsStatistiquesCommunicationService', CalculsStatistiquesCommunicationService)
    .component('calculsStatistiques', CalculsStatistiquesComponent)
    .component('calculStatistiqueForm', CalculStatistiqueFormComponent)
    .component('calculStatistiqueDetail', CalculStatistiqueDetailComponent)
    .factory('CalculStatistiqueValidator', CalculStatistiqueValidator);

export default moduleName;
