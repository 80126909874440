import CdgFiltresController from './cdg.filtres.controller';

export default {
    bindings: {
        domaine: '<',
        isTransport: '<',
        listes: '=',
        frontFilters: '=',
        dateApplication: '=',
        source: '<',
        onUpdate: '&',
        onDelete: '&'
    },
    template: require('./cdg.filtres.html'),
    controller: CdgFiltresController
};
