export default class GroupPortailService {
    constructor($http) {
        this.$http = $http;
        this.url = `${__configuration.apiUrl}/massia/portail/group`;
    }

    getAll(filters = [], sorts = [], pagination = { skip: 0, take: 0 }) {
        return this.$http.get(this.url, {
            params: {
                filters: JSON.stringify(filters),
                sorts: JSON.stringify(sorts),
                pagination: JSON.stringify(pagination)
            }
        });
    }

    getOne(id) {
        return this.$http.get(`${this.url}/${id}`);
    }

    create(data) {
        return this.$http.post(this.url, data);
    }

    update(id, data) {
        return this.$http.put(`${this.url}/${id}`, data);
    }

    delete(id) {
        return this.$http.delete(`${this.url}/${id}`);
    }
}

GroupPortailService.$inject = ['$http'];
