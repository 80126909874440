PrelevementValidator.$inject = ['validator'];

export default function PrelevementValidator(validator) {
    const instance = new validator();

    instance.ruleFor('code').notEmpty().when(isNotCodeAuto).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 15).when(isNotCodeAuto).withMessage('VALIDATION_TOO_LONG_15');
    instance.ruleFor('type.id').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    //instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    //instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance.ruleFor('producteur.id').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('produit.id').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('auditeur.id').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('date').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;

    function isNotCodeAuto(obj, prop) {
        return !obj.estCodeAuto;
    }
}
