import TrameSaisie from './trame.saisie.model';
import TrameSsEssai from './trame.saisie.ssEssai.model';

export default class TrameSaisieFormController {
    static $inject = [
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'TramesSaisiesCommunicationService',
        'TramesSaisiesService',
        'notification',
        '$location',
        '$anchorScroll',
        '_',
        'MassiaApplicationService',
        '$uibModal',
        'TramesSaisiesTypesService',
        '$filter',
        '$translate',
        'CodeAutomatiqueService',
        'RouterHistoryService',
        'MassiaRightsService',
        'MesuresService',
        'MOPService'
    ];

    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        TramesSaisiesCommunicationService,
        TramesSaisiesService,
        notification,
        $location,
        $anchorScroll,
        _,
        MassiaApplicationService,
        $uibModal,
        TramesSaisiesTypesService,
        $filter,
        $translate,
        CodeAutomatiqueService,
        RouterHistoryService,
        MassiaRightsService,
        MesuresService,
        MOPService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.TramesSaisiesCommunicationService = TramesSaisiesCommunicationService;
        this.TramesSaisiesService = TramesSaisiesService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this._ = _;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$uibModal = $uibModal;
        this.TramesSaisiesTypesService = TramesSaisiesTypesService;
        this.$filter = $filter;
        this.$translate = $translate;
        this.CodeAutomatiqueService = CodeAutomatiqueService;
        this.RouterHistoryService = RouterHistoryService;
        this.ongletOpen = {
            isEnteteOpen: false,
            isMobileOpen: false
        };
        this.ongletOpenVal = {
            isEnteteOpen: 'entete'
        };
        this.MassiaRightsService = MassiaRightsService;
        this.MesuresService = MesuresService;
        this.MOPService = MOPService;
    }

    async $onInit() {
        this.MOPService.setMop([{ title: 'TRAMES.BREADCRUMBS.TRAMES_LIST', filename: 'Trames.pdf', application: 'laboratoire' }]);
        this.dragOptions = {
            placeholder: 'table-row-ui-placeholder',
            axis: 'y',
            animation: 200,
            'ui-floating': true,
            'ui-preserve-size': true
        };
        this.trame = {};
        this.types = this.types || [];
        this.getTypes();
        this.loading = false;
        const domaineCourant = this.getDomaineCourant();
        if (domaineCourant == 'refsaisieenv') {
            this.domaineMesure = 'mesures';
        } else if (domaineCourant == 'refsaisiesecu') {
            this.domaineMesure = 'messecu';
        } else if (domaineCourant == 'refsaisiectrl') {
            this.domaineMesure = 'controles';
        } else if (domaineCourant == 'refsaisie') {
            this.domaineMesure = 'essais';
        }
        this.form = {
            entete: {}
        };

        this.typesMesures = this.typesMesures || [];
        this.code = await this.checkCodeAuto();
        await this.reset();
        console.log(this.produits);
    }

    $onDestroy() {
        this.$timeout.cancel(this.updateTrameTimeout);
        this.MOPService.resetMop();
    }

    async checkCodeAuto() {
        try {
            const code = await this.CodeAutomatiqueService.getCodeAutomatique(this.MassiaApplicationService.getApplication());
            const res = code.find((x) => x.domaineCode === 'RefSaisie');

            return res;
        } catch (err) {
            console.error(err);
        }
    }

    getDomaineCourant() {
        if (
            this.$state.current.name === 'refsaisieenv.edit' ||
            this.$state.current.name === 'refsaisieenv.new' ||
            this.$state.current.name === 'refsaisieenv.duplicate'
        ) {
            return 'refsaisieenv';
        } else if (
            this.$state.current.name === 'refsaisiectrl.edit' ||
            this.$state.current.name === 'refsaisiectrl.new' ||
            this.$state.current.name === 'refsaisiectrl.duplicate'
        ) {
            return 'refsaisiectrl';
        } else if (
            this.$state.current.name === 'refsaisiesecu.edit' ||
            this.$state.current.name === 'refsaisiesecu.new' ||
            this.$state.current.name === 'refsaisiesecu.duplicate'
        ) {
            return 'refsaisiesecu';
        } else if (
            this.$state.current.name === 'refsaisie.edit' ||
            this.$state.current.name === 'refsaisie.new' ||
            this.$state.current.name === 'refsaisie.duplicate'
        ) {
            return 'refsaisie';
        }
    }

    async reset() {
        this.startLoading();

        let data = {
            id: this.$stateParams.id
        };

        if (data.id) {
            try {
                data = await this.TramesSaisiesService.getTrameById(data.id);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
                this.annuler();
            }
        } else {
            data.isEnabled = true;
        }
        this.trame = new TrameSaisie(data);
        if (this.$stateParams.duplicate) {
            this.trame.id = undefined;
            this.trame.code += '_copie';
        }
        this.ongletOpen = {
            isEnteteOpen: true
        };
        this.selectedSitesProducteurs = [];
        await this.getSitesProducteurs();
        await this.changeType();
        this.$timeout.cancel(this.updateTrameTimeout);

        if (this.trame.isMobile) {
            this.trameMobile = angular.copy(this.trame);
        }
        this.updateTrameTimeout = this.$timeout(() => this.stopLoading());
    }

    async changeType() {
        if (this.getDomaineCourant() == 'refsaisie') {
            await this.getTypesProduits();
            await this.changeTypeProduit();
            //await this.getProduits();
        }
        await this.getTypesMesures();
    }

    async getSitesProducteurs() {
        try {
            this.sites = await this.TramesSaisiesService.getSitesProducteurs();
            if (this.trame && this.trame.sitesProducteursIds) {
                this.sites.forEach((item) => {
                    if (this.trame.sitesProducteursIds.indexOf(item.id) !== -1) {
                        item.selected = true;
                    }
                });
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async changeTypeProduit() {
        //aller chercher les tamis
        try {
            if (this.trame.typeProduitId) {
                this.tamis = await this.TramesSaisiesService.getTamis(this.trame.typeProduitId);
                const tamisVide = { id: 0, ouverture: '' };
                this.tamis.splice(0, 0, tamisVide);
            } else {
                this.tamis = [];
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
        //rafraichir les produits
        await this.getProduits();
    }

    async getProduits() {
        if (this.getDomaineCourant() == 'refsaisie') {
            try {
                this.produits = await this.TramesSaisiesService.getProduitsByProducteurs(this.trame.sitesProducteursIds);
                if (this.trame.typeProduitId) {
                    this.produits = this._.filter(this.produits, { idType: this.trame.typeProduitId });
                }
                if (this.trame.petitDProduitId) {
                    //chercher l'ouverture d
                    const tamisPetitD = this._.find(this.tamis, { id: this.trame.petitDProduitId });
                    this.produits = this._.filter(this.produits, { petitD: tamisPetitD.ouverture });
                }
                if (this.trame.grandDProduitId) {
                    //chercher l'ouverture D
                    const tamisGrandD = this._.find(this.tamis, { id: this.trame.grandDProduitId });
                    this.produits = this._.filter(this.produits, { grandD: tamisGrandD.ouverture });
                }
                if (this.trame && this.trame.produitsIds) {
                    this.produits.forEach((item) => {
                        if (this.trame.produitsIds.indexOf(item.id) !== -1) {
                            item.selected = true;
                        }
                    });
                }
            } catch (ex) {
                this.notification.error(ex.data);
            }
        }
    }

    deleteAllMesures() {
        this.trame.mesures.splice(0, this.trame.mesures.length);
        this.trameMobile.mesures.splice(0, this.trame.mesures.length);
    }

    deleteMesure(mesure) {
        const indice = this.trame.mesures.indexOf(mesure);
        this.trame.mesures.splice(indice, 1);
        this.trame.mesures = this.trame.mesures.concat(); //MN: pour activer $OnChanges sur component "MesuresSelectController"
        for (let i = indice; i < this.trame.mesures.length; i++) {
            this.trame.mesures[i].position = this.trame.mesures[i].position - 1;
        }
        this.trameMobile.mesures.splice(indice, 1);
    }

    async selectMesure(mesure) {
        const trameEssai = {
            position: this.trame.mesures.length,
            id: 0,
            essaiCodeLibelle: mesure.libelle + ' (' + mesure.code + ')',
            essaiId: mesure.id,
            hasGranulo: mesure.hasGranulo
        };
        this.trame.mesures.push(trameEssai);

        if (this.trame.isMobile) {
            const ssEssais = await this.MesuresService.getMesureSousMesuresById(mesure.id);
            trameEssai.displayMobile = true;
            trameEssai.positionMobile = null;
            trameEssai.sousEssais = ssEssais.map((x) => new TrameSsEssai(x));
            this.trameMobile.mesures.push(trameEssai);
        }
    }

    updateOrdre(mesures) {
        // var index = this.trame.mesures.indexOf(mesure);
        // if (index != -1) {
        //     if (sens == 1) {
        //         this.trame.mesures[index].position = this.trame.mesures[index].position - 1;
        //         var prevIndex = index - 1;
        //         this.trame.mesures[prevIndex].position = this.trame.mesures[prevIndex].position + 1;
        //     } else {
        //         this.trame.mesures[index].position = parseInt(this.trame.mesures[index].position) + 1;
        //         var nextIndex = index + 1;
        //         this.trame.mesures[nextIndex].position = parseInt(this.trame.mesures[nextIndex].position) - 1;
        //     }
        //     this.trame.mesures = this.$filter('orderBy')(this.trame.mesures, 'position', false);
        // }
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                let id = false;

                for (let i = 0; i < this.trame.mesures.length; i++) {
                    if (this.trame.isMobile) {
                        const idx = this.trameMobile.mesures.findIndex((x) => x.id == this.trame.mesures[i].id);
                        this.trame.mesures[i].sousEssais = this.trameMobile.mesures[idx].sousEssais;
                        this.trame.mesures[i].displayMobile = this.trameMobile.mesures[idx].displayMobile;
                        this.trame.mesures[i].positionMobile = idx;
                        this.trame.mesures[i].openMobile = this.trameMobile.mesures[idx].openMobile;
                    }
                    this.trame.mesures[i].position = i;
                }
                if (this.trame.id) {
                    await this.TramesSaisiesService.updateTrame(this.trame);
                    this.notification.success('TRAMES.UPDATED');
                    id = this.trame.id;
                } else {
                    id = await this.TramesSaisiesService.createTrame(this.trame);
                    this.notification.success('TRAMES.CREATED');
                }

                return id;
            } catch (ex) {
                console.error(ex);
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async appliquer() {
        this.RouterHistoryService.ignoreNextRoute();

        const id = await this.sauvegarder();

        if (id && this.trame.id) {
            this.reset();
        } else if (id) {
            this.$state.go(this.getDomaineCourant() + '.edit', { id: id });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    annuler() {
        if (!this.RouterHistoryService.back()) {
            this.$state.go(this.getDomaineCourant() + '.list');
        }
    }

    checkValidity() {
        let validity = true;

        let firstScroll = true;
        if (!this.trame.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('entete');
                firstScroll = false;
            }

            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.$scope.$broadcast('trameSaisieValidations');
        }
        return validity;
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    async createType() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'TRAMES.CONFIRM_CREATE_TYPE.TITLE',
            modalMsg: 'TRAMES.CONFIRM_CREATE_TYPE.MESSAGE',
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(() => this.$state.go(this.getDomaineCourant() + '.newType'));
    }

    async getTypes() {
        try {
            this.types = await this.TramesSaisiesTypesService.getAll(this.getDomaineCourant());
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async getTypesMesures() {
        try {
            this.typesMesures = await this.TramesSaisiesTypesService.getAllMesures(this.domaineMesure, this.trame.typeId);
            const itemVide = { id: 0, libelle: this.$translate.instant('TRAMES.AUCUN_FILTRE') };
            this.typesMesures.splice(0, 0, itemVide);
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async getTypesProduits() {
        try {
            this.typesProduits = await this.TramesSaisiesTypesService.getAllProduits(this.trame.typeId);
            const itemVide = { id: 0, libelle: '' };
            this.typesProduits.splice(0, 0, itemVide);
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.trame.codeExists = await this.TramesSaisiesService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.trame.codeExists = null;
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }

    manageMobileSetting() {
        if (!this.trame.isMobile) {
            this.notification.warning('TRAMES.MOBILE.WARNING_REMOVE_MOBILITY');
            this.trame.mesures.forEach((mesure) => {
                mesure.sousEssais = [];
                mesure.displayMobile = true;
                mesure.positionMobile = null;
            }, this);
        } else {
            this.trameMobile = angular.copy(this.trame);
            this.mobileLoading = true;

            this.ongletOpen.isMobileOpen = true;
            this.trameMobile.mesures.forEach(async (mesure) => {
                const ssEssais = await this.MesuresService.getMesureSousMesuresById(mesure.essaiId);
                mesure.sousEssais = ssEssais.map((x) => new TrameSsEssai(x));
                mesure.displayMobile = true;
                mesure.positionMobile = null;
            }, this);
            this.mobileLoading = false;
        }
    }

    sortBy(list, idx = -1) {
        if (idx >= 0) {
            this.trameMobile.mesures[idx].displayMobile = !this.trameMobile.mesures[idx].displayMobile;
        }
        if (list) {
            this.trameMobile.mesures = list.sort((a, b) => a.positionMobile - b.positionMobile);
            this.trameMobile.mesures = list.sort((a, b) => b.displayMobile - a.displayMobile);
        }
    }

    openEssai(essai) {
        if (essai.open) {
            essai.open = false;
        } else {
            essai.open = true;
        }
    }
}
