import FacturePopupController from './facture.popup.controller';

export default {
	bindings: {
		modalInstance: '=',
		type: '=',
		dateGenFactOrig: '=',
		idSociete: '=',
		numero: '=',
		client: '=',
		clientId: '=',
		chantier: '=',
		chantierId: '='
	},
	template: require('./facture.popup.html'),
	controller: FacturePopupController
};
