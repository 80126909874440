import StocksConfiguration from './_config/stocks.config';
import StocksRoutes from './_config/stocks.routes';
import StocksService from './services/stocks.service';
import StocksCommunicationService from './services/stocks.communication.service';

import GestionStocksComponent from './components/gestion-stocks';
import GestionStocksComposantComponent from './components/gestion-stocks-composant';
import GestionStocksProductionComponent from './components/gestion-stocks-production';
import GestionStocksTransfertComponent from './components/gestion-stocks-transfert';
import GestionStocksAjustementComponent from './components/gestion-stocks-ajustement';
import GestionStocksProductionTraiteeComponent from './components/gestion-stocks-production-traitee';
import GestionStocksProductionRetraiteeComponent from './components/gestion-stocks-production-retraitee';
import GestionStocksAjoutProduitComponent from './components/gestion-stocks-ajout-produit';
import InventaireStocksComponent from './components/inventaire-stocks';
import InventaireStockFormComponent from './components/inventaire-stock-form';
import InventaireStocksLeveeComponent from './components/inventaire-stocks-levee';
import InventaireStocksMouvementComponent from './components/inventaire-stocks-mouvement';
import ValorisationStocksComponent from './components/valorisation-stocks';
import DepreciationStockQteComponent from './components/depreciation-stock-qte';
import DepreciationStockPrixComponent from './components/depreciation-stock-prix';

import GestionStocksFiltresValidator from './components/gestion-stocks/gestion.stocks.filtres.validator';
import ValorisationStocksFiltresValidator from './components/valorisation-stocks/valorisation.stocks.filtres.validator';
import InventaireStockFormValidator from './components/inventaire-stock-form/inventaire.stock.form.validator';

const moduleName = 'app.parametrageGeneral.stocks';

angular
    .module(moduleName, [])
    .config(StocksConfiguration)
    .config(StocksRoutes)
    .service('StocksService', StocksService)
    .service('StocksCommunicationService', StocksCommunicationService)
    .component('gestionStocks', GestionStocksComponent)
    .component('gestionStocksComposant', GestionStocksComposantComponent)
    .component('gestionStocksProduction', GestionStocksProductionComponent)
    .component('gestionStocksTransfert', GestionStocksTransfertComponent)
    .component('gestionStocksAjustement', GestionStocksAjustementComponent)
    .component('gestionStocksProductionTraitee', GestionStocksProductionTraiteeComponent)
    .component('gestionStocksProductionRetraitee', GestionStocksProductionRetraiteeComponent)
    .component('gestionStocksAjoutProduit', GestionStocksAjoutProduitComponent)
    .component('inventaireStocks', InventaireStocksComponent)
    .component('inventaireStockForm', InventaireStockFormComponent)
    .component('inventaireStocksLevee', InventaireStocksLeveeComponent)
    .component('inventaireStocksMouvement', InventaireStocksMouvementComponent)
    .component('valorisationStocks', ValorisationStocksComponent)
    .component('depreciationStockQte', DepreciationStockQteComponent)
    .component('depreciationStockPrix', DepreciationStockPrixComponent)
    .factory('GestionStocksFiltresValidator', GestionStocksFiltresValidator)
    .factory('ValorisationStocksFiltresValidator', ValorisationStocksFiltresValidator)
    .factory('InventaireStockFormValidator', InventaireStockFormValidator);

export default moduleName;
