// on importe le modèle pour pouvoir le new-er (utile à la validation)
import LieuCaracteristiques from './lieu.caracteristiques.model';

export default class LieuCaracteristiquesController {
    // l'injection de dépendances se fait via le mot clé static
    // c'est une mécanique angularJS
    static $inject = ['_', 'LieuxTypesService', 'notification', '$timeout', '$scope'];

    // le constructeur est la seule fonction qui reçoit les dépendances
    constructor(_, LieuxTypesService, notification, $timeout, $scope) {
        // on publie les dépendances dans this
        // pour pouvoir s'en servir dans les autres méthodes du controller
        this._ = _;
        this.LieuxTypesService = LieuxTypesService;
        this.notification = notification;
        this.$timeout = $timeout;
        this.$scope = $scope;
    }

    // cette fonction est appelée automatiquement à la création du component
    // par la mécanique interne angularJS
    $onInit() {
        // on initialise les variables du controller
        this.loading = false;
        this.typeFormat = this.typeFormat || [];
        this.lieu = this.lieu || {};
        this.niveauVisibilite = 0;
        this.caracteristiques = new LieuCaracteristiques(this.lieu.caracteristiques);
        const _this = this;
        this.$scope.$watch('$ctrl.lieu.entete.niveauVisibilite', function (newValue, oldValue) {
            _this.niveauVisibilite = _this.lieu.entete.niveauVisibilite;
        });
    }

    // cette fonction est appelée automatiquement à la destruction du component
    // par la mécanique interne angularJS
    $onDestroy() {
        this.$timeout.cancel(this.typeFormatTimeout);
    }

    // cette fonction est appelée automatiquement
    // dans le cas où une des propriétés du component :
    // 1. est bindée en one-way '<'
    // 2. est modifiée d'une façon ou d'une autre
    $onChanges(objChanges) {
        // if (objChanges.lieu) {
        //     this.caracteristiques = new LieuCaracteristiques(objChanges.lieu.currentValue.caracteristiques.data);
        // }

        if (objChanges.typeId) {
            if (!objChanges.typeId.isFirstChange()) {
                this.caracteristiques = new LieuCaracteristiques();
            }

            this.getTypeFormat(objChanges.typeId.currentValue);
        }
    }

    // cette fonction est appelée automatiquement
    // a chaque digest (dirty check) d'angular
    $doCheck() {
        if (!angular.equals(this.caracteristiques, this.lieu.caracteristiques)) {
            this.onUpdate({
                caracteristiques: angular.copy(this.caracteristiques)
            });
        }
    }

    async getTypeFormat(typeId) {
        if (typeId) {
            // on démarre l'animation de loading avant le chargement des données
            this.startLoading();

            // on encapsule l'appel serveur dans un try...catch pour récupérer les erreurs
            try {
                // on récupère les données depuis le service qui interroge le serveur
                const typeFormat = await this.LieuxTypesService.getCaracteristiques(typeId);
                // on trie le tableau selon la propriété 'position'
                // on publie le tableau dans le this pour l'afficher dans la vue
                this.typeFormat = this._.sortBy(typeFormat, 'position');
            } catch (ex) {
                // en cas de problème on notifie l'utilisateur
                this.notification.error(ex.data);
            } finally {
                // On supprimer le timeout précédent (fuite mémoire)
                this.$timeout.cancel(this.typeFormatTimeout);

                // une fois l'appel terminé, on stoppe l'animation de loading
                this.typeFormatTimeout = this.$timeout(() => {
                    this.stopLoading();
                });
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
