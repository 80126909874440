import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-fieldset',
    bindings: {
        validation: '<',
        label: '@',
        width: '@',
        marginLeft: '@',
        marginRight: '@',
        noCard: '<',
        mandatoryField: '<',
        fieldsetClass: '@',
        fieldsetStyle: '<',
        legendStyle: '<',
        legendClass: '@'
    },
    transclude: true,
    template: require('./massia.fieldset.tpl.html')
})
export class MassiaFieldsetController {
    constructor($translate) {
        this.$translate = $translate;
    }

    $onInit() {
        this.style = {
            width: this.width || '100%'
        };

        if (this.marginLeft) this.style.marginLeft = this.marginLeft;
        if (this.marginRight) this.style.marginRight = this.marginRight;
    }
}

MassiaFieldsetController.$inject = ['$translate'];
