/* @ngInject */
export default function Configure($stateProvider: any) {
    $stateProvider.state('import-3r', {
        parent: 'common',
        url: '/import-3r',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.OUTILS" | translate }}'
        }
    });

    $stateProvider.state('import-3r.list', {
        url: '/list',
        template: '<import3r-list></import3r-list>',
        rights: {domain: 'parampresse3r', right: 'read'},
        ncyBreadcrumb: {
            label: '{{ "PRESSE_3R.BREADCRUMBS.LIST" | translate}}'
        },
        search: 'PRESSE_3R.BREADCRUMBS.LIST'
    });

    $stateProvider.state('import-3r.edit', {
        url: '/{id}/edit',
        template: '<import3r-form></import3r-form>',
        rights: {domain: 'parampresse3r', right: 'update'},
        ncyBreadcrumb: {
            parent: 'import-3r.list',
            label: '{{ "PRESSE_3R.BREADCRUMBS.EDIT" | translate }}'
        }
    });

    $stateProvider.state('import-3r.new', {
        url: '/new',
        template: '<import3r-form></import3r-form>',
        rights: {domain: 'parampresse3r', right: 'create'},
        ncyBreadcrumb: {
            parent: 'import-3r.list',
            label: '{{ "PRESSE_3R.BREADCRUMBS.NEW" | translate }}'
        }
    });
}
