export default function popoverCloseDirective() {
    return {
        retrict: 'A',
        scope: {
            popoverClose: '<'
        },
        link: function (scope, $element, attrs) {
            $element.on('click', function () {
                const clickedElm = document.querySelector('a[popover-link="' + scope.popoverClose + '"]').click();
            });
        }
    };
}
