// on importe le modèle pour pouvoir le new-er (utile à la validation)
import SiteCaracteristiquesLocataire from './site.caracteristiques.locataire.model';

export default class SiteCaracteristiquesLocataireController {
    // l'injection de dépendances se fait via le mot clé static
    // c'est une mécanique angularJS
    static $inject = ['_', 'SitesTypesService', 'notification', '$timeout', '$scope', 'Interpreteur'];

    // le constructeur est la seule fonction qui reçoit les dépendances
    constructor(_, SitesTypesService, notification, $timeout, $scope, Interpreteur) {
        // on publie les dépendances dans this
        // pour pouvoir s'en servir dans les autres méthodes du controller
        this._ = _;
        this.SitesTypesService = SitesTypesService;
        this.notification = notification;
        this.$timeout = $timeout;
        this.$scope = $scope;
        this.Interpreteur = Interpreteur;
    }

    // cette fonction est appelée automatiquement à la création du component
    // par la mécanique interne angularJS
    $onInit() {
        // on initialise les variables du controller
        this.loading = false;
        this.typeFormat = this.typeFormat || [];
        this.site = this.site || {};
        this.genre = this.genre;
        this.niveauVisibilite = 0;
        this.caracteristiquesLocataire = new SiteCaracteristiquesLocataire(this.site.caracteristiquesLocataire);
        this.Interpreteur.init(this.site, null);
        this.Interpreteur.formatCaract = this.typeFormat;
        this.initConditions = false;
        const _this = this;
        this.$scope.$watch('$ctrl.site.entete.niveauVisibiliteLocataire', function (newValue, oldValue) {
            _this.niveauVisibilite = _this.site.entete.niveauVisibiliteLocataire;
        });
    }

    // cette fonction est appelée automatiquement à la destruction du component
    // par la mécanique interne angularJS
    $onDestroy() {
        this.$timeout.cancel(this.typeFormatTimeout);
    }

    // cette fonction est appelée automatiquement
    // dans le cas où une des propriétés du component :
    // 1. est bindée en one-way '<'
    // 2. est modifiée d'une façon ou d'une autre
    $onChanges(objChanges) {
        if (objChanges.typeId) {
            if (!objChanges.typeId.isFirstChange()) {
                this.caracteristiquesLocataire = new SiteCaracteristiquesLocataire();
            }

            this.getTypeFormat(objChanges.typeId.currentValue);
        }
    }

    // cette fonction est appelée automatiquement
    // a chaque digest (dirty check) d'angular
    async $doCheck() {
        //recalcul des conditions d'affichage dès qu'on a les infos pour le faire
        if (!this.initConditions && this.typeFormat && this.typeFormat.length > 0) {
            await this.recalculeConditions();
            this.initConditions = true;
        }
        if (!angular.equals(this.caracteristiquesLocataire, this.site.caracteristiquesLocataire)) {
            this.onUpdate({
                caracteristiquesLocataire: angular.copy(this.caracteristiquesLocataire)
            });
        }
    }

    async getTypeFormat(typeId) {
        if (typeId) {
            // on démarre l'animation de loading avant le chargement des données
            this.startLoading();

            // on encapsule l'appel serveur dans un try...catch pour récupérer les erreurs
            try {
                // on récupère les données depuis le service qui interroge le serveur
                const typeFormat = await this.SitesTypesService.getCaracteristiques(typeId);
                // on trie le tableau selon la propriété 'position'
                // on publie le tableau dans le this pour l'afficher dans la vue
                this.typeFormat = this._.sortBy(typeFormat, 'position');
                this.Interpreteur.formatCaract = this.typeFormat;
            } catch (ex) {
                // en cas de problème on notifie l'utilisateur
                this.notification.error(ex.data);
            } finally {
                // On supprimer le timeout précédent (fuite mémoire)
                this.$timeout.cancel(this.typeFormatTimeout);

                // une fois l'appel terminé, on stoppe l'animation de loading
                this.typeFormatTimeout = this.$timeout(() => {
                    this.stopLoading();
                });
            }
        }
    }
    async exitCaract(idCaract) {
        if (!this.ignoreDoCheck) {
            this.ignoreDoCheck = true;
            const nouvValeur = this.caracteristiquesLocataire[idCaract];
            let updateListeCar = false;
            if (!this.caracteristiquesLocataire.data[idCaract]) {
                updateListeCar = true;
            } else if (this.caracteristiquesLocataire.data[idCaract] != nouvValeur) {
                updateListeCar = true;
                this.site.caracteristiquesLocataire[idCaract] = nouvValeur;
                this.site.caracteristiquesLocataire.data[idCaract] = nouvValeur;
                if (this.caracteristiquesLocataire.data[idCaract] !== undefined) {
                    this.caracteristiquesLocataire.data[idCaract].value = nouvValeur;
                }
            }
            if (updateListeCar) {
                await this.recalculeFormules();
                this.onUpdate({
                    caracteristiquesLocataire: angular.copy(this.caracteristiquesLocataire)
                });
                await this.recalculeConditions();
            }
            this.ignoreDoCheck = false;
        }
    }

    async recalculeFormules() {
        let recharge = false;
        if (this.Interpreteur) {
            this.Interpreteur.calculatedFormules = [];
            this.Interpreteur.obj = this.site;
            this.Interpreteur.obj.caracteristiques = this.Interpreteur.obj.caracteristiquesLocataire;
            this.Interpreteur.formatCaract = this.typeFormat;
        }
        let idx1 = 0;
        while (idx1 < this.typeFormat.length) {
            const format = this.typeFormat[idx1];
            if (format.formula) {
                try {
                    const oldVal = this.caracteristiquesLocataire[idx1];
                    this.Interpreteur.essaiAppel = undefined;
                    this.Interpreteur.forceIter = undefined;
                    let newVal = await this.Interpreteur.calculeFormule(format.formula);
                    if (format.nature == 'Numerique' && newVal !== '') {
                        let arrondi = format.numericAdditionalInformation.nombreDeDecimales;
                        const grandeurMinimum = format.numericAdditionalInformation.grandeurMinimum;
                        const grandeurMaximum = format.numericAdditionalInformation.grandeurMaximum;
                        const x = this.Interpreteur.strEnDouble(newVal);
                        if (!isNaN(x)) {
                            //gestion des min/max
                            if (grandeurMinimum != undefined && grandeurMinimum != null && grandeurMinimum > newVal) {
                                newVal = grandeurMinimum;
                            }
                            if (grandeurMaximum != undefined && grandeurMaximum != null && grandeurMaximum < newVal) {
                                newVal = grandeurMaximum;
                            }
                            //gestion de l'arrondi
                            if (arrondi == 0) {
                                newVal = Math.round(x);
                            } else {
                                if (!arrondi) {
                                    arrondi = 2;
                                }
                                newVal = x.toFixed(arrondi);
                            }
                        } else {
                            newVal = null;
                        }
                    }
                    if (oldVal !== newVal) {
                        this.caracteristiquesLocataire[format.id] = newVal;
                        if (this.caracteristiquesLocataire.data) {
                            this.caracteristiquesLocataire.data[format.id].value = newVal;
                        }
                        recharge = true;
                    }
                } catch (ex) {}
            }
            idx1++;
        }
        if (recharge) {
            this.typeFormat = angular.copy(this.typeFormat);
        }
    }

    async recalculeConditions() {
        if (this.typeFormat) {
            let rechargeCond = false;
            if (this.Interpreteur) {
                this.Interpreteur.calculatedFormules = [];
                this.Interpreteur.obj = this.site;
                this.Interpreteur.obj.caracteristiques = this.Interpreteur.obj.caracteristiquesLocataire;
                this.Interpreteur.formatCaract = this.typeFormat;
            }
            let idx1 = 0;
            while (idx1 < this.typeFormat.length) {
                const format = this.typeFormat[idx1];
                if (format.condition) {
                    try {
                        const oldCond = angular.copy(format.hide);
                        this.Interpreteur.essaiAppel = undefined;
                        this.Interpreteur.forceIter = undefined;
                        const newCond = await this.Interpreteur.calculeFormule(format.condition);

                        if (oldCond !== (newCond !== true && newCond !== 'true')) {
                            format.hide = newCond !== true && newCond !== 'true';
                            rechargeCond = true;
                        }
                    } catch (ex) {
                        console.error(ex);
                    }
                }
                idx1++;
            }
            if (rechargeCond) {
                this.typeFormat = angular.copy(this.typeFormat);
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
