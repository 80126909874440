import SocietesAssociees from './societes.associees.model';

export default class SocietesAssocieesController {
    static $inject = ['$state', '$stateParams', 'SocietesService', 'SocietesTypesService', 'ModalService', 'notification', '$translate'];

    constructor($state, $stateParams, SocietesService, SocietesTypesService, ModalService, notification, $translate) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SocietesService = SocietesService;
        this.SocietesTypesService = SocietesTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
    }

    $onInit() {
        this.societe = this.societe || {};
        this.societesAssociees = new SocietesAssociees(this.societe.societesAssociees);
        if (this.societe.id) {
            this.getListeSocietesAssociees(this.societe.id);
        }
        this.getListeAssocies();
    }

    async getListeAssocies() {
        this.startLoading();
        try {
            this.listeAssocies = [];
            const lstInit = await this.SocietesService.getAssocies();
            lstInit.forEach(function (element) {
                let found = false;
                if (this.societe.listeSocietesAssociees) {
                    for (let i = 0; i < this.societe.listeSocietesAssociees.length; i++) {
                        if (this.societe.listeSocietesAssociees[i].associeId == element.id) {
                            found = true;
                        }
                    }
                }

                if (found == false) {
                    this.listeAssocies.push(element);
                }
            }, this);
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.stopLoading();
        }
    }

    async getListeSocietesAssociees(idSociete) {
        this.startLoading();
        try {
            this.societe.listeSocietesAssociees = await this.SocietesService.getSocietesAssociees(idSociete);
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.stopLoading();
        }
    }

    async deleteSocieteAssociee(socAssoc) {
        this.startLoading();
        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('SOCIETES.SOCIETES_ASSOCIEES.DELETE.TITLE'),
            modalMsg: this.$translate.instant('SOCIETES.SOCIETES_ASSOCIEES.DELETE.MESSAGE'),
            headerClass: 'modal-danger'
        });

        modalInstance.result.then(async () => {
            try {
                const id = await this.SocietesService.deleteSocietesAssociees(socAssoc.id);
                this.notification.success('SOCIETES.SOCIETES_ASSOCIEES.DELETE.SUCCESS');
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            } finally {
                this.getListeSocietesAssociees(this.societe.id);
                this.getListeAssocies();
            }
        });
        this.stopLoading();
    }

    async addAssocie() {
        this.startLoading();
        try {
            this.new.idSociete = this.societe.id;
            this.new.nomAssocie = this.new.associe.libelle;
            this.new.idAssocie = this.new.associe.id;
            this.newSocAssoc = new SocietesAssociees(this.new);
            const id = await this.SocietesService.persistSocietesAssociees(this.newSocAssoc);
            this.getListeSocietesAssociees(this.societe.id);
            this.new = {};
            this.getListeAssocies();
            this.notification.success('SOCIETES.SOCIETES_ASSOCIEES.CREATED');
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
