(function (angular, undefined) {
    'use strict';

    angular.module('blocks.treeview')
        .factory('NodeModel', NodeModel);
    NodeModel.$inject = ['NodeValidator'];

    function NodeModel(validator) {
        var factory = {
            Node: Node
        };

        return factory;

        function Node(data) {
            data = data || {};

            return {
                id: data.id || -1,
                label: data.label,
                type: data.type,
                icon: data.icon,
                typeId: data.typeId,
                parentId: data.parentId,
                position: data.position,

                validationResults: function () {
                    return validator.validate(this);
                },

                isValid: function () {
                    var validationResults = validator.validate(this);
                    return validationResults.isValid;
                }
            };
        }
    }
})(angular);
