import NiveauxHierarchiquesFormController from './niveaux.hierarchiques.form.controller';

export default {
    template: require('./niveaux.hierarchiques.form.tpl.html'),
    controller: NiveauxHierarchiquesFormController,
    bindings: {
        readonly: '<',
        trame: '<',
        elements: '<',
        model: '<'
    }
};
