export default class TamisService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
        this.serviceUrl = `${__configuration.apiUrl}/v2/massia/serie-tamis`;
    }

    async getAll(filters, sorts, pagination) {
        const res = await this.$http.get(this.serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getOneById(id) {
        const res = await this.$http.get(this.serviceUrl + '/' + id);

        return res.data;
    }
}
