const serviceUrl = `${__configuration.apiUrl}/massia/gestion/plannings/demandes-livraisons`;

export default class DemandesLivraisonsService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getDemandeLivraisons(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getDemandeLivraisonById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async deleteDemandeLivraisonById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getSitesCommerciaux(idSociete) {
        const siteUrl = `${__configuration.apiUrl}/massia/sites-commerciaux/header/${idSociete}`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async getSocietes() {
        const siteUrl = `${__configuration.apiUrl}/massia/societes-commerciales/header`;
        const res = await this.$http.get(siteUrl);

        return res.data;
    }

    async getClients() {
        const siteUrl = `${__configuration.apiUrl}/massia/sites-clients/headers`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async getChantiers(idSite) {
        const dateFacture = null;
        const siteUrl = `${__configuration.apiUrl}/massia/chantiers/header/${idSite}/${dateFacture}`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async getLieux(valeur, idClient) {
        const lieuxUrl = `${__configuration.apiUrl}/massia/lieux/typeAhead/${valeur}/${idClient}`;
        const res = await this.$http.get(lieuxUrl);
        return res.data;
    }

    async getContacts(valeur) {
        const url = `${__configuration.apiUrl}/massia/personnes/contacts/header/${valeur}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getCommandes(valeur, clientId, chantierId) {
        if (chantierId) {
            const url = `${__configuration.apiUrl}/massia/commandes/headerByChantier/${chantierId}/${valeur}`;
            const res = await this.$http.get(url);
            return res.data;
        }

        if (clientId) {
            const url = `${__configuration.apiUrl}/massia/commandes/headerByClient/${clientId}/${valeur}`;
            const res = await this.$http.get(url);
            return res.data;
        }
        return null;
    }

    async getZones() {
        const url = `${__configuration.apiUrl}/massia/zones/header`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getProduitsSelectionnables(filters) {
        const url = `${__configuration.apiUrl}/massia/produits/producteursProduits`;
        const res = await this.$http.get(url, {
            params: {
                filters: JSON.stringify(filters || {})
            }
        });

        return res.data;
    }

    async createDemandeLivraison(data) {
        const result = await this.$http.post(serviceUrl, data);
        return result.data;
    }

    async updateDemandeLivraison(data) {
        const url = `${serviceUrl}/${data.id}`;
        return await this.$http.put(url, data);
    }

    async updateTour(data) {
        const url = `${serviceUrl}/tour/${data.id}`;
        return await this.$http.put(url, data);
    }
}
