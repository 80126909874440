export default class CodeAutoService {
    constructor($http) {
        this.$http = $http;
        this.serviceUrl = `${__configuration.apiUrl}/massia/domaines`;
    }

    async getCodeAutomatique(app) {
        const res = await this.$http.get(this.serviceUrl + '/' + app + '/code-automatique');

        // console.log(res);
        return res.data;
    }

    async updateCodeAutomatique(app, data) {
        const res = await this.$http.post(this.serviceUrl + '/' + app + '/code-automatique', data);
        return res.data;
    }
}

CodeAutoService.$inject = ['$http'];
