import FilterModalController from './filter-modal.controller';

export default {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: require('./filter-modal.html'),
    controller: FilterModalController
};
