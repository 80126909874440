import CritrereDateController from './critere.date.controller';

export default {
    bindings: {
        critere: '=',
        canUpdate: '<'
    },
    controller: CritrereDateController,
    template: require('./critere.date.tpl.html')
};
