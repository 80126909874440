import validatorBuilder from 'validator-builder';

let syntheseEntityCritereValidator = null;

export default class SyntheseEntityCritere {
    constructor(indiceSelection, listeSyntheseCritereValeurLignes) {
        this.id = -1;
        this.indiceSelection = indiceSelection;
        this.isEnabled = true;
        this.listeSyntheseCritereValeurLignes = angular.copy(listeSyntheseCritereValeurLignes) || [];
        this.nomComposant = '';
        this.pourcentageComposant = 0;
    }

    isValid() {
        syntheseEntityCritereValidator = syntheseEntityCritereValidator || validatorBuilder.getInstanceFor('SyntheseEntityCritere');
        const validationResults = syntheseEntityCritereValidator.validate(this);
        return validationResults.isValid;
    }
}
