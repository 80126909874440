const serviceUrl = `${__configuration.apiUrl}/massia/filtres`;

export default class DonneeFiltreesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getFiltres() {
        const url = `${__configuration.apiUrl}/massia/personnes/utilisateur/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getDonneeFiltree(id, type) {
        const url = `${serviceUrl}/${id}/${type}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async updateFiltre(data) {
        return this.$http.put(serviceUrl, data);
    }
}
