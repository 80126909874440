import SocieteCoordonneesController from './societe.coordonnees.controller';

export default {
    bindings: {
        societe: '<',
        form: '=',
        onUpdate: '&'
    },
    template: require('./societe.coordonnees.html'),
    controller: SocieteCoordonneesController
};
