DeviseEnteteValidator.$inject = ['validator'];

export default function DeviseEnteteValidator(validator) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 15).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance
        .ruleFor('code')
        .matches(/^[a-zA-Z0-9_]*$/)
        .withMessage('DEVISES.CODE_CHARACTERS_NOK');

    instance.ruleFor('symbole').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('symbole').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    instance.ruleFor('arrondi').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;
}
