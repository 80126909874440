import TacheCaracteristiques from './tache.caracteristiques.model';

export default class TacheCaracteristiquesController {
    static $inject = ['_', 'TachesTypesService', 'notification', '$timeout', '$scope'];

    constructor(_, TachesTypesService, notification, $timeout, $scope) {
        this._ = _;
        this.TachesTypesService = TachesTypesService;
        this.notification = notification;
        this.$timeout = $timeout;
        this.$scope = $scope;
    }

    $onInit() {
        this.loading = false;
        this.typeFormat = this.typeFormat || [];
        this.tache = this.tache || {};
        this.niveauVisibilite = 0;
        this.caracteristiques = new TacheCaracteristiques(this.tache.caracteristiques);
        const _this = this;
        this.$scope.$watch('$ctrl.tache.entete.niveauVisibilite', function (newValue, oldValue) {
            _this.niveauVisibilite = _this.tache.entete.niveauVisibilite;
        });
    }

    $onDestroy() {
        this.$timeout.cancel(this.typeFormatTimeout);
    }

    $onChanges(objChanges) {
        if (objChanges.typeId) {
            if (!objChanges.typeId.isFirstChange()) {
                this.caracteristiques = new TacheCaracteristiques();
            }

            this.getTypeFormat(objChanges.typeId.currentValue);
        }
    }

    // cette fonction est appelée automatiquement
    // a chaque digest (dirty check) d'angular
    $doCheck() {
        if (!angular.equals(this.caracteristiques, this.tache.caracteristiques)) {
            this.onUpdate({
                caracteristiques: angular.copy(this.caracteristiques)
            });
        }
    }

    async getTypeFormat(typeId) {
        if (typeId) {
            // on démarre l'animation de loading avant le chargement des données
            this.startLoading();

            // on encapsule l'appel serveur dans un try...catch pour récupérer les erreurs
            try {
                // on récupère les données depuis le service qui interroge le serveur
                const typeFormat = await this.TachesTypesService.getCaracteristiques(typeId);
                // on trie le tableau selon la propriété 'position'
                // on publie le tableau dans le this pour l'afficher dans la vue
                this.typeFormat = this._.sortBy(typeFormat, 'position');
            } catch (ex) {
                // en cas de problème on notifie l'utilisateur
                this.notification.error(ex.data);
            } finally {
                // On supprimer le timeout précédent (fuite mémoire)
                this.$timeout.cancel(this.typeFormatTimeout);

                // une fois l'appel terminé, on stoppe l'animation de loading
                this.typeFormatTimeout = this.$timeout(() => {
                    this.stopLoading();
                });
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
