export default function Configure($stateProvider) {
    $stateProvider.state('paiements', {
        parent: 'gestion',
        url: '/paiements',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('paiements.list', {
        url: '/list',
        template: '<paiements></paiements>',
        rights: { domain: 'modepaiement', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "PAIEMENTS.BREADCRUMBS.PAIEMENTS_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'PAIEMENTS.BREADCRUMBS.PAIEMENTS_LIST',
            order: 31
        },
        search: 'PAIEMENTS.BREADCRUMBS.PAIEMENTS_LIST'
    });

    $stateProvider.state('paiements.list.detail', {
        url: '/{id}',
        template: '<paiement-detail></paiement-detail>',
        rights: { domain: 'modepaiement', right: 'read' },
        ncyBreadcrumb: {
            parent: 'paiements.list',
            label: '{{ "PAIEMENTS.BREADCRUMBS.PAIEMENT_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('paiements.new', {
        url: '/new',
        template: '<paiement-form></paiement-form>',
        rights: { domain: 'modepaiement', right: 'create' },
        ncyBreadcrumb: {
            parent: 'paiements.list',
            label: '{{ "PAIEMENTS.BREADCRUMBS.PAIEMENT_NEW" | translate}}'
        },
        search: 'PAIEMENTS.BREADCRUMBS.PAIEMENT_NEW'
    });

    $stateProvider.state('paiements.edit', {
        url: '/{id}/edit',
        template: '<paiement-form></paiement-form>',
        rights: { domain: 'modepaiement', right: 'update' },
        ncyBreadcrumb: {
            parent: 'paiements.list',
            label: '{{ "PAIEMENTS.BREADCRUMBS.PAIEMENT_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
