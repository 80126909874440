import { StateParams, StateService } from '@uirouter/angularjs';
import ProduitsService from '../../../services/produits.service';
import SocietesService from '../../../../societes/services/societes.service';
import MassiaApplicationService from '../../../../../massia/layout/components/massia-application/massia.application.service';
import SocieteProduit from '../../../../../../core/models/SocieteProduit';
import Produit from '../produit.model';

export default class ProduitFormSocieteProduitController {
    /* @ngInject */

    loading: boolean;
    societesLabo: Array<SocieteProduit> = [];
    filteredSocietesLabo: Array<SocieteProduit> = [];
    selectedSocieteLabo: Array<SocieteProduit> = [];
    headers: Array<any>;
    buttons: Array<any>;
    produit: any;
    $scope: ng.IScope;
    $state: StateService;
    $stateParams: StateParams;
    $translate: any;
    notification: any;
    ProduitsService: ProduitsService;
    SocietesService: SocietesService;
    MassiaApplicationService: MassiaApplicationService;
    ModalService: any;

    constructor(
        $scope: ng.IScope,
        $stateParams: StateParams,
        $translate: any,
        ProduitsService: ProduitsService,
        notification: any,
        SocietesService: SocietesService,
        MassiaApplicationService: MassiaApplicationService,
        ModalService: any
    ) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.ProduitsService = ProduitsService;
        this.notification = notification;
        this.SocietesService = SocietesService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.ModalService = ModalService;
    }

    $onInit(): void {
        this.headers = [
            {
                title: 'SITES.ETAT_COMPTE.CODE_SOCIETE',
                class: 'width-20p',
                property: 'code'
            },
            {
                title: 'SITES.ETAT_COMPTE.NOM_SOCIETE',
                class: 'width-25p',
                property: 'libelle'
            },
            {
                title: 'ACTIONS',
                class: 'width-5p',
                button: true
            }
        ];
        this.buttons = [
            {
                tooltip: 'SUPPRIMER', // Titre du bouton
                callback: this.deleteSocieteProduct, // Fonction de callback
                icon: 'glyphicon-trash', // Icon du bouton
                authorize: false, // Pour pouvoir utilisé les propriété domain & right
                ProduitsService: this.ProduitsService, // Pour pouvoir l'utiliser dans la callback
                produit: { id: this.produit.id } //
            }
        ];

        this.loadSocieteProduct();
        this.loadSocieteLaboratoire();
    }

    $onDestroy(): void {}

    async loadSocieteLaboratoire(): Promise<void> {
        // Genre: 32 = 'Laboratoire'
        const res: any = await this.SocietesService.getSocietes([], [], { skip: 0, take: 99999 }, 32, this.MassiaApplicationService.getApplication());
        this.societesLabo = res.items;

        if (!this.produit.id && (!this.produit.societeLaboratoire || this.produit.societeLaboratoire.length <= 0))
            this.produit.societeLaboratoire = this.societesLabo;
    }

    loadSocieteProduct(): void {
        if (this.produit.societeLaboratoire?.length > 0) {
            this.societesLabo = this.produit.societeLaboratoire;
        }
    }

    selectSociete(selectedSociete: any) {
        this.produit.societeLaboratoire = selectedSociete;
    }

    deleteSocieteProduct = async (data: any): Promise<void> => {
        if (data && data.id && this.produit.societeLaboratoire.length === 1) {
            this.notification.error(this.$translate.instant('PRODUITS.DELETE_SOCIETE.LAST_SOCIETE'));
            return;
        }
        if (data && data.id) {
            const modalInstance: ng.ui.bootstrap.IModalInstanceService = this.ModalService.confirm({
                modalTitle: this.$translate.instant('PRODUITS.DELETE_SOCIETE.TITLE', { code: data.libelle }),
                modalMsg: this.$translate.instant('PRODUITS.DELETE_SOCIETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                this.startLoading();
                try {
                    await this.ProduitsService.deleteSocieteProduct(data.id, this.produit.id);
                    const index = this.produit.societeLaboratoire.findIndex((s: any) => s.id === data.id);
                    if (index > -1) {
                        this.produit.societeLaboratoire.splice(index, 1);
                    }
                    this.loadSocieteProduct();
                } catch (ex) {
                    console.log(ex);
                    this.notification.error(ex.data);
                } finally {
                    this.stopLoading();
                }
            });
        }
    };

    //#region Déplacé vers le controlleur principale (produit.form.controller)
    // async createSocieteProduct(data: any): Promise<void> {
    //     try {
    //         const res = await this.ProduitsService.createSocieteProduct(data, this.produit.id);
    //         this.loadSocieteProduct();
    //     } catch (err) {
    //         // this.notification.clear();
    //         this.notification.error(err.data ?? err);
    //     }
    // }

    /* Utilisé pour sauvegarder les societe directement depuis le controlleur */
    // onCloseMultiSelect(): void {
    //     if (this.selectedSocietes.length > 0) {
    //         for (let i: number = 0; i < this.selectedSocietes.length; i++) {
    //             this.selectedSocietes[i].selected = false;
    //             this.createSocieteProduct(this.selectedSocietes[i]);
    //         }
    //         this.selectedSocietes = [];
    //     }
    // }
    //#endregion

    startLoading(): void {
        this.loading = true;
    }

    stopLoading(): void {
        this.loading = false;
    }
}
