export default class GestionStocksComposantController {
    static $inject = ['$stateParams', 'StocksCommunicationService', 'StocksService', 'notification', '$uibModal'];

    constructor($stateParams, StocksCommunicationService, StocksService, notification, $uibModal) {
        this.$stateParams = $stateParams;
        this.StocksCommunicationService = StocksCommunicationService;
        this.StocksService = StocksService;
        this.notification = notification;
        this.$uibModal = $uibModal;
    }

    async $onInit() {}

    openProductionStockee() {
        if (this.isActive) {
            const _this = this;
            _this.source = this.ligne; //cellule;
            this.$uibModal
                .open({
                    template:
                        '<gestion-stocks-production ligne="$ctrl.source" arrondi="$ctrl.arrondi" modal-instance="$ctrl.uibModalInstance"></gestion-stocks-production>',
                    controller: [
                        '$uibModalInstance',
                        function ($uibModalInstance) {
                            const $ctrl = this;
                            $ctrl.source = _this.source;
                            $ctrl.arrondi = _this.arrondi;
                            $ctrl.uibModalInstance = $uibModalInstance;
                        }
                    ],
                    controllerAs: '$ctrl',
                    size: 'xxl',
                    backdrop: false
                })
                .result.then(
                    function (result) {
                        if (result) {
                            const qte = (result[0].quantite * 100) / result[0].pourcent;
                            if (_this.productionStockeeTotal) {
                                _this.productionStockeeTotal = _this.productionStockeeTotal + qte;
                            } else {
                                _this.productionStockeeTotal = qte;
                            }
                            if (_this.consoTheoriqueTotal) {
                                _this.consoTheoriqueTotal = _this.consoTheoriqueTotal - qte;
                            } else {
                                _this.consoTheoriqueTotal = -qte;
                            }
                            _this.majProdStockee(result);
                        }
                    },
                    function (reason) {
                        // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                    }
                );
        }
    }

    openProductionRetraitee() {
        if (this.isActive) {
            const _this = this;
            _this.source = this.ligne; //cellule;
            this.$uibModal
                .open({
                    template:
                        '<gestion-stocks-production-retraitee ligne="$ctrl.source" arrondi="$ctrl.arrondi" modal-instance="$ctrl.uibModalInstance"></gestion-stocks-production-retraitee>',
                    controller: [
                        '$uibModalInstance',
                        function ($uibModalInstance) {
                            const $ctrl = this;
                            $ctrl.source = _this.source;
                            $ctrl.arrondi = _this.arrondi;
                            $ctrl.uibModalInstance = $uibModalInstance;
                        }
                    ],
                    controllerAs: '$ctrl',
                    size: 'xxl',
                    backdrop: false
                })
                .result.then(
                    function (result) {
                        if (result) {
                            const qte = (result[0].quantite * 100) / result[0].pourcent;
                            if (_this.productionRetraiteeTotal) {
                                _this.productionRetraiteeTotal = _this.productionRetraiteeTotal + qte;
                            } else {
                                _this.productionRetraiteeTotal = qte;
                            }
                            if (_this.retraitementTotal) {
                                _this.retraitementTotal = _this.retraitementTotal - qte;
                            } else {
                                _this.retraitementTotal = -qte;
                            }
                            _this.majProdRetraitee(result);
                        }
                    },
                    function (reason) {
                        // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                    }
                );
        }
    }

    openTransfert() {
        if (this.isActive) {
            const _this = this;
            _this.source = this.ligne; //cellule;
            _this.produits = this.produits;
            _this.emplacements = this.emplacements;
            this.$uibModal
                .open({
                    template:
                        '<gestion-stocks-transfert ligne="$ctrl.source" produits="$ctrl.produits" emplacements = "$ctrl.emplacements" modal-instance="$ctrl.uibModalInstance"></gestion-stocks-transfert>',
                    controller: [
                        '$uibModalInstance',
                        function ($uibModalInstance) {
                            const $ctrl = this;
                            $ctrl.source = _this.source;
                            $ctrl.produits = _this.produits;
                            $ctrl.emplacements = _this.emplacements;
                            $ctrl.uibModalInstance = $uibModalInstance;
                        }
                    ],
                    controllerAs: '$ctrl',
                    size: 'xxl',
                    backdrop: false
                })
                .result.then(
                    function (result) {
                        if (result) {
                            _this.majTransfert(result);
                        }
                    },
                    function (reason) {
                        // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                    }
                );
        }
    }

    majProdStockee(compo) {
        this.onUpdate({ composition: compo });
    }

    majProdRetraitee(compo) {
        this.onUpdate({ composition: compo });
    }

    majTransfert(dest) {
        this.onUpdate({ destinataire: dest });
    }

    openAjustement() {
        if (this.isActive) {
            const _this = this;
            _this.source = this.ligne; //cellule;
            this.$uibModal
                .open({
                    template: '<gestion-stocks-ajustement ligne="$ctrl.source" modal-instance="$ctrl.uibModalInstance"></gestion-stocks-ajustement>',
                    controller: [
                        '$uibModalInstance',
                        function ($uibModalInstance) {
                            const $ctrl = this;
                            $ctrl.source = _this.source;
                            $ctrl.uibModalInstance = $uibModalInstance;
                        }
                    ],
                    controllerAs: '$ctrl',
                    size: 'xxl',
                    backdrop: false
                })
                .result.then(
                    function (result) {
                        if (result) {
                            if (_this.ajustementTotal) {
                                _this.ajustementTotal = _this.ajustementTotal + result.quantite;
                            } else {
                                _this.ajustementTotal = result.quantite;
                            }
                            if (_this.variationTotal) {
                                _this.variationTotal = _this.variationTotal + result.quantite;
                            } else {
                                _this.variationTotal = result.quantite;
                            }
                            if (_this.stockFinalTotal) {
                                _this.stockFinalTotal = _this.stockFinalTotal + result.quantite;
                            } else {
                                _this.stockFinalTotal = result.quantite;
                            }
                        }
                    },
                    function (reason) {
                        // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                    }
                );
        }
    }

    /* openProductionNonStockee() {
        if (this.isActive) {
            var _this = this;
            _this.source = this.ligne;//cellule;
            this.$uibModal.open({
                template: '<gestion-stocks-production-non-stockee ligne="$ctrl.source" modal-instance="$ctrl.uibModalInstance"></gestion-stocks-production-non-stockee>',
                controller: ['$uibModalInstance', function ($uibModalInstance) {
                    var $ctrl = this;
                    $ctrl.source = _this.source;
                    $ctrl.uibModalInstance = $uibModalInstance;
                }],
                controllerAs: '$ctrl',
                size: 'xxl',
                backdrop: false
            }).result.then(function (result) {
                if(result)
                {
                    if(_this.productionNonStockeeTotal)
                        _this.productionNonStockeeTotal = _this.productionNonStockeeTotal + result.quantite;
                    else
                        _this.productionNonStockeeTotal = result.quantite;
                    if(_this.variationTotal)
                        _this.variationTotal = _this.variationTotal + result.quantite;
                    else
                        _this.variationTotal = result.quantite;
                    if(_this.stockFinalTotal)
                        _this.stockFinalTotal = _this.stockFinalTotal + result.quantite;
                    else
                        _this.stockFinalTotal = result.quantite;
                }
                // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                // console.info(result);
            }, function (reason) {
                // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
            });
        }
    }; */

    openProductionTraitee() {
        if (this.isActive) {
            const _this = this;
            _this.source = this.ligne; //cellule;
            this.$uibModal
                .open({
                    template:
                        '<gestion-stocks-production-traitee ligne="$ctrl.source" modal-instance="$ctrl.uibModalInstance"></gestion-stocks-production-traitee>',
                    controller: [
                        '$uibModalInstance',
                        function ($uibModalInstance) {
                            const $ctrl = this;
                            $ctrl.source = _this.source;
                            $ctrl.uibModalInstance = $uibModalInstance;
                        }
                    ],
                    controllerAs: '$ctrl',
                    size: 'xxl',
                    backdrop: false
                })
                .result.then(
                    function (result) {
                        if (result) {
                            if (_this.productionTraiteeTotal) {
                                _this.productionTraiteeTotal = _this.productionTraiteeTotal + result.quantite;
                            } else {
                                _this.productionTraiteeTotal = result.quantite;
                            }
                            if (_this.variationTotal) {
                                _this.variationTotal = _this.variationTotal + result.quantite;
                            } else {
                                _this.variationTotal = result.quantite;
                            }
                            if (_this.stockFinalTotal) {
                                _this.stockFinalTotal = _this.stockFinalTotal + result.quantite;
                            } else {
                                _this.stockFinalTotal = result.quantite;
                            }
                        }
                        // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                        // console.info(result);
                    },
                    function (reason) {
                        // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                    }
                );
        }
    }
}
