import FactureEnteteController from './facture.entete.controller';

export default {
    bindings: {
        facture: '=',
        isOpen: '=',
        isDuplicate: '='
    },
    template: require('./facture.entete.tpl.html'),
    controller: FactureEnteteController
};
