import ComponentMock from './componentMock';
import ModuleMockConfiguration from './_configMock/moduleMock.config';
import ModuleMockRouteCinfiguratuion from './_configMock/moduleMock.routes';
import MockService from './serviceMock/MockService';
import MockValidator from './componentMock/mock.validator';

const moduleName = 'app.massia.common.test';

angular
    .module(moduleName, [])
    .config(ModuleMockConfiguration)
    .config(ModuleMockRouteCinfiguratuion)
    .service('MockService', MockService)
    .component('componentMock', ComponentMock)
    .factory('MockValidator', MockValidator);

export default moduleName;
