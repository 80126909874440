(function () {
    'use strict';

    angular.module('blocks.helpers').directive('acClickOnce', acClickOnce);
    acClickOnce.$inject = ['$timeout'];

    /**
     * Directive bloquant les clics multiples en désactivant
     * le contrôle x millisecondes après le premier clic
     */
    function acClickOnce($timeout) {
        return {
            restrict: 'A', // restreint à l'attribut : <button ac-click-once ... />
            link: function (scope, element, attrs) {
                var delay = 500;
                if (attrs.acClickOnce) {
                    delay = attrs.acClickOnce;
                }
                element.bind('click', function () {
                    $timeout(function () {
                        element.attr('disabled', true);
                    }, 0);
                    $timeout(function () {
                        element.attr('disabled', false);
                    }, delay);
                });
            }
        };
    }
})();