import { Pipe } from "../../../core/decorators";
import { PipeTransform } from "../../../core/decorators/pipe";

/**
 * 
 * @param {Array} array - Array of object formated like this [{ key: "key", value: [] }]
 * @param {string} key - key used for create group
 * @param {string} libelleAs - key used for set properties
 * @param {string} valueAs - value used to defined key of array value 
 */
@Pipe({
    name: 'istevenFormat'
})
export class IstevenFormat implements PipeTransform {
    transform(array: any[], key: string, valueAs: string) {
        if (!Array.isArray(array)) throw new Error(`${array} need to be an array of object`);
        let formated: any[] = [];

        const value = array;

        const regex = /[\w]+/gm;

        for (let i = 0; i < value.length; i++) {
            const e = value[i];

            if (i != 0) {
                formated.push({
                    msGroup: false
                })
            }

            let tmp: any = { msGroup: true };

            const found = key.match(regex);

            for (let j = 0; j < found.length; j++) {
                const k = found[j];
                tmp[k] = e[k];
            }

            formated.push(tmp);
            const a = e[valueAs];
            formated = formated.concat(a);
        }

        return formated;
    }
}