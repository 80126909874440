DomainesSelectController.$inject = ['DomainesService', 'MassiaApplicationService', '_'];

export default function DomainesSelectController(DomainesService, MassiaApplicationService, _) {
    /* jshint validthis: true */
    const vm = this;

    // Propriétés
    vm.loading = false;
    vm.domaines = [];

    // Méthodes
    vm.selectDomaine = selectDomaine;
    vm.refreshDomaines = refreshDomaines;
    vm.filterDomaines = filterDomaines;

    // Evènements
    vm.$onInit = onInit;

    // Méthodes publiques
    function onInit() {
        vm.refreshDomaines();
        vm.excludes = vm.excludes || [];
        vm.clearOnSelect = vm.clearOnSelect || false;
        vm.selected = vm.selected || null;
        vm.disabled = vm.disabled || false;
    }

    function selectDomaine(item, model) {
        vm.onSelect ? vm.onSelect({ domaine: item }) : (vm.selected = item);

        if (vm.clearOnSelect) {
            vm.selected = undefined;
        }
    }

    async function refreshDomaines() {
        let data;
        try {
            data = await DomainesService.getDomaines(MassiaApplicationService.getApplication(), false);
        } catch (ex) {
            data = [];
        }
        vm.domaines = data;
        if (vm.selected && vm.selected.idDomaine) {
            vm.selected = _.find(vm.domaines, { idDomaine: vm.selected.idDomaine });
        }
    }

    function filterDomaines(item) {
        return !_.find(vm.excludes, { idDomaine: item.idDomaine });
    }
}
