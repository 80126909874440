import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default function MassiaChartPlugin() {
    let ctx;
    let chartArea;
    let color;

    /**
     *
     * @param {Chart} chartInstance
     * @param {*} easing
     */
    function updateColorChartArea(chartInstance, easing) {
        ctx = chartInstance.ctx;
        chartArea = chartInstance.chartArea;
        color = chartInstance.options?.chartAreaColor;
        if (ctx && chartArea) {
            ctx.fillStyle = color || 'rgba(0, 0, 0, 0)';
            ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
        }
    }

    Chart.register(
        {
            id: 'ColorChartArea',
            beforeDraw: updateColorChartArea
        },
        ArcElement,
        LineElement,
        BarElement,
        PointElement,
        BarController,
        BubbleController,
        DoughnutController,
        LineController,
        PieController,
        PolarAreaController,
        RadarController,
        ScatterController,
        CategoryScale,
        LinearScale,
        LogarithmicScale,
        RadialLinearScale,
        TimeScale,
        TimeSeriesScale,
        Decimation,
        Filler,
        Legend,
        Title,
        Tooltip,
        SubTitle,
        ChartDataLabels
    );
}
