export default function PendingRequestsInterceptor($httpProvider) {
    $httpProvider.interceptors.push([
        '$q',
        'HttpPending',
        function ($q, HttpPending) {
            return {
                request: function (config) {
                    // s'il n'y a pas déjà un timeout défini
                    if (config.timeout === undefined) {
                        const canceller = $q.defer();
                        // on créé un timeout par défaut
                        config.timeout = canceller.promise;
                        // on l'ajoute au service httpPending pour pouvoir l'annuler plus tard
                        HttpPending.add(config.url, canceller);
                    }

                    return config;
                },
                requestError: function (rejection) {
                    // quel que soit la réponse du serveur, si on a une url on nettoie le httpPending
                    if (rejection && rejection.config && rejection.config.url) {
                        HttpPending.remove(rejection.config.url);
                    }
                    // on retourne la reponse telle quelle
                    return $q.reject(rejection);
                },
                response: function (response) {
                    // quel que soit la réponse du serveur, si on a une url on nettoie le httpPending
                    if (response && response.config && response.config.url) {
                        HttpPending.remove(response.config.url);
                    }
                    // on retourne la reponse telle quelle
                    return response;
                },
                responseError: function (rejection) {
                    // quel que soit la réponse du serveur, si on a une url on nettoie le httpPending
                    if (rejection && rejection.config && rejection.config.url) {
                        HttpPending.remove(rejection.config.url);
                    }
                    // on retourne la reponse telle quelle
                    return $q.reject(rejection);
                }
            };
        }
    ]);
}

PendingRequestsInterceptor.$inject = ['$httpProvider'];
