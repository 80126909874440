import validatorBuilder from 'validator-builder';
import TacheEntete from './entete/tache.entete.model';
import TacheCaracteristiques from './caracteristiques/tache.caracteristiques.model';

let tacheValidator = null;

export default class Tache {
    constructor(data = {}) {
        this.id = data.id;
        this.entete = new TacheEntete(data.entete);
        this.caracteristiques = new TacheCaracteristiques(data.caracteristiques);
    }

    isValid() {
        tacheValidator = tacheValidator || validatorBuilder.getInstanceFor('Tache');
        const validationResults = tacheValidator.validate(this);
        return validationResults.isValid;
    }
}
