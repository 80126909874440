import validatorBuilder from 'validator-builder';

let documentCECaracteristiqueValidator = null;

export class DocumentCECaracteristique {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.type = data.type;
        this.caracteristiqueEssentielle = data.caracteristiqueEssentielle;
        this.certif = [];
    }

    isValid() {
        documentCECaracteristiqueValidator = documentCECaracteristiqueValidator || validatorBuilder.getInstanceFor('DocumentCECaracteristique');
        const validationResults = documentCECaracteristiqueValidator.validate(this);
        return validationResults.isValid;
    }
}

export class DocumentCeCaracteristiqueEssentielle {
    constructor(data = {}) {
        this.idValeur = data.idValeur;
        this.idDocCE = data.idDocCE;
        this.idNorme = data.idNorme;
        this.idEssai = data.idEssai;
        this.valeur = data.valeur;
        this.idCarEss = data.idCarEss;
        this.typeValeur = data.typeValeur;
        this.idCategorie = data.idCategorie;
        this.ordre = data.ordre;
    }
}
