SeriesTamisController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'SeriesTamisService',
    'notification',
    'SeriesTamisTypesService',
    'MassiaApplicationService',
    '$transitions',
    'MOPService'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function SeriesTamisController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    SeriesTamisService,
    notification,
    SeriesTamisTypesService,
    MassiaApplicationService,
    $transitions,
    MOPService
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    vm.selectedSerieTamisId = undefined;
    vm.lastSelected = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.seriesTamis = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.selectSerieTamis = selectSerieTamis;
    vm.isSerieTamisSelected = isSerieTamisSelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadSeriesTamis = loadSeriesTamis;
    vm.deleteSerieTamis = deleteSerieTamis;
    vm.setSelected = setSelected;
    vm.eraseAllFilters = eraseAllFilters;
    vm.duplicateSerieTamis = duplicateSerieTamis;
    vm.exporter = exporter;
    vm.getApplication = getApplication;
    vm.MOPService = MOPService;

    function init() {
        vm.MOPService.setMop([{ title: 'SERIES_TAMIS.BREADCRUMBS.SERIES_TAMIS_LIST', filename: 'SeriesdeTamis.pdf', application: 'laboratoire' }]);
        //écouter l'event lié au changement de route
        //une fois on change l'url, et on arrive sur la page "series-tamis.list", on désélectionne la ligne sélectionnée (s'il y en a)
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        //quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }

        getTypes();
    }

    init();

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    function isActuListe() {
        return $state.current.name.indexOf('list') >= 0;
    }

    function stateChangeSuccessFunc(event, toState) {
        if (isActuListe()) {
            vm.setSelected();
        } else {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    async function getTypes(event, toState) {
        vm.types = await SeriesTamisTypesService.getAll();
    }

    function setSelected(serie) {
        if (serie && serie.id) {
            vm.selectedSerieTamisId = $stateParams.id;
            vm.lastSelected = vm.selectedSerieTamisId;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            vm.selectedSerieTamisId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return $state && $state.current && $state.current.name && !isActuListe() && $stateParams && $stateParams.id;
    }

    function selectSerieTamis(serie) {
        if (serie && serie.id) {
            //si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedSerieTamisId, et charger la vue détail pour la serie sélectionnée
            if (vm.selectedSerieTamisId !== serie.id) {
                vm.selectedSerieTamisId = serie.id;
                showDetail(serie.id);
            } else {
                //si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
                vm.selectedSerieTamisId = undefined;
                closeDetail();
            }
        }
    }

    function isSerieTamisSelected(serie) {
        return serie && serie.id && vm.selectedSerieTamisId === serie.id;
    }

    function showDetail(serieId) {
        if (MassiaApplicationService.getApplication() == 'laboratoire') {
            $state.go('series-tamis-labo.list.detail', { id: serieId });
        } else {
            $state.go('series-tamis.list.detail', { id: serieId });
        }
    }

    function closeDetail() {
        if (MassiaApplicationService.getApplication() == 'laboratoire') {
            $state.go('series-tamis-labo.list');
        } else {
            $state.go('series-tamis.list');
        }
    }

    async function loadSeriesTamis(tableState) {
        startLoading();

        //quand on rafraîchit la grille, on revient sur "series-tamis.list", et on ferme la vue détail d'un docce
        //sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
        if ($state && $state.current && !isActuListe() /*$state.current.name !== 'series-tamis.list'*/) {
            if (MassiaApplicationService.getApplication() == 'laboratoire') {
                $state.go('series-tamis-labo.list');
            } else {
                $state.go('series-tamis.list');
            }
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.seriesTamis = [];

        try {
            const data = await SeriesTamisService.getSeriesTamis(filters, sorts, pagination);

            vm.seriesTamis = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteSerieTamis(serie) {
        if (serie && serie.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('SERIES_TAMIS.DELETE.TITLE', { libelle: serie.libelle }),
                modalMsg: $translate.instant('SERIES_TAMIS.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await SeriesTamisService.deleteSerieTamisById(serie.id);
                    notification.success($translate.instant('SERIES_TAMIS.DELETE.SUCCESS'));

                    vm.selectedSerieTamisId = undefined;

                    // si jamais la vue détail est affichée,
                    // on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
                    if (vm.params.id) {
                        if (MassiaApplicationService.getApplication() == 'laboratoire') {
                            vm.state.go('series-tamis-labo.list');
                        } else {
                            vm.state.go('series-tamis.list');
                        }
                    }
                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);
                    loadSeriesTamis();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function duplicateSerieTamis(serie) {
        if (serie && serie.id) {
            if (MassiaApplicationService.getApplication() == 'laboratoire') {
                $state.go('series-tamis-labo.duplicate', { id: serie.id });
            } else {
                $state.go('series-tamis.duplicate', { id: serie.id });
            }
        }
    }

    function eraseAllFilters() {
        loadSeriesTamis();
    }

    async function exporter(modeImpression) {
        try {
            const filters = PaginationService.getFilters(previousTableState);
            const sorts = PaginationService.getSorts(previousTableState);
            const fileName = 'Liste_series_tamis';
            let resultat = undefined;
            try {
                resultat = await SeriesTamisService.exportListeSeriesTamis(modeImpression, filters, sorts);
            } catch (ex) {
                //si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
                const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
                notification.error('SERIES_TAMIS.' + msgException);
                return false;
            }
            if (resultat) {
                const data = resultat.data;
                const status = resultat.status;
                let headers = resultat.headers;
                headers = headers();

                const contentType = headers['content-type'];

                const linkElement = document.createElement('a');
                try {
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    if (modeImpression == 0) {
                        linkElement.setAttribute('download', fileName + '.pdf');
                    } else {
                        linkElement.setAttribute('download', fileName + '.xlsx');
                    }

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                } catch (ex) {
                } finally {
                }
                return true;
            }
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        }
    }

    function getApplication() {
        return MassiaApplicationService.getApplication();
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
