import ProspectEntete from './prospect.entete.model';

export default class ProspectEnteteController {
    static $inject = ['$stateParams', 'ProspectsCommunicationService', 'ProspectsService', 'notification'];

    constructor($stateParams, ProspectsCommunicationService, ProspectsService, notification) {
        this.$stateParams = $stateParams;
        this.ProspectsCommunicationService = ProspectsCommunicationService;
        this.ProspectsService = ProspectsService;
        this.notification = notification;
    }

    async $onInit() {
        this.loading = false;
        this.codeLoading = false;
        this.isEditMode = false;
        this.unregisterReinit = this.ProspectsCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    initForm() {
        let entete = {};
        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
            entete = this.prospect.entete;
        } else {
            this.isEditMode = false;
        }
        //this.prospect.entete = new ProspectEntete(entete);
        this.getCodeAuto();
        this.getSocietes();
        this.getPays();
    }

    async getCodeAuto() {
        try {
            this.prospect.entete.estCodeAuto = await this.ProspectsService.estCodeAuto();
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async getSocietes() {
        this.startLoading();
        try {
            const res = await this.ProspectsService.getSocietes();
            this.societes = res;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getPays() {
        this.startLoading();
        try {
            const res = await this.ProspectsService.getPays();
            this.paysListe = res.items;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.prospect.entete.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.prospect.entete.codeExists = await this.ProspectsService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    numberOnly() {
        if (isNaN(this.element.valeur)) {
            this.element.valeur = null;
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
