export default class ImportBlService {
    static $inject = ['$http', 'Upload', 'notification', '$translate'];

    constructor($http, Upload, notification, $translate) {
        this.$http = $http;
        this.Upload = Upload;
        this.notification = notification;
        this.$translate = $translate;
    }

    async getSitesByUser() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-commerciaux/headers`);
        return res.data;
    }
    async getSitesByUser() {
        // get les sites qui ont déjà paramétré les typeImportBl
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-commerciaux/headers/sitesWithImportBl`);
        return res.data;
    }

    async getSiteBLs(idSite) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/gestion/sites/importBLExternes/${idSite}`);
        return res.data;
    }

    async getAllImportBLExterne() {
        const res = await this.$http.get('assets/enum/sites/importBLExterne.json');
        return res.data;
    }

    async getSiteByIdWithIdSociete(idSite) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites/societe/${idSite}`);
        return angular.copy(res.data);
    }

    async getSiteCompteOuverteBySociete(idSociete) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-clients/typeAheadPourBL/${idSociete}`);
        return angular.copy(res.data);
    }

    // Controller (sans IMPORT : isCreate = false) le fichier (ligne par ligne)
    async upload(data) {
        try {
            const isCreate = false;
            const formData = new FormData();
            formData.append('file', data.file);
            formData.append('importBl', angular.toJson(data.importBl));
            const typeImportBL = data.importBl.typeImportBL;
            // traitement du fichier sans importer dans BDD (isCreate = false )
            const res = await this.$http.post(`${__configuration.apiUrl}/massia/tickets/blExternes/${typeImportBL}/${isCreate}`, formData, {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            });
            return res.data;
        } catch (error) {
            console.error(error.data);
        }
    }

    // import des BLs du fichier dans Massia (isCreate = true)
    async createBLsImport(data) {
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('importBl', angular.toJson(data.importBl));
        const typeImportBL = data.importBl.typeImportBL;
        const isCreate = true;
        // controller le fichier et l'importer dans BDD (isCreate = true )
        const res = await this.$http.post(`${__configuration.apiUrl}/massia/tickets/blExternes/${typeImportBL}/${isCreate}`, formData, {
            transformRequest: angular.identity,
            headers: {
                'Content-Type': undefined
            }
        });
        return res.data;
    }
}
