LoginDataValidator.$inject = ['validator'];

export default function LoginDataValidator(validator) {
    const instance = new validator();

    instance.ruleFor('userName').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('password').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;
}
