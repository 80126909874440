export default class MassiaAuthService {
	static $inject = [
		'$http',
		'$q',
		'$rootScope',
		'$state',
		'AuthConstant',
		'authService',
		'localStorageService',
		'notification',
		'MassiaApplicationService',
		'MassiaClientService',
		'DataShareService'
	];

	constructor(
		$http,
		$q,
		$rootScope,
		$state,
		AuthConstant,
		authService,
		localStorageService,
		notification,
		MassiaApplicationService,
		MassiaClientService,
		DataShareService
	) {
		this.$http = $http;
		this.$q = $q;
		this.$rootScope = $rootScope;
		this.$state = $state;
		this.AuthConstant = AuthConstant;
		this.authService = authService;
		this.localStorageService = localStorageService;
		this.notification = notification;
		this.MassiaApplicationService = MassiaApplicationService;
		this.MassiaClientService = MassiaClientService;
		this.DataShareService = DataShareService;
	}

	// region events

	onLoginRequired(event, args) {
		this.refreshToken(this.MassiaApplicationService.getApplication()).then(() => {
			this.authService.loginConfirmed();
		});

		// //VP : Vu avec JO, le 10/09 : si token expired, on logout pour demander nouvelle connexion
		// this.logout();
		// this.notification.info('SESSION_EXPIRED');
	}

	onForbidden(event, args) {
		// on delog l'utilisateur
		// this.logout(); //VP, NG, JO, le 10/09/2019, trop violent, on laffiche juste le message
		// et on lui explique qu'il n'a pas les droits pour accéder à cette ressource
		this.notification.warning('USER_MISSING_RIGHT');
	}

	onHttpError(event, args) {
		// on delog l'utilisateur
		// if (args && args.status === 402)
		//     this.logout();
		// else if (args && args.status === 401) {
		//     this.logout();
		//     this.notification.info('SESSION_EXPIRED');
		// }
		// if (args && args.status === 400 && args.data)
		//     this.notification.warning(args.data);
		// else if (args && args.status === 404 && args.data)
		//     this.notification.warning(args.data);
		// else if (args && args.status === 501 && args.data)
		//     this.notification.warning(args.data);
	}

	// endregion events

	// region login logic

	/*
	 * @param loginData = {userName: <string>, password: <string>}
	 */
	login(loginData, nomApplication, action = undefined) {
		const deferred = this.$q.defer();
		const ls = this.localStorageService;

		let data =
			'grant_type=password&username=' +
			encodeURIComponent(loginData.userName) +
			'&password=' +
			encodeURIComponent(loginData.password) +
			'&client_id=' +
			this.MassiaApplicationService.getApplication(); //__configuration.clientId;
		if (__configuration.environnement) {
			data += '&environnement=' + __configuration.environnement;
		}
		if (__configuration.godUser) {
			data += '&godusername=' + __configuration.godUser;
		}
		if (action) {
			data += '&action=' + action;
		}

		this.$http
			.post(__configuration.authUrl, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
			.then((response) => {
				if (response && response.data && response.data.refresh_token) {
					//window.localStorage.clear();
					this.postLogin(response.data, nomApplication).then(() => deferred.resolve());
				} else if (response && response.data && response.data['as:error']) {
					if (response.data['as:error'] == 'LOGINERROR000002') {
						deferred.resolve('LOGINERROR000002');
					} else {
						deferred.reject({ data: { error_description: response.data['as:error'] } });
					}
				} else if (response && response.data && response.data.access_token) {
					this.postLogin(response.data, nomApplication).then(() => deferred.resolve());
				} else {
					deferred.reject({ data: { error_description: 'La connexion est impossible, erreur serveur' } });
				}
			})
			.catch((error) => {
				this.logout({ sessionExpired: false });
				deferred.reject(error);
			});

		return deferred.promise;
	}

	refreshToken(nomApplication) {
		const deferred = this.$q.defer();
		const authData = this.localStorageService.get(this.AuthConstant.MASSIA_AUTH_DATA_STORAGE);

		if (authData) {
			let data =
				'grant_type=refresh_token&refresh_token=' +
				authData.refreshToken +
				'&client_id=' +
				this.MassiaApplicationService.getApplication() +
				'&username=' +
				authData.userName; //__configuration.clientId;
			if (authData.action) {
				data += '&action=' + authData.action;
			}
			if (__configuration.environnement) {
				data += '&environnement=' + __configuration.environnement;
			}
			if (__configuration.godUser) {
				data += '&godusername=' + __configuration.godUser;
			}

			this.$http
				.post(__configuration.authUrl, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
				.then((response) => {
					if (response && response.data && response.data.refresh_token) {
						this.postLogin(response.data, nomApplication).then(() => deferred.resolve());
					} else if (response && response.data && response.data['as:error']) {
						deferred.reject({ data: { error_description: response.data['as:error'] } });
					} else if (response && response.data && response.data.access_token) {
						this.postLogin(response.data, nomApplication).then(() => deferred.resolve());
					} else {
						deferred.reject({ data: { error_description: 'La connexion est impossible, erreur serveur' } });
					}
				})
				.catch((error) => {
					this.logout({ sessionExpired: true });
					deferred.reject(error);
				});
		} else {
			this.logout({ sessionExpired: false });
		}

		return deferred.promise;
	}

	deleteToken(nomApplication) {
		const deferred = this.$q.defer();

		try {
			const authData = this.localStorageService.get(this.AuthConstant.MASSIA_AUTH_DATA_STORAGE);

			if (authData) {
				// on supprime les données d'authentification
				authData.action = 'restore';
				this.localStorageService.set(this.AuthConstant.MASSIA_AUTH_DATA_STORAGE, authData);

				const data =
					'grant_type=refresh_token&refresh_token=' +
					authData.refreshToken +
					'&client_id=' +
					this.MassiaApplicationService.getApplication() +
					'&action=delete'; //__configuration.clientId
				//this.$http.post(__configuration.authUrl, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
				const xhr = new XMLHttpRequest();
				xhr.open('POST', __configuration.authUrl);
				xhr.responseType = 'json';
				xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
				xhr.send(data);
			}
			// } else {
			//     this.logout({ sessionExpired: false });
			// }
		} catch (error) {
			deferred.reject(error);
		}

		return deferred.promise;
	}

	deleteStorageInfo() {
		const authData = this.localStorageService.get(this.AuthConstant.MASSIA_AUTH_DATA_STORAGE);

		if (authData) {
			const data =
				'grant_type=refresh_token&refresh_token=' +
				authData.refreshToken +
				'&client_id=' +
				this.MassiaApplicationService.getApplication() +
				'&action=delete'; //__configuration.clientId
			this.$http.post(__configuration.authUrl, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

			// on supprime les données d'authentification
			this.localStorageService.remove(this.AuthConstant.MASSIA_AUTH_DATA_STORAGE);
			// on supprime les données utilisateur
			this.localStorageService.remove(this.AuthConstant.MASSIA_USER_DATA_STORAGE);
			//on supprime les données en cours de synchro
			this.localStorageService.remove(this.AuthConstant.MASSIA_WAITING_DATA_STORAGE);
		}
	}

	/*
	 * @param options = {sessionExpired: <bool>}
	 */
	logout(options) {
		options = options || {};
		//on envoie une requête au serveur pour détruire le token et libérer la connexion
		this.deleteToken(this.MassiaApplicationService.getApplication());
		// on nettoie le bufferHTTP qui gardait en mémoire les requêtes en 401
		// qu'il comptait rejouer en cas de relogin en succès
		this.authService.loginCancelled();
		// on avertit quiconque est intéressé par l'événement
		this.$rootScope.$broadcast('event:auth-logout');
		// on supprime les données d'authentification
		this.localStorageService.remove(this.AuthConstant.MASSIA_AUTH_DATA_STORAGE);
		// on supprime les données utilisateur
		this.localStorageService.remove(this.AuthConstant.MASSIA_USER_DATA_STORAGE);
		// WI 1293: On clear le dataShare de toutes données de la session précedentes
		this.DataShareService.clearAll();
		// on renvoie l'utilisateur sur le login
		this.$state.go('login', { sessionExpired: options.sessionExpired });
	}

	postLogin(data, nomApplication) {
		//supression des clés temporaires
		const keys = Object.keys(localStorage);
		const sysValues = Object.values(this.AuthConstant);
		for (let i = 0; i < keys.length; i++) {
			const isPresent = sysValues.findIndex((e) => keys[i].indexOf(e) >= 0) >= 0;
			if (!isPresent) {
				localStorage.removeItem(keys[i]);
			}
		}

		// on sauvegarde les données d'authentification
		this.localStorageService.set(this.AuthConstant.MASSIA_AUTH_DATA_STORAGE, {
			token: data.access_token,
			userName: data.userName,
			refreshToken: data.refresh_token
		});

		this.localStorageService.set(this.AuthConstant.MASSIA_CLIENT_NAME, this.MassiaClientService.getClient());

		// on authentifie l'utilisateur dans l'application
		return this.authenticateUser(nomApplication);
	}

	// endregion login logic

	// region user logic

	async authenticateUser(nomApplication) {
		const deferred = this.$q.defer();

		this.$http
			.get(`${__configuration.apiUrl}/massia/current-user`, { params: { appName: nomApplication } })
			.then((response) => {
				const user = response.data;
				this.localStorageService.set(this.AuthConstant.MASSIA_USER_DATA_STORAGE, user);

				let persistsUser = this.localStorageService.get(this.AuthConstant.MASSIA_PERSISTS_USER_DATA_STORAGE);
				if (!persistsUser || persistsUser.id !== user.id) {
					persistsUser = { id: user.id, nom: user.nom, prenom: user.prenom, login: user.login };
				} else {
					persistsUser.id = user.id;
					persistsUser.nom = user.nom;
					persistsUser.prenom = user.prenom;
					persistsUser.login = user.login;
				}
				this.localStorageService.set(this.AuthConstant.MASSIA_PERSISTS_USER_DATA_STORAGE, persistsUser);

				deferred.resolve(user);
			})
			.catch((error) => {
				deferred.reject(error);
			});
		return deferred.promise;
	}

	getAuthenticatedUser() {
		return this.localStorageService.get(this.AuthConstant.MASSIA_USER_DATA_STORAGE);
	}

	isUserAuthenticated() {
		return !!this.localStorageService.get(this.AuthConstant.MASSIA_USER_DATA_STORAGE);
	}

	// endregion user logic

	async updatePreferenceUtilisateur(utilisateur, code, valeur) {
		const serviceUrl = `${__configuration.apiUrl}/massia/preferences-utilisateur`;

		const data = {
			code: code,
			valeur: valeur
		};

		const url = `${serviceUrl}/${utilisateur}`;
		return await this.$http.put(url, data);
	}
}
