import validatorBuilder from 'validator-builder';
import PaiementEntete from '../paiement-entete/paiement.entete.model';

let paiementValidator = null;

export default class Paiement {
    constructor(data) {
        if (data) {
            this.entete = new PaiementEntete(data.entete);
        } else {
            this.entete = new PaiementEntete();
        }
    }

    isValid() {
        paiementValidator = paiementValidator || validatorBuilder.getInstanceFor('Paiement');
        const validationResults = paiementValidator.validate(this);
        return validationResults.isValid;
    }
}
