import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-loading',
    bindings: {
        comment: '<',
        loading: '<',
        size: '@',
        fullsize: '<'
    },
    template: require('./massia.page.loading.tpl.html')
})
export class MassiaPageLoadingController {
    constructor() {}

    $onInit() {}
}
