const serviceUrl = `${__configuration.apiUrl}/massia/gestion/badges`;

import { getTypeESNamesByValues } from './typeses.enum';
import { getTypeAVNamesByValues } from './typesav.enum';
import { getTypeTareNamesByValues } from './typestare.enum';

export default class BadgesService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
		this.getTypesES = () => getTypeESNamesByValues();
		this.getTypesAV = () => getTypeAVNamesByValues();
		this.getTypesTare = () => getTypeTareNamesByValues();
	}

	async getBadges(filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});

		return res.data;
	}

	async getBadgeById(id) {
		const url = `${serviceUrl}/${id}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async createBadge(badge) {
		const result = await this.$http.post(serviceUrl, badge);
		return result.data;
	}

	async updateBadge(badge) {
		const url = `${serviceUrl}/${badge.id}`;
		return await this.$http.put(url, badge);
	}

	async codeExists(code) {
		const url = `${serviceUrl}/code-unicity/${code}`;
		const result = await this.$http.get(url);
		return result.data;
	}

	async getSitesCommerciaux(idSociete) {
		const url = `${__configuration.apiUrl}/massia/sites-commerciaux/header/${idSociete}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTransporteurs(valeur) {
		const url = `${__configuration.apiUrl}/massia/sites-transporteurs/typeAhead/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getClients(idSociete, valeur) {
		const url = `${__configuration.apiUrl}/massia/sites-clients/typeAheadCompteOuvert/${idSociete}/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getLieux(valeur, idClient) {
		const url = `${__configuration.apiUrl}/massia/lieux/typeAhead/${valeur}/${idClient}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTypesBenne() {
		const url = `${__configuration.apiUrl}/massia/gestion/type-bennes/headers`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getCommandes(valeur, chantiersIds) {
		let url = `${__configuration.apiUrl}/massia/commandes/header/${valeur}`;
		const idchantier = chantiersIds[0];
		if (idchantier) {
			url = `${__configuration.apiUrl}/massia/commandes/headerByChantier/${idchantier}/${valeur}`;
		}
		const res = await this.$http.get(url);
		return res.data;
	}

	async getChantiers(idClient) {
		const date = undefined;
		const url = `${__configuration.apiUrl}/massia/chantiers/header/${idClient}/${date}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getVehicules(valeur) {
		const url = `${__configuration.apiUrl}/massia/vehicules/header/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getChauffeurs(valeur) {
		const url = `${__configuration.apiUrl}/massia/personnes/chauffeurs/header/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getProduitsSelectionnables(idCommande, filtres) {
		if (idCommande) {
			var url = `${__configuration.apiUrl}/massia/produits/producteursProduits/${idCommande}`;
			const res = await this.$http.get(url);
			return res.data;
		}

		const monFiltre = JSON.stringify(filtres);
		var url = `${__configuration.apiUrl}/massia/parametres/produits/lies?filters=${monFiltre}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getZonesStockage(idSite) {
		if (idSite) {
			const url = `${__configuration.apiUrl}/massia/origines-destinations/header/${idSite}`;
			const res = await this.$http.get(url);
			return res.data;
		}
	}

	async getDocumentsCE(idProduit, idSiteProd) {
		const url = `${__configuration.apiUrl}/massia/doc-ce/header/${idProduit}/${idSiteProd}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSocietes(valeur) {
		const url = `${__configuration.apiUrl}/massia/societes-commerciales/typeAhead/${valeur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async deleteBadgeById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async getParamImpressionTicketChargement(code, filtre) {
		const monFiltre = JSON.stringify(filtre);
		const url = `${__configuration.apiUrl}/massia/parametres/${code}/valeurs?filters=${monFiltre}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getDap(idSiteCommercial, idTransporteur, idClient, idChantier, idLieu, idNomenclature, idSiteProducteur) {
		const filtres = {
			idSiteCommercial: idSiteCommercial,
			idTransporteur: idTransporteur,
			idClient: idClient,
			idChantier: idChantier,
			idLieu: idLieu,
			idNomenclature: idNomenclature,
			idSiteProducteur: idSiteProducteur
		};
		const url = `${serviceUrl}/dap`;
		const res = await this.$http.get(url, {
			params: {
				filtres: JSON.stringify(filtres || {})
			}
		});

		return res.data;
	}

	async getNomenclatures(id) {
		try {
			const res = await this.$http.get(`${__configuration.apiUrl}/massia/nomenclature/idProduit/${id}`);
			return res.data;
		} catch (err) {
			throw err.data;
		}
	}
}
