export default class DuplicateController {
    static $inject = [
        '_',
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'FormulesCommunicationService',
        'FormuleService',
        'notification',
        '$location',
        '$anchorScroll',
        '$uibModal'
    ];

    constructor(
        _,
        $scope,
        $timeout,
        $state,
        $stateParams,
        FormulesCommunicationService,
        FormuleService,
        notification,
        $location,
        $anchorScroll,
        $uibModal
    ) {
        this._ = _;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.FormulesCommunicationService = FormulesCommunicationService;
        this.FormuleService = FormuleService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.$uibModal = $uibModal;
    }

    $onInit() {
        // Propriétés
        this.formules = {};
        this.loading = false;
        this.getCompositions();
    }

    $onDestroy() {
        this.$timeout.cancel(this.updateFormuleTimeout);
    }

    async getCompositions() {
        try {
            console.log(this.source);
            this.listeAllFormules = await this.FormuleService.getAllFormules();
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    useCompo() {
        this.modalInstance.close({ selected: this.toDuplicate });
    }

    cancel() {
        this.modalInstance.dismiss();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
