export default class MassiaRightsService {
    static $inject = ['MassiaAuthService', 'MassiaApplicationService'];

    constructor(MassiaAuthService, MassiaApplicationService) {
        this.MassiaAuthService = MassiaAuthService;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    userHasRight(domain, right, application) {
        // si on pose la question avec un domaine ou un droit vide,
        // on estime que l'utilisateur a le droit
        if (!domain || !right) {
            return true;
        }

        if (application) {
            const apps = application.split(';');
            let retour = false;
            for (const i in apps) {
                if (apps[i] == this.MassiaApplicationService.getApplication()) {
                    retour = true;
                }
            }
            if (retour == false) {
                //console.error('UserRight pas droit sur application(s)',apps);
                return false;
            }
        }

        // on récupère la dernière version des accréditations de l'utilisateur
        const user = this.MassiaAuthService.getAuthenticatedUser();

        if (user && user.droits) {
            // si l'utilisateur n'a pas de droits sur le domaine,
            // on revoie faux
            if (!user.droits.hasOwnProperty(domain) || user.droits.length === 0) {
                //console.error('UserRight pas droit sur domaine',domain);
                return false;
            }

            // si le droit est présent dans le domaine demandé
            // tout va bien
            if (user.droits[domain].indexOf(right) >= 0) {
                return true;
            }
        }

        return false;
    }

    async userHasRightForState(domain, right, application) {
        // si on pose la question avec un domaine ou un droit vide,
        // on estime que l'utilisateur a le droit
        if (!domain || !right) {
            return true;
        }

        if (application) {
            const apps = application.split(';');
            let retour = false;
            for (const i in apps) {
                if (apps[i] == this.MassiaApplicationService.getApplication()) {
                    retour = true;
                }
            }
            if (retour == false) {
                //console.error('UserRight pas droit sur application(s)',apps);
                return false;
            }
        }

        // on récupère la dernière version des accréditations de l'utilisateur
        const user = await this.MassiaAuthService.getAuthenticatedUser();

        if (user && user.droits) {
            // si l'utilisateur n'a pas de droits sur le domaine,
            // on revoie faux
            if (!user.droits.hasOwnProperty(domain) || user.droits.length === 0) {
                //console.error('UserRight pas droit sur domaine',domain);
                return false;
            }

            // si le droit est présent dans le domaine demandé
            // tout va bien
            if (user.droits[domain].indexOf(right) >= 0) {
                return true;
            }
        }

        return false;
    }
}
