import { Pipe } from '../../../core/decorators';
import { PipeTransform } from '../../../core/decorators/pipe';

@Pipe({
    name: 'inputMask'
})
export class InputMask implements PipeTransform {
    /* @ngInject */
    constructor() {}

    transform(obj: any, mask: string) {
        let input = mask;
        const matches = input.match(/([\w]+)/g);
        if (matches && matches.length) {
            for (const key of matches) {
                input = input.replace(key, obj[key] || '');
            }
        }
        return input;
    }
}
