import ProduitFormEnteteController from './produit.form.entete.controller';

export default {
    bindings: {
        produit: '=',
        form: '=',
        onUpdate: '&'
    },
    template: require('./produit.form.entete.html'),
    controller: ProduitFormEnteteController
};
