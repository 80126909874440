const serviceUrl = `${__configuration.apiUrl}/massia/prestations`;

export default class PrestationsService {
    static $inject = ['$http', '$translate'];

    constructor($http, $translate) {
        this.$http = $http;
        this.$translate = $translate;
    }

    async getPrestations(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async deletePrestationById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getPrestationEnteteById(id) {
        const url = `${serviceUrl}/${id}/entete`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createPrestation(entete) {
        const result = await this.$http.post(serviceUrl, entete);
        return result.data;
    }

    async updateEntetePrestation(entete) {
        const url = `${serviceUrl}/${entete.id}`;
        return this.$http.put(url, entete);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getUnites() {
        const uniteUrl = `${__configuration.apiUrl}/massia/unites`;
        const res = await this.$http.get(uniteUrl);

        return res.data;
    }

    async getModesDeclenchement() {
        const url = 'assets/enum/prestations/modes.json';
        const res = await this.$http.get(url);
        return res.data;
    }

    async getGrillesIndicesRevision() {
        const revisionUrl = `${__configuration.apiUrl}/massia/gestion/indices-revision`;
        const res = await this.$http.get(revisionUrl);

        return res.data;
    }

    // Get l'enum des modes de calcul pour Eco contribution
    async getModesCalcul() {
        const url = 'assets/enum/prestations/modesCalcul.json';
        const res = await this.$http.get(url);
        // Traduit les libelle de l'enum
        res.data.map((x) => (x.libelle = this.$translate.instant(x.libelle)));
        return res.data;
    }
}
