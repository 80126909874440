import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-switch',
    bindings: {
        model: '=',
        disabled: '=',
        change: '&',
        label: '@'
    },
    template: require('./massia.switch.tpl.html')
})
export class MassiaSwitchController {
    constructor() {}

    $onInit() {}

    $onDestroy() {}

    onChange(model) {
        if (this.change) {
            this.change({ $model: model });
        }
    }
}
