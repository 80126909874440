import validatorBuilder from 'validator-builder';

let remiseFinAnneeValidator = null;

export default class RemiseFinAnnee {
	constructor(data) {
		data = data || {};
		this.id = data.id || undefined;
		this.code = data.code || undefined;
		this.libelle = data.libelle || undefined;
		this.dateDebut = data.dateDebut || undefined;
		this.dateFin = data.dateFin || undefined;
		this.tousClients = data.tousClients || undefined;
		this.tousProduits = data.tousProduits || undefined;
		this.tousChantiers = data.tousChantiers || undefined;
		this.remiseGlobale = data.remiseGlobale || undefined;
		this.societeId = data.societeId || undefined;
		this.societe = data.societe || undefined;
		this.societeClientId = data.societeClientId || undefined;
		this.societeClient = data.societeClient || undefined;
		this.siteCommercialId = data.siteCommercialId || undefined;
		this.siteCommercial = data.siteCommercial || undefined;
		this.remiseFinAnneeQuantites = data.remiseFinAnneeQuantites || undefined;
		this.remiseFinAnneeProduits = data.remiseFinAnneeProduits || undefined;
		this.remiseFinAnneeClients = data.remiseFinAnneeClients || undefined;
		this.remiseFinAnneeChantiers = data.remiseFinAnneeChantiers || undefined;
		this.modeCalculRemise = data.modeCalculRemise || 0;
		this.comptabiliseAvoir = data.comptabiliseAvoir || false;
	}

	isValid() {
		remiseFinAnneeValidator = remiseFinAnneeValidator || validatorBuilder.getInstanceFor('RemiseFinAnnee');
		const validationResults = remiseFinAnneeValidator.validate(this);
		return validationResults.isValid;
	}
}
