ExcelModalValidator.$inject = ['validator'];
const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export default function ExcelModalValidator(validator) {
    let instance = new validator();
    instance.ruleFor('champs').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('cell').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance
        .ruleFor('cell')
        .must((obj) => {
            let cell = obj.cell;
            if (cell && cell.length > 1) {
                let char = cell[0].toUpperCase();
                let tmp = cell.substring(1);
                let number = parseInt(tmp);
                if (alphabet.includes(char)) {
                    if (number > 0) {
                        return true;
                    }
                    return false;
                }
                return false;
            }
            return false;
        })
        .withMessage('Valid cell');
    instance.ruleFor('pour').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('pages').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    return instance;
}

ExcelFormValidator.$inject = ['validator'];
export function ExcelFormValidator(validator) {
    let instance = new validator();
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    return instance;
}
