let unregisterReinit;

export default class typesClientsController {
    static $inject = ['$stateParams', 'notification', 'CentreGestionService'];

    constructor($stateParams, notification, CentreGestionService) {
        this.$stateParams = $stateParams;
        this.CentreGestionService = CentreGestionService;
        this.notification = notification;
        this.loading = false;
        this.codeLoading = false;
    }

    async $onInit() {
        this.listes = this.listes;
    }

    async $onChanges(changesObj) {}

    $onDestroy() {}

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
