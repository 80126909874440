import SiteObjectifController from './site.objectif.controller';

export default {
    bindings: {
        site: '=',
        isOpen: '='
    },
    template: require('./site.objectif.html'),
    controller: SiteObjectifController
};
