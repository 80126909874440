export default function MassiaGestionConfiguration($stateProvider) {
    $stateProvider.state('gestion', {
        parent: 'common',
        url: '/gestion',
        abstract: true,
        views: {
            common: {
                template: '<div ui-view="gestion"></div>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            skip: true
        }
    });

    $stateProvider.state('massiasyntheses', {
        url: 'stateChangeStartEvent',
        external: true,
        rewriteUrl: function (toStateUrl) {
            let login = null;

            const strAuthData = window.localStorage.getItem('massia.MassiaUserData');
            if (strAuthData) {
                const authData = JSON.parse(strAuthData);
                if (authData && authData.login) {
                    login = authData.login;
                }
            }

            let url = `${__configuration.syntheseUrl}/ULogin.aspx?licence=${__configuration.licenceGest}&activite=gestion`;
            url = url + (login ? `&autoconnect=true&user=${login}` : '');
            return url;
        }
    });
}

MassiaGestionConfiguration.$inject = ['$stateProvider'];
