(function (angular, undefined) {
    'use strict';

    angular.module('blocks.smart.table')
        .directive('stFilterBool', ['$timeout', function ($timeout) {
            return {
                require: '^stTable',
                restrict: 'A',
                transclude: true,
                templateUrl: 'blocks/smart-table/filter-bool.html',
                scope: {
                    predicate: '=stFilterBool',
                    predicateName: '@stFilterBool'
                },
                link: function (scope, element, attr, ctrl) {
                    var timer;
                    var unregisterDestroyEvent = scope.$on('$destroy', dispose);
                    var unregisterFilterSettedEvent = scope.$on('event:stPredicateObjectSetted', predicateObjectUpdated);
                    var slideboxEl, pinnedTableEl;
                    var first = true;
                    scope.filtering = false;
                    element.addClass('st-filter');

                    // Customize item model attr used
                    scope.stFilterCustomLabelYes = attr.stFilterCustomLabelYes || 'BLOCKS_FILTERS_YES';
                    scope.stFilterCustomLabelNo = attr.stFilterCustomLabelNo || 'BLOCKS_FILTERS_NO';

                    // pour récupérer l'info quand on modifie le tableState
                    scope.$watch(function () {
                        return ctrl.tableState().search;
                    }, function (newValue, oldValue) {
                        if (newValue.predicateObject && newValue.predicateObject[scope.predicateName]) {
                            scope.predicate = {
                                yes: newValue.predicateObject[scope.predicateName],
                                no: newValue.predicateObject[scope.predicateName]
                            };
                            if (scope.predicate && first) {
                                first = false;
                                scope.predicate = {
                                    yes: newValue.predicateObject[scope.predicateName].yes,
                                    no: newValue.predicateObject[scope.predicateName].no
                                };
                                scope.setFilterInfos();
                            }
                        }
                    });

                    // On utilise le filtre custom
                    ctrl.setFilterFunction('acTableFilter');

                    scope.eraseFilter = function () {
                        // si une valeur est entrée
                        if (scope.predicate) {
                            // on l'efface
                            scope.predicate.yes = undefined;
                            scope.predicate.no = undefined;
                        }
                        // on lance ensuite la recherche à vide (pour rappatrier tout)
                        ctrl.search(undefined, scope.predicateName);
                        scope.filtering = false;
                        closePopup()
    
                    };

                    scope.filter = function () {
                        if (scope.predicate && (scope.predicate.yes || scope.predicate.no)) {
                            scope.setFilterInfos();
                            scope.applyFilter();
                        }
                    };

                    scope.setFilterInfos = function () {
                        scope.filtering = scope.predicate && (scope.predicate.yes || scope.predicate.no);
                    };

                    scope.applyFilter = function () {
                        if (scope.predicate) {
                            var yes = scope.predicate.yes || undefined;
                            var no = scope.predicate.no || undefined;

                            if (yes !== undefined || no !== undefined) {
                                ctrl.search({yes: yes, no: no}, scope.predicateName);
                            }
                        }
                        closePopup()
    
                    };

                    // Dans le cas d'une table pinned, on ferme le dropdown au scroll
                    var slidebox = globalHelpers.findAncestorByClassName(element[0], "slidebox");
                    var pinnedTable = globalHelpers.findAncestorByClassName(element[0], "st-table-pinned");

                    if (slidebox) {
                        slideboxEl = angular.element(slidebox);
                        slideboxEl.bind('scroll', onScroll);
                    }

                    if (pinnedTable) {
                        pinnedTableEl = angular.element(pinnedTable);
                        pinnedTableEl.bind('scroll', onScroll);
                    }

                    function onScroll(e) {
                        closePopup()
                    }

                    function predicateObjectUpdated(evt, predicateObject) {
                        if (angular.isObject(predicateObject) && angular.isObject(predicateObject[scope.predicateName])) {
                            scope.predicate = {
                                yes: !!predicateObject[scope.predicateName].yes,
                                no: !!predicateObject[scope.predicateName].no
                            };
                        } else {
                            scope.predicate = {};
                        }

                        scope.setFilterInfos();
                    }

                    function dispose() {
                        unregisterFilterSettedEvent();
                        unregisterDestroyEvent();
                        element.removeClass('st-filter');

                        if (timer) {
                            $timeout.cancel(timer);
                        }
                        if (slideboxEl !== undefined) {
                            slideboxEl.unbind('scroll', onScroll);
                        }
                        if (pinnedTableEl !== undefined) {
                            pinnedTableEl.unbind('scroll', onScroll);
                        }
                    }
                    function closePopup(){
                        timer = $timeout(function () {
                            scope.filter.isOpen = false;
                        });
                    }
                }
            };
        }]);
})(angular);
