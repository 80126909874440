/* eslint-disable max-depth */
/* eslint-disable complexity */
export default class SyntheseEntitiesEssaisController {
    constructor(
        $scope,
        $state,
        $stateParams,
        $translate,
        $timeout,
        notification,
        moment,
        $uibModal,
        MassiaApplicationService,
        SyntheseEntitiesService,
        SyntheseEntitiesCommunicationService,
        MesuresService,
        ReferencesControleService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.$timeout = $timeout;
        this.notification = notification;
        this.moment = moment;
        this.$uibModal = $uibModal;

        this.MassiaApplicationService = MassiaApplicationService;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
        this.MesuresService = MesuresService;
        this.ReferencesControleService = ReferencesControleService;
    }

    async $onInit() {
        try {
            this.dragOptions = {
                placeholder: 'table-row-ui-placeholder',
                revert: true,
                animation: 200,
                axis: 'y',
                'ui-preserve-size': true
            };

            this.activite = this.MassiaApplicationService.getApplication();

            const that = this;
            this.$scope.$watch('$ctrl.syntheseEntity.idReference', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    that.chargeReference(true);
                }
            });
            this.$scope.$watch('$ctrl.syntheseEntity.idModeleSynthese', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    that.getEssais();
                }
            });

            this.startLoading();

            await this.getEssais();
            await this.checkEssaisReference();
            this.stopLoading();
        } catch (err) {
            this.stopLoading();
            console.error(err);
        }
    }

    $onDestroy() {}

    async getEssais() {
        try {
            this.essaisLoaded = await this.SyntheseEntitiesService.getEssais(this.syntheseEntity.idModeleSynthese, true);
            await this.updateListeEssaisLoaded(this.syntheseEntity.listeLiensSETS);

            this.lstAllEssais = angular.copy(this.essaisLoaded);
        } catch (err) {
            console.log('erreur syntheseentities.essais.controller getEssais');
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async updateListeEssaisLoaded(listEssaiGrps) {
        try {
            if (this.essaisLoaded && this.essaisLoaded.length > 0) {
                const listEssaisId = this.essaisLoaded.map(function (e) {
                    return e.id;
                });
                const listSousEssaisLoaded = await this.SyntheseEntitiesService.getSousEssais(listEssaisId);

                // check les sous essais de la partie en bdd
                for (let index = 0; index < this.essaisLoaded.length; index++) {
                    const id = this.essaisLoaded[index].id;
                    const codeLibelle = this.essaisLoaded[index].codeLibelle;

                    this.essaisLoaded[index] = listSousEssaisLoaded.find((e) => {
                        return e.idEssai === id;
                    });
                    this.essaisLoaded[index].id = id;
                    this.essaisLoaded[index].codeLibelle = codeLibelle;
                    await this.loadTamisBySousEssai(this.essaisLoaded[index]);
                    if (listEssaiGrps && listEssaiGrps.length > 0) {
                        const aran = listEssaiGrps.findIndex((e) => {
                            return e.idEssai === id;
                        });
                        if (aran > -1) {
                            this.essaisLoaded[index].selected = true;
                        }
                    }
                }

                if (this.syntheseEntity.typeDeSynthese === 12) {
                    this.essaisLoaded = this.essaisLoaded.filter((x) => x.listeTamisHeaders && x.listeTamisHeaders.length > 0);
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    loadSousEssaisByEssai(chargeAllSousEssai) {
        try {
            const selectedEssais = this.essaisLoaded.filter((e) => {
                return e.selected;
            });
            for (let i = 0; i < selectedEssais.length; i++) {
                let selectedEssai = selectedEssais[i];
                if (!selectedEssai.listeSousEssaisHeaders) {
                    selectedEssai.listeSousEssaisHeaders = [];
                }
                if (!selectedEssai.listeTamisHeaders) {
                    selectedEssai.listeTamisHeaders = [];
                }
                if (!selectedEssai.listeDimRqHeaders) {
                    selectedEssai.listeDimRqHeaders = [];
                }

                const lienSETS = this.syntheseEntity.listeLiensSETS.findIndex((e) => {
                    return e.idEssai === selectedEssai.idEssai;
                });

                if (lienSETS < 0) {
                    //sélectionner l'essai principal par défaut -> Plus maintenant
                    //VP Mantis 3350 : Accordéon ESSAIS. Prendre par défaut les sous-essais de niveau de visibilité stats de l’essai (i.e. sur l’essai, on donne un « Niv visi stat ». Prendre les sous-essais de niveau de visibilité <= à ce niveau défini sur l’essai)

                    if (chargeAllSousEssai) {
                        // for (let ss = 0; ss < selectedEssai.listeSousEssaisHeaders.length; ss++)
                        //     selectedEssai.listeSousEssaisHeaders[ss].selected = true;
                        const ess = this.lstAllEssais.find((e) => {
                            return e.id === selectedEssai.id;
                        });
                        selectedEssai = angular.copy(ess);
                    } else {
                        const idxSsEssPrcp = selectedEssai.listeSousEssaisHeaders.findIndex((e) => {
                            return e.idEssai === selectedEssai.idEssai && e.codeLibelleSousEssai === selectedEssai.codeLibelle;
                        });
                        if (idxSsEssPrcp > -1) {
                            selectedEssai.listeSousEssaisHeaders.map((e) => {
                                e.selected = false;
                                return e;
                            });
                            selectedEssai.listeSousEssaisHeaders[idxSsEssPrcp].selected = true;
                        }
                    }

                    if (
                        this.syntheseEntity.typeDeSynthese === 1 ||
                        this.syntheseEntity.typeDeSynthese === 7 ||
                        this.syntheseEntity.typeDeSynthese === 8
                    ) {
                        selectedEssai.isComplementaire = false;
                    } else {
                        const essaiNComplementaire = this.syntheseEntity.listeLiensSETS.find(function (e) {
                            return !e.isComplementaire;
                        });
                        if (essaiNComplementaire) {
                            selectedEssai.isComplementaire = true;
                        }
                    }

                    selectedEssai.indiceSelection = this.syntheseEntity.listeSyntheseCritereValeurBlocks[0].indiceSelection;

                    if (!selectedEssai.provenancesEssai) {
                        selectedEssai.provenancesEssai = ['0', '0', '0', '0'];
                    }

                    this.syntheseEntity.listeLiensSETS.splice(0, 0, selectedEssai);

                    if (selectedEssai.tamis) {
                        for (let j = 0; j < selectedEssai.tamis.length; j++) {
                            const idxTamSel = selectedEssai.listeTamisHeaders.findIndex((e) => {
                                return e.idTamis === selectedEssai.tamis[j];
                            });
                            if (idxTamSel > -1) {
                                selectedEssai.listeTamisHeaders[idxTamSel].selected = true;
                            }
                        }
                    }
                } else {
                    delete this.syntheseEntity.listeLiensSETS[lienSETS].hide;

                    const tmpSsEss = this.syntheseEntity.listeLiensSETS[lienSETS].listeSousEssaisHeaders.find(
                        (e) => e.codeSsEss === this.syntheseEntity.listeLiensSETS[lienSETS].codeEssai
                    );
                    if (tmpSsEss) {
                        tmpSsEss.selected = true;
                    }
                }
                if (this.syntheseEntity.typeDeSynthese === 12 && selectedEssai.listeTamisHeaders && selectedEssai.listeTamisHeaders.length > 0) {
                    selectedEssai.listeTamisHeaders = selectedEssai.listeTamisHeaders.map((x) => {
                        if (x.idTamis > -1) {
                            x.selected = true;
                            x.iStevenDisabled = true;
                        }
                        return x;
                    });
                }
            }
        } catch (err) {
            console.log('erreur syntheseentities.essais.controller loadSousEssaisByEssai');
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async loadTamisBySousEssai(essai) {
        if (essai.listeSousEssaisHeaders && essai.listeSousEssaisHeaders.length > 0) {
            try {
                const lstFilteredIdSousEssai = essai.listeSousEssaisHeaders
                    .filter((e) => {
                        return e.format === 8 && e.selected;
                    })
                    .map((e) => {
                        return e.idSousEssai;
                    });
                if (lstFilteredIdSousEssai && lstFilteredIdSousEssai.length > 0) {
                    if (!essai.listeTamisHeaders || essai.listeTamisHeaders.length <= 0) {
                        const temp = await this.SyntheseEntitiesService.getTamis(lstFilteredIdSousEssai);
                        // mettre au bon format, ie Tamis/SerieTamis
                        essai.listeTamisHeaders = this.formatListeTamisHeaders(temp);
                        if (essai.listeTamisHeaders && this.syntheseEntity.typeDeSynthese == 18) {
                            essai.listeDimRqHeaders = await this.SyntheseEntitiesService.getDimensionsRemarquables();
                        }
                    }
                } else {
                    essai.listeTamisHeaders = [];
                    //essai.listeDimRqHeaders = [];
                }
            } catch (err) {
                console.log('erreur syntheseentities.essais.controller loadTamisBySousEssai');
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            }
        }
    }

    formatListeTamisHeaders(temp) {
        const tempListeTamisHeaders = [];
        for (let i = 0; i < temp.length; i++) {
            const blocSousEssai = temp[i];
            /*const grpElt = {
                idTamis: -1,
                idSerieTamis: blocSousEssai.idSerieTamis,
                label: blocSousEssai.codeLibelleSerieTamis,

                msGroup: true,
                selected: false,
            };
            tempListeTamisHeaders.push(grpElt);*/

            for (let index = 0; index < blocSousEssai.listeTamisHeaders.length; index++) {
                const tamis = blocSousEssai.listeTamisHeaders[index];
                const tamisElt = {
                    idTamis: tamis.idTamis,
                    idSerieTamis: tamis.idSerieTamis,
                    valeurTamis: tamis.valeurTamis,

                    // SL on convertit la valeur int en string car une fois dans le iSteven,
                    // 0 n'est pas considéré comme une valeur donc affiche "label"
                    label: tamis.valeurTamis.toString(),
                    selected: tamis.selected || false,
                    arrondi: tamis.arrondi,
                    unite: blocSousEssai.unite,
                    ordre: tamis.ordre,
                    idSousEssaiTamis: tamis.idSousEssaiTamis
                };

                if (this.syntheseEntity.typeDeSynthese === 12) {
                    tamisElt.iStevenDisabled = true;
                }
                tempListeTamisHeaders.push(tamisElt);
            }
        }

        return tempListeTamisHeaders;
    }

    blockTamis(d) {
        if (this.syntheseEntity.typeDeSynthese === 12) {
            d.map((x) => {
                x.iStevenDisabled = true;
                return x;
            });
        }
    }

    deleteEssai(essai, wichList) {
        let idEssai = essai.idEssai;
        if (!idEssai) {
            idEssai = essai.essaiId;
        }

        const idDel = this.syntheseEntity.listeLiensSETS.findIndex((e) => {
            return e.idEssai === idEssai;
        });
        if (idDel >= 0) {
            const essaiPresent = this.syntheseEntity.listeLiensSETS[idDel];
            if (essaiPresent && essaiPresent.listeTamisHeaders && essaiPresent.listeTamisHeaders.length > 0) {
                essaiPresent.listeTamisHeaders.map((tamis) => {
                    tamis.selected = false;
                    return tamis;
                });
            }

            this.syntheseEntity.listeLiensSETS.splice(idDel, 1);
        }

        //SP:
        const idx = this.essaisLoaded.findIndex((e) => {
            return e.id === idEssai;
        });
        if (idx > -1) {
            this.essaisLoaded[idx].selected = false;
        }
    }

    supprimerTousEssais() {
        this.syntheseEntity.listeLiensSETS.map((essaiPresent) => {
            if (essaiPresent && essaiPresent.listeTamisHeaders && essaiPresent.listeTamisHeaders.length > 0) {
                essaiPresent.listeTamisHeaders.map((tamis) => {
                    tamis.selected = false;
                    return tamis;
                });
            }
        });

        this.syntheseEntity.listeLiensSETS = [];

        this.essaisLoaded.map((e) => {
            delete e.selected;
            return e;
        });
    }

    async checkEssaisReference() {
        if (!this.reference || this.reference.id !== this.syntheseEntity.idReference) {
            if (this.syntheseEntity.idReference && this.syntheseEntity.idReference > 0) {
                this.reference = await this.ReferencesControleService.getReferenceById(this.syntheseEntity.idReference);
            } else {
                this.reference = undefined;
            }
        }

        if (this.reference && !this.syntheseEntity.carteDeControle) {
            const essais = this.reference.mesures;
            for (let i = 0; i < essais.length; i++) {
                const essaiPresent = this.syntheseEntity.listeLiensSETS.find((elt) => {
                    return elt.idEssai === essais[i].essaiId;
                });
                if (essaiPresent) {
                    if (essais[i].tamis) {
                        const lstTamis = essaiPresent.listeTamisHeaders.filter((elt) => {
                            return elt.idSousEssaiTamis === essais[i].sousEssaiId;
                        });
                        for (let j = 0; j < lstTamis.length; j++) {
                            const tamisPresent = lstTamis[j];
                            if (tamisPresent) {
                                essaiPresent.provenancesEssai[3] = '1';
                            }
                        }
                    }

                    const lstSse = essaiPresent.listeSousEssaisHeaders.filter((elt) => {
                        return elt.idEssai === essais[i].essaiId;
                    });
                    for (let j = 0; j < lstSse.length; j++) {
                        const ssEssaiPresent = lstSse[j];
                        if (ssEssaiPresent && ssEssaiPresent.codeLibelleSousEssai.startsWith(essais[i].codeEssai + ' /')) {
                            essaiPresent.provenancesEssai[3] = '1';
                        }
                    }
                }
            }
        }
    }

    async chargeReference(fromInitial = false) {
        if (!this.reference || this.reference.id !== this.syntheseEntity.idReference) {
            if (this.syntheseEntity.idReference && this.syntheseEntity.idReference > 0) {
                this.reference = await this.ReferencesControleService.getReferenceById(this.syntheseEntity.idReference);
            } else {
                this.reference = undefined;
            }
        }
        if (this.reference) {
            const essais = this.reference.mesures;
            for (let i = 0; i < essais.length; i++) {
                const idxEssPrcp = this.essaisLoaded.findIndex((e) => {
                    return e.codeLibelle === essais[i].codeEssai + ' / ' + essais[i].libelleEssai;
                });
                if (idxEssPrcp >= 0) {
                    this.essaisLoaded[idxEssPrcp].selected = true;
                    this.essaisLoaded[idxEssPrcp].showEssaisReferenceControle = true;
                }
                //n'ajouter que si pas déjà présent !!
                this.loadSousEssaisByEssai(); //attention, mets le sous-essai principal par défaut
                const essaiPresent = this.syntheseEntity.listeLiensSETS.find((elt) => {
                    return elt.idEssai === essais[i].essaiId;
                });
                if (essaiPresent) {
                    if (essais[i].tamis) {
                        const lstTamis = essaiPresent.listeTamisHeaders.filter((elt) => {
                            return elt.idSousEssaiTamis === essais[i].sousEssaiId;
                        });
                        for (let j = 0; j < lstTamis.length; j++) {
                            const tamisPresent = lstTamis[j];
                            if (tamisPresent) {
                                tamisPresent.showEssaisReferenceControle = true;
                                tamisPresent.selected = true;
                                essaiPresent.provenancesEssai[3] = '1';
                            } else if (fromInitial) {
                                tamisPresent.showEssaisReferenceControle = false;
                                tamisPresent.selected = false;
                            }
                        }
                    }

                    const lstSse = essaiPresent.listeSousEssaisHeaders.filter((elt) => {
                        return elt.idEssai === essais[i].essaiId;
                    });
                    for (let j = 0; j < lstSse.length; j++) {
                        const ssEssaiPresent = lstSse[j];
                        if (ssEssaiPresent && ssEssaiPresent.codeLibelleSousEssai.startsWith(essais[i].codeEssai + ' /')) {
                            ssEssaiPresent.showEssaisReferenceControle = true;
                            ssEssaiPresent.selected = true;
                            essaiPresent.provenancesEssai[3] = '1';
                        } else if (fromInitial) {
                            ssEssaiPresent.showEssaisReferenceControle = false;
                            ssEssaiPresent.selected = false;
                        }
                    }
                }
            }
        }
    }

    async excluEssaisHorsRef() {
        if (
            this.reference &&
            this.reference.mesures.length > 0 &&
            this.syntheseEntity.listeLiensSETS &&
            this.syntheseEntity.listeLiensSETS.length > 0
        ) {
            for (let i = 0; i < this.syntheseEntity.listeLiensSETS.length; i++) {
                const essai = this.syntheseEntity.listeLiensSETS[i];
                const essLoaded = this.essaisLoaded.find((e) => {
                    return e.idEssai === essai.idEssai;
                });

                if (essai.listeSousEssaisHeaders) {
                    for (let s = 0; s < essai.listeSousEssaisHeaders.length; s++) {
                        const sse = essai.listeSousEssaisHeaders[s];
                        if (sse.selected) {
                            const refSse = this.reference.mesures.find((elt) => {
                                return !elt.tamis && elt.sousEssaiId === sse.idSousEssai;
                            });
                            if (!refSse && (sse.codeSsEss !== essai.codeEssai || !essai.listeTamisHeaders || essai.listeTamisHeaders.length <= 0)) {
                                sse.hideEssaisHorsReference = true;
                                sse.selected = false;
                            }
                        }
                    }
                }
                if (essai.listeTamisHeaders && essai.listeTamisHeaders.length > 0) {
                    for (let t = 0; t < essai.listeTamisHeaders.length; t++) {
                        const tamis = essai.listeTamisHeaders[t];
                        if (tamis.selected) {
                            const refTamis = this.reference.mesures.find((elt) => {
                                return elt.tamis && elt.sousEssaiId === tamis.idSousEssaiTamis;
                            });
                            if (!refTamis) {
                                tamis.hideEssaisHorsReference = true;
                                tamis.selected = false;
                            }
                        }
                    }

                    const lstTmpTamis = essai.listeTamisHeaders.filter((e) => {
                        return e.selected;
                    });
                    const mainSsEssGranulo = essai.listeSousEssaisHeaders.find((e) => e.codeLibelleSousEssai.startsWith(essai.codeEssai + ' /'));
                    if (!lstTmpTamis || lstTmpTamis.length <= 0) {
                        mainSsEssGranulo.hideEssaisHorsReference = true;
                        mainSsEssGranulo.selected = false;
                    } else {
                        mainSsEssGranulo.hideEssaisHorsReference = false;
                        mainSsEssGranulo.selected = true;
                    }
                }

                essai.hideEssaisHorsReference = true;
                essLoaded.hideEssaisHorsReference = true;

                const lstTmpTamis = essai.listeTamisHeaders
                    ? essai.listeTamisHeaders.filter((e) => {
                          return e.selected;
                      })
                    : [];
                const lstTmpSse = essai.listeSousEssaisHeaders
                    ? essai.listeSousEssaisHeaders.filter((e) => {
                          return e.selected;
                      })
                    : [];
                if (lstTmpTamis.length <= 0 && lstTmpSse.length <= 0) {
                    essai.hide = true;
                    essLoaded.selected = false;
                }
            }
        } else {
            this.syntheseEntity.hideEssaisHorsReference = false;
        }
    }

    async hideEssaisNonRealises() {
        if (this.syntheseEntity.listeLiensSETS && this.syntheseEntity.listeLiensSETS.length > 0) {
            const result = await this.regenererSynthese();
            if (result && result.resultat) {
                const resultat = result.resultat;

                for (let i = this.syntheseEntity.listeLiensSETS.length - 1; i >= 0; i--) {
                    const essai = this.syntheseEntity.listeLiensSETS[i];
                    const essLoaded = this.essaisLoaded.find((e) => {
                        return e.idEssai === essai.idEssai;
                    });

                    if (essai.listeSousEssaisHeaders) {
                        for (let j = 0; j < essai.listeSousEssaisHeaders.length; j++) {
                            const sousEssai = essai.listeSousEssaisHeaders[j];
                            if (sousEssai.selected && (!essai.listeTamisHeaders || essai.listeTamisHeaders.length <= 0)) {
                                //TODO: SP ne pas mettre le sous-essai d'analyse granulo : là, ignore tous les autres sous-essais de l'essai granulo
                                const objSsEss = { idEssai: sousEssai.idEssai, idSousEssai: sousEssai.idSousEssai };
                                objSsEss.horsRef = this.getBornes(objSsEss, resultat) === undefined;
                                if (this.isSousEssaiTamisSansValeur(objSsEss, resultat)) {
                                    sousEssai.hideEssaisNonRealises = true;
                                    sousEssai.selected = false;
                                }
                            }
                        }
                    }
                    if (essai.listeTamisHeaders && essai.listeTamisHeaders.length > 0) {
                        for (let j = 0; j < essai.listeTamisHeaders.length; j++) {
                            const tamis = essai.listeTamisHeaders[j];
                            if (tamis.selected) {
                                const objTamis = { idEssai: essai.idEssai, idTamis: tamis.idTamis, idSousEssaiTamis: tamis.idSousEssaiTamis };
                                objTamis.horsRef = this.getBornes(objTamis, resultat) === undefined;
                                if (this.isSousEssaiTamisSansValeur(objTamis, resultat)) {
                                    tamis.hideEssaisNonRealises = true;
                                    tamis.selected = false;
                                }
                            }
                        }

                        const lstTmpTamis = essai.listeTamisHeaders.filter((e) => {
                            return e.selected;
                        });
                        const mainSsEssGranulo = essai.listeSousEssaisHeaders.find((e) => e.codeLibelleSousEssai.startsWith(essai.codeEssai + ' /'));
                        if (!lstTmpTamis || lstTmpTamis.length <= 0) {
                            mainSsEssGranulo.hideEssaisNonRealises = true;
                            mainSsEssGranulo.selected = false;
                        } else {
                            mainSsEssGranulo.hideEssaisNonRealises = false;
                            mainSsEssGranulo.selected = true;
                        }
                    }

                    essai.hideEssaisNonRealises = true;
                    essLoaded.hideEssaisNonRealises = true;

                    const lstTmpTamis = essai.listeTamisHeaders
                        ? essai.listeTamisHeaders.filter((e) => {
                              return e.selected;
                          })
                        : [];
                    const lstTmpSse = essai.listeSousEssaisHeaders
                        ? essai.listeSousEssaisHeaders.filter((e) => {
                              return e.selected;
                          })
                        : [];
                    if (lstTmpTamis.length <= 0 && lstTmpSse.length <= 0) {
                        essai.hide = true;
                        essLoaded.selected = false;
                    }
                }
            } else {
                this.syntheseEntity.hideEssaisNonRealises = false;
            }
        } else {
            this.syntheseEntity.hideEssaisNonRealises = false;
        }
    }

    async showEssaisRealises() {
        const result = await this.regenererSynthese();
        if (result && result.resultat) {
            const resultat = result.resultat;
            //resultat contient une liste (prelevementBlock)
            for (let i = 0; i < resultat.prelevementBlock.length; i++) {
                const prelevement = resultat.prelevementBlock[i];
                //sous-essais
                for (let j = 0; j < prelevement.listeSousEssaiLignes.length; j++) {
                    const ssEss = prelevement.listeSousEssaiLignes[j];
                    const objSsEss = { idEssai: ssEss.idEssai, idSousEssai: ssEss.idSousEssai };

                    const idxEssPrcp = this.essaisLoaded.findIndex((e) => {
                        return e.codeLibelle === ssEss.codeEssai + ' / ' + ssEss.libelleEssai;
                    });
                    if (idxEssPrcp >= 0) {
                        this.essaisLoaded[idxEssPrcp].selected = true;
                        this.essaisLoaded[idxEssPrcp].showEssaisRealises = true;
                    }

                    this.loadSousEssaisByEssai(); //attention, mets le sous-essai principal par défaut
                    const essaiPresent = this.syntheseEntity.listeLiensSETS.find((elt) => {
                        return elt.idEssai === objSsEss.idEssai;
                    });
                    if (essaiPresent) {
                        const ssEssaiPresent = essaiPresent.listeSousEssaisHeaders.find((elt) => {
                            return elt.codeSsEss === essaiPresent.codeEssai;
                        });
                        if (ssEssaiPresent) {
                            ssEssaiPresent.showEssaisRealises = true;
                            ssEssaiPresent.selected = true;
                            essaiPresent.provenancesEssai[1] = '1';
                        }
                        // else {
                        //     ssEssaiPresent.showEssaisRealises = false;
                        //     ssEssaiPresent.selected = false;
                        // }
                    }
                }
                //tamis
                for (let j = 0; j < prelevement.listeTamisLignes.length; j++) {
                    const tam = prelevement.listeTamisLignes[j];
                    const objSsEss = { idEssai: tam.idEssai, idTamis: tam.idTamis, idSousEssaiTamis: tam.idSousEssaiTamis };

                    const idxEssPrcp = this.essaisLoaded.findIndex((e) => {
                        return e.codeLibelle === tam.codeEssai + ' / ' + tam.libelleEssai;
                    });
                    if (idxEssPrcp >= 0) {
                        this.essaisLoaded[idxEssPrcp].selected = true;
                        this.essaisLoaded[idxEssPrcp].showEssaisRealises = true;
                    }

                    this.loadSousEssaisByEssai(); //attention, mets le sous-essai principal par défaut
                    const essaiPresent = this.syntheseEntity.listeLiensSETS.find((elt) => {
                        return elt.idEssai === objSsEss.idEssai;
                    });
                    if (essaiPresent) {
                        const lstTamis = essaiPresent.listeTamisHeaders.filter((elt) => {
                            return elt.idSousEssaiTamis === objSsEss.idSousEssaiTamis;
                        });
                        for (let k = 0; k < lstTamis.length; k++) {
                            const tamisPresent = lstTamis[k];
                            if (tamisPresent) {
                                tamisPresent.showEssaisRealises = true;
                                tamisPresent.selected = true;
                                essaiPresent.provenancesEssai[1] = '1';
                            }
                            // else {
                            //     tamisPresent.showEssaisRealises = false;
                            //     tamisPresent.selected = false;
                            // }
                        }

                        const lstSse = essaiPresent.listeSousEssaisHeaders.find((elt) => {
                            return elt.codeSsEss === essaiPresent.codeEssai;
                        });
                        for (let k = 0; k < lstSse.length; k++) {
                            const ssEssaiPresent = lstSse[k];
                            if (ssEssaiPresent) {
                                ssEssaiPresent.showEssaisRealises = true;
                                ssEssaiPresent.selected = true;
                                essaiPresent.provenancesEssai[1] = '1';
                            }
                            // else {
                            //     ssEssaiPresent.showEssaisRealises = false;
                            //     ssEssaiPresent.selected = false;
                            // }
                        }
                    }
                }
            }
        } else {
            this.syntheseEntity.showEssaisRealises = false;
        }
    }

    async regenererSynthese() {
        try {
            if (this.areCriteresObligatoiresRemplis()) {
                this.notification.info('SYNTHENTS.GEN_IN_PROGRESS', null, {
                    timeOut: 0,
                    extendedTimeOut: 0
                });

                const res = await this.SyntheseEntitiesService.regenererSynthese(this.syntheseEntity, this.activite);

                const objResult = { resultat: res, synthese: this.syntheseEntity };

                this.notification.clear();
                if (this.modalInstance) {
                    this.modalInstance.close(objResult);
                } else {
                    return objResult;
                }
            } else {
                this.notification.error('SYNTHENTS.CRITERES_OBLIG_MANQUANTS');
            }
        } catch (ex) {
            this.notification.clear();
            this.notification.error(ex.data);
        }
    }

    areCriteresObligatoiresRemplis() {
        for (let i = 0; i < this.syntheseEntity.listeSyntheseCritereValeurBlocks.length; i++) {
            const block = this.syntheseEntity.listeSyntheseCritereValeurBlocks[i];
            for (let j = 0; j < block.listeSyntheseCritereValeurLignes.length; j++) {
                const crit = block.listeSyntheseCritereValeurLignes[j];
                if (crit.isObligatoire) {
                    switch (crit.typeDeChamp) {
                        case 1:
                            if (!crit.valeurTextuelle) {
                                return false;
                            }
                            break;
                        case 2:
                        case 4:
                            if (!crit.valeur) {
                                return false;
                            }
                            break;
                        case 3:
                            if (crit.operateur) {
                                switch (crit.operateur) {
                                    case 1:
                                    case 2:
                                        if (!crit.valeurMaximale) {
                                            return false;
                                        }
                                    case 3:
                                    case 4:
                                    case 11:
                                        if (!crit.valeurMinimale) {
                                            return false;
                                        }
                                    case 5:
                                        if (!crit.valeurMaximale || !crit.valeurMinimale) {
                                            return false;
                                        }
                                }
                            } else {
                                return false;
                            }
                            break;
                        case 5:
                            if (crit.isGammeDate == true) {
                                if (!crit.dateDebut || !crit.dateFin) {
                                    return false;
                                }
                            } else if (crit.isGammeDate == false) {
                                if (!crit.periodeGlissante) {
                                    return false;
                                }

                                if (crit.periodeGlissante > 0 && crit.periodeGlissante < 10) {
                                    if (!crit.dateDebut) {
                                        return false;
                                    }
                                } else if (crit.periodeGlissante > 10 && crit.periodeGlissante < 20) {
                                    if (!crit.dateFin) {
                                        return false;
                                    }
                                }
                            } else {
                                return false;
                            }
                            break;
                        case 6:
                            //TODO: heure
                            break;
                        case 7:
                            if (!crit.selectedEltsTable || crit.selectedEltsTable.length == 0) {
                                return false;
                            }
                            break;
                        case 8:
                            //TODO: enum
                            break;
                    }
                }
            }
        }
        return true;
    }

    getBornes(essStat, resultat) {
        let result = undefined;

        if (resultat.referentiel) {
            if (essStat.tamis) {
                result = resultat.referentiel.mesures.find((e) => {
                    e.essaiId === essStat.idEssai && e.sousEssaiId === essStat.idSousEssaiTamis;
                });
            } else {
                result = resultat.referentiel.mesures.find((e) => {
                    e.essaiId === essStat.idEssai && e.sousEssaiId === essStat.idSousEssai;
                });
            }
        }

        return result;
    }

    isSousEssaiTamisSansValeur(objSsEss, resultat) {
        for (let idSETSV = 0; idSETSV < resultat.prelevementBlock.length; idSETSV++) {
            const res = this.getResultatEssai(resultat.prelevementBlock[idSETSV], objSsEss);
            if (res !== undefined && res !== null && res !== '') {
                return false;
            }
        }
        return true;
    }

    getResultatEssai(lig, essai) {
        let value = '';
        if (!essai.idTamis /*tamis*/) {
            const lstSousEssais = lig.listeSousEssaiLignes.filter(function (e) {
                return e.idEssai === essai.idEssai && e.idSousEssai === essai.idSousEssai;
            });
            for (let i = 0; i < lstSousEssais.length; i++) {
                const sousEssai = lstSousEssais[i];
                if (sousEssai) {
                    lstSousEssais[i] = sousEssai.valeur;
                }
            }

            if (lstSousEssais && lstSousEssais.length > 0) {
                value = lstSousEssais[0];
            } else if (essai.idNature === 10 || essai.idNature === 11) {
                value = null;
            }
        } else {
            const tamis = lig.listeTamisLignes.find((e) => {
                return e.idEssai === essai.idEssai && e.idSousEssaiTamis === essai.idSousEssaiTamis;
            });
            if (tamis) {
                value = tamis.passant;
            }
        }

        return value;
    }

    traduitSymbole(symbole) {
        return this.MesuresService.traduitSymbole(symbole);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}

SyntheseEntitiesEssaisController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    '$timeout',
    'notification',
    'moment',
    '$uibModal',
    'MassiaApplicationService',
    'SyntheseEntitiesService',
    'SyntheseEntitiesCommunicationService',
    'MesuresService',
    'ReferencesControleService'
];
