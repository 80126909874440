ImagesDownloadController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'ImagesService',
    'ImagesTypesService',
    'notification',
    '_'
];

export default function ImagesDownloadController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    ImagesService,
    ImagesTypesService,
    notification,
    _
) {
    const vm = this;
    const watchers = [];

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm._ = _;
    vm.imageLie = [];

    vm.loadImages = loadImages;
    vm.print = print;
    vm.sauvegarder = sauvegarder;
    vm.createImage = createImage;

    vm.deleteFiltre = deleteFiltre;
    vm.selectFiltre = selectFiltre;
    vm.refreshFiltre = refreshFiltre;
    vm.setSelectedFiltre = setSelectedFiltre;
    vm.preselectFiltre = preselectFiltre;

    async function loadImages() {
        startLoading();

        vm.imageLie = {};

        try {
            if (vm.id) {
                vm.imageLie = await ImagesService.getImagesDownload(vm.id, vm.domaine);

                vm.donneeFiltreeImage = [];

                vm._.forEach(vm.imageLie.images, (dFiltree) => {
                    vm.donneeFiltreeImage.push(dFiltree.idImage);
                });

                vm.filtreImages = await refreshFiltre();
                vm.preselectFiltre();
            } else {
                vm.imageLie.isVisible = false;
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    async function print(fileName, extension, code) {
        startLoading();
        const resultat = await ImagesService.print(fileName, extension, code);

        const data = resultat.data;
        const status = resultat.status;
        let headers = resultat.headers;

        headers = headers();

        const contentType = headers['content-type'];

        const linkElement = image.createElement('a');
        try {
            const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
            const url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute('download', fileName + '.' + extension);

            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(clickEvent);
        } catch (ex) {
        } finally {
            stopLoading();
        }
    }

    function createImage() {
        const modalInstance = ModalService.confirm({
            modalTitle: 'IMAGES.CONFIRM_CREATE_IMAGE.TITLE',
            modalMsg: 'IMAGES.CONFIRM_CREATE_IMAGE.MESSAGE',
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(() => $state.go('images.newImage', { idOrigine: vm.id, domaine: vm.domaine }));
    }

    function deleteFiltre(filtre) {
        const index = vm.donneeFiltreeImage.indexOf(filtre.id);
        if (index >= 0) {
            vm.donneeFiltreeImage.splice(index, 1);
            filtre.selected = false;
        }
    }

    function selectFiltre(filtre) {
        const index = vm.donneeFiltreeImage.indexOf(filtre.id);
        if (index < 0) {
            vm.donneeFiltreeImage.push(filtre.id);
        }
    }

    async function refreshFiltre() {
        let data;
        try {
            data = await ImagesService.getFiltres();
        } catch (ex) {
            data = [];
        }
        return data;
    }

    function setSelectedFiltre() {
        vm._.forEach(vm.filtreImages, (filtre) => {
            if (filtre.selected) {
                vm.selectFiltre(filtre);
            } else {
                vm.deleteFiltre(filtre);
            }
        });
    }

    function preselectFiltre() {
        vm._.forEach(vm.donneeFiltreeImage, (id) => {
            const filtre = vm._.find(vm.filtreImages, { id: id });
            if (filtre) {
                filtre.selected = true;
            }
        });
    }

    async function sauvegarder() {
        setSelectedFiltre();
        startLoading();
        try {
            await ImagesService.updateFiltre(vm.id, vm.domaine, vm.donneeFiltreeImage);
            notification.success('IMAGES.UPDATED');
        } catch (ex) {
            notification.error(ex.data);
            return false;
        } finally {
            loadImages();
            stopLoading();
        }
    }
}
