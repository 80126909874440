export default class SettingServerController {
    constructor($scope, SettingPortailService) {
        this.$scope = $scope;
        this.SettingPortailService = SettingPortailService;
    }
    $onInit() {}
    $onDestroy() {}
}

SettingServerController.$inject = ['$scope', 'SettingPortailService'];
