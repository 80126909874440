import ApiService from '../../app/services/api.service';
import { Injectable } from '../decorators';
import { BaseHttpService } from '../interface/MassiaHttpService.interface';
import { HierarchicalLevel } from '../models/HierarchicalLevel';

@Injectable('HierarchicalLevelService')
export class HierarchicalLevelService extends BaseHttpService<
    HierarchicalLevel,
    HierarchicalLevel,
    HierarchicalLevel,
    HierarchicalLevel,
    HierarchicalLevel
> {
    /* @ngInject */
    constructor(ApiService: ApiService) {
        super(ApiService);
        this.url = 'v2/massia/hierachical-levels';
    }
}
