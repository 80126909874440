import ReferenceControleMesuresController from './reference.controle.mesures.controller';

export default {
    template: require('./reference.controle.mesures.tpl.html'),
    controller: ReferenceControleMesuresController,
    bindings: {
        reset: '&',
        mesures: '=',
        onDelete: '&',
        onDeleteItem: '&',
        onDeleteAll: '&',
        domaine: '<',
        specificationsNorme: '<',
        idNorme: '<',
        onUpdateCategorie: '&',
        onUpdateCalcStat: '&',
        calculsStatistiques: '<',
        referenceControle: '<',
        onSelectMesure: '&',
        onChangeXt: '&',
        onDuplicate: '&'
        //onUpdateOrdre: '&'
    }
};
