const serviceUrl = `${__configuration.apiUrl}/massia/synthese/print-config`;

export default class PrintConfigService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getAll(syntheseType = null) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                syntheseType: syntheseType
            }
        });
        return res?.data;
    }

    async createOrUpdate(data) {
        const res = await this.$http.post(serviceUrl, data);
        return res?.data;
    }

    async delete(data) {
        const res = await this.$http.delete(`${serviceUrl}/${data.id}`);
    }
}
