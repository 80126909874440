import TypeBascule from './type.bascules.model';

export default class TypeBasculeFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'PariteTypeBasculesService',
        'TypeBasculesCommunicationService',
        'TypeBasculesService',
        'notification',
        '$location'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        PariteTypeBasculesService,
        TypeBasculesCommunicationService,
        TypeBasculesService,
        notification,
        $location
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.PariteTypeBasculesService = PariteTypeBasculesService;
        this.TypeBasculesCommunicationService = TypeBasculesCommunicationService;
        this.TypeBasculesService = TypeBasculesService;
        this.notification = notification;
        this.$location = $location;
    }

    async $onInit() {
        this.typeBascule = {};
        this.loading = false;
        await this.loadFormContent(this.$stateParams.id);
        this.unregisterReinit = this.TypeBasculesCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    initForm() {
        let thatTypeBascule = {};
        if (this.$stateParams && this.$stateParams.id) {
            thatTypeBascule = {
                id: this.typeBascule.id,
                code: this.typeBascule.code,
                libelle: this.typeBascule.libelle,
                transmissionVitesse: this.typeBascule.transmissionVitesse,
                transmissionBitStart: this.typeBascule.transmissionBitStart,
                transmissionBitStop: this.typeBascule.transmissionBitStop,
                transmissionBitDate: this.typeBascule.transmissionBitDate,
                transmissionParite: this.typeBascule.transmissionParite,
                connexionAdresseIP: this.typeBascule.connexionAdresseIP,
                connexionPort: this.typeBascule.connexionPort,
                connexionProtocole: this.typeBascule.connexionProtocole
            };
        }
    }

    async loadFormContent(typeBasculeId) {
        this.startLoading();

        // Commence le chargement des promises
        const promises = [this.loadParite(), this.loadProtocole(), this.setTypeBascule(typeBasculeId)];
        try {
            await Promise.all(promises);

            if (typeBasculeId) {
                this.setCommunication();
            }
        } catch (ex) {
            this.$state.go('type-bascules.list', {}, { reload: true });
            this.notification.error(ex.data);
        }

        this.stopLoading();
    }

    async loadParite() {
        return (this.parites = await this.PariteTypeBasculesService.getParites());
    }

    async loadProtocole() {
        return (this.protocoles = await this.TypeBasculesService.getProtocoles());
    }

    async setTypeBascule(typeBasculeId) {
        if (typeBasculeId) {
            const stillThatTypeBascule = await this.TypeBasculesService.getTypeBasculeById(typeBasculeId);
            this.typeBascule = new TypeBascule(stillThatTypeBascule);
        } else {
            this.typeBascule = new TypeBascule();
            this.typeBascule.connexionProtocole = 0;
        }
    }

    setCommunication() {
        if (this.typeBascule.isSerieIp === 1) {
            this.isCommunicationIp = true;
        } else {
            this.isCommunicationSerie = true;
        }
    }

    changeCommunicationSerie() {
        if (this.isCommunicationSerie) {
            this.isCommunicationIp = false;
        }
    }

    changeCommunicationIp() {
        if (this.isCommunicationIp) {
            this.isCommunicationSerie = false;
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.typeBascule.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.typeBascule.codeExists = await this.TypeBasculesService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    confirmer() {
        if (this.isCommunicationSerie) {
            this.typeBascule.isSerieIp = 0;
        } else {
            this.typeBascule.isSerieIp = 1;
        }
        if (this.checkValidity()) {
            if (this.typeBascule.id) {
                this.updateTypeBascule();
            } else {
                this.createTypeBascule();
            }
        }
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('type-bascules.list');
    }

    reinit() {
        this.setTypeBascule(this.$stateParams.id);
        this.TypeBasculesCommunicationService.raiseReinit();
    }

    checkValidity() {
        let validity = true;
        if (!this.typeBascule.isValid()) {
            validity = false;
        }
        this.$scope.$broadcast('typeBasculeValidations');
        return validity;
    }

    async createTypeBascule() {
        this.startLoading();
        try {
            await this.TypeBasculesService.createTypeBascule(this.typeBascule);
            this.notification.success('TYPEBASCULES.CREATED');
            this.backToPreviousState();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateTypeBascule() {
        this.startLoading();
        try {
            await this.TypeBasculesService.updateTypeBascule(this.typeBascule);
            this.notification.success('TYPEBASCULES.UPDATED');
            this.backToPreviousState();
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
