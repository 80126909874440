import Transport from './transport.model';
import TransportEntete from '../transport-entete/transport.entete.model';

export default class TransportFormController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'TransportsCommunicationService',
		'TransportsService',
		'notification',
		'$location',
		'$anchorScroll',
		'MassiaApplicationService',
		'$uibModal',
		'MOPService'
	];

	constructor(
		$scope,
		$state,
		$stateParams,
		TransportsCommunicationService,
		TransportsService,
		notification,
		$location,
		$anchorScroll,
		MassiaApplicationService,
		$uibModal,
		MOPService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.TransportsCommunicationService = TransportsCommunicationService;
		this.TransportsService = TransportsService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$uibModal = $uibModal;
		this.MOPService = MOPService;
	}

	$onInit() {
		this.MOPService.setMop([
			{ title: 'TRANSPORTS.BREADCRUMBS.TRANSPORTS_LIST', filename: 'ModeOperatoirePlanningsTransport.pdf', application: 'gestion' }
		]);
		this.isEditMode = false;
		this.transport = {};
		this.loading = false;

		this.closeAllOnglets();
		this.openFirstOnglet();
		this.setTransport(this.$stateParams.id);
	}

	$onDestroy = () => {
		this.MOPService.resetMop();
	};

	async setTransport(transportId) {
		if (transportId) {
			this.startLoading();
			try {
				this.isEditMode = true;
				await this.setTransportEntete(transportId);
			} catch (ex) {
				if (ex.status === 404) {
					this.$state.go('transports.list', {}, { reload: true });
				}
				this.notification.error(ex.data);
			} finally {
				this.stopLoading();
			}
		} else {
			this.isEditMode = false;
			this.transport = new Transport();
		}
	}

	async setTransportEntete(transportId) {
		const entete = await this.TransportsService.getTransportEnteteById(transportId);
		this.transport.entete = new TransportEntete(entete);
	}

	async sauvegarder() {
		let id = undefined;
		if (!this.checkValidity()) {
			return;
		}

		try {
			if (this.isEditMode) {
				id = await this.TransportsService.updateEnteteTransport(this.transport.entete);
				this.notification.success('TRANSPORTS.UPDATED');
			} else {
				id = await this.TransportsService.createTransport(this.transport.entete);
				this.notification.success('TRANSPORTS.CREATED');
			}
		} catch (ex) {
			this.notification.error(ex.data);
		}

		return id;
	}

	async valider() {
		const id = await this.sauvegarder();
		if (!this.isEditMode) {
			if (id) {
				this.$state.go('transports.edit', { id: id });
			}
		} else {
			this.$state.reload();
		}
	}

	async validerEtFermer() {
		this.sauvegarder();
		this.backToPreviousState();
	}

	openFirstOnglet() {
		this.ongletOpen = {
			isEnteteOpen: true
		};
	}

	closeAllOnglets() {
		this.ongletOpen = {
			isEnteteOpen: false
		};
	}

	annuler() {
		this.backToPreviousState();
	}

	backToPreviousState() {
		this.$state.go('transports.list');
	}

	reinit() {
		this.openFirstOnglet();
		this.setTransport(this.$stateParams.id);
		this.TransportsCommunicationService.raiseReinit();
	}

	checkValidity() {
		this.closeAllOnglets();
		let validity = true;

		let firstScroll = true;
		if (!this.transport.entete.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}

			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('transportEnteteValidations');
		}

		return validity;
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	open(domaine) {
		const _this = this;
		_this.source = this.transport;
		_this.source.id = this.transport.entete.id;
		_this.domaine = domaine;
		_this.isTransport = true;
		this.$uibModal
			.open({
				template:
					'<centre-gestion source="$ctrl.source" domaine="$ctrl.domaine" is-transport="$ctrl.isTransport" modal-instance="$ctrl.uibModalInstance"></centre-gestion>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.source = _this.source;
						$ctrl.domaine = _this.domaine;
						$ctrl.isTransport = _this.isTransport;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(
				function (result) {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
				},
				function (reason) {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}
}
