import { ICompileService, IDocumentService, IRootScopeService } from "angular";
import { Injectable } from "../../../core/decorators";
@Injectable('DialogService')
export class DialogService {
    $document: IDocumentService;
    $compile: ICompileService;
    scope: IRootScopeService;

    dialog: any;

    static $inject = ['$rootScope', '$document', '$compile'];
    constructor($scope: IRootScopeService, $document: IDocumentService, $compile: ICompileService) {
        this.$document = $document;
        this.$compile = $compile;
        this.scope = $scope.$new(true);
        console.log('dialogsvc')
    }

    $onInit() { }
    $onDestroy() { }

    openDialog(component: string, options: DialogOptions): void {
        let name: string = this.toKebabCase(component);
        var template = '<' + name;

        if (options.data) {
            for (const key in options.data) {
                if (Object.prototype.hasOwnProperty.call(options.data, key)) {
                    const m = options.data[key];
                    //this.scope[key] = m;
                    template += ` ${this.toKebabCase(key)}=${key}`;
                }
            }
        }

        template += `></${name}>`;
        this.$document.find('massia-application').eq(0).append(this.$compile(template)(this.scope));
    }

    toKebabCase(string: string): string {
        return string.replace(/([A-Z])/g, (correspondance) => `-${correspondance.toLowerCase()}`);
    }
}

interface DialogOptions {
    data: any;
    width: string
}
