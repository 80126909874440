import { DocumentCECaracteristique, DocumentCeCaracteristiqueEssentielle } from './document.ce.caracteristique.model';

export default class CaracteristiqueController {
    constructor(
        $state,
        $stateParams,
        DocumentsCEService,
        NormesService,
        TableauZaService,
        $scope,
        MesuresService,
        _,
        TemplateImpressionService,
        notification,
        $translate
    ) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.documentCEService = DocumentsCEService;
        this.normesService = NormesService;
        this.tableauZaService = TableauZaService;
        this.$scope = $scope;
        this.mesuresService = MesuresService;
        this._ = _;
        this.TemplateImpressionService = TemplateImpressionService;
        this.notification = notification;
        this.$translate = $translate;
    }

    async $onInit() {
        this.dragOptions = {
            placeholder: 'table-row-ui-placeholder',
            axis: 'y',
            animation: 200,
            'ui-floating': true,
            'ui-preserve-size': true
        };
        this.checkModel = false;
        this.selectedNormes = [];
        if (this.$stateParams.id) {
            this.isEditMode = true;
        }
        this.docce = this.docce || {};
        this.caracteristique = new DocumentCECaracteristique(this.docce.caracteristique);
        if (!this.caracteristique.type) {
            this.caracteristique.type = 'granulat';
        }
        this.caracteristique.certif = [];
        this.unregisterNorme = this.$scope.$watch(
            () => this.normes,
            () => {
                this.getSelectedNormes();
            },
            true
        );
        this.unregisterProduit = this.$scope.$watch(
            () => this.docce.entete.idProduit,
            (newValue, oldValue) => {
                this.getdD();
            },
            true
        );

        await this.getSelectedNormes();
        await this.getEssaisList();
    }

    $onDestroy() {
        this.unregisterNorme();
        this.unregisterProduit();
    }

    $onChange(objChange) {
        this.getSelectedNormes();
    }

    $doCheck() {
        if (!angular.equals(this.caracteristique, this.docce.caracteristique)) {
            this.onUpdate({
                caracteristique: angular.copy(this.caracteristique)
            });
        }
    }

    async getSelectedNormes() {
        try {
            this.selectedNormes = this.normes ? this.normes : this.docce.entete.normesIds;
            //rechercher les catégories des normes
            const categories = {};
            const normeTab = [];
            this.caracteristique.caracteristiqueEssentielle = [];

            if (this.selectedNormes) {
                for (let i = 0; i < this.selectedNormes.length; i++) {
                    const normeId = this.selectedNormes[i];
                    var tableauZa = {};
                    const data = await this.tableauZaService.getTableauZaByNormeId({ id: normeId.id || normeId });
                    if (data.data[0] && data.data.length !== 0) {
                        //prendre le bon type granulat ou filler
                        tableauZa = {
                            tableauZa: data.data[0].tableauZa,
                            carEss: data.data
                        };
                    }
                    if (this.docce.id) {
                        const essai = await this.documentCEService.getEssaiByDocCEId(Number(this.docce.id), normeId.id || normeId);
                        if (essai && essai.length !== 0) {
                            if (this._.isEmpty(tableauZa)) {
                                tableauZa = {
                                    tableauZa: { normeLibelle: essai[0].normeLibelle, idNorme: normeId.id || normeId },
                                    carEss: []
                                };
                            }
                            essai.forEach((element) => {
                                const carEssOk = this._.find(tableauZa.carEss, function (eltCar) {
                                    return eltCar.essais.id == element.essaiId && eltCar.caractEssentielle.id == element.idCarEss;
                                });
                                if (carEssOk && carEssOk.typeValeur == element.typeValeur) {
                                    carEssOk.valeur = element.valeur;
                                    carEssOk.idCategorie = element.idCategorie;
                                    carEssOk.idValeur = element.idValeur;
                                    carEssOk.ordre = element.ordre;
                                } else {
                                    tableauZa.carEss.push({
                                        caractEssentielle: { id: null, code: null, libelle: null },
                                        essais: {
                                            id: element.essaiId,
                                            libelle: element.essaiLibelle,
                                            unite: element.essaiUnite
                                        },
                                        valeur: element.valeur,
                                        typeValeur: element.typeValeur,
                                        idCategorie: element.idCategorie,
                                        idValeur: element.idValeur,
                                        ordre: element.ordre
                                    });
                                }
                            });
                        } else if (data.data.length === 0) {
                            const norme = await this.normesService.getNormeById(normeId.id || normeId);
                            tableauZa = { tableauZa: { normeLibelle: norme.libelle, idNorme: normeId.id || normeId } };
                        }
                    } else if (data.data && data.data.length === 0) {
                        const norme = await this.normesService.getNormeById(normeId.id || normeId);
                        tableauZa = { tableauZa: { normeLibelle: norme.libelle, idNorme: normeId.id || normeId } };
                    }
                    if (tableauZa.carEss && tableauZa.carEss.length > 0) {
                        tableauZa.carEss.sort((a, b) => a.ordre - b.ordre);
                    }
                    normeTab.push(angular.copy(tableauZa));
                    this.getdD();
                    this.caracteristique.caracteristiqueEssentielle = this.normesTabs = normeTab;
                    const categNorme = await this.normesService.getCategories(normeId.id || normeId);
                    categories[normeId.id || normeId] = angular.copy(categNorme);
                    this.verifyTableauZa();
                    this.getCertification();
                }
            }
            this.categories = categories;
        } catch (err) {
            console.log(err);
        }
    }

    async getdD() {
        if (this.docce.entete.idProduit) {
            try {
                const data = await this.documentCEService.getProduitById(this.docce.entete.idProduit);
                this.dD = data.petitd + ' / ' + data.grandD;
            } catch (err) {
                console.warn(err);
            }
        }
    }

    async getEssaisList() {
        let data;
        try {
            data = await this.mesuresService.getMesuresList('essais');
            this.essaisList = data;
        } catch (ex) {
            this.notification.error(ex.data);
            data = [];
        }
    }

    async printDocCENorme(norme) {
        try {
            const res = await this.TemplateImpressionService.downloadModal('docce');
            const template = await this.documentCEService.print(this.docce.id, norme.tableauZa.idNorme, res.filename, true);
            template.pdf = res.pdf;
            if (res === 'cancel') {
                throw res;
            }
            this.notification.info(this.$translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
                autoDismiss: false
            });
            await this.TemplateImpressionService.downloadTemplate(template.filename, template, 'docce');
        } catch (err) {
            console.error(err);
        }
    }

    onSelectEssai(data, line, list) {
        line.essais = data;
    }

    addEssai(index, norme) {
        const data = {
            edit: true,
            caractEssentielle: {
                code: null,
                id: null,
                libelle: null
            },
            essais: {
                id: null,
                code: null,
                libelle: null,
                symbole: null,
                unite: null
            },
            typeValeur: null,
            idCategorie: null,
            idValeur: null
        };
        if (norme.carEss) {
            norme.carEss.push(data);
        } else {
            norme.carEss = [];
            norme.carEss.push(data);
        }
    }

    saveEssai(norme, line) {
        line.edit = false;
        line.tableauZa = angular.copy(norme.tableauZa);
        line.tableauZa.type = this.caracteristique.type;
        this.essaisList = this.essaisList.map((e) => {
            e.selected = false;
            return e;
        });
    }

    cancelEssai(norme, index) {
        norme.splice(index, 1);
    }

    deleteEssai(norme, elt) {
        const indice = norme.carEss.indexOf(elt);
        norme.carEss.splice(indice, 1);
    }

    verifyTableauZa() {
        if (this.caracteristique && this.caracteristique.caracteristiqueEssentielle) {
            this.caracteristique.caracteristiqueEssentielle.forEach((element) => {
                if (element.carEss) {
                    element.carEss.forEach((ele) => {
                        if (ele.tableauZa && ele.tableauZa.type && ele.tableauZa.type !== this.caracteristique.type) {
                            ele.hide = true;
                        } else {
                            ele.hide = false;
                        }
                    });
                }
            });
        }
    }

    getCertification() {
        if (this.docce.caracteristique && this.docce.caracteristique.caracteristiqueEssentielle) {
            this.docce.caracteristique.caracteristiqueEssentielle.forEach((element) => {
                if (this.certif && this.certif.length > 0) {
                    this.certif.forEach((ele) => {
                        if (element.tableauZa.idNorme === ele.idNorme) {
                            element.certification = ele.certif;
                        }
                    });
                }
            });
        }
        this.normesTabs.forEach((element) => {
            if (this.certif && this.certif.length > 0) {
                this.certif.forEach((ele) => {
                    if (element.tableauZa.idNorme === ele.idNorme) {
                        element.certification = ele.certif;
                    }
                });
            }
        });
    }
}

CaracteristiqueController.$inject = [
    '$state',
    '$stateParams',
    'DocumentsCEService',
    'NormesService',
    'TableauZaService',
    '$scope',
    'MesuresService',
    '_',
    'TemplateImpressionService',
    'notification',
    '$translate'
];
