import validatorBuilder from 'validator-builder';
import PrelevementMesure from './prelevement.mesure.model';

let prelevementValidator = null;

export default class Prelevement {
    constructor(data = {}, moment, dateFormat) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.origin = data.origin;
        this.date = this.convertToDate(data.date, moment, dateFormat);
        this.heure = /*(this.heure ??*/ (data.heure ?? this.getHours(data.date, moment, dateFormat)) /*)*/ || null;
        this.datePrecedente = angular.copy(this.date);
        this.longitude = data.longitude;
        this.latitude = data.latitude;
        this.conformite = data.conformite;
        this.cloture = data.cloture;
        this.chart = data.chart;

        this.personneFaitPar = data.personneFaitPar || {};
        this.pointMesure = data.pointMesure || {};
        this.type = data.type || {};
        this.reference = data.reference || {};
        this.norme = data.norme || {};
        this.ftpa = data.ftpa || {};

        this.utilisateur = data.utilisateur || {};
        this.typeProduit = data.typeProduit || {};
        this.trame = data.trame || {};
        this.laboratoire = data.laboratoire || {};
        this.producteur = data.producteur || {};
        this.produit = data.produit || {};
        this.produitCommercial = data.produitCommercial || {};
        this.client = data.client || {};
        this.auditeur = data.auditeur || {};
        this.bonLivraison = data.bonLivraison || {};

        this.campagne = data.campagne || {};

        this.caracteristiques = data.caracteristiques || null;
        this.caracteristiqueDtos = data.caracteristiqueDtos || null;

        this.mesures = data.mesures || [];
        this.mesures.map((mesure) => this.structureSsEssais(mesure));

        this.materiels = data.materiels || [];

        this.analyseGranulo = data.analyseGranulo || {};

        this.refreshDate = 0;

        this.codeExists = data.codeExists || null;
        this.estCodeAuto = data.estCodeAuto;
        this.afficheCode = data.afficheCode;
        this.niveauVisibilite = data.niveauVisibilite || 0;
        this.famille = data.famille || null;
        this.chantier = data.chantier;
        this.modeleSaisie = data.modeleSaisie || {};
    }

    isValid() {
        prelevementValidator = prelevementValidator || validatorBuilder.getInstanceFor('Prelevement');
        const validationResults = prelevementValidator.validate(this);
        return validationResults.isValid;
    }

    updateHeader(data, moment, dateFormat) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.origin = data.origin;
        this.date = this.convertToDate(data.date, moment, dateFormat);
        this.heure = /*(this.heure ??(*/ data.heure ?? this.getHours(data.date, moment, dateFormat);
        // this.heure = data.heure ? data.heure : this.getHours(data.date, moment, dateFormat);
        this.longitude = data.longitude;
        this.latitude = data.latitude;
        this.conformite = data.conformite;
        this.cloture = data.cloture;
        this.chart = data.chart;

        this.personneFaitPar = data.personneFaitPar || {};
        this.pointMesure = data.pointMesure || {};
        this.type = data.type || {};
        this.reference = data.reference || {};
        this.norme = data.norme || {};

        this.utilisateur = data.utilisateur || {};
        this.typeProduit = data.typeProduit || {};
        this.trame = data.trame || {};
        this.laboratoire = data.laboratoire || {};
        this.producteur = data.producteur || {};
        this.produit = data.produit || {};
        this.client = data.client || {};
        this.auditeur = data.auditeur || {};

        this.codeExists = data.codeExists || null;
        this.estCodeAuto = data.estCodeAuto;
        this.afficheCode = data.afficheCode;
        this.niveauVisibilite = data.niveauVisibilite || 0;
    }

    outsourceModel(moment, dateFormat) {
        if (this.initialReferenceId) {
            this.reference.id = this.initialReferenceId;
            this.initialReferenceId = null;
        }

        if (this.date) {
            const date = moment(this.date, dateFormat).toDate();

            const hours = this.heure;
            if (hours && !isNaN(hours.getTime())) {
                this.date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours.getHours(), hours.getMinutes());
            } else {
                this.date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), null, null);
            }

            this.date = moment(this.date).format(dateFormat + ' HH:mm');
        }

        this.mesures.map((essai) => {
            for (const key in essai.sousEssais) {
                if (key.startsWith('_')) {
                    if (!parseInt(key.substring(1))) {
                        delete essai.sousEssais[key];
                    }
                } else if (!parseInt(key)) {
                    delete essai.sousEssais[key];
                }
            }
            essai.sousEssais = Object.values(essai.sousEssais).filter((e) => e.hasOwnProperty('id'));

            essai.sousEssais.map((ssEss) => {
                if (ssEss.generiqueAdditionalInformation && ssEss.generiqueAdditionalInformation.essaiLie) {
                    for (const key in ssEss.generiqueAdditionalInformation.essaiLie.sousEssais) {
                        if (key.startsWith('_')) {
                            if (!parseInt(key.substring(1))) {
                                delete ssEss.generiqueAdditionalInformation.essaiLie.sousEssais[key];
                            }
                        } else if (!parseInt(key)) {
                            delete ssEss.generiqueAdditionalInformation.essaiLie.sousEssais[key];
                        }
                    }
                    ssEss.generiqueAdditionalInformation.essaiLie.sousEssais = Object.values(
                        ssEss.generiqueAdditionalInformation.essaiLie.sousEssais
                    ).filter((e) => e.hasOwnProperty('id'));

                    for (let i = 0; i < ssEss.generiqueAdditionalInformation.essaiLie.sousEssais.length; i++) {
                        const sse = ssEss.generiqueAdditionalInformation.essaiLie.sousEssais[i];
                        sse.valeurs = sse.value;
                        delete sse.value;
                    }

                    delete ssEss.value;
                } else if (ssEss.value || ssEss.value === 0) {
                    ssEss.valeur = ssEss.value.constructor === Array ? null : ssEss.value;
                    ssEss.valeurs = ssEss.value.constructor === Array ? ssEss.value : null;

                    delete ssEss.value;
                } else if (!ssEss.value) {
                    ssEss.valeur = null;
                    ssEss.valeurs = null;

                    delete ssEss.value;
                }

                if (ssEss.default && ssEss.default.startsWith('@') && ssEss.formule === ssEss.default) {
                    ssEss.default = ssEss.formule;
                    ssEss.formule = null;
                }
            });
        });
    }

    convertToDate(date, moment, dateFormat) {
        let res = null;
        if (!date) {
            date = new Date();
        }

        const d = moment(date, dateFormat).toDate();
        //this.heure = this.heure ?? d;
        if (moment) {
            res = moment(d, moment.ISO_8601).format(dateFormat);
        } else {
            res = `${d.getDate().toString().padStart(2, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getFullYear()}`;
        }

        return res;
    }

    getHours(date, moment, dateFormat) {
        const d = moment(date, dateFormat + ' HH:mm').toDate();

        if (d.getHours() > 22 || d.getHours() < 4) {
            d.setHours(null);
            d.setMinutes(null);
        }

        if (d.getHours() === 0 && d.getMinutes() === 0) {
            return null;
        }

        return d;
    }

    structureSsEssais(essai) {
        if (essai.hasOwnProperty('sousEssais')) {
            const lstSsEss = essai.sousEssais.map((ssEss) => {
                if (ssEss.generiqueAdditionalInformation && ssEss.generiqueAdditionalInformation.essaiLie) {
                    ssEss.generiqueAdditionalInformation.essaiLie = this.structureSsEssais(ssEss.generiqueAdditionalInformation.essaiLie);
                    for (const k in ssEss.generiqueAdditionalInformation.essaiLie.sousEssais) {
                        const ssess = ssEss.generiqueAdditionalInformation.essaiLie.sousEssais[k];

                        if (essai.isNew) {
                            ssess.isNew = true;
                        }

                        ssess.value = ssess.valeurs.map((x) => {
                            return ssess.isNew && !x && x !== 0 ? ssess.default : x;
                        });
                        ssess.isNew = false;
                    }
                } else if (ssEss.generiqueAdditionalInformation) {
                    ssEss.generiqueAdditionalInformation = null;
                }

                if (ssEss.default && ssEss.default.startsWith('@') && !ssEss.formule) {
                    ssEss.formule = ssEss.default;
                }
                ssEss.isNew = true;

                return ['_' + ssEss.id, new PrelevementMesure(ssEss).data];
            });

            const entries = new Map(lstSsEss);
            essai.sousEssais = Object.fromEntries(entries);
        }
        essai.isNew = false;
        return essai;
    }
}
