const serviceUrl = `${__configuration.apiUrl}/massia/prelevements/campagnes`;

import Prelevement from '../components/prelevement/prelevement.model';

export default class CampagnesService {
    static $inject = ['$http', 'PrelevementsService'];

    constructor($http, PrelevementsService) {
        this.$http = $http;
        this.PrelevementsService = PrelevementsService;
    }

    async deleteCampagneById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getCampagneEnteteById(id) {
        const url = `${serviceUrl}/${id}/entete`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getCampagneSaisiesById(id) {
        const url = `${serviceUrl}/${id}/saisies`;
        const res = await this.$http.get(url);
        const resultat = res.data;
        for (let i = 0; i < resultat.saisies.length; i++) {
            resultat.saisies[i] = new Prelevement(resultat.saisies[i]);
            const result = {};
            const data = resultat.saisies[i].caracteristiques;
            //traiter les caractéristiques
            for (const element in data) {
                if (data[element] && !Array.isArray(data[element])) {
                    if (data[element].value === 'True') {
                        data[element].value = 'true';
                    }
                    if (data[element].value === 'False') {
                        data[element].value = 'false';
                    }

                    if (data[element].value && data[element].value.key) {
                        result[data[element].id] = data[element].value.key;
                    } else {
                        result[data[element].id] = data[element].value;
                    }
                }
            }
            resultat.saisies[i].caracteristiques = result;
        }
        return resultat;
    }

    async getCampagneValeursCaracteristiquesAssociationsById(id) {
        const data = await this.getCampagneValeursCaracteristiquesById(id);
        const result = {};

        for (const element in data) {
            if (data[element] && !Array.isArray(data[element])) {
                if (data[element].value === 'True') {
                    data[element].value = 'true';
                }
                if (data[element].value === 'False') {
                    data[element].value = 'false';
                }

                if (data[element].value && data[element].value.key) {
                    result[data[element].id] = data[element].value.key;
                } else {
                    result[data[element].id] = data[element].value;
                }
            }
        }
        return result;
    }

    async getCampagneValeursCaracteristiquesById(id) {
        const url = `${serviceUrl}/${id}/valeurs-caracteristiques`;
        const res = await this.$http.get(url);
        const result = [];
        res.data.forEach(function (element) {
            const caract = {
                id: element.id,
                code: element.code,
                idNature: element.idNature,
                indice: element.indice,
                label: element.label,
                numericAdditionalInformation: element.numericAdditionalInformation,
                value: element.elementsValues || element.elementValue || element.values || element.value
            };
            result.push(caract);
        });
        return result;
    }

    async createCampagne(campagne) {
        const data = {
            code: campagne.entete.code,
            // libelle: campagne.entete.libelle,
            typeId: campagne.entete.typeId,
            typeSaisiesId: campagne.typeSaisiesId,
            siteAuditeurId: campagne.entete.siteAuditeurId,
            siteProducteurId: campagne.entete.siteProducteurId,
            personneFaitParId: campagne.entete.personneFaitParId,
            dateDebut: campagne.entete.dateDebut,
            dateFin: campagne.entete.dateFin,
            referenceControleId: campagne.referenceControleId,
            trameId: campagne.trameId,
            conformite: campagne.entete.conformite,
            caracteristiques: [],
            saisies: []
        };
        data.caracteristiques = this.formatCaracteristiques(campagne.caracteristiques);
        data.saisies = this.formatSaisies(campagne.listeSaisies);
        const result = await this.$http.post(serviceUrl, data);

        return result.data;
    }

    async updateCampagne(campagne) {
        const data = {
            id: campagne.entete.id,
            code: campagne.entete.code,
            //libelle: campagne.entete.libelle,
            typeId: campagne.entete.typeId,
            typeSaisiesId: campagne.typeSaisiesId,
            siteAuditeurId: campagne.entete.siteAuditeurId,
            siteProducteurId: campagne.entete.siteProducteurId,
            personneFaitParId: campagne.entete.personneFaitParId,
            dateDebut: campagne.entete.dateDebut,
            dateFin: campagne.entete.dateFin,
            referenceControleId: campagne.referenceControleId,
            trameId: campagne.trameId,
            conformite: campagne.entete.conformite,
            caracteristiques: [],
            saisies: []
        };
        data.caracteristiques = this.formatCaracteristiques(campagne.caracteristiques);
        data.saisies = this.formatSaisies(campagne.listeSaisies);
        const url = `${serviceUrl}/${campagne.id}`;
        return await this.$http.put(url, data);
    }

    formatSaisies(listeSaisies) {
        const saisies = [];
        for (let s = 0; s < listeSaisies.saisies.length; s++) {
            const data = [];
            const saisieTemp = listeSaisies.saisies[s];
            const saisie = angular.copy(saisieTemp);
            saisie.caracteristiques = this.formatCaracteristiques(saisieTemp.caracteristiques);
            saisie.mesures = this.PrelevementsService.formatMesures(saisieTemp.mesures.valeursMesures.valeursParMesure);

            /*let mesures = saisieTemp.mesures.valeursMesures;
            for (var i=0; i<mesures.length; i++) {
                let mesure = mesures[i];
                for (var property in mesure.valeur.data) {
                    let keyValueObject;
                    //On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variables interne Js.
                    if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
                        let propertyVal = mesure.valeur[property];
                        if (Array.isArray(propertyVal)) {
                            if (_.some(propertyVal, 'key')) {
                                keyValueObject = {
                                    IdSousMesure: parseInt(property),
                                    valeurs: _.map(propertyVal, 'key')
                                };
                            } else {
                                keyValueObject = {
                                    IdSousMesure: parseInt(property),
                                    valeurs: propertyVal
                                };
                            }
                        } else {
                            keyValueObject = {
                                IdSousMesure: parseInt(property),
                                valeur: propertyVal
                            };
                        }
                        data.push(keyValueObject);
                    }
                }
            }
            saisie.mesures = data;*/
            saisies.push(saisie);
        }
        return saisies;
    }

    formatCaracteristiques(caracteristiques) {
        const data = [];
        for (const property in caracteristiques) {
            let keyValueObject;
            //On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variables interne Js.
            if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
                const propertyVal = caracteristiques[property];
                if (Array.isArray(propertyVal)) {
                    if (_.some(propertyVal, 'key')) {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: _.map(propertyVal, 'key')
                        };
                    } else {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: propertyVal
                        };
                    }
                } else {
                    keyValueObject = {
                        IdCaracteristique: parseInt(property),
                        valeur: propertyVal
                    };
                }
                data.push(keyValueObject);
            }
        }
        return data;
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getPointsMesureGeoJSonByCampagne(listeSaisies, idSite) {
        const idsPtsMesures = [];
        for (let i = 0; i < listeSaisies.saisies.length; i++) {
            idsPtsMesures.push(listeSaisies.saisies[i].entete.pointMesureId);
        }
        const url = `${__configuration.apiUrl}/massia/qse/points-mesure/geojson-campagne`;
        const res = await this.$http.get(url, {
            params: {
                idsPtsMesure: JSON.stringify(idsPtsMesures || []),
                idSite: JSON.stringify(idSite || 0)
            }
        });
        return res.data;
    }

    async estCodeAuto(domaine) {
        const url = `${serviceUrl}/est-code-auto/${domaine}`;
        try {
            const res = await this.$http.get(url);
            return res.data;
        } catch (err) {
            if (err.status === 404) {
                return false;
            }
            throw err;
        }
    }

    async afficheCode(domaine) {
        const url = `${serviceUrl}/affiche-code/${domaine}`;
        try {
            const res = await this.$http.get(url);
            return res.data;
        } catch (err) {
            if (err.status === 404) {
                return false;
            }
            throw err;
        }
    }
}
