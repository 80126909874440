import SyntheseEntity from './syntheseentities.model';
import SyntheseEntityCritere from './syntheseentitiescritere.model';

export default class SyntheseEntityFormController {
	static $inject = [
		'$scope',
		'$state',
		'$stateParams',
		'$timeout',
		'$uibModal',
		'SyntheseEntitiesCommunicationService',
		'SyntheseEntitiesService',
		'notification',
		'MassiaApplicationService',
		'$translate',
		'FormuleCalculsService'
	];

	constructor(
		$scope,
		$state,
		$stateParams,
		$timeout,
		$uibModal,
		SyntheseEntitiesCommunicationService,
		SyntheseEntitiesService,
		notification,
		MassiaApplicationService,
		$translate,
		FormuleCalculsService
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$timeout = $timeout;
		this.$uibModal = $uibModal;
		this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
		this.SyntheseEntitiesService = SyntheseEntitiesService;
		this.notification = notification;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$translate = $translate;
		this.FormuleCalculsService = FormuleCalculsService;
	}

	async $onInit() {
		this.unregisterReinit = this.SyntheseEntitiesCommunicationService.registerReinit(() => this.initForm());

		this.isEditMode = false;
		this.forGeneration = false;
		this.inGeneration = false;
		this.syntheseEntity = null;
		this.loading = false;

		this.ongletOpen = {
			isEnteteOpen: true,
			isListeSelectionOpen: true,
			isExclusionsOpen: !this.inGeneration,
			isFTPOpen: !this.inGeneration,
			isControlesOpen: !this.inGeneration,
			isEssaisOpen: !this.inGeneration,
			isListeAffichagesOpen: !this.inGeneration,
			isListeTraitementsOpen: !this.inGeneration,
			isCarteDeControlesOpen: true,
			isFormOpen: true
		};

		this.edit = { bChoisi: false, type: '', indexTab: 0 };
		this.getSyntheseChild = this.getSyntheseEntity;

		this.critereObligatoireResultType = {
			CRIT_OK: 1,
			CRIT_NOK_AFFICHAGE: 2,
			CRIT_NOK: 3
		};

		this.startLoading();
		try {
			// renvoie "laboratoire" ou "qse"
			this.activite = this.MassiaApplicationService.getApplication();

			// si affiche l'HTML du formulaire dans la popUp via la grille des synth�ses
			if (this.synthForGene) {
				this.$stateParams.id = this.synthForGene;
				this.forGeneration = true;
			}

			if (this.paramsSynthese || (this.$stateParams.obj && this.$stateParams.obj.synthese)) {
				this.inGeneration = true;

				if (!this.forGeneration) {
					this.ongletOpen.isFormOpen = false;

					this.ongletOpen.isEnteteOpen = false;
					this.ongletOpen.isListeSelectionOpen = false;
					this.ongletOpen.isExclusionsOpen = false;
					this.ongletOpen.isFTPOpen = false;
					this.ongletOpen.isControlesOpen = false;
					this.ongletOpen.isEssaisOpen = false;
					this.ongletOpen.isListeAffichagesOpen = false;
					this.ongletOpen.isListeTraitementsOpen = false;
				}

				if (this.$stateParams.obj && this.$stateParams.obj.synthese) {
					await this.setSyntheseEntity(this.$stateParams.obj.synthese.id, this.$stateParams.obj.synthese);
				} else {
					await this.setSyntheseEntity(this.paramsSynthese.id, this.paramsSynthese);
				}
			} else {
				await this.setSyntheseEntity(this.$stateParams.id, this.syntheseEntity);
				if (this.syntheseEntity.typeDeSynthese === 13) {
					await this.setCarteControle();
				}
			}

			this.initForm();

			// pour la duplication
			if (this.$stateParams.duplicate) {
				this.duplicateCurrent();
			}
			if (this.syntheseEntity.typeDeSynthese) {
				if (!this.syntheseEntity.listeSyntheseExclusion || this.syntheseEntity.listeSyntheseExclusion.length <= 0) {
					this.syntheseEntity.listeSyntheseExclusion = [{ isEdit: true }];
				}
			}

			await this.getTypeSyntheses();
			await this.validTypeSyntheses();
		} finally {
			this.stopLoading();
		}
	}

	$onDestroy() {
		if (this.unregisterReinit) {
			this.unregisterReinit();
		}
	}

	initForm() {
		if (this.$stateParams && this.$stateParams.id) {
			this.isEditMode = true;
		} else {
			this.isEditMode = false;
		}
	}

	async setCarteControle(that = null) {
		let _this = this;
		if (that) {
			_this = that;
		}
		const copy = angular.copy(_this.syntheseEntity);
		for (let i = 0; i < copy.carteDeControle.syntheseLiee.length; i++) {
			const element = copy.carteDeControle.syntheseLiee[i].synthese;
			_this.syntheseEntity = element;
			await _this.setSyntheseEntity(element.id, element, that);
			copy.carteDeControle.syntheseLiee[i].synthese = element;
		}
		_this.syntheseEntity = copy;
	}

	duplicateCurrent() {
		this.syntheseEntity.id = undefined;
		this.syntheseEntity.libelle += '_copie';
		if (this.syntheseEntity.listeSyntheseCritereAffichage && this.syntheseEntity.listeSyntheseCritereAffichage.length > 0) {
			for (let index = 0; index < this.syntheseEntity.listeSyntheseCritereAffichage.length; index++) {
				const element = this.syntheseEntity.listeSyntheseCritereAffichage[index];
				element.idSynthCritAffichage = undefined;
			}
		}
		if (this.syntheseEntity.listeSyntheseCritereTraitement && this.syntheseEntity.listeSyntheseCritereTraitement.length > 0) {
			for (let index = 0; index < this.syntheseEntity.listeSyntheseCritereTraitement.length; index++) {
				const element = this.syntheseEntity.listeSyntheseCritereTraitement[index];
				element.idSynthCritTraitement = undefined;
			}
		}
		if (this.syntheseEntity.listeSyntheseCritereValeurBlocks && this.syntheseEntity.listeSyntheseCritereValeurBlocks.length > 0) {
			for (let index = 0; index < this.syntheseEntity.listeSyntheseCritereValeurBlocks.length; index++) {
				const element = this.syntheseEntity.listeSyntheseCritereValeurBlocks[index];
				element.id = undefined;
			}
		}
		if (this.syntheseEntity.listeDefaultSyntheseCriteres && this.syntheseEntity.listeDefaultSyntheseCriteres.length > 0) {
			for (let index = 0; index < this.syntheseEntity.listeDefaultSyntheseCriteres.length; index++) {
				const element = this.syntheseEntity.listeDefaultSyntheseCriteres[index];
				element.idSynthCrit = undefined;
			}
		}
	}

	async setSyntheseEntity(syntheseEntityId, syntheseEntity, that = null) {
		let _this = this;
		if (that) {
			_this = that;
		}
		if (syntheseEntityId) {
			//this.startLoading();
			try {
				_this.isEditMode = true;
				if (!syntheseEntity) {
					_this.syntheseEntity = await this.getSyntheseEntity(syntheseEntityId, _this);
				} else {
					_this.syntheseEntity = syntheseEntity;
				}

				if (_this.syntheseEntities) {
					let i = -1;
					for (let ind = 0; ind < _this.syntheseEntities.length; ind++) {
						if (_this.syntheseEntities[ind].id == syntheseEntityId) {
							i = ind;
						}
					}

					if (i > -1) {
						_this.syntheseEntities.splice(i, 1);
					}
				}

				// on met la liste de lien Tamis dans le bon format, ie tamis/SerieTamis (iSteven)
				if (!this.inGeneration) {
					if (_this.syntheseEntity.listeLiensSETS && _this.syntheseEntity.listeLiensSETS.length > 0) {
						for (let lienIndex = 0; lienIndex < _this.syntheseEntity.listeLiensSETS.length; lienIndex++) {
							const lien = _this.syntheseEntity.listeLiensSETS[lienIndex];
							if (lien.resultFromGetTamisBySousMesuresMethode && lien.resultFromGetTamisBySousMesuresMethode.length > 0) {
								let tempListeTamisHeaders = [];
								tempListeTamisHeaders = await _this.formatListeTamisHeaders(lien.resultFromGetTamisBySousMesuresMethode);
								lien.listeTamisHeaders = tempListeTamisHeaders;
							}
							//dimensions remarquables
							if (lien.resultFromGetDimRqBySousMesuresMethode && lien.resultFromGetDimRqBySousMesuresMethode.length > 0) {
								let tempListeDimRqHeaders = [];
								tempListeDimRqHeaders = await _this.formatListeDimRqHeaders(lien.resultFromGetDimRqBySousMesuresMethode);
								lien.listeDimRqHeaders = tempListeDimRqHeaders;
							}
						}
					}
				}

				let reloadSelection = null;
				if (_this.syntheseEntity.listeSyntheseCritereValeurBlocks && _this.syntheseEntity.listeSyntheseCritereValeurBlocks.length > 0) {
					reloadSelection = _this.syntheseEntity.listeSyntheseCritereValeurBlocks.filter(function (e) {
						return e.listeSyntheseCritereValeurLignes && e.listeSyntheseCritereValeurLignes.length > 0;
					});
				}
				if (!reloadSelection || reloadSelection <= 0) {
					await _this.getCriteresFromModele();
				}
			} catch (ex) {
				_this.stopLoading();
				if (ex.status === 404) {
					_this.$state.go('syntheseentities.list', {}, { reload: true });
				}
				console.error('erreur syntheseentities.form.controller setSyntheseEntity');
				_this.notification.error(ex.data);
			}
		} else {
			_this.isEditMode = false;
			_this.syntheseEntity = new SyntheseEntity();
		}
	}

	async getSyntheseEntity(syntheseEntityId, that = null) {
		let _this = this;
		if (that) {
			_this = that;
		}
		let stillThatSyntheseEntity;
		if (_this.forGeneration || _this.inGeneration) {
			stillThatSyntheseEntity = await _this.SyntheseEntitiesService.getSyntheseEntityByIdForUser(syntheseEntityId);
		} else {
			stillThatSyntheseEntity = await _this.SyntheseEntitiesService.getSyntheseEntityById(syntheseEntityId);
		}
		//Si recompo, mettre 2 selections de composants
		if (stillThatSyntheseEntity.typeDeSynthese == 2 && stillThatSyntheseEntity.listeSyntheseCritereValeurBlocks.length == 1) {
			_this.addBlock();
		}
		return new SyntheseEntity(stillThatSyntheseEntity);
	}

	addBlock() {
		const nbrBlock = this.syntheseEntity.listeSyntheseCritereValeurBlocks.length;
		const critBlock = new SyntheseEntityCritere(nbrBlock, this.syntheseEntity.listeDefaultSyntheseCriteres);
		if (this.syntheseEntity.typeDeSynthese == 2) {
			critBlock.nomComposant = this.$translate.instant('SYNTHENTS.COMPOSANT_NOM', { num: nbrBlock + 1 });
		} else {
			critBlock.nomComposant = this.$translate.instant('SYNTHENTS.SELECTION_NOM', { num: nbrBlock + 1 });
		}
		this.syntheseEntity.listeSyntheseCritereValeurBlocks.push(critBlock);
	}

	extractIdsSousEssaiTamis(listEssaiGrps) {
		for (let index = 0; index < listEssaiGrps.length; index++) {
			const essai = listEssaiGrps[index];

			const tempElt = {};
			tempElt.isComplementaire = essai.isComplementaire;
			tempElt.indiceSelection = essai.indiceSelection;
			tempElt.listIdsSousEssaisSelected = [];
			tempElt.listIdsSeriesDimSelected = [];
			tempElt.listIdsDimRqSelected = [];
			tempElt.provenancesEssai = null; // essai.provenancesEssai;

			//sous-essais
			let selectedSousEssais = null;
			if (essai.listeSousEssaisHeaders) {
				selectedSousEssais = essai.listeSousEssaisHeaders.filter(function (e) {
					return e.selected;
				});
			}
			if (selectedSousEssais) {
				for (let subIndex = 0; subIndex < selectedSousEssais.length; subIndex++) {
					tempElt.listIdsSousEssaisSelected.push(selectedSousEssais[subIndex].idSousEssai);
				}
			}

			//tamis
			let selectedTamis = null;
			if (essai.listeTamisHeaders) {
				selectedTamis = essai.listeTamisHeaders.filter(function (e) {
					return e.selected;
				});
			}
			if (selectedTamis) {
				for (let subIndex = 0; subIndex < selectedTamis.length; subIndex++) {
					tempElt.listIdsSeriesDimSelected.push(selectedTamis[subIndex].idTamis);
				}
			}

			//dim Rq
			let selectedDimRq = null;
			if (essai.listeDimRqHeaders) {
				selectedDimRq = essai.listeDimRqHeaders.filter(function (e) {
					return e.selected;
				});
			}
			if (selectedDimRq) {
				for (let subIndex = 0; subIndex < selectedDimRq.length; subIndex++) {
					tempElt.listIdsDimRqSelected.push(selectedDimRq[subIndex].idDimRq);
				}
			}

			this.syntheseEntity.listSousEssaisSeriesDimSelected.push(tempElt);
		}
	}

	async formatListeTamisHeaders(temp) {
		const tempListeTamisHeaders = [];
		for (let i = 0; i < temp.length; i++) {
			const blocSousEssai = temp[i];
			const grpElt = {
				idTamis: -1,
				idSerieTamis: blocSousEssai.idSerieTamis,
				label: blocSousEssai.codeLibelleSerieTamis,

				msGroup: true,
				selected: false
			};
			tempListeTamisHeaders.push(grpElt);

			for (let index = 0; index < blocSousEssai.listeTamisHeaders.length; index++) {
				const tamis = blocSousEssai.listeTamisHeaders[index];
				const tamisElt = {
					idTamis: tamis.idTamis,
					idSerieTamis: tamis.idSerieTamis,
					valeurTamis: tamis.valeurTamis,

					// SL on convertit la valeur int en string car une fois dans le iSteven,
					// 0 n'est pas consid�r� comme une valeur donc affiche "label"
					label: tamis.valeurTamis.toString(),
					selected: tamis.selected || false,
					arrondi: tamis.arrondi,
					unite: blocSousEssai.unite,
					ordre: tamis.ordre,
					idSousEssaiTamis: tamis.idSousEssaiTamis
				};
				tempListeTamisHeaders.push(tamisElt);
			}
		}

		return tempListeTamisHeaders;
	}

	async formatListeDimRqHeaders(temp) {
		const tempListeDimRqHeaders = [];
		for (let i = 0; i < temp.length; i++) {
			const blocSousEssai = temp[i];
			/*const grpElt = {
                idTamis: -1,
                idSerieTamis: blocSousEssai.idSerieTamis,
                label: blocSousEssai.codeLibelleSerieTamis,

                msGroup: true,
                selected: false
            };
            tempListeDimRqHeaders.push(grpElt);*/

			for (let index = 0; index < blocSousEssai.listeDimRqHeaders.length; index++) {
				const dimRq = blocSousEssai.listeDimRqHeaders[index];
				const dimRqElt = {
					idDimRq: dimRq.idDimRq,

					// SL on convertit la valeur int en string car une fois dans le iSteven,
					// 0 n'est pas considéré comme une valeur donc affiche "label"
					label: dimRq.label,
					selected: dimRq.selected || false,
					codeDimRq: dimRq.codeDimRq
				};
				tempListeDimRqHeaders.push(dimRqElt);
			}
		}

		return tempListeDimRqHeaders;
	}

	async getTypeSyntheses() {
		try {
			const tempList = await this.SyntheseEntitiesService.getTypeSyntheses();
			this.listeTypesSynthese = _.filter(tempList, { activite: this.activite });
		} catch (err) {
			console.error('erreur syntheseentities.form.controller getTypeSynthese');
			if (err.data) {
				this.notification.error(err.data);
			}
		}
	}

	async validTypeSyntheses() {
		if (this.syntheseEntity.typeDeSynthese) {
			await this.getModelesSynthese();

			if (!this.syntheseEntity.listeSyntheseExclusion || this.syntheseEntity.listeSyntheseExclusion.length <= 0) {
				this.syntheseEntity.listeSyntheseExclusion = [{ isEdit: true }];
			}
		}
	}

	async getModelesSynthese() {
		try {
			this.listeModelesSynthese = await this.SyntheseEntitiesService.getModelesSynthese(this.syntheseEntity.typeDeSynthese);
		} catch (err) {
			console.error('erreur syntheseentities.form.controller getModelesSynthese');
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
		}
	}

	/* #region ----------------------------------------------------- SAVE ------------------------------------------------------------ */
	// boutons de validation
	async sauvegarder() {
		if (this.checkValidity()) {
			// pour g�rer s'il manque des valeurs dans les diff�rents champs
			const mef = await this.manageValuesError();

			if (mef) {
				this.notification.warning(mef);
				return false;
			}
			this.startLoading();

			//if (this.syntheseEntity.id && this.syntheseEntity.id > 0) {
			this.syntheseEntity.listSousEssaisSeriesDimSelected = [];

			// récupérer les ids des sous essais de la partie en bdd
			if (this.syntheseEntity.typeDeSynthese !== 10 && this.syntheseEntity.listeLiensSETS && this.syntheseEntity.listeLiensSETS.length > 0) {
				await this.extractIdsSousEssaiTamis(this.syntheseEntity.listeLiensSETS);
			} else {
				this.syntheseEntity.listeLiensSETS = [];
			}

			//Dans le cas d'une carte contr�le r�sistance, on profite de la propri�t� de groupement des traitements
			//Il peut y avoir plusieurs blocs de traitements possibles (1 pour tous b�ton, 1 pour les familles)
			//On r�cup�re donc les groupes de traitement pour les fusionner en 1 seul
			if (this.syntheseEntity.typeDeSynthese === 8 && this.syntheseEntity.grpSyntheseCritereTraitement) {
				this.syntheseEntity.listeSyntheseCritereTraitement = [];
				for (let iGrp = 0; iGrp < this.syntheseEntity.grpSyntheseCritereTraitement.length; iGrp++) {
					const grpTraitements = this.syntheseEntity.grpSyntheseCritereTraitement[iGrp];

					for (let i = 0; i < grpTraitements.listeSyntheseCritereTraitement.length; i++) {
						grpTraitements.listeSyntheseCritereTraitement[i].groupementIndex = iGrp;
						this.syntheseEntity.listeSyntheseCritereTraitement.push(grpTraitements.listeSyntheseCritereTraitement[i]);
					}
				}
			}

			//re-ordonnement traitements
			if (this.syntheseEntity.listeSyntheseCritereTraitement) {
				for (let i = 0; i < this.syntheseEntity.listeSyntheseCritereTraitement.length; i++) {
					this.syntheseEntity.listeSyntheseCritereTraitement[i].position = i + 1;
				}
			}

			//re-ordonnement affichages
			if (this.syntheseEntity.listeSyntheseCritereAffichage) {
				for (let i = 0; i < this.syntheseEntity.listeSyntheseCritereAffichage.length; i++) {
					this.syntheseEntity.listeSyntheseCritereAffichage[i].position = i + 1;
				}
			}

			try {
				if (this.syntheseEntity.norme && this.syntheseEntity.norme.length === 1) {
					this.syntheseEntity.idNorme = this.syntheseEntity.norme[0].id;
					this.syntheseEntity.normeLibelle = this.syntheseEntity.norme[0].libelle;
				}
				if (this.syntheseEntity.NQA && this.syntheseEntity.NQA.length === 1) {
					this.syntheseEntity.idNQA = this.syntheseEntity.NQA[0].id;
					this.syntheseEntity.NQALibelle = this.syntheseEntity.NQA[0].libelle;
				}

				this.syntheseEntity.typeDeConformite = [];
				this.syntheseEntity.typeDeConformite.push(this.syntheseEntity.isProdBeton ? 1 : 0);
				this.syntheseEntity.typeDeConformite.push(this.syntheseEntity.isProdFamilleBeton ? 1 : 0);
				this.syntheseEntity.typeDeConformite.push(this.syntheseEntity.isProdContinue ? 1 : 0);
				this.syntheseEntity.typeDeConformite.push(this.syntheseEntity.isProdInitiale ? 1 : 0);

				if (this.syntheseEntity.id && this.syntheseEntity.id > 0) {
					await this.updateSyntheseEntity();
					return this.syntheseEntity.id;
				}
				const tmp = await this.createSyntheseEntity();
				return tmp;
			} catch (ex) {
				this.notification.error(ex.data);
				return false;
			} finally {
				this.stopLoading();
			}
		} else {
			return false;
		}
	}

	manageValuesError() {
		// pour chaque block
		if (this.syntheseEntity && this.syntheseEntity.listeSyntheseCritereValeurBlocks) {
			for (let index1 = 0; index1 < this.syntheseEntity.listeSyntheseCritereValeurBlocks.length; index1++) {
				const block = this.syntheseEntity.listeSyntheseCritereValeurBlocks[index1];

				// pour chaque lignes d'un block
				for (let index2 = 0; index2 < block.listeSyntheseCritereValeurLignes.length; index2++) {
					const ligne = block.listeSyntheseCritereValeurLignes[index2];

					switch (ligne.typeDeChamp) {
						case 3: // nombre d�cimal
							{
								if (ligne.operateur > 0) {
									switch (ligne.operateur) {
										case 1: // inferieur a
											if (ligne.valeurMaximale == null) {
												return this.$translate.instant('SYNTHENTS.ERREUR.MANQUE_VALEUR_MAX');
											}
											break;
										case 2: // inferieur ou egal a
											if (ligne.valeurMaximale == null) {
												return this.$translate.instant('SYNTHENTS.ERREUR.MANQUE_VALEUR_MAX');
											}
											break;
										case 3: // superieur a
											if (ligne.valeurMinimale == null) {
												return this.$translate.instant('SYNTHENTS.ERREUR.MANQUE_VALEUR_MIN');
											}
											break;
										case 4: // superieur ou egal a
											if (ligne.valeurMinimale == null) {
												return this.$translate.instant('SYNTHENTS.ERREUR.MANQUE_VALEUR_MIN');
											}
											break;
										case 5: // compris entre
											if (ligne.valeurMinimale == null || ligne.valeurMaximale == null) {
												return this.$translate.instant('SYNTHENTS.ERREUR.MANQUE_UNE_DEUX_VALEURS');
											}
											break;
										case 11: // egal a
											if (ligne.valeurMinimale == null) {
												return this.$translate.instant('SYNTHENTS.ERREUR.MANQUE_VALEUR');
											}
											break;
										default:
											break;
									}
								}
							}
							break;
						case 5: // date
							if (ligne.isGammeDate == true) {
								/*if (ligne.dateDebut == null || ligne.dateFin == null) {
                                    return this.$translate.instant('SYNTHENTS.ERREUR.MANQUE_UNE_DEUX_VALEURS');
                                }*/
								//SP 05/07/19 : non, sauf siobligatoire, mais pas g�r� ici
							} else {
								if (ligne.periodeGlissante != 0) {
									if (ligne.periodeGlissante != 31 && ligne.valeurTemporelle == null) {
										return this.$translate.instant('SYNTHENTS.ERREUR.MANQUE_VALEUR_TEMPORELLE');
									}

									if (ligne.periodeGlissante == 31 && ligne.valeurTemporelle == null && ligne.valeurTemporelle2 == null) {
										return this.$translate.instant('SYNTHENTS.ERREUR.MANQUE_VALEUR_TEMPORELLE');
									}

									/*if ((ligne.dateDebut == null) && (ligne.periodeGlissante > 0 && ligne.periodeGlissante < 10)) {
                                        return this.$translate.instant('SYNTHENTS.ERREUR.MANQUE_DATE');
                                    }

                                    if (ligne.dateFin == null && (ligne.periodeGlissante > 10 && ligne.periodeGlissante < 20)) {
                                        return this.$translate.instant('SYNTHENTS.ERREUR.MANQUE_DATE');
                                    }*/
								}
							}
							break;

						default:
							break;
					}
				}
			}
		}

		return null;
	}

	async createSyntheseEntity() {
		try {
			const returnedId = await this.SyntheseEntitiesService.createSyntheseEntity(this.syntheseEntity);
			this.notification.success('SYNTHENTS.CREATED');
			return returnedId;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async updateSyntheseEntity() {
		try {
			let returnedId;
			if (this.forGeneration || this.inGeneration) {
				returnedId = await this.SyntheseEntitiesService.updateSyntheseEntityForUser(this.syntheseEntity);
			} else {
				returnedId = await this.SyntheseEntitiesService.updateSyntheseEntity(this.syntheseEntity);
			}
			this.notification.success('SYNTHENTS.UPDATED');
			return returnedId;
		} catch (ex) {
			this.notification.error(ex.data);
			await this.reinit();
		}
	}

	checkValidity() {
		this.$scope.$broadcast('syntheseEntityValidations');
		return this.syntheseEntity.isValid();
	}

	async getCriteresFromModele() {
		try {
			this.syntheseEntity.listeDefaultSyntheseCriteres = null;
			this.syntheseEntity.listeSyntheseCritereValeurBlocks = null;

			if (this.syntheseEntity.idModeleSynthese) {
				const resultDto = await this.SyntheseEntitiesService.getSelectionFromModele(this.syntheseEntity.idModeleSynthese);
				this.syntheseEntity.changeModele = true;
				this.syntheseEntity.idFamille = resultDto.idFamille;
				this.syntheseEntity.listeDefaultSyntheseCriteres = resultDto.listeDefaultSyntheseCriteres;
				this.syntheseEntity.listeSyntheseCritereValeurBlocks = resultDto.listeSyntheseCritereValeurBlocks;
				//Si recompo, mettre 2 sélections de composants
				if (this.syntheseEntity.typeDeSynthese == 2 && this.syntheseEntity.listeSyntheseCritereValeurBlocks.length == 1) {
					this.addBlock();
				}
			}
		} catch (err) {
			console.error('erreur syntheseentities.form.controller getCriteresFromModele');
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}

	areCriteresObligatoiresRemplis() {
		if (this.syntheseEntity.typeDeSynthese === 13) {
			return this.critereObligatoireResultType.CRIT_OK;
		}
		for (let i = 0; i < this.syntheseEntity.listeSyntheseCritereValeurBlocks.length; i++) {
			const block = this.syntheseEntity.listeSyntheseCritereValeurBlocks[i];
			for (let j = 0; j < block.listeSyntheseCritereValeurLignes.length; j++) {
				const crit = block.listeSyntheseCritereValeurLignes[j];
				if (crit.isObligatoire) {
					switch (crit.typeDeChamp) {
						case 1:
							if (!crit.valeurTextuelle) {
								return this.critereObligatoireResultType.CRIT_NOK;
							}
							break;
						case 2:
						case 4:
							if (!crit.valeur) {
								return this.critereObligatoireResultType.CRIT_NOK;
							}
							break;
						case 3:
							if (crit.operateur) {
								switch (crit.operateur) {
									case 1:
									case 2:
										if (!crit.valeurMaximale) {
											return this.critereObligatoireResultType.CRIT_NOK;
										}
									case 3:
									case 4:
									case 11:
										if (!crit.valeurMinimale) {
											return this.critereObligatoireResultType.CRIT_NOK;
										}
									case 5:
										if (!crit.valeurMaximale || !crit.valeurMinimale) {
											return this.critereObligatoireResultType.CRIT_NOK;
										}
								}
							} else {
								return 0;
							}
							break;
						case 5:
							if (crit.isGammeDate == true) {
								if (!crit.dateDebut || !crit.dateFin) {
									return this.critereObligatoireResultType.CRIT_NOK;
								}
							} else if (crit.isGammeDate == 0) {
								if (!crit.periodeGlissante) {
									return this.critereObligatoireResultType.CRIT_NOK;
								}
								/*else {
                                    if (crit.periodeGlissante > 0 && crit.periodeGlissante < 10) {
                                        if (!crit.dateDebut)
                                            return false;
                                    } else if (crit.periodeGlissante > 10 && crit.periodeGlissante < 20) {
                                        if (!crit.dateFin)
                                            return false;
                                    }
                                }*/
							} else {
								return this.critereObligatoireResultType.CRIT_NOK;
							}
							break;
						case 6:
							//TODO: heure
							break;
						case 7:
							if (!crit.selectedEltsTable || crit.selectedEltsTable.length == 0) {
								return this.critereObligatoireResultType.CRIT_NOK;
							}
							break;
						case 8:
							//TODO: enum
							break;
					}
				}
			}
		}
		return this.critereObligatoireResultType.CRIT_OK;
	}

	async valider() {
		const returnedId = await this.sauvegarder();
		if (returnedId && !(this.forGeneration || this.inGeneration)) {
			this.$state.go('syntheseentities.edit', { id: returnedId }, { reload: true });
		}
	}

	async validerEtFermer() {
		const success = await this.sauvegarder();
		if (success) {
			this.annuler();
		}
	}

	annuler() {
		if (this.forGeneration || this.inGeneration) {
			// this.modalInstance.close(this.hasBeenModified);
			this.modalInstance.close();
		} else {
			this.$state.go('syntheseentities.list');
		}
	}

	async annulerParamUser() {
		try {
			const idSynthese = this.paramsSynthese && this.paramsSynthese.id ? this.paramsSynthese.id : this.syntheseEntity.id;

			await this.SyntheseEntitiesService.deleteSyntheseEntityUserById(idSynthese);
			//recharger le param�trage global
			this.inGeneration = false;
			await this.setSyntheseEntity(idSynthese, undefined);
			this.inGeneration = true;
			this.ongletOpen.isFormOpen = false;
			this.notification.success('SYNTHENTS.RETOUR_PARAMETRAGE_GLOBAL');
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async genererSynthese() {
		try {
			const critereObligatoireRemplis = this.areCriteresObligatoiresRemplis();
			if (critereObligatoireRemplis === this.critereObligatoireResultType.CRIT_OK) {
				this.notification.info('SYNTHENTS.GEN_IN_PROGRESS', null, {
					timeOut: 0,
					extendedTimeOut: 0
				});
				// On vérifie et passe le paramètre pour le contrôle continue Famille Béton
				if (this.syntheseEntity.typeDeSynthese === 8 && this.syntheseEntity.isProdFamilleBeton && this.syntheseEntity.isProdContinue) {
					this.syntheseEntity.rcViseeCalcFromCurDate = (await this.FormuleCalculsService.getCaractParamProg('BooRCVisDateCou')) === '1';
				}
				const res = await this.SyntheseEntitiesService.genererSynthese(this.syntheseEntity, this.activite);

				const objResult = { resultat: res, synthese: this.syntheseEntity };

				this.notification.clear();
				if (this.modalInstance) {
					this.modalInstance.close(objResult);
				} else {
					return objResult;
				}
			} else {
				this.notification.error('SYNTHENTS.CRITERES_OBLIG_MANQUANTS');
			}
		} catch (ex) {
			this.notification.clear();
			this.notification.error(ex.data);
		}
	}

	async reinit() {
		await this.setSyntheseEntity(this.$stateParams.id, undefined);
		//this.SyntheseEntitiesCommunicationService.raiseReinit();
		this.initForm();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
