import TicketComposantController from './ticket.composant.controller';

export default {
    bindings: {
        id: '=',
        domaine: '=',
        isOpen: '=',
        onUpdate: '&'
    },
    template: require('./ticket.composant.html'),
    controller: TicketComposantController
};
