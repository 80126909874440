(function (angular, undefined) {
	angular.module('blocks.smart.table').directive('stFilter', [
		'$timeout',
		function ($timeout) {
			return {
				require: '^stTable',
				restrict: 'A',
				transclude: true,
				templateUrl: 'blocks/smart-table/filter.html',
				scope: {
					predicate: '=stFilter',
					predicateName: '@stFilter'
				},
				link: function (scope, element, attr, ctrl) {
					let timer;
					const unregisterDestroyEvent = scope.$on('$destroy', dispose);
					const unregisterFilterSettedEvent = scope.$on('event:stPredicateObjectSetted', predicateObjectUpdated);
					let slideboxEl;
					let pinnedTableEl;
					let first = true;
					scope.filtering = false;
					scope.currentFilter = '';
					element.addClass('st-filter');

					// pour récupérer l'info quand on modifie le tableState
					scope.$watch(
						function () {
							return ctrl.tableState().search;
						},
						function (newValue, oldValue) {
							if (newValue.predicateObject && newValue.predicateObject[scope.predicateName]) {
								scope.predicate = { txt: newValue.predicateObject[scope.predicateName] };
								if (scope.predicate && scope.predicate.txt && first) {
									first = false;
									scope.setFilterInfos();
								}
							}
						}
					);

					// On utilise le filtre custom
					ctrl.setFilterFunction('acTableFilter');

					scope.eraseFilter = function () {
						// si une valeur est entrée
						if (scope.predicate) {
							// on l'efface
							scope.predicate.txt = undefined;
						}
						scope.currentFilter = '';
						// on lance ensuite la recherche à vide (pour rappatrier tout)
						ctrl.search(undefined, scope.predicateName);
						scope.filtering = false;
						scope.filter.isOpen = false;
					};

					scope.filter = function () {
						if (scope.predicate && scope.predicate.txt) {
							scope.setFilterInfos();
							scope.applyFilter();
							scope.filter.isOpen = false;
						}
					};

					scope.setFilterInfos = function () {
						if (scope.predicate && scope.predicate.txt) {
							scope.currentFilter = scope.predicate.txt;
							scope.filtering = true;
						} else {
							scope.currentFilter = '';
							scope.filtering = false;
						}
					};

					scope.applyFilter = function () {
						if (scope.predicate && scope.predicate.txt) {
							ctrl.search(scope.predicate.txt, scope.predicateName);
						}
					};

					// Dans le cas d'une table pinned, on ferme le dropdown au scroll
					const slidebox = globalHelpers.findAncestorByClassName(element[0], 'slidebox');
					const pinnedTable = globalHelpers.findAncestorByClassName(element[0], 'st-table-pinned');

					if (slidebox) {
						slideboxEl = angular.element(slidebox);
						slideboxEl.bind('scroll', onScroll);
					}

					if (pinnedTable) {
						pinnedTableEl = angular.element(pinnedTable);
						pinnedTableEl.bind('scroll', onScroll);
					}

					function onScroll(e) {
						timer = $timeout(function () {
							scope.filter.isOpen = false;
						});
					}

					function predicateObjectUpdated(evt, predicateObject) {
						if (angular.isObject(predicateObject) && predicateObject[scope.predicateName] !== undefined) {
							scope.predicate = { txt: predicateObject[scope.predicateName] };
						} else {
							scope.predicate = undefined;
						}

						scope.setFilterInfos();
					}

					function dispose() {
						unregisterFilterSettedEvent();
						unregisterDestroyEvent();
						element.removeClass('st-filter');

						if (timer) {
							$timeout.cancel(timer);
						}
						if (slideboxEl !== undefined) {
							slideboxEl.unbind('scroll', onScroll);
						}
						if (pinnedTableEl !== undefined) {
							pinnedTableEl.unbind('scroll', onScroll);
						}
					}
				}
			};
		}
	]);
})(angular);
