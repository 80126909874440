export default function TemplateImpressionController(
    $state,
    $stateParams,
    $scope,
    TemplateImpressionService,
    PaginationService,
    notification,
    $uibModal,
    ModalService,
    $translate,
    MOPService
) {
    const $this = this;

    $this.state = $state;
    $this.params = $stateParams;
    $this.savedPagination = {};
    $this.savedPagination.entity = 'pagination';
    $this.MOPService = MOPService;

    $this.$onInit = () => {
        $this.MOPService.setMop([{ title: 'IMPRESSION.BREADCRUMBS.IMPRESSION_LIST', filename: 'Modelesimpression.pdf', application: 'massia' }]);
    };

    $this.$onDestroy = () => {
        $this.MOPService.resetMop();
    };

    $scope.template = [];
    $scope.itemsByPageOptions = [20, 100, 200];
    $scope.loadTemplate = loadTemplate;
    $scope.onSeeTemplate = onSeeTemplate;
    $scope.onUpdateTemplate = onUpdateTemplate;

    $scope.templateHeaders = [
        {
            title: 'IMPRESSION.CODE',
            class: '',
            options: {
                sort: true,
                filter: true,
                filterType: 1
            },
            property: 'code'
        },
        {
            title: 'IMPRESSION.LIBELLE',
            class: '',
            options: {
                sort: true,
                filter: true,
                filterType: 1
            },
            property: 'libelle'
        },
        {
            title: 'IMPRESSION.FILENAME',
            class: '',
            options: {
                sort: true,
                filter: true,
                filterType: 1
            },
            property: 'filename'
        },
        // {
        //     title: "IMPRESSION.DRIVE",
        //     class : "",
        //     options: {
        //         sort: true,
        //         filter: true,
        //         filterType: 1
        //     },
        //     property: 'drive'
        // },
        // {
        //     title: "IMPRESSION.DIRECTORY",
        //     class: "",
        //     options: {
        //         sort: true,
        //         filter: true,
        //         filterType: 1
        //     },
        //     property: 'directory'
        // },
        {
            title: 'IMPRESSION.EXTENSION',
            class: '',
            options: {
                sort: true,
                filter: true,
                filterType: 1
            },
            property: 'extension'
        },
        {
            title: 'IMPRESSION.DATE_CREATION',
            class: '',
            options: {
                sort: true,
                filter: true,
                filterType: 4,
                default: true
            },
            property: 'dateCreation'
        },
        {
            title: 'IMPRESSION.DOMAINE', // Titre de la colonne
            class: '', // Class utilisateur
            options: {
                sort: true, // Si on veut pouvoir trier la colonne
                filter: true, // Si on veut pouvoir filtrer sur la colonne
                filterType: 1 // Type du filtre utilisé pour cette colonne
            },
            property: 'domaineLibelle' // La propriété affiché dans cette colonne
        },
        {
            title: 'IMPRESSION.SIZE', // Titre de la colonne
            class: '', // Class utilisateur
            options: {
                sort: true, // Si on veut pouvoir trier la colonne
                filter: true, // Si on veut pouvoir filtrer sur la colonne
                filterType: 1 // Type du filtre utilisé pour cette colonne
            },
            property: 'size' // La propriété affiché dans cette colonne
        },
        {
            title: 'IMPRESSION.ACTION',
            class: 'width-10p',
            button: true // Si cette colonne est lié à des actions dans ce cas ne pas mettre d'options
        }
    ];
    $scope.templateButton = [
        {
            tooltip: 'IMPRESSION.SHOW',
            callback: onSeeTemplate,
            icon: 'glyphicon-search',
            authorize: true,
            domain: 'template',
            right: 'read'
        },
        {
            tooltip: 'IMPRESSION.UPDATE',
            callback: onUpdateTemplate,
            icon: 'glyphicon-pencil',
            authorize: true,
            domain: 'template',
            right: 'update'
        },
        {
            tooltip: 'IMPRESSION.DOWNLOAD',
            callback: onDownloadTemplate,
            icon: 'glyphicon-save',
            authorize: true,
            domain: 'template',
            right: 'open'
        },
        {
            tooltip: 'IMPRESSION.DELETE', // Titre du bouton
            callback: onDeleteTemplate, // Fonction de callback
            icon: 'glyphicon-trash', // Icon du bouton
            authorize: true, // Pour pouvoir utilisé les propriété domain & right
            domain: 'template', // Domaine du bouton
            right: 'delete' // Droit pour pouvoir utiliser le bouton (Lié au domaine)
        }
    ];

    async function loadTemplate(tableState) {
        if (tableState) {
            $this.previousTableState = tableState;
        }

        const filters = PaginationService.getFilters($this.previousTableState);
        const sorts = PaginationService.getSorts($this.previousTableState);
        const pagination = PaginationService.getPagination($this.previousTableState);
        try {
            const data = await TemplateImpressionService.getTemplateList(filters, sorts, pagination);
            data.items.map((x) => {
                x.originalSize = angular.copy(x.size);
                x.size = x.size / 1000 + 'ko';
                return x;
            });
            $scope.template = data.items;

            if ($this.previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, $this.previousTableState);
            }
        } catch (err) {
            notification.error(err.data);
        }
    }

    $this.open = (data) => {
        const modalInstance = $uibModal.open({
            animation: true,
            backdrop: 'static',
            templateUrl: 'myModalContent.html',
            size: 'md',
            component: 'templateForm',
            resolve: {
                data: () => {
                    return data ? data : null;
                }
            }
        });

        modalInstance.result.then(
            (res) => {},
            (err) => {
                loadTemplate($this.previousTableState);
            }
        );
    };

    function onSeeTemplate(data) {
        $state.go('impression.list.detail', { id: data.id });
    }

    function onUpdateTemplate(data) {
        $this.open(data);
    }

    async function onDownloadTemplate(data) {
        try {
            const template = `${data.filename}`;
            const res = await TemplateImpressionService.downloadOriginalTemplate(template);
        } catch (err) {
            console.log(err);
            notification.error(err.data);
        }
    }

    function onDeleteTemplate(data) {
        const modalInstance = ModalService.confirm({
            modalTitle: $translate.instant('IMPRESSION.MODAL.TITLE', { code: data.libelle }),
            modalMsg: $translate.instant('IMPRESSION.MODAL.MESSAGE'),
            headerClass: 'modal-danger'
        });

        modalInstance.result.then(async function () {
            try {
                await TemplateImpressionService.deleteTemplateById(data.id);
                notification.success($translate.instant('IMPRESSION.MODAL.SUCCESS'));

                if ($this.params.id) {
                    $this.state.go('impression.list');
                }

                $this.previousTableState = PaginationService.getTableStateAfterDelete(
                    $this.previousTableState,
                    $this.previousTableState.pagination.take
                );

                loadTemplate();
            } catch (ex) {
                notification.error(ex.data);
            }
        });
    }
}

TemplateImpressionController.$inject = [
    '$state',
    '$stateParams',
    '$scope',
    'TemplateImpressionService',
    'PaginationService',
    'notification',
    '$uibModal',
    'ModalService',
    '$translate',
    'MOPService'
];
