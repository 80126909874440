ProfilDetailController.$inject = ['ProfilsService', 'MassiaApplicationService', 'notification', '$state'];

export default function ProfilDetailController(ProfilsService, MassiaApplicationService, notification, $state) {
    /* jshint validthis: true */
    const vm = this;

    // Propriétés
    vm.loading = true;
    vm.profil = null;
    vm.loadDomaines = loadDomaines;

    // Méthodes

    // Evènements
    vm.$onInit = onInit;

    // Méthodes publiques
    function onInit() {
        loadProfil();
    }

    // Méthodes privées
    async function loadProfil() {
        startLoading();

        try {
            vm.profil = await ProfilsService.getProfil($state.params.id, MassiaApplicationService.getApplication());
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function loadDomaines(filters, sorts, pagination) {
        return ProfilsService.getDomaines(vm.profil.id, filters, sorts, pagination, MassiaApplicationService.getApplication());
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }
}
