import TransportEntete from './transport.entete.model';

export default class TransportEnteteController {
    static $inject = ['$stateParams', 'TransportsCommunicationService', 'TransportsService', 'notification'];

    constructor($stateParams, TransportsCommunicationService, TransportsService, notification) {
        this.$stateParams = $stateParams;
        this.TransportsCommunicationService = TransportsCommunicationService;
        this.TransportsService = TransportsService;
        this.notification = notification;
    }

    async $onInit() {
        this.loading = false;
        this.codeLoading = false;
        this.isEditMode = false;
        this.unregisterReinit = this.TransportsCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    initForm() {
        let entete = {};

        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
            entete = this.transport.entete;
        } else {
            this.isEditMode = false;
        }
        this.transport.entete = new TransportEntete(entete);
        this.getUnites();
        this.getGrilles();
    }

    async getUnites() {
        this.startLoading();
        try {
            const res = await this.TransportsService.getUnites();
            this.unites = res.items;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }
    async getGrilles() {
        this.startLoading();
        try {
            const res = await this.TransportsService.getGrillesZonesKm();
            this.grillesZonesKm = res.items;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.transport.entete.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.transport.entete.codeExists = await this.TransportsService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
