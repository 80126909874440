(function (angular, undefined) {
    'use strict';

    angular.module('blocks.treeview')
        .factory('NodeValidator', NodeValidator);
    NodeValidator.$inject = ['validator', 'validationHelper'];

    function NodeValidator(validator, validationHelper) {
        var Validator = validator;
        var itemValidator = new Validator();

        //itemValidator.ruleFor('label').notEmpty().withMessage('VALIDATION_NOTEMPTY');
        //itemValidator.ruleFor('label').length(0, 50).withMessage('TREEVIEW_VALIDATION_TOO_LONG_50');
        //itemValidator.ruleFor('label').matches("^[a-zA-Z0-9_\\-.ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ ']*$").withMessage('TREEVIEW_VALIDATION_SPECIAL_CHARACTERS');

        return itemValidator;
    }
})(angular);
