import FacturesPrintController from './factures.print.controller';

export default {
    bindings: {
        modalInstance: '=',
        source: '='
    },
    template: require('./factures.print.html'),
    controller: FacturesPrintController
};
