(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.modal')
        .controller('ModalController', ModalController);
    ModalController.$inject = ['$scope', '$uibModalInstance', 'modalData', 'modalConfig'];

    /* @ngInject */
    function ModalController($scope, $modalInstance, modalData, modalConfig) {

        var vm = this;
        // propriétés
        vm.config = {};
        vm.data = {};
        vm.callbacks = {};
        vm.ctrl = angular.noop;
        vm.titleIcons = {};

        // méthodes publiques
        vm.close = close;
        vm.cancel = cancel;
        vm.confirm = confirm;

        init();

        //region Constructeur
        function init() {
            var callbacks = {
                close: close,
                cancel: cancel,
                confirm: confirm
            };

            vm.config = modalConfig || {};
            vm.data = modalData;
            vm.callbacks = callbacks;

            vm.titleIcons = vm.config.titleIcons || {
                'glyphicon glyphicon-exclamation-sign': (vm.config.headerClass || '').indexOf('modal-warning') >= 0,
                'glyphicon glyphicon-question-sign': (vm.config.headerClass || '').indexOf('modal-confirm') >= 0,
                'glyphicon glyphicon-ok-sign': (vm.config.headerClass || '').indexOf('modal-success') >= 0,
                'glyphicon glyphicon-remove-sign': (vm.config.headerClass || '').indexOf('modal-danger') >= 0,
                'glyphicon glyphicon-info-sign': (vm.config.headerClass || '').indexOf('modal-info') >= 0,
            };

            vm.ctrl = modalConfig.controller + ' as ' + modalConfig.controllerAs;
            vm.init = "modalCtrl.data, modalCtrl.callbacks, modalCtrl.config";
        }

        //endregion

        //region Méthodes publiques
        function close(data) {
            $modalInstance.close(data);
        }

        function cancel(data) {
            $modalInstance.dismiss(data);
        }

        function confirm(data) {
            $modalInstance.close(data);
        }

        //endregion Méthodes publiques

        //region Méthodes privées
        //endregion Méthodes privées
    }
})(angular);
