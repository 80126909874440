export default function Configure($stateProvider) {
    $stateProvider.state('encours', {
        parent: 'gestion',
        url: '/encours',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
        }
    });

    $stateProvider.state('encours.list', {
        url: '/list',
        template: '<encours></encours>',
        rights: { domain: 'encours', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "ENCOURS.BREADCRUMBS.ENCOURS_LIST" | translate}}'
        },
        navigation: {
            menu: 'facturation',
            translate: 'ENCOURS.BREADCRUMBS.ENCOURS_LIST',
            order: 9
        },
        search: 'ENCOURS.BREADCRUMBS.ENCOURS_LIST'
    });
}

Configure.$inject = ['$stateProvider'];
