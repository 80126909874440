const serviceUrl = `${__configuration.apiUrl}/massia/filtres-utilisateur`;

export default class DonneeFiltreeUtilisateursService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getFiltreSocietes(application) {
        let url = '';
        switch (application) {
            case 'gestion':
                url = `${__configuration.apiUrl}/massia/societes-commerciales/allHeader`;
                break;
            case 'qse':
                url = `${__configuration.apiUrl}/massia/societes-auditrices/allHeader`;
                break;
            case 'laboratoire':
                url = `${__configuration.apiUrl}/massia/societes-laboratoires/allHeader`;
                break;
            case 'performance':
                url = `${__configuration.apiUrl}/massia/societes-productrices/allHeader`;
                break;
        }
        const res = await this.$http.get(url);
        return res.data;
    }

    async getFiltreSites(pListeIdSocietes, application) {
        const url = `${__configuration.apiUrl}/massia/filtres-utilisateur/user-filter/${application}`;
        const res = await this.$http.get(url, {
            params: {
                listeIdSocietes: JSON.stringify(pListeIdSocietes)
            }
        });
        return res.data;
    }

    async getFiltreMetiers(application) {
        const url = `${__configuration.apiUrl}/massia/metiers/allHeaders/${application}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getFiltreThemes(pListeIdMetiers, application) {
        const url = `${__configuration.apiUrl}/massia/themes/allheaders-by-metiers`;
        const res = await this.$http.get(url, {
            params: {
                application: application,
                listeIdMetiers: JSON.stringify(pListeIdMetiers)
            }
        });
        return res.data;
    }

    async getFiltreFamilles(pListeIdMetiers, application) {
        const url = `${__configuration.apiUrl}/massia/familles`;
        const res = await this.$http.get(
            url /*, {
            params: {
                application: application,
                listeIdMetiers: JSON.stringify(pListeIdMetiers)

            }
        }*/
        );
        return res.data;
    }

    async getDonneeFiltreeUtilisateur(id, application) {
        const url = `${serviceUrl}/${id}/${application}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async updateFiltreUtilisateur(data, application) {
        data.application = application;
        return this.$http.put(serviceUrl, data);
    }

    async getFiltreUtilisateurs() {
        const url = `${__configuration.apiUrl}/massia/personnes/utilisateur/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async duplicateFiltreUtilisateur(idUtilisateurs, filtres, application) {
        const data = {
            idUtilisateurs: idUtilisateurs,
            idFiltreSocietes: filtres.idFiltreSocietes,
            idFiltreSites: filtres.idFiltreSites,
            idMetiers: filtres.idMetiers,
            idFamilles: filtres.idFamilles,
            idThemes: filtres.idThemes,
            application: application
        };

        const url = `${serviceUrl}/duplicate`;
        return this.$http.put(url, data);
    }

    async getMobileModele() {
        const url = `${__configuration.apiUrl}/massia/modeles-saisies`;
        const filters = [
            {
                criterion: 'isMobile',
                type: 'Eq',
                value: true
            }
        ];

        const res = await this.$http.get(url, {
            params: {
                filters: JSON.stringify(filters),
                pagination: JSON.stringify({ skip: 0, take: 0 }),
                domaine: JSON.stringify('modelessaisies')
            }
        });
        return res.data.items;
    }
}
