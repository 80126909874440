import { Caracteristique } from '../../../../../../core/models/caracteristique/Caracteristique';
import { ValeurCaracteristique } from '../../../../../../core/models/valeur-caracteristique/ValeurCaracteristique';

class CaractListNumController {
    caracteristique: Caracteristique;
    valeurs: ValeurCaracteristique[];
    model: ValeurCaracteristique[] = [];
    index: number;
    ROUNDED_DEFAULT: number = 0;
    defaultValue: string;
    /* @ngInject */
    constructor() {}
    $onInit(): void {
        const value = this.valeurs.filter((x) => x.idCaracteristique === this.caracteristique.id);
        if (!value || value.length === 0) {
            const valeur = <ValeurCaracteristique>{
                id: 0,
                idCaracteristique: this.caracteristique.id,
                value: this.defaultValue || this.defaultValue === '0' ? this.defaultValue : null
            };
            this.model.push(valeur);
            this.valeurs.push(valeur);
        } else {
            for (let i = 0; i < value.length; i++) {
                const v = value[i];
                if (typeof v.value === 'string') {
                    v.value = parseFloat(v.value);
                }
                this.model.push(v);
            }
        }
    }
    $onDestroy(): void {}
    addLine(): void {
        const valeur = <ValeurCaracteristique>{
            id: 0,
            idCaracteristique: this.caracteristique.id,
            value: null
        };
        this.model.push(valeur);
        this.valeurs.push(valeur);
    }
    removeLine(index: number): void {
        const idx = this.valeurs.findIndex((x) => x.idCaracteristique === this.model[index].idCaracteristique && x.value === this.model[index].value);
        this.valeurs.splice(idx, 1);
        this.model.splice(index, 1);
    }
    getStep() {
        let rounded = this.ROUNDED_DEFAULT;
        if (this.caracteristique.arrondi) {
            rounded = this.caracteristique.arrondi;
        }
        let start = '1';
        for (let i = 0; i < rounded; i++) {
            start += '0';
        }

        return 1 / parseInt(start);
    }
}
export default {
    bindings: {
        caracteristique: '<',
        valeurs: '=',
        isObligatoire: '<',
        lecture: '<',
        defaultValue: '='
    },
    controller: CaractListNumController,
    template: require('./caracteristique-list-numerique.html')
};
