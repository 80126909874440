//Config
import StocksCloturesConfig from './_config/stocks.clotures.config';
import StocksCloturesRoutes from './_config/stocks.clotures.routes';
import StocksPreCloturesRoutes from './_config/stocks.preclotures.routes';

//Components
import StockClotureJourComponent from './components/stock-cloture-jour';
import StockClotureJourPopupComponent from './components/stock-cloture-jour-popup';
import StockPreclotureMoisComponent from './components/stock-precloture-mois';
import StockPreclotureMoisPopupComponent from './components/stock-precloture-mois-popup';
import StockClotureMoisComponent from './components/stock-cloture-mois';
import StockClotureMoisPopupComponent from './components/stock-cloture-mois-popup';

//services
import StocksCloturesService from './services/stocks.clotures.service';

const moduleName = 'app.massia.gestion.stocks.clotures';

angular
    .module(moduleName, [])
    .config(StocksCloturesConfig)
    .config(StocksCloturesRoutes)
    .config(StocksPreCloturesRoutes)
    .service('StocksCloturesService', StocksCloturesService)
    .component('stockClotureJour', StockClotureJourComponent)
    .component('stockClotureJourPopup', StockClotureJourPopupComponent)
    .component('stockPreclotureMois', StockPreclotureMoisComponent)
    .component('stockPreclotureMoisPopup', StockPreclotureMoisPopupComponent)
    .component('stockClotureMois', StockClotureMoisComponent)
    .component('stockClotureMoisPopup', StockClotureMoisPopupComponent);

export default moduleName;
