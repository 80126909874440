import validatorBuilder from 'validator-builder';

let grilleZonesKilometriquesValidator = null;

export default class GrilleZonesKilometriques {
    constructor(data) {
        data = data || {};
        this.id = data.id || undefined;
        this.code = data.code || undefined;
        this.libelle = data.libelle || undefined;
        if (data.estVente == undefined) {
            this.achatVente = 1;
            this.estVente = true;
        } else {
            if (data.estVente) {
                this.achatVente = 1;
                this.estVente = true;
            } else {
                this.achatVente = 2;
                this.estVente = false;
            }
        }
        this.zones = data.zones || undefined;
    }

    isValid() {
        grilleZonesKilometriquesValidator = grilleZonesKilometriquesValidator || validatorBuilder.getInstanceFor('GrilleZonesKilometriques');
        const validationResults = grilleZonesKilometriquesValidator.validate(this);
        return validationResults.isValid;
    }
}
