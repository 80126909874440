(function (angular, undefined) {
    'use strict';

    angular.module('blocks.modal').provider('ModalService', ModalServiceProvider);
    ModalServiceProvider.$inject = ['modalTypes', 'modalDefaultConfig'];

    function ModalServiceProvider(modalTypes, modalDefaultConfig) {
        var provider = this;

        var defaultValues = angular.copy(modalDefaultConfig);

        provider.setDefaultValues = setDefaultValues;
        provider.enableDrag = enableDrag;
        provider.enableResize = enableResize;
        provider.$get = ModalService;

        function setDefaultValues(userValues) {
            angular.extend(defaultValues, userValues);
        }

        function enableDrag() {
            defaultValues.enableDrag = true;
        }

        function enableResize() {
            defaultValues.enableResize = true;
            // defaultValues.windowTemplateUrl = 'blocks/modal/window.resize.html';
        }

        ModalService.$inject = ['$uibModal', 'modalTypes'];
        function ModalService($modal, modalTypes) {
            var service = {
                // service Bootstrap UI
                open: $modal.open,
                // modale avec template et controller
                openModal: openModal,
                // modales d'information (message + bouton OK uniquement)
                simple: simple,
                success: success,
                info: info,
                warning: warning,
                danger: danger,
                // modale de confirmation (message + OK - CANCEL)
                confirm: confirm
            };
            return service;

            function openModal(config, templateUrl) {
                return $modal.open({
                    windowTemplateUrl: config.windowTemplateUrl || defaultValues.windowTemplateUrl,
                    windowClass: config.windowClass,
                    windowTopClass: config.windowTopClass,
                    templateUrl: templateUrl || defaultValues.templateUrl,
                    controller: defaultValues.controller,
                    controllerAs: defaultValues.controllerAs,
                    backdrop: config.backdrop || defaultValues.backdrop,
                    keyboard: config.keyboard || defaultValues.keyboard,
                    backdropClass: config.backdropClass,
                    size: config.size,
                    resolve: {
                        modalData: function () {
                            return config.resolve;
                        },
                        modalConfig: function () {
                            return {
                                modalType: config.modalType || modalTypes.DEFAULT,
                                modalTitle: config.modalTitle,
                                headerClass: config.headerClass,
                                templateUrl: config.templateUrl,
                                controller: config.controller,
                                controllerAs: config.controllerAs,
                                enableDrag: config.enableDrag || defaultValues.enableDrag,
                                enableResize: config.enableResize || defaultValues.enableResize,
                                modalMsg: config.modalMsg,
                                buttonOK: config.buttonOK || defaultValues.buttonOK,
                                buttonCancel: config.buttonCancel || defaultValues.buttonCancel,
                                buttonClass: config.buttonClass || defaultValues.buttonClass
                            };
                        }
                    }
                });
            }

            function simple(config) {
                return openModal(config, 'blocks/modal/info.tpl.html');
            }

            function success(config) {
                config.headerClass = config.headerClass ? config.headerClass : 'modal-success';
                config.buttonClass = config.buttonClass ? config.buttonClass : 'success';
                return openModal(config, 'blocks/modal/info.tpl.html');
            }

            function info(config) {
                config.headerClass = config.headerClass ? config.headerClass : 'modal-info';
                config.buttonClass = config.buttonClass ? config.buttonClass : 'info';
                return openModal(config, 'blocks/modal/info.tpl.html');
            }

            function warning(config) {
                config.headerClass = config.headerClass ? config.headerClass : 'modal-warning';
                config.buttonClass = config.buttonClass ? config.buttonClass : 'warning';
                return openModal(config, 'blocks/modal/info.tpl.html');
            }

            function danger(config) {
                config.headerClass = config.headerClass ? config.headerClass : 'modal-danger';
                config.buttonClass = config.buttonClass ? config.buttonClass : 'danger';
                return openModal(config, 'blocks/modal/info.tpl.html');
            }

            function confirm(config) {
                config.buttonOK = config.buttonOK ? config.buttonOK : 'BUTTON_YES';
                config.buttonCancel = config.buttonCancel ? config.buttonCancel : 'BUTTON_NO';
                config.headerClass = config.headerClass ? config.headerClass : 'modal-confirm';
                return openModal(config, 'blocks/modal/confirm.tpl.html');
            }
        }
    }
})(angular);
