PersonneValidator.$inject = ['validator'];

export default function PersonneValidator(validator) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').lengthBetweenValues(0, 15).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance.ruleFor('nom').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('nom').lengthBetweenValues(0, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance.ruleFor('prenom').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('prenom').lengthBetweenValues(0, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance.ruleFor('idType').notEmpty().when(isPersonne).withMessage('VALIDATION_NOTEMPTY');

    //Matches conditionnels, vides autorisés
    instance.ruleFor('mail').emailAddress().when(isCompleted).withMessage('VALIDATION_EMAIL');
    /*instance.ruleFor('telFixe').matches(/(0|\+33)\d{9}/).when(isCompleted).withMessage('VALIDATION_PHONE');
    instance.ruleFor('telMobile').matches(/(0|\+33)\d{9}/).when(isCompleted).withMessage('VALIDATION_PHONEMOBILE'); */

    instance.ruleFor('login').notEmpty().when(isUtilisateur).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('login').length(1, 50).when(isUtilisateur).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance
        .ruleFor('login')
        .matches(/^[a-zA-Z0-9_|]*$/)
        .when(isUtilisateur)
        .withMessage('UTILISATEURS.LOGIN_CHARACTERS_NOK');

    instance.ruleFor('password').notEmpty().when(modeCreation).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('passwordConfirm').notEmpty().when(modeCreation).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('passwordConfirm').must(confirmPassword).when(modeCreation).withMessage('UTILISATEURS.PASSWORD_NOT_SAME');

    instance.ruleFor('automaticPassword').lengthBetweenValues(0, 50).when(isUtilisateur).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance.ruleFor('showEvolutions').lengthBetweenValues(0, 25).when(isUtilisateur).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    return instance;

    function isUtilisateur(obj, prop) {
        return obj.isUtilisateur;
    }

    function isPersonne(obj, prop) {
        return obj.isPersonne;
    }

    function isCompleted(obj, prop) {
        return prop != null && typeof prop !== undefined && prop != '';
    }

    function confirmPassword(obj, prop) {
        if (obj && prop) {
            return obj.password === prop;
        }

        return true;
    }

    function modeCreation(obj, prop) {
        if (!obj.id && obj.isUtilisateur) {
            return true;
        }
        return false;
    }
}
