import ReferenceControle from './reference.controle.model';

export default class ReferenceControleFormController {
    /* @ngInject */
    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        ReferencesControleCommunicationService,
        ReferencesControleService,
        notification,
        $location,
        $anchorScroll,
        _,
        MassiaApplicationService,
        $uibModal,
        ReferencesControleTypesService,
        $filter,
        $translate,
        CalculsStatistiquesService,
        ImagesService,
        CodeAutomatiqueService,
        NormesService,
        RouterHistoryService,
        ProduitsService,
        TypesService,
        FTPArchiveeService,
        MOPService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ReferencesControleCommunicationService = ReferencesControleCommunicationService;
        this.ReferencesControleService = ReferencesControleService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this._ = _;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$uibModal = $uibModal;
        this.ReferencesControleTypesService = ReferencesControleTypesService;
        this.$filter = $filter;
        this.$translate = $translate;
        this.CalculsStatistiquesService = CalculsStatistiquesService;
        this.ImagesService = ImagesService;
        this.CodeAutomatiqueService = CodeAutomatiqueService;
        this.NormesService = NormesService;
        this.RouterHistoryService = RouterHistoryService;
        this.ProduitsService = ProduitsService;
        this.TypesService = TypesService;
        this.FTPArchiveeService = FTPArchiveeService;

        this.ongletOpen = {
            isEnteteOpen: true,
            isMesuresOpen: false,
            isCaracteristiqueOpen: false
        };
        this.ongletOpenVal = {
            isEnteteOpen: 'entete',
            isCaracteristiqueOpen: 'caracteristiques'
        };

        this.essaisFtp = [];
        this.MOPService = MOPService;
    }

    async $onInit() {
        this.MOPService.setMop([{ title: 'REFERENCES.BREADCRUMBS.REFERENCES_LIST', filename: 'References.pdf', application: 'laboratoire' }]);
        try {
            this.loading = true;
            this.referenceControle = {};
            this.normes = this.normes || [];
            this.types = this.types || [];
            this.produit = {};
            this.producteur = undefined;
            this.getTypes();
            this.loading = false;

            const domaineCourant = this.getDomaineCourant();
            if (domaineCourant === 'seuils') {
                this.domaineMesure === 'mesures';
            } else if (domaineCourant === 'seuilsecu') {
                this.domaineMesure = 'messecu';
            } else if (domaineCourant === 'seuilctrl') {
                this.domaineMesure = 'controles';
            } else if (domaineCourant === 'seuilgran') {
                this.domaineMesure = 'essais';
            }

            this.form = {
                entete: {},
                caracteristiques: {}
            };

            this.getNiveauVisibilite();
            this.getCalculsStatistiques();
            await this.checkCodeAuto();
            this.reset(true);
            this.getCalculStatistiqueDefaut();
        } catch (err) {
            console.error(err);
        }
        this.loading = false;
    }

    async checkCodeAuto() {
        try {
            const code = await this.CodeAutomatiqueService.getCodeAutomatique(this.MassiaApplicationService.getApplication());
            const res = code.find((x) => x.domaineCode === 'SeuilGran');

            this.code = res;
        } catch (err) {
            console.error(err);
        }
    }

    async iStevenNorme() {
        if (this.referenceControle && this.referenceControle.familleCode) {
            const res = await this.NormesService.getNormeSpecList(this.referenceControle.familleCode);
            const normeTrie = [];
            for (let i = 0; i < res.length; i++) {
                const element = res[i];

                element.list = this.normes.filter((x) => x.normeSpec === element.id);
                normeTrie.push(element);
            }
            const liste = this.normes.filter((x) => x.normeSpec === 0);

            const el = { libelle: this.$translate.instant('REFERENCES.SANS_SPECIFICATION'), list: liste };

            normeTrie.push(el);
            this.normeTrie = this.$filter('istevenFormat')(normeTrie, 'libelle', 'list');
        }
    }

    async changeNorme(choixNorme, data, list, importNorme) {
        try {
            if (choixNorme && data) {
                if (this.referenceControle.idNorme === data.id) {
                    const idx = list.findIndex((x) => x.id === this.referenceControle.idNorme);

                    if (idx > -1) {
                        list[idx].selected = false;
                    }
                    this.referenceControle.idNorme = 0;
                } else {
                    list.map((x) => {
                        if (x.id !== data.id) {
                            x.selected = false;
                        }
                        return x;
                    });
                    this.referenceControle.idNorme = data.id;
                }
            }
            //this.startLoading();
            if (this.referenceControle.idNorme) {
                const norme = data;
                if (norme) {
                    if (this.referenceControle.idNorme > -1) {
                        this.referenceControle.normeNorme = norme.normeSpec;
                    } else {
                        this.referenceControle.normeNorme = -1;
                    }
                    if (choixNorme) {
                        this.referenceControle.nombreResultatsN = norme.n;
                    }
                }
                if (this.referenceControle.sitesProducteursIds.length > 0) {
                    const siteEnCours = this._.find(this.sites, { id: this.referenceControle.sitesProducteursIds[0] });
                    if (siteEnCours) {
                        this.producteur = {
                            id: this.referenceControle.sitesProducteursIds[0],
                            libelle: siteEnCours.libelle,
                            code: siteEnCours.code
                        };
                    }
                    if (this.referenceControle.sitesProducteursIds.length > 1) {
                        this.notification.error(this.$translate.instant('REFERENCES.PRODUCTEUR_UNIQUE'));
                    }
                }
                if (this.referenceControle.produitsIds.length > 0) {
                    const produitEnCours = this._.find(this.produits, { id: this.referenceControle.produitsIds[0] });
                    if (produitEnCours) {
                        this.produit = await this.ProduitsService.getProduitEnteteById(this.referenceControle.produitsIds[0]);
                    }
                    if (this.referenceControle.produitsIds.length > 1) {
                        this.notification.error(this.$translate.instant('REFERENCES.PRODUIT_UNIQUE'));
                    }
                }
                if (this.produit && this.produit.id) {
                    this.specificationsNorme = await this.ReferencesControleService.getSpecificationsNorme(
                        this.referenceControle.idNorme,
                        this.produit.id
                    );
                } else {
                    this.specificationsNorme = { categories: [], specifications: [] };
                }
            } else {
                if (this.referenceControle.sitesProducteursIds.length == 0 && this.producteur) {
                    this.referenceControle.sitesProducteursIds.push(this.producteur.id);
                }
                this.producteur = undefined;
                if (this.referenceControle.produitsIds.length == 0 && this.produit) {
                    this.referenceControle.produitsIds.push(this.produit.id);
                }
                this.produit = undefined;
                this.specificationsNorme = { categories: [], specifications: [] };
            }
            if (this.produit && this.produit.id) {
                this.incertitudes = [];
                if (this.referenceControle.normeNorme) {
                    this.incertitudes = await this.ReferencesControleService.getIncertitudesEssaisByNorme(
                        this.referenceControle.normeNorme,
                        this.produit.id
                    );
                }
                if (choixNorme) {
                    //recherche les infos des spécifications pour les essais de la référence
                    for (let i = 0; i < this.referenceControle.mesures.length; i++) {
                        const currentIncertitudeReference = this.referenceControle.mesures[i].incertitudeReference;
                        this.chercheSpecification(this.referenceControle.mesures[i], false);
                        this.ReferencesControleService.chercheIncertitude(
                            this.referenceControle.mesures[i],
                            currentIncertitudeReference,
                            this.incertitudes,
                            importNorme
                        );
                        if (this.referenceControle && this.referenceControle.familleCode === 'ENROB') {
                            this.calculDesBornesEnrobes(this.referenceControle.mesures[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < this.referenceControle.mesures.length; i++) {
                        this.referenceControle.mesures[i].categories = this.getCategories(this.referenceControle.mesures[i]);
                    }
                }
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } /*finally {
            this.stopLoading();
        }*/
    }

    async changeProduit() {
        if (this.referenceControle.produitsIds.length > 0) {
            this.referenceControle.produitsIds[0] = this.produit.id;
            this.referenceControle.produits[0] = this.produit.libelle;
        }
        await this.changeNorme(true); //rechercher les spécifications et incertitudes si changement de produit
    }

    chercheSpecification(mesure, complete) {
        if (this.specificationsNorme.specifications.length > 0) {
            /* mesure.limiteInferieure = undefined;
			 mesure.limiteSuperieure = undefined;*/
            //mesure.etendueReference = undefined;
            //mesure.incertitudeReference = undefined;
            //mesure.isETControle = undefined;
            //mesure.enveloppe = undefined;
            mesure.categories = this.getCategories(mesure);
            const specificationsTrouvees = [];
            const sepOkPourData = (3 / 2).toString().substring(1, 2);
            for (let i = 0; i < this.specificationsNorme.specifications.length; i++) {
                const specification = this.specificationsNorme.specifications[i];
                for (let j = 0; j < specification.essais.length; j++) {
                    const specEssai = specification.essais[j];
                    let conditionsOk = true;
                    if (mesure.tamis) {
                        if (specEssai && !specEssai.idTamis && specEssai.condition && specEssai.condition.includes('@TAMIS')) {
                            let tmpCondition = angular.copy(specEssai.condition);
                            tmpCondition = tmpCondition.replace(/@TAMIS/g, mesure.ouvertureTamis).replace(/ /g, '');
                            if (sepOkPourData === ',') {
                                tmpCondition = tmpCondition.replace(/\./g, ',');
                            } else {
                                tmpCondition = tmpCondition.replace(/\,/g, '.');
                            }
                            if (tmpCondition.includes('AND')) {
                                tmpCondition = tmpCondition.replace(/AND/g, '&&');
                            }
                            if (tmpCondition.includes('OR')) {
                                tmpCondition = tmpCondition.replace(/OR/g, '||');
                            }
                            conditionsOk = eval(tmpCondition);
                        } else {
                            conditionsOk = specEssai.ouvertureTamis === mesure.ouvertureTamis; //specEssai.idTamis === mesure.sousEssaiId;
                        }
                    } else {
                        conditionsOk = specEssai.idSousEssai === mesure.sousEssaiId;
                    }
                    if (conditionsOk && (specEssai.idCalcStat === null || specEssai.idCalcStat === mesure.calculId)) {
                        specificationsTrouvees.push({ idCateg: specification.idCategorie, spec: specEssai });
                    }
                }
            }
            let specOk = undefined;
            if (specificationsTrouvees.length > 1) {
                const ordreCateg = -1;
                //trouver la spécification avec la catégorie la meilleure
                for (let i = 0; i < specificationsTrouvees.length; i++) {
                    if (specificationsTrouvees[i].idCateg && specificationsTrouvees[i].idCateg === mesure.categorieId) {
                        specOk = specificationsTrouvees[i];
                        break;
                    } else {
                        specOk = specificationsTrouvees[i];
                    }
                }
            } else if (specificationsTrouvees.length === 1) {
                specOk = specificationsTrouvees[0];
            }
            if (specOk) {
                if (mesure.categorieId) {
                    mesure.limiteInferieure = specOk.spec.limiteInferieure;
                    mesure.limiteSuperieure = specOk.spec.limiteSuperieure;
                    if (complete) {
                        mesure.etendueReference = specOk.spec.etendue;
                        mesure.incertitudeReference = specOk.spec.incertitude;
                        //mesure.isETControle = specOk.spec.isETControle;
                        mesure.categorieId = mesure.categorieId || specOk.idCateg;
                        //mesure.enveloppe = specOk.spec.enveloppe;
                    }
                }
            } else {
                mesure.limiteInferieure = mesure.limiteInferieure || null;
                mesure.limiteSuperieure = mesure.limiteSuperieure || null;
                if (complete) {
                    mesure.etendueReference = mesure.etendueReference || null;
                    mesure.incertitudeReference = mesure.incertitudeReference || null;
                    //mesure.isETControle = null;
                    mesure.categorieId = mesure.categorieId || 0;
                    //mesure.enveloppe = null;
                }
            }
        }
    }

    getCategories(mesure) {
        const categOk = [];
        if (this.specificationsNorme) {
            for (let iSpec = 0; iSpec < this.specificationsNorme.specifications.length; iSpec++) {
                const specTmp = this.specificationsNorme.specifications[iSpec];
                if (
                    !this._.find(categOk, function (ct) {
                        return ct.id === (specTmp.idCategorie ? specTmp.idCategorie : 0);
                    })
                ) {
                    if (!mesure.tamis) {
                        if (
                            this._.find(specTmp.essais, function (elt) {
                                return elt.idEssai === mesure.essaiId && elt.idSousEssai === mesure.sousEssaiId;
                            })
                        ) {
                            specTmp.idCategorie
                                ? categOk.push({
                                      id: specTmp.idCategorie,
                                      ordre: specTmp.ordreCategorie,
                                      categorie: specTmp.libelleCategorie
                                  })
                                : categOk.push({ id: 0, ordre: -1, categorie: '' });
                        }
                    } else {
                        if (
                            this._.find(specTmp.essais, function (elt) {
                                return elt.idEssai === mesure.essaiId && elt.ouvertureTamis === mesure.ouvertureTamis;
                            })
                        ) {
                            specTmp.idCategorie
                                ? categOk.push({
                                      id: specTmp.idCategorie,
                                      ordre: specTmp.ordreCategorie,
                                      categorie: specTmp.libelleCategorie
                                  })
                                : categOk.push({ id: 0, ordre: -1, categorie: '' });
                        }
                    }
                }
            }
        }
        return categOk;
    }

    async getNiveauVisibilite() {
        try {
            if (this.referenceControle && this.referenceControle.typeId) {
                this.referenceControle.niveauVisibilite = await this.ReferencesControleTypesService.GetNiveauVisibilite(
                    this.referenceControle.typeId
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    async getImageFromServer() {
        this.imageFile = await this.ImagesService.getShowImageById(this.referenceControle.idLogo);
    }

    $onDestroy() {
        this.$timeout.cancel(this.updateReferenceTimeout);
        this.MOPService.resetMop();
    }

    async getCalculStatistiqueDefaut() {
        this.startLoading();
        try {
            this.calculStatDefaut = await this.CalculsStatistiquesService.getCalculStatistiqueValeur();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async getCalculsStatistiques() {
        try {
            this.calculsStatistiques = await this.CalculsStatistiquesService.getCalculsStatistiquesSelect();
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    getDomaineCourant() {
        try {
            const domaine = this.$state.current.name;
            return domaine.substring(0, domaine.indexOf('.'));
        } catch (error) {
            console.log(error);
        }
    }

    async reset(choixNorme = false) {
        this.startLoading();
        try {
            let data = {
                id: this.$stateParams.id
            };

            if (data.id) {
                try {
                    data = await this.ReferencesControleService.getReferenceById(data.id);
                    data.caracteristiques = await this.ReferencesControleService.getReferenceControleValeursCaracteristiquesAssociationsById(data.id);
                } catch (err) {
                    if (err.data) {
                        this.notification.error(err.data);
                    } else {
                        throw err;
                    }
                    this.annuler();
                }
            } else {
                data.isEnabled = true;
            }
            this.referenceControle = new ReferenceControle(data);

            this.type = {
                id: this.referenceControle.typeId,
                idFamille: this.referenceControle.familleId,
                codeFamille: this.referenceControle.familleCode
            };

            this.selectedSitesProducteurs = [];

            await this.getSitesProducteurs();
            await this.getProduits();
            await this.getSitesClients();

            if (this.referenceControle && this.referenceControle.idNorme) {
                if (this.referenceControle.produitsIds.length > 0) {
                    const produitEnCours = this._.find(this.produits, { id: this.referenceControle.produitsIds[0] });
                    if (produitEnCours) {
                        this.produit = await this.ProduitsService.getProduitEnteteById(this.referenceControle.produitsIds[0]);
                    }
                }
                if (this.referenceControle && this.referenceControle.sitesProducteursIds && this.referenceControle.sitesProducteursIds.length > 0) {
                    const siteEnCours = this._.find(this.sites, { id: this.referenceControle.sitesProducteursIds[0] });
                    if (siteEnCours) {
                        this.producteur = {
                            id: this.referenceControle.sitesProducteursIds[0],
                            libelle: siteEnCours.libelle,
                            code: siteEnCours.code
                        };
                    }
                }
            }

            if (this.$stateParams.duplicate) {
                this.referenceControle.id = undefined;
                this.referenceControle.code += '_copie';
            }
            this.ongletOpen = {
                isEnteteOpen: true,
                isMesuresOpen: true,
                isCaracteristiqueOpen: false
            };

            if (this.domaineMesure == 'essais') {
                await this.getNormes();
                await this.getLogos();

                if (this.referenceControle && this.referenceControle.idLogo) {
                    await this.getImageFromServer();
                }
            }

            await this.changeNorme(choixNorme); //rechercher les spécifications et incertitudes au chargement (les valeurs en bdd ne correspondent peut-être plus aux valeurs des spécification de la norme)
            if (choixNorme) {
                await this.iStevenNorme();
            }

            this.$timeout.cancel(this.updateReferenceTimeout);
            this.updateReferenceTimeout = this.$timeout(() => this.stopLoading());
        } catch (error) {
            console.log(error);
        }
    }

    videSiteProducteur() {
        this.producteur = undefined;
    }

    videProduit() {
        this.produit = undefined;
    }

    async getSitesProducteurs() {
        try {
            this.sites = await this.ReferencesControleService.getSitesProducteurs();
            if (this.referenceControle && this.referenceControle.sitesProducteursIds) {
                for (let i = 0; i < this.referenceControle.sitesProducteursIds.length; i++) {
                    const elem = this.referenceControle.sitesProducteursIds[i];
                    this.sites = this.$filter('selectInIsteven')(this.sites, 'id', elem, 'selected');
                }
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async getSitesProducteursTypeAhead(valeur) {
        const res = await this.ReferencesControleService.getProducteursTypeAhead(valeur);
        return res;
    }

    async saveSelectedProducteurs(data, list, output, item) {
        if (item && data.id === item[0]) {
            list = list.map((x) => {
                x.selected = false;
                return x;
            });
            output = [];
            item = [];
            this.referenceControle.sitesProducteursIds = [];
        } else {
            item[0] = data.id;
            this.referenceControle.sitesProducteursIds[0] = data.id;
        }
        await this.getProduits();
    }

    async saveSelectedProduits(data, list, output, item) {
        if (item && data.id === item[0]) {
            list = list.map((x) => {
                x.selected = false;
                return x;
            });
            output = [];
            item = [];
            this.produit = {};
            this.referenceControle.produitsIds = [];
        } else {
            item[0] = data.id;
            this.referenceControle.produitsIds[0] = data.id;
            this.produit = data;
        }
    }

    async getSitesClients() {
        try {
            this.clients = await this.ReferencesControleService.getSitesClients();
            if (this.referenceControle && this.referenceControle.sitesClientsIds) {
                this.clients.forEach((item) => {
                    if (this.referenceControle.sitesClientsIds.indexOf(item.id) !== -1) {
                        item.selected = true;
                    }
                });
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    deleteAllMesures() {
        this.referenceControle.mesures.splice(0, this.referenceControle.mesures.length);
    }

    deleteMesure(mesure) {
        const lstFindMesures = this.referenceControle.mesures.filter((e) => e.sousEssaiId === mesure.sousEssaiId);
        if (lstFindMesures.length > 0) {
            for (let j = 0; j < lstFindMesures.length; j++) {
                const indice = this.referenceControle.mesures.indexOf(lstFindMesures[j]);

                this.referenceControle.mesures.splice(indice, 1);
            }

            for (let i = 0; i < this.referenceControle.mesures.length; i++) {
                this.referenceControle.mesures[i].position = i;
            }
        }
    }

    deleteMesureItem(mesure) {
        const indice = this.referenceControle.mesures.indexOf(mesure);
        this.referenceControle.mesures.splice(indice, 1);
        for (let i = 0; i < this.referenceControle.mesures.length; i++) {
            this.referenceControle.mesures[i].position = i;
        }
    }

    duplicateMesure(mesure) {
        // console.log(mesure);
        const indice = this.referenceControle.mesures.indexOf(mesure);
        const newMesure = angular.copy(mesure);
        newMesure.id = -1;
        newMesure.condition = '';
        newMesure.categorieId = -1;
        newMesure.calculId = this.calculStatDefaut.id;
        newMesure.calculLibelle = this.calculStatDefaut.libelle;
        newMesure.calculStatListe = angular.copy(this.calculsStatistiques);
        const calculStat = newMesure.calculStatListe.find((x) => x.id === newMesure.calculId);
        if (calculStat) {
            calculStat.selected = true;
        }
        newMesure.etendueReference = null;
        newMesure.incertitudeReference = null;
        newMesure.borneInferieure = null;
        newMesure.borneSuperieure = null;
        newMesure.alerte = null;
        newMesure.moyenne = null;
        newMesure.isETControle = null;
        this.chercheSpecification(newMesure, true);
        this.referenceControle.mesures.splice(indice + 1, 0, newMesure);

        for (let i = 0; i < this.referenceControle.mesures.length; i++) {
            this.referenceControle.mesures[i].position = i;
        }
    }

    selectMesure(mesure, ignoreRechercheSpec) {
        // console.log(angular.copy(mesure));
        if (!this.referenceControle.mesures) {
            this.referenceControle.mesures = [];
        }
        if (!mesure.calculStatListe) {
            mesure.calculStatListe = angular.copy(this.calculsStatistiques);
        }

        const lstFindMesures = this.referenceControle.oldMesures
            ? this.referenceControle.oldMesures.filter(
                  (e) => e.tamis && e.sousEssaiId === mesure.sousEssaiId && e.ouvertureTamis === mesure.ouvertureTamis
                  /* || (!e.tamis && e.sousEssaiId === mesure.sousEssaiId)) */
              )
            : [];

        if (lstFindMesures.length <= 0) {
            if (mesure.id <= 0) {
                mesure.calculId = this.calculStatDefaut.id;
                mesure.calculLibelle = this.calculStatDefaut.libelle;
                mesure.libelleComplet = mesure.essaiCodeLibelle + ' : ' + mesure.sousEssaiCodeLibelle + ' : ' + mesure.calculLibelle;
                const calculStat = mesure.calculStatListe.find((x) => x.id === mesure.calculId);
                if (calculStat) {
                    calculStat.selected = true;
                }
            }
            if (!ignoreRechercheSpec) {
                this.chercheSpecification(mesure, true);
            } else {
                /**+ incertitude */
                mesure.categories = this.getCategories(mesure);
            }
            //ajout depuis la norme ou pas, mettre les VSI/VSS aux LI/LS si pas d'étendue
            if (
                (mesure.etendueReference == null || mesure.etendueReference == undefined) &&
                mesure.limiteInferieure != null &&
                mesure.limiteInferieure != undefined
            ) {
                mesure.borneInferieure = mesure.limiteInferieure;
            }
            if (
                (mesure.etendueReference == null || mesure.etendueReference == undefined) &&
                mesure.limiteSuperieure != null &&
                mesure.limiteSuperieure != undefined
            ) {
                mesure.borneSuperieure = mesure.limiteSuperieure;
            }
            this.ReferencesControleService.chercheIncertitude(mesure, undefined, this.incertitudes);
            if (this.referenceControle && this.referenceControle.familleCode === 'ENROB') {
                this.calculDesBornesEnrobes(mesure);
            }
            this.referenceControle.mesures.splice(mesure.position, 0, mesure);
            this.trieEssais();
        } else {
            for (let i = 0; i < lstFindMesures.length; i++) {
                mesure = angular.copy(lstFindMesures[i]);
                this.referenceControle.mesures.splice(mesure.position, 0, mesure);
                if (!ignoreRechercheSpec) {
                    this.chercheSpecification(mesure, true);
                }
            }

            this.trieEssais();
        }
    }

    majIncertitude(mesure) {
        //remplacer par code famille

        if (this.referenceControle && this.referenceControle.familleCode === 'ENROB') {
            this.calculDesBornesEnrobes(mesure);
        }
        this.ReferencesControleService.chercheIncertitude(mesure, undefined, this.incertitudes, true);
    }

    calculDesBornesEnrobes(mesure) {
        if (this.referenceControle && this.referenceControle.familleCode === 'ENROB') {
            if (mesure && typeof Number.parseFloat(mesure.moyenne) === 'number') {
                if (mesure.limiteInferieure) {
                    const tmpInf = mesure.limiteInferieure + Number.parseFloat(mesure.moyenne || 0);
                    if (typeof Number.parseFloat(tmpInf) === 'number') {
                        if (mesure.grandeurMinimum && mesure.grandeurMinimum > tmpInf) {
                            mesure.borneInferieure = mesure.grandeurMinimum;
                        } else {
                            mesure.borneInferieure = tmpInf;
                        }
                    } else {
                        mesure.borneInferieure = null;
                    }
                }
                if (mesure.limiteSuperieure) {
                    const tmpSup = mesure.limiteSuperieure + Number.parseFloat(mesure.moyenne || 0);
                    if (typeof Number.parseFloat(tmpSup) === 'number') {
                        if (mesure.grandeurMaximum && tmpSup > mesure.grandeurMaximum) {
                            mesure.borneSuperieure = mesure.grandeurMaximum;
                        } else {
                            mesure.borneSuperieure = tmpSup;
                        }
                    } else {
                        mesure.borneSuperieure = null;
                    }
                }
                return mesure;
            }
        }
    }

    resetReference() {
        this.referenceControle.oldMesures ? (this.referenceControle.oldMesures = []) : '';
        this.referenceControle.oldMesures = angular.copy(this.referenceControle.mesures);
        this.referenceControle.mesures = [];
    }

    trieEssais() {
        this.referenceControle.mesures = this.$filter('orderBy')(
            this.referenceControle.mesures,
            ['tamis', 'essaiCodeLibelle', 'sousEssaiCodeLibelle', 'calculLibelle'],
            false,
            function (v1, v2) {
                let retour = 0;
                if (v1.type == 'boolean') {
                    if (v1.value != v2.value) {
                        if (v1.value) {
                            retour = -1;
                        } else {
                            retour = 1;
                        }
                    } else {
                        retour = 0;
                    }
                } else if (v1.value) {
                    if (v1.value.includes('(')) {
                        retour = v1.value.localeCompare(v2.value);
                    } else {
                        //tri descendant pour les tamis
                        const d1 = parseFloat(v1.value.replace(',', '.').replace(' ', ''));
                        const d2 = parseFloat(v2.value.replace(',', '.').replace(' ', ''));
                        if (d1 != d2) {
                            if (d1 > d2) {
                                retour = -1;
                            } else {
                                retour = 1;
                            }
                        } else {
                            retour = 0;
                        }
                    }
                }
                return retour;
            }
        );

        for (let i = 0; i < this.referenceControle.mesures.length; i++) {
            this.referenceControle.mesures[i].position = i;
        }

        // this.referenceControle.mesures.sort(function (a, b) {
        //     return b.essaiId - a.essaiId || b.sousEssaiId - a.sousEssaiId;
        // });
    }

    async updateCategorie(mesure) {
        try {
            this.startLoading();
            let specifEssai = undefined;
            if (this.produit) {
                specifEssai = await this.ReferencesControleService.getSpecificationByCategorie(mesure, this.produit.id);
            }
            if (specifEssai) {
                mesure.limiteInferieure = specifEssai.limiteInferieure;
                mesure.limiteSuperieure = specifEssai.limiteSuperieure;
                mesure.etendueReference = specifEssai.etendue;
                mesure.incertitudeReference = mesure.incertitudeReference;
                //mesure.nombreEssaiReference = specifEssai.nombreEssai;
                //mesure.enveloppe = specifEssa.enveloppe;
            } else {
                mesure.limiteInferieure = undefined;
                mesure.limiteSuperieure = undefined;
                mesure.etendueReference = undefined;
                mesure.incertitudeReference = undefined;
                //mesure.nombreEssaiReference = undefined;
                //mesure.enveloppe = undefined;
            }
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        } finally {
            this.stopLoading();
        }
    }

    updateCalcStat(mesure) {
        this.chercheSpecification(mesure, true);
    }

    formatCaracteristiques(caracteristiques) {
        const data = [];
        for (const property in caracteristiques) {
            let keyValueObject;
            //On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux letiables interne Js.
            if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
                const propertyVal = caracteristiques[property];
                if (Array.isArray(propertyVal)) {
                    if (_.some(propertyVal, 'key')) {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: _.map(propertyVal, 'key')
                        };
                    } else {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: propertyVal
                        };
                    }
                } else {
                    keyValueObject = {
                        IdCaracteristique: parseInt(property),
                        valeur: propertyVal
                    };
                }
                data.push(keyValueObject);
            }
        }
        return data;
    }

    async sauvegarder() {
        if (this.checkPasDoublonLigne()) {
            if (this.checkValidity()) {
                if (this.referenceControle.typeId == null) {
                    this.notification.error(this.$translate.instant('REFERENCES.NOTYPE'));
                    return false;
                }

                /* if (this.produit)
					this.referenceControle.produitsIds = [this.produit.id];

				if (this.producteur)
					this.referenceControle.sitesProducteursIds = [this.producteur.id]; */

                this.startLoading();
                // conversion de la liste des caracteristiques au bon format
                const clonedCarac = Object.assign({}, this.referenceControle.caracteristiques);
                this.referenceControle.caracteristiques = this.formatCaracteristiques(clonedCarac);

                try {
                    let id = false;
                    if (this.referenceControle.id) {
                        await this.ReferencesControleService.updateReference(this.referenceControle);
                        this.notification.success('REFERENCES.UPDATED');
                        id = this.referenceControle.id;
                    } else {
                        id = await this.ReferencesControleService.createReference(this.referenceControle);
                        this.notification.success('REFERENCES.CREATED');
                    }

                    return id;
                } catch (ex) {
                    this.notification.error(ex.data);
                    return false;
                } finally {
                    this.stopLoading();
                }
            } else {
                if (this.referenceControle.$errors) {
                    const validationErrors = this.referenceControle.$errors.$all;
                    for (let i = 0; i < validationErrors.length; i++) {
                        const message = validationErrors[i].errorMessage;
                        this.notification.error(this.$translate.instant(message));
                    }
                }
                return false;
            }
        } else {
            this.notification.error(this.$translate.instant('REFERENCES.DOUBLON_LIGNE'));
            return false;
        }
    }

    async appliquer() {
        this.RouterHistoryService.ignoreNextRoute();
        const id = await this.sauvegarder();

        if (id && this.referenceControle.id) {
            await this.$onInit();
        } else if (id) {
            this.$state.go(this.getDomaineCourant() + '.edit', { id: id });
            await this.$onInit();
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    annuler() {
        if (!this.RouterHistoryService.back()) {
            this.$state.go(this.getDomaineCourant() + '.list');
        }
    }

    checkPasDoublonLigne() {
        const lignes = [];
        let ok = true;
        for (let i = 0; ok && i < this.referenceControle.mesures.length; i++) {
            const eltActu = this.referenceControle.mesures[i];
            let condition = eltActu.condition;
            if (!condition) {
                condition = '';
            }
            const ligne = eltActu.sousEssaiId + '#' + eltActu.ouvertureTamis + '#' + eltActu.calculId + '#' + condition;
            if (lignes.indexOf(ligne) !== -1) {
                ok = false;
            } else {
                lignes.push(ligne);
            }
        }
        return ok;
    }

    checkValidity() {
        let validity = true;

        let firstScroll = true;
        if (!this.referenceControle.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('entete');
                firstScroll = false;
            }

            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.$scope.$broadcast('referenceControleValidations');
        }
        return validity;
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    async createType() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'REFERENCES.CONFIRM_CREATE_TYPE.TITLE',
            modalMsg: 'REFERENCES.CONFIRM_CREATE_TYPE.MESSAGE',
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(() => this.$state.go(this.getDomaineCourant() + '.newType'));
    }

    async getTypes() {
        try {
            this.types = await this.ReferencesControleTypesService.getAll(this.getDomaineCourant());
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();
            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.referenceControle.codeExists = await this.ReferencesControleService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.referenceControle.codeExists = null;
        }
    }

    onUpdateCaracteristiques(caracteristiques) {
        this.referenceControle.caracteristiques = caracteristiques;
    }

    async getProduits() {
        try {
            this.produits = await this.ReferencesControleService.getProduitsByProducteurs(this.referenceControle.sitesProducteursIds);
            if (this.referenceControle && this.referenceControle.produitsIds) {
                for (let i = 0; i < this.referenceControle.produitsIds.length; i++) {
                    const elem = this.referenceControle.produitsIds[i];
                    this.produits = this.$filter('selectInIsteven')(this.produits, 'id', elem, 'selected');
                }
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async getProduitsTypeAhead(valeur) {
        try {
            if (this.producteur) {
                return await this.getProduitsByProducteur(valeur);
            }
            return await this.getProduitsWithoutProducteur(valeur);
        } catch (error) {
            console.log(error);
        }
    }

    async getProduitsByProducteur(valeur) {
        return await this.ReferencesControleService.getProduitsByProducteurTypeAhead(this.producteur.id, valeur);
    }

    async getProduitsWithoutProducteur(valeur) {
        return await this.ReferencesControleService.getProduitsWithoutProducteurTypeAhead(valeur);
    }

    async getNormes() {
        try {
            const normes = await this.NormesService.getNormes([], [], { skip: 0, take: 0 });
            if (this.referenceControle && this.referenceControle.familleCode) {
                this.normes = normes.items.filter((x) => x.codeFamille === this.referenceControle.familleCode || !x.codeFamille);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async getLogos() {
        try {
            this.logos = await this.ReferencesControleService.getLogos();
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async getTypesMesures(type) {
        this.referenceControle.idNorme = null;

        this.referenceControle.typeId = type.id;
        this.referenceControle.familleId = type.idFamille;
        this.referenceControle.familleCode = type.codeFamille;

        await this.getNormes();
        await this.iStevenNorme();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }

    async importNorme() {
        if (!this.produit || !this.type || !this.produit.id || !this.type.id) {
            return this.notification.error('NORMES.ALERT_IMPORT_NORME');
        }
        const _this = this;
        _this.idProduit =
            this.referenceControle.produitsIds && this.referenceControle.produitsIds.length === 1 ? this.referenceControle.produitsIds[0] : undefined;
        _this.idNorme = this.referenceControle.idNorme;
        const modalInstance = this.$uibModal.open({
            template:
                '<import-norme-modal modal-instance="$ctrl.uibModalInstance" id-produit="$ctrl.idProduit" id-norme="$ctrl.idNorme"></import-norme-modal>',
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    const $ctrl = this;
                    $ctrl.uibModalInstance = $uibModalInstance;
                    $ctrl.idProduit = _this.idProduit;
                    $ctrl.idNorme = _this.idNorme;
                }
            ],
            controllerAs: '$ctrl',
            size: 'xxl',
            backdrop: false
        });
        const resModal = await modalInstance.result;
        if (resModal) {
            const idSpecChoisies = this._.map(resModal.specChoisies, 'id');
            const idProduit =
                this.referenceControle.produitsIds && this.referenceControle.produitsIds.length === 1
                    ? this.referenceControle.produitsIds[0]
                    : undefined;
            const specificationsNorme = await this.ReferencesControleService.getSpecificationsNorme(resModal.idNorme, idProduit);
            if (specificationsNorme) {
                for (let iSpecNorme = 0; iSpecNorme < specificationsNorme.specifications.length; iSpecNorme++) {
                    const specNormeActu = specificationsNorme.specifications[iSpecNorme];
                    if (this._.find(idSpecChoisies, (elt) => elt === specNormeActu.id)) {
                        for (let iEssNorme = 0; iEssNorme < specNormeActu.essais.length; iEssNorme++) {
                            const essaiNormeActu = specNormeActu.essais[iEssNorme];
                            essaiNormeActu.categorieId = specNormeActu.idCategorie;
                            essaiNormeActu.calculId = this.calculStatDefaut.id;
                            essaiNormeActu.calculLibelle = this.calculStatDefaut.libelle;
                            essaiNormeActu.calculStatListe = angular.copy(this.calculsStatistiques);
                            const calculStat = essaiNormeActu.calculStatListe.find((x) => x.id === essaiNormeActu.calculId);
                            if (calculStat) {
                                calculStat.selected = true;
                            }
                            essaiNormeActu.essaiId = essaiNormeActu.idEssai;
                            essaiNormeActu.codeEssai = essaiNormeActu.code;
                            essaiNormeActu.symboleNormeEssai = essaiNormeActu.symboleEssai;
                            essaiNormeActu.etendueReference = essaiNormeActu.etendue;
                            essaiNormeActu.borneInferieure = null;
                            essaiNormeActu.borneSuperieure = null;
                            essaiNormeActu.alerte = null;
                            essaiNormeActu.moyenne = null;
                            essaiNormeActu.isETControle = null;
                            essaiNormeActu.unite = '';
                            essaiNormeActu.arrondi = 2; //par défaut, mais pas réel...
                            essaiNormeActu.essaiCodeLibelle = essaiNormeActu.libelleEssai + ' (' + essaiNormeActu.code + ')';
                            if (essaiNormeActu.idTamis) {
                                essaiNormeActu.tamis = true;
                                essaiNormeActu.sousEssaiId = essaiNormeActu.idTamis;
                                essaiNormeActu.sousEssaiCodeLibelle = essaiNormeActu.ouvertureTamis.toString();
                                essaiNormeActu.libelleComplet =
                                    essaiNormeActu.essaiCodeLibelle +
                                    ' : ' +
                                    essaiNormeActu.sousEssaiCodeLibelle +
                                    ' : ' +
                                    essaiNormeActu.calculLibelle;
                                const essaiDansRefTamis = this._.find(this.referenceControle.mesures, function (elt) {
                                    return (
                                        elt.tamis === true &&
                                        elt.essaiId === essaiNormeActu.idEssai &&
                                        elt.ouvertureTamis === essaiNormeActu.ouvertureTamis
                                    );
                                });
                                if (!essaiDansRefTamis) {
                                    this.selectMesure(essaiNormeActu, true);
                                } else {
                                    essaiDansRefTamis.limiteInferieure = essaiNormeActu.limiteInferieure;
                                    essaiDansRefTamis.limiteSuperieure = essaiNormeActu.limiteSuperieure;
                                    essaiDansRefTamis.etendueReference = essaiNormeActu.etendue;
                                    //essaiDansRefTamis.incertitudeReference = essaiNormeActu.incertitude;
                                    essaiDansRefTamis.categorieId = essaiNormeActu.categorieId;
                                    if (
                                        (essaiDansRefTamis.etendueReference == null || essaiDansRefTamis.etendueReference == undefined) &&
                                        essaiDansRefTamis.limiteInferieure != null &&
                                        essaiDansRefTamis.limiteInferieure != undefined
                                    ) {
                                        essaiDansRefTamis.borneInferieure = essaiDansRefTamis.limiteInferieure;
                                    }
                                    if (
                                        (essaiDansRefTamis.etendueReference == null || essaiDansRefTamis.etendueReference == undefined) &&
                                        essaiDansRefTamis.limiteSuperieure != null &&
                                        essaiDansRefTamis.limiteSuperieure != undefined
                                    ) {
                                        essaiDansRefTamis.borneSuperieure = essaiDansRefTamis.limiteSuperieure;
                                    }
                                }
                            } else {
                                essaiNormeActu.sousEssaiId = essaiNormeActu.idSousEssai;
                                essaiNormeActu.tamis = false;
                                essaiNormeActu.sousEssaiCodeLibelle = essaiNormeActu.code + ' / ' + essaiNormeActu.libelleEssai;
                                essaiNormeActu.libelleComplet =
                                    essaiNormeActu.essaiCodeLibelle +
                                    ' : ' +
                                    essaiNormeActu.sousEssaiCodeLibelle +
                                    ' : ' +
                                    essaiNormeActu.calculLibelle;

                                const essaiDansRefEssai = this._.find(this.referenceControle.mesures, function (elt) {
                                    return (
                                        elt.tamis == false && elt.essaiId == essaiNormeActu.idEssai && elt.sousEssaiId == essaiNormeActu.idSousEssai
                                    );
                                });
                                if (!essaiDansRefEssai) {
                                    this.selectMesure(essaiNormeActu, true);
                                } else {
                                    essaiDansRefEssai.limiteInferieure = essaiNormeActu.limiteInferieure;
                                    essaiDansRefEssai.limiteSuperieure = essaiNormeActu.limiteSuperieure;
                                    essaiDansRefEssai.etendueReference = essaiNormeActu.etendue;
                                    //essaiDansRefEssai.incertitudeReference = essaiNormeActu.incertitude;
                                    essaiDansRefEssai.categorieId = essaiNormeActu.categorieId;
                                    if (
                                        (essaiDansRefEssai.etendueReference == null || essaiDansRefEssai.etendueReference == undefined) &&
                                        essaiDansRefEssai.limiteInferieure != null &&
                                        essaiDansRefEssai.limiteInferieure != undefined
                                    ) {
                                        essaiDansRefEssai.borneInferieure = essaiDansRefEssai.limiteInferieure;
                                    }
                                    if (
                                        (essaiDansRefEssai.etendueReference == null || essaiDansRefEssai.etendueReference == undefined) &&
                                        essaiDansRefEssai.limiteSuperieure != null &&
                                        essaiDansRefEssai.limiteSuperieure != undefined
                                    ) {
                                        essaiDansRefEssai.borneSuperieure = essaiDansRefEssai.limiteSuperieure;
                                    }
                                }
                            }
                        }
                    }
                }
                const norme = this._.find(this.normeTrie, function (elt) {
                    return elt.id === resModal.idNorme;
                });
                this.referenceControle.idNorme = 0; //sinon désélectionne la norme
                await this.changeNorme(true, norme, this.normeTrie, true); //aller rechercher les incertitudes
            }
            this.trieEssais();
            if (typeof resModal.n === 'number') {
                this.referenceControle.nombreResultatsN = resModal.n;
            }
        }
    }

    async importFtp() {
        const _this = this;
        const modalInstance = this.$uibModal.open({
            template: '<import-ftp-modal modal-instance="$ctrl.uibModalInstance" selected-ftp="$ctrl.selectedFtp"></import-ftp-modal>',
            controller: [
                '$uibModalInstance',
                function ($uibModalInstance) {
                    const $ctrl = this;
                    $ctrl.uibModalInstance = $uibModalInstance;
                    $ctrl.selectedFtp = _this.selectedFtp;
                }
            ],
            controllerAs: '$ctrl',
            size: 'xxl',
            backdrop: false
        });
        const resModal = await modalInstance.result;
        // console.log('resModal:', resModal);
        if (resModal) {
            // On retire les mesures d'une précédente importation :
            this.resetReference();
            // Libelle
            this.referenceControle.libelle = resModal.libelle;
            // Producteur
            this.getSiteProducteurByIdFromFtp(resModal);
            // Produit
            this.getProduitByIdFromFtp(resModal);
            // Type
            await this.getTypeByProductIdFromFtp(resModal);
            // Norme
            await this.getNormeByIdFromFtp(resModal);
            // On vide les essais deja present dans
            this.essaisFtp = [];
            // Ajout des sous essais / tamis
            await this.getMesureFromFTP(resModal);
            // Remplissage des sous-essais
            this._.forEach(this.essaisFtp, (essaiFtp) => {
                this.selectMesure(essaiFtp);
            });
        }
    }

    getSiteProducteurByIdFromFtp({ idProducteur, libelleProducteur }) {
        this.sites = this.$filter('selectInIsteven')(this.sites, 'id', idProducteur, 'selected');
        this.referenceControle.sitesProducteursIds.push(idProducteur);
        this.referenceControle.sitesProducteursIds = this.referenceControle.sitesProducteursIds.distinctBy((x) => x.value);
        this.referenceControle.sitesProducteurs.push(libelleProducteur);
        this.referenceControle.sitesProducteurs = this.referenceControle.sitesProducteurs.distinctBy((x) => x.value);
    }

    getProduitByIdFromFtp({ idProduit, libelleProduit }) {
        this.produits = this.$filter('selectInIsteven')(this.produits, 'id', idProduit, 'selected');
        this.referenceControle.produitsIds[0] = idProduit;
        this.referenceControle.produits.push(libelleProduit);
    }

    async getTypeByProductIdFromFtp({ idProduit }) {
        const produit = await this.ProduitsService.getProduitEnteteById(idProduit);
        const type = await this.TypesService.getTypeById(produit.typeId);
        this.type = this.types.find((elem) => elem.idFamille === type.idFamille);
        await this.getTypesMesures(this.type);
    }

    async getNormeByIdFromFtp({ idNorme }) {
        const norme = this.normeTrie.find((elem) => elem.id === idNorme);
        await this.changeNorme(true, norme, this.normeTrie);
    }

    async getMesureFromFTP(resModal) {
        const essais = resModal.essais.map((x) => {
            x.valeurs = resModal.valeurs.filter((v) => v.idSsEss === x.idSsEss);
            return x;
        });

        this._.forEach(essais, (essai) => {
            this.getValeursEssai(essai);
        });
    }

    getValeursEssai(essai) {
        const newMesure = {};
        newMesure.code = essai.codeSsEss;
        newMesure.idEssai = essai.idEssai;
        newMesure.essaiId = essai.idEssai;

        // newMesure.code = essai.sousEssaiCode;
        // newMesure.idSousEssai = essai.idSsEss;
        // newMesure.codeEssai = essai.codeEssai;
        // newMesure.isSousEssai = true;
        // newMesure.arrondi = 2; //par défaut, mais pas réel...
        // newMesure.position = 0;
        // newMesure.isDimensionRemarquable = false;

        newMesure.calculId = this.calculStatDefaut.id;
        newMesure.calculLibelle = this.calculStatDefaut.libelle;
        newMesure.sousEssaiId = essai.idSsEss;
        newMesure.sousEssaiCode = essai.codeSsEss;
        newMesure.sousEssaiCodeLibelle = essai.libelleSsEss;
        newMesure.libelle = essai.libelleSsEss;
        newMesure.libelleComplet = `${essai.libelleEssai} (${newMesure.sousEssaiCode}) : ${essai.libelleSsEss}`;
        newMesure.isTamis = essai.isTamis;
        newMesure.tamis = false;
        newMesure.ouvertureTamis = null;
        newMesure.essaiCodeLibelle = essai.libelleSsEss;

        if (essai.isTamis) {
            newMesure.libelle = `${essai.libelleEssai} : ${essai.libelleSsEssTamis}`;
            newMesure.sousEssaiCode = essai.codeSsEssTamis;
            newMesure.libelleComplet = `${essai.libelleEssai} (${newMesure.sousEssaiCode}) : ${essai.libelleSsEssTamis}`;
            newMesure.tamis = true;
            newMesure.sousEssaiId = essai.idSsEssTamis;
            newMesure.ouvertureTamis = Number.parseFloat(essai.libelleSsEssTamis.replace(',', '.'));
            newMesure.sousEssaiCodeLibelle = essai.libelleSsEssTamis.toString();
            newMesure.essaiCodeLibelle = `${essai.libelleEssai} (${newMesure.sousEssaiCode})`;
        }
        // recuperation des données min, max et moyenne
        newMesure.borneInferieure = essai.valeurs.find((x) => x.formuleCalcStat === '@VSI' && x.idSsEssTamis === essai.idSsEssTamis)?.valeur;
        newMesure.borneSuperieure = essai.valeurs.find((x) => x.formuleCalcStat === '@VSS' && x.idSsEssTamis === essai.idSsEssTamis)?.valeur;
        newMesure.moyenne = essai.valeurs.find((x) => x.formuleCalcStat === '@MOY' && x.idSsEssTamis === essai.idSsEssTamis)?.valeur;

        this.essaisFtp.push(newMesure);
    }
}
