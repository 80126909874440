(function (angular, undefined) {
    'use strict';

    angular.module('blocks.treeview')
        .run(run);
    run.$inject = ['$templateCache'];

    function run($templateCache) {

        $templateCache.put('blocks/treeview/ac.treeview.folder.tpl.html', '' +
            '<div class="tree-folder">' +
            '   <div ac-droppable="tree.dropNode"' +
            '        ac-droppable-target="folder"' +
            '        ac-droppable-mouseover="tree.openNode(folder)"' +
            '        ac-droppable-disabled="tree.dropDisabled"' +
            '        ac-can-drop="tree.canDrop">' +
            '      <div ng-class="{\'node-selected\': tree.isSelected(folder), \'item-inactive\': folder.active == false}"' +
            '           ng-mousedown="tree.select(folder)"' +
            '           ng-hide="tree.isLoading[parentFolder.type + \'.\' + parentFolder.id]"' +
            '           ng-init="folder.expanded = folder.expanded || (tree.config.autoExpand === true && folder.items.length > 0)"' +
            '           ac-context-menu="tree.disableDrag()"' +
            '           ac-context-menu-disabled="tree.isContextMenuDisabled(folder.type) || tree.renamedItemId == folder.type + \'.\' + folder.id"' +
            '           ac-context-menu-close="tree.enableDrag()"' +
            '           data-target="{{ ::tree.config.name }}-node-menu"' +
            '           class="drag-drop noselect {{tree.getStyle(folder)}}">' +
            '' +
            '          <div class="node-name droppable min-width-250"' +
            '               ng-dblclick="tree.toggle(folder)" ' +
            '               ng-class="folder.type">' +
            '' +
            '              <!-- Ouvre/ferme un noeud-->' +
            '              <a class="btn btn-link node-folder-icon padding-0" ng-click="tree.toggle(folder)">' +
            '                   <span class="glyphicon" ng-class="tree.getNodeIcon(folder)"></span>' +
            '              </a>' +
            '' +
            '              <span>' +
            '                   <!-- Icon en fonction du type du noeud-->' +
            '                   <a class="btn btn-link padding-0 node-icon"' +
            '                       ng-if="folder.type == tree.config.rootType || folder.icon">' +
            '                       <span ng-if="::(folder.type == tree.config.rootType)" class="node-root-icon glyphicon glyphicon-{{ ::tree.config.rootTypeIcon }}"></span>' +
            '                       <span ng-if="::!(folder.type == tree.config.rootType)" class="node-other-icon glyphicon glyphicon-{{ ::folder.icon }}"></span>' +
            '                   </a>' +
            '' +
            '                   <!-- Nom du Type (si il existe) -->' +
            '                   <span class="node-type-label margin-top-3" ' +
            '                         ng-show="::folder.typeLabel" ' +
            '                         ng-bind="::folder.typeLabel + \' : \'"></span>' +
            '' +
            '                   <!-- Nom du noeud -->' +
            '                   <span class="node-label margin-top-3" ' +
            '                        ng-hide="tree.renamedItemId == folder.type + \'.\' + folder.id"' +
            '                        title="{{ folder.label }}"' +
            '                        ng-bind="folder.label"></span>' +
            '              </span>' +
            '' +
            '              <div class="node-form display-inline-block"' +
            '                   ng-if="tree.renamedItemId == folder.type + \'.\' + folder.id"' +
            '                   ng-click="$event.stopPropagation();"' +
            '                   ng-mousedown="$event.stopPropagation();"' +
            '                   ng-dblclick="$event.stopPropagation();">' +
            '                  <form class="form-inline" novalidate>' +
            '                      <div class="display-inline-block margin-right-5" style="width:70%;">' +
            '                          <input ac-autofocus="select" ' +
            '                                 type="text" ' +
            '                                 class="input-xs form-control font-size-095" ' +
            '                                 ng-model="folder.editLabel" ' +
            '                                 style="height:25px;line-height:0;" ' +
            '                                 required />' +
            '                      </div>' +
            '                      <button type="submit" ' +
            '                              class="btn btn-xs btn-default margin-0-5"' +
            '                              ng-click="tree.confirmRename(folder)">' +
            '                          <span class="glyphicon glyphicon-ok"></span>' +
            '                      </button>' +
            '                      <button type="button" ' +
            '                              class="btn btn-xs btn-default" ' +
            '                              ng-click="tree.cancelRename(folder)">' +
            '                          <span class="glyphicon glyphicon-ban-circle"></span>' +
            '                      </button>' +
            '                  </form>' +
            '             </div>' +
            '          </div>' +
            '      </div>' +
            '   </div>' +
            '' +
            '   <!-- Gestion des sub-nodes -->' +
            '   <ol ng-if="folder.expanded" class="list-style-type-none">' +
            '       <li ng-show="tree.isLoading[folder.type + \'.\' + folder.id]">' +
            '           <div class="ac-loading-wrapper-left">' +
            '               <div ac-loader></div> ' +
            '           </div>' +
            '       </li>' +
            '       <li ng-repeat="child in filtered = (tree.config.orderByFunction ? (folder.items | orderBy:tree.config.orderByFunction) : folder.items)"' +
            '           ac-draggable="child"' +
            '           ac-draggable-parent="folder"' +
            '           ac-draggable-disabled="tree.dragDropDisabled(child)"' +
            '           ng-show="tree.displayNode(child)"' +
            '           id="{{::child.type + \'.\' + child.id}}"' +
            '           ac-treeview-node>' +
            '       </li>' +
            '   </ol>' +
            '</div>' +
            '');
    }
})(angular);