const serviceUrl = `${__configuration.apiUrl}/massia/gestion-stocks`;

export default class StocksService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getGestionStocks(idSite, dateDebut, dateFin, elt1, elt2) {
        const filtres = {
            idSite: idSite,
            dateDebut: dateDebut,
            dateFin: dateFin,
            eltNiv1: elt1,
            eltNiv2: elt2
        };
        const res = await this.$http.get(serviceUrl, {
            params: {
                filtres: JSON.stringify(filtres || {})
            }
        });

        return res.data;
    }

    async printGestionStock(idSite, dateDebut, dateFin, elt1, elt2) {
        const url = `${serviceUrl}/print`;
        const filtres = {
            idSite: idSite,
            dateDebut: dateDebut,
            dateFin: dateFin,
            eltNiv1: elt1,
            eltNiv2: elt2
        };
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {})
            },
            responseType: 'arraybuffer'
        });
        return res;
    }

    async getValorisationStocks(idSite, idPeriode, elt1, elt2, elt3, typeAnalyse, annee) {
        const filtres = {
            idSite: idSite,
            idCloture: idPeriode,
            eltNiv1: elt1,
            eltNiv2: elt2,
            eltNiv3: elt3,
            typeAnalyse: typeAnalyse ? typeAnalyse : 0,
            annee: annee
        };
        const valorisationUrl = `${__configuration.apiUrl}/massia/valorisation-stocks`;
        const res = await this.$http.get(valorisationUrl, {
            params: {
                filtres: JSON.stringify(filtres || {})
            }
        });

        return res.data;
    }

    async printValorisationStock(idSite, idPeriode, elt1, elt2, elt3, typeAnalyse, annee) {
        const url = `${__configuration.apiUrl}/massia/valorisation-stocks/print`;
        const filtres = {
            idSite: idSite,
            idCloture: idPeriode,
            eltNiv1: elt1,
            eltNiv2: elt2,
            eltNiv3: elt3,
            typeAnalyse: typeAnalyse ? typeAnalyse : 0,
            annee: annee
        };
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {})
            },
            responseType: 'arraybuffer'
        });
        return res;
    }

    async setGestionStock(gestionStock) {
        const result = await this.$http.post(serviceUrl, gestionStock);
        return result.data;
    }

    async getSites() {
        const url = `${__configuration.apiUrl}/massia/sites-commerciaux/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSitesCommerciaux(valeur) {
        const url = `${__configuration.apiUrl}/massia/sites-commerciaux/typeAhead/${valeur}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getPeriodes(annee, idSite) {
        const url = `${__configuration.apiUrl}/massia/valorisation-stocks/periodes/${annee}/${idSite}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getElementsNiv1() {
        const url = `${__configuration.apiUrl}/massia/domaines/produitsstockes/elements-hierarchiques/stock1`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getElementsNiv2(codeElt1) {
        const url = `${__configuration.apiUrl}/massia/domaines/produitsstockes/elements-hierarchiques/stock2/${codeElt1}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getElementsNiv3(codeElt1, codeElt2) {
        const url = `${__configuration.apiUrl}/massia/domaines/produitsstockes/elements-hierarchiques/stock3/${codeElt1}/${codeElt2}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getMotifs() {
        const url = `${__configuration.apiUrl}/massia/motifs-ajustement-stock/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getMotifsDepreciation() {
        const url = `${__configuration.apiUrl}/massia/motifs-depreciation-stock/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getAllProduits(idSite) {
        const url = `${__configuration.apiUrl}/massia/produits/produitsProducteur/${idSite}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getProduitLies(idSite) {
        try {
            const filters = {
                siteCommercialId: idSite,
                entreeSortie: null
            };
            const url = `${__configuration.apiUrl}/massia/parametres/produits/lies`;

            const res = await this.$http.get(url, {
                params: {
                    filters: filters || {}
                }
            });

            return res.data.produits;
        } catch (err) {
            throw err.data;
        }
    }

    async getZonesStockage(idSiteComm) {
        const url = `${__configuration.apiUrl}/massia/origines-destinations/header/${idSiteComm}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getInventaires(filters, sorts, pagination) {
        const url = `${__configuration.apiUrl}/massia/inventaire-stocks`;
        const res = await this.$http.get(url, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getInventaireById(id) {
        const url = `${__configuration.apiUrl}/massia/inventaire-stocks/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getInventaireStockProduits(idSiteCommercial, eltNiv1, idInventaire, date) {
        const url = `${__configuration.apiUrl}/massia/inventaire-stocks/listeProduits`;

        const filtres = {
            idSiteCommercial: idSiteCommercial,
            eltNiv1: eltNiv1,
            idInventaire: idInventaire,
            date: date
        };
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {})
            }
        });

        return res.data;
    }

    async printInventaireStock(idSiteCommercial, eltNiv1, idInventaire, date) {
        const url = `${__configuration.apiUrl}/massia/inventaire-stocks/print`;
        const filtres = {
            idSiteCommercial: idSiteCommercial,
            eltNiv1: eltNiv1,
            idInventaire: idInventaire,
            date: date
        };
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {})
            },
            responseType: 'arraybuffer'
        });
        return res;
    }

    async createInventaire(inventaire) {
        const url = `${__configuration.apiUrl}/massia/inventaire-stocks`;
        const result = await this.$http.post(url, inventaire);
        return result.data;
    }

    async updateInventaire(inventaire) {
        const url = `${__configuration.apiUrl}/massia/inventaire-stocks`;
        const result = await this.$http.put(url, inventaire);
        return result.data;
    }

    async createMouvement(inventaire) {
        const url = `${__configuration.apiUrl}/massia/inventaire-stocks/mouvements`;
        const result = await this.$http.post(url, inventaire);
        return result.data;
    }

    async sauveDepreciation(estDepreciationQuantite, idSite, idPeriode, idProduit, idProducteur, idEmplacement, idMotif, quantite, prix) {
        const depreciation = {
            idSiteCommercial: idSite,
            idCloture: idPeriode,
            idProduit: idProduit,
            idSiteProducteur: idProducteur,
            idEmplacement: idEmplacement,
            idMotifDepreciation: idMotif,
            quantiteDepreciee: quantite,
            prixDeprecie: prix,
            estDepreciationQuantite: estDepreciationQuantite
        };
        const url = `${__configuration.apiUrl}/massia/valorisation-stocks`;
        const result = await this.$http.post(url, depreciation);
        return result.data;
    }

    async verifieDatesGestionStock(idSite, dateDebut, dateFin) {
        const filtres = {
            idSite: idSite,
            dateDebut: dateDebut,
            dateFin: dateFin
        };
        const url = `${serviceUrl}/verifie-dates`;
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {})
            }
        });

        return res.data;
    }

    async deleteInventaireById(id) {
        const url = `${__configuration.apiUrl}/massia/inventaire-stocks/${id}`;
        return this.$http.delete(url);
    }
}
