(function (angular, undefined) {
    'use strict';

    angular.module('blocks.smart.table')
        .run(run);
    run.$inject = ['$templateCache', 'globalizationManagementService'];

    function run($templateCache, globalizationManagementService) {

        const dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;

        $templateCache.put('blocks/smart-table/filter.html',
            '	<div class="btn-group vertical-align-top st-filter-btn" uib-dropdown dropdown-append-to-body is-open="filter.isOpen" ng-click="$event.stopPropagation();"> ' +
            '		<a type="button" ' +
            '				class="btn btn-xs btn-link btn-filter uib-dropdown-toggle padding-0 margin-0" ' +
            '				ng-class="{\'filter-active\': filtering }" ' +
            '               uib-dropdown-toggle>' +
            '			<span style="font-size:1.5em" class="glyphicon glyphicon-filter"></span> ' +
            '		</a>' +
            '		<form ng-submit="filter()" class="dropdown-menu padding-5 bg-filter" uib-dropdown-menu ng-click="$event.stopPropagation();" style="width:200px;z-index:1050;"> ' +
            '			<label class="col-xs-10 margin-0 padding-5 font-size-090"> ' +
            '				<span translate="LABEL_FILTER_CONTAINS"></span> ' +
            '				<span class="text-muted font-style-italic">{{ currentFilter }}</span> ' +
            '			</label> ' +
            '			<div class="col-xs-12 margin-0 padding-5"> ' +
            '				<input ng-model="predicate.txt" ' +
            '                      class="input-sm form-control" ' +
            '                      ac-autofocus="filter.isOpen"' +
            '                      type="search"/> ' +
            '			</div>' +
            '			<div class="col-xs-12 margin-0 padding-5 text-right">' +
            '		        <button type="submit" class="btn btn-xs padding-3-10 btn-default" translate="LABEL_APPLY_FILTER"></button> ' +
            '		        <button type="button" ng-show="filtering" class="btn btn-xs padding-3-10 btn-erase" ng-click="eraseFilter()" translate="LABEL_ERASE_FILTER"></button> ' +
            '		    </div> ' +
            '		</form> ' +
            '	</div> ' +
            '   <ng-transclude></ng-transclude> ' +
            '');

        $templateCache.put('blocks/smart-table/filter-combobox.html',
            '	<div class="btn-group vertical-align-top st-filter-btn" uib-dropdown dropdown-append-to-body is-open="filter.isOpen" ng-click="$event.stopPropagation();"> ' +
            '		<a type="button" ' +
            '				class="btn btn-xs btn-link btn-filter uib-dropdown-toggle padding-0 margin-0" ' +
            '				ng-class="{\'filter-active\': filtering }" ' +
            '               uib-dropdown-toggle>' +
            '			<span style="font-size:1.5em" class="glyphicon glyphicon-filter"></span> ' +
            '		</a>' +
            '		<form ng-submit="filter()" class="dropdown-menu padding-5 bg-filter" uib-dropdown-menu ng-click="$event.stopPropagation();" style="width:200px;z-index:1050;"> ' +
            '			<label class="col-xs-10 margin-0 padding-5 font-size-090"> ' +
            '				<span translate="LABEL_FILTER_EQUALS"></span> ' +
            '				<span class="text-muted font-style-italic">{{ currentFilter }}</span> ' +
            '			</label> ' +
            '			<div class="col-xs-12 margin-0 padding-5"> ' +
            '				<select class="input-sm form-control" ' +
            '                      ng-model="predicate.txt" ' +
            '                      ac-autofocus="filter.isOpen"> ' +
            '				    <option value="" disabled> -- </option> ' +
            '				    <option ng-repeat="item in stFilterItems" value="{{ ::item[stFilterCustomId] }}">{{ ::item[stFilterCustomLabel] }}</option> ' +
            '				</select> ' +
            '			</div>' +
            '			<div class="col-xs-12 margin-0 padding-5 text-right">' +
            '		        <button type="submit" class="btn btn-xs padding-3-10 btn-default" translate="LABEL_APPLY_FILTER"></button> ' +
            '		        <button type="button" ng-show="filtering" class="btn btn-xs padding-3-10 btn-erase" ng-click="eraseFilter()" translate="LABEL_ERASE_FILTER"></button> ' +
            '		    </div> ' +
            '		</form> ' +
            '	</div> ' +
            '   <ng-transclude></ng-transclude> ' +
            '');


        $templateCache.put('blocks/smart-table/filter-combobox-multiple.html',
            '	<div class="btn-group vertical-align-top st-filter-btn" uib-dropdown dropdown-append-to-body is-open="filter.isOpen" ng-click="$event.stopPropagation();"> ' +
            '		<a type="button" ' +
            '				class="btn btn-xs btn-link btn-filter uib-dropdown-toggle padding-0 margin-0" ' +
            '				ng-class="{\'filter-active\': filtering }" ' +
            '               uib-dropdown-toggle>' +
            '			<span style="font-size:1.5em" class="glyphicon glyphicon-filter"></span> ' +
            '		</a>' +
            '		<form ng-submit="filter()" class="dropdown-menu padding-5 bg-filter" uib-dropdown-menu ng-click="$event.stopPropagation();" style="width:200px;z-index:1050;"> ' +
            '			<label class="col-xs-10 margin-0 padding-5 font-size-090"> ' +
            '				<span translate="LABEL_FILTER_IN"></span> ' +
            '				<span class="text-muted font-style-italic">{{ currentFilter }}</span> ' +
            '			</label> ' +
            '			<div class="col-xs-12 margin-0 padding-5"> ' +
            '               <div isteven-multi-select' +
            '                    input-model="stFilterItems" ' +
            '                    output-model="selectedItems" ' +
            '                    button-label="{{ ::stFilterCustomLabel }}"' +
            '                    item-label="{{ ::stFilterCustomLabel }}" ' +
            '                    max-labels="1"' +
            '                    max-height="200px" ' +
            '                    tick-property="ticked" ' +
            '                    group-property="isGroup"' +
            '                    helper-elements="all none filter close"' +
            '                    remove-dropdown-from-dom="true"' +
            '                    output-properties="{{ ::stFilterCustomId }}"> ' +
            '               </div> ' +
            '			</div>' +
            '			<div class="col-xs-12 margin-0 padding-5 text-right">' +
            '		        <button type="submit" class="btn btn-xs padding-3-10 btn-default" translate="LABEL_APPLY_FILTER"></button> ' +
            '		        <button type="button" ng-show="filtering" class="btn btn-xs padding-3-10 btn-erase" ng-click="eraseFilter()" translate="LABEL_ERASE_FILTER"></button> ' +
            '		    </div> ' +
            '		</form> ' +
            '	</div> ' +
            '   <ng-transclude></ng-transclude> ' +
            '');

        $templateCache.put('blocks/smart-table/filter-numeric.html',
            '	<div class="btn-group vertical-align-top st-filter-btn" uib-dropdown dropdown-append-to-body is-open="filter.isOpen" ng-click="$event.stopPropagation();"> ' +
            '		<a type="button" ' +
            '				class="btn btn-xs btn-link btn-filter uib-dropdown-toggle padding-0 margin-0" ' +
            '				ng-class="{\'filter-active\': filtering }" ' +
            '               uib-dropdown-toggle>' +
            '			<span style="font-size:1.5em" class="glyphicon glyphicon-filter"></span> ' +
            '		</a>' +
            '		<form ng-submit="filter()" class="dropdown-menu padding-5 bg-filter" uib-dropdown-menu ng-click="$event.stopPropagation();" style="width:200px;z-index:1050;"> ' +
            '			<label class="col-xs-10 margin-0 padding-5 font-size-090"> ' +
            '				<span translate="LABEL_FILTER_MIN"></span> ' +
            '				<span class="text-muted font-style-italic">{{ currentFilter.min }}</span> ' +
            '			</label> ' +
            '			<div class="col-xs-12 margin-0 padding-5"> ' +
            '				<input ng-model="predicate.min" ' +
            '                      class="input-sm form-control" ' +
            '                      ac-autofocus="filter.isOpen"' +
            '                      type="number" step="any"/> ' +
            '			</div>' +
            '			<label class="col-xs-10 margin-0 padding-5 font-size-090"> ' +
            '				<span translate="LABEL_FILTER_MAX"></span> ' +
            '				<span class="text-muted font-style-italic">{{ currentFilter.max }}</span> ' +
            '			</label> ' +
            '			<div class="col-xs-12 margin-0 padding-5"> ' +
            '				<input ng-model="predicate.max" ' +
            '                      class="input-sm form-control" ' +
            '                      type="number" step="any"/> ' +
            '			</div>' +
            '			<div class="col-xs-12 margin-0 padding-5 text-right">' +
            '		        <button type="submit" class="btn btn-xs padding-3-10 btn-default" translate="LABEL_APPLY_FILTER"></button> ' +
            '		        <button type="button" ng-show="filtering" class="btn btn-xs padding-3-10 btn-erase" ng-click="eraseFilter()" translate="LABEL_ERASE_FILTER"></button> ' +
            '		    </div> ' +
            '		</form> ' +
            '	</div> ' +
            '   <ng-transclude></ng-transclude> ' +
            '');

        $templateCache.put('blocks/smart-table/filter-date.html',
            '	<div class="btn-group vertical-align-top st-filter-btn" uib-dropdown dropdown-append-to-body is-open="filter.isOpen" ng-click="$event.stopPropagation();"> ' +
            '		<a type="button" ' +
            '				class="btn btn-xs btn-link btn-filter uib-dropdown-toggle padding-0 margin-0" ' +
            '				ng-class="{\'filter-active\': filtering }" ' +
            '               uib-dropdown-toggle>' +
            '			<span style="font-size:1.5em" class="glyphicon glyphicon-filter"></span> ' +
            '		</a>' +
            '		<form ng-submit="filter()" class="dropdown-menu padding-5 bg-filter" uib-dropdown-menu ng-click="$event.stopPropagation();" style="width:200px;z-index:1050;"> ' +
            '			<label class="col-xs-10 margin-0 padding-5 font-size-090"> ' +
            '				<span translate="LABEL_FILTER_FROM_DATE"></span> ' +
            '				<span class="text-muted font-style-italic">{{ currentFilter.dateMin.format(\''+dateFormat+'\') }}</span> ' +
            '			</label> ' +
            '			<div class="col-xs-12 margin-0 padding-5"> ' +
            '				<div ac-datepicker ' +
            '                    ng-model="predicate.dateMin" ' +
            '                    ac-autofocus="filter.isOpen"' +
            '                    ac-validation-disabled="true"> ' +
            '			    </div>' +
            '			</div>' +
            '			<label class="col-xs-10 margin-0 padding-5 font-size-090"> ' +
            '				<span translate="LABEL_FILTER_TO_DATE"></span> ' +
            '				<span class="text-muted font-style-italic">{{ currentFilter.dateMax.format(\''+dateFormat+'\') }}</span> ' +
            '			</label> ' +
            '			<div class="col-xs-12 margin-0 padding-5"> ' +
            '				<div ac-datepicker ' +
            '                    ng-model="predicate.dateMax"' +
            '                    ac-validation-disabled="true"> ' +
            '			    </div>' +
            '			</div>' +
            '			<div class="col-xs-12 margin-0 padding-5 text-right">' +
            '		        <button type="submit" class="btn btn-xs padding-3-10 btn-default" translate="LABEL_APPLY_FILTER"></button> ' +
            '		        <button type="button" ng-show="filtering" class="btn btn-xs padding-3-10 btn-erase" ng-click="eraseFilter()" translate="LABEL_ERASE_FILTER"></button> ' +
            '		    </div> ' +
            '		</form> ' +
            '	</div> ' +
            '   <ng-transclude></ng-transclude> ' +
            '');


        $templateCache.put('blocks/smart-table/filter-bool.html',
            '	<div class="btn-group vertical-align-top st-filter-btn" uib-dropdown dropdown-append-to-body is-open="filter.isOpen" ng-click="$event.stopPropagation();"> ' +
            '		<a type="button" ' +
            '				class="btn btn-xs btn-link btn-filter uib-dropdown-toggle padding-0 margin-0" ' +
            '				ng-class="{\'filter-active\': filtering }" ' +
            '               uib-dropdown-toggle>' +
            '			<span style="font-size:1.5em" class="glyphicon glyphicon-filter"></span> ' +
            '		</a> ' +
            '		<form ng-submit="filter()" class="dropdown-menu padding-10 bg-filter" uib-dropdown-menu ng-click="$event.stopPropagation();" style="width:200px;z-index:1050;"> ' +
            '			<label class="font-size-090"> ' +
            '				<span translate="LABEL_FILTER_EQUALS"></span> ' +
            '				<span class="text-muted font-style-italic">{{ currentFilter }}</span> ' +
            '			</label> ' +
            '			<div> ' +
            '               <span ac-checkbox="predicate.yes"' +
            '                     ac-checkbox-label="{{ stFilterCustomLabelYes }}"></span>' +
            '               <br>' +
            '               <span ac-checkbox="predicate.no"' +
            '                     ac-checkbox-label="{{ stFilterCustomLabelNo }}"></span>' +
            '			</div>' +
            '			<div class="margin-top-10 text-right">' +
            '		        <button type="submit" class="btn btn-xs padding-3-10 btn-default" translate="LABEL_APPLY_FILTER"></button> ' +
            '		        <button type="button" ng-show="filtering" class="btn btn-xs padding-3-10 btn-erase" ng-click="eraseFilter()" translate="LABEL_ERASE_FILTER"></button> ' +
            '		    </div> ' +
            '		</form> ' +
            '	</div> ' +
            '   <ng-transclude></ng-transclude> ' +
            '');
    }

})(angular);