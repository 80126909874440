//Config
import TramesSaisiesConfig from './_config/trames.saisies.config';
import RefSaisieEnvRoutes from './_config/refsaisieenv.routes';
import RefSaisieCtrlRoutes from './_config/refsaisiectrl.routes';
import RefSaisieSecuRoutes from './_config/refsaisiesecu.routes';
import RefSaisieRoutes from './_config/refsaisie.routes';

//Components
import TramesSaisiesComponent from './components/trames-saisies';
import TrameSaisieDetailComponent from './components/trame-saisie-detail';
import TrameSaisieFormComponent from './components/trame-saisie-form';

//Services
import TramesSaisiesService from './services/trames.saisies.service';
import TramesSaisiesTypesService from './services/trames.saisies.types.service';
import TramesSaisiesCommunicationService from './services/trames.saisies.communication.service';

//Validateurs
import TrameSaisieValidator from './components/trame-saisie-form/trame.saisie.validator';

const moduleName = 'app.massia.common.trames.saisies';

angular
    .module(moduleName, [])
    .config(TramesSaisiesConfig)
    .config(RefSaisieEnvRoutes)
    .config(RefSaisieCtrlRoutes)
    .config(RefSaisieSecuRoutes)
    .config(RefSaisieRoutes)
    .service('TramesSaisiesService', TramesSaisiesService)
    .service('TramesSaisiesTypesService', TramesSaisiesTypesService)
    .service('TramesSaisiesCommunicationService', TramesSaisiesCommunicationService)
    .component('tramesSaisies', TramesSaisiesComponent)
    .component('trameSaisieDetail', TrameSaisieDetailComponent)
    .component('trameSaisieForm', TrameSaisieFormComponent)
    .factory('TrameSaisieValidator', TrameSaisieValidator);

export default moduleName;
