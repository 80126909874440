import DomainesEditController from './domaines.edit.controller';

export default {
    template: require('./domaines.edit.tpl.html'),
    controller: DomainesEditController,
    bindings: {
        domaines: '<',
        onDelete: '&',
        onUpdate: '&',
        displayDroits: '<'
    }
};
