(function (angular, undefined) {
    'use strict';

    angular.module('blocks.treeview')
        .run(run);
    run.$inject = ['$templateCache'];

    function run($templateCache) {

        $templateCache.put('ac.treeview.readme.tpl.html', '' +
            '<div class="alert-info padding-10">' +
            '   <span translate="{{ tree.readme.msg }}"></span>' +
            '   <br/>' +
            '   <br/>' +
            '   <span>usage:</span>' +
            '   <pre>{{ tree.readme.usage }}</pre>' +
            '   <span>config:</span>' +
            '   <pre>{{ tree.readme.help | json }}</pre>' +
            '   <span>service:</span>' +
            '   <pre>{{ tree.readme.service | json }}</pre>' +
            '</div>' +
            '');
    }
})(angular);