(function (angular, undefined) {
    'use strict';

    angular
        .module('blocks.smart.table')
        .directive('acDynamicColumnsBody', acDynamicColumnsBody);

    acDynamicColumnsBody.$inject = ['$compile', '$parse', '$log'];

    /* @ngInject */
    function acDynamicColumnsBody($compile, $parse, $log) {
        var directive = {
            link: link,
            restrict: 'A'
        };
        return directive;

        function link(scope, element, attrs) {
            var parsedAttributes, generatedTemplate, compiledElement;

            // on récupère la configuration de la directive
            parsedAttributes = $parse(attrs.acDynamicColumnsBody)(scope);
            // en cas de problème on arrête tout et on prévient le dev
            if (!parsedAttributes || (parsedAttributes && (!parsedAttributes.row || !parsedAttributes.column))) {
                $log.warn('acDynamicColumnsBody: row or column definition missing');
                return;
            }
            // on publie les propriétés nécessaires à la compilation dans le scope
            scope.row = parsedAttributes.row;
            scope.column = parsedAttributes.column;

            //on gère ici les classes css
            var itemClass = parsedAttributes.class;
            var cssClass = 'ac-dynamic-column-body text-ellipsis position-relative';
            if(itemClass){
                cssClass += ' ' + itemClass;
            }

            // on créé le template dynamiquement
            generatedTemplate = '' +
                '<td class="' + cssClass + '" ' +
                '	 uib-tooltip-html="::(row[column.tooltip] | abstractFilter:column.tooltipFilter:column.tooltipFilterParameter) || (row[column.property] | abstractFilter:column.propertyFilter:column.propertyFilterParameter)" ' +
                '	 tooltip-append-to-body="true" ' +
                '	 tooltip-placement="bottom" ' +
                '	 tooltip-enable="{{::(row[column.tooltip] !== undefined || (row[column.property] | abstractFilter:column.propertyFilter:column.propertyFilterParameter).length >= 12) }}"> ' +
                '   <span ng-bind-html="::(row[column.property] | abstractFilter:column.propertyFilter:column.propertyFilterParameter)"></span> ' +
                '</td>' +
                '';

            // on compile le template pour en faire un élément angular
            compiledElement = $compile(generatedTemplate)(scope);

            // on remplace l'élément courant par l'élément compilé
            element.replaceWith(compiledElement);
        }
    }
})(angular);