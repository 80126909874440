const serviceUrl = `${__configuration.apiUrl}/massia/gestion/rfa`;

export default class RemisesFinAnneeService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getRemisesFinAnnee(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });
        return res.data;
    }

    async getRemiseFinAnneeById(idRemiseFinAnnee) {
        const url = `${serviceUrl}/${idRemiseFinAnnee}`;

        const res = await this.$http.get(url);
        return res.data;
    }

    async deleteRemiseFinAnneeById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async createRemiseFinAnnee(remiseFinAnnee) {
        const result = await this.$http.post(serviceUrl, remiseFinAnnee);
        return result.data;
    }

    async updateRemiseFinAnnee(remiseFinAnnee) {
        const url = `${serviceUrl}/${remiseFinAnnee.id}`;
        return this.$http.put(url, remiseFinAnnee);
    }

    //remiseFinAnneeId optionnel, passer null si c'est une creation de rfa
    //sinon l'id de la rfa qui est en train d'être editée
    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getSocietes() {
        const siteUrl = `${__configuration.apiUrl}/massia/societes-commerciales`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async getSitesCommerciaux(idSociete) {
        const url = `${__configuration.apiUrl}/massia/sites-commerciaux/header/${idSociete}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSocieteClients() {
        const url = `${__configuration.apiUrl}/massia/societes/header-genre`;
        const res = await this.$http.get(url, {
            params: {
                isCommercial: false,
                isFournisseur: false,
                isClient: true,
                isTransporteur: false,
                isProducteur: false,
                isLaboratoire: false,
                isProprietaire: false,
                isLocataire: false,
                isAuditeur: false
            }
        });
        return res.data;
    }

    async getSitesClientsBySociete(idSociete) {
        const valeur = undefined;
        const url = `${__configuration.apiUrl}/massia/sites-clients/typeAhead/${idSociete}/${valeur}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSitesClients(idSociete) {
        const url = `${__configuration.apiUrl}/massia/sites-clients/header/${idSociete}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getProduits(idSociete, idSiteCommercial) {
        let monFiltre = '';
        if (idSiteCommercial) {
            monFiltre = JSON.stringify({ societeId: idSociete, siteCommercialId: idSiteCommercial });
        }
        // else
        //     monFiltre=JSON.stringify({ societeId: idSociete });
        const url = `${__configuration.apiUrl}/massia/produits/producteursProduits?filters=${monFiltre}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getChantiers(idSociete, listeIdClients) {
        const res = await this.$http.post(`${__configuration.apiUrl}/massia/chantiers/headerchantierRFA/${idSociete}`, listeIdClients);
        return res.data;
    }

    async genererFacturesRFA(rfa) {
        const url = `${__configuration.apiUrl}/massia/gestion/rfa-generation`;
        const result = await this.$http.post(url, rfa);
        return result.data;
    }

    async getModes() {
        const url = 'assets/enum/remise-fin-annee/modeRemise.json';
        const res = await this.$http.get(url);
        return res.data;
    }
}
