import MesureFormEnteteController from './mesure.form.entete.controller';

export default {
    bindings: {
        mesure: '<',
        onUpdate: '&',
        redirection: '<'
    },
    template: require('./mesure.form.entete.html'),
    controller: MesureFormEnteteController
};
