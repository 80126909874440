import MotifsConfig from './_config/motifs.config';
import MotifsRoutes from './_config/motifs.routes';
import MotifsComponent from './components/motifs';
import MotifsService from './services/motifs.service';
import MotifsCommunicationService from './services/motifs.communication.service';
import MotifsFormComponent from './components/motif-form';
import MotifValidator from './components/motif-form/motif.validator';

const moduleName = 'app.massia.common.motifs';

angular
    .module(moduleName, [])
    .config(MotifsConfig)
    .config(MotifsRoutes)
    .service('MotifsService', MotifsService)
    .service('MotifsCommunicationService', MotifsCommunicationService)
    .component('motifs', MotifsComponent)
    .component('motifsForm', MotifsFormComponent)
    .factory('MotifValidator', MotifValidator);

export default moduleName;
