import ControleResultatController from './controle.resultat.controller';

export default {
    bindings: {
        idControle: '=',
        isOpen: '='
    },
    template: require('./controle.resultat.html'),
    controller: ControleResultatController
};
