import { Societe } from './../../../../../core/models/societe/Societe';
import Produit from './produit.model';
import ProduitEntete from './entete/produit.entete.model';
import { RouterHistoryService } from '../../../../../libs/massia-component/massia-service/router.history.service';
import { ProductService } from '../../../../../core/http/product.service';
import { ProduitDetail, ProduitDetailLabo } from '../../../../../core/models/produit/ProduitDetail';
import { ValidatorHelper } from '../../../../../core/helpers/validator-helper';
import { Site } from '../../../../../core/models/site/Site';
import { CreateUpdateProduit, PrixProduit } from '../../../../../core/models/produit/UpdateProduit';
import * as angular from 'angular';
import MOPService from '../../../../services/mop.service';

export default class ProduitFormController {
	$scope: ng.IScope;
	$timeout: ng.ITimeoutService;
	$state: any;
	$stateParams: any;
	ProduitCommunicationService: any;
	ProduitsService: any;
	notification: any;
	$location: ng.ILocationService;
	$anchorScroll: ng.IAnchorScrollService;
	MassiaApplicationService: any;
	$uibModal: ng.ui.bootstrap.IModalService;
	RouterHistoryService: RouterHistoryService;
	FormuleService: any;
	FilterHelperService: any;
	SitesService: any;
	productSvc: ProductService;
	produit: CreateUpdateProduit;
	_: any;
	isEditMode: boolean;
	certifications: any;

	app: string;
	ongletOpen: any = {
		isEnteteOpen: true,
		isProducteursOpen: true
	};
	ongletDisabled: any;
	loading: boolean = false;
	hasEnregistrements: boolean = false;
	filtreNomenclatures: any[] = [];

    watcher: Function;
    MOPService: MOPService;
    codesTraitements: any[] = [];
    mainRoute: any;

    /* @ngInject */
    constructor(
        _: any,
        $scope: ng.IScope,
        $timeout: ng.ITimeoutService,
        $state: any,
        $stateParams: any,
        ProduitsCommunicationService: any,
        ProduitsService: any,
        notification: any,
        $location: ng.ILocationService,
        $anchorScroll: ng.IAnchorScrollService,
        MassiaApplicationService: any,
        $uibModal: ng.ui.bootstrap.IModalService,
        RouterHistoryService: RouterHistoryService,
        FormuleService: any,
        FilterHelperService: any,
        SitesService: any,
        ProductService: ProductService,
        MOPService: MOPService
    ) {
        this._ = _;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ProduitCommunicationService = ProduitsCommunicationService;
        this.ProduitsService = ProduitsService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$uibModal = $uibModal;
        this.RouterHistoryService = RouterHistoryService;
        this.FormuleService = FormuleService;
        this.FilterHelperService = FilterHelperService;
        this.SitesService = SitesService;
        this.productSvc = ProductService;
        this.MOPService = MOPService;
    }

	$onInit(): void {
		// Configuration
		this.app = this.MassiaApplicationService.getApplication();

        this.MOPService.setMop([ 
            { title: 'Gestion Big-bag', filename: 'GestionBig-Bags.pdf', application: 'gestion' }
        ]);

        // Propriétés
        this.produit = {
            code: null,
            isCodeAutomatique: false,
            libelle: null,
            gamme: {
                id: 0,
                code: null,
                libelle: null
            },
            type: {
                id: 0,
                code: null,
                libelle: null,
                domaine: null,
                famille: null,
                niveauVisibilite: 0
            },
            producteurs: [],
            idCodeTraitements: [],
			isTerreValorisee: false,
            formules: [],
            appelationCom: [],
            codeDechet: null,
            isRecycle: false,
            isStockable: false,
            isVendable: false,
            isEnabled: true,
            mixDesign: [],
            nomenclatures: [],
            prix: [],
            produitCommerciaux: [],
            siteLinked: [],
            certifications: [],
            societeLaboratoire: [],
            valeurCaracteristique: [],
            niveaux: {},
            coefficientK: [],
            associes: [],
            siteDechet: []
            };
        this.loading = false;

		/**duplication */
		this.hasEnregistrements = false;
		this.isEditMode = false;
		this.saveOrGetHistorique();
		this.loadProduct();
		this.mainRoute = this.$state && this.$state.current && this.$state.current.name ? this.$state.current.name.split('.')[0] : 'produits';
	}

	$onDestroy(): void {
		if (this.watcher) {
			this.watcher();
		}
		this.MOPService.resetMop();
	}

    async loadProduct(): Promise<void> {
        this.startLoading();
        if (this.$stateParams.id) {
            const produit = await this.productSvc.getOne(this.$stateParams.id, {
                params: {
                    application: this.app
                }
            });
            this.produit = <CreateUpdateProduit>produit;
        } else {
            this.produit.isEnabled = true;
        }
        this.setPriceList(this.produit.siteLinked, true, true, this.produit.id);
        if (this.produit.type?.famille?.code === 'INER') {
            this.loadFiltre();
            this.loadCodesTraitement();
        }

        if (this.$stateParams.duplicate) {
            this.produit.id = 0;
            this.produit.code += '_copie';
            this.hasEnregistrements = false;
        }

		this.produit.siteDechet.forEach((x) => {
			x.isTerreValorisee = '' + (typeof x.isTerreValorisee === 'undefined' || x.isTerreValorisee === null ? '' : x.isTerreValorisee);
		});

		this.stopLoading();
	}

    saveOrGetHistorique() {
		const temp = this.RouterHistoryService.data;
		//sauvegarde du form si redirection
		if (temp) {
			this.produit = temp.produit;
			this.certifications = temp.certifications;
		} else {
			this.RouterHistoryService.data = {
				produit: this.produit,
				certifications: this.certifications
			};
		}
	}

    open(domaine: string) {
        const source = this.produit;
        const domain = domaine;
        this.$uibModal
            .open({
                template: '<centre-gestion source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></centre-gestion>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
                        const $ctrl = this;
                        $ctrl.source = source;
                        $ctrl.domaine = domain;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                windowClass: 'modern-modal',
                controllerAs: '$ctrl',
                size: 'xxl'
            })
            .result.then(
                function (result) {
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    }

    async sauvegarder() {
        try {
            if (this.checkValidity()) {
                let id: number;
                let update: boolean = false;
                this.produit.idCodeTraitements = [];
                if(this.produit.codeTraitements) {
                    this.produit.idCodeTraitements = this.produit.codeTraitements.map(x => x.id);

                }
                console.log(this.produit);
                if (this.produit.id) {
                    id = await this.productSvc.update(this.produit.id, this.produit, {
                        params: {
                            application: this.app
                        }
                    });
                    update = true;
                    this.notification.success('PRODUITS.UPDATED');
                } else {
                    id = await this.productSvc.create(this.produit, {
                        params: {
                            application: this.app
                        }
                    });
                    update = false;
                    this.notification.success('PRODUITS.CREATED');
                }

                if (this.produit.certifications) {
                    this.ProduitsService.saveCertifications(id, this.produit.certifications);
                }
                return { id, update };
            }
        } catch (err) {
            if (err.data) {
                console.error(err.data);
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async appliquer() {
        this.RouterHistoryService.ignoreNextRoute();
        const { id, update } = await this.sauvegarder();
        if (id && this.produit.id && update) {
            this.$onInit(); // MN: récuperer les ids des appellations commerciales
        } else if (id && !update) {
            this.$state.go('produits.edit', { id: id });
        }
    }

	async confirmer() {
		const success = await this.sauvegarder();
		if (success.id !== -1) {
			this.annuler();
		}
	}

    async annuler() {
        let returnState;
        if (!this.RouterHistoryService.back() && this.$stateParams.parentState) {
            returnState = this.$stateParams.parentState;
        }
    }

    checkValidity() {
        const validity = true;

        //on va seulement scroller et focus sur le premier onglet où il y a une erreur
        const firstScroll = true;
        const validation = ValidatorHelper.getValidation(this.produit, 'Product');
        if (!validation.isValid) {
            this.watcher = this.$scope.$watch(
                () => this.produit,
                () => this.$scope.$broadcast('productValidations'),
                true
            );
        }
        return validation.isValid;
    }

    async setPriceList(linked: any, initList = false, pIsNeeded = false, id?: number) {
        try {
            const idProduit = this.produit.id;
            let prix: { items: any[] } = {
                items: []
            };
            if (idProduit) {
                const produitFilter = this.FilterHelperService.containsFilter(idProduit, 'ProduitId');
                const producteurFilter = this.FilterHelperService.inFilter(
                    this.produit.producteurs.map((x) => x.id),
                    'ProducteurId'
                );
                prix = await this.ProduitsService.getAllPrix([produitFilter, producteurFilter], [], 0);
            }
            if (!this.produit.prix) {
                this.produit.prix = [];
            }
            this._.forEach(linked, ({ producteur, clients }: { producteur: Site; clients: Site[] }) => {
                this._.forEach(clients, (c: Site) => {
                    const p = prix.items.find(
                        (x: any) => x.grilleTarifaireSiteCommId === c.id && x.produitId === idProduit && x.producteurId === producteur.id
                    );
                    const m: PrixProduit = {
                        id: p?.id,
                        producteur: producteur,
                        client: c,
                        prix: this.produit.prix?.find((x: any) => x.producteur.id === producteur.id && x.client.id === c.id)?.prix ?? p?.valeur,
                        isNeeded: p === undefined || pIsNeeded,
                        arrondi: p?.grilleTarifaireSiteCommDeviseArrondi
                    };
                    if (m.prix) {
                        m.prix = this.doRound(m.prix, m.arrondi);
                    }
                    const pm = this.produit.prix?.find((x) => x.producteur.id === producteur.id && x.client.id === c.id);
                    if (pm) {
                        pm.prix = m.prix;
                    } else {
                        this.produit.prix.push(m);
                    }
                });
            });
            if (!initList && id) {
                await this.priceModal(
                    this.produit.prix.filter((x) => x.producteur.id === id),
                    !initList
                );
            }
        } catch (err) {
            err === undefined ? console.log(err) : this.notification.error(err);
            throw err;
        }
    }

	//Set price on article
	async priceModal(producteurs: PrixProduit[], displayModal: boolean) {
		try {
			if (displayModal) {
				const modalInstance = this.$uibModal.open({
					template: require('../produit-form/eqiom-price-modal/produit.form.eqiom.price.modal.html'),
					animation: false,
					windowClass: 'modern-modal',
					backdrop: 'static',
					size: 'md',
					controller: [
						'$uibModalInstance',
						function ($uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
							const $ctrl = this;
							$ctrl.producteurs = producteurs;
							$ctrl.ok = () => $uibModalInstance.close();
							$ctrl.cancel = () => $uibModalInstance.dismiss();
						}
					],
					controllerAs: '$ctrl'
				});

				await modalInstance.result;
				this._.forEach(producteurs, (prod: PrixProduit) => {
					prod.prix = this.doRound(prod.prix, prod.arrondi);
				});
			}
			return true;
		} catch (dismiss) {
			dismiss === undefined ? console.log(dismiss) : this.notification.error(dismiss);
			throw dismiss;
		}
	}

    doRound(number: string, decimal: number) {
        if (!number) {
            return number;
        }
        if (typeof decimal === 'undefined' || decimal === null) {
            decimal = 2;
        }

        var test = Number(number);
        return (Math.round((test + Number.EPSILON) * 100) / 100).toFixed(decimal);
    }

	async edit(data: any, _this: any) {
		const that = _this.$parent.$parent.$parent.$parent.$ctrl;
		that.RouterHistoryService.ignoreNextRoute();
		that.$state.go('produits.edit', { id: data.idProduit });
	}

	scrollToOnglet(id: any) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

    onUpdateNiveaux(niveaux: any) {
        this.produit.niveaux = niveaux;
    }

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

    async loadFiltre() {
        this.filtreNomenclatures = [];
        try {
            const data = await this.ProduitsService.getFiltres();
            this.filtreNomenclatures = data;
        } catch (ex) {
            console.error(ex);
        }
    }

    haveSite() {
        return !this.produit.id && (!this.produit.producteurs || this.produit.producteurs.length === 0);
    }

    async loadCodesTraitement() {
        this.codesTraitements = await this.ProduitsService.getCodesTraitements();
        if (this.produit.siteDechet) {
            this.produit.siteDechet.forEach(x => {
                x.listeCodesTraitement = angular.copy(this.codesTraitements)
                if(x.codesTraitement && x.codesTraitement.length > 0){
                    const ids : number[] = x.codesTraitement.map((x : any)=> x.id);
                    x.listeCodesTraitement.forEach((element: any) => {
                        element.selected = ids.includes(element.id);
                    });
                }
            });
        }
    }
}
