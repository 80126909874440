import VehiculesConfig from './_config/vehicules.config';
import VehiculesRoutes from './_config/vehicules.routes';
import VehiculesComponent from './components/vehicules';
import VehiculesService from './services/vehicules.service';
import VehiculesTypesService from './services/vehicules.types.service';
import VehiculesCommunicationService from './services/vehicules.communication.service';

import VehiculeFormComponent from './components/vehicule-form';
import VehiculeDetailComponent from './components/vehicule-detail';
import VehiculeEnteteComponent from './components/vehicule-entete';
import VehiculesATarerComponent from './components/vehicules-a-tarer';

import VehiculeValidator from './components/vehicule-form/vehicule.validator';
import VehiculeEnteteValidator from './components/vehicule-entete/vehicule.entete.validator';

const moduleName = 'app.massia.gestion.vehicules';

angular
    .module(moduleName, [])
    .config(VehiculesConfig)
    .config(VehiculesRoutes)
    .service('VehiculesService', VehiculesService)
    .service('VehiculesTypesService', VehiculesTypesService)
    .service('VehiculesCommunicationService', VehiculesCommunicationService)
    .component('vehicules', VehiculesComponent)
    .component('vehiculeForm', VehiculeFormComponent)
    .component('vehiculeDetail', VehiculeDetailComponent)
    .component('vehiculeEntete', VehiculeEnteteComponent)
    .component('vehiculesATarer', VehiculesATarerComponent)
    .factory('VehiculeValidator', VehiculeValidator)
    .factory('VehiculeEnteteValidator', VehiculeEnteteValidator);

export default moduleName;
