export const Champ = {
    AUCUN: 0,
    TEXTE: 1,
    NOMBREENTIER: 2,
    NOMBERDECIMAL: 3,
    BOOL: 4,
    DATE: 5,
    HEURE: 6,
    IDTABLE: 7,
    IDENUM: 8
};
