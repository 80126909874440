export default class DepreciationStockPrixController {
    static $inject = ['$stateParams', 'StocksCommunicationService', 'StocksService', 'notification', 'ModalService', '$uibModal'];

    constructor($stateParams, StocksCommunicationService, StocksService, notification, ModalService, $uibModal) {
        this.$stateParams = $stateParams;
        this.StocksCommunicationService = StocksCommunicationService;
        this.StocksService = StocksService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.svgQte = this.ligne.stockFinalQuantiteDepreciee;
        this.svgPrix = this.ligne.stockFinalPrixDeprecie;
        this.svgMotif = this.ligne.idMotifFinalPrix;
        this.motifs = await this.StocksService.getMotifsDepreciation();
    }

    annuler() {
        this.ligne.stockFinalQuantiteDepreciee = this.svgQte;
        this.ligne.stockFinalPrixDeprecie = this.svgPrix;
        this.ligne.idMotifFinalPrix = this.svgMotif;
        this.modalInstance.close(false);
    }

    async confirmer() {
        if (!this.ligne.idMotifFinalPrix) {
            this.notification.error('STOCKS.MOTIF_OBLIGATOIRE');
        } else {
            this.modalInstance.close(true);
        }
    }
}
