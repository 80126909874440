PaiementValidator.$inject = ['validator'];

export default function PaiementValidator(validator) {
    const instance = new validator();

    // instance.ruleFor('id').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    // instance.ruleFor('id').length(1, 15).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    return instance;
}
