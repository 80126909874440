import { rest } from 'lodash';

export default class ProduitFormProducteursValeurReferenceController {
    /* @ngInject */
    constructor(
        ProduitsService,
        notification,
        _,
        moment,
        $translate,
        MassiaApplicationService,
        globalizationManagementService,
        ParametreProgrammeService,
        $uibModal,
        CompositionsServices,
        FormuleService,
        FormuleCalculsService,
        MassiaRightsService
    ) {
        this.ProduitsService = ProduitsService;
        this.notification = notification;
        this._ = _;
        this.moment = moment;
        this.$translate = $translate;
        this.MassiaApplicationService = MassiaApplicationService;
        this.ParametreProgrammeService = ParametreProgrammeService;
        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
        this.$uibModal = $uibModal;
        this.CompositionsServices = CompositionsServices;
        this.FormuleService = FormuleService;
        this.FormuleCalculsService = FormuleCalculsService;
        this.MassiaRightsService = MassiaRightsService;
    }

    essais;
    valeurReferenceSelected;
    lastValeursReference = [];
    isOpenHisto = false;

    $onInit() {
        this.init();
    }

    async init() {
        this.loading = true;
        this.app = this.MassiaApplicationService.getApplication();
        this.readOnly = this.MassiaRightsService.userHasRight('valeurref', 'update', 'laboratoire');

        this.essais = await this.ProduitsService.getEssais(this.produit.id || 0, this.produit.type.id || 0);
        this.producteurs = await this.ProduitsService.getProducteurs(this.produit.id || 0);
        const producteur = this.producteurs.find((e) => e.id === this.producteur.id);
        this.producteur.valeursReference = producteur ? producteur.valeursReference : [];

        /* if (this.produit && this.produit.type && this.produit.type.famille && this.produit.type.famille.code === 'GRAN') { */
        this.EssMV = await this.ParametreProgrammeService.getOneByCode('EssMV');

        if (this.EssMV && this.producteur && this.producteur.valeursReference && this.producteur.valeursReference.length > 0) {
            const idxEssaisMV = this.producteur.valeursReference.findIndex((x) => x.essaiCode === this.EssMV && x.estActuel);
            if (idxEssaisMV > -1) {
                this.oldValue = this.producteur.valeursReference[idxEssaisMV].valeur;
            }
        }
        /* } */

        this.calculFormulePcentMassique = (await this.FormuleCalculsService.getCaractParamProg('BooGestFormMass')) === '1';

        this.orderValeursReference();
        this.loading = false;
        //console.log(this.lastValeursReference);
    }

    orderValeursReference() {
        this.lastValeursReference = [];
        this.producteur.valeursReference = this._.sortBy(this.producteur.valeursReference, 'estActuel').reverse();
        this.producteur.valeursReference.forEach(function (vr) {
            if (vr.estActuel) {
                vr.histo = [];
                this.lastValeursReference.push(vr);
            } else if (!vr.estActuel) {
                let valRef = this._.find(this.lastValeursReference, { essaiId: vr.essaiId });
                if (!valRef) {
                    valRef = { histo: [] };
                }
                if (!valRef.histo) {
                    valRef.histo = [];
                }
                valRef.histo.push(vr);
            }
            if (vr.isNumerique) {
                vr.valeur = parseFloat(vr.valeur);
            }
        }, this);

        this.lastValeursReference = this._.sortBy(this.lastValeursReference, 'essaiLibelle');
    }

    addValeursReference(producteur) {
        if (producteur.addVRMode != undefined) {
            producteur.addVRMode = !producteur.addVRMode;
        } else {
            producteur.addVRMode = true;
        }

        producteur.selectedEssais = [];
    }

    async saveVRValue(valeurReference) {
        try {
            this.startLoading();
            await this.ProduitsService.updateValeurReference(valeurReference);
            if (
                __configuration.licenceLaboratoire === '2' &&
                this.EssMV &&
                this.EssMV === valeurReference.essaiCode &&
                this.oldValue !== valeurReference.valeur
            ) {
                let compositions = await this.getFormulesImpactees(this.produit);
                //console.log(angular.copy(compositions));
                if (compositions && compositions.length > 0) {
                    const res = await this.verifMiseAuCube(this.oldValue, valeurReference.valeur, this.produit, this.producteur);

                    if (res && res.isArchive) {
                        compositions = await this.archiveComposition(compositions);
                    }

                    await this.sauvegarderChangementFormuleMiseAuCube(this.oldValue, valeurReference.valeur, this.produit, compositions, res.isOk);
                    if (res.isOk) {
                        this.notification.success('PRODUITS.MODAL_EQIOM.MISE_AU_CUBE.DONE');
                    }
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.oldValue = valeurReference.valeur;
            this.notification.success('PRODUITS.PRODUCTEURS.VR_VALUE_SAVE');
            this.stopLoading();
        }
    }

    async verifMiseAuCube(oldValue, newValue, produit, producteur) {
        let result = null;
        if (oldValue && newValue) {
            const modalInstance = this.$uibModal.open({
                component: 'produitFormProducteursValeurReferenceCubeModal',
                resolve: {
                    oldValue: () => oldValue,
                    newValue: () => newValue,
                    produit: () => produit,
                    producteur: () => producteur
                },
                backdrop: false
            });

            result = await modalInstance.result;
        }

        return result;
    }

    async getFormulesImpactees(produit) {
        try {
            const compositions = await this.CompositionsServices.getAll([
                {
                    criterion: 'composants',
                    value: produit.id,
                    type: 'Eq'
                }
            ]);

            return compositions.items.filter((e) => !e.dateArchivage && e.produit.isEnabled === true && e.produit.codeFamille === 'BPE');
        } catch (error) {
            this.notification.error(error);
        }
    }

    async archiveComposition(compositions) {
        if (compositions && compositions.length > 0) {
            for (let i = 0; i < compositions.length; i++) {
                const compo = compositions[i];

                compo.idComposition = compo.id;
                compo.code = compo.producteur.code + '' + compo.code;
                compo.idProducteur = compo.producteur.id;
                compo.dateCreation = this.moment(compo.dateCreation, this.dateFormat + ' hh:mm').toDate();
                compo.dateFinValidite = this.moment(compo.dateFinValidite, this.dateFormat + ' hh:mm').toDate();

                let id = false;
                id = await this.FormuleService.archiveFormule(compo.idComposition, compo, this.app);
                if (id) {
                    compo.id = id;
                    compo.idComposition = id;
                    if (compo.squelette != null) {
                        compo.squelette.idComposition = compo.squelette.id;
                    }
                    for (let j = 0; j < compo.composants.length; j++) {
                        const composant = compo.composants[j];
                        composant.id = 0;
                        composant.IdComposant = composant.produit.id;
                        composant.IdProducteur = composant.producteur.id;
                    }

                    this._.forEach(compo.compoAddCoef, (compoAddCoef) => {
                        compoAddCoef.id = 0;
                    });
                }
            }
            this.notification.success('PRODUITS.MODAL_EQIOM.MISE_AU_CUBE.ARCHIVED');
            return compositions;
        }
    }

    async sauvegarderChangementFormuleMiseAuCube(oldValue, newValue, produit, compositions, haveToNormalize) {
        for (let i = 0; i < compositions.length; i++) {
            const composition = compositions[i];

            if (composition.dosageKg) {
                if (produit.type.famille.code === 'GRAN') {
                    if (composition.squelette != null) {
                        composition.squelette = null;
                    }

                    composition.haveToNormalize = false;
                    for (let idx = 0; idx < composition.composants.length; idx++) {
                        composition.composants[idx].idComposant = composition.composants[idx].produit.id;
                        composition.composants[idx].codeFamille = composition.composants[idx].produit.codeFamille;
                        composition.composants[idx].idProducteur = composition.composants[idx].producteur.id;
                    }

                    /** changement Pourcent et volume comm changeWeight des formules */
                    this.changeWeight(produit, composition, newValue);

                    await this.FormuleService.updateFormule(composition.id, composition);
                    delete composition.haveToNormalize;
                }
            } else if (composition.composants && composition.composants.length > 0) {
                for (let idx = 0; idx < composition.composants.length; idx++) {
                    composition.composants[idx].idComposant = composition.composants[idx].produit.id;
                    composition.composants[idx].codeFamille = composition.composants[idx].produit.codeFamille;
                    composition.composants[idx].idProducteur = composition.composants[idx].producteur.id;
                    if (composition.squelette != null) {
                        composition.squelette.idComposition = composition.squelette.id;
                    }
                    if (composition.composants[idx].idComposant === produit.id) {
                        composition.composants[idx].masseVolumiqueChanged = newValue;
                        if (produit.type.famille.code === 'GRAN') {
                            composition.composants[idx].poids = this.remiseAuCubeComposant(oldValue, newValue, composition.composants[idx].poids);
                        }
                    }
                }
                composition.haveToNormalize = haveToNormalize;

                await this.FormuleService.updateFormule(composition.id, composition);

                delete composition.haveToNormalize;
            }
        }
    }

    remiseAuCubeComposant(oldValue, newValue, poids) {
        if (Number.parseFloat(oldValue) && Number.parseFloat(newValue) && Number.parseFloat(poids)) {
            if (!this.calculFormulePcentMassique) {
                const res = (poids / oldValue) * newValue;
                return Number.parseFloat(res);
            }
            return Number.parseFloat(poids);
        }
    }

    changeWeight(produit, composition, newValue) {
        try {
            // on change le volume du composant
            const composantEnCours = composition.composants.find((c) => c.produit.id === produit.id);
            composantEnCours.volume = Number.parseFloat(composantEnCours.poids) / Number.parseFloat(newValue);

            // on calcul le volume total des granulats de la composition
            let volTotalGran = 0;

            this._.forEach(composition.composants, (composantsGran) => {
                if (composantsGran.codeFamille === 'GRAN') {
                    if (composantsGran.produit.id !== produit.id) {
                        composantsGran.volume = Number.parseFloat(composantsGran.poids) / Number.parseFloat(composantsGran.masseVolumique);
                    }
                    volTotalGran += composantsGran.volume;
                }
            });

            // on recalcul les % volumiques
            const lstGranComposants = composition.composants.filter((e) => e.codeFamille === 'GRAN');

            // si le composant est le seul granulats le % est de  100 %
            if (lstGranComposants.length <= 1) {
                composantEnCours.quantite = '100.0';
            } else {
                for (let i = 0; i < lstGranComposants.length; i++) {
                    const e = lstGranComposants[i];
                    // pour chaque composants granulat restant
                    // si on est en % VOLUMIQUE
                    // on calcul le nouveau % de chaque composant par rapport a son volume et au volume total des granulats calculer plus haut
                    const newQuantite = ((Number.parseFloat(e.volume) * 100) / Number.parseFloat(volTotalGran)).toFixed(2);
                    e.quantite = Number.parseFloat(newQuantite).toFixed(2);
                }
            }
        } catch (error) {
            console.log(error.data);
        }
    }

    isLastValue(newValeurReference) {
        let res = true;

        this.lastValeursReference.forEach(function (lvr) {
            if (this.moment(lvr.date, this.dateFormat).isAfter(this.moment(newValeurReference.date, this.dateFormat))) {
                res = false;
            }
        }, this);

        return res;
    }

    pushVRInTab() {
        for (const essai of this.producteur.selectedEssais) {
            if (!_.find(this.producteur.valeursReference, { sousEssaiId: essai.sousEssaiId, date: this.producteur.startDate })) {
                const newValeurReference = {
                    id: null,
                    produitId: this.produit.id,
                    produitLibelle: this.produit.libelle,
                    producteurId: this.producteur.id,
                    producteurLibelle: this.producteur.libelle,
                    essaiId: essai.id,
                    essaiLibelle: essai.libelle,
                    sousEssaiId: essai.sousEssaiId,
                    valeur: null,
                    unite: essai.unite,
                    date: this.producteur.startDate,
                    essaiCode: essai.code
                };

                newValeurReference.estActuel = this.isLastValue(newValeurReference);
                this.producteur.valeursReference.push(newValeurReference);
                this.saveVR(newValeurReference);
            } else {
                this.notification.warning('PRODUITS.PRODUCTEURS.VR_EXIST');
            }
            this.orderValeursReference();
        }
    }

    async saveVR(newValeurReference) {
        const idVR = await this.ProduitsService.createValeurReference(newValeurReference);
        newValeurReference.id = idVR;
        this.notification.success('PRODUITS.PRODUCTEURS.VR_SAVE');
    }

    async deleteValeurReference(valeurReference, producteur) {
        await this.ProduitsService.deleteValeurReference(valeurReference);
        this._.remove(producteur.valeursReference, { id: valeurReference.id });
        this.notification.success('PRODUITS.PRODUCTEURS.VR_DELETE');
        this.orderValeursReference();
    }

    openHisto(valeurReference) {
        if (JSON.stringify(this.valeurReferenceSelected) === JSON.stringify(valeurReference) && this.openHisto) {
            this.valeurReferenceSelected = null;
            this.isOpenHisto = false;
        } else {
            this.valeurReferenceSelected = valeurReference;
            this.isOpenHisto = true;
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
