export default function Configure($stateProvider) {
    $stateProvider.state('referentiels', {
        parent: 'common',
        url: '/referentiels',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.SAISIES" | translate }}'
        }
    });

    $stateProvider.state('referentiels.list', {
        url: '/list',
        template: '<referentiels></referentiels>',
        rights: { domain: 'referentiel', right: 'read' },
        ncyBreadcrumb: {
            parent: 'referentiels',
            label: '{{ "REFERENTIELS.BREADCRUMBS.REFERENTIELS_LIST" | translate}}'
        },
        navigation: {
            menu: 'laboratoire_donnees',
            translate: 'REFERENTIELS.BREADCRUMBS.REFERENTIELS_LIST',
            order: 40,
            navigationCls: 'menu-item-separator'
        },
        search: 'REFERENTIELS.BREADCRUMBS.REFERENTIELS_LIST'
    });

    // $stateProvider.state('tva.list.detail', {
    //     url: '/{id}',
    //     template: '<tva-detail></tva-detail>',
    //     rights: { domain: 'tva', right: 'read' },
    //     ncyBreadcrumb: {
    //         parent: 'tva.list',
    //         label: '{{ "TVA.BREADCRUMBS.TVA_DETAIL" | translate }}',
    //     },
    // });

    $stateProvider.state('referentiels.new', {
        url: '/new',
        template: '<referentiel-form></referentiel-form>',
        rights: { domain: 'referentiel', right: 'create' },
        ncyBreadcrumb: {
            parent: 'referentiels.list',
            label: '{{ "REFERENTIELS.BREADCRUMBS.REFERENTIEL_NEW" | translate}}'
        },
        search: 'REFERENTIELS.BREADCRUMBS.REFERENTIEL_NEW'
    });

    $stateProvider.state('referentiels.edit', {
        url: '/{id}/edit',
        template: '<referentiel-form></referentiel-form>',
        rights: { domain: 'referentiel', right: 'update' },
        ncyBreadcrumb: {
            parent: 'referentiels.list',
            label: '{{ "REFERENTIELS.BREADCRUMBS.REFERENTIEL_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
