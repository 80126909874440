export default class TicketsExportCsvPopupController {
    static $inject = ['$scope', '$stateParams', 'TicketsService', 'notification', 'ModalService', '$uibModal', 'moment'];

    constructor($scope, $stateParams, TicketsService, notification, ModalService, $uibModal, moment) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.TicketsService = TicketsService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
        this.moment = moment;
    }

    annuler() {
        this.modalInstance.close();
    }

    async appliquer() {
        await this.sauvegarder();
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    async $onInit() {}

    async sauvegarder() {
        try {
            if (this.dateDebut && this.dateFin) {
                const ajdhui = Date.now();
                const fileName = 'Pesees_' + this.moment(ajdhui).format('DDMMYY');
                const resultat = await this.TicketsService.exportSete(fileName, this.dateDebut, this.dateFin);
                const data = resultat.data;
                const status = resultat.status;
                let headers = resultat.headers;

                headers = headers();

                const contentType = headers['content-type'];

                const linkElement = document.createElement('a');
                try {
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    linkElement.setAttribute('download', fileName + '.csv');

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                } catch (ex) {
                } finally {
                }
                return true;
            }

            this.notification.error('TICKETS.EXPORT_CSV_PARAMETRE_MANQUANT');
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        }
    }
}
