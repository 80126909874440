const serviceUrl = `${__configuration.apiUrl}/massia/produits`;
const serviceStockUrl = `${__configuration.apiUrl}/massia/produits-stockes`;
const serviceNormeUrl = `${__configuration.apiUrl}/massia/produitsnormes`;
const serviceGammeUrl = `${__configuration.apiUrl}/massia/gammes-produits`;
const serviceCertificatUrl = `${__configuration.apiUrl}/massia/laboratoire/certificat-produit`;
const serviceTypeProductionUrl = `${__configuration.apiUrl}/massia/gammes-produits`;

export default class ProduitsService {
	/*@ngInject*/
	constructor($http, TypesNiveauxHierarchique, CaracteristiquesTypesService, CaracteristiquesService) {
		this.$http = $http;
		this.TypesNiveauxHierarchique = TypesNiveauxHierarchique;
		this.CaracteristiquesTypesService = CaracteristiquesTypesService;
		this.CaracteristiquesService = CaracteristiquesService;
	}

	async getProduits(filters, sorts, pagination, isFiltered, application) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(
					pagination || {
						skip: 0,
						take: 2000000
					}
				),
				isFiltered: JSON.stringify(isFiltered || false),
				application: JSON.stringify(application)
			}
		});

		return res.data;
	}

	async getProduitsGrille(filters, sorts, pagination, isFiltered, application, familleId, typeId, producteursIds, sitesLiesIds) {
		const res = await this.$http.get(serviceUrl + '/grille', {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(
					pagination || {
						skip: 0,
						take: 2000000
					}
				),
				isFiltered: JSON.stringify(isFiltered || false),
				application: JSON.stringify(application),
				familleId: JSON.stringify(familleId),
				typeId: JSON.stringify(typeId || 0),
				producteursIds: JSON.stringify(producteursIds || []),
				sitesLiesIds: JSON.stringify(sitesLiesIds || [])
			}
		});
		return res.data;
	}

	async getAllProducts(typeId) {
		const res = await this.$http.get(serviceUrl + '/all', {
			params: {
				typeId: typeId
			}
		});

		return res;
	}

	async getAllProductsByFamille(codes) {
		const url = `${__configuration.apiUrl}/massia/produits/famille`;
		const res = await this.$http.get(url, {
			params: {
				codes: codes || null
			}
		});

		return res.data;
	}

	async deleteProduitById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async getProduitEnteteById(id) {
		const url = `${serviceUrl}/${id}/entete`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getProduitValeursCaracteristiquesAssociationsById(id) {
		let data = await this.getProduitValeursCaracteristiquesById(id);
		const result = {};

		if (!Array.isArray(data)) {
			data = [data];
		}

		for (let i = 0; i < data.length; i++) {
			if (data[i]) {
				if (data[i].value) {
					if (data[i].value === 'True') {
						data[i].value = 'true';
					}
					if (data[i].value === 'False') {
						data[i].value = 'false';
					}
				}

				if (data[i].value && data[i].value.key) {
					result[data[i].id] = data[i].value.key;
				} else {
					result[data[i].id] = data[i].value;
				}
			}
		}
		return result;
	}

	async getProduitValeursCaracteristiquesById(id) {
		const url = `${serviceUrl}/${id}/valeurs-caracteristiques`;
		const res = await this.$http.get(url);
		const result = [];
		res.data.forEach(function (element) {
			const caract = {
				id: element.id,
				code: element.code,
				idNature: element.idNature,
				indice: element.indice,
				label: element.label,
				numericAdditionalInformation: element.numericAdditionalInformation,
				value: element.elementsValues || element.elementValue || element.values || element.value,
				symbole: element.symbole || null
			};
			result.push(caract);
		});

		return result;
	}

	/*async getIdSerieTamis(idType){
        var typeUrl = `${__configuration.apiUrl}/massia/types/${idType}`;
        let res = await this.$http.get(typeUrl);
        return res.data.idSerieDimensionnelleTamis;
    }*/
	async getTypesProduction() {
		const url = `${__configuration.apiUrl}/massia/type-production`;
		const res = await this.$http.get(url);
		return res.data.items;
	}
	async getType(idType) {
		const typeUrl = `${__configuration.apiUrl}/massia/types/${idType}`;
		const res = await this.$http.get(typeUrl);
		return res.data;
	}

	async getTamis(idSerieDim) {
		const serieTamisUrl = `${__configuration.apiUrl}/massia/seriestamis/${idSerieDim}/entete`;
		const res = await this.$http.get(serieTamisUrl);
		return res.data;
	}

	async createProduit(produit) {
		const data = {
			code: produit.entete.code,
			libelle: produit.entete.libelle,
			idType: produit.entete.typeId,
			idPetitDiametre: produit.entete.idPetitd,
			idGrandDiametre: produit.entete.idGrandD,
			idGamme: produit.entete.idGamme,
			libelleGamme: produit.entete.libelleGamme,
			idNature: produit.entete.idNature,
			libelleNature: produit.entete.libelleNature,
			image: produit.entete.image,
			isVendable: produit.entete.isVendable,
			isStockable: produit.entete.isStockable,
			sitesIds: produit.entete.sitesIds,
			caracteristiques: [],
			niveaux: produit.niveaux,
			isRecycle: produit.entete.isRecycle,
			normeId: produit.entete.normeId,
			codeDechet: produit.entete.codeDechet,
			idCodeTraitements: produit.entete.idCodeTraitements,
			isTerreValorisee: produit.entete.isTerreValorisee,
			price: produit.price,
			sitesLies: produit.siteLies,
			siteDechets: produit.entete.siteDechets
		};
		data.caracteristiques = this.formatCaracteristiques(produit.caracteristiques);

		const result = await this.$http.post(serviceUrl, data);
		return result.data;
	}

	async updateProduit(produit) {
		console.log(produit);
		const data = {
			libelle: produit.entete.libelle,
			idType: produit.entete.typeId,
			idPetitDiametre: produit.entete.idPetitd,
			idGrandDiametre: produit.entete.idGrandD,
			idGamme: produit.entete.idGamme,
			libelleGamme: produit.entete.libelleGamme,
			idNature: produit.entete.idNature,
			libelleNature: produit.entete.libelleNature,
			image: produit.entete.image,
			isVendable: produit.entete.isVendable,
			isStockable: produit.entete.isStockable,
			poidsDefault: produit.entete.poidsDefault,
			sitesIds: produit.entete.sitesIds,
			caracteristiques: [],
			niveaux: produit.niveaux,
			donneeFiltreeNomenclature: produit.donneeFiltreeNomenclature,
			isRecycle: produit.entete.isRecycle,
			normeId: produit.entete.normeId,
			price: produit.price,
			sitesLies: produit.siteLies,
			codeDechet: produit.entete.codeDechet,
			idCodeTraitements: produit.entete.idCodeTraitements,
			isTerreValorisee: produit.entete.isTerreValorisee,
			siteDechets: produit.entete.siteDechets
		};
		data.caracteristiques = this.formatCaracteristiques(produit.caracteristiques);
		data.produitsStockes = this.formatProduitStocke(produit.produitsStockes);
		const url = `${serviceUrl}/${produit.id}`;
		return await this.$http.put(url, data);
	}

	formatProduitStocke(produitsStockes) {
		const data = [];
		for (const property in produitsStockes) {
			let keyValueObject;
			if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
				const propertyVal = produitsStockes[property];
				keyValueObject = {
					code: propertyVal.id,
					niveaux: propertyVal.values
				};
				data.push(keyValueObject);
			}
		}
		return data;
	}

	formatCaracteristiques(caracteristiques) {
		const data = [];
		for (const property in caracteristiques) {
			let keyValueObject;
			//On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variable interne Js.
			if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
				const propertyVal = caracteristiques[property];
				if (Array.isArray(propertyVal)) {
					if (_.some(propertyVal, 'key')) {
						keyValueObject = {
							IdCaracteristique: parseInt(property),
							valeurs: _.map(propertyVal, 'key')
						};
					} else {
						keyValueObject = {
							IdCaracteristique: parseInt(property),
							valeurs: propertyVal
						};
					}
				} else {
					keyValueObject = {
						IdCaracteristique: parseInt(property),
						valeur: propertyVal
					};
				}
				data.push(keyValueObject);
			}
		}
		return data;
	}

	async generateProduitNorme(produit, application = null, idProducteur, idComposition, eauRemplacer) {
		const data = {
			idType: produit.entete.typeId,
			idSite: produit.entete.idSite,
			idPetitDiametre: produit.entete.idPetitd,
			idGrandDiametre: produit.entete.idGrandD,
			code: produit.entete.code,
			caracteristiques: [],
			libelle: produit.entete.libelle,
			appelation: produit.entete.appelation,
			sitesLies: produit.siteLies,
			idProducteur: idProducteur || 0,
			idComposition: idComposition || 0,
			composantEau: eauRemplacer || []
		};
		data.caracteristiques = this.formatCaracteristiques(produit.caracteristiques);

		if (!idProducteur) {
			idProducteur = -1;
		}
		if (!idComposition) {
			idComposition = -1;
		}

		const result = await this.$http.post(`${serviceNormeUrl}`, data, {
			params: {
				application: JSON.stringify(application)
			}
		});
		return result.data;
	}

	async calculeIdentifiantNorme(produit) {
		const data = {
			idType: produit.entete.typeId,
			idSite: produit.entete.idSite,
			idPetitDiametre: produit.entete.idPetitd,
			idGrandDiametre: produit.entete.idGrandD,
			caracteristiques: []
		};
		data.caracteristiques = this.formatCaracteristiques(produit.caracteristiques);
		const url = `${serviceNormeUrl}/identifiant`;
		const result = await this.$http.post(url, data);
		return result.data;
	}

	async codeExists(code) {
		const url = `${serviceUrl}/code-unicity/${code}`;
		const result = await this.$http.get(url);
		return result.data;
	}

	async getSites() {
		const siteUrl = `${__configuration.apiUrl}/massia/sites-producteurs`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getProducteurs(idProduit) {
		const siteUrl = `${__configuration.apiUrl}/massia/sites/sites-by-produit/${idProduit}`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	/*async getSerieTamis() {
        var serieTamisUrl = `${__configuration.apiUrl}/massia/seriestamis`;
        let res = await this.$http.get(serieTamisUrl);
        return res.data;
    }*/

	async getProducteursList() {
		const application = 'all'; //SP 05/10/18 pour laisser non filtré sur états compte
		const siteUrl = `${__configuration.apiUrl}/massia/sites-producteurs/headers/${application}`;
		const res = await this.$http.get(siteUrl);
		return res.data;
	}

	async getProduitsNiveaux() {
		const url = `${__configuration.apiUrl}/massia/domaines/produits/niveaux-hierarchiques`;
		const res = await this.$http.get(url);
		return this.buildProduitsNiveaux(res.data);
	}

	async getProduitsStocksNiveaux() {
		const url = `${__configuration.apiUrl}/massia/domaines/produitsstockes/niveaux-hierarchiques`;
		const res = await this.$http.get(url);
		return this.buildProduitsNiveaux(res.data);
	}

	//methode de conversion du format retourne par le serveur vers le format attendu par le front
	buildProduitsNiveaux(niveaux) {
		let newNiveaux = [];

		if (niveaux && niveaux.length > 0) {
			const self = this;

			newNiveaux = _.map(niveaux, function (niveau) {
				return {
					//le resultat retourne par le serveur ait le type int pour id du niveau,
					//cependant, selon json attendu par le front, il est necessire que cet id soit en format string (pour que le reste puisse fonctionner comme prevu)
					//on convertit donc l'id de int a string ici
					idNiveau: niveau.id.toString(),

					label: niveau.label,
					saisi: niveau.type === self.TypesNiveauxHierarchique.Libre.value,
					sousNiveaux: self.buildProduitsNiveaux(niveau.items)
				};
			});
		}

		return newNiveaux;
	}

	//le retour de cette methode doit correspond au format predefini dans mock/produits/produits.elements.json
	async getProduitsElements() {
		const url = `${__configuration.apiUrl}/massia/domaines/produits/elements-hierarchiques-by-niveau`;
		const res = await this.$http.get(url);
		return res.data;
	}

	//le retour de cette methode doit correspond au format predefini dans mock/produits/produits.elements.json
	async getProduitsStocksElements() {
		const url = `${__configuration.apiUrl}/massia/domaines/produitsstockes/elements-hierarchiques-by-niveau`;
		const res = await this.$http.get(url);
		return res.data;
	}

	//le retour de cette methode doit correspond au format predefini dans mock/produits/produits.valeurs.json
	async getProduitValeursNiveauxById(id) {
		const url = `${serviceUrl}/${id}/elements-hierarchiques`;
		const res = await this.$http.get(url);
		return res.data;
	}

	//le retour de cette methode doit correspond au format predefini dans mock/produits/produits.valeurs.json
	async getProduitStockeValeursNiveauxById(id) {
		const url = `${serviceStockUrl}/${id}/elements-hierarchiques`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getProduitsStockesById(id) {
		const url = `${serviceUrl}/${id}/produits-stockes`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getSocietes() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/societes-commerciales/header`);
		return res.data;
	}

	async getSocietesProductrices() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/societes-productrices/header`);
		return res.data;
	}

	async getSocietesLaboratoires() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/societes-laboratoires/header`);
		return res.data;
	}

	async getAppellationsCommerciales(idProduit) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/produits/${idProduit}/appellations-commerciales/`);
		return res.data;
	}

	async getSitesClients() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-clients/headers`);
		return res.data;
	}

	async getSitesCommerciaux(idSociete) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-commerciaux/header/${idSociete}`);
		return res.data;
	}

	async getSitesCommerciauxSansSociete() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-commerciaux/headers`);
		return res.data;
	}

	async getSitesProducteursByProduit(idProduit) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites/sites-by-produit/${idProduit}`);
		return res.data;
	}
	async createAppellation(idProduit, data) {
		const res = await this.$http.post(`${__configuration.apiUrl}/massia/produits/${idProduit}/appellations-commerciales`, data);
		return res.data;
	}
	async updateAppellation(idProduit, idAppellation, data) {
		const res = await this.$http.put(`${__configuration.apiUrl}/massia/produits/${idProduit}/appellations-commerciales/${idAppellation}`, data);
		return res.data;
	}
	async deleteAppellation(idProduit, idAppellation) {
		const res = await this.$http.delete(`${__configuration.apiUrl}/massia/produits/${idProduit}/appellations-commerciales/${idAppellation}`);
		return res.data;
	}

	async getFiltres() {
		const url = `${__configuration.apiUrl}/massia/nomenclature/headers`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async exportListeProduits(modeImpr, filters, sorts, isFiltered, application, familleId, typeId, producteursIds, sitesLiesIds) {
		const url = `${serviceUrl}/print`;
		const res = await this.$http.get(url, {
			params: {
				modeImpr: JSON.stringify(modeImpr),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				isFiltered: JSON.stringify(isFiltered || false),
				application: JSON.stringify(application),
				familleId: JSON.stringify(familleId),
				typeId: JSON.stringify(typeId || 0),
				producteursIds: JSON.stringify(producteursIds || []),
				sitesLiesIds: JSON.stringify(sitesLiesIds || [])
			},
			responseType: 'arraybuffer'
		});
		return res;
	}

	async getProduitSpecificites(idType) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/produits/${idType}/specificites`);
		return res.data;
	}

	async getProduitFormules(produitId) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/produits/produitsComposablesByProduitId/${produitId}`);
		return res.data;
	}

	async deleteProduitFormules(produitId, producteurId) {
		const res = await this.$http.delete(`${__configuration.apiUrl}/massia/produits/produitsComposablesByProduitId/${produitId}/${producteurId}`);
		return res.data;
	}

	async getGammes() {
		const res = await this.$http.get(serviceGammeUrl);
		return res.data;
	}

	async saveGamme(gamme) {
		const res = await this.$http.post(serviceGammeUrl, gamme);
		gamme.id = res.data;
		return gamme;
	}

	async getProduitSiteLies(idProduit) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/produits/${idProduit}/site-lies`);
		return res.data;
	}

	async getProduitQuotaBitumes(idProduit) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/produits/${idProduit}/quota-bitumes`);
		return res.data;
	}

	async activeProduitProducteur(idProduit, idProducteur) {
		const res = await this.$http.put(`${__configuration.apiUrl}/massia/produits/${idProduit}/producteurs/${idProducteur}/etat/active`, null);
		return res.data;
	}

	async desactiveProduitProducteur(idProduit, idProducteur) {
		const res = await this.$http.put(`${__configuration.apiUrl}/massia/produits/${idProduit}/producteurs/${idProducteur}/etat/desactive`, null);
		return res.data;
	}

	async activeProduit(idProduit) {
		const res = await this.$http.put(`${__configuration.apiUrl}/massia/produits/${idProduit}/etat/active`, null);
		return res.data;
	}

	async desactiveProduit(idProduit) {
		const res = await this.$http.put(`${__configuration.apiUrl}/massia/produits/${idProduit}/etat/desactive`, null);
		return res.data;
	}

	async activeProduitSiteLie(idProduit, idProducteur, idCentrale) {
		const res = await this.$http.put(
			`${__configuration.apiUrl}/massia/site-lies-labo/${idProduit}/${idProducteur}/${idCentrale}/etat/active`,
			null
		);
		return res.data;
	}

	async desactiveProduitSiteLie(idProduit, idProducteur, idCentrale) {
		const res = await this.$http.put(
			`${__configuration.apiUrl}/massia/site-lies-labo/${idProduit}/${idProducteur}/${idCentrale}/etat/desactive`,
			null
		);
		return res.data;
	}

	async getProducteurs(idProduit) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/produits/${idProduit}/producteurs/`);
		return res.data;
	}

	async deleteProducteurs(idProduit, idProducteur) {
		const res = await this.$http.delete(`${__configuration.apiUrl}/massia/produits/${idProduit}/producteurs/${idProducteur}`);
		return res.data;
	}

	async getEssais(idProduit, idType) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/produits/${idProduit}/essais/${idType}`);
		return res.data;
	}

	async createValeurReference(valeurReference) {
		const res = await this.$http.post(
			`${__configuration.apiUrl}/massia/produits/${valeurReference.produitId}/producteurs/${valeurReference.producteurId}/valeurReference`,
			valeurReference
		);
		return res.data;
	}

	async updateValeurReference(valeurReference) {
		const res = await this.$http.put(
			`${__configuration.apiUrl}/massia/produits/${valeurReference.produitId}/producteurs/${valeurReference.producteurId}/valeurReference/${valeurReference.id}`,
			valeurReference
		);
		return res.data;
	}

	async deleteValeurReference(valeurReference) {
		const res = await this.$http.delete(
			`${__configuration.apiUrl}/massia/produits/${valeurReference.produitId}/producteurs/${valeurReference.producteurId}/valeurReference/${valeurReference.id}`
		);
		return res.data;
	}

	async updateProduitSiteLies(idProduit, siteLie) {
		const data = {
			producteurId: siteLie.producteurId,
			siteEntreeId: siteLie.siteEntreeId,
			siteSortieId: siteLie.siteSortieId
		};
		const url = `${__configuration.apiUrl}/massia/produits/${idProduit}/site-lies`;
		return await this.$http.put(url, data);
	}

	async updateProduitQuotabitumes(idProduit, quotaBitume) {
		const data = {
			producteurId: quotaBitume.producteurId,
			siteId: quotaBitume.siteId
		};
		const url = `${__configuration.apiUrl}/massia/produits/${idProduit}/quota-bitumes`;
		return await this.$http.put(url, data);
	}

	async getAllProduitCommerciaux(idProduit, filters, sorts) {
		const url = `${serviceUrl}/${idProduit}/produit-commerciaux`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || [])
			}
		});
		return res.data;
	}

	async createProduitCommerciaux(idProduit, data) {
		const url = `${serviceUrl}/${idProduit}/produit-commerciaux`;
		return await this.$http.post(url, data);
	}

	async deleteProduitCommerciaux(idProduit, data) {
		const url = `${serviceUrl}/${idProduit}/produit-commerciaux/${data.id}`;
		return await this.$http.delete(url);
	}

	// Get all produit linked to commercial-product
	async getAllMixDesign(idProduit, filters, sorts) {
		const url = `${__configuration.apiUrl}/massia/produit-commercial/${idProduit}/produits`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || [])
			}
		});
		return res.data;
	}

	async getProduitByTypeAndProducteur(codeType, idProducteur) {
		const url = `${serviceUrl}/type/${codeType}/producteur/${idProducteur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	/** site client lies au producteur selon produit */
	async getProduitSiteLiesLabo(idProduit, idProducteur) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/site-lies-labo/${idProduit}/${idProducteur}`);
		return res.data;
	}

	/** Tout Site Client */
	async getAllSitesClient() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-clients`, {
			params: {
				filters: JSON.stringify([]),
				sorts: JSON.stringify([]),
				pagination: JSON.stringify(null),
				isFiltered: JSON.stringify(true)
			}
		});
		return res.data;
	}

	/** enregistrement site client lies au producteur selon le produit*/
	async updateProduitSiteLiesLabo(siteLiesLabo) {
		if (siteLiesLabo && siteLiesLabo.length > 0) {
			const url = `${__configuration.apiUrl}/massia/site-lies-labo/${siteLiesLabo[0].produitId}/${siteLiesLabo[0].producteurId}`;
			return await this.$http.post(url, siteLiesLabo);
		}
	}

	async getProduitByProducteur(id) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/produits/produitsProducteur/${id}`);
		return res.data;
	}

	async getProduitsByTypes(idType) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/produits/type/${idType}`);
		return res.data;
	}

	/*async getProduitsByFamilleProducteur(codeFamille, idProducteur) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/produits/famille/${codeFamille}/producteur/${idProducteur}`);
        return res.data;
    }*/

	async getProduitByCodeFamilleProducteur(codeFamille, idProducteur) {
		const url = `${serviceUrl}/famille-code/${codeFamille}/producteur/${idProducteur}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getProduitsByFamilleProducteur(famille, producteur) {
		const res = await this.$http.get(`${serviceUrl}/famille/${famille}/producteur/${producteur}`);
		return res.data;
	}

	async getProduitsGroupedByProducteur(familles) {
		const res = await this.$http.get(`${serviceUrl}/produitsbyproducteurs`, {
			params: {
				familles: familles
			}
		});
		return res.data;
	}

	async getCertification(idProduit) {
		const res = await this.$http.get(serviceCertificatUrl, {
			params: {
				produit: idProduit,
				filters: JSON.stringify([]),
				sorts: JSON.stringify([]),
				pagination: JSON.stringify({
					skip: 0,
					take: 0
				})
			}
		});
		return res.data;
	}

	async getProducteurCertificat(producteur) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/laboratoire/certificat?siteCertifie=${producteur.id}&typeCertif=3`);
		return res.data.items;
	}

	async saveCertifications(idProduit, certifications) {
		const res = await this.$http.post(`${serviceCertificatUrl}/${idProduit}`, certifications);
		return res.data;
	}

	async saveCopyMixDesign(idProduit, idProducteur, idComposition, eauRemplacer) {
		const result = await this.$http.post(`${serviceUrl}/copy-mix-design/${idProduit}/${idProducteur}/${idComposition}`, eauRemplacer);
		return result.data;
	}

	async libelleExists(libelle) {
		const result = await this.$http.get(`${serviceUrl}/libelle-unicity`, {
			params: {
				libelle: JSON.stringify(libelle)
			}
		});
		return result.data;
	}

	async getProduitIdByLibelle(libelle) {
		const result = await this.$http.get(`${serviceUrl}/getByLibelle`, {
			params: {
				libelle: JSON.stringify(libelle)
			}
		});
		return result.data;
	}

	/* Societe produit */
	async getAllSocieteProduct(filters, sorts, pagination, idProduct) {
		const url = `${serviceUrl}/${idProduct}/societe`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});
		return res.data;
	}

	async createSocieteProduct(data, idProduct) {
		data = {
			Societe: { id: data.id },
			Produit: { id: idProduct }
		};

		const result = await this.$http.post(`${serviceUrl}/${idProduct}/societe`, data);
		return result;
	}

	async deleteSocieteProduct(id, idProduct) {
		const result = await this.$http.delete(`${serviceUrl}/${idProduct}/societe/${id}`);
		return result;
	}

	/* Grilles Tarifaire */
	async getAllGrilles(filters, sorts, pagination) {
		const url = `${__configuration.apiUrl}/massia/grillesTarifaires`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || { skip: 0, take: 0 })
			}
		});
		return res.data;
	}

	/* Prix */
	async getAllPrix(filters, sorts, pagination) {
		const url = `${__configuration.apiUrl}/massia/prix`;
		const res = await this.$http.get(url, {
			params: {
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || { skip: 0, take: 0 })
			}
		});
		return res.data;
	}

	/*  Specifique Eqiom  (à optmiser)   */
	async verifConcreteCode(formule, isTraitementFormule, composant = null) {
		try {
			// pour la famille Granulat on cherche le DMax (on vient ici uniquement s'il a changer)
			let grandD = '';

			let caractValGranComp = [];
			let caractValCimComp = [];
			let caractValAddComp = [];

			if (composant && composant.codeFamille === 'GRAN') {
				grandD = 'D' + composant.grandD.toFixed(0).toString();

				caractValGranComp = await this.CaracteristiquesService.getCaracteristiqueElementByCode('DMax');
			} else if (composant && composant.codeFamille === 'ADD') {
				caractValAddComp = await this.CaracteristiquesTypesService.getEntiteValeursCaracteristiquesById(
					composant.idComposant,
					composant.idType != null ? composant.idType : composant.typeId,
					'Produit'
				);
			} else {
				// Check if there's one 'CIM' composant
				let cimentComp = 0;
				let additionComp = 0;

				// Get CIM composant informations

				if (formule && formule.composants) {
					const ciment = formule.composants
						.filter((c) => c.codeFamille === 'CIM')
						.sort((a, b) => Number.parseFloat(b.poids) - Number.parseFloat(a.poids));
					if (ciment) {
						cimentComp = ciment[0];
					}
				}

				if (cimentComp?.idComposant && (cimentComp.idType || cimentComp.typeId)) {
					caractValCimComp = await this.CaracteristiquesTypesService.getEntiteValeursCaracteristiquesById(
						cimentComp.idComposant,
						cimentComp.idType != null ? cimentComp.idType : cimentComp.typeId,
						'Produit'
					);
				}

				// Get ADD composant informations
				if (formule && formule.composants) {
					const addition = formule.composants
						.filter((c) => c.codeFamille === 'ADD')
						.sort((a, b) => Number.parseFloat(b.poids) - Number.parseFloat(a.poids));
					if (addition) {
						additionComp = addition[0];
					}
				}

				if (additionComp?.idComposant && (additionComp.idType || additionComp.typeId)) {
					caractValAddComp = await this.CaracteristiquesTypesService.getEntiteValeursCaracteristiquesById(
						additionComp.idComposant,
						additionComp.idType != null ? additionComp.idType : additionComp.typeId,
						'Produit'
					);
				}
			}

			if (formule.listCc && formule.listCc.length > 0) {
				for (let i = 0; i < formule.listCc.length; i++) {
					const caract = [];

					//pour le granulat
					if (caractValGranComp && caractValGranComp.length > 0) {
						let grandDChanged;
						// On cherche la caracteristique DMax dans les CC par rapport au nouveau GrandD
						grandDChanged = formule.listCc[i].valeursCaracteristique.find((c) => c.elementValue && c.elementValue.key === grandD);

						//s'il a changer alors on change la caracteristique
						if (typeof grandDChanged === 'undefined') {
							//carac = angular.copy(formule.listCc[i].valeursCaracteristique);
							formule.listCc[i].valeursCaracteristique.forEach((el) => {
								if (el.code === 'DMax') {
									caract[el.idCaracteristique] = grandD;
								} else if (el.elementValue) {
									caract[el.idCaracteristique] = el.elementValue.key;
								}
							});
						}
					}

					// pour le ciments
					if (caractValCimComp && caractValCimComp.length > 0) {
						let CimTypeCc;

						// Check if CEM_TYPE has changed
						for (let vc = 0; vc < caractValCimComp.length; vc++) {
							if (caractValCimComp[vc].value) {
								if (!CimTypeCc) {
									CimTypeCc = formule.listCc[i].valeursCaracteristique.find(
										(c) => c.elementValue && c.elementValue.key === caractValCimComp[vc].value.key
									);
								}
							}
						}

						if (!CimTypeCc) {
							// Create temp Concrete Code with new CIM
							formule.listCc[i].valeursCaracteristique.forEach((el) => {
								if (el.code === 'CEM_TYPE') {
									caract[el.idCaracteristique] = caractValCimComp.find((e) => e.code === 'CEM_TYPE').value.key;
								} else if (el.elementValue) {
									caract[el.idCaracteristique] = el.elementValue.key;
								}
							});
						}
					}

					// pour l'addition
					if (caractValAddComp && caractValAddComp.length > 0) {
						let AddTypeCc;

						// Check if ADD_TYPE has changed
						for (let vc = 0; vc < caractValAddComp.length; vc++) {
							if (caractValAddComp[vc].value) {
								if (!AddTypeCc) {
									AddTypeCc = formule.listCc[i].valeursCaracteristique.find(
										(c) => c.elementValue && c.elementValue.key === caractValAddComp[vc].value.key
									);
								}
							}
						}

						if (!AddTypeCc) {
							// Create temp Concrete Code with new ADD
							formule.listCc[i].valeursCaracteristique.forEach((el) => {
								if (el.code === 'ADD_TYPE') {
									caract[el.idCaracteristique] = caractValAddComp.find((e) => e.code === 'ADD_TYPE').value.key;
								} else if (el.elementValue) {
									caract[el.idCaracteristique] = el.elementValue.key;
								}
							});
						}
					}

					if (caract && caract.length > 0) {
						let produit = {
							entete: await this.getProduitEnteteById(formule.listCc[i].idProduit),
							caracteristiques: caract,
							siteLies: []
						};
						produit.entete.idSite = produit.entete.sitesIds[0];

						const tmpGeneratedCc = await this.calculeIdentifiantNorme(produit);
						// Remove old Cc from current Mix Design
						await this.eqConcreteTreatment(1, formule.idProduit, 0, formule.listCc[i].idProduit);

						let ignoreCC = false;
						const libelleExists = await this.libelleExists(tmpGeneratedCc.libelle);
						if (libelleExists) {
							// Check producteur of existing cc
							// produit take id of existant cc
							produit = await this.getProduitIdByLibelle(tmpGeneratedCc.libelle);
							//vérifier si le CC trouvé a un code SAP
							if (isTraitementFormule) {
								const produitCC = await this.getProduitEnteteById(produit.id);
								if (!produitCC.code2) {
									ignoreCC = true;
								}
							}

							if (!ignoreCC) {
								const ccProducteurs = await this.getProducteurs(produit.id);

								let needProduct = true;

								ccProducteurs.forEach((prod) => {
									if (formule.idProducteur === prod.id) {
										return (needProduct = false);
									}
								});

								if (needProduct) {
									// Update: add site to CC
									await this.eqConcreteTreatment(0, produit.id, formule.idProducteur);
								}
							}
						} else {
							// Create new CC
							produit.entete.code = tmpGeneratedCc.code;
							produit.entete.libelle = tmpGeneratedCc.libelle;
							produit.entete.appelation = tmpGeneratedCc.appelation;
							produit.entete.idSite = formule.idProducteur;
							produit.siteLies.push({ libelleProducteur: formule.nomProducteur, producteurId: formule.idProducteur, DetailClient: [] });
							produit.id = await this.generateProduitNorme(produit, 'laboratoire');
						}

						if (!ignoreCC) {
							await this.eqConcreteTreatment(2, formule.idProduit, 0, produit.id);
						} // Add existing CC to current MD
						console.log('ok');
					}
				}
			}
		} catch (err) {
			throw err;
		}
	}

	async eqConcreteTreatment(enumAction, idProduit, idSite = 0, idProdCom = 0) {
		const result = await this.$http.get(
			`${serviceUrl}/eq-concrete-verif/${idProduit}?idSite=${idSite}&idProdCom=${idProdCom}&enumAction=${enumAction}`
		);
		return result.data;
	}

	async getFTPs(composant) {
		const url = `${__configuration.apiUrl}/massia/ftp-archivee/by-filtre`;
		const res = await this.$http.get(url, {
			params: {
				idProduit: JSON.stringify(composant.idElement),
				idProducteur: JSON.stringify(composant.idProducteur)
			}
		});
		return res.data;
	}

	/** site client lies au producteur */
	async getProducteurSiteLiesLabo(idCentrale) {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/site-lies-labo/${idCentrale}`);
		return res.data;
	}

	// codes raitement RNDTS (table TSYSCodeTraitement)
	async getCodesTraitements() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/gestion/inertes/codesTraitement`);
		return res.data;
	}

	async getAllEcoOrganismes() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/eco-organisme-inertes`);
		return res.data;
	}
	async getAssocies() {
		const res = await this.$http.get(`${__configuration.apiUrl}/massia/associes/headers`);
		return res.data;
	}
}
