const serviceUrl = `${__configuration.apiUrl}/massia/transport`;

export default class TransportService {
    static $inject = ['$http', 'notification', '$translate', 'localStorageService', 'AuthConstant'];

    constructor($http) {
        this.$http = $http;
    }

    async getAllTransports() {
        const res = await this.$http.get(serviceUrl);
        return res.data;
    }

    async updateTransport(data) {
        return this.$http.put(serviceUrl, data);
    }

    async deleteTransport(id) {
        return this.$http.delete(`${serviceUrl}/${id}`);
    }

    async disabledTransport(id) {
        return this.$http.post(`${serviceUrl}/${id}`);
    }
}
