const serviceUrl = `${__configuration.apiUrl}`;

export default class TestApiController {
    static $inject = ['$stateParams', 'notification', '$http'];

    constructor($stateParams, notification, $http) {
        this.$stateParams = $stateParams;
        this.notification = notification;
        this.$http = $http;
    }

    async $onInit() {
        this.tests = [];
        this.runTests();
    }

    async runTests() {
        this.testGetBanqueById(1);
        this.testGetChantierById(1);
        this.testGetPrestationById(1);
        this.testGetPrestationById(85851);
    }

    async execTest(url) {
        let apiResponse = {};

        try {
            apiResponse = await this.$http.get(url);
        } catch (ex) {
            apiResponse = { status: ex.status };
        }

        return apiResponse;
    }

    assertEqual(apiValue, testValue, contentValidation, errorMessage) {
        if (apiValue == testValue) {
            if (contentValidation.status != false) {
                contentValidation.status = true;
            }
        } else {
            contentValidation.status = false;
            contentValidation.info += errorMessage + ' (' + apiValue + ' != ' + testValue + ') | ';
        }
    }

    async testGetBanqueById(id) {
        const testName = 'getBanqueById';
        const testInfo = 'information sur le test';
        const contentValidation = { status: null, info: '' };
        const url = `${serviceUrl}/massia/banques/${id}/entete`;
        const apiResponse = await this.execTest(url);

        this.assertEqual(apiResponse.data.id, '1', contentValidation, "L'id' n'est pas bon");
        this.assertEqual(apiResponse.data.code, '00170', contentValidation, "Le code n'est pas bon");
        this.assertEqual(apiResponse.data.codeBanque, '123456', contentValidation, "Le codeBanque n'est pas bon");
        this.assertEqual(apiResponse.data.libelle, 'CREDIT AGRICOLE', contentValidation, "Le libelle n'est pas bon");

        this.tests.push({ name: testName, info: testInfo, status: apiResponse.status, contentValidation: contentValidation });
    }

    async testGetPrestationById(id) {
        const testName = 'getPrestationById';
        const testInfo = 'information sur le test';
        const contentValidation = { status: null, info: '' };
        const url = `${serviceUrl}/massia/prestations/${id}/entete`;
        const apiResponse = await this.execTest(url);

        this.tests.push({ name: testName, info: testInfo, status: apiResponse.status, contentValidation: contentValidation });
    }

    async testGetChantierById(id) {
        const testName = 'getChantierById';
        const testInfo = 'information sur le test';
        const contentValidation = { status: null, info: '' };
        const url = `${serviceUrl}/massia/chantiers/${id}/entete`;
        const apiResponse = await this.execTest(url);

        this.tests.push({ name: testName, info: testInfo, status: apiResponse.status, contentValidation: contentValidation });
    }
}
