(function (angular, undefined) {
    'use strict';

    angular.module('blocks.smart.table')
        .service('SortHelperService', SortHelperService);

    function SortHelperService(){
        var service = {
            ascending: ascending,
            descending: descending
        };

        return service;

        function ascending(predicate) {
            return {
                criterion: predicate,
                isDescending: false
            };
        }

        function descending(predicate) {
            return {
                criterion: predicate,
                isDescending: true
            };
        }
    }
})(angular);