(function () {
    'use strict';

    angular.module('blocks.validation').config(ModuleConfig);

    ModuleConfig.$inject = ['$translatePartialLoaderProvider'];

    /* @ngInject */
    function ModuleConfig($translatePartialLoaderProvider) {
        $translatePartialLoaderProvider.setPart('fr-FR', 'blocks.validation', {
            VALIDATION_NOTEMPTY: 'Ce champ est obligatoire',
            VALIDATION_EMAIL: "L'adresse mail n'est pas valide",
            VALIDATION_PHONE: "Le numéro de téléphone fixe n'est pas valide",
            VALIDATION_FAX: "Le numéro de fax n'est pas valide",
            VALIDATION_PHONEMOBILE: "Le numéro de téléphone mobile n'est pas valide",
            VALIDATION_NOT_INTEGER: "Le nombre n'est pas un entier",
            VALIDATION_NOT_INTEGER_NEGATIVE: "Le nombre n'est pas un entier négatif",
            VALIDATION_NOT_DECIMAL: "Le nombre n'est pas un décimal",
            VALIDATION_NOT_DECIMAL_NEGATIVE: "Le nombre n'est pas un décimal négatif",
            VALIDATION_DATE_NOT_VALID: "La date n'est pas valide",
            VALIDATION_LENGTH_EXCEEDED_25: '25 caractères max.',
            VALIDATION_TOO_LONG_LABEL: 'La longueur maximum du champ est 255 caractères',
            VALIDATION_TOO_LONG_3: 'La longueur maximum du champ est 3 caractères',
            VALIDATION_TOO_LONG_4: 'La longueur maximum du champ est 4 caractères',
            VALIDATION_TOO_LONG_7: 'La longueur maximum du champ est 7 caractères',
            VALIDATION_TOO_LONG_12: 'La longueur maximum du champ est 12 caractères',
            VALIDATION_TOO_LONG_15: 'La longueur maximum du champ est 15 caractères',
            VALIDATION_TOO_LONG_20: 'La longueur maximum du champ est 20 caractères',
            VALIDATION_TOO_LONG_25: 'La longueur maximum du champ est 25 caractères',
            VALIDATION_TOO_LONG_50: 'La longueur maximum du champ est 50 caractères',
            VALIDATION_TOO_LONG_100: 'La longueur maximum du champ est 100 caractères',
            VALIDATION_TOO_LONG_255: 'La longueur maximum du champ est 255 caractères',
            VALIDATION_TOO_LONG_COMMENT: 'La longueur maximum du champ est 256 caractères',
            VALIDATION_TOO_LONG_DESCRIPTION: 'La longueur maximum du champ est 511 caractères',
            VALIDATION_TOO_LONG: 'La longueur maximale du champ est dépassée',
            VALIDATION_INVALID_DATA: 'Les données saisies sont invalides',
            VALIDATION_DESCRIPTION_LENGTH: 'Vous avez dépassé 511 caractères',
            VALIDATION_DATE_GRATHER_THAN: 'La date de fin doit être supérieure ou égale à la date de début',
            VALIDATION_DATE_GRATHER_THAN_STRICT: 'La date de fin doit être supérieure à la date de début',
            VALIDATION_POSTALCODE: 'Le format du code postal est invalide',
            INVALID_PROFILE_PICTURE: 'Veuillez sélectionner un fichier',
            INVALID_MIME_TYPE: 'Le format du fichier est invalide',
            INVALID_PROFILE_PICTURE_SIZE: 'La taille du fichier excède la taille autorisée',
            VALIDATION_LENGTH: 'La taille maximale du champ est dépassée',
            DATE_VALIDATION_WARNING: "La date n'est pas valide",
            DATE_VALIDATION_COMPARE_WARNING: 'La chronologie est incorrecte',
            VALIDATION_GRATHER_THAN: 'La valeur de fin doit être supérieure ou égale à la valeur de début',
            VALIDATION_GRATHER_THAN_STRICT: 'La valeur de fin doit être supérieure à la valeur de début',
            VALIDATION_TIME_GRATHER_THAN: "L'heure de fin doit être supérieure ou égale à l'heure de début",
            VALIDATION_TIME_GRATHER_THAN_STRICT: "L'heure de fin doit être supérieure à l'heure de début",
            VALIDATION_HO_GRATHER_THAN_STRICT: 'HO doit être supérieure à la durée des affectations',
            VALIDATION_CODE_ALREADY_EXISTS: 'Le numéro de pièce existe déjà',
        });

        $translatePartialLoaderProvider.setPart('en-US', 'blocks.validation', {
            VALIDATION_NOTEMPTY: 'This field is mandatory',
            VALIDATION_EMAIL: 'The e-mail is invalid',
            VALIDATION_PHONE: 'Fixed phone number is not valid',
            VALIDATION_FAX: 'Fax number is not valid',
            VALIDATION_PHONEMOBILE: 'Mobile phone number is invalid',
            VALIDATION_NOT_INTEGER: 'The number is not an integer',
            VALIDATION_NOT_INTEGER_NEGATIVE: 'The number is not a negative integer',
            VALIDATION_NOT_DECIMAL: 'The number is not a decimal',
            VALIDATION_NOT_DECIMAL_NEGATIVE: 'The number is not a negative decimal',
            VALIDATION_DATE_NOT_VALID: 'The date is invalid',
            VALIDATION_LENGTH_EXCEEDED_25: '25 characters max.',
            VALIDATION_TOO_LONG_LABEL: "Field's max length is 255 characters",
            VALIDATION_TOO_LONG_3: "Field's max length is 3 characters",
            VALIDATION_TOO_LONG_4: "Field's max length is 4 characters",
            VALIDATION_TOO_LONG_7: "Field's max length is 7 characters",
            VALIDATION_TOO_LONG_12: "Field's max length is 12 characters",
            VALIDATION_TOO_LONG_15: "Field's max length is 15 characters",
            VALIDATION_TOO_LONG_20: "Field's max length is 20 characters",
            VALIDATION_TOO_LONG_25: "Field's max length is 25 characters",
            VALIDATION_TOO_LONG_50: "Field's max length is 255 characters",
            VALIDATION_TOO_LONG_100: "Field's max length is 100 characters",
            VALIDATION_TOO_LONG_255: "Field's max length is 255 characters",
            VALIDATION_TOO_LONG_COMMENT: "Field's max length is 256 characters",
            VALIDATION_TOO_LONG_DESCRIPTION: "Field's max length is 511 characters",
            VALIDATION_TOO_LONG: "Field's max length exceeded",
            VALIDATION_INVALID_DATA: 'Provided data is invalid',
            VALIDATION_DESCRIPTION_LENGTH: 'You have exceeded 511 characters',
            VALIDATION_DATE_GRATHER_THAN: 'End date must be greater or equal than start date',
            VALIDATION_DATE_GRATHER_THAN_STRICT: 'End date must be greater than start date',
            VALIDATION_POSTALCODE: 'Area code format is invalid',
            INVALID_PROFILE_PICTURE: 'Please select a file',
            INVALID_MIME_TYPE: 'Invalid file format',
            INVALID_PROFILE_PICTURE_SIZE: 'Maximum allowed file size exceeded',
            VALIDATION_LENGTH: "Field's max length exceeded",
            DATE_VALIDATION_WARNING: 'Invalid date',
            DATE_VALIDATION_COMPARE_WARNING: 'The chronology is incorrect',
            VALIDATION_GRATHER_THAN: 'The end value must be greater than or equal to the start value',
            VALIDATION_GRATHER_THAN_STRICT: 'The end value must be greater than the start value',
            VALIDATION_TIME_GRATHER_THAN: 'End time must be greater than or equal to start time',
            VALIDATION_TIME_GRATHER_THAN_STRICT: 'End time must be greater than start time',
            VALIDATION_HO_GRATHER_THAN_STRICT: 'OH must be greater than the duration of assignments',
            VALIDATION_CODE_ALREADY_EXISTS: 'The number already exists',
        });

        $translatePartialLoaderProvider.setPart('es-ES', 'blocks.validation', {
            VALIDATION_NOTEMPTY: 'Este campo es obligatorio',
            VALIDATION_EMAIL: 'La dirección de correo electrónico no es válida',
            VALIDATION_PHONE: 'El número de teléfono fijo no es válido',
            VALIDATION_FAX: 'El número de fax no es válido',
            VALIDATION_PHONEMOBILE: 'El número de teléfono móvil no es válido',
            VALIDATION_NOT_INTEGER: 'El número no es un entero',
            VALIDATION_NOT_INTEGER_NEGATIVE: 'El número no es un entero negativo',
            VALIDATION_NOT_DECIMAL: 'El número no es un decimal',
            VALIDATION_NOT_DECIMAL_NEGATIVE: 'El número no es un decimal negativo',
            VALIDATION_DATE_NOT_VALID: 'La fecha no es válida',
            VALIDATION_LENGTH_EXCEEDED_25: '25 carácteres máx.',
            VALIDATION_TOO_LONG_LABEL: 'La longitud máxima del campo es de 255 carácteres',
            VALIDATION_TOO_LONG_3: 'La longitud máxima del campo es de 3 carácteres',
            VALIDATION_TOO_LONG_4: 'La longitud máxima del campo es de 4 carácteres',
            VALIDATION_TOO_LONG_7: 'La longitud máxima del campo es de 7 carácteres',
            VALIDATION_TOO_LONG_12: 'La longitud máxima del campo es de 12 carácteres',
            VALIDATION_TOO_LONG_15: 'La longitud máxima del campo es de 15 carácteres',
            VALIDATION_TOO_LONG_20: 'La longitud máxima del campo es de 20 carácteres',
            VALIDATION_TOO_LONG_25: 'La longitud máxima del campo es de 25 carácteres',
            VALIDATION_TOO_LONG_50: 'La longitud máxima del campo es de 50 carácteres',
            VALIDATION_TOO_LONG_100: 'La longitud máxima del campo es de 100 carácteres',
            VALIDATION_TOO_LONG_255: 'La longitud máxima del campo es de 255 carácteres',
            VALIDATION_TOO_LONG_COMMENT: 'La longitud máxima del campo es de 256 carácteres',
            VALIDATION_TOO_LONG_DESCRIPTION: 'La longitud máxima del campo es de 511 carácteres',
            VALIDATION_TOO_LONG: 'La descripción supera la capacidad del campo',
            VALIDATION_INVALID_DATA: 'Los datos entrados son inválidos',
            VALIDATION_DESCRIPTION_LENGTH: 'Se han superado los 511 carácteres máximos disponibles',
            VALIDATION_DATE_GRATHER_THAN: 'La fecha de fin debe ser superior o igual a la fecha de inicio',
            VALIDATION_DATE_GRATHER_THAN_STRICT: 'La fecha de fin debe ser superior a la feche de inicio',
            VALIDATION_POSTALCODE: 'El formato del código postal no es válido',
            INVALID_PROFILE_PICTURE: 'Seleccione un expediente',
            INVALID_MIME_TYPE: 'El formato del expediente no es válido',
            INVALID_PROFILE_PICTURE_SIZE: 'El tamaño del expediente supera la capacidad autorizada',
            VALIDATION_LENGTH: 'El tamaño máximo del campo está superado',
            DATE_VALIDATION_WARNING: 'La fecha no es válida',
            DATE_VALIDATION_COMPARE_WARNING: 'La cronología es incorrecta',
            VALIDATION_GRATHER_THAN: 'El valor de fin debe ser superior o igual al valor de inicio',
            VALIDATION_GRATHER_THAN_STRICT: 'EL valor de fin debe ser superior al valor de inicio',
            VALIDATION_TIME_GRATHER_THAN: 'La hora de fin debe ser superior o igual a la hora de inicio',
            VALIDATION_TIME_GRATHER_THAN_STRICT: 'La hora de fin debe ser superior a la hora de inicio',
            VALIDATION_HO_GRATHER_THAN_STRICT: 'HO debe ser superior a la duración de las asignaciones',
            VALIDATION_CODE_ALREADY_EXISTS: 'El número de pieza ya existe',
        });
                
            $translatePartialLoaderProvider.setPart('nl-BE', 'blocks.validation', {
                VALIDATION_NOTEMPTY: 'Dit veld is verplicht',
                VALIDATION_EMAIL: "Het e-mailadres is ongeldig",
                VALIDATION_PHONE: "Vast nummer is ongeldig",
                VALIDATION_FAX: "Faxnummer is ongeldig",
                VALIDATION_PHONEMOBILE: "Mobiel telefoonnummer is ongeldig",
                VALIDATION_NOT_INTEGER: "Het getal is geen geheel getal",
                VALIDATION_NOT_INTEGER_NEGATIVE: "Het getal is geen negatief geheel getal",
                VALIDATION_NOT_DECIMAL: "Het getal is geen decimaalteken",
                VALIDATION_NOT_DECIMAL_NEGATIVE: "Het getal is geen negatief decimaal",
                VALIDATION_DATE_NOT_VALID: "De datum is ongeldig",
                VALIDATION_LENGTH_EXCEEDED_25: '25 tekens max.',
                VALIDATION_TOO_LONG_LABEL: 'De maximale lengte van het veld is 255 tekens',
                VALIDATION_TOO_LONG_3: 'De maximale lengte van het veld is 3 tekens',
                VALIDATION_TOO_LONG_4: 'De maximale lengte van het veld is 4 tekens',
                VALIDATION_TOO_LONG_7: 'De maximale lengte van het veld is 7 tekens',
                VALIDATION_TOO_LONG_12: 'De maximale lengte van het veld is 12 tekens',
                VALIDATION_TOO_LONG_15: 'De maximale lengte van het veld is 15 tekens',
                VALIDATION_TOO_LONG_20: 'De maximale lengte van het veld is 20 tekens',
                VALIDATION_TOO_LONG_25: 'De maximale lengte van het veld is 25 tekens',
                VALIDATION_TOO_LONG_50: 'De maximale lengte van het veld is 50 tekens',
                VALIDATION_TOO_LONG_100: 'De maximale lengte van het veld is 100 tekens',
                VALIDATION_TOO_LONG_255: 'De maximale lengte van het veld is 255 tekens',
                VALIDATION_TOO_LONG_COMMENT: 'De maximale lengte van het veld is 256 tekens',
                VALIDATION_TOO_LONG_DESCRIPTION: 'De maximale lengte van het veld is 511 tekens',
                VALIDATION_TOO_LONG: 'De maximale lengte van het veld wordt overschreden',
                VALIDATION_INVALID_DATA: 'De ingevoerde gegevens zijn ongeldig',
                VALIDATION_DESCRIPTION_LENGTH: 'U hebt meer dan 511 tekens',
                VALIDATION_DATE_GRATHER_THAN: 'De einddatum moet groter zijn dan of gelijk zijn aan de begindatum',
                VALIDATION_DATE_GRATHER_THAN_STRICT: 'De einddatum moet hoger zijn dan de begindatum',
                VALIDATION_POSTALCODE: 'Postcodenotatie is ongeldig',
                INVALID_PROFILE_PICTURE: 'Selecteer een bestand',
                INVALID_MIME_TYPE: 'De bestandsindeling is ongeldig',
                INVALID_PROFILE_PICTURE_SIZE: 'Bestandsgrootte overschrijdt toegestane grootte',
                VALIDATION_LENGTH: 'De maximale veldgrootte wordt overschreden',
                DATE_VALIDATION_WARNING: "De datum is ongeldig",
                DATE_VALIDATION_COMPARE_WARNING: 'De tijdlijn is onjuist',
                VALIDATION_GRATHER_THAN: 'De eindwaarde moet groter zijn dan of gelijk zijn aan de beginwaarde',
                VALIDATION_GRATHER_THAN_STRICT: 'De eindwaarde moet groter zijn dan de beginwaarde',
                VALIDATION_TIME_GRATHER_THAN: "De eindtijd moet groter zijn dan of gelijk zijn aan de begintijd",
                VALIDATION_TIME_GRATHER_THAN_STRICT: "De eindtijd moet hoger zijn dan de begintijd",
                VALIDATION_HO_GRATHER_THAN_STRICT: 'HO moet langer zijn dan de duur van de opdrachten',
                VALIDATION_CODE_ALREADY_EXISTS: 'Het onderdeelnummer bestaat al',
            });

        $translatePartialLoaderProvider.addPart('blocks.validation');
    }
})();
