import MassiaApplicationService from '../../app/massia/layout/components/massia-application/massia.application.service';

SeuilBetGachValidator.$inject = ['validator', 'MassiaApplicationService'];
export function SeuilBetGachValidator(validator: any, MassiaApplicationService: MassiaApplicationService) {
    const instance = new validator();

    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;
}
