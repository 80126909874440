Array.prototype.move = function (old_index, new_index) {
    while (old_index < 0) {
        old_index += this.length;
    }
    while (new_index < 0) {
        new_index += this.length;
    }
    if (new_index >= this.length) {
        let k = new_index - this.length;
        while (k-- + 1) {
            this.push(undefined);
        }
    }
    this.splice(new_index, 0, this.splice(old_index, 1)[0]);
    return this; // for testing purposes
};

export default class ProduitNiveauxTrameService {
    static $inject = ['notification', '$translate', '$http', 'AcTreeviewCommunicationService', 'TypesNiveauxHierarchique'];

    constructor(notification, $translate, $http, AcTreeviewCommunicationService, TypesNiveauxHierarchique) {
        let treeviewName;
        const baseUrl = `${__configuration.apiUrl}/massia/domaines/produits/niveaux-hierarchiques`;
        const rootId = 'root';

        this.trame = undefined;

        this.setTreeviewName = (name) => {
            treeviewName = name;
        };

        // permet de sortir les types disponibles pour un item donné
        this.getAvailableTypes = async () => {
            return angular.copy(_.sortBy(_.values(TypesNiveauxHierarchique), 'libelle'));
        };

        // on met tout dans le controller à cause de la façon dont fonctionne le treeview
        this.getRootNodes = async () => {
            try {
                const res = await $http.get(baseUrl);
                this.SetTrame(res.data);
                return angular.copy(this.trame);
            } catch (ex) {
                notification.error(ex.data);
            }
        };

        //adaptation des resultats venant du serveur, en ajoutant un noeud de "root" au dessus
        this.SetTrame = (niveaux) => {
            //selon trame.json, il faut ajouter un noeud 'root' comme la racine (pour tous les noeuds du premier niveau)
            niveaux.forEach((x) => (x.parentId = rootId));
            this.trame = [
                {
                    id: 'root',
                    expanded: true,
                    parentId: null,
                    label: 'Niveaux',
                    position: 0,
                    type: 'root',
                    typeId: 1,
                    items: niveaux
                }
            ];
        };

        this.getNode = async (id) => {
            try {
                //cette methode est aussi appellee quand on souhaite rafraichir le noeud "root" qui est un noeud artificiel et a ete ajoute manuellement dans la methode SetTrame
                //du coup, normalement pour les noeuds normaux, id en entree de cette methode est un int, mais exceptionnellement pour le noeud root, son id est 'root'
                //dans ce cas-la, on traite differamment l'appel
                if (id === rootId) {
                    const res = await this.getRootNodes();
                    //le resultat de retour de getRootNodes est une collection des noeuds racines (mais concretement dans notre context, une collection ayant comme le seul noeud de racine "root")
                    //pour nous, ce qu'il faut n'est que la racine 'root' au lieu d'etre la collection
                    return res[0];
                }
                const url = `${baseUrl}/${id}`;
                const res = await $http.get(url);
                const node = res.data;

                //suite aussi a l'ajoute du noeud root, quand on rafraichit un noeud de la racine (niveau directement sous le noeud root),
                //le serveur retourne null comme son parentId
                //pour adapter a notre context, nous devons setter la valeur 'root' a son parentId manuellement
                if (node.parentId === undefined || node.parentId === null) {
                    node.parentId = rootId;
                }

                //une fois on recupere un node, nous devons aussi mettre a jour la trame locale
                this.updateLocalTrame(node);

                return angular.copy(node);
            } catch (ex) {
                notification.error(ex.data);
                return false;
            }
        };

        this.getChildrenByNodeId = async (id) => {
            try {
                if (id === rootId) {
                    //quand on demande de recuperer les enfants de "root", on retourne en effet toute l'arbo reel
                    //en effet, le noeud root est un noeud artificiel que nous concatenons au front
                    return this.trame[0].items;
                }
                const url = `${baseUrl}/${id}/sous-niveaux-hierarchiques`;
                const niveauxEnfants = await $http.get(url);

                //une fois on recupere un node, nous devons aussi mettre a jour la trame locale
                this.updateLocalTrameWithChildrenNodes(id, niveauxEnfants.data);

                return niveauxEnfants.data;
            } catch (ex) {
                notification.error(ex.data);
                return false;
            }
        };

        this.createNode = async (parent, node) => {
            try {
                await $http.post(baseUrl, {
                    //quand parent id est 'root', cela represente la racine que nous avons ajoute dans SetTrame
                    idParent: parent.id === rootId ? null : parent.id,
                    code: node.code,
                    label: node.nom,
                    position: parent.items.length,
                    type: node.typeId
                });

                // on rafraichit le parent
                AcTreeviewCommunicationService.raiseNodeRefreshFunction(treeviewName, parent.id, true, parent);

                //todo : a voir si utile de retourner le node ajouté
                return angular.copy(node);
            } catch (ex) {
                notification.error(ex.data);
                return false;
            }
        };

        this.renameNode = async (node = {}) => {
            try {
                if (node.label.length <= 100) {
                    await this.updateNode(node);
                } else {
                    notification.error($translate.instant('VALIDATION_TOO_LONG_100'));
                }

                return angular.copy(node);
            } catch (ex) {
                notification.error(ex.data);
                return false;
            }
        };

        this.moveNode = async (direction, node) => {
            try {
                const parentNode = _.findDeep(this.trame, { id: node.parentId });
                //on ne peut pas remonter un noeud qui est deja le premier noeud, ou descendre un noeud qui est deja le dernier noeud
                if ((direction === 'up' && node.position > 0) || (direction === 'down' && node.position < parentNode.items.length - 1)) {
                    switch (direction) {
                        case 'up':
                            node.position--;
                            break;
                        case 'down':
                            node.position++;
                            break;
                    }

                    await this.updateNode(node, parentNode);
                }

                return angular.copy(node);
            } catch (ex) {
                notification.error(ex.data);
                return false;
            }
        };

        this.updateNode = async (node, parentNode) => {
            try {
                parentNode = parentNode || _.findDeep(this.trame, { id: node.parentId });

                const url = `${baseUrl}/${node.id}`;

                await $http.put(url, {
                    id: node.id,
                    label: node.label,
                    position: node.position
                });

                AcTreeviewCommunicationService.raiseNodeRefreshFunction(treeviewName, parentNode.id, true, parentNode);

                return angular.copy(node);
            } catch (ex) {
                notification.error(ex.data);
                return false;
            }
        };

        this.removeNode = async (node) => {
            try {
                const url = `${baseUrl}/${node.id}`;
                const parentNode = _.findDeep(this.trame, { id: node.parentId });

                await $http.delete(url);

                // on rafraichit le parent
                AcTreeviewCommunicationService.raiseNodeRefreshFunction(treeviewName, parentNode.id, true, parentNode);

                return angular.copy(node);
            } catch (ex) {
                notification.error(ex.data);
            }
        };

        this.codeExists = async (code) => {
            const url = `${baseUrl}/code-unicity/${code}`;
            const result = await this.$http.get(url);
            return result.data;
        };

        //mettre a jour le this.trame
        this.updateLocalTrame = (newNode) => {
            const item = _.findDeep(this.trame, { id: newNode.id, parentId: newNode.parentId });

            if (item) {
                angular.extend(item, newNode);
            }
        };

        //mettre a jour le this.trame
        this.updateLocalTrameWithChildrenNodes = (parentNodeId, childrenNodes) => {
            const item = _.findDeep(this.trame, { id: parentNodeId });

            if (item) {
                item.items = childrenNodes;
            }
        };
    }
}
