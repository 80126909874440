import LoginData from './login.data.model';

export default class LoginController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'LoginService',
        'MassiaAuthService',
        'MassiaDBService',
        'MassiaApplicationService',
        'notification',
        '$window'
    ];

    constructor($scope, $state, $stateParams, LoginService, MassiaAuthService, MassiaDBService, MassiaApplicationService, notification, $window) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.LoginService = LoginService;
        this.MassiaAuthService = MassiaAuthService;
        this.MassiaDBService = MassiaDBService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.notification = notification;
        this.$window = $window;
    }

    async $onInit() {
        this.loading = false;
        this.hidePassword = true;
        this.loginData = new LoginData();
        this.availableDatabases = [];

        this.handleSessionExpired();
        this.getAvailableDatabases();

        this.MassiaAuthService.deleteStorageInfo();
    }

    async getAvailableDatabases() {
        this.startLoading();
        try {
            this.availableDatabases = await this.MassiaDBService.getAvailableDB();

            if (this.availableDatabases.length === 1) {
                this.loginData.database = this.availableDatabases[0];
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async login(action) {
        if (!this.loginData.isValid()) {
            this.$scope.$broadcast('loginDataValidations');
            return;
        }

        if (this.loginData && this.loginData.userName && this.loginData.userName.toLowerCase() === __configuration.godUser) {
            this.$window.onbeforeunload = undefined;
            this.$window.onunload = undefined;
            this.$window.onload = undefined;
        }

        this.startLoading();
        try {
            const result = await this.MassiaAuthService.login(this.loginData, this.MassiaApplicationService.getApplication(), action);
            if (result && result === 'LOGINERROR000002') {
                this.forceConnexionButton = true;
            } else {
                this.goToNextState();
            }
        } catch (ex) {
            this.notification.error(ex.data && ex.data.error_description ? ex.data.error_description : 'UNKNOWN_ERROR');
        } finally {
            this.stopLoading();
        }
    }

    handleSessionExpired() {
        // si on arrive sur le login suite à une erreur d'authentification
        if (this.$stateParams.sessionExpired) {
            // on prévient l'utilisateur
            this.notification.info('SESSION_EXPIRED');
        }
    }

    goToNextState() {
        const redirection = this.LoginService.getPostLoginRedirection();
        // si on arrive sur le login depuis un autre state et une erreur d'authentification
        if (this.$stateParams.originalState) {
            // on essaie d'y retourner
            this.$state.go(this.$stateParams.originalState.name, this.$stateParams.originalStateParams);
        } else {
            this.$state.go(`${this.MassiaApplicationService.getApplication()}.home`);
        }
    }

    toggleShowPassword() {
        this.hidePassword = !this.hidePassword;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
