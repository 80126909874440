import validatorBuilder from 'validator-builder';
import CommandeEntete from '../commande-entete/commande.entete.model';

let commandeValidator = null;

export default class Commande {
    constructor(data) {
        if (data) {
            this.entete = new CommandeEntete(data);
        } else {
            this.entete = new CommandeEntete();
        }
    }

    isValid() {
        commandeValidator = commandeValidator || validatorBuilder.getInstanceFor('Commande');
        const validationResults = commandeValidator.validate(this);
        return validationResults.isValid;
    }
}
