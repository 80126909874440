export default class PaiementsTypesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getPaiementTypes() {
        const url = 'assets/enum/paiements/types.json';
        const res = await this.$http.get(url);
        return res.data;
    }
    async getType(id) {
        const url = 'assets/enum/paiements/idType.json';
        const res = await this.$http.get(url);
        return res.data[id];
    }
}
