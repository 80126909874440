export default class OffreCommandeController {
	/* @ngInject */
	constructor($state, $stateParams, OffresCommunicationService, OffresService, notification, moment, globalizationManagementService, $uibModal) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.OffresCommunicationService = OffresCommunicationService;
		this.OffresService = OffresService;
		this.notification = notification;
		this.moment = moment;
		this.$uibModal = $uibModal;

		this.isCompteClientGenerique = false;
		this.clientAccordionOpen = true;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}
	debug() {
		console.log(this);
	}
	async $onInit() {
		this.unregisterReinit = this.OffresCommunicationService.registerReinit(() => this.initForm());
		this.proposition = await this.OffresService.getProposition(this.$stateParams.id);
		this.clients = await this.OffresService.getClients(this.proposition.offre.idSociete);
		if (this.proposition.offre.prospectId && this.$stateParams.clientId == undefined) {
			this.prospect = await this.OffresService.getProspect(this.proposition.offre.prospectId);
		} else {
			if (this.$stateParams.clientId != undefined) {
				this.clientId = this.$stateParams.clientId;
			} else {
				this.clientId = this.proposition.offre.idClient;
			}
		}

		if (this.$stateParams.chantierId) {
			this.chantierId = this.$stateParams.chantierId;

			// si proposition est franco
			// faire un appel api pour passer le chantier en franco et le transport à facturer dans le centre de gestion !
			if (this.proposition.isFranco) {
				if (this.proposition.entreeSortie == 0 || this.proposition.entreeSortie == 2) {
					await this.OffresService.setChantierParameter(this.chantierId, this.moment().format(this.dateFormat), 'transpFactureDefaut', 0);
					await this.OffresService.setChantierParameter(this.chantierId, this.moment().format(this.dateFormat), 'transpFranco', 0);
				}

				if (this.proposition.entreeSortie == 1 || this.proposition.entreeSortie == 2) {
					await this.OffresService.setChantierParameter(this.chantierId, this.moment().format(this.dateFormat), 'transpFactureDefaut', 1);
					await this.OffresService.setChantierParameter(this.chantierId, this.moment().format(this.dateFormat), 'transpFranco', 1);
				}
			}
		}

		this.personneId = null;
		if (this.$stateParams.personneId) {
			this.personneId = this.$stateParams.personneId;
		}

		this.getChantiersByClient();
	}

	async getChantiersByClient() {
		this.clients.forEach((el) => {
			if (el.id == this.clientId && el.isGenerique) {
				this.isCompteClientGenerique = true;
			} else if (el.id == this.clientId && !el.isGenerique) {
				this.isCompteClientGenerique = false;
			}
		});
		if (this.clientId) {
			this.chantiers = null;
			this.chantiers = await this.OffresService.getChantiers(this.clientId);
		}
	}

	$onDestroy() {
		this.unregisterReinit();
	}

	annuler() {
		this.$state.go('offres.edit', { id: this.proposition.offre.id });
	}

	async orderProposition() {
		try {
			/////////// ici il faut passer le clientId et le Chantier Id
			const idCommande = await this.OffresService.orderProposition(
				this.$stateParams.id,
				this.clientId,
				this.chantierId,
				this.proposition.offre.idSociete,
				this.personneId
			);
			this.$state.go('commandes.edit', { id: idCommande.data });
		} catch (ex) {
			this.notification.error('OFFRES.COMMANDE_NOT_CREATED');
		}
	}

	async openClientDivers() {
		try {
			const prospect = this.prospect;
			const res = await this.$uibModal.open({
				template:
					'<client-divers-form modal-instance="$ctrl.uibModalInstance" prospect="$ctrl.prospect" from-offre-commande="$ctrl.fromOffre"></client-divers-form>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.fromOffre = true;
						$ctrl.uibModalInstance = $uibModalInstance;
						$ctrl.prospect = prospect;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl',
				backdrop: false
			}).result;

			if (res) {
				this.clientAccordionOpen = false;
			}
		} catch (reason) {
			console.log(reason);
			if (reason) {
				console.error(reason);
			}
		}
	}
}
