import MiniFormContactController from './mini.form.contact.controller';

export default {
    bindings: {
        newContact: '=',
        client: '=',
        selectNewContact: '&'
    },
    template: require('./mini.form.contact.html'),
    controller: MiniFormContactController
};
