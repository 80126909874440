import RemiseFinAnnee from './remise.fin.annee.model';

export default class RemiseFinAnneeFormController {
    static $inject = ['_', '$scope', '$state', '$stateParams', '$translate', 'notification', '$timeout', 'RemisesFinAnneeService', '$filter'];

    constructor(_, $scope, $state, $stateParams, $translate, notification, $timeout, RemisesFinAnneeService, $filter) {
        this.$scope = $scope;
        this.$state = $state;
        this.$translate = $translate;
        this.$stateParams = $stateParams;
        this.notification = notification;
        this.RemisesFinAnneeService = RemisesFinAnneeService;
        this.$timeout = $timeout;
        this._ = _;
        this.$filter = $filter;
    }

    async $onInit() {
        this.societes = await this.RemisesFinAnneeService.getSocietes();
        this.societeClients = await this.RemisesFinAnneeService.getSocieteClients();
        this.loading = false;
        this.canEdit = true;
        this.editCode = undefined;
        /*this.sitesClientsLoaded = [];
        this.produitsLoaded = [];*/
        this.reset();
    }

    // Public
    async reset() {
        this.startLoading();

        let data = {};
        this.getModes();

        if (this.$stateParams.id) {
            try {
                data = await this.RemisesFinAnneeService.getRemiseFinAnneeById(this.$stateParams.id);
            } catch (ex) {
                this.notification.error(ex.data || ex);
                this.annuler();
            }
        }
        this.canEdit = data.canEdit !== false;
        this.remiseFinAnnee = new RemiseFinAnnee(data);
        await this.loadSites();
        // await this.loadProduits();
        // await this.loadChantiers();
        if (this.remiseFinAnnee.code) {
            this.editCode = this.remiseFinAnnee.code;
            this.checkCodeUnicity(this.editCode);
        }

        const tm = this.$timeout(() => {
            this.stopLoading();
            this.$timeout.cancel(tm);
        });
    }

    async appliquer() {
        const id = await this.sauvegarder();
        /*if (id && this.remiseFinAnnee.id) {
            this.reset();
        } else*/ if (id) {
            this.$state.go('remises-fin-annee.edit', { id: id });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    annuler() {
        this.$state.go('remises-fin-annee.list');
    }

    async checkCodeUnicity(code) {
        if (code !== this.editCode) {
            this.remiseFinAnnee.codeExists = null;

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.remiseFinAnnee.codeExists = await this.RemisesFinAnneeService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            }
        } else {
            this.remiseFinAnnee.codeExists = false;
        }
    }

    async loadSites() {
        if (this.remiseFinAnnee.societeId) {
            this.sitesCommerciaux = await this.RemisesFinAnneeService.getSitesCommerciaux(this.remiseFinAnnee.societeId);
            if (this.remiseFinAnnee.societeClientId) {
                this.sitesClients = await this.RemisesFinAnneeService.getSitesClientsBySociete(this.remiseFinAnnee.societeClientId);
            } else {
                this.sitesClients = await this.RemisesFinAnneeService.getSitesClients(this.remiseFinAnnee.societeId);
            }
            //charger les clients sélectionnés en modification
            if (this.remiseFinAnnee && this.remiseFinAnnee.remiseFinAnneeClients) {
                for (let i = 0; i < this.remiseFinAnnee.remiseFinAnneeClients.length; i++) {
                    var id = this.remiseFinAnnee.remiseFinAnneeClients[i].clientId;
                    const cli = this.sitesClients.find(function (elt) {
                        return elt.id == id;
                    });
                    if (cli) {
                        cli.selected = true;
                    }
                }
            }
            await this.loadProduits();
            await this.loadChantiers();
        }
    }

    async loadProduits() {
        if (this.remiseFinAnnee.societeId) {
            this.allProduits = await this.RemisesFinAnneeService.getProduits(this.remiseFinAnnee.societeId, this.remiseFinAnnee.siteCommercialId);
            this.produits = await this.prepareListeElementsLies(this.allProduits);
            //charger les produits sélectionnés en modification
            if (this.remiseFinAnnee && this.remiseFinAnnee.remiseFinAnneeProduits) {
                for (let i = 0; i < this.remiseFinAnnee.remiseFinAnneeProduits.length; i++) {
                    var id = this.remiseFinAnnee.remiseFinAnneeProduits[i].siteProductId;
                    const pdt = this.produits.find(function (elt) {
                        return elt.id == id;
                    });
                    if (pdt) {
                        pdt.selected = true;
                    }
                }
            }
        }
    }

    async loadChantiers() {
        if (this.remiseFinAnnee.societeId) {
            const listeClientIds = [];
            const listeToParse = this.remiseFinAnnee.remiseFinAnneeClients || [];

            if (this.remiseFinAnnee.tousClients) {
                // on ne fait rien
            } else {
                for (let index = 0; index < listeToParse.length; index++) {
                    const element = listeToParse[index];
                    listeClientIds.push(element.clientId);
                }

                this.chantiers = await this.RemisesFinAnneeService.getChantiers(this.remiseFinAnnee.societeId, listeClientIds);

                //charger les chantiers sélectionnés en modification
                if (this.remiseFinAnnee && this.remiseFinAnnee.remiseFinAnneeChantiers) {
                    for (let i = 0; i < this.remiseFinAnnee.remiseFinAnneeChantiers.length; i++) {
                        var id = this.remiseFinAnnee.remiseFinAnneeChantiers[i].chantierId;
                        const cha = this.chantiers.find(function (elt) {
                            return elt.id == id;
                        });
                        if (cha) {
                            cha.selected = true;
                        }
                    }
                }
            }
        }
    }

    async prepareListeElementsLies(elementsATraiter) {
        const elementsSelectionnablesOrdonnes = this.$filter('orderBy')(elementsATraiter, 'producteurLibelle');
        let tmp = null;
        const temp = [];
        for (let index = 0; index < elementsSelectionnablesOrdonnes.length; index++) {
            const currentValue = elementsSelectionnablesOrdonnes[index];
            const toReturn = [];

            //#region Gestion des groupes
            if (tmp == null) {
                //creation du premier groupe
                tmp = currentValue.producteurId;
                toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
            } else {
                if (tmp != currentValue.producteurId) {
                    //création des autres groupes
                    toReturn.push({ msGroup: false });
                    tmp = currentValue.producteurId;
                    toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
                }
            }
            //#endregion
            currentValue.libelle = currentValue.produitCode + ' - ' + currentValue.produitLibelle;
            currentValue.selected = false;
            currentValue.id = currentValue.id;
            toReturn.push(currentValue);

            if (index == elementsSelectionnablesOrdonnes.length - 1) {
                toReturn.push({ msGroup: false });
            }
            temp.push(toReturn);
        }
        const sorties = _.flatten(temp);
        return new Promise((resolve) => resolve(sorties));
    }

    saveSelectedClients() {
        this.remiseFinAnnee.remiseFinAnneeClients = [];
        if (this.selectedClients) {
            for (let i = 0; i < this.selectedClients.length; i++) {
                const newClient = { id: -1, clientId: this.selectedClients[i].id, client: this.selectedClients[i].libelle };
                this.remiseFinAnnee.remiseFinAnneeClients.push(newClient);
            }
        }
        this.loadChantiers();
    }

    saveSelectedProduits() {
        this.remiseFinAnnee.remiseFinAnneeProduits = [];
        if (this.selectedProduits) {
            for (let i = 0; i < this.selectedProduits.length; i++) {
                const newProduit = { id: -1, siteProductId: this.selectedProduits[i].id };
                this.remiseFinAnnee.remiseFinAnneeProduits.push(newProduit);
            }
        }
    }

    saveSelectedChantiers() {
        this.remiseFinAnnee.remiseFinAnneeChantiers = [];
        if (this.selectedChantiers) {
            for (let i = 0; i < this.selectedChantiers.length; i++) {
                const newChantier = { id: -1, chantierId: this.selectedChantiers[i].id, chantier: this.selectedChantiers[i].libelle };
                this.remiseFinAnnee.remiseFinAnneeChantiers.push(newChantier);
            }
        }
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();

            try {
                let id = false;

                if (this.remiseFinAnnee.id) {
                    await this.RemisesFinAnneeService.updateRemiseFinAnnee(this.remiseFinAnnee);
                    this.notification.success('REMISES_FIN_ANNEE.UPDATED');
                    id = this.remiseFinAnnee.id;
                } else {
                    id = await this.RemisesFinAnneeService.createRemiseFinAnnee(this.remiseFinAnnee);
                    this.notification.success('REMISES_FIN_ANNEE.CREATED');
                }
                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    addQuantite() {
        const newQte = { id: -1, quantite: this.newQuantite, remise: this.newRemise };
        if (!this.remiseFinAnnee.remiseFinAnneeQuantites) {
            this.remiseFinAnnee.remiseFinAnneeQuantites = [];
        }
        this.remiseFinAnnee.remiseFinAnneeQuantites.push(newQte);
        this.newQuantite = undefined;
        this.newRemise = undefined;
    }

    removeQuantite(qte) {
        const i = this.remiseFinAnnee.remiseFinAnneeQuantites.indexOf(qte);
        this.remiseFinAnnee.remiseFinAnneeQuantites.splice(i, 1);
    }

    async getModes() {
        if (!this.listeModes) {
            this.startLoading();
            try {
                this.listeModes = await this.RemisesFinAnneeService.getModes();
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            } finally {
                this.stopLoading();
            }
        }
    }

    checkValidity() {
        if (!this.remiseFinAnnee.isValid() || this.remiseFinAnnee.codeExists) {
            this.$scope.$broadcast('RemiseFinAnneeValidations');
            return false;
        }

        return true;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
