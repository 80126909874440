import validatorBuilder from 'validator-builder';

let commandeLignePrestationValidator = null;

export default class CommandeLignePrestation {
    constructor(prestation, produit) {
        this.id = -1;
        this.prestation = prestation.libelle;
        this.prestationId = prestation.id;
        this.siteCommercial = produit.siteCommercial;
        this.siteCommercialId = produit.siteCommercialId;
        this.producteur = produit.producteur;
        this.producteurId = produit.producteurId;
        this.produitId = produit.produitId;
        this.quantiteCommandee = 1;
        this.typeES = produit.typeES;
        this.typePrestationLie = '2';
        this.prixStandard = 0;
        this.uniteLibelle = prestation.unite;
    }

    isValid() {
        commandeLignePrestationValidator = commandeLignePrestationValidator || validatorBuilder.getInstanceFor('CommandeLignePrestation');
        const validationResults = commandeLignePrestationValidator.validate(this);
        return validationResults.isValid;
    }
}
