import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-accordion',
    bindings: {
        isOpen: '<',
        validation: '<',
        header: '@'
    },
    transclude: true,
    template: require('./massia.accordion.tpl.html')
})
export class MassiaAccordionController {
    constructor($translate) {
        this.$translate = $translate;
    }

    $onInit() {}
}

MassiaAccordionController.$inject = ['$translate'];
