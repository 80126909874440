import validatorBuilder from 'validator-builder';

let referenceControleValidator = null;

export default class ReferenceControle {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.typeId = data.typeId || null;
        this.familleId = data.familleId || null;
        this.familleCode = data.familleCode || null;
        this.codeExists = data.codeExists || null;
        this.dateDebut = data.dateDebut;
        this.dateFin = data.dateFin;
        this.sitesProducteurs = data.sitesProducteurs || [];
        this.sitesProducteursIds = data.sitesProducteursIds || [];
        this.mesures = data.mesures || [];
        this.typeMesureId = 0;
        this.idNorme = data.idNorme || null;
        this.caracteristiques = data.caracteristiques || [];
        this.idLogo = data.idLogo;
        this.sitesClients = data.sitesClients || [];
        this.sitesClientsIds = data.sitesClientsIds || [];
        this.produits = data.produits || [];
        this.produitsIds = data.produitsIds || [];
        this.normeNorme = data.normeNorme || null;
        this.nombreResultatsN = data.nombreResultatsN || null;
        this.categorie = data.categorie;
    }

    isValid() {
        referenceControleValidator = referenceControleValidator || validatorBuilder.getInstanceFor('ReferenceControle');
        const validationResults = referenceControleValidator.validate(this);
        return validationResults.isValid;
    }
}
