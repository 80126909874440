export default class ChantierComposantController {
    static $inject = ['$scope', '$stateParams', 'ChantiersCommunicationService', 'ChantiersService', 'notification', '$uibModal'];

    constructor($scope, $stateParams, ChantiersCommunicationService, ChantiersService, notification, $uibModal) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.ChantiersCommunicationService = ChantiersCommunicationService;
        this.ChantiersService = ChantiersService;
        this.notification = notification;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.unregister = this.$scope.$watch(
            () => this.isOpen,
            () => {
                if (this.isOpen && !this.chantiers) {
                    this.loadChantiers();
                }
            },
            true
        );
    }

    $onDestroy() {
        this.unregister();
    }

    async loadChantiers() {
        if (this.id) {
            this.startLoading();
            try {
                this.chantiers = await this.ChantiersService.getChantierComposant(this.id, this.domaine);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            }
            this.stopLoading();
        }
    }

    async exporter() {
        try {
            this.startLoading();
            this.isLoadExport = true;
            const fileName = 'Liste_chantiers';
            let resultat = undefined;
            try {
                resultat = await this.ChantiersService.exportChantierComposants(this.id, this.domaine);
            } catch (ex) {
                //si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
                const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
                this.notification.error('CHANTIERS.' + msgException);
                return false;
            }
            if (resultat) {
                const data = resultat.data;
                const status = resultat.status;
                let headers = resultat.headers;
                headers = headers();

                const contentType = headers['content-type'];

                const linkElement = document.createElement('a');
                try {
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    linkElement.setAttribute('download', fileName + '.xlsx');

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                } catch (ex) {
                } finally {
                }
                return true;
            }
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        } finally {
            this.stopLoading();
            this.isLoadExport = false;
        }
    }

    openChantier(chantier) {
        this.$uibModal
            .open({
                template: '<chantier-form modal-instance="$ctrl.uibModalInstance" id-chantier="$ctrl.idChantier"></chantier-form>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.uibModalInstance = $uibModalInstance;
                        $ctrl.idChantier = chantier;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'xxl'
            })
            .result.then(
                async function (result) {
                    // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                    // console.info(result);
                    if (result) {
                    }
                },
                function (reason) {}
            );
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
