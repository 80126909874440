DomainesEditController.$inject = ['DomainesService', 'notification', '_'];

export default function DomainesEditController(DomainesService, notification, _) {
    /* jshint validthis: true */
    const vm = this;

    // Propriétés
    vm.loading = true;
    vm.droits = [];
    vm.displayDomaines = [];

    vm._ = _;

    // Méthodes

    // Evènements
    vm.$onInit = onInit;

    // Méthodes publiques
    function onInit() {
        vm.loading = false;
        vm.domaines = vm.domaines || [];
        vm.displayDroits = vm.displayDroits !== false;

        if (vm.displayDroits !== false) {
            vm.droits = DomainesService.getDroits();
        }

        vm.onDelete = vm.onDelete || angular.noop;
        vm.onUpdate = vm.onUpdate || angular.noop;
    }
}
