import Config from './config/synthese.config';
import Route from './config/synthese.routes';

//Import Module
import SyntheseServiceModule from './services';
import ModelesModule from './components/modeles-selection';
import SyntheseModule from './components/synthese';

//Validator
import ModelSelectionValidator from './validators/synthesemodeles.validator';
import { Champ } from './config/synthese.champ.enum';

const moduleName = 'app.massia.common.synthese';

angular
    .module(moduleName, [SyntheseServiceModule, ModelesModule, SyntheseModule])
    .config(Config)
    .config(Route)
    .factory('ModeleSelectionValidator', ModelSelectionValidator)
    .constant('EnumChamp', Champ);

export default moduleName;
