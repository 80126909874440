import UserPortail from '../../../models/UserPortail';
import AccessPortail from '../../../models/AccessPortail';
import Contact from '../../../../personnes/components/mini-form/contact/mini.form.contact.model';

export default class UserAddController {
    constructor(
        $scope,
        $uibModal,
        PersonnesService,
        SitesService,
        UtilisateurPortailService,
        notification,
        $stateParams,
        RouterHistoryService,
        $state,
        DomainPortailService,
        GroupPortailService,
        MassiaApplicationService,
        ChantiersService
    ) {
        this.$scope = $scope;
        this.modal = $uibModal;
        this.PersonnesService = PersonnesService;
        this.SitesService = SitesService;
        this.UtilisateurPortailService = UtilisateurPortailService;
        this.notification = notification;
        this.$stateParams = $stateParams;
        this.RouterHistoryService = RouterHistoryService;
        this.$state = $state;
        this.DomainPortailService = DomainPortailService;
        this.GroupPortailService = GroupPortailService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.ChantiersService = ChantiersService;
    }
    login = null;
    domainsSelect = [];
    typeContact = true;
    chauffeur;
    async $onInit() {
        this.applicationGestion = __configuration.licenceGest;
        this.currentAppGestion = this.MassiaApplicationService.getApplication() == 'gestion';

        this.newContact = new Contact();
        this.clientSelect = null;
        this.loading = true;

        //* si le compte portail concerne un chauffeur, affiche uniquement le domaine BL
        const that = this;
        this.$scope.$watch(
            '$ctrl.chauffeur',
            function (newValue, oldValue) {
                if (newValue != oldValue) {
                    that.restrictDomains();
                }
            },
            true
        );
        //? toujours true si les groupe de droits ne sont pas utilisés
        this.overideAccess = true;
        //? groupes portail non utilisés pour le moment
        // this.groups = (await this.GroupPortailService.getAll()).data.items;
        this.edit = this.$stateParams.id ? true : false;
        try {
            //* liste des domaines
            this.domains = await this.getDomain();
            this.domainsCopy = angular.copy(this.domains);
            //* liste des sites clients
            this.sitesClients = (await this.SitesService.getSitesClients([], [], { skip: 0, take: 0 })).items;

            //* contacts Massia
            this.users = await this.PersonnesService.getAllUsers('contact');
            //* filtre la liste des contacts → affiche uniquement les contacts n'ayant pas de compte portail (+ contact lié a l'utiliateur affiché si edit)
            this.usersCopy = angular.copy(this.users);
            this.users = this.filterUserList(this.UtilisateurPortailService.users, this.users, 'contact');

            //* liste des chauffeurs et chantiers si licence gestion
            this.chauffeurs = [];
            this.chauffeursCopy = [];
            this.sitesTransporteurs = [];
            this.chantiers = [];
            this.chantiersCopy = [];

            if (this.edit) {
                const res = await this.UtilisateurPortailService.getOne(this.$stateParams.id);
                this.user = new UserPortail(res.data);
                if (this.user.chauffeur) {
                    this.typeContact = false;
                }
                this.login = this.user.login;
                //* groupes portail non utilisés pour le moment
                // this.selectedGroup = this.user.group;
                this.chauffeur = this.user.chauffeur;
            } else {
                this.user = new UserPortail();
            }
            this.initAccessLevel();

            this.loading = false;

            if (this.applicationGestion) {
                const liste = (
                    await this.PersonnesService.getPersonnes(true, 'chauffeur', null, null, null, this.MassiaApplicationService.getApplication())
                ).items;
                this.chauffeursCopy = liste;
                this.chauffeurs = this.filterUserList(this.UtilisateurPortailService.users, liste, 'chauffeur');
                this.sitesTransporteurs = (await this.SitesService.getSitesTransporteurs([], [], { skip: 0, take: 0 })).items;
                this.chantiers = (await this.ChantiersService.getChantiers([], [], { skip: 0, take: 0 })).items;
                this.chantiersCopy = angular.copy(this.chantiers);

                //* filtre les chantiers en fonction du site client selectionner
                if (this.user.idSite) {
                    this.filterChantierList(this.user.idSite, this.chantiersCopy);
                }
            }
        } catch (err) {
            this.notification.error(err.message);
        }
    }

    restrictDomains() {
        if (this.chauffeur) {
            this.domains = this.domains.filter((x) => {
                return x.code == 'BL';
            });
        } else {
            this.domains = this.domainsCopy;
        }
    }

    selectItem() {
        this.user.group = this.selectedGroup;
        this.groups = this.groups.map((x) => {
            if (this.user.group.some((e) => e.id == x.id)) {
                x.selected = true;
            } else {
                x.selected = false;
            }

            return x;
        });
    }

    async getDomain() {
        try {
            return (await this.DomainPortailService.getAll()).data.items;
        } catch (err) {
            this.notification.error(err.message);
        }
    }

    async selectSite(data, type) {
        this.user.idPersonne = 0;
        if (this.user.idSite && data.id === this.user.idSite) {
            this.sitesClients.map((x) => {
                x.selected = false;
                return x;
            });
            this.user.idSite = null;
            // users liste entière
            this.users = this.filterUserList(this.UtilisateurPortailService.users, this.usersCopy, 'contact');
            // chauffeurs liste entière
            this.chauffeurs = this.filterUserList(this.UtilisateurPortailService.users, this.chauffeursCopy, 'chauffeur');
            this.filterChantierList(null, this.chantiersCopy);
        } else {
            this.sitesClients.map((x) => {
                if (x.id !== data.id) {
                    x.selected = false;
                }
                return x;
            });
            this.sitesTransporteurs.map((x) => {
                if (x.id !== data.id) {
                    x.selected = false;
                }
                return x;
            });
            this.user.idSite = data.id;
            if (type == 'client') {
                //filter users by site
                this.clientSelect = data;
                this.users = await this.PersonnesService.getContactsBySite(data.id);
                this.filterChantierList(data.id, this.chantiersCopy);
            }
            if (type == 'transporteur') {
                //filter users by site
                const liste = this.filterCHauffeursListe(data);
                this.chauffeurs = this.filterUserList(this.UtilisateurPortailService.users, liste, 'chauffeur');
            }
        }
    }

    initAccessLevel() {
        this.domains.forEach((d) => {
            const access = this.user.access?.find((x) => x.codeDomain === d.code);
            if (access) {
                d.access = new AccessPortail(access);
            } else {
                let boolean = true;
                if (this.domains && this.domains.length > 0) {
                    boolean = false;
                }
                d.access = {
                    idDomain: d.id,
                    codeDomain: d.code,
                    accessLevel: 0,
                    id: 0,
                    read: boolean,
                    print: boolean,
                    build: boolean
                };
            }
        });

        this.domainsCopy = angular.copy(this.domains);
    }

    checkSelectedDomains(domain, property) {
        domain.access[property] = !domain.access[property];

        const selected = domain.access.read || domain.access.print || domain.access.build;
        const indexInDomainSelected = this.domainsSelect.findIndex((x) => x.id == domain.id);

        if (selected && indexInDomainSelected == -1) {
            //add to list
            this.domainsSelect.push(domain);
        }
        if (!selected && indexInDomainSelected > -1) {
            //remove from list
            this.domainsSelect.splice(indexInDomainSelected, 1);
        }
        console.log(this.domains);
    }

    selectUser(data, type) {
        this.filterChantierList(this.user.idSite, this.chantiersCopy);
        if (this.user.idPersonne && data.id === this.user.idPersonne) {
            this.users.map((x) => {
                x.selected = false;
                return x;
            });
            this.user.idPersonne = null;
            this.user.login = null;
            this.user.nom = null;
            this.user.prenom = null;
            this.user.mail = null;
            this.user.phone = null;
            this.chauffeur = false;
        } else {
            this.users.map((x) => {
                if (x.id !== data.id) {
                    x.selected = false;
                }
                return x;
            });
            this.user.idPersonne = data.id;
            this.user.login = data.login;
            this.user.nom = data.nom;
            this.user.prenom = data.prenom;
            this.user.mail = data.mail;
            this.user.phone = data.telMobile;

            this.chauffeur = type == 'chauffeur';
        }
    }

    async save(exit = false) {
        try {
            // this.user.idsGroup = this.selectedGroup.map((x) => x.id);

            if (this.overideAccess) {
                this.user.access = this.domains.map((x) => x.access);
            } else {
                this.user.access = [];
            }
            const userGestion = this.user.access.filter((x) => {
                if (x.codeDomain == 'BL' || x.codeDomain == 'FACT') {
                    return true;
                }
            });
            if (this.user.idSite || this.chauffeur) {
                if (this.edit) {
                    await this.UtilisateurPortailService.update(this.$stateParams.id, this.user);
                    this.notification.success('PORTAIL.USER.UPDATE_SUCCESS');
                } else {
                    this.RouterHistoryService.ignoreNextRoute();
                    const user = await this.UtilisateurPortailService.create(this.user);
                    this.notification.success('PORTAIL.USER.CREATION_SUCCESS');
                    if (!exit) {
                        return this.$state.go('portail.users.edit', {
                            id: user.data.id,
                            obj: user.data
                        });
                    }
                }
            } else {
                return this.notification.error('Le client lié est obligatoire');
            }
            if (exit) {
                return this.exit();
            }
        } catch (err) {
            if (err.data && err.data.message) {
                return this.notification.error(err.data.message);
            }
            return this.notification.error(err.message);
        }
    }

    redirectPersonneForm(id, type) {
        if (type === 'contact') {
            this.$state.go('contacts.edit', { id: id, genre: 'contact' });
        } else {
            this.$state.go('chauffeurs.edit', { id: id, genre: 'chauffeur' });
        }
    }

    $onDestroy() {} //dismiss watcher ??

    exit() {
        if (!this.RouterHistoryService.back()) {
            this.$state.go('portail.users.list');
        }
    }

    selectNewContact() {
        this.newContact.selected = true;
        this.users.push(this.newContact);
        this.user.idPersonne = this.newContact.id;
        this.user.login = this.newContact.login;
        this.user.nom = this.newContact.nom;
        this.user.prenom = this.newContact.prenom;
        this.user.mail = this.newContact.mail;
        this.user.phone = this.newContact.telMobile;
    }

    async checkLoginUnicity(login) {
        if (!login || login == this.login) {
            this.personne.loginExists = null;
            return;
        }
        try {
            if (login.match(/^[a-zA-Z0-9_|]*$/)) {
                this.user.loginExists = await this.PersonnesService.loginExists(null, login);
                return;
            }
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    //* groupes portail non utilisés pour le moment
    // allowOverideAccess()
    // {
    //       liste groupes → récupérer aussi les accès par domaine
    //       si overideAccess === false
    //       calculer pour chaque domaine le droit le + éléver des groupes séléctionnés et l'afficher sur le tableau, si aucun groupe selectionner 0
    // }

    filterUserList(usersPortail, users, type) {
        // mode édition permet de conserver l'utilisateur concerner dans la liste des contacts / chauffeurs
        if (this.$stateParams.id) {
            const i = usersPortail.findIndex((x) => x.id == this.$stateParams.id);
            if (i >= 0) {
                usersPortail.splice(i, 1);
                if (type === 'chauffeur') {
                    //affiche la liste des chauffeurs si
                    this.typeContact = false;
                }
            }
        }
        // id des contacts ayant dejà un compte portail
        const userIds = usersPortail.map((x) => x.idPersonne);
        const filtered = users.filter((x) => {
            return !(userIds.indexOf(x.id) >= 0);
        });

        return filtered;
    }

    filterChantierList(idSite, chantiers) {
        if (idSite) {
            this.chantiers = chantiers.filter((x) => {
                return x.siteId == idSite;
            });
        } else {
            this.chantiers = chantiers;
        }
    }

    filterCHauffeursListe(site) {
        const liste = this.chauffeursCopy.filter((x) => {
            return x.site == site.libelle;
        });
        return liste;
    }
}

UserAddController.$inject = [
    '$scope',
    '$uibModal',
    'PersonnesService',
    'SitesService',
    'UtilisateurPortailService',
    'notification',
    '$stateParams',
    'RouterHistoryService',
    '$state',
    'DomainPortailService',
    'GroupPortailService',
    'MassiaApplicationService',
    'ChantiersService'
];
