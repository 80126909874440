const serviceUrl = `${__configuration.apiUrl}/massia/documents`;

export default class DocumentsService {
    static $inject = ['$http', 'TypesNiveauxHierarchique', 'Upload', 'notification', '$translate'];

    constructor($http, TypesNiveauxHierarchique, Upload, notification, $translate) {
        this.$http = $http;
        this.TypesNiveauxHierarchique = TypesNiveauxHierarchique;
        this.Upload = Upload;
        this.notification = notification;
        this.$translate = $translate;
    }

    async getDocuments(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    // async getEssai3RDefaut() {
    //     const a = await this.$http.get(`${__configuration.apiUrl}/massia/parametre-programme/code`, {
    //         params: {
    //             cle: 'Ess3RDefBet'
    //         }
    //     });
    //     return a.data;
    // }

    async uploadFile({ data, file }) {
        console.log(' 🔥', data, file);
        const formData = new FormData();
        formData.append('info', angular.toJson(data));
        formData.append('file', file);
        await this.$http.post(`${__configuration.apiUrl}/massia/prelevements/import`, formData, {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined }
        });
    }

    async estCodeAuto() {
        const url = `${serviceUrl}/est-code-auto`;
        try {
            const res = await this.$http.get(url);
            return res.data;
        } catch (err) {
            if (err.status === 404) {
                return false;
            }
            throw err;
        }
    }

    async getDocumentsDownload(id, domaine) {
        const url = `${serviceUrl}/${id}/upload/${domaine}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async deleteDocumentById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getDocumentEnteteById(id) {
        const url = `${serviceUrl}/${id}/entete`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getDocumentValeursCaracteristiquesAssociationsById(id) {
        const data = await this.getDocumentValeursCaracteristiquesById(id);
        const result = {};

        for (const element in data) {
            if (data[element] && !Array.isArray(data[element])) {
                if (data[element].value === 'True') {
                    data[element].value = 'true';
                }
                if (data[element].value === 'False') {
                    data[element].value = 'false';
                }

                if (data[element].value && data[element].value.key) {
                    result[data[element].id] = data[element].value.key;
                } else {
                    result[data[element].id] = data[element].value;
                }
            }
        }
        return result;
    }

    async getDocumentValeursCaracteristiquesById(id) {
        const url = `${serviceUrl}/${id}/valeurs-caracteristiques`;
        const res = await this.$http.get(url);
        const result = [];
        res.data.forEach(function (element) {
            const caract = {
                id: element.id,
                code: element.code,
                idNature: element.idNature,
                indice: element.indice,
                label: element.label,
                numericAdditionalInformation: element.numericAdditionalInformation,
                value: element.elementsValues || element.elementValue || element.values || element.value
            };
            result.push(caract);
        });
        return result;
    }

    async createDocument(document) {
        const data = {
            code: document.entete.code,
            libelle: document.entete.libelle,
            type: document.entete.typeId,
            source: document.entete.source,
            taille: document.entete.taille,
            caracteristiques: [],
            isForTemplate: document.forTemplate
        };
        data.caracteristiques = this.formatCaracteristiques(document.caracteristiques);

        const result = await this.$http.post(serviceUrl, data);

        return result.data;
    }

    formatCaracteristiques(caracteristiques) {
        const data = [];
        for (const property in caracteristiques) {
            let keyValueObject;
            //On vérifie que la propriété ne commence par pas '$' qui est normalement réservé aux variables interne Js.
            if (property[0] !== '$' && property !== 'data' && !isNaN(property)) {
                const propertyVal = caracteristiques[property];
                if (Array.isArray(propertyVal)) {
                    if (_.some(propertyVal, 'key')) {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: _.map(propertyVal, 'key')
                        };
                    } else {
                        keyValueObject = {
                            IdCaracteristique: parseInt(property),
                            valeurs: propertyVal
                        };
                    }
                } else {
                    keyValueObject = {
                        IdCaracteristique: parseInt(property),
                        valeur: propertyVal
                    };
                }
                data.push(keyValueObject);
            }
        }
        return data;
    }

    async updateDocument(document) {
        const data = document.entete;
        data.caracteristiques = [];

        data.caracteristiques = this.formatCaracteristiques(document.caracteristiques);

        const url = `${serviceUrl}/${document.id}`;
        return await this.$http.put(url, data);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async onFileSelect($files, id, code, idType, callback) {
        const apiUrl = `${serviceUrl}/${id}/upload/${code}/${idType}`;
        const that = this;
        for (let i = 0; i < $files.length; i++) {
            const file = $files[i];
            this.upload = this.Upload.upload({
                url: apiUrl,
                method: 'POST',
                file: file
            })
                .success(function (data) {
                    //logSuccess(data);
                    that.notification.success(that.$translate.instant('DOCUMENTS.DOC_CHARGEE_SUCESS'));
                    if (callback) {
                        callback(data);
                    }
                })
                .error(function (data, status) {
                    //logError('Failed to send file ' + status);
                    that.notification.error(that.$translate.instant('DOCUMENTS.DOC_CHARGEE_ERROR'));
                });
        }
    }

    async print(fileName, extension, code) {
        const url = `${__configuration.apiUrl}/massia/document/print/${fileName}/${extension}/${code}`;
        const res = await this.$http.get(url, {
            responseType: 'arraybuffer'
        });

        return res;
    }

    async getFiltres() {
        const url = `${serviceUrl}/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async updateFiltre(id, domaine, idNamePair) {
        const data = {
            id: id,
            domaine: domaine,
            idNamePair: idNamePair
        };
        const url = `${__configuration.apiUrl}/massia/document/print`;
        return this.$http.put(url, data);
    }
}
