import validatorBuilder from 'validator-builder';

let centreGestionValidator = null;

export default class CentreGestion {
    constructor(data = {}) {
        this.id = data.id;
    }

    isValid() {
        centreGestionValidator = centreGestionValidator || validatorBuilder.getInstanceFor('CentreGestion');
        const validationResults = centreGestionValidator.validate(this);
        return validationResults.isValid;
    }
}
