import NomenclatureEntete from './nomenclature.entete.model';

export default class NomenclatureFormEnteteController {
	static $inject = ['$state', '$stateParams', 'NomenclaturesService', 'ModalService', 'notification', '$translate'];

	constructor($state, $stateParams, NomenclaturesService, ModalService, notification, $translate) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.NomenclaturesService = NomenclaturesService;
		this.ModalService = ModalService;
		this.notification = notification;
		this.$translate = $translate;
	}

	$onInit() {
		this.nomenclature = this.nomenclature || {};
		this.entete = new NomenclatureEntete(this.nomenclature.entete);
	}

	$onChanges(objChanges) {}

	$doCheck() {
		if (!angular.equals(this.entete, this.nomenclature.entete)) {
			this.onUpdate({
				entete: angular.copy(this.entete)
			});
		}
	}

	async checkCodeUnicity(code) {
		if (code) {
			this.startCodeLoading();

			try {
				if (code.match(/^[a-zA-Z0-9_|]*$/)) {
					this.entete.codeExists = await this.NomenclaturesService.codeExists(code);
				}
			} catch (ex) {
				this.notification.error(ex.data);
			} finally {
				this.stopCodeLoading();
			}
		} else {
			this.entete.codeExists = null;
		}
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	startCodeLoading() {
		this.codeLoading = true;
	}

	stopCodeLoading() {
		this.codeLoading = false;
	}
}
