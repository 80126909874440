export default class FuseauxGranulometriquesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
        this.serviceUrl = `${__configuration.apiUrl}/v2/massia/fuseaux-granulometriques`;
    }

    async getAll(filters, sorts, pagination) {
        const res = await this.$http.get(this.serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getOneById(id) {
        const res = await this.$http.get(this.serviceUrl + '/' + id);

        return res.data;
    }

    async createFuseauGranulometrique(data) {
        try {
            const res = await this.$http.post(this.serviceUrl, data);
            return res.data;
        } catch (e) {
            throw e.data;
        }
    }

    async updateFuseauGranulometrique(data) {
        try {
            const res = await this.$http.put(this.serviceUrl + '/' + data.id, data);
            return res.data;
        } catch (e) {
            throw e.data;
        }
    }

    async deleteFuseauGranulometrique(id) {
        return await this.$http.delete(this.serviceUrl + '/' + id);
    }
}
