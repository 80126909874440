import { IAugmentedJQuery, IScope } from "angular";
import { Directive, Input, OnDestroy, AfterViewInit } from "../../../core/decorators";

@Directive({ selector: '[massiaFixedTable]' })
export class MassiaFixedTable implements AfterViewInit, OnDestroy {
    @Input() fixedHeader: boolean | string;
    @Input() rowNumber: number;
    @Input() fixedColumn: boolean | string;
    @Input() columnNumber: number;
    @Input() model: any;
    unregister: VoidFunction;

    /* @ngInject */
    constructor(public $scope: IScope, public $element: IAugmentedJQuery) { }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.init();
        this.unregister = this.$scope.$watch(() => this.model, () => this.init());
    }

    ngAfterViewInit(): void {
        this.init();
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.unregister();
    }

    init() {
        this.$element.ready(() => {
            var table = this.$element;
            table.css('border-collapse', 'collapse');
            if (this.fixedHeader || this.fixedHeader === 'true') {
                this.fixHeader(table, this.rowNumber);
            }
            if (this.fixedColumn || this.fixedColumn === 'true') {
                this.fixColumn(table, this.columnNumber);
            }
        });
    }

    fixHeader(table: IAugmentedJQuery, nbr: number) {
        var thead = table.children('thead:first');
        var trs = thead.children('tr');
        var infoTag = [];
        // Pour chaque <tr>
        for (let i = 0; i < trs?.length || 0; i++) {
            var tr = $(trs[i]);
            // On recupere les <th>
            var ths = tr.children('th');
            var toDel = [];
            // Pour chaque <th>
            for (let j = 0; j < ths?.length || 0; j++) {
                const th = $(ths[j]);
                // On check le rowspan et le colspan
                var [rowspan, colspan] = [Number.parseInt(th.attr('rowspan')), Number.parseInt(th.attr('colspan'))];
                [rowspan, colspan] = [Number.isNaN(rowspan) ? 1 : rowspan, Number.isNaN(colspan) ? 1 : colspan];
                const top: number = i === 0 ? 0 : infoTag[j].height;
                const height: number = th.outerHeight(true) + (i === 0 ? 0 : infoTag[j].height);

                // Si le rowspan depasse le nombre de ligne que l'on veut fixer on ne fait rien
                if (rowspan > nbr) continue;

                // i === 0 => Premiere ligne (premier <tr>) du coup pas de calcul et le top à 0
                if (i !== 0) {
                    rowspan = rowspan + infoTag[j].rowspan;
                    toDel.push(j);
                }
                th.css('position', 'sticky');
                th.css('top', top);
                th.css('z-index', 40);

                if (rowspan < nbr) {
                    for (let k = 0; k < colspan; k++) {
                        infoTag.push({
                            height: height,
                            rowspan: rowspan
                        });
                    }
                }
            }
            for (let j = 0; j < toDel.length; j++) {
                const index = toDel[j];
                infoTag.splice(index);
            }
        }
    }

    fixColumn(table: IAugmentedJQuery, nbr: number | string) {
        var number = nbr;
        if (!number || Number.isNaN(number)) {
            number = 1;
        }

        var index = 0;
        var thead = table.children('thead:first');
        var tbody = table.children('tbody');

        for (let i = 0; i < [thead, ...tbody]?.length || 0; i++) {
            var element = $([thead, ...tbody][i]);
            var tag = 'td';
            if (i === 0) tag = 'th';

            var trs = element.children('tr');

            var info = [];
            for (let j = 0; j < trs?.length || 0; j++) {
                var tr = $(trs[j]);
                var tags = tr.children(tag);

                for (let k = 0; k < tags.length; k++) {
                    var t = $(tags[k]);

                    var colspan = Number.isNaN(Number.parseInt(t.attr('colspan'))) ? 1 : Number.parseInt(t.attr('colspan'));
                    if (k === 0) {
                        if (!info[j]) info[j] = {};
                        info[j] = {
                            width: t.outerWidth(),
                            colspan: colspan,
                        };
                    } else {
                        info[j].colspan += colspan;
                        info[j].width += t.outerWidth();
                        colspan = info[j].colspan;
                    }

                    if (colspan <= nbr) {
                        t.css('position', 'sticky');
                        t.css('left', k === 0 ? 0 : info[j].width - t.outerWidth());
                        t.css('background-color', tag === 'th' ? t.css('background-color') : tr.css('background-color'));
                        t.css('z-index', 50000 - index);
                    }

                    index++;
                }
                index++;
            }
            index++;
        }
    }
}
