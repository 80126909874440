export default class FuseauRegulariteModalController {
    static $inject = [
        'ReferencesControleService',
        'notification',
        'ReferencesControleTypesService',
        '$uibModal',
        '_',
        'CalculsStatistiquesService',
        'CodeAutomatiqueService'
    ];

    /**
     * Construct
     */
    constructor(
        ReferencesControleService,
        notification,
        ReferencesControleTypesService,
        $uibModal,
        _,
        CalculsStatistiquesService,
        CodeAutomatiqueService
    ) {
        this.notification = notification;
        this.ReferencesControleService = ReferencesControleService;
        this.ReferencesControleTypesService = ReferencesControleTypesService;
        this.modal = $uibModal;
        this._ = _;
        this.CalculsStatistiquesService = CalculsStatistiquesService;
        this.CodeAutomatiqueService = CodeAutomatiqueService;
    }

    async $onInit() {
        this.newSeuil = {
            code: '',
            libelle: '',
            idProduit: this.idProduit,
            idType: null,
            idNorme: null,
            dateDebut: undefined,
            dateFin: undefined,
            essais: [],
            normeNorme: null
        };
        this.code = await this.checkCodeAuto();
        this.typesOrig = await this.ReferencesControleTypesService.getAll('SeuilGran');
        this.normesOrig = await this.ReferencesControleService.getNormes();
        this.specifications = [];
    }

    $onDestroy() {}

    async checkCodeAuto() {
        try {
            const code = await this.CodeAutomatiqueService.getCodeAutomatique('laboratoire');
            const res = code.find((x) => x.domaineCode === 'SeuilGran');
            return res;
        } catch (err) {
            console.error(err);
        }
    }

    /*  setTypeAheadAs() {
         this.newSeuil.idType = this.selType.id;
         this.selType.isSelected = true;
     }

     changeType() {
         const that = this;
         this.types = this.typesOrig.filter(function (e) { return e.libelle.toLowerCase().indexOf(that.selType.toLowerCase()) >= 0; });

         if (this.selType.isSelected) {
             this.selType = null;
             this.model.idType = null;
         }
     }

     async setNormeAheadAs() {
         this.newSeuil.idNorme = this.selNorme.id;
         this.newSeuil.normeNorme = this.selNorme.norme;
         this.selNorme.isSelected = true;
         this.specifications = await this.ReferencesControleService.getSpecificationsNorme(this.newSeuil.idNorme, this.newSeuil.idProduit);
         await this.chercheEssais();
         await this.chercheIncertitudes();
     }

     changeNorme() {
         const that = this;
         this.normes = this.normesOrig.filter(function (e) { return e.libelle.toLowerCase().indexOf(that.selNorme.toLowerCase()) >= 0; });

         if (this.selNorme.isSelected) {
             this.selNorme = null;
             this.model.idNorme = null;
         }
     }
  */
    async chercheIncertitudes() {
        this.incertitudes = await this.ReferencesControleService.getIncertitudesEssaisByNorme(this.newSeuil.normeNorme, this.newSeuil.idProduit);
        for (let i = 0; i < this.newSeuil.essais.length; i++) {
            this.ReferencesControleService.chercheIncertitude(this.newSeuil.essais[i], undefined, this.incertitudes, true);
        }
    }

    async chercheEssais() {
        this.newSeuil.essais = [];
        let moyennesARajouter = angular.copy(this.moyennes);
        if (this.specifications && this.specifications.specifications) {
            for (let i = 0; i < this.specifications.specifications.length; i++) {
                const spec = this.specifications.specifications[i];
                for (let j = 0; j < spec.essais.length; j++) {
                    const essSpec = spec.essais[j];
                    //vérifier si l'essai n'est pas déjà dans le tableau (pour une autre catégorie ou condition)
                    const dejaAjoute = this._.find(this.newSeuil.essais, {
                        essaiId: essSpec.idEssai,
                        sousEssaiId: essSpec.idTamis == null ? essSpec.idSousEssai : essSpec.idTamis
                    });
                    if (!dejaAjoute) {
                        const essai = {
                            id: 0,
                            //essaicodeLibelle: '',
                            essaiId: essSpec.idEssai,
                            //sousEssaiCodeLibelle: '',
                            sousEssaiId: essSpec.idTamis == null ? essSpec.idSousEssai : essSpec.idTamis,
                            //libelleComplet: '',
                            //calculLibelle: '',
                            calculId: essSpec.idCalcStat,
                            //calculFormule: '',
                            condition: essSpec.condition,
                            borneInferieure: '',
                            borneSuperieure: '',
                            alerte: null,
                            unite: essSpec.libelleUnite,
                            //arrondi: null,//TODO: arrondi
                            libelleEssai: essSpec.libelleEssai,
                            codeEssai: essSpec.code,
                            symboleNormeEssai: essSpec.symboleEssai,
                            limiteInferieure: essSpec.limiteInferieure,
                            limiteSuperieure: essSpec.limiteSuperieure,
                            moyenne: null,
                            etendueReference: essSpec.etendue,
                            incertitudeReference: null,
                            isETControle: null,
                            isFiltre: null,
                            niveauVisibilite: null,
                            ordre: essSpec.position,
                            categorieId: spec.idCategorie,
                            tamis: essSpec.idTamis == null ? false : true,
                            ouvertureTamis: essSpec.ouvertureTamis,
                            //grandeurMinimum: null,//TODO: grandeurs
                            //grandeurMaximum: null,
                            categories: []
                        };
                        //chercher la moyenne
                        const moy = essai.tamis
                            ? this._.find(this.moyennes, { isTamis: essai.tamis, idEssai: essSpec.idEssai, libelle: essSpec.ouvertureTamis }) ||
                              this._.find(this.moyennes, {
                                  isTamis: essai.tamis,
                                  idEssai: essSpec.idEssai,
                                  libelle: essSpec.ouvertureTamis.toString()
                              })
                            : this._.find(this.moyennes, { isTamis: essai.tamis, idEssai: essSpec.idEssai });
                        if (moy != undefined && moy != null) {
                            essai.moyenne = parseFloat(moy.valeur);
                            moyennesARajouter = this._.reject(moyennesARajouter, function (eltm) {
                                if (essai.tamis) {
                                    return (
                                        eltm.isTamis == true &&
                                        eltm.idEssai == essSpec.idEssai &&
                                        (eltm.libelle == essSpec.ouvertureTamis || eltm.libelle == essSpec.ouvertureTamis.toString())
                                    );
                                }
                                return eltm.isTamis == false && eltm.idEssai == essSpec.idEssai;
                            });
                        }
                        //chercher la catégorie
                        if (spec.idCategorie) {
                            const categ = this._.find(this.specifications.categories, { id: spec.idCategorie });
                            if (categ) {
                                essai.categories.push(categ);
                            } else {
                                essai.categories.push({ id: 0, catgorie: '' });
                            }
                        }
                        this.changeXt(essai);
                        this.newSeuil.essais.push(angular.copy(essai));
                    } else {
                        if (spec.idCategorie) {
                            //vérifier si la catégorie n'est pas déjà dans la liste
                            const categDejaAjoutee = this._.find(dejaAjoute.categories, { id: spec.idCategorie });
                            if (!categDejaAjoutee) {
                                const categ = this._.find(this.specifications.categories, { id: spec.idCategorie });
                                if (categ) {
                                    dejaAjoute.categories.push(categ);
                                }
                            }
                        } else {
                            const categVideDejaAjoutee = this._.find(dejaAjoute.categories, { id: 0 });
                            if (!categVideDejaAjoutee) {
                                dejaAjoute.categories.push({ id: 0, catgorie: '' });
                            }
                        }
                    }
                }
            }
        }
        //chercher la catégorie la + adaptée pour les moyennes renseignées
        for (let parcT = 0; parcT < this.newSeuil.essais.length; parcT++) {
            var essParcT = this.newSeuil.essais[parcT];
            if (essParcT.moyenne !== undefined && essParcT.moyenne !== null && essParcT.categories.length > 1) {
                //TODO: vérifier si on récupère les catégories dans l'ordre
                let catOk = false;
                var indCatg = 0;
                while (!catOk && indCatg < essParcT.categories.length) {
                    const specEssCategParc = this._.find(this.specifications.specifications, function (elt) {
                        return elt.idCategorie == essParcT.categories[indCatg].id;
                    });
                    if (specEssCategParc) {
                        const essCategParc = essParcT.tamis
                            ? this._.filter(specEssCategParc.essais, {
                                  idEssai: essParcT.essaiId,
                                  idTamis: essParcT.tamis ? essParcT.sousEssaiId : null
                              })
                            : this._.filter(specEssCategParc.essais, { idEssai: essParcT.essaiId });
                        if (essCategParc && essCategParc.length > 0) {
                            let isOk = true;
                            if (essCategParc[0].limiteInferieure !== undefined && essCategParc[0].limiteInferieure !== null) {
                                isOk = essParcT.moyenne >= essCategParc[0].limiteInferieure;
                            }
                            if (isOk && essCategParc[0].limiteSuperieure !== undefined && essCategParc[0].limiteSuperieure !== null) {
                                isOk = essParcT.moyenne <= essCategParc[0].limiteSuperieure;
                            }
                            if (isOk) {
                                catOk = true;
                                essParcT.categorieId = essParcT.categories[indCatg].id;
                                this.changeCategorie(essParcT);
                            }
                        }
                    }
                    indCatg++;
                }
            }
        }
        //ajouter tous les essais/tamis de la FTP même si pas dans les specifications de norme
        if (moyennesARajouter.length > 0) {
            await this.getCalculStatistiqueDefaut();
            for (let iEssAAjouter = 0; iEssAAjouter < moyennesARajouter.length; iEssAAjouter++) {
                const fromMoy = moyennesARajouter[iEssAAjouter];
                const essaiMoy = {
                    id: 0,
                    //essaicodeLibelle: '',
                    essaiId: fromMoy.idEssai,
                    //sousEssaiCodeLibelle: '',
                    sousEssaiId: fromMoy.id, //SP 15/06/21 nok, id TTamisSerie au lieu de TSsEssTamis
                    //libelleComplet: '',
                    //calculLibelle: '',
                    calculId: this.calculStatDefaut.id,
                    //calculFormule: '',
                    condition: '',
                    borneInferieure: '',
                    borneSuperieure: '',
                    alerte: null,
                    unite: fromMoy.unite,
                    //arrondi: null,//TODO: arrondi
                    libelleEssai: fromMoy.libelleEssai,
                    codeEssai: fromMoy.codeEssai,
                    symboleNormeEssai: fromMoy.symboleEssai,
                    limiteInferieure: null,
                    limiteSuperieure: null,
                    moyenne: parseFloat(fromMoy.valeur),
                    etendueReference: null,
                    incertitudeReference: null,
                    isETControle: null,
                    isFiltre: null,
                    niveauVisibilite: null,
                    ordre: 0,
                    categorieId: null,
                    tamis: fromMoy.isTamis,
                    ouvertureTamis: fromMoy.ouvertureTamis, //fromMoy.isTamis ? fromMoy.libelle : null,
                    //grandeurMinimum: null,//TODO: grandeurs
                    //grandeurMaximum: null,
                    categories: []
                };
                this.newSeuil.essais.push(angular.copy(essaiMoy));
            }
        }
        //trier !
        this.newSeuil.essais = this._.orderBy(this.newSeuil.essais, ['libelleEssai', 'ouvertureTamis'], ['asc', 'desc']);
    }

    async getCalculStatistiqueDefaut() {
        try {
            this.calculStatDefaut = await this.CalculsStatistiquesService.getCalculStatistiqueValeur();
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    changeCategorie(ess) {
        const categ = this._.filter(this.specifications.categories, { id: ess.categorieId });
        if (categ) {
            //chercher la spécification de l'essai correspondant à la catégorie (TODO: voir si il en a plusieurs...)
            const specEssCateg = this._.find(this.specifications.specifications, function (elt) {
                return elt.idCategorie == categ[0].id;
            });
            if (specEssCateg) {
                const essCateg = ess.tamis
                    ? this._.filter(specEssCateg.essais, { idEssai: ess.essaiId, idTamis: ess.tamis ? ess.sousEssaiId : null })
                    : this._.filter(specEssCateg.essais, { idEssai: ess.essaiId });
                if (essCateg && essCateg.length > 0) {
                    ess.calculId = essCateg[0].calculId;
                    ess.condition = essCateg[0].condition;
                    ess.limiteInferieure = essCateg[0].limiteInferieure;
                    ess.limiteSuperieure = essCateg[0].limiteSuperieure;
                    ess.etendueReference = essCateg[0].etendue;
                    ess.borneInferieure = undefined;
                    ess.borneSuperieure = undefined;
                    this.changeXt(ess);
                }
            }
        }
    }

    changeXt(ess) {
        if (ess.moyenne != undefined && ess.moyenne != null && ess.etendueReference) {
            ess.borneInferieure = parseFloat(ess.moyenne) - ess.etendueReference / 2;
            ess.borneSuperieure = parseFloat(ess.moyenne) + ess.etendueReference / 2;
            if (ess.tamis) {
                if (ess.borneInferieure < 0) {
                    ess.borneInferieure = 0;
                }
                if (ess.borneSuperieure > 100) {
                    ess.borneSuperieure = 100;
                }
            }
        }
        this.ReferencesControleService.chercheIncertitude(ess, undefined, this.incertitudes, true);
    }

    async checkCodeUnicity() {
        const input = this.newSeuil.code;
        this.codeValid = null;
        if (input != '') {
            try {
                const data = await this.ReferencesControleService.codeExists('SeuilGran', input);
                this.codeValid = !data;
            } catch (err) {
                this.notification.error(err);
            }
        }
    }

    async appliquer() {
        if (
            this.newSeuil &&
            ((this.newSeuil.code && this.codeValid) || this.code.estCodeAutomatique) &&
            this.newSeuil.libelle &&
            this.newSeuil.idType
        ) {
            this.modalInstance.close(this.newSeuil);
        }
    }

    async cancel() {
        this.modalInstance.close();
    }

    async onSelectItem(data, theme) {
        if (theme === 'type') {
            this.newSeuil.idType = data.id;
        } else if (theme === 'norme') {
            this.newSeuil.idNorme = data.id;
            this.newSeuil.normeNorme = data.norme;
            this.specifications = await this.ReferencesControleService.getSpecificationsNorme(this.newSeuil.idNorme, this.newSeuil.idProduit);
            await this.chercheEssais();
            await this.chercheIncertitudes();
        }
    }
}
