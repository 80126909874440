import { Pipe } from '../../../core/decorators';
import { PipeTransform } from '../../../core/decorators/pipe';

@Pipe({
    name: 'slice',
})
export class slice implements PipeTransform {
    transform(array: any[], start: number, end: number) {
        //console.log(array, start, end); >:(
        return array.slice(start, end);
    }
}
