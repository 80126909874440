import InventaireStocksMouvementController from './inventaire.stocks.mouvement.controller';

export default {
    bindings: {
        inventaire: '=',
        modalInstance: '='
    },
    template: require('./inventaire.stocks.mouvement.html'),
    controller: InventaireStocksMouvementController
};
