import FuseauRegulariteModalController from './fuseau.regularite.modal.controller';

const moduleName = 'app.massia.fuseauregularite';

export default {
    template: require('./fuseau.regularite.modal.html'),
    controller: FuseauRegulariteModalController,
    bindings: {
        modalInstance: '=',
        idProduit: '=',
        moyennes: '='
    }
};
