import Document from './document.model';

export default class DocumentFormController {
    static $inject = [
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'DocumentsCommunicationService',
        'DocumentsService',
        'notification',
        '$location',
        '$anchorScroll',
        '_',
        'MassiaApplicationService',
        '$uibModal',
        'RouterHistoryService',
        'MOPService'
    ];

    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        DocumentsCommunicationService,
        DocumentsService,
        notification,
        $location,
        $anchorScroll,
        _,
        MassiaApplicationService,
        $uibModal,
        RouterHistoryService,
        MOPService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.DocumentsCommunicationService = DocumentsCommunicationService;
        this.DocumentsService = DocumentsService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this._ = _;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$uibModal = $uibModal;
        this.RouterHistoryService = RouterHistoryService;

        this.ongletOpen = {
            isEnteteOpen: false,
            isCaracteristiqueOpen: false
        };
        this.ongletOpenVal = {
            isEnteteOpen: 'entete',
            isCaracteristiqueOpen: 'caracteristiques'
        };
        this.MOPService = MOPService;
    }

    $onInit() {
        this.MOPService.setMop([{ title: 'DOCUMENTS.BREADCRUMBS.DOCUMENTS_LIST', filename: 'Documents.pdf', application: 'massia' }]);
        this.document = {};
        this.loading = false;
        this.form = {
            entete: {},
            caracteristiques: {}
        };

        this.reset();
    }

    $onDestroy() {
        this.$timeout.cancel(this.updateDocumentTimeout);
        this.MOPService.resetMop();
    }

    async reset() {
        this.startLoading();

        let idParam = this.$stateParams.id;
        if (this.idOffre) {
            idParam = undefined;
        }
        if (this.docfromoffre) {
            idParam = this.docfromoffre;
        }
        const data = {
            id: idParam
        };

        if (data.id) {
            try {
                data.entete = await this.DocumentsService.getDocumentEnteteById(data.id);
                this.elements = data.entete.elements;
                data.caracteristiques = await this.DocumentsService.getDocumentValeursCaracteristiquesAssociationsById(data.id);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
                this.annuler();
            }
        }
        this.document = new Document(data);
        if (this.$stateParams.duplicate) {
            this.document.id = undefined;
            this.document.entete.code += '_copie';
        }

        this.ongletOpen = {
            isEnteteOpen: true,
            isCaracteristiqueOpen: false
        };

        this.document.forTemplate = false;
        if (this.idOffre || this.docfromoffre) {
            this.document.forTemplate = true;
        }

        this.$timeout.cancel(this.updateDocumentTimeout);
        this.updateDocumentTimeout = this.$timeout(() => this.stopLoading());
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();

            try {
                let id = false;
                if (this.document.id) {
                    await this.DocumentsService.updateDocument(this.document);
                    this.notification.success('DOCUMENTS.UPDATED');
                    id = this.document.id;
                } else {
                    id = await this.DocumentsService.createDocument(this.document);
                    this.notification.success('DOCUMENTS.CREATED');
                }
                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async appliquer() {
        this.RouterHistoryService.ignoreNextRoute();
        const id = await this.sauvegarder();

        //if (!this.RouterHistoryService.back()) {//non, valider ne quitte pas la page
        if (id && this.document.id) {
            this.reset();
        } else if (id) {
            if (this.document.forTemplate) {
                this.modalInstance.close(id);
            } else {
                this.$state.go('documents.edit', { id: id });
            }
        }
        //}
    }

    async confirmer() {
        const success = await this.sauvegarder();
        if (!this.RouterHistoryService.back()) {
            if (success) {
                this.annuler();
            }
        }
    }

    annuler() {
        if (!this.RouterHistoryService.back()) {
            if (this.document.forTemplate) {
                this.modalInstance.dismiss();
            } else {
                if (this.$stateParams.idOrigine) {
                    this.returnOrigine();
                } else {
                    this.$state.go('documents.list');
                }
            }
        }
    }

    returnOrigine() {
        const origine = this.$stateParams.idOrigine;
        const domaine = this.$stateParams.domaine;
        const theme = this.$stateParams.theme;
        const application = this.MassiaApplicationService.getApplication();
        switch (domaine) {
            case '0':
                this.$state.go('commandes.edit', { id: origine });
                break;
            case '1':
                this.$state.go('societes.edit', { id: origine });
                break;
            case '2':
                this.$state.go('producteurs.edit', { id: origine, genre: 'producteurs' });
                break;
            case '3':
                this.$state.go('sites.edit', { id: origine, genre: 'commerciaux' });
                break;
            case '4':
                this.$state.go('clients.edit', { id: origine, genre: 'clients' });
                break;
            case '5':
                this.$state.go('fournisseurs.edit', { id: origine, genre: 'fournisseurs' });
                break;
            case '6':
                this.$state.go('transporteurs.edit', { id: origine, genre: 'transporteurs' });
                break;
            case '7':
                this.$state.go('personnes.edit', { id: origine, genre: 'personne' });
                break;
            case '8':
                this.$state.go('produits.edit', { id: origine });
                break;
            case '9':
                this.$state.go('chantiers.edit', { id: origine });
                break;
            case '10':
                this.$state.go('clients-divers.edit', { id: origine });
                break;
            case '11':
                this.$state.go('banques.edit', { id: origine });
                break;
            case '12':
                this.$state.go('caracteristiques.edit', { id: origine });
                break;
            case '13':
                this.$state.go('devises.edit', { id: origine });
                break;
            case '14':
                this.$state.go('paiements.edit', { id: origine });
                break;
            case '15':
                this.$state.go('motifs.edit', { id: origine });
                break;
            case '16':
                this.$state.go('normes.edit', { id: origine });
                break;
            case '17':
                this.$state.go('pays.edit', { id: origine });
                break;
            case '18':
                this.$state.go('prestations.edit', { id: origine });
                break;
            case '19':
                this.$state.go('tva.edit', { id: origine });
                break;
            case '20':
                this.$state.go('types.edit', { id: origine });
                break;
            case '21':
                this.$state.go('unites.edit', { id: origine });
                break;
            case '22':
                if (application == 'gestion') {
                    this.$state.go('lieux.edit', { id: origine });
                } else {
                    this.$state.go('lieux-donnee.edit', { id: origine });
                }
                break;
            case '23':
                this.$state.go('vehicules.edit', { id: origine });
                break;
            case '24':
                this.$state.go('offres.edit', { id: origine });
                break;
            case '25':
                this.$state.go('prospects.edit', { id: origine });
                break;
            case '26':
                this.$state.go('tickets.list.detail', { id: origine });
                break;
            case '27':
                this.$state.go('factures.edit', { id: origine });
                break;
            case '28':
                this.$state.go('daps.edit', { id: origine });
                break;
            case '29':
                this.$state.go('mesures.edit', { id: origine });
                break;
            case '30':
                this.$state.go('points-mesure.edit', { id: origine });
                break;
            case '31':
                this.$state.go('laboratoires.edit', { id: origine, genre: 'laboratoires' });
                break;
            case '32':
                this.$state.go('proprietaires.edit', { id: origine, genre: 'proprietaires' });
                break;
            case '33':
                this.$state.go('locataires.edit', { id: origine, genre: 'locataires' });
                break;
            case '34':
                this.$state.go('auditeurs.edit', { id: origine, genre: 'auditeurs' });
                break;
            case '35':
                if (application == 'laboratoire') {
                    this.$state.go('prelevements.edit-campagne', { id: origine, idTheme: theme });
                } else {
                    this.$state.go('saisies.edit-campagne', { id: origine, idTheme: theme });
                }
                break;
            case '36':
                if (application == 'laboratoire') {
                    this.$state.go('prelevements.edit-saisie', { id: origine, idTheme: theme });
                } else {
                    this.$state.go('saisies.edit-saisie', { id: origine, idTheme: theme });
                }
                break;
            case '37':
                this.$state.go('rapports-itm.list.detail', { id: origine });
                break;
            case '38':
                this.$state.go('taches.edit', { id: origine });
                break;
            case '39':
                this.$state.go('bsd.edit', { id: origine });
                break;
            case '40':
                this.$state.go('journal-bord.edit', { id: origine });
                break;
        }
    }

    filtrer() {
        this.$state.go('donnee-filtrees.list', { id: this.document.id, type: 1 });
    }

    checkValidity() {
        let validity = true;

        let firstScroll = true;
        if (!this.document.entete.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('entete');
                firstScroll = false;
            }

            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.$scope.$broadcast('documentEnteteValidations');
        }
        if (!this.document.caracteristiques.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('caracteristiques');
                firstScroll = false;
            }

            this.ongletOpen.isCaracteristiqueOpen = true;
            validity = false;
            this.$scope.$broadcast('documentCaracteristiquesValidations');
        }
        return validity;
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    onUpdateEntete(entete) {
        this.document.entete = entete;
    }

    onUpdateCaracteristiques(caracteristiques) {
        this.document.caracteristiques = caracteristiques;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
