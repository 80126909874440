import FtpPrintConfigController from './ftp.print.config.controller';

export default {
    bindings: {
        resolve: '=',
        modalInstance: '<'
    },
    controller: FtpPrintConfigController,
    template: require('./ftp.print.config.tpl.html')
};
