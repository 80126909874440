import documentModalFormController from './document.modal.form.controller';

export default {
    bindings: {
        resolve: '<',
        modalInstance: '<',
        idOrigine: '=',
        typeOrigine: '=',
        typeSelected: '='
    },
    controller: documentModalFormController,
    template: require('./document.modal.form.html')
};
