//Config
import DocumentsCEConfig from './_config/documents.ce.config';
import DocumentsCERoutes from './_config/documents.ce.routes';
import DocumentsCELaboRoutes from './_config/documents.ce.labo.routes';
import DocumentsCEComponent from './components/documents-ce';
import DocumentsCEService from './services/documents.ce.service';
import DocumentsCECommunicationService from './services/documents.ce.communication.service';

import DocumentCEFormComponent from './components/document-ce-form';
import DocumentCEDetailComponent from './components/document-ce-detail';
import DocumentCEEnteteComponent from './components/document-ce-form/entete';
import DocumentCECaracteristiqueComponent from './components/document-ce-form/caracteristique';

import DocumentCEValidator from './components/document-ce-form/document.ce.validator';
import DocumentCEEnteteValidator from './components/document-ce-form/entete/document.ce.entete.validator';

const moduleName = 'app.massia.common.documents.ce';

angular
    .module(moduleName, [])
    .config(DocumentsCEConfig)
    .config(DocumentsCERoutes)
    .config(DocumentsCELaboRoutes)
    .service('DocumentsCEService', DocumentsCEService)
    .service('DocumentsCECommunicationService', DocumentsCECommunicationService)
    .component('documentsCE', DocumentsCEComponent)
    .component('documentCEForm', DocumentCEFormComponent)
    .component('documentCEDetail', DocumentCEDetailComponent)
    .component('documentCEEntete', DocumentCEEnteteComponent)
    .component('documentCECaracteristique', DocumentCECaracteristiqueComponent)
    .factory('DocumentCEValidator', DocumentCEValidator)
    .factory('DocumentCEEnteteValidator', DocumentCEEnteteValidator);

export default moduleName;
