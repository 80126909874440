import MotifRefusAdmission from './motif.refus.admission.model';

export default class MotifRefusAdmissionFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'MotifRefusAdmissionCommunicationService',
        'MotifRefusAdmissionService',
        'notification',
        '$location'
    ];

    constructor($scope, $state, $stateParams, MotifRefusAdmissionCommunicationService, MotifRefusAdmissionService, notification, $location) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.MotifRefusAdmissionCommunicationService = MotifRefusAdmissionCommunicationService;
        this.MotifRefusAdmissionService = MotifRefusAdmissionService;
        this.notification = notification;
        this.$location = $location;
    }

    async $onInit() {
        this.isEditMode = false;
        this.motifRefusAdmission = {};
        this.loading = false;
        this.setMotifRefusAdmission(this.$stateParams.id);
        this.unregisterReinit = this.MotifRefusAdmissionCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    initForm() {
        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
            const thatMotifRefusAdmission = {
                id: this.motifRefusAdmission.id,
                libelle: this.motifRefusAdmission.libelle
            };
        } else {
            this.isEditMode = false;
        }
    }

    async setMotifRefusAdmission(motifRefusAdmissionId) {
        if (motifRefusAdmissionId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                const stillThatMotifRefusAdmission = await this.MotifRefusAdmissionService.getMotifRefusAdmissionById(motifRefusAdmissionId);
                this.motifRefusAdmission = new MotifRefusAdmission(stillThatMotifRefusAdmission);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('motif-refus-admission.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.motifRefusAdmission = new MotifRefusAdmission();
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.motifRefusAdmission.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.motifRefusAdmission.codeExists = await this.MotifRefusAdmissionService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    // boutons de validation
    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                if (this.motifRefusAdmission.id) {
                    const tmp = await this.updateMotifRefusAdmission();
                    this.notification.success('MOTIFS_REFUS_ADMISSION.UPDATED');
                    return this.motifRefusAdmission.id;
                }
                const tmp = await this.createMotifRefusAdmission();
                this.notification.success('MOTIFS_REFUS_ADMISSION.CREATED');
                return tmp;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        } else {
            return false;
        }
    }

    async valider() {
        const returnedId = await this.sauvegarder();
        if (returnedId) {
            this.$state.go('motif-refus-admission.edit', { id: returnedId });
        }
    }
    async validerEtFermer() {
        const returnedId = this.sauvegarder();
        this.backToPreviousState();
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('motif-refus-admission.list');
    }

    async createMotifRefusAdmission() {
        this.startLoading();
        try {
            const returnedId = await this.MotifRefusAdmissionService.createMotifRefusAdmission(this.motifRefusAdmission);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateMotifRefusAdmission() {
        this.startLoading();
        try {
            const returnedId = await this.MotifRefusAdmissionService.updateMotifRefusAdmission(this.motifRefusAdmission);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    reinit() {
        this.setMotifRefusAdmission(this.$stateParams.id);
        this.MotifRefusAdmissionCommunicationService.raiseReinit();
    }

    checkValidity() {
        this.$scope.$broadcast('motifRefusAdmissionValidations');
        return this.motifRefusAdmission.isValid();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
