// on importe le controller car les components angularJS
// permettent l'ajout direct d'une fonction dans la propriété "controller"
import LieuFormCaracteristiquesController from './lieu.form.caracteristiques.controller';

export default {
    bindings: {
        // on bind avec '<' (onw-way binding)
        // quand on ne veut pas pouvoir modifier l'objet dans le component (seulement dans l'appelant)
        lieu: '<',
        // on bind avec '<' (binding one way)
        // quand on ne veut pas pouvoir modifier l'objet dans le component (seulement dans l'appelant)
        typeId: '<',
        // On bind une fonction avec '&'
        // hook mis à disposition par le component
        // fonction qu'appellera le component lorsqu'il voudra envoyer les nouvelles données à son parent
        onUpdate: '&',
        form: '='
    },
    template: require('./lieu.form.caracteristiques.html'),
    controller: LieuFormCaracteristiquesController
    // si on ne déclare pas de 'controllerAs', par défaut ce sera '$ctrl'
};
