import ParametreTransport from './parametre.transport.model';

export default class ParametreTransportController {
    static $inject = ['$state', '$stateParams', 'SocietesService', 'SocietesTypesService', 'ModalService', 'notification', '$translate'];

    constructor($state, $stateParams, SocietesService, SocietesTypesService, ModalService, notification, $translate) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SocietesService = SocietesService;
        this.SocietesTypesService = SocietesTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
    }

    $onInit() {
        this.societe = this.societe || {};
        this.new = {};

        this.parametreTransport = new ParametreTransport(this.societe.parametreTransport);

        this.getListeTypesBenne();
        this.getListeTransports();

        if (this.societe.id) {
            this.getLignesParamTrans(this.societe.id);
        }

        this.popoverModifParamTrans = {
            templateUrl: 'popoverModifParamTrans.html'
        };
    }

    async getListeTypesBenne() {
        this.startLoading();
        try {
            this.listeTypesBenne = await this.SocietesService.getTypesBenne();
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.stopLoading();
        }
    }

    async getListeTransports() {
        this.startLoading();
        try {
            this.listeTransports = await this.SocietesService.getTransports();
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.stopLoading();
        }
    }

    async deleteParamTrans(paramTrans) {
        this.startLoading();
        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant('SOCIETES.PARAMTRANS.DELETE.TITLE'),
            modalMsg: this.$translate.instant('SOCIETES.PARAMTRANS.DELETE.MESSAGE'),
            headerClass: 'modal-danger'
        });

        modalInstance.result.then(async () => {
            try {
                const id = await this.SocietesService.deleteParamTrans(paramTrans.id);
                this.notification.success('SOCIETES.PARAMTRANS.DELETE.SUCCESS');
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            } finally {
                this.getLignesParamTrans(this.societe.id);
            }
        });
        this.stopLoading();
    }

    async saveParamTrans() {
        if (await this.manualValidator(this.new)) {
            this.startLoading();
            try {
                this.new.idSociete = this.societe.id;
                const id = await this.SocietesService.saveParametreTransport(this.new);
                this.getLignesParamTrans(this.societe.id);
                this.new = {};
                this.notification.success('SOCIETES.PARAMTRANS.CREATED');
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            } finally {
                this.stopLoading();
            }
        }
    }

    async getLignesParamTrans(idSociete) {
        this.startLoading();
        try {
            this.societe.parametreTransport = await this.SocietesService.getParametreTransport(idSociete);
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.stopLoading();
        }
    }

    async getUnite() {
        if (this.new.idtypeBenne > 0) {
            const index = _.findIndex(this.listeTypesBenne, { id: this.new.idtypeBenne });
            if (index > 0) {
                this.new.unite = this.listeTypesBenne[index].unite;
            }
        }
    }

    initUpdate(paramTrans, index) {
        this.edit = angular.copy(paramTrans);
        this.clickedPopoverIndex = index;
    }

    async updateParamTrans() {
        if (await this.manualValidator(this.edit)) {
            this.startLoading();
            try {
                const id = await this.SocietesService.updateParametreTransport(this.edit.id, this.edit);
                this.getLignesParamTrans(this.societe.id);
                this.edit = {};
                this.notification.success('SOCIETES.PARAMTRANS.CREATED');
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            } finally {
                this.stopLoading();
            }
        }
    }

    async manualValidator(line) {
        let checked = false;
        if (line) {
            if (line.idTypeBenne && line.idTransport) {
                if (line.quantiteMinimum || line.quantiteMaximum) {
                    if (line.quantiteMinimum && line.quantiteMaximum && line.quantiteMinimum > line.quantiteMaximum) {
                        // les 2 quantités doivent être cohérentes entre elles
                        this.notification.error('SOCIETES.PARAMTRANS.QTENONCOHERENTES');
                    } else {
                        checked = true;
                    }
                } else {
                    // il faut au moins une quantité
                    this.notification.warning('SOCIETES.PARAMTRANS.MANQUEQTE');
                }
            } else {
                // il manque le Type de Benne et le Transport
                this.notification.warning('SOCIETES.PARAMTRANS.MANQUEIDS');
            }
        } else {
            // la ligne est vide (ne doit pas arriver car objet initialisé vide)
            this.notification.warning('SOCIETES.PARAMTRANS.EMPTYOBJECT');
        }
        return checked;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
