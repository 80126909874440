export default function Configure($stateProvider) {
    $stateProvider.state('revisions-prix', {
        parent: 'gestion',
        url: '/revisions-prix',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
        }
    });

    $stateProvider.state('revisions-prix.generation', {
        url: '/generer',
        template: '<indices-revision-generation></indices-revision-generation>',
        rights: { domain: 'indrevision', right: 'buildsynthesis' },
        ncyBreadcrumb: {
            label: '{{ "INDICES_REVISION.BREADCRUMBS.INDICE_REVISION_GENERATION" | translate}}'
        },
        navigation: {
            menu: 'facturation',
            translate: 'INDICES_REVISION.BREADCRUMBS.INDICE_REVISION_GENERATION',
            order: 8
        },
        search: 'INDICES_REVISION.BREADCRUMBS.INDICE_REVISION_GENERATION'
    });

    $stateProvider.state('revisions-prix.resultat', {
        url: '/resultat',
        params: {
            obj: null
        },
        template: '<indices-revision-resultat></indices-revision-resultat>',
        rights: { domain: 'indrevision', right: 'buildsynthesis' },
        ncyBreadcrumb: {
            parent: 'revisions-prix.generation',
            label: '{{ "INDICES_REVISION.BREADCRUMBS.INDICE_REVISION_RESULTAT_GENERATION" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
