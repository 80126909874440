TramesSaisiesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'TramesSaisiesService',
    'TramesSaisiesTypesService',
    'notification',
    '$transitions',
    'MOPService'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function TramesSaisiesController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    TramesSaisiesService,
    TramesSaisiesTypesService,
    notification,
    $transitions,
    MOPService
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    vm.selectedTrameId = undefined;
    vm.lastSelected = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.trames = [];
    vm.tramesCopy = [];
    vm.filterMobile = null;
    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];
    vm.getTypes = getTypes;

    vm.selectTrame = selectTrame;
    vm.isTrameSelected = isTrameSelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadTrames = loadTrames;
    vm.deleteTrame = deleteTrame;
    vm.setSelected = setSelected;
    vm.eraseAllFilters = eraseAllFilters;
    vm.getDomaineCourant = getDomaineCourant;
    vm.duplicateTrame = duplicateTrame;
    vm.exporter = exporter;
    vm.filterByMobile = filterByMobile;
    vm.MOPService = MOPService;

    function init() {
        vm.MOPService.setMop([{ title: 'TRAMES.BREADCRUMBS.TRAMES_LIST', filename: 'Trames.pdf', application: 'laboratoire' }]);
        //écouter l'event lié au changement de route
        //une fois on change l'url, et on arrive sur la page "trames.list", on désélectionne la ligne sélectionnée (s'il y en a)
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        //quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }

        getTypes();
    }

    init();

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    function getDomaineCourant() {
        if ($state.current.name === 'refsaisieenv.list' || $state.current.name === 'refsaisieenv.list.detail') {
            return 'refsaisieenv';
        } else if ($state.current.name === 'refsaisiectrl.list' || $state.current.name === 'refsaisiectrl.list.detail') {
            return 'refsaisiectrl';
        } else if ($state.current.name === 'refsaisiesecu.list' || $state.current.name === 'refsaisiesecu.list.detail') {
            return 'refsaisiesecu';
        } else if ($state.current.name === 'refsaisie.list' || $state.current.name === 'refsaisie.list.detail') {
            return 'refsaisie';
        }
    }

    function isActuListe() {
        return $state.current.name.indexOf('list') >= 0;
    }

    function stateChangeSuccessFunc(event, toState) {
        if (isActuListe()) {
            vm.setSelected();
        } else {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    async function getTypes(event, toState) {
        vm.types = await TramesSaisiesTypesService.getAll(getDomaineCourant());
    }

    function setSelected(trame) {
        if (trame && trame.id) {
            vm.selectedTrameId = $stateParams.id;
            vm.lastSelected = vm.selectedTrameId;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            vm.selectedTrameId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return $state && $state.current && $state.current.name && !isActuListe() && $stateParams && $stateParams.id;
    }

    function selectTrame(trame) {
        if (trame && trame.id) {
            //si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedTrameId, et charger la vue détail pour le trame sélectionné
            if (vm.selectedTrameId !== trame.id) {
                vm.selectedTrameId = trame.id;
                showDetail(trame.id);
            } else {
                //si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
                vm.selectedTrameId = undefined;
                closeDetail();
            }
        }
    }

    function isTrameSelected(trame) {
        return trame && trame.id && vm.selectedTrameId === trame.id;
    }

    function showDetail(trameId) {
        const route = getDomaineCourant();
        $state.go(route + '.detail', { id: trameId });
    }

    function closeDetail() {
        $state.go(getDomaineCourant() + '.list');
    }

    async function loadTrames(tableState) {
        startLoading();

        //quand on rafraîchit la grille, on revient sur "trames.list", et on ferme la vue détail d'un docce
        //sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
        if ($state && $state.current && !isActuListe() /*$state.current.name !== 'trames.list'*/) {
            $state.go(getDomaineCourant() + '.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.trames = [];

        try {
            const data = await TramesSaisiesService.getTrames(filters, sorts, pagination, getDomaineCourant());

            vm.trames = data.items;
            vm.tramesCopy = angular.copy(vm.trames);
            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteTrame(trame) {
        if (trame && trame.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('TRAMES.DELETE.TITLE', { libelle: trame.libelle }),
                modalMsg: $translate.instant('TRAMES.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await TramesSaisiesService.deleteTrameById(trame.id);
                    notification.success($translate.instant('TRAMES.DELETE.SUCCESS'));

                    vm.selectedTrameId = undefined;

                    // si jamais la vue détail est affichée,
                    // on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
                    if (vm.params.id) {
                        vm.state.go(getDomaineCourant() + '.list');
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadTrames();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function duplicateTrame(trame) {
        if (trame && trame.id) {
            $state.go(getDomaineCourant() + '.duplicate', { id: trame.id });
        }
    }

    function eraseAllFilters() {
        loadTrames();
    }

    async function exporter(modeImpression) {
        try {
            const filters = PaginationService.getFilters(previousTableState);
            const sorts = PaginationService.getSorts(previousTableState);
            const fileName = 'Liste_trames';
            let resultat = undefined;
            try {
                resultat = await TramesSaisiesService.exportListeTrames(getDomaineCourant(), modeImpression, filters, sorts);
            } catch (ex) {
                //si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
                const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
                notification.error('TRAMES.' + msgException);
                return false;
            }
            if (resultat) {
                const data = resultat.data;
                const status = resultat.status;
                let headers = resultat.headers;
                headers = headers();

                const contentType = headers['content-type'];

                const linkElement = document.createElement('a');
                try {
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    if (modeImpression == 0) {
                        linkElement.setAttribute('download', fileName + '.pdf');
                    } else {
                        linkElement.setAttribute('download', fileName + '.xlsx');
                    }

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                } catch (ex) {
                } finally {
                }
                return true;
            }
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }

    function filterByMobile() {
        /**
         * null aucun filtre sur les trames mobiles ou non
         * true trames partagées avec l'app
         * false trames non partagées avec l'app
         */

        if (vm.filterMobile === null) {
            vm.filterMobile = true;
            vm.trames = angular.copy(vm.tramesCopy).filter((x) => {
                return x.isMobile;
            });
        } else if (vm.filterMobile === true) {
            vm.filterMobile = false;
            vm.trames = angular.copy(vm.tramesCopy).filter((x) => {
                return !x.isMobile;
            });
        } else {
            vm.filterMobile = null;
            vm.trames = angular.copy(vm.tramesCopy);
        }
    }
}
