import GestionStocksComposantController from './gestion.stocks.composant.controller';

export default {
    bindings: {
        cellule: '=',
        ligne: '=',
        onUpdate: '&',
        ajustementTotal: '=',
        productionStockeeTotal: '=',
        productionNonStockeeTotal: '=',
        productionTraiteeTotal: '=',
        productionRetraiteeTotal: '=',
        consoTheoriqueTotal: '=',
        retraitementTotal: '=',
        variationTotal: '=',
        stockFinalTotal: '=',
        isActive: '=',
        produits: '=',
        arrondi: '=',
        emplacements: '='
    },
    template: require('./gestion.stocks.composant.html'),
    controller: GestionStocksComposantController
};
