Presse3rModalImportValidator.$inject = ['validator', 'validationHelper'];

export default function Presse3rModalImportValidator(validator, validationHelper) {
    const instance = new validator();
    // instance.ruleFor('Libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('extension').must(BeAValidExtension).withMessage('VALIDATION_FORMAT_INCORRECT');
    // instance.ruleFor('CodeEssai').notEmpty().withMessage('VALIDATION_NOEMPTY');

    // instance.ruleFor('MachineType').notEmpty().withMessage('VALIDATION_NOEMPTY');
    // instance.ruleFor('indice').matches(/^[0-9]$/);
    // instance.ruleFor('indice').notEmpty();

    function BeAValidExtension(file) {
        const extArray = ['3R_Resultat', 'res'];
        return extArray.includes(file.Extension);
    }

    return instance;
}
