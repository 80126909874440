import LieuFormNiveauxController from './lieu.form.niveaux.controller';

export default {
    bindings: {
        lieu: '<',
        form: '=',
        onUpdate: '&'
    },
    template: require('./lieu.form.niveaux.html'),
    controller: LieuFormNiveauxController
};
