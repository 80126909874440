// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
/* @ngInject */
export default function PrelevementsController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    PrelevementsService,
    Import3rService,
    TypesService,
    notification,
    _,
    $uibModal,
    DataShareService,
    moment,
    CampagnesService,
    MassiaApplicationService,
    globalizationManagementService,
    MassiaRightsService,
    SitesService,
    $transitions,
    MOPService,
    ParametreProgrammeService,
    MassiaAuthService,
    DonneeFiltreeUtilisateursService
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    let selectedSaisieId = undefined;
    vm.currentTableState = null;
    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm._ = _;
    vm.DataShareService = DataShareService;
    vm.saisies = [];
    vm.saisiesToClose = [];
    vm.saisiesToOpen = [];
    vm.moment = moment;
    vm.MassiaRightsService = MassiaRightsService;

    // initialisation de l'onglet recherche
    vm.isSearchOngletOpen = true;
    vm.searchingFormSaisies = {};
    vm.searchingFormSaisies.entity = 'saisie';
    vm.themesLoaded = [];
    vm.famillesLoaded = [];
    vm.sitesProdLoaded = [];
    vm.modelesLoaded = [];
    vm.searchingFormSaisies.themeIdSelected = null;
    vm.searchingFormSaisies.familleIdSelected = null;
    vm.searchingFormSaisies.familleCodeSelected = null;
    vm.searchingFormSaisies.siteProdIdSelected = null;
    vm.searchingFormSaisies.modeleIdSelected = null;
    vm.getDomaineCourant = getDomaineCourant;
    vm.domaineCourant = '';
    vm.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    vm.isEdit = false;
    vm.isDisplayMap = false;
    vm.initDates = initDates;

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right'
    };

    vm.manageModeleSelect = manageModeleSelect;
    vm.selectSaisie = selectSaisie;
    vm.isSaisieSelected = isSaisieSelected;
    vm.editCloture = editCloture;
    vm.editAllCloture = editAllCloture;
    vm.editNoneCloture = editNoneCloture;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadSaisies = loadSaisies;
    vm.deleteSaisie = deleteSaisie;
    vm.setSelected = setSelected;
    vm.changeTheme = changeTheme;
    vm.changeFamille = changeFamille;
    vm.manageFamilleSelect = manageFamilleSelect;
    vm.$uibModal = $uibModal;
    vm.print = print;
    vm.reset = reset;
    vm.edit = edit;
    vm.duplicate = duplicate;
    vm.exporter = exporter;
    vm.getSitesProd = getSitesProd;
    vm.getModelesSaisies = getModelesSaisies;
    vm.selectSite = selectSite;
    vm.changeSite = changeSite;
    vm.miseEnEvidence = miseEnEvidence;
    vm.createSMCM = createSMCM;
    vm.domaineCourant = '';
    vm.isCampagne = false;
    vm.carteChargee = false;
    vm.map = undefined;
    vm.marqueursAffiches = [];
    vm.afficheCode = true;
    vm.updateCloture = updateCloture;
    vm.editionCloture = editionCloture;
    vm.initMultiSelects = initMultiSelects;
    vm.manageLaboSelect = manageLaboSelect;
    vm.importFichiers3R = importFichiers3R;
    vm.getTypes = getTypes;
    vm.MOPService = MOPService;
    vm.ParametreProgrammeService = ParametreProgrammeService;
    vm.MassiaAuthService = MassiaAuthService;
    vm.DonneeFiltreeUtilisateursService = DonneeFiltreeUtilisateursService;

    vm.famille = null;

    vm.$onInit = async function init() {
        vm.MOPService.setMop([{ title: 'PRELEVEMENTS.BREADCRUMBS.PRELEVEMENTS_LIST', filename: 'Prelevements.pdf', application: 'laboratoire' }]);
        initDates();
        vm.clotureAccess = vm.MassiaRightsService.userHasRight('clotplv', 'update', 'laboratoire');
        getDomaineCourant();
        //écouter l'event lié au changement de route
        //une fois on change l'url, et on arrive sur la page "saisies.list", on désélectionne la ligne sélectionnée (s'il y en a)
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        //$transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        //quand on est sur la page ayant la vue détail ouverte, on présélectionne la ligne
        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }

        // s'il y a quelque chose dans le DataShare
        if (_.isEmpty(vm.DataShareService.savedData) == false) {
            // on cherche l'entité saisie
            const item = _.find(vm.DataShareService.savedData, { entity: 'saisie' });
            if (item != null) {
                await updateSearchForm();
                if (vm.domaineCourant != 'prelevements') {
                    changeTheme();
                } else {
                    changeFamille();
                }
            }
        }

        //getTypes();
        if (!vm.searchingFormSaisies.themeIdSelected && $stateParams.idTheme) {
            vm.searchingFormSaisies.themeIdSelected = parseInt($stateParams.idTheme);
            if (vm.domaineCourant != 'prelevements') {
                changeTheme();
            } else {
                changeTheme();
            }
        }
        if (vm.domaineCourant != 'prelevements') {
            await getThemes();
        } else {
            await getFamilles();
        }
        await getModelesSaisies();
        await getSitesProd();
        await getLaboratoire();
        await initCarte();

        // si en revenant d'une saisie on a des données, on reload les saisies
        //VP : pas besoin car double chargement (scintillement)
        // if (vm.searchingFormSaisies.themeIdSelected != 0 || vm.searchingFormSaisies.familleIdSelected != 0) {
        //     await vm.loadSaisies(null);
        // }
        initMultiSelects();
        await getConformitesList();
    };

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    this.onSelectedFilter = ($filters) => {
        vm.filters = $filters;
        loadSaisies(vm.currentTableState);
    };

    function initDates() {
        // initialise la date de debut pour avoir un an glissant
        const maDate = new Date();
        const year = maDate.getFullYear() - 1;
        const mois = 11 - maDate.getMonth();
        let day = maDate.getDate() - 1;
        if (day == 0) {
            if ([3, 5, 7, 8, 10, 12].indexOf(mois) > 0) {
                day = 30;
            } else {
                day = 31;
            }
        }
        const firstDay = new Date(year, mois, day, 0, 0, 0, 0, 0);
        vm.searchingFormSaisies.dateDebut = vm.moment(firstDay).format(vm.dateFormat);
        vm.searchingFormSaisies.dateFin = vm.moment(maDate).format(vm.dateFormat);
    }

    async function initCarte() {
        const zoom = 5;
        const longitude = 3.5;
        const latitude = 45.5;

        vm.map = L.map('map').setView([latitude, longitude], zoom); //par défaut

        //vm.map.scrollWheelZoom.disable();

        //Couches
        const OSMFr = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            //z:niveau de zoom, x et y coordonnées du pavé
            attribution: '&copy; Les contributeurs <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            maxZoom: 20 //zoom maximum de la carte
        });
        OSMFr.addTo(vm.map);

        const datageojson = await PrelevementsService.getSitesGeoJSon(MassiaApplicationService.getApplication());
        const blueMarker = L.icon({
            iconUrl: 'assets/img/pin_GO.png',
            iconSize: [32, 32], // size of the icon
            iconAnchor: [4, 27] // point of the icon which will correspond to marker's location
        });

        L.geoJSON(datageojson, {
            pointToLayer: function (site, latlng) {
                const marqueur = L.marker(latlng, { icon: blueMarker });
                marqueur.on('click', function (e) {
                    vm.selectSite({ id: site.properties.id, code: site.properties.code });
                });
                vm.marqueursAffiches.push(marqueur);
                return marqueur;
            }
        }) /*.bindPopup(function (layer) {
            let html = '<b>Code : </b>' + layer.feature.properties.code +
                '<br><b>Libelle : </b>' + layer.feature.properties.libelle +
                '<br><b>Type : </b>' + layer.feature.properties.type;
            return html;
        })*/
            .addTo(vm.map);

        const Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            //z:niveau de zoom, x et y coordonnées du pavé
            attribution: '&copy; Les contributeurs <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            maxZoom: 20 //zoom maximum de la carte
        });
        const EsriLayer = L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            attribution:
                '&copy; <a href="http://www.esri.com/">Esri</a>, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community',
            maxZoom: 18
        });

        const baseLayer = {
            'OpenStreetMap France': OSMFr,
            Satellite: EsriLayer,
            'OpenStreetMap Org': Mapnik
        };
        const overlaysLayers = {};
        /*if(topo)
			overlaysLayers['Plan topographique']= topo;
		for(var i=0; i<cadas.length; i++){
			overlaysLayers['Cadastre '+cadas[i].nom]= cadas[i].carte;
		}*/

        L.control.layers(baseLayer, overlaysLayers, { collapsed: true }).addTo(vm.map);
        //vm.carteChargee = true;
    }

    async function selectSite(site) {
        if (site && site.id) {
            if (vm.searchingFormSaisies.siteProdIdSelected !== site.id) {
                vm.searchingFormSaisies.siteProdIdSelected = site.id;
                vm.miseEnEvidence(site.code);
                await getModelesSaisies();
                vm.loadSaisies();
            }
        }
    }

    async function changeSite() {
        const siteChoisi = _.find(vm.sitesProdLoaded, { id: vm.searchingFormSaisies.siteProdIdSelected });
        vm.searchingFormSaisies.siteProdCodeSelected = siteChoisi.code;
        vm.searchingFormSaisies.siteProdLibelleSelected = siteChoisi.libelle;
        vm.miseEnEvidence(siteChoisi.code);
        await getModelesSaisies();
        vm.loadSaisies();
    }

    function miseEnEvidence(codeSite) {
        const blueMarker = L.icon({
            iconUrl: 'assets/img/pin_GO.png',
            iconSize: [32, 32], // size of the icon
            iconAnchor: [4, 27] // point of the icon which will correspond to marker's location
        });
        const meeMarker = L.icon({
            iconUrl: 'assets/img/pin_GM.png',
            iconSize: [32, 32], // size of the icon
            iconAnchor: [4, 27] // point of the icon which will correspond to marker's location
        });
        for (let j = 0; j < vm.marqueursAffiches.length; j++) {
            const marqueur = vm.marqueursAffiches[j];
            if (codeSite == marqueur.feature.properties.code) {
                marqueur.setIcon(meeMarker);
                /*marqueur.openPopup();
				vm.map.setView(marqueur.latLng);*/
                //vm.map.fireEvent('click',{latlng:marqueur.latLng});
            } else {
                marqueur.setIcon(blueMarker);
            }
        }
    }

    function getDomaineCourant() {
        vm.domaineCourant = '';
        vm.domaineCampagneCourant = '';
        if ($state.current.name === 'saisies.list' || $state.current.name === 'saisies.list.detail') {
            vm.domaineCourant = 'saisies';
            vm.domaineCampagneCourant = 'campagnes';
        } else if ($state.current.name === 'saisiectrl.list' || $state.current.name === 'saisiectrl.list.detail') {
            vm.domaineCourant = 'saisiectrl';
            vm.domaineCampagneCourant = 'campagnesctrl';
        } else if ($state.current.name === 'saisiesecu.list' || $state.current.name === 'saisiesecu.list.detail') {
            vm.domaineCourant = 'saisiesecu';
            vm.domaineCampagneCourant = 'campagnessecu';
        } else if ($state.current.name === 'prelevements.list' || $state.current.name === 'prelevements.list.detail') {
            vm.domaineCourant = 'prelevements';
            vm.domaineCampagneCourant = 'campagneslabo';
        }
        return vm.domaineCourant;
    }

    // recuperation des donnees du DataShare
    async function updateSearchForm() {
        vm.searchingFormSaisies = vm.DataShareService.getDataShare('saisie');
    }

    async function getThemes() {
        vm.themesLoaded = await PrelevementsService.getThemes(vm.domaineCourant);
        if (vm.themesLoaded.length == 1) {
            vm.searchingFormSaisies.themeIdSelected = vm.themesLoaded[0].id;
        }
    }

    async function getFamilles() {
        vm.famillesLoaded = await PrelevementsService.getFamilles();
        if (vm.famillesLoaded.length == 1) {
            vm.searchingFormSaisies.familleIdSelected = vm.famillesLoaded[0].idFamille;
            vm.searchingFormSaisies.familleCodeSelected = vm.famillesLoaded[0].codeFamille;
        }
        getDefaultselectedfamily(vm.famillesLoaded);
    }

    async function getLaboratoire() {
        const data = await SitesService.getSitesLaboratoires();
        vm.labosLoaded = data.items;
        if (vm.labosLoaded.length == 1) {
            vm.searchingFormSaisies.laboIdSelected = vm.labosLoaded[0].id;
        }

        /* plus d'utilité depuis l'ajout du massia multi select
			// if (vm.labosLoaded.length > 0)
			//     vm.labosLoaded.splice(0, 0, { id: 0, libelle: '', code: '' });
		*/
    }

    async function getModelesSaisies() {
        vm.modelesLoaded = await PrelevementsService.getModelesSaisies(
            vm.domaineCourant,
            vm.searchingFormSaisies.themeIdSelected,
            vm.searchingFormSaisies.siteProdIdSelected,
            vm.searchingFormSaisies.familleIdSelected
        );
        /*if (vm.modelesLoaded.length == 1) {
			vm.searchingFormSaisies.modeleIdSelected = vm.modelesLoaded[0].id;
		} else {*/

        /* plus d'utilité depuis l'ajout du massia multi select

			var modeleVide = { id: 0, code: '', libelle: '' };
			vm.modelesLoaded.splice(0, 0, modeleVide);

		*/
        //}
    }

    async function getSitesProd() {
        try {
            if (vm.searchingFormSaisies.familleCodeSelected) {
                vm.sitesProdLoaded = await SitesService.getSitesProducteursByFamille(
                    vm.searchingFormSaisies.familleCodeSelected,
                    MassiaApplicationService.getApplication()
                );
            } else {
                vm.sitesProdLoaded = await PrelevementsService.getSitesProd(MassiaApplicationService.getApplication());
            }
            if (vm.sitesProdLoaded.length == 1) {
                vm.searchingFormSaisies.siteProdIdSelected = vm.sitesProdLoaded[0].id;
            } else {
                const siteVide = { id: 0, code: '', libelle: '' };
                vm.sitesProdLoaded.splice(0, 0, siteVide);
            }
        } catch (error) {}
    }

    async function getTypes() {
        startLoading();
        try {
            let familleCode = '';

            if (vm.famille && vm.famille.code) {
                familleCode = vm.famille.code;
            }
            vm.types = await TypesService.getTypeByDomaine(vm.domaineCourant);
        } catch (err) {
            if (err.data) {
                notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            stopLoading();
        }
    }

    function stateChangeSuccessFunc(event, toState) {
        if (!$state.params.id) {
            return vm.setSelected();
        }
        vm.setSelected({ id: parseInt($state.params.id) });
    }

    function setSelected(saisie) {
        if (saisie && saisie.id) {
            selectedSaisieId = $stateParams.id;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            selectedSaisieId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return (
            $state &&
            $state.current &&
            $state.current.name &&
            $state.current.name === vm.domaineCourant + '.detail' &&
            $stateParams &&
            $stateParams.id
        );
    }

    function selectSaisie(saisie) {
        if (saisie && saisie.id) {
            //si c'est une nouvelle ligne qui a été sélectionnée, on update la variable locale selectedSaisieId, et charger la vue détail pour la saisie sélectionnée
            if (vm.selectedSaisieId !== saisie.id) {
                vm.selectedSaisieId = saisie.id;
                showDetail(saisie.id);
            } else {
                //si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
                vm.selectedSaisieId = undefined;
                closeDetail();
            }
        }
    }

    function isSaisieSelected(saisie) {
        return saisie && saisie.id && vm.selectedSaisieId === saisie.id;
    }

    function showDetail(saisieId) {
        // updateDataShare
        vm.DataShareService.setDataShare(vm.searchingFormSaisies, 'saisie');
        $state.go(vm.domaineCourant + '.detail', { id: saisieId });
    }

    function edit(saisie) {
        // updateDataShare
        vm.DataShareService.setDataShare(vm.searchingFormSaisies, 'saisie');
        if (vm.isCampagne) {
            $state.go(vm.domaineCourant + '.edit-campagne', {
                idTheme: vm.searchingFormSaisies.themeIdSelected,
                id: saisie.id
            });
        } else {
            if (vm.domaineCourant != 'prelevements') {
                $state.go(vm.domaineCourant + '.edit-saisie', {
                    idTheme: vm.searchingFormSaisies.themeIdSelected,
                    id: saisie.id
                });
            } else {
                $state.go(vm.domaineCourant + '.edit-prelevement', {
                    idFamille: vm.searchingFormSaisies.familleIdSelected,
                    id: saisie.id
                });
            }
        }
    }

    function duplicate(saisie) {
        if (vm.isCampagne) {
            console.error('Duplication de campagne : non implémenté pour le moment');
        } else {
            if (vm.domaineCourant != 'prelevements') {
                $state.go(vm.domaineCourant + '.duplicate-saisie', {
                    idTheme: vm.searchingFormSaisies.themeIdSelected,
                    id: saisie.id
                });
            } else {
                $state.go(vm.domaineCourant + '.duplicate-prelevement', {
                    idFamille: vm.searchingFormSaisies.familleIdSelected,
                    id: saisie.id
                });
            }
        }
    }

    function closeDetail() {
        $state.go(vm.domaineCourant + '.list');
    }

    function createSMCM() {
        // updateDataShare
        vm.DataShareService.setDataShare(vm.searchingFormSaisies, 'saisie');
        if (vm.isCampagne) {
            $state.go(vm.domaineCourant + '.new-campagne', { idTheme: vm.searchingFormSaisies.themeIdSelected });
        } else {
            if (vm.domaineCourant != 'prelevements') {
                $state.go(vm.domaineCourant + '.new-prelevement', { idTheme: vm.searchingFormSaisies.themeIdSelected });
            } else {
                $state.go(vm.domaineCourant + '.new-prelevement', { idFamille: vm.searchingFormSaisies.familleIdSelected });
            }
        }
    }

    async function changeTheme() {
        try {
            vm.isCampagne = await PrelevementsService.hasThemeCampagne(vm.searchingFormSaisies.themeIdSelected);
            if (vm.isCampagne) {
                vm.afficheCode = await CampagnesService.afficheCode(vm.domaineCampagneCourant);
            } else {
                vm.afficheCode = await PrelevementsService.afficheCode(vm.domaineCourant);
            }
            await vm.getModelesSaisies();
        } catch (ex) {
            notification.error(ex.data);
        }
    }

    async function initMultiSelects() {
        vm.famillesSelected = [];
        vm.laboratoireSelected = [];
        vm.modelesSelected = [];

        // getFamilleSelected
        if (vm.searchingFormSaisies.familleIdSelected) {
            const selectedFamille = vm.famillesLoaded.find((x) => x.idFamille === vm.searchingFormSaisies.familleIdSelected);
            if (selectedFamille) {
                selectedFamille.selected = true;
                vm.famille = selectedFamille;
            }
        }

        // getLaboratoireSelected
        if (vm.searchingFormSaisies.laboIdSelected) {
            const selectedLaboratoire = vm.labosLoaded.find((x) => x.id === vm.searchingFormSaisies.laboIdSelected);
            if (selectedLaboratoire) {
                selectedLaboratoire.selected = true;
            }
        }
        // getModeleSelected
        vm.modelesLoaded = await PrelevementsService.getModelesSaisies(
            vm.domaineCourant,
            vm.searchingFormSaisies.themeIdSelected,
            vm.searchingFormSaisies.siteProdIdSelected,
            vm.searchingFormSaisies.familleIdSelected
        );

        if (vm.searchingFormSaisies.modeleIdSelected) {
            const selectedModele = vm.modelesLoaded.find((x) => x.id === vm.searchingFormSaisies.modeleIdSelected);
            if (selectedModele) {
                selectedModele.selected = true;
            }
        }
    }

    async function manageModeleSelect() {
        vm.searchingFormSaisies.modeleIdSelected = vm.modelesSelected[0]?.id;
    }

    async function manageLaboSelect() {
        try {
            vm.searchingFormSaisies.laboIdSelected = vm.laboratoireSelected[0]?.id;

            //await changeFamille();
        } catch (error) {
            console.log(error);
        }
    }

    async function manageFamilleSelect() {
        try {
            vm.famille = vm.famillesLoadedSelected[0];
            vm.searchingFormSaisies.familleIdSelected = vm.famillesLoadedSelected[0]?.idFamille;
            vm.searchingFormSaisies.familleCodeSelected = vm.famillesLoadedSelected[0]?.codeFamille;
            await changeFamille();
        } catch (error) {
            console.log(error);
        }
    }

    async function changeFamille() {
        try {
            vm.afficheCode = await PrelevementsService.afficheCode(vm.domaineCourant);
            if (vm.searchingFormSaisies.familleIdSelected) {
                await vm.getModelesSaisies();
            }
            await vm.getSitesProd();
        } catch (ex) {
            notification.error(ex.data);
        }
    }

    async function loadSaisies(tableState, isEdit = null) {
        startLoading();
        try {
            if (isEdit) {
                vm.isEdit = !isEdit;
            }

            if ($state) {
                //quand on rafraîchit la grille, on revient sur "saisies.list", et on ferme la vue détail d'une saisie
                //sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
                if ($state && $state.current && $state.current.name !== vm.domaineCourant + '.list') {
                    $state.go(vm.domaineCourant + '.list');
                }

                if (tableState) {
                    previousTableState = tableState;
                }

                const filters = PaginationService.getFilters(previousTableState);
                const sorts = PaginationService.getSorts(previousTableState);
                const pagination = PaginationService.getPagination(previousTableState);

                vm.saisies = [];
                vm.saisiesToClose = [];
                vm.saisiesToOpen = [];
                let data = null;
                if (vm.searchingFormSaisies.themeIdSelected && vm.searchingFormSaisies.themeIdSelected > 0) {
                    data = await PrelevementsService.getSaisies(
                        filters,
                        sorts,
                        pagination,
                        vm.searchingFormSaisies.themeIdSelected,
                        vm.searchingFormSaisies.siteProdIdSelected,
                        vm.searchingFormSaisies.dateDebut,
                        vm.searchingFormSaisies.dateFin,
                        /*vm.searchingFormSaisies.modeleIdSelected*/ 0,
                        0
                        /*vm.filters,*/
                    );

                    vm.saisies = data.items;
                    if (previousTableState) {
                        PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
                    } else {
                        vm.saisies = [];
                    }
                } else if (
                    vm.searchingFormSaisies.familleIdSelected &&
                    vm.searchingFormSaisies.familleIdSelected > 0 &&
                    vm.searchingFormSaisies.laboIdSelected &&
                    vm.searchingFormSaisies.laboIdSelected > 0
                ) {
                    data = await PrelevementsService.getSaisies(
                        filters,
                        sorts,
                        pagination,
                        0,
                        vm.searchingFormSaisies.siteProdIdSelected,
                        vm.searchingFormSaisies.dateDebut,
                        vm.searchingFormSaisies.dateFin,
                        /*vm.searchingFormSaisies.modeleIdSelected*/ 0,
                        vm.searchingFormSaisies.familleIdSelected,
                        vm.searchingFormSaisies.laboIdSelected
                        /*vm.filters,*/
                    );

                    vm.saisies = data.items;
                    if (previousTableState) {
                        PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
                    } else {
                        vm.saisies = [];
                    }
                } else if (
                    !vm.searchingFormSaisies.laboIdSelected &&
                    vm.searchingFormSaisies.familleIdSelected &&
                    vm.searchingFormSaisies.familleIdSelected > 0
                ) {
                    data = await PrelevementsService.getSaisies(
                        filters,
                        sorts,
                        pagination,
                        0,
                        vm.searchingFormSaisies.siteProdIdSelected,
                        vm.searchingFormSaisies.dateDebut,
                        vm.searchingFormSaisies.dateFin,
                        /*vm.searchingFormSaisies.modeleIdSelected*/ 0,
                        vm.searchingFormSaisies.familleIdSelected,
                        vm.searchingFormSaisies.laboIdSelected
                        /*vm.filters,*/
                    );

                    vm.saisies = data.items;
                    if (previousTableState) {
                        PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
                    } else {
                        vm.saisies = [];
                    }
                }
                getTypes();
                // console.log(vm.saisies);
            }
            // vm.currentTableState = previousTableState || tableState;
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteSaisie(saisie) {
        if (saisie && saisie.id) {
            let debMsg = 'PRELEVEMENTS.DELETE_SAISIE';
            let lib = '';
            if (vm.isCampagne) {
                debMsg = 'PRELEVEMENTS.DELETE_CAMPAGNE';
                lib = saisie.nomSiteProducteur + ' (' + saisie.dateDebutCampagne + ' - ' + saisie.dateFinCampagne + ')';
            } else {
                if (MassiaApplicationService.getApplication() == 'qse') {
                    lib = saisie.nomSiteProducteur + ' ' + saisie.nomPointMesure + ' (' + saisie.date + ')';
                } else {
                    lib = saisie.nomSiteProducteur + ' ' + saisie.nomProduit + ' (' + saisie.date + ')';
                }
            }
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant(debMsg + '.TITLE', { code: lib }),
                modalMsg: $translate.instant(debMsg + '.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    if (vm.isCampagne) {
                        await CampagnesService.deleteCampagneById(saisie.id);
                    } else {
                        await PrelevementsService.deleteSaisieById(saisie.id);
                    }
                    notification.success($translate.instant(debMsg + '.SUCCESS'));

                    selectedSaisieId = undefined;

                    // si jamais la vue détail est affichée,
                    // on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
                    if (vm.params.id) {
                        vm.state.go(vm.domaineCourant + '.list');
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    await vm.loadSaisies(null);
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }

    function editCloture(saisie) {
        if (saisie) {
            if (saisie.cloture) {
                var idxOld = vm.saisiesToClose.findIndex((x) => x == saisie.id);
                if (idxOld > -1) {
                    vm.saisiesToClose.splice(idxOld, 1);
                } else {
                    vm.saisiesToOpen.push(saisie.id);
                }
            } else {
                var idxOld = vm.saisiesToOpen.findIndex((x) => x == saisie.id);
                if (idxOld > -1) {
                    vm.saisiesToOpen.splice(idxOld, 1);
                } else {
                    vm.saisiesToClose.push(saisie.id);
                }
            }
            return (saisie.cloture = !saisie.cloture);
        }
    }

    async function updateCloture(saisies, isEdit) {
        try {
            let isSaved = false;
            if (saisies && saisies.length > 0 && (vm.saisiesToClose.length > 0 || vm.saisiesToOpen.length > 0)) {
                await PrelevementsService.updateSaisiesCloture(vm.saisiesToClose, vm.saisiesToOpen);
                isSaved = true;
                vm.saisiesToClose = [];
                vm.saisiesToOpen = [];
            }
            if (isSaved) {
                vm.isEdit = !isEdit;
                notification.success($translate.instant('PRELEVEMENTS.UPDATED_CLOTURE'));
            }
        } catch (error) {
            notification.error(error);
        }
    }

    function editAllCloture(saisies) {
        if (saisies) {
            vm.saisiesToOpen = [];
            vm.saisiesToClose = [];
            for (let i = 0; i < saisies.length; i++) {
                const saisie = saisies[i];
                saisie.cloture = true;
                vm.saisiesToClose.push(saisie.id);
            }
            return saisies;
        }
    }

    function editNoneCloture(saisies) {
        if (saisies) {
            vm.saisiesToOpen = [];
            vm.saisiesToClose = [];
            for (let i = 0; i < saisies.length; i++) {
                const saisie = saisies[i];
                saisie.cloture = false;
                vm.saisiesToOpen.push(saisie.id);
            }
            return saisies;
        }
    }

    function reset() {
        vm.searchingFormSaisies.themeIdSelected = null;
        vm.searchingFormSaisies.familleIdSelected = null;
        vm.searchingFormSaisies.familleCodeSelected = null;
        vm.searchingFormSaisies.siteProdIdSelected = null;
        vm.searchingFormSaisies.modeleIdSelected = null;

        // initialise les dates de debut et fin au premier et dernier jour du mois en cours
        vm.initDates();
        vm.saisies = [];
        if (vm.domaineCourant != 'prelevements') {
            getThemes();
        } else {
            getFamilles();
        }
    }

    async function exporter(modeImpression) {
        try {
            const filters = PaginationService.getFilters(previousTableState);
            const sorts = PaginationService.getSorts(previousTableState);
            const fileName = 'Liste_saisies';
            let resultat = undefined;
            try {
                resultat = await PrelevementsService.exportListeSaisies(
                    modeImpression,
                    vm.searchingFormSaisies.themeIdSelected,
                    vm.searchingFormSaisies.siteProdIdSelected,
                    vm.searchingFormSaisies.dateDebut,
                    vm.searchingFormSaisies.dateFin,
                    vm.searchingFormSaisies.modeleIdSelected,
                    filters,
                    sorts
                );
            } catch (ex) {
                //si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
                const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
                notification.error('PRELEVEMENTS.' + msgException);
                return false;
            }
            if (resultat) {
                const data = resultat.data;
                const status = resultat.status;
                let headers = resultat.headers;
                headers = headers();

                const contentType = headers['content-type'];

                const linkElement = document.createElement('a');
                try {
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    if (modeImpression == 0) {
                        linkElement.setAttribute('download', fileName + '.pdf');
                    } else {
                        linkElement.setAttribute('download', fileName + '.xlsx');
                    }

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                } catch (ex) {
                } finally {
                }
                return true;
            }
        } catch (ex) {
            notification.error(ex.data);
            return false;
        }
    }

    function editionCloture(isEdit) {
        vm.isEdit = !isEdit;
        return vm.isEdit;
    }

    async function getConformitesList() {
        vm.conformites = await PrelevementsService.getConformitesList();
    }

    async function importFichiers3R() {
        const configList = await Import3rService.getParams3rConfigList();

        if (configList.length === 0) {
            return notification.error($translate.instant('PRELEVEMENTS.IMPORT_PRESSE.ERROR.NO_CONFIG'));
        }

        const configActive = configList.find(
            (config) => config.isActive && (config.machineType === 1 ? this.famille.code === 'BPE' : this.famille.code === 'CIM')
        );

        if (!configActive) {
            return notification.error($translate.instant('PRELEVEMENTS.IMPORT_PRESSE.ERROR.NO_CONFIG_ACTIVATE'));
        }

        const _this = vm;
        $uibModal
            .open({
                animation: true,
                backdrop: 'static',
                size: 'md',
                component: 'presse3rModalImport',
                resolve: {
                    parent: () => _this,
                    famille: () => _this.famille,
                    haveIterateOrder: () => configActive.haveIterateOrder
                }
            })
            .result.then(
                async () => {
                    // notification.success($translate.instant('DOCUMENTS.DOC_CHARGEE_SUCESS'));
                },
                (err) => {
                    err === null ? console.log(err) : notification.error(err);
                }
            );
    }

    async function getDefaultselectedfamily(familyList) {

        if (familyList) {
            const currentUser = vm.MassiaAuthService.getAuthenticatedUser();
            const userParameters = await vm.DonneeFiltreeUtilisateursService.getDonneeFiltreeUtilisateur(
                currentUser.id,
                MassiaApplicationService.getApplication()
                );
            const familySelectedInUserParam = familyList.find((family) => family.idFamille === userParameters.idDefaultFamily);

            if (familySelectedInUserParam) {
                familySelectedInUserParam.selected = true;
                vm.searchingFormSaisies.familleCodeSelected = familySelectedInUserParam.code;
                vm.searchingFormSaisies.familleIdSelected = familySelectedInUserParam.idFamille;
                vm.loadSaisies();
            } else {
                const CodeFamilyInGeneralParam = await vm.ParametreProgrammeService.getOneByCode('FamPdtDefaut');

                if (CodeFamilyInGeneralParam) {
                    const familySelectedInGeneralParam = familyList.find((family) => family.code === CodeFamilyInGeneralParam);
                    familySelectedInGeneralParam.selected = true;
                    vm.searchingFormSaisies.familleCodeSelected = familySelectedInGeneralParam.code;
                    vm.searchingFormSaisies.familleIdSelected = familySelectedInGeneralParam.idFamille;
                    vm.loadSaisies();
                }
            }
        }
    }
}
