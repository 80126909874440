export default function Configure($stateProvider) {
    $stateProvider.state('themes', {
        parent: 'common',
        url: '/themes',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('themes.list', {
        url: '/list',
        template: '<themes></themes>',
        rights: { domain: 'themes', right: 'read', application: 'qse' },
        ncyBreadcrumb: {
            parent: 'themes',
            label: '{{ "THEMES.BREADCRUMBS.THEMES_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'THEMES.BREADCRUMBS.THEMES_LIST',
            order: 1
        },
        search: 'THEMES.BREADCRUMBS.THEMES_LIST'
    });

    /*$stateProvider.state('themes.list.detail', {
        url: '/{id}',
        template: '<theme-detail></theme-detail>',
        rights: {domain: 'themes', right: 'read'},
        ncyBreadcrumb: {
            parent: 'themes.list',
            label: '{{ "THEMES.BREADCRUMBS.THEME_DETAIL" | translate }}'
        }
    });*/

    $stateProvider.state('themes.new', {
        url: '/new',
        template: '<theme-form></theme-form>',
        rights: { domain: 'themes', right: 'create' },
        ncyBreadcrumb: {
            parent: 'themes.list',
            label: '{{ "THEMES.BREADCRUMBS.THEME_NEW" | translate}}'
        },
        search: 'THEMES.BREADCRUMBS.THEME_NEW'
    });

    $stateProvider.state('themes.edit', {
        url: '/{id}/edit',
        template: '<theme-form></theme-form>',
        rights: { domain: 'themes', right: 'update' },
        ncyBreadcrumb: {
            parent: 'themes.list',
            label: '{{ "THEMES.BREADCRUMBS.THEME_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
