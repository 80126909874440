ThemeValidator.$inject = ['validator'];

export default function ThemeValidator(validator) {
    const instance = new validator();

    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('idMetier').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    return instance;
}
