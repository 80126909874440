export default class SyntheseEntitiesFTPController {
    constructor(
        $scope,
        $state,
        $stateParams,
        $translate,
        notification,
        moment,
        $uibModal,
        MassiaApplicationService,
        SyntheseEntitiesService,
        SyntheseEntitiesCommunicationService,
        FTPArchiveeService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.notification = notification;
        this.moment = moment;
        this.$uibModal = $uibModal;

        this.MassiaApplicationService = MassiaApplicationService;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
        this.FTPArchiveeService = FTPArchiveeService;
    }

    async $onInit() {
        this.dragOptions = {
            placeholder: 'table-row-ui-placeholder',
            revert: true,
            animation: 200,
            axis: 'y',
            'ui-preserve-size': true
        };

        this.activite = this.MassiaApplicationService.getApplication();

        this.startLoading();
        this.ftpArchiveeModel = {
            lstFTP: [],
            lstProduits: [],
            lstProducteurs: [],
            lstNormes: [],
            filteredLstFTP: [],
            filteredLstProduits: [],
            filteredLstProducteurs: [],
            filteredLstNormes: [],
            selFTP: null,
            selProduit: null,
            selProducteur: null,
            selNorme: null,
            selDate: null
        };

        const that = this;
        this.$scope.$watch('$ctrl.ftpArchiveeModel.selDate', function (newValue, oldValue) {
            that.changeDate();
        });
        this.$scope.$watch(
            '$ctrl.syntheseEntity.listeSyntheseCritereValeurBlocks',
            function (newValue, oldValue) {
                if (newValue && that.syntheseEntity && that.syntheseEntity.typeDeSynthese === 10) {
                    that.manageSelectionToFTP();
                }
            },
            true
        );

        await this.prepareFTPArchiveeDatas();
        if (this.syntheseEntity.typeDeSynthese === 10) {
            this.manageSelectionToFTP();
            this.selectFTP(true);
        }
        if (this.ftpArchiveeModel && this.ftpArchiveeModel.selProducteur) {
            this.ftpArchiveeModel.filteredLstProduits = this.ftpArchiveeModel.lstProduits?.filter(
                (x) => x.sites && x.sites.includes(this.ftpArchiveeModel.selProducteur.libelle)
            );
        }
        this.stopLoading();
    }

    $onDestroy() {}

    async prepareFTPArchiveeDatas() {
        this.ftpArchiveeModel.lstProducteurs = await this.FTPArchiveeService.getAllProducteurs('GRAN', this.activite);
        this.loadProduit();
        this.ftpArchiveeModel.lstNormes = await this.FTPArchiveeService.getAllNormes();

        const lstFTP = await this.FTPArchiveeService.getAllFTPArchivee();
        this.ftpArchiveeModel.lstFTP = lstFTP.items;
        this.ftpArchiveeModel.filteredLstFTP = angular.copy(this.ftpArchiveeModel.lstFTP);
    }

    async loadProduit() {
        this.ftpArchiveeModel.lstProduits = await this.FTPArchiveeService.getAllProduits(this.activite, 'GRAN');
        this.ftpArchiveeModel.filteredLstProduits = this.ftpArchiveeModel.lstProduits;
    }

    manageSelectionToFTP() {
        if (this.syntheseEntity.listeSyntheseCritereValeurBlocks) {
            const firstBlock = this.syntheseEntity.listeSyntheseCritereValeurBlocks[0];
            if (firstBlock) {
                const produitLine = firstBlock.listeSyntheseCritereValeurLignes.find((e) => {
                    return e.domaineCode.toLowerCase() === 'produits';
                });
                if (produitLine && produitLine.selectedCritAff && produitLine.selectedCritAff.length > 0) {
                    this.ftpArchiveeModel.selProduit = this.ftpArchiveeModel.lstProduits.find((e) => {
                        return e.id === produitLine.selectedCritAff[0].id;
                    });
                    this.selectedProduit = this.ftpArchiveeModel.selProduit ? true : false;
                }

                const producteurLine = firstBlock.listeSyntheseCritereValeurLignes.find((e) => {
                    return e.domaineCode.toLowerCase() === 'siteprod';
                });
                if (producteurLine && producteurLine.selectedCritAff && producteurLine.selectedCritAff.length > 0) {
                    this.ftpArchiveeModel.selProducteur = this.ftpArchiveeModel.lstProducteurs.find((e) => {
                        return e.id === producteurLine.selectedCritAff[0].id;
                    });
                    this.selectedProducteur = this.ftpArchiveeModel.selProducteur ? true : false;
                }

                this.filterDisplayFTP();
            }
        }
    }

    setProducteurAheadAs() {
        this.selectedProducteur = true;
        this.filterDisplayFTP();
    }
    async changeProducteur(data) {
        this.resetSelected(this.ftpArchiveeModel.lstProducteurs);
        // this.ftpArchiveeModel.lstProduits = await this.FTPArchiveeService.getAllProduits();
        if (this.ftpArchiveeModel.selProducteur && this.ftpArchiveeModel.selProducteur.code === data.code) {
            //this.selectedProducteur = false;
            this.ftpArchiveeModel.filteredLstProduits = this.ftpArchiveeModel.lstProduits;
            this.ftpArchiveeModel.selProducteur = null;
            this.filterDisplayFTP();
        } else {
            this.ftpArchiveeModel.filteredLstProduits = this.ftpArchiveeModel.lstProduits.filter(
                (x) => x.sites && x.sites.some((e) => e.id === data.id)
            );
            this.ftpArchiveeModel.selProducteur = data;
            this.filterDisplayFTP();
        }
    }

    setProduitAheadAs() {
        this.selectedProduit = true;
        this.filterDisplayFTP();
    }
    async changeProduit(data) {
        this.resetSelected(this.ftpArchiveeModel.filteredLstProduits);
        if (this.ftpArchiveeModel.selProduit && this.ftpArchiveeModel.selProduit.code === data.code) {
            //this.selectedProducteur = false;
            this.ftpArchiveeModel.selProduit = null;
            this.filterDisplayFTP();
        } else {
            this.ftpArchiveeModel.selProduit = data;
            this.filterDisplayFTP();
        }
    }

    setNormeAheadAs() {
        this.selectedNorme = true;
        this.filterDisplayFTP();
    }
    async changeNorme(data) {
        this.resetSelected(this.ftpArchiveeModel.lstNormes);
        if (this.ftpArchiveeModel.selNorme && this.ftpArchiveeModel.selNorme.code === data.code) {
            //this.selectedProducteur = false;
            this.ftpArchiveeModel.selNorme = null;
            this.filterDisplayFTP();
        } else {
            this.ftpArchiveeModel.selNorme = data;
            this.filterDisplayFTP();
        }
    }

    resetSelected(list) {
        list = list.map((x) => {
            x.selected = false;
            return x;
        });
    }

    changeDate() {
        this.filterDisplayFTP();
    }

    filterDisplayFTP() {
        this.loadingFTP = true;
        this.ftpArchiveeModel.filteredLstFTP = angular.copy(this.ftpArchiveeModel.lstFTP);

        if (this.ftpArchiveeModel.selProducteur) {
            this.ftpArchiveeModel.filteredLstFTP = this.ftpArchiveeModel.filteredLstFTP.filter(
                (e) => e.idProducteur === this.ftpArchiveeModel.selProducteur.id
            );
            // Filter product by producer when change from 'selection'
            this.ftpArchiveeModel.filteredLstProduits = this.ftpArchiveeModel.lstProduits.filter(
                (x) => x.sites && x.sites.some((e) => e.id === this.ftpArchiveeModel.selProducteur.id)
            );
        }
        if (this.ftpArchiveeModel.selProduit) {
            this.ftpArchiveeModel.filteredLstFTP = this.ftpArchiveeModel.filteredLstFTP.filter(
                (e) => e.idProduit === this.ftpArchiveeModel.selProduit.id
            );
        }
        if (this.ftpArchiveeModel.selNorme) {
            this.ftpArchiveeModel.filteredLstFTP = this.ftpArchiveeModel.filteredLstFTP.filter(
                (e) => e.idNorme === this.ftpArchiveeModel.selNorme.id
            );
        }
        if (this.ftpArchiveeModel.selDate) {
            this.ftpArchiveeModel.filteredLstFTP = this.ftpArchiveeModel.filteredLstFTP.filter((e) => e.date === this.ftpArchiveeModel.selDate);
        }
        this.loadingFTP = false;
    }

    selectFTP(init = false) {
        if (!init) {
            this.syntheseEntity.ftpArchivee = null;
        }

        let selFTP = this.ftpArchiveeModel.filteredLstFTP.find((e) => {
            return e.selected;
        });

        const oldSelected = this.ftpArchiveeModel.lstFTP.find((e) => {
            return e.selected;
        });
        if (oldSelected) {
            delete oldSelected.selected;
        }

        if (!selFTP && this.syntheseEntity.ftpArchivee) {
            selFTP = this.ftpArchiveeModel.lstFTP.find((e) => {
                return e.id === this.syntheseEntity.ftpArchivee.id;
            });
            if (selFTP) {
                selFTP.selected = true;
            }
        } else if (selFTP) {
            selFTP = this.ftpArchiveeModel.lstFTP.find((e) => {
                return e.id === selFTP.id;
            });
            if (selFTP) {
                selFTP.selected = true;
            }
        }

        if (selFTP) {
            this.syntheseEntity.ftpArchivee = selFTP;

            this.ftpArchiveeModel.selProducteur = this.ftpArchiveeModel.lstProducteurs.find((e) => {
                return e.id === this.syntheseEntity.ftpArchivee.idProducteur;
            });
            this.ftpArchiveeModel.selProduit = this.ftpArchiveeModel.filteredLstProduits.find((e) => {
                return e.id === this.syntheseEntity.ftpArchivee.idProduit;
            });
            this.ftpArchiveeModel.selNorme = this.ftpArchiveeModel.lstNormes.find((e) => {
                return e.id === this.syntheseEntity.ftpArchivee.idNorme;
            });
            this.ftpArchiveeModel.selDate = this.syntheseEntity.ftpArchivee.date;

            if (this.ftpArchiveeModel.selProducteur) {
                this.selectedProducteur = true;
            }
            if (this.ftpArchiveeModel.selProduit) {
                this.selectedProduit = true;
            }
            if (this.ftpArchiveeModel.selNorme) {
                this.selectedNorme = true;
            }
        }

        if (this.syntheseEntity.listeLiensSETS && this.syntheseEntity.listeLiensSETS.length > 0) {
            this.syntheseEntity.listeLiensSETS = [];
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}

SyntheseEntitiesFTPController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'notification',
    'moment',
    '$uibModal',
    'MassiaApplicationService',
    'SyntheseEntitiesService',
    'SyntheseEntitiesCommunicationService',
    'FTPArchiveeService'
];
