(function () {
    'use strict';

    /*   ***** UTILISATION *****
     *   ** Modale simple :
     *   ModalService.simple({ modalTitle: 'titre', modalMsg: 'Message' });
     *   ModalService.success({ modalTitle: 'titre', modalMsg: 'Message' });
     *   ModalService.info({ modalTitle: 'titre', modalMsg: 'Message' });
     *   ModalService.warning({ modalTitle: 'titre', modalMsg: 'Message' });
     *   ModalService.error({ modalTitle: 'titre', modalMsg: 'Message' });
     *
     *   ** Modale attendant une réponse :
     *   var modalInstance = ModalService.confirm({ modalTitle: 'titre', modalMsg: 'Message' });
     *
     *   modalInstance.result
     *      .then(function () {
     *         // résultat si bouton OK
     *         console.log('OK');
     *      })
     *      .catch(function () {
     *         // résultat si bouton Annuler
     *         console.log('NOK');
     *      });
     *
     *   ** Modale avec template et controller :
     *   var modalInstance = ModalService.openModal({
     *                                templateUrl: 'app/modules/readme/views/readme.form.html',
     *                                controller: 'ReadmeFormController',
     *                                controllerAs: 'readmeFormCtrl',
     *                                modalTitle: 'titre',
     *                                modalType: modalTypes.UPDATE,
     *                                resolve: {
     *                                    itemId: itemId
     *                                }
     *                           });;
     *
     *   *** ATTENTION : le controller se voit injecter dans l'init trois paramètres :
     *   init(data, modalCallbacks, modalConfig)
     *      data : le resolve envoyé dans le modal.open
     *      modalCallbacks : les callbacks close, confirm et cancel pour manipuler la modale depuis le controller
     *      modalConfig : la config envoyée dans le modal.open (si besoin)
     *
     */

    angular.module('blocks.modal', ['ui.bootstrap', 'pascalprecht.translate']);
})();
