import validatorBuilder from 'validator-builder';
import LieuEntete from './entete/lieu.entete.model';
import LieuCaracteristiques from './caracteristiques/lieu.caracteristiques.model';

let lieuValidator = null;

export default class Lieu {
    constructor(data = {}) {
        this.id = data.id;
        this.entete = new LieuEntete(data.entete);
        this.caracteristiques = new LieuCaracteristiques(data.caracteristiques);
        this.niveaux = data.niveaux || {};
    }

    isValid() {
        lieuValidator = lieuValidator || validatorBuilder.getInstanceFor('Lieu');
        const validationResults = lieuValidator.validate(this);
        return validationResults.isValid;
    }
}
