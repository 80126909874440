import ApiService from '../../../services/api.service';
import { BehaviorSubject } from 'rxjs';

export default class SyntheseCacheService {
    /**
     * @type BehaviorSubject<[]>
     */
    _essais = new BehaviorSubject([]);
    get essais() {
        return this._essais.asObservable();
    }

    set essais(val) {
        this._essais.next(val);
    }
    /* @ngInject */
    constructor(ApiService) {
        /**
         * @type ApiService
         */
        this.api = ApiService;
    }

    async loadEssai(filters = [], sorts = [], pagination = { skip: 0, take: 0 }) {
        const res = await this.api.get('massia/essais', {
            params: {
                filters: JSON.stringify(filters),
                sorts: JSON.stringify(sorts),
                pagination: JSON.stringify(pagination)
            }
        });
        this._essais.next(res.items);
    }
}
