import ApiService from '../../app/services/api.service';
import { Injectable } from '../decorators';
import { BaseHttpService } from '../interface/MassiaHttpService.interface';
import { Famille } from '../models/famille/Famille';

@Injectable('FamilleService')
export class FamilleService extends BaseHttpService<Famille, any, any, any, any> {
    /* @ngInject */
    constructor(ApiService: ApiService) {
        super(ApiService);
        this.url = 'v2/massia/familles';
    }
}
