// on importe le modèle pour pouvoir le new-er (utile à la validation)
import ProduitCaracteristiques from './produit.caracteristiques.model';

export default class ProduitCaracteristiquesController {
    // l'injection de dépendances se fait via le mot clé static
    // c'est une mécanique angularJS
    static $inject = ['_', 'ProduitsTypesService', 'Interpreteur', 'notification', '$timeout', '$scope'];

    // le constructeur est la seule fonction qui reçoit les dépendances
    constructor(_, ProduitsTypesService, Interpreteur, notification, $timeout, $scope) {
        // on publie les dépendances dans this
        // pour pouvoir s'en servir dans les autres méthodes du controller
        this._ = _;
        this.ProduitsTypesService = ProduitsTypesService;
        this.Interpreteur = Interpreteur;
        this.notification = notification;
        this.$timeout = $timeout;
        this.$scope = $scope;
    }

    // cette fonction est appelée automatiquement à la création du component
    // par la mécanique interne angularJS
    async $onInit() {
        // on initialise les variables du controller
        this.loading = false;
        this.typeFormat = this.typeFormat || [];
        this.produit = this.produit || {};
        this.niveauVisibilite = 0;
        this.caracteristiques = new ProduitCaracteristiques(this.produit.caracteristiques);
        const valeursRef = undefined; //TODO: ?? aller chercher les valeurs de référence du produit (par producteur, donc à priori non)
        this.Interpreteur.init(this.produit, valeursRef);
        this.Interpreteur.formatCaract = this.typeFormat;
        const _this = this;
        this.$scope.$watch('$ctrl.produit.entete.niveauVisibilite', function (newValue, oldValue) {
            _this.niveauVisibilite = _this.produit.entete.niveauVisibilite;
        });
    }

    // cette fonction est appelée automatiquement à la destruction du component
    // par la mécanique interne angularJS
    $onDestroy() {
        this.$timeout.cancel(this.typeFormatTimeout);
    }

    // cette fonction est appelée automatiquement
    // dans le cas où une des propriétés du component :
    // 1. est bindée en one-way '<'
    // 2. est modifiée d'une façon ou d'une autre
    $onChanges(objChanges) {
        if (objChanges.typeId) {
            if (!objChanges.typeId.isFirstChange()) {
                this.caracteristiques = new ProduitCaracteristiques();
            }

            this.getTypeFormat(objChanges.typeId.currentValue);
        }
    }

    // cette fonction est appelée automatiquement
    // a chaque digest (dirty check) d'angular
    $doCheck() {
        if (!angular.equals(this.caracteristiques, this.produit.caracteristiques)) {
            this.onUpdate({
                caracteristiques: angular.copy(this.caracteristiques)
            });
        }
    }

    async getTypeFormat(typeId) {
        if (typeId) {
            // on démarre l'animation de loading avant le chargement des données
            this.startLoading();

            // on encapsule l'appel serveur dans un try...catch pour récupérer les erreurs
            try {
                // on récupère les données depuis le service qui interroge le serveur
                const typeFormat = await this.ProduitsTypesService.getCaracteristiques(typeId);
                // on trie le tableau selon la propriété 'position'
                // on publie le tableau dans le this pour l'afficher dans la vue
                this.typeFormat = this._.sortBy(typeFormat, 'position');
                this.Interpreteur.formatCaract = this.typeFormat;

                await this.recalculeFormules();
            } catch (ex) {
                // en cas de problème on notifie l'utilisateur
                this.notification.error(ex.data);
            } finally {
                // On supprimer le timeout précédent (fuite mémoire)
                this.$timeout.cancel(this.typeFormatTimeout);

                // une fois l'appel terminé, on stoppe l'animation de loading
                this.typeFormatTimeout = this.$timeout(() => {
                    this.stopLoading();
                });
            }
        }
    }

    async exitCaract(idCaract) {
        if (!this.ignoreDoCheck) {
            this.ignoreDoCheck = true;
            const nouvValeur = this.caracteristiques[idCaract];
            let updateListeCar = false;
            if (this.caracteristiques.data[idCaract] != nouvValeur) {
                updateListeCar = true;
                this.produit.caracteristiques[idCaract] = nouvValeur;
                this.produit.caracteristiques.data[idCaract] = nouvValeur;
                if (this.caracteristiques.data[idCaract] !== undefined) {
                    this.caracteristiques.data[idCaract].value = nouvValeur;
                }
            }
            if (updateListeCar) {
                await this.recalculeFormules();
                this.onUpdate({
                    caracteristiques: angular.copy(this.caracteristiques)
                });
            }
            this.ignoreDoCheck = false;
        }
    }

    async recalculeFormules() {
        let recharge = false;
        if (this.Interpreteur) {
            this.Interpreteur.calculatedFormules = [];
            this.Interpreteur.obj = this.produit;
            this.Interpreteur.formatCaract = this.typeFormat;
        }
        let idx1 = 0;
        while (idx1 < this.typeFormat.length) {
            const format = this.typeFormat[idx1];
            if (format.formula) {
                try {
                    const oldVal = this.caracteristiques[idx1];
                    this.Interpreteur.essaiAppel = undefined;
                    this.Interpreteur.forceIter = undefined;
                    let newVal = await this.Interpreteur.calculeFormule(format.formula);
                    if (format.nature == 'Numerique' && newVal !== '') {
                        let arrondi = format.numericAdditionalInformation.nombreDeDecimales;
                        const grandeurMinimum = format.numericAdditionalInformation.grandeurMinimum;
                        const grandeurMaximum = format.numericAdditionalInformation.grandeurMaximum;
                        const x = this.Interpreteur.strEnDouble(newVal);
                        if (!isNaN(x)) {
                            //gestion des min/max
                            if (grandeurMinimum != undefined && grandeurMinimum != null && grandeurMinimum > newVal) {
                                newVal = grandeurMinimum;
                            }
                            if (grandeurMaximum != undefined && grandeurMaximum != null && grandeurMaximum < newVal) {
                                newVal = grandeurMaximum;
                            }
                            //gestion de l'arrondi
                            if (arrondi == 0) {
                                newVal = Math.round(x);
                            } else {
                                if (!arrondi) {
                                    arrondi = 2;
                                }
                                newVal = x.toFixed(arrondi);
                            }
                        } else {
                            newVal = null;
                        }
                    }
                    if (oldVal !== newVal) {
                        this.caracteristiques[format.id] = newVal;
                        if (this.caracteristiques.data) {
                            this.caracteristiques.data[format.id].value = newVal;
                        }
                        recharge = true;
                    }
                } catch (ex) {}
            }
            idx1++;
        }
        if (recharge) {
            this.typeFormat = angular.copy(this.typeFormat);
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
