export default function LoginRoutes($stateProvider) {
    $stateProvider.state('login', {
        url: '/login',
        params: {
            sessionExpired: false,
            originalState: undefined,
            originalStateParams: undefined
        },
        title: 'ROUTE_LOGIN_TITLE',
        views: {
            massia: {
                template: '<login-page></login-page>'
            }
        }
    });
}

LoginRoutes.$inject = ['$stateProvider'];
