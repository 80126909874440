import FeuilleRoutePopupController from './feuille.route.popup.controller';

export default {
    bindings: {
        modalInstance: '=',
        resolve: '<'
    },
    template: require('./feuille.route.popup.html'),
    controller: FeuilleRoutePopupController
};
