TypeDetailController.$inject = ['TypesService', '$state', 'notification', 'MassiaApplicationService'];

export default function TypeDetailController(TypesService, $state, notification, MassiaApplicationService) {
    /* jshint validthis: true */
    const vm = this;

    // Propriétés
    vm.loading = true;
    vm.type = {};

    // Méthodes
    vm.getApplication = getApplication;

    // Evènements
    vm.$onInit = onInit;

    // Méthodes publiques
    function onInit() {
        //par défaut, on ouvre l'onglet "Entête"
        this.ongletOpen = {
            isEnteteOpen: true,
            isCaracteristiqueOpen: false
        };
        loadType();
    }

    // Méthodes privées
    async function loadType() {
        startLoading();

        try {
            vm.type = await TypesService.getTypeById($state.params.id);
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function getApplication() {
        return MassiaApplicationService.getApplication();
    }

    // Méthodes privées
    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }
}
