export default class HistoriqueComposantController {
    static $inject = ['$scope', '$stateParams', 'HistoriqueCommunicationService', 'HistoriqueService', 'notification', '$uibModal'];

    constructor($scope, $stateParams, HistoriqueCommunicationService, HistoriqueService, notification, $uibModal) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.HistoriqueCommunicationService = HistoriqueCommunicationService;
        this.HistoriqueService = HistoriqueService;
        this.notification = notification;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.unregister = this.$scope.$watch(
            () => this.isOpen,
            () => {
                if (this.isOpen && !this.historiques) {
                    this.loadHistorique();
                }
            },
            true
        );
    }

    $onDestroy() {
        this.unregister();
    }

    async loadHistorique() {
        if (this.id) {
            this.startLoading();
            try {
                this.historiques = await this.HistoriqueService.getHistoriqueComposant(this.id, this.domaine);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            }
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
