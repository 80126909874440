import { getNatureNamesByValues } from '../../../../common/caracteristiques/services/natures.enum';

export default class ChantierDetailController {
    static $inject = ['$stateParams', '$state', 'ChantiersService', 'notification', 'ChantiersCommunicationService', 'ChantiersTypesService'];

    constructor($stateParams, $state, ChantiersService, notification, ChantiersCommunicationService, ChantiersTypesService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.ChantiersService = ChantiersService;
        this.notification = notification;
        this.ChantiersCommunicationService = ChantiersCommunicationService;
        this.ChantiersTypesService = ChantiersTypesService;
    }

    $onInit() {
        this.isChantier = this.$stateParams.genre === 'chantiers';
        this.loading = false;
        this.chantier = {};
        this.caracteristiques = [];
        this.chantierId = undefined;
        //par défaut, on ouvre l'onglet "Entête"
        this.ongletOpen = {
            isEnteteOpen: true,
            isCaracteristiqueOpen: false
        };

        this.initChantier();
    }

    async initChantier() {
        // on récupère l'id dans les paramètres
        this.chantierId = this.$stateParams.id;

        this.startLoading();
        try {
            // on ajoute await afin de ne pas passer trop rapidement dans le finally
            // et ainsi on garde le loading le temps nécessaire
            this.chantier.entete = await this.ChantiersService.getChantierEnteteById(this.chantierId);
            this.chantier.caracteristiques = await this.ChantiersService.getChantierValeursCaracteristiquesById(this.chantierId);
            this.setCaracteristiques(this.chantier.caracteristiques);
        } catch (ex) {
            if (ex.status === 404) {
                this.$state.go('chantiers.list', {}, { reload: true });
            }
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async setCaracteristiques(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiques = [];
        caracteristiques.forEach((c) => {
            this.caracteristiques.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
