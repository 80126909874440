import SyntheseEntityResistanceTabDetailsController from './syntheseentities.resistance.tabdetails.controller';

export default {
    template: require('./syntheseentities.resistance.tabdetails.html'),
    controller: SyntheseEntityResistanceTabDetailsController,
    bindings: {
        id: '<',
        model: '<',
        resultat: '<',
        controle: '<',
        produit: '<',
        famille: '<',
        type: '@',
        mainEssai: '<',
        isFamilleBeton: '<',
        onUpdate: '&',
        onReinit: '&',
        onExclure: '&',
        onInclure: '&' //pour mise à jour la liste des prélèvements quand on exclure un ou plusieurs PVs entre les onglets(Famille/Béton)
    }
};
