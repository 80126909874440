export default class ProduitCommercial {
    constructor(data) {
        this.id = data.id;
        this.idProduit = data.idProduit;
        this.code = data.code;
        this.code2 = data.code2 || 'N.C';
        this.libelle = data.libelle;
        this.type = data.type;
        this.sites = data.sites || [];
        this.selected = false;
        this.isEnabled = data.isEnabled;
    }
}
