export default function Configure($stateProvider) {
    $stateProvider.state('planification-job-prefacturations', {
        parent: 'gestion',
        url: '/planification-job-prefacturations',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.OUTILS" | translate }}'
        }
    });

    $stateProvider.state('planification-job-prefacturations.list', {
        url: '/list',
        template: '<planification-job-prefacturations></planification-job-prefacturations>',
        rights: { domain: 'prefavente', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "PLANIFICATION_JOB_PREFACTURATIONS.BREADCRUMBS.PLANIFICATION_JOB_PREFACTURATIONS_LIST" | translate}}'
        },
        //  navigation: {
        //     menu: 'facturation',
        //     translate: 'PLANIFICATION_JOB_PREFACTURATIONS.BREADCRUMBS.PLANIFICATION_JOB_PREFACTURATIONS_LIST',
        //     order: 15
        // },
        search: 'PLANIFICATION_JOB_PREFACTURATIONS.BREADCRUMBS.PLANIFICATION_JOB_PREFACTURATIONS_LIST'
    });
}

Configure.$inject = ['$stateProvider'];
