DapEnteteValidator.$inject = ['validator'];

export default function DapEnteteValidator(validator) {
    const instance = new validator();
    instance.ruleFor('numero').notEmpty().when(isNotCodeAuto).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('dateCreation').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('dateValidite').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('nomenclatureIds').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;

    function isNotCodeAuto(obj, prop) {
        return !obj.estCodeAuto;
    }
}
