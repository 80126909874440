const serviceUrl = `${__configuration.apiUrl}/massia/gestion/type-bennes`;

export default class TypeBennesService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
		this.getUrlUnites = () => `${__configuration.apiUrl}/massia/unites-list`;
	}

	async getUnites() {
		const url = `${this.getUrlUnites()}`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getTypeBennes(isEnabled, filters, sorts, pagination) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				isEnabled: JSON.stringify(isEnabled),
				filters: JSON.stringify(filters || []),
				sorts: JSON.stringify(sorts || []),
				pagination: JSON.stringify(pagination || {})
			}
		});
		return res.data;
	}

	async getTypeBenneById(idTypeBenne) {
		const url = `${serviceUrl}/${idTypeBenne}`;

		const res = await this.$http.get(url);
		return res.data;
	}

	async deleteTypeBenneById(id) {
		const url = `${serviceUrl}/${id}`;
		return this.$http.delete(url);
	}

	async activateTypeBenne(id) {
		const url = `${serviceUrl}/activate/${id}`;
		return this.$http.put(url);
	}

	async createTypeBenne(typeBenneInfo) {
		return this.$http.post(serviceUrl, typeBenneInfo);
	}

	async updateTypeBenne(id, typeBenne) {
		const data = {
			code: typeBenne.code,
			libelle: typeBenne.libelle,
			idTypeBenne: id,
			idUnite: typeBenne.idUnite,
			poidsMoyen: typeBenne.poids,
			ptac: typeBenne.ptac
		};
		const url = `${serviceUrl}/${id}`;
		return this.$http.put(url, data);
	}

	//benneId optionnel, passer null si c'est une creation de benne
	//sinon l'id du benne qui est en train d'être edité
	async codeExists(code, typeBenneId) {
		const url = `${serviceUrl}/code-unicity/${code}`;
		const result = await this.$http.get(url);
		return result.data;

		// if (typeBenneId) {
		//     url += `&Id=${typeBenneId}`;
		// }
	}

	async getUnites() {
		const uniteUrl = `${__configuration.apiUrl}/massia/unites`;
		const res = await this.$http.get(uniteUrl);

		return res.data;
	}
}
