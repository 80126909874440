import DocumentEntete from './document.entete.model';

export default class DocumentFormEnteteController {
    static $inject = ['$state', '$stateParams', 'DocumentsService', 'DocumentsTypesService', 'ModalService', 'notification', '$translate', 'Upload'];

    constructor($state, $stateParams, DocumentsService, DocumentsTypesService, ModalService, notification, $translate, Upload) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.DocumentsService = DocumentsService;
        this.DocumentsTypesService = DocumentsTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.Upload = Upload;
        this.Progression = 0;
    }

    async $onInit() {
        this.document = this.document || {};
        this.entete = new DocumentEntete(this.document.entete);
        try {
            this.entete.estCodeAuto = await this.DocumentsService.estCodeAuto();
        } catch (ex) {
            this.notification.error(ex.data);
            this.annuler();
        }
        this.types = this.types || [];
        this.fichier = [];
        await this.getNiveauVisibilite();

        if (this.document.forTemplate) {
            this.getTypes();
            for (let i = 0; i < this.types.length; i++) {
                const type = this.types[i];
                if (type.code == 'TEMPLATE_OFFRE') {
                    this.entete.typeId = type.id;
                }
            }
        }
    }

    async getNiveauVisibilite() {
        if (this.entete.typeId) {
            this.entete.niveauVisibilite = await this.DocumentsTypesService.GetNiveauVisibilite(this.entete.typeId);
        }
    }

    $onChanges(objChanges) {
        if (objChanges.document) {
            this.getTypes();
        }
    }

    $doCheck() {
        if (!angular.equals(this.entete, this.document.entete)) {
            this.onUpdate({
                entete: angular.copy(this.entete)
            });
        }
    }

    async onFileSelect($files) {
        if (this.document.forTemplate) {
            if ($files[0].name.endsWith('.xlsm')) {
                await this.DocumentsService.onFileSelect($files, this.document.id, this.document.entete.code, this.document.entete.typeId);
            } else {
                this.notification.error('DOCUMENTS.ERROR_XLSM_TEMPLATE');
            }
        } else {
            await this.DocumentsService.onFileSelect($files, this.document.id, this.document.entete.code, this.document.entete.typeId);
        }
    }

    async createType() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'DOCUMENTS.CONFIRM_CREATE_TYPE.TITLE',
            modalMsg: 'DOCUMENTS.CONFIRM_CREATE_TYPE.MESSAGE',
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(() => this.$state.go('documents.newType'));
    }

    async getTypes() {
        this.startLoading();

        try {
            this.types = await this.DocumentsTypesService.getAll();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async checkCodeUnicity(code) {
        if (code) {
            this.startCodeLoading();

            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.entete.codeExists = await this.DocumentsService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        } else {
            this.entete.codeExists = null;
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
