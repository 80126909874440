const serviceUrl = `${__configuration.apiUrl}/massia/mode-unites`;

class ModeUnite {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getModes() {
        const res = await this.$http.get(serviceUrl);
        return res.data;
    }
}

export default ModeUnite;
