(function (angular, undefined) {
    'use strict';

    angular.module('blocks.smart.table')
        .service('FilterHelperService', FilterHelperService);

    function FilterHelperService(){
        var service = {
            containsFilter: containsFilter,
            endsWithFilter: endsWithFilter,
            eqFilter: eqFilter,
            neqFilter: neqFilter,
            geFilter: geFilter,
            gtFilter: gtFilter,
            inFilter: inFilter,
            leFilter: leFilter,
            ltFilter: ltFilter,
            startsWithFilter: startsWithFilter
        };

        return service;

        function containsFilter(exp, predicate) {
            return {
                criterion: predicate,
                value: exp,
                type: "Contains"
            };
        }

        function endsWithFilter(exp, predicate) {
            return {
                criterion: predicate,
                value: exp,
                type: "EndsWith"
            };
        }

        function eqFilter(exp, predicate) {
            return {
                criterion: predicate,
                value: exp,
                type: "Eq"
            };
        }

        function neqFilter(exp, predicate) {
            return {
                criterion: predicate,
                value: exp,
                type: "Neq"
            };
        }

        function geFilter(exp, predicate) {
            return {
                criterion: predicate,
                value: exp,
                type: "Ge"
            };
        }

        function gtFilter(exp, predicate) {
            return {
                criterion: predicate,
                value: exp,
                type: "Gt"
            };
        }

        function inFilter(exp, predicate) {
            return {
                criterion: predicate,
                value: exp,
                type: "In"
            };
        }

        function leFilter(exp, predicate) {
            return {
                criterion: predicate,
                value: exp,
                type: "Le"
            };
        }

        function ltFilter(exp, predicate) {
            return {
                criterion: predicate,
                value: exp,
                type: "Lt"
            };
        }

        function startsWithFilter(exp, predicate) {
            return {
                criterion: predicate,
                value: exp,
                type: "StartsWith"
            };
        }
    }
})(angular);