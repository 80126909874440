import validatorBuilder from 'validator-builder';

let commandeLigneValidator = null;

export default class CommandeLigne {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.type = 'commande';
        this.code = data.code;
        this.uniteId = data.uniteId;
        this.modeDeclenchement = data.modeDeclenchement;
        this.libelle = data.libelle;
    }

    isValid() {
        commandeLigneValidator = commandeLigneValidator || validatorBuilder.getInstanceFor('CommandeLigne');
        const validationResults = commandeLigneValidator.validate(this);
        return validationResults.isValid;
    }
}
