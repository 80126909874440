import LoginTranslations from './config/login.translations';
import LoginRoutes from './config/login.routes';
import LoginProvider from './components/form/login.provider';
import LoginFormComponent from './components/form';
import LoginPageComponent from './components/page';
import MassiaDBService from './components/form/massia.db.service';
import LoginDataValidator from './components/form/login.data.validator';

const moduleName = 'app.massia.login';

angular
	.module(moduleName, [])
	.run([
		'$templateCache',
		($templateCache) => {
			$templateCache.put('login.page.default.html', require('./components/page/login.page.default.html'));
			$templateCache.put('login.page.eiffage.html', require('./components/page/login.page.eiffage.html'));
			$templateCache.put('login.page.html', require('./components/page/login.page.html'));
		}
	])
	.config(LoginTranslations)
	.config(LoginRoutes)
	.provider('LoginService', LoginProvider)
	.factory('LoginDataValidator', LoginDataValidator)
	.service('MassiaDBService', MassiaDBService)
	.component('loginForm', LoginFormComponent)
	.component('loginPage', LoginPageComponent);

export default moduleName;
