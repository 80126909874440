import TarifsController from './tarifs.controller';

export default {
    bindings: {
        source: '=',
        domaine: '=',
        idGrille: '=',
        modalInstance: '=',
        resolve: '<'
    },
    template: require('./tarifs.html'),
    controller: TarifsController
};
