import PrestationsLieesController from './prestations.liees.controller';

export default {
    bindings: {
        domaine: '<',
        frontFilters: '=',
        filterToBack: '=',
        parametre: '=',
        listes: '=',
        libelles: '=',
        source: '<',
        domaine: '<',
        filtresAppliques: '<',
        onUpdate: '&',
        onDelete: '&',
        dateApplication: '='
    },
    template: require('./prestations.liees.html'),
    controller: PrestationsLieesController
};
