import { copy } from 'angular';
import Prefacturation from './prefacturation.model';

export default class PrefacturationFormController {
	static $inject = [
		'$scope',
		'$timeout',
		'$state',
		'$stateParams',
		'PrefacturationCommunicationService',
		'PrefacturationService',
		'notification',
		'$location',
		'$anchorScroll',
		'_',
		'MassiaApplicationService',
		'moment',
		'$translate',
		'DataShareService',
		'ModalService',
		'globalizationManagementService',
		'MOPService'
	];

	constructor(
		$scope,
		$timeout,
		$state,
		$stateParams,
		PrefacturationCommunicationService,
		PrefacturationService,
		notification,
		$location,
		$anchorScroll,
		_,
		MassiaApplicationService,
		moment,
		$translate,
		DataShareService,
		ModalService,
		globalizationManagementService,
		MOPService
	) {
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.PrefacturationCommunicationService = PrefacturationCommunicationService;
		this.PrefacturationService = PrefacturationService;
		this.notification = notification;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		this._ = _;
		this.MassiaApplicationService = MassiaApplicationService;
		this.moment = moment;
		this.$translate = $translate;
		this.DataShareService = DataShareService;
		this.ModalService = ModalService;
		this.licenceGest = __configuration.licenceGest;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;

		this.ongletOpen = {
			isEnteteOpen: false,
			selectionSitesCommerciauxOpened: false,
			selectionSitesClientsFournisseursOpened: false,
			selectionSitesCommerciauxOpened: false
		};
		this.ongletOpenVal = {
			isEnteteOpen: 'entete',
			selectionSitesCommerciauxOpened: 'selectSitesCom',
			selectionSitesClientsFournisseursOpened: 'selectSitesCli',
			selectionChantiersOpened: 'selectChantiers'
		};
		this.MOPService = MOPService;
	}

	async $onInit() {
		this.MOPService.setMop([{ title: 'PREFACTURATION.BREADCRUMBS.PREFACTURATIONVENTE', filename: 'Facturation.pdf', application: 'gestion' }]);
		this.prefacturation = this.prefacturation || {};
		this.sitesCommerciauxLoaded = [];
		this.utilisateurLoaded = [];
		this.clientsLoaded = [];
		this.typeClientsLoaded = [];
		this.fournisseursLoaded = [];
		this.chantiersLoaded = [];
		this.selectedTypeClients = [];

		this.prefacturation = new Prefacturation();
		this.loading = false;
		this.nature = null;
		this.searched = false;
		this.withControlDate = true;

		this.prefacturation.hasDetailLignes = false;
		this.prefacturation.isValoriserTicket = true;

		// SL on détermine si c'est Achat ou Vente
		if (this.$state.current.rights.domain == 'prefaachat') {
			this.prefacturation.achatVente = 0;
		} else if (this.$state.current.rights.domain == 'prefavente') {
			this.prefacturation.achatVente = 1;
		} else {
			this.prefacturation.achatVente = null;
		}

		this.ongletOpen = {
			isEnteteOpen: true,
			selectionSitesCommerciauxOpened: true,
			selectionSitesClientsFournisseursOpened: true,
			selectionChantiersOpened: true,
			selectionSalarieOpened: true
		};

		await this.initDates();

		// SL pour customiser les libelle du ISteven de la préfa
		// remplace Aucune Sélection par Tous (fonctionnement spécifique à la préfa et au pont comptable)
		this.customLibelleISteven = {
			selectAll: this.$translate.instant('PREFACTURATION.CUSTOMISTEVEN.SELECTALL'),
			selectNone: this.$translate.instant('PREFACTURATION.CUSTOMISTEVEN.SELECTNONE'),
			reset: this.$translate.instant('PREFACTURATION.CUSTOMISTEVEN.RESET'),
			search: this.$translate.instant('PREFACTURATION.CUSTOMISTEVEN.SEARCH'),
			close: this.$translate.instant('PREFACTURATION.CUSTOMISTEVEN.CLOSE'),
			nothingSelected: this.$translate.instant('PREFACTURATION.CUSTOMISTEVEN.NOTHINGSELECTED')
		};

		await this.getSocietesCom();
		if (_.isEmpty(this.DataShareService.savedData) == false) {
			// si, de la PréFa, on est allé sur une commande
			// le dataShare a forcément des données en stock
			// donc on les prends
			const hasPreFaResult = _.find(this.DataShareService.savedData, { entity: 'preFaCommande' });
			if (hasPreFaResult != null) {
				await this.getResultPreFa();
			} else {
				// sinon, on regarde si on a des données concernant le formulaire de recherche
				const item = _.find(this.DataShareService.savedData, { entity: 'preFaForm' });
				if (item != null) {
					await this.updateSearchForm();
				}
			}
		}

		const _this = this;
		this.$scope.$watch('$ctrl.prefacturation.dateFin', function (newValue, oldValue) {
			_this.updateDateGeneration();
		});
	}

	initDates() {
		// initialise les dates de debut et fin au premier et dernier jour du mois en cours
		const maDate = new Date();
		const y = maDate.getFullYear();
		const m = maDate.getMonth();
		const firstDay = new Date(y, m, 1);
		const lastDay = new Date(y, m + 1, 0);

		this.prefacturation.dateDebut = this.moment(firstDay).format(this.dateFormat);
		this.prefacturation.dateFin = this.moment(lastDay).format(this.dateFormat);
		this.prefacturation.dateGeneration = this.prefacturation.dateFin;
	}

	updateDateGeneration() {
		this.prefacturation.dateGeneration = this.prefacturation.dateFin;
	}

	async getResultPreFa() {
		// on récupère les données du résultat de la PréFa
		const resultPreFaFromCommande = this.DataShareService.getDataShare('preFaCommande');
		// et on retourne direct au résultat de la PréFa SANS REVALORISATION
		this.$state.go('prefacturation.detailvente', { obj: resultPreFaFromCommande.dataItSelf });
	}

	async updateSearchForm() {
		this.prefacturation.searchingFormPreFa = this.DataShareService.getDataShare('preFaForm');

		this.prefacturation.dateDebut = this.prefacturation.searchingFormPreFa.dateDebut;
		this.prefacturation.dateFin = this.prefacturation.searchingFormPreFa.dateFin;
		this.prefacturation.idSociete = this.prefacturation.searchingFormPreFa.societeComIdSelected;
		this.prefacturation.nomSociete = this.prefacturation.searchingFormPreFa.societeComNomSelected;

		this.loadCliFourChantiers(1);
	}

	$onDestroy() {
		this.$timeout.cancel(this.updatePrefacturationTimeout);
		this.MOPService.resetMop();
	}

	async getSocietesCom() {
		this.societesComLoaded = await this.PrefacturationService.getSocietesCom();

		if (this.societesComLoaded.length == 1) {
			this.prefacturation.idSociete = this.societesComLoaded[0].id;
			this.prefacturation.nomSociete = this.societesComLoaded[0].libelle;
		}
	}

	async getSitesCom() {
		try {
			this.sitesCommerciauxLoaded = await this.PrefacturationService.getSitesCommerciauxBySociete(
				this.prefacturation.dateDebut,
				this.prefacturation.dateFin,
				this.prefacturation.idSociete,
				this.prefacturation.achatVente
			);
		} catch (ex) {
			// erreur chargement Clients
			if (ex.status == 400) {
				this.notification.info(ex.data);
			} else {
				this.notification.error(ex.data);
			}
		} finally {
		}

		if (this.sitesCommerciauxLoaded.length == 1) {
			this.prefacturation.idSiteCommercial = this.sitesCommerciauxLoaded[0].id;
			this.prefacturation.nomSiteCommercial = this.sitesCommerciauxLoaded[0].libelle;
		}
	}

	changeSociete() {
		for (let i = 0; i < this.societesComLoaded.length; i++) {
			if (this.societesComLoaded[i].id == this.prefacturation.idSociete) {
				this.prefacturation.nomSociete = this.societesComLoaded[i].libelle;
				return;
			}
		}
	}

	saveSelectedSitesCommerciaux() {
		this.prefacturation.listeIdSitesCommerciaux = [];
		for (let i = 0; i < this.selectedSitesCommerciaux.length; i++) {
			const siteCom = this.selectedSitesCommerciaux[i];
			this.prefacturation.listeIdSitesCommerciaux.push(siteCom.id);
		}
	}

	closeSiteCom() {
		this.loadCliFour();
	}

	saveSelectedTypeClients() {
		this.prefacturation.listeIdTypeClient = [];
		if (this.prefacturation.achatVente == 1) {
			for (let i = 0; i < this.selectedTypeClients.length; i++) {
				const type = this.selectedTypeClients[i];
				this.prefacturation.listeIdTypeClient.push(type.id);
			}

			//Reset clients loaded to re-push after
			this.clientsLoaded = [];
			this.loadCliFour();
		}
	}

	saveSelectedClientsFournisseurs() {
		this.prefacturation.listeIdCliFour = [];
		this.prefacturation.listeIdTypeClient = [];

		if (this.prefacturation.achatVente == 1) {
			for (var i = 0; i < this.selectedClients.length; i++) {
				const client = this.selectedClients[i];
				this.prefacturation.listeIdCliFour.push(client.id);
			}
		} else if (this.prefacturation.achatVente == 0) {
			for (var i = 0; i < this.selectedFournisseurs.length; i++) {
				const fournisseur = this.selectedFournisseurs[i];
				this.prefacturation.listeIdCliFour.push(fournisseur.id);
			}
		}

		this.prefacturation.listeIdChantiers = [];
		this.loadChantiers();
	}

	saveSelectedChantiers() {
		this.prefacturation.listeIdChantiers = [];
		for (let i = 0; i < this.selectedChantiers.length; i++) {
			const chantier = this.selectedChantiers[i];
			this.prefacturation.listeIdChantiers.push(chantier.id);
		}
	}

	async loadTypeClient(from) {
		this.startLoading('typeClient', from);
		try {
			const tab = this.clientsLoaded;
			const obj_tab = new Array();

			tab.forEach((el) => {
				if (!obj_tab.some((v) => el.idType === v.id)) {
					const obj_temp = new Object();
					obj_temp.id = el.idType;
					obj_temp.libelle = el.libelleType;
					obj_tab.push(obj_temp);
				}
			});
			this.typeClientsLoaded = obj_tab;
		} catch (e) {
			this.notification.error(e.data);
			console.error(e);
		} finally {
			this.stopLoading();
		}
		this.prefacturation.listeIdTypeClient = [];
	}

	async loadCliFour(from) {
		if (this.prefacturation.achatVente == 1) {
			this.startLoading('clients', from);
			try {
				this.clientsLoaded = await this.PrefacturationService.getNomClients(
					this.prefacturation.salarie.id,
					this.prefacturation.dateDebut,
					this.prefacturation.dateFin,
					this.prefacturation.idSociete,
					this.prefacturation.achatVente,
					this.prefacturation.listeIdTypeClient,
					this.prefacturation.listeIdSitesCommerciaux
				);
			} catch (ex) {
				// erreur chargement Clients
				if (ex.status == 400) {
					this.notification.info(ex.data);
				} else {
					this.notification.error(ex.data);
				}
			} finally {
				this.stopLoading();
			}

			this.prefacturation.listeIdCliFour = [];
			this.prefacturation.listeIdChantiers = [];
		} else if (this.prefacturation.achatVente == 0) {
			this.startLoading('fournisseurs', from);
			try {
				this.fournisseursLoaded = await this.PrefacturationService.getNomFournisseurs(
					this.prefacturation.salarie.id,
					this.prefacturation.dateDebut,
					this.prefacturation.dateFin,
					this.prefacturation.idSociete,
					this.prefacturation.achatVente,
					this.prefacturation.listeIdSitesCommerciaux
				);
			} catch (ex) {
				// erreur chargement Fournisseurs
				if (ex.status == 400) {
					this.notification.info(ex.data);
				} else {
					this.notification.error(ex.data);
				}
			} finally {
				this.stopLoading();
			}

			this.prefacturation.listeIdFournisseurs = [];
			this.prefacturation.listeIdChantiers = [];
		}
	}

	async loadChantiers(from) {
		this.startLoading('chantiers', from);
		try {
			this.chantiersLoaded = await this.PrefacturationService.getNomChantiers(
				this.prefacturation.salarie.id,
				this.prefacturation.dateDebut,
				this.prefacturation.dateFin,
				this.prefacturation.idSociete,
				this.prefacturation.listeIdCliFour,
				this.prefacturation.achatVente
			);
		} catch (ex) {
			// erreur chargement Clients
			this.notification.error(ex.data);
		} finally {
			this.stopLoading();
		}
	}

	resetSalarieObject() {
		if (this.prefacturation.salarie) {
			if (this.prefacturation.salarie.id) {
				// ok
			} else {
				// si patiellement écrit, on a donc un string
				this.prefacturation.salarie = {
					id: 0,
					libelle: null
				};
			}
		} else {
			// si plus l'objet (supprimé à la main)
			this.prefacturation.salarie = {
				id: 0,
				libelle: null
			};
		}
	}

	async loadCliFourChantiers(from) {
		if (this.prefacturation.idSociete) {
			const dataToUse = {};
			dataToUse.entity = 'preFaForm';
			dataToUse.dateDebut = this.prefacturation.dateDebut;
			dataToUse.dateFin = this.prefacturation.dateFin;
			dataToUse.societeComIdSelected = this.prefacturation.idSociete;
			dataToUse.societeComNomSelected = this.prefacturation.nomSociete;

			this.DataShareService.setDataShare(dataToUse, 'preFaForm');
			this.searched = true;
			await this.getSitesCom();
			await this.resetSalarieObject();
			await this.loadCliFour(from);
			await this.loadTypeClient(from);
			await this.loadChantiers(from);
		} else {
			this.notification.info('PREFACTURATION.NOSOCIETE');
		}
	}

	reset() {
		this.initDates();
		this.searched = false;
		this.selectedSitesCommerciaux = [];
		this.selectedClients = [];
		this.selectedFournisseurs = [];
		this.selectedChantiers = [];
		this.prefacturation.listeIdSitesCommerciaux = [];
		this.prefacturation.listeIdTypeClient = [];
		this.prefacturation.listeIdCliFour = [];
		this.prefacturation.listeIdChantiers = [];
		this.prefacturation.salarie = {
			id: 0,
			libelle: null
		};

		this.sitesCommerciauxLoaded = [];
		this.clientsLoaded = [];
		this.typeClientsLoaded = [];
		this.chantiersLoaded = [];
		this.fournisseursLoaded = [];

		this.prefacturation.idSociete = null;
		this.prefacturation.nomSociete = null;
	}

	async ConfirmeValoriserTicket() {
		if (!this.prefacturation.isValoriserTicket) {
			const _this = this;
			const modalInstance = this.ModalService.confirm({
				modalTitle: 'PREFACTURATION.CONTROL_VALORISER.TITLE',
				modalMsg: 'PREFACTURATION.CONTROL_VALORISER.MESSAGE',
				headerClass: 'modal-warning'
			});
			modalInstance.result.then(
				() => {
					//console.log('oui');
				},
				() => {
					_this.prefacturation.isValoriserTicket = true;
				}
			);
		}
	}

	async genererPreFacturation() {
		if (this.checkValidity()) {
			try {
				this.startLoading('prefacturation', 1);
				var resultatPreFa = await this.PrefacturationService.genererPreFacturation(this.prefacturation, this.withControlDate);
				if (resultatPreFa.dateFinTicket) {
					const _this = this;
					const modalInstance = this.ModalService.confirm({
						modalTitle: this.$translate.instant('PREFACTURATION.CONTROL_DATE.TITLE'),
						modalMsg: this.$translate.instant('PREFACTURATION.CONTROL_DATE.MESSAGE', { date: resultatPreFa.dateFinTicket }),
						headerClass: 'modal-danger'
					});
					modalInstance.result.then(
						() => {
							_this.GetPrefa(resultatPreFa.dateFinTicket);
						},
						() => {
							_this.GetPrefa();
						}
					);
				} else {
					// si on a un objet, on va sur la page detail en passant l objet
					if (resultatPreFa.nbTicketsSelectionnes != 0) {
						try {
							if (this.prefacturation.achatVente == 1) {
								this.$state.go('prefacturation.detailvente', { obj: resultatPreFa });
							} else if (this.prefacturation.achatVente == 0) {
								this.$state.go('prefacturation.detailachat', { obj: resultatPreFa });
							}
						} catch (ex) {
							this.notification.error(ex.data);
						}
					} else {
						if (this.prefacturation.achatVente == 1) {
							this.$state.go('prefacturation.newvente', { obj: resultatPreFa });
						} else if (this.prefacturation.achatVente == 0) {
							this.$state.go('prefacturation.newachat', { obj: resultatPreFa });
						}
						this.notification.info('PREFACTURATION.WARNINGPREFA');
					}
				}
			} catch (ex) {
				if (ex.status == 400) {
					this.notification.info(ex.data);
				} else {
					this.notification.error(ex.data);
				}

				if (this.prefacturation.achatVente == 1) {
					this.$state.go('prefacturation.newvente', { obj: resultatPreFa });
				} else if (this.prefacturation.achatVente == 0) {
					this.$state.go('prefacturation.newachat', { obj: resultatPreFa });
				}
			} finally {
				this.withControlDate = false;
				this.stopLoading();
			}
		}
	}

	async GetPrefa(dateReplace) {
		this.startLoading('prefacturation', 1);
		if (dateReplace) {
			this.prefacturation.dateGeneration = dateReplace;
		}
		const resultatPreFa = await this.PrefacturationService.genererPreFacturation(this.prefacturation, this.withControlDate, dateReplace);
		// si on a un objet, on va sur la page detail en passant l objet
		if (resultatPreFa.nbTicketsSelectionnes != 0) {
			try {
				if (this.prefacturation.achatVente == 1) {
					this.$state.go('prefacturation.detailvente', { obj: resultatPreFa });
				} else if (this.prefacturation.achatVente == 0) {
					this.$state.go('prefacturation.detailachat', { obj: resultatPreFa });
				}
			} catch (ex) {
				this.notification.error(ex.data);
			}
		} else {
			if (this.prefacturation.achatVente == 1) {
				this.$state.go('prefacturation.newvente', { obj: resultatPreFa });
			} else if (this.prefacturation.achatVente == 0) {
				this.$state.go('prefacturation.newachat', { obj: resultatPreFa });
			}
			this.notification.info('PREFACTURATION.WARNINGPREFA');
		}
		this.stopLoading();
	}

	async GetPrefa(dateReplace) {
		this.startLoading('prefacturation', 1);
		if (dateReplace) {
			this.prefacturation.dateGeneration = dateReplace;
		}
		const resultatPreFa = await this.PrefacturationService.genererPreFacturation(this.prefacturation, this.withControlDate, dateReplace);
		// si on a un objet, on va sur la page detail en passant l objet
		if (resultatPreFa.nbTicketsSelectionnes != 0) {
			try {
				if (this.prefacturation.achatVente == 1) {
					this.$state.go('prefacturation.detailvente', { obj: resultatPreFa });
				} else if (this.prefacturation.achatVente == 0) {
					this.$state.go('prefacturation.detailachat', { obj: resultatPreFa });
				}
			} catch (ex) {
				this.notification.error(ex.data);
			}
		} else {
			if (this.prefacturation.achatVente == 1) {
				this.$state.go('prefacturation.newvente', { obj: resultatPreFa });
			} else if (this.prefacturation.achatVente == 0) {
				this.$state.go('prefacturation.newachat', { obj: resultatPreFa });
			}
			this.notification.info('PREFACTURATION.WARNINGPREFA');
		}
		this.stopLoading();
	}

	async getPersonnes(valeur) {
		const personnes = await this.PrefacturationService.getPersonnes(
			valeur,
			this.prefacturation.idSociete,
			this.prefacturation.listeIdSitesCommerciaux
		);
		return personnes;
	}

	checkValidity() {
		let validity = true;

		let firstScroll = true;
		if (!this.prefacturation.isValid()) {
			if (firstScroll) {
				this.scrollToOnglet('entete');
				firstScroll = false;
			}

			this.ongletOpen.isEnteteOpen = true;
			validity = false;
			this.$scope.$broadcast('prefacturationEnteteValidations');
		}
		return validity;
	}

	scrollToOnglet(id) {
		this.$location.hash(id);
		this.$anchorScroll();
	}

	startLoading(nature, from) {
		if (from == 1) {
			this.loading = true;
			this.nature = nature;
		}
	}

	stopLoading() {
		this.loading = false;
		this.nature = null;
	}
}
