import { IAugmentedJQuery, IScope } from 'angular';
import { Directive, Input, OnInit } from '../../../core/decorators';

@Directive({ selector: '[massiaCustomPagination]' })
export class MassiaCustomPagination implements OnInit {
    @Input() model: any;

    /* @ngInject */
    constructor(public $scope: any, public $element: IAugmentedJQuery) {}

    ngOnInit(): void {
        this.$scope.$$childTail.context = this.model;
    }
}
