const serviceUrl = `${__configuration.apiUrl}/massia/banques`;

export default class BanquesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getBanques(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async deleteBanqueById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getBanqueById(id) {
        const url = `${serviceUrl}/${id}/entete`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createBanque(banque) {
        const data = {
            code: banque.entete.code,
            libelle: banque.entete.libelle,
            paysId: banque.entete.paysId,
            adresse: banque.entete.adresse,
            codePostal: banque.entete.codePostal,
            ville: banque.entete.ville,
            telephone: banque.entete.telephone,
            fax: banque.entete.fax,
            codeBanque: banque.entete.codeBanque,
            bic: banque.entete.bic,
            commentaire: banque.entete.commentaire
        };
        const result = await this.$http.post(serviceUrl, data);
        return result.data;
    }

    async updateBanque(banque) {
        const data = banque.entete;
        const url = `${serviceUrl}/${banque.id}`;
        return await this.$http.put(url, data);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getPays(filters, sorts, pagination) {
        const paysUrl = `${__configuration.apiUrl}/massia/pays`;
        const res = await this.$http.get(paysUrl);

        return res.data;
    }
}
