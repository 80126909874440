import ReferentielMesuresController from './referentiel.mesures.controller';

export default {
    template: require('./referentiel.mesures.html'),
    controller: ReferentielMesuresController,
    bindings: {
        reset: '&',
        mesures: '=',
        onDelete: '&',
        onDeleteItem: '&',
        onDeleteAll: '&',
        domaine: '<',
        referentiel: '<',
        onSelectMesure: '&',
        onDuplicate: '&'
    }
};
