const stylesEchelleByValue = {
    0: 'lineaire',
    1: 'logarithmique'
};

const stylesEchelleValuesByName = {
    lineaire: 0,
    logarithmique: 1
};

export function getStyleEchelleNamesByValues() {
    return angular.copy(stylesEchelleByValue);
}

export function getStyleEchelleNamesWithValues() {
    return angular.copy(stylesEchelleValuesByName);
}
