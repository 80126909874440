export default function Configure($stateProvider) {
    $stateProvider.state('monitoring', {
        parent: 'common',
        url: '/monitoring',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true,
            label: '{{ "LAYOUT.NAVIGATION.OUTILS" | translate }}'
        }
    });

    $stateProvider.state('monitoring.list', {
        url: '/list',
        template: '<monitoring></monitoring>',
        rights: { domain: 'monitoring', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "MONITORING.BREADCRUMBS.MONITORING" | translate}}'
        },
        // on affiche l'historique dans la navigation
        /*navigation: {
            // navigationCls: 'hidden-xs hidden-sm', // TODO exemple : ce state n'apparaitra pas sur mobile
            menu: 'transport_no_category',
            translate: 'HISTORIQUE.BREADCRUMBS.HISTORIQUE',
            order: 3
        },*/
        search: 'MONITORING.BREADCRUMBS.MONITORING'
    });

    $stateProvider.state('monitoring.list.detail', {
        url: '/{id}',
        template: '<monitoring-detail></monitoring-detail>',
        rights: { domain: 'monitoring', right: 'read' },
        ncyBreadcrumb: {
            parent: 'monitoring.list',
            label: '{{ "MONITORING.BREADCRUMBS.MONITORING_DETAIL" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
