(function () {
    'use strict';

    angular
        .module('blocks.smart.table', [
            'angularMoment',
            'ui.bootstrap',
            'pascalprecht.translate',
            'blocks.datepicker',
            'blocks.helpers',
            'blocks.multiselect'
        ]);
})();