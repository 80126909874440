import validatorBuilder from 'validator-builder';
import DeviseEntete from '../devise-entete/devise.entete.model';

let deviseValidator = null;

export default class Devise {
    constructor(data) {
        if (data) {
            this.entete = new DeviseEntete(data.entete);
        } else {
            this.entete = new DeviseEntete();
        }
    }

    isValid() {
        deviseValidator = deviseValidator || validatorBuilder.getInstanceFor('Devise');
        const validationResults = deviseValidator.validate(this);
        return validationResults.isValid;
    }
}
