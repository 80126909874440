SocieteCaracteristiquesValidator.$inject = ['validator'];

export default function SocieteCaracteristiquesValidator(validator) {
    const instance = new validator();

    instance.generateRules = generateRules;

    /**
     * Il est nécessaire de générer les règles de validation à la main
     * Car les règles sont nettoyées dans le cadre du formulaire dynamique
     * (à chaque changement de formulaire on supprime les règles automatiques
     * ajoutées par le formulaire dynamique)
     */
    function generateRules() {
        // instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    }

    return instance;
}
