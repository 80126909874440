(function (angular, undefined) {
    'use strict';

    angular.module('blocks.helpers').service('dateHelper', datehelper);

    function datehelper() {
        /* jshint validthis: true */
        var service = this;

        service.addDays = addDays;
        service.setToMidnight = setToMidnight;
        service.isInSqlBoundaries = isInSqlBoundaries;

        function setToMidnight(date) {
            var dat = new Date(date);

            dat.setHours(0);
            dat.setMinutes(0);
            dat.setSeconds(0);
            dat.setMilliseconds(0);

            return dat;
        }

        function addDays(date, days) {
            var dat = new Date(date);
            dat.setDate(dat.getDate() + days);
            return dat;
        }

        function isInSqlBoundaries(date) {
            var dat = new Date(date);
            var minDate = new Date(Date.UTC(1753, 0, 1));
            var maxDate = new Date(Date.UTC(9999, 11, 31, 23, 59, 59, 997));

            return minDate <= dat && dat <= maxDate;
        }
    }
})(angular);
