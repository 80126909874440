import FormuleArchivesController from './formule.archives.controller';

export default {
    bindings: {
        produit: '=',
        formules: '=',
        onUpdate: '&'
    },
    template: require('./formule.archives.html'),
    controller: FormuleArchivesController
};
