import Presse3rModalImportController from './presse3r.modal.import.controller';

export default {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    controller: Presse3rModalImportController,
    template: require('./presse3r.modal.import.html')
};
