import { Component } from '../../../core/decorators';
import { StateParams, StateService } from '@uirouter/angularjs';
import { TypeAideService } from '../massia-service/type.aide.service';
import DocumentsService from '../../../app/common/documents/services/documents.service';
import Document from '../../../app/common/documents/components/document-form/document.model';

import * as angular from 'angular';

@Component({
    selector: 'massia-type-aide',
    bindings: {
        entity: '=',
        entityDomaine: '='
    },
    template: require('./massia.type.aide.tpl.html')
})
export default class MassiaTypeAideController {
    $state: StateService;
    $stateParams: StateParams;
    entity: any;
    entityDomaine: number;
    TypeAideService: TypeAideService;
    DocumentsService: DocumentsService;
    typesAide: any;
    documents: Document[];
    $uibModal: ng.ui.bootstrap.IModalService;
    /* @ngInject */
    constructor(
        $state: StateService,
        $stateParams: StateParams,
        TypeAideService: TypeAideService,
        DocumentsService: DocumentsService,
        $uibModal: ng.ui.bootstrap.IModalService
    ) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.TypeAideService = TypeAideService;
        this.DocumentsService = DocumentsService;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        await this.getTypeAide();
        if (this.entity.idTypeAide === 3) {
            await this.getDocumentListe();
        }
    }

    $onDestroy() {}

    async getTypeAide() {
        let typeAide = await this.TypeAideService.getTypeAide();
        if (typeAide) {
            return (this.typesAide = typeAide);
        }
    }

    async selectItem(list: any, data: any) {
        if (data) {
            this.entity.aideUrl = null;
            this.entity.document = null;
            this.entity.aideToolType = null;

            if (data.id !== this.entity.idTypeAide) {
                this.entity.idTypeAide = data.id;
            } else {
                list = list.map((e: { selected: boolean }) => (e.selected = !e.selected));

                this.entity.idTypeAide = null;
                if (this.entity.aideUrl) this.entity.aideUrl = null;
                if (this.entity.document) this.entity.document = null;
                if (this.entity.aideToolType) this.entity.aideToolType = null;
            }

            if (this.entity.idTypeAide === 3) {
                await this.getDocumentListe();
            } else if (this.entity.idTypeAide === 2) {
                this.entity.aideUrl = 'http://';
            }
        }
    }

    selectDocument(list: any, data: any) {
        if (data) {
            if (!this.entity.document || (this.entity.document && data.id !== this.entity.document.id)) {
                this.entity.document = data;
            } else {
                list = list.map((e: { selected: boolean }) => (e.selected = !e.selected));

                if (this.entity.document) this.entity.document = null;
            }
        }
    }

    async getDocumentListe() {
        try {
            let doc = await this.DocumentsService.getDocuments([], [], { skip: 0, take: 0 });
            if (doc) {
                this.documents = doc.items;
            }
        } catch (ex) {}
    }

    async AddDocument() {
        let result: Document = null;
        try {
            const modalInstance = this.$uibModal.open({
                component: 'documentModalForm',
                resolve: {
                    data: () => {
                        return { idOrigine: this.entity.id, typeOrigine: this.entityDomaine };
                    }
                },
                backdrop: false
            });

            result = await modalInstance.result;

            if (result) {
                this.documents.push(result);
                this.selectDocument(this.documents, result);
            }
        } catch (ex) {
            console.error(ex);
        }
    }
}
