export default class ProduitFormProducteursCertificationController {
    /* @ngInject */
    constructor(ProduitsService, notification, $translate, SiteAccreditationService) {
        this.ProduitsService = ProduitsService;
        this.notification = notification;
        this.$translate = $translate;
        this.SiteAccreditationService = SiteAccreditationService;
    }

    produit;
    producteur;
    certifications = []; //toutes les certifications produit
    siteCertifications = []; // certifications pour le produit et le producteur
    certifTypeProduit = []; //certifications de type produit pour le producteur
    selectedCertif = [];

    async $onInit() {
        this.loading = true;

        //* certifications d'un produit pour le producteur
        this.siteCertifications = this.getProducteurCertif(this.producteur);
        //  console.log(this.certifications);
        //* certifications de type produit pour le site
        this.certifTypeProduit = await this.ProduitsService.getProducteurCertificat(this.producteur);
        //console.log(this.certifTypeProduit);
        //* liste des normes
        const normes = await this.SiteAccreditationService.getListeNormes();
        this.normes = normes.items;

        this.verifySelectedCertif();
        this.loading = false;
    }

    //* filtre les certifications liées au produit pour le site selectionné
    getProducteurCertif(producteur) {
        // console.log(this.certifications);
        if (this.certifications) {
            return angular.copy(
                this.certifications.filter((cp) => {
                    return cp.idProducteur == producteur.id;
                })
            );
        }
        return [];
    }

    //* permet de cocher les certifications déjà existantes pour le produit/site et ajoute la liste des normes (isteven tableau)
    verifySelectedCertif() {
        this.siteCertifications.forEach((x) => {
            x.normesListe = angular.copy(this.normes);
            const selected = this.certifTypeProduit.filter((item) => {
                return item.id === x.idCertificat;
            });
            selected.forEach((item) => {
                item.selected = true;
            });
        });
    }

    //* ajoute/enlève une certification au produit
    // modifie aussi l'objet certifications lié aux parents (pour la sauvegarde)
    addCertif(data) {
        // console.log(data, this.siteCertifications, this.certifications);

        let index;
        let index2;
        /*  if (this.siteCertifications && this.siteCertifications.length > 0) { */
        index = this.siteCertifications.findIndex((x) => x.idCertificat === data.id);
        if (index < 0) {
            this.siteCertifications.push(this.newCertif(data));
        } else {
            this.siteCertifications.splice(index, 1);
        }
        /*  } */
        /*
        if (this.certifications && this.certifications.length > 0) { */
        index2 = this.certifications.findIndex((x) => x.idCertificat === data.id);
        if (index2 < 0) {
            this.certifications.push(this.newCertif(data));
        } else {
            this.certifications.splice(index, 1);
        }
        /* } */
        this.produit.certifications = this.certifications;
    }

    //* formate l'objet recu au clic sur un item du isteven pour l'ajouter a la liste des certifications
    newCertif(data) {
        const siteCertif = {
            id: null,
            idCertificat: data.id,
            idProducteur: data.idSiteCertifie,
            idProduit: this.idProduit,
            libelleCG: data.certificatGenerique.nom,
            codeCG: data.certificatGenerique.code,
            numero: data.numCertificat,
            start: data.certificatGenerique.dateDebut,
            end: data.certificatGenerique.dateFin,
            logo: data.logo,
            normes: [],
            normesListe: this.normes,
            siteAcc: data.siteAccrediteur
        };

        if (data.produits.length > 0) {
            data.produits.forEach((p) => {
                if (p.idProduit == this.produit.id) {
                    const n = {
                        idCertifProduit: p.id,
                        id: p.idNorme,
                        libelle: p.libelleNorme,
                        code: p.codeNorme
                    };
                    siteCertif.normes.push(n);
                }
            });
        }
        return siteCertif;
    }

    //* ajoute/enlève une norme a la certification d'un produit
    addNorme(data, index) {
        const i = this.siteCertifications[index].normes.findIndex((x) => x.id === data.id);
        const idx = this.getCertificationIndex(this.siteCertifications[index]);
        const j = this.certifications[idx].normes.findIndex((x) => x.id === data.id);

        if (i < 0 && j < 0) {
            const n = {
                idCertifProduit: null,
                id: data.id,
                libelle: data.libelle,
                code: data.code
            };
            this.siteCertifications[index].normes.push(n);
            this.certifications[idx].normes.push(n);
        } else {
            this.siteCertifications[index].normes.splice(i, 1);
            this.certifications[idx].normes.splice(j, 1);
        }
        this.produit.certifications = this.certifications;
    }

    //* récupère la certification correspondante dans la liste lié aux parents
    getCertificationIndex(siteCertif) {
        return this.certifications.findIndex(
            (x) => x.idCertificat == siteCertif.idCertificat && x.idProducteur == siteCertif.idProducteur && x.idProduit == siteCertif.idProduit
        );
    }
}
