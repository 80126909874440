import ChantiersGrillesConfig from './_config/chantiers.grilles.config';
import ChantiersGrillesRoutes from './_config/chantiers.grilles.routes';
import ChantiersGrillesComponent from './components/chantiers-grilles';
import ChantiersGrillesService from './services/chantiers.grilles.service';
import ChantiersGrillesCommunicationService from './services/chantiers.grilles.communication.service';

const moduleName = 'app.massia.gestion.chantiers.grilles';

angular
    .module(moduleName, [])
    .config(ChantiersGrillesConfig)
    .config(ChantiersGrillesRoutes)
    .service('ChantiersGrillesService', ChantiersGrillesService)
    .service('ChantiersGrillesCommunicationService', ChantiersGrillesCommunicationService)
    .component('chantiersGrilles', ChantiersGrillesComponent);

export default moduleName;
