//
import syntheseList from './components/synthese-list';
//import syntheseForm from './components/synthese-form';

import Configure from './config/synthese.routes';
import conf from './config/synthese.route';
import syntheseEntete from './components/synthese-form-modal/components/synthese-entete';
import syntheseBlocSelection from './components/synthese-form-modal/components/synthese-bloc-selection';
import syntheseBlocSelectionEssai from './components/synthese-form-modal/components/synthese-bloc-selection/bloc-selection-essai';
import syntheseBlocSelectionCritereValeurForm from './components/synthese-form-modal/components/synthese-bloc-selection/bloc-selection-critere-form-valeur';
import critrereText from './components/synthese-form-modal/components/synthese-bloc-selection/bloc-selection-critere-form-valeur/templates/texte';
import critrereNombreEntier from './components/synthese-form-modal/components/synthese-bloc-selection/bloc-selection-critere-form-valeur/templates/nombre-entier';
import critrereNombreDecimal from './components/synthese-form-modal/components/synthese-bloc-selection/bloc-selection-critere-form-valeur/templates/nombre-decimal';
import critrereIdTable from './components/synthese-form-modal/components/synthese-bloc-selection/bloc-selection-critere-form-valeur/templates/id-table';
import critrereIdEnum from './components/synthese-form-modal/components/synthese-bloc-selection/bloc-selection-critere-form-valeur/templates/id-enum';
import critrereHeure from './components/synthese-form-modal/components/synthese-bloc-selection/bloc-selection-critere-form-valeur/templates/heure';
import critrereDate from './components/synthese-form-modal/components/synthese-bloc-selection/bloc-selection-critere-form-valeur/templates/date';
import critrereBool from './components/synthese-form-modal/components/synthese-bloc-selection/bloc-selection-critere-form-valeur/templates/bool';
import syntheseControle from './components/synthese-form-modal/components/synthese-controle';
import syntheseModalBlocSelection from './components/synthese-form-modal/components/synthese-modal-bloc-selection';
import syntheseAffichage from './components/synthese-form-modal/components/synthese-affichage';
//import Dynamic from './components/synthese-result/components/setup-modal/components/synthese-affichage/directives/index';
import ftp from './components/synthese-result/components/ftp';
import recomposition from './components/synthese-result/components/recomposition';
import controlePesee from './components/synthese-result/components/controle-pesee';
//import modalColumnSelect from './components/synthese-result/components/setup-modal/components/synthese-affichage/modal-column-select';
//import syntheseResult from './components/synthese-result';
import bonLivraison from './components/synthese-result/components/bon-livraison/bon.livraison.controller';
import factureResult from './components/synthese-result/components/facture/facture.controller';
import production from './components/synthese-result/components/production';
import setupSyntheseModal from './components/synthese-form-modal/setup.modal.controller';
import saveAsModal from './components/synthese-result/components/save-as-modal/save.as.modal.controller';
import { SyntheseTableComponent } from './components/synthese-result/synthese.table.controller';
import syntheseSettingController from './components/synthese-form-modal/components/synthese-setting/synthese.setting.controller';
import { template } from './components/synthese-result/constants/ui.grid.template';

const moduleName = 'app.massia.common.synthese.synthese';
angular
	.module(moduleName, [])
	//.config(Configure)
	.run(conf)
	.constant('uiGridTemplate', template)
	.component('syntheseList', syntheseList)
	//.component('syntheseForm', syntheseForm)
	.component('setupSyntheseModal', setupSyntheseModal)
	.component('syntheseTable', SyntheseTableComponent)
	.component('syntheseEntete', syntheseEntete)
	.component('syntheseSetting', syntheseSettingController)
	.component('syntheseControle', syntheseControle)
	.component('syntheseBlocSelectionCritereValeurForm', syntheseBlocSelectionCritereValeurForm)
	.component('syntheseBlocSelection', syntheseBlocSelection)
	.component('syntheseModalBlocSelection', syntheseModalBlocSelection)
	.component('syntheseBlocSelectionEssai', syntheseBlocSelectionEssai)
	.component('syntheseAffichage', syntheseAffichage)
	.component('critereText', critrereText)
	.component('critereNombreEntier', critrereNombreEntier)
	.component('critereNombreDecimal', critrereNombreDecimal)
	.component('critereIdTable', critrereIdTable)
	.component('critereIdEnum', critrereIdEnum)
	.component('critereHeure', critrereHeure)
	.component('critereDate', critrereDate)
	.component('critereBool', critrereBool)
	//.component('dynamicHeader', Dynamic)
	//.component('modalColumn', modalColumnSelect)
	.component('ftpResult', ftp)
	.component('recompositionResult', recomposition)
	.component('controlePeseeResult', controlePesee)
	.component('bonLivraisonResult', bonLivraison)
	.component('factureResult', factureResult)
	.component('productionResult', production)
	.component('saveAsModal', saveAsModal);
export default moduleName;
