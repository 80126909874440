import { copy } from 'angular';

/* @ngInject */
export default class ProduitEnteteController {
    constructor(
        _,
        $state,
        $stateParams,
        ProduitsCommunicationService,
        ProduitsService,
        ProduitsTypesService,
        ModalService,
        notification,
        SitesService,
        TypesService,
        MassiaApplicationService,
        CodeAutomatiqueService
    ) {
        this._ = _;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ProduitsCommunicationService = ProduitsCommunicationService;
        this.ProduitsService = ProduitsService;
        this.ProduitsTypesService = ProduitsTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.SitesService = SitesService;
        this.TypesService = TypesService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.CodeAutomatiqueService = CodeAutomatiqueService;
    }

    async $onInit() {
        if (this.$stateParams.id) {
            this.isEditMode = true;
        }
        this.produit = this.produit || {};
        this.entete = this.produit.entete;

        this.getTypes();

        this.checkCodeAuto();
        this.types = this.types || [];
        this.serieTamisGrandD = {};
        this.serieTamisPetitd = {};
        //this.getGammes();
        this.selectedSites = [];
        this.typeSelected = {};

        //if (this.entete.id) this.getTamis();

        if (!this.$stateParams.edit) {
            /************* duplication  *****/
            if (this.entete.code) {
                this.checkCodeUnicity(this.entete.code);
            }
            /************************************************************/
        }
        this.changeType(this.entete.typeId);
        // on sauvegarde la fonction de désabonnement pour l'invoquer au destroy
        // il est important de noter la façon de s'abonner :
        // on utilise les arrow functions, () => {},
        // elles permettent de sauvegarder l'état du 'this'
        // et d'éviter la perte du contexte lors de l'appel de la fonction depuis un autre controller
        //this.unregisterReinit = this.ProduitsCommunicationService.registerReinit(() => this.initForm());
        //this.preselectSites();
        this.getNiveauVisibilite();
        this.showCode2 = false;
        this.getLicence();
    }

    async checkCodeAuto() {
        try {
            const code = await this.CodeAutomatiqueService.getCodeAutomatique(this.MassiaApplicationService.getApplication());
            const res = code.find((x) => x.domaineCode === 'Produits');

            this.code = res;
        } catch (err) {
            console.error(err);
        }
    }

    async getNiveauVisibilite() {
        if (this.entete.typeId) {
            this.entete.niveauVisibilite = await this.ProduitsTypesService.GetNiveauVisibilite(this.entete.typeId);
        }
    }

    $onDestroy() {
        //this.unregisterReinit();
    }

    getLicence() {
        //MN: pour "Eqiom", on affiche les CODE2(SAP)
        if (__configuration.licenceLaboratoire === '2') {
            this.showCode2 = true;
        }
    }

    async createType() {
        const modalInstance = this.ModalService.confirm({
            modalTitle: 'PRODUITS.CONFIRM_CREATE_TYPE.TITLE',
            modalMsg: 'PRODUITS.CONFIRM_CREATE_TYPE.MESSAGE',
            headerClass: 'modal-warning'
        });
        modalInstance.result.then(() => this.$state.go('produits.newType'));
    }

    async getTypes() {
        //this.startLoading();

        try {
            this.types = await this.ProduitsTypesService.getAll();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            //this.stopLoading();
        }
    }

    async changeType(selected) {
        if (selected) {
            this.typeSelected = await this.TypesService.getTypeById(selected);
            this.produit.entete.idSerieDim = this.typeSelected.idSerieDimensionnelleTamis;
            this.produit.entete.granulometrie = this.typeSelected.enumGranulometrie;
            this.produit.entete.typeCode = this.typeSelected.code;
            this.produit.entete.famille = this.typeSelected.codeFamille;
            this.entete.idSerieDim = this.produit.entete.idSerieDim;
            this.entete.granulometrie = this.produit.entete.granulometrie;
            this.entete.typeCode = this.produit.entete.typeCode;
            // montre ou cache l'accès aux formules si type composé.
            this.entete.typeObj.isRecomposition = this.typeSelected.isRecompose;
            await this.getSites();
        } else {
            this.serieTamisGrandD = {};
            this.serieTamisPetitd = {};
        }
        this.getTamis();
        this.getGammes();
        this.getNiveauVisibilite();
    }

    async getTamis() {
        //this.startLoading();
        try {
            if (this.produit.entete.idSerieDim) {
                this.serieTamisPetitd = await this.ProduitsService.getTamis(this.produit.entete.idSerieDim);
                copy(this.serieTamisPetitd, this.serieTamisGrandD);
                /*for(var i = this.serieTamisGrandD.tamis.length - 1; i >= 0; i--){
                    if(this.serieTamisGrandD.tamis[i].ouverture == 0){
                        this.serieTamisGrandD.tamis.splice(i,1);
                    }
                }  */ //SP 31/07/17 remise en place de D à 0 pour les boues
            } else {
                this.serieTamisGrandD = {};
                this.serieTamisPetitd = {};
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            //this.stopLoading();
        }
    }

    async getSites() {
        try {
            const selectedSiteIds = copy(this.produit.entete.sitesIds);
            // SL appel de la liste des sites producteurs uniquement via FP methode
            if (this.produit.entete.famille) {
                const sitesFam = await this.SitesService.getSitesProducteursByFamille(
                    this.MassiaApplicationService.getApplication(),
                    this.produit.entete.famille
                );
                this.allSites = sitesFam;
            } else {
                const sites = await this.ProduitsService.getSites();
                this.allSites = sites.items;
            }
            // SL affiche la liste des sites associes a ce produit dans le formulaire

            if (this.isEditMode) {
                this.allSites.forEach((item) => {
                    if (selectedSiteIds.indexOf(item.id) !== -1) {
                        item.selected = true;
                    }
                });
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            //this.stopLoading();
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.produit.entete.codeExists = null;
        } else {
            this.startCodeLoading();
            try {
                if (code.match(/^[a-zA-Z0-9_|]*$/)) {
                    this.entete.codeExists = await this.ProduitsService.codeExists(code);
                }
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopCodeLoading();
            }
        }
    }

    async getGammes() {
        try {
            const gammes = await this.ProduitsService.getGammes();
            let tmpId = -1;
            if (this.typeSelected && this.typeSelected.idFamille >= 0) {
                tmpId = this.typeSelected.idFamille;
            } else if (!this.typeSelected) {
                tmpId = this.entete.idFamille;
            }
            if (tmpId >= 0) {
                gammes.items = gammes.items.filter((x) => {
                    if (x.idFamille === null) {
                        return true;
                    } else if (x.idFamille >= 0 && x.idFamille === tmpId) {
                        return true;
                    }
                    return false;
                });
            }

            this.gammes = gammes.items;
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    selectGamme(data, listGamme) {
        if (data) {
            if (data.id !== this.entete.idGamme) {
                this.entete.idGamme = data.id;
            } else {
                listGamme.map((e) => {
                    if (data.id === this.entete.idGamme) {
                        return (e.selected = !e.selected);
                    }
                });

                this.entete.idGamme = null;
            }
        }
    }

    selectProducteur(data) {
        const currentSites = [];
        for (let i = 0; i < this.selectedSiteIds.length; i++) {
            const site = this.allSites.find((e) => e.id === this.selectedSiteIds[i]);
            currentSites.push(site.libelle);
        }
        this.entete.sites = currentSites;
        this.entete.sitesIds = angular.copy(this.selectedSiteIds);
        this.entete.reloadSitesLies = !this.entete.reloadSitesLies;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }

    cardChecker() {
        return (
            this.entete.granulometrie === 1 ||
            this.entete.granulometrie > 0 ||
            (this.MassiaApplicationService.getApplication() === 'gestion' && this.produit.entete.isInerte == true) ||
            (this.MassiaApplicationService.getApplication() === 'gestion' && this.produit.entete.isInerte == true)
        );
    }
}
