import Controle from './controle.model';
import ControleEntete from '../controle-entete/controle.entete.model';

export default class ControleFormControler {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'ControlesCommunicationService',
        'ControlesService',
        'notification',
        '$location',
        '$anchorScroll',
        'MOPService'
    ];

    constructor($scope, $state, $stateParams, ControlesCommunicationService, ControlesService, notification, $location, $anchorScroll, MOPService) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ControlesCommunicationService = ControlesCommunicationService;
        this.ControlesService = ControlesService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.MOPService = MOPService;
    }

    $onInit() {
        this.MOPService.setMop([{ title: 'CONTROLES.BREADCRUMBS.CONTROLES_LIST', filename: 'TypesDeControles.pdf', application: 'gestion' }]);
        this.isEditMode = false;
        this.controle = {};
        this.loading = false;

        this.closeAllOnglets();
        this.openFirstOnglet();
        this.setControle(this.$stateParams.id);
    }

    $onDestroy = () => {
        this.MOPService.resetMop();
    };

    async setControle(controleId) {
        if (controleId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                await this.setControleEntete(controleId);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('controles.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.controle = new Controle();
        }
    }

    async setControleEntete(controleId) {
        const entete = await this.ControlesService.getControleEnteteById(controleId);
        this.controle.entete = new ControleEntete(entete);
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                let id = false;
                if (this.isEditMode) {
                    await this.ControlesService.updateEnteteControle(this.controle.entete);
                    this.notification.success('CONTROLES.UPDATED');
                    id = this.controle.entete.id;
                } else {
                    id = await this.ControlesService.createControle(this.controle.entete);
                    this.notification.success('CONTROLES.CREATED');
                }

                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async appliquer() {
        const id = await this.sauvegarder();

        if (id && this.controle.entete.id) {
            this.setControle(this.$stateParams.id);
        } else if (id) {
            this.$state.go('controles.edit', { id: id });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();
        if (success) {
            this.annuler();
        }
    }

    openFirstOnglet() {
        this.ongletOpen = {
            isEnteteOpen: true,
            isResultatOpen: true
        };
    }

    closeAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: false,
            isResultatOpen: false
        };
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('controles.list');
    }

    reinit() {
        this.openFirstOnglet();
        this.setControle(this.$stateParams.id);
        this.ControlesCommunicationService.raiseReinit();
    }

    checkValidity() {
        this.closeAllOnglets();
        let validity = true;

        let firstScroll = true;
        if (!this.controle.entete.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('entete');
                firstScroll = false;
            }

            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.$scope.$broadcast('controleEnteteValidations');
        }

        return validity;
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
