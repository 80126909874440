import CdgTvaController from './cdg.tva.controller';

export default {
    bindings: {
        frontFilters: '=',
        filterToBack: '=',
        parametre: '=',
        listes: '<',
        source: '<',
        domaine: '<',
        dateApplication: '=',
        onUpdate: '&',
        onDelete: '&'
    },
    template: require('./tva.html'),
    controller: CdgTvaController
};
