import ImageCaracteristiques from './image.caracteristiques.model';

export default class ImageCaracteristiquesController {
    static $inject = ['_', 'ImagesTypesService', 'notification', '$timeout', '$scope'];

    constructor(_, ImagesTypesService, notification, $timeout, $scope) {
        this._ = _;
        this.ImagesTypesService = ImagesTypesService;
        this.notification = notification;
        this.$timeout = $timeout;
        this.$scope = $scope;
    }

    $onInit() {
        this.loading = false;
        this.typeFormat = this.typeFormat || [];
        this.image = this.image || {};
        this.niveauVisibilite = 0;
        this.caracteristiques = new ImageCaracteristiques(this.image.caracteristiques);
        const _this = this;
        this.$scope.$watch('$ctrl.image.entete.niveauVisibilite', function (newValue, oldValue) {
            _this.niveauVisibilite = _this.image.entete.niveauVisibilite;
        });
    }

    $onDestroy() {
        this.$timeout.cancel(this.typeFormatTimeout);
    }

    $onChanges(objChanges) {
        if (objChanges.idType) {
            if (!objChanges.idType.isFirstChange()) {
                this.caracteristiques = new ImageCaracteristiques();
            }

            this.getTypeFormat(objChanges.idType.currentValue);
        }
    }

    // cette fonction est appelée automatiquement
    // a chaque digest (dirty check) d'angular
    $doCheck() {
        if (!angular.equals(this.caracteristiques, this.image.caracteristiques)) {
            this.onUpdate({
                caracteristiques: angular.copy(this.caracteristiques)
            });
        }
    }

    async getTypeFormat(idType) {
        if (idType) {
            // on démarre l'animation de loading avant le chargement des données
            this.startLoading();

            // on encapsule l'appel serveur dans un try...catch pour récupérer les erreurs
            try {
                // on récupère les données depuis le service qui interroge le serveur
                const typeFormat = await this.ImagesTypesService.getCaracteristiques(idType);
                // on trie le tableau selon la propriété 'position'
                // on publie le tableau dans le this pour l'afficher dans la vue
                this.typeFormat = this._.sortBy(typeFormat, 'position');
            } catch (ex) {
                // en cas de problème on notifie l'utilisateur
                this.notification.error(ex.data);
            } finally {
                // On supprimer le timeout précédent (fuite mémoire)
                this.$timeout.cancel(this.typeFormatTimeout);

                // une fois l'appel terminé, on stoppe l'animation de loading
                this.typeFormatTimeout = this.$timeout(() => {
                    this.stopLoading();
                });
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
