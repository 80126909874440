import validatorBuilder from 'validator-builder';
import PrestationEntete from '../prestation-entete/prestation.entete.model';

let prestationValidator = null;

export default class Prestation {
    constructor(data) {
        if (data) {
            this.entete = new PrestationEntete(data.entete);
        } else {
            this.entete = new PrestationEntete();
        }
    }

    isValid() {
        prestationValidator = prestationValidator || validatorBuilder.getInstanceFor('Prestation');
        const validationResults = prestationValidator.validate(this);
        return validationResults.isValid;
    }
}
