import TracteursConfig from './_config/tracteurs.config';
import TracteursRoutes from './_config/tracteurs.routes';
import TracteursComponent from './components/tracteurs';
import TracteursService from './services/tracteurs.service';

const moduleName = 'app.massia.gestion.tracteurs';

angular
    .module(moduleName, [])
    .config(TracteursConfig)
    .config(TracteursRoutes)
    .service('TracteursService', TracteursService)
    .component('tracteurs', TracteursComponent);

export default moduleName;
