import DomainesDetailController from './domaines.detail.controller';

export default {
    template: require('./domaines.detail.tpl.html'),
    controller: DomainesDetailController,
    bindings: {
        onLoad: '&',
        displayDroits: '<'
    }
};
