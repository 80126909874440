/* @ngInject */
export default class SyntheseEntityCtrlEnrobesController {
    defaultChartOption = [
        {
            class: 'mas-col-8',
            idPrefix: 'evol',
            type: 'line'
        },
        {
            class: 'mas-col-4',
            idPrefix: 'repar',
            type: 'bar'
        } /* ,
        {
            class: 'mas-col-12',
            idPrefix: 'ecar',
            type: 'line'
        } */
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        notification,
        $translate,
        $filter,
        globalizationManagementService,
        moment,
        ReferencesControleService,
        NormesService,
        SyntheseEntitiesService,
        SaisieProductionService,
        TemplateImpressionService,
        Interpreteur,
        _
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$filter = $filter;
        this.notification = notification;
        this.$translate = $translate;
        this.moment = moment;
        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
        this.ReferencesControleService = ReferencesControleService;
        this.NormesService = NormesService;
        this.SyntheseEntitiesService = SyntheseEntitiesService;
        this.SaisieProductionService = SaisieProductionService;
        this.TemplateImpressionService = TemplateImpressionService;
        this.Interpreteur = Interpreteur;
        this._ = _;
    }

    async $onInit() {
        try {
            this.startLoading();
            if (this.$state.params.obj === null) {
                this.$state.go('syntheseentities.list');
            } else {
                this.model = this.$state.params.obj;
                await this.OnLoad();
            }
            this.stopLoading();
        } catch (err) {
            this.notification.error(err);
            this.stopLoading();
        }
    }

    /* TODO :
     *           trier les prelevement par gamme de produit et afficher un tableau par gamme et trier les prelevement par date. (OK)
     *           faire les moyen selon le Nce (ok)
     *           faire les conformités des ecarts (ok) et des moyennes sur VI selon borne (ok)
     *           faire les Graphique des chaques tableaux
     *           finir par les périodes.
     */

    async OnLoad() {
        try {
            this.rechercheDesBornesNormatives();
            const modelTrie = await this.recuperationReference();
            await this.rechercheCalendriers();
            await this.rechercheProductions();
            this.recuperationPrelevementParGammeProduit(modelTrie);
            await this.recuperationCalculStatParGamme(modelTrie);
            this.calculDeLaCentrale(modelTrie);
            this.rechercheSemaines(modelTrie);
            this.modelAffichage = modelTrie;
            this.graphs = [];
            this.formatGraphs();
        } catch (error) {
            this.notification.error(error);
        }
    }

    //recherche du niveau de fréquence de la centrale et des calendriers liés à cette fréquence
    async rechercheCalendriers() {
        if (this.$state.params.obj.resultat.prelevementBlock && this.$state.params.obj.resultat.prelevementBlock.length > 0) {
            const idProducteur = this.$state.params.obj.resultat.prelevementBlock[0].idProducteur;
            this.calendriers = await this.SyntheseEntitiesService.getCalendriersEnrobes(idProducteur);
        }
    }

    async rechercheProductions() {
        if (this.$state.params.obj.resultat.prelevementBlock && this.$state.params.obj.resultat.prelevementBlock.length > 0) {
            const idProducteur = this.$state.params.obj.resultat.prelevementBlock[0].idProducteur;
            const periode = {
                du: this.$state.params.obj.resultat.prelevementBlock[0].datePrelevement.split(' ')[0],
                au: this.$state.params.obj.resultat.prelevementBlock[
                    this.$state.params.obj.resultat.prelevementBlock.length - 1
                ].datePrelevement.split(' ')[0]
            };
            const query = {
                idProduit: 0,
                idSite: idProducteur,
                period: periode
            };
            this.productions = await this.SaisieProductionService.getAll(query);
        }
    }

    rechercheDesBornesNormatives() {
        if (!this.bornesEssaisEnrobes) {
            this.bornesEssaisEnrobes = {};
        }

        for (let j = 0; j < this.$state.params.obj.sousEssaisTamis.length; j++) {
            const essStat = this.$state.params.obj.sousEssaisTamis[j];
            const keyEssai = essStat.idSousEssai ? 'E' + essStat.idSousEssai : essStat.idTamis ? 'T' + essStat.idTamis : 'D' + essStat.libelle;
            this.bornesEssaisEnrobes[keyEssai] = { libelleEssai: essStat.libelleEssai, libelle: essStat.libelle, gammes: {} };

            if (this.$state.params.obj.resultat.prelevementBlock && this.$state.params.obj.resultat.prelevementBlock.length > 0) {
                const lstAllGammes = [];
                this.$state.params.obj.resultat.prelevementBlock.map((e) => {
                    //trouver la spec du prélèvement
                    const tmpGamme = this.$state.params.obj.resultat.normeSpec.find((g) => {
                        if (g.codeGamme === e.codeGammeProduit) {
                            if (g.grandD) {
                                return eval(e.grandDiametre + g.grandD);
                            }
                            return true;
                        }
                        return false;
                    });
                    if (tmpGamme) {
                        e.idSpec = tmpGamme.id;
                        e.libelleGamme = tmpGamme.libelleGamme;
                        e.condGrandD = tmpGamme.grandD ? 'D' + tmpGamme.grandD : '';
                        const idxGam = lstAllGammes.findIndex((g) => g.idSpec === tmpGamme.id);
                        if (idxGam < 0) {
                            lstAllGammes.push({
                                idSpec: tmpGamme.id,
                                id: tmpGamme.idGamme,
                                code: tmpGamme.codeGamme,
                                condD: tmpGamme.grandD,
                                libGamme: tmpGamme.libelleGamme
                            });
                        }
                    }
                });

                const sepOkPourData = (3 / 2).toString().substring(1, 2);
                for (let g = 0; g < lstAllGammes.length; g++) {
                    const keyGamme = lstAllGammes[g].idSpec;

                    this.bornesEssaisEnrobes[keyEssai].gammes[keyGamme] = {
                        idSpec: lstAllGammes[g].idSpec,
                        id: lstAllGammes[g].id,
                        code: lstAllGammes[g].code,
                        condD: lstAllGammes[g].condD,
                        libGamme: lstAllGammes[g].libGamme,
                        stats: {}
                    };

                    const spec = this.$state.params.obj.resultat.normeSpec.find((e) => e.id == lstAllGammes[g].idSpec);

                    if (spec.essais && spec.essais.length > 0) {
                        let lstEss = spec.essais.filter((e) => (e.idTamis ? e.idTamis === essStat.idTamis : e.idSousEssai === essStat.idSousEssai));
                        if ((!lstEss || lstEss.length <= 0) && essStat.tamis) {
                            lstEss = [];
                            const lstEssais = spec.essais.filter((e) => e.code === essStat.codeEssai);
                            for (let es = 0; es < lstEssais.length; es++) {
                                let conditionsOk = false;
                                const ess = lstEssais[es];

                                if (!essStat.idTamis && essStat.idDimRq) {
                                    //dimension remarquable
                                    if (essStat.libelle && ess.formuleDimRq) {
                                        //dimrq sur norme ET sur stat
                                        if (essStat.libelle == ess.formuleDimRq) {
                                            lstEss.push(ess);
                                        }
                                    } else {
                                        if (!ess.ouvertureTamis && ess.condition && ess.condition.includes('@TAMIS')) {
                                            //pas tamis défini sur norme
                                            //chercher dans les prélèvements une valeur d'essai avec cette dimRq
                                            let tamisTemp = -1;
                                            let grandD = -1;
                                            this.$state.params.obj.resultat.prelevementBlock.map((p) => {
                                                if (tamisTemp == -1 && p.idSpec == keyGamme) {
                                                    const tamisLig = p.listeTamisLignes.find((t) => t.dimensionRemarquable == essStat.libelle);
                                                    if (tamisLig) {
                                                        tamisTemp = tamisLig.ouvertureTamis;
                                                        grandD = p.grandDiametre;
                                                    }
                                                }
                                            });
                                            if (tamisTemp != -1) {
                                                let tmpCondition = angular.copy(ess.condition);
                                                tmpCondition = tmpCondition
                                                    .replace(/@TAMIS/g, tamisTemp)
                                                    .replace(/ /g, '')
                                                    .replace(/@D/g, grandD);
                                                if (sepOkPourData == ',') {
                                                    tmpCondition = tmpCondition.replace(/\./g, ',');
                                                } else {
                                                    tmpCondition = tmpCondition.replace(/\,/g, '.');
                                                }
                                                if (tmpCondition.includes('AND')) {
                                                    tmpCondition = tmpCondition.replace(/&/g, '&&');
                                                }
                                                if (tmpCondition.includes('OR')) {
                                                    tmpCondition = tmpCondition.replace(/\|/g, '||');
                                                }
                                                try {
                                                    conditionsOk = eval(tmpCondition);
                                                } catch {
                                                    conditionsOk = false;
                                                }
                                                if (conditionsOk) {
                                                    lstEss.push(ess);
                                                }
                                            }
                                        }
                                    }
                                } else if (ess && ess.condition && ess.condition.includes('@TAMIS')) {
                                    let tmpCondition = angular.copy(ess.condition);
                                    tmpCondition = tmpCondition.replace(/@TAMIS/g, essStat.ouvertureTamis).replace(/ /g, '');
                                    if (sepOkPourData == ',') {
                                        tmpCondition = tmpCondition.replace(/\./g, ',');
                                    } else {
                                        tmpCondition = tmpCondition.replace(/\,/g, '.');
                                    }
                                    if (tmpCondition.includes('AND')) {
                                        tmpCondition = tmpCondition.replace(/AND/g, '&&');
                                    }
                                    if (tmpCondition.includes('OR')) {
                                        tmpCondition = tmpCondition.replace(/OR/g, '||');
                                    }
                                    try {
                                        conditionsOk = eval(tmpCondition);
                                    } catch {
                                        conditionsOk = false;
                                    }
                                    if (conditionsOk) {
                                        lstEss.push(ess);
                                    }
                                }
                            }
                        }

                        if (lstEss && lstEss.length > 0) {
                            for (let s = 0; s < this.$state.params.obj.synthese.listeSyntheseCritereTraitement.length; s++) {
                                const formule = this.$state.params.obj.synthese.listeSyntheseCritereTraitement[s].formule;
                                const tmpBorne = this.getBornesEnrobes(formule, lstEss);
                                if (tmpBorne) {
                                    this.bornesEssaisEnrobes[keyEssai].gammes[keyGamme].stats[formule] = tmpBorne;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    getBornesEnrobes(formuleBorne, specEss) {
        if (specEss && specEss.length > 0) {
            const tab = [];
            for (let i = 0; i < specEss.length; i++) {
                const el = specEss[i];
                let tmpBorne = null;

                /*if (formuleBorne === '@NBR') {
                    tmpBorne = { formuleCalcStat: el.formuleCalcStat, formuleBorne: formuleBorne, valeur: el.limiteInferieure };
                }*/
                if (formuleBorne === '@LI') {
                    tmpBorne = { formuleCalcStat: el.formuleCalcStat, formuleBorne: formuleBorne, valeur: el.limiteInferieure };
                }
                if (formuleBorne === '@LS') {
                    tmpBorne = { formuleCalcStat: el.formuleCalcStat, formuleBorne: formuleBorne, valeur: el.limiteSuperieure };
                }
                /*if (formuleBorne === '@MAX') {
                    tmpBorne = { formuleCalcStat: el.formuleCalcStat, formuleBorne: formuleBorne, valeur: el.limiteSuperieure };
                }
                if (formuleBorne === '@MIN') {
                    tmpBorne = { formuleCalcStat: el.formuleCalcStat, formuleBorne: formuleBorne, valeur: el.limiteInferieure };
                }*/
                tab.push(tmpBorne);
            }
            return tab;
        }
    }

    async recuperationReference() {
        if (this.$state.params.obj.resultat.prelevementBlock && this.$state.params.obj.resultat.prelevementBlock.length > 0) {
            try {
                const listRefs = [];
                // on parcours les blocks de prelevements
                for (let i = 0; i < this.$state.params.obj.resultat.prelevementBlock.length; i++) {
                    const block = this.$state.params.obj.resultat.prelevementBlock[i];
                    // on récupère la reference de chaque block
                    let ref = listRefs.find((e) => e.id === block.idReference);
                    if (!ref) {
                        ref = await this.ReferencesControleService.getReferenceById(block.idReference);
                        listRefs.push(ref);
                    }

                    if (ref && ref.mesures && ref.mesures.length > 0) {
                        if (this.model.formattedResult.lignes && this.model.formattedResult.lignes.length > 0) {
                            const valEss = this.model.formattedResult.lignes[i].valeursEssais;
                            this.model.formattedResult.lignes[i].conformiteTotVI = true;
                            //this.model.formattedResult.lignes[i].conformiteTotMoy = true;
                            if (valEss && valEss.length > 0) {
                                for (let k = 0; k < valEss.length; k++) {
                                    const ess = valEss[k];
                                    ess.valeur = Number.parseFloat(ess.valeur) || null;
                                    ess.conformiteMoy = true;
                                    ess.conformiteEcart = true;
                                    let ouvertureTamis = ess.tamis && !ess.isDimRq ? Number.parseFloat(ess.codeSousEssai) : -1;
                                    if (ess.isDimRq) {
                                        //chercher dans block le tamis avec la dimRq
                                        const idxTamisLig = block.listeTamisLignes.findIndex((y) => y.dimensionRemarquable == ess.dimRq);
                                        if (idxTamisLig > -1) {
                                            ouvertureTamis = block.listeTamisLignes[idxTamisLig].ouvertureTamis;
                                        }
                                    }
                                    const idxEss = ref.mesures.findIndex((x) =>
                                        ess.tamis
                                            ? x.essaiId === ess.idEssai && x.tamis && Number.parseFloat(x.ouvertureTamis) === ouvertureTamis
                                            : x.essaiId === ess.idEssai && x.sousEssaiId === ess.idSousEssai
                                    );

                                    if (idxEss > -1) {
                                        ess.valeurXt = ref.mesures[idxEss].moyenne;
                                        ess.borneInferieure = ref.mesures[idxEss].borneInferieure;
                                        ess.borneSuperieure = ref.mesures[idxEss].borneSuperieure;
                                        ess.ecart = null;
                                        ess.moy = null;
                                        if (typeof ess.valeur === 'number') {
                                            ess.ecart = Number.parseFloat(
                                                this.calculdesEcartVIXT(Number.parseFloat(ess.valeur), Number.parseFloat(ess.valeurXt))
                                            );
                                            if (typeof ess.ecart === 'number') {
                                                if (ref.mesures[idxEss].limiteInferieure || ref.mesures[idxEss].limiteSuperieure) {
                                                    ess.conformiteEcart = true;
                                                    if (
                                                        (ref.mesures[idxEss].limiteInferieure && ess.ecart < ref.mesures[idxEss].limiteInferieure) ||
                                                        (ref.mesures[idxEss].limiteSuperieure && ess.ecart > ref.mesures[idxEss].limiteSuperieure)
                                                    ) {
                                                        ess.conformiteEcart = false;
                                                        this.model.formattedResult.lignes[i].conformiteTotVI = false;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                const modelTrie = this.$state.params.obj.resultat.prelevementBlock.groupBy((x) => x.idSpec);
                return modelTrie;
            } catch (error) {
                this.notification.error(error);
            }
        }
    }

    recuperationPrelevementParGammeProduit(model) {
        if (model) {
            // trie des prelevement par gamme de produit
            for (const key in model) {
                if (Object.hasOwnProperty.call(model, key)) {
                    const element = model[key];
                    // trie par date des prelevements
                    this.sortPrelev(element);

                    if (element && element.length > 0) {
                        for (let i = 0; i < element.length; i++) {
                            const prelev = element[i];
                            const idxRes = this.model.formattedResult.lignes.findIndex((x) => x.idPrelevement === prelev.idPrelevement);
                            if (idxRes > -1) {
                                prelev.lignes = angular.copy(this.model.formattedResult.lignes[idxRes]);
                                prelev.ecartee = false;
                            }
                        }
                    }
                }
            }
        }
    }

    async recuperationCalculStatParGamme(modelTrie) {
        // on parcours les gammes récupérer
        for (const spec in modelTrie) {
            //const gammeValues = [];
            const values = [];
            let prodTot = 0;
            for (let i = 0; i < modelTrie[spec].length; i++) {
                //prelevements
                const plv = modelTrie[spec][i];
                const valeursEssais = plv.lignes.valeursEssais; //essais
                values.push([]);

                for (let j = 0; j < valeursEssais.length; j++) {
                    //valeurs essais
                    const valEss = valeursEssais[j];
                    //recherche de la production
                    const idxProd = this.productions.findIndex(
                        (x) => x.idSiteProducteur == plv.idProducteur && x.date == plv.datePrelevement.split(' ')[0] && x.idProduit == plv.idProduit
                    );
                    if (idxProd > -1 && !this.productions[idxProd].dejaTraite) {
                        modelTrie[spec][i].production = this.productions[idxProd].valeur;
                        prodTot = prodTot + this.productions[idxProd].valeur;
                        modelTrie[spec][i].prodCumul = angular.copy(prodTot);
                        this.productions[idxProd].dejaTraite = true;
                    }
                    // pour chaque colonne par essais ( 4 colonnes fixes)
                    for (let k = 0; k < 4; k++) {
                        // VI, Xt, Ecart, Moy X
                        if (k == 0) {
                            values[values.length - 1].push({
                                CodeGamme: spec,
                                //idSousEssai: valEss.idSousEssai || valEss.idTamis || valEss.idDimRq,
                                idSousEssai: valEss.idSousEssai
                                    ? 'E' + valEss.idSousEssai
                                    : valEss.idTamis
                                    ? 'T' + valEss.idTamis
                                    : 'D' + valEss.dimRq,
                                valeur: valEss.valeur
                            });
                        }
                        if (k == 1) {
                            values[values.length - 1].push({
                                CodeGamme: spec,
                                idSousEssai: valEss.idSousEssai
                                    ? 'E' + valEss.idSousEssai
                                    : valEss.idTamis
                                    ? 'T' + valEss.idTamis
                                    : 'D' + valEss.dimRq,
                                valeur: valEss.valeurXt
                            });
                        }
                        if (k == 2) {
                            values[values.length - 1].push({
                                CodeGamme: spec,
                                idSousEssai: valEss.idSousEssai
                                    ? 'E' + valEss.idSousEssai
                                    : valEss.idTamis
                                    ? 'T' + valEss.idTamis
                                    : 'D' + valEss.dimRq,
                                idPrelevement: plv.idPrelevement,
                                valeur: valEss.ecart,
                                externalFormule: '@VAL',
                                targetFormules: ['@LI', '@LS']
                            });
                        }
                        if (k == 3) {
                            values[values.length - 1].push({
                                CodeGamme: spec,
                                idSousEssai: valEss.idSousEssai
                                    ? 'E' + valEss.idSousEssai
                                    : valEss.idTamis
                                    ? 'T' + valEss.idTamis
                                    : 'D' + valEss.dimRq,
                                valeur: valEss.moy,
                                externalFormule: '@MOYENR',
                                targetFormules: ['@LI', '@LS']
                            });
                        }
                    }
                }
            }

            // compte de toutes les colonnes sur une ligne
            const nbColumns = values[0].length;

            //  calculs stat du parametrage synthese
            for (let i2 = 0; i2 < this.model.calculs.length; i2++) {
                //calculs

                // parcours chaque colonne
                for (let j2 = 0; j2 < nbColumns; j2++) {
                    // recuperation des valeurs d'essai de la colonne
                    const currentColumnValues = values.map((e) => e[j2]);

                    // on profite d'avoir les valeurs par colonne pour calculer la moyenne des ecarts par rapport au NCE selectionné
                    if (currentColumnValues.length > this.model.synthese.nce.nbAnalyse /*3*/ && currentColumnValues[0].externalFormule === '@VAL') {
                        this.calculDeLaMoyenneDesEcarts(currentColumnValues, modelTrie[spec]);
                    }
                }
            }
        }
        /******************************** RELOAD APRES RECUPERATION DES MOYENNE DES ECARTS ******************************************** */
        await this.recalculDesStats(modelTrie);

        return modelTrie;
    }

    async recalculDesStats(modelTrie) {
        for (const spec in modelTrie) {
            const gammeValues = [];
            const values = [];

            for (let i = 0; i < modelTrie[spec].length; i++) {
                //prelevements
                const plv = modelTrie[spec][i];
                const valeursEssais = plv.lignes.valeursEssais; //essais
                values.push([]);

                for (let j = 0; j < valeursEssais.length; j++) {
                    //valeurs essais
                    const valEss = valeursEssais[j];

                    // pour chaque colonne par essais ( 4 colonnes fixes)
                    for (let k = 0; k < 4; k++) {
                        // VI, Xt, Ecart, Moy X
                        if (k == 0) {
                            values[values.length - 1].push({
                                CodeGamme: spec,
                                idSousEssai: valEss.idSousEssai
                                    ? 'E' + valEss.idSousEssai
                                    : valEss.idTamis
                                    ? 'T' + valEss.idTamis
                                    : 'D' + valEss.dimRq,
                                valeur: valEss.valeur
                            });
                        }
                        if (k == 1) {
                            values[values.length - 1].push({
                                CodeGamme: spec,
                                idSousEssai: valEss.idSousEssai
                                    ? 'E' + valEss.idSousEssai
                                    : valEss.idTamis
                                    ? 'T' + valEss.idTamis
                                    : 'D' + valEss.dimRq,
                                valeur: valEss.valeurXt
                            });
                        }
                        if (k == 2) {
                            values[values.length - 1].push({
                                CodeGamme: spec,
                                idSousEssai: valEss.idSousEssai
                                    ? 'E' + valEss.idSousEssai
                                    : valEss.idTamis
                                    ? 'T' + valEss.idTamis
                                    : 'D' + valEss.dimRq,
                                idPrelevement: plv.idPrelevement,
                                valeur: valEss.ecart,
                                externalFormule: '@VAL',
                                targetFormules: ['@LI', '@LS']
                            });
                        }
                        if (k == 3) {
                            values[values.length - 1].push({
                                CodeGamme: spec,
                                idSousEssai: valEss.idSousEssai
                                    ? 'E' + valEss.idSousEssai
                                    : valEss.idTamis
                                    ? 'T' + valEss.idTamis
                                    : 'D' + valEss.dimRq,
                                idPrelevement: plv.idPrelevement,
                                valeur: valEss.moy,
                                externalFormule: '@MOYENR',
                                targetFormules: ['@LI', '@LS'],
                                conformiteMoy: true
                            });
                        }
                    }
                }
            }

            // compte de toutes les colonnes sur une ligne
            const nbColumns = values[0].length;
            //  calculs stat du parametrage synthese
            for (let i = 0; i < this.model.calculs.length; i++) {
                //calculs
                const resCurrentGamme = [this.model.calculs[i].libelle];
                // la stat enrobe du calcul qu'on parcours
                const statEnrobee = this.model.formattedResult.stats.find((e) => e.formule === this.model.calculs[i].formule);

                // parcours chaque colonne
                for (let j = 0; j < nbColumns; j++) {
                    // recuperation des valeurs d'essai de la colonne
                    const currentColumnValues = values.map((e) => e[j]);
                    const valuesInterpreteur = [];
                    let valueForced = false;
                    let isValidValue = false;

                    if (currentColumnValues && currentColumnValues.length > 0) {
                        var firstCol = currentColumnValues[0];
                        let currentStat = null;
                        if (
                            firstCol.targetFormules &&
                            firstCol.targetFormules.indexOf(statEnrobee.formule) >= 0 &&
                            statEnrobee &&
                            this.bornesEssaisEnrobes &&
                            this.bornesEssaisEnrobes[firstCol.idSousEssai] &&
                            this.bornesEssaisEnrobes[firstCol.idSousEssai].gammes &&
                            this.bornesEssaisEnrobes[firstCol.idSousEssai].gammes[spec]
                        ) {
                            const currentStats = this.bornesEssaisEnrobes[firstCol.idSousEssai].gammes[spec].stats[statEnrobee.formule];
                            if (currentStats) {
                                // si concordance récuperation de la valeur dans les bornesEnrobés
                                currentStat = currentStats.find((e) => e.formuleCalcStat == firstCol.externalFormule);
                                if (currentStat) {
                                    valuesInterpreteur.push(currentStat.valeur);
                                }
                            }
                        }

                        // parcours de chaque valeur de la colonne
                        for (let k = 0; k < currentColumnValues.length; k++) {
                            const columnValue = currentColumnValues[k];
                            // verification de la concordance entre calculStat encours et les bornes enrobés ( fait pour Tol.Inf. Tol.Sup. spécifique enrobes)
                            if (currentStat) {
                                const idxPrlv = modelTrie[spec].findIndex((x) => x.idPrelevement === columnValue.idPrelevement);
                                let idxEssais = -1;
                                if (idxPrlv > -1) {
                                    if (modelTrie[spec][idxPrlv].lignes.conformite === false) {
                                        modelTrie[spec][idxPrlv].lignes.conformite = false;
                                    } else {
                                        modelTrie[spec][idxPrlv].lignes.conformite = true;
                                    }
                                    idxEssais = modelTrie[spec][idxPrlv].lignes.valeursEssais.findIndex((x) =>
                                        x.tamis
                                            ? x.idTamis
                                                ? 'T' + x.idTamis === columnValue.idSousEssai
                                                : 'D' + x.dimRq === columnValue.idSousEssai
                                            : 'E' + x.idSousEssai === columnValue.idSousEssai
                                    );
                                }
                                if (
                                    typeof columnValue.valeur === 'number' &&
                                    typeof currentStat.valeur === 'number' &&
                                    columnValue.externalFormule === '@MOYENR'
                                ) {
                                    if (
                                        (currentStat.formuleBorne === '@LI' && columnValue.valeur < currentStat.valeur) ||
                                        (currentStat.formuleBorne === '@LS' && columnValue.valeur > currentStat.valeur)
                                    ) {
                                        if (idxPrlv > -1 && idxEssais > -1) {
                                            modelTrie[spec][idxPrlv].lignes.valeursEssais[idxEssais].conformiteMoy = false;
                                            modelTrie[spec][idxPrlv].lignes.conformiteTotMoy = false;
                                        }
                                    } else {
                                        if (idxPrlv > -1 && idxEssais > -1) {
                                            if (modelTrie[spec][idxPrlv].lignes.conformiteTotMoy !== false) {
                                                modelTrie[spec][idxPrlv].lignes.conformiteTotMoy = true;
                                            }
                                        }
                                    }
                                }
                                valueForced = true;
                            }
                            // sinon récuperation de la valeur courante
                            if (!valueForced) {
                                valuesInterpreteur.push(columnValue.valeur);
                            }

                            if (!isValidValue && typeof valuesInterpreteur[valuesInterpreteur.length - 1] === 'number') {
                                isValidValue = true;
                            }
                        }
                    }
                    // sur une valeur courante on la push et on recalcule via l'interpreteur
                    if (valuesInterpreteur && valuesInterpreteur.length > 0) {
                        if (!valueForced) {
                            if (isValidValue) {
                                this.Interpreteur.calculatedFormules = [];
                                this.Interpreteur.listeValeursStr = valuesInterpreteur.join(';');
                                const resultat = await this.Interpreteur.calculeFormule(this.model.calculs[i].formule);
                                if (typeof resultat === 'number' && isValidValue) {
                                    resCurrentGamme.push(resultat);
                                } else {
                                    resCurrentGamme.push('');
                                }
                            } else {
                                resCurrentGamme.push('');
                            }
                        }
                        // si cas valeur enrobé on la prend
                        else {
                            resCurrentGamme.push(valuesInterpreteur[0] || '');
                        }
                    }
                }
                gammeValues.push(resCurrentGamme);
            }

            const newGamme = {};
            newGamme.prelevements = modelTrie[spec];
            newGamme.calculs = gammeValues;
            modelTrie[spec] = newGamme;
        }
        return modelTrie;
    }

    sortPrelev(element) {
        return element
            .sort((a, b) => {
                const db = this.moment(b.datePrelevement, this.dateFormat).unix();
                const da = this.moment(a.datePrelevement, this.dateFormat).unix();
                return da - db;
            })
            .map((p) => {
                p.dateFormat = p.datePrelevement.split(' ')[0];
                return p;
            });
    }

    calculdesEcartVIXT(valeurInd, valeurXt) {
        if (valeurInd && valeurXt) {
            return (Number.parseFloat(valeurInd) - Number.parseFloat(valeurXt)).toFixed(2);
        }
    }

    calculDeLaMoyenneDesEcarts(columnValue, modelTrieSpec) {
        if (columnValue && columnValue.length > this.model.synthese.nce.nbAnalyse) {
            const tabCalc = [];
            // on récupere les colonnes par essais ou on a besoin de calculer les moyennes
            for (let i = 0; i < columnValue.length; i++) {
                const valeur = columnValue[i].valeur;
                valeur = Number.parseFloat(valeur);
                // on enferme les valeurs dans un nouveau tableau
                if (typeof valeur === 'number' && !Number.isNaN(valeur)) {
                    tabCalc.push(valeur);
                }
                // si le tableau excede le nombre d'analyse du NCE, on enleve la premiere entree du tableau
                // car on vient d'en rajouter une auparavant a la fin pour faire des moyennes glissantes
                if (tabCalc.length > this.model.synthese.nce.nbAnalyse) {
                    tabCalc.splice(0, 1);
                }

                // on calcule uniquement la moyenne au moment ou le tableau a le nombre d'entrees suffisant (nce.nbdAnalyse === la longueur du tableau cree ci-dessus)
                if (tabCalc.length === this.model.synthese.nce.nbAnalyse) {
                    let calcMoy = null;
                    // on fait la somme de tte les valeurs du tableau
                    for (let j = 0; j < tabCalc.length; j++) {
                        const val = tabCalc[j];
                        calcMoy += Number.parseFloat(val);
                    }
                    // puis on calcule la moyenne
                    if (typeof calcMoy === 'number') {
                        const moyenne = (calcMoy / this.model.synthese.nce.nbAnalyse).toFixed(2);
                        if (moyenne) {
                            const idxPlv = modelTrieSpec.findIndex((x) => x.idPrelevement === columnValue[i].idPrelevement);
                            if (idxPlv > -1) {
                                const idxEssai = modelTrieSpec[idxPlv].lignes.valeursEssais.findIndex((x) =>
                                    x.tamis
                                        ? x.idTamis
                                            ? 'T' + x.idTamis === columnValue[i].idSousEssai
                                            : 'D' + x.dimRq === columnValue[i].idSousEssai
                                        : 'E' + x.idSousEssai === columnValue[i].idSousEssai
                                );

                                // on affecte la moyenne calculée a la gamme, au prelevement et l'essais courant (bien ranger dans le tableau d'affichage)
                                if (idxEssai > -1) {
                                    modelTrieSpec[idxPlv].lignes.valeursEssais[idxEssai].moy = Number.parseFloat(moyenne);
                                }
                            }
                        }
                    }
                }
            }
        }
        return modelTrieSpec;
    }

    calculDeLaCentrale(modelTrie) {
        for (const spec in modelTrie) {
            if (Object.hasOwnProperty.call(modelTrie, spec)) {
                const gammes = modelTrie[spec];
                let countConformite = 0;
                for (let i = 0; i < gammes.prelevements.length; i++) {
                    const plv = gammes.prelevements[i].lignes;
                    if (!plv.conformiteTotVI) {
                        countConformite += 1;
                    }
                    if (i >= this.model.synthese.nce.nbAnalyse - 1) {
                        for (let j = 0; j < this.model.synthese.nce.details.length; j++) {
                            const classe = this.model.synthese.nce.details[j];
                            if (countConformite >= classe.nbMini && countConformite <= classe.nbMaxi) {
                                plv.classeCentrale = classe.classement.value;
                            }
                            plv.conformiteCentrale = countConformite;
                            if (plv.conformiteTotMoy === false) {
                                //déclasser si la moyenne des écarts est non conforme
                                if (j + 1 < this.model.synthese.nce.details.length) {
                                    plv.classeCentrale = this.model.synthese.nce.details[j + 1].classement.value;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    rechercheSemaines(modelTrie) {
        for (const spec in modelTrie) {
            if (Object.hasOwnProperty.call(modelTrie, spec)) {
                const gammes = modelTrie[spec];
                var semaines = [];
                const dateMin = gammes.prelevements[0].dateFormat;
                var semaine = {
                    numSemaine: this.moment(dateMin, this.dateFormat).week(),
                    annee: this.moment(dateMin, this.dateFormat).year(),
                    classe: gammes.prelevements[0].lignes.classeCentrale
                };
                if (semaine.numSemaine == 53 && this.moment(dateMin, this.dateFormat).month() == 1) {
                    semaine.numSemaine = 1;
                }
                var debSem = this.moment(dateMin, this.dateFormat).startOf('isoweek');
                var finSem = this.moment(debSem, this.dateFormat).add(6, 'days');
                semaine.debSem = this.moment(debSem, this.dateFormat).format('L');
                semaine.finSem = this.moment(finSem, this.dateFormat).format('L');
                //recherche des calendriers pour la gamme en cours
                let calendriersGammeActu = [];
                const idxSpec = this.$state.params.obj.resultat.normeSpec.findIndex((x) => x.id == spec);
                if (idxSpec > 0) {
                    var specification = this.$state.params.obj.resultat.normeSpec[idxSpec];
                    const idxCald = this.calendriers.findIndex((x) =>
                        specification.grandD
                            ? x.siteNiveauFrequence.gamme.id == specification.idGamme && x.siteNiveauFrequence.conditionD == specification.grandD
                            : x.siteNiveauFrequence.gamme.id == specification.idGamme
                    );
                    if (idxCald > -1) {
                        calendriersGammeActu = this.calendriers[idxCald].frequencesNCE;
                    }
                }
                if (calendriersGammeActu && calendriersGammeActu.length > 0) {
                    semaine.niveauFrequence = calendriersGammeActu[0].niveauFrequenceEnrobes.niveau;
                    semaine.uniteProd = calendriersGammeActu[0].typeProduit.uniteProduction
                        ? calendriersGammeActu[0].typeProduit.uniteProduction.libelle
                        : '';
                }
                for (let i = 0; i < gammes.prelevements.length; i++) {
                    const plv = gammes.prelevements[i];
                    if (
                        this.moment(plv.dateFormat, this.dateFormat).week() != semaine.numSemaine ||
                        this.moment(plv.dateFormat, this.dateFormat).year() != semaine.annee
                    ) {
                        semaines.push(angular.copy(semaine));
                        semaine = {
                            numSemaine: this.moment(plv.dateFormat, this.dateFormat).week(),
                            annee: this.moment(plv.dateFormat, this.dateFormat).year(),
                            classe: plv.lignes.classeCentrale
                        };
                        if (semaine.numSemaine == 53 && this.moment(plv.dateFormat, this.dateFormat).month() == 1) {
                            semaine.numSemaine = 1;
                        }
                        var debSem = this.moment(plv.dateFormat, this.dateFormat).startOf('isoweek');
                        var finSem = this.moment(debSem, this.dateFormat).add(6, 'days');
                        semaine.debSem = this.moment(debSem, this.dateFormat).format('L');
                        semaine.finSem = this.moment(finSem, this.dateFormat).format('L');
                        if (calendriersGammeActu && calendriersGammeActu.length > 0) {
                            semaine.niveauFrequence = calendriersGammeActu[0].niveauFrequenceEnrobes.niveau;
                            semaine.uniteProd = calendriersGammeActu[0].typeProduit.uniteProduction
                                ? calendriersGammeActu[0].typeProduit.uniteProduction.libelle
                                : '';
                        }
                    } else {
                        //comparer la classe centrale
                        if (plv.lignes.classeCentrale && plv.lignes.classeCentrale != semaine.classe) {
                            if (!semaine.classe) {
                                semaine.classe = plv.lignes.classeCentrale;
                            } else {
                                const idxSem = this.model.synthese.nce.details.findIndex((x) => x.classement.value == semaine.classe);
                                const idxPlv = this.model.synthese.nce.details.findIndex((x) => x.classement.value == plv.lignes.classeCentrale);
                                if (idxPlv > idxSem) {
                                    semaine.classe = plv.lignes.classeCentrale;
                                }
                            }
                        }
                    }
                }
                semaines.push(angular.copy(semaine));
                for (let j = 0; j < semaines.length; j++) {
                    //classement à C tant que pas assez de valeurs
                    if (semaines[j].classe === undefined) {
                        semaines[j].classe = this.model.synthese.nce.details[this.model.synthese.nce.details.length - 1].classement.value;
                    }
                    if (j > 0) {
                        semaines[j].classePrec = semaines[j - 1].classe;
                    } else {
                        semaines[j].classePrec = this.model.synthese.nce.details[this.model.synthese.nce.details.length - 1].classement.value;
                    }
                    semaines[j].periodes = [];
                    if (calendriersGammeActu && calendriersGammeActu.length > 0) {
                        const idxPeriods = calendriersGammeActu.findIndex((x) => x.classementCentrale.value == semaines[j].classePrec.toString());
                        if (idxPeriods > -1) {
                            semaines[j].periodes = calendriersGammeActu[idxPeriods].frequencePeriods;
                        }
                    }
                }
                gammes.semaines = angular.copy(semaines);
            }
        }
    }

    formatChart(modelTrie) {
        const prelevements = this.filterPlvRemoved(modelTrie.prelevements);
        const res = [];
        for (let i = 0, l = this.defaultChartOption.length; i < l; i++) {
            const o = this.defaultChartOption[i];
            switch (o.idPrefix) {
                case 'evol':
                    res.push({
                        title: 'SYNTHENTS.GRAPH.EVOLUTION',
                        ...this.createChartOptions(o.type),
                        labels: prelevements.map((x, index) => index + 1),
                        series: [
                            this.$translate.instant('SYNTHENTS.GRAPH.NC'),
                            this.$translate.instant('SYNTHENTS.GRAPH.LIMITECENTRALE') +
                                this.model.synthese.nce.details[0].classement.value +
                                this.$translate.instant('SYNTHENTS.GRAPH.LIMITECENTRALENEXT') +
                                this.model.synthese.nce.details[1].classement.value,
                            this.$translate.instant('SYNTHENTS.GRAPH.LIMITECENTRALE') +
                                this.model.synthese.nce.details[1].classement.value +
                                this.$translate.instant('SYNTHENTS.GRAPH.LIMITECENTRALENEXT') +
                                this.model.synthese.nce.details[2].classement.value
                        ],
                        data: [
                            prelevements.map((x) => x.lignes.conformiteCentrale) || null,
                            prelevements.map((x) => this.model.synthese.nce.details[1].nbMini) || null,
                            prelevements.map((x) => this.model.synthese.nce.details[2].nbMini) || null
                        ],
                        ...o
                    });
                    break;
                case 'repar':
                    res.push({
                        title: 'SYNTHENTS.GRAPH.REPARTITION',
                        ...this.createChartOptions(o.type, false, 100),
                        ...this.pourcentageNonConformeParSsEssai(prelevements),
                        ...o
                    });
                    break;
                /*  case 'ecar':
                     res.push({
                         title: 'SYNTHENTS.GRAPH.ECART',
                         ...this.createChartOptions(o.type),
                         labels: prelevements.map((x, index) => index + 1),
                         series: ['test'],
                         data: [prelevements.map(x => x.lignes['conformiteCentrale'] || null)],
                         ...o
                     });
                     break; */
                default:
                    break;
            }
        }
        return res;
    }

    createChartOptions(ptype, legend = true, max = undefined) {
        return {
            type: ptype,
            datasetOverride: [{ showLine: false }],
            options: {
                legend: {
                    display: legend,
                    position: 'bottom'
                },
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        ticks: {
                            min: 0,
                            max: max,
                            step: 1
                        }
                    }
                }
            }
        };
    }

    pourcentageNonConformeParSsEssai(plv) {
        const fat = [];
        let model = null;
        const essais = plv.map((x) => x.lignes.valeursEssais);
        for (let i = 0; i < essais.length; i++) {
            const element = essais[i];
            if (i === 0) {
                model = element;
            }
            fat.push(...element);
        }
        const res = {
            series: [this.$translate.instant('SYNTHENTS.GRAPH.POURCENT')],
            labels: [],
            data: [[]]
        };
        for (let i = 0; i < model.length; i++) {
            const m = model[i];
            const all = fat.filter((x) => x.codeSousEssai === m.codeSousEssai);
            const length = all.length;
            res.labels.push(m.codeSousEssai);
            res.data[0].push((all.filter((x) => !x.conformiteEcart || !x.conformiteMoy).length * 100) / length);
        }
        return res;
    }

    async print() {
        this.modelAffichageFormatted = angular.copy(this.modelAffichage);
        this.printable = true;
        try {
            const tmp = {
                model: angular.copy(this.model),
                affichage: []
            };
            let indAff = 0;
            for (const spec in this.modelAffichageFormatted) {
                this.modelAffichageFormatted[spec].prelevements = this.filterPlvRemoved(this.modelAffichageFormatted[spec].prelevements);

                tmp.affichage.push(angular.copy(this.modelAffichageFormatted[spec]));
                //graph evol
                let el = document.getElementById(`evol_${indAff}_${0}`);
                if (el) {
                    tmp.affichage[tmp.affichage.length - 1].graph1 = angular.copy(el.toDataURL('image/png', 1.0));
                }
                //graph repar
                el = document.getElementById(`repar_${indAff}_${1}`);
                if (el) {
                    tmp.affichage[tmp.affichage.length - 1].graph2 = angular.copy(el.toDataURL('image/png', 1.0));
                }
                indAff++;
            }
            for (let k = 0; k < tmp.affichage.length; k++) {
                for (let j = 0; j < tmp.affichage[k].prelevements.length; j++) {
                    for (let i = 0; i < tmp.affichage[k].prelevements[j].lignes.valeursEssais.length; i++) {
                        tmp.affichage[k].prelevements[j].productionEtCumul = tmp.affichage[k].prelevements[j].production
                            ? tmp.affichage[k].prelevements[j].production + ' (' + tmp.affichage[k].prelevements[j].prodCumul + ')'
                            : '';

                        tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].titre = this.model.formattedResult.entete.titresSousEssais[i];
                        if (
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].valeur === null ||
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].valeur === undefined
                        ) {
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].valeur = '';
                        }
                        if (
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].valeurXt === null ||
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].valeurXt === undefined
                        ) {
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].valeurXt = '';
                        }
                        if (
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].ecart === null ||
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].ecart === undefined
                        ) {
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].ecart = '';
                        }
                        if (
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].moy === null ||
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].moy === undefined
                        ) {
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].moy = '';
                        }
                        const txtComplet =
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].valeur +
                            ' | ' +
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].valeurXt +
                            ' | ' +
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].ecart +
                            ' | ' +
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].moy;
                        tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].valeurs =
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].conformiteEcart === false ||
                            tmp.affichage[k].prelevements[j].lignes.valeursEssais[i].conformiteMoy === false
                                ? { value: txtComplet, text: 'FF0000' }
                                : { value: txtComplet, text: '000000' };
                    }
                    tmp.affichage[k].prelevements[j].valeursEssais = angular.copy(tmp.affichage[k].prelevements[j].lignes.valeursEssais);
                    tmp.affichage[k].prelevements[j].date = tmp.affichage[k].prelevements[j].datePrelevement.split(' ')[0];
                    tmp.affichage[k].prelevements[j].conformiteVI =
                        tmp.affichage[k].prelevements[j].lignes.conformiteTotVI === true
                            ? { value: this.$translate.instant('OUI'), text: '00CC00' }
                            : tmp.affichage[k].prelevements[j].lignes.conformiteTotVI === false
                            ? { value: this.$translate.instant('NON'), text: 'FF0000' }
                            : { value: '', text: '000000' };
                    tmp.affichage[k].prelevements[j].conformiteMoy =
                        tmp.affichage[k].prelevements[j].lignes.conformiteTotMoy === true
                            ? { value: this.$translate.instant('OUI'), text: '00CC00' }
                            : tmp.affichage[k].prelevements[j].lignes.conformiteTotMoy === false
                            ? { value: this.$translate.instant('NON'), text: 'FF0000' }
                            : { value: '', text: '000000' };
                    tmp.affichage[k].prelevements[j].classeNCE = tmp.affichage[k].prelevements[j].lignes.classeCentrale
                        ? tmp.affichage[k].prelevements[j].lignes.classeCentrale +
                          ' [' +
                          tmp.affichage[k].prelevements[j].lignes.conformiteCentrale +
                          ']'
                        : '';
                }
                //périodicités
                for (let l = 0; l < tmp.affichage[k].semaines.length; l++) {
                    tmp.affichage[k].semaines[l].periodesEssais = [];
                    for (let m = 0; m < tmp.affichage[k].prelevements[0].lignes.valeursEssais.length; m++) {
                        const idxPeriod = tmp.affichage[k].semaines[l].periodes.findIndex(
                            (x) => x.essai.libelle == tmp.affichage[k].prelevements[0].lignes.valeursEssais[m].titre.libelleEssai
                        );
                        if (idxPeriod > -1) {
                            tmp.affichage[k].semaines[l].periodesEssais.push({
                                essai: tmp.affichage[k].prelevements[0].lignes.valeursEssais[m].titre.titre,
                                number: tmp.affichage[k].semaines[l].periodes[idxPeriod].number
                            });
                        }
                    }
                    tmp.affichage[k].semaines[l].semaine = tmp.affichage[k].semaines[l].debSem + ' - ' + tmp.affichage[k].semaines[l].finSem;
                }
            }
            const res = await this.TemplateImpressionService.downloadModal('ctrlprodenr');
            if (res === 'cancel') {
                throw res;
            }
            this.notification.info(this.$translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
                autoDismiss: false,
                progressBar: true
            });
            const template = await this.SyntheseEntitiesService.printCtrlEnr(tmp, res.filename);
            template.pdf = res.pdf;
            await this.TemplateImpressionService.downloadTemplate(template.filename, template, 'ctrlprodenr');
        } catch (err) {
            console.log(err);
            this.printable = false;
            if (err !== 'cancel') {
                this.notification.error(err.data);
            }
        }
    }

    formatGraphs() {
        this.graphs = [];
        this._.forEach(this.modelAffichage, (graph) => {
            this.graphs.push(this.formatChart(graph));
        });
    }

    updateGraphs() {
        try {
            this.formatGraphs();
        } catch (error) {
            console.log(
                '🚀 ~ file: syntheseentities.ctrlenrobes.controller.js ~ line 1106 ~ SyntheseEntityCtrlEnrobesController ~ updateGraphs ~ error',
                error
            );
        }
    }

    filterPlvRemoved(listPlv) {
        listPlv = this._.filter(listPlv, { ecartee: false });
        return listPlv;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
