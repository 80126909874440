import VehiculeEnteteController from './vehicule.entete.controller';

export default {
    bindings: {
        vehicule: '=',
        isOpen: '='
    },
    template: require('./vehicule.entete.html'),
    controller: VehiculeEnteteController
};
