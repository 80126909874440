import { Injectable } from '../../../core/decorators';

interface ThreadParams {
    fn: Function;
    cb?: Function;
    args: any[];
}

@Injectable('WebWorkerService')
export class WebWorkerService {
    worker: any = {};
    workerJs =
        '(function(){var JSONfn={parse:function(str,date2obj){var iso8061=date2obj?/^(d{4})-(d{2})-(d{2})T(d{2}):(d{2}):(d{2}(?:.d*)?)Z$/:false;return JSON.parse(str,function(key,value){var prefix,func,fnArgs,fnBody;if(typeof value!=="string")return value;if(value.length<8)return value;prefix=value.substring(0,8);if(iso8061&&value.match(iso8061))return new Date(value);if(prefix==="function")return eval("("+value+")");if(prefix==="_PxEgEr_")return eval(value.slice(8));if(prefix==="_NuFrRa_"){func=value.slice(8).trim().split("=>");fnArgs=func[0].trim();fnBody=func[1].trim();if(fnArgs.indexOf("(")<0)fnArgs="("+fnArgs+")";if(fnBody.indexOf("{")<0)fnBody="{ return "+fnBody+"}";return eval("("+"function"+fnArgs+fnBody+")")}return value})}};onmessage=function(e){var obj=JSONfn.parse(e.data,true),cntx=obj.context||self;if(obj.importFiles)importScripts.apply(null,obj.importFiles);if(typeof obj.fn==="function")if(typeof Promise!=="undefined")Promise.resolve(obj.fn.apply(cntx,obj.args)).then(function(data){postMessage(data)})["catch"](function(reason){postMessage(reason)});else postMessage(obj.fn.apply(cntx,obj.args));else postMessage(self[obj.fn].apply(cntx,obj.args))};function vkhttp(cfg){var body=cfg.body?JSON.stringify(cfg.body):null,contentType=cfg.contentType||"application/json",method=cfg.method?cfg.method.toUpperCase():"GET",xhr=new XMLHttpRequest,ret;xhr.onload=function(){if(xhr.status>=200&&xhr.status<300)ret=xhr.responseText;else ret="Error: "+xhr.status+xhr.statusText};xhr.onerror=function(data){ret=xhr.status+xhr.statusText};xhr.open(method,cfg.url,false);if(method==="POST")xhr.setRequestHeader("Content-Type",contentType);xhr.send(body);return ret}})();';
    workerBlob = new Blob([this.workerJs], { type: 'application/javascript' });

    /* @ngInject */
    constructor() {}

    thread(param: ThreadParams): Promise<any> {
        var worker = new Worker(window.URL.createObjectURL(this.workerBlob)),
            promise;
        if (param.cb && typeof param.cb === 'function') {
            worker.onmessage = function (oEvent: any) {
                param.cb(oEvent.data);
                worker.terminate();
            };

            worker.onerror = function (error: any) {
                param.cb(null, error.message);
                worker.terminate();
            };

            worker.postMessage(this.JSONfn.stringify(param));
        } else {
            if (typeof Promise !== 'undefined') {
                promise = new Promise(function (resolve, reject) {
                    worker.onmessage = function (oEvent: any) {
                        console.log(oEvent);
                        resolve(oEvent.data);
                        worker.terminate();
                    };

                    worker.onerror = function (error: any) {
                        reject(error.message);
                        worker.terminate();
                    };
                });
                worker.postMessage(this.JSONfn.stringify(param));
                return promise;
            }
        }
    }

    async threadAll(...args: ThreadParams[]) {
        var promises = [];

        for (var ix = 0; ix < args.length; ix++) {
            promises.push(this.thread(args[ix]));
        }

        const values = await Promise.all(promises);
        return values;
    }

    private JSONfn = {
        stringify: function (obj: ThreadParams) {
            return JSON.stringify(obj, function (key, value) {
                var fnBody;
                if (value instanceof Function || typeof value === 'function') {
                    fnBody = value.toString();

                    if (fnBody.length < 8 || fnBody.substring(0, 8) !== 'function') {
                        //this is ES6 Arrow Function
                        return '_NuFrRa_' + fnBody;
                    }
                    return fnBody;
                }
                if (value instanceof RegExp) {
                    return '_PxEgEr_' + value;
                }
                return value;
            });
        },
    };
}
