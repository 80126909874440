import DemandeLivraison from './demande.livraison.model';

export default class DemandeLivraisonFormController {
    static $inject = [
        '_',
        '$scope',
        '$state',
        '$stateParams',
        '$translate',
        'notification',
        'DemandesLivraisonsService',
        'moment',
        '$filter',
        'ModalService',
        'globalizationManagementService',
        '$uibModal'
    ];

    constructor(
        _,
        $scope,
        $state,
        $stateParams,
        $translate,
        notification,
        DemandesLivraisonsService,
        moment,
        $filter,
        ModalService,
        globalizationManagementService,
        $uibModal
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$translate = $translate;
        this.$stateParams = $stateParams;
        this.notification = notification;
        this.DemandesLivraisonsService = DemandesLivraisonsService;
        this._ = _;
        this.moment = moment;
        this.$filter = $filter;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;

        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    async $onInit() {
        this.demandeLivraison = {};
        this.isEditMode = false;
        this.loading = false;
        this.form = {};

        await this.reset();

        this.societes = await this.DemandesLivraisonsService.getSocietes();
        this.sites = await this.DemandesLivraisonsService.getSitesCommerciaux(this.demandeLivraison.societeId);
        this.clients = await this.DemandesLivraisonsService.getClients();
        this.zones = await this.DemandesLivraisonsService.getZones();
        if (this.demandeLivraison.clientId) {
            this.chantiers = await this.DemandesLivraisonsService.getChantiers(this.demandeLivraison.clientId);
        }
        if (this.$stateParams.id) {
            this.setDemandePrestationAsTypeAhead();
        }

        if (this.demandeLivraison.societeId && this.demandeLivraison.siteDepartId) {
            await this.getProduits();
        }
        if (this.isEditMode) {
            this.preselectFiltreProduits();
        }
    }

    watcher() {
        const _this = this;
        this.$scope.$watch('$ctrl.demandeLivraison.achatVente', function (newValue, oldValue) {
            if (oldValue != newValue) {
                _this.changeChantier();
            }
        });
        this.$scope.$watch('$ctrl.demandeLivraison.sens', function (newValue, oldValue) {
            if (oldValue != newValue) {
                _this.changeChantier();
            }
        });
        this.$scope.$watch('$ctrl.demandeLivraison.dateDemande', function (newValue, oldValue) {
            if (oldValue != newValue) {
                _this.changeChantier();
            }
        });
    }

    async reset() {
        this.startLoading();

        let data = {
            id: this.$stateParams.id
        };

        if (data.id) {
            this.isEditMode = true;
            try {
                data = await this.DemandesLivraisonsService.getDemandeLivraisonById(data.id);
            } catch (ex) {
                this.notification.error(ex.data);
                this.annuler();
            }
        } else {
            this.isEditMode = false;
        }

        this.demandeLivraison = new DemandeLivraison(data);
        if (this.isEditMode) {
            this.heureDebutChantier = this.demandeLivraison.heureDebutChantier;
            this.heureDebutLivraison = this.demandeLivraison.heureDebutLivraison;
            this.heureFinLivraison = this.demandeLivraison.heureFinLivraison;
        } else {
            this.demandeLivraison.priorite = 0;
            this.demandeLivraison.achatVente = true;
            this.demandeLivraison.sens = 1;
        }

        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.watcher();

        this.stopLoading();
    }

    async getLieux(valeur) {
        const lieux = await this.DemandesLivraisonsService.getLieux(valeur);
        return lieux;
    }

    async getContacts(valeur) {
        const contacts = await this.DemandesLivraisonsService.getContacts(valeur);
        return contacts;
    }

    async getLieux(valeur) {
        const lieux = await this.DemandesLivraisonsService.getLieux(valeur);
        return lieux;
    }

    async getCommandes(valeur) {
        const commandes = await this.DemandesLivraisonsService.getCommandes(valeur, this.demandeLivraison.clientId, this.demandeLivraison.chantierId);
        return commandes;
    }

    async getProduits() {
        const filtre = {
            societeId: this.demandeLivraison.societeId ? this.demandeLivraison.societeId : null,
            clientId: this.demandeLivraison.clientId ? this.demandeLivraison.clientId : null,
            entreeSortie: this.demandeLivraison.sens,
            achatVente: this.demandeLivraison.achatVente == true ? 1 : 0,
            date: this.demandeLivraison.dateDemande ? this.moment(this.demandeLivraison.dateDemande, this.dateFormat).format(this.dateFormat) : null,
            siteCommercialId: this.demandeLivraison.siteDepartId ? this.demandeLivraison.siteDepartId : null,
            chantierId: this.demandeLivraison.chantierId ? this.demandeLivraison.chantierId : null
        };
        this.produitsSelectionnables = await this.DemandesLivraisonsService.getProduitsSelectionnables(filtre);
        this.produitsLies = await this.prepareListeElementsLies(this.produitsSelectionnables);
    }

    prepareListeElementsLies(elementsATraiter) {
        const elementsSelectionnablesOrdonnes = this.$filter('orderBy')(elementsATraiter, 'producteurLibelle');
        let tmp = null;
        const temp = [];
        for (let index = 0; index < elementsSelectionnablesOrdonnes.length; index++) {
            const currentValue = elementsSelectionnablesOrdonnes[index];
            const toReturn = [];
            if (currentValue.hasOwnProperty('prestationId')) {
                currentValue.libelle = currentValue.prestationLibelle;
                currentValue.valeur = false;
                currentValue.id = currentValue.prestationId;
                toReturn.push(currentValue);
            } else if (currentValue.hasOwnProperty('transportId')) {
                currentValue.libelle = currentValue.transportLibelle;
                currentValue.valeur = false;
                currentValue.id = currentValue.transportId;
                toReturn.push(currentValue);
            } else {
                //#region Gestion des groupes
                if (tmp == null) {
                    //creation du premier groupe
                    tmp = currentValue.producteurId;
                    toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
                } else {
                    if (tmp != currentValue.producteurId) {
                        //création des autres groupes
                        toReturn.push({ msGroup: false });
                        tmp = currentValue.producteurId;
                        toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
                    }
                }
                //#endregion
                currentValue.libelle = currentValue.produitCode + ' - ' + currentValue.produitLibelle;
                currentValue.valeur = false;
                currentValue.id = currentValue.id;
                toReturn.push(currentValue);

                if (index == elementsSelectionnablesOrdonnes.length - 1) {
                    toReturn.push({ msGroup: false });
                }
            }
            temp.push(toReturn);
        }
        const sorties = _.flatten(temp);
        return new Promise((resolve) => resolve(sorties));
    }

    async preselectFiltreProduits() {
        if (this.demandeLivraison.siteProduitId) {
            const filtre = this._.find(this.produitsLies, { id: this.demandeLivraison.siteProduitId });
            filtre.valeur = true;
        }
    }

    change() {
        this.demandeLivraison.siteProduitId = this.produitReplace[0].id;
    }

    setDemandePrestationAsTypeAhead() {
        if (this.demandeLivraison) {
            this.lieu = {
                id: this.demandeLivraison.lieuId,
                code: this.demandeLivraison.lieuCode,
                libelle: this.demandeLivraison.lieu,
                codeEtLib:
                    this.demandeLivraison && this.demandeLivraison.lieuId && this.demandeLivraison.lieuId != -1
                        ? this.demandeLivraison.lieu + ' [' + this.demandeLivraison.lieuCode + ']'
                        : ''
            };

            this.siteClient = {
                id: this.demandeLivraison.clientId,
                libelle: this.demandeLivraison.client
            };

            if (this.demandeLivraison.commandeId) {
                this.commande = {
                    id: this.demandeLivraison.commandeId,
                    codeLibelle: this.demandeLivraison.commande
                };
            }

            this.contact = {
                id: this.demandeLivraison.contactId,
                libelle: this.demandeLivraison.contact
            };
        }
    }

    async setTypeAheadAs(val) {
        if (!this.demandeLivraison) {
            this.demandeLivraison = {};
        }
        switch (val) {
            case 'lieu':
                if (this.lieu) {
                    this.demandeLivraison.lieuId = this.lieu.id;
                    this.demandeLivraison.lieu = this.lieu.libelle;
                    this.demandeLivraison.lieuCode = this.lieu.code;
                }
                break;
            case 'commande':
                this.demandeLivraison.commandeId = this.commande.id;
                this.demandeLivraison.commande = this.commande.codeLibelle;
                break;
            case 'client':
                this.demandeLivraison.clientId = this.siteClient.id;
                this.demandeLivraison.client = this.siteClient.libelle;
                this.chantiers = await this.DemandesLivraisonsService.getChantiers(this.siteClient.id);
                this.getProduits();
                break;
            case 'contact':
                this.demandeLivraison.contactId = this.contact.id;
                this.demandeLivraison.contact = this.contact.libelle;
                break;
        }
    }

    videLieu() {
        this.lieu = {
            id: undefined,
            code: undefined,
            libelle: undefined,
            codeEtLib: ''
        };
        this.demandeLivraison.lieuId = undefined;
        this.demandeLivraison.lieu = undefined;
        this.demandeLivraison.lieuCode = undefined;
    }

    videCommande() {
        this.commande = undefined;
        this.demandeLivraison.commandeId = undefined;
        this.demandeLivraison.commande = undefined;
    }

    videContact() {
        this.contact = undefined;
        this.demandeLivraison.contactId = undefined;
        this.demandeLivraison.contact = undefined;
    }

    async changeSociete() {
        this.sites = await this.DemandesLivraisonsService.getSitesCommerciaux(this.demandeLivraison.societeId);
        await this.getProduits();
    }

    async changeSite() {
        //this.chantiers = await this.DemandesLivraisonsService.getChantiers(this.demandeLivraison.clientId);
        await this.getProduits();
    }

    async changeChantier() {
        await this.getProduits();
    }

    initHeureDebutChantier(time) {
        this.demandeLivraison.heureDebutChantier = time;
    }

    initHeureDebutLivraison(time) {
        this.demandeLivraison.heureDebutLivraison = time;
    }

    initHeureFinLivraison(time) {
        this.demandeLivraison.heureFinLivraison = time;
    }

    async validerEtFermer() {
        if (this.checkValidity()) {
            await this.enregistrer();
            this.backToPreviousState();
        }
    }

    async valider() {
        if (this.checkValidity()) {
            const id = await this.enregistrer();

            if (id != null) {
                this.$state.go('demandes-livraisons.edit', { id: id });
            }
        }
    }

    async enregistrer() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                let id = false;
                if (this.demandeLivraison.id) {
                    await this.DemandesLivraisonsService.updateDemandeLivraison(this.demandeLivraison);
                    this.notification.success('DEMANDES_LIVRAISONS.UPDATED');
                    id = this.demandeLivraison.id;
                } else {
                    id = await this.DemandesLivraisonsService.createDemandeLivraison(this.demandeLivraison);
                    this.notification.success('DEMANDES_LIVRAISONS.CREATED');
                }

                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    backToPreviousState() {
        this.$state.go('demandes-livraisons.list');
    }

    checkValidity() {
        let validity = true;

        if (!this.demandeLivraison.isValid()) {
            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.$scope.$broadcast('demandeLivraisonValidations');
        }

        return validity;
    }

    async annuler() {
        this.backToPreviousState();
    }

    activeEditMode(tour) {
        tour.isEditMode = true;
    }

    desactiveEditMode(tour) {
        tour.isEditMode = false;
    }

    async updateTour(tour) {
        const zoneSelected = this.zones.find((x) => x.id === tour.idZone);
        tour.zone = zoneSelected.libelle;

        if (tour && tour.id && tour.prixAchat) {
            const vm = this;
            const modalInstance = this.ModalService.confirm({
                modalTitle: this.$translate.instant('DEMANDES_LIVRAISONS.UPDATE.TITLE'),
                modalMsg: this.$translate.instant('DEMANDES_LIVRAISONS.UPDATE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                vm.startLoading();
                try {
                    await vm.DemandesLivraisonsService.updateTour(tour);
                    vm.notification.success(vm.$translate.instant('DEMANDES_LIVRAISONS.UPDATE.SUCCESS'));
                    vm.desactiveEditMode(tour);
                } catch (ex) {
                    vm.notification.error(ex.data);
                } finally {
                    vm.stopLoading();
                }
            });
        }
    }

    openTicket(ticket) {
        if (ticket) {
            this.$uibModal
                .open({
                    template: '<ticket-detail modal-instance="$ctrl.uibModalInstance" ticket-from-pre-fa="$ctrl.ticketFromPreFa"></ticket-detail>',
                    controller: [
                        '$uibModalInstance',
                        function ($uibModalInstance) {
                            const $ctrl = this;
                            $ctrl.uibModalInstance = $uibModalInstance;
                            $ctrl.ticketFromPreFa = ticket;
                        }
                    ],
                    controllerAs: '$ctrl',
                    size: 'xxl'
                })
                .result.then(
                    async function (result) {
                        // console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
                        // console.info(result);
                        if (result) {
                        }
                    },
                    function (reason) {}
                );
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
