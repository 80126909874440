import { MassiaMultiSelect } from './massia.multi.select.controller';
import { NgModule } from '../../../core/decorators';
import { MassiaMultiSelectModalController } from './massia.multi.select.modal.controller';

const moduleName = 'app.massia.massia.component.multi.select';

@NgModule({
    id: moduleName,
    declarations: [MassiaMultiSelectModalController, MassiaMultiSelect]
})
export class MassiaMultiSelectModule {}
