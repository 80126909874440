const serviceUrl = `${__configuration.apiUrl}/massia/import3r-config`;

export default class Import3rService {
    /* @ngInject */
    constructor($http) {
        this.$http = $http;
    }

    async getParams3rConfig(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || { skip: 0, take: 0 })
            }
        });
        return res.data;
    }

    async getParams3rConfigList() {
        const res = await this.$http.get(serviceUrl + '/header');
        return res.data;
    }

    async getParams3rConfigById(idConfig) {
        const res = await this.$http.get(`${serviceUrl}/${idConfig}`);
        return res.data;
    }

    async createParam3rConfig(config) {
        this.deleteEmptyParamRow(config);
        return await this.$http.post(serviceUrl, config);
    }

    async updateParam3rConfig(config) {
        this.deleteEmptyParamRow(config);
        return await this.$http.put(`${serviceUrl}/${config.id}`, config);
    }

    async deleteParam3rConfig(idConfig) {
        const res = await this.$http.delete(`${serviceUrl}/${idConfig}`);
        return res.data;
    }

    deleteEmptyParamRow({ essaisList, caractList }) {
        if (essaisList.length > 0) {
            const { libelle, essai, sousEssai } = essaisList[essaisList.length - 1];
            if (!libelle && !essai && !sousEssai) {
                essaisList.splice(-1);
            }
        }
        if (caractList.length > 0) {
            const { libelle, caracteristique } = caractList[caractList.length - 1];
            if (!libelle && !caracteristique) {
                caractList.splice(-1);
            }
        }
        return { essaisList, caractList };
    }

    //il ne peut y avoir qu'une config active par Laboratoire/Machine
    async changeActiveConfigFromList(config) {
        const res = await this.$http.post(`${serviceUrl}/active/${config.id}`, config);
        return res.data;
    }
}
