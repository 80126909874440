TypeBennesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'TypeBennesService',
	'notification',
	'$transitions',
	'MOPService'
];

export default function TypeBennesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	TypeBennesService,
	notification,
	$transitions,
	MOPService
) {
	const vm = this;

	let previousTableState;
	let selectedTypeBenneId = undefined;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.typeBennes = [];
	vm.isEnabled = true;
	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectTypeBenne = selectTypeBenne;
	vm.isTypeBenneSelected = isTypeBenneSelected;
	vm.closeDetail = closeDetail;
	vm.loadTypeBennes = loadTypeBennes;
	vm.deleteTypeBenne = deleteTypeBenne;
	vm.activateTypeBenne = activateTypeBenne;
	vm.MOPService = MOPService;

	function init() {
		vm.MOPService.setMop([{ title: 'TYPEBENNES.BREADCRUMBS.TYPEBENNES_LIST', filename: 'Vehicules.pdf', application: 'gestion' }]);
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));

		if (shouldSetSelectedId()) {
			selectedTypeBenneId = $stateParams.id;
		}
	}

	init();

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function stateChangeSuccessFunc(event, toState) {
		if (!$stateParams.id) {
			selectedTypeBenneId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return (
			$state && $state.current && $state.current.name && $state.current.name === 'type-bennes.list.detail' && $stateParams && $stateParams.id
		);
	}

	function selectTypeBenne(typeBenne) {
		if (typeBenne && typeBenne.id) {
			if (selectedTypeBenneId !== typeBenne.id) {
				showDetail(typeBenne.id);
			} else {
				//si on click sur la ligne qui est déjà sélectionnée, on ferme la vue détail
				closeDetail();
			}
		}
	}

	function isTypeBenneSelected(typeBenne) {
		return typeBenne && typeBenne.id && selectedTypeBenneId === typeBenne.id;
	}

	function showDetail(typeBenneId) {
		/*let modalInstance = ModalService.info({
            modalTitle: $translate.instant('TYPEBENNES.DETAIL.MODAL_HEADER', {libelle: libelle}),
            modalMsg: this.getTooltipDroits(droits),
            headerClass: 'modal-info'
        });*/

		vm.slideMargin['margin-right'] = vm.slideWidth;
		selectedTypeBenneId = typeBenneId;
		$state.go('type-bennes.list.detail', { id: typeBenneId });
	}

	function closeDetail() {
		selectedTypeBenneId = undefined;
		$state.go('type-bennes.list');
	}

	async function loadTypeBennes(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'type-bennes.list') {
			$state.go('type-bennes.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.bennes = [];

		try {
			const data = await TypeBennesService.getTypeBennes(vm.isEnabled, filters, sorts, pagination);

			vm.typeBennes = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteTypeBenne(typeBenne) {
		if (typeBenne && typeBenne.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('TYPEBENNES.DELETE.TITLE', { code: typeBenne.libelle }),
				modalMsg: $translate.instant('TYPEBENNES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await TypeBennesService.deleteTypeBenneById(typeBenne.id);
					notification.success($translate.instant('TYPEBENNES.DELETE.SUCCESS'));
					selectedTypeBenneId = undefined;
					if (vm.params.id) {
						vm.state.go('type-bennes.list');
					}
					loadTypeBennes();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function activateTypeBenne(idTypeBenne) {
		await TypeBennesService.activateTypeBenne(idTypeBenne);
		loadTypeBennes();
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
