import OffreResultatValidationController from './offre.resultat.validation.controller';

export default {
    bindings: {
        modalInstance: '=',
        source: '='
    },
    template: require('./offre.resultat.validation.html'),
    controller: OffreResultatValidationController
};
