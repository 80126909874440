TypeBasculeValidator.$inject = ['validator'];

export default function TypeBasculeValidator(validator) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').lengthBetweenValues(0, 15).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance
        .ruleFor('code')
        .matches(/^[a-zA-Z0-9_]*$/)
        .withMessage('TYPEBASCULES.CODE_CHARACTERS_NOK');

    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').lengthBetweenValues(0, 50).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    instance.ruleFor('transmissionVitesse').notEmpty().when(isCommunicationSerie).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('transmissionBitStart').notEmpty().when(isCommunicationSerie).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('transmissionBitStop').notEmpty().when(isCommunicationSerie).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('transmissionBitDate').notEmpty().when(isCommunicationSerie).withMessage('VALIDATION_NOTEMPTY');

    return instance;

    function isCommunicationSerie(obj, prop) {
        return obj.isSerieIp == 0;
    }
}
