import { ModeSeuil, ValidationDevisNiveaux, ValidationDevisProduits } from './ValidationDevis';

export const isValidationProduitRow = (newValProd: ValidationDevisProduits) => {
	let produitValide: boolean = true;
	// Produit Pas Valide si pas de tpeProduit
	if (newValProd.typeProduit && newValProd.typeProduit.length <= 0) {
		produitValide = false;
	}
	// Produit Pas Valide si pas de seuil
	if (!newValProd.modeSeuil || newValProd.modeSeuil === 'undefined') {
		produitValide = false;
	}
	if (typeof newValProd.valeurMin !== 'number' && newValProd.valeurMin < 0) {
		produitValide = false;
	}
	// Produit Pas Valide si valeurs basses > valeurs hautes ou pas de valeur
	if (!isValidSeuilEntre(newValProd.modeSeuil, newValProd.valeurMin, newValProd.valeurMax)) {
		produitValide = false;
	}
	// Produit Pas Valide si pas de valeur basse
	if (!isValidSeuilSup(newValProd.modeSeuil, newValProd.valeurMin)) {
		produitValide = false;
	}
	// Produit Pas Valide si valeurs basses et valeurs hautes sup à 100
	if (!isLessThanOrEqualHundred(newValProd.valeurMin, newValProd.valeurMax)) {
		produitValide = false;
	}

	return produitValide;
};

export const isSeuilVolume = (nv: ValidationDevisNiveaux, volumeValide: boolean) => {
	if (!nv.modeSeuilVolume || nv.modeSeuilVolume === 'undefined') {
		volumeValide = false;
	}
	if (typeof nv.valeurMinVolume !== 'number' || nv.valeurMinVolume < 0) {
		volumeValide = false;
	}
	// Volume pas Valide si valeurs basses > valeurs hautes
	if (!isValidSeuilEntre(nv.modeSeuilVolume, nv.valeurMinVolume, nv.valeurMaxVolume)) {
		volumeValide = false;
	}
	if (!isValidSeuilSup(nv.modeSeuilVolume, nv.valeurMinVolume)) {
		volumeValide = false;
	}
	if (!nv.unite) {
		volumeValide = false;
	}
	return volumeValide;
};

export const isSeuilMontant = (nv: ValidationDevisNiveaux, montantValide: boolean) => {
	if (!nv.modeSeuilMontant || nv.modeSeuilMontant === 'undefined') {
		montantValide = false;
	}
	if (typeof nv.valeurMinMontant !== 'number' || nv.valeurMinMontant < 0) {
		montantValide = false;
	}
	// Montant pas Valide si valeurs basses > valeurs hautes
	if (!isValidSeuilEntre(nv.modeSeuilMontant, nv.valeurMinMontant, nv.valeurMaxMontant)) {
		montantValide = false;
	}
	if (!isValidSeuilSup(nv.modeSeuilMontant, nv.valeurMinMontant)) {
		montantValide = false;
	}
	return montantValide;
};

const isValidSeuilEntre = (modeSeuil: string, valeurMin: number, valeurMax: number): boolean => {
	if (modeSeuil === ModeSeuil.ENTRE) {
		return typeof valeurMin === 'number' && typeof valeurMax === 'number' && valeurMin >= 0 && valeurMax > 0 && valeurMin < valeurMax;
	}
	return true;
};

const isValidSeuilSup = (modeSeuil: any, valeurMin: number): boolean => {
	if (modeSeuil === ModeSeuil.SUPERIEUR_A) {
		return typeof valeurMin === 'number' && valeurMin >= 0;
	}
	return true;
};

const isLessThanOrEqualHundred = (valeurMin: number, valeurMax: number): boolean => {
	if (valeurMin > 100 || valeurMax > 100) {
		return false;
	}
	return true;
};
