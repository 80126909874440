import ClientDiversFormController from './client.divers.form.controller';

export default {
    bindings: {
        modalInstance: '<',
        prospect: '<',
        fromOffreCommande: '<'
    },
    template: require('./client.divers.form.html'),
    controller: ClientDiversFormController
};
