UniteValidator.$inject = ['validator', 'validationHelper'];

export default function UniteValidator(validator, validationHelper) {
	const instance = new validator();
	instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('libelle').lengthBetweenValues(0, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
	instance.ruleFor('modeFactureEtPeseeValue').notEmpty().withMessage('VALIDATION_NOTEMPTY');
	instance.ruleFor('modeFactureEtPeseeValue').greaterThan(0).withMessage('VALIDATION_NOTEMPTY');

	//instance.ruleFor('arrondi').must(beInteger||isNull).withMessage('VALIDATION_NOT_INTEGER');
	//instance.ruleFor('arrondi').notEmpty().withMessage('VALIDATION_NOTEMPTY');//SP supprimé sinon on ne peut pas mettre 0
	instance.ruleFor('arrondi').must(beInteger).withMessage('VALIDATION_NOT_INTEGER');
	//instance.ruleFor('symbole').notEmpty().withMessage('VALIDATION_NOTEMPTY');

	return instance;

	function beInteger(obj, prop) {
		return !isNaN(prop);
	}

	function beNull(obj, prop) {
		if (typeof prop !== 'undefined' && prop) {
			return false;
		}
		return true;
	}
}
