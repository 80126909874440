import ngDragDrop from 'angular-native-dragdrop';

DispatchingController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'DispatchingService',
    'DispatchingCommunicationService',
    'notification',
    '$uibModal'
];

export default function DispatchingController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    DispatchingService,
    DispatchingCommunicationService,
    notification,
    $uibModal
) {
    const vm = this;

    const watchers = [];

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.dispatching = {};

    vm.loadDispatching = loadDispatching;
    vm.showChauffeur = showChauffeur;
    vm.showLivraison = showLivraison;
    vm.deletePlanningTour = deletePlanningTour;

    async function init() {
        vm.isFiltrer = false;
        vm.sites = await DispatchingService.getSitesCommerciaux();
        vm.ongletOpen = {
            isFiltreOpen: true
        };
    }

    init();

    async function loadDispatching() {
        startLoading();

        vm.dispatching = {};

        try {
            if (vm.date && vm.siteId) {
                vm.dispatching = await DispatchingService.getDispatching(vm.date, vm.siteId);
                vm.isFiltrer = true;
                vm.ongletOpen = {
                    isFiltreOpen: false
                };
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    $scope.onDrop = function (target, source) {
        const _this = this;
        _this.source = source;
        _this.target = target;
        _this.date = vm.dispatching.date;
        $uibModal
            .open({
                template:
                    '<planning-modal equipage="$ctrl.source" demande="$ctrl.target" date="$ctrl.date" modal-instance="$ctrl.uibModalInstance"></planning-modal>',
                controller: [
                    '$uibModalInstance',
                    function ($uibModalInstance) {
                        const $ctrl = this;
                        $ctrl.source = _this.source;
                        $ctrl.target = _this.target;
                        $ctrl.date = _this.date;
                        $ctrl.uibModalInstance = $uibModalInstance;
                    }
                ],
                controllerAs: '$ctrl',
                size: 'l',
                backdrop: false
            })
            .result.then(
                function (result) {
                    if (result) {
                        loadDispatching();
                    }
                },
                function (reason) {
                    // console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
                }
            );
    };

    $scope.dropValidate = function (target, source) {
        let response = true;
        if (source.etat == 2 || source.etat == 3) {
            response = false;
        }
        return response;
    };

    function showLivraison(equipage, value) {
        equipage.isShowLivraison = value;
    }

    function showChauffeur(demande, value) {
        demande.isShowChauffeur = value;
    }

    async function deletePlanningTour(planningTour) {
        if (planningTour && planningTour.idPlanningTour) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('DISPATCHING.DELETE.TITLE'),
                modalMsg: $translate.instant('DISPATCHING.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await DispatchingService.deletePlanningTourById(planningTour.idPlanningTour);
                    notification.success($translate.instant('DISPATCHING.DELETE.SUCCESS'));
                    if (vm.params.id) {
                        vm.state.go('dispatching.list');
                    }
                    loadDispatching();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
