import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-slider-container',
    bindings: {
        table: '<',
        loadPipe: '=',
        params: '<',
        closeUrl: '@',
        noSlider: '<',
        noBackground: '<'
    },
    template: require('./massia.slider.container.tpl.html'),
    replace: true,
    transclude: {
        content: 'subContent',
        footer: 'subFooter'
    }
})
export class MassiaSliderContainerController {
    constructor($state, $window, $scope, $rootScope) {
        this.$state = $state;
        this.window = $window;
        this.scope = $scope;
        this.rScope = $rootScope;
    }

    $onInit() {
        if (!this.noSlider) {
            this.slideMargin = {
                'margin-right': '0px',
                'transition-duration': '0.5s',
                'transition-animation': 'margin-right'
            };
            this.onResize();
            this.checkSize();
            this.startListener();
        } else {
            this.slideMargin = {};
        }
    }

    $onDestroy() {
        angular.element(this.window).off('resize');
        if (this.unbindLocation) {
            this.unbindLocation();
        }
    }

    checkSize() {
        if (this.$state.params.id) {
            this.slideMargin['margin-right'] = this.slideWidth;
        } else {
            this.slideMargin['margin-right'] = '0px';
        }
    }

    startListener() {
        this.unbindLocation = this.rScope.$on('$locationChangeSuccess', () => {
            this.checkSize();
        });
        angular.element(this.window).on('resize', () => {
            this.checkSize();
        });
    }

    onResize() {
        if (this.window.innerWidth < 768) {
            this.slideWidth = '100%';
        } else {
            this.slideWidth = '500px';
        }
    }

    closeDetail() {
        this.$state.go(this.closeUrl);
    }
}

MassiaSliderContainerController.$inject = ['$state', '$window', '$scope', '$rootScope'];
