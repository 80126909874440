import validatorBuilder from 'validator-builder';

let motifDepreciationStockValidator = null;

export default class MotifDepreciationStock {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
    }

    isValid() {
        motifDepreciationStockValidator = motifDepreciationStockValidator || validatorBuilder.getInstanceFor('MotifDepreciationStock');
        const validationResults = motifDepreciationStockValidator.validate(this);
        return validationResults.isValid;
    }
}
