import controller from './massia.navigation.controller';

const massiaNavigationComponent = {
    bindings: {
        close: '=',
        openCloseHeader: '&'
    },
    template: require('./massia.navigation.2.tpl.html'),
    controller: controller
};

export default massiaNavigationComponent;
