ChantiersGrillesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'ChantiersGrillesService',
    'notification'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function ChantiersGrillesController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    ChantiersGrillesService,
    notification
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    const selectedChantierId = undefined;
    const displayModal = false;
    let indiceSelected;
    let grilleParentSelected;
    const grillesSocieteEtSiteComm = [];
    const indicesRevision = [];
    const duplication = {
        indiceId: null,
        grilleParentId: null,
        dateCreation: null,
        augmentation: null,
        suffixe: null,
        dateDebut: null,
        dateFin: null,
        grillesId: []
    };
    const resultat = null;
    let startYear;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.grillesChantier = [];
    vm.displayModal = displayModal;
    vm.indicesRevision = indicesRevision;
    vm.duplication = duplication;
    vm.grillesSocieteEtSiteComm = grillesSocieteEtSiteComm;
    vm.indiceSelected = indiceSelected;
    vm.grilleParentSelected = grilleParentSelected;
    vm.loadGrillesChantier = loadGrillesChantier;
    vm.valider = valider;
    vm.annuler = annuler;
    vm.resultat = resultat;
    vm.startYear = startYear;
    vm.yearChange = yearChange;

    async function init() {
        vm.startYear = new Date().getFullYear();
        //écouter l'event lié au changement de route
        //une fois on change l'url, et on arrive sur la page "chantiers.list", on désélectionne la ligne sélectionnée (s'il y en a)
        watchers.push($scope.$on('$destroy', dispose));
        vm.indicesRevision = await ChantiersGrillesService.getIndicesRevision();
        vm.grillesSocieteEtSiteComm = await ChantiersGrillesService.getGrillesSocieteEtSiteComm();
    }

    init();

    async function valider() {
        if (
            vm.grilleParentSelected &&
            vm.indiceSelected &&
            vm.duplication.dateDebut != null &&
            vm.duplication.dateFin != null &&
            vm.duplication != null &&
            vm.duplication.suffixe != null
        ) {
            vm.duplication.grilleParentId = vm.grilleParentSelected.id;
            vm.duplication.indiceId = vm.indiceSelected.id;

            vm.duplication.grillesId = [];

            vm.grillesChantier.forEach(function (grille) {
                if (grille.selected == true) {
                    vm.duplication.grillesId.push(grille.id);
                }
            });

            vm.resultat = await ChantiersGrillesService.duplicationGrillesChantier(vm.duplication);
            loadGrillesChantier(null);
        } else {
            notification.error('Des champs obligatoires ne sont pas renseignés!');
        }
    }

    function annuler() {
        vm.displayModal = false;
        vm.duplication = {
            indiceId: null,
            grilleParentId: null,
            dateCreation: null,
            augmentation: null,
            suffixe: null,
            dateDebut: null,
            dateFin: null,
            grillesId: []
        };
        vm.indiceSelected = null;
        vm.grilleParentSelected = null;
        vm.resultat = null;
    }

    async function yearChange() {
        loadGrillesChantier();
    }

    async function loadGrillesChantier(tableState) {
        startLoading();

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.grillesChantier = [];

        try {
            const data = await ChantiersGrillesService.getGrillesChantier(filters, sorts, pagination, vm.startYear);

            vm.grillesChantier = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
