export default function Configure($stateProvider) {
    $stateProvider.state('calculs', {
        parent: 'common',
        url: '/calculs',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('calculs.list', {
        url: '/list',
        template: '<calculs-statistiques></calculs-statistiques>',
        rights: { domain: 'calcstat', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "CALCULS_STATS.BREADCRUMBS.CALCULS_STATS_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'CALCULS_STATS.BREADCRUMBS.CALCULS_STATS_LIST',
            order: 22,
            navigationCls: 'menu-item-separator'
        },
        search: 'CALCULS_STATS.BREADCRUMBS.CALCULS_STATS_LIST'
    });

    $stateProvider.state('calculs.list.detail', {
        url: '/{id}',
        template: '<calcul-statistique-detail></calcul-statistique-detail>',
        rights: { domain: 'calcstat', right: 'read' },
        ncyBreadcrumb: {
            parent: 'calculs.list',
            label: '{{ "CALCULS_STATS.BREADCRUMBS.CALCUL_STAT_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('calculs.new', {
        url: '/new',
        template: '<calcul-statistique-form></calcul-statistique-form>',
        rights: { domain: 'calcstat', right: 'create' },
        ncyBreadcrumb: {
            parent: 'calculs.list',
            label: '{{ "CALCULS_STATS.BREADCRUMBS.CALCUL_STAT_NEW" | translate}}'
        },
        search: 'CALCULS_STATS.BREADCRUMBS.CALCUL_STAT_NEW'
    });

    $stateProvider.state('calculs.edit', {
        url: '/{id}/edit',
        template: '<calcul-statistique-form></calcul-statistique-form>',
        rights: { domain: 'calcstat', right: 'update' },
        ncyBreadcrumb: {
            parent: 'calculs.list',
            label: '{{ "CALCULS_STATS.BREADCRUMBS.CALCUL_STAT_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
