PrestationEnteteValidator.$inject = ['validator'];

export default function PrestationEnteteValidator(validator) {
    const instance = new validator();

    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 15).withMessage('VALIDATION_TOO_LONG_15');
    instance.ruleFor('libelle').length(0, 50).withMessage('VALIDATION_TOO_LONG_50');
    instance.ruleFor('uniteId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('grilleRevisionId').notEmpty().when(showGrilleRevision).withMessage('VALIDATION_NOTEMPTY');

    return instance;

    function showGrilleRevision(obj, prop) {
        return (
            typeof obj.modeDeclenchement !== 'undefined' && (obj.modeDeclenchement == 3 || obj.modeDeclenchement == 4 || obj.modeDeclenchement == 5)
        );
    }
}
