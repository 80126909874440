const serviceUrl = `${__configuration.apiUrl}/massia/plannings/feuille-route`;

export default class FeuilleRouteService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getChauffeurs() {
        const chauffUrl = `${__configuration.apiUrl}/massia/personnes/chauffeurs`;
        const res = await this.$http.get(chauffUrl, {
            params: {
                societeId: -1,
                siteTransporteurId: -1
            }
        });
        return res.data;
    }

    async print(id, date, fileName) {
        const filtres = {
            id: id,
            date: date,
            fileName: fileName
        };
        const url = `${serviceUrl}/print`;
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {})
            },
            responseType: 'arraybuffer'
        });

        return res;
    }
}
