import NiveauxHierarchiquesElementsController from './niveaux.hierarchiques.elements.controller';

export default {
    template: require('./niveaux.hierarchiques.elements.tpl.html'),
    controller: NiveauxHierarchiquesElementsController,
    bindings: {
        nodeUpdated: '<',
        treeviewConfig: '<',
        treeviewService: '<'
    }
};
