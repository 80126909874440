import ReferentielsConfig from './_config/referentiels.config';
import ReferentielsRoutes from './_config/referentiels.routes';
import ReferentielsComponent from './components/referentiels';
import ReferentielFormComponent from './components/referentiel-form';
import ReferentielMesuresComponent from './components/referentiel-form/referentiel-mesures';
import ReferentielFormCaracteristiquesComponent from './components/referentiel-form/caracteristiques';
import ReferentielFormCaracteristiqueTypeProduitsComponent from './components/referentiel-form/caracteristique-type-produits';
import ReferentielComposantComponent from './components/referentiel-composant';
import ReferentielsService from './services/referentiels.service';
import ReferentielTypesService from './services/referentiel.types.service';
import ReferentielsCommunicationService from './services/referentiels.communication.service.js';
import ReferentielValidator from './components/referentiel-form/referentiel.validator';
import ReferentielCaracteristiquesValidator from './components/referentiel-form/caracteristiques/referentiel.caracteristiques.validator';
import ReferentielCaracteristiqueTypeProduitsValidator from './components/referentiel-form/caracteristique-type-produits/referentiel.caracteristique.type.produits.validator';

const moduleName = 'app.massia.common.qualib.referentiels';

angular
    .module(moduleName, [])
    .config(ReferentielsConfig)
    .config(ReferentielsRoutes)
    .service('ReferentielsService', ReferentielsService)
    .service('ReferentielTypesService', ReferentielTypesService)
    .service('ReferentielsCommunicationService', ReferentielsCommunicationService)
    .component('referentiels', ReferentielsComponent)
    .component('referentielForm', ReferentielFormComponent)
    .component('referentielMesures', ReferentielMesuresComponent)
    .component('referentielFormCaracteristiques', ReferentielFormCaracteristiquesComponent)
    .component('referentielFormCaracteristiqueTypeProduits', ReferentielFormCaracteristiqueTypeProduitsComponent)
    .component('referentielComposant', ReferentielComposantComponent)
    .factory('ReferentielValidator', ReferentielValidator)
    .factory('ReferentielCaracteristiquesValidator', ReferentielCaracteristiquesValidator)
    .factory('ReferentielCaracteristiqueTypeProduitsValidator', ReferentielCaracteristiqueTypeProduitsValidator);

export default moduleName;
