const serviceUrl = `${__configuration.apiUrl}/massia/parametrage-general`;
const jsonFileGroupes = 'assets/enum/param-prog/paramProgGroups.json';
const jsonFileParametresConfig = 'assets/enum/param-prog/paramProgConfig.json';
export default class ParametrageGeneralService {
	static $inject = ['$http'];

	constructor($http) {
		this.$http = $http;
	}

	async getParametrageGeneral(application) {
		const res = await this.$http.get(serviceUrl, {
			params: {
				application: JSON.stringify(application)
			}
		});
		return res.data;
	}

	async updateParametrageGeneral(parametrageGeneral, application) {
		//formater les valeurs
		parametrageGeneral.forEach((groupe) => {
			groupe.parametres.forEach((parametre) => {
				switch (parametre.typeParametre) {
					case 1: //booléen
						if (parametre.valeur) {
							parametre.valeur = 'true';
						} else {
							parametre.valeur = 'false';
						}
						break;
					case 7: //entier
						if (parametre.valeur) {
							parametre.valeur = parametre.valeur.toString();
						}
						break;
				}
			});
		});
		const url = `${__configuration.apiUrl}/massia/parametrage-general/${application}`;
		return this.$http.put(url, parametrageGeneral);
	}

	async getElementsNiveauxStocks() {
		const url = `${__configuration.apiUrl}/massia/domaines/produitsstockes/elements-hierarchiques`;
		const res = await this.$http.get(url);
		return res.data;
	}
	async getNiveauxStocks() {
		const url = `${__configuration.apiUrl}/massia/domaines/produitsstockes/niveaux-hierarchiques`;
		const res = await this.$http.get(url);
		return res.data;
	}

	async getGroupes() {
		return (await this.$http.get(jsonFileGroupes)).data;
	}
	async getParametres() {
		return (await this.$http.get(jsonFileParametresConfig)).data;
	}
}
