export class CritereAffichage {
    constructor(data = {}, type) {
        this.id = data.id || -1;
        this.ordre = data.ordre || 0;
        this.title = data.title || data.searchProperty || null;
        this.searchProperty = data.searchProperty || null;
        this.style = data.style || {};
        this.nestedCritere = data.nestedCritere || [];
        this.idDonneeSelectionable = data.idDonneeSelectionable;
        this.champ = data.typeCritere;
        this.champCompo = data.champComposition;
        this.idCaracteristique = data.idCaracteristique;
        this.type = type;
        this.idUnite = data.idUnite;
        this.style = data.style
            ? {
                  id: data.style.id,
                  isFiltered: data.style.isFiltered,
                  isGrouped: data.style.isGrouped,
                  typeFilter: data.style.typeFilter,
                  periodInterval: data.style.periodInterval,
                  periodType: data.style.periodType,
                  typePinned: data.style.typePinned,
                  isPinned: data.style.isPinned,
                  unite: data.style.unite || {}
              }
            : {};
    }
}

export class LigneCritereAffichage {
    constructor(data = {}) {
        this.id = data.id || -1;
        this.style = data.style || {};
        this.criteres = data.criteres || [];
    }
}
