//Config
import MesuresConfig from './_config/mesures.config';
import MesuresRoutes from './_config/mesures.routes';
import MesCtrlRoutes from './_config/mesctrl.routes';
import MesSecuRoutes from './_config/messecu.routes';
import EssaisRoutes from './_config/essais.routes';

//Components
import MesuresComponent from './components/mesures';
import MesureFormComponent from './components/mesure-form';
import MesureDetailComponent from './components/mesure-detail';

import MesureFormEnteteComponent from './components/mesure-form/entete';
import MesureSousMesuresComponent from './components/mesure-form/sous-mesures';
import MesureCourbeGranuloComponent from './components/mesure-form/courbe-granulo';
import MesureFormCaracteristiquesComponent from './components/mesure-form/caracteristiques';

import MesuresSelectComponent from './components/mesures-select';
import MesuresOrdreComponent from './components/mesures-ordre';
import SousMesuresSelectComponent from './components/sous-mesures-select';
import TamisSelectComponent from './components/tamis-select';

import MesureFormAccreditation from './components/mesure-form/accreditation';
//services
import MesuresService from './services/mesures.service';
import MesuresTypesService from './services/mesures.types.service';
import MesuresCommunicationService from './services/mesures.communication.service';

//Validateurs
import MesureValidator from './components/mesure-form/mesure.validator';
import MesureEnteteValidator from './components/mesure-form/entete/mesure.entete.validator';
import MesureSousMesuresValidator from './components/mesure-form/sous-mesures/mesure.sous.mesures.validator';
import MesureCourbeGranuloValidator from './components/mesure-form/courbe-granulo/mesure.courbe.granulo.validator';
import MesureCaracteristiquesValidator from './components/mesure-form/caracteristiques/mesure.caracteristiques.validator';
import AdditionnalInfoMesureValidator from './components/mesure-form/nature-additionnal-info-mesure/additionnal.info.mesure.validator';

import NatureAdditionnalInfoMesure from './components/mesure-form/nature-additionnal-info-mesure';

const moduleName = 'app.massia.common.mesures';

angular
    .module(moduleName, [])
    .run([
        '$templateCache',
        ($templateCache) => {
            $templateCache.put(
                'mesure-form/field-template.elements.html',
                require('./components/mesure-form/nature-additionnal-info-mesure/templates/field-template.elements.html')
            );
            $templateCache.put(
                'mesure-form/field-template.essaiiteratif.html',
                require('./components/mesure-form/nature-additionnal-info-mesure/templates/field-template.essaiiteratif.html')
            );
            $templateCache.put(
                'mesure-form/field-template.excel.html',
                require('./components/mesure-form/nature-additionnal-info-mesure/templates/field-template.excel.html')
            );
            $templateCache.put(
                'mesure-form/field-template.granulo.html',
                require('./components/mesure-form/nature-additionnal-info-mesure/templates/field-template.granulo.html')
            );
            $templateCache.put(
                'mesure-form/field-template.numeric.html',
                require('./components/mesure-form/nature-additionnal-info-mesure/templates/field-template.numeric.html')
            );
        }
    ])
    .config(MesuresConfig)
    .config(MesuresRoutes)
    .config(MesCtrlRoutes)
    .config(MesSecuRoutes)
    .config(EssaisRoutes)
    .service('MesuresService', MesuresService)
    .service('MesuresTypesService', MesuresTypesService)
    .service('MesuresCommunicationService', MesuresCommunicationService)
    .service('AdditionnalInfoMesureValidator', AdditionnalInfoMesureValidator)
    .component('mesures', MesuresComponent)
    .component('mesureForm', MesureFormComponent)
    .component('mesureDetail', MesureDetailComponent)
    .component('mesureFormEntete', MesureFormEnteteComponent)
    .component('mesureFormCaracteristiques', MesureFormCaracteristiquesComponent)
    .component('natureAdditionnalInfoMesure', NatureAdditionnalInfoMesure)
    .component('mesureSousMesures', MesureSousMesuresComponent)
    .component('mesureCourbeGranulo', MesureCourbeGranuloComponent)
    .component('mesuresSelect', MesuresSelectComponent)
    .component('mesuresOrdre', MesuresOrdreComponent)
    .component('sousMesuresSelect', SousMesuresSelectComponent)
    .component('tamisSelect', TamisSelectComponent)
    .component('mesureFormAccreditation', MesureFormAccreditation)
    .factory('MesureValidator', MesureValidator)
    .factory('MesureEnteteValidator', MesureEnteteValidator)
    .factory('MesureCaracteristiquesValidator', MesureCaracteristiquesValidator)
    .factory('MesureSousMesuresValidator', MesureSousMesuresValidator)
    .factory('MesureCourbeGranuloValidator', MesureCourbeGranuloValidator);

export default moduleName;
