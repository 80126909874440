DeviseValidator.$inject = ['validator'];

export default function DeviseValidator(validator) {
    const instance = new validator();

    instance.ruleFor('id').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('id').length(1, 15).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    instance.ruleFor('symbole').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('symbole').length(1, 15).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    instance.ruleFor('arrondi').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    return instance;
}
