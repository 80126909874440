(function (angular, globalHelpers, undefined) {
    'use strict';

    angular
        .module('blocks.helpers')
        .factory('BrowserService', BrowserService);
    BrowserService.$inject = ['$window', '$timeout'];

    /* @ngInject */
    function BrowserService($window, $timeout) {
        var service = {
            openNewTab: openNewTab,
            updateTabLocation: updateTabLocation
        };

        return service;

        function openNewTab(url) {
            if (globalHelpers && !globalHelpers.isInternetExplorer()) {
                var newTabWindow = $window.open(url);
                return newTabWindow;
            }
        }

        function updateTabLocation(url, tab) {
            // si l'on n'est pas sur IE, on doit avoir recours à une astuce pour ouvrir les onglets sans se faire bloquer
            if (globalHelpers && !globalHelpers.isInternetExplorer()) {
                if (!tab || !angular.isFunction(tab.close)) {
                    return;
                }
                if (!url) {
                    tab.close();
                    return;
                }
                tab.location.href = url;
            }
            else {
                $window.open(url, '_blank');
            }
        }
    }
})(angular, window.globalHelpers);