//Config
import ThemesConfig from './_config/themes.config';
import ThemesRoutes from './_config/themes.routes';
import ThemesLaboRoutes from './_config/themeslabo.routes';

//Components
import ThemesComponent from './components/themes';
import ThemeFormComponent from './components/theme-form';
//import ThemeDetailComponent from './components/theme-detail';

//services
import ThemesService from './services/themes.service';

//Validateurs
import ThemeValidator from './components/theme-form/theme.validator';

const moduleName = 'app.massia.common.themes';

angular
    .module(moduleName, [])
    .config(ThemesConfig)
    .config(ThemesRoutes)
    .config(ThemesLaboRoutes)
    .service('ThemesService', ThemesService)
    .component('themes', ThemesComponent)
    .component('themeForm', ThemeFormComponent)
    //.component('themeDetail', ThemeDetailComponent)
    .factory('ThemeValidator', ThemeValidator);

export default moduleName;
