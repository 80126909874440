import SyntheseEntityFormController from './syntheseentities.form.controller';

export default {
    bindings: {
        synthForGene: '=',
        modalInstance: '=',
        paramsSynthese: '='
    },
    template: require('./syntheseentities.form.html'),
    controller: SyntheseEntityFormController
};
