import MonitoringSynchroClientServeurController from './monitoring.synchro.client.serveur.controller';

export default {
    bindings: {
        sites: '<',
        domaines: '<'
    },
    template: require('./monitoring.synchro.client.serveur.html'),
    controller: MonitoringSynchroClientServeurController
};
