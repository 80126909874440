import validatorBuilder from 'validator-builder';

let offreProducteurLigneTransportValidator = null;

export default class OffreProducteurLigneTransport {
    constructor() {
        this.id = -1;
        this.typeArticle = 1;
        this.quantite = 1;
        this.prixVente = null;
        this.typePrix = null;
        this.prixStandard = null;
        this.prixProduitTransporte = null;
        this.prixAchatTransport = null;
        this.idProduit = null;
        this.unite = null;
        this.idUnite = null;
        this.idBenne = null;
        this.tvaId = 1;
    }

    isValid() {
        offreProducteurLigneTransportValidator =
            offreProducteurLigneTransportValidator || validatorBuilder.getInstanceFor('OffreProducteurLigneTransport');
        const validationResults = offreProducteurLigneTransportValidator.validate(this);
        return validationResults.isValid;
    }
}
