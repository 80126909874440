import CritrereIdEnumController from './critere.id.enum.controller';

export default {
    bindings: {
        critere: '=',
        modeleSelection: '=',
        canUpdate: '<'
    },
    controller: CritrereIdEnumController,
    template: require('./critere.id.enum.tpl.html')
};
