import validatorBuilder from 'validator-builder';

let formuleComposantValidator = null;

export default class FormuleComposant {
    constructor(data = {}, skeleton = false) {
        this.id = data.id;
        this.idComposant = data.idComposant || data.produitId;
        this.idElement = data.idElement || data.idComposant || data.produitId;
        this.ordre = data.ordre || data.order;
        this.idProducteur = data.idProducteur || data.producteurId;
        this.libelleProducteur = data.libelleProducteur || data.producteurLibelle;
        this.code = data.code;
        this.codeFamille = data.codeFamille || data.familleCode;
        this.libelle = data.libelle;
        this.unite = data.unite;
        this.quantite = data.quantite || 0;
        this.libelleFamille = data.libelleFamille || data.familleLibelle;
        this.unitePesee = data.unitePesee;
        this.coutIncorporation = data.coutIncorporation || null;
        this.pourcentagePerte = data.pourcentagePerte || null;
        this.prixCession = data.prixCession || null;
        this.commentaire = data.commentaire || null;
        this.isInSquelette = data.isInSquelette || skeleton;
        this.isInSkeleton = data.isInSquelette || skeleton;
        this.poids = data.poids || 0;
        this.idType = data.typeProduit || data.idType;
        this.typeLibelle = data.typeLibelle;
        this.typeCode = data.typeCode;
        this.idUniteCompo = data.idUniteCompo;
        this.uniteCompo = data.uniteCompo;
        this.coefk = data.coefk;
        this.selectCoef = data.selectCoef || false;
        this.imprimable = data.imprimable || false;
        this.qteImprimable = data.qteImprimable || false;
        this.nomImprime = data.nomImprime;
        this.valeursDeRef = data.valeursDeRef;
        this.poidsAbs = data.poidsAbs;
        this.pourcAbs = data.pourcAbs;
        this.mvt = data.mvt;
        this.volume = data.volume || 0;
        this.prix = data.prix;
        if (data.familleCode === 'ADD' || data.codeFamille === 'ADD') {
            this.coefficientK = data.coefficientK || [];
        }
        this.valeurCaracteristiques = data.valeurCaracteristiques || [];
        this.formuleChoice = data.formuleChoice;
        this.isEnabledProduct = data.isEnabledProduct || true;
        this.isEnabledSiteProduct = data.isEnabledSiteProduct || true;
        this.isEnabledSiteLinked = data.isEnabledSiteLinked || true;
        this.backgroundColor = data.backgroundColor;

        if (this.idProducteur) {
            this.type = 1;
        } else {
            this.type = 0;
        }
    }

    isValid() {
        formuleComposantValidator = formuleComposantValidator || validatorBuilder.getInstanceFor('FormuleComposant');
        const validationResults = formuleComposantValidator.validate(this);
        return validationResults.isValid;
    }
}
