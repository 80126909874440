import AccessPortail from './AccessPortail';

export default class GroupDomain {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.access = data.access ? data.access : null;
    }

    /* set access(value) {
        if (value && isArray(value)) {
            this.access = this._defineAccess(value);
        } else {
            this.access = null;
        }
    } */

    _defineAccess(list) {
        const res = [];
        for (let i = 0; i < list.length; i++) {
            const access = list[i];
            res.push(
                new AccessPortail({
                    id: access.id,
                    idDomain: access.idDomain,
                    accessLevel: access.accessLevel,
                    read: access.read,
                    update: access.update,
                    create: access.create,
                    delete: access.delete,
                    print: access.print
                })
            );
        }
        return res;
    }
}
