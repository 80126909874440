import SyntheseAffichageController from './synthese.affichage.controller';

export default {
    controller: SyntheseAffichageController,
    template: require('./synthese.affichage.tpl.html'),
    bindings: {
        synthese: '=',
        elements: '=',
        conversion: '<',
        gen: '<'
    }
};
