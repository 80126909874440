import CampagneCaracteristiques from './campagne.caracteristiques.model';

export default class CampagneCaracteristiquesController {
    static $inject = ['_', 'CampagnesTypesService', 'notification', '$timeout', '$scope'];

    constructor(_, CampagnesTypesService, notification, $timeout, $scope) {
        this._ = _;
        this.CampagnesTypesService = CampagnesTypesService;
        this.notification = notification;
        this.$timeout = $timeout;
        this.$scope = $scope;
    }

    $onInit() {
        this.loading = false;
        this.typeFormat = this.typeFormat || [];
        this.campagne = this.campagne || {};
        this.niveauVisibilite = 0;
        this.caracteristiques = new CampagneCaracteristiques(this.campagne.caracteristiques);

        this.unregister = [];
        this.unregister.push(
            this.$scope.$watch(
                () => this.campagne.entete.niveauVisibilite,
                async () => {
                    this.niveauVisibilite = this.campagne.entete.niveauVisibilite;
                }
            )
        );
    }

    $onDestroy() {
        this.$timeout.cancel(this.typeFormatTimeout);

        for (let i = 0; i < this.unregister.length; i++) {
            this.unregister[i]();
        }
    }

    $onChanges(objChanges) {
        if (objChanges.typeId) {
            if (!objChanges.typeId.isFirstChange()) {
                this.caracteristiques = new CampagneCaracteristiques();
            }

            this.getTypeFormat(objChanges.typeId.currentValue);
        }
    }

    // cette fonction est appelée automatiquement
    // a chaque digest (dirty check) d'angular
    $doCheck() {
        if (!angular.equals(this.caracteristiques, this.campagne.caracteristiques)) {
            this.onUpdate({
                caracteristiques: angular.copy(this.caracteristiques)
            });
        }
    }

    async getTypeFormat(typeId) {
        if (typeId) {
            // on démarre l'animation de loading avant le chargement des données
            this.startLoading();

            // on encapsule l'appel serveur dans un try...catch pour récupérer les erreurs
            try {
                // on récupère les données depuis le service qui interroge le serveur
                const typeFormat = await this.CampagnesTypesService.getCaracteristiques(typeId);
                // on trie le tableau selon la propriété 'position'
                // on publie le tableau dans le this pour l'afficher dans la vue
                this.typeFormat = this._.sortBy(typeFormat, 'position');
            } catch (ex) {
                // en cas de problème on notifie l'utilisateur
                this.notification.error(ex.data);
            } finally {
                // On supprimer le timeout précédent (fuite mémoire)
                this.$timeout.cancel(this.typeFormatTimeout);

                // une fois l'appel terminé, on stoppe l'animation de loading
                this.typeFormatTimeout = this.$timeout(() => {
                    this.stopLoading();
                });
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
