FactureEnteteValidator.$inject = ['validator'];

export default function FactureEnteteValidator(validator) {
    const instance = new validator();
    instance.ruleFor('idSociete').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    // instance.ruleFor('idClient').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('idModePaiement').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('idPresentation').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('idTypeFacture').notEmpty().when(isNotZero).withMessage('VALIDATION_NOTEMPTY');
    return instance;

    function isNotZero(obj, prop) {
        return prop != null && typeof prop !== undefined && prop != 0;
    }
}
