//Config
import ModelesSaisiesConfig from './_config/modeles.saisies.config';
import ModelesSaisiesRoutes from './_config/modelessaisies.routes';
import ModelesSaisiesERoutes from './_config/modelessaisiese.routes';
import ModelesSaisiesPRoutes from './_config/modelessaisiesp.routes';
import ModelesSaisiesMRoutes from './_config/modelessaisiesm.routes';

//Components
import ModelesSaisiesComponent from './components/modeles-saisies';
import ModeleSaisieDetailComponent from './components/modele-saisie-detail';
import ModeleSaisieFormComponent from './components/modele-saisie-form';

//Services
import ModelesSaisiesService from './services/modeles.saisies.service';
//import ModelesSaisiesTypesService from './services/modeles.saisies.types.service';
import ModelesSaisiesCommunicationService from './services/modeles.saisies.communication.service';

//Validateurs
import ModeleSaisieValidator from './components/modele-saisie-form/modele.saisie.validator';

const moduleName = 'app.massia.common.modeles.saisies';

angular
    .module(moduleName, [])
    .config(ModelesSaisiesConfig)
    .config(ModelesSaisiesRoutes)
    .config(ModelesSaisiesERoutes)
    .config(ModelesSaisiesPRoutes)
    .config(ModelesSaisiesMRoutes)
    .service('ModelesSaisiesService', ModelesSaisiesService)
    //.service('ModelesSaisiesTypesService', ModelesSaisiesTypesService)
    .service('ModelesSaisiesCommunicationService', ModelesSaisiesCommunicationService)
    .component('modelesSaisies', ModelesSaisiesComponent)
    .component('modeleSaisieDetail', ModeleSaisieDetailComponent)
    .component('modeleSaisieForm', ModeleSaisieFormComponent)
    .factory('ModeleSaisieValidator', ModeleSaisieValidator);

export default moduleName;
