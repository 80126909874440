import SocieteFormEnteteController from './societe.form.entete.controller';

export default {
    bindings: {
        societe: '<',
        form: '=',
        onUpdate: '&',
        genre: '<'
    },
    template: require('./societe.form.entete.html'),
    controller: SocieteFormEnteteController
};
