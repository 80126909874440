import { getNatureNamesByValues } from '../../../caracteristiques/services/natures.enum';

export default class SocieteDetailController {
    static $inject = ['$stateParams', '$state', 'SocietesService', 'notification', 'SocietesCommunicationService', 'MassiaApplicationService'];

    constructor($stateParams, $state, SocietesService, notification, SocietesCommunicationService, MassiaApplicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.SocietesService = SocietesService;
        this.notification = notification;
        this.SocietesCommunicationService = SocietesCommunicationService;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        this.loading = false;
        this.societe = {};
        this.caracteristiques = [];

        this.ongletOpen = {
            isEnteteOpen: true,
            isCaracteristiqueOpen: false,
            isNiveauxHierarchiqueOpen: false
        };

        this.initSociete();
    }

    async initSociete() {
        this.societe = { id: this.$stateParams.id };
        if (this.societe.id) {
            this.startLoading();
            try {
                this.societe.entete = await this.SocietesService.getSocieteEnteteById(
                    this.societe.id,
                    this.MassiaApplicationService.getApplication()
                );
                this.societe.caracteristiques = await this.SocietesService.getSocieteValeursCaracteristiquesById(this.societe.id);
                this.societe.niveaux = await this.SocietesService.getSocieteValeursNiveauxById(this.societe.id);
                this.setCaracteristiques(this.societe.caracteristiques);
                await this.loadNiveaux();
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListSociete();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListSociete();
        }
    }

    async setCaracteristiques(caracteristiques = []) {
        const natures = getNatureNamesByValues();
        this.caracteristiques = [];
        caracteristiques.forEach((c) => {
            this.caracteristiques.push({
                code: c.code,
                label: c.label,
                values: getValue(c.value),
                nature: natures[c.idNature],
                numericAdditionalInformation: c.numericAdditionalInformation,
                labelCls: 'col-xs-12 col-sm-4',
                inputCls: 'col-xs-12 col-sm-8'
            });
        });

        function getValue(val) {
            if (angular.isArray(val)) {
                if (_.some(val, 'key')) {
                    return _.map(val, 'value');
                }
                return val;
            }
            return [val];
        }
    }

    async loadNiveaux() {
        this.startLoading();

        try {
            this.niveaux = await this.SocietesService.getSocietesNiveaux();
            this.elements = await this.SocietesService.getSocietesElements();
        } catch (ex) {
            this.notification.error(ex);
        } finally {
            this.stopLoading();
        }
    }

    goListSociete() {
        this.$state.go('societes.list', {}, { reload: true });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
