import ArchivageFtpModalController from './archivage.ftp.modal.controller';

const moduleName = 'app.massia.archivageftp';

export default {
    template: require('./archivage.ftp.modal.html'),
    controller: ArchivageFtpModalController,
    bindings: {
        modalInstance: '='
    }
};
