const serviceUrl = `${__configuration.apiUrl}/massia/references`;

export default class ReferencesControleService {
    static $inject = ['$http', '_'];

    constructor($http, _) {
        this.$http = $http;
        this._ = _;
    }

    async getReferences(filters, sorts, pagination, domaine) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                domaine: JSON.stringify(domaine || {}),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getReferenceById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getReferenceBorneById(id, idProduit, idProduitCom) {
        if (!idProduitCom) {
            idProduitCom = 0;
        }
        const url = `${serviceUrl}/bornes/${id}/${idProduit}/${idProduitCom}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSitesProducteurs() {
        const application = 'all'; //SP 05/10/18 pour laisser non filtré sur états compte
        const url = `${__configuration.apiUrl}/massia/sites-producteurs/headers/${application}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getProducteursTypeAhead(valeur) {
        const application = 'all'; //SP 05/10/18 pour laisser non filtré sur états compte
        const url = `${__configuration.apiUrl}/massia/sites-producteurs/typeAhead/${application}/${valeur}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSitesClients() {
        const url = `${__configuration.apiUrl}/massia/sites-clients/all`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async createReference(reference) {
        const result = await this.$http.post(serviceUrl, reference);
        return result.data;
    }

    async updateReference(reference) {
        const url = `${serviceUrl}/${reference.id}`;
        return await this.$http.put(url, reference);
    }

    async deleteReferenceById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async exportListeReferences(domaine, modeImpr, filters, sorts) {
        const url = `${serviceUrl}/print`;
        const res = await this.$http.get(url, {
            params: {
                modeImpr: JSON.stringify(modeImpr),
                domaine: JSON.stringify(domaine),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || [])
            },
            responseType: 'arraybuffer'
        });
        return res;
    }

    async getReferenceControleValeursCaracteristiquesAssociationsById(id) {
        const data = await this.getReferenceControleValeursCaracteristiquesById(id);
        const result = {};
        for (const element in data) {
            if (data[element] && !Array.isArray(data[element])) {
                if (data[element].value === 'True') {
                    data[element].value = 'true';
                }
                if (data[element].value === 'False') {
                    data[element].value = 'false';
                }

                if (data[element].value && data[element].value.key) {
                    result[data[element].id] = data[element].value.key;
                } else {
                    result[data[element].id] = data[element].value;
                }
            }
        }
        return result;
    }

    async getReferenceControleValeursCaracteristiquesById(id) {
        const url = `${serviceUrl}/${id}/valeurs-caracteristiques`;
        const res = await this.$http.get(url);
        const result = [];
        res.data.forEach(function (element) {
            const caract = {
                id: element.id,
                code: element.code,
                idNature: element.idNature,
                indice: element.indice,
                label: element.label,
                numericAdditionalInformation: element.numericAdditionalInformation,
                value: element.elementsValues || element.elementValue || element.values || element.value
            };
            result.push(caract);
        });
        return result;
    }

    async getProduitsByProducteurs(idsProducteurs) {
        const url = `${__configuration.apiUrl}/massia/produits/produits-dimensions-by-producteurs/header`;
        const res = await this.$http.get(url, {
            params: {
                idsProducteurs: JSON.stringify(idsProducteurs)
            }
        });
        return res.data;
    }

    async getProduitsByProducteurTypeAhead(idProducteur, valeur) {
        const url = `${__configuration.apiUrl}/massia/produits/produitsProducteur/headers`;
        const res = await this.$http.get(url, {
            params: {
                id: id,
                valeur: valeur
            }
        });
        return res.data;
    }

    async getProduitsWithoutProducteurTypeAhead(valeur) {
        const url = `${__configuration.apiUrl}/massia/produits/headers/${valeur}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getNormes() {
        const url = `${__configuration.apiUrl}/massia/normes/headers`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getLogos() {
        const url = `${__configuration.apiUrl}/massia/images/reference`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSpecificationsNorme(idNorme, idProduit) {
        const url = `${__configuration.apiUrl}/massia/normes/specifications/${idNorme}/${idProduit}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSpecificationByCategorie(mesure, idProduit) {
        let url = '';
        const idCategorie = mesure.categorieId || 0;
        if (mesure.tamis) {
            const idTamis = mesure.sousEssaiId;
            url = `${__configuration.apiUrl}/massia/normes/specification-tamis-by-categorie/${idProduit}/${idTamis}/${idCategorie}`;
        } else {
            const idSsEss = mesure.sousEssaiId;
            url = `${__configuration.apiUrl}/massia/normes/specification-essai-by-categorie/${idProduit}/${idSsEss}/${idCategorie}`;
        }
        const res = await this.$http.get(url);
        return res.data;
    }

    async getIncertitudesEssaisByNorme(idNormeSpecifique, idProduit) {
        const url = `${__configuration.apiUrl}/massia/laboratoire/incertitudes-essais/${idNormeSpecifique}/${idProduit}`;
        const res = await this.$http.get(url);

        return res.data;
    }

    chercheIncertitude(mesure, currentIncertitudeReference, incertitudes, importNorme) {
        currentIncertitudeReference = mesure.incertitudeReference || currentIncertitudeReference;
        const elementIncertitudesTamis = this._.filter(incertitudes, function (e) {
            return e.idSsEssTamis === mesure.idTamis || (e.formatSsEssai == 8 && e.idSsEssTamis == -1 && mesure.tamis);
        });
        const elementIncertitudesId = this._.filter(incertitudes, function (e) {
            return e.idSsEssai === mesure.sousEssaiId && e.formatSsEssai != 8 && !mesure.tamis;
        });
        let lstOK = [];
        //TAMIS
        if (elementIncertitudesTamis && elementIncertitudesTamis.length > 0 && importNorme) {
            //vérifier les conditions VTYPE & TAMIS
            for (var i = 0; i < elementIncertitudesTamis.length; i++) {
                var cond = elementIncertitudesTamis[i].condition;
                var bCondNOK = false;
                if (cond && cond.includes('@VTYPE')) {
                    if (mesure.moyenne == undefined || mesure.moyenne == null) {
                        bCondNOK = true;
                    } else {
                        cond = cond.replace(/@VTYPE/g, mesure.moyenne);
                    }
                }
                if (cond && cond.includes('@VSI')) {
                    if (mesure.borneInferieure == undefined || mesure.borneInferieure == null) {
                        bCondNOK = true;
                    } else {
                        cond = cond.replace(/@VSI/g, mesure.borneInferieure);
                    }
                }
                if (cond && cond.includes('@VSS')) {
                    if (mesure.borneSuperieure == undefined || mesure.borneSuperieure == null) {
                        bCondNOK = true;
                    } else {
                        cond = cond.replace(/@VSS/g, mesure.borneSuperieure);
                    }
                }
                if (cond && cond.includes('@TAMIS')) {
                    cond = cond.replace(/@TAMIS/g, mesure.ouvertureTamis);
                }
                if (!bCondNOK) {
                    try {
                        if (cond) {
                            cond = cond.replace(/True/g, 'true');
                            cond = cond.replace(/False/g, 'false');
                            cond = cond.replace(/<>/g, '!=');
                            cond = cond.replace(/<=/g, '{'); //pour pouvoir traiter le = après
                            cond = cond.replace(/>=/g, '}'); //pour pouvoir traiter le = après
                            cond = cond.replace(/=/g, '==');
                            cond = cond.replace(/{/g, '<='); //suite traitement =
                            cond = cond.replace(/}/g, '>='); //suite traitement =
                            bCondNOK = !eval(cond);
                        }
                    } catch (ex) {
                        bCondNOK = true;
                        console.error('catch eval tamis chercheIncertitude', cond, ex);
                    }
                    if (!bCondNOK) {
                        lstOK.push(elementIncertitudesTamis[i]);
                    }
                }
            }
            if (lstOK.length > 1) {
                //trouver l'incertitude prioritaire
                //=> si il y a des incertitudes avec le bon tamis direct, prioritaire sur celles de la granulo entière
                const lstTamisDirect = this._.filter(lstOK, function (e) {
                    return e.idSsEssTamis === mesure.sousEssaiId;
                });
                if (lstTamisDirect && lstTamisDirect.length > 0) {
                    lstOK = lstTamisDirect;
                }
                if (lstOK.length > 1) {
                    //=> si il y a des incertitudes avec condition : prioritaire sur celles n'en ayant pas
                    var lstAvecCond = this._.filter(lstOK, function (e) {
                        return e.condition;
                    });
                    if (lstAvecCond && lstAvecCond.length > 0) {
                        lstOK = lstAvecCond;
                    }
                }
            }
            if (lstOK.length == 1) {
                mesure.incertitudeReference = lstOK[0].incertitude;
            }
        }
        //SOUS-ESSAIS
        else if (elementIncertitudesId && elementIncertitudesId.length > 0 && importNorme) {
            //vérifier les conditions VTYPE/VSS/VSI
            for (var i = 0; i < elementIncertitudesId.length; i++) {
                var cond = elementIncertitudesId[i].condition;
                var bCondNOK = false;
                if (cond && cond.includes('@VTYPE')) {
                    if (mesure.moyenne == undefined || mesure.moyenne == null) {
                        bCondNOK = true;
                    } else {
                        cond = cond.replace(/@VTYPE/g, mesure.moyenne);
                    }
                }
                if (cond && cond.includes('@VSI')) {
                    if (mesure.borneInferieure == undefined || mesure.borneInferieure == null) {
                        bCondNOK = true;
                    } else {
                        cond = cond.replace(/@VSI/g, mesure.borneInferieure);
                    }
                }
                if (cond && cond.includes('@VSS')) {
                    if (mesure.borneSuperieure == undefined || mesure.borneSuperieure == null) {
                        bCondNOK = true;
                    } else {
                        cond = cond.replace(/@VSS/g, mesure.borneSuperieure);
                    }
                }
                if (!bCondNOK) {
                    try {
                        if (cond) {
                            cond = cond.replace(/True/g, 'true');
                            cond = cond.replace(/False/g, 'false');
                            cond = cond.replace(/<>/g, '!=');
                            cond = cond.replace(/<=/g, '{'); //pour pouvoir traiter le = après
                            cond = cond.replace(/>=/g, '}'); //pour pouvoir traiter le = après
                            cond = cond.replace(/=/g, '==');
                            cond = cond.replace(/{/g, '<='); //suite traitement =
                            cond = cond.replace(/}/g, '>='); //suite traitement =
                            bCondNOK = !eval(cond);
                        }
                    } catch (ex) {
                        bCondNOK = true;
                        console.error('catch eval essai chercheIncertitude', cond, ex);
                    }
                    if (!bCondNOK) {
                        lstOK.push(elementIncertitudesId[i]);
                    }
                }
            }
            if (lstOK.length > 1) {
                //trouver l'incertitude prioritaire
                //=> si il y a des incertitudes avec condition : prioritaire sur celles n'en ayant pas
                var lstAvecCond = this._.filter(lstOK, function (e) {
                    return e.condition;
                });
                if (lstAvecCond && lstAvecCond.length > 0) {
                    lstOK = lstAvecCond;
                }
            }
            if (lstOK.length == 1) {
                mesure.incertitudeReference = lstOK[0].incertitude;
            }
        } else {
            mesure.incertitudeReference = currentIncertitudeReference;
        }
    }

    async getReferencesByFamille(idFamille) {
        const url = `${serviceUrl}/famille/${idFamille}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getReferencesGroupedByNorme(lstIdsProduit, lstIdsSiteProd, lstIdsSiteClient, idFamille) {
        const res = await this.$http.get(`${serviceUrl}/referencesbynormes`, {
            params: {
                lstIdsSiteProd: lstIdsSiteProd || [],
                lstIdsProduit: lstIdsProduit || [],
                lstIdsSiteClient: lstIdsSiteClient || [],
                idFamille: idFamille || 0
            }
        });
        return res.data;
    }
}
