export default class HomeController {
    static $inject = ['$stateParams', 'notification', 'MOPService'];

    constructor($stateParams, notification, MOPService) {
        this.$stateParams = $stateParams;
        this.notification = notification;
        this.MOPService = MOPService;
    }

    async $onInit() {
        this.MOPService.setMop([
            { title: 'Bien commencer avec MASSIA', filename: 'BienCommencerAvecMASSIA.pdf', application: 'massia' },
            { title: "Généralités d'utilisation", filename: 'GeneralitesUtilisationMASSIA.pdf', application: 'massia' },
            { title: 'Massia portail', filename: 'PortailMassia.pdf', application: 'massia' }
        ]);
        // si on vient de la gestion des droits, on prévient l'utilisateur de sa redirection
        if (this.$stateParams.missingRight) {
            // TODO afficher le nom du state via this.$stateParams.originalState
            this.notification.warning('USER_MISSING_STATE_RIGHT');
        }
    }

    $onDestroy() {
        this.MOPService.resetMop();
    }
}
