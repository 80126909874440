export default function Configure($stateProvider) {
	$stateProvider.state('releve-bons', {
		parent: 'common',
		url: '/releve-bons',
		views: {
			common: {
				template: '<ui-view></ui-view>'
			}
		},
		ncyBreadcrumb: {
			label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
		}
	});

	$stateProvider.state('releve-bons.list', {
		url: '/list',
		template: '<releve-bons></releve-bons>',
		rights: { domain: 'tickets', right: 'read', application: 'gestion' },
		ncyBreadcrumb: {
			label: '{{ "RELEVE_BONS.BREADCRUMBS.RELEVE_BONS_LIST" | translate}}'
		},
		// on affiche la liste des tickets dans la navigation
		navigation: {
			menu: 'pesees_livraisons',
			translate: 'RELEVE_BONS.BREADCRUMBS.RELEVE_BONS_LIST',
			order: 2,
			navigationCls: 'menu-item-separator'
		},
		// la liste des tickets fait partie des points d'entrée recherchables dans la navigation
		search: 'RELEVE_BONS.BREADCRUMBS.RELEVE_BONS'
	});
	$stateProvider.state('releve-bons.print', {
		url: '/configuration-impression',
		template: '<releve-bons-print></releve-bons-print>',
		rights: { domain: 'tickets', right: 'print', application: 'gestion' },
		ncyBreadcrumb: {
			parent: 'releve-bons.list',
			label: '{{ "RELEVE_BONS.BREADCRUMBS.RELEVE_BONS_PRINT_SETTINGS" | translate}}'
		}
	});
}

Configure.$inject = ['$stateProvider'];
