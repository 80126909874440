import validatorBuilder from 'validator-builder';

let prospectEnteteValidator = null;

export default class ProspectEntete {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.adresse = data.adresse;
        this.codePostal = data.codePostal;
        this.commentaire = data.commentaire;
        this.email = data.email;
        this.fax = data.fax;
        this.paysId = data.paysId;
        this.societeId = data.societeId;
        this.telephone = data.telephone;
        this.ville = data.ville;
        this.website = data.website;
        this.createur = data.createur;
        this.estCodeAuto = data.estCodeAuto;
        this.nomContact = data.nomContact;
        this.sfac = data.sfac;
        this.encours = data.encours;
    }

    isValid() {
        prospectEnteteValidator = prospectEnteteValidator || validatorBuilder.getInstanceFor('ProspectEntete');
        const validationResults = prospectEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
