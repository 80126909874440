import MotifAjustementStock from './motif.ajustement.stock.model';

export default class MotifAjustementStockFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'MotifsAjustementStockCommunicationService',
        'MotifsAjustementStockService',
        'notification',
        '$location'
    ];

    constructor($scope, $state, $stateParams, MotifsAjustementStockCommunicationService, MotifsAjustementStockService, notification, $location) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.MotifsAjustementStockCommunicationService = MotifsAjustementStockCommunicationService;
        this.MotifsAjustementStockService = MotifsAjustementStockService;
        this.notification = notification;
        this.$location = $location;
    }

    async $onInit() {
        this.isEditMode = false;
        this.motifAjustementStock = {};
        this.loading = false;
        this.setMotifAjustementStock(this.$stateParams.id);
        this.unregisterReinit = this.MotifsAjustementStockCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        this.unregisterReinit();
    }

    initForm() {
        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
            const thatMotifAjustementStock = {
                id: this.motifAjustementStock.id,
                libelle: this.motifAjustementStock.libelle
            };
        } else {
            this.isEditMode = false;
        }
    }

    async setMotifAjustementStock(motifAjustementStockId) {
        if (motifAjustementStockId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                const stillThatMotifAjustementStock = await this.MotifsAjustementStockService.getMotifAjustementStockById(motifAjustementStockId);
                this.motifAjustementStock = new MotifAjustementStock(stillThatMotifAjustementStock);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('motifs-ajustement-stock.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.motifAjustementStock = new MotifAjustementStock();
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.motifAjustementStock.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.motifAjustementStock.codeExists = await this.MotifsAjustementStockService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    // boutons de validation
    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                if (this.motifAjustementStock.id) {
                    const tmp = await this.updateMotifAjustementStock();
                    this.notification.success('MOTIFS_AJUSTEMENT_STOCK.UPDATED');
                    return this.motifAjustementStock.id;
                }
                const tmp = await this.createMotifAjustementStock();
                this.notification.success('MOTIFS_AJUSTEMENT_STOCK.CREATED');
                return tmp;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        } else {
            return false;
        }
    }

    async valider() {
        const returnedId = await this.sauvegarder();
        if (returnedId) {
            this.$state.go('motifs-ajustement-stock.edit', { id: returnedId });
        }
    }
    async validerEtFermer() {
        const returnedId = this.sauvegarder();
        this.backToPreviousState();
    }

    annuler() {
        this.backToPreviousState();
    }

    backToPreviousState() {
        this.$state.go('motifs-ajustement-stock.list');
    }

    async createMotifAjustementStock() {
        this.startLoading();
        try {
            const returnedId = await this.MotifsAjustementStockService.createMotifAjustementStock(this.motifAjustementStock);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateMotifAjustementStock() {
        this.startLoading();
        try {
            const returnedId = await this.MotifsAjustementStockService.updateMotifAjustementStock(this.motifAjustementStock);
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    reinit() {
        this.setMotifAjustementStock(this.$stateParams.id);
        this.MotifsAjustementStockCommunicationService.raiseReinit();
    }

    checkValidity() {
        this.$scope.$broadcast('motifAjustementStockValidations');
        return this.motifAjustementStock.isValid();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
