import validatorBuilder from 'validator-builder';

let zoneAbattageValidator = null;

export default class ZoneAbattage {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.idSite = data.idSite;
        this.codeExists = data.codeExists || null;
        this.codeInvalid = data.codeInvalid || null;
    }

    isValid() {
        zoneAbattageValidator = zoneAbattageValidator || validatorBuilder.getInstanceFor('ZoneAbattage');
        const validationResults = zoneAbattageValidator.validate(this);
        return validationResults.isValid;
    }
}
