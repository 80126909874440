export default function Configure($stateProvider) {
    $stateProvider.state('type-bascules', {
        parent: 'gestion',
        url: '/type-bascules',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('type-bascules.list', {
        url: '/list',
        template: '<type-bascules></type-bascules>',
        rights: { domain: 'typebascule', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "TYPEBASCULES.BREADCRUMBS.TYPEBASCULES_LIST" | translate}}'
        },
        // on affiche la liste des type-bascules dans la navigation
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'TYPEBASCULES.BREADCRUMBS.TYPEBASCULES_LIST',
            order: 39
        },
        // la liste des type-bascules fait partie des points d'entrée recherchables dans la navigation
        search: 'TYPEBASCULES.BREADCRUMBS.TYPEBASCULES_LIST'
    });

    $stateProvider.state('type-bascules.list.detail', {
        url: '/{id}',
        template: '<type-bascules-detail></type-bascules-detail>',
        rights: { domain: 'typebascule', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher type-bascules > détail
            parent: 'type-bascules.list',
            label: '{{ "TYPEBASCULES.BREADCRUMBS.TYPEBASCULE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('type-bascules.new', {
        url: '/new',
        template: '<type-bascules-form></type-bascules-form>',
        rights: { domain: 'typebascule', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher type-bascules > ajouter
            parent: 'type-bascules.list',
            label: '{{ "TYPEBASCULES.BREADCRUMBS.TYPEBASCULE_NEW" | translate}}'
        },
        // l'ajout d'une entite fait partie des points d'entrée recherchables dans l'application
        search: 'TYPEBASCULES.BREADCRUMBS.TYPEBASCULE_NEW'
    });

    $stateProvider.state('type-bascules.edit', {
        url: '/{id}/edit',
        template: '<type-bascules-form></type-bascules-form>',
        rights: { domain: 'typebascule', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher type-bascules > modifier
            parent: 'type-bascules.list',
            label: '{{ "TYPEBASCULES.BREADCRUMBS.TYPEBASCULE_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
