const serviceUrl = `${__configuration.apiUrl}/massia/modeles-saisies`;

export default class ModelesSaisiesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getModeles(filters, sorts, pagination, domaine) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                domaine: JSON.stringify(domaine || {}),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });
        return res.data;
    }
    async getModeleById(id) {
        const url = `${serviceUrl}/${id}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async createModele(modele) {
        const result = await this.$http.post(serviceUrl, modele);
        return result.data;
    }

    async updateModele(modele) {
        const url = `${serviceUrl}/${modele.id}`;
        return await this.$http.put(url, modele);
    }

    async deleteModeleById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async exportListeModeles(domaine, modeImpr, filters, sorts) {
        const url = `${serviceUrl}/print`;
        const res = await this.$http.get(url, {
            params: {
                modeImpr: JSON.stringify(modeImpr),
                domaine: JSON.stringify(domaine),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || [])
            },
            responseType: 'arraybuffer'
        });
        return res;
    }

    async getTypesCM(domaine, idTheme) {
        let domaineCM = 'campagnes';
        switch (domaine) {
            case 'modelessaisies':
                domaineCM = 'campagnesgran';
                break;
            case 'modelessaisiesp':
                domaineCM = 'campagnesctrl';
                break;
            case 'modelessaisiesm':
                domaineCM = 'campagnessecu';
                break;
        }
        if (!idTheme) {
            idTheme = 0;
        }
        const url = `${__configuration.apiUrl}/massia/domaines/${domaineCM}/types/by-theme/${idTheme}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getTypesSM(domaine, idTheme, idFamille) {
        let domaineSM = 'saisies';
        switch (domaine) {
            case 'modelessaisies':
                domaineSM = 'prelevements';
                break;
            case 'modelessaisiesp':
                domaineSM = 'saisieCtrl';
                break;
            case 'modelessaisiesm':
                domaineSM = 'saisiesecu';
                break;
        }
        let url = '';
        if (idTheme) {
            url = `${__configuration.apiUrl}/massia/domaines/${domaineSM}/types/by-theme/${idTheme}`;
        } else if (idFamille) {
            url = `${__configuration.apiUrl}/massia/domaines/${domaineSM}/types/filtre-famille/${idFamille}`;
        } else {
            url = `${__configuration.apiUrl}/massia/domaines/${domaineSM}/types`;
        }
        const res = await this.$http.get(url);
        return res.data;
    }

    async getThemes(codeDomaine) {
        const themeUrl = `${__configuration.apiUrl}/massia/themes/headers/${codeDomaine}`;
        const res = await this.$http.get(themeUrl);
        return res.data;
    }

    async getFamilles() {
        const familleUrl = `${__configuration.apiUrl}/massia/familles/filtre`;
        const res = await this.$http.get(familleUrl);
        return res.data;
    }

    async getSitesIntervenants() {
        // const url = `${__configuration.apiUrl}/massia/sites-auditeurs/typeAhead/${valeur}`;
        const url = `${__configuration.apiUrl}/massia/sites-auditeurs/`;
        const res = await this.$http.get(url);
        return res.data.items;
    }

    async getSitesLaboratoires() {
        // const url = `${__configuration.apiUrl}/massia/sites-laboratoires/typeAhead/${valeur}`;
        const url = `${__configuration.apiUrl}/massia/sites-laboratoires`;
        const res = await this.$http.get(url);
        return res.data.items;
    }

    async getSitesProducteurs(application, idSiteInterv) {
        let url = '';
        if (idSiteInterv) {
            const params = {
                sitesIntervenant: [idSiteInterv]
            };
            url = `${__configuration.apiUrl}/massia/sites-producteurs/compteOuvertSiteComm`;
            const res = await this.$http.get(url, { params });
            return res.data;
        }

        url = `${__configuration.apiUrl}/massia/sites-producteurs/${application}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getPersonnes(idSiteAuditeur) {
        const siteUrl = `${__configuration.apiUrl}/massia/personnes/salarie-et-non-affecte-site-auditeur/headers/${idSiteAuditeur}`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    // Deprecated
    async getSitesClient() {
        const url = `${__configuration.apiUrl}/massia/sites-clients`;
        const res = await this.$http.get(url);
        return res.data.items;
    }

    async getPointsMesures(idTheme, idSiteProd) {
        if (!idSiteProd) {
            idSiteProd = 0;
        }
        if (!idTheme) {
            idTheme = 0;
        }
        const ptsMesUrl = `${__configuration.apiUrl}/massia/qse/points-mesure/theme/${idTheme}/site/${idSiteProd}`;
        const res = await this.$http.get(ptsMesUrl);
        return res.data;
    }

    async getProduits(valeur, idFamille, idSiteProd) {
        if (!idSiteProd) {
            idSiteProd = 0;
        }
        if (!idFamille) {
            idFamille = 0;
        }
        const pdtsUrl = `${__configuration.apiUrl}/massia/produits/headers/${valeur}/famille/${idFamille}/site/${idSiteProd}`;
        const res = await this.$http.get(pdtsUrl);
        return res.data;
    }

    async getTrames(domaine, idTheme, idSiteProd, idFamille) {
        const referenceUrl = `${__configuration.apiUrl}/massia/trames/by-filtre`;
        const res = await this.$http.get(referenceUrl, {
            params: {
                idFamille: JSON.stringify(idFamille || 0),
                idSiteProd: JSON.stringify(idSiteProd || 0),
                idProduit: JSON.stringify(/*idProduit ||*/ 0),
                domaine: JSON.stringify(domaine)
            }
        });
        return res.data;
    }

    async getReferences(domaine, idTheme, idSiteProd, idFamille) {
        if (!idSiteProd) {
            idSiteProd = 0;
        }
        let refUrl = '';
        if (idTheme) {
            refUrl = `${__configuration.apiUrl}/massia/references/header-by-theme/${idTheme}/by-site/${idSiteProd}`;
        } else if (idFamille) {
            refUrl = `${__configuration.apiUrl}/massia/references/famille/${idFamille}`;
        } else {
            refUrl = `${__configuration.apiUrl}/massia/references/header-by-domaine/${domaine}/by-site/${idSiteProd}`;
        }
        const res = await this.$http.get(refUrl);
        return res.data;
    }

    async hasThemeCampagne(idTheme) {
        const url = `${__configuration.apiUrl}/massia/themes/${idTheme}`;
        const res = await this.$http.get(url);
        return res.data.hasCampagne;
    }

    async estCodeAuto(domaine) {
        const url = `${serviceUrl}/est-code-auto/${domaine}`;
        try {
            const res = await this.$http.get(url);
            return res.data;
        } catch (err) {
            if (err.status === 404) {
                return false;
            }
            throw err;
        }
    }

    async afficheCode(domaine) {
        const url = `${serviceUrl}/affiche-code/${domaine}`;
        try {
            const res = await this.$http.get(url);
            return res.data;
        } catch (err) {
            if (err.status === 404) {
                return false;
            }
            throw err;
        }
    }
}
