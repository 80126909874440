import Prospect from './prospect.model';
import ProspectEntete from '../prospect-entete/prospect.entete.model';

export default class ProspectFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'ProspectsCommunicationService',
        'ProspectsService',
        'notification',
        '$location',
        '$anchorScroll'
    ];

    constructor($scope, $state, $stateParams, ProspectsCommunicationService, ProspectsService, notification, $location, $anchorScroll) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ProspectsCommunicationService = ProspectsCommunicationService;
        this.ProspectsService = ProspectsService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
    }

    $onInit() {
        this.isEditMode = false;
        this.prospect = {};
        this.loading = false;

        this.closeAllOnglets();
        this.openFirstOnglet();

        this.setProspect(this.$stateParams.id);
    }

    async setProspect(prospectId) {
        if (prospectId && this.$stateParams.fromOffre == false) {
            this.startLoading();
            try {
                this.isEditMode = true;
                await this.setProspectEntete(prospectId);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('prospects.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.prospect = new Prospect();
            if (this.idSociete) {
                this.prospect.entete.societeId = this.idSociete;
            }
        }
    }

    async setProspectEntete(prospectId) {
        const entete = await this.ProspectsService.getProspectEnteteById(prospectId);
        this.prospect.entete = new ProspectEntete(entete);
    }

    // nécessité de sortir ce test des méthodes appliquer() et confirmer()
    // pour être sûr que la bdd soit à jour avant de faire la suite
    async createOrUpdate() {
        let id = null;
        if (this.isEditMode) {
            id = await this.updateProspect();
        } else {
            id = await this.createProspect();
        }
        return id;
    }

    async appliquer() {
        const id = await this.createOrUpdate();
        if (id && this.$stateParams.fromOffre == false) {
            this.$state.go('prospects.edit', { id: id });
        }
        // si on vient de l'offre, on laisse la modal ouverte
    }

    async confirmer() {
        const id = await this.createOrUpdate();

        if (this.$stateParams.fromOffre == false) {
            this.backToPreviousState();
        } else {
            // si on vient de l'offre, on ferme la modal
            this.modalInstance.close(true);
        }
    }

    openFirstOnglet() {
        this.ongletOpen = {
            isEnteteOpen: true
        };
    }

    closeAllOnglets() {
        this.ongletOpen = {
            isEnteteOpen: false
        };
    }

    annuler() {
        if (this.$stateParams.fromOffre == false) {
            this.backToPreviousState();
        } else {
            // si on vient de l'offre, on ferme la modal
            this.modalInstance.close(true);
        }
    }

    backToPreviousState() {
        this.$state.go('prospects.list');
    }

    reinit() {
        this.openFirstOnglet();
        this.setProspect(this.$stateParams.id);
        this.ProspectsCommunicationService.raiseReinit();
    }

    checkValidity() {
        this.closeAllOnglets();
        let validity = true;

        let firstScroll = true;
        if (!this.prospect.entete.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('entete');
                firstScroll = false;
            }

            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.$scope.$broadcast('prospectEnteteValidations');
        }

        return validity;
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    async createProspect() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                let id = false;
                id = await this.ProspectsService.createProspect(this.prospect.entete);
                this.notification.success('PROSPECTS.CREATED');

                return id;
            } catch (ex) {
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        }
    }

    async updateProspect() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                let id = false;
                await this.ProspectsService.updateEnteteProspect(this.prospect.entete);
                this.notification.success('PROSPECTS.UPDATED');
                id = this.prospect.entete.id;

                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                this.reinit();
            } finally {
                this.stopLoading();
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
