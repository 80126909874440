import NormeDetailSpecificationsController from './norme.detail.specifications.controller';

export default {
    template: require('./norme.detail.specifications.tpl.html'),
    controller: NormeDetailSpecificationsController,
    bindings: {
        categories: '<',
        domaine: '<',
        specification: '<',
        essai: '<',
        norme: '<'
    }
};
