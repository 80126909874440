import LayoutConfiguration from './config/layout.translations';
import BreadcrumbsConfiguration from './config/breadcrumbs.config';

import HeaderComponent from './components/header';
import LayoutComponent from './components/layout';
import LogoComponent from './components/logo';
import UserInfoComponent from './components/user-info';
import AuthConstant from '../authentication/config/auth.constant';

// Massia navigation
import * as MassiaNavigation from './components/massia-navigation/index';
import * as MassiaApplication from './components/massia-application';

const moduleName = 'app.massia.layout';

angular
	.module(moduleName, [])
	.provider('MassiaApplication', [
		function MassiaApplicationProvider() {
			this.app = 'gestion';
			this.setApp = (name) => {
				this.app = name;
			};
			this.$get = () => {
				const app = this.app;
				return {
					getCurrentApplication: function () {
						return app;
					},
					setApp: (name) => {
						this.app = name;
					}
				};
			};
		}
	])
	.config(LayoutConfiguration)
	.config(BreadcrumbsConfiguration)
	.constant('AuthConstant', AuthConstant)
	.component('massiaHeader', HeaderComponent)
	.component('layout', LayoutComponent)
	.component('logo', LogoComponent)
	.component('userInfo', UserInfoComponent)

	// Massia application
	.component('massiaApplication', MassiaApplication.component)
	.service('MassiaApplicationService', MassiaApplication.svcApplication)
	.service('MassiaClientService', MassiaApplication.svcClient)

	// Massia navigation
	.constant('MassiaNavigationConfigConstant', MassiaNavigation.config)
	.service('MassiaNavigationService', MassiaNavigation.service)
	.component('massiaNavigation', MassiaNavigation.component);

export default moduleName;
