import validatorBuilder from 'validator-builder';

let signatureValidator = null;

export default class Signature {
    constructor() {
        this.id = null;
        this.code = null;
        this.libelle = null;
        this.idType = null;
        this.source = null;
        this.taille = null;
        this.file = null;
    }

    isValid() {
        signatureValidator = signatureValidator || validatorBuilder.getInstanceFor('Signature');
        const validationResults = signatureValidator.validate(this);
        return validationResults.isValid;
    }
}
