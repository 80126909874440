//import validatorBuilder from 'validator-builder';
import ProduitEnteteNorme from './entete/produit.entete.norme.model';
import ProduitCaracteristiques from '../produit-form/caracteristiques/produit.caracteristiques.model';

const produitValidator = null;

export default class ProduitNorme {
    constructor(data = {}) {
        this.id = data.id;
        this.entete = new ProduitEnteteNorme(data.entete);
        this.caracteristiques = new ProduitCaracteristiques(data.caracteristiques);
    }

    isValid() {
        //produitValidator = produitValidator || validatorBuilder.getInstanceFor('Produit');
        //const validationResults = produitValidator.validate(this);
        return true; //validationResults.isValid;
    }
}
