import CodeAutoConfig from './_config/code-automatique.config';
import CodeAutoRoutes from './_config/code-automatique.routes';
import CodeAutoComponent from './components';
import CodeAutoService from './services/code-automatique.service';
import codeAutomatiqueHelper from './components/code-automatique-helper';

const moduleName = 'app.massia.common.code-automatique';

angular
    .module(moduleName, [])
    .config(CodeAutoConfig)
    .config(CodeAutoRoutes)
    .service('CodeAutomatiqueService', CodeAutoService)
    .component('codeAutomatique', CodeAutoComponent)
    .component('codeAutoHelper', codeAutomatiqueHelper);
export default moduleName;
