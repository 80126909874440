import SyntheseBlocSelectionController from './synthese.bloc.selection.controller';

export default {
    controller: SyntheseBlocSelectionController,
    template: require('./synthese.bloc.selection.tpl.html'),
    bindings: {
        bloc: '=',
        modeleSelection: '<',
        isUser: '<'
    }
};
