import GrillesZonesKilometriquesConfig from './_config/grilles.zones.kilometriques.config';
import GrillesZonesKilometriquesRoutes from './_config/grilles.zones.kilometriques.routes';

import GrillesZonesKilometriquesService from './services/grilles.zones.kilometriques.service';

import GrillesZonesKilometriquesComponent from './components/grilles-zones-kilometriques';
import GrilleZonesKilometriquesDetailComponent from './components/grille-zones-kilometriques-detail';
import GrilleZonesKilometriquesFormComponent from './components/grille-zones-kilometriques-form';
import GrilleZonesKilometriquesValidator from './components/grille-zones-kilometriques-form/grille.zones.kilometriques.validator';

const moduleName = 'app.massia.gestion.grilles.zones.kilometriques';

angular
    .module(moduleName, [])
    .config(GrillesZonesKilometriquesConfig)
    .config(GrillesZonesKilometriquesRoutes)
    .service('GrillesZonesKilometriquesService', GrillesZonesKilometriquesService)
    .factory('GrilleZonesKilometriquesValidator', GrilleZonesKilometriquesValidator)
    .component('grillesZonesKilometriques', GrillesZonesKilometriquesComponent)
    .component('grilleZonesKilometriquesForm', GrilleZonesKilometriquesFormComponent)
    .component('grilleZonesKilometriquesDetail', GrilleZonesKilometriquesDetailComponent);

export default moduleName;
