import PrelevementGestionColonneTamisController from './prelevement.gestion.colonne.tamis.modal.controller';

export default {
    bindings: {
        close: '&',
        dismiss: '&',
        resolve: '<'
    },
    template: require('./prelevement.gestion.colonne.tamis.html'),
    controller: PrelevementGestionColonneTamisController
};
