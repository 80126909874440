import FactureEntete from './facture.entete.model';
import * as _ from 'lodash';
let unregisterReinit;

export default class FactureEnteteController {
	static $inject = [
		'$scope',
		'$stateParams',
		'notification',
		'FacturesCommunicationService',
		'FacturesService',
		'moment',
		'globalizationManagementService',
		'CentreGestionService'
	];

	constructor(
		$scope,
		$stateParams,
		notification,
		FacturesCommunicationService,
		FacturesService,
		moment,
		globalizationManagementService,
		CentreGestionService
	) {
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.FacturesCommunicationService = FacturesCommunicationService;
		this.FacturesService = FacturesService;
		this.notification = notification;
		this.loading = false;
		this.codeLoading = false;
		this.moment = moment;
		this.CentreGestionService = CentreGestionService;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async $onInit() {
		this.isEditMode = false;
		await this.checkEdition();

		unregisterReinit = this.FacturesCommunicationService.registerReinit(() => this.initForm());
		this.initEntete();

		await this.initiateClient();

		const _this = this;
		this.$scope.$watch('$ctrl.entete.dateGeneration', function (newValue, oldValue) {
			_this.processCalc();
		});
	}

	$onDestroy() {
		unregisterReinit();
	}

	async checkEdition() {
		if (this.$stateParams && this.$stateParams.id) {
			this.isEditMode = true;
		} else {
			this.isEditMode = false;
		}
	}

	initEntete() {
		this.startLoading();

		this.unites = [];
		this.modesCalcul = [];
		this.facture = this.facture || {};
		this.etatBloque = false;
		this.entete = new FactureEntete(this.facture.entete);
		this.listePiecesFacturation = null;
		this.facture.entete = this.entete;
		this.idSiteCommentaire = 0;
		try {
			// this.getEtats();
			this.getPresentationFacture();
			this.getListeModesPaiement();
			this.getListeSocietes();
			if (this.isEditMode) {
				this.getTypes();
				this.setFactureAsTypeAhead();
				if (this.entete.dateEcheance) {
					this.savedDateEcheance = angular.copy(this.entete.dateEcheance);
				} else {
					this.savedDateEcheance = this.moment().format(this.dateFormat);
				}
				this.idSiteCommentaire = this.entete.idClient;
			} else {
				// initialise les dates de debut et fin à la première seconde et la dernière du jour
				const maDate = new Date();
				const y = maDate.getFullYear();
				const m = maDate.getMonth();
				const d = maDate.getDate();
				this.entete.dateGeneration = maDate;
				this.entete.isSiteModifiable = true;
				this.getTypesCreate();
			}
		} catch (err) {
			if (err.status === 404) {
				this.goListFacture();
			}
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		} finally {
			this.stopLoading();
		}
		if (!this.entete.idEtatFacture) {
			this.entete.idEtatFacture = 0;
			this.etatBloque = true;
		}
		console.log(this.entete);
	}

	async getCommentaireClient(id) {
		if (id && id != this.idSiteCommentaire) {
			try {
				this.entete.commentaire = await this.FacturesService.getCommentaireClient(id);
				this.idSiteCommentaire = id;
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
		}
	}

	async getTypes() {
		if (!this.listeTypes) {
			this.startLoading();
			try {
				this.listeTypes = await this.FacturesService.getTypes();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.stopLoading();
			}
		}
	}
	async getTypesCreate() {
		if (!this.listeTypes) {
			this.startLoading();
			try {
				const droits = await this.FacturesService.getDroits();
				let droit = 'empty';
				if (droits.hasAvoirManuel && droits.hasFactureManuel) {
					droit = 'all';
				} else {
					if (droits.hasAvoirManuel) {
						droit = 'avoir';
					}
					if (droits.hasFactureManuel) {
						droit = 'facture';
					}
				}
				if (droit != 'empty') {
					this.listeTypes = await this.FacturesService.getTypesSurCreation(droit);
				}
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.stopLoading();
			}
		}
	}
	async getPresentationFacture() {
		if (!this.listePresentationFacture) {
			this.startLoading();
			try {
				const tmp = await this.FacturesService.getPresentation();
				this.listePresentationFacture = tmp.items;
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.stopLoading();
			}
		}
	}
	async getListeModesPaiement() {
		if (!this.listeModesPaiement) {
			this.startLoading();
			try {
				this.listeModesPaiement = await this.FacturesService.getModesPaiement();
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			} finally {
				this.stopLoading();
			}
		}
	}

	async getListeSocietes() {
		try {
			this.listeSocietes = await this.FacturesService.getSocietes();
		} catch (err) {
			if (err.data) {
				this.notification.error(err.data);
			} else {
				throw err;
			}
		}
	}

	async setTypeAheadAs(val) {
		if (!this.facture) {
			this.facture = {};
		}
		switch (val) {
			case 'siteUtilisateur':
				this.entete.idSiteCommercial = this.siteUtilisateur.id;
				this.entete.nomSiteCommercial = this.siteUtilisateur.libelle;
				break;
			case 'siteClient':
				this.entete.idClient = this.siteClient.id;
				this.entete.nomClient = this.siteClient.libelle;
				if (this.siteClient.id) {
					await this.getCommentaireClient(this.siteClient.id);
					// si il exite récupère le mode de paiement paramétrer pour le client
					await this.getModePaiement();
				}
				if (this.entete.idSociete != null) {
					await this.getPiecesFacturationLiees();
				}
				this.facture.entete.isClientGenerique = this.siteClient.isGenerique;
				this.entete.idClientDivers = null;
				this.entete.nomClientDivers = null;
				this.clientDivers.id = null;
				this.clientDivers.libelle = null;
				break;
			case 'clientDivers':
				this.entete.idClientDivers = this.clientDivers.id;
				this.entete.nomClientDivers = this.clientDivers.libelle;
				break;
			case 'chantier':
				this.entete.idChantier = this.entete.nomChantier.id;
				this.entete.nomChantier = this.entete.nomChantier.libelle;
				break;
		}
	}
	setFactureAsTypeAhead() {
		this.siteUtilisateur = {
			id: this.entete.idSiteCommercial,
			libelle: this.entete.nomSiteCommercial
		};
		this.siteClient = {
			id: this.entete.idClient,
			libelle: this.entete.nomClient
		};
		this.clientDivers = {
			id: this.entete.idClientDivers,
			libelle: this.entete.nomClientDivers
		};
		this.chantier = {
			id: this.entete.idChantier,
			libelle: this.entete.nomChantier
		};
	}

	// Fontionnel
	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	async getNomSitesCommerciaux(valeur) {
		try {
			const res = await this.FacturesService.getNomSitesCommerciaux(valeur, this.entete.idSociete);
			return res;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async getNomSitesClients(valeur) {
		try {
			const res = await this.FacturesService.getNomSitesClients(valeur, this.entete.idSociete);
			return res;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async getNomClientDivers(valeur) {
		try {
			const res = await this.FacturesService.getNomClientDivers(valeur, this.entete.idSociete);
			return res;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async getNomChantiersByClient(valeur) {
		try {
			const res = await this.FacturesService.getNomChantiersByClient(valeur, this.entete.idClient);
			return res;
		} catch (ex) {
			this.notification.error(ex.data);
		}
	}

	async processCalc() {
		if (this.entete.idModePaiement) {
			let dateGeneration = new Date();
			if (this.entete.dateGeneration) {
				dateGeneration = this.entete.dateGeneration;
			}

			const tempPaiementObject = {};
			tempPaiementObject.idModePaiement = this.entete.idModePaiement;
			tempPaiementObject.type = null;
			tempPaiementObject.echeance = null;
			tempPaiementObject.echeanceUnite = null;
			tempPaiementObject.modeCalcul = null;
			tempPaiementObject.jourFinDeMois = null;
			tempPaiementObject.dateEcheance = new Date();

			this.entete.dateEcheance = await this.FacturesService.GetDateSimulated(dateGeneration, tempPaiementObject);
		}
	}

	async getPiecesFacturationLiees() {
		this.siteUtilisateur = {
			id: null,
			libelle: null
		};
		await this.initiateClient();
		if (this.entete.idSociete !== null && this.entete.idClient !== null && this.entete.idTypeFacture !== null) {
			this.listePiecesFacturation = await this.FacturesService.getPiecesFacturationLiees(
				this.entete.idSociete,
				this.entete.idClient,
				this.entete.idTypeFacture
			);
		}
	}

	async getPiecesFacturationLiees(fromSociete = false) {
		if (fromSociete) {
			this.siteUtilisateur = {
				id: null,
				libelle: null
			};
		}
		await this.initiateClient();
		if (this.entete.idSociete !== null && this.entete.idClient !== null && this.entete.idTypeFacture !== null) {
			this.listePiecesFacturation = await this.FacturesService.getPiecesFacturationLiees(
				this.entete.idSociete,
				this.entete.idClient,
				this.entete.idTypeFacture
			);
		}
	}
	async initiateClient() {
		this.inputFiltreClients = await this.refreshFiltreClients();
		//await this.preselectFiltreSites();
	}

	async saveSelectedClients() {
		this.facture.idFiltreClients = [];
		for (let i = 0; i < this.outputFiltreClients.length; i++) {
			const siteClient = this.outputFiltreClients[i];
			this.facture.idFiltreClients.push(siteClient.id);
		}
	}

	async refreshFiltreClients() {
		let data;
		try {
			data = await this.FacturesService.getFiltreClients(this.entete.idSociete);
		} catch (ex) {
			data = [];
		}
		return data;
	}

	async getModePaiement() {
		const filter = {
			clientId: this.siteClient.id,
			date: this.moment().format(this.dateFormat)
		};
		const groupesParametres = await this.CentreGestionService.getParametersWithFilter('SiteClient', this.siteClient.id, filter);
		let modePaiement = -1;
		if (groupesParametres) {
			const factu = groupesParametres.find((gp) => gp.codeGroupe === 'FACTU');
			const modesPaiements = factu?.parametres.find((p) => p.codeParametre === 'modePaiement');
			const donnees = modesPaiements?.donnees.map((x) => x.valeurs.map((v) => v.valeur));
			const values = _.flattenDeep(donnees);
			if (values && values.length > 0) {
				modePaiement = values.find((x) => Number(x) > 0);
			}
			if (modePaiement) {
				this.entete.idModePaiement = Number(modePaiement);
				// this.listeModesPaiement[this.listeModesPaiement.findIndex(x => x.id == modePaiement)].selected = true;

				this.processCalc();
			}
		}
	}
}
