export default class AffectationPortailService {
    constructor($http) {
        this.$http = $http;
        this.url = `${__configuration.apiUrl}/massia/portail/affectation`;
    }
    listes = {
        SITE_LABO: [],
        SITE_PROD: [],
        SITE_CLIENT: [],
        NORME: [],
        MODELE_DOC: [],
        TYPE: [],
        REF: [],
        PROD: [],
        CONFORMITE: [],
        LANGUE: []
    };
    criteres = {
        ftp: [],
        prel: [],
        bl: [],
        fact: []
    };
    DOMAINES_PORTAIL = [
        {
            code: 'PREL',
            translate: 'PORTAIL.DOMAINS.PREL',
            libelle: ''
        },
        {
            code: 'FTP',
            translate: 'PORTAIL.DOMAINS.FTP',
            libelle: ''
        },
        {
            code: 'FACT',
            translate: 'PORTAIL.DOMAINS.FACT',
            libelle: ''
        },
        {
            code: 'BL',
            translate: 'PORTAIL.DOMAINS.BL',
            libelle: ''
        }
    ];
    DEPENDS_ON = {
        SITE_PROD: ['SITE_LABO'],
        REF: ['TYPE'],
        SITE_CLIENT: ['SITE_PROD'],
        PROD: ['SITE_PROD']
    };

    getDomains() {
        return this.DOMAINES_PORTAIL;
    }
    getAll(filters = [], sorts = [], pagination = { skip: 0, take: 0 }) {
        return this.$http.get(this.url, {
            params: {
                filters: JSON.stringify(filters),
                sorts: JSON.stringify(sorts),
                pagination: JSON.stringify(pagination)
            }
        });
    }
    getOne(id) {
        return this.$http.get(`${this.url}/${id}`);
    }
    create(data) {
        return this.$http.post(this.url, data);
    }
    update(id, data) {
        return this.$http.put(`${this.url}/${id}`, data);
    }
    delete(id) {
        return this.$http.delete(`${this.url}/${id}`);
    }
    async getAllCriteres() {
        const response = await this.$http.get(`${this.url}/criteres`);
        if (response && response.data) {
            this.criteres.ftp = response.data.filter((x) => x.domainPortail == 'FTP');
            this.criteres.prel = response.data.filter((x) => x.domainPortail == 'PREL');
            this.criteres.bl = response.data.filter((x) => x.domainPortail == 'BL');
            this.criteres.fact = response.data.filter((x) => x.domainPortail == 'FACT');
        }
    }

    //? LISTES DES DONNEES POUR LES CRITERES D'AFFECTATION
    getDependencies(critereCode) {
        if (this.DEPENDS_ON[critereCode]) {
            return this.DEPENDS_ON[critereCode];
        }

        return [];
    }
    async getList(critere, affectation) {
        switch (critere.code) {
            case 'MODELE_DOC':
                await this.getListeModelesDoc();
                break;
            case 'SITE_LABO':
                await this.getListeSitesLaboratoire();
                break;
            case 'NORME':
                await this.getListeNormes();
                break;
            case 'TYPE':
                await this.getListeTypes(affectation.domainCode);
                break;
            case 'SITE_PROD':
                await this.getListeProducteurs(affectation); //filtrer avec les labos séléctionnés si critère labo
                break;
            case 'REF':
                await this.getListeReference();
                break;
            case 'SITE_CLIENT':
                await this.getListeClients();
                break;
            case 'PROD':
                await this.getListeProduits();
                break;
            case 'CONFORMITE':
                await this.getListeConformités();
                break;
            case 'LANGUE':
                await this.getListeLangues();
                break;
            default:
                break;
        }
    }
    //* FTP / PREL
    async getListeSitesLaboratoire() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-laboratoires`);
        this.listes.SITE_LABO = res.data.items;
    }
    async getListeModelesDoc() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/template-impression`);
        this.listes.MODELE_DOC = res.data.items;
    }
    async getListeNormes() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/normes`);
        this.listes.NORME = res.data.items;
    }
    async getListeTypes(domaine) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/types/${domaine}/headers`);
        this.listes.TYPE = res.data.items;
    }
    async getListeProducteurs(affectation) {
        const critereLabo = affectation.listCriteres.find((x) => x.code === 'SITE_LABO');
        const url = `${__configuration.apiUrl}/massia/sites-producteurs/compteOuvertSiteComm`;
        let values = [];
        if (critereLabo) {
            values = critereLabo.values;
        }
        const params = {
            sitesIntervenant: values
        };
        const res = await this.$http.get(url, { params });
        this.listes.SITE_PROD = res.data;
    }
    getListeReference() {}
    async getListeClients() {
        //! revoir en fonction des comptes ouverts
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/sites-clients`);
        this.listes.SITE_CLIENT = res.data.items;
    }
    async getListeProduits() {
        //! vérifier le param application
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/produits`, {
            params: {
                application: 'laboraoire'
            }
        });
        this.listes.PROD = res.data.items;
    }
    getListeConformités() {}
    getListeLangues() {}
}

AffectationPortailService.$inject = ['$http'];
