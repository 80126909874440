import Image from './image.model';

export default class ImageFormController {
    static $inject = [
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'ImagesCommunicationService',
        'ImagesService',
        'notification',
        '$location',
        '$anchorScroll',
        '_',
        'MassiaApplicationService',
        '$uibModal',
        'MOPService'
    ];

    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        ImagesCommunicationService,
        ImagesService,
        notification,
        $location,
        $anchorScroll,
        _,
        MassiaApplicationService,
        $uibModal,
        MOPService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ImagesCommunicationService = ImagesCommunicationService;
        this.ImagesService = ImagesService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this._ = _;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$uibModal = $uibModal;
        this.ongletOpen = {
            isEnteteOpen: false,
            isCaracteristiqueOpen: false
        };
        this.ongletOpenVal = {
            isEnteteOpen: 'entete',
            isCaracteristiqueOpen: 'caracteristiques'
        };
        this.MOPService = MOPService;
    }

    $onInit() {
        this.MOPService.setMop([{ title: 'IMAGES.BREADCRUMBS.IMAGES_LIST', filename: 'Images.pdf', application: 'massia' }]);
        this.image = {};
        this.loading = false;
        this.form = {
            entete: {},
            caracteristiques: {}
        };

        this.reset();
    }

    $onDestroy() {
        this.$timeout.cancel(this.updateImageTimeout);
        this.MOPService.resetMop();
    }

    async reset() {
        this.startLoading();

        const data = {
            id: this.$stateParams.id
        };

        if (data.id) {
            try {
                data.entete = await this.ImagesService.getImageEnteteById(data.id);
                data.caracteristiques = await this.ImagesService.getImageValeursCaracteristiquesAssociationsById(data.id);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
                this.annuler();
            }
        }
        this.image = new Image(data);

        if (this.$stateParams.duplicate) {
            this.image.id = undefined;
            this.image.entete.code += '_copie';
        }

        this.ongletOpen = {
            isEnteteOpen: true,
            isCaracteristiqueOpen: false
        };

        this.$timeout.cancel(this.updateImageTimeout);
        this.updateImageTimeout = this.$timeout(() => this.stopLoading());
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();

            try {
                let id = false;
                if (this.image.id) {
                    await this.ImagesService.updateImage(this.image);
                    this.notification.success('IMAGES.UPDATED');
                    id = this.image.id;
                } else {
                    id = await this.ImagesService.createImage(this.image);
                    this.notification.success('IMAGES.CREATED');
                }
                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async appliquer() {
        const id = await this.sauvegarder();

        if (id && this.image.id) {
            this.reset();
        } else if (id) {
            this.$state.go('images.edit', { id: id });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    annuler() {
        if (this.$stateParams.idOrigine) {
            this.returnOrigine();
        } else {
            this.$state.go('images.list');
        }
    }

    returnOrigine() {
        const origine = this.$stateParams.idOrigine;
        const domaine = this.$stateParams.domaine;
        switch (domaine) {
            case '0':
                this.$state.go('commandes.edit', { id: origine });
                break;
            case '1':
                this.$state.go('societes.edit', { id: origine });
                break;
            case '2':
                this.$state.go('producteurs.edit', { id: origine, genre: 'producteurs' });
                break;
            case '3':
                this.$state.go('sites.edit', { id: origine, genre: 'commerciaux' });
                break;
            case '4':
                this.$state.go('clients.edit', { id: origine, genre: 'clients' });
                break;
            case '5':
                this.$state.go('fournisseurs.edit', { id: origine, genre: 'fournisseurs' });
                break;
            case '6':
                this.$state.go('transporteurs.edit', { id: origine, genre: 'transporteurs' });
                break;
            case '7':
                this.$state.go('personnes.edit', { id: origine, genre: 'personne' });
                break;
            case '8':
                this.$state.go('produits.edit', { id: origine });
                break;
            case '9':
                this.$state.go('chantiers.edit', { id: origine });
                break;
            case '10':
                this.$state.go('clients-divers.edit', { id: origine });
                break;
            case '11':
                this.$state.go('banques.edit', { id: origine });
                break;
            case '12':
                this.$state.go('caracteristiques.edit', { id: origine });
                break;
            case '13':
                this.$state.go('devises.edit', { id: origine });
                break;
            case '14':
                this.$state.go('paiements.edit', { id: origine });
                break;
            case '15':
                this.$state.go('motifs.edit', { id: origine });
                break;
            case '16':
                this.$state.go('normes.edit', { id: origine });
                break;
            case '17':
                this.$state.go('pays.edit', { id: origine });
                break;
            case '18':
                this.$state.go('prestations.edit', { id: origine });
                break;
            case '19':
                this.$state.go('tva.edit', { id: origine });
                break;
            case '20':
                this.$state.go('types.edit', { id: origine });
                break;
            case '21':
                this.$state.go('unites.edit', { id: origine });
                break;
            case '22':
                if (this.MassiaApplicationService.getApplication() == 'gestion') {
                    this.$state.go('lieux.edit', { id: origine });
                } else {
                    this.$state.go('lieux-donnee.edit', { id: origine });
                }
                break;
            case '23':
                this.$state.go('vehicules.edit', { id: origine });
                break;
            case '24':
                this.$state.go('offres.edit', { id: origine });
                break;
            case '25':
                this.$state.go('prospects.edit', { id: origine });
                break;
            case '26':
                this.$state.go('tickets.list.detail', { id: origine });
                break;
            case '27':
                this.$state.go('factures.edit', { id: origine });
                break;
            case '28':
                this.$state.go('daps.edit', { id: origine });
                break;
            case '29':
                this.$state.go('mesures.edit', { id: origine });
                break;
            case '30':
                this.$state.go('points-mesure.edit', { id: origine });
                break;
        }
    }

    filtrer() {
        this.$state.go('donnee-filtrees.list', { id: this.image.id, type: 1 });
    }

    checkValidity() {
        let validity = true;

        let firstScroll = true;
        if (!this.image.entete.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('entete');
                firstScroll = false;
            }

            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.$scope.$broadcast('imageEnteteValidations');
        }
        if (!this.image.caracteristiques.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('caracteristiques');
                firstScroll = false;
            }

            this.ongletOpen.isCaracteristiqueOpen = true;
            validity = false;
            this.$scope.$broadcast('imageCaracteristiquesValidations');
        }
        return validity;
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    onUpdateEntete(entete) {
        this.image.entete = entete;
    }

    async onUpdateImage() {
        await this.reset();
    }

    onUpdateCaracteristiques(caracteristiques) {
        this.image.caracteristiques = caracteristiques;
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
