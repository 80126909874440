import GammeProduit from '../gamme-produit-form/gamme.produit.model';

export default class GammeProduitDetailController {
    static $inject = ['$stateParams', '$state', 'GammesProduitsService', 'notification', 'ProduitsCommunicationService', 'MassiaApplicationService'];

    constructor($stateParams, $state, GammesProduitsService, notification, ProduitsCommunicationService, MassiaApplicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.GammesProduitsService = GammesProduitsService;
        this.notification = notification;
        this.ProduitsCommunicationService = ProduitsCommunicationService;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    async $onInit() {
        this.loading = false;
        this.form = {};
        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.startLoading();

        // let data = { id: this.$stateParams.id };
        // if (data.id) {
        //     try {
        //         data = await this.GammesProduitsService.getGammeProduitById(data.id);
        //     } catch (err) {
        //         if (err.data) this.notification.error(err.data);
        //         else throw err;
        //         this.annuler();
        //     }
        // }

        if (this.$stateParams.gamme) {
            this.gammeProduit = new GammeProduit(this.$stateParams.gamme);
        } else {
            const data = await this.GammesProduitsService.getGammeProduitById(this.$stateParams.id);
            this.gammeProduit = new GammeProduit(data);
        }

        this.stopLoading();
    }

    $onDestroy() {}

    goListProduit() {
        this.$state.go('gammes-produits.list', {}, { reload: true });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
