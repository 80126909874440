export default class FamillesService {
    static $inject = ['$http', '_'];

    constructor($http, _) {
        this.$http = $http;
        this._ = _;

        this.getUrlFamille = () => `${__configuration.apiUrl}/massia/familles`;
    }

    async getFamilles() {
        const url = this.getUrlFamille();
        const res = await this.$http.get(url);
        return res.data;
    }

    async getFamillesFiltrees() {
        const url = this.getUrlFamille() + '/filtre';
        const res = await this.$http.get(url);
        return res.data;
    }

    async getIdFor(codeFamille) {
        const familles = await this.getFamilles();
        const fam = this._.find(familles, { code: codeFamille });
        return fam.idFamille;
    }

    async getOneById(id) {
        const url = this.getUrlFamille() + `/${id}`;
        const res = await this.$http.get(url);

        return res.data;
    }

    async getFamilleByType(idType) {
        const url = this.getUrlFamille() + `/type/${idType}`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
