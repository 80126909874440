export default class SiteSpecificiteController {
    static $inject = [
        '$state',
        '$stateParams',
        'SitesService',
        'SitesTypesService',
        'ModalService',
        'notification',
        '$translate',
        '_',
        '$filter',
        'MassiaApplicationService'
    ];

    constructor($state, $stateParams, SitesService, SitesTypesService, ModalService, notification, $translate, _, $filter, MassiaApplicationService) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SitesService = SitesService;
        this.SitesTypesService = SitesTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this._ = _;
        this.$filter = $filter;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    async $onInit() {
        this.startLoading();
        this.isEditMode = false;
        this.stopLoading();

        // if (this.site.entete.isProducteur)
        //     this.entete = angular.copy(this.site.entete);
    }

    // $doCheck() {
    //     if (!angular.equals(this.entete, this.site.entete)) {
    //         this.onUpdate({
    //             entete: angular.copy(this.entete)
    //         });
    //     }
    // }

    $onDestroy() {}

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
