import { copy } from 'angular';
import { AggregationService } from '../../../../../../../../../libs/massia-component/massia-service/aggregation.service';

export default class ProductionController {
    /* @ngInject */
    constructor($scope, SyntheseEntityService, $state, AggregationService, $translate, $timeout, _, uiGridConstants, notification) {
        /**
         * @type ng.IScope
         */
        this.$scope = $scope;
        this.SyntheseEntityService = SyntheseEntityService;
        this.$state = $state;
        /**
         * @type AggregationService
         */
        this.aggregationSvc = AggregationService;
        this.$translate = $translate;
        this.$timeout = $timeout;
        /**
         * @type import('lodash').LoDashStatic
         */
        this._ = _;
        this.uiGridConstants = uiGridConstants;

        this.notification = notification;
    }

    $onInit() {
        try {
            this.columns = [];
            this.body = [];

            this.u = [
                this.$scope.$watch(
                    () => this.result.results,
                    () => {
                        if (this.result.results.length > 0) {
                            this.createTable(this.result.results);
                            this.grid.columnDefs = this.createColumnsDef(this.result.results);

                            this.fullscreen = false;
                            this.chart = this.createChartOptions();
                            this.formatChart();

                            this.$timeout(() => {
                                this.grid.data = this.result.results[0].grid.body;
                                if (this.setting) {
                                    this.setting.columns = [];
                                    this.gridApi.saveState.restore(this.$scope, this.setting);
                                }
                            }, 50);
                        }
                    }
                ),
                this.$scope.$watch(
                    () => this.filter,
                    () => {
                        if (this.gridApi) {
                            this.$timeout(() => {
                                this.gridApi.core.handleWindowResize();
                            }, 50);
                        }
                    },
                    true
                )
            ];

            this.loading = false;
        } catch (err) {
            this.notification.error({ error: err });
        }
    }

    $onDestroy() {
        if (this.u) {
            this._.forEach(this.u, (item) => item());
        }
    }

    generate() {
        return this.SyntheseEntityService.generate(this.$state.params.id, this.synthese, this.synthese.typeSynthese, [], []);
    }

    createScope() {}

    createColumnsDef(results) {
        const table = results[0].grid;
        const columns = this.getColumnDefs(table);
        return columns;
    }

    createTable(results) {
        const table = results[0].grid;
        if (!this.grid) {
            this.grid = {};
        }
        this.grid = {
            showGridFooter: true,
            enableGridMenu: true,
            showColumnFooter: true,
            columnFooterHeight: table.footer.length * 30,
            treeRowHeaderAlwaysVisible: true,
            enableSorting: true,
            enableFiltering: true,
            exporterCsvFilename: 'production.csv',
            exporterMenuPdf: false,
            exporterMenuSelectedData: false,
            exporterMenuVisibleData: false,
            excessColumns: 12,
            excessRows: 12,
            groupingShowCounts: false,
            rowTemplate: `
                <div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" 
                class="ui-grid-cell" ng-class="{ 'bold': row.entity.footer }" ng-style="{ 'color': row.entity.text, 'background-color': row.entity.background }" 
                ui-grid-cell></div>`,
            exporterExcelFilename: 'production.xlsx',
            exporterExcelSheetName: 'production',
            onRegisterApi: (gridApi) => {
                this.gridApi = gridApi;
                if (this.synthese && this.synthese.setting) {
                    this.$timeout(() => {
                        const setting = JSON.parse(this.synthese.setting.setting);
                        gridApi.saveState.restore(this.$scope, setting);
                    }, 1);
                }
                gridApi.grouping.on.aggregationChanged(this.$scope, (col) => {
                    if (!this.changeAgg) {
                        this.aggregationChanged(gridApi, col, table);
                    }
                });
                //gridApi.core.refresh();
            },
            columnDefs: [],
            data: []
        };
    }

    getColumnDefs({ header, footer }) {
        const res = [];
        this._.forEach(header, (r) => {
            const x = { ...r };
            if (x && x.grouping === null) {
                delete x.grouping;
            }
            if (x && x.filter) {
                x.filter.placeholder = this.$translate.instant(x.filter.placeholder);
            }
            if (x && x.filters) {
                x.filters = x.filters.map((f) => {
                    f.placeholder = this.$translate.instant(f.placeholder);
                    return f;
                });
            }
            x.minWidth = 100;
            x.width = 200;
            x.maxWidth = 1200;
            x.originalField = x.field;
            x.field = 'columns.' + x.field + '.value';
            x.headerCellClass = 'bg-primary';
            x.cellFilter = 'gridRound:2';
            x.footerCellFilter = 'gridRound:2';
            x.customTreeAggregationFinalizerFn = this.roundTreeAggregation;
            x.footerCellTemplate = this.createFooterTemplate(footer);
            if (!x.isAff) {
                x.aggregationType = (aggregation, fieldValue) => this.aggregationFooter(aggregation, fieldValue, footer);
            }
            res.push(x);
        });
        return res;
    }

    aggregationChanged(grid, col, table) {
        this.changeAgg = true;
        const finded = table.header.filter((x) => x.displayName === col.displayName);
        for (let i = 0; i < finded.length; i++) {
            const f = finded[i];
            (this.gridApi || grid).grouping.aggregateColumn(f.field, col.treeAggregation.type, col.treeAggregation.label);
        }
        this.changeAgg = false;
    }

    roundTreeAggregation(agg) {
        if (agg.type && agg.type === 'count' && agg.groupVal && agg.groupVal === 'Null') {
            agg.rendered = 'Aucun';
            agg.groupVal = 'Aucun';
            return;
        }
        if (!agg.type || agg.type === 'count') {
            return;
        }
        if (typeof agg.value === 'number') {
            agg.value = agg.value.round(2);
        }
        if (typeof agg.value === 'undefined' || agg.value === null) {
            agg.value = '';
            agg.label = '';
        }
        return (agg.rendered = agg.label + agg.value);
    }

    createFooterTemplate(footer) {
        let template = '';
        for (let i = 0; i < footer.length; i++) {
            template += `<div class="ui-grid-cell-contents" col-index="renderIndex" ng-style='col.getAggregationValue()[${i}].style'>{{ col.getAggregationValue()[${i}].val }}</div>`;
        }
        return template;
    }
    aggregationFooter(row, fieldValue, footer) {
        const col = fieldValue.colDef;
        const res = [];
        for (let i = 0; i < footer.length; i++) {
            const f = footer[i];
            let val = f.columns[col.originalField] ? f.columns[col.originalField].value : null;
            if (typeof val === 'number') {
                val = val.round(2);
            }
            res[i] = {
                val: `${f.lineName}: ${val}`,
                style: {
                    height: '30px',
                    background: f.background,
                    color: f.text
                }
            };
        }
        return res;
    }

    createChartOptions() {
        return {
            type: 'line',
            datasetOverride: [
                { backgroundColor: '#bbddff', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#78ac30', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#733707', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#a1ac09', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#64d5a0', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#d0885b', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#af3d86', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#c4e146', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#05ec2a', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#aecc6e', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#d23001', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#477149', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#d12e51', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#1d160b', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#15e436', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#a53db3', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#9ddf36', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#9ddf36', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#e3e91f', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#07ead6', borderColor: '#000000', borderWidth: 1 },
                { backgroundColor: '#46995b', borderColor: '#000000', borderWidth: 1 }
            ],
            options: {
                responsive: true,
                //maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                            type: 'linear',
                            display: true,
                            position: 'left'
                        }
                    ]
                }
            }
        };
    }

    formatChart() {
        this.chart.series = ['Production'];

        let produitProcesses = angular.copy(this.result.results[0].produitsProcess);
        produitProcesses = produitProcesses.sort((a, b) => {
            if (a.petitD > b.petitD) {
                return 1;
            } else if (a.petitD < b.petitD) {
                return -1;
            }

            if (a.grandD < b.grandD) {
                return -1;
            } else if (a.grandD > b.grandD) {
                return 1;
            }
            return 0;
        });

        this.chart.data = [produitProcesses.map((e) => e.quantite)];
        this.chart.labels = produitProcesses.map((x) => `${x.petitD}/${x.grandD}`);
    }

    // debug() {
    //     const data = angular.copy(this.result.results);
    //     this.result.results = [];
    //     this.result.results = data;
    // }
}
