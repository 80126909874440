import 'angular/angular';

//  Loading css files
import 'font-awesome/css/font-awesome.css';
import 'angular-toastr/dist/angular-toastr.css';
import './assets/fonts/massia-icons/style.css';
import './assets/css/massia.scss';
import './assets/css/rzslider.css';
//import './assets/css/textAngular.css';
import 'angular-bootstrap-nav-tree/dist/abn_tree.css';

// Getting application modules
import coreModule from './core/core.module';
import serviceModule from './app/services/service.module';
import gestionModule from './app/gestion.module';

angular.module('massia', [coreModule, serviceModule, gestionModule]);
