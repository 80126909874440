import FormuleDateController from './formule.date.controller';

export default {
    bindings: {
        modalInstance: '=',
        dateArchivage: '=',
        resolve: '<',
        close: '&',
        dismiss: '&'
    },
    template: require('./formule.date.html'),
    controller: FormuleDateController
};
