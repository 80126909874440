import SocietesAssocieesController from './societes.associees.controller';

export default {
    bindings: {
        societe: '<',
        form: '=',
        onUpdate: '&'
    },
    template: require('./societes.associees.html'),
    controller: SocietesAssocieesController
};
