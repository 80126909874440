import SyntheseEntitiesTraitementsController from './syntheseentities-traitements.controller';

export default {
    bindings: {
        graphData: '=',
        resultat: '=',
        calculs: '=',
        model: '=',
        lig: '=',
        onDelete: '&'
    },

    template: require('./syntheseentities-traitements.html'),
    controller: SyntheseEntitiesTraitementsController
};
