export default class VehiculesTypesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getAll() {
        const url = `${__configuration.apiUrl}/massia/domaines/Vehicule/types`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
