const serviceUrl = `${__configuration.apiUrl}/massia/seriestamis`;

export default class SeriesTamisService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getSeriesTamis(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getSerieTamisEnteteById(id) {
        const url = `${serviceUrl}/${id}/entete`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async createSerieTamis(serieTamis) {
        const result = await this.$http.post(serviceUrl, serieTamis);
        return result.data;
    }

    async updateSerieTamis(serieTamis) {
        const url = `${serviceUrl}/${serieTamis.id}`;
        return await this.$http.put(url, serieTamis);
    }

    async deleteSerieTamisById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async exportListeSeriesTamis(modeImpr, filters, sorts) {
        const url = `${serviceUrl}/print`;
        const res = await this.$http.get(url, {
            params: {
                modeImpr: JSON.stringify(modeImpr),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || [])
            },
            responseType: 'arraybuffer'
        });
        return res;
    }

    async getSerieTamisValeursCaracteristiquesAssociationsById(id) {
        const data = await this.getSerieTamisValeursCaracteristiquesById(id);
        const result = {};

        for (const element in data) {
            if (data[element] && !Array.isArray(data[element])) {
                if (data[element].value === 'True') {
                    data[element].value = 'true';
                }
                if (data[element].value === 'False') {
                    data[element].value = 'false';
                }

                if (data[element].value && data[element].value.key) {
                    result[data[element].id] = data[element].value.key;
                } else {
                    result[data[element].id] = data[element].value;
                }
            }
        }
        return result;
    }

    async getSerieTamisValeursCaracteristiquesById(id) {
        const url = `${serviceUrl}/${id}/valeurs-caracteristiques`;
        const res = await this.$http.get(url);
        const result = [];
        res.data.forEach(function (element) {
            const caract = {
                id: element.id,
                code: element.code,
                idNature: element.idNature,
                indice: element.indice,
                label: element.label,
                numericAdditionalInformation: element.numericAdditionalInformation,
                value: element.elementsValues || element.elementValue || element.values || element.value
            };
            result.push(caract);
        });
        return result;
    }

    async getUnites() {
        const uniteUrl = `${__configuration.apiUrl}/massia/unites`;
        const res = await this.$http.get(uniteUrl);

        return res.data;
    }
}
