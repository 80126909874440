import CritrereIdTableController from './critere.id.table.controller';

export default {
    bindings: {
        critere: '=',
        modeleSelection: '=',
        canUpdate: '<'
    },
    controller: CritrereIdTableController,
    template: require('./critere.id.table.tpl.html')
};
