import CritereValeur from '../../../../../../../../../models/criteres.valeur.models';

/* @ngInject */
export default class CritrereHeureController {
    constructor() {}
    $onInit() {
        if (!this.critere.modelSelectionCritereValeur[0]) {
            this.critere.modelSelectionCritereValeur[0] = new CritereValeur();
        }
        this.date1 = this._convertTimeToDate(this.critere.modelSelectionCritereValeur[0].valeur1);
        this.date2 = this._convertTimeToDate(this.critere.modelSelectionCritereValeur[0].valeur2);
    }
    $onDestroy() {}

    onChange(model, ref) {
        if (model === null) {
            return (this.critere.modelSelectionCritereValeur[0][ref] = null);
        }
        return (this.critere.modelSelectionCritereValeur[0][ref] = new Date(model).toLocaleTimeString());
    }

    _convertTimeToDate(date) {
        if (!date) {
            return null;
        }
        const s = date.split(':').map((x) => parseInt(x));
        return new Date().setHours(...s);
    }
}
