export default function Configuration($compileProvider, $translateProvider, localStorageServiceProvider, toastrConfig, $httpProvider) {
    $httpProvider.useApplyAsync(true); // Passer les requetes en async

    $translateProvider.useLoader('$translatePartialLoader', {
        urlTemplate: 'assets/i18n/{lang}/{part}.json'
    });
    $translateProvider.preferredLanguage(__configuration.preferredLanguage);
    localStorageServiceProvider.setPrefix('massia');

    toastrConfig.positionClass = 'toast-top-right';
    toastrConfig.preventOpenDuplicates = true;
    toastrConfig.closeButton = true;
    // on enlève les info de debug pour gagner en performance
    $compileProvider.debugInfoEnabled(false);
}

Configuration.$inject = ['$compileProvider', '$translateProvider', 'localStorageServiceProvider', 'toastrConfig', '$httpProvider'];
