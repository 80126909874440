import ProduitFormCoefficientkController from './produit.form.coefficientk.controller';

export default {
    bindings: {
        ongletOpen: '=',
        produit: '='
    },
    template: require('./produit.form.coefficientk.html'),
    controller: ProduitFormCoefficientkController
};
