import DonneeFiltreeUtilisateursConfig from './_config/donnee.filtree.utilisateurs.config';
import DonneeFiltreeUtilisateursRoutes from './_config/donnee.filtree.utilisateurs.routes';
import DonneeFiltreeUtilisateursComponent from './components/donnee-filtree-utilisateurs';
import DonneeFiltreeUtilisateursService from './services/donnee.filtree.utilisateurs.service';

const moduleName = 'app.massia.common.donneeFiltreeUtilisateurs';

angular
    .module(moduleName, [])
    .config(DonneeFiltreeUtilisateursConfig)
    .config(DonneeFiltreeUtilisateursRoutes)
    .service('DonneeFiltreeUtilisateursService', DonneeFiltreeUtilisateursService)
    .component('donneeFiltreeUtilisateurs', DonneeFiltreeUtilisateursComponent);

export default moduleName;
