export default class CodeAutomatique {
    constructor(data = {}) {
        this.id = data.id || null;
        this.DomaineLibelle = data.DomaineLibelle || null;
        this.DomaineCode = data.DomaineCode || null;
        this.DomaineId = data.DomaineId || null;
        this.EstCodeAutomatique = data.EstCodeAutomatique || false;
        this.Masque = data.Masque || null;
        this.EstCodeAffiche = data.EstCodeAffiche || false;
    }
}
