import './ext/ng-radial-gauge';
import { MassiaGaugeController } from './massia.gauge.controller';

const gaugeModule = 'app.libs.massia.gauge';
angular.module(gaugeModule, ['ngRadialGauge']).component('massiaGauge', {
    bindings: {
        id: '@',
        titre: '<',
        min: '<',
        max: '<',
        realValue: '<',
        info: '<',
        unit: '<',
        precision: '<',
        ranges: '<'
    },
    template: require('./massia.gauge.tpl.html'),
    controller: MassiaGaugeController
});
export default gaugeModule;
