import SyntheseModelesConfig from './_config/synthesemodeles.config';
import SyntheseModelesRoutes from './_config/synthesemodeles.routes';
import SyntheseModelesComponent from './components/synthesemodeles';
import SyntheseModelesCommunicationService from './services/synthesemodeles.communication.service';
import SyntheseModelesFormComponent from './components/synthesemodeles-form';
import SyntheseModeleValidator from './components/synthesemodeles-form/synthesemodeles.validator';
import SyntheseModeleDetailComponent from './components/synthesemodele-detail';
import SyntheseModelesOldService from './services/synthesemodeles.service';

const moduleName = 'app.massia.common.synthesemodelesold';

angular
    .module(moduleName, [])
    .config(SyntheseModelesConfig)
    //.config(SyntheseModelesRoutes)
    .service('SyntheseModelesOldService', SyntheseModelesOldService)
    .service('SyntheseModelesCommunicationService', SyntheseModelesCommunicationService);
/* .component('synthesemodeles', SyntheseModelesComponent)
    .component('synthesemodelesForm', SyntheseModelesFormComponent)
    .component('synthesemodeleDetail', SyntheseModeleDetailComponent)
    .factory('SyntheseModeleValidator', SyntheseModeleValidator) */

export default moduleName;
