import DocumentFormEnteteController from './document.form.entete.controller';

export default {
    bindings: {
        document: '<',
        form: '=',
        onUpdate: '&'
    },
    template: require('./document.form.entete.html'),
    controller: DocumentFormEnteteController
};
