import SyntheseModele from './synthesemodeles.model';

export default class SyntheseModeleFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'SyntheseModelesCommunicationService',
        'SyntheseModelesOldService',
        'notification',
        '$location',
        'MassiaApplicationService',
        '_',
        '$translate',
        'ModalService',
        'FamillesService'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        SyntheseModelesCommunicationService,
        SyntheseModelesOldService,
        notification,
        $location,
        MassiaApplicationService,
        _,
        $translate,
        ModalService,
        FamillesService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SyntheseModelesCommunicationService = SyntheseModelesCommunicationService;
        this.SyntheseModelesOldService = SyntheseModelesOldService;
        this.notification = notification;
        this.$location = $location;
        this.MassiaApplicationService = MassiaApplicationService;
        this._ = _;
        this.$translate = $translate;
        this.ModalService = ModalService;
        this.FamillesService = FamillesService;
    }

    async $onInit() {
        this.isEditMode = false;
        this.syntheseModele = {};
        this.loading = false;
        this.displayCriteres = [];

        this.dragOptions = {
            placeholder: 'table-row-ui-placeholder',
            revert: true,
            animation: 200,
            axis: 'y',
            'ui-preserve-size': true
        };

        // renvoie "laboratoire" ou "qse"
        this.activite = this.MassiaApplicationService.getApplication();

        this.getTypeSyntheses();
        await this.setSyntheseModele(this.$stateParams.id);
        this.unregisterReinit = this.SyntheseModelesCommunicationService.registerReinit(() => this.initForm());
        this.initForm();

        // pour la duplication
        if (this.$stateParams.duplicate) {
            this.syntheseModele.id = undefined;
            this.syntheseModele.libelle += '_copie';

            if (this.syntheseModele.listeSyntheseCritereElement && this.syntheseModele.listeSyntheseCritereElement.length > 0) {
                for (let index = 0; index < this.syntheseModele.listeSyntheseCritereElement.length; index++) {
                    const element = this.syntheseModele.listeSyntheseCritereElement[index];
                    element.id = undefined;
                }
            }
        }

        this.ongletOpen = {
            isEnteteOpen: true,
            isListeCriteresOpen: false
        };

        // rempli le iSteven de sléection des critères (si un type de modèle de synthèse est renseigné)
        await this.loadCriteres();
    }

    selectFamille(famille) {
        this.syntheseModele.idFamille = famille.idFamille;
        this.syntheseModele.libelleFamille = famille.n;
    }

    async getTypeSyntheses() {
        try {
            const tempList = await this.SyntheseModelesOldService.getTypeSyntheses();
            this.listeTypesSynthese = _.filter(tempList, { activite: this.activite });
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
        }
    }

    /*async changeTypeSynthese() {
        let typeSynthActu = _.find(this.listeTypesSynthese, {id: this.syntheseModele.typeDeSynthese});
        if(typeSynthActu.famille){
            this.syntheseModele.idFamille = await this.FamillesService.getIdFor(typeSynthActu.famille);
        }
    }*/

    async setSyntheseModele(syntheseModeleId) {
        if (syntheseModeleId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                const stillThatSyntheseModele = await this.SyntheseModelesOldService.getSyntheseModeleById(syntheseModeleId);
                this.syntheseModele = new SyntheseModele(stillThatSyntheseModele);
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('synthesemodeles.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.syntheseModele = new SyntheseModele();
        }
    }

    /* #region ----------------------------------------------------- iSteven CRITERES --------------------------------------------------- */
    async loadCriteres() {
        if (this.syntheseModele) {
            this.startLoading();
            // données brutes venant du back
            await this.getCriteresISteven();
            this.stopLoading();
        }
    }

    tickSelectedCritAffTra(line) {
        let toCheck = false;
        const index = this.syntheseModele.listeSyntheseCritereElement.findIndex(function (element, index, array) {
            element.idType = null;
            element.champCompo = null;
            if (element.idDonneeSelectionnable === line.idDonneeSelectionnable && element.idCaracteristique === line.idCaracteristique) {
                return true;
            }
            return false;
        });
        if (index > -1) {
            toCheck = true;
        }
        return toCheck;
    }

    async getCriteresISteven() {
        try {
            if (!this.activite) {
                throw new Error('Application not found');
            }
            const result = await this.SyntheseModelesOldService.getCriteresISteven(this.activite, 'criteres', true);

            for (let i = 0; i < result.length; i++) {
                const res = result[i];
                if (!res.msGroup && res.libelle) {
                    delete res.msGroup;
                }

                if (res.idType !== null) {
                    result.splice(i, 1);
                    i--;
                }
            }

            for (let i = 0; i < result.length; i++) {
                result[i].selected = this.tickSelectedCritAffTra(result[i]);
            }

            this.listIsteven = result;
        } catch (err) {
            console.log(err);
            this.notification.error(err.data);
        }
    }
    /* #endregion */

    /* #region ----------------------------------------------------- SAVE --------------------------------------------------------------- */
    // boutons de validation
    async sauvegarder() {
        if (this.syntheseModele.typeDeSynthese == null || this.syntheseModele.typeDeSynthese == 0) {
            this.notification.warning(this.$translate.instant('SYNTHMODS.NOTYPESYNTH'));
            return false;
        }

        if (this.syntheseModele.listeSyntheseCritereElement != null && this.syntheseModele.listeSyntheseCritereElement.length > 0) {
            if (this._.find(this.syntheseModele.listeSyntheseCritereElement, { titre: '' })) {
                this.notification.warning(this.$translate.instant('SYNTHMODS.NOTITRE'));
                return false;
            }

            for (let i = 0; i < this.syntheseModele.listeSyntheseCritereElement.length; i++) {
                this.syntheseModele.listeSyntheseCritereElement[i].position = i;
            }
        }

        if (this.checkValidity()) {
            this.startLoading();
            try {
                if (this.syntheseModele.id) {
                    const tmp = await this.updateSyntheseModele();
                    return this.syntheseModele.id;
                }
                const tmp = await this.createSyntheseModele();
                return tmp;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        } else {
            return false;
        }
    }

    async valider() {
        const returnedId = await this.sauvegarder();
        if (returnedId) {
            this.$state.go('synthesemodeles.edit', { id: returnedId });
        }
    }
    async validerEtFermer() {
        const success = await this.sauvegarder();
        if (success) {
            this.annuler();
        }
    }

    annuler() {
        this.$state.go('synthesemodeles.list');
    }

    async createSyntheseModele() {
        this.startLoading();
        try {
            const returnedId = await this.SyntheseModelesOldService.createSyntheseModele(this.syntheseModele);
            this.notification.success('SYNTHMODS.CREATED');
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateSyntheseModele() {
        this.startLoading();
        try {
            const returnedId = await this.SyntheseModelesOldService.updateSyntheseModele(this.syntheseModele);
            this.notification.success('SYNTHMODS.UPDATED');
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }
    /* #endregion */

    /* #region ----------------------------------------------------- Gestion lignes tableau critères ------------------------------------ */
    // pour ajouter un critère dans la liste avec ses valeurs par défaut
    addCritere(crit) {
        const newCritToAdd = {};
        newCritToAdd.id = -1;
        newCritToAdd.idDonneeSelectionnable = crit.idDonneeSelectionnable;
        newCritToAdd.idCaracteristique = crit.idCaracteristique;
        newCritToAdd.idType = crit.idType || null;

        newCritToAdd.position = this.syntheseModele.listeSyntheseCritereElement.length + 1;
        newCritToAdd.isObligatoire = false;

        newCritToAdd.libelleTotalChamp = crit.libelleDomaine + ' / ' + crit.libelleChamp; // valeur par défaut
        if (crit.idCaracteristique != null) {
            newCritToAdd.libelleTotalChamp = newCritToAdd.libelleTotalChamp + ' / ' + crit.libelleCaracteristique;
        }
        if (crit.champ === 17) {
            newCritToAdd.libelleTotalChamp = 'Produits / ' + newCritToAdd.libelleTotalChamp;
        }

        newCritToAdd.titre = newCritToAdd.libelleTotalChamp; // valeur par défaut
        newCritToAdd.champ = crit.champCompo || crit.champ;
        newCritToAdd.isUnique = true;
        this.syntheseModele.listeSyntheseCritereElement.push(newCritToAdd);
    }

    deleteCritere(crit) {
        if (this.syntheseModele.listeSyntheseCritereElement && this.syntheseModele.listeSyntheseCritereElement.length > 0) {
            const idx = this.syntheseModele.listeSyntheseCritereElement.findIndex(
                (e) => e.idDonneeSelectionnable === crit.idDonneeSelectionnable && e.idCaracteristique === crit.idCaracteristique
            );
            if (idx !== -1) {
                this.syntheseModele.listeSyntheseCritereElement.splice(idx, 1);
            }
        }
    }

    // faire monter ou descendre les éléments dans la liste
    // la position est important dans la traitement de génération de la synthèse
    moveElt(currentElt, sens) {
        const currentPos = currentElt.position;
        if (sens == 'up') {
            this._.find(this.syntheseModele.listeSyntheseCritereElement, { position: currentElt.position - 1 }).position = currentPos;
            this._.find(this.syntheseModele.listeSyntheseCritereElement, {
                idDonneeSelectionnable: currentElt.idDonneeSelectionnable,
                idCaracteristique: currentElt.idCaracteristique
            }).position = currentPos - 1;
        } else if (sens == 'down') {
            this._.find(this.syntheseModele.listeSyntheseCritereElement, { position: currentElt.position + 1 }).position = currentPos;
            this._.find(this.syntheseModele.listeSyntheseCritereElement, {
                idDonneeSelectionnable: currentElt.idDonneeSelectionnable,
                idCaracteristique: currentElt.idCaracteristique
            }).position = currentPos + 1;
        }
    }

    // à chaque fois qu'on sollicite le iSteven (on-item-click, on-select-all, on-select-none), la liste se remet à jour
    // la gestion des enregistrements, suppressions et modifications est géré dans le back lorsqu'on valide
    updateListCriteres() {
        //////////////////////////////////////////////////////////////////
        const listOriginal = this.listIsteven;

        const listFromBDD = this.syntheseModele.listeSyntheseCritereElement;

        for (let i = 0; i < listOriginal.length; i++) {
            const crit = listOriginal[i];

            if (crit.msGroup === true || crit.msGroup === false) {
                continue;
            }

            let index = -1;
            if (this.syntheseModele && listFromBDD && listFromBDD.length > 0) {
                index = listFromBDD.findIndex(function (x) {
                    if (x.idDonneeSelectionnable === crit.idDonneeSelectionnable && x.idCaracteristique === crit.idCaracteristique) {
                        return true;
                    }
                    return false;
                });
            }
            if (index === -1) {
                // s'il n'existe pas encore
                if (crit.selected) {
                    // cochée
                    this.addCritere(crit);
                } else if (!crit.selected) {
                    // décochée
                    // ne rien faire
                }
            } else {
                // s'il existe déjà
                if (crit.selected) {
                    // cochée
                    // ne rien faire
                } else if (!crit.selected) {
                    // décochée
                    this.deleteCritere(crit);
                }
            }
        }
    }
    /* #endregion */

    $onDestroy() {
        this.unregisterReinit();
    }

    initForm() {
        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
        } else {
            this.isEditMode = false;
        }
    }

    reinit() {
        this.setSyntheseModele(this.$stateParams.id);
        this.SyntheseModelesCommunicationService.raiseReinit();
    }

    checkValidity() {
        this.$scope.$broadcast('syntheseModeleValidations');
        return this.syntheseModele.isValid();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
