SiteCoordonneesValidator.$inject = ['validator'];

export default function SiteCoordonneesValidator(validator) {
    const instance = new validator();
    instance.ruleFor('codeBanque').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('guichet').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('compte').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('rib').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('iban').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    //instance.ruleFor('typeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    // instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    // instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    return instance;
}
