inputNbdecimalDirective.$inject = ['$filter', '$locale', 'Interpreteur'];

export default function inputNbdecimalDirective($filter, $locale, Interpreteur) {
    return {
        retrict: 'A',
        require: '?ngModel',
        scope: {
            arrondi: '@'
        },
        link: function (scope, element, attrs, ngModel) {
            if (!ngModel) {
                return;
            } // do nothing if no ng-model

            // get the number format
            const formats = $locale.NUMBER_FORMATS;
            const sepLocal = angular.copy(formats.DECIMAL_SEP);
            formats.DECIMAL_SEP = '.';

            // fix up the incoming number to make sure it will parse into a number correctly
            const fixNumber = function (number) {
                const parsed = parseFloat(number);
                if (parsed || parsed === 0) {
                    if (typeof number !== 'number') {
                        const numberStr = number.toString().replace(/\s/g, '').replace(',', '.');
                        number = parseFloat(numberStr);
                    }
                }
                return number;
            };

            // function to do the rounding
            const roundMe = function (number) {
                number = fixNumber(number);
                if (typeof number === 'number') {
                    // filtre d'accelis modifier a cause du bug des formules des essais et du calcul dans les compositions
                    //console.log($filter('number')(number, scope.arrondi));
                    // AB Modifier pour arrondir au pas plutôt en décimale
                    const objetToRound = [{ key: number, val: number, rounder: scope.arrondi }];
                    return Interpreteur.getRoundedValue(objetToRound);
                    // return number.toFixed(scope.arrondi);
                }
            };

            // Listen for change events to enable binding
            element.bind('blur', function () {
                element.val(roundMe(ngModel.$modelValue));
            });

            // push a formatter so the model knows how to render
            ngModel.$formatters.push(function (value) {
                if (value || value === 0) {
                    return roundMe(value);
                }
            });

            // push a parser to remove any special rendering and make sure the inputted number is rounded
            ngModel.$parsers.push(function (value) {
                return value;
            });
        }
    };
}
