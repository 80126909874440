const serviceUrl = `${__configuration.apiUrl}/massia/types`;

export default class TypesService {
    static $inject = ['$http', '$translate'];

    constructor($http, $translate) {
        this.$http = $http;
        /**
         * @type ng.translate.ITranslateService
         */
        this.$translate = $translate;
    }

    async getTypes(filters, sorts, pagination, nomApplication) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {}),
                appName: nomApplication
            }
        });
        return res.data;
    }

    async getTypeById(idType) {
        const url = `${serviceUrl}/${idType}`;

        const res = await this.$http.get(url);

        return res.data;
    }

    async deleteTypeById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async createType(typeInfo) {
        const result = await this.$http.post(serviceUrl, typeInfo);
        return result.data;
    }

    async updateType(id, type) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.put(url, type);
    }

    //typeId optionnel, passer null si c'est une creation de type
    //sinon l'id du type qui est en train d'être edité
    async codeExists(code, typeId) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }

    async getCaracteristiquesByType(id, filters, sorts, pagination) {
        /*let res = await this.$http.get(`${serviceUrl}/${id}/caracteristiques`, {
         params: {
         id: id,
         filters: JSON.stringify(filters || []),
         sorts: JSON.stringify(sorts || []),
         pagination: JSON.stringify(pagination || {})
         }
         });*/
        const res = await this.$http.get('mock/caracteristiques.json');
        return res.data;
    }

    async getSerieTamis() {
        const serieTamisUrl = `${__configuration.apiUrl}/massia/seriestamis`;
        const res = await this.$http.get(serieTamisUrl);
        return res.data;
    }

    async getThemes(codeDomaine) {
        const themeUrl = `${__configuration.apiUrl}/massia/themes/headers/${codeDomaine}`;
        const res = await this.$http.get(themeUrl);
        return res.data;
    }

    async exportListeTypes(modeImpr, filters, sorts, nomApplication) {
        const url = `${serviceUrl}/print`;
        const res = await this.$http.get(url, {
            params: {
                modeImpr: JSON.stringify(modeImpr),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                appName: JSON.stringify(nomApplication)
            },
            responseType: 'arraybuffer'
        });
        return res;
    }

    async getElementsFromCaracteristique(idCarac) {
        const url = `${__configuration.apiUrl}/massia/caracteristiques/elements/${idCarac}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getType(domain, famille) {
        const url = `${__configuration.apiUrl}/massia/types/types`;
        const res = await this.$http.get(url, {
            params: {
                domain: domain,
                famille: famille || ''
            }
        });

        return res.data;
    }

    async getTypeByDomaine(domaine) {
        const url = `${serviceUrl}/${domaine}/headers`;

        const res = await this.$http.get(url);

        return res.data;
    }

    async getSousTypesFournisseurs() {
        //enum sous types fournisseurs
        const data = await this.$http.get('assets/enum/sites/sousTypeFournisseur.json');
        return data.data;
    }

    async getEnumFormule() {
        const data = await this.$http.get('assets/enum/type/select.quantite.volume.enum.json');
        return data.data.map((x) => {
            x.libelle = this.$translate.instant(x.translate);
            return x;
        });
    }
}
