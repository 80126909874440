//Config
import FeuilleRouteConfig from './_config/feuille.route.config';
import FeuilleRouteRoutes from './_config/feuille.route.routes';

//Components
import FeuilleRouteComponent from './components/feuille-route';
import FeuilleRoutePopupComponent from './components/feuille-route-popup';

//services
import FeuilleRouteService from './services/feuille.route.service';

const moduleName = 'app.massia.gestion.plannings.feuille.route';

angular
    .module(moduleName, [])
    .config(FeuilleRouteConfig)
    .config(FeuilleRouteRoutes)
    .service('FeuilleRouteService', FeuilleRouteService)
    .component('feuilleRoute', FeuilleRouteComponent)
    .component('feuilleRoutePopup', FeuilleRoutePopupComponent);

export default moduleName;
