CaracteristiquesFormController.$inject = ['TypesService', 'PaginationService', 'notification'];

export default function CaracteristiquesFormController(TypesService, PaginationService, notification) {
    /* jshint validthis: true */
    const vm = this;

    // Propriétés
    vm.loading = true;
    vm.displayCaracteristiques = [];

    // Méthodes

    // Evènements
    vm.$onInit = onInit;
    /*vm.$onChanges = angular.noop;
     vm.$doCheck = angular.noop;
     vm.$postLink = angular.noop;
     vm.$onDestroy = angular.noop;*/

    // Méthodes publiques
    function onInit() {
        //si domaine prélevements et droit mobilité
        vm.loading = false;
        vm.caracteristiques = vm.caracteristiques || [];
        vm.onDelete = vm.onDelete || angular.noop;
        vm.onUpdate = vm.onUpdate || angular.noop;
        vm.onSetIdentification = vm.onSetIdentification || angular.noop;
        vm.disabled = vm.disabled || false;
        vm.normalise = vm.normalise || false;
    }

    vm.onSelectItem = function (data) {
        const idx = vm.caracteristiques.findIndex((x) => x.id === data.idCaracteristique);
        if (idx > -1) {
            if (vm.caracteristiques[idx].defaultValue === data.code) {
                vm.caracteristiques[idx].defaultValue = null;
                const e = vm.caracteristiques[idx].eltList.find((e) => e.selected === true);
                e.selected = !e.selected;
            } else {
                vm.caracteristiques[idx].defaultValue = data.code;
            }
        }
    };
}
