import SyntheseEntitiesFTPController from './syntheseentities.ftp.controller';

export default {
    bindings: {
        syntheseEntity: '=',
        forGeneration: '=',
        ongletOpen: '='
    },
    template: require('./syntheseentities.ftp.html'),
    controller: SyntheseEntitiesFTPController
};
