referentielValidator.$inject = ['validator', 'validationHelper'];

export default function referentielValidator(validator, validationHelper) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 15).withMessage('VALIDATION_TOO_LONG_15');
    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_100');
    instance.ruleFor('idType').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    return instance;
}
