export default class CoefficientkService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
        this.serviceUrl = `${__configuration.apiUrl}/massia/laboratoire/coefficientk`;
    }

    async getAll(filters, sorts, pagination) {
        const res = await this.$http.get(this.serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });
        return res.data;
    }

    async getOneById(id) {
        const res = await this.$http.get(this.serviceUrl + '/' + id);

        return res.data;
    }

    async createCoefficientk(data) {
        try {
            const res = await this.$http.post(this.serviceUrl, data);
            return res.data;
        } catch (e) {
            throw e.data;
        }
    }

    async updateCoefficientk(data) {
        try {
            const res = await this.$http.put(this.serviceUrl, data);
            return res.data;
        } catch (e) {
            throw e.data;
        }
    }

    async deleteCoefficientk(id) {
        return await this.$http.delete(this.serviceUrl + '/' + id);
    }

    async getOneByNormeAddition(idNorme, idAddition) {
        const res = await this.$http.get(this.serviceUrl + '/' + idNorme + '/' + idAddition);
        return res.data;
    }

    async getKPrime() {
        const res = await this.$http.get('assets/enum/coefk/kprime.enum.json');
        return res.data;
    }
}
