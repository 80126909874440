ReferentielsController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'ReferentielsService',
    'notification',
    '$transitions'
];

export default function ReferentielsController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    ReferentielsService,
    notification,
    $transitions
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    vm.selectedReferentielId = undefined;
    vm.lastSelected = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.referentiels = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    // vm.selectTva = selectTva;
    // vm.isTvaSelected = isTvaSelected;
    // vm.showDetail = showDetail;
    // vm.closeDetail = closeDetail;
    vm.loadReferentiels = loadReferentiels;
    vm.deleteReferentiel = deleteReferentiel;
    // vm.setSelected = setSelected;
    // vm.eraseAllFilters = eraseAllFilters;

    function init() {
        // $transitions.onSuccess({}, stateChangeSuccessFunc);
        // watchers.push($scope.$on('$destroy', dispose));
        // if (shouldSetSelectedId()) {
        //     vm.setSelected({ id: parseInt($state.params.id) });
        // }
    }

    init();

    function stateChangeSuccessFunc(event, toState) {
        if (!$state.params.id) {
            vm.setSelected();
        } else if ($state.params.id) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    // function setSelected(tva) {
    //     if (tva && tva.id) {
    //         vm.selectedTvaId = $stateParams.id;
    //         vm.lastSelected = vm.selectedTvaId;
    //         vm.slideMargin['margin-right'] = vm.slideWidth;
    //     } else {
    //         vm.selectedTvaId = undefined;
    //         vm.slideMargin['margin-right'] = '0px';
    //     }
    // }

    // function shouldSetSelectedId() {
    //     return $state && $state.current && $state.current.name && $state.current.name === 'tva.list.detail' && $stateParams && $stateParams.id;
    // }

    // function selectTva(tva) {
    //     if (tva && tva.id) {
    //         if (vm.selectedTvaId !== tva.id) {
    //             vm.selectedTvaId = tva.id;
    //             showDetail(tva.id);
    //         } else {
    //             vm.selectedTvaId = undefined;
    //             closeDetail();
    //         }
    //     }
    // }

    // function isTvaSelected(tva) {
    //     return tva && tva.id && vm.selectedTvaId === tva.id;
    // }

    // function showDetail(tvaId) {
    //     $state.go('tva.list.detail', { id: tvaId });
    // }

    // function closeDetail() {
    //     $state.go('tva.list');
    // }

    async function loadReferentiels(tableState) {
        startLoading();

        if ($state && $state.current && $state.current.name !== 'referentiels.list') {
            $state.go('referentiels.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.referentiels = [];

        try {
            const data = await ReferentielsService.getList(filters, sorts, pagination);
            vm.referentiels = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteReferentiel(referentiel) {
        if (referentiel && referentiel.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('REFERENTIELS.DELETE.TITLE', { code: referentiel.code }),
                modalMsg: $translate.instant('REFERENTIELS.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await ReferentielsService.deleteReferentielById(referentiel.id);
                    notification.success($translate.instant('REFERENTIELS.DELETE.SUCCESS'));

                    vm.selectedReferentielId = undefined;

                    if (vm.params.id) {
                        vm.state.go('referentiels.list');
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadReferentiels();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    // function eraseAllFilters() {
    //     loadTva();
    // }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
