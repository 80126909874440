import { getNatureNamesWithValues } from '../../services/natures.enum';

const enumWithValues = getNatureNamesWithValues();

CaracteristiqueValidator.$inject = ['validator'];

export default function CaracteristiqueValidator(validator) {
    const instance = new validator();

    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 15).withMessage('VALIDATION_TOO_LONG_15');
    instance
        .ruleFor('code')
        .matches(/^[a-zA-Z0-9_|]*$/)
        .withMessage('CODE_CHARACTERS_NOK');

    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_100');

    instance.ruleFor('idNature').greaterThan(-1).withMessage('VALIDATION_NOTEMPTY');

    instance.ruleFor('codeExists').notEqual(true).withMessage('VALIDATION_NOTEMPTY');
    return instance;
}
