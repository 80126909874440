export default function Configure($stateProvider) {
    $stateProvider.state('modelessaisiesp', {
        parent: 'common',
        url: '/modelessaisiesp',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.SECURITE_PERSONNEL" | translate }}'
        }
    });

    $stateProvider.state('modelessaisiesp.list', {
        url: '/list',
        template: '<modeles-saisies></modeles-saisies>',
        rights: { domain: 'modelessaisiesp', right: 'read' },
        ncyBreadcrumb: {
            parent: 'modelessaisiesp',
            label: '{{ "MODELES_SAISIES.BREADCRUMBS.MODELES_SAISIES_LIST" | translate}}'
        },
        navigation: {
            menu: 'securite_personnel_donnees',
            translate: 'MODELES_SAISIES.BREADCRUMBS.MODELES_SAISIES_LIST',
            order: 6
        },
        search: 'MODELES_SAISIES.BREADCRUMBS.MODELES_SAISIES_LIST'
    });

    $stateProvider.state('modelessaisiesp.list.detail', {
        url: '/{id}',
        template: '<modele-saisie-detail></modele-saisie-detail>',
        rights: { domain: 'modelessaisiesp', right: 'read' },
        ncyBreadcrumb: {
            parent: 'modelessaisiesp.list',
            label: '{{ "MODELES_SAISIES.BREADCRUMBS.MODELE_SAISIE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('modelessaisiesp.new', {
        url: '/new',
        template: '<modele-saisie-form></modele-saisie-form>',
        rights: { domain: 'modelessaisiesp', right: 'create' },
        ncyBreadcrumb: {
            parent: 'modelessaisiesp.list',
            label: '{{ "MODELES_SAISIES.BREADCRUMBS.MODELE_SAISIE_NEW" | translate}}'
        },
        search: 'MODELES_SAISIES.BREADCRUMBS.MODELE_SAISIE_NEW'
    });

    $stateProvider.state('modelessaisiesp.edit', {
        url: '/{id}/edit',
        template: '<modele-saisie-form></modele-saisie-form>',
        rights: { domain: 'modelessaisiesp', right: 'update' },
        ncyBreadcrumb: {
            parent: 'modelessaisiesp.list',
            label: '{{ "MODELES_SAISIES.BREADCRUMBS.MODELE_SAISIE_EDIT" | translate }}'
        }
    });

    $stateProvider.state('modelessaisiesp.newType', {
        url: '/new-type',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        params: { parentState: 'modelessaisiesp.new' },
        ncyBreadcrumb: {
            parent: 'modelessaisiesp.new',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        }
    });

    $stateProvider.state('modelessaisiesp.duplicate', {
        url: '/{id}/duplicate',
        template: '<modele-saisie-form></modele-saisie-form>',
        rights: { domain: 'modelessaisiesp', right: 'create' },
        ncyBreadcrumb: {
            parent: 'modelessaisiesp.list',
            label: '{{ "MODELES_SAISIES.BREADCRUMBS.MODELE_SAISIE_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });
}

Configure.$inject = ['$stateProvider'];
