import FacteurEmissionCo2Config from './_config/facteur.emission.co2.config';
import FacteurEmissionCo2Routes from './_config/facteur.emission.co2.routes';
import FacteurEmissionCo2Component from './components/facteur-emission-co2';
import FacteurEmissionCo2Service from './services/facteur-emission-co2.service';
import AddTransportModal from './components/add-transport-modal';
import TransportService from './services/transport.service';

const moduleName = 'app.massia.common.facteurEmissionCo2';

angular
    .module(moduleName, [])
    .config(FacteurEmissionCo2Config)
    .config(FacteurEmissionCo2Routes)
    .service('FacteurEmissionCo2Service', FacteurEmissionCo2Service)
    .service('TransportService', TransportService)
    .component('facteurEmissionCo2', FacteurEmissionCo2Component)
    .component('addTransportModal', AddTransportModal);

export default moduleName;
