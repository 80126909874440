(function (angular, undefined) {
    'use strict';

    angular.module('blocks.filters').filter('StringJoin', StringJoin);

    function StringJoin() {
        return function(input, separator) {
            if(input && angular.isArray(input) && separator) {
                return input.join(separator);
            }

            return input;
        };
    }

})(angular);

