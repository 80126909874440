import validatorBuilder from 'validator-builder';

let modeleSaisieValidator = null;

export default class ModeleSaisie {
    constructor(data = {}) {
        this.id = data.id;
        this.code = data.code;
        this.libelle = data.libelle;
        this.typeCMId = data.typeCMId;
        this.typeSMId = data.typeSMId;
        this.siteLaboId = data.siteLaboId;
        this.siteProdId = data.siteProdId;
        this.produitId = data.produitId;
        this.siteClientId = data.siteClientId;
        this.siteLabo = data.siteLabo;
        this.siteProd = data.siteProd;
        this.personneId = data.personneId;
        this.personne = data.personne;
        this.produit = data.produit;
        this.siteClient = data.siteClient;
        this.themeId = data.themeId;
        this.familleId = data.familleId;
        this.trameId = data.trameId;
        this.codeExists = data.codeExists || null;
        this.estSimplifie = data.estSimplifie;
        this.aideSaisie = data.aideSaisie;
        this.pointsMesures = data.pointsMesures || [];
        this.pointsMesuresIds = data.pointsMesuresIds || [];
        this.references = data.references || [];
        this.domaineCode = data.domaineCode;
        this.estCodeAuto = data.estCodeAuto;
        this.afficheCode = data.afficheCode;
        this.isMobile = data.isMobile;
        this.ordreMobile = data.ordreMobile;
        this.users = data.users || [];
        this.selected = undefined;
    }

    isValid() {
        modeleSaisieValidator = modeleSaisieValidator || validatorBuilder.getInstanceFor('ModeleSaisie');
        const validationResults = modeleSaisieValidator.validate(this);
        return validationResults.isValid;
    }
}
