//import {getPositionLegendeNamesByValues} from './../../../services/positions.legende.enum';
import MesureCourbeGranulo from './mesure.courbe.granulo.model';
//import AdditionnalInfoMesure from '../nature-additionnal-info-mesure/additionnal.info.mesure.model';
import rzModule from 'angularjs-slider';
import 'angularjs-slider/dist/rzslider.min.js';

export default class MesureCourbeGranuloController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'MesuresService',
        'ModalService',
        'notification',
        '$translate',
        '$filter',
        'ColorPickerService',
        '_'
    ];

    constructor($scope, $state, $stateParams, MesuresService, ModalService, notification, $translate, $filter, ColorPickerService, _) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.MesuresService = MesuresService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.$filter = $filter;
        this.ColorPickerService = ColorPickerService;
        this._ = _;
        this.newLigne = false;
        this.ongletOpen = {
            isEnteteOpen: false,
            isTracesOpen: false
        };
        this.ongletOpenVal = {
            isEnteteOpen: 'entete',
            isEnteteOpen: 'caracteristiques'
        };
        this.positionsLegende = [];
        this.stylesEchelle = [];
        this.stylesTrace = [];
        this.formesPoint = [];
        this.stylesFuseau = [];
    }

    async $onInit() {
        this.form = {
            entete: {},
            traces: {}
        };
        this.activeReference = 0;
        this.mesure = this.mesure || {};
        this.courbeGranulo = new MesureCourbeGranulo(this.mesure.courbeGranulo);
        this.loadPositionsLegende();
        this.loadStylesEchelle();
        this.loadStylesTrace();
        this.loadFormesPoint();
        this.loadStylesFuseau();
        this.loadCalculsStatsResultats();
        this.loadCalculsStatsFuseauReferences();
        if (this.courbeGranulo.listeTracesResultats && this.courbeGranulo.listeTracesResultats.length == 0) {
            this.ajouterLigneTraceResultat();
        }
        if (this.courbeGranulo.listeIndicesReferences && this.courbeGranulo.listeIndicesReferences.length == 0) {
            this.ajouterLigneIndiceReference();
        }
        if (this.courbeGranulo.listeTracesComposants && this.courbeGranulo.listeTracesComposants.length == 0) {
            this.ajouterLigneTraceComposant();
        }
        this.edit = { bChoisi: false, type: '', tableau: '', indexTab: 0, indexRef: 0 };
        this.couleur = { hue: 0, saturation: 100, luminosity: 50 };
        this.luminosity = 50;
        this.saturation = 100;
        this.alpha = 1;
        this.clickedPopoverIndex = undefined;
        // var _this = this;
        // this.$scope.$watch('$ctrl.luminosity', function (newValue, oldValue) {
        //     if (_this.edit && _this.couleur && (newValue != oldValue)) {
        //         _this.couleur.luminosity = newValue;
        //         _this.edit.bChoisi = true;
        //         //_this.edit = angular.copy(_this.edit); //force la mise à jour de la couleur du color-picker
        //         //_this.couleur = angular.copy(_this.couleur);
        //         //console.log('change luminosity', _this.couleur);
        //     }
        // });
        // this.$scope.$watch('$ctrl.saturation', function (newValue, oldValue) {
        //     if (_this.edit && _this.couleur && (newValue != oldValue)) {
        //         _this.couleur.saturation = newValue;
        //         _this.edit.bChoisi = true;
        //         //_this.edit = angular.copy(_this.edit); //force la mise à jour de la couleur du color-picker
        //         //_this.couleur = angular.copy(_this.couleur);
        //         //console.log('change saturation',_this.couleur);
        //     }
        // });
    }

    async loadCalculsStatsResultats() {
        this.calculsResultats = [];

        try {
            this.calculsResultats = await this.MesuresService.getCalculsStatsResultats();
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async loadCalculsStatsFuseauReferences() {
        this.calculsReferences = [];

        try {
            this.calculsReferences = await this.MesuresService.getCalculsStatsReferences();
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    supprimerToutesLignesTracesResultat() {
        this.courbeGranulo.listeTracesResultats.splice(0, this.courbeGranulo.listeTracesResultats.length);
        this.ajouterLigneTraceResultat();
    }

    ajouterLigneTraceResultat() {
        const couleurP = {
            hue: 0,
            saturation: 100,
            luminosity: 49,
            alpha: 1
        };
        const couleurT = {
            hue: 0,
            saturation: 100,
            luminosity: 50,
            alpha: 1
        };
        const couleurF = {
            hue: 0,
            saturation: 100,
            luminosity: 51,
            alpha: 1
        };
        const obj = {
            id: 0,
            idCalculStat: undefined,
            indice: undefined,
            formePoint: 0,
            couleurPoint: couleurP,
            couleurPointAuto: true,
            taillePoint: 1,
            valeurPoint: false,
            styleTrace: 1,
            couleurTrace: couleurT,
            couleurTraceAuto: true,
            epaisseurTrace: 1,
            couleurFuseau: couleurF,
            couleurFuseauAuto: true,
            styleFuseau: 0
        };
        this.courbeGranulo.listeTracesResultats.push(obj);
    }

    deleteTraceResultat(trace) {
        const indice = this.courbeGranulo.listeTracesResultats.indexOf(trace);
        this.courbeGranulo.listeTracesResultats.splice(indice, 1);
        if (this.courbeGranulo.listeTracesResultats && this.courbeGranulo.listeTracesResultats.length == 0) {
            this.ajouterLigneTraceResultat();
        }
    }

    supprimerToutesLignesIndicesReference() {
        this.courbeGranulo.listeIndicesReferences.splice(0, this.courbeGranulo.listeIndicesReferences.length);
        this.ajouterLigneIndiceReference();
    }

    supprimerToutesLignesTracesReference(indice) {
        const idxLig = this._.findIndex(this.courbeGranulo.listeIndicesReferences, { indice: indice });
        this.courbeGranulo.listeIndicesReferences[idxLig].listeTracesReferences.splice(
            0,
            this.courbeGranulo.listeIndicesReferences[idxLig].listeTracesReferences.length
        );
        this.ajouterLigneTraceReference(indice);
    }

    ajouterLigneTraceReference(indice) {
        const couleurP = {
            hue: 0,
            saturation: 100,
            luminosity: 49,
            alpha: 1
        };
        const couleurT = {
            hue: 0,
            saturation: 100,
            luminosity: 50,
            alpha: 1
        };
        const couleurF = {
            hue: 0,
            saturation: 100,
            luminosity: 51,
            alpha: 1
        };
        const obj = {
            id: 0,
            idCalculStat: undefined,
            indice: undefined,
            formePoint: 0,
            couleurPoint: couleurP,
            couleurPointAuto: true,
            taillePoint: 1,
            valeurPoint: false,
            styleTrace: 0,
            couleurTrace: couleurT,
            couleurTraceAuto: true,
            epaisseurTrace: 1,
            couleurFuseau: couleurF,
            couleurFuseauAuto: true,
            styleFuseau: 0
        };
        this.courbeGranulo.listeIndicesReferences[indice].listeTracesReferences.push(obj);
    }

    ajouterLigneIndiceReference() {
        const nouvIndice = this.courbeGranulo.listeIndicesReferences.length;
        const objIndice = {
            indice: nouvIndice,
            listeTracesReferences: []
        };
        this.courbeGranulo.listeIndicesReferences.push(objIndice);
        this.ajouterLigneTraceReference(nouvIndice);
    }

    deleteIndiceReference(trace) {
        const indice = this.courbeGranulo.listeIndicesReferences.indexOf(trace);
        this.courbeGranulo.listeIndicesReferences.splice(indice, 1);
        if (this.courbeGranulo.listeIndicesReferences && this.courbeGranulo.listeIndicesReferences.length == 0) {
            this.ajouterLigneIndiceReference();
        } else {
            for (let i = 0; i < this.courbeGranulo.listeIndicesReferences.length; i++) {
                this.courbeGranulo.listeIndicesReferences[i].indice = i;
                for (let j = 0; j < this.courbeGranulo.listeIndicesReferences[i].listeTracesReferences; j++) {
                    this.courbeGranulo.listeIndicesReferences[i].listeTracesReferences[j].indice = i;
                }
            }
        }
    }

    deleteTraceReference(indice, trace) {
        const index = this.courbeGranulo.listeIndicesReferences[indice].listeTracesReferences.indexOf(trace);
        this.courbeGranulo.listeIndicesReferences[indice].listeTracesReferences.splice(index, 1);
        if (
            this.courbeGranulo.listeIndicesReferences[indice].listeTracesReferences &&
            this.courbeGranulo.listeIndicesReferences[indice].listeTracesReferences.length == 0
        ) {
            this.ajouterLigneTraceComposant(indice);
        }
    }

    supprimerToutesLignesTracesComposant() {
        this.courbeGranulo.listeTracesComposants.splice(0, this.courbeGranulo.listeTracesComposants.length);
        this.ajouterLigneTraceComposant();
    }

    ajouterLigneTraceComposant() {
        const couleurP = {
            hue: 0,
            saturation: 100,
            luminosity: 49,
            alpha: 1
        };
        const couleurT = {
            hue: 0,
            saturation: 100,
            luminosity: 50,
            alpha: 1
        };
        const couleurF = {
            hue: 0,
            saturation: 100,
            luminosity: 51,
            alpha: 1
        };
        const obj = {
            id: 0,
            idCalculStat: undefined,
            indice: undefined,
            formePoint: 0,
            couleurPoint: couleurP,
            couleurPointAuto: true,
            taillePoint: 1,
            valeurPoint: false,
            styleTrace: 1,
            couleurTrace: couleurT,
            couleurTraceAuto: true,
            epaisseurTrace: 1,
            couleurFuseau: couleurF,
            couleurFuseauAuto: true,
            styleFuseau: 0
        };
        this.courbeGranulo.listeTracesComposants.push(obj);
    }

    deleteTraceComposant(trace) {
        const indice = this.courbeGranulo.listeTracesComposants.indexOf(trace);
        this.courbeGranulo.listeTracesComposants.splice(indice, 1);
        if (this.courbeGranulo.listeTracesComposants && this.courbeGranulo.listeTracesComposants.length == 0) {
            this.ajouterLigneTraceComposant();
        }
    }

    getColorFromPicker(coul) {
        if (coul) {
            const hexa = this.ColorPickerService.hslToRgb(coul.hue, coul.saturation, coul.luminosity);
            return 'rgba(' + hexa.red + ',' + hexa.green + ',' + hexa.blue + ', ' + (coul.alpha || coul.alpha === 0 ? coul.alpha : '1') + ')';
        }
        return 'rgb(0,0,0)';
    }

    initUpdateCouleur(trace, tableau, type, indexTab, indexRef) {
        //tableau : RCX (Résultat,Composant,Référence) ; type : TPF (Trait,Point,Fuseau)
        if (!this.clickedPopoverIndex) {
            //éviter l'evt click qd update
            this.edit.type = type;
            this.edit.tableau = tableau;
            this.edit.indexTab = indexTab;
            this.edit.indexRef = indexRef;
            switch (type) {
                case 'T':
                    this.couleur = angular.copy(trace.couleurTrace);
                    break;
                case 'P':
                    this.couleur = angular.copy(trace.couleurPoint);
                    break;
                case 'F':
                    this.couleur = angular.copy(trace.couleurFuseau);
                    break;
            }
            this.luminosity = this.couleur.luminosity;
            this.saturation = this.couleur.saturation;
            this.alpha = this.couleur.alpha;
            this.edit.bChoisi = false;
            //console.log('initUpdateCouleur',this.couleur);
            this.clickedPopoverIndex = tableau + indexRef + type + indexTab;
        }
    }

    cancelCouleur() {
        this.clickedPopoverIndex = undefined;
    }

    updateCouleur() {
        if (this.edit) {
            switch (this.edit.tableau) {
                case 'R':
                    switch (this.edit.type) {
                        case 'P':
                            this.courbeGranulo.listeTracesResultats[this.edit.indexTab].couleurPoint = angular.copy(this.couleur);
                            break;
                        case 'T':
                            this.courbeGranulo.listeTracesResultats[this.edit.indexTab].couleurTrace = angular.copy(this.couleur);
                            break;
                        case 'F':
                            this.courbeGranulo.listeTracesResultats[this.edit.indexTab].couleurFuseau = angular.copy(this.couleur);
                            break;
                    }
                    break;
                case 'X':
                    switch (this.edit.type) {
                        case 'P':
                            this.courbeGranulo.listeIndicesReferences[this.edit.indexTab].listeTracesReferences[this.edit.indexRef].couleurPoint =
                                angular.copy(this.couleur);
                            break;
                        case 'T':
                            this.courbeGranulo.listeIndicesReferences[this.edit.indexTab].listeTracesReferences[this.edit.indexRef].couleurTrace =
                                angular.copy(this.couleur);
                            break;
                        case 'F':
                            this.courbeGranulo.listeIndicesReferences[this.edit.indexTab].listeTracesReferences[this.edit.indexRef].couleurFuseau =
                                angular.copy(this.couleur);
                            break;
                    }
                    break;
                case 'C':
                    switch (this.edit.type) {
                        case 'P':
                            this.courbeGranulo.listeTracesComposants[this.edit.indexTab].couleurPoint = angular.copy(this.couleur);
                            break;
                        case 'T':
                            this.courbeGranulo.listeTracesComposants[this.edit.indexTab].couleurTrace = angular.copy(this.couleur);
                            break;
                        case 'F':
                            this.courbeGranulo.listeTracesComposants[this.edit.indexTab].couleurFuseau = angular.copy(this.couleur);
                            break;
                    }
                    break;
            }
        }
        //this.edit.bChoisi = false;// = {};
        this.clickedPopoverIndex = undefined;
    }

    selectCouleur(color, luminosity, saturation, alpha) {
        if (this.clickedPopoverIndex) {
            this.edit.bChoisi = true;

            if (color) {
                this.couleur.alpha = color.alpha ? color.alpha : 1;
                this.couleur.hue = color.hue;
                this.couleur.saturation = saturation && saturation > 0 ? saturation : color.saturation;
                this.couleur.luminosity = luminosity && luminosity > 0 ? luminosity : color.luminosity;

                if (color.hue <= 0 && color.saturation <= 0 && color.luminosity <= 0) {
                    this.couleur.saturation = 100;
                    this.couleur.luminosity = 50;
                    this.saturation = 100;
                    this.luminosity = 50;
                    this.alpha = 1;
                }
            } else if (saturation || luminosity) {
                this.couleur.saturation = saturation;
                this.couleur.luminosity = luminosity;
            }

            const eColorPicker = document.getElementsByTagName('color-picker')[0];

            const eColorChildren = Array.from(eColorPicker.childNodes);
            const eDivColorIndex = eColorChildren.findIndex(function (e) {
                return e.className && e.className === 'color-shadow';
            });
            const eButtonColorIndex = eColorChildren.findIndex(function (e) {
                return e.className && e.className === 'color';
            });

            if (eDivColorIndex >= 0 && eButtonColorIndex >= 0) {
                const hexa = this.ColorPickerService.hslToHex(this.couleur.hue, this.couleur.saturation, this.couleur.luminosity);
                eColorPicker.childNodes[eDivColorIndex].style.borderColor = '#' + hexa;
                eColorPicker.childNodes[eButtonColorIndex].style.backgroundColor = '#' + hexa;
            }
        }
    }

    async loadPositionsLegende() {
        this.positionsLegende = [];

        try {
            const positionsLegende = this.MesuresService.getPositionsLegende();

            angular.forEach(positionsLegende, (type, id) => {
                this.positionsLegende.push({
                    id: parseInt(id),
                    label: this.$translate.instant(`MESURES.POSITIONS_LEGENDE.${id}`)
                });
            });
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async loadStylesFuseau() {
        this.stylesFuseau = [];

        try {
            const stylesFuseau = this.MesuresService.getStylesFuseau();

            angular.forEach(stylesFuseau, (type, id) => {
                this.stylesFuseau.push({
                    id: parseInt(id),
                    label: this.$translate.instant(`MESURES.STYLES_FUSEAU.${id}`)
                });
            });
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async loadStylesTrace() {
        this.stylesTrace = [];

        try {
            const stylesTrace = this.MesuresService.getStylesTrace();

            angular.forEach(stylesTrace, (type, id) => {
                this.stylesTrace.push({
                    id: parseInt(id),
                    label: this.$translate.instant(`MESURES.STYLES_TRACE.${id}`)
                });
            });
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async loadStylesEchelle() {
        this.stylesEchelle = [];

        try {
            const stylesEchelle = this.MesuresService.getStylesEchelle();

            angular.forEach(stylesEchelle, (type, id) => {
                this.stylesEchelle.push({
                    id: parseInt(id),
                    label: this.$translate.instant(`MESURES.STYLES_ECHELLE.${id}`)
                });
            });
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async loadFormesPoint() {
        this.formesPoint = [];

        try {
            const formesPoint = this.MesuresService.getFormesPoint();

            angular.forEach(formesPoint, (type, id) => {
                this.formesPoint.push({
                    id: parseInt(id),
                    label: this.$translate.instant(`MESURES.FORMES_POINT.${id}`)
                });
            });
        } catch (ex) {
            this.notification.error(ex.data);
        }
    }

    async changeStyleTrace(trace, prop, ev) {
        if (trace.styleTrace === 0) {
            //repasser en auto si 'Aucun'
            trace[prop] = true;
        }
    }
    async changeStyleFuseau(trace, prop, ev) {
        if (trace.styleFuseau === 0) {
            //repasser en auto si 'Aucun'
            trace[prop] = true;
        }
    }
    async changeStylePoint(trace, prop, ev) {
        if (trace.formePoint === 0) {
            //repasser en auto si 'Aucun'
            trace[prop] = true;
        }
    }

    $doCheck() {
        if (!angular.equals(this.courbeGranulo, this.mesure.courbeGranulo)) {
            this.onUpdate({
                courbeGranulo: angular.copy(this.courbeGranulo)
            });
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    createPicker($color, couleur) {
        const hsl = $color.HSLA;
        couleur.hue = hsl[0];
        couleur.saturation = hsl[1];
        couleur.luminosity = hsl[2];
        couleur.alpha = hsl[3];
        this.$doCheck();
    }
}
