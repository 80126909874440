import ApiService from '../../../services/api.service';

export default class EssaisPlanifiesService {
    /* @ngInject */
    constructor(ApiService) {
        /**
         * @type ApiService
         */
        this.api = ApiService;
    }

    getAll(filters, sorts, pagination) {
        return this.api.get('massia/essais-planifier', {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || { skip: 0, take: 0 })
            }
        });
    }
}
