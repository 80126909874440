export default class FeuilleRoutePopupController {
    static $inject = ['$scope', '$stateParams', 'FeuilleRouteService', 'notification', 'ModalService', '$uibModal', 'moment', '$translate'];

    constructor($scope, $stateParams, FeuilleRouteService, notification, ModalService, $uibModal, moment, $translate) {
        this.$scope = $scope;
        this.$stateParams = $stateParams;
        this.FeuilleRouteService = FeuilleRouteService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
        this.moment = moment;
        this.$translate = $translate;
    }

    annuler() {
        this.modalInstance.close();
    }

    async appliquer() {
        this.print();
    }

    async confirmer() {
        this.print();
        this.annuler();
    }

    async $onInit() {
        this.chauffeurs = await this.FeuilleRouteService.getChauffeurs();
    }

    // async print() {
    //     try {
    //         let vm = this;

    //         var fileName = Date.now();
    //         let resultat = await vm.FeuilleRouteService.print(vm.chauffeurSelected.id, vm.date, fileName);

    //         let data = resultat.data;
    //         let headers = resultat.headers;

    //         headers = headers();

    //         var contentType = headers['content-type'];

    //         var linkElement = document.createElement('a');

    //         var blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
    //         var url = window.URL.createObjectURL(blob);
    //         linkElement.setAttribute('href', url);
    //         linkElement.setAttribute("download", fileName + '.pdf');

    //         var clickEvent = new MouseEvent("click", {
    //             "view": window,
    //             "bubbles": true,
    //             "cancelable": false
    //         });
    //         linkElement.dispatchEvent(clickEvent);
    //     } catch (ex) {
    //         this.notification.error(ex.data);
    //     } finally {

    //     }
    // }

    async print() {
        const fileName = Date.now();
        const resultat = await this.FeuilleRouteService.print(this.chauffeurSelected.id, this.date, fileName);

        const data = resultat.data;
        const status = resultat.status;
        let headers = resultat.headers;

        headers = headers();

        const contentType = headers['content-type'];

        const linkElement = document.createElement('a');
        try {
            const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
            const url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute('download', fileName + '.pdf');

            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(clickEvent);
        } catch (ex) {
        } finally {
        }
    }
}
