export default class GestionStocksTransfertController {
    static $inject = ['$stateParams', 'StocksCommunicationService', 'StocksService', 'notification', 'ModalService', '$uibModal', '$filter', '_'];

    constructor($stateParams, StocksCommunicationService, StocksService, notification, ModalService, $uibModal, $filter, _) {
        this.$stateParams = $stateParams;
        this.StocksCommunicationService = StocksCommunicationService;
        this.StocksService = StocksService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
        this.$filter = $filter;
        this._ = _;
    }

    async $onInit() {
        this.quantite = 0;
        this.idEmplacementDestinataire = 0;
        this.idProduitDestinataire = 0;
        this.idSiteProducteurDestinataire = 0;
        this.codeProduitDestinataire = '';
        this.getElementsSelectionnables();
    }

    change() {
        this.idProduitDestinataire = this.composant[0].produitId;
        this.idSiteProducteurDestinataire = this.composant[0].producteurId;
        this.codeProduitDestinataire = this.composant[0].produitCode;
        this.getZonesStockage();
    }

    annuler() {
        this.modalInstance.close(false);
    }

    async confirmer() {
        if (this.idProduitDestinataire) {
            //ajouter le nouveau transfert origine
            const transfOrig = {
                quantite: this.quantite,
                estSortie: true
            };
            if (!this.ligne.newTransferts) {
                this.ligne.newTransferts = [];
            }
            this.ligne.newTransferts.push(transfOrig);
            //recalculer la variation, le transfert et le stock final de la ligne
            if (this.ligne.variation.valeur) {
                this.ligne.variation.valeur = this.ligne.variation.valeur - this.quantite;
            } else {
                this.ligne.variation.valeur = -this.quantite;
            }
            if (this.ligne.stockFinal.valeur) {
                this.ligne.stockFinal.valeur = this.ligne.stockFinal.valeur - this.quantite;
            } else {
                this.ligne.stockFinal.valeur = -this.quantite;
            }
            if (this.ligne.transfert.valeur) {
                this.ligne.transfert.valeur = this.ligne.transfert.valeur - this.quantite;
            } else {
                this.ligne.transfert.valeur = -this.quantite;
            }
            //renvoyer le nouveau transfert destinataire
            const transfDest = {
                quantite: this.quantite,
                idProduit: this.idProduitDestinataire,
                idProducteur: this.idSiteProducteurDestinataire,
                idEmplacement: this.idEmplacementDestinataire
            };
            this.modalInstance.close(transfDest);
        } else {
            this.notification.error('STOCKS.ERROR_TRANSFERT');
            this.modalInstance.close(false);
        }
    }

    async getZonesStockage() {
        const produitEmplacements = this.emplacements.find(
            (x) => x.id == this.idProduitDestinataire && x.idProducteur == this.idSiteProducteurDestinataire
        );
        console.log(produitEmplacements);
        this.zones = produitEmplacements.emplacements;
    }

    async getElementsSelectionnables() {
        try {
            if (typeof this.produitSelect === 'undefined') {
                let indexToDelete = 0;
                for (let index = 0; index < this.produits.length; index++) {
                    const currentValue = this.produits[index];
                    if (currentValue.produitId == this.ligne.idProduit && currentValue.producteurId == this.ligne.idSiteProducteur) {
                        indexToDelete = index;
                    }
                }
                const allProduits = angular.copy(this.produits);
                allProduits.splice(indexToDelete, 1);

                // Distinct par produit / producteur
                const result = allProduits.reduce((prev, el) => {
                    if (prev.some((o) => o.producteurId == el.producteurId && o.produitId == el.produitId)) {
                        return prev;
                    }
                    return [...prev, el];
                }, []);

                this.produitSelect = await this.prepareListeElementsLies(result);
            }
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async prepareListeElementsLies(elementsATraiter) {
        const elementsSelectionnablesOrdonnes = this.$filter('orderBy')(elementsATraiter, 'producteurLibelle');
        let tmp = null;
        const temp = [];
        for (let index = 0; index < elementsSelectionnablesOrdonnes.length; index++) {
            const currentValue = elementsSelectionnablesOrdonnes[index];
            const toReturn = [];

            if (currentValue.hasOwnProperty('modeDeclenchement')) {
                currentValue.idComposant = currentValue.id;
                toReturn.push(currentValue);
            } else {
                //#region Gestion des groupes
                if (tmp == null) {
                    //creation du premier groupe
                    tmp = currentValue.producteurId;
                    toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
                } else {
                    if (tmp != currentValue.producteurId) {
                        //création des autres groupes
                        toReturn.push({ msGroup: false });
                        tmp = currentValue.producteurId;
                        toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
                    }
                }
                //#endregion
                currentValue.libelle = currentValue.produitCode + ' - ' + currentValue.produitLibelle;
                currentValue.valeur = false;
                currentValue.idComposant = currentValue.produitId;
                currentValue.code = currentValue.produitCode;
                currentValue.IdProducteur = currentValue.producteurId;
                toReturn.push(currentValue);

                if (index == elementsSelectionnablesOrdonnes.length - 1) {
                    toReturn.push({ msGroup: false });
                }
            }
            temp.push(toReturn);
        }
        const sorties = _.flatten(temp);
        return new Promise((resolve) => resolve(sorties));
    }
}
