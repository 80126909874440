//Config
import GammesProduitsConfig from './_config/gammes.produits.config';
import GammesProduitsRoutes from './_config/gammes.produits.routes';

//Services
import GammesProduitsService from './services/gammes.produits.service';

//Components
import GammesProduitsComponent from './components/gammes-produits';
import GammeProduitDetailComponent from './components/gamme-produit-detail';
import GammeProduitFormComponent from './components/gamme-produit-form';

//Validators
import GammeProduitValidator from './components/gamme-produit-form/gamme.produit.validator';

const moduleName = 'app.massia.common.gammes.produits';

angular
    .module(moduleName, [])
    .config(GammesProduitsConfig)
    .config(GammesProduitsRoutes)
    .service('GammesProduitsService', GammesProduitsService)
    .component('gammesProduits', GammesProduitsComponent)
    .component('gammeProduitForm', GammeProduitFormComponent)
    .component('gammeProduitDetail', GammeProduitDetailComponent)
    .factory('GammeProduitValidator', GammeProduitValidator);

export default moduleName;
