export default function Configure($stateProvider) {
    $stateProvider.state('feuille-route', {
        parent: 'gestion',
        url: '/feuille-route',
        //abstract: true,
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            skip: true,
            label: '{{ "LAYOUT.NAVIGATION.TRANSPORT" | translate }}'
        }
    });

    $stateProvider.state('feuille-route.list', {
        url: '/feuille-route',
        template: '<feuille-route></feuille-route>',
        rights: { domain: 'dmdlivrs', right: 'read' },
        ncyBreadcrumb: {
            skip: true,
            label: '{{ "FEUILLE_ROUTE.BREADCRUMBS.FEUILLE_ROUTE" | translate}}'
        },
        // on affiche la liste des motifs dans la navigation
        navigation: {
            menu: 'transport_plannings',
            translate: 'FEUILLE_ROUTE.BREADCRUMBS.FEUILLE_ROUTE',
            order: 13
        },
        // la liste des motifs fait partie des points d'entrée recherchables dans la navigation
        search: 'FEUILLE_ROUTE.BREADCRUMBS.FEUILLE_ROUTE'
    });
}

Configure.$inject = ['$stateProvider'];
