import validatorBuilder from 'validator-builder';

let presse3rModalImportValidator = null;

export default class Presse3rModalImport {
    constructor(data) {
        data = data || {};
        // this.id = data.id;
        this.libelle = data.libelle;
        this.extension = data.extension;
        this.famille = data.famille;
        // this.ordreImport = data.ordreImport;
    }
    isValid() {
        presse3rModalImportValidator = presse3rModalImportValidator || validatorBuilder.getInstanceFor('Presse3rModalImport');
        const validationResults = presse3rModalImportValidator.validate(this);
        return validationResults.isValid;
    }
}
