(function (angular, undefined) {
    'use strict';

    angular.module('blocks.smart.table')
        .directive('stFilterNumeric', ['$timeout', function ($timeout) {
            return {
                require: '^stTable',
                restrict: 'A',
                transclude: true,
                templateUrl: 'blocks/smart-table/filter-numeric.html',
                scope: {
                    predicate: '=stFilterNumeric',
                    predicateName: '@stFilterNumeric'
                },
                link: function (scope, element, attr, ctrl) {
                    var timer;
                    var unregisterDestroyEvent = scope.$on('$destroy', dispose);
                    var unregisterFilterSettedEvent = scope.$on('event:stPredicateObjectSetted', predicateObjectUpdated);
                    var slideboxEl, pinnedTableEl;
                    var first = true;
                    scope.filtering = false;
                    scope.currentFilter = '';
                    element.addClass('st-filter');

                    // pour récupérer l'info quand on modifie le tableState
                    scope.$watch(function () {
                        return ctrl.tableState().search;
                    }, function (newValue, oldValue) {
                        if (newValue.predicateObject && newValue.predicateObject[scope.predicateName]) {
                            scope.predicate = {
                                min: newValue.predicateObject[scope.predicateName],
                                max: newValue.predicateObject[scope.predicateName]
                            };
                            if (scope.predicate && first) {
                                if(first) {
                                    scope.predicate = {
                                        min: newValue.predicateObject[scope.predicateName].min,
                                        max: newValue.predicateObject[scope.predicateName].max
                                    };
                                    scope.setFilterInfos();
                                    first = false;
                                }
                            }
                        }
                    });

                    // On utilise le filtre custom
                    ctrl.setFilterFunction('acTableFilter');

                    scope.eraseFilter = function () {
                        // si une valeur est entrée
                        if (scope.predicate) {
                            // on l'efface
                            scope.predicate.min = undefined;
                            scope.predicate.max = undefined;
                        }
                        scope.currentFilter = '';
                        // on lance ensuite la recherche à vide (pour rappatrier tout)
                        ctrl.search(undefined, scope.predicateName);
                        scope.filtering = false;
                    };

                    scope.filter = function () {
                        if (scope.predicate && (isValidNumber(scope.predicate.min) || isValidNumber(scope.predicate.max))) {
                            scope.setFilterInfos();
                            scope.applyFilter();
                        }
                    };

                    scope.setFilterInfos = function () {
                        scope.currentFilter = scope.predicate ? angular.copy(scope.predicate) : '';
                        scope.filtering = scope.predicate && (isValidNumber(scope.predicate.min) || isValidNumber(scope.predicate.max));
                    };

                    scope.applyFilter = function () {
                        if (scope.predicate && (isValidNumber(scope.predicate.min) || isValidNumber(scope.predicate.max))) {
                            ctrl.search({
                                min: isValidNumber(scope.predicate.min) ? scope.predicate.min : undefined,
                                max: isValidNumber(scope.predicate.max) ? scope.predicate.max : undefined
                            }, scope.predicateName);
                        }
                    };

                    // Dans le cas d'une table pinned, on ferme le dropdown au scroll
                    var slidebox = globalHelpers.findAncestorByClassName(element[0], "slidebox");
                    var pinnedTable = globalHelpers.findAncestorByClassName(element[0], "st-table-pinned");

                    if (slidebox) {
                        slideboxEl = angular.element(slidebox);
                        slideboxEl.bind('scroll', onScroll);
                    }

                    if (pinnedTable) {
                        pinnedTableEl = angular.element(pinnedTable);
                        pinnedTableEl.bind('scroll', onScroll);
                    }

                    function isValidNumber(number) {
                        return parseFloat(number);
                    }

                    function onScroll(e) {
                        timer = $timeout(function () {
                            scope.filter.isOpen = false;
                        });
                    }

                    function predicateObjectUpdated(evt, predicateObject) {
                        if (angular.isObject(predicateObject) && angular.isObject(predicateObject[scope.predicateName])) {
                            var min = predicateObject[scope.predicateName].min;
                            var max = predicateObject[scope.predicateName].max;

                            scope.predicate = {
                                min: isValidNumber(min) ? min : undefined,
                                max: isValidNumber(max) ? max : undefined
                            };
                        } else {
                            scope.predicate = {};
                        }

                        scope.setFilterInfos();
                    }

                    function dispose() {
                        unregisterFilterSettedEvent();
                        unregisterDestroyEvent();
                        element.removeClass('st-filter');

                        if (timer) {
                            $timeout.cancel(timer);
                        }
                        if (slideboxEl !== undefined) {
                            slideboxEl.unbind('scroll', onScroll);
                        }
                        if (pinnedTableEl !== undefined) {
                            pinnedTableEl.unbind('scroll', onScroll);
                        }
                    }
                }
            };
        }]);
})(angular);