import ProduitFormProducteursValeurReferenceController from './produit.form.producteurs.valeur.reference.controller';

export default {
    bindings: {
        producteur: '=',
        produit: '='
    },
    template: require('./produit.form.producteurs.valeur.reference.html'),
    controller: ProduitFormProducteursValeurReferenceController
};
