import OffreProducteur from '../offre-producteur/offre.producteur.model';
import OffreProducteurLigneProduit from '../offre-producteur/offre.producteur.ligne.produit.model';
import { v4 as uuidv4 } from 'uuid';

export default class OffrePropositionController {
	static $inject = [
		'$state',
		'$stateParams',
		'OffresCommunicationService',
		'OffresService',
		'notification',
		'_',
		'ModalService',
		'$translate',
		'$uibModal',
		'moment',
		'globalizationManagementService'
	];

	constructor(
		$state,
		$stateParams,
		OffresCommunicationService,
		OffresService,
		notification,
		_,
		ModalService,
		$translate,
		$uibModal,
		moment,
		globalizationManagementService
	) {
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.OffresCommunicationService = OffresCommunicationService;
		this.OffresService = OffresService;
		this.notification = notification;
		this.$uibModal = $uibModal;
		this.moment = moment;
		this._ = _;
		this.totaux = {
			ht: [],
			tva: [],
			ttc: []
		};
		this.ModalService = ModalService;
		this.$translate = $translate;

		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async $onInit() {
		this.unregisterReinit = this.OffresCommunicationService.registerReinit(() => this.initForm());
		//this.lieux = await this.OffresService.getLieux();
		this.sitesCommerciaux = await this.OffresService.getSitesCommerciaux(this.offre.demande.idSociete);
		if (this.sitesCommerciaux.length > 0) {
			this.selectedSiteCommercial = this.sitesCommerciaux[0].id;
		}
		this.sitesProducteurs = await this.OffresService.getSitesProducteurs();
		this.selectedSiteProducteur = this.sitesProducteurs[0].id;
		// this.produitList = await this.OffresService.getProduits();
		this.selectedTypeES = '1';

		// Si la date du jour est apres la date de validité de l offre
		const m = this.moment();
		m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
		if (this.moment(this.offre.entete.dateValidite, this.dateFormat).isBefore(m)) {
			this.proposition.canOrder = false;
		} else {
			this.proposition.canOrder = true;
		}
		try {
			this.proposition.uuid = this.proposition.uuid || uuidv4();
		} catch (err) {
			console.error(err);
		}
		this.setPropositionAsTypeAhead();
	}

	async getLieux(valeur) {
		const lieux = await this.OffresService.getLieux(valeur);
		return lieux;
	}

	setPropositionAsTypeAhead() {
		this.lieu = {
			id: this.proposition.idLieu,
			code: this.proposition.codeLieu,
			libelle: this.proposition.libelleLieu,
			codeEtLib: this.proposition.idLieu ? this.proposition.libelleLieu + ' [' + this.proposition.codeLieu + ']' : ''
		};
	}
	setTypeAheadAs() {
		if (this.lieu) {
			this.proposition.idLieu = this.lieu.id;
			this.proposition.libelleLieu = this.lieu.libelle;
			this.proposition.codeLieu = this.lieu.code;
		}
	}

	videLieu() {
		this.lieu = {
			id: undefined,
			code: undefined,
			libelle: undefined,
			codeEtLib: ''
		};
		this.proposition.idLieu = undefined;
		this.proposition.libelleLieu = undefined;
		this.proposition.codeLieu = undefined;
	}

	$onDestroy() {
		this.unregisterReinit();
	}

	getHT() {
		let total = 0;
		this.totaux.ht.forEach(function (element) {
			total += element;
		}, this);

		return total;
	}

	getTVA() {
		let total = 0;
		this.totaux.tva.forEach(function (element) {
			total += element;
		}, this);

		return total;
	}

	getTTC() {
		let total = 0;
		this.totaux.ttc.forEach(function (element) {
			total += element;
		}, this);

		return total;
	}

	delete() {
		const proposition = angular.copy(this.proposition);
		this.offre.entete.propositions.splice(this.index, 1);
		for (let i = 0; i < this.offre.entete.propositions.length; i++) {
			const prop = this.offre.entete.propositions[i];
			if (
				prop.proposition &&
				((prop.proposition.id > -1 && prop.proposition.id === proposition.id) || prop.proposition.uuid === proposition.uuid)
			) {
				prop.proposition = null;
			}
		}
	}

	duplicate(liaison = false) {
		const duplicateProposition = {};
		const libelle = liaison ? '_RENDU' : '_copie';
		angular.copy(this.proposition, duplicateProposition);
		duplicateProposition.libelle = duplicateProposition.libelle + libelle;
		duplicateProposition.id = -1;
		duplicateProposition.codeCommande = null;
		duplicateProposition.chantier = null;
		duplicateProposition.canUpdate = true;
		duplicateProposition.proposition = null;
		duplicateProposition.propositionOrigineId = null;
		duplicateProposition.uuid = this.proposition.uuid;
		angular.forEach(
			duplicateProposition.producteurs,
			function (producteur, key) {
				producteur.id = -1;
				producteur.produits = producteur.produits.filter((x) => !x.idProduitLie && !x.idTransportLie);
				angular.forEach(
					producteur.produits,
					function (produit, key) {
						produit.id = -1;
					},
					this
				);
			},
			this
		);
		if (liaison) {
			duplicateProposition.isFranco = true;
			duplicateProposition.proposition = this.proposition;
			duplicateProposition.propositionOrigineId = this.proposition.id;
			this.proposition.proposition = duplicateProposition;
		}
		this.offre.entete.propositions.push(duplicateProposition);
	}

	updatePropositionPrixTotal() {
		let totalHT = 0;
		this.producteur.produits.forEach(function (ligne) {
			if (ligne.prixVente >= 0 && ligne.quantite >= 0) {
				totalHT += ligne.prixVente * ligne.quantite;
			}
		});

		this.totaux.ttc[this.indexproducteur] = totalHT * 1.2;
		this.totaux.tva[this.indexproducteur] = totalHT * 0.2;
		this.totaux.ht[this.indexproducteur] = totalHT;
	}

	openModal = function (size, parentSelector) {
		const _this = this;
		_this.sitesCommerciaux = this.sitesCommerciaux;

		if (_this.sitesCommerciaux.length > 0) {
			const modalInstance = this.$uibModal.open({
				animation: true,
				ariaLabelledBy: 'modal-title',
				ariaDescribedBy: 'modal-body',
				templateUrl: 'conditionDeVenteModalContent',
				controllerAs: '$ctrl',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						this.sitesCommerciaux = _this.sitesCommerciaux;
						this.selectedSiteCommercial = this.sitesCommerciaux[0].id;
						this.selectedTypeES = '1';
						this.$uibModalInstance = $uibModalInstance;

						this.ok = function () {
							this.$uibModalInstance.close({ siteCommercialId: this.selectedSiteCommercial, typeES: this.selectedTypeES });
						};
						this.cancel = function () {
							this.$uibModalInstance.close();
						};
					}
				]
			});

			const that = this;
			modalInstance.result.then(function (result) {
				if (result) {
					that.addConditionVente(result.siteCommercialId, result.typeES);
				}
			});
		} else {
			this.notification.error(this.$translate.instant('OFFRES.ALERT_SOCIETE_SANS_SITE_COMMERCIAL'));
		}
	};

	addConditionVente(siteCommercialId, typeES) {
		const siteCommercialIndex = this._.findIndex(this.sitesCommerciaux, { id: siteCommercialId });
		const newProducteur = new OffreProducteur(typeES, this.sitesCommerciaux[siteCommercialIndex]);
		const newProduit = new OffreProducteurLigneProduit();
		newProducteur.produits.push(newProduit);
		this.proposition.producteurs.push(newProducteur);
	}
}
