(function (angular, globalConfiguration, undefined) {
    'use strict';

    angular
        .module('blocks.i18n.globalization')
        .config(AppGlobalizationConfig);

    AppGlobalizationConfig.$inject = [
        '$httpProvider',
        '$translateProvider',
        'tmhDynamicLocaleProvider',
        'globalizationConstants'
    ];

    function AppGlobalizationConfig($httpProvider,
                                    $translateProvider,
                                    tmhDynamicLocaleProvider,
                                    globalizationConstants) {

        var languageConfig = getLanguageConfiguration();

        // gestion de l'intercepteur pour envoyer la langue au serveur
        $httpProvider.interceptors.push('globalizationInterceptorService');

        // gestion de la traduction
        $translateProvider
            .useLoader('$translatePartialLoader', {
                urlTemplate: 'assets/i18n/{lang}.{part}.json'
            })
            .addInterpolation('$translateMessageFormatInterpolation')
            .useSanitizeValueStrategy('escapeParameters')
            .registerAvailableLanguageKeys(languageConfig.iso, languageConfig.map)
            .preferredLanguage(languageConfig.default);

        // locale
        if (globalConfiguration && globalConfiguration.environment === 'production') {
            var appsUrl = globalConfiguration.baseUrl || '';
            tmhDynamicLocaleProvider.localeLocationPattern(appsUrl + 'assets/i18n/angular-locale_{{locale}}.js');
        }
        else {
            tmhDynamicLocaleProvider.localeLocationPattern('../' + globalizationConstants.libsLocation + '/angular-i18n/angular-locale_{{locale}}.js');
        }

        function getLanguageConfiguration() {
            var availableLanguagesIso = [];
            var availableLanguagesMap = {};
            angular.forEach(globalizationConstants.availableLanguages, function (language) {
                availableLanguagesIso.push(language.iso);
                availableLanguagesMap[language.code] = language.iso;
            });

            return {
                default: globalizationConstants.defaultLanguage,
                iso: availableLanguagesIso,
                map: availableLanguagesMap
            };
        }
    }
})(angular, window.globalConfiguration);