svcQueryTimer.$inject = ['$timeout', '$http', 'notification', '$translate', 'localStorageService', 'AuthConstant'];

export default function svcQueryTimer($timeout, $http, notification, $translate, localStorageService, AuthConstant) {
    // I provide a simple wrapper around the core $timeout that allows for
    // the timer to be easily reset.
    function Timer(duration, invokeApply, callback, callBackParams) {
        // Store properties.
        this._duration = duration || 0;
        this._invokeApply = invokeApply !== false;
        this._callback = callback;
        this._callbackParams = callBackParams;
        // I hold the $timeout promise. This will only be non-null when the
        // timer is actively counting down to callback invocation.
        this._timer = null;
    }
    // Define the instance methods.
    Timer.prototype = {
        // Set constructor to help with instanceof operations.
        constructor: Timer,
        // I determine if the timer is currently counting down.
        isActive: function () {
            return !!this._timer;
        },
        // I stop (if it is running) and then start the timer again.
        restart: function () {
            this.stop();
            this.start();
        },
        // I start the timer, which will invoke the callback upon timeout.
        start: function () {
            const self = this;
            // NOTE: Instead of passing the callback directly to the timeout,
            // we're going to wrap it in an anonymous function so we can set
            // the enable flag. We need to do this approach, rather than
            // binding to the .then() event since the .then() will initiate a
            // digest, which the user may not want.
            this._timer = $timeout(
                async function handleTimeoutResolve() {
                    try {
                        const waitingDatas = localStorageService.get(AuthConstant.MASSIA_WAITING_DATA_STORAGE);
                        if (waitingDatas) {
                            for (const key in waitingDatas) {
                                const waitingData = waitingDatas[key];
                                const res = await $http.post(
                                    waitingData.url,
                                    waitingData.items.map(function (e) {
                                        return e.ticks;
                                    })
                                );

                                if (res && res.data && res.data.length > 0) {
                                    let nbSuccess = 0;
                                    const errors = [];
                                    for (let i = 0; i < res.data.length; i++) {
                                        let item = null;
                                        if (res.data[i].isError === false || res.data[i].isError === true) {
                                            item = waitingData.items.find(function (e) {
                                                return e.ticks === res.data[i].ticks;
                                            });
                                            item.proceeded = true;
                                        }

                                        if (res.data[i].isError === true) {
                                            errors.push(item.target.factureDetail);
                                        }
                                        if (res.data[i].isError === false) {
                                            nbSuccess++;
                                        }
                                    }

                                    if (nbSuccess === waitingData.items.length) {
                                        notification.success($translate.instant(waitingData.success));
                                    } else if (errors && errors.length > 0) {
                                        let displayError = $translate.instant(waitingData.error);
                                        displayError = displayError + '\r\nRef. : ' + errors.join(', ');

                                        notification.error(displayError);
                                    }
                                }

                                waitingData.items = waitingData.items.filter(function (e) {
                                    return !e.proceeded;
                                });
                                if (!waitingData.items || waitingData.items.length <= 0) {
                                    delete waitingDatas[key];
                                    notification.success($translate.instant(waitingData.success));
                                }
                            }

                            localStorageService.set(AuthConstant.MASSIA_WAITING_DATA_STORAGE, waitingDatas);
                        }

                        if (self._callback) {
                            if (self._callbackParams) {
                                self._callback(self._callbackParams);
                            } else {
                                self._callback.call(null);
                            }
                        }
                    } finally {
                        self._timer = null;
                        self.start();
                    }
                },
                this._duration,
                this._invokeApply
            );
        },
        // I stop the current timer, if it is running, which will prevent the
        // callback from being invoked.
        stop: function () {
            $timeout.cancel(this._timer);
            this._timer = false;
        },
        // I clean up the internal object references to help garbage
        // collection (hopefully).
        teardown: function () {
            this.stop();
            this._callback = null;
            this._callBackParams = null;
            this._duration = null;
            this._invokeApply = null;
            this._timer = null;
        }
    };
    // Create a factory that will call the constructor. This will simplify
    // the calling context.
    function timerFactory(duration, invokeApply, callback, callBackParams) {
        return new Timer(duration, invokeApply, callback, callBackParams);
    }
    // Store the actual constructor as a factory property so that it is still
    // accessible if anyone wants to use it directly.
    timerFactory.Timer = Timer;
    // Set up some time-based constants to help readability of code.
    timerFactory.TWO_MINUTES = 2 * 60 * 1000;
    timerFactory.TEN_MINUTES = 9 * 60 * 1000;
    timerFactory.TEN_SECONDS = 10 * 1000;
    // Return the factory.
    return timerFactory;
}
