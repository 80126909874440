import validatorBuilder from 'validator-builder';

let siteObjectifValidator = null;

export default class SiteObjectif {
    constructor(data) {
        data = data || {};
        this.alerteStockPrix = data.alerteStockPrix || 80;
        this.alerteStockRotation1 = data.alerteStockRotation1 || 180;
        this.alerteStockRotation2 = data.alerteStockRotation2 || 365;
    }

    isValid() {
        siteObjectifValidator = siteObjectifValidator || validatorBuilder.getInstanceFor('SiteObjectif');
        const validationResults = siteObjectifValidator.validate(this);
        return validationResults.isValid;
    }
}
