import ApiService from '../../app/services/api.service';
import { Injectable } from '../decorators';
import { BaseHttpService } from '../interface/MassiaHttpService.interface';
import { User, UserDetail } from '../models/User';

@Injectable('UserService')
export class UserService extends BaseHttpService<User | UserDetail, User | UserDetail, User, User, User> {
    /* @ngInject */
    constructor(ApiService: ApiService) {
        super(ApiService);
        this.url = 'v2/massia/users';
    }
}
