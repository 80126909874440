import validatorBuilder from 'validator-builder';

let elementValidator = null;

export default class Element {
    constructor(data = {}) {
        this.code = data.code;
        this.nom = data.nom;
        this.typeId = data.typeId;

        this.codeExists = data.codeExists || null;
    }

    isValid() {
        elementValidator = elementValidator || validatorBuilder.getInstanceFor('Element');
        const validationResults = elementValidator.validate(this);
        return validationResults.isValid;
    }
}
