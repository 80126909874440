UnitesController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'UnitesService',
	'notification',
	'MassiaApplicationService',
	'$transitions',
	'MOPService'
];

// Du fait d'une limitation d'angularJS et de blocks.smart.table
// le controller doit être déclaré comme une fonction et non une classe
// on injecte donc directement les dépendances dans la fonction comme le veut angularJS
export default function UnitesController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	UnitesService,
	notification,
	MassiaApplicationService,
	$transitions,
	MOPService
) {
	const vm = this;
	const watchers = [];

	let previousTableState;
	let selectedUniteId = undefined;

	vm.loading = false;

	vm.params = $stateParams;
	vm.state = $state;

	vm.unites = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectUnite = selectUnite;
	vm.isUniteSelected = isUniteSelected;
	vm.showDetail = showDetail;
	vm.closeDetail = closeDetail;
	vm.loadUnites = loadUnites;
	vm.setSelected = setSelected;
	vm.deleteUnite = deleteUnite;
	vm.MOPService = MOPService;

	vm.$onInit = () => {
		vm.MOPService.setMop([{ title: 'UNITES.BREADCRUMBS.UNITES_LIST', filename: 'UnitesEtDevises.pdf', application: 'massia' }]);
		//écouter l'event lié au changement de route
		//une fois on change l'url, et on arrive sur la page "unites.list", on désélectionne la ligne sélectionnée (s'il y en a)
		$transitions.onSuccess({}, stateChangeSuccessFunc);
		watchers.push($scope.$on('$destroy', dispose));
		if (shouldSetSelectedId()) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	};

	vm.$onDestroy = () => {
		vm.MOPService.resetMop();
	};

	function stateChangeSuccessFunc(event, toState) {
		/*if (toState.name === 'unites.list') {
            selectedUniteId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }*/
		if (!$state.params.id) {
			vm.setSelected();
		} else if ($state.params.id) {
			vm.setSelected({ id: parseInt($state.params.id) });
		}
	}

	function setSelected(unite) {
		if (unite && unite.id) {
			selectedUniteId = $stateParams.id;
			vm.slideMargin['margin-right'] = vm.slideWidth;
		} else {
			selectedUniteId = undefined;
			vm.slideMargin['margin-right'] = '0px';
		}
	}

	function shouldSetSelectedId() {
		return $state && $state.current && $state.current.name && $state.current.name === 'unites.list.detail' && $stateParams && $stateParams.id;
	}

	function selectUnite(unite) {
		if (unite && unite.id) {
			if (selectedUniteId !== unite.id) {
				//vm.selectedUniteId !== unite.id) {
				//vm.selectedUniteId = unite.id;
				showDetail(unite.id);
			} else {
				//vm.selectedUniteId = undefined;
				closeDetail();
			}
		}
	}

	function isUniteSelected(unite) {
		return unite && unite.id && selectedUniteId === unite.id;
	}

	function showDetail(uniteId) {
		//vm.slideMargin['margin-right'] = vm.slideWidth;
		selectedUniteId = uniteId;
		$state.go('unites.list.detail', { id: uniteId });
	}

	function closeDetail() {
		selectedUniteId = undefined;
		$state.go('unites.list');
	}

	async function deleteUnite(unite) {
		if (unite && unite.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('UNITES.DELETE.TITLE', { code: unite.libelle }),
				modalMsg: $translate.instant('UNITES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await UnitesService.deleteUniteById(unite.id);
					notification.success($translate.instant('UNITES.DELETE.SUCCESS'));

					selectedUniteId = undefined;

					// si jamais la vue détail est affichée,
					// on va faire une rédirection vers la vue de la grille, adin de fermer la vue détail
					if (vm.params.id) {
						$state.go('unites.list');
					}
					previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);
					loadUnites();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function loadUnites(tableState) {
		startLoading();

		//quand on rafraîchit la grille, on revient sur "unites.list", et on ferme la vue détail d'un unite
		//sinon, on risque d'avoir la vue détail qui est ouverte, mais la ligne n'apparaît plus dans la grille
		if ($state && $state.current && $state.current.name !== 'unites.list') {
			$state.go('unites.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.unites = [];

		try {
			const data = await UnitesService.getUnites(filters, sorts, pagination);
			vm.unites = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
