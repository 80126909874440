const serviceUrl = `${__configuration.apiUrl}/massia/gestion/plannings/dispatching`;

export default class DispatchingService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getDispatching(date, siteId) {
        const filtres = {
            date: date,
            idSiteCommercial: siteId
        };
        const res = await this.$http.get(serviceUrl, {
            params: {
                filtres: JSON.stringify(filtres || {})
            }
        });

        return res.data;
    }

    async createPlanningTour(data) {
        const result = await this.$http.post(serviceUrl, data);
        return result.data;
    }

    async deletePlanningTourById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getSitesCommerciaux() {
        const siteUrl = `${__configuration.apiUrl}/massia/sites-commerciaux/headers`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async getZones() {
        const url = `${__configuration.apiUrl}/massia/zones/header`;
        const res = await this.$http.get(url);
        return res.data;
    }
}
