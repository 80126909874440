import { copy, forEach } from 'angular';
import { AggregationService } from '../../../../../../../../../libs/massia-component/massia-service/aggregation.service';
import ControleResultatControler from '../../../../../../../../gestion/controles/components/controle-resultat/controle.resultat.controller';

export default class ControlePeseeController {
    /* @ngInject */
    constructor(
        $scope,
        SyntheseEntityService,
        $state,
        AggregationService,
        $translate,
        $timeout,
        _,
        uiGridConstants,
        $filter,
        uiGridTemplate,
        MassiaRightsService,
        notification,
        uiGridExporterService,
        uiGridExporterConstants,
        MassiaAuthService,
        moment
    ) {
        /**
         * @type ng.IScope
         */
        this.$scope = $scope;
        this.SyntheseEntityService = SyntheseEntityService;
        this.$state = $state;
        /**
         * @type AggregationService
         */
        this.aggregationSvc = AggregationService;
        this.$translate = $translate;
        this.$timeout = $timeout;
        /**
         * @type import('lodash').LoDashStatic
         */
        this._ = _;
        this.uiGridConstants = uiGridConstants;
        this.$filter = $filter;
        this.uiGridTemplate = uiGridTemplate;
        this.MassiaRightsService = MassiaRightsService;
        this.notification = notification;
        this.uiGridExporterService = uiGridExporterService;
        this.uiGridExporterConstants = uiGridExporterConstants;
        this.MassiaAuthService = MassiaAuthService;
        this.moment = moment;
        this.saveFooter = [];
    }

    $onInit() {
        try {
            this.columns = [];
            this.body = [];
            this.u = [
                this.$scope.$watch(
                    () => this.result.results,
                    () => {
                        if (this.result.results.length > 0) {
                            this.createTable(this.result.results);
                            this.grid.columnDefs = this.createColumnsDef(this.result.results);
                            this.$timeout(() => {
                                this.grid.data = [...this.result.results[0].grid.body, ...this.addTraitementsRow()];
                                this.grid.footer = this.result.results[0].grid.footer.filter((x) => x.text === 'footer');

                                this._.forEach(this.grid.footer, (el) => {
                                    el.total = el.columns[this.grid.columnDefs[1].originalField].value.split(':')[1];
                                    el.nc = el.columns[this.grid.columnDefs[2].originalField].value.split(':')[1];
                                    el.nc_percent = el.columns[this.grid.columnDefs[3].originalField].value.split(':')[1];
                                });

                                if (this.setting) {
                                    console.log(this.setting);
                                    this.setting.columns = [];
                                    this.gridApi.saveState.restore(this.$scope, this.setting);
                                }
                                // console.log(this)
                            }, 50);

                            this.$timeout(() => {
                                // Save summary line after being treated by uiGrid
                                this.saveFooter.push(this.gridApi.grid.rows[this.gridApi.grid.rows.length - 1]);
                                this.saveFooter.push(this.gridApi.grid.rows[this.gridApi.grid.rows.length - 2]);
                                // Delete summary lines (array off the grid)
                                delete this.grid.data[this.grid.data.length - 1];
                                delete this.grid.data[this.grid.data.length - 2];
                                // delete this.gridApi.grid.rows[this.gridApi.grid.rows.length - 1];
                                // delete this.gridApi.grid.rows[this.gridApi.grid.rows.length - 2];
                            }, 50);
                        }
                    }
                ),
                this.$scope.$watch(
                    () => this.filter,
                    () => {
                        if (this.gridApi) {
                            this.$timeout(() => {
                                this.gridApi.core.handleWindowResize();
                            }, 50);
                        }
                    },
                    true
                )
            ];

            this.hasSelectableRight = this.MassiaRightsService.userHasRight('controlepesee', 'delete', 'laboratoire');
            this.loading = false;
            this.formatters = [];
        } catch (err) {
            this.error({ error: err });
        }
    }

    $onDestroy() {
        if (this.u) {
            this._.forEach(this.u, (item) => item());
        }
    }

    generateBl() {
        if (this.gridApi) {
            if (this.gridApi.selection) {
                this.gridApi.selection.clearSelectedRows();
                delete this.grid.dataBackup;
            }
            // Parametre en standby le temps de trouver du temps pour mieux le gerer
            //this.setting = this.gridApi.saveState.save();
        }
        this.generate();
    }

    createColumnsDef(results) {
        const table = results[0].grid;
        const columns = this.getColumnDefs(table);
        return columns;
    }

    createTable(results) {
        console.time('CreateTable');
        const table = results[0].grid;
        if (!this.grid) {
            this.grid = {};
        }
        this.grid = {
            showGridFooter: false,
            enableGridMenu: true,
            flatEntityAccess: true,
            showColumnFooter: false,
            treeRowHeaderAlwaysVisible: true,
            enableSorting: true,
            enableFiltering: true,
            exporterCsvFilename: 'controle_pesee.csv',
            exporterPdfFilename: 'controle_pesee.pdf',
            exporterMenuPdf: false,
            exporterMenuCsv: false,
            exporterMenuSelectedData: false,
            exporterMenuVisibleData: false,
            excessColumns: 12,
            keepLastSelected: false,
            excessRows: 30,
            groupingShowCounts: false,
            rowTemplate: `
                <div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" 
                class="ui-grid-cell" ng-class="{ 'bold': row.entity.footer }" ng-style="{ 'color': row.entity.text, 'background-color': row.entity.background }" 
                ui-grid-cell></div>`,
            exporterExcelFilename: 'controle_pesee.xlsx',
            exporterExcelSheetName: 'controle_pesee',
            exporterExcelHeader: (grid, workbook, sheet, docDefinition) => {
                this.formatter = {};
                // this can be defined outside this method
                const stylesheet = workbook.getStyleSheet();

                const aFormatDefn = {
                    font: { size: 11, fontName: 'Calibri', bold: true },
                    alignment: { horizontal: 'center' }
                    // border: { top: { style: 'thin' }, left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } }
                };
                const aFormatRedColor = {
                    font: { bold: true },
                    fill: {
                        type: 'pattern',
                        patternType: 'solid',
                        fgColor: 'B41E1E' // = Red color
                        // bgColor: 'A90606'
                    }
                };
                this.formatter.red = stylesheet.createFormat(aFormatRedColor);
                const aFormatOrangeColor = angular.copy(aFormatRedColor);
                aFormatOrangeColor.fill.fgColor = 'DE6C19'; // = Orange color
                this.formatter.orange = stylesheet.createFormat(aFormatOrangeColor);
                const aFormatGrayColor = angular.copy(aFormatRedColor);
                aFormatGrayColor.fill.fgColor = 'BFBFBF'; // = Gray color
                this.formatter.gray = stylesheet.createFormat(aFormatGrayColor);
                const formatterId = stylesheet.createFormat(aFormatDefn);

                const grouped = this.groupBy(grid.columns, (col) => col.colDef.group);
                const cols = [];
                let count = 0;

                grouped.forEach((element) => {
                    if (element.findIndex((x) => x.colDef?.group !== undefined) > -1) {
                        count += element.length;
                        if (element.findIndex((x) => x.colDef?.group !== null) < 0) {
                            this.addEmptyColumns(0, element.length, cols);
                        } else {
                            this.mergeSheetCells(count, element.length, sheet);
                            // Push group header
                            cols.push({ value: element[0].colDef.group, metadata: { style: formatterId.id } });
                            // Add empty column in case many cells are merged
                            if (element.length > 1) {
                                this.addEmptyColumns(1, element.length, cols);
                            }
                        }
                    }
                });
                sheet.data.push(cols);
            },
            exporterExcelCustomFormatters: (grid, workbook, docDefinition) => {
                grid.rows = [...grid.rows, ...this.saveFooter];
                this.saveFooter = [];

                const stylesheet = workbook.getStyleSheet();
                const stdStyle = stylesheet.createFontStyle({
                    size: 9,
                    fontName: 'Calibri'
                });
                const boldStyle = stylesheet.createFontStyle({
                    size: 9,
                    fontName: 'Calibri',
                    bold: true
                });

                this.formatters.date = stylesheet.createSimpleFormatter('date');
                this.formatters.bold = stylesheet.createFormat({
                    font: boldStyle.id,
                    fill: { type: 'pattern', patternType: 'solid', fgColor: 'E9E9E9' },
                    alignment: { wrapText: true }
                });
                this.formatters.boldNum = stylesheet.createFormat({
                    font: boldStyle.id,
                    fill: { type: 'pattern', patternType: 'solid', fgColor: 'E9E9E9' },
                    alignment: { wrapText: true },
                    format: '#,##0.00'
                });
                this.formatters.red = stylesheet.createFormat({
                    fill: { type: 'pattern', patternType: 'solid', fgColor: 'B41E1E' },
                    format: '#,##0.00'
                });
                this.formatters.orange = stylesheet.createFormat({
                    fill: { type: 'pattern', patternType: 'solid', fgColor: 'F88706' },
                    format: '#,##0.00'
                });
                this.formatters.grey = stylesheet.createFormat({
                    fill: { type: 'pattern', patternType: 'solid', fgColor: 'BFBFBF' },
                    format: '#,##0.00'
                });
                this.formatters.num = stylesheet.createFormat({
                    font: stdStyle.id,
                    format: '#,##0.00'
                });

                Object.assign(docDefinition.styles, this.formatters);
                return docDefinition;
            },

            exporterFieldFormatCallback: (grid, row, gridCol, cellValue) => {
                if (Number(angular.copy(cellValue))) {
                    cellValue = Number(cellValue);
                }
                const colName = gridCol.field.replace('columns.', '').replace('.value', '');
                const classCss = colName != '' ? row.entity.columns[colName]?.cellClass : null;

                if (classCss === 'bg-orange' && cellValue) {
                    return { value: Number(cellValue), metadata: { style: this.formatters.orange?.id } };
                }
                if (classCss === 'bg-red' && cellValue) {
                    return { value: Number(cellValue), metadata: { style: this.formatters.red?.id } };
                }
                if (classCss === 'bg-grey' && cellValue) {
                    return { value: Number(cellValue), metadata: { style: this.formatters.grey?.id } };
                }
                if (row.entity.lineName) {
                    if (Number(angular.copy(cellValue)) || cellValue == '0') {
                        return { value: Number(cellValue), metadata: { style: this.formatters.boldNum?.id } };
                    }
                    return { metadata: { style: this.formatters.bold?.id } };
                }
                if (colName.includes('donnéesenvaleurs')) {
                    return { value: Number(cellValue), metadata: { style: this.formatters.num?.id } };
                }
                if (colName.includes('date')) {
                    return { metadata: { style: this.formatters.date?.id } };
                }
                return null;
            },
            //   exporterColumnScaleFactor: 4.5,
            onRegisterApi: (gridApi) => {
                this.gridApi = gridApi;
                if (this.synthese && this.synthese.setting) {
                    this.$timeout(() => {
                        const setting = JSON.parse(this.synthese.setting.setting);
                        gridApi.saveState.restore(this.$scope, setting);
                    }, 10);
                }
                gridApi.grouping.on.aggregationChanged(this.$scope, (col) => {
                    if (!this.changeAgg) {
                        this.aggregationChanged(gridApi, col, table);
                    }
                });
                //gridApi.core.refresh();
            },
            isRowSelectable: (row) => {
                if (this.MassiaRightsService.userHasRight('controlepesee', 'delete', 'laboratoire')) {
                    return true;
                }
                return false;
            },

            columnDefs: [],
            data: [],
            exporterPdfDefaultStyle: { fontSize: 6 },
            exporterPdfTableStyle: { margin: [2, 30, 2, 2] },
            exporterPdfTableHeaderStyle: { fontSize: 7, bold: true, italics: true },

            exporterPdfFooter: (currentPage, pageCount) => {
                // return { text: currentPage.toString() + ' / ' + pageCount.toString(), style: 'footerStyle' };
                const cols = [
                    { text: `Créé par: ${this.user.nom} ${this.user.prenom}`, style: 'footerStyle', alignment: 'center' }
                    // { text: 'Producteur: Libelle - Code', style: 'footerStyle', alignment: 'center' },
                ];
                if (this.periode) {
                    cols.push({ text: this.periode, style: 'footerStyle', alignment: 'center' });
                }
                cols.push({ text: currentPage.toString() + ' / ' + pageCount.toString(), style: 'footerStyle', alignment: 'right' });
                return {
                    margin: 10,
                    columns: cols
                };
            },
            exporterPdfHeader: (currentPage, pageCount) => {
                if (currentPage === 1) {
                    return {
                        columns: [
                            // {

                            //     text: 'LOGO PRODUCTEUR',
                            //     style: 'headerStyle',
                            //     alignment: 'center',
                            // },
                            {
                                text: 'Rapport de conformité des pesées',
                                style: 'headerStyle',
                                alignment: 'center'
                            } /*,
                            {
                                text: ' Producteur info \n\r Période d\'évaluation',
                                alignment: 'center'

                            }*/
                        ]
                    };
                }
            },
            exporterPdfCustomFormatter: (docDefinition) => {
                docDefinition.styles.headerStyle = { fontSize: 16, bold: true };
                // docDefinition.styles.headerTitleStyle = { fontSize: 14, bold: true };
                docDefinition.styles.footerStyle = { fontSize: 7, bold: true };
                docDefinition.pageMargins = [5, 30, 5, 30];
                docDefinition.content[0].table = this.formatHeaders(docDefinition.content[0].table);
                return docDefinition;
            },
            exporterPdfOrientation: 'landscape',
            exporterPdfMaxGridWidth: 700,
            exporterPdfPageSize: 'A4'
        };
        console.timeEnd('CreateTable');
    }

    getColumnDefs({ header, footer }) {
        header = this.addHeaderTraitements(header, footer);
        const res = [];
        this._.forEach(header, (r) => {
            const x = { ...r };
            if (x && x.grouping === null) {
                delete x.grouping;
            }
            if (x && x.filter) {
                x.filter.placeholder = this.$translate.instant(x.filter.placeholder);
            }
            if (x && x.filters) {
                x.filters = x.filters.map((f) => {
                    f.placeholder = this.$translate.instant(f.placeholder);
                    return f;
                });
            }
            x.headerCellTemplate = this.uiGridTemplate.synthentsHeader;
            x.minWidth = 80;
            /* x.width = 200;
            x.maxWidth = 1200; */
            x.originalField = x.field;
            x.field = 'columns.' + x.field + '.value';
            x.headerCellClass = 'bg-primary';
            x.cellFilter = 'gridRound:4';
            x.cellClass = (grid, row, col, rowRenderIndex, colRenderIndex) => {
                const f = col.colDef.originalField;
                const o = row.entity.columns[f];
                if (!o) {
                    return;
                }
                if (o.type === 1) {
                    return `grid-cell-right ${o.cellClass}`;
                }
                if (o.cellClass) {
                    return o.cellClass;
                }
            };
            x.footerCellFilter = 'gridRound:4';
            x.customTreeAggregationFinalizerFn = this.roundTreeAggregation;
            // x.footerCellTemplate = this.createFooterTemplate(footer);
            if (!x.isAff) {
                x.aggregationType = (aggregation, fieldValue) => this.aggregationFooter(aggregation, fieldValue, footer);
            }
            res.push(x);
        });
        return res;
    }

    addHeaderTraitements(header, footer) {
        if (footer && footer.length > 0) {
            const titleTraitementCol = {
                displayName: ' ',
                enableColumnResizing: true,
                enableFiltering: false,
                field: 'traitements',
                filter: null,
                group: null,
                grouping: null,
                isAff: true,
                pinnedLeft: true,
                pinnedRight: false,
                sort: null,
                type: null
            };
            header.unshift(titleTraitementCol);
        }

        return header;
    }
    addTraitementsRow() {
        let rows = [];
        if (this.result.results[0].grid.footer && this.result.results[0].grid.footer.length > 0) {
            rows = this.result.results[0].grid.footer.map((x) => {
                let title = '';
                if (x.lineName.includes('Traitement')) {
                    title = x.lineName.split('/')[1];
                } else {
                    title = x.lineName.replace('/', ' ');
                }
                x.columns.traitements = {
                    cellClass: null,
                    id: 0,
                    type: 1,
                    value: title
                };
                return x;
            });
        }
        return rows;
    }
    aggregationChanged(grid, col, table) {
        const gridApi = grid || this.gridApi;
        this.changeAgg = true;
        const finded = table.header.filter((x) => x.displayName === col.displayName);
        for (let i = 0; i < finded.length; i++) {
            const f = finded[i];
            gridApi.grouping.aggregateColumn('columns.' + f.field + '.value', col.treeAggregation.type, col.treeAggregation.label);
        }
        this.changeAgg = false;
    }

    roundTreeAggregation = (agg) => {
        if (agg.type && agg.type === 'count' && agg.groupVal && agg.groupVal === 'Null') {
            agg.rendered = 'Aucun';
            agg.groupVal = 'Aucun';
            return;
        }
        if (!agg.type || agg.type === 'count') {
            return;
        }
        if (typeof agg.value === 'number') {
            agg.value = this.$filter('gridRound')(agg.value, 2);
        }
        if (typeof agg.value === 'undefined' || agg.value === null) {
            agg.value = '';
            agg.label = '';
        }
        return (agg.rendered = agg.label + agg.value);
    };

    createFooterTemplate(footer) {
        // let template = '';
        // for (let i = 0; i < footer.length; i++) {
        //     template += `
        //     <div class="ui-grid-cell-contents mas-jc-space-b" col-index="renderIndex"
        //         title="{{ col.getAggregationValue()[${i}].val }}"
        //         ng-style='col.getAggregationValue()[${i}].style'>
        //         <div class="mas-col-fit">{{ col.getAggregationValue()[${i}].name }}</div>
        //         <div class="mas-col-fit">{{ col.getAggregationValue()[${i}].val }}</div>
        //     </div>`;
        // }
        // return template;
        return '';
    }
    aggregationFooter = (row, fieldValue, footer) => {
        const col = fieldValue.colDef;
        const res = [];
        for (let i = 0; i < footer.length; i++) {
            const f = footer[i];
            let val = f.columns[col.originalField] ? f.columns[col.originalField].value : null;
            if (typeof val === 'number') {
                val = this.$filter('gridRound')(val, 2);
            }
            res[i] = {
                name: `${f.lineName}:`,
                val: val,
                style: {
                    height: '30px',
                    display: 'flex',
                    background: f.background,
                    color: f.text
                }
            };
        }
        return res;
    };

    getHeaderTemplate() {
        return `<div
        role="columnheader"
        ng-class="{ 'sortable': sortable, 'ui-grid-header-cell-last-col': isLastCol }"
        ui-grid-one-bind-aria-labelledby-grid="col.uid + '-header-text ' + col.uid + '-sortdir-text'"
        aria-sort="{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}">
        <div
          role="button"
          tabindex="0"
          ng-keydown="handleKeyDown($event)"
          class="ui-grid-cell-contents ui-grid-header-cell-primary-focus"
          col-index="renderIndex"
          title="TOOLTIP">
          <span title="{{ col.displayName CUSTOM_FILTERS }}" class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + '-header-text'">{{ col.displayName CUSTOM_FILTERS }}</span>
      
          <span
            ui-grid-one-bind-id-grid="col.uid + '-sortdir-text'"
            ui-grid-visible="col.sort.direction"
            aria-label="{{getSortDirectionAriaLabel()}}">
            <i
             ng-class="{ 'ui-grid-icon-up-dir': col.sort.direction == asc, 'ui-grid-icon-down-dir': col.sort.direction == desc, 'ui-grid-icon-blank': !col.sort.direction }"
             title="{{isSortPriorityVisible() ? i18n.headerCell.priority + ' ' + ( col.sort.priority + 1 )  : null}}"
             aria-hidden="true">
           </i>
           <sub
             ui-grid-visible="isSortPriorityVisible()"
             class="ui-grid-sort-priority-number">
             {{col.sort.priority + 1}}
           </sub>
          </span>
        </div>
      
        <div
          role="button"
          tabindex="0"
          ui-grid-one-bind-id-grid="col.uid + '-menu-button'"
          class="ui-grid-column-menu-button"
          ng-if="grid.options.enableColumnMenus && !col.isRowHeader  && col.colDef.enableColumnMenu !== false"
          ng-click="toggleMenu($event)"
          ng-keydown="headerCellArrowKeyDown($event)"
          ui-grid-one-bind-aria-label="i18n.headerCell.aria.columnMenuButtonLabel"
          aria-expanded="{{col.menuShown}}"
          aria-haspopup="true">
          <i
            class="ui-grid-icon-angle-down"
            aria-hidden="true">
            &nbsp;
          </i>
        </div>
      
        <div class="ui-grid-filter-custom" ui-grid-filter ng-hide="col.filterContainer === 'columnMenu'"></div>
      </div>`;
    }

    addEmptyColumns(start, stop, cols) {
        for (start; start < stop; start++) {
            cols.push({ value: '' });
        }
    }

    mergeSheetCells(count, length, sheet) {
        if (length > 1) {
            const cellsNumber = [];
            for (let i = count - length; i < count; i++) {
                cellsNumber.push(this.excelColumnNameByNumber(i));
            }
            sheet.mergeCells(`${cellsNumber[0]}1`, `${cellsNumber[cellsNumber.length - 1]}1`);
        }
    }

    groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    excelColumnNameByNumber(n) {
        const ordA = 'a'.charCodeAt(0);
        const ordZ = 'z'.charCodeAt(0);
        const len = ordZ - ordA + 1;

        let s = '';
        while (n >= 0) {
            s = String.fromCharCode((n % len) + ordA) + s;
            n = Math.floor(n / len) - 1;
        }
        return s;
    }

    deleteSelectedRows() {
        if (this.gridApi?.grid?.rows) {
            const hasSelectedRows = this.gridApi.grid.rows.findIndex((x) => x.isSelected) > -1;
            if (hasSelectedRows) {
                if (!this.grid.dataBackup) {
                    this.grid.dataBackup = this.gridApi.grid.rows;
                }
                this.gridApi.grid.rows = this.gridApi.grid.rows.filter((x) => !x.isSelected);
                this.gridApi.core.refresh();
            } else {
                this.notification.warning(this.$translate.instant('SYNTHENTS.NONE_SELECTED'));
            }
        }
    }

    restoreDeletedRows() {
        if (this.grid.dataBackup) {
            this.gridApi.grid.rows = this.grid.dataBackup;
            this.gridApi.core.refresh();
        } else {
            this.notification.warning(this.$translate.instant('SYNTHENTS.NO_BACKUP'));
        }
    }

    exportPdf() {
        // todo get user nom + prenom
        this.user = this.MassiaAuthService.getAuthenticatedUser();
        // todo get periode
        this.getPeriodeSelection();
        // todo get logo

        // todo get producteur

        const grid = this.gridApi.grid;
        grid.rows = [...grid.rows, ...this.saveFooter];
        this.saveFooter = [];

        const rowTypes = this.uiGridExporterConstants.ALL;
        const colTypes = this.uiGridExporterConstants.VISIBLE;
        this.uiGridExporterService.pdfExport(grid, rowTypes, colTypes);
    }

    formatHeaders(table) {
        const count = this.grid.columnDefs.filter((x) => x.visible !== false).length;
        table.body[0] = table.body[0].map((x, i) => {
            const head = this.grid.columnDefs[i];
            table.widths[i] = 550 / count;

            if (x.text === 'Données en valeurs/Ecart en poids') {
                x.text = head.group + '\n kg/m3';
            }
            if (x.text === 'Données en valeurs/Ecart en pourcentage') {
                x.text = head.group + '\n %';
            }
            if (x.text.includes('/')) {
                x.text = x.text.replace('/', '\n');
            }
            return x;
        });

        return table;
    }

    getPeriodeSelection() {
        let allCritere = [];
        this.synthese.blocSelections.forEach((bloc) => {
            allCritere = [...allCritere, ...bloc.modelSelection.modelSelectionCriteres];
        });
        const critDate = allCritere.find((x) => x.typeCritere == 5);
        if (critDate) {
            const now = this.moment().format('L');

            const end =
                critDate.modelSelectionCritereValeur[0].valeur2 != null && critDate.modelSelectionCritereValeur[0].valeur2 != ''
                    ? critDate.modelSelectionCritereValeur[0].valeur2
                    : now;
            const start = critDate.modelSelectionCritereValeur[0].valeur1;
            if (start && start != '') {
                this.periode = `Periode: ${start} - ${end}`;
            }
        }
    }
}
