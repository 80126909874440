(function (angular, globalHelpers, undefined) {
    'use strict';
    angular.module('blocks.context.menu').factory('ContextMenuService', ContextMenuService);
    angular.module('blocks.context.menu').directive('acContextMenu', AcContextMenu);
    AcContextMenu.$inject = ['$rootScope', '$document', 'ContextMenuService'];


    function ContextMenuService() {
        return {
            element: null,
            menuElement: null
        };
    }

    function AcContextMenu($rootScope, $document, ContextMenuService) {
        return {
            restrict: 'A',
            scope: {
                callback: '&acContextMenu',
                disabled: '&acContextMenuDisabled',
                closeCallback: '&acContextMenuClose',
                type: '@acContextMenuType'
            },
            link: function (scope, element, attrs) {
                var opened = false;
                var unbind = [];
                var unregisterDestroyEvent = scope.$on('$destroy', dispose);
                var unregisterCloseElementEvent = scope.$on('event:context-menu-close', closeElementEvent);
                // par défaut c'est right click
                var rightClickMenu = (scope.type === undefined || scope.type === 'right' || scope.type === 'both');
                var leftClickMenu = (scope.type === 'left' || scope.type === 'both');

                // On définit les events en fonction du type de directive (clic gauche ou droit)
                if (leftClickMenu) {
                    element.bind('click', contextMenuEvent);
                }
                if (rightClickMenu) {
                    element.bind('contextmenu', contextMenuEvent);
                    $document.bind('contextmenu', handleClickEvent);
                }

                // bind sur le document
                $document.bind('keyup', handleKeyUpEvent);
                // Firefox treats a right-click as a click and a contextmenu event
                // while other browsers just treat it as a contextmenu event
                $document.bind('click', handleClickEvent);
                $document.bind('scroll', handleScroll);

                // on enregistre les parents sur l'event de scroll pour fermer le menu au moindre scroll
                registerParentScroll(element[0].parentNode);

                function open(event, menuElement) {
                    menuElement.addClass('open');

                    var doc = $document[0].documentElement;
                    var body = $document[0].body;

                    var docLeft = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0),
                        docTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0),
                        elementWidth = menuElement[0].scrollWidth,
                        elementHeight = menuElement[0].scrollHeight;
                    var docWidth = doc.clientWidth + docLeft,
                        docHeight = doc.clientHeight + docTop,
                        totalWidth = elementWidth + event.pageX,
                        totalHeight = elementHeight + event.pageY,
                        left = Math.max(event.pageX - docLeft, 0),
                        top = Math.max(event.pageY - docTop, 0);

                    if (totalWidth > docWidth) {
                        left = left - (totalWidth - docWidth);
                    }

                    if (totalHeight > docHeight) {
                        top = top - (totalHeight - docHeight);
                    }

                    // Internet Explorer n'a pas la même logique de positionnement (il ne sépare pas la modale du body)
                    if (body.classList.contains('modal-open') && !globalHelpers.isInternetExplorer()) {
                        var modal = doc.getElementsByClassName('modal-dialog'),
                            modalContainer = doc.getElementsByClassName('modal');

                        if (modal.length > 0 && modal[0].offsetLeft > 0) {
                            left -= modal[0].offsetLeft;
                            top -= modal[0].offsetTop;
                        }

                        if (modalContainer.length > 0) {
                            left += modalContainer[0].scrollLeft;
                            top += modalContainer[0].scrollTop;
                        }
                    }

                    menuElement.css('top', top + 'px');
                    menuElement.css('left', left + 'px');
                    opened = true;
                }

                function close(menuElement) {
                    if (menuElement) {
                        menuElement.removeClass('open');
                    }

                    if (opened) {
                        scope.closeCallback();
                    }

                    opened = false;
                }

                function closeElementEvent(event, elt) {
                    if (element !== elt) {
                        closeElement();
                    }
                }

                function closeElement() {
                    if (!scope.disabled() && opened) {
                        close(ContextMenuService.menuElement);
                    }
                }

                function contextMenuEvent(event) {
                    if (!scope.disabled()) {
                        if (ContextMenuService.menuElement !== null) {
                            close(ContextMenuService.menuElement);
                        }
                        ContextMenuService.menuElement = angular.element(
                            document.getElementById(attrs.target)
                        );
                        ContextMenuService.element = event.target;

                        event.preventDefault();
                        event.stopPropagation();

                        // on indique aux autres menus de se fermer
                        $rootScope.$broadcast('event:context-menu-close', element);

                        scope.$applyAsync(function () {
                            scope.callback({$event: event});
                            open(event, ContextMenuService.menuElement);
                        });
                    }
                }

                function handleKeyUpEvent(event) {
                    if (!scope.disabled() && opened && event.keyCode === 27) {
                        scope.$applyAsync(function () {
                            close(ContextMenuService.menuElement);
                        });
                    }
                }

                function handleClickEvent(event) {
                    scope.$applyAsync(function () {
                        close(ContextMenuService.menuElement);
                    });
                }

                function handleScroll(event) {
                    if (!scope.disabled() && opened) {
                        scope.$applyAsync(function () {
                            close(ContextMenuService.menuElement);
                        });
                    }
                }

                function registerParentScroll(element) {
                    var elt;
                    if (element) {
                        elt = angular.element(element);
                        elt.bind('scroll', handleScroll);
                        unbind.push(elt);
                        registerParentScroll(elt[0].parentNode);
                    }
                }

                function dispose() {
                    closeElement();
                    $document.unbind('keyup', handleKeyUpEvent);
                    $document.unbind('click', handleClickEvent);
                    $document.unbind('scroll', handleScroll);

                    // on unbind tous les events de la généalogie
                    angular.forEach(unbind, function (elt) {
                        elt.unbind('scroll', handleScroll);
                    });

                    if (rightClickMenu) {
                        $document.unbind('contextmenu', handleClickEvent);
                    }

                    if (unregisterCloseElementEvent !== undefined) {
                        unregisterCloseElementEvent();
                    }

                    if (unregisterDestroyEvent !== undefined) {
                        unregisterDestroyEvent();
                    }
                }
            }
        };
    }
})(angular, window.globalHelpers);