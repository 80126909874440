export default class CompteOuvertsExportController {
    static $inject = ['$scope', '$state', '$stateParams', 'CompteOuvertsExportService', 'notification'];

    constructor($scope, $state, $stateParams, CompteOuvertsExportService, notification) {
        this.$scope = $scope;
        this.$state = $state;
        this.params = $stateParams;
        this.CompteOuvertsExportService = CompteOuvertsExportService;
        this.notification = notification;
        this.loading = false;
        this.outputParametres = [];
    }

    $onInit() {
        this.getGenres();
    }

    async getGenres() {
        this.startLoading();
        try {
            this.inputGenres = await this.CompteOuvertsExportService.getGenres();
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        } finally {
            this.stopLoading();
        }
    }

    async print() {
        this.startLoading();
        const fileName = Date.now();

        const resultat = await this.CompteOuvertsExportService.print(this.outputGenres);

        const data = resultat.data;
        const status = resultat.status;
        let headers = resultat.headers;

        headers = headers();

        const contentType = headers['content-type'];

        const fileExtention = '.xlsx';
        const linkElement = document.createElement('a');
        try {
            const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
            const url = window.URL.createObjectURL(blob);
            linkElement.setAttribute('href', url);
            linkElement.setAttribute('download', fileName + fileExtention);

            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false
            });
            linkElement.dispatchEvent(clickEvent);
        } catch (ex) {
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
