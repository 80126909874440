CaracteristiquesController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    '_',
    'CaracteristiquesService',
    'PaginationService',
    'notification',
    'ModalService',
    '$transitions',
    'MOPService'
];

export default function CaracteristiquesController(
    $scope,
    $state,
    $stateParams,
    $translate,
    _,
    CaracteristiquesService,
    PaginationService,
    notification,
    ModalService,
    $transitions,
    MOPService
) {
    /* jshint validthis: true */
    const vm = this;
    const watchers = [];

    let previousTableState;
    vm.selectedCaracteristiqueId = undefined;
    vm.lastSelected = undefined;

    // Propriétés
    vm.loading = true;
    vm.params = $stateParams;
    vm.state = $state;
    vm.caracteristiques = [];

    vm.selected = null;
    vm.lastSelectedId = undefined;

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];

    vm.domaines = [];
    vm.TooltipDomaines = '';

    // Méthodes
    vm.selectCaracteristique = selectCaracteristique;
    vm.isCaracteristiqueSelected = isCaracteristiqueSelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadCaracteristiques = loadCaracteristiques;
    vm.getDomaines = getDomaines;
    vm.setSelected = setSelected;
    vm.deleteCaracteristique = deleteCaracteristique;
    vm.duplicateCaracteristique = duplicateCaracteristique;
    vm.eraseAllFilters = eraseAllFilters;

    // Evènements
    // vm.$onInit = onInit;
    vm.$onChanges = angular.noop;
    vm.$doCheck = angular.noop;
    vm.$postLink = angular.noop;
    vm.$onDestroy = onDestroy;
    vm.MOPService = MOPService;

    // Méthodes publiques
    vm.$onInit = () => {
        vm.MOPService.setMop([
            { title: 'CARACTERISTIQUES.BREADCRUMBS.CARACTERISTIQUES_LIST', filename: 'TypesEtCaracteristiques.pdf', application: 'massia' }
        ]);
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        //$transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));
        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    };

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    function stateChangeSuccessFunc(event, toState) {
        if (!$state.params.id) {
            vm.setSelected();
        } else if ($state.params.id) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    function setSelected(caracteristique) {
        if (caracteristique && caracteristique.id) {
            vm.selectedCaracteristiqueId = $stateParams.id;
            vm.lastSelectedId = vm.selectedCaracteristiqueId;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            vm.selectedCaracteristiqueId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return (
            $state &&
            $state.current &&
            $state.current.name &&
            $state.current.name === 'caracteristiques.list.detail' &&
            $stateParams &&
            $stateParams.id
        );
    }

    function selectCaracteristique(caracteristique) {
        if (caracteristique && caracteristique.id) {
            if (vm.selectedCaracteristiqueId != caracteristique.id) {
                vm.selectedCaracteristiqueId = caracteristique.id;
                showDetail(caracteristique.id);
            } else {
                vm.selectedCaracteristiqueId = undefined;
                closeDetail();
            }
        }
    }

    async function getDomaines(caracteristique) {
        vm.domaines = caracteristique.domainesLibelles || [];

        if (vm.domaines.length > 0) {
            vm.TooltipDomaines = '<ul class="text-left">';
            vm.TooltipDomaines += _.map(vm.domaines, function (d) {
                return '<li>' + d + '</li>';
            })
                .slice(0, 5)
                .join('');
            vm.TooltipDomaines += vm.domaines.length > 5 ? '<li>...</li>' : '';
            vm.TooltipDomaines += '</ul>';
        } else {
            vm.TooltipDomaines = null;
        }
    }

    function isCaracteristiqueSelected(caracteristique) {
        return caracteristique && caracteristique.id && vm.selectedCaracteristiqueId === caracteristique.id;
    }

    function showDetail(caracteristiqueId) {
        $state.go('caracteristiques.list.detail', { id: caracteristiqueId });
    }

    function closeDetail() {
        $state.go('caracteristiques.list');
    }

    async function loadCaracteristiques(tableState) {
        startLoading();

        if ($state && $state.current && $state.current.name !== 'caracteristiques.list') {
            $state.go('caracteristiques.list');
        }

        if (tableState) {
            previousTableState = tableState;
        }

        const filters = PaginationService.getFilters(previousTableState);
        const sorts = PaginationService.getSorts(previousTableState);
        const pagination = PaginationService.getPagination(previousTableState);

        vm.caracteristiques = [];

        try {
            const data = await CaracteristiquesService.getCaracteristiques(filters, sorts, pagination);

            vm.caracteristiques = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteCaracteristique(caracteristique) {
        if (caracteristique && caracteristique.id) {
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant('CARACTERISTIQUES.DELETE.TITLE', { libelle: caracteristique.libelle }),
                modalMsg: $translate.instant('CARACTERISTIQUES.DELETE.MESSAGE'),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    if (await CaracteristiquesService.deleteCaracteristique(caracteristique)) {
                        notification.success($translate.instant('SUPPRIMER_SUCCESS', { entity: caracteristique.libelle }));
                        vm.selectedSocieteId = undefined;

                        if (vm.params.id) {
                            vm.state.go('caracteristiques.list');
                        }

                        previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);
                        loadCaracteristiques();
                    }
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function eraseAllFilters() {
        loadCaracteristiques();
    }

    function duplicateCaracteristique(caracteristique) {
        if (caracteristique && caracteristique.id) {
            $state.go('caracteristiques.duplicate', { id: caracteristique.id });
        }
    }

    // Méthodes privées
    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function onDestroy() {
        watchers.forEach((x) => x());
    }

    function dispose() {
        watchers.forEach((x) => x());
    }
}
