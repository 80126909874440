export default class ProduitFormQuotaBitumeController {
    static $inject = ['$scope', 'ProduitsService', 'notification', '_'];

    constructor($scope, ProduitsService, notification) {
        this.$scope = $scope;
        this.ProduitsService = ProduitsService;
        this.notification = notification;
        this._ = _;
    }

    $onInit = () => {
        this.unregister = this.$scope.$watch(
            () => this.isOpen,
            async () => {
                if (this.isOpen) {
                    await this.loadQuotaBitumes();
                }
            },
            true
        );
    };

    $onDestroy = () => {
        this.unregister();
    };

    async loadQuotaBitumes() {
        this.startLoading();

        try {
            if (this.produit) {
                this.quotaBitumes = await this.ProduitsService.getProduitQuotaBitumes(this.produit);
                await this.initiateSiteCommerciale();
            }
        } catch (ex) {
            this.notification.error(ex);
        } finally {
            this.stopLoading();
        }
    }

    async initiateSiteCommerciale() {
        const sitesCommerciaux = await this.refreshFiltreSitesCommerciaux();
        this.inputFiltreSites = angular.copy(sitesCommerciaux);
    }

    resetSiteCommerciale() {
        for (let index = 0; index < this.inputFiltreSites.length; index++) {
            const element = this.inputFiltreSites[index];
            element.selected = false;
        }
    }

    async preselectFiltreSitesCommerciaux(quotaBitume) {
        this.resetSiteCommerciale();
        for (let index = 0; index < quotaBitume.siteId.length; index++) {
            const element = quotaBitume.siteId[index];
            const filtre = this._.find(this.inputFiltreSites, { id: element });
            if (filtre) {
                filtre.selected = true;
            }
        }
    }

    async saveSelectedSites(quotaBitume) {
        quotaBitume.siteId = [];
        for (let i = 0; i < this.outputFiltreSites.length; i++) {
            const siteCom = this.outputFiltreSites[i];
            quotaBitume.siteId.push(siteCom.id);
        }
    }

    async refreshFiltreSitesCommerciaux() {
        let data;
        try {
            data = await this.ProduitsService.getSitesCommerciauxSansSociete();
        } catch (ex) {
            data = [];
        }
        return data;
    }

    activeEditMode(quotaBitume) {
        quotaBitume.isEditMode = true;
        this.isEditMode = true;
        this.preselectFiltreSitesCommerciaux(quotaBitume);
    }

    async desactiveEditMode(quotaBitume) {
        quotaBitume.isEditMode = false;
        this.isEditMode = false;
        await this.loadQuotaBitumes();
    }

    async updateQuotabitume(quotaBitume) {
        quotaBitume.isEditMode = false;
        this.isEditMode = false;
        await this.ProduitsService.updateProduitQuotabitumes(this.produit, quotaBitume);
        await this.loadQuotaBitumes();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
