import ChantierComposantController from './chantier.composant.controller';

export default {
    bindings: {
        id: '=',
        domaine: '=',
        isOpen: '='
    },
    template: require('./chantier.composant.html'),
    controller: ChantierComposantController
};
