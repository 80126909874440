export default function Configure($stateRegistry, MassiaApplicationService, $rootScope) {
    $stateRegistry.register({
        name: 'synthese.model',
        url: '/modeles',
        abstract: true,
        template: '<ui-view></ui-view>',
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            skip: true,
            label: '{{ "LAYOUT.NAVIGATION.ANALYSE" | translate }}'
        }
    });
    $stateRegistry.register({
        name: 'synthese.model.new',
        url: '/new',
        template: '<synthesemodeles-form></synthesemodeles-form>',
        rights: { domain: 'synthadmin', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher synthesemodeles > ajouter
            parent: 'synthese.model.list',
            label: '{{ "SYNTHMODS.BREADCRUMBS.SYNTHMOD_NEW" | translate}}'
        },
        // l'ajout d'une synthesemodele fait partie des points d'entrée recherchables dans l'application
        search: 'SYNTHMODS.BREADCRUMBS.SYNTHMOD_NEW'
    });
    $stateRegistry.register({
        name: 'synthese.model.list.detail',
        url: '/{id}',
        template: '<synthesemodele-detail></synthesemodele-detail>',
        rights: { domain: 'synthadmin', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher synthesemodeles > détail
            parent: 'synthese.model.list',
            label: '{{ "SYNTHMODS.BREADCRUMBS.SYNTHMOD_DETAIL" | translate }}'
        }
    });
    $stateRegistry.register({
        name: 'synthese.model.edit',
        url: '/{id}/edit',
        template: '<synthesemodeles-form></synthesemodeles-form>',
        rights: { domain: 'synthadmin', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher synthesemodeles > modifier
            parent: 'synthese.model.list',
            label: '{{ "SYNTHMODS.BREADCRUMBS.SYNTHMOD_EDIT" | translate }}'
        }
    });
    $stateRegistry.register({
        name: 'synthese.model.duplicate',
        url: '/{id}/duplicate',
        template: '<synthesemodeles-form></synthesemodeles-form>',
        rights: { domain: 'synthadmin', right: 'create' },
        ncyBreadcrumb: {
            parent: 'synthese.model.list',
            label: '{{ "SYNTHMODS.BREADCRUMBS.SYNTHMOD_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });
    $rootScope.$watch(
        () => MassiaApplicationService.application,
        () => {
            const app = MassiaApplicationService.getApplication();
            const menu = app === 'laboratoire' ? 'analyse_synthese_admin' : 'massia_synth_synth';
            if ($stateRegistry.get('synthese.model.list')) {
                $stateRegistry.deregister('synthese.model.list');
            }
            $stateRegistry.register({
                name: 'synthese.model.list',
                url: '',
                template: '<synthesemodeles></synthesemodeles>',
                rights: { domain: 'synthadmin', right: 'read' },
                ncyBreadcrumb: {
                    parent: 'synthese.model',
                    label: '{{ "SYNTHMODS.BREADCRUMBS.SYNTHMODS_LIST" | translate}}'
                },
                // on affiche la liste des synthesemodeles dans la navigation
                navigation: {
                    menu: menu,
                    translate: 'SYNTHMODS.BREADCRUMBS.SYNTHMODS_LIST',
                    order: 1,
                    navigationCls: 'menu-item-separator'
                },
                // la liste des synthesemodeles fait partie des points d'entrée recherchables dans la navigation
                search: 'SYNTHMODS.BREADCRUMBS.SYNTHMODS_LIST'
            });
        },
        true
    );
}

Configure.$inject = ['$stateRegistry', 'MassiaApplicationService', '$rootScope'];
