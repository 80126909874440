const serviceUrl = `${__configuration.apiUrl}/massia/tva-configuration`;

export default class TvaConfigurationService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getTvaConfiguration(id, type) {
        const url = `${serviceUrl}/${id}/${type}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getPays() {
        const paysUrl = `${__configuration.apiUrl}/massia/pays`;
        const res = await this.$http.get(paysUrl);
        return res.data;
    }

    async getTaux() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/tvas/headers`);
        return res.data;
    }

    async createTvaConfiguration(data) {
        return await this.$http.post(serviceUrl, data);
    }

    async updateTvaConfiguration(tva) {
        const url = `${serviceUrl}/${tva.idAchat}/${tva.idVente}`;
        const data = {
            idPays: tva.idPays,
            idTvaVente: tva.idTvaVente,
            idTvaAchat: tva.idTvaAchat,
            date: tva.date
        };
        return await this.$http.put(url, data);
    }

    async deleteTvaConfigurationById(idAchat, idVente) {
        const url = `${serviceUrl}/${idAchat}/${idVente}`;
        return this.$http.delete(url);
    }
}
