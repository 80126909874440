import TransportEnteteController from './transport.entete.controller';

export default {
    bindings: {
        transport: '=',
        isOpen: '='
    },
    template: require('./transport.entete.html'),
    controller: TransportEnteteController
};
