export default class SiteQualibController {
    static $inject = ['$scope', '$state', '$stateParams', '$translate', 'SitesService', 'MassiaRightsService'];

    constructor($scope, $state, $stateParams, $translate, SitesService, MassiaRightsService) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$translate = $translate;
        this.SitesService = SitesService;
        this.MassiaRightsService = MassiaRightsService;
        this.controlesFamilleSurface = [];
        this.reglesDateEssai = [];
    }

    async $onInit() {
        this.loading = false;

        this.site = this.site || {};
        this.canModifSpecif = this.MassiaRightsService.userHasRight('qualibsitespec', 'update');

        const ctrlFamSurf = await this.SitesService.getControlesFamilleSurface();
        if (ctrlFamSurf) {
            this.controlesFamilleSurface = ctrlFamSurf;
        }

        const regDateEssai = await this.SitesService.getReglesDateEssai();
        if (regDateEssai) {
            this.reglesDateEssai = regDateEssai;
        }
    }

    selectCtrlFamSurf(list, data) {
        if (data) {
            if (!this.site.entete.ctrlFamilleSurface || (this.site.entete.ctrlFamilleSurface && data.id !== this.site.entete.ctrlFamilleSurface)) {
                this.site.entete.ctrlFamilleSurface = data.id;
            } else {
                list = list.map((e) => (e.selected = !e.selected));

                if (this.site.entete.ctrlFamilleSurface) {
                    this.site.entete.ctrlFamilleSurface = null;
                }
            }
        }
    }

    selectRegDateEss(list, data) {
        if (data) {
            if (!this.site.entete.regleDateEssai || (this.site.entete.regleDateEssai && data.id !== this.site.entete.regleDateEssai)) {
                this.site.entete.regleDateEssai = data.id;
            } else {
                list = list.map((e) => (e.selected = !e.selected));

                if (this.site.entete.regleDateEssai) {
                    this.site.entete.regleDateEssai = null;
                }
            }
        }
    }
}
