export default class SyntheseEntityCtrlAcquereurController {
	/*@ngInject*/
	constructor(
		$scope,
		$state,
		$stateParams,
		SyntheseEntitiesCommunicationService,
		_,
		notification,
		MassiaApplicationService,
		$translate,
		SyntheseEntitiesService,
		NormesService,
		ReferencesControleService,
		ProduitsService,
		FormulesService,
		$filter,
		ColorPickerService,
		$uibModal,
		TemplateImpressionService,
		MesuresService,
		moment,
		globalizationManagementService,
		Interpreteur
	) {
		this.$scope = $scope;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$filter = $filter;
		this.SyntheseEntitiesCommunicationService = SyntheseEntitiesCommunicationService;
		this._ = _;
		this.notification = notification;
		this.MassiaApplicationService = MassiaApplicationService;
		this.$translate = $translate;
		this.SyntheseEntitiesService = SyntheseEntitiesService;
		this.NormesService = NormesService;
		this.ReferencesControleService = ReferencesControleService;
		this.ProduitsService = ProduitsService;
		this.FormulesService = FormulesService;
		this.ColorPickerService = ColorPickerService;
		this.$uibModal = $uibModal;
		this.TemplateImpressionService = TemplateImpressionService;
		this.MesuresService = MesuresService;
		this.moment = moment;
		this.Interpreteur = Interpreteur;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async $onInit() {
		this.unregisterReinit = this.SyntheseEntitiesCommunicationService.registerReinit(() => this.initForm());
		this.form = {};
		this.ongletOpen = { isBlocAcquOpen: true, isBlocStatOpen: true, isBlocFTPStatOpen: true };
		if (this.$state.params.obj) {
			this.model = this.$state.params.obj || {};
			this.resultat = {};
			this.graphData = this.model.graphData || {};

			this.startLoading();
			this.resultat = await this.preparePrelevements();
			this.stopLoading();
		} else {
			this.endCtrlAcquereur();
		}
	}

	$onDestroy() {
		this.unregisterReinit();
	}

	async preparePrelevements() {
		const resultat = {};
		resultat.entete = { titresAffichage: [], titresEssais: [], titresSousEssais: [] };
		resultat.lignes = [];
		resultat.stats = [];
		resultat.produits = await this.prepareProduits();

		resultat.entete.titresAffichage = this.model.formattedResult.entete.titresAffichage;
		resultat.entete.titresEssais = this.model.formattedResult.entete.titresEssais;
		const that = this;
		resultat.entete.titresEssais.forEach(function (element) {
			if (!element.symbole) {
				element.symbole = element.code;
			} else {
				element.symbole = that.MesuresService.traduitSymbole(element.symbole);
			}
		});
		resultat.stats = this.model.formattedResult.stats.filter(function (e) {
			return e.isImprimable;
		});

		resultat.entete.titresSousEssais = this.model.formattedResult.entete.titresSousEssais.map((e) => {
			e.displayTitle = e.codeSsEss ? (e.unite ? e.codeSsEss + ' (' + e.unite + ')' : e.codeSsEss) : e.titre + e.unite + ' (%)';
			return e;
		});

		let lastProductId = null;
		let lignes = [];
		for (let l = 0; l < this.model.formattedResult.lignes.length; l++) {
			const line = this.model.formattedResult.lignes[l];
			if (line && line.valeursEssais) {
				//&& line.valeursEssais[0].valeur) {
				const produit = resultat.produits.find(function (p) {
					return p.idsPrelevement.indexOf(line.idPrelevement) >= 0;
				});

				if (produit) {
					const prelevement = this.model.resultat.prelevementBlock.find(function (p) {
						return p.idPrelevement === line.idPrelevement;
					});

					line.nbProduits = resultat.produits.length;

					if (prelevement.idProduit !== lastProductId) {
						line.position = 1;
					}

					lastProductId = prelevement.idProduit;
					line.idProduit = prelevement.idProduit;
					line.codePrelevement = prelevement.codePrelevement;
					line.libelleProduit = prelevement.libelleProduit;
					line.datePrelevement = prelevement.datePrelevement;
					line.ecartee = false;

					lignes.push(line);
				}
			}
		}

		if (lignes && lignes.length > 0) {
			if (lignes[0].datePrelevement) {
				lignes = lignes.sort((a, b) => {
					const db = this.moment(b.datePrelevement, this.dateFormat).unix();
					const da = this.moment(a.datePrelevement, this.dateFormat).unix();
					return da - db;
				});
			}
		}

		resultat.ftpStats = await this.formatFTPStat();

		resultat.lignes = lignes;
		return angular.copy(resultat);
	}

	async prepareProduits() {
		const produits = [];
		if (this.model) {
			try {
				// on met a jour les donnees du scope 'resultat'
				this.resultat = {};
				for (let i = 0; i < this.model.resultat.prelevementBlock.length; i++) {
					const prelev = this.model.resultat.prelevementBlock[i];

					const prelevement = this.model.formattedResult.lignes.find(function (p) {
						return p.idPrelevement === prelev.idPrelevement;
					});
					if (prelevement && prelevement.valeursEssais) {
						const emptyLine =
							prelevement.valeursEssais.findIndex(function (e) {
								return e.valeur;
							}) < 0;
						if (!emptyLine) {
							let findProduit = produits.find(function (e) {
								return e.idProduit === prelev.idProduit;
							});
							if (!findProduit) {
								findProduit = {
									id: prelev.idProduit,
									idProduit: prelev.idProduit,
									idsPrelevement: [prelev.idPrelevement],
									code: prelev.codeProduit,
									libelle: prelev.libelleProduit
								};
								//findProduit.caracteristiques = await this.ProduitsService.getProduitValeursCaracteristiquesById(prelev.idProduit);
								this.ongletOpen[prelev.idProduit] = true;
								produits.push(findProduit);
							} else {
								findProduit.idsPrelevement.push(prelev.idPrelevement);
							}
						}
					}
				}
			} catch (ex) {}
		} else {
			this.$state.go('syntheseentities.list');
		}

		return produits;
	}

	async formatFTPStat() {
		const ftpStat = [];
		if (this.model.synthese.ftpArchivee && this.model.synthese.ftpArchivee.valeurs) {
			const lstBddCalcul = await this.SyntheseEntitiesService.getCalculsStatistiques(this.MassiaApplicationService.getApplication());
			for (let i = 0; i < this.model.synthese.ftpArchivee.valeurs.length; i++) {
				const ftpVal = this.model.synthese.ftpArchivee.valeurs[i];
				const ftpEss = this.model.synthese.ftpArchivee.essais.find(
					(x) =>
						x.isTamis === ftpVal.isTamis &&
						x.idEssai === ftpVal.idEssai &&
						x.idSsEss === ftpVal.idSsEss &&
						x.idSsEssTamis === ftpVal.idSsEssTamis
				);
				let currentStat = ftpStat.find((e) => {
					return e.idCalcStat === ftpVal.idCalcStat;
				});
				if (!currentStat) {
					const bddStat = lstBddCalcul.find((e) => {
						return e.id === ftpVal.idCalcStat;
					});
					currentStat = {
						code: bddStat.code,
						couleurFond: 'rgb(255,255,255)',
						couleurTexte: 'rgb(0,0,0)',
						formule: bddStat.formule,
						groupementIndex: 0,
						idCalcStat: bddStat.id,
						isControle: bddStat.isControle,
						isGroupement: bddStat.isGroupement,
						isGroupementGlissant: bddStat.isGroupementGlissant,
						isImprimable: true,
						nbValGroupe: bddStat.nbValGroupe,
						titre: bddStat.libelle,
						arrondi: bddStat.arrondi,
						arrondiDeLEssai: bddStat.arrondiDeLEssai,
						decimaleSupp: bddStat.decimaleSupp !== null ? bddStat.decimaleSupp : null,
						statsEssais: []
					};

					for (let sse = 0; sse < this.model.formattedResult.entete.titresSousEssais.length; sse++) {
						const titreSse = this.model.formattedResult.entete.titresSousEssais[sse];

						let arrondi = null;
						const essai = this.model.essais.find((e) => {
							return e.idEssai === titreSse.idEssai;
						});
						if (essai) {
							if (titreSse.isTamis) {
								const sousEssai = essai.listeTamisHeaders.find((e) => {
									return e.idTamis > 0 && parseFloat(e.label) === parseFloat(titreSse.titre);
								});
								if (sousEssai) {
									arrondi = sousEssai.arrondi;
								}
							} else {
								const sousEssai = essai.listeSousEssaisHeaders.find((e) => {
									return e.idSousEssai === titreSse.id;
								});
								if (sousEssai) {
									arrondi = sousEssai.arrondi;
								}
							}
						}
						currentStat.statsEssais.push({
							valeur: undefined, //SP 22/05/20 : pas de 0 par défaut !
							idEssai: titreSse.idEssai,
							idSousEssai: titreSse.id,
							isTamis: titreSse.isTamis,
							ouverture: titreSse.titre,
							arrondi: arrondi,
							isComplementaire: ftpEss
								? ftpEss.isComplementaire !== undefined && ftpEss.isComplementaire !== null
									? ftpEss.isComplementaire
									: false
								: false /*essai.isComplementaire*/, //SP 23/03/22 c'est sur la FTPA qu'il y a l'info complémentaire ou pas
							decimaleSupp: ftpEss.decimaleSupp
						});
					}

					ftpStat.push(currentStat);
				}

				const statEssai = currentStat.statsEssais.find((e) => {
					// S'il ne s'agit pas d'un tamis, pas de libellé
					return ftpVal.isTamis
						? e.idEssai === ftpVal.idEssai &&
								(e.idSousEssai === ftpVal.idSsEss ||
									(e.isTamis && parseFloat(e.ouverture) === parseFloat(ftpVal.libelleSsEssTamis.replace(',', '.'))))
						: e.idEssai === ftpVal.idEssai && e.idSousEssai === ftpVal.idSsEss;
				});
				if (statEssai) {
					if (currentStat.arrondiDeLEssai) {
						const newArrondi =
							currentStat.decimaleSupp !== 0 && currentStat.decimaleSupp !== null
								? this.Interpreteur.formatDecSupp(ftpVal.arrondi, currentStat.decimaleSupp)
								: statEssai.arrondi;
						statEssai.valeur = this.arrondiValeur(ftpVal.valeur, newArrondi);
					} else {
						if (currentStat.arrondi !== undefined && currentStat.arrondi !== null) {
							statEssai.valeur = this.arrondiValeur(ftpVal.valeur, currentStat.arrondi);
						} else {
							statEssai.valeur = this.arrondiValeur(ftpVal.valeur, 0);
						}
					}
					//statEssai.valeur = this.arrondiValeur(ftpVal.valeur, 2);// (ftpVal.valeur && ftpVal.valeur.indexOf('/') < 0 && parseFloat(ftpVal.valeur) ? parseFloat(ftpVal.valeur).toFixed(2) : ftpVal.valeur);
				}
			}
		}

		return ftpStat;
	}

	arrondiValeur(valeur, arrondi) {
		const objetToRound = [{ key: valeur, val: valeur, rounder: arrondi }];
		return this.Interpreteur.getRoundedValue(objetToRound);
	}

	openSynthese() {
		const _this = this;
		this.$uibModal
			.open({
				template:
					'<syntheseentities-form modal-instance="$ctrl.uibModalInstance" synth-for-gene="undefined" params-synthese="$ctrl.paramsSynthese"></syntheseentities-form>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.paramsSynthese = _this.model.synthese;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl',
				backdrop: false
			})
			.result.then(
				async function (result) {
					if (result) {
						//result contient une liste (prelevementBlock)
						//TODO: Rafraichir le résultat

						_this.$state.go('syntheseentities.result', { obj: result });
					}
				},
				function (reason) {}
			);
	}

	async openPrelevement(lig) {
		const _this = this;
		_this.id = lig.idPrelevement;
		_this.idFamille = lig.idFamille;
		_this.codeDomaine = lig.codeDomaine;
		this.$uibModal
			.open({
				template:
					'<prelevement modal-instance="$ctrl.uibModalInstance" id="$ctrl.id" id-famille="$ctrl.idFamille" code-domaine="$ctrl.codeDomaine"></prelevement>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.objSynthese = _this.objSynthese;
						$ctrl.uibModalInstance = $uibModalInstance;
						$ctrl.id = _this.id;
						$ctrl.idFamille = _this.idFamille;
						$ctrl.codeDomaine = _this.codeDomaine;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl',
				backdrop: false
			})
			.result.then(
				async function (result) {
					if (result) {
						//await _this.regenererSynthese();
						const res = await _this.SyntheseEntitiesService.genererSynthese(_this.model.synthese, 'laboratoire');
						const objResult = { resultat: res, synthese: _this.model.synthese };
						_this.$state.go('syntheseentities.result', { obj: objResult });
					}
				},
				function (reason) {}
			);
	}

	genereInfos(avecStat) {
		const that = this;
		const ctrlAcqInfo = {};
		//référentiel
		const ctrlAcqFTPA = {};
		ctrlAcqFTPA.libelle = this.model.synthese.ftpArchivee ? this.model.synthese.ftpArchivee.libelle : '';
		ctrlAcqFTPA.norme = this.model.synthese.ftpArchivee ? this.model.synthese.ftpArchivee.libelleNorme : '';
		ctrlAcqFTPA.normeId = this.model.synthese.ftpArchivee ? this.model.synthese.ftpArchivee.idNorme : undefined;
		ctrlAcqFTPA.categorie = this.model.synthese.ftpArchivee ? this.model.synthese.ftpArchivee.categorie : '';
		ctrlAcqFTPA.selections = this.model.synthese.ftpArchivee?.selections || [];
		ctrlAcqInfo.ftpa = ctrlAcqFTPA;
		//récupérer les prélèvements du premier bloc
		const prelsBloc0 = this._.filter(this.model.resultat.prelevementBlock, function (p) {
			return p.numsBloc.includes(0);
		});
		if (prelsBloc0 && prelsBloc0.length > 0) {
			const prelTemp = prelsBloc0[0];
			//produit
			const ctrlAcqProduit = {};
			ctrlAcqProduit.id = prelTemp.idProduit;
			ctrlAcqProduit.code = prelTemp.codeProduit;
			ctrlAcqProduit.libelle = prelTemp.libelleProduit;
			ctrlAcqProduit.type = prelTemp.typeProduit;
			ctrlAcqProduit.grandD = prelTemp.grandDiametre;
			ctrlAcqProduit.petitD = prelTemp.petitDiametre;
			ctrlAcqProduit.caracteristiques = prelTemp.listeCaracteristiquesProduit;
			ctrlAcqInfo.produit = ctrlAcqProduit;
			//laboratoire
			const ctrlAcqLaboratoire = {};
			ctrlAcqLaboratoire.id = prelTemp.idLaboAudit;
			ctrlAcqLaboratoire.code = prelTemp.codeLaboAudit;
			ctrlAcqLaboratoire.libelle = prelTemp.libelleLaboAudit;
			ctrlAcqLaboratoire.societe = prelTemp.societeLaboAudit;
			ctrlAcqLaboratoire.raisonSociale = prelTemp.rsLaboAudit; // Mantis 6438
			ctrlAcqInfo.laboratoire = ctrlAcqLaboratoire;
			//producteur
			const ctrlAcqProducteur = {};
			ctrlAcqProducteur.id = prelTemp.idProducteur;
			ctrlAcqProducteur.code = prelTemp.codeProducteur;
			ctrlAcqProducteur.libelle = prelTemp.libelleProducteur;
			ctrlAcqProducteur.societe = prelTemp.societeProducteur;
			ctrlAcqProducteur.raisonSociale = prelTemp.rsProducteur;
			ctrlAcqProducteur.caracteristiques = prelTemp.listeCaracteristiquesProducteur;
			ctrlAcqInfo.producteur = ctrlAcqProducteur;
			// client
			const ctrlAcqClient = {};
			ctrlAcqClient.id = prelTemp.idClient;
			ctrlAcqClient.code = prelTemp.codeClient;
			ctrlAcqClient.type = prelTemp.typeClient;
			ctrlAcqClient.libelle = prelTemp.libelleClient;
			ctrlAcqInfo.client = ctrlAcqClient;

			const datesPrel = this._.map(this.model.resultat.prelevementBlock, 'datePrelevement');
			ctrlAcqInfo.dateMinP = this.moment(
				new Date(
					Math.min.apply(
						null,
						datesPrel.map((e) => {
							return that.moment(e, this.dateFormat + ' hh:mm');
						})
					)
				)
			).format(this.dateFormat);
			ctrlAcqInfo.dateMaxP = this.moment(
				new Date(
					Math.max.apply(
						null,
						datesPrel.map((e) => {
							return that.moment(e, this.dateFormat + ' hh:mm');
						})
					)
				)
			).format(this.dateFormat);
		}
		ctrlAcqInfo.dateMinA = this.model.synthese.ftpArchivee ? this.model.synthese.ftpArchivee.debEngag : null;
		ctrlAcqInfo.dateMaxA = this.model.synthese.ftpArchivee ? this.model.synthese.ftpArchivee.finEngag : null;
		if (avecStat) {
			const titresSousEssaisNonComplementaires = [];
			const titresSousEssaisComplementaires = [];
			const complem = this._.filter(this.resultat.stats, { isImprimable: true, isControle: false, formule: '@MOY' });
			const complementaires = [];
			for (let i = 0; i < this.resultat.entete.titresSousEssais.length; i++) {
				const titSsEss = this.resultat.entete.titresSousEssais[i];
				if (!titSsEss.isComplementaire) {
					titresSousEssaisNonComplementaires.push(titSsEss);
					if (complem && complem.length > 0) {
						if (complem[0].statsEssais[i].valeur != '') {
							complementaires.push({ libelle: titSsEss.displayTitle, valeur: complem[0].statsEssais[i].valeur });
						}
					}
				} else {
					titresSousEssaisComplementaires.push(titSsEss);
				}
				for (let j = 0; j < this.resultat.ftpStats.length; j++) {
					this.resultat.ftpStats[j].statsEssais[i].isComplementaire = titSsEss.isComplementaire;
				}
			}
			ctrlAcqInfo.complementaires = complementaires;
			const valeursEssais = this._.map(titresSousEssaisNonComplementaires, function (b) {
				// const essaiInfo = this.resultat.entete.titresEssais.find(x => x.idEssai == a.idEssai)
				const valeur = {
					title: b.displayTitle,
					codeSsEss: b.isTamis ? b.ouvertureTamis : b.codeSsEss != null ? b.codeSsEss : '',
					unite: b.unite || '',
					libelleEssai: b.libelleEssai || '',
					symbole: b.symbole ? b.symbole : b.code,
					codeEssai: b.code
				};
				return { valeur: valeur };
			});
			const _this = this;
			//partie Spécifications
			const stats1 = angular.copy(this.resultat.ftpStats.filter((e) => e.isImprimable && e.isControle));
			const statsEssais1 = stats1.map((a) => {
				const res = {
					libelle: a.titre
				};
				const stat = a.statsEssais.filter((x) => !x.isComplementaire);
				res.valeurs = stat.map((x) => {
					if (a.couleurFond) {
						x.valeur = {
							color: this.rgbToHex(this.rgbStringToIntArray(a.couleurFond)).toUpperCase(),
							value: x.valeur
						};
					}

					if (x.conformite > 0) {
						let text = null;
						switch (x.conformite) {
							case 1:
								text = 'FF6600';
								break;
							case 2:
								text = 'FF0000';
								break;
							case 3:
								text = '0000FF';
								break;
						}

						if (a.couleurFond) {
							x.valeur.text = text;
						} else {
							x.valeur = {
								text: text,
								value: x.valeur
							};
						}
					}

					return x;
				});
				return res;
			});
			statsEssais1.splice(0, 0, {
				titre: '',
				code: '',
				codeLibelle: '',
				codeUnite: '',
				codeSymbole: '',
				libelle: '',
				libelleUnite: '',
				libelleSymbole: '',
				unite: '',
				symbole: '',
				codeLibelleSymbole: '',
				codeLibelleUnite: '',
				codeLibelleUniteSymbole: '',
				valeurs: angular.copy(valeursEssais)
			});
			ctrlAcqInfo.statsEssais1 = statsEssais1;

			//résultats de production
			const stats2 = angular.copy(this.resultat.ftpStats.filter((e) => e.isImprimable && !e.isControle));
			const statsEssais2 = stats2.map((a) => {
				const res = {
					libelle: a.titre
				};
				const stat = a.statsEssais.filter((x) => !x.isComplementaire);
				res.valeurs = stat.map((x) => {
					if (a.couleurFond) {
						x.valeur = {
							color: this.rgbToHex(this.rgbStringToIntArray(a.couleurFond)).toUpperCase(),
							value: x.valeur
						};
					}

					if (x.conformite > 0) {
						let text = null;
						switch (x.conformite) {
							case 1:
								text = 'FF6600';
								break;
							case 2:
								text = 'FF0000';
								break;
							case 3:
								text = '0000FF';
								break;
						}

						if (a.couleurFond) {
							x.valeur.text = text;
						} else {
							x.valeur = {
								text: text,
								value: x.valeur
							};
						}
					}

					return x;
				});
				return res;
			});
			statsEssais2.splice(0, 0, {
				titre: '',
				code: '',
				codeLibelle: '',
				codeUnite: '',
				codeSymbole: '',
				libelle: '',
				libelleUnite: '',
				libelleSymbole: '',
				unite: '',
				symbole: '',
				codeLibelleSymbole: '',
				codeLibelleUnite: '',
				codeLibelleUniteSymbole: '',
				valeurs: angular.copy(valeursEssais)
			});
			ctrlAcqInfo.statsEssais2 = statsEssais2;
			//résultats acquéreur
			const stats3 = angular.copy(this.resultat.stats.filter((e) => e.isImprimable /*&& !e.isControle*/));
			const statsEssais3 = stats3.map((a) => {
				const res = {
					libelle: a.titre
				};
				const stat = a.statsEssais.filter((y) => !y.isComplementaire);
				res.valeurs = stat.map((x) => {
					if (a.couleurFond) {
						x.valeur = {
							color: this.rgbToHex(this.rgbStringToIntArray(a.couleurFond)).toUpperCase(),
							value: x.valeur
						};
					}

					if (x.conformite > 0) {
						let text = null;
						switch (x.conformite) {
							case 1:
								text = 'FF6600';
								break;
							case 2:
								text = 'FF0000';
								break;
							case 3:
								text = '0000FF';
								break;
						}

						if (a.couleurFond) {
							x.valeur.text = text;
						} else {
							x.valeur = {
								text: text,
								value: x.valeur
							};
						}
					}

					return x;
				});
				return res;
			});
			/*statsEssais3.splice(0, 0, {
                libelle: '',
                code: '',
                codeLibelle: '',
                codeUnite: '',
                unite: '',
                codeLibelleUnite: '',
                titre: '',
                valeurs: angular.copy(valeursEssais)
            });*/
			statsEssais3.splice(0, 0, {
				titre: '',
				code: '',
				codeLibelle: '',
				codeUnite: '',
				codeSymbole: '',
				libelle: '',
				libelleUnite: '',
				libelleSymbole: '',
				unite: '',
				symbole: '',
				codeLibelleSymbole: '',
				codeLibelleUnite: '',
				codeLibelleUniteSymbole: '',
				valeurs: angular.copy(valeursEssais)
			});
			ctrlAcqInfo.statsEssais3 = statsEssais3;
			//essais complémentaires
			//var titresSousEssaisComplementaires = this._.filter(this.resultat.entete.titresSousEssais, { isComplementaire: true });
			/*var valeursEssaisCompl = this._.map(titresSousEssaisComplementaires, function (b) {
                return { valeur: b.displayTitle };
            });
            var complem = this._.filter(this.resultat.stats, { isImprimable: true, isControle: false, formule: '@MOY' });
            var complementaires = [];
            if (complem && complem.length > 0) {
                var valeurs = _this._.filter(complem[0].statsEssais, { isComplementaire: true });
                for (var i = 0; i < valeurs.length; i++) {
                    if (valeurs[i].valeur != '') complementaires.push({ libelle: valeursEssaisCompl[i].valeur, valeur: valeurs[i].valeur });
                }
            }
            ctrlAcqInfo.complementaires = complementaires;*/

			const valeursEssaisComp = this._.map(titresSousEssaisComplementaires, function (b) {
				const valeur = {
					title: b.displayTitle,
					codeSsEss: b.isTamis ? b.ouvertureTamis : b.codeSsEss != null ? b.codeSsEss : '',
					unite: b.unite || '',
					libelleEssai: b.isTamis ? '' : b.libelleEssai,
					symbole: b.symbole || b.code || '',
					codeEssai: b.code || '',
					isComplementaire: b.isComplementaire
				};
				return { valeur: valeur };
			});
			//ajout des stats sur les essais complémentaires (mantis 7869)
			ctrlAcqInfo.statsComplementaires = [];

			if (complementaires && complementaires.length > 0) {
				ctrlAcqInfo.statsComplementaires = [
					...this.getStatsForPrint(stats1, angular.copy(valeursEssaisComp), true),
					...this.getStatsForPrint(stats2, angular.copy(valeursEssaisComp), true),
					...this.getStatsForPrint(stats3, angular.copy(valeursEssaisComp), true)
				];
			}
		}
		return ctrlAcqInfo;
	}

	rgbStringToIntArray(rgb) {
		let array = rgb.replace('rgb(', '').replace(')', '').split(',');
		array = array.map((x) => Number.parseInt(x));
		return array;
	}

	rgbToHex(rgb) {
		return rgb
			.map((x) => {
				const hex = x.toString(16);
				return hex.length === 1 ? '0' + hex : hex;
			})
			.join('');
	}

	async print() {
		const acq = this.genereInfos(true);
		//récupérer la courbe granulo
		const granulo = document.getElementById('granulograph');
		if (granulo != null) {
			const base64 = granulo.toDataURL('image/png');
			acq.canvas = base64;
		} else {
			acq.canvas = null;
		}
		try {
			const res = await this.TemplateImpressionService.downloadModal('contrac');
			if (res === 'cancel') {
				throw res;
			}

			acq.printPrels = res.printPrel;
			if (res.printPrel) {
				this.resultat.lignes = this.resultat.lignes.filter((x) => {
					return !x.ecartee;
				});
				acq.resultat = this.resultat;
			}

			this.notification.info(this.$translate.instant('EXPORT.GENERATION_DOC_ENCOURS'), '', {
				autoDismiss: false
			});
			const template = await this.SyntheseEntitiesService.printCtrlAcq(acq, res.filename);
			template.pdf = res.pdf;
			await this.TemplateImpressionService.downloadTemplate(template.filename, template, 'contrac');
		} catch (err) {
			if (err !== 'cancel') {
				this.notification.error(err.data);
			}
		}
	}

	async endCtrlAcquereur() {
		this.$state.go('syntheseentities.list', { obj: this.model });
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}

	getStatsForPrint(listeStats, valEssais, complementaire = false) {
		const stats = listeStats.map((a) => {
			const res = {
				libelle: a.titre,
				code: a.code
			};
			const stat = a.statsEssais.filter((x) => x.isComplementaire === complementaire);
			res.valeurs = stat.map((x) => {
				if (a.couleurFond) {
					x.valeur = {
						color: this.rgbToHex(this.rgbStringToIntArray(a.couleurFond)).toUpperCase(),
						value: x.valeur
					};
				}

				if (a.conformite > 0) {
					let text = null;
					switch (a.conformite) {
						case 1:
							text = 'FF6600';
							break;
						case 2:
							text = 'FF0000';
							break;
						case 3:
							text = '0000FF';
							break;
					}

					if (a.couleurFond) {
						x.valeur.text = text;
					} else {
						x.valeur = {
							text: text,
							value: x.valeur
						};
					}
				}

				return x;
			});
			return res;
		});
		stats.splice(0, 0, {
			titre: '',
			code: '',
			codeLibelle: '',
			codeUnite: '',
			codeSymbole: '',
			libelle: '',
			libelleUnite: '',
			libelleSymbole: '',
			unite: '',
			symbole: '',
			codeLibelleSymbole: '',
			codeLibelleUnite: '',
			codeLibelleUniteSymbole: '',
			valeurs: angular.copy(valEssais)
		});

		return stats;
	}
}
