import validatorBuilder from 'validator-builder';
import { Critere } from './criteres.models';

let modelValidator = null;

export default class ModeleSelection {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.libelle = data.libelle;
        this.code = data.code;
        this.famille = data.famille || {};
        this.modelSelectionTypes = data.modelSelectionTypes || [];

        const criteres = data.modelSelectionCriteres || [];
        this.modelSelectionCriteres = criteres.map((x) => new Critere(x));
    }

    isValid() {
        modelValidator = modelValidator || validatorBuilder.getInstanceFor('ModeleSelection');
        const res = modelValidator.validate(this);
        return res.isValid;
    }

    getErrors() {
        modelValidator = modelValidator || validatorBuilder.getInstanceFor('ModeleSelection');
        const res = modelValidator.validate(this);
        return res.errors;
    }
}
