import SiteElementHierarchique from './site.element.model';

export default class SiteNiveauxElementsService {
    static $inject = ['$http', '_', 'AcTreeviewCommunicationService', 'notification', 'TypesNiveauxHierarchique', '$translate'];

    constructor($http, _, AcTreeviewCommunicationService, notification, TypesNiveauxHierarchique, $translate) {
        let treeviewName;
        const baseUrl = `${__configuration.apiUrl}/massia/domaines/sites/niveaux-hierarchiques`;

        this._ = _;

        this.elements = undefined;

        this.setTreeviewName = (name) => {
            treeviewName = name;
        };

        this.getRootId = () => {
            return 'ElementsRoot';
        };

        // permet de sortir les types disponibles (sauf entrée libre et pays) pour un item donné
        this.getAvailableNiveaux = async (item) => {
            let url = '';
            if (item.id === this.getRootId()) {
                url = `${__configuration.apiUrl}/massia/domaines/sites/niveaux-hierarchiques`;
            } else {
                const typeId = SiteElementHierarchique.getIdNiveau(item.id);
                url = `${baseUrl}/${typeId}/sous-niveaux-hierarchiques`;
            }
            try {
                const availableNiveaux = await $http.get(url);
                const niveaux = availableNiveaux.data;
                //le resultat de retour contient tout type de niveau existant,
                //cependant, nous n'autorisons d'ajouter que les niveaux de type Defini
                return _.reject(niveaux, function (niv) {
                    return niv.type === TypesNiveauxHierarchique.Libre.value || niv.type === TypesNiveauxHierarchique.Pays.value;
                });
            } catch (ex) {
                notification.error(ex.data);
            }
        };

        /* nécessaires au treeview */
        this.getRootNodes = async () => {
            try {
                const url = `${__configuration.apiUrl}/massia/domaines/sites/elements-hierarchiques`;
                const res = await $http.get(url);

                //formattage des retours venant du serveur
                this.SetElements(res.data);
                return angular.copy(this.elements);
            } catch (ex) {
                notification.error(ex.data);
                return false;
            }
        };

        //adaptation des resultats venant du serveur au format attendu par le front
        this.SetElements = (elements) => {
            //selon elements.json, il faut ajouter un noeud 'root' comme la racine (pour tous les noeuds du premier niveau)
            elements.forEach((x) => (x.parentId = this.getRootId()));

            this.elements = [
                new SiteElementHierarchique(
                    {
                        id: this.getRootId(),
                        expanded: true,
                        parentId: null,
                        label: $translate.instant('NIVEAUXHIERARCHIQUES.ELEMENTS.NOM'),
                        position: 0,
                        type: 'root',
                        items: elements,
                        canEdit: false,
                        canReorder: false
                    },
                    null,
                    this._
                )
            ];
        };

        this.getNode = async (id, localNode) => {
            try {
                //cette methode est aussi appellee quand on souhaite rafraichir le noeud "root" qui est un noeud artificiel et a ete ajoute manuellement dans la methode SetTrame
                //du coup, normalement pour les noeuds normaux, id en entree de cette methode est un int, mais exceptionnellement pour le noeud root, son id est 'root'
                //dans ce cas-la, on traite differamment l'appel
                if (id === this.getRootId()) {
                    const res = await this.getRootNodes();
                    //le resultat de retour de getRootNodes est une collection des noeuds racines (mais concretement dans notre context, une collection ayant comme le seul noeud de racine "root")
                    //pour nous, ce qu'il faut n'est que la racine 'root' au lieu d'etre la collection
                    return res[0];
                }
                const url = `${baseUrl}/${SiteElementHierarchique.getIdNiveau(id)}/elements-hierarchiques/${SiteElementHierarchique.getCodeElement(
                    id
                )}`;
                const res = await $http.get(url);
                const parentNode = _.findDeep(this.elements, { id: localNode.parentId });
                const node = new SiteElementHierarchique(res.data, parentNode.id);

                node.expanded = localNode ? localNode.expanded : false;

                //une fois on recupere un node, nous devons aussi mettre a jour la trame locale
                this.updateLocalElement(node);

                return angular.copy(node);
            } catch (ex) {
                notification.error(ex.data);
                return false;
            }
        };

        this.getChildrenByNodeId = async (id) => {
            try {
                if (id === this.getRootId()) {
                    //quand on demande de recuperer les enfants de "root", on retourne en effet toute l'arbo reel
                    //en effet, le noeud root est un noeud artificiel que nous concatenons au front
                    return this.elements[0].items;
                }
                const url = `${baseUrl}/${SiteElementHierarchique.getIdNiveau(id)}/elements-hierarchiques/${SiteElementHierarchique.getCodeElement(
                    id
                )}/sous-elements-hierarchiques`;
                const res = await $http.get(url);
                const parentNode = _.findDeep(this.elements, { id: id });

                //formattage des retours venant du serveur
                const childrens = _.map(res.data, function (node) {
                    return new SiteElementHierarchique(node, parentNode.id);
                });

                this.updateLocalElementWithChildrenNodes(id, childrens);

                return angular.copy(childrens);
            } catch (ex) {
                notification.error(ex.data);
                return false;
            }
        };

        //actuellement au niveau front, il semble que la seule possibilite pour declencher cette methode est de brancher le drag&drop
        //a faire dans la suite
        this.changeNodeParent = async (node, targetId, target, parent) => {
            //normalement quand on arrive ici,
            //on a deja passe la methode de verification canDrop

            //on change l'id de l'element parent
            node.parentId = target.id;

            //on set la position a la derniere position de sous element du nouveau parent

            //tous les sous elements existant du target, qui ont le meme niveau que l'element courant
            const allElementsDuMemeNiveau = _.filter(target.items, function (item) {
                return item.typeLabel === node.typeLabel;
            });

            node.position = allElementsDuMemeNiveau.length;

            await this.updateNode(node);

            AcTreeviewCommunicationService.raiseNodeRefreshFunction(treeviewName, target.id, true, target);

            return angular.copy(node);
        };

        this.createNode = async (parent, node) => {
            try {
                const url = `${__configuration.apiUrl}/massia/domaines/sites/elements-hierarchiques`;

                //preparer le dto demande par le serveur pour update
                const nodeInfo = {
                    code: node.code,
                    label: node.nom,
                    idNiveau: node.typeId,
                    idParent: null
                };

                //quand l'id du parent est root, on ne remplit rien pour idParent, car c'est deja un element de racine
                if (parent.id !== this.getRootId()) {
                    nodeInfo.idParent = {
                        niveauHierarchiqueId: SiteElementHierarchique.getIdNiveau(parent.id),
                        elementId: SiteElementHierarchique.getCodeElement(parent.id)
                    };
                }

                await $http.post(url, nodeInfo);

                // on rafraichit le parent
                AcTreeviewCommunicationService.raiseNodeRefreshFunction(treeviewName, parent.id, true, parent);

                return angular.copy(nodeInfo);
            } catch (ex) {
                notification.error(ex.data);
            }
        };

        this.renameNode = async (node) => {
            try {
                if (node.label.length <= 100) {
                    await this.updateNode(node);
                } else {
                    notification.error($translate.instant('VALIDATION_TOO_LONG_100'));
                }

                return angular.copy(node);
            } catch (ex) {
                notification.error(ex.data);
                return false;
            }
        };

        this.moveNode = async (direction, node) => {
            try {
                const parentNode = _.findDeep(this.elements, { id: node.parentId });

                //tous les elements existant, qui ont le meme niveau que l'element courant
                const allElementsDuMemeNiveau = _.filter(parentNode.items, function (item) {
                    return item.typeLabel === node.typeLabel;
                });

                if (this.canMove(node, allElementsDuMemeNiveau, direction)) {
                    node.position = direction === 'up' ? node.position - 1 : node.position + 1;

                    await this.updateNode(node, parentNode);
                }

                return node;
            } catch (ex) {
                notification.error(ex.data);
                return false;
            }
        };

        //determiner si nous pouvons deplacer un element ou pas
        this.canMove = (node, allElementsDuMemeNiveau, direction) => {
            //on determine si, parmi tous les elements existants du meme niveau,
            //l'element courant a des elements au dessus (dans le treeview)
            const hasElementDessus = _.some(allElementsDuMemeNiveau, function (item) {
                return item.position < node.position;
            });

            //l'element courant a des elements au dessous (dans le treeview)
            const hasElementDessous = _.some(allElementsDuMemeNiveau, function (item) {
                return item.position > node.position;
            });

            return (direction === 'up' && hasElementDessus) || (direction === 'down' && hasElementDessous);
        };

        this.removeNode = async (node) => {
            try {
                const url = `${baseUrl}/${SiteElementHierarchique.getIdNiveau(
                    node.id
                )}/elements-hierarchiques/${SiteElementHierarchique.getCodeElement(node.id)}`;
                const parentNode = _.findDeep(this.elements, { id: node.parentId });

                await $http.delete(url);

                // on rafraichit le parent
                AcTreeviewCommunicationService.raiseNodeRefreshFunction(treeviewName, parentNode.id, true, parentNode);

                return angular.copy(node);
            } catch (ex) {
                notification.error(ex.data);
                return false;
            }
        };

        this.updateLocalElement = (newNode) => {
            const item = _.findDeep(this.elements, { id: newNode.id, parentId: newNode.parentId });

            if (item) {
                angular.extend(item, newNode);
            }
        };

        //mettre a jour le this.trame
        this.updateLocalElementWithChildrenNodes = (parentNodeId, childrenNodes) => {
            const parentNode = _.findDeep(this.elements, {
                id: parentNodeId
            });

            if (parentNode) {
                parentNode.items = childrenNodes;
            }
        };

        this.updateNode = async (node, parentNode) => {
            try {
                parentNode = parentNode || _.findDeep(this.elements, { id: node.parentId });

                const url = `${baseUrl}/${SiteElementHierarchique.getIdNiveau(
                    node.id
                )}/elements-hierarchiques/${SiteElementHierarchique.getCodeElement(node.id)}`;

                //preparer le dto demande par le serveur pour update
                const nodeInfo = {
                    label: node.label,
                    position: node.position,
                    idParent: null
                };

                //quand l'id du parent est root, on ne remplit rien pour idParent, car c'est deja un element de racine
                if (node.parentId !== this.getRootId()) {
                    nodeInfo.idParent = {
                        niveauHierarchiqueId: SiteElementHierarchique.getIdNiveau(node.parentId),
                        elementId: SiteElementHierarchique.getCodeElement(node.parentId)
                    };
                }

                await $http.put(url, nodeInfo);

                AcTreeviewCommunicationService.raiseNodeRefreshFunction(treeviewName, parentNode.id, true, parentNode);
            } catch (ex) {
                notification.error(ex.data);
                return false;
            }
        };

        //verifier si le code saisi pour l'element est unique ou pas
        this.codeExists = async (code) => {
            const url = `${__configuration.apiUrl}/massia/domaines/sites/elements-hierarchiques/code-unicity/${code}`;
            const result = await this.$http.get(url);
            return result.data;
        };

        //dans l'arbre d'elements hierarchiques, nous pouvons changer le parent d'un element,
        //sous condition que le nouveau parent ait le meme type (niveau) que le parent actuel
        this.canDrop = (source, target) => {
            if (source && target) {
                //si on drag un element de type Pays, on empeche l'action
                if (source.type === TypesNiveauxHierarchique.Pays.value) {
                    notification.error($translate.instant('NIVEAUXHIERARCHIQUES.ELEMENTS.ERROR_DEPLACEMENT_ELEMENT_PAYS'));
                    return false;
                }

                //si on drop l'element sur son element parent, on fait rien
                if (source.parentId === target.id) {
                    return false;
                }

                const oldIdNiveauParent = SiteElementHierarchique.getIdNiveau(source.parentId);
                const newIdNiveauParent = SiteElementHierarchique.getIdNiveau(target.id);

                const res = oldIdNiveauParent === newIdNiveauParent;

                if (!res) {
                    notification.error($translate.instant('NIVEAUXHIERARCHIQUES.ELEMENTS.ERROR_DEPLACEMENT_DIFFERENT_TYPE_PARENT'));
                }

                return res;
            }

            //si source ou target est null, on empeche le drag & drop
            return false;
        };
    }
}
