export default class TgapExclureController {
	static $inject = ['$scope', '$stateParams', 'TgapExclureCommunicationService', 'TgapExclureService', 'notification', '$uibModal'];

	constructor($scope, $stateParams, TgapExclureCommunicationService, TgapExclureService, notification, $uibModal) {
		this.$scope = $scope;
		this.$stateParams = $stateParams;
		this.TgapExclureCommunicationService = TgapExclureCommunicationService;
		this.TgapExclureService = TgapExclureService;
		this.notification = notification;
		this.$uibModal = $uibModal;
	}

	async $onInit() {
		this.isAllExclure = false;
		this.unregister = this.$scope.$watch(
			() => this.isOpen,
			() => {
				if (this.isOpen && !this.tgapExclures) {
					this.loadTgapExclure();
				}
			},
			true
		);
	}

	$onDestroy() {
		this.unregister();
	}

	async loadTgapExclure() {
		if (this.idClient) {
			this.startLoading();
			try {
				this.tgapExclures = await this.TgapExclureService.getTgapExclure(this.idClient, this.idChantier);
				if (this.tgapExclures.find((x) => x.isExclure)) {
					this.isAllExclure = true;
				} else {
					this.isAllExclure = false;
				}
			} catch (err) {
				if (err.data) {
					this.notification.error(err.data);
				} else {
					throw err;
				}
			}
			this.stopLoading();
		}
	}

	async updateTgapExclure(tgapExclure) {
		if (!tgapExclure.isBloque) {
			if (tgapExclure.idTgapExclure) {
				this.TgapExclureService.deleteTgapExclureById(tgapExclure.idTgapExclure);
			} else {
				tgapExclure.idClient = this.idClient;
				tgapExclure.idChantier = this.idChantier;
				this.TgapExclureService.createTgapExclure(tgapExclure);
			}
		}
	}

	async changeExclure(tgapExclure) {
		await this.updateTgapExclure(tgapExclure);
		this.loadTgapExclure();
	}

	async changeAllExclure() {
		this.startLoading();
		console.log(this.isAllExclure);
		const tgapExclureSelecteds = this.tgapExclures.filter((x) => x.isExclure != this.isAllExclure);
		tgapExclureSelecteds.forEach(async function (element) {
			await this.updateTgapExclure(element);
		}, this);
		this.stopLoading();
		this.loadTgapExclure();
	}

	startLoading() {
		this.loading = true;
	}

	stopLoading() {
		this.loading = false;
	}
}
