import validatorBuilder from 'validator-builder';

let transportEnteteValidator = null;

export default class TransportEntete {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.type = 'transport';
        this.code = data.code;
        this.uniteId = data.uniteId;
        this.prixBenne = data.prixBenne;
        this.prixZone = data.prixZone;
        this.libelle = data.libelle;
        this.idGrilleZoneKm = data.idGrilleZoneKm;
    }

    isValid() {
        transportEnteteValidator = transportEnteteValidator || validatorBuilder.getInstanceFor('TransportEntete');
        const validationResults = transportEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
