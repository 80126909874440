//Config
import SocietesConfig from './_config/societes.config';
import SocietesRoutes from './_config/societes.routes';
import ClientsRoutes from './_config/clients.routes';
import ProducteursRoutes from './_config/producteurs.routes';
import TransporteursRoutes from './_config/transporteurs.routes';
import FournisseursRoutes from './_config/fournisseurs.routes';
import LaboratoiresRoutes from './_config/laboratoires.routes';
import ProprietairesRoutes from './_config/proprietaires.routes';
import LocatairesRoutes from './_config/locataires.routes';
import AuditeursRoutes from './_config/auditeurs.routes';

//Components
import SocietesComponent from './components/societes';
import SocieteFormComponent from './components/societe-form';
import SocieteDetailComponent from './components/societe-detail';

import SocieteFormEnteteComponent from './components/societe-form/entete';
import SocieteFormCaracteristiquesComponent from './components/societe-form/caracteristiques';
import SocieteCoordonneesComponent from './components/societe-form/coordonnees';
import SocietesAssocieesComponent from './components/societe-form/societes-associees';
import SocieteFormNiveauxComponent from './components/societe-form/niveaux';
import PontComptableComponent from './components/societe-form/pont-comptable';
import ParametreTransportComponent from './components/societe-form/parametre-transport';
import MiniFormLogoComponent from '../../common/images/components/mini-form/logo';

import SocieteConfigNiveauxComponent from './components/societe-config-niveaux';
import EnteteImpressionHelper from './components/entete-impression-helper';

//services
import SocietesService from './services/societes.service';
// import CentreGestionService from './services/centre.gestion.service';
import SocietesTypesService from './services/societes.types.service';
import SocietesCommunicationService from './services/societes.communication.service';
import SocieteNiveauxTrameService from './components/societe-config-niveaux/societe.niveaux.trame.service';
import SocieteNiveauxElementsService from './components/societe-config-niveaux/societe.niveaux.elements.service';

import TypesNiveauxHierarchique from './components/societe-config-niveaux/societe.niveaux.trame.constant';

//Validateurs
import LogoValidator from '../images/components/mini-form/logo/mini.form.logo.validator';
import SocieteValidator from './components/societe-form/societe.validator';
import SocieteEnteteValidator from './components/societe-form/entete/societe.entete.validator';
import SocieteCoordonneesValidator from './components/societe-form/coordonnees/societe.coordonnees.validator';
import SocietesAssocieesValidator from './components/societe-form/societes-associees/societes.associees.validator';
import SocieteCaracteristiquesValidator from './components/societe-form/caracteristiques/societe.caracteristiques.validator';
import PontComptableValidator from './components/societe-form/pont-comptable/pont.comptable.validator';
import ParametreTransportValidator from './components/societe-form/parametre-transport/parametre.transport.validator';

const moduleName = 'app.massia.common.societes';

angular
	.module(moduleName, [])
	.config(SocietesConfig)
	.config(SocietesRoutes)
	.config(ClientsRoutes)
	.config(ProducteursRoutes)
	.config(TransporteursRoutes)
	.config(FournisseursRoutes)
	.config(LaboratoiresRoutes)
	.config(ProprietairesRoutes)
	.config(LocatairesRoutes)
	.config(AuditeursRoutes)
	.constant('TypesNiveauxHierarchique', TypesNiveauxHierarchique)
	.service('SocietesService', SocietesService)
	.service('SocietesTypesService', SocietesTypesService)
	.service('SocietesCommunicationService', SocietesCommunicationService)
	.service('SocieteNiveauxTrameService', SocieteNiveauxTrameService)
	.service('SocieteNiveauxElementsService', SocieteNiveauxElementsService)
	.component('societes', SocietesComponent)
	.component('societeForm', SocieteFormComponent)
	.component('societeDetail', SocieteDetailComponent)
	.component('societeFormEntete', SocieteFormEnteteComponent)
	.component('societeFormCaracteristiques', SocieteFormCaracteristiquesComponent)
	.component('societeFormNiveaux', SocieteFormNiveauxComponent)
	.component('pontComptable', PontComptableComponent)
	.component('societeConfigNiveaux', SocieteConfigNiveauxComponent)
	.component('enteteImpressionHelper', EnteteImpressionHelper)
	.component('societeCoordonnees', SocieteCoordonneesComponent)
	.component('societesAssociees', SocietesAssocieesComponent)
	.component('parametreTransport', ParametreTransportComponent)
	.component('miniFormLogo', MiniFormLogoComponent)
	.factory('LogoValidator', LogoValidator)
	.factory('SocieteValidator', SocieteValidator)
	.factory('SocieteEnteteValidator', SocieteEnteteValidator)
	.factory('SocieteCaracteristiquesValidator', SocieteCaracteristiquesValidator)
	.factory('SocietesAssocieesValidator', SocietesAssocieesValidator)
	.factory('PontComptableValidator', PontComptableValidator)
	.factory('ParametreTransportValidator', ParametreTransportValidator);

export default moduleName;
