import validatorBuilder from 'validator-builder';

let prestationEnteteValidator = null;

export default class PrestationEntete {
    constructor(data) {
        data = data || {};
        this.id = data.id;
        this.type = 'prestation';
        this.code = data.code;
        this.uniteId = data.uniteId;
        this.grilleRevisionId = data.grilleRevisionId;
        this.modeDeclenchement = data.modeDeclenchement;
        this.modeCalcul = data.modeCalcul;
        this.libelle = data.libelle;
        this.isMergeWithPrixProduit = data.isMergeWithPrixProduit;
        this.isTgap = data.isTgap;
    }

    isValid() {
        prestationEnteteValidator = prestationEnteteValidator || validatorBuilder.getInstanceFor('PrestationEntete');
        const validationResults = prestationEnteteValidator.validate(this);
        return validationResults.isValid;
    }
}
