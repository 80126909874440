//Config
import DocumentsConfig from './_config/documents.config';
import DocumentsRoutes from './_config/documents.routes';

//Components
import DocumentsComponent from './components/documents';
import DocumentFormComponent from './components/document-form';
import DocumentModalFormComponent from './components/document-modal-form';
import DocumentModalImportComponent from '../prelevements/components/presse3r-modal-import';
import DocumentDetailComponent from './components/document-detail';

import DocumentFormEnteteComponent from './components/document-form/entete';
import DocumentFormCaracteristiquesComponent from './components/document-form/caracteristiques';

import DocumentsDownloadComponent from './components/documents-download';

//services
import DocumentsService from './services/documents.service';
import DocumentsTypesService from './services/documents.types.service';
import DocumentsCommunicationService from './services/documents.communication.service';

//Validateurs
import DocumentValidator from './components/document-form/document.validator';
import DocumentModalValidator from './components/document-modal-form/document.modal.validator';
import Presse3rModalImportValidator from '../prelevements/components/presse3r-modal-import/presse3r.modal.import.validator';
import DocumentEnteteValidator from './components/document-form/entete/document.entete.validator';
import DocumentCaracteristiquesValidator from './components/document-form/caracteristiques/document.caracteristiques.validator';
const moduleName = 'app.massia.common.documents';

angular
    .module(moduleName, [])
    .config(DocumentsConfig)
    .config(DocumentsRoutes)
    .service('DocumentsService', DocumentsService)
    .service('DocumentsTypesService', DocumentsTypesService)
    .service('DocumentsCommunicationService', DocumentsCommunicationService)
    .component('documents', DocumentsComponent)
    .component('documentForm', DocumentFormComponent)
    .component('documentModalForm', DocumentModalFormComponent)
    .component('documentModalImport', DocumentModalImportComponent)
    .component('documentDetail', DocumentDetailComponent)
    .component('documentFormEntete', DocumentFormEnteteComponent)
    .component('documentFormCaracteristiques', DocumentFormCaracteristiquesComponent)
    .component('documentsDownload', DocumentsDownloadComponent)
    .factory('DocumentValidator', DocumentValidator)
    .factory('DocumentModalImportValidator', Presse3rModalImportValidator)
    .factory('DocumentModalValidator', DocumentModalValidator)
    .factory('DocumentEnteteValidator', DocumentEnteteValidator)
    .factory('DocumentCaracteristiquesValidator', DocumentCaracteristiquesValidator);

export default moduleName;
