export default class GestionStocksProductionController {
    static $inject = ['$stateParams', 'StocksCommunicationService', 'StocksService', 'notification', 'ModalService', '$uibModal'];

    constructor($stateParams, StocksCommunicationService, StocksService, notification, ModalService, $uibModal) {
        this.$stateParams = $stateParams;
        this.StocksCommunicationService = StocksCommunicationService;
        this.StocksService = StocksService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
    }

    async $onInit() {
        this.quantite = 0;
        this.retraitement = [];
        for (let index = 0; index < this.ligne.composition.length; index++) {
            const currentComp = {
                idProduit: this.ligne.composition[index].idProduit,
                codeProduit: this.ligne.composition[index].codeProduit,
                libelleProduit: this.ligne.composition[index].libelleProduit,
                idSiteProducteur: this.ligne.composition[index].idSiteProducteur,
                siteProducteur: this.ligne.composition[index].siteProducteur,
                idEmplacement: 0,
                emplacements: this.ligne.composition[index].emplacements,
                pourcent: this.ligne.composition[index].pourcent,
                stockFinal: this.ligne.composition[index].stockFinal
            };
            this.retraitement.push(currentComp);
        }
    }

    changeQuantite() {
        for (let index = 0; index < this.retraitement.length; index++) {
            const currentFormule = this.retraitement[index];
            currentFormule.quantite = (currentFormule.pourcent * this.quantite) / 100;
            currentFormule.stockApresProduction = currentFormule.stockFinal - currentFormule.quantite;
        }
    }

    annuler() {
        this.modalInstance.close(false);
    }

    async confirmer() {
        let isValid = true;
        for (let index = 0; index < this.retraitement.length; index++) {
            const current = this.retraitement[index];
            if (current.stockApresProduction < 0) {
                isValid = false;
            }
        }

        if (isValid) {
            //ajouter la nouvelle production retraitée
            const prodRetrait = {
                quantite: this.quantite
            };
            if (!this.ligne.newProductionsRetraitees) {
                this.ligne.newProductionsRetraitees = [];
            }
            this.ligne.newProductionsRetraitees.push(prodRetrait);
            //recalculer la variation, la production retraitée et le stock final de la ligne
            if (this.ligne.variation.valeur) {
                this.ligne.variation.valeur = this.ligne.variation.valeur + this.quantite;
            } else {
                this.ligne.variation.valeur = this.quantite;
            }
            if (this.ligne.stockFinal.valeur) {
                this.ligne.stockFinal.valeur = this.ligne.stockFinal.valeur + this.quantite;
            } else {
                this.ligne.stockFinal.valeur = this.quantite;
            }
            if (this.ligne.productionRetraitee.valeur) {
                this.ligne.productionRetraitee.valeur = this.ligne.productionRetraitee.valeur + this.quantite;
            } else {
                this.ligne.productionRetraitee.valeur = this.quantite;
            }
            //renvoyer les retraitements
            this.modalInstance.close(this.retraitement);
        } else {
            this.notification.error('STOCKS.PRODUCTION_NEGATIVE');
        }
    }
}
