import ModalAddContactController from './add.contact.controller';

export default {
    template: require('./add.contact.tpl.html'),
    controller: ModalAddContactController,
    bindings: {
        newContact: '=',
        client: '=',
        selectNewContact: '&'
    }
};
