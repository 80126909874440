const serviceUrl = `${__configuration.apiUrl}/massia/prestations`;

export default class BonsService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getBons(filters, sorts, pagination) {
        const url = `${__configuration.apiUrl}/massia/prestation-bons`;
        const res = await this.$http.get(url, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getUnites() {
        const uniteUrl = `${__configuration.apiUrl}/massia/unites`;
        const res = await this.$http.get(uniteUrl);

        return res.data;
    }

    async deleteBonById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async createBon(bon) {
        return this.$http.post(serviceUrl, bon);
    }

    async updateBon(bon) {
        const url = `${serviceUrl}/${bon.id}`;
        return this.$http.put(url, bon);
    }
}
