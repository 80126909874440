export default function Configure($stateProvider) {
    $stateProvider.state('bennes', {
        parent: 'gestion',
        url: '/bennes',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.TRANSPORT" | translate }}'
        }
    });

    $stateProvider.state('bennes.list', {
        url: '/list',
        template: '<bennes></bennes>',
        rights: { domain: 'vehicule', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "BENNES.BREADCRUMBS.BENNES_LIST" | translate}}'
        },
        navigation: {
            menu: 'vehicule',
            translate: 'BENNES.BREADCRUMBS.BENNES_LIST',
            order: 9
        },
        search: 'BENNES.BREADCRUMBS.BENNES_LIST'
    });
}

Configure.$inject = ['$stateProvider'];
