import validatorBuilder from 'validator-builder';
import AdditionnalInfo from '../nature-additionnal-info/additionnal.info.model';

let caracteristiqueValidator = null;

export default class Caracteristique {
    constructor(data) {
        data = data || {};
        this.id = data.id || undefined;
        this.code = data.code || undefined;
        this.libelle = data.libelle || undefined;
        const setNature = function () {
            if (data.idNature >= 0) {
                return data.idNature;
            }
            return undefined;
        };
        this.idNature = setNature();
        this.domaines = data.domaines || [];
        this.additionnalInfo = data.additionnalInfo || new AdditionnalInfo(this.idNature);

        this.idTypeAide = data.idTypeAide || null;
        this.aideUrl = data.aideUrl || null;
        this.aideToolType = data.aideToolType || null;
        this.document = data.document || null;
    }

    isValid() {
        caracteristiqueValidator = caracteristiqueValidator || validatorBuilder.getInstanceFor('Caracteristique');
        const validationResults = caracteristiqueValidator.validate(this);
        return validationResults.isValid && this.additionnalInfo.isValid();
    }

    getData() {
        const data = {
            id: this.id,
            code: this.code,
            libelle: this.libelle,
            IdDomaines: this.domaines.map((domaine) => domaine.idDomaine),
            idTypeAide: this.idTypeAide,
            aideUrl: this.aideUrl,
            aideToolType: this.aideToolType,
            document: this.document
        };

        data.idNature = this.idNature;

        if (this.additionnalInfo.elements) {
            angular.forEach(this.additionnalInfo.elements, (e, index) => {
                e.ordre = index;
            });
        }

        angular.forEach(this.additionnalInfo, (info, key) => {
            data[key] = info;
        });

        return data;
    }
}
