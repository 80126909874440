import GestionStocksAjoutProduitController from './gestion.stocks.ajout.produit.controller';

export default {
    bindings: {
        produits: '=',
        idSite: '=',
        site: '=',
        date: '=',
        modalInstance: '='
    },
    template: require('./gestion.stocks.ajout.produit.html'),
    controller: GestionStocksAjoutProduitController
};
