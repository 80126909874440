const serviceUrl = `${__configuration.apiUrl}/massia/articles`;

export default class articlesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getArticleById(id) {
        try {
            const res = await this.$http.put(`${serviceUrl}/${id}`);
            return res.data;
        } catch (err) {
            throw err.data;
        }
    }

    async updateArticle(article) {
        try {
            const result = await this.$http.post(`${serviceUrl}`);
            return article.idArticle;
        } catch (err) {
            throw err.data;
        }
    }
    async getListeTVA() {
        try {
            const res = await this.$http.get(`${__configuration.apiUrl}/massia/tvas/headers`);
            return res.data;
        } catch (err) {
            throw err.data;
        }
    }
    async getArticles(siteCommercialId, date) {
        try {
            const filters = {
                siteCommercialId: siteCommercialId,
                date: date
            };
            const url = `${__configuration.apiUrl}/massia/parametres/produits/lies`;

            const res = await this.$http.get(url, {
                params: {
                    filters: filters || {}
                }
            });

            return res.data.produits;
        } catch (err) {
            throw err.data;
        }
    }
    async getUnites() {
        try {
            const res = await this.$http.get(`${__configuration.apiUrl}/massia/unites-list`);
            return res.data;
        } catch (err) {
            throw err.data;
        }
    }
}
