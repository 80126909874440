import { catchError } from 'rxjs/operators';
import { DebugLevel, LoggerService } from '../../../../../../../../../../../../libs/massia-component/massia-service/logger.service';
import CritereValeur from '../../../../../../../../../models/criteres.valeur.models';

/* @ngInject */
export default class CritrereIdTableController {
    constructor(SyntheseSettingService, $translate, $filter, Logger) {
        this.SyntheseSettingService = SyntheseSettingService;
        this.$translate = $translate;
        /**
         * @type LoggerService
         */
        this.logSvc = Logger;
        /**
         * @type ng.IFilterFilter
         */
        this.$filter = $filter;
    }
    $onInit() {
        if (!this.critere.modelSelectionCritereValeur) {
            this.critere.modelSelectionCritereValeur = [];
        }
        this.initCritere(this.critere.modelSelectionCritereValeur);
        this.loadListeValeur();
    }

    loadListeValeur() {
        let strIdsProducteurs = null;
        if (this.critere.producteursSelectionCritereValeur) {
            strIdsProducteurs = this.critere.producteursSelectionCritereValeur.map((e) => e.idValeur).join(',');
        }
        this.SyntheseSettingService.getValeur(this.critere, strIdsProducteurs)
            .pipe(catchError((err) => this.logSvc.log(DebugLevel.ERROR, err)))
            .subscribe((res) => {
                if (res.length > 0) {
                    if (!res) {
                        res = [];
                    }
                    res.findIndex((x) => x.id === 0) === -1 &&
                        res.splice(0, 0, {
                            code: '#',
                            id: 0,
                            libelle: this.$translate.instant('NONE'),
                            label: `${this.$translate.instant('NONE')}`
                        });
                    this.liste = this.critere.isUnique
                        ? this.$filter('selectInIsteven')(res, 'id', this.critere.modelSelectionCritereValeur[0].idValeur, 'selected')
                        : this.$filter('selectInIsteven')(res, 'id', this.critere.modelSelectionCritereValeur, 'selected', 'idValeur');
                }
            });
        this.SyntheseSettingService.loadValeur(this.critere, strIdsProducteurs);
    }

    setValeur(data, crit) {
        if (!crit) {
            crit = new CritereValeur();
        }
        if (typeof data.id === 'number') {
            crit.valeur1 = data.code;
            if (crit.idValeur === data.id) {
                crit.idValeur = -1;
            } else {
                crit.idValeur = data.id;
            }
        } else if (data !== null) {
            if (crit.idValeur === data.id.niveauHierarchiqueId && crit.valeur1 === data.id.elementId) {
                crit.idValeur = -1;
            } else {
                crit.idValeur = data.id.niveauHierarchiqueId;
                crit.valeur1 = data.id.elementId;
            }
        }
    }

    multiSelect() {
        this.critere.modelSelectionCritereValeur = [];
        for (let i = 0; i < this.selected.length; i++) {
            const element = this.selected[i];
            this.critere.modelSelectionCritereValeur.push(new CritereValeur());
            const idx = this.critere.modelSelectionCritereValeur.length - 1;
            this.setValeur(element, this.critere.modelSelectionCritereValeur[idx]);
        }
    }

    deleteLine(liste, id) {
        const i = liste.findIndex((x) => x.idValeur === id);
        liste.splice(i, 1);
    }

    initCritere(list) {
        if (!list[0]) {
            list.push(new CritereValeur());
        }
    }

    $onDestroy() {}
}
