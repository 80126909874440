import FactureRecapController from './recap.controller';

export default {
    bindings: {
        facture: '=',
        isOpen: '='
    },
    template: require('./recap.tpl.html'),
    controller: FactureRecapController
};
