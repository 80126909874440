// import FiltresController from './pont.comptable.model';

export default class CdgFiltresController {
    static $inject = ['$state', '$stateParams', 'CentreGestionService', 'ModalService', 'notification', '$translate', '$filter', '_'];

    constructor($state, $stateParams, CentreGestionService, ModalService, notification, $translate, $filter, _) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.CentreGestionService = CentreGestionService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this.$filter = $filter;
    }

    $onInit() {
        this.domaine = this.domaine;
        this.listes = this.listes;
        this.frontFilters = this.frontFilters;
        this.filtresOpen = true;
        this.hasFiltres = 0;
        this.getlistes();
    }

    async getlistes() {
        if (this.domaine == 'Produits') {
            await this.getProduitEntete(this.source.entete);
        }
    }

    async getProduitEntete(entete) {
        try {
            if (!this.listes.sitesProducteurs) {
                this.listes.sitesProducteurs = await this.CentreGestionService.getProducteurs();
            }
            if (entete.sitesIds) {
                this.listes.producteurs = [];
                for (let i = 0; i < entete.sitesIds.length; i++) {
                    const unId = entete.sitesIds[i];
                    const retour = _.find(this.listes.sitesProducteurs, { id: unId });
                    this.listes.producteurs.push(retour);
                }
            }
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    // méthode pour afficher ou non un filtre dans l'objet filtre
    showThisFilter(filter) {
        let toReturn = false;
        if (
            this.domaine == 'SiteClient' &&
            (filter == 'Societes' || filter == 'SiteUtil' || filter == 'SiteTransp' || filter == 'DateApplication' || filter == 'TypeSociete')
        ) {
            toReturn = true;
            this.hasFiltres++;
        }
        if (this.domaine == 'Chantiers' && (filter == 'Societes' || filter == 'SiteUtil' || filter == 'SiteTransp' || filter == 'DateApplication')) {
            toReturn = true;
            this.hasFiltres++;
        }
        if (
            this.domaine == 'Produits' &&
            (filter == 'Societes' ||
                filter == 'SiteUtil' ||
                filter == 'SiteClient' ||
                filter == 'Chantiers' ||
                filter == 'SiteProd' ||
                filter == 'DateApplication' ||
                filter == 'TVA' ||
                filter == 'TypeSociete' ||
                filter == 'TypeClient')
        ) {
            toReturn = true;
            this.hasFiltres++;
        }

        if (
            this.domaine == 'Prestation' &&
            (filter == 'Societes' ||
                filter == 'SiteUtil' ||
                filter == 'SiteClient' ||
                filter == 'Chantiers' ||
                filter == 'DateApplication' ||
                filter == 'TVA' ||
                filter == 'TypeSociete' ||
                filter == 'TypeClient')
        ) {
            toReturn = true;
            this.hasFiltres++;
        }

        if (
            this.isTransport &&
            (filter == 'Benne' ||
                filter == 'Societes' ||
                filter == 'SiteUtil' ||
                filter == 'SiteClient' ||
                filter == 'Chantiers' ||
                filter == 'DateApplication' ||
                filter == 'TVA' ||
                filter == 'TypeSociete' ||
                filter == 'TypeClient')
        ) {
            toReturn = true;
            this.hasFiltres++;
        }

        if (this.domaine == 'Pays' && filter == 'DateApplication') {
            toReturn = true;
            this.hasFiltres++;
        }
        if (this.domaine == 'SiteUtil' && (filter == 'DateApplication' || filter == 'Benne')) {
            toReturn = true;
            this.hasFiltres++;
        }
        if (this.domaine == 'Societes' && filter == 'Benne') {
            toReturn = true;
            this.hasFiltres++;
        }
        if (
            this.domaine == 'TypeProduit' &&
            (filter == 'Societes' ||
                filter == 'SiteUtil' ||
                filter == 'DateApplication' ||
                filter == 'TVA' ||
                filter == 'TypeSociete' ||
                filter == 'TypeClient')
        ) {
            toReturn = true;
            this.hasFiltres++;
        }
        if (this.domaine == 'SiteTransp' && filter == 'Societes') {
            toReturn = true;
            this.hasFiltres++;
        }
        if (
            this.domaine == 'TypeClient' &&
            (filter == 'Societes' || filter == 'SiteUtil' || filter == 'DateApplication' || filter == 'TypeSociete')
        ) {
            toReturn = true;
            this.hasFiltres++;
        }

        return toReturn;
    }
    filtrer() {
        this.onUpdate();
    }
    reinit() {
        this.onDelete();
    }

    /**###################
    * Services filtres
    ####################*/
    async getSocietes(val) {
        try {
            this.listes.societes = await this.CentreGestionService.getSocietes(val);
            return this.listes.societes;
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async getSiteCom(val) {
        if (this.frontFilters.societes) {
            var idSociete = this.frontFilters.societes.id;
        } else {
            idSociete = null;
        }
        try {
            this.listes.sitesCom = await this.CentreGestionService.getSiteCom(val, idSociete);
            return this.listes.sitesCom;
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async getSiteTransporteur(val) {
        try {
            this.listes.transporteurs = await this.CentreGestionService.getTransporteurs(val);
            return this.listes.transporteurs;
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async getChantiers(val) {
        try {
            this.listes.chantiers = await this.CentreGestionService.getChantiers(val);
            return this.listes.chantiers;
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async getSitesClient(val) {
        try {
            this.listes.clients = await this.CentreGestionService.getSitesClients(val);
            return this.listes.clients;
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async getTypesClient(val) {
        try {
            this.listes.typesClient = await this.CentreGestionService.getTypesClient(val);
            return this.listes.typesClient;
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
