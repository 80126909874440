const serviceUrl = `${__configuration.apiUrl}/massia/plannings/previsionnel-tournee`;

export default class PrevisionnelTourneeService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getPrevisionnelTournee(filters, sorts, pagination, idSite, date) {
        const filtres = {
            idSite: idSite,
            date: date
        };
        const res = await this.$http.get(serviceUrl, {
            params: {
                filtres: JSON.stringify(filtres || {}),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async getSitesCommerciaux() {
        const siteUrl = `${__configuration.apiUrl}/massia/sites-commerciaux/all`;
        const res = await this.$http.get(siteUrl);
        return res.data;
    }

    async exportPrevisionnel(modeImpr, filters, sorts, idSite, date) {
        const filtres = {
            idSite: idSite,
            date: date
        };
        const url = `${serviceUrl}/print`;
        const res = await this.$http.get(url, {
            params: {
                filtres: JSON.stringify(filtres || {}),
                modeImpr: JSON.stringify(modeImpr),
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || [])
            },
            responseType: 'arraybuffer'
        });
        return res;
    }
}
