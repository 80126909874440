export default function Configure($stateProvider) {
    $stateProvider.state('sites', {
        parent: 'common',
        url: '/sites',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('sites.list', {
        url: '/:genre/list',
        template: '<sites></sites>',
        rights: { domain: 'siteutil', right: 'read', application: 'gestion;performance' },
        ncyBreadcrumb: {
            label: '{{ "SITES.BREADCRUMBS.SITES_COMMERCIAUX_LIST" | translate}}'
        }
    });

    $stateProvider.state('sites.niveaux', {
        url: '/:genre/niveaux',
        template: '<site-config-niveaux></site-config-niveaux>',
        rights: { domain: 'nivh', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher sites > niveaux
            parent: 'sites.list({genre: "commerciaux"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_NIVEAUX" | translate }}'
        }
    });

    $stateProvider.state('sites.list.detail', {
        url: '/:genre/{id}',
        template: '<site-detail></site-detail>',
        rights: { domain: 'siteutil', right: 'read' },
        ncyBreadcrumb: {
            parent: 'sites.list({genre: "commerciaux"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('sites.new', {
        url: '/:genre/new',
        template: '<site-form></site-form>',
        rights: { domain: 'siteutil', right: 'create' },
        ncyBreadcrumb: {
            parent: 'sites.list({genre: "commerciaux"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_NEW" | translate}}'
        },
        search: 'SITES.BREADCRUMBS.SITE_NEW'
    });

    $stateProvider.state('sites.edit', {
        url: '/:genre/{id}/edit',
        template: '<site-form></site-form>',
        rights: { domain: 'siteutil', right: 'update' },
        ncyBreadcrumb: {
            parent: 'sites.list({genre: "commerciaux"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_EDIT" | translate }}'
        }
    });

    $stateProvider.state('sites.feries', {
        url: '/:genre/{id}/feries',
        template: '<site-jours-feries></site-jours-feries>',
        rights: { domain: 'jferies', right: 'create' },
        ncyBreadcrumb: {
            parent: 'sites.list({genre: "commerciaux"})',
            label: '{{ "SITES.BREADCRUMBS.JOURS_FERIES" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
