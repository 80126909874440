import Campagne from './campagne.model';
import Prelevement from '../prelevement/prelevement.model';

export default class CampagneFormController {
    static $inject = [
        '$scope',
        '$timeout',
        '$state',
        '$stateParams',
        'PrelevementsCommunicationService',
        'PrelevementsService',
        'CampagnesService',
        'CaracteristiquesTypesService',
        'notification',
        '$location',
        '$anchorScroll',
        '_',
        'MassiaApplicationService',
        '$uibModal',
        'ModalService',
        '$translate',
        'DataShareService',
        'moment',
        'globalizationManagementService'
    ];

    constructor(
        $scope,
        $timeout,
        $state,
        $stateParams,
        PrelevementsCommunicationService,
        PrelevementsService,
        CampagnesService,
        CaracteristiquesTypesService,
        notification,
        $location,
        $anchorScroll,
        _,
        MassiaApplicationService,
        $uibModal,
        ModalService,
        $translate,
        DataShareService,
        moment,
        globalizationManagementService
    ) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.PrelevementsCommunicationService = PrelevementsCommunicationService;
        this.PrelevementsService = PrelevementsService;
        this.CampagnesService = CampagnesService;
        this.CaracteristiquesTypesService = CaracteristiquesTypesService;
        this.notification = notification;
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this._ = _;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$uibModal = $uibModal;
        this.ModalService = ModalService;
        this.$translate = $translate;
        this.DataShareService = DataShareService;
        this.ongletOpen = {
            isEnteteOpen: false,
            isCaracteristiqueOpen: false,
            isListeSaisiesOpen: false
        };
        this.ongletOpenVal = {
            isEnteteOpen: 'entete',
            isCaracteristiqueOpen: 'caracteristiques',
            isListeSaisiesOpen: 'listeSaisies'
        };

        this.moment = moment;
        this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
    }

    async $onInit() {
        this.campagne = {};
        this.bornes = [];
        this.loading = false;
        this.form = {
            entete: {},
            caracteristiques: {},
            listeSaisies: {}
        };
        this.trames = this.trames || [];
        this.references = this.references || [];
        await this.reset();
        this.referenceActuId = this.campagne.entete.referenceControleId;

        this.unregister = [];
        this.unregister.push(
            this.$scope.$watch(
                () => this.campagne.entete.siteProducteurId,
                async () => {
                    this.chargeTramesEtReferences();
                }
            )
        );
    }

    $onDestroy() {
        this.$timeout.cancel(this.updateCampagneTimeout);

        for (let i = 0; i < this.unregister.length; i++) {
            this.unregister[i]();
        }
    }

    async chargeTramesEtReferences() {
        await this.getTrames();
        await this.getReferences();
        await this.changeReferentielActu(this.referenceActuId, true);
    }

    async getTrames() {
        try {
            this.trames = await this.PrelevementsService.getTrames(this.$stateParams.idTheme, this.campagne.entete.siteProducteurId, 0);
            const trameVide = { id: 0, libelle: '' };
            this.trames.splice(0, 0, trameVide);
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
        }
    }

    async getTypesSaisies() {
        try {
            this.typesSaisies = await this.CaracteristiquesTypesService.getAll(this.$stateParams.idTheme, this.getDomaineCourant());
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
        }
    }

    async getReferences() {
        const idxActu = this._.findIndex(this.references, { isDefaut: true });
        let idSelectionneActu = 0;
        if (idxActu > -1) {
            idSelectionneActu = this.references[idxActu].id;
        } else {
            idSelectionneActu = this.campagne.entete.referenceControleId;
        }
        let idSite = 0;
        if (this.campagne.entete && this.campagne.entete.siteProducteurId) {
            idSite = this.campagne.entete.siteProducteurId;
        }

        try {
            this.references = await this.PrelevementsService.getReferences(this.$stateParams.idTheme, idSite);
            //ne prendre que les références du modèle si il y a qui sont spécifiées
            if (this.modeleSaisie && this.modeleSaisie.references && this.modeleSaisie.references.length > 0) {
                const refCopie = angular.copy(this.references);
                for (let i = refCopie.length - 1; i > -1; i--) {
                    const idxRef = this._.findIndex(this.modeleSaisie.references, { referenceId: refCopie[i].id });
                    if (idxRef == -1) {
                        this.references.splice(i, 1);
                    } else {
                        //la référence est à garder
                        //vérifier si c'est celle par défaut
                        if (this.modeleSaisie.references[idxRef].estDefaut) {
                            this.referenceActuId = this.modeleSaisie.references[idxRef].referenceId;
                        }
                        //vérifier si c'est celle pour conformité
                        if (this.modeleSaisie.references[idxRef].estEnregistre) {
                            idSelectionneActu = this.modeleSaisie.references[idxRef].referenceId;
                        }
                    }
                }
            }
            const idx = this._.findIndex(this.references, { id: idSelectionneActu });
            if (idx > -1) {
                this.references[idx].isDefaut = true;
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
        }
    }

    getDomaineCourant() {
        if (
            this.$state.current.name === 'saisies.edit-campagne' ||
            this.$state.current.name === 'saisies.new-campagne' ||
            this.$state.current.name === 'saisies.duplicate-campagne'
        ) {
            return 'saisies';
        } else if (
            this.$state.current.name === 'saisiectrl.edit-campagne' ||
            this.$state.current.name === 'saisiectrl.new-campagne' ||
            this.$state.current.name === 'saisiectrl.duplicate-campagne'
        ) {
            return 'saisiectrl';
        } else if (
            this.$state.current.name === 'saisiesecu.edit-campagne' ||
            this.$state.current.name === 'saisiesecu.new-campagne' ||
            this.$state.current.name === 'saisiesecu.duplicate-campagne'
        ) {
            return 'saisiesecu';
        }
    }

    async reset() {
        this.startLoading();

        const data = {
            id: this.$stateParams.id
        };
        this.modeleSaisie = undefined;
        if (data.id) {
            try {
                data.entete = await this.CampagnesService.getCampagneEnteteById(data.id);
                data.caracteristiques = await this.CampagnesService.getCampagneValeursCaracteristiquesAssociationsById(data.id);
                data.listeSaisies = await this.CampagnesService.getCampagneSaisiesById(data.id);
                data.typeSaisiesId = data.entete.typeSaisiesId;
                data.trameId = data.entete.trameId;
                data.referenceControleId = data.entete.referenceControleId;
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
                this.annuler();
            }
        } else {
            if (this._.isEmpty(this.DataShareService.savedData) == false) {
                // on cherche l'entité saisie
                const dataShareSaisie = this._.find(this.DataShareService.savedData, { entity: 'saisie' });
                if (dataShareSaisie != null) {
                    data.entete = {};
                    if (dataShareSaisie.siteProdIdSelected) {
                        data.entete.siteProducteurId = dataShareSaisie.siteProdIdSelected;
                        data.entete.siteProducteurLibelle = dataShareSaisie.siteProdLibelleSelected;
                    }
                    if (dataShareSaisie.modeleIdSelected) {
                        this.modeleSaisie = await this.PrelevementsService.GetModeleById(dataShareSaisie.modeleIdSelected);
                        if (this.modeleSaisie.siteLaboId) {
                            data.entete.siteAuditeurId = this.modeleSaisie.siteLaboId;
                            data.entete.siteAuditeurLibelle = this.modeleSaisie.siteLaboLibelle;
                        }
                        if (this.modeleSaisie.siteProdId && !data.entete.siteProducteurId) {
                            data.entete.siteProducteurId = this.modeleSaisie.siteProdId;
                            data.entete.siteProducteurLibelle = this.modeleSaisie.siteProdLibelle;
                        }
                        if (this.modeleSaisie.typeCMId) {
                            data.entete.typeId = this.modeleSaisie.typeCMId;
                        }
                        if (this.modeleSaisie.typeSMId) {
                            data.typeSaisiesId = this.modeleSaisie.typeSMId;
                        }
                        if (this.modeleSaisie.trameId) {
                            data.trameId = this.modeleSaisie.trameId;
                        }
                        if (this.modeleSaisie.references && this.modeleSaisie.references.length == 1) {
                            data.referenceControleId = this.modeleSaisie.references[0].referenceId;
                        }
                        if (this.modeleSaisie.aideSaisie) {
                            this.aideSaisie = this.modeleSaisie.aideSaisie;
                        }
                    }
                }
            }
        }
        this.campagne = new Campagne(data);
        if (this.modeleSaisie) {
            if (this.modeleSaisie.pointsMesures && this.modeleSaisie.pointsMesures.length > 0 && this.campagne.entete.siteProducteurId > 0) {
                const saisies = { pointsMesures: [] };
                for (let iPM = 0; iPM < this.modeleSaisie.pointsMesures.length; iPM++) {
                    const saisie = {
                        id: this.modeleSaisie.pointsMesuresIds[iPM],
                        libelle: this.modeleSaisie.pointsMesures[iPM]
                    };
                    saisies.pointsMesures.push(saisie);
                }
                this.addSaisies(saisies);
            }
        }

        if (this.$stateParams.duplicate) {
            this.campagne.id = undefined;
            //this.campagne.entete.code += '_copie';
        }
        this.ongletOpen = {
            isEnteteOpen: true,
            isCaracteristiqueOpen: false,
            isListeSaisiesOpen: false
        };
        await this.getTypesSaisies();
        this.libelleTheme = await this.PrelevementsService.getLibelleTheme(this.$stateParams.idTheme);
        this.$timeout.cancel(this.updateCampagneTimeout);
        this.updateCampagneTimeout = this.$timeout(() => this.stopLoading());
    }

    afficheAide() {
        const msg = this.aideSaisie.replace(/\n/g, '<br/>');
        const modalInstance = this.ModalService.info({
            modalTitle: this.$translate.instant('PRELEVEMENTS.AIDE_SAISIE'),
            modalMsg: this.$translate.instant(msg)
        });
    }

    async sauvegarder() {
        if (this.checkValidity()) {
            this.startLoading();
            try {
                let id = false;
                if (this.campagne.id) {
                    await this.CampagnesService.updateCampagne(this.campagne);
                    this.notification.success('PRELEVEMENTS.UPDATED_CAMPAGNE');
                    id = this.campagne.id;
                } else {
                    id = await this.CampagnesService.createCampagne(this.campagne);
                    this.notification.success('PRELEVEMENTS.CREATED_CAMPAGNE');
                }

                return id;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        }
    }

    async appliquer() {
        const id = await this.sauvegarder();

        if (id && this.campagne.id) {
            this.reset();
        } else if (id) {
            this.$state.go(this.getDomaineCourant() + '.edit-campagne', { idTheme: this.$stateParams.idTheme, id: id });
        }
    }

    async confirmer() {
        const success = await this.sauvegarder();

        if (success) {
            this.annuler();
        }
    }

    annuler() {
        this.$state.go(this.getDomaineCourant() + '.list', { idTheme: this.$stateParams.idTheme });
    }

    async changeReferentielActu(nouvIdRef, bChangeReel) {
        let conformiteCampagne = -1;
        if (this.campagne.listeSaisies) {
            for (let i = 0; i < this.campagne.listeSaisies.saisies.length; i++) {
                if (bChangeReel) {
                    const idxRef = this._.findIndex(this.references, { id: nouvIdRef });
                    this.bornes = [];
                    if (idxRef > -1) {
                        this.bornes = this.references[idxRef].bornes;
                    }
                    this.campagne.listeSaisies.saisies[i].reference.id = nouvIdRef;
                }
                const conformiteSaisie = this.campagne.listeSaisies.saisies[i].conformite;
                if (conformiteSaisie > conformiteCampagne) {
                    conformiteCampagne = conformiteSaisie;
                }
            }
        }
        this.campagne.entete.conformite = conformiteCampagne;
    }

    changeTrame() {
        if (this.campagne.listeSaisies) {
            for (let i = 0; i < this.campagne.listeSaisies.saisies.length; i++) {
                this.campagne.listeSaisies.saisies[i].trame.id = this.campagne.trameId;
            }
        }
    }

    changeReferentielDefaut(nouvDefautId) {
        const idxActu = this._.findIndex(this.references, { isDefaut: true });
        const idxNouveau = this._.findIndex(this.references, { id: nouvDefautId });
        if (idxActu > -1) {
            this.references[idxActu].isDefaut = false;
        }
        if (idxActu != idxNouveau && idxNouveau > -1) {
            this.references[idxNouveau].isDefaut = true;
            this.campagne.referenceControleId = nouvDefautId;
        } else {
            this.campagne.referenceControleId = undefined;
        }
        for (let i = 0; i < this.campagne.listeSaisies.saisies.length; i++) {
            this.campagne.listeSaisies.saisies[i].reference.id = this.campagne.referenceControleId;
        }
    }

    ajoutSaisie = function (size, parentSelector) {
        if (this.campagne.entete.siteProducteurId) {
            const _this = this;
            const modalInstance = this.$uibModal.open({
                animation: true,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                templateUrl: 'ajoutSaisiesModalContent',
                controllerAs: '$ctrl',
                controller: [
                    '$uibModalInstance',
                    '_',
                    function ($uibModalInstance, _) {
                        const $ctrl = this;
                        this.idSite = _this.campagne.entete.siteProducteurId;
                        this.typeFiltreId = _this.campagne.entete.typeId;
                        this.pointsMesureCampagne = [];
                        this.pointsMesureAAjouter = [];
                        for (let i = 0; i < _this.campagne.listeSaisies.saisies.length; i++) {
                            this.pointsMesureCampagne.push({
                                pointsMesures: _this.campagne.listeSaisies.saisies[i].entete.pointMesureId
                            });
                        }
                        this.$uibModalInstance = $uibModalInstance;
                        this._ = _;

                        this.selectPointMesure = function (pointMesure) {
                            const idx = this._.findIndex(this.pointsMesureAAjouter, { id: pointMesure.id });
                            pointMesure.codeSaisie = '';
                            if (idx == -1) {
                                this.pointsMesureAAjouter.push(pointMesure);
                            }
                        };
                        this.onDelete = function (id) {
                            const idx = this._.findIndex(this.pointsMesureAAjouter, { id: id.id });
                            if (idx > -1) {
                                this.pointsMesureAAjouter.splice(idx, 1);
                            }
                        };

                        this.ok = function () {
                            this.$uibModalInstance.close({ pointsMesures: this.pointsMesureAAjouter });
                        };
                        this.cancel = function () {
                            this.$uibModalInstance.close();
                        };
                    }
                ]
            });

            const that = this;
            modalInstance.result.then(function (result) {
                if (result) {
                    that.addSaisies(result);
                }
            });
        } else {
            this.notification.error('PRELEVEMENTS.CHOISIR_PRODUCTEUR_AVANT_AJOUT_SAISIE');
        }
    };

    addSaisies(saisies) {
        this.startLoading();
        for (let i = 0; i < saisies.pointsMesures.length; i++) {
            const obj = {
                entete: {
                    pointMesureId: saisies.pointsMesures[i].id,
                    pointMesure: saisies.pointsMesures[i].libelle,
                    typeId: this.campagne.typeSaisiesId,
                    type: this.campagne.typeSaisies,
                    siteAuditeurId: this.campagne.entete.siteAuditeurId,
                    siteAuditeur: this.campagne.entete.siteAuditeur,
                    siteProducteurId: this.campagne.entete.siteProducteurId,
                    siteProducteur: this.campagne.entete.siteProducteur,
                    personneFaitParId: this.campagne.entete.personneFaitParId,
                    personneFaitPar: this.campagne.entete.personneFaitPar,
                    date: this.campagne.entete.dateFin,
                    estDansCampagne: true,
                    trameId: this.campagne.trameId,
                    referenceControleId: this.campagne.referenceControleId,
                    referenceActuId: this.referenceActuId
                },
                caracteristiques: [],
                mesures: []
            };
            if (this.campagne.listeSaisies.saisies.length > 0) {
                obj.mesures = angular.copy(this.campagne.listeSaisies.saisies[0].mesures);
            }
            this.campagne.listeSaisies.saisies.push(obj);
        }
        this.stopLoading();
    }

    ajoutMesure = function (size, parentSelector) {
        const _this = this;
        const modalInstance = this.$uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            templateUrl: 'ajoutMesuresModalContent',
            controllerAs: '$ctrl',
            controller: [
                '$uibModalInstance',
                '_',
                function ($uibModalInstance, _) {
                    const $ctrl = this;
                    this.typeFiltreId = _this.campagne.entete.typeId;
                    this.mesuresSaisies = [];
                    this.mesuresAAjouter = [];
                    if (_this.campagne.listeSaisies.length > 0) {
                        for (let i = 0; i < _this.campagne.listeSaisies[0].mesures.length; i++) {
                            this.mesuresSaisies.push({
                                essaiId: _this.campagne.listeSaisies[0].mesures[i].id
                            });
                        }
                    }
                    this.$uibModalInstance = $uibModalInstance;
                    this._ = _;

                    this.selectMesure = function (mesure) {
                        const idx = this._.findIndex(this.mesuresAAjouter, { id: mesure.id });
                        if (idx == -1) {
                            this.mesuresAAjouter.push(mesure);
                        }
                    };
                    this.onDelete = function (id) {
                        const idx = this._.findIndex(this.mesuresAAjouter, { id: id.id });
                        if (idx > -1) {
                            this.mesuresAAjouter.splice(idx, 1);
                        }
                    };

                    this.ok = function () {
                        this.$uibModalInstance.close({ mesures: this.mesuresAAjouter });
                    };
                    this.cancel = function () {
                        this.$uibModalInstance.close();
                    };
                }
            ]
        });

        modalInstance.result.then(async (result) => {
            if (result && result.mesures) {
                this.startLoading();
                try {
                    for (let j = 0; j < this.campagne.listeSaisies.saisies.length; j++) {
                        const idxMes = this._.findIndex(result.mesures, { id: this.campagne.listeSaisies.saisies[j].id });
                        if (idxMes == -1) {
                            this.campagne.listeSaisies.saisies[j].mesures = await this.PrelevementsService.getMesuresComplet(
                                this.campagne.listeSaisies.saisies[j].id,
                                result.mesures.map((e) => e.id)
                            );
                            this.campagne.listeSaisies.saisies[j] = new Prelevement(
                                this.campagne.listeSaisies.saisies[j],
                                this.moment,
                                this.dateFormat
                            );
                        }
                    }
                } catch (ex) {
                    this.notification.error(ex.data);
                } finally {
                    this.stopLoading();
                }
            }
        });
    };

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    checkValidity() {
        let validity = true;

        let firstScroll = true;
        if (!this.campagne.entete.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('entete');
                firstScroll = false;
            }

            this.ongletOpen.isEnteteOpen = true;
            validity = false;
            this.$scope.$broadcast('campagneEnteteValidations');
        }
        if (!this.campagne.caracteristiques.isValid()) {
            if (firstScroll) {
                this.scrollToOnglet('caracteristiques');
                firstScroll = false;
            }

            this.ongletOpen.isCaracteristiqueOpen = true;
            validity = false;
            this.$scope.$broadcast('campagneCaracteristiquesValidations');
        }
        return validity;
    }

    scrollToOnglet(id) {
        this.$location.hash(id);
        this.$anchorScroll();
    }

    onUpdateEntete(entete) {
        this.campagne.entete = entete;
        if (this.campagne.entete.dateFin) {
            for (let i = 0; i < this.campagne.listeSaisies.saisies.length; i++) {
                if (!this.campagne.listeSaisies.saisies[i].entete.date) {
                    this.campagne.listeSaisies.saisies[i].entete.date = this.campagne.entete.dateFin;
                }
            }
        }
    }

    onUpdateCaracteristiques(caracteristiques) {
        this.campagne.caracteristiques = caracteristiques;
    }

    onUpdateCaracteristiquesSaisie(index, caracteristiques) {
        this.campagne.listeSaisies.saisies[index].caracteristiques = caracteristiques;
    }

    supprimerSaisie(saisie) {
        const debMsg = 'PRELEVEMENTS.DELETE_SAISIE';
        let libSM = saisie.entete.pointMesure;
        if (saisie.entete.date) {
            libSM += ' (' + saisie.entete.date + ')';
        }
        const modalInstance = this.ModalService.confirm({
            modalTitle: this.$translate.instant(debMsg + '.TITLE', { code: libSM }),
            modalMsg: this.$translate.instant(debMsg + '.MESSAGE'),
            headerClass: 'modal-warning'
        });

        const _this = this;
        modalInstance.result.then(async function () {
            try {
                _this.removeSaisie(saisie);
            } catch (ex) {
                _this.notification.error(ex.data);
            }
        }, _this);
    }

    removeSaisie(saisie) {
        const index = this.campagne.listeSaisies.saisies.indexOf(saisie);
        this.campagne.listeSaisies.saisies.splice(index, 1);
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
