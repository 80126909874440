import PortailStat from './portail.stats.model';

export default class PortailStatsController {
    constructor(UtilisateurPortailService, SharedFileService, SettingPortailService, UserRequestService) {
        this.UserRequestService = UserRequestService;
        this.UtilisateurPortailService = UtilisateurPortailService;
        this.SharedFileService = SharedFileService;
        this.SettingPortailService = SettingPortailService;
        this.id = 0;
    }

    async $onInit() {
        this.stats = [];
        await this.loadUser();
        await this.loadFile();
        await this.loadSetting();
        await this.loadRequest();

        this.stats.sort((a, b) => {
            return a.ordre - b.ordre;
        });
    }

    async loadRequest() {
        try {
            const request = (await this.UserRequestService.getAll()).data || {};
            if (request && request.length > 0) {
                this.stats.push(
                    new PortailStat({
                        id: this.id,
                        text: 'PORTAIL.NAV.REQUEST',
                        total: request.length,
                        type: 'total',
                        icon: 'edit',
                        class: 'warning'
                    })
                );
                this.id++;
            }
        } catch (error) {}
    }

    async loadSetting() {
        try {
            const setting = (await this.SettingPortailService.getSetting()).data || {};
            if (setting) {
                if (!setting.find((x) => x.code === 'Url') || setting.find((x) => x.code === 'Url').value === '') {
                    this.stats.push(
                        new PortailStat({
                            id: this.id,
                            text: 'PORTAIL.STATS.NO_URL',
                            type: 'text',
                            icon: 'ban-circle',
                            class: 'danger',
                            link: 'portail.setting.server'
                        })
                    );
                    this.id++;
                }
                if (
                    !setting.find((x) => x.code === 'MailAuto') ||
                    setting.find((x) => x.code === 'MailAuto').value === '' ||
                    !setting.find((x) => x.code === 'MailPassSender') ||
                    setting.find((x) => x.code === 'MailPassSender').value === '' ||
                    !setting.find((x) => x.code === 'MailPortSender') ||
                    setting.find((x) => x.code === 'MailPortSender').value === '' ||
                    !setting.find((x) => x.code === 'MailServerSender') ||
                    setting.find((x) => x.code === 'MailServerSender').value === ''
                ) {
                    this.stats.push(
                        new PortailStat({
                            id: this.id,
                            text: 'PORTAIL.STATS.NO_MAIL',
                            type: 'text',
                            icon: 'warning-sign',
                            class: 'warning',
                            link: 'portail.setting.mail',
                            ordre: 3
                        })
                    );
                    this.id++;
                }

                if (!setting.find((x) => x.code === 'Contact') || setting.find((x) => x.code === 'Contact').value === '') {
                    this.stats.push(
                        new PortailStat({
                            id: this.id,
                            text: 'PORTAIL.STATS.NO_CONTACT',
                            type: 'text',
                            icon: 'warning-sign',
                            class: 'warning',
                            link: 'portail.setting.mail',
                            ordre: 4
                        })
                    );
                    this.id++;
                }
            }
        } catch (error) {}
    }

    async loadUser() {
        try {
            const users = (await this.UtilisateurPortailService.getAll()).data;
            if (users && users.total) {
                this.stats.push(
                    new PortailStat({
                        id: this.id,
                        text: 'PORTAIL.NAV.USERS',
                        total: users.total,
                        type: 'total',
                        icon: 'user',
                        link: 'portail.users.list',
                        ordre: 1
                    })
                );
                this.id++;
            }
        } catch (error) {}
    }

    async loadFile() {
        try {
            const files = (await this.SharedFileService.getAll()).data;
            if (files && files.total) {
                this.stats.push(
                    new PortailStat({
                        id: this.id,
                        text: 'PORTAIL.NAV.DOC',
                        total: files.total,
                        type: 'total',
                        icon: 'file',
                        link: 'portail.file.list',
                        ordre: 2
                    })
                );
                this.id++;
            }
        } catch (error) {}
    }

    $onDestroy() {}
}

PortailStatsController.$inject = ['UtilisateurPortailService', 'SharedFileService', 'SettingPortailService', 'UserRequestService'];
