import SynthesesConfig from './_config/syntheses.config';
import SynthesesRoutes from './_config/syntheses.routes';
import SynthesesConsoClientComponent from './components/syntheses-conso-client';
import SynthesesService from './services/syntheses.service';
import SynthesesCommunicationService from './services/syntheses.communication.service';

// import SyntheseDetailComponent  from './components/synthese-detail';

const moduleName = 'app.massia.common.syntheses';

angular
    .module(moduleName, [])
    .config(SynthesesConfig)
    .config(SynthesesRoutes)
    .service('SynthesesService', SynthesesService)
    .service('SynthesesCommunicationService', SynthesesCommunicationService)
    .component('synthesesConsoClient', SynthesesConsoClientComponent);
// .component('syntheseDetail', SyntheseDetailComponent)

export default moduleName;
