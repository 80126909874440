import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-slider',
    bindings: {
        massiaSliderModel: '=',
        massiaSliderMin: '<',
        massiaSliderMax: '<',
        massiaSliderOptions: '<',
        massiaSliderId: '<',
        onChange: '&'
    },
    template: require('./massia.slider.tpl.html')
})
export class MassiaSlider {
    constructor() {}

    $onInit() {
        this.sliderOptions = {
            floor: this.massiaSliderMin || 0,
            ceil: this.massiaSliderMax || 100,
            hideLimitLabels: true,
            hidePointerLabels: true,
            id: this.massiaSliderId || (this.massiaSliderOptions ? this.massiaSliderOptions.id : null),
            showSelectionBar: true,
            onChange: (id) => {
                this.onSliderChange(id);
            },
            ...this.massiaSliderOptions
        };

        this.onSliderChange();
    }

    onSliderChange(id = null) {
        if (this.onChange) {
            this.onChange({
                model: this.massiaSliderModel
            });
        }
    }
}

MassiaSlider.$inject = [];
