(function (angular, undefined) {
    'use strict';

    angular.module('blocks.smart.table')
        .directive('stFilterComboboxMultiple', ['$timeout', '_', '$window', '$state', function ($timeout, _, $window, $state) {
            return {
                require: '^stTable',
                restrict: 'A',
                transclude: true,
                templateUrl: 'blocks/smart-table/filter-combobox-multiple.html',
                scope: {
                    predicate: '=stFilterComboboxMultiple',
                    predicateName: '@stFilterComboboxMultiple',
                    stFilterItems: '=?'
                },
                link: function (scope, element, attr, ctrl) {
                    var timer;
                    var stTableData = ctrl.safeCopy;
                    var unregisterDestroyEvent = scope.$on('$destroy', dispose);
                    var unregisterFilterSettedEvent = scope.$on('event:stPredicateObjectSetted', predicateObjectUpdated);
                    var slideboxEl, pinnedTableEl;
                    element.addClass('st-filter');

                    // Customize item model attr used
                    scope.stFilterCustomId = attr.stFilterCustomId || 'id';
                    scope.stFilterCustomLabel = attr.stFilterCustomLabel || 'label';

                    if (attr.stFilterItems === undefined) {
                        scope.stFilterItems = [];
                        var filter = _.getDistinctPropertyValues(stTableData, scope.predicateName);
                        angular.forEach(filter, function (value, key) {
                            scope.stFilterItems.push({id: value, label: value});
                        });
                    }

                    // On utilise le filtre custom
                    ctrl.setFilterFunction('acTableFilter');

                    scope.eraseFilter = function () {
                        // si une valeur est entrée
                        if (scope.predicate) {
                            // on l'efface
                            scope.predicate.txt = undefined;
                        }
                        scope.currentFilter = '';
                        // on vide la sélection
                        scope.selectedItems = [];
                        // on déselectionne les items dans la combobox multiple
                        angular.forEach(scope.stFilterItems, function (item) {
                            delete item.ticked;
                        });
                        // on lance ensuite la recherche à vide (pour rappatrier tout)
                        ctrl.search(undefined, scope.predicateName);
                        scope.filtering = false;
                        scope.filter.isOpen = false;
                    };

                    scope.filter = function () {
                        scope.setFilterInfos();
                        scope.applyFilter();
                        scope.filter.isOpen = false;
                    };

                    scope.setFilterInfos = function () {
                        scope.filtering = scope.selectedItems && scope.selectedItems.length > 0;
                    };

                    scope.applyFilter = function () {
                        if (scope.selectedItems && scope.selectedItems.length > 0) {
                            var itemsId = _.map(scope.selectedItems, scope.stFilterCustomId);
                            ctrl.search(itemsId, scope.predicateName);
                        } else {
                            ctrl.search(undefined, scope.predicateName);
                        }
                    };

                    // Dans le cas d'une table pinned, on ferme le dropdown au scroll
                    var slidebox = globalHelpers.findAncestorByClassName(element[0], "slidebox");
                    var pinnedTable = globalHelpers.findAncestorByClassName(element[0], "st-table-pinned");

                    if (slidebox) {
                        slideboxEl = angular.element(slidebox);
                        slideboxEl.bind('scroll', onScroll);
                    }

                    if (pinnedTable) {
                        pinnedTableEl = angular.element(pinnedTable);
                        pinnedTableEl.bind('scroll', onScroll);
                    }

                    function onScroll(e) {
                        timer = $timeout(function () {
                            scope.filter.isOpen = false;
                        });
                    }

                    function predicateObjectUpdated(evt, predicateObject) {
                        unselectAll();

                        if (angular.isObject(predicateObject) && angular.isArray(predicateObject[scope.predicateName]) && predicateObject[scope.predicateName].length > 0) {
                            var toSelect = [].concat(predicateObject[scope.predicateName]);

                            angular.forEach(scope.stFilterItems, function (listItem) {
                                var predicateIndex = toSelect.indexOf(listItem[scope.stFilterCustomId]);

                                if (predicateIndex !== -1) {
                                    listItem.ticked = true;
                                    scope.selectedItems.push(listItem[scope.stFilterCustomId]);
                                    toSelect.splice(predicateIndex, 1); // Item selected
                                } else {
                                    listItem.ticked = false;
                                }
                            });

                            if (toSelect.length > 0) { // Des éléments n'existent pas dans la liste, on les retire du predicateObject
                                predicateObject[scope.predicateName] = _.difference(predicateObject[scope.predicateName], toSelect);
                            }

                            if (predicateObject[scope.predicateName].length === 0) { // Aucun élément trouvé dans la liste, on retire le filtre du predicateObject
                                predicateObject[scope.predicateName] = undefined;
                            }
                        }

                        scope.setFilterInfos();
                    }

                    function selectedDefault() {
                        const currentPage = $state.current.name;
                        const currentFilters = $window.localStorage[currentPage];
                        let idsSelected = [];
                        if(currentFilters && currentFilters !== ""){
                            let filters = JSON.parse(currentFilters);
                            if(filters?.search?.predicateObject && filters?.search?.predicateObject.hasOwnProperty(scope.predicateName))
                                idsSelected = filters?.search.predicateObject[scope.predicateName];

                        }
                        
                        // if()
                        if(idsSelected && idsSelected.length > 0) {
                            scope.filtering = true;
                            for(let i = 0; i < idsSelected.length; i++) {
                                var id = idsSelected[i];
                                for(let j = 0; j < scope.stFilterItems.length; j++) {
                                    var item = scope.stFilterItems[j];
                                    if(item.id === id) {
                                        item.ticked = true;
                                    }
                                }
                            }
                        }
                    }

                    function getListItem() {
                        if(!scope.stFilterItems) {
                            setTimeout(getListItem, 250);
                        } else {
                            selectedDefault();
                        }
                    }

                    function unselectAll() {
                        scope.selectedItems = [];
                        angular.forEach(scope.stFilterItems, function (e) {
                            e.ticked = false;
                        });
                    }

                    function dispose() {
                        unregisterFilterSettedEvent();
                        unregisterDestroyEvent();
                        element.removeClass('st-filter');

                        if (timer) {
                            $timeout.cancel(timer);
                        }
                        if (slideboxEl !== undefined) {
                            slideboxEl.unbind('scroll', onScroll);
                        }
                        if (pinnedTableEl !== undefined) {
                            pinnedTableEl.unbind('scroll', onScroll);
                        }
                    }

                    getListItem();
                    scope.setFilterInfos();

                }
            };
        }]);
})(angular);