import { Caracteristique } from "../../../../../../core/models/caracteristique/Caracteristique";
import { ValeurCaracteristique } from "../../../../../../core/models/valeur-caracteristique/ValeurCaracteristique";

class CaractListDateController {
    caracteristique: Caracteristique;
    valeurs: ValeurCaracteristique[];
    model: ValeurCaracteristique[] = [];
    index: number;
    defaultValue: String;
    /* @ngInject */
    constructor() {}
    $onInit(): void {
        const value = this.valeurs.filter(x => x.idCaracteristique === this.caracteristique.id);
        if (!value || value.length === 0) {
            let valeur = <ValeurCaracteristique>{
                id: 0,
                idCaracteristique: this.caracteristique.id,
                value: this.defaultValue || this.defaultValue === '0' ? this.defaultValue : null
            };
            this.model.push(valeur);
            this.valeurs.push(valeur)
        }
        for (let i = 0; i < value.length; i++) {
            const v = value[i];
            this.model.push(v);
        }
    }
    $onDestroy(): void {}
    addLine(): void {
        let valeur = <ValeurCaracteristique>{
            id: 0,
            idCaracteristique: this.caracteristique.id,
            value: null
        };
        this.model.push(valeur);
        this.valeurs.push(valeur);
    }
    removeLine(index: number): void {
        const idx = this.valeurs.findIndex(x => x.idCaracteristique === this.model[index].idCaracteristique && x.value === this.model[index].value);
        this.valeurs.splice(idx, 1);
        this.model.splice(index, 1);
    }
}
export default {
    bindings: {
        caracteristique: '<',
        valeurs: '=',
        isObligatoire: '<',
        lecture: '<',
        defaultValue: '='
    },
    controller: CaractListDateController,
    template: require('./caracteristique-list-date.html')
};
