import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';
import enLocale from '@fullcalendar/core/locales/en-gb';
import esLocale from '@fullcalendar/core/locales/es';
import interactionPlugin from '@fullcalendar/interaction';
import { Injectable } from '../../../core/decorators';

/**
 * @property {Calendar} calendar
 */
@Injectable('CalendarComponentService')
export class CalendarComponentService {
    weeks = {
        FIRST: 0,
        SECOND: 1,
        THIRD: 2,
        FOURTH: 3,
        LAST: 4
    };
    days = {
        SUNDAY: 0,
        MONDAY: 1,
        TUESDAY: 2,
        WEDNESDAY: 3,
        THURSDAY: 4,
        FRIDAY: 5,
        SATURDAY: 6
    };

    constructor(moment, globalizationManagementService, $document) {
        /**
         * @type Calendar
         */
        this.calendar = null;

        /**
         * @type Array<CalendarEvent|BackgroundEvent>
         */
        this.events = [];

        /**
         * @type import('moment').Moment
         */
        this.moment = moment;
        this.language = globalizationManagementService.getCurrentLanguage();
        this.dateFormat = this.language.dateFormat;
        this.$document = $document;
    }

    /**
     *
     * @param {String} elementId Id de la div dans laquel sera le calendrier
     * @param {CalendarOptions} options La langue d'affichage du calendrier (defaut = fr)
     */
    createCalendar(elementId, options) {
        if (!options) options = new CalendarOptions(options);
        var calendarEl = document.getElementById(elementId);
        this.calendar = new Calendar(calendarEl, {
            height: '100%',
            contentHeight: 'auto',
            plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
            locales: [frLocale, enLocale, esLocale],
            locale: options.locale || this.language.locale,
            headerToolbar: {
                center: 'dayGridMonth,timeGridWeek,listWeek'
            },
            views: {
                dayGrid: {
                    titleFormat: { year: 'numeric', month: 'long' }
                },
                timeGrid: {
                    titleFormat: { year: 'numeric', month: 'long', day: 'numeric' }
                },
                listWeek: {
                    titleFormat: { year: 'numeric', month: 'long', day: 'numeric' }
                }
            },
            eventDidMount: function (info) {
                if (info.event.extendedProps.visibility || typeof info.event.extendedProps.visibility === 'number') {
                    const visibility = info.event.extendedProps.visibility;
                    if (info.event.extendedProps.eventClick) $(info.el).css('cursor', 'pointer');
                    if (visibility === true || visibility === 1 || visibility === 'show') {
                        info.event.setProp('display', 'auto');
                    } else if (visibility === 0 || visibility === 'hide' || visibility === false) {
                        info.event.setProp('display', 'none');
                    }
                }
                if (info.event.extendedProps.eventRender) {
                    info.event.extendedProps.eventRender(info.event, info.el, info.view);
                }
            },
            eventClick: function (info) {
                if (info.event.extendedProps.eventClick) {
                    info.event.extendedProps.eventClick(info.event, info.el, info.view);
                }
            },
            eventMouseEnter: function (info) {
                if (info.event.extendedProps.mouseEnter) {
                    info.event.extendedProps.mouseEnter(info.event, info.el, info.view);
                }
            },
            eventMouseLeave: function (info) {
                if (info.event.extendedProps.mouseLeave) {
                    info.event.extendedProps.mouseLeave(info.event, info.el, info.view);
                }
            },
            dayCellDidMount: function (info) {
                if (options.dateRender) {
                    options.dateRender(info.date, info.el);
                }
            },
            dateClick: function (info) {
                if (options.dateClick) {
                    options.dateClick(info.date, info.dayEl, info.jsEvent);
                }
            }
        });

        this.calendar.render();
    }

    /**
     *
     * @param {RecurringOptions} options Object containing all options of recurrence
     * @param {function(Object)} callback function((date) => {})
     */
    recurringDate(options, callback) {
        if (!options) throw new Error('Le paramètre options est obligatoire | recurringDate(options, callback)');
        if (!options instanceof RecurringOptions) throw new Error('Le paramètre options doit être une instance de RecurringOptions');
        if (options.start && options.end) {
            const tmpStart = this.convertDate(options.start);
            const tmpEnd = this.convertDate(options.end);
            for (var m = this.moment(tmpStart); m.isSameOrBefore(tmpEnd); m.add(options.recur, options.type)) {
                switch (options.type) {
                    case 'days':
                        callback(m);
                        break;
                    case 'weeks':
                        for (let i = 0; i < options.dow.length; i++) {
                            const day = options.dow[i];
                            const indexOfDay = this._getIndexOfDay(day);
                            if (this.moment(m).day(indexOfDay).isSameOrAfter(tmpStart) && this.moment(m).day(indexOfDay).isSameOrBefore(tmpEnd)) {
                                callback(this.moment(m).day(indexOfDay));
                            }
                        }
                        break;
                    case 'months':
                        if (!options.dom.weeks && options.dom.weeks !== 0) {
                            if (!Number.isInteger(options.dom.day)) throw new Error('La propriété dom.day doit être de type number');
                            if (
                                this.moment(m).date(options.dom.day).isSameOrAfter(tmpStart) &&
                                this.moment(m).date(options.dom.day).isSameOrBefore(tmpEnd)
                            ) {
                                callback(this.moment(m).date(options.dom.day));
                            }
                        } else {
                            const indexOfDay = this._getIndexOfDay(options.dom.day);
                            const res = this._getSpecificDateOfMonth(m, indexOfDay, options.dom.weeks);
                            if (res.isSameOrAfter(tmpStart) && res.isSameOrBefore(tmpEnd)) {
                                callback(res);
                            }
                        }
                        break;
                    case 'years':
                        if (!options.doy.weeks && options.doy.weeks !== 0) {
                            if (!Number.isInteger(options.doy.day)) throw new Error('La propriété doy.day doit être de type number');
                            const month = this._getIndexOfMonth(options.doy.month);
                            if (
                                this.moment(m).month(month).date(options.doy.day).isSameOrAfter(tmpStart) &&
                                this.moment(m).month(month).date(options.doy.day).isSameOrBefore(tmpEnd)
                            ) {
                                callback(this.moment(m).month(month).date(options.doy.day));
                            }
                        } else {
                            const indexOfDay = this._getIndexOfDay(options.doy.day);
                            const month = this._getIndexOfMonth(options.doy.month);
                            const res = this._getSpecificDateOfMonth(this.moment(m).month(month), indexOfDay, options.doy.weeks);
                            if (res.isSameOrAfter(tmpStart) && res.isSameOrBefore(tmpEnd)) {
                                callback(res);
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }

    //Private method
    _getSpecificDateOfMonth(date, dayOfWeek, weekNumber) {
        var month = this.moment(date).startOf('month');
        var firstDayOfWeek = month.clone().day(dayOfWeek);
        if (firstDayOfWeek.month() < month.month()) {
            weekNumber++;
        } else if (firstDayOfWeek.month() > month.month()) {
            weekNumber--;
        }
        var result = firstDayOfWeek.add(weekNumber, 'weeks');
        while (result.month() !== month.month()) {
            if (result.year() === month.year()) {
                if (result.month() < month.month()) {
                    result = result.add(1, 'weeks');
                } else if (result.month() > month.month()) {
                    result = result.subtract(1, 'weeks');
                }
            } else if (result.year() > month.year()) {
                result = result.subtract(1, 'weeks');
            } else if (result.year() < month.year()) {
                result = result.add(1, 'weeks');
            }
        }
        return result;
    }

    // Private method
    _getIndexOfMonth(month) {
        if (typeof month === 'string') {
            switch (month) {
                case 'january':
                case 'jan':
                    return 0;
                case 'february':
                case 'feb':
                    return 1;
                case 'march':
                case 'mar':
                    return 2;
                case 'april':
                case 'apr':
                    return 3;
                case 'may':
                    return 4;
                case 'june':
                case 'jun':
                    return 5;
                case 'july':
                case 'jul':
                    return 6;
                case 'august':
                case 'aug':
                    return 7;
                case 'september':
                case 'sep':
                    return 8;
                case 'october':
                case 'oct':
                    return 9;
                case 'november':
                case 'nov':
                    return 10;
                case 'december':
                case 'dec':
                    return 11;
                default:
                    throw new Error(`${month} n'est pas un nom de mois valide. Exemple 'september', 'sep' ou 8 est valide`);
            }
        } else if (typeof month === 'number') {
            return month;
        } else {
            throw new Error("doy.month n'est pas valide");
        }
    }

    // Private method
    _getIndexOfDay(day) {
        if (typeof day === 'string') {
            switch (day.toLowerCase()) {
                case 'sunday':
                case 'sun':
                    return 0;
                case 'monday':
                case 'mon':
                    return 1;
                case 'tuesday':
                case 'tue':
                    return 2;
                case 'wednesday':
                case 'wed':
                    return 3;
                case 'thursday':
                case 'thu':
                    return 4;
                case 'friday':
                case 'fri':
                    return 5;
                case 'saturday':
                case 'sat':
                    return 6;
                default:
                    throw new Error(`${day} n'est pas un nom de jour valide. Exemple monday ou mon est valide`);
            }
        } else if (typeof day === 'number') {
            return day;
        } else {
            throw new Error("Une entrée du tableau dow n'est pas valide");
        }
    }

    /**
     *
     * @param {String|Date} date Date à convertir
     * @returns {Object} Objet moment pour la date
     */
    convertDate(date) {
        var m = this.moment(date, this.dateFormat);
        if (m.isValid()) return m;
        else {
            m = this.moment(date, this.moment.ISO_8601);
            if (m.isValid()) return m;
            else {
                m = this.moment(date.split('/').reverse().join('-'), this.moment.ISO_8601);
                if (m.isValid()) return m;
                throw new Error(`${date} n'est pas au bon format, le format doit être 'dd/MM/yyyy' ou un objet Date`);
            }
        }
    }

    renderCalendar() {
        this.calendar.render();
    }

    rerenderEvents() {
        this.calendar.render();
    }

    renderEvents() {
        this.calendar.removeAllEventSources();
        this.calendar.addEventSource(this.events);
        this.calendar.refetchEvents();
    }

    clean() {
        if (this.calendar) {
            var sources = this.calendar.getEventSources();
            for (let i = 0; i < sources.length; i++) {
                const source = sources[i];
                source.remove();
            }
            this.events = [];
            this.calendar.refetchEvents();
        }
    }

    /**
     *
     * @param {CalendarEvent|BackgroundEvent} event
     */
    addEvent(event) {
        if (!this.calendar) throw new Error("Le calendrier n'à pas encore était crée utiliser la méthode createCalendar pour le crée");
        if (!this.events) this.events = [];
        let idx = this.events.findIndex((x) => x.id === event.id);
        if (idx >= 0) {
            this.events[idx] = event;
            return;
        }
        this.events.push(event);
        return;
        //this.calendar.addEvent(event);
    }

    getEventById(id) {
        if (!this.calendar) throw new Error("Le calendrier n'à pas encore était crée utiliser la méthode createCalendar pour le crée");
        return this.events.find((x) => x.id === id);
    }

    getAllEvents() {
        if (!this.calendar) throw new Error("Le calendrier n'à pas encore était crée utiliser la méthode createCalendar pour le crée");
        return this.events;
    }
}

export class CalendarOptions {
    /**
     *
     * @param {Object} options Options to pass in calendar
     * @param {String} options.locale Locale language. Default 'fr'
     * @param {function(Object)} options.dateClick Event fired on date click
     * @param {function(Object)} options.dateRender Event fired on date render
     */
    constructor(options) {
        options = options || {};
        this.locale = options.locale || null;
        this.dateClick = options.dateClick || null;
        this.dateRender = options.dateRender || null;
    }
}

export class RecurringOptions {
    /**
     *
     * @param {Object} options Options to pass
     * @param {(String|Date|Object)} options.start (Required) Start date of recur format:'dd/MM/yyyy' or Date
     * @param {(String|Date|Object)} options.end (Optional) End date of recur format:'dd/MM/yyyy' or Date
     * @param {String} options.type (Required) Recurrence type ('days', 'weeks', 'months', 'years')
     * @param {Number} options.recur (Optional|Default:1) Determine each recurrence example: recur every 2 weeks
     * @param {(Array.<String>|Array.<Number>)} options.dow (Required if type == 'weeks') Days of weeks ['monday', 'tuesday', ...] or with index of days [0, 1, ...]
     * @param {Object} options.dom (Required if type == 'months')
     * @param {(String|Number)} options.dom.day Day to select in month. If is a simple recur day it's in number. If you want to choose weeks you need to specify what day is with is index or title like 'monday', 'tuesday'
     * @param {Number} options.dom.weeks If is the first, second, third or last week in month
     * @param {Object} options.doy (Required if type == 'years')
     * @param {(String|Number)} options.doy.day Day to select in Number for simple recur and index or string for special recur
     * @param {Number} options.doy.weeks If is the first, second, third or last week in month
     * @param {(String|Number)} options.doy.month Month of year like january, february or index of month
     */
    constructor(options) {
        if (options.type === 'weeks' && !options.dow && options.dow.length === 0)
            throw new Error('La propriété dow ne doit pas être null, undefined ou vide');
        this.start = options.start;
        this.end = options.end || options.start;
        this.type = options.type;
        this.recur = options.recur || 1;
        this.dow = options.dow || null;
        this.dom = options.dom || null;
        this.doy = options.doy || null;
    }
}

export class BackgroundEvent {
    constructor(event) {
        this.rendering = 'background';
        this.start = event.start;
        this.end = event.end;
        this.backgroundColor = '#FFA07A';
    }

    _hexToRgb(hex) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16)
              }
            : null;
    }

    _setContrast(rgb) {
        const color = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 125 ? '#484848' : '#fff';
        return color;
    }
}

export class CalendarEvent {
    constructor(event) {
        this.id = event.id || null;
        this.title = event.title;
        this.start = event.start;
        this.end = event.end;
        this.startTime = event.startTime || null;
        this.endTime = event.endTime || null;
        this.daysOfWeek = event.daysOfWeek || null;
        this.startRecur = event.startRecur || null;
        this.endRecur = event.endRecur || null;
        this.editable = event.editable || false;
        this.backgroundColor = event.backgroundColor || null;
        if (event.backgroundColor) {
            this.textColor = this._setContrast(this._hexToRgb(event.backgroundColor));
        }
        this.rendering = event.rendering || null;
        this.visibility = event.visibility;
        this.classNames = event.classNames;
        this.borderColor = event.borderColor || null;

        //Callback à utiliser si vous savez quoi en faire
        this.eventClick = event.eventClick || null;
        this.mouseEnter = event.mouseEnter || null;
        this.mouseLeave = event.mouseLeave || null;
        this.eventRender = event.eventRender || null;
        this.dayClick = event.dayClick || null;
        //End callback

        this.data = event.data || {};
    }

    _hexToRgb(hex) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16)
              }
            : null;
    }

    _setContrast(rgb) {
        const color = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 125 ? '#484848' : '#fff';
        return color;
    }
}

CalendarComponentService.$inject = ['moment', 'globalizationManagementService', '$document'];
