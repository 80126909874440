import CaracteristiquesSelectController from './caracteristiques.select.controller';

export default {
    template: require('./caracteristiques.select.tpl.html'),
    controller: CaracteristiquesSelectController,
    bindings: {
        clearOnSelect: '<',
        selected: '<',
        excluded: '<',
        onSelect: '&',
        onLoad: '&'
    }
};
