export default function GroupListController($stateParams, GroupPortailService, PaginationService, notification, $state, ModalService, $translate) {
    const $ctrl = this;

    this.$onInit = () => {
        _toggleLoading(true);
        $ctrl.itemsByPageOptions = [20, 50, 100];
        $ctrl.groupList = [];
        $ctrl.itemsByPageOptions = [20, 50, 100];
    };

    this.$onDestroy = () => {};

    $ctrl.loadGroup = async (tableState) => {
        try {
            _toggleLoading(true);
            if (tableState) {
                previousTableState = tableState;
            }
            const filters = PaginationService.getFilters(previousTableState);
            const sorts = PaginationService.getSorts(previousTableState);
            const pagination = PaginationService.getPagination(previousTableState);

            const res = await GroupPortailService.getAll(filters, sorts, pagination);
            $ctrl.groupList = res.data.items;

            if (previousTableState) {
                PaginationService.setTableState(res.data.skip, res.data.take, res.data.total, previousTableState);
            }
            _toggleLoading();
        } catch (err) {
            console.error(err);
            if (err.data && err.data.message) {
                notification.error(err.data.message);
            } else {
                notification.error(err.message);
            }
            _toggleLoading();
        }
    };

    _toggleLoading = (value) => {
        $ctrl.loading = value || !$ctrl.loading;
    };

    $ctrl.update = (group) => {
        $state.go('portail.group.edit', {
            id: group.id
        });
    };

    $ctrl.delete = (group) => {
        ModalService.confirm({
            modalTitle: $translate.instant('PORTAIL.GROUP.DELETED.TITLE'),
            modalMsg: `${$translate.instant('PORTAIL.GROUP.DELETED.MESSAGE')} ${group.libelle}`,
            headerClass: 'modal-danger'
        }).result.then(async () => {
            try {
                await GroupPortailService.delete(group.id);
                notification.success('PORTAIL.GROUP.DELETED.SUCCESS');
                return await $ctrl.loadGroup();
            } catch (err) {
                console.log(err);
                if (err.data && err.data.message) {
                    return notification.error(err.data.message);
                }
                return notification.error(err.message);
            }
        });
    };
}

GroupListController.$inject = ['$stateParams', 'GroupPortailService', 'PaginationService', 'notification', '$state', 'ModalService', '$translate'];
