export default class TrameSaisieDetailController {
    static $inject = [
        '$stateParams',
        '$state',
        'TramesSaisiesService',
        'notification',
        'TramesSaisiesCommunicationService',
        'MassiaApplicationService'
    ];

    constructor($stateParams, $state, TramesSaisiesService, notification, TramesSaisiesCommunicationService, MassiaApplicationService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.TramesSaisiesService = TramesSaisiesService;
        this.notification = notification;
        this.TramesSaisiesCommunicationService = TramesSaisiesCommunicationService;
        this.MassiaApplicationService = MassiaApplicationService;
    }

    $onInit() {
        this.loading = false;
        this.trame = {};

        this.ongletOpen = {
            isEnteteOpen: true
        };

        this.initTrame();
    }

    getRouteCourante() {
        if (this.$state.current.name === 'refsaisieenv.list' || this.$state.current.name === 'refsaisieenv.list.detail') {
            return 'refsaisieenv';
        } else if (this.$state.current.name === 'refsaisiectrl.list' || this.$state.current.name === 'refsaisiectrl.list.detail') {
            return 'refsaisiectrl';
        } else if (this.$state.current.name === 'refsaisiesecu.list' || this.$state.current.name === 'refsaisiesecu.list.detail') {
            return 'refsaisiesecu';
        } else if (this.$state.current.name === 'refsaisie.list' || this.$state.current.name === 'refsaisie.list.detail') {
            return 'refsaisie';
        }
    }

    async initTrame() {
        this.trame = { id: this.$stateParams.id };
        if (this.trame.id) {
            this.startLoading();
            try {
                this.trame = await this.TramesSaisiesService.getTrameById(this.trame.id);
            } catch (ex) {
                if (ex.status === 404) {
                    this.goListTrame();
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.goListTrame();
        }
    }

    async getTrame(trameId) {
        this.trame = await this.TramesSaisieService.getTrameById(trameId);
    }

    goListTrame() {
        this.$state.go(this.getRouteCourante() + '.list', {}, { reload: true });
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
