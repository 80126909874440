ZoneValidator.$inject = ['validator', 'validationHelper'];

export default function ZoneValidator(validator, validationHelper) {
    const instance = new validator();
    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').lengthBetweenValues(0, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    return instance;

    function beInteger(obj, prop) {
        return !isNaN(prop);
    }

    function beNull(obj, prop) {
        if (typeof prop !== 'undefined' && prop) {
            return false;
        }
        return true;
    }
}
