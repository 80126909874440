export default class FormuleCalculsService {
	/*@ngInject*/
	constructor(
		$http,
		FormuleService,
		ProduitsService,
		CoefficientkService,
		ElementCaracteristiqueService,
		globalizationManagementService,
		ParametreProgrammeService,
		FuseauxGranulometriquesService,
		FacteurEmissionCo2Service,
		SiteDistancePoidsCo2Service,
		ModalService,
		$stateParams,
		$state,
		$translate,
		moment
	) {
		this.$http = $http;
		this.FormuleService = FormuleService;
		this.ProduitsService = ProduitsService;
		this.CoefficientkService = CoefficientkService;
		this.ElementCaracteristiqueService = ElementCaracteristiqueService;
		this.ParametreProgrammeService = ParametreProgrammeService;
		this.FuseauxGranulometriquesService = FuseauxGranulometriquesService;
		this.FacteurEmissionCo2Service = FacteurEmissionCo2Service;
		this.SiteDistancePoidsCo2Service = SiteDistancePoidsCo2Service;
		this.ModalService = ModalService;
		this.$stateParams = $stateParams;
		this.$state = $state;
		this.$translate = $translate;
		this.moment = moment;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	}

	async init(formule, parametresFormulation) {
		this.formule = formule;
	}

	getDomaineCourant() {
		return this.$state.current.name.substring(0, this.$state.current.name.indexOf('.'));
	}

	async getValeurReference(element, codeParam, lstAllValeursRef) {
		let valeurDeRef = null;
		if (lstAllValeursRef) {
			const lstValRef = lstAllValeursRef.filter(
				(e) => e.idProduit === element.idElement && e.idSite === element.idProducteur && e.codeParam === codeParam
			);
			if (lstValRef && lstValRef.length > 0) {
				if (this.formule.dateFinValidite) {
					valeurDeRef = lstValRef.find(
						(e) => this.moment(e.date, this.dateFormat) <= this.moment(this.formule.dateFinValidite, this.dateFormat)
					);
				} else {
					valeurDeRef = lstValRef.sort((a, b) => {
						return this.moment(b.date, this.dateFormat) - this.moment(a.date, this.dateFormat);
					})[0];
				}
			}
		} else {
			valeurDeRef = await this.FormuleService.getValeurReference(
				element.idElement,
				element.idProducteur,
				codeParam,
				this.formule.dateCreation,
				this.formule.dateFinValidite
			);
		}
		return valeurDeRef;
	}

	async recuperationCiment(lstAllValeursRef = null) {
		if (!this.formule) {
			throw new Error('Il faut dabord utiliser la methode init pour envoyer la formule');
		}

		const tmpCiment = {
			cimentId: null,
			cimentPoids: 0,
			classeVraieDuCiment: 0,
			sommeMVCim: 0,
			natureCiment: [],
			refChlorureCiment: 0,
			poidsChlorureCiment: 0,
			idEssaiChlorure: -1,
			poidsAlcalinCiment: 0,
			idEssaiAlcalin: -1,
			distanceParTypeTransportTotal: 0,
			totalEmissionCo2: 0
		};

		if (this.formule && this.formule.composants) {
			const compoCiment = this.formule.composants
				.filter((c) => c.codeFamille === 'CIM')
				.sort((a, b) => Number.parseFloat(b.poids) - Number.parseFloat(a.poids));
			if (compoCiment && compoCiment.length > 0) {
				tmpCiment.cimentId = compoCiment[0].idComposant;
			}
		}

		for (let i = 0; i < this.formule.composants.length; i++) {
			const element = this.formule.composants[i];
			if (element.codeFamille === 'ADD') {
				await this.ProduitsService.getProduitValeursCaracteristiquesById(Number.parseInt(element.idComposant));
			} else if (element.codeFamille === 'CIM') {
				const produitCaracteristique = await this.ProduitsService.getProduitValeursCaracteristiquesById(Number.parseInt(element.idComposant));
				if (produitCaracteristique && produitCaracteristique.length > 0) {
					const idxNatureCiment = produitCaracteristique.findIndex((x) => x.code === 'NatCim');
					if (
						idxNatureCiment > -1 &&
						produitCaracteristique[idxNatureCiment].value &&
						produitCaracteristique[idxNatureCiment].value.length > 0
					) {
						for (let j = 0; j < produitCaracteristique[idxNatureCiment].value.length; j++) {
							const eltCaract = produitCaracteristique[idxNatureCiment].value[j];

							if (tmpCiment.natureCiment && eltCaract) {
								if (!tmpCiment.natureCiment.map((e) => e.key).includes(eltCaract.key)) {
									tmpCiment.natureCiment.push(eltCaract.key);
								}
							}
						}
					}
				}
				tmpCiment.cimentPoids += Number.parseFloat(element.poids);

				let valeurDeRefCiment = null;
				valeurDeRefCiment = await this.getValeurReference(element, 'EssCV28Cim', lstAllValeursRef);
				if (valeurDeRefCiment && valeurDeRefCiment.valeur) {
					tmpCiment.classeVraieDuCiment = Number.parseFloat(valeurDeRefCiment.valeur);
				}

				if (element.volume) {
					tmpCiment.sommeMVCim += Number.parseFloat(element.volume);
				}

				let valRefChlorureCim = null;
				valRefChlorureCim = await this.getValeurReference(element, 'EssCl', lstAllValeursRef);
				if (valRefChlorureCim && valRefChlorureCim.valeur) {
					tmpCiment.idEssaiChlorure = valRefChlorureCim.idEssai;
					tmpCiment.arrondiEssaiChlorure = valRefChlorureCim.arrondiEssai;
					tmpCiment.poidsChlorureCiment += (Number.parseFloat(element.poids) * Number.parseFloat(valRefChlorureCim.valeur)) / 100;
				} else {
					tmpCiment.poidsChlorureCiment += 0;
				}

				let valRefAlcalinCim = null;

				valRefAlcalinCim = await this.getValeurReference(element, 'EssAlc', lstAllValeursRef);
				if (valRefAlcalinCim && valRefAlcalinCim.valeur) {
					tmpCiment.idEssaiAlcalin = valRefAlcalinCim.idEssai;
					tmpCiment.arrondiEssaiAlcalin = valRefAlcalinCim.arrondiEssai;
					tmpCiment.poidsAlcalinCiment += (Number.parseFloat(element.poids) * Number.parseFloat(valRefAlcalinCim.valeur)) / 100;
				} else {
					tmpCiment.poidsAlcalinCiment += 0;
				}
			}

			// calcul CO2
			let valRefPoidsCO2 = null;
			valRefPoidsCO2 = await this.getValeurReference(element, 'EssPoidsCO2', lstAllValeursRef);

			if (valRefPoidsCO2 && valRefPoidsCO2.valeur) {
				//le gCO2 est la valeur de reference du composant
				element.gCo2 = Number.parseFloat(valRefPoidsCO2.valeur);

				//initialisation du calcul du fCo2
				if (element.gCo2) {
					element.fCo2 = Number.parseFloat(element.gCo2);
				}

				await this.calculCompletCo2parFamille(tmpCiment, element, 'CIM');
			}
		}
		return tmpCiment;
	}

	async recuperationAddition(lstAllValeursRef = null) {
		if (!this.formule) {
			throw new Error('Il faut dabord utiliser la methode init pour envoyer la formule');
		}
		const tmpAddition = {
			additionId: null,
			additionPoids: 0,
			sommeMVAdd: 0,
			coefficientK: this.formule.composantAdditions ? this.formule.composantAdditions.coefficientK : 0,
			poidsAlcalinAddition: 0,
			idEssaiAlcalin: -1,
			distanceParTypeTransportTotal: 0,
			totalEmissionCo2: 0
		};

		if (this.formule && this.formule.composants) {
			const compoAddition = this.formule.composants
				.filter((c) => c.codeFamille === 'ADD')
				.sort((a, b) => Number.parseFloat(b.poids) - Number.parseFloat(a.poids));
			if (compoAddition && compoAddition.length > 0) {
				tmpAddition.additionId = compoAddition[0].idComposant;
			}
		}

		for (let i = 0; i < this.formule.composants.length; i++) {
			const element = this.formule.composants[i];
			if (element.codeFamille === 'ADD') {
				const compoAddCoef = this.formule.compoAddCoef ? this.formule.compoAddCoef.filter((y) => y.idAddition === element.idComposant) : null;
				if (compoAddCoef && compoAddCoef.length > 0 && compoAddCoef[0].selected) {
					tmpAddition.coefficientK = Number.parseFloat(compoAddCoef[0].coefK);
				}
				tmpAddition.additionPoids += Number.parseFloat(element.poids);

				if (element.volume) {
					tmpAddition.sommeMVAdd += element.volume;
				}
			}

			let valRefAlcalinAdd = null;

			valRefAlcalinAdd = await this.getValeurReference(element, 'EssAlc', lstAllValeursRef);
			if (valRefAlcalinAdd && valRefAlcalinAdd.valeur) {
				tmpAddition.idEssaiAlcalin = valRefAlcalinAdd.idEssai;
				tmpAddition.arrondiEssaiAlcalin = valRefAlcalinAdd.arrondiEssai;
				tmpAddition.poidsAlcalinAddition += (Number.parseFloat(element.poids) * Number.parseFloat(valRefAlcalinAdd.valeur)) / 100;
			} else {
				tmpAddition.poidsAlcalinAddition += 0;
			}

			// calcul CO2
			let valRefPoidsCO2 = null;
			valRefPoidsCO2 = await this.getValeurReference(element, 'EssPoidsCO2', lstAllValeursRef);

			if (valRefPoidsCO2 && valRefPoidsCO2.valeur) {
				//le gCO2 est la valeur de reference du composant
				element.gCo2 = Number.parseFloat(valRefPoidsCO2.valeur);

				//initialisation du calcul du fCo2
				if (element.gCo2) {
					element.fCo2 = Number.parseFloat(element.gCo2);
				}

				await this.calculCompletCo2parFamille(tmpAddition, element, 'ADD');
			}
		}

		return tmpAddition;
	}

	async recuperationAdjuvant(lstAllValeursRef = null) {
		if (!this.formule) {
			throw new Error('Il faut dabord utiliser la methode init pour envoyer la formule');
		}
		const tmpAdjuvant = {
			adjuvantId: [],
			adjuvantPoids: 0,
			eauAdjuvant: 0,
			uniteAdj: '',
			sommeMVAdj: 0,
			eauAdjSelected: this.formule.eauAdjuvantSelected,
			poidsAlcalinAdjuvant: 0,
			idEssaiAlcalin: -1,
			volumeAdjuvant: 0,
			disabledEau: false,
			distanceParTypeTransportTotal: 0,
			totalEmissionCo2: 0
		};

		for (let i = 0; i < this.formule.composants.length; i++) {
			const element = this.formule.composants[i];
			if (element.codeFamille === 'ADJ') {
				tmpAdjuvant.adjuvantId.push(element.idComposant);
				tmpAdjuvant.adjuvantPoids += Number.parseFloat(element.poids);
				tmpAdjuvant.volumeAdjuvant += Number.parseFloat(element.volume);

				let valeurDeRef = null;
				//if (this.formule.dateFinValidite) {
				valeurDeRef = await this.getValeurReference(element, 'EssTESAdj', lstAllValeursRef);
				//}

				if (valeurDeRef && valeurDeRef.valeur) {
					element.poidsAbs = Number.parseFloat(element.poids) * (1 - Number.parseFloat(valeurDeRef.valeur) / 100);
					tmpAdjuvant.eauAdjuvant += element.poidsAbs;
				} else {
					//si c'est la teneur en eau qui est affichée
					valeurDeRef = await this.getValeurReference(element, 'EssWGran', lstAllValeursRef);
					if (valeurDeRef && valeurDeRef.valeur) {
						element.poidsAbs = Number.parseFloat(element.poids) * (Number.parseFloat(valeurDeRef.valeur) / 100);
						tmpAdjuvant.eauAdjuvant += element.poidsAbs;
					}
				}
				tmpAdjuvant.uniteAdj = element.uniteCompo;

				const MVAdjValRef = null;
				//if (this.formule.dateFinValidite) {

				/* MVAdjValRef = await this.getValeurReference(element, 'EssMV', lstAllValeursRef);
                //}
                if (MVAdjValRef && MVAdjValRef.valeur) {
                    tmpAdjuvant.sommeMVAdj += Number.parseFloat(element.poids) / Number.parseFloat(MVAdjValRef.valeur);
                } else {
                    tmpAdjuvant.sommeMVAdj += 0;
                } */

				if (element.volume) {
					tmpAdjuvant.sommeMVAdj += element.volume;
				}

				let valRefAlcalinAdj = null;

				valRefAlcalinAdj = await this.getValeurReference(element, 'EssAlc', lstAllValeursRef);
				if (valRefAlcalinAdj && valRefAlcalinAdj.valeur) {
					tmpAdjuvant.idEssaiAlcalin = valRefAlcalinAdj.idEssai;
					tmpAdjuvant.arrondiEssaiAlcalin = valRefAlcalinAdj.arrondiEssai;
					tmpAdjuvant.poidsAlcalinAdjuvant += (Number.parseFloat(element.poids) * Number.parseFloat(valRefAlcalinAdj.valeur)) / 100;
				} else {
					tmpAdjuvant.poidsAlcalinAdjuvant += 0;
				}

				// calcul CO2
				let valRefPoidsCO2 = null;
				valRefPoidsCO2 = await this.getValeurReference(element, 'EssPoidsCO2', lstAllValeursRef);

				if (valRefPoidsCO2 && valRefPoidsCO2.valeur) {
					//le gCO2 est la valeur de reference du composant
					element.gCo2 = Number.parseFloat(valRefPoidsCO2.valeur);

					//initialisation du calcul du fCo2
					if (element.gCo2) {
						element.fCo2 = Number.parseFloat(element.gCo2);
					}

					await this.calculCompletCo2parFamille(tmpAdjuvant, element, 'ADJ');
				}
			}
		}

		if (tmpAdjuvant.unite === '%') {
			const poids = (tmpAdjuvant.adjuvantPoids * this.formule.composantCiments.cimentPoids) / 100;
			tmpAdjuvant.adjuvantPoids = Number.parseFloat(poids);
		}

		/**
		 * Selon la norme si le volume total des ajduvants est superieur à 3 litres alors l'eau des adjuvants est obligatoirement pris en compte
		 */
		if (tmpAdjuvant.volumeAdjuvant >= 3) {
			tmpAdjuvant.disabledEau = true;
			this.formule.eauAdjuvantSelected = true;
			this.formule.fromVolSup = true;
		} else {
			if (this.formule && this.formule.fromVolSup) {
				this.formule.eauAdjuvantSelected = false;
			}
		}
		/******************************************************************************************************************************** */

		return tmpAdjuvant;
	}

	async recuperationGranulat(lstAllValeursRef = null) {
		if (!this.formule) {
			throw new Error('Il faut dabord utiliser la methode init pour envoyer la formule');
		}
		const tmpGranulat = {
			granulatId: [],
			compteurGranulat: 0,
			granulatPoids: 0,
			granulatSable: 0,
			granulatGravillon: 0,
			coefGranulaire: 0,
			absorptionGranulats: 0,
			teneurEnEauGran: 0,
			eauGranulat: 0,
			sommeMVGran: 0,
			masseTotaleGran: 0,
			grandD: 0,
			eauTotal: 0,
			poidsChlorureGranulat: 0,
			idEssaiChlorure: -1,
			poidsAlcalinGranulat: 0,
			idEssaiAlcalin: -1,
			distanceParTypeTransportTotal: 0,
			totalEmissionCo2: 0,
			granulatSableRecycle: 0,
			granulatGravillonRecycle: 0,
			compteurGranSable: 0,
			compteurGranGravillon: 0,
			typeGranRecycle: [],
			isRecycle: false
		};

		for (let i = 0; i < this.formule.composants.length; i++) {
			const element = this.formule.composants[i];

			if (element.codeFamille === 'GRAN') {
				tmpGranulat.masseTotaleGran += Number.parseFloat(element.poids);
				tmpGranulat.granulatId.push(element.idComposant);
				let produitGranulat = null;
				if (lstAllValeursRef) {
					const valRefEntry = lstAllValeursRef.find((e) => e.idProduit === element.idElement);
					if (valRefEntry) {
						produitGranulat = valRefEntry.produit;
					}
				}

				if (!produitGranulat) {
					produitGranulat = await this.ProduitsService.getProduitEnteteById(element.idElement);
				}

				tmpGranulat.compteurGranulat++;

				/**
				 * Pour Kb2 : si D<=10 0.55, si D>10 et D<=25 0.6, si D>25 0.65, et Kb=moyenne des Kb2
				 * Res estimée = C28j*Kb*(CkA/(EEff+VAir)-0.5)
				 */
				let kb2 = 0;
				let kb2ValRef = null;

				kb2ValRef = await this.getValeurReference(element, 'EssKbGran', lstAllValeursRef);

				if (kb2ValRef) {
					kb2 = Number.parseFloat(kb2ValRef.valeur);
				} else {
					if (produitGranulat.tamisGrandD <= 10) {
						kb2 = 0.55;
					} else if (produitGranulat.tamisGrandD > 10 && produitGranulat.tamisGrandD <= 25) {
						kb2 = 0.6;
					} else if (produitGranulat.tamisGrandD > 25) {
						kb2 = 0.65;
					}
				}

				tmpGranulat.coefGranulaire += kb2;

				if (typeof tmpGranulat.grandD === 'number' && produitGranulat.tamisGrandD > tmpGranulat.grandD) {
					tmpGranulat.grandD = produitGranulat.tamisGrandD;
				}

				if (produitGranulat && produitGranulat.tamisPetitd === 0) {
					tmpGranulat.granulatSable += Number.parseFloat(element.poids);
					tmpGranulat.compteurGranSable += 1;
				} else if (produitGranulat && produitGranulat.tamisPetitd && produitGranulat.tamisPetitd > 0) {
					tmpGranulat.granulatGravillon += Number.parseFloat(element.poids);
					tmpGranulat.compteurGranSable += 1;
				}
				tmpGranulat.granulatPoids += Number.parseFloat(element.poids);

				//absorption des granulats
				let tmp = null;
				let absorption = 0;
				tmp = await this.getValeurReference(element, 'EssAbGran', lstAllValeursRef);

				if (tmp && tmp.valeur) {
					const ab = Number.parseFloat(tmp.valeur);
					absorption = (Number.parseFloat(element.poids) * ab) / 100;
				} else {
					absorption = 0;
				}
				tmpGranulat.absorptionGranulats += absorption;

				//teneur en eau des granulats
				let teneurEau = 0;
				let tmpTE = null;

				tmpTE = await this.getValeurReference(element, 'EssWGran', lstAllValeursRef);

				if (tmpTE && tmpTE.valeur) {
					const w = Number.parseFloat(tmpTE.valeur);
					teneurEau = (Number.parseFloat(element.poids) * w) / 100;
				} else {
					teneurEau = 0;
				}
				tmpGranulat.eauTotal += teneurEau;

				if (element.volume) {
					tmpGranulat.sommeMVGran += element.volume;
				}

				let valRefChlorureGran = null;

				valRefChlorureGran = await this.getValeurReference(element, 'EssCl', lstAllValeursRef);
				if (valRefChlorureGran && valRefChlorureGran.valeur) {
					tmpGranulat.idEssaiChlorure = valRefChlorureGran.idEssai;
					tmpGranulat.arrondiEssaiChlorure = valRefChlorureGran.arrondiEssai;
					tmpGranulat.poidsChlorureGranulat += (Number.parseFloat(element.poids) * Number.parseFloat(valRefChlorureGran.valeur)) / 100;
				} else {
					tmpGranulat.poidsChlorureGranulat += 0;
				}

				let valRefAlcalinGran = null;
				valRefAlcalinGran = await this.getValeurReference(element, 'EssAlc', lstAllValeursRef);
				if (valRefAlcalinGran && valRefAlcalinGran.valeur) {
					tmpGranulat.idEssaiAlcalin = valRefAlcalinGran.idEssai;
					tmpGranulat.arrondiEssaiAlcalin = valRefAlcalinGran.arrondiEssai;
					tmpGranulat.poidsAlcalinGranulat += (Number.parseFloat(element.poids) * Number.parseFloat(valRefAlcalinGran.valeur)) / 100;
				} else {
					tmpGranulat.poidsAlcalinGranulat += 0;
				}

				// calcul CO2
				let valRefPoidsCO2 = null;
				valRefPoidsCO2 = await this.getValeurReference(element, 'EssPoidsCO2', lstAllValeursRef);
				if (valRefPoidsCO2 && valRefPoidsCO2.valeur) {
					//le gCO2 est la valeur de reference du composant
					element.gCo2 = Number.parseFloat(valRefPoidsCO2.valeur);

					//initialisation du calcul du fCo2
					if (element.gCo2) {
						element.fCo2 = Number.parseFloat(element.gCo2);
					}

					await this.calculCompletCo2parFamille(tmpGranulat, element, 'GRAN');
				}

				// calcul TxGran
				let valRefTxGranRec = null;
				valRefTxGranRec = await this.getValeurReference(element, 'EssTxGran', lstAllValeursRef);
				if (valRefTxGranRec && valRefTxGranRec.valeur) {
					// si cette valeur de reference est presente sur au moins un granulat alors on passe le booleen a true, important pour verifier les types de granulat et le message de different granulat (on ne peux pas avoir plusieur type de granulat different).
					tmpGranulat.isRecycle = true;

					let produitGranCaracteristique = [];
					if (produitGranulat) {
						produitGranCaracteristique = await this.ProduitsService.getProduitValeursCaracteristiquesById(
							Number.parseInt(produitGranulat.id)
						);
					} else {
						produitGranulat = await this.ProduitsService.getProduitEnteteById(element.idElement);
						produitGranCaracteristique = await this.ProduitsService.getProduitValeursCaracteristiquesById(
							Number.parseInt(produitGranulat.id)
						);
					}

					const CaracteristiqueGranRecycle = await this.getCaractParamProg('CarGranRecycl');
					const GranRecycle = await this.getCaractParamProg('EltGranRecTot');
					const GranNaturel = await this.getCaractParamProg('EltGranRecNat');
					const GranPremelange = await this.getCaractParamProg('EltGranRecPrem');

					const idxGranRecycle = produitGranCaracteristique.findIndex((x) => x.code === CaracteristiqueGranRecycle);
					if (idxGranRecycle > -1) {
						// si cest un sable
						if (produitGranulat && produitGranulat.tamisPetitd === 0) {
							if (produitGranCaracteristique[idxGranRecycle].value.key === GranRecycle) {
								tmpGranulat.granulatSableRecycle += Number.parseFloat(element.poids);
							} else if (produitGranCaracteristique[idxGranRecycle].value.key === GranNaturel) {
								tmpGranulat.granulatSableRecycle += 0;
							} else if (produitGranCaracteristique[idxGranRecycle].value.key === GranPremelange) {
								tmpGranulat.granulatSableRecycle += Number.parseFloat(element.poids) * Number.parseFloat(valRefTxGranRec.valeur);
							}
						}
						// si cest un gravillon
						else {
							if (produitGranCaracteristique[idxGranRecycle].value.key === GranRecycle) {
								tmpGranulat.granulatGravillonRecycle += Number.parseFloat(element.poids);
							} else if (produitGranCaracteristique[idxGranRecycle].value.key === GranNaturel) {
								tmpGranulat.granulatGravillonRecycle += 0;
							} else if (produitGranCaracteristique[idxGranRecycle].value.key === GranPremelange) {
								tmpGranulat.granulatGravillonRecycle += Number.parseFloat(element.poids) * Number.parseFloat(valRefTxGranRec.valeur);
							}
						}
					}

					const CaracteristiqueTypeGranRecycle = await this.getCaractParamProg('CarGranTypeRecy');

					const idxTypeGranRecycle = produitGranCaracteristique.findIndex((x) => x.code === CaracteristiqueTypeGranRecycle);
					if (idxTypeGranRecycle > -1) {
						tmpGranulat.typeGranRecycle.push(produitGranCaracteristique[idxTypeGranRecycle].value.key);
					} else {
						tmpGranulat.typeGranRecycle.push('Type1');
					}
				}
			}
		}

		return tmpGranulat;
	}

	async recuperationEau(lstAllValeursRef = null) {
		if (!this.formule) {
			throw new Error('Il faut dabord utiliser la methode init pour envoyer la formule');
		}
		const tmpEau = {
			eauId: [],
			eauTotal: 0,
			totalEauMvTEau: 0,
			masseVolumiqueEau: 0,
			poidsAlcalinEau: 0,
			idEssaiAlcalin: -1,
			distanceParTypeTransportTotal: 0,
			totalEmissionCo2: 0
		};

		for (let i = 0; i < this.formule.composants.length; i++) {
			const element = this.formule.composants[i];
			if (element.codeFamille === 'EAU') {
				tmpEau.eauId.push(element.idComposant);
				tmpEau.eauTotal += Number.parseFloat(element.poids);

				if (element.volume) {
					tmpEau.totalEauMvTEau += element.volume;
				}
			}

			let valRefAlcalinEau = null;

			valRefAlcalinEau = await this.getValeurReference(element, 'EssAlc', lstAllValeursRef);
			if (valRefAlcalinEau && valRefAlcalinEau.valeur) {
				tmpEau.idEssaiAlcalin = valRefAlcalinEau.idEssai;
				tmpEau.arrondiEssaiAlcalin = valRefAlcalinEau.arrondiEssai;
				tmpEau.poidsAlcalinEau += (Number.parseFloat(element.poids) * Number.parseFloat(valRefAlcalinEau.valeur)) / 100;
			} else {
				tmpEau.poidsAlcalinEau += 0;
			}

			// calcul CO2
			let valRefPoidsCO2 = null;
			valRefPoidsCO2 = await this.getValeurReference(element, 'EssPoidsCO2', lstAllValeursRef);

			if (valRefPoidsCO2 && valRefPoidsCO2.valeur) {
				//le gCO2 est la valeur de reference du composant
				element.gCo2 = Number.parseFloat(valRefPoidsCO2.valeur);

				//initialisation du calcul du fCo2
				if (element.gCo2) {
					element.fCo2 = Number.parseFloat(element.gCo2);
				}
				await this.calculCompletCo2parFamille(tmpEau, element, 'EAU');
			}
		}
		return tmpEau;
	}

	async calculCompletCo2parFamille(tmpFamille, element, famille) {
		const distanceParTypeTransport = [];
		if (this.formule.facteurEmissionCO2 && this.formule.facteurEmissionCO2.filter((e) => e.famille.code === famille).length > 0) {
			const typeTransportCO2 = this.formule.facteurEmissionCO2.filter((e) => e.famille.code === famille);
			if (this.formule.distanceCO2 && this.formule.distanceCO2.length > 0) {
				const distanceCO2 = this.formule.distanceCO2.filter((x) => x.siteProducteur.id === element.idProducteur);

				// CO2 distance/parType de transport
				for (let j = 0; j < typeTransportCO2.length; j++) {
					const typeTransport = typeTransportCO2[j];

					const valeurDistancepartype = distanceCO2.find((d) => (d.transport.id = typeTransport.transport.id)).valeur;

					if (valeurDistancepartype && typeof valeurDistancepartype === 'number') {
						distanceParTypeTransport.push({
							valeur: Number.parseFloat(valeurDistancepartype) * Number.parseFloat(typeTransport.valeur),
							typeTransport: typeTransport.transport,
							valeurTypeTransport: typeTransport.valeur,
							distance: valeurDistancepartype
						});

						tmpFamille.distanceParTypeTransportTotal +=
							Number.parseFloat(valeurDistancepartype) * Number.parseFloat(typeTransport.valeur);
						element.fCo2 += Number.parseFloat(valeurDistancepartype) * Number.parseFloat(typeTransport.valeur);
					}
				}

				if (distanceParTypeTransport && distanceParTypeTransport.length > 0) {
					element.distanceParTypeTransport = distanceParTypeTransport;
				}
				element.totalCo2ParPoidsConstituant = Number.parseFloat(element.fCo2) * Number.parseFloat(element.poids);
				tmpFamille.totalEmissionCo2 += element.totalCo2ParPoidsConstituant;
			}
		}
	}

	async getProduitFormule(lstAllValeursRef = null) {
		if (!this.formule) {
			throw new Error('Il faut dabord utiliser la methode init pour envoyer la formule');
		}
		if (this.formule.idProduit || this.formule.idProduitCompose) {
			let produit = null;
			let produitCaracteristique = null;
			if (lstAllValeursRef) {
				const valRefEntry = lstAllValeursRef.find((e) =>
					e.idProduit === this.formule.idProduit ? this.formule.idProduit : this.formule.idProduitCompose
				);
				if (valRefEntry) {
					produit = valRefEntry.produit;
					produitCaracteristique = valRefEntry.valeursCaracteristiquesProduit;
				}
			}

			if (!produitCaracteristique) {
				produit = await this.ProduitsService.getProduitEnteteById(
					Number.parseInt(this.formule.idProduit ? this.formule.idProduit : this.formule.idProduitCompose)
				);
				produitCaracteristique = await this.ProduitsService.getProduitValeursCaracteristiquesById(
					Number.parseInt(this.formule.idProduit ? this.formule.idProduit : this.formule.idProduitCompose)
				);
			}

			return {
				produit: produit,
				produitCaracteristique: produitCaracteristique
			};
		}
	}

	async recuperationClasseExpo(carac, bTraitement) {
		if (carac) {
			if (!this.classeExpoProduit) {
				this.classeExpoProduit = await this.ElementCaracteristiqueService.getAll('CarBetClExpo');
			}

			for (let j = 0; j < carac.length; j++) {
				const elem = carac[j];
				if (!bTraitement && elem.value !== null) {
					const idx = this.classeExpoProduit.findIndex((x) => x.code === elem.value.key);
					if (idx > 0) {
						return this.classeExpoProduit[idx];
					}
				} else if (bTraitement && elem.valeur !== null) {
					//traitement des formules
					const idx = this.classeExpoProduit.findIndex((x) => x.code === elem.valeur);
					if (idx > 0) {
						return this.classeExpoProduit[idx];
					}
				}
			}
		}
	}

	async recuperationDosageCiment(carac, bTraitement) {
		if (carac) {
			if (!this.dosageCimentProduit) {
				this.dosageCimentProduit = await this.ElementCaracteristiqueService.getAll('CarBetDosCim');
			}
			for (let j = 0; j < carac.length; j++) {
				const elem = carac[j];
				if (!bTraitement && elem.value !== null) {
					const idx = this.dosageCimentProduit.findIndex((x) => x.code === elem.value.key);
					if (idx > 0) {
						return this.dosageCimentProduit[idx];
					}
				} else if (bTraitement && elem.valeur !== null) {
					//traitement des formules
					const idx = this.dosageCimentProduit.findIndex((x) => x.code === elem.valeur);
					if (idx > 0) {
						return this.dosageCimentProduit[idx];
					}
				}
			}
		}
	}

	async recuperationClasseResistance(carac, bTraitement) {
		if (carac) {
			if (!this.classeResistProduit) {
				this.classeResistProduit = await this.ElementCaracteristiqueService.getAll('CarBetClResist');
			}

			for (let l = 0; l < carac.length; l++) {
				const element = carac[l];
				if (!bTraitement && element.value) {
					const idx = this.classeResistProduit.findIndex((x) => x.code === element.value.key);

					if (idx > 0) {
						return this.classeResistProduit[idx];
					}
				} else if (bTraitement && element.valeur) {
					const idx = this.classeResistProduit.findIndex((x) => x.code === element.valeur);

					if (idx > 0) {
						return this.classeResistProduit[idx];
					}
				}
			}
		}
	}

	async recuperationClasseChlorure(carac, bTraitement) {
		if (carac) {
			if (!this.classeChlorureProduit) {
				this.classeChlorureProduit = await this.ElementCaracteristiqueService.getAll('CarBetClChlor');
			}

			for (let l = 0; l < carac.length; l++) {
				const element = carac[l];
				if (!bTraitement && element.value) {
					const idx = this.classeChlorureProduit.findIndex((x) => x.code === element.value.key);

					if (idx > 0) {
						return this.classeChlorureProduit[idx];
					}
				} else if (bTraitement && element.valeur) {
					const idx = this.classeChlorureProduit.findIndex((x) => x.code === element.valeur);

					if (idx > 0) {
						return this.classeChlorureProduit[idx];
					}
				}
			}
		}
	}

	async recuperationClasseSubGran(carac, bTraitement) {
		if (carac) {
			if (!this.classeSubGran) {
				this.classeSubGran = await this.ElementCaracteristiqueService.getAll('CarBetGranRecy');
			}

			for (let l = 0; l < carac.length; l++) {
				const element = carac[l];
				if (!bTraitement && element.value) {
					const idx = this.classeSubGran.findIndex((x) => x.code === element.value.key);

					if (idx > 0) {
						return this.classeSubGran[idx];
					}
				} else if (bTraitement && element.valeur) {
					const idx = this.classeSubGran.findIndex((x) => x.code === element.valeur);

					if (idx > 0) {
						return this.classeSubGran[idx];
					}
				}
			}
		}
	}

	/*  async recuperationClasseAlcalin(carac, bTraitement) {
        if (carac) {
            if (!this.classeAlcalinProduit) this.classeAlcalinProduit = await this.ElementCaracteristiqueService.getAll('CarBetClAlc');

            for (let l = 0; l < carac.length; l++) {
                const element = carac[l];
                if (!bTraitement && element.value) {
                    const idx = this.classeAlcalinProduit.findIndex(x => x.code === element.value.key);

                    if (idx > 0) {
                        return this.classeAlcalinProduit[idx];
                    }
                } else if (bTraitement && element.valeur) {
                    const idx = this.classeAlcalinProduit.findIndex(x => x.code === element.valeur);

                    if (idx > 0) {
                        return this.classeAlcalinProduit[idx];
                    }
                }
            }
        }
    } */

	async getProduitCommerciaux(lstAllValeursRef, idSelected, compo, fromCatalogue = false) {
		if (!this.formule && compo) {
			this.formule = compo;
		}
		if (
			!this.formule ||
			(!this.formule && !this.formule.produitCommerciaux) ||
			(this.formule && !this.formule.produitCommerciaux) ||
			!idSelected
		) {
			let id = 0;
			if (fromCatalogue) {
				id =
					this.formule && this.formule.idProduitCompose ? Number.parseInt(this.formule.idProduitCompose) : Number.parseInt(compo.idProduit);
			} else {
				id =
					typeof this.formule !== 'undefined' && typeof this.formule.idProduit !== 'undefined'
						? Number.parseInt(this.formule.idProduit)
						: Number.parseInt(compo.idProduit);
			}
			this.formule.produitCommerciaux = await this.ProduitsService.getAllProduitCommerciaux(id, null, null);
		}

		const produitCC = [];
		if (
			this.formule.produitCommerciaux &&
			this.formule.produitCommerciaux.produitsCom &&
			this.formule.produitCommerciaux.produitsCom.length > 0
		) {
			for (let p = 0; p < this.formule.produitCommerciaux.produitsCom.length; p++) {
				const element = this.formule.produitCommerciaux.produitsCom[p];
				let produitsCommerciauxCC = null;

				if (lstAllValeursRef) {
					const valRefEntry = lstAllValeursRef.find((e) => e.idProduit === element.idProduit);

					if (valRefEntry) {
						produitsCommerciauxCC = valRefEntry.valeursCaracteristiquesProduit;
					}
				}

				produitsCommerciauxCC = element.valeursCaracteristique;
				if (idSelected && element.idProduit === idSelected) {
					return produitsCommerciauxCC;
				}
				produitCC.push(produitsCommerciauxCC);
			}
		}
		return produitCC;
	}

	async recuperationConcreteCodeExpo(compo) {
		if (!this.concretCodeExpoProduit) {
			this.concretCodeExpoProduit = await this.ElementCaracteristiqueService.getAll('CarBetClExpo');
		}
		if (this.formule || compo) {
			if (!this.formule && compo) {
				this.formule = compo;
			} else {
				if (
					this.concretCodeExpoProduit &&
					this.concretCodeExpoProduit.length > 0 &&
					this.formule.produitCommerciaux &&
					this.formule.produitCommerciaux.length > 0
				) {
					const tmpExpo = [];
					for (let i = 0; i < this.formule.produitCommerciaux.length; i++) {
						const element = this.formule.produitCommerciaux[i];

						const finded = element.find((x) => x.id === this.concretCodeExpoProduit[0].idCaracteristique);
						if (finded) {
							for (let z = 0; z < element.length; z++) {
								const carac = element[z];
								if (carac.value || carac.elementValue) {
									const idx = this.concretCodeExpoProduit.findIndex(
										(x) => x.code === (carac.value ? carac.value.key : carac.elementValue.key)
									);
									if (idx > 0) {
										tmpExpo.push(this.concretCodeExpoProduit[idx]);
									}
								}
							}
						}
					}
					tmpExpo.sort((a, b) => b.ordre - a.ordre);
					return tmpExpo[0];
				}
			}
		}
	}

	async recuperationConcreteCodeCiment(compo) {
		if (!this.concretCodeCimentProduit) {
			this.concretCodeCimentProduit = await this.ElementCaracteristiqueService.getAll('CarBetDosCim');
		}
		if (this.formule || compo) {
			if (!this.formule && compo) {
				this.formule = compo;
			} else {
				if (
					this.concretCodeCimentProduit &&
					this.concretCodeCimentProduit.length > 0 &&
					this.formule.produitCommerciaux &&
					this.formule.produitCommerciaux.length > 0
				) {
					const tmpDosCim = [];
					for (let i = 0; i < this.formule.produitCommerciaux.length; i++) {
						const element = this.formule.produitCommerciaux[i];
						const finded = element.find((x) => x.id === this.concretCodeCimentProduit[0].idCaracteristique);
						if (finded) {
							for (let z = 0; z < element.length; z++) {
								const carac = element[z];
								if (carac.value || carac.elementValue) {
									const idx = this.concretCodeCimentProduit.findIndex(
										(x) => x.code === (carac.value ? carac.value.key : carac.elementValue.key)
									);
									if (idx > 0) {
										tmpDosCim.push(this.concretCodeCimentProduit[idx]);
									}
								}
							}
						}
					}

					tmpDosCim.sort((a, b) => b.ordre - a.ordre);

					return tmpDosCim[0];
				}
			}
		}
	}

	async recuperationConcreteCodeResistance(compo) {
		if (!this.concretCodeResistProduit) {
			this.concretCodeResistProduit = await this.ElementCaracteristiqueService.getAll('CarBetClResist');
		}
		if (this.formule || compo) {
			if (!this.formule && compo) {
				this.formule = compo;
			} else {
				if (
					this.concretCodeResistProduit &&
					this.concretCodeResistProduit.length > 0 &&
					this.formule.produitCommerciaux &&
					this.formule.produitCommerciaux.length > 0
				) {
					const tmpRes = [];
					for (let i = 0; i < this.formule.produitCommerciaux.length; i++) {
						const element = this.formule.produitCommerciaux[i];

						const finded = element.find((x) => x.id === this.concretCodeResistProduit[0].idCaracteristique);
						if (finded) {
							for (let j = 0; j < element.length; j++) {
								const carac = element[j];
								if (carac.value || carac.elementValue) {
									const idx = this.concretCodeResistProduit.findIndex(
										(x) => x.code === (carac.value ? carac.value.key : carac.elementValue.key)
									);
									if (idx > 0) {
										tmpRes.push(this.concretCodeResistProduit[idx]);
									}
								}
							}
						}
						tmpRes.sort((a, b) => b.ordre - a.ordre);
					}
					return tmpRes[0];
				}
			}
		}
	}

	async recuperationConcreteCodeChlorure(compo) {
		if (!this.concretCodeChlorureProduit) {
			this.concretCodeChlorureProduit = await this.ElementCaracteristiqueService.getAll('CarBetClChlor');
		}
		if (this.formule || compo) {
			if (!this.formule && compo) {
				this.formule = compo;
			} else {
				if (
					this.concretCodeChlorureProduit &&
					this.concretCodeChlorureProduit.length > 0 &&
					this.formule.produitCommerciaux &&
					this.formule.produitCommerciaux.length > 0
				) {
					const tmpRes = [];
					for (let i = 0; i < this.formule.produitCommerciaux.length; i++) {
						const element = this.formule.produitCommerciaux[i];

						const finded = element.find((x) => x.id === this.concretCodeChlorureProduit[0].idCaracteristique);
						if (finded) {
							for (let j = 0; j < element.length; j++) {
								const carac = element[j];
								if (carac.value || carac.elementValue) {
									const idx = this.concretCodeChlorureProduit.findIndex(
										(x) => x.code === (carac.value ? carac.value.key : carac.elementValue.key)
									);
									if (idx > 0) {
										tmpRes.push(this.concretCodeChlorureProduit[idx]);
									}
								}
							}
						}
						tmpRes.sort((a, b) => b.ordre - a.ordre);
					}
					return tmpRes[0];
				}
			}
		}
	}

	async recuperationConcreteCodeConsistance(compo) {
		if (!this.concretCodeConsistProduit) {
			this.concretCodeConsistProduit = await this.ElementCaracteristiqueService.getAll('CarBetClCons');
		}
		if (this.formule || compo) {
			if (!this.formule && compo) {
				this.formule = compo;
			} else {
				if (
					this.concretCodeConsistProduit &&
					this.concretCodeConsistProduit.length > 0 &&
					this.formule.produitCommerciaux &&
					this.formule.produitCommerciaux.length > 0
				) {
					const tmpConsist = [];

					for (let i = 0; i < this.formule.produitCommerciaux.length; i++) {
						const element = this.formule.produitCommerciaux[i];

						const finded = element.find((x) => x.id === this.concretCodeConsistProduit[0].idCaracteristique);
						if (finded) {
							for (let z = 0; z < element.length; z++) {
								const carac = element[z];
								if (carac.value || carac.elementValue) {
									const idx = this.concretCodeConsistProduit.findIndex(
										(x) => x.code === (carac.value ? carac.value.key : carac.elementValue.key)
									);
									if (idx > 0) {
										tmpConsist.push(this.concretCodeConsistProduit[idx]);
									}
								}
							}
						}
					}
					tmpConsist.sort((a, b) => b.ordre - a.ordre);
					return tmpConsist[0];
				}
			}
		}
	}

	async recuperationConcreteCodeClasseSub(compo) {
		if (!this.concretCodeClasseSubGran) {
			this.concretCodeClasseSubGran = await this.ElementCaracteristiqueService.getAll('CarBetGranRecy');
		}
		if (this.formule || compo) {
			if (!this.formule && compo) {
				this.formule = compo;
			} else {
				if (
					this.concretCodeClasseSubGran &&
					this.concretCodeClasseSubGran.length > 0 &&
					this.formule.produitCommerciaux &&
					this.formule.produitCommerciaux.length > 0
				) {
					const tmpDosCim = [];
					for (let i = 0; i < this.formule.produitCommerciaux.length; i++) {
						const element = this.formule.produitCommerciaux[i];
						const finded = element.find((x) => x.id === this.concretCodeClasseSubGran[0].idCaracteristique);
						if (finded) {
							for (let z = 0; z < element.length; z++) {
								const carac = element[z];
								if (carac.value || carac.elementValue) {
									const idx = this.concretCodeClasseSubGran.findIndex(
										(x) => x.code === (carac.value ? carac.value.key : carac.elementValue.key)
									);
									if (idx > 0) {
										tmpDosCim.push(this.concretCodeClasseSubGran[idx]);
									}
								}
							}
						}
					}

					tmpDosCim.sort((a, b) => b.ordre - a.ordre);

					return tmpDosCim[0];
				}
			}
		}
	}

	async getFacteurEmisionCO2() {
		try {
			const res = await this.FacteurEmissionCo2Service.getAllFacteursEmissionCo2ByDate(this.formule.dateCreation);
			return res.data;
		} catch (error) {
			this.notification.error(this.$translate.instant('FORMULES.FACTEUR_CO2_ERROR'));
		}
	}

	async getDistanceCO2ParProducteur() {
		try {
			const res = await this.SiteDistancePoidsCo2Service.getAllDistancesPoidsCo2(this.formule.idProducteur);
			return res;
		} catch (error) {
			this.notification.error(this.$translate.instant('FORMULES.FACTEUR_CO2_ERROR'));
		}
	}

	/* async recuperationConcreteCodeAlcalin(compo) {
        if (!this.concretCodeAlcalinProduit) this.concretCodeAlcalinProduit = await this.ElementCaracteristiqueService.getAll('CarBetClAlc');
        if (this.formule || compo) {
            if (!this.formule && compo) {
                this.formule = compo;
            } else {
                if (this.concretCodeAlcalinProduit && this.formule.produitCommerciaux && this.formule.produitCommerciaux.length > 0) {
                    let tmpRes = [];
                    for (let i = 0; i < this.formule.produitCommerciaux.length; i++) {
                        const element = this.formule.produitCommerciaux[i];
                        for (let j = 0; j < element.length; j++) {
                            const carac = element[j];
                            if (carac.value || carac.elementValue) {
                                let idx = this.concretCodeAlcalinProduit.findIndex(
                                    x => x.code === (carac.value ? carac.value.key : carac.elementValue.key)
                                );
                                if (idx > 0) {
                                    tmpRes.push(this.concretCodeAlcalinProduit[idx]);
                                }
                            }
                        }
                        tmpRes.sort((a, b) => b.ordre - a.ordre);
                    }
                    return tmpRes[0];
                }
            }
        }
    } */

	checkDosageCiment() {
		if (this.formule && this.formule.dosageCiment) {
			const valCarac = Number.parseFloat(this.formule.dosageCiment.code.replace('C', ''));
			if (valCarac) {
				const vdc = this.valeurDeControle.find((v) => v.code === 'CKA');
				if (vdc && vdc.seuil) {
					vdc.seuil = Math.max(vdc.seuil, valCarac);
				}
			}
		}
	}

	resetCache() {
		delete this.classeExpoProduit;
		delete this.classeResistProduit;
		delete this.classeChlorureProduit;
		delete this.dosageCimentProduit;
		delete this.concretCodeExpoProduit;
		delete this.concretCodeResistProduit;
		delete this.concretCodeCimentProduit;
		delete this.valeurDeControle;
	}

	uniteValeurDeControle(valeurDeControle, tableau, unite) {
		for (let i = 0; i < tableau.length; i++) {
			const element = tableau[i];
			const idx = valeurDeControle.findIndex((f) => f.code === element);
			if (idx > -1) {
				valeurDeControle[idx].unite = unite;
			}
		}
	}

	async recuperationSeuil(valeurDeControle) {
		/** creation d'un model pour affichage des valeurs*/
		const modelValeur = {
			normeSpec: -1,
			idBeton: null,
			idCiment: null,
			idAddition: null,
			idClasseExpo: null,
			grandD: null,
			valeurDeControleId: valeurDeControle
		};

		modelValeur.normeSpec = this.formule && this.formule.normeSpec ? this.formule.normeSpec : { id: this.formule?.idNorme };

		if ((this.formule && this.formule.produits?.produit?.typeId) || (this.formule && this.formule.idTypeComposition)) {
			modelValeur.idBeton = this.formule.idTypeComposition != null ? this.formule.idTypeComposition : this.formule.produits?.produit?.typeId;
		}
		if (this.formule && this.formule.composantCiments && this.formule.composantCiments.cimentId) {
			modelValeur.idCiment = this.formule.composantCiments.cimentId;
		}
		if (this.formule && this.formule.composantAdditions && this.formule.composantAdditions.additionId) {
			modelValeur.idAddition = this.formule.composantAdditions.additionId;
		}
		if (this.formule && this.formule.classeExpo && this.formule.classeExpo.id) {
			modelValeur.idClasseExpo = this.formule.classeExpo.id;
		}
		if (this.formule && this.formule.composantGranulats && this.formule.composantGranulats.grandD) {
			modelValeur.grandD = this.formule.composantGranulats.grandD;
		}
	}

	assignationPourAffichage() {
		if (this.formule.composantAdditions && this.formule.composantAdditions.additionPoids) {
			this.assignationDeValeurCalcul('A', this.formule.composantAdditions.additionPoids);
			this.assignationDeValeurCalcul('Add', this.formule.composantAdditions.additionPoids);
		}
		if (this.formule.composantCiments && this.formule.composantCiments.cimentPoids) {
			this.assignationDeValeurCalcul('C', this.formule.composantCiments.cimentPoids);
		}
		if (this.formule.composantGranulats && typeof this.formule.composantGranulats.grandD === 'number') {
			this.assignationDeValeurCalcul('DM', this.formule.composantGranulats.grandD);
		}
		if (this.formule.composantCiments && this.formule.composantCiments.classeVraieDuCiment) {
			this.assignationDeValeurCalcul('CVC28', this.formule.composantCiments.classeVraieDuCiment);
		}
	}

	assignationDeValeurCalcul(string, valeur) {
		try {
			if (this.formule && this.formule.vdc) {
				const idx = this.formule.vdc.findIndex((a) => a.code === string);
				if (idx > -1) {
					this.formule.vdc[idx].calcul = valeur;
				}
			}
		} catch (err) {
			console.error(err);
		}
	}

	// calcul Add/(Add+C)
	calculAddAddC() {
		let res = 0;
		if (
			this.formule &&
			((this.formule.composantAdditions && this.formule.composantAdditions.additionPoids > 0) ||
				(this.formule.composantCiments && this.formule.composantCiments.cimentPoids > 0))
		) {
			res =
				this.formule.composantAdditions.additionPoids /
				(this.formule.composantAdditions.additionPoids + this.formule.composantCiments.cimentPoids);
			res = Number.parseFloat(res.toFixed(2));
		}
		this.assignationDeValeurCalcul('AddAddC', res);
	}

	// calcul A/(A+C)
	calculAAC(poidsAdditionModifier) {
		let res = 0;
		if (
			this.formule &&
			((poidsAdditionModifier && Number.parseFloat(poidsAdditionModifier) > 0) ||
				(this.formule.composantCiments && this.formule.composantCiments.cimentPoids > 0))
		) {
			res = poidsAdditionModifier / (poidsAdditionModifier + this.formule.composantCiments.cimentPoids);
			res = Number.parseFloat(res.toFixed(2));
		}
		this.assignationDeValeurCalcul('AAC', res);
	}

	// calcul Add-A (CorrGran)
	calculCorrGran(poidsAdditionModifier) {
		if (this.formule && this.formule.composantAdditions) {
			let res = this.formule.composantAdditions.additionPoids - poidsAdditionModifier;
			res = Number.parseFloat(res.toFixed(2));
			this.assignationDeValeurCalcul('CorrGran', res);
		}
	}

	// calcul Coefficient Granulaire
	calculCG() {
		if (this.formule && this.formule.composantGranulats && this.formule.composantGranulats.compteurGranulat > 0) {
			let res = this.formule.composantGranulats.coefGranulaire / this.formule.composantGranulats.compteurGranulat;
			res = Number.parseFloat(res.toFixed(2));
			this.assignationDeValeurCalcul('CG', res);
		}
	}

	// calcul C+(k*A)
	calculCKA() {
		let res = 0;
		if (this.formule && this.formule.vdc) {
			const idx = this.formule.vdc.findIndex((a) => a.code === 'AddAddC');
			if (idx > -1 && this.formule.vdc[idx].seuil) {
				if (this.formule.vdc[idx].calcul && this.formule.vdc[idx].calcul > this.formule.vdc[idx].seuil) {
					const poidsAdditionModifier =
						(this.formule.vdc[idx].seuil * this.formule.composantCiments.cimentPoids) / (1 - this.formule.vdc[idx].seuil);
					res = this.formule.composantCiments.cimentPoids + this.formule.composantAdditions.coefficientK * poidsAdditionModifier;

					if (res) {
						this.assignationDeValeurCalcul('A', Number.parseFloat(poidsAdditionModifier.toFixed(2)));
						this.calculAAC(poidsAdditionModifier);
						this.calculCorrGran(poidsAdditionModifier);
					}
				} else {
					if (this.formule && this.formule.composantCiments && this.formule.composantAdditions) {
						res =
							this.formule.composantCiments.cimentPoids +
							this.formule.composantAdditions.coefficientK * this.formule.composantAdditions.additionPoids;
						this.assignationDeValeurCalcul('CorrGran', 0);
						this.assignationDeValeurCalcul('A', this.formule.composantAdditions.additionPoids);
						this.calculAAC(this.formule.composantAdditions.additionPoids);
					}
				}
			} else {
				if (this.formule && this.formule.composantCiments && this.formule.composantAdditions) {
					res =
						this.formule.composantCiments.cimentPoids +
						this.formule.composantAdditions.coefficientK * this.formule.composantAdditions.additionPoids;

					this.assignationDeValeurCalcul('CorrGran', 0);
					this.assignationDeValeurCalcul('A', this.formule.composantAdditions.additionPoids);
					this.calculAAC(this.formule.composantAdditions.additionPoids);
				}
			}
		}
		res = Number.parseFloat(res.toFixed(2));
		this.assignationDeValeurCalcul('CKA', res);
	}

	//calcul Eau Totale avec Eau de adjuvants
	calculEauTotalAvecAdjuvant(frmTheoEauTotale) {
		let res = 0;
		if (this.formule && this.formule.composantEaux && this.formule.composantEaux.eauTotal) {
			res = this.formule.composantEaux.eauTotal;
			if (!frmTheoEauTotale && this.formule.composantAdjuvants && this.formule.composantAdjuvants.eauAdjuvant) {
				res += this.formule.composantAdjuvants.eauAdjuvant;
			}
		}
		//SP 12/02/21 ajout de l'eau des granulats
		if (frmTheoEauTotale && this.formule && this.formule.composantGranulats && this.formule.composantGranulats.eauTotal) {
			res += this.formule.composantGranulats.eauTotal;
		}
		if (!frmTheoEauTotale && this.formule && this.formule.composantGranulats && this.formule.composantGranulats.absorptionGranulats) {
			res += this.formule.composantGranulats.absorptionGranulats;
		}
		res = Number.parseFloat(res.toFixed(2));
		this.assignationDeValeurCalcul('EAU', res);
	}

	//calcul Eau Totale sans Eau de adjuvants
	calculEauTotalSansAdjuvant(frmTheoEauTotale) {
		let res = 0;
		if (this.formule && this.formule.composantEaux && this.formule.composantEaux.eauTotal) {
			res = this.formule.composantEaux.eauTotal;
		}
		//SP 12/02/21 ajout de l'eau des granulats
		if (frmTheoEauTotale && this.formule && this.formule.composantGranulats && this.formule.composantGranulats.eauTotal) {
			res += this.formule.composantGranulats.eauTotal;
		}
		if (!frmTheoEauTotale && this.formule && this.formule.composantGranulats && this.formule.composantGranulats.absorptionGranulats) {
			res += this.formule.composantGranulats.absorptionGranulats;
		}
		res = Number.parseFloat(res.toFixed(2));
		this.assignationDeValeurCalcul('EAU', res);
	}

	//calcul Eau Efficace avec Eau de adjuvants
	calculEauEfficace(frmTheoEauTotale) {
		let res = 0;
		if (frmTheoEauTotale) {
			if (this.formule && this.formule.vdc && this.formule.composantGranulats) {
				const idx = this.formule.vdc.findIndex((x) => x.code === 'EAU');
				if (idx > -1 && this.formule.vdc[idx].calcul) {
					res = this.formule.vdc[idx].calcul - this.formule.composantGranulats.absorptionGranulats;
				}
			}
		} else {
			//formule avec eau efficace
			if (this.formule && this.formule.composantEaux && this.formule.composantEaux.eauTotal && this.formule.eauAdjuvantSelected === true) {
				res += this.formule.composantEaux.eauTotal + this.formule.composantAdjuvants.eauAdjuvant;
			} else if (this.formule && this.formule.composantEaux && this.formule.composantEaux.eauTotal) {
				res += this.formule.composantEaux.eauTotal;
			}
		}
		res = Number.parseFloat(res.toFixed(2));
		this.assignationDeValeurCalcul('Eeff', res);
	}

	//calcul Eeff/C
	calculEauTotaleSurCiment() {
		let res = 0;
		if (this.formule && this.formule.vdc) {
			//SP 25/10/21 ?? eau efficace ?
			const idx = this.formule.vdc.findIndex((x) => x.code === 'Eeff');
			if (idx > -1 && this.formule.vdc[idx].calcul && this.formule.vdc[idx].calcul && this.formule.composantCiments.cimentPoids > 0) {
				res = this.formule.vdc[idx].calcul / this.formule.composantCiments.cimentPoids;
			}
		}
		res = Number.parseFloat(res.toFixed(2));
		this.assignationDeValeurCalcul('EC', res);
	}

	// calcul Eeff /(C + kA)
	calculECKA() {
		let res = 0;
		if (this.formule && this.formule.vdc) {
			const idxCKA = this.formule.vdc.findIndex((x) => x.code === 'CKA');
			const idxEeff = this.formule.vdc.findIndex((x) => x.code === 'Eeff');
			if (idxCKA > -1 && idxEeff > 1 && this.formule.vdc[idxEeff].calcul && this.formule.vdc[idxCKA].calcul > 0) {
				res = this.formule.vdc[idxEeff].calcul / this.formule.vdc[idxCKA].calcul;
				res = Number.parseFloat(res.toFixed(2));
				this.assignationDeValeurCalcul('ECKA', res);
			}
		}
	}

	// calcul Eau Liée
	calculEauLiee() {
		let res = 0;
		if (this.formule && this.formule.vdc) {
			const idx = this.formule.vdc.findIndex((x) => x.code === 'ECKA');
			if (idx > -1 && this.formule.vdc[idx].calcul) {
				res = this.formule.vdc[idx].calcul / 4;
			}
		}
		res = Number.parseFloat(res.toFixed(2));
		this.assignationDeValeurCalcul('EL', res);
	}

	// calcul Eau interstitielle
	calculEauInterstitielle() {
		let res = 0;
		if (this.formule && this.formule.vdc) {
			const idxEauTotal = this.formule.vdc.findIndex((x) => x.code === 'EAU');
			const idxEauLiee = this.formule.vdc.findIndex((x) => x.code === 'EL');
			if (idxEauTotal > -1 && idxEauTotal > -1 && this.formule.vdc[idxEauTotal].calcul && this.formule.vdc[idxEauLiee].calcul) {
				res =
					this.formule.vdc[idxEauTotal].calcul - this.formule.vdc[idxEauLiee].calcul - this.formule.composantGranulats.absorptionGranulats;
			}
		}
		res = Number.parseFloat(res.toFixed(2));
		this.assignationDeValeurCalcul('EINT', res);
	}

	// calcul granulats sur sables
	calculGravillonsSurSables() {
		if (
			this.formule &&
			this.formule.composantGranulats &&
			this.formule.composantGranulats.granulatSable &&
			this.formule.composantGranulats.granulatSable > 0 &&
			this.formule.composantGranulats.granulatGravillon
		) {
			let res = this.formule.composantGranulats.granulatGravillon / this.formule.composantGranulats.granulatSable;
			res = Number.parseFloat(res.toFixed(2));
			this.assignationDeValeurCalcul('GS', res);
		}
	}

	// calcul Sable sur sables + granulats
	calculSableSurSablePlusGranulat() {
		let res = 0;
		if (
			this.formule &&
			((this.formule.composantGranulats && this.formule.composantGranulats.granulatSable > 0) ||
				(this.formule.composantGranulats && this.formule.composantGranulats.granulatGravillon))
		) {
			res =
				this.formule.composantGranulats.granulatSable /
				(this.formule.composantGranulats.granulatSable + this.formule.composantGranulats.granulatGravillon);
		}
		res = Number.parseFloat(res.toFixed(2));
		this.assignationDeValeurCalcul('SSG1', res);
	}

	//calcul Volume d'Air
	async calculVolumeAir() {
		if (this.formule && this.formule.volumeAir) {
			const res = Number.parseFloat(this.formule.volumeAir) || 0;
			this.assignationDeValeurCalcul('VA', res);
		}

		const modelValeur = {
			normeSpec: -1,
			idBeton: null,
			idCiment: null,
			idAddition: null,
			idClasseExpo: null,
			grandD: null,
			valeurDeControleId: this.formule ? this.formule.vdc : null
		};

		if (this.formule && this.formule.normeSpec?.id) {
			modelValeur.normeSpec = this.formule.normeSpec?.id;
		}
		if ((this.formule && this.formule.produits?.produit?.typeId) || (this.formule && this.formule.idTypeComposition)) {
			modelValeur.idBeton = this.formule.idTypeComposition != null ? this.formule.idTypeComposition : this.formule.produits?.produit?.typeId;
		}
		if (this.formule && this.formule.composantCiments && this.formule.composantCiments.cimentId) {
			modelValeur.idCiment = this.formule.composantCiments.cimentId;
		}
		if (this.formule && this.formule.composantAdditions && this.formule.composantAdditions.additionId) {
			modelValeur.idAddition = this.formule.composantAdditions.additionId;
		}
		if (this.formule && this.formule.classeExpo && this.formule.classeExpo.id) {
			modelValeur.idClasseExpo = this.formule.classeExpo.id;
		}
		if (this.formule && this.formule.composantGranulats && this.formule.composantGranulats.grandD) {
			modelValeur.grandD = this.formule.composantGranulats.grandD;
		}
	}

	// Resistance Estimée
	calculResistanceEstimee() {
		let res = 0;

		if (this.formule && this.formule.vdc) {
			if (this.formule.resistanceEstimee) {
				res = this.formule.resistanceEstimee;
				this.formule.resistanceEstimee = res;
			} else {
				const idxCKA = this.formule.vdc.findIndex((x) => x.code === 'CKA');
				const idxEeff = this.formule.vdc.findIndex((x) => x.code === 'Eeff');
				let cka = 0;
				let Eeff = 0;
				if (idxCKA > -1 && this.formule.vdc[idxCKA].calcul) {
					cka = this.formule.vdc[idxCKA].calcul;
				}
				if (idxEeff > -1 && this.formule.vdc[idxEeff].calcul) {
					Eeff = this.formule.vdc[idxEeff].calcul;
				}

				if (!this.formule.volumeAir) {
					this.formule.volumeAir = 0;
				}
				if (Eeff > 0 || (this.formule.volumeAir > 0 && this.formule.composantCiments && this.formule.composantGranulats)) {
					let moyenneKb2 = 0;
					if (this.formule.composantGranulats.coefGranulaire && this.formule.composantGranulats.compteurGranulat) {
						moyenneKb2 = this.formule.composantGranulats.coefGranulaire / this.formule.composantGranulats.compteurGranulat;
					}

					res =
						this.formule.composantCiments.classeVraieDuCiment *
						moyenneKb2 *
						(cka / (Eeff + Number.parseFloat(this.formule.volumeAir)) - 0.5);
				}
			}
		}
		res = Number.parseFloat(res.toFixed(2));
		this.formule.resistanceEstimee = Number.parseFloat(res.toFixed(2));
		this.assignationDeValeurCalcul('RE', res);
	}

	// Ecart type adopté
	calculETA() {
		if (this.formule && this.formule.ecartTypeAdopte !== null) {
			const res = this.formule.ecartTypeAdopte;
			this.assignationDeValeurCalcul('ETA', res);
		}
	}

	// calcul granulat sur masse totale
	calculGranultatsSurMasseTotale() {
		let res = 0;
		if (
			this.formule &&
			((this.formule.composantEaux && this.formule.composantEaux.eauTotal > 0) ||
				(this.formule.composantGranulats && this.formule.composantGranulats.granulatPoids > 0) ||
				(this.formule.composantAdditions && this.formule.composantAdditions.additionPoids > 0) ||
				(this.formule.composantCiments && this.formule.composantCiments.cimentPoids > 0) ||
				(this.formule.composantAdjuvants && this.formule.composantAdjuvants.adjuvantPoids > 0))
		) {
			res =
				this.formule.composantGranulats.granulatPoids /
				(this.formule.composantEaux.eauTotal +
					this.formule.composantGranulats.granulatPoids +
					this.formule.composantAdditions.additionPoids +
					this.formule.composantCiments.cimentPoids +
					this.formule.composantAdjuvants.adjuvantPoids);
		}
		res = Number.parseFloat(res.toFixed(2));
		this.assignationDeValeurCalcul('GMT', res);
	}

	// calculMVT
	calculMasseVolumiqueTheorique() {
		let res = 0;
		if (
			this.formule &&
			((this.formule.composantEaux && this.formule.composantEaux.eauTotal) ||
				(this.formule.composantGranulats && this.formule.composantGranulats.granulatPoids) ||
				(this.formule.composantAdditions && this.formule.composantAdditions.additionPoids) ||
				(this.formule.composantCiments && this.formule.composantCiments.cimentPoids) ||
				(this.formule.composantAdjuvants && this.formule.composantAdjuvants.adjuvantPoids))
		) {
			res =
				(this.formule.composantEaux.eauTotal +
					this.formule.composantGranulats.granulatPoids +
					this.formule.composantAdditions.additionPoids +
					this.formule.composantCiments.cimentPoids +
					this.formule.composantAdjuvants.adjuvantPoids +
					(this.formule.composantGranulats.absorptionGranulats || 0)) /
				1000;
		}

		if (res) {
			this.calculMasseVolumiqueTheoriqueKgM3(res);
		}

		res = Number.parseFloat(res.toFixed(3));
		this.formule.mvt = res;
		this.assignationDeValeurCalcul('MVT', res);
	}

	// calculMVTH -(kg/m3)
	calculMasseVolumiqueTheoriqueKgM3(res) {
		res = Number.parseFloat(res.toFixed(3) * 1000);
		this.assignationDeValeurCalcul('MVTH', res);
	}

	//calcul Volume Theorique
	calculVolumeTheorique() {
		let res = 0;
		if (
			this.formule &&
			this.formule.vdc &&
			(this.formule.composantAdditions ||
				this.formule.composantAdjuvants ||
				this.formule.composantCiments ||
				this.formule.composantGranulats ||
				this.formule.composantEaux)
		) {
			const MVTotalComposant =
				this.formule.composantAdditions.sommeMVAdd +
				this.formule.composantAdjuvants.sommeMVAdj +
				this.formule.composantCiments.sommeMVCim +
				this.formule.composantGranulats.sommeMVGran +
				this.formule.composantEaux.totalEauMvTEau;

			res = Number.parseFloat(this.formule.volumeAir) + (MVTotalComposant ? Number.parseFloat(MVTotalComposant) : 0);
		}
		res = Number.parseFloat(res.toFixed(2));
		this.assignationDeValeurCalcul('VT', res);
	}

	// Temps Malaxage
	calculMAL() {
		if (this.formule && this.formule.tempsMalaxage !== null) {
			const res = this.formule.tempsMalaxage;
			this.assignationDeValeurCalcul('MAL', res);
		}
	}

	// NatureCiment
	calculNatCim() {
		if (
			this.formule &&
			this.formule.composantCiments !== null /*&&
            this.formule.composantCiments.natureCiment &&
            this.formule.composantCiments.natureCiment.length > 0*/
		) {
			let res = '';
			if (
				this.formule.composantCiments &&
				this.formule.composantCiments.natureCiment &&
				this.formule.composantCiments.natureCiment.length > 0
			) {
				res = this.formule.composantCiments.natureCiment.join();
			}
			this.assignationDeValeurCalcul('NatCim', res);
		}
	}

	// calcul Addition / ciment
	calculAddCim() {
		if (
			this.formule &&
			this.formule.composantCiments &&
			this.formule.composantAdditions &&
			this.formule.composantCiments.cimentPoids !== 0 &&
			this.formule.composantAdditions.additionPoids
		) {
			let res = null;
			res = this.formule.composantAdditions.additionPoids / this.formule.composantCiments.cimentPoids;
			res = Number.parseFloat(res.toFixed(2));
			this.assignationDeValeurCalcul('AC', res);
		}
	}

	async getCaractParamProg(cle) {
		const c = await this.$http.get(`${__configuration.apiUrl}/massia/parametre-programme/code`, {
			params: {
				cle: cle
			}
		});
		return c.data;
	}

	calculEmissionCo2Beton() {
		let res = null;
		if (
			this.formule &&
			this.formule.composantCiments &&
			this.formule.composantGranulats &&
			this.formule.composantAdditions &&
			this.formule.composantAdjuvants &&
			this.formule.composantEaux
		) {
			res =
				this.formule.composantCiments.totalEmissionCo2 +
				this.formule.composantGranulats.totalEmissionCo2 +
				this.formule.composantAdditions.totalEmissionCo2 +
				this.formule.composantAdjuvants.totalEmissionCo2 +
				this.formule.composantEaux.totalEmissionCo2;

			res = Number.parseFloat(res.toFixed(2));
			this.assignationDeValeurCalcul('CO2', res);
		}
	}

	async modalTypeGranRecylAlert() {
		const modalInstance = this.ModalService.info({
			modalTitle: this.$translate.instant('FORMULES.TYPE_GRAN_RECYCL_ERROR.TITLE'),
			modalMsg: this.$translate.instant('FORMULES.TYPE_GRAN_RECYCL_ERROR.MESSAGE'),
			headerClass: 'modal-warning'
		});

		await modalInstance.result;
	}

	async initCalcul() {
		this.calculAddAddC();
		this.calculCG();
		this.calculCKA();
		//eau eeff
		if (!this.parametresFormulation.frmTheoEauTotale) {
			this.calculEauEfficace(false);
		}

		if (this.formule && this.formule.composantAdjuvants && this.formule.eauAdjuvantSelected === true) {
			this.calculEauTotalAvecAdjuvant(this.parametresFormulation.frmTheoEauTotale);
		} else {
			this.calculEauTotalSansAdjuvant(this.parametresFormulation.frmTheoEauTotale);
		}
		// eau total
		if (this.parametresFormulation.frmTheoEauTotale) {
			this.calculEauEfficace(true);
		}

		this.calculEauTotaleSurCiment();
		this.calculECKA();
		this.calculEauLiee();
		this.calculEauInterstitielle();
		this.calculGravillonsSurSables();
		this.calculSableSurSablePlusGranulat();

		await this.calculClasseDeResistanceMinimum();

		await this.calculVolumeAir();
		this.calculResistanceEstimee();
		this.calculETA();
		this.calculGranultatsSurMasseTotale();
		this.calculMasseVolumiqueTheorique();
		this.calculVolumeTheorique();
		this.calculMAL();
		this.calculNatCim();
		this.calculAddCim();
		await this.calculTeneurChlorure();
		await this.calculTeneurAlcalins();
		this.calculEmissionCo2Beton();
		if (this.formule.composantGranulats && this.formule.composantGranulats.isRecycle && this.formule.composantGranulats.typeGranRecycle) {
			const firstValue = this.formule.composantGranulats.typeGranRecycle[0];
			if (this.formule.composantGranulats.typeGranRecycle.every((val) => val === firstValue)) {
				await this.calculSubMassiqSable();
				await this.calculSubMassiqGrav();
				await this.calculSubMassiqGranulat();
			} else {
				this.modalTypeGranRecylAlert();
				if (this.formule && this.formule.vdc) {
					const idxGran = this.formule.vdc.findIndex((a) => a.code === 'SubMassiqGranulat');
					if (idxGran > -1) {
						this.formule.vdc[idx].seuil = '';
						this.formule.vdc[idx].comparateur = '';
					}

					const idxGrav = this.formule.vdc.findIndex((a) => a.code === 'SubMassiqGrav');
					if (idxGrav > -1) {
						this.formule.vdc[idx].seuil = '';
						this.formule.vdc[idx].comparateur = '';
					}

					const idxSable = this.formule.vdc.findIndex((a) => a.code === 'SubMassiqSable');
					if (idxSable > -1) {
						this.formule.vdc[idx].seuil = '';
						this.formule.vdc[idx].comparateur = '';
					}
				}
			}
		}
	}
}
