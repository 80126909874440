export default function Configure($stateProvider) {
    $stateProvider.state('clients-divers', {
        parent: 'common',
        url: '/clients-divers',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('clients-divers.list', {
        url: '/list',
        template: '<clients-divers></clients-divers>',
        rights: { domain: 'divers', right: 'read' },
        ncyBreadcrumb: {
            parent: 'clients-divers',
            label: '{{ "CLIENTSDIVERS.BREADCRUMBS.CLIENTSDIVERS_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_donnees',
            translate: 'CLIENTSDIVERS.BREADCRUMBS.CLIENTSDIVERS_LIST',
            order: 15,
            navigationCls: 'menu-item-separator'
        },
        search: 'CLIENTSDIVERS.BREADCRUMBS.CLIENTSDIVERS_LIST'
    });

    $stateProvider.state('clients-divers.list.detail', {
        url: '/{id}',
        template: '<client-divers-detail></client-divers-detail>',
        rights: { domain: 'divers', right: 'read' },
        ncyBreadcrumb: {
            parent: 'clients-divers.list',
            label: '{{ "CLIENTSDIVERS.BREADCRUMBS.CLIENTDIVERS_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('clients-divers.new', {
        url: '/new',
        template: '<client-divers-form></client-divers-form>',
        rights: { domain: 'divers', right: 'create' },
        params: {
            fromOffreCommande: false
        },
        ncyBreadcrumb: {
            parent: 'clients-divers.list',
            label: '{{ "CLIENTSDIVERS.BREADCRUMBS.CLIENTDIVERS_NEW" | translate}}'
        },
        search: 'CLIENTSDIVERS.BREADCRUMBS.CLIENTDIVERS_NEW'
    });

    $stateProvider.state('clients-divers.edit', {
        url: '/{id}/edit',
        template: '<client-divers-form></client-divers-form>',
        rights: { domain: 'divers', right: 'update' },
        params: {
            fromOffreCommande: false
        },
        ncyBreadcrumb: {
            parent: 'clients-divers.list',
            label: '{{ "CLIENTSDIVERS.BREADCRUMBS.CLIENTDIVERS_EDIT" | translate }}'
        }
    });

    /*$stateProvider.state('clients-divers.newSite', {
        url: '/:genre/new-site',
        template: '<site-form></site-form>',
        params: {parentState:'clients-divers.new', genre:'commerciaux'},
        ncyBreadcrumb: {
            parent: 'clients-divers.new',
            label: '{{ "SITES.BREADCRUMBS.SITE_NEW" | translate}}'
        }
    });*/

    // $stateProvider.state('clients-divers.editMachin', {
    //     url: '/:genre/new-site',
    //     template: '<site-form></site-form>',
    //     params: {parentState:'clients-divers.edit', genre:'commerciaux'},
    //     id: '{id}',
    //     ncyBreadcrumb: {
    //         parent: 'clients-divers.edit',
    //         label: '{{ "SITES.BREADCRUMBS.SITE_NEW" | translate}}'
    //     }
    // });

    // SL rooting pour la transformation
    $stateProvider.state('clients-divers.transformClient', {
        url: '/{clientDiversId}/transform-client',
        template: '<site-form></site-form>',
        params: {
            parentState: 'clients-divers.edit',
            genre: 'clients',
            fromClientDivers: 'true'
        },
        ncyBreadcrumb: {
            parent: 'clients-divers.edit',
            label: '{{ "CLIENTSDIVERS.TRANSFORMCLIENT" | translate}}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
