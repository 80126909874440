import validatorBuilder from 'validator-builder';

let prefaGrilleValidator = null;

export default class PrefaGrille {
    constructor(data = {}) {
        this.id = data.id;
        this.type = 0; //data.type;
        this.estVente = true; //data.estVente;
        this.libelle = data.libelle;
        this.societeId = data.societeId;
        this.siteCommercialId = data.siteCommercialId;
        this.transporteurId = data.transporteurId;
        this.typeClientId = data.typeClientId;
        this.clientId = data.clientId;
        this.chantierId = data.chantierId;
        this.dateDebut = data.dateDebut;
        this.dateFin = data.dateFin;
    }

    isValid() {
        prefaGrilleValidator = prefaGrilleValidator || validatorBuilder.getInstanceFor('PrefaGrille');
        const validationResults = prefaGrilleValidator.validate(this);
        return validationResults.isValid;
    }
}
