import { LoDashStatic } from 'lodash';
import { CaracteristiqueService } from '../../../../../../core/http/caracteristique.service';
import { Caracteristique } from '../../../../../../core/models/caracteristique/Caracteristique';
import { ElementCaracteristique } from '../../../../../../core/models/element-caracteristique/ElementCaracteristique';
import { ValeurCaracteristique } from '../../../../../../core/models/valeur-caracteristique/ValeurCaracteristique';

class CaractListElementController {
    caractSvc: CaracteristiqueService;
    caracteristique: Caracteristique;
    valeurs: ValeurCaracteristique[];
    model: ValeurCaracteristique[] = [];
    liste: ElementCaracteristique[] = [];
    index: number;
    defaultValue: String;
    _: LoDashStatic;
    /* @ngInject */
    constructor(CaracteristiqueService: CaracteristiqueService, _: LoDashStatic) {
        this.caractSvc = CaracteristiqueService;
        this._ = _;
    }
    $onInit() {
        const lstValCaract = this.valeurs.filter(x => x.idCaracteristique === this.caracteristique.id).map(c => {
            c.value = !c.value && c.value !== '0' && (this.defaultValue || this.defaultValue === '0') ? this.defaultValue : c.value;
            return c;
        });

        this.model = lstValCaract;
        this.loadElement();
    }
    $onDestroy() { }

    async loadElement() {
        const elements = await this.caractSvc.getAllElement(this.caracteristique.id);
        this.liste = elements;
    }

    selectElement(output: ElementCaracteristique[]) {
        this.model = [];
        for (let i = 0; i < output.length; i++) {
            const o = output[i];
            this.model.push(<ValeurCaracteristique>{
                id: 0,
                idCaracteristique: this.caracteristique.id,
                value: o.code
            });
        }
        this.cleanAndRefreshOriginalModel();
    }

    cleanAndRefreshOriginalModel() {
        this._.remove(this.valeurs, x => x.idCaracteristique === this.caracteristique.id);
        this._.forEach(this.model, m => {
            this.valeurs.push(m);
        });
    }
}
export default {
    bindings: {
        caracteristique: '<',
        valeurs: '=',
        isObligatoire: '<',
        lecture: '<',
        defaultValue: '='
    },
    controller: CaractListElementController,
    template: require('./caracteristique-list-element.html')
};
