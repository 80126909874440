export default function Configure($stateProvider) {
    $stateProvider.state('gammes-produits', {
        parent: 'common',
        url: '/gammes-produits',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('gammes-produits.list', {
        url: '/list',
        template: '<gammes-produits></gammes-produits>',
        rights: { domain: 'produits', right: 'read', application: 'laboratoire' },
        ncyBreadcrumb: {
            label: '{{ "GAMMES_PRODUITS.BREADCRUMBS.GAMME_PRODUITS_LIST" | translate}}'
        },
        // on affiche la liste des produits dans la navigation
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'GAMMES_PRODUITS.BREADCRUMBS.GAMME_PRODUITS_LIST',
            order: 4.2,
            navigationCls: 'menu-item-separator'
        },
        // la liste des produits fait partie des points d'entrée recherchables dans la navigation
        search: 'GAMMES_PRODUITS.BREADCRUMBS.GAMME_PRODUITS_LIST'
    });

    $stateProvider.state('gammes-produits.new', {
        url: '/new',
        template: '<gamme-produit-form></gamme-produit-form>',
        rights: { domain: 'produits', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher produits > ajouter
            parent: 'gammes-produits.list',
            label: '{{ "GAMMES_PRODUITS.BREADCRUMBS.GAMME_PRODUIT_NEW" | translate}}'
        },
        // l'ajout d'un produit fait partie des points d'entrée recherchables dans l'application
        search: 'GAMMES_PRODUITS.BREADCRUMBS.GAMME_PRODUIT_NEW'
    });

    $stateProvider.state('gammes-produits.list.detail', {
        url: '/{id}',
        template: '<gamme-produit-detail></gamme-produit-detail>',
        rights: { domain: 'produits', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher produits > détail
            parent: 'gammes-produits.list',
            label: '{{ "GAMMES_PRODUITS.BREADCRUMBS.GAMME_PRODUIT_DETAIL" | translate }}'
        },
        params: {
            id: null,
            gamme: null
        }
    });

    $stateProvider.state('gammes-produits.edit', {
        url: '/{id}/edit',
        template: '<gamme-produit-form></gamme-produit-form>',
        rights: { domain: 'produits', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher produits > modifier
            parent: 'gammes-produits.list',
            label: '{{ "GAMMES_PRODUITS.BREADCRUMBS.GAMME_PRODUIT_EDIT" | translate }}'
        },
        params: {
            id: null,
            gamme: null
        }
    });
}

Configure.$inject = ['$stateProvider'];
