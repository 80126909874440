export default class PrelevementValeurMesureController {
	/* @ngInject */
	constructor(
		_,
		$state,
		PrelevementsService,
		notification,
		$timeout,
		$scope,
		$translate,
		MaterielService,
		$rootScope,
		MassiaRightsService,
		moment,
		RouterHistoryService,
		globalizationManagementService,
		$uibModal,
		$element,
		ModalService
	) {
		this._ = _;
		this.$state = $state;
		this.PrelevementsService = PrelevementsService;
		this.notification = notification;
		this.$timeout = $timeout;
		this.$scope = $scope;
		this.$translate = $translate;
		this.MaterielService = MaterielService;
		this.MassiaRightsService = MassiaRightsService;
		this.firstTime = false;
		this.$rootScope = $rootScope;
		this.moment = moment;
		this.RouterHistoryService = RouterHistoryService;
		this.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
		//pour desactiver
		this.isDeactivate = true;
		this.$uibModal = $uibModal;
		this.$element = $element;
		this.ModalService = ModalService;
	}

	async $onInit() {
		this.certifie = this.mesure.certifie;
		this.loadingValeurs = false;
		this.saisie.materiels[this.mesure.id] = this.saisie.materiels[this.mesure.id] || null;
		if (!this.saisie.materiels[this.mesure.id] || this.saisie.materiels[this.mesure.id].length <= 0) {
			//this.saisie.materiels[this.mesure.id] = angular.copy(this.materiels);
			if (this.materiels && this.materiels.length > 0 && this.saisie && this.saisie.auditeur) {
				this.materiels[this.mesure.id] = angular.copy(this.materiels.filter((x) => x.idLaboratoire == this.saisie.auditeur.id));
			}
		}

		this.ignoreDoCheck = false;
		this.niveauVisibilite = this.mesure.niveauVisibilite || 0;
		this.canAccessImportGranulo = this.MassiaRightsService.userHasRight('importgran', 'read', 'laboratoire');
		this.displayImportGranulo = this.canAccessImportGranulo && Object.values(this.mesure.sousEssais).find((x) => x.idNature === 8) != null;
		this.startLoadingValeurs();

		// if (this.mesure)
		//     this.rechargeSsEss();

		this.unregister = [];

		const canAccessMetrologie = this.MassiaRightsService.userHasRight('materiel', 'read', 'laboratoire');

		if (canAccessMetrologie) {
			if (this.mesure) {
				this.unregister.push(
					this.$scope.$watchCollection(
						() => this.mesuresMateriels,
						async (newValue) => {
							if (newValue) {
								this.selectMateriels();
							}
						}
					)
				);
			}
		}
		const haveIteratifs = Object.values(this.mesure.sousEssais).find((x) => x.idNature === 10);
		this.unregister.push(
			this.$scope.$watch(
				() => this.blDataLoaded,
				async (newValue, oldValue) => {
					if (newValue === true && haveIteratifs) {
						const iteratifs = angular.element('massia-essai-iteratif');
						if (iteratifs && iteratifs.length > 0) {
							const inputs = iteratifs.find('tbody')?.find('input');
							if (inputs && inputs.length > 0) {
								inputs.trigger('blur');
							}
						}
					}
				}
			)
		);
		this.stopLoadingValeurs();
	}

	$onDestroy() {
		this.$timeout.cancel(this.typeFormatTimeout);

		for (let i = 0; i < this.unregister.length; i++) {
			this.unregister[i]();
		}
	}

	// debug() {
	//     console.log(' 🔥', this);
	// }

	//-------- MATERIELS--------------- //

	deleteMateriel(materiel) {
		const mat = this.saisie.materiels[this.mesure.id].find((m) => m.id === materiel.id);
		mat.selected = false;
	}

	onSelect(data, selected) {
		//  console.log(selected, this.saisie.materiels[this.mesure.id]);
		for (const s of selected) {
			this.saisie.materiels[this.mesure.id].forEach((m) => {
				if (!m.id !== s.id) {
					return;
				}
				m.selected = s.selected;
				return;
			});
		}
	}

	clone(data) {
		if (Array.isArray(data)) {
			return [...data];
		}
		return Object.assign([], data);
	}

	async selectMateriels() {
		try {
			this.defaultSelected = Array.from(
				new Set([...this.prelevMateriels.map((x) => x.idMateriel)], [...this.mesuresMateriels.map((x) => x.idMateriel)])
			).map((x) => ({ id: x }));
			// console.log(this.defaultSelected);
			//console.log('1', this.mesuresMateriels);
			if (this.saisie.materiels[this.mesure.id]) {
				this.saisie.materiels[this.mesure.id].forEach((l) => {
					/* const matIsDefault = this.prelevMateriels.find((x) => x.idMateriel === l.id); */
					const matLinkedToEssai = this.mesuresMateriels.find((x) => x.idMateriel === l.id);
					/* if (!l.hasOwnProperty('selected')) {
                        l.selected = matIsDefault ? true : false;
                        l.selected = matLinkedToEssai ? true : l.selected;
                    } */
					l.selected = l.inDb = !!~this.defaultSelected.findIndex((x) => x.id === l.id);
					if (!l.hasOwnProperty('linkedParams')) {
						if (!matLinkedToEssai) {
							l.linkedParams = new EssaisMateriel();
							l.linkedParams.idMateriel = l.id;
							l.linkedParams.idPrelevement = this.saisie.id;
							l.linkedParams.idEssai = this.mesure.id;
						} else {
							l.linkedParams = matLinkedToEssai;
							l.linkedParams.idPrelevement = this.saisie.id;
							l.linkedParams.idEssai = this.mesure.id;
						}
					}
				});
				const isteven = [];
				const noLabsMat = this.saisie.materiels[this.mesure.id].filter((x) => x.idLaboratoire === this.saisie.auditeur.id);
				const labsMat = this.saisie.materiels[this.mesure.id].filter((x) => x.idLaboratoire !== this.saisie.auditeur.id);
				if (noLabsMat.length > 0) {
					isteven.push(
						/* {
                            code: 'ML',
                            libelle: `Matériels du laboratoire ${this.saisie.auditeur.libelle}`,
                            msGroup: true
                        }, */
						...this.saisie.materiels[this.mesure.id].filter((x) => x.idLaboratoire === this.saisie.auditeur.id)
						/* { msGroup: false } */
					);
				}
				if (labsMat.length > 0) {
					isteven.push(
						/* { code: 'M', libelle: 'Autres matériels', msGroup: true }, */
						...this.saisie.materiels[this.mesure.id].filter((x) => x.idLaboratoire !== this.saisie.auditeur.id)
						/* { msGroup: false } */
					);
				}
				//  console.log(isteven);
				this.materiels = isteven;
				//this.saisie.materiels[this.mesure.id] = labMateriels.concat(nonLabMateriels);
			}
		} catch (e) {
			console.error('selectMateriels', e);
		}
	}

	//--------FIN DES MATERIELS--------------- //
	// rechargeSsEss() {
	//     this.changeNiveauVisibilite();
	// }

	deleteMesure(ev) {
		if (ev) {
			ev.stopPropagation();
			ev.stopImmediatePropagation();
			ev.preventDefault();
		}

		const idx = this.saisie.mesures.findIndex((e) => e.id == this.mesure.id);
		this.saisie.mesures.splice(idx, 1);
	}

	changeNiveauVisibilite(ev) {
		if (ev) {
			ev.stopPropagation();
			ev.stopImmediatePropagation();
			ev.preventDefault();
		}
	}

	focusNiveauVisibilite(ev) {
		if (ev) {
			ev.stopPropagation();
			ev.stopImmediatePropagation();
			ev.preventDefault();
		}
	}

	editEssai(ev, essai) {
		if (ev) {
			ev.stopPropagation();
			ev.stopImmediatePropagation();
			ev.preventDefault();
		}

		if (essai) {
			this.saisie.outsourceModel(this.moment, this.dateFormat);
			this.RouterHistoryService.data = this.saisie;
			return this.$state.go('essais.edit', { id: essai.id });
		}
	}

	async exitMesure() {
		if (this.launchCalcul) {
			this.launchCalcul();
		}
	}

	async mesureDeleted(mesure) {
		this.onDelete({
			id: this.mesure.id
		});
	}

	startLoadingValeurs() {
		this.loadingValeurs = true;
	}

	stopLoadingValeurs() {
		this.loadingValeurs = false;
	}

	getSousMesureForComponent(field) {
		if (!this.firstTime) {
			return field;
		}
	}

	forceEditable(idSsEss) {
		//renvoie supprimable
		const ssEssai = this.mesure.sousEssais[idSsEss];
		//forcer le sous-essai principal en éditable même si il y a une formule si aucun sous-essai sans formule est renseigné
		delete ssEssai.forceSaisie;
		const isPrincipal = this.mesure.code == ssEssai.code;
		const hasFormule = ssEssai.formule;
		if (hasFormule && isPrincipal) {
			let saisieTrouvee = false;
			const lstSansFormule = Object.values(this.mesure.sousEssais).filter((x) => x.hasOwnProperty('id') && !x.formule);
			let i = 0;
			while (i < lstSansFormule.length && !saisieTrouvee) {
				const val = lstSansFormule[i].value;
				if (lstSansFormule[i].idNature === 10 || lstSansFormule[i].idNature === 11) {
					const lstSsMes = lstSansFormule[i].generiqueAdditionalInformation.essaiLie.sousEssais;
					if (lstSsMes) {
						for (const j in lstSsMes) {
							if (lstSsMes[j].hasOwnProperty('id')) {
								const ssVal = lstSsMes[j].value;
								if (Array.isArray(ssVal)) {
									for (let k = 0; k < ssVal.length; k++) {
										if (ssVal[k] || ssVal[k] === 0) {
											saisieTrouvee = true;
											break;
										}
									}
								} else if (ssVal || ssVal === 0) {
									saisieTrouvee = true;
								}

								if (saisieTrouvee) {
									break;
								}
							}
						}
					}
				} else {
					if (Array.isArray(val)) {
						for (let j = 0; j < val.length; j++) {
							if (val[j] || val[j] === 0) {
								saisieTrouvee = true;
								break;
							}
						}
					} else if (val || val === 0) {
						saisieTrouvee = true;
					}
				}
				i++;
			}

			if (!saisieTrouvee) {
				ssEssai.forceSaisie = true;
			}

			return !saisieTrouvee;
		}

		return this.mesure.isDansTrame && isPrincipal;
	}

	// Retourne true si la date prévue de l'essai n'est pas encore arrivé. Utilisé pour désactiver l'essai
	isDatePrevueNotPassed(mesure) {
		if (typeof mesure.datePrevue !== 'undefined') {
			const datePrevue = this.moment(mesure.datePrevue, 'DD/MM/YYYY');
			const dateNow = this.moment();
			return datePrevue.isAfter(dateNow);
		}
		return false;
	}

	async importAnalyseGranulo() {
		const modalInstance = this.ModalService.open({
			component: 'modalImportGranulo',
			animation: true,
			size: 'm',
			resolve: {
				mesure: () => this.mesure
			}
		}).result.then((res) => {
			this.mesure.sousEssais[res.idx] = Object.assign({}, res.essai.sousEssais[res.idx]);
			this.notification.success('PRELEVEMENTS.IMPORT_GRANULO.SUCCESS');
		});
	}
}
