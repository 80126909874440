import AffectationPortail from '../../../models/AffectationPortail';
import DomainPortail from '../../../models/DomainPortail';

/*@ngInject*/
export default class PortailAffectationFormController {
    constructor($scope, $state, $stateParams, notification, AffectationPortailService, UtilisateurPortailService, $translate) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.notification = notification;
        this.affectationPortailService = AffectationPortailService;
        this.UtilisateurPortailService = UtilisateurPortailService;
        this.$translate = $translate;
    }

    steps = 0;
    stepInProcess = 0;
    selectedCriteres = [];
    async $onInit() {
        await this.affectationPortailService.getAllCriteres();

        this.ongletOpen = {
            isEnteteOpen: true,
            isListeCriteresOpen: false,
            isValuesOpen: false
        };
        this.loading = true;
        this.id = this.$stateParams.id ? this.$stateParams.id : false;

        if (this.id) {
            const res = await this.affectationPortailService.getOne(this.id);
            this.affectation = new AffectationPortail(res.data);
            for (let i = 0; i < this.affectation.listCriteres.length; i++) {
                await this.affectationPortailService.getList(this.affectation.listCriteres[i], this.affectation);
            }
            this.loadCriteres(this.affectation.domain.code);
        } else {
            this.loadDomains();
            this.affectation = new AffectationPortail();
            this.noneSelect = true;
        }
        await this.loadUsers();
        this.loading = false;
    }

    async loadUsers() {
        const users = await this.UtilisateurPortailService.getAll(null, null, null, true);
        this.utilisateursPortail = users.data;

        for (let i = 0; i < this.utilisateursPortail.length; i++) {
            if (this.affectation.listPersonnes.length > 0) {
                const findInAffectation = this.affectation.listPersonnes.find((x) => x.id === this.utilisateursPortail[i].id) ? true : false;
                this.utilisateursPortail[i].selected = findInAffectation;
            }
            if (!this.utilisateursPortail[i].msGroup && this.utilisateursPortail[i].id > 0) {
                delete this.utilisateursPortail[i].msGroup;
            }
        }
    }
    loadDomains() {
        this.domainsPortail = this.affectationPortailService.getDomains();
        for (let i = 0; i < this.domainsPortail.length; i++) {
            this.domainsPortail[i].libelle = this.$translate.instant(this.domainsPortail[i].translate);
        }
    }
    async loadCriteres(domainCode) {
        //* liste des critères de sélection pour le domaine
        this.listCritere = await this.affectationPortailService.criteres[domainCode.toLowerCase()];

        //* préselectionne les critères sauvegardés pour l'affectation
        if (this.affectation.listCriteres.length > 0) {
            for (let i = 0; i < this.affectation.listCriteres.length; i++) {
                const item = this.listCritere.find((x) => x.code === this.affectation.listCriteres[i].code);
                this.selectedCriteres.push(item);
                item.selected = true;
            }
            this.validerSelectedCriteres();
        }
    }
    updateListUsers(data) {
        if (data.msGroup) {
            const group = this.utilisateursPortail.filter((x) => x.idSite === data.idSite);
            const selected = data.selected ? false : true;

            for (let i = 0; i < group.lenght; i++) {
                group[i].selected = selected;
            }
        }
    }
    selectDomain(data) {
        this.affectation.domainCode = data.code;
        this.affectation.domain = new DomainPortail(data);
        this.loadCriteres(data.code);
    }
    validerSelectedCriteres() {
        this.ongletOpen.isListeCriteresOpen = true;
        this.listValuesDisabled = false;
        this.loadingListes = true;
        this.steps = this.selectedCriteres.length - 1;
        this.setOrderCriteres();
        this.loadListStepper();
        this.loadingListes = false;
    }
    async disableListValues(data) {
        if (this.selectedCriteres.length > 0) {
            this.listValuesDisabled = true;
            this.noneSelect = false;
        } else {
            this.noneSelect = true;
            this.listValuesDisabled = false;
        }

        // if(data.selected){
        //   await this.affectationPortailService.getList(data, this.affectation)
        // }
    }

    $onDestroy() {}

    async loadListStepper() {
        const critere = this.selectedCriteres[this.stepInProcess];
        if (!critere.values) {
            critere.values = [];
        }

        await this.affectationPortailService.getList(critere, this.affectation);

        this.affectation.listCriteres.push(this.selectedCriteres[this.stepInProcess]);
        this.libelle = this.selectedCriteres[this.stepInProcess].libelle;
        this.listToDisplay = this.affectationPortailService.listes[critere.code];
    }

    setOrderCriteres() {
        for (let i = 0; i < this.selectedCriteres.length; i++) {
            const dependency = this.affectationPortailService.getDependencies(this.selectedCriteres[i].code);
            this.selectedCriteres[i].dependsOn = dependency;
            this.selectedCriteres[i].dependencies = dependency.length;
        }

        this.selectedCriteres.sort(function (a, b) {
            return a.dependencies - b.dependencies;
        });
    }

    validerEtFermer() {}
    valider() {}
    annuler() {}
    async reinit() {
        this.affectation = angular.copy(this.affectationCopie);
        this.step = 0;

        this.loadDomains();
        await this.loadUsers();
    }

    previousCritere() {
        this.saveCurrentCritere();
        this.stepInProcess -= 1;
        this.loadListStepper();
    }
    nextCritere() {
        this.saveCurrentCritere();
        this.stepInProcess += 1;
        this.loadListStepper();
    }

    saveCurrentCritere() {
        const critere = this.selectedCriteres[this.stepInProcess];
        const critereAffectation = this.affectation.listCriteres.find((x) => x.code === critere.code);
        critereAffectation.values = this.selectedValues.map((x) => x.id);
    }
}
