PaiementEnteteValidator.$inject = ['validator'];

export default function PaiementEnteteValidator(validator) {
    const instance = new validator();
    instance.ruleFor('code').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('code').length(1, 15).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');
    instance
        .ruleFor('code')
        .matches(/^[a-zA-Z0-9]*$/)
        .withMessage('PAIEMENTS.CODE_CHARACTERS_NOK');

    instance.ruleFor('libelle').notEmpty().withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('libelle').length(1, 100).withMessage('VALIDATION_TOO_LONG_DESCRIPTION');

    instance.ruleFor('typeId').notEmpty().withMessage('VALIDATION_NOTEMPTY');

    instance.ruleFor('echeanceUnite').notEmpty().when(showAdvanced).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('echeance').greaterThan(-1).when(showAdvanced).withMessage('VALIDATION_NOTEMPTY');
    instance.ruleFor('modeCalcul').greaterThan(-1).when(showAdvanced).withMessage('VALIDATION_NOTEMPTY');

    instance.ruleFor('jourDuMois').greaterThan(-1).when(showJourFinMois).withMessage('VALIDATION_NOTEMPTY');

    return instance;

    function showAdvanced(obj, prop) {
        return typeof obj.typeId !== 'undefined' && obj.typeId != 1 && obj.typeId != 10 && obj.typeId != 0;
    }

    function showJourFinMois(obj, prop) {
        return showAdvanced(obj, prop) && obj.modeCalcul != 'undefined' && (obj.modeCalcul == 21 || obj.modeCalcul == 11);
    }
}
