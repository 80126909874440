import CentreGestionConfig from './_config/centre.gestion.config';
import CentreGestionRoutes from './_config/centre.gestion.routes';
import CentreGestionComponent from './components/centre-gestion';
import TypesClientsComponent from './components/centre-gestion/types-clients';
import AvEsComponent from './components/centre-gestion/av-es';
import WithDateComponent from './components/centre-gestion/with-date';
import ElementsLiesComponent from './components/centre-gestion/elements-lies';
import PrestationsLieesComponent from './components/centre-gestion/prestations-liees';
import CdgFiltresComponent from './components/centre-gestion/cdg-filtres';
import CdgTVAComponent from './components/centre-gestion/cdg-tva';
import ExportFormComponent from './components/centre-gestion/export-form';
import CentreGestionService from './services/centre.gestion.service';
// import QteTranspMiniComponent from './components/centre-gestion/qte-transp-mini';

const moduleName = 'app.massia.common.centre.gestion';

angular
    .module(moduleName, [])
    .config(CentreGestionConfig)
    .config(CentreGestionRoutes)
    .service('CentreGestionService', CentreGestionService)
    .component('centreGestion', CentreGestionComponent)
    .component('avEs', AvEsComponent)
    .component('withDate', WithDateComponent)
    // .component('qteTranspMini', QteTranspMiniComponent)
    .component('elementsLies', ElementsLiesComponent)
    .component('prestationsLiees', PrestationsLieesComponent)
    .component('cdgFiltres', CdgFiltresComponent)
    .component('cdgTva', CdgTVAComponent)
    .component('exportForm', ExportFormComponent)
    .component('typesClients', TypesClientsComponent);

export default moduleName;
