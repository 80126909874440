import validatorBuilder from 'validator-builder';

let logoValidator = null;

export default class Logo {
    constructor() {
        this.id = null;
        this.code = null;
        this.libelle = null;
        this.idType = null;
        this.source = null;
        this.taille = null;
        this.file = null;
    }

    isValid() {
        logoValidator = logoValidator || validatorBuilder.getInstanceFor('Logo');
        const validationResults = logoValidator.validate(this);
        return validationResults.isValid;
    }
}
