export default class MassiaNavigationService {
    static $inject = ['_', 'MassiaRightsService', 'MassiaApplicationService', 'MassiaClientService'];

    constructor(_, MassiaRightsService, MassiaApplicationService, MassiaClientService) {
        this._ = _;
        this.MassiaRightsService = MassiaRightsService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.MassiaClientService = MassiaClientService;
        this.items = [];
    }

    setItems(values = [], activite) {
        this.items.splice(0, this.items.length);
        values.forEach(this.addItem, this);
    }

    getItems() {
        return this.items;
    }

    clearItems() {
        this.items = [];
    }

    addItem(config = {}, activite) {
        if (config && config.id) {
            const index = this._.findIndex(this.items, { id: config.id });

            if (index >= 0) {
                this.items[index] = this.createItem(config, activite);
            } else {
                this.items.push(this.createItem(config, activite));
            }
        }
    }

    createItem(config, activite) {
        const children = (config.items || [])
            .filter((child) => {
                const enfantAAjouter = true;
                if (
                    (child.id == 'applications_gestion' && (activite == 'gestion' || !__configuration.licenceGest)) ||
                    (child.id == 'applications_performance' && (activite == 'performance' || !__configuration.licencePerf)) ||
                    (child.id == 'applications_qse' && (activite == 'qse' || !__configuration.licenceQse)) ||
                    (child.id == 'applications_laboratoire' && (activite == 'laboratoire' || !__configuration.licenceLaboratoire))
                ) {
                    return false;
                }
                return (
                    child &&
                    // on retire ceux que l'utilisateur n'a pas le droit de voir
                    (child.rights === undefined ||
                        this.MassiaRightsService.userHasRight(child.rights.domain, child.rights.right, child.rights.application)) &&
                    //on retire ceux qui ont une prop de restriction client
                    (child.clientRestriction === undefined || child.clientRestriction.indexOf(this.MassiaClientService.getClient()) >= 0)
                );
            })
            .map(this.createItem, this, activite);

        const item = {
            id: config.id,
            type: config.type,
            icon: config.icon,
            navigationCls: config.navigationCls,
            translate: config.translate,
            translateCls: config.translateCls,
            sref: config.sref,
            href: config.href,
            collapse: config.collapse,
            position: config.position || 'left',
            order: config.order || 0,
            header: config.header || false,
            divider: config.divider || false,
            items: children.length ? children : undefined,
            rights: config.rights,
            clientRestriction: config.clientRestriction
        };

        for (const p in config) {
            if (p.startsWith('href_')) {
                item[p] = config[p];
            }
        }

        return item;
    }

    removeItem(id, recursive) {}
}
