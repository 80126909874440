import validatorBuilder from 'validator-builder';
import AdditionnalInfoMesure from '../nature-additionnal-info-mesure/additionnal.info.mesure.model';

let mesureEnteteValidator = null;

export default class MesureEntete {
    constructor(data = {}) {
        this.id = data.id;
        this.idEssai = data.idEssai;
        this.code = data.code;
        this.libelle = data.libelle;
        this.typeId = data.typeId;
        this.codeExists = data.codeExists || null;
        this.symboleNorme = data.symboleNorme;
        this.norme = data.norme;
        this.idNature = typeof data.idNature === 'number' ? data.idNature : 70;
        this.additionnalInfoMesure = new AdditionnalInfoMesure(this.idNature, data.additionnalInfoMesure || {});
        //this.formule = data.formule;
        //this.defaultValue = data.defaultValue;
        this.niveauVisibilite = 0;
        this.visibiliteImpression = data.visibiliteImpression || 0;
        this.visibiliteSaisie = data.visibiliteSaisie || 0;
        this.visibiliteStat = data.visibiliteStat || 0;
        this.formulePlanification = data.formulePlanification;
        this.texteNonConforme = data.texteNonConforme;
    }

    isValid() {
        mesureEnteteValidator = mesureEnteteValidator || validatorBuilder.getInstanceFor('MesureEntete');
        const validationResults = mesureEnteteValidator.validate(this);
        return validationResults.isValid;
    }

    getData() {
        const data = {
            id: this.id,
            code: this.code,
            libelle: this.libelle,
            idNature: this.idNature,
            typeId: this.domaines.map((domaine) => domaine.idDomaine),
            formule: this.formule,
            defaultValue: this.defaultValue,
            idTypeAide: data.idTypeAide,
            aideUrl: data.aideUrl,
            aideToolType: data.aideToolType,
            document: data.document
        };

        angular.forEach(this.additionnalInfoMesure, (info, key) => {
            data[key] = info;
        });

        return data;
    }
}
