export default function Configure($stateProvider) {
    $stateProvider.state('laboratoires', {
        parent: 'common',
        url: '/laboratoires',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('laboratoires.list', {
        url: '/:genre/list',
        template: '<sites></sites>',
        rights: { domain: 'sitelabo', right: 'read', application: 'laboratoire' },
        ncyBreadcrumb: {
            label: '{{ "SITES.BREADCRUMBS.SITES_LABORATOIRES_LIST" | translate}}'
        }
    });

    $stateProvider.state('laboratoires.niveaux', {
        url: '/:genre/niveaux',
        template: '<site-config-niveaux></site-config-niveaux>',
        rights: { domain: 'nivh', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher sites > niveaux
            parent: 'laboratoires.list({genre: "laboratoires"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_NIVEAUX" | translate }}'
        }
    });

    $stateProvider.state('laboratoires.list.detail', {
        url: '/:genre/{id}',
        template: '<site-detail></site-detail>',
        ncyBreadcrumb: {
            parent: 'laboratoires.list({genre: "laboratoires"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('laboratoires.new', {
        url: '/:genre/new',
        template: '<site-form></site-form>',
        rights: { domain: 'sitelabo', right: 'create' },
        ncyBreadcrumb: {
            parent: 'laboratoires.list({genre: "laboratoires"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_NEW_LABORATOIRE" | translate}}'
        },
        search: 'SITES.BREADCRUMBS.SITE_NEW_LABORATOIRE'
    });

    $stateProvider.state('laboratoires.edit', {
        url: '/:genre/{id}/edit',
        template: '<site-form></site-form>',
        rights: { domain: 'sitelabo', right: 'update' },
        ncyBreadcrumb: {
            parent: 'laboratoires.list({genre: "laboratoires"})',
            label: '{{ "SITES.BREADCRUMBS.SITE_EDIT_LABORATOIRE" | translate }}'
        }
    });

    $stateProvider.state('laboratoires.feries', {
        url: '/:genre/{id}/feries',
        template: '<site-jours-feries></site-jours-feries>',
        rights: { domain: 'jferies', right: 'create' },
        ncyBreadcrumb: {
            parent: 'laboratoires.list({genre: "laboratoires"})',
            label: '{{ "SITES.BREADCRUMBS.JOURS_FERIES" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
