const serviceUrl = `${__configuration.apiUrl}/massia/gestion/equipage`;

export default class EquipagesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getEquipages(date, societeId, siteId) {
        const filtres = {
            date: date,
            societeId: societeId,
            siteId: siteId
        };
        const res = await this.$http.get(serviceUrl, {
            params: {
                filtres: JSON.stringify(filtres || {})
            }
        });

        return res.data;
    }

    async getEquipageEntete(date, societeId, siteId) {
        const filtres = {
            date: date,
            societeId: societeId,
            siteId: siteId
        };
        const res = await this.$http.get(serviceUrl + '/entete', {
            params: {
                filtres: JSON.stringify(filtres || {})
            }
        });

        return res.data;
    }

    async getChauffeurs(idSociete, idSite) {
        if (idSociete == null) {
            idSociete = -1;
        }
        if (idSite == null) {
            idSite = -1;
        }
        const chauffUrl = `${__configuration.apiUrl}/massia/personnes/chauffeurs`;
        const res = await this.$http.get(chauffUrl, {
            params: {
                societeId: idSociete,
                siteTransporteurId: idSite,
                withFiltres: true
            }
        });
        return res.data;
    }

    async getVehicules(valeur) {
        const url = `${__configuration.apiUrl}/massia/vehicules/header/${valeur}`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async getSocietes() {
        const url = `${__configuration.apiUrl}/massia/societes/header-genre`;
        const res = await this.$http.get(url, {
            params: {
                isCommercial: false,
                isFournisseur: false,
                isClient: false,
                isTransporteur: true,
                isProducteur: false,
                isLaboratoire: false,
                isProprietaire: false,
                isLocataire: false,
                isAuditeur: false
            }
        });
        return res.data;
    }

    async getSites(idSociete) {
        const siteUrl = `${__configuration.apiUrl}/massia/sites-transporteurs/header/${idSociete}`;
        const res = await this.$http.get(siteUrl);

        return res.data;
    }

    async deleteEquipageById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async createEquipage(data) {
        const result = await this.$http.post(serviceUrl, data);
        return result.data;
    }

    async updateEquipage(data) {
        const url = `${serviceUrl}/${data.id}`;
        return await this.$http.put(url, data);
    }
}
