import validatorBuilder from 'validator-builder';

let offreProducteurLignePrestationValidator = null;

export default class OffreProducteurLignePrestation {
    constructor() {
        this.id = -1;
        this.typeArticle = 2;
        this.quantite = 1;
        this.prixVente = null;
        this.typePrix = null;
        this.prixStandard = null;
        this.prixProduitTransporte = null;
        this.prixAchatTransport = null;
        this.idProduit = null;
        this.unite = null;
        this.IdUnite = null;
        this.idBenne = null;
        this.tvaId = 1;
    }

    isValid() {
        offreProducteurLignePrestationValidator =
            offreProducteurLignePrestationValidator || validatorBuilder.getInstanceFor('OffreProducteurLignePrestation');
        const validationResults = offreProducteurLignePrestationValidator.validate(this);
        return validationResults.isValid;
    }
}
