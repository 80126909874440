/* @ngInject */
export default function Configure($stateProvider: any) {
	$stateProvider.state('registres', {
		parent: 'gestion',
		url: '/registres',
		views: {
			gestion: {
				template: '<ui-view></ui-view>'
			}
		},
		ncyBreadcrumb: {
			label: '{{ "LAYOUT.NAVIGATION.PESEES_FACTURATIONS" | translate }}'
		}
	});

	$stateProvider.state('registres.list', {
		url: '/list',
		template: '<registres></registres>',
		rights: { domain: 'registres', right: 'read' },
		ncyBreadcrumb: {
			parent: 'registres',
			label: '{{ "REGISTRES.BREADCRUMBS.REGISTRES_LIST" | translate}}'
		},
		navigation: {
			menu: 'registres',
            translate: 'REGISTRES.BREADCRUMBS.REGISTRES_LIST',
            order: 1,
		},
		search: 'REGISTRES.BREADCRUMBS.REGISTRES_LIST'
	});
}
