export default class SocieteConfigNiveauxController {
    static $inject = ['SocieteNiveauxTrameService', 'SocieteNiveauxElementsService', 'TypesNiveauxHierarchique'];

    constructor(SocieteNiveauxTrameService, SocieteNiveauxElementsService, TypesNiveauxHierarchique) {
        this.SocieteNiveauxTrameService = SocieteNiveauxTrameService;
        this.SocieteNiveauxElementsService = SocieteNiveauxElementsService;
        this.TypesNiveauxHierarchique = TypesNiveauxHierarchique;
    }

    $onInit() {
        this.name = 'SocieteNiveaux';

        this.treeviewTrameConfig = { orderByFunction: 'position' };

        this.treeviewTrameService = this.SocieteNiveauxTrameService;

        this.treeviewElementsConfig = {
            dropDisabled: false,
            //les elements de types pays sont les feuilles, pas d'action possible pour l'instant
            leafTypes: [this.TypesNiveauxHierarchique.Pays.value, this.TypesNiveauxHierarchique.Libre.value],
            leafTypesIcons: {}
        };
        //on set la valeur de "leafTypesIcons" en deux etape,
        //car on a besoin d'utiliser la valeur d'une variable (constant this.TypesNiveauxHierarchique.Pays.value)
        //en tant que cle d'objet
        this.treeviewElementsConfig.leafTypesIcons[this.TypesNiveauxHierarchique.Pays.value] = 'globe';
        this.treeviewElementsConfig.leafTypesIcons[this.TypesNiveauxHierarchique.Libre.value] = 'play';

        this.treeviewElementsService = this.SocieteNiveauxElementsService;
    }
}
