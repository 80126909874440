import CompteOuvertsConfig from './_config/compte.ouverts.config';
import CompteOuvertsRoutes from './_config/compte.ouverts.routes';
import CompteOuvertsExportComponent from './components/compte-ouverts-export';
import CompteOuvertsExportService from './services/compte.ouverts.export.service';

const moduleName = 'app.massia.common.compte.ouverts';

angular
    .module(moduleName, [])
    .config(CompteOuvertsConfig)
    .config(CompteOuvertsRoutes)
    .service('CompteOuvertsExportService', CompteOuvertsExportService)
    .component('compteOuvertsExport', CompteOuvertsExportComponent);
export default moduleName;
