export default class LoginProvider {
    constructor() {
        this.postLoginRedirection = '';
    }

    setPostLoginRedirection(state) {
        this.postLoginRedirection = state;
    }

    $get() {
        return {
            setPostLoginRedirection: this.setPostLoginRedirection.bind(this),
            getPostLoginRedirection: () => this.postLoginRedirection
        };
    }
}
