export default class SiteImportBLController {
    static $inject = ['$scope', '$state', '$stateParams', 'SiteImportBLService', 'notification', '$translate', '_'];

    constructor($scope, $state, $stateParams, SiteImportBLService, notification, $translate, _) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SiteImportBLService = SiteImportBLService;
        this.notification = notification;
        this.$translate = $translate;
        this._ = _;
    }

    $onInit() {
        this.loading = false;
        this.site = this.site || {};
        this.newImportBLExterne = {};
        // liste des BL dans 'importBLExterne.json'
        this.listeBL = [];
        //liste des BL déjà importés par utilisateur
        this.siteBLs = [];
        this.getSiteBLWithLibelle();
    }

    async createImportBLExterne() {
        let data = {};
        this.site.id = this.$stateParams.id;
        if (this.newImportBLExterne.typeImportBL && this.newImportBLExterne.isClient != null && this.newImportBLExterne.isPeriode != null) {
            data = {
                TypeImportBL: this.newImportBLExterne.typeImportBL,
                IsClientSelected: this.newImportBLExterne.isClient,
                IsPeriodeSelected: this.newImportBLExterne.isPeriode
            };
            try {
                await this.SiteImportBLService.createSiteBL(this.site.id, data);
                this.notification.success(this.$translate.instant('SITES.BL_EXTERNE.CREATE.SUCCESS'));
                this.startLoading();
                await this.getSiteBLWithLibelle();
                this.newImportBLExterne = {};
            } catch (ex) {
                console.error(ex);
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        }
    }

    async updateBL(importBLExterne) {
        let data = {};
        this.site.id = this.$stateParams.id;
        data = {
            IdSite: this.site.id,
            TypeImportBL: importBLExterne.typeImportBL,
            IsClientSelected: importBLExterne.isClientSelected,
            IsPeriodeSelected: importBLExterne.isPeriodeSelected
        };
        try {
            await this.SiteImportBLService.updateSiteBL(this.site.id, importBLExterne.id, data);
            this.notification.success(this.$translate.instant('SITES.BL_EXTERNE.UPDATE.SUCCESS'));
            this.startLoading();
            await this.getSiteBLWithLibelle();
        } catch (ex) {
            console.error(ex);
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async deleteBL(importBLExterne) {
        this.site.id = this.$stateParams.id;
        try {
            await this.SiteImportBLService.deleteSiteBL(this.site.id, importBLExterne.id);
            this.notification.success(this.$translate.instant('SITES.BL_EXTERNE.DELETE.SUCCESS'));
            this.startLoading();
            await this.getSiteBLWithLibelle();
        } catch (ex) {
            console.error(ex);
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    activeEditMode(importBLExterne) {
        importBLExterne.isEditMode = true;
        /*         _.map(this.listeBL, function(o){
            if (o.id == importBLExterne.typeImportBL) {
                o.selected = false;
            }
        });
 */
    }

    desactiveEditMode(importBLExterne) {
        importBLExterne.isEditMode = false;
    }

    // liste (Objets) de toutes les modèles de BL externes dans assets/enum/sites/importBLExterne.json
    async getAllImportBL() {
        try {
            var listeBL = await this.SiteImportBLService.getAllImportBLExterne();
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
        return listeBL;
    }
    // liste des BL externes (sans libelle) déjà ensegistrées dans BDD par utillisateur
    async getSitetBLs() {
        // en cas de modification, affiche les BL importés
        if (this.$stateParams.id) {
            this.site.id = this.$stateParams.id;
            try {
                var siteBLs = await this.SiteImportBLService.getSiteBLs(this.site.id);
            } catch (err) {
                if (err.data) {
                    this.notification.error(err.data);
                } else {
                    throw err;
                }
            }
            // utiliser angular.copy pour mise à jour data et vider le cahche
            return angular.copy(siteBLs);
        }
        return (siteBLs = []);
    }
    // recuperer les libelles et filtrer des BL déjà ensegistrées dans BDD par utillisateur
    async getSiteBLWithLibelle() {
        this.startLoading();
        const listeBL = await this.getAllImportBL();
        const siteBLs = await this.getSitetBLs();
        // liste des BL dans ENUM
        for (var i = 0; i < listeBL.length; i++) {
            if (siteBLs.length == 0) {
                listeBL[i].selected = false;
            } else {
                _.map(siteBLs, function (o) {
                    if (o.typeImportBL == listeBL[i].id) {
                        o.libelle = listeBL[i].libelle;
                        listeBL[i].selected = true;
                    } else if (!listeBL[i].selected) {
                        listeBL[i].selected = false;
                    }
                    o.isEditMode = false;
                });
            }
        }
        // liste des BL avec propiété "selected"
        this.listeBL = listeBL;
        this.siteBLs = siteBLs;
        this.stopLoading();
    }
}
