import DapEnteteController from './dap.entete.controller';

export default {
    bindings: {
        dap: '=',
        isOpen: '=',
        onUpdate: '&'
    },
    template: require('./dap.entete.html'),
    controller: DapEnteteController
};
