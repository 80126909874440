import { IAugmentedJQuery, IScope } from 'angular';
import { Directive, OnDestroy, OnInit, Output } from '../../../core/decorators';

@Directive({ selector: '[ngUploadChange]' })
export class MassiaUploadChange implements OnInit, OnDestroy {
    @Output() ngUploadChange: CallableFunction;

    /* @ngInject */
    constructor(public $scope: IScope, public $element: IAugmentedJQuery) {}

    ngOnInit(): void {
        this.$element.on('change', (ev) => {
            this.$scope.$apply(() => {
                this.ngUploadChange({ $event: ev });
            });
        });
    }

    ngOnDestroy(): void {
        this.$element.off();
    }
}
