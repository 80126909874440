MesuresOrdreController.$inject = ['MesuresService', 'notification', '_'];

export default function MesuresOrdreController(MesuresService, notification, _) {
    /* jshint validthis: true */
    const vm = this;

    // Propriétés
    vm.loading = true;
    vm.displayMesures = [];
    vm.dragOptions = {};
    vm._ = _;

    // Méthodes

    // Evènements
    vm.$onInit = onInit;
    vm.selectTamis = selectTamis;
    vm.onDeleteAllTamis = onDeleteAllTamis;
    vm.onDeleteTamis = onDeleteTamis;
    vm.sortBy = sortBy;
    // Méthodes publiques
    function onInit() {
        vm.loading = false;
        vm.mesures = vm.mesures || [];
        vm.onDelete = vm.onDelete || angular.noop;
        vm.onUpdate = vm.onUpdate || angular.noop;
        vm.onDeleteAll = vm.onDeleteAll || angular.noop;

        vm.dragOptions = {
            placeholder: 'table-row-ui-placeholder',
            axis: 'y',
            animation: 200,
            'ui-floating': true,
            'ui-preserve-size': true,
            stop: vm.onUpdateOrdre({ mesures: vm.mesures })
        };
    }

    function selectTamis(idMesure, tamis) {
        const idxEssai = this._.findIndex(vm.mesures, { essaiId: idMesure });
        if (idxEssai > -1) {
            if (!vm.mesures[idxEssai].tamis) {
                vm.mesures[idxEssai].tamis = [];
            }
            const idxTamis = this._.findIndex(vm.mesures[idxEssai].tamis, { ouverture: tamis.ouverture });
            if (idxTamis == -1) {
                const newTamis = { id: null, essaiId: idMesure, ouverture: tamis.ouverture };
                vm.mesures[idxEssai].tamis.push(newTamis);
            }
        }
    }

    function onDeleteAllTamis(mesure) {
        const idxEssai = this._.findIndex(vm.mesures, { essaiId: mesure.essaiId });
        if (idxEssai > -1) {
            vm.mesures[idxEssai].tamis.splice(0, vm.mesures[idxEssai].tamis.length);
        }
    }

    function onDeleteTamis(mesure, tamis) {
        const idxEssai = this._.findIndex(vm.mesures, { essaiId: mesure.essaiId });
        if (idxEssai > -1) {
            const idxTamis = this._.findIndex(vm.mesures[idxEssai].tamis, { ouverture: tamis.ouverture });
            if (idxTamis > -1) {
                vm.mesures[idxEssai].tamis.splice(idxTamis, 1);
            }
        }
    }

    function sortBy(list) {
        vm.displayMesures = list.sort((a, b) => a.position - b.position);
    }
}
