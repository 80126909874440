import EquipagesConfig from './_config/equipages.config';
import EquipagesRoutes from './_config/equipages.routes';

import EquipagesComponent from './components/equipages';
//import DemandeLivraisonFormComponent from './components/demande-livraison-form';

import EquipagesService from './services/equipages.service';
//import DemandeLivraisonValidator  from './components/demande-livraison-form/demande.livraison.validator';

const moduleName = 'app.massia.gestion.equipages';

angular
    .module(moduleName, [])
    .config(EquipagesConfig)
    .config(EquipagesRoutes)
    .service('EquipagesService', EquipagesService)
    .component('equipages', EquipagesComponent);
// .component('demandeLivraisonForm', DemandeLivraisonFormComponent)
// .factory('DemandeLivraisonValidator', DemandeLivraisonValidator)

export default moduleName;
