import NiveauxHierarchiquesTrameController from './niveaux.hierarchiques.trame.controller';

export default {
    template: require('./niveaux.hierarchiques.trame.tpl.html'),
    controller: NiveauxHierarchiquesTrameController,
    bindings: {
        onTrameChange: '&',
        treeviewConfig: '<',
        treeviewService: '<'
    }
};
