import CampagneEnteteController from './campagne.entete.controller';

export default {
    bindings: {
        campagne: '=',
        //form: '=',
        onUpdate: '&'
    },
    template: require('./campagne.entete.html'),
    controller: CampagneEnteteController
};
