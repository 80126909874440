import AccessPortail from '../../../models/AccessPortail';
import GroupDomain from '../../../models/GroupDomain';

export default class GroupPortailAddController {
    constructor($state, GroupPortailService, notification, DomainPortailService, $translate, RouterHistoryService) {
        this.$state = $state;
        this.GroupPortailService = GroupPortailService;
        this.notification = notification;
        this.DomainPortailService = DomainPortailService;
        this.$translate = $translate;
        this.RouterHistoryService = RouterHistoryService;
    }

    async $onInit() {
        this.domain = await this.getDomain();
        await this.initGroup();
    }

    async initGroup() {
        if (this.$state.params.id) {
            const res = await this.GroupPortailService.getOne(this.$state.params.id);
            this.group = new GroupDomain(res.data);
            for (let i = 0; i < this.domain.length; i++) {
                var d = this.domain[i];
                const access = this.group.access.find((x) => x.codeDomain === d.code);
                if (access) {
                    d.access.id = access.id;
                    d.access.read = access.read;
                    // d.access.create = access.create;
                    // d.access.update = access.update;
                    // d.access.delete = access.delete;
                    d.access.print = access.print;
                    d.access.build = access.build;
                }
            }
        } else {
            this.group = new GroupDomain({
                id: null,
                code: '',
                libelle: ''
            });
        }
    }

    async getDomain() {
        try {
            return (await this.DomainPortailService.getAll()).data.items;
        } catch (err) {
            this.notification.error(err.message);
        }
    }

    initAccessLevel(domain) {
        domain.access = new AccessPortail({
            id: null,
            idDomain: domain.id,
            accessLevel: 0
        });
    }

    async save(exit = false) {
        this.group.access = this.domain.map((x) => x.access);
        try {
            if (!this.$state.params.id) {
                this.RouterHistoryService.ignoreNextRoute();
                const res = await this.GroupPortailService.create(this.group);
                this.notification.success('PORTAIL.GROUP.CREATED');
                return this.$state.go('portail.group.edit', { id: res.data });
            }
            await this.GroupPortailService.update(this.$state.params.id, this.group);
            this.notification.success('PORTAIL.GROUP.UPDATED');

            if (exit) {
                return this.exit();
            }
        } catch (err) {
            console.log(err);
            this.notification.error(this.$translate.instant(err.data.message));
        }
    }

    exit() {
        if (!this.RouterHistoryService.back()) {
            this.$state.go('portail.group.list');
        }
    }

    $onDestroy() {}
}

GroupPortailAddController.$inject = ['$state', 'GroupPortailService', 'notification', 'DomainPortailService', '$translate', 'RouterHistoryService'];
