export default class ConfigurationBalanceService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
        this.serviceUrl = `${__configuration.apiUrl}/massia/laboratoire/configuration-balance`;
    }

    async getAll(filters, sorts, pagination) {
        const res = await this.$http.get(this.serviceUrl, {
            params: {
                filters: JSON.stringify(filters || null),
                sorts: JSON.stringify(sorts || null),
                pagination: JSON.stringify(pagination || null)
            }
        });
        return res.data;
    }

    async getOneById(id) {
        const res = await this.$http.get(this.serviceUrl + '/' + id);

        return res.data;
    }

    async createConfig(data) {
        try {
            const res = await this.$http.post(this.serviceUrl, data);
            return res.data;
        } catch (e) {
            throw e.data;
        }
    }

    async updateConfig(data) {
        try {
            const res = await this.$http.put(this.serviceUrl + '/' + data.id, data);
            return res.data;
        } catch (e) {
            throw e.data;
        }
    }

    async deleteConfig(item) {
        return await this.$http.delete(this.serviceUrl + '/' + item.id);
    }

    async getEnumParite() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/parite-type-bascules`);
        return res.data;
    }
}
