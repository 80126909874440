import L from 'leaflet/dist/leaflet';
import leafletEasyPrint from 'leaflet-easyprint';

export default class CampagneFormCarteController {
    static $inject = [
        '$stateParams',
        'CampagnesService',
        'PrelevementsCommunicationService',
        'PrelevementsService',
        'notification',
        'ModalService',
        '$uibModal',
        '$translate'
    ];

    constructor(
        $stateParams,
        CampagnesService,
        PrelevementsCommunicationService,
        PrelevementsService,
        notification,
        ModalService,
        $uibModal,
        $translate
    ) {
        this.$stateParams = $stateParams;
        this.CampagnesService = CampagnesService;
        this.PrelevementsCommunicationService = PrelevementsCommunicationService;
        this.PrelevementsService = PrelevementsService;
        this.notification = notification;
        this.ModalService = ModalService;
        this.$uibModal = $uibModal;
        this.$translate = $translate;
    }

    async $onInit() {
        await this.initCarte();
    }

    async initCarte() {
        let zoom = 5;
        let longitude = 3.5;
        let latitude = 45.5;

        const site = await this.PrelevementsService.getSite(this.entete.siteProducteurId);
        if (site.longitude && site.latitude) {
            longitude = site.longitude;
            latitude = site.latitude;
            zoom = 18;
        }

        this.map = L.map('map').setView([latitude, longitude], zoom).setMaxZoom(20); //par défaut

        this.map.scrollWheelZoom.disable();

        //this.map.setMaxZoom(20);
        //Couches
        /*var Mapnik = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {//z:niveau de zoom, x et y coordonnées du pavé
            attribution: '&copy; Les contributeurs <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            maxZoom: 20//zoom maximum de la carte
        });
        Mapnik.addTo(this.map);*/

        const OSMFr = L.tileLayer('https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
            //z:niveau de zoom, x et y coordonnées du pavé
            attribution: '&copy; Les contributeurs <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            maxZoom: 20 //zoom maximum de la carte
            //zoomSpan: 0.25
        });
        OSMFr.addTo(this.map);

        //ajout du plan topo
        this.planTopo(site.id);

        const datageojson = await this.CampagnesService.getPointsMesureGeoJSonByCampagne(this.listeSaisies, this.entete.siteProducteurId);

        const blueMarker = L.icon({
            iconUrl: 'assets/img/pin_GB.png',
            iconSize: [32, 32], // size of the icon
            iconAnchor: [4, 27] // point of the icon which will correspond to marker's location
        });

        L.geoJSON(datageojson, {
            pointToLayer: function (point, latlng) {
                const marqueur = L.marker(latlng, { icon: blueMarker });
                return marqueur;
            }
        })
            .bindPopup(function (layer) {
                const html =
                    '<b>Code : </b>' +
                    layer.feature.properties.code +
                    '<br><b>Libelle : </b>' +
                    layer.feature.properties.libelle +
                    '<br><b>Thème : </b>' +
                    layer.feature.properties.theme +
                    '<br><b>Type : </b>' +
                    layer.feature.properties.type;
                return html;
            })
            .addTo(this.map);

        //impression
        L.easyPrint({
            title: this.$translate.instant('EXPORTER'),
            position: 'topleft',
            sizeModes: ['Current', 'A4Portrait', 'A4Landscape'],
            exportOnly: true,
            filename: this.entete.libelle,
            tileLayer: OSMFr,
            hideControlContainer: true
        }).addTo(this.map);
    }

    async planTopo(idSite) {
        if (idSite) {
            const planTopoSite = await this.PrelevementsService.getPlanTopo(idSite);
            if (planTopoSite) {
                const options = {
                    idSite: idSite,
                    annee: planTopoSite.annee,
                    minZoom: 15,
                    maxZoom: 20,
                    maxNativeZoom: 20,
                    opacity: 1.0,
                    attribution: 'Rendered with <a href="http://www.maptiler.com/">MapTiler</a>',
                    tms: false
                };
                const layer = L.tileLayer('assets/topo/{idSite}/{annee}/{z}/{x}/{y}.png', options);
                layer.addTo(this.map);
            }
        }
    }

    async ok() {
        this.modalInstance.close();
    }
}
