(function (angular, undefined) {
    'use strict';

    angular.module('blocks.i18n.globalization')
        .factory('globalizationInterceptorService', globalizationInterceptorService);

    globalizationInterceptorService.$inject = ['$translate'];


    function globalizationInterceptorService($translate) {
        var factory = {};

        var _request = function (config) {
            config.headers = config.headers || {};

            var langKey = $translate.proposedLanguage() || $translate.use();
            if (langKey) {
                config.headers['Accept-Language'] = langKey;
            }

            return config;
        };


        factory.request = _request;
        return factory;
    }
})(angular);
