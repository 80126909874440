(function () {
    'use strict';

    angular.module('blocks.multiselect').service('MultiselectHelpers', MultiselectHelpers);
    MultiselectHelpers.$inject = ['_', '$translate'];

    function MultiselectHelpers(_, $translate) {
        var factory = {
            getTranslation: getTranslation,
            getSelectTreeViewArray: getSelectTreeViewArray,
        };

        return factory;

        function getTranslation(element) {
            const width = $(element).width();
            var lang = $translate.use();
            var translation;
            switch (lang) {
                case 'en-US':
                case 'en-GB':
                case 'en':                    
                    translation = {
                        selectAll: '<span class="glyphicon glyphicon-check"></span>',
                        selectNone: '<span class="glyphicon glyphicon-unchecked"></span>',
                        reset: `<span class="massia-icon-undo"></span> ${
                            width < 350 ? '' : '<span class="hidden-md hidden-sm hidden-xs">Reset</span>'
                        }`,
                        close: `<span class="glyphicon glyphicon-ok"></span> ${
                            width < 350 ? '' : '<span class="hidden-md hidden-sm hidden-xs">Close</span>'
                        }`,
                        search: 'Search',
                        nothingSelected: 'Nothing selected',
                        selection: 'Multiple selection',
                    };
                    break;
                case 'es':
                case 'es-ES':
                    translation = {
                        selectAll: '<span class="glyphicon glyphicon-check"></span>',
                        selectNone: '<span class="glyphicon glyphicon-unchecked"></span>',
                        reset: `<span class="massia-icon-undo"></span> ${
                            width < 350 ? '' : '<span class="hidden-md hidden-sm hidden-xs">Reiniciar</span>'
                        }`,
                        close: `<span class="glyphicon glyphicon-ok"></span> ${
                            width < 350 ? '' : '<span class="hidden-md hidden-sm hidden-xs">Cerrar</span>'
                        }`,
                        search: 'Buscar',
                        nothingSelected: 'Ninguna selección',
                        selection: 'Selección multiple',
                    };
                    break;
                default:
                    translation = {
                        selectAll: '<span class="glyphicon glyphicon-check"></span>',
                        selectNone: '<span class="glyphicon glyphicon-unchecked"></span>',
                        reset: `<span class="massia-icon-undo"></span> ${
                            width < 350 ? '' : '<span class="hidden-md hidden-sm hidden-xs">Réinitialiser</span>'
                        }`,
                        close: `<span class="glyphicon glyphicon-ok"></span> ${
                            width < 350 ? '' : '<span class="hidden-md hidden-sm hidden-xs">Fermer</span>'
                        }`,
                        search: 'Recherche',
                        nothingSelected: 'Aucune sélection',
                        selection: 'Sélection multiple',
                    };
                    break;
            }
            return translation;
        }

        /**
         * input = [{ id, label, fullLabel, children: [{...}] }, {...}];
         *
         * @param input
         * @param output
         * @param model
         * @returns {*}
         */
        function getSelectTreeViewArray(input, output, model) {
            _.forEach(_.sortBy(input, 'label'), function (rankItem) {
                if (rankItem.children.length > 0) {
                    output.push({ label: rankItem.label, isGroup: true });

                    getSelectTreeViewArray(_.sortBy(rankItem.children, 'label'), output, model);

                    output.push({ isGroup: false });
                } else {
                    var isSelected = false;
                    if (model === rankItem.id) {
                        isSelected = true;
                    }
                    output.push({ id: rankItem.id, label: rankItem.label, fullLabel: rankItem.fullLabel, selected: isSelected });
                }
            });

            return output;
        }
    }
})();
