export default class ModalImportGranuloController {
    /* @ngInject */
    constructor(PrelevementsService, $translate, notification) {
        this.PrelevementsService = PrelevementsService;
        this.$translate = $translate;
        this.notification = notification;
    }

    $onInit() {
        this.fileInfoOpen = false;
        console.log(this);
        this.results = [];
        this.importEmpty = false;
        this.form = {
            data: {},
            file: {}
        };
    }

    dismiss() {
        this.modalInstance.dismiss();
    }
    openFileUploader() {
        document.getElementById('file-upload').click();
    }
    change(event) {
        this.fileInfoOpen = true;

        const fileInfo = event.target.files[0];
        this.form.file = fileInfo;
        const array = fileInfo.name.split('.');
        this.form.data.libelle = array[0];
        this.form.data.extension = '.txt';
        this.form.data.size = fileInfo.size;
        this.form.data.sizeCoverted = fileInfo.size / 100 + 'ko';
        this.form.data.dateCreation = fileInfo.lastModified;

        this.importFile();
        this.fileInfoOpen = false;
    }
    async importFile() {
        this.loading = true;
        this.PrelevementsService.uploadFileImportGranulo(this.form)
            .then((res) => {
                if (res && res.length > 0) {
                    this.results = res;
                } else {
                    this.importEmpty = true;
                    const msg = this.$translate.instant('PRELEVEMENTS.IMPORT_GRANULO.EMPTY', { file: this.form.data.libelle });
                    this.notification.info(msg);
                }
            })
            .catch((err) => {
                this.notification.error(err.data);
            })
            .then(() => {
                this.loading = false;
            });
    }

    save() {
        const ouvertureTamis = this.results.map((x) => x.tamis);
        const gran = Object.values(this.resolve?.mesure?.sousEssais).find((x) => x.idNature === 8);
        const idxArray = Object.values(this.resolve?.mesure?.sousEssais).findIndex((x) => x.idNature === 8);
        const idx = Object.keys(this.resolve?.mesure?.sousEssais)[idxArray];
        if (gran && gran.granuloAdditionalInformation) {
            const tamisToSet = gran.granuloAdditionalInformation.tamis.filter((x) => ouvertureTamis.includes(x.ouverture));
            for (let i = 0; i < tamisToSet.length; i++) {
                const val = this.results.find((x) => x.tamis == tamisToSet[i].ouverture)?.value;
                const idxTamis = this.resolve.mesure.sousEssais[idx].granuloAdditionalInformation.tamis.findIndex(
                    (x) => x.ouverture == tamisToSet[i].ouverture
                );
                this.resolve.mesure.sousEssais[idx].granuloAdditionalInformation.tamis[idxTamis].pourcPassant = val;
            }

            this.modalInstance.close({ essai: this.resolve.mesure, idx: idx });
        }
    }
}
