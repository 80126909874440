import VisualisationController from './visualisation.controller';

export default {
    bindings: {
        modalInstance: '=',
        resolve: '<'
    },
    template: require('./visualisation.html'),
    controller: VisualisationController
};
