import { NgModule } from '../../../core/decorators';
import { FilterBoolComponent } from './components/filter-bool/filter-bool.component';
import { FilterDateComponent } from './components/filter-date/filter-date.component';
import { FilterNumericComponent } from './components/filter-numeric/filter-numeric.component';
import { FilterTableComponent } from './components/filter-table/filter-table.component';
import { FilterTextComponent } from './components/filter-text/filter-text.component';
import { MassiaFilterComponent } from './massia-filter.component';

@NgModule({
    id: 'libs.massia.filter',
    declarations: [FilterBoolComponent, FilterDateComponent, FilterNumericComponent, FilterTableComponent, FilterTextComponent, MassiaFilterComponent]
})
export class MassiaFilterModule {}
