MesuresController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    '$translate',
    'ModalService',
    'PaginationService',
    'MesuresService',
    'MesuresTypesService',
    'notification',
    'Upload',
    '$transitions',
    'MOPService'
];

export default function MesuresController(
    $scope,
    $state,
    $stateParams,
    $translate,
    ModalService,
    PaginationService,
    MesuresService,
    MesuresTypesService,
    notification,
    Upload,
    $transitions,
    MOPService
) {
    const vm = this;
    const watchers = [];

    let previousTableState;
    vm.selectedMesureId = undefined;
    vm.lastSelected = undefined;

    vm.loading = false;
    vm.params = $stateParams;
    vm.state = $state;
    vm.mesures = [];

    vm.slideWidth = '500px';
    vm.slideMargin = {
        'margin-right': '0px',
        'transition-duration': '0.5s',
        'transition-animation': 'margin-right',
        height: '100%'
    };

    vm.itemsByPageOptions = [20, 100, 200];
    vm.itemsByPage = vm.itemsByPageOptions[0];
    vm.getTypes = getTypes;
    vm.getTypesProduits = getTypesProduits;
    vm.selectMesure = selectMesure;
    vm.isMesureSelected = isMesureSelected;
    vm.showDetail = showDetail;
    vm.closeDetail = closeDetail;
    vm.loadMesures = loadMesures;
    vm.deleteMesure = deleteMesure;
    vm.setSelected = setSelected;
    vm.eraseAllFilters = eraseAllFilters;
    vm.getDomaineCourant = getDomaineCourant;
    vm.duplicateMesure = duplicateMesure;
    vm.exporter = exporter;
    vm.getListeProduitsTypes = getListeProduitsTypes;
    vm.MOPService = MOPService;

    function init() {
        vm.MOPService.setMop([{ title: 'MESURES.BREADCRUMBS.ESSAIS_LIST', filename: 'Essais.pdf', application: 'laboratoire' }]);
        $transitions.onSuccess({}, stateChangeSuccessFunc);
        //$transitions.onSuccess({}, stateChangeSuccessFunc);
        watchers.push($scope.$on('$destroy', dispose));

        if (shouldSetSelectedId()) {
            vm.setSelected({ id: parseInt($state.params.id) });
        }

        getTypes();
        getTypesProduits();
    }

    init();

    vm.$onDestroy = () => {
        vm.MOPService.resetMop();
    };

    function getDomaineCourant() {
        return $state.current.name.substring(0, $state.current.name.indexOf('.'));
    }

    function isActuListe() {
        return $state.current.name.indexOf('list') >= 0;
    }

    function stateChangeSuccessFunc(event, toState) {
        if (!$state.params.id) {
            vm.setSelected();
        } else {
            vm.setSelected({ id: parseInt($state.params.id) });
        }
    }

    async function getTypes(event, toState) {
        vm.types = await MesuresTypesService.getAll(getDomaineCourant());
    }
    async function getTypesProduits() {
        vm.produitsTypes = await MesuresTypesService.getAll('produits');
    }
    function setSelected(mesure) {
        if (mesure && mesure.id) {
            vm.selectedMesureId = $stateParams.id;
            vm.lastSelected = vm.selectedMesureId;
            vm.slideMargin['margin-right'] = vm.slideWidth;
        } else {
            vm.selectedMesureId = undefined;
            vm.slideMargin['margin-right'] = '0px';
        }
    }

    function shouldSetSelectedId() {
        return (
            $state &&
            $state.current &&
            $state.current.name &&
            !isActuListe() && //($state.current.name === 'mesures.list.detail' || $state.current.name === 'mesctrl.list.detail')
            $stateParams &&
            $stateParams.id
        );
    }

    function selectMesure(mesure) {
        if (mesure && mesure.id) {
            if (vm.selectedMesureId !== mesure.id) {
                vm.selectedMesureId = mesure.id;
                showDetail(mesure.id);
            } else {
                vm.selectedMesureId = undefined;
                closeDetail();
            }
        }
    }

    function isMesureSelected(mesure) {
        return mesure && mesure.id && vm.selectedMesureId === mesure.id;
    }

    function showDetail(mesureId) {
        $state.go(getDomaineCourant() + '.list.detail', { id: mesureId });
    }

    function closeDetail() {
        $state.go(getDomaineCourant() + '.list');
    }

    async function loadMesures(tableState) {
        try {
            startLoading();

            if ($state && $state.current && !isActuListe() /*$state.current.name !== 'mesures.list'*/) {
                $state.go(getDomaineCourant() + '.list');
            }

            if (tableState) {
                previousTableState = tableState;
            }

            const filters = PaginationService.getFilters(previousTableState);
            //console.log('🚀 ~ file: mesures.controller.js ~ line 161 ~ loadMesures ~ filters', filters);
            const sorts = PaginationService.getSorts(previousTableState);
            const pagination = PaginationService.getPagination(previousTableState);

            vm.mesures = [];

            const data = await MesuresService.getMesures(filters, sorts, pagination, getDomaineCourant());

            vm.mesures = data.items;

            if (previousTableState) {
                PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
            }
        } catch (ex) {
            notification.error(ex.data);
        } finally {
            stopLoading();
        }
    }

    async function deleteMesure(mesure) {
        if (mesure /*&& mesure.canDelete*/ && mesure.id) {
            let tradTitre = 'MESURES.DELETE_MESURE.TITLE';
            let tradMsg = 'MESURES.DELETE_MESURE.MESSAGE';
            let tradSucces = 'MESURES.DELETE_MESURE.SUCCESS';
            if (getDomaineCourant() == 'mesctrl') {
                tradTitre = 'MESURES.DELETE_CONTROLE.TITLE';
                tradMsg = 'MESURES.DELETE_CONTROLE.MESSAGE';
                tradSucces = 'MESURES.DELETE_CONTROLE.SUCCESS';
            } else if (getDomaineCourant().startsWith('essais')) {
                tradTitre = 'MESURES.DELETE_ESSAI.TITLE';
                tradMsg = 'MESURES.DELETE_ESSAI.MESSAGE';
                tradSucces = 'MESURES.DELETE_ESSAI.SUCCESS';
            }
            const modalInstance = ModalService.confirm({
                modalTitle: $translate.instant(tradTitre, { code: mesure.libelle }),
                modalMsg: $translate.instant(tradMsg),
                headerClass: 'modal-danger'
            });

            modalInstance.result.then(async function () {
                startLoading();
                try {
                    await MesuresService.deleteMesureById(mesure.id);
                    notification.success($translate.instant(tradSucces));

                    vm.selectedMesureId = undefined;

                    if (vm.params.id) {
                        vm.state.go(getDomaineCourant() + '.list');
                    }

                    previousTableState = PaginationService.getTableStateAfterDelete(previousTableState, previousTableState.pagination.take);

                    loadMesures();
                } catch (ex) {
                    notification.error(ex.data);
                } finally {
                    stopLoading();
                }
            });
        }
    }

    function duplicateMesure(mesure) {
        if (mesure && mesure.id) {
            $state.go(getDomaineCourant() + '.duplicate', { id: mesure.id });
        }
    }

    function eraseAllFilters() {
        loadMesures();
    }

    async function exporter(modeImpression) {
        try {
            const filters = PaginationService.getFilters(previousTableState);
            const sorts = PaginationService.getSorts(previousTableState);
            const fileName = 'Liste_mesures';
            let resultat = undefined;
            try {
                resultat = await MesuresService.exportListeMesures(getDomaineCourant(), modeImpression, filters, sorts);
            } catch (ex) {
                //si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
                const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
                notification.error('MESURES.' + msgException);
                return false;
            }
            if (resultat) {
                const data = resultat.data;
                const status = resultat.status;
                let headers = resultat.headers;
                headers = headers();

                const contentType = headers['content-type'];

                const linkElement = document.createElement('a');
                try {
                    const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
                    const url = window.URL.createObjectURL(blob);
                    linkElement.setAttribute('href', url);
                    if (modeImpression == 0) {
                        linkElement.setAttribute('download', fileName + '.pdf');
                    } else {
                        linkElement.setAttribute('download', fileName + '.xlsx');
                    }

                    const clickEvent = new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: false
                    });
                    linkElement.dispatchEvent(clickEvent);
                } catch (ex) {
                } finally {
                }
                return true;
            }
        } catch (ex) {
            this.notification.error(ex.data);
            return false;
        }
    }

    function startLoading() {
        vm.loading = true;
    }

    function stopLoading() {
        vm.loading = false;
    }

    function dispose() {
        watchers.forEach((x) => x());
    }

    function getListeProduitsTypes(types, i) {
        //console.log('🚀 ~ file: mesures.controller.js ~ line 295 ~ getListeProduitsTypes ~ types', types);
        vm.mesures[i].produitTypeTooltip = types.map((x) => '<span>' + x.libelle + '</span>').join('</br>');
        /*console.log(
            '🚀 ~ file: mesures.controller.js ~ line 297 ~ getListeProduitsTypes ~ vm.mesures[i].produitTypeTooltip',
            vm.mesures[i].produitTypeTooltip
        );*/
        return types.map((x) => x.libelle).join(', ');
    }
}
