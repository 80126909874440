// on importe le controller car les components angularJS
// permettent l'ajout direct d'une fonction dans la propriété "controller"
import EntityCaracteristiquesController from './entity.caracteristiques.controller';

export default {
    bindings: {
        // on bind avec '=' (double binding)
        // quand on veut pouvoir modifier l'objet des deux cotés (appelant et component)
        model: '=', //SP 07/12/19 ancien =
        entity: '@',
        // on bind avec '<' (binding one way)
        // quand on ne veut pas pouvoir modifier l'objet dans le component (seulement dans l'appelant)
        idType: '<',
        onUpdate: '&', //SP 07/12/19 commenté
        lecture: '<',
        populate: '=',
        forceRefresh: '='
    },
    template: require('./entity.caracteristiques.html'),
    controller: EntityCaracteristiquesController
    // si on ne déclare pas de 'controllerAs', par défaut ce sera '$ctrl'
};
