/* @ngInject */
export default class ModalBlocSelection {
    constructor($scope, BlocSelectionService, $translate) {
        this.$scope = $scope;
        this.BlocSelectionService = BlocSelectionService;
        this.$translate = $translate;
    }
    $onInit() {
        this.selectBloc = false;
        this.selectedBloc = [];
        this.$scope.$watch(
            () => this.resolve.idTypeSynthese,
            () => {
                if (this.resolve.idTypeSynthese) {
                    this.loadBlocSelection();
                }
            }
        );
        //this.loadBlocSelection();
    }
    $onDestroy() {}

    async loadBlocSelection() {
        try {
            this.selectBloc = true;
            let req = await this.BlocSelectionService.getAll(this.resolve.idTypeSynthese);
            if (this.resolve.blocSelection) {
                req = req.filter((x) => !this.resolve.blocSelection.some((b) => b.id === x.id));
            }
            this.bloc = req;
        } catch (err) {
            console.error(err);
        }
    }

    async addBloc() {
        try {
            const idBloc = this.selectedBloc[0].id;
            const res = await this.BlocSelectionService.getOne(idBloc, this.resolve.idSynthese);
            this.close(res);
        } catch (err) {
            console.error(err);
        }
    }

    addNewBloc() {
        const count = this.resolve.blocSelection ? this.resolve.blocSelection.length + 1 : 1;
        this.close({
            code: '',
            libelle: this.$translate.instant('SYNTHENTS.NAV.BLOC_SEL') + ` ${count}`,
            modeleSelection: null,
            blocSelectionEssais: []
        });
    }
}
