export default function Configure($stateProvider) {
    $stateProvider.state('types', {
        parent: 'common',
        url: '/types',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('types.list', {
        url: '/list',
        template: '<types></types>',
        rights: { domain: 'types', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "TYPES.BREADCRUMBS.TYPES_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'TYPES.BREADCRUMBS.TYPES_LIST',
            order: 5
        },
        search: 'TYPES.BREADCRUMBS.TYPES_LIST'
    });

    $stateProvider.state('types.clientlist', {
        url: '/client/list',
        template: '<types></types>',
        rights: { domain: 'types', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "TYPES.BREADCRUMBS.TYPES_CLIENT_LIST" | translate}}'
        },
        search: 'TYPES.BREADCRUMBS.TYPES_LIST'
    });

    $stateProvider.state('types.produitlist', {
        url: '/produit/list',
        template: '<types></types>',
        rights: { domain: 'types', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "TYPES.BREADCRUMBS.TYPES_PRODUIT_LIST" | translate}}'
        },
        search: 'TYPES.BREADCRUMBS.TYPES_LIST'
    });

    //dfs
    $stateProvider.state('types.list.detail', {
        url: '/{id}',
        template: '<type-detail></type-detail>',
        rights: { domain: 'types', right: 'read' },
        ncyBreadcrumb: {
            parent: 'types.list',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('types.new', {
        url: '/new',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        ncyBreadcrumb: {
            parent: 'types.list',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        },
        search: 'TYPES.BREADCRUMBS.TYPE_NEW'
    });

    $stateProvider.state('types.edit', {
        url: '/{id}/edit',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'update' },
        ncyBreadcrumb: {
            parent: 'types.list',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_EDIT" | translate}}'
        }
    });

    $stateProvider.state('types.duplicate', {
        url: '/{id}/duplicate',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        ncyBreadcrumb: {
            parent: 'types.list',
            label: '{{ "TYPES.BREADCRUMBS.TYPE_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });

    $stateProvider.state('types.editCaracteristiqueFromEdit', {
        url: '/{parentId}/caracteristiques/{id}/edit',
        template: '<caracteristique-form></caracteristique-form>',
        rights: { domain: 'caracts', right: 'update' },
        params: { parentState: 'types.edit', parentId: '{parentId}' },
        ncyBreadcrumb: {
            parent: 'types.edit',
            label: '{{ "CARACTERISTIQUES.BREADCRUMBS.CARACTERISTIQUE_EDIT" | translate}}'
        }
    });

    $stateProvider.state('types.editCaracteristiqueFromNew', {
        url: '/new/caracteristiques/{id}/edit',
        template: '<caracteristique-form></caracteristique-form>',
        rights: { domain: 'caracts', right: 'update' },
        params: { parentState: 'types.new' },
        ncyBreadcrumb: {
            parent: 'types.new',
            label: '{{ "CARACTERISTIQUES.BREADCRUMBS.CARACTERISTIQUE_EDIT" | translate}}'
        }
    });

    $stateProvider.state('secteurs', {
        parent: 'common',
        url: '/secteurs',
        //abstract: true,
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('secteurs.list', {
        url: '/list',
        template: '<types></types>',
        rights: { domain: 'types', right: 'read', application: 'performance' },
        ncyBreadcrumb: {
            label: '{{ "TYPES.BREADCRUMBS.SECTEURS_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'TYPES.BREADCRUMBS.SECTEURS_LIST',
            order: 4
        },
        search: 'TYPES.BREADCRUMBS.SECTEURS_LIST'
    });

    //dfs
    $stateProvider.state('secteurs.list.detail', {
        url: '/{id}',
        template: '<type-detail></type-detail>',
        rights: { domain: 'types', right: 'read' },
        ncyBreadcrumb: {
            parent: 'secteurs.list',
            label: '{{ "TYPES.BREADCRUMBS.SECTEUR_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('secteurs.new', {
        url: '/new',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        ncyBreadcrumb: {
            parent: 'secteurs.list',
            label: '{{ "TYPES.BREADCRUMBS.SECTEUR_NEW" | translate}}'
        },
        search: 'TYPES.BREADCRUMBS.SECTEUR_NEW'
    });

    $stateProvider.state('secteurs.edit', {
        url: '/{id}/edit',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'update' },
        ncyBreadcrumb: {
            parent: 'secteurs.list',
            label: '{{ "TYPES.BREADCRUMBS.SECTEUR_EDIT" | translate}}'
        }
    });

    $stateProvider.state('secteurs.duplicate', {
        url: '/{id}/duplicate',
        template: '<type-form></type-form>',
        rights: { domain: 'types', right: 'create' },
        ncyBreadcrumb: {
            parent: 'secteurs.list',
            label: '{{ "TYPES.BREADCRUMBS.SECTEUR_NEW" | translate}}'
        },
        params: {
            duplicate: true
        }
    });

    $stateProvider.state('secteurs.editCaracteristiqueFromEdit', {
        url: '/{parentId}/caracteristiques/{id}/edit',
        template: '<caracteristique-form></caracteristique-form>',
        rights: { domain: 'caracts', right: 'update' },
        params: { parentState: 'types.edit', parentId: '{parentId}' },
        ncyBreadcrumb: {
            parent: 'secteurs.edit',
            label: '{{ "CARACTERISTIQUES.BREADCRUMBS.CARACTERISTIQUE_EDIT" | translate}}'
        }
    });

    $stateProvider.state('secteurs.editCaracteristiqueFromNew', {
        url: '/new/caracteristiques/{id}/edit',
        template: '<caracteristique-form></caracteristique-form>',
        rights: { domain: 'caracts', right: 'update' },
        params: { parentState: 'secteurs.new' },
        ncyBreadcrumb: {
            parent: 'secteurs.new',
            label: '{{ "CARACTERISTIQUES.BREADCRUMBS.CARACTERISTIQUE_EDIT" | translate}}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
