(function (angular, globalHelpers, undefined) {
    'use strict';
    angular.module('blocks.validation').directive('acValidation', acValidation);
    acValidation.$inject = ['ValidationService', '$compile', 'validationHelper', '$document'];

    function acValidation(ValidationService, $compile, validationHelper, $document) {
        return {
            restrict: 'A',
            require: 'ngModel',

            link: function (scope, element, attrs, ngModel) {
                var validationEventSuffix = 'Validations';
                var clearEventSuffix = 'ClearValidation';

                var unregisterPropertyWatch;
                var unregisterLinkedPropertiesWatch;
                var unregisterLinkedPropertyWatch;
                var unregisterValidationEvent;
                var unregisterValidationDirtyEvent;
                var unregisterPropertyValidationEvent;
                var unregisterPropertyClearEvent;
                var unregisterDestroyEvent;
                var unregisterPristineEvent;

                var decorator;
                var validationScope;
                var validator;
                var modelbinding;
                var model;
                var binding;
                var formGroup;
                var accordionHeader;

                var appendToBody = false;
                var popoverPlacement = "bottom";
                var forceValidationAlways = false;

                // On peut désactiver la validation pour de bon via l'attribut ac-validation-disabled="true"
                if (!scope.$eval(attrs.acValidationDisabled)) {
                    activate();
                }

                function activate() {
                    if (attrs.acValidationAppendToBody != undefined) {
                        appendToBody = attrs.acValidationAppendToBody;
                    }

                    if (attrs.acValidationPlacement != undefined) {
                        popoverPlacement = attrs.acValidationPlacement;
                    }

                    if (attrs.forceValidationAlways != undefined) {
                        forceValidationAlways = scope.$eval(attrs.forceValidationAlways);
                    }

                    if (attrs.modelName != undefined) {
                        modelbinding = attrs.modelName;
                    }
                    else {
                        modelbinding = attrs.ngModel;
                    }

                    binding = ValidationService.createBinding(modelbinding);

                    // si la propriété est définie, on la force
                    if (attrs.propertyName !== undefined && attrs.propertyName !== '' && attrs.propertyName !== null) {
                        binding.Property = attrs.propertyName;
                    }
                    if (binding != undefined) {
                        // récupération du validateur
                        validator = ValidationService.getValidator(binding.Model, binding);

                        if (validator != undefined) {
                            // ajout des validateurs communs
                            setCommonValidators(attrs.acValidation, ngModel);
                            // récupération des éléments HTML
                            formGroup = angular.element(globalHelpers.findAncestorByClassName(element[0], 'form-group'));
                            accordionHeader = $(element[0]).closest('.panel').children('.panel-heading');

                            // abonnements
                            unregisterPropertyWatch = scope.$watch(watchedProperty, watchCallback, true);

                            // propriété liée
                            // Attention il faut envoyer un tableau JSON valide, comme suit :
                            // ac-linked-properties='["elementsFormCtrl.element.code", "elementsFormCtrl.element.description"]'
                            if (attrs.acLinkedProperties) {
                                var acLinkedProperties = JSON.parse(attrs.acLinkedProperties);
                                unregisterLinkedPropertiesWatch = scope.$watchGroup(acLinkedProperties, watchCallback, true);
                            }

                            if (attrs.acLinkedProperty) {
                                scope.$watch(attrs.acLinkedProperty, validateLinkedProperty, true);
                            }

                            unregisterValidationEvent = scope.$on(binding.Model + validationEventSuffix, validateProperty);
                            unregisterValidationDirtyEvent = scope.$on(binding.Model + validationEventSuffix + 'Dirty', validateDirtyProperty);
                            unregisterPropertyValidationEvent = scope.$on(binding.Model + '.' + binding.Property + 'Property' + validationEventSuffix, validateProperty);
                            unregisterPropertyClearEvent = scope.$on(binding.Model + '.' + binding.Property + 'Property' + clearEventSuffix, clearValidationError);

                            unregisterPristineEvent = scope.$watch(function () {
                                return ngModel.$pristine;
                            }, setPristine, true);
                            unregisterDestroyEvent = scope.$on('$destroy', dispose);
                        }
                    }
                }

                function watchedProperty() {
                    return ngModel.$modelValue;
                }

                function watchCallback(newValue, oldValue) {
                    if (ngModel.$dirty || forceValidationAlways) {
                        validateProperty();
                    }
                }

                function validateDirtyProperty() {
                    if (ngModel.$dirty) {
                        validateProperty();
                    }
                }

                function validateLinkedProperty(linkedPropertyValue, oldLinkedPropertyValue) {
                    // on ne lance pas la validation si la propriété liée n'a pas changé
                    if (linkedPropertyValue !== oldLinkedPropertyValue) {
                        validateProperty();
                    }
                }

                function validateProperty() {
                    //si l'élément est désactivé, on ne lève pas d'erreur de validation
                    if (forceValidationAlways || !attrs.disabled && !scope.$eval(attrs.ngDisabled)) {
                        // s'il existe on récupère l'objet depuis la directive
                        if (attrs.getModelObject != undefined) {
                            model = scope.$eval(attrs.getModelObject);
                        }

                        if (model == undefined) {
                            if (scope[binding.Controller]) {
                                // sinon on récupère l'objet à valider depuis le scope
                                model = scope[binding.Controller][binding.Model];
                            }
                            else {
                                // si on n'a pas pu récupérer le controller dans le scope c'est qu'il est isolé
                                // du a une directive. On récupére donc le scope.$parent
                                model = scope.$parent[binding.Controller][binding.Model];
                            }
                        }

                        // effectue la validation
                        var errors = ValidationService.validate(validator, model, binding.Property);
                        clearValidationError();

                        if (errors && errors.length > 0) {
                            ngModel.$setValidity(binding.Property, false); // indique la non validité
                            displayValidationErrors(errors[0]);
                        }
                    }
                }

                function displayValidationErrors(error) {
                    var template = ValidationService.getValidationTemplate(attrs.template ? attrs.template : "default");

                    if (decorator === undefined) {

                        if(validationScope === undefined) {
                            validationScope = scope.$new(true);
                            validationScope.validation = {
                                error: error,
                                popoverPlacement: popoverPlacement,
                                appendToBody: appendToBody
                            };
                        }

                        decorator = $compile(angular.element(template))(validationScope);
                        if(accordionHeader !== undefined) {
                            accordionHeader.addClass('header-error');
                        }
                        if (formGroup !== undefined) {
                            formGroup.addClass('has-error');
                        }
                        else {
                            element.addClass('in-error');
                        }

                        // on récupère le premier ancêtre qui porte la classe de validation
                        var formValidation = angular.element(globalHelpers.findAncestorByClassName(element[0], 'display-validation'));
                        if (formValidation != undefined) {
                            formValidation.after(decorator);
                        }
                    }
                }

                function clearValidationError() {
                    ngModel.$setValidity(binding.Property, true);

                    if(validationScope !== undefined) {
                        validationScope.$destroy();
                        validationScope = undefined;
                    }
                    if (decorator != undefined) {
                        if (element.hasClass('in-error')) {
                            element.removeClass('in-error');
                        }
                        if (formGroup != undefined && formGroup.hasClass('has-error')) {
                            formGroup.removeClass('has-error');
                        }
                        if(accordionHeader != undefined && accordionHeader.hasClass('header-error')) {
                            accordionHeader.removeClass('header-error');
                        }
                        // Si le popover existe au moment de la destruction (sur le body ou en tant qu'enfant)
                        if (appendToBody && $document[0].body.querySelector('.popover')) {
                            $document[0].body.querySelector('.popover').remove();
                        }
                        else if (decorator.next().hasClass('popover')) {
                            decorator.next().remove();
                        }

                        decorator.remove();
                        // on détruit l'objet javascript pour ne pas se le traîner à la prochaine boucle
                        decorator = undefined;
                    }
                }

                function setPristine(value, oldValue) {
                    if (value && value != oldValue) {
                        clearValidationError();
                    }
                }

                // Ici sont gérés les cas classiques de la validation (date et numérique)
                function setCommonValidators(acValidation, ngModel) {
                    switch (acValidation) {
                        case 'integer' :
                            ngModel.$parsers.push(validationHelper.numericParser);
                            validator.ruleFor(binding.Property).must(validationHelper.isValidInteger).withMessage('VALIDATION_NOT_INTEGER');
                            break;
                        case 'negative-integer' :
                            ngModel.$parsers.push(validationHelper.numericParser);
                            validator.ruleFor(binding.Property).must(validationHelper.isValidNegativeInteger).withMessage('VALIDATION_NOT_INTEGER_NEGATIVE');
                            break;
                        case 'decimal' :
                            ngModel.$parsers.push(validationHelper.numericParser);
                            ngModel.$formatters.push(validationHelper.numericFormatter);
                            validator.ruleFor(binding.Property).must(validationHelper.isValidDecimal).withMessage('VALIDATION_NOT_DECIMAL');
                            break;
                        case 'negative-decimal' :
                            ngModel.$parsers.push(validationHelper.numericParser);
                            ngModel.$formatters.push(validationHelper.numericFormatter);
                            validator.ruleFor(binding.Property).must(validationHelper.isValidNegativeDecimal).withMessage('VALIDATION_NOT_DECIMAL_NEGATIVE');
                            break;
                        case 'date' :
                            validator.ruleFor(binding.Property).must(validationHelper.isValidDatepicker).when(validationHelper.notEmptyDatepicker).withMessage('DATE_VALIDATION_WARNING');
                            break;
                        case 'multiple' :
                            validator.ruleFor(binding.Property).must(validationHelper.isNotEmptyArray).withMessage('VALIDATION_NOTEMPTY');
                            break;
                    }

                    if(scope.$eval(attrs.acValidationRequired) || scope.$eval(attrs.ngRequired)) {
                        validator.ruleFor(binding.Property).notEmpty().withMessage('VALIDATION_NOTEMPTY');
                    }
                }

                function dispose() {
                    // on nettoie les erreurs lorsque l'on détruit l'input lié
                    clearValidationError();

                    // nettoyage des abonnements
                    if (unregisterPropertyWatch != undefined) {
                        unregisterPropertyWatch();
                    }
                    if (unregisterValidationEvent != undefined) {
                        unregisterValidationEvent();
                    }
                    if (unregisterValidationDirtyEvent != undefined) {
                        unregisterValidationDirtyEvent();
                    }
                    if (unregisterPropertyValidationEvent != undefined) {
                        unregisterPropertyValidationEvent();
                    }
                    if (unregisterPropertyClearEvent != undefined) {
                        unregisterPropertyClearEvent();
                    }
                    if (unregisterDestroyEvent != undefined) {
                        unregisterDestroyEvent();
                    }
                    if (unregisterPristineEvent != undefined) {
                        unregisterPristineEvent();
                    }
                    if (unregisterLinkedPropertiesWatch != undefined) {
                        unregisterLinkedPropertiesWatch();
                    }
                    if (unregisterLinkedPropertyWatch != undefined) {
                        unregisterLinkedPropertyWatch();
                    }
                }
            }
        };
    }
})(angular, window.globalHelpers);