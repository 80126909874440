import DocumentCEFormEnteteController from './document.ce.form.entete.controller';

export default {
    bindings: {
        docce: '<',
        form: '=',
        onUpdate: '&',
        normes: '=',
        app: '<',
        validity: '=',
        normeserror: '='
    },
    template: require('./document.ce.form.entete.html'),
    controller: DocumentCEFormEnteteController
};
