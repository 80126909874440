import ZoneGisementsConfig from './_config/zone.gisements.config';
import ZoneGisementsRoutes from './_config/zone.gisements.routes';
import ZoneGisementsRoutesQse from './_config/zone.gisements.qse.routes';
import ZoneGisementsComponent from './components/zone-gisements';
import ZoneGisementsService from './services/zone.gisements.service';
import ZoneGisementsCommunicationService from './services/zone.gisements.communication.service';

import ZoneGisementFormComponent from './components/zone-gisements-form';

import ZoneGisementValidator from './components/zone-gisements-form/zone.gisements.validator';

const moduleName = 'app.massia.common.zone.gisements';

angular
    .module(moduleName, [])
    .config(ZoneGisementsConfig)
    .config(ZoneGisementsRoutes)
    .config(ZoneGisementsRoutesQse)
    .service('ZoneGisementsService', ZoneGisementsService)
    .service('ZoneGisementsCommunicationService', ZoneGisementsCommunicationService)
    .component('zoneGisements', ZoneGisementsComponent)
    .component('zoneGisementsForm', ZoneGisementFormComponent)
    .factory('ZoneGisementValidator', ZoneGisementValidator);

export default moduleName;
