(function (angular, undefined) {
    'use strict';

    angular.module('blocks.smart.table')
        .filter('abstractFilter', abstractFilter);
    abstractFilter.$inject = ['$filter'];

    function abstractFilter($filter) {
        return function (value) {
            var filterName = [].splice.call(arguments, 1, 1)[0];
            if (filterName) {
                return $filter(filterName).apply(null, arguments);
            }
            else {
                return value;
            }

        };
    }

})(angular);