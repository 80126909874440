const serviceUrl = `${__configuration.apiUrl}/massia/grilles/chantier`;

export default class ChantiersGrillesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getGrillesChantier(filters, sorts, pagination, annee) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {}),
                annee: annee
            }
        });

        return res.data;
    }

    async getGrillesSocieteEtSiteComm(filters, sorts, pagination) {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/grilles/societeetsitecomm`, {
            params: null
        });

        return res.data.items;
    }

    async duplicationGrillesChantier(duplication) {
        const result = await this.$http.post(`${__configuration.apiUrl}/massia/grilles/duplicationchantier`, duplication);
        return result.data;
    }

    async getIndicesRevision() {
        const res = await this.$http.get(`${__configuration.apiUrl}/massia/gestion/indices-revision`, {
            params: {
                filters: JSON.stringify([]),
                sorts: JSON.stringify([]),
                pagination: JSON.stringify({ skip: 0, take: '100' })
            }
        });

        return res.data.items;
    }
}
