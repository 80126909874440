import { Component } from '../../../core/decorators';

@Component({
    selector: 'massia-footer-pagination',
    bindings: {
        itemsByPageOptions: '<'
    },
    template: require('./massia.footer.pagination.tpl.html'),
    transclude: true
})
export class MassiaFooterPaginationController {
    constructor($scope) {
        this.scope = $scope;
    }

    $onInit() {
        this.scope.itemsByPageOptions = this.itemsByPageOptions;
        this.scope.itemsByPage = this.scope.itemsByPageOptions[0];
    }
}

MassiaFooterPaginationController.$inject = ['$scope'];
