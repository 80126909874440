export default function Configure($stateProvider) {
    $stateProvider.state('equipages', {
        parent: 'gestion',
        url: '/equipages',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.TRANSPORT" | translate }}'
        }
    });

    $stateProvider.state('equipages.list', {
        url: '/list',
        template: '<equipages></equipages>',
        rights: { domain: 'dmdlivrs', right: 'read' },
        ncyBreadcrumb: {
            parent: 'equipages',
            label: '{{ "EQUIPAGES.BREADCRUMBS.EQUIPAGE_LIST" | translate}}'
        },
        navigation: {
            menu: 'transport_plannings',
            translate: 'EQUIPAGES.BREADCRUMBS.EQUIPAGE_LIST',
            order: 11
        },
        search: 'EQUIPAGES.BREADCRUMBS.EQUIPAGE_LIST'
    });

    // $stateProvider.state('indices-revision.list.detail', {
    //     url: '/{id}',
    //     template: '<indice-revision-detail></indice-revision-detail>',
    //     rights: {domain: 'dmdlivrs', right: 'read'},
    //     ncyBreadcrumb: {
    //         // on décide que la liste est le parent des autres states
    //         // afin d'afficher grilles d'indices de révision > détail
    //         parent: 'indices-revision.list',
    //         label: '{{ "INDICES_REVISION.BREADCRUMBS.INDICE_REVISION_DETAIL" | translate }}'
    //     }
    // });

    // $stateProvider.state('equipages.new', {
    //     url: '/new',
    //     template: '<demande-livraison-form></demande-livraison-form>',
    //     rights: {domain: 'dmdlivrs', right: 'create'},
    //     ncyBreadcrumb: {
    //         parent: 'demandes-livraisons.list',
    //         label: '{{ "DEMANDES_LIVRAISONS.BREADCRUMBS.DEMANDE_LIVRAISON_NEW" | translate}}'
    //     },
    //     // l'ajout d'une grilles d'indices de révision fait partie des points d'entrée recherchables dans l'application
    //     search: 'DEMANDES_LIVRAISONS.BREADCRUMBS.DEMANDE_LIVRAISON_NEW'
    // });

    // $stateProvider.state('demandes-livraisons.edit', {
    //     url: '/{id}/edit',
    //     template: '<demande-livraison-form></demande-livraison-form>',
    //     rights: {domain: 'dmdlivrs', right: 'update'},
    //     ncyBreadcrumb: {
    //         // on décide que la liste est le parent des autres states
    //         // afin d'afficher indices révision > modifier
    //         parent: 'demandes-livraisons.list',
    //         label: '{{ "DEMANDES_LIVRAISONS.BREADCRUMBS.DEMANDE_LIVRAISON_EDIT" | translate }}'
    //     }
    // });
}

Configure.$inject = ['$stateProvider'];
