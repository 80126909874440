import SiteFormEnteteController from './site.form.entete.controller';

export default {
    bindings: {
        site: '=',
        form: '=',
        onUpdate: '&',
        genre: '<'
    },
    template: require('./site.form.entete.html'),
    controller: SiteFormEnteteController
};
