import DocumentsDownloadController from './documents.download.controller';

export default {
    bindings: {
        id: '=',
        domaine: '=',
        theme: '=',
        isOpen: '<'
    },
    template: require('./documents.download.html'),
    controller: DocumentsDownloadController
};
