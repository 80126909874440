export default function Configure($stateProvider) {
    $stateProvider.state('importdonnees', {
        parent: 'common',
        url: '/import-donnees',
        views: {
            common: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.OUTILS" | translate }}'
        }
    });

    $stateProvider.state('importdonnees.edit', {
        url: '/edit',
        template: '<importdonnees></importdonnees>',
        rights: { domain: 'importdonnees', right: 'read' },
        ncyBreadcrumb: {
            parent: 'importdonnees',
            label: '{{ "IMPORT_DONNEES.BREADCRUMBS.IMPORT_DONNEES" | translate }}'
        },
        search: 'IMPORT_DONNEES.BREADCRUMBS.IMPORT_DONNEES'
    });
}

Configure.$inject = ['$stateProvider'];
