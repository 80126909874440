BonsController.$inject = [
	'$scope',
	'$state',
	'$stateParams',
	'$translate',
	'ModalService',
	'PaginationService',
	'BonsService',
	'notification',
	'$uibModal'
];

export default function BonsController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	BonsService,
	notification,
	$uibModal
) {
	const vm = this;

	let previousTableState;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.state = $state;
	vm.$uibModal = $uibModal;

	vm.bons = [];

	vm.slideWidth = '500px';
	vm.slideMargin = {
		'margin-right': '0px',
		'transition-duration': '0.5s',
		'transition-animation': 'margin-right',
		height: '100%'
	};
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.loadBons = loadBons;
	vm.deleteBon = deleteBon;
	vm.createBon = createBon;
	vm.updateBon = updateBon;
	vm.activeEditMode = activeEditMode;
	vm.desactiveEditMode = desactiveEditMode;
	vm.open = open;

	async function init() {
		getUnites();
	}

	init();

	async function loadBons(tableState) {
		startLoading();

		if ($state && $state.current && $state.current.name !== 'bons.list') {
			$state.go('bons.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.bons = [];
		vm.newBon = {};

		try {
			const data = await BonsService.getBons(filters, sorts, pagination);

			vm.bons = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function getUnites() {
		startLoading();
		try {
			const res = await BonsService.getUnites();
			vm.unites = res.items;
		} catch (ex) {
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteBon(bon) {
		if (bon && bon.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('BONS.DELETE.TITLE'),
				modalMsg: $translate.instant('BONS.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await BonsService.deleteBonById(bon.id);
					notification.success($translate.instant('BONS.DELETE.SUCCESS'));
					loadBons();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	function activeEditMode(bon) {
		vm.libelleBeforeUpdate = bon.libelle;
		bon.isEditMode = true;
	}

	function desactiveEditMode(bon) {
		bon.libelle = vm.libelleBeforeUpdate;
		bon.isEditMode = false;
	}

	async function createBon(bon) {
		bon.type = 'bon';
		if (bon && bon.code && bon.libelle) {
			startLoading();
			try {
				await BonsService.createBon(bon);
				notification.success($translate.instant('BONS.CREATE.SUCCESS'));
				loadBons();
				vm.newBon = {};
			} catch (ex) {
				notification.error(ex.data);
			} finally {
				stopLoading();
			}
		}
	}

	async function updateBon(bon) {
		if (bon && bon.id && bon.code && bon.libelle) {
			startLoading();
			try {
				await BonsService.updateBon(bon);
				notification.success($translate.instant('BONS.UPDATE.SUCCESS'));
				vm.newBon = {};
				loadBons();
			} catch (ex) {
				notification.error(ex.data);
			} finally {
				stopLoading();
			}
		}
	}

	function open(domaine, bon) {
		vm.source = {};
		vm.source.entete = bon;
		vm.source.entete.type = 'prestation';
		vm.source.id = bon.id;
		vm.$uibModal
			.open({
				template: '<centre-gestion source="$ctrl.source" domaine="$ctrl.domaine" modal-instance="$ctrl.uibModalInstance"></centre-gestion>',
				controller: [
					'$uibModalInstance',
					function ($uibModalInstance) {
						const $ctrl = this;
						$ctrl.source = vm.source;
						$ctrl.domaine = domaine;
						$ctrl.uibModalInstance = $uibModalInstance;
					}
				],
				controllerAs: '$ctrl',
				size: 'xxl'
			})
			.result.then(
				function (result) {
					// console.info("I was closed, so do what I need to do myContent's controller now.  Result was->");
					// console.info(result);
				},
				function (reason) {
					// console.info("I was dimissed, so do what I need to do myContent's controller now.  Reason was->" + reason);
				}
			);
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	function dispose() {
		watchers.forEach((x) => x());
	}
}
