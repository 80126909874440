export default class MassiaApplicationService {
	body = angular.element(document).find('body');
	application = 'gestion';
	/* @ngInject */
	constructor(localStorageService, $urlService, LoginService, $timeout) {
		this.localStorageService = localStorageService;
		this.UrlService = $urlService;
		this.LoginService = LoginService;
		this.$timeout = $timeout;
		this.setApplication(this.application);
	}

	setApplication(app) {
		localStorage.removeItem('massia.application');
		this.body.removeClass(`app-${this.application}`);
		this.application = app;
		this.body.addClass(`app-${this.application}`);
		this.LoginService.setPostLoginRedirection(`common.${app}.home`);
		this.UrlService.rules.otherwise(`${app}/home`);
		this.localStorageService.set('application', app);
		this.changeFavicon();
	}

	getApplication() {
		return this.localStorageService.get('application');
	}

	resetApplication() {
		this.setApplication(this.application);
	}

	changeFavicon() {
		const link = document.getElementById('massia-fav');
		const iconPath = `assets/img/favicon-${this.application}.ico`;
		this.$timeout(() => {
			if (!link) {
				const head = document.getElementsByTagName('head')[0];
				link = document.createElement('link');
				link.rel = 'icon';
				head.appendChild(link);
			}
			link.href = iconPath;
		});
	}
}
