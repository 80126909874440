export default class UserRequestService {
    request = [];
    userAddRequest = [];

    constructor($http) {
        this.$http = $http;
        this.url = `${__configuration.apiUrl}/massia/portail/request`;
        this.addUserUrl = `${__configuration.apiUrl}/massia/portail/user-add-request`;
    }

    getAll() {
        return this.$http.get(this.url);
    }

    async loadAll() {
        try {
            await this.$http.get(this.url).then((res) => {
                this.request = res.data;
            });
            await this.$http.get(this.addUserUrl).then((res) => {
                this.userAddRequest = res.data;
            });
        } catch (err) {
            console.error(err);
        }
    }

    deleteRequest(req, allow) {
        return this.$http.post(`${this.url}/${req.id}`, {
            id: req.id,
            type: req.toUpdate ? 'updateRequest' : req.toDelete ? 'deleteRequest' : '',
            response: allow
        });
    }

    getAllAddUserRequest() {
        return this.$http.get(this.addUserUrl);
    }

    updateAddUserRequest(req) {
        return this.$http.put(`${this.addUserUrl}/${req.id}`, req);
    }
}

UserRequestService.$inject = ['$http'];
