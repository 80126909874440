import { Component } from '../../../core/decorators';
import ExcelModal, { ExcelForm } from './massia.essai.excel.model';

@Component({
    selector: 'massia-essai-excel',
    bindings: {
        mesure: '<',
        sousMesures: '<',
        model: '<',
        popover: '@',
        resolve: '<',
        modalInstance: '<',
        configActual: '<',
        excel: '='
    },
    template: require('./massia.essai.excel.tpl.html')
})
export class MassiaEssaiExcelController {
    constructor(TemplateImpressionService, MesuresService, $scope, $uibModal, ExcelService) {
        this.impression = TemplateImpressionService;
        this.test = [];
        this.temptemplate = {};
        this.libCell = [];
        this.MesuresService = MesuresService;
        this.scope = $scope;
        this.$uibModal = $uibModal;
        this.ExcelService = ExcelService;
        this.tmp = {};
        this.isEdit = false;
    }

    async $onInit() {
        this.config = new ExcelModal();
        this.createValidatorWatcher();
        this.createSelect();
        this.excel = {
            configExcel: [],
            enteteExcel: new ExcelForm(),
            configToRemove: []
        };
        if (this.configActual && this.configActual.id) {
            this.isEdit = true;

            this.excel.enteteExcel = new ExcelForm(this.configActual);
            await this.loadExcelData(this.excel.enteteExcel.id);
        } else {
            this.excel.enteteExcel.code = new Date().getTime();
        }

        await this.loadTemplate();
        //await this.loadTemplate();
    }

    $onDestroy() {
        this.unregisterConfig();
        this.unregisterConfigCell();
        this.unregisterMesureWatcher();
    }

    async $onChanges(val) {
        if (val.configActual?.previousValue && val.configActual?.currentValue) {
            if (val.configActual.currentValue.id !== 0 && val.configActual.currentValue.id !== this.excel.enteteExcel?.id) {
                this.excel.configExcel = [];
                this.isEdit = true;

                this.excel.enteteExcel = new ExcelForm(val.configActual.currentValue);
                await this.loadExcelData(this.excel.enteteExcel.id);
            }
        } else {
            this.isEdit = false;
            // this.excel.enteteExcel = new ExcelForm();
        }
        if (val.sousMesures && val.sousMesures.currentValue) {
            this.addSousMesuresToSelect(val.sousMesures.currentValue);
        }
    }

    onEnter(ev) {
        if (ev.which === 13) {
            this.add();
        }
    }

    async loadExcelData(id) {
        try {
            const res = await this.ExcelService.getCellByConfig(id, this.mesure.id ? this.mesure.id : null);
            this.excel.configExcel = [];
            for (let i = 0; i < res.length; i++) {
                let cell = res[i];
                this.excel.configExcel.push(new ExcelModal(cell));
            }
        } catch (err) {
            console.log(err);
        }
    }

    remove(index) {
        if (this.excel.configExcel[index].id) {
            this.excel.configToRemove.push(this.excel.configExcel[index]);
        }
        this.excel.configExcel.splice(index, 1);
    }

    createValidatorWatcher() {
        this.unregisterConfigCell = this.scope.$watch(
            () => {
                return this.config;
            },
            () => {
                if (this.config) {
                    this.config.isValid();
                }
            },
            true
        );

        this.unregisterConfig = this.scope.$watch(
            () => {
                return this.excel.enteteExcel;
            },
            () => {
                if (this.excel.enteteExcel) {
                    this.excel.enteteExcel.isValid();
                    if (!this.isEdit && this.excel.enteteExcel.code && this.excel.enteteExcel.code.length > 3) {
                        this.codeIsUnique(this.excel.enteteExcel.code);
                    }
                }
            },
            true
        );

        this.unregisterMesureWatcher = this.scope.$watch(
            () => {
                return this.sousMesures;
            },
            () => {
                this.addSousMesuresToSelect(this.sousMesures);
            },
            true
        );
    }

    async addSousMesuresToSelect(sousMes) {
        await this.createSelect();
        for (let i = 0; i < sousMes.length; i++) {
            if (sousMes[i].idNature != 12) {
                //essai excel
                this.cat.push({
                    libelle: sousMes[i].libelle,
                    champs: 'sousessai',
                    code: sousMes[i].code
                });
            }
        }
    }

    async codeIsUnique(code) {
        const res = await this.ExcelService.isCodeUnique(code, 'ConfigExcel');
        this.isCodeUnique = res ? false : true;
    }

    async createSelect() {
        this.cat = [];
        this.cat = [
            {
                libelle: 'Produit',
                champs: 'produit'
            },
            {
                libelle: 'Prélèvement',
                champs: 'prelevement'
            },
            {
                libelle: '',
                champs: 'essais'
            },
            {
                libelle: "Liste d'essais",
                champs: 'liste essais'
            },
            {
                libelle: "Liste d'essais et sous-essais",
                champs: 'liste sous-essais'
            },
            {
                libelle: 'Laboratoire',
                champs: 'laboratoire'
            },
            {
                libelle: 'Utilisateur',
                champs: 'utilisateur'
            },
            {
                libelle: 'Producteur',
                champs: 'producteur'
            }
        ];
        return;
    }

    add() {
        if (this.config.isValid()) {
            this.config.cell = this.config.cell.toUpperCase();
            this.excel.configExcel.push(angular.copy(this.config));
            this.config = new ExcelModal();
            this.tmp = {};
        }
    }

    valid() {
        if (this.excel.enteteExcel.isValid()) {
            if (this.excel.configExcel) {
                let valid = true;
                for (let i = 0; i < this.excel.configExcel.length; i++) {
                    if (!this.excel.configExcel[i].isValid()) {
                        valid = false;
                        break;
                    }
                }
                if (valid) {
                    if (this.isEdit) {
                        this.updateConfig(this.excel.enteteExcel);
                    } else {
                        if (this.isCodeUnique) {
                            this.saveConfig(this.excel.enteteExcel, this.config);
                        }
                    }
                }
            }
        }
    }

    async saveConfig(config, listConfig) {
        try {
            const conf = await this.ExcelService.createConfig(config);
            if (conf && conf.id) {
                await this.ExcelService.createCellConfig(conf.id, listConfig);
                this.modalInstance.close(conf);
            }
        } catch (err) {
            console.log(err);
        }
    }

    async updateConfig(config) {
        try {
            await this.ExcelService.updateConfig(config.id, config);
            let data = {
                cellToUpdate: this.config,
                cellToDelete: this.configToRemove
            };
            await this.ExcelService.updateConfigCell(config.id, data);
            this.modalInstance.close(config);
        } catch (err) {
            console.log(err);
        }
    }

    selectedCat(item, model = null) {
        if (model) {
            model.champs = item.champs;
            if (model.champs === 'sousessai') {
                model.libelle = item.libelle;
                if (item.code) {
                    model.codeSousEssai = item.code;
                }
            }
        } else {
            this.config.champs = item.champs;
            if (item.champs === 'sousessai') {
                this.config.libelle = item.libelle;
                if (item.code) {
                    this.config.codeSousEssai = item.code;
                }
            }
        }
        this.fields = this.getFields(item.champs);
    }

    selectedField(item, model = null) {
        if (model) {
            model.pour = item.pour;
        } else {
            this.config.pour = item.pour;
        }
    }

    getFields(cat) {
        switch (cat) {
            case 'produit':
                return [
                    {
                        libelle: 'Code',
                        pour: 'code'
                    },
                    {
                        libelle: 'Libelle',
                        pour: 'libelle'
                    },
                    {
                        libelle: 'Petit d',
                        pour: 'petitd'
                    },
                    {
                        libelle: 'Grand D',
                        pour: 'grandD'
                    },
                    {
                        libelle: 'Type',
                        pour: 'type'
                    }
                ];

            case 'prelevement':
                return [
                    {
                        libelle: 'Code',
                        pour: 'code'
                    },
                    {
                        libelle: 'Date',
                        pour: 'date'
                    }
                ];

            case 'essais':
            case 'liste essais':
            case 'liste sous-essais':
                return [
                    {
                        libelle: 'Code',
                        pour: 'code'
                    },
                    {
                        libelle: 'Libelle',
                        pour: 'libelle'
                    },
                    {
                        libelle: 'Valeur',
                        pour: 'valeur'
                    }
                ];

            case 'laboratoire':
            case 'utilisateur':
            case 'producteur':
                return [
                    {
                        libelle: 'Nom',
                        pour: 'nom'
                    },
                    {
                        libelle: 'Adresse',
                        pour: 'adresse'
                    },
                    {
                        libelle: 'Code postal',
                        pour: 'codepostal'
                    },
                    {
                        libelle: 'Ville',
                        pour: 'ville'
                    },
                    {
                        libelle: 'Téléphone',
                        pour: 'telephone'
                    },
                    {
                        libelle: 'Fax',
                        pour: 'fax'
                    }
                ];

            case 'sousessai':
                return [
                    {
                        libelle: 'Libellé',
                        pour: 'libelle'
                    },
                    {
                        libelle: 'Code',
                        pour: 'code'
                    },
                    {
                        libelle: 'Valeur',
                        pour: 'valeur'
                    }
                ];
            default:
                break;
        }
    }

    getValue(model) {
        let champs = model.champs;
        let pour = model.pour;

        for (let i = 0; i < this.cat.length; i++) {
            let cat = this.cat[i];
            if (champs === 'sousessai' && cat.champs === 'essais') {
                model.code = cat.code;
                let fields = this.getFields('sousessai');
                for (let k = 0; k < fields.length; k++) {
                    let f = fields[k];
                    if (f.pour === pour) {
                        return `${model.libelle} [${model.codeSousEssai}] (${f.libelle})`;
                    }
                }
            } else if (cat.champs === champs) {
                let fields = this.getFields(champs);
                for (let k = 0; k < fields.length; k++) {
                    let f = fields[k];
                    if (f.pour === pour) {
                        return `${cat.libelle} (${f.libelle})`;
                    }
                }
            }
        }
    }

    async loadTemplate() {
        try {
            this.template = await this.impression.getTemplateByDomaine('essais');
        } catch (err) {
            console.log(err);
        }
    }

    selectItem(template) {
        this.excel.enteteExcel.idTemplate = template.id;
    }
}

MassiaEssaiExcelController.$inject = ['TemplateImpressionService', 'MesuresService', '$scope', '$uibModal', 'ExcelService'];
