export default function Configure($stateProvider) {
    $stateProvider.state('zones', {
        parent: 'gestion',
        url: '/zones',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            // le state étant parent et abstract, on décide de ne pas l'afficher dans le fil d'ariane
            //skip: true
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('zones.list', {
        url: '/list',
        template: '<zones></zones>',
        rights: { domain: 'zone', right: 'read' },
        ncyBreadcrumb: {
            parent: 'zones',
            label: '{{ "ZONES.BREADCRUMBS.ZONES_LIST" | translate}}'
        },
        // on affiche la liste des zones dans la navigation
        navigation: {
            menu: 'tarification_transport',
            translate: 'ZONES.BREADCRUMBS.ZONES_LIST',
            order: 3
        },
        // la liste des zones fait partie des points d'entrée recherchables dans la navigation
        search: 'ZONES.BREADCRUMBS.ZONES_LIST'
    });

    $stateProvider.state('zones.list.detail', {
        url: '/{id}',
        template: '<zone-detail></zone-detail>',
        rights: { domain: 'zone', right: 'read' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher zones > détail
            parent: 'zones.list',
            label: '{{ "ZONES.BREADCRUMBS.ZONE_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('zones.new', {
        url: '/new',
        template: '<zones-form></zones-form>',
        rights: { domain: 'zone', right: 'create' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher zones > ajouter
            parent: 'zones.list',
            label: '{{ "ZONES.BREADCRUMBS.ZONE_NEW" | translate}}'
        },
        // l'ajout d'une zone fait partie des points d'entrée recherchables dans l'application
        search: 'ZONES.BREADCRUMBS.ZONE_NEW'
    });

    $stateProvider.state('zones.edit', {
        url: '/{id}/edit',
        template: '<zones-form></zones-form>',
        rights: { domain: 'zone', right: 'update' },
        ncyBreadcrumb: {
            // on décide que la liste est le parent des autres states
            // afin d'afficher zones > modifier
            parent: 'zones.list',
            label: '{{ "ZONES.BREADCRUMBS.ZONE_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
