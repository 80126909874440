import validatorBuilder from 'validator-builder';

export default class Coefficientk {
    constructor(data, moment, dateFormat) {
        data = data || {};

        this.id = data.id || null;
        this.normeSpecifique = data.normeSpecifique || null;
        this.date = data.date || /* moment().format(dateFormat) */ null;

        this.produit = data.produit || null;
        this.producteur = data.producteur || null;
        this.client = data.client || null;

        this.typeAddition = data.typeAddition || null;
        this.typeCiment = data.typeCiment || null;
        this.classeResistanceCiment = data.classeResistanceCiment || null;

        this.coefK = data.coefK || null;
        this.kPrime = data.kPrime || null;

        this.kprimeList = data.kprimeList || [];
        this.producteursList = data.producteursList || [];
        this.normesList = data.normesList || [];
        this.clientsList = data.clientsList || [];
        this.typeCimentList = data.typeCimentList || [];
        this.resistanceCimentList = data.resistanceCimentList || [];
    }

    isValid() {
        const validator = validatorBuilder.getInstanceFor('ProduitCoefficientk');
        const res = validator.validate(this);
        return res.isValid;
    }

    getErrors() {
        const validator = validatorBuilder.getInstanceFor('ProduitCoefficientk');
        const res = validator.validate(this);
        return res.errors;
    }
}
