import { enumEtudeAffaire } from '../offre-entete/offre.entete.model';

/* @ngInject */
export default function OffresController(
	$scope,
	$state,
	$stateParams,
	$translate,
	ModalService,
	PaginationService,
	OffresService,
	notification,
	DataShareService,
	globalizationManagementService
) {
	const vm = this;
	$scope.enum = enumEtudeAffaire;
	let previousTableState;
	let selectedOffreId = undefined;
	const watchers = [];

	vm.loading = false;
	vm.params = $stateParams;
	vm.DataShareService = DataShareService;
	vm.state = $state;
	vm.offres = [];
	vm.savedPagination = {};
	vm.savedPagination.entity = 'pagination';
	vm.itemsByPageOptions = [20, 100, 200];
	vm.itemsByPage = vm.itemsByPageOptions[0];

	vm.selectOffre = selectOffre;
	vm.addOffre = addOffre;
	vm.loadOffres = loadOffres;
	vm.deleteOffre = deleteOffre;
	vm.eraseAllFilters = eraseAllFilters;
	vm.exporter = exporter;
	vm.dupliquer = dupliquer;
	vm.validerParametre = validerParametre;

	vm.dateFormat = globalizationManagementService.getCurrentLanguage().dateFormat;
	vm.displayDateMask = globalizationManagementService.getCurrentLanguage().displayDateMask;

	function selectOffre(offre) {
		$state.go('offres.edit', { id: offre.id });
	}

	async function getEtats() {
		vm.etats = await OffresService.getEtats();
	}

	// Transformation de l'enum en tableau pour l'utiliser dans les filtre
	function getEnum() {
		vm.enum = [];
		for (const key in enumEtudeAffaire) {
			if (Object.hasOwnProperty.call(enumEtudeAffaire, key)) {
				// Key = la propriété de l'objet, value = la valeur donc le int
				const value = enumEtudeAffaire[key];
				// Traduction de l'enum faite à la construction du tableau
				vm.enum.push({ id: value, label: $translate.instant(`OFFRES.${key}`) });
			}
		}
	}

	async function loadOffres(tableState) {
		startLoading();

		// gestion de la sauvegarde et de la restitution de la pagination
		const objectToManage = {};
		objectToManage.tableState = tableState;
		objectToManage.savedPagination = vm.savedPagination;
		objectToManage.itemsByPageOptions = vm.itemsByPageOptions;
		objectToManage.itemsByPage = vm.itemsByPage;
		const resultObject = await vm.DataShareService.managePaginationContextuelle(objectToManage);
		if (resultObject) {
			tableState = resultObject.tableState;
			vm.savedPagination = resultObject.savedPagination;
			vm.itemsByPageOptions = resultObject.itemsByPageOptions;
			vm.itemsByPage = resultObject.itemsByPage;
		}

		if ($state && $state.current && $state.current.name !== 'offres.list') {
			$state.go('offres.list');
		}

		if (tableState) {
			previousTableState = tableState;
		}

		const filters = PaginationService.getFilters(previousTableState);
		const sorts = PaginationService.getSorts(previousTableState);
		const pagination = PaginationService.getPagination(previousTableState);

		vm.offres = [];

		getEtats();

		try {
			const data = await OffresService.getOffres(filters, sorts, pagination);
			vm.offres = data.items;

			if (previousTableState) {
				PaginationService.setTableState(data.skip, data.take, data.total, previousTableState);
			}
			getEnum();
		} catch (ex) {
			console.error(ex);
			notification.error(ex.data);
		} finally {
			stopLoading();
		}
	}

	async function deleteOffre(offre) {
		if (offre && offre.id) {
			const modalInstance = ModalService.confirm({
				modalTitle: $translate.instant('OFFRES.DELETE.TITLE', { code: offre.id }), //TODO FP à modifier - pas id
				modalMsg: $translate.instant('OFFRES.DELETE.MESSAGE'),
				headerClass: 'modal-danger'
			});

			modalInstance.result.then(async function () {
				startLoading();
				try {
					await OffresService.deleteOffreById(offre.id);
					notification.success($translate.instant('OFFRES.DELETE.SUCCESS'));

					selectedOffreId = undefined;

					if (vm.params.id) {
						vm.state.go('offres.list');
					}

					loadOffres();
				} catch (ex) {
					notification.error(ex.data);
				} finally {
					stopLoading();
				}
			});
		}
	}

	async function exporter(modeImpression) {
		try {
			const filters = PaginationService.getFilters(previousTableState);
			const sorts = PaginationService.getSorts(previousTableState);
			const fileName = 'Liste_offres';
			let resultat = undefined;
			try {
				resultat = await OffresService.exportListeOffres(modeImpression, filters, sorts);
			} catch (ex) {
				//si on a des exception à afficher, le faire de cette manière à cause du format de retour de type fichier
				const msgException = String.fromCharCode.apply(null, new Uint8Array(ex.data));
				notification.error('OFFRES.' + msgException);
				return false;
			}
			if (resultat) {
				const data = resultat.data;
				const status = resultat.status;
				let headers = resultat.headers;
				headers = headers();

				const contentType = headers['content-type'];

				const linkElement = document.createElement('a');
				try {
					const blob = new Blob([data], { type: contentType + ';charset=UTF-8' });
					const url = window.URL.createObjectURL(blob);
					linkElement.setAttribute('href', url);
					if (modeImpression == 0) {
						linkElement.setAttribute('download', fileName + '.pdf');
					} else {
						linkElement.setAttribute('download', fileName + '.xlsx');
					}

					const clickEvent = new MouseEvent('click', {
						view: window,
						bubbles: true,
						cancelable: false
					});
					linkElement.dispatchEvent(clickEvent);
				} catch (ex) {
				} finally {
				}
				return true;
			}
		} catch (ex) {
			this.notification.error(ex.data);
			return false;
		}
	}

	function startLoading() {
		vm.loading = true;
	}

	function stopLoading() {
		vm.loading = false;
	}

	async function addOffre() {
		//var idOffre = await OffresService.createOffre();
		$state.go('offres.new');
	}

	function eraseAllFilters() {
		$state.reload();
	}

	async function dupliquer(offre) {
		const modalInstance = ModalService.confirm({
			modalTitle: $translate.instant('OFFRES.DUPLICATE.TITLE', { code: offre.code }),
			modalMsg: $translate.instant('OFFRES.DUPLICATE.MESSAGE'),
			headerClass: 'modal-info'
		});

		modalInstance.result.then(async function () {
			startLoading();
			try {
				const duplicatedOffreId = await OffresService.duplicateOffre(offre.id);
				$state.go('offres.edit', { id: duplicatedOffreId });
			} catch (ex) {
				notification.error(ex.data);
			} finally {
				stopLoading();
			}
		});
	}

	function validerParametre() {
		$state.go('offres.validation');
	}
}
