import BennesConfig from './_config/bennes.config';
import BennesRoutes from './_config/bennes.routes';
import BennesComponent from './components/bennes';
import BennesService from './services/bennes.service';

const moduleName = 'app.massia.gestion.bennes';

angular.module(moduleName, []).config(BennesConfig).config(BennesRoutes).service('BennesService', BennesService).component('bennes', BennesComponent);

export default moduleName;
