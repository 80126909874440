import validatorBuilder from 'validator-builder';
import DocumentEntete from './entete/document.entete.model';
import DocumentCaracteristiques from './caracteristiques/document.caracteristiques.model';

let documentValidator = null;

export default class Document {
    constructor(data = {}) {
        this.id = data.id;
        this.entete = new DocumentEntete(data.entete);
        this.caracteristiques = new DocumentCaracteristiques(data.caracteristiques);
    }

    isValid() {
        documentValidator = documentValidator || validatorBuilder.getInstanceFor('Document');
        const validationResults = documentValidator.validate(this);
        return validationResults.isValid;
    }
}
