const serviceUrl = `${__configuration.apiUrl}/massia/devises`;

export default class DevisesService {
    static $inject = ['$http'];

    constructor($http) {
        this.$http = $http;
    }

    async getDevises(filters, sorts, pagination) {
        const res = await this.$http.get(serviceUrl, {
            params: {
                filters: JSON.stringify(filters || []),
                sorts: JSON.stringify(sorts || []),
                pagination: JSON.stringify(pagination || {})
            }
        });

        return res.data;
    }

    async deleteDeviseById(id) {
        const url = `${serviceUrl}/${id}`;
        return this.$http.delete(url);
    }

    async getDeviseEnteteById(id) {
        const url = `${serviceUrl}/${id}/entete`;
        const res = await this.$http.get(url);
        return res.data;
    }

    async createDevise(entete, caracteristiques, niveaux) {
        const data = {
            code: entete.code,
            symbole: entete.symbole,
            arrondi: entete.arrondi
        };

        return this.$http.post(serviceUrl, data);
    }

    async updateDevise(entete, caracteristiques, niveaux) {
        const data = {
            id: entete.id,
            code: entete.code,
            symbole: entete.symbole,
            arrondi: entete.arrondi
        };

        const url = `${serviceUrl}/${data.id}`;
        return this.$http.put(url, data);
    }

    async codeExists(code) {
        const url = `${serviceUrl}/code-unicity/${code}`;
        const result = await this.$http.get(url);
        return result.data;
    }
}
