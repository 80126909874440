import { NgModule } from '../decorators';
import { ProductValidator } from './product.validator';
import { SeuilBetGachTolValidator } from './seuil.bet.gach.tol.validator';
import { SeuilBetGachValidator } from './seuil.bet.gach.validator';

export const validatorModule = 'app.core.validator';

@NgModule({
    id: 'app.core.validator',
    providers: [
        {
            provide: 'ProductValidator',
            useFactory: ProductValidator
        },
        {
            provide: 'SeuilBetGachTolValidator',
            useFactory: SeuilBetGachTolValidator
        },
        {
            provide: 'SeuilBetGachValidator',
            useFactory: SeuilBetGachValidator
        }
    ]
})
export class ValidatorModule {}
