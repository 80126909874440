import ImportBlFichierPopupController from './import.bl.fichier.popup.controller';

export default {
    bindings: {
        modalInstance: '=',
        importBl: '='
    },
    template: require('./import.bl.fichier.popup.html'),
    controller: ImportBlFichierPopupController
};
