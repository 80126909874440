export default function GroupRoute($stateProvider) {
    $stateProvider
        .state('portail.group', {
            url: '/group',
            abstract: true,
            template: '<ui-view></ui-view>',
            ncyBreadcrumb: {
                skip: true
                //label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}',
            }
        })
        .state('portail.group.list', {
            url: '',
            rights: { domain: 'portail', right: 'read' },
            template: '<portail-group-list></portail-group-list>',
            ncyBreadcrumb: {
                skip: true
                //label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}',
            }
        })
        .state('portail.group.add', {
            url: '/add',
            rights: { domain: 'portail', right: 'create' },
            template: '<portail-group-add></portail-group-add>',
            ncyBreadcrumb: {
                skip: true,
                label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}'
            },
            search: 'PORTAIL.BREADCRUMBS.USERS_NEW'
        })
        .state('portail.group.edit', {
            url: '/:id/edit',
            rights: { domain: 'portail', right: 'update' },
            template: '<portail-group-add></portail-group-add>',
            params: {
                obj: null
            },
            ncyBreadcrumb: {
                skip: true,
                label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}'
            },
            search: 'PORTAIL.BREADCRUMBS.USERS_NEW'
        });

    /* $stateProvider.state('portail.user', {
        url: '/utilisateur',
        template: '<zone-abattages></zone-abattages>',
        rights: { domain: 'portail.user', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "PORTAIL.BREADCRUMBS.PORTAIL" | translate}}',
        },
        // la liste des zone-abattages fait partie des points d'entrée recherchables dans la navigation
        search: 'PORTAIL.BREADCRUMBS.PORTAIL',
    }); */
}

GroupRoute.$inject = ['$stateProvider'];
