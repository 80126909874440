export default class SiteZoneStockagesController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'SitesService',
        'SitesTypesService',
        'ModalService',
        'notification',
        '$translate',
        '_',
        '$filter'
    ];

    constructor($scope, $state, $stateParams, SitesService, SitesTypesService, ModalService, notification, $translate, _, $filter) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SitesService = SitesService;
        this.SitesTypesService = SitesTypesService;
        this.ModalService = ModalService;
        this.notification = notification;
        this.$translate = $translate;
        this._ = _;
        this.$filter = $filter;

        this.types = [
            {
                libelle: 'Stockage',
                id: 0
            },
            {
                libelle: 'Extraction',
                id: 1
            }
        ];
        this.typeToAdd = 0;
    }

    $onInit() {
        this.unregister = this.$scope.$watch(
            () => this.isOpen,
            () => {
                if (this.isOpen && !this.zoneStockages) {
                    this.site = this.site || {};
                    this.zoneStockages = this.site.zoneStockages;
                    this.getElementsSelectionnables();
                }
            },
            true
        );
    }

    deleteZoneStockage(zoneStockage) {
        const index = this.zoneStockages.indexOf(zoneStockage);
        this.zoneStockages.splice(index, 1);
    }

    addZoneStockage() {
        if (this.codeToAdd && this.typeToAdd != null) {
            const isExist = this._.findIndex(this.zoneStockages, { code: this.codeToAdd, type: this.typeToAdd });
            if (isExist == -1) {
                const zoneStockageToAdd = {};
                zoneStockageToAdd.code = this.codeToAdd;
                zoneStockageToAdd.libelle = this.libelleToAdd;
                zoneStockageToAdd.type = this.typeToAdd;
                zoneStockageToAdd.isActif = this.isActifToAdd;
                zoneStockageToAdd.zoneStockageProduits = [];

                for (let index = 0; index < this.produit.length; index++) {
                    const currentProduit = this.produit[index];
                    const zoneStockageProduit = {
                        idProduit: currentProduit.idProduit,
                        nomProduit: currentProduit.nomProduit,
                        idProducteur: currentProduit.idProducteur,
                        nomProducteur: currentProduit.nomProducteur,
                        libelle: currentProduit.nomProduit + ' / ' + currentProduit.nomProducteur
                    };
                    zoneStockageToAdd.zoneStockageProduits.push(zoneStockageProduit);
                }

                zoneStockageToAdd.produitProducteurs = zoneStockageToAdd.zoneStockageProduits.map((x) => x.libelle).join(', ');
                this.zoneStockages.push(zoneStockageToAdd);
                this.codeToAdd = '';
                this.typeToAdd = 0;
                this.idProduit = '';
                this.idProducteur = '';
                this.nomProducteur = '';
                this.nomProduit = '';
                this.produit = '';
            } else {
                this.notification.error('SITES.ZONESTOCKAGES.ERROR');
            }
        }
    }

    async getElementsSelectionnables(type) {
        try {
            let entreeSortie = this.typeToAdd;
            if (type) {
                entreeSortie = type;
            }
            this.allProduits = await this.SitesService.getAllProduits(this.site.id, entreeSortie);
            this.produits = await this.prepareListeElementsLies(this.allProduits);
            this.liste = this.produits;
        } catch (err) {
            if (err.data) {
                this.notification.error(err.data);
            } else {
                throw err;
            }
        }
    }

    async prepareListeElementsLies(elementsATraiter) {
        const elementsSelectionnablesOrdonnes = this.$filter('orderBy')(elementsATraiter, 'producteurId');
        let tmp = null;
        const temp = [];
        for (let index = 0; index < elementsSelectionnablesOrdonnes.length; index++) {
            const currentValue = elementsSelectionnablesOrdonnes[index];
            const toReturn = [];

            if (currentValue.hasOwnProperty('modeDeclenchement')) {
                currentValue.idProduit = currentValue.id;
                toReturn.push(currentValue);
            } else {
                //#region Gestion des groupes
                if (tmp == null) {
                    //creation du premier groupe
                    tmp = currentValue.producteurId;
                    toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
                } else {
                    if (tmp != currentValue.producteurId) {
                        //création des autres groupes
                        toReturn.push({ msGroup: false });
                        tmp = currentValue.producteurId;
                        toReturn.push({ libelle: '<span="font-weight:bold">' + currentValue.producteurLibelle + '</span>', msGroup: true });
                    }
                }
                //#endregion
                currentValue.libelle = currentValue.produitCode + ' - ' + currentValue.produitLibelle;
                currentValue.valeur = false;
                currentValue.idProduit = currentValue.produitId;
                currentValue.code = currentValue.produitCode;
                currentValue.idProducteur = currentValue.producteurId;
                currentValue.nomProduit = currentValue.produitLibelle;
                currentValue.nomProducteur = currentValue.producteurLibelle;
                toReturn.push(currentValue);

                if (index == elementsSelectionnablesOrdonnes.length - 1) {
                    toReturn.push({ msGroup: false });
                }
            }
            temp.push(toReturn);
        }
        const sorties = _.flatten(temp);
        return new Promise((resolve) => resolve(sorties));
    }

    changeZoneStockage(zoneStockage) {
        zoneStockage.isUpdate = true;
    }

    async activeEditMode(zoneStockage) {
        zoneStockage.isEditMode = true;
        this.zoneStockageTemp = {
            id: zoneStockage.id,
            code: zoneStockage.code,
            libelle: zoneStockage.libelle,
            type: zoneStockage.type,
            produitProducteurs: zoneStockage.produitProducteurs,
            zoneStockageProduits: zoneStockage.zoneStockageProduits
        };
        this.zoneIsEditMode = true;
        await this.getElementsSelectionnables(zoneStockage.type);
        this.preselectProducteurProduits(zoneStockage.zoneStockageProduits);
    }

    desactiveEditMode(index, zoneStockage) {
        zoneStockage.isEditMode = false;
        this.site.zoneStockages[index] = this.zoneStockageTemp;
        this.zoneIsEditMode = false;
        this.getElementsSelectionnables();
    }

    updateZoneStockage(zoneStockage) {
        zoneStockage.isEditMode = false;
        this.zoneIsEditMode = false;

        zoneStockage.zoneStockageProduits = [];
        for (let index = 0; index < this.produit.length; index++) {
            const currentProduit = this.produit[index];
            const zoneStockageProduit = {
                idProduit: currentProduit.idProduit,
                nomProduit: currentProduit.nomProduit,
                idProducteur: currentProduit.idProducteur,
                nomProducteur: currentProduit.nomProducteur,
                libelle: currentProduit.nomProduit + ' / ' + currentProduit.nomProducteur
            };
            zoneStockage.zoneStockageProduits.push(zoneStockageProduit);
        }
        zoneStockage.produitProducteurs = zoneStockage.zoneStockageProduits.map((x) => x.libelle).join(', ');
        zoneStockage.isUpdate = true;

        this.getElementsSelectionnables();
    }

    async preselectProducteurProduits(zoneStockageProduits) {
        if (zoneStockageProduits) {
            for (let index = 0; index < zoneStockageProduits.length; index++) {
                const element = zoneStockageProduits[index];
                const filtre = this.liste.findIndex((x) => x.idProducteur === element.idProducteur && x.idProduit === element.idProduit);
                if (filtre > 0) {
                    this.liste[filtre].selected = true;
                }
            }
        }
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }
}
