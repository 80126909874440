import { copy, IDocumentService, ILogService, IScope } from 'angular';
import { StateParams, StateService } from '@uirouter/angularjs';
import {} from 'angular-ui-bootstrap';
import SyntheseEntityService from '../../../../services/synthese.entity.service';
import SyntheseModelesService from '../../../../services/synthesemodeles.service';
import MassiaApplicationService from '../../../../../../massia/layout/components/massia-application/massia.application.service';
import { RouterHistoryService } from '../../../../../../../libs/massia-component/massia-service/router.history.service';
import SyntheseCacheService from '../../../../services/synthese.cache.service';
import Synthese from '../../../../models/synthese.model';
import * as uiGrid from 'ui-grid';
import MassiaRightsService from '../../../../../../massia/authentication/services/massia.rights.service';
import TemplateImpressionService from '../../../../../template-impression/services/template.impression.service';
import * as angular from 'angular';

export class SyntheseTable {
    public filterOpen: boolean = true;
    public synthese: Synthese;
    public gridOptions: uiGrid.IGridOptions;
    public app: String;
    public u: Array<any>;
    public activeBloc: number;
    loading: boolean = true;
    error: any;
    userSetting: boolean = false;
    canUpdate: boolean;
    result: any;
    $scope: IScope;
    $state: StateService;
    $stateParams: StateParams;
    SyntheseEntityService: SyntheseEntityService;
    SyntheseModelesService: SyntheseModelesService;
    MassiaApplicationService: MassiaApplicationService;
    $uibModal: ng.ui.bootstrap.IModalService;
    templateSvc: TemplateImpressionService;
    $log: ILogService;
    $document: IDocumentService;
    notification: any;
    RouterHistoryService: RouterHistoryService;
    SyntheseCacheService: SyntheseCacheService;
    MassiaRightsService: MassiaRightsService;
    $translate: any;
    $element: ng.IAugmentedJQuery;
    $compile: ng.ICompileService;
    localStorageService: any;
    AuthConstant: any;
    $timeout: any;
    loadingMsg: string = 'SYNTHENTS.GENERATE_IN_PROGRESS';
    info: string;
    showFilter: boolean = false;
    syntheseCopy: Synthese;

    /* @ngInject */
    constructor(
        $scope: IScope,
        $state: StateService,
        $stateParams: StateParams,
        SyntheseEntityService: SyntheseEntityService,
        SyntheseModelesService: SyntheseModelesService,
        MassiaApplicationService: MassiaApplicationService,
        $uibModal: ng.ui.bootstrap.IModalService,
        $log: ILogService,
        $document: IDocumentService,
        notification: any,
        RouterHistoryService: RouterHistoryService,
        SyntheseCacheService: SyntheseCacheService,
        $translate: any,
        $element: ng.IAugmentedJQuery,
        $compile: ng.ICompileService,
        MassiaRightsService: MassiaRightsService,
        localStorageService: any,
        AuthConstant: any,
        $timeout: any,
        TemplateImpressionService: TemplateImpressionService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SyntheseEntityService = SyntheseEntityService;
        this.SyntheseModelesService = SyntheseModelesService;
        this.MassiaApplicationService = MassiaApplicationService;
        this.$uibModal = $uibModal;
        this.$log = $log;
        this.$document = $document;
        this.notification = notification;
        this.RouterHistoryService = RouterHistoryService;
        this.SyntheseCacheService = SyntheseCacheService;
        this.$translate = $translate;
        this.$element = $element;
        this.templateSvc = TemplateImpressionService;
        this.$compile = $compile;
        this.MassiaRightsService = MassiaRightsService;
        this.localStorageService = localStorageService;
        this.AuthConstant = AuthConstant;
        this.$timeout = $timeout;
    }

    async $onInit() {
        this.u = [];
        const { id, user } = this.$stateParams;
        this.userSetting = user;
        this.canUpdate = this.MassiaRightsService.userHasRight('blocsel', 'update');
        this.synthese = await this.loadSynthese(id);
        if (!this.synthese.id) {
            await this.openSyntheseSetup();
            //this._loadTemplate();
        }
        if (this.synthese.id > 0) await this.generateSynthese(this.synthese.id);
        if (this.app === 'performance') {
            this.applyGlobalProducteur();
            this.setLinkedProducteurFilter();
        }
        //this._loadTemplate();
        //this.loadUiGrid();
        this.loading = false;
    }
    $onDestroy() {
        for (let i = 0; i < this.u.length; i++) {
            var u = this.u[i];
            u();
        }
    }

    async loadSynthese(id: number | undefined): Promise<Synthese> {
        try {
            if (this.$stateParams['synthese']) {
                return new Synthese(this.$stateParams['synthese']);
            }
            if (id) {
                const res = await this.SyntheseEntityService.getOne(id, !this.userSetting);
                return new Synthese(res);
            }
            return new Synthese();
        } catch (err) {}
    }

    generateSynthese = async (id: number | undefined) => {
        try {
            this.result = {
                results: []
            };
            this.loading = true;
            this.info = null;

            if (!id) id = this.synthese.id;
            let res = await this.SyntheseEntityService.generate(id, this.synthese, this.synthese.typeSynthese, [], null, this.userSetting);
            this.result = res;
            this.loading = false;
        } catch {
            this.loading = false;
        }
    };

    print = async (theme: string, res: { filename: string; pdf: boolean }) => {
        const template = await this.SyntheseEntityService.print(
            this.synthese.id,
            this.synthese,
            this.synthese.typeSynthese,
            [],
            null,
            res.filename,
            this.userSetting
        );
        template.pdf = res.pdf;
        await this.templateSvc.downloadTemplate(template.filename, template, theme);
    };

    toggleFilter = () => {
        this.showFilter = !this.showFilter;
    };

    openSyntheseSetup = async (fromResult = false) => {
        var modal = this.$uibModal.open({
            animation: false,
            component: 'setupSyntheseModal',
            windowClass: 'modern-modal',
            size: '80',
            appendTo: this.$document.find('massia-application').eq(0),
            resolve: {
                fromResult: () => fromResult,
                synthese: () => copy(this.synthese),
                isUser: () => false,
            }
        });
        try {
            var res = await modal.result;
            this.$log.log(res);
            this.synthese = res;
            if (!this.synthese.id || this.synthese.id === -1) {
                //this.gridOptions.columnDefs = this.createHeader();
                this.$log.log(this.gridOptions);
            }
            this.generateSynthese(this.synthese.id);
        } catch (err) {
            //modal closed
        }
    };

    save = async (gridApi: any, exit = false, gen = false, duplicate = false) => {
        let id = null;
        try {
            if (this.userSetting && gridApi.saveState) {
                const state = gridApi.saveState.save();
                this.synthese.setting = {
                    ...this.synthese.setting,
                    setting: JSON.stringify(state)
                };
            }
            if(!duplicate)
            {
                // Mise à jour
                if(this.$stateParams['id'])
                {
                    id = this.synthese.id;
                    await this.SyntheseEntityService.updateOne(this.synthese.id, this.synthese, this.userSetting);
                    this.notification.clear();
                    this.notification.success('SUCCESS_UPDATE', null, {
                        closeButton: false,
                        progressBar: true
                    });
                }

                // Nouvelle synthèse
                else
                {
                    id = await this.SyntheseEntityService.create(this.synthese);
                    this.notification.clear();
                    this.notification.success('SUCCESS_CREATE', null, {
                        closeButton: false,
                        progressBar: true
                    });
                    this.$state.go('synthese.synthese.edit', { id: id });
                }
            }
            else
            {
                // Duplication
                this.synthese.critereAffichages.forEach((element : any) => {
                    element.id = 0;
                    element.style.id = 0;
                });

                this.synthese.blocSelections.forEach((bloc: any)=>{
                    bloc.modelSelection.modelSelectionCriteres.forEach((crit : any) => {
                        crit.modelSelectionCritereValeur.forEach((critVal : any) => {
                            critVal.id = 0;
                        })
                    });
                })

                id = await this.openSaveAsModal();
                this.$state.go('synthese.synthese.edit', { id: id });
            }

            //if(!id) throw new Error("❌ Error occured while saving synthesis");

            if(gen) this.generateSynthese(id);
            if(exit) this.exit();
        } catch (error) {
            this.notification.clear();
            this.notification.error(error.message);
        }
    };

    async openSaveAsModal(){
        let id = null;
        var modal = this.$uibModal.open({
            animation: false,
            component: 'saveAsModal',
            windowClass: 'modern-modal',
            size: '60',
            appendTo: this.$document.find('massia-application').eq(0),
            resolve: {
                code: () => this.synthese.code,
                libelle: () => this.synthese.libelle,
            }
        });
        try {
            var res = await modal.result;
            this.syntheseCopy = angular.copy(this.synthese);
            this.syntheseCopy.code = res.code;
            this.syntheseCopy.libelle = res.libelle;
          
            id = await this.SyntheseEntityService.create(this.syntheseCopy);
            this.notification.clear();
            this.notification.success('SUCCESS_CREATE', null, {
                closeButton: false,
                progressBar: true
            });
        } catch (err) {
           
            //modal closed
        }

        return id;
    }

    exit() {
        if (!this.RouterHistoryService.back()) {
            this.$state.go('synthese.synthese.list');
        }
    }

    applyGlobalProducteur() {
        const localUser = this.localStorageService.get(this.AuthConstant.MASSIA_PERSISTS_USER_DATA_STORAGE);
        const selectedNavSiteId = localUser.selectedNavSite?.id;

        if (
            selectedNavSiteId &&
            this.synthese.blocSelections[this.activeBloc].modelSelection &&
            this.synthese.blocSelections[this.activeBloc].modelSelection.modelSelectionCriteres &&
            this.synthese.blocSelections[this.activeBloc].modelSelection.modelSelectionCriteres.length > 0
        ) {
            const affSiteProd = this.synthese.blocSelections[this.activeBloc].modelSelection.modelSelectionCriteres.find(
                (e: any) => e.typeCritere === 7 && e.donneeSelectionable.domaine.code === 'SiteProd'
            );
            if (affSiteProd && !affSiteProd.modelSelectionCritereValeur[0].idValeur)
                affSiteProd.modelSelectionCritereValeur[0].idValeur = selectedNavSiteId;
        }
    }

    setLinkedProducteurFilter() {
        if (
            this.synthese.blocSelections[this.activeBloc].modelSelection &&
            this.synthese.blocSelections[this.activeBloc].modelSelection.modelSelectionCriteres &&
            this.synthese.blocSelections[this.activeBloc].modelSelection.modelSelectionCriteres.length > 0
        ) {
            const affSiteProd = this.synthese.blocSelections[this.activeBloc].modelSelection.modelSelectionCriteres.find(
                (e: any) => e.typeCritere === 7 && e.donneeSelectionable.domaine.code === 'SiteProd'
            );
            if (affSiteProd) {
                for (let i = 0; i < affSiteProd.modelSelectionCritereValeur.length; i++) {
                    this.u.push(
                        this.$scope.$watch(
                            () => affSiteProd.modelSelectionCritereValeur[i],
                            () => {
                                this.loading = true;

                                this.$timeout(() => {
                                    this.synthese.blocSelections[this.activeBloc].modelSelection.modelSelectionCriteres.map((e: any) => {
                                        if (e.id !== affSiteProd.id) e.producteursSelectionCritereValeur = affSiteProd.modelSelectionCritereValeur;
                                    });
                                    this.loading = false;
                                }, 0);
                            }
                        )
                    );
                }

                this.synthese.blocSelections[this.activeBloc].modelSelection.modelSelectionCriteres.map((e: any) => {
                    if (e.id !== affSiteProd.id) e.producteursSelectionCritereValeur = affSiteProd.modelSelectionCritereValeur;
                });
            }
        }
    }
}

export const SyntheseTableComponent: ng.IComponentOptions = {
    bindings: {},
    controller: SyntheseTable,
    template: require('./synthese.table.html')
};
