export default function Configure($stateProvider) {
    $stateProvider.state('bons', {
        parent: 'gestion',
        url: '/bons',
        views: {
            gestion: {
                template: '<ui-view></ui-view>'
            }
        },
        ncyBreadcrumb: {
            label: '{{ "LAYOUT.NAVIGATION.DONNEES_PARAMETRES" | translate }}'
        }
    });

    $stateProvider.state('bons.list', {
        url: '/list',
        template: '<bons></bons>',
        rights: { domain: 'prestation', right: 'read' },
        ncyBreadcrumb: {
            label: '{{ "BONS.BREADCRUMBS.BONS_LIST" | translate}}'
        },
        navigation: {
            menu: 'donnees_parametres_parametres',
            translate: 'BONS.BREADCRUMBS.BONS_LIST',
            order: 37
        },
        search: 'BONS.BREADCRUMBS.BONS_LIST'
    });

    $stateProvider.state('bons.list.detail', {
        url: '/{id}',
        template: '<bon-detail></bon-detail>',
        rights: { domain: 'prestation', right: 'read' },
        ncyBreadcrumb: {
            parent: 'bons.list',
            label: '{{ "BONS.BREADCRUMBS.BON_DETAIL" | translate }}'
        }
    });

    $stateProvider.state('bons.new', {
        url: '/new',
        template: '<bon-form></bon-form>',
        rights: { domain: 'prestation', right: 'create' },
        ncyBreadcrumb: {
            parent: 'bons.list',
            label: '{{ "BONS.BREADCRUMBS.BON_NEW" | translate}}'
        },
        search: 'BONS.BREADCRUMBS.BON_NEW'
    });

    $stateProvider.state('bons.edit', {
        url: '/{id}/edit',
        template: '<bon-form></bon-form>',
        rights: { domain: 'prestation', right: 'update' },
        ncyBreadcrumb: {
            parent: 'bons.list',
            label: '{{ "BONS.BREADCRUMBS.BON_EDIT" | translate }}'
        }
    });
}

Configure.$inject = ['$stateProvider'];
