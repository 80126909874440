import Unite from './unites.model';

export default class UniteFormController {
    static $inject = [
        '$scope',
        '$state',
        '$stateParams',
        'ModeUnitesService',
        'UnitesCommunicationService',
        'UnitesService',
        'notification',
        '$location',
        'MassiaApplicationService',
        'MOPService'
    ];

    constructor(
        $scope,
        $state,
        $stateParams,
        ModeUnitesService,
        UnitesCommunicationService,
        UnitesService,
        notification,
        $location,
        MassiaApplicationService,
        MOPService
    ) {
        this.$scope = $scope;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.ModeUnitesService = ModeUnitesService;
        this.UnitesCommunicationService = UnitesCommunicationService;
        this.UnitesService = UnitesService;
        this.notification = notification;
        this.$location = $location;
        this.MassiaApplicationService = MassiaApplicationService;
        this.newLigne = false;
        this.MOPService = MOPService;
    }

    async $onInit() {
        this.MOPService.setMop([{ title: 'UNITES.BREADCRUMBS.UNITES_LIST', filename: 'UnitesEtDevises.pdf', application: 'massia' }]);
        this.isEditMode = false;
        this.unite = {};
        this.loading = false;
        this.loadModeUnites();
        this.getUnites();
        this.setUnite(this.$stateParams.id);
        this.unregisterReinit = this.UnitesCommunicationService.registerReinit(() => this.initForm());
        this.initForm();
    }

    $onDestroy() {
        this.unregisterReinit();
        this.MOPService.resetMop();
    }

    initForm() {
        if (this.$stateParams && this.$stateParams.id) {
            this.isEditMode = true;
        } else {
            this.isEditMode = false;
        }
    }

    async getUnites() {
        this.startLoading();
        try {
            this.unites = await this.UnitesService.getUnitesSelect();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async loadModeUnites() {
        this.startLoading();
        try {
            this.modeUnites = await this.ModeUnitesService.getModes();
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async setUnite(uniteId) {
        if (uniteId) {
            this.startLoading();
            try {
                this.isEditMode = true;
                const stillThatUnite = await this.UnitesService.getUniteById(uniteId);
                this.unite = new Unite(stillThatUnite);
                if (this.unites) {
                    let i = -1;
                    for (let ind = 0; ind < this.unites.length; ind++) {
                        if (this.unites[ind].id == uniteId) {
                            i = ind;
                        }
                    }
                    if (i > -1) {
                        this.unites.splice(i, 1);
                    }
                }
            } catch (ex) {
                if (ex.status === 404) {
                    this.$state.go('unites.list', {}, { reload: true });
                }
                this.notification.error(ex.data);
            } finally {
                this.stopLoading();
            }
        } else {
            this.isEditMode = false;
            this.unite = new Unite();
        }
    }

    async checkCodeUnicity(code) {
        if (!code) {
            this.unite.codeExists = null;
            return;
        }
        this.startCodeLoading();
        try {
            if (code.match(/^[a-zA-Z0-9_]*$/)) {
                this.unite.codeExists = await this.UnitesService.codeExists(code);
            }
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopCodeLoading();
        }
    }

    // boutons de validation
    async sauvegarder() {
        const continuer = true;
        // if(this.newLigne || !this.unite.conversions || this.unite.conversions.length==0)
        // {
        //     if(!await this.addConversion(false))
        //     {
        //         return false;
        //     }
        // }
        if (continuer && this.checkValidity()) {
            this.startLoading();
            try {
                if (this.unite.id) {
                    const tmp = await this.updateUnite();
                    this.notification.success('UNITES.UPDATED');
                    return this.unite.id;
                }
                const tmp = await this.createUnite();
                this.notification.success('UNITES.CREATED');
                return tmp;
            } catch (ex) {
                this.notification.error(ex.data);
                return false;
            } finally {
                this.stopLoading();
            }
        } else {
            return false;
        }
    }

    // supprimerToutesLignes() {
    //     this.unite.conversions.splice(0,this.unite.conversions.length);
    // }

    // removeConversion(conv) {
    //     var i = this.unite.conversions.indexOf(conv);
    //     this.unite.conversions.splice(i,1);
    // }

    ajouterLigne() {
        this.newLigne = true;
    }

    removeNewLigne() {
        this.newLigne = false;
    }

    // async addConversion(newLigne) {
    //     if(this.newUniteConv && this.newFormuleConv)
    //     {

    //         var trouve=false;
    //         if(this.unite.conversions)
    //         {
    //             for(var ind=0; ind<this.unite.conversions.length;ind++)
    //             {
    //                 if(this.unite.conversions[ind].idUnite==this.newUniteConv)
    //                     trouve=true;
    //             }
    //         }
    //         if(trouve)
    //         {
    //             this.notification.error('UNITES.CONVERSION_EXISTANTE_UNITE');
    //             return false;
    //         }
    //         else
    //         {
    //             let newIndic = { id:-1, idUnite:this.newUniteConv, formuleConversion:this.newFormuleConv};
    //             if(!this.unite.conversions)
    //                 this.unite.conversions=[];
    //             this.unite.conversions.push(newIndic);
    //             this.newUniteConv=undefined;
    //             this.newFormuleConv=undefined;
    //             if(newLigne)
    //                 this.ajouterLigne();
    //             return true;
    //         }
    //     }
    //     else
    //     {
    //         if(this.newUniteConv || this.newFormuleConv)
    //         {
    //             this.notification.error('UNITES.UNITE_FORMULE_OBLIGATOIRES');
    //             return false;
    //         }
    //         else
    //             return true;//pas de nouvelle ligne à créer
    //     }
    // }

    async valider() {
        const returnedId = await this.sauvegarder();
        if (returnedId) {
            this.newLigne = false;
            this.$state.go('unites.edit', { id: returnedId });
        }
    }
    async validerEtFermer() {
        const success = await this.sauvegarder();
        if (success) {
            this.annuler();
        }
    }

    annuler() {
        this.$state.go('unites.list');
    }

    async createUnite() {
        this.startLoading();
        try {
            const returnedId = await this.UnitesService.createUnite(this.unite);
            this.notification.success('UNITES.CREATED');
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
        } finally {
            this.stopLoading();
        }
    }

    async updateUnite() {
        this.startLoading();
        try {
            const returnedId = await this.UnitesService.updateUnite(this.unite);
            this.notification.success('UNITES.UPDATED');
            return returnedId;
        } catch (ex) {
            this.notification.error(ex.data);
            this.reinit();
        } finally {
            this.stopLoading();
        }
    }

    reinit() {
        this.setUnite(this.$stateParams.id);
        this.UnitesCommunicationService.raiseReinit();
    }

    checkValidity() {
        this.$scope.$broadcast('uniteValidations');
        return this.unite.isValid();
    }

    startLoading() {
        this.loading = true;
    }

    stopLoading() {
        this.loading = false;
    }

    startCodeLoading() {
        this.codeLoading = true;
    }

    stopCodeLoading() {
        this.codeLoading = false;
    }
}
